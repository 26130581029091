import React, { FC, useEffect } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectFileConfirmationById } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import BhDivTableHeader from "@components/table/BhDivTableHeader";
import { classNames } from "@/utilities/jsUtilities";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import { IUser } from "@/model/IUser";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { openCommentsForFileConfirmationFileIdSet, selectFileConfirmationFilesForConfirmationSorted } from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import { Trans } from "react-i18next";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";

interface Props {
  confirmationId: EntityId;
}

const ConfirmationContainerUsers: FC<Props> = ({ confirmationId }) => {
  const fileConfirmation = useAppSelector((state) => selectFileConfirmationById(state, confirmationId));
  const fileConfirmationFiles = useAppSelector((state) => selectFileConfirmationFilesForConfirmationSorted(state, confirmationId));
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(openCommentsForFileConfirmationFileIdSet(undefined));
  }, []);

  if (!fileConfirmation) {
    return null;
  }

  const tableColumnHeaders: Array<IBhTableHeader<IUser>> = [
    { id: 1, content: <Trans>GLOBAL.ORDER</Trans>, classes: "w-22 flex-none justify-center" },
    { id: 2, content: <Trans>COMPANY.SETTINGS.ORDERS.ORDER_BY.NAME</Trans>, classes: "flex-grow" },
    { id: 3, content: <Trans>CONFIRMATIONS.CONFIRMED_FILES</Trans>, classes: "w-40" },
    { id: 7, content: "", classes: "w-28 flex-none" }
  ];

  return (
    <BhScrollableBody
      header={
        <>
          <BhDivTableHeader columns={tableColumnHeaders} onClickCallback={() => {}} sortedBy={""} reversed={false} />
        </>
      }
    >
      {fileConfirmation.users.map((userObject, index) => {
        return (
          <div
            key={userObject.userEntity.id}
            className={classNames("bh-border-pigeon-40 bh-hover-container group group block flex h-14 items-center border-b", userObject.confirmationUser.deleted && "bh-bg-smoke-50")}
          >
            <div className="w-18 flex flex-none items-center justify-center">{index + 1}</div>
            <div className={classNames("flex flex-grow flex-row items-center px-3", userObject.confirmationUser.deleted && "opacity-50")}>
              <BhUserIconWithName user={userObject.userEntity} disablePopup={userObject.confirmationUser.deleted} />
              {userObject.confirmationUser.deleted && (
                <div className="ml-2">
                  <BhTag type={BhTagType.ARHIVEERITUD}>
                    <Trans>CONFIRMATION.USER_REMOVED</Trans>
                  </BhTag>
                </div>
              )}
            </div>
            <div className={classNames("w-40 flex-none px-3", userObject.confirmationUser.deleted && "opacity-50")}>
              {fileConfirmationFiles.filter((file) => file.confirmedUserIds.includes(userObject.userEntity.id)).length + "/" + fileConfirmation?.totalFiles}
            </div>
            <div className="w-28 flex-none px-3 text-right"></div>
          </div>
        );
      })}
    </BhScrollableBody>
  );
};

export default ConfirmationContainerUsers;
