import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { useNavigate } from "react-router-dom";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { IChecklistBaseVersion } from "@/model/checklist/IChecklistBaseVersion";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  checklistBaseVersions: Array<IChecklistBaseVersion>;
  currentVersionId: EntityId;
  companyId: EntityId;
  projectId?: EntityId;
}

const ChecklistBaseHeaderVersionDropdown: FC<Props> = ({ checklistBaseVersions, currentVersionId, companyId, projectId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentVersion = checklistBaseVersions.find((version) => version.id === currentVersionId);
  const moreThanOneVersionExists = checklistBaseVersions.length > 1;

  if (!currentVersion) return null;

  const changeVersion = (version: IChecklistBaseVersion) => {
    if (version.id === currentVersionId) {
      return;
    }
    if (projectId) {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklist/base/${version.checklistBaseId}/version/${version.id}`);
    } else {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${companyId}/checklist/base/${version.checklistBaseId}/version/${version.id}`);
    }
  };

  const dropdownChecklistBaseVersions = checklistBaseVersions
    .slice()
    .sort((a, b) => (a.versionNumber < b.versionNumber ? 1 : -1))
    .map((version) => {
      return {
        text: (
          <div className="flex w-full flex-row items-center">
            <div className="flex w-8 flex-row items-center">{version.id === currentVersionId && <FontAwesomeIcon icon={faCheck} />}</div>
            <div className="whitespace-nowrap">{t("CHECKLIST.CHECKLIST_VERSION") + " " + version.versionNumber}</div>
          </div>
        ),
        function: () => changeVersion(version)
      };
    });

  return (
    <BhDropdown
      button={
        <div className="bh-bg-pigeon-20 mr-2 flex cursor-pointer flex-row items-center rounded p-1.5">
          <div className="bh-text-deep-ocean font-bold">{"VER " + currentVersion.versionNumber}</div>
          {moreThanOneVersionExists && <FontAwesomeIcon icon={faCaretDown} className="ml-2" />}
        </div>
      }
      disabled={!moreThanOneVersionExists}
      menu={<BhDropdownMenu values={dropdownChecklistBaseVersions} textProperty={"text"} type={BhDropdownTypeEnum.STRING} widthClass={"w-fit"} />}
    />
  );
};

export default ChecklistBaseHeaderVersionDropdown;
