import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { IYearlyInvoiceOverview } from "@/model/invoices/IYearlyInvoiceOverview";
import { ISalesInvoice } from "@/model/billing/ISalesInvoice";
import { IMonthlyInvoiceOverview } from "@/model/invoices/IMonthlyInvoiceOverview";
import { IInvoiceEmail } from "@/model/invoices/IInvoiceEmail";
import { IFileUploadPresignedDTO } from "@/model/files/IFileUploadPresignedDTO";
import { IMonthlyInvoiceOverviewRow } from "@/model/invoices/IMonthlyInvoiceOverviewRow";

export function boFetchYearlyInvoiceOverview(year: string): Promise<IYearlyInvoiceOverview> {
  return bauhubGet("/admin/year/" + year + "/invoice/overview");
}

export function boFetchMonthlyInvoiceOverview(month: string): Promise<IMonthlyInvoiceOverview> {
  return bauhubGet("/admin/month/" + month + "/invoice/overview");
}

export function boDownloadInvoice(uuid: string): Promise<any> {
  return bauhubGet("/admin/invoice/" + uuid + "/presigned");
}

export function boCreditInvoice(invoice: ISalesInvoice): Promise<IMonthlyInvoiceOverview> {
  return bauhubPost("/admin/invoice/credit", invoice);
}

export function boMarkInvoicePaid(invoice: ISalesInvoice): Promise<IMonthlyInvoiceOverview> {
  return bauhubPost("/admin/invoice/mark/paid", invoice);
}

export function boChangeInvoicePaymentDate(invoice: ISalesInvoice): Promise<ISalesInvoice> {
  return bauhubPost("/admin/invoice/change/payment/date", invoice);
}

export function boResendSalesInvoice(invoice: ISalesInvoice): Promise<void> {
  return bauhubPost("/admin/invoice/resend?disableSuccessToast=true", invoice);
}

export function boSendKmdInfA(emails: IInvoiceEmail): Promise<void> {
  return bauhubPost("/admin/monthly/invoice/report/kmd", emails);
}

export function boSendMonthlyInvoiceReport(emails: IInvoiceEmail): Promise<void> {
  return bauhubPost("/admin/monthly/invoice/report", emails);
}

export function boSaveNewInvoice(invoice: ISalesInvoice): Promise<IMonthlyInvoiceOverviewRow> {
  return bauhubPost("/admin/invoice/custom", invoice);
}

export function boChangeInvoice(invoice: ISalesInvoice): Promise<IMonthlyInvoiceOverviewRow> {
  return bauhubPost("/admin/invoice/change/generate", invoice);
}

export function boGetPresignedUploadUrlForCustomSalesInvoice(contentType: string): Promise<IFileUploadPresignedDTO> {
  return bauhubGet("/url/upload/invoice/custom", { contentType: contentType });
}
