import React, { FC, useEffect, useState } from "react";
import BOHeader from "@/views/home/backoffice/views/BOHeader";
import BhNavigationDropdown from "@components/dropdown/BhNavigationDropdown";
import { boFindYearTrialOveriewAsync, boSelectYearlyTrialOverview } from "@/app/store/backofficeSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BOTrialsSpreadsheetTable from "@components/backoffice/trials/BOTrialsSpreadsheetTable";

const BOTrials: FC = () => {
  const yearlyTrialOverview = useAppSelector(boSelectYearlyTrialOverview);
  const year = new Date().getFullYear();
  const [trialYear, setTrialYear] = useState(year);
  const startYear = year - 5;
  const endYear = year + 5;
  const trialYears = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(boFindYearTrialOveriewAsync(trialYear.toString()));
  }, [trialYear]);

  const noYearlyTrialOverview = yearlyTrialOverview?.rows?.length === 0;

  return (
    <div className="px-16 sm:px-4">
      <BOHeader
        customHeader={
          <div className="flex items-center gap-4 py-2">
            <h1 className="m-0">Trials</h1>
            <BhNavigationDropdown
              onMenuItemClickCallback={setTrialYear}
              menuItems={trialYears}
              value={trialYear.toString()}
              onLeftClick={() => setTrialYear(trialYear - 1)}
              onRightClick={() => setTrialYear(trialYear + 1)}
            />
          </div>
        }
      />
      <BOTrialsSpreadsheetTable />
      {noYearlyTrialOverview && <p className="mt-2 text-center text-lg">No data</p>}
    </div>
  );
};

export default BOTrials;
