import { IUser } from "@/model/IUser";

export interface IMaruClientPerson {
  firstname: string;
  lastname: string;
  clientname: string;
  personalphone: string;
  uniquecontactid: string;
  personalemail: string;
  positionincompany: string;
}

export function convertMaruClientPersonToUser(maruClientPerson: IMaruClientPerson): IUser {
  return {
    id: maruClientPerson.uniquecontactid || Math.random() * 100000,
    username: maruClientPerson.personalemail,
    firstName: maruClientPerson.firstname,
    lastName: maruClientPerson.lastname
  } as IUser;
}
