import React, { Dispatch, FC, SetStateAction, useState } from "react";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhSelectInputFilter from "@components/filters/BhSelectInputFilter";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectCompanyId, selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { selectUsernamesForFormBaseFilterInProjectSettings } from "@/app/store/form/formBasesSlice";
import NewFormBaseModal from "@components/form/settings/companySettings/NewFormBaseModal";
import { BhSectionMessageSomethingElse } from "@components/sectionMessage/BhSectionMessages";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons/faCircleInfo";
import { isCurrentUserCompanyFormsManager } from "@/app/store/userSlice";
import { Link } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faCog } from "@fortawesome/pro-regular-svg-icons/faCog";
import BhFilter from "@components/filters/BhFilter";
import { IFormBaseFilter } from "@/model/form/IFormBaseFilter";

interface Props {
  filter: IFormBaseFilter;
  setFilter: Dispatch<SetStateAction<IFormBaseFilter>>;
}

const ProjectFormsSettingsHeader: FC<Props> = ({ filter, setFilter }) => {
  const { t } = useTranslation();
  const companyId = useAppSelector(selectCurrentProjectCompanyId);
  const projectId = useAppSelector(selectCurrentProjectId);
  const usernamesForFilter = useAppSelector(selectUsernamesForFormBaseFilterInProjectSettings);
  const isCompanyFormsManager = useAppSelector((state) => isCurrentUserCompanyFormsManager(state, companyId));
  const [showNewFormBaseModal, setShowNewFormBaseModal] = useState<boolean>(false);
  const baseTypeFilterValues = [
    { name: t("FORMBUILDER.FORM.PROJECT_TEMPLATES"), showProjectTemplatesOnly: true, showCompanyTemplatesOnly: false },
    {
      name: t("FORMBUILDER.FORM.COMPANY_TEMPLATES"),
      showProjectTemplatesOnly: false,
      showCompanyTemplatesOnly: true
    }
  ];

  const onChange = (search: { type: string }) => {
    setFilter({ ...filter, searchString: search.type });
  };

  const isUsernameSelected = (username: { username: string; translateValue: string }) => {
    return filter.usernames.some((selectedUsername) => selectedUsername.toLowerCase() === username.username.toLowerCase());
  };

  const onUsernameFilterSelect = (username: { username: string; translateValue: string }) => {
    const updatedUsernames = isUsernameSelected(username) ? filter.usernames.filter((u) => u.toLowerCase() !== username.username.toLowerCase()) : [...filter.usernames, username.username];
    setFilter({ ...filter, usernames: updatedUsernames });
  };

  const onUsernameFilterReset = () => {
    setFilter({ ...filter, usernames: [] });
  };

  const onBaseTypeFilterSelect = (typeObject: any) => {
    setFilter({ ...filter, ...{ showProjectTemplatesOnly: typeObject.showProjectTemplatesOnly, showCompanyTemplatesOnly: typeObject.showCompanyTemplatesOnly } });
  };

  const onBaseTypeFilterReset = () => {
    setFilter({ ...filter, ...{ showProjectTemplatesOnly: false, showCompanyTemplatesOnly: false } });
  };

  return (
    <div className="flex flex-col">
      <BhSectionMessageSomethingElse iconClasses="pl-2" small={true} icon={faCircleInfo}>
        <div className="flex flex-row items-center justify-between pl-1">
          <p>
            <b>{t("FORMBUILDER.FORM.PROJECT_SETTINGS.COMPANY_BASE_INFO.HEADER")}</b> {t("FORMBUILDER.FORM.PROJECT_SETTINGS.COMPANY_BASE_INFO.BODY")}
          </p>
          {isCompanyFormsManager && companyId && (
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_BASE_URL + `/company/${companyId}/forms`}>
              <BhTextOnlyButton icon={faCog} buttonProps={{ classes: "w-max" }}>
                {t("FORMBUILDER.FORM.COMPANY_SETTINGS")}
              </BhTextOnlyButton>
            </Link>
          )}
        </div>
      </BhSectionMessageSomethingElse>
      <div className="my-4 flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-x-4">
          <BhSearchInputWBG initialValue={filter.searchString} property="type" onChangeCallback={onChange} inputClasses="w-72" placeholder={t("FORMBUILDER.FORM.SEARCH.TEMPLATE") as string} />
          <BhFilter
            title={t("FORMBUILDER.FORM_BASE.FILTER.CREATED_BY")}
            translateValues={true}
            values={usernamesForFilter}
            textProperty="translateValue"
            onSelect={onUsernameFilterSelect}
            onReset={onUsernameFilterReset}
            selectedValues={filter.usernames.length}
            isSelected={isUsernameSelected}
          />
          <BhSelectInputFilter
            values={baseTypeFilterValues}
            currentValue={
              filter.showProjectTemplatesOnly ? (t("FORMBUILDER.FORM.PROJECT_TEMPLATES") as string) : filter.showCompanyTemplatesOnly ? (t("FORMBUILDER.FORM.COMPANY_TEMPLATES") as string) : ""
            }
            onSelect={onBaseTypeFilterSelect}
            placeholder={t("FORMBUILDER.FORM.FORM_TYPE")}
            onReset={onBaseTypeFilterReset}
            textProperty="name"
          />
        </div>
        <BhPrimaryButton
          icon={faPlus}
          buttonProps={{
            onClick: () => setShowNewFormBaseModal(true)
          }}
        >
          {t("FORMBUILDER.FORM.PROJECT_SETTINGS.NEW_BASE")}
        </BhPrimaryButton>
        {showNewFormBaseModal && <NewFormBaseModal setShowModal={setShowNewFormBaseModal} companyId={companyId} projectId={projectId} />}
      </div>
    </div>
  );
};

export default ProjectFormsSettingsHeader;
