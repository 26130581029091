import React, { FC, useState } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectProjectWorkGroupById } from "@/app/store/project/projectWorkGroupsSlice";
import { EntityId } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import BhCardContainer from "@components/cards/BhCardContainer";
import MaruPartiesListItemInfo from "@/views/home/project/detail/users/parties/maru/MaruPartiesListItemInfo";
import MaruPartyValuationModal from "@/views/home/project/detail/users/parties/maru/MaruPartyValuationModal";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  partyId: EntityId;
}

const MaruPartiesListItem: FC<Props> = ({ partyId }) => {
  const party = useAppSelector((state) => selectProjectWorkGroupById(state, partyId));
  const navigate = useNavigate();
  const [maruPartyValuationModalOpen, setMaruPartyValuationModalOpen] = useState(false);

  if (!party) return null;

  return (
    <>
      <BhCardContainer
        onClick={() => {
          navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${party.projectId}/users/party/${party.id}/privileges`);
        }}
      >
        <MaruPartiesListItemInfo party={party} setValuationModalOpen={setMaruPartyValuationModalOpen} />
      </BhCardContainer>
      {maruPartyValuationModalOpen && <MaruPartyValuationModal workGroup={party} setIsShown={setMaruPartyValuationModalOpen} />}
    </>
  );
};

export default MaruPartiesListItem;
