import React, { FC, useEffect, useState } from "react";
import ChecklistsFilter from "@components/checklists/list/ChecklistsFilter";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import {
  fetchChecklistBasesForUserAsync,
  fetchChecklistsForUserAsync,
  selectAllChecklists,
  selectChecklistFilter,
  selectUserChecklistBases,
  setChecklistFilter
} from "@/app/store/checklist/checklistsSlice";
import BhPagination from "@components/pagination/BhPagination";
import ChecklistsTable from "@components/checklists/list/ChecklistsTable";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import NoChecklists from "@components/checklists/list/NoChecklists";
import NoChecklistBases from "@components/checklists/list/NoChecklistBases";
import ChecklistsBasketContainer from "@components/checklists/list/ChecklistsBasketContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { urlParamsToObject } from "@/utilities/jsUtilities";
import { ChecklistBaseCategory } from "@/model/checklist/IChecklistBase";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { useTranslation } from "react-i18next";
import ChecklistBulkCopyModal from "@components/checklists/list/ChecklistBulkCopyModal";
import ChecklistBulkCopyDirSelectionModal from "@components/checklists/list/ChecklistBulkCopyDirSelectionModal";

interface Props {
  archived?: boolean;
}

const Checklists: FC<Props> = ({ archived }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const projectId = useAppSelector(selectCurrentProjectId);
  const filter = useAppSelector(selectChecklistFilter);
  const checklists = useAppSelector(selectAllChecklists);
  const checklistBases = useAppSelector(selectUserChecklistBases);
  const [firstRender, setFirstRender] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isCopyRevisionsModalOpen, setIsCopyRevisionsModalOpen] = useState<boolean>(false);
  const [isDirectorySelectionModalOpen, setIsDirectorySelectionModalOpen] = useState<boolean>(false);
  const [copyFilesAsRevisions, setCopyFilesAsRevisions] = useState<boolean>(false);

  useEffect(() => {
    if (filter.category) {
      dispatch(fetchChecklistBasesForUserAsync({ projectId: projectId, category: filter.category }));
    }
  }, [filter.category]);

  useEffect(() => {
    fetchChecklistsForPage(firstRender ? 1 : filter.page);
    if (firstRender) {
      setFirstRender(false);
    }
  }, [filter.type, filter.name, filter.orderBy, filter.orderAsc, filter.tagName, filter.createdBy, filter.since, filter.until]);

  const changePageClicked = (page: number) => {
    fetchChecklistsForPage(page);
  };

  const fetchChecklistsForPage = (page: number) => {
    setIsLoading(true);
    let newFilter = { ...filter, ...{ archived: archived, page: page } };
    if (!filter.category) {
      const categoryParam = urlParamsToObject(location.search).category as ChecklistBaseCategory;
      if (!categoryParam) {
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklists`);
        return;
      }
      newFilter = { ...newFilter, category: categoryParam };
      dispatch(setChecklistFilter({ filter: newFilter }));
    }
    dispatch(fetchChecklistsForUserAsync({ projectId: projectId, filter: newFilter })).then(() => {
      setIsLoading(false);
    });
  };

  const checklistBasesExist = checklistBases && checklistBases.length > 0;
  const checklistsExist = checklists && checklists.length > 0;
  const showNoChecklists = !firstRender && !isLoading && !checklistsExist && checklistBasesExist;
  const showNoChecklistBases = !firstRender && !isLoading && !checklistsExist && !checklistBasesExist;
  const showChecklistsTable = isLoading || checklistsExist;

  return (
    <BhScrollableBody
      header={<ChecklistsFilter archived={archived} />}
      footer={
        checklists &&
        checklists.length > 0 &&
        filter.total &&
        filter.total > filter.limit && <BhPagination total={filter.total} limit={filter.limit} page={filter.page} changePageClicked={changePageClicked} />
      }
    >
      {showChecklistsTable && <ChecklistsTable isLoading={isLoading} />}
      {showNoChecklists && <NoChecklists archived={archived} />}
      {showNoChecklistBases && <NoChecklistBases newBaseDefaultCategory={filter.category} />}
      {isCopyRevisionsModalOpen && (
        <ChecklistBulkCopyModal
          isShown={isCopyRevisionsModalOpen}
          setIsShown={(value: boolean) => setIsCopyRevisionsModalOpen(value)}
          setCopyFilesAsRevisions={setCopyFilesAsRevisions}
          setIsDirSelectionModalShown={setIsDirectorySelectionModalOpen}
        />
      )}
      {isDirectorySelectionModalOpen && (
        <ChecklistBulkCopyDirSelectionModal
          setIsShown={() => setIsDirectorySelectionModalOpen(!isDirectorySelectionModalOpen)}
          copyAsRevisions={copyFilesAsRevisions}
          setCopyAsRevisions={setCopyFilesAsRevisions}
        />
      )}
      <ChecklistsBasketContainer
        showCopyMultipleRevisionsModal={() => setIsCopyRevisionsModalOpen(true)}
        showCopySingleRevisionsModal={() => setIsDirectorySelectionModalOpen(true)}
        archived={archived}
      />
    </BhScrollableBody>
  );
};

export default Checklists;
