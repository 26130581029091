import React, { FC } from "react";
import BhTableHeader from "@components/table/BhTableHeader";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IBauhubEvent } from "@/model/IBauhubEvent";
import ProjectLogTableRowContainer from "@/features/projectLogTable/ProjectLogTableRowContainer";
import ProjectLogTableRowContainerSkeleton from "@/features/projectLogTable/ProjectLogTableRowContainerSkeleton";
import { useTranslation } from "react-i18next";

interface Props {
  logs: Array<IBauhubEvent>;
  sortedBy: any;
  sortReversed: boolean;
  tableHeaderOnClick: Function;
  logsLoading: boolean;
  restoreFileCallback?: Function;
  openDirectoryCallback?: Function;
  openFileHistoryCallback?: Function;
  tableHeaderClasses?: Record<number, string>;
  openActCallback?: Function;
}

const ProjectLogTable: FC<Props> = ({
  logs,
  sortedBy,
  sortReversed,
  tableHeaderOnClick,
  logsLoading,
  restoreFileCallback,
  openDirectoryCallback,
  openFileHistoryCallback,
  tableHeaderClasses,
  openActCallback
}) => {
  const { t } = useTranslation();

  const tableColumnHeaders: Array<IBhTableHeader<IBauhubEvent>> = [
    { id: 1, content: t("LOG.TYPE"), field: "type", classes: "w-80" },
    { id: 2, content: t("LOG.NAME"), field: "fileType" },
    { id: 3, content: t("LOG.CREATED"), field: "created", sortable: true, classes: "w-52" }
  ];

  return (
    <div className="flex w-full min-w-[800px] flex-col">
      <table className="w-full table-fixed">
        <BhTableHeader columns={tableColumnHeaders} onClickCallback={tableHeaderOnClick} sortedBy={sortedBy} reversed={sortReversed} />
        <tbody className="divide-y">
          {logsLoading && <ProjectLogTableRowContainerSkeleton numberOfDisplayedItems={20} />}
          {!logsLoading &&
            logs.map((log) => (
              <ProjectLogTableRowContainer
                log={log}
                restoreFileCallback={restoreFileCallback}
                openDirectoryCallback={openDirectoryCallback}
                openFileHistoryCallback={openFileHistoryCallback}
                openActCallback={openActCallback}
                key={log.id}
              />
            ))}
          {!logsLoading && logs.length === 0 && (
            <tr>
              <td colSpan={10} className="center pt-10 text-center">
                <h2>{t("LOG._NO_HISTORY")}</h2>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectLogTable;
