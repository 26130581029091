import React, { FC } from "react";
import TaskInfoField from "@components/task/modal/TaskInfoField";
import { ITask } from "@/model/taskBoard/ITask";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { useAppSelector } from "@/app/hooks";
import { selectAllTaskBoards, selectIsPersonalTaskBoardOpen, selectOpenedTaskBoardId, selectTaskBoardById } from "@/app/store/taskBoardsSlice";
import { faSquareKanban } from "@fortawesome/pro-regular-svg-icons/faSquareKanban";
import { ITaskBoard } from "@/model/taskBoard/ITaskBoard";
import { useTranslation } from "react-i18next";
import { selectOpenedTaskDisabled } from "@/app/store/tasksSlice";

interface Props {
  task: ITask;
  onSelect?: Function;
}

const TaskTaskboardInfoField: FC<Props> = ({ task, onSelect }) => {
  const { t } = useTranslation();
  const taskBoardById = useAppSelector((state) => selectTaskBoardById(state, task.taskBoardId));
  const taskBoards = useAppSelector((state) => selectAllTaskBoards(state));
  const openedTaskboardId = useAppSelector(selectOpenedTaskBoardId);
  const isPersonalTaskboard = useAppSelector(selectIsPersonalTaskBoardOpen);
  const isTaskDisabled = useAppSelector(selectOpenedTaskDisabled);
  const isNewTaskNotOnTaskboard = !openedTaskboardId && !isPersonalTaskboard && !task.id;
  const disabled = !isNewTaskNotOnTaskboard || isTaskDisabled;
  const taskBoard = taskBoardById ? taskBoardById : { name: t("TASKBOARD.PERSONAL") };
  const dropdownValues = [{ name: t("TASKBOARD.PERSONAL"), id: null }, ...taskBoards];

  const changeTaskboard = (taskBoard: ITaskBoard) => {
    const changedObject = { taskBoardId: taskBoard.id };
    onSelect && onSelect(changedObject);
  };

  return (
    <div>
      <BhDropdown
        button={
          <TaskInfoField
            text={t("TASK.TASK_BOARD")}
            children={<div className="bh-text-deep-ocean font-bold">{taskBoard.name}</div>}
            valueExists={true}
            trailingIcon={faCaretDown}
            icon={faSquareKanban}
            disabled={disabled}
          />
        }
        menu={<BhDropdownMenu type={BhDropdownTypeEnum.STRING} values={dropdownValues} textProperty={"name"} onSelect={changeTaskboard} closeOnItemClick={true} numberOfItemsDisplayed={8} />}
        position={BhDropdownPositionEnum.BOTTOM_LEFT}
        buttonClasses={"w-full px-0"}
        menuClasses={"right-4 top-11"}
        disabled={disabled}
      />
    </div>
  );
};

export default TaskTaskboardInfoField;
