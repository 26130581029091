import React, { useEffect } from "react";
import { getCookie } from "@/utilities/jsUtilities";
import { useAppDispatch } from "@/app/hooks";
import { xsrfTokenSet } from "@/app/store/companiesSlice";
import { ModalProvider } from "react-modal-hook";
import dayjs from "dayjs";
import BauhubAppRouterWithOldUrlRedirect from "@/views/home/BauhubAppRouterWithOldUrlRedirect";
import { BrowserRouter } from "react-router-dom";
import BhFullPageLoading from "@components/loading/BhFullPageLoading";
import { useTranslation } from "react-i18next";

const utc = require("dayjs/plugin/utc");

function App() {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  dayjs.extend(utc);

  useEffect(() => {
    const xsrfValue = getCookie("XSRF-TOKEN");
    if (xsrfValue) dispatch(xsrfTokenSet(xsrfValue));

    i18n.services.backendConnector.backend.getLanguages((err: any, ret: any) => {
      if (err) return; // TODO: Kui tekib viga siis lae fail meie serverist
    });
  }, []);

  return (
    <div className="App">
      <ModalProvider>
        <BrowserRouter>
          <BauhubAppRouterWithOldUrlRedirect />
        </BrowserRouter>
      </ModalProvider>
      <BhFullPageLoading />
    </div>
  );
}

export default App;
