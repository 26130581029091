import { Axis, GlyphSeries, Grid, LineSeries, Tooltip, XYChart } from "@visx/xychart";
import React, { FC } from "react";
import { formatCurrency } from "@/utilities/accountingUtilities";
import BhCustomLeftAxisTick from "@components/charts/BhCustomLeftAxisTick";

export interface IBhChartAccessors {
  xAccessor: any;
  yAccessor: any;
  colorAccessor?: (value: any) => string;
}

export interface IBhChartLinePoint {
  dataKey: string;
  x: any;
  y: any;
  color?: string;
}

export interface IBhChartLegendData {
  label: string;
  color?: string;
}

export interface IBhChartData {
  [key: string]: Array<IBhChartLinePoint>;
}

interface Props {
  accessors: IBhChartAccessors;
  data: IBhChartData;
  leftAxisFormatter?: Function;
  bottomAxisFormatter?: Function;
  height?: number;
}

const BhLineChart: FC<Props> = ({ accessors, data, bottomAxisFormatter, leftAxisFormatter, height }) => {
  const formatBottomAxis = (value: any): any => {
    if (bottomAxisFormatter) {
      return bottomAxisFormatter(value);
    } else {
      return value;
    }
  };
  const formatLeftAxis = (value: any): any => {
    if (leftAxisFormatter) {
      return leftAxisFormatter(value);
    } else {
      return value;
    }
  };

  const formatTooltipSum = (sum: number) => {
    return formatCurrency(sum, { symbol: "€", thousandsSeparator: " ", decimalSeparator: ",", significantDigits: 2 });
  };

  return (
    <div className="chart-container w-full">
      <XYChart height={height ? height : 400} xScale={{ type: "band" }} yScale={{ type: "linear" }}>
        <Axis orientation="bottom" axisClassName="bottom-axis" tickFormat={formatBottomAxis} axisLineClassName="bottom-axis-line" />
        
        {/*<Axis orientation="left" axisClassName="left-axis" tickFormat={formatLeftAxis} />*/}
        <Axis
          orientation="left"
          axisClassName="left-axis"
          hideAxisLine={true}
          tickStroke="transparent"
          left={40}
          tickFormat={(value) => formatLeftAxis(value)}
          tickComponent={({ x, y, formattedValue }) => <BhCustomLeftAxisTick x={x} y={y} formattedValue={formattedValue} />}
        />
        <Grid columns={true} numTicks={12} lineStyle={{ stroke: "#D6DFE3" }} />
        {Object.keys(data).map((dataKey) => (
          <React.Fragment key={dataKey}>
            <LineSeries dataKey={data[dataKey][0].dataKey} data={data[dataKey]} {...accessors} stroke={data[dataKey][0].color} />
            <GlyphSeries dataKey={data[dataKey][0].dataKey} data={data[dataKey]} {...accessors} />
          </React.Fragment>
        ))}
        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          unstyled={true}
          applyPositionStyle={true}
          offsetTop={-30}
          renderTooltip={({ tooltipData, colorScale, tooltipLeft }) => {
            if (tooltipData && tooltipData.nearestDatum && colorScale) {
              return <div className="bh-tooltip -m-2 -translate-x-1/2 rounded">{formatTooltipSum(accessors.yAccessor(tooltipData.nearestDatum.datum))}</div>;
            }
          }}
        />
      </XYChart>
    </div>
  );
};

export default BhLineChart;
