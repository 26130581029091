import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  resetUserProfileModalsData,
  saveUserMailSettingsInfoAsync,
  selectUserMailProjectRelatedSettings,
  selectUserMailSettingsActiveProjectId,
  selectUserMailSettingsModalType,
  setUserMailSettingsModalActiveProjectId
} from "@/app/store/userPreferenceSlice";
import ProjectTreeListItemWithCheckbox from "@/views/home/company/createProject/newProjectFolderStructure/projectTreePreviewModal/ProjectTreeListItemWithCheckbox";
import { IProject } from "@/model/IProject";
import { selectAllProjectsSortedByName } from "@/app/store/companiesSlice";
import { UserEmailSettingsType } from "@/model/profile/IUserEmailSettings";
import { parseInt } from "lodash";
import { saveCurrentUserInfoAsync, selectCurrentUserInfo } from "@/app/store/userSlice";
import BhInputLabel from "@components/input/BhInputLabel";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import BhCombobox from "@components/input/BhCombobox";

const UserMailSettingsModalProjectsContainer: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userMailSettingsInfo = useAppSelector(selectUserMailProjectRelatedSettings);
  const modalType = useAppSelector(selectUserMailSettingsModalType);
  const projects = useAppSelector(selectAllProjectsSortedByName);
  const activeProjectId = useAppSelector(selectUserMailSettingsActiveProjectId);
  const currentUserInfo = useAppSelector(selectCurrentUserInfo);
  const isDailyMailModal = modalType === UserEmailSettingsType.DISABLE_IN_DAILY_EMAIL;
  const isTaskMailModal = modalType === UserEmailSettingsType.DISABLE_IN_TASK_EMAIL;
  const [timeValues, setTimeValues] = useState([] as Array<string>);

  const currentUserDailyMailTime =
    currentUserInfo.dailyMailTimeHour === null
      ? "-- : --"
      : (currentUserInfo.dailyMailTimeHour < 10 ? "0" : "") + currentUserInfo.dailyMailTimeHour + ":" + (currentUserInfo.dailyMailTimeMinute === 0 ? "0" : "") + currentUserInfo.dailyMailTimeMinute;

  const findAndSetTimeValues = () => {
    let times = [];
    for (let i = 0; i < 24; i++) {
      if (i < 10) {
        times.push("0" + i + ":00");
        times.push("0" + i + ":30");
      } else {
        times.push(i + ":00");
        times.push(i + ":30");
      }
    }
    setTimeValues(times);
  };

  useEffect(() => {
    if (isDailyMailModal) {
      findAndSetTimeValues();
    }
  }, []);

  useEffect(() => {
    if (projects && projects.length > 0 && (isDailyMailModal || isTaskMailModal)) {
      dispatch(setUserMailSettingsModalActiveProjectId(projects[0].id));
    }
  }, [projects]);

  const isProjectSelected = (project: IProject) => {
    return !userMailSettingsInfo.some((info) => info.resourceId === project.id);
  };

  const selectProject = (projectId: EntityId, changedObject: any) => {
    if (userMailSettingsInfo) {
      const existingSetting = userMailSettingsInfo.find((info) => info.resource === "PROJECT" && info.resourceId === projectId);
      let objectToSave = existingSetting ? { ...existingSetting, ...changedObject } : { resource: "PROJECT", resourceId: projectId, type: modalType };
      dispatch(saveUserMailSettingsInfoAsync(objectToSave));
    }
  };

  const changeActiveProject = (projectId: EntityId) => {
    if (projectId === activeProjectId) {
      return;
    }
    dispatch(resetUserProfileModalsData({ closeModal: false }));
    dispatch(setUserMailSettingsModalActiveProjectId(projectId));
  };

  function saveDailyMailTime(changedValue: { time: string }) {
    const { time } = changedValue;
    const hourString = time.split(":")[0];
    let hourInt = hourString[0] === "0" ? parseInt(hourString[1]) : parseInt(hourString);
    const minuteString = time.split(":")[1];
    let minuteInt = minuteString === "00" ? 0 : parseInt(minuteString);
    const userInfoToSave = { ...currentUserInfo, ...{ dailyMailTimeHour: hourInt, dailyMailTimeMinute: minuteInt } };
    dispatch(saveCurrentUserInfoAsync(userInfoToSave));
  }

  return (
    <div className="flex w-1/3 flex-col px-8 pt-8 pb-6">
      {isDailyMailModal && (
        <div className="mb-8">
          <BhInputLabel>{t("USER.INFO.DAILY_MAIL_TIME")}</BhInputLabel>
          <BhCombobox property="time" values={timeValues} initialValue={currentUserDailyMailTime} onSelect={saveDailyMailTime} />
        </div>
      )}
      <div className="text-13px l-h-18px bh-text-deep-ocean-80 mb-4 place-self-start px-0.5 font-semibold">{t("PROFILE.DISABLE_IN_EMAIL.BODY")}</div>
      <div className="self-stretch overflow-auto">
        {projects &&
          projects.map((project) => (
            <ProjectTreeListItemWithCheckbox
              projectId={project.id}
              isChecked={isProjectSelected(project)}
              onChange={selectProject}
              property={"deleted"}
              key={project.id}
              isActive={project.id === activeProjectId}
              onItemClick={changeActiveProject}
              classes={"px-2 my-1 hover:bh-bg-mint "}
            />
          ))}
      </div>
    </div>
  );
};

export default UserMailSettingsModalProjectsContainer;
