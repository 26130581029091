import React, { FC, ReactElement } from "react";
import { CompanyStatus, ICompanyComment } from "@/model/ICompany";
import { BhSectionMessageError, BhSectionMessageSomethingElse, BhSectionMessageSuccess } from "@components/sectionMessage/BhSectionMessages";
import { faRollerCoaster } from "@fortawesome/pro-solid-svg-icons";
import { faSnooze } from "@fortawesome/pro-regular-svg-icons";
import BhDatepickerStackedLabel from "@components/input/BhDatepickerStackedLabel";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boSaveCompanyCommentAsync, boSaveCompanyTrialEndAsync, boSaveCompanyTrialStartAsync, boSelectCompany } from "@/app/store/backofficeSlice";
import { EntityId } from "@reduxjs/toolkit";
import BhInlineInput from "@components/input/BhInlineInput";

export interface IBOCompanyTrialDateDTO {
  companyId: EntityId;
  date: Date;
}

const BOCompanyAndBillingGeneral: FC = () => {
  const company = useAppSelector(boSelectCompany);
  const dispatch = useAppDispatch();

  const statusMap: Record<CompanyStatus, ReactElement> = {
    [CompanyStatus.TRIAL]:
      <BhSectionMessageSomethingElse
        icon={faRollerCoaster}
        classes="bh-bg-warning-yellow-10 bh-border-warning-yellow font-bold"
        iconClasses="bh-text-warning-yellow"
      >
        On trial.
      </BhSectionMessageSomethingElse>,
    [CompanyStatus.TRIAL_EXPIRED]:
      <BhSectionMessageError>
        <span className="font-bold">Trial expired.</span> At least one project created but none of the projects are confirmed
      </BhSectionMessageError>
    ,
    [CompanyStatus.INACTIVE]:
      <BhSectionMessageSomethingElse icon={faSnooze}>
        <span className="font-bold">Inactive.</span> Trial has ended without any projects created.
      </BhSectionMessageSomethingElse>
    ,
    [CompanyStatus.ACTIVE]:
      <BhSectionMessageSuccess>
        <span className="font-bold">Active.</span> At least 1 active project.
      </BhSectionMessageSuccess>
  };

  const handleChangeTrialStart = (changedValue: Record<string, Date>) => {
    const dateWithCompanyId = { companyId: company.id, date: changedValue.trialStart };
    dispatch(boSaveCompanyTrialStartAsync(dateWithCompanyId));
  };

  const handleChangeTrialEnd = (changedValue: Record<string, Date>) => {
    const dateWithCompanyId = { companyId: company.id, date: changedValue.trialEnd };
    dispatch(boSaveCompanyTrialEndAsync(dateWithCompanyId));
  };

  const handleCommentChange = (changedObj: ICompanyComment) => {
    const companyComment = { ...company.comment, ...changedObj, companyId: company.id };
    dispatch(boSaveCompanyCommentAsync(companyComment));
  };

  return (
    <div>
      <h2>General</h2>
      <div className="flex w-full gap-60 mt-4">
        <div className="w-full">
          <p className="font-bold">Status</p>
          {statusMap[company.status]}
          <div className="grid grid-cols-2 gap-4 w-full mt-6">
            <div className="full-w-datepicker">
              <BhDatepickerStackedLabel
                initialValue={new Date(company.trialStart)}
                property="trialStart"
                label="Trial start"
                onChangeCallback={handleChangeTrialStart} />
            </div>
            <div className="full-w-datepicker">
              <BhDatepickerStackedLabel
                initialValue={new Date(company.trialEnd)}
                property="trialEnd"
                label="Trial end"
                onChangeCallback={handleChangeTrialEnd} />
            </div>
          </div>
        </div>
        <div className="w-full">
          <p className="font-bold">Comment</p>
          <BhInlineInput
            initialValue={company?.comment?.comment}
            placeholder="Any comments about the company?"
            property="comment"
            inputClasses="border-none w-full"
            saveCallback={handleCommentChange}
          />
        </div>
      </div>
    </div>
  );
};

export default BOCompanyAndBillingGeneral;