import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { convertToRaw, DraftEntityMutability, EditorState } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin, { defaultTheme, MentionData } from "@draft-js-plugins/mention";
import { IUser } from "@/model/IUser";
import "@draft-js-plugins/mention/lib/plugin.css";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUser } from "@/app/store/userSlice";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import { EntryComponentProps } from "@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry";
import { SubMentionComponentProps } from "@draft-js-plugins/mention/lib/Mention";
import { convertFromHTML } from "draft-convert";

interface Props {
  property: string;
  onChangeCallback?: Function;
  onBlurCallback?: Function;
  placeholder?: string;
  userSuggestions?: Array<IUser>;
  inputClasses?: string;
  initialValue?: string;
}

const BhTextareaWithMentions: FC<Props> = ({ property, onChangeCallback, placeholder, userSuggestions, inputClasses, initialValue, onBlurCallback }) => {
  const createEditorStateWithInitialValue = (initialValue: string): EditorState => {
    const mentionStrategy = (nodeName: string, node: HTMLElement, createEntity: (type: string, mutability: DraftEntityMutability, data: { [key: string]: any }) => string) => {
      if (nodeName === "span" && node.classList.contains("user-mention")) {
        return createEntity("mention", "IMMUTABLE", {
          mention: {
            name: node.textContent,
            id: node.getAttribute("data-mentioned-user-id")
          }
        });
      }
    };

    const contentState = convertFromHTML({
      htmlToEntity: mentionStrategy
    })(initialValue);

    return EditorState.createWithContent(contentState);
  };

  const [editorState, setEditorState] = useState(() => {
    if (initialValue) {
      return createEditorStateWithInitialValue(initialValue);
    }
    return EditorState.createEmpty();
  });
  const currentUser = useAppSelector(selectCurrentUser);
  const ref = useRef<Editor>(null);
  const [open, setOpen] = useState(false);
  const transformUserSuggestions = (users: Array<IUser> | undefined): MentionData[] => {
    return users
      ? users.map((user) => ({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
          link: "",
          avatar: ""
        }))
      : [];
  };
  const mentions = transformUserSuggestions(userSuggestions);
  const [suggestions, setSuggestions] = useState(mentions);

  const CustomMention = (props: SubMentionComponentProps) => {
    return (
      <span className="user-mention" data-id={props.mention.id}>
        {props.children}
      </span>
    );
  };

  const SuggestionEntry: FC<EntryComponentProps> = (props) => {
    const { mention, onMouseDown, onMouseUp, onMouseEnter } = props;
    const user = userSuggestions?.find((user) => mention.id === user.id);
    if (user) {
      return (
        <div className="hover:bh-bg-smoke cursor-pointer rounded">
          <div className="flex flex-row items-center space-x-3 py-1.5 px-4" onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseEnter={onMouseEnter}>
            <BhUserIconWithName user={user} size="sm" disablePopup={true} textClasses="font-normal" />
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionComponent: CustomMention,
      theme: { ...defaultTheme, ...{ mentionSuggestionsPopup: "user-suggestions-dropdown" } }
      // other options
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(
    ({ value }: { value: string }) => {
      const suggestions = filterSuggestions(value, mentions);
      setSuggestions(suggestions);
    },
    [mentions]
  );

  const filterSuggestions = (value: string, mentions: MentionData[]): MentionData[] => {
    return mentions.filter((mention) => mention.name.toLowerCase().includes(value.toLowerCase()));
  };

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    if (onChangeCallback) {
      const contentState = newEditorState.getCurrentContent();
      const rawContent = convertToRaw(contentState);
      const updatedContent = replaceMentionsWithHTML(rawContent);
      onChangeCallback({ [property]: updatedContent });
    }
  };

  const replaceMentionsWithHTML = (rawContent: any): string => {
    const blocks = rawContent.blocks;
    const entityMap = rawContent.entityMap;

    return blocks
      .map((block: any) => {
        let text = block.text;
        const entityRanges = block.entityRanges;

        entityRanges
          .sort((a: any, b: any) => b.offset - a.offset)
          .forEach((range: any) => {
            const entity = entityMap[range.key];
            if (entity.type === "mention") {
              const mentionedUser = entity.data.mention;
              const mentionText =
                '<span class="user-mention" data-mentioned-by-user-id="' +
                currentUser.id +
                '" data-mentioned-user-id="' +
                mentionedUser.id +
                '" contenteditable="false">' +
                mentionedUser.name +
                "</span>&nbsp;";
              text = text.slice(0, range.offset) + mentionText + text.slice(range.offset + range.length);
            }
          });

        return text;
      })
      .join("<br/>");
  };

  const onBlur = () => {
    if (onBlurCallback) {
      const contentState = editorState.getCurrentContent();
      const rawContent = convertToRaw(contentState);
      const updatedContent = replaceMentionsWithHTML(rawContent);
      onBlurCallback({ [property]: updatedContent });
    }
  };

  return (
    <div
      className={`editor ${inputClasses}`}
      onClick={() => {
        ref.current!.focus();
      }}
    >
      <Editor editorKey={"editor"} editorState={editorState} onChange={onEditorStateChange} plugins={plugins} ref={ref} placeholder={placeholder} onBlur={onBlur} />
      <MentionSuggestions open={open} onOpenChange={onOpenChange} suggestions={suggestions} onSearchChange={onSearchChange} entryComponent={SuggestionEntry} />
    </div>
  );
};
export default BhTextareaWithMentions;
