import React from "react";
import { IContact } from "@/model/IContact";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { setContactActionsOverlayOpen, setEditableContact } from "@/app/store/project/projectContactsSlice";
import BhTableRow from "@components/table/BhTableRow";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import ContactActionsDropdown from "@/views/home/project/detail/users/contacts/ContactActionsDropdown";
import { selectAllProjectWorkGroups } from "@/app/store/project/projectWorkGroupsSlice";
import ContactWorkGroupsContainer from "@/views/home/project/detail/users/contacts/ContactWorkGroupsContainer";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/pro-regular-svg-icons/faEyeSlash";

interface Props {
  contact: IContact;
  isSearchView?: boolean;
}

const ContactsTableRowContainer = React.memo<Props>(({ contact, isSearchView }) => {
  const projectWorkGroups = useAppSelector(selectAllProjectWorkGroups);
  const contactWorkGroups = projectWorkGroups.filter((wg) => typeof wg.id === "number" && contact.workGroupIds?.includes(wg.id));
  const projectId = useAppSelector(selectCurrentProjectId);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const dispatch = useAppDispatch();

  const showContactWorkGroupDetailsAndContactActions = !isSearchView;

  if (!contact) {
    return null;
  }

  const openContactActionsOverlay = () => {
    dispatch(setEditableContact(contact as IContact));
    dispatch(setContactActionsOverlayOpen(true));
  };

  return (
    <BhTableRow classes={classNames(contact.hidden && "bh-bg-smoke-50 bh-text-pigeon")}>
      <td className="p-0-imp flex-col align-top">
        <div className="my-[13.5px] ml-3">
          <BhUserIconWithName user={contact} />
        </div>
      </td>
      <td className="align-top">
        <div className={contact.email && contact.phone ? "mt-[11px]" : "mt-[19px]"}>
          <a className="bh-underline-link font-weight-600" href={"mailto:" + contact.email}>
            {contact.email}
          </a>
          <div>{contact.phone}</div>
        </div>
      </td>
      <td className="align-top">
        <div className={contact.position && contact.company ? "mt-[11px]" : "mt-[19px]"}>
          <div className="font-weight-700">{contact.position}</div>
          <div
            onClick={() => {
              openContactActionsOverlay();
            }}
          >
            {contact.company}
          </div>
        </div>
      </td>
      {isProjectAdmin && showContactWorkGroupDetailsAndContactActions && (
        <td className="bh-text-deep-ocean-80 font-normal">
          <ContactWorkGroupsContainer workGroups={contactWorkGroups} />
        </td>
      )}
      <td className="w-32 text-right">
        {isProjectAdmin && showContactWorkGroupDetailsAndContactActions && (
          <div className="flex flex-row items-center justify-end">
            {contact.hidden && <FontAwesomeIcon icon={faEyeSlash} className={"bh-text-deep-ocean pr-2"} aria-hidden="true" />}
            <ContactActionsDropdown contact={contact} projectId={projectId} />
          </div>
        )}
      </td>
    </BhTableRow>
  );
});

export default ContactsTableRowContainer;
