import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BhOwnSignatureStatusType } from "@components/container/BhSignatureStatusType";
import { ISignInvite } from "@/model/ISignInvite";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import { Trans } from "react-i18next";

interface Props {
  signInvite?: ISignInvite;
  signatureHolder?: ISignatureHolder;
}

const BhSignatureContainerStatusForUser: FC<Props> = ({ signInvite, signatureHolder }) => {
  let status;

  if (signInvite) {
    if (signInvite.signed) {
      status = BhOwnSignatureStatusType.SIGNED;
    } else if (signInvite.declined) {
      status = BhOwnSignatureStatusType.REFUSED;
    } else if (signInvite.orderNumber && !signInvite.nextInLine) {
      status = BhOwnSignatureStatusType.IN_LINE;
    } else {
      status = BhOwnSignatureStatusType.PENDING;
    }
  }
  if (signatureHolder) {
    if (!signatureHolder.isUnsignedInvite) {
      if (signatureHolder.isValid) {
        status = BhOwnSignatureStatusType.SIGNED;
      } else {
        status = BhOwnSignatureStatusType.SIGNED_NOT_VALID;
      }
    } else if (signatureHolder.declined) {
      status = BhOwnSignatureStatusType.REFUSED;
    } else if (signatureHolder.orderNumber && !signatureHolder.nextInLine) {
      status = BhOwnSignatureStatusType.IN_LINE;
    } else {
      status = BhOwnSignatureStatusType.PENDING;
    }
  }

  if (!status) {
    return null;
  }

  return (
    <div className="text-13px flex inline-flex h-6 flex-row items-center rounded px-1 font-bold">
      <FontAwesomeIcon icon={status.icon} className={"h-4 pr-1.5 " + status.iconColor} aria-hidden="true" />
      <span className="pl-1 pr-2">
        <Trans>{status.translateCode}</Trans>
      </span>
    </div>
  );
};

export default BhSignatureContainerStatusForUser;
