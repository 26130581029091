import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  value: boolean;
  onClickAction?: Function;
  small?: boolean;
  property?: string;
  disabled?: boolean;
}

const BhToggleSwitch: FC<Props> = ({ value, onClickAction, small, property, disabled }) => {
  let dimensionClasses = "w-11 h-6 min-w-11 min-h-6";
  if (small) {
    dimensionClasses = "w-8 h-5 min-w-8 min-h-5";
  }

  const onClick = () => {
    if (disabled) return;
    if (property) {
      const changedObject = { [property]: !value };
      onClickAction && onClickAction(changedObject);
    } else {
      onClickAction && onClickAction();
    }
  };

  const enabledAndTrue = !disabled && value;
  const enabledAndFalse = !disabled && !value;

  return (
    <div
      className={classNames(
        dimensionClasses,
        disabled ? "bh-bg-pigeon-40 cursor-not-allowed" : "cursor-pointer",
        enabledAndTrue && "bh-bg-success-green hover:bh-bg-success-green-110",
        enabledAndFalse && "bh-bg-pigeon-50 hover:bh-bg-pigeon-60",
        "relative rounded-full transition"
      )}
      onClick={onClick}
    >
      {!small && (
        <FontAwesomeIcon
          icon={value ? faCheck : faTimes}
          size="lg"
          className={classNames("absolute top-1 content-center", value ? "left-1.5" : "right-1.5", !disabled && !value ? "bh-text-deep-ocean-80" : "bh-text-white")}
        />
      )}
      <div className={classNames(disabled ? "bh-bg-smoke" : "bh-bg-white", value ? "right-1" : "left-1", small ? "h-3 w-3" : "h-4 w-4", "dot absolute top-1 rounded-full")} />
    </div>
  );
};

export default BhToggleSwitch;
