import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  numberOfRows: number;
}

const formBaseInfoPdfModalRowsPlaceholder: FC<Props> = ({ numberOfRows }) => {
  return (
    <div>
      {[...Array(numberOfRows)].map((e, i) => {
        return (
          <div className={classNames("bh-border-pigeon-40 flex w-full flex-col gap-y-3 border p-3", i !== numberOfRows - 1 && "border-b-0")} key={i}>
            <div className={classNames("bh-bg-pigeon-20 h-2.5", i % 2 ? "w-48" : "w-28")}></div>
            <div className={classNames("bh-bg-pigeon-20 h-4.5 max-w-full", i % 2 ? "w-[500px]" : "w-64")}></div>
          </div>
        );
      })}
    </div>
  );
};

export default formBaseInfoPdfModalRowsPlaceholder;
