import React, { FC, useEffect, useState } from "react";
import { findVatNumberCompany } from "@/api/billingAPI";
import { IProjectBillingRequisites } from "@/model/IBillingInfo";
import BhSpinner from "@components/spinner/BhSpinner";
import BhTooltip from "@components/BhTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "@/utilities/jsUtilities";
import { faOctagonExclamation } from "@fortawesome/pro-solid-svg-icons/faOctagonExclamation";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faQuestion } from "@fortawesome/pro-solid-svg-icons/faQuestion";
import { useTranslation } from "react-i18next";

interface Props {
  vatNumber: string;
  customerRecipient: string;
  onChangeHandler: Function;
}

const BillingFormVatValidator: FC<Props> = ({ vatNumber, customerRecipient, onChangeHandler }) => {
  const { t } = useTranslation();
  const [isVatInfoLoading, setIsVatInfoLoading] = useState<boolean>(false);
  const [vatInfo, setVatInfo] = useState<{ name: string; valid: boolean } | false | undefined>({} as { name: string; valid: boolean });

  useEffect(() => {
    if (!vatNumber || vatNumber === "") {
      setVatInfo(undefined);
    } else {
      setIsVatInfoLoading(true);
      findVatNumberCompany(vatNumber).then(
        (res) => {
          setVatInfo({ name: res.name, valid: res.valid });
          if (res.valid && res.nameSpecified && (!customerRecipient || customerRecipient === "")) {
            onChangeHandler({ customerRecipient: res.name } as IProjectBillingRequisites);
          }
          setIsVatInfoLoading(false);
        },
        () => {
          setVatInfo(false);
          setIsVatInfoLoading(false);
        }
      );
    }
  }, [vatNumber]);

  return (
    <>
      {vatNumber?.length > 0 && (
        <div className="absolute bottom-0 right-0 flex h-9 w-9 items-center justify-center">
          {isVatInfoLoading ? (
            <BhSpinner />
          ) : (
            <BhTooltip body={!vatInfo ? (t("BILLING.VAT_ERROR") as string) : !vatInfo.valid ? (t("BILLING.VAT_NOT_VALID") as string) : ""}>
              <FontAwesomeIcon
                className={classNames(vatInfo === false || !vatInfo?.valid ? "bh-text-error-red cursor-pointer" : "bh-text-success-green")}
                icon={vatInfo === false ? faOctagonExclamation : vatInfo?.valid ? faCheck : faQuestion}
              />
            </BhTooltip>
          )}
        </div>
      )}
    </>
  );
};

export default BillingFormVatValidator;
