import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IBauhubEvent } from "@/model/IBauhubEvent";
import { IFileEntity } from "@/model/files/IFileEntity";
import { IProjectLogFilter, IProjectLogFilterOptions } from "@/model/logs/IProjectLogFilter";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { fetchProjectLog } from "@/api/projectAPI";
import ProjectLogTable from "@/views/home/project/detail/projectLog/ProjectLogTable";
import BhPagination from "@components/pagination/BhPagination";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhFilterContainer from "@components/filters/BhFilterContainer";
import { debounce } from "lodash";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { restoreFileFromEventLog } from "@/api/fileAPI";
import BhSelectInputFilter from "@components/filters/BhSelectInputFilter";
import { fullPageLoadingSet } from "@/app/store/globalSlice";

interface Props {
  directory: IFileEntity;
}

const DirectoryLogContainer: FC<Props> = ({ directory }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const [logs, setLogs] = useState([] as Array<IBauhubEvent>);
  const [loading, setLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState({} as IProjectLogFilterOptions);
  const [filter, setFilter] = useState({
    isContract: false,
    limit: 20,
    page: 0,
    total: 0,
    orderBy: "created",
    sortOrderAsc: false,
    parentFileEntityId: directory.id
  } as IProjectLogFilter);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (directory) {
      setFilter({ ...filter, page: 1 });
    }
  }, [directory]);

  useEffect(() => {
    if (filter.page > 0) {
      fetchLogsByFilter(filter);
    }
  }, [filter.page, filter.fileName, filter.type, filter.username, filter.orderBy, filter.sortOrderAsc]);

  const fetchLogsByFilter = (filterObj: IProjectLogFilter) => {
    setLoading(true);
    fetchProjectLog(projectId, filterObj).then((res) => {
      setLogs(res.list);
      setFilter({ ...filterObj, total: res.total });
      const filterOptions = {
        types: res.filterOptions.types.map((type) => {
          return { name: type, selected: false };
        }),
        usernames: res.filterOptions.usernames.map((username) => {
          return { name: username, selected: false };
        })
      } as IProjectLogFilterOptions;
      setFilterOptions(filterOptions);
      setLoading(false);
    });
  };

  const changePage = useCallback(
    (page: number) => {
      setFilter({ ...filter, page: page });
    },
    [filter]
  );

  const onFileNameFilterChange = useCallback(
    debounce((filterObject) => {
      setFilter({ ...filter, ...{ page: 1, fileName: filterObject.fileName } });
    }, 400),
    [filter]
  );

  const onLogTypeFilterSelect = useCallback(
    (type: any) => {
      setFilter({ ...filter, ...{ page: 1, type: type } });
    },
    [filter]
  );

  const onLogTypeFilterReset = useCallback(() => {
    let newFilter = { ...filter };
    delete newFilter.type;
    setFilter(newFilter);
  }, [filter]);

  const onLogUsernameFilterSelect = useCallback(
    (username: any) => {
      setFilter({ ...filter, ...{ page: 1, username: username } });
    },
    [filter]
  );

  const onLogUsernameFilterReset = useCallback(() => {
    let newFilter = { ...filter };
    delete newFilter.username;
    setFilter(newFilter);
  }, [filter]);

  const onTableHeaderClickCallback = useCallback(
    (column: IBhTableHeader<IBauhubEvent>) => {
      if (column.field) {
        if (filter.orderBy === column.field) {
          setFilter({ ...filter, ...{ page: 1, sortOrderAsc: !filter.sortOrderAsc } });
        } else {
          setFilter({ ...filter, ...{ page: 1, orderBy: column.field } });
        }
      }
    },
    [filter]
  );

  const restoreFile = useCallback(
    (log: IBauhubEvent) => {
      dispatch(fullPageLoadingSet(true));
      return restoreFileFromEventLog(log.id)
        .then(() => {
          fetchLogsByFilter(filter);
        })
        .finally(() => {
          dispatch(fullPageLoadingSet(false));
        });
    },
    [filter]
  );

  const logTypes = ["NEW_FILE", "FILE_DELETED", "FILE_RENAMED", "FILE_MOVED_TO", "FILE_NEW_REVISION", "FILE_REVISION_ROLLBACK", "FILE_UNDO_DELETE", "FILE_COMMENT"];

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <div>
        <BhFilterContainer>
          <div>
            <BhSearchInputWBG initialValue={filter.fileName} property="fileName" onChangeCallback={onFileNameFilterChange} placeholder={t("LOG.SEARCH_FILENAME_PLACEHOLDER") as string} />
          </div>
          <BhSelectInputFilter
            values={logTypes}
            currentValue={filter.type as string}
            onSelect={onLogTypeFilterSelect}
            placeholder={t("LOG.TYPE_OF_CHANGE")}
            translateValues={true}
            onReset={onLogTypeFilterReset}
          />
          {filterOptions && filterOptions.usernames?.length > 0 && (
            <BhSelectInputFilter
              values={filterOptions.usernames.map((u: { name: string }) => u.name)}
              currentValue={filter?.username}
              onSelect={onLogUsernameFilterSelect}
              placeholder={t("LOG.CHANGED_BY")}
              onReset={onLogUsernameFilterReset}
            />
          )}
        </BhFilterContainer>
      </div>
      <div className="bh-text-deep-ocean-80 mb-2 pl-3">{t("FILE_INFORMATION.FILES") + " - " + filter.total}</div>
      <div className="flex flex-1 overflow-auto">
        <ProjectLogTable
          logs={logs}
          sortedBy={filter.orderBy}
          sortReversed={filter.sortOrderAsc as boolean}
          tableHeaderOnClick={onTableHeaderClickCallback}
          logsLoading={loading}
          restoreFileCallback={restoreFile}
        />
      </div>
      <div className="flex">{filter.total > filter.limit && <BhPagination total={filter.total} limit={filter.limit} page={filter.page} changePageClicked={changePage} />}</div>
    </div>
  );
};

export default DirectoryLogContainer;
