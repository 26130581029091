import React, { FC, useCallback } from "react";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { useNavigate } from "react-router-dom";
import { classNames } from "@/utilities/jsUtilities";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { setCurrentImage, setLightboxCanRename, setLightboxFileIds, toggleLightboxOpen } from "@/app/store/lightboxSlice";
import { canOpenInPdftron, canPreviewDwgContentType, isImage } from "@/utilities/fileEntity/fileEntityUtilities";
import { fetchUrlForFile } from "@/api/fileAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import FileNameWrapped from "@/features/fileContainer/FileNameWrapped";
import { selectCurrentUserDontOpenCADFiles } from "@/app/store/userSlice";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  fileEntity: IFileEntity;
  openInNewWindow?: boolean;
  withCreatorName?: boolean;
  disableOnClickHandler?: boolean;
  onFolderClickCallback?: Function;
  onFileNameClickCallback?: Function;
  downloadContainers?: boolean;
  openInNewTab?: boolean;
  currentState?: string;
  attachmentFileIds?: Array<EntityId>;
  canRename?: boolean;
}

const FileNameLink: FC<Props> = ({
  fileEntity,
  withCreatorName,
  disableOnClickHandler,
  onFolderClickCallback,
  onFileNameClickCallback,
  downloadContainers,
  openInNewTab,
  currentState,
  attachmentFileIds,
  canRename
}) => {
  const navigate = useNavigate();
  const dontOpenCadFilesForUser = useAppSelector(selectCurrentUserDontOpenCADFiles);
  const dispatch = useAppDispatch();

  const openBauhubView = (url: string, ctrlButtonPressed?: boolean) => {
    if (openInNewTab || ctrlButtonPressed) {
      window.open(url, "_blank");
      return;
    }

    const isCompanyLevelFile = Boolean(fileEntity.companyId);
    const optionsWithCurrentState = { state: { previousState: currentState, fileEntity: isCompanyLevelFile ? fileEntity : null } };
    const optionsWithoutCurrentState = isCompanyLevelFile ? { state: { fileEntity: fileEntity } } : undefined;
    const navigateOptions = currentState ? optionsWithCurrentState : optionsWithoutCurrentState;

    navigate(url, navigateOptions);
  };

  const fileClickAction = (ctrlButtonPressed: boolean) => {
    if (onFileNameClickCallback) {
      return onFileNameClickCallback(fileEntity);
    }
    if (isImage(fileEntity)) {
      return lightboxOpenAction();
    }
    if (canOpenInPdftron(fileEntity)) {
      if (canPreviewDwgContentType(fileEntity) && dontOpenCadFilesForUser) {
        return fetchUrlForFile(fileEntity.id, false, true, fileEntity.uuid).then((presignedUrl) => {
          imitateUrlDownload(presignedUrl.value);
        });
      }

      const isCompanyLevelFile = Boolean(fileEntity.companyId);
      const projectPdfTronUrl = ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${fileEntity.projectId}/view/${fileEntity.id}/${fileEntity.uuid || ""}`;
      const companyPdfTronUrl = ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/view`;
      const urlToUse = isCompanyLevelFile ? companyPdfTronUrl : projectPdfTronUrl;
      return openBauhubView(urlToUse, ctrlButtonPressed);
    }
    return fetchUrlForFile(fileEntity.id, false, true, fileEntity.uuid).then((presignedUrl) => {
      imitateUrlDownload(presignedUrl.value);
    });
  };

  const lightboxOpenAction = async () => {
    if (attachmentFileIds) {
      dispatch(setLightboxFileIds(attachmentFileIds));
    }
    dispatch(setLightboxCanRename(canRename));
    dispatch(toggleLightboxOpen());
    dispatch(setCurrentImage(fileEntity));
  };

  const folderClickAction = (ctrlButtonPressed: boolean) => {
    if (onFolderClickCallback) {
      return onFolderClickCallback(fileEntity);
    }
    return openBauhubView(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${fileEntity.projectId}/dir/${fileEntity.id}`, ctrlButtonPressed);
  };

  const formClickAction = (ctrlButtonPressed: boolean) => {
    if (onFileNameClickCallback) {
      return onFileNameClickCallback(fileEntity);
    }
    return openBauhubView(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${fileEntity.projectId}/dir/${fileEntity.parentFileEntityId}/form/${fileEntity.id}`, ctrlButtonPressed);
  };

  const containerClickAction = (ctrlButtonPressed: boolean) => {
    if (onFileNameClickCallback) {
      return onFileNameClickCallback(fileEntity);
    }
    if (downloadContainers) {
      return fetchUrlForFile(fileEntity.id, false, true, fileEntity.uuid).then((presignedUrl) => {
        imitateUrlDownload(presignedUrl.value);
      });
    }
    return openBauhubView(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${fileEntity.projectId}/container/${fileEntity.id}`, ctrlButtonPressed);
  };

  // @ts-ignore
  const clickActionMap: Record<FileEntityType, Function> = {
    [FileEntityType.FILE]: fileClickAction,
    [FileEntityType.DOCUMENT]: fileClickAction,
    [FileEntityType.ATTACHMENT]: fileClickAction,
    [FileEntityType.ACT_PDF]: fileClickAction,
    [FileEntityType.CONFIRMATION_PDF]: fileClickAction,
    [FileEntityType.DIR]: folderClickAction,
    [FileEntityType.ROOT_DIR]: folderClickAction,
    [FileEntityType.ROOT_DOCUMENT_DIR]: folderClickAction,
    [FileEntityType.CONTAINER]: containerClickAction,
    [FileEntityType.ATTACHMENT_CONTAINER]: containerClickAction,
    [FileEntityType.FORM]: formClickAction
  };

  const handleFileNameClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (disableOnClickHandler) return;
      e.stopPropagation();
      if (e.ctrlKey || e.metaKey) {
        clickActionMap[fileEntity.type](true);
        return;
      }
      clickActionMap[fileEntity.type]();
    },
    [onFolderClickCallback, onFileNameClickCallback, disableOnClickHandler, attachmentFileIds]
  );

  if (!fileEntity) {
    return null;
  }

  return (
    <span className={classNames(withCreatorName && "text-14px font-bold", "filename")}>
      <a onClick={handleFileNameClick} className={classNames("filename", !disableOnClickHandler && "cursor-pointer hover:underline")}>
        <FileNameWrapped fileName={fileEntity.name} />
      </a>
    </span>
  );
};

export default FileNameLink;
