import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";

interface Props {
  saveCallback: Function;
  currentValue: string;
}

const FormBaseTextFieldInputDropdown: FC<Props> = ({ saveCallback, currentValue }) => {
  const { t } = useTranslation();

  return (
    <div className="bh-bg-white bh-shadow bh-bg-raised-white absolute right-0 z-50 mt-1 inline-block flex h-fit w-52 flex-col whitespace-nowrap rounded py-3 text-left align-top">
      <div className="hover:bh-bg-smoke bh-text-pigeon text-14px flex cursor-pointer flex-row items-center gap-x-2 py-1.5 px-4" onClick={() => saveCallback(false, "")}>
        <div className={!currentValue ? "opacity-100" : "opacity-0"}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
        {t("FORMBUILDER.FORM.BASE.FIELD.TEXT.NO_PREFILL")}
      </div>
      <div className="bh-text-pigeon text-12px mt-4 py-1.5 pl-[38px] pr-4 font-bold">{t("FORMBUILDER.FORM.BASE.FIELD.TEXT.PREFILL.PROJECT_INFO")}</div>
      <div className="hover:bh-bg-smoke bh-text-deep-ocean text-14px flex cursor-pointer flex-row items-center gap-x-2 py-1.5 px-4" onClick={() => saveCallback(true, "projectName")}>
        <div className={currentValue === "projectName" ? "opacity-100" : "opacity-0"}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
        <div>{t("FORMBUILDER.FORM.BASE.FIELD.TEXT.PREFILL.PROJECT_NAME")}</div>
      </div>
      <div className="hover:bh-bg-smoke bh-text-deep-ocean text-14px flex cursor-pointer flex-row items-center gap-x-2 py-1.5 px-4" onClick={() => saveCallback(true, "projectAddress")}>
        <div className={currentValue === "projectAddress" ? "opacity-100" : "opacity-0"}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
        {t("FORMBUILDER.FORM.BASE.FIELD.TEXT.PREFILL.PROJECT_ADDRESS")}
      </div>
      <div className="hover:bh-bg-smoke bh-text-deep-ocean text-14px flex cursor-pointer flex-row items-center gap-x-2 py-1.5 px-4" onClick={() => saveCallback(true, "projectContractNumber")}>
        <div className={currentValue === "projectContractNumber" ? "opacity-100" : "opacity-0"}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
        {t("FORMBUILDER.FORM.BASE.FIELD.TEXT.PREFILL.PROJECT_CONTRACT_NR")}
      </div>
      <div className="bh-text-pigeon text-12px mt-4 py-1.5 pl-[38px] pr-4 font-bold">{t("FORMBUILDER.FORM.BASE.FIELD.TEXT.PREFILL.COMPANY_INFO")}</div>
      <div className="hover:bh-bg-smoke bh-text-deep-ocean text-14px flex cursor-pointer flex-row items-center gap-x-2 py-1.5 px-4" onClick={() => saveCallback(true, "companyName")}>
        <div className={currentValue === "companyName" ? "opacity-100" : "opacity-0"}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
        {t("FORMBUILDER.FORM.BASE.FIELD.TEXT.PREFILL.COMPANY_NAME")}
      </div>
      <div className="bh-text-pigeon text-12px mt-4 py-1.5 pl-[38px] pr-4 font-bold">{t("FORMBUILDER.FORM.BASE.FIELD.TEXT.PREFILL.USER_INFO")}</div>
      <div className="hover:bh-bg-smoke bh-text-deep-ocean text-14px flex cursor-pointer flex-row items-center gap-x-2 py-1.5 px-4" onClick={() => saveCallback(true, "createdByName")}>
        <div className={currentValue === "createdByName" ? "opacity-100" : "opacity-0"}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
        {t("FORMBUILDER.FORM.BASE.FIELD.TEXT.PREFILL.USER_NAME")}
      </div>
    </div>
  );
};

export default FormBaseTextFieldInputDropdown;
