import React from "react";
import BOCompanyModulesTemplatesMenu from "@components/backoffice/company/modulesAndTemplates/BOCompanyModulesTemplatesMenu";
import BOCompanyModulesTemplates from "@components/backoffice/company/modulesAndTemplates/BOCompanyModulesTemplates";

const BOCompanyModulesTemplatesTab = () => {
  return (
    <div className="relative m-auto flex w-full max-w-[1500px] gap-10 px-44 pt-10 pb-72">
      <div className="w-1/4">
        <BOCompanyModulesTemplatesMenu />
      </div>
      <BOCompanyModulesTemplates />
    </div>
  );
};

export default BOCompanyModulesTemplatesTab;
