import React, { FC } from "react";
import { IShareboxUrl } from "@/model/shareboxes/IShareboxUrl";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import { selectShareboxById } from "@/app/store/shareboxesSlice";
import BhLargeText from "@components/text/BhLargeText";

interface Props {
  shareboxId: EntityId;
  shareboxUrl?: IShareboxUrl;
}

const PublicShareboxInfoHeader: FC<Props> = ({ shareboxId, shareboxUrl }) => {
  const sharebox = useAppSelector((state) => selectShareboxById(state, shareboxId));

  if (!sharebox) {
    return null;
  }

  return (
    <div className="bh-bg-mint flex w-full">
      <div className="mx-auto flex w-full max-w-[1366px] flex-col items-start space-y-4 px-6 pt-4 pb-2">
        <h1>{sharebox?.name}</h1>
        <div className="w-1/2">
          <BhLargeText>{shareboxUrl?.message}</BhLargeText>
        </div>
      </div>
    </div>
  );
};

export default PublicShareboxInfoHeader;
