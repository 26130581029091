import React from "react";

export declare type FieldValues = Record<string, any>;
export declare type SubmitErrorHandler<TFieldValues extends FieldValues> = (errors: any, event?: React.BaseSyntheticEvent) => any | Promise<any>;

const useHandleFormSubmit =
  <TFieldValues extends Record<keyof TFieldValues, any>>() =>
  (onValid: (data: TFieldValues, event?: React.BaseSyntheticEvent) => any | Promise<any>, onInvalid?: SubmitErrorHandler<TFieldValues>) =>
  async (e?: React.FormEvent<HTMLFormElement>) => {
    if (!e) {
      if (onInvalid) {
        await onInvalid({}, e);
      }
      return;
    }

    e.preventDefault();
    const elements = e.currentTarget.elements;
    const formValues = {} as TFieldValues;
    Array.prototype.forEach.call(elements, (element: HTMLInputElement) => {
      if (element.type === "text" || element.type === "textarea") {
        // @ts-ignore
        formValues[element.name] = element.value;
      }
    });

    await onValid(formValues, e);
  };

export default useHandleFormSubmit;
