import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import BhMenuListItem from "@components/nav/BhMenuListItem";
import { faCashRegister, faEyes, faFort, faHandHorns, faHelmetSafety, faPeach, faPersonFromPortal, faPuzzlePieceSimple, faRollerCoaster, faSkull, faWorm } from "@fortawesome/pro-solid-svg-icons";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import React from "react";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons";

const BauhubBOHome = () => {
  return (
    <BhScrollableBody>
      <div className="px-16 pt-6">
        <h1>Admin</h1>
        <div className="flex flex-row sm:flex-col">
          <div className="w-72">
            <h2>Clients</h2>
          </div>
          <div className="w-640px sm:w-full">
            <BhMenuListItem url={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "companies"} iconColor="bh-bg-success-green-20" icon={faFort} linkName="Companies" />
            <BhMenuListItem url={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "projects"} iconColor="bh-bg-pigeon-30" icon={faBuilding} linkName="Projects" />
            <BhMenuListItem url={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "users"} iconColor="bh-bg-warning-yellow-20" icon={faHelmetSafety} linkName="Users" />
            <BhMenuListItem url={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "invoices"} iconColor="bh-bg-error-red-10" icon={faCashRegister} linkName="Invoices" />
            <BhMenuListItem url={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "trials"} iconColor="bh-bg-mint" icon={faRollerCoaster} linkName="Trials" />
          </div>
        </div>
        <div className="mt-12 flex flex-row sm:flex-col">
          <div className="w-72">
            <h2>Developer</h2>
          </div>
          <div className="w-640px sm:w-full">
            <BhMenuListItem url={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "backend"} iconColor="bh-bg-royal-blue-light" icon={faPeach} linkName="Backend" />
            <BhMenuListItem url={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "template-developer"} iconColor="bh-bg-eggplant-10" icon={faPuzzlePieceSimple}
                            linkName="Template developer ™" />
            <BhMenuListItem url={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "form-developer"} iconColor="bh-bg-pigeon-30" icon={faWorm} linkName="Form developer" />
            <BhMenuListItem url={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "events"} iconColor="bh-bg-mint-110" icon={faHandHorns} linkName="Events" />
            <BhMenuListItem url={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "baudrive-logs"} iconColor="bh-bg-mediterranean-10" icon={faEyes} linkName="Baudrive logs" />
            <BhMenuListItem url={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "killswitch"} iconColor="bh-bg-error-red-20" icon={faSkull} linkName="Killswitch" />
            <BhMenuListItem url={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "asyncjobs"} iconColor="bh-bg-warning-yellow-20" icon={faPersonFromPortal} linkName="Asyncjobs" />
          </div>
        </div>
      </div>
    </BhScrollableBody>
  );
};

export default BauhubBOHome;
