import React, { FC, useEffect } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectFilesStatus, selectFilteredAndSortedFileEntityIdsForDirectory } from "@/app/store/filesSlice";
import FileContainerSkeleton from "@/features/fileContainer/FileContainerSkeleton";
import { EntityId } from "@reduxjs/toolkit";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { IAuthorityBoolean } from "@/model/IUser";
import FileContainer from "@/features/fileContainer/FileContainer";
import DirectoryFileListContainerEmpty from "@/views/home/project/detail/directory/DirectoryFileListContainerEmpty";
import DirectoryListScrollContainer from "@/views/home/project/detail/userNotifications/DirectoryListScrollContainer";
import { CustomDragLayer } from "@/features/fileContainer/CustomDragLayer";
import { useLocation } from "react-router-dom";
import { urlParamsToObject } from "@/utilities/jsUtilities";
import { selectBimDirectoryId } from "@/app/store/project/projectSlice";
import BimFileContainer from "@/features/fileContainer/bim/BimFileContainer";

interface Props {
  currentDirId: EntityId;
  privilegesForDirectory: IAuthorityBoolean;
}

const DirectoryFileListContainer: FC<Props> = ({ currentDirId, privilegesForDirectory }) => {
  const fileIds = useAppSelector((state) => selectFilteredAndSortedFileEntityIdsForDirectory(state, currentDirId));
  const status = useAppSelector(selectFilesStatus);
  const projectBimDirectoryId = useAppSelector(selectBimDirectoryId);
  const isCurrentDirectoryBimDir = projectBimDirectoryId === currentDirId;
  const location = useLocation();
  const { urlHighlight } = urlParamsToObject(location.search);
  const { highlight } = location.state || ({ highlight: undefined } as any);

  const highlightId = highlight || urlHighlight;

  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, []);

  return (
    <>
      {status === BhStateStatusType.PENDING && (
        <div>
          <FileContainerSkeleton />
          <FileContainerSkeleton />
          <FileContainerSkeleton />
        </div>
      )}
      {status === BhStateStatusType.SUCCESS && (
        <>
          {fileIds.length > 0 && (
            <DirectoryListScrollContainer
              currentDirId={currentDirId}
              items={fileIds.map((fileId) => {
                return isCurrentDirectoryBimDir ? (
                  <BimFileContainer fileId={fileId} key={fileId} privilegesForDirectory={privilegesForDirectory} highlighted={highlightId ? highlightId + "" === fileId + "" : undefined} />
                ) : (
                  <FileContainer fileId={fileId} key={fileId} privilegesForDirectory={privilegesForDirectory} highlighted={highlightId ? highlightId + "" === fileId + "" : undefined} />
                );
              })}
              highlight={urlHighlight}
            />
          )}
          <DirectoryFileListContainerEmpty currentDirId={currentDirId} />
        </>
      )}
      <CustomDragLayer />
    </>
  );
};

export default DirectoryFileListContainer;
