import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import companiesReducer from "@/app/store/companiesSlice";
import projectReducer from "@/app/store/project/projectSlice";
import sidebarReducer from "@/app/store/sidebarSlice";
import filesReducer from "@/app/store/filesSlice";
import uploadReducer from "@/app/store/uploadSlice";
import signInvitesReducer from "@/app/store/signInvitesSlice";
import signaturesReducer from "@/app/store/signaturesSlice";
import foldersReducer from "@/app/store/foldersSlice";
import userReducer from "@/app/store/userSlice";
import tasksReducer from "@/app/store/tasksSlice";
import userNotificationsReducer from "@/app/store/userNotificationsSlice";
import taskBoardsReducer from "@/app/store/taskBoardsSlice";
import formReducer from "@/app/store/form/formSlice";
import formBaseRowsReducer from "@/app/store/form/formBaseRowsSlice";
import formBasesReducer from "@/app/store/form/formBasesSlice";
import actsReducer from "@/app/store/actsSlice";
import actWorksReducer from "@/app/store/actWorksSlice";
import contractsReducer from "@/app/store/contractsSlice";
import contractWorksReducer from "@/app/store/contractWorksSlice";
import userPreferenceReducer from "@/app/store/userPreferenceSlice";
import checklistsReducer from "@/app/store/checklist/checklistsSlice";
import checklistRowsReducer from "@/app/store/checklist/checklistRowsSlice";
import checklistBaseRowsReducer from "@/app/store/checklist/checklistBaseRowsSlice";
import shareboxesReducer from "@/app/store/shareboxesSlice";
import asyncJobsReducer from "@/app/store/asyncJobsSlice";
import lightboxReducer from "@/app/store/lightboxSlice";
import fileConfirmationsReducer from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import fileConfirmationFilesReducer from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import fileAnnotationsReducer from "@/app/store/fileAnnotationsSlice";
import globalReducer from "@/app/store/globalSlice";
import searchReducer from "@/app/store/searchSlice";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import backofficeReducer from "@/app/store/backofficeSlice";

// initialize an empty api service that we'll inject endpoints into later as needed
export const bauhubApi = createApi({
  reducerPath: "bauhubApi",
  baseQuery: fetchBaseQuery({ baseUrl: ConfigSingleton.getInstance().getConfig().REACT_APP_API_URL }),
  endpoints: () => ({})
});

export const store = configureStore({
  reducer: {
    companies: companiesReducer,
    // @ts-ignore
    project: projectReducer,
    sidebar: sidebarReducer,
    signInvites: signInvitesReducer,
    files: filesReducer,
    folders: foldersReducer,
    upload: uploadReducer,
    signatures: signaturesReducer,
    user: userReducer,
    tasks: tasksReducer,
    taskBoards: taskBoardsReducer,
    form: formReducer,
    formBases: formBasesReducer,
    formBaseRows: formBaseRowsReducer,
    acts: actsReducer,
    actWorks: actWorksReducer,
    contracts: contractsReducer,
    contractWorks: contractWorksReducer,
    checklists: checklistsReducer,
    checklistRows: checklistRowsReducer,
    checklistBaseRows: checklistBaseRowsReducer,
    userPreference: userPreferenceReducer,
    shareboxes: shareboxesReducer,
    asyncJobs: asyncJobsReducer,
    lightbox: lightboxReducer,
    fileConfirmations: fileConfirmationsReducer,
    fileConfirmationFiles: fileConfirmationFilesReducer,
    userNotifications: userNotificationsReducer,
    fileAnnotations: fileAnnotationsReducer,
    global: globalReducer,
    search: searchReducer,
    backoffice: backofficeReducer,
    [bauhubApi.reducerPath]: bauhubApi.reducer
  },
  // @ts-ignore
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(bauhubApi.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
