import React, { useEffect } from "react";
import { BauhubXeokitTool } from "@/views/home/project/detail/xeokit/XeokitWebViewer";

interface Props {
  setBauhubToolActive: Function;
}

const XeokitEscButtonTool = React.memo<Props>(({ setBauhubToolActive }) => {
  const handleKeyPress = (event: any) => {
    if (event && event.keyCode === 27) {
      setBauhubToolActive(BauhubXeokitTool.SELECT);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return null;
});

export default XeokitEscButtonTool;
