import React, { FC, ReactElement } from "react";
import { ChecklistRowFieldType, IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import { useAppSelector } from "@/app/hooks";
import { selectChecklistRowByBaseRowId } from "@/app/store/checklist/checklistRowsSlice";
import BhDatePicker from "@components/input/BhDatePicker";
import BhTimePicker from "@components/input/BhTimePicker";
import BhTextareaSimple from "@components/input/BhTextareaSimple";
import ChecklistRowOptionField from "@components/checklists/checklist/body/rowTypes/ChecklistRowOptionField";
import BhInputSimple from "@components/input/BhInputSimple";
import BhInputWithFetchedUsers from "@components/input/BhInputWithFetchedUsers";

interface Props {
  baseVersionRow: IChecklistBaseVersionRow;
  callback: Function;
  disabled: boolean;
}

const ChecklistFieldWithLabel: FC<Props> = ({ baseVersionRow, callback, disabled }) => {
  const roundRow = useAppSelector((state) => selectChecklistRowByBaseRowId(state, baseVersionRow.id));

  if (!roundRow) {
    return null;
  }

  const saveCallback = (changedObject: any) => {
    const rowToSave = { ...roundRow, ...changedObject };
    callback(rowToSave);
  };

  const widthClassMap: Record<ChecklistRowFieldType, string> = {
    DATEPICKER: "w-1/6",
    LARGE_TEXTFIELD: "w-4/6",
    MULTIPLE: "w-4/6",
    SINGLE: "w-4/6",
    SMALL_TEXTFIELD: "w-2/6",
    TIMEPICKER: "w-1/6",
    USER: "w-2/6"
  };

  const checklistRowElementMap: Record<ChecklistRowFieldType, ReactElement> = {
    DATEPICKER: <BhDatePicker property={"fieldValue"} initialValue={new Date(roundRow.fieldValue)} onChangeCallback={saveCallback} classes={"w-full"} returnISOString={true} disabled={disabled} />,
    TIMEPICKER: <BhTimePicker property={"fieldValue"} initialValue={roundRow.fieldValue} saveCallback={saveCallback} disabled={disabled} />,
    LARGE_TEXTFIELD: <BhTextareaSimple initialValue={roundRow.fieldValue} property={"fieldValue"} onBlurCallback={saveCallback} disabled={!baseVersionRow.editable || disabled} />,
    MULTIPLE: roundRow.optionFieldValue && <ChecklistRowOptionField values={roundRow.optionFieldValue.values} singleChoice={false} onChangeCallback={saveCallback} disabled={disabled} />,
    SINGLE: roundRow.optionFieldValue && <ChecklistRowOptionField values={roundRow.optionFieldValue.values} singleChoice={true} onChangeCallback={saveCallback} disabled={disabled} />,
    SMALL_TEXTFIELD: <BhInputSimple initialValue={roundRow.fieldValue} property={"fieldValue"} saveCallback={saveCallback} disabled={!baseVersionRow.editable || disabled} />,
    USER: <BhInputWithFetchedUsers initialValue={roundRow.fieldValue} property={"fieldValue"} saveCallback={saveCallback} disabled={!baseVersionRow.editable || disabled} returnOnlyString={true} />
  };

  return (
    <div className="my-1 flex flex-row">
      <div className="bh-text-deep-ocean text-14px flex w-2/6 flex-row pt-2 font-bold leading-5">
        <div>{baseVersionRow.fieldTitle}</div>
        {baseVersionRow.required && <div className="ml-0.5">*</div>}
      </div>
      <div className={widthClassMap[baseVersionRow.fieldType]}>{checklistRowElementMap[baseVersionRow.fieldType]}</div>
    </div>
  );
};

export default ChecklistFieldWithLabel;
