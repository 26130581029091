import React, { FC } from "react";
import BhTableHeader from "@components/table/BhTableHeader";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import { IFileEntity } from "@/model/files/IFileEntity";
import { useTranslation } from "react-i18next";
import { IContainer } from "@/model/container/IContainer";
import SignaturesListItem from "@/views/home/project/detail/container/containerComponents/SignaturesListItem";
import { sortSignaturesAndInvites } from "@/utilities/sortUtilities";

interface Props {
  signatures: Array<ISignatureHolder>;
  containerFileEntity: IFileEntity;
  queueEnabled: boolean;
}

const SignaturesList: FC<Props> = ({ signatures, containerFileEntity, queueEnabled }) => {
  const { t } = useTranslation();

  const tableColumnHeaders: Array<IBhTableHeader<IContainer>> = [
    queueEnabled && {
      id: 1,
      content: t("GLOBAL.ORDER"),
      classes: "w-24 text-center"
    },
    { id: 2, content: t("GLOBAL.NAME") },
    { id: 3, content: t("CONTAINER.TABLE.DATE"), classes: "w-44" },
    { id: 4, content: t("CONTAINER.TABLE.VALIDITY"), classes: "w-32 text-center" },
    { id: 5, content: "", classes: "w-48" }
  ].filter(Boolean) as Array<IBhTableHeader<IContainer>>;

  if (!signatures || signatures.length === 0) {
    return null;
  }

  return (
    <table className="min-w-full overflow-auto ">
      <BhTableHeader columns={tableColumnHeaders} />
      <tbody className="divide-y overflow-hidden ">
        {signatures
          .sort((a, b) => sortSignaturesAndInvites(a, b, containerFileEntity.queueEnabled))
          .map((signature, index) => {
            const signatureAdditionalFieldsFilled = signature.roleResolution || signature.country || signature.county || signature.city || signature.zip;
            return (
              <SignaturesListItem
                key={signature.uniqueId}
                signature={signature}
                containerFileEntity={containerFileEntity}
                queueEnabled={queueEnabled}
                index={index}
                signatureAdditionalFieldsFilled={signatureAdditionalFieldsFilled}
              />
            );
          })}
      </tbody>
    </table>
  );
};

export default SignaturesList;
