import React, { FC, useEffect, useRef, useState } from "react";

interface Props {
  fileName: string;
}

const FileNameWrapped: FC<Props> = ({ fileName }) => {
  const fileNameString = fileName + "";
  const nameElementRef = useRef<HTMLSpanElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (!nameElementRef) return;
    const isOverflowed = nameElementRef?.current ? nameElementRef?.current.offsetWidth < nameElementRef?.current.scrollWidth : false;
    setShowTooltip(isOverflowed);
  }, [nameElementRef]);

  return (
    <span className="filename">
      <span className="filename__base" ref={nameElementRef} data-tooltip-id={showTooltip ? "bh-tooltip" : undefined} data-tooltip-content={showTooltip ? fileNameString : undefined}>
        {fileNameString}
      </span>
    </span>
  );
};

export default FileNameWrapped;
