import React, { Dispatch, FC, SetStateAction, useEffect } from "react";
import { INewProjectForm } from "@/model/INewProjectForm";
import { EntityId } from "@reduxjs/toolkit";
import { Trans, useTranslation } from "react-i18next";
import BhCheckboxWithText from "@components/checkboxes/BhCheckboxWithText";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchCompanyCustomFormBasesAsync, fetchFormBasesForCompanyAsync, selectActiveCompanyFormBases, selectCompanyCustomFormBases } from "@/app/store/form/formBasesSlice";
import { IFormBase } from "@/model/form/IFormBase";

interface Props {
  newProjectForm: INewProjectForm;
  setNewProjectForm: Dispatch<SetStateAction<INewProjectForm>>;
  companyId: EntityId;
}

const NewProjectFormBases: FC<Props> = ({ newProjectForm, setNewProjectForm, companyId }) => {
  const formBases = useAppSelector(selectActiveCompanyFormBases);
  const customFormBases = useAppSelector(selectCompanyCustomFormBases);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formBasesSorted = formBases.slice().sort((a, b) => ((a.bauhubFormBase ? t(`FORMS.${a.type}`) : a.type) > (b.bauhubFormBase ? t(`FORMS.${b.type}`) : b.type) ? 1 : -1));
  const showCustomFormBases = customFormBases && customFormBases.length > 0;

  const isSelected = (formBase: IFormBase) => newProjectForm.formBases?.some((fb) => fb.id === formBase.id);

  useEffect(() => {
    if (!newProjectForm.formBases) {
      dispatch(fetchFormBasesForCompanyAsync(companyId)).then((action) => {
        const formBases = action.payload as Array<IFormBase>;
        const defaultFormBasesToAdd = formBases.filter((formBase) => formBase.addToNewProjects);
        setNewProjectForm({ ...newProjectForm, formBases: defaultFormBasesToAdd });
      });
    }
    if (!customFormBases || customFormBases.length === 0) dispatch(fetchCompanyCustomFormBasesAsync({ companyId: companyId }));
  }, []);

  const onFormBaseSelect = (formBase: IFormBase) => {
    const formBases = newProjectForm.formBases;
    const updatedFormBases = formBases.some((fb) => fb.id === formBase.id) ? formBases.filter((fb) => fb.id !== formBase.id) : [...formBases, formBase];
    setNewProjectForm({ ...newProjectForm, formBases: updatedFormBases });
  };

  return (
    <>
      <div className="w-2/3">
        <h2 className="pb-4">
          <Trans>COMPANY.SETTINGS.FORM_BASES</Trans>
        </h2>
        <p>
          <Trans>COMPANY.SETTINGS.FORM_BASES.INFO</Trans>
        </p>
        <div className="-ml-4 pt-10">
          {formBasesSorted.map((fb) => (
            <BhCheckboxWithText key={fb.id} text={fb.bauhubFormBase ? t(`FORMS.${fb.type}`) : fb.type} onChange={() => onFormBaseSelect(fb)} isChecked={isSelected(fb)} property={"addToNewProjects"} />
          ))}
          {showCustomFormBases &&
            customFormBases.map((fb) => (
              <div className="bh-text-pigeon">
                <BhCheckboxWithText key={fb.id} text={t(`FORMS.${fb.type}`)} property="" isChecked={true} disabled={true} />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default NewProjectFormBases;
