export interface ICurrencyFormatterOptions {
  significantDigits: number;
  thousandsSeparator: string;
  decimalSeparator: string;
  symbol: string;
}

export const formatCurrency = (value: number, options?: ICurrencyFormatterOptions, includeSymbolWithZeros?: boolean): string => {
  if (!value && !includeSymbolWithZeros) {
    return "";
  }

  const defaultOptions: ICurrencyFormatterOptions = {
    significantDigits: 2,
    thousandsSeparator: " ",
    decimalSeparator: ",",
    symbol: "€"
  };

  options = { ...defaultOptions, ...options };
  const fixedValue = value.toFixed(options.significantDigits);

  const [currency, decimal] = fixedValue.split(".");

  return `${currency.replace(new RegExp(`\\B(?=(\\d{3})+(?!\\d))`, "g"), options.thousandsSeparator)}${options.decimalSeparator}${decimal} ${options.symbol}`;
};
