import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { EntityId } from "@reduxjs/toolkit";
import { IProjectNotificationCount, IUserNotification } from "@/model/IUserNotification";
import { formatDateTimeInternationalFormat } from "@/utilities/dateUtility";

export function fetchProjectNotifications(projectId: EntityId): Promise<Array<IUserNotification>> {
  return bauhubGet("/user/notification/" + projectId + "/list");
}

export function fetchLatestProjectNotifications(projectId: EntityId): Promise<Array<IUserNotification>> {
  return bauhubGet("/user/notification/" + projectId + "/latest");
}

export function fetchArchivedNotifications(projectId: EntityId): Promise<Array<IUserNotification>> {
  return bauhubGet("/user/notification/archived/" + projectId);
}

export function setNotificationSeen(userNotification: IUserNotification): Promise<IUserNotification> {
  return bauhubPost("/user/notification/seen?st=false", userNotification);
}

export function fetchProjectNotificationCounts(): Promise<Array<IProjectNotificationCount>> {
  return bauhubGet("/user/notification/all");
}

export function setNotificationArchived(userNotification: IUserNotification): Promise<IUserNotification> {
  return bauhubPost("/user/notification/archive", userNotification);
}

export function setAllProjectNotificationsSeen(projectId: EntityId): Promise<IUserNotification> {
  return bauhubPost("/notification/project/" + projectId + "/seen");
}

export function fetchNewNotifications(since?: number): Promise<Array<IUserNotification>> {
  const sinceDate = since && formatDateTimeInternationalFormat(new Date(since), true);
  return bauhubGet("/user/notification/new?since=" + sinceDate + "&st=false");
}

export function setAllNotificationsArchivedForProject(projectId: EntityId): Promise<Array<IUserNotification>> {
  return bauhubPost("/notification/project/" + projectId + "/archive");
}
