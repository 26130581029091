import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveNewChecklistBaseVersionRowAsync, selectAllChecklistBaseVersionRowsSorted } from "@/app/store/checklist/checklistBaseRowsSlice";
import { EntityId } from "@reduxjs/toolkit";
import ChecklistBaseFields from "@components/checklists/checklistBase/body/ChecklistBaseFields";
import ChecklistBaseRow from "@components/checklists/checklistBase/body/ChecklistBaseRow";
import { ChecklistRowType, IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import ChecklistBaseFieldsAddButton from "@components/checklists/checklistBase/body/ChecklistBaseFieldsAddButton";
import { selectCompanyChecklistBaseById } from "@/app/store/checklist/checklistsSlice";
import { ChecklistBaseVersionStatus } from "@/model/checklist/IChecklistBaseVersion";
import { useTranslation } from "react-i18next";

interface Props {
  companyId: EntityId;
  checklistBaseId: EntityId;
  checklistBaseVersionId: EntityId;
  projectId?: EntityId;
}

const ChecklistBaseRows: FC<Props> = ({ companyId, checklistBaseId, checklistBaseVersionId, projectId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const baseVersionRows = useAppSelector(selectAllChecklistBaseVersionRowsSorted);
  const checklistBase = useAppSelector((state) => selectCompanyChecklistBaseById(state, checklistBaseId));
  const checklistBaseVersion = checklistBase && checklistBase.checklistBaseVersions && checklistBase.checklistBaseVersions.find((cbv) => cbv.id === checklistBaseVersionId);
  const isFinalVersion = checklistBaseVersion && checklistBaseVersion.status === ChecklistBaseVersionStatus.FINAL;

  const saveNewChecklistBaseVersionRow = (field: any, previousRow: IChecklistBaseVersionRow) => {
    const checklistBaseVersionRow = {
      checklistBaseVersionId: checklistBaseVersionId,
      checklistRowType: field.type,
      indentation: field.indentation,
      editable: true,
      previousChecklistBaseVersionRowId: previousRow ? previousRow.id : null,
      checkpoint: field.checkpoint,
      fieldType: field.fieldType,
      plusMinusFieldValue: field.plusMinusFieldValue,
      allowAttachmentUploads: field.type === ChecklistRowType.ATTACHMENTS ? true : null
    } as IChecklistBaseVersionRow;
    dispatch(saveNewChecklistBaseVersionRowAsync({ companyId: companyId, checklistBaseId: checklistBaseId, checklistBaseVersionRow: checklistBaseVersionRow }));
  };

  return (
    <div className="flex w-full flex-col py-8 px-36">
      {baseVersionRows && baseVersionRows.length > 0 && (
        <div>
          {!isFinalVersion && <ChecklistBaseFieldsAddButton saveNewRowCallback={saveNewChecklistBaseVersionRow} />}
          {baseVersionRows.map((baseVersionRow) => {
            return (
              <ChecklistBaseRow
                companyId={companyId}
                checklistBaseId={checklistBaseId}
                checklistBaseVersionId={checklistBaseVersionId}
                baseVersionRow={baseVersionRow}
                saveNewRowCallback={saveNewChecklistBaseVersionRow}
                key={baseVersionRow.id}
                disabled={isFinalVersion}
                projectId={projectId}
              />
            );
          })}
        </div>
      )}
      {(!baseVersionRows || baseVersionRows.length === 0) && !isFinalVersion && (
        <div className="flex flex-col">
          <div className="bh-text-deep-ocean-60 text-18px mb-2 flex w-full flex-row justify-center leading-6">{t("CHECKLIST_BASE.START_NEW")}</div>
          <div className="m-auto w-fit">
            <ChecklistBaseFields saveNewRowCallback={saveNewChecklistBaseVersionRow} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChecklistBaseRows;
