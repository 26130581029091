import React, { FC, useState } from "react";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import AddPartyAccessDropdown from "@/views/home/project/detail/directory/directoryModals/authoritiesModal/AddPartyAccessDropdown";
import PartyAuthoritiesCardSkeleton from "@/views/home/project/detail/directory/directoryModals/authoritiesModal/PartyAuthoritiesCardSkeleton";
import PartyAuthoritiesCard from "@/views/home/project/detail/directory/directoryModals/authoritiesModal/PartyAuthoritiesCard";
import { IFileAccess, IWorkGroupUserAuthorities } from "@/model/files/IFileAccess";

interface Props {
  directoryAccess: IFileAccess;
  setDirectoryAccess: Function;
  disableAddAuthorities?: boolean;
}

const AuthoritiesModalPartiesTab: FC<Props> = ({ directoryAccess, setDirectoryAccess, disableAddAuthorities }) => {
  const [searchString, setSearchString] = useState<string>("");
  const filteredAndSortedParties =
    directoryAccess.workGroups
      ?.filter((party: IWorkGroupUserAuthorities) => party.name.toLowerCase().includes(searchString.toLowerCase()))
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) || [];

  return (
    <BhScrollableBody>
      <div className="h-22 flex items-center py-6">
        <BhSearchInputWBG property="query" onChangeCallback={(v: { query: string }) => setSearchString(v.query)} inputClasses="w-72" />
        {!disableAddAuthorities && <AddPartyAccessDropdown parties={directoryAccess.workGroupsWithoutAuthority} setDirectoryAccess={setDirectoryAccess} />}
      </div>
      {directoryAccess ? (
        <div className="pb-8">
          {filteredAndSortedParties?.map((party: IWorkGroupUserAuthorities) => {
            return <PartyAuthoritiesCard key={party.id} party={party} />;
          })}
        </div>
      ) : (
        <PartyAuthoritiesCardSkeleton />
      )}
    </BhScrollableBody>
  );
};

export default AuthoritiesModalPartiesTab;
