import React, { FC, ReactNode } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";

interface Props {
  classes?: string;
  iconClasses?: string;
  icon?: IconProp;
  buttonLabel?: ReactNode;
  onClick?: Function;
  children?: ReactNode;
  small?: boolean;
}

const BhSectionMessage: FC<Props> = ({ classes, iconClasses, icon, buttonLabel, onClick, children, small }) => {
  const iconSizeClasses = !small ? "h-16px w-16px pt-[4.5px] pl-[4.5px] pr-[0.5px]" : "h-16px w-16px pl-1 pr-0";
  const containerSizeClasses = !small ? "min-h-14 my-2 p-4" : "my-2 p-1";
  const iconSize = !small ? "2xl" : "xl";

  return (
    <div className={classNames(classes, containerSizeClasses, "flex flex-row items-center rounded")}>
      {icon && <FontAwesomeIcon icon={icon} className={classNames(iconClasses, iconSizeClasses)} size={iconSize} aria-hidden="true" />}
      <div className="flex-1 px-4">{children}</div>
      {buttonLabel && (
        <div>
          <BhTextOnlyButton buttonProps={{ onClick: () => onClick && onClick() }}>{buttonLabel}</BhTextOnlyButton>
        </div>
      )}
    </div>
  );
};

export default BhSectionMessage;
