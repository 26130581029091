import React, { FC } from "react";
import { FileTreeType, IFileTree } from "@/model/files/IFileTreeTemplate";
import { classNames } from "@/utilities/jsUtilities";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons/faAngleRight";
import { faFolder } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { useTranslation } from "react-i18next";
import { EntityId } from "@reduxjs/toolkit";
import { naturalSortFilesByField } from "@/utilities/sortUtilities";

interface Props {
  fileTree: IFileTree;
  fileTreeType: FileTreeType;
  selectedIds: Array<EntityId>;
  onSelect: Function;
  toggleDirOpen: Function;
}

const PartyTemplateDirSelectionContainer: FC<Props> = ({ fileTree, fileTreeType, selectedIds, onSelect, toggleDirOpen }) => {
  const { t } = useTranslation();

  const subDirs = fileTree?.children && [...fileTree?.children].sort((a, b) => naturalSortFilesByField(a, b, "name"));
  const isSelected = selectedIds.some((id) => id === fileTree.id);
  const isOpen = fileTree?.open && !isSelected;

  const name = fileTree?.name === "ROOT" ? t("GLOBAL." + fileTreeType) : fileTree?.name;

  const handleDirSelect = () => {
    onSelect(fileTree.id);
  };

  return (
    <>
      <div className={classNames(isSelected && "bh-bg-mint-110", "hover:bh-bg-mint-50 even:bh-bg-smoke mb-0.5 rounded-sm")}>
        <div className={classNames("flex h-7 flex-row items-center overflow-hidden py-2 pr-2")}>
          <div>
            <BhCheckbox isChecked={isSelected} onChange={handleDirSelect} large={true} property="selected" disabled={false} />
          </div>
          <div className={classNames("flex items-center overflow-hidden", !isSelected && "cursor-pointer")} onClick={() => toggleDirOpen(fileTree.id, isSelected)}>
            <FontAwesomeIcon className="h-4 w-8" icon={isOpen ? faAngleDown : faAngleRight} size={"1x"} />
            <div className="pl-1 pr-1.5">
              <FontAwesomeIcon className={classNames(isSelected ? "bh-text-bauhub-green-120" : "bh-text-pigeon-60")} icon={faFolder} />
            </div>
            <div className="flex flex-1 truncate">
              <div className={classNames("bh-text-deep-ocean cursor-pointer", isSelected && "font-bold")} onClick={() => {}}>
                {name}
              </div>
            </div>
          </div>
        </div>
      </div>
      {subDirs?.length > 0 && isOpen && (
        <div className="ml-2">
          {subDirs.map((dir) => {
            return <PartyTemplateDirSelectionContainer key={dir.id} fileTree={dir} fileTreeType={fileTreeType} selectedIds={selectedIds} onSelect={onSelect} toggleDirOpen={toggleDirOpen} />;
          })}
        </div>
      )}
    </>
  );
};

export default PartyTemplateDirSelectionContainer;
