import React, { FC, useCallback, useState } from "react";
import BhInputSimple from "@components/input/BhInputSimple";
import FormBaseProtocolFieldTablePlaceholderRow from "@components/form/base/body/fields/protocolField/FormBaseProtocolFieldTablePlaceholderRow";
import { classNames } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectIsOnlyOneMainRowRemaining, selectProtocolRowByIdFromCurrentFormData } from "@/app/store/form/formSlice";
import ProtocolAddRowButton from "@components/form/base/body/fields/protocolField/ProtocolAddRowButton";
import BhFakeInput from "@components/input/BhFakeInput";
import FormProtocolFieldTableRowDelete from "@components/form/fields/protocolField/FormProtocolFieldTableRowDelete";
import FormProtocolFieldTaskRelatedFields from "@components/form/fields/protocolField/protocolFieldTaskFields/FormProtocolFieldTaskRelatedFields";
import FormProtocolRowInput from "@components/form/base/body/fields/protocolField/FormProtocolRowInput";

interface Props {
  rowId: any;
  saveRow: Function;
  addRowCallback: Function;
  removeRowCallback: Function;
  disabled: boolean;
  isLastRow: boolean;
  agendaFieldProperty: string;
  agendaRow?: any;
  isPreview?: boolean;
}

const FormProtocolFieldTableRow: FC<Props> = ({ rowId, saveRow, isLastRow, removeRowCallback, disabled, addRowCallback, agendaFieldProperty, agendaRow, isPreview }) => {
  const { t } = useTranslation();
  const row = useAppSelector((state) => (!isPreview ? selectProtocolRowByIdFromCurrentFormData(state, rowId, agendaFieldProperty) : agendaRow));
  const isOnlyOneMainRowInTable = useAppSelector((state) => (!isPreview ? selectIsOnlyOneMainRowRemaining(state, rowId, agendaFieldProperty) : false));
  const isCurrentRowOnlyMainRow = isOnlyOneMainRowInTable && row.level === 0;
  const isSubRow = row.level === 1;
  const [isHovered, setIsHovered] = useState(false);

  const nrSaveCallback = useCallback((value: any) => saveRow(value, "nr", row._id), [saveRow, row._id]);
  const contentOnBlurCallback = useCallback((value: any) => saveRow(value, "content", row._id), [saveRow, row._id]);
  const contentFormatSaveCallback = useCallback((value: any, property: string) => saveRow(value, property, row._id), [saveRow, row._id]);

  return (
    <div className="flex flex-col gap-y-1">
      <div className="flex flex-row items-start gap-x-1" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <div className="min-w-14 w-14 py-1">
          <ProtocolAddRowButton saveNewRowCallback={addRowCallback} isCurrentRowSubRow={isSubRow} disabled={disabled} row={row} isFormBase={false} agendaFieldProperty={agendaFieldProperty} />
        </div>
        <div className={classNames(isSubRow ? "min-w-16 max-w-16 ml-4 w-16" : "min-w-20 max-w-20 w-20")}>
          <BhFakeInput
            value={row.nr}
            className={classNames(isSubRow ? "min-w-16" : "min-w-20")}
            placeholder={isHovered && !row.nr ? (t("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.NUMBER") as string) : ""}
            disabled={disabled}
          >
            <BhInputSimple
              initialValue={row.nr}
              property={"nr"}
              inputClasses={isSubRow ? "min-w-16" : "min-w-20"}
              placeholder={t("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.NUMBER") as string}
              saveCallback={nrSaveCallback}
              disabled={disabled}
              autoFocus={true}
            />
          </BhFakeInput>
        </div>
        <div className="-mb-1 h-full w-full">
          <FormProtocolRowInput
            initialValue={row.content}
            property={"content"}
            saveCallback={contentOnBlurCallback}
            disabled={disabled}
            row={row}
            contentFormatSaveCallback={contentFormatSaveCallback}
            placeholder={t("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.CONTENT") as string}
            isHovered={isHovered}
          />
        </div>
        <FormProtocolFieldTaskRelatedFields row={row} saveCallback={saveRow} disabled={disabled} isHovered={isHovered} agendaFieldProperty={agendaFieldProperty} />
        <div className={classNames("min-w-10 max-w-10 w-10", isHovered ? "opacity-100" : "opacity-0")}>
          {!disabled && !isCurrentRowOnlyMainRow && <FormProtocolFieldTableRowDelete row={row} removeRowCallback={removeRowCallback} disabled={disabled} saveRowCallback={saveRow} />}
        </div>
      </div>
      {isLastRow && (
        <FormBaseProtocolFieldTablePlaceholderRow isCurrentRowSubRow={isSubRow} row={row} saveNewRowCallback={addRowCallback} disabled={disabled} agendaFieldProperty={agendaFieldProperty} />
      )}
    </div>
  );
};

export default FormProtocolFieldTableRow;
