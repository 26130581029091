import { IFormFieldImportDTO } from "@/model/IForm";

export interface IFormModals {
  open: IFormModalsOpen;
  data?: Array<IFormFieldImportDTO>;
  field?: string;
  isAttachmentsField?: boolean;
}

export interface IFormModalsOpen {
  importFieldInfoModal: boolean;
}

export const FormModalsInitialState = {
  open: {
    importFieldInfoModal: false
  }
};
