import BhFilterContainer from "@components/filters/BhFilterContainer";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhFilter from "@components/filters/BhFilter";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import React, { FC, useEffect } from "react";
import { boSelectFilter, boSetFilters } from "@/app/store/backofficeSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { IBoPlanFilter, IBoTrialStatusFilter, ICompaniesFilter, initialCompaniesFilter } from "@/model/backoffice/IBoFilter";

interface Props {
  onNewCompanyClickCallback: Function;
}

const BOCompaniesFilterRow: FC<Props> = ({ onNewCompanyClickCallback }) => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector(boSelectFilter);

  useEffect(() => {
    dispatch(boSetFilters({ ...filter, searchQuery: "", companiesFilter: initialCompaniesFilter }));
  }, []);

  const onNameFilterChange = (searchQuery: Record<string, string>) => {
    const newFilter = { ...filter, ...searchQuery };
    dispatch(boSetFilters(newFilter));
  };

  const onPlanFilterChange = (changedValue: IBoPlanFilter) => {
    const companiesFilter: ICompaniesFilter = { ...filter.companiesFilter };
    const updatedOrderTypeFilter = companiesFilter.plans.map((plan) => plan.value === changedValue.value ? { ...plan, selected: !plan.selected } : plan);
    const newCompaniesFilter = { ...companiesFilter, plans: updatedOrderTypeFilter };
    const newFilter = { ...filter, companiesFilter: newCompaniesFilter };
    dispatch(boSetFilters(newFilter));
  };

  const onStatusFilterChange = (changedValue: IBoTrialStatusFilter) => {
    const companiesFilter: ICompaniesFilter = { ...filter.companiesFilter };
    const updatedOrderTypeFilter = companiesFilter.status.map((status) => status.value === changedValue.value ? { ...status, selected: !status.selected } : status);
    const newCompaniesFilter = { ...companiesFilter, status: updatedOrderTypeFilter };
    const newFilter = { ...filter, companiesFilter: newCompaniesFilter };
    dispatch(boSetFilters(newFilter));
  };

  const isPlanSelected = (plan: IBoPlanFilter) => {
    return filter.companiesFilter.plans.flatMap((p) => p.selected && p.value).includes(plan.value);
  };

  const isStatusSelected = (status: IBoTrialStatusFilter) => {
    return filter.companiesFilter.status.flatMap((s) => s.selected && s.value).includes(status.value);
  };

  const onPlanFilterReset = () => {
    const newFilterPlans = filter.companiesFilter.plans.map((plan) => ({ ...plan, selected: false }));
    const newFilter = { ...filter, companiesFilter: { ...filter.companiesFilter, plans: newFilterPlans } };
    dispatch(boSetFilters(newFilter));
  };

  const onStatusFilterReset = () => {
    const newFilterStatus = filter.companiesFilter.status.map((status) => ({ ...status, selected: false }));
    const newFilter = { ...filter, companiesFilter: { ...filter.companiesFilter, status: newFilterStatus } };
    dispatch(boSetFilters(newFilter));
  };

  return (
    <div className="flex justify-between items-center flex-wrap">
      <BhFilterContainer>
        <div>
          <BhSearchInputWBG property={"searchQuery"} inputClasses="w-64" placeholder="Search" onChangeCallback={onNameFilterChange} />
        </div>
        <BhFilter
          title={"Plan"}
          values={filter.companiesFilter.plans}
          textProperty={"name"}
          onSelect={onPlanFilterChange}
          isSelected={isPlanSelected}
          onReset={onPlanFilterReset}
          selectedValues={filter.companiesFilter.plans?.filter((plan) => plan.selected).length}
        />
        <BhFilter
          title={"Trial period"}
          values={filter.companiesFilter.status}
          textProperty={"name"}
          onSelect={onStatusFilterChange}
          isSelected={isStatusSelected}
          onReset={onStatusFilterReset}
          selectedValues={filter.companiesFilter.status?.filter((status) => status.selected).length}
        />
      </BhFilterContainer>
      <BhSecondaryButton icon={faPlus} buttonProps={{ onClick: onNewCompanyClickCallback }}>
        New Company
      </BhSecondaryButton>
    </div>
  );
};

export default BOCompaniesFilterRow;
