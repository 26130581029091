import { createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { ISidebarItem, SidebarInitialState, SidebarItemType } from "@/model/ISidebarItem";
import { RootState } from "@/app/store";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { IProject, Module } from "@/model/IProject";

export interface SidebarState {
  items: Array<ISidebarItem>;
  current?: SidebarItemType;
  collapsed: boolean;
}

const initialState: SidebarState = {
  items: SidebarInitialState,
  collapsed: false
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSidebarCollapsed: (state, action: PayloadAction<boolean>) => {
      state.collapsed = action.payload;
    },
    toggleSidebarCollapsed: (state) => {
      state.collapsed = !state.collapsed;
    },
    setCurrentSidebarMenuItem: (state, action: PayloadAction<SidebarItemType>) => {
      state.current = action.payload;
    }
  }
});

export const { setSidebarCollapsed, toggleSidebarCollapsed, setCurrentSidebarMenuItem } = sidebarSlice.actions;

export const selectSidebarMenuItems = (state: RootState) => state.sidebar.items;
export const selectCurrentSidebarMenuItem = (state: RootState) => state.sidebar.current;
export const selectSidebarCollapsed = (state: RootState) => state.sidebar.collapsed;

export const selectCurrentUserForSideBar = (state: RootState) => state.user.current;
export const selectCurrentProjectForSidebar = (state: RootState, projectId: EntityId, project: IProject) => project;

export const selectSidebarMenuItemsForCurrentUser = createSelector(
  [selectSidebarMenuItems, selectIsCurrentUserProjectAdmin, selectCurrentProjectForSidebar, selectCurrentUserForSideBar],
  (menuItems, isCurrentUserProjectAdmin, project, currentUser) => {
    if (!project || !project.id) return [];

    // RT TRESOOR
    const projectHasRTEngineeringPMRole = project.modules.some((module) => module.module === Module.RT_ENGINEERING_PM_ROLE);
    const isCurrentUserRTEngineeringPM = projectHasRTEngineeringPMRole && currentUser.privileges?.RT_ENGINEERING_PM[project.id];
    const isRTEngineeringPM = projectHasRTEngineeringPMRole && isCurrentUserRTEngineeringPM;
    const projectHasTresoorBudgetModule = project.modules.some((module) => module.module === Module.TRESOOR_BUDGET);
    const projectHasTresoorV2BudgetModule = project.modules.some((module) => module.module === Module.TRESOOR_BUDGET_V2);

    // MARU
    const projectHasMRBudgetModule = project.modules.some((module) => module.module === Module.MR_BUDGET);
    const isCurrentUserMRProjectTeam = currentUser.privileges?.MR_PROJECT_TEAM[project.id];

    // BIM (has module)
    const hasBimVisible = project.modules.some((module) => module.module === Module.BIM);

    return menuItems.filter((menuItem) => {
      if (menuItem.needsAdmin) {
        if (!isCurrentUserProjectAdmin) {
          return false;
        }
        if (isCurrentUserProjectAdmin) {
          switch (menuItem.type) {
            // RT TRESOOR
            case SidebarItemType.BUDGET:
              return projectHasTresoorBudgetModule && !isRTEngineeringPM;
              break;
            case SidebarItemType.BUDGET_V2:
              return projectHasTresoorV2BudgetModule && !isRTEngineeringPM;
              break;
            // MARU
            case SidebarItemType.MR_DASHBOARD:
            case SidebarItemType.MR_4R:
            case SidebarItemType.MR_BUDGET:
              return projectHasMRBudgetModule && !isCurrentUserMRProjectTeam;
              break;
            // ALL OTHER ITEMS ARE VALID
            default:
              return true;
              break;
          }
        }
      }
      if (!menuItem.needsAdmin) {
        if (menuItem.type === SidebarItemType.BIM && !hasBimVisible) return false;
        return true;
      }
    });
  }
);

export default sidebarSlice.reducer;
