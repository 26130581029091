import React, { FC } from "react";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import BhDivTableHeader from "@components/table/BhDivTableHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignature } from "@fortawesome/pro-regular-svg-icons/faSignature";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons/faPaperclip";
import { IAct } from "@/model/IAct";
import { useTranslation } from "react-i18next";

const ActsListTableHeader: FC = () => {
  const { t } = useTranslation();

  const tableColumnHeaders: Array<IBhTableHeader<IAct>> = [
    { id: 1, content: t("ACT.NUMBER"), classes: "w-18 flex-none text-center" },
    { id: 2, content: t("GLOBAL.ITEM_NAME"), classes: "flex-grow" },
    { id: 3, content: <FontAwesomeIcon icon={faPaperclip} />, classes: "w-14 flex-none justify-center" },
    { id: 5, content: <FontAwesomeIcon icon={faSignature} />, classes: "w-12 flex-none justify-center" },
    { id: 6, content: t("GLOBAL.ORDER_BY.STATUS"), classes: "w-52 flex-none" },
    { id: 7, content: t("GLOBAL.CREATED"), classes: "w-40 flex-none" },
    { id: 8, content: "", classes: "w-14 flex-none" }
  ];

  return <BhDivTableHeader columns={tableColumnHeaders} reversed={false} sortedBy={"number"} />;
};

export default ActsListTableHeader;
