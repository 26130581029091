import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectAllUploadsSorted, selectFilesUploadingAndNotProcessingLength, selectFilesUploadingLength, setUploadModalOpen, uploadFileModified } from "@/app/store/uploadSlice";
import BhFileCardMedium from "@components/cards/BhFileCardMedium";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons/faSpinnerThird";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons/faCircleXmark";
import { debounce } from "lodash";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretUp } from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import { selectAnyAsyncJobsExecuting, selectExecutingAsyncJobIds, selectFailedAsyncJobIds } from "@/app/store/asyncJobsSlice";
import BhAsyncJobContainer from "@components/asyncJobs/BhAsyncJobContainer";
import { useTranslation } from "react-i18next";
import { textWithVariables } from "@/utilities/jsUtilities";
import parse from "html-react-parser";
import { abortUploadFunctionsStore } from "@/api/upload/uploadHelper";
import BhFailedAsyncJobContainer from "@components/asyncJobs/BhFailedAsyncJobContainer";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { FileEntityType, IUploadFileEntity } from "@/model/files/IFileEntity";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons/faCircleCheck";
import { goToFileEntityParentDir } from "@/features/searchWithDropdown/SearchFileCardContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { faFolder } from "@fortawesome/pro-regular-svg-icons/faFolder";

const UploadAndAsyncJobModal: FC = () => {
  const uploadingFiles = useAppSelector(selectAllUploadsSorted);
  const executingAsyncJobIds = useAppSelector(selectExecutingAsyncJobIds);
  const failedAndCancelledAsyncJobIds = useAppSelector(selectFailedAsyncJobIds);
  const anyAsyncJobsExecuting = useAppSelector(selectAnyAsyncJobsExecuting);
  const uploadingFilesLength = useAppSelector(selectFilesUploadingLength);
  const filesUploadingAndNotProcessingLength = useAppSelector(selectFilesUploadingAndNotProcessingLength);
  const [collapsed, setCollapsed] = useState(false);
  const anyErrors = uploadingFiles.filter((file) => file.errorUploading).length > 0;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const modalCloser = useCallback(() => {
    dispatch(setUploadModalOpen(false));
  }, []);

  const debouncedModalCloser = useMemo(() => debounce(modalCloser, 5000), []);

  useEffect(() => {
    if (uploadingFilesLength === 0 && !anyErrors) {
      debouncedModalCloser();
    }

    return () => {
      debouncedModalCloser.cancel();
    };
  }, [uploadingFilesLength]);

  useEffect(() => {
    if (anyAsyncJobsExecuting) {
      setCollapsed(true);
    }
  }, []);

  const cancelUpload = useCallback((uploadingFile: IUploadFileEntity) => {
    if (!uploadingFile.percentage || uploadingFile.percentage < 98) {
      const abortFunction = abortUploadFunctionsStore.get(uploadingFile.uuid);

      if (!uploadingFile.name) return null;

      if (!abortFunction) {
        const changedObject = { ...uploadingFile, ...{ uploading: false, errorUploading: true } };
        dispatch(uploadFileModified(changedObject));
      }
      if (abortFunction) {
        abortFunction.abort();
        abortUploadFunctionsStore.set(uploadingFile.uuid, null);
      }
    }
  }, []);

  const cancelAllUploads = useCallback(() => {
    uploadingFiles.forEach((uploadingFile) => {
      cancelUpload(uploadingFile);
    });
  }, [uploadingFiles]);

  return (
    <div className="bh-bg-white bh-shadow z-100 absolute right-8 bottom-8 flex w-96 flex-col overflow-hidden rounded">
      <div className="bh-bg-deep-ocean flex h-10 items-center justify-between px-3">
        <div className="flex cursor-pointer items-center" onClick={() => setCollapsed(!collapsed)}>
          <FontAwesomeIcon icon={collapsed ? faCaretUp : faCaretDown} className="bh-text-white w-2 pr-2" />
          <h3 className="bh-text-white">
            {anyAsyncJobsExecuting && t("ASYNC.COMBINED_MODAL_HEADER")}
            {!anyAsyncJobsExecuting && <> {parse(textWithVariables(t("ASYNC.UPLOAD_FILE.N_FILES_UPLOADING"), { number: uploadingFilesLength.toString() }))}</>}
          </h3>
        </div>
        {anyAsyncJobsExecuting && <FontAwesomeIcon icon={faSpinnerThird} className="fa-spin bh-text-white" />}
        {!anyAsyncJobsExecuting && filesUploadingAndNotProcessingLength > 0 && <BhTextOnlyButton buttonProps={{ onClick: cancelAllUploads, inverted: true }}>{t("ASYNC.CANCEL")}</BhTextOnlyButton>}
        {!anyAsyncJobsExecuting && uploadingFilesLength < 1 && (
          <BhTextOnlyButton buttonProps={{ onClick: () => dispatch(setUploadModalOpen(false)), inverted: true }}>{t("GLOBAL.CLOSE")}</BhTextOnlyButton>
        )}
      </div>
      {!collapsed && (
        <>
          <div className="max-h-[240px] flex-grow divide-y overflow-auto">
            {anyAsyncJobsExecuting &&
              executingAsyncJobIds.map((asyncJobId) => {
                return <BhAsyncJobContainer asyncJobId={asyncJobId} key={asyncJobId} />;
              })}
            {failedAndCancelledAsyncJobIds.length > 0 &&
              failedAndCancelledAsyncJobIds.map((asyncJobId) => {
                return <BhFailedAsyncJobContainer asyncJobId={asyncJobId} key={asyncJobId} />;
              })}
          </div>
          {uploadingFiles.length > 0 && (
            <div className="flex max-h-[240px] flex-grow flex-col overflow-auto border-t p-2">
              {anyAsyncJobsExecuting && uploadingFilesLength > 0 && (
                <h3 className="px-2">{parse(textWithVariables(t("ASYNC.UPLOAD_FILE.N_FILES_UPLOADING"), { number: uploadingFilesLength.toString() }))}</h3>
              )}
              {uploadingFiles.map((fileEntity) => {
                if (!fileEntity.name) {
                  return null;
                }
                const isNavigateable = [FileEntityType.FILE, FileEntityType.CONTAINER].includes(fileEntity.type);
                return (
                  <div key={fileEntity.uuid} className="pb-1">
                    <BhFileCardMedium fileEntity={fileEntity} disabled={true}>
                      {fileEntity.uploading && (
                        <div className="bh-text-color-dark-green bh-hover-container flex flex-row items-center">
                          <div className={"flex flex-row items-center gap-x-1.5 pl-1" + ((fileEntity?.percentage < 99 || !fileEntity.percentage) && " bh-hover-item-hide")}>
                            {fileEntity?.percentage > 0 && <strong>{fileEntity.percentage + "%"}</strong>}
                            <FontAwesomeIcon icon={faSpinnerThird} className="fa-spin mr-4" />
                          </div>
                          {(!fileEntity.percentage || fileEntity.percentage < 99) && (
                            <div className="bh-hover-item -m-1">
                              <BhTextOnlyButton
                                icon={faTimes}
                                buttonProps={{
                                  onClick: () => cancelUpload(fileEntity)
                                }}
                              >
                                {t("GLOBAL.CANCEL")}
                              </BhTextOnlyButton>
                            </div>
                          )}
                        </div>
                      )}
                      {!fileEntity.uploading && (
                        <>
                          {!fileEntity.errorUploading && (
                            <div className="bh-text-color-dark-green bh-hover-container flex flex-row items-center">
                              <div className={"flex flex-row items-center gap-x-1.5 pl-1" + (isNavigateable && " bh-hover-item-hide")}>
                                <FontAwesomeIcon icon={faCircleCheck} className="bh-text-success-green mr-4" />
                              </div>
                              {isNavigateable && (
                                <div className="bh-hover-item -m-1">
                                  <BhTextOnlyButton
                                    icon={faFolder}
                                    buttonProps={{
                                      onClick: () => goToFileEntityParentDir(fileEntity.projectId, fileEntity, navigate, dispatch, location.pathname)
                                    }}
                                  >
                                    {t("FILE.GO_TO_DIR")}
                                  </BhTextOnlyButton>
                                </div>
                              )}
                            </div>
                          )}
                          {fileEntity.errorUploading && <FontAwesomeIcon icon={faCircleXmark} className="bh-text-error-red mr-4" />}
                        </>
                      )}
                    </BhFileCardMedium>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UploadAndAsyncJobModal;
