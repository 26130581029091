import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretUp } from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import { classNames } from "@/utilities/jsUtilities";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons/faCirclePlus";

interface Props {
  versionNumber: number;
  disabled?: boolean;
  onClick?: Function;
  icon?: IconProp;
  otherVersionsExist?: boolean;
  allVersionsVisible?: boolean;
  isActive?: boolean;
  addNewCallback?: Function;
}

const BhTableRowVersion: FC<Props> = ({ disabled, addNewCallback, onClick, versionNumber, otherVersionsExist, isActive, icon, allVersionsVisible }) => {
  const addNew = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    addNewCallback && addNewCallback();
  };

  return (
    <div
      className={classNames(otherVersionsExist && "hover:bh-bg-smoke hover:cursor-pointer", isActive && "bh-bg-pigeon-20", "rounded py-1 px-2")}
      onClick={() => {
        otherVersionsExist && onClick && onClick();
      }}
    >
      <div className="bh-text-deep-ocean-80 flex flex-row items-center justify-start">
        {icon && !disabled && (
          <FontAwesomeIcon icon={faCirclePlus} className={classNames("hover:bh-text-bauhub-green-120 -m-0.5 hidden h-4 w-4 cursor-pointer group-hover:block")} size={"sm"} onClick={addNew} />
        )}
        <div className="flex flex-row items-center">
          <div className="ml-2">{versionNumber}</div>
          {otherVersionsExist && <FontAwesomeIcon icon={allVersionsVisible ? faCaretUp : faCaretDown} className="ml-2" />}
        </div>
      </div>
    </div>
  );
};

export default BhTableRowVersion;
