import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import BhTabs from "@components/tabs/BhTabs";
import UserShareboxesTab from "@/views/home/project/detail/shareboxes/UserShareboxesTab";
import ProjectShareboxesTab from "@/views/home/project/detail/shareboxes/ProjectShareboxesTab";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import RequireProjectAdminAuth from "@/views/authentication/RequireProjectAdminAuth";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { useTranslation } from "react-i18next";

interface Props {}

const ShareboxList: FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));

  useEffect(() => {
    dispatch(setSidebarCollapsed(false));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.SHAREBOXES));
  }, []);

  const tabs = [
    {
      id: 0,
      header: t("SHAREBOX.PROJECT.LIST.CREATED_BY_ME"),
      content: <UserShareboxesTab key="my-sharebox-tab" projectId={projectId} />,
      href: `user/`
    },
    isProjectAdmin && {
      id: 1,
      header: t("SHAREBOX.PROJECT.LIST.TITLE"),
      content: (
        <RequireProjectAdminAuth>
          <ProjectShareboxesTab key="project-sharebox-tab" projectId={projectId} />
        </RequireProjectAdminAuth>
      ),
      href: `project/`
    }
  ].filter(Boolean);

  // @ts-ignore
  return <BhTabs tabs={tabs} router={true} />;
};

export default ShareboxList;
