import React, { FC } from "react";
import BhCompactTableTD from "@components/table/compactTable/BhCompactTableTD";
import BhCompactTableTR from "@components/table/compactTable/BhCompactTableTR";

interface Props {
  tdCount: number;
}

const MaruDashboardTableRowLoading: FC<Props> = ({ tdCount }) => {
  return (
    <BhCompactTableTR>
      {[...Array(tdCount)].map((td, index) => (
        <BhCompactTableTD classes="text-right" key={index}>
          <div className="bh-bg-pigeon-50 h-5 w-2/3 animate-pulse rounded" />
        </BhCompactTableTD>
      ))}
    </BhCompactTableTR>
  );
};
export default MaruDashboardTableRowLoading;
