import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { getCombinedParams, IProjectNotificationCount, IUserNotification, UserNotificationType } from "@/model/IUserNotification";
import {
  fetchLatestProjectNotifications,
  fetchNewNotifications,
  fetchProjectNotificationCounts,
  fetchProjectNotifications,
  setAllProjectNotificationsSeen,
  setNotificationSeen
} from "@/api/notificationAPI";
import { BauhubBannerType } from "@/model/IProject";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { fetchCurrentUserAsync } from "@/app/store/userSlice";
import { fetchAllTasksAsync, fetchTaskAsync, fetchTaskBoardTasksWithExtraFieldsAsync } from "@/app/store/tasksSlice";
import { fetchUserUnsignedSignInvitesAsync } from "@/app/store/signInvitesSlice";

const TOLERANCE_SECONDS = 60 * 1000;

export const userNotificationsAdapter = createEntityAdapter<IUserNotification>({
  sortComparer: (a, b) => {
    return a.id > b.id ? -1 : 1;
  }
});

export interface UserNotificationsState {
  status: BhStateStatusType;
  lastPollingSent?: number;
  projectNotificationCounts: Array<IProjectNotificationCount>;
}

export const userNotificationsInitialState = userNotificationsAdapter.getInitialState<UserNotificationsState>({
  status: BhStateStatusType.INITIAL,
  lastPollingSent: Date.now() - TOLERANCE_SECONDS,
  projectNotificationCounts: []
});

function handleNewNotifications(notifications: Array<IUserNotification>, toast: boolean, state: RootState, dispatch: (arg0: any) => void) {
  const newNotifications: IUserNotification[] = notifications.filter((notification) => !notification.seen && !state.userNotifications.entities[notification.id]);
  newNotifications.forEach((notification) => {
    dispatch(addToProjectNotificationCount(notification.projectId));
    handleNotificationEvent(notification, state, dispatch);
  });

  if (toast && state.project.current.id) {
    const newNotificationsInCurrentProject = newNotifications.filter((notification) => notification.projectId === state.project.current.id);
    if (newNotificationsInCurrentProject.length === 1) {
      dispatch(
        toastFlagAdded({
          id: newNotificationsInCurrentProject[0].id,
          type: BauhubBannerType.ANNOUNCEMENT,
          disappear: true,
          translateCode: newNotificationsInCurrentProject[0].contentCode,
          params: getCombinedParams(notifications[0])
        })
      );
    } else if (newNotificationsInCurrentProject.length > 1) {
      dispatch(
        toastFlagAdded({
          id: newNotificationsInCurrentProject[0].id,
          type: BauhubBannerType.ANNOUNCEMENT,
          disappear: true,
          translateCode: "NOTIFICATION.MULTIPLE_NOTIFICATIONS",
          params: { number: newNotificationsInCurrentProject.length }
        })
      );
    }
  }
}

function handleNotificationEvent(notification: IUserNotification, state: RootState, dispatch: (arg0: any) => void) {
  if (notification.type === UserNotificationType.NEW_PRIVILEGES) {
    dispatch(fetchCurrentUserAsync());
  } else if (notification.type === UserNotificationType.SIGN_INVITE) {
    dispatch(fetchUserUnsignedSignInvitesAsync());
  } else if (notification.projectId !== state.project.current.id) {
    return;
  } else if (
    notification.type === UserNotificationType.NEW_TASK_COMMENT ||
    notification.type === UserNotificationType.TASK_COMMENT_DELETE ||
    notification.type === UserNotificationType.TASK_COMMENT_EDIT
  ) {
    if (notification.contentParams.taskBoardId && notification.contentParams.taskIdentifier && state.tasks.openedTaskInfo && state.tasks.openedTaskInfo.id + "" === notification.contentParams.taskId) {
      dispatch(fetchTaskAsync({ taskIdentifier: notification.contentParams.taskIdentifier, projectId: notification.projectId }));
    }
  } else if (notification.type === UserNotificationType.NEW_TASK_STATUS || notification.type === UserNotificationType.NEW_TASK) {
    dispatch(fetchAllTasksAsync({ includeExtraFields: true, projectId: notification.projectId }));
    dispatch(fetchTaskBoardTasksWithExtraFieldsAsync({ projectId: notification.projectId, taskBoardId: notification.contentParams.taskBoardId }));
  }
}

export const fetchNewUserNotificationsAsync = createAsyncThunk("user/notifications/fetch/new", async (_, { getState, dispatch }) => {
  const state: RootState = getState() as RootState;
  const notifications = await fetchNewNotifications(state.userNotifications.lastPollingSent);
  handleNewNotifications(notifications, true, state, dispatch);
  dispatch(setLastPollingSent(Date.now() - TOLERANCE_SECONDS));
  return notifications;
});

export const fetchProjectNotificationCountsAsync = createAsyncThunk("user/notifications/counts", async (_, { getState, dispatch }) => {
  const response = await fetchProjectNotificationCounts();
  dispatch(setProjectNotificationCounts(response));
  return response;
});

export const fetchUserNotificationsAsync = createAsyncThunk("user/notifications/fetchAll", async (projectId: EntityId, { dispatch }) => {
  if (projectId) {
    const notifications = await fetchProjectNotifications(projectId);
    dispatch(setProjectNotificationCount({ projectId: projectId, count: notifications.filter((n) => !n.seen).length }));
    return notifications;
  }
  return [];
});

export const fetchLatestProjectNotificationsAsync = createAsyncThunk("user/notifications/fetchLatestProjectNotifications", async (projectId: EntityId, { getState, dispatch }) => {
  const state: RootState = getState() as RootState;
  if (projectId) {
    const notifications = await fetchLatestProjectNotifications(projectId);
    handleNewNotifications(notifications, false, state, dispatch);
    return notifications;
  }
  return [];
});

export const setNotificationSeenAsync = createAsyncThunk("user/notification/seen", async (userNotification: IUserNotification, { dispatch }) => {
  return setNotificationSeen(userNotification);
});

export const setAllNotificationsSeenAsync = createAsyncThunk("user/notifications/seen", async (projectId: EntityId, { dispatch }) => {
  return setAllProjectNotificationsSeen(projectId).then((response) => {
    dispatch(setAllProjectNotificationsSeenLocal(projectId));
    return response;
  });
});

export const userNotificationsSlice = createSlice({
  name: "userNotifications",
  initialState: userNotificationsInitialState,
  reducers: {
    setLastPollingSent: (state, action: PayloadAction<number>) => {
      state.lastPollingSent = action.payload;
    },
    setProjectNotificationCounts: (state, action: PayloadAction<Array<IProjectNotificationCount>>) => {
      state.projectNotificationCounts = action.payload;
    },
    setProjectNotificationCount: (state, action: PayloadAction<IProjectNotificationCount>) => {
      const projectNotificationCountObj = state.projectNotificationCounts.find((c) => c.projectId === action.payload.projectId);
      if (projectNotificationCountObj) {
        projectNotificationCountObj.count = action.payload.count;
      } else {
        state.projectNotificationCounts.push(action.payload);
      }
    },
    subtractFromProjectNotificationCount: (state, action: PayloadAction<EntityId>) => {
      const projectNotificationCountObj = state.projectNotificationCounts.find((c) => c.projectId === action.payload);
      if (projectNotificationCountObj) {
        projectNotificationCountObj.count -= 1;
      }
    },
    addToProjectNotificationCount: (state, action: PayloadAction<EntityId>) => {
      const projectNotificationCountObj = state.projectNotificationCounts.find((c) => c.projectId === action.payload);
      if (projectNotificationCountObj) {
        projectNotificationCountObj.count += 1;
      } else {
        state.projectNotificationCounts.push({ projectId: action.payload, count: 1 });
      }
    },
    setAllProjectNotificationsSeenLocal: (state, action: PayloadAction<EntityId>) => {
      const projectNotifications = Object.values(state.entities).filter((notification) => {
        if (!notification) return false;
        return notification.projectId === action.payload;
      });
      projectNotifications.forEach((notification) => {
        if (notification) {
          notification.seen = true;
        }
      });

      const projectNotificationCountObj = state.projectNotificationCounts.find((c) => c.projectId === action.payload);
      if (projectNotificationCountObj) {
        projectNotificationCountObj.count = 0;
      } else {
        state.projectNotificationCounts.push({ projectId: action.payload, count: 0 });
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewUserNotificationsAsync.fulfilled, (state, action) => {
        userNotificationsAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchUserNotificationsAsync.fulfilled, (state, action) => {
        userNotificationsAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchLatestProjectNotificationsAsync.fulfilled, (state, action) => {
        userNotificationsAdapter.upsertMany(state, action.payload);
      })
      .addCase(setNotificationSeenAsync.fulfilled, (state, action) => {
        const projectNotificationCountObj = state.projectNotificationCounts.find((c) => c.projectId === action.payload.projectId);
        if (projectNotificationCountObj) {
          projectNotificationCountObj.count -= 1;
        }
        userNotificationsAdapter.upsertOne(state, action.payload);
      });
  }
});

export const {
  setLastPollingSent,
  setProjectNotificationCounts,
  subtractFromProjectNotificationCount,
  addToProjectNotificationCount,
  setAllProjectNotificationsSeenLocal,
  setProjectNotificationCount
} = userNotificationsSlice.actions;

export const {
  selectIds: selectUserNotificationIds,
  selectAll: selectAllUserNotifications,
  selectById: selectUserNotificationById
} = userNotificationsAdapter.getSelectors((state: RootState) => state.userNotifications);

export const selectProjectNotificationCounts = (state: RootState) => state.userNotifications.projectNotificationCounts as Array<IProjectNotificationCount>;

export const selectUserNotifications = createSelector([selectAllUserNotifications, (state: any, projectId?: EntityId) => projectId], (notifications, projectId) => {
  const sortedNotifications = [...notifications].sort((a, b) => (new Date(a.created) > new Date(b.created) ? -1 : 1));
  return sortedNotifications.filter((n) => (projectId ? n.projectId === projectId : true));
});

export const selectLatestUserNotifications = createSelector([selectAllUserNotifications, (state: any, projectId?: EntityId) => projectId], (notifications, projectId) => {
  const sortedNotifications = [...notifications].sort((a, b) => (new Date(a.created) > new Date(b.created) ? -1 : 1));
  return sortedNotifications.filter((n) => (projectId ? n.projectId === projectId : true)).slice(0, 25);
});

export const isThereUnseenNotifications = createSelector([selectAllUserNotifications, (state: any, projectId?: EntityId) => projectId], (notifications, projectId) => {
  return notifications.some((n) => !n.seen && n.projectId === projectId);
});

export const projectUnseenNotificationCount = createSelector([selectProjectNotificationCounts, (state: any, projectId?: EntityId) => projectId], (counts, projectId) => {
  if (projectId) {
    const projectNotificationCountObj = counts.find((c) => c.projectId === projectId);
    return projectNotificationCountObj?.count ?? 0;
  }
  return 0;
});

export default userNotificationsSlice.reducer;
