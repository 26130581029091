import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { partiesSortSet, selectCurrentProjectWorkGroupSort } from "@/app/store/project/projectWorkGroupsSlice";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import BhSortButton from "@components/sort/BhSortButton";
import { initialPartiesSort, IPartiesSort } from "@/model/IPartiesSort";

interface Props {}

const PartiesSort: FC<Props> = () => {
  const { t } = useTranslation();
  const [partiesOrdering, setPartiesOrdering] = useLocalStorage<IPartiesSort>("partiesOrdering", initialPartiesSort);
  const sort = useAppSelector(selectCurrentProjectWorkGroupSort);

  const dropdownMenuValues = [
    { value: "name", translation: t("GLOBAL.ITEM_NAME") },
    { value: "created", translation: t("GLOBAL.CREATED") }
  ];

  return (
    <div className="pb-3">
      <BhSortButton sort={sort} localStorageSort={partiesOrdering} setSortForStore={partiesSortSet} setSortForLocalStorage={setPartiesOrdering} sortValues={dropdownMenuValues} />
    </div>
  );
};

export default PartiesSort;
