import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { IFileEntity } from "@/model/files/IFileEntity";
import { ISyncCompany } from "@/model/sync/ISyncCompany";
import { ISyncProject } from "@/model/sync/ISyncProject";
import { EntityId } from "@reduxjs/toolkit";

export function fetchSyncProjectsByCompany(): Promise<Array<ISyncCompany>> {
  return bauhubGet("/sync/project/all");
}

export function fetchSyncProject(projectId: EntityId): Promise<ISyncProject> {
  return bauhubGet("/sync/project/" + projectId);
}

export function saveFileSelectToSync(fileEntityId: EntityId): Promise<IFileEntity> {
  return bauhubPost("/file/" + fileEntityId + "/sync/mark");
}

export function saveFileDeselectToSync(fileEntityId: EntityId): Promise<IFileEntity> {
  return bauhubPost("/file/" + fileEntityId + "/sync/remove");
}

export function fetchSyncedProjectIdList(): Promise<Array<EntityId>> {
  return bauhubGet("/sync/projects/status");
}




