import React, { FC, useCallback } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProject, selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { Trans, useTranslation } from "react-i18next";
import { IAct } from "@/model/IAct";
import { fetchAnyDigiDocumentPresentInWorkGroupContracts, saveNewActForPartyAngular } from "@/api/actAPI";
import { useNavigate } from "react-router-dom";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { fullPageLoadingSet } from "@/app/store/globalSlice";
import { selectActsPrivilegesForParty, selectContractPrivilegesForParty, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faReceipt } from "@fortawesome/pro-solid-svg-icons/faReceipt";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  partyId: EntityId;
}

const ActsListContainerHeaderButtons: FC<Props> = ({ partyId }) => {
  const { t } = useTranslation();
  const project = useAppSelector(selectCurrentProject);
  const navigate = useNavigate();
  const newActObject = { projectId: project.id, workGroupId: partyId } as IAct;
  const projectId = useAppSelector(selectCurrentProjectId);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const actPrivileges = useAppSelector((state) => selectActsPrivilegesForParty(state, partyId));
  const contractPrivileges = useAppSelector((state) => selectContractPrivilegesForParty(state, partyId));
  const dispatch = useAppDispatch();

  const [showNoDigidocExistModal, hideNoDigidocExistModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideNoDigidocExistModal}
      header={<h2>{t("MODAL.ACT.NO_EXISTING_DIGI_DOCUMENTS.TITLE")}</h2>}
      body={<div>{t("MODAL.ACT.NO_EXISTING_DIGI_DOCUMENTS.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.CONTINUE")}
      handleDelete={() => {
        saveAct();
      }}
    />
  ));

  const saveAct = () => {
    dispatch(fullPageLoadingSet(true));
    saveNewActForPartyAngular(newActObject)
      .then(function (newAct) {
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${newAct.projectId}/contract/${partyId}/act/${newAct.id}`);
      })
      .finally(() => {
        dispatch(fullPageLoadingSet(false));
      });
  };

  const saveNewAct = useCallback(() => {
    const nordeconOrContractHtmlTemplateModules = project.modules.filter((module) => {
      return ["NORDECON", "EMBACH", "CONTRACT_HTML_TEMPLATES"].indexOf(module.module) > -1;
    });

    if (nordeconOrContractHtmlTemplateModules.length === 2) {
      fetchAnyDigiDocumentPresentInWorkGroupContracts(partyId).then((result) => {
        var digidocPresentInWorkGroupContracts = result.value;
        if (digidocPresentInWorkGroupContracts) {
          saveAct();
        } else {
          showNoDigidocExistModal();
        }
      });
    } else {
      saveAct();
    }
  }, []);

  return (
    <div className="flex flex-row">
      {(isProjectAdmin || contractPrivileges.isRead) && (
        <BhSecondaryButton icon={faReceipt} buttonProps={{ onClick: () => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/contract/${partyId}`) }}>
          <Trans>CONTRACT.CONTRACT</Trans>
        </BhSecondaryButton>
      )}
      {(isProjectAdmin || actPrivileges.isWrite) && (
        <BhPrimaryButton icon={faPlus} buttonProps={{ onClick: saveNewAct }}>
          <Trans>ACT.ADD_NEW</Trans>
        </BhPrimaryButton>
      )}
    </div>
  );
};

export default ActsListContainerHeaderButtons;
