import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignature } from "@fortawesome/pro-regular-svg-icons/faSignature";

const BhStatusCellSigned: FC = () => {
  return (
    <div className="bh-bg-royal-blue-light flex h-14 w-12 flex-row items-center justify-center">
      <FontAwesomeIcon icon={faSignature} className="bh-text-royal-blue h-[14px] w-[20px]" />
    </div>
  );
};

export default BhStatusCellSigned;
