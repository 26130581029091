import React, { FC } from "react";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import BhInputSimple from "@components/input/BhInputSimple";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  addChecklistBaseToNewProjectsAsync,
  changeChecklistBaseCategoryAsync,
  changeChecklistBaseLanguageAsync,
  renameChecklistBaseAsync,
  saveChecklistBaseVersionAsync,
  selectCompanyChecklistBaseById
} from "@/app/store/checklist/checklistsSlice";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { BauhubBannerType } from "@/model/IProject";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhCheckboxWithText from "@components/checkboxes/BhCheckboxWithText";
import { ChecklistBaseVersionStatus } from "@/model/checklist/IChecklistBaseVersion";
import ChecklistBaseCategoryTagInputDropdown from "@components/checklists/category/ChecklistBaseCategoryTagInputDropdown";
import { ChecklistBaseCategory } from "@/model/checklist/IChecklistBase";

interface Props {
  companyId: EntityId;
  checklistBaseId: EntityId;
  checklistBaseVersionId: EntityId;
}

const ChecklistBaseGeneralInfo: FC<Props> = ({ companyId, checklistBaseId, checklistBaseVersionId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const checklistBase = useAppSelector((state) => selectCompanyChecklistBaseById(state, checklistBaseId));
  const checklistBaseVersion = checklistBase && checklistBase.checklistBaseVersions && checklistBase.checklistBaseVersions.find((cbv) => cbv.id === checklistBaseVersionId);
  const isBauhubChecklistBase = checklistBase && checklistBase.bauhubChecklistBase;

  if (!checklistBase || !checklistBaseVersion || !checklistBase.checklistBaseVersions) {
    return null;
  }

  const languages = [
    { code: "et_EE", language: t("CHECKLIST.LANGUAGE.ET_EE") },
    { code: "en_EN", language: t("CHECKLIST.LANGUAGE.EN_EN") },
    { code: "lv_LV", language: t("CHECKLIST.LANGUAGE.LV_LV") }
    // { code: "ru_RU", language: t("CHECKLIST.LANGUAGE.RU_RU") }
  ].filter((lng) => lng.code !== checklistBase.language);

  const renameChecklistBase = (nameObject: any) => {
    if (!nameObject.type.length) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          children: t("INPUT.NOT_EMPTY")
        })
      );
      return;
    }
    const checklistBaseToSave = { ...checklistBase, ...nameObject };
    dispatch(renameChecklistBaseAsync({ companyId: companyId, checklistBase: checklistBaseToSave }));
  };

  const changeChecklistBaseLanguage = (languageObject: any) => {
    const checklistBaseToSave = { ...checklistBase, language: languageObject.code };
    dispatch(changeChecklistBaseLanguageAsync({ companyId: companyId, checklistBase: checklistBaseToSave })).then(() => {
      if (checklistBaseVersion.status !== ChecklistBaseVersionStatus.FINAL) {
        const language = languageObject.code.split("_")[0];
        const customLabelToSave = {
          timeLabel: { value: t("CHECKLIST.CHECK_TIME", { lng: language }), visible: checklistBaseVersion.customLabel.timeLabel.visible },
          createdByLabel: { value: t("CHECKLIST.CREATED_BY", { lng: language }), visible: checklistBaseVersion.customLabel.createdByLabel.visible },
          participantsLabel: { value: t("CHECKLIST.PARTICIPANTS", { lng: language }), visible: checklistBaseVersion.customLabel.participantsLabel.visible },
          siteNameLabel: { value: t("CHECKLIST.SITE_NAME", { lng: language }), visible: checklistBaseVersion.customLabel.siteNameLabel.visible }
        };
        const baseVersionToSave = { ...checklistBaseVersion, ...{ customLabel: customLabelToSave } };
        dispatch(saveChecklistBaseVersionAsync({ companyId: companyId, checklistBaseVersion: baseVersionToSave }));
      }
    });
  };

  const changeChecklistBaseCategory = (newCategory: ChecklistBaseCategory) => {
    const checklistBaseToSave = { ...checklistBase, category: newCategory };
    dispatch(changeChecklistBaseCategoryAsync({ companyId: companyId, checklistBase: checklistBaseToSave }));
  };

  const toggleAddBaseToNewProjects = (changedObject: any) => {
    const checklistBaseToSave = { ...checklistBase, ...changedObject };
    dispatch(addChecklistBaseToNewProjectsAsync({ companyId: companyId, checklistBase: checklistBaseToSave }));
  };

  return (
    <div className="bh-border-pigeon-20 w-full border-b py-8 px-36">
      <h2 className="bh-text-deep-ocean-60 m-0">{t("CHECKLIST.GENERAL_INFO")}</h2>
      <div className="flex w-4/5 flex-col">
        <div className="mb-1">
          <BhSideBySideInput label={t("CHECKLIST.BASE.NAME")}>
            <BhInputSimple initialValue={checklistBase.type} property={"type"} saveCallback={renameChecklistBase} />
          </BhSideBySideInput>
        </div>
        <div className="mb-1">
          <BhSideBySideInput label={t("CHECKLIST_BASE.CATEGORY")}>
            <ChecklistBaseCategoryTagInputDropdown currentCategory={checklistBase.category} saveCallback={changeChecklistBaseCategory} />
          </BhSideBySideInput>
        </div>
        <div className="mb-1">
          <BhSideBySideInput label={t("CHECKLIST.CHECKLIST_LANGUAGE")}>
            <BhDropdown
              buttonClasses={"w-48"}
              button={
                <button className="min-w-70px bh-border-smoke-40 hover:bh-border-pigeon-70 flex h-9 w-full items-center justify-between rounded border p-2">
                  {t("CHECKLIST.LANGUAGE." + checklistBase.language.toUpperCase())}
                  <FontAwesomeIcon icon={faCaretDown} />
                </button>
              }
              menu={<BhDropdownMenu values={languages} closeOnItemClick={true} type={BhDropdownTypeEnum.STRING} onSelect={changeChecklistBaseLanguage} textProperty={"language"} widthClass={"w-48"} />}
            />
          </BhSideBySideInput>
        </div>
        {!checklistBase.createdInProjectId && (
          <div className="mb-1">
            <BhSideBySideInput>
              <div className="-ml-4">
                <BhCheckboxWithText
                  text={t("CHECKLIST.BASE.ADD_TO_NEW_PROJECTS")}
                  isChecked={checklistBase.addToNewProjects}
                  property={"addToNewProjects"}
                  onChange={toggleAddBaseToNewProjects}
                  disabled={isBauhubChecklistBase}
                />
              </div>
            </BhSideBySideInput>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChecklistBaseGeneralInfo;
