interface IConfiguration {
  REACT_APP_HOME: string;
  REACT_APP_API_URL: string;
  BUILD_INFO: string;
  REACT_APP_PUBLIC_API_URL: string;
  REACT_APP_BASE_URL: string;
  REACT_APP_CAS_URL: string;
  REACT_APP_CAS_CALLBACK_URL: string;
  REACT_APP_OLD_FE: string;
  PUBLIC_URL: string;
}

export class ConfigSingleton {
  private static instance: ConfigSingleton;
  private configuration: IConfiguration;

  private constructor() {
    this.configuration = {
      REACT_APP_HOME: "",
      REACT_APP_API_URL: "",
      BUILD_INFO: "",
      REACT_APP_PUBLIC_API_URL: "",
      REACT_APP_BASE_URL: "",
      REACT_APP_CAS_URL: "",
      REACT_APP_CAS_CALLBACK_URL: "",
      REACT_APP_OLD_FE: "",
      PUBLIC_URL: ""
    };
  }

  public static getInstance(): ConfigSingleton {
    if (!ConfigSingleton.instance) {
      ConfigSingleton.instance = new ConfigSingleton();
    }
    return ConfigSingleton.instance;
  }

  public getConfig(): IConfiguration {
    return this.configuration;
  }

  public updateConfig(newConfig: Partial<IConfiguration>): void {
    this.configuration = {
      ...this.configuration,
      ...newConfig,
    };
  }
}






