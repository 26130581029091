import { FileEntityType, IFileEntity, IFolderFileEntity, IUploadFileEntity } from "@/model/files/IFileEntity";
import { IFileConfirmationFile } from "@/model/confirmations/IFileConfirmationFile";
import { EntityId } from "@reduxjs/toolkit";

//PDFTron contentTypes
const previewDwgContentTypes = [
  "image/x-dwg",
  "application/acad",
  "application/x-acad",
  "application/autocad_dwg",
  "application/dwg",
  "application/x-dwg",
  "application/x-autocad",
  "image/vnd.dwg",
  "drawing/dwg"
];

const officeDocumentContentTypes = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
];

const previewContentTypes = ["application/pdf"];

//PDFTRON extensions
export const pdftronExtensions = [
  "pdf",
  "fdf",
  "xfdf",
  "doc",
  "docx",
  "xlsx",
  "pptx",
  "pub",
  "dwg",
  "dxf",
  "dgn",
  "xls",
  "doc",
  "ppt",
  "rtf",
  "odt",
  "ods",
  "odp",
  "wpf",
  "mht",
  "svg",
  "txt",
  "oxps",
  "xml",
  "tif",
  "tiff"
];
export const officeExtensions = ["doc", "docx", "xlsx", "pptx", "xls", "ppt"];

const serverExtensions = ["dwg", "dwf", "txt", "rtf", "tif", "tiff", "odt", "ods", "odp"];
const cadExtensions = ["dwg", "dxf", "cad", "ics", "ifc", "rvt", "nwd"];
const internalLinkTypes = ["FORM"];
const bimExtensions = ["ifc", "ifczip"];

export function canPreviewContentType(fileEntity: IFileEntity) {
  if (!fileEntity || !fileEntity.name) return false;
  const fileName = fileEntity.name.toLowerCase();
  const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
  const validPdfTronPreviewType =
    previewContentTypes.indexOf(fileEntity.contentType) !== -1 || previewDwgContentTypes.indexOf(fileEntity.contentType) !== -1 || officeDocumentContentTypes.indexOf(fileEntity.contentType) !== -1;
  const validPdfTronPreviewExtension = pdftronExtensions.indexOf(extension) !== -1;
  return validPdfTronPreviewType && validPdfTronPreviewExtension;
}

export function canOpenInPdftron(fileEntity: IFileEntity) {
  if (!fileEntity || !fileEntity.name) return false;
  const fileName = fileEntity.name.toLowerCase();
  const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
  return pdftronExtensions.indexOf(extension) !== -1;
}

export function canOpenInXeokit(fileEntity: IFileEntity) {
  if (!fileEntity || !fileEntity.name) return false;
  const fileName = fileEntity.name.toLowerCase();
  const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
  return bimExtensions.indexOf(extension) !== -1;
}

export function isIfc(fileEntity: IFileEntity) {
  if (!fileEntity || !fileEntity.name) return false;
  const fileName = fileEntity.name.toLowerCase();
  const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
  return extension === "ifc" || extension === "ifczip";
}

export function isXkt(fileEntity: IFileEntity) {
  if (!fileEntity || !fileEntity.name) return false;
  const fileName = fileEntity.name.toLowerCase();
  const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
  return extension === "xkt";
}

export function shouldUsePdftronServer(fileEntity?: IFileEntity) {
  if (!fileEntity || !fileEntity.name) return false;
  const ext = fileEntity.name.split(".").pop();
  return ext && serverExtensions.indexOf(ext) !== -1;
}

export function canPreviewDwgContentType(fileEntity: IFileEntity) {
  if (!fileEntity || !fileEntity.name) return false;
  const fileName = fileEntity.name.toLowerCase();
  const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
  return previewDwgContentTypes.indexOf(fileEntity.contentType) !== -1 || cadExtensions.indexOf(extension) !== -1;
}

export function isOfficeDocument(extension: string | undefined) {
  if (!extension) {
    return false;
  }
  return officeExtensions.indexOf(extension) !== -1;
}

export function isInternalLink(fileEntity: IFileEntity) {
  return internalLinkTypes.indexOf(fileEntity.type) !== -1;
}

export function isImage(fileEntity: IFileEntity) {
  const imageContentTypes = ["image/png", "image/jpg", "image/jpeg", "image/pjpeg", "image/gif"];
  return imageContentTypes.indexOf(fileEntity.contentType) !== -1;
}

export function isDirectory(fileEntity: IFileEntity | IUploadFileEntity | IFileConfirmationFile) {
  const directoryTypes = [FileEntityType.DIR, FileEntityType.ROOT_DOCUMENT_DIR, FileEntityType.ROOT_DIR];
  return directoryTypes.includes(fileEntity.type);
}

export function findParentFolders(folders: Array<IFolderFileEntity | IFileEntity>, folderId: EntityId | null) {
  let parents = [];
  while (folderId) {
    if (folderId) {
      // TODO: refactor
      const folder = folders.find((fo) => fo.id === folderId);
      if (folder) {
        parents.push(folder);
        folderId = folder.parentFileEntityId;
      } else {
        folderId = null;
      }
    }
  }
  return parents.reverse();
}
