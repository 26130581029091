import React, { FC, useState } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { IChecklistRound } from "@/model/checklist/IChecklistRound";
import { faSignature } from "@fortawesome/pro-regular-svg-icons/faSignature";
import { IFileEntity } from "@/model/files/IFileEntity";
import { IContainerCreateRequest, IUserSigningInfo } from "@/model/container/IContainerCreateRequest";
import { generatePdfForChecklistRound } from "@/api/checklist/checklistAPI";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { findFileNameForContainer } from "@/utilities/fileEntityUtilities";
import { fullPageLoadingSet } from "@/app/store/globalSlice";
import { createContainerForChecklistAsync } from "@/app/store/checklist/checklistsSlice";
import BhContainerModal from "@/views/home/project/detail/container/modals/BhContainerModal";
import { IChecklist } from "@/model/checklist/IChecklist";
import { useTranslation } from "react-i18next";
import BhTooltip from "@components/BhTooltip";
import { convertChecklistParticipantsToUsers } from "@/model/checklist/IChecklistParticipant";
import { isValidEmail } from "@/utilities/jsUtilities";

interface Props {
  checklist: IChecklist;
  checklistRound: IChecklistRound;
}

const ChecklistHeaderRoundSigningContainer: FC<Props> = ({ checklist, checklistRound }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const [containerModalOpen, setContainerModalOpen] = useState(false);
  const [containerFiles, setContainerFiles] = useState([] as Array<IFileEntity>);
  const [signers, setSigners] = useState<Array<IUserSigningInfo>>([]);
  const [requestObject, setRequestObject] = useState({} as IContainerCreateRequest);

  if (checklistRound.containerId) return null;

  const openContainerModal = () => {
    dispatch(fullPageLoadingSet(true));
    generatePdfForChecklistRound(projectId, checklistRound.id).then((generatedFile) => {
      if (checklist.checklistParticipants && checklist.checklistParticipants.length > 0) {
        const possibleSigners = checklist.checklistParticipants.filter((participant) => participant.userEntityId || (!participant.userEntityId && isValidEmail(participant.name)));
        const signersToSet = convertChecklistParticipantsToUsers(possibleSigners).map((user) => {
          return { ...user, userExists: Boolean(user.id) };
        });
        setSigners(signersToSet);
      }
      const files = [generatedFile];
      setContainerFiles(files);
      setRequestObject({ containerName: findFileNameForContainer(files), checklistRoundId: checklistRound.id } as IContainerCreateRequest);
      dispatch(fullPageLoadingSet(false));
      setContainerModalOpen(true);
    });
  };

  const containerCreatedCallack = () => {
    setContainerModalOpen(false);
  };

  return (
    <div>
      <BhTooltip body={t("GLOBAL.SIGN")}>
        <BhIconButton icon={faSignature} buttonProps={{ onClick: openContainerModal }} />
      </BhTooltip>
      {containerModalOpen && (
        <div className="z-100 fixed top-0 left-0 h-full w-full">
          <BhContainerModal
            files={containerFiles}
            requestObject={requestObject}
            predefinedSigners={signers}
            closeModalCallback={() => setContainerModalOpen(false)}
            createContainerCallback={createContainerForChecklistAsync}
            containerCreatedCallback={containerCreatedCallack}
            hasFileDeletePermission={false}
          />
        </div>
      )}
    </div>
  );
};

export default ChecklistHeaderRoundSigningContainer;
