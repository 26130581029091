import React, { FC, ReactElement, useEffect } from "react";
import MntMpAlusedTable from "@components/form/fields/oldCustomFields/mntTables/MntMpAlusedTable";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentFormType, selectMntTableSumRowValuesByFormType, selectValueByPropertyFromCurrentFormData } from "@/app/store/form/formSlice";
import { createObjectId } from "@/utilities/formUtilities";
import MntMpPinnasTable from "@components/form/fields/oldCustomFields/mntTables/MntMpPinnasTable";
import MntMpKraavidTable from "@components/form/fields/oldCustomFields/mntTables/MntMpKraavidTable";
import MntMpDreenTable from "@components/form/fields/oldCustomFields/mntTables/MntMpDreenTable";
import MntMpMuldTable from "@components/form/fields/oldCustomFields/mntTables/MntMpMuldTable";
import MntMpKandevoimeTable from "@components/form/fields/oldCustomFields/mntTables/MntMpKandevoimeTable";
import MntMpTruupTables from "@components/form/fields/oldCustomFields/mntTables/MntMpTruupTables";
import MntMpKatendTable from "@components/form/fields/oldCustomFields/mntTables/MntMpKatendTable";
import { shallowEqual } from "react-redux";

interface Props {
  field: any;
  saveCallback?: Function;
  saveRowCallback?: Function;
  addRowCallback?: Function;
  removeRowCallback?: Function;
  disabled: boolean;
  isPreview?: boolean;
}

const FormMntTableField: FC<Props> = ({ field, saveRowCallback, removeRowCallback, addRowCallback, saveCallback, disabled, isPreview }) => {
  const currentFormType = useAppSelector(selectCurrentFormType);
  const formType = !isPreview ? currentFormType : field.value.formType;
  const dataTable = useAppSelector((state) => (!isPreview ? selectValueByPropertyFromCurrentFormData(state, field.property) : [{}]));
  const tableSums = useAppSelector((state) => selectMntTableSumRowValuesByFormType(state, formType), shallowEqual);

  const tableColumnsMap: Record<string, Array<string>> = {
    MNT_MP_ALUSED: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q"],
    MNT_MP_DREEN: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q"],
    MNT_MP_KATEND: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o"],
    MNT_MP_KRAAVID: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n"],
    MNT_MP_MULD: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p"],
    MNT_MP_PINNAS: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l"],
    MNT_MP_KANDEVOIME: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n"]
  };

  useEffect(() => {
    if (tableSums) {
      saveCallback && saveCallback(tableSums);
    }
  }, [tableSums]);

  const addRowBelow = (rowIndex: number) => {
    const newRowIndex = rowIndex || rowIndex === 0 ? rowIndex + 1 : 0;
    addRowCallback && addRowCallback({}, "dataTable", newRowIndex);
  };

  const removeRow = (row: any) => {
    removeRowCallback && removeRowCallback(row, "dataTable");
  };

  const saveMntMpTruupValues = (valueObject: any) => {
    saveCallback && saveCallback({ value: valueObject.value }, "dataTable." + valueObject.column);
  };

  const onPaste = (pastedRows: Array<any>, row: any, column: string) => {
    let dataTableToSave = dataTable.map((r: any) => r);
    let indexOfRow = dataTable.indexOf(row);
    const tableFields = tableColumnsMap[formType];
    pastedRows.forEach((pastedRow) => {
      let rowToSave = dataTable[indexOfRow] ? dataTable[indexOfRow] : {};
      const cellValues = pastedRow.split("\t");
      let cellValueIndex = 0;
      let fieldIndex = tableFields.indexOf(column);
      for (let i = 0; i < cellValues.length; i++) {
        if (tableFields[fieldIndex]) {
          const columnToSave = { [tableFields[fieldIndex]]: cellValues[cellValueIndex] };
          rowToSave = { ...rowToSave, ...columnToSave };
          cellValueIndex++;
          fieldIndex++;
        }
      }
      if (rowToSave._id) {
        dataTableToSave[indexOfRow] = rowToSave;
      } else {
        const newRow = { ...rowToSave, _id: createObjectId() };
        dataTableToSave.push(newRow);
      }
      indexOfRow++;
    });
    saveCallback && saveCallback({ dataTable: dataTableToSave });
  };

  const mntFormTableMap: Record<string, ReactElement> = {
    MNT_MP_ALUSED: (
      <MntMpAlusedTable
        saveRowCallback={saveRowCallback}
        dataTableRows={dataTable}
        addRowCallback={addRowBelow}
        removeRowCallback={removeRow}
        onPasteCallback={onPaste}
        dataTableColumns={tableColumnsMap[formType]}
        disabled={disabled}
      />
    ),
    MNT_MP_DREEN: (
      <MntMpDreenTable
        saveRowCallback={saveRowCallback}
        dataTableRows={dataTable}
        addRowCallback={addRowBelow}
        removeRowCallback={removeRow}
        onPasteCallback={onPaste}
        dataTableColumns={tableColumnsMap[formType]}
        disabled={disabled}
      />
    ),
    MNT_MP_KRAAVID: (
      <MntMpKraavidTable
        saveRowCallback={saveRowCallback}
        dataTableRows={dataTable}
        addRowCallback={addRowBelow}
        removeRowCallback={removeRow}
        onPasteCallback={onPaste}
        dataTableColumns={tableColumnsMap[formType]}
        disabled={disabled}
      />
    ),
    MNT_MP_MULD: (
      <MntMpMuldTable
        saveRowCallback={saveRowCallback}
        dataTableRows={dataTable}
        addRowCallback={addRowBelow}
        removeRowCallback={removeRow}
        onPasteCallback={onPaste}
        dataTableColumns={tableColumnsMap[formType]}
        disabled={disabled}
      />
    ),
    MNT_MP_PINNAS: (
      <MntMpPinnasTable
        saveRowCallback={saveRowCallback}
        dataTableRows={dataTable}
        addRowCallback={addRowBelow}
        removeRowCallback={removeRow}
        onPasteCallback={onPaste}
        dataTableColumns={tableColumnsMap[formType]}
        disabled={disabled}
      />
    ),
    MNT_MP_KANDEVOIME: (
      <MntMpKandevoimeTable
        saveRowCallback={saveRowCallback}
        dataTableRows={dataTable}
        addRowCallback={addRowBelow}
        removeRowCallback={removeRow}
        onPasteCallback={onPaste}
        dataTableColumns={tableColumnsMap[formType]}
        formType={formType}
        disabled={disabled}
      />
    ),
    MNT_MP_KATEND: (
      <MntMpKatendTable
        saveRowCallback={saveRowCallback}
        dataTableRows={dataTable}
        addRowCallback={addRowBelow}
        removeRowCallback={removeRow}
        onPasteCallback={onPaste}
        dataTableColumns={tableColumnsMap[formType]}
        disabled={disabled}
      />
    ),
    MNT_MP_TRUUP: <MntMpTruupTables dataTable={dataTable} saveCallback={saveMntMpTruupValues} disabled={disabled} />
  };

  return mntFormTableMap[formType];
};

export default FormMntTableField;
