import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { Menu } from "@headlessui/react";
import { selectSuggestedFormsForUser } from "@/app/store/form/formBasesSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { fetchSuggestedFormsForDir } from "@/api/form/formBaseAPI";
import { classNames, interleaveAndRemoveDuplicates } from "@/utilities/jsUtilities";
import { IFormBase } from "@/model/form/IFormBase";
import { IEnabledForm } from "@/model/ICompany";
import { useTranslation } from "react-i18next";

interface Props {
  currentDirId: EntityId;
  allVisibleForms: Array<IFormBase | IEnabledForm>;
  createNewForm: Function;
  filter: any;
}

const DirectoryFileListHeaderMenuNewFormSuggestions: FC<Props> = ({ currentDirId, allVisibleForms, createNewForm, filter }) => {
  const { t } = useTranslation();
  const suggestedFormsForUser = useAppSelector(selectSuggestedFormsForUser);
  const allVisibleFormTypes = allVisibleForms.map((form) => form.type);
  const allVisibleFormTypesToFormMap: Record<string, IFormBase | IEnabledForm> = allVisibleForms.reduce((acc, form) => {
    if (form.type) {
      acc[form.type] = form;
    }
    if ("code" in form && form.code) {
      acc[form.code] = form;
    }
    return acc;
  }, {} as Record<string, IFormBase | IEnabledForm>);
  const [loading, setLoading] = useState(true);
  const [suggestedForms, setSuggestedForms] = useState<Array<string>>([]);

  useEffect(() => {
    setLoading(true);
    fetchSuggestedFormsForDir(currentDirId)
      .then((result) => {
        const formsInDirWhichAreEnabled = result.filter((formName) => allVisibleFormTypes.includes(formName));
        const suggestedFormsForUserWithCurrentProjectFormTypes = suggestedFormsForUser.filter((form) => allVisibleFormTypes.includes(form));
        const forms = interleaveAndRemoveDuplicates(formsInDirWhichAreEnabled, suggestedFormsForUserWithCurrentProjectFormTypes);
        setSuggestedForms(forms.slice(0, 3));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="flex w-[440px] flex-col px-2">
      {(loading || suggestedForms.length > 1) && (
        <div className={classNames(filter.displayName?.length > 1 && "hidden")}>
          <p className="mb-0 px-2 text-sm font-bold">{t("FORMS.SUGGESTED")}</p>
          <div className="flex flex-1 flex-row gap-x-4">
            <ul className="w-full">
              {loading && (
                <div>
                  {[...Array(3)].map((_, index) => {
                    return (
                      <div key={index} className="my-0.5 flex flex-row items-center px-2 py-1">
                        <div className="h-5 w-3/4 animate-pulse rounded bg-gray-400" />
                      </div>
                    );
                  })}
                </div>
              )}
              {!loading &&
                suggestedForms.map((suggestedForm) => {
                  return (
                    <Menu.Item as={"div"} key={suggestedForm} onClick={() => createNewForm(allVisibleFormTypesToFormMap[suggestedForm])}>
                      <li className="hover:bh-bg-smoke my-0.5 cursor-pointer rounded px-2 py-1">
                        <FontAwesomeIcon icon={faArrowRight} className="pr-1.5 text-sm" />
                        {allVisibleFormTypesToFormMap[suggestedForm]?.displayName}
                      </li>
                    </Menu.Item>
                  );
                })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default DirectoryFileListHeaderMenuNewFormSuggestions;
