import React, { FC } from "react";
import { selectSearchStateStatus, selectSearchString, selectShareboxIdsInSearch } from "@/app/store/searchSlice";
import { useAppSelector } from "@/app/hooks";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhBadge from "@components/badge/BhBadge";
import { useNavigate } from "react-router-dom";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import SearchShareboxCardContainer from "@/features/searchWithDropdown/SearchShareboxCardContainer";
import { Trans } from "react-i18next";

const SearchDropdownShareboxResults: FC = () => {
  const searchResultShareboxIds = useAppSelector(selectShareboxIdsInSearch);
  const projectId = useAppSelector(selectCurrentProjectId);
  const searchString = useAppSelector(selectSearchString);
  const searchStatus = useAppSelector(selectSearchStateStatus);
  const navigate = useNavigate();

  if (!searchResultShareboxIds) return null;

  let shareboxResultIdsInDropdown = searchResultShareboxIds.length > 4 ? searchResultShareboxIds?.slice(0, 4) : searchResultShareboxIds;
  let hasMoreThanFourResults = searchResultShareboxIds.length > 4;
  let remainingShareboxIdsCount = searchResultShareboxIds.length - shareboxResultIdsInDropdown.length;

  const goToAllShareboxResults = () => {
    navigate(`/project/${projectId}/search/shareboxes?query=${searchString}`);
  };

  return (
    <div className="px-6">
      <h4 className="bh-text-deep-ocean mb-5">
        <Trans>SEARCH.SHAREBOXES</Trans>
      </h4>
      <div className="flex flex-col gap-1">
        {shareboxResultIdsInDropdown.map((shareboxId) => {
          return <SearchShareboxCardContainer key={shareboxId} shareboxId={shareboxId} />;
        })}
      </div>
      {hasMoreThanFourResults && (
        <BhTextOnlyButton buttonProps={{ classes: "mt-3", onClick: goToAllShareboxResults }}>
          <BhBadge classes="mr-1">+{remainingShareboxIdsCount}</BhBadge>
          <Trans>DASHBOARD.INFO.SEE_ALL</Trans>
        </BhTextOnlyButton>
      )}
    </div>
  );
};

export default SearchDropdownShareboxResults;
