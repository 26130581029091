import React, { FC } from "react";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhToggleButtonBar from "@components/toggle/BhToggleButtonBar";
import BhModal from "@components/modal/BhModal";
import { INewCompanyDTO } from "@/model/ICompany";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhInputWithDropdown from "@components/input/BhInputWithDropdown";
import { countriesMap } from "@/utilities/countriesMap";
import BhComboboxStackedLabel from "@components/input/BhComboboxStackedLabel";

interface Props {
  newCompany: INewCompanyDTO;
  setIsShown: (value: boolean) => void;
  onSave: Function;
  isSaveDisabled: boolean;
  onFieldChangeCallback: Function;
}

const BONewCompanyModal: FC<Props> = ({ setIsShown, onSave, isSaveDisabled, onFieldChangeCallback, newCompany }) => {
  const countries = countriesMap.map((country) => ({ country: country.country }));
  const selectedCountryStates = countriesMap.find((country) => country.country === newCompany?.companyInfo?.country)?.states;

  return (
    <BhModal
      setIsShown={setIsShown}
      header={<h2 className="bh-text-deep-ocean-80">New company</h2>}
      footer={<BhModalFooter cancelButtonText="Cancel" onCancel={setIsShown} confirmButtonText="Save" onConfirm={onSave} confirmDisabled={isSaveDisabled} />}
    >
      <div className="flex h-full flex-col gap-6 overflow-auto px-8 py-8">
        <BhInputStackedLabel initialValue={newCompany?.name} property="name" label={"Name"} onChangeCallback={onFieldChangeCallback} />
        <BhToggleButtonBar label="Plan" items={[{ value: "PRO" }, { value: "LIGHT" }]} onClickAction={onFieldChangeCallback} property="tier" selected={newCompany?.tier} />
        <h3>Contact info</h3>
        <div className="grid grid-cols-2 gap-4">
          <BhInputWithDropdown
            initialValue={newCompany?.companyInfo?.country}
            values={countries}
            property="country"
            onSelect={(changedValue: any) => onFieldChangeCallback({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
            label="Country"
          />
          <BhComboboxStackedLabel
            initialValue={newCompany?.companyInfo?.state}
            values={selectedCountryStates ? selectedCountryStates : []}
            property="state"
            onSelect={(changedValue: any) => onFieldChangeCallback({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
            label="State"
          />
          <BhInputStackedLabel
            initialValue={newCompany?.companyInfo?.city}
            property="city"
            onChangeCallback={(changedValue: any) => onFieldChangeCallback({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
            label="City"
          />
          <BhInputStackedLabel
            initialValue={newCompany?.companyInfo?.zipCode}
            property="zipCode"
            onChangeCallback={(changedValue: any) => onFieldChangeCallback({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
            label="Zip code"
          />
          <BhInputStackedLabel
            initialValue={newCompany?.companyInfo?.street}
            property="street"
            onChangeCallback={(changedValue: any) => onFieldChangeCallback({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
            label="Street"
          />
          <BhInputStackedLabel
            initialValue={newCompany?.companyInfo?.house}
            property="house"
            onChangeCallback={(changedValue: any) => onFieldChangeCallback({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
            label="House/apartment no."
          />
        </div>
        <BhInputStackedLabel
          initialValue={newCompany?.companyInfo?.email}
          property="email"
          onChangeCallback={(changedValue: any) => onFieldChangeCallback({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
          label="E-mail"
        />
        <BhInputStackedLabel
          initialValue={newCompany?.companyInfo?.phoneNumber}
          property="phoneNumber"
          onChangeCallback={(changedValue: any) => onFieldChangeCallback({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
          label="Phone number"
        />
        <h3>Register info</h3>
        <BhInputStackedLabel
          initialValue={newCompany?.companyInfo?.regCode}
          property="regCode"
          onChangeCallback={(changedValue: any) => onFieldChangeCallback({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
          label="Registration code"
        />
        <BhInputStackedLabel
          initialValue={newCompany?.companyInfo?.vatNumber}
          property="vatNumber"
          onChangeCallback={(changedValue: any) => onFieldChangeCallback({ companyInfo: { ...newCompany?.companyInfo, ...changedValue } })}
          label="VAT number"
        />
      </div>
    </BhModal>
  );
};

export default BONewCompanyModal;
