import { FC } from "react";

interface Props {
  x: number;
  y: number;
  formattedValue: any;
}

const BhCustomLeftAxisTick: FC<Props> = ({ x, y, formattedValue }) => {
  return (
    <text x={x} y={y} dy=".32em" fontFamily="Lato" fontSize="12px" fontWeight="500" textAnchor="end" fill="#495057">
      {formattedValue}
    </text>
  );
};

export default BhCustomLeftAxisTick;
