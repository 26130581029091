import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import FileNameWrapped from "../fileContainer/FileNameWrapped";

interface Props {
  classes?: string;
  oldFileName: string;
  newFileName: string;
}

const ProjectLogFileRenamedExtraContainer: FC<Props> = ({ classes, oldFileName, newFileName }) => {
  return (
    <div className={classes}>
      <div className="max-w-half">
        <span className="text-xs">
          <FileNameWrapped fileName={oldFileName} />
        </span>
      </div>
      <div className="self-center">
        <FontAwesomeIcon icon={faChevronRight} size={"xs"} className="mx-2" />
      </div>
      <div className="max-w-half">
        <span className="text-xs">
          <FileNameWrapped fileName={newFileName} />
        </span>
      </div>
    </div>
  );
};

export default ProjectLogFileRenamedExtraContainer;
