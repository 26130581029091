import React, { FC, ReactNode } from "react";
import BhLightSeparator from "@components/separator/BhLightSeparator";

interface Props {
  children: ReactNode;
}

const BhDetailContainerHeader: FC<Props> = ({ children }) => {
  return (
    <div>
      <div className="flex h-20 w-full flex-row items-center py-2 px-8">{children}</div>
      <BhLightSeparator />
    </div>
  );
};

export default BhDetailContainerHeader;
