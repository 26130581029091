import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhModal from "@components/modal/BhModal";
import {
  fetchUserMailSettingsInfoAsync,
  resetUserProfileModalsData,
  selectUserMailSettingsActiveProjectId,
  selectUserMailSettingsModalOpen,
  selectUserMailSettingsModalType,
  toggleUserProfileModalsOpen
} from "@/app/store/userPreferenceSlice";
import { fetchProjectsByCompanyAsync } from "@/app/store/companiesSlice";
import { UserEmailSettingsType } from "@/model/profile/IUserEmailSettings";
import { useTranslation } from "react-i18next";
import UserMailSettingsModalProjectsContainer from "@/views/home/profile/modals/UserMailSettingsModalProjectsContainer";
import UserMailSettingsModalContent from "@/views/home/profile/modals/UserMailSettingsModalContent";
import MailSettingsModalProjectToggleList from "@/views/home/profile/modals/signingAndOtherMailModal/MailSettingsModalProjectToggleList";

const UserMailSettingsModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dailyMailSettingsModalOpen = useAppSelector(selectUserMailSettingsModalOpen);
  const modalType = useAppSelector(selectUserMailSettingsModalType);
  const activeProjectId = useAppSelector(selectUserMailSettingsActiveProjectId);
  const isDailyMailModal = modalType === UserEmailSettingsType.DISABLE_IN_DAILY_EMAIL;
  const isTaskMailModal = modalType === UserEmailSettingsType.DISABLE_IN_TASK_EMAIL;

  useEffect(() => {
    if (dailyMailSettingsModalOpen && modalType) {
      dispatch(fetchProjectsByCompanyAsync());
      dispatch(fetchUserMailSettingsInfoAsync({ type: modalType }));
    }
  }, [dailyMailSettingsModalOpen]);

  if (!modalType) return null;

  const closeModal = () => {
    dispatch(toggleUserProfileModalsOpen({ modal: "userMailSettingsModal" }));
    dispatch(resetUserProfileModalsData({ closeModal: true }));
  };

  const modalTypeToHeaderMap: Record<UserEmailSettingsType, string> = {
    ADDING_NEW_DOCUMENTS_FOLDER_TO_DAILY_MAIL_DISABLED: "",
    ADDING_NEW_DRAWINGS_FOLDER_TO_DAILY_MAIL_DISABLED: "",
    DAILY_MAIL_PROJECT_SPECIFIC_DOCUMENTS_SELECTED: "",
    DAILY_MAIL_PROJECT_SPECIFIC_FILES_SELECTED: "",
    DAILY_MAIL_SIGNING_AND_CONFIRMATION_DISABLED: "",
    DAILY_MAIL_TASKS_DISABLED: "",
    TASK_EMAIL_TASK_BOARD_DISABLED: "",
    DISABLE_IN_DAILY_EMAIL: t("USER.INFO.DAILY_MAIL"),
    DISABLE_IN_TASK_EMAIL: t("USER.INFO.TASK_MAIL_ENABLED"),
    DISABLE_IN_SIGNING_AND_CONFIRMATION_EMAIL: t("USER.INFO.SIGNING_AND_CONFIRMATION_MAIL_ENABLED"),
    DISABLE_IN_OTHER_EMAIL: t("USER.INFO.ALL_MAIL_ENABLED")
  };

  return (
    <BhModal
      size="5xl"
      isShown={true}
      setIsShown={() => {}}
      onClose={closeModal}
      header={
        <div className="h-7">
          <h2 className="mt-1 leading-7">
            <span className="bh-text-deep-ocean-60">{t("NOTIFICATION.NOTIFICATIONS.HEADER")} – </span>
            <span className="bh-text-deep-ocean">{modalTypeToHeaderMap[modalType]}</span>
          </h2>
        </div>
      }
      children={
        <div className="min-h-120 flex max-h-full w-full flex-row self-stretch overflow-hidden">
          {(isDailyMailModal || isTaskMailModal) && <UserMailSettingsModalProjectsContainer />}
          {activeProjectId && <UserMailSettingsModalContent projectId={activeProjectId} />}
          {!isDailyMailModal && !isTaskMailModal && <MailSettingsModalProjectToggleList />}
        </div>
      }
    />
  );
};

export default UserMailSettingsModal;
