import BOHeader from "@/views/home/backoffice/views/BOHeader";
import React, { useEffect, useState } from "react";
import { boFetchYearlyInvoiceOverviewAsync, boSelectYearlyInvoiceOverview } from "@/app/store/backofficeSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhNavigationDropdown from "@components/dropdown/BhNavigationDropdown";
import BOInvoicesSpreadsheetTable from "@components/backoffice/invoices/BOInvoicesSpreadsheetTable";
import BOYearlyInvoiceOverviewChart from "@components/backoffice/invoices/BOYearlyInvoiceOverviewChart";

const BOInvoices = () => {
  const year = new Date().getFullYear();
  const yearlyInvoiceOverview = useAppSelector(boSelectYearlyInvoiceOverview);
  const dispatch = useAppDispatch();
  const [invoiceYear, setInvoiceYear] = useState(year);
  const startYear = year - 5;
  const endYear = year + 5;
  const invoiceYears = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);

  useEffect(() => {
    dispatch(boFetchYearlyInvoiceOverviewAsync(invoiceYear.toString()));
  }, [invoiceYear]);

  const noYearlyInvoiceOverview = yearlyInvoiceOverview?.data?.length === 0;

  return (
    <div className="px-16 sm:px-4">
      <BOHeader
        customHeader={
          <div className="flex items-center gap-4 py-2">
            <h1 className="m-0">Invoices</h1>
            <BhNavigationDropdown
              onMenuItemClickCallback={setInvoiceYear}
              menuItems={invoiceYears}
              value={invoiceYear.toString()}
              onLeftClick={() => setInvoiceYear(invoiceYear - 1)}
              onRightClick={() => setInvoiceYear(invoiceYear + 1)}
            />
          </div>
        }
      />

      <BOInvoicesSpreadsheetTable />
      {noYearlyInvoiceOverview && <p className="mt-2 text-center text-lg">No data</p>}
      {!noYearlyInvoiceOverview && <BOYearlyInvoiceOverviewChart />}
    </div>
  );
};

export default BOInvoices;
