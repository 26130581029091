import React, { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectShareboxMessageShareboxUrl, setSelectedShareboxUrlId } from "@/app/store/shareboxesSlice";
import BhModal from "@components/modal/BhModal";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { formatDate } from "@/utilities/dateUtility";
import { useTranslation } from "react-i18next";

const ShareboxMessageModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const shareboxUrl = useAppSelector(selectShareboxMessageShareboxUrl);

  const closeModal = useCallback(() => {
    dispatch(setSelectedShareboxUrlId(undefined));
  }, []);

  if (!shareboxUrl) {
    return null;
  }

  return (
    <BhModal
      isShown={true}
      setIsShown={() => {}}
      onClose={closeModal}
      header={
        <div className="flex flex-row items-center">
          <h2>{t("SHAREBOX.EDIT_MESSAGE")}</h2>
        </div>
      }
      footer={
        <div className="flex w-full flex-1 justify-end">
          <BhPrimaryButton buttonProps={{ onClick: closeModal }}>{t("GLOBAL.CLOSE")}</BhPrimaryButton>
        </div>
      }
    >
      <div className="px-8 pt-6 pb-4">
        <table className="w-full">
          <tbody>
            <tr className="">
              <th className="w-1/4 font-normal">{t("SHAREBOX.SHARE.MODAL.RECEIVER")}</th>
              <td className="w-3/4 font-bold">{shareboxUrl.email}</td>
            </tr>
            <tr className="">
              <th className="w-1/4 font-normal">{t("SHAREBOX.SHARE.MODAL.SENT")}</th>
              <td className="w-3/4 font-bold">{shareboxUrl.created && formatDate(shareboxUrl.created)}</td>
            </tr>
          </tbody>
        </table>
        <div className="mt-6 font-normal">{shareboxUrl.message}</div>
      </div>
    </BhModal>
  );
};

export default ShareboxMessageModal;
