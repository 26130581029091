import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { generateHash, identifyIdCard } from "@/api/verificationAPI";
import { authenticate } from "@web-eid/web-eid-library/dist/es/web-eid";
import { ICardAuthToken } from "@/views/home/profile/ProfileTabs/profileAuthSettings/ICardAuthToken";
import { fetchCurrentUserAsync, selectCurrentUser, selectCurrentUserInfo } from "@/app/store/userSlice";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { toggleUserProfileModalsOpen } from "@/app/store/userPreferenceSlice";
import BhSeparatorMarginless from "@components/separator/BhSeparatorMarginless";
import BhModalFooter from "@components/modal/BhModalFooter";

const PersonalizationIdCardTab: FC = () => {
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectCurrentUser);
  const userInfo = useAppSelector(selectCurrentUserInfo);
  const dispatch = useAppDispatch();

  const userLang = currentUser.language === "et_EE" ? "et" : currentUser.language === "ru_RU" ? "ru" : "en";

  const startPersonalizationByIdCard = async () => {
    generateHash(userInfo.userEntityId)
      .then(async (result) => {
        if (result.generatedHash) {
          await authenticate(result.generatedHash, { lang: userLang }).then(
            async (authResult: ICardAuthToken) => {
              await identifyIdCard(authResult)
                .then(async (validationResult) => {
                  if (validationResult.result === "OK") {
                    dispatch(fetchCurrentUserAsync());
                    dispatch(toggleUserProfileModalsOpen({ modal: "profileAuthSettingsPersonalizationModal" }));
                  }
                })
                .catch(() => {
                  dispatch(toggleUserProfileModalsOpen({ modal: "profileAuthSettingsPersonalizationModal" }));
                });
            },
            function (err: any) {
              let errCode = err.code ? err.code : "GLOBAL.ERROR";
              if (errCode === "ERR_WEBEID_VERSION_MISMATCH") {
                if (err.requiresUpdate?.extension) {
                  // Web eID browser extension needs to be updated
                  errCode = "ERR_WEBEID_VERSION_MISMATCH_BROWSER";
                }

                if (err.requiresUpdate?.nativeApp) {
                  // Web eID native application needs to be updated
                  errCode = "ERR_WEBEID_VERSION_MISMATCH_NATIVE";
                }
              }

              dispatch(
                toastFlagAdded({
                  id: uuidv4(),
                  type: BauhubBannerType.ERROR,
                  disappear: true,
                  translateCode: errCode
                })
              );
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.ERROR,
            disappear: true,
            translateCode: error.name
          })
        );
      });
  };

  return (
    <div>
      <div className="mt-6 px-20 pb-12 pt-1.5">{t("USER.PROFILE.INSERT_ID_CARD")}</div>
      <BhSeparatorMarginless />
      <div className="flex flex-row items-center justify-end p-4">
        <BhModalFooter
          cancelButtonText={t("GLOBAL.CANCEL") as string}
          onCancel={() => dispatch(toggleUserProfileModalsOpen({ modal: "profileAuthSettingsPersonalizationModal" }))}
          confirmButtonText={t("IDENTIFICATION.MODAL.IDENTIFY") as string}
          onConfirm={startPersonalizationByIdCard}
        />
      </div>
    </div>
  );
};

export default PersonalizationIdCardTab;
