import React, { FC, useEffect, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import BhProgressContainer from "@components/progress/BhProgressContainer";
import CreateNewPartyTemplateNameStep from "@/views/home/company/detail/companySettingsPartyTemplates/createNewPartyTemplate/CreateNewPartyTemplateNameStep";
import CreateNewPartyTemplateUsersStep from "@/views/home/company/detail/companySettingsPartyTemplates/createNewPartyTemplate/CreateNewPartyTemplateUsersStep";
import CreateNewPartyTemplateFilesStep from "@/views/home/company/detail/companySettingsPartyTemplates/createNewPartyTemplate/CreateNewPartyTemplateFilesStep";
import CreateNewPartyTemplateDocumentsStep from "@/views/home/company/detail/companySettingsPartyTemplates/createNewPartyTemplate/CreateNewPartyTemplateDocumentsStep";
import { BhProgressBulletStatus } from "@/model/utilities/BhProgressBulletStatus";
import { IPredefinedWorkGroup } from "@/model/IPredefinedWorkGroup";
import { IProgressContainerStepState } from "@/model/tabs/IProgressContainerStepState";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { saveNewPredefinedParty } from "@/api/predefinedPartiesAPI";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  companyId: EntityId;
}

const CreateNewPartyTemplateContainer: FC<Props> = ({ companyId }) => {
  const { t } = useTranslation();
  const [newPartyForm, setNewPartyForm] = useState<IPredefinedWorkGroup>({} as IPredefinedWorkGroup);
  const [steps, setSteps] = useState<Array<IProgressContainerStepState>>([]);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const navigate = useNavigate();

  const stepsInitialState = [
    { id: 0, status: BhProgressBulletStatus.CURRENT, header: t("WORKGROUP.NAME"), validation: ["name"], href: "name/", isDirty: false },
    { id: 1, status: BhProgressBulletStatus.UPCOMING, header: t("WORKGROUP.NEW.USERS"), href: "users/", isDirty: false },
    { id: 2, status: BhProgressBulletStatus.UPCOMING, header: t("WORKGROUP.NEW.ADD_FILES"), href: "files/", isDirty: false },
    { id: 3, status: BhProgressBulletStatus.UPCOMING, header: t("WORKGROUP.NEW.ADD_DOCUMENTS"), href: "documents/", isDirty: false }
  ] as Array<IProgressContainerStepState>;

  useEffect(() => {
    setNewPartyForm({ ...newPartyForm, companyId: companyId });
    setSteps(stepsInitialState);
  }, []);

  const content = [
    <CreateNewPartyTemplateNameStep newPartyForm={newPartyForm} setNewPartyForm={setNewPartyForm} />,
    <CreateNewPartyTemplateUsersStep newPartyForm={newPartyForm} setNewPartyForm={setNewPartyForm} />,
    <CreateNewPartyTemplateFilesStep newPartyForm={newPartyForm} setNewPartyForm={setNewPartyForm} companyId={companyId} />,
    <CreateNewPartyTemplateDocumentsStep newPartyForm={newPartyForm} setNewPartyForm={setNewPartyForm} companyId={companyId} />
  ];

  return (
    <BhProgressContainer
      steps={steps}
      setSteps={setSteps}
      header={t("WORKGROUP.NEW_WORKGROUP") as string}
      content={content}
      form={newPartyForm}
      onSubmit={() => saveNewPredefinedParty(newPartyForm)}
      onCancel={() => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${companyId}/work-group-templates`)}
      finalizeButtonTitle={t("WORKGROUP.NEW.CONFIRM") as string}
    />
  );
};

export default CreateNewPartyTemplateContainer;
