import React, { FC, useEffect } from "react";
import ConfirmationsListSort from "@/views/home/project/detail/confirmations/list/ConfirmationsListSort";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchAllProjectConfirmationsAsync, selectAllFileConfirmationIdsForProjectSortedFiltered } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import ConfirmationsListItemContainer from "@/views/home/project/detail/confirmations/list/ConfirmationsListItemContainer";
import { shallowEqual } from "react-redux";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import ConfirmationsListFilter from "@/views/home/project/detail/confirmations/list/ConfirmationsListFilter";

interface Props {}

const ConfirmationsProjectList: FC<Props> = () => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const confirmationIds = useAppSelector((state) => selectAllFileConfirmationIdsForProjectSortedFiltered(state, projectId), shallowEqual);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllProjectConfirmationsAsync(projectId));
  }, []);

  return (
    <BhScrollableBody header={<ConfirmationsListFilter />} keepScrollForKey="confirmationsProjectList">
      <ConfirmationsListSort />
      {confirmationIds.length > 0 && (
        <div className="pt-3">
          {confirmationIds.map((fileConfirmationId) => {
            return <ConfirmationsListItemContainer key={fileConfirmationId} fileConfirmationId={fileConfirmationId} />;
          })}
        </div>
      )}
      {confirmationIds.length === 0 && <BhNoMatchesImage>Kinnitamised puuduvad</BhNoMatchesImage>}
    </BhScrollableBody>
  );
};

export default ConfirmationsProjectList;
