import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectChecklistById } from "@/app/store/checklist/checklistsSlice";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft";
import { useNavigate } from "react-router-dom";
import ChecklistHeaderVersionDropdown from "@components/checklists/checklist/header/ChecklistHeaderVersionDropdown";
import ChecklistHeaderRoundInfo from "@components/checklists/checklist/header/ChecklistHeaderRoundInfo";
import ChecklistHeaderRoundActions from "@components/checklists/checklist/header/ChecklistHeaderRoundActions";
import ChecklistHeaderContainerStatus from "@components/checklists/checklist/header/ChecklistHeaderContainerStatus";
import ChecklistRoundProgressBar from "../../ChecklistRoundProgressBar";
import { EntityId } from "@reduxjs/toolkit";
import ChecklistHeaderArchivedStatus from "@components/checklists/checklist/header/ChecklistHeaderArchivedStatus";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";

interface Props {
  checklistId: EntityId;
  checklistRoundId: EntityId;
}

const ChecklistHeader: FC<Props> = ({ checklistId, checklistRoundId }) => {
  const navigate = useNavigate();
  const projectId = useAppSelector(selectCurrentProjectId);
  const checklist = useAppSelector((state) => selectChecklistById(state, checklistId));
  const checklistRound = checklist && checklist.checklistRounds && checklist.checklistRounds.find((round) => round.id === checklistRoundId);
  const isArchived = checklist?.archived;

  useDocumentTitle(checklist?.name);

  if (!checklist || !checklist.checklistRounds || !checklistRound) {
    return null;
  }

  const goBack = () => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklists/list/view?category=` + checklist.category);
  };

  return (
    <div className="flex flex-col">
      <div className="flew-row flex w-full items-center justify-between p-4">
        <div className="flex flex-row items-center">
          <BhIconButton icon={faArrowLeft} buttonProps={{ classes: "mr-5", onClick: goBack }} />
          <ChecklistHeaderVersionDropdown checklistRounds={checklist.checklistRounds} currentRoundId={checklistRound.id} currentRoundNumber={checklistRound.roundNumber} />
          <ChecklistHeaderRoundInfo checklist={checklist} />
        </div>
        <ChecklistHeaderRoundActions checklist={checklist} checklistRound={checklistRound} />
      </div>
      {checklistRound.checkpointValue.total > 0 && (
        <div>
          <ChecklistRoundProgressBar checkpointValue={checklistRound.checkpointValue} />
        </div>
      )}
      {(!checklistRound.checkpointValue || checklistRound.checkpointValue.total === 0) && <div className="bh-border-pigeon-20 border-b"></div>}
      {checklistRound.digiDocContainer && checklistRound.digiDocContainer.id && <ChecklistHeaderContainerStatus checklistRound={checklistRound} />}
      {isArchived && <ChecklistHeaderArchivedStatus />}
    </div>
  );
};

export default ChecklistHeader;
