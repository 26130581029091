import React, { Dispatch, FC, SetStateAction } from "react";
import BhModal from "@components/modal/BhModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { Trans, useTranslation } from "react-i18next";
import BhModalFooter from "@components/modal/BhModalFooter";

interface Props {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleDelete: Function;
}
const AddPartyConfirmationModal: FC<Props> = ({ setIsOpen, handleDelete }) => {
  const { t } = useTranslation();

  return (
    <BhModal
      setIsShown={setIsOpen}
      header={
        <div className="flex items-center gap-4">
          <FontAwesomeIcon icon={faExclamationTriangle} className="bh-text-warning-yellow" size="lg" />
          <h2>
            <Trans>WORKGROUP.CONFIRM_AUTHORITY_ADD.TITLE</Trans>
          </h2>
        </div>
      }
      footer={
        <BhModalFooter
          confirmButtonText={`${t("MODAL.DEFAULT.CONFIRMATION.OK")}`}
          onConfirm={() => handleDelete()}
          cancelButtonText={`${t("MODAL.DEFAULT.CONFIRMATION.CANCEL")}`}
          onCancel={() => {
            setIsOpen(false);
          }}
          isWarningButton={true}
        />
      }
    >
      <p className="p-8">
        <Trans>WORKGROUP.CONFIRM_AUTHORITY_ADD.BODY</Trans>
      </p>
    </BhModal>
  );
};

export default AddPartyConfirmationModal;
