import React, { FC } from "react";
import { ITask } from "@/model/taskBoard/ITask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareKanban } from "@fortawesome/pro-regular-svg-icons/faSquareKanban";
import { classNames } from "@/utilities/jsUtilities";
import BhStackedUserIcons from "@components/user/BhStackedUserIcons";
import { ITaskBoard } from "@/model/taskBoard/ITaskBoard";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUser } from "@/app/store/userSlice";
import { getUserFullName } from "@/model/IUser";

interface Props {
  taskBoard: ITaskBoard;
  onClick: Function;
  isPersonalTaskBoard: boolean;
}

const TaskModalTaskBoardContainer: FC<Props> = ({ taskBoard, onClick, isPersonalTaskBoard }) => {
  const currentUser = useAppSelector(selectCurrentUser);

  const setTaskBoardForTask = (taskBoard: ITaskBoard) => {
    let taskToSet = { taskBoardId: taskBoard.id } as ITask;
    if (isPersonalTaskBoard) {
      taskToSet = { ...taskToSet, assignee: currentUser.id, assigneeFullName: getUserFullName(currentUser) };
    }
    onClick(taskToSet);
  };

  return (
    <div
      className={classNames("h-18 hover:bh-bg-smoke flex flex-row items-center justify-between pl-7 pr-12 hover:cursor-pointer", !isPersonalTaskBoard && "bh-border-pigeon-40 border-t")}
      key={taskBoard.id}
      onClick={() => setTaskBoardForTask(taskBoard)}
    >
      <div className="flex flex-row items-center">
        <FontAwesomeIcon icon={faSquareKanban} className="bh-text-pigeon-60 mr-7" />
        <div className="text-18px bh-text-deep-ocean font-bold leading-6">{taskBoard.name}</div>
      </div>
      {!isPersonalTaskBoard && (
        <div>
          <BhStackedUserIcons users={taskBoard.users} />
        </div>
      )}
    </div>
  );
};

export default TaskModalTaskBoardContainer;
