import React, { FC } from "react";
import { ProjectStatus } from "@/model/IProject";
import { selectProjectById, toggleFavouriteProjectAsync } from "@/app/store/companiesSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import BhIconButton from "@components/buttons/BhIconButton";
import { classNames } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";
import BhTooltip from "@components/BhTooltip";
import { faBookmark as faBookmarkSolid } from "@fortawesome/pro-solid-svg-icons/faBookmark";
import { faBookmark as faBookmarkRegular } from "@fortawesome/pro-regular-svg-icons/faBookmark";

interface Props {
  projectId: EntityId;
  showOnlyOnHover?: boolean;
  classes?: string;
}

const ProjectListItemPin: FC<Props> = ({ projectId, showOnlyOnHover, classes }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => selectProjectById(state, projectId));

  if (!project) {
    return null;
  }

  const icon = project.pinned ? faBookmarkSolid : faBookmarkRegular;

  const toggleProjectPinned = () => {
    dispatch(toggleFavouriteProjectAsync(project));
  };

  return (
    <div className={classNames("relative cursor-pointer items-center self-center rounded-full text-right", classes)}>
      {project.status === ProjectStatus.IN_PROGRESS && (
        <BhTooltip body={project.pinned ? t("GLOBAL.SET_NOT_PINNED") : t("GLOBAL.SET_PINNED")}>
          <BhIconButton
            icon={icon}
            withBackground={false}
            buttonProps={{
              classes: classNames(!showOnlyOnHover && project.pinned ? "opacity-100" : "opacity-0", "self-center duration-200 group-hover:text-gray-700 group-hover:opacity-100 "),
              onClick: (e: any) => {
                e.preventDefault();
                toggleProjectPinned();
              }
            }}
          />
        </BhTooltip>
      )}
    </div>
  );
};

export default ProjectListItemPin;
