import React, { FC, useEffect, useRef, useState } from "react";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { IFileEntity, IFileMatch, RevisionFileVersion } from "@/model/files/IFileEntity";
import { classNames } from "@/utilities/jsUtilities";
import BhSearchInput from "@components/input/BhSearchInput";
import BhIconButton from "@components/buttons/BhIconButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import FileNameLink from "@/features/fileContainer/FileNameLink";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { useTranslation } from "react-i18next";
import BhFixedDropdownParent from "@components/dropdown/BhFixedDropdownParent";
import { useOuterClick } from "@/utilities/hooks/useOuterClick";

interface Props {
  fileMatch: IFileMatch;
  version: RevisionFileVersion;
  showVersionNumber?: boolean;
  isUnusedFileContainer?: boolean;
  unusedDirectoryFiles?: Array<IFileEntity>;
  fileMatchSelected?: Function;
}

const RevisionModalFileCard: FC<Props> = ({ fileMatch, version, showVersionNumber, isUnusedFileContainer, unusedDirectoryFiles, fileMatchSelected }) => {
  const { t } = useTranslation();
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [availableFiles, setAvailableFiles] = useState([] as Array<IFileEntity>);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const isOldVersion = version === RevisionFileVersion.OLD;
  const fileEntity = isOldVersion ? fileMatch.matchingFile : fileMatch.file;
  const fileVersion = isOldVersion ? fileMatch.matchingFile.revision : fileMatch.matchingFile?.revision + 1 || 1;
  const classes = isOldVersion ? "bh-bg-pigeon-60" : "bh-bg-success-green-110";

  const closeSearch = () => {
    isSearchActive && setIsSearchActive(false);
    isDropdownOpen && setIsDropdownOpen(false);
  };

  const outsideClickRef = useOuterClick(() => closeSearch());

  useEffect(() => {
    closeSearch();
  }, [fileMatch]);

  const handleClick = (e: React.MouseEvent, value: boolean) => {
    e.stopPropagation();
    setIsSearchActive(value);
    setTimeout(() => {
      searchInputRef?.current?.focus();
    }, 100);
  };

  const onSearchChange = (filter: any) => {
    if (filter.name) {
      const filteredFiles =
        unusedDirectoryFiles &&
        unusedDirectoryFiles.filter((file) => {
          return file.name.toLowerCase().includes(filter.name.toLowerCase());
        });
      if (filteredFiles && filteredFiles.length > 0) {
        setAvailableFiles(filteredFiles);
        setIsDropdownOpen(true);
      } else {
        setIsDropdownOpen(false);
      }
    } else {
      setIsDropdownOpen(false);
    }
  };

  const onMatchingFileSelect = (selectedMatchingFile: IFileEntity) => {
    const newFileMatch = { file: fileMatch.file, matchingFile: selectedMatchingFile } as IFileMatch;
    fileMatchSelected && fileMatchSelected(newFileMatch);
    closeSearch();
  };

  return (
    <div className="bh-bg-white relative flex h-9 w-full items-center rounded">
      <BhTag classes={classNames(classes, isUnusedFileContainer && "ml-1.5", "absolute w-10 -left-14 l-h-18px")} type={BhTagType.CUSTOM}>
        <div className="flex w-full flex-row items-center justify-center">{isOldVersion ? t("GLOBAL.OLD") : t("GLOBAL.NEW")}</div>
      </BhTag>
      <div className={classNames("flex w-full items-center justify-between", isOldVersion && !isSearchActive && "cursor-text")} onClick={(e: React.MouseEvent) => handleClick(e, true)}>
        {isOldVersion && isSearchActive ? (
          <div className="bh-bg-pigeon-40 flex w-full">
            <div className="relative w-full" ref={outsideClickRef}>
              <BhSearchInput property="name" placeholder={t("REVISIONS.SEARCH_PLACEHOLDER") as string} inputClasses="bh-bg-white" onChangeCallback={onSearchChange} inputRef={searchInputRef} />
              {isDropdownOpen && (
                <BhFixedDropdownParent onOutsideClick={() => setIsDropdownOpen(false)}>
                  <BhDropdownMenu type={BhDropdownTypeEnum.FILE} values={availableFiles} onSelect={onMatchingFileSelect} numberOfItemsDisplayed={5} customDropdown={true} widthClass={"w-full"} />
                </BhFixedDropdownParent>
              )}
            </div>
            <BhIconButton icon={faXmark} buttonProps={{ classes: "ml-2 grow", onClick: (e: React.MouseEvent) => handleClick(e, false) }} />
          </div>
        ) : (
          <>
            <div className="ml-2 flex flex-row items-center">
              <BhFileCardIcon fileEntity={fileEntity} fileCardSize={FileCardSize.MEDIUM} />
              <div className="text-12px max-w-420px px ml-1 cursor-default">
                <FileNameLink fileEntity={fileEntity} disableOnClickHandler={true} />
              </div>
            </div>
            {showVersionNumber && <span className="text-12px bh-text-deep-ocean pr-2 font-bold leading-6">V{fileVersion}</span>}
          </>
        )}
      </div>
    </div>
  );
};

export default RevisionModalFileCard;
