import React from "react";
import { ITabCardType } from "@components/backoffice/company/projects/BOCompanyProjectsTabCards";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglass, faSnooze } from "@fortawesome/pro-regular-svg-icons";
import { faFlagCheckered } from "@fortawesome/pro-solid-svg-icons";
import BOInvoiceTabCard from "@components/backoffice/invoices/BOInvoiceTabCard";
import { useParams } from "react-router-dom";
import { useAppSelector } from "@/app/hooks";
import { boSelectYearlyTrialOverview } from "@/app/store/backofficeSlice";
import { formatCurrency } from "@/utilities/accountingUtilities";

const BOTrialTabCards = ({}) => {
  const trials = useAppSelector(boSelectYearlyTrialOverview);
  const { month } = useParams();
  const currentTrialRow = trials.rows?.find((trial) => trial.yearMonth === month);
  const companiesOnTrial = currentTrialRow?.ongoingTrials.length || 0;
  const endedTrials = currentTrialRow?.endedTrials.filter((trial) => trial.projectCount !== 0).length || 0;
  const inactiveTrials = currentTrialRow?.endedTrials.filter((trial) => trial.projectCount === 0).length || 0;

  const formatSum = (sum: number) => {
    return formatCurrency(sum, { symbol: "€", thousandsSeparator: " ", decimalSeparator: ",", significantDigits: 2 }, true);
  };

  const trialStatusTabCards = [
    {
      type: ITabCardType.WARNING,
      title: "On trial",
      value: companiesOnTrial,
      icon: <FontAwesomeIcon icon={faHourglass} className="bh-text-warning-yellow-120" />
    },
    {
      type: ITabCardType.SUCCESS,
      title: "Trials ended",
      value: endedTrials,
      icon: <FontAwesomeIcon icon={faFlagCheckered} className="bh-text-deep-ocean" />
    },
    {
      type: ITabCardType.DEFAULT,
      title: "Inactive trials",
      value: inactiveTrials,
      icon: <FontAwesomeIcon icon={faSnooze} className="bh-text-deep-ocean" />
    }
  ];

  const trialsTurnoverTabCards = [
    {
      type: ITabCardType.DEFAULT,
      title: "Estimated turnover",
      value: formatSum(currentTrialRow?.estimatedTurnover || 0)
    },
    {
      type: ITabCardType.DEFAULT,
      title: "Actual turnover",
      value: formatSum(currentTrialRow?.actualTurnover || 0)
    },
    {
      type: ITabCardType.DEFAULT,
      title: "Turnover total",
      value: formatSum(currentTrialRow?.turnoverTotal || 0)
    }
  ];

  return (
    <div className="mt-6 flex w-10/12 gap-10">
      <div className="flex w-full gap-1">
        {trialStatusTabCards.map((card) => (
          <BOInvoiceTabCard key={card.title} title={card.title} type={card.type} value={card.value} icon={card.icon} />
        ))}
      </div>
      <div className="flex w-full gap-1">
        {trialsTurnoverTabCards.map((card) => (
          <BOInvoiceTabCard key={card.title} title={card.title} type={card.type} value={card.value} />
        ))}
      </div>
    </div>
  );
};

export default BOTrialTabCards;
