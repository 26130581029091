import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { IAsyncJob } from "@/model/IAsyncJob";
import { EntityId } from "@reduxjs/toolkit";

export function fetchUserRunningAsyncJobs(): Promise<Array<IAsyncJob>> {
  return bauhubGet("/async/user/job/list?st=false");
}

export function fetchAsyncJob(jobId: EntityId): Promise<IAsyncJob> {
  return bauhubGet("/async/job/" + jobId + "?st=false");
}

export function removeAsyncJob(job: IAsyncJob): Promise<IAsyncJob> {
  return bauhubPost("/async/user/job/remove", job);
}

export function cancelAsyncJob(job: IAsyncJob): Promise<IAsyncJob> {
  return bauhubPost("/async/user/job/cancel?st=false", job);
}
