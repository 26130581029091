import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BOHeader from "@/views/home/backoffice/views/BOHeader";
import BhNavigationDropdown from "@components/dropdown/BhNavigationDropdown";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import BOTrialTabCards from "@components/backoffice/trials/BOTrialTabCards";
import BOTrialsFilter from "@components/backoffice/trials/BOTrialsFilter";
import BOMonthlyTrialOverviewTable from "@components/backoffice/trials/BOMonthlyTrialOverviewTable";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boFindYearTrialOveriewAsync, boSelectMonthlyTrialRowsFilteredAndSorted } from "@/app/store/backofficeSlice";
import { IYearlyTrialOverview } from "@/model/trials/IYearlyTrialOverview";

const BOMonthlyTrialOverview: FC = () => {
  const { month } = useParams();
  const navigate = useNavigate();
  const trials: IYearlyTrialOverview = useAppSelector(boSelectMonthlyTrialRowsFilteredAndSorted);
  const currentTrialRow = trials.rows?.find((trial) => trial.yearMonth === month);
  const inactiveTrials = currentTrialRow?.endedTrials.filter((trial) => trial.projectCount === 0);
  const endedTrials = currentTrialRow?.endedTrials.filter((trial) => trial.projectCount !== 0);
  const ongoingTrialsExist = currentTrialRow?.ongoingTrials && currentTrialRow?.ongoingTrials.length > 0;
  const inactiveTrialsExist = inactiveTrials && inactiveTrials.length > 0;
  const endedTrialsExist = endedTrials && endedTrials.length > 0;
  const dispatch = useAppDispatch();
  const year = month?.split("-")[0];

  useEffect(() => {
    if (year) dispatch(boFindYearTrialOveriewAsync(year));
  }, [year]);

  const onNextMonthClick = () => {
    if (month) {
      let currentMonth = Number(month.split("-")[1]);
      let currentYear = Number(month.split("-")[0]);
      if (currentMonth > 0 && currentMonth < 12) {
        currentMonth += 1;
      } else {
        currentMonth = 1;
        currentYear += 1;
      }
      const newMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth;
      const newDate = `${currentYear}-${newMonth}`;
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "/bo/trials/" + newDate);
    }
  };

  const onPreviousMonthClick = () => {
    if (month) {
      let currentMonth = Number(month.split("-")[1]);
      let currentYear = Number(month.split("-")[0]);
      if (currentMonth > 1 && currentMonth <= 12) {
        currentMonth -= 1;
      } else {
        currentMonth = 12;
        currentYear -= 1;
      }
      const newMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth;
      const newDate = `${currentYear}-${newMonth}`;
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "/bo/trials/" + newDate);
    }
  };
  return (
    <div className="w-full px-16 pb-40 sm:px-4">
      <BOHeader
        customHeader={
          <div className="flex items-center gap-4">
            <h1>Trials</h1>
            {month && (
              <div className="-mb-1">
                <BhNavigationDropdown value={month} onLeftClick={onPreviousMonthClick} onRightClick={onNextMonthClick} />
              </div>
            )}
          </div>
        }
      />
      <BOTrialTabCards />
      <div className="justify center mt-6 flex flex-wrap items-center">
        <BOTrialsFilter />
      </div>

      <h2>On trial</h2>
      {!ongoingTrialsExist && <p className="text-18px bh-text-deep-ocean-60">No ongoing trials</p>}
      {ongoingTrialsExist && <BOMonthlyTrialOverviewTable trialRow={currentTrialRow.ongoingTrials} isOngoingTrialRow={true} />}

      <h2 className="mt-10">Inactive trial</h2>
      {!inactiveTrialsExist && <p className="text-18px bh-text-deep-ocean-60">No inactive trials</p>}
      {inactiveTrialsExist && <BOMonthlyTrialOverviewTable trialRow={inactiveTrials} isOngoingTrialRow={true} />}

      <h2 className="mt-10">Ended trial</h2>
      {!endedTrialsExist && <p className="text-18px bh-text-deep-ocean-60">No ended trials</p>}
      {endedTrialsExist && <BOMonthlyTrialOverviewTable trialRow={endedTrials} isOngoingTrialRow={false} />}
    </div>
  );
};

export default BOMonthlyTrialOverview;
