import React, { FC } from "react";
import { IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveSubRowForRoundListRowAsync, selectSortedChecklistRowsByBaseRowId } from "@/app/store/checklist/checklistRowsSlice";
import CheckpointRow from "@components/checklists/checklist/body/rowTypes/checkpoint/CheckpointRow";
import { selectChecklistBaseFileId, selectChecklistLanguageForTranslationByRoundId, selectChecklistTagsByChecklistId } from "@/app/store/checklist/checklistsSlice";
import { IChecklistRoundRow } from "@/model/checklist/IChecklistRoundRow";
import { createNewTaskObject } from "@/model/taskBoard/ITask";
import { selectDefaultProjectTaskCategory, setNewTask, setOpenedTaskInfo } from "@/app/store/tasksSlice";
import { toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { selectCurrentUser } from "@/app/store/userSlice";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { ITaskTag } from "@/model/taskBoard/ITaskTag";

interface Props {
  checklistId: EntityId;
  checklistRoundId: EntityId;
  baseVersionRow: IChecklistBaseVersionRow;
  callback: Function;
  disabled: boolean;
}

const ChecklistRowCheckpointList: FC<Props> = ({ checklistId, checklistRoundId, baseVersionRow, callback, disabled }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const roundRows = useAppSelector((state) => selectSortedChecklistRowsByBaseRowId(state, baseVersionRow.id, false));
  const currentUser = useAppSelector(selectCurrentUser);
  const defaultProjectTaskCategory = useAppSelector(selectDefaultProjectTaskCategory);
  const checklistLanguage = useAppSelector((state) => selectChecklistLanguageForTranslationByRoundId(state, checklistRoundId));
  const checklistBaseFileId = useAppSelector((state) => selectChecklistBaseFileId(state, checklistId, checklistRoundId));
  const currentChecklistTags = useAppSelector((state) => selectChecklistTagsByChecklistId(state, checklistId));

  const addSubRow = () => {
    dispatch(saveSubRowForRoundListRowAsync({ baseVersionRowId: baseVersionRow.id, roundId: checklistRoundId })).then((action) => {
      //If new row was just added, open new task modal
      const savedRow = action.payload as IChecklistRoundRow;
      createNewTaskIfEligibleRow(savedRow);
    });
  };

  const saveChecklistRow = (checklistRow: IChecklistRoundRow) => {
    callback(checklistRow);
    createNewTaskIfEligibleRow(checklistRow);
  };

  const createNewTaskIfEligibleRow = (checklistRow: IChecklistRoundRow) => {
    //If row value was changed to "NO" and row does not have task already, open new task modal
    if (checklistRow.fieldValue === "NO" && (!checklistRow.tasks || checklistRow.tasks.length === 0) && !checklistRow.deleted) {
      const titleText = checklistRow.fieldTitle ? "" + checklistRow.fieldTitle : "";
      const deadline = new Date();
      deadline.setDate(deadline.getDate() + 7);
      const tags = currentChecklistTags
        ? currentChecklistTags.map((tag) => {
            return { name: tag.name, projectId: tag.projectId } as ITaskTag;
          })
        : undefined;
      const newTask = createNewTaskObject({
        currentUser: currentUser,
        category: defaultProjectTaskCategory,
        predefinedName: checklistRow.description || "",
        predefinedDescription: (baseVersionRow.number ? " " + baseVersionRow.number : "") + " " + titleText,
        checklistRowId: checklistRow.id,
        deadline: deadline,
        checklistBaseFileId: checklistBaseFileId,
        tags: tags
      });
      dispatch(setNewTask(newTask));
      dispatch(setOpenedTaskInfo({ closeModalOnNewTaskSave: true }));
      dispatch(toggleProjectModalsOpen({ modal: "projectTaskModal" }));
    }
  };

  return (
    <div>
      <div className="bh-bg-smoke text-14px bh-text-deep-ocean mt-6 flex h-12 flex-row items-center justify-between p-3 font-bold leading-5">
        <div>{baseVersionRow.fieldTitle}</div>
        {!disabled && (
          <div>
            <BhTextOnlyButton icon={faPlus} buttonProps={{ onClick: addSubRow }}>
              {t("CHECKLIST.CHECKPOINT_LIST.ADD_CHECKPOINT", { lng: checklistLanguage })}
            </BhTextOnlyButton>
          </div>
        )}
      </div>
      {roundRows &&
        roundRows.length > 0 &&
        roundRows.map((row) => {
          return (
            <CheckpointRow
              baseVersionRow={baseVersionRow}
              isExtraRow={true}
              rowId={row.id}
              key={row.id}
              saveCallback={saveChecklistRow}
              newTaskCallback={createNewTaskIfEligibleRow}
              disabled={disabled}
              checklistId={checklistId}
            />
          );
        })}
    </div>
  );
};

export default ChecklistRowCheckpointList;
