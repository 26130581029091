import React, { FC } from "react";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";

interface Props {
  setDisableCustomPricingModalOpen: (value: boolean) => void;
  onConfirm: Function;
}

const BOCompanyDisableCustomPricingModal: FC<Props> = ({ setDisableCustomPricingModalOpen, onConfirm }) => {
  return (
    <BhModal
      setIsShown={setDisableCustomPricingModalOpen}
      header={<h2>Disable custom pricing</h2>}
      footer={
        <BhModalFooter
          confirmButtonText="Save"
          onConfirm={onConfirm}
          cancelButtonText="Cancel"
          onCancel={() => setDisableCustomPricingModalOpen(false)} />}
    >
      <div className="p-8">
        <p>Disabling custom pricing will reset the company's pricing settings. Are you sure you want to disable custom pricing?</p>
      </div>
    </BhModal>
  );
};

export default BOCompanyDisableCustomPricingModal;