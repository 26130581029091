import React, { FC } from "react";
import BhCheckbox from "@components/checkboxes/BhCheckbox";

interface Props {
  text: string;
  isChecked: boolean;
  onChangeCallback: Function;
  property: string;
  disabled?: boolean;
}

const FormBaseFieldCheckboxWithText: FC<Props> = ({ text, isChecked, onChangeCallback, property, disabled }) => {
  return (
    <div className="-ml-1 flex flex-row items-center gap-x-0.5">
      <BhCheckbox isChecked={isChecked} onChange={onChangeCallback} property={property} large={true} disabled={disabled} />
      <div className="bh-text-pigeon">{text}</div>
    </div>
  );
};

export default FormBaseFieldCheckboxWithText;
