import { combineReducers, createAsyncThunk, createSelector, createSlice, EntityId, EntityState, PayloadAction } from "@reduxjs/toolkit";
import reduceReducers from "reduce-reducers";
import { IContact } from "@/model/IContact";
import { BauhubPlan, IProject, IProjectModule, IProjectSecurityLevelRequest, Module, ProjectStatus } from "@/model/IProject";
import { IProjectContactsState, projectContactsExtraReducer, projectContactsInitialState } from "@/app/store/project/projectContactsSlice";
import {
  addNordeconLawyerAuthority,
  addRTEngineeringPMAuthority,
  addRTEngineeringPMAuthorityToInvite,
  fetchCompanyAdminsByProject,
  fetchNordeconLawyerAuthorities,
  fetchProject,
  fetchRTEngineeringPMAuthorities,
  removeNordeconLawyerAuthority,
  removeRTEngineeringPMAuthority,
  removeUserOrInvitedUserFromProject,
  saveProject,
  saveProjectSecurityLevel,
  saveProjectStatus,
  saveRTProjectCode
} from "@/api/projectAPI";
import { RootState } from "@/app/store";
import { IBauhubEvent } from "@/model/IBauhubEvent";
import { IProjectLogState, projectLogExtraReducer, projectLogInitialState } from "@/app/store/project/projectLogSlice";
import { IWorkGroup } from "@/model/IWorkGroup";
import { IProjectWorkGroupsState, projectWorkGroupExtraReducer, projectWorkGroupInitialState } from "@/app/store/project/projectWorkGroupsSlice";
import { IProjectModals, IProjectModalsOpen, projectModalsInitialState } from "@/model/projects/IProjectModals";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { fetchCompanyUsers, fetchProjectAdministrators, fetchProjectAdministratorsWithUserInfo, removeAuthority, saveUserAuthorities } from "@/api/userAPI";
import { activateProjectSubscription } from "@/api/billingAPI";
import { IBillingRequisites, IProjectBillingRequisites } from "@/model/IBillingInfo";
import { IUserAuthoritiesRequest, IUserCompanyRelatedAuthority, Resource } from "@/model/IUserAuthority";
import { IProjectSettings } from "@/model/projects/IProjectSettings";
import { deleteInvite, fetchProjectAdministratorInvites, resendInvite } from "@/api/inviteApi";
import { IInvite } from "@/model/invites/IInvite";
import { FileEntityBranch, FileEntityType, IFolderFileEntity } from "@/model/files/IFileEntity";
import { rootFoldersAdded } from "@/app/store/foldersSlice";
import { IProjectMessageState, projectMessageExtraReducer, projectMessageInitialState } from "@/app/store/project/projectMessageSlice";
import { IProjectMessage } from "@/model/IProjectMessage";
import { isCurrentUserRTEngineeringProjectManager } from "@/app/store/userSlice";
import { fetchMaruAuthorities, refreshMaruProjectName, updateMaruAuthority } from "@/api/maru/maruAPI";

export interface IProjectState {
  current: IProject;
  status: BhStateStatusType;
  projectInfoStatus: BhStateStatusType;
  contacts: EntityState<IContact> & IProjectContactsState;
  log: EntityState<IBauhubEvent> & IProjectLogState;
  workGroups: EntityState<IWorkGroup> & IProjectWorkGroupsState;
  messages: EntityState<IProjectMessage> & IProjectMessageState;
  projectModals: IProjectModals;
  projectAdministrators: Array<ISimpleValidUserAuthority>;
  billingRequisites: EntityState<IBillingRequisites> & IBillingRequisites;
  projectSettings: IProjectSettings;
}

const initialState: IProjectState = {
  current: {} as IProject,
  status: BhStateStatusType.INITIAL,
  projectInfoStatus: BhStateStatusType.INITIAL,
  contacts: projectContactsInitialState as EntityState<IContact> & IProjectContactsState,
  log: projectLogInitialState as EntityState<IBauhubEvent> & IProjectLogState,
  messages: projectMessageInitialState as EntityState<IProjectMessage> & IProjectMessageState,
  workGroups: projectWorkGroupInitialState as EntityState<IWorkGroup> & IProjectWorkGroupsState,
  projectModals: projectModalsInitialState,
  projectAdministrators: [] as Array<ISimpleValidUserAuthority>,
  billingRequisites: {} as EntityState<IBillingRequisites> & IBillingRequisites,
  projectSettings: { projectAdministratorInvites: [] as Array<IInvite>, companyUsers: [] as Array<ISimpleValidUserAuthority> } as IProjectSettings
};

export const fetchProjectAsync = createAsyncThunk("project/fetchProject", async (projectId: EntityId, { dispatch }) => {
  const project = await fetchProject(projectId);

  // Add root directories
  const planRootDirectory = { name: "Joonised", id: project.planRootDirectoryId, branch: FileEntityBranch.ROOT_DIR, type: FileEntityType.FILE } as IFolderFileEntity;
  const documentRootDirectory = { name: "Dokumendid", id: project.documentRootDirectoryId, branch: FileEntityBranch.ROOT_DOCUMENT_DIR, type: FileEntityType.DOCUMENT } as IFolderFileEntity;
  const bimRootDirectory = { name: "Mudel", id: project.bimDirectoryId, branch: FileEntityBranch.ROOT_BIM_DIR, type: FileEntityType.DOCUMENT } as IFolderFileEntity;

  dispatch(rootFoldersAdded({ planRootDirectory, documentRootDirectory, bimRootDirectory }));
  return project;
});

export const saveCurrentProjectAsync = createAsyncThunk("project/saveCurrentProject", async (project: IProject) => {
  return saveProject(project);
});

export const fetchProjectAdministratorsAsync = createAsyncThunk("project/fetchProjectAdministrators", async (projectId: EntityId) => {
  return fetchProjectAdministrators(projectId);
});

export const fetchProjectAdministratorsWithUserInfoAsync = createAsyncThunk("project/fetchProjectAdministrators/info", async (projectId: EntityId) => {
  return fetchProjectAdministratorsWithUserInfo(projectId);
});

export const saveProjectStatusAsync = createAsyncThunk("project/saveProjectStatus", async (project: IProject) => {
  return saveProjectStatus(project.id, project.status);
});

export const saveProjectBillingRequisitesAsync = createAsyncThunk("project/saveBillingRequisites", async (projectBillingRequisites: IProjectBillingRequisites) => {
  return activateProjectSubscription(projectBillingRequisites);
});

export const removeUserOrInvitedUserFromProjectAsync = createAsyncThunk(
  "project/removeUserOrInvitedUser",
  async ({ companyId, projectId, username, userExists }: { companyId: EntityId; projectId: EntityId; username: string; userExists: boolean }) => {
    const response = await removeUserOrInvitedUserFromProject(projectId, username);
    return { companyId: companyId, username: username, userExists: userExists, userDTO: response };
  }
);

//--------------------------------PROJECT SETTINGS--------------------------------
export const fetchCurrentProjectAdministratorInvitesAsync = createAsyncThunk("invites/admin/fetch", async (projectId: EntityId) => {
  return fetchProjectAdministratorInvites(projectId);
});

export const resendProjectAdministratorInviteAsync = createAsyncThunk("invites/admin/resend", async (dto: { projectId: EntityId; invite: IInvite }) => {
  return resendInvite(dto.projectId, dto.invite);
});

export const deleteProjectAdministratorInviteAsync = createAsyncThunk("invites/admin/delete", async (dto: { projectId: EntityId; invite: IInvite }) => {
  return deleteInvite(dto.projectId, dto.invite);
});

export const fetchCompanyUsersAsync = createAsyncThunk("user/companyUsers", async (projectId: EntityId) => {
  return fetchCompanyUsers(projectId);
});

export const removeProjectAdministratorAuthorityAsync = createAsyncThunk("project/removeAdminAuth", async (authority: ISimpleValidUserAuthority, { getState, dispatch }) => {
  const state: RootState = getState() as RootState;
  const currentProjectId = selectCurrentProjectId(state);
  const response = await removeAuthority(authority);
  if (authority.companyRelatedAuthorities) {
    if (authority.companyRelatedAuthorities.isLawyer) {
      dispatch(removeNordeconLawyerAuthorityAsync({ projectId: currentProjectId, authority: authority }));
    }
    if (authority.companyRelatedAuthorities.isEngineeringPM) {
      dispatch(removeRTEngineeringPMAuthorityAsync({ projectId: currentProjectId, authority: authority }));
    }
  }
  return response;
});

export const saveProjectAdministratorUserAuthoritiesAsync = createAsyncThunk(
  "project/saveAdminAuth",
  async (dto: { projectId: EntityId; userAuths: Array<IUserAuthoritiesRequest> }, { getState, dispatch }) => {
    const state: RootState = getState() as RootState;
    const response = await saveUserAuthorities(dto.projectId, dto.userAuths);
    const isCurrentUserRTEngineeringPM = isCurrentUserRTEngineeringProjectManager(state, dto.projectId);
    if (isCurrentUserRTEngineeringPM) {
      dto.userAuths.forEach((auth) => {
        const existingUser = response.userList.find((existingUser) => auth.username.toLowerCase() === existingUser.username.toLowerCase());
        const newUserInvite = response.inviteList.find((newUserInvite) => auth.username.toLowerCase() === newUserInvite.username.toLowerCase());
        if (existingUser) {
          dispatch(addRTEngineeringPMAuthorityAsync({ projectId: dto.projectId, authority: existingUser }));
        }
        if (newUserInvite) {
          dispatch(addRTEngineeringPMAuthorityToInviteAsync({ projectId: dto.projectId, invite: newUserInvite }));
        }
      });
    }
    return response;
  }
);

export const fetchNordeconLawyerAuthoritiesAsync = createAsyncThunk("project/nordecon/lawyer/auths", async (projectId: EntityId) => {
  return fetchNordeconLawyerAuthorities(projectId);
});

export const addNordeconLawyerAuthorityAsync = createAsyncThunk("project/nordecon/lawyer/add", async (dto: { projectId: EntityId; authority: ISimpleValidUserAuthority }) => {
  return addNordeconLawyerAuthority(dto.projectId, dto.authority);
});

export const removeNordeconLawyerAuthorityAsync = createAsyncThunk("project/nordecon/lawyer/remove", async (dto: { projectId: EntityId; authority: ISimpleValidUserAuthority }) => {
  return removeNordeconLawyerAuthority(dto.projectId, dto.authority);
});

export const fetchCompanyAdminsByProjectAsync = createAsyncThunk("company/admins", async (projectId: EntityId) => {
  return fetchCompanyAdminsByProject(projectId);
});

export const fetchRTEngineeringPMAuthoritiesAsync = createAsyncThunk("project/RT/PM/auths", async (projectId: EntityId) => {
  return fetchRTEngineeringPMAuthorities(projectId);
});

export const addRTEngineeringPMAuthorityAsync = createAsyncThunk("project/RT/PM/add", async (dto: { projectId: EntityId; authority: ISimpleValidUserAuthority }) => {
  return addRTEngineeringPMAuthority(dto.projectId, dto.authority);
});

export const addRTEngineeringPMAuthorityToInviteAsync = createAsyncThunk("project/RT/PM/invite/add", async (dto: { projectId: EntityId; invite: IInvite }) => {
  return addRTEngineeringPMAuthorityToInvite(dto.projectId, dto.invite);
});

export const removeRTEngineeringPMAuthorityAsync = createAsyncThunk("project/RT/PM/remove", async (dto: { projectId: EntityId; authority: ISimpleValidUserAuthority }) => {
  return removeRTEngineeringPMAuthority(dto.projectId, dto.authority);
});

export const saveRTProjectCodeAsync = createAsyncThunk("project/RT/projectCode", async (dto: { projectId: EntityId; projectCode: string }) => {
  return saveRTProjectCode(dto.projectId, dto.projectCode);
});

export const fetchMaruAuthoritiesAsync = createAsyncThunk("project/Maru/auths", async (projectId: EntityId) => {
  return fetchMaruAuthorities(projectId);
});

export const updateMaruAuthorityAsync = createAsyncThunk("project/Maru/auth/update", async (dto: { projectId: EntityId; authority: ISimpleValidUserAuthority }) => {
  return updateMaruAuthority(dto.projectId, dto.authority);
});

export const refreshMaruProjectNameAsync = createAsyncThunk("project/Maru/project/update", async (projectId: EntityId) => {
  return refreshMaruProjectName(projectId);
});

export const saveProjectSecurityLevelAsync = createAsyncThunk("project/security", async (projectSecurityLevelRequest: IProjectSecurityLevelRequest) => {
  return saveProjectSecurityLevel(projectSecurityLevelRequest);
});

//--------------------------------PROJECT SETTINGS--------------------------------

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProject: (state, action: PayloadAction<IProject>) => {
      state.current = action.payload;
    },
    resetProjectState: () => initialState,
    toggleProjectModalsOpen: (state, action: PayloadAction<{ modal: keyof IProjectModalsOpen }>) => {
      state.projectModals.open[action.payload.modal] = !state.projectModals.open[action.payload.modal];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchProjectAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.current = action.payload;
      })
      .addCase(fetchProjectAdministratorsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchProjectAdministratorsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.projectAdministrators = action.payload;
      })
      .addCase(saveCurrentProjectAsync.pending, (state) => {
        state.projectInfoStatus = BhStateStatusType.PENDING;
      })
      .addCase(saveCurrentProjectAsync.fulfilled, (state, action) => {
        state.projectInfoStatus = BhStateStatusType.SUCCESS;
        state.current = action.payload;
      })
      .addCase(saveProjectStatusAsync.pending, (state) => {
        state.projectInfoStatus = BhStateStatusType.PENDING;
      })
      .addCase(saveProjectStatusAsync.fulfilled, (state, action) => {
        state.projectInfoStatus = BhStateStatusType.SUCCESS;
        state.current = { ...state.current, ...{ status: action.payload.status, archivedDate: action.payload.archivedDate } };
      })
      .addCase(saveProjectBillingRequisitesAsync.pending, (state) => {
        state.projectInfoStatus = BhStateStatusType.PENDING;
      })
      .addCase(saveProjectBillingRequisitesAsync.fulfilled, (state, action) => {
        state.projectInfoStatus = BhStateStatusType.SUCCESS;
        state.current = { ...state.current, status: ProjectStatus.IN_PROGRESS, billingModel: action.payload.billingModel, billingDate: action.payload.billingDate, price: action.payload.price };
      })
      //--------------------------------PROJECT SETTINGS--------------------------------
      .addCase(fetchProjectAdministratorsWithUserInfoAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchProjectAdministratorsWithUserInfoAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        state.projectAdministrators = action.payload;
      })
      .addCase(fetchCurrentProjectAdministratorInvitesAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchCurrentProjectAdministratorInvitesAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        state.projectSettings.projectAdministratorInvites = action.payload;
      })
      .addCase(resendProjectAdministratorInviteAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(resendProjectAdministratorInviteAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        state.projectSettings.projectAdministratorInvites = state.projectSettings.projectAdministratorInvites.filter((invite) => invite.id !== action.payload.id);
        state.projectSettings.projectAdministratorInvites = [...state.projectSettings.projectAdministratorInvites, action.payload];
      })
      .addCase(deleteProjectAdministratorInviteAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(deleteProjectAdministratorInviteAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        state.projectSettings.projectAdministratorInvites = state.projectSettings.projectAdministratorInvites.filter((invite) => invite.id !== action.payload.id);
      })
      .addCase(fetchCompanyUsersAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchCompanyUsersAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        state.projectSettings.companyUsers = action.payload;
      })
      .addCase(removeProjectAdministratorAuthorityAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(removeProjectAdministratorAuthorityAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        state.projectAdministrators = state.projectAdministrators.filter((admin) => admin.userEntityId !== action.payload.userEntityId);
      })
      .addCase(saveProjectAdministratorUserAuthoritiesAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(saveProjectAdministratorUserAuthoritiesAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        state.projectSettings.projectAdministratorInvites = [...state.projectSettings.projectAdministratorInvites, ...action.payload.inviteList];
        const addedUsers = action.payload.userList.filter((user) => {
          return !state.projectAdministrators.some((admin) => admin.userEntityId === user.userEntityId);
        });
        state.projectAdministrators = [...state.projectAdministrators, ...addedUsers];
      })
      .addCase(fetchNordeconLawyerAuthoritiesAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchNordeconLawyerAuthoritiesAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        const lawyerAuthorities = action.payload;
        if (lawyerAuthorities.length > 0) {
          lawyerAuthorities.forEach((lawyerAuth) => {
            const projectAdminAuth = state.projectAdministrators.find((admin) => {
              return admin.userEntityId === lawyerAuth.userEntityId;
            });
            if (projectAdminAuth) {
              projectAdminAuth.companyRelatedAuthorities = projectAdminAuth.companyRelatedAuthorities || ({} as IUserCompanyRelatedAuthority);
              projectAdminAuth.companyRelatedAuthorities.isLawyer = true;
            }
          });
        }
      })
      .addCase(addNordeconLawyerAuthorityAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(addNordeconLawyerAuthorityAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        const lawyerAuthority = action.payload;
        const projectAdminAuth = state.projectAdministrators.find((admin) => {
          return admin.userEntityId === lawyerAuthority.userEntityId;
        });
        if (projectAdminAuth) {
          projectAdminAuth.companyRelatedAuthorities = projectAdminAuth.companyRelatedAuthorities || ({} as IUserCompanyRelatedAuthority);
          projectAdminAuth.companyRelatedAuthorities.isLawyer = true;
        }
      })
      .addCase(removeNordeconLawyerAuthorityAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(removeNordeconLawyerAuthorityAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        const lawyerAuthority = action.payload;
        const projectAdminAuth = state.projectAdministrators.find((admin) => {
          return admin.userEntityId === lawyerAuthority.userEntityId;
        });
        if (projectAdminAuth) {
          projectAdminAuth.companyRelatedAuthorities.isLawyer = false;
        }
      })
      .addCase(fetchCompanyAdminsByProjectAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchCompanyAdminsByProjectAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        const companyAdminAuths = action.payload;
        if (companyAdminAuths.length > 0) {
          companyAdminAuths.forEach((companyAdminAuth) => {
            const projectAdminAuth = state.projectAdministrators.find((admin) => {
              return admin.userEntityId === companyAdminAuth.id;
            });
            if (projectAdminAuth) {
              projectAdminAuth.companyRelatedAuthorities = projectAdminAuth.companyRelatedAuthorities || ({} as IUserCompanyRelatedAuthority);
              projectAdminAuth.companyRelatedAuthorities.isCompanyAdmin = true;
            }
          });
        }
      })
      .addCase(fetchRTEngineeringPMAuthoritiesAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchRTEngineeringPMAuthoritiesAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        const engineeringPMAuths = action.payload;
        if (engineeringPMAuths.length > 0) {
          engineeringPMAuths.forEach((engineeringPMAuth) => {
            const projectAdminAuth = state.projectAdministrators.find((admin) => {
              return admin.userEntityId === engineeringPMAuth.userEntityId;
            });
            if (projectAdminAuth) {
              projectAdminAuth.companyRelatedAuthorities = projectAdminAuth.companyRelatedAuthorities || ({} as IUserCompanyRelatedAuthority);
              projectAdminAuth.companyRelatedAuthorities.isEngineeringPM = true;
            }
          });
        }
      })
      .addCase(addRTEngineeringPMAuthorityAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(addRTEngineeringPMAuthorityAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        const engineeringPMAuthority = action.payload;
        const projectAdminAuth = state.projectAdministrators.find((admin) => {
          return admin.userEntityId === engineeringPMAuthority.userEntityId;
        });
        if (projectAdminAuth) {
          projectAdminAuth.companyRelatedAuthorities = projectAdminAuth.companyRelatedAuthorities || ({} as IUserCompanyRelatedAuthority);
          projectAdminAuth.companyRelatedAuthorities.isEngineeringPM = true;
        }
      })
      .addCase(addRTEngineeringPMAuthorityToInviteAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(addRTEngineeringPMAuthorityToInviteAsync.fulfilled, (state) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
      })
      .addCase(removeRTEngineeringPMAuthorityAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(removeRTEngineeringPMAuthorityAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        const engineeringPMAuthority = action.payload;
        const projectAdminAuth = state.projectAdministrators.find((admin) => {
          return admin.userEntityId === engineeringPMAuthority.userEntityId;
        });
        if (projectAdminAuth) {
          projectAdminAuth.companyRelatedAuthorities.isEngineeringPM = false;
        }
      })
      .addCase(fetchMaruAuthoritiesAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchMaruAuthoritiesAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        const maruAuthorities = action.payload;
        const projectAdmins = Object.values(state.projectAdministrators);
        if (projectAdmins.length > 0) {
          projectAdmins.forEach((admin) => {
            const maruAuth = maruAuthorities.find((auth) => auth.userEntityId === admin.userEntityId);
            admin.companyRelatedAuthorities = admin.companyRelatedAuthorities || ({} as IUserCompanyRelatedAuthority);
            if (admin.companyRelatedAuthorities) {
              admin.companyRelatedAuthorities.maruAuthority = maruAuth ? maruAuth.resource : Resource.PROJECT;
            }
          });
        }
      })
      .addCase(updateMaruAuthorityAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(updateMaruAuthorityAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        const newMaruAuthority = { ...action.payload, ...{ companyRelatedAuthorities: { maruAuthority: action.payload.resource } } } as ISimpleValidUserAuthority;
        state.projectAdministrators = state.projectAdministrators.filter((admin) => admin.username.toLowerCase() !== newMaruAuthority.username.toLowerCase());
        state.projectAdministrators = [...state.projectAdministrators, newMaruAuthority];
      })
      .addCase(refreshMaruProjectNameAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(refreshMaruProjectNameAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        state.current.name = action.payload.name;
      })
      .addCase(saveRTProjectCodeAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(saveRTProjectCodeAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        const RTBudgetModule = state.current.modules.find((module) => module.module === Module.TRESOOR_BUDGET_V2);
        if (RTBudgetModule) {
          RTBudgetModule.adminSettings = RTBudgetModule.adminSettings || {};
          RTBudgetModule.adminSettings.projectCode = action.payload.value;
        }
      })
      .addCase(saveProjectSecurityLevelAsync.pending, (state) => {
        state.projectSettings.status = BhStateStatusType.PENDING;
      })
      .addCase(saveProjectSecurityLevelAsync.fulfilled, (state, action) => {
        state.projectSettings.status = BhStateStatusType.SUCCESS;
        state.current = { ...state.current, securityLevel: action.payload.securityLevel };
      });
    //--------------------------------PROJECT SETTINGS--------------------------------
  }
});

export const { resetProjectState, toggleProjectModalsOpen } = projectSlice.actions;

export const selectCurrentProject = (state: RootState) => state.project.current as IProject;
export const selectCurrentProjectId = (state: RootState) => state.project.current.id as EntityId;
export const selectCurrentProjectName = (state: RootState) => state.project.current?.name;
export const selectProjectStatus = (state: RootState) => state.project.status;
export const selectCurrentProjectPlan = (state: RootState) => state.project.current.plan as BauhubPlan;
export const selectCurrentProjectIsPro = (state: RootState) => state.project.current.plan === BauhubPlan.PRO;
export const selectSecurityTooLowModal = (state: RootState) => state.project.securityTooLowModal;
export const selectIsProjectInProgress = (state: RootState) => state.project.current.status === ProjectStatus.IN_PROGRESS;
export const selectProjectInfoStatus = (state: RootState) => state.project.projectInfoStatus;
export const selectCurrentProjectCompanyId = (state: RootState) => state.project.current.companyId;
export const selectRootDirectoryId = (state: RootState) => state.project.current.planRootDirectoryId;
export const selectRootDocumentDirectoryId = (state: RootState) => state.project.current.documentRootDirectoryId;
export const selectBimDirectoryId = (state: RootState) => state.project.current.bimDirectoryId;
export const selectProjectTaskModalOpen = (state: RootState) => state.project.projectModals.open.projectTaskModal;
export const selectConfirmationModalOpen = (state: RootState) => state.project.projectModals.open.directoryConfirmationModal;
export const selectDirectoryShareboxModalOpen = (state: RootState) => state.project.projectModals.open.directoryShareboxModal;
export const selectContainerModalOpen = (state: RootState) => state.project.projectModals.open.directoryContainerModal;
export const selectProjectAdministrators = (state: RootState) => state.project.projectAdministrators as Array<ISimpleValidUserAuthority>;
export const selectIsProjectArchived = (state: RootState) => state.project.status === ProjectStatus.ARCHIVED;
export const selectBillingRequisites = (state: RootState) => state.project.billingRequisites as IBillingRequisites;
//--------------------------------PROJECT SETTINGS--------------------------------
export const selectCurrentProjectAdministratorInvites = (state: RootState) => state.project.projectSettings.projectAdministratorInvites as Array<IInvite>;
export const selectCompanyUsers = (state: RootState) => state.project.projectSettings.companyUsers as Array<ISimpleValidUserAuthority>;
export const selectCompanyUsersSorted = createSelector([selectCompanyUsers, (state: any, property: keyof ISimpleValidUserAuthority) => property], (users, property) => {
  return users.slice().sort((a, b) => (a[property] > b[property] ? 1 : -1));
});
export const selectProjectAdministratorsSorted = createSelector([selectProjectAdministrators, (state: any, property: keyof ISimpleValidUserAuthority) => property], (administrators, property) => {
  return administrators.slice().sort((a, b) => (a[property] > b[property] ? 1 : -1));
});
export const selectBimProjectModule = createSelector([selectCurrentProject], (currentProject) => {
  if (!currentProject || !currentProject.modules) return false;
  return currentProject.modules.find((projectModule: IProjectModule) => projectModule.module === Module.BIM);
});
export const selectNordeconProjectModule = createSelector([selectCurrentProject], (currentProject) => {
  return currentProject.modules.find((projectModule: IProjectModule) => projectModule.module === Module.NORDECON);
});
export const selectEmbachProjectModule = createSelector([selectCurrentProject], (currentProject) => {
  return currentProject.modules.find((projectModule: IProjectModule) => projectModule.module === Module.EMBACH);
});
export const selectRTProjectModule = createSelector([selectCurrentProject], (currentProject) => {
  return currentProject.modules.find((projectModule: IProjectModule) => projectModule.module === Module.RT_ENGINEERING_PM_ROLE);
});
export const selectRTBudgetModule = createSelector([selectCurrentProject], (currentProject) => {
  return currentProject.modules.find((projectModule: IProjectModule) => projectModule.module === Module.TRESOOR_BUDGET_V2);
});
export const selectMaruProjectModule = createSelector([selectCurrentProject], (currentProject) => {
  return currentProject.modules.find((projectModule: IProjectModule) => projectModule.module === Module.MR_BUDGET);
});
export const selectIsCustomCompany = createSelector([selectCurrentProject], (currentProject) => {
  const customCompanyModules = [Module.NORDECON, Module.EMBACH, Module.RT_ENGINEERING_PM_ROLE, Module.MR_BUDGET];
  return currentProject.modules.some((projectModule: IProjectModule) => customCompanyModules.includes(projectModule.module));
});
export const selectIsEditableObjectNameInForms = createSelector([selectCurrentProject], (currentProject) => {
  return currentProject.modules.find((projectModule: IProjectModule) => projectModule.module === Module.EDITABLE_OBJECT_NAME_IN_FORMS);
});
//--------------------------------PROJECT SETTINGS--------------------------------

const nestedEntitiesReducer = combineReducers({
  current: (s = {} as IProject) => s,
  status: (s = "idle") => s,
  currentSidebarMenuItem: (s = "Esileht") => s,
  contacts: projectContactsExtraReducer,
  log: projectLogExtraReducer,
  messages: projectMessageExtraReducer,
  workGroups: projectWorkGroupExtraReducer,
  projectModals: (s = {} as IProjectModals) => s,
  projectAdministrators: (a = [] as Array<ISimpleValidUserAuthority>) => a,
  projectInfoStatus: (s = "idle") => s,
  billingRequisites: (s = {} as IBillingRequisites) => s,
  projectSettings: (s = {} as IProjectSettings) => s
});

// @ts-ignore
const reducer = reduceReducers<ProjectState>(projectSlice.reducer, nestedEntitiesReducer);

export default reducer;
