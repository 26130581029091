import { EntityId } from "@reduxjs/toolkit";
import { IFileEntity } from "@/model/files/IFileEntity";

export interface IfcConversionStatusDTO {
  started: Date;
  status: IfcConversionStatus;
  deleted: boolean;
  fileEntityId: EntityId;
  lambdaInvocationUuid: string;
  fileEntity: IFileEntity;
}

export enum IfcConversionStatus {
  RUNNING = "RUNNING",
  FAILED = "FAILED",
  LAMBDA_FAILED = "LAMBDA_FAILED",
  BATCH_FAILED = "BATCH_FAILED",
  SUCCESS = "SUCCESS",
  UNCONVERTABLE = "UNCONVERTABLE"
}
