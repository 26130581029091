import React, { FC, useState } from "react";
import SidebarProjectName from "@/views/home/project/sidebar/SidebarProjectName";
import SidebarNav from "@/views/home/project/sidebar/SidebarNav";
import { useAppSelector } from "@/app/hooks";
import { selectSidebarCollapsed } from "@/app/store/sidebarSlice";
import { classNames } from "@/utilities/jsUtilities";
import { useWindowSize } from "@/utilities/hooks/useWindowSize";
import SidebarNavMobile from "@/views/home/project/sidebar/SidebarNavMobile";

interface Props {}

const Sidebar: FC<Props> = () => {
  const { width: pageWidth } = useWindowSize();
  const [expandSidebar, setExpandSidebar] = useState(false);
  const [timerId, setTimerId] = useState<number>();
  const sidebarCollapsed = useAppSelector(selectSidebarCollapsed);
  const isSmallScreen = pageWidth !== undefined && pageWidth < 1000;
  const isSidebarCollapsed = sidebarCollapsed || isSmallScreen;

  if (pageWidth && pageWidth < 768) {
    return (
      <div className="flex flex-row">
        <SidebarNavMobile />
      </div>
    );
  }

  return (
    <div
      className={classNames(isSidebarCollapsed ? "w-18" : "w-66", " relative flex h-full")}
      onMouseEnter={() => setExpandSidebar(true)}
      onMouseLeave={() => {
        setExpandSidebar(false);
        clearInterval(timerId);
      }}
      onMouseDown={() => {
        const timerId = window.setTimeout(() => {
          if (!(isSidebarCollapsed && expandSidebar)) {
            setExpandSidebar(false);
          }
        }, 200);
        setTimerId(timerId);
      }}
    >
      <div
        className={classNames(
          !isSidebarCollapsed || expandSidebar ? "w-66" : "w-18",
          "bh-bg-smoke bh-border-pigeon-20 absolute z-20 flex h-full w-full flex-col overflow-y-auto border-r transition-[width] duration-[50ms] ease-out"
        )}
      >
        <div className="flex flex-1 flex-col">
          <div className="sidebar-nav flex flex-1 flex-col pb-4">
            <SidebarProjectName collapsed={isSidebarCollapsed && !expandSidebar} />
            <SidebarNav collapsed={isSidebarCollapsed && !expandSidebar} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
