import React, { FC } from "react";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { WorkGroupType } from "@/model/IWorkGroup";
import { useTranslation } from "react-i18next";

interface Props {
  workGroupType: WorkGroupType;
}

const PartiesListItemTag: FC<Props> = ({ workGroupType }) => {
  const { t } = useTranslation();
  const tagType = workGroupType === WorkGroupType.CLIENT ? BhTagType.TELLIJA : BhTagType.ALLTOOVOTJA;

  return (
    <div className="-mx-1">
      <BhTag type={tagType}>{t("PARTY." + workGroupType.toUpperCase())}</BhTag>
    </div>
  );
};

export default PartiesListItemTag;
