import React, { FC } from "react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhIconButton from "@components/buttons/BhIconButton";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveFormTaskAsync, savePersonalFormTaskAsync, savePersonalTaskAsync, saveTaskBoardTaskAsync, selectShouldModalCloseOnNewTaskSave } from "@/app/store/tasksSlice";
import { ITask } from "@/model/taskBoard/ITask";
import { faLock } from "@fortawesome/pro-solid-svg-icons/faLock";
import { faUnlock } from "@fortawesome/pro-regular-svg-icons/faUnlock";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhLightSeparatorVertical from "@components/separator/BhLightSeparatorVertical";
import { isTaskPersonal } from "@/utilities/taskBoardUtil";
import { selectCurrentUser } from "@/app/store/userSlice";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { fullPageLoadingSet } from "@/app/store/globalSlice";

interface Props {
  task: ITask;
  toggleLock: Function;
  enableTaskLocking: boolean;
  closeTaskModal: Function;
}

const NewTaskModalHeader: FC<Props> = ({ task, closeTaskModal, toggleLock, enableTaskLocking }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const projectId = useAppSelector(selectCurrentProjectId);
  const shouldCloseModalOnSave = useAppSelector(selectShouldModalCloseOnNewTaskSave);
  const isPersonalTask = isTaskPersonal(task, currentUser);
  const isFormTask = task.formTaskInfo && task.formTaskInfo.formId;
  const saveEnabled = task.taskBoardId || isPersonalTask;
  const showTaskLockingButton = enableTaskLocking && saveEnabled;

  const saveNewTask = () => {
    dispatch(fullPageLoadingSet(true));
    if (isFormTask) {
      saveFormTask();
    } else {
      saveTaskBoardTask();
    }
    shouldCloseModalOnSave && closeTaskModal();
  };
  const saveTaskBoardTask = () => {
    const dispatchAction = isPersonalTask ? savePersonalTaskAsync({ task: task, projectId: projectId }) : saveTaskBoardTaskAsync({ task: task, projectId: projectId });
    dispatch(dispatchAction).then(() => {
      dispatch(fullPageLoadingSet(false));
    });
  };
  const saveFormTask = () => {
    const dispatchAction = isPersonalTask ? savePersonalFormTaskAsync({ task: task, projectId: projectId }) : saveFormTaskAsync({ task: task, projectId: projectId });
    dispatch(dispatchAction).then(() => {
      dispatch(fullPageLoadingSet(false));
    });
  };

  return (
    <div className="flex h-full w-full flex-row items-center justify-between">
      <div>
        <h2>{t("TASK.NEW_TASK")}</h2>
      </div>
      <div className="flex h-full flex-row items-center">
        {showTaskLockingButton && (
          <div className="flex h-full flex-row items-center">
            <BhTooltip body={t("TASK.LOCK.MESSAGE")} key={1}>
              <BhIconButton
                icon={task.locked ? faLock : faUnlock}
                buttonProps={{
                  classes: "mx-1.25",
                  onClick: toggleLock
                }}
              />
            </BhTooltip>
            <div className="mx-2 h-full py-4">
              <BhLightSeparatorVertical />
            </div>
          </div>
        )}
        <BhTextOnlyButton buttonProps={{ onClick: closeTaskModal, classes: "py-[6px]" }}>{t("GLOBAL.CANCEL")}</BhTextOnlyButton>
        {saveEnabled && <BhPrimaryButton buttonProps={{ onClick: saveNewTask, disabled: !task.name }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
      </div>
    </div>
  );
};

export default NewTaskModalHeader;
