import React, { FC, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons/faSpinner";
import { IFileEntity } from "@/model/files/IFileEntity";
import { classNames } from "@/utilities/jsUtilities";
import { fetchPublicShareboxFileUrl } from "@/api/shareboxAPI";
import { fetchUrlForFile } from "@/api/fileAPI";

interface Props {
  fileEntity: IFileEntity;
  selected?: boolean;
  onClickAction?: () => void;
  isPublicSharebox?: boolean;
  shareboxUuid?: string;
}

const GalleryThumbnail: FC<Props> = ({ fileEntity, selected, onClickAction, isPublicSharebox, shareboxUuid }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [thumbUrl, setThumbUrl] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const imgClasses = classNames("inline-flex h-14 w-24 min-w-24 rounded-sm object-cover hover:opacity-100", selected ? "border border-2 rounded bh-border-deep-ocean-40" : "opacity-50");
  const thumbnailRef = useRef<HTMLDivElement>(null);

  const handleFetchResult = (response: { value: string } | { uuid: string; value: string } | undefined | null) => {
    response && setThumbUrl(response.value);
    setIsLoading(false);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      {
        rootMargin: "0px 0px 200px 0px"
      }
    );

    if (thumbnailRef.current) {
      observer.observe(thumbnailRef.current);
    }

    return () => {
      if (thumbnailRef.current) {
        observer.unobserve(thumbnailRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const usePublicShareboxUrl = isPublicSharebox && shareboxUuid;
      const fetchUrlPromise = usePublicShareboxUrl ? fetchPublicShareboxFileUrl(shareboxUuid, fileEntity.uuid, false, true) : fetchUrlForFile(fileEntity.id, true, false, fileEntity?.uuid);
      fetchUrlPromise.then(
        (response) => handleFetchResult(response),
        () => setIsLoading(false)
      );
    }
  }, [isVisible]);

  if (isLoading) {
    return (
      <div ref={thumbnailRef} className="bh-text-pigeon-60 inline-flex h-14 w-24">
        {isVisible && <FontAwesomeIcon icon={faSpinner} className="w-4 w-4" spin aria-hidden="true" />}
      </div>
    );
  }
  return (
    <div className="flex flex-none cursor-pointer items-center justify-center overflow-hidden rounded-sm" onClick={() => onClickAction && onClickAction()}>
      <img className={imgClasses} crossOrigin="" src={thumbUrl} alt="" />
    </div>
  );
};

export default GalleryThumbnail;
