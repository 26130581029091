import React, { FC } from "react";
import BhTableHeader from "@components/table/BhTableHeader";
import BhTableRow from "@components/table/BhTableRow";
import BhIconButton from "@components/buttons/BhIconButton";
import { faEnvelopeOpenText } from "@fortawesome/pro-regular-svg-icons/faEnvelopeOpenText";
import { selectShareboxUrls, setSelectedShareboxUrlId, setShareboxShareModalOpen } from "@/app/store/shareboxesSlice";
import { formatDate, formatDateTime } from "@/utilities/dateUtility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IShareboxUrl } from "@/model/shareboxes/IShareboxUrl";
import { IBauhubEvent } from "@/model/IBauhubEvent";
import { toggleLogSortOrder } from "@/app/store/project/projectLogSlice";
import { useTranslation } from "react-i18next";

interface Props {
  shareboxId: EntityId;
}

const ShareboxDetailShareByEmailTable: FC<Props> = ({ shareboxId }) => {
  const { t } = useTranslation();
  const shareboxUrls = useAppSelector((state) => selectShareboxUrls(state, shareboxId));
  const dispatch = useAppDispatch();

  if (!shareboxUrls) return null;

  const tableColumnHeaders: Array<IBhTableHeader<IShareboxUrl>> = [
    { id: 1, content: t("SHAREBOX.SHARE.MODAL.EMAIL"), field: "email", sortable: false, classes: "w-80 flex-grow" },
    { id: 2, content: t("SHAREBOX.EDIT_MESSAGE"), sortable: false, classes: "w-8" },
    { id: 3, content: t("SHAREBOX.SHARE.MODAL.SENT"), field: "created", sortable: false, classes: "w-36" },
    { id: 4, content: t("SHAREBOX.SHARE.MODAL.VISITED"), field: "visitedTime", sortable: false, classes: "w-52" }
  ];

  const onTableHeaderClickCallback = (column: IBhTableHeader<IBauhubEvent>) => {
    if (column.field) {
      dispatch(toggleLogSortOrder(column.field));
    }
  };

  return (
    <>
      <div className="flex flex-row">
        <h3 className="m-0 w-1/4 py-3 px-6">{t("SHAREBOX.SHARE_BY_EMAIL")}</h3>
        <div className="w-3/4">
          {shareboxUrls.length > 0 && (
            <table className="mb-4 w-full">
              <BhTableHeader columns={tableColumnHeaders} onClickCallback={onTableHeaderClickCallback} />
              <tbody>
                {shareboxUrls &&
                  shareboxUrls.map((sbUrl) => {
                    return (
                      <BhTableRow key={sbUrl.id} classes={"items-center align-middle bh-border-pigeon-40 border-b"}>
                        <td>{sbUrl.email}</td>
                        <td className="cursor-pointer items-center justify-center text-center align-middle">
                          {sbUrl.message && <BhIconButton icon={faEnvelopeOpenText} buttonProps={{ onClick: () => dispatch(setSelectedShareboxUrlId(sbUrl.id)), classes: " bh-text-deep-ocean-80" }} />}
                          {!sbUrl.message && "-"}
                        </td>
                        {sbUrl.created && <td>{formatDateTime(sbUrl.created)}</td>}
                        <td>
                          {sbUrl.visited && sbUrl.visitedTime && (
                            <span>
                              <FontAwesomeIcon icon={faCheck} className="bh-text-success-green-110 mr-1" /> {formatDate(sbUrl.visitedTime)}
                            </span>
                          )}
                          {!sbUrl.visited && "-"}
                        </td>
                      </BhTableRow>
                    );
                  })}
              </tbody>
            </table>
          )}
          <BhSecondaryButton icon={faPlus} buttonProps={{ onClick: () => dispatch(setShareboxShareModalOpen(true)) }}>
            {t("GLOBAL.SEND_INVITE")}
          </BhSecondaryButton>
        </div>
      </div>
    </>
  );
};

export default ShareboxDetailShareByEmailTable;
