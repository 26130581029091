import React, { FC } from "react";
import { ReactComponent as BhLogo } from "@svg/bauhub-logo-colour-positive.svg";

const FullPageLogo: FC = () => {
  return (
    <div className="fixed flex h-full w-full flex-col items-center justify-center">
      <BhLogo className="h-8" />
    </div>
  );
};

export default FullPageLogo;
