import React, { FC, useState } from "react";
import FormBaseInfoInputDropdown from "@components/form/base/body/formBaseInfo/FormBaseInfoInputDropdown";
import FormBaseInputWithCustomDropdown from "@components/form/base/body/formBaseInfo/FormBaseInputWithCustomDropdown";

interface Props {
  disabled?: boolean;
  saveCallback: Function;
  value: string;
  label: string;
  property: string;
  isPrefixInput: boolean;
}

const FormBaseNameContainer: FC<Props> = ({ disabled, saveCallback, value, label, isPrefixInput, property }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const saveNameOption = (changedObject: any) => {
    saveCallback(changedObject);
    setIsDropdownOpen(false);
  };

  return (
    <FormBaseInputWithCustomDropdown
      value={value}
      dropdown={<FormBaseInfoInputDropdown saveCallback={saveNameOption} isPrefixDropdown={isPrefixInput} property={property} />}
      dropdownOpen={isDropdownOpen}
      setDropdownOpen={setIsDropdownOpen}
      label={label}
    />
  );
};

export default FormBaseNameContainer;
