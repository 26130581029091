import React, { FC } from "react";
import BhSuccessToast from "@components/flags/BhSuccessToast";
import ToastBucket from "@/views/global/ToastBucket";

const GlobalToasts: FC = () => {
  return (
    <>
      <BhSuccessToast />
      <ToastBucket />
    </>
  );
};

export default GlobalToasts;
