import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  children: React.ReactNode;
  classes?: string;
}

const BhCompactTableHeaderRow: FC<Props> = ({ children, classes }) => {
  return <tr className={classNames(classes, "bh-border-pigeon-50 min-h-7 border")}>{children}</tr>;
};

export default BhCompactTableHeaderRow;
