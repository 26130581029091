import React, { FC, useEffect, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useLocation, useNavigate } from "react-router-dom";
import BhDetailContainer from "@components/detailContainer/BhDetailContainer";
import BhDetailContainerHeader from "@components/detailContainer/BhDetailContainerHeader";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft";
import { useTranslation } from "react-i18next";
import BhIFrameComponent from "@/views/global/BhIFrameComponent";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { FormType } from "@/model/IForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons/faCircleExclamation";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  dirId: EntityId;
}

const NewFormContainerForOldFE: FC<Props> = ({ dirId }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const location = useLocation();
  const navigate = useNavigate();
  const formType = location.state as FormType;
  const [clicked, setClicked] = useState(false);
  const dispatch = useAppDispatch();

  useDocumentTitle("GLOBAL.CREATE_FORM");

  useEffect(() => {
    dispatch(setSidebarCollapsed(true));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.DOCUMENTS));
    if (!formType) {
      navigate(`/project/${projectId}/dir/${dirId}`);
    }
  }, []);

  const onSaveClick = (functionName: string, doNotDisableButtons?: boolean) => {
    !doNotDisableButtons && setClicked(true);
    const iframeComponent = document.getElementById("bhAngular");
    iframeComponent &&
      // @ts-ignore
      iframeComponent.contentWindow.postMessage(
        {
          type: "triggerFunction",
          message: functionName
        },
        "*"
      );
  };

  return (
    <BhDetailContainer>
      <BhDetailContainerHeader>
        <div className="flex w-full flex-row items-center justify-between">
          <div className="flex flex-1 items-center">
            <BhIconButton icon={faArrowLeft} buttonProps={{ onClick: () => navigate(-1) }} />
            <div className="flex flex-col gap-y-1 pl-2">
              <div>
                <span className="l-h-24px text-18px">
                  <span className="pr-2">{t("GLOBAL.CREATE_FORM")}</span>
                  <strong>{formType ? t("FORMS." + formType) : ""}</strong>
                </span>
              </div>
              <div className="bh-text-deep-ocean-80 flex flex-row items-center gap-x-2">
                <FontAwesomeIcon className="bh-text-warning-yellow" icon={faCircleExclamation} />
                {t("FORM.UNSAVED")}
              </div>
            </div>
          </div>
          <div>
            {[FormType.SCM_ASTLANDA].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("scmSave"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.SCM_EVENTUS].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("scmSave"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.BWD_KAAMOS].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.SCM_KAAMOS].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("scmSave"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.VCW_KAAMOS].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.KTA_KIIKRI].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.KOOSOLEK_KULINVEST].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("koosolekSave"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.BWD_MARU].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("saveBwd"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.KOOSOLEK_MARU].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.SCM_MARU].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("scmSave"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.ULEANDMIS_VASTUVOTU_AKT_MARU].includes(formType) && (
              <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>
            )}
            {[FormType.ULEANDMIS_VASTUVOTU_AKT_MARU_CLIENT].includes(formType) && (
              <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>
            )}
            {[FormType.ULEANDMIS_VASTUVOTU_AKT_MARU_SUB].includes(formType) && (
              <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>
            )}
            {[FormType.VCW_MARU].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.BWD_MERKO].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.BWD_MERKO_SUB].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.KTA_MERKO, FormType.KTA_MERKO_KAKSKEELNE].includes(formType) && (
              <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>
            )}
            {[FormType.TOODE_MARKUSTE_TABEL_MERKO].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.EHITUSPAEVIK_METROPOL].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.KTA_METROPOL].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.ULEANDMIS_VASTUVOTU_AKT_METROPOL].includes(formType) && (
              <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>
            )}
            {[FormType.SCM_NB].includes(formType) && (
              <>
                <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("addTasksAndSaveScm"), disabled: clicked }}>{t("GLOBAL.CREATE_TASKS_AND_SAVE")}</BhPrimaryButton>
                <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("saveScm"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>
              </>
            )}
            {[FormType.TTA_NB].includes(formType) && (
              <>
                <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("addTasksAndSaveScm"), disabled: clicked }}>{t("GLOBAL.CREATE_TASKS_AND_SAVE")}</BhPrimaryButton>
                <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("saveScm"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>
              </>
            )}
            {[FormType.TTA_NORDECON].includes(formType) && (
              <>
                <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("addTasksAndSaveScm"), disabled: clicked }}>{t("GLOBAL.CREATE_TASKS_AND_SAVE")}</BhPrimaryButton>
                <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("saveScm"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>
              </>
            )}
            {[FormType.KOKKULEPETE_REGISTER_NORDECON].includes(formType) && (
              <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>
            )}
            {[FormType.MENETLUS_KOKKULEPE_NORDECON].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.LEPINGUTE_REGISTER_NORDECON].includes(formType) && (
              <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("orderAndSave"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>
            )}
            {[FormType.MATERJALIDE_KOOSKOLASTAMISE_VORM].includes(formType) && (
              <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>
            )}
            {[FormType.PROOVIDE_KOONDTABEL_NORDECON].includes(formType) && (
              <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("saveProtokoll"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>
            )}
            {[FormType.PROOVIVOTUPROTOKOLL_NORDECON].includes(formType) && (
              <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>
            )}
            {[FormType.ASFALTEERIMINE_NORDECON].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.ATV_LOPPULEVAATUSE_AKT_NCN].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.BETOONITOOD_NORDECON].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.BWD_NORDECON].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.BWD_NORDECON_SUB].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.FREESIMINE_NORDECON].includes(formType) && (
              <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("freesimineSave"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>
            )}
            {[FormType.KANDEVOIME_NORDECON].includes(formType) && (
              <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("kandevoimeSave"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>
            )}
            {[FormType.KATMATA_TOODE_AKT_NORDECON].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.KOOSOLEK_NORDECON].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("koosolekSave"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.LIIKLUSKORRALDUSPAEVIK_NCN].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.LOPPULEVAATUS_NORDECON].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.RINGKAIGU_CHECKLIST_NORDECON].includes(formType) && (
              <>
                <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("saveTasksAndSendMail", true), disabled: clicked }}>Salvesta töökäsud ja teavita koordinaatorit</BhPrimaryButton>
                <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("saveChecklist", true), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>
              </>
            )}
            {[FormType.STABILISEERIMINE_NORDECON].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.TIHEDUS_NORDECON].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.TOOPLAAN_NORDECON].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.TOOPLAAN_NORDECON_PTV].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.TORUSTIKU_KATSETUS_NORDECON].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.ULEVAATUSE_LEIUD_NORDECON].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
            {[FormType.VCW_NORDECON].includes(formType) && <BhPrimaryButton buttonProps={{ onClick: () => onSaveClick("save"), disabled: clicked }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>}
          </div>
        </div>
      </BhDetailContainerHeader>
      <div className="h-full w-full overflow-hidden">
        <BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/dir/${dirId}/form/new/${formType}/react`} />;
      </div>
    </BhDetailContainer>
  );
};

export default NewFormContainerForOldFE;
