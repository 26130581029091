import React, { FC, ReactNode } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  children?: ReactNode;
  className?: string;
  small?: boolean;
}
const BhPlanTag: FC<Props> = ({ children, className, small }) => {
  return <div className={classNames(className, small ? "py-0" : "py-0.5", "l-h-18px text-14px ml-2 mr-0.5 rounded-full border px-1.5 font-bold")}>{children}</div>;
};

export default BhPlanTag;
