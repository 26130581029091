import React, { FC } from "react";
import { ITask } from "@/model/taskBoard/ITask";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { resetOpenedTaskInfo, selectTaskById } from "@/app/store/tasksSlice";
import { BhSectionMessageWarning } from "@components/sectionMessage/BhSectionMessages";
import { useTranslation } from "react-i18next";
import { selectCurrentProjectId, toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { useNavigate } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  task: ITask;
  isPersonalTask: boolean;
}

const ChecklistTaskNotification: FC<Props> = ({ task, isPersonalTask }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const projectId = useAppSelector(selectCurrentProjectId);
  const taskBoardTask = useAppSelector((state) => selectTaskById(state, task.id));
  const taskBoardTaskHasBeenChanged =
    taskBoardTask &&
    (taskBoardTask.deadline !== task.deadline ||
      taskBoardTask.status !== task.status ||
      taskBoardTask.priority !== task.priority ||
      taskBoardTask.name !== task.name ||
      taskBoardTask.description !== task.description ||
      taskBoardTask.assignee !== task.assignee ||
      taskBoardTask.projectCategoryId !== task.projectCategoryId);

  if (!taskBoardTaskHasBeenChanged) return null;

  const navigateToTaskView = () => {
    if (isPersonalTask) {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/personal/task/${task.id}`);
    } else {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/task/${task.taskIdentifier}`);
    }
    dispatch(toggleProjectModalsOpen({ modal: "projectTaskModal" }));
    dispatch(resetOpenedTaskInfo());
  };

  return (
    <div className="pb-4">
      <BhSectionMessageWarning buttonLabel={t("TASK.CHANGED.BUTTON") as string} onClick={navigateToTaskView}>
        {t("TASK.UPDATED_ON_TASKBOARD")}
      </BhSectionMessageWarning>
    </div>
  );
};

export default ChecklistTaskNotification;
