import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { isValidEmail } from "@/utilities/jsUtilities";
import { Authority, IPredefinedWorkGroupUser } from "@/model/IUserAuthority";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhModal from "@components/modal/BhModal";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveNewPredefinedPartyUserAsync, selectAllCompanyUsers, selectCompanyPredefinedPartyUsersById } from "@/app/store/companiesSlice";
import { EntityId } from "@reduxjs/toolkit";
import PartyTemplateAddNewUserModalContent from "@/views/home/company/detail/companySettingsPartyTemplates/PartyTemplateAddNewUserModalContent";

interface Props {
  setIsAddNewModalOpen: Dispatch<SetStateAction<boolean>>;
  companyId: EntityId;
  predefinedPartyId: EntityId;
}

const PartyTemplateAddNewUserModal: FC<Props> = ({ setIsAddNewModalOpen, companyId, predefinedPartyId }) => {
  const { t } = useTranslation();
  const [userToAdd, setUserToAdd] = useState<IPredefinedWorkGroupUser>({
    username: "",
    filesAuthLevel: Authority.NONE,
    documentsAuthLevel: Authority.NONE,
    actsAuthLevel: Authority.NONE,
    contractAuthLevel: Authority.NONE,
    modelAuthLevel: Authority.NONE
  } as IPredefinedWorkGroupUser);
  const companyUsers = useAppSelector((state) => selectAllCompanyUsers(state, companyId));
  const predefinedPartyUsers = useAppSelector((state) => selectCompanyPredefinedPartyUsersById(state, [companyId, predefinedPartyId]));
  const dispatch = useAppDispatch();

  const handleClose = () => {
    setUserToAdd({} as IPredefinedWorkGroupUser);
    setIsAddNewModalOpen(false);
  };

  const handleSubmit = () => {
    dispatch(saveNewPredefinedPartyUserAsync({ ...userToAdd, predefinedWorkGroupId: predefinedPartyId }));
    handleClose();
  };

  const userDoesNotExist = !companyUsers.some((user) => user.username === userToAdd.username);
  const partyAlreadyAdded = predefinedPartyUsers.some((user) => user.username === userToAdd.username);

  const isConfirmDisabled =
    !isValidEmail(userToAdd.username) ||
    userDoesNotExist ||
    partyAlreadyAdded ||
    !userToAdd.filesAuthLevel ||
    !userToAdd.documentsAuthLevel ||
    !userToAdd.actsAuthLevel ||
    !userToAdd.contractAuthLevel;

  return (
    <BhModal
      isShown={true}
      setIsShown={setIsAddNewModalOpen}
      onClose={handleClose}
      size="5xl"
      header={<h2>{t("WORKGROUP.ADD_USER")}</h2>}
      children={
        <div className="w-full px-20 pt-12 lg:px-8">
          <PartyTemplateAddNewUserModalContent
            userToAdd={userToAdd}
            setUserToAdd={setUserToAdd}
            userDoesNotExist={userDoesNotExist}
            partyAlreadyAdded={partyAlreadyAdded}
            companyId={companyId}
            predefinedPartyId={predefinedPartyId}
          />
        </div>
      }
      footer={
        <BhModalFooter
          cancelButtonText={t("GLOBAL.CLOSE") as string}
          onCancel={handleClose}
          confirmButtonText={t("MODAL.DEFAULT.CONFIRMATION.OK") as string}
          onConfirm={handleSubmit}
          confirmDisabled={isConfirmDisabled}
        />
      }
    />
  );
};

export default PartyTemplateAddNewUserModal;
