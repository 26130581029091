import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import BhTableHeader from "@components/table/BhTableHeader";
import { useTranslation } from "react-i18next";
import { selectCompanyCustomFormBases, selectCompanyFormBases, selectCompanyFormBasesActivatedInProject, selectCompanyFormBasesStatus } from "@/app/store/form/formBasesSlice";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { IFormBase } from "@/model/form/IFormBase";
import { naturalSortByField } from "@/utilities/sortUtilities";
import ProjectFormsSettingsListRowContainer from "@components/form/settings/projectSettings/ProjectFormsSettingsListRowContainer";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IFormBaseFilter } from "@/model/form/IFormBaseFilter";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import FormsSettingsTableSkeleton from "@components/form/settings/FormsSettingsTableSkeleton";

interface Props {
  filter: IFormBaseFilter;
  setFilter: Dispatch<SetStateAction<IFormBaseFilter>>;
}

const ProjectFormsSettingsTable: FC<Props> = ({ filter, setFilter }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const companyFormBases = useAppSelector(selectCompanyFormBases);
  const customFormBases = useAppSelector(selectCompanyCustomFormBases);
  const basesActivatedInProject = useAppSelector((state) => selectCompanyFormBasesActivatedInProject(state, projectId));
  const formBaseStatus = useAppSelector(selectCompanyFormBasesStatus);
  const [filteredAndSortedFormBases, setFilteredAndSortedFormBases] = useState<Array<IFormBase>>([]);

  useEffect(() => {
    filterAndSortFormBases();
  }, [filter, companyFormBases, basesActivatedInProject, customFormBases]);

  const filterAndSortFormBases = () => {
    const filteredFormBases = filterFormBases([...companyFormBases, ...customFormBases]);
    const sortedAndFilteredFormBases = sortFormBases(filteredFormBases);
    setFilteredAndSortedFormBases(sortedAndFilteredFormBases);
  };

  const filterFormBases = (formBases: Array<IFormBase>) => {
    if (filter.searchString && filter.searchString.length > 0) {
      formBases = formBases.filter((base) => {
        if (base.bauhubFormBase) {
          const translatedValue = t(`FORMS.${base.type}`);
          return translatedValue.toLowerCase().includes(filter.searchString.toLowerCase());
        } else {
          return base.type.toLowerCase().includes(filter.searchString.toLowerCase());
        }
      });
    }
    if (filter.usernames && filter.usernames.length > 0) {
      const removeBauhubTemplates = !filter.usernames.some((u) => u.toLowerCase() === "bauhub");
      formBases = formBases.filter((base) => {
        const nameFilter = filter.usernames.some((username) => username.toLowerCase() === base.createdBy.toLowerCase());
        const bauhubTemplateFilter = removeBauhubTemplates ? !base.bauhubFormBase : true;
        return nameFilter && bauhubTemplateFilter;
      });
    }
    if (filter.showProjectTemplatesOnly) {
      formBases = formBases.filter((base) => base.createdInProjectId);
    }
    if (filter.showCompanyTemplatesOnly) {
      formBases = formBases.filter((base) => !base.createdInProjectId);
    }
    return formBases;
  };

  const sortFormBases = (formBases: Array<IFormBase>) => {
    const filteredFormBases = filterFormBases(formBases);
    if (filter.orderBy === "type") {
      formBases = filteredFormBases.slice().sort((a, b) => {
        if (a.bauhubFormBase || a.createdBy === "custom") {
          a = { ...a, type: t("FORMS." + a.type) };
        }
        if (b.bauhubFormBase || b.createdBy === "custom") {
          b = { ...b, type: t("FORMS." + b.type) };
        }
        return naturalSortByField(a, b, filter.orderBy, filter.reversed);
      });
    }
    if (filter.orderBy === "created") {
      const bauhubTemplates = filteredFormBases
        .slice()
        .filter((fb) => fb.bauhubFormBase)
        .sort((a, b) => (t("FORMS." + a.type) > t("FORMS." + b.type) ? 1 : -1));
      const customTemplates = filteredFormBases
        .slice()
        .filter((fb) => fb.createdBy === "custom")
        .sort((a, b) => (t("FORMS." + a.type) > t("FORMS." + b.type) ? 1 : -1));
      const userTemplates = filteredFormBases.slice().filter((fb) => !fb.bauhubFormBase && fb.createdBy !== "custom");
      formBases = userTemplates
        .slice()
        .sort((a, b) =>
          a.formBaseVersions.reduce((max, row) => (max.versionNumber > row.versionNumber ? max : row)).created >
          b.formBaseVersions.reduce((max, row) => (max.versionNumber > row.versionNumber ? max : row)).created
            ? filter.reversed
              ? -1
              : 1
            : filter.reversed
            ? 1
            : -1
        );
      formBases = [...formBases, ...bauhubTemplates, ...customTemplates];
    }
    if (filter.orderBy === "active") {
      const filteredActivatedBases = basesActivatedInProject.filter((base) => filteredFormBases.some((b) => b.id === base.id));
      const activeBases = filteredActivatedBases.slice().sort((a, b) => naturalSortByField(a, b, "type", false));
      const inactiveBases = filteredFormBases
        .slice()
        .filter((base) => !activeBases.some((pcb) => pcb.id === base.id))
        .sort((a, b) => naturalSortByField(a, b, "type", false));
      formBases = filter.reversed ? [...inactiveBases, ...activeBases] : [...activeBases, ...inactiveBases];
    }
    return formBases;
  };

  const tableColumnHeaders: Array<IBhTableHeader<any>> = [
    { id: 0, content: t("FORM.BASE.NAME"), field: "type", sortable: true },
    { id: 2, content: t("GLOBAL.VERSION"), classes: "w-24 text-center" },
    { id: 3, content: t("GLOBAL.CREATED"), field: "created", sortable: true, classes: "w-44" },
    { id: 4, content: t("FORM.ACTIVE_IN_PROJECT"), classes: "w-28", sortable: true, field: "active" },
    { id: 6, content: "", classes: "w-10" }
  ];

  const onTableHeaderClickCallback = (column: IBhTableHeader<IFormBase>) => {
    if (column.field) {
      setFilter({ ...filter, orderBy: column.field, reversed: column.field === filter.orderBy ? !filter.reversed : filter.reversed });
    }
  };

  return (
    <table className="w-full">
      <BhTableHeader columns={tableColumnHeaders} sortedBy={filter.orderBy} reversed={filter.reversed} onClickCallback={onTableHeaderClickCallback} />
      <tbody>
        {formBaseStatus === BhStateStatusType.PENDING && <FormsSettingsTableSkeleton isProjectFormTable={true} />}
        {formBaseStatus === BhStateStatusType.SUCCESS && (
          <>
            {filteredAndSortedFormBases.length > 0 &&
              filteredAndSortedFormBases.map((formBase) => {
                return <ProjectFormsSettingsListRowContainer key={formBase.id} formBase={formBase} setFilter={setFilter} />;
              })}
          </>
        )}
      </tbody>
    </table>
  );
};

export default ProjectFormsSettingsTable;
