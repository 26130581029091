import React, { FC } from "react";
import BhBadge from "@components/badge/BhBadge";

interface Props {
  title: string;
  count: any;
}

const SearchTabHeader: FC<Props> = ({ title, count }) => {
  return (
    <div className="flex items-center gap-1.5">
      {title} <BhBadge>{count}</BhBadge>
    </div>
  );
};

export default SearchTabHeader;
