import BhPrimaryButton from "@/components/buttons/BhPrimaryButton";
import BhTextOnlyButton from "@/components/buttons/BhTextOnlyButton";
import BhSeparatorMarginless from "@/components/separator/BhSeparatorMarginless";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SyncStep1Container from "./steps/SyncStep1Container";
import SyncStep2Container from "./steps/SyncStep2Container";
import SyncStep3Container from "./steps/SyncStep3Container";
import SyncStep4Container from "./steps/SyncStep4Container";

const SyncStepsContainer: FC = () => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const steps = [
    { title: "Step 1", component: <SyncStep1Container /> },
    { title: "Step 2", component: <SyncStep2Container /> },
    { title: "Step 3", component: <SyncStep3Container /> },
    { title: "Step 4", component: <SyncStep4Container /> }
  ];

  useEffect(() => {}, []);

  function nextStep() {
    if (selectedIndex + 1 === steps.length) {
      //@ts-ignore
      if (window.chrome && window.chrome.webview && window.chrome.webview.postMessage) {
        //@ts-ignore
        window.chrome.webview.postMessage('{"method":"close"}');
      }
      window.close();
      return;
    }
    setSelectedIndex(selectedIndex + 1);
  }

  function setStep(index: number) {
    setSelectedIndex(index);
  }

  function prevStep() {
    setSelectedIndex(selectedIndex - 1);
  }

  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex-1 overflow-hidden">{steps[selectedIndex].component}</div>
      <BhSeparatorMarginless />
      <div className="bh-bg-white h-18 flex w-full flex-row items-center">
        <div className="w-40 pl-6">{selectedIndex !== 0 && <BhTextOnlyButton buttonProps={{ onClick: prevStep, classes: "" }}>{t("GLOBAL.PREVIOUS")}</BhTextOnlyButton>}</div>
        <div className="flex flex-1 flex-row items-center justify-center">
          <div className="flex flex-row gap-x-2.5">
            {steps &&
              steps.map((step, index) => {
                return (
                  <div onClick={() => setStep(index)} className="cursor-pointer">
                    {index === selectedIndex || index < selectedIndex ? (
                      <div className="bh-bg-bauhub-green h-1.5 w-1.5 rounded-full"></div>
                    ) : (
                      <div className="bh-bg-pigeon-40 h-1.5 w-1.5 rounded-full"></div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="w-40 pr-6 text-right">
          <BhPrimaryButton buttonProps={{ onClick: nextStep, classes: "right-0" }}>{steps.length - 1 === selectedIndex ? t("SYNC.END_TOUR") : t("GLOBAL.NEXT")}</BhPrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default SyncStepsContainer;
