import React, { FC } from "react";
import { IFileConfirmation } from "@/model/confirmations/IFileConfirmation";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { fetchFileConfirmationWithFiles } from "@/api/fileConfirmationAPI";
import { fetchPresignedBatchUrl } from "@/api/fileAPI";
import { imitateBatchFileDownload } from "@/utilities/downloadUtilities";
import { selectCurrentUser } from "@/app/store/userSlice";
import { Trans, useTranslation } from "react-i18next";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  fileConfirmation: IFileConfirmation;
}

const ConfirmationsListItemApprovalFooter: FC<Props> = ({ fileConfirmation }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const currentUser = useAppSelector(selectCurrentUser);
  const navigate = useNavigate();

  const fetchFileConfirmationFilesAndBatchDownload = () => {
    fetchFileConfirmationWithFiles(fileConfirmation.id).then((result) => {
      const needsApproval = result.files.filter((file) => file.nextToConfirmUserId === currentUser.id);
      fetchPresignedBatchUrl(needsApproval.map((f) => f.fileEntityId)).then((result) => imitateBatchFileDownload(result));
    });
  };

  return (
    <div className="bh-bg-mint flex flex-row p-4" onClick={(e) => e.preventDefault()}>
      <div className="flex flex-1 flex-row items-center py-2 px-4">
        <BhBadge type={BhBadgeType.BRIGHT}>{fileConfirmation.userFilesPending}</BhBadge>
        <span className="pl-2 font-bold">{t("CONFIRMATIONS.FILES_WAITING", { count: fileConfirmation.userFilesPending })}</span>
      </div>
      <div className="flex flex-row items-center">
        <BhTextOnlyButton icon={faArrowDownToLine} buttonProps={{ onClick: fetchFileConfirmationFilesAndBatchDownload, classes: "!hover:bh-bg-mint-120" }}>
          <Trans>GLOBAL.DOWNLOAD_FILES</Trans>
        </BhTextOnlyButton>
        <BhPrimaryButton buttonProps={{ onClick: () => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/confirmation/${fileConfirmation.id}/actions`) }}>
          <Trans>CONFIRMATIONS.GO_CONFIRM</Trans>
        </BhPrimaryButton>
      </div>
    </div>
  );
};

export default ConfirmationsListItemApprovalFooter;
