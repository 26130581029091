import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  fetchProjectWorkGroupPrivilegesAsync,
  fetchWorkGroupTaskBoardsAsync,
  selectCurrentWorkGroupUsersInvitesSorted,
  selectIsAddUserModalShown,
  selectIsRemoveUserModalShown,
  setAddUserModalShown
} from "@/app/store/project/projectWorkGroupsSlice";
import PartyUserListItem from "@/views/home/project/detail/users/parties/party/PartyUserListItem";
import { IPartyPrivilegeListItemDTO } from "@/model/party/IPartyPrivilegeListItemDTO";
import BhTableHeader from "@components/table/BhTableHeader";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import PartyAddUserContainer from "@/views/home/project/detail/users/parties/party/PartyAddUserContainer";
import PartyRemoveUserModal from "@/views/home/project/detail/users/parties/party/PartyRemoveUserModal";
import { IWorkGroup } from "@/model/IWorkGroup";
import { Trans, useTranslation } from "react-i18next";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import { selectBimProjectModule, selectMaruProjectModule } from "@/app/store/project/projectSlice";
import MaruPartyAddUserContainer from "@/views/home/project/detail/users/parties/party/maru/MaruPartyAddUserContainer";

interface Props {
  workGroup: IWorkGroup;
  callback?: Function;
}

const PartyPrivilegesContainer: FC<Props> = ({ workGroup, callback }) => {
  const { t } = useTranslation();
  const usersInvites = useAppSelector(selectCurrentWorkGroupUsersInvitesSorted);
  const isModalShown = useAppSelector(selectIsAddUserModalShown);
  const isRemoveUserModalShown = useAppSelector(selectIsRemoveUserModalShown);
  const isMaruProject = useAppSelector(selectMaruProjectModule);
  const isBauhubProject = !isMaruProject;
  const isBimProject = useAppSelector(selectBimProjectModule);
  const [openedUser, setOpenedUser] = useState<IPartyPrivilegeListItemDTO>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (workGroup && workGroup.id && workGroup.id !== -1) {
      dispatch(fetchProjectWorkGroupPrivilegesAsync(workGroup.id));
      dispatch(fetchWorkGroupTaskBoardsAsync(workGroup.id));
    }
  }, []);

  const getTableColumnHeaders = () => {
    const tableColumnHeaders = [
      { id: 1, content: t("GLOBAL.USER"), classes: "w-60" },
      { id: 2, content: t("GLOBAL.DRAWINGS"), classes: "w-36" },
      { id: 3, content: t("GLOBAL.DOCUMENTS"), classes: "w-36" },
      ...(isBimProject ? [{ id: 4, content: t("BIM.FILES"), classes: "w-36" }] : []),
      { id: 5, content: t("PARTY.ACTS"), classes: "w-36" },
      { id: 6, content: t("PARTY.CONTRACTS"), classes: "w-36" }
    ] as Array<IBhTableHeader<IPartyPrivilegeListItemDTO>>;

    if (workGroup.id === -1) {
      const cols = [{ id: 7, content: "", classes: "w-16" }] as Array<IBhTableHeader<IPartyPrivilegeListItemDTO>>;
      return [...tableColumnHeaders, ...cols];
    } else {
      const cols = [
        { id: 7, content: t("GLOBAL.TASKBOARDS"), classes: "w-20" },
        { id: 8, content: "", classes: "w-16" }
      ] as Array<IBhTableHeader<IPartyPrivilegeListItemDTO>>;
      return [...tableColumnHeaders, ...cols];
    }
  };

  const openAddUserModal = () => {
    dispatch(setAddUserModalShown(true));
  };

  const closeModal = () => {
    dispatch(setAddUserModalShown(false));
  };

  if (!workGroup) {
    return null;
  }

  return (
    <div className="h-full overflow-auto">
      <div className="flex flex-col">
        {workGroup.id !== -1 && (
          <div className="flex flex-row justify-end">
            <BhSecondaryButton icon={faPlus} buttonProps={{ onClick: () => openAddUserModal() }}>
              {t("WORKGROUP.ADD_USER")}
            </BhSecondaryButton>
            {isModalShown && (
              <>
                {isBauhubProject && (
                  <PartyAddUserContainer workGroup={workGroup} usersInvites={usersInvites} isModal={true} closeModal={closeModal} submitButtonText={t("GLOBAL.SEND_INVITE") as string} />
                )}
                {isMaruProject && (
                  <MaruPartyAddUserContainer workGroup={workGroup} usersInvites={usersInvites} isModal={true} closeModal={closeModal} submitButtonText={t("GLOBAL.SEND_INVITE") as string} />
                )}
              </>
            )}
            {isRemoveUserModalShown && openedUser && <PartyRemoveUserModal listItem={openedUser} partyId={workGroup.id} />}
          </div>
        )}
        {workGroup.id === -1 && (
          <div className="w-full">
            <h2 className="pb-6">{t("WORKGROUP.NEW.ADD_USERS")}</h2>
            {isBauhubProject && <PartyAddUserContainer workGroup={workGroup} usersInvites={usersInvites} isModal={false} />}
            {isMaruProject && <MaruPartyAddUserContainer workGroup={workGroup} usersInvites={usersInvites} isModal={false} />}
          </div>
        )}
      </div>
      {((workGroup.id === -1 && usersInvites?.length > 0) || workGroup.id !== -1) && (
        <div className="overflow-x-auto pb-40">
          <table className="mt-2 w-full">
            <BhTableHeader columns={getTableColumnHeaders()} />
            <tbody>
              {usersInvites.map((userOrInvite: IPartyPrivilegeListItemDTO) => {
                return <PartyUserListItem key={userOrInvite.uniqueId} setOpenedUser={setOpenedUser} workGroup={workGroup} listItem={userOrInvite} />;
              })}
            </tbody>
          </table>
          {usersInvites?.length < 1 && (
            <BhNoMatchesImage>
              <Trans>PARTY.NO_USERS</Trans>
            </BhNoMatchesImage>
          )}
        </div>
      )}
    </div>
  );
};
export default PartyPrivilegesContainer;
