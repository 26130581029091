export interface IProjectModals {
  open: IProjectModalsOpen;
}

export interface IProjectModalsOpen {
  projectTaskModal: boolean;
  directoryConfirmationModal: boolean;
  directoryShareboxModal: boolean;
  directoryContainerModal: boolean;
  lightboxModal: boolean;
}

export const projectModalsInitialState = {
  open: {
    projectTaskModal: false,
    directoryConfirmationModal: false,
    directoryShareboxModal: false,
    directoryContainerModal: false,
    lightboxModal: false
  }
};
