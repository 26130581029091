import React, { FC } from "react";
import FormBaseTextFieldInput from "@components/form/base/body/fields/textField/FormBaseTextFieldInput";
import FormFieldContainerInput from "@components/form/base/body/fields/FormFieldContainerInput";
import FormBaseFieldCheckboxWithText from "@components/form/base/body/FormBaseFieldCheckboxWithText";
import FormBaseFieldWithImportCheckbox from "@components/form/base/body/fields/FormBaseFieldWithImportCheckbox";
import { useTranslation } from "react-i18next";

interface Props {
  fieldValue: any;
  saveFieldCallback: Function;
  disabled?: boolean;
}

const FormBaseTextField: FC<Props> = ({ fieldValue, saveFieldCallback, disabled }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-y-1.5">
      <FormFieldContainerInput
        initialValue={fieldValue.label}
        property={"label"}
        disabled={disabled}
        onBlurCallback={saveFieldCallback}
        placeholder={t("FORMBUILDER.FORM.BASE.FIELD.TEXT.LABEL_PLACEHOLDER") as string}
      />
      <div className="flex h-full flex-col justify-between">
        <div>
          <FormBaseTextFieldInput
            property={"text"}
            disabled={disabled}
            saveCallback={(property: string, value: any) => saveFieldCallback(property, value[property])}
            initialValue={fieldValue.text}
            fieldValue={fieldValue}
            textFieldLabelValue={fieldValue.label}
          />
          {fieldValue.helper && fieldValue.helper.enabled && (
            <div className="-mt-0.5">
              <FormFieldContainerInput
                initialValue={fieldValue.helper.text || ""}
                property={"helper"}
                disabled={disabled}
                onBlurCallback={(property: string, value: string) => saveFieldCallback(property, { enabled: true, text: value })}
                isHelperTextInput={true}
              />
            </div>
          )}
        </div>
        <div className="mt-1.5 flex flex-row items-center gap-x-4">
          <FormBaseFieldCheckboxWithText
            text={t("FORMBUILDER.FORM.BASE.FIELD.TEXT.HELPER_TEXT")}
            isChecked={fieldValue.helper && fieldValue.helper.enabled}
            property={"enabled"}
            disabled={disabled}
            onChangeCallback={(value: any) => saveFieldCallback("helper", value)}
          />
          <FormBaseFieldWithImportCheckbox fieldValue={fieldValue.import} disabled={disabled} saveFieldCallback={() => saveFieldCallback("import", !fieldValue?.import)} />
        </div>
      </div>
    </div>
  );
};

export default FormBaseTextField;
