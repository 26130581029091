import React, { FC } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons/faCirclePlus";
import { useTranslation } from "react-i18next";
import FormTableFieldRow from "@components/form/fields/tableField/FormTableFieldRow";
import FormTableFieldHeader from "@components/form/fields/tableField/FormTableFieldHeader";

interface Props {
  rows: any;
  field: any;
  nestedDataSaveCallback?: Function;
  addCallback?: Function;
  disabled?: boolean;
  nestedDataRemoveCallback?: Function;
}

const FormTableFieldTable: FC<Props> = ({ field, nestedDataSaveCallback, addCallback, rows, disabled, nestedDataRemoveCallback }) => {
  const { t } = useTranslation();
  const addRow = () => {
    const newRow = field.value.columns.reduce((acc: any, item: any) => {
      acc[item.property] = item.type === "TEXT" ? "" : false;
      return acc;
    }, {});

    addCallback && addCallback(newRow, field.property);
  };

  const removeRow = (rowId: string) => {
    nestedDataRemoveCallback && nestedDataRemoveCallback({ _id: rowId }, field.property);
  };

  return (
    <div className="flex flex-col gap-2">
      {rows.map((row: any, rowIndex: number) => (
        <div className="flex flex-col gap-1" key={rowIndex}>
          {rowIndex === 0 && <FormTableFieldHeader columns={field.value.columns} removeEnabled={rows.length > 1} />}
          <FormTableFieldRow
            row={row}
            saveCallback={nestedDataSaveCallback}
            fieldProperty={field.property}
            disabled={disabled}
            removeRowCallback={rows.length > 1 ? removeRow : undefined}
            columns={field.value.columns}
          />
        </div>
      ))}
      <div className="flex flex-row justify-between">
        <BhIconButton
          icon={faCirclePlus}
          buttonProps={{
            onClick: addRow,
            disabled: disabled
          }}
          sizeClasses="w-max"
        >
          {t("FORMBUILDER.FORM.BASE.FIELD.WEATHER.ADD_ROW")}
        </BhIconButton>
      </div>
    </div>
  );
};
export default FormTableFieldTable;
