import React, { FC, useEffect, useRef, useState } from "react";
import WebViewer, { Core, WebViewerInstance } from "@pdftron/webviewer";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

const PdftronContainerBlank: FC = () => {
    const viewer = useRef<HTMLDivElement | null>(null);
    const [instance, setInstance] = useState<WebViewerInstance | null>(null);
    const [initialized, setInitialized] = useState<boolean>(false);

    const imgSize = 600;
    const annoSize = 200;
    const fetchFileUrlAndInitPdftron = (url: string, xfdf: string, fileName: string) => {
      if (!viewer.current || instance) return; // Prevent multiple initializations
      WebViewer(
        {
          licenseKey: "Bauhub OU:OEM:Bauhub::B+:AMS(20260305):4A5F85823C6F78B942CE606F4F3D959CDE1FC65BA4AF8F58BE54B2B6F5C7",
          filename: "pdf.pdf",
          path: ConfigSingleton.getInstance().getConfig().PUBLIC_URL + "/pdftron10.9.0",
          initialDoc: url,
          extension: fileName.split(".").pop(),
          disabledElements: ["header", "leftPanel", "scaleOverlayContainer", "pageNavOverlay", "annotationCommentButton"],
          disableMultiViewerComparison: true,
          fullAPI: true
        },
        viewer.current
      ).then((instance) => {
        setInstance(instance);
        instance.UI.setPrintQuality(3);

        let annos: Core.Annotations.Annotation[] | undefined = undefined;
        let currentAnno: Core.Annotations.Annotation | undefined = undefined;
        let index = 0;

        instance.Core.documentViewer.addEventListener("documentLoaded", async () => {

          instance.Core.annotationManager.importAnnotations(xfdf);
          if (!annos) {
            // first time get all of them
            annos = instance.Core.annotationManager.getAnnotationsList();
          }
          instance.Core.annotationManager.hideAnnotations(instance.Core.annotationManager.getAnnotationsList());
          currentAnno = annos[index];
          const localAnno = instance.Core.annotationManager.getAnnotationsList().find(a => a.Id === currentAnno?.Id);
          if (!localAnno || !currentAnno) {
            return;
          }
          instance.Core.annotationManager.showAnnotations([localAnno]);
          await instance.Core.PDFNet.initialize();
          const doc = await instance.Core.documentViewer.getDocument().getPDFDoc();
          // Run PDFNet methods with memory management
          await instance.Core.PDFNet.runWithCleanup(async () => {
            if (!currentAnno) {
              return;
            }
            doc.lock();
            // Export only the current annotation
            const annots = await instance.Core.annotationManager.exportAnnotations({ annotList: [currentAnno] });
            // Import annotations to PDFNet
            const fdf_doc = await instance.Core.PDFNet.FDFDoc.createFromXFDF(annots);
            await doc.fdfUpdate(fdf_doc);
            // Flatten the current annotation
            return await doc.flattenAnnotations();
          }, "Bauhub OU:OEM:Bauhub::B+:AMS(20260305):4A5F85823C6F78B942CE606F4F3D959CDE1FC65BA4AF8F58BE54B2B6F5C7");

          // Capture the flattened annotation image
          await captureAnnotationImage(instance, currentAnno);

          index++;
          instance.UI.loadDocument(url, {
            documentId: Math.random() * 1000 + "",
            filename: Math.random() * 1000 + ".pdf"
          });
          // Refresh and update the viewer
          instance.Core.documentViewer.refreshAll();
          instance.Core.documentViewer.updateView();
        });
      });
    };

    const captureAnnotationImage = async (instance: WebViewerInstance, annot: any) => {
      const pageNumber = annot.PageNumber;
      const doc = instance.Core.documentViewer.getDocument();
      const annotRect = annot.getRect();

      // Calculate the current annotation width and height in pixels
      const annotWidth = annotRect.getWidth();
      const annotHeight = annotRect.getHeight();

      // Calculate the zoom level needed to make the annotation 100x100px
      const targetZoom = Math.min(100 / annotWidth, 100 / annotHeight);

      // Determine the center of the annotation
      const centerX = (annotRect.x1 + annotRect.x2) / 2;
      const centerY = (annotRect.y1 + annotRect.y2) / 2;

      // Calculate the top-left corner of the 600x600px area around the annotation
      const left = centerX - (imgSize / 2) / targetZoom;
      const top = centerY - (imgSize / 2) / targetZoom;
      const width = imgSize / targetZoom;
      const height = imgSize / targetZoom;

      const canvas = document.createElement("canvas");
      canvas.width = imgSize;
      canvas.height = imgSize;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        return new Promise<void>((resolve) => {
          doc.loadCanvas({
            pageNumber,
            zoom: targetZoom,
            pageRotation: instance.Core.documentViewer.getCompleteRotation(pageNumber),
            renderRect: {
              x1: left,
              y1: top,
              x2: left + width,
              y2: top + height
            },
            drawComplete: async (pageCanvas) => {
              // Draw the page content first
              ctx.drawImage(pageCanvas, 0, 0, canvas.width, canvas.height);
              // The annotation should now be centered within a 100x100px area
              const imgData = canvas.toDataURL("image/png");
              // Dispatch a custom event with the image data
              const event = new CustomEvent("imageCaptured", { detail: { imgData: imgData, uuid: annot.Id} });
              window.dispatchEvent(event);
              resolve(); // Resolve the promise once the image is captured
            }
          });
        });
      }
    };

    useEffect(() => {
      if (!initialized) {
        const handleMessage = (event: any) => {
          if (event.data && event.data.url && !instance) { // Check to ensure no instance already exists
            fetchFileUrlAndInitPdftron(event.data.url, event.data.xfdf, event.data.fileName);
          }
        };

        // Listen for messages
        window.addEventListener("message", handleMessage);

        return () => {
          window.removeEventListener("message", handleMessage); // Cleanup listener on unmount
        };
      }

      setInitialized(true);
    }, []); // Depend on `initialized` and `instance`

    return (
      <div className="bh-bg-white flex h-screen flex-col overflow-hidden text-left">
        <div className="webviewer" ref={viewer} style={{ height: "100%", width: "100%" }} />
      </div>
    );
  }
;

export default PdftronContainerBlank;
