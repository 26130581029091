import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  classes?: string;
}

const BhPigeon20Separator: FC<Props> = ({ classes }) => {
  return <div className={classNames(classes ? classes : "my-4", "bh-pigeon-20-separator")} />;
};

export default BhPigeon20Separator;
