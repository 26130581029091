import React, { FC, useEffect } from "react";
import TaskBoardList from "@/views/home/project/detail/taskBoard/TaskBoardList";
import TaskBoard from "@/views/home/project/detail/taskBoard/TaskBoard";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchProjectTaskBoardsAsync } from "@/app/store/taskBoardsSlice";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import NotFound from "@/views/global/NotFound";
import PersonalTaskBoard from "@/views/home/project/detail/taskBoard/PersonalTaskBoard";
import AllTasksTaskBoard from "@/views/home/project/detail/taskBoard/AllTasksTaskBoard";
import TaskBoardModals from "@/views/home/project/detail/taskBoard/modals/TaskBoardModals";
import { fetchProjectTaskCategoriesAsync, fetchProjectTaskTagsAsync, removeDeletedTasksFromTaskBoards } from "@/app/store/tasksSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import { EntityId } from "@reduxjs/toolkit";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

interface Props {}

const TaskBoardContainerFC: FC<Props> = () => {
  useDocumentTitle("GLOBAL.TASKBOARDS");
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const [lastOpenedTaskBoardByProjectId] = useLocalStorage<{ [projectId: EntityId]: EntityId }>("lastOpenedTaskBoardByProjectId", {});

  useEffect(() => {
    dispatch(setSidebarCollapsed(true));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.TASKBOARDS));
  }, []);

  useEffect(() => {
    if (projectId) {
      dispatch(fetchProjectTaskBoardsAsync(projectId));
      dispatch(fetchProjectTaskCategoriesAsync(projectId));
      dispatch(fetchProjectTaskTagsAsync(projectId));
      dispatch(removeDeletedTasksFromTaskBoards());
    }
  }, [projectId]);

  function TaskBoardContainerWithParams() {
    const { taskBoardId } = useParams();
    if (taskBoardId) {
      const taskBoardIdInt = parseInt(taskBoardId);
      return <TaskBoard taskBoardId={taskBoardIdInt} />;
    }
    return <NotFound />;
  }

  const redirectTo = () => {
    if (lastOpenedTaskBoardByProjectId[projectId]) {
      if (lastOpenedTaskBoardByProjectId[projectId] === -2) {
        return "all";
      } else if (Number(lastOpenedTaskBoardByProjectId[projectId]) > 0) {
        return lastOpenedTaskBoardByProjectId[projectId] + "";
      } else {
        return "personal";
      }
    }
    return "personal";
  };
  const redirectToUrl = redirectTo();

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex w-full">
        <aside className="relative flex w-72 flex-shrink-0 overflow-y-auto md:hidden">
          <div className="w-full flex-col">
            <div className="h-full">
              <TaskBoardList />
            </div>
          </div>
        </aside>
        <main className="relative inline-flex w-full overflow-hidden">
          <Routes>
            <Route path=":taskBoardId" element={<TaskBoardContainerWithParams />} />
            <Route path="personal" element={<PersonalTaskBoard />} />
            <Route path="all" element={<AllTasksTaskBoard />} />
            <Route path="/" element={<Navigate to={redirectToUrl} replace />} />
          </Routes>
        </main>
        <TaskBoardModals />
      </div>
    </DndProvider>
  );
};

const TaskBoardContainer = React.memo(TaskBoardContainerFC);
export default TaskBoardContainer;
