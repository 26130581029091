import React, { FC } from "react";
import { IBhButtonWithIconProps } from "@components/buttons/IBhButtonProps";
import BhButtonTemplateWithIcon from "@components/buttons/BhButtonTemplateWithIcon";
import { classNames } from "@/utilities/jsUtilities";

// FIGMA https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H/Bauhub-design-system?node-id=2080%3A112472

const BhDeclineButton: FC<IBhButtonWithIconProps> = ({ buttonProps, icon, children }) => {
  buttonProps = buttonProps ?? {};
  buttonProps.classes = classNames(
    buttonProps.classes,
    buttonProps.textClasses || "bh-text-white disabled:bh-text-pigeon-60",
    buttonProps.widthClasses || "min-w-70px",
    !buttonProps.disabled && "bh-bg-error-red-10 hover:bh-bg-error-red-20 active:bh-bg-error-red-30 bh-text-error-red-120",
    buttonProps.disabled && "disabled:bh-bg-pigeon-20 disabled:bh-text-pigeon-60",
    "py-1.5"
  );

  return (
    <BhButtonTemplateWithIcon buttonProps={buttonProps} icon={icon}>
      {children}
    </BhButtonTemplateWithIcon>
  );
};

export default BhDeclineButton;
