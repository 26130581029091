import React, { FC } from "react";
import { IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import BhWysiwygEditor from "@components/wysiwyg/BhWysiwygEditor";

interface Props {
  baseVersionRow: IChecklistBaseVersionRow;
  saveCallback: Function;
  disabled?: boolean;
}

const ChecklistBaseRowWysiwigField: FC<Props> = ({ baseVersionRow, saveCallback, disabled }) => {
  return (
    <div className="p-4">
      <BhWysiwygEditor initialValue={baseVersionRow.fieldValue} property={"fieldValue"} saveCallback={saveCallback} disabled={disabled} />
    </div>
  );
};

export default ChecklistBaseRowWysiwigField;
