import React, { FC, useEffect, useRef, useState } from "react";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import BhInputSimple from "@components/input/BhInputSimple";
import { useOuterClick } from "@/utilities/hooks/useOuterClick";
import { sortByFullNameAndEmail } from "@/utilities/sortUtilities";

interface Props {
  emailInput?: string;
  setEmailInput?: Function;
  values: Array<ISimpleValidUserAuthority>;
  showValueCondition: Function;
  placeholder?: string;
}

const BhEmailInputWithUserDropdown: FC<Props> = ({ emailInput, setEmailInput, values, showValueCondition, placeholder }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const outsideClickRef = useOuterClick(() => {
    setIsDropdownOpen(false);
  });

  const onSelectDropdownValue = (selectedUser: ISimpleValidUserAuthority) => {
    if (selectedUser?.username.length > 0) {
      setEmailInput && setEmailInput(selectedUser?.username);
      if (inputRef?.current) inputRef.current.value = selectedUser?.username;
    }
    setIsDropdownOpen(false);
  };

  const queryFilter = (value: ISimpleValidUserAuthority) => {
    const fullName = value?.firstName + " " + value?.lastName;
    const input = emailInput || "";
    const nameFilter = fullName.toLowerCase().replace(/\s+/g, "").includes(input.toLowerCase().replace(/\s+/g, ""));
    const userNameFilter = value.username?.toLowerCase().replace(/\s+/g, "").includes(input.toLowerCase().replace(/\s+/g, ""));
    return nameFilter || userNameFilter;
  };

  const filteredValues = values
    .filter((value) => {
      const isQueryFilter = emailInput === "" ? true : queryFilter(value);
      const isShowValueCondition = showValueCondition ? showValueCondition(value) : true;
      return isQueryFilter && isShowValueCondition;
    })
    .sort((a, b) => sortByFullNameAndEmail(a, b));

  useEffect(() => {
    if (emailInput === "" && inputRef?.current) {
      inputRef.current.value = "";
    }
  }, [emailInput]);

  return (
    <div className="relative w-full" ref={outsideClickRef}>
      <BhInputSimple
        initialValue={emailInput}
        property={"email"}
        placeholder={placeholder}
        onChangeCallback={(value: { email: string }) => setEmailInput && setEmailInput(value.email)}
        onBlurAction={(value: { email: string }) => setEmailInput && setEmailInput(value.email)}
        onFocus={() => setIsDropdownOpen(true)}
        inputRef={inputRef}
      />
      {isDropdownOpen && filteredValues.length > 0 && (
        <div className="absolute z-50 min-w-full max-w-[500px]">
          <BhDropdownMenu type={BhDropdownTypeEnum.AUTH} withUsername={true} values={filteredValues} onSelect={onSelectDropdownValue} customDropdown={true} widthClass="w-full" />
        </div>
      )}
    </div>
  );
};

export default React.memo(BhEmailInputWithUserDropdown);
