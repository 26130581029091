import React, { FC } from "react";

interface Props {
  rowTotalOk: number;
  rowTotalNotOk: number;
}

const PlusMinusRowTotal: FC<Props> = ({ rowTotalNotOk, rowTotalOk }) => {
  const rowTom = (rowTotalOk * 100) / (rowTotalOk + rowTotalNotOk) || 0;
  return (
    <div className="flex h-full min-w-[100px] flex-col justify-center py-2">
      <div className="text-14px bh-text-deep-ocean mb-1 flex w-full flex-row justify-center leading-5">{rowTom.toFixed(1) + "%"}</div>
    </div>
  );
};

export default PlusMinusRowTotal;
