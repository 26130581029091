import React, { FC, useEffect } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { useAppDispatch } from "@/app/hooks";
import ConfirmationContainer from "@/views/home/project/detail/confirmations/confirmation/ConfirmationContainer";
import ConfirmationActionsContainer from "@/views/home/project/detail/confirmations/confirmation/actions/ConfirmationActionsContainer";
import { Route, Routes } from "react-router-dom";

interface Props {
  confirmationId: EntityId;
}
const ConfirmationRouter: FC<Props> = ({ confirmationId }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSidebarCollapsed(false));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.CONFIRMATIONS));
  }, []);

  return (
    <Routes>
      <Route path="*" element={<ConfirmationContainer confirmationId={confirmationId} />} />
      <Route path="actions/*" element={<ConfirmationActionsContainer confirmationId={confirmationId} />} />
    </Routes>
  );
};

export default ConfirmationRouter;
