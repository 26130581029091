import React, { FC } from "react";
import BhSpreadsheetTable from "@components/spreadsheet/BhSpreadsheetTable";
import BhSpreadsheetTableHead from "@components/spreadsheet/BhSpreadsheetTableHead";
import BhSpreadsheetTableHeaderRow from "@components/spreadsheet/BhSpreadsheetTableHeaderRow";
import BhSpreadsheetTableTH from "@components/spreadsheet/BhSpreadsheetTableTH";
import BhSpreadsheetTableBody from "@components/spreadsheet/BhSpreadsheetTableBody";
import BhSpreadsheetTableTR from "@components/spreadsheet/BhSpreadsheetTableTR";
import BhSpreadsheetTableCell from "@components/spreadsheet/BhSpreadsheetTableCell";
import { useAppSelector } from "@/app/hooks";
import { boSelectYearlyTrialOverview } from "@/app/store/backofficeSlice";
import { IYearlyTrialOverview } from "@/model/trials/IYearlyTrialOverview";
import { classNames } from "@/utilities/jsUtilities";
import BOTrialsSpreadsheetTableRow from "@components/backoffice/trials/BOTrialsSpreadsheetTableRow";
import { formatCurrency } from "@/utilities/accountingUtilities";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";

const BOTrialsSpreadsheetTable: FC = () => {
  const yearlyTrialOverview = useAppSelector(boSelectYearlyTrialOverview);
  const rowsExist = yearlyTrialOverview.rows && yearlyTrialOverview.rows.length > 0;
  const totalActiveCompanies =
    yearlyTrialOverview.rows?.reduce((prev, trial) => {
      return prev + (trial.ongoingTrials?.some((t) => t.projectCount > 0) ? 1 : 0);
    }, 0) || 0;

  const trialsTableHeaders: Array<IBhTableHeader<IYearlyTrialOverview>> = [
    { id: 0, content: "Trial ends", classes: "w-544px" },
    { id: 1, content: "Companies", classes: "text-left w-40" },
    { id: 2, content: "Active companies", classes: "text-left w-40" },
    { id: 3, content: "Estimated turnover", classes: "text-right w-40" },
    { id: 4, content: "Actual turnover", classes: "text-right w-40" },
    { id: 5, content: "Turnover total", classes: "text-right w-40" }
  ];

  const formatSum = (sum: number) => {
    return formatCurrency(sum, { symbol: "€", thousandsSeparator: " ", decimalSeparator: ",", significantDigits: 2 }, true);
  };

  return (
    <>
      <BhSpreadsheetTable>
        <BhSpreadsheetTableHead>
          <BhSpreadsheetTableHeaderRow>
            {trialsTableHeaders.map((header) => (
              <BhSpreadsheetTableTH key={header.id} classes={classNames(header.classes, "py-2.5")}>
                {header.content}
              </BhSpreadsheetTableTH>
            ))}
          </BhSpreadsheetTableHeaderRow>
        </BhSpreadsheetTableHead>
        <BhSpreadsheetTableBody>
          {rowsExist && yearlyTrialOverview.rows.map((trial) => <BOTrialsSpreadsheetTableRow trial={trial} />)}
          {rowsExist && (
            <>
              <BhSpreadsheetTableTR classes="border-x-white border"></BhSpreadsheetTableTR>
              <BhSpreadsheetTableTR>
                <BhSpreadsheetTableCell classes="border !border-y-white !border-l-white"></BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell>{yearlyTrialOverview.trialCompaniesCount}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell>{totalActiveCompanies}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="text-right">{formatSum(yearlyTrialOverview.estimatedTurnover)}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="text-right">{formatSum(yearlyTrialOverview.actualTurnover)}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="text-right">{formatSum(yearlyTrialOverview.turnoverTotal)}</BhSpreadsheetTableCell>
              </BhSpreadsheetTableTR>
            </>
          )}
        </BhSpreadsheetTableBody>
      </BhSpreadsheetTable>
    </>
  );
};

export default BOTrialsSpreadsheetTable;
