import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { FileEntityBranch, FileEntityConfirmationStatus, FileEntityType, IFileEntity, IFolderFileEntity, IRevisionReplaceRequestDTO } from "@/model/files/IFileEntity";
import {
  createContainer,
  createFolderHierarchy,
  deleteFiles,
  fetchAllUnlockedFilesForDirs,
  fetchDirectory,
  fetchDirectoryOld,
  fetchFileEntity,
  fetchFileRevisions,
  removeFileFromContainer,
  replaceFileRevision,
  saveFileEntities,
  saveFileName,
  saveNewDirectory,
  toggleContainerQueuedSigning,
  undoAndPreserveFileRevision,
  undoFileRevision
} from "@/api/fileAPI";
import { SidebarItemType } from "@/model/ISidebarItem";
import { RootState } from "@/app/store";
import { setCurrentSidebarMenuItem } from "@/app/store/sidebarSlice";
import { foldersAdded, foldersRemoved, selectedInFileTreeFolderSet } from "@/app/store/foldersSlice";
import { IFilesFilter } from "@/model/files/IFilesFilter";
import { IFileEntityTag } from "@/model/files/IFileEntityTag";
import { IFileExtensionFilter } from "@/model/files/IFileExtensionFilter";
import { IFilesSort, initialFilesSort } from "@/model/files/IFilesSort";
import { directoryModalsInitialState, IDirectoryModals, IDirectoryModalsOpen } from "@/model/files/IDirectoryModals";
import { deleteFileEntityComment, deleteFileEntityTag, fetchFileEntityComments, saveFileEntityComment, saveFileEntityTag } from "@/api/fileInfoAPI";
import { IFileEntityComment } from "@/model/files/IFileEntityComment";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { IContainerCreateRequest } from "@/model/container/IContainerCreateRequest";
import { asyncJobAdded } from "@/app/store/asyncJobsSlice";
import { IAsyncJob } from "@/model/IAsyncJob";
import { toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { RevisionUndoType } from "@/views/home/project/detail/directory/directoryModals/revisionModal/UndoRevisionModal";
import { isImage } from "@/utilities/fileEntity/fileEntityUtilities";
import { DirectoryFileInfoBarTab } from "@/views/home/project/detail/directory/fileInfoBar/DirectoryFileInfoBar";
import { fetchAllFavouriteFolders, removeFavouriteFile, saveFavouriteFile } from "@/api/favouriteAPI";
import { findAllChildrenForFileEntities } from "@/utilities/fileEntityUtilities";
import { naturalSortFilesByField, sortFilesBySignatures } from "@/utilities/sortUtilities";

const filesAdapter = createEntityAdapter<IFileEntity>();

const filesInitialState = filesAdapter.getInitialState<{
  status: BhStateStatusType;
  error: null;
  currentDirId?: EntityId;
  sort: IFilesSort;
  filter: IFilesFilter;
  type?: SidebarItemType;
  infoBarTab: DirectoryFileInfoBarTab;
  directoryModals: IDirectoryModals;
  fileDraggedToDirectory?: IFileEntity;
  tossFileAnimationTriggered: boolean;
}>({
  status: BhStateStatusType.INITIAL,
  error: null,
  sort: initialFilesSort,
  filter: {
    extensions: [
      { id: 0, name: "pdf" },
      { id: 1, name: "jpg" },
      { id: 2, name: "dwg" },
      { id: 3, name: "asice" },
      { id: 4, name: "docx" },
      { id: 5, name: "xlsx" },
      { id: 6, name: "ifc" },
      { id: 7, name: "jpeg" }
    ]
  } as IFilesFilter,
  infoBarTab: DirectoryFileInfoBarTab.COLLAPSED,
  directoryModals: directoryModalsInitialState,
  tossFileAnimationTriggered: false
});

export function changeBranchType(dispatch: (arg0: { payload: SidebarItemType; type: string }) => void, files: Array<IFileEntity>) {
  if (files[0]) {
    dispatch(setCurrentSidebarMenuItem(files[0].branch === "ROOT_DIR" ? SidebarItemType.DRAWINGS : SidebarItemType.DOCUMENTS));
  }
}

export const fetchFilesAsync = createAsyncThunk("files/fetchDir", async (dirId: EntityId, { dispatch }) => {
  const response = await fetchDirectory(dirId);
  dispatch(selectedInFileTreeFolderSet(dirId));
  // lisa kaustad FileTree'sse
  const folders = response.filter((fe) => [FileEntityType.DIR, FileEntityType.ROOT_DIR, FileEntityType.ROOT_DOCUMENT_DIR].includes(fe.type)) as IFolderFileEntity[];
  dispatch(foldersAdded({ files: folders, parentId: dirId }));
  return { files: response, dirId: dirId };
});

export const fetchFilesOldAsync = createAsyncThunk("files/fetchDir/old", async (dirId: EntityId) => {
  const response = await fetchDirectoryOld(dirId);
  return { files: response, dirId: dirId };
});

export const fetchFileEntityAsync = createAsyncThunk("files/fetchFileEntity", async (fileEntityId: EntityId) => {
  return fetchFileEntity(fileEntityId);
});

export const fetchAllUnlockedFilesForDirsAsync = createAsyncThunk("files/fetchAllUnlockedFilesForDirs", async (fileEntityIds: Array<EntityId>) => {
  return fetchAllUnlockedFilesForDirs(fileEntityIds);
});

export const saveFileEntitiesAsync = createAsyncThunk("files/saveFileEntity", async (fileEntities: Array<IFileEntity>) => {
  return saveFileEntities(fileEntities);
});

export const saveNewDirectoryAsync = createAsyncThunk("files/saveNewDir", async (folderFileEntity: IFileEntity, { dispatch }) => {
  const response = await saveNewDirectory(folderFileEntity);
  dispatch(foldersAdded({ files: [response as IFolderFileEntity], parentId: response.parentFileEntityId }));
  return response;
});

export const fetchAllFavouriteFoldersAsync = createAsyncThunk("files/fetchAllFavouriteFolders", async (projectId: EntityId, { dispatch }) => {
  const folders = await fetchAllFavouriteFolders(projectId);
  dispatch(foldersAdded({ files: folders }));
  return folders;
});

export const toggleFavouriteFileAsync = createAsyncThunk("files/toggleFavouriteFile", async (file: IFileEntity) => {
  if (!file.favourite) {
    return saveFavouriteFile(file.id);
  } else {
    return removeFavouriteFile(file.id);
  }
});

export const saveFileNameAsync = createAsyncThunk("files/saveFileName", async (fileEntity: IFileEntity, { dispatch }) => {
  const response = await saveFileName(fileEntity);
  if (fileEntity.type === FileEntityType.DIR) dispatch(foldersAdded({ files: [response as IFolderFileEntity], parentId: response.parentFileEntityId }));
  if (fileEntity.tags && fileEntity.tags.length > 0) {
    const responseWithTags = { ...response, ...{ tags: fileEntity.tags } };
    return responseWithTags;
  }
  return response;
});

export const deleteFilesAsync = createAsyncThunk("files/deleteFiles", async (_, { getState, dispatch }) => {
  const state: RootState = getState() as RootState;
  const fileEntityIdsToDelete = state.files.directoryModals.deleteConfirmationModalFileEntityIds;
  if (!fileEntityIdsToDelete || fileEntityIdsToDelete.length === 0) return;
  const filesToDelete = Object.values(state.files.entities).filter((fileEntity) => fileEntity && fileEntityIdsToDelete.includes(fileEntity.id)) as Array<IFileEntity>;
  const deletedFileEntities = await deleteFiles(filesToDelete);

  // findAllChildren
  const parentFileEntityIds = deletedFileEntities.map((fileEntity) => fileEntity.id);
  const allFileEntities = Object.values(state.files.entities) as Array<IFileEntity>;
  let deletedFilesWithChildren = [] as Array<EntityId>;
  findAllChildrenForFileEntities(allFileEntities, parentFileEntityIds, deletedFilesWithChildren);

  // Remove deleted folders from folderSlice
  dispatch(foldersRemoved(deletedFilesWithChildren));

  return deletedFilesWithChildren;
});

export const removeFileFromContainerAsync = createAsyncThunk("files/removeFileFromContainer", async (fileEntityId: EntityId) => {
  return removeFileFromContainer(fileEntityId);
});

export const fetchFileEntityCommentsAsync = createAsyncThunk("files/fetchFileEntityComments", async (fileEntityId: EntityId) => {
  const response = await fetchFileEntityComments(fileEntityId);
  return { fileEntityId: fileEntityId, comments: response };
});

export const saveFileEntityCommentAsync = createAsyncThunk("files/saveFileEntityComment", async (comment: IFileEntityComment) => {
  return await saveFileEntityComment(comment);
});

export const deleteFileEntityCommentAsync = createAsyncThunk("files/deleteFileEntityComment", async (comment: IFileEntityComment) => {
  return deleteFileEntityComment(comment);
});

export const createContainerAsync = createAsyncThunk("files/createContainer", async (dto: { projectId: EntityId; requestDTO: IContainerCreateRequest }, { dispatch }) => {
  const result = await createContainer(dto.projectId, dto.requestDTO);
  const asyncJob = { id: result.jobId, status: result.status, requestDTO: dto.requestDTO } as IAsyncJob;
  dispatch(asyncJobAdded(asyncJob));
  dispatch(toggleProjectModalsOpen({ modal: "directoryContainerModal" }));
  dispatch(allFileEntitiesUnSelected());
  return result;
});

export const fetchFileRevisionsAsync = createAsyncThunk("files/fetchFileRevisions", async (fileId: EntityId) => {
  const result = await fetchFileRevisions(fileId);
  return { result: result, parentFileEntityId: fileId };
});

export const undoFileRevisionAsync = createAsyncThunk("files/undoFileRevision", async (dto: { fileId: EntityId; type: RevisionUndoType }) => {
  let result;
  if (dto.type === RevisionUndoType.UNDO) {
    result = await undoFileRevision(dto.fileId);
  }
  if (dto.type === RevisionUndoType.UNDO_AND_PRESERVE) {
    result = await undoAndPreserveFileRevision(dto.fileId);
  }
  return { parentFileId: dto.fileId, type: dto.type, result: result };
});

export const saveFileEntityTagAsync = createAsyncThunk("files/saveFileEntityTag", async (fileEntityTag: IFileEntityTag) => {
  return saveFileEntityTag(fileEntityTag);
});

export const deleteFileEntityTagAsync = createAsyncThunk("files/deleteFileEntityTag", async (fileEntityTag: IFileEntityTag) => {
  return deleteFileEntityTag(fileEntityTag);
});

export const replaceFileRevisionAsync = createAsyncThunk("files/replaceFileRevision", async (dto: IRevisionReplaceRequestDTO, { dispatch }) => {
  const result = await replaceFileRevision(dto);
  dispatch(fileRemoved(dto.fileToReplaceId));
  dispatch(fileRemoved(dto.replacementFileId));
  return result;
});

export const createFolderHierarchyAsync = createAsyncThunk("files/createFolderHierarchyAsync", async (dto: { directoryId: EntityId; paths: Array<string> }, { dispatch }) => {
  const response = await createFolderHierarchy(dto.directoryId, dto.paths);
  // lisa kaustad FileTree'sse
  const folders = response.map((folderHierarchyDTO) => folderHierarchyDTO.folderFileEntity) as IFolderFileEntity[];
  dispatch(foldersAdded({ files: folders }));
  return response;
});

export const toggleContainerQueuedSigningAsync = createAsyncThunk("files/enableContainerQueuedSinging", async (containerFileEntity: IFileEntity) => {
  return toggleContainerQueuedSigning(containerFileEntity);
});

const filesSlice = createSlice({
  name: "files",
  initialState: filesInitialState,
  reducers: {
    allFilesRemoved: filesAdapter.removeAll,
    filesRemoved: filesAdapter.removeMany,
    fileRemoved: filesAdapter.removeOne,
    fileAdded: filesAdapter.addOne,
    filesAdded: filesAdapter.upsertMany,
    fileModified: filesAdapter.upsertOne,
    filesModified: filesAdapter.upsertMany,
    filesUpdatedAndAdded: (state, action: PayloadAction<Array<IFileEntity>>) => {
      const newAndUpdatedFiles = action.payload.map((file) => {
        const alreadySavedFile = state.entities[file.id];
        return alreadySavedFile ? { ...alreadySavedFile, ...file } : file;
      });
      filesAdapter.upsertMany(state, newAndUpdatedFiles);
    },
    onlyThisFileEntitySelected: (state, action: PayloadAction<{ id: EntityId }>) => {
      const { id } = action.payload;
      const existingFileEntity = state.entities[id];
      if (existingFileEntity) {
        existingFileEntity.selected = !existingFileEntity.selected;
      }
      const files = Object.values(state.entities);
      files.forEach((fileEntity) => {
        if (fileEntity && existingFileEntity && fileEntity.id !== existingFileEntity.id) fileEntity.selected = false;
      });
      state.tossFileAnimationTriggered = true;
    },
    fileEntitySelected: (state, action: PayloadAction<{ ids: Array<EntityId>; selected: boolean }>) => {
      const { ids, selected } = action.payload;
      ids.forEach((id) => {
        const existingFileEntity = state.entities[id];
        if (existingFileEntity) {
          existingFileEntity.selected = selected;
        }
      });
      state.tossFileAnimationTriggered = selected;
    },
    fileTossAnimationCleared: (state) => {
      state.tossFileAnimationTriggered = false;
    },
    setFileEntityParentIdsAfterMove: (state, action: PayloadAction<{ ids: Array<EntityId>; newParentFileEntity: EntityId }>) => {
      const { ids, newParentFileEntity } = action.payload;
      ids.forEach((id) => {
        const existingFileEntity = state.entities[id];
        if (existingFileEntity) {
          existingFileEntity.parentFileEntityId = newParentFileEntity;
        }
      });
    },
    setFileEntityUrl: (state, action: PayloadAction<{ id: number; url: string }>) => {
      const { id, url } = action.payload;
      const existingFileEntity = state.entities[id];
      if (existingFileEntity) {
        existingFileEntity.url = url;
        const now = new Date();
        existingFileEntity.urlCreated = now.getTime();
      }
    },
    allFileEntitiesUnSelected: (state) => {
      Object.values(state.entities).forEach((fileEntity) => {
        if (fileEntity) fileEntity.selected = false;
      });
    },
    allFilteredFilesSelectedToggledInDirectory: (state, action: PayloadAction<Array<EntityId>>) => {
      const allFiles = Object.values(state.entities);
      const filteredFileIds = action.payload;
      const filteredDirFiles = allFiles.filter((fileEntity) => fileEntity && filteredFileIds.some((id) => id === fileEntity.id));
      const allSelected = filteredDirFiles.every((fileEntity) => fileEntity && fileEntity.selected);
      const value = !allSelected;
      filteredDirFiles.forEach((fileEntity) => {
        if (fileEntity) fileEntity.selected = value;
      });
      state.tossFileAnimationTriggered = value;
    },
    allFilesSelectedToggledInSearch: (state, action: PayloadAction<Array<EntityId>>) => {
      const allFiles = Object.values(state.entities);
      const searchResultIds = action.payload;
      const searchResultFiles = allFiles.filter((fileEntity) => (fileEntity ? searchResultIds.includes(fileEntity.id) : false));
      const allSelected = searchResultFiles.every((fileEntity) => fileEntity && fileEntity.selected);
      const value = !allSelected;
      searchResultFiles.forEach((fileEntity) => {
        if (fileEntity) fileEntity.selected = value;
      });
      state.tossFileAnimationTriggered = value;
    },
    currentDirIdSet: (state, action: PayloadAction<EntityId>) => {
      state.currentDirId = action.payload;
    },
    setDirectoryModalsOpen: (state, action: PayloadAction<{ modal: keyof IDirectoryModalsOpen; value: boolean }>) => {
      state.directoryModals.open[action.payload.modal] = action.payload.value;
    },
    setRenameModalFileEntity: (state, action: PayloadAction<IFileEntity>) => {
      state.directoryModals.renameModalFileEntity = action.payload;
    },
    resetFileDirectoryModalState: (state) => {
      state.directoryModals = directoryModalsInitialState;
    },
    setDeleteConfirmationModalFileEntityIds: (state, action: PayloadAction<Array<EntityId>>) => {
      state.directoryModals.deleteConfirmationModalFileEntityIds = action.payload;
    },
    setUndoRevisionModalFileEntity: (state, action: PayloadAction<IFileEntity>) => {
      state.directoryModals.undoRevisionModalFileEntity = action.payload;
    },
    authoritiesModalFileEntitySet: (state, action: PayloadAction<EntityId>) => {
      state.directoryModals.authoritiesModalFileEntity = action.payload;
    },
    setSelectedFilesAsDeleteConfirmationModalFileEntityIds: (state) => {
      const files = Object.values(state.entities);
      const allSelectedFileEntities = files.filter((fileEntity) => fileEntity !== undefined && fileEntity.selected);
      if (allSelectedFileEntities) {
        // @ts-ignore
        state.directoryModals.deleteConfirmationModalFileEntityIds = allSelectedFileEntities.map((fileEntity) => fileEntity.id);
      }
    },
    setFilesFilter: (state, action: PayloadAction<IFilesFilter>) => {
      state.filter = action.payload;
    },
    fileNameFilterReset: (state) => {
      state.filter.name = "";
    },
    tagToggledInFilesFilter: (state, action: PayloadAction<IFileEntityTag>) => {
      if (!state.filter.tags) {
        state.filter.tags = [];
      }
      const isTagAlreadyFiltered = state.filter.tags.some((t) => t.text === action.payload.text);
      if (isTagAlreadyFiltered) {
        state.filter.tags = state.filter.tags.filter((t) => t.text !== action.payload.text);
      } else {
        state.filter.tags = [...state.filter.tags, { text: action.payload.text } as IFileEntityTag];
      }
    },
    tagFilterCleared: (state) => {
      state.filter.tags = [];
    },
    directoryInfoBarTabSet: (state, action: PayloadAction<DirectoryFileInfoBarTab>) => {
      state.infoBarTab = action.payload;
    },
    extensionToggledInFilesFilter: (state, action: PayloadAction<IFileExtensionFilter>) => {
      const extensionFilter = state.filter.extensions.find((ext) => ext.id === action.payload.id);
      if (extensionFilter) {
        extensionFilter.selected = !extensionFilter.selected;
        if (extensionFilter.name === "jpg") {
          const jpegFilter = state.filter.extensions.find((ext) => ext.name === "jpeg");
          if (jpegFilter) {
            jpegFilter.selected = extensionFilter.selected;
          }
        }
      }
    },
    extensionFilterCleared: (state) => {
      state.filter.extensions.forEach((ext) => (ext.selected = false));
    },
    filesSortSet: (state, action: PayloadAction<IFilesSort>) => {
      state.sort = action.payload;
    },
    filesSentToConfirmation: (state, action: PayloadAction<{ fileIds: Array<EntityId>; userCount: number }>) => {
      const { fileIds, userCount } = action.payload;
      fileIds.forEach((fileEntityId) => {
        const existingFileEntity = state.entities[fileEntityId];
        if (existingFileEntity) {
          existingFileEntity.confirmationUserCount = userCount;
          existingFileEntity.confirmedCount = 0;
          existingFileEntity.status = FileEntityConfirmationStatus.PENDING;
        }
      });
    },
    fileDraggedToDirectorySet: (state, action: PayloadAction<IFileEntity | undefined>) => {
      state.fileDraggedToDirectory = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilesAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchFilesAsync.rejected, (state, action) => {
        state.status = BhStateStatusType.ERROR;
      })
      .addCase(fetchFilesAsync.fulfilled, (state, action) => {
        const fileEntities = action.payload.files;
        const parentDirId = action.payload.dirId;
        const fileEntitiesIds = fileEntities.map((fileEntity) => fileEntity.id);
        const filesToDelete = Object.values(state.entities).filter((fileEntity) => {
          if (!fileEntity) return false;
          return fileEntity.parentFileEntityId === parentDirId && !fileEntitiesIds.includes(fileEntity.id);
        });
        filesAdapter.removeMany(
          state,
          // @ts-ignore
          filesToDelete.filter((item) => item).map((fe) => fe.id)
        );
        filesAdapter.upsertMany(state, fileEntities);
        state.status = BhStateStatusType.SUCCESS;
      })
      .addCase(fetchFilesOldAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchFilesOldAsync.rejected, (state, action) => {
        state.status = BhStateStatusType.ERROR;
      })
      .addCase(fetchFilesOldAsync.fulfilled, (state, action) => {
        const fileEntities = action.payload.files;
        const parentDirId = action.payload.dirId;
        const fileEntitiesIds = fileEntities.map((fileEntity) => fileEntity.id);
        const filesToDelete = Object.values(state.entities).filter((fileEntity) => {
          if (!fileEntity) return false;
          return fileEntity.parentFileEntityId === parentDirId && !fileEntitiesIds.includes(fileEntity.id);
        });
        filesAdapter.removeMany(
          state,
          // @ts-ignore
          filesToDelete.filter((item) => item).map((fe) => fe.id)
        );
        filesAdapter.upsertMany(state, fileEntities);
        state.status = BhStateStatusType.SUCCESS;
      })
      .addCase(saveFileEntitiesAsync.pending, (state) => {})
      .addCase(saveFileEntitiesAsync.fulfilled, (state, action) => {
        filesAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchAllUnlockedFilesForDirsAsync.fulfilled, (state, action) => {
        filesAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchFileEntityAsync.pending, (state) => {})
      .addCase(fetchFileEntityAsync.fulfilled, (state, action) => {
        filesAdapter.upsertOne(state, action.payload);
      })
      .addCase(saveNewDirectoryAsync.pending, (state) => {})
      .addCase(saveNewDirectoryAsync.fulfilled, (state, action) => {
        const savedFolderFileEntity = action.payload;
        filesAdapter.upsertOne(state, savedFolderFileEntity);
      })
      .addCase(deleteFilesAsync.pending, (state) => {})
      .addCase(deleteFilesAsync.fulfilled, (state, action) => {
        const deletedFileEntityIds = action.payload;
        if (deletedFileEntityIds) {
          filesAdapter.removeMany(state, deletedFileEntityIds);
        }
      })
      .addCase(removeFileFromContainerAsync.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        const removedFile: IFileEntity = action.payload;
        if (removedFile?.id) {
          filesAdapter.removeOne(state, removedFile.id);
        }
      })
      .addCase(saveFileNameAsync.pending, (state) => {})
      .addCase(saveFileNameAsync.fulfilled, (state, action) => {
        const savedFolderFileEntity = action.payload;
        filesAdapter.upsertOne(state, savedFolderFileEntity);
      })
      .addCase(fetchFileEntityCommentsAsync.pending, (state) => {})
      .addCase(fetchFileEntityCommentsAsync.fulfilled, (state, action) => {
        const { fileEntityId, comments } = action.payload;
        const existingFileEntity = state.entities[fileEntityId];
        if (existingFileEntity) {
          existingFileEntity.comments = comments;
        }
      })
      .addCase(saveFileEntityCommentAsync.pending, (state) => {})
      .addCase(saveFileEntityCommentAsync.fulfilled, (state, action) => {
        const comment = action.payload;
        const existingFileEntity = state.entities[comment.fileEntityId];
        if (existingFileEntity) {
          existingFileEntity.comments = [...existingFileEntity.comments, comment];
          existingFileEntity.commentCount = existingFileEntity.comments.length;
        }
      })
      .addCase(deleteFileEntityCommentAsync.pending, (state) => {})
      .addCase(deleteFileEntityCommentAsync.fulfilled, (state, action) => {
        const comment = action.payload;
        const existingFileEntity = state.entities[comment.fileEntityId];
        if (existingFileEntity && comment.deleted) {
          const commentIndex = existingFileEntity.comments.findIndex((c) => c.id === comment.id);
          existingFileEntity.comments.splice(commentIndex, 1);
          existingFileEntity.commentCount = existingFileEntity.comments.length;
        }
      })
      .addCase(createContainerAsync.fulfilled, (state, action) => {
        // TODO: kas siia sisse tuleb midagi?
      })
      .addCase(fetchFileRevisionsAsync.fulfilled, (state, action) => {
        const { result, parentFileEntityId } = action.payload;
        const fileEntitiesIds = result.map((fileEntity) => fileEntity.id);
        const filesToDelete = Object.values(state.entities).filter((fileEntity) => {
          if (!fileEntity) return false;
          return fileEntity.parentFileEntityId === parentFileEntityId && !fileEntitiesIds.includes(fileEntity.id);
        });
        filesAdapter.removeMany(
          state,
          // @ts-ignore
          filesToDelete.filter((item) => item).map((fe) => fe.id)
        );
        filesAdapter.upsertMany(state, result);
      })
      .addCase(undoFileRevisionAsync.fulfilled, (state, action) => {
        const dto = action.payload;
        if (dto.result) {
          filesAdapter.upsertOne(state, dto.result);
          if (dto.type === RevisionUndoType.UNDO) {
            filesAdapter.removeOne(state, dto.parentFileId);
          }
          if (dto.type === RevisionUndoType.UNDO_AND_PRESERVE) {
            const oldLatestRevision = state.entities[dto.parentFileId];
            if (oldLatestRevision) oldLatestRevision.revision = 1;
          }
          const filesToDelete = Object.values(state.entities).filter((fileEntity) => {
            if (!fileEntity) return false;
            return fileEntity.parentFileEntityId === dto.parentFileId;
          });
          filesAdapter.removeMany(
            state,
            // @ts-ignore
            filesToDelete.filter((item) => item).map((fe) => fe.id)
          );
        }
      })
      .addCase(toggleFavouriteFileAsync.pending, (state) => {})
      .addCase(toggleFavouriteFileAsync.fulfilled, (state, action) => {
        const favourite = action.payload;
        const existingFileEntity = state.entities[favourite.resourceId];
        if (existingFileEntity) {
          existingFileEntity.favourite = !favourite.deleted;
        }
      })
      .addCase(fetchAllFavouriteFoldersAsync.pending, (state) => {})
      .addCase(fetchAllFavouriteFoldersAsync.fulfilled, (state, action) => {
        const folderEntities = action.payload;
        filesAdapter.upsertMany(state, folderEntities);
      })
      .addCase(saveFileEntityTagAsync.fulfilled, (state, action) => {
        const fileEntityTag = action.payload;
        const existingFileEntity = state.entities[fileEntityTag.fileEntityId];
        if (existingFileEntity) {
          existingFileEntity.tags = [...existingFileEntity.tags, fileEntityTag];
        }
      })
      .addCase(deleteFileEntityTagAsync.fulfilled, (state, action) => {
        const fileEntityTag = action.payload;
        const existingFileEntity = state.entities[fileEntityTag.fileEntityId];
        if (existingFileEntity) {
          existingFileEntity.tags = existingFileEntity.tags.filter((tag) => tag.id !== fileEntityTag.id);
        }
      })
      .addCase(replaceFileRevisionAsync.fulfilled, (state, action) => {
        const latestRevisionOfReplacementFile = action.payload.latestRevisionOfReplacementFile;
        const newRevision = action.payload.newRevision;
        if (latestRevisionOfReplacementFile) filesAdapter.upsertOne(state, latestRevisionOfReplacementFile);
        if (newRevision) filesAdapter.upsertOne(state, newRevision);
      })
      .addCase(toggleContainerQueuedSigningAsync.fulfilled, (state, action) => {
        filesAdapter.upsertOne(state, action.payload);
        state.status = BhStateStatusType.SUCCESS;
      })
      .addCase(toggleContainerQueuedSigningAsync.rejected, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
      })
      .addCase(createFolderHierarchyAsync.fulfilled, (state, action) => {
        filesAdapter.upsertMany(
          state,
          action.payload.map((f) => f.folderFileEntity)
        );
      });
  }
});

export const {
  fileAdded,
  filesAdded,
  fileModified,
  filesModified,
  filesRemoved,
  fileRemoved,
  filesUpdatedAndAdded,
  onlyThisFileEntitySelected,
  fileEntitySelected,
  fileTossAnimationCleared,
  setFileEntityUrl,
  currentDirIdSet,
  setFilesFilter,
  fileNameFilterReset,
  tagToggledInFilesFilter,
  tagFilterCleared,
  allFileEntitiesUnSelected,
  allFilteredFilesSelectedToggledInDirectory,
  allFilesSelectedToggledInSearch,
  directoryInfoBarTabSet,
  extensionToggledInFilesFilter,
  extensionFilterCleared,
  filesSortSet,
  setDirectoryModalsOpen,
  setRenameModalFileEntity,
  resetFileDirectoryModalState,
  setDeleteConfirmationModalFileEntityIds,
  setSelectedFilesAsDeleteConfirmationModalFileEntityIds,
  setUndoRevisionModalFileEntity,
  authoritiesModalFileEntitySet,
  setFileEntityParentIdsAfterMove,
  filesSentToConfirmation,
  fileDraggedToDirectorySet
} = filesSlice.actions;

export const { selectAll: selectAllFiles, selectById: selectFileById, selectIds: selectFileIds } = filesAdapter.getSelectors((state: RootState) => state.files);

export const selectFilesStatus = (state: RootState) => state.files.status;
export const selectFilesSort = (state: RootState) => state.files.sort;
export const selectFilesSortDateField = (state: RootState) => state.files.sort.dateField;
export const selectCurrentDirId = (state: RootState) => state.files.currentDirId;
export const selectCurrentDirFilter = (state: RootState) => state.files.filter;
export const selectInfoBarTab = (state: RootState) => state.files.infoBarTab;
export const selectNewFolderModalOpen = (state: RootState) => state.files.directoryModals.open.newFolderModal;
export const selectRenameModalOpen = (state: RootState) => state.files.directoryModals.open.renameModal;
export const selectRenameModalFileEntity = (state: RootState) => state.files.directoryModals.renameModalFileEntity;
export const selectDeleteConfirmationModalOpen = (state: RootState) => state.files.directoryModals.open.deleteConfirmationModal;
export const selectCopyModalOpen = (state: RootState) => state.files.directoryModals.open.copyModal;
export const selectMoveModalOpen = (state: RootState) => state.files.directoryModals.open.moveModal;
export const selectUndoRevisionModalOpen = (state: RootState) => state.files.directoryModals.open.undoRevisionModal;
export const selectUndoRevisionModalFileEntity = (state: RootState) => state.files.directoryModals.undoRevisionModalFileEntity;
export const selectFileHistoryModalOpen = (state: RootState) => state.files.directoryModals.open.fileHistoryModal;
export const selectAuthoritiesModalOpen = (state: RootState) => state.files.directoryModals.open.authoritiesModal;
export const selectAuthoritiesModalFileEntityId = (state: RootState) => state.files.directoryModals.authoritiesModalFileEntity;
export const selectDeleteConfirmationModalFileEntityIds = (state: RootState) => state.files.directoryModals.deleteConfirmationModalFileEntityIds;
export const selectFileDraggedToDirectory = (state: RootState) => state.files.fileDraggedToDirectory;
export const selectTossFileAnimationTriggered = (state: RootState) => state.files.tossFileAnimationTriggered;

export const selectProjectIdForFilesSlice = (state: RootState, itemId: EntityId) => itemId;
export const selectParentFileEntityId = (state: RootState, itemId: EntityId) => itemId;
export const selectFileIdsAsArg = (state: RootState, fileIds: Array<EntityId>) => fileIds;

export const selectAllFilesForParentFileEntityId = createSelector([selectAllFiles, selectParentFileEntityId], (files, parentFileEntityId) =>
  files.filter((fileEntity) => fileEntity.parentFileEntityId === parentFileEntityId)
);
export const selectAllXktFilesForParentFileEntityId = createSelector([selectAllFiles, selectParentFileEntityId], (files, parentFileEntityId) =>
  files.filter((fileEntity) => fileEntity.parentFileEntityId === parentFileEntityId && fileEntity.convertedToXkt)
);
export const selectAllImagesByParentId = createSelector([selectAllFiles, selectParentFileEntityId, selectFilesSort], (files, parentFileEntityId, sort) =>
  files.filter((fileEntity) => fileEntity.parentFileEntityId === parentFileEntityId && isImage(fileEntity)).sort((a, b) => naturalSortFilesByField(a, b, sort.property, sort.reversed))
);

export const selectAllRevisionFileEntityIdsForFile = createSelector([selectAllFilesForParentFileEntityId], (files) =>
  files
    .filter((f) => !f.isActive)
    .sort((a, b) => (a.revision < b.revision ? 1 : -1))
    .map((f) => f.id)
);
export const selectAllFilesForCurrentDirectory = createSelector([selectAllFiles, selectCurrentDirId], (files, directoryId) =>
  files.filter((fileEntity) => fileEntity.parentFileEntityId === directoryId)
);

export const selectNumberOfChildFilesSelectedForDirectory = createSelector(
  [selectAllFiles, selectParentFileEntityId],
  (files, parentId) => files.filter((fileEntity) => fileEntity.parentFileEntityId === parentId && fileEntity.selected).length
);

export const selectAllFilesSelected = createSelector([selectAllFiles, selectFilesSort], (files, sort) =>
  files
    .filter((fileEntity) => fileEntity.selected)
    .sort((a, b) => naturalSortFilesByField(a, b, sort.property, sort.reversed))
    .sort((a, b) => (a.type === FileEntityType.DIR && b.type === FileEntityType.DIR ? 0 : a.type === FileEntityType.DIR ? -1 : 1))
);

export const selectAreAllFilesSelectedFromCurrentDirectory = createSelector([selectAllFiles, selectCurrentDirId], (files, currentDirId) =>
  files.filter((fileEntity) => fileEntity.selected).every((fileEntity) => fileEntity.parentFileEntityId === currentDirId)
);

export const selectAnyFilesSelected = createSelector(selectAllFiles, (files) => files.some((fileEntity) => fileEntity.selected));
export const selectManyFilesSelected = createSelector(selectAllFiles, (files) => files.filter((fileEntity) => fileEntity.selected).length > 1);
export const selectOneFileSelected = createSelector(selectAllFiles, (files) => files.filter((fileEntity) => fileEntity.selected).length === 1);

export const selectDistinctTagsForCurrentDirFiles = createSelector(selectAllFilesForCurrentDirectory, (files) =>
  files.flatMap((fileEntity) => fileEntity.tags || []).filter((tag, index, self) => index === self.findIndex((t) => t.text === tag.text))
);

export const selectFilteredAndSortedFileEntityIdsForDirectory = createSelector(
  [selectAllFiles, selectParentFileEntityId, selectCurrentDirFilter, selectFilesSort],
  (files, parentFileEntityId, filter, sort) => {
    const filtered = files.filter((fileEntity) => {
      if (fileEntity.parentFileEntityId === parentFileEntityId) {
        const nameFilter = filter.name && filter.name.length > 0 ? fileEntity.name.toLowerCase().includes(filter.name.toLowerCase()) : true;
        const tagsFilter = filter.tags && filter.tags.length > 0 ? fileEntity.tags && fileEntity.tags.some((tag) => filter.tags.flatMap((tag) => tag.text).includes(tag.text)) : true;
        const extensionsFiltersSelected = filter.extensions && filter.extensions.filter((e) => e.selected);
        const extensionsFilter =
          extensionsFiltersSelected.length > 0
            ? extensionsFiltersSelected.filter((e) => (fileEntity.type === FileEntityType.FILE || fileEntity.type === FileEntityType.CONTAINER) && e.name === fileEntity.name.split(".").pop()).length >
              0
            : true;
        return nameFilter && tagsFilter && extensionsFilter;
      }
      return false;
    });
    // separate files and directories, because Firefox behaves different when sorting:
    // https://stackoverflow.com/questions/55039157/array-sort-behaves-differently-in-firefox-and-chrome-edge
    // if sort returns 1 for files then the order is reversed for some reason
    const filesFiltered = filtered.filter((fileEntity) => fileEntity.type !== FileEntityType.DIR);
    const fileIdsFilteredAndSorted = filesFiltered.sort((a, b) =>
      sort.property === "signaturesCount" ? sortFilesBySignatures(a, b, sort.reversed) : naturalSortFilesByField(a, b, sort.property, sort.reversed)
    );
    const fileIds = fileIdsFilteredAndSorted.map((fileEntity) => fileEntity.id);
    const dirIds = filtered
      .filter((fileEntity) => fileEntity.type === FileEntityType.DIR)
      .sort((a, b) => naturalSortFilesByField(a, b, sort.property, sort.reversed))
      .map((fileEntity) => fileEntity.id);
    return [...dirIds, ...fileIds];
  }
);

export const selectFavouriteFolderIdsInBranch = createSelector(
  [selectAllFiles, (state) => (state.sidebar.current === SidebarItemType.DRAWINGS ? FileEntityBranch.ROOT_DIR : FileEntityBranch.ROOT_DOCUMENT_DIR), selectProjectIdForFilesSlice],
  (folders, branch, projectId) =>
    folders
      .filter((fileEntity) => fileEntity.projectId === projectId && fileEntity.branch === branch && fileEntity.favourite)
      .sort((a, b) => naturalSortFilesByField(a, b, "name"))
      .map((fileEntity) => fileEntity.id)
);

export const selectFilesSelectedInCurrentDirectory = createSelector(selectAllFilesForCurrentDirectory, (files) => files.filter((fileEntity) => fileEntity.selected));
export const selectAllFilteredFilesSelectedInCurrentDirectory = createSelector(
  [selectFilesSelectedInCurrentDirectory, selectFilteredAndSortedFileEntityIdsForDirectory, selectParentFileEntityId],
  (selectedFiles, filteredFiles) => filteredFiles.length > 0 && selectedFiles.length >= filteredFiles.length
);

export const selectFilesByIds = createSelector([selectAllFiles, selectFileIdsAsArg], (files, ids) => {
  return files.filter((file) => file && ids.includes(file.id)).sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));
});
export const selectImageFilesFromIds = createSelector([selectFilesByIds], (files) => {
  return files.filter((file) => isImage(file));
});

export default filesSlice.reducer;
