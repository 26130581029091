import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  fetchProjectTaskBoardsForTaskMailAsync,
  fetchUserMailSettingsInfoForTaskMailAsync,
  saveUserMailSettingsInfoForTaskMailAsync,
  selectUserMailSettingsActiveProjectId,
  selectUserMailSettingsActiveProjectTaskBoardsSorted,
  selectUserTaskMailRelatedSettings
} from "@/app/store/userPreferenceSlice";
import MailSettingsHighSecurityProjectWarning from "@/views/home/profile/modals/MailSettingsHighSecurityProjectWarning";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareKanban } from "@fortawesome/pro-regular-svg-icons/faSquareKanban";
import BhToggleRow from "@components/toggle/BhToggleRow";
import { classNames } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";
import { IUserEmailSetting, UserEmailSettingsType } from "@/model/profile/IUserEmailSettings";

interface Props {
  userSecurityLevelTooLow: boolean;
}

const TaskMailProjectSettingsContainer: FC<Props> = ({ userSecurityLevelTooLow }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const activeProjectId = useAppSelector(selectUserMailSettingsActiveProjectId);
  const activeProjectTaskBoards = useAppSelector(selectUserMailSettingsActiveProjectTaskBoardsSorted);
  const taskMailSettingsInfo = useAppSelector(selectUserTaskMailRelatedSettings);
  const [taskBoards, setTaskBoards] = useState(activeProjectTaskBoards);

  useEffect(() => {
    if (activeProjectId) {
      dispatch(fetchProjectTaskBoardsForTaskMailAsync(activeProjectId));
    }
  }, [activeProjectId]);

  useEffect(() => {
    if (activeProjectTaskBoards && activeProjectTaskBoards.length > 0) {
      const taskBoardIds = activeProjectTaskBoards.map((tb) => tb.id);
      dispatch(
        fetchUserMailSettingsInfoForTaskMailAsync({
          type: UserEmailSettingsType.TASK_EMAIL_TASK_BOARD_DISABLED,
          resource: "TASK_BOARD",
          resourceIds: taskBoardIds
        })
      );
    }
  }, [activeProjectTaskBoards]);

  useEffect(() => {
    if (taskMailSettingsInfo) {
      const taskBoardsWithSelectedValue = activeProjectTaskBoards.map((tb) => {
        return { ...tb, ...{ selected: !taskMailSettingsInfo.some((info) => info.resourceId === tb.id) } };
      });
      setTaskBoards(taskBoardsWithSelectedValue);
    }
  }, [taskMailSettingsInfo]);

  const toggleTaskBoard = (taskBoard: any) => {
    if (taskMailSettingsInfo) {
      const existingSetting = taskMailSettingsInfo.find((info) => info.resource === "TASK_BOARD" && info.resourceId === taskBoard.id);
      let objectToSave = existingSetting
        ? { ...existingSetting, ...{ deleted: !taskBoard.selected } }
        : ({ resource: "TASK_BOARD", resourceId: taskBoard.id, type: UserEmailSettingsType.TASK_EMAIL_TASK_BOARD_DISABLED } as IUserEmailSetting);
      dispatch(saveUserMailSettingsInfoForTaskMailAsync(objectToSave));
    }
  };

  if (!activeProjectId) return null;

  return (
    <div className="bh-bg-pigeon-20 flex w-2/3 flex-col self-stretch overflow-auto p-4">
      {userSecurityLevelTooLow && <MailSettingsHighSecurityProjectWarning projectId={activeProjectId} />}
      <div className="bh-bg-white rounded p-6">
        <div className="mb-3 flex flex-row items-center">
          <FontAwesomeIcon icon={faSquareKanban} />
          <h3 className="bh-text-deep-ocean my-0 ml-2">{t("GLOBAL.TASKBOARDS")}</h3>
        </div>
        {taskBoards &&
          taskBoards.length > 0 &&
          taskBoards.map((tb, index) => {
            return (
              <BhToggleRow
                key={tb.id}
                toggleObject={tb}
                toggleProperty={"selected"}
                onToggle={() => toggleTaskBoard(tb)}
                label={<span className="bh-text-deep-ocean text-14px leading-5">{tb.name}</span>}
                classes={classNames("pt-5 bh-border-pigeon-20", index !== 0 && "border-t", index !== taskBoards.length - 1 && "pb-5")}
                disabled={userSecurityLevelTooLow}
              />
            );
          })}
        {taskBoards && taskBoards.length === 0 && (
          <div className="w-full py-5 text-center">
            <h3>{t("MAIL.SETTINGS.MODAL.TASKS.NO_TASKBOARDS")}</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskMailProjectSettingsContainer;
