import React, { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import ProjectLogFilter from "@/views/home/project/detail/projectLog/ProjectLogFilter";
import { fetchProjectLogAsync, resetProjectLog, selectCurrentProjectLogFilter, selectCurrentProjectLogLoading, setProjectLogFilter } from "@/app/store/project/projectLogSlice";
import BhPagination from "@components/pagination/BhPagination";
import { serialize, urlParamsToObject } from "@/utilities/jsUtilities";
import { IProjectLogFilter } from "@/model/logs/IProjectLogFilter";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import ProjectLogTableContainer from "@/views/home/project/detail/projectLog/ProjectLogTableContainer";
import ProjectLogModals from "@/features/projectLogTable/ProjectLogModals";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useTranslation } from "react-i18next";
import { EntityId } from "@reduxjs/toolkit";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";

interface Props {}

const ProjectLogContainer: FC<Props> = () => {
  useDocumentTitle("PROJECT.LOG");
  const filter = useAppSelector(selectCurrentProjectLogFilter);
  const projectId = useAppSelector(selectCurrentProjectId);
  const loading = useAppSelector(selectCurrentProjectLogLoading);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (location.search) {
      let filterFromUrlParams = urlParamsToObject(location.search) as unknown as IProjectLogFilter;
      let sortOrderAsc = filterFromUrlParams.sortOrderAsc === "true";
      let folderIds = filterFromUrlParams.folderIds && filterFromUrlParams.folderIds.toString();
      filterFromUrlParams.sortOrderAsc = sortOrderAsc;
      filterFromUrlParams.folderIds = folderIds?.split(",").map((dirId) => parseInt(dirId) as EntityId);
      dispatch(setProjectLogFilter(filterFromUrlParams));
    } else {
      dispatch(resetProjectLog());
    }
    dispatch(fetchProjectLogAsync(projectId));
  }, [location]);

  useEffect(() => {
    dispatch(setSidebarCollapsed(false));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.PROJECT_LOG));
    return function cleanup() {
      dispatch(resetProjectLog());
    };
  }, []);

  const changePageClicked = (page: number) => {
    let newFilter = { ...filter, page: page };
    delete newFilter.filterOptions;
    const newLocation = {
      pathname: location.pathname,
      search: serialize(newFilter)
    };
    navigate(newLocation);
  };

  return (
    <div className="flex h-full w-full flex-col overflow-hidden px-6">
      <div className="mt-4 flex flex-col">
        <DetailViewHeading>{t("PROJECT.LOG")}</DetailViewHeading>
        <ProjectLogFilter />
      </div>
      <div className="flex flex-1 overflow-auto pb-8">
        <ProjectLogTableContainer />
      </div>
      <div className="flex pb-4">
        <BhPagination total={filter.total} limit={filter.limit} page={parseInt(filter.page)} changePageClicked={changePageClicked} disabled={loading} />
      </div>

      {/*-----------MODALS-----------*/}
      <ProjectLogModals />
    </div>
  );
};

export default ProjectLogContainer;
