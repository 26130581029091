import React, { FC } from "react";
import color from "tinycolor2";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/pro-solid-svg-icons/faQuestion";

interface Props {
  firstName?: string;
  lastName?: string;
  userExists?: boolean;
  size?: string;
}

function hash(str: string) {
  let hash = 5381,
    i = str.length;
  while (i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }
  return hash >>> 0;
}

const BhAvatarFallback: FC<Props> = ({ firstName, lastName, userExists, size }) => {
  const viewSize = size || "32";
  const viewBox = "0 0 " + parseInt(viewSize) * 2 + " " + parseInt(viewSize) * 2;

  if (!userExists) {
    return (
      <div className="bh-bg-pigeon-20 bh-text-pigeon flex w-full items-center justify-center rounded-full" style={{ height: viewSize, width: viewSize, minWidth: viewSize }}>
        <FontAwesomeIcon icon={faQuestion} />
      </div>
    );
  }

  const firstNameFirstLetterString = firstName && firstName.length > 0 ? firstName[0] : "?";
  const lastNameFirstLetterString = lastName && lastName.length > 0 ? lastName[0] : "?";

  const fullName = firstName + "" + lastName;
  const fullNameWithoutSpecialChars = fullName.replace(/[^A-Z0-9]+/gi, "");
  const hashed = hash(fullNameWithoutSpecialChars);
  const c = color({ h: hashed % 360, s: 0.6, l: 0.8 }).complement();
  const c2 = c.clone().toHexString();
  const c1 = c.clone().desaturate(70).toHexString();
  const c3 = color({ h: hashed % 360, s: 0.2, l: 0.4 })
    .complement()
    .toHexString();
  const c3Value = c3 || "gray";

  return (
    <div className="relative overflow-hidden rounded-full">
      <svg role="img" aria-label={fullNameWithoutSpecialChars} width={viewSize} height={viewSize} viewBox={viewBox}>
        <defs>
          <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id={fullNameWithoutSpecialChars}>
            <stop stopColor={c1} offset="0%" />
            <stop stopColor={c2} offset="100%" />
          </linearGradient>
        </defs>
        <g stroke="none" strokeWidth="1" fill="none">
          <rect fill={`url(#${fullNameWithoutSpecialChars})`} x="0" y="0" width="100%" height="100%" />
        </g>
      </svg>
      <div className={classNames(size && parseInt(size) < 32 && "text-sm", "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform")} style={{ color: c3Value }}>
        {(firstNameFirstLetterString + "" + lastNameFirstLetterString).toUpperCase()}
      </div>
    </div>
  );
};

export default BhAvatarFallback;
