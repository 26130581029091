import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveChecklistBaseVersionAsync, selectCompanyChecklistBaseById } from "@/app/store/checklist/checklistsSlice";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import ChecklistBaseInfoFieldRow from "@components/checklists/checklistBase/body/ChecklistBaseInfoFieldRow";
import { ChecklistBaseVersionStatus } from "@/model/checklist/IChecklistBaseVersion";

interface Props {
  companyId: EntityId;
  checklistBaseId: EntityId;
  checklistBaseVersionId: EntityId;
}

const ChecklistBaseInfoFields: FC<Props> = ({ companyId, checklistBaseId, checklistBaseVersionId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const checklistBase = useAppSelector((state) => selectCompanyChecklistBaseById(state, checklistBaseId));
  const checklistBaseVersion = checklistBase && checklistBase.checklistBaseVersions && checklistBase.checklistBaseVersions.find((cbv) => cbv.id === checklistBaseVersionId);
  const isFinalVersion = checklistBaseVersion && checklistBaseVersion.status === ChecklistBaseVersionStatus.FINAL;

  if (!checklistBase || !checklistBaseVersion || !checklistBase.checklistBaseVersions) {
    return null;
  }

  const saveInfoFieldRowValue = (changedObject: any) => {
    const customLabelToSave = { ...checklistBaseVersion.customLabel, ...changedObject };
    const baseVersionToSave = { ...checklistBaseVersion, ...{ customLabel: customLabelToSave } };
    dispatch(saveChecklistBaseVersionAsync({ companyId: companyId, checklistBaseVersion: baseVersionToSave }));
  };

  return (
    <div className="bh-border-pigeon-20 w-full border-b py-8 px-36">
      <h2 className="bh-text-deep-ocean-60 m-0">{t("CHECKLIST.BASE.INFO_FIELDS")}</h2>
      <div className="mt-6 w-full">
        <div className="flex w-full flex-row items-center justify-between pl-4 pb-2 font-bold">
          <div className="flex w-full flex-row items-center">
            <div className="w-2/6">{t("CHECKLIST_BASE.INFO.HEADER.NAME")}</div>
            <div className="w-full">{t("CHECKLIST_BASE.INFO.HEADER.CONTENT")}</div>
          </div>
          <div className="w-36 pl-4">{t("CHECKLIST_BASE.INFO.HEADER.APPLY")}</div>
        </div>
        <div className="bh-border-pigeon-60 flex h-full w-full flex-col rounded border">
          <ChecklistBaseInfoFieldRow
            rowObject={checklistBaseVersion.customLabel.siteNameLabel}
            property={"siteNameLabel"}
            placeholder={t("CHECKLIST_BASE.INFO.CONTENT.SITE_NAME")}
            saveRowCallback={saveInfoFieldRowValue}
            bottomBorder={true}
            disabled={isFinalVersion}
          />
          <ChecklistBaseInfoFieldRow
            rowObject={checklistBaseVersion.customLabel.timeLabel}
            property={"timeLabel"}
            placeholder={t("CHECKLIST_BASE.INFO.CONTENT.TIME")}
            saveRowCallback={saveInfoFieldRowValue}
            bottomBorder={true}
            disabled={isFinalVersion}
          />
          <ChecklistBaseInfoFieldRow
            rowObject={checklistBaseVersion.customLabel.createdByLabel}
            property={"createdByLabel"}
            placeholder={t("CHECKLIST_BASE.INFO.CONTENT.CREATED_BY")}
            saveRowCallback={saveInfoFieldRowValue}
            bottomBorder={true}
            disabled={isFinalVersion}
            checkboxDisabled={true}
          />
          <ChecklistBaseInfoFieldRow
            rowObject={checklistBaseVersion.customLabel.participantsLabel}
            property={"participantsLabel"}
            placeholder={t("CHECKLIST_BASE.INFO.CONTENT.PARTICIPANTS")}
            saveRowCallback={saveInfoFieldRowValue}
            disabled={isFinalVersion}
          />
        </div>
      </div>
    </div>
  );
};

export default ChecklistBaseInfoFields;
