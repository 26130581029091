import React, { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentSidebarMenuItem, setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import BhTabs from "@components/tabs/BhTabs";
import ProjectInfoContainer from "@/views/home/project/detail/projectSettings/ProjectInfoContainer";
import ProjectAdministratorsContainer from "@/views/home/project/detail/projectSettings/ProjectAdministratorsContainer";
import { useTranslation } from "react-i18next";

const ProjectSettings: FC = () => {
  const sidebarMenuItem = useAppSelector(selectCurrentSidebarMenuItem);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setSidebarCollapsed(false));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.PROJECT_SETTINGS));
  }, [location, sidebarMenuItem]);

  const tabs = [
    { id: 0, header: t("GLOBAL.PROJECT_INFO"), content: <ProjectInfoContainer /> },
    {
      id: 1,
      header: t("GLOBAL.PROJECT_ADMIN_USERS"),
      content: <ProjectAdministratorsContainer />
    }
  ];

  return <BhTabs tabs={tabs} />;
};

export default ProjectSettings;
