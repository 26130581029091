import React, { FC } from "react";
import { ITask } from "@/model/taskBoard/ITask";
import TaskStatusInfoField from "@components/task/modal/TaskStatusInfoField";
import TaskPriorityInfoField from "@components/task/modal/TaskPriorityInfoField";
import TaskAssigneeInfoField from "@components/task/modal/TaskAssigneeInfoField";
import TaskDeadlineInfoField from "@components/task/modal/TaskDeadlineInfoField";
import TaskTagsInfoField from "@components/task/modal/TaskTagsInfoField";
import TaskParticipantsInfoField from "@components/task/modal/TaskParticipantsInfoField";
import TaskHistory from "@components/task/modal/TaskHistory";
import TaskTaskboardInfoField from "@components/task/modal/TaskTaskboardInfoField";

interface Props {
  task: ITask;
  saveTaskCallback: Function;
}

const TaskModalBodyRight: FC<Props> = ({ task, saveTaskCallback }) => {
  return (
    <div className="bh-bg-smoke-50 bh-border-pigeon-20 flex h-full min-w-[385px] max-w-[385px] flex-col border-l md:w-full md:max-w-full">
      <TaskTaskboardInfoField task={task} onSelect={saveTaskCallback} />
      <TaskStatusInfoField task={task} onSelect={saveTaskCallback} />
      <TaskPriorityInfoField task={task} onSelect={saveTaskCallback} />
      <TaskAssigneeInfoField task={task} onSelect={saveTaskCallback} />
      <TaskDeadlineInfoField task={task} onSelect={saveTaskCallback} />
      <TaskTagsInfoField task={task} />
      <TaskParticipantsInfoField task={task} onSelect={saveTaskCallback} />
      <TaskHistory task={task} />
    </div>
  );
};

export default TaskModalBodyRight;
