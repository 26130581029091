import React, { FC, useRef, useState } from "react";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { Trans } from "react-i18next";
import { faUserGroup } from "@fortawesome/pro-regular-svg-icons/faUserGroup";
import { fetchFileAccessList, saveFileAccess } from "@/api/fileAPI";
import { useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { IWorkGroupUserAuthorities } from "@/model/files/IFileAccess";
import { selectAuthoritiesModalFileEntityId } from "@/app/store/filesSlice";
import { useModal } from "react-modal-hook";
import AddPartyConfirmationModal from "@/views/home/project/detail/directory/directoryModals/authoritiesModal/AddPartyConfirmationModal";
import { IWorkGroup } from "@/model/IWorkGroup";
import BhFixedDropdownParent from "@components/dropdown/BhFixedDropdownParent";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";

interface Props {
  parties: Array<IWorkGroupUserAuthorities>;
  setDirectoryAccess: Function;
}

const AddPartyAccessDropdown: FC<Props> = ({ parties, setDirectoryAccess }) => {
  const [partySearchString, setPartySearchString] = useState<string>("");
  const [partyId, setPartyId] = useState<EntityId | undefined>(undefined);
  const fileId = useAppSelector(selectAuthoritiesModalFileEntityId);
  const projectId = useAppSelector(selectCurrentProjectId);
  const dropdownButtonRef = useRef(null);

  const [showAddPartyConfirmationModal, hideAddPartyConfirmationModal] = useModal(
    () => (
      <AddPartyConfirmationModal
        setIsOpen={hideAddPartyConfirmationModal}
        handleDelete={() => {
          if (partyId && fileId) {
            saveFileAccess(fileId, partyId).then(() => fetchFileAccessList(projectId, fileId).then((res) => setDirectoryAccess(res)));
          }
          hideAddPartyConfirmationModal();
        }}
      />
    ),
    [partyId]
  );

  if (!fileId) return null;

  const noPartiesToAdd = parties?.length === 0;

  const handlePartySelect = (party: IWorkGroup) => {
    setPartyId(party.id);
    showAddPartyConfirmationModal();
  };

  const filteredPartiesWithoutAuthority = parties
    ?.filter((party: IWorkGroupUserAuthorities) => party?.name?.toLowerCase().includes(partySearchString?.toLowerCase()))
    ?.map((party) => {
      return { ...party, icon: faUserGroup };
    });

  const DropdownButton: FC = () => (
    <BhPrimaryButton icon={faPlus} buttonProps={{ classes: "whitespace-nowrap", disabled: noPartiesToAdd, buttonRef: dropdownButtonRef }}>
      <Trans>MODAL.ADD_RIGHTS</Trans>
    </BhPrimaryButton>
  );

  return (
    <BhDropdown
      button={<DropdownButton />}
      disabled={noPartiesToAdd}
      position={BhDropdownPositionEnum.BOTTOM_LEFT}
      fixedPosition={true}
      menu={
        <BhFixedDropdownParent dropdownPosition={BhDropdownPositionEnum.BOTTOM_LEFT} parentRef={dropdownButtonRef}>
          <BhDropdownMenu
            type={BhDropdownTypeEnum.STRING}
            values={filteredPartiesWithoutAuthority}
            onSelect={handlePartySelect}
            textProperty="name"
            search={true}
            searchInputSaveCallback={(value: { query: string }) => setPartySearchString(value.query)}
            searchInputProperty="query"
          />
        </BhFixedDropdownParent>
      }
    />
  );
};

export default AddPartyAccessDropdown;
