import React, { FC, useMemo } from "react";
import { classNames } from "@/utilities/jsUtilities";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { Link, useNavigate } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { formatDateTime } from "@/utilities/dateUtility";
import { getUserFullName } from "@/model/IUser";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhIconButton from "@components/buttons/BhIconButton";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons/faEllipsisVertical";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhTableRow from "@components/table/BhTableRow";
import { useTranslation } from "react-i18next";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectAllCompanyActiveProjectIds, selectCurrentCompanyProjectNameById } from "@/app/store/companiesSlice";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons/faPlusCircle";
import { faPen } from "@fortawesome/pro-regular-svg-icons/faPen";
import { faGear } from "@fortawesome/pro-regular-svg-icons/faGear";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { FormBaseVersionStatus, IFormBaseVersion } from "@/model/form/IFormBaseVersion";
import BhTableRowVersion from "@components/table/BhTableRowVersion";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons/faCirclePlus";
import { CopyNotAllowedFormBaseTypes, IFormBase } from "@/model/form/IFormBase";
import {
  addFormBasesToProjectsAsync,
  copyFormBaseToCompanyAsync,
  makeSelectActivatedBasesInCompanyActiveProjects,
  removeFormBaseAsync,
  removeFormBasesFromProjectsAsync,
  removeFormBaseVersionAsync,
  saveCompanyFormBaseAsync,
  saveNewFormBaseVersionAsync,
  selectIsFormBaseActivatedInProjectByBaseAndProjectId
} from "@/app/store/form/formBasesSlice";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import ActivateFormBaseInProjectsModal from "@components/form/settings/companySettings/ActivateFormBaseInProjectsModal";
import { IFormBasesToProjectsDTO } from "@/model/form/IFormBasesToProjectsDTO";
import BhFormBaseIcon from "@components/form/settings/BhFormBaseIcon";
import BhLink from "@components/buttons/BhLink";
import FormBaseHeaderPdfButton from "@components/form/base/header/FormBaseHeaderPdfButton";
import BhTooltip from "@components/BhTooltip";

interface Props {
  companyId: EntityId;
  formBase: IFormBase;
  formBaseVersion: IFormBaseVersion;
  isLastVersion: boolean;
  toggleShowAllVersions: Function;
  allVersionsVisible: boolean;
  selectRow: Function;
  isRowSelected: boolean;
  isProjectFormBase: boolean;
}

const CompanySettingsFormBaseListRow: FC<Props> = ({ companyId, formBase, formBaseVersion, isLastVersion, toggleShowAllVersions, allVersionsVisible, selectRow, isRowSelected, isProjectFormBase }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectActivatedBasesInCompanyProjects = useMemo(makeSelectActivatedBasesInCompanyActiveProjects, []);
  const activatedBasesInCompanyProjects = useAppSelector((state) => selectActivatedBasesInCompanyProjects(state, companyId));
  const baseActivatedInProjectsCount = activatedBasesInCompanyProjects.filter((base) => base.formBaseId === formBase.id).length;
  const isDraft = formBaseVersion.status === FormBaseVersionStatus.DRAFT;
  const anyBaseInDraft = formBase.formBaseVersions.some((fb) => fb.status === FormBaseVersionStatus.DRAFT);
  const companyActiveProjectsCount = useAppSelector((state) => selectAllCompanyActiveProjectIds(state, companyId)).length;
  const formBaseCreatedInProjectName = useAppSelector((state) => selectCurrentCompanyProjectNameById(state, formBase.createdInProjectId));
  const isFormBaseActivatedInProject = useAppSelector((state) => selectIsFormBaseActivatedInProjectByBaseAndProjectId(state, formBase.id, formBase.createdInProjectId));
  const isBauhubFormBase = formBase.bauhubFormBase;
  const isCustomFormBase = formBase.createdBy === "custom" && !isBauhubFormBase;
  const formDisplayName = isBauhubFormBase || isCustomFormBase ? t(`FORMS.${formBase.type}`) : formBase.type;
  const isCopyAllowed = !CopyNotAllowedFormBaseTypes.some((type) => type === formBase.type);

  const [showDeleteFormBaseConfirmationModal, hideDeleteFormBaseConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteFormBaseConfirmationModal}
      header={<h2>{t("CHECKLIST.DELETE.MODAL.HEADER")}</h2>}
      body={<div>{t("CHECKLIST.DELETE.MODAL.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={removeFormBase}
    />
  ));

  const [showDeleteFormBaseVersionConfirmationModal, hideDeleteFormBaseVersionConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteFormBaseVersionConfirmationModal}
      header={<h2>{t("CHECKLIST.VERSION.DELETE.MODAL.HEADER")}</h2>}
      body={<div>{t("FORM.VERSION.DELETE.MODAL.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={() => {
        deleteFormBaseVersion();
        hideDeleteFormBaseVersionConfirmationModal();
      }}
    />
  ));

  const [showFormBaseCopyConfirmationModal, hideFormBaseCopyConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideFormBaseCopyConfirmationModal}
      header={<h2>{t("CHECKLIST.BASE.COPY.MODAL.HEADER")}</h2>}
      body={<div>{t("CHECKLIST.BASE.COPY.MODAL.BODY")}</div>}
      confirmationButtonText={t("CHECKLIST.BASE.COPY.MODAL.CONFIRM")}
      handleDelete={() => {
        copyFormBaseToCompany();
        hideFormBaseCopyConfirmationModal();
      }}
    />
  ));

  const [showActivateInProjectsModal, hideActivateInProjectsModal] = useModal(() => (
    <ActivateFormBaseInProjectsModal
      setIsShown={hideActivateInProjectsModal}
      formBase={formBase}
      companyId={companyId}
      addFormBaseToProjects={addFormBaseToProjects}
      removeFormBaseFromProjects={removeFormBaseFromProjects}
    />
  ));

  if (!formBase || !formBaseVersion) return null;

  const addNewVersion = () => {
    dispatch(saveNewFormBaseVersionAsync({ companyId: companyId, formBaseId: formBase.id })).then((action) => {
      const newFormBaseVersion = action.payload as IFormBaseVersion;
      editVersion(newFormBaseVersion.id);
    });
  };

  const editVersion = (formBaseVersionId: EntityId) => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${companyId}/form/base/${formBase.id}/version/${formBaseVersionId}`);
  };

  const removeFormBase = () => {
    dispatch(removeFormBaseAsync({ companyId: companyId, formBaseId: formBase.id }));
    hideDeleteFormBaseConfirmationModal();
  };

  const deleteFormBaseVersion = () => {
    dispatch(removeFormBaseVersionAsync({ companyId: companyId, formBaseId: formBase.id, formBaseVersionId: formBaseVersion.id }));
  };

  const toggleBaseInProject = (projectId: EntityId) => {
    if (isFormBaseActivatedInProject) {
      dispatch(removeFormBasesFromProjectsAsync({ companyId: companyId, basesToProjectsDTO: { formBaseIds: [formBase.id], projectIds: [projectId] } }));
    } else {
      dispatch(addFormBasesToProjectsAsync({ companyId: companyId, basesToProjectsDTO: { formBaseIds: [formBase.id], projectIds: [projectId] } }));
    }
  };

  const addFormBaseToProjects = (projectIds: Array<EntityId>) => {
    const dto = { formBaseIds: [formBase.id], projectIds: projectIds } as IFormBasesToProjectsDTO;
    dispatch(addFormBasesToProjectsAsync({ companyId: companyId, basesToProjectsDTO: dto }));
  };

  const removeFormBaseFromProjects = (projectIds: Array<EntityId>) => {
    const dto = { formBaseIds: [formBase.id], projectIds: projectIds } as IFormBasesToProjectsDTO;
    dispatch(removeFormBasesFromProjectsAsync({ companyId: companyId, basesToProjectsDTO: dto }));
  };

  const copyFormBaseToCompany = () => {
    dispatch(copyFormBaseToCompanyAsync({ companyId: companyId, formBaseId: formBase.id }));
  };

  const toggleAddToNewProjects = () => {
    dispatch(saveCompanyFormBaseAsync({ companyId: companyId, formBase: { ...formBase, addToNewProjects: !formBase.addToNewProjects } }));
  };

  const ellipsisDropdownValues: Array<any> = [
    !isBauhubFormBase &&
      isLastVersion &&
      !anyBaseInDraft && {
        text: t("CHECKLIST.BASE.NEW_VERSION"),
        function: addNewVersion,
        icon: faPlusCircle
      },
    isDraft && {
      text: t("CHECKLIST.TEMPLATE_EDIT"),
      function: () => editVersion(formBaseVersion.id),
      icon: faPen
    },
    isLastVersion &&
      !isDraft &&
      !isProjectFormBase && {
        text: t("CHECKLIST.BASE.MANAGE_IN_PROJECTS"),
        function: showActivateInProjectsModal,
        icon: faGear
      },
    isLastVersion &&
      !isDraft &&
      isCopyAllowed && {
        text: isProjectFormBase ? t("CHECKLIST.BASE.COPY_FROM_PROJECT") : t("CHECKLIST.BASE.COPY_TO_COMPANY"),
        function: showFormBaseCopyConfirmationModal,
        icon: faCopy
      },
    isLastVersion &&
      !isBauhubFormBase && {
        text: t("CHECKLIST.BASE.DELETE"),
        function: showDeleteFormBaseConfirmationModal,
        icon: faTrash
      },
    !isLastVersion &&
      isDraft && {
        text: t("CHECKLIST.BASE.VERSION.DELETE"),
        function: showDeleteFormBaseVersionConfirmationModal,
        icon: faTrash
      }
  ].filter(Boolean);

  const showDropdown = ellipsisDropdownValues && ellipsisDropdownValues.length > 1;

  return (
    <BhTableRow classes={classNames(!isLastVersion && !isDraft && "bh-bg-smoke-50", isDraft && "bh-bg-warning-yellow-10", "border-b bh-border-pigeon-40 group hover:bh-bg-mint-30")}>
      {!isProjectFormBase && (
        <td>
          {isLastVersion && !isCustomFormBase && (
            <BhCheckbox isChecked={isRowSelected} onChange={selectRow} property={"selected"} large={true} disabled={formBaseVersion.status === FormBaseVersionStatus.DRAFT} />
          )}
        </td>
      )}
      <td>
        <div className={classNames("group flex w-full flex-row items-center", !isLastVersion && "pl-7")}>
          {isBauhubFormBase ? (
            <BhFileCardIcon fileEntity={{ id: formBase.id, name: formBase.type, type: FileEntityType.FORM } as IFileEntity} fileCardSize={FileCardSize.LARGE} />
          ) : (
            <BhFormBaseIcon isCustomFormBase={isCustomFormBase} />
          )}
          <div className="ml-2 flex w-full flex-row items-center">
            {!isCustomFormBase ? (
              <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${companyId}/form/base/${formBase.id}/version/${formBaseVersion.id}`}>
                <BhLink>{formDisplayName}</BhLink>
              </Link>
            ) : (
              <FormBaseHeaderPdfButton companyId={companyId} formType={formBase.type}>
                <BhLink>{formDisplayName}</BhLink>
              </FormBaseHeaderPdfButton>
            )}

            {isDraft && <BhTag type={BhTagType.MUSTAND} children={t("CHECKLIST.STATUS.DRAFT")} classes={"ml-3 h-4.5"} />}
          </div>
        </div>
      </td>
      <td>
        {!isBauhubFormBase && !isCustomFormBase && (
          <BhTableRowVersion
            versionNumber={formBaseVersion.versionNumber}
            disabled={!isLastVersion || (isLastVersion && anyBaseInDraft)}
            onClick={toggleShowAllVersions}
            otherVersionsExist={formBaseVersion.versionNumber > 1 && isLastVersion}
            allVersionsVisible={allVersionsVisible}
            isActive={allVersionsVisible && isLastVersion}
            icon={faCirclePlus}
            addNewCallback={addNewVersion}
          />
        )}
      </td>
      <td>
        <div className="flex flex-col">
          <div className="text-14px bh-text-deep-ocean leading-5">
            {isBauhubFormBase && t("FORMBUILDER.FORM.BASE.BAUHUB_TEMPLATE")}
            {isCustomFormBase && t("FORMBUILDER.FORM.BASE.CUSTOM_FORM_BASE")}
            {!isBauhubFormBase && !isCustomFormBase && formatDateTime(formBaseVersion.created)}
          </div>
          {formBaseVersion.createdByUserEntity && !isBauhubFormBase && !isCustomFormBase && (
            <div className="text-12px bh-text-deep-ocean-80">{getUserFullName(formBaseVersion.createdByUserEntity)}</div>
          )}
        </div>
      </td>
      <td>
        {!isProjectFormBase && isLastVersion && <BhToggleSwitch value={formBase.addToNewProjects || isCustomFormBase} onClickAction={toggleAddToNewProjects} disabled={isDraft || isCustomFormBase} />}
        {isProjectFormBase && <div>{formBaseCreatedInProjectName}</div>}
      </td>
      <td>
        {!isProjectFormBase && isLastVersion && !isDraft && (
          <div className="flex flex-row items-center justify-between">
            <BhSecondaryButton
              buttonProps={{
                onClick: showActivateInProjectsModal,
                disabled: isCustomFormBase
              }}
            >
              {isCustomFormBase ? companyActiveProjectsCount : baseActivatedInProjectsCount}/{companyActiveProjectsCount}
            </BhSecondaryButton>
          </div>
        )}
        {isProjectFormBase && isLastVersion && <BhToggleSwitch value={isFormBaseActivatedInProject} onClickAction={() => toggleBaseInProject(formBase.createdInProjectId)} disabled={isDraft} />}
      </td>
      <td>
        {!isCustomFormBase && (
          <>
            {showDropdown ? (
              <BhDropdown
                button={<BhIconButton icon={faEllipsisVertical} />}
                menu={<BhDropdownMenu values={ellipsisDropdownValues} type={BhDropdownTypeEnum.STRING} textProperty="text" widthClass={isProjectFormBase ? "w-64" : ""} />}
                position={BhDropdownPositionEnum.BOTTOM_LEFT}
              />
            ) : (
              <>
                {ellipsisDropdownValues.length !== 0 && (
                  <BhTooltip body={ellipsisDropdownValues[0] ? ellipsisDropdownValues[0].text : ""}>
                    <BhIconButton
                      icon={ellipsisDropdownValues[0] ? ellipsisDropdownValues[0].icon : undefined}
                      buttonProps={{ onClick: ellipsisDropdownValues[0] ? ellipsisDropdownValues[0].function : undefined }}
                    />
                  </BhTooltip>
                )}
              </>
            )}
          </>
        )}
      </td>
    </BhTableRow>
  );
};

export default CompanySettingsFormBaseListRow;
