import React, { FC } from "react";
import TaskPriorityTag from "@components/task/TaskPriorityTag";

interface Props {
  priority: number;
  text: string;
  textClasses?: string;
}

const PriorityTagWithName: FC<Props> = ({ priority, text, textClasses }) => {
  return (
    <div className="flex flex-row items-center">
      {priority !== 3 && <TaskPriorityTag priority={priority} />}
      <span className={textClasses}>{text}</span>
    </div>
  );
};

export default PriorityTagWithName;
