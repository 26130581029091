import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { Link } from "react-router-dom";
import { selectContactById, selectCurrentProjectContactsFilter, setContactsFilter } from "@/app/store/project/projectContactsSlice";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  userId: EntityId;
}

const SearchUserCardContainer: FC<Props> = ({ userId }) => {
  const contact = useAppSelector((state) => selectContactById(state, userId));
  const projectId = useAppSelector(selectCurrentProjectId);
  const filter = useAppSelector(selectCurrentProjectContactsFilter);
  const dispatch = useAppDispatch();

  if (!contact) return null;

  const handleClick = () => {
    dispatch(setContactsFilter({ ...filter, searchQuery: contact.email ? contact.email : contact?.firstName + " " + contact?.lastName }));
  };

  return (
    <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/users/contacts`} onClick={handleClick}>
      <div key={userId} className="hover:bh-bg-mint-50 flex h-8 cursor-pointer items-center justify-between rounded p-1.5 transition duration-300 ease-in-out">
        <BhUserIconWithName user={contact} withoutRing={true} size="sm" />
        <span className="truncate">{contact.email}</span>
      </div>
    </Link>
  );
};

export default SearchUserCardContainer;
