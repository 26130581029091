import React, { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { IChecklistBase } from "@/model/checklist/IChecklistBase";
import BhToggleRow from "@components/toggle/BhToggleRow";
import { classNames } from "@/utilities/jsUtilities";
import { selectAllProjectWorkGroups } from "@/app/store/project/projectWorkGroupsSlice";
import { saveChecklistBaseToAllWorkGroupsAsync, saveWorkGroupToChecklistBaseAuthorityAsync, selectWorkgroupToChecklistBases } from "@/app/store/checklist/checklistsSlice";
import { naturalSortByField } from "@/utilities/sortUtilities";
import { IWorkGroupToChecklistBase } from "@/model/checklist/IWorkGroupToChecklistBase";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import PartiesListItemTag from "@/views/home/project/detail/users/parties/PartiesListItemTag";
import BhUserIconGrouped from "@components/user/BhUserIconGrouped";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";

interface Props {
  setIsShown: Dispatch<SetStateAction<boolean>>;
  checklistBase: IChecklistBase;
}

const ChecklistWorkGroupAuthoritiesModal: FC<Props> = ({ setIsShown, checklistBase }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const workGroups = useAppSelector(selectAllProjectWorkGroups);
  const noWorkGroupsInProject = workGroups.length === 0 || !workGroups;
  const workGroupAuthorities = useAppSelector(selectWorkgroupToChecklistBases);
  const workGroupsWithSelected = workGroups
    .map((workGroup) => {
      const isChecklistBaseActivatedInWorkGroup = workGroupAuthorities.some((auth) => auth.checklistBaseId === checklistBase.id && auth.workGroupId === workGroup.id && auth.active);
      return { ...workGroup, selected: isChecklistBaseActivatedInWorkGroup };
    })
    .sort((a, b) => naturalSortByField(a, b, "name", false));
  const baseInAllWorkGroups =
    workGroupsWithSelected.filter((workGroup) => {
      return workGroup.selected;
    }).length === workGroups.length;

  const toggleBaseInWorkGroup = (workGroup: any) => {
    const existingAuth = workGroupAuthorities.find((auth) => auth.workGroupId === workGroup.id && auth.checklistBaseId === checklistBase.id);
    const authToSave = existingAuth
      ? { ...existingAuth, active: !workGroup.selected }
      : ({ workGroupId: workGroup.id, checklistBaseId: checklistBase.id, projectId: projectId, active: !workGroup.selected } as IWorkGroupToChecklistBase);
    dispatch(saveWorkGroupToChecklistBaseAuthorityAsync({ projectId: projectId, authority: authToSave }));
  };

  const toggleBaseInAllWorkGroups = () => {
    const dtoToSave = { projectId: projectId, checklistBaseId: checklistBase.id, removeBase: baseInAllWorkGroups };
    dispatch(saveChecklistBaseToAllWorkGroupsAsync(dtoToSave));
  };

  return (
    <BhModal
      isShown={true}
      size={"5xl"}
      setIsShown={setIsShown}
      header={
        <div className="h-7">
          <h2 className="mt-1 leading-7">
            <span className="bh-text-deep-ocean-60">{t("CHECKLIST.TEMPLATE.MANAGE.HEADER")} – </span>
            <span className="bh-text-deep-ocean">{checklistBase.type}</span>
          </h2>
        </div>
      }
      children={
        <div className="h-full w-full overflow-auto px-20 pb-16">
          {noWorkGroupsInProject ? (
            <div className="my-8 pt-16">
              <BhNoMatchesImage />
              <h2 className="text-center">{t("CHECKLIST.TEMPLATE.MANAGE.NO_WORKGROUPS")}</h2>
            </div>
          ) : (
            <>
              <div className="my-8">{t("CHECKLIST.TEMPLATE.MANAGE.USER_GROUPS.BODY")}</div>
              <BhToggleRow
                toggleObject={{ id: 0, selected: baseInAllWorkGroups } as any}
                toggleProperty={"selected"}
                onToggle={toggleBaseInAllWorkGroups}
                label={<div className="bh-text-deep-ocean text-14px font-bold leading-5">{t("CHECKLIST.ALL_USER_GROUPS")}</div>}
                classes={"pb-5"}
              />
              {workGroupsWithSelected &&
                workGroupsWithSelected.map((workGroup: any, index) => {
                  return (
                    <BhToggleRow
                      key={workGroup.id}
                      toggleObject={workGroup}
                      toggleProperty={"selected"}
                      onToggle={() => toggleBaseInWorkGroup(workGroup)}
                      label={
                        <div className="flex flex-row items-center justify-between">
                          <div className="flex flex-col gap-y-1">
                            <div className="bh-text-deep-ocean text-14px leading-5">{workGroup.name}</div>
                            {workGroup.type && (
                              <div className="ml-1">
                                <PartiesListItemTag workGroupType={workGroup.type} />
                              </div>
                            )}
                          </div>
                          <div className="mr-10 flex w-28 flex-row items-center">
                            <BhUserIconGrouped userGroup={workGroup.users} size={24} />
                          </div>
                        </div>
                      }
                      classes={classNames("pt-5 bh-border-pigeon-20 border-t", index !== workGroupsWithSelected.length - 1 && "pb-5")}
                    />
                  );
                })}
            </>
          )}
        </div>
      }
      footer={<BhModalFooter onCancel={() => setIsShown(false)} cancelButtonText={t("GLOBAL.CLOSE") as string} />}
    />
  );
};

export default ChecklistWorkGroupAuthoritiesModal;
