import React, { FC } from "react";
import BhFileCardMedium from "@components/cards/BhFileCardMedium";
import { FileEntityBranch, FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { selectFileById } from "@/app/store/filesSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { fetchFileEntity, fetchUrlForFile } from "@/api/fileAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { useTranslation } from "react-i18next";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faFolder } from "@fortawesome/pro-regular-svg-icons/faFolder";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import { isDirectory } from "@/utilities/fileEntity/fileEntityUtilities";
import BhTooltip from "@components/BhTooltip";
import { findWorkGroupIdByContractDirectoryFileEntityId } from "@/api/partyAPI";
import { fetchParentDirsAsync } from "@/app/store/foldersSlice";

interface Props {
  fileId: EntityId;
  fileEntity?: IFileEntity;
}

export const goToFileEntityParentDir = async (projectId: EntityId, fileEntity: IFileEntity, navigate: Function, dispatch: Function, currentPath: string) => {
  const folderId = fileEntity.parentFileEntityId;
  if (fileEntity.branch === FileEntityBranch.CONTRACT_DIRECTORY) {
    findWorkGroupIdByContractDirectoryFileEntityId(fileEntity.projectId, fileEntity.parentFileEntityId).then(function (result) {
      navigate(`/project/${projectId}/contract/${result.value}`);
    });
  } else if (fileEntity.branch === FileEntityBranch.ROOT_BIM_DIR) {
    navigate(`/project/${projectId}/bim/dir`);
  } else {
    if (!fileEntity.parentFileEntityId) return;

    const parentFileEntity = await fetchFileEntity(fileEntity.parentFileEntityId);

    if (parentFileEntity.isDeleted) return;

    const isContainerFile = [FileEntityType.CONTAINER, FileEntityType.ATTACHMENT_CONTAINER].includes(parentFileEntity.type);
    if (isContainerFile) {
      navigate(`/project/${projectId}/container/${folderId}`);
      return;
    }
    const match = matchPath({ path: "/project/:projectId/dir/:id" }, currentPath);
    if (match) {
      dispatch(fetchParentDirsAsync(fileEntity.parentFileEntityId));
    }
    if (![FileEntityType.DIR, FileEntityType.ROOT_DIR, FileEntityType.ROOT_DOCUMENT_DIR].includes(parentFileEntity.type)) return;
    navigate(`/project/${projectId}/dir/${folderId}?urlHighlight=${fileEntity.id}`);
  }
};

const SearchFileCardContainer: FC<Props> = ({ fileId, fileEntity }) => {
  const { t } = useTranslation();
  const reduxFileEntity = useAppSelector((state) => selectFileById(state, fileId));
  const currentFileEntity = fileEntity ? fileEntity : reduxFileEntity;
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  if (!currentFileEntity) return null;

  const goToParentDir = async (e: React.MouseEvent, folderId: EntityId) => {
    goToFileEntityParentDir(projectId, currentFileEntity, navigate, dispatch, location.pathname);
  };

  const handleFileDownload = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (currentFileEntity) {
      fetchUrlForFile(currentFileEntity.id, false, true, currentFileEntity.uuid).then((response) => {
        imitateUrlDownload(response.value);
      });
    }
  };

  const isDownloadable = !isDirectory(currentFileEntity);

  return (
    <div key={currentFileEntity.id}>
      <div className="hover:bh-bg-mint-50 group relative rounded">
        <BhFileCardMedium fileEntity={currentFileEntity as IFileEntity} withCreatorName={true} />
        <div className="bh-bg-mint-50 hover:bh-bg-white absolute right-2 top-1 flex opacity-0 transition duration-300 ease-in-out group-hover:opacity-100">
          <BhTextOnlyButton
            buttonProps={{
              onClick: (e: React.MouseEvent) => goToParentDir(e, currentFileEntity.parentFileEntityId)
            }}
            icon={faFolder}
          >
            {t("FILE.GO_TO_DIR")}
          </BhTextOnlyButton>
          {isDownloadable && (
            <BhTooltip body={t("GLOBAL.DOWNLOAD")}>
              <BhIconButton icon={faArrowDownToLine} buttonProps={{ onClick: handleFileDownload }} />
            </BhTooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchFileCardContainer;
