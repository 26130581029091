import { EntityId } from "@reduxjs/toolkit";
import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { IFormBase } from "@/model/form/IFormBase";
import { IFormBaseVersion } from "@/model/form/IFormBaseVersion";
import { IFormBaseVersionRow } from "@/model/form/IFormBaseVersionRow";
import { IProjectFormBase } from "@/model/form/IProjectFormBase";
import { IFormBasesToProjectsDTO } from "@/model/form/IFormBasesToProjectsDTO";
import { IFormFieldSaveRequest } from "@/model/form/IFormFieldSaveRequest";
import { IFormBasePdfInfo } from "@/model/form/IFormBasePdfInfo";
import { IEnabledForm } from "@/model/ICompany";
import { FormType } from "@/model/IForm";
import { IFormPdfPreview } from "@/model/form/IFormPdfPreview";

export function fetchFormBasesForCompany(companyId: EntityId): Promise<Array<IFormBase>> {
  return bauhubGet("/company/" + companyId + "/form/bases");
}

export function fetchFormBasesForProject(projectId: EntityId): Promise<Array<IFormBase>> {
  return bauhubGet("/project/" + projectId + "/form/bases");
}

export function fetchCountOfCompanyBuiltFormBases(companyId: EntityId): Promise<number> {
  return bauhubGet("/company/" + companyId + "/form/base/usage");
}

export function fetchProjectFormBasesForCompany(companyId: EntityId): Promise<Array<IProjectFormBase>> {
  return bauhubGet("/company/" + companyId + "/project/form/bases");
}

export function fetchProjectFormBasesForCompanyProject(companyId: EntityId, projectId: EntityId): Promise<Array<IProjectFormBase>> {
  return bauhubGet("/company/" + companyId + "/project/" + projectId + "/form/bases");
}

export function fetchCompanyFormBase(companyId: EntityId, formBaseId: EntityId): Promise<IFormBase> {
  return bauhubGet("/company/" + companyId + "/form/base/" + formBaseId);
}

export function fetchCompanyCustomFormBases(companyId: EntityId, projectId?: EntityId): Promise<Array<IEnabledForm>> {
  return bauhubGet("/form/bases/custom", { companyId: companyId, projectId: projectId });
}

export function fetchFormBaseVersions(companyId: EntityId, formBaseId: EntityId): Promise<Array<IFormBaseVersion>> {
  return bauhubGet("/company/" + companyId + "/form/base/" + formBaseId + "/versions");
}

export function fetchFormBaseVersionRowsForEditing(companyId: EntityId, formBaseId: EntityId, formBaseVersionId: EntityId): Promise<Array<IFormBaseVersionRow>> {
  return bauhubGet("/company/" + companyId + "/form/base/" + formBaseId + "/version/" + formBaseVersionId + "/rows/edit");
}

export function saveNewFormBaseVersionRow(companyId: EntityId, formBaseId: EntityId, formBaseVersionRow: IFormBaseVersionRow): Promise<Array<IFormBaseVersionRow>> {
  return bauhubPost("/company/" + companyId + "/form/base/" + formBaseId + "/version/row/new", formBaseVersionRow);
}

export function removeFormBaseVersionRow(companyId: EntityId, formBaseId: EntityId, formBaseVersionRow: IFormBaseVersionRow): Promise<Array<IFormBaseVersionRow>> {
  return bauhubPost("/company/" + companyId + "/form/base/" + formBaseId + "/version/row/remove", formBaseVersionRow);
}

export function saveFormBaseVersionRowWithNewField(companyId: EntityId, formBaseId: EntityId, formBaseVersionRow: IFormBaseVersionRow): Promise<IFormBaseVersionRow> {
  return bauhubPost("/company/" + companyId + "/form/base/" + formBaseId + "/version/field/new", formBaseVersionRow);
}

export function removeFieldFromBaseVersionRow(companyId: EntityId, formBaseId: EntityId, formBaseVersionRow: IFormBaseVersionRow): Promise<IFormBaseVersionRow> {
  return bauhubPost("/company/" + companyId + "/form/base/" + formBaseId + "/version/field/remove", formBaseVersionRow);
}

export function saveFormBaseVersionRowField(companyId: EntityId, formBaseId: EntityId, fieldSaveRequest: IFormFieldSaveRequest): Promise<IFormFieldSaveRequest> {
  return bauhubPost("/company/" + companyId + "/form/base/" + formBaseId + "/version/field/save", fieldSaveRequest);
}

export function saveNewFormBaseVersion(companyId: EntityId, formBaseId: EntityId): Promise<IFormBaseVersion> {
  return bauhubPost("/company/" + companyId + "/form/base/" + formBaseId + "/version");
}

export function saveCompanyFormBase(companyId: EntityId, formBase: IFormBase): Promise<IFormBase> {
  return bauhubPost("/company/" + companyId + "/form/base", formBase);
}

export function saveNewCompanyFormBase(companyId: EntityId, formBase: IFormBase): Promise<IFormBase> {
  return bauhubPost("/company/" + companyId + "/form/base/new", formBase);
}

export function removeFormBasesFromProjects(companyId: EntityId, dto: IFormBasesToProjectsDTO): Promise<Array<IProjectFormBase>> {
  return bauhubPost("/company/" + companyId + "/form/bases/remove", dto);
}

export function addFormBasesToProjects(companyId: EntityId, dto: IFormBasesToProjectsDTO): Promise<Array<IProjectFormBase>> {
  return bauhubPost("/company/" + companyId + "/form/bases/add", dto);
}

export function removeFormBase(companyId: EntityId, formBaseId: EntityId): Promise<IFormBase> {
  return bauhubPost("/company/" + companyId + "/form/base/" + formBaseId + "/delete");
}

export function removeFormBaseVersion(companyId: EntityId, formBaseId: EntityId, formBaseVersionId: EntityId): Promise<IFormBaseVersion> {
  return bauhubPost("/company/" + companyId + "/form/base/" + formBaseId + "/version/" + formBaseVersionId + "/delete");
}

export function copyFormBaseToCompany(companyId: EntityId, formBaseId: EntityId): Promise<IFormBase> {
  return bauhubPost("/company/" + companyId + "/form/base/" + formBaseId + "/copy");
}

export function copyFormBaseToProject(companyId: EntityId, projectId: EntityId, formBaseId: EntityId): Promise<IFormBase> {
  return bauhubPost("/company/" + companyId + "/project/" + projectId + "/form/base/" + formBaseId + "/copy");
}

export function fetchCompanyFormBasesForProject(companyId: EntityId, projectId: EntityId): Promise<Array<IFormBase>> {
  return bauhubGet("/company/" + companyId + "/project/" + projectId + "/form/base");
}

export function publishFormBaseVersion(companyId: EntityId, formBaseId: EntityId, formBaseVersionId: EntityId): Promise<IFormBaseVersion> {
  return bauhubPost("/company/" + companyId + "/form/base/" + formBaseId + "/version/" + formBaseVersionId + "/publish");
}

export function fetchSuggestedFormsForUser(): Promise<Array<string>> {
  return bauhubGet("/form/suggestions?st=false");
}

export function fetchSuggestedFormsForDir(dirId: EntityId): Promise<Array<string>> {
  return bauhubGet("/dir/" + dirId + "/form/suggestions?st=false");
}

export function fetchFormBasePdfInfo(companyId: EntityId, formBaseId: EntityId): Promise<IFormBasePdfInfo> {
  return bauhubGet("/company/" + companyId + "/form/base/" + formBaseId + "/pdf/info");
}

export function saveFormBasePdfInfo(companyId: EntityId, formBaseId: EntityId, formBasePdfInfo: IFormBasePdfInfo): Promise<IFormBasePdfInfo> {
  return bauhubPost("/company/" + companyId + "/form/base/" + formBaseId + "/pdf/info", formBasePdfInfo);
}

export function fetchFormPdfPreviewHtml(companyId: EntityId, formBaseId: EntityId, formBaseVersionId: EntityId): Promise<IFormPdfPreview> {
  return bauhubGet("/company/" + companyId + "/form/base/" + formBaseId + "/version/" + formBaseVersionId + "/preview");
}

export function fetchFormTypePdfPreviewHtml(companyId: EntityId, formType: FormType): Promise<IFormPdfPreview> {
  return bauhubGet("/company/" + companyId + "/form/type/" + formType + "/preview");
}

export function boSaveFormBuilderForm(companyId: EntityId, formBase: IFormBase): Promise<IFormBase> {
  return bauhubPost("/bo/company/" + companyId + "/form/save", formBase);
}

export function boDeleteFormBuilderForm(companyId: EntityId, formBaseId: EntityId): Promise<IFormBase> {
  return bauhubPost("/bo/company/" + companyId + "/form/" + formBaseId + "/delete");
}
