import React, { FC } from "react";
import { Rating } from "react-simple-star-rating";
import BhIconButton from "@components/buttons/BhIconButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";

interface Props {
  initialValue: number;
  property?: string;
  onChangeCallback?: Function;
  allowFractions?: boolean;
  readOnly?: boolean;
  displayNumericValue?: boolean;
}

const BhStarRating: FC<Props> = ({ initialValue, property, onChangeCallback, allowFractions, readOnly, displayNumericValue }) => {
  const handleRating = (changedRating: number) => {
    if (property) {
      onChangeCallback && onChangeCallback({ [property]: changedRating });
    }
  };

  const resetRating = () => {
    if (property) {
      onChangeCallback && onChangeCallback({ [property]: 0 });
    }
  };

  return (
    <div className="group flex flex-row items-center justify-between">
      <div className="flex flex-row items-center">
        <Rating
          emptyStyle={{ display: "flex" }}
          onClick={handleRating}
          initialValue={initialValue}
          fillStyle={{ display: "-webkit-inline-box" }}
          size={25}
          allowFraction={allowFractions}
          readonly={readOnly}
          allowTitleTag={false}
          transition={true}
        />
        {displayNumericValue && <div className="bh-text-deep-ocean-80 ml-1 font-bold">{"– " + initialValue}</div>}
      </div>
      {!readOnly && (
        <div className="opacity-0 group-hover:opacity-100">
          <BhIconButton icon={faXmark} buttonProps={{ onClick: resetRating }} />
        </div>
      )}
    </div>
  );
};

export default React.memo(BhStarRating);
