import React, { FC } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { useTranslation } from "react-i18next";
import { ITask } from "@/model/taskBoard/ITask";
import { saveFormTaskAsync, savePersonalFormTaskAsync } from "@/app/store/tasksSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";

interface Props {
  row: any;
  removeRowCallback: Function;
  saveRowCallback: Function;
  disabled?: boolean;
}

const FormProtocolFieldTableRowDelete: FC<Props> = ({ row, removeRowCallback, disabled, saveRowCallback }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const enableRowDeleteConfirmationModal = row.attachments?.length > 0 || row.task || row.content || row.nr || row.level === 0;

  const [showRowDeleteConfirmationModal, hideRowDeleteConfirmationModal] = useModal(
    () => (
      <BhDeleteConfirmationModal
        setIsOpen={hideRowDeleteConfirmationModal}
        header={<h2>{t("FORMS.CONFIRMATION.DELETE")}</h2>}
        body={
          <div>
            <div className="mb-4 font-bold">
              {row.nr && <i>{row.content ? "Nr. " + row.nr + " - " : "Nr. " + row.nr}</i>}
              {row.content && <i>{row.content}</i>}
            </div>
            <div>{t("FORMS.CONFIRMATION.DELETE_BODY")}</div>
            {row.task && row.task.id && <div>{t("FORMS.CONFIRMATION.DELETE_BODY.TASK")}</div>}
          </div>
        }
        confirmationButtonText={t("GLOBAL.DELETE")}
        handleDelete={() => {
          removeRowCallback(row);
          if (row.task && row.task.id) {
            saveRowCallback({} as ITask, "task", row._id);
            const isPersonalTask = !row.task.taskBoardId;
            if (isPersonalTask) {
              dispatch(savePersonalFormTaskAsync({ task: { ...row.task, deleted: true } as ITask, projectId: projectId }));
            } else {
              dispatch(saveFormTaskAsync({ task: { ...row.task, deleted: true }, projectId: projectId }));
            }
          }
          hideRowDeleteConfirmationModal();
        }}
      />
    ),
    [removeRowCallback, row]
  );

  return <BhIconButton icon={faXmark} buttonProps={{ onClick: enableRowDeleteConfirmationModal ? showRowDeleteConfirmationModal : () => removeRowCallback(row), disabled: disabled }} />;
};

export default FormProtocolFieldTableRowDelete;
