import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import BhElementWithBadge from "@components/badge/BhElementWithBadge";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import BhIconButton from "@components/buttons/BhIconButton";
import { useNavigate } from "react-router-dom";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import { faBell } from "@fortawesome/pro-regular-svg-icons/faBell";
import { projectUnseenNotificationCount } from "@/app/store/userNotificationsSlice";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  projectId: EntityId;
  classes?: string;
  fixedWidth?: boolean;
}

const ProjectListNotificationButton: FC<Props> = ({ projectId, classes, fixedWidth }) => {
  const { t } = useTranslation();
  const unseenNotificationsCount = useAppSelector((state) => projectUnseenNotificationCount(state, projectId));
  const navigate = useNavigate();

  return (
    <div className={fixedWidth ? "w-10" : ""}>
      {unseenNotificationsCount > 0 && (
        <div className={classes}>
          <BhTooltip body={t("NOTIFICATION.TOOLTIP")}>
            <BhElementWithBadge badge={<BhBadge type={BhBadgeType.BRIGHT}>{unseenNotificationsCount}</BhBadge>}>
              <BhIconButton
                icon={faBell}
                iconClasses="bh-text-deep-ocean"
                buttonProps={{
                  onClick: (e: any) => {
                    e.preventDefault();
                    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/overview`);
                  }
                }}
              />
            </BhElementWithBadge>
          </BhTooltip>
        </div>
      )}
    </div>
  );
};

export default ProjectListNotificationButton;
