import { IChecklistBaseVersion } from "@/model/checklist/IChecklistBaseVersion";
import { EntityId } from "@reduxjs/toolkit";

export interface IChecklistBase {
  id: EntityId;
  type: string;
  deleted: boolean;
  language: string;
  templateBaseId: EntityId;
  checklistBaseVersions: Array<IChecklistBaseVersion>;
  activeInProjectsCount?: number;
  bauhubChecklistBase?: boolean;
  createdBy: string;
  updatedBy: string;
  created: Date;
  updated: Date;
  addToNewProjects: boolean;
  createdInProjectId: EntityId;
  category: ChecklistBaseCategory;
}

export enum ChecklistBaseCategory {
  SAFETY = "SAFETY",
  QUALITY = "QUALITY",
  HANDOVERS = "HANDOVERS",
  OTHER = "OTHER"
}
