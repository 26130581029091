import React, { FC, useEffect, useRef, useState } from "react";
import WebViewer, { WebViewerInstance } from "@pdftron/webviewer";
import { fetchUrlForFile } from "@/api/fileAPI";
import { isOfficeDocument, shouldUsePdftronServer } from "@/utilities/fileEntity/fileEntityUtilities";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUser, selectCurrentUserTheme } from "@/app/store/userSlice";
import { FileEntityType } from "@/model/files/IFileEntity";
import { ReactComponent as BhLogoLoading } from "@svg/bauhub-loading-compressed.svg";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { Theme } from "@/model/IUserInfo";
import BhGoBackButton from "@components/buttons/BhGoBackButton";
import { useLocation } from "react-router-dom";
import { pdftronDisabledElements } from "@/views/home/project/detail/pdftron/PdftronHelperFunctions";

const PdftronContainerViewOnly: FC = () => {
  const location = useLocation();
  const fileEntity = location.state.fileEntity;
  const currentUser = useAppSelector(selectCurrentUser);
  const theme = useAppSelector(selectCurrentUserTheme);
  const [instance, setInstance] = useState<WebViewerInstance>();
  const [fileUrlLoading, setFileUrlLoading] = useState(true);
  const viewer = useRef(null);
  useDocumentTitle(fileEntity?.name);

  const isReadOnly = true;

  const fetchFileUrlAndInitPdftron = () => {
    async function fetchFileUrl() {
      if (!fileEntity) return;
      setFileUrlLoading(true);
      return await fetchUrlForFile(fileEntity.id, false, false, fileEntity.uuid);
    }

    fetchFileUrl()
      .then((presignedUrl) => {
        setFileUrlLoading(false);
        if (!viewer.current || instance) return;

        const formExtension = fileEntity?.type === FileEntityType.FORM ? ".pdf" : "";
        const fileName = fileEntity?.name + "" + formExtension;
        const isMSOfficeFile = isOfficeDocument(fileName.split(".").pop());

        if (!presignedUrl) return;

        WebViewer(
          {
            licenseKey: "Bauhub OU:OEM:Bauhub::B+:AMS(20260305):4A5F85823C6F78B942CE606F4F3D959CDE1FC65BA4AF8F58BE54B2B6F5C7",
            filename: !isMSOfficeFile ? fileName : undefined,
            path: ConfigSingleton.getInstance().getConfig().PUBLIC_URL + "/pdftron10.9.0",
            useDownloader: fileEntity && fileEntity.size > 40000000,
            initialDoc: !isMSOfficeFile ? presignedUrl.value : undefined,
            extension: !isMSOfficeFile ? fileName.split(".").pop() : undefined,
            webviewerServerURL: fileEntity && shouldUsePdftronServer(fileEntity) ? "https://pdf3.bauhub.ee" : undefined,
            disabledElements: [...pdftronDisabledElements, "toggleNotesButton"],
            isReadOnly: isReadOnly,
            disableMultiViewerComparison: true
          },
          viewer.current
        ).then((instance) => {
          setInstance(instance);

          if (fileEntity && isMSOfficeFile && presignedUrl) {
            const officeDoc = instance.Core.createDocument(presignedUrl.value, { filename: fileName, extension: fileName.split(".").pop(), officeOptions: { formatOptions: { locale: "de-DE" } } });
            officeDoc.then(function (dc) {
              instance.UI.loadDocument(dc, { filename: fileName });
            });
          }

          instance.UI.setTheme(theme === Theme.DARK ? "dark" : "light");

          instance.UI.setPrintQuality(3);
          instance.UI.hotkeys.off(
            "space, AnnotationCreateArrow, AnnotationCreateCallout, AnnotationEraserTool, AnnotationCreateFreeHand, AnnotationCreateStamp, AnnotationCreateLine, AnnotationCreateSticky, AnnotationCreateEllipse, AnnotationCreateRectangle, AnnotationCreateFreeText, AnnotationCreateSignature, AnnotationCreateTextSquiggly, AnnotationCreateTextHighlight, AnnotationCreateTextStrikeout, AnnotationCreateTextUnderline, AnnotationCreateRubberStamp"
          );

          const languageMap = { et_EE: "et", ru_RU: "ru", en_EN: "en" };
          // @ts-ignore
          instance.UI.setLanguage(languageMap[currentUser.language]);
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    fetchFileUrlAndInitPdftron();

    return function cleanup() {
      instance?.UI.dispose();
    };
  }, []);

  if (!fileEntity) {
    return null;
  }

  return (
    <div className="flex h-full w-full flex-col">
      <div className="items-top min-h-12 flex flex-row items-center border-b py-1">
        <div className="flex flex-1 flex-row items-center">
          <BhGoBackButton />
          <div className="text-13px whitespace-nowrap px-4 font-bold sm:hidden">V {fileEntity?.revision}</div>
          <h3 className="sm:overflow-hidden">{fileEntity?.name}</h3>
        </div>
      </div>
      <div className="flex h-full w-full flex-row overflow-hidden">
        <div className="relative flex-1">
          {fileUrlLoading && (
            <div className="flex h-full w-full flex-row items-center justify-center">
              <BhLogoLoading className="h-10 w-10" />
            </div>
          )}
          <div className="webviewer" ref={viewer} style={{ height: "100%", width: "100%" }} />
        </div>
      </div>
    </div>
  );
};

export default PdftronContainerViewOnly;
