import React, { FC, useEffect } from "react";
import TaskBoardFilter from "@components/taskBoard/TaskBoardFilter";
import TaskBoardHeaderRow from "@components/taskBoard/TaskBoardHeaderRow";
import TaskBoardColumnsContainer from "@components/taskBoard/TaskBoardColumnsContainer";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchTaskBoardTasksWithExtraFieldsAsync } from "@/app/store/tasksSlice";
import { EntityId } from "@reduxjs/toolkit";
import { resetOpenedTaskBoardId, setOpenedTaskBoardId } from "@/app/store/taskBoardsSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhFilterContainerWithItemsOnRight from "@components/filters/BhFilterContainerWithItemsOnRight";
import TaskBoardSort from "@components/taskBoard/TaskBoardSort";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";

interface Props {
  taskBoardId: EntityId;
}
const TaskBoard: FC<Props> = ({ taskBoardId }) => {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const [lastOpenedTaskBoardByProjectId, setLastOpenedTaskBoardByProjectId] = useLocalStorage<{ [projectId: EntityId]: EntityId }>("lastOpenedTaskBoardByProjectId", {});

  useEffect(() => {
    return function cleanup() {
      dispatch(resetOpenedTaskBoardId());
    };
  }, []);

  useEffect(() => {
    if (projectId) {
      dispatch(fetchTaskBoardTasksWithExtraFieldsAsync({ taskBoardId: taskBoardId, projectId: projectId }));
      if (taskBoardId) {
        dispatch(setOpenedTaskBoardId(taskBoardId));
      }
    }
    setLastOpenedTaskBoardByProjectId({ ...lastOpenedTaskBoardByProjectId, [projectId]: taskBoardId });
  }, [projectId, taskBoardId]);

  return (
    <div className="flex h-full w-full min-w-0 flex-col overflow-hidden px-6">
      <TaskBoardHeaderRow />
      <BhFilterContainerWithItemsOnRight right={<TaskBoardSort />}>
        <TaskBoardFilter />
      </BhFilterContainerWithItemsOnRight>
      <TaskBoardColumnsContainer />
    </div>
  );
};

export default TaskBoard;
