import React, { FC, useEffect } from "react";
import DetailViewContainer from "@/views/home/project/detail/detailViewContainer/DetailViewContainer";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import BhCardContainer from "@components/cards/BhCardContainer";
import { faChartLine, faHandshake, faHelmetSafety, faInfinity, faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { resetChecklistFilter, selectChecklistFilter, setChecklistFilter } from "@/app/store/checklist/checklistsSlice";
import { serialize } from "@/utilities/jsUtilities";
import { ChecklistBaseCategory } from "@/model/checklist/IChecklistBase";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { faGear } from "@fortawesome/pro-regular-svg-icons/faGear";

interface Props {}

const ChecklistCategoriesContainer: FC<Props> = () => {
  useDocumentTitle("PROJECT.CHECKLISTS");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const filter = useAppSelector(selectChecklistFilter);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));

  useEffect(() => {
    dispatch(resetChecklistFilter());
    dispatch(setSidebarCollapsed(false));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.CHECKLISTS));
  }, []);

  const goToCategoryList = (categoryName: ChecklistBaseCategory) => {
    const newFilter = { ...filter, ...{ category: categoryName } };
    dispatch(setChecklistFilter({ filter: newFilter }));

    const newLocation = {
      pathname: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklists/list/view`,
      search: serialize({ category: categoryName })
    };
    navigate(newLocation);
  };

  const checklistCategories = [
    { id: 0, name: ChecklistBaseCategory.SAFETY, icon: faHelmetSafety },
    { id: 1, name: ChecklistBaseCategory.QUALITY, icon: faStar },
    { id: 2, name: ChecklistBaseCategory.HANDOVERS, icon: faHandshake },
    { id: 3, name: ChecklistBaseCategory.OTHER, icon: faInfinity }
  ];

  const navigateTo = (location: string) => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklists/admin/` + location);
  };

  return (
    <DetailViewContainer
      header={
        <DetailViewHeading>
          <div className="flex flex-row items-center justify-between">
            <div>{t("PROJECT.CHECKLISTS")}</div>
            {isProjectAdmin && (
              <div className="flex flex-row items-center">
                <BhSecondaryButton icon={faChartLine} buttonProps={{ onClick: () => navigateTo("statistics") }}>
                  {t("CHECKLIST.STATISTICS")}
                </BhSecondaryButton>
                <BhPrimaryButton icon={faGear} buttonProps={{ onClick: () => navigateTo("settings") }}>
                  {t("CHECKLIST.PROJECT_SETTINGS")}
                </BhPrimaryButton>
              </div>
            )}
          </div>
        </DetailViewHeading>
      }
    >
      <div className="mt-5 flex w-full flex-col">
        {checklistCategories.map((category, index) => {
          return (
            <BhCardContainer key={category.id} onClick={() => goToCategoryList(category.name as ChecklistBaseCategory)}>
              <div className="flex flex-row items-center px-6 hover:cursor-pointer">
                <div className="bh-text-bauhub-green min-w-5 mr-6 w-5">
                  <FontAwesomeIcon icon={category.icon} size="lg" />
                </div>
                <div className="text-18px font-bold">{t("CHECKLIST.CATEGORY." + category.name)}</div>
              </div>
            </BhCardContainer>
          );
        })}
      </div>
    </DetailViewContainer>
  );
};

export default ChecklistCategoriesContainer;
