import React, { FC } from "react";
import { ITask } from "@/model/taskBoard/ITask";
import { ITaskComment, ITaskCommentDTO } from "@/model/taskBoard/ITaskComment";
import BhComment from "@components/comment/BhComment";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { deleteTaskCommentAsync, savePersonalTaskCommentAsync, saveTaskBoardTaskCommentAsync } from "@/app/store/tasksSlice";
import { selectCurrentUser } from "@/app/store/userSlice";
import { useTranslation } from "react-i18next";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { extractMentions } from "@/utilities/jsUtilities";
import BhCommentInputWithUsers from "@components/comment/BhCommentInputWithUsers";

interface Props {
  task: ITask;
}

const TaskComments: FC<Props> = ({ task }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const comments = task.comments && task.comments.slice().sort((a, b) => (new Date(a.created) > new Date(b.created) ? -1 : 1));
  const currentUser = useAppSelector(selectCurrentUser);
  const projectId = useAppSelector(selectCurrentProjectId);
  const isPersonalTask = task && !task.taskBoardId && task.assignee === currentUser.id && task.reporter === currentUser.id;

  const saveTaskComment = (commentObj: ITaskComment) => {
    const hasAttachments = commentObj.attachments && commentObj.attachments.length > 0;
    if (commentObj.text || hasAttachments) {
      const mentions = extractMentions(commentObj.text, task.id, projectId, "TASK_COMMENT");
      commentObj.mentions = mentions;
      const commentDTO = { taskId: task.id, taskBoardId: task.taskBoardId, comment: commentObj } as ITaskCommentDTO;
      if (!isPersonalTask) {
        dispatch(saveTaskBoardTaskCommentAsync({ commentDTO: commentDTO, projectId: projectId }));
      } else {
        dispatch(savePersonalTaskCommentAsync({ commentDTO: commentDTO, projectId: projectId }));
      }
    }
  };

  const deleteTaskComment = (comment: ITaskComment) => {
    dispatch(deleteTaskCommentAsync(comment));
  };

  return (
    <div className="pt-6">
      <div className="bh-text-deep-ocean pl-2 pb-2 font-bold">{t("TASK.COMMENTS")}</div>
      <div className="pb-2 pl-2">
        <BhCommentInputWithUsers taskBoardId={task.taskBoardId} textProperty={"text"} onSubmit={saveTaskComment} />
      </div>
      {comments && comments.length > 0 && (
        <div>
          {comments.map((comment: ITaskComment) => {
            return (
              <div className="mb-4" key={comment.id}>
                <BhComment taskBoardId={task.taskBoardId} comment={comment} editCallback={saveTaskComment} deleteCallback={deleteTaskComment} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TaskComments;
