import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import {
  fetchCompanyChecklistBasesForProjectAsync,
  fetchProjectChecklistBasesForCompanyProjectAsync,
  fetchWorkgroupToChecklistBasesForProjectAsync,
  selectCompanyChecklistBases
} from "@/app/store/checklist/checklistsSlice";
import { selectCurrentProjectCompanyId, selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { fetchProjectWorkGroupsAsync } from "@/app/store/project/projectWorkGroupsSlice";
import ProjectChecklistSettingsHeader from "@components/checklists/settings/projectSettings/ProjectChecklistSettingsHeader";
import ProjectChecklistSettingsTable from "@components/checklists/settings/projectSettings/ProjectChecklistSettingsTable";
import NoChecklistBases from "@components/checklists/list/NoChecklistBases";
import DetailViewContainer from "@/views/home/project/detail/detailViewContainer/DetailViewContainer";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import { useTranslation } from "react-i18next";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { useNavigate } from "react-router-dom";

const ChecklistProjectSettings: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(selectCurrentProjectCompanyId);
  const projectId = useAppSelector(selectCurrentProjectId);
  const checklistBases = useAppSelector(selectCompanyChecklistBases);
  const [filter, setFilter] = useState({ searchString: "", username: "", showProjectTemplatesOnly: false, showCompanyTemplatesOnly: false, orderBy: "type", reversed: false, category: null });

  useEffect(() => {
    dispatch(fetchCompanyChecklistBasesForProjectAsync({ companyId: companyId, projectId: projectId }));
    dispatch(fetchProjectChecklistBasesForCompanyProjectAsync({ companyId: companyId, projectId: projectId }));
    dispatch(fetchWorkgroupToChecklistBasesForProjectAsync(projectId));
    dispatch(fetchProjectWorkGroupsAsync(projectId));
  }, []);

  const navigateToCategories = () => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklists`);
  };

  return (
    <DetailViewContainer
      header={
        <DetailViewHeading>
          <div className="flex flex-row items-center gap-x-2">
            <div className={classNames("bh-text-deep-ocean-60 cursor-pointer")} onClick={navigateToCategories}>
              {t("PROJECT.CHECKLISTS")}
            </div>
            <div className="flex flex-row items-center gap-x-2">
              <div className="bh-text-deep-ocean-40">
                <FontAwesomeIcon icon={faChevronRight} size={"2xs"} />
              </div>
              <div>{t("CHECKLIST.PROJECT_SETTINGS")}</div>
            </div>
          </div>
        </DetailViewHeading>
      }
    >
      {checklistBases && checklistBases.length > 0 && (
        <BhScrollableBody header={<ProjectChecklistSettingsHeader filter={filter} setFilter={setFilter} />}>
          <ProjectChecklistSettingsTable filter={filter} setFilter={setFilter} />
        </BhScrollableBody>
      )}

      {(!checklistBases || checklistBases.length === 0) && <NoChecklistBases />}
    </DetailViewContainer>
  );
};

export default ChecklistProjectSettings;
