import React, { FC } from "react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface Props {
  closeTaskModal: Function;
}

const DisabledTaskModalHeader: FC<Props> = ({ closeTaskModal }) => {
  const { t } = useTranslation();

  return (
    <div className="flex h-full w-full flex-row items-center justify-end">
      <BhTextOnlyButton icon={faXmark} buttonProps={{ onClick: closeTaskModal }}>
        {t("GLOBAL.CLOSE")}
      </BhTextOnlyButton>
    </div>
  );
};

export default DisabledTaskModalHeader;
