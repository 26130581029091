import React, { ChangeEvent, FC, useId, useRef, useState } from "react";
import { IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import AttachmentsContainerLarge from "@components/attachments/AttachmentsContainerLarge";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { FileEntityResource, FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import BhDropFileContainer from "@/components/upload/BhDropFileContainer";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faArrowUpToLine } from "@fortawesome/pro-regular-svg-icons/faArrowUpToLine";
import { classNames } from "@/utilities/jsUtilities";
import { faFolderBlank } from "@fortawesome/pro-regular-svg-icons/faFolderBlank";
import SelectFilesModal from "@/views/home/project/projectModals/selectFilesModal/SelectFilesModal";
import { useTranslation } from "react-i18next";
import { uploadCompanyAttachmentAsync } from "@/app/store/uploadSlice";
import { selectCurrentProjectCompanyId, selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { EntityId } from "@reduxjs/toolkit";
import { attachFilesToChecklistRoundRowAsync, removeFileFromChecklistRoundRowAsync, selectChecklistRowByBaseRowId } from "@/app/store/checklist/checklistRowsSlice";

interface Props {
  baseVersionRow: IChecklistBaseVersionRow;
  checklistRoundId: EntityId;
  checklistId: EntityId;
  disabled: boolean;
}

const ChecklistRowAttachments: FC<Props> = ({ baseVersionRow, checklistRoundId, checklistId, disabled }) => {
  const [selectFilesModalOpen, setSelectFilesModalOpen] = useState(false);
  const fileInputRef = useRef(null);
  const inputId = useId();
  const { t } = useTranslation();
  const roundRow = useAppSelector((state) => selectChecklistRowByBaseRowId(state, baseVersionRow.id));
  const attachmentsExist = roundRow?.attachments && roundRow?.attachments.length > 0;
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(selectCurrentProjectCompanyId);
  const projectId = useAppSelector(selectCurrentProjectId);

  if (!roundRow) {
    return null;
  }

  const saveRowAttachments = (files: any, isUpload: boolean) => {
    if (files.length > 0) {
      const attachments = files.map((file: IFileEntity) => {
        return {
          checklistId: checklistId,
          fileEntityId: file.id,
          baseVersionRowId: baseVersionRow.id,
          checklistProjectId: projectId,
          deleted: false,
          upload: isUpload
        };
      });
      dispatch(attachFilesToChecklistRoundRowAsync({ checklistRoundId: checklistRoundId, checklistRoundRowId: roundRow.id, attachments: attachments }));
    }
  };

  const removeRowAttachment = (fileEntityId: EntityId) => {
    dispatch(removeFileFromChecklistRoundRowAsync({ checklistRoundId: checklistRoundId, fileEntityId: fileEntityId }));
  };

  const addUploadedOrAddedFilesToRow = (attachmentObject: { files: Array<IFileEntity> }) => {
    const addedAttachments = attachmentObject.files.filter((file) => {
      return !roundRow.attachments.some((att) => att.id === file.id);
    });
    const isUpload = addedAttachments.length > 0 && !addedAttachments[0].parentFileEntityId;
    saveRowAttachments(addedAttachments, isUpload);
  };

  const uploadFiles = async (files: any) => {
    let savedFiles: Array<IFileEntity> = [];
    await Promise.all(
      files.map(async (file: any) => {
        await dispatch(uploadCompanyAttachmentAsync({ file: file, companyId: companyId, resource: FileEntityResource.CHECKLIST_BASE_VERSION_ROW, resourceId: baseVersionRow.id })).then((action) => {
          const savedFileEntity = action.payload as IFileEntity;
          if (savedFileEntity.id) {
            savedFiles.push(savedFileEntity);
          }
        });
      })
    ).then(() => {
      saveRowAttachments(savedFiles, true);
    });
  };

  const onUploadFilesSelected = (event: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const files = [...event.target.files];
    if (!files) return;
    const filesWithSizeLargerThanZero = files.filter((file) => file.size > 0);
    event.target.value = "";
    uploadFiles(filesWithSizeLargerThanZero);
  };

  const addProjectFilesToRow = (selectedFiles: Array<IFileEntity>) => {
    saveRowAttachments(selectedFiles, false);
  };

  return (
    <div className="mt-6 mb-3 flex w-full flex-col">
      <div className="text-14px l-h-18px bh-text-deep-ocean place-self-start px-0.5 pb-1 font-semibold">{baseVersionRow.fieldTitle}</div>
      {attachmentsExist && (
        <AttachmentsContainerLarge
          attachments={roundRow.attachments}
          addingDisabled={disabled || !baseVersionRow.allowAttachmentUploads}
          saveCallback={addUploadedOrAddedFilesToRow}
          property={"files"}
          multiple={true}
          chooseFromProjectAllowed={projectId ? true : false}
          excludeFileTypes={[FileEntityType.FORM]}
          removeCallback={!disabled && baseVersionRow.allowAttachmentUploads ? removeRowAttachment : undefined}
        />
      )}
      {baseVersionRow.allowAttachmentUploads && !attachmentsExist && (
        <BhDropFileContainer onDrop={uploadFiles}>
          <div className={classNames("bh-bg-smoke bh-border-pigeon-50 mt-2.5 flex flex-row items-center justify-center gap-x-3 rounded border border-dashed py-5")}>
            <BhTextOnlyButton
              icon={faArrowUpToLine}
              buttonProps={{
                onClick: () => {
                  // @ts-ignore
                  fileInputRef.current.click();
                }
              }}
            >
              {t("GLOBAL.UPLOAD_FILES")}
            </BhTextOnlyButton>
            {projectId && (
              <BhTextOnlyButton icon={faFolderBlank} buttonProps={{ onClick: () => setSelectFilesModalOpen(true) }}>
                {t("FILEPICKER.PICK_FILE")}
              </BhTextOnlyButton>
            )}
          </div>
        </BhDropFileContainer>
      )}
      <input type="file" id={inputId} ref={fileInputRef} style={{ display: "none" }} multiple={true} onChange={onUploadFilesSelected} />
      {selectFilesModalOpen && <SelectFilesModal onSelect={addProjectFilesToRow} setModalOpen={setSelectFilesModalOpen} multiple={true} excludeFileTypes={[FileEntityType.FORM]} />}
    </div>
  );
};

export default ChecklistRowAttachments;
