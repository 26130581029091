import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhModal from "@components/modal/BhModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/pro-solid-svg-icons/faWarning";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import { ICompanyInvitedUser } from "@/model/companyUsers/ICompanyInvitedUser";
import { deletableUserSet, removeUserFromCompanyAsync, removeUserInvitesAsync, selectDeletableUser } from "@/app/store/companiesSlice";
import { useTranslation } from "react-i18next";
import BhModalFooter from "@components/modal/BhModalFooter";

const DeleteCompanyUserModal: FC = () => {
  const { t } = useTranslation();
  const deletableUser = useAppSelector(selectDeletableUser);
  const dispatch = useAppDispatch();

  const handleDelete = (companyUser: ICompanyUser | ICompanyInvitedUser) => {
    if (companyUser.userExists) {
      dispatch(removeUserFromCompanyAsync(companyUser as ICompanyUser));
    } else {
      dispatch(removeUserInvitesAsync(companyUser as ICompanyInvitedUser));
    }
    dispatch(deletableUserSet({} as ICompanyUser));
  };

  const handleCancel = () => {
    dispatch(deletableUserSet({} as ICompanyUser));
  };

  return (
    <div>
      <BhModal
        isShown={true}
        setIsShown={handleCancel}
        header={
          <div className="inline-flex items-center">
            <FontAwesomeIcon className="bh-text-warning-yellow mt-1 pr-5" icon={faWarning} /> <h2>{t("PROJECT.CONFIRM_ADMIN_REMOVE.REMOVE")}</h2>
          </div>
        }
        children={<div className="px-8 py-6" dangerouslySetInnerHTML={{ __html: t("COMPANY.SETTINGS.USERS.DELETE.ALL_AUTHORITIES.BODY") as any }}></div>}
        footer={
          <BhModalFooter
            onCancel={handleCancel}
            onConfirm={() => handleDelete(deletableUser)}
            cancelButtonText={t("GLOBAL.CANCEL") as string}
            confirmButtonText={t("WORKGROUP.NEW.REMOVE_USER") as string}
            isWarningButton={true}
          />
        }
      />
    </div>
  );
};

export default DeleteCompanyUserModal;
