import React, { FC, ReactNode } from "react";
import { Transition } from "@headlessui/react";

interface Props {
  header: ReactNode;
  footer: ReactNode;
  onOutsideClick: Function;
  children: ReactNode;
}

const BhShareModal: FC<Props> = ({ header, footer, onOutsideClick, children }) => {
  return (
    <Transition show={true} enter="transition ease duration-1000" enterFrom="opacity-0" enterTo="opacity-100" leave="transition ease duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
      <div className="absolute right-0 top-0 z-30 flex h-screen w-screen flex-row items-end overflow-hidden">
        <div className="bh-bg-pigeon h-full flex-1 opacity-60" onClick={() => onOutsideClick()} />
        <div className="bh-shadow flex-share-modal bh-bg-raised-white flex h-full flex-col overflow-hidden">
          <div className="bh-border-pigeon-20 border-b px-8 py-3">{header}</div>
          <div className="flex flex-1 flex-col overflow-y-auto">{children}</div>
          <div className="bh-border-pigeon-20 flex flex-row border-t p-4">{footer}</div>
        </div>
      </div>
    </Transition>
  );
};

export default BhShareModal;
