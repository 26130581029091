import React, { FC, ReactNode, useLayoutEffect, useRef } from "react";

interface Props {
  header?: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
  keepScrollForKey?: string;
}

export const scrollMemoryStore = {
  _data: new Map(),
  get(key: any) {
    if (!key) {
      return null;
    }

    return this._data.get(key) || null;
  },
  set(key: any, data: any) {
    if (!key) {
      return;
    }
    return this._data.set(key, data);
  }
};

const BhScrollableBody: FC<Props> = ({ header, footer, children, keepScrollForKey }) => {
  const containerEl = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (keepScrollForKey) {
      const scrollTop = scrollMemoryStore.get(keepScrollForKey);
      if ((scrollTop || scrollTop === 0) && containerEl.current) {
        containerEl.current.scrollTop = scrollTop;
      }
    }

    return () => {
      if (keepScrollForKey && containerEl.current) {
        const currentScrollTop = containerEl.current?.scrollTop;
        scrollMemoryStore.set(keepScrollForKey, currentScrollTop);
      }
    };
  }, []);

  return (
    <div className="mx-auto flex h-full w-full flex-col">
      {header}
      <div ref={containerEl} className="flex-1 overflow-auto pb-16">
        {children}
      </div>
      {footer}
    </div>
  );
};

export default BhScrollableBody;
