import React, { FC } from "react";
import BhStarRating from "@components/input/BhStarRating";
import BhInputLabel from "@components/input/BhInputLabel";

interface Props {
  initialValue: number;
  property?: string;
  onChangeCallback?: Function;
  label?: string;
  labelHelper?: string;
  allowFractions?: boolean;
  readOnly?: boolean;
  displayNumericValue?: boolean;
}

const BhStarRatingStackedLabel: FC<Props> = ({ initialValue, property, onChangeCallback, label, labelHelper, allowFractions, readOnly, displayNumericValue }) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        {label && <BhInputLabel>{label}</BhInputLabel>}
        {labelHelper && (
          <div className="text-13px l-h-16px bh-text-deep-ocean-80 px-0.5 py-1.5 font-bold">
            <span className="bh-text-pigeon-70 font-normal italic"> - {labelHelper}</span>
          </div>
        )}
      </div>
      <BhStarRating onChangeCallback={onChangeCallback} initialValue={initialValue} property={property} allowFractions={allowFractions} readOnly={readOnly} displayNumericValue={displayNumericValue} />
    </div>
  );
};

export default React.memo(BhStarRatingStackedLabel);
