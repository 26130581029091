import React, { FC } from "react";
import BOHeader from "@/views/home/backoffice/views/BOHeader";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { bauhubPost } from "@/api/bauhubAPI";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { useAppDispatch } from "@/app/hooks";

const BOBackend: FC = () => {
  const dispatch = useAppDispatch();

  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteConfirmationModal}
      header={"Initial form base version rows copy to MongoDB"}
      body={"If you don't know what it does, don't do it!"}
      confirmationButtonText={"Transfer"}
      cancelButtonText={"Cancel"}
      handleDelete={() => {
        return bauhubPost("/form/builder/rows/transfer/mongo").then((response) => {
          dispatch(
            toastFlagAdded({
              id: uuidv4(),
              type: BauhubBannerType.SUCCESS,
              disappear: true,
              children: response.value
            })
          );
        });
      }}
    />
  ));

  return (
    <div className="px-16 sm:px-4">
      <BOHeader />
      <div className="mt-10">
        <BhPrimaryButton buttonProps={{ onClick: showDeleteConfirmationModal }}>Form rows to mongo transfer</BhPrimaryButton>
      </div>
    </div>
  );
};

export default BOBackend;
