import { createAsyncThunk, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { IAuthorityBoolean, IUser, preparePrivileges } from "@/model/IUser";
import { IUserInfo } from "@/model/IUserInfo";
import { Authority, IUserAuthority, Resource, SubResource } from "@/model/IUserAuthority";
import {
  fetchCurrentUser,
  fetchCurrentUserInfo,
  removeIdentityCode,
  removeTwoFactorPhoneNumber,
  saveCurrentUserLanguage,
  saveCurrentUserTwoFactorPreference,
  saveUser,
  saveUserInfo
} from "@/api/userAPI";
import { getKeyValue, setKeyValue } from "@/utilities/jsUtilities";
import { RootState } from "@/app/store";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { FileEntityBranch, IFolderFileEntity } from "@/model/files/IFileEntity";
import { selectParentDirs } from "@/app/store/foldersSlice";
import { toggleHideUserContactsGloballyAsync } from "@/app/store/project/projectContactsSlice";

export interface UserState {
  current: IUser;
  currentUserInfo: IUserInfo;
  status: BhStateStatusType;
}

const initialState: UserState = {
  current: {} as IUser,
  currentUserInfo: {} as IUserInfo,
  status: BhStateStatusType.INITIAL
};

export const fetchCurrentUserInfoAsync = createAsyncThunk("user/fetchCurrentUserInfo", async () => {
  return fetchCurrentUserInfo();
});

export const fetchCurrentUserAsync = createAsyncThunk("user/fetchCurrentUser", async () => {
  return fetchCurrentUser();
});

export const saveCurrentUserAsync = createAsyncThunk("user/saveCurrentUser", async (user: IUser) => {
  const response = await saveUser(user);
  return response;
});

export const saveCurrentUserLanguageAsync = createAsyncThunk("user/saveCurrentUser/language", async (user: IUser) => {
  const response = await saveCurrentUserLanguage(user);
  return response;
});

interface ValidationErrors {
  errorMessage: string;
  field_errors: Record<string, string>;
}

export const saveCurrentUserInfoAsync = createAsyncThunk<
  IUserInfo,
  IUserInfo,
  {
    rejectValue: ValidationErrors;
  }
>("user/saveCurrentUserInfo", async (userInfo: IUserInfo, { rejectWithValue }) => {
  const response = await saveUserInfo(userInfo);
  return response;

  // try {
  // } catch (err) {
  //   // We got validation errors, let's return those so we can reference in our component and set form errors
  //   // return rejectWithValue(data);
  // }
});

export const saveCurrentUserTwoFactorPreferenceAsync = createAsyncThunk<
  IUser,
  boolean,
  {
    rejectValue: any;
  }
>("user/saveCurrentUserTwoFactorPreference", async (value: boolean, { rejectWithValue }) => {
  const response = await saveCurrentUserTwoFactorPreference(value);
  return response;
  // try {
  //
  // } catch (err) {
  //   console.log("ERROR", err);
  //   return rejectWithValue(err.response.data);
  // }
});

export const removeTwoFactorPhoneNumberAsync = createAsyncThunk("user/removeTwoFactorPhoneNumber", async () => {
  return removeTwoFactorPhoneNumber();
});

export const removeIdentityCodeAsync = createAsyncThunk("user/removeIdentityCodeAsync", async () => {
  return removeIdentityCode();
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLoggedOut: (state) => {
      state.current = {} as IUser;
    },
    userInfoSettingToggled: (state, action: PayloadAction<keyof IUserInfo>) => {
      const property = action.payload;
      setKeyValue(state.currentUserInfo, property, getKeyValue<keyof IUserInfo, IUserInfo>(property)(state.currentUserInfo));
    },
    setUserAvatarUuid: (state, action) => {
      state.currentUserInfo.avatarUuid = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUserInfoAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchCurrentUserInfoAsync.fulfilled, (state, action) => {
        state.currentUserInfo = action.payload;
        state.status = BhStateStatusType.SUCCESS;
      })
      .addCase(fetchCurrentUserAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchCurrentUserAsync.fulfilled, (state, action) => {
        let user = action.payload;
        preparePrivileges(user);
        state.current = user;
        state.status = BhStateStatusType.SUCCESS;
      })
      .addCase(saveCurrentUserAsync.fulfilled, (state, action) => {
        state.current = action.payload;
      })
      .addCase(saveCurrentUserAsync.rejected, (state, action) => {
        // halda error
      })
      .addCase(saveCurrentUserLanguageAsync.fulfilled, (state, action) => {
        state.current.language = action.payload.language;
      })
      .addCase(saveCurrentUserLanguageAsync.rejected, (state, action) => {
        // halda error
      })
      .addCase(saveCurrentUserInfoAsync.fulfilled, (state, action) => {
        state.currentUserInfo = action.payload;
      })
      .addCase(saveCurrentUserInfoAsync.rejected, (state, action) => {
        // halda error
      })
      .addCase(saveCurrentUserTwoFactorPreferenceAsync.fulfilled, (state, action) => {
        // TODO arvuta siin privileegid uuesti
        state.current = action.payload;
      })
      .addCase(saveCurrentUserTwoFactorPreferenceAsync.rejected, (state, action) => {
        // halda error
      })
      .addCase(removeTwoFactorPhoneNumberAsync.fulfilled, (state, action) => {
        state.current.twoFactorEnabled = false;
        state.current.phoneNumber = "";
      })
      .addCase(removeTwoFactorPhoneNumberAsync.rejected, (state, action) => {
        // halda error
      })
      .addCase(removeIdentityCodeAsync.fulfilled, (state, action) => {
        state.current.identityCode = "";
      })
      .addCase(removeIdentityCodeAsync.rejected, (state, action) => {
        // halda error
      })
      .addCase(toggleHideUserContactsGloballyAsync.fulfilled, (state, action) => {
        state.currentUserInfo.contactsHidden = action.payload.hidden;
      });
  }
});

export const { userInfoSettingToggled, userLoggedOut, setUserAvatarUuid } = userSlice.actions;

export const selectCurrentUser = (state: RootState) => state.user.current;
export const selectCurrentUserFetchStatus = (state: RootState) => state.user.status;
export const selectCurrentUserInfo = (state: RootState) => state.user.currentUserInfo;
export const selectCurrentUserTheme = (state: RootState) => state.user.currentUserInfo.theme;
export const selectCurrentUserLanguage = (state: RootState) => state.user.current.language;
export const selectCurrentUserUsername = createSelector(selectCurrentUser, (user) => user.username);
export const selectCurrentUserDontOpenCADFiles = createSelector(selectCurrentUserInfo, (userInfo: IUserInfo) => userInfo.openPdftronCad === false);

export const selectAnyFilesSelected = createSelector(selectCurrentUser, (user) => user.id === 2);

export const isCurrentUserSuperUser = (state: RootState) => {
  return state.user.current.userAuthorities.find((auth) => auth.resource === Resource.APP && auth.authority === Authority.SUPERUSER);
};

export const isCurrentUserCompanyAdminWithAccountManagerPrivilege = (state: RootState, companyId: EntityId) => {
  if (isCurrentUserSuperUser(state)) {
    return true;
  }
  return state.user.current.userAuthorities.some(
    (auth) => auth.resource === Resource.COMPANY && auth.resourceId === companyId && auth.subResource === SubResource.ACCOUNT_MANAGER && auth.authority === Authority.ADMIN
  );
};
export const isCurrentUserCompanyChecklistsManager = (state: RootState, companyId: EntityId) => {
  if (isCurrentUserSuperUser(state)) {
    return true;
  }
  return state.user.current.userAuthorities.some(
    (auth) => auth.resource === Resource.COMPANY && auth.resourceId === companyId && auth.subResource === SubResource.CHECKLISTS_MANAGER && auth.authority === Authority.ADMIN
  );
};
export const isCurrentUserCompanyFormsManager = (state: RootState, companyId: EntityId) => {
  if (isCurrentUserSuperUser(state)) {
    return true;
  }
  return state.user.current.userAuthorities.some(
    (auth) => auth.resource === Resource.COMPANY && auth.resourceId === companyId && auth.subResource === SubResource.FORMS_MANAGER && auth.authority === Authority.ADMIN
  );
};
export const isCurrentUserRTEngineeringProjectManager = (state: RootState, projectId: EntityId) => {
  return state.user.current.userAuthorities.some((auth) => auth.resource === Resource.RT_ENGINEERING_PM && auth.resourceId === projectId);
};
export const isCurrentUserMaruProjectManager = (state: RootState, projectId: EntityId) => {
  return state.user.current.userAuthorities.some((auth) => auth.resource === Resource.MR_PROJECT_PM && auth.resourceId === projectId);
};
export const isCurrentUserMaruProjectTeamMember = (state: RootState, projectId: EntityId) => {
  return state.user.current.userAuthorities.some((auth) => auth.resource === Resource.MR_PROJECT_TEAM && auth.resourceId === projectId);
};

export const selectProjectId = (state: RootState, itemId: EntityId) => itemId;
export const selectIsCurrentUserProjectAdmin = createSelector([selectCurrentUser, selectProjectId], (currentUser, projectId) => {
  if (!currentUser || !currentUser.userAuthorities) return false;
  const currentUserIsProjectAdmin = currentUser.userAuthorities.some(
    (auth: IUserAuthority) => auth.authority === Authority.ADMIN && auth.resource === Resource.PROJECT && auth.resourceId === projectId
  );
  const currentUserIsSuperuser = currentUser.userAuthorities.some((auth: IUserAuthority) => auth.resource === Resource.APP && auth.authority === Authority.SUPERUSER);
  return currentUserIsProjectAdmin || currentUserIsSuperuser;
});

export const selectHasBimrights = createSelector([selectCurrentUser, selectProjectId], (currentUser, projectId) => {
  if (!currentUser || !currentUser.userAuthorities) return false;
  const currentUserIsProjectAdmin = currentUser.userAuthorities.some(
    (auth: IUserAuthority) => auth.authority === Authority.ADMIN && auth.resource === Resource.PROJECT && auth.resourceId === projectId
  );
  return currentUserIsProjectAdmin || currentUser.userAuthorities.some((ua) => ua.subResource === SubResource.MODEL && ua.authority !== Authority.NONE && ua.projectId === projectId);
});

// DIR RIGHTS

function findDirRights(directoryId: EntityId, parentFolderIds: Array<EntityId>, currentUser: IUser, type: SubResource): IAuthorityBoolean {
  let foundWorkGroupIds: Array<EntityId> = [];
  Object.entries(currentUser.workGroupToFileMap).forEach((value) => {
    const [workGroupId, fileIdList] = value;
    fileIdList.forEach((fileId) => {
      if (parentFolderIds.includes(fileId)) {
        foundWorkGroupIds.push(workGroupId);
      }
    });
  });
  if (foundWorkGroupIds.length > 0) {
    let authority: IAuthorityBoolean = { isRead: false, isAdmin: false, isWrite: false };
    foundWorkGroupIds.forEach((workGroupId) => {
      // @ts-ignore
      const privilege = currentUser.privileges.WORK_GROUP[workGroupId][type];
      if (privilege) {
        if (!authority.isRead && privilege.isRead) {
          authority = privilege;
        }
        if (!authority.isWrite && privilege.isWrite) {
          authority = privilege;
        }
        if (!authority.isAdmin && privilege.isAdmin) {
          authority = privilege;
        }
      }
    });
    return authority;
  }
  return { isRead: false, isAdmin: false, isWrite: false } as IAuthorityBoolean;
}

export const selectDirectoryIdForPrivileges = (state: RootState, directoryId: EntityId) => directoryId;
export const selectDirectoryForPrivileges = (state: RootState, directoryId: EntityId, directory: IFolderFileEntity) => directory;
export const selectIsCurrentUserProjectAdminInDir = (state: RootState, directoryId: EntityId, directory: IFolderFileEntity, isAdmin?: boolean) => isAdmin;
export const selectDirectoryParentIds = (state: RootState, directoryId: EntityId, directory: IFolderFileEntity, isAdmin?: boolean, directoryParentIds?: Array<EntityId>) => directoryParentIds;

export const selectPrivilegesForDir = createSelector(
  [selectCurrentUser, selectParentDirs, selectDirectoryIdForPrivileges, selectDirectoryForPrivileges, selectIsCurrentUserProjectAdminInDir],
  (currentUser, parentFolders, directoryId, directory, isAdmin) => {
    if (isAdmin) return { isRead: true, isAdmin: true, isWrite: true } as IAuthorityBoolean;
    const type = directory.branch === FileEntityBranch.ROOT_DIR ? SubResource.FILE : directory.branch === FileEntityBranch.ROOT_DOCUMENT_DIR ? SubResource.DOCUMENT : SubResource.MODEL;
    const parentFolderIds = parentFolders.map((f) => f.id);
    if (!directory) {
      return { isRead: false, isAdmin: false, isWrite: false } as IAuthorityBoolean;
    }
    return findDirRights(directory.id, parentFolderIds, currentUser, type);
  }
);

export const selectPrivilegesForDirUsingParentDirIds = createSelector(
  [selectCurrentUser, selectDirectoryIdForPrivileges, selectDirectoryForPrivileges, selectIsCurrentUserProjectAdminInDir, selectDirectoryParentIds],
  (currentUser, directoryId, directory, isAdmin, directoryParentIds) => {
    if (isAdmin) return { isRead: true, isAdmin: true, isWrite: true } as IAuthorityBoolean;
    const type = directory.branch === FileEntityBranch.ROOT_DIR ? SubResource.FILE : directory.branch === FileEntityBranch.ROOT_DOCUMENT_DIR ? SubResource.DOCUMENT : SubResource.MODEL;
    if (!directory) {
      return { isRead: false, isAdmin: false, isWrite: false } as IAuthorityBoolean;
    }
    return findDirRights(directory.id, directoryParentIds || [], currentUser, type);
  }
);

export const selectPartyIdForPrivileges = (state: RootState, partyId: EntityId) => partyId;
export const selectActsPrivilegesForParty = createSelector([selectCurrentUser, selectPartyIdForPrivileges], (currentUser, partyId) => {
  return (
    // @ts-ignore
    (currentUser.privileges["WORK_GROUP"] && currentUser.privileges["WORK_GROUP"][partyId] && currentUser.privileges["WORK_GROUP"][partyId]["ACT"]) || { isRead: false, isWrite: false, isAdmin: false }
  );
});
export const selectContractPrivilegesForParty = createSelector([selectCurrentUser, selectPartyIdForPrivileges], (currentUser, partyId) => {
  return (
    // @ts-ignore
    (currentUser.privileges["WORK_GROUP"] && currentUser.privileges["WORK_GROUP"][partyId] && currentUser.privileges["WORK_GROUP"][partyId]["CONTRACT"]) || {
      isRead: false,
      isWrite: false,
      isAdmin: false
    }
  );
});
export const selectCurrentUserLanguageForTranslation = createSelector([selectCurrentUserLanguage], (currentUserLanguage) => {
  if (currentUserLanguage) {
    return currentUserLanguage.split("_")[0] || "et";
  }
  return "et";
});

export default userSlice.reducer;
