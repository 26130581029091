import React, { FC } from "react";
import { ITaskBoard } from "@/model/taskBoard/ITaskBoard";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareKanban } from "@fortawesome/pro-regular-svg-icons/faSquareKanban";
import ContactAuthoritiesDataSkeleton from "@/views/home/project/detail/users/contacts/contactRightsModal/ContactAuthoritiesDataSkeleton";

interface Props {
  isLoading: boolean;
  userTaskBoards: Array<ITaskBoard>;
}
const ContactAuthoritiesTaskboardCard: FC<Props> = ({ isLoading, userTaskBoards }) => {
  return (
    <div className="bh-bg-white mb-2 rounded p-6">
      <h3>
        <Trans>GLOBAL.TASKBOARDS</Trans>
      </h3>
      {isLoading ? (
        <ContactAuthoritiesDataSkeleton />
      ) : (
        userTaskBoards.map((taskBoard: ITaskBoard) => {
          return (
            <div key={taskBoard.id}>
              <span className="bh-text-pigeon mr-3 ">
                <FontAwesomeIcon icon={faSquareKanban} />
              </span>
              {taskBoard.name}
            </div>
          );
        })
      )}
    </div>
  );
};

export default ContactAuthoritiesTaskboardCard;
