import React, { FC, useEffect, useState } from "react";
import NewProjectDetails from "@/views/home/company/createProject/NewProjectDetails";
import { EntityId } from "@reduxjs/toolkit";
import NewProjectAdministrators from "@/views/home/company/createProject/NewProjectAdministrators";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import NewProjectTreeContainer from "@/views/home/company/createProject/newProjectFolderStructure/NewProjectProjectTreeContainer";
import NewProjectPredefinedParties from "@/views/home/company/createProject/newProjectPredefinedParties/NewProjectPredefinedParties";
import NewProjectBilling from "@/views/home/company/createProject/NewProjectBilling";
import BhProgressContainer from "@components/progress/BhProgressContainer";
import { INewProjectForm } from "@/model/INewProjectForm";
import { saveNewProject } from "@/api/companyAPI";
import { useNavigate } from "react-router-dom";
import { fetchCompanyAsync, selectCompanyById, selectCompanyStateStatus } from "@/app/store/companiesSlice";
import { SubResource } from "@/model/IUserAuthority";
import { BhProgressBulletStatus } from "@/model/utilities/BhProgressBulletStatus";
import { IProgressContainerStepState } from "@/model/tabs/IProgressContainerStepState";
import { fetchCurrentUserAsync, isCurrentUserSuperUser } from "@/app/store/userSlice";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import NewProjectFormBases from "@/views/home/company/createProject/NewProjectFormBases";

interface Props {
  companyId: EntityId;
}

const CreateProjectContainer: FC<Props> = ({ companyId }) => {
  const [steps, setSteps] = useState<Array<IProgressContainerStepState>>([] as Array<IProgressContainerStepState>);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const companyStatus = useAppSelector(selectCompanyStateStatus);
  const [newProjectForm, setNewProjectForm] = useState<INewProjectForm>({ country: company?.companyInfo.country } as INewProjectForm);
  const isSuperUser = useAppSelector(isCurrentUserSuperUser);
  const [confirmDisabled, setConfirmedDisabled] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const showBillingTab = isSuperUser || company?.billingSetupDone;

  const content = [
    <NewProjectDetails newProjectForm={newProjectForm} setNewProjectForm={setNewProjectForm} companyId={companyId} />,
    <NewProjectAdministrators newProjectForm={newProjectForm} setNewProjectForm={setNewProjectForm} companyId={companyId} />,
    <NewProjectTreeContainer newProjectForm={newProjectForm} setNewProjectForm={setNewProjectForm} companyId={companyId} />,
    <NewProjectPredefinedParties newProjectForm={newProjectForm} setNewProjectForm={setNewProjectForm} companyId={companyId} />,
    <NewProjectFormBases newProjectForm={newProjectForm} setNewProjectForm={setNewProjectForm} companyId={companyId} />,
    showBillingTab && <NewProjectBilling newProjectForm={newProjectForm} setNewProjectForm={setNewProjectForm} companyId={companyId} />
  ].filter(Boolean) as Array<React.ReactElement>;

  const stepsInitialState = [
    {
      id: 0,
      status: BhProgressBulletStatus.CURRENT,
      header: "GLOBAL.PROJECT_INFO",
      href: `details/`,
      validation: ["name", "country"],
      isDirty: true
    },
    {
      id: 1,
      status: BhProgressBulletStatus.UPCOMING,
      header: "PROJECT.NEW.ADMINS",
      href: `administrators/`,
      validation: ["relatedUsers"],
      isDirty: true
    },
    {
      id: 2,
      status: BhProgressBulletStatus.UPCOMING,
      header: "NEW_PROJECT.PROJECT_TREES",
      href: `tree/`,
      properties: ["drawingTemplateTree", "documentTemplateTree"],
      isDirty: false
    },
    {
      id: 3,
      status: BhProgressBulletStatus.UPCOMING,
      header: "PROJECT.PARTIES",
      href: `workgroups/`,
      properties: ["predefinedWorkGroups"],
      isDirty: false
    },
    {
      id: 4,
      status: BhProgressBulletStatus.UPCOMING,
      header: "COMPANY.SETTINGS.FORM_BASES",
      href: `formBases/`,
      properties: ["formBases"],
      isDirty: false
    },
    showBillingTab && {
      id: 5,
      status: BhProgressBulletStatus.UPCOMING,
      header: "MODAL.NEW_PROJECT.REQUISITES.TITLE",
      href: `billing/`,
      validation: ["billing.customerRecipientEmails", "billing.customerRegCode", "billing.customerRecipient"],
      isDirty: true
    }
  ].filter(Boolean) as Array<IProgressContainerStepState>;

  useEffect(() => {
    dispatch(fetchCompanyAsync(companyId));
  }, []);

  useEffect(() => {
    if (company && company.users) {
      setNewProjectForm({ ...newProjectForm, relatedUsers: [...company.users.filter((user) => user.subResource === SubResource.NEW_PROJECT_DEFAULT_ADMIN)] });
    }
    setSteps(stepsInitialState);
  }, [company]);

  if (!company) return null;

  const onSubmit = () => {
    setConfirmedDisabled(true);
    saveNewProject({ ...newProjectForm, companyId: companyId })
      .then(() => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/`))
      .catch((reason) => console.log("Error", reason))
      .finally(() => {
        setConfirmedDisabled(false);
        dispatch(fetchCurrentUserAsync());
      });
  };

  return (
    <div className="flex h-full w-full">
      {companyStatus === BhStateStatusType.SUCCESS && steps && (
        <BhProgressContainer
          onCancel={() => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/`)}
          steps={steps}
          setSteps={setSteps}
          header="MODAL.NEW_PROJECT.HEADER"
          form={newProjectForm}
          onSubmit={onSubmit}
          disabled={confirmDisabled}
          content={content}
          finalizeButtonTitle="NEW_PROJECT.CONFIRMATION_BUTTON_TITLE"
        />
      )}
    </div>
  );
};

export default CreateProjectContainer;
