import React, { Dispatch, useEffect, useState } from "react";
// @ts-ignore
import { Entity, FaceAlignedSectionPlanesPlugin, math, PickResult, SectionPlanesPlugin, Viewer } from "@xeokit/xeokit-sdk";
import { BauhubXeokitTool } from "@/views/home/project/detail/xeokit/XeokitWebViewer";
import { classNames } from "@/utilities/jsUtilities";
import { faAngle90 } from "@fortawesome/pro-solid-svg-icons/faAngle90";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScissors } from "@fortawesome/pro-solid-svg-icons/faScissors";
import BhCloseButton from "@components/buttons/BhCloseButton";
import { useTranslation } from "react-i18next";
import { faHandBackPointUp } from "@fortawesome/pro-solid-svg-icons/faHandBackPointUp";
import { ReactComponent as BhOldMouse } from "@svg/old-mouse.svg";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUserLanguageForTranslation } from "@/app/store/userSlice";

interface Props {
  onModelsLoaded: boolean;
  isMobile?: boolean;
  bauhubToolActive: BauhubXeokitTool;
  setBauhubToolActive: Dispatch<BauhubXeokitTool>;
  sectionToolProps?: { sections: number };
  setSectionToolProps: Dispatch<{ sections: number }>;
  viewer: Viewer;
  sectionPlanesPluginObject?: SectionPlanesPlugin;
  setSectionPlanesPluginObject: Dispatch<SectionPlanesPlugin>;
}

const XeokitSectionTool = React.memo<Props>(
  ({ onModelsLoaded, bauhubToolActive, isMobile, setBauhubToolActive, sectionToolProps, setSectionToolProps, viewer, sectionPlanesPluginObject, setSectionPlanesPluginObject }) => {
    const { t } = useTranslation();
    const currentUserLanguage = useAppSelector(selectCurrentUserLanguageForTranslation);
    const [showControl, setShowControl] = useState(false);

    useEffect(() => {
      // const sectionPlanesObject = new SectionPlanesPlugin(viewer, {});
      const sectionPlanesObject = new FaceAlignedSectionPlanesPlugin(viewer, {
        controlElementId: "sectionPlaneControl",
        dragSensitivity: 1
      });
      setSectionPlanesPluginObject(sectionPlanesObject);

      viewer.on("reset", () => {
        setSectionToolProps({ sections: 0 });
        sectionPlanesObject.clear();
        setShowControl(false);
      });

      // @ts-ignore
      const sectionPlaneCreated = viewer.scene.on("sectionPlaneCreated", () => {
        setShowControl(true);
      });

      // @ts-ignore
      const sectionPlaneEdit = viewer.scene.on("sectionPlaneEdit", () => {
        setShowControl(true);
      });

      return function cleanup() {
        viewer.scene.off(sectionPlaneCreated);
        viewer.scene.off(sectionPlaneEdit);
      };
    }, []);

    const mouseClickedCallback = (coords: Array<number>) => {
      if (!sectionPlanesPluginObject) return;
      //------------------------------------------------------------------------------------------------------------------
      // Use the AnnotationsPlugin to create an annotation wherever we click on an object
      //------------------------------------------------------------------------------------------------------------------

      if (bauhubToolActive !== BauhubXeokitTool.SECTION) return;

      const pickResult = viewer.scene.pick({
        canvasPos: coords,
        pickSurface: true // <<------ This causes picking to find the intersection point on the entity
      });

      if (pickResult && pickResult.worldNormal) {
        // Disallow SectionPlanes on point clouds, because points don't have normals

        const sectionPlane = sectionPlanesPluginObject.createSectionPlane({
          pos: pickResult.worldPos,
          dir: math.mulVec3Scalar(pickResult.worldNormal, -1)
        });

        sectionPlane.on("destroyed", () => {
          setShowControl(false);
        });

        sectionPlanesPluginObject.showControl(sectionPlane.id);

        const planesLength = Object.keys(sectionPlanesPluginObject.sectionPlanes).length;
        setSectionToolProps({ sections: planesLength });
      }
    };

    const createSectionCallback = (hit: PickResult) => {
      if (!sectionPlanesPluginObject) return;

      const pickResult = viewer.scene.pick({
        canvasPos: [hit.canvasPos[0], hit.canvasPos[1]],
        pickSurface: true // <<------ This causes picking to find the intersection point on the entity
      });

      if (pickResult && pickResult.worldNormal) {
        // Disallow SectionPlanes on point clouds, because points don't have normals

        const sectionPlane = sectionPlanesPluginObject.createSectionPlane({
          pos: pickResult.worldPos,
          dir: math.mulVec3Scalar(pickResult.worldNormal, -1)
        });

        sectionPlane.on("destroyed", () => {
          setShowControl(false);
        });

        sectionPlanesPluginObject.showControl(sectionPlane.id);

        const planesLength = Object.keys(sectionPlanesPluginObject.sectionPlanes).length;
        setSectionToolProps({ sections: planesLength });
      }
    };

    const flipSectionPlane = () => {
      if (!sectionPlanesPluginObject) return;
      const currentSection = sectionPlanesPluginObject.getShownControl();
      const sectionPlane = sectionPlanesPluginObject.sectionPlanes[currentSection];

      if (!sectionPlane) return;
      sectionPlane.dir = sectionPlane.dir.map((_, i, a) => a[(i + 1) % a.length]);
    };

    useEffect(() => {
      if (!sectionPlanesPluginObject) return;
      if (bauhubToolActive !== BauhubXeokitTool.SECTION) {
        sectionPlanesPluginObject.hideControl();
        setShowControl(false);
      }

      if (isMobile) return;

      const id = bauhubToolActive === BauhubXeokitTool.SECTION ? viewer.scene.input.on("mouseclicked", mouseClickedCallback) : undefined;

      return () => {
        if (id) {
          viewer.scene.input.off(id);
        }
      };
    }, [bauhubToolActive]);

    useEffect(() => {
      if (!sectionPlanesPluginObject) return;
      if (!isMobile) return;

      // @ts-ignore
      const id = viewer.scene.on("createSectionPlaneForEntityId", createSectionCallback);

      return () => {
        if (id) {
          viewer.scene.off(id);
        }
      };
    }, [onModelsLoaded]);

    const closeEditSection = () => {
      if (!sectionPlanesPluginObject) return;

      setBauhubToolActive(BauhubXeokitTool.SELECT);
      sectionPlanesPluginObject.hideControl();
      setShowControl(false);
    };

    const layoutDivPositionClasses = !isMobile ? "right-10 top-10 w-44" : "right-4 bottom-28 w-24";

    return (
      <div className={classNames(!showControl && "hidden", layoutDivPositionClasses, "bh-bg-white bh-shadow absolute z-40 flex h-full max-h-[380px] flex-col gap-y-2 rounded-lg border p-2")}>
        <div className="absolute -right-3 -top-3 z-20">
          <BhCloseButton buttonProps={{ onClick: closeEditSection }} />
        </div>
        <div
          className={classNames(
            isMobile ? "margin-x-0 px-1" : "margin-x-2 px-4",
            "margin-y-2 bh-caret-xeokit-pink bh-drag-bg bh-border-pigeon-20 cancel-animation-on-hover relative flex flex-1 cursor-ns-resize select-none flex-col rounded border py-4 text-center"
          )}
        >
          <div id="sectionPlaneControl" className="bh-bg-error-red absolute top-0 left-0 z-10 h-full w-full opacity-0"></div>
          <div className="flex flex-row items-center justify-center">
            <div className="bh-bg-xeokit-pink flex h-8 w-8 items-center justify-center rounded-full">
              <FontAwesomeIcon className="bh-text-white" icon={faScissors} />
            </div>
          </div>
          <div className="bh-text-deep-ocean-80 py-2">{isMobile ? t("BIM.SLICE.SLIDE_BODY_MOBILE", { lng: currentUserLanguage }) : t("BIM.SLICE.SLIDE_BODY")}</div>
          <div className="relative flex flex-row justify-center py-4">
            <div className="slice-mouse-icon-move absolute top-4">{isMobile ? <FontAwesomeIcon icon={faHandBackPointUp} className="bh-text-pigeon-50 -rotate-30 h-8 w-8" /> : <BhOldMouse />}</div>
          </div>
        </div>
        <div className="flex flex-row justify-center">
          <BhTextOnlyButton icon={faAngle90} buttonProps={{ onClick: flipSectionPlane }}>
            {isMobile ? t("BIM.SLICE.AXIS_MOBILE", { lng: currentUserLanguage }) : t("BIM.SLICE.AXIS")}
          </BhTextOnlyButton>
        </div>
      </div>
    );
  }
);

export default XeokitSectionTool;
