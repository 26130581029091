import React, { FC, startTransition, useCallback, useEffect, useRef } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  initialValue: any;
  property: string;
  saveCallback?: Function;
  required?: boolean;
  placeholder?: string;
  inputClasses?: string;
  disabled?: boolean;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  initialRows?: number;
}

const BhInlineInput: FC<Props> = ({ property, initialValue, required, saveCallback, placeholder, inputClasses, disabled, inputRef, initialRows }) => {
  let containedInputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    let inputRefPropOrContained = inputRef || containedInputRef;
    if (inputRefPropOrContained.current && initialValue !== undefined) {
      inputRefPropOrContained.current.value = initialValue;
      calculateHeight(inputRefPropOrContained);
    }
  });

  const onChange = useCallback((e: React.FocusEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    startTransition(() => {
      const inputValue = e.target.value;
      const initialValueString = initialValue || "";
      if (inputValue === initialValueString) return;
      let inputRefPropOrContained = inputRef || containedInputRef;
      calculateHeight(inputRefPropOrContained);
    });
  }, []);

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      e.preventDefault();
      const inputValue = e.target.value;
      const initialValueString = initialValue || "";
      if (inputValue === initialValueString) return;
      const changedValue = { [property]: e.target.value };
      saveCallback && saveCallback(changedValue);
    },
    [saveCallback]
  );

  const calculateHeight = (element: React.RefObject<HTMLTextAreaElement>) => {
    if (element && element.current) {
      element.current.style.height = "inherit";
      element.current.style.height = `${element.current.scrollHeight}px`;
    }
  };

  return (
    <textarea
      ref={inputRef || containedInputRef}
      name={property}
      onChange={onChange}
      className={classNames("bh-text-deep-ocean hover:bh-border-pigeon-70 focus:bh-border-pigeon-70 bh-border-white w-full resize-none rounded p-2 hover:border", inputClasses)}
      placeholder={placeholder || ""}
      disabled={disabled}
      autoComplete="off"
      onBlur={onBlur}
      rows={initialRows || 2}
    />
  );
};

export default React.memo(BhInlineInput);
