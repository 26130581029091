import React, { FC, useState } from "react";
import { IWorkGroup } from "@/model/IWorkGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { getUniqueValues } from "@/utilities/jsUtilities";
import { faCaretUp } from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import TaskBoardSettingsWorkGroupUser from "@components/taskBoard/modals/TaskBoardSettingsWorkGroupUser";
import BhStackedUserIcons from "@components/user/BhStackedUserIcons";
import { ITaskBoard } from "@/model/taskBoard/ITaskBoard";
import { IWorkGroupToTaskBoard } from "@/model/taskBoard/IWorkGroupToTaskBoard";
import { useAppDispatch } from "@/app/hooks";
import {
  addUsersToTaskBoard,
  removeUserFromTaskBoardAsync,
  removeUsersFromTaskBoard,
  removeWorkGroupFromTaskBoardAsync,
  saveUserToTaskBoardAsync,
  saveWorkGroupToTaskBoardAsync
} from "@/app/store/taskBoardsSlice";
import { ITaskBoardUserDTO } from "@/model/taskBoard/ITaskBoardUserDTO";

interface Props {
  workGroup: IWorkGroup;
  taskBoard: ITaskBoard;
  setTaskBoard: Function;
}

const TaskBoardSettingsWorkGroup: FC<Props> = ({ workGroup, taskBoard, setTaskBoard }) => {
  const dispatch = useAppDispatch();
  const [showUsers, setShowUsers] = useState(false);
  const users = getUniqueValues(workGroup.users, "userEntityId");
  const isNewTaskBoard = !taskBoard.id;
  const getNewTaskBoardUsers = () => {
    const newTaskBoardUsers = users.filter((user) => {
      return taskBoard.workGroupToTaskBoards
        .filter((wgTb) => wgTb.workGroupId === workGroup.id)
        .flatMap((wgTb) => wgTb.includedUserIds)
        .some((id) => {
          return id === user.userEntityId;
        });
    });
    return newTaskBoardUsers.length > 0 ? newTaskBoardUsers : [];
  };
  const getExistingTaskBoardUsers = () => {
    const existingTaskBoardUsers = users.filter((user) => {
      return taskBoard.users.some((u) => {
        return u.userEntityId === user.userEntityId;
      });
    });
    return existingTaskBoardUsers.length > 0 ? existingTaskBoardUsers : [];
  };
  const selectedUsers = isNewTaskBoard ? getNewTaskBoardUsers() : getExistingTaskBoardUsers();
  const isWorkGroupSelected = taskBoard.workGroupToTaskBoards.some((wgTb) => {
    return wgTb.workGroupId === workGroup.id;
  });

  const selectWorkGroup = () => {
    if (isNewTaskBoard) {
      if (!isWorkGroupSelected) {
        const wgTb = { workGroupId: workGroup.id, includedUserIds: users.map((u) => u.userEntityId) } as IWorkGroupToTaskBoard;
        setTaskBoard({ ...taskBoard, ...{ workGroupToTaskBoards: [...taskBoard.workGroupToTaskBoards, wgTb] } });
      } else {
        setTaskBoard({ ...taskBoard, ...{ workGroupToTaskBoards: taskBoard.workGroupToTaskBoards.filter((wgTb) => wgTb.workGroupId !== workGroup.id) } });
      }
    } else {
      const dto = { taskBoardId: taskBoard.id, workGroupId: workGroup.id } as ITaskBoardUserDTO;
      if (!isWorkGroupSelected) {
        dispatch(saveWorkGroupToTaskBoardAsync(dto));
        dispatch(addUsersToTaskBoard({ users: users, taskBoardId: taskBoard.id }));
      } else {
        dispatch(removeWorkGroupFromTaskBoardAsync(dto));
        dispatch(removeUsersFromTaskBoard({ users: users, taskBoardId: taskBoard.id }));
      }
    }
  };

  const isUserSelected = (user: ISimpleValidUserAuthority) => {
    return selectedUsers.some((u) => {
      return u.userEntityId === user.userEntityId;
    });
  };

  const selectUser = (user: ISimpleValidUserAuthority) => {
    if (isNewTaskBoard) {
      const wgTbs = taskBoard.workGroupToTaskBoards.filter((wgTb) => wgTb.workGroupId !== workGroup.id);
      const includedUsers = isUserSelected(user)
        ? selectedUsers.filter((u) => user.userEntityId !== u.userEntityId).map((u) => u.userEntityId)
        : [...selectedUsers.map((u) => u.userEntityId), user.userEntityId];
      const wgTbToSave = {
        workGroupId: workGroup.id,
        includedUserIds: includedUsers
      } as IWorkGroupToTaskBoard;
      setTaskBoard({ ...taskBoard, ...{ workGroupToTaskBoards: [...wgTbs, wgTbToSave] } });
    } else {
      const dto = { taskBoardId: taskBoard.id, workGroupId: workGroup.id, userIds: [user.userEntityId] } as ITaskBoardUserDTO;
      if (!isUserSelected(user)) {
        dispatch(saveUserToTaskBoardAsync(dto));
        dispatch(addUsersToTaskBoard({ users: [user], taskBoardId: taskBoard.id }));
      } else {
        dispatch(removeUserFromTaskBoardAsync(dto));
        dispatch(removeUsersFromTaskBoard({ users: [user], taskBoardId: taskBoard.id }));
      }
    }
  };

  return (
    <div className="flex flex-col">
      <div className="bh-border-pigeon-40 h-13 flex flex-row items-center border-t py-3 pl-1 pr-6">
        <input className="h-4.5 w-4.5 rounded focus:ring-0 focus:ring-offset-0" type="checkbox" checked={isWorkGroupSelected} onChange={() => selectWorkGroup()} />
        <div className="ml-3 flex w-full cursor-pointer flex-row items-center" onClick={() => setShowUsers(!showUsers)}>
          <div className="w-full flex-1">{workGroup.name}</div>
          <div className="w-56 pl-5 font-bold">
            <BhStackedUserIcons users={selectedUsers} />
          </div>
          <FontAwesomeIcon icon={showUsers ? faCaretUp : faCaretDown} />
        </div>
      </div>
      {showUsers && users.length > 0 && (
        <div className="pb-2">
          {users.map((user) => (
            <TaskBoardSettingsWorkGroupUser isChecked={isUserSelected(user)} onChange={selectUser} user={user} key={user.id} />
          ))}
        </div>
      )}
    </div>
  );
};

export default TaskBoardSettingsWorkGroup;
