import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import BhLightSeparator from "@components/separator/BhLightSeparator";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import { IUser } from "@/model/IUser";
import { INewProjectForm } from "@/model/INewProjectForm";
import { EntityId } from "@reduxjs/toolkit";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { Trans, useTranslation } from "react-i18next";
import NewProjectAdminInput from "@/views/home/company/createProject/NewProjectAdminInput";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { SubResource } from "@/model/IUserAuthority";

interface Props {
  newProjectForm: INewProjectForm;
  setNewProjectForm: Dispatch<SetStateAction<INewProjectForm>>;
  companyId: EntityId;
}

const NewProjectAdministrators: FC<Props> = ({ newProjectForm, setNewProjectForm, companyId }) => {
  const { t } = useTranslation();
  const [relatedUsers, setRelatedUsers] = useState<Array<ISimpleValidUserAuthority>>([]);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const companyRelatedUsers = (company && company.projects?.flatMap((project) => project.relatedUsers)) || [];

  useEffect(() => {
    const uniqueAdministrators = companyRelatedUsers.filter((admin, index, self) => {
      return index === self.findIndex((adm) => adm.userEntityId === admin.userEntityId);
    });
    const notAddedByDefaultAdmins = uniqueAdministrators.filter((user) => !newProjectForm.relatedUsers.some((u) => u.username === user.username));
    setRelatedUsers(notAddedByDefaultAdmins);
  }, []);

  const { relatedUsers: selectedProjectAdmins } = newProjectForm;

  const onAdminRemove = (selectedAdmin: ISimpleValidUserAuthority) => {
    const isRelatedUser = companyRelatedUsers.some((user) => user.username === selectedAdmin.username);
    const isDefaultAdmin = selectedAdmin.subResource === SubResource.ACCOUNT_MANAGER || selectedAdmin.subResource === SubResource.NEW_PROJECT_DEFAULT_ADMIN;
    if (isRelatedUser || isDefaultAdmin) {
      setRelatedUsers([...relatedUsers, selectedAdmin]);
    }
    setNewProjectForm({ ...newProjectForm, relatedUsers: selectedProjectAdmins.filter((admin) => admin.username !== selectedAdmin.username) });
  };

  return (
    <>
      <div className="w-2/3">
        <h2 className="pb-6">
          <Trans>PROJECT.NEW.ADMINS</Trans>
        </h2>
        <p>
          <Trans>COMPANY.SETTINGS.NEW_PROJECT_ADMINS.INFO</Trans>
        </p>
        <div className="mt-6 flex flex-col">
          <div className="w-1/2">
            <NewProjectAdminInput companyId={companyId} newProjectForm={newProjectForm} setNewProjectForm={setNewProjectForm} users={relatedUsers} setUsers={setRelatedUsers} />
          </div>
          <div className="mt-10">
            {selectedProjectAdmins?.length > 0 && (
              <p className="bh-text-deep-ocean-80 mb-2 font-semibold">
                <Trans>GLOBAL.PROJECT_ADMIN_USERS</Trans>
              </p>
            )}
            {selectedProjectAdmins.map((admin: ISimpleValidUserAuthority) => {
              return (
                <div key={admin.userEntityId || admin.username}>
                  <BhLightSeparator />
                  <div className="h-13 mx-3 flex items-center justify-between ">
                    <BhUserIconWithName user={{ username: admin.username, firstName: admin.firstName, lastName: admin.lastName } as IUser} showUsername={true} />
                    <BhTextOnlyButton buttonProps={{ onClick: () => onAdminRemove(admin) }} icon={faCircleMinus}>
                      {t("WORKGROUP.NEW.REMOVE_USER")}
                    </BhTextOnlyButton>
                  </div>
                </div>
              );
            })}
            {selectedProjectAdmins.length > 0 && <BhLightSeparator />}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewProjectAdministrators;
