import React, { FC, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import BhIconButton from "@components/buttons/BhIconButton";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons/faAngleRight";
import CompanyFormBasesHeader from "@components/form/settings/companySettings/CompanyFormBasesHeader";
import CompanySettingsFormTable from "@components/form/settings/companySettings/CompanySettingsFormTable";
import FormBuilderEuFundingFooter from "@components/form/settings/FormBuilderEuFundingFooter";

interface Props {
  companyId: EntityId;
}

const CompanyFormSettingsTablesContainer: FC<Props> = ({ companyId }) => {
  const { t } = useTranslation();
  const [companyFormsTableVisible, setCompanyFormsTableVisible] = useState(true);
  const [projectFormTableVisible, setProjectFormTableVisible] = useState(true);
  const [companyBasesFilter, setCompanyBasesFilter] = useState({ searchString: "", username: "", orderBy: "type", reversed: false });
  const [projectBasesFilter, setProjectBasesFilter] = useState({ searchString: "", username: "", orderBy: "type", reversed: false });

  return (
    <div className="flex flex-col px-16 pt-6 lg:px-24 md:px-8">
      <div>
        <div className="flex flex-row items-center gap-x-1">
          <BhIconButton icon={companyFormsTableVisible ? faAngleDown : faAngleRight} buttonProps={{ onClick: () => setCompanyFormsTableVisible(!companyFormsTableVisible) }} />
          <h2 className="m-0">{t("COMPANY.SETTINGS.COMPANY_FORM_BASES")}</h2>
        </div>
        {companyFormsTableVisible && (
          <div className="mx-auto flex h-full w-full flex-col">
            <CompanyFormBasesHeader filter={companyBasesFilter} setFilter={setCompanyBasesFilter} companyId={companyId} isProjectFormTableHeader={false} />
            <CompanySettingsFormTable filter={companyBasesFilter} setFilter={setCompanyBasesFilter} companyId={companyId} isProjectFormTable={false} />
          </div>
        )}
      </div>
      <div className="mb-12 mt-10">
        <div className="flex flex-row items-center gap-x-1">
          <BhIconButton icon={projectFormTableVisible ? faAngleDown : faAngleRight} buttonProps={{ onClick: () => setProjectFormTableVisible(!projectFormTableVisible) }} />
          <h2 className="m-0">{t("COMPANY.SETTINGS.PROJECT_FORM_BASES")}</h2>
        </div>
        <div className="bh-text-deep-ocean-80 my-3 w-3/5" dangerouslySetInnerHTML={{ __html: t("COMPANY.SETTINGS.PROJECT_FORM_BASES.TEXT") as any }} />
        {projectFormTableVisible && (
          <div className="mx-auto flex h-full w-full flex-col">
            <CompanyFormBasesHeader filter={projectBasesFilter} setFilter={setProjectBasesFilter} companyId={companyId} isProjectFormTableHeader={true} />
            <CompanySettingsFormTable filter={projectBasesFilter} setFilter={setProjectBasesFilter} companyId={companyId} isProjectFormTable={true} />
          </div>
        )}
        <FormBuilderEuFundingFooter />
      </div>
    </div>
  );
};

export default CompanyFormSettingsTablesContainer;
