import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SyncProjectListContainer from "../SyncProjectListContainer";

const SyncStep1Container: FC = () => {
    const { t } = useTranslation();

    useEffect(() => {
    }, []);

    return (
        <div className="h-full w-full overflow-auto">
            <div className="pl-16">
                <h1>{t("SYNC.STEP_1_TITLE")}</h1>
                <p className="bh-text-18">{t("SYNC.STEP_1_SUBTEXT")}</p>
            </div>
            <div className="overflow-hidden">
                <SyncProjectListContainer />
            </div>
        </div>
    );
};

export default SyncStep1Container;
