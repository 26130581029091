import { useAppSelector } from "@/app/hooks";
import React, { FC } from "react";
import { faFolder } from "@fortawesome/pro-regular-svg-icons/faFolder";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import noFilesImg from "@img/no_bim_files.png";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { selectHasBimrights } from "@/app/store/userSlice";

interface Props {}

const XeokitNoFiles: FC<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const projectId = useAppSelector(selectCurrentProjectId);
  const hasBimRights = useAppSelector((state) => selectHasBimrights(state, projectId));

  return (
    <div className="flex w-full flex-col items-center pt-32">
      <img src={noFilesImg} className="m-8 w-full max-w-[350px]" alt="No files" />
      <div>
        <div className="text-center">{!hasBimRights ? t("BIM.NO_PRIVILEGES") : t("BIM.NO_FILES")}</div>
        {hasBimRights && (
          <div className="flex w-full flex-row items-center justify-center pt-5">
            <BhPrimaryButton icon={faFolder} buttonProps={{ onClick: () => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/bim/dir`) }}>
              {t("BIM.FILES")}
            </BhPrimaryButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default XeokitNoFiles;
