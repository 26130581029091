import { ICompany } from "@/model/ICompany";
import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { EntityId } from "@reduxjs/toolkit";
import { INewProjectForm } from "@/model/INewProjectForm";
import { IUserAuthority } from "@/model/IUserAuthority";
import { IProject } from "@/model/IProject";
import { ICompanyInfo } from "@/model/iCompanyInfo";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import { ICompanyInvitedUser } from "@/model/companyUsers/ICompanyInvitedUser";
import { ICompanyAdministratorChangeRequest } from "@/model/ICompanyAdministratorChangeRequest";
import { ICompanyUserActionResponse } from "@/model/companyUsers/ICompanyUserActionResponse";
import { IPredefinedWorkGroup } from "@/model/IPredefinedWorkGroup";

export function fetchCompany(companyId: EntityId): Promise<ICompany> {
  return bauhubGet("/company/" + companyId + "/allprojects");
}

export function fetchAllCompanies(): Promise<Array<ICompany>> {
  return bauhubGet("/company/list");
}

export function saveCompany(company: ICompany): Promise<ICompany> {
  return bauhubPost("/company", company);
}

export function fetchNewProjectDefaultAdmins(companyId: EntityId): Promise<Array<IUserAuthority>> {
  return bauhubGet("/company/" + companyId + "/new/project/default/admins");
}

export function fetchNewProjectDefaultParties(companyId: EntityId): Promise<Array<IPredefinedWorkGroup>> {
  return bauhubGet("/company/" + companyId + "/workgroup/predefined/list");
}

export function saveCompanyInfo(companyId: EntityId, companyInfo: ICompanyInfo): Promise<ICompanyInfo> {
  return bauhubPost("/company/" + companyId + "/info", companyInfo);
}

export function saveNcnContractEmail(companyId: EntityId, contractEmail: string): Promise<ICompanyInfo> {
  return bauhubPost("/company/" + companyId + "/ncn/contract/email", { value: contractEmail });
}

export function saveNewProject(newProjectForm: INewProjectForm): Promise<IProject> {
  return bauhubPost("/project", newProjectForm);
}

export function fetchCompanyUsers(companyId: EntityId): Promise<Array<ICompanyUser>> {
  return bauhubGet("/company/" + companyId + "/users");
}

export function addCompanyAdministrator(companyId: EntityId, request: ICompanyAdministratorChangeRequest): Promise<ICompanyInvitedUser | ICompanyUser> {
  return bauhubPost("/company/" + companyId + "/administrator", request);
}

export function changeCompanyAdministrator(companyId: EntityId, adminUser: ICompanyUser, request: ICompanyAdministratorChangeRequest): Promise<ICompanyUser> {
  return bauhubPost("/company/" + companyId + "/administrator/" + adminUser.userEntityId + "/change", request);
}

export function changeCompanyAdminUserInvite(companyId: EntityId, request: ICompanyAdministratorChangeRequest): Promise<ICompanyInvitedUser> {
  return bauhubPost("/company/" + companyId + "/administrator/username/" + request.username + "/invites/change", request);
}

export function removeCompanyAdministratorPrivileges(companyId: EntityId, userId: EntityId): Promise<Array<ICompanyUser>> {
  return bauhubPost("/company/" + companyId + "/administrator/" + userId + "/remove");
}

export function removeUserFromCompany(companyUser: ICompanyUser): Promise<ICompanyUserActionResponse> {
  return bauhubPost("/company/" + companyUser.companyId + "/user/" + companyUser.userEntityId + "/remove");
}

export function fetchCompanyInvitedUsers(companyId: EntityId): Promise<Array<ICompanyInvitedUser>> {
  return bauhubGet("/invite/company/" + companyId);
}

export function removeUserInvites(companyUser: ICompanyInvitedUser): Promise<ICompanyUserActionResponse> {
  return bauhubPost("/company/" + companyUser.companyId + "/username/" + companyUser.username + "/invites/delete");
}

export function removeCompanyAdminInvite(companyUser: ICompanyInvitedUser): Promise<ICompanyUserActionResponse> {
  return bauhubPost("/company/" + companyUser.companyId + "/administrator/username/" + companyUser.username + "/invites/delete");
}
