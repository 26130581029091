import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import BhSeparatorMarginless from "@components/separator/BhSeparatorMarginless";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import BhFileCardDateWithName from "@components/cards/BhFileCardDateWithName";
import { IFileEntity } from "@/model/files/IFileEntity";

interface Props {
  fileEntity: IFileEntity;
  isLatestVersion: boolean;
}

const NewVersionModalFileCard: FC<Props> = ({ isLatestVersion, fileEntity }) => {
  return (
    <div className={classNames(!isLatestVersion && "pl-5", isLatestVersion && "mt-4")}>
      <BhSeparatorMarginless />
      <div className={classNames(!isLatestVersion && "bh-bg-smoke-50", "flex w-full flex-row items-center justify-between p-2")}>
        <div className="flex flex-row items-center">
          <div className="mr-2">
            <BhFileCardIcon fileEntity={fileEntity} fileCardSize={FileCardSize.LARGE} />
          </div>
          <div className="bh-text-deep-ocean">{fileEntity.name}</div>
        </div>
        <div className="flex flex-row items-center">
          <div className={classNames(isLatestVersion && "bh-bg-smoke", "mr-16 flex flex-row items-center rounded py-1 px-3")}>
            <span className="mr-2">{fileEntity.revision}</span>
            <FontAwesomeIcon className={classNames(isLatestVersion ? "opacity-100" : "opacity-0", "grow-0")} icon={faCaretUp} />
          </div>
          <BhFileCardDateWithName fileDate={fileEntity.created} fullName={fileEntity.createdByFullName} />
        </div>
      </div>
    </div>
  );
};

export default NewVersionModalFileCard;
