import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TaskInfoFieldLabel from "@components/task/modal/TaskInfoFieldLabel";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  text: string;
  valueExists: boolean;
  children?: React.ReactNode;
  icon?: IconDefinition;
  trailingIcon?: IconDefinition;
  isActive?: boolean;
  disabled?: boolean;
}

const TaskInfoField: FC<Props> = ({ children, icon, trailingIcon, text, valueExists, isActive, disabled }) => {
  return (
    <div
      className={classNames(
        "bh-border-pigeon-20 min-h-14 flex w-full flex-row items-center justify-between border-b pl-4",
        valueExists ? "pr-7" : "pr-6",
        isActive && "bh-bg-pigeon-20 hover:bh-bg-pigeon-20",
        !isActive && !disabled && "hover:bh-bg-smoke",
        !disabled && "cursor-pointer"
      )}
    >
      <div className="flex w-full flex-row items-center overflow-y-auto">
        <TaskInfoFieldLabel icon={icon} text={text} />
        {children}
      </div>
      {trailingIcon && !disabled && <FontAwesomeIcon icon={trailingIcon} className="bh-text-deep-ocean-80 pl-3" size={valueExists ? "1x" : "lg"} />}
    </div>
  );
};

export default TaskInfoField;
