import { useEffect, useRef } from "react";

export const useOuterClick = (callback: Function) => {
  const callbackRef = useRef(null); // initialize mutable ref, which stores callback
  const innerRef = useRef(null); // returned to client, who marks "border" element

  useRef<HTMLTextAreaElement | null>();

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    // @ts-ignore
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e: { target: any }) {
      // @ts-ignore
      if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) callbackRef.current(e);
    }
  }, []); // no dependencies -> stable click listener

  return innerRef; // convenience for client (doesn't need to init ref himself)
};
