import React, { FC, ReactElement } from "react";
import { BauhubPlan } from "@/model/IProject";
import BhProPlanTag from "@components/tags/BhProPlanTag";
import BhLightPlanTag from "@components/tags/BhLightPlanTag";

interface Props {
  plan: BauhubPlan;
}

const BhBOCompanyPlanTag: FC<Props> = ({ plan }) => {

  const planTagMap: Record<BauhubPlan, ReactElement> = {
    [BauhubPlan.PRO]: <BhProPlanTag />,
    [BauhubPlan.LIGHT]: <BhLightPlanTag />
  };

  if (!plan) return null;

  return planTagMap[plan];
};

export default BhBOCompanyPlanTag;
