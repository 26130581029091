import React, { FC } from "react";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import BhTableHeader from "@components/table/BhTableHeader";
import BOCompanyProjectAdministratorRow from "@components/backoffice/company/projects/BOCompanyProjectAdministratorRow";

interface Props {
  projectAdministrators: Array<ISimpleValidUserAuthority>;
}

const BOCompanyProjectAdministratorsTable: FC<Props> = ({ projectAdministrators }) => {
  const tableColumnHeaders: Array<IBhTableHeader<ISimpleValidUserAuthority>> = [
    { id: 0, content: "Name" },
    { id: 1, content: "Email" },
    { id: 2, content: "Phone" },
    { id: 3, content: "Occupation" }
  ];

  return (
    <div>
      <table className="w-full">
        <BhTableHeader columns={tableColumnHeaders} sortedBy="name" />
        <tbody>
          {projectAdministrators?.map((admin) => (
            <BOCompanyProjectAdministratorRow key={admin.userEntityId} admin={admin} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BOCompanyProjectAdministratorsTable;
