import React, { FC } from "react";
import BhModalFooter from "@components/modal/BhModalFooter";
import { BauhubPlan } from "@/model/IProject";
import BhModal from "@components/modal/BhModal";
import { IUpgradePlanInfo } from "@/model/billing/IUpgradePlanInfo";
import { IDowngradePlanInfo } from "@/model/billing/IDowngradePlanInfo";

interface Props {
  selectedPlan: BauhubPlan;
  newPlanInfo: IUpgradePlanInfo | IDowngradePlanInfo;
  setIsShown: (value: boolean) => void;
  onConfirm: Function;
}

const BOCompanyChangePlan: FC<Props> = ({ selectedPlan, newPlanInfo, setIsShown, onConfirm }) => {
  return (
    <BhModal
      setIsShown={setIsShown}
      header={<h2>{selectedPlan} plan</h2>}
      footer={<BhModalFooter confirmButtonText="Vali" onConfirm={onConfirm} cancelButtonText="Katkesta" onCancel={() => setIsShown(false)} />}
    >
      <div className="p-8">
        <p>Affected projects: {newPlanInfo?.projects?.length}</p>
        {selectedPlan === BauhubPlan.LIGHT &&
          newPlanInfo?.projects?.map((project: any) => (
            <p>{`${project.projectName}, original end date ${project.originalEndDate}, plan is valid until ${project.newEndDate}`}</p>
          ))}
      </div>
    </BhModal>
  );
};

export default BOCompanyChangePlan;
