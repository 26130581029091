import React, { FC } from "react";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import BhUserIconWithName from "@components/user/BhUserIconWithName";

export interface Props {
  value: ISimpleValidUserAuthority;
  withCheckbox?: boolean;
  onSelect?: Function;
  isSelected?: (value: any) => boolean;
  withUsername?: boolean;
}

const BhDropdownMenuAuthListItem: FC<Props> = ({ value, withCheckbox, onSelect, isSelected, withUsername }) => {
  return (
    <div className="flex flex-row items-center py-1.5 px-4" onClick={() => onSelect && onSelect(value)}>
      {withCheckbox && <input className="h-4 w-4 rounded focus:ring-0 focus:ring-offset-0" type="checkbox" checked={isSelected && isSelected(value)} onChange={() => {}} />}
      <BhUserIconWithName user={value} size="sm" disablePopup={true} />
      {withUsername && <div>{"(" + value.username + ")"}</div>}
    </div>
  );
};

export default BhDropdownMenuAuthListItem;
