import { faCircleXmark } from "@fortawesome/pro-regular-svg-icons/faCircleXmark";
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faSignature } from "@fortawesome/pro-regular-svg-icons/faSignature";
import { faZzz } from "@fortawesome/pro-regular-svg-icons/faZzz";

export const BhSignatureStatusType = {
  PENDING: {
    bgColor: "bh-bg-warning-yellow-10",
    iconColor: "bh-text-warning-yellow-120",
    icon: faClock,
    translateCode: "SIGNATURE.WAITING"
  },
  SIGNED: {
    bgColor: "bh-bg-royal-blue-light",
    iconColor: "bh-text-royal-blue",
    icon: faSignature,
    translateCode: "SIGNATURE.SIGNED"
  },
  REFUSED: {
    bgColor: "bh-bg-error-red-10",
    iconColor: "bh-text-error-red",
    icon: faCircleXmark,
    translateCode: "SIGNATURE.DECLINED"
  }
};

export const BhOwnSignatureStatusType = {
  SIGNED: {
    iconColor: "bh-text-royal-blue",
    icon: faSignature,
    translateCode: "SIGNATURE.SIGNED"
  },
  SIGNED_NOT_VALID: {
    iconColor: "bh-text-pigeon-50",
    icon: faSignature,
    translateCode: "SIGNATURE.IS_NOT_VALID"
  },
  REFUSED: {
    iconColor: "bh-text-error-red",
    icon: faCircleXmark,
    translateCode: "SIGNATURE.DECLINED"
  },
  PENDING: {
    iconColor: "bh-text-warning-yellow-120",
    icon: faClock,
    translateCode: "SIGNATURE.WAITING"
  },
  IN_LINE: {
    iconColor: "bh-text-pigeon",
    icon: faZzz,
    translateCode: "CONFIRMATION.STATUS.PENDING"
  }
};
