import React from "react";
import BOCompanyModules from "@components/backoffice/company/modulesAndTemplates/BOCompanyModules";
import BOCompanyForms from "@components/backoffice/company/modulesAndTemplates/BOCompanyForms";
import BOCompanyChecklists from "@components/backoffice/company/modulesAndTemplates/BOCompanyChecklists";
import BOCompanyContractsSection from "@components/backoffice/company/modulesAndTemplates/BOCompanyContractsSection";
import BOCompanyActsSection from "@components/backoffice/company/modulesAndTemplates/BOCompanyActsSection";

const BOCompanyModulesTemplates = () => {
  return (
    <div className="flex w-full flex-col gap-8">
      <BOCompanyModules />
      <BOCompanyForms />
      <BOCompanyChecklists />
      <BOCompanyContractsSection />
      <BOCompanyActsSection />
    </div>
  );
};

export default BOCompanyModulesTemplates;
