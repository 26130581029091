import React, { FC, ReactNode } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  label?: ReactNode;
  children?: ReactNode;
  customLabelPosition?: boolean;
}

const BhSideBySideInput: FC<Props> = ({ label, children, customLabelPosition }) => {
  return (
    <div className={classNames("flex flex-row py-1.5", !customLabelPosition && "items-center")}>
      <div className="flex inline-flex w-1/3 justify-end px-3 text-right align-top font-semibold">{label}</div>
      <div className="flex inline-flex w-2/3 px-3">{children}</div>
    </div>
  );
};

export default BhSideBySideInput;
