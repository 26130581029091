import React, { FC, useEffect } from "react";
import BhTableRow from "@components/table/BhTableRow";

const ContactsTableRowContainerSkeleton: FC = () => {

  return (
    <BhTableRow>
      <td>
        <div className="h-5 bh-bg-pigeon-50 rounded w-32 animate-pulse" />
      </td>
      <td>
        <div className="h-5 bh-bg-pigeon-50 rounded w-32 animate-pulse" />
      </td>
      <td>
        <div className="h-5 bh-bg-pigeon-50 rounded w-20 animate-pulse" />
      </td>
      <td>
        <div className="h-5 bh-bg-pigeon-50 rounded w-20 animate-pulse" />
      </td>
      <td>
        <div className="h-5 bh-bg-pigeon-50 rounded w-20 animate-pulse" />
      </td>
      <td />
    </BhTableRow>
  );
};

export default ContactsTableRowContainerSkeleton;
