import React, { FC, useEffect, useState } from "react";
import ContentContainer from "@/views/home/contentContainer/ContentContainer";
import SyncProjectListItemContainer from "./SyncProjectListItemContainer";
import { fetchSyncProjectsByCompany } from "@/api/syncAPI";
import { ISyncCompany } from "@/model/sync/ISyncCompany";
import { ReactComponent as BhLogoLoading } from "@svg/bauhub-loading-compressed.svg";
import BhProPlanTag from "@/components/tags/BhProPlanTag";
import BhLightPlanTag from "@/components/tags/BhLightPlanTag";
import BhCardContainer from "@/components/cards/BhCardContainer";
import BhTextOnlyButton from "@/components/buttons/BhTextOnlyButton";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";
import BhCompanyLogo from "@/views/home/project/sidebar/BhCompanyLogo";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import { selectShowArchivedProjects, toggleArchivedProjects } from "@/app/store/companiesSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { ProjectStatus } from "@/model/IProject";

const SyncProjectListContainer: FC = () => {
  const [companies, setCompanies] = useState([] as Array<ISyncCompany>);
  const [loading, setLoading] = useState(true);
  const showArchived = useAppSelector((state) => selectShowArchivedProjects(state));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchSyncProjectsByCompany().then((companies) => {
      setCompanies(companies.sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => b.plan.localeCompare(a.plan)));
      setLoading(false);
    });
  }, []);

  return (
    <div className="bh-bg-white flex h-full w-full flex-col text-left">
      {loading && (
        <div className="flex flex-col items-center justify-center p-28">
          <BhLogoLoading className="h-10 w-10 " />
        </div>
      )}
      {!loading && (
        <ContentContainer
          children={
            <div className="pb-9">
              {companies.map((company) => {
                return (
                  <div>
                    <div className="mt-3" key={company.id}>
                      <div className="flex flex-row flex-wrap items-center">
                        <div className="mr-2 flex flex-row items-center overflow-hidden align-middle">
                          <BhCompanyLogo companyId={company.id} />
                        </div>
                        <div className="inline-flex flex-1 items-center">
                          <h3 className="bh-text-pigeon">{company.name}</h3>
                          {company.plan === "PRO" && <BhProPlanTag />}
                          {company.plan === "LIGHT" && <BhLightPlanTag />}
                        </div>
                        <div>
                          <div className="inline-flex items-center">
                            <p className="ml-3 mr-1.5">{t("HOME.SHOW_ARCHIVED")}</p>
                            <BhToggleSwitch value={showArchived} onClickAction={() => dispatch(toggleArchivedProjects())} small={true} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {company.plan === "PRO" &&
                        company.projects &&
                        company.projects.length > 0 &&
                        company.projects.map((project) => {
                          return ((showArchived && project.status === ProjectStatus.ARCHIVED) || project.status === ProjectStatus.IN_PROGRESS) && <SyncProjectListItemContainer project={project} />;
                        })}
                      {company.plan === "LIGHT" && (
                        <BhCardContainer classes={"bh-bg-deep-ocean hover:bh-bg-deep-ocean"}>
                          <div className="group flex items-center">
                            <p className="text-38px font-weight-800 bh-text-white m-6">Pro</p>
                            <p className="bh-text-white text-18px ml-14 ">
                              {t("SYNC.PACKAGE_TEXT")} <span className="bh-text-bauhub-green font-weight-700">{t("SYNC.PACKAGE_TEXT_PACKAGE")}</span>
                            </p>
                          </div>
                          <a href="https://www.bauhub.ee/hinnad" className="pr-6 text-right">
                            <BhTextOnlyButton buttonProps={{ classes: "bh-bg-mint" }}>
                              <Trans>{t("SYNC.COMPARE_PLANS")}</Trans>
                            </BhTextOnlyButton>
                          </a>
                        </BhCardContainer>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          }
        />
      )}
    </div>
  );
};

export default SyncProjectListContainer;
