import React, { FC, useEffect, useState } from "react";
import { ITask } from "@/model/taskBoard/ITask";
import TaskModalBodyLeft from "@components/task/modal/TaskModalBodyLeft";
import TaskModalBodyRight from "@components/task/modal/TaskModalBodyRight";
import TaskModalBodyTaskBoards from "@components/task/modal/TaskModalBodyTaskBoards";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchProjectTaskBoardsAsync, selectIsPersonalTaskBoardOpen } from "@/app/store/taskBoardsSlice";
import { fetchProjectTaskCategoriesAsync, fetchProjectTaskTagsAsync } from "@/app/store/tasksSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";

interface Props {
  task: ITask;
  saveTaskCallback: Function;
}

const TaskModalBody: FC<Props> = ({ task, saveTaskCallback }) => {
  const dispatch = useAppDispatch();
  const personalTaskBoardOpen = useAppSelector(selectIsPersonalTaskBoardOpen);
  const projectId = useAppSelector(selectCurrentProjectId);
  const allowTaskBoardSelection = !personalTaskBoardOpen && !task.taskBoardId && !task.id;
  const [taskBoardChosen, setTaskBoardChosen] = useState(!allowTaskBoardSelection);

  useEffect(() => {
    dispatch(fetchProjectTaskBoardsAsync(projectId));
    dispatch(fetchProjectTaskCategoriesAsync(projectId));
    dispatch(fetchProjectTaskTagsAsync(projectId));
  }, []);

  const chooseTaskBoard = (changedObject: ITask) => {
    saveTaskCallback(changedObject);
    setTaskBoardChosen(true);
  };

  return (
    <>
      {taskBoardChosen && (
        <div className="bh-bg-white flex h-full flex-1 flex-row overflow-hidden md:flex-col md:overflow-auto">
          <TaskModalBodyLeft task={task} saveTaskCallback={saveTaskCallback} />
          <TaskModalBodyRight task={task} saveTaskCallback={saveTaskCallback} />
        </div>
      )}

      {!taskBoardChosen && <TaskModalBodyTaskBoards saveTaskCallback={chooseTaskBoard} />}
    </>
  );
};

export default TaskModalBody;
