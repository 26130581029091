import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignatureSlash } from "@fortawesome/pro-regular-svg-icons/faSignatureSlash";

const BhStatusCellSignedNotValid: FC = () => {
  return (
    <div className="bh-bg-smoke flex h-14 w-12 flex-row items-center justify-center">
      <FontAwesomeIcon icon={faSignatureSlash} className="bh-text-deep-ocean-60 h-[14px] w-[20px]" />
    </div>
  );
};

export default BhStatusCellSignedNotValid;
