import React, { FC, useEffect, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import ShareboxFilesContainerDirectoryViewFolder from "@/views/home/project/detail/shareboxes/detail/shareboxFilesView/ShareboxFilesContainerDirectoryViewFolder";
import { serialize, urlParamsToObject } from "@/utilities/jsUtilities";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectShareboxFolders, shareboxAllFilesUnSelected } from "@/app/store/shareboxesSlice";

interface Props {
  shareboxId: EntityId;
  shareboxUuid: string;
  publicSharebox: boolean;
}

const ShareboxFilesContainerDirectoryView: FC<Props> = ({ shareboxId, shareboxUuid, publicSharebox }) => {
  const location = useLocation();
  const [currentDirectoryId, setCurrentDirectoryId] = useState<EntityId | null>(null);
  const parentFolders = useAppSelector((state) => selectShareboxFolders(state, shareboxId, null));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const highestParentWithMoreThanOneFolder = (id: EntityId | null): any => {
    const children = parentFolders?.filter((folder) => folder.parentFileEntityId === id);
    if (children && children.length > 0) {
      if (children.length === 1) {
        return highestParentWithMoreThanOneFolder(children[0].id);
      } else {
        return id;
      }
    } else {
      return id;
    }
  };

  useEffect(() => {
    dispatch(shareboxAllFilesUnSelected(shareboxId));
  }, []);

  useEffect(() => {
    const { dir } = urlParamsToObject(location.search);
    const idNumber = Number(dir) || null;
    if (parentFolders?.some((folder) => folder.id === idNumber)) {
      setCurrentDirectoryId(idNumber);
    } else {
      setCurrentDirectoryId(null);
    }
  }, [location]);

  useEffect(() => {
    const { dir } = urlParamsToObject(location.search);
    if (!dir && parentFolders && parentFolders.length > 0 && currentDirectoryId === null) {
      const dirToOpenFirst = highestParentWithMoreThanOneFolder(currentDirectoryId);
      setCurrentDirectoryId(dirToOpenFirst);
    }
  }, []);

  const setCurrentDirectoryAndUrl = (fileEntityId: EntityId | null) => {
    const newLocation = {
      pathname: location.pathname,
      search: serialize({ dir: fileEntityId })
    };
    navigate(newLocation);
  };

  return (
    <ShareboxFilesContainerDirectoryViewFolder
      shareboxId={shareboxId}
      shareboxUuid={shareboxUuid}
      folderId={currentDirectoryId}
      setCurrentDirectoryId={setCurrentDirectoryAndUrl}
      publicSharebox={publicSharebox}
    />
  );
};

export default ShareboxFilesContainerDirectoryView;
