import React, { FC, useEffect, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveDecisionsAsync, selectCurrentDecisions } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { FileConfirmationDecisionType } from "@/model/confirmations/IFileConfirmationDecisionV2";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhModal from "@components/modal/BhModal";
import BhLargeText from "@components/text/BhLargeText";
import { Trans, useTranslation } from "react-i18next";
import { openCommentsForFileConfirmationFileIdSet } from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  confirmationId: EntityId;
}

const ConfirmationActionsHeaderButtons: FC<Props> = ({ confirmationId }) => {
  const { t } = useTranslation();
  const decisions = useAppSelector(selectCurrentDecisions);
  const projectId = useAppSelector(selectCurrentProjectId);
  const [modalShown, setModalShown] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const anyDecisionMade = decisions.some((d) => d.status === FileConfirmationDecisionType.CONFIRMED || d.status === FileConfirmationDecisionType.DECLINED);
  const allDecisionMade = decisions.length > 0 && decisions.every((d) => d.status === FileConfirmationDecisionType.CONFIRMED || d.status === FileConfirmationDecisionType.DECLINED);

  const saveDecisions = () => {
    dispatch(saveDecisionsAsync()).then(() => {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/confirmation/${confirmationId}`);
      dispatch(openCommentsForFileConfirmationFileIdSet(undefined));
    });
  };

  useEffect(() => {
    if (allDecisionMade) setModalShown(true);
  }, [allDecisionMade]);

  return (
    <div className="flex items-center">
      <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/confirmation/${confirmationId}`}>
        <BhTextOnlyButton>
          <Trans>GLOBAL.CANCEL</Trans>
        </BhTextOnlyButton>
      </Link>
      <BhSecondaryButton
        buttonProps={{
          disabled: !anyDecisionMade,
          onClick: () => setModalShown(true)
        }}
      >
        <Trans>GLOBAL.CONFIRM_CHOICES</Trans>
      </BhSecondaryButton>

      <BhModal
        isShown={modalShown}
        setIsShown={() => setModalShown(false)}
        header={<h2>{t("GLOBAL.CONFIRM_CHOICES") + "?"}</h2>}
        children={
          <div className="mx-10 my-10">
            <BhLargeText classes="bh-text-deep-ocean">{t("CONFIRMATIONS.CONFIRM_QUESTION")}</BhLargeText>
            <BhLargeText classes="bh-text-deep-ocean">{t("CONFIRMATIONS.CONFIRM_INFO")}</BhLargeText>
          </div>
        }
        footer={<BhModalFooter onCancel={() => setModalShown(false)} onConfirm={saveDecisions} confirmButtonText={`${t("GLOBAL.SAVE")}`} />}
      />
    </div>
  );
};

export default ConfirmationActionsHeaderButtons;
