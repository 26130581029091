import React, { FC } from "react";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import BhIconButton from "@components/buttons/BhIconButton";

interface Props {
  total: number;
  limit: number;
  page: number;
  changePageClicked: Function;
  disabled?: boolean;
}

const BhPagination: FC<Props> = ({ total, limit, page, changePageClicked, disabled }) => {
  const lastPage = Math.ceil(total / limit);
  const firstPage = 1;
  const currentPage = page > lastPage ? lastPage : page;
  const paginationScale = 3;
  let pagesArray = [{ page: currentPage, text: currentPage.toString() }];

  if (currentPage !== firstPage) {
    pagesArray.push({ page: firstPage, text: firstPage.toString() });
  }
  if (currentPage !== lastPage && total !== limit) {
    pagesArray.push({ page: lastPage, text: lastPage.toString() });
  }
  for (let i = 1; i < paginationScale; i++) {
    let nextPage = currentPage + i;
    if (nextPage < lastPage && nextPage !== lastPage) {
      pagesArray.push({ page: nextPage, text: nextPage.toString() });
      if (i === paginationScale - 1 && currentPage + paginationScale < lastPage) {
        pagesArray.push({ page: currentPage + paginationScale, text: "..." });
      }
    }
    let previousPage = currentPage - i;
    if (previousPage > 0 && previousPage !== firstPage) {
      pagesArray.push({ page: previousPage, text: previousPage.toString() });
      if (i === paginationScale - 1 && previousPage !== 1 && currentPage - paginationScale !== 1) {
        pagesArray.push({ page: currentPage - paginationScale, text: "..." });
      }
    }
  }
  pagesArray.sort((a, b) => {
    return a.page - b.page;
  });

  if (!total) {
    return null;
  }

  return (
    <div className="mt-2.5 flex flex-row space-x-1">
      <BhIconButton icon={faChevronLeft} buttonProps={{ onClick: () => changePageClicked(currentPage - 1), disabled: currentPage === 1 || disabled }} />
      {pagesArray &&
        pagesArray.map((i) => {
          return (
            <BhIconButton buttonProps={{ onClick: () => changePageClicked(i.page), disabled: disabled }} key={i.page} isActive={i.page === currentPage}>
              {i.text}
            </BhIconButton>
          );
        })}
      <BhIconButton icon={faChevronRight} buttonProps={{ onClick: () => changePageClicked(currentPage + 1), disabled: currentPage === lastPage || total === limit || disabled }} />
    </div>
  );
};

export default BhPagination;
