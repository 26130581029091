import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { createAsyncThunk, createSelector, createSlice, EntityId } from "@reduxjs/toolkit";
import { IFormBase } from "@/model/form/IFormBase";
import {
  addFormBasesToProjects,
  boDeleteFormBuilderForm,
  boSaveFormBuilderForm,
  copyFormBaseToCompany,
  copyFormBaseToProject,
  fetchCompanyCustomFormBases,
  fetchCompanyFormBase,
  fetchCompanyFormBasesForProject,
  fetchFormBasesForCompany,
  fetchFormBasesForProject,
  fetchFormBaseVersions,
  fetchProjectFormBasesForCompany,
  fetchProjectFormBasesForCompanyProject,
  fetchSuggestedFormsForUser,
  publishFormBaseVersion,
  removeFormBase,
  removeFormBasesFromProjects,
  removeFormBaseVersion,
  saveCompanyFormBase,
  saveNewCompanyFormBase,
  saveNewFormBaseVersion
} from "@/api/form/formBaseAPI";
import { RootState } from "@/app/store";
import { IProjectFormBase } from "@/model/form/IProjectFormBase";
import { removeDuplicates } from "@/utilities/jsUtilities";
import { IFormBasesToProjectsDTO } from "@/model/form/IFormBasesToProjectsDTO";
import { FormBaseVersionStatus } from "@/model/form/IFormBaseVersion";
import { selectAllCompanyProjects } from "@/app/store/companiesSlice";
import { IProject, ProjectStatus } from "@/model/IProject";
import { IEnabledForm } from "@/model/ICompany";
import { naturalSortByField } from "@/utilities/sortUtilities";

export interface IFormBasesState {
  status: BhStateStatusType;
  companyFormBasesStatus: BhStateStatusType;
  companyFormBases: Array<IFormBase>;
  projectFormBases: Array<IFormBase>;
  activatedBasesInCompanyProjects: Array<IProjectFormBase>;
  customFormBases: Array<IEnabledForm>;
  previewEnabled: boolean;
  suggestedForms: Array<string>;
}

const initialState: IFormBasesState = {
  status: BhStateStatusType.INITIAL,
  companyFormBasesStatus: BhStateStatusType.INITIAL,
  companyFormBases: [],
  projectFormBases: [],
  activatedBasesInCompanyProjects: [],
  customFormBases: [],
  previewEnabled: false,
  suggestedForms: []
};

export const fetchFormBasesForCompanyAsync = createAsyncThunk("form/company/bases", async (companyId: EntityId) => {
  return fetchFormBasesForCompany(companyId);
});

export const fetchFormBasesForProjectAsync = createAsyncThunk("form/project/bases", async (projectId: EntityId) => {
  return fetchFormBasesForProject(projectId);
});

export const fetchProjectFormBasesForCompanyAsync = createAsyncThunk("form/projectFormBases/fetch", async (companyId: EntityId) => {
  return fetchProjectFormBasesForCompany(companyId);
});

export const fetchProjectFormBasesForCompanyProjectAsync = createAsyncThunk("form/projectFormBases/project/fetch", async (dto: { companyId: EntityId; projectId: EntityId }) => {
  return fetchProjectFormBasesForCompanyProject(dto.companyId, dto.projectId);
});

export const fetchCompanyCustomFormBasesAsync = createAsyncThunk("form/bases/custom", async (dto: { companyId: EntityId; projectId?: EntityId }) => {
  return fetchCompanyCustomFormBases(dto.companyId, dto.projectId);
});

export const saveNewFormBaseVersionAsync = createAsyncThunk("form/version/new", async (dto: { companyId: EntityId; formBaseId: EntityId }) => {
  return saveNewFormBaseVersion(dto.companyId, dto.formBaseId);
});

export const saveCompanyFormBaseAsync = createAsyncThunk("form/base/save", async (dto: { companyId: EntityId; formBase: IFormBase }) => {
  return saveCompanyFormBase(dto.companyId, dto.formBase);
});

export const saveNewCompanyFormBaseAsync = createAsyncThunk("form/base/save/new", async (dto: { companyId: EntityId; formBase: IFormBase }) => {
  return saveNewCompanyFormBase(dto.companyId, dto.formBase);
});

export const addFormBasesToProjectsAsync = createAsyncThunk("form/projectFormBase/add", async (dto: { companyId: EntityId; basesToProjectsDTO: IFormBasesToProjectsDTO }) => {
  return addFormBasesToProjects(dto.companyId, dto.basesToProjectsDTO);
});

export const removeFormBasesFromProjectsAsync = createAsyncThunk("form/projectFormBase/remove", async (dto: { companyId: EntityId; basesToProjectsDTO: IFormBasesToProjectsDTO }) => {
  return removeFormBasesFromProjects(dto.companyId, dto.basesToProjectsDTO);
});

export const removeFormBaseAsync = createAsyncThunk("form/base/delete", async (dto: { companyId: EntityId; formBaseId: EntityId }) => {
  return removeFormBase(dto.companyId, dto.formBaseId);
});

export const removeFormBaseVersionAsync = createAsyncThunk("form/version/delete", async (dto: { companyId: EntityId; formBaseId: EntityId; formBaseVersionId: EntityId }) => {
  return removeFormBaseVersion(dto.companyId, dto.formBaseId, dto.formBaseVersionId);
});

export const copyFormBaseToCompanyAsync = createAsyncThunk("form/base/company/copy", async (dto: { companyId: EntityId; formBaseId: EntityId }) => {
  return copyFormBaseToCompany(dto.companyId, dto.formBaseId);
});

export const copyFormBaseToProjectAsync = createAsyncThunk("form/base/project/copy", async (dto: { companyId: EntityId; projectId: EntityId; formBaseId: EntityId }) => {
  return copyFormBaseToProject(dto.companyId, dto.projectId, dto.formBaseId);
});

export const fetchCompanyFormBaseAsync = createAsyncThunk("form/base/fetch", async (dto: { companyId: EntityId; formBaseId: EntityId }) => {
  return fetchCompanyFormBase(dto.companyId, dto.formBaseId);
});

export const fetchCompanyFormBasesForProjectAsync = createAsyncThunk("form/company/bases/project", async (dto: { companyId: EntityId; projectId: EntityId }) => {
  return fetchCompanyFormBasesForProject(dto.companyId, dto.projectId);
});

export const fetchFormBaseVersionsAsync = createAsyncThunk("form/versions/fetch", async (dto: { companyId: EntityId; formBaseId: EntityId }) => {
  return fetchFormBaseVersions(dto.companyId, dto.formBaseId);
});

export const publishFormBaseVersionAsync = createAsyncThunk("form/base/version/publish", async (dto: { companyId: EntityId; formBaseId: EntityId; formBaseVersionId: EntityId }) => {
  return publishFormBaseVersion(dto.companyId, dto.formBaseId, dto.formBaseVersionId);
});

export const fetchSuggestedFormsForUserAsync = createAsyncThunk("form/suggestions", async () => {
  return fetchSuggestedFormsForUser();
});

export const boSaveFormBuilderFormAsync = createAsyncThunk("backoffice/company/forms/save", async (dto: { companyId: EntityId; formBase: IFormBase }) => {
  return boSaveFormBuilderForm(dto.companyId, dto.formBase);
});

export const boDeleteFormBuilderFormAsync = createAsyncThunk("backoffice/company/forms/delete", async (dto: { companyId: EntityId; formBaseId: EntityId }) => {
  return boDeleteFormBuilderForm(dto.companyId, dto.formBaseId);
});

export const formBasesSlice = createSlice({
  name: "formBasesSlice",
  initialState: initialState,
  reducers: {
    toggleFormBasePreview: (state, action) => {
      state.previewEnabled = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveNewFormBaseVersionAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveNewFormBaseVersionAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.companyFormBases = state.companyFormBases.map((companyFormBase) => {
          if (companyFormBase.id === action.payload.formBaseId) {
            return {
              ...companyFormBase,
              formBaseVersions: [...companyFormBase.formBaseVersions, action.payload]
            };
          }
          return companyFormBase;
        });
      })
      .addCase(fetchFormBasesForCompanyAsync.pending, (state) => {
        state.companyFormBasesStatus = BhStateStatusType.PENDING;
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchFormBasesForCompanyAsync.fulfilled, (state, action) => {
        state.companyFormBasesStatus = BhStateStatusType.SUCCESS;
        state.status = BhStateStatusType.SUCCESS;
        state.companyFormBases = action.payload;
      })
      .addCase(fetchFormBasesForProjectAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchFormBasesForProjectAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.projectFormBases = action.payload;
      })
      .addCase(fetchProjectFormBasesForCompanyAsync.fulfilled, (state, action) => {
        state.activatedBasesInCompanyProjects = action.payload;
      })
      .addCase(fetchProjectFormBasesForCompanyProjectAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchProjectFormBasesForCompanyProjectAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.activatedBasesInCompanyProjects = action.payload;
      })
      .addCase(fetchCompanyFormBaseAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchCompanyFormBaseAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.companyFormBases = state.companyFormBases.filter((cfb) => cfb.id !== action.payload.id);
        state.companyFormBases = [...state.companyFormBases, action.payload];
      })
      .addCase(fetchCompanyCustomFormBasesAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchCompanyCustomFormBasesAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.customFormBases = action.payload;
      })
      .addCase(fetchFormBaseVersionsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchFormBaseVersionsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const firstBaseVersion = action.payload[0];
        const companyFormBase = state.companyFormBases.find((cfb) => cfb.id === firstBaseVersion.formBaseId);
        if (companyFormBase) {
          companyFormBase.formBaseVersions = action.payload;
        }
      })
      .addCase(saveCompanyFormBaseAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveCompanyFormBaseAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.companyFormBases = state.companyFormBases.map((companyFormBase) => {
          if (companyFormBase.id === action.payload.id) {
            return action.payload;
          }
          return companyFormBase;
        });
      })
      .addCase(addFormBasesToProjectsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(addFormBasesToProjectsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.activatedBasesInCompanyProjects = [...state.activatedBasesInCompanyProjects, ...action.payload];
      })
      .addCase(removeFormBasesFromProjectsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(removeFormBasesFromProjectsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.activatedBasesInCompanyProjects = state.activatedBasesInCompanyProjects.filter((pfb) => !action.payload.some((fb: IProjectFormBase) => pfb.id === fb.id));
      })
      .addCase(removeFormBaseAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(removeFormBaseAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.companyFormBases = state.companyFormBases.filter((cfb) => cfb.id !== action.payload.id);
      })
      .addCase(saveNewCompanyFormBaseAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveNewCompanyFormBaseAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.companyFormBases = [...state.companyFormBases, action.payload];
      })
      .addCase(removeFormBaseVersionAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(removeFormBaseVersionAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const formBase = state.companyFormBases.find((fb) => fb.id === action.payload.formBaseId);
        if (formBase && formBase.formBaseVersions) {
          formBase.formBaseVersions = formBase.formBaseVersions.filter((fbv) => fbv.id !== action.payload.id);
        }
      })
      .addCase(copyFormBaseToCompanyAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(copyFormBaseToCompanyAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.companyFormBases = [...state.companyFormBases, action.payload];
      })
      .addCase(copyFormBaseToProjectAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(copyFormBaseToProjectAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.companyFormBases = [...state.companyFormBases, action.payload];
      })
      .addCase(publishFormBaseVersionAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(publishFormBaseVersionAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const companyFormBase = state.companyFormBases.find((cfb) => cfb.id === action.payload.formBaseId);
        if (companyFormBase) {
          companyFormBase.formBaseVersions = companyFormBase.formBaseVersions.map((fbv) => {
            if (fbv.id === action.payload.id) {
              return { ...fbv, status: action.payload.status };
            }
            return fbv;
          });
        }
      })
      .addCase(fetchCompanyFormBasesForProjectAsync.pending, (state) => {
        state.companyFormBasesStatus = BhStateStatusType.PENDING;
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchCompanyFormBasesForProjectAsync.fulfilled, (state, action) => {
        state.companyFormBasesStatus = BhStateStatusType.SUCCESS;
        state.status = BhStateStatusType.SUCCESS;
        state.companyFormBases = action.payload;
      })
      .addCase(fetchSuggestedFormsForUserAsync.fulfilled, (state, action) => {
        state.suggestedForms = action.payload;
      })
      .addCase(boSaveFormBuilderFormAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(boSaveFormBuilderFormAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.companyFormBases = [...state.companyFormBases, action.payload];
      })
      .addCase(boDeleteFormBuilderFormAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(boDeleteFormBuilderFormAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.companyFormBases = state.companyFormBases.filter((cfb) => cfb.id !== action.payload.id);
      });
  }
});

export const { toggleFormBasePreview } = formBasesSlice.actions;

export const selectCompanyFormBases = (state: RootState) => state.formBases.companyFormBases;
export const selectCompanyFormBasesStatus = (state: RootState) => state.formBases.companyFormBasesStatus;
export const selectProjectFormBases = (state: RootState) => state.formBases.projectFormBases;
export const selectActivatedBasesInCompanyProjects = (state: RootState) => state.formBases.activatedBasesInCompanyProjects;
export const selectIsFormBasePreviewEnabled = (state: RootState) => state.formBases.previewEnabled;
export const selectFormBaseId = (state: RootState, formBaseId: EntityId) => formBaseId;
export const selectFormBaseVersionId = (state: RootState, formBaseVersionId: EntityId) => formBaseVersionId;
export const selectProjectId = (state: RootState, _: any, projectId: EntityId) => projectId;
export const selectSuggestedFormsForUser = (state: RootState) => state.formBases.suggestedForms;

export const selectCompanyCustomFormBases = createSelector([(state: RootState) => state.formBases.customFormBases], (customFormBases) => {
  const formBases = customFormBases.map((fb) => {
    const enabledFormToFormBase = {
      type: fb.code,
      id: fb.id,
      createdBy: "custom",
      formBaseVersions: [{ created: fb.created, createdBy: "custom", formBaseId: fb.id, status: FormBaseVersionStatus.FINAL }]
    } as IFormBase;
    return enabledFormToFormBase;
  });
  return formBases.sort((a, b) => naturalSortByField(a, b, "type"));
});

export const selectUsernamesForFormBaseFilter = createSelector([selectCompanyFormBases, selectCompanyCustomFormBases], (companyFormBases, customFormBases) => {
  const translationKeyRecord: Record<string, string> = {
    bauhub: "FORM_BASE.BAUHUB_TEMPLATE",
    custom: "FORMS.CREATED_BY.CUSTOM_FORM_BASE"
  };
  const usernamesForFilter = removeDuplicates(companyFormBases.filter((fb) => !fb.createdInProjectId).map((cfb) => cfb.createdBy));
  const addedUsernames = customFormBases.length > 0 ? [...usernamesForFilter, "custom"] : usernamesForFilter;
  const usernamesToObjects = addedUsernames.map((username) => {
    return translationKeyRecord.hasOwnProperty(username) ? { username: username, translateValue: translationKeyRecord[username] } : { username: username, translateValue: username };
  });
  return usernamesToObjects;
});
export const selectUsernamesForProjectFormBaseFilterInCompanySettings = createSelector([selectCompanyFormBases], (companyFormBases) => {
  return removeDuplicates(companyFormBases.filter((fb) => fb.createdInProjectId).map((cfb) => cfb.createdBy));
});
export const selectCompanyFormBasesNotCreatedInProjects = createSelector([selectCompanyFormBases], (formBases) => {
  return formBases.filter((cfb) => !cfb.createdInProjectId);
});
export const selectCompanyFormBasesCreatedInProjects = createSelector([selectCompanyFormBases], (formBases) => {
  return formBases.filter((cfb) => cfb.createdInProjectId);
});
export const makeSelectCompanyProjectsForFormBaseFilter = () =>
  createSelector([selectCompanyFormBasesCreatedInProjects, (state, companyId) => selectAllCompanyProjects(state, companyId)], (projectBases, companyProjects) => {
    if (projectBases && projectBases.length > 0) {
      const projectIds = projectBases.map((fb) => fb.createdInProjectId);
      const projects: Array<IProject> = companyProjects.filter((project) => projectIds.includes(project.id));
      if (projects.length > 0) {
        return projects.map((project: IProject) => {
          return { id: project.id, name: project.name };
        });
      }
    }
    return [];
  });

export const selectCompanyFormBasesActivatedInProject = createSelector(
  [selectCompanyFormBases, selectActivatedBasesInCompanyProjects, (state: RootState, projectId: EntityId) => projectId, selectCompanyCustomFormBases],
  (formBases, activatedCompanyBases, projectId, customFormBases) => {
    const projectFormBases = formBases.filter((fb) => activatedCompanyBases.some((cfb) => cfb.projectId === projectId && cfb.formBaseId === fb.id));
    return [...projectFormBases, ...customFormBases];
  }
);

export const selectIsFormBaseActivatedInProjectByBaseAndProjectId = createSelector(
  [selectActivatedBasesInCompanyProjects, selectFormBaseId, selectProjectId],
  (activatedProjectBases, formBaseId, projectId) => {
    return activatedProjectBases.some((pfb) => pfb.formBaseId === formBaseId && pfb.projectId === projectId);
  }
);

export const selectUsernamesForFormBaseFilterInProjectSettings = createSelector([selectCompanyFormBases, selectCompanyCustomFormBases], (companyFormBases, customFormBases) => {
  const translationKeyRecord: Record<string, string> = {
    bauhub: "FORM_BASE.BAUHUB_TEMPLATE",
    custom: "FORMS.CREATED_BY.CUSTOM_FORM_BASE"
  };
  const usernamesForFilter = companyFormBases.map((cfb) => cfb.createdBy);
  const anyBauhubTemplates = companyFormBases.some((fb) => fb.bauhubFormBase);
  const anyCustomFormBases = customFormBases.length > 0;
  const addedUsernames = removeDuplicates([...usernamesForFilter, anyCustomFormBases && "custom", anyBauhubTemplates && "bauhub"]);
  const usernamesToObjects = addedUsernames.map((username) => {
    return translationKeyRecord.hasOwnProperty(username) ? { username: username, translateValue: translationKeyRecord[username] } : { username: username, translateValue: username };
  });
  return usernamesToObjects;
});

export const selectCompanyFormBaseById = createSelector([selectCompanyFormBases, (state: any, formBaseId: EntityId) => formBaseId], (formBases, formBaseId) => {
  const formBase = formBases.find((fb) => fb.id === formBaseId);
  if (formBase) {
    return formBase;
  }
});

export const makeSelectActivatedBasesInCompanyActiveProjects = () =>
  createSelector([selectActivatedBasesInCompanyProjects, (state, companyId) => selectAllCompanyProjects(state, companyId)], (activatedBases, companyProjects) => {
    const activeCompanyProjects = companyProjects.filter((project) => project.status !== ProjectStatus.ARCHIVED && !project.suspended && !project.closed);
    return activatedBases.filter((base) => activeCompanyProjects.some((project) => project.id === base.projectId));
  });

export const selectCompanyFormBaseByVersionId = createSelector([selectFormBaseVersionId, selectCompanyFormBases], (formBaseVersionId, companyFormBases) => {
  return companyFormBases.find((base) => base.formBaseVersions.some((version) => version.id === formBaseVersionId));
});

export const selectActiveCompanyFormBases = createSelector([selectCompanyFormBases], (companyFormBases) => {
  return companyFormBases.filter((formBase) => formBase.formBaseVersions.some((version) => version.status === FormBaseVersionStatus.FINAL));
});

export default formBasesSlice.reducer;
