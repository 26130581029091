import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faArrowTurnDownRight } from "@fortawesome/pro-regular-svg-icons/faArrowTurnDownRight";
import { classNames } from "@/utilities/jsUtilities";
import { findProtocolNextMainRowNumber, findProtocolNextSubRowNumber } from "@/utilities/formUtilities";
import { useAppSelector } from "@/app/hooks";
import { selectIndexForNextProtocolMainRowByCurrentRowId, selectIndexForNextProtocolSubRowByCurrentRowId } from "@/app/store/form/formSlice";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";

interface Props {
  disabled?: boolean;
  saveNewRowCallback: Function;
  isCurrentRowSubRow: boolean;
  row: any;
  isFormBase: boolean;
  agendaFieldProperty?: string;
}

const ProtocolAddRowButton: FC<Props> = ({ saveNewRowCallback, row, isCurrentRowSubRow, disabled, isFormBase, agendaFieldProperty }) => {
  const { t } = useTranslation();
  const nextMainRowIndex = useAppSelector((state) => (!isFormBase && agendaFieldProperty ? selectIndexForNextProtocolMainRowByCurrentRowId(state, row._id, agendaFieldProperty) : 0));
  const nextSubRowIndex = useAppSelector((state) => (!isFormBase && agendaFieldProperty ? selectIndexForNextProtocolSubRowByCurrentRowId(state, row._id, agendaFieldProperty) : 0));

  const saveNewRow = (isSubRow: boolean) => {
    if (disabled) return;
    const newRowNumber = isSubRow ? findProtocolNextSubRowNumber(row.nr) : findProtocolNextMainRowNumber(row.nr);
    const newRowToSave = { level: isSubRow ? 1 : 0, nr: newRowNumber, content: "" };
    const newRowIndex = isCurrentRowSubRow ? nextSubRowIndex : nextMainRowIndex;
    saveNewRowCallback(newRowIndex, newRowToSave);
  };

  return (
    <div className={classNames("flex h-[26px] w-full flex-row", isCurrentRowSubRow && "justify-end")}>
      {!isCurrentRowSubRow && (
        <div
          className={classNames(
            "max-w-half rounded-l-3px flex h-full w-1/2 flex-row items-center justify-center",
            disabled ? "bh-bg-pigeon-20 bh-text-pigeon-80 cursor-not-allowed" : "bh-bg-dark-jungle bh-text-white hover:bh-bg-dark-jungle-110 cursor-pointer"
          )}
          onClick={() => saveNewRow(false)}
        >
          <BhTooltip body={t("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.NEW_ROW")}>
            <FontAwesomeIcon icon={faPlus} />{" "}
          </BhTooltip>
        </div>
      )}

      <div
        className={classNames(
          "max-w-half flex h-full w-1/2 flex-row items-center justify-center",
          isCurrentRowSubRow ? "rounded-3px" : "rounded-r-3px",
          disabled ? "bh-bg-pigeon-20 bh-text-pigeon-80 cursor-not-allowed" : "bh-bg-dark-jungle bh-text-white hover:bh-bg-dark-jungle-110 cursor-pointer"
        )}
        onClick={() => saveNewRow(true)}
      >
        <BhTooltip body={t("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.NEW_SUBROW")}>
          <FontAwesomeIcon icon={isCurrentRowSubRow ? faPlus : faArrowTurnDownRight} />{" "}
        </BhTooltip>
      </div>
    </div>
  );
};

export default ProtocolAddRowButton;
