import { IBhSort } from "@/model/IBhSort";
import { ICompany } from "@/model/ICompany";
import { ISalesInvoice } from "@/model/billing/ISalesInvoice";
import { BauhubPlan, ProjectSecurityLevel, ProjectStatus } from "@/model/IProject";
import { IProjectBilling } from "@/model/billing/IProjectBilling";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { IMonthlyTrialOverviewRow } from "@/model/trials/IMonthlyTrialOverviewRow";

export interface IBoSort {
  companiesSort: IBhSort<ICompany>;
  invoicesSort: IBhSort<IBoInvoiceRowSort>;
  companyProjectsSort: IBhSort<IBoProjectSort>;
  trialsSort: IBhSort<IMonthlyTrialOverviewRow>;
}

export interface IBoInvoiceRowSort extends ISalesInvoice {
  companyName: string;
  companyPlan: BauhubPlan;
}

export interface IBoProjectSort extends IProjectBilling {
  name: string;
  status: ProjectStatus;
  created: Date;
  relatedUsers: Array<ISimpleValidUserAuthority>;
  securityLevel: ProjectSecurityLevel;
  closed: boolean;
  suspended: boolean;
}

export const initialCompaniesSort: IBhSort<ICompany> = {
  property: "name" as keyof ICompany,
  reversed: false
};

export const initialInvoiceRowSort: IBhSort<IBoInvoiceRowSort> = {
  property: "invoiceDate" as keyof IBoInvoiceRowSort,
  reversed: true
};

const initialCompanyProjectsSort: IBhSort<IBoProjectSort> = {
  property: "name" as keyof IBoProjectSort,
  reversed: false
};

const initalTrialsSort: IBhSort<IMonthlyTrialOverviewRow> = {
  property: "companyName" as keyof IMonthlyTrialOverviewRow,
  reversed: false
};

export const initialSort: IBoSort = {
  companiesSort: initialCompaniesSort,
  invoicesSort: initialInvoiceRowSort,
  companyProjectsSort: initialCompanyProjectsSort,
  trialsSort: initalTrialsSort
};
