import React, { FC, useEffect } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyById } from "@/app/store/companiesSlice";
import BhTooltip from "@components/BhTooltip";
import BhCompanyLogo from "@/views/home/project/sidebar/BhCompanyLogo";

interface Props {
  companyId: EntityId;
}

const BhCompanyDropdownModalHeader: FC<Props> = ({ companyId }) => {
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  useEffect(() => {});

  if (!company) {
    return null;
  }

  return (
    <BhTooltip body={company.name.length > 40 ? company.name : ""} delayShow={1000}>
      <div className="mb-2 flex h-14 flex-row items-center pl-2 pt-2">
        <BhCompanyLogo companyId={companyId} small={true} />
        <h2 className="bh-text-deep-ocean mr-2 flex-1 truncate">{company.name}</h2>
      </div>
    </BhTooltip>
  );
};

export default BhCompanyDropdownModalHeader;
