import React, { FC, useState } from "react";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectCompanyId, selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import NewFormBaseModal from "@components/form/settings/companySettings/NewFormBaseModal";

const NoFormBases: FC = () => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const companyId = useAppSelector(selectCurrentProjectCompanyId);
  const isCurrentUserProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const [showNewFormBaseModal, setShowNewFormBaseModal] = useState(false);

  return (
    <div className="mt-11 flex flex-row justify-center">
      <div>
        <div className="flex flex-row justify-center">
          <BhNoMatchesImage />
        </div>
        <div className="text-18px bh-text-deep-ocean mt-2 text-center leading-6">
          <div>{t("FORMS.PROJECT_SETTINGS.NO_TEMPLATES")}</div>
        </div>
        {isCurrentUserProjectAdmin && (
          <div className="mt-4 text-center">
            <BhPrimaryButton icon={faPlus} buttonProps={{ onClick: () => setShowNewFormBaseModal(true) }}>
              {t("FORMBUILDER.FORM.PROJECT_SETTINGS.NEW_BASE")}
            </BhPrimaryButton>
          </div>
        )}
      </div>
      {showNewFormBaseModal && <NewFormBaseModal setShowModal={setShowNewFormBaseModal} companyId={companyId} projectId={projectId} />}
    </div>
  );
};

export default NoFormBases;
