import React from "react";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { IDropdownItem } from "@/model/IDropdownItem";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { faPen } from "@fortawesome/pro-regular-svg-icons/faPen";
import { faEyeSlash } from "@fortawesome/pro-regular-svg-icons/faEyeSlash";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { deleteContactAsync, hideContactAsync, setContactEditModalOpen, setContactRightsModalOpen, setEditableContact, unhideContactAsync } from "@/app/store/project/projectContactsSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhIconButton from "@components/buttons/BhIconButton";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons/faEllipsisVertical";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { IContact } from "@/model/IContact";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { faKeySkeletonLeftRight } from "@fortawesome/pro-solid-svg-icons/faKeySkeletonLeftRight";
import { selectCurrentUser, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  contact: IContact;
  projectId: EntityId;
}
const ContactActionsDropdown = React.memo<Props>(({ contact, projectId }) => {
  const { t } = useTranslation();
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const currentUser = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();

  const [showDeleteContactConfirmationModal, hideDeleteContactConfirmationModal] = useModal(
    () => (
      <BhDeleteConfirmationModal
        setIsOpen={hideDeleteContactConfirmationModal}
        header={<h2>{t("MODAL.CONTACT.CONFIRMATION.DELETE.HEADER")}</h2>}
        body={<div>{t("MODAL.CONTACT.CONFIRMATION.DELETE")}</div>}
        confirmationButtonText={t("GLOBAL.DELETE")}
        handleDelete={() => {
          dispatch(deleteContactAsync(contact));
          hideDeleteContactConfirmationModal();
        }}
      />
    ),
    [contact]
  );

  if (!contact) {
    return null;
  }

  const contactExists = contact.userExists;
  const hidingAllowed = isProjectAdmin || contact.userEntityId === currentUser.id;

  const fileCommentEllipsisDropdownValues: Array<IDropdownItem> = [
    !contactExists && {
      text: `${t("WORKGROUP.EDIT")}`,
      function: () => {
        dispatch(setContactEditModalOpen(true));
        dispatch(setEditableContact(contact as IContact));
      },
      icon: faPen
    },
    isProjectAdmin &&
      contactExists && {
        text: `${t("PARTIES.ACCESSES")}`,
        function: () => {
          dispatch(setEditableContact(contact as IContact));
          dispatch(setContactRightsModalOpen(true));
        },
        icon: faKeySkeletonLeftRight
      },
    hidingAllowed && {
      text: contact.hidden ? `${t("GLOBAL.SHOW")}` : `${t("GLOBAL.HIDE")}`,
      function: () => {
        contact.hidden ? dispatch(unhideContactAsync(contact)) : dispatch(hideContactAsync(contact));
      },
      icon: faEyeSlash
    },
    !contactExists && {
      text: `${t("GLOBAL.DELETE")}`,
      function: showDeleteContactConfirmationModal,
      icon: faTrash
    }
  ].filter(Boolean) as Array<IDropdownItem>;

  return (
    <BhDropdown
      menuClasses="bh-text-deep-ocean"
      button={<BhIconButton icon={faEllipsisVertical} />}
      menu={<BhDropdownMenu values={fileCommentEllipsisDropdownValues} type={BhDropdownTypeEnum.STRING} textProperty="text" widthClass="w-116px" />}
      position={BhDropdownPositionEnum.BOTTOM_LEFT}
    />
  );
});

export default ContactActionsDropdown;
