import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IFileEntity } from "@/model/files/IFileEntity";
import BhDivTableHeader from "@components/table/BhDivTableHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import {
  fileConfirmationFilesSortSet,
  selectAllFilesSelectedInConfirmation,
  selectFileConfirmationFilesSort,
  toggleAllFileConfirmationFileSelected
} from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import { EntityId } from "@reduxjs/toolkit";
import { IFileConfirmationFile } from "@/model/confirmations/IFileConfirmationFile";
import { useTranslation } from "react-i18next";

interface Props {
  confirmationId: EntityId;
}

const ConfirmationContainerActionsTableHeader: FC<Props> = ({ confirmationId }) => {
  const { t } = useTranslation();
  const sort = useAppSelector(selectFileConfirmationFilesSort);
  const allFilesSelectedInConfirmation = useAppSelector((state) => selectAllFilesSelectedInConfirmation(state, confirmationId));
  const dispatch = useAppDispatch();

  const tableColumnHeaders: Array<IBhTableHeader<IFileEntity>> = [
    {
      id: 1,
      content: (
        <input
          className="hover:bh-border-deep-ocean bh-border-pigeon-60 h-5 w-5 rounded focus:ring-0 focus:ring-offset-0"
          type="checkbox"
          checked={allFilesSelectedInConfirmation}
          onChange={() => {}}
          onClick={() => {
            dispatch(toggleAllFileConfirmationFileSelected(confirmationId));
          }}
        />
      ),
      classes: "w-9 flex-none justify-center"
    },
    { id: 2, content: "", classes: "w-11 flex-none" },
    { id: 3, content: t("GLOBAL.ITEM_NAME"), classes: "flex-grow", field: "name", sortable: true },
    { id: 4, content: t("GLOBAL.VERSION"), classes: "w-18 flex-none justify-center" },
    { id: 5, content: "", classes: "w-32 flex-none" },
    {
      id: 6,
      content: (
        <span>
          <FontAwesomeIcon icon={faCheck} className="pr-2" />
          {t("CONFIRM.CONFIRM")}
        </span>
      ),
      classes: "w-28 flex-none justify-center"
    },
    {
      id: 7,
      content: (
        <span>
          <FontAwesomeIcon icon={faTimes} className="pr-2" />
          {t("ACT.REFUSE")}
        </span>
      ),
      classes: "w-28 flex-none justify-center"
    }
  ];

  const onTableHeaderClickCallback = (column: IBhTableHeader<IFileConfirmationFile>) => {
    if (column.field) dispatch(fileConfirmationFilesSortSet({ ...sort, property: column.field, reversed: column.field === sort.property ? !sort.reversed : sort.reversed }));
  };

  return <BhDivTableHeader columns={tableColumnHeaders} onClickCallback={onTableHeaderClickCallback} sortedBy={sort.property} reversed={sort.reversed} />;
};

export default ConfirmationContainerActionsTableHeader;
