import React, { FC } from "react";
import BhFakeInput from "@components/input/BhFakeInput";
import { formatDate } from "@/utilities/dateUtility";
import BhDatePicker from "@components/input/BhDatePicker";

interface Props {
  row: any;
  saveCallback: Function;
  placeholder?: string;
  disabled: boolean;
}

const FormProtocolFieldTaskDeadline: FC<Props> = ({ row, saveCallback, placeholder, disabled }) => {
  return (
    <BhFakeInput value={row.task && formatDate(row.task.deadline)} className={"min-w-26 max-w-26 w-26"} placeholder={placeholder} disabled={disabled}>
      <BhDatePicker
        property={"deadline"}
        initialValue={row.task && new Date(row.task.deadline)}
        disabled={disabled}
        classes={"min-w-26 max-w-26 w-26"}
        autoFocus={true}
        onChangeCallback={saveCallback}
        wrapperClasses={"w-full"}
      />
    </BhFakeInput>
  );
};

export default FormProtocolFieldTaskDeadline;
