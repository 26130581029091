import React, { FC } from "react";
import { ICompany } from "@/model/ICompany";

import { boSaveCompanyBillingAsync, boSelectCompanyBilling } from "@/app/store/backofficeSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhPigeon20Separator from "@components/separator/BhPigeon20Separator";
import BOMaruFinbiteAuthCode from "@components/backoffice/company/BOMaruFinbiteAuthCode";
import BOCompanyAndBillingGeneral from "@/components/backoffice/company/companyAndBilling/BOCompanyAndBillingGeneral";
import BOCompanyBillingSettings from "@components/backoffice/company/companyAndBilling/BOCompanyBillingSettings";
import BOCompanyConsolidatedInvoiceSettings from "@components/backoffice/company/companyAndBilling/BOCompanyConsolidatedInvoiceSettings";
import BOCompanyKillswitch from "@components/backoffice/company/companyAndBilling/BOCompanyKillswitch";
import BOCompanyCompanyAdministrators from "@/components/backoffice/company/companyAndBilling/BOCompanyCompanyAdministrators";

interface Props {
  company: ICompany;
}

const BOCompanyAndBillingInfoTab: FC<Props> = ({ company }) => {
  const companyBilling = useAppSelector(boSelectCompanyBilling);
  const dispatch = useAppDispatch();
  const isMaruCompany = company?.modules?.some((m) => m.module === "MR_BUDGET");

  const handleBillingChange = (changedObj: Record<string, any>) => {
    const changedCompanyBilling = { ...companyBilling, ...changedObj };
    dispatch(boSaveCompanyBillingAsync(changedCompanyBilling));
  };

  return (
    <div className="m-auto max-w-[1500px] px-44 py-10">
      <BOCompanyAndBillingGeneral />
      <BhPigeon20Separator />
      {isMaruCompany && <BOMaruFinbiteAuthCode />}
      {isMaruCompany && <BhPigeon20Separator />}
      <BOCompanyCompanyAdministrators companyId={company.id} />
      <BhPigeon20Separator classes="mb-4 mt-0" />
      <BOCompanyBillingSettings onFieldChangeCallback={handleBillingChange} />
      <BhPigeon20Separator />
      <BOCompanyConsolidatedInvoiceSettings onFieldChangeCallback={handleBillingChange} />
      <BhPigeon20Separator />
      <BOCompanyKillswitch />
    </div>
  );
};

export default BOCompanyAndBillingInfoTab;
