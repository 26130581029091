import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchChecklistBasesForStatisticsAsync, selectChecklistStatisticsFilter, selectCompanyChecklistBases } from "@/app/store/checklist/checklistsSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { IChecklistStatistics } from "@/model/checklist/IChecklistStatistics";
import ChecklistStatisticsRow from "@components/checklists/statistics/ChecklistStatisticsRow";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import BhLargeText from "@components/text/BhLargeText";
import ChecklistStatisticsTableHeader from "@components/checklists/statistics/ChecklistStatisticsTableHeader";
import { naturalSortByField } from "@/utilities/sortUtilities";
import NoChecklistBases from "@components/checklists/list/NoChecklistBases";
import ChecklistStatisticsContainer from "@/views/home/project/detail/checklists/statistics/ChecklistStatisticsContainer";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import DetailViewContainer from "@/views/home/project/detail/detailViewContainer/DetailViewContainer";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { useNavigate } from "react-router-dom";

const ChecklistStatistics: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const projectId = useAppSelector(selectCurrentProjectId);
  const checklistBases = useAppSelector(selectCompanyChecklistBases);
  const filter = useAppSelector(selectChecklistStatisticsFilter);
  const [checklistStatistics, setChecklistStatistics] = useState<IChecklistStatistics>({} as IChecklistStatistics);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchChecklistBasesForStatisticsAsync(projectId));
  }, []);

  const checklistBasesExisting = checklistBases && checklistBases.length > 0;
  const noChecklists = checklistStatistics.checklists?.length === 0;
  const sortedChecklists = checklistStatistics.checklists?.sort((a, b) => naturalSortByField(a, b, filter.orderBy, !filter.orderAsc)) || [];

  const navigateToCategories = () => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklists`);
  };

  return (
    <DetailViewContainer
      header={
        <DetailViewHeading>
          <div className="flex flex-row items-center gap-x-2">
            <div className={classNames("bh-text-deep-ocean-60 cursor-pointer")} onClick={navigateToCategories}>
              {t("PROJECT.CHECKLISTS")}
            </div>
            <div className="flex flex-row items-center gap-x-2">
              <div className="bh-text-deep-ocean-40">
                <FontAwesomeIcon icon={faChevronRight} size={"2xs"} />
              </div>
              <div>{t("CHECKLIST.STATISTICS")}</div>
            </div>
          </div>
        </DetailViewHeading>
      }
    >
      <BhScrollableBody>
        {checklistBasesExisting && (
          <>
            {checklistBases && <ChecklistStatisticsContainer checklistStatistics={checklistStatistics} setChecklistStatistics={setChecklistStatistics} />}
            <table className="mt-3 w-full">
              <ChecklistStatisticsTableHeader />
              <tbody>
                {sortedChecklists?.map((checklist, index) => (
                  <ChecklistStatisticsRow key={index} checklistData={checklist} />
                ))}
              </tbody>
            </table>
            {noChecklists && <BhLargeText classes="w-full text-center mt-8">{t("CHECKLIST.CHECKLISTS_MISSING")}</BhLargeText>}
          </>
        )}
        {!checklistBasesExisting && <NoChecklistBases />}
      </BhScrollableBody>
    </DetailViewContainer>
  );
};

export default ChecklistStatistics;
