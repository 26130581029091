import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { useNavigate } from "react-router-dom";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhBanner from "@components/banners/BhBanner";
import { BauhubBannerType } from "@/model/IProject";
import BhSignatureContainerStatus from "@components/container/BhSignatureContainerStatus";
import { IFileEntity } from "@/model/files/IFileEntity";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { IChecklistRound } from "@/model/checklist/IChecklistRound";
import { useTranslation } from "react-i18next";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  checklistRound: IChecklistRound;
}

const ChecklistHeaderContainerStatus: FC<Props> = ({ checklistRound }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const projectId = useAppSelector(selectCurrentProjectId);

  const getDigiDocLabel = (digiDoc: IFileEntity) => {
    if (digiDoc.declinedInvitesCount > 0) {
      return t("CHECKLIST.SIGNATURES.DECLINED");
    } else if (digiDoc.invitesCount > 0) {
      return t("CHECKLIST.SIGNATURES.SIGNING");
    } else if (digiDoc.signaturesCount > 0) {
      return t("CHECKLIST.SIGNATURES.SIGNED");
    }
  };

  const openDigiDocContainer = () => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/container/${checklistRound.digiDocContainer.id}`);
  };

  return (
    <div className="m-1">
      <BhBanner type={BauhubBannerType.ANNOUNCEMENT_BLUE} fullWidth={true}>
        <div className="flex w-full flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <BhSignatureContainerStatus fileEntity={checklistRound.digiDocContainer} dropdownPosition={BhDropdownPositionEnum.BOTTOM_RIGHT} />
            <div className="text-14px ml-2 leading-5">{getDigiDocLabel(checklistRound.digiDocContainer)}</div>
          </div>
          <BhTextOnlyButton buttonProps={{ onClick: openDigiDocContainer }}>{t("GLOBAL.OPEN_DIGIDOC")}</BhTextOnlyButton>
        </div>
      </BhBanner>
    </div>
  );
};

export default ChecklistHeaderContainerStatus;
