import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";
import { FormRowFieldType, FormRowFieldTypes } from "@/model/form/IFormBaseVersionRowField";
import { useClickOutside } from "@/utilities/hooks/useClickOutside";
import FormBaseFieldsAddContainer from "@components/form/base/body/fields/FormBaseFieldsAddContainer";
import { ReactComponent as AttachmentImage } from "@svg/form-base-attachment.svg";
import { ReactComponent as DateImage } from "@svg/form-base-date.svg";
import { ReactComponent as PeopleImage } from "@svg/form-base-people.svg";
import { ReactComponent as ProtocolImage } from "@svg/form-base-protocol.svg";
import { ReactComponent as SeparatorImage } from "@svg/form-base-separator.svg";
import { ReactComponent as TableImage } from "@svg/form-base-table.svg";
import { ReactComponent as TextImage } from "@svg/form-base-text.svg";
import { ReactComponent as TimeImage } from "@svg/form-base-time.svg";
import { ReactComponent as WeatherImage } from "@svg/form-base-weather.svg";
import { ReactComponent as WysiwygImage } from "@svg/form-base-wysiwyg.svg";

interface Props {
  onFieldSelectCallback: Function;
  clickOutsideCallback: Function;
  addFieldsToExistingRow?: boolean;
}

const FormBaseFields: FC<Props> = ({ onFieldSelectCallback, clickOutsideCallback, addFieldsToExistingRow }) => {
  const { t } = useTranslation();
  const divRef = useClickOutside(() => clickOutsideCallback());

  const fieldTypeIconMap: Record<FormRowFieldType, any> = {
    ATTACHMENT: <AttachmentImage />,
    DATE: <DateImage />,
    PEOPLE: <PeopleImage />,
    PROTOCOL: <ProtocolImage />,
    SEPARATOR: <SeparatorImage />,
    TABLE: <TableImage />,
    TEXT: <TextImage />,
    TIME: <TimeImage />,
    WEATHER: <WeatherImage />,
    WYSIWYG: <WysiwygImage />,
    MNT_MP_TABLE_FIELD: <></>,
    TOOPLAAN_TABLE: <></>,
    TEXT_EDITOR: <></>,
    SCM_TABLE: <></>,
    SCM_TASKS: <></>,
    ATTACHMENTS_WITH_DATES: <></>,
    UPLOAD: <></>
  };

  const rowFieldTypes = FormRowFieldTypes.map((fieldType, index) => {
    return { id: index, image: fieldTypeIconMap[fieldType], text: t("FORMBUILDER.FORM.BASE.FIELD." + fieldType), type: fieldType };
  });

  const firstRow = rowFieldTypes.slice(0, 5);
  const secondRow = rowFieldTypes.slice(5, rowFieldTypes.length);
  const existingRowItems = rowFieldTypes.slice(0).filter((type) => [FormRowFieldType.TEXT, FormRowFieldType.DATE, FormRowFieldType.TIME].includes(type.type));
  const firstRowItems = addFieldsToExistingRow ? existingRowItems : firstRow;

  return (
    <div className={classNames("bh-border-pigeon-60 bh-shadow flex flex-col rounded border")} ref={divRef}>
      <div className="flex flex-row">
        {firstRowItems.map((field, index) => {
          return (
            <FormBaseFieldsAddContainer
              index={index}
              text={field.text}
              image={field.image}
              onClickCallback={() => onFieldSelectCallback(field)}
              key={index}
              classes={classNames(!addFieldsToExistingRow && "border-b")}
            />
          );
        })}
      </div>
      {!addFieldsToExistingRow && (
        <div className="flex flex-row">
          {secondRow.map((field, index) => {
            return <FormBaseFieldsAddContainer index={index} text={field.text} image={field.image} onClickCallback={() => onFieldSelectCallback(field)} key={index} classes={"border-b-0"} />;
          })}
        </div>
      )}
    </div>
  );
};

export default FormBaseFields;
