import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import BhTableHeader from "@components/table/BhTableHeader";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { useTranslation } from "react-i18next";
import CompanySettingsChecklistBaseListRowContainer from "@components/checklists/settings/companySettings/CompanySettingsChecklistBaseListRowContainer";
import { useAppSelector } from "@/app/hooks";
import { selectActivatedBasesInCompanyProjects, selectCompanyChecklistBasesCreatedInProjects, selectCompanyChecklistBasesNotCreatedInProjects } from "@/app/store/checklist/checklistsSlice";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import CompanySettingsChecklistsBasketContainer from "@components/checklists/settings/companySettings/CompanySettingsChecklistsBasketContainer";
import { EntityId } from "@reduxjs/toolkit";
import { IChecklistBase } from "@/model/checklist/IChecklistBase";
import ToggleBaseInProjectsModal from "@components/checklists/settings/companySettings/ToggleBaseInProjectsModal";
import { naturalSortByField, naturalSortString } from "@/utilities/sortUtilities";

interface Props {
  companyId: EntityId;
  filter: any;
  setFilter: Dispatch<SetStateAction<any>>;
  isProjectChecklistTable: boolean;
}

const CompanySettingsChecklistTable: FC<Props> = ({ companyId, filter, setFilter, isProjectChecklistTable }) => {
  const { t } = useTranslation();
  const companyChecklistBases = useAppSelector(isProjectChecklistTable ? selectCompanyChecklistBasesCreatedInProjects : selectCompanyChecklistBasesNotCreatedInProjects);
  const basesWithAtLeastOneFinishedVersion = companyChecklistBases.filter((ccb) => ccb.checklistBaseVersions && ccb.checklistBaseVersions.some((cbv) => cbv.status === "FINAL"));
  const activatedBasesInCompanyProjects = useAppSelector(selectActivatedBasesInCompanyProjects);
  const [selectedIds, setSelectedIds] = useState([] as Array<EntityId>);
  const [showProjectsAddModal, setShowProjectsAddModal] = useState(false);
  const [showProjectsRemoveModal, setShowProjectsRemoveModal] = useState(false);
  const [filteredAndSortedChecklistBases, setFilteredAndSortedChecklistBases] = useState([] as Array<any>);

  useEffect(() => {
    if (!showProjectsAddModal) {
      setSelectedIds([]);
    }
  }, [showProjectsAddModal]);

  useEffect(() => {
    if (!showProjectsRemoveModal) {
      setSelectedIds([]);
    }
  }, [showProjectsRemoveModal]);

  useEffect(() => {
    filterAndSortChecklistBases();
  }, [filter, companyChecklistBases]);

  const filterAndSortChecklistBases = () => {
    const filteredChecklistBases = filterChecklistBases(companyChecklistBases);
    const sortedAndFilteredChecklistBases = sortChecklistBases(filteredChecklistBases);
    setFilteredAndSortedChecklistBases(sortedAndFilteredChecklistBases);
  };

  const filterChecklistBases = (checklistBases: Array<IChecklistBase>) => {
    if (filter.searchString && filter.searchString.length > 0) {
      checklistBases = checklistBases.filter((base) => base.type.toLowerCase().includes(filter.searchString.toLowerCase()));
    }
    if (filter.username && filter.username.length > 0) {
      checklistBases = checklistBases.filter((base) => base.createdBy.toLowerCase() === filter.username.toLowerCase());
    }
    if (filter.category) {
      checklistBases = checklistBases.filter((base) => base.category.toUpperCase() === filter.category.categoryName.toUpperCase());
    }
    if (filter.projectId) {
      checklistBases = checklistBases.filter((base) => base.createdInProjectId === filter.projectId);
    }
    return checklistBases;
  };

  const sortChecklistBases = (checklistBases: Array<IChecklistBase>) => {
    const filteredChecklistBases = filterChecklistBases(checklistBases);
    if (filter.orderBy === "type") {
      checklistBases = checklistBases.slice().sort((a, b) => naturalSortByField(a, b, filter.orderBy, filter.reversed));
    }
    if (filter.orderBy === "category") {
      checklistBases = filteredChecklistBases
        .slice()
        .sort((a, b) => naturalSortString(t("CHECKLIST.CATEGORY." + a.category.toUpperCase()), t("CHECKLIST.CATEGORY." + b.category.toUpperCase()), filter.reversed));
    }
    if (filter.orderBy === "created") {
      checklistBases = filteredChecklistBases
        .slice()
        .sort((a, b) =>
          a.checklistBaseVersions.reduce((max, row) => (max.versionNumber > row.versionNumber ? max : row)).created >
          b.checklistBaseVersions.reduce((max, row) => (max.versionNumber > row.versionNumber ? max : row)).created
            ? filter.reversed
              ? -1
              : 1
            : filter.reversed
            ? 1
            : -1
        );
    }
    if (filter.orderBy === "active") {
      const activatedBases = filteredChecklistBases
        .filter((base) => activatedBasesInCompanyProjects.some((pcb) => pcb.checklistBaseId === base.id))
        .sort((a, b) => naturalSortByField(a, b, "type", filter.reversed));
      const inactiveBases = filteredChecklistBases
        .filter((base) => !activatedBasesInCompanyProjects.some((pcb) => pcb.checklistBaseId === base.id))
        .sort((a, b) => naturalSortByField(a, b, "type", filter.reversed));
      checklistBases = filter.reversed ? [...inactiveBases, ...activatedBases] : [...activatedBases, ...inactiveBases];
    }
    return checklistBases;
  };

  const toggleSelectAllRows = () => {
    if (selectedIds.length === basesWithAtLeastOneFinishedVersion.length) {
      setSelectedIds([]);
    } else {
      const allIds = basesWithAtLeastOneFinishedVersion.map((cb) => cb.id);
      setSelectedIds(allIds);
    }
  };

  const toggleRowSelection = (row: IChecklistBase) => {
    const rowAlreadySelected = selectedIds.find((id) => id === row.id);
    if (rowAlreadySelected) {
      setSelectedIds(selectedIds.filter((id) => id !== row.id));
    } else {
      setSelectedIds([...selectedIds, row.id]);
    }
  };

  const tableColumnHeaders: Array<any> = [
    !isProjectChecklistTable && {
      id: 0,
      content: <BhCheckbox large={true} property={"allChecked"} onChange={toggleSelectAllRows} isChecked={selectedIds.length === basesWithAtLeastOneFinishedVersion.length} />,
      classes: "w-10"
    },
    { id: 1, content: t("CHECKLIST.BASE.NAME"), field: "type", sortable: true },
    { id: 2, content: t("CHECKLIST_BASE.CATEGORY"), field: "category", sortable: true, classes: "w-32" },
    { id: 3, content: t("CHECKLIST.CHECKLIST_VERSION"), classes: "w-24" },
    { id: 4, content: t("CHECKLIST.CREATED"), field: "created", sortable: true, classes: "w-44" },
    isProjectChecklistTable && { id: 5, content: t("CHECKLIST.BASE.PROJECT"), classes: "w-80" },
    isProjectChecklistTable && { id: 6, content: t("CHECKLIST.ACTIVE_IN_PROJECT"), classes: "w-16 whitespace-nowrap", sortable: true, field: "active" },
    !isProjectChecklistTable && { id: 7, content: t("CHECKLIST.ACTIVE_IN_PROJECTS"), classes: "w-40" },
    { id: 8, content: "", classes: "w-10" }
  ].filter(Boolean);

  const onTableHeaderClickCallback = (column: IBhTableHeader<IChecklistBase>) => {
    if (column.field) {
      setFilter({ ...filter, orderBy: column.field, reversed: column.field === filter.orderBy ? !filter.reversed : filter.reversed });
    }
  };

  return (
    <div>
      <table className="h-full w-full">
        <BhTableHeader columns={tableColumnHeaders} sortedBy={filter.orderBy} reversed={filter.reversed} onClickCallback={onTableHeaderClickCallback} />
        <tbody>
          {filteredAndSortedChecklistBases.length > 0 &&
            filteredAndSortedChecklistBases.map((checklistBase) => {
              return (
                <CompanySettingsChecklistBaseListRowContainer
                  checklistBase={checklistBase}
                  key={checklistBase.id}
                  selectRow={() => toggleRowSelection(checklistBase)}
                  isRowSelected={selectedIds.some((id) => id === checklistBase.id)}
                  companyId={companyId}
                  isProjectChecklistBase={isProjectChecklistTable}
                />
              );
            })}
          {isProjectChecklistTable && filteredAndSortedChecklistBases.length === 0 && (
            <tr>
              <td colSpan={6} className="pt-10 text-center">
                <h2>{t("CHECKLIST_BASE.NO_BASES_CREATED_IN_PROJECTS")}</h2>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {selectedIds.length > 0 && (
        <CompanySettingsChecklistsBasketContainer
          selectedIds={selectedIds}
          allSelected={selectedIds.length === basesWithAtLeastOneFinishedVersion.length}
          resetSelected={() => setSelectedIds([])}
          addBasesToProjects={() => setShowProjectsAddModal(true)}
          removeBasesFromProjects={() => setShowProjectsRemoveModal(true)}
        />
      )}
      {showProjectsAddModal && <ToggleBaseInProjectsModal selectedIds={selectedIds} removeBases={false} setIsShown={setShowProjectsAddModal} companyId={companyId} />}
      {showProjectsRemoveModal && <ToggleBaseInProjectsModal selectedIds={selectedIds} removeBases={true} setIsShown={setShowProjectsRemoveModal} companyId={companyId} />}
    </div>
  );
};

export default CompanySettingsChecklistTable;
