import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareKanban } from "@fortawesome/pro-regular-svg-icons/faSquareKanban";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { classNames, getUserFromFullName } from "@/utilities/jsUtilities";
import { faCalendarDay } from "@fortawesome/pro-regular-svg-icons/faCalendarDay";
import BhUserIcon from "@components/user/BhUserIcon";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectTaskById, setOpenedTaskInfo } from "@/app/store/tasksSlice";
import { toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { formatDate } from "@/utilities/dateUtility";
import { isTaskPersonal } from "@/utilities/taskBoardUtil";
import { selectCurrentUser } from "@/app/store/userSlice";

interface Props {
  taskId: EntityId;
  withBorder?: boolean;
  taskDisabled?: boolean;
  isSearchView?: boolean;
}

const BhTaskContainer: FC<Props> = React.memo(({ taskId, withBorder, taskDisabled, isSearchView }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const task = useAppSelector((state) => selectTaskById(state, taskId));
  const isPersonalTaskOfCurrentUser = task && isTaskPersonal(task, currentUser);
  const isPersonalTask = task && !task.taskBoardId;
  const taskOpenDisabled = isPersonalTask && !isPersonalTaskOfCurrentUser;

  if (!task) return null;

  const openTaskModal = () => {
    if (taskOpenDisabled) {
      return;
    }
    dispatch(setOpenedTaskInfo({ id: task.id, disabled: taskDisabled }));
    dispatch(toggleProjectModalsOpen({ modal: "projectTaskModal" }));
  };

  let classes = "bh-bg-white flex h-8 w-auto cursor-pointer flex-row items-center overflow-hidden rounded border p-1";
  if (isSearchView) classes += " hover:bh-bg-mint-50 border-0 transition ease-in-out duration-300 ";
  if (!isSearchView && withBorder) classes += " bh-border-pigeon-40 hover:bh-border-pigeon-70 ";
  if (!isSearchView && !withBorder) classes += " bh-border-white hover:bh-bg-smoke ";

  return (
    <div className={classes} onClick={openTaskModal}>
      <div className="min-w-6 mr-2 flex h-6 w-6 items-center justify-center rounded text-white" style={{ backgroundColor: task.category?.color }}>
        <FontAwesomeIcon icon={faSquareKanban} />
      </div>
      <div className="flex flex-1 overflow-hidden">
        <div className="text-13 overflow-hidden truncate">
          <span className="bh-text-deep-ocean-80 text-nowrap mr-1">{isPersonalTask ? t("GLOBAL.PERSONAL_TASK").toUpperCase() : task.taskIdentifier}</span>
          <span className="bh-text-deep-ocean font-bold">{task.name}</span>
        </div>
      </div>
      <div className="flex flex-row items-center justify-end">
        <div className="mr-2">{task.status && <BhTag type={BhTagType.TASK_STATUS} children={t("TASK." + task.status.toUpperCase())} />}</div>
        {task.deadline && (
          <div className={classNames("bh-text-deep-ocean mr-4 flex flex-row items-center")}>
            <div className="mr-2">
              <FontAwesomeIcon icon={faCalendarDay} />
            </div>
            <div>{formatDate(new Date(task.deadline))}</div>
          </div>
        )}
        {task.assignee && task.assigneeFullName && (
          <div className="w-6">
            <BhUserIcon user={getUserFromFullName(task.assigneeFullName)} size={24} />
          </div>
        )}
      </div>
    </div>
  );
});

export default BhTaskContainer;
