import React, { FC, useEffect } from "react";
import SignInvitesFilterAndSort from "@/views/home/project/detail/signInvites/SignInvitesFilterAndSort";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useLocation } from "react-router-dom";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { fetchUserDeclinedInvitesAsync } from "@/app/store/signInvitesSlice";
import SignInvitesDeclinedList from "@/views/home/project/detail/signInvites/SignInvitesDeclinedList";

interface Props {}

const SignInvitesDeclined: FC<Props> = () => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchUserDeclinedInvitesAsync(projectId));
  }, [location]);

  return (
    <BhScrollableBody header={<SignInvitesFilterAndSort useDeclinedSignInvitesSelector={true} />} keepScrollForKey="signInvitesDeclinedList">
      <SignInvitesDeclinedList />
    </BhScrollableBody>
  );
};

export default SignInvitesDeclined;
