import React, { FC, useEffect, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { IPredefinedWorkGroup } from "@/model/IPredefinedWorkGroup";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { FileTreeType, IFileTree, IFileTreeTemplate } from "@/model/files/IFileTreeTemplate";
import { fetchDefaultFileTreeTemplates } from "@/api/fileTreeAPI";
import { Trans, useTranslation } from "react-i18next";
import BhCombobox from "@components/input/BhCombobox";
import PartyTemplateDirSelectionContainer from "@/views/home/company/detail/companySettingsPartyTemplates/PartyTemplateDirSelectionContainer";
import { findPredefinedWorkGroupToFileTreeTemplateFileRelations, savePredefinedPartyFileTreeRelation } from "@/api/predefinedPartiesAPI";
import { useModal } from "react-modal-hook";
import PartyTemplateChangeAssociatedFileTreeConfirmationModal from "@/views/home/company/detail/companySettingsPartyTemplates/PartyTemplateChangeAssociatedFileTreeConfirmationModal";
import BhInputLabel from "@components/input/BhInputLabel";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { predefinedPartyPropertyChanged, selectCompanyPredefinedPartyById } from "@/app/store/companiesSlice";
import { naturalSortByField } from "@/utilities/sortUtilities";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";

interface Props {
  predefinedPartyId: EntityId;
  companyId: EntityId;
  type: FileTreeType.DRAWINGS | FileTreeType.DOCUMENTS;
}

const PartyTemplateDirSelectionTab: FC<Props> = ({ predefinedPartyId, companyId, type }) => {
  const { t } = useTranslation();
  const predefinedParty = useAppSelector((state) => selectCompanyPredefinedPartyById(state, [companyId, predefinedPartyId]));
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [availablePredefinedFileStructures, setAvailablePredefinedFileStructures] = useState<Array<IFileTreeTemplate>>([]);
  const [selectedPredefinedFileStructure, setSelectedPredefinedFileStructure] = useState<IFileTreeTemplate>({} as IFileTreeTemplate);
  const [predefinedStructureIdToSet, setPredefinedStructureIdToSet] = useState<any>(null);
  const [selectedFolders, setSelectedFolders] = useState<Array<EntityId>>([]);
  const [tree, setTree] = useState({} as any);
  const dispatch = useAppDispatch();

  let property: keyof IPredefinedWorkGroup;
  switch (type) {
    case FileTreeType.DRAWINGS:
      property = "drawingTreeRelations";
      break;
    case FileTreeType.DOCUMENTS:
      property = "documentTreeRelations";
      break;
  }

  const [showChangeFileTreeConfirmationModal, hideChangeFileTreeConfirmationModal] = useModal(
    () => (
      <PartyTemplateChangeAssociatedFileTreeConfirmationModal
        setIsOpen={hideChangeFileTreeConfirmationModal}
        handleConfirm={() => {
          if (predefinedStructureIdToSet) {
            savePredefinedPartyFileTreeRelation(companyId, predefinedPartyId, Object.keys(predefinedStructureIdToSet)[0], []).then((result) => {
              setSelectedFolders(result);
              dispatch(predefinedPartyPropertyChanged({ companyId, predefinedPartyId, property, value: predefinedStructureIdToSet }));
            });
            const newSelectedPredefinedFileStructure = availablePredefinedFileStructures.find((template) => template.id === predefinedStructureIdToSet);
            setSelectedPredefinedFileStructure(newSelectedPredefinedFileStructure || ({} as IFileTreeTemplate));
          }
          setPredefinedStructureIdToSet(null);
          hideChangeFileTreeConfirmationModal();
        }}
        handleCancel={() => {
          setPredefinedStructureIdToSet(null);
          hideChangeFileTreeConfirmationModal();
        }}
      />
    ),
    [predefinedStructureIdToSet, availablePredefinedFileStructures]
  );

  useEffect(() => {
    setIsLoading(true);
    fetchDefaultFileTreeTemplates(companyId, type).then((res) => {
      setAvailablePredefinedFileStructures(res);
      if (predefinedParty && predefinedParty[property]) {
        const selectedFileTree = res.find((tree) => {
          const selectedTreeId = parseInt(Object.keys(predefinedParty[property])[0]);
          return tree.id === selectedTreeId;
        });
        if (selectedFileTree) {
          setSelectedPredefinedFileStructure(selectedFileTree);
          setTree(selectedFileTree.tree);
        }
      }
    });
    setIsLoading(false);
  }, [predefinedParty]);

  useEffect(() => {
    if (selectedPredefinedFileStructure.id)
      findPredefinedWorkGroupToFileTreeTemplateFileRelations(companyId, predefinedPartyId, selectedPredefinedFileStructure.id).then((res) => {
        setSelectedFolders(res);
      });
  }, [selectedPredefinedFileStructure]);

  useEffect(() => {
    if (selectedFolders && selectedFolders.length > 0) {
      let openedTree = { ...tree };
      openTreeForSelectedFolders(openedTree, selectedFolders);
      setTree(openedTree);
    }
  }, [selectedFolders]);

  useEffect(() => {
    setTree(selectedPredefinedFileStructure.tree);
  }, [selectedPredefinedFileStructure]);

  const openTreeForSelectedFolders = (item: IFileTree, selectedFolders: Array<EntityId>) => {
    if (selectedFolders && selectedFolders.some((dirId) => dirId === item.id)) {
      item.open = true;
    } else if (item.children && item.children.length > 0) {
      item.children.forEach((sub) => {
        openTreeForSelectedFolders(sub, selectedFolders);
      });
      item.open = item.children.some((c) => c.open);
    }
  };

  const onPresetTemplateSelect = (template: { fileTreeTemplate: IFileTreeTemplate }) => {
    setPredefinedStructureIdToSet({ [template.fileTreeTemplate.id]: template.fileTreeTemplate.name });
    const newTemplateId = template.fileTreeTemplate.id;
    if (selectedPredefinedFileStructure.id && template.fileTreeTemplate.id !== selectedPredefinedFileStructure.id) {
      showChangeFileTreeConfirmationModal();
    } else {
      savePredefinedPartyFileTreeRelation(companyId, predefinedPartyId, newTemplateId, []).then((result) => {
        setSelectedFolders(result);
        dispatch(predefinedPartyPropertyChanged({ companyId, predefinedPartyId, property, value: { [template.fileTreeTemplate.id]: template.fileTreeTemplate.name } }));
      });
      const newSelectedPredefinedFileStructure = availablePredefinedFileStructures.find((template) => template.id === newTemplateId);
      setSelectedPredefinedFileStructure(newSelectedPredefinedFileStructure || ({} as IFileTreeTemplate));
    }
  };

  const onDirSelect = (folderId: EntityId) => {
    const isSelected = selectedFolders.some((id) => id === folderId);
    let updatedSelectedIds = [] as Array<EntityId>;
    if (isSelected) updatedSelectedIds = selectedFolders.filter((id) => id !== folderId);
    if (!isSelected) updatedSelectedIds = [...selectedFolders, folderId];
    savePredefinedPartyFileTreeRelation(companyId, predefinedPartyId, selectedPredefinedFileStructure.id, updatedSelectedIds).then((res) => setSelectedFolders(res));
  };

  const toggleDirOpen = (dirId: EntityId, isSelected: boolean) => {
    let newTree = { ...tree };
    const buildTree = (tree: IFileTree) => {
      if (tree.id === dirId && !isSelected) tree.open = !tree.open;
      tree.children?.forEach((t) => buildTree(t));
    };

    buildTree(newTree);
    setTree(newTree);
  };

  return (
    <div className="flex h-full flex-col gap-y-8 px-1 pb-44">
      {!isLoading && (
        <>
          <div className="h-full">
            <BhInputLabel>{t("COMPANY.SETTINGS.WORKGROUP.PROJECT_TREE.PICKED_TREE") as string}</BhInputLabel>
            <div className="flex flex-row">
              <div className="flex-1">
                <BhCombobox
                  visibleValueProperty="name"
                  property="fileTreeTemplate"
                  customType={BhDropdownTypeEnum.FILETREETEMPLATE}
                  values={availablePredefinedFileStructures.sort((a, b) => naturalSortByField(a, b, "name")) || []}
                  onSelect={onPresetTemplateSelect}
                  initialValue={selectedPredefinedFileStructure.name || ""}
                />
              </div>
              {selectedPredefinedFileStructure.id && (
                <BhTextOnlyButton
                  buttonProps={{
                    onClick: () => {
                      savePredefinedPartyFileTreeRelation(companyId, predefinedPartyId, selectedPredefinedFileStructure.id, []).then((result) => {
                        setSelectedFolders(result);
                        dispatch(predefinedPartyPropertyChanged({ companyId, predefinedPartyId, property, value: {} }));
                        setSelectedPredefinedFileStructure({} as IFileTreeTemplate);
                      });
                    }
                  }}
                >
                  <Trans>GLOBAL.REMOVE</Trans>
                </BhTextOnlyButton>
              )}
            </div>
          </div>
          <div className="h-full ">
            {selectedPredefinedFileStructure?.name && (
              <PartyTemplateDirSelectionContainer
                fileTree={tree}
                toggleDirOpen={toggleDirOpen}
                fileTreeType={selectedPredefinedFileStructure.type}
                selectedIds={selectedFolders}
                onSelect={onDirSelect}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PartyTemplateDirSelectionTab;
