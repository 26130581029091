import React, { FC, useEffect } from "react";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import BhUserIconWithName from "@components/user/BhUserIconWithName";

interface Props {
  isChecked: boolean;
  onChange: Function;
  user: ISimpleValidUserAuthority;
}

const TaskBoardSettingsWorkGroupUser: FC<Props> = ({ isChecked, onChange, user }) => {


  return (
    <div className="flex h-10 flex-row items-center py-3 pl-8">
      <input className="h-4.5 w-4.5 mr-3 rounded focus:ring-0 focus:ring-offset-0" type="checkbox" checked={isChecked} onChange={() => onChange(user)} />
      <BhUserIconWithName user={user} />
    </div>
  );
};

export default TaskBoardSettingsWorkGroupUser;
