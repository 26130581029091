import React, { ChangeEvent, FC, useId, useRef } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCaretUp } from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { selectFileById } from "@/app/store/filesSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons/faCirclePlus";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import { fetchFileConfirmationFileRevisions } from "@/api/fileConfirmationAPI";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { uploadFileRevisionAsync } from "@/app/store/uploadSlice";
import { IFileEntity } from "@/model/files/IFileEntity";
import { replaceFileWithLatestVersionAsync } from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";

interface Props {
  fileEntityId: EntityId;
  fileConfirmationId: EntityId;
  parentFileEntityId: EntityId;
  revision: number;
  canUploadNewVersion: boolean;
  isRevision?: boolean;
  setRevisionShown: Function;
  revisionsShown: boolean;
  setRevisions: Function;
}

const ConfirmationFileCardRevisionNumber: FC<Props> = ({
  fileEntityId,
  fileConfirmationId,
  parentFileEntityId,
  revision,
  canUploadNewVersion,
  isRevision,
  setRevisionShown,
  revisionsShown,
  setRevisions
}) => {
  const { t } = useTranslation();
  const fileRevisionInputRef = useRef(null);
  const fileEntity = useAppSelector((state) => selectFileById(state, fileEntityId));
  const projectId = useAppSelector(selectCurrentProjectId);
  const inputId = useId();
  const dispatch = useAppDispatch();

  const onFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;
    for (let i = 0; i < files.length; ++i) {
      const file = files[i];
      if (!parentFileEntityId) return;
      dispatch(uploadFileRevisionAsync({ file: file, projectId: projectId, revisionFile: { id: fileEntityId, parentFileEntityId: parentFileEntityId } as IFileEntity })).then(() => {
        dispatch(replaceFileWithLatestVersionAsync({ fileId: fileEntityId, fileConfirmationId }));
      });
    }
    event.target.value = "";
  };

  return (
    <div className="flex flex-row items-center justify-start overflow-hidden">
      <div className="w-8">
        {canUploadNewVersion && (
          <>
            <BhTooltip body={t("GLOBAL.ADD_NEW_VERSION")}>
              <BhIconButton
                icon={faCirclePlus}
                buttonProps={{
                  onClick: (e: any) => {
                    e.preventDefault();
                    // @ts-ignore
                    fileRevisionInputRef.current.click();
                  }
                }}
                sizeClasses="hidden group-hover:block w-6 h-8"
              />
            </BhTooltip>
            <input type="file" id={inputId} ref={fileRevisionInputRef} style={{ display: "none" }} multiple={false} onChange={onFileSelect} />
          </>
        )}
      </div>
      {!isRevision && revision > 1 && (
        <div className="-ml-2">
          <BhIconButton
            icon={revisionsShown ? faCaretUp : faCaretDown}
            right={true}
            iconSize={"xs"}
            buttonProps={{
              onClick: (e: any) => {
                e.stopPropagation();
                setRevisionShown();
                fetchFileConfirmationFileRevisions(fileConfirmationId, fileEntityId).then((results) => {
                  const sorted = results.sort((a, b) => (a.revision > b.revision ? -1 : 1));
                  setRevisions(sorted);
                });
              }
            }}
          >
            {revision}
          </BhIconButton>
        </div>
      )}
      {(isRevision || revision === 1) && <span className="text-12px pl-1">{revision}</span>}
    </div>
  );
};

export default ConfirmationFileCardRevisionNumber;
