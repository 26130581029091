import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchCompanyAsync, selectCompaniesLoading } from "@/app/store/companiesSlice";
import { EntityId } from "@reduxjs/toolkit";
import NavbarGlobal from "@/views/home/navbar/NavbarGlobal";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import CompanySettingsContainer from "./detail/CompanySettingsContainer";

interface Props {
  companyId: EntityId;
}

const Company: FC<Props> = ({ companyId }) => {
  useDocumentTitle("COMPANY.SETTINGS.TITLE.COMPANY_SETTINGS");
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectCompaniesLoading);

  useEffect(() => {
    dispatch(fetchCompanyAsync(companyId));
  }, []);

  if (loading) {
    return null;
  }

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <NavbarGlobal companyId={companyId} />
      <div className="flex h-full w-full flex-row overflow-hidden">
        <CompanySettingsContainer companyId={companyId} />
      </div>
    </div>
  );
};

export default Company;
