import React, { FC, Fragment, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchProjectsByCompanyAsync, selectCompanyIdsSortedByName, selectCompanyStateStatus, selectPinnedProjectIds } from "@/app/store/companiesSlice";
import BhCompanyDropdownModalProjectItem from "@components/dropdown/BhCompanyDropdownModalProjectItem";
import { Menu, Transition } from "@headlessui/react";
import { classNames } from "@/utilities/jsUtilities";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchUserUnsignedSignInvitesAsync } from "@/app/store/signInvitesSlice";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BhCompanyDropdownCompanyContainer from "@components/dropdown/BhCompanyDropdownCompanyContainer";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  collapsed: boolean;
  children?: React.ReactNode;
}

const BhCompanyDropdown: FC<Props> = ({ collapsed, children }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const companyStateStatus = useAppSelector(selectCompanyStateStatus);
  const companies = useAppSelector(selectCompanyIdsSortedByName);
  const pinnedProjectIds = useAppSelector(selectPinnedProjectIds);
  const itemClasses = collapsed ? "translate-x-[5.5rem]" : "translate-x-[17.5rem]";

  useEffect(() => {
    if (companyStateStatus === BhStateStatusType.INITIAL) {
      dispatch(fetchProjectsByCompanyAsync());
      dispatch(fetchUserUnsignedSignInvitesAsync());
    }
  }, []);

  return (
    <Menu as="div" className="h-22">
      <Menu.Button as="div" className="flex flex-1 cursor-pointer">
        {children}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {companies && (
          <Menu.Items
            className={classNames("bh-shadow max-h-80-vh bh-bg-raised-white fixed z-20 -ml-4 w-[580px] -translate-y-20 overflow-auto rounded align-top focus-visible:outline-0", itemClasses)}
          >
            {pinnedProjectIds && pinnedProjectIds.length > 0 && (
              <div className="bh-border-pigeon-20 border-b py-4">
                {pinnedProjectIds.map((pinnedProjectId) => {
                  return (
                    <Menu.Item key={pinnedProjectId}>
                      <BhCompanyDropdownModalProjectItem projectId={pinnedProjectId}></BhCompanyDropdownModalProjectItem>
                    </Menu.Item>
                  );
                })}
              </div>
            )}
            {companies.map((companyId) => {
              return <BhCompanyDropdownCompanyContainer key={companyId} companyId={companyId}></BhCompanyDropdownCompanyContainer>;
            })}
            <div className="h-17 flex items-center justify-end">
              <Link to={"/" + ConfigSingleton.getInstance().getConfig().REACT_APP_HOME}>
                <BhTextOnlyButton>
                  {t("PROJECTS.OVERVIEW")}
                  <FontAwesomeIcon icon={faArrowRight} className="ml-1.5" aria-hidden="true" />
                </BhTextOnlyButton>
              </Link>
            </div>
          </Menu.Items>
        )}
      </Transition>
    </Menu>
  );
};

export default BhCompanyDropdown;
