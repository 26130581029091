import React, { FC } from "react";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { useTranslation } from "react-i18next";
import { EntityId } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  companyId: EntityId;
}
const CompanySettingsGetProPlanBanner: FC<Props> = ({ companyId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="bh-bg-deep-ocean h-22 absolute right-0 top-0 flex max-w-min items-center rounded px-8 sm:hidden">
      <div className="bh-text-white flex flex-row items-center md:flex-col">
        <span className="mr-4 whitespace-nowrap text-3xl font-extrabold">
          <span className="mr-1.5 hidden font-normal md:inline-block">Bauhub</span>
          {t("PLANS.PRO")}
        </span>
        <div className="text-16px whitespace-nowrap">
          <p className="md:hidden" dangerouslySetInnerHTML={{ __html: t("COMPANY.SETTINGS.GET_PRO_BANNER") as any }} />
          <BhSecondaryButton buttonProps={{ onClick: () => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${companyId}/billing-plan`) }}>
            <span className="px-2">{t("COMPANY.SETTINGS.GET_PRO_BANNER.MORE_INFO")}</span>
          </BhSecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default CompanySettingsGetProPlanBanner;
