import { IYearlyInvoiceOverview } from "@/model/invoices/IYearlyInvoiceOverview";
import { ICompactTableColumn } from "@components/table/compactTable/BhCompactTable";

export const invoicesTableHeaders: Array<ICompactTableColumn<IYearlyInvoiceOverview>> = [
  { id: 0, label: "Month", rowSpan: 2, classes: "w-40" },
  { id: 1, label: "Manual", colSpan: 3, classes: "w-64" },
  { id: 2, label: "Monthly", colSpan: 3, classes: "w-64" },
  { id: 3, label: "Annual", colSpan: 3, classes: "w-64" },
  { id: 4, label: "Total", colSpan: 2, classes: "w-40 bh-bg-mint-110b" },
  { id: 5, label: "Average project price", rowSpan: 2, classes: "w-24 text-right" },
  { id: 6, label: "Unsettled", rowSpan: 2, classes: "w-24 text-right bh-text-deep-ocean-60" }
];

export const invoicesTableSubHeaders: Array<ICompactTableColumn<IYearlyInvoiceOverview>> = [
  { id: 1, label: "Pcs", classes: "w-18" },
  { id: 2, label: "€", classes: "w-26 text-right" },
  { id: 3, label: "Projects", classes: "w-18" },
  { id: 4, label: "Pcs", classes: "w-18" },
  { id: 5, label: "€", classes: "w-26 text-right" },
  { id: 6, label: "Projects", classes: "w-18" },
  { id: 7, label: "Pcs", classes: "w-18" },
  { id: 8, label: "€", classes: "w-26 text-right flex-grow-0" },
  { id: 9, label: "Projects", classes: "w-18" },
  { id: 10, label: "€", classes: "w-26 text-right bh-bg-mint-120b" },
  { id: 11, label: "Projects", classes: "w-18 bh-bg-mint-120b" }
];
