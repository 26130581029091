import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectAllCompanyActiveProjectIds } from "@/app/store/companiesSlice";
import { EntityId } from "@reduxjs/toolkit";
import { Menu } from "@headlessui/react";
import BhCompanyDropdownModalHeader from "@components/dropdown/BhCompanyDropdownModalHeader";
import BhCompanyDropdownModalProjectItem from "@components/dropdown/BhCompanyDropdownModalProjectItem";

interface Props {
  companyId: EntityId;
}

const BhCompanyDropdownCompanyContainer: FC<Props> = ({ companyId }) => {
  const filteredProjectIds = useAppSelector((state) => selectAllCompanyActiveProjectIds(state, companyId));

  if (filteredProjectIds.length === 0) {
    return <div></div>;
  }

  return (
    <div className="bh-border-pigeon-20 border-b pb-4" key={companyId}>
      <Menu.Item>
        <BhCompanyDropdownModalHeader companyId={companyId}></BhCompanyDropdownModalHeader>
      </Menu.Item>

      {filteredProjectIds.map((projectId) => {
        return (
          <Menu.Item key={projectId}>
            <BhCompanyDropdownModalProjectItem projectId={projectId} dontShowPin={true}></BhCompanyDropdownModalProjectItem>
          </Menu.Item>
        );
      })}
    </div>
  );
};

export default BhCompanyDropdownCompanyContainer;
