import React, { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import BhInputSimple from "@components/input/BhInputSimple";
import BhInputLabel from "@components/input/BhInputLabel";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  initialValue: any;
  property: string;
  onChangeCallback?: Function;
  onEnterCallback?: Function;
  onBlurAction?: Function;
  label?: string | null;
  labelHelper?: string;
  leadingIcon?: IconProp;
  trailingIcon?: IconProp;
  type?: string;
  placeholder?: string;
  validationHelper?: string;
  disabled?: boolean;
  inputClasses?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  required?: boolean;
  autoFocus?: boolean;
  saveCallback?: Function;
  maxLength?: number;
  validationHelperClasses?: string;
  onFocus?: Function;
  debounceLength?: number;
  placeholderDisabled?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  onChangeCallbackOnEveryValue?: boolean;
}

const BhInputStackedLabel: FC<Props> = ({
  property,
  initialValue,
  onChangeCallback,
  onEnterCallback,
  onBlurAction,
  label,
  leadingIcon,
  trailingIcon,
  type,
  placeholder,
  validationHelper,
  disabled,
  inputClasses,
  labelHelper,
  inputRef,
  required,
  autoFocus,
  saveCallback,
  maxLength,
  validationHelperClasses,
  onFocus,
  debounceLength,
  inputProps,
  onChangeCallbackOnEveryValue
}) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        {label && <BhInputLabel>{label}</BhInputLabel>}
        {labelHelper && (
          <div className="text-13px l-h-16px bh-text-deep-ocean-80 px-0.5 py-1.5 font-bold">
            <span className="bh-text-pigeon-70 font-normal italic"> - {labelHelper}</span>
          </div>
        )}
        {required && <span className="bh-text-error-red">*</span>}
      </div>
      <BhInputSimple
        property={property}
        initialValue={initialValue}
        onChangeCallback={onChangeCallback}
        onEnterCallback={onEnterCallback}
        onBlurAction={onBlurAction}
        type={type}
        placeholder={placeholder}
        leadingIcon={leadingIcon}
        trailingIcon={trailingIcon}
        disabled={disabled}
        inputClasses={inputClasses}
        inputRef={inputRef}
        required={required}
        autoFocus={autoFocus}
        saveCallback={saveCallback}
        maxLength={maxLength}
        onFocus={onFocus}
        debounceLength={debounceLength}
        inputProps={inputProps}
        onChangeCallbackOnEveryValue={onChangeCallbackOnEveryValue}
      />
      {validationHelper && <div className={classNames("l-h-14px bh-text-deep-ocean-80 px-0.5 py-1.5 text-sm", validationHelperClasses)}>{validationHelper}</div>}
    </div>
  );
};

export default BhInputStackedLabel;
