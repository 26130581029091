import React, { FC } from "react";
import { BhSectionMessageError, BhSectionMessageSuccess } from "@components/sectionMessage/BhSectionMessages";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boChangeCompanyBillingSetupDoneAsync, boSelectCompany } from "@/app/store/backofficeSlice";

export interface IBOBillingSetupDoneDTO {
  companyId: EntityId;
  billingSetupDone: boolean;
}

const BOCompanyKillswitch: FC = () => {
  const company = useAppSelector(boSelectCompany);
  const dispatch = useAppDispatch();

  const handleBillingSetupDoneChange = () => {
    const dto = { companyId: company.id, billingSetupDone: !company.billingSetupDone };
    dispatch(boChangeCompanyBillingSetupDoneAsync(dto));
  };

  return (
    <div>
      <h2>Automated events status (killswitch)</h2>
      <div className="flex w-full items-center">
        <div className="w-2/5">
          {company.billingSetupDone && (
            <BhSectionMessageSuccess>
              <span className="font-bold">Active.</span> Invoice is sent when billing date arrives.
            </BhSectionMessageSuccess>
          )}
          {!company.billingSetupDone && (
            <BhSectionMessageError>
              <span className="font-bold">Inactive.</span> Invoice is not sent when billing date arrives.
            </BhSectionMessageError>
          )}
        </div>
        <BhTextOnlyButton buttonProps={{ onClick: handleBillingSetupDoneChange }} icon={company.billingSetupDone ? faXmark : undefined}>
          {!company.billingSetupDone ? "Activate" : "Deactivate"}
        </BhTextOnlyButton>
      </div>
    </div>
  );
};

export default BOCompanyKillswitch;
