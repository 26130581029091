import React, { FC, SetStateAction, useCallback } from "react";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhChip from "@components/chips/BhChip";
import { IPredefinedWorkGroup } from "@/model/IPredefinedWorkGroup";
import { WorkGroupType } from "@/model/IWorkGroup";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  newPartyForm: IPredefinedWorkGroup;
  setNewPartyForm: React.Dispatch<SetStateAction<IPredefinedWorkGroup>>;
}

const CreateNewPartyTemplateNameStep: FC<Props> = ({ newPartyForm, setNewPartyForm }) => {
  const { t } = useTranslation();
  const setPartyName = useCallback(
    (changedValue: IPredefinedWorkGroup) => {
      setNewPartyForm({ ...newPartyForm, ...changedValue });
    },
    [newPartyForm]
  );

  const toggleType = (type: WorkGroupType) => {
    setNewPartyForm({ ...newPartyForm, type: type });
  };

  return (
    <div className="mx-28 w-full">
      <h2 className="bh-text-deep-ocean mb-6">
        <Trans>WORKGROUP.NEW.NAME</Trans>
      </h2>
      <div className="w-3/5">
        <BhInputStackedLabel initialValue={newPartyForm.name} property="name" label={t("WORKGROUP.NEW.NAME")} onChangeCallback={setPartyName} />
      </div>
      <div className="mt-6 mb-4">
        <span className="bh-text-deep-ocean-80 mr-2 font-semibold">{t("WORKGROUP.NEW.TYPE")}</span>
        <i>({t("GLOBAL.NOT_MANDATORY")})</i>
      </div>
      <div className="flex items-center gap-2">
        <BhChip isSelected={newPartyForm.type === WorkGroupType.SUBCONTRACTOR} onClick={() => toggleType(WorkGroupType.SUBCONTRACTOR)}>
          {t("PARTY.SUBCONTRACTOR")}
        </BhChip>
        <BhChip isSelected={newPartyForm.type === WorkGroupType.CLIENT} onClick={() => toggleType(WorkGroupType.CLIENT)}>
          {t("PARTY.CLIENT")}
        </BhChip>
      </div>
    </div>
  );
};

export default CreateNewPartyTemplateNameStep;
