import React, { FC } from "react";
import SignInvitesFilterAndSort from "@/views/home/project/detail/signInvites/SignInvitesFilterAndSort";
import SignInvitesUnsignedList from "@/views/home/project/detail/signInvites/SignInvitesUnsignedList";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";

interface Props {}

const SignInvitesUnsigned: FC<Props> = () => {
  return (
    <BhScrollableBody header={<SignInvitesFilterAndSort useUnsignedSignInvitesSelector={true} />} keepScrollForKey="signInvitesUnsignedList">
      <SignInvitesUnsignedList />
    </BhScrollableBody>
  );
};

export default SignInvitesUnsigned;
