import React, { FC } from "react";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { IFileEntity } from "@/model/files/IFileEntity";

interface Props {
  fileEntity: IFileEntity;
}

const RevisionFileContainerIcon: FC<Props> = ({ fileEntity }) => {
  return (
    <>
      <div className="relative flex h-full w-9 flex-none items-center justify-center text-center" onClick={(e) => e.stopPropagation()}>
        <BhFileCardIcon fileEntity={fileEntity} fileCardSize={FileCardSize.LARGE} />
      </div>
    </>
  );
};

export default RevisionFileContainerIcon;
