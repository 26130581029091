import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchAsyncJobAsync, selectAsyncJobById } from "@/app/store/asyncJobsSlice";
import { EntityId } from "@reduxjs/toolkit";
import { delay } from "@/utilities/jsUtilities";

interface Props {
  jobId: EntityId;
}

const AsyncJobController: FC<Props> = ({ jobId }) => {
  const [pollingCounter, setPollingCounter] = useState(0);
  const asyncJob = useAppSelector((state) => selectAsyncJobById(state, jobId));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (asyncJob && ["NEW", "EXECUTING"].includes(asyncJob.status)) {
      pollAsyncJob();
    }
  }, [pollingCounter]);

  async function pollAsyncJob() {
    if (!asyncJob) return;
    dispatch(fetchAsyncJobAsync(asyncJob.id));
    await delay(1500);
    setPollingCounter(pollingCounter + 1);
    // TODO: teha siia mingi circuit breaker
  }

  return <></>;
};

export default AsyncJobController;
