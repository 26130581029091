import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { deleteFilesAsync, resetFileDirectoryModalState, selectDeleteConfirmationModalFileEntityIds, selectFilesByIds } from "@/app/store/filesSlice";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { textWithVariables } from "@/utilities/jsUtilities";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { isDirectory } from "@/utilities/fileEntity/fileEntityUtilities";
import { fullPageLoadingSet } from "@/app/store/globalSlice";

const DeleteConfirmationModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const fileEntityIdsToDelete = useAppSelector(selectDeleteConfirmationModalFileEntityIds);
  const fileEntitiesToDelete = useAppSelector((state) => fileEntityIdsToDelete && selectFilesByIds(state, fileEntityIdsToDelete));
  const [saving, setSaving] = useState(false);

  const deleteFileEntities = () => {
    setSaving(true);
    dispatch(fullPageLoadingSet(true));
    dispatch(deleteFilesAsync())
      .then((result) => {
        dispatch(resetFileDirectoryModalState());
      })
      .finally(() => {
        setSaving(false);
        dispatch(fullPageLoadingSet(false));
      });
  };

  const closeModal = () => {
    if (saving) return;
    dispatch(resetFileDirectoryModalState());
  };

  const containsFolders = fileEntitiesToDelete?.some((fileEntity) => fileEntity && isDirectory(fileEntity));
  const body = containsFolders ? t("MODAL.DELETE.DIR.CONFIRMATION.BODY") : parse(textWithVariables(t("MODAL.DELETE.CONFIRMATION.BODY"), { number: fileEntityIdsToDelete?.length + "" }));

  return (
    <BhDeleteConfirmationModal
      isOpen={true}
      setIsOpen={closeModal}
      header={<h2>{t("MODAL.DELETE.CONFIRMATION.TITLE")}</h2>}
      body={body}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={deleteFileEntities}
      confirmDisabled={saving}
    />
  );
};

export default DeleteConfirmationModal;
