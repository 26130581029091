import React, { Dispatch, FC, useEffect, useRef, useState } from "react";
// @ts-ignore
import { math, StoreyViewsPlugin, Viewer } from "@xeokit/xeokit-sdk";
import { classNames } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";
import { LoadedObjectInfo } from "@/views/home/project/detail/xeokit/XeokitWebViewer";
import { IFileEntity } from "@/model/files/IFileEntity";
import { IFloorplanMetadata } from "@/views/home/project/detail/xeokit/helpers/XeokitHelper";
import { IfcConversionStatus } from "@/model/files/IIfcConversionStatusDTO";
import { bauhubGetWithBaseUrl } from "@/api/bauhubAPI";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BhDropdownButton from "@components/dropdown/BhDropdownButton";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons/faCircleExclamation";
import { EntityId } from "@reduxjs/toolkit";
import { IBimFloorplansDTO } from "@/model/files/IManifestDTO";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUserLanguageForTranslation } from "@/app/store/userSlice";

interface Props {
  isMobile?: boolean;
  projectId: EntityId;
  viewer: Viewer;
  loadedStoreyElevations: Array<LoadedObjectInfo>;
  storeyViewsPluginObject?: StoreyViewsPlugin;
  setFirstPersonActive: Function;
  setToolActive: Dispatch<boolean>;
  setFirstOpen: Dispatch<boolean>;
  floorplansDTO?: IBimFloorplansDTO;
}

class StoreyMap {
  storeyId: string;
  imageData: string;
  format: string;
  width: number;
  height: number;
  aabb?: Array<number>;

  constructor(storeyId: string, imageData: string, format: string, width: number, height: number, aabb?: Array<number>) {
    this.storeyId = storeyId;
    this.imageData = imageData;
    this.format = format;
    this.width = width;
    this.height = height;
    this.aabb = aabb;
  }
}

const XeokitFloorPlanMenu: FC<Props> = ({ isMobile, projectId, viewer, loadedStoreyElevations, storeyViewsPluginObject, setFirstPersonActive, setToolActive, floorplansDTO, setFirstOpen }) => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguageForTranslation);
  const [floorplans, setFloorplans] = useState<Array<IFileEntity>>();
  const [floorplansConversionStatus, setFloorplansConversionStatus] = useState<IfcConversionStatus>();
  const [floorplanMetadata, setFloorplanMetadata] = useState<IFloorplanMetadata | undefined>();
  const [selectedStoreyElevation, setSelectedStoreyElevation] = useState<LoadedObjectInfo>();
  const [floorplanModalOpen, setFloorplanModalOpen] = useState(false);
  const dropdownButtonRef = useRef(null);

  useEffect(() => {
    if (!floorplansDTO) return;

    setFloorplansConversionStatus(floorplansDTO.conversionStatus);
    if (floorplansDTO.conversionStatus === IfcConversionStatus.RUNNING) {
      setFloorplanModalOpen(true);
      return;
    }
    if (floorplansDTO.conversionStatus !== IfcConversionStatus.SUCCESS) {
      return;
    }
    if (floorplansDTO.floorplans.length > 0) {
      const metadataFileName = "floorplan_metadata.json";
      const floorplanImages = floorplansDTO.floorplans.filter((plan) => plan.name !== metadataFileName);
      const floorplanMetadataFile = floorplansDTO.floorplans.find((plan) => plan.name === metadataFileName);
      if (floorplanMetadataFile) {
        bauhubGetWithBaseUrl(floorplanMetadataFile.url).then((responseJson) => {
          setFloorplanMetadata(responseJson);
        });
      }
      setFloorplans(floorplanImages);
    }

    return function cleanup() {
      removePreviousPointerElement();
    };
  }, []);

  useEffect(() => {
    if (isMobile && dropdownButtonRef.current) {
      // @ts-ignore
      dropdownButtonRef.current.click();
    }
  }, [dropdownButtonRef.current]);

  const removePreviousPointerElement = () => {
    const previousMapPointerElement = document.getElementById("mapPointer");
    if (previousMapPointerElement) {
      previousMapPointerElement.remove();
    }
  };

  const selectStoreyElevation = async (floorplan: IFileEntity, storeyElevationToSelect?: LoadedObjectInfo) => {
    if (!storeyElevationToSelect || !viewer) return;

    setSelectedStoreyElevation(storeyElevationToSelect);

    if (!viewer) return;
    if (!storeyViewsPluginObject) return;

    const storeyMapDiv = document.getElementById("storeyMap");
    if (!storeyMapDiv) return;
    storeyMapDiv.innerHTML = "";

    const mapImgElement = new Image();
    mapImgElement.crossOrigin = "";

    let imgWidth = 0;
    let imgHeight = 0;

    await new Promise<void>((resolve, reject) => {
      mapImgElement.onload = function () {
        // because we do 280 x 2 px images in the BE
        imgWidth = mapImgElement.width / 2;
        imgHeight = mapImgElement.height / 2;
        resolve();
      };
      mapImgElement.src = floorplan.url;
    });
    storeyMapDiv.appendChild(mapImgElement);

    const floorplanMetadataAabb = (floorplanMetadata && floorplanMetadata[storeyElevationToSelect?.elevation + ""]) || (floorplanMetadata && floorplanMetadata.sceneBoundaries);

    const storeyMap = new StoreyMap("", floorplan.url, "png", imgWidth, imgHeight, floorplanMetadataAabb);

    mapImgElement.style.width = storeyMap.width + "px";
    mapImgElement.style.height = storeyMap.height + "px";

    removePreviousPointerElement();

    const mapPointerElement = document.createElement("div");
    mapPointerElement.id = "mapPointer";
    mapPointerElement.style.left = "0px";
    mapPointerElement.style.top = "0px";
    mapPointerElement.style.visibility = "hidden";
    storeyMapDiv.appendChild(mapPointerElement);

    const worldPos = math.vec3();
    const tempVec3a = math.vec3();
    // @ts-ignore
    const tempMat4 = math.mat4();

    const pickStoreyMap = (storeyMap: StoreyMap, imagePos: any, options = {}) => {
      const normX = 1.0 - imagePos[0] / storeyMap.width;
      const normZ = 1.0 - imagePos[1] / storeyMap.height;
      const aabb = storeyMap.aabb || viewer.scene.aabb;

      const xmin = aabb[0];
      const ymin = aabb[1];
      const zmin = aabb[2];
      const xmax = aabb[3];
      const ymax = aabb[4];
      const zmax = aabb[5];

      const xWorldSize = xmax - xmin;
      const yWorldSize = ymax - ymin;
      const zWorldSize = zmax - zmin;

      const origin = math.vec3([xmin + xWorldSize * normX, ymin + yWorldSize * 0.5, zmin + zWorldSize * normZ]);
      const direction = math.vec3([0, -1, 0]);
      // @ts-ignore
      const look = math.addVec3(origin, direction, tempVec3a);
      const worldForward = viewer.camera.worldForward;
      // @ts-ignore
      const matrix = math.lookAtMat4v(origin, look, worldForward, tempMat4);

      // This hack is there because pick() can only pick something, if the camera sees the picked point
      const oldCameraLook = viewer.camera.look.slice(0);
      viewer.camera.look = origin;
      const pickResult = viewer.scene.pick({
        pickSurface: true,
        matrix: matrix
      });
      viewer.camera.look = oldCameraLook;

      return pickResult;
    };

    const worldPosToStoreyMap = (storeyMap: StoreyMap, worldPos: any, imagePos: any) => {
      const aabb = storeyMap.aabb || viewer.scene.aabb;

      const xmin = aabb[0];
      const ymin = aabb[1];
      const zmin = aabb[2];

      const xmax = aabb[3];
      const ymax = aabb[4];
      const zmax = aabb[5];

      const xWorldSize = xmax - xmin;
      const yWorldSize = ymax - ymin;
      const zWorldSize = zmax - zmin;

      const camera = viewer.camera;
      const worldUp = camera.worldUp;

      const xUp = worldUp[0] > worldUp[1] && worldUp[0] > worldUp[2];
      const yUp = !xUp && worldUp[1] > worldUp[0] && worldUp[1] > worldUp[2];

      const ratioX = storeyMap.width / xWorldSize;
      const ratioY = yUp ? storeyMap.height / zWorldSize : storeyMap.height / yWorldSize; // Assuming either Y or Z is "up", but never X

      imagePos[0] = Math.floor(storeyMap.width - (worldPos[0] - xmin) * ratioX);
      imagePos[1] = Math.floor(storeyMap.height - (worldPos[2] - zmin) * ratioY);

      return imagePos[0] >= 0 && imagePos[0] < storeyMap.width && imagePos[1] >= 0 && imagePos[1] <= storeyMap.height;
    };

    const worldDirToStoreyMap = (worldDir: any, imageDir: any) => {
      const camera = viewer.camera;
      const eye = camera.eye;
      const look = camera.look;
      // @ts-ignore
      const eyeLookDir = math.subVec3(look, eye, tempVec3a);
      const worldUp = camera.worldUp;
      const xUp = worldUp[0] > worldUp[1] && worldUp[0] > worldUp[2];
      const yUp = !xUp && worldUp[1] > worldUp[0] && worldUp[1] > worldUp[2];
      const zUp = !xUp && !yUp && worldUp[2] > worldUp[0] && worldUp[2] > worldUp[1];
      if (xUp) {
        imageDir[0] = eyeLookDir[1];
        imageDir[1] = eyeLookDir[2];
      } else if (yUp) {
        imageDir[0] = eyeLookDir[0];
        imageDir[1] = eyeLookDir[2];
      } else {
        imageDir[0] = eyeLookDir[0];
        imageDir[1] = eyeLookDir[1];
      }
      // @ts-ignore
      math.normalizeVec2(imageDir);
    };

    mapImgElement.onclick = (e) => {
      const imagePos = [e.offsetX, e.offsetY];
      const pickResult = pickStoreyMap(storeyMap, imagePos);
      if (pickResult) {
        // @ts-ignore
        worldPos.set(pickResult.worldPos);

        const camera = viewer.scene.camera;
        const idx = camera.xUp ? 0 : camera.yUp ? 1 : 2; // Find the right axis for "up"

        const aabb = storeyMap.aabb || viewer.scene.aabb;
        worldPos[idx] = (aabb[idx] + aabb[3 + idx]) / 2;

        const yCoord = (storeyElevationToSelect.elevation || 0) / 1000 + 1.5;
        if (yCoord !== undefined || !isNaN(yCoord)) {
          worldPos[idx] = yCoord;
        }

        viewer.cameraFlight.flyTo(
          {
            eye: worldPos,
            up: viewer.camera.worldUp,
            duration: 0.5
          },
          () => {
            setFirstPersonActive(true);
            viewer.cameraControl.navMode = "firstPerson";
            viewer.cameraControl.constrainVertical = true;
            viewer.cameraControl.followPointer = false;
            viewer.cameraControl.mouseWheelDollyRate = 7;
          }
        );
      }
    };
    //
    const imagePos = math.vec2();
    const worldDir = math.vec3();
    const imageDir = math.vec2();

    const updatePointer = () => {
      const eye = viewer.camera.eye;
      const inBounds = worldPosToStoreyMap(storeyMap, eye, imagePos);
      if (!inBounds) {
        hidePointer();
        return;
      }
      imagePos[0] += mapImgElement.offsetLeft - mapImgElement.scrollLeft + mapImgElement.clientLeft;
      imagePos[1] += mapImgElement.offsetTop - mapImgElement.scrollTop + mapImgElement.clientTop;

      worldDirToStoreyMap(worldDir, imageDir);

      showPointer(imagePos, imageDir);
    };

    viewer.camera.on("viewMatrix", updatePointer);
    // @ts-ignore
    viewer.scene.canvas.on("boundary", updatePointer);

    function hidePointer() {
      mapPointerElement.style.visibility = "hidden";
    }

    function showPointer(imagePos: any, imageDir: any) {
      const angleRad = Math.atan2(imageDir[0], imageDir[1]);
      const angleDeg = Math.floor((180 * angleRad) / Math.PI);

      mapPointerElement.style.left = imagePos[0] - 30 + "px";
      mapPointerElement.style.top = imagePos[1] - 30 + "px";
      mapPointerElement.style.transform = "rotate(" + -(angleDeg - 45) + "deg)";
      mapPointerElement.style.visibility = "visible";
    }
  };

  const closeModal = () => {
    setFloorplanModalOpen(false);
    setToolActive(false);
    setFirstOpen(false);
  };

  const dropdownValues =
    floorplans &&
    floorplans
      .map((floorplan) => {
        const storeyElevation = loadedStoreyElevations.find((storeyElevation) => {
          return storeyElevation.elevation === parseFloat(floorplan.name.replace(".png", ""));
        });
        return storeyElevation
          ? { text: storeyElevation ? storeyElevation.name : floorplan.name, elevation: storeyElevation.elevation, function: () => selectStoreyElevation(floorplan, storeyElevation) }
          : undefined;
      })
      .filter(Boolean)
      // @ts-ignore
      .sort((a, b) => {
        if (!a || a.elevation === undefined) return -1;
        if (!b || b.elevation === undefined) return 1;
        return a.elevation < b.elevation ? 1 : -1;
      });

  const layoutClasses = isMobile ? "text-center items-center m-1" : "bh-shadow absolute max-w-[306px] rounded-lg border p-2 m-2";

  const floorplanConversionSuccess = dropdownValues && dropdownValues.length > 0 && floorplansConversionStatus === IfcConversionStatus.SUCCESS;
  const floorplanConversionRunning = !floorplanConversionSuccess && floorplansConversionStatus === IfcConversionStatus.RUNNING;
  const floorplanConversionFailed =
    floorplansConversionStatus &&
    !floorplanConversionSuccess &&
    [IfcConversionStatus.LAMBDA_FAILED, IfcConversionStatus.BATCH_FAILED, IfcConversionStatus.FAILED, IfcConversionStatus.UNCONVERTABLE].includes(floorplansConversionStatus);

  if (!floorplansConversionStatus) {
    return null;
  }

  return (
    <>
      {!floorplanConversionRunning && (
        <div className={classNames(layoutClasses, "bh-bg-white z-10 flex max-h-[346px] w-full flex-col")}>
          {floorplanConversionFailed && (
            <div className="bh-text-error-red flex w-full flex-row items-center justify-center gap-x-2 p-5">
              <FontAwesomeIcon icon={faCircleExclamation} />
              <div className="whitespace-nowrap">{t("BIM.FLOORPLAN.CONVERSION_FAILED", { lng: currentUserLanguage })}</div>
            </div>
          )}
          {floorplanConversionSuccess && (
            <>
              <div className={classNames(isMobile && !selectedStoreyElevation && "flex h-[284px] flex-col justify-center")}>
                <BhDropdown
                  button={
                    <div
                      ref={dropdownButtonRef}
                      className={classNames(isMobile ? "px-1 pt-1" : "p-2", isMobile && selectedStoreyElevation && "mb-2", "flex cursor-pointer flex-row items-center font-bold")}
                    >
                      <BhDropdownButton title={""} value={selectedStoreyElevation ? selectedStoreyElevation.name : (t("BIM.PLAN.CHOOSE", { lng: currentUserLanguage }) as string)} reversed={true} />
                    </div>
                  }
                  menu={<BhDropdownMenu textProperty="text" type={BhDropdownTypeEnum.STRING} values={dropdownValues} closeOnItemClick={true} />}
                />
              </div>
              <div className={classNames(!selectedStoreyElevation && "hidden", "bh-bg-3d-background h-[284px] w-[284px] overflow-hidden rounded p-0.5")}>
                <div id="storeyMap" className="flex items-center justify-center"></div>
              </div>
            </>
          )}
        </div>
      )}
      {floorplanConversionRunning && (
        <BhModal
          isShown={floorplanModalOpen}
          setIsShown={setFloorplanModalOpen}
          onClose={closeModal}
          header={<h2>{t("BIM.FLOORPLAN.CONVERSION_RUNNING.HEADER", { lng: currentUserLanguage })}</h2>}
          children={<div className="p-8">{t("BIM.FLOORPLAN.CONVERSION_RUNNING.BODY", { lng: currentUserLanguage })}</div>}
          footer={<BhModalFooter onCancel={closeModal} cancelButtonText={t("GLOBAL.CANCEL", { lng: currentUserLanguage }) as string} />}
        />
      )}
    </>
  );
};

export default XeokitFloorPlanMenu;
