import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { useTranslation } from "react-i18next";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { useNavigate } from "react-router-dom";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import { isCurrentUserCompanyAdminWithAccountManagerPrivilege, selectCurrentUserLanguageForTranslation } from "@/app/store/userSlice";

interface Props {
  modalTextKey: string;
  lightBannerTextKey: string;
  proBannerTextKey: string;
  isManager: boolean;
  companyId: EntityId;
}

const CompanySettingsPlanLimitModalContent: FC<Props> = ({ modalTextKey, lightBannerTextKey, proBannerTextKey, isManager, companyId }) => {
  const { t } = useTranslation();
  const isCurrentUserCompanyAdmin = useAppSelector((state) => isCurrentUserCompanyAdminWithAccountManagerPrivilege(state, companyId));
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguageForTranslation);
  const navigate = useNavigate();
  const hasCompanySettingsAccess = isManager || isCurrentUserCompanyAdmin;

  const handlePlanComparisonButtonClick = () => {
    hasCompanySettingsAccess && navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${companyId}/billing-plan`);
    //@ts-ignore
    !hasCompanySettingsAccess && window.open(currentUserLanguage === "et" ? "https://www.bauhub.ee/hinnad" : "https://www.bauhub.ee/prices", "_blank");
  };

  return (
    <div className="flex flex-col gap-y-3 p-8">
      <div className="pb-2" dangerouslySetInnerHTML={{ __html: t(modalTextKey) as any }} />
      <div className="rounded-5px h-full border">
        <div className="bh-bg-white flex h-24 w-full items-center justify-between rounded-t">
          <span className="bh-text-bauhub-green text-38px w-36 shrink-0 p-8 text-center leading-9">Light</span>
          <div className="text-18px w-full" dangerouslySetInnerHTML={{ __html: t(lightBannerTextKey) as any }} />
          <div className="flex w-52 flex-shrink-0 items-center justify-center px-8">
            <div className="flex items-center justify-center gap-2 whitespace-nowrap font-bold">
              <FontAwesomeIcon icon={faCheck} className="bh-text-bauhub-green-120" />
              <span className="shrink-0">{t("TIER.SELECTION.ACTIVE_PLAN")}</span>
            </div>
          </div>
        </div>
        <div className="bh-bg-deep-ocean flex h-24 w-full items-center justify-between rounded-b border-t">
          <span className="bh-text-white font-weight-800 text-38px w-36 shrink-0 p-8 text-center leading-9">Pro</span>
          <div className="bh-text-white text-18px w-full" dangerouslySetInnerHTML={{ __html: t(proBannerTextKey) as any }} />
          <div className="flex w-52 flex-shrink-0 items-center justify-center px-8">
            <BhSecondaryButton buttonProps={{ classes: "text-nowrap", onClick: handlePlanComparisonButtonClick }}>{t("LIGHT_PLAN_LIMIT_MODAL.COMPARE_PLANS")}</BhSecondaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanySettingsPlanLimitModalContent;
