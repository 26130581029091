import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchUserRunningAsyncJobsAsync, selectAsyncJobsIds } from "@/app/store/asyncJobsSlice";
import AsyncJobController from "@/views/home/project/asyncJobs/AsyncJobController";

const AsyncJobsController: FC = () => {
  const asyncJobsIds = useAppSelector(selectAsyncJobsIds);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUserRunningAsyncJobsAsync());
  }, []);

  return (
    <>
      {asyncJobsIds.map((asyncJobId) => (
        <AsyncJobController key={asyncJobId} jobId={asyncJobId} />
      ))}
    </>
  );
};

export default AsyncJobsController;
