import React, { FC } from "react";
import { IBhButtonWithIconProps } from "@components/buttons/IBhButtonProps";
import BhButtonTemplateWithIcon from "@components/buttons/BhButtonTemplateWithIcon";
import { classNames } from "@/utilities/jsUtilities";

// FIGMA https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H?node-id=1416:72983#180001949

const BhWarningButton: FC<IBhButtonWithIconProps> = ({ buttonProps, icon, children }) => {
  buttonProps = buttonProps ?? {};
  buttonProps.classes += classNames(
    !buttonProps.disabled && " bh-bg-warning-yellow hover:bh-bg-warning-yellow-110 active:bh-bg-warning-yellow-120 ",
    buttonProps.disabled && " disabled:bh-bg-pigeon-20 disabled:bh-text-pigeon-60 ",
    " py-1.5 min-w-70px bh-text-deep-ocean "
  );

  return (
    <BhButtonTemplateWithIcon buttonProps={buttonProps} icon={icon}>
      {children}
    </BhButtonTemplateWithIcon>
  );
};

export default BhWarningButton;
