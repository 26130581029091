import React, { FC, useState } from "react";
import { IUser } from "@/model/IUser";
import { IContact } from "@/model/IContact";
import BhUserIcon from "@components/user/BhUserIcon";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { IPartyPrivilegeListItemDTO } from "@/model/party/IPartyPrivilegeListItemDTO";
import { classNames } from "@/utilities/jsUtilities";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import { IUserAuthority } from "@/model/IUserAuthority";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-solid-svg-icons";

interface Props {
  user: IUser | IContact | ISimpleValidUserAuthority | IUserAuthority | ICompanyUser | IPartyPrivilegeListItemDTO;
  size?: "sm";
  showUsername?: boolean;
  withoutRing?: boolean;
  textClasses?: string;
  disablePopup?: boolean;
  reducedOpacity?: boolean;
  adminBadgeVisible?: boolean;
}

const BhUserIconWithName: FC<Props> = ({ user, size, showUsername, withoutRing, textClasses, disablePopup, reducedOpacity, adminBadgeVisible }) => {
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [delayHandler, setDelayHandler] = useState<any>();

  const handleMouseEnter = () => {
    const id = setTimeout(() => {
      setShowAdditionalInfo(true);
    }, 1000);

    setDelayHandler(id);
  };

  const handleMouseLeave = () => {
    clearTimeout(delayHandler);
    setShowAdditionalInfo(false);
  };

  const username = "username" in user && user.username;
  const fullNameOrEmail = user.firstName || user.lastName ? user.firstName + " " + user.lastName : username;

  return (
    <div
      className={classNames(!disablePopup && "cursor-default", "relative flex flex-row items-center whitespace-nowrap")}
      onMouseEnter={!disablePopup && username ? handleMouseEnter : undefined}
      onMouseLeave={!disablePopup && username ? handleMouseLeave : undefined}
    >
      <div className={classNames(reducedOpacity && "opacity-50", "relative")}>
        <BhUserIcon user={user} size={size === "sm" ? 24 : undefined} withoutRing={withoutRing} />
        {adminBadgeVisible && (
          <div className="absolute -top-2 -right-1.5">
            <BhBadge type={BhBadgeType.BRIGHT} classes="px-1">
              <FontAwesomeIcon icon={faStar} size="sm" />
            </BhBadge>
          </div>
        )}
      </div>
      <div className={classNames(reducedOpacity && "opacity-50", "ml-1")}>
        <div className={classNames("mx-1", textClasses || "font-semibold")}>{fullNameOrEmail}</div>
        {showUsername && username && (
          <a className="text-12px mx-1 hover:underline" href={"mailto:" + user.username}>
            {username}
          </a>
        )}
      </div>
      {!disablePopup && showAdditionalInfo && username && (
        <div className="bh-bg-white bh-shadow absolute top-8 left-0 z-30 flex flex-row items-center rounded p-2">
          <BhUserIcon user={user} />
          <div className="ml-1">
            <div className={classNames("mx-1", textClasses || "font-semibold")}>{fullNameOrEmail}</div>
            <a className="text-12px mx-1 hover:underline" href={"mailto:" + user.username}>
              {username}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default BhUserIconWithName;
