import { FileEntityBranch, FolderOpenStatus, IFolderFileEntity } from "@/model/files/IFileEntity";
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { ReactText } from "react";
import { fetchChildrenDirectoriesForDirectory, fetchParentDirectoriesForDirectory } from "@/api/fileAPI";
import { SidebarItemType } from "@/model/ISidebarItem";
import { RootState } from "@/app/store";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { changeBranchType } from "@/app/store/filesSlice";
import { naturalSortFilesByField } from "@/utilities/sortUtilities";
import { findParentFolders } from "@/utilities/fileEntity/fileEntityUtilities";

const foldersAdapter = createEntityAdapter<IFolderFileEntity>({
  sortComparer: (a, b) => naturalSortFilesByField(a, b, "name")
});

const foldersInitialState = foldersAdapter.getInitialState<{
  status: BhStateStatusType;
  planRootDirectoryId: EntityId | undefined;
  documentRootDirectoryId: EntityId | undefined;
  bimRootDirectoryId: EntityId | undefined;
  error: null;
}>({
  status: BhStateStatusType.INITIAL,
  planRootDirectoryId: undefined,
  documentRootDirectoryId: undefined,
  bimRootDirectoryId: undefined,
  error: null
});

export const fetchSubDirsAsync = createAsyncThunk("folders/fetchSubDirs", async (dirId: EntityId) => {
  return fetchChildrenDirectoriesForDirectory(dirId);
});

export const fetchRootSubDirsAsync = createAsyncThunk("folders/fetchRootSubDirs", async (dirId: EntityId) => {
  return fetchChildrenDirectoriesForDirectory(dirId);
});

export const fetchParentDirsAsync = createAsyncThunk("folders/fetchParentDirs", async (dirId: EntityId, { dispatch }) => {
  const response = await fetchParentDirectoriesForDirectory(dirId);
  const currentDir = response.find((fe) => fe.id === dirId);
  if (currentDir) currentDir.selectedInFileTree = true;
  changeBranchType(dispatch, response);
  return { dirId: dirId, response: response };
});

const foldersSlice = createSlice({
  name: "folders",
  initialState: foldersInitialState,
  reducers: {
    allFoldersRemoved: foldersAdapter.removeAll,
    folderAdded: foldersAdapter.addOne,
    foldersAdded: (state, action: PayloadAction<{ files: Array<IFolderFileEntity>; parentId?: EntityId }>) => {
      const { files } = action.payload;
      foldersAdapter.upsertMany(state, files);
      state.status = BhStateStatusType.SUCCESS;
    },
    foldersRemoved: (state, action: PayloadAction<Array<EntityId>>) => {
      foldersAdapter.removeMany(state, action.payload);
    },
    rootFoldersAdded: (state, action: PayloadAction<{ planRootDirectory: IFolderFileEntity; documentRootDirectory: IFolderFileEntity; bimRootDirectory: IFolderFileEntity }>) => {
      const { planRootDirectory, documentRootDirectory, bimRootDirectory } = action.payload;
      state.planRootDirectoryId = planRootDirectory.id;
      state.documentRootDirectoryId = documentRootDirectory.id;
      state.bimRootDirectoryId = bimRootDirectory.id;
      foldersAdapter.upsertMany(state, [planRootDirectory, documentRootDirectory, bimRootDirectory]);
      state.status = BhStateStatusType.SUCCESS;
    },
    resetFoldersSelected: (state) => {
      Object.values(state.entities).forEach((folderFileEntity) => {
        if (folderFileEntity && folderFileEntity.selected) folderFileEntity.selected = false;
      });
    },
    toggleFolderOpen: (state, action: PayloadAction<EntityId>) => {
      const existingFileEntity = state.entities[action.payload];
      if (!existingFileEntity) return;
      existingFileEntity.open = !existingFileEntity.open;
      if (existingFileEntity.openStatus === undefined || existingFileEntity.openStatus === FolderOpenStatus.CLOSED || existingFileEntity.openStatus === FolderOpenStatus.EXPANDED) {
        existingFileEntity.openStatus = FolderOpenStatus.OPEN;
      } else {
        existingFileEntity.openStatus = FolderOpenStatus.CLOSED;
      }
    },
    toggleFolderSelected: (state, action: PayloadAction<EntityId>) => {
      const existingFileEntity = state.entities[action.payload];
      if (!existingFileEntity) return;
      const existingFileEntityValue = existingFileEntity.selected;
      Object.values(state.entities).forEach((folderFileEntity) => {
        if (folderFileEntity && folderFileEntity.selected) folderFileEntity.selected = false;
      });
      if (existingFileEntity) existingFileEntity.selected = !existingFileEntityValue;
      if (existingFileEntity.selected) existingFileEntity.open = false;
    },
    selectedInFileTreeFolderSet: (state, action: PayloadAction<ReactText>) => {
      const id = action.payload;
      Object.values(state.entities).forEach((folderFileEntity) => {
        if (folderFileEntity && folderFileEntity.selectedInFileTree) folderFileEntity.selectedInFileTree = false;
      });
      const existingFileEntity = state.entities[id];
      if (existingFileEntity) {
        existingFileEntity.selectedInFileTree = true;
        existingFileEntity.open = true;
        existingFileEntity.openStatus = FolderOpenStatus.OPEN;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubDirsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchSubDirsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const folderEntities = action.payload;
        if (!folderEntities || folderEntities.length === 0) return;
        foldersAdapter.upsertMany(state, folderEntities);
      })
      .addCase(fetchRootSubDirsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchRootSubDirsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const folderEntities = action.payload;
        foldersAdapter.upsertMany(state, folderEntities);
      })
      .addCase(fetchParentDirsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchParentDirsAsync.rejected, (state, action) => {
        state.status = BhStateStatusType.ERROR;
      })
      .addCase(fetchParentDirsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const folderEntities = action.payload.response;
        folderEntities.forEach((folder) => (folder.openStatus = folder.selectedInFileTree ? FolderOpenStatus.OPEN : FolderOpenStatus.EXPANDED));
        foldersAdapter.upsertMany(state, folderEntities);
      });
  }
});

export const { folderAdded, foldersAdded, foldersRemoved, rootFoldersAdded, selectedInFileTreeFolderSet, toggleFolderOpen, toggleFolderSelected, resetFoldersSelected } = foldersSlice.actions;

export const { selectAll: selectAllFolders, selectById: selectFolderById, selectIds: selectFolderIds } = foldersAdapter.getSelectors((state: RootState) => state.folders);
export const selectFoldersStatus = (state: RootState) => state.folders.status;
export const selectFolderId = (state: RootState, folderId: EntityId) => folderId;

export const selectCurrentRootFolderSubDirIds = createSelector(
  [selectAllFolders, (state) => (state.sidebar.current === SidebarItemType.DRAWINGS ? state.project.current.planRootDirectoryId : state.project.current.documentRootDirectoryId)],
  (folders, rootId) => folders.filter((fileEntity) => fileEntity.parentFileEntityId === rootId).map((fileEntity) => fileEntity.id)
);

export const selectIsFolderDocumentDir = createSelector([selectAllFolders, selectFolderId], (folders, id) => {
  const currentDir = folders.find((folderFileEntity) => folderFileEntity.id === id);
  return currentDir && currentDir.branch === FileEntityBranch.ROOT_DOCUMENT_DIR;
});

export const selectSubDirs = createSelector([selectAllFolders, selectFolderId], (folders, folderId) => folders.filter((fileEntity) => fileEntity.parentFileEntityId === folderId));

export const selectParentDirs = createSelector([selectAllFolders, selectFolderId], (folders, folderId) => {
  return findParentFolders(folders, folderId);
});

export const makeSelectSubDirs = () =>
  createSelector(
    selectAllFolders,
    (state: any, folderId: ReactText) => folderId,
    (folders, folderId) => folders.filter((fileEntity) => fileEntity.parentFileEntityId === folderId).map((fileEntity) => fileEntity.id)
  );

export default foldersSlice.reducer;
