import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectFullPageLoading } from "@/app/store/globalSlice";
import { ReactComponent as BhLogoLoading } from "@svg/bauhub-loading-compressed.svg";

const BhFullPageLoading: FC = () => {
  const show = useAppSelector(selectFullPageLoading);

  if (!show) {
    return null;
  }

  return (
    <div className="bh-bg-smoke z-500 fixed top-0 left-0 flex h-full w-full flex-row items-center justify-center opacity-50">
      <BhLogoLoading className="h-10 w-10" />
    </div>
  );
};

export default BhFullPageLoading;
