import React, { FC } from "react";
import TaskBoardFilter from "@components/taskBoard/TaskBoardFilter";
import TaskBoardSortInSearch from "@components/taskBoard/TaskBoardSortInSearch";

const SearchResultsTaskTabFilters: FC = () => {
  return (
    <div className="flex">
      <TaskBoardSortInSearch />
      <TaskBoardFilter isSearchView={true} />
    </div>
  );
};

export default SearchResultsTaskTabFilters;
