import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/form/formSlice";
import BhWysiwygEditor from "@components/wysiwyg/BhWysiwygEditor";

interface Props {
  field: any;
  saveCallback?: Function;
  disabled: boolean;
}

const FormTextEditorField: FC<Props> = ({ field, saveCallback, disabled }) => {
  const fieldValue = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, field.property));
  const fieldCss = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, field.property + "Css"));

  // Tools for EHITUSOBJEKTI_TOOOHUTUSE_PLAAN_MARU since it's the only form where this component is used
  const toolbarTools = "undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | fontsize";

  return (
    <div className="p-4">
      <BhWysiwygEditor initialValue={fieldValue} property={field.property} saveCallback={saveCallback} contentStyle={fieldCss} toolbarTools={toolbarTools} disabled={disabled} />
    </div>
  );
};

export default FormTextEditorField;
