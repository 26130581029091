import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  children?: React.ReactNode;
  classes?: string;
  colSpan?: number;
  rowSpan?: number;
}

const BhSpreadsheetTableCell: FC<Props> = ({ children, classes, colSpan, rowSpan }) => {
  return (
    <td className={classNames(classes, "text-13px px-2 l-h-14px bh-text-deep-ocean bh-border-pigeon-50 border")} colSpan={colSpan} rowSpan={rowSpan}>
      {children}
    </td>
  );
};

export default BhSpreadsheetTableCell;
