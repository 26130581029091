import React, { FC, useEffect, useState } from "react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { removeChecklistRoundBaseFileAsync, selectChecklistLanguageForTranslationById, selectChecklistRoundById, selectIsChecklistRoundEditable } from "@/app/store/checklist/checklistsSlice";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { fetchFileEntityAsync, fileRemoved, selectFileById } from "@/app/store/filesSlice";
import ChecklistsPdftronViewOnlyContainer from "@/views/home/project/detail/pdftron/ChecklistsPdftronViewOnlyContainer";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";

interface Props {
  checklistRoundId: EntityId;
}

const ChecklistBaseFile: FC<Props> = ({ checklistRoundId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const checklistRound = useAppSelector((state) => selectChecklistRoundById(state, checklistRoundId));
  const projectId = useAppSelector(selectCurrentProjectId);
  const isRoundEditable = useAppSelector((state) => selectIsChecklistRoundEditable(state, projectId, checklistRoundId));
  const checklistLanguage = useAppSelector((state) => checklistRound && selectChecklistLanguageForTranslationById(state, checklistRound.checklistId));
  const baseFileId = checklistRound && checklistRound.baseFileEntityId;
  const baseFileEntity = useAppSelector((state) => baseFileId && selectFileById(state, baseFileId));
  const [rowAnnotationsShown, setRowAnnotationsShown] = useState(false);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);

  useEffect(() => {
    if (baseFileId) {
      dispatch(fetchFileEntityAsync(baseFileId));
    }
  }, [baseFileId]);

  if (!baseFileEntity) return null;

  const removeChecklistRoundBaseFile = () => {
    dispatch(removeChecklistRoundBaseFileAsync(checklistRoundId));
    setDeleteConfirmationModalOpen(false);
    dispatch(fileRemoved(baseFileEntity.id));
  };

  return (
    <div className="bh-bg-smoke max-h-500px mt-4 flex w-full flex-col rounded">
      <div className="mb-2 flex flex-row items-center justify-between pt-5 pl-5 pr-3">
        <div>
          <h3>{t("CHECKLIST.BASE_FILE", { lng: checklistLanguage })}</h3>
        </div>
        <div className="flex flex-row gap-x-2">
          {isRoundEditable && (
            <div>
              <BhTextOnlyButton icon={faTrash} buttonProps={{ onClick: () => setDeleteConfirmationModalOpen(true) }}>
                {t("CHECKLIST.REMOVE_MAIN_PLAN", { lng: checklistLanguage })}
              </BhTextOnlyButton>
            </div>
          )}
          <div>
            <BhTextOnlyButton buttonProps={{ onClick: () => setRowAnnotationsShown(!rowAnnotationsShown) }}>
              {rowAnnotationsShown ? t("CHECKLIST.SHOW_ONLY_TASKS", { lng: checklistLanguage }) : t("CHECKLIST.SHOW_ALL_ANNOTATIONS", { lng: checklistLanguage })}
            </BhTextOnlyButton>
          </div>
        </div>
      </div>
      <div className="h-[500px]">
        <ChecklistsPdftronViewOnlyContainer fileEntityId={baseFileEntity.id} fileEntityUuid={baseFileEntity.uuid} rowAnnotationsShown={rowAnnotationsShown} isRoundEditable={isRoundEditable} />
      </div>
      {deleteConfirmationModalOpen && (
        <BhDeleteConfirmationModal
          isOpen={true}
          setIsOpen={setDeleteConfirmationModalOpen}
          header={<h2>{t("CHECKLIST.REMOVE_BASE_FILE.HEADER")}</h2>}
          body={t("CHECKLIST.REMOVE_BASE_FILE.BODY")}
          confirmationButtonText={t("GLOBAL.DELETE")}
          handleDelete={removeChecklistRoundBaseFile}
        />
      )}
    </div>
  );
};

export default ChecklistBaseFile;
