import React, { Dispatch, FC, SetStateAction } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { classNames } from "@/utilities/jsUtilities";
import { faFolder } from "@fortawesome/pro-solid-svg-icons/faFolder";

interface Props {
  hasSubDirs: boolean;
  isDirOpen: boolean;
  name: string;
  onClickAction: Dispatch<SetStateAction<boolean>>;
  level: number;
}

const ProjectTreeListItem: FC<Props> = ({ hasSubDirs, isDirOpen, name, onClickAction, level }) => {
  const handleDirClick = (e: any) => {
    e.stopPropagation();
    return hasSubDirs ? onClickAction(!isDirOpen) : null;
  };

  return (
    <div className="flex h-7 items-center leading-9">
      <div className={classNames(hasSubDirs && "cursor-pointer", "mx-0.5")} onClick={(e) => handleDirClick(e)}>
        {hasSubDirs && <FontAwesomeIcon className="w-4" icon={isDirOpen ? faCaretDown : faCaretRight} size="xs" />}
        <FontAwesomeIcon className={classNames(!hasSubDirs && "!ml-7", " bh-text-pigeon-60 ml-3 mr-2")} icon={faFolder} />
        {name}
      </div>
    </div>
  );
};

export default ProjectTreeListItem;
