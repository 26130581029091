import React, { FC, useEffect } from "react";
import { useAppDispatch } from "@/app/hooks";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { ITabArrayElement } from "@/model/tabs/ITabArrayElement";
import BhTabs from "@components/tabs/BhTabs";
import Checklists from "@/views/home/project/detail/checklists/list/Checklists";
import { useTranslation } from "react-i18next";

interface Props {}

const ChecklistList: FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSidebarCollapsed(false));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.CHECKLISTS));
  }, []);

  const tabs: Array<ITabArrayElement> = [
    {
      id: 0,
      header: t("PROJECT.CHECKLISTS"),
      content: (
        <div className="h-full overflow-hidden">
          <Checklists />
        </div>
      ),
      href: "view"
    },
    {
      id: 1,
      header: t("CHECKLISTS.ARCHIVED"),
      content: <Checklists archived={true} />,
      href: "archived"
    }
  ].filter(Boolean) as Array<ITabArrayElement>;

  return <BhTabs tabs={tabs} router={true} />;
};

export default ChecklistList;
