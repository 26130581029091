import React, { FC } from "react";
import { ChecklistRowFieldType, IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import BhCheckboxWithText from "@components/checkboxes/BhCheckboxWithText";
import BhInputSimple from "@components/input/BhInputSimple";
import BhRadioInput from "@components/input/BhRadioInput";
import BhIconButton from "@components/buttons/BhIconButton";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

interface Props {
  checklistBaseId: EntityId;
  baseVersionRow: IChecklistBaseVersionRow;
  saveCallback: Function;
  disabled?: boolean;
}

const ChecklistBaseRowOptionField: FC<Props> = ({ baseVersionRow, saveCallback, disabled, checklistBaseId }) => {
  const { t } = useTranslation();

  const saveFieldType = (changedObject: any) => {
    const fieldTypeToSave = changedObject.fieldType ? ChecklistRowFieldType.MULTIPLE : ChecklistRowFieldType.SINGLE;
    saveCallback({ fieldType: fieldTypeToSave });
  };

  const addNewRow = () => {
    const existingValues = baseVersionRow.optionFieldValue.values || [];
    const newOrderNumber =
      baseVersionRow.optionFieldValue.values.length > 0 ? baseVersionRow.optionFieldValue.values.reduce((a: any, b: any) => (a.orderNumber > b.orderNumber ? a : b)).orderNumber + 1 : 1;
    const newValue = {
      name: "",
      value: false,
      orderNumber: newOrderNumber,
      baseVersionRowId: baseVersionRow.id
    };
    const valuesToSave = { optionFieldValue: { ...baseVersionRow.optionFieldValue, values: [...existingValues, newValue] } };
    saveCallback(valuesToSave);
  };

  const removeRow = (row: any) => {
    const remainingValues = baseVersionRow.optionFieldValue.values
      .filter((value: any) => value.orderNumber !== row.orderNumber)
      .map((value: any) => {
        if (value.orderNumber > row.orderNumber) {
          return { ...value, ...{ orderNumber: value.orderNumber - 1 } };
        }
        return value;
      });
    const valuesToSave = { optionFieldValue: { ...baseVersionRow.optionFieldValue, ...{ values: remainingValues } } };
    saveCallback(valuesToSave);
  };

  const saveRowValue = (changedObject: any, row: any) => {
    const changedValues = baseVersionRow.optionFieldValue.values.map((value: any) => {
      if (value.orderNumber === row.orderNumber) {
        return { ...row, ...changedObject };
      }
      return value;
    });
    const valuesToSave = { optionFieldValue: { ...baseVersionRow.optionFieldValue, ...{ values: changedValues } } };
    saveCallback(valuesToSave);
  };

  return (
    <div className="flex flex-col p-4">
      <div className="mb-2 flex flex-row items-center">
        <BhCheckboxWithText
          text={t("CHECKLIST.ROW.IS_MULTIPLE_CHOICE")}
          isChecked={baseVersionRow.fieldType === ChecklistRowFieldType.MULTIPLE}
          property={"fieldType"}
          isSmallCheckbox={true}
          onChange={saveFieldType}
          disabled={disabled}
        />
        <BhCheckboxWithText
          text={t("CHECKLIST.ROW.IS_REQUIRED")}
          isChecked={baseVersionRow.required}
          property={"required"}
          isSmallCheckbox={true}
          onChange={saveCallback}
          disabled={!baseVersionRow.editable || disabled}
        />
      </div>
      <div className="flex w-full flex-col">
        <BhInputSimple
          initialValue={baseVersionRow.fieldTitle}
          property={"fieldTitle"}
          placeholder={t("CHECKLIST_BASE.OPTION_FIELD.TITLE_PLACEHOLDER") as string}
          onBlurAction={saveCallback}
          disabled={disabled}
        />
        <div className="pl-10">
          {baseVersionRow.optionFieldValue &&
            baseVersionRow.optionFieldValue.values.map((value: any, index: number) => {
              return (
                <div className="mt-2 flex flex-row items-center gap-x-2" key={index}>
                  <BhRadioInput property={"value"} sizeClasses={"h-4.5 w-4.5"} disabled={true} />
                  <BhInputSimple initialValue={value.name} property={"name"} onBlurAction={(changedObject: any) => saveRowValue(changedObject, value)} disabled={disabled} />
                  {!disabled && <BhIconButton icon={faTrash} buttonProps={{ onClick: () => removeRow(value) }} />}
                </div>
              );
            })}
          {!disabled && (
            <div className="mt-3 ml-6">
              <BhTextOnlyButton icon={faPlus} buttonProps={{ onClick: addNewRow }}>
                {t("CHECKLIST.ROW.ADD_NEW_CHOICE")}
              </BhTextOnlyButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChecklistBaseRowOptionField;
