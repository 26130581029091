import React, { FC, useState } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentSidebarMenuItem, selectSidebarMenuItemsForCurrentUser } from "@/app/store/sidebarSlice";
import { useTranslation } from "react-i18next";
import SidebarNav from "@/views/home/project/sidebar/SidebarNav";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectCurrentProject } from "@/app/store/project/projectSlice";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";

interface Props {}

const SidebarNavMobile: FC<Props> = ({}) => {
  const { t } = useTranslation();
  const currentItem = useAppSelector(selectCurrentSidebarMenuItem);
  const project = useAppSelector(selectCurrentProject);
  const sidebarItems = useAppSelector((state) => selectSidebarMenuItemsForCurrentUser(state, project.id, project));
  const [menuOpen, setMenuOpen] = useState(false);

  const currentMenuItem = Object.entries(sidebarItems)
    .map(([id, navItem]) => navItem)
    .find((navItem) => navItem.type === currentItem);

  if (!currentMenuItem) {
    return null;
  }

  return (
    <div className="w-full">
      <div className="bh-bg-smoke flex h-12 h-8 w-full cursor-pointer flex-row items-center justify-center pl-6 pr-4 text-center font-bold" onClick={() => setMenuOpen(true)}>
        <FontAwesomeIcon
          icon={currentMenuItem.iconActive || faCheck}
          className={classNames(currentItem === currentMenuItem.type ? "bh-text-bauhub-green-120" : "", "mx-1 h-4 w-4")}
          aria-hidden="true"
        />
        <div className="ml-3 flex flex-row items-center">
          <span>{t(currentMenuItem.nameCode)}</span>
          {/*{currentMenuItem.type && getBadgeForItem(currentMenuItem.type, false)}*/}
        </div>
      </div>
      {menuOpen && (
        <div onClick={() => setMenuOpen(false)} className="z-100 bh-bg-white bh-bg-smoke fixed top-0 left-0 h-screen w-screen overflow-auto">
          <SidebarNav collapsed={false} />
        </div>
      )}
    </div>
  );
};

export default SidebarNavMobile;
