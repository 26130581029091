import { IContact } from "@/model/IContact";
import { IProjectLogQueryDTO } from "@/model/logs/IProjectLogQueryDTO";
import { IProjectLogFilter } from "@/model/logs/IProjectLogFilter";
import { EntityId } from "@reduxjs/toolkit";
import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { HideContactsResponse } from "@/model/contacts/HideContactsResponse";
import { IUserPartyDataResponse } from "@/model/contacts/IContactRightsModal";
import { IWorkGroup } from "@/model/IWorkGroup";
import { ContactStatus } from "@/model/contacts/ContactStatus";

export function fetchProjectContacts(projectId: EntityId): Promise<Array<IContact>> {
  return bauhubGet("/project/" + projectId + "/users/list");
}

export function fetchProjectLog(projectId: EntityId, filter: IProjectLogFilter): Promise<IProjectLogQueryDTO> {
  return bauhubGet("/project/" + projectId + "/log", filter);
}

export function saveContact(contact: IContact): Promise<IContact> {
  return bauhubPost("/project/contact", contact);
}

export function deleteContact(contact: IContact): Promise<IContact> {
  return bauhubPost("/project/contact/" + contact.projectContactId + "/delete");
}

export function hideContact(contact: IContact): Promise<IContact> {
  return bauhubPost("/project/" + contact.projectId + "/contact/" + contact.id + "/hide");
}

export function unhideContact(contact: IContact): Promise<IContact> {
  return bauhubPost("/project/" + contact.projectId + "/contact/" + contact.id + "/unhide");
}

export function toggleHideUserContactsGlobally(userEntityId: EntityId, hide: boolean): Promise<HideContactsResponse> {
  return bauhubPost("/user/" + userEntityId + "/contact/hidden/" + hide);
}

export function fetchContactStatus(projectId: EntityId, contact: IContact): Promise<{ value: ContactStatus }> {
  return bauhubGet("/contact/project/" + projectId + "/status", { username: contact.email });
}

export function fetchContactWorkGroups(projectId: EntityId, contact: IContact): Promise<Array<IWorkGroup>> {
  return bauhubGet("/project/" + projectId + "/user/parties", { username: contact.email });
}

export function fetchContactWorkGroupAuthorities(workGroupId: EntityId, contact: IContact): Promise<IUserPartyDataResponse> {
  return bauhubGet("/user/party/" + workGroupId + "/authorities", { username: contact.email });
}

export function fetchDownloadUrl(projectId: EntityId, form: any): Promise<{ value: string }> {
  return bauhubPost("/project/" + projectId + "/tasks/pdf?st=false", form);
}
