import React, { FC, useState } from "react";
import ProjectTreeListItem from "@/views/home/company/createProject/newProjectFolderStructure/projectTreePreviewModal/ProjectTreeListItem";
import { IFileTree } from "@/model/files/IFileTreeTemplate";

interface Props {
  subDir: IFileTree;
  level: number;
}

const ProjectTreeSubDirContainer: FC<Props> = ({ subDir, level }) => {
  const [isSubDirOpen, setSubDirOpen] = useState<boolean>(false);
  const subDirs = subDir.children;
  const hasSubDirs = subDirs.length > 0;

  return (
    <>
      <ProjectTreeListItem hasSubDirs={hasSubDirs} isDirOpen={isSubDirOpen} name={subDir.name} onClickAction={setSubDirOpen} level={level} />
      {isSubDirOpen && (
        <div className="ml-4">
          {subDirs.map((dir: IFileTree) => {
            return <ProjectTreeSubDirContainer key={dir.id} subDir={dir} level={level + 1} />;
          })}
        </div>
      )}
    </>
  );
};

export default ProjectTreeSubDirContainer;
