import React, { FC } from "react";
import FormFieldContainerInput from "@components/form/base/body/fields/FormFieldContainerInput";
import FormBaseFieldCheckboxWithText from "@components/form/base/body/FormBaseFieldCheckboxWithText";
import { useTranslation } from "react-i18next";
import BhTimePicker from "@components/input/BhTimePicker";
import BhInputSimple from "@components/input/BhInputSimple";
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock";

interface Props {
  fieldValue: any;
  disabled?: boolean;
  saveFieldCallback: Function;
}

const FormBaseTimeField: FC<Props> = ({ fieldValue, saveFieldCallback, disabled }) => {
  const { t } = useTranslation();
  const inputDisabled = fieldValue.prefill && fieldValue.prefill.enabled;

  return (
    <div className="flex flex-col gap-y-1.5">
      <FormFieldContainerInput
        initialValue={fieldValue.label}
        property={"label"}
        disabled={disabled}
        onBlurCallback={saveFieldCallback}
        placeholder={t("FORMBUILDER.FORM.BASE.FIELD.TEXT.LABEL_PLACEHOLDER") as string}
      />
      <div className="flex h-full w-full flex-col justify-between place-self-end">
        <div className="w-full">
          {!inputDisabled && (
            <BhTimePicker
              property={"time"}
              classes={"w-full disabled:bh-bg-smoke"}
              disabled={false}
              saveCallback={(value: any) => saveFieldCallback("time", value.time)}
              initialValue={fieldValue.time}
            />
          )}
          {inputDisabled && (
            <BhInputSimple
              placeholder={t("FORMBUILDER.FORM.BASE.FIELD.TIME_PLACEHOLDER") as string}
              disabled={true}
              initialValue={""}
              property={""}
              trailingIcon={faClock}
              inputClasses="text-ellipsis"
            />
          )}
        </div>
        <div className="mt-1.5">
          <FormBaseFieldCheckboxWithText
            text={t("FORMBUILDER.FORM.BASE.FIELD.TIME_PREFILL")}
            isChecked={fieldValue.prefill && fieldValue.prefill.enabled}
            property={"enabled"}
            disabled={disabled}
            onChangeCallback={(value: any) => saveFieldCallback && saveFieldCallback("prefill", value)}
          />
        </div>
      </div>
    </div>
  );
};

export default FormBaseTimeField;
