import React, { FC } from "react";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { projectsSortSet, selectProjectsSort, selectShowArchivedProjects, toggleArchivedProjects } from "@/app/store/companiesSlice";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import { initialProjectsSort, IProjectsSort } from "@/model/projects/IProjectsSort";
import BhSortButton from "@components/sort/BhSortButton";

const CompanyListSettingsRow: FC = React.memo(() => {
  const { t } = useTranslation();
  const [projectsOrdering, setProjectsOrdering] = useLocalStorage<IProjectsSort>("projectsOrdering", initialProjectsSort);
  const showArchived = useAppSelector((state) => selectShowArchivedProjects(state));
  const sort = useAppSelector(selectProjectsSort);
  const dispatch = useAppDispatch();

  const dropdownMenuValues = [
    { value: "name", translation: t("GLOBAL.ITEM_NAME") },
    { value: "created", translation: t("GLOBAL.DATE") }
  ];

  return (
    <div className="pb-3">
      <h2 className="bh-text-deep-ocean pt-3">{t("HOME.PROJECTS")}</h2>
      <div className="my-2 flex h-8 items-center">
        <BhSortButton sort={sort} localStorageSort={projectsOrdering} setSortForStore={projectsSortSet} setSortForLocalStorage={setProjectsOrdering} sortValues={dropdownMenuValues} />
        <div className="inline-flex items-center">
          <p className="ml-3 mr-1.5">{t("HOME.SHOW_ARCHIVED")}</p>
          <BhToggleSwitch value={showArchived} onClickAction={() => dispatch(toggleArchivedProjects())} small={true} />
        </div>
      </div>
    </div>
  );
});

export default CompanyListSettingsRow;
