import React, { FC, useRef } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  allFileEntitiesUnSelected,
  authoritiesModalFileEntitySet,
  directoryInfoBarTabSet,
  fetchFileEntityAsync,
  fileEntitySelected,
  onlyThisFileEntitySelected,
  setDeleteConfirmationModalFileEntityIds,
  setDirectoryModalsOpen,
  setRenameModalFileEntity,
  setUndoRevisionModalFileEntity,
  toggleFavouriteFileAsync
} from "@/app/store/filesSlice";
import { FileEntityBranch, FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import BhIconButton from "@components/buttons/BhIconButton";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons/faEllipsisVertical";
import { IDropdownItem } from "@/model/IDropdownItem";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faUndo } from "@fortawesome/pro-regular-svg-icons/faUndo";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons/faFilePdf";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { faFiles } from "@fortawesome/pro-regular-svg-icons/faFiles";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons/faFileExport";
import { faPen } from "@fortawesome/pro-regular-svg-icons/faPen";
import { faBookmark as faBookmarkRegular } from "@fortawesome/pro-regular-svg-icons/faBookmark";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import DirectoryFileRevisionUpload from "@/views/home/project/detail/directory/DirectoryFileRevisionUpload";
import { IAuthorityBoolean } from "@/model/IUser";
import { selectCurrentUser } from "@/app/store/userSlice";
import { faBookmark as faBookmarkSolid } from "@fortawesome/pro-solid-svg-icons/faBookmark";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DirectoryFileInfoBarTab } from "@/views/home/project/detail/directory/fileInfoBar/DirectoryFileInfoBar";
import { fetchPresignedBatchUrl, fetchUrlForFile } from "@/api/fileAPI";
import { imitateBatchFileDownload, imitateUrlDownload } from "@/utilities/downloadUtilities";
import { canOpenInPdftron, isDirectory } from "@/utilities/fileEntity/fileEntityUtilities";
import { faUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { faClockRotateLeft } from "@fortawesome/pro-regular-svg-icons/faClockRotateLeft";
import { faMessage } from "@fortawesome/pro-regular-svg-icons/faMessage";
import { faLink } from "@fortawesome/pro-regular-svg-icons/faLink";
import { copyToClipboard } from "@/utilities/jsUtilities";
import { faTag } from "@fortawesome/pro-regular-svg-icons/faTag";
import { useModal } from "react-modal-hook";
import FileNewTagModal from "@/features/fileContainer/FileNewTagModal";

interface Props {
  fileEntity: IFileEntity;
  privilegesForDirectory: IAuthorityBoolean;
  isRevision?: boolean;
}

const FileContainerDropdown: FC<Props> = ({ fileEntity, privilegesForDirectory, isRevision }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const fileRevisionInputRef = useRef(null);
  const currentUser = useAppSelector(selectCurrentUser);
  const isFileUserCreated = fileEntity.createdBy === currentUser.username;
  const isBimFolderFile = fileEntity.branch === FileEntityBranch.ROOT_BIM_DIR;
  const navigate = useNavigate();
  const [showTagModal, hideTagModal] = useModal(() => <FileNewTagModal hideTagModal={hideTagModal} fileEntityId={fileEntity.id} />, []);

  const handleRenameClick = () => {
    if (!fileEntity) return;
    dispatch(setRenameModalFileEntity(fileEntity));
    dispatch(setDirectoryModalsOpen({ modal: "renameModal", value: true }));
  };

  if (!fileEntity) {
    return null;
  }
  if (!privilegesForDirectory.isRead) {
    return null;
  }

  const generateUrlForClipboard = (fileEntity: IFileEntity) => {
    const projectUrl = ConfigSingleton.getInstance().getConfig().REACT_APP_BASE_URL + "/project/" + fileEntity.projectId;
    if (isBimFolderFile) {
      return projectUrl + "/bim/dir?urlHighlight=" + fileEntity.id;
    } else if (isForm) {
      return projectUrl + "/dir/" + fileEntity.parentFileEntityId + "/form/" + fileEntity.id;
    } else if (isFolder) {
      return projectUrl + "/dir/" + fileEntity.id;
    } else if (isFile) {
      if (canOpenInPdftron(fileEntity)) {
        return projectUrl + "/view/" + fileEntity.id + "/" + fileEntity.uuid;
      } else if (fileEntity.type === FileEntityType.CONTAINER) {
        return projectUrl + "/container/" + fileEntity.id;
      } else {
        return projectUrl + "/dir/" + fileEntity.parentFileEntityId + "?urlHighlight=" + fileEntity.id;
      }
    } else {
      return projectUrl + "/dir/" + fileEntity.parentFileEntityId + "?urlHighlight=" + fileEntity.id;
    }
  };

  const isFile = [FileEntityType.FILE, FileEntityType.CONTAINER].includes(fileEntity.type);
  const isFolder = fileEntity.type === FileEntityType.DIR;
  const isForm = fileEntity.type === FileEntityType.FORM;
  const hasPreviousVersions = fileEntity.revision > 1;

  const actionsMap: Record<string, IDropdownItem> = {
    ADD_NEW_VERSION: {
      text: t("CHECKLIST.ADD_NEW_TEMPLATE_VERSION"),
      function: () => {
        // @ts-ignore
        fileRevisionInputRef.current.click();
      },
      icon: faPlus
    },
    UNDO_LAST_VERSION: {
      text: t("GLOBAL.UNDO_REVISION"),
      function: () => {
        dispatch(setUndoRevisionModalFileEntity(fileEntity));
        dispatch(setDirectoryModalsOpen({ modal: "undoRevisionModal", value: true }));
      },
      icon: faUndo
    },
    OPEN_PDF: {
      text: t("GLOBAL.AS_PDF"),
      function: () => {
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${fileEntity.projectId}/view/${fileEntity.id}`);
      },
      icon: faFilePdf
    },
    CHANGE_NAME: {
      text: t("GLOBAL.RENAME"),
      function: handleRenameClick,
      icon: faPen
    },
    MOVE: {
      text: t("GLOBAL.MOVE"),
      function: () => {
        dispatch(allFileEntitiesUnSelected());
        dispatch(fileEntitySelected({ ids: [fileEntity.id], selected: true }));
        dispatch(setDirectoryModalsOpen({ modal: "moveModal", value: true }));
      },
      icon: faFileExport
    },
    COPY: {
      text: t("GLOBAL.COPY"),
      function: () => {
        dispatch(allFileEntitiesUnSelected());
        dispatch(fileEntitySelected({ ids: [fileEntity.id], selected: true }));
        dispatch(setDirectoryModalsOpen({ modal: "copyModal", value: true }));
      },
      icon: faFiles
    },
    FAVOURITE: {
      text: fileEntity.favourite ? t("GLOBAL.SET_NOT_PINNED") : t("GLOBAL.SET_PINNED"),
      function: () => {
        fileEntity && dispatch(toggleFavouriteFileAsync(fileEntity));
      },
      icon: fileEntity.favourite ? faBookmarkSolid : faBookmarkRegular
    },
    COPY_LINK: {
      text: t("FILE.COPY_LINK"),
      function: () => {
        const urlForClipboard = generateUrlForClipboard(fileEntity);
        copyToClipboard(urlForClipboard, "TOAST.LINK_COPIED");
      },
      icon: faLink
    },
    ADD_TAG: {
      text: t("TAG.CHOOSE"),
      function: showTagModal,
      icon: faTag
    },
    ACCESS: {
      text: t("DIRECTORY.FOLDER_ACCESS"),
      function: () => {
        if (!privilegesForDirectory.isAdmin) return;
        dispatch(allFileEntitiesUnSelected());
        dispatch(fileEntitySelected({ ids: [fileEntity.id], selected: true }));
        dispatch(fetchFileEntityAsync(fileEntity.id));
        dispatch(setDirectoryModalsOpen({ modal: "authoritiesModal", value: true }));
        dispatch(authoritiesModalFileEntitySet(fileEntity.id));
      },
      icon: faUsers
    },
    LOG: {
      text: t(isFolder ? "DIRECTORY.LOG" : "MODAL.FILE_LOG_BUTTON"),
      function: () => {
        if (!privilegesForDirectory.isAdmin) return;
        dispatch(allFileEntitiesUnSelected());
        dispatch(fileEntitySelected({ ids: [fileEntity.id], selected: true }));
        dispatch(setDirectoryModalsOpen({ modal: "fileHistoryModal", value: true }));
      },
      icon: faClockRotateLeft
    },
    COMMENTS: {
      text: t("TASK.COMMENTS"),
      function: () => {
        dispatch(allFileEntitiesUnSelected());
        dispatch(fileEntitySelected({ ids: [fileEntity.id], selected: true }));
        dispatch(directoryInfoBarTabSet(DirectoryFileInfoBarTab.COMMENTS));
      },
      icon: faMessage
    },
    INFO: {
      text: t("GLOBAL.INFO"),
      function: () => {
        dispatch(onlyThisFileEntitySelected({ id: fileEntity.id }));
        dispatch(fileEntitySelected({ ids: [fileEntity.id], selected: true }));
        dispatch(directoryInfoBarTabSet(DirectoryFileInfoBarTab.INFO));
      },
      icon: faInfoCircle
    },
    DOWNLOAD: {
      text: t("GLOBAL.DOWNLOAD"),
      function: () => {
        if (!isDirectory(fileEntity)) {
          fetchUrlForFile(fileEntity.id, false, true, fileEntity.uuid).then((presignedUrl) => {
            imitateUrlDownload(presignedUrl.value);
          });
        } else {
          fetchPresignedBatchUrl([fileEntity.id]).then((result) => imitateBatchFileDownload(result));
        }
      },
      icon: faArrowDownToLine
    },
    DELETE: {
      text: t("GLOBAL.DELETE"),
      function: () => {
        dispatch(setDeleteConfirmationModalFileEntityIds([fileEntity.id]));
        dispatch(setDirectoryModalsOpen({ modal: "deleteConfirmationModal", value: true }));
      },
      icon: faTrash
    },
    SEPARATOR: { separator: true } as IDropdownItem
  };

  const canUndoLastVersion = hasPreviousVersions && (privilegesForDirectory.isAdmin || isFileUserCreated);
  const canEdit = privilegesForDirectory.isAdmin || isFileUserCreated;
  const canChangeName = canEdit;
  const canDelete = canEdit;
  const canAddTag = canEdit;

  const fileActions = [
    privilegesForDirectory.isWrite && isFile && actionsMap.ADD_NEW_VERSION,
    canUndoLastVersion && actionsMap.UNDO_LAST_VERSION,
    isForm && actionsMap.OPEN_PDF,
    canChangeName && actionsMap.CHANGE_NAME,
    !isBimFolderFile && privilegesForDirectory.isAdmin && actionsMap.MOVE,
    !isBimFolderFile && privilegesForDirectory.isAdmin && actionsMap.COPY,
    privilegesForDirectory.isRead && actionsMap.COPY_LINK,
    canAddTag && actionsMap.ADD_TAG,
    privilegesForDirectory.isAdmin && actionsMap.SEPARATOR,
    privilegesForDirectory.isRead && actionsMap.LOG,
    actionsMap.COMMENTS,
    actionsMap.INFO,
    privilegesForDirectory.isRead && actionsMap.DOWNLOAD,
    canDelete && actionsMap.SEPARATOR,
    canDelete && actionsMap.DELETE
  ].filter(Boolean);

  const folderActions = [
    canChangeName && actionsMap.CHANGE_NAME,
    actionsMap.FAVOURITE,
    privilegesForDirectory.isRead && actionsMap.COPY_LINK,
    actionsMap.SEPARATOR,
    canAddTag && actionsMap.ADD_TAG,
    privilegesForDirectory.isAdmin && actionsMap.ACCESS,
    privilegesForDirectory.isRead && actionsMap.LOG,
    actionsMap.COMMENTS,
    actionsMap.INFO,
    privilegesForDirectory.isRead && actionsMap.DOWNLOAD,
    canDelete && actionsMap.SEPARATOR,
    canDelete && actionsMap.DELETE
  ].filter(Boolean);

  const revisionActions = [privilegesForDirectory.isRead && actionsMap.DOWNLOAD].filter(Boolean);

  let fileEllipsisDropdownValues: Array<IDropdownItem> = [];
  if (isFile || isForm) {
    // @ts-ignore
    fileEllipsisDropdownValues = fileActions;
  }
  // @ts-ignore
  if (isFolder) fileEllipsisDropdownValues = folderActions;
  // @ts-ignore
  if (isRevision) fileEllipsisDropdownValues = revisionActions;

  return (
    <>
      {isFile && <DirectoryFileRevisionUpload fileInputRef={fileRevisionInputRef} revisionFile={fileEntity} />}
      <BhDropdown
        button={<BhIconButton icon={faEllipsisVertical} />}
        menu={<BhDropdownMenu values={fileEllipsisDropdownValues} type={BhDropdownTypeEnum.STRING} textProperty="text" />}
        position={BhDropdownPositionEnum.BOTTOM_LEFT}
      />
    </>
  );
};

export default FileContainerDropdown;
