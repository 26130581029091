import React, { FC, useEffect, useState } from "react";
import { useAppDispatch } from "@/app/hooks";
import { fetchPredefinedPartiesAsync, saveNewPredefinedPartyAsync } from "@/app/store/companiesSlice";
import PartyTemplatesList from "@/views/home/company/detail/companySettingsPartyTemplates/PartyTemplatesList";
import { EntityId } from "@reduxjs/toolkit";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { IPredefinedWorkGroup } from "@/model/IPredefinedWorkGroup";

interface Props {
  companyId: EntityId;
}

const CompanySettingsPartyTemplatesTabPro: FC<Props> = ({ companyId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [newPartyTemplateName, setNewPartyTemplateName] = useState<string | undefined>(undefined);
  const [showAddNewPartyModal, hideAddNewPartyModal] = useModal(
    () => (
      <BhModal
        isShown={true}
        setIsShown={hideAddNewPartyModal}
        header={<h2>{t("WORKGROUP.NEW.NAME")}</h2>}
        children={
          <div className="flex flex-col gap-y-8 p-8">
            <BhInputStackedLabel
              label={t("WORKGROUP.NEW.NAME")}
              initialValue={newPartyTemplateName || ""}
              property="name"
              onChangeCallback={(changedValue: { name: string }) => setNewPartyTemplateName(changedValue.name)}
              onBlurAction={(changedValue: { name: string }) => setNewPartyTemplateName(changedValue.name)}
            />
          </div>
        }
        footer={
          <BhModalFooter
            onCancel={() => {
              hideAddNewPartyModal();
              setNewPartyTemplateName(undefined);
            }}
            confirmDisabled={!newPartyTemplateName || newPartyTemplateName?.length === 0}
            onConfirm={() => {
              dispatch(saveNewPredefinedPartyAsync({ companyId: companyId, name: newPartyTemplateName } as IPredefinedWorkGroup));
              setNewPartyTemplateName(undefined);
              hideAddNewPartyModal();
            }}
          />
        }
      />
    ),
    [newPartyTemplateName]
  );

  useEffect(() => {
    dispatch(fetchPredefinedPartiesAsync(companyId));
  }, []);

  return (
    <BhScrollableBody>
      <div className="px-44 pt-6 md:px-8">
        <div className="mt-12 mb-2 flex w-full items-center justify-between">
          <h2>{t("MODAL.NEW_PROJECT.PREDEFINED_WORKGROUPS")}</h2>
          <div className="flex gap-2">
            <BhPrimaryButton icon={faPlus} buttonProps={{ onClick: showAddNewPartyModal }}>
              {t("GLOBAL.CREATE_FORM")}
            </BhPrimaryButton>
          </div>
        </div>
        <PartyTemplatesList companyId={companyId} />
      </div>
    </BhScrollableBody>
  );
};

export default CompanySettingsPartyTemplatesTabPro;
