import React, { FC, useCallback } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { IToggleBarIconValue } from "@components/toggle/IToggleBarIconValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  label?: string;
  values: Array<IToggleBarIconValue>;
  onClickAction: Function;
  selected?: string;
  property?: string;
  classes?: string;
  disabled?: boolean;
}

const BhToggleIconBar: FC<Props> = ({ label, values, onClickAction, property, classes, selected, disabled }) => {
  const onClickHandler = useCallback(
    (value: any) => () => {
      return property ? onClickAction({ [property]: value }) : onClickAction(value);
    },
    [onClickAction]
  );

  return (
    <div className={classes && classes}>
      <div className="text-14 l-h-16 bh-text-deep-ocean-80 px-0.5 font-semibold">{label}</div>
      <div>
        <div className="bh-text-deep-ocean-80 extra-letter-spacing button-text group flex cursor-pointer flex-row  justify-around border text-center font-bold uppercase first:rounded-l last:rounded-r">
          {values.map((value, index) => {
            return (
              <div
                key={index}
                className={classNames(
                  disabled && "bh-text-deep-ocean-60 cursor-not-allowed bg-transparent",
                  selected === value.textValue ? "bh-border-pigeon-60 bh-bg-smoke bh-text-deep-ocean-80 -my-px border first:-ml-px last:-mr-px" : "",
                  "hover:bh-bg-pigeon-20 grow first:rounded-l last:rounded-r"
                )}
                onClick={onClickHandler(value)}
                data-tooltip-id={value.tooltipText ? "bh-tooltip" : undefined}
                data-tooltip-content={value.tooltipText ? value.tooltipText : undefined}
              >
                <FontAwesomeIcon icon={value.icon} className="m-2.5 h-[13px] w-[16px] align-middle" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BhToggleIconBar;
