import { IFileEntityTag } from "@/model/files/IFileEntityTag";
import { IFileEntityComment } from "@/model/files/IFileEntityComment";
import { EntityId } from "@reduxjs/toolkit";

export interface IFileEntity {
  id: EntityId;
  name: string;
  size: number;
  selected: boolean;
  folderActive: boolean;
  selectedInFileTree: boolean;
  compressedSize: number;
  projectId: EntityId;
  children: Array<EntityId>;
  parentFileEntityId: EntityId;
  uuid: string;
  contentType: string;
  uploaderUserEntityId: EntityId;
  filePath: string;
  revision: number;
  isActive: boolean;
  isDeleted: boolean;
  thumbnailUuid: string;
  compressedUuid: string;
  commentCount: number;
  comments: Array<IFileEntityComment>;
  branch: FileEntityBranch;
  tags: Array<IFileEntityTag>;
  type: FileEntityType;
  locked: boolean;
  createdByFullName: string;
  updatedByFullName: string;
  created: Date;
  createdBy: string;
  updated: Date;
  invitesCount: number;
  declinedInvitesCount: number;
  signaturesCount: number;
  queueEnabled: boolean;
  queueEnabler: EntityId;
  url: string;
  urlCreated: number; // Date().getTime()
  status: FileEntityConfirmationStatus;
  confirmationUserCount: number;
  confirmedCount: number;
  resource: string;
  resourceId: number;
  favourite: boolean;
  convertedToXkt: boolean;
  pendingConversion: boolean;
  thumbUrl: string;
  companyId: EntityId;
}

export enum FolderOpenStatus {
  OPEN = "OPEN",
  EXPANDED = "EXPANDED",
  CLOSED = "CLOSED"
}

export interface IFolderFileEntity extends IFileEntity {
  open: boolean;
  openStatus?: FolderOpenStatus;
  subDirs: Array<IFolderFileEntity>;
  subDirsFetched?: boolean;
}

export interface IUploadFileEntity extends IFileEntity {
  uploading: boolean;
  uploaded: string;
  percentage: number;
  errorUploading: boolean;
  thumbUrl: string;
}

export interface IRevisionMatcherFileEntity extends IFileEntity {
  fileToUpload: File;
}

export enum FileEntityBranch {
  ROOT_DIR = "ROOT_DIR",
  ROOT_DOCUMENT_DIR = "ROOT_DOCUMENT_DIR",
  ROOT_BIM_DIR = "ROOT_BIM_DIR",
  CONTRACT_DIRECTORY = "CONTRACT_DIRECTORY"
}

export enum FileEntityType {
  DIR = "DIR",
  FILE = "FILE",
  ATTACHMENT = "ATTACHMENT",
  CONTAINER = "CONTAINER",
  FORM = "FORM",
  ATTACHMENT_CONTAINER = "ATTACHMENT_CONTAINER",
  ACT = "ACT",
  ACT_PDF = "ACT_PDF",
  CONFIRMATION_PDF = "CONFIRMATION_PDF",
  ROOT_DOCUMENT_DIR = "ROOT_DOCUMENT_DIR",
  ROOT_DIR = "ROOT_DIR",
  DOCUMENT = "DOCUMENT",
  CONTRACT_DIRECTORY = "CONTRACT_DIRECTORY",
  SIGNATURE = "SIGNATURE",
  CHECKLIST = "CHECKLIST"
}

export enum FileEntityConfirmationStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  DECLINED = "DECLINED"
}

export interface ReplaceRevisionDTO {
  newRevision: IFileEntity;
  latestRevisionOfReplacementFile: IFileEntity;
}

export interface IFileCopyOrMoveDTO {
  fileToCopyOrMove: IFileEntity;
  destinationDirectory: IFileEntity;
  matchingFile: IFileEntity;
}

export interface IFileMoveOrCopyRequestDTO {
  fileIds: Array<EntityId>;
  destinationId: EntityId;
  fromId: EntityId;
  projectId: EntityId;
  mergeFolders: boolean;
  keepSameName: boolean;
  copyRevisions?: boolean;
  fileRevisionRequests?: Array<IRevisionReplaceRequestDTO>;
}

export interface IRevisionReplaceRequestDTO {
  fileToReplaceId: EntityId;
  replacementFileId: EntityId;
  revisionOption: NewVersionOption;
  stackDraggedFile: boolean;
  stackFiles: boolean;
  combineFiles: boolean;
}

export enum NewVersionOption {
  ONLY_DRAGGED_FILE_AS_NEW_REVISION = "ONLY_DRAGGED_FILE_AS_NEW_REVISION",
  STACK_DRAGGED_FILE_REVISIONS = "STACK_DRAGGED_FILE_REVISIONS",
  COMBINE_FILE_REVISIONS_ACCORDING_TO_TIME = "COMBINE_FILE_REVISIONS_ACCORDING_TO_TIME"
}

export interface IFileMatch {
  file: IRevisionMatcherFileEntity;
  matchingFile: IFileEntity;
  bestDistance: IDistance;
}

export interface IDistance {
  distance: number;
  stringComparisonDistance: number;
  dirFileId: EntityId;
}

export enum RevisionFileVersion {
  OLD = "OLD",
  NEW = "NEW"
}

export enum FileEntityResource {
  ACT = "ACT",
  FILE_CONFIRMATION = "FILE_CONFIRMATION",
  CONTRACT = "CONTRACT",
  MARU_REPORT = "MARU_REPORT",
  CHECKLIST_ROUND = "CHECKLIST_ROUND",
  CHECKLIST_BASE = "CHECKLIST_BASE",
  CHECKLIST_BASE_VERSION_ROW = "CHECKLIST_BASE_VERSION_ROW",
  FLOORPLAN = "FLOORPLAN",
  FORM_BASE_PDF = "FORM_BASE_PDF"
}
