import React, { Dispatch, useEffect } from "react";
import { Viewer } from "@xeokit/xeokit-sdk";
import BhIconButton from "@components/buttons/BhIconButton";
import { ReactComponent as PerspectiveIcon } from "@icons/perspective.svg";
import XeokitMobileMoreToolsModalElement from "@/views/home/project/detail/xeokit/mobile/modals/XeokitMobileMoreToolsModalElement";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUserLanguageForTranslation } from "@/app/store/userSlice";

interface Props {
  toolActive: boolean;
  setToolActive: Dispatch<boolean>;
  closeFloorplan: Function;
  disabled?: boolean;
  viewer?: Viewer;
  isMobile?: boolean;
}

const XeokitOrthoTool = React.memo<Props>(({ toolActive, setToolActive, closeFloorplan, disabled, viewer, isMobile }) => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguageForTranslation);

  useEffect(() => {
    if (!viewer) return;

    viewer.on("reset", () => {
      setToolActive(false);
    });
  }, [viewer]);

  const toggleOrthoActive = () => {
    if (!viewer) return;
    if (disabled) return;

    const newOrthoActive = !toolActive;
    if (newOrthoActive) {
      closeFloorplan();
    }

    const eye = viewer.camera.eye;
    const look = viewer.camera.look;

    const flyToProp = { projection: newOrthoActive ? "ortho" : "perspective", eye, look, duration: 0.5 };

    viewer.cameraFlight.flyTo(flyToProp);

    setToolActive(newOrthoActive);
  };

  if (isMobile) {
    return (
      <XeokitMobileMoreToolsModalElement icon={<PerspectiveIcon />} callback={toggleOrthoActive} disabled={disabled} isActive={toolActive}>
        {t("BIM.TOOL.ORTOGRAPHIC", { lng: currentUserLanguage })}
      </XeokitMobileMoreToolsModalElement>
    );
  }

  return (
    <BhTooltip body={t("BIM.TOOL.ORTOGRAPHIC")}>
      <BhIconButton buttonProps={{ onClick: toggleOrthoActive, disabled: disabled }} isActive={toolActive}>
        <PerspectiveIcon />
      </BhIconButton>
    </BhTooltip>
  );
});

export default XeokitOrthoTool;
