import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveProjectSecurityLevelAsync, selectCurrentProject } from "@/app/store/project/projectSlice";
import BhToggleButtonBar, { IBhToggleButtonBarItem } from "@components/toggle/BhToggleButtonBar";
import { IProject, IProjectSecurityLevelRequest, ProjectSecurityLevel } from "@/model/IProject";
import { useTranslation } from "react-i18next";
import { isCurrentUserMaruProjectManager, isCurrentUserMaruProjectTeamMember } from "@/app/store/userSlice";

const ProjectSecurity: FC = () => {
  const project = useAppSelector(selectCurrentProject);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isCurrentUserMaruPM = useAppSelector((state) => isCurrentUserMaruProjectManager(state, project.id));
  const isCurrentUserMaruTeamMember = useAppSelector((state) => isCurrentUserMaruProjectTeamMember(state, project.id));
  const statusChangeDisabled = isCurrentUserMaruPM || isCurrentUserMaruTeamMember;

  const projectSecurityLevels: Array<IBhToggleButtonBarItem> = Object.keys(ProjectSecurityLevel)
    .reverse()
    .map((level) => {
      return { value: level, text: t("SECURITY.LEVEL." + level) as string };
    });

  const onProjectSecurityChange = (changedValue: IProject) => {
    const changedObject = { ...{ id: project.id }, ...changedValue } as IProjectSecurityLevelRequest;
    dispatch(saveProjectSecurityLevelAsync(changedObject));
  };

  return (
    <div className="flex justify-between pt-4 lg:flex-col">
      <div className="basis-1/2">
        <h3>{t("GLOBAL.SECURITY")}</h3>
        <div className="w-2/3 pt-2">
          <div className="mb-5">{t("GLOBAL.SECURITY.INFO")}</div>
          <div>
            <span className="font-bold">{t("SECURITY.LEVEL.LOW")}</span> – <span>{t("PROJECT.SECURITY.LOW")}</span>
          </div>
          <div>
            <span className="font-bold">{t("SECURITY.LEVEL.MEDIUM")}</span> – <span>{t("PROJECT.SECURITY.MEDIUM")}</span>
          </div>
          <div>
            <span className="font-bold">{t("SECURITY.LEVEL.HIGH")}</span> – <span>{t("PROJECT.SECURITY.HIGH")}</span>
          </div>
        </div>
      </div>
      <div className="basis-1/2">
        <BhToggleButtonBar
          label={t("GLOBAL.SECURITY") ?? ""}
          property="securityLevel"
          items={projectSecurityLevels}
          onClickAction={onProjectSecurityChange}
          selected={project.securityLevel}
          disabled={statusChangeDisabled}
        />
      </div>
    </div>
  );
};

export default ProjectSecurity;
