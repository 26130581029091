import React, { FC } from "react";

interface Props {
  title: string;
  helperText: string;
  children: React.ReactNode;
}

const FormBaseInfoRow: FC<Props> = ({ title, helperText, children }) => {
  return (
    <div className="flex w-full flex-row gap-x-8">
      <div className="flex w-1/3 flex-col">
        <h3>{title}</h3>
        <div>{helperText}</div>
      </div>
      {children}
    </div>
  );
};

export default FormBaseInfoRow;
