import React, { FC, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  fetchProjectWorkGroupPrivilegesAsync,
  fetchWorkGroupTaskBoardsAsync,
  selectCurrentWorkGroupUsersInvites,
  taskBoardAddedToWorkGroup,
  userAddedToTaskboard
} from "@/app/store/project/projectWorkGroupsSlice";
import { fetchProjectTaskBoardsAsync, selectAllTaskBoards, selectPartyAddTaskBoardModal, selectTaskBoardAbbreviations, toggleTaskBoardModalsOpen } from "@/app/store/taskBoardsSlice";
import PartyTaskBoardListItem from "@/views/home/project/detail/users/parties/party/PartyTaskBoardListItem";
import { IPartyTaskBoard } from "@/model/taskBoard/IPartyTaskBoard";
import { IPartyPrivilegeListItemDTO } from "@/model/party/IPartyPrivilegeListItemDTO";
import { IWorkGroup } from "@/model/IWorkGroup";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhModal from "@components/modal/BhModal";
import { findUniqueAbbreviationForTaskBoard } from "@/utilities/taskBoardUtil";
import { ITaskBoard } from "@/model/taskBoard/ITaskBoard";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareKanban } from "@fortawesome/pro-regular-svg-icons/faSquareKanban";
import BhModalFooter from "@components/modal/BhModalFooter";

interface Props {
  workGroup: IWorkGroup;
}

const PartyTaskboardsContainer: FC<Props> = ({ workGroup }) => {
  const { t } = useTranslation();
  const projectTaskBoards = useAppSelector(selectAllTaskBoards);
  const usersInvites = useAppSelector(selectCurrentWorkGroupUsersInvites);
  const [taskBoardList, setTaskBoardList] = useState([] as Array<IPartyTaskBoard>);
  const existingAbbreviations = useAppSelector(selectTaskBoardAbbreviations);
  const defaultNewTaskBoard = { name: "", abbreviation: "" } as IPartyTaskBoard;
  const [newTaskBoard, setNewTaskBoard] = useState(defaultNewTaskBoard);
  let wgAbbrInputRef = useRef<HTMLInputElement>(null);
  const partyAddTaskBoardModalOpen = useAppSelector(selectPartyAddTaskBoardModal);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (workGroup && workGroup.id) {
      dispatch(fetchProjectTaskBoardsAsync(workGroup.projectId));
      if (workGroup.id !== -1) {
        dispatch(fetchWorkGroupTaskBoardsAsync(workGroup.id));
        dispatch(fetchProjectWorkGroupPrivilegesAsync(workGroup.id));
      }
      setNewTaskBoard(defaultNewTaskBoard);
    }
  }, []);

  useEffect(() => {
    if (workGroup && projectTaskBoards && usersInvites) {
      let list = projectTaskBoards.map((ptb) => {
        const usersNotInTaskBoard = usersInvites.filter((userInvite: IPartyPrivilegeListItemDTO) => {
          return userInvite.taskBoardIds.indexOf(ptb.id as number) === -1;
        });
        const currentUsersInvites = usersInvites.filter((userInvite: IPartyPrivilegeListItemDTO) => {
          return userInvite.taskBoardIds.indexOf(ptb.id as number) > -1;
        });
        const relationExists = workGroup.taskBoards.find((tb: ITaskBoard) => {
          return tb.id === ptb.id;
        });
        return {
          id: ptb.id,
          name: ptb.name,
          projectId: ptb.projectId,
          abbreviation: ptb.abbreviation,
          allUsersInThisTaskBoard: ptb.users,
          usersOpen: false,
          currentWgUsersInThisTaskBoard: currentUsersInvites,
          partlySelected: currentUsersInvites.length > 0 && usersNotInTaskBoard.length > 0,
          fullySelected: usersNotInTaskBoard.length === 0 && currentUsersInvites.length > 0,
          relationExists: relationExists ? true : false
        };
      });
      if (workGroup.taskBoards && workGroup.id === -1) {
        // THIS IS ONLY FOR keeping the taskboards in memory before saving a new party
        workGroup.taskBoards.forEach((tb) => {
          const tbExists = projectTaskBoards.find((ptb) => {
            return ptb.id === tb.id;
          });
          if (!tbExists) {
            list = [
              ...list,
              {
                id: tb.id,
                name: tb.name,
                projectId: workGroup.projectId,
                abbreviation: tb.abbreviation,
                allUsersInThisTaskBoard: [],
                usersOpen: false,
                currentWgUsersInThisTaskBoard: usersInvites,
                partlySelected: false,
                fullySelected: true,
                relationExists: false
              }
            ];
          }
        });
      }

      setTaskBoardList(list);
    }
  }, [workGroup, projectTaskBoards, usersInvites]);

  const openAddTaskBoardModal = () => {
    dispatch(toggleTaskBoardModalsOpen({ modal: "partyAddTaskBoardModal" }));
  };

  const closeModal = () => {
    setNewTaskBoard(defaultNewTaskBoard);
    dispatch(toggleTaskBoardModalsOpen({ modal: "partyAddTaskBoardModal" }));
  };

  const submitNewTaskBoard = () => {
    var tb = {
      id: -Math.floor(Math.random() * 100000), // negative id means this is a new taskboard
      name: newTaskBoard.name,
      projectId: workGroup.projectId,
      abbreviation: newTaskBoard.abbreviation,
      allUsersInThisTaskBoard: [],
      usersOpen: false,
      currentWgUsersInThisTaskBoard: usersInvites,
      partlySelected: false,
      fullySelected: true,
      relationExists: false
    } as IPartyTaskBoard;
    setTaskBoardList([...taskBoardList, tb]);
    dispatch(taskBoardAddedToWorkGroup({ workGroupId: workGroup.id, taskBoard: { id: tb.id, name: tb.name, abbreviation: tb.abbreviation } as ITaskBoard }));

    usersInvites.forEach((user: IPartyPrivilegeListItemDTO) => {
      const dto = {
        userEntityId: user.userEntityId,
        inviteId: user.inviteId,
        taskBoardId: tb.id,
        workGroupId: workGroup.id,
        username: user.username
      };
      dispatch(userAddedToTaskboard(dto));
    });
    dispatch(toggleTaskBoardModalsOpen({ modal: "partyAddTaskBoardModal" }));
  };

  const setTaskBoardName = (nameObj: any) => {
    if (wgAbbrInputRef && wgAbbrInputRef.current) {
      wgAbbrInputRef.current.value = findUniqueAbbreviationForTaskBoard(nameObj.name, existingAbbreviations) as string;
      setNewTaskBoard({ ...newTaskBoard, ...{ name: nameObj.name, abbreviation: wgAbbrInputRef.current.value } });
    }
  };
  const setTaskBoardAbbreviation = (nameObj: any) => {
    setNewTaskBoard({ ...newTaskBoard, ...{ abbreviation: nameObj.abbreviation } });
  };

  if (!workGroup) {
    return null;
  }

  return (
    <div className="flex h-full w-full flex-col overflow-auto py-12 px-24">
      <h2>
        <FontAwesomeIcon icon={faSquareKanban} className="mr-3" /> {t("WORKGROUP.NEW.TASKBOARDS")}
      </h2>
      <div className="flex flex-row items-center justify-between">
        <div className="my-6">{t("TASKBOARD.PARTY_TASKBOARDS")}</div>
        {workGroup.id === -1 && (
          <BhPrimaryButton icon={faPlus} buttonProps={{ onClick: openAddTaskBoardModal }}>
            {t("TASKBOARD.CREATE_NEW")}
          </BhPrimaryButton>
        )}
      </div>
      <div>
        <div className="bh-text-deep-ocean mb-3 font-bold">{t("PROJECT.TASKBOARDS")}</div>
        {taskBoardList &&
          taskBoardList.map((taskBoard: IPartyTaskBoard) => {
            return <PartyTaskBoardListItem key={taskBoard.id} workGroup={workGroup} taskBoard={taskBoard} />;
          })}
      </div>
      {partyAddTaskBoardModalOpen && (
        <BhModal
          size="3xl"
          isShown={true}
          setIsShown={() => { }}
          onClose={closeModal}
          header={
            <div className="h-7 pl-4">
              <h2 className="mt-1 leading-7">{t("TASKBOARD.CREATE_NEW")}</h2>
            </div>
          }
          children={
            <div className="bh-border-pigeon-40 border-t px-20 py-14">
              <div className="flex flex-row">
                <div className="w-full flex-1">
                  <BhInputStackedLabel initialValue={newTaskBoard.name} property={"name"} label={t("TASKBOARD.NAME")} onChangeCallback={setTaskBoardName} />
                </div>
                <div className="ml-8 w-56">
                  <BhInputStackedLabel
                    initialValue={newTaskBoard.abbreviation}
                    property={"abbreviation"}
                    label={t("GLOBAL.ABBREVIATION")}
                    inputRef={wgAbbrInputRef}
                    onChangeCallback={setTaskBoardAbbreviation}
                  />
                </div>
              </div>
            </div>
          }
          footer={<BhModalFooter cancelButtonText={t("GLOBAL.CANCEL") as string} confirmButtonText={t("TASKBOARD.SAVE") as string} onCancel={closeModal} onConfirm={submitNewTaskBoard} />}
        />
      )}
    </div>
  );
};

export default PartyTaskboardsContainer;
