import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BillingForm from "@/views/home/project/detail/projectSettings/BillingForm";
import { CompanyInvoiceType, IBillingModel, ProjectInvoiceType } from "@/model/IBillingInfo";
import { useTranslation } from "react-i18next";
import BhModalFooter from "@components/modal/BhModalFooter";
import { IProjectOrder } from "@/model/billing/IProjectOrder";
import { changeProjectBillingRequisites, getProjectActivationBillingModel } from "@/api/billingAPI";
import { IProjectBilling } from "@/model/billing/IProjectBilling";
import { isValidEmails } from "@/utilities/jsUtilities";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentCompanyId } from "@/app/store/companiesSlice";

interface Props {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  projectOrder: IProjectOrder;
  callback?: Function;
}

const CompanySettingsProjectBillingInfoModal: FC<Props> = ({ isShown, setIsShown, projectOrder, callback }) => {
  const [projectBilling, setProjectBilling] = useState<IProjectBilling>(projectOrder.projectBilling);
  const [billingModel, setBillingModel] = useState<IBillingModel>({} as IBillingModel);
  const companyId = useAppSelector(selectCurrentCompanyId);
  const { t } = useTranslation();

  useEffect(() => {
    setProjectBilling(projectOrder.projectBilling);
  }, [projectOrder.projectBilling]);

  useEffect(() => {
    if (companyId && isShown) {
      getProjectActivationBillingModel(companyId, projectOrder.id).then((billingModel) => {
        setBillingModel(billingModel);
      });
    }
  }, [projectBilling]);

  const isSaveButtonDisabled =
    !projectBilling.customerRecipientEmails ||
    projectBilling.customerRecipientEmails.length === 0 ||
    !isValidEmails(projectBilling.customerRecipientEmails) ||
    !projectBilling.customerRecipient ||
    projectBilling.customerRecipient.length === 0 ||
    (!projectBilling.customerCivilian && (!projectBilling.customerRegCode || projectBilling.customerRegCode.length === 0));

  const companyHasMonthlyAggregateInvoice = projectOrder.companyBillingRequisites?.invoiceType === CompanyInvoiceType.AGGREGATE;

  const getProjectBillingWithCompanyBillingValues = () => {
    return {
      ...projectBilling,
      customerRecipientEmails: projectOrder.companyBillingRequisites!.customerRecipientEmails,
      customerRecipient: projectOrder.companyBillingRequisites!.customerRecipient,
      customerAddress: projectOrder.companyBillingRequisites!.customerAddress,
      customerRegCode: projectOrder.companyBillingRequisites!.customerRegCode,
      customerVatNumber: projectOrder.companyBillingRequisites!.customerVatNumber,
      customerAdditionalInfo: projectOrder.companyBillingRequisites!.customerAdditionalInfo
    };
  };

  const onSave = useCallback(async () => {
    setIsShown(false);
    if (projectOrder.companyBillingRequisites && companyId) {
      projectOrder.projectBilling = projectBilling.type === ProjectInvoiceType.MONTH && companyHasMonthlyAggregateInvoice ? getProjectBillingWithCompanyBillingValues() : projectBilling;
      changeProjectBillingRequisites(companyId, projectOrder).then((response) => {
        if (callback) {
          callback(response);
        }
      });
    }
  }, [projectBilling]);

  const onFormChange = (changedValue: IProjectBilling) => {
    setProjectBilling((prevState) => {
      return { ...prevState, ...changedValue };
    });
  };

  const onCancel = () => {
    setIsShown(false);
    setProjectBilling(projectOrder.projectBilling);
  };

  return (
    <BhModal
      isShown={isShown}
      setIsShown={setIsShown}
      size={"2xl"}
      onClose={onCancel}
      header={<h2>{t("PROJECT.BILLING.CHANGE.MODAL.HEADER")}</h2>}
      children={
        <BhScrollableBody>
          <div className="px-8">
            <BillingForm
              onChange={onFormChange}
              billingForm={projectBilling}
              companyHasMonthlyAggregateInvoice={companyHasMonthlyAggregateInvoice}
              companyBillingRequisites={projectOrder.companyBillingRequisites}
              billingModel={billingModel}
              projectName={projectOrder.name}
            />
          </div>
        </BhScrollableBody>
      }
      footer={<BhModalFooter onCancel={onCancel} onConfirm={onSave} confirmDisabled={isSaveButtonDisabled} />}
    />
  );
};

export default CompanySettingsProjectBillingInfoModal;
