import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons/faTriangleExclamation";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { removeProjectAdministratorAuthorityAsync, selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { fetchPendingInvitesForRemovalFromProject } from "@/api/inviteApi";
import { ISignInvite } from "@/model/ISignInvite";
import { getUserFullName } from "@/model/IUser";
import { useTranslation } from "react-i18next";
import { textWithVariables } from "@/utilities/jsUtilities";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons/faSpinner";
import parse from "html-react-parser";

interface Props {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  authority: ISimpleValidUserAuthority;
}

const ProjectAdministratorRemoveModal: FC<Props> = ({ modalOpen, setModalOpen, authority }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const [pending, setPending] = useState({ confirmations: [] as Array<any>, invites: [] as Array<ISignInvite> });
  const anyPending = pending.confirmations.length > 0 || pending.invites.length > 0;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (authority.userEntityId) {
      setIsLoading(true);
      fetchPendingInvitesForRemovalFromProject(projectId, authority.userEntityId).then((response) => {
        setPending({ confirmations: response.fileConfirmations, invites: response.signInvites });
        setIsLoading(false);
      });
    }
  }, []);

  const removeAuthority = () => {
    dispatch(removeProjectAdministratorAuthorityAsync(authority));
  };

  const modalBodyVariableMap: Record<string, string> = {
    username: getUserFullName(authority),
    pendingInvites: pending.invites.length.toString(),
    pendingConfirmations: pending.confirmations.length.toString()
  };

  return (
    <BhModal
      isShown={modalOpen}
      setIsShown={setModalOpen}
      header={
        <div>
          {anyPending && (
            <div className="flex flex-row items-center">
              <FontAwesomeIcon icon={faTriangleExclamation} size={"lg"} className="bh-text-warning-yellow mr-4" />
              <h2 className="m-0">{t("PROJECT.CONFIRM_ADMIN_REMOVE.TITLE.ALT")}</h2>
            </div>
          )}
          {!anyPending && <h2 className="m-0">{t("PROJECT.CONFIRM_ADMIN_REMOVE.TITLE")}</h2>}
        </div>
      }
      children={
        <div className="p-8">
          {isLoading && (
            <div className="flex w-full flex-row justify-center">
              <FontAwesomeIcon icon={faSpinner} className="h-6 w-6" spin aria-hidden="true" />
            </div>
          )}
          {!isLoading && (
            <div>
              {anyPending && <div className="mb-6">{parse(textWithVariables(t("PROJECT.CONFIRM_ADMIN_REMOVE.BODY.ALT"), modalBodyVariableMap))}</div>}
              <div>{t("PROJECT.CONFIRM_ADMIN_REMOVE.BODY")}</div>
            </div>
          )}
        </div>
      }
      footer={
        <div className="flex flex-row items-center">
          <BhTextOnlyButton buttonProps={{ onClick: () => setModalOpen(false) }}>{t("GLOBAL.CLOSE")}</BhTextOnlyButton>
          <BhPrimaryButton buttonProps={{ onClick: removeAuthority }}>{t("PROJECT.CONFIRM_ADMIN_REMOVE.REMOVE")}</BhPrimaryButton>
        </div>
      }
      size="3xl"
    />
  );
};

export default ProjectAdministratorRemoveModal;
