import React, { FC, useCallback, useState } from "react";
import { IProjectOrder } from "@/model/billing/IProjectOrder";
import BhToggleButtonBar, { IBhToggleButtonBarItem } from "@components/toggle/BhToggleButtonBar";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { useTranslation } from "react-i18next";
import BhIconButton from "@components/buttons/BhIconButton";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons/faEllipsisVertical";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { IDropdownItem } from "@/model/IDropdownItem";
import { faGear } from "@fortawesome/pro-regular-svg-icons/faGear";
import { formatDate } from "@/utilities/dateUtility";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import BhTag from "@components/tags/BhTag";
import ProjectArchiveModal from "@/views/home/project/detail/projectSettings/ProjectArchiveModal";
import { ProjectStatus } from "@/model/IProject";
import ProjectListItemTag from "@/features/projectList/projectListItem/ProjectListItemTag";
import CompanySettingsProjectBillingInfoModal from "@/views/home/company/detail/CompanySettingsProjectBillingInfoModal";
import { changeProjectSubscriptionModel } from "@/api/billingAPI";
import { ProjectInvoiceType } from "@/model/IBillingInfo";
import { faWavePulse } from "@fortawesome/pro-regular-svg-icons/faWavePulse";
import CompanySettingsActivateProjectModal from "@/views/home/company/detail/CompanySettingsActivateProjectModal";
import { saveProjectStatus } from "@/api/projectAPI";
import BhWarningButton from "@components/buttons/BhWarningButton";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons/faTriangleExclamation";
import CompanySettingsConfirmProjectModal from "@/views/home/company/detail/CompanySettingsConfirmProjectModal";
import BhCardContainerWithLeadingIcon from "@components/cards/BhCardContainerWithLeadingIcon";
import { faSquareKanban } from "@fortawesome/pro-regular-svg-icons/faSquareKanban";
import ChangeOrderTypeConfirmationModal from "@/views/home/company/detail/ChangeOrderTypeConfirmationModal";
import ProjectTaskCategoryModal from "@/views/home/project/detail/taskBoard/modals/ProjectTaskCategoryModal";

interface Props {
  order: IProjectOrder;
  setProjectOrders?: React.Dispatch<React.SetStateAction<IProjectOrder[]>>;
  isFirst?: boolean;
  isLast?: boolean;
  companyStatus?: string;
}

const CompanyPlanSettingsProjectOrderItem: FC<Props> = ({ order, setProjectOrders, isFirst, isLast, companyStatus }) => {
  const { t } = useTranslation();
  const [isStatusModalOpen, setStatusModalOpen] = useState<boolean>(false);
  const [isActivateModalOpen, setActivateModalOpen] = useState<boolean>(false);
  const [isBillingInfoModalOpen, setBillingInfoModalOpen] = useState<boolean>(false);
  const [isTaskCategoriesModalOpen, setTaskCategoriesModalOpen] = useState<boolean>(false);
  const [isChangeOrderTypeConfirmationModalOpen, setChangeOrderTypeConfirmationModalOpen] = useState<boolean>(false);
  const [isConfirmProjectModalOpen, setConfirmProjectModalOpen] = useState<boolean>(false);
  const isOrderActive = order.projectBilling.confirmed || companyStatus === "ACTIVE";

  const onArchiveConfirmButtonClick = () => {
    saveProjectStatus(order.id, ProjectStatus.ARCHIVED).then(
      () => {
        orderChangedCallback({ ...order, status: ProjectStatus.ARCHIVED });
        setStatusModalOpen(false);
      },
      () => {
        setStatusModalOpen(false);
      }
    );
  };

  const orderChangedCallback = (changedOrder: IProjectOrder) => {
    setProjectOrders &&
      setProjectOrders((oldValues) => {
        return oldValues.filter((o) => o.id !== changedOrder.id).concat(changedOrder);
      });
  };

  const onBillingTypeToggleClicked = useCallback(
    async (choice: ProjectInvoiceType) => {
      if (choice === order.projectBilling.type) {
        return;
      }
      setChangeOrderTypeConfirmationModalOpen(true);
    },
    [order]
  );

  const billingTypeChangeCallback = useCallback(async () => {
    if (order.companyBillingRequisites?.companyId) {
      const newType = order.projectBilling.type === ProjectInvoiceType.MONTH ? ProjectInvoiceType.YEAR : ProjectInvoiceType.MONTH;
      const response = await changeProjectSubscriptionModel(order.companyBillingRequisites.companyId, order.id, newType);
      if (response && response.projectBilling) {
        orderChangedCallback({ ...order, projectBilling: response.projectBilling });
      }
    }
  }, [order]);

  const selectionItems = [ProjectInvoiceType.MONTH, ProjectInvoiceType.YEAR].map((type) => {
    return { value: type, text: t("COMPANY.SETTINGS.ORDERS.BILLING." + type) } as IBhToggleButtonBarItem;
  });

  const ellipsisDropdownValues: Array<IDropdownItem> = [
    {
      text: order.status === ProjectStatus.ARCHIVED ? t("COMPANY.SETTINGS.ORDERS.ACTIVATE") : t("COMPANY.BILLING.INVOICE_SETTINGS"),
      function: () => {
        if (order.status === ProjectStatus.ARCHIVED) {
          setActivateModalOpen(true);
        } else {
          setBillingInfoModalOpen(true);
        }
      },
      icon: order.status === ProjectStatus.ARCHIVED ? faWavePulse : faGear
    },
    {
      text: t("TASKBOARD.CATEGORIES"),
      function: () => setTaskCategoriesModalOpen(true),
      icon: faSquareKanban
    }
  ];

  const confirmProjectButtonClicked = () => {
    setConfirmProjectModalOpen(true);
  };

  if (order.status === ProjectStatus.ARCHIVED) {
    return (
      <BhCardContainerWithLeadingIcon classes="bh-bg-smoke-50" isLast={isLast} isFirst={isFirst} leadingIcon={!isOrderActive ? faTriangleExclamation : undefined} iconClasses="bh-text-warning-yellow">
        <div className="flex w-full flex-row items-center justify-between px-4 py-3">
          <div className="flex flex-row items-center">
            <div className="mr-8 flex w-40 items-center justify-center">
              <BhTag type={BhTagType.ARHIVEERITUD} children={t("PROJECT.STATUS.ARCHIVED")} />
            </div>
            <div className="flex grow flex-col">
              <div className="text-18px l-h-24px bh-text-deep-ocean text-ellipsis font-bold">{order.name}</div>
              <div className="flex flex-row items-center">
                <div>
                  {t("COMPANY.SETTINGS.ORDERS.ORDER_BY.CREATED")}: {formatDate(order.created)}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <BhDropdown
              button={<BhIconButton icon={faEllipsisVertical} />}
              menu={<BhDropdownMenu values={ellipsisDropdownValues} type={BhDropdownTypeEnum.STRING} textProperty="text" />}
              position={BhDropdownPositionEnum.BOTTOM_LEFT}
            />
          </div>
        </div>
        <CompanySettingsActivateProjectModal isShown={isActivateModalOpen} setIsShown={setActivateModalOpen} projectOrder={order} setProjectOrders={setProjectOrders} />
      </BhCardContainerWithLeadingIcon>
    );
  }

  return (
    <BhCardContainerWithLeadingIcon isLast={isLast} isFirst={isFirst} leadingIcon={!isOrderActive ? faTriangleExclamation : undefined} iconClasses="bh-text-warning-yellow">
      <div className="flex w-full flex-row items-center justify-between px-4 py-3">
        <div className="flex flex-row items-center">
          <div className="mr-8 w-40 flex-none">
            {isOrderActive && <BhToggleButtonBar items={selectionItems} onClickAction={onBillingTypeToggleClicked} selected={order.projectBilling.type} />}
            {!isOrderActive && (
              <BhWarningButton buttonProps={{ onClick: () => confirmProjectButtonClicked(), disabled: order.suspended, classes: "ml-7" }}>{t("COMPANY.SETTINGS.INVOICES.SELECT_TYPE")}</BhWarningButton>
            )}
          </div>
          <div className="flex grow flex-col">
            <div className="flex flex-1 flex-wrap items-center transition duration-300">
              <div className="text-18px l-h-24px bh-text-deep-ocean text-ellipsis pr-4 font-bold">{order.name}</div>
              <ProjectListItemTag project={order} />
            </div>
            <div className="flex flex-row items-center">
              <div>
                {t("COMPANY.SETTINGS.ORDERS.ORDER_BY.CREATED")}: {formatDate(order.created)}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center">
          {isOrderActive && (
            <div className="flex flex-col items-center px-10">
              <div>{t("COMPANY.SETTINGS.ORDERS.ORDER_BY.PROJECTBILLING.BILLINGDATE")}</div>
              <div className="font-bold">{formatDate(new Date(order.projectBilling.billingDate))}</div>
            </div>
          )}
          {isOrderActive && (
            <div className="flex flex-col items-center pr-10">
              <div>{t("COMPANY.SETTINGS.ORDERS.ORDER_BY.PROJECTBILLING.SUMWITHOUTVAT")}</div>
              <div className="font-bold">{order.projectBilling.sumWithoutVat}€</div>
            </div>
          )}
          <div className="flex pr-4">
            <BhSecondaryButton buttonProps={{ onClick: () => setStatusModalOpen(true), disabled: order.suspended }}>{t("MODAL.ARCHIVE")}</BhSecondaryButton>
          </div>
          <div className="flex">
            <BhDropdown
              button={<BhIconButton icon={faEllipsisVertical} />}
              menu={<BhDropdownMenu values={ellipsisDropdownValues} type={BhDropdownTypeEnum.STRING} textProperty="text" />}
              position={BhDropdownPositionEnum.BOTTOM_LEFT}
            />
          </div>
        </div>
      </div>
      {isStatusModalOpen && order.companyBillingRequisites?.companyId && (
        <ProjectArchiveModal
          companyId={order.companyBillingRequisites?.companyId}
          isShown={isStatusModalOpen}
          setIsShown={setStatusModalOpen}
          onArchiveButtonClick={onArchiveConfirmButtonClick}
          onCancelButtonClick={() => setStatusModalOpen(false)}
        />
      )}
      {isChangeOrderTypeConfirmationModalOpen && (
        <ChangeOrderTypeConfirmationModal
          isShown={isChangeOrderTypeConfirmationModalOpen}
          setIsShown={setChangeOrderTypeConfirmationModalOpen}
          projectOrder={order}
          callback={billingTypeChangeCallback}
        />
      )}

      {isBillingInfoModalOpen && order.companyBillingRequisites && (
        <CompanySettingsProjectBillingInfoModal isShown={isBillingInfoModalOpen} setIsShown={setBillingInfoModalOpen} projectOrder={order} callback={orderChangedCallback} />
      )}
      {isTaskCategoriesModalOpen && <ProjectTaskCategoryModal isShown={isTaskCategoriesModalOpen} setIsShown={setTaskCategoriesModalOpen} projectId={order.projectBilling.projectId} />}
      {!isOrderActive && <CompanySettingsConfirmProjectModal isShown={isConfirmProjectModalOpen} setIsShown={setConfirmProjectModalOpen} projectOrder={order} setProjectOrders={setProjectOrders} />}
    </BhCardContainerWithLeadingIcon>
  );
};

export default CompanyPlanSettingsProjectOrderItem;
