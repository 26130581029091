import { IFileEntity } from "@/model/files/IFileEntity";
import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentUser, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { selectSignaturesForContainer } from "@/app/store/signaturesSlice";
import BhAddFilesContainer from "@components/upload/BhAddFilesContainer";
import { selectAllFilesForParentFileEntityId, selectFilesStatus } from "@/app/store/filesSlice";
import { addFilesToContainerAsync, uploadAttachmentFileAsync } from "@/app/store/uploadSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { EntityId } from "@reduxjs/toolkit";
import { fullPageLoadingSet } from "@/app/store/globalSlice";
import ContainerFileRow from "@/views/home/project/detail/container/ContainerFileRow";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import BhDivTableHeader from "@components/table/BhDivTableHeader";
import { useTranslation } from "react-i18next";
import ContainerFilesSkeleton from "@/views/home/project/detail/container/ContainerFilesSkeleton";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { naturalSortFilesByField } from "@/utilities/sortUtilities";

interface Props {
  containerFileEntity: IFileEntity;
}

const ContainerFilesContainer: FC<Props> = ({ containerFileEntity }) => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const files = useAppSelector((state) => selectAllFilesForParentFileEntityId(state, containerFileEntity.id));
  const currentUser = useAppSelector(selectCurrentUser);
  const isCurrentUserProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const isCurrentUserAdminOrCreator = isCurrentUserProjectAdmin || containerFileEntity.createdBy === currentUser.username;
  const signatures = useAppSelector(selectSignaturesForContainer(containerFileEntity.uuid));
  const anyInvitees = signatures.length > 0;
  const filesStatus = useAppSelector(selectFilesStatus);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const fileTableHeaders: Array<IBhTableHeader<IFileEntity>> = [
    { id: 1, content: t("GLOBAL.ORDER_BY.NAME"), classes: "min-w-24 flex-grow" },
    { id: 2, content: t("GLOBAL.USER"), classes: "w-48" },
    { id: 3, content: "", classes: "w-32" }
  ];

  const uploadFilesSelectedCallback = async (selectedFiles: Array<File>) => {
    const currentFileNames = files.filter((file) => !file.isDeleted).map((file) => file.name);
    const hasSameNameFileAlready = selectedFiles.find((file) => currentFileNames.includes(file.name));

    // TODO viska viga
    if (hasSameNameFileAlready) return;

    let savedFileIds: Array<EntityId> = [];
    await Promise.all(
      selectedFiles.map(async (file) => {
        await dispatch(uploadAttachmentFileAsync({ file: file, projectId: projectId })).then((action) => {
          const savedFileEntity = action.payload as IFileEntity;
          savedFileIds.push(savedFileEntity.id);
        });
      })
    );
    dispatch(addFilesToContainerAsync({ containerUuid: containerFileEntity.uuid, fileIds: savedFileIds, areFilesSelectedFromProject: false }));
  };

  const projectFilesSelectedCallback = async (selectedFiles: Array<IFileEntity>) => {
    dispatch(fullPageLoadingSet(true));
    const selectedFileEntityIds = selectedFiles.map((f) => f.id);
    dispatch(addFilesToContainerAsync({ containerUuid: containerFileEntity.uuid, fileIds: selectedFileEntityIds, areFilesSelectedFromProject: true })).then(() => {
      dispatch(fullPageLoadingSet(false));
    });
  };

  return (
    <>
      <div className="bh-bg-smoke rounded p-8">
        <div className="flex flex-row items-center justify-between">
          <h3 className="bh-text-deep-ocean pb-3">{t("CONTAINER.CONTENT")}</h3>
          {filesStatus === BhStateStatusType.SUCCESS && signatures.length === 0 && isCurrentUserAdminOrCreator && (
            <BhAddFilesContainer uploadFilesSelectedCallback={uploadFilesSelectedCallback} projectFilesSelectedCallback={projectFilesSelectedCallback} />
          )}
        </div>
        <div className="w-full">
          <BhDivTableHeader key="filesTable" columns={fileTableHeaders} sortedBy="" reversed={false} />
        </div>
        <div>
          {filesStatus !== BhStateStatusType.SUCCESS && (
            <div>
              <ContainerFilesSkeleton />
              <ContainerFilesSkeleton />
            </div>
          )}
          {filesStatus === BhStateStatusType.SUCCESS &&
            files &&
            files
              .filter((f) => f.isActive)
              .sort((a, b) => naturalSortFilesByField(a, b, "name"))
              .map((fileEntity) => {
                return <ContainerFileRow fileId={fileEntity.id} key={fileEntity.id} deletable={!anyInvitees} />;
              })}
        </div>
      </div>
    </>
  );
};

export default ContainerFilesContainer;
