import React, { Dispatch } from "react";
import { SectionPlanesPlugin, Viewer } from "@xeokit/xeokit-sdk";
import { classNames } from "@/utilities/jsUtilities";
import BhLightSeparator from "@components/separator/BhLightSeparator";
import { Menu } from "@headlessui/react";
import BhDropdownMenuListItem from "@components/dropdown/BhDropdownMenuListItem";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUserLanguageForTranslation } from "@/app/store/userSlice";

interface Props {
  sectionToolProps?: { sections: number };
  viewer: Viewer;
  sectionPlanes: SectionPlanesPlugin;
  setSectionToolProps: Dispatch<{ sections: number }>;
}

const XeokitSectionsDropdown = React.memo<Props>(({ sectionToolProps, viewer, sectionPlanes, setSectionToolProps }) => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguageForTranslation);
  const sectionPlaneKeys = Object.keys(sectionPlanes.sectionPlanes);

  if (!sectionToolProps) return null;

  return (
    <div className={classNames("bh-shadow bh-bg-raised-white inline-block flex max-h-[400px] w-56 flex-col rounded py-3 text-left align-top")}>
      <div className="overflow-auto" style={{ maxHeight: 400 }}>
        <ul>
          <Menu.Item as={"div"}>
            <li className="hover:bh-bg-smoke cursor-pointer">
              <BhDropdownMenuListItem
                value={t("BIM.SECTIONS.CLEAR_ALL", { lng: currentUserLanguage })}
                onSelect={() => {
                  sectionPlanes.clear();
                  setSectionToolProps({ ...sectionToolProps, sections: Object.keys(sectionPlanes.sectionPlanes).length });
                }}
              />
            </li>
          </Menu.Item>
          <Menu.Item as={"div"}>
            <li className="hover:bh-bg-smoke cursor-pointer">
              <BhDropdownMenuListItem value={t("BIM.SECTIONS.FLIP_ALL", { lng: currentUserLanguage })} onSelect={() => sectionPlanes.flipSectionPlanes()} />
            </li>
          </Menu.Item>
          <div className="py-0.25">
            <BhLightSeparator />
          </div>
          {sectionPlaneKeys &&
            sectionPlaneKeys.map((sectionPlaneKey, index) => {
              const sectionPlane = sectionPlanes.sectionPlanes[sectionPlaneKey];
              if (sectionPlane) {
                return (
                  <Menu.Item as={"div"} key={index}>
                    <h4 className="my-1 mx-3 ">{t("BIM.SECTION", { lng: currentUserLanguage }) + " " + (index + 1)}</h4>
                    <div className="py-0.25">
                      <BhLightSeparator />
                    </div>
                    <li className="hover:bh-bg-smoke cursor-pointer">
                      <BhDropdownMenuListItem
                        value={sectionPlane.active ? t("BIM.SECTIONS.DEACTIVATE", { lng: currentUserLanguage }) : t("BIM.SECTIONS.ACTIVATE", { lng: currentUserLanguage })}
                        onSelect={() => {
                          sectionPlane.active = !sectionPlane.active;
                        }}
                      />
                    </li>
                    <li className="hover:bh-bg-smoke cursor-pointer">
                      <BhDropdownMenuListItem
                        value={t("BIM.SECTIONS.EDIT", { lng: currentUserLanguage })}
                        onSelect={() => {
                          sectionPlanes.showControl(sectionPlane.id);
                          viewer.scene.fire("sectionPlaneEdit", sectionPlane.id);
                        }}
                      />
                    </li>
                    <li className="hover:bh-bg-smoke cursor-pointer">
                      <BhDropdownMenuListItem value={t("BIM.SECTIONS.FLIP", { lng: currentUserLanguage })} onSelect={() => sectionPlane.flipDir()} />
                    </li>
                    <li className="hover:bh-bg-smoke cursor-pointer">
                      <BhDropdownMenuListItem
                        value={t("BIM.SECTIONS.CLEAR", { lng: currentUserLanguage })}
                        onSelect={() => {
                          sectionPlane.destroy();
                          setSectionToolProps({ ...sectionToolProps, sections: Object.keys(sectionPlanes.sectionPlanes).length });
                        }}
                      />
                    </li>
                  </Menu.Item>
                );
              }
            })}
        </ul>
      </div>
    </div>
  );
});

export default XeokitSectionsDropdown;
