import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenRuler } from "@fortawesome/pro-regular-svg-icons/faPenRuler";

interface Props {
  children: ReactNode;
}

const PartyFilesTextContainer: FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div className="flex h-full w-full flex-col overflow-auto py-12 px-24">
      <h2>
        <FontAwesomeIcon icon={faPenRuler} className="mr-3" />
        {t("WORKGROUP.NEW.DRAWINGS.AUTHORITIES")}
      </h2>
      <div className="my-6">{t("WORKGROUP.NEW.DRAWINGS.AUTHORITIES.TEXT")}</div>
      <div className="overflow-visible">{children}</div>
    </div>
  );
};

export default PartyFilesTextContainer;
