import React, { useEffect } from "react";
import { Viewer } from "@xeokit/xeokit-sdk";
import { removeDuplicates } from "@/utilities/jsUtilities";
import { v4 as uuidv4 } from "uuid";

interface Props {
  viewer: Viewer;
  onNewModelLoaded?: number;
  setLoadedStoreyElevations: Function;
}

const XeokitStoreyElevationTool = React.memo<Props>(({ viewer, onNewModelLoaded, setLoadedStoreyElevations }) => {
  useEffect(() => {
    if (onNewModelLoaded && onNewModelLoaded > 0) {
      if (!viewer) return;
      //------------------------------------------------------------------------------------------------------------------
      // ADD ALL UNIQUE STOREY ELEVATIONS to SIDEBAR
      //------------------------------------------------------------------------------------------------------------------

      const storeyIds = viewer?.metaScene.getObjectIDsByType("IfcBuildingStorey");

      const uniqueStoreyElevations =
        storeyIds &&
        removeDuplicates(
          storeyIds
            .filter((storeyId) => {
              const storeyMetaObject = viewer?.metaScene.metaObjects[storeyId];
              const elements = storeyMetaObject?.getObjectIDsInSubtree();
              return elements.length > 0;
            })
            .map((storeyId) => {
              // @ts-ignore
              return parseFloat(viewer?.metaScene.metaObjects[storeyId].attributes ? viewer?.metaScene.metaObjects[storeyId].attributes["elevation"] : 0);
            })
        )
          .sort((a, b) => a - b)
          .reverse();

      setLoadedStoreyElevations(
        uniqueStoreyElevations.map((storeyElevation: number) => {
          const displayText = (storeyElevation >= 0 ? "+" : "") + (storeyElevation / 1000).toFixed(2) + " m";
          return { name: displayText, elevation: storeyElevation, id: uuidv4(), visible: true };
        })
      );
    }
  }, [onNewModelLoaded]);

  return null;
});

export default XeokitStoreyElevationTool;
