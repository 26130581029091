import React, { FC, useState } from "react";
import BhToggleButtonBar from "@components/toggle/BhToggleButtonBar";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  boDowngradePlanAsync,
  boFetchDowngradeInfoAsync,
  boFetchUpgradeInfoAsync,
  boSaveCompanyBillingAsync,
  boSelectCompany,
  boSelectCompanyBilling,
  boUpgradePlanAsync
} from "@/app/store/backofficeSlice";
import BhInputLabel from "@components/input/BhInputLabel";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { BauhubPlan } from "@/model/IProject";
import { IDowngradePlanInfo } from "@/model/billing/IDowngradePlanInfo";
import { IUpgradePlanInfo } from "@/model/billing/IUpgradePlanInfo";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { checkIfCompanyHasDefaultPricingSettings, pricesMap } from "@/utilities/backofficeUtilities";
import { ICompanyBilling } from "@/model/billing/ICompanyBilling";
import BOCompanyChangePlan from "@components/backoffice/company/companyAndBilling/BOCompanyChangePlanModal";
import BoCompanyCustomPricingModal from "@components/backoffice/company/companyAndBilling/BOCompanyCustomPricingModal";
import BOCompanyDisableCustomPricingModal from "@components/backoffice/company/companyAndBilling/BOCompanyDisableCustomPricingModal";

interface Props {
  onFieldChangeCallback: Function;
}

const BOCompanyBillingSettings: FC<Props> = ({ onFieldChangeCallback }) => {
  const company = useAppSelector(boSelectCompany);
  const companyBilling = useAppSelector(boSelectCompanyBilling);
  const dispatch = useAppDispatch();
  const [planChangingModal, setPlanChangingModal] = useState(false);
  const [newPlanInfo, setNewPlanInfo] = useState<IDowngradePlanInfo | IUpgradePlanInfo>();
  const [selectedPlan, setSelectedPlan] = useState<BauhubPlan>();
  const [customPricingModalOpen, setCustomPricingModalOpen] = useState(false);
  const [disableCustomPricingModalOpen, setDisableCustomPricingModalOpen] = useState(false);
  const hasDefaultPricing = checkIfCompanyHasDefaultPricingSettings(companyBilling);
  const handlePlanClick = (clickedPlan: Record<string, BauhubPlan>) => {
    if (clickedPlan.plan === companyBilling.plan) return;
    if (clickedPlan.plan === BauhubPlan.LIGHT) {
      dispatch(boFetchDowngradeInfoAsync(company.id)).then((action) => {
        const planInfo = action.payload as IDowngradePlanInfo;
        setNewPlanInfo(planInfo);
      });
    }
    if (clickedPlan.plan === BauhubPlan.PRO) {
      dispatch(boFetchUpgradeInfoAsync(company.id)).then((action) => {
        const planInfo = action.payload as IUpgradePlanInfo;
        setNewPlanInfo(planInfo);
      });
    }
    setSelectedPlan(clickedPlan.plan);
    setPlanChangingModal(true);
  };

  const downgradePlan = () => {
    dispatch(boDowngradePlanAsync(company.id)).then(() => {
      setPlanChangingModal(false);
    });
  };

  const upgradePlan = () => {
    dispatch(boUpgradePlanAsync(company.id)).then(() => {
      setPlanChangingModal(false);
    });
  };

  const handleCustomPricingToggle = () => {
    if (!hasDefaultPricing) {
      setDisableCustomPricingModalOpen(true);
    } else {
      setCustomPricingModalOpen(true);
    }
  };

  const handleDisableCustomPricing = () => {
    let newCompanyBilling = { ...companyBilling } as ICompanyBilling;
    for (const key in pricesMap) {
      newCompanyBilling = { ...newCompanyBilling, [key]: pricesMap[key] };
    }
    dispatch(boSaveCompanyBillingAsync(newCompanyBilling)).then(() => {
      setDisableCustomPricingModalOpen(false);
    });
  };

  return (
    <div>
      <h2>Billing settings</h2>
      <div className="flex gap-60">
        <div className="w-full flex flex-col z-0 gap-6">
          <BhToggleButtonBar label="Plan" items={[{ value: BauhubPlan.PRO }, { value: BauhubPlan.LIGHT }]} onClickAction={handlePlanClick} property="plan" selected={companyBilling.plan}
                             disabled={companyBilling.disablePlanChange} />
          <div className="flex justify-between items-center">
            <BhInputLabel>Allow plan switching</BhInputLabel>
            <BhToggleSwitch value={!companyBilling.disablePlanChange} property="disablePlanChange"
                            onClickAction={() => onFieldChangeCallback({ disablePlanChange: !companyBilling.disablePlanChange })} />
          </div>
          <div className="relative">
            <div className="flex justify-between items-center">
              <BhInputLabel>Custom pricing</BhInputLabel>
              <BhToggleSwitch
                value={!hasDefaultPricing}
                onClickAction={handleCustomPricingToggle} />
            </div>
            {!hasDefaultPricing && (
              <div className="absolute -right-24 -top-1">
                <BhTextOnlyButton buttonProps={{ onClick: () => setCustomPricingModalOpen(true) }}>
                  Settings
                </BhTextOnlyButton>
              </div>
            )}
          </div>
          <div>
            <h3>Invoice deadline from creation</h3>
            <div className="grid grid-cols-2 gap-4">
              <BhInputStackedLabel initialValue={companyBilling.monthlyProjectDaysToPay} property="monthlyProjectDaysToPay" label="Monthly/consolidated" saveCallback={onFieldChangeCallback} />
              <BhInputStackedLabel initialValue={companyBilling.yearlyProjectDaysToPay} property="yearlyProjectDaysToPay" label="Annual" saveCallback={onFieldChangeCallback} />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-6">
          <BhToggleButtonBar label="Monthly invoices" items={[{ value: "SINGLE", text: "Individual" }, { value: "AGGREGATE", text: "Consolidated" }]} onClickAction={onFieldChangeCallback}
                             property="invoiceType" selected={companyBilling.invoiceType}
          />
          <div className="flex justify-between items-center">
            <BhInputLabel>Show project contract number on invoice</BhInputLabel>
            <BhToggleSwitch value={companyBilling.projectContractNumberOnInvoice} property="projectContractNumberOnInvoice" onClickAction={onFieldChangeCallback} />
          </div>
        </div>
      </div>
      {planChangingModal && (
        <BOCompanyChangePlan
          selectedPlan={selectedPlan as BauhubPlan}
          newPlanInfo={newPlanInfo as IDowngradePlanInfo | IUpgradePlanInfo}
          setIsShown={setPlanChangingModal}
          onConfirm={selectedPlan === BauhubPlan.PRO ? upgradePlan : downgradePlan}
        />
      )}
      {customPricingModalOpen && <BoCompanyCustomPricingModal setCustomPricingModalOpen={setCustomPricingModalOpen} />}
      {disableCustomPricingModalOpen && <BOCompanyDisableCustomPricingModal setDisableCustomPricingModalOpen={setDisableCustomPricingModalOpen} onConfirm={handleDisableCustomPricing} />}
    </div>
  );
};

export default BOCompanyBillingSettings;