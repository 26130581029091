import React, { FC } from "react";
import { ProjectInvoiceType } from "@/model/IBillingInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faBoxArchive } from "@fortawesome/pro-regular-svg-icons/faBoxArchive";
import { ProjectStatus } from "@/model/IProject";
import { useAppSelector } from "@/app/hooks";
import { boSelectCompany } from "@/app/store/backofficeSlice";
import BOProjectTabCard from "@components/backoffice/company/projects/BOProjectTabCard";

export enum ITabCardType {
  DEFAULT = "DEFAULT",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  ERROR = "ERROR"
}

const BOCompanyProjectsTabCards: FC = () => {
  const company = useAppSelector(boSelectCompany);
  const monthlyProjects = company.projects?.filter((project) => project.billing.type === ProjectInvoiceType.MONTH && project.status === ProjectStatus.IN_PROGRESS) || [];
  const yearlyProjects = company.projects?.filter((project) => project.billing.type === ProjectInvoiceType.YEAR && project.status === ProjectStatus.IN_PROGRESS) || [];
  const freeProjects = company.projects?.filter((project) => project.billing.type === ProjectInvoiceType.FREE && project.status === ProjectStatus.IN_PROGRESS) || [];

  const activeProjects = company.projects?.filter((project) => project.status === ProjectStatus.IN_PROGRESS) || [];
  const archivedProjects = company.projects?.filter((project) => project.status === ProjectStatus.ARCHIVED) || [];

  const projectTypesTabCards = [
    {
      type: ITabCardType.DEFAULT,
      title: "Monthly",
      value: monthlyProjects.length
    },
    {
      type: ITabCardType.DEFAULT,
      title: "Annual",
      value: yearlyProjects.length
    },
    {
      type: ITabCardType.DEFAULT,
      title: "Free",
      value: freeProjects.length
    }
  ];

  const projectStatusTabCards = [
    {
      type: ITabCardType.SUCCESS,
      title: "Active",
      value: activeProjects.length,
      icon: <FontAwesomeIcon icon={faCheck} className="bh-text-success-green-110" />
    },
    {
      type: ITabCardType.DEFAULT,
      title: "Archived",
      value: archivedProjects.length,
      icon: <FontAwesomeIcon icon={faBoxArchive} className="bh-text-deep-ocean" />
    }
  ];

  return (
    <div className="mt-6 flex  gap-6">
      <div className="flex gap-1 ">
        {projectTypesTabCards.map((card) => (
          <BOProjectTabCard title={card.title} value={card.value} type={card.type} key={card.title} />
        ))}
      </div>
      <div className="flex gap-1 ">
        {projectStatusTabCards.map((card) => (
          <BOProjectTabCard title={card.title} value={card.value} type={card.type} icon={card.icon} key={card.title} />
        ))}
      </div>
    </div>
  );
};

export default BOCompanyProjectsTabCards;
