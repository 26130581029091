import React, { FC, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchParentDirsAsync, fetchRootSubDirsAsync, selectCurrentRootFolderSubDirIds, selectFoldersStatus } from "@/app/store/foldersSlice";
import { selectCurrentProjectId, selectProjectStatus, selectRootDirectoryId, selectRootDocumentDirectoryId } from "@/app/store/project/projectSlice";
import FolderContainer from "@/features/directoryContainer/FolderContainer";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { EntityId } from "@reduxjs/toolkit";
import { SidebarItemType } from "@/model/ISidebarItem";
import { selectCurrentSidebarMenuItem } from "@/app/store/sidebarSlice";
import { fetchAllFavouriteFoldersAsync, selectFavouriteFolderIdsInBranch } from "@/app/store/filesSlice";
import { useNavigate } from "react-router-dom";
import FavouriteFolderContainer from "@/features/directoryContainer/FavouriteFolderContainer";
import { useTranslation } from "react-i18next";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  currentDirId: EntityId;
}

const FileTreeFC: FC<Props> = ({ currentDirId }) => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const rootFolderIds = useAppSelector(selectCurrentRootFolderSubDirIds, shallowEqual);
  const projectStatus = useAppSelector(selectProjectStatus);
  const currentProjectId = useAppSelector(selectCurrentProjectId);
  const folderStatus = useAppSelector(selectFoldersStatus);
  const currentSidebarItem = useAppSelector(selectCurrentSidebarMenuItem);
  const planRootDirectoryId = useAppSelector(selectRootDirectoryId);
  const documentRootDirectoryId = useAppSelector(selectRootDocumentDirectoryId);
  const favouriteFolderIds = useAppSelector((state) => selectFavouriteFolderIdsInBranch(state, projectId), shallowEqual);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // @ts-ignore
  const dirMap: Record<SidebarItemType, EntityId> = {
    [SidebarItemType.DRAWINGS]: planRootDirectoryId,
    [SidebarItemType.DOCUMENTS]: documentRootDirectoryId
  };

  useEffect(() => {
    dispatch(fetchAllFavouriteFoldersAsync(currentProjectId));
  }, []);

  useEffect(() => {
    if (projectStatus === BhStateStatusType.SUCCESS) {
      dispatch(fetchParentDirsAsync(currentDirId));
    }
  }, [projectStatus]);

  useEffect(() => {
    if (projectStatus === BhStateStatusType.SUCCESS) {
      if (currentSidebarItem) {
        const rootDirToFetch = dirMap[currentSidebarItem];
        rootDirToFetch && dispatch(fetchRootSubDirsAsync(rootDirToFetch));
      }
    }
  }, [currentSidebarItem]);

  useEffect(() => {
    if (folderStatus === BhStateStatusType.ERROR && projectStatus === BhStateStatusType.SUCCESS) {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/dir/${planRootDirectoryId}`);
    }
  }, [folderStatus, currentSidebarItem]);

  return (
    <div className="bh-border-pigeon-20 flex h-full min-w-0 flex-1 flex-col overflow-hidden border-r">
      <main className="flex-1 overflow-y-auto px-2">
        {favouriteFolderIds && favouriteFolderIds.length > 0 && (
          <div>
            <div className="mx-2 mt-6 mb-2 flex-1 font-bold">{t("FAVOURITE.FOLDERS")}</div>
            <section className="">
              {favouriteFolderIds.map((folderId) => {
                return <FavouriteFolderContainer folderId={folderId} key={folderId} />;
              })}
            </section>
          </div>
        )}
        {currentSidebarItem && (
          <div className="mt-6">
            <FolderContainer folderId={dirMap[currentSidebarItem]} key={dirMap[currentSidebarItem]} removeFolderIconAndBoldText={true} translate={true} />
          </div>
        )}
      </main>
    </div>
  );
};

const FileTree = React.memo(FileTreeFC);
export default FileTree;
