import React, { FC, useEffect, useState } from "react";
import BhTableRow from "@components/table/BhTableRow";
import { IProject, IProjectModule, Module } from "@/model/IProject";
import BhInlineInput from "@components/input/BhInlineInput";
import { useAppDispatch } from "@/app/hooks";
import { boSaveProjectModuleAsync } from "@/app/store/backofficeSlice";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  project: IProject;
}

const BOCompanyProjectCodesTableRow: FC<Props> = ({ project }) => {
  const module = project.modules.find((module) => module.module === Module.TRESOOR_BUDGET || module.module === Module.TRESOOR_BUDGET_V2 || module.module === Module.RAPID_BUDGET);
  const [newProjectModule, setNewProjectModule] = useState<IProjectModule>(module as IProjectModule);
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (newProjectModule.adminSettings?.projectCode !== module?.adminSettings.projectCode) {
      dispatch(boSaveProjectModuleAsync(newProjectModule));
    }
  }, [newProjectModule]);
  const onProjectCodeChange = (changedValue: Record<string, number>) => {
    if (!changedValue.projectCode) {
      setError(true);
      return;
    }
    setNewProjectModule({ ...newProjectModule, adminSettings: { ...changedValue } });
  };

  return (
    <BhTableRow>
      <td>
        <div>{project.name}</div>
      </td>
      <td>
        <div>
          <BhInlineInput
            placeholder="Project code"
            initialValue={newProjectModule?.adminSettings?.projectCode}
            property="projectCode"
            inputClasses={classNames("overflow-hidden", error && "bh-border-error-red")}
            required={true}
            initialRows={1}
            saveCallback={(e: any) => onProjectCodeChange({ projectCode: parseInt(e.projectCode) })}
          />
        </div>
      </td>
    </BhTableRow>
  );
};

export default BOCompanyProjectCodesTableRow;
