import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import { EntityId } from "@reduxjs/toolkit";
import { ICompanyBilling } from "@/model/billing/ICompanyBilling";
import { IUpgradePlanInfo, ProjectUpgradePlanInfo } from "@/model/billing/IUpgradePlanInfo";
import { fetchBillingUpgradeInfo, postBillingUpgrade } from "@/api/billingAPI";
import { companyPlanSet } from "@/app/store/companiesSlice";
import { useAppDispatch } from "@/app/hooks";
import BhModalFooter from "@components/modal/BhModalFooter";
import { formatDate } from "@/utilities/dateUtility";
import { useTranslation } from "react-i18next";
import { BauhubPlan } from "@/model/IProject";

interface Props {
  setConfirmModalOpen: Dispatch<SetStateAction<boolean>>;
  companyId: EntityId;
  setCompanyBilling: Dispatch<SetStateAction<ICompanyBilling>>;
  testDate: Date;
}

const CompanySettingsUpgradeModal: FC<Props> = ({ setConfirmModalOpen, companyId, setCompanyBilling, testDate }) => {
  const { t } = useTranslation();
  const [upgradeInfo, setUpgradeInfo] = useState({} as IUpgradePlanInfo);
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchBillingUpgradeInfo(companyId, testDate.toISOString()).then((res) => {
      setUpgradeInfo(res);
    });
  }, []);

  const upgrade = () => {
    setConfirmDisabled(true);
    postBillingUpgrade(companyId, BauhubPlan.PRO, testDate.toISOString()).then((res: ICompanyBilling) => {
      setCompanyBilling(res);
      dispatch(companyPlanSet(res));
      setConfirmModalOpen(false);
      setConfirmDisabled(false);
    });
  };

  const companyHasProjectsWithAnnualSubscriptions = upgradeInfo?.projects?.length > 0;

  return (
    <BhModal
      isShown={true}
      setIsShown={setConfirmModalOpen}
      header={<h2>{t("COMPANY.SETTINGS.SUBSCRIPTION.UPGRADE_MODAL.HEADER")}</h2>}
      children={
        <div className="px-8 py-6">
          <p>{t("COMPANY.SETTINGS.SUBSCRIPTION.UPGRADE_MODAL.BODY")}</p>
          {companyHasProjectsWithAnnualSubscriptions && (
            <div>
              <div className="my-5">{t("COMPANY.SETTINGS.SUBSCRIPTION.UPGRADE_MODAL.BODY.ISSUE_INVOICE")}</div>
              {upgradeInfo?.projects?.map((project: ProjectUpgradePlanInfo) => (
                <p
                  key={project.projectId}
                  dangerouslySetInnerHTML={{
                    __html: t("COMPANY.SETTINGS.SUBSCRIPTION.UPGRADE_MODAL.BODY.ISSUE_INVOICE.PROJECT", {
                      projectName: project.projectName,
                      remainingToPay: project.remainingToPay,
                      endDate: formatDate(project.endDate)
                    }) as any
                  }}
                />
              ))}
            </div>
          )}
        </div>
      }
      footer={
        <BhModalFooter confirmDisabled={confirmDisabled} onConfirm={upgrade} confirmButtonText={t("COMPANY.SETTINGS.SUBSCRIPTION.CONFIRM") as string} onCancel={() => setConfirmModalOpen(false)} />
      }
    />
  );
};
export default CompanySettingsUpgradeModal;
