import React, { FC, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhTabs from "@components/tabs/BhTabs";
import ProfileUserInfo from "@/views/home/profile/ProfileTabs/ProfileUserInfo";
import ProfileAuthSettings from "@/views/home/profile/ProfileTabs/profileAuthSettings/ProfileAuthSettings";
import { fetchCurrentUserInfoAsync, selectCurrentUser } from "@/app/store/userSlice";
import ProfilePreferences from "@/views/home/profile/ProfileTabs/ProfilePreferences";
import { faShieldCheck } from "@fortawesome/pro-regular-svg-icons/faShieldCheck";
import BhUserIcon from "@components/user/BhUserIcon";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import UserProfileModals from "@/views/home/profile/modals/UserProfileModals";
import { getUserFullName } from "@/model/IUser";
import { useNavigate } from "react-router-dom";
import { toggleUserProfileModalsOpen } from "@/app/store/userPreferenceSlice";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons/faArrowLeft";
import { Trans, useTranslation } from "react-i18next";
import { ITabArrayElement } from "@/model/tabs/ITabArrayElement";

const Profile: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector(selectCurrentUser);
  const userIsVerified = currentUser.identityCode !== null && currentUser.identityCode.length > 0;

  useEffect(() => {
    dispatch(fetchCurrentUserInfoAsync());
  }, []);

  const tabsArray: Array<ITabArrayElement> = [
    { id: 0, header: t("USER.PROFILE.HEADER"), content: <ProfileUserInfo />, href: "info" },
    { id: 1, header: t("USER.PROFILE.NOTIFICATIONS"), content: <ProfilePreferences />, href: "preferences" },
    { id: 2, header: t("GLOBAL.SECURITY"), content: <ProfileAuthSettings />, href: "security" }
  ];

  return (
    <div className="flex w-full flex-col">
      <div className="bh-bg-smoke w-full px-44 pb-16 md:px-8">
        <BhTextOnlyButton
          buttonProps={{
            onClick: () => navigate(-1),
            classes: "md:flex mt-3 -ml-32 float-left md:float-none md:ml-0"
          }}
        >
          <FontAwesomeIcon className="mr-1" icon={faArrowLeft} size={"xs"} aria-hidden="true" />
          <Trans>GLOBAL.BACK</Trans>
        </BhTextOnlyButton>
        <div className="mb-4 mt-5">
          <h3>
            <Trans>USER.PROFILE.SETTINGS</Trans>
          </h3>
        </div>
        <div className="relative flex flex-row items-start justify-between">
          <div className="flex flex-row items-center">
            <BhUserIcon user={currentUser} size={40} />
            <div className="mx-6">
              <h2 className="text- m-0">{getUserFullName(currentUser)}</h2>
              <div className="inline-flex items-center">
                <p className="m-0">{currentUser.username}</p>
                {userIsVerified && (
                  <>
                    <span className="bh-text-pigeon-50 mx-4">&bull;</span>
                    <FontAwesomeIcon icon={faShieldCheck} /> <span className="ml-2"> {t("USER.PROFILE.PERSONALIZED_BADGE")}</span>
                  </>
                )}
              </div>
            </div>
          </div>
          {!userIsVerified && (
            <div className="relative text-right">
              <p className="m-0 font-bold">
                <Trans>USER.PROFILE.NOT_PERSONALIZED</Trans>
              </p>
              <p className="mt-0 mb-2 min-w-0">
                <Trans>USER.PROFILE.NOT_PERSONALIZED.INFO</Trans>
              </p>
              <BhPrimaryButton
                buttonProps={{
                  onClick: () => dispatch(toggleUserProfileModalsOpen({ modal: "profileAuthSettingsPersonalizationModal" })),
                  classes: "absolute right-0 truncate min-w-0 whitespace-nowrap"
                }}
              >
                {`${t("USER.PROFILE.PERSONALIZE")}`}
              </BhPrimaryButton>
            </div>
          )}
        </div>
      </div>
      <div className="-mt-12 flex w-full flex-1 overflow-hidden pt-0.5">
        <BhTabs tabs={tabsArray} router={true} initialActiveTab={0} tabsContainerClasses="px-44 md:px-8" />
      </div>
      <UserProfileModals />
    </div>
  );
};

export default Profile;
