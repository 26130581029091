import React, { FC } from "react";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import BhDivTableHeader from "@components/table/BhDivTableHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignature } from "@fortawesome/pro-regular-svg-icons/faSignature";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons/faPaperclip";
import { IAct } from "@/model/IAct";
import { useTranslation } from "react-i18next";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  projectId: EntityId;
}

const MrActsListTableHeader: FC<Props> = ({ projectId }) => {
  const { t } = useTranslation();
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));

  const tableColumnHeaders: Array<IBhTableHeader<IAct>> = [
    { id: 1, content: t("ACT.NUMBER"), classes: "w-18 flex-none text-center" },
    { id: 2, content: t("GLOBAL.ITEM_NAME"), classes: "flex-grow" },
    { id: 3, content: <FontAwesomeIcon icon={faPaperclip} />, classes: "w-14 flex-none justify-center" },
    { id: 5, content: <FontAwesomeIcon icon={faSignature} />, classes: "w-12 flex-none justify-center" },
    { id: 6, content: t("GLOBAL.ORDER_BY.STATUS"), classes: classNames("flex-none", isProjectAdmin ? "w-40" : "w-56") },
    isProjectAdmin && { id: 7, content: t("ACT.HEADER.SENDING"), classes: "w-40 flex-none" },
    { id: 8, content: t("GLOBAL.CREATED"), classes: "w-40 flex-none" },
    { id: 9, content: "", classes: "w-14 flex-none" }
  ].filter(Boolean) as Array<IBhTableHeader<IAct>>;

  return <BhDivTableHeader columns={tableColumnHeaders} reversed={false} sortedBy={"number"} />;
};

export default MrActsListTableHeader;
