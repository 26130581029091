import React, { FC, useRef } from "react";
import BhInputLabel from "@components/input/BhInputLabel";
import BhChipWithEmail from "@/views/home/project/detail/directory/directoryShareModals/BhChipWithEmail";
import { isValidEmail } from "@/utilities/jsUtilities";

interface Props {
  label?: string;
  emailsToSend: Array<string>;
  onRemove?: Function;
  onEnterKeyDown?: Function;

}

const BOInputWithEmailChip: FC<Props> = ({ label, emailsToSend, onRemove, onEnterKeyDown }) => {
  const containedInputRef = useRef<HTMLInputElement>(null);
  const onEnterKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && onEnterKeyDown) {
      let inputRefPropOrContained = containedInputRef;
      const inputValue = inputRefPropOrContained.current?.value as string;
      if (!isValidEmail(inputValue)) return;
      onEnterKeyDown && onEnterKeyDown(inputValue);
      if (inputRefPropOrContained && inputRefPropOrContained.current) {
        inputRefPropOrContained.current.value = "";
      }
    }
  };
  return (
    <div className="w-full p-8">
      {label && <BhInputLabel>{label}</BhInputLabel>}
      <div className="bh-border-pigeon-40 min-h-42px bh-bg-white flex flex-row justify-between whitespace-nowrap rounded border p-1">
        <div className="flex w-full flex-row flex-wrap items-center overflow-y-auto overflow-x-hidden">
          {emailsToSend &&
            emailsToSend.length > 0 &&
            emailsToSend.map((email) => {
              return <BhChipWithEmail key={email} email={email} onRemove={onRemove} />;
            })}
          <input
            ref={containedInputRef}
            className="text-14px min-w-50px disabled:bh-bg-white flex-1 leading-5 outline-0"
            onKeyDown={(e) => onEnterKeyPress(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default BOInputWithEmailChip;