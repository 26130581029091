import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhModal from "@components/modal/BhModal";
import {
  ISecurityDetails,
  securityTooLowDetailsSet,
  selectSecurityTooLowDetails,
  selectShowSecurityTooLowModal,
  selectShowSecurityTooLowSyncModal,
  showSecurityTooLowModalSet,
  showSecurityTooLowSyncModalSet
} from "@/app/store/globalSlice";
import { Trans } from "react-i18next";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { ButtonSize } from "@components/buttons/IBhButtonProps";
import { ProjectSecurityLevel } from "@/model/IProject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldCheck } from "@fortawesome/pro-solid-svg-icons/faShieldCheck";
import { faShieldHalved } from "@fortawesome/pro-solid-svg-icons/faShieldHalved";
import { useNavigate } from "react-router-dom";
import { logOutOfCas } from "@/api/auth/authAPI";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

const SecurityTooLowModal: FC = () => {
  const securityTooLowModal = useAppSelector(selectShowSecurityTooLowModal);
  const securityTooLowSyncModal = useAppSelector(selectShowSecurityTooLowSyncModal);
  const securityTooLowDetails = useAppSelector(selectSecurityTooLowDetails);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return function cleanup() {
      dispatch(securityTooLowDetailsSet({} as ISecurityDetails));
    };
  }, []);

  const isHighProjectWithoutIdentification = securityTooLowDetails?.projectSecurityLevel === ProjectSecurityLevel.HIGH && securityTooLowDetails?.userHasIdentityCode === false;
  const isMediumProjectWithoutIdentification =
    securityTooLowDetails?.projectSecurityLevel === ProjectSecurityLevel.MEDIUM && securityTooLowDetails?.userHasPhoneNumber === false && securityTooLowDetails?.userHasIdentityCode === false;
  const isMediumProjectWithoutTwoFactorWithEid = securityTooLowDetails?.projectSecurityLevel === ProjectSecurityLevel.MEDIUM && securityTooLowDetails?.userHasPhoneNumber === false;
  const isMediumProjectWithoutEid = securityTooLowDetails?.projectSecurityLevel === ProjectSecurityLevel.MEDIUM && securityTooLowDetails?.userHasIdentityCode === false;
  const showGoToSettings = isHighProjectWithoutIdentification || isMediumProjectWithoutIdentification;
  const showGoToLogin = !showGoToSettings;

  const headerTransCode = "PROJECT.SECURITY_MODAL.HEADER." + securityTooLowDetails?.projectSecurityLevel;
  const settingsBodyTransCode = "PROJECT.SECURITY_MODAL.IDENTIFICATION." + securityTooLowDetails?.projectSecurityLevel;
  const settingsBodyDescTransCode = "PROJECT.SECURITY_MODAL.IDENTIFICATION_DESC." + securityTooLowDetails?.projectSecurityLevel;
  const loginBodyTransCode = "PROJECT.SECURITY_MODAL.AUTH." + securityTooLowDetails?.projectSecurityLevel;

  const goToSettingsView = (
    <>
      <p className="mb-4">
        <Trans>{settingsBodyTransCode}</Trans>
      </p>
      <p className="mb-6">
        <Trans>{settingsBodyDescTransCode}</Trans>
      </p>
      <BhSecondaryButton
        buttonProps={{
          size: ButtonSize.XL,
          classes: "w-full",
          onClick: () => {
            navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/user/security`);
            dispatch(showSecurityTooLowModalSet(false));
          }
        }}
      >
        <Trans>PROJECT.SECURITY_MODAL.IDENTIFICATION.SETTINGS</Trans>
      </BhSecondaryButton>
    </>
  );

  const goToLoginView = (
    <>
      <p className="mb-4">
        <Trans>{loginBodyTransCode}</Trans>
      </p>
      <h3 className="mb-6">
        <Trans>PROJECT.SECURITY_MODAL.CHOOSE_AUTH</Trans>
      </h3>
      {securityTooLowDetails?.projectSecurityLevel === ProjectSecurityLevel.MEDIUM && (
        <BhSecondaryButton
          buttonProps={{
            size: ButtonSize.XL,
            classes: "w-full",
            disabled: !securityTooLowDetails?.userHasPhoneNumber,
            onClick: () => {
              const redirectUrl =
                ConfigSingleton.getInstance().getConfig().REACT_APP_CAS_URL +
                "/login?service=" +
                encodeURIComponent(ConfigSingleton.getInstance().getConfig().REACT_APP_CAS_CALLBACK_URL + "") +
                "&authn_method=mfa-simple";
              logOutOfCas(redirectUrl);
            }
          }}
        >
          <Trans>GLOBAL.TWO_FACTOR</Trans>
        </BhSecondaryButton>
      )}
      <BhSecondaryButton
        buttonProps={{
          size: ButtonSize.XL,
          classes: "w-full",
          disabled: !securityTooLowDetails?.userHasIdentityCode,
          onClick: () => {
            const redirectUrl =
              ConfigSingleton.getInstance().getConfig().REACT_APP_CAS_URL +
              "/login?service=" +
              encodeURIComponent(ConfigSingleton.getInstance().getConfig().REACT_APP_CAS_CALLBACK_URL + "") +
              "&highlight=id";
            logOutOfCas(redirectUrl);
          }
        }}
      >
        <Trans>GLOBAL.ID_CARD</Trans>
      </BhSecondaryButton>
      <BhSecondaryButton
        buttonProps={{
          size: ButtonSize.XL,
          classes: "w-full",
          disabled: !securityTooLowDetails?.userHasIdentityCode,
          onClick: () => {
            const redirectUrl =
              ConfigSingleton.getInstance().getConfig().REACT_APP_CAS_URL +
              "/login?service=" +
              encodeURIComponent(ConfigSingleton.getInstance().getConfig().REACT_APP_CAS_CALLBACK_URL + "") +
              "&highlight=mid";
            logOutOfCas(redirectUrl);
          }
        }}
      >
        <Trans>GLOBAL.M_ID</Trans>
      </BhSecondaryButton>
      <BhSecondaryButton
        buttonProps={{
          size: ButtonSize.XL,
          classes: "w-full",
          disabled: !securityTooLowDetails?.userHasIdentityCode,
          onClick: () => {
            const redirectUrl =
              ConfigSingleton.getInstance().getConfig().REACT_APP_CAS_URL +
              "/login?service=" +
              encodeURIComponent(ConfigSingleton.getInstance().getConfig().REACT_APP_CAS_CALLBACK_URL + "") +
              "&highlight=sid";
            logOutOfCas(redirectUrl);
          }
        }}
      >
        <Trans>GLOBAL.S_ID</Trans>
      </BhSecondaryButton>
      {isMediumProjectWithoutTwoFactorWithEid && (
        <p className="pt-2 text-center">
          <Trans>PROJECT.SECURITY_MODAL.ACTIVATE.MEDIUM</Trans>
          <a
            className="bh-underline-link cursor-pointer pl-1"
            onClick={() => {
              navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/user/security`);
              dispatch(showSecurityTooLowModalSet(false));
            }}
          >
            <Trans>PROJECT.SECURITY_MODAL.ACTIVATE.SETTINGS</Trans>
          </a>
        </p>
      )}
      {isMediumProjectWithoutEid && (
        <p className="cursor-pointer pt-2 text-center">
          <Trans>PROJECT.SECURITY_MODAL.ACTIVATE.HIGH</Trans>
          <a
            className="bh-underline-link pl-1"
            onClick={() => {
              navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/user/security`);
              dispatch(showSecurityTooLowModalSet(false));
            }}
          >
            <Trans>PROJECT.SECURITY_MODAL.ACTIVATE.SETTINGS</Trans>
          </a>
        </p>
      )}
    </>
  );

  return (
    <>
      <BhModal
        isShown={securityTooLowModal}
        setIsShown={() => {
          dispatch(showSecurityTooLowModalSet(false));
        }}
        header={
          <div>
            <h2>
              <FontAwesomeIcon icon={securityTooLowDetails?.projectSecurityLevel === ProjectSecurityLevel.MEDIUM ? faShieldHalved : faShieldCheck} className="mr-4" />
              <Trans>{headerTransCode}</Trans>
            </h2>
          </div>
        }
        children={
          <div className="p-8 pt-0">
            {showGoToSettings && goToSettingsView}
            {showGoToLogin && goToLoginView}
          </div>
        }
      />
      <BhModal
        isShown={securityTooLowSyncModal}
        onClose={() => {
          navigate(-1);
          dispatch(showSecurityTooLowSyncModalSet(false));
        }}
        setIsShown={() => {
          dispatch(showSecurityTooLowSyncModalSet(false));
        }}
        header={
          <div>
            <h2>
              <FontAwesomeIcon icon={securityTooLowDetails?.projectSecurityLevel === ProjectSecurityLevel.MEDIUM ? faShieldHalved : faShieldCheck} className="mr-4" />
              <Trans>{headerTransCode}</Trans>
            </h2>
          </div>
        }
        children={
          <div className="p-8 pt-0">
            <p className="pt-2 text-center">
              <Trans>SYNC.SECURITY_LEVEL_TOO_LOW</Trans>
              <BhSecondaryButton
                buttonProps={{
                  size: ButtonSize.XL,
                  classes: "w-full mt-2",
                  onClick: () => {
                    navigate(-1);
                    dispatch(showSecurityTooLowSyncModalSet(false));
                  }
                }}
              >
                <Trans>SYNC.ALL_PROJECTS</Trans>
              </BhSecondaryButton>
            </p>
          </div>
        }
      />
    </>
  );
};

export default SecurityTooLowModal;
