import React, { FC, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import FormBaseFields from "@components/form/base/body/FormBaseFields";
import { classNames } from "@/utilities/jsUtilities";
import { IFormBaseVersionRowField } from "@/model/form/IFormBaseVersionRowField";

interface Props {
  saveNewFieldCallback: Function;
  previousField: IFormBaseVersionRowField;
}

const FormBaseFieldAddButton: FC<Props> = ({ saveNewFieldCallback, previousField }) => {
  const [fieldsVisible, setFieldsVisible] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [dropdownPositionRight, setDropdownPositionRight] = useState<number>();

  useEffect(() => {
    if (buttonRef?.current && dropdownRef?.current) {
      const isDropdownOutsideScreen = dropdownRef.current.getBoundingClientRect().right > window.innerWidth;
      if (isDropdownOutsideScreen) {
        const newPos = 30 - (window.innerWidth - buttonRef.current.getBoundingClientRect().right);
        setDropdownPositionRight(newPos);
      }
    }
  }, [fieldsVisible]);

  const saveNewRowWithField = (selectedField: any) => {
    setFieldsVisible(false);
    saveNewFieldCallback(selectedField.type, previousField);
  };

  const onOutsideClick = () => {
    setFieldsVisible(false);
  };

  return (
    <div className="relative flex h-full flex-row justify-center">
      <div
        ref={buttonRef}
        className={classNames(
          "bh-border-pigeon-40 bh-bg-smoke hover:bh-bg-mint hover:bh-border-dark-jungle bh-text-deep-ocean-80 hover:bh-text-dark-jungle flex h-full w-6 cursor-pointer flex-row justify-center rounded border border-dashed transition duration-200 ease-in-out",
          fieldsVisible && "opacity-0"
        )}
        onClick={() => setFieldsVisible(true)}
      >
        <div className="flex flex-col justify-center">
          <FontAwesomeIcon icon={faPlus} />
        </div>
      </div>
      {fieldsVisible && (
        <div className="bh-bg-white absolute top-0 z-20" ref={dropdownRef} style={{ right: dropdownPositionRight }}>
          <FormBaseFields onFieldSelectCallback={saveNewRowWithField} clickOutsideCallback={onOutsideClick} addFieldsToExistingRow={true} />
        </div>
      )}
    </div>
  );
};

export default FormBaseFieldAddButton;
