import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { selectFileConfirmationById } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import ConfirmationsListItemGeneral from "@/views/home/project/detail/confirmations/list/ConfirmationsListItemGeneral";
import ConfirmationsListItemNeedsApproval from "@/views/home/project/detail/confirmations/list/ConfirmationsListItemNeedsApproval";

interface Props {
  fileConfirmationId: EntityId;
}

const ConfirmationsListItem: FC<Props> = ({ fileConfirmationId }) => {
  const fileConfirmation = useAppSelector((state) => selectFileConfirmationById(state, fileConfirmationId));

  if (!fileConfirmation) {
    return null;
  }

  const needsUserAction = fileConfirmation.userFilesPending > 0;

  return (
    <>
      {!needsUserAction && <ConfirmationsListItemGeneral fileConfirmation={fileConfirmation} />}
      {needsUserAction && <ConfirmationsListItemNeedsApproval fileConfirmation={fileConfirmation} />}
    </>
  );
};

export default ConfirmationsListItem;
