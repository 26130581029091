import React, { FC } from "react";
import { IFolderFileEntity } from "@/model/files/IFileEntity";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { isDirectory } from "@/utilities/fileEntity/fileEntityUtilities";
import { useTranslation } from "react-i18next";
import FileNameLink from "@/features/fileContainer/FileNameLink";

interface Props {
  fileEntity: IFolderFileEntity;
  indicateDeletedFolders?: boolean;
}

const BhFileTreeContained: FC<Props> = ({ fileEntity, indicateDeletedFolders }) => {
  const { t } = useTranslation();
  const subDirs = fileEntity.subDirs;
  const isDir = isDirectory(fileEntity);

  if (!fileEntity) {
    return null;
  }

  return (
    <div className="group block">
      <div className="hover:bh-bg-mint-50 rounded-sm">
        <div className="flex h-7 flex-row items-center py-2 px-2">
          <div className="mr-1.5">
            <BhFileCardIcon fileEntity={fileEntity} fileCardSize={FileCardSize.SMALL} />
          </div>
          <FileNameLink fileEntity={fileEntity} disableOnClickHandler={true} />
          {indicateDeletedFolders && fileEntity.isDeleted && <i className="bh-text-pigeon ml-4">{t("FILE_DELETED")}</i>}
        </div>
      </div>
      {isDir && subDirs && subDirs.length > 0 && (
        <div className="ml-2">
          {subDirs.map((subDir) => {
            return <BhFileTreeContained fileEntity={subDir} indicateDeletedFolders={indicateDeletedFolders} key={subDir.id} />;
          })}
        </div>
      )}
    </div>
  );
};

export default BhFileTreeContained;
