import { useDragLayer, XYCoord } from "react-dnd";
import { CSSProperties } from "react";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import FileNameLink from "@/features/fileContainer/FileNameLink";
import { useAppSelector } from "@/app/hooks";
import { selectAllFilesSelected } from "@/app/store/filesSlice";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import { Trans } from "react-i18next";

export const CustomDragLayer = (props: {}) => {
  const selectedFileEntities = useAppSelector(selectAllFilesSelected);
  const oneSelectedFile = selectedFileEntities?.length === 1 && selectedFileEntities[0];
  const manySelectedFileEntities = selectedFileEntities?.length > 1;

  const { itemType, isDragging, initialCursorOffset, initialFileOffset, currentFileOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialCursorOffset: monitor.getInitialClientOffset(),
    initialFileOffset: monitor.getInitialSourceClientOffset(),
    currentFileOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging()
  }));

  if (!isDragging) {
    return null;
  }

  if (isDragging && itemType === "fileEntityRow") {
    return (
      <div style={layerStyles}>
        <div
          style={getItemStyles(initialCursorOffset, initialFileOffset, currentFileOffset)}
          className="h-13 bh-bg-mint-50 bh-border-dark-jungle w-90 flex flex flex-row items-center gap-x-1 overflow-hidden rounded border py-1 px-2"
        >
          {oneSelectedFile && (
            <>
              <BhFileCardIcon fileEntity={oneSelectedFile} fileCardSize={FileCardSize.LARGE} />
              <div className="flex flex-row items-center">
                <FileNameLink fileEntity={oneSelectedFile} />
              </div>
            </>
          )}
          {manySelectedFileEntities && (
            <>
              <BhBadge type={BhBadgeType.DARK}>{selectedFileEntities?.length}</BhBadge>
              <span>
                <Trans>DIRECTORY.FILES_SELECTED</Trans>
              </span>
            </>
          )}
        </div>
      </div>
    );
  }

  return null;
};

const layerStyles: CSSProperties = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%"
};

function getItemStyles(initialCursorOffset: XYCoord | null, initialOffset: XYCoord | null, currentOffset: XYCoord | null) {
  if (!initialOffset || !currentOffset || !initialCursorOffset) {
    return {
      display: "none"
    };
  }

  const x = initialCursorOffset?.x + (currentOffset.x - initialOffset.x) - 60;
  const y = initialCursorOffset?.y + (currentOffset.y - initialOffset.y) - 40;
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform
  };
}
