import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectAllFilesForCurrentDirectory, selectFilteredAndSortedFileEntityIdsForDirectory } from "@/app/store/filesSlice";
import { EntityId } from "@reduxjs/toolkit";
import { Trans } from "react-i18next";

interface Props {
  currentDirId: EntityId;
}

const DirectoryFileListContainerEmpty: FC<Props> = ({ currentDirId }) => {
  const fileIds = useAppSelector((state) => selectFilteredAndSortedFileEntityIdsForDirectory(state, currentDirId));
  const allFileEntitiesInCurrentDirectory = useAppSelector(selectAllFilesForCurrentDirectory);

  const emptyFolder = allFileEntitiesInCurrentDirectory.length < 1;

  return (
    <>
      {!emptyFolder && fileIds.length === 0 && (
        <div className="flex flex-row items-center justify-center p-6 text-center">
          <Trans>GLOBAL.NO_RESULTS</Trans>
        </div>
      )}
      {emptyFolder && (
        <div className="flex flex-row items-center justify-center p-6 text-center">
          <Trans>PROJECT.DIRECTORY.NOFILES</Trans>
        </div>
      )}
    </>
  );
};

export default DirectoryFileListContainerEmpty;
