import React, { ChangeEvent, FC, useEffect, useId, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromLine } from "@fortawesome/pro-regular-svg-icons/faArrowUpFromLine";
import { EntityId } from "@reduxjs/toolkit";
import { FileEntityResource, IFileEntity } from "@/model/files/IFileEntity";
import { uploadCompanyAttachmentAsync } from "@/app/store/uploadSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchFileEntityAsync, selectFileById } from "@/app/store/filesSlice";
import { fetchUrlForFile } from "@/api/fileAPI";
import { classNames } from "@/utilities/jsUtilities";
import BhDeclineButton from "@components/buttons/BhDeclineButton";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";

interface Props {
  formBaseId: EntityId;
  companyId: EntityId;
  logoFileEntityId?: EntityId;
  setPdfInfoCallback: Function;
}

const FormBaseInfoPdfLogoContainer: FC<Props> = ({ formBaseId, companyId, logoFileEntityId, setPdfInfoCallback }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const fileRevisionInputRef = useRef(null);
  const inputId = useId();
  const logoFileEntity = useAppSelector((state) => logoFileEntityId && selectFileById(state, logoFileEntityId));
  const [logoThumbnailUrl, setLogoThumbnailUrl] = useState<string | null>();

  useEffect(() => {
    if (!logoFileEntity && logoFileEntityId) {
      dispatch(fetchFileEntityAsync(logoFileEntityId));
    }
  }, [logoFileEntityId]);

  useEffect(() => {
    if (logoFileEntity) {
      fetchUrlForFile(logoFileEntity.id, true, false, logoFileEntity.uuid).then((response) => setLogoThumbnailUrl(response.value));
    }
  }, [logoFileEntity]);

  const onFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;
    for (let i = 0; i < files.length; ++i) {
      const file = files[i];
      dispatch(uploadCompanyAttachmentAsync({ file: file, companyId: companyId, resource: FileEntityResource.FORM_BASE_PDF, resourceId: formBaseId })).then((action) => {
        const savedFileEntity = action.payload as IFileEntity;
        setPdfInfoCallback({ logoFileEntityId: savedFileEntity.id });
      });
    }
    event.target.value = "";
  };

  const removeLogo = () => {
    setPdfInfoCallback({ logoFileEntityId: null });
    setLogoThumbnailUrl(null);
  };

  return (
    <div className="h-30 group relative flex w-44 flex-shrink-0 flex-col items-center justify-center hover:cursor-pointer">
      {logoThumbnailUrl && (
        <img src={logoThumbnailUrl} alt="logo" className="absolute max-h-[50px] max-w-[100px] object-contain transition duration-200 ease-in-out group-hover:opacity-0" crossOrigin="" />
      )}
      <div
        className={classNames(
          "bh-bg-smoke bh-border-pigeon-60 absolute flex h-full w-full flex-col justify-center gap-y-2 rounded border border-dashed align-middle transition duration-200 ease-in-out",
          logoThumbnailUrl && "hover:bh-bg-smoke opacity-0 group-hover:opacity-100",
          !logoThumbnailUrl && "hover:bh-bg-pigeon-20"
        )}
        // @ts-ignore
        onClick={() => fileRevisionInputRef.current.click()}
      >
        <div className="flex w-full flex-row items-center justify-center gap-x-2">
          <FontAwesomeIcon icon={faArrowUpFromLine} />
          <div className="text-12px font-bold leading-6">{t("FORMBUILDER.FORM.BASE.PDF.UPLOAD_LOGO_FILE")}</div>
          <input type="file" accept="image/*" id={inputId} ref={fileRevisionInputRef} style={{ display: "none" }} multiple={false} onChange={onFileSelect} />
        </div>
        {logoThumbnailUrl && (
          <BhDeclineButton
            icon={faTrash}
            buttonProps={{
              classes: "mx-4",
              onClick: (event: any) => {
                event.stopPropagation();
                removeLogo();
              }
            }}
          >
            Eemalda logo
          </BhDeclineButton>
        )}
      </div>
    </div>
  );
};

export default FormBaseInfoPdfLogoContainer;
