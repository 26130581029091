import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import ChecklistBaseGeneralInfo from "@components/checklists/checklistBase/body/ChecklistBaseGeneralInfo";
import ChecklistBaseInfoFields from "@components/checklists/checklistBase/body/ChecklistBaseInfoFields";
import ChecklistBaseRows from "@components/checklists/checklistBase/body/ChecklistBaseRows";

interface Props {
  companyId: EntityId;
  checklistBaseId: EntityId;
  checklistBaseVersionId: EntityId;
  projectId?: EntityId;
}

const ChecklistBaseBody: FC<Props> = ({ companyId, checklistBaseId, checklistBaseVersionId, projectId }) => {
  return (
    <div className="flex flex-col overflow-auto pb-32">
      <ChecklistBaseGeneralInfo checklistBaseId={checklistBaseId} checklistBaseVersionId={checklistBaseVersionId} companyId={companyId} />
      <ChecklistBaseInfoFields checklistBaseId={checklistBaseId} checklistBaseVersionId={checklistBaseVersionId} companyId={companyId} />
      <ChecklistBaseRows checklistBaseId={checklistBaseId} checklistBaseVersionId={checklistBaseVersionId} companyId={companyId} projectId={projectId} />
    </div>
  );
};

export default ChecklistBaseBody;
