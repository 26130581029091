import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { fetchContractByPartyId, saveContract, saveContractWorkType } from "@/api/contractAPI";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { IContract } from "@/model/contracts/IContract";
import { RootState } from "@/app/store";
import { addContractWorks } from "@/app/store/contractWorksSlice";
import { IContractWorkType } from "@/model/contracts/IContractWorkType";

const contractsAdapter = createEntityAdapter<IContract>();

const contractsInitialState = contractsAdapter.getInitialState<{
  status: BhStateStatusType;
}>({
  status: BhStateStatusType.INITIAL
});

export const fetchContractByPartyIdAsync = createAsyncThunk("contract/fetchContract", async (dto: { partyId: EntityId; projectId: EntityId }, { dispatch }) => {
  const result = await fetchContractByPartyId(dto.projectId, dto.partyId);
  dispatch(addContractWorks(result.works));
  return { ...result, works: [] };
});

export const saveContractAsync = createAsyncThunk("contract/save", async (contract: IContract) => {
  return saveContract(contract);
});

export const saveContractWorkTypeAsync = createAsyncThunk("contract/save/workType", async (contractWorkType: IContractWorkType) => {
  return saveContractWorkType(contractWorkType);
});

const contractsSlice = createSlice({
  name: "contracts",
  initialState: contractsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContractByPartyIdAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchContractByPartyIdAsync.fulfilled, (state, action: PayloadAction<IContract>) => {
        state.status = BhStateStatusType.SUCCESS;
        contractsAdapter.upsertOne(state, action.payload);
      })
      .addCase(saveContractAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveContractAsync.fulfilled, (state, action: PayloadAction<IContract>) => {
        state.status = BhStateStatusType.SUCCESS;
        contractsAdapter.upsertOne(state, action.payload);
      })
      .addCase(saveContractWorkTypeAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveContractWorkTypeAsync.fulfilled, (state, action: PayloadAction<IContractWorkType>) => {
        state.status = BhStateStatusType.SUCCESS;
        const workType = action.payload;
        const contract = state.entities[workType.contractId];
        const workTypes = contract?.workTypes || [];
        const workTypeExists = workTypes.some((wt) => wt.id === workType.id);
        const updatedWorkTypes = workTypeExists
          ? workTypes?.map((wt) => {
              return wt.id === workType.id ? workType : wt;
            })
          : [...workTypes, workType];
        const updatedContract = { ...contract, workTypes: updatedWorkTypes } as IContract;
        contractsAdapter.upsertOne(state, updatedContract);
      });
  }
});

export const { selectAll: selectAllContracts, selectById: selectContractById, selectIds: selectContractIds } = contractsAdapter.getSelectors((state: RootState) => state.contracts);

export const selectPartyEntityId = (state: RootState, partyId: EntityId) => partyId;
export const selectContractId = (state: RootState, contractId: EntityId) => contractId;

export const selectContractByPartyId = createSelector([selectAllContracts, selectPartyEntityId], (contracts, partyId) => {
  return contracts.find((c) => c.workGroupId === partyId);
});

export const selectContractWorkContainerIdsByContractId = createSelector([selectAllContracts, selectContractId], (contracts, contractId) => {
  const contract = contracts.find((c) => c.id === contractId);
  return contract?.contractWorkContainers.map((cwc) => cwc.id) || [];
});

export const selectContractWorkTypesByContractId = createSelector([selectAllContracts, selectContractId], (contracts, contractId) => {
  const contract = contracts.find((c) => c.id === contractId);
  return contract?.workTypes;
});

export default contractsSlice.reducer;
