import React, { FC } from "react";
import { Trans } from "react-i18next";
import { Authority, IUserAuthority, SubResource } from "@/model/IUserAuthority";
import ContactAuthoritiesDataSkeleton from "@/views/home/project/detail/users/contacts/contactRightsModal/ContactAuthoritiesDataSkeleton";

interface Props {
  isLoading: boolean;
  userAuthorities: Array<IUserAuthority>;
}

const ContactAuthoritiesRightsCard: FC<Props> = ({ isLoading, userAuthorities }) => {
  const contactRightsMap: Record<Authority, string> = {
    [Authority.ADMIN]: "PARTY.ADMIN",
    [Authority.READ]: "PARTY.READ",
    [Authority.WRITE]: "PARTY.WRITE",
    [Authority.NONE]: "PARTY.NONE",
    [Authority.SUPERUSER]: ""
  };

  const tableColumnHeaders = [
    { id: 0, content: <Trans>GLOBAL.DRAWINGS</Trans> },
    { id: 1, content: <Trans>GLOBAL.DOCUMENTS</Trans> },
    { id: 2, content: <Trans>PARTY.ACTS</Trans> },
    { id: 3, content: <Trans>PARTY.CONTRACTS</Trans> }
  ];

  const tableData = [
    { id: 0, content: userAuthorities.find((auth) => auth.subResource === SubResource.FILE) || ({} as IUserAuthority) },
    { id: 1, content: userAuthorities.find((auth) => auth.subResource === SubResource.DOCUMENT) || ({} as IUserAuthority) },
    { id: 2, content: userAuthorities.find((auth) => auth.subResource === SubResource.ACT) || ({} as IUserAuthority) },
    { id: 3, content: userAuthorities.find((auth) => auth.subResource === SubResource.CONTRACT) || ({} as IUserAuthority) }
  ];

  return (
    <div className="bh-bg-white mb-2 flex flex-col gap-3 rounded p-6">
      <h3>
        <Trans>CONTACT.ACCESS.MODAL.USER_AUTHORITIES</Trans>
      </h3>
      <table className="w-full divide-y">
        <thead>
          <tr>
            {tableColumnHeaders.map((th) => {
              return (
                <th key={th.id} className="w-1/4 break-words pb-3 pr-1">
                  {th.content}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            {tableData.map((data) => (
              <td key={data.id} className="w-1/4 pt-4">
                {isLoading ? <ContactAuthoritiesDataSkeleton /> : <Trans>{contactRightsMap[data.content.authority]}</Trans>}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ContactAuthoritiesRightsCard;
