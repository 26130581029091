import React, { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { faCalendarDay } from "@fortawesome/pro-regular-svg-icons/faCalendarDay";
import { useTranslation } from "react-i18next";
import FormFieldContainerInput from "@components/form/base/body/fields/FormFieldContainerInput";
import FormBaseLabelChangeModal from "@components/form/base/body/FormBaseLabelChangeModal";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  saveCallback: Function;
  fieldValue: any;
  disabled?: boolean;
}

const FormBaseProtocolFieldTableHeader: FC<Props> = ({ saveCallback, fieldValue, disabled }) => {
  const { t } = useTranslation();
  const [labelChangeModalOpen, setLabelChangeModalOpen] = useState(false);
  const labels = fieldValue.labels;
  const statusLabels = fieldValue.statusLabels;

  const saveLabels = (labelsToSave: any) => {
    saveCallback("labels", { ...fieldValue.labels, [labelsToSave.mainLabelProperty]: labelsToSave.mainLabel });
    saveCallback("statusLabels", labelsToSave.statusLabels);
    setLabelChangeModalOpen(false);
  };

  const saveLabel = (changedProperty: string, changedValue: any) => {
    const valueToSave = changedValue ? changedValue : t("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL." + changedProperty.toUpperCase());
    const labelsToSave = { ...fieldValue.labels, [changedProperty]: valueToSave };
    saveCallback("labels", labelsToSave);
  };

  return (
    <div className="flex flex-row items-end gap-x-1 py-2 font-bold">
      <div className="min-w-14 w-14"></div>
      <div className="min-w-20 w-20">
        <FormFieldContainerInput initialValue={labels.number} property={"number"} onBlurCallback={saveLabel} disabled={disabled} inputClasses={"min-w-20"} />
      </div>
      <div className="w-full">
        <FormFieldContainerInput initialValue={labels.content} property={"content"} onBlurCallback={saveLabel} disabled={disabled} inputClasses={"w-full"} />
      </div>
      <div className="min-w-56 flex w-56 flex-row items-center gap-x-2">
        <FontAwesomeIcon icon={faUser} />
        <FormFieldContainerInput initialValue={labels.assignee} property={"assignee"} onBlurCallback={saveLabel} disabled={disabled} inputClasses={"min-w-56"} />
      </div>
      <div className="min-w-26 max-w-26 w-26 flex flex-row items-center gap-x-2">
        <FontAwesomeIcon icon={faCalendarDay} />
        <FormFieldContainerInput initialValue={labels.deadline} property={"deadline"} onBlurCallback={saveLabel} disabled={disabled} inputClasses={"min-w-20"} />
      </div>
      <div
        className={classNames(
          "min-w-24 bh-text-deep-ocean-80 text-13px w-24 whitespace-normal break-words text-center font-bold leading-4",
          disabled ? "bh-bg-white hover:cursor-text" : "bh-bg-mint hover:bh-bg-mint-110 hover:cursor-pointer"
        )}
        onClick={() => {
          if (disabled) return;
          setLabelChangeModalOpen(true);
        }}
      >
        {labels.status}
      </div>
      <div className="min-w-24 mr-11 w-24 text-center">
        <FormFieldContainerInput initialValue={labels.taskBoard} property={"taskBoard"} onBlurCallback={saveLabel} disabled={disabled} inputClasses={"min-w-24 text-center"} />
      </div>
      {labelChangeModalOpen && (
        <FormBaseLabelChangeModal
          setIsModalOpen={setLabelChangeModalOpen}
          mainLabelProperty="status"
          initialMainLabel={t("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.STATUS")}
          currentMainLabel={labels.status}
          initialStatusLabels={{ TODO: t("TASK.TODO"), IN_PROGRESS: t("TASK.IN_PROGRESS"), NEEDS_APPROVAL: t("TASK.NEEDS_APPROVAL"), DONE: t("TASK.DONE") }}
          currentStatusLabels={statusLabels}
          saveCallback={saveLabels}
        />
      )}
    </div>
  );
};

export default FormBaseProtocolFieldTableHeader;
