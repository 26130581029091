import React, { Dispatch, FC, SetStateAction, useCallback } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { IProject } from "@/model/IProject";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import BhLightSeparator from "@components/separator/BhLightSeparator";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import { IUser } from "@/model/IUser";
import BONewProjectAdminInput from "@components/backoffice/company/projects/BONewProjectAdminInput";

interface Props {
  newProjectForm: IProject;
  setNewProjectForm: Dispatch<SetStateAction<IProject>>;
  companyId: EntityId;
}

const BOCompanyNewProjectAdministrators: FC<Props> = ({ newProjectForm, setNewProjectForm, companyId }) => {
  const { relatedUsers: selectedProjectAdmins } = newProjectForm;

  const onAdminRemove = useCallback(
    (selectedAdmin: ISimpleValidUserAuthority) => {
      const updatedArray = selectedProjectAdmins.filter((admin) => admin.userEntityId !== selectedAdmin.userEntityId || admin.username !== selectedAdmin.username);
      setNewProjectForm({ ...newProjectForm, relatedUsers: [...updatedArray] });
    },
    [newProjectForm]
  );

  return (
    <>
      <div className="full">
        <div className="flex flex-col">
          <div className="w-full">
            <BONewProjectAdminInput companyId={companyId} newProjectForm={newProjectForm} setNewProjectForm={setNewProjectForm} />
          </div>
          <div className="mt-2">
            {selectedProjectAdmins && selectedProjectAdmins?.length > 0 && <p className="bh-text-deep-ocean-80 mb-2 font-semibold">Project administrators</p>}
            {selectedProjectAdmins?.map((admin: ISimpleValidUserAuthority) => {
              return (
                <div key={admin.userEntityId || admin.username}>
                  <BhLightSeparator />
                  <div className="h-13 mx-3 flex items-center justify-between ">
                    <BhUserIconWithName user={{ username: admin.username, firstName: admin.firstName, lastName: admin.lastName } as IUser} showUsername={true} />
                    <BhTextOnlyButton buttonProps={{ onClick: () => onAdminRemove(admin) }} icon={faCircleMinus}>
                      Remove
                    </BhTextOnlyButton>
                  </div>
                </div>
              );
            })}
            {selectedProjectAdmins && selectedProjectAdmins.length > 0 && <BhLightSeparator />}
          </div>
        </div>
      </div>
    </>
  );
};

export default BOCompanyNewProjectAdministrators;
