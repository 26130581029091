import React, { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const XeokitMobileMoreToolsModal: FC<Props> = ({ children }) => {
  return (
    <div className="bh-shadow bh-bg-raised-white inline-block flex max-h-[400px] w-64 flex-col rounded py-3 text-left align-top">
      <div className="overflow-auto">{children}</div>
    </div>
  );
};

export default XeokitMobileMoreToolsModal;
