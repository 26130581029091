import React, { FC, useState } from "react";
import { useAppSelector } from "@/app/hooks";
import { boSelectCompany } from "@/app/store/backofficeSlice";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import { ICompanyModule } from "@/model/ICompany";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IProject } from "@/model/IProject";
import BhTableHeader from "@components/table/BhTableHeader";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BOCompanyProjectCodesTableRow from "@components/backoffice/company/modulesAndTemplates/BOCompanyProjectCodesTableRow";

interface Props {
  setIsShown: (value: boolean) => void;
  module: ICompanyModule;
}

const BOCompanyProjectCodesModal: FC<Props> = ({ setIsShown, module }) => {
  const company = useAppSelector(boSelectCompany);
  const [nameFilter, setNameFilter] = useState("");

  const filteredProjects = company.projects?.filter((project) => project.name.toLowerCase().includes(nameFilter.toLowerCase()));

  const tableColumnHeaders: Array<IBhTableHeader<IProject>> = [
    { id: 1, content: "Name", field: "name", sortable: true, classes: "w-1/2" },
    { id: 2, content: "Project code", classes: "" }
  ];

  return (
    <BhModal
      size="3xl"
      header={
        <h2>
          <span className="bh-text-deep-ocean-60">Project codes</span> – {module.module}
        </h2>
      }
      footer={<BhModalFooter cancelButtonText="Close" onCancel={() => setIsShown(false)} />}
      setIsShown={setIsShown}
    >
      <div className="h-full overflow-auto px-20 pt-8 pb-20">
        <BhSearchInputWBG property="name" inputClasses="w-72" placeholder="Search from project" initialValue={nameFilter} onChangeCallback={(e: any) => setNameFilter(e.name)} />
        <table className="mt-2 w-full">
          <BhTableHeader columns={tableColumnHeaders} onClickCallback={() => {}} />
          <tbody>
            {filteredProjects.map((project) => (
              <BOCompanyProjectCodesTableRow project={project} key={project.id} />
            ))}
          </tbody>
        </table>
      </div>
    </BhModal>
  );
};

export default BOCompanyProjectCodesModal;
