import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import BhInputSimple from "@components/input/BhInputSimple";

interface Props {
  initialValue: any;
  property: string;
  saveCallback?: Function;
  disabled?: boolean;
  inputClasses?: string;
  onPasteCallback?: Function;
}

const BhFormInput: FC<Props> = ({ property, initialValue, saveCallback, disabled, inputClasses, onPasteCallback }) => {
  return (
    <BhInputSimple
      initialValue={initialValue}
      property={property}
      inputClasses={classNames("p-1 h-6 border-0 bh-bg-smoke-50 form-table-input ", inputClasses)}
      saveCallback={saveCallback}
      onPasteCallback={onPasteCallback}
      disabled={disabled}
    />
  );
};

export default BhFormInput;
