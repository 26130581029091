import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import ProjectTaskModal from "@/views/home/project/projectModals/ProjectTaskModal";
import { selectConfirmationModalOpen, selectContainerModalOpen, selectDirectoryShareboxModalOpen, selectProjectTaskModalOpen } from "@/app/store/project/projectSlice";
import { selectLightboxModalOpen } from "@/app/store/lightboxSlice";
import DirectoryConfirmationModalContainer from "@/views/home/project/detail/directory/directoryShareModals/DirectoryConfirmationModalContainer";
import DirectoryShareboxModalContainer from "@/views/home/project/detail/directory/directoryShareModals/DirectoryShareboxModalContainer";
import DirectoryContainerModalContainer from "@/views/home/project/detail/directory/directoryShareModals/DirectoryContainerModalContainer";
import LightboxModal from "@/views/home/project/projectModals/LightboxModal";

const ProjectModals: FC = () => {
  const projectTaskModalOpen = useAppSelector(selectProjectTaskModalOpen);
  const directoryConfirmationModalOpen = useAppSelector(selectConfirmationModalOpen);
  const directoryShareboxModalOpen = useAppSelector(selectDirectoryShareboxModalOpen);
  const directoryContainerModalOpen = useAppSelector(selectContainerModalOpen);
  const lightboxModalOpen = useAppSelector(selectLightboxModalOpen);

  return (
    <>
      {projectTaskModalOpen && <ProjectTaskModal />}
      {directoryConfirmationModalOpen && <DirectoryConfirmationModalContainer />}
      {directoryShareboxModalOpen && <DirectoryShareboxModalContainer />}
      {directoryContainerModalOpen && <DirectoryContainerModalContainer />}
      {lightboxModalOpen && <LightboxModal />}
    </>
  );
};

export default ProjectModals;
