import React, { FC } from "react";
import BhBanner from "@components/banners/BhBanner";
import { BauhubBannerType } from "@/model/IProject";
import { useTranslation } from "react-i18next";

const ChecklistHeaderArchivedStatus: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="m-1">
      <BhBanner type={BauhubBannerType.WARNING} fullWidth={true}>
        <div className="text-14px ml-2 leading-5">{t("CHECKLIST.ARCHIVED")}</div>
      </BhBanner>
    </div>
  );
};

export default ChecklistHeaderArchivedStatus;
