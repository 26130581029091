import React, { FC, useCallback } from "react";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhFilterContainer from "@components/filters/BhFilterContainer";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { confirmationsFilterSet, selectConfirmationsFilter, selectConfirmationUserBubbles } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import { useTranslation } from "react-i18next";
import { FileConfirmationStatusFilter, FileConfirmationUserRoleFilterEnum, IFileConfirmationsFilter, IFileConfirmationsRoleFilter } from "@/model/confirmations/IFileConfirmationsFilter";
import BhFilter from "@components/filters/BhFilter";
import { BhFilterTypeEnum } from "@components/filters/BhFilterTypeEnum";
import { IUserAuthority } from "@/model/IUserAuthority";
import _ from "lodash";
import BhToggleButtonBar from "@components/toggle/BhToggleButtonBar";

interface Props {}

const ConfirmationsListFilter: FC<Props> = () => {
  const { t } = useTranslation();
  const filter = useAppSelector(selectConfirmationsFilter);
  const users = useAppSelector(selectConfirmationUserBubbles);
  const dispatch = useAppDispatch();

  const onFilterChange = useCallback(
    (changedValue: IFileConfirmationsFilter) => {
      dispatch(confirmationsFilterSet({ ...filter, ...changedValue }));
    },
    [filter]
  );

  const userRoleFilterValues = [
    { name: FileConfirmationUserRoleFilterEnum.CREATOR, translation: "FILE_CONFIRMATION.STARTER" },
    { name: FileConfirmationUserRoleFilterEnum.NEXT_CONFIRMER, translation: "CONFIRMATION.STATUS.NEXT_IN_LINE" },
    { name: FileConfirmationUserRoleFilterEnum.PARTICIPANT, translation: "FILE_CONFIRMATION.CONFIRMER" }
  ];

  const onSelect = useCallback(
    (array: keyof IFileConfirmationsFilter, value: any) => {
      const filteredArray = filter[array] as Array<any>;
      const changedValue = { ...filter, [array]: filteredArray.some((f) => _.isEqual(f, value)) ? filteredArray.filter((f) => !_.isEqual(f, value)) : [...filteredArray, value] };
      dispatch(confirmationsFilterSet({ ...filter, ...changedValue }));
    },
    [filter]
  );

  const onReset = useCallback(
    (array: keyof IFileConfirmationsFilter) => {
      const changedValue = { ...filter, [array]: [] };
      dispatch(confirmationsFilterSet({ ...filter, ...changedValue }));
    },
    [filter]
  );

  const isSelected = useCallback(
    (array: keyof IFileConfirmationsFilter, value: any) => {
      const filteredArray = (filter[array] as Array<any>) || [];
      return filteredArray.some((f) => _.isEqual(f, value));
    },
    [filter]
  );

  const statusFilterButtonValues = Object.values(FileConfirmationStatusFilter).map((status) => {
    return { text: t("CONFIRMATION_STATUS." + status), value: status };
  });

  return (
    <BhFilterContainer>
      <div className="flex w-full flex-row flex-wrap items-center justify-between gap-y-2">
        <div className="flex flex-row">
          <div>
            <BhSearchInputWBG initialValue={filter.name} property="name" onChangeCallback={onFilterChange} placeholder={t("SHAREBOX.LIST.SEARCH_BY_NAME") as string} />
          </div>
          <div className="flex flex-row gap-x-2">
            <BhFilter
              title={t("TASKBOARD.FILTER.ROLE")}
              values={userRoleFilterValues}
              textProperty="translation"
              onSelect={(value: IFileConfirmationsRoleFilter) => onSelect("userRoles", value)}
              onReset={() => onReset("userRoles")}
              isSelected={(value: IFileConfirmationsRoleFilter) => isSelected("userRoles", value)}
              selectedValues={filter.userRoles && filter.userRoles.length}
              translateValues={true}
            />
            <BhFilter
              filterType={BhFilterTypeEnum.USER}
              title={t("TASKBOARD.FILTER.USER")}
              values={users}
              onSelect={(value: IUserAuthority) => onSelect("users", value)}
              onReset={() => onReset("users")}
              isSelected={(value: IUserAuthority) => isSelected("users", value)}
              selectedValues={filter.users && filter.users.length}
            />
          </div>
        </div>
        <div className="flex">
          <BhToggleButtonBar items={statusFilterButtonValues} property={"status"} onClickAction={onFilterChange} selected={filter.status} />
        </div>
      </div>
    </BhFilterContainer>
  );
};

export default ConfirmationsListFilter;
