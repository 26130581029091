import { createAction, createAsyncThunk, createEntityAdapter, createReducer, createSelector, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { deleteContact, fetchProjectContacts, hideContact, saveContact, toggleHideUserContactsGlobally, unhideContact } from "@/api/contactAPI";
import { IContact } from "@/model/IContact";
import { RootState } from "@/app/store";
import { IContactsCompanyDropdownFilter, IContactsCompanyFilter, IContactsFilter, IContactsWorkGroupDropdownFilter, IContactsWorkGroupFilter } from "@/model/contacts/IContactsFilter";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { IContactActionsOverlay } from "@/model/contacts/IContactActionsOverlay";
import { IWorkGroup, WorkGroupType } from "@/model/IWorkGroup";
import { selectAllProjectWorkGroups } from "@/app/store/project/projectWorkGroupsSlice";
import { getKeyValue, removeDuplicates } from "@/utilities/jsUtilities";
import { IContactsSort } from "@/model/contacts/IContactsSort";

export const projectContactsAdapter = createEntityAdapter<IContact>();

export interface IProjectContactsState {
  loading: BhStateStatusType;
  contactModalStatus: BhStateStatusType;
  contactEditModalOpen: boolean;
  contactDeleteModalOpen: boolean;
  contactRightsModalOpen: boolean;
  sort: IContactsSort;
  filter: IContactsFilter;
  editableContact: IContact;
  contactActionsOverlay: IContactActionsOverlay;
}

export const projectContactsInitialState = projectContactsAdapter.getInitialState<IProjectContactsState>({
  loading: BhStateStatusType.INITIAL,
  contactModalStatus: BhStateStatusType.INITIAL,
  contactEditModalOpen: false,
  contactDeleteModalOpen: false,
  contactRightsModalOpen: false,
  sort: { property: "firstName", reversed: false },
  filter: {
    searchQuery: "",
    company: { companySearchInput: "", selectedCompaniesInDropdown: [] },
    workGroup: { workGroupSearchInput: "", selectedWorkGroupIds: [] },
    workGroupType: []
  },
  editableContact: {} as IContact,
  contactActionsOverlay: {
    contactActionsOverlayOpen: false
  } as IContactActionsOverlay
});

export const fetchProjectContactsAsync = createAsyncThunk("project/fetchProjectContacts", async (projectId: EntityId, { getState }) => {
  return fetchProjectContacts(projectId);
});

export const saveContactAsync = createAsyncThunk("project/contact/saveContact", async (contact: IContact, { getState }) => {
  if (!contact.projectId) {
    const state: RootState = getState() as RootState;
    contact.projectId = state.project.current.id;
  }
  return saveContact(contact);
});

export const deleteContactAsync = createAsyncThunk("project/contacts/deleteContact", async (contact: IContact) => {
  return deleteContact(contact);
});

export const hideContactAsync = createAsyncThunk("project/contacts/hideContact", async (contact: IContact) => {
  return hideContact(contact);
});

export const unhideContactAsync = createAsyncThunk("project/contacts/unhideContact", async (contact: IContact) => {
  return unhideContact(contact);
});

export const toggleHideUserContactsGloballyAsync = createAsyncThunk("project/contacts/toggleHideUserContacts", async ({ userEntityId, hide }: { userEntityId: EntityId; hide: boolean }) => {
  return toggleHideUserContactsGlobally(userEntityId, hide);
});

export const resetProjectContacts = createAction("project/resetProjectContactsState");
export const contactsUpdatedAndAdded = createAction<Array<IContact>>("project/contactsUpdatedAndAdded");
export const setContactsSortBy = createAction<keyof IContact>("project/contacts/setSortBy");
export const toggleContactsSortReversed = createAction("project/contacts/toggleSortReversed");
export const setContactsFilter = createAction<IContactsFilter>("project/contacts/setContactsFilter");
export const setContactsCompanySearchFilter = createAction<IContactsCompanyDropdownFilter>("project/contacts/setContactsCompanyFilter");
export const setContactsWorkGroupSearchFilter = createAction<IContactsWorkGroupDropdownFilter>("project/contacts/setContactsWorkGroupFilter");
export const setContactEditModalOpen = createAction<boolean>("project/contacts/setContactEditModalOpen");
export const setContactDeleteModalOpen = createAction<boolean>("project/contacts/setContactDeleteModalOpen");
export const setContactRightsModalOpen = createAction<boolean>("project/contacts/setContactRightsModalOpen");
export const setContactActionsOverlayOpen = createAction<boolean>("project/contacts/setContactActionsOverlayOpen");
export const setEditableContact = createAction<IContact>("project/contacts/setEditableContact");
export const companyToggledInContactsFilter = createAction<IContactsCompanyFilter>("project/contacts/toggleContactCompaniesFilter");
export const workGroupToggledInContactsFilter = createAction<IContactsWorkGroupFilter>("project/contacts/toggleContactWorkGroupsFilter");
export const workGroupTypeToggledInContactsFilter = createAction<WorkGroupType>("project/contacts/toggleContactWorkGroupsTypeFilter");
export const clearContactCompanyFilter = createAction("project/contacts/clearContactCompanyFilter");
export const clearContactWorkGroupFilter = createAction("project/contacts/clearContactWorkGroupFilter");
export const clearContactWorkGroupTypeFilter = createAction("project/contacts/clearContactWorkGroupTypeFilter");

export const projectContactsExtraReducer = createReducer(projectContactsInitialState, (builder) => {
  builder
    .addCase(resetProjectContacts, () => {
      return projectContactsInitialState;
    })
    .addCase(contactsUpdatedAndAdded, (state, action: PayloadAction<Array<IContact>>) => {
      const newAndUpdatedContacts = action.payload.map((contact) => {
        const alreadySavedContact = state.entities[contact.id];
        return alreadySavedContact ? { ...alreadySavedContact, ...contact } : contact;
      });
      projectContactsAdapter.upsertMany(state, newAndUpdatedContacts);
    })
    .addCase(setContactsSortBy, (state, action: PayloadAction<keyof IContact>) => {
      state.sort.property = action.payload;
    })
    .addCase(setContactsFilter, (state, action: PayloadAction<IContactsFilter>) => {
      state.filter = action.payload;
    })
    .addCase(setContactsCompanySearchFilter, (state, action: PayloadAction<IContactsCompanyDropdownFilter>) => {
      state.filter.company = action.payload;
    })
    .addCase(setContactsWorkGroupSearchFilter, (state, action: PayloadAction<IContactsWorkGroupDropdownFilter>) => {
      state.filter.workGroup.workGroupSearchInput = action.payload.workGroupSearchInput;
    })
    .addCase(toggleContactsSortReversed, (state) => {
      state.sort.reversed = !state.sort.reversed;
    })
    .addCase(fetchProjectContactsAsync.pending, (state) => {
      state.loading = BhStateStatusType.PENDING;
    })
    .addCase(fetchProjectContactsAsync.fulfilled, (state, action) => {
      state.loading = BhStateStatusType.SUCCESS;
      projectContactsAdapter.setAll(state, action.payload);
    })
    .addCase(saveContactAsync.pending, (state) => {
      state.contactModalStatus = BhStateStatusType.PENDING;
    })
    .addCase(saveContactAsync.fulfilled, (state, action) => {
      const contactEntity = action.payload;
      state.contactModalStatus = BhStateStatusType.SUCCESS;
      projectContactsAdapter.upsertOne(state, contactEntity);
    })
    .addCase(deleteContactAsync.pending, (state) => {
      state.contactModalStatus = BhStateStatusType.PENDING;
    })
    .addCase(deleteContactAsync.fulfilled, (state, action) => {
      const contactEntityId = action.payload.id;
      projectContactsAdapter.removeOne(state, contactEntityId);
    })
    .addCase(hideContactAsync.pending, (state) => {
      state.contactModalStatus = BhStateStatusType.PENDING;
    })
    .addCase(hideContactAsync.fulfilled, (state, action) => {
      const contactEntity = action.payload;
      projectContactsAdapter.upsertOne(state, contactEntity);
    })
    .addCase(unhideContactAsync.pending, (state) => {
      state.contactModalStatus = BhStateStatusType.PENDING;
    })
    .addCase(unhideContactAsync.fulfilled, (state, action) => {
      const contactEntity = action.payload;
      projectContactsAdapter.upsertOne(state, contactEntity);
    })
    .addCase(setContactEditModalOpen, (state, action) => {
      state.contactEditModalOpen = action.payload;
    })
    .addCase(setContactDeleteModalOpen, (state, action) => {
      state.contactDeleteModalOpen = action.payload;
    })
    .addCase(setContactRightsModalOpen, (state, action) => {
      state.contactRightsModalOpen = action.payload;
    })
    .addCase(setContactActionsOverlayOpen, (state, action) => {
      if (!action.payload) {
        state.contactActionsOverlay = projectContactsInitialState.contactActionsOverlay as IContactActionsOverlay;
      } else {
        state.contactActionsOverlay.contactActionsOverlayOpen = action.payload;
      }
    })
    .addCase(setEditableContact, (state, action) => {
      state.editableContact = action.payload;
    })
    .addCase(companyToggledInContactsFilter, (state, action) => {
      const isCompanyAlreadySelected = state.filter.company.selectedCompaniesInDropdown.findIndex((company) => company.companyName === action.payload.companyName);
      if (isCompanyAlreadySelected > -1) {
        state.filter.company.selectedCompaniesInDropdown.splice(isCompanyAlreadySelected, 1);
      } else {
        state.filter.company.selectedCompaniesInDropdown.push(action.payload);
      }
    })
    .addCase(workGroupToggledInContactsFilter, (state, action) => {
      const toggledWorkGroupId = action.payload.workGroupId;
      const indexInSelectedIds = state.filter.workGroup.selectedWorkGroupIds.indexOf(toggledWorkGroupId);

      if (indexInSelectedIds === -1) {
        state.filter.workGroup.selectedWorkGroupIds.push(toggledWorkGroupId);
      } else {
        state.filter.workGroup.selectedWorkGroupIds.splice(indexInSelectedIds, 1);
      }
    })
    .addCase(workGroupTypeToggledInContactsFilter, (state, action) => {
      const toggledWorkGroupTypeName = action.payload;
      const indexInSelectedIds = state.filter.workGroupType.findIndex((wg) => wg === toggledWorkGroupTypeName);

      if (indexInSelectedIds === -1) {
        state.filter.workGroupType.push(action.payload);
      } else {
        state.filter.workGroupType.splice(indexInSelectedIds, 1);
      }
    })
    .addCase(clearContactCompanyFilter, (state) => {
      state.filter.company.selectedCompaniesInDropdown = [];
      state.filter.company.companySearchInput = "";
    })
    .addCase(clearContactWorkGroupFilter, (state) => {
      state.filter.workGroup.selectedWorkGroupIds = [];
      state.filter.workGroup.workGroupSearchInput = "";
    })
    .addCase(clearContactWorkGroupTypeFilter, (state) => {
      state.filter.workGroupType = [];
    });
});

export const {
  selectAll: selectAllProjectContacts,
  selectIds: selectAllProjectContactIds,
  selectById: selectContactById
} = projectContactsAdapter.getSelectors((state: RootState) => state.project.contacts);

export const selectAllProjectContactsFilteredAndSorted = createSelector(
  [
    selectAllProjectContacts,
    selectAllProjectWorkGroups,
    (state) => state.project.contacts.sort.property as keyof IContact,
    (state) => state.project.contacts.sort.reversed as boolean,
    (state) => state.project.contacts.filter as IContactsFilter
  ],
  (contacts, workGroups, sortBy, sortReversed, filter) => {
    const sorted = [...contacts].sort((a, b) => {
      let aValue = getKeyValue<keyof IContact, IContact>(sortBy)(a) || "";
      let bValue = getKeyValue<keyof IContact, IContact>(sortBy)(b) || "";
      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.toLowerCase() < bValue.toLowerCase() ? -1 : 1;
      }
      return aValue < bValue ? -1 : 1;
    });
    if (sortReversed) sorted.reverse();
    return sorted
      .filter((contact) => {
        const nameMatches = (contact.firstName + " " + contact.lastName).toLowerCase().includes(filter.searchQuery.toLowerCase());
        const emailMatches = contact.email?.toLocaleLowerCase().includes(filter.searchQuery?.toLocaleLowerCase());
        return nameMatches || emailMatches;
      })
      .filter((contact) => {
        const companiesFilterSelected = filter.company.selectedCompaniesInDropdown.map((comp) => comp.companyName || "");
        return companiesFilterSelected.length === 0 ? contact : companiesFilterSelected.includes(contact.company || "");
      })
      .filter((contact) => {
        const selectedWorkGroupIds = filter.workGroup.selectedWorkGroupIds;
        const noFilterSelected = selectedWorkGroupIds.length === 0 || selectedWorkGroupIds.every((item) => item === undefined);
        const contactInSelectedWorkGroups =
          selectedWorkGroupIds.filter((selectedWorkGroup) => contact.workGroupIds.includes(selectedWorkGroup)).length > 0 || (selectedWorkGroupIds.includes(-1) && contact.workGroupIds.length === 0);
        return noFilterSelected || contactInSelectedWorkGroups;
      })
      .filter((contact) => {
        const selectedWorkGroupTypes = filter.workGroupType;
        const noFilterSelected = selectedWorkGroupTypes.length === 0 || selectedWorkGroupTypes.every((item) => item === undefined);
        const workGroupsWithNoType = workGroups.filter((wg) => !wg.type);
        const emptyWorkGroupType =
          selectedWorkGroupTypes.length === 0 &&
          contact.workGroupIds?.length !== 0 &&
          workGroupsWithNoType.filter((wg) => typeof wg.id === "number" && contact.workGroupIds?.includes(wg.id)).length > 0;
        const workGroupIdsOfSelectedTypes = workGroups.filter((workGroup) => workGroup.type && selectedWorkGroupTypes.includes(workGroup.type)).map((workGroup) => workGroup.id);
        const contactInSelectedWorkGroupTypes = contact.workGroupIds?.filter((contactWorkGroupId) => workGroupIdsOfSelectedTypes.includes(contactWorkGroupId)).length > 0;

        return noFilterSelected || emptyWorkGroupType || contactInSelectedWorkGroupTypes;
      });
  }
);

export const selectCurrentProjectContactsLoading = (state: RootState) => state.project.contacts.loading;
export const selectCurrentProjectContactsSortedBy = (state: RootState) => state.project.contacts.sort.property;
export const selectCurrentProjectContactsSortReversed = (state: RootState) => state.project.contacts.sort.reversed;
export const selectCurrentProjectContactsFilter = (state: RootState) => state.project.contacts.filter;

export const selectContactsFilterWorkGroupDropdownValues = createSelector(
  [selectAllProjectWorkGroups, selectAllProjectContacts, selectCurrentProjectContactsFilter],
  (workGroups, contacts, filter) => {
    let contactsWorkGroupIds = contacts
      .map((contact: IContact) => {
        if (contact.workGroupIds && contact.workGroupIds.length > 0) {
          return contact.workGroupIds;
        } else {
          return -1;
        }
      })
      .flat(1);
    contactsWorkGroupIds = removeDuplicates(contactsWorkGroupIds);

    let dropdownValues = contactsWorkGroupIds.map((workGroupId: EntityId) => {
      return {
        workGroupId: workGroupId,
        workGroupName:
          workGroups.find((wg: IWorkGroup) => {
            return wg.id === workGroupId;
          })?.name || ""
      };
    });
    dropdownValues = removeDuplicates(dropdownValues);

    const filteredAndSortedDropdownValues = dropdownValues
      .filter(
        (dropdownValue: IContactsWorkGroupFilter) => dropdownValue.workGroupName.length > 0 && dropdownValue.workGroupName.toLowerCase().includes(filter.workGroup.workGroupSearchInput.toLowerCase())
      )
      .sort((a: IContactsWorkGroupFilter, b: IContactsWorkGroupFilter) => {
        return a.workGroupName < b.workGroupName ? -1 : 1;
      });
    return filteredAndSortedDropdownValues;
  }
);

export const selectContactsFilterCompanyDropdownValues = createSelector([selectAllProjectContacts, selectCurrentProjectContactsFilter], (contacts, filter) => {
  const companies = contacts.filter((contact: IContact) => contact.company && contact.company.length > 0).map((contact: IContact) => contact.company);
  const uniqueCompanyNames = removeDuplicates(companies).map((name) => {
    return { companyName: name };
  });
  const companyDropdownFilterValue = filter.company.companySearchInput;
  if (companyDropdownFilterValue.length > 0) {
    return uniqueCompanyNames.filter((company) => company.companyName?.toLowerCase().includes(companyDropdownFilterValue.toLowerCase()));
  }
  return uniqueCompanyNames;
});

//TODO hetkel on ainult 2 WG tüüpi ja pole selectorit vaja, kas kustutada see selector?
export const selectCurrentProjectContactsFilterWorkGroupTypeDropdownValues = createSelector(
  [selectAllProjectWorkGroups, (state) => state.project.contacts.filter.workGroupType.selectedWorkGroupTypes],
  (projectWorkGroups, previouslySelectedWorkGroupTypes: Array<string>) => {
    let projectWorkGroupTypes = projectWorkGroups.map((workGroup) => {
      return workGroup.type || "";
    });
    projectWorkGroupTypes = removeDuplicates(projectWorkGroupTypes);

    let dropdownValues = projectWorkGroupTypes
      .map((type) => {
        let previouslySelected = previouslySelectedWorkGroupTypes.some((prevSelectedType) => prevSelectedType === type);
        return { workGroupType: type, selected: previouslySelected };
      })
      .sort((a, b) => {
        return a.workGroupType < b.workGroupType ? -1 : 1;
      });

    return dropdownValues;
  }
);

export const selectContactEditModalOpen = (state: RootState) => state.project.contacts.contactEditModalOpen;
export const selectContactRightsModalOpen = (state: RootState) => state.project.contacts.contactRightsModalOpen;
export const selectEditableContact = (state: RootState) => state.project.contacts.editableContact;
