import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import BhInputWithDropdown from "@components/input/BhInputWithDropdown";
import { IChecklistBaseVersion } from "@/model/checklist/IChecklistBaseVersion";
import ChecklistStatisticsExportCSVButton from "@components/checklists/statistics/ChecklistStatisticsExportToExcelButton";
import ChecklistStatisticsSummaryDetails from "@components/checklists/statistics/ChecklistStatisticsSummaryDetails";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyChecklistBases } from "@/app/store/checklist/checklistsSlice";
import { IChecklistBase } from "@/model/checklist/IChecklistBase";
import { useTranslation } from "react-i18next";
import { IChecklistStatistics } from "@/model/checklist/IChecklistStatistics";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import { EntityId } from "@reduxjs/toolkit";
import { sortByAnyType } from "@/utilities/sortUtilities";
import { fetchChecklistStatisticsForProject } from "@/api/checklist/checklistStatisticsAPI";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";

interface ISelectedBase {
  baseId: EntityId;
  version: number;
}

interface Props {
  checklistStatistics: IChecklistStatistics;
  setChecklistStatistics: Dispatch<SetStateAction<IChecklistStatistics>>;
}

const ChecklistStatisticsContainerFC: FC<Props> = ({ checklistStatistics, setChecklistStatistics }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const checklistBases = useAppSelector(selectCompanyChecklistBases);
  const [selectedChecklistBase, setSelectedChecklistBase] = useLocalStorage<ISelectedBase>("checklistStatisticsSelectedBase", {} as ISelectedBase);
  const selectedBaseEntity = checklistBases.find((base) => base.id === selectedChecklistBase.baseId);
  const [isLoading, setIsLoading] = useState(false);

  const findLastVersionForBase = (checklistBase: IChecklistBase) => {
    return checklistBase.checklistBaseVersions.reduce((max, version) => (max.versionNumber > version.versionNumber ? max : version));
  };

  useEffect(() => {
    const previouslySelectedBase = checklistBases.find((base) => base.id === selectedChecklistBase.baseId);
    const defaultSelectedBase = {
      baseId: previouslySelectedBase ? selectedChecklistBase.baseId : checklistBases[0].id,
      version: previouslySelectedBase ? selectedChecklistBase.version : findLastVersionForBase(checklistBases[0]).versionNumber
    } as ISelectedBase;
    setSelectedChecklistBase(defaultSelectedBase);
  }, [checklistBases]);

  useEffect(() => {
    setIsLoading(true);
    if (selectedChecklistBase)
      fetchChecklistStatisticsForProject(projectId, selectedChecklistBase.baseId, selectedChecklistBase.version).then((res) => {
        setChecklistStatistics(res);
        setIsLoading(false);
      });
  }, [selectedChecklistBase]);

  const changeChecklistBase = (checklistBase: IChecklistBase) => {
    const newSelectedBase = {
      baseId: checklistBase.id,
      version: findLastVersionForBase(checklistBase).versionNumber
    } as ISelectedBase;
    if (selectedChecklistBase.baseId !== newSelectedBase.baseId || selectedChecklistBase.version !== newSelectedBase.version) {
      setSelectedChecklistBase(newSelectedBase);
    }
  };

  const changeChecklistBaseVersion = (checklistBaseVersion: IChecklistBaseVersion) => {
    const newSelectedBase = { ...selectedChecklistBase, version: checklistBaseVersion.versionNumber };
    if (selectedChecklistBase.version !== newSelectedBase.version) {
      setSelectedChecklistBase(newSelectedBase);
    }
  };

  const anyChecklistSelected = selectedChecklistBase.baseId && selectedChecklistBase.version;
  const noChecklists = checklistStatistics.checklists?.length === 0;

  if (!selectedBaseEntity) return null;

  const sortedVersionsForDropdown = selectedBaseEntity.checklistBaseVersions
    .slice()
    .sort((a, b) => sortByAnyType(a, b, "versionNumber", true))
    .map((version) => {
      return { ...version, versionFullName: t("CHECKLIST.CHECKLIST_VERSION") + " " + version.versionNumber };
    });

  return (
    <div className="bh-bg-smoke mt-3.5 w-full rounded p-3">
      <div className="flex w-full flex-row items-end gap-2">
        {selectedBaseEntity && sortedVersionsForDropdown && (
          <>
            <div className="w-3/4">
              <BhInputWithDropdown
                initialValue={selectedBaseEntity.type || ""}
                values={checklistBases}
                property="type"
                onSelect={(checklistBase: IChecklistBase) => changeChecklistBase(checklistBase)}
                label={t("CHECKLIST.STATISTICS.TEMPLATE") as string}
              />
            </div>
            <div className="w-1/4">
              <BhInputWithDropdown
                initialValue={t("CHECKLIST.CHECKLIST_VERSION") + " " + selectedChecklistBase.version}
                values={sortedVersionsForDropdown}
                onSelect={(checklistBaseVersion: IChecklistBaseVersion) => changeChecklistBaseVersion(checklistBaseVersion)}
                property="versionFullName"
                label={t("CHECKLIST.CHECKLIST_VERSION") as string}
              />
            </div>
          </>
        )}
        <ChecklistStatisticsExportCSVButton checklistBase={selectedBaseEntity} versionNumber={selectedChecklistBase.version} isDisabled={noChecklists} />
      </div>
      {anyChecklistSelected && <ChecklistStatisticsSummaryDetails checklistStatistics={checklistStatistics} isLoading={isLoading} />}
    </div>
  );
};

const ChecklistStatisticsContainer = React.memo(ChecklistStatisticsContainerFC);
export default ChecklistStatisticsContainer;
