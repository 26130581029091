import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectAnyFilesSelected, selectCurrentDirId, selectFilesSelectedInCurrentDirectory, setDirectoryModalsOpen } from "@/app/store/filesSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft } from "@fortawesome/pro-regular-svg-icons/faClockRotateLeft";
import FileNameWrapped from "@/features/fileContainer/FileNameWrapped";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhModal from "@components/modal/BhModal";
import { useTranslation } from "react-i18next";
import { FileEntityType } from "@/model/files/IFileEntity";
import { ITabArrayElement } from "@/model/tabs/ITabArrayElement";
import BhTabs from "@components/tabs/BhTabs";
import FileLogContainer from "@/views/home/project/detail/directory/directoryModals/log/FileLogContainer";
import DirectoryLogContainer from "@/views/home/project/detail/directory/directoryModals/log/DirectoryLogContainer";
import { selectFolderById } from "@/app/store/foldersSlice";

const DirectoryFileHistoryModal: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentDirectoryId = useAppSelector(selectCurrentDirId);
  const currentDirectory = useAppSelector((state) => currentDirectoryId && selectFolderById(state, currentDirectoryId));
  const anyFilesSelected = useAppSelector(selectAnyFilesSelected);
  const selectedFiles = useAppSelector(selectFilesSelectedInCurrentDirectory);
  const selectedFile = selectedFiles[0];
  const openModalForCurrentDirectory = currentDirectoryId && currentDirectory && !anyFilesSelected;
  const isDir = (selectedFile && selectedFile.type === FileEntityType.DIR) || (!anyFilesSelected && currentDirectory);

  const tabs: Array<ITabArrayElement> = [
    { id: 0, header: t("DIRECTORY.LOG.ACTIONS"), content: <DirectoryLogContainer directory={openModalForCurrentDirectory ? currentDirectory : selectedFile} /> },
    { id: 1, header: t("DIRECTORY.LOG"), content: <FileLogContainer selectedFile={openModalForCurrentDirectory ? currentDirectory : selectedFile} /> }
  ];

  const closeModal = () => {
    dispatch(setDirectoryModalsOpen({ modal: "fileHistoryModal", value: false }));
  };

  return (
    <BhModal
      size="6xl"
      isShown={true}
      setIsShown={() => {}}
      onClose={closeModal}
      header={
        <div className="w-5/6">
          <div className="flex flex-row items-center">
            <h2>
              <FontAwesomeIcon icon={faClockRotateLeft} className="mr-4" />
            </h2>
            <h2 className="bh-text-deep-ocean-60">{(isDir ? t("DIRECTORY.LOG") : t("MODAL.FILE_LOG_BUTTON")) + " – "}</h2>
            <h2 className="max-w-half ml-1">
              <FileNameWrapped fileName={openModalForCurrentDirectory ? currentDirectory?.name : selectedFile?.name} />
            </h2>
          </div>
        </div>
      }
      children={
        <div className="h-full overflow-hidden">
          {!isDir && (
            <div className="px-8 py-6">
              <FileLogContainer selectedFile={selectedFile} />
            </div>
          )}
          {isDir && (
            <div className="h-full w-full overflow-hidden px-8 pt-4 pb-6">
              <BhTabs tabs={tabs} />
            </div>
          )}
        </div>
      }
      footer={
        <div>
          <BhTextOnlyButton buttonProps={{ onClick: closeModal }}>{t("GLOBAL.CLOSE")}</BhTextOnlyButton>
        </div>
      }
    />
  );
};

export default DirectoryFileHistoryModal;
