import { EntityId } from "@reduxjs/toolkit";

export interface ITaskBoardModals {
  open: ITaskBoardModalsOpen;
  activeTaskBoardId?: EntityId;
}

export interface ITaskBoardModalsOpen {
  taskBoardSettingsModal: boolean;
  projectTaskCategoryModal: boolean;
  partyAddTaskBoardModal: boolean;
}

export const TaskBoardModalsInitialState = {
  open: {
    taskBoardSettingsModal: false,
    projectTaskCategoryModal: false,
    partyAddTaskBoardModal: false
  }
};
