import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import { selectProjectById } from "@/app/store/companiesSlice";
import BhProjectStatusBadge from "@components/badge/BhProjectStatusBadge";
import { IProject } from "@/model/IProject";

interface Props {
  projectId: EntityId;
  isChecked: boolean;
  onChange: Function;
  property: string;
  isActive: boolean;
  onItemClick?: Function;
  classes?: string;
  index?: number;
  customProject?: IProject;
  hideStatusBadge?: boolean;
}

const ProjectTreeListItemWithCheckbox: FC<Props> = ({ projectId, isChecked, onChange, property, isActive, onItemClick, customProject, hideStatusBadge }) => {
  const projectById = useAppSelector((state) => selectProjectById(state, projectId));
  const project = customProject ? customProject : projectById;

  if (!project) return null;

  const onToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const changedValue = { [property]: e.target.checked };
    onChange && onChange(projectId, changedValue);
  };

  const onClick = () => {
    onItemClick && onItemClick(project.id);
  };

  return (
    <div className={classNames("hover:bh-bg-mint group flex h-7 cursor-pointer flex-row items-center justify-start   px-2", isActive && "bh-bg-mint-50")} onClick={onClick}>
      <input className="mr-2 h-4 w-4 rounded focus:ring-0 focus:ring-offset-0" type="checkbox" checked={isChecked} onChange={(e) => onToggle(e)} />
      {!hideStatusBadge && (
        <BhProjectStatusBadge type={project.securityLevel} classes={classNames(!isActive && "group-hover:bh-bg-none-group bh-bg-pigeon-20", "bh-text-deep-ocean-80 mr-1 w-5 h-5")} />
      )}
      <div className="bh-text-deep-ocean overflow-hidden text-ellipsis whitespace-nowrap">{project.name}</div>
    </div>
  );
};

export default ProjectTreeListItemWithCheckbox;
