import React, { FC, useEffect, useRef, useState } from "react";
import BhInputSimple from "@components/input/BhInputSimple";
import { useOuterClick } from "@/utilities/hooks/useOuterClick";
import { isValidEmail } from "@/utilities/jsUtilities";
import BhFixedDropdownParent from "@components/dropdown/BhFixedDropdownParent";
import BhStringDropdownMenu from "@components/dropdown/BhStringDropdownMenu";

interface Props {
  values: Array<string>;
  onSelect: Function;
  showValueCondition?: Function;
  placeholder?: string;
}

const BhEmailInputWithEmailsDropdown: FC<Props> = ({ values, onSelect, showValueCondition, placeholder }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const outsideClickRef = useOuterClick(() => {
    setIsDropdownOpen(false);
  });

  const onSelectDropdownValue = (selectedItem: string) => {
    onSelect && onSelect(selectedItem);
    setSearchQuery("");
    if (inputRef?.current) inputRef.current.value = "";
    setIsDropdownOpen(false);
  };

  const isEmailNotInValues = (email: string) => {
    return !values.some((value) => value === email);
  };

  const isCustomEmailAdd = searchQuery !== "" && isValidEmail(searchQuery) && isEmailNotInValues(searchQuery);

  const queryFilter = (value: string) => {
    return value?.toLowerCase().includes(searchQuery.toLowerCase());
  };

  const filteredValues = [
    ...values.filter((value) => {
      const isQueryFilter = searchQuery === "" ? true : queryFilter(value);
      const isShowValueCondition = showValueCondition ? showValueCondition(value) : true;
      return isQueryFilter && isShowValueCondition;
    }),
    isCustomEmailAdd && searchQuery
  ].filter(Boolean) as Array<string>;

  const onKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      filteredValues[selectedIndex] && onSelectDropdownValue(filteredValues[selectedIndex]);
    }
    if (event.key === "ArrowDown") {
      !isDropdownOpen && setIsDropdownOpen(true);
      selectedIndex + 1 < filteredValues.length && setSelectedIndex(selectedIndex + 1);
    }
    if (event.key === "ArrowUp") {
      !isDropdownOpen && setIsDropdownOpen(true);
      selectedIndex - 1 >= 0 && setSelectedIndex(selectedIndex - 1);
    }
  };

  const setQuery = (value: { query: string }) => {
    setSearchQuery(value.query);
    if (!isDropdownOpen) {
      setIsDropdownOpen(true);
    }
  };

  useEffect(() => {
    isDropdownOpen && setSelectedIndex(0);
  }, [isDropdownOpen]);

  return (
    <div className="relative w-full" ref={outsideClickRef}>
      <div onKeyDown={onKeyDown} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <BhInputSimple initialValue={searchQuery} property={"query"} placeholder={placeholder} onChangeCallback={setQuery} inputRef={inputRef} />
      </div>

      {isDropdownOpen && (filteredValues.length > 0 || isCustomEmailAdd) && (
        <BhFixedDropdownParent>
          <BhStringDropdownMenu values={filteredValues} onSelect={onSelectDropdownValue} customEmail={isCustomEmailAdd ? searchQuery : undefined} selectedIndex={selectedIndex} />
        </BhFixedDropdownParent>
      )}
    </div>
  );
};

export default React.memo(BhEmailInputWithEmailsDropdown);
