import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { IChecklist } from "@/model/checklist/IChecklist";
import BhTags from "@components/tags/BhTags";

interface Props {
  checklist: IChecklist;
}

const ChecklistHeaderRoundInfo: FC<Props> = ({ checklist }) => {
  return (
    <div className="flex flex-col">
      <div className="bh-text-deep-ocean text-18px font-bold leading-6">{checklist.name}</div>
      <div className="flex flex-row items-center pt-1">
        <div className="bh-text-deep-ocean-80 text-14px leading-5">{checklist.type}</div>
        {checklist.checklistTags && checklist.checklistTags.length > 0 && (
          <div className="flex flex-row items-center">
            <FontAwesomeIcon icon={faCircle} className="text-4px bh-text-pigeon-50 mx-4" />
            <BhTags tags={checklist.checklistTags} type={BhTagType.TAG} property={"name"} sortByProperty={true} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChecklistHeaderRoundInfo;
