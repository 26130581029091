import React, { FC } from "react";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhSearchInput from "@components/input/BhSearchInput";
import { IUser } from "@/model/IUser";
import { IFileEntity } from "@/model/files/IFileEntity";
import BhDropdownMenuListItem from "@components/dropdown/BhDropdownMenuListItem";
import BhDropdownMenuUserListItem from "@components/dropdown/BhDropdownMenuUserListItem";
import BhDropdownMenuFileListItem from "@components/dropdown/BhDropdownMenuFileListItem";
import BhSearchInputContainerInDropdown from "@components/input/BhSearchInputContainerInDropdown";
import { IDropdownItem } from "@/model/IDropdownItem";
import BhLightSeparator from "@components/separator/BhLightSeparator";
import BhDropdownMenuAuthListItem from "@components/dropdown/BhDropdownMenuAuthListItem";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { Menu } from "@headlessui/react";
import { classNames } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";

// FIGMA https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H/Bauhub-design-system?node-id=460%3A8702

interface Props {
  values?: Array<any | IUser | IFileEntity | IDropdownItem>;
  textProperty?: string;
  type: BhDropdownTypeEnum;
  withCheckbox?: boolean;
  onSelect?: Function;
  isSelected?: (value: any) => boolean;
  search?: boolean;
  searchInputSaveCallback?: Function;
  searchInputProperty?: string;
  dropdownSearchInitialValue?: string;
  numberOfItemsDisplayed?: number;
  widthClass?: string;
  closeOnItemClick?: boolean;
  withUsername?: boolean;
  customDropdown?: boolean;
  translateValues?: boolean;
  leadingProperty?: string;
}

const BhDropdownMenu: FC<Props> = ({
  values,
  textProperty,
  type,
  withCheckbox,
  onSelect,
  isSelected,
  search,
  searchInputSaveCallback,
  searchInputProperty,
  dropdownSearchInitialValue,
  numberOfItemsDisplayed,
  widthClass,
  closeOnItemClick,
  withUsername,
  customDropdown,
  translateValues,
  leadingProperty
}) => {
  const { t } = useTranslation();
  const closeOnItemClickProp = closeOnItemClick === undefined ? true : closeOnItemClick;
  const renderListItem = (dropDownType: BhDropdownTypeEnum, value: any | IUser | IFileEntity) => {
    switch (dropDownType) {
      case BhDropdownTypeEnum.STRING:
        return (
          <BhDropdownMenuListItem
            value={value}
            withCheckbox={withCheckbox}
            textProperty={textProperty}
            leadingProperty={leadingProperty}
            onSelect={onSelect}
            isSelected={isSelected}
            translateValues={translateValues}
          />
        );
      case BhDropdownTypeEnum.USER:
        return <BhDropdownMenuUserListItem value={value as IUser} withCheckbox={withCheckbox} onSelect={onSelect} isSelected={isSelected} />;
      case BhDropdownTypeEnum.AUTH:
        return <BhDropdownMenuAuthListItem value={value as ISimpleValidUserAuthority} withCheckbox={withCheckbox} onSelect={onSelect} isSelected={isSelected} withUsername={withUsername} />;
      case BhDropdownTypeEnum.FILE:
        return <BhDropdownMenuFileListItem value={value as IFileEntity} withCheckbox={withCheckbox} onSelect={onSelect} isSelected={isSelected} />;
      default:
        break;
    }
  };

  const getListItemKey = (dropDownType: BhDropdownTypeEnum, value: String | IUser | IFileEntity | IDropdownItem | ISimpleValidUserAuthority, index: number) => {
    switch (dropDownType) {
      case BhDropdownTypeEnum.STRING:
        return index;
      case BhDropdownTypeEnum.USER:
        return value && ((value as IUser).id || (value as ISimpleValidUserAuthority).userEntityId);
      case BhDropdownTypeEnum.FILE:
        return value && (value as IFileEntity).id;
      case BhDropdownTypeEnum.AUTH:
        return value && (value as ISimpleValidUserAuthority).userEntityId;
      default:
        break;
    }
  };

  const numberOfItems = numberOfItemsDisplayed ? numberOfItemsDisplayed * 30 + "px" : "";

  return (
    <div className={classNames("bh-shadow bh-bg-raised-white inline-block flex max-h-[400px] flex-col rounded py-3 text-left align-top", widthClass || "w-56")}>
      {search && (
        <BhSearchInputContainerInDropdown>
          <BhSearchInput property={searchInputProperty || ""} placeholder={t("GLOBAL.SEARCH_PLACEHOLDER") || ""} onChangeCallback={searchInputSaveCallback} initialValue={dropdownSearchInitialValue} />
        </BhSearchInputContainerInDropdown>
      )}
      <div className="overflow-auto" style={{ maxHeight: numberOfItems }}>
        <ul>
          {values &&
            values.map((value, index) => {
              if (value.separator) {
                return (
                  <div className="py-1.5" key={index}>
                    <BhLightSeparator />
                  </div>
                );
              }
              if (!closeOnItemClickProp || customDropdown) {
                return (
                  <li className="hover:bh-bg-smoke cursor-pointer" key={getListItemKey(type, value, index)}>
                    {renderListItem(type, value)}
                  </li>
                );
              } else {
                return (
                  <Menu.Item as={"div"} key={getListItemKey(type, value, index)}>
                    <li className="hover:bh-bg-smoke cursor-pointer">{renderListItem(type, value)}</li>
                  </Menu.Item>
                );
              }
            })}
        </ul>
      </div>
    </div>
  );
};

export default BhDropdownMenu;
