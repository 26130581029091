import React, { FC } from "react";
import BhUserIcon from "@components/user/BhUserIcon";
import { IObjectChange } from "@/model/IObjectChange";
import { classNames, getUserFromFullName } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight";
import TaskHistoryLogValue from "@components/task/modal/TaskHistoryLogValue";
import { formatDateTime } from "@/utilities/dateUtility";
import { ReactComponent as BhLogoMark } from "@svg/bauhub-logomark-green.svg";

interface Props {
  log: IObjectChange;
}

const TaskHistoryLog: FC<Props> = ({ log }) => {
  const { t } = useTranslation();

  return (
    <div className="mb-3 flex flex-row items-center">
      <div className={classNames("flex h-full flex-col justify-center pt-1", !log.creatorFullName && log.createdBy !== "bauhub" && "pl-8")}>
        {log.creatorFullName && (
          <div className="h-full pt-1">
            <BhUserIcon user={getUserFromFullName(log.creatorFullName)} />
          </div>
        )}
        {log.createdBy === "bauhub" && (
          <div className="h-full pt-1">
            <BhLogoMark className="h-8 w-8" />
          </div>
        )}
      </div>
      <div className="text-12 bh-text-deep-ocean ml-2 flex flex-col">
        <div>
          <span className="mr-1 font-bold">{log.creatorFullName ? log.creatorFullName : log.createdBy}</span>
          <span className="mr-1">{t("TASK.HISTORY." + log.userAction)}</span>
          <span className="font-bold">{t("HISTORY.FIELD_" + log.field.toUpperCase())}</span>
        </div>
        <div>
          {log.oldValue !== log.newValue && (
            <span>
              <TaskHistoryLogValue field={log.field} valueField={log.oldValue} />
              {log.oldValue && (
                <span className="mx-1">
                  <FontAwesomeIcon icon={faArrowRight} size={"sm"} />
                </span>
              )}
            </span>
          )}
          <TaskHistoryLogValue field={log.field} valueField={log.newValue} />
        </div>
        <div>{formatDateTime(log.created)}</div>
      </div>
    </div>
  );
};

export default TaskHistoryLog;
