import React, { FC, useEffect } from "react";
import { ITask } from "@/model/taskBoard/ITask";
import { EntityId } from "@reduxjs/toolkit";
import TaskMoveModal from "@/views/home/project/detail/task/modals/TaskMoveModal";
import { useAppSelector } from "@/app/hooks";
import { selectTaskById } from "@/app/store/tasksSlice";
import { selectCurrentUser, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { store } from "@/app/store";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";

interface Props {
  task?: ITask;
  taskId?: EntityId;
  isDedicatedView?: boolean;
  closeModal: Function;
  selectedTaskBoardId?: EntityId;
}

const TaskMoveModalContainer: FC<Props> = ({ task, taskId, isDedicatedView, closeModal, selectedTaskBoardId }) => {
  const taskFromState = useAppSelector((state) => taskId && selectTaskById(state, taskId));
  const taskObject = task ? task : taskFromState;

  const currentUser = useAppSelector(selectCurrentUser);
  const projectId = useAppSelector(selectCurrentProjectId);
  const isCurrentUserProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const isCurrentUserAdminOrTaskReporter = isCurrentUserProjectAdmin || (taskObject && taskObject.reporter === currentUser.id);

  useEffect(() => {
    // Kui avaja ei ole taski tegija või admin, siis keela teisaldada
    if (!isCurrentUserAdminOrTaskReporter) {
      closeModal();
      store.dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          translateCode: "NOT_ENOUGH_PRIVILEGES",
          disappear: true
        })
      );
    }
  }, []);

  if (isCurrentUserAdminOrTaskReporter && taskObject) {
    return <TaskMoveModal task={taskObject} isDedicatedView={isDedicatedView} closeModal={closeModal} selectedTaskBoardId={selectedTaskBoardId} />;
  }

  return null;
};

export default TaskMoveModalContainer;
