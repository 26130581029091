import React, { FC, ReactNode } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  children: ReactNode;
  classes?: string;
}

const BhSpreadsheetTableHead: FC<Props> = ({ children, classes }) => {
  return <thead className={classNames(classes)}>{children}</thead>;
};

export default React.memo(BhSpreadsheetTableHead);