import React, { FC, useState } from "react";
import { ChecklistRowType, IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import { classNames } from "@/utilities/jsUtilities";
import CheckpointButtonsNoSelection from "@components/checklists/checklist/body/rowTypes/checkpoint/CheckpointButtonsNoSelection";
import CheckpointButtonSelected from "@components/checklists/checklist/body/rowTypes/checkpoint/CheckpointButtonSelected";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowTurnDownRight } from "@fortawesome/pro-regular-svg-icons/faArrowTurnDownRight";
import { faSquareKanban } from "@fortawesome/pro-regular-svg-icons/faSquareKanban";
import { faLocationDot } from "@fortawesome/pro-regular-svg-icons/faLocationDot";
import { faMessage } from "@fortawesome/pro-regular-svg-icons/faMessage";
import BhInputSimple from "@components/input/BhInputSimple";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  attachFilesToChecklistRoundRowAsync,
  deleteChecklistRoundRowTaskAsync,
  modifyOneChecklistRow,
  removeFileFromChecklistRoundRowAsync,
  saveSubRowForRoundRowAsync,
  selectChecklistRowById
} from "@/app/store/checklist/checklistRowsSlice";
import { IChecklistRoundRow } from "@/model/checklist/IChecklistRoundRow";
import { selectChecklistLanguageForTranslationByRoundId } from "@/app/store/checklist/checklistsSlice";
import { ITask } from "@/model/taskBoard/ITask";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import BhTooltip from "@components/BhTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons/faCircleExclamation";
import PdftronMarkYesPointsContainer from "@/views/home/project/detail/pdftron/PdftronMarkYesPointsContainer";
import ChecklistTaskContainer from "@components/task/container/ChecklistTaskContainer";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons/faPaperclip";
import BhAttachmentsUploadContainer from "@components/upload/BhAttachmentsUploadContainer";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import AttachmentsContainerLarge from "@components/attachments/AttachmentsContainerLarge";

interface Props {
  baseVersionRow: IChecklistBaseVersionRow;
  rowId: EntityId;
  checklistBaseFileId?: EntityId;
  saveCallback: Function;
  extraRows?: Array<IChecklistRoundRow>;
  isExtraRow?: boolean;
  newTaskCallback?: Function;
  disabled: boolean;
  checklistId: EntityId;
}

const CheckpointRow: FC<Props> = ({ baseVersionRow, checklistBaseFileId, saveCallback, isExtraRow, rowId, extraRows, newTaskCallback, disabled, checklistId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const roundRow = useAppSelector((state) => selectChecklistRowById(state, rowId));
  const checklistLanguage = useAppSelector((state) => roundRow && selectChecklistLanguageForTranslationByRoundId(state, roundRow.checklistRoundId));
  const isCheckpointList = baseVersionRow.checklistRowType === ChecklistRowType.CHECKPOINT_LIST;
  const [commentVisible, setCommentVisible] = useState((roundRow && roundRow.description) || isExtraRow ? true : false);
  const [markYesPointsModalOpen, setMarkYesPointsModalOpen] = useState(false);

  if (!baseVersionRow || !roundRow) {
    return null;
  }

  const mainRow = baseVersionRow.indentation === 0 && !isExtraRow;
  const subRow = baseVersionRow.indentation === 1 && !isExtraRow;

  const saveRow = (changedObject: any, fieldValueChanged?: boolean) => {
    const rowToSave = { ...roundRow, ...changedObject };
    saveCallback(rowToSave, fieldValueChanged);
  };

  const addSubRow = () => {
    dispatch(saveSubRowForRoundRowAsync({ roundRowId: roundRow.id, roundId: roundRow.checklistRoundId })).then(() => {
      // If this is the first sub-row added to a row then move its task, description and annotationUuid to the newly created row (here we just delete main row attributes, other logic is done in back-end)
      if (!extraRows || !extraRows.length) {
        const mainRowToChange = { ...roundRow, ...{ tasks: [] as Array<ITask>, description: "", primaryAnnotationUuid: "" } };
        dispatch(modifyOneChecklistRow(mainRowToChange));
      }
    });
  };

  const deleteSubRow = () => {
    const rowToDelete = { ...roundRow, ...{ deleted: true } };
    saveCallback(rowToDelete);
  };

  const deleteRowDescription = () => {
    const rowToSave = { ...roundRow, ...{ description: "" } };
    setCommentVisible(false);
    saveCallback(rowToSave);
  };

  const deleteRowTask = (taskId: EntityId) => {
    dispatch(deleteChecklistRoundRowTaskAsync({ checklistRoundRow: roundRow, taskId: taskId }));
  };

  const saveRowAttachments = (files: any, isUpload: boolean) => {
    if (files.uploaded.length > 0) {
      const attachments = files.uploaded.map((file: IFileEntity) => {
        return {
          checklistId: checklistId,
          fileEntityId: file.id,
          baseVersionRowId: baseVersionRow.id,
          checklistProjectId: projectId,
          deleted: false,
          upload: isUpload
        };
      });
      dispatch(attachFilesToChecklistRoundRowAsync({ checklistRoundId: roundRow.checklistRoundId, checklistRoundRowId: roundRow.id, attachments: attachments }));
    }
  };

  const removeRowAttachment = (fileEntityId: EntityId) => {
    dispatch(removeFileFromChecklistRoundRowAsync({ checklistRoundId: roundRow.checklistRoundId, fileEntityId: fileEntityId }));
  };

  return (
    <div className={classNames("mb-1 w-full", subRow && "pl-4", mainRow && "mt-6", isExtraRow && "mt-1 pl-4")}>
      <div className="flex flex-row">
        <div className="bh-bg-smoke min-h-12 bh-border-deep-ocean-80 flex w-full flex-col border-l-2 py-1.5 px-3">
          {!isExtraRow && (
            <div className="flex h-full flex-row items-center justify-between">
              <div className="text-14px bh-text-deep-ocean flex flex-row font-bold leading-5">
                <div className="mr-1">{baseVersionRow.number + "."}</div>
                <div>{baseVersionRow.fieldTitle}</div>
              </div>
              {baseVersionRow.checkpoint && (
                <div className="flex flex-row items-center">
                  {!disabled && (
                    <div className="flex flex-row items-center">
                      <BhTooltip body={extraRows && extraRows.length > 0 ? t("CHECKLIST.CHECKPOINT.ADD_NEW_SUB_ROW") : t("CHECKLIST.CHECKPOINT.DIVIDE_INTO_SUB_ROWS")}>
                        <BhIconButton icon={faArrowTurnDownRight} buttonProps={{ onClick: addSubRow }} />
                      </BhTooltip>
                      {!commentVisible && (
                        <BhTooltip body={t("FILE_COMMENT")}>
                          <BhIconButton icon={faMessage} buttonProps={{ onClick: () => setCommentVisible(true) }} />
                        </BhTooltip>
                      )}
                      <BhTooltip body={t("CHECKLIST_BASE.ADD_ATTACHMENT")}>
                        <BhAttachmentsUploadContainer
                          disabled={disabled}
                          saveCallback={saveRowAttachments}
                          button={<BhIconButton icon={faPaperclip} />}
                          property={"uploaded"}
                          fileUploadAllowed={true}
                          chooseFromProjectAllowed={true}
                          excludeFileTypes={[FileEntityType.ROOT_DIR, FileEntityType.ROOT_DOCUMENT_DIR, FileEntityType.DIR, FileEntityType.FORM]}
                          multiple={true}
                        />
                      </BhTooltip>
                      {(!roundRow.tasks || roundRow.tasks.length === 0) && roundRow.fieldValue === "NO" && extraRows?.length === 0 && (
                        <BhTooltip body={t("MODAL.ADD_TASK")}>
                          <BhIconButton icon={faSquareKanban} buttonProps={{ onClick: () => newTaskCallback && newTaskCallback(roundRow) }} />
                        </BhTooltip>
                      )}
                    </div>
                  )}
                  {checklistBaseFileId && roundRow.fieldValue === "YES" && (
                    <BhIconButton
                      icon={faLocationDot}
                      isActive={(roundRow.primaryAnnotationUuid && roundRow.primaryAnnotationUuid.length > 0) || false}
                      buttonProps={{
                        onClick: () => {
                          checklistBaseFileId && setMarkYesPointsModalOpen(true);
                        }
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          )}
          {commentVisible && (
            <div className={classNames("flex h-full w-full flex-row items-center", !baseVersionRow.attachments && !isExtraRow && "pb-2", (!roundRow.tasks || roundRow.tasks.length === 0) && "pt-1.5")}>
              <div className="w-full">
                <BhInputSimple initialValue={roundRow.description} property={"description"} saveCallback={saveRow} disabled={disabled} inputClasses={isExtraRow ? "font-bold" : ""} />
              </div>
              {!disabled && (
                <div className="bh-text-deep-ocean-80 flex flex-row">
                  {isCheckpointList && (
                    <BhTooltip body={t("CHECKLIST_BASE.ADD_ATTACHMENT")}>
                      <BhAttachmentsUploadContainer
                        disabled={disabled}
                        saveCallback={saveRowAttachments}
                        button={<BhIconButton icon={faPaperclip} />}
                        property={"uploaded"}
                        fileUploadAllowed={true}
                        chooseFromProjectAllowed={true}
                        excludeFileTypes={[FileEntityType.ROOT_DIR, FileEntityType.ROOT_DOCUMENT_DIR, FileEntityType.DIR, FileEntityType.FORM]}
                        multiple={true}
                      />
                    </BhTooltip>
                  )}
                  {isExtraRow && roundRow.fieldValue === "NO" && (!roundRow.tasks || roundRow.tasks.length === 0) && (
                    <BhTooltip body={t("MODAL.ADD_TASK")}>
                      <BhIconButton icon={faSquareKanban} buttonProps={{ onClick: () => newTaskCallback && newTaskCallback(roundRow) }} />
                    </BhTooltip>
                  )}
                  <BhTooltip body={isExtraRow ? t("CHECKLIST.DELETE_ROW") : t("CHECKLIST.DELETE_COMMENT")}>
                    <BhIconButton icon={faTrash} buttonProps={{ onClick: isExtraRow ? deleteSubRow : deleteRowDescription }} />
                  </BhTooltip>
                </div>
              )}
            </div>
          )}
          {roundRow.attachments && roundRow.attachments.length > 0 && (
            <div className={classNames("pt-1", (!roundRow.tasks || roundRow.tasks.length === 0) && "pb-2")}>
              <AttachmentsContainerLarge attachments={roundRow.attachments} addingDisabled={true} removeCallback={!disabled ? removeRowAttachment : undefined} />
            </div>
          )}
          {roundRow.tasks &&
            roundRow.tasks.length > 0 &&
            roundRow.tasks.map((task) => {
              return (
                <div className="flex flex-col" key={task.id}>
                  <div className="flex w-full flex-row items-center py-1">
                    <div className="w-full">
                      <ChecklistTaskContainer task={task} taskDisabled={disabled} />
                    </div>
                    {!disabled && task.deleted && (
                      <BhTooltip body={t("CHECKLIST.DELETE.TASK")}>
                        <BhIconButton icon={faTrash} buttonProps={{ onClick: () => deleteRowTask(task.id) }} />
                      </BhTooltip>
                    )}
                  </div>
                  {task.deleted && (
                    <div className="bh-text-deep-ocean-80 flex flex-row items-center gap-x-2">
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      {t("CHECKLIST.TASK.SAVED_TO_CHECK")}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        <div className="align-top">
          {baseVersionRow.checkpoint && !roundRow.fieldValue && (
            <CheckpointButtonsNoSelection callback={saveRow} isRoundEditable={!disabled} checklistLanguage={checklistLanguage} isCheckpointList={isCheckpointList} />
          )}
          {baseVersionRow.checkpoint && roundRow.fieldValue && (
            <CheckpointButtonSelected fieldValue={roundRow.fieldValue} callback={saveRow} isRoundEditable={!disabled} checklistLanguage={checklistLanguage} isCheckpointList={isCheckpointList} />
          )}
        </div>
      </div>
      {extraRows &&
        extraRows.length > 0 &&
        extraRows.map((row: any) => {
          return (
            <CheckpointRow
              baseVersionRow={baseVersionRow}
              key={row.id}
              isExtraRow={true}
              rowId={row.id}
              checklistBaseFileId={checklistBaseFileId}
              saveCallback={saveCallback}
              newTaskCallback={newTaskCallback}
              disabled={disabled}
              checklistId={checklistId}
            />
          );
        })}
      {markYesPointsModalOpen && checklistBaseFileId && (
        <PdftronMarkYesPointsContainer
          rowId={rowId}
          roundRow={roundRow}
          isReadOnly={disabled}
          fileEntityId={checklistBaseFileId}
          header={<h2>{roundRow.fieldTitle}</h2>}
          setMarkYesPointsModalOpen={setMarkYesPointsModalOpen}
        />
      )}
    </div>
  );
};

export default CheckpointRow;
