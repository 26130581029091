import React, { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { UserEmailSettingsType } from "@/model/profile/IUserEmailSettings";
import { IUserInfo } from "@/model/IUserInfo";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveCurrentUserInfoAsync, selectCurrentUserInfo } from "@/app/store/userSlice";
import { setUserProfileModalsType, toggleUserProfileModalsOpen } from "@/app/store/userPreferenceSlice";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";

interface Props {
  settingsHeader?: ReactElement;
  settingsBody: ReactElement;
  settingsProperty: keyof IUserInfo;
  settingsType?: UserEmailSettingsType;
}
const NotificationSettingsRow: FC<Props> = ({ settingsHeader, settingsBody, settingsProperty, settingsType }) => {
  const { t } = useTranslation();
  const userInfo = useAppSelector(selectCurrentUserInfo);
  const dispatch = useAppDispatch();

  const handleUpdateUserInfo = async (changedValue: IUserInfo) => {
    const changedCurrentUserInfo = { ...userInfo, ...changedValue };
    const property = Object.keys(changedValue)[0] as keyof IUserInfo;

    //TODO kas selle konsooli logimise võib siit nüüd välja visata?
    const resultAction = await dispatch(saveCurrentUserInfoAsync(changedCurrentUserInfo));
    if (saveCurrentUserInfoAsync.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const userInfo = resultAction.payload;
    } else {
      if (resultAction.payload) {
      } else {
      }
    }
  };

  const openModal = (modalType: UserEmailSettingsType) => {
    dispatch(toggleUserProfileModalsOpen({ modal: "userMailSettingsModal" }));
    dispatch(setUserProfileModalsType(modalType));
  };

  const showSettingsButton = settingsType && userInfo[settingsProperty];

  return (
    <>
      <div className="col-span-4 col-start-1">
        {settingsHeader && <h3>{settingsHeader}</h3>}
        <p>{settingsBody}</p>
      </div>
      <div className="col-span-1 col-start-6 place-self-center">
        <BhToggleSwitch value={userInfo[settingsProperty] as boolean} property={settingsProperty} onClickAction={handleUpdateUserInfo} />
      </div>
      {showSettingsButton && (
        <BhTextOnlyButton buttonProps={{ onClick: () => openModal(settingsType), classes: "min-w-0 xl:col-start-8 col-start-7 place-self-center ml-4" }}>{t("GLOBAL.SETTINGS")}</BhTextOnlyButton>
      )}
    </>
  );
};

export default NotificationSettingsRow;
