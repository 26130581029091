import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareFromSquare } from "@fortawesome/pro-regular-svg-icons/faShareFromSquare";
import { useAppSelector } from "@/app/hooks";
import { selectShareboxById } from "@/app/store/shareboxesSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatDateWithMonthName } from "@/utilities/dateUtility";

interface Props {
  shareboxId: EntityId;
}

const SearchShareboxCardContainer: FC<Props> = ({ shareboxId }) => {
  const { t } = useTranslation();
  const sharebox = useAppSelector((state) => selectShareboxById(state, shareboxId));
  const projectId = useAppSelector(selectCurrentProjectId);
  const navigate = useNavigate();

  if (!sharebox) return null;

  return (
    <div key={shareboxId} className="hover:bh-bg-mint-50 flex h-9 items-center justify-between rounded p-1.5 transition duration-300 ease-in-out ">
      <div className="flex items-center gap-4">
        <FontAwesomeIcon icon={faShareFromSquare} />
        <button
          onClick={() => {
            navigate(`/project/${projectId}/sharebox/${shareboxId}`);
          }}
          className="cursor-pointer hover:underline"
        >
          <span>{sharebox.name}</span>
        </button>
      </div>
      <p className="flex min-w-0 flex-wrap gap-1 truncate pl-2">
        {t("SHAREBOX.LIST.CREATED")} <span className="truncate font-bold">{formatDateWithMonthName(sharebox.created)}</span>
      </p>
    </div>
  );
};

export default SearchShareboxCardContainer;
