import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import {
  selectAllFilesSelectedForSharebox,
  selectShareboxById,
  selectShareboxFilesSort,
  selectShareboxFoldersByShareboxIdSorted,
  selectShareboxLoadingStatus,
  shareboxAllFilesSelectedToggled,
  shareboxAllFilesUnSelected
} from "@/app/store/shareboxesSlice";
import ShareboxFilesContainerListViewFolder from "@/views/home/project/detail/shareboxes/detail/shareboxFilesView/ShareboxFilesContainerListViewFolder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/pro-solid-svg-icons/faFolder";
import ShareboxFilesContainerTableHeader from "@/views/home/project/detail/shareboxes/detail/shareboxFilesView/ShareboxFilesContainerTableHeader";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import ShareboxFilesContainerSkeleton from "@/views/home/project/detail/shareboxes/detail/shareboxFilesView/ShareboxFilesContainerSkeleton";
import { IFileEntity } from "@/model/files/IFileEntity";
import { naturalSortFilesByField } from "@/utilities/sortUtilities";

interface Props {
  shareboxId: EntityId;
  shareboxUuid: string;
  publicSharebox: boolean;
}

const ShareboxFilesContainerListView: FC<Props> = ({ shareboxId, shareboxUuid, publicSharebox }) => {
  const folders = useAppSelector((state) => selectShareboxFoldersByShareboxIdSorted(state, shareboxId, 0));
  const sharebox = useAppSelector((state) => selectShareboxById(state, shareboxId));
  const allFilesSelected = useAppSelector((state) => selectAllFilesSelectedForSharebox(state, shareboxId, 0));
  const shareboxLoadingStatus = useAppSelector(selectShareboxLoadingStatus);
  const shareboxFilesSort = useAppSelector(selectShareboxFilesSort);
  const [sortedShareboxFiles, setSortedShareboxFiles] = useState<Array<IFileEntity>>(sharebox?.fileList || []);

  const attachmentFileIds = sortedShareboxFiles.map((file) => file.id);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(shareboxAllFilesUnSelected(shareboxId));
  }, []);

  useEffect(() => {
    sharebox && setSortedShareboxFiles(sortFilesInFolders() as Array<IFileEntity>);
  }, [shareboxFilesSort, sharebox]);

  const sortFilesInFolders = () => {
    return folders
      .map((folder) => sharebox?.fileList.filter((file) => file.parentFileEntityId === folder.id).sort((a, b) => naturalSortFilesByField(a, b, shareboxFilesSort.property, shareboxFilesSort.reversed)))
      .flat();
  };

  return (
    <div>
      <ShareboxFilesContainerTableHeader
        checked={publicSharebox ? allFilesSelected || false : undefined}
        handleChange={publicSharebox ? () => dispatch(shareboxAllFilesSelectedToggled(shareboxId)) : undefined}
      />
      {shareboxLoadingStatus !== BhStateStatusType.SUCCESS && !publicSharebox && (
        <div>
          <ShareboxFilesContainerSkeleton />
          <ShareboxFilesContainerSkeleton />
        </div>
      )}
      {(shareboxLoadingStatus === BhStateStatusType.SUCCESS || publicSharebox) && (
        <div>
          {folders.map((folder) => {
            return (
              <div key={folder.id}>
                <div className="text-13px l-h-20px bh-text-deep-ocean-60 border-b p-2 font-bold">
                  <FontAwesomeIcon icon={faFolder} className="pr-1" />
                  {folder.filePath}
                </div>
                <ShareboxFilesContainerListViewFolder shareboxId={shareboxId} shareboxUuid={shareboxUuid} folderId={folder.id} publicSharebox={publicSharebox} attachmentFileIds={attachmentFileIds} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ShareboxFilesContainerListView;
