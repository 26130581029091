import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import OverviewTabs from "@/views/home/project/detail/overview/OverviewTabs";
import MessageBoard from "@/views/home/project/detail/overview/MessageBoard";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";

const OverviewContainer: FC = () => {
  useDocumentTitle("PROJECT.SIDEBAR.DASHBOARD");
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSidebarCollapsed(false));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.OVERVIEW));
  }, []);

  return (
    <div className="flex h-full w-full flex-row overflow-hidden md:flex-col md:overflow-auto">
      <div className="flex-1 overflow-hidden md:overflow-visible">
        <OverviewTabs />
      </div>
      <div className="w-[288px] md:w-full">
        <MessageBoard projectId={projectId} />
      </div>
    </div>
  );
};

export default OverviewContainer;
