import React, { FC } from "react";
import { ChecklistBaseCategory } from "@/model/checklist/IChecklistBase";
import ChecklistBaseCategoryTag from "@components/checklists/category/ChecklistBaseCategoryTag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { IDropdownItem } from "@/model/IDropdownItem";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  currentCategory: ChecklistBaseCategory;
  saveCallback: Function;
  disabled?: boolean;
  fixedPosition?: boolean;
}

const ChecklistBaseCategoryTagInputDropdown: FC<Props> = ({ currentCategory, saveCallback, disabled, fixedPosition }) => {
  const categories: Array<IDropdownItem> = [ChecklistBaseCategory.SAFETY, ChecklistBaseCategory.QUALITY, ChecklistBaseCategory.HANDOVERS, ChecklistBaseCategory.OTHER]
    .filter((cat) => cat !== currentCategory)
    .map((cat) => {
      return {
        text: <ChecklistBaseCategoryTag category={cat} />,
        function: () => {
          saveCallback(cat);
        }
      };
    });

  return (
    <BhDropdown
      disabled={disabled}
      fixedPosition={fixedPosition}
      buttonClasses={"w-48"}
      button={
        <button className={classNames(disabled && "cursor-default", "min-w-70px bh-border-smoke-40 hover:bh-border-pigeon-70 flex h-9 w-full items-center justify-between rounded border p-2")}>
          <ChecklistBaseCategoryTag category={currentCategory} disabled={disabled} />
          {!disabled && <FontAwesomeIcon icon={faCaretDown} />}
        </button>
      }
      menu={
        <div className={classNames(fixedPosition && "absolute top-8 mt-1")}>
          <BhDropdownMenu values={categories} closeOnItemClick={true} type={BhDropdownTypeEnum.STRING} textProperty={"text"} widthClass={"w-48"} />
        </div>
      }
    />
  );
};

export default ChecklistBaseCategoryTagInputDropdown;
