import React, { FC } from "react";
import { ISignInvite } from "@/model/ISignInvite";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import BhStatusCellInLineOfSigning from "@components/status/BhStatusCellInLineOfSigning";
import BhStatusCellSigned from "@components/status/BhStatusCellSigned";
import BhStatusCellDenied from "@components/status/BhStatusCellDenied";
import BhStatusCellWaiting from "@components/status/BhStatusCellWaiting";
import BhStatusCellSignedNotValid from "@components/status/BhStatusCellSignedNotValid";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";

interface Props {
  signInvite?: ISignInvite;
  signatureHolder?: ISignatureHolder;
}

const BhSignatureCellStatusForUser: FC<Props> = ({ signInvite, signatureHolder }) => {
  const { t } = useTranslation();

  if (signInvite) {
    if (signInvite.signed) {
      //BhOwnSignatureStatusType.SIGNED;
      return (
        <BhTooltip body={t("SIGNATURE.SIGNED")}>
          <BhStatusCellSigned />
        </BhTooltip>
      );
    } else if (signInvite.declined) {
      //BhOwnSignatureStatusType.REFUSED;
      return (
        <BhTooltip body={t("SIGNATURE.DECLINED")}>
          <BhStatusCellDenied />
        </BhTooltip>
      );
    } else if (signInvite.orderNumber && !signInvite.nextInLine) {
      //BhOwnSignatureStatusType.IN_LINE;
      return <BhStatusCellInLineOfSigning />;
    } else {
      //BhOwnSignatureStatusType.PENDING;
      return <BhStatusCellWaiting />;
    }
  }
  if (signatureHolder) {
    if (!signatureHolder.isUnsignedInvite) {
      if (signatureHolder.isValid) {
        //BhOwnSignatureStatusType.SIGNED;
        return (
          <BhTooltip body={t("SIGNATURE.SIGNED")}>
            <BhStatusCellSigned />
          </BhTooltip>
        );
      } else {
        //BhOwnSignatureStatusType.SIGNED_NOT_VALID;
        return (
          <BhTooltip body={t("SIGNATURE.IS_NOT_VALID")}>
            <BhStatusCellSignedNotValid />
          </BhTooltip>
        );
      }
    } else if (signatureHolder.declined) {
      //BhOwnSignatureStatusType.REFUSED;
      return (
        <BhTooltip body={t("SIGNATURE.DECLINED")}>
          <BhStatusCellDenied />
        </BhTooltip>
      );
    } else if (signatureHolder.orderNumber && !signatureHolder.nextInLine) {
      //BhOwnSignatureStatusType.IN_LINE;
      return <BhStatusCellInLineOfSigning />;
    } else {
      //BhOwnSignatureStatusType.PENDING;
      return <BhStatusCellWaiting />;
    }
  }

  return null;
};

export default BhSignatureCellStatusForUser;
