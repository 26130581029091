import React, { FC, useState } from "react";
import { ChecklistRowType, IChecklistBaseVersionRow, PlusMinusCounterType } from "@/model/checklist/IChecklistBaseVersionRow";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  deleteChecklistRoundRowTaskAsync,
  selectChecklistRowByBaseRowId,
  selectIsLastPlusMinusRow,
  selectNextChecklistRowType,
  selectPreviousChecklistRowType
} from "@/app/store/checklist/checklistRowsSlice";
import PlusMinusFieldCounter from "@components/checklists/checklistBase/body/rowTypes/plusMinus/PlusMinusFieldCounter";
import { selectChecklistBaseFileId, selectChecklistLanguageForTranslationByRoundId, selectChecklistNameByChecklistId, selectChecklistTagsByChecklistId } from "@/app/store/checklist/checklistsSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import ChecklistRowPlusMinusFieldSumRow from "@components/checklists/checklist/body/rowTypes/plusMinus/ChecklistRowPlusMinusFieldSumRow";
import { ITaskTag } from "@/model/taskBoard/ITaskTag";
import { createNewTaskObject } from "@/model/taskBoard/ITask";
import { selectSafetyProjectTaskCategoryIfAvailable, setNewTask, setOpenedTaskInfo } from "@/app/store/tasksSlice";
import { toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { EntityId } from "@reduxjs/toolkit";
import { selectCurrentUser } from "@/app/store/userSlice";
import ChecklistTaskContainer from "@components/task/container/ChecklistTaskContainer";
import PlusMinusRowTotal from "@components/checklists/checklist/body/rowTypes/plusMinus/PlusMinusRowTotal";
import BhTooltip from "@components/BhTooltip";
import BhIconButton from "@components/buttons/BhIconButton";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons/faCircleExclamation";
import { useTranslation } from "react-i18next";
import { classNames } from "@/utilities/jsUtilities";
import { faSquareKanban } from "@fortawesome/pro-regular-svg-icons/faSquareKanban";

interface Props {
  checklistId: EntityId;
  checklistRoundId: EntityId;
  baseVersionRow: IChecklistBaseVersionRow;
  callback: Function;
  disabled: boolean;
}

const ChecklistRowPlusMinusField: FC<Props> = ({ checklistId, checklistRoundId, baseVersionRow, callback, disabled }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const roundRow = useAppSelector((state) => selectChecklistRowByBaseRowId(state, baseVersionRow.id));
  const fieldValues = roundRow && roundRow.plusMinusFieldValue;
  const checklistLanguage = useAppSelector((state) => roundRow && selectChecklistLanguageForTranslationByRoundId(state, roundRow.checklistRoundId));
  const isLastRow = useAppSelector((state) => roundRow && selectIsLastPlusMinusRow(state, roundRow.id));
  const tooltipExists = Boolean(fieldValues && fieldValues.tooltipAdded && fieldValues.tooltipText);
  const [isSaving, setIsSaving] = useState(false);
  const previousRowType = useAppSelector((state) => selectPreviousChecklistRowType(state, baseVersionRow.id));
  const nextRowType = useAppSelector((state) => selectNextChecklistRowType(state, baseVersionRow.id));
  const isPreviousRowPlusMinus = previousRowType && previousRowType === ChecklistRowType.PLUSMINUS;
  const isNextRowPlusMinus = nextRowType && nextRowType === ChecklistRowType.PLUSMINUS;

  const currentChecklistTags = useAppSelector((state) => selectChecklistTagsByChecklistId(state, checklistId));
  const currentUser = useAppSelector(selectCurrentUser);
  const taskCategory = useAppSelector(selectSafetyProjectTaskCategoryIfAvailable);
  const checklistBaseFileId = useAppSelector((state) => selectChecklistBaseFileId(state, checklistId, checklistRoundId));
  const sortedTasks = roundRow && roundRow.tasks && roundRow.tasks.length > 0 ? roundRow.tasks.slice().sort((a, b) => (a.id > b.id ? 1 : -1)) : [];
  const checklistName = useAppSelector((state) => selectChecklistNameByChecklistId(state, checklistId));

  if (!roundRow || !fieldValues) return null;

  const saveFieldValues = async (changedObject: any) => {
    setIsSaving(true);
    const changedFieldValues = { plusMinusFieldValue: { ...fieldValues, ...changedObject } };
    await callback({ ...roundRow, ...changedFieldValues });
    setIsSaving(false);
  };

  const incrementOkCounter = () => {
    return saveFieldValues({ okTotal: fieldValues.okTotal + 1 });
  };

  const decrementOkCounter = () => {
    if (fieldValues.okTotal - 1 < 0) {
      return;
    }
    return saveFieldValues({ okTotal: fieldValues.okTotal - 1 });
  };

  const incrementNotOkCounter = () => {
    if (fieldValues.autoCreateTask) {
      createNewTask();
    }
    return saveFieldValues({ notOkTotal: fieldValues.notOkTotal + 1 });
  };

  const decrementNotOkCounter = () => {
    if (fieldValues.notOkTotal - 1 < 0) {
      return;
    }
    return saveFieldValues({ notOkTotal: fieldValues.notOkTotal - 1 });
  };

  const createNewTask = () => {
    let titleText = roundRow.fieldTitle || "";
    const onePrefillExists = fieldValues.prefills && fieldValues.prefills.length === 1;
    const moreThanOnePrefillsExist = fieldValues.prefills && fieldValues.prefills.length > 1;
    if (onePrefillExists) {
      titleText = fieldValues.prefills[0].text;
    }
    if (moreThanOnePrefillsExist) {
      titleText = "";
    }
    const deadline = new Date();
    deadline.setDate(deadline.getDate() + 7);
    const tags = currentChecklistTags
      ? currentChecklistTags.map((tag) => {
          return { name: tag.name, projectId: tag.projectId } as ITaskTag;
        })
      : undefined;
    const newTask = createNewTaskObject({
      currentUser: currentUser,
      category: taskCategory,
      predefinedName: titleText,
      predefinedDescription: checklistName + " - " + roundRow.fieldTitle,
      checklistRowId: roundRow.id,
      deadline: deadline,
      checklistBaseFileId: checklistBaseFileId,
      tags: tags,
      nameDropdownValues: moreThanOnePrefillsExist ? fieldValues.prefills : []
    });
    dispatch(setNewTask(newTask));
    dispatch(setOpenedTaskInfo({ closeModalOnNewTaskSave: true }));
    dispatch(toggleProjectModalsOpen({ modal: "projectTaskModal" }));
  };

  const deleteRowTask = (taskId: EntityId) => {
    dispatch(deleteChecklistRoundRowTaskAsync({ checklistRoundRow: roundRow, taskId: taskId }));
  };

  return (
    <div className={classNames("flex w-full flex-col", !isPreviousRowPlusMinus && "mt-3", !isNextRowPlusMinus && "mb-3")}>
      <div className="bh-border-pigeon-20 flex w-full flex-row items-center gap-x-2 border-b">
        <div className="flex w-full flex-col py-2">
          <div className="flex flex-row items-center justify-between">
            <div className="text-18px font-bold leading-6">{roundRow.fieldTitle}</div>
            {!disabled && (
              <BhTooltip body={t("MODAL.ADD_TASK")}>
                <BhIconButton icon={faSquareKanban} buttonProps={{ onClick: createNewTask }} />
              </BhTooltip>
            )}
          </div>
          {tooltipExists && (
            <div className="text-14px flex flex-row gap-x-2 pt-3 pb-2 pl-1 leading-5">
              <div>
                <FontAwesomeIcon icon={faInfoCircle} />
              </div>
              <div>{fieldValues.tooltipText}</div>
            </div>
          )}
          {sortedTasks.length > 0 && (
            <div className="flex flex-col gap-y-1 py-3">
              {sortedTasks.map((task) => (
                <div className="flex w-full flex-row items-center" key={task.id}>
                  <div className="w-full">
                    <ChecklistTaskContainer task={task} taskDisabled={disabled} />
                  </div>
                  {!disabled && task.deleted && (
                    <BhTooltip body={t("CHECKLIST.DELETE.TASK")}>
                      <BhIconButton icon={faTrash} buttonProps={{ onClick: () => deleteRowTask(task.id) }} />
                    </BhTooltip>
                  )}
                </div>
              ))}
              {sortedTasks.some((task) => task.deleted) && (
                <div className="bh-text-deep-ocean-80 flex flex-row items-center gap-x-2 pl-1">
                  <FontAwesomeIcon icon={faCircleExclamation} />
                  {t("CHECKLIST.TASKS.SAVED_TO_CHECK")}
                </div>
              )}
            </div>
          )}
        </div>
        <PlusMinusFieldCounter
          total={fieldValues.okTotal}
          fieldType={PlusMinusCounterType.PLUS}
          labelLanguage={checklistLanguage}
          disabled={disabled || isSaving}
          onPlusClickCallback={incrementOkCounter}
          onMinusClickCallback={decrementOkCounter}
        />
        <PlusMinusFieldCounter
          total={fieldValues.notOkTotal}
          fieldType={PlusMinusCounterType.MINUS}
          labelLanguage={checklistLanguage}
          disabled={disabled || isSaving}
          onPlusClickCallback={incrementNotOkCounter}
          onMinusClickCallback={decrementNotOkCounter}
        />
        <PlusMinusRowTotal rowTotalOk={fieldValues.okTotal} rowTotalNotOk={fieldValues.notOkTotal} />
      </div>
      {isLastRow && <ChecklistRowPlusMinusFieldSumRow labelLanguage={checklistLanguage} />}
    </div>
  );
};

export default ChecklistRowPlusMinusField;
