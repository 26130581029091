import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { EntityId } from "@reduxjs/toolkit";
import { IWorkGroupToChecklistBase } from "@/model/checklist/IWorkGroupToChecklistBase";
import { IProjectChecklistBase } from "@/model/checklist/IProjectChecklistBase";
import { IBasesToProjectsDTO } from "@/model/checklist/IBasesToProjectsDTO";
import { IObjectChange } from "@/model/IObjectChange";

export function fetchWorkgroupToChecklistBasesForProject(projectId: EntityId): Promise<Array<IWorkGroupToChecklistBase>> {
  return bauhubGet("/project/" + projectId + "/checklist/base/authorities");
}

export function saveWorkGroupToChecklistBaseAuthority(projectId: EntityId, authority: IWorkGroupToChecklistBase): Promise<IWorkGroupToChecklistBase> {
  return bauhubPost("/project/" + projectId + "/checklist/base/authority", authority);
}

export function saveChecklistBaseToAllWorkGroups(projectId: EntityId, checklistBaseId: EntityId, removeBase: boolean): Promise<Array<IWorkGroupToChecklistBase>> {
  return bauhubPost("/project/" + projectId + "/checklist/" + checklistBaseId + "/base/authorities/all", {}, { removeBase: removeBase });
}

export function addAllProjectBasesToWorkGroup(projectId: EntityId, workGroupId: EntityId, removeBase: boolean): Promise<Array<IWorkGroupToChecklistBase>> {
  return bauhubPost("/project/" + projectId + "/checklist/workgroup/" + workGroupId + "/authorities/all", {}, { removeBase: removeBase });
}

export function fetchProjectChecklistBasesForCompany(companyId: EntityId): Promise<Array<IProjectChecklistBase>> {
  return bauhubGet("/company/" + companyId + "/project/checklist/bases");
}

export function fetchProjectChecklistBasesForCompanyProject(companyId: EntityId, projectId: EntityId): Promise<Array<IProjectChecklistBase>> {
  return bauhubGet("/company/" + companyId + "/project/" + projectId + "/checklist/bases");
}

export function addChecklistBasesToProjects(companyId: EntityId, dto: IBasesToProjectsDTO): Promise<Array<IProjectChecklistBase>> {
  return bauhubPost("/company/" + companyId + "/bases/add", dto);
}

export function removeChecklistBasesFromProjects(companyId: EntityId, dto: IBasesToProjectsDTO): Promise<Array<IProjectChecklistBase>> {
  return bauhubPost("/company/" + companyId + "/bases/remove", dto);
}

export function fetchChecklistLogsForProject(projectId: EntityId): Promise<Array<IObjectChange>> {
  return bauhubGet("/project/" + projectId + "/checklist/history");
}

export function restoreChecklistFromLogs(projectId: EntityId, checklistId: EntityId): Promise<Array<IObjectChange>> {
  return bauhubPost("/project/" + projectId + "/checklist/" + checklistId + "/restore");
}
