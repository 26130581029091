import React, { FC, useCallback, useState } from "react";
import { IFileEntity } from "@/model/files/IFileEntity";
import { fileEntitySelected } from "@/app/store/filesSlice";
import BhFileCardMedium from "@components/cards/BhFileCardMedium";
import { useAppDispatch } from "@/app/hooks";
import { Trans, useTranslation } from "react-i18next";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import SelectFilesModal from "@/views/home/project/projectModals/selectFilesModal/SelectFilesModal";
import { classNames } from "@/utilities/jsUtilities";
interface Props {
  files: Array<IFileEntity>;
  deleteCallback?: Function;
  fileAddingCallback?: Function;
}

const BhShareFileContainer: FC<Props> = ({ files, deleteCallback, fileAddingCallback }) => {
  const { t } = useTranslation();
  const [fileListOpen, setFileListOpen] = useState(false);
  const [selectFilesModalOpen, setSelectFilesModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  const attachmentFileIds = files.map((file) => file.id);

  const onDelete = useCallback(
    (selectedFile: IFileEntity) => {
      if (deleteCallback) {
        deleteCallback(selectedFile);
        return;
      }
      dispatch(fileEntitySelected({ ids: [selectedFile.id], selected: false }));
    },
    [files]
  );

  const firstFourFiles = files.slice(0, 4);
  const remainingFiles = files.slice(4, files.length);

  return (
    <div className="bh-border-pigeon-20 border-b">
      <div className="flex flex-row items-center py-2 px-20">
        <div className="flex w-full flex-row items-center justify-between">
          <div className="font-bold">
            <Trans>FILEPICKER.SELECTED_FILES</Trans>
          </div>
          {fileAddingCallback && (
            <div onClick={(e) => e.stopPropagation()}>
              <BhPrimaryButton buttonProps={{ onClick: () => setSelectFilesModalOpen(true), classes: "ml-6" }} icon={faPlus}>
                <Trans>GLOBAL.ADD_FILES</Trans>
              </BhPrimaryButton>
            </div>
          )}
        </div>
      </div>
      {files.length > 0 && (
        <div className={classNames(files.length > 3 && "bottom-inset-shadow", !fileListOpen && "max-h-[148px]", "relative overflow-hidden")}>
          <div className={classNames(fileListOpen && "pb-10", "bh-bg-smoke overflow flex flex-col px-16 pt-2")}>
            {firstFourFiles.map((selectedFileEntity) => (
              <div key={selectedFileEntity.id} className="p-1 pt-0">
                <BhFileCardMedium fileEntity={selectedFileEntity} deleteCallback={onDelete} openInNewTab={true} attachmentFileIds={attachmentFileIds} />
              </div>
            ))}
            {fileListOpen &&
              remainingFiles.map((selectedFileEntity) => (
                <div key={selectedFileEntity.id} className="p-1 pt-0">
                  <BhFileCardMedium fileEntity={selectedFileEntity} deleteCallback={onDelete} openInNewTab={true} attachmentFileIds={attachmentFileIds} />
                </div>
              ))}
          </div>
          {files.length > 3 && (
            <div className="absolute bottom-2 flex w-full flex-row items-center justify-center">
              <BhPrimaryButton buttonProps={{ classes: "rounded-xl h-6 !py-0 px-6", onClick: () => setFileListOpen(!fileListOpen) }}>
                {fileListOpen ? t("GLOBAL.HIDE") : `+ ${remainingFiles.length + 1} ${remainingFiles.length + 1 > 1 ? t("GLOBAL.FILE_PLURAL") : t("GLOBAL.FILE")}`}
              </BhPrimaryButton>
            </div>
          )}
        </div>
      )}
      {selectFilesModalOpen && fileAddingCallback && <SelectFilesModal onSelect={(files) => fileAddingCallback(files)} setModalOpen={setSelectFilesModalOpen} multiple={true} />}
    </div>
  );
};

export default BhShareFileContainer;
