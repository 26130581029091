import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import BhDropdown from "@components/dropdown/BhDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListTree } from "@fortawesome/pro-regular-svg-icons/faListTree";
import BhDropdownButton from "@components/dropdown/BhDropdownButton";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhIconButton from "@components/buttons/BhIconButton";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { Viewer } from "@xeokit/xeokit-sdk";
import { LoadedObjectInfo } from "@/views/home/project/detail/xeokit/XeokitWebViewer";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import XeokitSidebarMenuTypesTreeView from "@/views/home/project/detail/xeokit/xeokitSidebar/XeokitSidebarMenuTypesTreeView";
import XeokitSidebarMenuObjectsTreeView from "@/views/home/project/detail/xeokit/xeokitSidebar/XeokitSidebarMenuObjectsTreeView";
import { useTranslation } from "react-i18next";
import XeokitSidebarMenuModelsTreeView from "@/views/home/project/detail/xeokit/xeokitSidebar/XeokitSidebarMenuModelsTreeView";
import XeokitSidebarMenuStoreysTreeView from "@/views/home/project/detail/xeokit/xeokitSidebar/XeokitSidebarMenuStoreysTreeView";
import { naturalSortString } from "@/utilities/sortUtilities";
import XeokitSidebarMenuObjectInfo from "@/views/home/project/detail/xeokit/xeokitSidebar/XeokitSidebarMenuObjectInfo";

export interface IBimFavouriteProperty {
  propertySet: string;
  propertyName: string;
}

interface Props {
  viewer: Viewer;
  treeVisible: boolean;
  setTreeVisible: Function;
  propertiesVisible: boolean;
  setPropertiesVisible: Function;
  loadedModels: Array<LoadedObjectInfo>;
  toggleModelVisible: Function;
  toggleAllModelsVisible: Function;
  loadedStoreyElevations: Array<LoadedObjectInfo>;
  setLoadedStoreyElevations: Function;
}

const XeokitSidebarMenu: FC<Props> = ({
  viewer,
  treeVisible,
  setTreeVisible,
  propertiesVisible,
  setPropertiesVisible,
  loadedModels,
  toggleModelVisible,
  toggleAllModelsVisible,
  loadedStoreyElevations,
  setLoadedStoreyElevations
}) => {
  const { t } = useTranslation();
  const [bimSideBarTab, setBimSideBarTab] = useLocalStorage<number>("bimSideBarTab", 0);
  const loadedModelsSorted = loadedModels.sort((a, b) => naturalSortString(a.name, b.name));
  const tabs = [
    { text: t("BIM.MODELS"), id: 0 },
    { text: t("BIM.STOREYS_HEIGHT"), id: 1 },
    { text: t("BIM.CLASSES"), id: 2 },
    { text: t("BIM.OBJECTS"), id: 3 }
  ];

  const dropdownValues = tabs.map((tab) => {
    return { text: tab.text, function: () => setBimSideBarTab(tab.id) };
  });

  return (
    <div className={classNames(treeVisible || propertiesVisible ? "flex" : "hidden", "w-80 flex-col overflow-hidden")}>
      <div className={treeVisible ? "flex flex-1 flex-col overflow-hidden border-b" : "hidden"}>
        <div className="flex flex-row items-center justify-between p-1">
          <BhDropdown
            button={
              <div className="flex cursor-pointer flex-row items-center p-2 font-bold">
                <div className="pr-2">
                  <FontAwesomeIcon icon={faListTree} />
                </div>
                <BhDropdownButton title={""} value={tabs[bimSideBarTab].text} reversed={true} />
              </div>
            }
            menu={<BhDropdownMenu textProperty="text" type={BhDropdownTypeEnum.STRING} values={dropdownValues} closeOnItemClick={true} />}
          />
          <BhIconButton
            icon={faTimes}
            buttonProps={{
              onClick: () => {
                setTreeVisible(false);
              }
            }}
          />
        </div>
        <div className="flex-1 overflow-auto p-2">
          {bimSideBarTab === 0 && <div className="block">
            <XeokitSidebarMenuModelsTreeView loadedModels={loadedModelsSorted} toggleModelVisible={toggleModelVisible} toggleAllModelsVisible={toggleAllModelsVisible} />
          </div>}
          {bimSideBarTab === 1 && <div className="block">
            <XeokitSidebarMenuStoreysTreeView viewer={viewer} loadedStoreyElevations={loadedStoreyElevations} setLoadedStoreyElevations={setLoadedStoreyElevations} />
          </div>}
          {bimSideBarTab === 2 && <div className="block">
            <XeokitSidebarMenuTypesTreeView viewer={viewer} loadedModels={loadedModelsSorted} />
          </div>}
          {bimSideBarTab === 3 && <div className="block">
            <XeokitSidebarMenuObjectsTreeView viewer={viewer} loadedModels={loadedModelsSorted} toggleModelVisible={toggleModelVisible} toggleAllModelsVisible={toggleAllModelsVisible} />
          </div>}
        </div>
      </div>
      <XeokitSidebarMenuObjectInfo viewer={viewer} setPropertiesVisible={setPropertiesVisible} propertiesVisible={propertiesVisible} />
    </div>
  );
};

export default XeokitSidebarMenu;
