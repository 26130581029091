import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";

interface Props {
  onClick: Function;
  isActive: boolean;
  icon?: IconDefinition;
  label: string;
}

const ChecklistsAuthoritiesModalSidebarItem: FC<Props> = ({ onClick, icon, isActive, label }) => {
  return (
    <div
      onClick={() => onClick()}
      className={classNames("hover:bh-bg-mint bh-text-deep-ocean my-1 flex cursor-pointer flex-row items-center justify-start rounded px-2 py-1.5", isActive && "bh-bg-mint pointer-events-none")}
    >
      {icon && <FontAwesomeIcon icon={icon} className="mr-1" />}
      <div className="overflow-hidden text-ellipsis whitespace-nowrap">{label}</div>
    </div>
  );
};

export default ChecklistsAuthoritiesModalSidebarItem;
