import React, { FC } from "react";
import FormWeatherFieldRowInput from "@components/form/fields/weatherField/FormWeatherFieldRowInput";
import { IFormDataSaveRequest } from "@/model/IForm";
import BhIconButton from "@components/buttons/BhIconButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";

interface Props {
  row: any;
  saveCallback?: Function;
  fieldProperty?: string;
  removeRowCallback?: Function;
  disabled?: boolean;
  columns?: any;
}

const FormWeatherFieldRow: FC<Props> = ({ row, saveCallback, disabled, removeRowCallback, fieldProperty, columns }) => {
  const rowsToMap = columns.map((column: any) => {
    const value = row[column.property] || (column.type === "TEXT" ? "" : false);
    return { property: column.property, value: value, type: column.type };
  });

  const saveRow = (changedValue: any, property: string) => {
    const saveRequest = {
      path: fieldProperty,
      changedProperty: property,
      changedValue: changedValue[property],
      changedObjectId: row._id
    } as IFormDataSaveRequest;
    saveCallback && saveCallback(saveRequest);
  };

  return (
    <div className="flex w-full items-start gap-2">
      {rowsToMap.map((row: any, rowIndex: number) => (
        <FormWeatherFieldRowInput value={row.value} property={row.property} key={rowIndex} saveCallback={saveRow} disabled={disabled} type={row.type} />
      ))}
      {removeRowCallback && <BhIconButton icon={faXmark} buttonProps={{ onClick: () => removeRowCallback(row._id), disabled: disabled }} />}
    </div>
  );
};

export default FormWeatherFieldRow;
