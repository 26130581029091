import React, { FC, useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { EntityId } from "@reduxjs/toolkit";
import { fetchShareboxAsync, fetchShareboxUrlsAsync, saveShareboxUrlsAsync, selectShareboxById, setShareboxShareModalOpen } from "@/app/store/shareboxesSlice";
import BhModal from "@components/modal/BhModal";
import { IShareboxUrl } from "@/model/shareboxes/IShareboxUrl";
import { fetchProjectUserEmailsForSharebox } from "@/api/projectAPI";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { Trans, useTranslation } from "react-i18next";
import BhInputLabel from "@components/input/BhInputLabel";
import BhInlineInput from "@components/input/BhInlineInput";
import BhChildComponentWithFetchValues from "@components/input/BhChildComponentWithFetchValues";
import BhTagListContainer from "@components/tags/BhTagListContainer";
import BhChipWithEmail from "@/views/home/project/detail/directory/directoryShareModals/BhChipWithEmail";
import BhSearchInputWithStringDropdown from "@components/input/BhSearchInputWithStringDropdown";
import { getUniqueValues } from "@/utilities/jsUtilities";
import { store } from "@/app/store";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";

interface Props {
  shareboxId: EntityId;
}

const ShareboxShareModal: FC<Props> = ({ shareboxId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sharebox = useAppSelector((state) => selectShareboxById(state, shareboxId));
  const currentProjectId = useAppSelector(selectCurrentProjectId);
  const [emailsToSend, setEmailsToSend] = useState<Array<string>>([]);
  const [message, setMessage] = useState<string>();

  const closeModal = useCallback(() => {
    dispatch(setShareboxShareModalOpen(false));
  }, []);

  const removeEmail = (removedEmail: string) => {
    setEmailsToSend((prev) => prev.filter((prevEmail) => prevEmail !== removedEmail));
  };

  const selectEmail = (email: string) => {
    if (sharebox?.receiverEmails.includes(email)) {
      store.dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          translateCode: "SHAREBOX.ALREADY_SENT"
        })
      );
      return;
    }
    setEmailsToSend((prev) => getUniqueValues([...prev, email]));
  };

  const saveShareboxReceivers = () => {
    const shareboxUrls: Array<IShareboxUrl> = emailsToSend.map((email) => {
      return { shareboxId: shareboxId, email: email, visited: false, created: new Date(), message: message || "", id: undefined };
    });
    dispatch(saveShareboxUrlsAsync({ shareboxId: shareboxId, shareboxUrls: shareboxUrls })).then(() => {
      dispatch(fetchShareboxAsync(shareboxId));
      dispatch(fetchShareboxUrlsAsync(shareboxId));
    });
    closeModal();
  };

  const shareboxUrlsCallback = (result: any) => {
    setMessage(result.message);
  };

  if (!sharebox) {
    return null;
  }

  return (
    <BhModal
      isShown={true}
      setIsShown={() => {}}
      onClose={closeModal}
      header={
        <div className="flex flex-row items-center">
          <h2>{t("SHAREBOX.SHARE_BY_EMAIL")}</h2>
        </div>
      }
      footer={
        <div className="flex w-full flex-1 justify-end">
          <BhTextOnlyButton buttonProps={{ onClick: closeModal }}>{t("GLOBAL.CANCEL")}</BhTextOnlyButton>
          <BhPrimaryButton buttonProps={{ onClick: () => saveShareboxReceivers(), disabled: emailsToSend?.length < 1 }}>{t("GLOBAL.SEND")}</BhPrimaryButton>
        </div>
      }
    >
      <div className="flex flex-col p-8">
        <BhInputLabel>
          <Trans>SHARE.HEADER.EMAIL</Trans>
        </BhInputLabel>
        <BhChildComponentWithFetchValues
          fetchValues={() => fetchProjectUserEmailsForSharebox(currentProjectId, shareboxId)}
          child={
            <BhSearchInputWithStringDropdown values={[]} onSelect={selectEmail} showValueCondition={(value: string) => !emailsToSend.includes(value)} placeholder={t("SHARE.HEADER.EMAIL") as string} />
          }
        />
        <div className="py-4">
          <BhTagListContainer>
            {emailsToSend.map((email) => (
              <BhChipWithEmail email={email} key={email} onRemove={removeEmail} />
            ))}
          </BhTagListContainer>
        </div>
        <div>
          <BhInputLabel>{t("SHAREBOX.EDIT_MESSAGE")}</BhInputLabel>
          <BhInlineInput placeholder={t("SHAREBOX.EDIT_MESSAGE") as string} saveCallback={shareboxUrlsCallback} initialValue={message} property="message" inputClasses="border bh-border-pigeon-40" />
        </div>
      </div>
    </BhModal>
  );
};

export default ShareboxShareModal;
