import React, { FC, useCallback, useState } from "react";
import { PropertySet } from "@xeokit/xeokit-sdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons/faAngleRight";
import { IBimFavouriteProperty } from "@/views/home/project/detail/xeokit/xeokitSidebar/XeokitSidebarMenu";
import { EntityId } from "@reduxjs/toolkit";
import XeokitSidebarMenuPropertySetProperty from "@/views/home/project/detail/xeokit/xeokitSidebar/XeokitSidebarMenuPropertySetProperty";
import { naturalSortString } from "@/utilities/sortUtilities";

interface Props {
  propertySet: PropertySet;
  toggleFavourite: Function;
  favouriteProperties: Array<IBimFavouriteProperty>;
  projectId: EntityId;
}

const XeokitSidebarMenuPropertySet: FC<Props> = ({ propertySet, toggleFavourite, favouriteProperties }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const isPropertyFavourite = useCallback(
    (propertyName: string) => {
      return favouriteProperties && favouriteProperties.some((prop) => prop.propertyName === propertyName);
    },
    [favouriteProperties]
  );


  const setPropertiesSorted = propertySet.properties.filter(p => p.name).sort((a, b) => naturalSortString(a.name, b.name));
  const currentSetFavouriteProperties = setPropertiesSorted.filter((prop) => isPropertyFavourite(prop.name));
  const currentSetAllOtherProperties = setPropertiesSorted.filter((prop) => !isPropertyFavourite(prop.name));

  return (
    <div key={propertySet.id} className="pb-2">
      <div className="flex cursor-pointer flex-row items-center gap-x-2" onClick={toggleOpen}>
        <div className="flex w-3 justify-center">
          <FontAwesomeIcon icon={open ? faAngleDown : faAngleRight} />
        </div>
        <h3>{propertySet.name}</h3>
      </div>
      {currentSetFavouriteProperties.map((property) => {
        return (
          <XeokitSidebarMenuPropertySetProperty
            key={property.name}
            propertySetName={propertySet.name}
            property={property}
            toggleFavourite={toggleFavourite}
            isFavourite={isPropertyFavourite(property.name)}
          />
        );
      })}
      {open &&
        currentSetAllOtherProperties.map((property) => {
          return (
            <XeokitSidebarMenuPropertySetProperty
              key={property.name}
              propertySetName={propertySet.name}
              property={property}
              toggleFavourite={toggleFavourite}
              isFavourite={isPropertyFavourite(property.name)}
            />
          );
        })}
    </div>
  );
};
export default XeokitSidebarMenuPropertySet;
