import React, { FC, useCallback } from "react";
import { useAppDispatch } from "@/app/hooks";
import { IPartyPrivilegeListItemDTO } from "@/model/party/IPartyPrivilegeListItemDTO";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { saveAddUserToTaskBoardAsync, saveRemoveUserFromTaskBoardAsync } from "@/app/store/project/projectWorkGroupsSlice";
import { ITaskBoard } from "@/model/taskBoard/ITaskBoard";
import { IWorkGroup } from "@/model/IWorkGroup";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhDropdownButton from "@components/dropdown/BhDropdownButton";

interface Props {
  workGroup: IWorkGroup;
  listItem: IPartyPrivilegeListItemDTO;
}

const PartyUserListItemTaskBoardDropdown: FC<Props> = ({ workGroup, listItem }) => {
  const dispatch = useAppDispatch();

  const isTaskBoardSelected = useCallback(
    (taskBoard: ITaskBoard) => {
      return listItem.taskBoardIds.some((tbId) => tbId === taskBoard.id);
    },
    [listItem]
  );

  const onSelectTaskBoardCheckbox = useCallback(
    (taskBoard: ITaskBoard) => {
      const dto = {
        userEntityId: listItem.userEntityId,
        inviteId: listItem.inviteId,
        taskBoardId: taskBoard.id,
        workGroupId: workGroup.id
      };
      if (!isTaskBoardSelected(taskBoard)) {
        dispatch(saveAddUserToTaskBoardAsync(dto));
      } else {
        dispatch(saveRemoveUserFromTaskBoardAsync(dto));
      }
    },
    [listItem]
  );

  if (!workGroup) {
    return null;
  }

  return (
    <div>
      <BhDropdown
        button={<BhDropdownButton reversed={true} title="" value={`${listItem.taskBoardIds.length}/${workGroup.taskBoards.length}`} />}
        menu={
          <BhDropdownMenu
            values={workGroup.taskBoards}
            onSelect={onSelectTaskBoardCheckbox}
            isSelected={isTaskBoardSelected}
            type={BhDropdownTypeEnum.STRING}
            textProperty="name"
            withCheckbox={true}
          />
        }
        position={BhDropdownPositionEnum.BOTTOM_LEFT}
      />
    </div>
  );
};

export default PartyUserListItemTaskBoardDropdown;
