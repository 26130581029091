import React, { FC } from "react";
import { IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectSortedChecklistRowsByBaseRowId } from "@/app/store/checklist/checklistRowsSlice";
import CheckpointRow from "@components/checklists/checklist/body/rowTypes/checkpoint/CheckpointRow";
import { IChecklistRoundRow } from "@/model/checklist/IChecklistRoundRow";
import { createNewTaskObject } from "@/model/taskBoard/ITask";
import { selectDefaultProjectTaskCategory, setNewTask, setOpenedTaskInfo } from "@/app/store/tasksSlice";
import { toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { selectCurrentUser } from "@/app/store/userSlice";
import { EntityId } from "@reduxjs/toolkit";
import { selectChecklistBaseFileId, selectChecklistTagsByChecklistId } from "@/app/store/checklist/checklistsSlice";
import { setRemovedAnnotationIdsForFileAnnotationId } from "@/app/store/fileAnnotationsSlice";
import { ITaskTag } from "@/model/taskBoard/ITaskTag";

interface Props {
  checklistId: EntityId;
  checklistRoundId: EntityId;
  baseVersionRow: IChecklistBaseVersionRow;
  callback: Function;
  disabled: boolean;
}

const ChecklistRowCheckpoint: FC<Props> = ({ checklistId, checklistRoundId, baseVersionRow, callback, disabled }) => {
  const dispatch = useAppDispatch();
  const roundRows = useAppSelector((state) => selectSortedChecklistRowsByBaseRowId(state, baseVersionRow.id, false));
  const extraRows = useAppSelector((state) => selectSortedChecklistRowsByBaseRowId(state, baseVersionRow.id, true));
  const checklistBaseFileId = useAppSelector((state) => selectChecklistBaseFileId(state, checklistId, checklistRoundId));
  const currentUser = useAppSelector(selectCurrentUser);
  const defaultProjectTaskCategory = useAppSelector(selectDefaultProjectTaskCategory);
  const currentChecklistTags = useAppSelector((state) => selectChecklistTagsByChecklistId(state, checklistId));

  const saveChecklistRow = (checklistRow: IChecklistRoundRow, fieldValueChanged?: boolean) => {
    let checklistRowObject = checklistRow;
    // If row has some Yes marked annotations and changes to NO or N/A
    if (fieldValueChanged && checklistRowObject.primaryAnnotationUuid && (checklistRowObject.fieldValue === undefined || checklistRowObject.fieldValue === null)) {
      checklistBaseFileId &&
        checklistRowObject.primaryAnnotationUuid &&
        dispatch(setRemovedAnnotationIdsForFileAnnotationId({ fileEntityId: checklistBaseFileId, annotationIds: [checklistRowObject.primaryAnnotationUuid], type: "Row" }));
      checklistRowObject.primaryAnnotationUuid = undefined;
    }

    callback(checklistRowObject);
    if (fieldValueChanged) {
      // If main row is changed to N/A, also change all extra rows to N/A
      const isMainRowChangedToNA = !checklistRowObject.extraRow && checklistRowObject.fieldValue === "N/A" && extraRows && extraRows.length > 0;
      if (isMainRowChangedToNA) {
        extraRows.forEach((row) => {
          const extraRowToSave = { ...row, fieldValue: "" };
          callback(extraRowToSave);
        });
      }
      // If extra row is changed, check if all extra rows are "YES" and change main row accordingly (if at least one extra row is "NO", main row is also "NO")
      const isExtraRowValueChanged = checklistRowObject.extraRow && extraRows && extraRows.length > 0;
      if (isExtraRowValueChanged) {
        let extraRowsArray = extraRows.filter((r) => r.id !== checklistRowObject.id);
        extraRowsArray.push(checklistRowObject);
        const anyNoOrBlankValueExisting = extraRowsArray.some((r) => r.fieldValue === "NO" || r.fieldValue === "" || !r.fieldValue);
        const allValuesAreYes = extraRowsArray.every((r) => r.fieldValue === "YES");
        const mainRow = roundRows && roundRows.length > 0 && roundRows[0];
        if (mainRow) {
          const mainRowFieldValue = allValuesAreYes ? "YES" : anyNoOrBlankValueExisting ? "NO" : "";
          const mainRowToSave = { ...mainRow, fieldValue: mainRowFieldValue };
          callback(mainRowToSave);
        }
      }
      // Open new task modal if answer was "NO", row does not have task already and row is eligible for new task
      createNewTaskIfEligibleRow(checklistRowObject);
    }
  };

  const createNewTaskIfEligibleRow = (checklistRow: IChecklistRoundRow) => {
    if (checklistRow.fieldValue === "NO" && (!checklistRow.tasks || checklistRow.tasks.length === 0) && !checklistRow.deleted) {
      const isExtraRow = checklistRow.extraRow;
      const isMainRowWithoutExtraRows = !checklistRow.extraRow && roundRows.length === 1 && extraRows.length === 0;
      if (isExtraRow || isMainRowWithoutExtraRows) {
        const titleText = checklistRow.fieldTitle ? "" + checklistRow.fieldTitle : "";
        const deadline = new Date();
        deadline.setDate(deadline.getDate() + 7);
        const tags = currentChecklistTags
          ? currentChecklistTags.map((tag) => {
              return { name: tag.name, projectId: tag.projectId } as ITaskTag;
            })
          : undefined;
        const newTask = createNewTaskObject({
          currentUser: currentUser,
          category: defaultProjectTaskCategory,
          predefinedName: checklistRow.description,
          predefinedDescription: (baseVersionRow.number ? " " + baseVersionRow.number : "") + " " + titleText,
          checklistRowId: checklistRow.id,
          deadline: deadline,
          checklistBaseFileId: checklistBaseFileId,
          tags: tags
        });
        dispatch(setNewTask(newTask));
        dispatch(setOpenedTaskInfo({ closeModalOnNewTaskSave: true }));
        dispatch(toggleProjectModalsOpen({ modal: "projectTaskModal" }));
      }
    }
  };

  return (
    <div>
      {roundRows &&
        roundRows.length > 0 &&
        roundRows.map((row) => {
          return (
            <CheckpointRow
              baseVersionRow={baseVersionRow}
              rowId={row.id}
              key={row.id}
              extraRows={extraRows}
              checklistBaseFileId={checklistBaseFileId}
              saveCallback={saveChecklistRow}
              newTaskCallback={createNewTaskIfEligibleRow}
              disabled={disabled}
              checklistId={checklistId}
            />
          );
        })}
    </div>
  );
};

export default ChecklistRowCheckpoint;
