import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  children?: React.ReactNode;
  classes?: string;
  trRef?: any;
}

const BhSpreadsheetTableTR: FC<Props> = ({ children, classes, trRef }) => {
  return (
    <tr className={classNames(classes, "hover:bh-bg-smoke-50 group relative h-8")} ref={trRef}>
      {children}
    </tr>
  );
};

export default BhSpreadsheetTableTR;
