import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { ISharebox } from "@/model/shareboxes/ISharebox";
import { deleteSharebox, fetchProjectShareboxes, fetchSharebox, fetchShareboxUrls, fetchUserShareboxes, saveSharebox, saveShareboxUrls, saveShareboxValidTo } from "@/api/shareboxAPI";
import { RootState } from "@/app/store";
import { selectCurrentUserUsername } from "@/app/store/userSlice";
import { IShareboxesFilter } from "@/model/shareboxes/IShareboxesFilter";
import { intersection, removeDuplicates } from "@/utilities/jsUtilities";
import { IShareboxUrl } from "@/model/shareboxes/IShareboxUrl";
import { initialShareboxesSort, IShareboxesSort } from "@/model/shareboxes/IShareboxesSort";
import { naturalSortByField, naturalSortFilesByField } from "@/utilities/sortUtilities";
import { IUser } from "@/model/IUser";
import { initialShareboxFilesSort, IShareboxFilesSort } from "@/model/shareboxes/IShareboxFilesSort";
import { findParentFolders } from "@/utilities/fileEntity/fileEntityUtilities";
import { findAllChildrenForFileEntities } from "@/utilities/fileEntityUtilities";
import { filesUpdatedAndAdded } from "@/app/store/filesSlice";

const shareboxesAdapter = createEntityAdapter<ISharebox>();

const shareboxesInitialState = shareboxesAdapter.getInitialState<{
  status: BhStateStatusType;
  shareboxListStatus: BhStateStatusType;
  shareboxLoadingStatus: BhStateStatusType;
  filter: IShareboxesFilter;
  sort: IShareboxesSort;
  filesSort: IShareboxFilesSort;
  shareboxUrls: Array<IShareboxUrl>;
  shareModalOpen: boolean;
  selectedShareboxUrlId: EntityId | undefined;
}>({
  status: BhStateStatusType.INITIAL,
  shareboxListStatus: BhStateStatusType.INITIAL,
  shareboxLoadingStatus: BhStateStatusType.INITIAL,
  filter: { searchKey: "", shareboxCreators: { creatorsSearchKey: "", creatorsDropdown: [] }, shareboxReceivers: { receiversSearchKey: "", receiversDropdown: [] }, displayExpired: false },
  sort: initialShareboxesSort,
  filesSort: initialShareboxFilesSort,
  shareboxUrls: [],
  shareModalOpen: false,
  selectedShareboxUrlId: undefined
});

export const fetchShareboxAsync = createAsyncThunk("sharebox/fetchSharebox", async (shareboxId: EntityId, { dispatch }) => {
  const fetchResult = await fetchSharebox(shareboxId);
  dispatch(filesUpdatedAndAdded(fetchResult.fileList));
  return fetchResult;
});

export const fetchUserCreatedShareboxesAsync = createAsyncThunk("sharebox/fetchShareboxes", async (projectId: EntityId) => {
  return fetchUserShareboxes(projectId);
});

export const fetchProjectShareboxesAsync = createAsyncThunk("sharebox/fetchProjectShareboxes", async (projectId: EntityId) => {
  return fetchProjectShareboxes(projectId);
});

export const deleteShareboxAsync = createAsyncThunk("sharebox/deleteSharebox", async (shareboxId: EntityId) => {
  return deleteSharebox(shareboxId);
});

export const fetchShareboxUrlsAsync = createAsyncThunk("sharebox/fetchUrlsForSharebox", async (shareboxId: EntityId) => {
  return fetchShareboxUrls(shareboxId);
});

export const saveShareboxAsync = createAsyncThunk("sharebox/saveSharebox", async (sharebox: ISharebox) => {
  return saveSharebox(sharebox);
});

export const saveShareboxUrlsAsync = createAsyncThunk("sharebox/saveShareboxUrls", async ({ shareboxId, shareboxUrls }: { shareboxId: EntityId; shareboxUrls: Array<IShareboxUrl> }) => {
  return saveShareboxUrls(shareboxId, shareboxUrls);
});

export const saveShareboxValidToAsync = createAsyncThunk("sharebox/saveShareboxValidTo", async ({ shareboxId, validTo }: { shareboxId: EntityId; validTo?: Date }) => {
  const request = { shareboxId: shareboxId, validTo: validTo };
  return saveShareboxValidTo(request);
});

const shareboxesSlice = createSlice({
  name: "shareboxes",
  initialState: shareboxesInitialState,
  reducers: {
    shareboxesAdded: shareboxesAdapter.upsertMany,
    clearShareboxReceiversFilter: (state) => {
      state.filter.shareboxReceivers.receiversDropdown = [];
      state.filter.shareboxReceivers.receiversSearchKey = "";
    },
    shareboxesUpdatedAndAdded: (state, action: PayloadAction<Array<ISharebox>>) => {
      const newAndUpdatedShareboxes = action.payload.map((sharebox) => {
        const alreadySavedSharebox = state.entities[sharebox.id];
        return alreadySavedSharebox ? alreadySavedSharebox : sharebox;
      });
      shareboxesAdapter.upsertMany(state, newAndUpdatedShareboxes);
    },
    clearShareboxCreatorsFilter: (state) => {
      state.filter.shareboxCreators.creatorsDropdown = [];
      state.filter.shareboxCreators.creatorsSearchKey = "";
    },
    receiverToggledInShareboxFilter: (state, action: PayloadAction<string>) => {
      const selectedReceivers = state.filter.shareboxReceivers.receiversDropdown;
      const index = selectedReceivers.findIndex((email) => email.toLowerCase() === action.payload.toLowerCase());
      index === -1 ? state.filter.shareboxReceivers.receiversDropdown.push(action.payload) : state.filter.shareboxReceivers.receiversDropdown.splice(index, 1);
    },
    creatorToggledInShareboxFilter: (state, action: PayloadAction<IUser>) => {
      const selectedCreators = state.filter.shareboxCreators.creatorsDropdown;
      const index = selectedCreators.findIndex((creator) => creator.id === action.payload.id);
      index === -1 ? state.filter.shareboxCreators.creatorsDropdown.push(action.payload) : state.filter.shareboxCreators.creatorsDropdown.splice(index, 1);
    },
    // Needed for setting the sharebox receivers filter search key
    setShareboxReceiversFilter: (state, action: PayloadAction<string>) => {
      state.filter.shareboxReceivers.receiversSearchKey = action.payload;
    },
    setShareboxCreatorsFilter: (state, action: PayloadAction<string>) => {
      state.filter.shareboxCreators.creatorsSearchKey = action.payload;
    },
    // Needed for setting sharebox filter search key
    setShareboxFilter: (state, action: PayloadAction<IShareboxesFilter>) => {
      state.filter = action.payload;
    },
    shareboxesSortSet: (state, action: PayloadAction<IShareboxesSort>) => {
      state.sort = action.payload;
    },
    shareboxFilesSortSet: (state, action: PayloadAction<IShareboxFilesSort>) => {
      state.filesSort = action.payload;
    },
    toggleDisplayExpired: (state) => {
      state.filter.displayExpired = !state.filter.displayExpired;
    },
    setShareboxShareModalOpen: (state, action: PayloadAction<boolean>) => {
      state.shareModalOpen = action.payload;
    },
    setSelectedShareboxUrlId: (state, action: PayloadAction<EntityId | undefined>) => {
      state.selectedShareboxUrlId = action.payload;
    },
    shareboxFileEntityIdsSelected: (state, action: PayloadAction<{ shareboxId: EntityId; fileEntityIds: Array<EntityId>; selected: boolean }>) => {
      const { shareboxId, fileEntityIds, selected } = action.payload;
      const sharebox = state.entities[shareboxId];
      if (sharebox && sharebox.fileList && sharebox.fileList.length > 0) {
        sharebox.fileList = sharebox.fileList.map((fileEntity) => ({ ...fileEntity, selected: fileEntityIds.includes(fileEntity.id) ? selected : fileEntity.selected }));
      }
    },
    shareboxFolderIdsSelected: (state, action: PayloadAction<{ shareboxId: EntityId; folderIds: Array<EntityId>; selected: boolean }>) => {
      const { shareboxId, folderIds, selected } = action.payload;
      const sharebox = state.entities[shareboxId];
      if (sharebox && sharebox.fileList && sharebox.fileList.length > 0) {
        const allFiles = [...sharebox.fileList, ...sharebox.parentList];
        let allSelectedWithChildren = [] as Array<EntityId>;
        findAllChildrenForFileEntities(allFiles, folderIds, allSelectedWithChildren);
        sharebox.fileList = sharebox.fileList?.map((fileEntity) => ({ ...fileEntity, selected: allSelectedWithChildren.includes(fileEntity.id) ? selected : fileEntity.selected }));
        sharebox.parentList = sharebox.parentList?.map((fileEntity) => ({ ...fileEntity, selected: allSelectedWithChildren.includes(fileEntity.id) ? selected : fileEntity.selected }));
      }
    },
    shareboxAllFilesUnSelected: (state, action: PayloadAction<EntityId>) => {
      const sharebox = state.entities[action.payload];
      if (sharebox && sharebox.fileList && sharebox.fileList.length > 0) {
        sharebox.fileList = sharebox.fileList?.map((fileEntity) => ({ ...fileEntity, selected: false }));
        sharebox.parentList = sharebox.parentList?.map((fileEntity) => ({ ...fileEntity, selected: false }));
      }
    },
    shareboxAllFilesSelectedToggled: (state, action: PayloadAction<EntityId>) => {
      const sharebox = state.entities[action.payload];
      if (sharebox && sharebox.fileList && sharebox.fileList.length > 0) {
        const allSelected = sharebox.fileList?.every((file) => file.selected);
        sharebox.fileList = sharebox.fileList?.map((fileEntity) => ({ ...fileEntity, selected: !allSelected }));
        sharebox.parentList = sharebox.parentList?.map((fileEntity) => ({ ...fileEntity, selected: false }));
      }
    },
    setShareboxLoadingState: (state, action: PayloadAction<BhStateStatusType>) => {
      state.shareboxLoadingStatus = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShareboxAsync.pending, (state, action) => {
        state.shareboxLoadingStatus = BhStateStatusType.PENDING;
      })
      .addCase(fetchShareboxAsync.fulfilled, (state, action) => {
        state.shareboxLoadingStatus = BhStateStatusType.SUCCESS;
        shareboxesAdapter.upsertOne(state, { ...action.payload, numberOfFiles: action.payload.fileList.length });
      })
      .addCase(fetchUserCreatedShareboxesAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchProjectShareboxesAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(deleteShareboxAsync.fulfilled, (state, action) => {
        const removedSharebox = action.payload as ISharebox;
        shareboxesAdapter.removeOne(state, removedSharebox.id);
      })
      .addCase(fetchShareboxUrlsAsync.fulfilled, (state, action) => {
        const oldShareboxUrls = state.shareboxUrls;
        const oldShareboxUrlIds = oldShareboxUrls.map((oldUrl) => oldUrl.id);
        const urlsToAdd = action.payload.filter((url) => !oldShareboxUrlIds.includes(url.id));
        state.shareboxUrls.push(...urlsToAdd);
      })
      .addCase(saveShareboxAsync.fulfilled, (state, action) => {
        shareboxesAdapter.upsertOne(state, action.payload);
      })
      .addCase(saveShareboxValidToAsync.fulfilled, (state, action) => {
        shareboxesAdapter.upsertOne(state, action.payload);
      })
      .addMatcher(isAnyOf(fetchUserCreatedShareboxesAsync.fulfilled, fetchProjectShareboxesAsync.fulfilled), (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.shareboxListStatus = BhStateStatusType.SUCCESS;
        const userShareboxes = action.payload;
        shareboxesAdapter.upsertMany(state, userShareboxes);
      })
      .addMatcher(isAnyOf(fetchUserCreatedShareboxesAsync.rejected, fetchProjectShareboxesAsync.rejected), (state, action) => {
        state.status = BhStateStatusType.ERROR;
        console.error("Sharebox request rejected");
      });
  }
});

export const {
  shareboxesAdded,
  shareboxesUpdatedAndAdded,
  clearShareboxReceiversFilter,
  clearShareboxCreatorsFilter,
  receiverToggledInShareboxFilter,
  creatorToggledInShareboxFilter,
  setShareboxReceiversFilter,
  setShareboxCreatorsFilter,
  setShareboxFilter,
  shareboxesSortSet,
  shareboxFilesSortSet,
  toggleDisplayExpired,
  setShareboxShareModalOpen,
  setSelectedShareboxUrlId,
  shareboxFileEntityIdsSelected,
  shareboxFolderIdsSelected,
  shareboxAllFilesUnSelected,
  shareboxAllFilesSelectedToggled,
  setShareboxLoadingState
} = shareboxesSlice.actions;

export const { selectIds: selectShareboxIds, selectAll: selectAllShareboxes, selectById: selectShareboxById } = shareboxesAdapter.getSelectors((state: RootState) => state.shareboxes);

export const selectShareboxListStatus = (state: RootState) => state.shareboxes.shareboxListStatus;
export const selectShareboxLoadingStatus = (state: RootState) => state.shareboxes.shareboxLoadingStatus;
export const selectShareboxesFilter = (state: RootState) => state.shareboxes.filter;
export const selectShareboxesReceiversFilter = (state: RootState) => state.shareboxes.filter.shareboxReceivers;
export const selectShareboxesCreatorsFilter = (state: RootState) => state.shareboxes.filter.shareboxCreators;
export const selectShareboxesSort = (state: RootState) => state.shareboxes.sort;
export const selectShareboxFilesSort = (state: RootState) => state.shareboxes.filesSort;
export const selectShareboxProjectId = (state: RootState, projectId: EntityId) => projectId;

export const selectProjectShareboxes = createSelector([selectAllShareboxes, selectShareboxProjectId], (projectShareboxes, projectId) => {
  return projectShareboxes.filter((sharebox) => sharebox.projectId === projectId);
});

export const selectUserProjectShareboxes = createSelector([selectProjectShareboxes, selectShareboxProjectId, selectCurrentUserUsername], (projectShareboxes, projectId, username) => {
  return projectShareboxes.filter((sharebox) => sharebox.createdBy === username);
});

export const selectUserProjectShareboxesCount = createSelector([selectUserProjectShareboxes, selectShareboxProjectId], (userProjectShareboxes) => {
  return userProjectShareboxes.length;
});

export const selectProjectShareboxesCount = createSelector([selectProjectShareboxes, selectShareboxProjectId], (projectShareboxes) => {
  return projectShareboxes.length;
});

export const selectUserProjectShareboxesFilteredAndSorted = createSelector(
  [selectUserProjectShareboxes, selectShareboxProjectId, selectCurrentUserUsername, selectShareboxesFilter, selectShareboxesSort],
  (userProjectShareboxes, projectId, username, filter, sort) => {
    const selectedEmails = filter.shareboxReceivers.receiversDropdown || [];

    const userProjectShareboxesReceiverFiltered = userProjectShareboxes.filter((sharebox) => selectedEmails.length === 0 || intersection(selectedEmails, sharebox.receiverEmails).length > 0);

    const userProjectShareboxesReceiverExpiredFiltered = userProjectShareboxesReceiverFiltered.filter(
      (sharebox) => filter.displayExpired || sharebox.validTo == null || new Date(sharebox.validTo) >= new Date()
    );

    const key = filter.searchKey?.toLowerCase();
    const userProjectShareboxesReceiverExpiredKeyFiltered = userProjectShareboxesReceiverExpiredFiltered.filter((sharebox) => sharebox.name.toLowerCase().includes(key));

    const { property, reversed } = sort;

    return userProjectShareboxesReceiverExpiredKeyFiltered.sort((a, b) => naturalSortByField(a, b, property, reversed));
  }
);

export const selectProjectShareboxesFilteredAndSorted = createSelector(
  [selectProjectShareboxes, selectShareboxProjectId, selectShareboxesFilter, selectShareboxesSort],
  (projectShareboxes, projectId, filter, sort) => {
    const selectedCreatorDropdownValues = filter.shareboxCreators.creatorsDropdown || [];
    const selectedReceiverEmails = filter.shareboxReceivers.receiversDropdown || [];

    const projectShareboxesReceiverFiltered = projectShareboxes.filter((sharebox) => selectedReceiverEmails.length === 0 || intersection(selectedReceiverEmails, sharebox.receiverEmails).length > 0);

    const selectedCreatorUsernames = selectedCreatorDropdownValues.map((dropdownValue) => {
      return dropdownValue.username;
    });

    const projectShareboxesReceiverCreatorFiltered = projectShareboxesReceiverFiltered.filter(
      (sharebox) => selectedCreatorDropdownValues.length === 0 || selectedCreatorUsernames.includes(sharebox.createdBy)
    );

    const projectShareboxesReceiverCreatorExpiredFiltered = projectShareboxesReceiverCreatorFiltered.filter(
      (sharebox) => filter.displayExpired || sharebox.validTo == null || new Date(sharebox.validTo) >= new Date()
    );

    const key = filter.searchKey?.toLowerCase();
    const projectShareboxesReceiverCreatorExpiredKeyFiltered = projectShareboxesReceiverCreatorExpiredFiltered.filter((sharebox) => sharebox.name.toLowerCase().includes(key));

    const { property, reversed } = sort;

    return projectShareboxesReceiverCreatorExpiredKeyFiltered.sort((a, b) => naturalSortByField(a, b, property, reversed));
  }
);

export const selectShareboxUrls = (state: RootState, shareboxId: EntityId) => {
  const shareboxUrls = state.shareboxes.shareboxUrls.filter((sbUrl) => sbUrl.shareboxId === shareboxId);
  const sortedShareboxUrls = shareboxUrls.sort((url1, url2) => {
    return url1.created > url2.created ? 1 : -1;
  });
  return sortedShareboxUrls;
};
export const selectShareboxMessageShareboxUrl = (state: RootState) => {
  const shareboxUrlId = state.shareboxes.selectedShareboxUrlId;
  return state.shareboxes.shareboxUrls.find((url) => url.id === shareboxUrlId);
};
export const selectShareboxShareModalOpen = (state: RootState) => state.shareboxes.shareModalOpen;
export const selectShareboxMessageModalOpen = (state: RootState) => state.shareboxes.selectedShareboxUrlId;

export const selectUserShareboxesFilterReceiversValues = createSelector([selectUserProjectShareboxes], (shareboxes) => {
  const receivers = shareboxes.flatMap((sb) => sb.receiverEmails);
  return removeDuplicates(receivers) || [];
});

export const selectProjectShareboxesFilterReceiversValues = createSelector([selectProjectShareboxes], (shareboxes) => {
  const receivers = shareboxes.flatMap((sb) => sb.receiverEmails);
  return removeDuplicates(receivers) || [];
});

export const selectProjectShareboxesFilterCreatorsValues = createSelector([selectProjectShareboxes], (shareboxes) => {
  const creators = shareboxes.map((sb) => sb.createdBy);
  return removeDuplicates(creators) || [];
});

export const selectShareboxIdForSharebox = (state: RootState, shareboxId: EntityId, _: EntityId | null) => shareboxId;
export const selectFileIdForSharebox = (state: RootState, _: EntityId, fileId: EntityId | null) => fileId;

export const selectShareboxFoldersByShareboxIdSorted = createSelector([selectShareboxById, selectShareboxIdForSharebox], (sharebox) => {
  const folders = sharebox?.parentList || [];
  const foldersWithFiles = folders.filter((folder) => sharebox?.fileList.some((file) => file.parentFileEntityId === folder.id));
  return [...foldersWithFiles].sort((a, b) => naturalSortByField(a, b, "filePath"));
});

export const selectShareboxFilesForFolderSorted = createSelector(
  [selectShareboxById, selectShareboxIdForSharebox, selectFileIdForSharebox, selectShareboxFilesSort],
  (sharebox, shareboxId, folderId, sort) => {
    return sharebox?.fileList?.filter((file) => file.parentFileEntityId === folderId).sort((a, b) => naturalSortFilesByField(a, b, sort.property, sort.reversed));
  }
);

export const selectShareboxFolders = createSelector([selectShareboxById, selectShareboxIdForSharebox], (sharebox) => {
  return sharebox?.parentList;
});

export const selectShareboxFoldersForFolderSorted = createSelector(
  [selectShareboxById, selectShareboxIdForSharebox, selectFileIdForSharebox, selectShareboxFilesSort],
  (sharebox, shareboxId, folderId, sort) => {
    return sharebox?.parentList?.filter((file) => file.parentFileEntityId === folderId).sort((a, b) => naturalSortFilesByField(a, b, sort.property, sort.reversed));
  }
);

export const selectShareboxFolderParentDirs = createSelector([selectShareboxById, selectShareboxIdForSharebox, selectFileIdForSharebox], (sharebox, shareboxId, folderId) => {
  return findParentFolders(sharebox?.parentList || [], folderId);
});

export const selectAllFilesSelectedForSharebox = createSelector([selectShareboxById, selectShareboxIdForSharebox], (sharebox) => {
  return sharebox?.fileList?.every((file) => file.selected);
});

export default shareboxesSlice.reducer;
