import { IFileEntity } from "@/model/files/IFileEntity";
import { IBhSort } from "@/model/IBhSort";

export interface IFilesSort extends IBhSort<IFileEntity> {
  dateField: "created" | "updated";
}

export const initialFilesSort: IFilesSort = {
  property: "name" as keyof IFileEntity,
  reversed: false,
  dateField: "created"
};
