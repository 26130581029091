import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { classNames } from "@/utilities/jsUtilities";
import { addAllProjectBasesToWorkGroupAsync, selectCompanyChecklistBasesWithFinishedVersions, selectWorkgroupToChecklistBases } from "@/app/store/checklist/checklistsSlice";
import BhToggleRow from "@components/toggle/BhToggleRow";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { naturalSortFilesByField } from "@/utilities/sortUtilities";
import { IWorkGroup } from "@/model/IWorkGroup";

interface Props {
  activeWorkGroup: IWorkGroup;
  activateBaseInWorkGroup: Function;
}

const ChecklistsAuthoritiesModalWorkgroupsTab: FC<Props> = ({ activeWorkGroup, activateBaseInWorkGroup }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const workGroupAuthorities = useAppSelector(selectWorkgroupToChecklistBases);
  const projectId = useAppSelector(selectCurrentProjectId);
  const checklistBases = useAppSelector(selectCompanyChecklistBasesWithFinishedVersions);
  const [checklistBasesSorted, setChecklistBasesSorted] = useState(checklistBases.slice().sort((a, b) => naturalSortFilesByField(a, b, "type", false)) as Array<any>);
  const [activeWorkGroupHasAllBases, setActiveWorkGroupHasAllBases] = useState(false);

  useEffect(() => {
    if (activeWorkGroup) {
      const checklistBasesToSet = checklistBasesSorted.map((cb) => {
        const isChecklistBaseActivatedInWorkGroup = workGroupAuthorities.some((auth) => auth.checklistBaseId === cb.id && auth.workGroupId === activeWorkGroup.id && auth.active);
        return { ...cb, selected: isChecklistBaseActivatedInWorkGroup };
      });
      setChecklistBasesSorted(checklistBasesToSet);

      const wgHasAllBases =
        checklistBasesToSet.filter((cb) => {
          return cb.selected;
        }).length === checklistBasesSorted.length;
      setActiveWorkGroupHasAllBases(wgHasAllBases);
    }
  }, [activeWorkGroup, workGroupAuthorities]);

  const toggleAllBasesInActiveWorkGroup = () => {
    const dtoToSave = { projectId: projectId, workGroupId: activeWorkGroup.id, removeBase: activeWorkGroupHasAllBases };
    dispatch(addAllProjectBasesToWorkGroupAsync(dtoToSave));
  };

  return (
    <div>
      <BhToggleRow
        toggleObject={{ id: 0, selected: activeWorkGroupHasAllBases } as any}
        toggleProperty={"selected"}
        onToggle={toggleAllBasesInActiveWorkGroup}
        label={<div className="bh-text-deep-ocean text-14px mt-4 font-bold leading-5">{t("CHECKLIST.TEMPLATES.ALL")}</div>}
        classes={"pb-5"}
      />
      {checklistBasesSorted &&
        checklistBasesSorted.length > 0 &&
        checklistBasesSorted.map((cb, index) => {
          return (
            <BhToggleRow
              key={cb.id}
              toggleObject={cb}
              toggleProperty={"selected"}
              onToggle={() => activateBaseInWorkGroup(cb, activeWorkGroup)}
              label={
                <div className="flex flex-row items-center gap-x-2">
                  <BhFileCardIcon fileEntity={{ id: cb.id, name: cb.type, type: FileEntityType.CHECKLIST } as IFileEntity} fileCardSize={FileCardSize.MEDIUM} />
                  <div className="bh-text-deep-ocean text-14px leading-5">{cb.type}</div>
                </div>
              }
              classes={classNames("pt-5 bh-border-pigeon-20 border-t", index !== checklistBasesSorted.length - 1 && "pb-5")}
            />
          );
        })}
    </div>
  );
};

export default ChecklistsAuthoritiesModalWorkgroupsTab;
