//@ts-ignore
import React, { FC, Fragment, ReactNode, startTransition, useCallback, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUserFullName, IUser } from "@/model/IUser";
import { IFileEntity } from "@/model/files/IFileEntity";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import { IProject } from "@/model/IProject";
import { isValidEmail } from "@/utilities/jsUtilities";
import { IFileTreeTemplate } from "@/model/files/IFileTreeTemplate";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { useTranslation } from "react-i18next";

interface Props {
  initialValue?: string;
  placeholder?: string;
  property: string;
  values: Array<string | IUser | IFileEntity | IProject | ISimpleValidUserAuthority | IFileTreeTemplate>;
  onSelect?: Function;
  showValueCondition?: Function;
  disabled?: boolean;
  customType?: BhDropdownTypeEnum;
  // enable using the value from the input as a new value
  enableNewValue?: boolean;
  // callback for updating the value of input in upper component
  queryValueCallback?: Function;
  // keep value visible after selecting a value from list
  keepValueVisibleAfterSelect?: boolean;
  // which property to use of the objects that are in the list
  visibleValueProperty?: string;
  canAddCustomEmail?: boolean;
  showUsernameWithUserValues?: boolean;
}

const BhCombobox: FC<Props> = ({
  initialValue,
  placeholder,
  property,
  values,
  onSelect,
  showValueCondition,
  disabled,
  customType,
  queryValueCallback,
  enableNewValue,
  keepValueVisibleAfterSelect,
  visibleValueProperty,
  canAddCustomEmail,
  showUsernameWithUserValues
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");

  const queryFilter = (value: string | IUser | IFileEntity | IProject | ISimpleValidUserAuthority | IFileTreeTemplate) => {
    let stringValue: string;

    switch (customType) {
      case BhDropdownTypeEnum.USER:
        stringValue = getUserFullName(value as IUser);
        break;
      case BhDropdownTypeEnum.AUTH:
        stringValue = getUserFullName(value as ISimpleValidUserAuthority);
        break;
      case BhDropdownTypeEnum.FILE:
        stringValue = (value as IFileEntity).name;
        break;
      case BhDropdownTypeEnum.PROJECT:
        stringValue = (value as IProject).name;
        break;
      case BhDropdownTypeEnum.FILETREETEMPLATE:
        stringValue = (value as IFileTreeTemplate).name;
        break;
      default:
        stringValue = value as string;
    }
    return stringValue?.toLowerCase().replace(/\s+/g, "").includes(query.toLowerCase().replace(/\s+/g, ""));
  };

  const filteredValues = values.filter((value) => {
    const isQueryFilter = query === "" ? true : queryFilter(value);
    const isShowValueCondition = showValueCondition ? showValueCondition(value) : true;
    return isQueryFilter && isShowValueCondition;
  });

  const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    startTransition(() => {
      const inputValue = e.target.value;
      if (inputValue === initialValue && initialValue !== "") return;
      setQuery(inputValue);
      if (queryValueCallback) {
        queryValueCallback(inputValue);
      }
    });
  }, []);

  const getDropdownValues = useCallback(() => {
    return filteredValues.map((itemValue) => {
      let key;
      let value;
      let element: ReactNode;

      switch (customType) {
        case BhDropdownTypeEnum.USER:
          key = (itemValue as IUser).id;
          value = itemValue as IUser;
          element = <BhUserIconWithName user={itemValue as IUser} showUsername={showUsernameWithUserValues} disablePopup={true} />;
          break;
        case BhDropdownTypeEnum.AUTH:
          key = (itemValue as ISimpleValidUserAuthority).userEntityId;
          value = itemValue as ISimpleValidUserAuthority;
          element = <BhUserIconWithName user={itemValue as ISimpleValidUserAuthority} disablePopup={true} />;
          break;
        case BhDropdownTypeEnum.PROJECT:
          key = (itemValue as IProject).id;
          value = itemValue as IProject;
          element = <p>{value.name}</p>;
          break;
        case BhDropdownTypeEnum.FILETREETEMPLATE:
          key = (itemValue as IFileTreeTemplate).id;
          value = itemValue as IFileTreeTemplate;
          element = <p>{value.name}</p>;
          break;
        default:
          key = value = element = itemValue as string;
      }
      return (
        <Combobox.Option key={key} className={({ active, selected }) => `relative cursor-pointer select-none py-1.5 pl-10 pr-4 ${active && "bh-bg-smoke"} ${selected && "bh-bg-smoke"}`} value={value}>
          {({ selected }) => (
            <div>
              {selected && <FontAwesomeIcon icon={faCheck} className="absolute -ml-6 mt-0.5" size="xs" />}
              <span className={`block truncate ${selected ? "bh-bg-smoke w-full" : "font-normal"}`}>{element}</span>
            </div>
          )}
        </Combobox.Option>
      );
    });
  }, [filteredValues]);

  const isEmailNonUser = (email: string) => {
    return !values.some((u) => typeof u === "object" && "username" in u && u.username === email);
  };

  if (!values) {
    return null;
  }

  return (
    <div className="w-full">
      <Combobox
        value={initialValue}
        onChange={(changedValue) => {
          if (!changedValue || (typeof changedValue === "string" && changedValue.trim() === "")) return;
          onSelect && onSelect({ [property]: changedValue });
          if (changedValue && keepValueVisibleAfterSelect) {
            const value = Object.entries(changedValue).find(([key, value]) => {
              if (key === visibleValueProperty) {
                return value;
              }
            });
            if (value) {
              setQuery(value[1]);
            }
          }
        }}
        disabled={disabled}
      >
        <div className="relative">
          <div className="relative w-full cursor-pointer overflow-hidden rounded text-left">
            <Combobox.Button className="w-full" as="div">
              <Combobox.Input
                autoComplete="off"
                className="l-h-20 bh-border-pigeon-40 placeholder:bh-text-deep-ocean-40 hover:bh-border-pigeon-70 focus:bh-border-pigeon-70 disabled:bh-text-deep-ocean-40 disabled:bh-bg-smoke disabled:bh-border-smoke w-full rounded rounded py-2"
                displayValue={(initialValue: string) => {
                  if (enableNewValue) {
                    return query;
                  }
                  return initialValue;
                }}
                onChange={(e) => onInputChange(e)}
                placeholder={placeholder && placeholder}
                onClick={() => {
                  if (!enableNewValue) {
                    setQuery("");
                  }
                }}
              />
              <div className="bh-text-pigeon absolute inset-y-2 right-0.5 pr-2">
                <FontAwesomeIcon icon={faCaretDown} />
              </div>
            </Combobox.Button>
          </div>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" afterLeave={() => setQuery("")}>
            <Combobox.Options className="bh-shadow bh-bg-raised-white z-60 absolute mt-1 max-h-60 w-full overflow-auto rounded py-1">
              {filteredValues.length === 0 && query !== "" ? <div className="bh-text-deep-ocean-40 relative cursor-pointer select-none py-2 px-4">{t("GLOBAL.NO_RESULTS")}</div> : getDropdownValues()}
              {canAddCustomEmail && query !== "" && isValidEmail(query) && isEmailNonUser(query) && (
                <Combobox.Option
                  className={({ active, selected }) => `relative cursor-pointer select-none py-1.5 pl-10 pr-4 ${active && "bh-bg-smoke"} ${selected && "bh-bg-smoke"}`}
                  value={{ username: query, userExists: false }}
                >
                  <div>
                    <span className={"block truncate"}>
                      Lisa e-mail <b>"{query}"</b>
                    </span>
                  </div>
                </Combobox.Option>
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

export default BhCombobox;
