import React, { FC, useCallback, useEffect, useState } from "react";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectSignersFilterDropdownValuesForAllProjectUnsignedInvites, selectSignInvitesFilter, signInvitesSignerFilterCleared, signInvitesSignerFilterSet } from "@/app/store/signInvitesSlice";
import BhFilter from "@components/filters/BhFilter";
import { BhFilterTypeEnum } from "@components/filters/BhFilterTypeEnum";
import { useTranslation } from "react-i18next";
import { intersection } from "@/utilities/jsUtilities";
import { fetchRelatedUsersInProjectWithSearch } from "@/api/projectAPI";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { sortByFullNameAndEmail } from "@/utilities/sortUtilities";

interface Props {}
const SignInvitesSignerFilter: FC<Props> = ({}) => {
  const { t } = useTranslation();
  const [signerUserEntities, setSignerUserEntities] = useState<Array<ISimpleValidUserAuthority>>([]);
  const [filteredSigners, setFilteredSigners] = useState<Array<ISimpleValidUserAuthority>>([]);
  const signersFilterDropownValues = useAppSelector(selectSignersFilterDropdownValuesForAllProjectUnsignedInvites);
  const filter = useAppSelector(selectSignInvitesFilter);
  const projectId = useAppSelector(selectCurrentProjectId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [projectUsers, setProjectUsers] = useState<Array<ISimpleValidUserAuthority>>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSignerUserEntities(
      projectUsers
        .filter((projectUser) => {
          const allProjectUsernames = projectUsers.map((user) => user.username);
          return intersection(allProjectUsernames, signersFilterDropownValues || []).some((username) => username === projectUser.username);
        })
        .sort((a, b) => sortByFullNameAndEmail(a, b))
    );
  }, [projectUsers]);

  useEffect(() => {
    setFilteredSigners(signerUserEntities);
  }, [signerUserEntities]);

  const toggleSignerFilter = useCallback(
    (selectedValue: ISimpleValidUserAuthority) => {
      dispatch(signInvitesSignerFilterSet(selectedValue.username));
    },
    [filter]
  );

  const isSelected = (selectedValue: ISimpleValidUserAuthority) => filter.signer.some((signer) => signer?.toLowerCase() === selectedValue.username.toLowerCase());

  const onSignerSearch = (query: { query: string }) => {
    const searchString = query.query.toLowerCase();
    setFilteredSigners(
      signerUserEntities.filter((user) => {
        const firsNameFilter = user.firstName.toLowerCase().includes(searchString);
        const lastNameFilter = user.lastName.toLowerCase().includes(searchString);
        return firsNameFilter || lastNameFilter;
      })
    );
  };

  const searchUsers = () => {
    if (projectId) {
      setIsLoading(true);
      fetchRelatedUsersInProjectWithSearch(projectId)
        .then((projectUsers) => {
          setProjectUsers(projectUsers);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="inline-flex" onClick={searchUsers}>
      <BhFilter
        filterType={BhFilterTypeEnum.USER}
        title={t("SIGNING.SIGNERS")}
        values={filteredSigners}
        onSelect={toggleSignerFilter}
        onReset={() => dispatch(signInvitesSignerFilterCleared())}
        isSelected={isSelected}
        selectedValues={filter.signer.length}
        dropdownWidthClass="min-w-max"
        search={true}
        searchInputProperty="query"
        searchInputSaveCallback={onSignerSearch}
        isLoading={isLoading}
      />
    </div>
  );
};

export default SignInvitesSignerFilter;
