import React from "react";
import BhSeparatorMarginless from "@components/separator/BhSeparatorMarginless";

const FormBaseSeparator = () => {
  return (
    <div className="flex h-full w-full">
      <BhSeparatorMarginless />
    </div>
  );
};

export default FormBaseSeparator;
