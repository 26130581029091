import { math, Viewer } from "@xeokit/xeokit-sdk";
import { objectsWithoutFurthest } from "@/views/home/project/detail/xeokit/helpers/pointDistanceHelper";

export const storeyViewsPluginObjectStates = {
  IfcSlab: {
    visible: true,
    edges: true
  },
  IfcWall: {
    visible: true,
    colorize: [0, 0, 0],
    edges: true
  },
  IfcWallStandardCase: {
    visible: true,
    colorize: [0, 0, 0],
    edges: true
  },
  IfcDoor: {
    visible: true,
    edges: true
  },
  IfcWindow: {
    visible: true,
    edges: true
  },
  IfcColumn: {
    visible: true,
    edges: true
  },
  IfcCurtainWall: {
    visible: true,
    edges: true
  },
  IfcStair: {
    visible: true,
    edges: true
  },
  IfcStairFlight: {
    visible: true,
    edges: true
  },
  IfcRamp: {
    visible: true,
    edges: true
  },
  IfcFooting: {
    visible: true,
    edges: true
  },
  IfcFloor: {
    visible: true,
    edges: true
  },
  IfcBeam: {
    visible: true,
    edges: true
  },
  IfcBuildingElementPart: {
    visible: true,
    edges: false
  },
  IfcCovering: {
    visible: true,
    edges: false
  }
};

export const firstCameraCfg = {
  look: [30, 5, -25],
  eye: [50, 25, 50],
  up: [-0.25, 0.25, -0.9],
  orthoScale: 110,
  projection: "perspective"
};

export const defaultCameraPosition = (viewer: Viewer, projection: string) => {
  const tempVec3a = math.vec3();

  const scene = viewer.scene;
  const visibleObjectIds = scene.visibleObjectIds;
  const objectIds = visibleObjectIds.length > 500 ? objectsWithoutFurthest(viewer, visibleObjectIds) : visibleObjectIds;

  const aabb = scene.getAABB(objectIds);
  const diag = math.getAABB3Diag(aabb);
  // @ts-ignore
  const center = math.getAABB3Center(aabb, tempVec3a);
  const camera = scene.camera;
  const fitFOV = camera.perspective.fov;
  const dist = Math.abs(diag / Math.tan(50 * math.DEGTORAD));
  const dir = math.normalizeVec3(camera.yUp ? [-0.2, -0.15, -0.5] : [-1, 1, -1]);
  const up = math.normalizeVec3(camera.yUp ? [-0.2, 0.15, -0.5] : [-1, 1, 1]);
  viewer.cameraControl.pivotPos = center;
  viewer.cameraControl.constrainVertical = false;
  viewer.cameraControl.followPointer = true;

  const cfg = {
    look: center,
    eye: [center[0] - dist * dir[0], center[1] - dist * dir[1], center[2] - dist * dir[2]],
    up: up,
    orthoScale: diag * 1.3,
    projection: projection,
    duration: 0.5
  };
  viewer.cameraFlight.flyTo(cfg);
};

export const defaultCameraActionForMobile = (viewer: Viewer) => {
  const cameraControl = viewer.cameraControl;

  cameraControl.navMode = "firstPerson";
  cameraControl.followPointer = false;
  // cameraControl.constrainVertical = true;
  cameraControl.touchDollyRate = 0.05;
  // cameraControl.touchPanRate = 0.05;
  // cameraControl.panInertia = 0.05;
  cameraControl.dragRotationRate = 100;
};

export interface IFloorplanMetadata {
  [key: string]: number[];

  sceneBoundaries: number[];
}
