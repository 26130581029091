import React, { FC } from "react";

interface Props {
  containerName?: string;
}

const PublicContainerInfoHeader: FC<Props> = ({ containerName }) => {
  return (
    <div className="bh-bg-mint flex w-full">
      <div className="mx-auto flex w-full max-w-[1366px] flex-col items-start space-y-4 px-6 py-2">
        <h2>{containerName}</h2>
      </div>
    </div>
  );
};

export default PublicContainerInfoHeader;
