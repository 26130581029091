import React, { Dispatch, FC, SetStateAction } from "react";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { TaskStatus } from "@/model/taskBoard/ITask";
import { useAppSelector } from "@/app/hooks";
import { selectIsProjectArchived } from "@/app/store/project/projectSlice";
import { selectIsAllTasksTaskBoardOpen } from "@/app/store/taskBoardsSlice";
import { useTranslation } from "react-i18next";
import BhIconButton from "@components/buttons/BhIconButton";
import BhTooltip from "@components/BhTooltip";

interface Props {
  status: TaskStatus;
  setNewTaskContainer: Dispatch<SetStateAction<any>>;
}

const TaskColumnHeader: FC<Props> = ({ status, setNewTaskContainer }) => {
  const { t } = useTranslation();
  const projectArchived = useAppSelector(selectIsProjectArchived);
  const isAllTasks = useAppSelector(selectIsAllTasksTaskBoardOpen);

  const toggleNewTask = () => {
    if (projectArchived) {
      return;
    }
    setNewTaskContainer({ visible: true, status: status });
  };

  return (
    <div className="bh-bg-smoke relative flex w-1/4 min-w-[200px] flex-row items-center justify-between rounded-t p-4">
      <h3>{t("TASK." + status)}</h3>
      {!isAllTasks && status !== TaskStatus.ARCHIVED && (
        <BhTooltip body={t("TASK.NEW_TASK")}>
          <BhIconButton icon={faPlus} buttonProps={{ onClick: () => toggleNewTask() }} />
        </BhTooltip>
      )}
    </div>
  );
};

export default TaskColumnHeader;
