import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { IFileAnnotation } from "@/model/files/IFileAnnotation";
import { fetchFileAnnotation, saveFileAnnotation } from "@/api/fileAnnotationAPI";
import { IFileEntity } from "@/model/files/IFileEntity";
import { taskFileAnnotationRelationsRemoved } from "@/app/store/tasksSlice";
import { ITask } from "@/model/taskBoard/ITask";

export const fileAnnotationsAdapter = createEntityAdapter<IFileAnnotation>({});

export interface IFileAnnotationState {
  taskModalClosedWithTaskId?: EntityId;
  removeAnnotationFromChecklist?: { fileAnnotationId: EntityId; annotationIds: Array<string>; type: "Task" | "Row" };
}

const initialState = fileAnnotationsAdapter.getInitialState<IFileAnnotationState>({});

export const fetchFileAnnotationAsync = createAsyncThunk("fileAnnotations/fetchFileAnnotation", async (fileEntity: IFileEntity, { dispatch }) => {
  return fetchFileAnnotation(fileEntity.projectId, fileEntity.id);
});

export const saveFileAnnotationAsync = createAsyncThunk("fileAnnotations/saveFileAnnotation", async (fileAnnotation: IFileAnnotation, { dispatch }) => {
  const taskRelationsToDelete = fileAnnotation.taskAnnotations?.filter((taskAnnotation) => fileAnnotation.removedAnnotationIds.includes(taskAnnotation.annotationId));
  const result = await saveFileAnnotation(fileAnnotation);
  if (taskRelationsToDelete) {
    dispatch(taskFileAnnotationRelationsRemoved(taskRelationsToDelete));
  }
  return result;
});

export const fileAnnotationSlice = createSlice({
  name: "fileAnnotations",
  initialState,
  reducers: {
    fileAnnotationUpdated: fileAnnotationsAdapter.upsertOne,
    taskModalClosedWithTaskIdSet: (state, action: PayloadAction<EntityId | undefined>) => {
      state.taskModalClosedWithTaskId = action.payload;
    },
    setRemovedAnnotationIdsForFileAnnotationId: (state, action: PayloadAction<{ fileEntityId: EntityId; annotationIds: Array<string>; type: "Task" | "Row" } | undefined>) => {
      const fileAnnotation = Object.values(state.entities).find((fa) => {
        return fa && fa.fileEntityId === action.payload?.fileEntityId;
      });
      if (fileAnnotation && action.payload?.annotationIds) {
        state.removeAnnotationFromChecklist = { fileAnnotationId: fileAnnotation.id, annotationIds: action.payload?.annotationIds, type: action.payload?.type };
      }
    },
    fileAnnotationTaskAnnotationRemoved: (state, action: PayloadAction<ITask>) => {
      const { id: taskId, fileAnnotationRelations } = action.payload;
      fileAnnotationRelations.forEach((fileAnnotationRelation) => {
        const existingFileAnnotation = state.entities[fileAnnotationRelation.fileAnnotation.id];
        if (existingFileAnnotation) {
          existingFileAnnotation.taskAnnotations = existingFileAnnotation.taskAnnotations.filter((taskAnnotation) => taskAnnotation.taskId !== taskId);
        }
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFileAnnotationAsync.fulfilled, (state, action) => {
        fileAnnotationsAdapter.upsertOne(state, action.payload);
      })
      .addCase(saveFileAnnotationAsync.fulfilled, (state, action) => {
        fileAnnotationsAdapter.upsertOne(state, action.payload);
      });
  }
});

export const { taskModalClosedWithTaskIdSet, setRemovedAnnotationIdsForFileAnnotationId, fileAnnotationUpdated, fileAnnotationTaskAnnotationRemoved } = fileAnnotationSlice.actions;

export const {
  selectIds: selectFileAnnotationIds,
  selectAll: selectAllFileAnnotations,
  selectById: selectFileAnnotationById
} = fileAnnotationsAdapter.getSelectors((state: RootState) => state.fileAnnotations);

export const selectTaskModalClosedWithTaskId = (state: RootState) => state.fileAnnotations.taskModalClosedWithTaskId;
export const selectRemoveAnnotationFromChecklist = (state: RootState) => state.fileAnnotations.removeAnnotationFromChecklist;
export const selectFileEntityId = (state: RootState, fileEntityId: EntityId) => fileEntityId;

export const selectFileAnnotationForFileEntityId = createSelector([selectAllFileAnnotations, selectFileEntityId], (fileAnnotations, fileEntityId) =>
  fileAnnotations.find((fa) => fa.fileEntityId === fileEntityId)
);
export default fileAnnotationSlice.reducer;
