import React, { FC } from "react";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import TaskBoardItemGroup from "@components/taskBoard/TaskBoardItemGroup";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectStatusToTaskBoardMap, toggleTaskBoardModalsOpen } from "@/app/store/taskBoardsSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareKanban } from "@fortawesome/pro-regular-svg-icons";
import { fetchProjectWorkGroupsAsync } from "@/app/store/project/projectWorkGroupsSlice";
import CategoriesModalButton from "@components/taskBoard/modals/CategoriesModalButton";
import TaskBoardItem from "@components/taskBoard/TaskBoardItem";
import RequireProjectAdminAuth from "@/views/authentication/RequireProjectAdminAuth";
import { useTranslation } from "react-i18next";
import BhTooltip from "@components/BhTooltip";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { TaskBoardStatus } from "@/model/taskBoard/ITaskBoard";

interface Props {}

const TaskBoardListFC: FC<Props> = () => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();
  const taskBoardStatusToIdMap = useAppSelector(selectStatusToTaskBoardMap);

  const openNewTaskBoardModal = () => {
    dispatch(fetchProjectWorkGroupsAsync(projectId));
    dispatch(toggleTaskBoardModalsOpen({ modal: "taskBoardSettingsModal" }));
  };

  const openProjectCategoriesModal = () => {
    dispatch(toggleTaskBoardModalsOpen({ modal: "projectTaskCategoryModal" }));
  };

  const taskBoardStatusesSorted = [TaskBoardStatus.IN_PROGRESS, TaskBoardStatus.TODO, TaskBoardStatus.DONE];

  return (
    <div className="bh-border-pigeon-20 flex h-full min-w-0 flex-col justify-between overflow-hidden border-r">
      <div className="flex flex-col overflow-hidden">
        <div className="ml-4 mt-8 mb-2 flex flex-row items-center">
          <FontAwesomeIcon icon={faSquareKanban} size="lg" />
          <h2 className="bh-text-deep-ocean-80 m-0 ml-2 p-0 font-bold">{t("GLOBAL.TASKBOARDS")}</h2>
        </div>
        <div className="taskboard-list-scrollbar overflow-hidden hover:overflow-auto focus:overflow-auto active:overflow-auto">
          <main className="flex-1 px-2">
            <section>
              <TaskBoardItem isAllTasks={true} />
              <TaskBoardItem isPersonal={true} />
              <div className="mt-6">
                {taskBoardStatusToIdMap &&
                  Object.keys(taskBoardStatusToIdMap)
                    .sort((a: any, b: any) => {
                      return taskBoardStatusesSorted.indexOf(a) - taskBoardStatusesSorted.indexOf(b);
                    })
                    .map((status, index) => {
                      return <TaskBoardItemGroup status={status} taskBoardIds={taskBoardStatusToIdMap[status]} key={index} />;
                    })}
              </div>
            </section>
          </main>
        </div>
      </div>
      <RequireProjectAdminAuth>
        <div className="m-6 flex flex-row items-center">
          <BhSecondaryButton icon={faPlus} buttonProps={{ classes: "w-full h-10 my-0 mx-0", onClick: () => openNewTaskBoardModal() }}>
            {t("TASKBOARD.CREATE_NEW")}
          </BhSecondaryButton>
          <div className="ml-2">
            <BhTooltip body={t("TASK.CATEGORIES")}>
              <CategoriesModalButton onClick={openProjectCategoriesModal} />
            </BhTooltip>
          </div>
        </div>
      </RequireProjectAdminAuth>
    </div>
  );
};

const TaskBoardList = React.memo(TaskBoardListFC);
export default TaskBoardList;
