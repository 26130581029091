import React, { FC, MouseEventHandler, ReactNode } from "react";
import { Menu } from "@headlessui/react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  callback: MouseEventHandler<HTMLDivElement>;
  icon: ReactNode;
  disabled?: boolean;
  children: ReactNode;
  isActive?: boolean;
}

const XeokitMobileMoreToolsModalElement: FC<Props> = ({ callback, icon, disabled, children, isActive }) => {
  return (
    <Menu.Item as={"div"}>
      <li>
        <div
          className={classNames(
            disabled ? "bh-text-pigeon-60" : "",
            "m-2 flex flex-row items-center",
            isActive ? (disabled ? "bh-bg-pigeon" : "bh-bg-dark-jungle bh-text-white") : "bh-text-deep-ocean",
            "rounded"
          )}
          onClick={!disabled ? callback : () => {}}
        >
          <div className="m-2 flex h-4 w-8 flex-shrink-0 items-center justify-center">{icon}</div>
          <div className="mr-2">{children}</div>
        </div>
      </li>
    </Menu.Item>
  );
};

export default XeokitMobileMoreToolsModalElement;
