import React, { FC, useCallback } from "react";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhFilter from "@components/filters/BhFilter";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  extensionFilterCleared,
  extensionToggledInFilesFilter,
  selectCurrentDirFilter,
  selectDistinctTagsForCurrentDirFiles,
  setFilesFilter,
  tagFilterCleared,
  tagToggledInFilesFilter
} from "@/app/store/filesSlice";
import { IFilesFilter } from "@/model/files/IFilesFilter";
import { IFileEntityTag } from "@/model/files/IFileEntityTag";
import { IFileExtensionFilter } from "@/model/files/IFileExtensionFilter";
import BhFilterContainer from "@components/filters/BhFilterContainer";
import { useTranslation } from "react-i18next";
import { getUniqueValues } from "@/utilities/jsUtilities";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { IFileEntity } from "@/model/files/IFileEntity";

const DirectoryFileListFilter: FC = () => {
  const { t } = useTranslation();
  const filter = useAppSelector(selectCurrentDirFilter);
  const tagsInDir = useAppSelector(selectDistinctTagsForCurrentDirFiles);
  const dispatch = useAppDispatch();

  const onNameFilterChange = useCallback(
    (changedValue: IFilesFilter) => {
      const changedObject = { ...filter, ...changedValue };
      dispatch(setFilesFilter(changedObject));
    },
    [filter]
  );

  const onTagFilterSelect = useCallback(
    (tag: IFileEntityTag) => {
      dispatch(tagToggledInFilesFilter(tag));
    },
    [filter]
  );

  const onFileExtensionFilterSelect = useCallback(
    (ext: IFileExtensionFilter) => {
      dispatch(extensionToggledInFilesFilter({ id: ext.id } as IFileExtensionFilter));
    },
    [filter]
  );

  const onTagFilterReset = useCallback(() => {
    dispatch(tagFilterCleared());
  }, [filter]);

  const onFileExtensionFilterReset = useCallback(() => {
    dispatch(extensionFilterCleared());
  }, [filter]);

  const isTagSelected = useCallback(
    (tag: IFileEntityTag) => {
      return (filter.tags || []).flatMap((t) => t.text).includes(tag.text);
    },
    [filter]
  );

  const isFileExtensionSelected = useCallback(
    (ext: IFileExtensionFilter) => {
      return ext.selected;
    },
    [filter.extensions]
  );

  const tagsInDirAndFilteredTags = getUniqueValues([...tagsInDir, ...(filter?.tags || [])], "text");

  const extensionFilterValues: Record<string, any> = {
    pdf: {
      id: 0,
      name: (
        <div className="flex flex-row items-center gap-x-2">
          <BhFileCardIcon fileEntity={{ id: 0, name: ".pdf" } as IFileEntity} fileCardSize={FileCardSize.MEDIUM} />
          <span>pdf</span>
        </div>
      )
    },
    jpg: {
      id: 1,
      name: (
        <div className="flex flex-row items-center gap-x-2">
          <BhFileCardIcon fileEntity={{ id: 1, name: ".jpg" } as IFileEntity} fileCardSize={FileCardSize.MEDIUM} />
          <span>jpg, jpeg</span>
        </div>
      )
    },
    dwg: {
      id: 2,
      name: (
        <div className="flex flex-row items-center gap-x-2">
          <BhFileCardIcon fileEntity={{ id: 2, name: ".dwg" } as IFileEntity} fileCardSize={FileCardSize.MEDIUM} />
          <span>dwg</span>
        </div>
      )
    },
    asice: {
      id: 3,
      name: (
        <div className="flex flex-row items-center gap-x-2">
          <BhFileCardIcon fileEntity={{ id: 3, name: ".asice" } as IFileEntity} fileCardSize={FileCardSize.MEDIUM} />
          <span>asice</span>
        </div>
      )
    },
    docx: {
      id: 4,
      name: (
        <div className="flex flex-row items-center gap-x-2">
          <BhFileCardIcon fileEntity={{ id: 4, name: ".docx" } as IFileEntity} fileCardSize={FileCardSize.MEDIUM} />
          <span>docx</span>
        </div>
      )
    },
    xlsx: {
      id: 5,
      name: (
        <div className="flex flex-row items-center gap-x-2">
          <BhFileCardIcon fileEntity={{ id: 5, name: ".xlsx" } as IFileEntity} fileCardSize={FileCardSize.MEDIUM} />
          <span>xlsx</span>
        </div>
      )
    },
    ifc: {
      id: 6,
      name: (
        <div className="flex flex-row items-center gap-x-2">
          <BhFileCardIcon fileEntity={{ id: 6, name: ".ifc" } as IFileEntity} fileCardSize={FileCardSize.MEDIUM} />
          <span>ifc</span>
        </div>
      )
    }
  };
  return (
    <BhFilterContainer>
      <div>
        <BhSearchInputWBG initialValue={filter.name} property="name" onChangeCallback={onNameFilterChange} placeholder={t("SHAREBOX.LIST.SEARCH_BY_NAME") as string} inputClasses={"max-w-xs"} />
      </div>
      <BhFilter
        title={t("FILE.TYPE")}
        values={filter.extensions
          .filter((ext) => ext.name !== "jpeg")
          .map((ext) => {
            return { ...extensionFilterValues[ext.name], ...{ selected: ext.selected } };
          })}
        selectedValues={filter.extensions.filter((v) => v.selected && v.name !== "jpeg").length}
        textProperty="name"
        onSelect={onFileExtensionFilterSelect}
        onReset={onFileExtensionFilterReset}
        isSelected={isFileExtensionSelected}
      />
      {tagsInDirAndFilteredTags.length > 0 && (
        <BhFilter
          title={t("GLOBAL.TAG")}
          values={tagsInDirAndFilteredTags}
          selectedValues={filter.tags && filter.tags.length}
          textProperty="text"
          onSelect={onTagFilterSelect}
          isSelected={isTagSelected}
          onReset={onTagFilterReset}
        />
      )}
    </BhFilterContainer>
  );
};

export default DirectoryFileListFilter;
