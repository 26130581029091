import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IFileEntity } from "@/model/files/IFileEntity";
import BhDivTableHeader from "@components/table/BhDivTableHeader";
import { IFileConfirmationFile } from "@/model/confirmations/IFileConfirmationFile";
import { fileConfirmationFilesSortSet, selectFileConfirmationFilesSort } from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";

const ConfirmationContainerActionsPreviewTableHeader: FC = () => {
  const sort = useAppSelector(selectFileConfirmationFilesSort);
  const dispatch = useAppDispatch();

  const tableColumnHeaders: Array<IBhTableHeader<IFileEntity>> = [{ id: 3, content: "Nimetus", classes: "flex-grow", field: "name", sortable: true }];

  const onTableHeaderClickCallback = (column: IBhTableHeader<IFileConfirmationFile>) => {
    if (column.field) dispatch(fileConfirmationFilesSortSet({ ...sort, property: column.field, reversed: column.field === sort.property ? !sort.reversed : sort.reversed }));
  };

  return <BhDivTableHeader columns={tableColumnHeaders} onClickCallback={onTableHeaderClickCallback} sortedBy={sort.property} reversed={sort.reversed} />;
};

export default ConfirmationContainerActionsPreviewTableHeader;
