import React, { FC, useEffect } from "react";
import { Core, WebViewerInstance } from "@pdftron/webviewer";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectFileAnnotationRelationsForFileEntityId } from "@/app/store/tasksSlice";
import { selectFileAnnotationForFileEntityId, selectTaskModalClosedWithTaskId, taskModalClosedWithTaskIdSet } from "@/app/store/fileAnnotationsSlice";

interface Props {
  fileEntityId: EntityId;
  instance?: WebViewerInstance;
}

const TaskSaveCallbackLogicForPdftron: FC<Props> = ({ fileEntityId, instance }) => {
  const fileAnnotationRelations = useAppSelector((state) => selectFileAnnotationRelationsForFileEntityId(state, fileEntityId));
  const fileAnnotation = useAppSelector((state) => selectFileAnnotationForFileEntityId(state, fileEntityId));

  const dispatch = useAppDispatch();

  const taskModalClosedWithTaskId = useAppSelector(selectTaskModalClosedWithTaskId);

  useEffect(() => {
    // If a task modal is closed
    if (taskModalClosedWithTaskId !== undefined) {
      callbackOnTaskClose(taskModalClosedWithTaskId);
    }
    dispatch(taskModalClosedWithTaskIdSet(undefined));
  }, [taskModalClosedWithTaskId]);

  // Uuenda Pdftroni xfdf'is taske, kui näiteks task salvestatakse või lisatakse uus task
  const callbackOnTaskClose = (taskId: EntityId) => {
    const annotationManager = instance?.Core.annotationManager;
    if (!annotationManager) return;

    let annotations = annotationManager.getAnnotationsList();

    // THESE TWO FUNCTIONS WERE MADE OBSOLETE AFTER PDFTRONCONTROLLER USEEFFECT
    // If you start creating a task but don't save it
    // if (taskId === -1) {
    //   const taskAnnotationsWithoutId = annotations.filter((annotation) => annotation.Subject === "Task" && !annotation.getCustomData("taskId"));
    //   annotationManager.deleteAnnotations(taskAnnotationsWithoutId);
    // }
    // If you remove a relation between task and annotation
    // const closedTaskFileAnnotationRelationExists = fileAnnotationRelations.find((fileAnnotationRelation) => fileAnnotationRelation.taskId === taskId);
    // if (taskId !== -1 && !closedTaskFileAnnotationRelationExists) {
    //   const removedAnnotation = annotations.filter((annotation) => annotation.Subject === "Task" && annotation.getCustomData("taskId") === taskId + "");
    //   annotationManager.deleteAnnotation(removedAnnotation);
    // }

    fileAnnotation?.removedAnnotationIds.forEach((removedAnnotationId) => {
      const removedAnnotation = annotations.filter((annotation) => annotation.Id === removedAnnotationId);
      annotationManager.deleteAnnotation(removedAnnotation);
    });

    // If you create a new task
    fileAnnotationRelations.forEach(async (fileAnnotationRelation) => {
      let correspondingAnnotation = annotations?.find((annotation) => annotation.Id === fileAnnotationRelation.annotationId) as Core.Annotations.StampAnnotation;
      if (correspondingAnnotation && taskId === fileAnnotationRelation.taskId) {
        correspondingAnnotation.setCustomData("taskId", fileAnnotationRelation.taskId.toString());
      }
    });

    // If you create a new task and immediately delete it
    const taskAnnotationsWithoutId = annotations.filter((annotation) => annotation.Subject === "Task" && !annotation.getCustomData("taskId"));
    annotationManager.deleteAnnotations(taskAnnotationsWithoutId);
  };

  return null;
};

export default TaskSaveCallbackLogicForPdftron;
