import React, { FC, useEffect } from "react";
import { fetchTaskAsync, saveTaskBoardTaskAsync, selectTaskByIdentifier } from "@/app/store/tasksSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { ITask } from "@/model/taskBoard/ITask";
import TaskModalBody from "@components/task/modal/TaskModalBody";
import TaskModalHeader from "@components/task/modal/TaskModalHeader";
import { setCurrentSidebarMenuItem } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";

interface Props {
  taskIdentifier: string;
}

const TaskContainerDedicated: FC<Props> = ({ taskIdentifier }) => {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const task = useAppSelector((state) => selectTaskByIdentifier(state, taskIdentifier));

  useDocumentTitle(task?.name);

  useEffect(() => {
    dispatch(fetchTaskAsync({ taskIdentifier: taskIdentifier, projectId: projectId }));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.TASKBOARDS));
  }, []);

  if (!task) {
    return null;
  }

  const saveTask = (changedObject: ITask) => {
    let changedTask = { ...task, ...changedObject };
    dispatch(saveTaskBoardTaskAsync({ projectId, task: changedTask }));
  };

  return (
    <div className="flex h-full w-full flex-col">
      <TaskModalHeader task={task} saveTaskCallback={saveTask} isDedicatedView={true} />
      <TaskModalBody task={task} saveTaskCallback={saveTask} />
    </div>
  );
};

export default TaskContainerDedicated;
