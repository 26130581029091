import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { selectProjectById } from "@/app/store/companiesSlice";
import { useAppSelector } from "@/app/hooks";
import { BauhubBannerType, ProjectSecurityLevel } from "@/model/IProject";
import BhBanner from "@components/banners/BhBanner";
import { useTranslation } from "react-i18next";
import { faShieldHalved } from "@fortawesome/pro-solid-svg-icons/faShieldHalved";
import { faShieldCheck } from "@fortawesome/pro-solid-svg-icons/faShieldCheck";

interface Props {
  projectId: EntityId;
}
const MailSettingsHighSecurityProjectWarning: FC<Props> = ({ projectId }) => {
  const { t } = useTranslation();
  const project = useAppSelector((state) => selectProjectById(state, projectId));

  if (!project || project.securityLevel === ProjectSecurityLevel.LOW) return null;

  let icon;
  switch (project.securityLevel) {
    case ProjectSecurityLevel.MEDIUM:
      icon = faShieldHalved;
      break;
    case ProjectSecurityLevel.HIGH:
      icon = faShieldCheck;
      break;
    default:
      icon = undefined;
  }

  const bannerBodyTranslationKey = `USER.INFO.NOTIFICATIONS.PROJECT_SECURITY.${project.securityLevel}`;

  return (
    <div className="mb-2">
      <BhBanner fullWidth={true} type={BauhubBannerType.WARNING} customIcon={icon}>
        <div dangerouslySetInnerHTML={{ __html: t(bannerBodyTranslationKey, { projectName: project.name }) as string }} />
      </BhBanner>
    </div>
  );
};

export default MailSettingsHighSecurityProjectWarning;
