import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyIdsSortedByName } from "@/app/store/companiesSlice";
import CompanyContainer from "@/views/home/projectList/CompanyContainer";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import { Trans } from "react-i18next";

const CompanyList: FC = () => {
  const companyIds = useAppSelector(selectCompanyIdsSortedByName);

  return (
    <div className="pb-10">
      {companyIds.map((id) => {
        return <CompanyContainer key={id} companyId={id} />;
      })}
      {companyIds.length < 1 && (
        <BhNoMatchesImage>
          <Trans>COMPANY.NO_COMPANIES</Trans>
        </BhNoMatchesImage>
      )}
    </div>
  );
};

export default CompanyList;
