import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { BhProgressBulletStatus } from "@/model/utilities/BhProgressBulletStatus";
import { useTranslation } from "react-i18next";

interface Props {
  stepNumber: number;
  status: BhProgressBulletStatus | undefined;
  stepHeader: string;
}

const BhProgressBullet: FC<Props> = ({ stepNumber, status, stepHeader }) => {
  const { t } = useTranslation();

  let classes = "";
  let textClasses = "";
  let content;
  let statusText = "";

  switch (status) {
    case BhProgressBulletStatus.CURRENT:
      classes = "bh-bg-deep-ocean text-white ";
      textClasses = "";
      content = stepNumber;
      break;
    case BhProgressBulletStatus.FINISHED:
      classes = "bh-bg-success-green-10 border bh-border-success-green-110 ";
      textClasses = "bh-text-success-green-110";
      content = <FontAwesomeIcon icon={faCheck} />;
      statusText = "PROGRESS_CONTAINER.STATUS.FINISHED";
      break;
    case BhProgressBulletStatus.UPCOMING:
      classes = "bh-border-deep-ocean-60 border ";
      textClasses = "bh-text-deep-ocean-60";
      content = stepNumber;
      break;
    case BhProgressBulletStatus.SKIPPED:
      classes = "bh-border-pigeon-60 bh-text-pigeon-50 border bh-bg-pigeon-20 ";
      textClasses = "bh-text-pigeon-50";
      content = <FontAwesomeIcon icon={faXmark} />;
      statusText = "PROGRESS_CONTAINER.STATUS.SKIPPED";
      break;
  }

  const showStatus = ![BhProgressBulletStatus.CURRENT, BhProgressBulletStatus.UPCOMING].some((s) => status === s);

  return (
    <div className="flex h-9 items-center gap-4">
      <div className={classNames(classes, textClasses, "flex h-8 w-8 place-content-center items-center rounded-full")}>{content}</div>
      <div className="flex flex-col items-start">
        <span className={classNames(status === BhProgressBulletStatus.UPCOMING && "bh-text-deep-ocean-60", "font-bold")}>{t(stepHeader)}</span>
        <span className={classNames(textClasses, "text-sm")}>{showStatus && t(statusText)}</span>
      </div>
    </div>
  );
};

export default React.memo(BhProgressBullet);
