import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  currentColor: string;
  diameter?: number;
  disabled?: boolean;
}

const BhColorPickerButton: FC<Props> = ({ currentColor, diameter, disabled }) => {
  const size = diameter ? "h-" + diameter + " w-" + diameter : "h-8 w-8";

  return (
    <div className="hover:bh-bg-pigeon-20 active:bh-bg-pigeon-40 mr-2 flex cursor-pointer flex-row items-center rounded p-1">
      <div className={classNames(size, "rounded-full", disabled && "opacity-60")} style={{ backgroundColor: currentColor }} />
      <FontAwesomeIcon icon={faCaretDown} className={classNames("bh-text-deep-ocean px-3", disabled && "bh-text-pigeon-60")} />
    </div>
  );
};

export default React.memo(BhColorPickerButton);
