import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { FastNavPlugin, SectionPlanesPlugin, StoreyViewsPlugin, Viewer, XKTLoaderPlugin } from "@xeokit/xeokit-sdk";
import React, { useEffect, useState } from "react";
import { faEraser } from "@fortawesome/pro-solid-svg-icons/faEraser";
import { defaultCameraActionForMobile, firstCameraCfg } from "@/views/home/project/detail/xeokit/helpers/XeokitHelper";
import XeokitResetTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitResetTool";
import { useTranslation } from "react-i18next";
import XeokitHideObjectTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitHideObjectTool";
import { BauhubXeokitTool, LoadedObjectInfo } from "@/views/home/project/detail/xeokit/XeokitWebViewer";
import { faListTree } from "@fortawesome/pro-regular-svg-icons/faListTree";
import XeokitMobileTreeModal from "@/views/home/project/detail/xeokit/mobile/modals/XeokitMobileTreeModal";
import XeokitStoreyElevationTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitStoreyElevationTool";
import XeokitMobilePropertiesModal from "@/views/home/project/detail/xeokit/mobile/modals/XeokitMobilePropertiesModal";
import XeokitObjectSelectionTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitObjectSelectionTool";
import BhIconWithLabelButton from "@components/buttons/BhIconWithLabelButton";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons/faEllipsisVertical";
import XeokitFitObjectsTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitFitObjectsTool";
import XeokitFirstPersonTool, { toggleFirstPersonMode } from "@/views/home/project/detail/xeokit/xeokitTools/XeokitFirstPersonTool";
import XeokitOrthoTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitOrthoTool";
import Xeokit2DTool from "@/views/home/project/detail/xeokit/xeokitTools/Xeokit2DTool";
import XeokitMobileMoreToolsModal from "@/views/home/project/detail/xeokit/mobile/modals/XeokitMobileMoreToolsModal";
import XeokitSectionTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitSectionTool";
import XeokitSectionsDropdown from "@/views/home/project/detail/xeokit/xeokitDropdowns/XeokitSectionsDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScissors } from "@fortawesome/pro-solid-svg-icons/faScissors";
import { IFileEntity } from "@/model/files/IFileEntity";
import XeokitFloorPlanTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitFloorPlanTool";
import XeokitElementContextMenu from "@/views/home/project/detail/xeokit/menus/XeokitElementContextMenu";
import XeokitFloorPlanMenuContainer from "@/views/home/project/detail/xeokit/menus/XeokitFloorPlanMenuContainer";
import { fetchFromDb, saveBase64ToDbAsFile } from "@/app/store/DB";
import { fetchCurrentUserAsync, selectCurrentUserLanguageForTranslation } from "@/app/store/userSlice";

const XeokitMobileViewer = React.memo(() => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguageForTranslation);
  const [onNewModelLoaded, setOnNewModelLoaded] = useState<number>(0);
  const [treeModalVisible, setTreeModalVisible] = useState<boolean>(false);
  const [propertiesModalVisible, setPropertiesModalVisible] = useState<boolean>(false);

  const [loadedModels, setLoadedModels] = useState<Array<LoadedObjectInfo>>([]);
  const [loadedStoreyElevations, setLoadedStoreyElevations] = useState<Array<LoadedObjectInfo>>([]);

  const [bauhubToolActive, setBauhubToolActive] = useState<BauhubXeokitTool>(BauhubXeokitTool.SELECT);
  const [threeDActive, setThreeDActive] = useState(true);
  const [orthoActive, setOrthoActive] = useState(false);
  const [firstPersonActive, setFirstPersonActive] = useState(true);
  const [floorPlanActive, setFloorPlanActive] = useState(false);

  const [sectionToolProps, setSectionToolProps] = useState<{ sections: number }>({ sections: 0 });
  const [sectionPlanesPluginObject, setSectionPlanesPluginObject] = useState<SectionPlanesPlugin>();

  const [viewer, setViewer] = useState<Viewer>();
  const [storeyViewsPluginObject, setStoreyViewsPluginObject] = useState<StoreyViewsPlugin>();
  const [viewerReady, setViewerReady] = useState(false);

  const [xktLoaderObject, setXktLoaderObject] = useState<XKTLoaderPlugin>();
  const [projectId, setProjectId] = useState<EntityId>();

  const dispatch = useAppDispatch();

  const ios = /iphone|ipod|ipad/i.test(navigator.userAgent);
  const android = /Android/i.test(navigator.userAgent);
  const memoryConfiguration = {
    dtxEnabled: true,
    maxGeometryBatchSize: ios || android ? 7000 : 5000000
  };

  useEffect(() => {
    /*let interval: string | number | NodeJS.Timeout | null | undefined = null;
    if (enableDebug) {
      interval = setInterval(() => {
        // @ts-ignore
        setCurrentStats({ frame: { fps: stats.frame.fps } });
      }, 1000);
    }*/

    dispatch(fetchCurrentUserAsync());
    loadViewer();

    return () => {
      viewer?.destroy();
      /*if (interval) {
        clearInterval(interval);
      }*/
    };
  }, []);

  useEffect(() => {
    const handler = async (
      ev: MessageEvent<{
        type: string;
        files: { currentFileEntityId: string; currentPartFileId: string; filePartsToLoadMap: any; filePartsToLoadInitial: any; fileEntitiesNameMap: any; xktBase64String: string };
        model?: any;
      }>
    ) => {
      const messageTypes = ["loadFiles", "loadModel", "unloadFile", "startLoaderAnimation", "endLoaderAnimation"];

      if (!messageTypes.includes(ev.data.type)) return;

      const type = ev.data.type;
      const files = ev.data.files;

      if (type === "startLoaderAnimation") {
        setFloorPlanActive(false);
        // @ts-ignore
        viewer && viewer.scene.canvas.spinner.processes++;
      }
      if (type === "endLoaderAnimation") {
        // @ts-ignore
        viewer && viewer.scene.canvas.spinner.processes--;
      }

      if (type === "loadModel") {
        const model = ev.data.model;
        if (model.fileEntityId && model.filePartsToLoadInitial && model.metaDataJsonBase64) {
          await saveBase64ToDbAsFile(model.fileEntityId + "metaDataJson", model.metaDataJsonBase64);
          const filePartsToLoadInitial = JSON.parse(model.filePartsToLoadInitial);
          const fileEntitiesNameMap = JSON.parse(model.fileEntitiesNameMap);
          loadModels(model.fileEntityId, filePartsToLoadInitial, fileEntitiesNameMap);
        }
      }

      if (typeof ev.data !== "object" || !ev.data.type || !ev.data.files) return;

      if (type === "loadFiles") {
        if (files.currentFileEntityId && files.currentPartFileId && files.filePartsToLoadMap && files.filePartsToLoadInitial && files.xktBase64String) {
          await saveBase64ToDbAsFile(files.currentPartFileId, files.xktBase64String);
          const fileEntitiesNameMap = JSON.parse(files.fileEntitiesNameMap);
          const filePartsToLoadMap = JSON.parse(files.filePartsToLoadMap);
          const filePartsToLoadInitial = JSON.parse(files.filePartsToLoadInitial);
          // @ts-ignore
          if (window.flutter_inappwebview) {
            // @ts-ignore
            window.flutter_inappwebview.callHandler(
              "partLoaded",
              files.currentFileEntityId,
              files.currentPartFileId,
              JSON.stringify(fileEntitiesNameMap),
              JSON.stringify(filePartsToLoadMap),
              JSON.stringify(filePartsToLoadInitial)
            );
          }

          const message = {
            handler: "partLoaded",
            fileEntityId: files.currentFileEntityId,
            partFileId: files.currentPartFileId,
            fileEntitiesNameMap: JSON.stringify(fileEntitiesNameMap),
            filePartsToLoadMap: JSON.stringify(filePartsToLoadMap),
            filePartsToLoadInitial: JSON.stringify(filePartsToLoadInitial)
          };
          window.postMessage(message, "*");
        }
      }

      if (type === "unloadFile") {
        if (files.currentFileEntityId) {
          unloadFile(files.currentFileEntityId);
        }
      }
    };

    window.addEventListener("message", handler);

    return () => {
      window.removeEventListener("message", handler);
    };
  }, [xktLoaderObject]);

  const modelLoadedCallback = async (fileEntitiesNameMap: any) => {
    if (viewer) {
      // Kui tahad mudeli osamudelite nimesid treeView asjale ette anda
      const metaModelIds = Object.keys(viewer.metaScene.metaModels);
      let loadedModelObjects = [];
      for (const metaModelId of metaModelIds) {
        const fileEntity = fileEntitiesNameMap.find((f: IFileEntity) => f.id + "" === metaModelId + "");
        if (!fileEntity) continue;
        setProjectId(fileEntity?.projectId);

        const metaModel = viewer.metaScene.metaModels[metaModelId];
        // @ts-ignore
        const rootObjectForModel = metaModel.rootMetaObjects.find((m) => m.type === "IfcProject");
        if (!rootObjectForModel) {
          console.log("No root object found with type IfcProject");
          return;
        }
        const loadedModel = {
          name: fileEntity?.name || rootObjectForModel.name,
          rootMetaObjectId: metaModel ? rootObjectForModel.id : fileEntity?.id + "",
          id: fileEntity.id + "",
          visible: true,
          fileEntityId: fileEntity.id,
          loaded: true
        };
        loadedModelObjects.push(loadedModel);
      }
      setLoadedModels(loadedModelObjects);
    }
  };

  const loadModel = async (fileEntityId: string, fileUrls: Array<any>, fileEntitiesNameMap: any, metaDataJsonUrl?: string) => {
    if (!xktLoaderObject) return;

    const sceneModel = xktLoaderObject.load({
      id: fileEntityId + "",
      manifest: { xktFiles: fileUrls, metaModelFiles: [metaDataJsonUrl] },
      reuseGeometries: true,
      globalizeObjectIds: true,
      excludeTypes: [],
      edges: true
    });

    await new Promise<void>((resolve) => {
      sceneModel.on("loaded", async function () {
        // @ts-ignore
        viewer && viewer.scene.canvas.spinner.processes--;
        resolve();
      });
    });
    modelLoadedCallback(fileEntitiesNameMap);
    setOnNewModelLoaded(onNewModelLoaded + 1);
  };

  const loadModels = async (fileEntityId: string, filePartsToLoadInitial: any, fileEntitiesNameMap: any) => {
    if (!xktLoaderObject) return;

    const partFileIds = filePartsToLoadInitial[fileEntityId];
    let fileUrls = [];
    for (const partFileId of partFileIds) {
      const dbFile = await fetchFromDb(partFileId);
      const dbFileUrl = dbFile && URL.createObjectURL(dbFile.content);
      fileUrls.push(dbFileUrl);
    }

    const metaDataJsonFile = await fetchFromDb(fileEntityId + "metaDataJson");
    const metaDataJsonFileUrl = metaDataJsonFile && URL.createObjectURL(metaDataJsonFile.content);

    await loadModel(fileEntityId, fileUrls, fileEntitiesNameMap, metaDataJsonFileUrl);

    for (const url of fileUrls) {
      url && URL.revokeObjectURL(url);
    }

    setOnNewModelLoaded(onNewModelLoaded + 1);
  };

  const unloadFile = (fileEntityId: string) => {
    if (!viewer) return;

    const existingModel = loadedModels.find((m) => m.id + "" === fileEntityId + "");
    if (existingModel) {
      setLoadedModels(loadedModels.filter((m) => m.id + "" !== fileEntityId + ""));
    }

    const modelIds = Object.keys(viewer.scene.models).filter((key) => key.startsWith(fileEntityId + ""));

    for (const modelId of modelIds) {
      viewer.scene.models[modelId].destroy();
    }

    setOnNewModelLoaded(onNewModelLoaded + 1);
  };

  const loadViewer = () => {
    const viewerConfig = {
      canvasId: "xeokitCanvas",
      antialias: true,
      spinnerElementId: "bauhubSpinnerElement",
      units: "millimeters",
      scale: 1000,
      dtxEnabled: memoryConfiguration.dtxEnabled
    };

    // @ts-ignore
    const viewer = new Viewer(viewerConfig);
    setViewerReady(true);

    viewer.camera.eye = firstCameraCfg.eye;
    viewer.camera.look = firstCameraCfg.look;
    viewer.camera.up = firstCameraCfg.up;

    const xktLoader = new XKTLoaderPlugin(viewer, {
      maxGeometryBatchSize: memoryConfiguration.maxGeometryBatchSize
      // dataSource: new XKTDefaultDataSource()
    });

    xktLoader.globalizeObjectIds = true;

    //------------------------------------------------------------------------------------------------------------------
    // Add a SectionPlanesPlugin - we'll use this to create cross-section planes
    //------------------------------------------------------------------------------------------------------------------

    const sectionPlanesPluginObject = new SectionPlanesPlugin(viewer, {});
    setSectionPlanesPluginObject(sectionPlanesPluginObject);

    const fastNavPlugin = new FastNavPlugin(viewer, {
      hideEdges: true,
      hideSAO: true,
      hidePBR: false,
      hideTransparentObjects: false,
      scaleCanvasResolution: false,
      scaleCanvasResolutionFactor: 0.6,
      delayBeforeRestore: true,
      delayBeforeRestoreSeconds: 0.4
    });

    const storeyViewsPluginObject = new StoreyViewsPlugin(viewer, {});
    setStoreyViewsPluginObject(storeyViewsPluginObject);

    setViewer(viewer);
    setXktLoaderObject(xktLoader);

    defaultCameraActionForMobile(viewer);

    // Let the mobile app know that we are ready to receive messages
    // @ts-ignore
    if (window.flutter_inappwebview) {
      // @ts-ignore
      window.flutter_inappwebview.callHandler("viewerReady", true);
      const message = {
        handler: "viewerReady"
      };
      window.postMessage(message, "*");
    }
  };

  const closeFloorplan = () => {
    if (!viewer) return;
    toggleFirstPersonMode(viewer, true, setFirstPersonActive);
    setFloorPlanActive(false);
  };

  return (
    <>
      <div className="absolute flex h-full w-full flex-col overflow-hidden">
        {viewer && projectId && (
          <div className="flex-2">
            <XeokitFloorPlanMenuContainer
              isOpen={floorPlanActive}
              viewer={viewer}
              projectId={projectId}
              loadedStoreyElevations={loadedStoreyElevations}
              isMobile={true}
              storeyViewsPluginObject={storeyViewsPluginObject}
              setFirstPersonActive={setFirstPersonActive}
              setToolActive={setFloorPlanActive}
            />
          </div>
        )}
        <div className="relative flex flex-1">
          <canvas id="xeokitCanvas" className="bh-bg-3d-background absolute h-full w-full overflow-hidden"></canvas>
          {/*enableDebug && <div className="opacity-50 visible block h-0 w-0 text-sm">
            <pre>dtxEnabled: {!!memoryConfiguration.dtxEnabled ? 1 : 0}</pre>
            <pre>maxGeoBatchSize: {memoryConfiguration.maxGeometryBatchSize}</pre>
            <pre>fps: {currentStats.frame.fps}</pre>
          </div>*/}
          <div id="bauhubSpinnerElement" className="bh-bg-smoke absolute flex h-full w-full flex-row items-center justify-center p-6 opacity-50">
            <div className="logo-loading-block-container h-10 w-10">
              <div className="logo-loading-block logo-loading-static-block"></div>
              <div className="logo-loading-block logo-loading-animated-block"></div>
            </div>
          </div>
        </div>
        {onNewModelLoaded > 0 && (
          <div className="bh-shadow bh-bg-white max-w-660px fixed left-1/2 bottom-6 flex -translate-x-1/2 flex-row rounded-xl">
            <div className="flex items-center justify-center gap-x-2 p-2">
              <XeokitResetTool viewer={viewer} setBauhubToolActive={setBauhubToolActive} isMobile={true} />
              <BhIconWithLabelButton icon={faListTree} buttonProps={{ onClick: () => setTreeModalVisible(!treeModalVisible) }} isActive={treeModalVisible}>
                {t("BIM.STRUCTURE", { lng: currentUserLanguage })}
              </BhIconWithLabelButton>
              <BhIconWithLabelButton
                icon={faEraser}
                buttonProps={{ onClick: () => setBauhubToolActive(bauhubToolActive === BauhubXeokitTool.HIDE ? BauhubXeokitTool.SELECT : BauhubXeokitTool.HIDE) }}
                isActive={bauhubToolActive === BauhubXeokitTool.HIDE}
              >
                {t("BIM.TOOL.HIDE", { lng: currentUserLanguage })}
              </BhIconWithLabelButton>
              {viewer && projectId && <XeokitFloorPlanTool toolActive={floorPlanActive} setToolActive={setFloorPlanActive} viewer={viewer} isMobile={true} />}
              {/*{viewer && <XeokitMeasureTool onModelsLoaded={onNewModelLoaded > 0} toolActive={bauhubToolActive} setToolActive={setBauhubToolActive} viewer={viewer} isMobile={true} />}*/}
              <BhDropdown
                button={
                  <BhIconWithLabelButton icon={faEllipsisVertical}>
                    {(!threeDActive || orthoActive || firstPersonActive) && (
                      <div className="absolute top-1.5 right-1.5">{<div className="bh-bg-dark-jungle bh-border-raised-white h-3.5 w-3.5 rounded-full border-2"></div>}</div>
                    )}
                    {t("BIM.TOOL.DROPDOWN", { lng: currentUserLanguage })}
                  </BhIconWithLabelButton>
                }
                menu={
                  <XeokitMobileMoreToolsModal>
                    <ul className="text-lg">
                      <Xeokit2DTool
                        toolActive={!threeDActive}
                        setToolActive={setThreeDActive}
                        firstPersonActive={firstPersonActive}
                        orthoActive={orthoActive}
                        closeFloorplan={closeFloorplan}
                        viewer={viewer}
                        isMobile={true}
                      />
                      <XeokitOrthoTool toolActive={orthoActive} setToolActive={setOrthoActive} closeFloorplan={closeFloorplan} disabled={!threeDActive} viewer={viewer} isMobile={true} />
                      <XeokitFitObjectsTool viewer={viewer} isMobile={true} />
                      <XeokitFirstPersonTool toolActive={firstPersonActive} setToolActive={setFirstPersonActive} threeDActive={threeDActive} viewer={viewer} isMobile={true} />
                    </ul>
                  </XeokitMobileMoreToolsModal>
                }
                position={BhDropdownPositionEnum.TOP_LEFT}
              />
            </div>
          </div>
        )}
        {/* Functions and initalization for storey map and logic */}
        {viewer && <XeokitStoreyElevationTool viewer={viewer} onNewModelLoaded={onNewModelLoaded} setLoadedStoreyElevations={setLoadedStoreyElevations} />}
        {/* Context menu for right click on object */}
        {viewer && <XeokitObjectSelectionTool onModelsLoaded={true} bauhubToolActive={bauhubToolActive} viewer={viewer} setPropertiesVisible={setPropertiesModalVisible} />}
        {viewer && (
          <XeokitElementContextMenu
            viewer={viewer}
            bauhubToolActive={bauhubToolActive}
            isMobile={true}
            onModelsLoaded={true}
            setPropertiesVisible={setPropertiesModalVisible}
            setTreeVisible={setTreeModalVisible}
          />
        )}
        {viewer && (
          <XeokitSectionTool
            onModelsLoaded={onNewModelLoaded > 0}
            isMobile={true}
            bauhubToolActive={bauhubToolActive}
            setBauhubToolActive={setBauhubToolActive}
            sectionToolProps={sectionToolProps}
            setSectionToolProps={setSectionToolProps}
            viewer={viewer}
            sectionPlanesPluginObject={sectionPlanesPluginObject}
            setSectionPlanesPluginObject={setSectionPlanesPluginObject}
          />
        )}
        {viewer && <XeokitHideObjectTool onModelsLoaded={onNewModelLoaded > 0} bauhubToolActive={bauhubToolActive} viewer={viewer} />}
        {viewer && sectionPlanesPluginObject && sectionToolProps.sections > 0 && (
          <div className="bh-bg-white bh-shadow absolute right-4 bottom-28 flex rounded-lg border p-2">
            <BhDropdown
              button={
                <div className="flex flex-row items-center justify-center">
                  <div className="bh-bg-xeokit-pink flex h-8 w-8 items-center justify-center rounded-full">
                    <FontAwesomeIcon className="bh-text-white" icon={faScissors} />
                  </div>
                </div>
              }
              menu={<XeokitSectionsDropdown sectionToolProps={sectionToolProps} setSectionToolProps={setSectionToolProps} viewer={viewer} sectionPlanes={sectionPlanesPluginObject} />}
              position={BhDropdownPositionEnum.TOP_LEFT}
            />
          </div>
        )}
        {/*MobileTreeModal*/}
        {viewerReady && viewer && (
          <XeokitMobileTreeModal
            viewer={viewer}
            treeModalVisible={treeModalVisible}
            setTreeModalVisible={setTreeModalVisible}
            loadedModels={loadedModels}
            loadedStoreyElevations={loadedStoreyElevations}
            setLoadedStoreyElevations={setLoadedStoreyElevations}
          />
        )}
        {viewer && propertiesModalVisible && <XeokitMobilePropertiesModal viewer={viewer} setPropertiesModalVisible={setPropertiesModalVisible} />}
      </div>
    </>
  );
});

export default XeokitMobileViewer;
