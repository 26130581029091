import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCurrentProjectName } from "@/app/store/project/projectSlice";

const useDocumentTitle = (titleTranslationCode?: string) => {
  const { t } = useTranslation();
  const projectName = useSelector(selectCurrentProjectName);

  useEffect(() => {
    if (titleTranslationCode && titleTranslationCode.length > 0) {
      const title = t(titleTranslationCode);
      document.title = title + (projectName ? " - " + projectName : "") + " | Bauhub";
    } else {
      document.title = "Bauhub";
    }
  }, [titleTranslationCode, projectName]);

  return;
};

export { useDocumentTitle };
