import React, { FC } from "react";
import { UserEmailSettingsType } from "@/model/profile/IUserEmailSettings";
import { Trans } from "react-i18next";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import BhSeparatorMarginless from "@components/separator/BhSeparatorMarginless";
import NotificationSettingsRow from "@/views/home/profile/ProfileTabs/PreferenceSettingsRow";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { isCurrentUserSuperUser, saveCurrentUserInfoAsync, selectCurrentUserInfo } from "@/app/store/userSlice";
import BhToggleButtonBar, { IBhToggleButtonBarItem } from "@components/toggle/BhToggleButtonBar";
import { IUserInfo, Theme } from "@/model/IUserInfo";

const ProfilePreferences: FC = () => {
  const isSuperUser = useAppSelector(isCurrentUserSuperUser);
  const userInfo = useAppSelector(selectCurrentUserInfo);
  const dispatch = useAppDispatch();

  const themeOptions: Array<IBhToggleButtonBarItem> = Object.keys(Theme).map((theme) => {
    return { value: theme, text: theme };
  });

  const handleThemeChange = async (changedValue: IUserInfo) => {
    const changedCurrentUserInfo = { ...userInfo, ...changedValue };
    dispatch(saveCurrentUserInfoAsync(changedCurrentUserInfo));
  };

  return (
    <BhScrollableBody>
      <div className="flex w-full flex-row justify-center px-8 pt-10">
        <div className="flex w-full max-w-[800px] flex-row">
          <div className="grid grid-cols-7 content-center gap-y-6 xl:grid-cols-8">
            <div className="col-span-4">
              <h2>
                <Trans>USER.PREFERENCES.GENERAL</Trans>
              </h2>
            </div>
            <NotificationSettingsRow settingsBody={<Trans>USER.INFO.PDFTRON_CAD</Trans>} settingsProperty="openPdftronCad" />
            {isSuperUser && (
              <div className="col-span-7 xl:grid-cols-8">
                <BhToggleButtonBar label={"Theme (superadmin preference)"} property="theme" items={themeOptions} onClickAction={handleThemeChange} selected={userInfo.theme} />
              </div>
            )}
            <div className="col-span-7 mt-4 xl:grid-cols-8">
              <BhSeparatorMarginless />
            </div>
            <h2 className="col-span-4">
              <Trans>SITE_TITLE_NOTIFICATIONS</Trans>
            </h2>
            <NotificationSettingsRow
              settingsHeader={<Trans>USER.INFO.DAILY_MAIL</Trans>}
              settingsBody={<Trans>USER.INFO.DAILY_MAIL_TOOLTIP</Trans>}
              settingsProperty="dailyMailEnabled"
              settingsType={UserEmailSettingsType.DISABLE_IN_DAILY_EMAIL}
            />
            <NotificationSettingsRow
              settingsHeader={<Trans>USER.INFO.TASK_MAIL_ENABLED</Trans>}
              settingsBody={<Trans>USER.INFO.TASK_MAIL_ENABLED_TOOLTIP</Trans>}
              settingsProperty="taskMailEnabled"
              settingsType={UserEmailSettingsType.DISABLE_IN_TASK_EMAIL}
            />
            <NotificationSettingsRow
              settingsHeader={<Trans>USER.INFO.SIGNING_AND_CONFIRMATION_MAIL_ENABLED</Trans>}
              settingsBody={<Trans>USER.INFO.SIGNING_AND_CONFIRMATION_MAIL_ENABLED_TOOLTIP</Trans>}
              settingsProperty="signingAndConfirmationMailEnabled"
              settingsType={UserEmailSettingsType.DISABLE_IN_SIGNING_AND_CONFIRMATION_EMAIL}
            />
            <NotificationSettingsRow
              settingsHeader={<Trans>USER.INFO.ALL_MAIL_ENABLED</Trans>}
              settingsBody={<Trans>USER.INFO.ALL_MAIL_ENABLED_TOOLTIP</Trans>}
              settingsProperty="otherMailEnabled"
              settingsType={UserEmailSettingsType.DISABLE_IN_OTHER_EMAIL}
            />
          </div>
        </div>
      </div>
    </BhScrollableBody>
  );
};

export default ProfilePreferences;
