import React, { FC, ReactNode } from "react";

interface Props {
  body: ReactNode;
  children: ReactNode;
  delayShow?: number;
}

// @ts-ignore
const BhTooltip: FC<Props> = (props) => {
  const text = props.body?.toString();
  const delayShowValue = props.delayShow || 0;

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      // @ts-ignore
      if (props?.children?.type?.render) {
        // @ts-ignore
        return React.cloneElement(child, { "data-tooltip-id": "bh-tooltip", "data-tooltip-content": text, "data-tooltip-delay-show": delayShowValue });
      } else {
        return (
          <div className="inline-flex" data-tooltip-id="bh-tooltip" data-tooltip-content={text} data-tooltip-delay-show={delayShowValue}>
            {child}
          </div>
        );
      }
    }
    return child;
  });

  return childrenWithProps;
};

export default BhTooltip;
