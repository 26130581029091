import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { deleteAct, fetchAct, fetchActV2, fetchPartyActs, saveAct, saveActSigner, saveActSignerGroup, saveNewActExtraWorkContainer, saveNewActForParty } from "@/api/actAPI";
import { RootState } from "@/app/store";
import { IAct } from "@/model/IAct";
import { actWorksAdded } from "@/app/store/actWorksSlice";
import { ISigner } from "@/model/ISigner";
import { ISignerGroup } from "@/model/ISignerGroup";
import { IContractWorkContainer } from "@/model/contracts/IContractWorkContainer";
import { IActWork } from "@/model/IActWork";

const actsAdapter = createEntityAdapter<IAct>();

const actsInitialState = actsAdapter.getInitialState<{
  status: BhStateStatusType;
}>({
  status: BhStateStatusType.INITIAL
});

export const fetchPartyActsAsync = createAsyncThunk("acts/fetchPartyActs", async (dto: { partyId: EntityId; projectId: EntityId }) => {
  return fetchPartyActs(dto.projectId, dto.partyId);
});

export const fetchActAsyncV2 = createAsyncThunk("acts/fetchAct/v2", async (actId: EntityId, { dispatch }) => {
  const result = await fetchActV2(actId);
  dispatch(actWorksAdded(result.works));
  return { ...result, works: [] };
});

export const fetchActAsync = createAsyncThunk("acts/fetchAct", async (actId: EntityId, { dispatch }) => {
  const result = await fetchAct(actId);
  dispatch(actWorksAdded(result.works));
  return { ...result, works: [] };
});

export const saveNewActForPartyAsync = createAsyncThunk("acts/saveNewActForParty", async (dto: { projectId: EntityId; workGroupId: EntityId }) => {
  return saveNewActForParty(dto);
});

export const saveActAsync = createAsyncThunk("acts/saveAct", async (act: IAct) => {
  return saveAct(act);
});

export const deleteActAsync = createAsyncThunk("acts/deleteAct", async (actId: EntityId) => {
  return deleteAct(actId);
});

export const saveNewActExtraWorkContainerAsync = createAsyncThunk("acts/saveActExtraWorkContainer", async (container: { contractWorkContainer: IContractWorkContainer; actId: EntityId }) => {
  const { contractWorkContainer, actId } = container;
  return saveNewActExtraWorkContainer(contractWorkContainer, actId);
});

export const saveActSignerAsync = createAsyncThunk("acts/saveActSigner", async (signer: ISigner) => {
  return saveActSigner(signer);
});

export const saveActSignerGroupAsync = createAsyncThunk("acts/saveActSignerGroup", async (actSignerGroup: ISignerGroup) => {
  return saveActSignerGroup(actSignerGroup);
});

const actsSlice = createSlice({
  name: "acts",
  initialState: actsInitialState,
  reducers: {
    actAdded: actsAdapter.addOne,
    actUpdated: actsAdapter.upsertOne
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPartyActsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchPartyActsAsync.fulfilled, (state, action: PayloadAction<Array<IAct>>) => {
        state.status = BhStateStatusType.SUCCESS;
        actsAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchActAsyncV2.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchActAsyncV2.fulfilled, (state, action: PayloadAction<IAct>) => {
        state.status = BhStateStatusType.SUCCESS;
        actsAdapter.upsertOne(state, action.payload);
      })
      .addCase(fetchActAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchActAsync.fulfilled, (state, action: PayloadAction<IAct>) => {
        state.status = BhStateStatusType.SUCCESS;
        actsAdapter.upsertOne(state, action.payload);
      })
      .addCase(saveNewActForPartyAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveNewActForPartyAsync.fulfilled, (state, action: PayloadAction<IAct>) => {
        state.status = BhStateStatusType.SUCCESS;
        actsAdapter.upsertOne(state, action.payload);
      })
      .addCase(saveActAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveActAsync.fulfilled, (state, action: PayloadAction<IAct>) => {
        state.status = BhStateStatusType.SUCCESS;
        actsAdapter.upsertOne(state, action.payload);
      })
      .addCase(deleteActAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(deleteActAsync.fulfilled, (state, action: PayloadAction<IAct>) => {
        state.status = BhStateStatusType.SUCCESS;
        const { id, deleted } = action.payload;
        if (deleted) actsAdapter.removeOne(state, id);
      })
      .addCase(saveNewActExtraWorkContainerAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveNewActExtraWorkContainerAsync.fulfilled, (state, action: PayloadAction<Array<IActWork>>) => {
        state.status = BhStateStatusType.SUCCESS;
        const updatedActWorks = action.payload;
        const { actId } = updatedActWorks[0];
        const currentAct = actId && actsAdapter.getSelectors().selectById(state, actId);
        if (currentAct) {
          updatedActWorks.forEach((actWork) => currentAct.works.push(actWork));
          actsAdapter.upsertOne(state, currentAct);
        }
      })
      .addCase(saveActSignerAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveActSignerAsync.fulfilled, (state, action: PayloadAction<ISigner>) => {
        state.status = BhStateStatusType.SUCCESS;
        const updatedSigner = action.payload;
        const act = state.entities[updatedSigner.resourceId];
        const signerGroup = act?.actSignerGroups.find((group) => group.id === updatedSigner.actSignerGroupId);
        const signerExists = signerGroup?.signers?.some((signer) => signer.id === updatedSigner.id);
        const updatedGroupSigners = signerExists
          ? signerGroup?.signers.map((signer) => {
              return signer.id === updatedSigner.id ? updatedSigner : signer;
            })
          : [...(signerGroup?.signers || []), { ...updatedSigner }];
        const updatedSignerGroups = act?.actSignerGroups.map((group) => {
          return group.id === signerGroup?.id ? { ...group, signers: updatedGroupSigners || [] } : group;
        });
        const updatedAct = act && { ...act, actSignerGroups: updatedSignerGroups || [] };
        updatedAct && actsAdapter.upsertOne(state, updatedAct);
      })
      .addCase(saveActSignerGroupAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveActSignerGroupAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const updatedSignerGroup = action.payload;
        const act = state.entities[updatedSignerGroup.actId];
        const groupExists = act?.actSignerGroups?.some((group) => group.id === updatedSignerGroup.id);
        const updatedSignerGroups = groupExists
          ? act?.actSignerGroups.map((group) => {
              return group.id === updatedSignerGroup.id ? updatedSignerGroup : group;
            })
          : [...(act?.actSignerGroups || []), updatedSignerGroup];
        act && updatedSignerGroups && actsAdapter.upsertOne(state, { ...act, actSignerGroups: updatedSignerGroups });
      });
  }
});

export const { actUpdated } = actsSlice.actions;

export const { selectAll: selectAllActs, selectById: selectActById, selectIds: selectActIds, selectEntities: selectActsEntities } = actsAdapter.getSelectors((state: RootState) => state.acts);

export const selectActId = (state: RootState, id: EntityId) => id;
//TODO see n siin on placeholder, sest createSelectoris tahan kasutada kahte id-d... äkki saab kuidagi ilusamalt teha seda?
export const selectActSignerGroupId = (state: RootState, n: EntityId, id: EntityId) => id;

export const selectActSignerGroupById = createSelector([selectAllActs, selectActId, selectActSignerGroupId], (acts, actId, groupId) => {
  const act = acts.find((act) => act.id === actId);
  const group = act?.actSignerGroups.find((group) => group.id === groupId && !group.deleted);
  return group;
});

export const selectPartyEntityId = (state: RootState, partyId: EntityId) => partyId;
export const selectPartyActId = (state: RootState, partyId: EntityId, actId: EntityId) => actId;

export const selectActIdsForPartyId = createSelector([selectAllActs, selectPartyEntityId], (acts, partyId) => {
  return acts
    .filter((a) => a.workGroupId === partyId && !a.deleted)
    .sort((a, b) => (new Date(a.created) < new Date(b.created) ? 1 : -1))
    .map((a) => a.id);
});

export const selectIsLatestActForParty = createSelector([selectAllActs, selectPartyEntityId, selectPartyActId], (acts, partyId, actId) => {
  const latestAct = acts.filter((a) => a.workGroupId === partyId && !a.deleted).reduce((a, b) => (new Date(a.created) > new Date(b.created) ? a : b));
  return latestAct.id === actId;
});

export const selectActsStatus = (state: RootState) => state.acts.status;

export default actsSlice.reducer;
