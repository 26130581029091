import React, { FC, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { Trans, useTranslation } from "react-i18next";
import { EntityId } from "@reduxjs/toolkit";
import BhInputLabel from "@components/input/BhInputLabel";
import BhChildComponentWithFetchValues from "@components/input/BhChildComponentWithFetchValues";
import { fetchRelatedUsersInProjectWithSearch } from "@/api/projectAPI";
import { convertSimpleValidUserAuthorityToUser } from "@/model/ISimpleValidUserAuthority";
import BhSearchInputWithUserDropdown from "@components/input/BhSearchInputWithUserDropdown";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectActById } from "@/app/store/actsSlice";
import { IUser } from "@/model/IUser";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhChipWithEmail from "@/views/home/project/detail/directory/directoryShareModals/BhChipWithEmail";
import BhTagListContainer from "@components/tags/BhTagListContainer";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhTextareaStackedLabel from "@components/input/BhTextareaStackedLabel";
import useHandleFormSubmit from "@/utilities/hooks/useHandleFormSubmit";
import { IFileEntity } from "@/model/files/IFileEntity";
import { selectProjectById } from "@/app/store/companiesSlice";
import BhFileCardMedium from "@components/cards/BhFileCardMedium";
import { fetchFileEntity } from "@/api/fileAPI";
import { BhSectionMessageError } from "@components/sectionMessage/BhSectionMessages";
import { formatFileSize } from "@/utilities/jsUtilities";
import { fullPageLoadingSet, toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { sendActFiles } from "@/api/actAPI";

interface Props {
  actId: EntityId;
  closeModal: Function;
}

// TODO: lisa võimalus pasteda väärtusi

const SendActWithEmailModal: FC<Props> = ({ actId, closeModal }) => {
  const { t } = useTranslation();
  const act = useAppSelector((state) => selectActById(state, actId));
  const project = useAppSelector((state) => act && selectProjectById(state, act.projectId));
  const [emails, setEmails] = useState<Array<string>>([]);
  const [attachments, setAttachments] = useState<Array<IFileEntity>>([]);
  const [digidocFileEntity, setDigidocFileEntity] = useState<IFileEntity>();
  const [sizeOverTheLimit, setSizeOverTheLimit] = useState(false);
  const handleFormSubmit = useHandleFormSubmit<{ subject: string; message: string }>();
  const dispatch = useAppDispatch();
  const SIZE_LIMIT = 7000000;

  useEffect(() => {
    if (act?.attachments) {
      setAttachments(act.attachments);
    }
    if (act?.digiDocContainerId) {
      fetchFileEntity(act.digiDocContainerId).then((resultFileEntity) => {
        setDigidocFileEntity(resultFileEntity);
      });
    }
  }, []);

  useEffect(() => {
    const size = attachments.reduce((acc, obj) => acc + obj.size, 0);
    setSizeOverTheLimit(size > SIZE_LIMIT);
  }, [attachments]);

  if (!act) return null;

  const dtoTemplate = {
    addresses: "",
    subject: project?.name + ", " + act.name,
    message: "",
    attachmentIds: [],
    actId: act.id,
    projectId: act.projectId
  };

  const addUser = (addedUser: IUser) => {
    setEmails([...emails, addedUser.username]);
  };

  const removeUser = (emailAddress: string) => {
    setEmails(emails.filter((email) => email !== emailAddress));
  };

  const removeFile = (fileEntity: IFileEntity) => {
    setAttachments(attachments.filter((attachment) => attachment.id !== fileEntity.id));
  };

  const showValueCondition = (user: IUser) => {
    return !emails.some((email) => email === user.username);
  };

  const handleSubmit = (formValues: { subject: string; message: string }) => {
    const { subject, message } = formValues;
    if (subject.length < 1 || message.length < 1) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.WARNING,
          disappear: true,
          translateCode: "GLOBAL.FILL_REQUIRED_FIELDS"
        })
      );
      return;
    }

    const final = { ...dtoTemplate, subject, message, attachmentIds: attachments.map((a) => a.id), addresses: emails.join(",") };
    dispatch(fullPageLoadingSet(true));
    sendActFiles(final)
      .then(() => {
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.SUCCESS,
            disappear: true,
            translateCode: "ACT.EMAIL_SENT"
          })
        );
      })
      .finally(() => dispatch(fullPageLoadingSet(false)));
    closeModal();
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={() => closeModal()}
      size="3xl"
      header={
        <h2>
          <Trans>ACT.SEND_EMAIL.TITLE</Trans>
        </h2>
      }
      children={
        <div className="px-8 py-6">
          <BhInputLabel>
            <Trans>ACT.SEND_EMAIL.TO</Trans>
          </BhInputLabel>
          <BhChildComponentWithFetchValues
            fetchValues={() => {
              return fetchRelatedUsersInProjectWithSearch(act.projectId).then((users) => {
                return users.map((simpleValidUser) => convertSimpleValidUserAuthorityToUser(simpleValidUser));
              });
            }}
            child={<BhSearchInputWithUserDropdown values={[]} onSelect={addUser} showValueCondition={showValueCondition} placeholder="Otsi kasutajat" />}
          />
          <div className="py-4">
            <BhTagListContainer>
              {emails.map((email) => (
                <BhChipWithEmail email={email} key={email} onRemove={removeUser} />
              ))}
            </BhTagListContainer>
          </div>

          <form className="space-y-2 pb-1" id="emailForm" onSubmit={handleFormSubmit((emailContent) => handleSubmit(emailContent))}>
            <BhInputStackedLabel initialValue={dtoTemplate.subject} property={"subject"} label={t("ACT.SEND_EMAIL.SUBJECT")} placeholder={t("ACT.SEND_EMAIL.SUBJECT") as string} />
            <BhTextareaStackedLabel label={t("ACT.SEND_EMAIL.MESSAGE")} placeholder={t("ACT.SEND_EMAIL.MESSAGE")} initialValue="" property={"message"} rows={4} />
          </form>

          <BhInputLabel>
            <Trans>TASK.ATTACHMENTS</Trans>
          </BhInputLabel>

          {sizeOverTheLimit && (
            <BhSectionMessageError>
              <Trans>ACT.OVER_SIZE_LIMIT</Trans>
            </BhSectionMessageError>
          )}

          <div className="flex flex-row flex-wrap">
            <BhFileCardMedium fileEntity={{ name: act.name + ".pdf" } as IFileEntity} disabled={true} />
            {digidocFileEntity && <BhFileCardMedium fileEntity={digidocFileEntity} openInNewTab={true} />}
            {attachments.map((fileEntity) => (
              <BhFileCardMedium fileEntity={fileEntity} deleteCallback={removeFile} openInNewTab={true}>
                {sizeOverTheLimit && formatFileSize(fileEntity.size)}
              </BhFileCardMedium>
            ))}
          </div>
        </div>
      }
      footer={
        <div className="flex flex-row items-center">
          <BhTextOnlyButton buttonProps={{ onClick: closeModal }}>{t("GLOBAL.CANCEL")}</BhTextOnlyButton>
          <BhPrimaryButton buttonProps={{ submitForm: "emailForm", disabled: emails.length === 0 || sizeOverTheLimit }}>{t("GLOBAL.SEND")}</BhPrimaryButton>
        </div>
      }
    />
  );
};

export default SendActWithEmailModal;
