import React, { Dispatch } from "react";
import { Viewer } from "@xeokit/xeokit-sdk";
import { BauhubXeokitTool } from "@/views/home/project/detail/xeokit/XeokitWebViewer";
import { defaultCameraActionForMobile, defaultCameraPosition, firstCameraCfg } from "@/views/home/project/detail/xeokit/helpers/XeokitHelper";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faArrowRotateLeft } from "@fortawesome/pro-regular-svg-icons/faArrowRotateLeft";
import BhIconWithLabelButton from "@components/buttons/BhIconWithLabelButton";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUserLanguageForTranslation } from "@/app/store/userSlice";

interface Props {
  setBauhubToolActive: Dispatch<BauhubXeokitTool>;
  viewer?: Viewer;
  isMobile?: boolean;
}

const XeokitResetTool = React.memo<Props>(({ setBauhubToolActive, viewer, isMobile }) => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguageForTranslation);
  const resetCamera = () => {
    if (!viewer) return;

    viewer.camera.eye = firstCameraCfg.eye;
    viewer.camera.look = firstCameraCfg.look;
    viewer.camera.up = firstCameraCfg.up;
    defaultCameraPosition(viewer, "perspective");
    if (isMobile) {
      defaultCameraActionForMobile(viewer);
    }
  };

  const resetViewer = () => {
    if (!viewer) return;

    setBauhubToolActive(BauhubXeokitTool.SELECT);

    viewer.fire("reset", true);

    resetCamera();
  };

  if (isMobile) {
    return (
      <BhIconWithLabelButton icon={faArrowRotateLeft} buttonProps={{ onClick: resetViewer }}>
        {t("BIM.TOOL.RESET", { lng: currentUserLanguage })}
      </BhIconWithLabelButton>
    );
  }
  return (
    <BhTextOnlyButton icon={faArrowRotateLeft} buttonProps={{ onClick: resetViewer }}>
      {t("BIM.TOOL.RESET_MODEL")}
    </BhTextOnlyButton>
  );
});

export default XeokitResetTool;
