import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { IChangeDailyMailFolderSelectionRequestBody, IUserDailyMailProjectFileEntityRelation, IUserEmailSetting, UserEmailSettingsType } from "@/model/profile/IUserEmailSettings";
import { IFileDirectoryDTO } from "@/model/utilities/IFileDirectoryDTO";
import { EntityId } from "@reduxjs/toolkit";

export function fetchUserMailSettingsInfo(type: UserEmailSettingsType, resource?: string, resourceIds?: Array<EntityId>): Promise<Array<IUserEmailSetting>> {
  return bauhubGet("/user/email/info", { type: type, resource: resource, resourceIds: resourceIds });
}

export function fetchUserMailSettingsInfos(types: Array<UserEmailSettingsType>, resource?: string, resourceIds?: Array<EntityId>): Promise<Array<IUserEmailSetting>> {
  return bauhubGet("/user/email/info/multiple", { types: types, resource: resource, resourceIds: resourceIds });
}

export function saveUserMailSettingsInfo(userEmailSetting: IUserEmailSetting): Promise<IUserEmailSetting> {
  return bauhubPost("/user/email/info", userEmailSetting);
}

export function saveFileFolderSelection(dto: IChangeDailyMailFolderSelectionRequestBody): Promise<{ value: string }> {
  return bauhubPost("/user/mail/daily/project/file/relation/change/selection", dto);
}

export function saveDocumentFolderSelection(dto: IChangeDailyMailFolderSelectionRequestBody): Promise<{ value: string }> {
  return bauhubPost("/user/mail/daily/project/document/relation/change/selection", dto);
}

export function saveAllSelectedDailyMailDirectories(
  projectId: EntityId,
  userDailyMailProjectFileEntityRelations: Array<IUserDailyMailProjectFileEntityRelation>
): Promise<Array<IUserDailyMailProjectFileEntityRelation>> {
  return bauhubPost("/v2/user/mail/daily/project/" + projectId + "/file/relations", userDailyMailProjectFileEntityRelations);
}

export function saveUserDailyMailSettingsInfo(userEmailSetting: IUserEmailSetting): Promise<IUserEmailSetting> {
  return bauhubPost("/user/email/info/daily/mail", userEmailSetting);
}

export function fetchProjectFileRootDirectoryForDailyMail(projectId: EntityId): Promise<IFileDirectoryDTO> {
  return bauhubGet("/file/root/project/" + projectId + "/optimized");
}

export function fetchProjectDocumentRootDirectoryForDailyMail(projectId: EntityId): Promise<IFileDirectoryDTO> {
  return bauhubGet("/document/root/project/" + projectId + "/optimized");
}

export function fetchUserDailyMailProjectFileEntityRelation(projectId: EntityId): Promise<Array<IUserDailyMailProjectFileEntityRelation>> {
  return bauhubGet("/user/mail/daily/project/" + projectId + "/file/relations");
}
