import React, { FC } from "react";
import BhDatePicker from "@components/input/BhDatePicker";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectSignInvitesFilter } from "@/app/store/signInvitesSlice";

interface Props {
  onChange: Function;
}

const SignInvitesDateFilter: FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();
  const filter = useAppSelector(selectSignInvitesFilter);

  return (
    <>
      <BhDatePicker
        initialValue={filter.createdFrom ? new Date(filter.createdFrom) : null}
        property="createdFrom"
        onChangeCallback={onChange}
        classes={"w-full"}
        returnISOString={true}
        placeholder={t("LOG.SINCE") as string}
        onResetCallback={() => onChange({ createdFrom: null })}
      />
      <BhDatePicker
        initialValue={filter.createdUntil ? new Date(filter.createdUntil) : null}
        property={"createdUntil"}
        onChangeCallback={onChange}
        classes={"w-full"}
        returnISOString={true}
        placeholder={t("LOG.UNTIL") as string}
        onResetCallback={() => onChange({ createdUntil: null })}
      />
    </>
  );
};

export default SignInvitesDateFilter;
