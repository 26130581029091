import React, { FC, useEffect } from "react";
import { IUser } from "@/model/IUser";
import { IContact } from "@/model/IContact";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import BhUserIcon from "@components/user/BhUserIcon";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  users: Array<IUser> | Array<IContact> | Array<ISimpleValidUserAuthority>;
  size?: number;
}

const BhStackedUserIcons: FC<Props> = ({ users, size }) => {
  if (users.length === 0) return null;

  return (
    <div className="flex w-full flex-row items-center">
      {users.map((user, index) => {
        return (
          <div className={classNames(index !== 0 && "-ml-1", "z-" + index + "0")} key={user.id}>
            <BhUserIcon user={user} key={user.id} />
          </div>
        );
      })}
    </div>
  );
};

export default BhStackedUserIcons;
