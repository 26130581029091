import React, { FC } from "react";
import BhPlanTag from "@components/tags/BhPlanTag";

const BhProPlanTagFaded: FC = () => {
  return (
    <BhPlanTag className="bh-bg-pigeon-60 bh-border-pigeon-60 bh-text-white" small={true}>
      Pro
    </BhPlanTag>
  );
};

export default BhProPlanTagFaded;
