import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { IWorkGroupToTaskBoard } from "@/model/taskBoard/IWorkGroupToTaskBoard";
import { EntityId } from "@reduxjs/toolkit";

export interface ITaskBoard {
  id: EntityId;
  name: string;
  projectId: EntityId;
  deadline: Date;
  abbreviation: string;
  taskCounter: number;
  deleted: boolean;
  status: TaskBoardStatus;
  created: Date;
  updated: Date;
  createdBy: string;
  updatedBy: string;
  users: Array<ISimpleValidUserAuthority>;
  workGroupToTaskBoards: Array<IWorkGroupToTaskBoard>;
}

export enum TaskBoardStatus {
  TODO = "TODO",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE"
}
