import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import BhTextareaSimple from "@components/input/BhTextareaSimple";

interface Props {
  value: any;
  property: string;
  saveCallback: Function;
  disabled?: boolean;
  type: string;
}

const FormTableFieldRowInput: FC<Props> = ({ value, property, saveCallback, disabled, type }) => {
  return (
    <div className={classNames("flex h-full w-full items-start justify-center", type === "CHECKBOX" ? "min-w-20 max-w-20 mt-1 w-20" : "max-w-half")}>
      {type === "CHECKBOX" && <BhCheckbox isChecked={value} onChange={(val: any) => saveCallback(val, property)} property={property} large={true} disabled={disabled} />}
      {type === "TEXT" && <BhTextareaSimple property={property} onBlurCallback={(val: any) => saveCallback(val, property)} disabled={disabled} inputClasses="w-full" initialValue={value} />}
    </div>
  );
};
export default FormTableFieldRowInput;
