import React, { FC } from "react";
import { IFormBaseVersionRowField } from "@/model/form/IFormBaseVersionRowField";
import FormProtocolFieldTableRow from "@components/form/fields/protocolField/FormProtocolFieldTableRow";

interface Props {
  field: IFormBaseVersionRowField;
}

const FormProtocolFieldTablePreview: FC<Props> = ({ field }) => {
  return (
    <>
      {field.value.agenda &&
        field.value.agenda.length > 0 &&
        field.value.agenda.map((agendaRow: any, index: number) => {
          return (
            <FormProtocolFieldTableRow
              key={index}
              disabled={true}
              isPreview={true}
              agendaRow={agendaRow}
              rowId={index}
              addRowCallback={() => console.log("add")}
              removeRowCallback={() => console.log("remove")}
              saveRow={() => console.log("save")}
              isLastRow={false}
              agendaFieldProperty={field.property}
            />
          );
        })}
    </>
  );
};

export default FormProtocolFieldTablePreview;
