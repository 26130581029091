import React, { FC, useCallback, useState } from "react";
import BhFilterWithToggle from "@components/filters/BhFilterWithToggle";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectSignInvitesFilter, signInvitesFilterSet } from "@/app/store/signInvitesSlice";
import { ISignInvitesFilter } from "@/model/ISignInvitesFilter";
import SignInvitesDateFilter from "@/views/home/project/detail/signInvites/signInvitesFilter/SignInvitesDateFilter";
import { useTranslation } from "react-i18next";
import DirectorySelectionModal from "@/views/home/project/detail/directory/directoryModals/DirectorySelectionModal";
import SignInvitesSignerFilter from "@/views/home/project/detail/signInvites/signInvitesFilter/SignInvitesSignerFilter";
import { selectRootDirectoryId, selectRootDocumentDirectoryId } from "@/app/store/project/projectSlice";
import { IFolderFileEntity } from "@/model/files/IFileEntity";
import BhFilterButton from "@components/filters/BhFilterButton";

interface Props {}

const SignInvitesAllInvitesFilterContainer: FC<Props> = ({}) => {
  const { t } = useTranslation();
  const [isFileTreeModalOpen, setIsFileTreeModalOpen] = useState<boolean>(false);
  const filter = useAppSelector(selectSignInvitesFilter);
  const fileRootDirectoryId = useAppSelector(selectRootDirectoryId);
  const documentRootDirectoryId = useAppSelector(selectRootDocumentDirectoryId);
  const directorySelectionModalIds = filter.folderIds ? [...filter.folderIds, fileRootDirectoryId, documentRootDirectoryId] : [fileRootDirectoryId, documentRootDirectoryId];
  const dispatch = useAppDispatch();

  const onFilterChange = useCallback(
    (changedValue: ISignInvitesFilter) => {
      const changedObject = { ...filter, ...changedValue } as ISignInvitesFilter;
      dispatch(signInvitesFilterSet(changedObject));
    },
    [filter]
  );

  const onContainerFolderFilterSelect = useCallback(
    (selectedFolders: Array<IFolderFileEntity>) => {
      if (selectedFolders.length > 0) {
        const folderIds = selectedFolders.map((folder) => folder.id);
        dispatch(signInvitesFilterSet({ ...filter, folderIds: folderIds }));
      }
      setIsFileTreeModalOpen(false);
    },
    [filter]
  );

  const onContainerFolderFilterReset = useCallback(() => {
    dispatch(signInvitesFilterSet({ ...filter, folderIds: [] }));
  }, [filter]);

  return (
    <>
      <SignInvitesSignerFilter />
      <BhFilterButton selectedValues={filter.folderIds ? filter.folderIds.length : 0} onReset={onContainerFolderFilterReset} onClick={() => setIsFileTreeModalOpen(true)}>
        {t("SIGN_INVITE.FILTER_BY_FOLDER")}
      </BhFilterButton>
      <SignInvitesDateFilter onChange={onFilterChange} />
      <BhFilterWithToggle
        text={t("SIGN_INVITE.QUEUE")}
        value={filter.queueEnabled}
        onClickAction={() => {
          onFilterChange({ queueEnabled: !filter.queueEnabled } as ISignInvitesFilter);
        }}
      />
      {isFileTreeModalOpen && (
        <DirectorySelectionModal
          directoryIds={directorySelectionModalIds}
          preSelectedDirectoryIds={filter.folderIds || []}
          modalHeader={t("MODAL.CHANGE_DIR.CHOOSE_DIR")}
          onModalSubmit={onContainerFolderFilterSelect}
          onModalClose={() => setIsFileTreeModalOpen(false)}
          selectOnlyOne={false}
          allowOnlyParentsToBeSelected={false}
        />
      )}
    </>
  );
};

export default SignInvitesAllInvitesFilterContainer;
