export interface ITaskModals {
  open: ITaskModalsOpen;
}

export interface ITaskModalsOpen {
  taskCopyModal: boolean;
  taskMoveModal: boolean;
}

export const TaskModalsInitialState = {
  open: {
    taskCopyModal: false,
    taskMoveModal: false
  }
};
