import React, { FC, ReactNode } from "react";
import BhFilterButton from "@components/filters/BhFilterButton";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhFilterTypeEnum } from "@components/filters/BhFilterTypeEnum";

interface Props {
  title: ReactNode;
  values: any;
  textProperty?: string;
  onSelect?: Function;
  onReset?: Function;
  selectedValues?: number;
  isSelected?: (value: any) => boolean;
  search?: boolean;
  searchInputSaveCallback?: Function;
  searchInputProperty?: string;
  dropdownSearchInitialValue?: string;
  numberOfItemsDisplayed?: number;
  filterType?: BhFilterTypeEnum;
  dropdownWidthClass?: string;
  closeOnItemClick?: boolean;
  translateValues?: boolean;
  isLoading?: boolean;
}

const BhFilter: FC<Props> = ({
  title,
  values,
  textProperty,
  onSelect,
  onReset,
  selectedValues,
  isSelected,
  search,
  searchInputSaveCallback,
  searchInputProperty,
  dropdownSearchInitialValue,
  numberOfItemsDisplayed,
  filterType,
  dropdownWidthClass,
  closeOnItemClick,
  translateValues,
  isLoading
}) => {
  const filterTypeToDropDownTypeMap: Record<BhFilterTypeEnum, BhDropdownTypeEnum> = {
    FILE: BhDropdownTypeEnum.FILE,
    STRING: BhDropdownTypeEnum.STRING,
    USER: BhDropdownTypeEnum.USER
  };

  return (
    <BhDropdown
      button={
        <BhFilterButton selectedValues={selectedValues} onReset={onReset} isLoading={isLoading}>
          {title}
        </BhFilterButton>
      }
      menu={
        <BhDropdownMenu
          type={filterType ? filterTypeToDropDownTypeMap[filterType] : BhDropdownTypeEnum.STRING}
          values={values}
          textProperty={textProperty}
          withCheckbox={true}
          onSelect={onSelect}
          isSelected={isSelected}
          search={search}
          searchInputSaveCallback={searchInputSaveCallback}
          searchInputProperty={searchInputProperty}
          dropdownSearchInitialValue={dropdownSearchInitialValue}
          numberOfItemsDisplayed={numberOfItemsDisplayed}
          widthClass={dropdownWidthClass}
          closeOnItemClick={closeOnItemClick}
          translateValues={translateValues}
        />
      }
    />
  );
};

export default BhFilter;
