import React, { FC, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { shallowEqual } from "react-redux";
import { IFormBaseVersionRowField } from "@/model/form/IFormBaseVersionRowField";
import { IFormDataSaveRequest } from "@/model/IForm";
import FormProtocolFieldTableRow from "@components/form/fields/protocolField/FormProtocolFieldTableRow";
import { selectProtocolRowIdsFromCurrentFormData } from "@/app/store/form/formSlice";
import { fetchProjectTaskBoardsAsync } from "@/app/store/taskBoardsSlice";

interface Props {
  field: IFormBaseVersionRowField;
  saveCallback?: Function;
  addCallback?: Function;
  removeCallback?: Function;
  disabled: boolean;
}

const FormProtocolFieldTable: FC<Props> = ({ field, saveCallback, addCallback, removeCallback, disabled }) => {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const agendaRowIds = useAppSelector((state) => selectProtocolRowIdsFromCurrentFormData(state, field.property), shallowEqual);

  useEffect(() => {
    if (projectId) {
      dispatch(fetchProjectTaskBoardsAsync(projectId));
    }
  }, []);

  const addRowBelow = useCallback((index: number, row: any) => {
    addCallback && addCallback(row, field.property, index);
  }, []);

  const removeRow = useCallback((row: any) => {
    removeCallback && removeCallback(row, field.property);
  }, []);

  const saveRow = useCallback((changedObject: any, changedProperty: any, rowId: string) => {
    const saveRequest = {
      path: field.property,
      changedProperty: changedProperty,
      changedValue: changedObject[changedProperty],
      changedObjectId: rowId
    } as IFormDataSaveRequest;
    saveCallback && saveCallback(saveRequest);
  }, []);

  return (
    <>
      {agendaRowIds &&
        agendaRowIds.length > 0 &&
        agendaRowIds.map((rowId: any, index: number) => {
          return (
            <FormProtocolFieldTableRow
              rowId={rowId}
              addRowCallback={addRowBelow}
              removeRowCallback={removeRow}
              key={rowId}
              disabled={disabled}
              saveRow={saveRow}
              isLastRow={agendaRowIds.length === index + 1}
              agendaFieldProperty={field.property}
            />
          );
        })}
    </>
  );
};

export default FormProtocolFieldTable;
