import React, { Dispatch, useEffect, useState } from "react";
import { ContextMenu, DistanceMeasurementsMouseControl, DistanceMeasurementsPlugin, Viewer } from "@xeokit/xeokit-sdk";
import BhIconButton from "@components/buttons/BhIconButton";
import { faRulerTriangle } from "@fortawesome/pro-regular-svg-icons/faRulerTriangle";
import { BauhubXeokitTool } from "@/views/home/project/detail/xeokit/XeokitWebViewer";
import { useTranslation } from "react-i18next";
import BhIconWithLabelButton from "@components/buttons/BhIconWithLabelButton";
import BhTooltip from "@components/BhTooltip";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import BhDeclineButton from "@components/buttons/BhDeclineButton";

interface Props {
  onModelsLoaded: boolean;
  toolActive: BauhubXeokitTool;
  setToolActive: Dispatch<BauhubXeokitTool>;
  viewer?: Viewer;
  isMobile?: boolean;
}

const XeokitMeasureTool = React.memo<Props>(({ onModelsLoaded, toolActive, setToolActive, viewer, isMobile }) => {
  const { t } = useTranslation();
  const [distanceMeasurementsControlObject, setDistanceMeasurementsControlObject] = useState<any>();
  const [measurementsPlugin, setMeasurementsPlugin] = useState<any>();
  const [showMeasurementsRemoveButton, setShowMeasurementsRemoveButton] = useState<boolean>(false);

  // Only run on the first time when viewer is initialized
  useEffect(() => {
    if (!viewer) return;

    const contextMenu = new ContextMenu({
      items: [
        [
          {
            getTitle: (context: any) => {
              return context.measurement.axisVisible ? t("BIM.MEASUREMENT.HIDE_AXIS") : t("BIM.MEASUREMENT.SHOW_AXIS");
            },
            doAction: function (context: any) {
              context.measurement.axisVisible = !context.measurement.axisVisible;
            }
          },
          {
            getTitle: (context: any) => {
              return context.measurement.labelsVisible ? t("BIM.MEASUREMENT.HIDE_LABELS") : t("BIM.MEASUREMENT.SHOW_LABELS");
            },
            doAction: function (context: any) {
              context.measurement.labelsVisible = !context.measurement.labelsVisible;
            }
          }
        ],
        [
          {
            title: t("BIM.MEASUREMENT.REMOVE"),
            doAction: function (context: any) {
              context.measurement.destroy();
              if (Object.keys(distanceMeasurementsPlugin.measurements).length === 0) {
                setShowMeasurementsRemoveButton(false);
              }
            }
          },
          {
            title: t("BIM.MEASUREMENT.REMOVE_ALL"),
            doAction: function (context: any) {
              distanceMeasurementsPlugin.clear();
              setShowMeasurementsRemoveButton(false);
            }
          }
        ]
      ]
    });

    const distanceMeasurementsPlugin = new DistanceMeasurementsPlugin(viewer, {
      defaultAxisVisible: true,
      labelMinAxisLength: 1
    });
    setMeasurementsPlugin(distanceMeasurementsPlugin);

    distanceMeasurementsPlugin.on("measurementCreated", (measurement) => {
      measurement.approximate = false;
      const measurementsExist = Object.keys(distanceMeasurementsPlugin.measurements).length > 0;
      setShowMeasurementsRemoveButton(measurementsExist);
    });

    const distanceMeasurementsControl = new DistanceMeasurementsMouseControl(distanceMeasurementsPlugin, {});

    // @ts-ignore
    // distanceMeasurementsControl.snapToVertex = true;
    // @ts-ignore
    distanceMeasurementsControl.snapToEdge = true;

    // @ts-ignore
    distanceMeasurementsPlugin.on("contextMenu", (e) => {
      // @ts-ignore
      window.measurementsContextMenuOpen = true;
      
      contextMenu.context = {
        // Must set context before showing menu
        distanceMeasurementsPlugin: distanceMeasurementsPlugin,
        // @ts-ignore
        measurement: e.measurement
      };
      // @ts-ignore
      contextMenu.show(e.event.clientX, e.event.clientY);
      // @ts-ignore
      e.event.preventDefault();
    });

    setDistanceMeasurementsControlObject(distanceMeasurementsControl);

    viewer.on("reset", () => {
      distanceMeasurementsPlugin.clear();
      setShowMeasurementsRemoveButton(false);
    });

    return function destroy() {
      distanceMeasurementsPlugin.clear();
      contextMenu.destroy();
    };
  }, [onModelsLoaded]);

  useEffect(() => {
    if (viewer && distanceMeasurementsControlObject && toolActive !== BauhubXeokitTool.MEASURE && distanceMeasurementsControlObject.active) {
      distanceMeasurementsControlObject.deactivate();
    }
  }, [toolActive]);

  const toggleMeasureToolActive = () => {
    if (!viewer || !distanceMeasurementsControlObject) return;

    const newMeasureToolActive = toolActive !== BauhubXeokitTool.MEASURE;
    if (newMeasureToolActive) {
      setToolActive(BauhubXeokitTool.MEASURE);
      distanceMeasurementsControlObject.activate();
    } else {
      distanceMeasurementsControlObject.deactivate();
      setToolActive(BauhubXeokitTool.SELECT);
    }
  };

  const removeMeasurements = () => {
    measurementsPlugin.clear();
    setShowMeasurementsRemoveButton(false);
  };

  if (isMobile) {
    return (
      <BhIconWithLabelButton icon={faRulerTriangle} buttonProps={{ onClick: toggleMeasureToolActive }} isActive={toolActive === BauhubXeokitTool.MEASURE}>
        {t("BIM.TOOL.MEASURE")}
      </BhIconWithLabelButton>
    );
  }

  return (
    <div className="flex flex-row items-center">
      <BhTooltip body={t("BIM.TOOL.MEASURE")}>
        <BhIconButton icon={faRulerTriangle} buttonProps={{ onClick: toggleMeasureToolActive }} isActive={toolActive === BauhubXeokitTool.MEASURE} />
      </BhTooltip>
      {showMeasurementsRemoveButton && (
        <BhDeclineButton icon={faTrash} buttonProps={{ onClick: removeMeasurements, classes: "!py-1" }}>
          {t("BIM.MEASUREMENTS")}
        </BhDeclineButton>
      )}
    </div>
  );
});

export default XeokitMeasureTool;
