import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { removeFileFromContainerAsync, selectFileById } from "@/app/store/filesSlice";
import FileNameLink from "@/features/fileContainer/FileNameLink";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { EntityId } from "@reduxjs/toolkit";
import BhIconButton from "@components/buttons/BhIconButton";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { fetchUrlForFile } from "@/api/fileAPI";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { useTranslation } from "react-i18next";

interface Props {
  fileId: EntityId;
  deletable: boolean;
}

const ContainerFileRowFC: FC<Props> = ({ fileId, deletable }) => {
  const fileEntity = useAppSelector((state) => selectFileById(state, fileId));
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const downloadFile = (fileId: EntityId) => {
    if (!fileId || !fileEntity?.uuid) {
      return;
    }

    fetchUrlForFile(fileId, false, true, fileEntity.uuid).then((response) => {
      imitateUrlDownload(response.value);
    });
  };

  const [showRemoveFileFromContainerConfirmationModal, hideRemoveFileFromContainerConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideRemoveFileFromContainerConfirmationModal}
      header={<h2>{t("CONTAINER.MODAL.REMOVE_FILE.TITLE")}</h2>}
      body={<div>{t("CONTAINER.MODAL.REMOVE_FILE.BODY")}</div>}
      confirmationButtonText={t("CONTAINER.MODAL.REMOVE_FILE.CONFIRM")}
      handleDelete={() => {
        dispatch(removeFileFromContainerAsync(fileId));
      }}
    />
  ));

  if (!fileEntity) {
    return null;
  }

  return (
    <>
      <div className="bh-bg-white hover:bh-bg-mint-30 bh-border-pigeon-40 bh-hover-container group group block flex h-14 items-center border-b">
        <div className="relative flex w-12 flex-none justify-center text-center">
          <BhFileCardIcon fileEntity={fileEntity} fileCardSize={FileCardSize.LARGE} />
        </div>
        <div className="min-w-24 flex flex-grow flex-row items-center overflow-hidden pr-3">
          <div className="flex flex-col justify-center overflow-hidden">
            <div className="flex flex-row items-center">
              <FileNameLink fileEntity={fileEntity} />
            </div>
          </div>
        </div>
        <div className="w-48 flex-none px-3">{fileEntity.createdByFullName}</div>
        <div className="w-32 flex-none px-3 text-right">
          <BhIconButton icon={faArrowDownToLine} buttonProps={{ onClick: () => downloadFile(fileId) }} />
          {deletable && <BhIconButton icon={faTrash} buttonProps={{ onClick: showRemoveFileFromContainerConfirmationModal }} />}
        </div>
      </div>
    </>
  );
};

const ContainerFileRow = React.memo(ContainerFileRowFC);
export default ContainerFileRow;
