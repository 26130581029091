import React, { FC } from "react";

const ChecklistStatisticsDetailsSkeleton: FC = () => {
  return (
    <>
      <div className="bh-bg-pigeon-50 mt-5 mb-3 h-8 w-40 animate-pulse rounded pt-2" />
      <div className="bh-bg-pigeon-50 mt-5 mb-3 h-8 w-40 animate-pulse rounded pt-2" />
      <div className="bh-bg-pigeon-50 mt-5 mb-3 h-8 w-40 animate-pulse rounded pt-2" />
      <div className="bh-bg-pigeon-50 mt-5 mb-3 h-8 w-40 animate-pulse rounded pt-2" />
    </>
  );
};

export default ChecklistStatisticsDetailsSkeleton;
