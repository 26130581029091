export enum BhTagType {
  TAG = "TAG",
  ALLTOOVOTJA = "ALLTOOVOTJA",
  TELLIJA = "TELLIJA",
  ADMINISTRAATOR = "ADMINISTRAATOR",
  REGISTREERIMATA = "REGISTREERIMATA",
  ARHIVEERITUD = "ARHIVEERITUD",
  VOLGNEVUS = "VOLGNEVUS",
  MUSTAND = "MUSTAND",
  PROJEKT_TOOS = "PROJEKT_TOOS",
  TIP = "TIP",
  CUSTOM = "CUSTOM",
  TASK_STATUS = "TASK_STATUS",
  PAID = "PAID",
  FAILED = "FAILED",
  SENT = "SENT",
  NOT_SENT = "NOT_SENT"
}
