import React from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boRemoveCompanyModuleAsync, boSaveCompanyModuleAsync, boSelectCompany } from "@/app/store/backofficeSlice";
import BOCompanyTemplateRow from "@components/backoffice/company/modulesAndTemplates/BOCompanyTemplateRow";
import BhCombobox from "@components/input/BhCombobox";
import { Module } from "@/model/IProject";
import { naturalSortByField, naturalSortString } from "@/utilities/sortUtilities";
import { ICompanyModule } from "@/model/ICompany";

const BOCompanyModules = () => {
  const company = useAppSelector(boSelectCompany);
  const companyModules = company.modules?.map((module) => module.module);
  const companyModulesSorted = company.modules ? [...company.modules]?.sort((a, b) => naturalSortByField(a, b, "module")) : [];
  const modules = Object.values(Module);
  const dropdownValues = modules.filter((module) => !companyModules?.includes(module)).sort((a, b) => naturalSortString(a, b));
  const dispatch = useAppDispatch();

  const handleModuleSelect = (selectedObj: Record<string, Module>) => {
    const moduleDto = { ...selectedObj, companyId: company.id } as ICompanyModule;
    dispatch(boSaveCompanyModuleAsync(moduleDto));
  };

  const handleRemoveModule = (module: ICompanyModule) => {
    dispatch(boRemoveCompanyModuleAsync(module));
  };

  return (
    <div id="modules" className="w-full">
      <h2>Modules</h2>
      {companyModulesSorted.map((module) => (
        <BOCompanyTemplateRow key={module.id} template={module} nameProperty="module" removeCallback={() => handleRemoveModule(module)} label={module.module} />
      ))}
      <div className="mt-2 w-full">
        <BhCombobox initialValue={""} values={dropdownValues} property="module" placeholder="+ Add module" onSelect={handleModuleSelect} enableNewValue={true} />
      </div>
    </div>
  );
};

export default BOCompanyModules;
