import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhIconButton from "@components/buttons/BhIconButton";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons/faEllipsisVertical";
import { IDropdownItem } from "@/model/IDropdownItem";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons/faFilePdf";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons/faFileExport";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { actUpdated, deleteActAsync } from "@/app/store/actsSlice";
import { useTranslation } from "react-i18next";
import { fetchUrlForActPdf, moveActToFileTree } from "@/api/actAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { EntityId } from "@reduxjs/toolkit";
import { useModal } from "react-modal-hook";
import DirectorySelectionModal from "@/views/home/project/detail/directory/directoryModals/DirectorySelectionModal";
import { IFolderFileEntity } from "@/model/files/IFileEntity";
import { fullPageLoadingSet } from "@/app/store/globalSlice";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { selectCurrentProject, selectRootDirectoryId, selectRootDocumentDirectoryId } from "@/app/store/project/projectSlice";
import SendActWithEmailModal from "@/views/home/project/detail/actsAndContracts/acts/actsModals/SendActWithEmailModal";

interface Props {
  actId: EntityId;
  isWritePrivilege: boolean;
  isLatestAct: boolean;
}

const ActListItemContainerDropdown: FC<Props> = ({ actId, isWritePrivilege, isLatestAct }) => {
  const project = useAppSelector(selectCurrentProject);
  const fileRootDirectoryId = useAppSelector(selectRootDirectoryId);
  const documentRootDirectoryId = useAppSelector(selectRootDocumentDirectoryId);
  const directorySelectionModalIds = [fileRootDirectoryId, documentRootDirectoryId];
  const [showEmailModal, setShowEmailModal] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isMaruProject = project.modules.some((module) => module.module === "MR_BUDGET");

  const [showCopyPdfFileToDirectoryModal, hideCopyPdfFileToDirectoryModal] = useModal(() => (
    <DirectorySelectionModal
      directoryIds={directorySelectionModalIds}
      modalHeader={t("MODAL.CHANGE_DIR.CHOOSE_DIR")}
      onModalClose={hideCopyPdfFileToDirectoryModal}
      onModalSubmit={(selectedFolderFileEntities: Array<IFolderFileEntity>) => {
        const [selectedFolder] = selectedFolderFileEntities;
        if (selectedFolder.id) {
          dispatch(fullPageLoadingSet(true));
          moveActToFileTree(actId, selectedFolder.id)
            .then((act) => {
              dispatch(actUpdated(act));
            })
            .finally(() => {
              dispatch(fullPageLoadingSet(false));
            });
          hideCopyPdfFileToDirectoryModal();
        }
      }}
      selectOnlyOne={true}
    />
  ));

  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteConfirmationModal}
      header={<h2>{t("MODAL.DELETEACT.TITLE")}</h2>}
      body={<div>{t("MODAL.DELETEACT.CONFIRMATION")}</div>}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={() => {
        dispatch(deleteActAsync(actId));
        hideDeleteConfirmationModal();
      }}
    />
  ));

  if (!actId) {
    return null;
  }

  const ellipsisDropdownValues: Array<IDropdownItem> = [
    {
      text: t("ACT.OPEN_PDF"),
      function: () => {
        fetchUrlForActPdf(actId).then((response) => {
          imitateUrlDownload(response.value);
        });
      },
      icon: faFilePdf
    },
    isWritePrivilege && {
      text: t("ACT.COPY_TO_FILETREE"),
      function: () => {
        showCopyPdfFileToDirectoryModal();
      },
      icon: faFileExport
    },
    isWritePrivilege && {
      text: t("ACT.SEND_EMAIL.TITLE"),
      function: () => {
        setShowEmailModal(true);
      },
      icon: faEnvelope
    },
    isWritePrivilege && isLatestAct && ({ separator: true } as IDropdownItem),
    isWritePrivilege &&
      isLatestAct && {
        text: t("GLOBAL.DELETE"),
        function: () => {
          showDeleteConfirmationModal();
        },
        icon: faTrash
      }
  ].filter(Boolean) as Array<IDropdownItem>;

  return (
    <>
      <BhDropdown
        button={<BhIconButton icon={faEllipsisVertical} />}
        menu={<BhDropdownMenu values={ellipsisDropdownValues} type={BhDropdownTypeEnum.STRING} textProperty="text" />}
        position={BhDropdownPositionEnum.BOTTOM_LEFT}
      />
      {showEmailModal && <SendActWithEmailModal actId={actId} closeModal={() => setShowEmailModal(false)} />}
    </>
  );
};

export default ActListItemContainerDropdown;
