import { ITaskBoard } from "@/model/taskBoard/ITaskBoard";
import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { EntityId } from "@reduxjs/toolkit";
import { IWorkGroupToTaskBoard } from "@/model/taskBoard/IWorkGroupToTaskBoard";
import { ITaskBoardUserDTO } from "@/model/taskBoard/ITaskBoardUserDTO";

export function fetchProjectTaskBoards(projectId: EntityId): Promise<Array<ITaskBoard>> {
  return bauhubGet("/project/" + projectId + "/boards");
}

export function saveTaskBoard(taskBoard: ITaskBoard): Promise<ITaskBoard> {
  return bauhubPost("/task/board", taskBoard);
}

export function deleteTaskBoard(taskBoard: ITaskBoard): Promise<ITaskBoard> {
  return bauhubPost("/task/board/delete", taskBoard);
}

export function fetchTaskBoardWorkGroups(taskBoardId: EntityId): Promise<Array<IWorkGroupToTaskBoard>> {
  return bauhubGet("/task/board/" + taskBoardId + "/workgroups");
}

export function saveUserToTaskBoard(taskBoardUserDTO: ITaskBoardUserDTO): Promise<ITaskBoardUserDTO> {
  return bauhubPost("/project/board/user/add", taskBoardUserDTO);
}

export function removeUserFromTaskBoard(taskBoardUserDTO: ITaskBoardUserDTO): Promise<ITaskBoardUserDTO> {
  return bauhubPost("/project/board/user/remove", taskBoardUserDTO);
}

export function saveWorkGroupToTaskBoard(taskBoardUserDTO: ITaskBoardUserDTO): Promise<ITaskBoardUserDTO> {
  return bauhubPost("/project/board/wg/add", taskBoardUserDTO);
}

export function removeWorkGroupFromTaskBoard(taskBoardUserDTO: ITaskBoardUserDTO): Promise<ITaskBoardUserDTO> {
  return bauhubPost("/project/board/wg/remove", taskBoardUserDTO);
}
