import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveWorkGroupUserInviteAsync, workGroupUserInviteAdded } from "@/app/store/project/projectWorkGroupsSlice";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhModal from "@components/modal/BhModal";
import { IPartyPrivilegeListItemDTO } from "@/model/party/IPartyPrivilegeListItemDTO";
import { IWorkGroup } from "@/model/IWorkGroup";
import { useTranslation } from "react-i18next";
import BhModalFooter from "@components/modal/BhModalFooter";
import { isValidEmail } from "@/utilities/jsUtilities";
import { store } from "@/app/store";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { EntityId } from "@reduxjs/toolkit";
import { IMaruClientPerson } from "@/model/maru/IMaruClientPerson";
import MaruPartyAddUserContentContainer from "@/views/home/project/detail/users/parties/party/maru/MaruPartyAddUserContentContainer";
import { selectBimProjectModule } from "@/app/store/project/projectSlice";

interface Props {
  workGroup: IWorkGroup;
  isModal?: boolean;
  usersInvites?: Array<IPartyPrivilegeListItemDTO>;
  closeModal?: Function;
  submitButtonText?: string;
}

const MaruPartyAddUserContainer: FC<Props> = ({ workGroup, isModal, closeModal, submitButtonText, usersInvites }) => {
  const { t } = useTranslation();
  const [userToAdd, setUserToAdd] = useState<IPartyPrivilegeListItemDTO>({} as IPartyPrivilegeListItemDTO);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const dispatch = useAppDispatch();
  const taskBoardIds = workGroup.taskBoards.map((tb) => tb.id);
  const [maruClientPersons, setMaruClientPersons] = useState([] as Array<IMaruClientPerson>);
  const isBimProject = useAppSelector(selectBimProjectModule);

  const defaultUserToAdd = {
    username: "",
    documents: "READ",
    files: "READ",
    models: isBimProject ? "READ" : "NONE",
    acts: "NONE",
    contracts: "NONE",
    taskBoardIds: taskBoardIds as Array<EntityId>,
    uniqueId: "new-" + Math.random() * 100000 // random uniqueId for list key
  } as IPartyPrivilegeListItemDTO;

  useEffect(() => {
    setUserToAdd(defaultUserToAdd);
  }, []);

  const handleSubmit = () => {
    setSubmitDisabled(true);
    if (!userToAdd.username || !isValidEmail(userToAdd.username)) {
      store.dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.WARNING,
          disappear: true,
          children: t("LOGIN.INVALID_EMAIL")
        })
      );

      setSubmitDisabled(false);
      return;
    }

    if (maruClientPersons && maruClientPersons.length > 0) {
      const usernameInMaruPersons = maruClientPersons.some((person) => person.personalemail?.toLowerCase() === userToAdd.username?.toLowerCase());
      if (!usernameInMaruPersons) {
        store.dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.WARNING,
            disappear: true,
            children: t("PARTY.EMAIL_NOT_IN_LIST")
          })
        );
        setSubmitDisabled(false);
        return;
      }
    }

    if (usersInvites) {
      const existing = usersInvites.find((ui) => {
        return ui.username?.toLowerCase() === userToAdd.username?.toLowerCase();
      });
      if (existing) {
        setUserToAdd({ ...defaultUserToAdd, uniqueId: "new-" + Math.floor(Math.random() * 100000) });
        setSubmitDisabled(false);
        store.dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.WARNING,
            disappear: true,
            children: t("PARTY.USER_ALREADY_ADDED")
          })
        );
        return;
      }
    }

    if (workGroup.id === -1) {
      dispatch(workGroupUserInviteAdded(userToAdd));
      setSubmitDisabled(false);
      setUserToAdd({ ...defaultUserToAdd, uniqueId: "new-" + Math.random() * 100000 });
    } else {
      dispatch(saveWorkGroupUserInviteAsync({ workGroupId: workGroup.id, dto: userToAdd })).then(() => {
        setSubmitDisabled(false);
      });
    }
  };

  const handleCancel = () => {
    setUserToAdd(defaultUserToAdd);
  };

  const handleCloseModal = () => {
    closeModal && closeModal();
  };

  return (
    <div>
      {isModal ? (
        <BhModal
          isShown={true}
          setIsShown={handleCloseModal}
          size="5xl"
          header={<h2>{t("WORKGROUP.ADD_USER")}</h2>}
          children={
            <MaruPartyAddUserContentContainer
              userToAdd={userToAdd}
              setUserToAdd={setUserToAdd}
              usersInvites={usersInvites}
              workGroup={workGroup}
              isModal={isModal}
              setMaruClientPersons={setMaruClientPersons}
            />
          }
          footer={
            <BhModalFooter cancelButtonText={t("GLOBAL.CLOSE") as string} onCancel={closeModal} confirmButtonText={submitButtonText} onConfirm={handleSubmit} confirmDisabled={isSubmitDisabled} />
          }
        />
      ) : (
        <div>
          <MaruPartyAddUserContentContainer userToAdd={userToAdd} usersInvites={usersInvites} setUserToAdd={setUserToAdd} workGroup={workGroup} setMaruClientPersons={setMaruClientPersons} />
          <div className="mt-6 flex flex-row items-center">
            <BhPrimaryButton buttonProps={{ onClick: handleSubmit, disabled: isSubmitDisabled }}>{t("WORKGROUP.NEW.CONFIRM_USERS")}</BhPrimaryButton>
            <BhTextOnlyButton buttonProps={{ onClick: handleCancel, disabled: isSubmitDisabled }}>{t("GLOBAL.CANCEL")}</BhTextOnlyButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default MaruPartyAddUserContainer;
