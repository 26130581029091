import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import SignInvitesUnsignedListItem from "@/views/home/project/detail/signInvites/SignInvitesUnsignedListItem";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { ISignInvite } from "@/model/ISignInvite";
import {
  fetchUserUnsignedSignInvitesAsync,
  selectCountOfMyUnsignedSignInvitesForProject,
  selectSignInvitesListStatus,
  selectUnsignedSignInvitesForProjectFilteredAndSorted
} from "@/app/store/signInvitesSlice";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import { Trans, useTranslation } from "react-i18next";
import BhLargeText from "@components/text/BhLargeText";
import { useLocation } from "react-router-dom";
import SignInvitesUnsignedListSkeleton from "@/views/home/project/detail/confirmations/list/SignInvitesUnsignedListSkeleton";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";

interface Props {}

const SignInvitesUnsignedList: FC<Props> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectSignInvitesListStatus);
  const projectId = useAppSelector(selectCurrentProjectId);
  const countOfMyUnsignedSignInvites = useAppSelector(selectCountOfMyUnsignedSignInvitesForProject(projectId));
  const filteredAndSortedInvites = useAppSelector((state) => selectUnsignedSignInvitesForProjectFilteredAndSorted(state, projectId));

  useEffect(() => {
    dispatch(fetchUserUnsignedSignInvitesAsync());
  }, [location]);

  const noSignInvites = countOfMyUnsignedSignInvites === 0;
  const noMatches = !noSignInvites && filteredAndSortedInvites.length === 0;

  return (
    <div className="mt-2">
      {status === BhStateStatusType.PENDING && noSignInvites && <SignInvitesUnsignedListSkeleton />}
      {status === BhStateStatusType.SUCCESS && (
        <>
          {noSignInvites && (
            <BhNoMatchesImage>
              <Trans>OVERVIEW.NO_SIGN_INVITES</Trans>
            </BhNoMatchesImage>
          )}
          {noMatches && <BhLargeText classes="w-full text-center mt-5">{t("GLOBAL.NO_RESULTS")}</BhLargeText>}
          {filteredAndSortedInvites.length > 0 && (
            <div className="mt-2 space-y-2">
              {filteredAndSortedInvites.map((signInvite: ISignInvite) => {
                return <SignInvitesUnsignedListItem key={signInvite.id} signInvite={signInvite} />;
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SignInvitesUnsignedList;
