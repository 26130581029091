import React, { FC } from "react";
import { Link } from "react-router-dom";
import { classNames, getArchivedTimeLeft, textWithVariables } from "@/utilities/jsUtilities";
import { useAppSelector } from "@/app/hooks";
import { BauhubPlan, ProjectStatus } from "@/model/IProject";
import ProjectListItemTag from "@/features/projectList/projectListItem/ProjectListItemTag";
import ProjectListNotificationButton from "@/features/projectList/projectListItem/ProjectListNotificationButton";
import ProjectListItemPin from "@/features/projectList/projectListItem/ProjectListItemPin";
import BhProjectStatusBadge from "@components/badge/BhProjectStatusBadge";
import { EntityId } from "@reduxjs/toolkit";
import { selectCompanyById, selectProjectById } from "@/app/store/companiesSlice";
import BhCardContainer from "@components/cards/BhCardContainer";
import { useTranslation } from "react-i18next";
import BhLargeBoldText from "@components/text/BhLargeBoldText";
import { isMaruProject } from "@/utilities/customClients/maruUtilities";
import ProjectListSignatureButton from "@/features/projectList/projectListItem/ProjectListSignatureButton";
import { formatDate } from "@/utilities/dateUtility";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons/faTriangleExclamation";

interface Props {
  projectId: EntityId;
  isCompanySettingsView?: boolean;
  showArchivedProjectDeletingInfo?: boolean;
}

const ProjectListItem: FC<Props> = ({ projectId, isCompanySettingsView, showArchivedProjectDeletingInfo }) => {
  const { t } = useTranslation();
  const project = useAppSelector((state) => selectProjectById(state, projectId));
  const company = useAppSelector((state) => project && selectCompanyById(state, project.companyId));

  if (!project) {
    return null;
  }

  const isArchived = project.status === ProjectStatus.ARCHIVED ? " bh-text-pigeon-70 bh-bg-smoke " : " bh-text-deep-ocean-80 ";
  const isMaru = isMaruProject(project);

  const showPinButton = !isCompanySettingsView;
  const archivedTimeLeft = project.status === ProjectStatus.ARCHIVED ? getArchivedTimeLeft(project.archivedDate) : null;

  return (
    <BhCardContainer classes={isArchived}>
      <Link className="w-full cursor-pointer" to={`/project/${project.id}/overview`}>
        <div className="group flex items-center transition duration-300">
          <BhProjectStatusBadge type={project.securityLevel} />
          <div className="flex flex-1 flex-wrap items-center p-6 transition duration-300">
            <BhLargeBoldText classes={classNames(isArchived, "mr-3")}>
              {isMaru && <span className="pr-2">{project.contractNumber}</span>}
              {project.name}
            </BhLargeBoldText>
            <ProjectListItemTag project={project} />
            {showArchivedProjectDeletingInfo && project.status === ProjectStatus.ARCHIVED && company?.plan === BauhubPlan.LIGHT && archivedTimeLeft && (
              <div className="bh-text-deep-ocean py-2">
                <FontAwesomeIcon icon={faTriangleExclamation} size={"lg"} className="bh-text-warning-yellow mr-2" />
                <span>
                  {parse(
                    textWithVariables(t("PROJECT.LIST.ARCHIVED_INFO"), {
                      year: archivedTimeLeft.years().toString(),
                      month: archivedTimeLeft.months().toString(),
                      day: archivedTimeLeft.days().toString(),
                      monthText: archivedTimeLeft.months() === 1 ? t("PROJECT.BANNER.ARCHIVED.LIGHT.MONTH") : t("PROJECT.BANNER.ARCHIVED.LIGHT.MONTHS"),
                      dayText: archivedTimeLeft.days() === 1 ? t("PROJECT.BANNER.ARCHIVED.LIGHT.DAY") : t("PROJECT.BANNER.ARCHIVED.LIGHT.DAYS"),
                      yearText: archivedTimeLeft.years() === 1 ? t("PROJECT.BANNER.ARCHIVED.LIGHT.YEAR") : t("PROJECT.BANNER.ARCHIVED.LIGHT.YEARS")
                    })
                  )}
                </span>
              </div>
            )}
          </div>
          <div className="mr-4 flex flex-row">
            <div className="my-6 mr-4 w-10">
              <ProjectListSignatureButton projectId={project.id} />
            </div>
            <div className="my-6 mr-4 w-10">
              <ProjectListNotificationButton projectId={project.id} />
            </div>
          </div>
          <div className="mr-4 flex w-36 transition duration-300 sm:hidden">
            <p className="text-center">
              {t("GLOBAL.ORDER_BY.CREATED")}: {formatDate(project.created)}
            </p>
          </div>
          {showPinButton && <ProjectListItemPin projectId={project.id} classes="px-2 mr-3 w-14" />}
        </div>
      </Link>
    </BhCardContainer>
  );
};

export default ProjectListItem;
