import React, { FC } from "react";
import BhSpreadsheetTableHead from "@components/spreadsheet/BhSpreadsheetTableHead";
import BhSpreadsheetTableHeaderRow from "@components/spreadsheet/BhSpreadsheetTableHeaderRow";
import { invoicesTableHeaders, invoicesTableSubHeaders } from "@/views/home/backoffice/views/invoices/invoicesTableHeaders";
import BhSpreadsheetTableTH from "@components/spreadsheet/BhSpreadsheetTableTH";
import BhSpreadsheetTableBody from "@components/spreadsheet/BhSpreadsheetTableBody";
import BhSpreadsheetTableTR from "@components/spreadsheet/BhSpreadsheetTableTR";
import BhSpreadsheetTableCell from "@components/spreadsheet/BhSpreadsheetTableCell";
import { Link } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import BhSpreadsheetTable from "@components/spreadsheet/BhSpreadsheetTable";
import { useAppSelector } from "@/app/hooks";
import { boSelectYearlyInvoiceOverview } from "@/app/store/backofficeSlice";
import { IYearlyInvoiceOverview } from "@/model/invoices/IYearlyInvoiceOverview";
import { IYearlyInvoiceOverviewRow } from "@/model/invoices/IYearlyInvoiceOverviewRow";
import { formatCurrency } from "@/utilities/accountingUtilities";

const BOInvoicesSpreadsheetTable: FC = () => {
  const yearlyInvoiceOverview = useAppSelector(boSelectYearlyInvoiceOverview);
  const rowsExist = yearlyInvoiceOverview.data && yearlyInvoiceOverview.data.length > 0;

  const calculateTotalMonthlyAverageInvoiceSum = (month: IYearlyInvoiceOverviewRow): number => {
    const monthlySum = month.monthlyInvoiceSum;
    const annualSum = month.yearlyInvoiceSum;
    const manualSum = month.totalSumForAvgPrice;
    const totalProjects = month.manualProjectsCount + month.monthlyProjectsCount + month.yearlyProjectsCount || 1;
    return (manualSum + monthlySum + annualSum / 12) / totalProjects;
  };

  const calculateTotalMonthlyProjects = (month: IYearlyInvoiceOverviewRow): number => {
    return month.manualProjectsCount + month.monthlyProjectsCount + month.yearlyProjectsCount;
  };

  const calculateTotalYearlyAverageInvoiceSum = (invoice: IYearlyInvoiceOverview): number => {
    const manualSum = invoice.totalSumForAvgPrice;
    const monthlySum = invoice.monthlyInvoicesSum;
    const annualSum = invoice.yearlyInvoicesSum;
    const totalProjects = invoice.manualProjectsCount + invoice.monthlyProjectsCount + invoice.yearlyProjectsCount || 1;
    return (manualSum + monthlySum + annualSum / 12) / totalProjects;
  };

  const calculateTotalYearlyProjects = (invoice: IYearlyInvoiceOverview): number => {
    return invoice.manualProjectsCount + invoice.monthlyProjectsCount + invoice.yearlyProjectsCount;
  };

  const formatSum = (sum: number) => {
    return formatCurrency(sum, { symbol: "€", thousandsSeparator: " ", decimalSeparator: ",", significantDigits: 2 }, true);
  };

  return (
    <>
      <BhSpreadsheetTable>
        <BhSpreadsheetTableHead>
          <BhSpreadsheetTableHeaderRow>
            {invoicesTableHeaders.map((header) => (
              <BhSpreadsheetTableTH key={header.id} rowSpan={header.rowSpan} colSpan={header.colSpan} classes={header.classes}>
                {header.label}
              </BhSpreadsheetTableTH>
            ))}
          </BhSpreadsheetTableHeaderRow>
          <BhSpreadsheetTableHeaderRow>
            {invoicesTableSubHeaders.map((header) => (
              <BhSpreadsheetTableTH key={header.id} subheader={true} rowSpan={header.rowSpan} colSpan={header.colSpan} classes={header.classes}>
                {header.label}
              </BhSpreadsheetTableTH>
            ))}
          </BhSpreadsheetTableHeaderRow>
        </BhSpreadsheetTableHead>
        <BhSpreadsheetTableBody>
          {rowsExist &&
            yearlyInvoiceOverview.data?.map((month) => (
              <BhSpreadsheetTableTR key={month.yearMonth}>
                <BhSpreadsheetTableCell classes="p-3">
                  <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + month.yearMonth} className="underline-offset-4 hover:underline">
                    {month.yearMonth}
                  </Link>
                </BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell>{month.customInvoices}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="text-right">{formatSum(month.customInvoiceSum)}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell>{month.manualProjectsCount}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="bh-bg-smoke">{month.monthlyInvoices}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="text-right bh-bg-smoke">{formatSum(month.monthlyInvoiceSum)}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="bh-bg-smoke">{month.monthlyProjectsCount}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell>{month.yearlyInvoices}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="text-right">{formatSum(month.yearlyInvoiceSum)}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell>{month.yearlyProjectsCount}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="text-right bh-bg-mint-50 font-bold">{formatSum(month.totalSum)}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="bh-bg-mint-50 font-bold">{calculateTotalMonthlyProjects(month)}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="text-right">{formatSum(calculateTotalMonthlyAverageInvoiceSum(month))}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="text-right bh-text-deep-ocean-60">{formatSum(month.dueSum)}</BhSpreadsheetTableCell>
              </BhSpreadsheetTableTR>
            ))}
          {rowsExist && (
            <>
              <BhSpreadsheetTableTR classes="border-x-white border"></BhSpreadsheetTableTR>
              <BhSpreadsheetTableTR>
                <BhSpreadsheetTableCell classes="border !border-y-white !border-l-white"></BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell>{yearlyInvoiceOverview.customInvoices}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="text-right">{formatSum(yearlyInvoiceOverview?.customInvoicesSum)}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell>{yearlyInvoiceOverview.manualProjectsCount}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="bh-bg-smoke">{yearlyInvoiceOverview.monthlyInvoices}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="text-right bh-bg-smoke">{formatSum(yearlyInvoiceOverview.monthlyInvoicesSum)}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="bh-bg-smoke">{yearlyInvoiceOverview.monthlyProjectsCount}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell>{yearlyInvoiceOverview.yearlyInvoices}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="text-right">{formatSum(yearlyInvoiceOverview.yearlyInvoicesSum)}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell>{yearlyInvoiceOverview.yearlyProjectsCount}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="bh-bg-mint-50 font-bold text-right">{formatSum(yearlyInvoiceOverview.totalSum)}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="bh-bg-mint-50 font-bold">{calculateTotalYearlyProjects(yearlyInvoiceOverview)}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="text-right">{formatSum(calculateTotalYearlyAverageInvoiceSum(yearlyInvoiceOverview))}</BhSpreadsheetTableCell>
                <BhSpreadsheetTableCell classes="text-right bh-text-deep-ocean-60">{formatSum(yearlyInvoiceOverview.dueSum)}</BhSpreadsheetTableCell>
              </BhSpreadsheetTableTR>
            </>
          )}
        </BhSpreadsheetTableBody>
      </BhSpreadsheetTable>
    </>
  );
};

export default BOInvoicesSpreadsheetTable;
