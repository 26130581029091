import { IFileEntity } from "@/model/files/IFileEntity";
import { EntityId } from "@reduxjs/toolkit";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { ITaskBoard } from "@/model/taskBoard/ITaskBoard";
import { BhTagType } from "@components/tags/BhTagTypeEnum";

export interface IWorkGroup {
  id: EntityId;
  abbreviation: string;
  created: Date;
  createdBy: string;
  deleted: boolean;
  documents: Array<IFileEntity>;
  files: Array<IFileEntity>;
  taskBoards: Array<ITaskBoard>;
  maruValuationId: EntityId;
  name: string;
  party: boolean;
  projectId: EntityId;
  type: WorkGroupType;
  actEnabled: boolean;
  uniqueClientId: string;
  updated: Date;
  updatedBy: string;
  users: Array<ISimpleValidUserAuthority>;
}

export enum WorkGroupType {
  SUBCONTRACTOR = "SUBCONTRACTOR",
  CLIENT = "CLIENT"
}

export namespace WorkGroupType {
  const reverseMap = new Map<string, WorkGroupType>();

  Object.keys(WorkGroupType).forEach((s: string) => {
    const e = (WorkGroupType as any)[s];
    reverseMap.set(e.toString(), e);
  });

  export function valueOf(str: string) {
    return reverseMap.get(str);
  }
}

export const tagTypeMap: Record<WorkGroupType, BhTagType> = {
  [WorkGroupType.SUBCONTRACTOR]: BhTagType.ALLTOOVOTJA,
  [WorkGroupType.CLIENT]: BhTagType.TELLIJA
};
