import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import { selectCountOfUnsignedInvitesForProject } from "@/app/store/signaturesSlice";
import BhElementWithBadge from "@components/badge/BhElementWithBadge";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import { faSignature } from "@fortawesome/pro-regular-svg-icons/faSignature";
import BhIconButton from "@components/buttons/BhIconButton";
import { useNavigate } from "react-router-dom";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  projectId: EntityId;
  classes?: string;
}

const ProjectListSignatureButton: FC<Props> = ({ projectId, classes }) => {
  const { t } = useTranslation();
  const invitesCountForProject = useAppSelector(selectCountOfUnsignedInvitesForProject(projectId));
  const navigate = useNavigate();

  return (
    <>
      {invitesCountForProject > 0 && (
        <div className={classes}>
          <BhTooltip body={t("CONTAINER.INVITES.TITLE")}>
            <BhElementWithBadge badge={<BhBadge type={BhBadgeType.BRIGHT}>{invitesCountForProject}</BhBadge>}>
              <BhIconButton
                icon={faSignature}
                iconClasses="bh-text-deep-ocean"
                buttonProps={{
                  onClick: (e: any) => {
                    e.preventDefault();
                    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/sign/personal/`);
                    window.location.reload();
                  }
                }}
              />
            </BhElementWithBadge>
          </BhTooltip>
        </div>
      )}
    </>
  );
};

export default ProjectListSignatureButton;
