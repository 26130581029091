import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectSignaturesForContainer, signaturesModified } from "@/app/store/signaturesSlice";
import { IFileEntity } from "@/model/files/IFileEntity";
import { selectCurrentUser, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { sendAllSignInvitesForContainer } from "@/api/signInviteAPI";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import { toggleContainerQueuedSigningAsync } from "@/app/store/filesSlice";
import RegularSignatureAddUserInput from "@/views/home/project/detail/container/containerComponents/RegularSignatureAddUserInput";
import InitialQueueSignaturesContainer from "@/views/home/project/detail/container/InitialQueueSignaturesContainer";
import SignaturesList from "@/views/home/project/detail/container/containerComponents/SignaturesList";
import { useTranslation } from "react-i18next";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { useModal } from "react-modal-hook";

interface Props {
  containerFileEntity: IFileEntity;
}

const ContainerSignaturesContainer: FC<Props> = ({ containerFileEntity }) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const signatures = useAppSelector(selectSignaturesForContainer(containerFileEntity.uuid));
  const invitesOutOfSignatures = signatures.filter((signature) => signature.isUnsignedInvite && !signature.declined);
  const isCurrentUserProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, containerFileEntity.projectId));
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [showDisableQueueConfirmationModal, hideDisableQueueConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={() => {
        hideDisableQueueConfirmationModal();
      }}
      header={<h2>{t("CONFIRMATION.MODAL.SIGN_QUEUE.TITLE")}</h2>}
      body={<div>{t("CONFIRMATION.MODAL.SIGN_QUEUE.BODY")}</div>}
      confirmationButtonText={t("CONTAINER.MODAL.DISABLE_QUEUE.CONFIRM")}
      handleDelete={() => {
        toggleQueueEnabled(false);
        hideDisableQueueConfirmationModal();
      }}
    />
  ));

  const showDisableQueueModalOrToggleQueue = (queueEnabledToSet: boolean) => {
    if (queueEnabledToSet) {
      toggleQueueEnabled(queueEnabledToSet);
    } else {
      if (invitesOutOfSignatures?.length > 0) {
        showDisableQueueConfirmationModal();
      } else {
        toggleQueueEnabled(queueEnabledToSet);
      }
    }
  };

  const toggleQueueEnabled = (queueEnabledToSet: boolean) => {
    // Kui lülitad sisse siis queueEnabled = true ja queueEnabler = currentUser.id
    if (queueEnabledToSet) {
      dispatch(toggleContainerQueuedSigningAsync(containerFileEntity));
      return;
    }
    // Kui lülitad välja, siis salvesta fail selliselt, et queueEnabled = false ja saada välja kõik kutsed
    if (!queueEnabledToSet) {
      dispatch(toggleContainerQueuedSigningAsync(containerFileEntity)).then(() => {
        if (invitesOutOfSignatures?.length > 0) {
          sendAllSignInvitesForContainer(containerFileEntity.uuid).then((signatureHolders) => {
            dispatch(signaturesModified(signatureHolders));
          });
        }
      });
    }
  };

  const isInitialQueueSigning = signatures.length === 0 && containerFileEntity.queueEnabled;
  const isRegularSigning = !isInitialQueueSigning;

  // Sisse lülitamine - Kui olemasolevaid kutseid on null, siis saab sisse lülitada iga kasutaja
  // Välja lülitamine - Kui kasutaja on projekti admin, või queueEnabler
  const isQueueToggleShown = containerFileEntity.queueEnabled || signatures.length === 0;
  const canUserToggleQueueEnabled = isCurrentUserProjectAdmin || containerFileEntity.queueEnabler === currentUser.id || signatures.length === 0;

  return (
    <>
      <div className="mb-4 px-4 pb-2">
        <div className="flex items-center justify-between">
          <h3 className="inline-block py-3">{t("CONTAINER.SIGNATURES.HEADER")}</h3>
          {isQueueToggleShown && (
            <span className="flex items-center">
              <span className="pr-3">{t("CONTAINER.ENABLE_QUEUE")}</span>
              <BhToggleSwitch
                value={containerFileEntity.queueEnabled}
                property={"queueEnabled"}
                onClickAction={() => showDisableQueueModalOrToggleQueue(!containerFileEntity.queueEnabled)}
                disabled={!canUserToggleQueueEnabled}
              />
            </span>
          )}
        </div>
        <SignaturesList signatures={signatures} containerFileEntity={containerFileEntity} queueEnabled={containerFileEntity.queueEnabled} />
        <div className="pt-2 pb-8">
          {isRegularSigning && <RegularSignatureAddUserInput signatures={signatures} containerFileEntity={containerFileEntity} />}
          {/* If no signInvites are created and the container is queue enabled, then a special component appears https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H/Bauhub-design-system?node-id=5082%3A184685 */}
          {isInitialQueueSigning && <InitialQueueSignaturesContainer containerFileEntity={containerFileEntity} />}
        </div>
      </div>
    </>
  );
};

export default ContainerSignaturesContainer;
