import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { ChecklistBaseCategory, IChecklistBase } from "@/model/checklist/IChecklistBase";
import { EntityId } from "@reduxjs/toolkit";
import { IChecklistBaseVersion } from "@/model/checklist/IChecklistBaseVersion";
import { IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import { IFileEntity } from "@/model/files/IFileEntity";
import { IChecklistBaseVersionRowAttachment } from "@/model/checklist/IChecklistBaseVersionRowAttachment";

export function fetchChecklistBasesForUser(projectId: EntityId, category: ChecklistBaseCategory): Promise<Array<IChecklistBase>> {
  return bauhubGet("/project/" + projectId + "/checklist/base/user/category/" + category);
}

export function fetchCompanyChecklistBases(companyId: EntityId): Promise<Array<IChecklistBase>> {
  return bauhubGet("/company/" + companyId + "/checklist/base");
}

export function fetchCompanyChecklistBasesForProject(companyId: EntityId, projectId: EntityId): Promise<Array<IChecklistBase>> {
  return bauhubGet("/company/" + companyId + "/project/" + projectId + "/checklist/base");
}

export function fetchCountOfCompanyBuiltChecklistBases(companyId: EntityId): Promise<number> {
  return bauhubGet("/company/" + companyId + "/checklist/base/usage");
}

export function saveNewCompanyChecklistBase(checklistBase: IChecklistBase, companyId: EntityId): Promise<Array<IChecklistBase>> {
  return bauhubPost("/company/" + companyId + "/checklist/base/new", checklistBase);
}

export function deleteChecklistBase(companyId: EntityId, checklistBaseId: EntityId): Promise<IChecklistBase> {
  return bauhubPost("/company/" + companyId + "/checklist/base/" + checklistBaseId + "/delete");
}

export function deleteChecklistBaseVersion(companyId: EntityId, checklistBaseId: EntityId, checklistBaseVersionId: EntityId): Promise<IChecklistBaseVersion> {
  return bauhubPost("/company/" + companyId + "/checklist/base/" + checklistBaseId + "/version/" + checklistBaseVersionId + "/delete");
}

export function saveNewChecklistBaseVersion(companyId: EntityId, checklistBaseId: EntityId): Promise<IChecklistBaseVersion> {
  return bauhubPost("/company/" + companyId + "/checklist/base/" + checklistBaseId + "/version");
}

export function fetchCompanyChecklistBase(companyId: EntityId, checklistBaseId: EntityId): Promise<IChecklistBase> {
  return bauhubGet("/company/" + companyId + "/checklist/base/" + checklistBaseId);
}

export function fetchChecklistBaseVersions(companyId: EntityId, checklistBaseId: EntityId): Promise<Array<IChecklistBaseVersion>> {
  return bauhubGet("/company/" + companyId + "/checklist/base/" + checklistBaseId + "/versions");
}

export function fetchChecklistBaseVersionRowsForEditing(companyId: EntityId, checklistBaseId: EntityId, checklistBaseVersionId: EntityId): Promise<Array<IChecklistBaseVersionRow>> {
  return bauhubGet("/company/" + companyId + "/checklist/base/" + checklistBaseId + "/version/" + checklistBaseVersionId + "/rows/edit");
}

export function renameChecklistBase(companyId: EntityId, checklistBase: IChecklistBase): Promise<IChecklistBase> {
  return bauhubPost("/company/" + companyId + "/checklist/base/rename", checklistBase);
}

export function saveChecklistBase(companyId: EntityId, checklistBase: IChecklistBase): Promise<IChecklistBase> {
  return bauhubPost("/company/" + companyId + "/checklist/base", checklistBase);
}

export function saveChecklistBaseVersion(companyId: EntityId, checklistBaseVersion: IChecklistBaseVersion): Promise<IChecklistBaseVersion> {
  return bauhubPost("/company/" + companyId + "/checklist/base/version", checklistBaseVersion);
}

export function saveChecklistBaseVersionRow(companyId: EntityId, checklistBaseId: EntityId, checklistBaseVersionRow: IChecklistBaseVersionRow): Promise<IChecklistBaseVersionRow> {
  return bauhubPost("/company/" + companyId + "/checklist/base/" + checklistBaseId + "/version/row", checklistBaseVersionRow);
}

export function attachNewFilesToChecklistBaseVersionRow(companyId: EntityId, checklistBaseId: EntityId, baseRowId: EntityId, fileIds: Array<EntityId>, isUpload: boolean): Promise<Array<IFileEntity>> {
  return bauhubPost("/company/" + companyId + "/checklist/base/" + checklistBaseId + "/version/row/" + baseRowId + "/file/" + fileIds + "/upload/" + isUpload);
}

export function deleteFileFromChecklistBaseVersionRow(companyId: EntityId, checklistBaseId: EntityId, baseRowId: EntityId, fileId: EntityId): Promise<IChecklistBaseVersionRowAttachment> {
  return bauhubPost("/company/" + companyId + "/checklist/base/" + checklistBaseId + "/version/row/" + baseRowId + "/file/" + fileId + "/delete");
}

export function deleteChecklistBaseVersionRow(companyId: EntityId, checklistBaseId: EntityId, checklistBaseVersionId: EntityId, checklistBaseVersionRowId: EntityId): Promise<IChecklistBaseVersion> {
  return bauhubPost("/company/" + companyId + "/checklist/base/" + checklistBaseId + "/version/" + checklistBaseVersionId + "/row/" + checklistBaseVersionRowId + "/delete");
}

export function saveNewChecklistBaseVersionRow(companyId: EntityId, checklistBaseId: EntityId, checklistBaseVersionRow: IChecklistBaseVersionRow): Promise<IChecklistBaseVersion> {
  return bauhubPost("/company/" + companyId + "/checklist/base/" + checklistBaseId + "/version/row/new", checklistBaseVersionRow);
}

export function publishChecklistBaseVersion(companyId: EntityId, checklistBaseId: EntityId, checklistBaseVersionId: EntityId): Promise<IChecklistBaseVersion> {
  return bauhubPost("/company/" + companyId + "/checklist/base/" + checklistBaseId + "/version/" + checklistBaseVersionId + "/publish");
}

export function generatePdfForChecklistBaseVersion(companyId: EntityId, checklistBaseId: EntityId, checklistBaseVersionId: EntityId): Promise<IFileEntity> {
  return bauhubPost("/company/" + companyId + "/checklist/base/" + checklistBaseId + "/version/" + checklistBaseVersionId + "/pdf");
}

export function copyChecklistBaseToCompany(companyId: EntityId, checklistBaseId: EntityId): Promise<IChecklistBase> {
  return bauhubPost("/company/" + companyId + "/checklist/base/" + checklistBaseId + "/copy");
}

export function copyChecklistBaseToProject(companyId: EntityId, projectId: EntityId, checklistBaseId: EntityId): Promise<IChecklistBase> {
  return bauhubPost("/company/" + companyId + "/project/" + projectId + "/checklist/base/" + checklistBaseId + "/copy");
}
