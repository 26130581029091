import React, { FC } from "react";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import BhInputSimple from "@components/input/BhInputSimple";
import { convertChecklistParticipantsToUsers, IChecklistParticipant } from "@/model/checklist/IChecklistParticipant";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  removeUserFromChecklistAsync,
  saveChecklistAsync,
  saveChecklistRoundAsync,
  saveChecklistRoundBaseFileAsync,
  saveChecklistTagAsync,
  saveUserToChecklistAsync,
  selectCanCurrentUserEditChecklist,
  selectChecklistById,
  selectChecklistLanguageForTranslationById,
  selectChecklistRoundById,
  selectChecklistTags,
  selectIsChecklistRoundEditable
} from "@/app/store/checklist/checklistsSlice";
import BhDatePicker from "@components/input/BhDatePicker";
import BhInputWithFetchedUserChips from "@components/input/BhInputWithFetchedUserChips";
import { IUser } from "@/model/IUser";
import BhInputWithTags from "@components/input/BhInputWithTags";
import { IChecklistTag } from "@/model/checklist/IChecklistTag";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhAttachmentsUploadContainer from "@components/upload/BhAttachmentsUploadContainer";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons";
import { pdftronExtensions } from "@/utilities/fileEntity/fileEntityUtilities";
import { Authority } from "@/model/IUserAuthority";
import { selectCurrentUser } from "@/app/store/userSlice";

interface Props {
  checklistId: EntityId;
  checklistRoundId: EntityId;
}

const ChecklistGeneralInfo: FC<Props> = ({ checklistId, checklistRoundId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const checklist = useAppSelector((state) => selectChecklistById(state, checklistId));
  const checklistRound = useAppSelector((state) => selectChecklistRoundById(state, checklistRoundId));
  const projectChecklistTags = useAppSelector(selectChecklistTags) || ([] as Array<IChecklistTag>);
  const projectId = useAppSelector(selectCurrentProjectId);
  const isRoundEditable = useAppSelector((state) => selectIsChecklistRoundEditable(state, projectId, checklistRoundId));
  const canUserEdit = useAppSelector((state) => selectCanCurrentUserEditChecklist(state, projectId, checklistId));
  const checklistLanguage = useAppSelector((state) => selectChecklistLanguageForTranslationById(state, checklistId));
  const allowedUploadFileTypesForBaseFile = pdftronExtensions.map((ext) => "." + ext).join(",");

  if (!checklist || !checklistRound || !checklist.checklistBaseVersion) {
    return null;
  }

  const usersWithWriteAuthority = checklist.checklistParticipants
    ? convertChecklistParticipantsToUsers(checklist.checklistParticipants.filter((participant) => participant.authority === Authority.WRITE).sort((a, b) => (a.name > b.name ? 1 : -1)))
    : [];

  const usersWithReadAuthority = checklist.checklistParticipants
    ? convertChecklistParticipantsToUsers(checklist.checklistParticipants.filter((participant) => participant.authority === Authority.READ).sort((a, b) => (a.name > b.name ? 1 : -1)))
    : [];

  const disabledUserIds =
    usersWithWriteAuthority.length > 0 ? usersWithWriteAuthority.filter((user) => user.username === checklist.createdBy || user.id === currentUser.id).map((user) => user.id) : [];

  const saveChecklist = (changedObject: any) => {
    if (changedObject.name && !changedObject.name.length) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          children: t("INPUT.NOT_EMPTY")
        })
      );
      return;
    }
    const checklistToSave = { ...checklist, ...changedObject };
    dispatch(saveChecklistAsync(checklistToSave));
  };

  const saveChecklistRound = (changedObject: any) => {
    if (!isRoundEditable) {
      return;
    }
    const checklistRoundToSave = { ...checklistRound, ...changedObject };
    dispatch(saveChecklistRoundAsync(checklistRoundToSave));
  };

  const addBauhubUserWithWriteAuthority = (user: IUser) => {
    saveBauhubUserAsParticipant(user, Authority.WRITE);
  };

  const addBauhubUserWithReadAuthority = (user: IUser) => {
    saveBauhubUserAsParticipant(user, Authority.READ);
  };

  const saveBauhubUserAsParticipant = (user: IUser, authority: Authority) => {
    const checklistParticipant = {
      name: user.firstName + " " + user.lastName,
      userEntityId: user.id,
      checklistId: checklistId,
      authority: authority
    } as IChecklistParticipant;
    dispatch(saveUserToChecklistAsync(checklistParticipant));
  };

  const addNewUserToParticipants = (username: string) => {
    const checklistParticipant = {
      name: username,
      checklistId: checklistId,
      authority: Authority.READ
    } as IChecklistParticipant;
    dispatch(saveUserToChecklistAsync(checklistParticipant));
  };

  const removeUserFromParticipants = (user: any) => {
    let userToRemove;
    if (user && user.id) {
      userToRemove = checklist.checklistParticipants.find((p) => p.userEntityId === user.id);
    } else if (user) {
      userToRemove = checklist.checklistParticipants.find((p) => p.name === user.name);
    }
    if (userToRemove) {
      dispatch(removeUserFromChecklistAsync(userToRemove));
    }
  };

  const saveTag = (tag: IChecklistTag) => {
    if (checklist.checklistTags.some((t: any) => t.name.toLowerCase() === tag.name.toLowerCase())) {
      return;
    }
    const tagToSave = { name: tag.name, checklistId: checklistId, projectId: checklist.projectId } as IChecklistTag;
    dispatch(saveChecklistTagAsync(tagToSave));
  };

  const removeTag = (tag: IChecklistTag) => {
    const tagToRemove = { ...tag, ...{ deleted: true } };
    dispatch(saveChecklistTagAsync(tagToRemove));
  };

  const saveChecklistRoundBaseFile = (files: any, isUpload: boolean) => {
    if (files.uploaded.length > 0) {
      dispatch(saveChecklistRoundBaseFileAsync({ checklistRoundId: checklistRoundId, fileId: files.uploaded[0].id, isUpload: isUpload }));
    }
  };

  return (
    <div className="bh-bg-smoke flex w-full flex-row rounded p-5">
      <div>
        <h3>{t("CHECKLIST.GENERAL_INFO", { lng: checklistLanguage })}</h3>
      </div>
      <div className="flex w-4/5 flex-col gap-y-1">
        <BhSideBySideInput label={t("CHECKLIST.CHECKLIST_NAME", { lng: checklistLanguage })}>
          <BhInputSimple initialValue={checklist.name} property={"name"} saveCallback={saveChecklist} disabled={!canUserEdit} />
        </BhSideBySideInput>
        {checklist.checklistBaseVersion.customLabel.siteNameLabel.visible && (
          <BhSideBySideInput label={checklist.checklistBaseVersion.customLabel.siteNameLabel.value}>
            <BhInputSimple initialValue={checklist.siteName} property={"siteName"} saveCallback={saveChecklist} disabled={!canUserEdit} />
          </BhSideBySideInput>
        )}
        {checklist.checklistBaseVersion.customLabel.timeLabel.visible && (
          <BhSideBySideInput label={checklist.checklistBaseVersion.customLabel.timeLabel.value}>
            <div className="w-full">
              <BhDatePicker
                property={"checkTime"}
                initialValue={new Date(checklistRound.checkTime)}
                onChangeCallback={saveChecklistRound}
                classes={"w-full"}
                returnISOString={true}
                disabled={!isRoundEditable || !canUserEdit}
              />
            </div>
          </BhSideBySideInput>
        )}
        {checklist.checklistBaseVersion.customLabel.createdByLabel.visible && (
          <BhSideBySideInput label={checklist.checklistBaseVersion.customLabel.createdByLabel.value}>
            <BhInputWithFetchedUserChips
              currentValues={usersWithWriteAuthority}
              onSelect={addBauhubUserWithWriteAuthority}
              onRemove={removeUserFromParticipants}
              disabled={!canUserEdit}
              disabledUserIds={disabledUserIds}
              usersToExclude={[...usersWithReadAuthority, ...usersWithWriteAuthority]}
            />
          </BhSideBySideInput>
        )}
        {checklist.checklistBaseVersion.customLabel.participantsLabel.visible && (
          <BhSideBySideInput label={checklist.checklistBaseVersion.customLabel.participantsLabel.value}>
            <BhInputWithFetchedUserChips
              currentValues={usersWithReadAuthority}
              onSelect={addBauhubUserWithReadAuthority}
              onRemove={removeUserFromParticipants}
              onNewValueSelect={addNewUserToParticipants}
              disabled={!canUserEdit}
              usersToExclude={[...usersWithReadAuthority, ...usersWithWriteAuthority]}
            />
          </BhSideBySideInput>
        )}
        <BhSideBySideInput
          label={
            <div>
              <div>{t("CHECKLIST.TAGS", { lng: checklistLanguage })}</div>
              <div className="font-normal">{t("CHECKLIST.TAGS.TOOLTIP")}</div>
            </div>
          }
        >
          <BhInputWithTags
            onRemove={removeTag}
            onSelect={saveTag}
            currentValues={checklist.checklistTags && checklist.checklistTags.length > 0 ? checklist.checklistTags : []}
            dropdownValues={projectChecklistTags}
            disabled={!canUserEdit}
          />
        </BhSideBySideInput>
        {!checklistRound.baseFileEntityId && (
          <BhSideBySideInput label={t("CHECKLIST.BASE_FILE", { lng: checklistLanguage })}>
            <BhAttachmentsUploadContainer
              disabled={!isRoundEditable}
              saveCallback={saveChecklistRoundBaseFile}
              existingAttachments={[]}
              button={
                <BhTextOnlyButton icon={faPaperclip} buttonProps={{ disabled: !isRoundEditable }}>
                  {t("CHECKLIST.ADD_MAIN_PLAN", { lng: checklistLanguage })}
                </BhTextOnlyButton>
              }
              property={"uploaded"}
              fileUploadAllowed={true}
              chooseFromProjectAllowed={true}
              onlyPdfTronFilesAllowedFromProjectFiles={true}
              fileTypesAllowedForUpload={allowedUploadFileTypesForBaseFile}
            />
          </BhSideBySideInput>
        )}
      </div>
    </div>
  );
};

export default ChecklistGeneralInfo;
