import React, { FC, ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldCheck } from "@fortawesome/pro-solid-svg-icons/faShieldCheck";
import { faShieldHalved } from "@fortawesome/pro-solid-svg-icons/faShieldHalved";
import { ProjectSecurityLevel } from "@/model/IProject";
import { useTranslation } from "react-i18next";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  type: ProjectSecurityLevel;
  classes?: string;
}

const BhProjectStatusBadge: FC<Props> = ({ type, classes }) => {
  const { t } = useTranslation();

  const defaultClasses = "bh-bg-pigeon-20 bh-text-deep-ocean-80 my-1 ml-1 mr-2 w-6 self-stretch";

  if (type === ProjectSecurityLevel.LOW) {
    return null;
  }

  const valuesMap: Record<ProjectSecurityLevel, { icon?: ReactNode; tooltipText?: string }> = {
    [ProjectSecurityLevel.LOW]: {},
    [ProjectSecurityLevel.MEDIUM]: { icon: <FontAwesomeIcon icon={faShieldHalved} />, tooltipText: t("PROJECT.SECURITY.MEDIUM_TOOLTIP") || "" },
    [ProjectSecurityLevel.HIGH]: { icon: <FontAwesomeIcon icon={faShieldCheck} />, tooltipText: t("PROJECT.SECURITY.HIGH_TOOLTIP") || "" }
  };

  return (
    <div className={classNames(classes || defaultClasses, "flex items-center justify-center rounded")} data-tooltip-id="bh-tooltip" data-tooltip-content={valuesMap[type]?.tooltipText}>
      {valuesMap[type]?.icon}
    </div>
  );
};
export default BhProjectStatusBadge;
