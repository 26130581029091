import React, { FC } from "react";
import NewFolderModal from "@/views/home/project/detail/directory/directoryModals/NewFolderModal";
import { useAppSelector } from "@/app/hooks";
import {
  selectAuthoritiesModalOpen,
  selectCopyModalOpen,
  selectDeleteConfirmationModalOpen,
  selectFileHistoryModalOpen,
  selectMoveModalOpen,
  selectNewFolderModalOpen,
  selectRenameModalOpen,
  selectUndoRevisionModalOpen
} from "@/app/store/filesSlice";
import RenameModal from "@/views/home/project/detail/directory/directoryModals/RenameModal";
import DeleteConfirmationModal from "@/views/home/project/detail/directory/directoryModals/DeleteConfirmationModal";
import UndoRevisionModal from "@/views/home/project/detail/directory/directoryModals/revisionModal/UndoRevisionModal";
import CopyAndMoveModals from "@/views/home/project/detail/directory/directoryModals/copyAndMoveModals/CopyAndMoveModals";
import { EntityId } from "@reduxjs/toolkit";
import DirectoryFileHistoryModal from "@/views/home/project/detail/directory/directoryModals/log/DirectoryFileHistoryModal";
import AuthoritiesModal from "@/views/home/project/detail/directory/directoryModals/authoritiesModal/AuthoritiesModal";

interface Props {
  currentDirectoryId: EntityId;
}

const DirectoryModals: FC<Props> = ({ currentDirectoryId }) => {
  const newFolderModalOpen = useAppSelector(selectNewFolderModalOpen);
  const renameModalOpen = useAppSelector(selectRenameModalOpen);
  const deleteConfirmationModalOpen = useAppSelector(selectDeleteConfirmationModalOpen);
  const copyModalOpen = useAppSelector(selectCopyModalOpen);
  const moveModalOpen = useAppSelector(selectMoveModalOpen);
  const undoRevisionModalOpenOpen = useAppSelector(selectUndoRevisionModalOpen);
  const fileHistoryModalOpen = useAppSelector(selectFileHistoryModalOpen);
  const authoritiesModalOpen = useAppSelector(selectAuthoritiesModalOpen);

  return (
    <>
      {newFolderModalOpen && <NewFolderModal />}
      {renameModalOpen && <RenameModal />}
      {deleteConfirmationModalOpen && <DeleteConfirmationModal />}
      {copyModalOpen && <CopyAndMoveModals isCopyModal={true} isMoveModal={false} currentDirectoryId={currentDirectoryId} />}
      {moveModalOpen && <CopyAndMoveModals isCopyModal={false} isMoveModal={true} currentDirectoryId={currentDirectoryId} />}
      {undoRevisionModalOpenOpen && <UndoRevisionModal />}
      {fileHistoryModalOpen && <DirectoryFileHistoryModal />}
      {authoritiesModalOpen && <AuthoritiesModal />}
    </>
  );
};

export default DirectoryModals;
