import { DailyMailFolderSelection, IUserDailyMailProjectFileEntityRelation, IUserEmailSetting, UserEmailSettingsType } from "@/model/profile/IUserEmailSettings";
import { IFileDirectoryDTO } from "@/model/utilities/IFileDirectoryDTO";
import { ITaskBoard } from "@/model/taskBoard/ITaskBoard";
import { EntityId } from "@reduxjs/toolkit";

export interface IUserProfileModals {
  open: IUserProfileModalsOpen;
  modalType?: UserEmailSettingsType;
  userMailSettingsModal: IUserMailSettingsModal;
}

export interface IUserProfileModalsOpen {
  userMailSettingsModal: boolean;
  profileAuthSettingsPhoneModal: boolean;
  profileAuthSettingsPersonalizationModal: boolean;
}

export const UserProfileModalsInitialState = {
  open: {
    userMailSettingsModal: false,
    profileAuthSettingsPhoneModal: false,
    profileAuthSettingsPersonalizationModal: false
  },
  userMailSettingsModal: {
    projectRelatedSettings: [] as Array<IUserEmailSetting>,
    taskMailRelatedSettings: [] as Array<IUserEmailSetting>,
    activeProjectUserDailyMailFileEntityRelations: [] as Array<IUserDailyMailProjectFileEntityRelation>,
    activeProjectSelectedFileDirectories: [] as Array<IFileDirectoryDTO>,
    activeProjectSelectedDocumentDirectories: [] as Array<IFileDirectoryDTO>,
    activeProjectTaskBoards: [] as Array<ITaskBoard>
  } as IUserMailSettingsModal
};

export interface IUserMailSettingsModal {
  projectRelatedSettings: Array<IUserEmailSetting>;
  dailyMailRelatedSettings: Array<IUserEmailSetting>;
  taskMailRelatedSettings: Array<IUserEmailSetting>;
  activeProjectId?: EntityId;
  activeProjectFileRootDirectory?: IFileDirectoryDTO;
  activeProjectDocumentRootDirectory?: IFileDirectoryDTO;
  activeProjectUserDailyMailFileEntityRelations: Array<IUserDailyMailProjectFileEntityRelation>;
  activeProjectSelectedFileDirectories: Array<IFileDirectoryDTO>;
  activeProjectSelectedDocumentDirectories: Array<IFileDirectoryDTO>;
  activeProjectTaskBoards: Array<ITaskBoard>;
}

export const getUserMailSettingsModalInfoCleared = (projectSettings: Array<IUserEmailSetting>, resetProjectSettings: boolean) => {
  const clearedInfo = {
    projectRelatedSettings: [] as Array<IUserEmailSetting>,
    taskMailRelatedSettings: [] as Array<IUserEmailSetting>,
    activeProjectUserDailyMailFileEntityRelations: [] as Array<IUserDailyMailProjectFileEntityRelation>,
    activeProjectSelectedFileDirectories: [] as Array<IFileDirectoryDTO>,
    activeProjectSelectedDocumentDirectories: [] as Array<IFileDirectoryDTO>,
    activeProjectTaskBoards: [] as Array<ITaskBoard>
  } as IUserMailSettingsModal;

  if (!resetProjectSettings) {
    clearedInfo.projectRelatedSettings = projectSettings;
  }
  return clearedInfo;
};
