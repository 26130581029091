import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { faSlashForward } from "@fortawesome/pro-solid-svg-icons/faSlashForward";
import { faQuestion } from "@fortawesome/pro-solid-svg-icons/faQuestion";
import { useTranslation } from "react-i18next";

interface Props {
  checkpointValue: any;
  classes?: string;
}

const ChecklistRoundProgressBar: FC<Props> = ({ checkpointValue, classes }) => {
  const { t } = useTranslation();

  const progressBarColorMap: Record<string, string> = {
    YES: "bh-bg-success-green-110",
    NO: "bh-bg-error-red",
    NA: "bh-bg-pigeon-60",
    UA: "bh-bg-pigeon-20"
  };

  const progressBarWidthMap: Record<string, string> = {
    YES: ((checkpointValue.yes * 100) / checkpointValue.total).toString().concat("%"),
    NO: ((checkpointValue.no * 100) / checkpointValue.total).toString().concat("%"),
    NA: ((checkpointValue.na * 100) / checkpointValue.total).toString().concat("%"),
    UA: ((checkpointValue.ua * 100) / checkpointValue.total).toString().concat("%")
  };

  return (
    <div className="flex flex-row items-center">
      <div className={classNames(classes, "flex h-5 w-full flex-row overflow-hidden leading-normal")}>
        <div
          className={classNames(
            "flex cursor-pointer flex-row items-center justify-start",
            checkpointValue.yes > 0 && checkpointValue.yes !== checkpointValue.total && "bh-border-white h-full border-r-2",
            progressBarColorMap["YES"]
          )}
          style={{ width: progressBarWidthMap["YES"] }}
          data-tooltip-id="bh-tooltip"
          data-tooltip-content={t("CHECKLIST.CHECKPOINT.YES") + " — " + checkpointValue.yes}
        >
          <FontAwesomeIcon icon={faCheck} className="bh-text-white h-3 w-3 pl-2" />
        </div>
        <div
          className={classNames(
            "flex cursor-pointer flex-row items-center justify-start",
            checkpointValue.no > 0 && checkpointValue.no !== checkpointValue.total && "bh-border-white h-full border-r-2",
            progressBarColorMap["NO"]
          )}
          style={{ width: progressBarWidthMap["NO"] }}
          data-tooltip-id="bh-tooltip"
          data-tooltip-content={t("CHECKLIST.CHECKPOINT.NO") + " — " + checkpointValue.no}
        >
          <FontAwesomeIcon icon={faXmark} className="bh-text-white h-3 w-3 pl-2" />
        </div>
        <div
          className={classNames(
            "flex cursor-pointer flex-row items-center justify-start",
            checkpointValue.na > 0 && checkpointValue.na !== checkpointValue.total && "bh-border-white h-full border-r-2",
            progressBarColorMap["NA"]
          )}
          style={{ width: progressBarWidthMap["NA"] }}
          data-tooltip-id="bh-tooltip"
          data-tooltip-content={t("CHECKLIST.CHECKPOINT.NA") + " — " + checkpointValue.na}
        >
          <FontAwesomeIcon icon={faSlashForward} className="bh-text-white h-3 w-3 pl-2" />
        </div>
        <div
          className={classNames("flex cursor-pointer flex-row items-center justify-start", progressBarColorMap["UA"])}
          style={{ width: progressBarWidthMap["UA"] }}
          data-tooltip-id="bh-tooltip"
          data-tooltip-content={t("CHECKLIST.CHECKPOINT.UA") + " — " + checkpointValue.ua}
        >
          <FontAwesomeIcon icon={faQuestion} className="bh-text-pigeon h-3 w-3 pl-2" />
        </div>
      </div>
    </div>
  );
};

export default ChecklistRoundProgressBar;
