import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import FileNameLink from "@/features/fileContainer/FileNameLink";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons";
import { fetchPublicShareboxFileUrl } from "@/api/shareboxAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { formatDateTime } from "@/utilities/dateUtility";
import { IFileEntity } from "@/model/files/IFileEntity";
import { EntityId } from "@reduxjs/toolkit";
import { shareboxFileEntityIdsSelected } from "@/app/store/shareboxesSlice";
import { useAppDispatch } from "@/app/hooks";
import { isImage } from "@/utilities/fileEntity/fileEntityUtilities";
import { setCurrentImage, setLightboxFileIds, toggleLightboxOpen } from "@/app/store/lightboxSlice";

interface Props {
  fileEntity: IFileEntity;
  shareboxId: EntityId;
  shareboxUuid: string;
  publicSharebox: boolean;
  attachmentFileIds?: Array<EntityId>;
}

const ShareboxFileContainerFC: FC<Props> = ({ fileEntity, shareboxId, shareboxUuid, publicSharebox, attachmentFileIds }) => {
  const dispatch = useAppDispatch();

  if (!fileEntity) {
    return null;
  }

  const downloadFile = () => {
    if (fileEntity.uuid && shareboxUuid) {
      fetchPublicShareboxFileUrl(shareboxUuid, fileEntity.uuid, true, false).then((response) => {
        imitateUrlDownload(response.value);
      });
    }
  };

  const handleFileClick = (fileEntity: IFileEntity) => {
    if (isImage(fileEntity)) {
      dispatch(toggleLightboxOpen());
      dispatch(setCurrentImage(fileEntity));
      dispatch(setLightboxFileIds(attachmentFileIds || []));
    } else {
      downloadFile();
    }
  };

  const handleChange = (id: EntityId, e: { target: { checked: any } }) => {
    dispatch(shareboxFileEntityIdsSelected({ shareboxId: shareboxId, fileEntityIds: [fileEntity.id], selected: e.target.checked }));
  };

  return (
    <div className={classNames(fileEntity.selected ? "bh-bg-mint" : "bh-bg-white hover:bh-bg-mint-30", "bh-border-pigeon-40 bh-hover-container group group block flex h-14 items-center border-b")}>
      {publicSharebox && (
        <>
          <div className={classNames(fileEntity.selected && "bh-bg-bauhub-green-120", "h-full w-0.5")} />
          <div className="flex w-8 flex-none items-center justify-center">
            <input
              className="hover:bh-border-deep-ocean bh-border-pigeon-60 h-5 w-5 rounded focus:ring-0 focus:ring-offset-0"
              type="checkbox"
              checked={fileEntity.selected || false}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => handleChange(fileEntity.id, e)}
            />
          </div>
        </>
      )}
      <div className="relative flex w-9 flex-none justify-center pl-3 text-center">
        <BhFileCardIcon fileEntity={fileEntity} fileCardSize={FileCardSize.LARGE} shareboxUuid={shareboxUuid} />
      </div>
      <div className="flex flex-grow flex-col overflow-hidden px-3">
        <div>
          <FileNameLink fileEntity={fileEntity} openInNewTab={true} onFileNameClickCallback={publicSharebox ? handleFileClick : undefined} attachmentFileIds={attachmentFileIds} />
        </div>
      </div>
      <div className="w-18 flex-none px-3 text-center">
        <span>{fileEntity.revision}</span>
      </div>
      <div className="w-48 flex-none px-3">
        <div className="flex flex-col">
          <div>
            <span className="leading-4">{formatDateTime(fileEntity.created)}</span>
          </div>
          <div className="bh-text-color-light-gray flex flex-row items-center text-xs">
            <span className="truncate">{fileEntity.createdByFullName}</span>
          </div>
        </div>
      </div>
      <div className="w-14 flex-none px-3 text-right">
        <BhIconButton icon={faArrowDownToLine} buttonProps={{ onClick: () => downloadFile() }} />
      </div>
    </div>
  );
};

const ShareboxFileContainer = React.memo(ShareboxFileContainerFC);
export default ShareboxFileContainer;
