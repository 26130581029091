import { createAsyncThunk, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchProjectDocumentRootDirectoryForDailyMail,
  fetchProjectFileRootDirectoryForDailyMail,
  fetchUserDailyMailProjectFileEntityRelation,
  fetchUserMailSettingsInfo,
  fetchUserMailSettingsInfos,
  saveAllSelectedDailyMailDirectories,
  saveDocumentFolderSelection,
  saveFileFolderSelection,
  saveUserDailyMailSettingsInfo,
  saveUserMailSettingsInfo
} from "@/api/userPreferenceAPI";
import { RootState } from "@/app/store";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { getUserMailSettingsModalInfoCleared, IUserProfileModals, IUserProfileModalsOpen, UserProfileModalsInitialState } from "@/model/profile/IUserProfileModals";
import {
  DailyMailFolderSelection,
  IChangeDailyMailFolderSelectionRequestBody,
  IUserDailyMailProjectFileEntityRelation,
  IUserEmailSetting,
  UserEmailSettingsType
} from "@/model/profile/IUserEmailSettings";
import { IFileDirectoryDTO } from "@/model/utilities/IFileDirectoryDTO";
import { fetchProjectTaskBoards } from "@/api/taskBoardAPI";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import { selectProjectNotificationCounts } from "@/app/store/userNotificationsSlice";

export interface UserPreference {
  status: BhStateStatusType;
  userModals: IUserProfileModals;
}

const initialState: UserPreference = {
  status: BhStateStatusType.INITIAL,
  userModals: UserProfileModalsInitialState
};

export const fetchUserMailSettingsInfoAsync = createAsyncThunk("user/mailSettingInfo", async (dto: { type: UserEmailSettingsType; resource?: string; resourceIds?: Array<EntityId> }) => {
  return fetchUserMailSettingsInfo(dto.type, dto.resource, dto.resourceIds);
});

export const fetchUserMailSettingsInfosForDailyMailAsync = createAsyncThunk(
  "user/mail/daily/mailSettingInfo",
  async (dto: { types: Array<UserEmailSettingsType>; resource?: string; resourceIds?: Array<EntityId> }) => {
    return fetchUserMailSettingsInfos(dto.types, dto.resource, dto.resourceIds);
  }
);

export const saveUserMailSettingsInfoAsync = createAsyncThunk("user/mailSettingInfo/save", async (userEmailSetting: IUserEmailSetting) => {
  return saveUserMailSettingsInfo(userEmailSetting);
});

export const saveUserDailyMailSettingsInfoAsync = createAsyncThunk("user/mailSettingInfo/daily/save", async (userEmailSetting: IUserEmailSetting) => {
  return saveUserDailyMailSettingsInfo(userEmailSetting);
});

export const saveFileFolderSelectionAsync = createAsyncThunk("user/mail/daily/fileFolder/save", async (changeDailyMailFolderSelectionRequestBody: IChangeDailyMailFolderSelectionRequestBody) => {
  return saveFileFolderSelection(changeDailyMailFolderSelectionRequestBody);
});

export const saveDocumentFolderSelectionAsync = createAsyncThunk(
  "user/mail/daily/documentFolder/save",
  async (changeDailyMailFolderSelectionRequestBody: IChangeDailyMailFolderSelectionRequestBody) => {
    return saveDocumentFolderSelection(changeDailyMailFolderSelectionRequestBody);
  }
);

export const saveAllSelectedDailyMailDirectoriesAsync = createAsyncThunk(
  "user/mail/daily/selected/save/all",
  async (dto: { projectId: EntityId; dailyMailProjectFileEntityRelations: Array<IUserDailyMailProjectFileEntityRelation> }) => {
    return saveAllSelectedDailyMailDirectories(dto.projectId, dto.dailyMailProjectFileEntityRelations);
  }
);

export const fetchProjectFileRootDirectoryForDailyMailAsync = createAsyncThunk("user/mail/daily/file/root", async (projectId: EntityId) => {
  return fetchProjectFileRootDirectoryForDailyMail(projectId);
});

export const fetchProjectDocumentRootDirectoryForDailyMailAsync = createAsyncThunk("user/mail/daily/document/root", async (projectId: EntityId) => {
  return fetchProjectDocumentRootDirectoryForDailyMail(projectId);
});

export const fetchUserDailyMailProjectFileEntityRelationAsync = createAsyncThunk("user/mail/daily/fileEntityRelations", async (projectId: EntityId) => {
  return fetchUserDailyMailProjectFileEntityRelation(projectId);
});

export const fetchProjectTaskBoardsForTaskMailAsync = createAsyncThunk("user/mail/task/taskBoards", async (projectId: EntityId) => {
  return fetchProjectTaskBoards(projectId);
});

export const fetchUserMailSettingsInfoForTaskMailAsync = createAsyncThunk(
  "user/mail/task/mailSettingInfo",
  async (dto: { type: UserEmailSettingsType; resource?: string; resourceIds?: Array<EntityId> }) => {
    return fetchUserMailSettingsInfo(dto.type, dto.resource, dto.resourceIds);
  }
);

export const saveUserMailSettingsInfoForTaskMailAsync = createAsyncThunk("user/mail/task/mailSettingInfo/save", async (userEmailSetting: IUserEmailSetting) => {
  return saveUserMailSettingsInfo(userEmailSetting);
});

export const userPreferenceSlice = createSlice({
  name: "userPreference",
  initialState,
  reducers: {
    toggleUserProfileModalsOpen: (state, action: PayloadAction<{ modal: keyof IUserProfileModalsOpen }>) => {
      state.userModals.open[action.payload.modal] = !state.userModals.open[action.payload.modal];
    },
    setUserProfileModalsType: (state, action: PayloadAction<UserEmailSettingsType>) => {
      state.userModals.modalType = action.payload;
    },
    setUserMailSettingsModalActiveProjectId: (state, action: PayloadAction<EntityId>) => {
      state.userModals.userMailSettingsModal.activeProjectId = action.payload;
    },
    setUserMailSettingsModalSelectedFileDirectories: (state, action: PayloadAction<Array<IFileDirectoryDTO>>) => {
      state.userModals.userMailSettingsModal.activeProjectSelectedFileDirectories = action.payload;
    },
    setUserMailSettingsModalSelectedDocumentDirectories: (state, action: PayloadAction<Array<IFileDirectoryDTO>>) => {
      state.userModals.userMailSettingsModal.activeProjectSelectedDocumentDirectories = action.payload;
    },
    resetUserProfileModalsData: (state, action: PayloadAction<{ closeModal: boolean }>) => {
      const modalClosed = action.payload.closeModal;
      state.userModals.userMailSettingsModal = getUserMailSettingsModalInfoCleared(state.userModals.userMailSettingsModal.projectRelatedSettings, modalClosed);
      if (modalClosed) {
        delete state.userModals.modalType;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserMailSettingsInfoAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchUserMailSettingsInfoAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.userModals.userMailSettingsModal.projectRelatedSettings = action.payload;
      })
      .addCase(saveUserMailSettingsInfoAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveUserMailSettingsInfoAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.userModals.userMailSettingsModal.projectRelatedSettings = state.userModals.userMailSettingsModal.projectRelatedSettings.filter((info) => info.id !== action.payload.id);
        if (!action.payload.deleted) {
          state.userModals.userMailSettingsModal.projectRelatedSettings = [...state.userModals.userMailSettingsModal.projectRelatedSettings, action.payload];
        }
      })
      .addCase(saveUserDailyMailSettingsInfoAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveUserDailyMailSettingsInfoAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.userModals.userMailSettingsModal.dailyMailRelatedSettings = state.userModals.userMailSettingsModal.dailyMailRelatedSettings.filter((info) => {
          return info.type !== action.payload.type;
        });
        if (!action.payload.deleted) {
          state.userModals.userMailSettingsModal.dailyMailRelatedSettings.push(action.payload);
        }
      })
      .addCase(fetchUserMailSettingsInfosForDailyMailAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchUserMailSettingsInfosForDailyMailAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.userModals.userMailSettingsModal.dailyMailRelatedSettings = action.payload;
      })
      .addCase(saveFileFolderSelectionAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveFileFolderSelectionAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
      })
      .addCase(saveDocumentFolderSelectionAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveDocumentFolderSelectionAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
      })
      .addCase(fetchProjectFileRootDirectoryForDailyMailAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchProjectFileRootDirectoryForDailyMailAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.userModals.userMailSettingsModal.activeProjectFileRootDirectory = action.payload;
      })
      .addCase(fetchProjectDocumentRootDirectoryForDailyMailAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchProjectDocumentRootDirectoryForDailyMailAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.userModals.userMailSettingsModal.activeProjectDocumentRootDirectory = action.payload;
      })
      .addCase(fetchUserDailyMailProjectFileEntityRelationAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchUserDailyMailProjectFileEntityRelationAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.userModals.userMailSettingsModal.activeProjectUserDailyMailFileEntityRelations = action.payload;
      })
      .addCase(fetchProjectTaskBoardsForTaskMailAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchProjectTaskBoardsForTaskMailAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.userModals.userMailSettingsModal.activeProjectTaskBoards = action.payload;
      })
      .addCase(fetchUserMailSettingsInfoForTaskMailAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchUserMailSettingsInfoForTaskMailAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.userModals.userMailSettingsModal.taskMailRelatedSettings = action.payload;
      })
      .addCase(saveUserMailSettingsInfoForTaskMailAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveUserMailSettingsInfoForTaskMailAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.userModals.userMailSettingsModal.taskMailRelatedSettings = state.userModals.userMailSettingsModal.taskMailRelatedSettings.filter((info) => info.id !== action.payload.id);
        if (!action.payload.deleted) {
          state.userModals.userMailSettingsModal.taskMailRelatedSettings = [...state.userModals.userMailSettingsModal.taskMailRelatedSettings, action.payload];
        }
      })
      .addCase(saveAllSelectedDailyMailDirectoriesAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveAllSelectedDailyMailDirectoriesAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const savedRelations = action.payload;
        const deletedRelations = savedRelations.filter((rel) => rel.deleted);
        const addedRelations = savedRelations.filter((rel) => !rel.deleted);
        const notDeletedRelations = state.userModals.userMailSettingsModal.activeProjectUserDailyMailFileEntityRelations.filter((rel) => {
          return !deletedRelations.some((r) => {
            return r.fileEntityId === rel.fileEntityId;
          });
        });
        state.userModals.userMailSettingsModal.activeProjectUserDailyMailFileEntityRelations = [...notDeletedRelations, ...addedRelations];
      });
  }
});

export const {
  toggleUserProfileModalsOpen,
  setUserProfileModalsType,
  resetUserProfileModalsData,
  setUserMailSettingsModalActiveProjectId,
  setUserMailSettingsModalSelectedFileDirectories,
  setUserMailSettingsModalSelectedDocumentDirectories
} = userPreferenceSlice.actions;

export const selectUserMailSettingsModalOpen = (state: RootState) => state.userPreference.userModals.open.userMailSettingsModal;
export const selectUserMailProjectRelatedSettings = (state: RootState) => state.userPreference.userModals.userMailSettingsModal.projectRelatedSettings;
export const selectUserDailyMailRelatedSettings = (state: RootState) => state.userPreference.userModals.userMailSettingsModal.dailyMailRelatedSettings;
export const selectUserTaskMailRelatedSettings = (state: RootState) => state.userPreference.userModals.userMailSettingsModal.taskMailRelatedSettings;
export const selectUserMailSettingsModalType = (state: RootState) => state.userPreference.userModals.modalType;
export const selectUserMailSettingsActiveProjectId = (state: RootState) => state.userPreference.userModals.userMailSettingsModal.activeProjectId;
export const selectUserMailSettingsActiveProjectUserDailyMailFileEntityRelations = (state: RootState) =>
  state.userPreference.userModals.userMailSettingsModal.activeProjectUserDailyMailFileEntityRelations;
export const selectUserMailSettingsProjectFileRootDirectory = (state: RootState) => state.userPreference.userModals.userMailSettingsModal.activeProjectFileRootDirectory;
export const selectUserMailSettingsProjectDocumentRootDirectory = (state: RootState) => state.userPreference.userModals.userMailSettingsModal.activeProjectDocumentRootDirectory;
export const selectUserMailSettingsActiveProjectSelectedFileDirectories = (state: RootState) => state.userPreference.userModals.userMailSettingsModal.activeProjectSelectedFileDirectories;
export const selectUserMailSettingsActiveProjectSelectedDocumentDirectories = (state: RootState) => state.userPreference.userModals.userMailSettingsModal.activeProjectSelectedDocumentDirectories;
export const selectUserMailSettingsActiveProjectTaskBoards = (state: RootState) => state.userPreference.userModals.userMailSettingsModal.activeProjectTaskBoards;
export const selectUserMailSettingsActiveProjectTaskBoardsSorted = createSelector([selectUserMailSettingsActiveProjectTaskBoards], (taskBoards) => {
  return taskBoards.slice().sort((a, b) => {
    return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
  });
});
export const selectProfileAuthSettingsPhoneModalOpen = (state: RootState) => state.userPreference.userModals.open.profileAuthSettingsPhoneModal;
export const selectProfileAuthSettingsPersonalizationModalOpen = (state: RootState) => state.userPreference.userModals.open.profileAuthSettingsPersonalizationModal;

export const selectUserMailSettingsActiveProjectFilesFolderSelection = createSelector(
  [selectUserDailyMailRelatedSettings, selectUserMailSettingsActiveProjectSelectedFileDirectories, (state: any, projectId?: EntityId) => projectId],
  (dailyMailSettingsInfo, selectedDirectories, projectId) => {
    if (!dailyMailSettingsInfo || !projectId) {
      return DailyMailFolderSelection.ALL;
    }
    const anyFolderSelectedType = UserEmailSettingsType.DAILY_MAIL_PROJECT_SPECIFIC_FILES_SELECTED;
    const newFoldersAddingDisabledType = UserEmailSettingsType.ADDING_NEW_DRAWINGS_FOLDER_TO_DAILY_MAIL_DISABLED;
    const folderSelectionSettings = dailyMailSettingsInfo.filter((info) => {
      return info.type === UserEmailSettingsType.DAILY_MAIL_PROJECT_SPECIFIC_FILES_SELECTED || info.type === UserEmailSettingsType.ADDING_NEW_DRAWINGS_FOLDER_TO_DAILY_MAIL_DISABLED;
    });
    return getDailyMailFolderSelection(folderSelectionSettings, projectId, anyFolderSelectedType, newFoldersAddingDisabledType, selectedDirectories);
  }
);

export const selectUserMailSettingsActiveProjectDocumentsFolderSelection = createSelector(
  [selectUserDailyMailRelatedSettings, selectUserMailSettingsActiveProjectSelectedDocumentDirectories, (state: any, projectId?: EntityId) => projectId],
  (dailyMailSettingsInfo, selectedDirectories, projectId) => {
    if (!dailyMailSettingsInfo || !projectId) {
      return DailyMailFolderSelection.ALL;
    }
    const anyFolderSelectedType = UserEmailSettingsType.DAILY_MAIL_PROJECT_SPECIFIC_DOCUMENTS_SELECTED;
    const newFoldersAddingDisabledType = UserEmailSettingsType.ADDING_NEW_DOCUMENTS_FOLDER_TO_DAILY_MAIL_DISABLED;
    const folderSelectionSettings = dailyMailSettingsInfo.filter((info) => {
      return info.type === UserEmailSettingsType.DAILY_MAIL_PROJECT_SPECIFIC_DOCUMENTS_SELECTED || info.type === UserEmailSettingsType.ADDING_NEW_DOCUMENTS_FOLDER_TO_DAILY_MAIL_DISABLED;
    });
    return getDailyMailFolderSelection(folderSelectionSettings, projectId, anyFolderSelectedType, newFoldersAddingDisabledType, selectedDirectories);
  }
);

const getDailyMailFolderSelection = (
  folderSelectionSettings: Array<IUserEmailSetting>,
  projectId: EntityId,
  anyFolderSelectedType: UserEmailSettingsType,
  newFoldersAddingDisabledType: UserEmailSettingsType,
  selectedDirectories: Array<IFileDirectoryDTO>
) => {
  if (folderSelectionSettings.length > 0) {
    const anyFolderSelected = folderSelectionSettings.some((setting) => setting.resource === "PROJECT" && setting.resourceId === projectId && setting.type === anyFolderSelectedType);
    const newFoldersAddingDisabled = folderSelectionSettings.some((setting) => setting.resource === "PROJECT" && setting.resourceId === projectId && setting.type === newFoldersAddingDisabledType);
    if (anyFolderSelected) {
      if (!newFoldersAddingDisabled || (selectedDirectories && selectedDirectories.length > 0)) {
        return DailyMailFolderSelection.SELECTED;
      } else {
        return DailyMailFolderSelection.NONE;
      }
    } else {
      return DailyMailFolderSelection.ALL;
    }
  } else {
    return DailyMailFolderSelection.ALL;
  }
};

export default userPreferenceSlice.reducer;
