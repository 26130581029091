import { EntityId } from "@reduxjs/toolkit";
import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { FileTreeType, IFileTreeTemplate, IFileTreeTemplates } from "@/model/files/IFileTreeTemplate";

export function fetchNewProjectFileTreeTemplates(companyId: EntityId): Promise<{ value: IFileTreeTemplates }> {
  return bauhubGet("/company/" + companyId + "/new/project/tree/templates");
}

export function fetchDefaultFileTreeTemplates(companyId: EntityId, fileTreeType: FileTreeType): Promise<Array<IFileTreeTemplate>> {
  return bauhubGet("/company/" + companyId + "/tree/template/" + fileTreeType + "/list");
}

export function saveNewFileTreeTemplate(fileTree: IFileTreeTemplate): Promise<IFileTreeTemplate> {
  return bauhubPost("/v2/company/tree/template/new", fileTree);
}

export function removeFileTreeTemplate(fileTree: IFileTreeTemplate): Promise<Array<IFileTreeTemplate>> {
  return bauhubPost("/company/tree/template/remove", fileTree);
}

export function saveFileTreeTemplate(fileTree: IFileTreeTemplate): Promise<IFileTreeTemplate> {
  return bauhubPost("/company/tree/template/save", fileTree);
}

export function fetchProjectFileTreeJson(projectId: EntityId, fileTreeType: FileTreeType) {
  return bauhubGet("/v2/company/project/tree/import/" + projectId + "/tree/" + fileTreeType);
}
