import React, { FC, useEffect, useRef } from "react";
import { ITask } from "@/model/taskBoard/ITask";
import { useTranslation } from "react-i18next";
import BhInlineInputXLWithDropdown from "@components/input/BhInlineInputXLWithDropdown";
import BhInlineInputXL from "@components/input/BhInlineInputXL";

interface Props {
  task: ITask;
  saveTaskCallback: Function;
  disabled?: boolean;
}

const TaskModalNameInput: FC<Props> = ({ task, saveTaskCallback, disabled }) => {
  const { t } = useTranslation();
  const taskNameInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!task.id) {
      taskNameInput?.current?.focus();
    }
  }, []);

  return (
    <>
      {task.nameDropdownValues && task.nameDropdownValues.length > 0 && (
        <BhInlineInputXLWithDropdown
          onSelect={saveTaskCallback}
          initialValue={task.name}
          placeholder={t("TASK.NAME.PLACEHOLDER") as string}
          property={"name"}
          dropdownValueProperty={"text"}
          inputRef={taskNameInput}
          disabled={disabled}
          values={task.nameDropdownValues}
        />
      )}
      {(!task.nameDropdownValues || task.nameDropdownValues.length === 0) && (
        <BhInlineInputXL
          onBlurCallback={saveTaskCallback}
          initialValue={task.name}
          placeholder={t("TASK.NAME.PLACEHOLDER") as string}
          property={"name"}
          inputRef={taskNameInput}
          disabled={disabled}
          saveOnEnter={true}
        />
      )}
    </>
  );
};

export default TaskModalNameInput;
