import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesUp, faCheck, faCheckDouble, faExclamation, faPlus, faStar, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { faMessage, faPen, faSignature, faSquareKanban } from "@fortawesome/pro-regular-svg-icons";
import { UserNotificationType, UserNotificationTypeIcon } from "@/model/IUserNotification";

interface Props {
  type: UserNotificationType;
}

const UserNotificationTypeIconContainer: FC<Props> = ({ type }) => {
  // @ts-ignore
  const oldColorToNewMap: Record<UserNotificationType, UserNotificationTypeIcon> = {
    [UserNotificationType.ACT_SUBMITTED]: UserNotificationTypeIcon.ADD,
    [UserNotificationType.NEW_FILE]: UserNotificationTypeIcon.SUBMITTED,
    [UserNotificationType.ACT_APPROVED]: UserNotificationTypeIcon.APPROVED,
    [UserNotificationType.CONTAINER_SIGNING_COMPLETE]: UserNotificationTypeIcon.SUCCESS,
    [UserNotificationType.ACT_REFUSED]: UserNotificationTypeIcon.DECLINED,
    [UserNotificationType.ACT_CANCELLED]: UserNotificationTypeIcon.ALERT,
    [UserNotificationType.FILE_DELETED]: UserNotificationTypeIcon.EDIT,
    [UserNotificationType.ADDED_AS_COMPANY_ADMIN]: UserNotificationTypeIcon.ADMIN_RIGHTS,
    [UserNotificationType.FILE_RENAMED]: UserNotificationTypeIcon.EDIT,
    [UserNotificationType.ADDED_TO_WG]: UserNotificationTypeIcon.ADD,
    [UserNotificationType.SIGN_INVITE]: UserNotificationTypeIcon.SIGNATURE,
    [UserNotificationType.SIGNED]: UserNotificationTypeIcon.SIGNATURE,
    [UserNotificationType.SIGNATURE_DECLINED]: UserNotificationTypeIcon.DECLINED,
    [UserNotificationType.NEW_TASK]: UserNotificationTypeIcon.ADD,
    [UserNotificationType.NEW_TASK_COMMENT]: UserNotificationTypeIcon.COMMENT,
    [UserNotificationType.NEW_TASK_STATUS]: UserNotificationTypeIcon.TASKBOARD,
    [UserNotificationType.TASK_COMMENT_EDIT]: UserNotificationTypeIcon.EDIT,
    [UserNotificationType.FILES_NEED_APPROVAL]: UserNotificationTypeIcon.SUBMITTED,
    [UserNotificationType.NEW_PRIVILEGES]: UserNotificationTypeIcon.ADMIN_RIGHTS,
    [UserNotificationType.REFRESH]: UserNotificationTypeIcon.ALERT,
    [UserNotificationType.USER_MENTIONED]: UserNotificationTypeIcon.COMMENT,
    [UserNotificationType.NEW_FILE_COMMENT]: UserNotificationTypeIcon.COMMENT
  };

  const iconType = oldColorToNewMap[type] || UserNotificationTypeIcon.SUCCESS;

  const containerClassName = "w-8 h-8 rounded-full items-center justify-center flex inline-flex bh-border-white border " + BhNotificationTypeContainer[iconType].bgColor;
  const iconClassName = "" + BhNotificationTypeContainer[iconType].iconColor;
  return (
    <div className={containerClassName}>
      <FontAwesomeIcon icon={BhNotificationTypeContainer[iconType].icon} className={iconClassName} aria-hidden="true" size="lg" />
    </div>
  );
};

export default UserNotificationTypeIconContainer;

export const BhNotificationTypeContainer = {
  SUCCESS: {
    icon: faCheck,
    iconColor: "bh-text-success-green-110",
    bgColor: "bh-bg-success-green-10"
  },
  DECLINED: {
    icon: faXmark,
    iconColor: "bh-text-error-red",
    bgColor: "bh-bg-error-red-10"
  },
  ALERT: {
    icon: faExclamation,
    iconColor: "bh-text-warning-yellow-120",
    bgColor: "bh-bg-warning-yellow-10"
  },
  COMMENT: {
    icon: faMessage,
    iconColor: "bh-text-dark-jungle",
    bgColor: "bh-bg-mint"
  },
  SIGNATURE: {
    icon: faSignature,
    iconColor: "bh-text-royal-blue",
    bgColor: "bh-bg-royal-blue-light"
  },
  EDIT: {
    icon: faPen,
    iconColor: "bh-text-pigeon",
    bgColor: "bh-bg-pigeon-20"
  },
  ADD: {
    icon: faPlus,
    iconColor: "bh-text-bauhub-green-120",
    bgColor: "bh-bg-mint-110"
  },
  TASKBOARD: {
    icon: faSquareKanban,
    iconColor: "bh-text-mediterranean",
    bgColor: "bh-bg-mediterranean-10"
  },
  SUBMITTED: {
    icon: faAnglesUp,
    iconColor: "bh-text-success-green-110",
    bgColor: "bh-bg-success-green-10"
  },
  APPROVED: {
    icon: faCheckDouble,
    iconColor: "bh-text-success-green-10",
    bgColor: "bh-bg-success-green-110"
  },
  ADMIN_RIGHTS: {
    icon: faStar,
    iconColor: "bh-text-bauhub-green-120",
    bgColor: "bh-bg-mint"
  }
};
