import { saveFileDeselectToSync, saveFileSelectToSync } from "@/api/syncAPI";
import { IFolderFileEntity } from "@/model/files/IFileEntity";
import { ISyncProject } from "@/model/sync/ISyncProject";
import { EntityId } from "@reduxjs/toolkit";
import { FC, useEffect, useState } from "react";
import DirectorySelectionContainer from "../project/detail/directory/DirectorySelectionContainer";

interface Props {
  project: ISyncProject;
  openedIds: Array<EntityId>;
  selectedIds: Array<EntityId>;
  setSelectedIds: Function;
}
const SyncProjectDirectoryPickerTab: FC<Props> = ({ project, openedIds, selectedIds, setSelectedIds }) => {
  const [fileRootDir, setFileRootDir] = useState({} as IFolderFileEntity);
  const [documentRootDir, setDocumentRootDir] = useState({} as IFolderFileEntity);
  const [modelRootDir, setModelRootDir] = useState({} as IFolderFileEntity);

  useEffect(() => {}, []);

  function markToSync(directory: IFolderFileEntity) {
    if (directory.selected) {
      saveFileSelectToSync(directory.id);
      setSelectedIds([...selectedIds, directory.id]);
    } else {
      saveFileDeselectToSync(directory.id);
      setSelectedIds(selectedIds.filter((id) => id !== directory.id));
    }
  }

  if (!project) {
    return null;
  }

  return (
    <div className="bh-bg-white flex h-full flex-col text-left">
      <DirectorySelectionContainer
        allowOnlyParentsToBeSelected={true}
        directoryIds={openedIds}
        selectOnlyOne={false}
        preSelectedDirectoryIds={selectedIds}
        selectCallback={markToSync}
        fileRootDir={fileRootDir}
        setFileRootDir={setFileRootDir}
        documentRootDir={documentRootDir}
        setDocumentRootDir={setDocumentRootDir}
        modelRootDir={modelRootDir}
        setModelRootDir={setModelRootDir}
        ignorePrivileges={true}
        keepChildrenSelectedOnParentSelectionChange={true}
      />
    </div>
  );
};

export default SyncProjectDirectoryPickerTab;
