import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import BhUserIcon from "@components/user/BhUserIcon";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { IUser } from "@/model/IUser";
import { IUserAuthority } from "@/model/IUserAuthority";

interface Props {
  userGroup: Array<IUser | IUserAuthority | ISimpleValidUserAuthority>;
  size?: number;
}

const BhUserIconGrouped: FC<Props> = ({ userGroup, size }) => {
  const viewSize = size ? size : 32;
  const hasMoreThanFiveUsers = userGroup.length > 5;
  const numOfAggregatedUsers = userGroup.length - 4;
  const groupedAvatars = hasMoreThanFiveUsers ? userGroup.slice(0, 4) : userGroup;

  return (
    <>
      {groupedAvatars.map((user, index) => {
        const id = "userEntityId" in user ? user.userEntityId : user.id;
        return (
          <div key={id} className={classNames(`z-${index * 2}`, "-ml-1.5 first:m-0")}>
            <BhUserIcon user={user} size={viewSize} />
          </div>
        );
      })}
      {hasMoreThanFiveUsers && (
        <div
          style={{ height: viewSize, width: viewSize, minWidth: viewSize }}
          className={classNames("bh-bg-pigeon-20 bh-text-deep-ocean-80 z-8 relative -ml-1.5 overflow-hidden rounded-full ring-2 ring-white")}
        >
          <div className={classNames(size && "text-sm", "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform")}>+{numOfAggregatedUsers}</div>
        </div>
      )}
    </>
  );
};

export default BhUserIconGrouped;
