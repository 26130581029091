import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boRemoveFormAsync, boSelectCompany } from "@/app/store/backofficeSlice";
import { ClientContractFormType, SubcontractorContractFormType } from "@/model/IForm";
import { EnabledFormType, IEnabledForm } from "@/model/ICompany";
import { naturalSortString } from "@/utilities/sortUtilities";
import BOCompanyTemplateRow from "@components/backoffice/company/modulesAndTemplates/BOCompanyTemplateRow";
import BhCombobox from "@components/input/BhCombobox";

interface Props {
  formType: EnabledFormType;
  onSaveCallback: Function;
}

const BOCompanyContracts: FC<Props> = ({ formType, onSaveCallback }) => {
  const { t } = useTranslation();
  const company = useAppSelector(boSelectCompany);
  const contracts: string[] = Object.values(formType === EnabledFormType.SUBCONTRACTOR_CONTRACT ? SubcontractorContractFormType : ClientContractFormType);
  const companyContracts = company.enabledForms?.filter((form) => form.type === formType);
  const filteredContracts = contracts.filter((form) => !companyContracts?.some((enabledForm) => form === enabledForm.code));
  const dispatch = useAppDispatch();

  const contractTranslationMap: Record<string, string> = {};

  const translatedContracts = filteredContracts
    .map((contract) => {
      const translatedContract = t("BACKOFFICE.FORMS." + contract);
      contractTranslationMap[translatedContract] = contract;
      return translatedContract;
    })
    .sort((a, b) => naturalSortString(a, b));
  const removeForm = (form: IEnabledForm) => {
    dispatch(boRemoveFormAsync(form));
  };

  const saveForm = (selectedValue: Record<string, SubcontractorContractFormType>) => {
    const dto = { code: contractTranslationMap[selectedValue.code], nameLabel: contractTranslationMap[selectedValue.code], companyId: company.id, type: formType };
    onSaveCallback(dto);
  };

  return (
    <div>
      {companyContracts?.map((contract) => (
        <BOCompanyTemplateRow key={contract.id} template={contract as IEnabledForm} nameProperty="code" removeCallback={removeForm} label={t("BACKOFFICE.FORMS." + contract.code)} />
      ))}
      <div className="mt-2 w-full">
        <BhCombobox initialValue={""} values={translatedContracts} property="code" placeholder="+ Add module" onSelect={saveForm} enableNewValue={true} />
      </div>
    </div>
  );
};

export default BOCompanyContracts;
