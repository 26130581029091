import { createAsyncThunk, createEntityAdapter, createReducer, EntityId } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { deleteProjectMessage, fetchProjectMessages, saveProjectMessage } from "@/api/projectAPI";
import { IProjectMessage } from "@/model/IProjectMessage";

export const projectMessageAdapter = createEntityAdapter<IProjectMessage>({
  sortComparer: (a, b) => {
    return a.id > b.id ? -1 : 1;
  }
});

export interface IProjectMessageState {
  status: BhStateStatusType;
}

export const projectMessageInitialState = projectMessageAdapter.getInitialState<IProjectMessageState>({
  status: BhStateStatusType.INITIAL
});

export const fetchProjectMessagesAsync = createAsyncThunk("project/fetchProjectMessages", async (projectId: EntityId, { getState }) => {
  return fetchProjectMessages(projectId);
});

export const saveProjectMessagesAsync = createAsyncThunk("project/saveProjectMessage", async (message: IProjectMessage) => {
  return saveProjectMessage(message);
});

export const deleteProjectMessageAsync = createAsyncThunk("project/deleteProjectMessage", async (message: IProjectMessage) => {
  return deleteProjectMessage(message);
});

export const projectMessageExtraReducer = createReducer(projectMessageInitialState, (builder) => {
  builder
    .addCase(fetchProjectMessagesAsync.pending, (state) => {
      state.status = BhStateStatusType.PENDING;
    })
    .addCase(fetchProjectMessagesAsync.fulfilled, (state, action) => {
      state.status = BhStateStatusType.SUCCESS;
      projectMessageAdapter.setAll(state, action.payload);
    })
    .addCase(saveProjectMessagesAsync.fulfilled, (state, action) => {
      projectMessageAdapter.upsertOne(state, action.payload);
    })
    .addCase(deleteProjectMessageAsync.fulfilled, (state, action) => {
      projectMessageAdapter.removeOne(state, action.payload.id);
    });
});

export const {
  selectAll: selectAllProjectMessages,
  selectIds: selectAllProjectMessageIds,
  selectById: selectProjectMessageById
} = projectMessageAdapter.getSelectors((state: RootState) => state.project.messages);

export const selectProjectMessagesStatus = (state: RootState) => state.project.messages.status;
