import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhModal from "@components/modal/BhModal";
import { moveTaskAsync } from "@/app/store/tasksSlice";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhInputWithChips from "@components/input/BhInputWithChips";
import { ITask } from "@/model/taskBoard/ITask";
import { getUserFromFullName } from "@/utilities/jsUtilities";
import { convertSimpleValidUserAuthorityToUser, ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { getUserFullName, IUser } from "@/model/IUser";
import { ITaskBoard } from "@/model/taskBoard/ITaskBoard";
import { selectAllTaskBoards, selectTaskBoardUsersByTaskBoardId } from "@/app/store/taskBoardsSlice";
import BhInputWithDropdown from "@components/input/BhInputWithDropdown";
import { useTranslation } from "react-i18next";
import BhModalFooter from "@components/modal/BhModalFooter";
import { useNavigate } from "react-router-dom";
import { isTaskPersonal } from "@/utilities/taskBoardUtil";
import { selectCurrentUser } from "@/app/store/userSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { EntityId } from "@reduxjs/toolkit";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  task: ITask;
  isDedicatedView?: boolean;
  closeModal: Function;
  selectedTaskBoardId?: EntityId;
}

const TaskMoveModal: FC<Props> = ({ task, isDedicatedView, closeModal, selectedTaskBoardId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const taskBoards = useAppSelector(selectAllTaskBoards);
  const currentUser = useAppSelector(selectCurrentUser);
  const projectId = useAppSelector(selectCurrentProjectId);
  const [taskBoard, setTaskBoard] = useState({} as ITaskBoard);
  const [taskToMove, setTaskToMove] = useState({ ...task, ...{ attachments: [] } });
  const [availableUsers, setAvailableUsers] = useState([] as Array<IUser>);
  const taskBoardUsers = useAppSelector((state) => taskBoard && taskBoard.id !== -1 && selectTaskBoardUsersByTaskBoardId(state, taskBoard.id)) as Array<ISimpleValidUserAuthority>;
  const assignee = taskToMove.assignee ? [{ ...getUserFromFullName(taskToMove.assigneeFullName), ...{ id: taskToMove.assignee } }] : [];
  const taskBoardsDropdownValues = [{ id: -1, name: t("TASKBOARD.PERSONAL") }, ...taskBoards];

  const [predefinedTaskBoardSelected, setPredefinedTaskBoardSelected] = useState(false);

  useEffect(() => {
    if (taskBoardUsers) {
      setAvailableUsers(taskBoardUsers.map((user) => convertSimpleValidUserAuthorityToUser(user)));
      const currentTaskParticipantsWithAccessToNewTaskBoard = task.participants.filter((participant) => taskBoardUsers.some((user) => user.userEntityId === participant.id));
      let taskToSet = { ...taskToMove, participants: currentTaskParticipantsWithAccessToNewTaskBoard };
      const currentTaskAssigneeHasAccessToNewTaskBoard = taskBoardUsers && task.assignee && taskBoardUsers.some((user) => user.userEntityId === task.assignee);
      if (currentTaskAssigneeHasAccessToNewTaskBoard) {
        taskToSet = { ...taskToSet, ...{ assignee: task.assignee, assigneeFullName: task.assigneeFullName } };
      }
      setTaskToMove(taskToSet);
    }
  }, [taskBoardUsers]);

  useEffect(() => {
    if (!predefinedTaskBoardSelected && selectedTaskBoardId !== undefined && taskBoardsDropdownValues) {
      const taskBoard = taskBoardsDropdownValues.find((t) => t.id === selectedTaskBoardId);
      if (taskBoard) selectTaskBoard(taskBoard as ITaskBoard);
      setPredefinedTaskBoardSelected(true);
    }
  }, [selectedTaskBoardId, taskBoardsDropdownValues, predefinedTaskBoardSelected]);

  const removeAssignee = () => {
    setTask({ assignee: null, assigneeFullName: null });
  };

  const selectAssignee = (user: IUser) => {
    setTask({ assignee: user.id, assigneeFullName: user.firstName + " " + user.lastName });
  };

  const removeParticipant = (user: IUser) => {
    setTask({ participants: taskToMove.participants.filter((participant) => participant.id !== user.id) });
  };

  const selectParticipant = (user: IUser) => {
    setTask({ participants: [...taskToMove.participants, user] });
  };

  const setNewTaskName = (nameObject: any) => {
    setTask(nameObject);
  };

  const setAttachments = (e: any) => {
    setTask({ attachments: e.target.checked ? task.attachments : [] });
  };

  const setTask = (changedObject: any) => {
    setTaskToMove({ ...taskToMove, ...changedObject });
  };

  const selectTaskBoard = (selectedTaskBoard: ITaskBoard) => {
    setTaskBoard(selectedTaskBoard);
    if (selectedTaskBoard.id === -1) {
      setTask({ taskBoardId: undefined, assignee: currentUser.id, assigneeFullName: getUserFullName(currentUser), participants: [] });
    } else {
      setTask({ taskBoardId: selectedTaskBoard.id });
    }
  };

  const moveTask = () => {
    dispatch(moveTaskAsync(taskToMove)).then((action) => {
      closeModal();
      if (isDedicatedView) {
        const movedTask = action.payload as ITask;
        const isPersonalTask = isTaskPersonal(movedTask, currentUser);
        if (isPersonalTask) {
          navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/personal/task/${movedTask.id}`);
        } else {
          navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/task/${movedTask.taskIdentifier}`);
        }
      }
    });
  };

  return (
    <BhModal
      size="xl"
      isShown={true}
      setIsShown={() => {}}
      onClose={closeModal}
      header={
        <div className="h-7">
          <h2 className="mt-1 leading-7">{t("TASK.MOVE_TASK")}</h2>
        </div>
      }
      children={
        <div className="px-8 pt-3 pb-4">
          <BhInputWithDropdown
            property={"name"}
            initialValue={taskBoard ? taskBoard.name : ""}
            values={taskBoardsDropdownValues}
            onSelect={selectTaskBoard}
            label={t("TASK.CHOOSE_TASK_BOARD") as string}
          />
          {taskBoard && taskBoard.id && (
            <div>
              <BhInputStackedLabel initialValue={taskToMove.name} property={"name"} label={t("TASK.TASK_NAME")} onChangeCallback={setNewTaskName} />
              <div className="my-5 flex flex-row items-center">
                <input className="mr-3 h-4 w-4 rounded focus:ring-0 focus:ring-offset-0" type="checkbox" onChange={(e) => setAttachments(e)} />
                <span>{t("TASK.MOVE.WITH_ATTACHMENTS")}</span>
              </div>
              {taskBoard.id !== -1 && (
                <div>
                  <BhInputWithChips label={t("TASK.ASSIGNEE") as string} currentValues={assignee} dropdownValues={availableUsers} onRemove={removeAssignee} onSelect={selectAssignee} />
                  <div className="mt-4">
                    <BhInputWithChips
                      label={t("TASK.PARTICIPANTS") as string}
                      currentValues={taskToMove.participants}
                      dropdownValues={availableUsers}
                      onRemove={removeParticipant}
                      onSelect={selectParticipant}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      }
      footer={<BhModalFooter onCancel={closeModal} onConfirm={moveTask} confirmButtonText={t("GLOBAL.MOVE") as string} />}
    />
  );
};

export default TaskMoveModal;
