import { RefObject, useEffect, useRef, useState } from "react";

const useInputIsActive: () => { isActive: boolean; toggleIsActive: (disabled?: boolean) => void; inputRef: RefObject<any> } = () => {
  const [isActive, setIsActive] = useState(false);
  let inputRef = useRef<any>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [isActive]);

  const toggleIsActive = (disabled?: boolean) => {
    if (disabled) {
      return;
    }
    setIsActive(!isActive);
  };

  return { isActive, toggleIsActive, inputRef };
};

export default useInputIsActive;
