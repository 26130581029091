import React, { FC } from "react";

interface Props {
  children: React.ReactNode;
}

const BhCompactTableBody: FC<Props> = ({ children }) => {
  return <tbody>{children}</tbody>;
};

export default React.memo(BhCompactTableBody);
