import React, { FC, useEffect, useState } from "react";
import BhToggleButtonBar, { IBhToggleButtonBarItem } from "@components/toggle/BhToggleButtonBar";
import { DailyMailFolderSelection, IUserDailyMailProjectFileEntityRelation, UserEmailSettingsType } from "@/model/profile/IUserEmailSettings";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  fetchUserDailyMailProjectFileEntityRelationAsync,
  fetchUserMailSettingsInfosForDailyMailAsync,
  saveAllSelectedDailyMailDirectoriesAsync,
  saveDocumentFolderSelectionAsync,
  saveFileFolderSelectionAsync,
  selectUserMailSettingsActiveProjectDocumentsFolderSelection,
  selectUserMailSettingsActiveProjectFilesFolderSelection,
  selectUserMailSettingsActiveProjectId,
  selectUserMailSettingsActiveProjectSelectedDocumentDirectories,
  selectUserMailSettingsActiveProjectSelectedFileDirectories,
  selectUserMailSettingsActiveProjectUserDailyMailFileEntityRelations,
  selectUserMailSettingsProjectDocumentRootDirectory,
  selectUserMailSettingsProjectFileRootDirectory,
  setUserMailSettingsModalSelectedDocumentDirectories,
  setUserMailSettingsModalSelectedFileDirectories
} from "@/app/store/userPreferenceSlice";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { IFileDirectoryDTO } from "@/model/utilities/IFileDirectoryDTO";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { findFolderWithPathFromRoot } from "@/utilities/jsUtilities";
import DirectorySelectionModal from "@/views/home/project/detail/directory/directoryModals/DirectorySelectionModal";
import { useTranslation } from "react-i18next";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";

interface Props {
  addNewDirectoriesAutomaticallyCallback: Function;
  addNewDirectoriesAutomatically: boolean;
  isFiles: boolean;
  disabled?: boolean;
}

const DailyMailFoldersContainer: FC<Props> = ({ addNewDirectoriesAutomaticallyCallback, addNewDirectoriesAutomatically, isFiles, disabled }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const activeProjectId = useAppSelector(selectUserMailSettingsActiveProjectId);
  const selectionType = useAppSelector((state) =>
    isFiles ? selectUserMailSettingsActiveProjectFilesFolderSelection(state, activeProjectId) : selectUserMailSettingsActiveProjectDocumentsFolderSelection(state, activeProjectId)
  );
  const fileEntityRelations = useAppSelector(selectUserMailSettingsActiveProjectUserDailyMailFileEntityRelations);
  const rootDirectory = useAppSelector(isFiles ? selectUserMailSettingsProjectFileRootDirectory : selectUserMailSettingsProjectDocumentRootDirectory);
  const selectedDirectories = useAppSelector(isFiles ? selectUserMailSettingsActiveProjectSelectedFileDirectories : selectUserMailSettingsActiveProjectSelectedDocumentDirectories);
  const toggleValue = isFiles ? { addNewFileDirectories: addNewDirectoriesAutomatically } : { addNewDocumentDirectories: addNewDirectoriesAutomatically };
  const [directorySelectionModalOpen, setDirectorySelectionModalOpen] = useState(false);
  const directorySelectionModalIds = rootDirectory && (selectedDirectories.length > 0 ? selectedDirectories.map((dir) => dir.id) : [rootDirectory.id]);

  const setSelectedDirectoriesWithPaths = (rootDirectory: IFileDirectoryDTO, fileEntityRelations: Array<IUserDailyMailProjectFileEntityRelation>) => {
    let foundFolders = [] as Array<IFileDirectoryDTO>;
    fileEntityRelations.forEach((relation) => {
      let foundFolder = findFolderWithPathFromRoot(rootDirectory, relation.fileEntityId);
      if (foundFolder && foundFolder.id) {
        foundFolders.push(foundFolder);
      }
    });
    if (isFiles) {
      dispatch(setUserMailSettingsModalSelectedFileDirectories(foundFolders));
    } else {
      dispatch(setUserMailSettingsModalSelectedDocumentDirectories(foundFolders));
    }
  };

  useEffect(() => {
    if (rootDirectory && fileEntityRelations) {
      setSelectedDirectoriesWithPaths(rootDirectory, fileEntityRelations);
    }
  }, [rootDirectory, fileEntityRelations]);

  const selectionItems = Object.keys(DailyMailFolderSelection).map((selection) => {
    return { value: selection, text: t("GLOBAL." + selection) } as IBhToggleButtonBarItem;
  });

  const changeSelection = async (value: string) => {
    if (activeProjectId) {
      if (isFiles) {
        await dispatch(saveFileFolderSelectionAsync({ projectId: activeProjectId, selection: value as DailyMailFolderSelection }));
      } else {
        await dispatch(saveDocumentFolderSelectionAsync({ projectId: activeProjectId, selection: value as DailyMailFolderSelection }));
      }
      const dailyMailTypesToFetch = {
        types: [
          UserEmailSettingsType.DAILY_MAIL_PROJECT_SPECIFIC_FILES_SELECTED,
          UserEmailSettingsType.DAILY_MAIL_PROJECT_SPECIFIC_DOCUMENTS_SELECTED,
          UserEmailSettingsType.ADDING_NEW_DRAWINGS_FOLDER_TO_DAILY_MAIL_DISABLED,
          UserEmailSettingsType.ADDING_NEW_DOCUMENTS_FOLDER_TO_DAILY_MAIL_DISABLED
        ]
      };
      dispatch(fetchUserMailSettingsInfosForDailyMailAsync(dailyMailTypesToFetch));
      dispatch(fetchUserDailyMailProjectFileEntityRelationAsync(activeProjectId));
    }
  };

  const changeDirectories = (selected: Array<IFileDirectoryDTO>) => {
    const deletedDirectories = selectedDirectories.filter((dir) => {
      return !selected.some((sel) => sel.id === dir.id);
    });
    const addedDirectories = selected.filter((sel) => {
      return !selectedDirectories.some((dir) => dir.id === sel.id);
    });
    let relationsToSave = [] as Array<IUserDailyMailProjectFileEntityRelation>;
    if (deletedDirectories.length > 0) {
      const deletedRelations = fileEntityRelations
        .filter((rel) => {
          return deletedDirectories.some((dir) => dir.id === rel.fileEntityId);
        })
        .map((r) => {
          return { ...r, deleted: true };
        });
      relationsToSave = deletedRelations;
    }
    if (addedDirectories.length > 0) {
      const addedRelations = addedDirectories.map((dir) => {
        return { projectId: activeProjectId, fileEntityId: dir.id, deleted: false } as IUserDailyMailProjectFileEntityRelation;
      });
      relationsToSave = [...relationsToSave, ...addedRelations];
    }
    if (relationsToSave.length > 0 && activeProjectId) {
      dispatch(saveAllSelectedDailyMailDirectoriesAsync({ projectId: activeProjectId, dailyMailProjectFileEntityRelations: relationsToSave }));
    }
    setDirectorySelectionModalOpen(false);
  };

  return (
    <div className="bh-bg-white rounded p-6">
      <h3 className="bh-text-deep-ocean mt-0">{t(isFiles ? "GLOBAL.DRAWINGS" : "GLOBAL.DOCUMENTS")}</h3>
      <BhToggleButtonBar label={t("USER.NOTIFICATIONS.DAILY_OVERVIEW.WHICH_FOLDERS") as string} items={selectionItems} onClickAction={changeSelection} selected={selectionType} disabled={disabled} />
      {selectionType === DailyMailFolderSelection.SELECTED && (
        <div>
          <div className="bh-bg-white bh-border-pigeon-20 flex flex-row items-center justify-between border-b py-6">
            <div>
              <span className="bh-text-deep-ocean text-14px font-bold leading-5">{t("USER.NOTIFICATIONS.DAILY_OVERVIEW.NEW_FOLDERS")}</span>
            </div>
            <div>
              <BhToggleSwitch
                value={toggleValue[isFiles ? "addNewFileDirectories" : "addNewDocumentDirectories"] as boolean}
                onClickAction={() =>
                  addNewDirectoriesAutomaticallyCallback(
                    isFiles ? UserEmailSettingsType.ADDING_NEW_DRAWINGS_FOLDER_TO_DAILY_MAIL_DISABLED : UserEmailSettingsType.ADDING_NEW_DOCUMENTS_FOLDER_TO_DAILY_MAIL_DISABLED
                  )
                }
                disabled={disabled}
              />
            </div>
          </div>
          <div className="bh-bg-white mb-1 flex flex-row items-center justify-between pt-6">
            <div>
              <span className="bh-text-deep-ocean text-14px font-bold leading-5">
                {t(isFiles ? "USER.NOTIFICATIONS.DAILY_OVERVIEW.SELECTED_PLANS" : "USER.NOTIFICATIONS.DAILY_OVERVIEW.SELECTED_DOCUMENTS")}
              </span>
            </div>
            <div>
              <BhTextOnlyButton buttonProps={{ onClick: () => setDirectorySelectionModalOpen(true), disabled: disabled }}>{t("GLOBAL.CHANGE")}</BhTextOnlyButton>
            </div>
          </div>
          {selectedDirectories &&
            selectedDirectories
              .slice()
              .sort((a, b) => (a.path && b.path && a.path.toLocaleString() > b.path.toLocaleString() ? 1 : -1))
              .map((dir) => {
                return (
                  <div key={dir.id}>
                    <FontAwesomeIcon icon={faFolder} />
                    <span className="bh-text-deep-ocean ml-3">{dir.path}</span>
                  </div>
                );
              })}
        </div>
      )}
      {directorySelectionModalOpen && directorySelectionModalIds && (
        <DirectorySelectionModal
          directoryIds={directorySelectionModalIds}
          preSelectedDirectoryIds={selectedDirectories.map((dir) => dir.id)}
          modalHeader={t(isFiles ? "GLOBAL.CHOOSE_FILES" : "GLOBAL.CHOOSE_DOCUMENTS")}
          onModalSubmit={changeDirectories}
          onModalClose={() => setDirectorySelectionModalOpen(false)}
          selectOnlyOne={false}
          allowOnlyParentsToBeSelected={true}
        />
      )}
    </div>
  );
};

export default DailyMailFoldersContainer;
