import React, { FC } from "react";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  thumbUrl: string;
  onHoverIn: Function;
  loading?: boolean;
  iconRef?: any;
}

const BhFileCardImagePreview: FC<Props> = ({ thumbUrl, onHoverIn, loading, iconRef }) => {
  const getFixedPosition = () => {
    const modal = document.getElementsByClassName("bauhub-modal");
    const icon = iconRef?.current;

    const modalLeft = modal[0]?.getBoundingClientRect().left || 0;
    const modalTop = modal[0]?.getBoundingClientRect().top || 0;

    const iconRight = icon?.getBoundingClientRect().right || 0;
    const iconTop = icon?.getBoundingClientRect().top || 0;

    const imageLeft = iconRight - modalLeft;
    const ImageTop = iconTop - modalTop - 96;

    return { left: imageLeft, top: ImageTop };
  };

  return (
    <div className={classNames("z-100 fixed h-24 w-32 rounded-sm", loading && "bh-bg-smoke", !loading && "bh-bg-white")} onMouseEnter={() => onHoverIn()} style={getFixedPosition()}>
      {loading && (
        <div className="flex h-full w-full flex-row items-center justify-center">
          <FontAwesomeIcon icon={faSpinner} spin aria-hidden="true" className="!h-1/2 !w-1/2" />
        </div>
      )}
      {!loading && <img className="inline-flex h-full w-full" style={{ objectFit: "cover" }} src={thumbUrl} crossOrigin="" alt="" />}
    </div>
  );
};

export default BhFileCardImagePreview;
