import React, { FC, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boSaveCompanyBillingAsync, boSelectCompanyBilling } from "@/app/store/backofficeSlice";
import BOCompanyPricingRowInput from "@components/backoffice/company/companyAndBilling/BOCompanyPricingRowInput";
import BhSidebarNavDivider from "@components/nav/BhSidebarNavDivider";
import { ICompanyBilling } from "@/model/billing/ICompanyBilling";

interface Props {
  setCustomPricingModalOpen: (value: boolean) => void;
}

const TierTypeLabel: FC<{ tier: string }> = ({ tier }) => {
  return (
    <div className="w-full pl-28">
      <h1>{tier}</h1>
      <div className="flex">
        <div className="w-full">
          <p>Monthly</p>
        </div>
        <div className="w-full pl-2">
          <p>Annual</p>
        </div>
      </div>
    </div>
  );
};

const BoCompanyCustomPricingModal: FC<Props> = ({ setCustomPricingModalOpen }) => {
  const companyBilling = useAppSelector(boSelectCompanyBilling);
  const dispatch = useAppDispatch();
  const [currentBillingInfo, setCurrentBillingInfo] = useState<ICompanyBilling>(companyBilling);
  const handlePriceChange = (changedValue: Record<string, number>) => {
    const updatedBillingInfo = { ...currentBillingInfo, ...changedValue };
    setCurrentBillingInfo(updatedBillingInfo);
  };
  const saveCompanyBilling = () => {
    dispatch(boSaveCompanyBillingAsync(currentBillingInfo)).then(() => {
      setCustomPricingModalOpen(false);
    });
  };

  const onModalClose = () => {
    setCurrentBillingInfo(companyBilling);
    setCustomPricingModalOpen(false);
  };

  return (
    <BhModal
      setIsShown={setCustomPricingModalOpen}
      header={<h2>Custom pricing settings</h2>}
      footer={<BhModalFooter cancelButtonText="Cancel" confirmButtonText="Save" onConfirm={saveCompanyBilling} onCancel={onModalClose} />}
      size="4xl"
    >
      <div className="h-full overflow-scroll">
        <div className="px-20 pt-4 pb-8">
          <TierTypeLabel tier="Pro" />
          <BOCompanyPricingRowInput
            label="Rank 1"
            helperText="1 project"
            initialMonthlyValue={currentBillingInfo.tier1ProMonthlyCost}
            monthlyProperty="tier1ProMonthlyCost"
            initialYearlyValue={currentBillingInfo.tier1ProYearlyCost}
            yearlyProperty="tier1ProYearlyCost"
            onChange={handlePriceChange}
          />
          <BOCompanyPricingRowInput
            label="Rank 2"
            helperText="2-5 projects"
            initialMonthlyValue={currentBillingInfo.tier2ProMonthlyCost}
            monthlyProperty="tier2ProMonthlyCost"
            initialYearlyValue={currentBillingInfo.tier2ProYearlyCost}
            yearlyProperty="tier2ProYearlyCost"
            onChange={handlePriceChange}
          />
          <BOCompanyPricingRowInput
            label="Rank 3"
            helperText="6-29 projects"
            initialMonthlyValue={currentBillingInfo.tier3ProMonthlyCost}
            monthlyProperty="tier3ProMonthlyCost"
            initialYearlyValue={currentBillingInfo.tier3ProYearlyCost}
            yearlyProperty="tier3ProYearlyCost"
            onChange={handlePriceChange}
          />
          <BOCompanyPricingRowInput
            label="Rank 4"
            helperText="30+ projects"
            initialMonthlyValue={currentBillingInfo.tier4ProMonthlyCost}
            monthlyProperty="tier4ProMonthlyCost"
            initialYearlyValue={currentBillingInfo.tier4ProYearlyCost}
            yearlyProperty="tier4ProYearlyCost"
            onChange={handlePriceChange}
          />
          <div className="my-8">
            <BhSidebarNavDivider />
          </div>
          <TierTypeLabel tier="Light" />
          <BOCompanyPricingRowInput
            label="Rank 1"
            helperText="1 project"
            initialMonthlyValue={currentBillingInfo.tier1MonthlyCost}
            monthlyProperty="tier1MonthlyCost"
            initialYearlyValue={currentBillingInfo.tier1YearlyCost}
            yearlyProperty="tier1YearlyCost"
            onChange={handlePriceChange}
          />
          <BOCompanyPricingRowInput
            label="Rank 2"
            helperText="2-5 projects"
            initialMonthlyValue={currentBillingInfo.tier2MonthlyCost}
            monthlyProperty="tier2MonthlyCost"
            initialYearlyValue={currentBillingInfo.tier2YearlyCost}
            yearlyProperty="tier2YearlyCost"
            onChange={handlePriceChange}
          />
          <BOCompanyPricingRowInput
            label="Rank 3"
            helperText="6-29 projects"
            initialMonthlyValue={currentBillingInfo.tier3MonthlyCost}
            monthlyProperty="tier3MonthlyCost"
            initialYearlyValue={currentBillingInfo.tier3YearlyCost}
            yearlyProperty="tier3YearlyCost"
            onChange={handlePriceChange}
          />
          <BOCompanyPricingRowInput
            label="Rank 4"
            helperText="30+ projects"
            initialMonthlyValue={currentBillingInfo.tier4MonthlyCost}
            monthlyProperty="tier4MonthlyCost"
            initialYearlyValue={currentBillingInfo.tier4YearlyCost}
            yearlyProperty="tier4YearlyCost"
            onChange={handlePriceChange}
          />
        </div>
      </div>
    </BhModal>
  );
};

export default BoCompanyCustomPricingModal;