import React, { FC } from "react";
import { IFormBaseVersionRowField } from "@/model/form/IFormBaseVersionRowField";
import FormProtocolFieldTableHeader from "@components/form/fields/protocolField/FormProtocolFieldTableHeader";
import FormProtocolFieldTable from "@components/form/fields/protocolField/FormProtocolFieldTable";
import FormProtocolFieldTablePreview from "@components/form/fields/protocolField/FormProtocolFieldTablePreview";

interface Props {
  field: IFormBaseVersionRowField;
  saveCallback?: Function;
  addCallback?: Function;
  removeCallback?: Function;
  disabled: boolean;
  isPreview?: boolean;
}

const FormProtocolField: FC<Props> = ({ field, saveCallback, addCallback, removeCallback, disabled, isPreview }) => {
  return (
    <div className="flex h-full w-full flex-col gap-y-1">
      <FormProtocolFieldTableHeader field={field} />
      {!isPreview && <FormProtocolFieldTable field={field} saveCallback={saveCallback} removeCallback={removeCallback} disabled={disabled} addCallback={addCallback} />}
      {isPreview && <FormProtocolFieldTablePreview field={field} />}
    </div>
  );
};

export default FormProtocolField;
