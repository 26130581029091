import React, { FC, useEffect, useState } from "react";
import { classNames, ConditionalWrapper } from "@/utilities/jsUtilities";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ITabArrayElement } from "@/model/tabs/ITabArrayElement";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  tabs: Array<ITabArrayElement>;
  router?: boolean;
  tabClasses?: string;
  tabsContainerClasses?: string;
  tabsWidthFull?: boolean;
  callback?: Function;
  initialActiveTab?: number;
}

const BhTabs: FC<Props> = ({ tabs, router, tabClasses, tabsWidthFull, callback, initialActiveTab, tabsContainerClasses }) => {
  const [activeTab, setActiveTab] = useState<EntityId>(initialActiveTab ?? 0);
  const navigate = useNavigate();
  const location = useLocation();
  const urlWithoutLastSlash = location.pathname.endsWith("/") ? location.pathname.substring(0, location.pathname.length - 1) : location.pathname;
  const urlLastElement = urlWithoutLastSlash.split("/").slice(-1).join("/");
  const urlParamsString = location.search;

  useEffect(() => {
    if (router) {
      tabs.forEach((tab) => {
        if (urlLastElement === tab.href) {
          setActiveTab(tab.id);
        }
      });
    }
  }, [location]);

  useEffect(() => {
    if (router) {
      if (initialActiveTab && initialActiveTab !== 0) {
        const tab = tabs.find((tab) => tab.id === initialActiveTab);
        if (tab && tab.href) {
          navigate(tab.href);
        }
      }
    }
  }, []);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <div className={classNames(tabsContainerClasses && tabsContainerClasses, "flex-2 flex pt-2")}>
        <nav className={classNames(tabsWidthFull && "w-full", "flex flex-row")} aria-label="Tabs">
          {tabs.map((tab) => (
            <React.Fragment key={tab.id}>
              <ConditionalWrapper key={tab.id} condition={!!router} wrapper={(children: React.ReactNode) => <Link to={(tab.href || "") + urlParamsString}>{children}</Link>}>
                <button
                  key={tab.id}
                  onClick={() => {
                    setActiveTab(tab.id);
                    callback && callback(tab.id);
                  }}
                  className={classNames(
                    tab.id === activeTab ? "bh-border-deep-ocean bh-text-deep-ocean" : "bh-border-pigeon-20 bh-text-deep-ocean-80 hover:bh-border-pigeon-50 hover:bh-text-deep-ocean",
                    "cursor-pointer whitespace-nowrap border-b-2 py-2 px-2 font-semibold",
                    tabClasses
                  )}
                  aria-current={tab.id === activeTab ? "page" : undefined}
                >
                  {tab.header}
                </button>
              </ConditionalWrapper>
            </React.Fragment>
          ))}
        </nav>
      </div>
      <div className="flex w-full flex-1 overflow-hidden">
        <div className="w-full px-0.5 pb-0.5">
          {router && (
            <Routes>
              {tabs.map((tab) => {
                return (
                  <Route
                    key={tab.id}
                    path={tab.href}
                    element={
                      <div key={tab.id} className="h-full">
                        {tab.content}
                      </div>
                    }
                  />
                );
              })}
            </Routes>
          )}
          {!router &&
            tabs.map((tab, index) => {
              if (index === activeTab) {
                return (
                  <div key={tab.id} className="h-full">
                    {tab.content}
                  </div>
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default BhTabs;
