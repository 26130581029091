import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { FormType, IForm, IFormDataSaveRequest, IFormDiff, IFormDTO, IFormFieldImportDTO, IFormImportInfo, ISimpleForm } from "@/model/IForm";
import { EntityId } from "@reduxjs/toolkit";
import { IFileEntity } from "@/model/files/IFileEntity";
import { IUser } from "@/model/IUser";
import { IFormEditorChangeResponse, IFormEditorInfo } from "@/model/IFormEditor";

export function fetchForm(projectId: EntityId, dirId: EntityId, fileEntityId: EntityId): Promise<IForm> {
  return bauhubGet("/project/" + projectId + "/dir/" + dirId + "/file/" + fileEntityId);
}

export function saveFullForm(projectId: EntityId, dirId: EntityId, form: IForm): Promise<IForm> {
  return bauhubPost("/project/" + projectId + "/dir/" + dirId + "/form", form);
}

export function savePartForm(projectId: EntityId, dirId: EntityId, formDiff: IFormDiff): Promise<IFormDiff> {
  return bauhubPost("/project/" + projectId + "/dir/" + dirId + "/form/part", formDiff);
}

export function fetchFieldDataOfSameTypeFormsForFormFieldDateRange(formImportInfo: IFormImportInfo): Promise<Array<IFormFieldImportDTO>> {
  return bauhubGet("/project/" + formImportInfo.projectId + "/form/field/date", formImportInfo);
}

export function fetchFormDTO(directoryId: EntityId, fileEntityId: EntityId): Promise<IFormDTO> {
  return bauhubGet("/v2/project/dir/" + directoryId + "/form/" + fileEntityId);
}

export function saveFormData(request: IFormDataSaveRequest): Promise<IFormDataSaveRequest> {
  return bauhubPost("/v2/project/form/data/save", request);
}

export function saveNestedFormData(request: IFormDataSaveRequest): Promise<IFormDataSaveRequest> {
  return bauhubPost("/v2/project/form/data/nested/save", request);
}

export function addNestedFormData(request: IFormDataSaveRequest): Promise<IFormDataSaveRequest> {
  return bauhubPost("/v2/project/form/data/nested/add", request);
}

export function removeNestedFormData(request: IFormDataSaveRequest): Promise<IFormDataSaveRequest> {
  return bauhubPost("/v2/project/form/data/nested/remove", request);
}

export function saveNewForm(projectId: EntityId, dirId: EntityId, form: IForm): Promise<IForm> {
  return bauhubPost("/v2/project/" + projectId + "/dir/" + dirId + "/new/form/save", form);
}

export function fetchFormsOfTypeSince(projectId: EntityId, since: string, formTypes: Array<FormType>): Promise<Array<IFileEntity>> {
  return bauhubGet("/project/" + projectId + "/forms/added", { since: since, formTypes: formTypes });
}

export function saveFormName(projectId: EntityId, formFileEntityId: EntityId, formName: string): Promise<IForm> {
  return bauhubPost("/v2/project/" + projectId + "/form/" + formFileEntityId + "/name/save", { value: formName });
}

export function fetchSimpleFormsInSameDir(dirId: EntityId, formId: EntityId): Promise<Array<ISimpleForm>> {
  return bauhubGet("/v2/dir/" + dirId + "/form/" + formId + "/simpleforms");
}

export function prefillFormFromPreviousForm(formId: EntityId, simpleForm: ISimpleForm): Promise<any> {
  return bauhubPost("/v2/form/" + formId + "/prefill", simpleForm);
}

export function fetchFormWithTypeAndBaseVersionId(formFileEntityId: EntityId): Promise<IForm> {
  return bauhubGet("/v2/form/" + formFileEntityId + "/type");
}

export function saveFormLocked(projectId: EntityId, formId: EntityId, locked: boolean): Promise<IForm> {
  return bauhubPost("/v2/project/" + projectId + "/form/" + formId + "/lock", {}, { locked: locked });
}

export function fetchEligibleUsersForFormEdit(projectId: EntityId, formFileEntityId: EntityId): Promise<Array<IUser>> {
  return bauhubGet("/project/" + projectId + "/form/" + formFileEntityId + "/editors/available");
}

export function saveFormEditor(projectId: EntityId, formEditorInfo: IFormEditorInfo): Promise<IFormEditorChangeResponse> {
  return bauhubPost("/project/" + projectId + "/form/change/editor", formEditorInfo);
}
