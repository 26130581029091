import React, { FC } from "react";
import FormFieldContainerInput from "@components/form/base/body/fields/FormFieldContainerInput";
import { classNames } from "@/utilities/jsUtilities";
import { IFormDataSaveRequest } from "@/model/IForm";
import { useTranslation } from "react-i18next";

interface Props {
  person: any;
  columns: any;
  fieldProperty: string;
  enabledColumns: Array<string>;
  disabled?: boolean;
  saveCallback?: Function;
  isPreview?: boolean;
}

const FormPeopleFieldTableHeader: FC<Props> = ({ person, columns, enabledColumns, disabled, saveCallback, isPreview, fieldProperty }) => {
  const { t } = useTranslation();

  const saveNameLabel = (changedProperty: string, changedValue: string) => {
    const saveRequest = {
      path: fieldProperty,
      changedProperty: changedProperty,
      changedValue: changedValue,
      changedObjectId: person._id
    } as IFormDataSaveRequest;
    saveCallback && saveCallback(saveRequest);
  };

  return (
    <div className="flex w-full flex-row items-end gap-x-2">
      {enabledColumns?.map((key, index) => {
        return (
          <div
            className={classNames(
              "text-13px bh-text-deep-ocean-80 flex w-full flex-col font-semibold",
              key === "status" || key === "isSigner" ? "min-w-24 max-w-24 w-24 justify-center" : "justify-start",
              key === "name" && "form-people-field-width min-w-[174.5px]",
              index + 1 === enabledColumns.length && "mr-12"
            )}
            key={index}
          >
            {key === "name" ? (
              <FormFieldContainerInput initialValue={person.title} property={"title"} onBlurCallback={saveNameLabel} disabled={disabled} placeholder={t("FORMS.GLOBAL.TITLE") as string} />
            ) : (
              <div className={classNames(key === "status" || key === "isSigner" ? "whitespace-normal break-words text-center" : "h-4")}>{columns[key]?.inputLabel || "\u00A0"}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default FormPeopleFieldTableHeader;
