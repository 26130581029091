import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { EntityId } from "@reduxjs/toolkit";
import BhTabs from "@components/tabs/BhTabs";
import CompanySettingsGeneralTab from "@/views/home/company/detail/CompanySettingsGeneralTab";
import CompanySettingsProjectsTab from "@/views/home/company/detail/CompanySettingsProjectsTab";
import CompanySettingsUsersTab from "@/views/home/company/detail/CompanySettingsUsersTab";
import CompanySettingsProjectTreeTemplatesTab from "@/views/home/company/detail/CompanySettingsProjectTreeTemplatesTab";
import CompanySettingsPartyTemplatesTab from "./CompanySettingsPartyTemplatesTab";
import CompanySettingsPlanSettingsTab from "@/views/home/company/detail/CompanySettingsPlanSettingsTab";
import CompanySettingsBillsTab from "@/views/home/company/detail/CompanySettingsBillsTab";
import BhBreadcrumbs from "@components/breadcrumb/BhBreadcrumbs";
import { BauhubBannerType, BauhubPlan } from "@/model/IProject";
import BhProPlanTagFaded from "@components/tags/BhProPlanTagFaded";
import BhBanner from "@components/banners/BhBanner";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { textWithVariables } from "@/utilities/jsUtilities";
import { formatDate } from "@/utilities/dateUtility";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import CompanySettingsPlanChoiceTab from "@/views/home/company/detail/companySettingsSubscription/CompanySettingsPlanChoiceTab";
import CompanySettingsGetProPlanBanner from "@/views/home/company/detail/CompanySettingsGetProPlanBanner";
import { useLocation } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import CompanySettingsChecklistBasesTab from "@/views/home/company/detail/companySettingsChecklistBases/CompanySettingsChecklistBasesTab";
import { ITabArrayElement } from "@/model/tabs/ITabArrayElement";
import { isCurrentUserCompanyAdminWithAccountManagerPrivilege, isCurrentUserCompanyChecklistsManager, isCurrentUserCompanyFormsManager } from "@/app/store/userSlice";
import CompanySettingsFormBasesTab from "@/views/home/company/detail/companySettingsFormBases/CompanySettingsFormBasesTab";

interface Props {
  companyId: EntityId;
}

const CompanySettingsContainer: FC<Props> = ({ companyId }) => {
  const { t } = useTranslation();
  const isCurrentUserCompanyAdmin = useAppSelector((state) => isCurrentUserCompanyAdminWithAccountManagerPrivilege(state, companyId));
  const isCurrentUserChecklistsManager = useAppSelector((state) => isCurrentUserCompanyChecklistsManager(state, companyId));
  const isCurrentUserFormsManager = useAppSelector((state) => isCurrentUserCompanyFormsManager(state, companyId));
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const breadcrumbValues = [
    {
      text: t("HOME.MY_PROJECTS"),
      url: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/`
    },
    { text: t("COMPANY.SETTINGS.HEADER", { name: company?.name || "" }) }
  ];
  const location = useLocation();
  const urlWithoutLastSlash = location.pathname.endsWith("/") ? location.pathname.substring(0, location.pathname.length - 1) : location.pathname;
  const urlLastElement = urlWithoutLastSlash.split("/").slice(-1).join("/");

  const tabs = [
    isCurrentUserCompanyAdmin && {
      id: 0,
      header: t("COMPANY.GENERAL_INFO"),
      content: <CompanySettingsGeneralTab key="general" companyId={companyId} />,
      href: "general"
    },
    isCurrentUserCompanyAdmin && {
      id: 1,
      header: t("COMPANY.SETTINGS.PROJECTS"),
      content: <CompanySettingsProjectsTab key="projects" companyId={companyId} />,
      href: "projects"
    },
    isCurrentUserCompanyAdmin && {
      id: 2,
      header: t("COMPANY.SETTINGS.USERS"),
      content: <CompanySettingsUsersTab key="users" companyId={companyId} />,
      href: "users"
    },
    isCurrentUserCompanyAdmin && {
      id: 3,
      header: (
        <div className="flex flex-row">
          <span>{t("COMPANY.SETTINGS.FOLDER_STRUCTURE_TEMPLATES")}</span>
          {company?.plan === BauhubPlan.LIGHT && <BhProPlanTagFaded />}
        </div>
      ),
      content: <CompanySettingsProjectTreeTemplatesTab key="projectTreeTemplates" companyId={companyId} />,
      href: "project-tree-templates"
    },
    isCurrentUserCompanyAdmin && {
      id: 4,
      header: (
        <div className="flex flex-row">
          <span>{t("COMPANY.SETTINGS.PARTY_TEMPLATES")}</span>
          {company?.plan === BauhubPlan.LIGHT && <BhProPlanTagFaded />}
        </div>
      ),
      content: <CompanySettingsPartyTemplatesTab key="wgTreeTemplates" companyId={companyId} />,
      href: "work-group-templates"
    },
    (isCurrentUserCompanyAdmin || isCurrentUserFormsManager || isCurrentUserChecklistsManager) && {
      id: 5,
      header: t("COMPANY.SETTINGS.SUBSCRIPTIONS.PLANS"),
      content: <CompanySettingsPlanChoiceTab key="plan" companyId={companyId} />,
      href: "billing-plan"
    },
    isCurrentUserChecklistsManager && {
      id: 6,
      header: t("COMPANY.SETTINGS.CHECKLIST_BASES"),
      content: <CompanySettingsChecklistBasesTab key="checklistBases" companyId={companyId} />,
      href: "checklists"
    },
    isCurrentUserFormsManager && {
      id: 7,
      header: t("COMPANY.SETTINGS.FORM_BASES"),
      content: <CompanySettingsFormBasesTab key="formBases" companyId={companyId} />,
      href: "forms"
    },
    isCurrentUserCompanyAdmin && {
      id: 8,
      header: t("COMPANY.SETTINGS.SUBSCRIPTIONS.SETTINGS"),
      content: <CompanySettingsPlanSettingsTab key="settings" companyId={companyId} />,
      href: "billing-settings"
    },
    isCurrentUserCompanyAdmin && {
      id: 9,
      header: t("COMPANY.SETTINGS.ORDERS.INVOICES"),
      content: <CompanySettingsBillsTab key="bills" companyId={companyId} />,
      href: "bills"
    }
  ].filter(Boolean) as Array<ITabArrayElement>;
  const showGetProPlanBanner = company?.plan === BauhubPlan.LIGHT && urlLastElement !== "billing-plan";

  return (
    <BhScrollableBody>
      <div className="relative flex w-full flex-col ">
        <div className="bh-bg-smoke w-full px-44 pt-2 pb-14 lg:px-24 md:px-8">
          {company && company.status === "TRIAL" && (
            <div>
              <BhBanner type={BauhubBannerType.ANNOUNCEMENT} fullWidth={true} textClasses={"text-center"}>
                <span>{parse(textWithVariables(t("COMPANY.SETTINGS.TRIAL_BANNER_PART_1"), { trialEnd: formatDate(company.trialEnd) }))}</span>
                <a className="bh-underline-link font-weight-600" href={"billing-settings"}>
                  {t("COMPANY.SETTINGS.TRIAL_BANNER_PART_2")}
                </a>
                <span>{t("COMPANY.SETTINGS.TRIAL_BANNER_PART_3")}</span>
              </BhBanner>
            </div>
          )}
          <div className="relative mt-4">
            <BhBreadcrumbs values={breadcrumbValues} />
            <h1 className="mt-3">{company?.name}</h1>
            {showGetProPlanBanner && <CompanySettingsGetProPlanBanner companyId={companyId} />}
          </div>
        </div>
        <div className="-mt-12 flex h-full w-full flex-1 overflow-hidden pt-0.5">
          <BhTabs tabs={tabs} router={true} tabsContainerClasses="lg:px-24 px-44 md:px-8" />
        </div>
      </div>
    </BhScrollableBody>
  );
};

export default CompanySettingsContainer;
