import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  ref?: any;
  children: React.ReactNode;
  classes?: string;
  disableHover?: boolean;
}

const BhTableRow: FC<Props> = ({ ref, children, classes, disableHover }) => {
  return (
    <tr
      className={classNames("bh-table-row h-13 bh-hover-container l-h-20px group border-b py-2 transition-all duration-200 ease-in", classes, !disableHover && "hover:bh-bg-mint-30")}
      ref={ref ? ref : null}
    >
      {children}
    </tr>
  );
};

export default BhTableRow;
