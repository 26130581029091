import React, { FC, useState } from "react";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhInputWithPrefixDropdown from "@components/input/BhInputWithPrefixDropdown";
import { useTranslation } from "react-i18next";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { identifyMobileId, sendMobileIdIdentificationCode } from "@/api/verificationAPI";
import { fetchCurrentUserAsync, selectCurrentUser, selectCurrentUserInfo } from "@/app/store/userSlice";
import { getNumberAndPrefix } from "@/model/IUserInfo";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhSeparatorMarginless from "@components/separator/BhSeparatorMarginless";
import BhModalFooter from "@components/modal/BhModalFooter";
import { toggleUserProfileModalsOpen } from "@/app/store/userPreferenceSlice";

interface Props {
  setStatus: Function;
  setVerification: Function;
}

const PersonalizationMobileIdTab: FC<Props> = ({ setStatus, setVerification }) => {
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectCurrentUser);
  const userInfo = useAppSelector(selectCurrentUserInfo);
  const [telephonePrefix, setTelephonePrefix] = useState(userInfo && userInfo.phoneNumber ? getNumberAndPrefix(userInfo.phoneNumber).prefix : "+372");
  const [telephoneNumber, setTelephoneNumber] = useState(userInfo && userInfo.phoneNumber ? getNumberAndPrefix(userInfo.phoneNumber).number : undefined);
  const [identityCode, setIdentityCode] = useState("");
  const dispatch = useAppDispatch();

  const startPersonalization = () => {
    const identityCodeWithoutSpaces = identityCode.replace(/\s/g, "");
    const telephoneNumberWithoutSpaces = (telephoneNumber + "").replace(/\s/g, "");
    const combined = telephonePrefix + telephoneNumberWithoutSpaces;
    const validNumber = telephoneNumberWithoutSpaces && /\+\d{9,}/.test(combined);
    if (!identityCode || identityCodeWithoutSpaces.length === 0 || !validNumber) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          translateCode: "ERROR_INCORRECT_INPUT_PARAMETERS"
        })
      );
      return;
    }
    if (validNumber) {
      sendMobileIdIdentificationCode(currentUser.id)
        .then((result) => {
          setVerification(result.verificationCode);
          setStatus("verificationInput");
          identifyMobileId(combined, identityCodeWithoutSpaces, currentUser.id)
            .then(async (result) => {
              if (result.result === "OK") {
                dispatch(fetchCurrentUserAsync());
                setStatus("success");
              }
            })
            .catch(() => {
              dispatch(toggleUserProfileModalsOpen({ modal: "profileAuthSettingsPersonalizationModal" }));
            });
        })
        .catch((error) => console.log(error));
    }
  };
  return (
    <div>
      <form id="mobileSignForm" className="mx-20 mb-12 flex flex-col">
        <div className="mt-6">
          <BhInputStackedLabel
            initialValue={identityCode}
            property="code"
            label={t("USER.PROFILE.SSN")}
            onChangeCallback={(changedValue: any) => {
              if (changedValue.code !== undefined) setIdentityCode(changedValue.code);
            }}
            onEnterCallback={startPersonalization}
            debounceLength={50}
          />
        </div>
        <div className="mt-6">
          <BhInputWithPrefixDropdown
            initialPrefix={telephonePrefix}
            initialValue={telephoneNumber}
            dropdownElements={["+372", "+371", "+370", "+358", "+381", "+39", "+82"]}
            property="number"
            type="tel"
            onChangeCallback={(value: { prefix: string; number: string }) => {
              if (value.prefix !== undefined) setTelephonePrefix(value.prefix);
              if (value.number !== undefined) setTelephoneNumber(value.number);
            }}
            onEnterCallback={startPersonalization}
            label={`${t("GLOBAL.PHONE_NUMBER")}`}
            debounceLength={50}
          />
        </div>
      </form>
      <BhSeparatorMarginless />
      <div className="flex flex-row items-center justify-end p-4">
        <BhModalFooter
          cancelButtonText={t("GLOBAL.CANCEL") as string}
          onCancel={() => dispatch(toggleUserProfileModalsOpen({ modal: "profileAuthSettingsPersonalizationModal" }))}
          confirmButtonText={t("IDENTIFICATION.MODAL.IDENTIFY") as string}
          onConfirm={startPersonalization}
        />
      </div>
    </div>
  );
};
export default PersonalizationMobileIdTab;
