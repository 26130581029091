import React, { FC } from "react";
import BhTooltip from "@components/BhTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons/faCircleInfo";

interface Props {
  header: string;
  tooltip: string;
}
export const CompanySettingsAdminTableHeaderFC: FC<Props> = ({ header, tooltip }) => {
  return (
    <div className="flex flex-row gap-x-2 lg:flex-col">
      {header}
      <BhTooltip body={tooltip}>
        <FontAwesomeIcon icon={faCircleInfo} className="cursor-pointer" />
      </BhTooltip>
    </div>
  );
};
