import { Viewer } from "@xeokit/xeokit-sdk";

const getCenterPoint = (point: Array<number>) => {
  return [(point[0] + point[3]) / 2, (point[1] + point[4]) / 2, (point[3] + point[5]) / 2];
};

const calculateDistance = (point: Array<number>, medianCenterPoint: Array<number>) => {
  const pointCenter = getCenterPoint(point);
  const deltaX = medianCenterPoint[0] - pointCenter[0],
    deltaY = medianCenterPoint[1] - pointCenter[1],
    deltaZ = medianCenterPoint[2] - pointCenter[2];

  return Math.sqrt(deltaX ** 2 + deltaY ** 2 + deltaZ ** 2);
};

const findFurthestObjectIndexes = (medianPoint: Array<number>, objects: Array<Array<number>>) => {
  const distances = objects.map((object, index) => {
    return { distance: calculateDistance(object, medianPoint), index: index };
  });

  const sortedDistances = distances.sort((a: any, b: any) => a.distance - b.distance);

  const q1 = sortedDistances[Math.floor(sortedDistances.length * 0.05)].distance;
  const q3 = sortedDistances[Math.ceil(sortedDistances.length * 0.95)].distance;

  const iqr = q3 - q1;

  const upperBound = q3 + 1.1 * iqr;

  return sortedDistances.filter((dist) => dist.distance > upperBound).map((dist) => dist.index);
};

const findMedian = (arr: Array<number>) => {
  arr.sort((a: any, b: any) => a - b);
  const middleIndex = Math.floor(arr.length / 2);

  if (arr.length % 2 === 0) {
    return (arr[middleIndex - 1] + arr[middleIndex]) / 2;
  } else {
    return arr[middleIndex];
  }
};

export const objectsWithoutFurthest = (viewer: Viewer, objectIds: Array<string>) => {
  const objectAabbs = objectIds.map((id) => {
    return viewer.scene.objects[id].aabb;
  });

  const elementsAtPosition = Array.from({ length: objectAabbs[0].length }, (_, i) => objectAabbs.map((arr) => arr[i]));

  const [x1s, y1s, z1s, x2s, y2s, z2s] = elementsAtPosition;
  const ax1 = findMedian(x1s),
    ay1 = findMedian(y1s),
    az1 = findMedian(z1s),
    ax2 = findMedian(x2s),
    ay2 = findMedian(y2s),
    az2 = findMedian(z2s);
  const medianCenterPoint = [ax1, ay1, az1, ax2, ay2, az2];

  const furthestObjectIndexes = findFurthestObjectIndexes(medianCenterPoint, objectAabbs);

  return objectIds.filter((el, index) => !furthestObjectIndexes.includes(index));
};
