import React, { FC } from "react";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

interface Props {
  text: string;
  url?: string;
  onClick?: Function;
  active: boolean;
  classes?: string;
}

const BhBreadcrumb: FC<Props> = ({ text, url, onClick, active, classes }) => {
  const navigate = useNavigate();
  const commonClasses = " hover:bh-text-deep-ocean";
  const activeClasses = " bh-text-deep-ocean font-bold";
  const defaultClasses = " inline-flex bh-text-deep-ocean-60 mr-2 items-center gap-2 cursor-pointer";
  classes += commonClasses;

  if (active) {
    classes += activeClasses;
  } else {
    classes += defaultClasses;
  }

  const onClickCallback = () => {
    if (onClick) {
      onClick();
    }
    if (url) {
      navigate(url);
    }
  };

  return (
    <span className={classes} onClick={onClickCallback}>
      {text}
      {!active && <FontAwesomeIcon icon={faChevronRight} className="bh-text-deep-ocean-80 max-h-[9px] max-w-[5px]" />}
    </span>
  );
};

export default React.memo(BhBreadcrumb);
