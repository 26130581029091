import React, { useState } from "react";
import CompanyUserProject from "@/views/home/company/detail/companySettingsUsers/CompanyUserProject";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons/faArrowsRotate";
import { deletableUserSet, resendAllInvitesForCompanyAndUsernameAsync } from "@/app/store/companiesSlice";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import { ICompanyInvitedUser } from "@/model/companyUsers/ICompanyInvitedUser";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentUser } from "@/app/store/userSlice";
import { useTranslation } from "react-i18next";
import BhTooltip from "@components/BhTooltip";
import { useModal } from "react-modal-hook";
import ResendInvitationModal from "@/views/home/company/detail/companySettingsUsers/ResendInvitationModal";

interface Props {
  companyUser: ICompanyUser | ICompanyInvitedUser;
}

const CompanyUserRowEndContainer = React.memo<Props>(({ companyUser }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const notOwnUser = companyUser.username !== currentUser.username;
  const projectNames = companyUser.projects.map((simpleProject) => simpleProject.projectName);
  const [expanded, toggleExpanded] = useState(projectNames.length === 1);

  const [showResendInvitationConfirmationModal, hideResendInvitationConfirmationModal] = useModal(() => (
    <ResendInvitationModal
      setIsOpen={hideResendInvitationConfirmationModal}
      handleResend={() => {
        dispatch(resendAllInvitesForCompanyAndUsernameAsync({ companyId: companyUser.companyId, username: companyUser.username }));
        hideResendInvitationConfirmationModal();
      }}
    />
  ));

  const toggleExpandProjects = () => {
    toggleExpanded((state) => !state);
  };

  return (
    <div className="w-full">
      {companyUser.projects && (
        <>
          {projectNames.length > 0 && (
            <>
              {projectNames.length > 1 && (
                <div id={"summary-" + companyUser.username} className="flex items-center justify-between">
                  <div className="flex flex-row items-center gap-x-2 py-3 align-middle lg:flex-col lg:items-start">
                    <div className="inline-block">{t("COMPANY.SETTINGS.USERS.USER_IN_PROJECTS", { projectCount: projectNames.length })}</div>
                    <div className="bh-text-dark-jungle bh-underline-link inline-block cursor-pointer text-sm" onClick={toggleExpandProjects}>
                      {t(!expanded ? "GLOBAL.SHOW" : "GLOBAL.HIDE")}
                    </div>
                  </div>
                  <div className="flex grow-0 flex-row justify-end lg:flex-col-reverse">
                    {!companyUser.userExists && (
                      <BhTooltip body={t("GLOBAL.RESEND")}>
                        <BhIconButton icon={faArrowsRotate} buttonProps={{ onClick: showResendInvitationConfirmationModal }} />
                      </BhTooltip>
                    )}
                    {notOwnUser && (
                      <BhTooltip body={t("COMPANY.SETTINGS.USERS.REMOVE_FROM_COMPANY")}>
                        <BhIconButton icon={faCircleMinus} buttonProps={{ onClick: () => dispatch(deletableUserSet(companyUser)) }} />
                      </BhTooltip>
                    )}
                  </div>
                </div>
              )}
              {expanded && (
                <div id="hiddenProjects" className="w-full">
                  {companyUser.projects.map((project) => (
                    <CompanyUserProject project={project} key={project.projectId} user={companyUser} />
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
});

export default CompanyUserRowEndContainer;
