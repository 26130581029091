import React, { FC, useState } from "react";
import { IFileCopyOrMoveDTO, IFileEntity, IFileMatch, IFileMoveOrCopyRequestDTO, IRevisionMatcherFileEntity, IRevisionReplaceRequestDTO } from "@/model/files/IFileEntity";
import { EntityId } from "@reduxjs/toolkit";
import RevisionModal from "@/views/home/project/detail/directory/directoryModals/revisionModal/RevisionModal";
import SimilarFileNameWarningModal from "@/views/home/project/detail/directory/directoryModals/copyAndMoveModals/SimilarFileNameWarningModal";
import { useTranslation } from "react-i18next";

interface Props {
  dtos: Array<IFileCopyOrMoveDTO>;
  onClose: Function;
  projectId: EntityId;
  copyRevisions: boolean;
}

const SimilarFileNamesWarningModals: FC<Props> = ({ dtos, onClose, projectId, copyRevisions }) => {
  const { t } = useTranslation();
  const [showSimilarFileNamesModal, setShowSimilarFileNamesModal] = useState(true);
  const [showRevisionModal, setShowRevisionModal] = useState(false);
  const firstDTO = dtos[0];
  const files = dtos.filter((dto) => dto.fileToCopyOrMove.parentFileEntityId === firstDTO.fileToCopyOrMove.parentFileEntityId).map((dto) => dto.fileToCopyOrMove) as Array<IRevisionMatcherFileEntity>;
  const destinationDirectory = firstDTO.destinationDirectory;
  const fileCopyOrMoveRequestDTO = {
    fileIds: files.map((file) => file.id),
    destinationId: destinationDirectory.id,
    fromId: firstDTO.fileToCopyOrMove.parentFileEntityId,
    projectId: projectId,
    copyRevisions: copyRevisions,
    mergeFolders: false,
    keepSameName: false
  } as IFileMoveOrCopyRequestDTO;

  const close = () => {
    const dtoToRemove = { fileIds: fileCopyOrMoveRequestDTO.fileIds } as IFileMoveOrCopyRequestDTO;
    onClose([dtoToRemove]);
  };

  const similarFileNamesModalOptionChosen = (manageFiles: boolean) => {
    if (manageFiles) {
      setShowSimilarFileNamesModal(false);
      setShowRevisionModal(true);
    } else {
      onClose([fileCopyOrMoveRequestDTO]);
    }
  };

  const revisionModalCallback = (fileMatches: Array<IFileMatch>, unmatchedFiles: Array<IFileEntity>) => {
    const dtosForCallback = [] as Array<IFileMoveOrCopyRequestDTO>;
    if (fileMatches.length > 0) {
      fileMatches.forEach((fileMatch) => {
        const fileMatchDTO = {
          ...fileCopyOrMoveRequestDTO,
          ...{ fileIds: [fileMatch.file.id], keepSameName: true,
              fileRevisionRequests: [{ replacementFileId: fileMatch.file.id, fileToReplaceId: fileMatch.matchingFile.id } as IRevisionReplaceRequestDTO ]}
        } as IFileMoveOrCopyRequestDTO;
        dtosForCallback.push(fileMatchDTO);
      });
    }
    if (unmatchedFiles.length > 0) {
      const unmatchedFilesIds = unmatchedFiles.map((file) => file.id);
      const unmatchedFilesDTO = { ...fileCopyOrMoveRequestDTO, fileIds: unmatchedFilesIds } as IFileMoveOrCopyRequestDTO;
      dtosForCallback.push(unmatchedFilesDTO);
    }
    onClose(dtosForCallback);
  };

  return (
    <>
      {showSimilarFileNamesModal && <SimilarFileNameWarningModal onClose={close} onSubmit={similarFileNamesModalOptionChosen} destinationDirectoryName={destinationDirectory.name} />}
      {showRevisionModal && <RevisionModal onClose={close} onSubmit={revisionModalCallback} files={files} directoryId={destinationDirectory.id} />}
    </>
  );
};

export default SimilarFileNamesWarningModals;
