import { bauhubGet, bauhubPostWithBaseUrl } from "@/api/bauhubAPI";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

export function logOutOfCas(redirectUrl?: string): Promise<void> {
  return bauhubPostWithBaseUrl("/logout/cas?st=false", ConfigSingleton.getInstance().getConfig().REACT_APP_BASE_URL).finally(() => {
    const redirect = redirectUrl ? "?redirect=" + encodeURIComponent(redirectUrl) : "";
    // eslint-disable-next-line no-restricted-globals
    location.href = ConfigSingleton.getInstance().getConfig().REACT_APP_CAS_URL + "/logout" + redirect;
  });
}

export function redirect(): Promise<void> {
  return bauhubGet("/user/session").then((res: { value: string }) => {
    const redirect = "baudrive://session=" + res.value;
    // eslint-disable-next-line no-restricted-globals
    location.href = redirect;
  });
}
