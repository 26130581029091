import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import CompanySettingsPlanLimitModalContent from "@/views/home/company/detail/companySettingsSubscription/CompanySettingsPlanLimitModalContent";
import { useAppSelector } from "@/app/hooks";
import { isCurrentUserCompanyChecklistsManager } from "@/app/store/userSlice";

interface Props {
  companyId: EntityId;
}

const NewChecklistBaseModalLimitContentContainer: FC<Props> = ({ companyId }) => {
  const isChecklistManager = useAppSelector((state) => isCurrentUserCompanyChecklistsManager(state, companyId));

  return (
    <CompanySettingsPlanLimitModalContent
      companyId={companyId}
      isManager={isChecklistManager}
      modalTextKey="CHECKLISTS.LIGHT_PLAN_LIMIT_MODAL.TEXT"
      lightBannerTextKey="CHECKLISTS.LIGHT_PLAN_LIMIT_MODAL.LIGHT_BODY"
      proBannerTextKey="CHECKLISTS.LIGHT_PLAN_LIMIT_MODAL.PRO_BODY"
    />
  );
};

export default NewChecklistBaseModalLimitContentContainer;
