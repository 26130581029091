import React, { FC, startTransition, useCallback, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";

interface Props {
  initialValue?: any;
  property: string;
  saveCallback?: Function;
  disabled?: boolean;
  classes?: string;
}

const BhTimePicker: FC<Props> = ({ initialValue, property, saveCallback, disabled, classes }) => {
  const [time, setTime] = useState(initialValue || "");

  const onBlur = useCallback(() => {
    startTransition(() => {
      if (time === initialValue) return;
      const changedValue = { [property]: time ? time : "" };
      saveCallback && saveCallback(changedValue);
    });
  }, [saveCallback, initialValue, time]);

  return (
    <div className="relative flex flex-row items-center">
      <TimePicker value={initialValue} onChange={setTime} disableClock={true} locale={"et-EE"} format={"HH:mm"} onClockClose={onBlur} disabled={disabled} clearIcon={null} className={classes} />
      <div className="absolute right-2.5 top-2">
        <FontAwesomeIcon icon={faClock} className="bh-text-pigeon h-4 w-4 pt-0.5" />
      </div>
    </div>
  );
};

export default BhTimePicker;
