import React, { FC } from "react";
import { ITask } from "@/model/taskBoard/ITask";
import TaskInfoFieldLabel from "@components/task/modal/TaskInfoFieldLabel";
import { faClockRotateLeft } from "@fortawesome/pro-regular-svg-icons/faClockRotateLeft";
import TaskHistoryLog from "@components/task/modal/TaskHistoryLog";
import { IObjectChange, UserAction } from "@/model/IObjectChange";
import { useAppSelector } from "@/app/hooks";
import { selectTaskHistorySorted } from "@/app/store/tasksSlice";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "@/utilities/dateUtility";

interface Props {
  task: ITask;
}

const TaskHistory: FC<Props> = ({ task }) => {
  const { t } = useTranslation();
  const taskHistory = useAppSelector((state) => selectTaskHistorySorted(state, task.id));

  const createdBy = {
    creatorFullName: task.reporterFullName,
    userAction: UserAction.ADDED,
    field: "task",
    newValue: formatDateTime(task.created)
  } as IObjectChange;

  return (
    <div className="flex h-full flex-col overflow-hidden px-4 pb-4">
      <div className="py-5">
        <TaskInfoFieldLabel text={t("TASK.CHANGE_LOG")} icon={faClockRotateLeft} />
      </div>
      <div className="task-column-scrollbar flex h-full flex-col overflow-y-auto">
        {taskHistory &&
          taskHistory.length > 0 &&
          taskHistory.map((log) => {
            return <TaskHistoryLog log={log} key={log.id} />;
          })}
        {task.id && <TaskHistoryLog log={createdBy} />}
      </div>
    </div>
  );
};

export default TaskHistory;
