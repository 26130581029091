import BhModal from "@/components/modal/BhModal";
import React, { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhInputWithTags from "@components/input/BhInputWithTags";
import { deleteFileEntityTagAsync, saveFileEntityTagAsync, selectDistinctTagsForCurrentDirFiles, selectFileById } from "@/app/store/filesSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { IFileEntityTag } from "@/model/files/IFileEntityTag";
import { store } from "@/app/store";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";

interface Props {
  hideTagModal: () => void;
  fileEntityId: EntityId;
}

const FileNewTagModal: FC<Props> = ({ hideTagModal, fileEntityId }) => {
  const { t } = useTranslation();
  const fileEntity = useAppSelector((state) => selectFileById(state, fileEntityId));
  const tagsInDir = useAppSelector(selectDistinctTagsForCurrentDirFiles);
  const tagInputRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  if (!fileEntity) return null;

  const saveTag = (tag: IFileEntityTag) => {
    if (tag.text === undefined || !tag.text.trim().length) return;
    if (fileEntity.tags.some((t) => t.text?.toLowerCase() === tag.text)) {
      store.dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.WARNING,
          disappear: true,
          translateCode: "FILE.DUPLICATE_TAG"
        })
      );
      return;
    }
    const tagToSave = { text: tag.text, fileEntityId: fileEntity.id } as IFileEntityTag;
    dispatch(saveFileEntityTagAsync(tagToSave));
  };

  const removeTag = (tag: IFileEntityTag) => {
    dispatch(deleteFileEntityTagAsync(tag));
  };

  return (
    <div className="relative">
      <BhModal setIsShown={hideTagModal} header={<h2>{t("TAG.CHOOSE")}</h2>} footer={<BhModalFooter cancelButtonText={`${t("GLOBAL.CLOSE")}`} onCancel={hideTagModal} />}>
        <div className="h-32">
          <div className="absolute w-full px-8 pb-8 pt-4">
            <BhInputWithTags
              label={`${t("FILE.TAGS")}`}
              inputRef={tagInputRef}
              customTextProperty={"text"}
              currentValues={fileEntity.tags}
              dropdownValues={tagsInDir}
              onSelect={saveTag}
              onRemove={removeTag}
            />
          </div>
        </div>
      </BhModal>
    </div>
  );
};

export default FileNewTagModal;
