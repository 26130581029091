import React, { FC, SetStateAction } from "react";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhComboboxStackedLabel from "@components/input/BhComboboxStackedLabel";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import { selectAllCompanyProjects } from "@/app/store/companiesSlice";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { useTranslation } from "react-i18next";
import BhFixedDropdownParent from "@components/dropdown/BhFixedDropdownParent";

interface Props {
  isShown: boolean;
  setIsShown: React.Dispatch<SetStateAction<boolean>>;
  companyId: EntityId;
  handleSelect: Function;
  handleCancel: Function;
}

const ProjectTreeImportModal: FC<Props> = ({ isShown, setIsShown, companyId, handleSelect, handleCancel }) => {
  const { t } = useTranslation();
  const companyProjects = useAppSelector((state) => selectAllCompanyProjects(state, companyId));

  return (
    <BhModal
      isShown={isShown}
      setIsShown={setIsShown}
      header={<h2>{t("COMPANY.SETTINGS.PROJECT.TREE.IMPORT_HEADER")}</h2>}
      footer={<BhModalFooter cancelButtonText={`${t("GLOBAL.CANCEL")}`} confirmButtonText={`${t("GLOBAL.IMPORT")}`} onCancel={handleCancel} />}
    >
      <div className="h-56">
        <div className="flex flex-col gap-4 p-8">
          <span>{t("COMPANY.SETTINGS.PROJECT.TREE.IMPORT")}</span>
        </div>
        <div>
          <BhFixedDropdownParent>
            <div className="px-8">
              <BhComboboxStackedLabel initialValue="" property="project" values={companyProjects} customType={BhDropdownTypeEnum.PROJECT} onSelect={handleSelect} label={t("HOME.PROJECTS")} />
            </div>
          </BhFixedDropdownParent>
        </div>
      </div>
    </BhModal>
  );
};

export default ProjectTreeImportModal;
