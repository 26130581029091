import React, { FC } from "react";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/pro-regular-svg-icons/faArrowUp";
import { faArrowDown } from "@fortawesome/pro-regular-svg-icons/faArrowDown";
import { classNames } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";

interface Props<T> {
  columns: Array<IBhTableHeader<T>>;
  onClickCallback?: Function;
  sortedBy?: keyof T;
  reversed?: boolean | string;
  translateContent?: boolean;
}

const BhTableHeader: FC<Props<any>> = ({ columns, onClickCallback, sortedBy, reversed, translateContent }) => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr className="bh-table-header-text h-10 min-h-[40px] border-b py-1 align-middle font-bold">
        {columns.map((column) => (
          <th key={column.id} className={classNames("px-3", column.classes)} colSpan={column.colSpan || 1}>
            <span className={classNames(column.sortable && "cursor-pointer")} onClick={() => column.sortable && onClickCallback?.(column)}>
              <span>{translateContent && column.content && typeof column.content === "string" ? t(column.content) : column.content}</span>
              {sortedBy && sortedBy === column.field && (
                <button onClick={() => {}} className="bh-text-deep-ocean-imp hover:bh-bg-smoke disabled:bh-text-pigeon-60 bh-text-pigeon mx-0.5 rounded px-0.5 py-0.5 font-bold">
                  <FontAwesomeIcon className="px-1" icon={(typeof reversed === "boolean" && reversed) || reversed === "true" ? faArrowDown : faArrowUp} />
                </button>
              )}
            </span>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default BhTableHeader;
