import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  selected?: boolean;
  children: React.ReactNode;
  onClick?: Function;
  classes?: string;
  isFirst?: boolean;
  isLast?: boolean;
  leadingIcon?: IconProp;
  iconClasses?: string;
}

const BhCardContainerWithLeadingIcon: FC<Props> = ({ selected, children, onClick, classes, isFirst, isLast, iconClasses, leadingIcon }) => {
  return (
    <div className="flex flex-row items-center">
      <div className="w-8">{leadingIcon && <FontAwesomeIcon icon={leadingIcon} className={iconClasses} aria-hidden="true" />}</div>
      <div
        className={classNames(
          classes && classes,
          selected ? "bh-bg-mint-50 bh-outline-dark-jungle hover:bh-bg-mint border" : "bh-outline-pigeon-40 hover:bh-bg-mint-30 hover:bh-outline-pigeon hover:z-10",
          "mt-1px relative flex min-h-[80px] flex-1 items-center justify-between outline outline-1 transition duration-500 ease-in-out",
          onClick && "cursor-pointer",
          isLast && "rounded-b",
          isFirst && "rounded-t"
        )}
        onClick={() => {
          onClick && onClick();
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default BhCardContainerWithLeadingIcon;
