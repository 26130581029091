import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { cancelAsyncJobAsync, selectAsyncJobById } from "@/app/store/asyncJobsSlice";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhProgressBar from "@components/progress/BhProgressBar";
import BhSmallText from "@components/text/BhSmallText";
import { Trans } from "react-i18next";

interface Props {
  asyncJobId: EntityId;
}

const BhAsyncJobContainer: FC<Props> = ({ asyncJobId }) => {
  const asyncJob = useAppSelector((state) => selectAsyncJobById(state, asyncJobId));
  const dispatch = useAppDispatch();

  if (!asyncJob) return null;

  return (
    <div className="flex flex-col px-4 py-1">
      <div className="flex flex-row items-center justify-between">
        <h3>
          <Trans>{"ASYNC." + asyncJob.jobType}</Trans>
        </h3>
        <BhTextOnlyButton buttonProps={{ onClick: () => dispatch(cancelAsyncJobAsync(asyncJob)) }}>
          <Trans>GLOBAL.CANCEL</Trans>
        </BhTextOnlyButton>
      </div>
      <div className="flex flex-row items-center">
        <div className="flex-1">
          <BhProgressBar progress={asyncJob.progress} total={100} />
        </div>
        <div className="w-12 text-center font-bold">
          <BhSmallText classes="bh-text-pigeon">{asyncJob.progress}%</BhSmallText>
        </div>
      </div>
    </div>
  );
};

export default BhAsyncJobContainer;
