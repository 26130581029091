import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectAllProjectWorkGroups } from "@/app/store/project/projectWorkGroupsSlice";
import { classNames } from "@/utilities/jsUtilities";
import { naturalSortFilesByField } from "@/utilities/sortUtilities";
import { saveChecklistBaseToAllWorkGroupsAsync, selectWorkgroupToChecklistBases } from "@/app/store/checklist/checklistsSlice";
import BhToggleRow from "@components/toggle/BhToggleRow";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import PartiesListItemTag from "@/views/home/project/detail/users/parties/PartiesListItemTag";
import BhUserIconGrouped from "@components/user/BhUserIconGrouped";
import { IChecklistBase } from "@/model/checklist/IChecklistBase";

interface Props {
  activeChecklistBase: IChecklistBase;
  activateBaseInWorkGroup: Function;
}

const ChecklistsAuthoritiesModal: FC<Props> = ({ activeChecklistBase, activateBaseInWorkGroup }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const workGroups = useAppSelector(selectAllProjectWorkGroups);
  const workGroupAuthorities = useAppSelector(selectWorkgroupToChecklistBases);
  const [workGroupsSorted, setWorkGroupsSorted] = useState(workGroups.slice().sort((a, b) => naturalSortFilesByField(a, b, "name", false)) as Array<any>);
  const [activeBaseInAllWorkGroups, setActiveBaseInAllWorkGroups] = useState(false);

  useEffect(() => {
    if (activeChecklistBase) {
      const workGroupsToSet = workGroupsSorted.map((wg) => {
        const isChecklistBaseActivatedInWorkGroup = workGroupAuthorities.some((auth) => auth.checklistBaseId === activeChecklistBase.id && auth.workGroupId === wg.id && auth.active);
        return { ...wg, selected: isChecklistBaseActivatedInWorkGroup };
      });
      setWorkGroupsSorted(workGroupsToSet);

      const baseInAllWgs =
        workGroupsToSet.filter((wg) => {
          return wg.selected;
        }).length === workGroupsSorted.length;
      setActiveBaseInAllWorkGroups(baseInAllWgs);
    }
  }, [activeChecklistBase, workGroupAuthorities]);

  const toggleAllWorkGroupsInActiveChecklistBase = () => {
    const dtoToSave = { projectId: projectId, checklistBaseId: activeChecklistBase.id, removeBase: activeBaseInAllWorkGroups };
    dispatch(saveChecklistBaseToAllWorkGroupsAsync(dtoToSave));
  };

  return (
    <div>
      <BhToggleRow
        toggleObject={{ id: 0, selected: activeBaseInAllWorkGroups } as any}
        toggleProperty={"selected"}
        onToggle={toggleAllWorkGroupsInActiveChecklistBase}
        label={<div className="bh-text-deep-ocean text-14px mt-4 font-bold leading-5">{t("CHECKLIST.ALL_USER_GROUPS")}</div>}
        classes={"pb-5"}
      />
      {workGroupsSorted &&
        workGroupsSorted.length > 0 &&
        workGroupsSorted.map((wg, index) => {
          return (
            <BhToggleRow
              key={wg.id}
              toggleObject={wg}
              toggleProperty={"selected"}
              onToggle={() => activateBaseInWorkGroup(activeChecklistBase, wg)}
              label={
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-col gap-y-1">
                    <div className="bh-text-deep-ocean text-14px leading-5">{wg.name}</div>
                    {wg.type && (
                      <div className="ml-1">
                        <PartiesListItemTag workGroupType={wg.type} />
                      </div>
                    )}
                  </div>
                  <div className="mr-10 flex w-28 flex-row items-center">
                    <BhUserIconGrouped userGroup={wg.users} size={24} />
                  </div>
                </div>
              }
              classes={classNames("pt-5 bh-border-pigeon-20 border-t", index !== workGroupsSorted.length - 1 && "pb-5")}
            />
          );
        })}
    </div>
  );
};

export default ChecklistsAuthoritiesModal;
