import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { BauhubPlan } from "@/model/IProject";
import CompanySettingsProjectTreeTemplatesTabPro from "@/views/home/company/detail/CompanySettingsProjectTreeTemplatesTabPro";
import CompanySettingsGetProPlanPlaceholder from "./CompanySettingsGetProPlanPlaceholder";

interface Props {
  companyId: EntityId;
}

const CompanySettingsProjectTreeTemplatesTab: FC<Props> = ({ companyId }) => {
  const company = useAppSelector((state) => selectCompanyById(state, companyId));

  if (!company || !company.plan) return null;

  return (
    <>
      {company.plan === BauhubPlan.LIGHT && <CompanySettingsGetProPlanPlaceholder companyId={companyId} isFolderStructureTemplateView={true} />}
      {company.plan === BauhubPlan.PRO && <CompanySettingsProjectTreeTemplatesTabPro companyId={companyId} />}
    </>
  );
};

export default CompanySettingsProjectTreeTemplatesTab;
