import React, { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useOuterClick } from "@/utilities/hooks/useOuterClick";
import { useTranslation } from "react-i18next";
import FormBaseTextFieldInputDropdown from "@components/form/base/body/fields/textField/FormBaseTextFieldInputDropdown";
import BhTextareaStackedLabel from "@components/input/BhTextareaStackedLabel";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import FormBaseInputWithCustomDropdown from "@components/form/base/body/formBaseInfo/FormBaseInputWithCustomDropdown";

interface Props {
  property: string;
  disabled?: boolean;
  saveCallback: Function;
  initialValue: string;
  fieldValue: any;
  textFieldLabelValue: string;
}

const FormBaseTextFieldInput: FC<Props> = ({ property, initialValue, disabled, saveCallback, fieldValue, textFieldLabelValue }) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const outsideClickRef = useOuterClick(() => {
    setIsDropdownOpen(false);
  });
  const inputDisabled = disabled || (fieldValue.prefill && fieldValue.prefill.enabled);

  const textFieldLabelPrefillMap: Record<string, string> = {
    projectName: t("FORMBUILDER.FORM.BASE.TEXT.PREFILL.LABEL.PROJECTNAME"),
    projectAddress: t("FORMBUILDER.FORM.BASE.TEXT.PREFILL.LABEL.PROJECTADRESS"),
    projectContractNumber: t("FORMBUILDER.FORM.BASE.TEXT.PREFILL.LABEL.PROJECTCONTRACTNUMBER"),
    companyName: t("FORMBUILDER.FORM.BASE.TEXT.PREFILL.LABEL.COMPANYNAME"),
    createdByName: t("FORMBUILDER.FORM.BASE.TEXT.PREFILL.LABEL.CREATEDBYNAME")
  };

  const savePrefillOption = (enabled: boolean, value: any) => {
    if (!fieldValue.prefill?.enabled && !enabled) return;
    const prefillObject = { prefill: { enabled: enabled, value: value } };
    saveCallback("prefill", prefillObject);
    if (prefillObject.prefill.enabled) {
      saveCallback("userInput", { userInput: value === "createdByName" });
    }
    const prefillLabel = prefillObject.prefill.enabled && (!textFieldLabelValue || Object.values(textFieldLabelPrefillMap).includes(textFieldLabelValue));
    if (prefillLabel) {
      saveCallback("label", { label: textFieldLabelPrefillMap[prefillObject.prefill.value] });
    }
    if (fieldValue.prefill && !fieldValue.prefill.enabled && fieldValue.text && fieldValue.text.length > 0) {
      saveCallback("text", { text: "" });
    }
    setIsDropdownOpen(false);
  };

  return (
    <div className="w-full" ref={outsideClickRef}>
      <div className="relative">
        <div className="w-full">
          {!inputDisabled && (
            <BhTextareaStackedLabel
              property={property}
              disabled={inputDisabled}
              onBlurCallback={(value: any) => saveCallback(property, value)}
              initialValue={initialValue}
              placeholder={fieldValue.prefill && fieldValue.prefill.enabled && t("FORMBUILDER.FORM.BASE.TEXT.PREFILL." + fieldValue.prefill.value.toUpperCase())}
              inputClasses={"pr-10"}
            />
          )}
          {inputDisabled && (
            <div className="mb-[4.5px]">
              <FormBaseInputWithCustomDropdown
                value={
                  fieldValue.prefill && fieldValue.prefill.enabled ? t("FORMBUILDER.FORM.BASE.TEXT.PREFILL." + fieldValue.prefill.value.toUpperCase()) : fieldValue.text ? fieldValue.text : "\u00A0"
                }
                dropdown={<FormBaseTextFieldInputDropdown saveCallback={savePrefillOption} currentValue={fieldValue.prefill && fieldValue.prefill.value} />}
                dropdownOpen={isDropdownOpen}
                setDropdownOpen={setIsDropdownOpen}
                disabled={disabled}
              />
            </div>
          )}
        </div>
        {!inputDisabled && (
          <div className="absolute top-[9.5px] right-[6.5px] h-6 w-6 cursor-pointer text-center" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <div className="relative">
              <FontAwesomeIcon icon={faAngleDown} />
              {isDropdownOpen && <FormBaseTextFieldInputDropdown saveCallback={savePrefillOption} currentValue={fieldValue.prefill?.value} />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormBaseTextFieldInput;
