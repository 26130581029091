import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { EntityId } from "@reduxjs/toolkit";
import { IPredefinedWorkGroup } from "@/model/IPredefinedWorkGroup";
import { IPredefinedWorkGroupUser } from "@/model/IUserAuthority";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { WorkGroupType } from "@/model/IWorkGroup";

export function fetchPredefinedParties(companyId: EntityId): Promise<Array<IPredefinedWorkGroup>> {
  return bauhubGet("/company/" + companyId + "/workgroup/predefined/list");
}

export function saveNewPredefinedParty(predefinedParty: IPredefinedWorkGroup): Promise<IPredefinedWorkGroup> {
  return bauhubPost("/company/workgroup/predefined/new", predefinedParty);
}

export function saveNewPredefinedPartyUser(predefinedPartyUser: IPredefinedWorkGroupUser): Promise<IPredefinedWorkGroupUser> {
  return bauhubPost("/company/workgroup/predefined/user/add", predefinedPartyUser);
}

export function savePredefinedPartyUser(predefinedPartyUser: IPredefinedWorkGroupUser): Promise<IPredefinedWorkGroupUser> {
  return bauhubPost("/company/workgroup/predefined/user/change", predefinedPartyUser);
}

export function removePredefinedPartyUser(predefinedPartyUser: IPredefinedWorkGroupUser): Promise<IPredefinedWorkGroupUser> {
  return bauhubPost("/company/workgroup/predefined/user/remove", predefinedPartyUser);
}

export function savePredefinedPartyFileTreeRelation(companyId: EntityId, predefinedPartyId: EntityId, templateId: EntityId, relations: Array<EntityId>): Promise<Array<EntityId>> {
  return bauhubPost("/company/" + companyId + "/workgroup/predefined/" + predefinedPartyId + "/tree/template/" + templateId + "/relation", { value: relations });
}

export function fetchCompanyUsersForNewPredefinedParty(companyId: EntityId): Promise<Array<ISimpleValidUserAuthority>> {
  return bauhubGet("/company/" + companyId + "/projects/users");
}

export function savePredefinedPartyName(companyId: EntityId, partyId: EntityId, dto: { value: string }): Promise<{ value: string }> {
  return bauhubPost("/company/" + companyId + "/workgroup/predefined/" + partyId + "/name", dto);
}

export function savePredefinedPartyType(companyId: EntityId, partyId: EntityId, dto: { value?: WorkGroupType }): Promise<{ value?: WorkGroupType }> {
  return bauhubPost("/company/" + companyId + "/workgroup/predefined/" + partyId + "/type", dto);
}

export function removePredefinedParty(predefinedParty: IPredefinedWorkGroup): Promise<IPredefinedWorkGroup> {
  return bauhubPost("/company/workgroup/predefined/delete", predefinedParty);
}

export function findPredefinedWorkGroupToFileTreeTemplateFileRelations(companyId: EntityId, predefinedPartyId: EntityId, selectedPredefinedFileStructureId: EntityId): Promise<any> {
  return bauhubGet("/company/" + companyId + "/workgroup/predefined/" + predefinedPartyId + "/tree/template/" + selectedPredefinedFileStructureId + "/relation/list");
}
