import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  allFilesSelectedToggledInSearch,
  allFilteredFilesSelectedToggledInDirectory,
  selectAllFilteredFilesSelectedInCurrentDirectory,
  selectCurrentDirId,
  selectFilteredAndSortedFileEntityIdsForDirectory
} from "@/app/store/filesSlice";
import { selectAllFilesSelectedInSearch, selectFilteredAndSortedFileEntityIdsInSearch } from "@/app/store/searchSlice";

interface Props {
  isSearchView?: boolean;
}

const FileContainerTableHeaderCheckbox: FC<Props> = ({ isSearchView }) => {
  const searchResultFileIds = useAppSelector(selectFilteredAndSortedFileEntityIdsInSearch);
  const currentDirId = useAppSelector(selectCurrentDirId);
  const filteredFileIdsInDirectory = useAppSelector((state) => currentDirId && selectFilteredAndSortedFileEntityIdsForDirectory(state, currentDirId));
  const allFilesSelectedInCurrentDirectory = useAppSelector((state) =>
    isSearchView ? selectAllFilesSelectedInSearch : currentDirId && selectAllFilteredFilesSelectedInCurrentDirectory(state, currentDirId)
  ) as boolean;
  const dispatch = useAppDispatch();

  if (!currentDirId && !isSearchView) {
    return null;
  }

  const handleChange = () => {
    isSearchView
      ? dispatch(allFilesSelectedToggledInSearch(searchResultFileIds))
      : currentDirId && filteredFileIdsInDirectory && dispatch(allFilteredFilesSelectedToggledInDirectory(filteredFileIdsInDirectory));
  };

  return (
    <input
      className="hover:bh-border-deep-ocean bh-border-pigeon-60 h-5 w-5 rounded focus:ring-0 focus:ring-offset-0"
      type="checkbox"
      checked={allFilesSelectedInCurrentDirectory}
      onChange={handleChange}
    />
  );
};

export default FileContainerTableHeaderCheckbox;
