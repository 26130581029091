import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import BhModal from "@components/modal/BhModal";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhModalFooter from "@components/modal/BhModalFooter";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveNewCompanyChecklistBaseAsync } from "@/app/store/checklist/checklistsSlice";
import { ChecklistBaseCategory, IChecklistBase } from "@/model/checklist/IChecklistBase";
import { useNavigate } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { EntityId } from "@reduxjs/toolkit";
import ChecklistBaseCategoryTagInputDropdown from "@components/checklists/category/ChecklistBaseCategoryTagInputDropdown";
import BhInputLabel from "@components/input/BhInputLabel";
import { RootState } from "@/app/store";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { BauhubPlan } from "@/model/IProject";
import NewChecklistBaseModalLimitContentContainer from "@components/checklists/settings/companySettings/NewChecklistBaseModalLimitContentContainer";
import { fetchCountOfCompanyBuiltChecklistBases } from "@/api/checklist/checklistBaseAPI";

interface Props {
  setIsShown: Dispatch<SetStateAction<boolean>>;
  companyId: EntityId;
  projectId?: EntityId;
  defaultCategory?: ChecklistBaseCategory;
}

const NewChecklistBaseModal: FC<Props> = ({ setIsShown, companyId, projectId, defaultCategory }) => {
  const { t } = useTranslation();
  const [countOfCompanyBuiltChecklistBases, setCountOfCompanyBuiltChecklistBases] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const company = useAppSelector((state: RootState) => selectCompanyById(state, companyId));
  const navigate = useNavigate();
  let inputRef = useRef<any>(null);
  const [checklistBaseCategory, setChecklistBaseCategory] = useState<ChecklistBaseCategory>(defaultCategory || ChecklistBaseCategory.OTHER);

  useEffect(() => {
    company?.plan === BauhubPlan.LIGHT && fetchCountOfCompanyBuiltChecklistBases(company.id).then((res) => setCountOfCompanyBuiltChecklistBases(res));

    return function cleanup() {
      setCountOfCompanyBuiltChecklistBases(null);
    };
  }, []);

  if (!company) return null;

  const isProPlan = company?.plan === BauhubPlan.PRO;
  const canAddNewChecklistBases = isProPlan || (countOfCompanyBuiltChecklistBases && countOfCompanyBuiltChecklistBases < 2);

  const createNewChecklistBase = () => {
    const newChecklistBaseType = inputRef.current.value;
    if (!newChecklistBaseType) {
      return;
    }
    const newChecklistBase = { type: newChecklistBaseType, createdInProjectId: projectId, category: checklistBaseCategory } as IChecklistBase;
    dispatch(saveNewCompanyChecklistBaseAsync({ checklistBase: newChecklistBase, companyId: companyId })).then((action) => {
      const checklistBase = action.payload as IChecklistBase;
      const firstVersion = checklistBase.checklistBaseVersions[0];
      if (projectId) {
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklist/base/${checklistBase.id}/version/${firstVersion.id}`);
      } else {
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${companyId}/checklist/base/${checklistBase.id}/version/${firstVersion.id}`);
      }
    });
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={setIsShown}
      size={canAddNewChecklistBases ? undefined : "2xl"}
      hideSeparator={!canAddNewChecklistBases}
      header={<h2>{t(canAddNewChecklistBases ? "CHECKLIST.ADD_NEW_MODAL.HEADER" : "CHECKLIST_BASES.LIGHT_PLAN_LIMIT_MODAL.HEADER")}</h2>}
      children={
        canAddNewChecklistBases ? (
          <div className="flex w-full flex-col gap-y-3 px-8 py-4">
            <BhInputStackedLabel initialValue={""} property={"type"} label={t("CHECKLIST.ADD_NEW_MODAL.PLACEHOLDER")} inputRef={inputRef} />
            <div className="flex flex-col">
              <BhInputLabel>{t("CHECKLIST_BASE.CATEGORY")}</BhInputLabel>
              <ChecklistBaseCategoryTagInputDropdown
                currentCategory={checklistBaseCategory}
                saveCallback={(category: ChecklistBaseCategory) => setChecklistBaseCategory(category)}
                fixedPosition={true}
              />
            </div>
          </div>
        ) : (
          <NewChecklistBaseModalLimitContentContainer companyId={company.id} />
        )
      }
      footer={
        canAddNewChecklistBases && (
          <BhModalFooter confirmButtonText={t("GLOBAL.SAVE") as string} onCancel={() => setIsShown(false)} cancelButtonText={t("GLOBAL.CLOSE") as string} onConfirm={createNewChecklistBase} />
        )
      }
    />
  );
};

export default NewChecklistBaseModal;
