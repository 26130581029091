import React, { FC, useEffect } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { useAppDispatch, useAppSelector } from "@/app/hooks";

import BhTabs from "@components/tabs/BhTabs";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { Link, useNavigate } from "react-router-dom";
import ConfirmationContainerPendingContainer from "@/views/home/project/detail/confirmations/confirmation/ConfirmationContainerPendingContainer";
import BhIconButton from "@components/buttons/BhIconButton";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons/faEllipsisVertical";
import ConfirmationContainerRejectedContainer from "@/views/home/project/detail/confirmations/confirmation/ConfirmationContainerRejectedContainer";
import { deleteFileConfirmationAsync, fetchFileConfirmationWithFilesAsync, selectFileConfirmationById } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import ConfirmationContainerConfirmedContainer from "@/views/home/project/detail/confirmations/confirmation/ConfirmationContainerConfirmedContainer";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons/faTrashCan";
import ConfirmationFilesCount from "@/views/home/project/detail/confirmations/confirmation/confirmationFileContainer/count/ConfirmationFilesCount";
import { FileConfirmationDecisionType } from "@/model/confirmations/IFileConfirmationDecisionV2";
import ConfirmationContainerUsers from "@/views/home/project/detail/confirmations/confirmation/ConfirmationContainerUsers";
import { addFilesToConfirmationAsync, openCommentsForFileConfirmationFileIdSet, selectFileConfirmationFilesSort } from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import ConfirmationContainerFileComments from "@/views/home/project/detail/confirmations/confirmation/ConfirmationContainerFileComments";
import { formatDateTime } from "@/utilities/dateUtility";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { Trans, useTranslation } from "react-i18next";
import BhHeadingBreadcrumbText from "@components/breadcrumb/BhHeadingBreadcrumbText";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { fetchUrlForConfirmationPdf } from "@/api/fileConfirmationAPI";
import { FileEntityType } from "@/model/files/IFileEntity";
import BhAddFilesContainer from "@components/upload/BhAddFilesContainer";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { selectCurrentUser, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { naturalSortFilesByField } from "@/utilities/sortUtilities";
import { IFileConfirmationFile } from "@/model/confirmations/IFileConfirmationFile";

interface Props {
  confirmationId: EntityId;
}

const ConfirmationContainer: FC<Props> = ({ confirmationId }) => {
  const { t } = useTranslation();
  const fileConfirmation = useAppSelector((state) => selectFileConfirmationById(state, confirmationId));
  const projectId = useAppSelector(selectCurrentProjectId);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const currentUser = useAppSelector(selectCurrentUser);
  const fileConfirmationFilesSort = useAppSelector(selectFileConfirmationFilesSort);
  const canEditConfirmation = isProjectAdmin || fileConfirmation?.starterUserEntityId === currentUser.id;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useDocumentTitle(fileConfirmation?.description);

  useEffect(() => {
    dispatch(setSidebarCollapsed(false));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.CONFIRMATIONS));
    dispatch(fetchFileConfirmationWithFilesAsync(confirmationId));

    return function cleanup() {
      dispatch(openCommentsForFileConfirmationFileIdSet(undefined));
    };
  }, []);

  const sortFilesInParentFolders = (uniqueParentFileEntityIdsAndNames: Array<{ id: EntityId; name: string }>, fileConfirmationFiles: Array<IFileConfirmationFile>) => {
    return uniqueParentFileEntityIdsAndNames
      .map((parentFileEntity) =>
        fileConfirmationFiles
          .filter((file) => file.parentFileEntityId === parentFileEntity.id)
          .sort((a, b) => naturalSortFilesByField(a, b, fileConfirmationFilesSort.property, fileConfirmationFilesSort.reversed))
      )
      .flat();
  };

  const tabs = [
    {
      id: 0,
      header: (
        <div className="flex flex-row items-center">
          <span className="pr-1.5">
            <Trans>CONFIRMATIONS.PENDING</Trans>
          </span>
          <ConfirmationFilesCount confirmationId={confirmationId} type={FileConfirmationDecisionType.PENDING} />
        </div>
      ),
      content: <ConfirmationContainerPendingContainer confirmationId={confirmationId} canEditConfirmation={canEditConfirmation} sortFilesInParentFolders={sortFilesInParentFolders} />
    },
    {
      id: 1,
      header: (
        <div className="flex flex-row items-center">
          <span className="pr-1.5">
            <Trans>CONFIRMATION.CONFIRMED</Trans>
          </span>
          <ConfirmationFilesCount confirmationId={confirmationId} type={FileConfirmationDecisionType.CONFIRMED} />
        </div>
      ),
      content: <ConfirmationContainerConfirmedContainer confirmationId={confirmationId} canEditConfirmation={canEditConfirmation} sortFilesInParentFolders={sortFilesInParentFolders} />
    },
    {
      id: 2,
      header: (
        <div className="flex flex-row items-center">
          <span className="pr-1.5">
            <Trans>CONFIRMATIONS.DECLINED</Trans>
          </span>
          <ConfirmationFilesCount confirmationId={confirmationId} type={FileConfirmationDecisionType.DECLINED} />
        </div>
      ),
      content: <ConfirmationContainerRejectedContainer confirmationId={confirmationId} canEditConfirmation={canEditConfirmation} sortFilesInParentFolders={sortFilesInParentFolders} />
    },
    {
      id: 3,
      header: <Trans>CONFIRMATION.CONFIRMERS</Trans>,
      content: <ConfirmationContainerUsers confirmationId={confirmationId} />
    }
  ];

  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteConfirmationModal}
      header={<h2>{t("CONFIRMATION.DELETE")}</h2>}
      body={<div>{t("CONFIRMATIONS.DELETE_MODAL.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={() => {
        fileConfirmation &&
          dispatch(deleteFileConfirmationAsync(fileConfirmation.id)).then(() => {
            navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/confirmations/personal/`);
          });
        hideDeleteConfirmationModal();
      }}
    />
  ));

  if (!fileConfirmation) {
    return null;
  }

  const starterName = fileConfirmation.starter?.firstName + " " + fileConfirmation.starter?.lastName;

  return (
    <div className="flex w-full flex-row overflow-hidden">
      <div className="flex flex-1 flex-col overflow-hidden p-6">
        <div className="flex flex-row items-start">
          <div className="flex-1">
            <div className="flex flex-row flex-wrap items-center gap-x-2 pb-1 pt-1">
              <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/confirmations/personal`}>
                <BhHeadingBreadcrumbText>
                  <Trans>GLOBAL.CONFIRMATIONS</Trans>
                </BhHeadingBreadcrumbText>
              </Link>
              <FontAwesomeIcon icon={faChevronRight} />
              <div className="bh-text-deep-ocean-80 text-20px pl-2">{"#" + fileConfirmation.number}</div>
              <h2 className="bh-text-deep-ocean m-0">{fileConfirmation.description}</h2>
              {canEditConfirmation && (
                <BhDropdown
                  button={<BhIconButton icon={faEllipsisVertical} />}
                  menu={
                    <BhDropdownMenu
                      values={[
                        {
                          text: t("GLOBAL.DELETE"),
                          function: () => {
                            showDeleteConfirmationModal();
                          },
                          icon: faTrashCan
                        }
                      ]}
                      type={BhDropdownTypeEnum.STRING}
                      textProperty="text"
                      widthClass={"w-40"}
                    />
                  }
                  position={BhDropdownPositionEnum.BOTTOM_LEFT}
                />
              )}
            </div>
            <div className="mb-2 flex flex-row">
              <div className="bh-text-deep-ocean-80">
                <span>
                  <Trans>FILE_CONFIRMATION.CREATED</Trans>: {formatDateTime(fileConfirmation.created)} {fileConfirmation.starter && t("CONFIRMATION.CREATED_BY", { starterName: starterName })}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-row flex-wrap">
            <BhTextOnlyButton
              icon={faArrowDownToLine}
              buttonProps={{
                onClick: () =>
                  fetchUrlForConfirmationPdf(confirmationId).then((presignedUrl) => {
                    imitateUrlDownload(presignedUrl.value);
                  })
              }}
            >
              <Trans>GLOBAL.PDF</Trans>
            </BhTextOnlyButton>
            {canEditConfirmation && (
              <BhAddFilesContainer
                button={
                  <BhSecondaryButton icon={faPlus}>
                    <Trans>GLOBAL.ADD_FILES</Trans>
                  </BhSecondaryButton>
                }
                projectFilesSelectedCallback={(selectedFiles) => {
                  dispatch(addFilesToConfirmationAsync({ fileConfirmationId: fileConfirmation.id, fileIds: selectedFiles.map((fe) => fe.id) }));
                }}
                excludeFileTypes={[FileEntityType.FORM, FileEntityType.CONTAINER]}
              />
            )}
          </div>
        </div>
        <BhTabs tabs={tabs} />
      </div>
      <ConfirmationContainerFileComments fileConfirmationId={fileConfirmation.id} />
    </div>
  );
};

export default ConfirmationContainer;
