import React, { FC, useRef } from "react";
import { IUserSigningInfo } from "@/model/container/IContainerCreateRequest";
import BhDeclineIconButton from "@components/buttons/BhDeclineIconButton";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import { IUser } from "@/model/IUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons/faGripDotsVertical";
import { useDrag, useDrop } from "react-dnd";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  user: IUser;
  index: number;
  removeUser: Function;
  insertUserAfter: Function;
}

const NewConfirmationModalListRow: FC<Props> = ({ user, index, removeUser, insertUserAfter }) => {
  // DRAG AND DROP

  const [{ opacity, isDragging }, dragRef, preview] = useDrag({
    type: "signerRow",
    item: { user: user, oldIndex: index },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: monitor.isDragging()
    })
  });

  const [{ isOver }, dropRef] = useDrop({
    accept: "signerRow",
    drop: (item: { user: IUserSigningInfo; oldIndex: number }) => {
      if (!isDragging) {
        const newIndex = index;
        insertUserAfter(item.user, newIndex);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  const ref = useRef(null);
  const previewDropRef = dragRef(dropRef(ref));

  return (
    <tr ref={previewDropRef as any} className={classNames(isOver && "bh-bg-smoke", "bh-table-row h-13 bh-hover-container")} style={{ opacity }}>
      <>
        <td ref={dragRef}>
          <FontAwesomeIcon icon={faGripDotsVertical} className="cursor-move" />
        </td>
        <td className="text-center">{index + 1}</td>
      </>
      <td>{<BhUserIconWithName user={user as object as IUser} />}</td>
      <td className="text-right">{<BhDeclineIconButton icon={faCircleMinus} buttonProps={{ onClick: () => removeUser(user) }} />}</td>
    </tr>
  );
};

export default NewConfirmationModalListRow;
