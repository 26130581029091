import React, { FC, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BhIconButton from "@components/buttons/BhIconButton";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { MetaObject, Viewer } from "@xeokit/xeokit-sdk";
import { useTranslation } from "react-i18next";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import XeokitSidebarMenuObjectProperties from "@/views/home/project/detail/xeokit/xeokitSidebar/XeokitSidebarMenuObjectProperties";
import BhModalContainer from "@components/modal/BhModalContainer";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUserLanguageForTranslation } from "@/app/store/userSlice";

interface Props {
  viewer: Viewer;
  setPropertiesModalVisible: Function;
}

const XeokitMobilePropertiesModal: FC<Props> = ({ viewer, setPropertiesModalVisible }) => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguageForTranslation);
  const [objectProperties, setObjectProperties] = useState<MetaObject | undefined>(undefined);

  useEffect(() => {
    if (!viewer) return;
    const selectedObjectId = viewer.scene.selectedObjectIds.length === 1 && viewer.scene.selectedObjectIds[0];
    if (selectedObjectId) {
      const metaObject = viewer.metaScene.metaObjects[selectedObjectId];
      setObjectProperties(metaObject);
    }
  }, []);

  return (
    <div className="absolute flex h-full w-full overflow-hidden">
      <BhModalContainer>
        <div className="flex flex-1 flex-col overflow-hidden">
          <div className="flex flex-row items-center justify-between p-1">
            <div className="flex flex-row items-center p-2 font-bold">
              <div className="pr-2">
                <FontAwesomeIcon icon={faInfoCircle} />
              </div>
              <div>{t("BIM.PROPERTIES", { lng: currentUserLanguage })}</div>
            </div>
            <BhIconButton
              icon={faTimes}
              buttonProps={{
                onClick: () => {
                  setPropertiesModalVisible(false);
                  setObjectProperties(undefined);
                }
              }}
            />
          </div>
          <div className="overflow-auto p-3">{objectProperties && <XeokitSidebarMenuObjectProperties objectProperties={objectProperties} viewer={viewer} />}</div>
        </div>
      </BhModalContainer>
    </div>
  );
};

export default XeokitMobilePropertiesModal;
