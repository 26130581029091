import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import PartyPrivilegesContainer from "@/views/home/project/detail/users/parties/party/PartyPrivilegesContainer";
import PartyTaskboardsContainer from "@/views/home/project/detail/users/parties/party/PartyTaskboardsContainer";
import PartyFilesContainer from "@/views/home/project/detail/users/parties/party/PartyFilesContainer";
import { fetchWorkGroupAsync, selectProjectWorkGroupById } from "@/app/store/project/projectWorkGroupsSlice";
import BhTag from "@components/tags/BhTag";
import BhTabs from "@components/tabs/BhTabs";
import { FileEntityBranch } from "@/model/files/IFileEntity";
import PartyOverviewContainer from "@/views/home/project/detail/users/parties/party/PartyOverviewContainer";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { EntityId } from "@reduxjs/toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { faUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { faGear } from "@fortawesome/pro-regular-svg-icons/faGear";
import { faSquareKanban } from "@fortawesome/pro-regular-svg-icons/faSquareKanban";
import { faFiles } from "@fortawesome/pro-regular-svg-icons/faFiles";
import { faPenRuler } from "@fortawesome/pro-regular-svg-icons/faPenRuler";
import { Link } from "react-router-dom";
import { tagTypeMap } from "@/model/IWorkGroup";
import PartyDocumentsTextContainer from "@/views/home/project/detail/users/parties/party/PartyDocumentsTextContainer";
import PartyFilesTextContainer from "@/views/home/project/detail/users/parties/party/PartyFilesTextContainer";
import { formatDateTime } from "@/utilities/dateUtility";
import { useTranslation } from "react-i18next";
import { setCurrentSidebarMenuItem } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import BhHeadingBreadcrumbText from "@components/breadcrumb/BhHeadingBreadcrumbText";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  partyId: EntityId;
}

const PartyContainer: FC<Props> = ({ partyId }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const workGroup = useAppSelector((state) => selectProjectWorkGroupById(state, partyId));

  useDocumentTitle(workGroup?.name);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.USERS));
    dispatch(fetchWorkGroupAsync({ projectId, partyId }));
  }, []);

  if (!workGroup) {
    return null;
  }

  const tabs = [
    {
      id: 0,
      header: (
        <>
          <FontAwesomeIcon icon={faUsers} className="mr-1" />
          {t("WORKGROUP.TAB.USERS")}
        </>
      ),
      content: <PartyPrivilegesContainer workGroup={workGroup} />,
      href: `privileges`
    },
    {
      id: 1,
      header: (
        <>
          <FontAwesomeIcon icon={faPenRuler} className="mr-1" />
          {t("GLOBAL.DRAWINGS")}
        </>
      ),
      content: (
        <PartyFilesTextContainer>
          <PartyFilesContainer workGroup={workGroup} branch={FileEntityBranch.ROOT_DIR} newWorkGroup={false} />
        </PartyFilesTextContainer>
      ),
      href: `drawings`
    },
    {
      id: 2,
      header: (
        <>
          <FontAwesomeIcon icon={faFiles} className="mr-1" />
          {t("GLOBAL.DOCUMENTS")}
        </>
      ),
      content: (
        <PartyDocumentsTextContainer>
          <PartyFilesContainer workGroup={workGroup} branch={FileEntityBranch.ROOT_DOCUMENT_DIR} newWorkGroup={false} />
        </PartyDocumentsTextContainer>
      ),
      href: `documents`
    },
    {
      id: 3,
      header: (
        <>
          <FontAwesomeIcon icon={faSquareKanban} className="mr-1" />
          {t("GLOBAL.TASKBOARDS")}
        </>
      ),
      content: <PartyTaskboardsContainer workGroup={workGroup} />,
      href: `taskboards`
    },
    {
      id: 4,
      header: (
        <>
          <FontAwesomeIcon icon={faGear} className="mr-1" />
          {t("WORKGROUP.TAB.GENERAL")}
        </>
      ),
      content: <PartyOverviewContainer workGroup={workGroup} />,
      href: `general`
    }
  ];

  return (
    <div className="flex w-full flex-col p-6">
      <div className="flex flex-row items-baseline space-x-2 py-1">
        <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/users/parties`}>
          <BhHeadingBreadcrumbText>{t("GLOBAL.WORKGROUPS")}</BhHeadingBreadcrumbText>
        </Link>
        <FontAwesomeIcon icon={faChevronRight} />
        <h2 className="bh-text-deep-ocean m-0">{workGroup.name}</h2>
      </div>
      <div className="mb-2 flex flex-row items-center">
        {workGroup.type && <BhTag type={tagTypeMap[workGroup.type]} children={t("PARTY." + workGroup.type)} />}
        <div className="bh-text-deep-ocean-80 ml-2">{workGroup && t("GLOBAL.CREATED") + ": " + formatDateTime(workGroup.created)}</div>
      </div>
      <BhTabs tabs={tabs} router={true} />
    </div>
  );
};

export default PartyContainer;
