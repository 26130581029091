import React, { FC, SetStateAction } from "react";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhModal from "@components/modal/BhModal";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveFileTreeTemplateAsync, saveNewFileTreeTemplateAsync, selectCompanyById } from "@/app/store/companiesSlice";
import { EntityId } from "@reduxjs/toolkit";
import { FileTreeType, IFileTree, IFileTreeTemplate } from "@/model/files/IFileTreeTemplate";
import ProjectTreeSettingsModalSidebar from "@/views/home/company/detail/companySettingsProjectTree/ProjectTreeSettingsModalSidebar";
import { useTranslation } from "react-i18next";
import ProjectSettingsModalTreeRowNew from "@/views/home/company/detail/companySettingsProjectTree/ProjectSettingsModalTreeRowNew";
import { v4 as uuidv4 } from "uuid";

interface Props {
  isShown: boolean;
  setIsShown: React.Dispatch<SetStateAction<boolean>>;
  editableTemplate: IFileTreeTemplate;
  setEditableTemplate: React.Dispatch<SetStateAction<IFileTreeTemplate>>;
  fileTreeType: FileTreeType;
  companyId: EntityId;
  resetEditableTemplate: Function;
}

const ProjectTreeSettingsModal: FC<Props> = ({ isShown, setIsShown, editableTemplate, setEditableTemplate, fileTreeType, companyId, resetEditableTemplate }) => {
  const { t } = useTranslation();
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    if (fileTreeType !== FileTreeType.DIR) {
      const newTree = findAndDeleteNoNameFolders(editableTemplate.tree);
      const editableTreeWithNoNoNameFolders = { ...editableTemplate, tree: newTree };
      setEditableTemplate(editableTreeWithNoNoNameFolders);
      const alreadyExists = company?.fileTreeTemplates[fileTreeType]?.some((tree: IFileTreeTemplate) => tree.id === editableTemplate.id);
      dispatch(alreadyExists ? saveFileTreeTemplateAsync(editableTreeWithNoNoNameFolders) : saveNewFileTreeTemplateAsync(editableTreeWithNoNoNameFolders)).then(() => {
        resetEditableTemplate();
      });
      setIsShown(false);
    }
  };

  const findNodeAndAddChildItem: any = (idToAddTo: EntityId, tree: IFileTree, insertAfterId?: EntityId) => {
    if (tree.id === idToAddTo) {
      const newItem = { name: "", id: uuidv4(), type: FileTreeType.DIR, children: [] };
      const insertAfterIndex = tree.children.findIndex((element) => element.id === insertAfterId);
      const indexOrLast = insertAfterIndex > -1 ? insertAfterIndex + 1 : tree.children.length;
      const array = [...tree.children];
      array.splice(indexOrLast, 0, newItem);
      return { ...tree, children: array };
    }
    return { ...tree, children: tree.children.map((c) => findNodeAndAddChildItem(idToAddTo, c, insertAfterId)) };
  };

  const addChildToIdCallback = (idToAddTo: EntityId, insertAfterId?: EntityId) => {
    const newTree = findNodeAndAddChildItem(idToAddTo, editableTemplate.tree, insertAfterId);
    setEditableTemplate({ ...editableTemplate, tree: newTree });
  };

  const findNodeAndRemoveItem: any = (idToRemove: EntityId, tree: IFileTree) => {
    if (tree.children.map((c) => c.id).includes(idToRemove)) {
      return { ...tree, children: tree.children.filter((c) => c.id !== idToRemove) };
    }
    return { ...tree, children: tree.children.map((c) => findNodeAndRemoveItem(idToRemove, c)) };
  };

  const deleteIdCallback = (idToRemove: EntityId) => {
    const newTree = findNodeAndRemoveItem(idToRemove, editableTemplate.tree);
    setEditableTemplate({ ...editableTemplate, tree: newTree });
  };

  const findNodeAndChangeName: any = (item: IFileTree, tree: IFileTree, parent: IFileTree) => {
    if (item.id === tree.id) {
      const name = handleDuplicateName(item.name, parent.children);
      return { ...tree, name };
    }
    return { ...tree, children: tree.children.map((c) => findNodeAndChangeName(item, c, tree)) };
  };

  const changeNameCallback = (item: IFileTree) => {
    if (item.name && item.name.length > 0) {
      const newTree = findNodeAndChangeName(item, editableTemplate.tree);
      setEditableTemplate({ ...editableTemplate, tree: newTree });
    } else {
      deleteIdCallback(item.id);
    }
  };

  const solveDuplicateName: any = (name: string, iteration: number, dirNames: Array<string>) => {
    const tmpName = name.concat(" (" + iteration + ")");
    if (dirNames.indexOf(tmpName) > -1) {
      return solveDuplicateName(name, iteration + 1, dirNames);
    }
    return tmpName;
  };

  const findAndDeleteNoNameFolders: any = (tree: IFileTree) => {
    if (tree.children && tree.children.length > 0) {
      return { ...tree, children: tree.children.filter((c) => c.name && c.name.length > 0).map((c) => findAndDeleteNoNameFolders(c)) };
    }
    return { ...tree, children: tree.children.map((c) => findAndDeleteNoNameFolders(c)) };
  };

  const handleDuplicateName = (name: string, sameParentFolders: Array<IFileTree>) => {
    const dirNames = sameParentFolders.map((child) => child.name);
    return dirNames.includes(name) ? solveDuplicateName(name, 1, dirNames) : name;
  };

  const handleCancel = () => {
    resetEditableTemplate();
    setIsShown(false);
  };

  return (
    <BhModal
      isShown={isShown}
      setIsShown={setIsShown}
      onClose={handleCancel}
      size="6xl"
      header={<h3>{t("COMPANY.SETTINGS.PROJECT.TREE." + fileTreeType + "_MODAL.HEADER")}</h3>}
      footer={
        <BhModalFooter
          cancelButtonText={t("MODAL.DEFAULT.CONFIRMATION.CANCEL") as string}
          confirmButtonText={t("MODAL.DEFAULT.CONFIRMATION.OK") as string}
          onCancel={handleCancel}
          onConfirm={handleSubmit}
          confirmDisabled={!editableTemplate.name || editableTemplate.name.length === 0}
        />
      }
    >
      <div className="flex h-full w-full overflow-hidden">
        <ProjectTreeSettingsModalSidebar fileTreeTemplate={editableTemplate} setFileTreeTemplate={setEditableTemplate} companyId={companyId} />
        <div className="bh-bg-pigeon-20 w-2/3 p-4">
          <div className="bh-bg-white h-full w-full overflow-y-scroll pt-4 pb-20">
            <ProjectSettingsModalTreeRowNew
              level={0}
              rowItem={editableTemplate.tree}
              parentId={undefined}
              changeNameCallback={changeNameCallback}
              deleteIdCallback={deleteIdCallback}
              addChildToIdCallback={addChildToIdCallback}
            />
          </div>
        </div>
      </div>
    </BhModal>
  );
};

export default ProjectTreeSettingsModal;
