import { Route, Routes, useParams } from "react-router-dom";
import React, { FC, useEffect } from "react";
import ActsListContainer from "@/views/home/project/detail/actsAndContracts/acts/ActsListContainer";
import NotFound from "@/views/global/NotFound";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import ContractContainerAngular from "@/views/home/project/detail/actsAndContracts/contractAngular/ContractContainerAngular";
import ActContainerAngular from "@/views/home/project/detail/actsAndContracts/actAngular/ActContainerAngular";
import { selectCurrentProjectId, selectMaruProjectModule } from "@/app/store/project/projectSlice";
import MrActsListContainer from "@/views/home/project/detail/actsAndContracts/acts/MrActsListContainer";
import ContractFormContainer from "@/views/home/project/detail/actsAndContracts/contract/contractForm/ContractFormContainer";
import NewContractFormContainerForOldFE from "@/views/home/project/detail/actsAndContracts/contract/contractForm/NewContractFormContainerForOldFE";
import BhIFrameComponent from "@/views/global/BhIFrameComponent";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  partyId: EntityId;
}

const ContractRouter: FC<Props> = ({ partyId }) => {
  const dispatch = useAppDispatch();
  const isMaruProject = useAppSelector(selectMaruProjectModule);
  const projectId = useAppSelector(selectCurrentProjectId);

  useEffect(() => {
    dispatch(setSidebarCollapsed(false));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.CONTRACTS));

    return function cleanup() {};
  }, []);

  function ActContainerWithParams() {
    const { actId } = useParams();
    if (partyId && actId) {
      const actIdInt = parseInt(actId);
      return <ActContainerAngular actId={actIdInt} partyId={partyId} />;
      // return <ActContainer actId={actIdInt} partyId={partyId} />;
    }
    return <NotFound />;
  }

  function ActAccountantContainerWithParams() {
    const { actId } = useParams();
    if (partyId && actId) {
      const actIdInt = parseInt(actId);
      return <BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/parties/${partyId}/act/${actIdInt}/react/accountant/react`} />;
    }
    return <NotFound />;
  }

  function ContractFormContainerWithParams() {
    const { fileEntityId } = useParams();
    if (fileEntityId) {
      const fileEntityIdInt = parseInt(fileEntityId);
      return <ContractFormContainer fileEntityId={fileEntityIdInt} partyId={partyId} />;
    }
    return <NotFound />;
  }

  function ContractTemplateContainerWithParams() {
    const { templateId } = useParams();
    if (templateId) {
      const templateIdInt = parseInt(templateId);
      return <BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/template/${templateIdInt}/edit/react`} />;
    }
    return <NotFound />;
  }

  function ActsListContainerForProject() {
    if (isMaruProject) {
      return <MrActsListContainer partyId={partyId} />;
    }
    return <ActsListContainer partyId={partyId} />;
  }

  return (
    <Routes>
      <Route path="*" element={<ContractContainerAngular partyId={partyId} />} />
      <Route path="acts" element={<ActsListContainerForProject />} />
      <Route path="act/:actId/*" element={<ActContainerWithParams />} />
      <Route path="act/:actId/accountant" element={<ActAccountantContainerWithParams />} />
      <Route path="form/:fileEntityId" element={<ContractFormContainerWithParams />} />
      <Route path="form/new" element={<NewContractFormContainerForOldFE partyId={partyId} />} />
      <Route path="template/:templateId/edit" element={<ContractTemplateContainerWithParams />} />
    </Routes>
  );
};

export default ContractRouter;
