import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from "@fortawesome/pro-solid-svg-icons/faSquareCheck";
import { faSquare } from "@fortawesome/pro-regular-svg-icons/faSquare";
import { LoadedObjectInfo } from "@/views/home/project/detail/xeokit/XeokitWebViewer";
import { Viewer } from "@xeokit/xeokit-sdk";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUserLanguageForTranslation } from "@/app/store/userSlice";

interface Props {
  viewer: Viewer;
  loadedStoreyElevations: Array<LoadedObjectInfo>;
  setLoadedStoreyElevations: Function;
}

const XeokitSidebarMenuStoreysTreeView: FC<Props> = ({ viewer, loadedStoreyElevations, setLoadedStoreyElevations }) => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguageForTranslation);
  const allVisible = loadedStoreyElevations.every((elevation) => elevation.visible);

  const toggleStoreyElevationVisibleInViewer = (storeyElevationToToggle: LoadedObjectInfo, visible: boolean) => {
    if (!viewer) return;

    // Show / hide storeys with specific elevation
    const storeyIds = viewer?.metaScene.getObjectIDsByType("IfcBuildingStorey");
    storeyIds &&
      storeyIds.forEach((storeyId) => {
        const storeyObject = viewer?.metaScene.metaObjects[storeyId];
        // @ts-ignore
        const storeyElevation = parseFloat(storeyObject.attributes["elevation"]);
        if (storeyElevation === storeyElevationToToggle.elevation) {
          const childObjects = storeyObject?.getObjectIDsInSubtree();
          childObjects?.forEach((childObjectId) => {
            if (viewer.scene.objects[childObjectId]) {
              viewer.scene.objects[childObjectId].visible = visible;
            }
          });
        }
      });
  };

  const toggleStoreyElevationVisible = (storeyElevationToToggle: LoadedObjectInfo) => {
    toggleStoreyElevationVisibleInViewer(storeyElevationToToggle, !storeyElevationToToggle.visible);
    setLoadedStoreyElevations(
      loadedStoreyElevations.map((loadedStoreyElevation) => ({
        ...loadedStoreyElevation,
        visible: storeyElevationToToggle === loadedStoreyElevation ? !loadedStoreyElevation.visible : loadedStoreyElevation.visible
      }))
    );
  };

  const toggleAllStoreyElevationsVisibleInTree = (visible: boolean) => {
    setLoadedStoreyElevations(
      loadedStoreyElevations.map((loadedStoreyElevation) => ({
        ...loadedStoreyElevation,
        visible: visible
      }))
    );
  };

  viewer.on("reset", () => {
    toggleAllStoreyElevationsVisibleInTree(true);
  });

  const toggleAll = () => {
    loadedStoreyElevations.forEach((loadedStoreyElevation) => {
      toggleStoreyElevationVisibleInViewer(loadedStoreyElevation, !allVisible);
    });
    toggleAllStoreyElevationsVisibleInTree(!allVisible);
  };

  return (
    <div>
      <div className="flex flex-row gap-x-1 py-[3px] px-2 md:gap-x-2 md:py-2 md:text-[1.2rem]" onClick={toggleAll}>
        <FontAwesomeIcon className="bh-text-bauhub-green-120 py-0.25 relative top-0 cursor-pointer text-lg md:-top-1 md:h-6 md:text-2xl" icon={allVisible ? faSquareCheck : faSquare} />
        <div className="flex-1 cursor-pointer">{t("BIM.SIDEBAR.SELECT_ALL", { lng: currentUserLanguage })}</div>
      </div>
      {loadedStoreyElevations.map((storeyElevation) => {
        return (
          <div key={storeyElevation.id} className="flex flex-row gap-x-1 py-[3px] px-2 md:gap-x-2 md:py-2 md:text-[1.2rem]" onClick={() => toggleStoreyElevationVisible(storeyElevation)}>
            <FontAwesomeIcon
              className="bh-text-bauhub-green-120 py-0.25 relative top-0 cursor-pointer text-lg md:-top-1 md:h-6 md:text-2xl"
              icon={storeyElevation.visible ? faSquareCheck : faSquare}
            />
            <div className="flex-1 cursor-pointer">{storeyElevation.name}</div>
          </div>
        );
      })}
    </div>
  );
};

export default XeokitSidebarMenuStoreysTreeView;
