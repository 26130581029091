import React, { FC, useEffect } from "react";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import BhTableHeader from "@components/table/BhTableHeader";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  fetchCurrentProjectAdministratorInvitesAsync,
  fetchProjectAdministratorsWithUserInfoAsync,
  selectCurrentProjectAdministratorInvites,
  selectCurrentProjectId,
  selectProjectAdministratorsSorted
} from "@/app/store/project/projectSlice";
import ProjectSettingsAdminInput from "@components/projectSettings/ProjectSettingsAdminInput";
import ProjectAdministratorsTableRow from "@components/projectSettings/ProjectAdministratorsTableRow";
import ProjectAdministratorInvitesTableRow from "@components/projectSettings/ProjectAdministratorInvitesTableRow";
import { useTranslation } from "react-i18next";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";

const ProjectAdministratorsContainerBauhub: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const projectAdministrators = useAppSelector((state) => selectProjectAdministratorsSorted(state, "firstName"));
  const projectAdministratorInvites = useAppSelector(selectCurrentProjectAdministratorInvites);

  useEffect(() => {
    dispatch(fetchProjectAdministratorsWithUserInfoAsync(projectId));
    dispatch(fetchCurrentProjectAdministratorInvitesAsync(projectId));
  }, []);

  const tableColumnHeaders: Array<IBhTableHeader<ISimpleValidUserAuthority>> = [
    { id: 1, content: t("PROJECT.TABLE.HEADER.NAME"), classes: "", field: "firstName" },
    { id: 2, content: t("PROJECT.TABLE.HEADER.EMAIL"), classes: "" },
    { id: 3, content: t("PROJECT.TABLE.HEADER.TITLE"), classes: "" },
    { id: 4, content: t("PROJECT.TABLE.HEADER.COMPANY"), classes: "" },
    { id: 5, content: t("PROJECT.TABLE.HEADER.PHONE"), classes: "" },
    { id: 6, content: "", classes: "w-12" }
  ];

  return (
    <div>
      <ProjectSettingsAdminInput />
      <div className="mt-4">
        <table className="w-full">
          <BhTableHeader columns={tableColumnHeaders} />
          <tbody>
            {projectAdministrators &&
              projectAdministrators.map((admin, index) => {
                return <ProjectAdministratorsTableRow admin={admin} index={index} key={admin.userEntityId} />;
              })}
            {projectAdministratorInvites &&
              projectAdministratorInvites.map((invite) => {
                return <ProjectAdministratorInvitesTableRow invite={invite} key={invite.id} />;
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ProjectAdministratorsContainerBauhub;
