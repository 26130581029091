import React, { FC, useEffect, useRef, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import useHandleFormSubmit from "@/utilities/hooks/useHandleFormSubmit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { resetFileDirectoryModalState, saveFileNameAsync, selectRenameModalFileEntity } from "@/app/store/filesSlice";
import { setSelectionRange } from "@/utilities/jsUtilities";
import BhModalFooter from "@components/modal/BhModalFooter";
import { useTranslation } from "react-i18next";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";

const RenameModal: FC = () => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const handleFormSubmit = useHandleFormSubmit<IFileEntity>();
  const fileEntity = useAppSelector(selectRenameModalFileEntity);
  const dispatch = useAppDispatch();
  const [confirmDisabled, setConfirmDisabled] = useState<boolean>(false);
  const [fileExtensionRemoved, setFileExtensionRemoved] = useState<boolean>(false);
  const prevNameLastDotIndex = fileEntity && fileEntity.name.lastIndexOf(".");
  const prevNameExtension = prevNameLastDotIndex !== -1 ? fileEntity && prevNameLastDotIndex && fileEntity.name.substring(prevNameLastDotIndex + 1) : "";

  const noFileExtension = fileEntity && [FileEntityType.FORM, FileEntityType.DIR].includes(fileEntity.type);

  useEffect(() => {
    if (!noFileExtension && fileEntity && fileEntity.name) {
      setSelectionRange(inputRef, 0, fileEntity.name.lastIndexOf("."));
    }
  }, []);

  const handleSubmit = (formValues: IFileEntity) => {
    if (!fileEntity || fileEntity.name.length < 1 || fileEntity.name.trim().length < 1) return;
    if (isFileNameTooLong(formValues.name)) return;

    const savableFileEntityObject = { ...fileEntity, ...formValues };

    if (!!savableFileEntityObject) {
      setConfirmDisabled(true);
      dispatch(saveFileNameAsync(savableFileEntityObject)).then((result) => {
        dispatch(resetFileDirectoryModalState());
        setConfirmDisabled(false);
      });
    }
  };

  const checkIsFileExtensionRemoved = (fileName: string) => {
    if (!noFileExtension && prevNameExtension) {
      const newNameLastDotIndex = fileName.lastIndexOf(".");
      const newNameExtension = newNameLastDotIndex !== -1 ? fileName.substring(newNameLastDotIndex + 1) : "";

      setFileExtensionRemoved(prevNameExtension.toLowerCase() !== newNameExtension.toLowerCase());
    }
  };

  const isFileNameTooLong = (fileName: string) => {
    if (fileName && fileName.length > 254) {
      var extensionLength = fileName.lastIndexOf(".") !== -1 ? fileName.substring(fileName.lastIndexOf(".")).length : 0;
      var fileNameLength = fileName.length - extensionLength;
      var charsOverLimitStartIndex = fileNameLength - (fileNameLength - (254 - extensionLength));
      setSelectionRange(inputRef, charsOverLimitStartIndex, fileNameLength);
      if (inputRef.current) {
        inputRef.current.scrollLeft = inputRef.current.scrollWidth;
      }
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          children: t("ERROR.FILE_NAME_TOO_LONG")
        })
      );
      return true;
    }
    return false;
  };

  const closeModal = () => {
    dispatch(resetFileDirectoryModalState());
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={closeModal}
      header={<h2>{t("GLOBAL.RENAME")}</h2>}
      children={
        fileEntity && (
          <div className="w-full p-4">
            <form id="renameFileEntityForm" className="flex flex-col" onSubmit={handleFormSubmit((fileEntity) => handleSubmit(fileEntity))}>
              <BhInputStackedLabel
                initialValue={fileEntity.name}
                property={"name"}
                label={t("FILE.NAME")}
                inputRef={inputRef}
                validationHelperClasses={"bh-text-error-red"}
                validationHelper={!noFileExtension && fileExtensionRemoved ? (t("FILE.RENAME.WRONG_EXTENSION", { fileExtension: (prevNameExtension || "").toLowerCase() }) as string) : undefined}
                onChangeCallback={(input: IFileEntity) => {
                  setConfirmDisabled(!input.name.length);
                  checkIsFileExtensionRemoved(input.name);
                }}
                onChangeCallbackOnEveryValue={true}
              />
            </form>
          </div>
        )
      }
      footer={
        <BhModalFooter
          confirmDisabled={confirmDisabled}
          submitForm="renameFileEntityForm"
          confirmButtonText={t("GLOBAL.SAVE") as string}
          onCancel={closeModal}
          cancelButtonText={t("GLOBAL.CLOSE") as string}
        />
      }
    />
  );
};

export default RenameModal;
