import React, { FC, useEffect, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import ConfirmationFileContainer from "@/views/home/project/detail/confirmations/confirmation/confirmationFileContainer/ConfirmationFileContainer";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { selectFileConfirmationById } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import {
  selectFileConfirmationFileParentEntityNameAndIdsSorted,
  selectFileConfirmationFilesSort,
  selectPendingFileConfirmationFilesForConfirmation
} from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import { selectCurrentUser } from "@/app/store/userSlice";
import ConfirmationFolderPath from "@/views/home/project/detail/confirmations/confirmation/confirmationFileContainer/ConfirmationFolderPath";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { BhSectionMessageAction } from "@components/sectionMessage/BhSectionMessages";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import { Link } from "react-router-dom";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import ConfirmationContainerTableHeader from "@/views/home/project/detail/confirmations/confirmation/tableHeader/ConfirmationContainerTableHeader";
import { imitateBatchFileDownload } from "@/utilities/downloadUtilities";
import { fetchPresignedBatchUrl } from "@/api/fileAPI";
import { Trans, useTranslation } from "react-i18next";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { IFileConfirmationFile } from "@/model/confirmations/IFileConfirmationFile";

interface Props {
  confirmationId: EntityId;
  canEditConfirmation: boolean;
  sortFilesInParentFolders: Function;
}

const ConfirmationContainerPendingContainer: FC<Props> = ({ confirmationId, canEditConfirmation, sortFilesInParentFolders }) => {
  const { t } = useTranslation();
  const fileConfirmation = useAppSelector((state) => selectFileConfirmationById(state, confirmationId));
  const fileConfirmationFiles = useAppSelector((state) => selectPendingFileConfirmationFilesForConfirmation(state, confirmationId));
  const uniqueParentFileEntityIdsAndNames = useAppSelector((state) => selectFileConfirmationFileParentEntityNameAndIdsSorted(state, confirmationId));
  const fileConfirmationFilesSort = useAppSelector(selectFileConfirmationFilesSort);
  const currentUser = useAppSelector(selectCurrentUser);
  const projectId = useAppSelector(selectCurrentProjectId);
  const [fileConfirmationFilesSorted, setFileConfirmationFilesSorted] = useState<Array<IFileConfirmationFile>>(fileConfirmationFiles || []);
  const anyFiles = fileConfirmationFiles.length > 0;

  const attachmentFileIds = fileConfirmationFilesSorted.map((file) => file.fileEntityId);

  useEffect(() => {
    const sortedFiles = sortFilesInParentFolders(uniqueParentFileEntityIdsAndNames, fileConfirmationFiles);
    setFileConfirmationFilesSorted(sortedFiles);
  }, [fileConfirmationFilesSort, fileConfirmationFiles]);

  if (!fileConfirmation || !fileConfirmationFiles) {
    return null;
  }

  const needsApproval = fileConfirmationFiles.filter((file) => file.nextToConfirmUserId === currentUser.id);

  if (!anyFiles) {
    return (
      <BhNoMatchesImage>
        <Trans>CONFIRMATIONS.NO_FILES_PENDING</Trans>
      </BhNoMatchesImage>
    );
  }

  return (
    <BhScrollableBody
      header={
        <>
          {needsApproval.length > 0 && (
            <BhSectionMessageAction>
              <div className="flex flex-row">
                <div className="flex flex-1 flex-row items-center">
                  <BhBadge type={BhBadgeType.BRIGHT}>{needsApproval.length}</BhBadge>
                  <span className="pl-2 font-bold">{t("CONFIRMATIONS.FILES_WAITING", { count: needsApproval.length })}</span>
                </div>
                <div className="flex flex-row items-center">
                  <BhTextOnlyButton
                    icon={faArrowDownToLine}
                    buttonProps={{ onClick: () => fetchPresignedBatchUrl(needsApproval.map((f) => f.fileEntityId)).then((result) => imitateBatchFileDownload(result)) }}
                  >
                    <Trans>GLOBAL.DOWNLOAD_FILES</Trans>
                  </BhTextOnlyButton>
                  <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/confirmation/${confirmationId}/actions`}>
                    <BhPrimaryButton>
                      <Trans>CONFIRMATIONS.GO_CONFIRM</Trans>
                    </BhPrimaryButton>
                  </Link>
                </div>
              </div>
            </BhSectionMessageAction>
          )}
          <ConfirmationContainerTableHeader showConfirmer={true} />
        </>
      }
    >
      {uniqueParentFileEntityIdsAndNames.map((parentFileEntity) => {
        const files = fileConfirmationFiles.filter((fcf) => fcf.parentFileEntityId === parentFileEntity.id);
        if (files.length < 1) return null;
        return (
          <div key={parentFileEntity.id}>
            <ConfirmationFolderPath>{parentFileEntity.name}</ConfirmationFolderPath>
            {fileConfirmationFiles
              .filter((fcf) => fcf.parentFileEntityId === parentFileEntity.id)
              .map((file) => {
                return <ConfirmationFileContainer key={file.fileEntityId} fileConfirmationFileId={file.fileEntityId} canEditConfirmation={canEditConfirmation} attachmentFileIds={attachmentFileIds} />;
              })}
          </div>
        );
      })}
    </BhScrollableBody>
  );
};

export default ConfirmationContainerPendingContainer;
