import { IFileEntityComment } from "@/model/files/IFileEntityComment";
import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { EntityId } from "@reduxjs/toolkit";
import { IFileEntityInfo } from "@/model/files/IFileEntityInfo";
import { IFileEntityTag } from "@/model/files/IFileEntityTag";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { IUser } from "@/model/IUser";

export function fetchFileEntityComments(fileEntityId: EntityId): Promise<Array<IFileEntityComment>> {
  return bauhubGet("/file/" + fileEntityId + "/comments");
}

export function saveFileEntityComment(comment: IFileEntityComment): Promise<IFileEntityComment> {
  return bauhubPost("/file/" + comment.fileEntityId + "/comment", comment);
}

export function deleteFileEntityComment(comment: IFileEntityComment): Promise<IFileEntityComment> {
  return bauhubPost("/file/comment/" + comment.id + "/delete");
}

export function fetchFileEntityInfo(fileEntityId: EntityId): Promise<IFileEntityInfo> {
  return bauhubGet("/file/" + fileEntityId + "/info");
}

export function saveFileEntityTag(tag: IFileEntityTag): Promise<IFileEntityTag> {
  return bauhubPost("/file/" + tag.fileEntityId + "/tag", tag);
}

export function deleteFileEntityTag(tag: IFileEntityTag): Promise<IFileEntityTag> {
  return bauhubPost("/file/" + tag.fileEntityId + "/tag/delete", tag);
}

export function findAccessListForFileInformation(fileEntityId: EntityId, projectId: EntityId): Promise<Array<IUser>> {
  return bauhubGet("/project/" + projectId + "/file/" + fileEntityId + "/information/access");
}
