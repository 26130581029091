import React, { FC } from "react";
import BhLightSeparator from "@components/separator/BhLightSeparator";

const FormSeparatorField: FC = () => {
  return (
    <div className="py-8">
      <BhLightSeparator />
    </div>
  );
};

export default FormSeparatorField;
