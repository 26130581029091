import React from "react";
import { Trans, useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUser } from "@/app/store/userSlice";

const ArchiveModalInformationBanner = () => {
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectCurrentUser);

  return (
    <div className="">
      <div className="bh-bg-white relative flex flex-row items-center justify-between rounded-t border p-6">
        <div className="flex flex-row items-center">
          <div className="w-[105px] flex-none">
            <span className="bh-text-bauhub-green-120 text-4xl">{t("PLANS.LIGHT")}</span>
          </div>
          <span className="bh-text-deep-ocean">{parse(t("MODAL.ARCHIVE.CONFIRMATION_BODY.LIGHT_BANNER_TEXT"))}</span>
        </div>
        <div className="mr-2 flex flex-row items-center justify-center gap-2 whitespace-nowrap pl-8 font-bold">
          <FontAwesomeIcon icon={faCheck} className="bh-text-bauhub-green-120" />
          {t("TIER.SELECTION.ACTIVE_PLAN")}
        </div>
      </div>
      <div className="bh-bg-deep-ocean bh-text-white relative flex flex-row items-center justify-between rounded-b border border-t-0 p-6">
        <div className="flex flex-row items-center">
          <div className="w-[105px] flex-none">
            <span className="text-4xl font-extrabold">{t("PLANS.PRO")}</span>
          </div>
          <div>
            <span className="">{t("MODAL.ARCHIVE.CONFIRMATION_BODY.PRO_BANNER_TEXT_PART_1")}</span>
            <span className="bh-text-bauhub-green font-bold">{t("MODAL.ARCHIVE.CONFIRMATION_BODY.PRO_BANNER_TEXT_PART_2")}</span>
          </div>
        </div>
        <a href={currentUser.language === "et_EE" ? "https://www.bauhub.ee/hinnad" : "https://www.bauhub.ee/prices"} target={"_blank"} rel="noreferrer" className="whitespace-nowrap pl-8 text-right">
          <BhTextOnlyButton buttonProps={{ classes: "bh-bg-mint" }}>
            <Trans>{t("SYNC.COMPARE_PLANS")}</Trans>
          </BhTextOnlyButton>
        </a>
      </div>
    </div>
  );
};

export default ArchiveModalInformationBanner;
