import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  selectAllProjectContactsFilteredAndSorted,
  selectCurrentProjectContactsLoading,
  selectCurrentProjectContactsSortedBy,
  selectCurrentProjectContactsSortReversed,
  setContactsSortBy,
  toggleContactsSortReversed
} from "@/app/store/project/projectContactsSlice";
import BhTableHeader from "@components/table/BhTableHeader";
import ContactsTableRowContainerSkeleton from "@/features/contactsTable/ContactsTableRowContainerSkeleton";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IContact } from "@/model/IContact";
import ContactsTableRowContainer from "@/features/contactsTable/ContactsTableRowContainer";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useTranslation } from "react-i18next";

const ContactsTable: FC = () => {
  const { t } = useTranslation();
  const loading = useAppSelector(selectCurrentProjectContactsLoading);
  const contacts = useAppSelector(selectAllProjectContactsFilteredAndSorted);
  const sortedBy = useAppSelector(selectCurrentProjectContactsSortedBy);
  const sortReversed = useAppSelector(selectCurrentProjectContactsSortReversed);
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));

  useEffect(() => {});

  const tableColumnHeaders: Array<IBhTableHeader<IContact>> = [
    { id: 1, content: `${t("PROJECT.TABLE.HEADER.NAME")}`, field: "firstName", classes: "min-w-250px w-1/3 ", sortable: true },
    { id: 2, content: `${t("CONTACT.TABLE.HEADER.CONTACT")}`, field: "email", classes: "w-72 min-w-272px flex-shrink-0 ", sortable: true },
    { id: 3, content: `${t("CONTACT.POSITION") + "/" + t("CONTACTS.FILTER.COMPANY")}`, field: "company", classes: "w-52 min-w-196px flex-shrink-0 ", sortable: true },
    isProjectAdmin && { id: 4, content: `${t("CONTACTS.FILTER.USER_GROUP")}`, field: "workGroupIds", classes: "w52 min-w-132px flex-shrink-0", sortable: false },
    { id: 5, content: "", classes: "w-32 min-w-92px flex-shrink-0 ", sortable: false }
  ].filter(Boolean) as Array<IBhTableHeader<IContact>>;

  const onTableHeaderClickCallback = (column: IBhTableHeader<IContact>) => {
    if (column.field) {
      if (sortedBy === column.field) {
        dispatch(toggleContactsSortReversed());
      } else {
        dispatch(setContactsSortBy(column.field));
      }
    }
  };

  return (
    <table className="min-w-full overflow-auto ">
      <BhTableHeader columns={tableColumnHeaders} onClickCallback={onTableHeaderClickCallback} sortedBy={sortedBy} reversed={sortReversed} />
      <tbody className="divide-y overflow-hidden ">
        {loading === BhStateStatusType.PENDING && <ContactsTableRowContainerSkeleton />}
        {loading === BhStateStatusType.SUCCESS && contacts.map((contact) => <ContactsTableRowContainer contact={contact} key={contact.id} />)}
      </tbody>
    </table>
  );
};

export default ContactsTable;
