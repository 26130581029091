import React, { FC } from "react";
import { IFileTree } from "@/model/files/IFileTreeTemplate";
import BhIconButton from "@components/buttons/BhIconButton";
import { faListTree } from "@fortawesome/pro-regular-svg-icons";

interface Props {
  tree: IFileTree;
  onClick: Function;
}

const ProjectTreePreviewButton: FC<Props> = ({ tree, onClick }) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick(tree);
  };

  return <BhIconButton buttonProps={{ onClick: handleClick }} icon={faListTree} />;
};

export default React.memo(ProjectTreePreviewButton);
