import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { useAppSelector } from "@/app/hooks";
import { isCurrentUserSuperUser } from "@/app/store/userSlice";
import NavbarUserComponent from "@/views/home/navbar/NavbarUserComponent";
import BhCompanyPlanTag from "@components/tags/BhCompanyPlanTag";
import { EntityId } from "@reduxjs/toolkit";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import BhBauhubLogo from "@components/logo/BhBauhubLogo";

interface Props {
  companyId?: EntityId;
}

const NavbarGlobal: FC<Props> = ({ companyId }) => {
  const isSuperUser = useAppSelector(isCurrentUserSuperUser);
  const location = useLocation();

  const isRootView = location.pathname === "/";
  const isAdminView = location.pathname.startsWith("/admin") || location.pathname.startsWith("/bo");
  const isSyncView = location.pathname.startsWith("/sync/");
  const isProjectView = location.pathname.startsWith("/project/");
  const isCompanyView = location.pathname.startsWith("/company/");
  const showAdmin = isSuperUser && (isRootView || isAdminView);

  if (isSyncView || isProjectView || (isCompanyView && !companyId)) {
    return null;
  }

  return (
    <Disclosure as="nav" className="bh-border-pigeon-20 z-30 flex h-14 flex-row border-b">
      <div className="w-66 flex px-8 sm:pr-0">
        <div className="mr-8 flex flex-shrink-0 items-center">
          <Link className="h-font" to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/`}>
            <BhBauhubLogo />
          </Link>
          {companyId && <BhCompanyPlanTag companyId={companyId} />}
        </div>
      </div>
      {showAdmin && (
        <div className="mr-8 flex w-full items-center space-x-4">
          <Link className="h-font " to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/bo`}>
            Back Office
          </Link>
          <Link className="h-font" to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/admin`}>
            Admin
          </Link>
          <div className="w-66 flex flex-col text-[9px] leading-[11px]">
            <div className="bh-text-pigeon">BE: {ConfigSingleton.getInstance().getConfig().BUILD_INFO ? ConfigSingleton.getInstance().getConfig().BUILD_INFO.substring(0, 35) : ""} </div>
            <div className="bh-text-pigeon">FE: {process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION.substring(9, 45) : ""} </div>
          </div>
        </div>
      )}
      <div className="flex w-full items-center justify-end space-x-2 px-8">
        <NavbarUserComponent />
      </div>
    </Disclosure>
  );
};

export default NavbarGlobal;
