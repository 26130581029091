import React, { FC, useCallback, useEffect, useState } from "react";
import BhShareModal from "@components/modal/BhShareModal";
import BhShareFileContainer from "@components/modal/BhShareFileContainer";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { allFileEntitiesUnSelected, fileEntitySelected, selectAllFilesSelected } from "@/app/store/filesSlice";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhIconButton from "@components/buttons/BhIconButton";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhLightSeparator from "@components/separator/BhLightSeparator";
import BhLargeBoldText from "@components/text/BhLargeBoldText";
import { selectCurrentProjectId, toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import useHandleFormSubmit from "@/utilities/hooks/useHandleFormSubmit";
import { IFileConfirmation } from "@/model/confirmations/IFileConfirmation";
import { IFileConfirmationFile } from "@/model/confirmations/IFileConfirmationFile";
import BhInputLabel from "@components/input/BhInputLabel";
import BhChildComponentWithFetchValues from "@components/input/BhChildComponentWithFetchValues";
import { fetchRelatedUsersInProjectWithSearch } from "@/api/projectAPI";
import { convertSimpleValidUserAuthorityToUser } from "@/model/ISimpleValidUserAuthority";
import { IUser } from "@/model/IUser";
import { IFileConfirmationUser } from "@/model/confirmations/IFileConfirmationUser";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import NewConfirmationModalListRow from "@/views/home/project/detail/confirmations/newConfirmationModal/NewConfirmationModalListRow";
import BhTableHeader from "@components/table/BhTableHeader";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IBauhubEvent } from "@/model/IBauhubEvent";
import { saveNewFileConfirmationAsync } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import { selectCurrentUser } from "@/app/store/userSlice";
import { store } from "@/app/store";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import BhSearchInputWithUserDropdown from "@components/input/BhSearchInputWithUserDropdown";
import { Trans, useTranslation } from "react-i18next";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {}

const DirectoryConfirmationModalContainer: FC<Props> = () => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const currentUser = useAppSelector(selectCurrentUser);
  const files = useAppSelector(selectAllFilesSelected);
  const handleConfirmationFormSubmit = useHandleFormSubmit<IFileConfirmation>();
  const [users, setUsers] = useState<Array<IUser>>([]);
  const [saving, setSaving] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fileEntityIdsAlreadyInConfirmation = files.filter((file) => file.confirmationUserCount).map((f) => f.id);
    if (fileEntityIdsAlreadyInConfirmation.length > 0) {
      dispatch(fileEntitySelected({ ids: fileEntityIdsAlreadyInConfirmation, selected: false }));
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.WARNING,
          disappear: true,
          children: t("CONFIRMATIONS.FILES_REMOVED", { count: fileEntityIdsAlreadyInConfirmation.length })
        })
      );
    }
  }, []);

  const closeModal = useCallback(() => {
    dispatch(toggleProjectModalsOpen({ modal: "directoryConfirmationModal" }));
  }, []);

  const saveNewFileConfirmation = (formValues: IFileConfirmation) => {
    if (formValues.description?.length < 1 || files.length < 1 || users.length < 1) {
      store.dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.WARNING,
          disappear: true,
          children: t("GLOBAL.FILL_ALL_FIELDS")
        })
      );
      return;
    }
    setSaving(true);

    const withCorrectDateFormat = {
      ...formValues,
      projectId: projectId,
      starterUserEntityId: currentUser.id,
      fileRelations: files.map((file) => ({ fileEntityId: file.id } as IFileConfirmationFile)),
      userRelations: users.map((user, index) => ({ userEntityId: user.id, orderNumber: index + 1 } as IFileConfirmationUser))
    } as IFileConfirmation;
    dispatch(saveNewFileConfirmationAsync(withCorrectDateFormat)).then((action) => {
      const confirmation = action.payload as IFileConfirmation;
      confirmation &&
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.SUCCESS,
            disappear: true,
            navigateTo: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${confirmation.projectId}/confirmation/${confirmation.id}`,
            translateCode: "CONFIRMATION.CREATED"
          })
        );
      setSaving(false);
      dispatch(allFileEntitiesUnSelected());
    });
  };

  const tableColumnHeaders: Array<IBhTableHeader<IBauhubEvent>> = [
    { id: 1, content: "", classes: "w-6" },
    { id: 2, content: t("CONFIRMATION.APPROVERS_TABLE.ORDER_NR"), classes: "w-14 text-center" },
    { id: 3, content: t("GLOBAL.NAME") },
    { id: 4, content: "", classes: "w-10" }
  ];

  const addUser = (addedUser: IUser) => {
    setUsers([...users, addedUser]);
  };

  const removeUser = (userToRemove: IUser) => {
    setUsers(users.filter((user) => user !== userToRemove));
  };

  const insertUserAfter = (user: IUser, newIndex: number) => {
    const withoutUser = [...users].filter((s) => s !== user);
    const newUsers = [...withoutUser.slice(0, newIndex), user, ...withoutUser.slice(newIndex)];
    setUsers(newUsers);
  };

  const showValueCondition = (user: IUser) => {
    return !users.find((s) => s === user);
  };

  return (
    <BhShareModal
      header={
        <div className="flex flex-row items-center justify-between">
          <h2>
            <Trans>FILE_CONFIRMATION.TITLE</Trans>
          </h2>
          <BhIconButton icon={faTimes} buttonProps={{ onClick: closeModal }} />
        </div>
      }
      footer={
        <div className="flex w-full flex-1 justify-between">
          <BhTextOnlyButton buttonProps={{ onClick: closeModal }}>{t("GLOBAL.BACK")}</BhTextOnlyButton>
          <BhPrimaryButton buttonProps={{ submitForm: "shareboxForm", disabled: saving || files.length === 0 }}>{t("GLOBAL.CONFIRM")}</BhPrimaryButton>
        </div>
      }
      onOutsideClick={closeModal}
    >
      <BhShareFileContainer files={files} />
      <div className="py-8 px-20">
        <form id="shareboxForm" className="flex flex-col" onSubmit={handleConfirmationFormSubmit((confirmation) => saveNewFileConfirmation(confirmation))}>
          <BhLargeBoldText classes="mb-6">{t("CONFIRMATION.NEW")}</BhLargeBoldText>
          <BhInputStackedLabel initialValue={""} property={"description"} label={t("CONFIRMATION.NAME")} />
          <div className="my-10">
            <BhLightSeparator />
          </div>
          <div className="flex flex-row pb-8">
            <div className="w-1/2">
              <BhInputLabel>{t("FILE_CONFIRMATION.CONFIRMER")}</BhInputLabel>
              <BhChildComponentWithFetchValues
                fetchValues={() => {
                  return fetchRelatedUsersInProjectWithSearch(projectId).then((users) => {
                    return users.map((simpleValidUser) => convertSimpleValidUserAuthorityToUser(simpleValidUser));
                  });
                }}
                child={<BhSearchInputWithUserDropdown values={[]} onSelect={addUser} showValueCondition={showValueCondition} placeholder={t("CONFIRMATION.SEARCH_USER") as string} />}
              />
            </div>
          </div>
        </form>
        <DndProvider backend={HTML5Backend}>
          {users.length > 0 && (
            <table className="min-w-full overflow-auto">
              <BhTableHeader columns={tableColumnHeaders} />
              <tbody className="divide-y overflow-hidden ">
                {users.map((user, index) => {
                  return <NewConfirmationModalListRow key={user.id} user={user} index={index} removeUser={removeUser} insertUserAfter={insertUserAfter} />;
                })}
              </tbody>
            </table>
          )}
        </DndProvider>
      </div>
    </BhShareModal>
  );
};

export default DirectoryConfirmationModalContainer;
