import React, { FC, Fragment } from "react";
import { Tab } from "@headlessui/react";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGroup } from "@fortawesome/pro-regular-svg-icons/faUserGroup";

interface Props {
  workGroupName: string;
}
const ContactWorkgroupCard: FC<Props> = ({ workGroupName }) => {
  return (
    <>
      <Tab as={Fragment}>
        {({ selected }) => (
          <div className={classNames(selected ? "bh-bg-mint-110" : "hover:bh-bg-mint-50", "flex cursor-pointer px-1 transition-all duration-300 ease-in-out")}>
            <div className="my-1.5 inline-flex items-center">
              <div className={classNames(selected && "bh-text-bauhub-green-120")}>
                <FontAwesomeIcon icon={faUserGroup} />
              </div>
              <div className={classNames(selected && "font-bold ", "ml-1")}>{workGroupName}</div>
            </div>
          </div>
        )}
      </Tab>
    </>
  );
};

export default ContactWorkgroupCard;
