import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

export const imitateBatchFileDownload = (presignedResult: any, requestParams?: string) => {
  let link = document.createElement("a");
  link.href = ConfigSingleton.getInstance().getConfig().REACT_APP_PUBLIC_API_URL + "/url/batch/direct/" + presignedResult.value;
  if (requestParams) {
    link.href += "?" + requestParams;
  }
  link.target = "_self";
  document.body.appendChild(link);
  link.click();
  if (link.parentNode) link.parentNode.removeChild(link);
};

export const imitatePdfFromFormDownload = (response: any) => {
  let link = document.createElement("a");
  link.href = response.value;
  link.target = "_self";
  document.body.appendChild(link);
  link.click();
  if (link.parentNode) link.parentNode.removeChild(link);
};

export const imitateUrlDownload = (url: string) => {
  let link = document.createElement("a");
  link.href = url;
  link.target = "_self";
  document.body.appendChild(link);
  link.click();
  if (link.parentNode) link.parentNode.removeChild(link);
};

export const imitateExcelDownload = (response: any, fileName: string) => {
  response.blob().then((blob: Blob) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName + ".xlsx");
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  });
};
