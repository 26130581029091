import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import TaskBoardExport from "@components/taskBoard/TaskBoardExport";
import { useTranslation } from "react-i18next";
import BhSortButton from "@components/sort/BhSortButton";
import { selectTasksSort, tasksSortSet } from "@/app/store/tasksSlice";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import { initialTasksSort, ITasksSort } from "@/model/taskBoard/ITasksSort";

const TaskBoardSort: FC = () => {
  const { t } = useTranslation();
  const [tasksOrdering, setTasksOrdering] = useLocalStorage<ITasksSort>("tasksOrdering", initialTasksSort);
  const sort = useAppSelector(selectTasksSort);

  const dropdownMenuValues = [
    { value: "name", translation: t("GLOBAL.ORDER_BY.NAME") },
    { value: "created", translation: t("GLOBAL.ORDER_BY.CREATED") },
    { value: "updated", translation: t("GLOBAL.ORDER_BY.UPDATED") },
    { value: "deadline", translation: t("GLOBAL.ORDER_BY.DEADLINE") },
    { value: "priority", translation: t("GLOBAL.ORDER_BY.PRIORITY") },
    { value: "assignee", translation: t("GLOBAL.ORDER_BY.ASSIGNEEFULLNAME") }
  ];

  return (
    <div className="flex flex-row flex-wrap items-center justify-end">
      <BhSortButton
        sort={sort}
        setSortForStore={tasksSortSet}
        localStorageSort={tasksOrdering}
        setSortForLocalStorage={setTasksOrdering}
        sortValues={dropdownMenuValues}
        position={BhDropdownPositionEnum.BOTTOM_LEFT}
      />
      {<TaskBoardExport />}
    </div>
  );
};

export default TaskBoardSort;
