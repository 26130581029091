import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { classNames } from "@/utilities/jsUtilities";
import FileNameLink from "@/features/fileContainer/FileNameLink";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { EntityId } from "@reduxjs/toolkit";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import BhElementWithBadge from "@components/badge/BhElementWithBadge";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import { faMessage } from "@fortawesome/pro-regular-svg-icons/faMessage";
import { ButtonSize } from "@components/buttons/IBhButtonProps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons/faArrowsRotate";
import { BhSectionMessageAction } from "@components/sectionMessage/BhSectionMessages";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons/faExclamationCircle";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import {
  deleteFilesFromConfirmationAsync,
  openCommentsForFileConfirmationFileIdSet,
  replaceFileWithLatestVersionAsync,
  resetFileInConfirmationAsync,
  selectFileConfirmationFileById,
  selectOpenCommentsForFileConfirmationFileId
} from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import { selectFileConfirmationUsers } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import { IFileEntity } from "@/model/files/IFileEntity";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import ConfirmationFileRevisionContainer from "@/views/home/project/detail/confirmations/confirmation/confirmationFileContainer/ConfirmationFileRevisionContainer";
import { formatDateTime } from "@/utilities/dateUtility";
import { fetchUrlForFile } from "@/api/fileAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { Trans, useTranslation } from "react-i18next";
import ConfirmationFileCardRevisionNumber from "@/views/home/project/detail/confirmations/confirmation/confirmationFileContainer/ConfirmationFileCardRevisionNumber";
import BhTooltip from "@components/BhTooltip";

interface Props {
  fileConfirmationFileId: EntityId;
  canEditConfirmation: boolean;
  attachmentFileIds?: Array<EntityId>;
}

const ConfirmationFileContainerRejectedFC: FC<Props> = ({ fileConfirmationFileId, canEditConfirmation, attachmentFileIds }) => {
  const { t } = useTranslation();
  const fileConfirmationFile = useAppSelector((state) => selectFileConfirmationFileById(state, fileConfirmationFileId));
  const fileConfirmationUsers = useAppSelector((state) => fileConfirmationFile && selectFileConfirmationUsers(state, fileConfirmationFile.fileConfirmationId));
  const openCommentFileEntityId = useAppSelector(selectOpenCommentsForFileConfirmationFileId);
  const [revisionsShown, setRevisionShown] = useState(false);
  const [revisions, setRevisions] = useState<Array<IFileEntity>>([]);
  const dispatch = useAppDispatch();

  const [showDeleteFileConfirmationModal, hideDeleteFileConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteFileConfirmationModal}
      header={<h2>{t("CONFIRMATIONS.DELETE_FILE_MODAL.HEADER")}</h2>}
      body={<div>{t("CONFIRMATIONS.DELETE_FILE_MODAL.BODY")}</div>}
      confirmationButtonText={t("CONFIRM.CONFIRM")}
      handleDelete={() => {
        fileConfirmationFile && dispatch(deleteFilesFromConfirmationAsync({ fileConfirmationId: fileConfirmationFile.fileConfirmationId, fileIds: [fileConfirmationFile.fileEntityId] }));
        hideDeleteFileConfirmationModal();
      }}
    />
  ));

  const [showResetFileConfirmationModal, hideResetFileConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideResetFileConfirmationModal}
      header={<h2>{t("CONFIRMATIONS.RESET_MODAL.HEADER")}</h2>}
      body={<div>{t("CONFIRMATIONS.RESET_MODAL.BODY")}</div>}
      confirmationButtonText={t("CONFIRM.CONFIRM")}
      handleDelete={() => {
        fileConfirmationFile && dispatch(resetFileInConfirmationAsync({ fileConfirmationId: fileConfirmationFile.fileConfirmationId, fileId: fileConfirmationFile.fileEntityId }));
        hideResetFileConfirmationModal();
      }}
    />
  ));

  const [showReplaceFileConfirmationModal, hideReplaceFileConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideReplaceFileConfirmationModal}
      header={<h2>{t("CONFIRMATIONS.RESET_MODAL.HEADER")}</h2>}
      body={<div>{t("CONFIRMATIONS.RESET_MODAL.BODY")}</div>}
      confirmationButtonText={t("CONFIRM.CONFIRM")}
      handleDelete={() => {
        fileConfirmationFile && dispatch(replaceFileWithLatestVersionAsync({ fileConfirmationId: fileConfirmationFile?.fileConfirmationId, fileId: fileConfirmationFile?.fileEntityId }));
        hideReplaceFileConfirmationModal();
      }}
    />
  ));

  if (!fileConfirmationFile) {
    return null;
  }

  const declinedByUser = fileConfirmationUsers?.find((u) => u.id === fileConfirmationFile?.declinedByUserId);

  const fileConfirmationFileWithId = { ...fileConfirmationFile, id: fileConfirmationFile.fileEntityId };

  return (
    <>
      <div className={classNames("bh-border-pigeon-40 bh-hover-container group flex flex-col border-b")}>
        <div className="flex h-14 w-full flex-row items-center">
          <div className="flex flex-1 flex-row justify-between overflow-hidden pl-3 pr-2">
            <div className="flex flex-1 flex-row items-center space-x-2 overflow-hidden">
              <BhFileCardIcon fileEntity={fileConfirmationFileWithId as unknown as IFileEntity} fileCardSize={FileCardSize.LARGE} />
              <FileNameLink fileEntity={fileConfirmationFileWithId as unknown as IFileEntity} attachmentFileIds={attachmentFileIds} />
            </div>
            <div className={classNames(fileConfirmationFileWithId.commentCount < 1 && "hidden group-hover:block")}>
              <BhElementWithBadge badge={fileConfirmationFileWithId.commentCount > 0 && <BhBadge type={BhBadgeType.DARK}>{fileConfirmationFile.commentCount}</BhBadge>}>
                <BhTooltip body={t("TASK.COMMENTS")}>
                  <BhIconButton
                    icon={faMessage}
                    buttonProps={{
                      onClick: () => {
                        dispatch(openCommentsForFileConfirmationFileIdSet(fileConfirmationFileWithId?.fileEntityId));
                      }
                    }}
                    withBackground={openCommentFileEntityId === fileConfirmationFileWithId?.fileEntityId}
                  />
                </BhTooltip>
              </BhElementWithBadge>
            </div>
          </div>
          <div className="w-18 relative flex flex-none items-center justify-end px-3">
            <ConfirmationFileCardRevisionNumber
              fileEntityId={fileConfirmationFileWithId.fileEntityId}
              parentFileEntityId={fileConfirmationFileWithId.parentFileEntityId}
              fileConfirmationId={fileConfirmationFileWithId.fileConfirmationId}
              canUploadNewVersion={canEditConfirmation}
              revision={fileConfirmationFileWithId.revision}
              isRevision={false}
              revisionsShown={revisionsShown}
              setRevisionShown={() => setRevisionShown(!revisionsShown)}
              setRevisions={setRevisions}
            />
          </div>
          <div className="w-66 flex-none px-3">{declinedByUser && <BhUserIconWithName user={declinedByUser} />}</div>
          <div className="w-24 flex-none px-3 text-right">
            <div className="hidden flex-row justify-end group-hover:flex">
              <BhTooltip body={t("GLOBAL.DOWNLOAD")}>
                <BhIconButton
                  icon={faArrowDownToLine}
                  buttonProps={{
                    onClick: () => {
                      fetchUrlForFile(fileConfirmationFileWithId.fileEntityId, false, true, fileConfirmationFileWithId.uuid).then((presignedUrl) => {
                        imitateUrlDownload(presignedUrl.value);
                      });
                    }
                  }}
                />
              </BhTooltip>
              {canEditConfirmation && (
                <BhTooltip body={t("ASYNC.REMOVE")}>
                  <BhIconButton icon={faCircleMinus} buttonProps={{ onClick: showDeleteFileConfirmationModal }} />
                </BhTooltip>
              )}
            </div>
          </div>
          <div className="w-20 flex-none">
            {canEditConfirmation && (
              <BhSecondaryButton buttonProps={{ size: ButtonSize.XL, onClick: showResetFileConfirmationModal }}>
                <FontAwesomeIcon icon={faArrowsRotate} size={"lg"} />
              </BhSecondaryButton>
            )}
          </div>
        </div>
        {canEditConfirmation && fileConfirmationFile.newVersion && (
          <div className="pl-12">
            <BhSectionMessageAction>
              <div className="-mx-4 -my-2 flex flex-row">
                <div className="flex flex-1 flex-row items-center">
                  <FontAwesomeIcon icon={faExclamationCircle} className="pr-2" />
                  <Trans>CONFIRMATIONS.NEW_VERSION</Trans> –{" "}
                  <b className="px-2">
                    <Trans>GLOBAL.VERSION</Trans> {fileConfirmationFile.newVersion.revision}
                  </b>
                  <i>
                    {t("GLOBAL.UPDATED_BY") + " " + formatDateTime(fileConfirmationFile.newVersion.updated)}, {fileConfirmationFile.newVersion.updatedByFullName}
                  </i>
                </div>
                <div className="flex flex-row items-center">
                  <BhPrimaryButton icon={faCheck} buttonProps={{ onClick: showReplaceFileConfirmationModal }}>
                    <Trans>CONTRACT.COPY_MODAL.REPLACE</Trans>
                  </BhPrimaryButton>
                </div>
              </div>
            </BhSectionMessageAction>
          </div>
        )}
      </div>
      {revisionsShown && revisions.length > 0 && revisions.map((revisionFileEntity) => <ConfirmationFileRevisionContainer fileEntity={revisionFileEntity} key={revisionFileEntity.id} />)}
    </>
  );
};

const ConfirmationFileContainerRejected = React.memo(ConfirmationFileContainerRejectedFC);
export default ConfirmationFileContainerRejected;
