import React, { FC } from "react";
import BhTableRow from "@components/table/BhTableRow";

interface Props {
  numberOfDisplayedItems: number;
}

const ProjectLogTableRowContainerSkeleton: FC<Props> = ({ numberOfDisplayedItems }) => {
  return (
    <>
      {[...Array(numberOfDisplayedItems)].map((e, i) => {
        return (
          <BhTableRow key={i}>
            <td>
              <div className="bh-bg-pigeon-50 h-5 w-32 animate-pulse rounded" />
            </td>
            <td>
              <div className="bh-bg-pigeon-50 h-5 w-32 animate-pulse rounded" />
            </td>
            <td>
              <div className="bh-bg-pigeon-50 h-5 w-20 animate-pulse rounded" />
            </td>
          </BhTableRow>
        );
      })}
    </>
  );
};

export default ProjectLogTableRowContainerSkeleton;
