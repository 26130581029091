import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons/faTriangleExclamation";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";

interface Props {
  setIsShown: (value: boolean) => void;
  isShown: boolean;
  onConfirmCallback: Function;
}

const BOCreditingConfirmationModal: FC<Props> = ({ setIsShown, isShown, onConfirmCallback }) => {
  return (
    <BhModal
      setIsShown={setIsShown}
      isShown={isShown}
      header={(
        <div className="flex items-center gap-5">
          <FontAwesomeIcon icon={faTriangleExclamation} className="bh-text-warning-yellow" size="lg" />
          <h2>Crediting confirmation</h2>
        </div>
      )}
      footer={<BhModalFooter confirmButtonText="Credit" onConfirm={onConfirmCallback} cancelButtonText="Cancel" onCancel={setIsShown} />}
    >
      <div className="p-8">
        <p>The crediting of the invoice cannot be reversed. Are you sure you want to credit the invoice?</p>
      </div>
    </BhModal>
  );
};

export default BOCreditingConfirmationModal;