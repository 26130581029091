import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import ProjectPinnedContainer from "@/views/home/projectList/ProjectPinnedContainer";
import { fetchProjectsByCompanyAsync, selectCompaniesStatus } from "@/app/store/companiesSlice";
import CompanyListSettingsRow from "@/views/home/projectList/CompanyListSettingsRow";
import CompanyList from "@/views/home/projectList/CompanyList";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import BhLoadingIconContained from "@components/loading/BhLoadingIconContained";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import { fetchUserUnsignedSignInvitesAsync } from "@/app/store/signInvitesSlice";

const ProjectListContainer: FC = () => {
  useDocumentTitle("HOME.PROJECTS");
  const dispatch = useAppDispatch();
  const companiesStatus = useAppSelector(selectCompaniesStatus);

  useEffect(() => {
    dispatch(fetchProjectsByCompanyAsync());
    dispatch(fetchUserUnsignedSignInvitesAsync());
  });

  return (
    <div className="flex h-full w-full justify-center">
      {companiesStatus === BhStateStatusType.INITIAL && <BhLoadingIconContained />}
      {companiesStatus === BhStateStatusType.SUCCESS && (
        <BhScrollableBody keepScrollForKey="companiesList">
          <div className="max-w-screen-2xl w-full px-16 pb-4 sm:px-4">
            <>
              <CompanyListSettingsRow />
              <ProjectPinnedContainer />
              <CompanyList />
            </>
          </div>
        </BhScrollableBody>
      )}
    </div>
  );
};

export default ProjectListContainer;
