import React, { FC, useState } from "react";
import { deleteSignatureAsync, deleteSignInviteAsync, resendSignInviteAsync, selectNextToSignForContainer } from "@/app/store/signaturesSlice";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons/faArrowsRotate";
import BhDeclineIconButton from "@components/buttons/BhDeclineIconButton";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import { selectCurrentUser, selectCurrentUserInfo, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { IFileEntity } from "@/model/files/IFileEntity";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import BhTooltip from "@components/BhTooltip";

interface Props {
  signature: ISignatureHolder;
  containerFileEntity: IFileEntity;
}

const RegularSignatureActionButtons: FC<Props> = ({ signature, containerFileEntity }) => {
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectCurrentUser);
  const currentUserInfo = useAppSelector(selectCurrentUserInfo);
  const isCurrentUserProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, containerFileEntity.projectId));
  const nextToSign = useAppSelector(selectNextToSignForContainer(signature.containerUuid));
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useAppDispatch();

  const [deleteInviteConfirmationModalOpen, setDeleteInviteConfirmationModalOpen] = useState(false);
  const [showRemoveSignatureModal, hideRemoveSignatureModal] = useModal(
    () => (
      <BhDeleteConfirmationModal
        setIsOpen={() => {
          hideRemoveSignatureModal();
        }}
        header={<h2>{t("MODAL.DELETE_SIGNATURE_TITLE")}</h2>}
        body={<div>{t("MODAL.DELETE_SIGNATURE_BODY")}</div>}
        confirmationButtonText={t("MODAL.DELETE_SIGNATURE.CONFIRM")}
        confirmDisabled={isSaving}
        handleDelete={() => {
          removeSignature();
        }}
      />
    ),
    [isSaving]
  );

  const isCurrentUserCreatedInvite = signature.inviteCreatedBy && signature.inviteCreatedBy === currentUser.username;
  const canChangeInvite = isCurrentUserCreatedInvite || isCurrentUserProjectAdmin;

  const isNotSignedInvite = signature.isUnsignedInvite;
  const isNotSignedOrDeclinedInvite = isNotSignedInvite && !signature.declined;

  const isResendShown = isNotSignedOrDeclinedInvite && canChangeInvite && (!containerFileEntity.queueEnabled || (containerFileEntity.queueEnabled && signature === nextToSign));
  const isDeleteInviteShown = isNotSignedInvite && canChangeInvite;

  const isCurrentUserSigner = signature.personalCode === currentUserInfo.personalCode;
  const canChangeSignature = isCurrentUserCreatedInvite || isCurrentUserSigner;

  const isDeleteSignatureShown = !signature.isUnsignedInvite && canChangeSignature && !signature.declined;

  const removeSignature = () => {
    setIsSaving(true);
    dispatch(deleteSignatureAsync({ fileEntityId: containerFileEntity.id, signatureHolder: signature })).then(() => {
      setIsSaving(false);
    });
  };

  return (
    <>
      {isResendShown && (
        <BhTooltip body={t("SIGNATURE.RESEND_INVITE")}>
          <BhIconButton icon={faArrowsRotate} buttonProps={{ onClick: () => dispatch(resendSignInviteAsync(signature)) }} />
        </BhTooltip>
      )}
      {isDeleteInviteShown && (
        <BhTooltip body={t("SIGNATURE.REMOVE_INVITE")}>
          <BhDeclineIconButton icon={faCircleMinus} buttonProps={{ onClick: () => setDeleteInviteConfirmationModalOpen(true) }} />
        </BhTooltip>
      )}
      {isDeleteSignatureShown && (
        <BhTooltip body={t("ASYNC.REMOVE")}>
          <BhIconButton icon={faCircleMinus} buttonProps={{ onClick: () => showRemoveSignatureModal() }} />
        </BhTooltip>
      )}

      {deleteInviteConfirmationModalOpen && (
        <BhDeleteConfirmationModal
          isOpen={true}
          setIsOpen={setDeleteInviteConfirmationModalOpen}
          header={<h2>{t("MODAL.DELETE_INVITE_TITLE")}</h2>}
          body={t("MODAL.DELETE_INVITE_BODY")}
          confirmationButtonText={t("GLOBAL.DELETE")}
          confirmDisabled={isSaving}
          handleDelete={() => {
            setIsSaving(true);
            dispatch(deleteSignInviteAsync(signature)).then(() => {
              setIsSaving(false);
            });
          }}
        />
      )}
    </>
  );
};

export default RegularSignatureActionButtons;
