import { useLocation } from "react-router-dom";
import React, { FC, useCallback, useEffect } from "react";
import { redirect } from "@/api/auth/authAPI";

const SyncRedirect: FC = () => {
  const location = useLocation();

  useEffect(() => {
    redirect();
  }, [location]);


  return null;
};

export default SyncRedirect;
