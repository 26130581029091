import { EntityId } from "@reduxjs/toolkit";
import { IUser } from "@/model/IUser";

export interface IFormBaseVersion {
  id: EntityId;
  formBaseId: EntityId;
  status: FormBaseVersionStatus;
  versionNumber: number;
  deleted: boolean;
  createdBy: string;
  updatedBy: string;
  created: Date;
  updated: Date;
  createdByUserEntity?: IUser;
}

export enum FormBaseVersionStatus {
  DRAFT = "DRAFT",
  FINAL = "FINAL"
}
