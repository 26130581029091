import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/pro-solid-svg-icons/faCheckDouble";

const BhStatusCellApproved: FC = () => {
  return (
    <div className="bh-bg-success-green-10 flex h-14 w-12 flex-row items-center justify-center">
      <FontAwesomeIcon icon={faCheckDouble} size="lg" className="bh-text-success-green-110" />
    </div>
  );
};

export default BhStatusCellApproved;
