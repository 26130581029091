import React, { FC, useEffect } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhIconButton from "@components/buttons/BhIconButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import NewCommentInput, { ICommentInput } from "@/views/home/project/detail/directory/fileInfoBar/fileInfoBarDetailViews/NewCommentInput";
import {
  fetchFileConfirmationFileCommentsAsync,
  openCommentsForFileConfirmationFileIdSet,
  saveFileConfirmationFileCommentAsync,
  selectFileConfirmationFilesComments,
  selectFileConfirmationFilesName,
  selectOpenCommentsForFileConfirmationFileId
} from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import BhCommentCompact from "@components/comment/BhCommentCompact";
import { IFileConfirmationComment } from "@/model/confirmations/IFileConfirmationComment";
import { Trans } from "react-i18next";

interface Props {
  fileConfirmationId: EntityId;
}
const ConfirmationContainerFileComments: FC<Props> = ({ fileConfirmationId }) => {
  const openCommentFileEntityId = useAppSelector(selectOpenCommentsForFileConfirmationFileId);
  const fileConfirmationFileName = useAppSelector((state) => openCommentFileEntityId && selectFileConfirmationFilesName(state, openCommentFileEntityId));
  const fileConfirmationFileComments = useAppSelector((state) => openCommentFileEntityId && selectFileConfirmationFilesComments(state, openCommentFileEntityId));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (openCommentFileEntityId) {
      dispatch(fetchFileConfirmationFileCommentsAsync({ fileConfirmationId: fileConfirmationId, fileId: openCommentFileEntityId }));
    }
  }, [openCommentFileEntityId]);

  if (!openCommentFileEntityId) {
    return null;
  }

  return (
    <div className="flex w-[290px] flex-col border-l">
      <div className="flex h-full flex-col overflow-hidden">
        <div className="">
          <div className="flex flex-row items-center justify-between pl-4 pr-2 pt-2">
            <p>
              <Trans>TASK.COMMENTS</Trans>
            </p>
            <BhIconButton icon={faXmark} buttonProps={{ onClick: () => dispatch(openCommentsForFileConfirmationFileIdSet(undefined)) }} />
          </div>
          <div className="px-4 font-bold">{fileConfirmationFileName}</div>
          <NewCommentInput
            onSubmit={(commentInput: ICommentInput) => {
              const comment = { ...commentInput, fileConfirmationId: fileConfirmationId, fileEntityId: openCommentFileEntityId } as unknown as IFileConfirmationComment;
              return dispatch(saveFileConfirmationFileCommentAsync({ fileConfirmationId, fileId: openCommentFileEntityId, comment }));
            }}
          />
        </div>
        <div className="flex-1 overflow-auto">
          {fileConfirmationFileComments &&
            [...fileConfirmationFileComments]
              .sort((a, b) => (new Date(a.created) > new Date(b.created) ? -1 : 1))
              .map((comment) => {
                return (
                  <div className="border-b" key={comment.id}>
                    <BhCommentCompact
                      comment={comment}
                      deleteCallback={() => {
                        const deletedComment = { ...comment, deleted: true };
                        return dispatch(saveFileConfirmationFileCommentAsync({ fileConfirmationId, fileId: openCommentFileEntityId, comment: deletedComment }));
                      }}
                    />
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationContainerFileComments;
