import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectAllProjectWorkGroups } from "@/app/store/project/projectWorkGroupsSlice";
import BhTabs from "@components/tabs/BhTabs";
import { naturalSortFilesByField } from "@/utilities/sortUtilities";
import { saveWorkGroupToChecklistBaseAuthorityAsync, selectCompanyChecklistBasesWithFinishedVersions, selectWorkgroupToChecklistBases } from "@/app/store/checklist/checklistsSlice";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons/faListCheck";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { IWorkGroupToChecklistBase } from "@/model/checklist/IWorkGroupToChecklistBase";
import ChecklistsAuthoritiesModalSidebarItem from "@components/checklists/settings/projectSettings/checklistsAuthoritiesModal/ChecklistsAuthoritiesModalSidebarItem";
import { faUserGroup } from "@fortawesome/pro-regular-svg-icons/faUserGroup";
import ChecklistsAuthoritiesModalWorkgroupsTab from "@components/checklists/settings/projectSettings/checklistsAuthoritiesModal/ChecklistsAuthoritiesModalWorkgroupsTab";
import ChecklistsAuthoritiesModalChecklistsTab from "@components/checklists/settings/projectSettings/checklistsAuthoritiesModal/ChecklistsAuthoritiesModalChecklistsTab";

interface Props {
  setIsShown: Dispatch<SetStateAction<boolean>>;
}

const ChecklistsAuthoritiesModal: FC<Props> = ({ setIsShown }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const workGroups = useAppSelector(selectAllProjectWorkGroups);
  const checklistBases = useAppSelector(selectCompanyChecklistBasesWithFinishedVersions);
  const workGroupAuthorities = useAppSelector(selectWorkgroupToChecklistBases);
  const workGroupsSorted = workGroups && workGroups.slice().sort((a, b) => naturalSortFilesByField(a, b, "name", false));
  const checklistBasesSorted = checklistBases && checklistBases.slice().sort((a, b) => naturalSortFilesByField(a, b, "type", false));
  const [activeWorkGroup, setActiveWorkGroup] = useState({} as any);
  const [activeChecklistBase, setActiveChecklistBase] = useState({} as any);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (workGroupsSorted && workGroupsSorted.length > 0) {
      setActiveWorkGroup(workGroupsSorted[0]);
    }
    if (checklistBasesSorted && checklistBasesSorted.length > 0) {
      setActiveChecklistBase(checklistBasesSorted[0]);
    }
  }, []);

  const tabs = [
    {
      id: 0,
      header: <div>{t("GLOBAL.WORKGROUPS")}</div>,
      content: (
        <div className="mt-5 h-full overflow-y-auto">
          {workGroupsSorted &&
            workGroupsSorted.map((wg) => (
              <ChecklistsAuthoritiesModalSidebarItem key={wg.id} onClick={() => setActiveWorkGroup(wg)} icon={faUserGroup} label={wg.name} isActive={wg.id === activeWorkGroup.id} />
            ))}
        </div>
      )
    },
    {
      id: 1,
      header: <div>{t("CHECKLIST.TEMPLATES")}</div>,
      content: (
        <div className="mt-5 h-full overflow-y-auto">
          {checklistBasesSorted &&
            checklistBasesSorted.map((cb) => (
              <ChecklistsAuthoritiesModalSidebarItem key={cb.id} onClick={() => setActiveChecklistBase(cb)} icon={faListCheck} label={cb.type} isActive={cb.id === activeChecklistBase.id} />
            ))}
        </div>
      )
    }
  ];

  const activateBaseInWorkGroup = (checklistBase: any, workGroup: any) => {
    const existingAuth = workGroupAuthorities.find((auth) => auth.workGroupId === workGroup.id && auth.checklistBaseId === checklistBase.id);
    const active = selectedTab === 0 ? !checklistBase.selected : !workGroup.selected;
    const authToSave = existingAuth
      ? { ...existingAuth, active: active }
      : ({ workGroupId: workGroup.id, checklistBaseId: checklistBase.id, projectId: projectId, active: active } as IWorkGroupToChecklistBase);
    dispatch(saveWorkGroupToChecklistBaseAuthorityAsync({ projectId: projectId, authority: authToSave }));
  };

  return (
    <BhModal
      isShown={true}
      size={"5xl"}
      setIsShown={setIsShown}
      header={
        <div className="h-7">
          <h2 className="bh-text-deep-ocean mt-1 leading-7">
            <span>{t("CHECKLIST.AUTHORITIES.MODAL.HEADER")}</span>
          </h2>
        </div>
      }
      children={
        <div className="h-150 flex h-full w-full flex-row overflow-hidden">
          <div className="w-1/3 overflow-auto px-8 pt-8 pb-6">
            <BhTabs tabs={tabs} initialActiveTab={0} callback={setSelectedTab} />
          </div>
          <div className="bh-bg-pigeon-20 flex w-2/3 flex-col overflow-auto p-4">
            <div className="bh-bg-white rounded p-6">
              <div className="mb-3 flex flex-row items-center">
                <h3 className="bh-text-deep-ocean my-0">{selectedTab === 0 ? t("CHECKLIST.TEMPLATES") : t("GLOBAL.WORKGROUPS")}</h3>
              </div>
              {selectedTab === 0 && <ChecklistsAuthoritiesModalWorkgroupsTab activeWorkGroup={activeWorkGroup} activateBaseInWorkGroup={activateBaseInWorkGroup} />}
              {selectedTab === 1 && <ChecklistsAuthoritiesModalChecklistsTab activeChecklistBase={activeChecklistBase} activateBaseInWorkGroup={activateBaseInWorkGroup} />}
            </div>
          </div>
        </div>
      }
      footer={<BhModalFooter onCancel={() => setIsShown(false)} cancelButtonText={t("GLOBAL.CLOSE") as string} />}
    />
  );
};

export default ChecklistsAuthoritiesModal;
