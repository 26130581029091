import { bauhubGet } from "@/api/bauhubAPI";
import { EntityId } from "@reduxjs/toolkit";
import { IfcConversionStatusDTO } from "@/model/files/IIfcConversionStatusDTO";

export function fetchProgress(projectId: EntityId, fileEntityId: EntityId): Promise<IfcConversionStatusDTO> {
  return bauhubGet("/bim/project/" + projectId + "/progress/" + fileEntityId + "?st=false");
}

export function fetchFilesStatus(projectId: EntityId, fileEntityIds: Array<EntityId>): Promise<Array<IfcConversionStatusDTO>> {
  return bauhubGet("/bim/project/" + projectId + "/progress/files/" + fileEntityIds.join(",") + "?st=false");
}
