import React, { FC } from "react";
import { IProject } from "@/model/IProject";
import BhModal from "@components/modal/BhModal";
import { Authority, Resource } from "@/model/IUserAuthority";
import BhModalFooter from "@components/modal/BhModalFooter";
import BOCompanyProjectAdministratorsTable from "@components/backoffice/company/projects/BOCompanyProjectAdministratorsTable";

interface Props {
  project: IProject;
  setIsShown: (value: boolean) => void;
}

const BOCompanyProjectAdministratorsModal: FC<Props> = ({ project, setIsShown }) => {
  const projectAdministrators = project.relatedUsers.filter((user) => user.authority === Authority.ADMIN && user.resource === Resource.PROJECT);

  return (
    <BhModal
      size="5xl"
      setIsShown={setIsShown}
      header={
        <h2>
          <span className="bh-text-deep-ocean-60">Project administrators - </span>
          {project.name}
        </h2>
      }
      footer={<BhModalFooter onCancel={() => setIsShown(false)} cancelButtonText="Close" />}
    >
      <div className="h-full overflow-auto p-8">
        <BOCompanyProjectAdministratorsTable projectAdministrators={projectAdministrators} />
      </div>
    </BhModal>
  );
};

export default BOCompanyProjectAdministratorsModal;
