import React, { FC } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import { faArrowDown } from "@fortawesome/pro-regular-svg-icons/faArrowDown";
import { faArrowUp } from "@fortawesome/pro-regular-svg-icons/faArrowUp";
import { selectNextToSignForContainer, selectSignatureToSwapDown, selectSignatureToSwapUp, swapSignInviteAsync } from "@/app/store/signaturesSlice";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import { selectCurrentUser, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { IFileEntity } from "@/model/files/IFileEntity";

interface Props {
  signature: ISignatureHolder;
  containerFileEntity: IFileEntity;
}

const QueueSignatureActionButtons: FC<Props> = ({ signature, containerFileEntity }) => {
  const { t } = useTranslation();
  const nextToSign = useAppSelector(selectNextToSignForContainer(signature.containerUuid));
  const signatureToSwapDown = useAppSelector(selectSignatureToSwapDown(signature));
  const signatureToSwapUp = useAppSelector(selectSignatureToSwapUp(signature));
  const currentUser = useAppSelector(selectCurrentUser);
  const isCurrentUserProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, containerFileEntity.projectId));
  const dispatch = useAppDispatch();

  const isCurrentUserQueueEnabler = containerFileEntity.queueEnabler === currentUser.id;
  const canChangeInvite = isCurrentUserQueueEnabler || isCurrentUserProjectAdmin;

  if (!canChangeInvite) return null;

  const isChangeable = nextToSign && signature.orderNumber > nextToSign.orderNumber;
  const showMoveSignatureUp = isChangeable && signatureToSwapUp;
  const showMoveSignatureDown = isChangeable && signatureToSwapDown;

  const moveSignatureUp = () => {
    if (signatureToSwapUp) dispatch(swapSignInviteAsync({ containerUuid: signature.containerUuid, swapUsername: signature.username, withUsername: signatureToSwapUp.username }));
  };

  const moveSignatureDown = () => {
    if (signatureToSwapDown) dispatch(swapSignInviteAsync({ containerUuid: signature.containerUuid, swapUsername: signature.username, withUsername: signatureToSwapDown.username }));
  };

  return (
    <>
      {showMoveSignatureUp && (
        <BhTooltip body={t("CONTAINER.CHANGE_ORDER")}>
          <BhIconButton icon={faArrowUp} withBackground={true} buttonProps={{ onClick: moveSignatureUp }} />
        </BhTooltip>
      )}
      {showMoveSignatureDown && (
        <BhTooltip body={t("CONTAINER.CHANGE_ORDER")}>
          <BhIconButton icon={faArrowDown} withBackground={true} buttonProps={{ onClick: moveSignatureDown }} />
        </BhTooltip>
      )}
    </>
  );
};

export default QueueSignatureActionButtons;
