import React, { FC } from "react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import { useTranslation } from "react-i18next";
import { fetchChecklistStatisticsExcelExportData } from "@/api/checklist/checklistStatisticsAPI";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { IChecklistBase } from "@/model/checklist/IChecklistBase";
import { imitateExcelDownload } from "@/utilities/downloadUtilities";

interface Props {
  checklistBase: IChecklistBase;
  versionNumber: number;
  isDisabled: boolean;
}

const ChecklistStatisticsExportToExcelButton: FC<Props> = ({ checklistBase, versionNumber, isDisabled }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);

  if (!projectId) return null;

  const exportChecklistStatisticsExcelFile = () => {
    fetchChecklistStatisticsExcelExportData(projectId, checklistBase.id, versionNumber).then((response) => {
      imitateExcelDownload(response, checklistBase.type + "-v" + versionNumber);
    });
  };

  return (
    <BhTextOnlyButton icon={faArrowDownToLine} buttonProps={{ onClick: exportChecklistStatisticsExcelFile, disabled: isDisabled }}>
      {t("CHECKLIST.STATISTICS.XLSX")}
    </BhTextOnlyButton>
  );
};

export default ChecklistStatisticsExportToExcelButton;
