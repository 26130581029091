import React, { FC } from "react";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { ITaskTag } from "@/model/taskBoard/ITaskTag";
import BhTagListContainer from "@components/tags/BhTagListContainer";

interface Props {
  tags: Array<ITaskTag>;
}

const TaskTags: FC<Props> = ({ tags }) => {
  return (
    <BhTagListContainer>
      {tags.map((tag, index) => {
        return <BhTag type={BhTagType.TAG} children={tag.name} key={index} />;
      })}
    </BhTagListContainer>
  );
};

export default TaskTags;
