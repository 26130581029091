import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { IActWork } from "@/model/IActWork";
import { saveActWork } from "@/api/actAPI";
import { RootState } from "@/app/store";
import { BhWorkType } from "@/model/BhWorkType";

const actWorksAdapter = createEntityAdapter<IActWork>({
  sortComparer: (a, b) => (a.id < b.id ? -1 : 1)
});

const actWorksInitialState = actWorksAdapter.getInitialState<{
  status: BhStateStatusType;
}>({
  status: BhStateStatusType.INITIAL
});

export const saveActWorkAsync = createAsyncThunk("actWork/saveActWork", async (actWork: IActWork) => {
  return saveActWork(actWork);
});

const actWorksSlice = createSlice({
  name: "actWorks",
  initialState: actWorksInitialState,
  reducers: {
    actWorkAdded: actWorksAdapter.addOne,
    actWorksAdded: (state, action: PayloadAction<Array<IActWork>>) => {
      state.status = BhStateStatusType.SUCCESS;
      actWorksAdapter.upsertMany(state, action.payload);
    },
    actWorksUpdated: actWorksAdapter.upsertMany,
    actWorksCleared: () => {
      return actWorksInitialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveActWorkAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveActWorkAsync.rejected, (state, action) => {
        state.status = BhStateStatusType.ERROR;
      })
      .addCase(saveActWorkAsync.fulfilled, (state, action: PayloadAction<Array<IActWork>>) => {
        state.status = BhStateStatusType.SUCCESS;
        const updatedActWorks = action.payload;
        actWorksAdapter.upsertMany(state, updatedActWorks);
      });
  }
});

export const { actWorksAdded, actWorksCleared } = actWorksSlice.actions;

export const {
  selectAll: selectAllActWorks,
  selectById: selectActWorkById,
  selectIds: selectActWorkIds,
  selectEntities: selectActWorksEntities
} = actWorksAdapter.getSelectors((state: RootState) => state.actWorks);

export const selectActEntityId = (state: RootState, actId: EntityId) => actId;
export const selectActWorkType = (state: RootState, n: any, type: BhWorkType) => type;
export const selectActWorkEntityId = (state: RootState, actWorkId: EntityId) => actWorkId;

export const selectSortedFirstLevelActWorkIdsForActId = createSelector([selectAllActWorks, selectActEntityId, selectActWorkType], (actWorks, actId, type) => {
  return actWorks
    .filter((aw) => aw.actId === actId && aw.type === type && aw.parentActWorkId == null)
    .sort((a: IActWork, b: IActWork) => (a.contractWorkOrderNumber > b.contractWorkOrderNumber ? 1 : -1))
    .map((aw) => aw.id);
});

export const selectSortedActWorkIdsForParentId = createSelector([selectAllActWorks, selectActEntityId], (actWorks, parentActWorkId) => {
  return actWorks
    .filter((aw) => aw.parentActWorkId === parentActWorkId)
    .sort((a: IActWork, b: IActWork) => (a.contractWorkOrderNumber > b.contractWorkOrderNumber ? 1 : -1))
    .map((aw) => aw.id);
});

export const selectTotalLevelsForActId = createSelector([selectAllActWorks, selectActEntityId, selectActWorkType], (actWorks, actId, type) => {
  let maxLevel = 0;

  function findMaxLevel(work: IActWork, level: number): number {
    if (work.parentActWorkId) {
      const parent = actWorks.find((w) => w.id === work.parentActWorkId);
      return (parent && findMaxLevel(parent, (level += 1))) || 0;
    } else {
      return level;
    }
  }

  actWorks
    .filter((aw) => aw.actId === actId && aw.type === type)
    .forEach((work) => {
      let level = findMaxLevel(work, 0);
      if (level && level > maxLevel) {
        maxLevel = level;
      }
    });

  return maxLevel + 1;
});

export default actWorksSlice.reducer;
