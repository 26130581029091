import React, { Dispatch, FC, SetStateAction, useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenRuler } from "@fortawesome/pro-regular-svg-icons/faPenRuler";
import BhSelectCard from "@components/cards/BhSelectCard";
import { FileTreeType, IFileTree, IFileTreeTemplate } from "@/model/files/IFileTreeTemplate";
import { faFiles } from "@fortawesome/pro-regular-svg-icons/faFiles";
import { INewProjectForm } from "@/model/INewProjectForm";
import { Trans, useTranslation } from "react-i18next";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import BhLargeText from "@components/text/BhLargeText";
import ProjectTreePreviewButton from "@/views/home/company/createProject/newProjectFolderStructure/ProjectTreePreviewButton";
import { EntityId } from "@reduxjs/toolkit";
import directoryFolders from "@img/eelseadistatud-kaustapuud-compressed.png";
import BhSmallText from "@components/text/BhSmallText";

interface Props {
  projectTreeTemplates: Array<IFileTreeTemplate>;
  templateType: FileTreeType;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  setTreePreview: Dispatch<SetStateAction<IFileTree>>;
  property: keyof INewProjectForm;
  onChangeCallback: Function;
  selectedTreeId?: EntityId;
}

const NewProjectProjectTreeList: FC<Props> = ({ projectTreeTemplates, templateType, setModalOpen, setTreePreview, property, onChangeCallback, selectedTreeId }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const { t } = useTranslation();

  const onPreviewButtonClick = useCallback(
    (tree: IFileTree) => {
      setTreePreview(tree);
      setModalOpen(true);
    },
    [projectTreeTemplates]
  );

  const templateTypeIcon = templateType === FileTreeType.DRAWINGS ? faPenRuler : faFiles;
  const noTemplatesFound = projectTreeTemplates === undefined || projectTreeTemplates.length < 0;

  return (
    <div className="mt-10">
      <h3 className="relative w-fit cursor-pointer" onClick={() => setIsCollapsed(!isCollapsed)}>
        <FontAwesomeIcon className="absolute -left-6" icon={isCollapsed ? faCaretRight : faCaretDown} />
        <FontAwesomeIcon className="mx-2" icon={templateTypeIcon} />
        <Trans>{"GLOBAL." + templateType}</Trans>
      </h3>
      <div className="mt-5">
        {!isCollapsed && noTemplatesFound && (
          <div className="flex w-full flex-col">
            <img src={directoryFolders} className="m-8 max-w-[100px] place-self-center" alt="No matches" />
            <BhSmallText classes="text-center">{t("NEW_PROJECT.PROJECT_TREES." + templateType + ".NO_MATCHES")}</BhSmallText>
            <BhSmallText classes="text-center font-bold">{t("NEW_PROJECT.NO_MATCHES_INFO")}</BhSmallText>
          </div>
        )}
        {!isCollapsed &&
          projectTreeTemplates?.map((template) => {
            return (
              <BhSelectCard
                key={template.id}
                children={<BhLargeText>{template.name}</BhLargeText>}
                trailingComponent={<ProjectTreePreviewButton tree={template.tree} onClick={onPreviewButtonClick} />}
                value={{ [property]: template.id }}
                isSelected={template.id === selectedTreeId}
                isAnySelected={selectedTreeId !== undefined}
                onSelect={onChangeCallback}
                size="sm"
              />
            );
          })}
      </div>
    </div>
  );
};

export default React.memo(NewProjectProjectTreeList);
