import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChecklistRoundStatus, IChecklistRound } from "@/model/checklist/IChecklistRound";
import { faUnlockKeyhole } from "@fortawesome/pro-regular-svg-icons/faUnlockKeyhole";
import { faLockKeyhole as faLockKeyholeSolid } from "@fortawesome/pro-solid-svg-icons/faLockKeyhole";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { useNavigate } from "react-router-dom";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import ChecklistRoundProgressBar from "@components/checklists/ChecklistRoundProgressBar";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { classNames } from "@/utilities/jsUtilities";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  checklistRounds: Array<IChecklistRound>;
  currentRoundId: EntityId;
  currentRoundNumber: number;
}

const ChecklistHeaderVersionDropdown: FC<Props> = ({ checklistRounds, currentRoundId, currentRoundNumber }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const projectId = useAppSelector(selectCurrentProjectId);
  const checklistRound = checklistRounds.find((round) => round.id === currentRoundId);
  const complete = checklistRound && checklistRound.status === ChecklistRoundStatus.COMPLETE;
  const moreThanOneRoundExists = checklistRounds.length > 1;

  const changeRound = (round: IChecklistRound) => {
    if (round.id === currentRoundId) {
      return;
    }
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklists/${round.checklistId}/round/${round.id}`);
  };

  const dropdownChecklistRounds = checklistRounds
    .slice()
    .sort((a, b) => (a.roundNumber < b.roundNumber ? 1 : -1))
    .map((round) => {
      const checkpointsExisting = round.checkpointValue.total > 0;
      return {
        text: (
          <div className={classNames("flex w-full flex-row items-center", checkpointsExisting && "w-96")}>
            <div className="flex w-8 flex-row items-center">{round.id === currentRoundId && <FontAwesomeIcon icon={faCheck} />}</div>
            <div className="whitespace-nowrap">{t("CHECKLIST.ROUND") + " " + round.roundNumber}</div>
            {checkpointsExisting && (
              <div className="ml-4 w-full">
                <ChecklistRoundProgressBar checkpointValue={round.checkpointValue} classes={"rounded-full"} />
              </div>
            )}
          </div>
        ),
        function: () => changeRound(round)
      };
    });

  return (
    <BhDropdown
      button={
        <div className="hover:bh-bg-smoke mr-8 flex cursor-pointer flex-row items-center rounded p-1.5">
          <FontAwesomeIcon icon={complete ? faLockKeyholeSolid : faUnlockKeyhole} className="mr-2" />
          <div className="mr-2">{"V" + currentRoundNumber}</div>
          {moreThanOneRoundExists && <FontAwesomeIcon icon={faCaretDown} />}
        </div>
      }
      disabled={!moreThanOneRoundExists}
      menu={<BhDropdownMenu values={dropdownChecklistRounds} textProperty={"text"} type={BhDropdownTypeEnum.STRING} widthClass={"w-fit"} />}
    />
  );
};

export default ChecklistHeaderVersionDropdown;
