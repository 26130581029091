import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { RootState } from "@/app/store";
import { IFileConfirmation } from "@/model/confirmations/IFileConfirmation";
import {
  deleteFileConfirmation,
  fetchAllProjectConfirmations,
  fetchAllUserConfirmationsForProject,
  fetchFileConfirmation,
  fetchFileConfirmationWithFiles,
  fetchUserPendingFileConfirmationsCount,
  saveDecisions,
  saveNewFileConfirmation
} from "@/api/fileConfirmationAPI";
import { toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { fileConfirmationFilesAdded } from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import { FileConfirmationDecisionType, IFileConfirmationDecisionV2, IFileConfirmationV2DecisionDTO } from "@/model/confirmations/IFileConfirmationDecisionV2";
import { IFileConfirmationFile } from "@/model/confirmations/IFileConfirmationFile";
import { filesSentToConfirmation, filesUpdatedAndAdded } from "@/app/store/filesSlice";
import { IConfirmationsSort, initialConfirmationsSort } from "@/model/confirmations/IConfirmationsSort";
import { getUniqueValues } from "@/utilities/jsUtilities";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { FileConfirmationStatusFilter, FileConfirmationUserRoleFilterEnum, IFileConfirmationsFilter, initialFileConfirmationsFilter } from "@/model/confirmations/IFileConfirmationsFilter";
import { naturalSortByField } from "@/utilities/sortUtilities";
import { IFileEntity } from "@/model/files/IFileEntity";

const fileConfirmationsAdapter = createEntityAdapter<IFileConfirmation>();

const fileConfirmationsInitialState = fileConfirmationsAdapter.getInitialState<{
  status: BhStateStatusType;
  currentDecisions: Array<IFileConfirmationDecisionV2>;
  sort: IConfirmationsSort;
  filter: IFileConfirmationsFilter;
  userBubbles: Array<ISimpleValidUserAuthority>;
  needsApprovalCount?: number;
}>({
  status: BhStateStatusType.INITIAL,
  currentDecisions: [],
  sort: initialConfirmationsSort,
  filter: initialFileConfirmationsFilter,
  userBubbles: []
});

export const fetchFileConfirmationAsync = createAsyncThunk("fileConfirmation/fetchFileConfirmation", async (fileConfirmationId: EntityId, { dispatch }) => {
  const result = await fetchFileConfirmation(fileConfirmationId);
  dispatch(fileConfirmationFilesAdded(result.files));
  const confirmationFileEntities = result.files.map((fcf) => {
    return { id: fcf.fileEntityId, uuid: fcf.uuid, parentFileEntityId: fcf.parentFileEntityId, contentType: fcf.contentType, name: fcf.name, projectId: fcf.projectId } as IFileEntity;
  });
  dispatch(filesUpdatedAndAdded(confirmationFileEntities));
  dispatch(decisionsAdded(result.files.map((fcf) => ({ id: fcf.decisionId, status: FileConfirmationDecisionType.PENDING } as IFileConfirmationDecisionV2))));
  return { ...result, files: [], totalFiles: result.files.length };
});

export const fetchFileConfirmationWithFilesAsync = createAsyncThunk("fileConfirmation/fetchFileConfirmationWithFiles", async (fileConfirmationId: EntityId, { dispatch }) => {
  const result = await fetchFileConfirmationWithFiles(fileConfirmationId);
  dispatch(fileConfirmationFilesAdded(result.files));
  const confirmationFileEntities = result.files.map((fcf) => {
    return { id: fcf.fileEntityId, uuid: fcf.uuid, parentFileEntityId: fcf.parentFileEntityId, contentType: fcf.contentType, name: fcf.name, projectId: fcf.projectId } as IFileEntity;
  });
  dispatch(filesUpdatedAndAdded(confirmationFileEntities));
  return { ...result, files: [], totalFiles: result.files.length };
});

export const fetchAllUserConfirmationsForProjectAsync = createAsyncThunk("fileConfirmation/fetchAllUserConfirmations", async (projectId: EntityId) => {
  return fetchAllUserConfirmationsForProject(projectId);
});

export const fetchAllProjectConfirmationsAsync = createAsyncThunk("fileConfirmation/fetchAllProjectConfirmations", async (projectId: EntityId) => {
  return fetchAllProjectConfirmations(projectId);
});

export const saveDecisionsAsync = createAsyncThunk("fileConfirmation/saveDecisions", async (_, { getState, dispatch }) => {
  const state: RootState = getState() as RootState;
  const dto = state.fileConfirmations.currentDecisions.map((d) => ({ decisionId: d.id, status: d.status } as IFileConfirmationV2DecisionDTO));
  const result = await saveDecisions(dto);
  dispatch(fetchUserPendingFileConfirmationsCountAsync(state.project.current.id));
  return result;
});

export const deleteFileConfirmationAsync = createAsyncThunk("fileConfirmation/deleteFileConfirmation", async (fileConfirmationId: EntityId) => {
  return deleteFileConfirmation(fileConfirmationId);
});

export const saveNewFileConfirmationAsync = createAsyncThunk("fileConfirmation/saveNew", async (fileConfirmation: IFileConfirmation, { dispatch }) => {
  const result = await saveNewFileConfirmation(fileConfirmation);
  dispatch(filesSentToConfirmation({ fileIds: result.fileRelations?.map((fr) => fr.fileEntityId), userCount: result.userRelations?.length }));
  dispatch(toggleProjectModalsOpen({ modal: "directoryConfirmationModal" }));
  dispatch(fetchUserPendingFileConfirmationsCountAsync(result.projectId));
  return result;
});

export const fetchUserPendingFileConfirmationsCountAsync = createAsyncThunk("fileConfirmation/fetchUserPendingFileConfirmationsCount", async (projectId: EntityId) => {
  return fetchUserPendingFileConfirmationsCount(projectId);
});

const fileConfirmationsSlice = createSlice({
  name: "fileConfirmations",
  initialState: fileConfirmationsInitialState,
  reducers: {
    confirmationsSortSet: (state, action: PayloadAction<IConfirmationsSort>) => {
      state.sort = action.payload;
    },
    confirmationsFilterSet: (state, action: PayloadAction<IFileConfirmationsFilter>) => {
      state.filter = action.payload;
    },
    decisionsAdded: (state, action: PayloadAction<Array<IFileConfirmationDecisionV2>>) => {
      state.currentDecisions = action.payload;
    },
    decisionsReset: (state) => {
      state.currentDecisions = [];
    },
    decisionStatusChanged: (state, action: PayloadAction<IFileConfirmationDecisionV2>) => {
      const existingDecision = state.currentDecisions.find((d) => d.id === action.payload.id);
      if (existingDecision) {
        existingDecision.status = action.payload.status;
      }
    },
    decisionStatusChangedForFiles: (state, action: PayloadAction<{ value: FileConfirmationDecisionType; files: Array<IFileConfirmationFile> }>) => {
      action.payload.files.forEach((fcf) => {
        const decision = state.currentDecisions.find((d) => d.id === fcf.decisionId);
        if (decision) decision.status = action.payload.value;
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFileConfirmationAsync.fulfilled, (state, action) => {
        fileConfirmationsAdapter.upsertOne(state, action.payload);
      })
      .addCase(fetchFileConfirmationWithFilesAsync.fulfilled, (state, action) => {
        fileConfirmationsAdapter.upsertOne(state, action.payload);
      })
      .addCase(saveNewFileConfirmationAsync.fulfilled, (state, action) => {
        fileConfirmationsAdapter.upsertOne(state, action.payload);
      })
      .addCase(fetchAllUserConfirmationsForProjectAsync.fulfilled, (state, action) => {
        const confirmations = action.payload.confirmations.map((confirmation) => ({ ...confirmation, relatedToCurrentUser: true }));
        state.userBubbles = getUniqueValues([...state.userBubbles, ...action.payload.users], "userEntityId");
        fileConfirmationsAdapter.upsertMany(state, confirmations);
        state.status = BhStateStatusType.SUCCESS;
      })
      .addCase(fetchAllProjectConfirmationsAsync.fulfilled, (state, action) => {
        const confirmations = action.payload.confirmations.map((confirmation) => ({ ...confirmation, relatedToCurrentUser: false }));
        state.userBubbles = getUniqueValues([...state.userBubbles, ...action.payload.users], "userEntityId");
        fileConfirmationsAdapter.upsertMany(state, confirmations);
        state.status = BhStateStatusType.SUCCESS;
      })
      .addCase(deleteFileConfirmationAsync.fulfilled, (state, action) => {
        fileConfirmationsAdapter.removeOne(state, action.payload.id);
      })
      .addCase(saveDecisionsAsync.fulfilled, (state, action) => {
        state.currentDecisions = [];
      })
      .addCase(fetchUserPendingFileConfirmationsCountAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.INITIAL;
        state.needsApprovalCount = action.payload.value;
      });
  }
});

export const { confirmationsSortSet, confirmationsFilterSet, decisionsAdded, decisionsReset, decisionStatusChanged, decisionStatusChangedForFiles } = fileConfirmationsSlice.actions;

export const {
  selectAll: selectAllFileConfirmations,
  selectById: selectFileConfirmationById,
  selectIds: selectFileConfirmationsIds
} = fileConfirmationsAdapter.getSelectors((state: RootState) => state.fileConfirmations);

export const selectConfirmationsStatus = (state: RootState) => state.fileConfirmations.status;
export const selectNeedsApprovalCount = (state: RootState) => state.fileConfirmations.needsApprovalCount;
export const selectConfirmationsSort = (state: RootState) => state.fileConfirmations.sort;
export const selectConfirmationsFilter = (state: RootState) => state.fileConfirmations.filter;
export const selectConfirmationUserBubbles = (state: RootState) => state.fileConfirmations.userBubbles;
export const selectProjectId = (state: RootState, projectId: EntityId) => projectId;

export const selectFileConfirmationsForProjectSorted = createSelector([selectAllFileConfirmations, selectProjectId, selectConfirmationsSort], (fileConfirmations, projectId, sort) => {
  return fileConfirmations.filter((fileConfirmation) => fileConfirmation.projectId === projectId).sort((a, b) => naturalSortByField(a, b, sort.property, sort.reversed));
});
export const selectFileConfirmationsForProjectSortedFiltered = createSelector([selectFileConfirmationsForProjectSorted, selectConfirmationsFilter], (fileConfirmations, filter) => {
  return fileConfirmations.filter((fc) => {
    const statusFilter =
      filter.status === FileConfirmationStatusFilter.ALL ||
      (filter.status === FileConfirmationStatusFilter.IN_PROGRESS && fc.overallFilesPending > 0) ||
      (filter.status === FileConfirmationStatusFilter.FINISHED && fc.overallFilesPending < 1);
    const nameFilter = filter.name?.length > 0 ? fc.description.toLowerCase().includes(filter.name.toLowerCase()) : true;
    const usersFilter =
      filter.users?.length > 0
        ? filter.userRoles?.length > 0
          ? filter.userRoles.some((role) => role.name === FileConfirmationUserRoleFilterEnum.CREATOR && filter.users.some((user) => user.userEntityId === fc.starterUserEntityId)) ||
            filter.userRoles.some(
              (role) => role.name === FileConfirmationUserRoleFilterEnum.NEXT_CONFIRMER && filter.users.some((user) => fc.nextInLineUserIds?.some((userId) => userId === user.userEntityId))
            ) ||
            filter.userRoles.some((role) => role.name === FileConfirmationUserRoleFilterEnum.PARTICIPANT && filter.users.some((user) => fc.userIds.some((userId) => userId === user.userEntityId)))
          : filter.users.some((user) => user.userEntityId === fc.starterUserEntityId || user.userEntityId === fc.nextUserToConfirm || fc.userIds.some((userId) => userId === user.userEntityId))
        : true;

    return statusFilter && nameFilter && usersFilter;
  });
});

export const selectAllFileConfirmationIdsForProjectSortedFiltered = createSelector([selectFileConfirmationsForProjectSortedFiltered], (fileConfirmations) => {
  return fileConfirmations.map((fc) => fc.id);
});

export const selectFileConfirmationsRelatedToUserForProject = createSelector([selectFileConfirmationsForProjectSortedFiltered], (fileConfirmations) => {
  return fileConfirmations.filter((fc) => fc.relatedToCurrentUser);
});

export const selectUserFileConfirmationIdsForProjectSortedFiltered = createSelector([selectFileConfirmationsRelatedToUserForProject], (fileConfirmations) => {
  return fileConfirmations.filter((fileConfirmation) => fileConfirmation.userFilesPending < 1).map((fc) => fc.id);
});
export const selectUserNeedsApprovalFileConfirmationIdsForProjectSortedFiltered = createSelector([selectFileConfirmationsForProjectSortedFiltered], (fileConfirmations) => {
  return fileConfirmations.filter((fileConfirmation) => fileConfirmation.userFilesPending > 0).map((fc) => fc.id);
});

export const selectUserNeedsApprovalFileConfirmationIdsForProjectUnfiltered = createSelector([selectFileConfirmationsForProjectSorted], (fileConfirmations) => {
  return fileConfirmations.filter((fileConfirmation) => fileConfirmation.userFilesPending > 0).map((fc) => fc.id);
});

export const selectUserNeedsApprovalFileConfirmationIdsForProjectCount = createSelector(
  [selectConfirmationsStatus, selectNeedsApprovalCount, selectUserNeedsApprovalFileConfirmationIdsForProjectUnfiltered],
  (status, count, fileConfirmationsIds) => {
    return status === BhStateStatusType.INITIAL ? count : fileConfirmationsIds.length;
  }
);

export const selectFileConfirmationUsers = createSelector([selectFileConfirmationById], (fileConfirmation) => {
  return fileConfirmation?.users?.map((u) => u.userEntity) || [];
});

export const selectCurrentDecisions = (state: RootState) => state.fileConfirmations.currentDecisions;

export default fileConfirmationsSlice.reducer;
