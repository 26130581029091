import React, { Dispatch, useEffect } from "react";
import { Viewer } from "@xeokit/xeokit-sdk";
import BhIconButton from "@components/buttons/BhIconButton";
import { useTranslation } from "react-i18next";
import BhTooltip from "@components/BhTooltip";
import { faMapLocationDot } from "@fortawesome/pro-regular-svg-icons/faMapLocationDot";
import BhIconWithLabelButton from "@components/buttons/BhIconWithLabelButton";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUserLanguageForTranslation } from "@/app/store/userSlice";

interface Props {
  toolActive: boolean;
  setToolActive: Dispatch<boolean>;
  isMobile?: boolean;
  disabled?: boolean;
  viewer?: Viewer;
}

const XeokitFloorPlanTool = React.memo<Props>(({ toolActive, setToolActive, isMobile, disabled, viewer }) => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguageForTranslation);

  useEffect(() => {
    if (!viewer) return;

    viewer.on("reset", () => {
      setToolActive(false);
    });
  }, [viewer]);

  const toggleFloorPlanMode = () => {
    if (!viewer) return;

    const newFirstPersonActive = !toolActive;
    setToolActive(newFirstPersonActive);
  };

  if (isMobile) {
    return (
      <BhIconWithLabelButton icon={faMapLocationDot} buttonProps={{ onClick: toggleFloorPlanMode, disabled: disabled }} isActive={toolActive}>
        {t("BIM.TOOL.FLOOR_PLAN.SHORT", { lng: currentUserLanguage })}
      </BhIconWithLabelButton>
    );
  }

  return (
    <BhTooltip body={t("BIM.TOOL.FLOOR_PLAN")}>
      <BhIconButton icon={faMapLocationDot} buttonProps={{ onClick: toggleFloorPlanMode, disabled: disabled }} isActive={toolActive} />
    </BhTooltip>
  );
});

export default XeokitFloorPlanTool;
