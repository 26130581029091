import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveRemoveUserFromWorkGroupAsync, setRemoveUserModalShown } from "@/app/store/project/projectWorkGroupsSlice";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhModal from "@components/modal/BhModal";
import { IPartyPrivilegeListItemDTO } from "@/model/party/IPartyPrivilegeListItemDTO";
import { IUserRemovalInfoResponse } from "@/model/invites/IUserRemovalInfoResponse";
import { EntityId } from "@reduxjs/toolkit";
import { getUserFullName } from "@/model/IUser";
import { fetchUserRemovalInfo } from "@/api/partyAPI";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";

interface Props {
  partyId: EntityId;
  listItem: IPartyPrivilegeListItemDTO;
}

const PartyRemoveUserModal: FC<Props> = ({ partyId, listItem }) => {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const [removalInfo, setRemovalInfo] = useState({ signInvites: [], fileConfirmations: [] } as IUserRemovalInfoResponse);
  const anyPending = removalInfo.fileConfirmations.length > 0 || removalInfo.signInvites.length > 0;
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);

  useEffect(() => {
    if (listItem.userEntityId && !listItem.inviteId) {
      fetchUserRemovalInfo(projectId, partyId, listItem.userEntityId).then((info) => {
        setRemovalInfo(info);
      });
    }
  }, []);

  const handleSubmit = () => {
    setSubmitDisabled(true);
    dispatch(saveRemoveUserFromWorkGroupAsync({ workGroupId: partyId, dto: listItem })).then(() => {
      setSubmitDisabled(false);
    });
  };

  const closeModal = () => {
    dispatch(setRemoveUserModalShown(false));
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={closeModal}
      size="5xl"
      header={<h2>Eemalda kasutaja</h2>}
      children={
        <div className="p-8">
          {anyPending && (
            <div className="mb-6">
              Kasutajal <strong>{getUserFullName(listItem)}</strong> on pooleli <strong>{removalInfo.signInvites.length + " allkirjastamist"}</strong> ning
              <strong>{" " + removalInfo.fileConfirmations.length + " kinnitamist"}</strong>.
            </div>
          )}
          <div>Kas oled kindel, et soovid kasutaja eemaldada? Juhul kui kasutaja pole enam läbi ühegi rolli projektiga seotud, kaob ka tema ligipääs allkirjastamistele ning kinnitamistele.</div>
        </div>
      }
      footer={
        <div>
          <BhTextOnlyButton buttonProps={{ onClick: closeModal }}>Sulge</BhTextOnlyButton>
          <BhPrimaryButton buttonProps={{ onClick: handleSubmit, disabled: isSubmitDisabled }}>Eemalda</BhPrimaryButton>
        </div>
      }
    />
  );
};

export default PartyRemoveUserModal;
