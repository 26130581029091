import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { formatDateTime } from "@/utilities/dateUtility";

interface Props {
  fileDate: Date;
  fullName: string;
  classes?: string;
}

const BhFileCardDateWithName: FC<Props> = ({ fileDate, fullName, classes }) => {
  return (
    <div className={classNames(classes, "flex flex-col whitespace-nowrap")}>
      <div>
        <span className="leading-4">{formatDateTime(fileDate)}</span>
      </div>
      <div className="bh-text-color-light-gray flex flex-row items-center text-xs">
        <span className="truncate">{fullName}</span>
      </div>
    </div>
  );
};

export default BhFileCardDateWithName;
