import React, { FC, useEffect, useState } from "react";
import { ICompany, INewCompanyDTO } from "@/model/ICompany";
import { BauhubPlan } from "@/model/IProject";
import BOHeader from "@/views/home/backoffice/views/BOHeader";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boFetchAllCompaniesAsync, boSaveNewCompanyAsync, boSelectAllCompaniesFilteredAndSorted } from "@/app/store/backofficeSlice";
import BOCompaniesFilterRow from "@/views/home/backoffice/views/companies/BOCompaniesFilterRow";
import BONewCompanyModal from "@/views/home/backoffice/views/companies/BONewCompanyModal";
import BOCompaniesListTable from "@/views/home/backoffice/views/companies/BOCompaniesListTable";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { useNavigate } from "react-router-dom";
import { ICompanyInfo } from "@/model/iCompanyInfo";

const BOCompanies: FC = () => {
  const companies = useAppSelector(boSelectAllCompaniesFilteredAndSorted);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [newCompany, setNewCompany] = useState<INewCompanyDTO>({ tier: BauhubPlan.PRO } as INewCompanyDTO);

  useEffect(() => {
    dispatch(boFetchAllCompaniesAsync());
  }, []);

  const saveNewCompany = () => {
    if (newCompany) {
      const companyInfo = newCompany?.companyInfo ? newCompany?.companyInfo : ({} as ICompanyInfo);
      const company = { ...newCompany, companyInfo, users: [] };
      dispatch(boSaveNewCompanyAsync(company)).then((action) => {
        const savedCompany = action.payload as ICompany;
        setNewCompany({} as INewCompanyDTO);
        setModalOpen(false);
        navigate("/bo/company/" + savedCompany.id + "/general");
      });
    }
  };

  const handleNewCompanyFieldChange = (changedValue: Record<string, string>) => {
    setNewCompany({ ...newCompany, ...changedValue } as INewCompanyDTO);
  };

  const isNewCompanySaveButtonDisabled = !newCompany?.name || !newCompany?.tier;

  return (
    <BhScrollableBody
      header={
        <div className="px-16 sm:px-4">
          <BOHeader />
          <div className="flex flex-col">
            <BOCompaniesFilterRow onNewCompanyClickCallback={setModalOpen} />
            <p className="bh-text-pigeon mb-2 -mt-2 text-sm">{companies.length} Companies</p>
          </div>
        </div>
      }
    >
      <div className="px-16 sm:px-4">
        <BOCompaniesListTable />
      </div>
      {modalOpen && (
        <BONewCompanyModal
          newCompany={newCompany as INewCompanyDTO}
          setIsShown={() => setModalOpen(!modalOpen)}
          onSave={saveNewCompany}
          isSaveDisabled={isNewCompanySaveButtonDisabled}
          onFieldChangeCallback={handleNewCompanyFieldChange}
        />
      )}
    </BhScrollableBody>
  );
};

export default BOCompanies;
