import React, { FC } from "react";
import { IUser } from "@/model/IUser";
import BhDropdownMenuUserListItem from "@components/dropdown/BhDropdownMenuUserListItem";
import { classNames } from "@/utilities/jsUtilities";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";

interface Props {
  values?: Array<ISimpleValidUserAuthority>;
  onSelect?: Function;
  customEmail?: string;
  selectedIndex?: number;
}

const BhUserDropdownMenu: FC<Props> = ({ values, onSelect, customEmail, selectedIndex }) => {
  return (
    <div className={classNames("bh-shadow bh-bg-white inline-block flex w-full flex-col rounded py-3 text-left align-top")}>
      <div className="max-h-40 overflow-auto">
        <ul>
          {values &&
            values.map((value, index) => {
              return (
                <li key={value.id || "customEmail"} className={classNames(index === selectedIndex && "bh-bg-smoke", "hover:bh-bg-smoke cursor-pointer")} onClick={() => onSelect && onSelect(value)}>
                  {value.userExists === false ? (
                    <span className="block truncate py-1.5 px-4">
                      Lisa e-mail <b>"{customEmail}"</b>
                    </span>
                  ) : (
                    <BhDropdownMenuUserListItem value={value as unknown as IUser} />
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default BhUserDropdownMenu;
