import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/pro-regular-svg-icons/faCircleXmark";

const BhStatusCellDenied: FC = () => {
  return (
    <div className="bh-bg-error-red-10 flex h-14 w-12 flex-row items-center justify-center">
      <FontAwesomeIcon icon={faCircleXmark} className="bh-text-error-red h-4 w-4" />
    </div>
  );
};

export default BhStatusCellDenied;
