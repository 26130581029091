import React, { FC } from "react";
import { BhSectionMessageError } from "@components/sectionMessage/BhSectionMessages";
import PublicNavbar from "@/views/home/navbar/PublicNavbar";
import { useTranslation } from "react-i18next";

interface Props {
  error: string;
}

const PublicShareboxErrorContainer: FC<Props> = ({ error }) => {
  const { t } = useTranslation();

  const translations = [
    { error: "SHAREBOX.SHORT_UUID", heading: "SHAREBOX.BROKEN_LINK", description: "SHAREBOX.SHORT_UUID" },
    { error: "SHAREBOX.NO_SHAREBOX", heading: "SHAREBOX.NO_SHAREBOX", description: "SHAREBOX.NO_SHAREBOX.DESC" },
    { error: "SHAREBOX.NO_URL", heading: "SHAREBOX.NO_SHAREBOX", description: "SHAREBOX.NO_SHAREBOX.DESC" },
    { error: "SHAREBOX.ACCESS_REVOKED", heading: "SHAREBOX.ACCESS_REVOKED", description: "SHAREBOX.ACCESS_REVOKED.DESC" },
    { error: "SHAREBOX.EXPIRED", heading: "SHAREBOX.EXPIRED", description: "SHAREBOX.EXPIRED.DESC" },
    { error: "SHAREBOX.DELETED", heading: "SHAREBOX.DELETED", description: "SHAREBOX.DELETED.DESC" }
  ];
  const currentTranslation = translations.find((elem) => elem.error === error);

  if (!error || !currentTranslation) {
    return null;
  }

  return (
    <div className="flex h-screen w-screen flex-col overflow-hidden">
      <div>
        <PublicNavbar />
      </div>
      <div className="flex flex-1 flex-col overflow-auto">
        <div className="mx-auto flex w-full max-w-[1366px] flex-col p-6">
          <BhSectionMessageError>
            <div className="text-18px l-h-24px font-bold">{t(currentTranslation.heading)}</div>
            <div className="mt-2 font-normal">{t(currentTranslation.description)}</div>
          </BhSectionMessageError>
        </div>
      </div>
    </div>
  );
};

export default PublicShareboxErrorContainer;
