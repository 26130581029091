import React, { FC } from "react";
import { IProjectMessage } from "@/model/IProjectMessage";
import { IFileEntityComment } from "@/model/files/IFileEntityComment";
import BhCommentCompact from "@components/comment/BhCommentCompact";

interface Props {
  message: IProjectMessage;
  editCallback?: Function;
  deleteCallback?: Function;
}

const BhProjectMessageCompact: FC<Props> = ({ message, editCallback, deleteCallback }) => {
  const comment = { ...message, authorFullName: message.userFullName } as unknown as IFileEntityComment;

  return <BhCommentCompact comment={comment} editCallback={editCallback} deleteCallback={deleteCallback} />;
};

export default BhProjectMessageCompact;
