import React from "react";
import { math, Viewer } from "@xeokit/xeokit-sdk";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCropSimple } from "@fortawesome/pro-solid-svg-icons/faCropSimple";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import XeokitMobileMoreToolsModalElement from "@/views/home/project/detail/xeokit/mobile/modals/XeokitMobileMoreToolsModalElement";
import { useTranslation } from "react-i18next";
import BhTooltip from "@components/BhTooltip";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUserLanguageForTranslation } from "@/app/store/userSlice";
import { objectsWithoutFurthest } from "@/views/home/project/detail/xeokit/helpers/pointDistanceHelper";

interface Props {
  viewer?: Viewer;
  isMobile?: boolean;
}

const XeokitFitObjectsTool = React.memo<Props>(({ viewer, isMobile }) => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguageForTranslation);

  const fitObjects = () => {
    if (!viewer) return;
    const scene = viewer.scene;
    const selectedObjectIds = scene.selectedObjectIds;
    const visibleObjectIds = scene.visibleObjectIds;
    const objectIds = selectedObjectIds.length > 0 ? selectedObjectIds : visibleObjectIds.length > 500 ? objectsWithoutFurthest(viewer, visibleObjectIds) : visibleObjectIds;

    const aabb = scene.getAABB(objectIds);

    viewer.cameraFlight.flyTo({
      aabb: aabb
    });
    const tempVec3 = math.vec3();
    // @ts-ignore
    viewer.cameraControl.pivotPos = math.getAABB3Center(aabb, tempVec3);
  };

  if (isMobile) {
    return (
      <XeokitMobileMoreToolsModalElement icon={<FontAwesomeIcon icon={faCropSimple} />} callback={fitObjects}>
        {t("BIM.TOOL.FOCUS", { lng: currentUserLanguage })}
      </XeokitMobileMoreToolsModalElement>
    );
  }

  return (
    <BhTooltip body={t("BIM.TOOL.FOCUS")}>
      <BhIconButton icon={faCropSimple} buttonProps={{ onClick: fitObjects }} />
    </BhTooltip>
  );
});

export default XeokitFitObjectsTool;
