import React, { FC, useCallback, useEffect, useState } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId, toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { resetNewTask, resetOpenedTaskInfo, saveTaskStatusAsync, selectOpenedTaskDisabled, setTaskModalsOpen } from "@/app/store/tasksSlice";
import { ITask, ITaskStatusChangeDTO, TaskStatus } from "@/model/taskBoard/ITask";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons/faFileExport";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faArchive as farArchive } from "@fortawesome/pro-regular-svg-icons/faArchive";
import { faArchive as fasArchive } from "@fortawesome/pro-solid-svg-icons/faArchive";
import TaskModals from "@/views/home/project/detail/task/modals/TaskModals";
import { ITaskModalsOpen } from "@/model/taskBoard/ITaskModals";
import { isTaskPersonal } from "@/utilities/taskBoardUtil";
import { selectCurrentUser, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import { taskModalClosedWithTaskIdSet } from "@/app/store/fileAnnotationsSlice";
import { copyToClipboard } from "@/utilities/jsUtilities";
import NewTaskModalHeader from "@components/task/modal/headers/NewTaskModalHeader";
import DedicatedTaskViewHeader from "@components/task/modal/headers/DedicatedTaskViewHeader";
import DefaultTaskModalHeader from "@components/task/modal/headers/DefaultTaskModalHeader";
import DisabledTaskModalHeader from "@components/task/modal/headers/DisabledTaskModalHeader";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  task: ITask;
  saveTaskCallback: Function;
  isDedicatedView?: boolean;
}

const TaskModalHeader: FC<Props> = ({ task, saveTaskCallback, isDedicatedView }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const projectId = useAppSelector(selectCurrentProjectId);
  const isDisabled = useAppSelector(selectOpenedTaskDisabled);
  const isCurrentUserProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const isArchived = task.status === TaskStatus.ARCHIVED;
  const isNewTask = !task.id;
  const isPersonalTask = isTaskPersonal(task, currentUser);
  const isChecklistTask = task.checklistRowId ? true : false;
  const isFormTask = task.formTaskInfo && task.formTaskInfo.formId;
  const isDefaultTask = !isNewTask && !isDedicatedView && !isDisabled && !isChecklistTask;
  const isCurrentUserAdminOrTaskReporter = isCurrentUserProjectAdmin || task.reporter === currentUser.id;
  const showDefaultTaskActions = isCurrentUserAdminOrTaskReporter && !isFormTask;
  const enableTaskLocking = !isPersonalTask && isCurrentUserAdminOrTaskReporter;
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);

  const escFunction = useCallback((event: any) => {
    if (event.key === "Escape" && !isDedicatedView) {
      closeTaskModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const closeTaskModal = () => {
    dispatch(taskModalClosedWithTaskIdSet(task?.id || -1));
    dispatch(toggleProjectModalsOpen({ modal: "projectTaskModal" }));
    dispatch(resetOpenedTaskInfo());
    dispatch(resetNewTask());
  };

  const toggleLock = () => {
    const changedObject = { locked: !task.locked };
    saveTaskCallback(changedObject);
  };

  const archiveTask = (task: ITask) => {
    const taskStatusChangeDTO = { taskId: task.id, status: task.status === TaskStatus.ARCHIVED ? TaskStatus.TODO : TaskStatus.ARCHIVED } as ITaskStatusChangeDTO;
    dispatch(saveTaskStatusAsync({ taskStatusChangeDTO: taskStatusChangeDTO, projectId: projectId }));
  };

  const openTaskModal = (modalName: string) => {
    dispatch(setTaskModalsOpen({ modal: modalName as keyof ITaskModalsOpen }));
  };

  const copyTaskLink = () => {
    const taskLink = isPersonalTask
      ? ConfigSingleton.getInstance().getConfig().REACT_APP_BASE_URL + "/project/" + projectId + "/personal/task/" + task.id
      : ConfigSingleton.getInstance().getConfig().REACT_APP_BASE_URL + "/project/" + projectId + "/task/" + task.taskIdentifier;
    copyToClipboard(taskLink, "TOAST.LINK_COPIED");
  };

  const actionButtons = showDefaultTaskActions
    ? [
        <BhTooltip body={t("TASK.MOVE_TASK")} key={0}>
          <BhIconButton icon={faFileExport} buttonProps={{ classes: "mx-1.25", onClick: () => openTaskModal("taskMoveModal") }} />
        </BhTooltip>,
        <BhTooltip body={t("TASK.COPY_TASK")} key={1}>
          <BhIconButton icon={faCopy} buttonProps={{ classes: "mx-1.25", onClick: () => openTaskModal("taskCopyModal") }} />
        </BhTooltip>,
        <BhTooltip body={t("TASK.ARCHIVE")} key={2}>
          <BhIconButton icon={isArchived ? fasArchive : farArchive} buttonProps={{ classes: "mx-1.25", onClick: () => archiveTask(task) }} />
        </BhTooltip>,
        <BhTooltip body={t("GLOBAL.DELETE")} key={3}>
          <BhIconButton icon={faTrash} buttonProps={{ classes: "ml-1.25", onClick: () => setDeleteConfirmationModalOpen(true) }} />
        </BhTooltip>
      ]
    : [];

  return (
    <div className="bh-bg-raised-white h-56px min-h-56px bh-border-pigeon-20 border-b pl-8 pr-6">
      {isNewTask && <NewTaskModalHeader task={task} toggleLock={toggleLock} closeTaskModal={closeTaskModal} enableTaskLocking={enableTaskLocking} />}
      {isDedicatedView && <DedicatedTaskViewHeader task={task} toggleLock={toggleLock} actionButtons={actionButtons} enableTaskLocking={enableTaskLocking} />}
      {(isDisabled || isChecklistTask) && <DisabledTaskModalHeader closeTaskModal={closeTaskModal} />}
      {isDefaultTask && (
        <DefaultTaskModalHeader task={task} closeTaskModal={closeTaskModal} copyTaskLink={copyTaskLink} toggleLock={toggleLock} actionButtons={actionButtons} enableTaskLocking={enableTaskLocking} />
      )}
      <TaskModals task={task} deleteConfirmationModalOpen={deleteConfirmationModalOpen} setDeleteConfirmationModalOpen={setDeleteConfirmationModalOpen} isDedicatedView={isDedicatedView} />
    </div>
  );
};

export default TaskModalHeader;
