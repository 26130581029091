import React, { FC } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import BhLightSeparatorVertical from "@components/separator/BhLightSeparatorVertical";
import { EntityId } from "@reduxjs/toolkit";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { ButtonSize } from "@components/buttons/IBhButtonProps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import BhDeclineButton from "@components/buttons/BhDeclineButton";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import {
  fileConfirmationFilesSelectedReset,
  selectFileConfirmationFilesForConfirmationWithDecisionId,
  selectNumberOfFilesSelectedInConfirmation
} from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import { decisionStatusChangedForFiles } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import { FileConfirmationDecisionType } from "@/model/confirmations/IFileConfirmationDecisionV2";

interface Props {
  confirmationId: EntityId;
}

const ConfirmationActionsBasket: FC<Props> = ({ confirmationId }) => {
  const numberOfFilesSelectedInConfirmation = useAppSelector((state) => selectNumberOfFilesSelectedInConfirmation(state, confirmationId));
  const fileConfirmationFiles = useAppSelector((state) => selectFileConfirmationFilesForConfirmationWithDecisionId(state, confirmationId));
  const dispatch = useAppDispatch();

  const selectedFiles = fileConfirmationFiles.filter((f) => f.selected);

  return (
    <div className="bh-bg-white bh-shadow max-w-660px fixed bottom-6 left-1/2 m-auto -ml-48 flex w-auto flex-col overflow-hidden rounded-xl">
      <div className="flex h-16 flex-row">
        <div className="bh-bg-bauhub-green-120 flex items-center">
          <div className="bh-text-white p-7 font-bold">{numberOfFilesSelectedInConfirmation}</div>
        </div>
        <div className="flex items-center px-10 py-6">
          <BhPrimaryButton
            buttonProps={{
              size: ButtonSize.XL,
              classes: "w-32",
              onClick: () => {
                dispatch(decisionStatusChangedForFiles({ value: FileConfirmationDecisionType.CONFIRMED, files: selectedFiles }));
                dispatch(fileConfirmationFilesSelectedReset(confirmationId));
              }
            }}
          >
            <FontAwesomeIcon icon={faCheck} size={"lg"} />
          </BhPrimaryButton>
          <BhDeclineButton
            buttonProps={{
              size: ButtonSize.XL,
              classes: "w-32",
              onClick: () => {
                dispatch(decisionStatusChangedForFiles({ value: FileConfirmationDecisionType.DECLINED, files: selectedFiles }));
                dispatch(fileConfirmationFilesSelectedReset(confirmationId));
              }
            }}
          >
            <FontAwesomeIcon icon={faTimes} size={"lg"} />
          </BhDeclineButton>
        </div>
        <BhLightSeparatorVertical />
        <div className="flex items-center p-2">
          <BhIconButton
            icon={faXmark}
            buttonProps={{
              onClick: () => {
                dispatch(fileConfirmationFilesSelectedReset(confirmationId));
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationActionsBasket;
