import { FileEntityType, IFileEntity, IFolderFileEntity } from "@/model/files/IFileEntity";
import { IFileTreeDTO } from "@/model/files/IFileTreeDTO";
import { EntityId } from "@reduxjs/toolkit";

export function findFileNameForContainer(files: Array<IFileEntity>) {
  if (files.length === 1) {
    if (files[0].type === FileEntityType.FORM) {
      return files[0].name;
    } else {
      const list = files[0].name.split(".");
      const withoutExtension = list.slice(0, -1);
      return withoutExtension.join(".");
    }
  } else {
    return "Untitled";
  }
}

export function createFileTree(listOfFiles: Array<IFileTreeDTO>, selectedFiles: Array<EntityId>) {
  let rootList = [] as Array<IFileTreeDTO>;
  listOfFiles.forEach((file) => {
    file.children = [] as Array<IFileTreeDTO>;
    listOfFiles.forEach((innerFile) => {
      if (innerFile.parentFileEntityId === file.id) {
        file.children.push(innerFile);
        file.open = true;
      }
      if (selectedFiles.indexOf(file.id) > -1) {
        file.selected = true;
      }
    });
    if (file.parentFileEntityId == null) {
      rootList.push(file);
    }
  });
  return rootList;
}

export function findFileFromTree(files: Array<IFileTreeDTO>, fileId: EntityId): IFileTreeDTO | null {
  let result = null;
  for (const child of files) {
    if (child.id === fileId) {
      result = child;
    }
    if (!result && child.children) {
      result = findFileFromTree(child.children, fileId);
    }
  }
  return result;
}

export function findAllChildrenForFileEntity(files: Array<IFileEntity>, parentId: EntityId, childrenArray: Array<EntityId>): void {
  const children = files.filter((fileEntity) => fileEntity.parentFileEntityId === parentId);

  for (const child of children) {
    findAllChildrenForFileEntity(files, child.id, childrenArray);
  }

  childrenArray.push(...children.map((fileEntity) => fileEntity.id));
}

export function findAllChildrenForFileEntities(files: Array<IFileEntity>, parentIds: Array<EntityId>, childrenArray: Array<EntityId>): void {
  for (const parentId of parentIds) {
    findAllChildrenForFileEntity(files, parentId, childrenArray);
  }
  childrenArray.push(...parentIds);
}

export function isAnyParentDeleted(fileTree: IFolderFileEntity): boolean {
  return (
    fileTree.isDeleted ||
    fileTree.subDirs.some(function (dir) {
      return dir.type === FileEntityType.DIR && (dir.isDeleted || isAnyParentDeleted(dir));
    })
  );
}
