import { IVerificationCodeResult } from "@/model/IVerificationCodeResult";
import { ISmartIdVerificationResult } from "@/model/ISmartIdVerificationResult";
import { IMobileIdVerificationResult } from "@/model/IMobileIdVerificationResult";
import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { EntityId } from "@reduxjs/toolkit";
import { IChallengeDTO } from "@/views/home/profile/ProfileTabs/profileAuthSettings/IChallengeDTO";
import { ICardVerificationResult } from "@/model/ICardVerificationResult";
import { ICardAuthToken } from "@/views/home/profile/ProfileTabs/profileAuthSettings/ICardAuthToken";

export function generateHash(userEntityId: EntityId): Promise<IChallengeDTO> {
  return bauhubGet("/identification/ID/generate/hash/" + userEntityId);
}

export function identifyIdCard(dto: ICardAuthToken): Promise<ICardVerificationResult> {
  return bauhubPost("/identification/card/identify", dto);
}

export function sendPhoneNumberVerificationCode(phoneNumber: string): Promise<void> {
  return bauhubPost("/verification/mobile/code/send", { phoneNumber: phoneNumber });
}

export function verifyPhoneNumberVerificationCode(phoneNumber: string, verificationCode: string): Promise<void> {
  return bauhubPost("/verification/mobile/code/check", {
    phoneNumber: phoneNumber,
    verificationCode: verificationCode
  }).catch((error) => {
    if (error.message === "ERROR_VERIFICATION_CODE_NOT_MATCH") {
      throw new Error("Wrong code!");
    } else if (error.message === "ERROR_VERIFICATION_SESSION_NOT_FOUND") {
      throw new Error("Session not found!");
    }
    throw new Error("Error");
  });
}

export function sendMobileIdIdentificationCode(userId: EntityId): Promise<IVerificationCodeResult> {
  return bauhubGet("/identification/MOBILE_ID/generate/hash/" + userId.toString());
}

export function sendSmartIdIdentificationCode(userId: EntityId): Promise<IVerificationCodeResult> {
  return bauhubGet("/identification/SMART_ID/generate/hash/" + userId.toString());
}

export function identifyMobileId(phoneNumber: string, identityCode: string, userId: EntityId): Promise<IMobileIdVerificationResult> {
  return bauhubPost("/identification/mobile/identify", {
    identityCode: identityCode,
    phoneNumber: phoneNumber,
    userEntityId: userId
  });
}

export function identifySmartId(countryCode: string, identityCode: string, userId: EntityId): Promise<ISmartIdVerificationResult> {
  return bauhubPost("/identification/smart/identify", {
    identityCode: identityCode,
    country: countryCode,
    userEntityId: userId
  });
}
