import React, { FC, startTransition, useCallback, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCalendarDay } from "@fortawesome/pro-regular-svg-icons/faCalendarDay";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhDatePicker from "@components/input/BhDatePicker";
import ReactTimeAgo from "react-time-ago";

interface Props {
  property: string;
  initialValue?: any;
  onChangeCallback?: Function;
  disabled?: boolean;
  returnISOString?: boolean;
  dateSelected?: boolean;
  disablePastDates?: boolean;
}

const BhIconButtonDatepicker: FC<Props> = ({ initialValue, property, onChangeCallback, disabled, returnISOString, dateSelected, disablePastDates }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date>(initialValue || new Date());

  const onChange = useCallback(
    (selectedDate: any) => {
      const inputDate = selectedDate[property];
      startTransition(() => {
        if (inputDate === initialValue) return;
        const changedValue = { [property]: returnISOString ? inputDate.toISOString() : inputDate };
        onChangeCallback && onChangeCallback(changedValue);
        setStartDate(inputDate);
        setIsOpen(false);
      });
    },
    [onChangeCallback]
  );

  return (
    <div className="relative z-30">
      {dateSelected ? (
        <BhTextOnlyButton buttonProps={{ onClick: () => setIsOpen(!isOpen), disabled: disabled }}>
          <ReactTimeAgo date={startDate} />
        </BhTextOnlyButton>
      ) : (
        <div onClick={(e: any) => e.stopPropagation()}>
          <BhIconButton icon={faCalendarDay} buttonProps={{ onClick: () => setIsOpen(!isOpen), disabled: disabled }} />
        </div>
      )}
      {isOpen && (
        <div className="absolute" onClick={(e: any) => e.stopPropagation()}>
          <BhDatePicker property={property} inline={true} onChangeCallback={onChange} />
        </div>
      )}
    </div>
  );
};

export default BhIconButtonDatepicker;
