import React, { FC } from "react";
import BhCardContainer from "@components/cards/BhCardContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareFromSquare } from "@fortawesome/pro-regular-svg-icons";

const ShareboxListSkeleton: FC = () => {
  return (
    <>
      <BhCardContainer classes="md:px-2 p-4 h-[86px] pr-3">
        <div className="min-w-32px bh-text-deep-ocean-40 w-10 shrink-0 sm:hidden">
          <FontAwesomeIcon icon={faShareFromSquare} className="h-4" />
        </div>
        <div className="flex w-full flex-col">
          <div className="bh-bg-pigeon-50 mt-1.5 mb-1 h-6 w-1/2 animate-pulse rounded" />
          <div className="bh-bg-pigeon-50 h-3.5 w-72 animate-pulse rounded" />
        </div>
        <div className="w-full" />
      </BhCardContainer>
      <BhCardContainer classes="md:px-2 p-4 pr-3 h-[86px]">
        <div className="min-w-32px bh-text-deep-ocean-40 w-10 shrink-0 sm:hidden">
          <FontAwesomeIcon icon={faShareFromSquare} className="h-4" />
        </div>
        <div className="flex w-full flex-col">
          <div className="bh-bg-pigeon-50 mt-1.5 mb-1 h-6 w-1/2 animate-pulse rounded" />
          <div className="bh-bg-pigeon-50 h-3.5 w-72 animate-pulse rounded" />
        </div>
        <div className="w-full" />
      </BhCardContainer>
      <BhCardContainer classes="md:px-2 p-4 pr-3 h-[86px]">
        <div className="min-w-32px bh-text-deep-ocean-40 w-10 shrink-0 sm:hidden">
          <FontAwesomeIcon icon={faShareFromSquare} className="h-4" />
        </div>
        <div className="flex w-full flex-col">
          <div className="bh-bg-pigeon-50 mt-1.5 mb-1 h-6 w-1/2 animate-pulse rounded" />
          <div className="bh-bg-pigeon-50 h-3.5 w-72 animate-pulse rounded" />
        </div>
        <div className="w-full" />
      </BhCardContainer>
    </>
  );
};

export default ShareboxListSkeleton;
