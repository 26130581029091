import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import BhInputWithPrefixDropdown from "@components/input/BhInputWithPrefixDropdown";
import { identifySmartId, sendSmartIdIdentificationCode } from "@/api/verificationAPI";
import { fetchCurrentUserAsync, selectCurrentUser } from "@/app/store/userSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { toggleUserProfileModalsOpen } from "@/app/store/userPreferenceSlice";
import BhSeparatorMarginless from "@components/separator/BhSeparatorMarginless";
import BhModalFooter from "@components/modal/BhModalFooter";

interface Props {
  setStatus: Function;
  setVerification: Function;
}

const PersonalizationSmartIdTab: FC<Props> = ({ setStatus, setVerification }) => {
  const { t } = useTranslation();
  const [identityCodePrefix, setIdentityCodePrefix] = useState("EE");
  const [identityCode, setIdentityCode] = useState("");
  const currentUser = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();

  const startPersonalization = () => {
    const identityCodeWithoutSpaces = identityCode.replace(/\s/g, "");
    sendSmartIdIdentificationCode(currentUser.id)
      .then((result) => {
        setVerification(result.verificationCode);
        setStatus("verificationInput");
        identifySmartId(identityCodePrefix, identityCodeWithoutSpaces, currentUser.id)
          .then(async (result) => {
            if (result.status === "OK") {
              dispatch(fetchCurrentUserAsync());
              setStatus("success");
            }
          })
          .catch(() => {
            dispatch(toggleUserProfileModalsOpen({ modal: "profileAuthSettingsPersonalizationModal" }));
          });
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <form id="mobileSignForm" className="mx-20 mb-12 flex flex-col">
        <div className="mt-6">
          <BhInputWithPrefixDropdown
            initialPrefix={identityCodePrefix}
            initialValue={identityCode}
            dropdownElements={["EE", "LV", "LT"]}
            property="code"
            onChangeCallback={(value: { prefix: string; code: string }) => {
              if (value.prefix !== undefined) setIdentityCodePrefix(value.prefix);
              if (value.code !== undefined) setIdentityCode(value.code);
            }}
            onEnterCallback={startPersonalization}
            label={`${t("USER.PROFILE.SSN")}`}
            debounceLength={50}
          />
        </div>
      </form>
      <BhSeparatorMarginless />
      <div className="flex flex-row items-center justify-end p-4">
        <BhModalFooter
          cancelButtonText={t("GLOBAL.CANCEL") as string}
          onCancel={() => dispatch(toggleUserProfileModalsOpen({ modal: "profileAuthSettingsPersonalizationModal" }))}
          confirmButtonText={t("IDENTIFICATION.MODAL.IDENTIFY") as string}
          onConfirm={startPersonalization}
        />
      </div>
    </div>
  );
};

export default PersonalizationSmartIdTab;
