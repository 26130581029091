import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { useNavigate } from "react-router-dom";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import UserNotificationListItemContainer from "@/views/home/notifications/UserNotificationListItemContainer";
import { fetchLatestProjectNotificationsAsync, selectLatestUserNotifications, setAllNotificationsSeenAsync } from "@/app/store/userNotificationsSlice";
import { useTranslation } from "react-i18next";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {}

const UserNotificationsDropdown: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const projectId = useAppSelector(selectCurrentProjectId);
  const notifications = useAppSelector((state) => selectLatestUserNotifications(state, projectId));
  const [isOpen, setIsOpen] = useState<boolean>(true); //TODO: selle võiks kuidagi BhDropdown komponendis lahendada
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchLatestProjectNotificationsAsync(projectId));
  }, []);

  if (isOpen) {
    return (
      <div className="bh-bg-raised-white bh-shadow w-[32rem] rounded px-6 py-4">
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between pb-5">
            <div className="bh-text-deep-ocean bh-table-header-text font-bold">{t("NOTIFICATION.TITLE")}</div>
            <BhTextOnlyButton
              icon={faEye}
              buttonProps={{
                onClick: () => {
                  dispatch(setAllNotificationsSeenAsync(projectId));
                }
              }}
            >
              {t("NOTIFICATION.SET_ALL_SEEN")}
            </BhTextOnlyButton>
          </div>
          <div className="max-h-96 flex-auto flex-col overflow-y-scroll">
            {notifications.map((notification: any) => {
              return <UserNotificationListItemContainer notification={notification} key={notification.id} callBack={setIsOpen} />;
            })}
          </div>
          <div className="py-2 pl-7">
            <BhTextOnlyButton
              buttonProps={{
                onClick: () => {
                  setIsOpen(false);
                  navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/user/notifications`);
                }
              }}
            >
              {t("PROJECT.NAVBAR.NOTIFICATION.SEE_ALL")}
            </BhTextOnlyButton>
          </div>
        </div>
      </div>
    );
  }
  return <div></div>;
};

export default UserNotificationsDropdown;
