import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectAnyFetchRequestExists } from "@/app/store/globalSlice";
import { classNames } from "@/utilities/jsUtilities";

const NavbarLoadingIndicator: FC = () => {
  const fetchInProgress = useAppSelector(selectAnyFetchRequestExists);

  return (
    <div className="fixed top-0 left-0 h-1.5 w-full">
      <div className={classNames(fetchInProgress ? "bh-bg-mint-140b" : "bg-transparent", "bh-animate-loading-strip h-1.5 w-full transition-colors duration-100")} />
    </div>
  );
};

export default NavbarLoadingIndicator;
