import React, { FC, useEffect } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { fetchFilesAsync, selectAllFilesForParentFileEntityId, selectFilesSort } from "@/app/store/filesSlice";
import { naturalSortFilesByField } from "@/utilities/sortUtilities";
import { FileEntityType } from "@/model/files/IFileEntity";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  fileEntityId: EntityId;
  dirId: EntityId;
}

const FormContainerHeaderNavigateOtherForms: FC<Props> = ({ fileEntityId, dirId }) => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const currentDirFiles = useAppSelector((state) => selectAllFilesForParentFileEntityId(state, dirId));
  const sort = useAppSelector(selectFilesSort);
  const sortedCurrentDirForms = currentDirFiles.filter((file) => file.type === FileEntityType.FORM).sort((a, b) => naturalSortFilesByField(a, b, sort.property, sort.reversed));
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchFilesAsync(dirId));
  }, []);

  if (sortedCurrentDirForms.length < 2) {
    return null;
  }

  const currentFormIndex = sortedCurrentDirForms.findIndex((dirForm) => dirForm.id === fileEntityId);
  const previousForm = sortedCurrentDirForms[currentFormIndex - 1];
  const nextForm = sortedCurrentDirForms[currentFormIndex + 1];

  return (
    <div className="text-12px l-h-16px bh-bg-smoke bh-border-pigeon-20 flex flex-row justify-between border-b px-2 py-1 font-bold">
      <div>
        {previousForm && (
          <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/dir/${previousForm.parentFileEntityId}/form/${previousForm.id}`} className="hover:bh-text-dark-jungle">
            <FontAwesomeIcon icon={faChevronLeft} className="pr-1" />
            {previousForm.name}
          </Link>
        )}
      </div>
      <div>
        {nextForm && (
          <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/dir/${nextForm.parentFileEntityId}/form/${nextForm.id}`} className="hover:bh-text-dark-jungle">
            {nextForm.name}
            <FontAwesomeIcon icon={faChevronRight} className="pl-1" />
          </Link>
        )}
      </div>
    </div>
  );

  return null;
};

export default FormContainerHeaderNavigateOtherForms;
