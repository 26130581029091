import React, { FC } from "react";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { Link } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

const BauhubAdminHome: FC = () => {
  return (
    <BhScrollableBody>
      <div className="flex flex-col pt-2 pb-14">
        <h1>Admin</h1>
        <div className="flex flex-col gap-y-2">
          <div>
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/admin/companies/list`}>Companies</Link>
          </div>
          <div>
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/admin/users`}>Users</Link>
          </div>
          <div>
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/admin/backend`}>Backend</Link>
          </div>
          <div>
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/admin/template`}>Template developer™</Link>
          </div>
          <div>
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/admin/analytics`}>Analytics</Link>
          </div>
          <div>
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/admin/statistics`}>Statistics</Link>
          </div>
          <div>
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/admin/asyncjobs`}>Asyncjobs</Link>
          </div>
          <div>
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/admin/invoices`}>Invoices</Link>
          </div>
          <div>
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/admin/clients`}>Clients</Link>
          </div>
          <div>
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/admin/trials`}>Trials</Link>
          </div>
          <div>
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/admin/sessions`}>Sessions</Link>
          </div>
          <div>
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/admin/events`}>Events</Link>
          </div>
          <div>
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/admin/killswitch`}>Killswitch</Link>
          </div>
          <div>
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/admin/logs`}>BauDrive logs</Link>
          </div>
          <div>
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/`}>Homepage</Link>
          </div>
        </div>
      </div>
    </BhScrollableBody>
  );
};

export default BauhubAdminHome;
