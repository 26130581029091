import React, { useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { resetFileDirectoryModalState, selectUndoRevisionModalFileEntity, undoFileRevisionAsync } from "@/app/store/filesSlice";
import BhRadioGroup from "@components/input/BhRadioGroup";
import BhRadioInput from "@components/input/BhRadioInput";
import BhModalFooter from "@components/modal/BhModalFooter";
import { fetchFileRevisions } from "@/api/fileAPI";
import { IFileEntity } from "@/model/files/IFileEntity";
import { useTranslation } from "react-i18next";

export enum RevisionUndoType {
  UNDO = "UNDO",
  UNDO_AND_PRESERVE = "UNDO_AND_PRESERVE"
}

const UndoRevisionModal = () => {
  const { t } = useTranslation();
  const [undoType, setUndoType] = useState<RevisionUndoType>(RevisionUndoType.UNDO);
  const [latestRevName, setLatestRevName] = useState<String>("");
  const fileEntity = useAppSelector(selectUndoRevisionModalFileEntity);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setUndoType(RevisionUndoType.UNDO);
    if (fileEntity) {
      fetchFileRevisions(fileEntity.id).then((res: Array<IFileEntity>) => {
        if (res.length > 0) {
          res = res.sort((a, b) => {
            return a.revision > b.revision ? -1 : 1;
          });
          setLatestRevName(res[0].name);
        }
      });
    }
  }, []);

  const closeModal = () => {
    dispatch(resetFileDirectoryModalState());
  };
  const onConfirm = () => {
    if (fileEntity && fileEntity.id) {
      dispatch(undoFileRevisionAsync({ fileId: fileEntity.id, type: undoType })).then(() => {
        dispatch(resetFileDirectoryModalState());
      });
    }
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={() => closeModal()}
      header={<h2>{t("MODAL.REVISION.CONFIRMATION.TITLE")}</h2>}
      size="4xl"
      children={
        <div className="mx-20 my-14 pb-48">
          {fileEntity && fileEntity.id && latestRevName && (
            <div>
              <div>{t("REVISION.REVERT_MODAL_TEXT", { fileName: fileEntity.name, latestRevName: latestRevName })}</div>
              <BhRadioGroup>
                <BhRadioInput property="type" onChange={() => setUndoType(RevisionUndoType.UNDO)} checked={undoType === RevisionUndoType.UNDO} label={t("FILE.RESTORE") as string} />
                <BhRadioInput
                  property="type"
                  onChange={() => setUndoType(RevisionUndoType.UNDO_AND_PRESERVE)}
                  checked={undoType === RevisionUndoType.UNDO_AND_PRESERVE}
                  label={t("REVISION.MODAL.RESTORE_AND_PRESERVE") as string}
                />
              </BhRadioGroup>
            </div>
          )}
        </div>
      }
      footer={<BhModalFooter onCancel={() => closeModal()} onConfirm={onConfirm} />}
    />
  );
};

export default UndoRevisionModal;
