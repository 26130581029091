import React, { FC, useEffect } from "react";
import { useWindowSize } from "@/utilities/hooks/useWindowSize";
import { selectSidebarCollapsed, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";

const ProjectResizer: FC = () => {
  const { width } = useWindowSize();
  const sidebarCollapsed = useAppSelector(selectSidebarCollapsed);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (width && width < 900 && !sidebarCollapsed) {
      dispatch(setSidebarCollapsed(true));
    }
  }, [width]);

  return null;
};

export default ProjectResizer;
