import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import UploadAndAsyncJobModal from "@/views/home/project/projectModals/UploadAndAsyncJobModal";
import { selectUploadModalOpen } from "@/app/store/uploadSlice";

const CompanyModals: FC = () => {
  const uploadModalOpen = useAppSelector(selectUploadModalOpen);

  return <>{uploadModalOpen && <UploadAndAsyncJobModal />}</>;
};

export default CompanyModals;
