import React from "react";
import { selectCurrentProject } from "@/app/store/project/projectSlice";
import { useAppSelector } from "@/app/hooks";
import { ProjectStatus } from "@/model/IProject";
import { Trans } from "react-i18next";
import { formatDate } from "@/utilities/dateUtility";
import BhLightSeparator from "@components/separator/BhLightSeparator";
import BhInputLabel from "@components/input/BhInputLabel";
import { ProjectInvoiceType } from "@/model/IBillingInfo";

const ProjectBillingInfoContainer = () => {
  const project = useAppSelector(selectCurrentProject);

  if (project.billingSetupDone && project.status === ProjectStatus.IN_PROGRESS) {
    return (
      <>
        <div className="mt-10 mb-6">
          <BhLightSeparator />
        </div>
        <div className="flex flex-row">
          <div className="basis-1/4 px-1">
            <div>
              <BhInputLabel>
                <Trans>PROJECT.BILLING.MODEL</Trans>
              </BhInputLabel>
            </div>
            <div className="px-0.5">{project.trialProject && !project.confirmed ? <Trans>PROJECT.BILLING.TRIAL_PERIOD</Trans> : <Trans>{"PROJECT.BILLING." + project.billingModel}</Trans>}</div>
          </div>
          {project.billing?.type !== ProjectInvoiceType.FREE && (!project.trialProject || project.confirmed) && (
            <>
              <div className="basis-2/4 px-1">
                <div>
                  <BhInputLabel>{project.trialProject ? <Trans>PROJECT.BILLING.TRIAL_END</Trans> : <Trans>PROJECT.BILLING.RENEWAL_DATE</Trans>}</BhInputLabel>
                </div>
                <div className="px-0.5">{formatDate(project.billingDate)}</div>
              </div>
              <div className="basis-1/4 px-1">
                <div>
                  <BhInputLabel>
                    <Trans>PROJECT.BILLING.CURRENT_PRICE</Trans>
                  </BhInputLabel>
                </div>
                <div className="px-0.5">{project.price + "€"}</div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }

  return null;
};

export default ProjectBillingInfoContainer;
