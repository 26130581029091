import React, { FC } from "react";
import SearchDropdownTaskResults from "@/features/searchWithDropdown/SearchDropdownTaskResults";
import SearchDropdownShareboxResults from "@/features/searchWithDropdown/SearchDropdownShareboxResults";
import { useAppSelector } from "@/app/hooks";
import { selectHasAnyFileResultsInSearch, selectHasAnyShareboxResultsInSearch, selectHasAnyTaskResultsInSearch, selectHasAnyUserResultsInSearch } from "@/app/store/searchSlice";
import SearchDropdownUserResults from "@/features/searchWithDropdown/SearchDropdownUserResults";
import SearchDropdownFileResults from "@/features/searchWithDropdown/SearchDropdownFileResults";
import BhPigeon20Separator from "@components/separator/BhPigeon20Separator";
import { useTranslation } from "react-i18next";

interface Props {
  setIsOpen: Function;
}

const SearchWithDropdownPopover: FC<Props> = ({ setIsOpen }) => {
  const { t } = useTranslation();
  const hasAnyFileResults = useAppSelector(selectHasAnyFileResultsInSearch);
  const hasAnyUserResults = useAppSelector(selectHasAnyUserResultsInSearch);
  const hasAnyTaskResults = useAppSelector(selectHasAnyTaskResultsInSearch);
  const hasAnyShareboxResults = useAppSelector(selectHasAnyShareboxResultsInSearch);

  const hasNoResults = !hasAnyFileResults && !hasAnyUserResults && !hasAnyTaskResults && !hasAnyShareboxResults;

  return (
    <div className="absolute top-9 z-20 mt-1 w-full">
      <div className="max-h-80-vh bh-shadow min-w-385px pb-7.5 bh-bg-raised-white inline-block flex flex-col overflow-auto rounded pt-6 text-left align-top">
        {hasAnyFileResults && (
          <>
            <SearchDropdownFileResults />
            {(hasAnyUserResults || hasAnyTaskResults || hasAnyShareboxResults) && <BhPigeon20Separator />}
          </>
        )}
        {hasAnyUserResults && (
          <>
            <SearchDropdownUserResults />
            {(hasAnyTaskResults || hasAnyShareboxResults) && <BhPigeon20Separator />}
          </>
        )}
        {hasAnyTaskResults && (
          <>
            <SearchDropdownTaskResults setIsOpen={setIsOpen} />
            {hasAnyShareboxResults && <BhPigeon20Separator />}
          </>
        )}
        {hasAnyShareboxResults && <SearchDropdownShareboxResults />}
        {hasNoResults && <p className="font bh-text-deep-ocean-80 -my-1.5 text-center text-lg">{t("GLOBAL.NO_RESULTS")}</p>}
      </div>
    </div>
  );
};

export default SearchWithDropdownPopover;
