import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { EntityId } from "@reduxjs/toolkit";
import { IMaruSafPerson } from "@/model/maru/IMaruSafPerson";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { IProject } from "@/model/IProject";
import { IMaruClient } from "@/model/maru/IMaruClient";
import { IMaruClientPerson } from "@/model/maru/IMaruClientPerson";
import { IMaruValuation } from "@/model/maru/IMaruValuation";
import { IMaruProject } from "@/model/maru/IMaruProject";
import { IMaruProjectInfoDTO } from "@/model/maru/IMaruProjectInfoDTO";

export function fetchUsersForMaruProject(projectId: EntityId, searchString: string): Promise<Array<IMaruSafPerson>> {
  return bauhubGet("/mr/project/" + projectId + "/persons", { searchString: searchString });
}

export function updateMaruAuthority(projectId: EntityId, authority: ISimpleValidUserAuthority): Promise<ISimpleValidUserAuthority> {
  return bauhubPost("/mr/project/" + projectId + "/grant/auth", authority);
}

export function fetchMaruAuthorities(projectId: EntityId): Promise<Array<ISimpleValidUserAuthority>> {
  return bauhubGet("/mr/project/" + projectId + "/auths");
}

export function refreshMaruProjectName(projectId: EntityId): Promise<IProject> {
  return bauhubPost("/project/" + projectId + "/name/refresh");
}

export function fetchClientsForMaruProject(projectId: EntityId, searchString: string): Promise<Array<IMaruClient>> {
  return bauhubGet("/mr/project/" + projectId + "/clients", { searchString: searchString });
}

export function fetchPersonsForMaruClient(projectId: EntityId, clientId: string): Promise<Array<IMaruClientPerson>> {
  return bauhubGet("/mr/project/" + projectId + "/client/" + clientId + "/persons");
}

export function fetchMaruPrefilledValuation(workGroupId: EntityId): Promise<IMaruValuation> {
  return bauhubGet("/mr/valuation/prefill/" + workGroupId);
}

export function saveMaruValuation(workGroupId: EntityId, maruValuation: IMaruValuation): Promise<IMaruValuation> {
  return bauhubPost("/mr/valuation/prefill/" + workGroupId, maruValuation);
}

export function fetchMaruProjectsForCompany(companyId: EntityId, searchString: string): Promise<IMaruProject> {
  return bauhubGet("/mr/company/" + companyId + "/projects", { searchString: searchString });
}

export function fetchMaruPersonsForCompany(companyId: EntityId, searchString: string): Promise<Array<IMaruSafPerson>> {
  return bauhubGet("/mr/company/" + companyId + "/persons", { searchString: searchString });
}

export function fetchMaruProjectInfo(projectId: EntityId): Promise<IMaruProjectInfoDTO> {
  return bauhubGet("/maru/" + projectId + "/dashboard/all");
}

export function createMaruDashBoards(): Promise<IMaruProjectInfoDTO> {
  return bauhubGet("/admin/maru/dashboards/create");
}
