import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { removeFormBaseVersionRowAsync, saveNewFormBaseVersionRowAsync, selectAllFormBaseVersionRowsSorted, selectBaseRowsLoadingStatus } from "@/app/store/form/formBaseRowsSlice";
import FormBaseRow from "@components/form/base/body/FormBaseRow";
import { IFormBaseVersionRow } from "@/model/form/IFormBaseVersionRow";
import { IFormBaseVersionRowField } from "@/model/form/IFormBaseVersionRowField";
import { selectCompanyFormBaseById } from "@/app/store/form/formBasesSlice";
import { FormBaseVersionStatus } from "@/model/form/IFormBaseVersion";
import FormBaseRowAddButton from "@components/form/base/body/FormBaseRowAddButton";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";

interface Props {
  companyId: EntityId;
  formBaseId: EntityId;
  formBaseVersionId: EntityId;
  projectId?: EntityId;
}

const FormBaseRows: FC<Props> = ({ companyId, formBaseId, formBaseVersionId, projectId }) => {
  const dispatch = useAppDispatch();
  const baseRowsLoadingStatus = useAppSelector(selectBaseRowsLoadingStatus);
  const baseVersionRows = useAppSelector(selectAllFormBaseVersionRowsSorted);
  const formBase = useAppSelector((state) => selectCompanyFormBaseById(state, formBaseId));
  const formBaseVersion = formBase && formBase.formBaseVersions && formBase.formBaseVersions.find((fbv) => fbv.id === formBaseVersionId);
  const isFinalVersion = formBaseVersion && formBaseVersion.status === FormBaseVersionStatus.FINAL;

  const saveNewRow = (fields: Array<IFormBaseVersionRowField>, previousRow?: IFormBaseVersionRow) => {
    const newFormBaseVersionRow = {
      formBaseVersionId: formBaseVersionId,
      orderNumber: previousRow ? previousRow.orderNumber + 1 : 1,
      fields: fields
    } as IFormBaseVersionRow;
    dispatch(saveNewFormBaseVersionRowAsync({ companyId: companyId, formBaseId: formBaseId, formBaseVersionRow: newFormBaseVersionRow }));
  };

  const removeRow = (row: IFormBaseVersionRow) => {
    dispatch(removeFormBaseVersionRowAsync({ companyId: companyId, formBaseId: formBaseId, formBaseVersionRow: row }));
  };

  return (
    <div className="flex w-full flex-col pt-2">
      {!isFinalVersion && (
        <FormBaseRowAddButton
          formBaseVersionId={formBaseVersionId}
          saveNewRowCallback={saveNewRow}
          dropdownOpen={baseRowsLoadingStatus !== BhStateStatusType.INITIAL && baseRowsLoadingStatus !== BhStateStatusType.PENDING && baseVersionRows.length === 0}
        />
      )}
      {baseVersionRows && baseVersionRows.length > 0 && (
        <div>
          {baseVersionRows.map((baseVersionRow, index) => {
            return (
              <FormBaseRow
                formBaseVersionId={formBaseVersionId}
                formBaseId={formBaseId}
                baseVersionRow={baseVersionRow}
                companyId={companyId}
                key={index}
                saveNewRowCallback={saveNewRow}
                removeRowCallback={removeRow}
                disabled={isFinalVersion}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FormBaseRows;
