import React, { Dispatch, FC, SetStateAction } from "react";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import { IFileEntity } from "@/model/files/IFileEntity";
import { useAppSelector } from "@/app/hooks";
import { selectSignaturesForContainer } from "@/app/store/signaturesSlice";
import { selectCurrentUser } from "@/app/store/userSlice";
import ContainerDeclineModalBody from "@/views/home/project/detail/container/modals/ContainerDeclineModalBody";

interface Props {
  container?: IFileEntity;
  publicSignInviteToDecline?: ISignatureHolder;
  setContainerDeclineModalShown: Dispatch<SetStateAction<boolean>>;
  callbackAfterDeclineSuccess?: Function;
}

const ContainerDeclineModal: FC<Props> = ({ container, publicSignInviteToDecline, setContainerDeclineModalShown, callbackAfterDeclineSuccess }) => {
  const signatures = useAppSelector(selectSignaturesForContainer(container?.uuid ?? ""));
  const currentUser = useAppSelector(selectCurrentUser);

  if (publicSignInviteToDecline) {
    return (
      <ContainerDeclineModalBody
        signInviteToDecline={publicSignInviteToDecline}
        setContainerDeclineModalShown={setContainerDeclineModalShown}
        callbackAfterDeclineSuccess={callbackAfterDeclineSuccess}
        isPublicSignInvite={true}
      />
    );
  }

  const signInviteToDecline = signatures.find((signature) => signature.isUnsignedInvite && !signature.declined && signature.username === currentUser.username);

  if (signInviteToDecline) {
    return <ContainerDeclineModalBody signInviteToDecline={signInviteToDecline} setContainerDeclineModalShown={setContainerDeclineModalShown} />;
  }

  return null;
};

export default ContainerDeclineModal;
