import React, { FC, startTransition, useEffect, useState } from "react";
import { useAppSelector } from "@/app/hooks";
import BhModal from "@components/modal/BhModal";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { FileEntityBranch, FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { selectCurrentProjectId, selectRootDirectoryId, selectRootDocumentDirectoryId } from "@/app/store/project/projectSlice";
import { fetchDirectory, searchFilesInBranch } from "@/api/fileAPI";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhBreadcrumbs from "@components/breadcrumb/BhBreadcrumbs";
import BhFileCardMedium from "@components/cards/BhFileCardMedium";
import BhLightSeparator from "@components/separator/BhLightSeparator";
import BhShareFileContainer from "@components/modal/BhShareFileContainer";
import { canOpenInPdftron, isDirectory } from "@/utilities/fileEntity/fileEntityUtilities";
import { useTranslation } from "react-i18next";
import { naturalSortFilesByField } from "@/utilities/sortUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons/faSpinnerThird";

interface Props {
  setModalOpen: Function;
  onSelect: (selectedFiles: Array<IFileEntity>) => any;
  multiple?: boolean;
  excludeFileTypes?: Array<FileEntityType>;
  pdftronOnly?: boolean;
}

const SelectFilesModal: FC<Props> = ({ onSelect, setModalOpen, multiple, excludeFileTypes, pdftronOnly }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const rootDir = { id: 0, name: t("MODAL.CHANGE_DIR.ROOT") } as IFileEntity;
  const rootDrawingsDirectoryId = useAppSelector(selectRootDirectoryId);
  const rootDocumentsDirectoryId = useAppSelector(selectRootDocumentDirectoryId);
  const [loading, setLoading] = useState<boolean>(false);
  const [directory, setDirectory] = useState<IFileEntity>(rootDir);
  const [filesInDir, setFilesInDir] = useState<Array<IFileEntity>>([]);
  const [pathDirectories, setPathDirectories] = useState<Array<IFileEntity>>([rootDir]);
  const [selectedFiles, setSelectedFiles] = useState<Array<IFileEntity>>([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (directory && !showSearchResults) {
      const indexOfDirInPathDirectories = pathDirectories.indexOf(directory);
      if (indexOfDirInPathDirectories > -1) {
        setPathDirectories([...pathDirectories.slice(0, indexOfDirInPathDirectories + 1)]);
      }
      if (directory.id !== rootDir.id) {
        setLoading(true);
        fetchDirectory(directory.id).then((results) => {
          const withPreviouslySelectedFiles = findPreviousSelectedFiles(results);
          setFilesInDir(withPreviouslySelectedFiles);
          setLoading(false);
        });
      }
      if (directory.id === rootDir.id) {
        setFilesInDir([
          { name: t("GLOBAL.ROOT_DIR"), id: rootDrawingsDirectoryId, type: FileEntityType.DIR } as IFileEntity,
          { name: t("GLOBAL.ROOT_DOCUMENT_DIR"), id: rootDocumentsDirectoryId, type: FileEntityType.DIR } as IFileEntity
        ]);
      }
    }
  }, [directory, showSearchResults]);

  const breadcrumbs = pathDirectories.map((dir) => ({ text: dir.name, onClick: () => setDirectory(dir) }));

  const onFolderClickCallback = (file: IFileEntity) => {
    setPathDirectories([...pathDirectories, file]);
    setDirectory(file);
  };

  const findPreviousSelectedFiles = (files: Array<IFileEntity>) => {
    return files.map((resultFile) => {
      const selected = selectedFiles.find((sf) => sf.id === resultFile.id);
      return selected ? { ...resultFile, selected: true } : resultFile;
    });
  };

  const onCheckboxClickCallback = (file: IFileEntity) => {
    const selected = selectedFiles.find((sf) => sf.id === file.id);
    const selectedFile = { ...file, selected: !selected };
    const filesInDirArray =
      multiple === false ? [...filesInDir.map((f) => (f.id === file.id ? selectedFile : { ...f, selected: false }))] : [...filesInDir.map((f) => (f.id === file.id ? selectedFile : f))];
    setFilesInDir(filesInDirArray);
    if (selectedFile.selected) {
      const selectedArray = multiple === false ? [selectedFile] : [...selectedFiles, selectedFile];
      setSelectedFiles(selectedArray);
    } else {
      setSelectedFiles(selectedFiles.filter((f) => f.id !== file.id));
    }
  };

  const onNameFilterChange = (filter: { searchQuery: string }) => {
    const isQueryMinimumWidth = filter.searchQuery.length > 1;
    startTransition(() => {
      if (directory !== rootDir) {
        setDirectory(rootDir);
        setPathDirectories([rootDir]);
      }
      setSearchQuery(isQueryMinimumWidth ? filter.searchQuery : "");
      setShowSearchResults(isQueryMinimumWidth);
    });

    if (isQueryMinimumWidth) {
      setLoading(true);
      searchFilesInBranch(projectId, filter.searchQuery, [FileEntityBranch.ROOT_DIR, FileEntityBranch.ROOT_DOCUMENT_DIR]).then((searchResultFiles) => {
        const withPreviouslySelectedFiles = findPreviousSelectedFiles(searchResultFiles.fileResults);
        const sortedFilesByName = withPreviouslySelectedFiles.sort((a, b) => naturalSortFilesByField(a, b, "name"));
        setFilesInDir(sortedFilesByName);
        setLoading(false);
      });
    }
  };

  const sortedFilesInDir = filesInDir
    .sort((a, b) => naturalSortFilesByField(a, b, "name"))
    .sort((a, b) => (a.type === FileEntityType.DIR && b.type === FileEntityType.DIR ? 0 : a.type === FileEntityType.DIR ? -1 : 1));

  return (
    <BhModal
      isShown={true}
      setIsShown={(open) => setModalOpen(open)}
      header={<h2>{multiple ? t("FILEPICKER.PICK_FILES") : t("FILEPICKER.PICK_FILE")}</h2>}
      children={
        <div className="h-full flex-1 overflow-auto">
          <div className="flex h-full min-h-[300px] flex-col">
            <div className="flex flex-1 flex-col overflow-hidden px-20 py-6">
              <div>
                <BhSearchInputWBG initialValue={searchQuery} property="searchQuery" onChangeCallback={onNameFilterChange} placeholder={t("FILE_PICKER.SEARCH_PLACEHOLDER") as string} />
              </div>
              <div className="flex flex-1 flex-col overflow-hidden pt-4">
                <div className="flex flex-row flex-wrap items-center">
                  <BhBreadcrumbs values={showSearchResults ? [{ text: t("GLOBAL.SEARCH") + ' "' + searchQuery + '"' }] : breadcrumbs}></BhBreadcrumbs>
                  {loading && <FontAwesomeIcon icon={faSpinnerThird} spin className="ml-2" />}
                </div>
                <div className="h-[300px] overflow-y-auto pt-2">
                  {!loading &&
                    sortedFilesInDir.map((file) => {
                      const isDir = isDirectory(file);
                      const isExcluded = excludeFileTypes?.includes(file.type);
                      const isNotPdfTronFile = pdftronOnly && !canOpenInPdftron(file);
                      const selectDisabled = isExcluded || isNotPdfTronFile;
                      return (
                        <BhFileCardMedium
                          key={file.id}
                          fileEntity={file}
                          onCheckboxClickCallback={isDir ? undefined : selectDisabled ? () => {} : onCheckboxClickCallback}
                          onFolderClickCallback={onFolderClickCallback}
                          onFileNameClickCallback={selectDisabled ? () => {} : onCheckboxClickCallback}
                          disabled={!isDir && selectDisabled}
                          withThumbnail={true}
                          previewOnHover={true}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            {selectedFiles.length > 0 && (
              <div className="max-h-[206px] overflow-hidden overflow-y-auto">
                <BhLightSeparator />
                <BhShareFileContainer files={selectedFiles} deleteCallback={onCheckboxClickCallback} />
              </div>
            )}
          </div>
        </div>
      }
      footer={
        <div className="flex flex-row items-center">
          <BhTextOnlyButton buttonProps={{ onClick: () => setModalOpen(false) }}>{t("GLOBAL.CANCEL")}</BhTextOnlyButton>
          <BhPrimaryButton
            buttonProps={{
              onClick: () => {
                setModalOpen(false);
                onSelect(selectedFiles);
              }
            }}
          >
            {t("GLOBAL.OK")}
          </BhPrimaryButton>
        </div>
      }
      size="3xl"
    />
  );
};

export default SelectFilesModal;
