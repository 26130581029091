import { EntityId } from "@reduxjs/toolkit";

export interface IObjectChange {
  id: EntityId;
  objectName: string;
  objectId: EntityId;
  field: string;
  userAction: UserAction;
  oldValue: string;
  oldValueId: EntityId;
  newValue: string;
  newValueId: EntityId;
  projectId: EntityId;
  creatorFullName: string;
  data: any;
  created: Date;
  updated: Date;
  createdBy: string;
  updatedBy: string;
}

export enum UserAction {
  UPDATE = "UPDATE",
  NEW = "NEW",
  DELETE = "DELETE",
  ADDED = "ADDED",
  REMOVED = "REMOVED"
}
