import React, { FC, useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { Trans } from "react-i18next";
import ContactWorkgroupCard from "@/views/home/project/detail/users/contacts/contactRightsModal/ContactWorkgroupCard";
import ContactRightsModalDetail from "@/views/home/project/detail/users/contacts/contactRightsModal/ContactRightsModalDetail";
import { IWorkGroup } from "@/model/IWorkGroup";
import { fetchContactWorkGroups } from "@/api/contactAPI";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { selectEditableContact } from "@/app/store/project/projectContactsSlice";

interface Props {
  isContainerLoading: boolean;
}
const ContactAuthoritiesForWorkGroupUser: FC<Props> = ({ isContainerLoading }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [contactWorkGroups, setContactWorkGroups] = useState<Array<IWorkGroup>>([]);
  const projectId = useAppSelector(selectCurrentProjectId);
  const editableContact = useAppSelector(selectEditableContact);

  useEffect(() => {
    if (!isContainerLoading) {
      projectId &&
        editableContact &&
        fetchContactWorkGroups(projectId, editableContact)
          .then((workGroups) => {
            setContactWorkGroups(workGroups);
          })
          .then(() => setIsLoading(false));
    }

    return function cleanup() {
      setContactWorkGroups([]);
    };
  }, [isContainerLoading]);

  return (
    <Tab.Group vertical>
      <div className="flex w-full">
        <div className="w-1/3 pl-8 pr-4">
          <Tab.List>
            <div className="mt-8 flex flex-col">
              <h3 className="mb-4">
                <Trans>CONTACT.ACCESS.MODAL.USER_IN_PARTIES</Trans>
              </h3>
              {isLoading ? (
                <ContactWorkgroupCard workGroupName="" />
              ) : (
                contactWorkGroups.map((wg) => {
                  return <ContactWorkgroupCard key={wg.id} workGroupName={wg.name} />;
                })
              )}
            </div>
          </Tab.List>
        </div>
        <div className="bh-bg-pigeon-20 w-2/3 px-4 pt-4 pb-2">
          <Tab.Panels>
            {isLoading ? (
              <ContactRightsModalDetail isContainerLoading={isLoading} />
            ) : (
              contactWorkGroups.map((wg) => <ContactRightsModalDetail key={wg.id} isContainerLoading={isLoading} workGroupId={wg.id} />)
            )}
          </Tab.Panels>
        </div>
      </div>
    </Tab.Group>
  );
};

export default ContactAuthoritiesForWorkGroupUser;
