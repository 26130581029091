import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { BauhubBannerType, BauhubPlan } from "@/model/IProject";
import BhBanner from "@components/banners/BhBanner";
import ArchiveModalInformationBanner from "@/views/home/project/detail/projectSettings/ArchiveModalInformationBanner";

interface Props {
  companyId: EntityId;
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  onCancelButtonClick: Function;
  onArchiveButtonClick: Function;
}

const ProjectArchiveModal: FC<Props> = ({ companyId, isShown, setIsShown, onArchiveButtonClick, onCancelButtonClick }) => {
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const [switchModals, setSwitchModals] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setSwitchModals(false);
    };
  }, []);

  if (company?.plan === BauhubPlan.LIGHT && !switchModals) {
    return (
      <BhModal
        size={"xl"}
        isShown={isShown}
        setIsShown={setIsShown}
        header={
          <div className="flex items-center">
            <h2>{t("MODAL.ARCHIVE.CONFIRMATION")}</h2>
          </div>
        }
        children={
          <div className="bh-text-deep-ocean px-8 py-6">
            <div className="pb-4">
              <BhBanner type={BauhubBannerType.WARNING} fullWidth={true}>
                <div className="text-14px ml-2 leading-5">{parse(t("MODAL.ARCHIVE.CONFIRMATION_BODY.LIGHT_INFO"))}</div>
              </BhBanner>
            </div>
            <ArchiveModalInformationBanner />
          </div>
        }
        footer={
          <div className="flex flex-row items-center">
            <BhTextOnlyButton buttonProps={{ onClick: () => onCancelButtonClick() }}>{t("CONFIRMATION.MODAL.SIGN_QUEUE.CANCEL")}</BhTextOnlyButton>
            <BhPrimaryButton buttonProps={{ onClick: () => setSwitchModals(true) }}>{t("GLOBAL.CONTINUE")}</BhPrimaryButton>
          </div>
        }
      />
    );
  }

  return (
    <BhModal
      isShown={isShown}
      setIsShown={setIsShown}
      header={
        <div className="flex items-center">
          <h2>{t("MODAL.ARCHIVE.CONFIRMATION")}</h2>
        </div>
      }
      children={<div className="bh-text-deep-ocean px-8 py-6">{parse(t("MODAL.ARCHIVE.CONFIRMATION_BODY.V2"))}</div>}
      footer={
        <div className="flex flex-row items-center">
          <BhTextOnlyButton buttonProps={{ onClick: () => onCancelButtonClick() }}>{t("CONFIRMATION.MODAL.SIGN_QUEUE.CANCEL")}</BhTextOnlyButton>
          <BhPrimaryButton buttonProps={{ onClick: () => onArchiveButtonClick() }}>{t("MODAL.ARCHIVE")}</BhPrimaryButton>
        </div>
      }
    />
  );
};

export default ProjectArchiveModal;
