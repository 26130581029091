import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectIsCustomCompany } from "@/app/store/project/projectSlice";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { useTranslation } from "react-i18next";
import ProjectAdministratorsContainerCustomClient from "@/views/home/project/detail/projectSettings/customClient/ProjectAdministratorsContainerCustomClient";
import ProjectAdministratorsContainerBauhub from "@/views/home/project/detail/projectSettings/ProjectAdministratorsContainerBauhub";

const ProjectAdministratorsContainer: FC = () => {
  const { t } = useTranslation();
  const isCustomCompany = useAppSelector(selectIsCustomCompany);

  return (
    <BhScrollableBody>
      <h2 className="bh-text-deep-ocean pb-2 pt-4">{t("GLOBAL.USERS.ADMINS")}</h2>
      {isCustomCompany && <ProjectAdministratorsContainerCustomClient />}
      {!isCustomCompany && <ProjectAdministratorsContainerBauhub />}
    </BhScrollableBody>
  );
};
export default ProjectAdministratorsContainer;
