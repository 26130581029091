import BhModal from "@/components/modal/BhModal";
import BhTableHeader from "@/components/table/BhTableHeader";
import React, { FC, useEffect } from "react";
import { IBauhubEvent, ProjectLogType } from "@/model/IBauhubEvent";
import BhTableRow from "@/components/table/BhTableRow";
import BhEventLabelContainer from "@/components/eventContainer/BhEventLabelContainer";
import BhTextOnlyButton from "@/components/buttons/BhTextOnlyButton";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { useTranslation } from "react-i18next";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import { IUser } from "@/model/IUser";
import FileNameWrapped from "@/features/fileContainer/FileNameWrapped";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft } from "@fortawesome/pro-regular-svg-icons/faClockRotateLeft";
import { fetchFileLogAsync, resetFileHistoryModalData, selectFileHistoryModalFileHistory, selectFileHistoryModalLog, setFileHistoryModalShown } from "@/app/store/project/projectLogSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { formatDateTime } from "@/utilities/dateUtility";

const ProjectLogFileHistoryModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const log = useAppSelector(selectFileHistoryModalLog) as IBauhubEvent;
  const fileHistory = useAppSelector(selectFileHistoryModalFileHistory) as Array<IBauhubEvent>;
  const fileHistorySorted = fileHistory && fileHistory.slice().sort((a, b) => (new Date(a.created) < new Date(b.created) ? 1 : -1));

  useEffect(() => {
    dispatch(fetchFileLogAsync());
  }, []);

  const closeFileHistoryModal = () => {
    dispatch(setFileHistoryModalShown(false));
    dispatch(resetFileHistoryModalData());
  };

  const fileLogNameMap: Record<ProjectLogType, string> = {
    AUTHORITY_CHANGED: log.data.name,
    CONTAINER_UPDATE: log.data.name,
    FILE_COMMENT: log.data.name,
    FILE_DELETED: log.data.name,
    FILE_LOCKED: log.data.parentFileEntityName,
    FILE_MOVED_FROM: log.data.name,
    FILE_MOVED_TO: log.data.movedFileName,
    FILE_NEW_REVISION: log.data.newFileName,
    FILE_RENAMED: log.data.name,
    FILE_REVISION_ROLLBACK: log.data.latestRevisionFileName,
    FILE_UNDO_DELETE: log.data.name,
    FILE_UNLOCKED: log.data.parentFileEntityName,
    FORM_UPDATE: log.data.name,
    NEW_FILE: log.data.name,
    PROJECT_ARCHIVED: log.data.name,
    PROJECT_OPENED: log.data.name,
    PROJECT_UPDATE: log.data.name,
    SYNC_DIR_ADDED: log.data.name,
    SYNC_DIR_REMOVED: log.data.name
  };

  const fileHistoryTableHeaders: Array<IBhTableHeader<IBauhubEvent>> = [
    { id: 1, content: t("LOG.TYPE") },
    { id: 2, content: t("LOG.TRIGGERER") },
    { id: 3, field: "created", content: t("LOG.CREATED") }
  ];

  return (
    <BhModal
      size="4xl"
      isShown={true}
      setIsShown={() => {}}
      onClose={closeFileHistoryModal}
      header={
        <div className="w-5/6">
          <div className="flex flex-row items-center">
            <h2>
              <FontAwesomeIcon icon={faClockRotateLeft} className="mr-4" />
            </h2>
            <h2 className="bh-text-deep-ocean-60">{t("MODAL.FILE_LOG_BUTTON") + " – "} </h2>
            <h2 className="max-w-half ml-1">
              <FileNameWrapped fileName={fileLogNameMap[log.type]} />
            </h2>
          </div>
        </div>
      }
      children={
        <div className="px-8 py-6">
          {fileHistorySorted && <div className="bh-text-deep-ocean-80 mb-2 pl-3">{t("FILE_INFORMATION.FILES") + " - " + fileHistorySorted.length}</div>}
          <table className="w-full">
            <BhTableHeader columns={fileHistoryTableHeaders} sortedBy="created" reversed={false} />
            <tbody>
              {fileHistorySorted &&
                fileHistorySorted.map((log) => {
                  return (
                    <BhTableRow key={log.id}>
                      <td>
                        <BhEventLabelContainer event={log} />
                      </td>
                      <td>
                        <BhUserIconWithName user={{ firstName: log.data.eventTriggererFirstName, lastName: log.data.eventTriggererLastName, userExists: true } as IUser} />
                      </td>
                      <td>{formatDateTime(log.created)}</td>
                    </BhTableRow>
                  );
                })}
            </tbody>
          </table>
        </div>
      }
      footer={
        <div>
          <BhTextOnlyButton buttonProps={{ onClick: closeFileHistoryModal }}>{t("GLOBAL.CLOSE")}</BhTextOnlyButton>
        </div>
      }
    />
  );
};

export default ProjectLogFileHistoryModal;
