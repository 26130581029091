import React, { FC } from "react";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons";
import { fetchShareboxFilesUrl } from "@/api/shareboxAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { Trans, useTranslation } from "react-i18next";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import { selectShareboxById } from "@/app/store/shareboxesSlice";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareFromSquare } from "@fortawesome/pro-regular-svg-icons/faShareFromSquare";
import { ShareboxDisplayOption } from "@/model/shareboxes/ISharebox";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  shareboxId: EntityId;
  shareboxDisplayOption: ShareboxDisplayOption;
}

const PublicShareboxContainerHeader: FC<Props> = ({ shareboxId, shareboxDisplayOption }) => {
  const sharebox = useAppSelector((state) => selectShareboxById(state, shareboxId));
  const { t } = useTranslation();

  if (!sharebox) {
    return null;
  }

  const downloadSharebox = () => {
    if (sharebox.fileIds && sharebox?.uuid) {
      let selectedIds = [];
      if (anySelected) {
        selectedIds = [...sharebox.fileList, ...sharebox.parentList].filter((f) => f.selected).map((f) => f.id);
      } else {
        if (shareboxDisplayOption === ShareboxDisplayOption.FILE_LIST) {
          selectedIds = [...sharebox.fileIds];
        } else {
          selectedIds = [...sharebox.fileIds, ...sharebox.parentList.map((f) => f.id)];
        }
      }
      fetchShareboxFilesUrl(sharebox.uuid, selectedIds).then((response) => {
        const url = ConfigSingleton.getInstance().getConfig().REACT_APP_PUBLIC_API_URL + "/url/batch/sharebox/direct/" + response.value;
        imitateUrlDownload(url);
      });
    }
  };

  const anySelected = [...sharebox.fileList, ...sharebox.parentList].some((f) => f.selected);

  return (
    <div className="bh-bg-mint flex w-full">
      <div className="mx-auto flex w-full max-w-[1366px] flex-row flex-wrap items-center justify-between px-6 py-2.5">
        <div>
          <FontAwesomeIcon icon={faShareFromSquare} />
          <span className="ml-1.5">{t("SHAREBOX.LIST.SHAREBOX")}</span>
        </div>
        <div className="flex items-center">
          <div>{anySelected ? <Trans>SHAREBOX.DOWNLOAD_SELECTED</Trans> : <Trans>SHAREBOX.DOWNLOAD_ALL</Trans>}</div>
          <BhPrimaryButton icon={faArrowDownToLine} buttonProps={{ onClick: () => downloadSharebox(), classes: "px-4 ml-4" }}>
            <Trans>GLOBAL.DOWNLOAD</Trans>
          </BhPrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default PublicShareboxContainerHeader;
