import React from "react";
import { useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "@/utilities/jsUtilities";

const BOCompanyModulesTemplatesMenu = () => {
  const location = useLocation();
  const currentSection = location.hash.replace("#", "");
  const menuLinks = ["modules", "forms", "checklists", "subcontractor-contracts", "client-contracts", "subcontractor-acts", "client-acts"];

  const handleScroll = (sectionId: string) => {
    const targetElement = document.getElementById(sectionId);

    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  };

  return (
    <div className="fixed">
      {menuLinks.map((link) => (
        <a
          onClick={(e) => {
            e.preventDefault();
            handleScroll(link);
          }}
          href={`#${link}`}
          className={currentSection === link ? "font-weight-700 bh-text-deep-ocean" : "bh-text-pigeon"}
          key={link}
        >
          <p>{capitalizeFirstLetter(link.split("-").join(" "))}</p>
        </a>
      ))}
    </div>
  );
};

export default BOCompanyModulesTemplatesMenu;
