import React, { FC } from "react";
import { Property } from "@xeokit/xeokit-sdk";
import { faBookmark as faBookmarkRegular } from "@fortawesome/pro-regular-svg-icons/faBookmark";
import { classNames } from "@/utilities/jsUtilities";
import { faBookmark as faBookmarkSolid } from "@fortawesome/pro-solid-svg-icons/faBookmark";
import BhButtonTemplateWithIcon from "@components/buttons/BhButtonTemplateWithIcon";

interface Props {
  propertySetName: string;
  property: Property;
  toggleFavourite: Function;
  isFavourite: boolean;
}

const XeokitSidebarMenuPropertySetProperty: FC<Props> = ({ propertySetName, property, toggleFavourite, isFavourite }) => {
  return (
    <div key={property.name} className="flex flex-row items-center justify-between py-1">
      <div>
        <div>
          <strong className="bh-text-pigeon">{property.name}</strong>
        </div>
        <div>{property.value && property.value !== undefined ? ((property.value + "").toLowerCase() !== "undefined" ? property.value : "–") : "–"}</div>
      </div>
      <div>
        <BhButtonTemplateWithIcon
          buttonProps={{ classes: classNames("hover:bh-bg-pigeon-20 w-3 py-0.5"), onClick: () => toggleFavourite(propertySetName, property.name) }}
          icon={isFavourite ? faBookmarkSolid : faBookmarkRegular}
          iconSize="sm"
        />
      </div>
    </div>
  );
};
export default XeokitSidebarMenuPropertySetProperty;
