import React, { FC } from "react";
import BhInputLabel from "@components/input/BhInputLabel";
import BhInput from "@components/input/BhInput";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boSelectCompanyBilling } from "@/app/store/backofficeSlice";
import { ICompanyBilling } from "@/model/billing/ICompanyBilling";

interface Props {
  label: string;
  helperText: string;
  initialMonthlyValue: number;
  monthlyProperty: keyof ICompanyBilling;
  initialYearlyValue: number;
  yearlyProperty: keyof ICompanyBilling;
  onChange: Function;
}

const BoCompanyPricingRowInput: FC<Props> = ({ label, helperText, initialMonthlyValue, monthlyProperty, initialYearlyValue, yearlyProperty, onChange }) => {
  const companyBilling = useAppSelector(boSelectCompanyBilling);
  const dispatch = useAppDispatch();

  return (
    <div className="flex flex-row py-1.5 items-center">
      <div className="flex inline-flex justify-end px-3 text-right align-top font-semibold w-28">
        <div>
          <BhInputLabel classes="text-13px bh-text-deep-ocean-80 l-h-16px mb-1">{label}</BhInputLabel>
          <BhInputLabel classes="text-sm l-h-14px font-normal whitespace-nowrap">{helperText}</BhInputLabel>
        </div>
      </div>
      <div className="flex inline-flex !w-full px-3">
        <div className="flex gap-4 w-full">
          <BhInput initialValue={Number(initialMonthlyValue)} property={monthlyProperty} onChangeCallback={onChange} />
          <BhInput initialValue={Number(initialYearlyValue)} property={yearlyProperty} onChangeCallback={onChange} />
        </div>
      </div>
    </div>
  );
};

export default BoCompanyPricingRowInput;