import { EntityId } from "@reduxjs/toolkit";
import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectActsPrivilegesForParty, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhIFrameComponent from "@/views/global/BhIFrameComponent";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  actId: EntityId;
  partyId: EntityId;
}

const ActContainerAngular: FC<Props> = ({ actId, partyId }) => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const privilegesForAct = useAppSelector((state) => !isProjectAdmin && selectActsPrivilegesForParty(state, partyId));

  if (!isProjectAdmin && !privilegesForAct.isRead) {
    return null;
  }

  return <BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/parties/${partyId}/act/${actId}/react`} />;
};

export default ActContainerAngular;
