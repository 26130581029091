import React, { FC } from "react";
import BhLargeText from "@components/text/BhLargeText";
import BhTag from "@components/tags/BhTag";
import { tagTypeMap } from "@/model/IWorkGroup";
import { IPredefinedWorkGroup } from "@/model/IPredefinedWorkGroup";

interface Props {
  party: IPredefinedWorkGroup;
}
const PartyNameContainer: FC<Props> = ({ party }) => {
  return (
    <div className="flex flex-col">
      <BhLargeText>{party.name}</BhLargeText>
      {party.type && <BhTag type={tagTypeMap[party.type]} children={party.type} />}
    </div>
  );
};

export default React.memo(PartyNameContainer);
