import { EntityId } from "@reduxjs/toolkit";
import { bauhubGet, bauhubPost, bauhubPublicGet, bauhubPublicPost } from "@/api/bauhubAPI";
import { ISharebox } from "@/model/shareboxes/ISharebox";
import { IFileEntity } from "@/model/files/IFileEntity";
import { IShareboxUrl } from "@/model/shareboxes/IShareboxUrl";
import { IShareboxValidToRequest } from "@/model/shareboxes/IShareboxValidToRequest";
import { IPublicShareboxResponse } from "@/model/shareboxes/IPublicShareboxResponse";

export function fetchSharebox(shareboxId: EntityId): Promise<ISharebox> {
  return bauhubGet("/sharebox/" + shareboxId);
}

export function fetchProjectShareboxes(projectId: EntityId): Promise<Array<ISharebox>> {
  return bauhubGet("/sharebox/project/" + projectId);
}

export function fetchUserShareboxes(projectId: EntityId): Promise<Array<ISharebox>> {
  return bauhubGet("/sharebox/project/" + projectId + "/user");
}

export function deleteSharebox(shareboxId: EntityId): Promise<ISharebox> {
  return bauhubPost("/v2/sharebox/" + shareboxId + "/delete");
}

export function fetchFilesForSharebox(shareboxId: EntityId): Promise<Array<IFileEntity>> {
  return bauhubGet("/sharebox/" + shareboxId + "/files");
}

export function fetchFilesForPublicSharebox(shareboxUuid: string): Promise<Array<IFileEntity>> {
  return bauhubPublicGet("/v2/sharebox/" + shareboxUuid + "/files");
}

export function fetchShareboxUrls(shareboxId: EntityId): Promise<Array<IShareboxUrl>> {
  return bauhubGet("/sharebox/" + shareboxId + "/urls");
}

export function fetchPublicShareboxByUrlUuid(shareboxUrlUuid: string): Promise<IPublicShareboxResponse> {
  return bauhubPublicGet("/v2/sharebox/url/" + shareboxUrlUuid + "?disableErrors=true");
}

export function fetchPublicShareboxByShareboxUuid(shareboxUuid: string): Promise<ISharebox> {
  return bauhubPublicGet("/v2/sharebox/" + shareboxUuid + "?disableErrors=true");
}

export function fetchShareboxFilesUrl(sbUuid: string, fileIds: Array<EntityId>): Promise<{ value: string }> {
  return bauhubPublicPost("/v2/sharebox/" + sbUuid + "/files/url?st=false", fileIds);
}

export function fetchPublicShareboxFileUrl(sbUuid: string, fileUuid: string, isDownload: boolean, isCompressed: boolean, isThumb?: boolean): Promise<{ value: string }> {
  return bauhubPublicGet("/v2/sharebox/" + sbUuid + "/url/" + fileUuid, { isDownload: isDownload, isCompressed: isCompressed, isThumb: isThumb });
}

export function saveSharebox(sharebox: ISharebox): Promise<ISharebox> {
  return bauhubPost("/sharebox/" + sharebox.projectId, sharebox);
}

export function saveShareboxUrls(shareboxId: EntityId, urls: Array<IShareboxUrl>) {
  return bauhubPost("/sharebox/" + shareboxId + "/urls", urls);
}

export function saveShareboxValidTo(request: IShareboxValidToRequest): Promise<ISharebox> {
  return bauhubPost("/sharebox/" + request.shareboxId + "/validto", request);
}
