import React, { FC, useEffect, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import CompanySettingsFormBaseListRow from "@components/form/settings/companySettings/CompanySettingsFormBaseListRow";
import { FormBaseVersionStatus, IFormBaseVersion } from "@/model/form/IFormBaseVersion";
import { IFormBase } from "@/model/form/IFormBase";

interface Props {
  companyId: EntityId;
  formBase: IFormBase;
  selectRow: Function;
  isRowSelected: boolean;
  isProjectFormBase: boolean;
}

const CompanySettingsFormBaseListRowContainer: FC<Props> = ({ companyId, formBase, selectRow, isRowSelected, isProjectFormBase }) => {
  const sortedVersions = formBase?.formBaseVersions && formBase.formBaseVersions.slice().sort((a: IFormBaseVersion, b: IFormBaseVersion) => (a.versionNumber < b.versionNumber ? 1 : -1));
  const [visibleVersions, setVisibleVersions] = useState([] as Array<IFormBaseVersion>);
  const [showAllVersions, setShowAllVersions] = useState(false);

  useEffect(() => {
    if (sortedVersions) {
      const moreThanOneVersion = sortedVersions.length > 1;
      const lastVersionInDraft = sortedVersions[0].status === FormBaseVersionStatus.DRAFT;
      if (showAllVersions) {
        if (moreThanOneVersion && lastVersionInDraft) {
          const lastVersion = sortedVersions[0];
          const versionsToShow = sortedVersions.map((v) => v);
          versionsToShow.splice(0, 1);
          versionsToShow.splice(1, 0, lastVersion);
          setVisibleVersions(versionsToShow);
        } else {
          setVisibleVersions(sortedVersions);
        }
      } else {
        const versionsToShow = moreThanOneVersion && lastVersionInDraft ? [sortedVersions[1], sortedVersions[0]] : [sortedVersions[0]];
        setVisibleVersions(versionsToShow);
      }
    }
  }, [showAllVersions, formBase]);

  const toggleShowAllVersions = () => {
    setShowAllVersions(!showAllVersions);
  };

  if (!visibleVersions || visibleVersions.length === 0 || !formBase) return null;

  return (
    <>
      {visibleVersions &&
        visibleVersions.map((version) => {
          return (
            <CompanySettingsFormBaseListRow
              key={version?.id || formBase.id}
              companyId={companyId}
              formBase={formBase}
              formBaseVersion={version}
              isLastVersion={version.id === visibleVersions[0].id}
              toggleShowAllVersions={toggleShowAllVersions}
              allVersionsVisible={showAllVersions}
              selectRow={selectRow}
              isRowSelected={isRowSelected}
              isProjectFormBase={isProjectFormBase}
            />
          );
        })}
    </>
  );
};

export default CompanySettingsFormBaseListRowContainer;
