import { fetchSyncProject } from "@/api/syncAPI";
import BhTextOnlyButton from "@/components/buttons/BhTextOnlyButton";
import BhScrollableBody from "@/components/detailContainer/BhScrollableBody";
import BhSeparatorMarginless from "@/components/separator/BhSeparatorMarginless";
import BhTabs from "@/components/tabs/BhTabs";
import { ISyncProject } from "@/model/sync/ISyncProject";
import { ITabArrayElement } from "@/model/tabs/ITabArrayElement";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EntityId } from "@reduxjs/toolkit";
import { FC, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SyncProjectDirectoryPickerTab from "./SyncProjectDirectoryPickerTab";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";

interface Props {
  projectId: EntityId;
  showDownloadButton?: boolean;
  hideFooter?: boolean;
}

const SyncProjectDirectoryTabsContainer: FC<Props> = ({ projectId, showDownloadButton, hideFooter }) => {
  const { t } = useTranslation();
  const [project, setProject] = useState({} as ISyncProject);
  const navigate = useNavigate();
  const [selectedDocIds, setSelectedDocIds] = useState([] as Array<EntityId>);
  const [selectedFileIds, setSelectedFileIds] = useState([] as Array<EntityId>);
  const [selectedModelIds, setSelectedModelIds] = useState([] as Array<EntityId>);
  const [openedFileIds, setOpenedFileIds] = useState([] as Array<EntityId>);
  const [openedDocIds, setOpenedDocIds] = useState([] as Array<EntityId>);
  const [openedModelIds, setOpenedModelIds] = useState([] as Array<EntityId>);

  function reload() {
    fetchSyncProject(projectId).then((result) => {
      setSelectedDocIds(result.markedDocumentIds);
      setSelectedFileIds(result.markedFileIds);
      setSelectedModelIds(result.markedModelIds);

      setOpenedFileIds([result.rootDirId, ...result.markedFileIds]);
      setOpenedDocIds([result.rootDocumentDirId, ...result.markedDocumentIds]);
      setOpenedModelIds([result.rootModelDirId, ...result.markedModelIds]);

      setProject(result);
    });
  }

  useEffect(() => {
    reload();
  }, []);

  if (!project || !project.id || !project.markedFileIds || !project.markedDocumentIds) {
    return null;
  }

  const baseTabs: Array<ITabArrayElement> = [
    {
      id: 0,
      header: t("GLOBAL.DRAWINGS"),
      content: <SyncProjectDirectoryPickerTab project={project} selectedIds={selectedFileIds} setSelectedIds={setSelectedFileIds} openedIds={openedFileIds} />,
      href: "drawings"
    },
    {
      id: 1,
      header: t("GLOBAL.DOCUMENTS"),
      content: <SyncProjectDirectoryPickerTab project={project} selectedIds={selectedDocIds} setSelectedIds={setSelectedDocIds} openedIds={openedDocIds} />,
      href: "documents"
    },
    {
      id: 2,
      header: t("GLOBAL.MODELS"),
      content: <SyncProjectDirectoryPickerTab project={project} selectedIds={selectedModelIds} setSelectedIds={setSelectedModelIds} openedIds={openedModelIds} />,
      href: "models"
    }
  ];

  const tabsArray = baseTabs;

  return (
    <div className="flex h-full w-full flex-col">
      <BhScrollableBody>
        <div className="p-12">
          <div className="bh-bg-white flex h-full flex-col text-left">
            {!showDownloadButton && (
              <div>
                <div>
                  <h2>{project && project.name}</h2>
                </div>
                <p className="text-18px">{t("SYNC.PICK_DIRECTORIES")}</p>
              </div>
            )}
            {showDownloadButton && (
              <div className="flex flex-row justify-between">
                <div className="flex flex-col">
                  <h2>Baudrive</h2>
                  <p className="text-18px">{t("SYNC.PICK_DIRECTORIES")}</p>
                </div>
                <div className="ml-2 flex flex-row items-center rounded border text-right">
                  <div className="p-3">
                    <p>{t("BAUDRIVE.DOWNLOAD_HEADER_TEXT")}</p>
                  </div>
                  <div className="p-3">
                    <BhSecondaryButton icon={faArrowDownToLine} buttonProps={{ onClick: () => window.open("https://www.microsoft.com/store/apps/9NMS5FST0HFJ", "_blank") }}>
                      {t("GLOBAL.DOWNLOAD")}
                    </BhSecondaryButton>
                  </div>
                </div>
              </div>
            )}
            <div>
              <BhTabs callback={reload} tabs={tabsArray} router={false} initialActiveTab={0} tabsContainerClasses="overflow-auto" />
            </div>
          </div>
        </div>
      </BhScrollableBody>
      {!hideFooter && (
        <div className="bh-bg-white h-18 fixed bottom-0 w-full">
          <BhSeparatorMarginless />
          <div className="flex flex-row items-center">
            <div className="pl-6 pt-4">
              <BhTextOnlyButton buttonProps={{ onClick: () => navigate(-1) }}>
                <FontAwesomeIcon className="mr-1" icon={faArrowLeft} size={"xs"} aria-hidden="true" />
                <Trans>{t("SYNC.ALL_PROJECTS")}</Trans>
              </BhTextOnlyButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SyncProjectDirectoryTabsContainer;
