import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-locize-backend";
import { locizePlugin } from "locize";

const locizeOptions = {
  projectId: "1746f3c0-d2b5-4b7d-b22c-b2e7ec65e78d",
  apiKey: "67678815-1d61-49ea-af97-112ad0944335", // YOU should not expose your apps API key to production!!!
  referenceLng: "et"
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(locizePlugin)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    lng: "et",
    fallbackLng: ["en", "et"],
    interpolation: {},
    backend: locizeOptions
  });

export default i18n;
