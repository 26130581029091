import React, { FC } from "react";
import { ChecklistBaseCategory } from "@/model/checklist/IChecklistBase";
import BhNewTagWithDropdown from "@components/tags/BhNewTagWithDropdown";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { useTranslation } from "react-i18next";

interface Props {
  category: ChecklistBaseCategory;
  saveCallback: Function;
  disabled?: boolean;
}

const ChecklistBaseCategoryTagDropdown: FC<Props> = ({ category, saveCallback, disabled }) => {
  const { t } = useTranslation();

  const changeCategory = (categoryObject: any) => {
    saveCallback(categoryObject.categoryName);
  };

  const categoryClassesMap: Record<ChecklistBaseCategory, { backgroundColorClass: string; textColorClass: string; categoryName: string; dropdownValue: string }> = {
    SAFETY: { backgroundColorClass: "bh-bg-mediterranean-10", textColorClass: "bh-text-mediterranean", categoryName: "SAFETY", dropdownValue: t("CHECKLIST.CATEGORY_TAG.SAFETY") },
    QUALITY: { backgroundColorClass: "bh-bg-lush-10", textColorClass: "bh-text-lush", categoryName: "QUALITY", dropdownValue: t("CHECKLIST.CATEGORY_TAG.QUALITY") },
    HANDOVERS: { backgroundColorClass: "bh-bg-eggplant-10", textColorClass: "bh-text-eggplant", categoryName: "HANDOVERS", dropdownValue: t("CHECKLIST.CATEGORY_TAG.HANDOVERS") },
    OTHER: { backgroundColorClass: "bh-bg-dull-grey-10", textColorClass: "bh-text-dull-grey", categoryName: "OTHER", dropdownValue: t("CHECKLIST.CATEGORY_TAG.OTHER") }
  };

  return (
    <BhNewTagWithDropdown
      dropdownPosition={BhDropdownPositionEnum.BOTTOM_RIGHT}
      tagText={t("CHECKLIST.CATEGORY_TAG." + category.toUpperCase())}
      values={Object.values(categoryClassesMap).filter((cat) => cat.categoryName !== category)}
      textProperty={"dropdownValue"}
      onSelect={changeCategory}
      textColorClass={categoryClassesMap[category].textColorClass}
      backgroundColorClass={categoryClassesMap[category].backgroundColorClass}
      disabled={disabled}
    />
  );
};

export default ChecklistBaseCategoryTagDropdown;
