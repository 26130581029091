import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { IFileConfirmationFile } from "@/model/confirmations/IFileConfirmationFile";
import {
  addFilesToConfirmation,
  deleteFilesFromConfirmation,
  fetchFileConfirmationFileComments,
  replaceFileWithLatestVersion,
  resetFileInConfirmation,
  saveFileConfirmationFileComment
} from "@/api/fileConfirmationAPI";
import { IFileConfirmationComment } from "@/model/confirmations/IFileConfirmationComment";
import { IBhSort } from "@/model/IBhSort";
import { naturalSortFilesByField } from "@/utilities/sortUtilities";
import { getUniqueValues } from "@/utilities/jsUtilities";

export interface IFileConfirmationFilesSort extends IBhSort<IFileConfirmationFile> {}

export const initialFileConfirmationFilesSort = {
  property: "name" as keyof IFileConfirmationFile,
  reversed: false
};

const fileConfirmationFilesAdapter = createEntityAdapter<IFileConfirmationFile>({
  selectId: (fc) => fc.fileEntityId
});

const fileConfirmationFilesInitialState = fileConfirmationFilesAdapter.getInitialState<{
  sort: IFileConfirmationFilesSort;
  openCommentsForFileConfirmationFileId?: EntityId;
}>({
  sort: initialFileConfirmationFilesSort
});

export const deleteFilesFromConfirmationAsync = createAsyncThunk("fileConfirmationFiles/deleteFilesFromConfirmation", async (dto: { fileConfirmationId: EntityId; fileIds: Array<EntityId> }) => {
  return deleteFilesFromConfirmation(dto.fileConfirmationId, dto.fileIds);
});

export const resetFileInConfirmationAsync = createAsyncThunk("fileConfirmationFiles/resetFileInConfirmation", async (dto: { fileConfirmationId: EntityId; fileId: EntityId }) => {
  return resetFileInConfirmation(dto.fileConfirmationId, dto.fileId);
});

export const replaceFileWithLatestVersionAsync = createAsyncThunk("fileConfirmationFiles/replaceFileWithLatestVersion", async (dto: { fileConfirmationId: EntityId; fileId: EntityId }) => {
  const result = await replaceFileWithLatestVersion(dto.fileConfirmationId, dto.fileId);
  return { deletedFileId: dto.fileId, newFile: result };
});

export const addFilesToConfirmationAsync = createAsyncThunk("fileConfirmationFiles/addFilesToConfirmation", async (dto: { fileConfirmationId: EntityId; fileIds: Array<EntityId> }) => {
  return addFilesToConfirmation(dto.fileConfirmationId, dto.fileIds);
});

export const fetchFileConfirmationFileCommentsAsync = createAsyncThunk("fileConfirmation/fetchFileConfirmationFileComments", async (dto: { fileConfirmationId: EntityId; fileId: EntityId }) => {
  return fetchFileConfirmationFileComments(dto.fileConfirmationId, dto.fileId);
});

export const saveFileConfirmationFileCommentAsync = createAsyncThunk(
  "fileConfirmation/saveFileConfirmationFileComment",
  async (dto: { fileConfirmationId: EntityId; fileId: EntityId; comment: IFileConfirmationComment }) => {
    return saveFileConfirmationFileComment(dto.fileConfirmationId, dto.fileId, dto.comment);
  }
);

const fileConfirmationFilesSlice = createSlice({
  name: "fileConfirmationFiles",
  initialState: fileConfirmationFilesInitialState,
  reducers: {
    fileConfirmationFilesAdded: fileConfirmationFilesAdapter.upsertMany,
    fileConfirmationFilesSortSet: (state, action: PayloadAction<IFileConfirmationFilesSort>) => {
      state.sort = action.payload;
    },
    openCommentsForFileConfirmationFileIdSet: (state, action: PayloadAction<EntityId | undefined>) => {
      state.openCommentsForFileConfirmationFileId = action.payload;
    },
    toggleFileConfirmationFileSelected: (state, action: PayloadAction<IFileConfirmationFile>) => {
      const existingFileConfirmationFile = state.entities[action.payload.fileEntityId];
      if (existingFileConfirmationFile) existingFileConfirmationFile.selected = !existingFileConfirmationFile.selected;
    },
    toggleAllFileConfirmationFileSelected: (state, action: PayloadAction<EntityId>) => {
      const confirmationId = action.payload;
      const fileConfirmationFiles = Object.values(state.entities);
      const confirmationFiles = fileConfirmationFiles.filter((fcf) => fcf && fcf.fileConfirmationId === confirmationId);
      const allSelected = confirmationFiles.every((fcf) => fcf && fcf.selected);
      const value = !allSelected;
      confirmationFiles.forEach((fcf) => fcf && (fcf.selected = value));
    },
    fileConfirmationFilesSelectedReset: (state, action: PayloadAction<EntityId>) => {
      const fileConfirmationFiles = Object.values(state.entities);
      const confirmationFiles = fileConfirmationFiles.filter((fcf) => fcf && fcf.fileConfirmationId === action.payload);
      confirmationFiles.forEach((fcf) => fcf && (fcf.selected = false));
    }
  },
  extraReducers: (builder) => {
    return builder
      .addCase(deleteFilesFromConfirmationAsync.fulfilled, (state, action) => {
        fileConfirmationFilesAdapter.removeMany(state, action.payload.value);
      })
      .addCase(resetFileInConfirmationAsync.fulfilled, (state, action) => {
        fileConfirmationFilesAdapter.upsertOne(state, action.payload);
      })
      .addCase(replaceFileWithLatestVersionAsync.fulfilled, (state, action) => {
        fileConfirmationFilesAdapter.removeOne(state, action.payload.deletedFileId);
        fileConfirmationFilesAdapter.upsertOne(state, action.payload.newFile);
      })
      .addCase(addFilesToConfirmationAsync.fulfilled, (state, action) => {
        fileConfirmationFilesAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchFileConfirmationFileCommentsAsync.fulfilled, (state, action) => {
        const fileEntityId = action.payload[0]?.fileEntityId;
        if (!fileEntityId) return;
        const existingFileConfirmationFile = state.entities[fileEntityId];
        if (existingFileConfirmationFile) existingFileConfirmationFile.comments = action.payload;
      })
      .addCase(saveFileConfirmationFileCommentAsync.fulfilled, (state, action) => {
        const fileEntityId = action.payload.fileEntityId;
        const existingFileConfirmationFile = state.entities[fileEntityId];
        if (existingFileConfirmationFile) {
          const comments = (existingFileConfirmationFile.comments || []).filter((comment) => comment.id !== action.payload.id);
          existingFileConfirmationFile.comments = [...comments, action.payload];
          existingFileConfirmationFile.commentCount = existingFileConfirmationFile.comments.filter((comment) => !comment.deleted).length;
        }
      });
  }
});

export const {
  fileConfirmationFilesAdded,
  fileConfirmationFilesSortSet,
  openCommentsForFileConfirmationFileIdSet,
  toggleFileConfirmationFileSelected,
  toggleAllFileConfirmationFileSelected,
  fileConfirmationFilesSelectedReset
} = fileConfirmationFilesSlice.actions;

export const {
  selectAll: selectAllFileConfirmationFiles,
  selectById: selectFileConfirmationFileById,
  selectIds: selectFileConfirmationFilesIds
} = fileConfirmationFilesAdapter.getSelectors((state: RootState) => state.fileConfirmationFiles);

export const selectFileConfirmationFilesSort = (state: RootState) => state.fileConfirmationFiles.sort;
export const selectOpenCommentsForFileConfirmationFileId = (state: RootState) => state.fileConfirmationFiles.openCommentsForFileConfirmationFileId;
export const selectFileConfirmationId = (state: RootState, confirmationId: EntityId) => confirmationId;
export const selectFileConfirmationFileEntityId = (state: RootState, fileEntityId: EntityId) => fileEntityId;

export const selectFileConfirmationFilesComments = createSelector([selectAllFileConfirmationFiles, selectFileConfirmationFileEntityId], (fileConfirmationFiles, fileEntityId) => {
  const comments = fileConfirmationFiles.find((fileConfirmationFile) => fileConfirmationFile.fileEntityId === fileEntityId)?.comments || [];
  return comments.filter((comment) => !comment.deleted);
});
export const selectFileConfirmationFilesName = createSelector([selectAllFileConfirmationFiles, selectFileConfirmationFileEntityId], (fileConfirmationFiles, fileEntityId) => {
  return fileConfirmationFiles.find((fileConfirmationFile) => fileConfirmationFile.fileEntityId === fileEntityId)?.name;
});

export const findParentFileEntityName = (parentFileEntityId: EntityId, fileConfirmationFiles: Array<IFileConfirmationFile>) => {
  const firstFile = fileConfirmationFiles.filter((fcf) => fcf.parentFileEntityId === parentFileEntityId).at(0);
  if (firstFile?.filePath && firstFile?.name) {
    const filePathElements = firstFile.filePath.split("/");
    if (filePathElements.length > 1) {
      const indexOfFileName = filePathElements.indexOf(firstFile.name);
      if (indexOfFileName > -1) {
        const filePathWithoutFileNames = filePathElements.splice(0, indexOfFileName);
        return filePathWithoutFileNames.join("/");
      }
      return filePathElements.join("/");
    }
    return firstFile.filePath;
  }
  return "";
};

export const selectFileConfirmationFileParentEntityNameAndIdsSorted = createSelector([selectAllFileConfirmationFiles, selectFileConfirmationId], (fileConfirmationFiles) => {
  const uniqueParentFileEntityIds = getUniqueValues(fileConfirmationFiles, "parentFileEntityId").map((f) => f.parentFileEntityId);
  const uniqueParentIdAndName = uniqueParentFileEntityIds.map((id) => ({ id, name: findParentFileEntityName(id, fileConfirmationFiles) }));
  return uniqueParentIdAndName.sort((a, b) => naturalSortFilesByField(a, b, "name"));
});

export const selectFileConfirmationFilesForConfirmationSorted = createSelector(
  [selectAllFileConfirmationFiles, selectFileConfirmationId, selectFileConfirmationFilesSort],
  (fileConfirmationFiles, fileConfirmationId, sort) => {
    return fileConfirmationFiles
      .filter((fileConfirmationFile) => fileConfirmationFile.fileConfirmationId === fileConfirmationId)
      .sort((a, b) => naturalSortFilesByField(a, b, sort.property, sort.reversed));
  }
);

// TODO: määra kindlamalt
export const selectPendingFileConfirmationFilesForConfirmation = createSelector([selectFileConfirmationFilesForConfirmationSorted], (fileConfirmationFiles) => {
  return fileConfirmationFiles.filter(
    (fileConfirmationFile) =>
      (!fileConfirmationFile.declined && !fileConfirmationFile.declinedByUserId && fileConfirmationFile.confirmCount < fileConfirmationFile.userCount) ||
      (fileConfirmationFile.confirmCount === 0 && fileConfirmationFile.userCount === 0)
  );
});
export const selectConfirmedFileConfirmationFilesForConfirmation = createSelector([selectFileConfirmationFilesForConfirmationSorted], (fileConfirmationFiles) => {
  return fileConfirmationFiles.filter(
    (fileConfirmationFile) => fileConfirmationFile.confirmCount > 0 && fileConfirmationFile.userCount > 0 && fileConfirmationFile.confirmCount === fileConfirmationFile.userCount
  );
});
export const selectDeclinedFileConfirmationFilesForConfirmation = createSelector([selectFileConfirmationFilesForConfirmationSorted], (fileConfirmationFiles) => {
  return fileConfirmationFiles.filter((fileConfirmationFile) => fileConfirmationFile.declined || fileConfirmationFile.declinedByUserId);
});
export const selectFileConfirmationFilesForConfirmationWithDecisionId = createSelector([selectFileConfirmationFilesForConfirmationSorted], (fileConfirmationFiles) => {
  return fileConfirmationFiles.filter((fileConfirmationFile) => fileConfirmationFile.decisionId !== null);
});

export const selectAllFilesSelectedInConfirmation = createSelector([selectFileConfirmationFilesForConfirmationWithDecisionId], (allFiles) => allFiles.every((fcf) => fcf.selected));
export const selectAnyFilesSelectedInConfirmation = createSelector([selectFileConfirmationFilesForConfirmationWithDecisionId], (allFiles) => allFiles.some((fcf) => fcf.selected));
export const selectNumberOfFilesSelectedInConfirmation = createSelector([selectFileConfirmationFilesForConfirmationWithDecisionId], (allFiles) => allFiles.filter((fcf) => fcf.selected).length);

export default fileConfirmationFilesSlice.reducer;
