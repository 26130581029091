import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons/faCaretRight";

const PartyAuthoritiesCardSkeleton: FC = () => {
  return (
    <div className="pb-8">
      <div className="bh-outline-pigeon-40 hover:bh-bg-mint-30 hover:bh-outline-pigeon mt-1px relative flex h-14 cursor-pointer flex-col outline outline-1 transition duration-500 ease-in-out first:rounded-t last:rounded-b hover:z-10">
        <div className="flex h-14 items-center">
          <div className="mx-4 flex h-6 w-6 items-center justify-center">
            <FontAwesomeIcon className="" size="sm" icon={faCaretRight} />
          </div>
          <div className="h-4.5 w-1/3 animate-pulse rounded bg-gray-400" />
        </div>
      </div>
      <div className="bh-outline-pigeon-40 hover:bh-bg-mint-30 hover:bh-outline-pigeon mt-1px relative flex cursor-pointer flex-col outline outline-1 transition duration-500 ease-in-out first:rounded-t last:rounded-b hover:z-10">
        <div className="flex h-14 items-center">
          <div className="mx-4 flex h-6 w-6 items-center justify-center">
            <FontAwesomeIcon className="" size="sm" icon={faCaretRight} />
          </div>
          <div className="h-4.5 w-1/3 animate-pulse rounded bg-gray-400" />
        </div>
      </div>
    </div>
  );
};

export default PartyAuthoritiesCardSkeleton;
