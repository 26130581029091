import React, { FC } from "react";
import { ITask } from "@/model/taskBoard/ITask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons";
import AttachmentsContainerLarge from "@components/attachments/AttachmentsContainerLarge";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectOpenedTaskInfo } from "@/app/store/tasksSlice";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import { fetchPresignedBatchUrl, fetchUrlForFile } from "@/api/fileAPI";
import { imitateBatchFileDownload, imitateUrlDownload } from "@/utilities/downloadUtilities";

interface Props {
  task: ITask;
  saveCallback: Function;
}

const TaskAttachments: FC<Props> = ({ task, saveCallback }) => {
  const { t } = useTranslation();
  const openedTaskInfo = useAppSelector(selectOpenedTaskInfo);
  const isTaskDisabled = openedTaskInfo && openedTaskInfo.disabled;

  const removeAttachment = (removedAttachmentId: any) => {
    const attachmentsToSave = task.attachments.filter((attachment) => attachment.id !== removedAttachmentId);
    saveCallback({ attachments: attachmentsToSave });
  };

  const downloadAllAttachments = () => {
    if (task.attachments && task.attachments.length > 0) {
      if (task.attachments.length === 1) {
        const fileEntity = task.attachments[0];
        fetchUrlForFile(fileEntity.id, false, true, fileEntity.uuid).then((response) => {
          imitateUrlDownload(response.value);
        });
      } else {
        fetchPresignedBatchUrl(task.attachments.map((att) => att.id)).then((result) => imitateBatchFileDownload(result, "excludeChildren=true"));
      }
    }
  };

  return (
    <div className="pt-6">
      <div className="flex flex-row items-center gap-x-2 pl-2 pb-2">
        <FontAwesomeIcon icon={faPaperclip} className={"bh-text-pigeon"} />
        <span className="bh-text-deep-ocean font-bold">{t("TASK.ATTACHMENTS")}</span>
        {task.attachments && task.attachments.length > 0 && (
          <BhTextOnlyButton icon={faArrowDownToLine} buttonProps={{ onClick: downloadAllAttachments }}>
            {t("GLOBAL.DOWNLOAD")}
          </BhTextOnlyButton>
        )}
      </div>
      <div className="flex flex-row flex-wrap items-center pl-2">
        <AttachmentsContainerLarge
          attachments={task.attachments}
          property={"attachments"}
          saveCallback={saveCallback}
          openInNewTab={true}
          removeCallback={!isTaskDisabled ? removeAttachment : undefined}
          addingDisabled={isTaskDisabled}
          multiple={true}
        />
      </div>
    </div>
  );
};

export default TaskAttachments;
