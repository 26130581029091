import { IFileEntity } from "@/model/files/IFileEntity";
import { EntityId } from "@reduxjs/toolkit";

export interface ISharebox {
  id: EntityId;
  name: string;
  uuid: string;
  projectId: EntityId;
  zipUuid: string;
  message: string;
  displayPreference: ShareboxDisplayOption;
  languagePreference: SecurityLevel;
  securityLevel: string;
  validTo: Date;
  // @deprecated
  fileList: Array<IFileEntity>;
  fileIds: Array<EntityId>;
  parentList: Array<IFileEntity>;
  receiverEmails: Array<string>;
  createdBy: string;
  created: Date;
  updated: Date;
  numberOfFiles: number;
  creatorName: string;
}

export enum ShareboxDisplayOption {
  DIRECTORY_TREE = "DIRECTORY_TREE",
  FILE_LIST = "FILE_LIST"
}

// TODO: Rename when implementing displaying groups of files
export enum ShareboxFileViewOption {
  FILE_NAME = "FILE_NAME",
  FILE_ICON = "FILE_ICON"
}

export enum SecurityLevel {
  PUBLIC = "PUBLIC",
  PUBLIC_UNIQUE_URL = "PUBLIC_UNIQUE_URL",
  PASSWORD_PROTECTED = "PASSWORD_PROTECTED",
  PRIVATE = "PRIVATE"
}
