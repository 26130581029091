import React, { FC, useEffect, useState } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import {
  archiveOrActivateChecklistsAsync,
  fetchChecklistsForUserAsync,
  resetChecklistSelection,
  selectAllChecklistsSelected,
  selectAnyOlderRoundIdsSelected,
  selectChecklistFilter,
  selectOnlySingleRoundChecklistsSelected,
  selectSelectedChecklistRoundIds,
  selectSelectedChecklistsIds
} from "@/app/store/checklist/checklistsSlice";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhLightSeparatorVertical from "@components/separator/BhLightSeparatorVertical";
import { imitateBatchFileDownload } from "@/utilities/downloadUtilities";
import { fetchPresignedBatchUrl } from "@/api/fileAPI";
import { fetchChecklistRoundContainerIds, fetchChecklistRoundPdfIds } from "@/api/checklist/checklistAPI";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useTranslation } from "react-i18next";
import BhTooltip from "@components/BhTooltip";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons/faCircleInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";

interface Props {
  archived?: boolean;
  showCopyMultipleRevisionsModal: Function;
  showCopySingleRevisionsModal: Function;
}

const ChecklistsBasketContainer: FC<Props> = ({ archived, showCopyMultipleRevisionsModal, showCopySingleRevisionsModal }) => {
  const { t } = useTranslation();
  const [isArchiveButtonDisabled, setIsArchiveButtonDisabled] = useState<boolean>(false);
  const allSelected = useAppSelector(selectAllChecklistsSelected);
  const selectedChecklistRoundIds = useAppSelector(selectSelectedChecklistRoundIds);
  const selectedChecklistsIds = useAppSelector(selectSelectedChecklistsIds);
  const anyOlderRoundsSelected = useAppSelector(selectAnyOlderRoundIdsSelected);
  const onlySingleRoundChecklistsSelected = useAppSelector(selectOnlySingleRoundChecklistsSelected);
  const filter = useAppSelector(selectChecklistFilter);
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetChecklistSelection());
  }, [archived]);

  useEffect(() => {
    setIsArchiveButtonDisabled(anyOlderRoundsSelected);
  }, [anyOlderRoundsSelected]);

  if (!allSelected && selectedChecklistRoundIds.length === 0) {
    return null;
  }

  const downloadSelectedRoundPdfs = () => {
    fetchChecklistRoundPdfIds(selectedChecklistRoundIds).then((result) => fetchPresignedBatchUrl(result).then((result) => imitateBatchFileDownload(result)));
  };

  const downloadSelectedRoundContainers = () => {
    fetchChecklistRoundContainerIds(selectedChecklistRoundIds).then((result) => fetchPresignedBatchUrl(result).then((result) => imitateBatchFileDownload(result)));
  };

  const archiveSelectedChecklists = () => {
    dispatch(archiveOrActivateChecklistsAsync(selectedChecklistsIds)).then(() => {
      const newFilter = { ...filter, archived: archived };
      dispatch(fetchChecklistsForUserAsync({ projectId: projectId, filter: newFilter }));
      dispatch(resetChecklistSelection());
    });
  };

  const toggleBulkCopyModal = () => {
    onlySingleRoundChecklistsSelected ? showCopySingleRevisionsModal() : showCopyMultipleRevisionsModal();
  };

  return (
    <div className="bh-bg-white bh-shadow fixed left-1/2 bottom-14 flex -translate-x-1/2 flex-col overflow-hidden rounded-xl">
      <div className="flex h-16 flex-row">
        <div className="bh-bg-bauhub-green-120 flex items-center">
          <div className="bh-text-white p-6 font-bold">
            <span className="pl-1">{selectedChecklistRoundIds.length}</span>
          </div>
        </div>
        <div className="flex items-center space-x-6 p-6">
          <BhTextOnlyButton icon={faArrowDownToLine} buttonProps={{ onClick: downloadSelectedRoundPdfs }}>
            {t("CHECKLIST.DOWNLOAD.PDF")}
          </BhTextOnlyButton>
          <BhTextOnlyButton icon={faArrowDownToLine} buttonProps={{ onClick: downloadSelectedRoundContainers }}>
            {t("CHECKLIST.DOWNLOAD.ASICE")}
          </BhTextOnlyButton>
        </div>
        <BhLightSeparatorVertical />
        <div className="flex items-center gap-4 p-4">
          <BhTextOnlyButton
            buttonProps={{
              onClick: toggleBulkCopyModal,
              classes: "text-nowrap"
            }}
            icon={faCopy}
          >
            {t("CHECKLIST.COPY_TO_FILETREE")}
          </BhTextOnlyButton>
        </div>
        <BhLightSeparatorVertical />
        <div className="wrap flex items-center gap-4 p-4">
          <BhTextOnlyButton buttonProps={{ onClick: archiveSelectedChecklists, disabled: isArchiveButtonDisabled }}>{archived ? t("CHECKLIST.RESTORE") : t("CHECKLIST.ARCHIVE")}</BhTextOnlyButton>
          {isArchiveButtonDisabled && (
            <BhTooltip body={t(archived ? "CHECKLISTS.RESTORE.DISABLED" : "CHECKLISTS.ARCHIVE.DISABLED")}>
              <FontAwesomeIcon className="-ml-4 outline-0" cursor="pointer" icon={faCircleInfo} size={"lg"} />
            </BhTooltip>
          )}
        </div>
        <BhLightSeparatorVertical />
        <div className="flex items-center space-x-4 p-4">
          <BhIconButton icon={faXmark} buttonProps={{ onClick: () => dispatch(resetChecklistSelection()) }} />
        </div>
      </div>
    </div>
  );
};

export default ChecklistsBasketContainer;
