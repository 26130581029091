import React, { FC, useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import ContactAuthoritiesRightsCard from "@/views/home/project/detail/users/contacts/contactRightsModal/ContactAuthoritiesRightsCard";
import ContactAuthoritiesFileCard from "@/views/home/project/detail/users/contacts/contactRightsModal/ContactAuthoritiesFileCard";
import ContactAuthoritiesTaskboardCard from "@/views/home/project/detail/users/contacts/contactRightsModal/ContactAuthoritiesTaskboardCard";
import { EntityId } from "@reduxjs/toolkit";
import { IUserPartyDataResponse } from "@/model/contacts/IContactRightsModal";
import { fetchContactWorkGroupAuthorities } from "@/api/contactAPI";
import { useAppSelector } from "@/app/hooks";
import { selectEditableContact } from "@/app/store/project/projectContactsSlice";
import { useTranslation } from "react-i18next";

interface Props {
  isContainerLoading: boolean;
  workGroupId?: EntityId;
}
const ContactRightsModalDetail: FC<Props> = ({ isContainerLoading, workGroupId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const [contactWorkGroupAuthorities, setContactWorkGroupAuthorities] = useState<IUserPartyDataResponse | null>(null);
  const editableContact = useAppSelector(selectEditableContact);

  useEffect(() => {
    if (!isContainerLoading && workGroupId) {
      fetchContactWorkGroupAuthorities(workGroupId, editableContact)
        .then((authorities) => setContactWorkGroupAuthorities(authorities))
        .then(() => setIsLoading(false));
    }

    return function cleanup() {
      setContactWorkGroupAuthorities(null);
    };
  }, []);

  return (
    <Tab.Panel>
      <ContactAuthoritiesRightsCard isLoading={isLoading} userAuthorities={contactWorkGroupAuthorities?.userAuthorities || []} />
      <ContactAuthoritiesFileCard isLoading={isLoading} userFiles={contactWorkGroupAuthorities?.partyFiles || []} title={`${t("GLOBAL.DRAWINGS")}`} />
      <ContactAuthoritiesFileCard isLoading={isLoading} userFiles={contactWorkGroupAuthorities?.partyDocuments || []} title={`${t("GLOBAL.DOCUMENTS")}`} />
      <ContactAuthoritiesTaskboardCard isLoading={isLoading} userTaskBoards={contactWorkGroupAuthorities?.workGroupTaskBoardsWithUserAuth || []} />
    </Tab.Panel>
  );
};

export default ContactRightsModalDetail;
