import React, { Dispatch, FC, SetStateAction } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { IFormBase } from "@/model/form/IFormBase";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectAllCompanyActiveProjects } from "@/app/store/companiesSlice";
import BhModal from "@components/modal/BhModal";
import BhToggleRow from "@components/toggle/BhToggleRow";
import { classNames } from "@/utilities/jsUtilities";
import BhModalFooter from "@components/modal/BhModalFooter";
import { selectActivatedBasesInCompanyProjects } from "@/app/store/form/formBasesSlice";

interface Props {
  setIsShown: Dispatch<SetStateAction<boolean>>;
  formBase: IFormBase;
  companyId: EntityId;
  addFormBaseToProjects: Function;
  removeFormBaseFromProjects: Function;
}

const ActivateFormBaseInProjectsModal: FC<Props> = ({ setIsShown, formBase, addFormBaseToProjects, removeFormBaseFromProjects, companyId }) => {
  const { t } = useTranslation();
  const companyProjects = useAppSelector((state) => selectAllCompanyActiveProjects(state, companyId)).sort((a, b) => (a.name > b.name ? 1 : -1));
  const activatedBasesInCompanyProjects = useAppSelector(selectActivatedBasesInCompanyProjects);
  const isBauhubFormTemplate = formBase.bauhubFormBase;
  const formDisplayName = isBauhubFormTemplate ? t(`FORMS.${formBase.type}`) : formBase.type;

  const companyProjectsWithSelected = companyProjects.map((project) => {
    const isFormBaseActivatedInProject = activatedBasesInCompanyProjects.some((pfb) => pfb.formBaseId === formBase.id && pfb.projectId === project.id);
    return { ...project, selected: isFormBaseActivatedInProject };
  });
  const baseInAllProjects =
    companyProjectsWithSelected.filter((project) => {
      return project.selected;
    }).length === companyProjects.length;

  if (!companyProjects || companyProjects.length === 0) return null;

  const toggleBaseInProject = (project: any) => {
    if (project.selected) {
      removeFormBaseFromProjects([project.id]);
    } else {
      addFormBaseToProjects([project.id]);
    }
  };

  const toggleBaseInAllProjects = () => {
    if (baseInAllProjects) {
      const projectsSelectedIds = companyProjectsWithSelected.filter((project) => project.selected).map((p) => p.id);
      removeFormBaseFromProjects(projectsSelectedIds);
    } else {
      const projectsNotSelectedIds = companyProjectsWithSelected.filter((project) => !project.selected).map((p) => p.id);
      addFormBaseToProjects(projectsNotSelectedIds);
    }
  };

  return (
    <BhModal
      isShown={true}
      size={"5xl"}
      setIsShown={setIsShown}
      header={
        <div className="h-7">
          <h2 className="mt-1 leading-7">
            <span className="bh-text-deep-ocean-60">{t("FORMBUILDER.FORM.BASE.MANAGE.HEADER")} – </span>
            <span className="bh-text-deep-ocean">{formDisplayName}</span>
          </h2>
        </div>
      }
      children={
        <div className="h-full w-full overflow-auto px-20 pb-16">
          <div className="my-8">{t("FORMBUILDER.FORM.BASE.MANAGE.BODY")}</div>
          <BhToggleRow
            toggleObject={{ id: 0, selected: baseInAllProjects } as any}
            toggleProperty={"selected"}
            onToggle={toggleBaseInAllProjects}
            label={<div className="bh-text-deep-ocean text-14px font-bold leading-5">{t("PROJECT.SIDEBAR.ALL_PROJECTS")}</div>}
            classes={"pb-5"}
          />
          {companyProjectsWithSelected &&
            companyProjectsWithSelected.map((project, index) => {
              return (
                <BhToggleRow
                  key={project.id}
                  toggleObject={project}
                  toggleProperty={"selected"}
                  onToggle={() => toggleBaseInProject(project)}
                  label={<span className="bh-text-deep-ocean text-14px leading-5">{project.name}</span>}
                  classes={classNames("pt-5 bh-border-pigeon-20 border-t", index !== companyProjects.length - 1 && "pb-5")}
                />
              );
            })}
        </div>
      }
      footer={<BhModalFooter onCancel={() => setIsShown(false)} cancelButtonText={t("GLOBAL.CLOSE") as string} />}
    />
  );
};

export default ActivateFormBaseInProjectsModal;
