import React, { FC, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import useHandleFormSubmit from "@/utilities/hooks/useHandleFormSubmit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { saveNewDirectoryAsync, selectCurrentDirId, setDirectoryModalsOpen } from "@/app/store/filesSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useTranslation } from "react-i18next";
import { store } from "@/app/store";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";

const NewFolderModal: FC = () => {
  const [newFolder] = useState({} as IFileEntity);
  const handleFormSubmit = useHandleFormSubmit<IFileEntity>();
  const parentFileEntityId = useAppSelector(selectCurrentDirId);
  const [saving, setSaving] = useState(false);
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleSubmit = (formValues: IFileEntity) => {
    if (!parentFileEntityId || !projectId) return;
    const savableFileEntityObject = { ...newFolder, ...formValues, ...({ parentFileEntityId: parentFileEntityId, projectId: projectId, type: FileEntityType.DIR } as IFileEntity) };

    if (!!savableFileEntityObject) {
      if (savableFileEntityObject.name.length < 1) {
        store.dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.WARNING,
            disappear: true,
            translateCode: "GLOBAL.FILL_REQUIRED_FIELDS"
          })
        );
        return;
      }
      setSaving(true);
      dispatch(saveNewDirectoryAsync(savableFileEntityObject)).then((result) => {
        dispatch(setDirectoryModalsOpen({ modal: "newFolderModal", value: false }));
        setSaving(false);
      });
    }
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={() => dispatch(setDirectoryModalsOpen({ modal: "newFolderModal", value: false }))}
      header={<h2>{t("GLOBAL.ADD_NEW_FOLDER")}</h2>}
      size="md"
      children={
        <div className="w-full py-4 px-8">
          <form id="newFolderForm" className="flex flex-col" onSubmit={handleFormSubmit((folder) => handleSubmit(folder))}>
            <BhInputStackedLabel initialValue={newFolder.name} property={"name"} label={t("MODAL.NEW_DIR.DIRNAME_PLACEHOLDER")} placeholder={" "} autoFocus={true} onEnterCallback={handleSubmit} />
          </form>
        </div>
      }
      footer={
        <div className="flex flex-row items-center">
          <BhTextOnlyButton buttonProps={{ onClick: () => dispatch(setDirectoryModalsOpen({ modal: "newFolderModal", value: false })) }}>{t("GLOBAL.CLOSE")}</BhTextOnlyButton>
          <BhPrimaryButton buttonProps={{ submitForm: "newFolderForm", disabled: saving }}>{t("GLOBAL.ADD")}</BhPrimaryButton>
        </div>
      }
    />
  );
};

export default NewFolderModal;
