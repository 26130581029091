import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BillingForm from "@/views/home/project/detail/projectSettings/BillingForm";
import { CompanyInvoiceType, ICompanyBillingRequisites } from "@/model/IBillingInfo";
import { useTranslation } from "react-i18next";
import BhModalFooter from "@components/modal/BhModalFooter";
import { changeCompanyBillingRequisites } from "@/api/billingAPI";
import { isValidEmails } from "@/utilities/jsUtilities";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";

interface Props {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  companyBilling: ICompanyBillingRequisites;
  setCompanyBilling: Function;
  changeInvoiceTypeCallback?: Function;
}

const CompanySettingsCompanyBillingInfoModal: FC<Props> = ({ isShown, setIsShown, companyBilling, setCompanyBilling, changeInvoiceTypeCallback }) => {
  const [companyBillingRequisites, setCompanyBillingRequisites] = useState<ICompanyBillingRequisites>(companyBilling);
  const { t } = useTranslation();

  useEffect(() => {
    setCompanyBillingRequisites(companyBilling);
  }, [companyBilling]);

  const isSaveButtonDisabled =
    !companyBillingRequisites.customerRecipientEmails ||
    companyBillingRequisites.customerRecipientEmails.length === 0 ||
    !isValidEmails(companyBillingRequisites.customerRecipientEmails) ||
    !companyBillingRequisites.customerRecipient ||
    companyBillingRequisites.customerRecipient.length === 0 ||
    (!companyBillingRequisites.customerCivilian && (!companyBillingRequisites.customerRegCode || companyBillingRequisites.customerRegCode.length === 0));

  const onSave = async () => {
    setIsShown(false);
    changeCompanyBillingRequisites(companyBillingRequisites).then(async (response) => {
      await setCompanyBilling(response);
      if (response.invoiceType === CompanyInvoiceType.SINGLE && changeInvoiceTypeCallback) {
        changeInvoiceTypeCallback(CompanyInvoiceType.AGGREGATE);
      }
    });
  };

  const onCancel = () => {
    setIsShown(false);
    setCompanyBillingRequisites(companyBilling);
  };

  const onFormChange = (changedValue: ICompanyBillingRequisites) => {
    setCompanyBillingRequisites((prevState) => {
      return { ...prevState, ...changedValue };
    });
  };

  return (
    <BhModal
      isShown={isShown}
      setIsShown={setIsShown}
      onClose={onCancel}
      size={"2xl"}
      header={<h2>{t("COMPANY.BILLING.CHANGE.MODAL.HEADER")}</h2>}
      children={
        <BhScrollableBody>
          <div className="px-8">
            <BillingForm billingForm={companyBillingRequisites} dontShowProjectBillingRequisitesType={true} onChange={onFormChange} />
          </div>
        </BhScrollableBody>
      }
      footer={<BhModalFooter onCancel={onCancel} onConfirm={onSave} confirmDisabled={isSaveButtonDisabled} />}
    />
  );
};

export default CompanySettingsCompanyBillingInfoModal;
