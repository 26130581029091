import React, { FC, useEffect } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectProfileAuthSettingsPersonalizationModalOpen, selectProfileAuthSettingsPhoneModalOpen, selectUserMailSettingsModalOpen } from "@/app/store/userPreferenceSlice";
import UserMailSettingsModal from "@/views/home/profile/modals/UserMailSettingsModal";
import ProfileAuthSettingsPhoneModal from "@/views/home/profile/ProfileTabs/profileAuthSettings/ProfileAuthSettingsPhoneModal";
import ProfileAuthSettingsPersonalizationModal from "@/views/home/profile/ProfileTabs/profileAuthSettings/ProfileAuthSettingsPersonalizationModal";

const UserProfileModals: FC = () => {
  const userMailSettingsModalOpen = useAppSelector(selectUserMailSettingsModalOpen);
  const profileAuthSettingsPhoneModalOpen = useAppSelector(selectProfileAuthSettingsPhoneModalOpen);
  const profileAuthSettingsPersonalizationModalOpen = useAppSelector(selectProfileAuthSettingsPersonalizationModalOpen);


  return (
    <>
      {userMailSettingsModalOpen && <UserMailSettingsModal />}
      {profileAuthSettingsPhoneModalOpen && <ProfileAuthSettingsPhoneModal />}
      {profileAuthSettingsPersonalizationModalOpen && <ProfileAuthSettingsPersonalizationModal />}
    </>
  );
};

export default UserProfileModals;
