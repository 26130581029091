import React, { FC } from "react";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/pro-regular-svg-icons/faArrowUp";
import { faArrowDown } from "@fortawesome/pro-regular-svg-icons/faArrowDown";
import { classNames } from "@/utilities/jsUtilities";

interface Props<T> {
  columns: Array<IBhTableHeader<T>>;
  onClickCallback?: Function;
  sortedBy: keyof T;
  reversed: boolean;
}

const BhDivTableHeader: FC<Props<any>> = ({ columns, onClickCallback, sortedBy, reversed }) => {
  return (
    <div className="bh-border-pigeon-40 bh-table-header-text flex min-h-[40px] border-b py-1 font-bold">
      {columns.map((column) => (
        <div key={column.id} className={classNames("flex items-center px-1.5", column.classes)}>
          <span onClick={() => column.sortable && onClickCallback?.(column)}>
            <span className={classNames(column.field && "hover:bh-bg-smoke cursor-pointer", "rounded py-0.5 px-1")}>{column.content}</span>
            {sortedBy && sortedBy === column.field && (
              <button className="bh-text-deep-ocean-imp hover:bh-bg-smoke disabled:bh-text-pigeon-60 bh-text-pigeon mx-0.5 rounded p-0.5 font-bold">
                <FontAwesomeIcon className="px-1" icon={reversed ? faArrowDown : faArrowUp} />
              </button>
            )}
          </span>
        </div>
      ))}
    </div>
  );
};

export default BhDivTableHeader;
