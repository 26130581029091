import { EntityId } from "@reduxjs/toolkit";
import { IFileEntity } from "@/model/files/IFileEntity";
import { IActWork } from "@/model/IActWork";
import { ISigner } from "@/model/ISigner";
import { ISignerGroup } from "@/model/ISignerGroup";

export interface IAct {
  created: Date;
  id: EntityId;
  workGroupId: EntityId;
  projectId: EntityId;
  number: string;
  deleted: boolean;
  status: ActStatus;
  name: string;
  certifyingPeriod: string;
  files: Array<IFileEntity>;
  works: Array<IActWork>;
  actDate: Date;
  signers: Array<ISigner>;
  totalCertified: number;
  actTotalCertified: number;
  vatPercentage: number;
  vatAdded: number;
  totalWithVat: number;
  deductibleAdvancePayment: number;
  deposit: number;
  priceToPay: number;
  actSignerGroups: Array<ISignerGroup>;
  attachments: Array<IFileEntity>;
  fileRelations: Array<IActToFileEntity>;
  digiDocContainerId: EntityId;
  latest: boolean;
  actSendingStatus: SendingStatus;
  createdByFullName: string;
  signaturesCount: number;
}

export enum ActStatus {
  SIGNING = "SIGNING",
  SIGNED = "SIGNED",
  EDITABLE = "EDITABLE",
  REVIEWING = "REVIEWING",
  APPROVED = "APPROVED",
  REFUSED = "REFUSED",
  NO_SIGNERS = "NO_SIGNERS",
  DECLINED = "DECLINED"
}

export enum SendingStatus {
  NOT_SENT = "NOT_SENT",
  SENT = "SENT",
  FAILED = "FAILED"
}

export enum ActToFileEntityType {
  ATTACHMENT = "ATTACHMENT",
  ACT_EXTRA_DOCUMENT = "ACT_EXTRA_DOCUMENT"
}

export interface IActToFileEntity {
  id: EntityId;
  actId: EntityId;
  fileEntityId: EntityId;
  type: ActToFileEntityType;
  deleted: boolean;
  file: IFileEntity;
}

export interface IActExternalInfo {
  id: EntityId;
  actId: EntityId;
  recipient: string;
  sender: string;
  status: SendingStatus;
  messageCode: string;
  xmlRequest: string;
  created: Date;
}
