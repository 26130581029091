import React, { FC, useState } from "react";
import BhChildComponentWithFetchValues from "@components/input/BhChildComponentWithFetchValues";
import { fetchRelatedUsersInProjectWithSearch } from "@/api/projectAPI";
import { convertSimpleValidUserAuthorityToUser } from "@/model/ISimpleValidUserAuthority";
import { IUser } from "@/model/IUser";
import { InviteType, ISignInvite } from "@/model/ISignInvite";
import { saveSignInviteAsync } from "@/app/store/signaturesSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import { IFileEntity } from "@/model/files/IFileEntity";
import { useTranslation } from "react-i18next";
import BhSearchInputWithUserDropdownAndCustomEmailOption from "@components/input/BhSearchInputWithUserDropdownAndCustomEmailOption";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";

interface Props {
  signatures: Array<ISignatureHolder>;
  containerFileEntity: IFileEntity;
}

const RegularSignatureAddUserInput: FC<Props> = ({ signatures, containerFileEntity }) => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [pendingPublicUser, setPendingPublicUser] = useState<IUser | undefined>();

  const [showPublicEmailInviteModal, hidePublicEmailInviteModal] = useModal(
    () => (
      <BhDeleteConfirmationModal
        setIsOpen={() => {
          hidePublicEmailInviteModal();
        }}
        header={<h2>{t("MODAL.PUBLIC_INVITE.TITLE")}</h2>}
        body={<div>{t("MODAL.PUBLIC_INVITE.BODY")}</div>}
        confirmationButtonText={t("GLOBAL.SEND")}
        handleDelete={() => {
          pendingPublicUser && addSigner(pendingPublicUser);
          hidePublicEmailInviteModal();
        }}
      />
    ),
    [pendingPublicUser]
  );

  const findBiggestOrderNumber = (): number => {
    if (signatures.length === 0) {
      return 0;
    }
    const biggestOrderNumber = signatures.sort((a, b) => a.orderNumber - b.orderNumber).reverse()[0];
    return biggestOrderNumber.orderNumber;
  };

  const addSignerOrShowPublicInviteModal = (value: IUser) => {
    if (!value.id) {
      setPendingPublicUser(value);
      showPublicEmailInviteModal();
    } else {
      addSigner(value);
    }
  };

  const addSigner = (value: IUser) => {
    const user = value;
    let signInvite: ISignInvite = {
      username: user.username,
      signed: false,
      projectId: projectId,
      containerUuid: containerFileEntity.uuid,
      fileEntityId: containerFileEntity.id
    } as ISignInvite;

    if (!user.id) {
      signInvite.inviteType = InviteType.PUBLIC;
    }

    if (containerFileEntity.queueEnabled) {
      signInvite = { ...signInvite, ...{ orderNumber: findBiggestOrderNumber() + 10, declined: false } };
    }

    dispatch(saveSignInviteAsync(signInvite));
    setPendingPublicUser(undefined);
  };

  const showValueCondition = (user: IUser) => {
    return !signatures.some((s) => s.username === user.username);
  };

  return (
    <BhChildComponentWithFetchValues
      fetchValues={() => {
        return fetchRelatedUsersInProjectWithSearch(projectId).then((users) => {
          return users.map((simpleValidUser) => convertSimpleValidUserAuthorityToUser(simpleValidUser));
        });
      }}
      child={
        <BhSearchInputWithUserDropdownAndCustomEmailOption
          values={[]}
          onSelect={addSignerOrShowPublicInviteModal}
          showValueCondition={showValueCondition}
          placeholder={t("CONTAINER.SIGN_INVITE_PLACEHOLDER") as string}
        />
      }
    />
  );
};

export default RegularSignatureAddUserInput;
