import React, { FC } from "react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhBadge from "@components/badge/BhBadge";
import { selectSearchString, selectUserIdsInSearch } from "@/app/store/searchSlice";
import { useAppSelector } from "@/app/hooks";
import { useNavigate } from "react-router-dom";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import SearchUserCardContainer from "@/features/searchWithDropdown/SearchUserCardContainer";
import { Trans } from "react-i18next";

const SearchDropdownUserResults: FC = () => {
  const searchResultUserIds = useAppSelector(selectUserIdsInSearch);
  const projectId = useAppSelector(selectCurrentProjectId);
  const searchString = useAppSelector(selectSearchString);
  const navigate = useNavigate();

  if (!searchResultUserIds) return null;

  let userResultIdsInDropdown = searchResultUserIds.length > 4 ? searchResultUserIds?.slice(0, 4) : searchResultUserIds;
  let hasMoreThanFourResults = searchResultUserIds.length > 4;
  let remainingUserIdsCount = searchResultUserIds.length - userResultIdsInDropdown.length;

  const goToAllUserResults = () => {
    navigate(`/project/${projectId}/search/users?query=${searchString}`);
  };

  return (
    <div className="px-6">
      <h4 className="bh-text-deep-ocean mb-5">
        <Trans>SEARCH.USERS</Trans>
      </h4>
      <div className="flex flex-col gap-1">
        {userResultIdsInDropdown.map((userId) => {
          return <SearchUserCardContainer key={userId} userId={userId} />;
        })}
      </div>
      {hasMoreThanFourResults && (
        <BhTextOnlyButton buttonProps={{ classes: "mt-3", onClick: goToAllUserResults }}>
          <BhBadge classes="mr-1">+{remainingUserIdsCount}</BhBadge>
          <Trans>DASHBOARD.INFO.SEE_ALL</Trans>
        </BhTextOnlyButton>
      )}
    </div>
  );
};

export default SearchDropdownUserResults;
