import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { IFormBase } from "@/model/form/IFormBase";
import ProjectFormsSettingsListRow from "@components/form/settings/projectSettings/ProjectFormsSettingsListRow";
import { FormBaseVersionStatus, IFormBaseVersion } from "@/model/form/IFormBaseVersion";

interface Props {
  formBase: IFormBase;
  setFilter: Dispatch<SetStateAction<any>>;
}

const ProjectFormsSettingsListRowContainer: FC<Props> = ({ formBase, setFilter }) => {
  const sortedVersions = formBase.formBaseVersions && formBase.formBaseVersions.slice().sort((a, b) => (a.versionNumber < b.versionNumber ? 1 : -1));
  const [visibleVersions, setVisibleVersions] = useState([] as Array<IFormBaseVersion>);
  const [showAllVersions, setShowAllVersions] = useState<boolean>(false);

  useEffect(() => {
    if (sortedVersions) {
      const moreThanOneVersion = sortedVersions.length > 1;
      const lastVersionInDraft = sortedVersions[0].status === FormBaseVersionStatus.DRAFT;
      if (showAllVersions) {
        if (moreThanOneVersion && lastVersionInDraft) {
          const lastVersion = sortedVersions[0];
          const versionsToShow = sortedVersions.map((version) => version);
          versionsToShow.splice(0, 1);
          versionsToShow.splice(1, 0, lastVersion);
          setVisibleVersions(versionsToShow);
        } else {
          setVisibleVersions(sortedVersions);
        }
      } else {
        const versionsToShow = moreThanOneVersion && lastVersionInDraft ? [sortedVersions[1], sortedVersions[0]] : [sortedVersions[0]];
        setVisibleVersions(versionsToShow);
      }
    }
  }, [showAllVersions, formBase]);

  const toggleShowAllVersions = () => {
    setShowAllVersions(!showAllVersions);
  };

  return (
    <>
      {visibleVersions &&
        visibleVersions.map((version) => {
          return (
            <ProjectFormsSettingsListRow
              key={version?.id || formBase.id}
              formBase={formBase}
              formBaseVersion={version}
              isLastVersion={version.id === visibleVersions[0].id}
              toggleShowAllVersions={toggleShowAllVersions}
              allVersionsVisible={showAllVersions}
              anyVersionInDraft={sortedVersions.some((fbv) => fbv.status === FormBaseVersionStatus.DRAFT)}
              setFilter={setFilter}
            />
          );
        })}
    </>
  );
};

export default ProjectFormsSettingsListRowContainer;
