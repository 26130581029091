import React, { FC, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// @ts-ignore
import _ from "lodash";
import { FolderOpenStatus, IFolderFileEntity } from "@/model/files/IFileEntity";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchSubDirsAsync, makeSelectSubDirs, selectedInFileTreeFolderSet, selectFolderById, toggleFolderOpen } from "@/app/store/foldersSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { classNames } from "@/utilities/jsUtilities";
import { faFolder } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { EntityId } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";
import BhTooltip from "@components/BhTooltip";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { Trans } from "react-i18next";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

export enum FolderContainerSize {
  SMALL = "SMALL",
  LARGE = "LARGE"
}

interface Props {
  folderId: EntityId;
  removeFolderIconAndBoldText?: boolean;
  translate?: boolean;
}

const areFolderObjectsEqual = (left?: IFolderFileEntity, right?: IFolderFileEntity) => {
  if (!left || !right) return false;
  const { selectedInFileTree: selectedInFileTreePrev, ...withoutSelectedInFileTreePrev } = left;
  const { selectedInFileTree: selectedInFileTreeNext, ...withoutSelectedInFileTreeNext } = right;
  const withoutSelectedInFileTree = Object.entries(withoutSelectedInFileTreePrev).toString() === Object.entries(withoutSelectedInFileTreeNext).toString();
  return withoutSelectedInFileTree && !selectedInFileTreePrev === !selectedInFileTreeNext;
};

const areSubDirObjectsEqual = (prevProps?: Array<EntityId>, nextProps?: Array<EntityId>) => {
  return _.isEmpty(_.xor(prevProps, nextProps));
};

const FolderContainerFC: FC<Props> = ({ folderId, removeFolderIconAndBoldText, translate }) => {
  const folderFileEntity = useAppSelector<IFolderFileEntity | undefined>((state) => selectFolderById(state, folderId), areFolderObjectsEqual);
  const memoSelectSubDirs = useMemo(makeSelectSubDirs, []);
  const subDirs = useAppSelector<Array<EntityId>>((state) => memoSelectSubDirs(state, folderId), areSubDirObjectsEqual);
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();

  if (!folderId || !folderFileEntity) return null;

  const isSelected = folderFileEntity.selectedInFileTree;
  const isOpen = folderFileEntity.openStatus === FolderOpenStatus.OPEN;
  const isExpanded = folderFileEntity.openStatus === FolderOpenStatus.EXPANDED;
  const showChildren = isOpen || isExpanded;

  return (
    <div className="group block">
      <div className={classNames(isSelected ? "bh-bg-mint-110" : "", "hover:bh-bg-mint-50 rounded-sm")}>
        <div className={classNames(removeFolderIconAndBoldText && "pl-2", "flex h-7 flex-row items-center py-2 pr-2")}>
          {!removeFolderIconAndBoldText && (
            <span
              className="flex h-7 w-7 cursor-pointer items-center"
              onClick={() => {
                dispatch(toggleFolderOpen(folderId));
                dispatch(fetchSubDirsAsync(folderId));
              }}
            >
              <FontAwesomeIcon className="h-3 w-7" icon={isOpen ? faAngleDown : faAngleRight} aria-hidden="true" />
            </span>
          )}
          <div className="flex w-full flex-col overflow-hidden">
            <BhTooltip body={folderFileEntity.name} delayShow={1000}>
              <Link
                className="flex flex-row items-center"
                to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/dir/${folderId}`}
                onClick={() => dispatch(selectedInFileTreeFolderSet(folderId))}
              >
                {!removeFolderIconAndBoldText && (
                  <FontAwesomeIcon className={classNames(isSelected ? "bh-text-bauhub-green-120" : "bh-text-pigeon-60", "w-11px mr-1.5")} icon={faFolder} aria-hidden="true" />
                )}
                <div className={classNames((isSelected || removeFolderIconAndBoldText) && "font-bold", "text-13 flex-1 overflow-hidden truncate")}>
                  {translate ? <Trans>{folderFileEntity?.name}</Trans> : folderFileEntity?.name}
                </div>
              </Link>
            </BhTooltip>
          </div>
        </div>
      </div>
      {subDirs && showChildren && (
        <div className={classNames(!removeFolderIconAndBoldText && "ml-2")}>
          {subDirs.map((subDirId) => {
            return <FolderContainer folderId={subDirId} key={subDirId} />;
          })}
        </div>
      )}
    </div>
  );
};

const FolderContainer = React.memo(FolderContainerFC);
export default FolderContainer;
