import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";

interface Props {
  children: React.ReactNode;
}

// @ts-ignore
const RequireProjectAdminAuth: FC<Props> = ({ children }) => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));

  return isProjectAdmin ? children : null;
};

export default RequireProjectAdminAuth;
