import React, { FC, ReactNode } from "react";

interface Props {
  right: ReactNode;
  children: ReactNode;
}

const BhFilterContainerWithItemsOnRight: FC<Props> = ({ right, children }) => {
  return (
    <div className="flex flex-row items-center justify-between">
      {children}
      <div className="flex items-center">{right}</div>
    </div>
  );
};

export default BhFilterContainerWithItemsOnRight;
