import React, { useCallback } from "react";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { refreshMaruProjectNameAsync, saveCurrentProjectAsync, saveRTProjectCodeAsync, selectCurrentProject, selectMaruProjectModule, selectRTBudgetModule } from "@/app/store/project/projectSlice";
import { BauhubBannerType, IProject } from "@/model/IProject";
import { countriesMap } from "@/utilities/countriesMap";
import BhComboboxStackedLabel from "@components/input/BhComboboxStackedLabel";
import { useTranslation } from "react-i18next";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons/faArrowsRotate";
import { isCurrentUserMaruProjectManager, isCurrentUserMaruProjectTeamMember } from "@/app/store/userSlice";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";

const ProjectDetailsFormContainer = () => {
  const project = useAppSelector(selectCurrentProject);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const RTBudgetModule = useAppSelector(selectRTBudgetModule);
  const RTProjectCode = RTBudgetModule && RTBudgetModule.adminSettings && RTBudgetModule.adminSettings.projectCode ? RTBudgetModule.adminSettings.projectCode : "";
  const maruProjectModule = useAppSelector(selectMaruProjectModule);
  const isCurrentUserMaruPM = useAppSelector((state) => isCurrentUserMaruProjectManager(state, project.id));
  const isCurrentUserMaruTeamMember = useAppSelector((state) => isCurrentUserMaruProjectTeamMember(state, project.id));
  const fieldEditDisabled = isCurrentUserMaruPM || isCurrentUserMaruTeamMember;

  const countries = countriesMap;
  const countryNames = countries.map((c) => c.country);
  const selectedCountryStates = countries.find((country) => country.country === project.country)?.states;

  const onProjectSubmit = useCallback(
    (changedValue: IProject) => {
      if (changedValue?.name?.length < 1) {
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.ERROR,
            disappear: true,
            children: t("INPUT.NOT_EMPTY")
          })
        );
        return;
      }
      let changedObject = { ...project, ...changedValue };
      if (Object.keys(changedValue)[0] === "country") {
        changedObject = { ...changedObject, state: undefined };
      }
      dispatch(saveCurrentProjectAsync(changedObject));
    },
    [project]
  );

  const updateMaruProjectName = () => {
    dispatch(refreshMaruProjectNameAsync(project.id));
  };

  const saveRTProjectCode = (code: any) => {
    dispatch(saveRTProjectCodeAsync({ projectId: project.id, projectCode: code.projectCode }));
  };

  return (
    <div>
      <div className="relative mt-6">
        {maruProjectModule && (
          <div className="absolute -left-12 bottom-0">
            <BhIconButton icon={faArrowsRotate} buttonProps={{ onClick: updateMaruProjectName }} />
          </div>
        )}
        <BhInputStackedLabel
          initialValue={project.name || ""}
          property="name"
          label={t("FORMS.PROJECT_NAME") as string}
          onChangeCallback={onProjectSubmit}
          onBlurAction={onProjectSubmit}
          debounceLength={900}
          disabled={maruProjectModule !== undefined}
          maxLength={300}
        />
      </div>
      {RTBudgetModule && (
        <div className="mt-6">
          <BhInputStackedLabel
            initialValue={RTProjectCode}
            property="projectCode"
            label={t("PROJECT.CODE") as string}
            onChangeCallback={saveRTProjectCode}
            onBlurAction={saveRTProjectCode}
            debounceLength={900}
            maxLength={300}
          />
        </div>
      )}
      <div className="mt-6 flex flex-row">
        <div className="mr-8 flex-1 basis-1/2">
          <BhComboboxStackedLabel
            initialValue={project.country}
            onSelect={onProjectSubmit}
            values={countryNames}
            property="country"
            label={t("GLOBAL.COUNTRY") as string}
            placeholder={project.country}
            disabled={fieldEditDisabled}
          />
        </div>
        <div className="flex-1 basis-1/2">
          <BhComboboxStackedLabel
            initialValue={project.state}
            onSelect={onProjectSubmit}
            values={selectedCountryStates ? selectedCountryStates : []}
            property="state"
            label={t("GLOBAL.STATE") as string}
            placeholder={project.state}
            disabled={fieldEditDisabled}
          />
        </div>
      </div>
      <div className="mt-6 flex flex-row">
        <div className="mr-8 flex-1 basis-1/2">
          <BhInputStackedLabel
            initialValue={project.city || ""}
            property="city"
            label={t("GLOBAL.CITY") as string}
            onChangeCallback={onProjectSubmit}
            onBlurAction={onProjectSubmit}
            debounceLength={900}
            disabled={fieldEditDisabled}
          />
        </div>
        <div className="flex-1 basis-1/2">
          <BhInputStackedLabel
            initialValue={project.zipCode || ""}
            property="zipCode"
            label={t("GLOBAL.ZIP") as string}
            onChangeCallback={onProjectSubmit}
            onBlurAction={onProjectSubmit}
            debounceLength={900}
            disabled={fieldEditDisabled}
          />
        </div>
      </div>
      <div className="mt-6 flex flex-row">
        <div className="mr-8 flex-1 basis-1/2">
          <BhInputStackedLabel
            initialValue={project.street || ""}
            property="street"
            label={t("COMPANY.STREET") as string}
            onChangeCallback={onProjectSubmit}
            onBlurAction={onProjectSubmit}
            debounceLength={900}
            disabled={fieldEditDisabled}
          />
        </div>
        <div className="flex-1 basis-1/2">
          <BhInputStackedLabel
            initialValue={project.house || ""}
            property="house"
            label={t("COMPANY.HOUSE") as string}
            onChangeCallback={onProjectSubmit}
            onBlurAction={onProjectSubmit}
            debounceLength={900}
            disabled={fieldEditDisabled}
          />
        </div>
      </div>
      <div className="mt-6 flex flex-row">
        <div className="mr-8 flex-1 basis-1/2">
          <BhInputStackedLabel
            initialValue={project.contractNumber || ""}
            property="contractNumber"
            label={t("MODAL.NEW_PROJECT.CONTRACTNUMBER_PLACEHOLDER") as string}
            onChangeCallback={onProjectSubmit}
            onBlurAction={onProjectSubmit}
            debounceLength={900}
            disabled={fieldEditDisabled}
          />
        </div>
        <div className="flex-1 basis-1/2"></div>
      </div>
    </div>
  );
};

export default ProjectDetailsFormContainer;
