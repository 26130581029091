import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { IChecklistBase } from "@/model/checklist/IChecklistBase";
import { ChecklistBaseVersionStatus, IChecklistBaseVersion } from "@/model/checklist/IChecklistBaseVersion";
import ProjectChecklistSettingsListRow from "@components/checklists/settings/projectSettings/ProjectChecklistSettingsListRow";

interface Props {
  checklistBase: IChecklistBase;
  filter: any;
  setFilter: Dispatch<SetStateAction<any>>;
}

const ProjectChecklistSettingListRowContainer: FC<Props> = ({ checklistBase, filter, setFilter }) => {
  const sortedVersions = checklistBase.checklistBaseVersions && checklistBase.checklistBaseVersions.slice().sort((a, b) => (a.versionNumber < b.versionNumber ? 1 : -1));
  const [visibleVersions, setVisibleVersions] = useState([] as Array<IChecklistBaseVersion>);
  const [showAllVersions, setShowAllVersions] = useState(false);

  useEffect(() => {
    if (sortedVersions) {
      const moreThanOneVersion = sortedVersions.length > 1;
      const lastVersionInDraft = sortedVersions[0].status === ChecklistBaseVersionStatus.DRAFT;
      if (showAllVersions) {
        if (moreThanOneVersion && lastVersionInDraft) {
          const lastVersion = sortedVersions[0];
          const versionsToShow = sortedVersions.map((version) => version);
          versionsToShow.splice(0, 1);
          versionsToShow.splice(1, 0, lastVersion);
          setVisibleVersions(versionsToShow);
        } else {
          setVisibleVersions(sortedVersions);
        }
      } else {
        const versionsToShow = moreThanOneVersion && lastVersionInDraft ? [sortedVersions[1], sortedVersions[0]] : [sortedVersions[0]];
        setVisibleVersions(versionsToShow);
      }
    }
  }, [showAllVersions, checklistBase]);

  if (!visibleVersions || visibleVersions.length === 0) return null;

  const toggleShowAllVersions = () => {
    setShowAllVersions(!showAllVersions);
  };

  const handleCompanyTemplateTagClick = () => {
    setFilter({
      ...filter,
      ...{
        showCompanyTemplatesOnly: !filter.showCompanyTemplatesOnly
      }
    });
  };

  return (
    <>
      {visibleVersions &&
        visibleVersions.map((version) => {
          return (
            <ProjectChecklistSettingsListRow
              checklistBase={checklistBase}
              checklistBaseVersion={version}
              isLastVersion={version.id === visibleVersions[0].id}
              toggleShowAllVersions={toggleShowAllVersions}
              allVersionsVisible={showAllVersions}
              anyVersionInDraft={visibleVersions.some((cbv) => cbv.status === ChecklistBaseVersionStatus.DRAFT)}
              key={version.id}
              handleCompanyTemplateTagClick={handleCompanyTemplateTagClick}
            />
          );
        })}
    </>
  );
};

export default ProjectChecklistSettingListRowContainer;
