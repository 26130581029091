import React, { useEffect } from "react";
import { NavCubePlugin, Viewer } from "@xeokit/xeokit-sdk";

interface Props {
  onModelsLoaded: boolean;
  viewer?: Viewer;
}

const XeokitNavCubeTool = React.memo<Props>(({ onModelsLoaded, viewer }) => {
  useEffect(() => {
    // NAV CUBE
    if (!viewer) return;

    const navCubeObject = new NavCubePlugin(viewer, {
      canvasId: "bimNavCubeCanvas",
      color: "#E6ECEE",
      visible: true,
      shadowVisible: false
    });

    return function destroy() {
      navCubeObject.destroy();
    };
  }, [onModelsLoaded]);

  return <canvas id="bimNavCubeCanvas"></canvas>;
});

export default XeokitNavCubeTool;
