import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import BhSortButton from "@components/sort/BhSortButton";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import { IConfirmationsSort, initialConfirmationsSort } from "@/model/confirmations/IConfirmationsSort";
import { useAppSelector } from "@/app/hooks";
import { confirmationsSortSet, selectConfirmationsSort } from "@/app/store/fileConfirmation/fileConfirmationsSlice";

const ConfirmationsListSort: FC = () => {
  const { t } = useTranslation();
  const [confirmationsOrdering, setConfirmationsOrdering] = useLocalStorage<IConfirmationsSort>("confirmationsOrdering", initialConfirmationsSort);
  const sort = useAppSelector(selectConfirmationsSort);

  const dropdownMenuValues = [
    { value: "description", translation: t("GLOBAL.ITEM_NAME") },
    { value: "created", translation: t("GLOBAL.CREATED") }
  ];

  return (
    <div className="flex flex-row items-center">
      <BhSortButton sort={sort} localStorageSort={confirmationsOrdering} setSortForStore={confirmationsSortSet} setSortForLocalStorage={setConfirmationsOrdering} sortValues={dropdownMenuValues} />
    </div>
  );
};

export default ConfirmationsListSort;
