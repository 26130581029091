import React, { FC, useEffect, useState } from "react";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveProjectAdministratorUserAuthoritiesAsync, selectCurrentProjectId, selectProjectAdministrators } from "@/app/store/project/projectSlice";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { Authority, IUserAuthoritiesRequest } from "@/model/IUserAuthority";
import BhInputLabel from "@components/input/BhInputLabel";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { useTranslation } from "react-i18next";
import { fetchCompanyUsers } from "@/api/userAPI";
import BhEmailInputWithUserDropdown from "@components/input/BhEmailInputWithUserDropdown";

const ProjectSettingsAdminInput: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const projectAdministrators = useAppSelector(selectProjectAdministrators);
  const [emailInput, setEmailInput] = useState("");
  const [users, setUsers] = useState([] as Array<ISimpleValidUserAuthority>);

  useEffect(() => {
    fetchCompanyUsers(projectId).then((companyUsers) => {
      setUsers(companyUsers);
    });
  }, []);

  const showValueCondition = (user: ISimpleValidUserAuthority) => {
    return !projectAdministrators.some((admin) => admin.userEntityId === user.userEntityId);
  };

  const addAdmin = () => {
    if (emailInput) {
      const usernameIsNotEmail = !emailInput.match(/\S+@\S+\.\S+/);
      if (usernameIsNotEmail) {
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.ERROR,
            disappear: true,
            children: t("GLOBAL.EMAIL_INVALID")
          })
        );
        return;
      }
      const authToSave = { username: emailInput.toLowerCase().replace(/[\s\u200B-\u200D\uFEFF]/g, ""), authority: Authority.ADMIN } as IUserAuthoritiesRequest;
      dispatch(saveProjectAdministratorUserAuthoritiesAsync({ projectId: projectId, userAuths: [authToSave] }));
      setEmailInput("");
    }
  };

  return (
    <div>
      <BhInputLabel>{t("COMPANY.SETTINGS.ADD_ADMINISTRATOR")}</BhInputLabel>
      <div className="flex flex-row items-center">
        <div className="w-80">
          <BhEmailInputWithUserDropdown
            emailInput={emailInput}
            setEmailInput={setEmailInput}
            values={users}
            showValueCondition={showValueCondition}
            placeholder={t("INPUT.PLACEHOLDER.NAME_OR_EMAIL") as string}
          />
        </div>
        <BhPrimaryButton buttonProps={{ onClick: addAdmin, disabled: !emailInput }}>{t("GLOBAL.SEND_INVITE")}</BhPrimaryButton>
      </div>
    </div>
  );
};

export default ProjectSettingsAdminInput;
