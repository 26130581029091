import React, { FC } from "react";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { ITag } from "@/model/ITag";
import BhTag from "@components/tags/BhTag";
import BhTagListContainer from "@components/tags/BhTagListContainer";

interface Props {
  tags: Array<ITag>;
  type: BhTagType;
  property: string;
  sortByProperty?: boolean;
  classes?: string;
}

const BhTags: FC<Props> = ({ type, classes, tags, property, sortByProperty }) => {
  const sortedTags = sortByProperty ? tags.slice().sort((a: any, b: any) => (a[property] > b[property] ? 1 : -1)) : tags;
  return (
    <BhTagListContainer>
      {sortedTags &&
        sortedTags.length > 0 &&
        sortedTags.map((tag: any, index) => {
          return (
            <BhTag type={type} classes={classes} key={index}>
              {tag[property]}
            </BhTag>
          );
        })}
    </BhTagListContainer>
  );
};

export default BhTags;
