import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { FormNameOption } from "@/model/form/IFormBase";

interface Props {
  saveCallback: Function;
  isPrefixDropdown: boolean;
  property: string;
}

const FormBaseInfoInputDropdown: FC<Props> = ({ saveCallback, isPrefixDropdown, property }) => {
  const { t } = useTranslation();

  return (
    <div className="bh-bg-white bh-shadow bh-bg-raised-white absolute right-0 z-50 mt-1 inline-block flex h-fit w-52 flex-col rounded py-3 text-left align-top">
      <div className="hover:bh-bg-smoke bh-text-pigeon cursor-pointer py-1.5 px-4" onClick={() => saveCallback({ [property]: FormNameOption.NONE })}>
        {isPrefixDropdown ? t("FORMBUILDER.BASE.FILE_NO_PREFIX") : t("FORMBUILDER.BASE.FILE_NO_SUFFIX")}
      </div>
      <div className="hover:bh-bg-smoke bh-text-deep-ocean cursor-pointer py-1.5 px-4" onClick={() => saveCallback({ [property]: FormNameOption.ORDER_NUMBER })}>
        {t("FORMBUILDER.BASE.FILE_PREFIX_ORDER_NUMBER")}
      </div>
      <div className="hover:bh-bg-smoke bh-text-deep-ocean cursor-pointer py-1.5 px-4" onClick={() => saveCallback({ [property]: FormNameOption.DATE })}>
        {t("FORMBUILDER.BASE.FILE_PREFIX_DATE")}
      </div>
      {!isPrefixDropdown && (
        <div className="hover:bh-bg-smoke bh-text-deep-ocean cursor-pointer py-1.5 px-4" onClick={() => saveCallback({ [property]: FormNameOption.ORDER_NUMBER_AND_DATE })}>
          {t("FORMBUILDER.BASE.FILE_SUFFIX_ORDER_NUMBER_DATE")}
        </div>
      )}
    </div>
  );
};

export default FormBaseInfoInputDropdown;
