import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/form/formSlice";
import FormTableFieldTable from "@components/form/fields/tableField/FormTableFieldTable";
import FormTableFieldTablePreview from "@components/form/fields/tableField/FormTableFieldTablePreview";

interface Props {
  field: any;
  disabled?: boolean;
  isPreview?: boolean;
  nestedDataSaveCallback?: Function;
  nestedDataAddCallback?: Function;
  nestedDataRemoveCallback?: Function;
}

const FormTableField: FC<Props> = ({ field, disabled, isPreview, nestedDataSaveCallback, nestedDataAddCallback, nestedDataRemoveCallback }) => {
  const rows = useAppSelector((state) => (!isPreview ? selectValueByPropertyFromCurrentFormData(state, field.property) : field.value.rows || []));

  return (
    <div className="flex">
      <div className="min-w-800px flex h-full w-full flex-col">
        {!isPreview && (
          <FormTableFieldTable
            rows={rows}
            field={field}
            nestedDataSaveCallback={nestedDataSaveCallback}
            addCallback={nestedDataAddCallback}
            disabled={disabled}
            nestedDataRemoveCallback={nestedDataRemoveCallback}
          />
        )}
        {isPreview && <FormTableFieldTablePreview rows={rows} cols={field.value.columns} />}
      </div>
    </div>
  );
};
export default FormTableField;
