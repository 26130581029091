import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAnglesDown, faAnglesUp, faAngleUp } from "@fortawesome/pro-solid-svg-icons";
import { classNames } from "@/utilities/jsUtilities";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";

interface Props {
  priority: number;
}

const TaskPriorityTag: FC<Props> = ({ priority }) => {
  const tagClassesMap: Record<number, string> = {
    1: "task-priority-1",
    2: "task-priority-2",
    4: "task-priority-4",
    5: "task-priority-5"
  };

  const tagIconMap: Record<number, IconDefinition> = {
    1: faAnglesDown,
    2: faAngleDown,
    4: faAngleUp,
    5: faAnglesUp
  };

  if (priority === 3) {
    return null;
  }

  return (
    <div className={classNames(tagClassesMap[priority], "mr-1 inline-block h-4 w-4 rounded text-center")}>
      <FontAwesomeIcon icon={tagIconMap[priority]} size="xs" className="mb-0.5" />
    </div>
  );
};

export default TaskPriorityTag;
