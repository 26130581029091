import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveChecklistForLatestBaseVersionAsync, selectUserChecklistBases } from "@/app/store/checklist/checklistsSlice";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { IChecklistBase } from "@/model/checklist/IChecklistBase";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useTranslation } from "react-i18next";
import { IChecklist } from "@/model/checklist/IChecklist";
import { useNavigate } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  archived?: boolean;
}

const NoChecklists: FC<Props> = ({ archived }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const checklistBases = useAppSelector(selectUserChecklistBases);
  const projectId = useAppSelector(selectCurrentProjectId);

  const startNewChecklist = (checklistBase: IChecklistBase) => {
    dispatch(saveChecklistForLatestBaseVersionAsync({ projectId: projectId, checklistBaseId: checklistBase.id })).then((action) => {
      const checklist = action.payload as IChecklist;
      if (checklist) {
        const lastRoundId = checklist.checklistRounds && checklist.checklistRounds.reduce((max, round) => (max.roundNumber > round.roundNumber ? max : round)).id;
        if (lastRoundId) {
          navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklists/${checklist.id}/round/${lastRoundId}`);
        }
      }
    });
  };

  return (
    <div className="mt-11 flex flex-row justify-center">
      <div>
        <div className="text-18px bh-text-deep-ocean mt-2 text-center leading-6">{archived ? t("CHECKLISTS.NO_ARCHIVED_CHECKS") : t("CHECKLISTS.NO_ACTIVE_CHECKS")}</div>
        {!archived && (
          <div className="mt-4 text-center">
            <BhDropdown
              button={
                <BhPrimaryButton icon={faPlus} buttonProps={{ classes: "w-52 h-12" }}>
                  {t("CHECKLIST.START_NEW")}
                </BhPrimaryButton>
              }
              menu={<BhDropdownMenu values={checklistBases} type={BhDropdownTypeEnum.STRING} textProperty="type" onSelect={startNewChecklist} widthClass={"w-72"} />}
              position={BhDropdownPositionEnum.BOTTOM_LEFT}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NoChecklists;
