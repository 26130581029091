import React, { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BhControlledInput from "@components/input/BhInput";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  initialValue: any;
  property: string;
  saveCallback?: Function;
  required?: boolean;
  leadingIcon?: IconProp;
  trailingIcon?: IconProp;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  inputClasses?: string;
  containerClasses?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  onEnterCallback?: Function;
  onChangeCallback?: Function;
  autoFocus?: boolean;
  onFocus?: Function;
  onBlurAction?: Function;
  withoutBorder?: boolean;
  maxLength?: number;
  onPasteCallback?: Function;
  debounceLength?: number;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  onChangeCallbackOnEveryValue?: boolean;
  iconColour?: string;
}

const BhInputSimple: FC<Props> = ({
  property,
  initialValue,
  saveCallback,
  required,
  leadingIcon,
  trailingIcon,
  type,
  placeholder,
  disabled,
  inputClasses,
  containerClasses,
  inputRef,
  onChangeCallback,
  onEnterCallback,
  autoFocus,
  onFocus,
  onBlurAction,
  withoutBorder,
  maxLength,
  onPasteCallback,
  debounceLength,
  inputProps,
  onChangeCallbackOnEveryValue,
  iconColour
}) => {
  return (
    <div className={classNames(containerClasses ? containerClasses : "relative inline-block w-full")}>
      {leadingIcon && <FontAwesomeIcon icon={leadingIcon} className={classNames(iconColour ? iconColour : "bh-text-pigeon", "w-16px h-16px absolute left-1 top-1 p-1.5")} aria-hidden="true" />}
      <BhControlledInput
        initialValue={initialValue}
        property={property}
        saveCallback={saveCallback}
        inputClasses={classNames(leadingIcon && "pl-9", trailingIcon && "pr-9", inputClasses)}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        inputRef={inputRef}
        onEnterCallback={onEnterCallback}
        onChangeCallback={onChangeCallback}
        autoFocus={autoFocus}
        onFocus={onFocus}
        onBlurAction={onBlurAction}
        withoutBorder={withoutBorder}
        maxLength={maxLength}
        onPasteCallback={onPasteCallback}
        debounceLength={debounceLength}
        inputProps={inputProps}
        onChangeCallbackOnEveryValue={onChangeCallbackOnEveryValue}
      />
      {trailingIcon && (
        <FontAwesomeIcon icon={trailingIcon} className={classNames(iconColour ? iconColour : "bh-text-pigeon", "w-16px h-16px absolute right-1 top-1 p-1.5")} size="2x" aria-hidden="true" />
      )}
    </div>
  );
};

export default BhInputSimple;
