import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import BhDropdown from "@components/dropdown/BhDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListTree } from "@fortawesome/pro-regular-svg-icons/faListTree";
import BhDropdownButton from "@components/dropdown/BhDropdownButton";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhIconButton from "@components/buttons/BhIconButton";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { Viewer } from "@xeokit/xeokit-sdk";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import { useTranslation } from "react-i18next";
import BhModalContainer from "@components/modal/BhModalContainer";
import XeokitSidebarMenuObjectsTreeView from "@/views/home/project/detail/xeokit/xeokitSidebar/XeokitSidebarMenuObjectsTreeView";
import { LoadedObjectInfo } from "@/views/home/project/detail/xeokit/XeokitWebViewer";
import XeokitSidebarMenuTypesTreeView from "@/views/home/project/detail/xeokit/xeokitSidebar/XeokitSidebarMenuTypesTreeView";
import XeokitSidebarMenuStoreysTreeView from "@/views/home/project/detail/xeokit/xeokitSidebar/XeokitSidebarMenuStoreysTreeView";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUserLanguageForTranslation } from "@/app/store/userSlice";

interface Props {
  viewer: Viewer;
  treeModalVisible: boolean;
  setTreeModalVisible: Function;
  loadedModels: Array<LoadedObjectInfo>;
  loadedStoreyElevations: Array<LoadedObjectInfo>;
  setLoadedStoreyElevations: Function;
}

const XeokitMobileTreeModal: FC<Props> = ({ viewer, treeModalVisible, setTreeModalVisible, loadedModels, loadedStoreyElevations, setLoadedStoreyElevations }) => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguageForTranslation);
  const [bimModalTab, setBimModalTab] = useLocalStorage<number>("bimModalTab", 1);

  const tabs = [
    { text: t("BIM.STOREYS_HEIGHT", { lng: currentUserLanguage }), id: 0 },
    { text: t("BIM.CLASSES", { lng: currentUserLanguage }), id: 1 },
    { text: t("BIM.OBJECTS", { lng: currentUserLanguage }), id: 2 }
  ];

  const dropdownValues = tabs.map((tab) => {
    return { text: tab.text, function: () => setBimModalTab(tab.id) };
  });

  const selectStoreyElevation = (storeyElevationToSelect: LoadedObjectInfo) => {
    const yCoord = (storeyElevationToSelect.elevation || 0) / 1000 + 1.2;
    if (yCoord === undefined || isNaN(yCoord)) return;

    viewer.camera.eye = [viewer.camera.eye[0], yCoord, viewer.camera.eye[2]];
    viewer.camera.look = [viewer.camera.look[0], yCoord, viewer.camera.look[2]];
    viewer.camera.up = viewer.camera.worldUp;
    viewer.cameraControl.constrainVertical = true;
    setTreeModalVisible(false);
  };

  return (
    <div className={classNames(treeModalVisible ? "flex" : "hidden", "absolute h-full w-full overflow-hidden")}>
      <BhModalContainer>
        <div className="flex flex-1 flex-col overflow-hidden border-b">
          <div className="flex flex-row items-center justify-between p-1">
            <BhDropdown
              button={
                <div className="flex cursor-pointer flex-row items-center p-2 font-bold">
                  <div className="pr-2">
                    <FontAwesomeIcon icon={faListTree} />
                  </div>
                  <BhDropdownButton title={""} value={tabs[bimModalTab].text} reversed={true} />
                </div>
              }
              menu={<BhDropdownMenu textProperty="text" type={BhDropdownTypeEnum.STRING} values={dropdownValues} closeOnItemClick={true} />}
            />
            <BhIconButton
              icon={faTimes}
              buttonProps={{
                onClick: () => {
                  setTreeModalVisible(false);
                }
              }}
            />
          </div>
          <div className="flex-1 overflow-auto p-2">
            {bimModalTab === 0 && (
              <div className="block">
                <XeokitSidebarMenuStoreysTreeView viewer={viewer} loadedStoreyElevations={loadedStoreyElevations} setLoadedStoreyElevations={setLoadedStoreyElevations} />
              </div>
            )}
            {bimModalTab === 1 && (
              <div className="block">
                <XeokitSidebarMenuTypesTreeView viewer={viewer} loadedModels={loadedModels} />
              </div>
            )}
            {bimModalTab === 2 && (
              <div className="block">
                <XeokitSidebarMenuObjectsTreeView viewer={viewer} loadedModels={loadedModels} toggleModelVisible={() => {}} toggleAllModelsVisible={() => {}} />
              </div>
            )}
          </div>
        </div>
      </BhModalContainer>
    </div>
  );
};

export default XeokitMobileTreeModal;
