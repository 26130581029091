import React, { FC } from "react";

export enum BhBadgeType {
  DARK = "DARK",
  BRIGHT = "BRIGHT",
  SUBTLE = "SUBTLE",
  YELLOW = "YELLOW",
  ERROR = "ERROR"
}

interface Props {
  type?: BhBadgeType;
  classes?: string;
  children?: React.ReactNode;
}

const BhBadge: FC<Props> = ({ type, classes, children }) => {
  let className = "px-1.5 h-4 inline-flex badge-text whitespace-nowrap flex-row items-center rounded-xl " + classes;
  if (type === BhBadgeType.DARK) {
    className += " bh-bg-dark-jungle bh-text-white";
  }
  if (type === BhBadgeType.BRIGHT) {
    className += " bh-bg-bauhub-green-120 bh-text-white";
  }
  if (type === BhBadgeType.YELLOW) {
    className += " bh-bg-warning-yellow bh-text-deep-ocean";
  }
  if (type === BhBadgeType.ERROR) {
    className += " bh-bg-error-red-120 bh-text-white";
  }
  if (type === BhBadgeType.SUBTLE || !type) {
    className += " bh-bg-pigeon-40";
  }

  return <span className={className}>{children}</span>;
};

export default BhBadge;
