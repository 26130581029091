import { EntityId } from "@reduxjs/toolkit";
import { ProjectSecurityLevel } from "@/model/IProject";

export interface IUserInfo {
  id: EntityId;
  userEntityId: EntityId;
  title: string;
  companyName: string;
  phoneNumber: string;
  contactNumber: string;
  personalCode: string;
  dailyMailEnabled: boolean;
  taskMailEnabled: boolean;
  signingAndConfirmationMailEnabled: boolean;
  otherMailEnabled: boolean;
  openPdftronCad: boolean;
  dailyMailTimeHour: number;
  dailyMailTimeMinute: number;
  uuid: string;
  securityLevel: ProjectSecurityLevel;
  theme: Theme;
  avatarUuid: string;
  contactsHidden: boolean;
}

export interface ISimpleUserInfo {
  id: EntityId;
  userEntityId: EntityId;
  title: string;
  companyName: string;
  contactNumber: string;
}

export enum Theme {
  LIGHT = "LIGHT",
  DARK = "DARK",
  AUTO = "AUTO"
}

export function getNumberAndPrefix(number: string) {
  const prefixes = ["+372", "+371", "+370", "+358", "+381", "+39", "+82"];
  let numberWithoutPrefix = "";
  let numberPrefix = "";
  prefixes.forEach((prefix) => {
    if (number.startsWith(prefix)) {
      numberPrefix = prefix;
      numberWithoutPrefix = number.substring(prefix.length);
    }
  });
  if (!numberWithoutPrefix) {
    if (number.startsWith("+")) {
      numberWithoutPrefix = number.substring(1);
      numberPrefix = "+";
    } else {
      numberPrefix = "+372";
      numberWithoutPrefix = number;
    }
  }
  return { prefix: numberPrefix, number: numberWithoutPrefix };
}
