import { ISimpleUserInfo, IUserInfo } from "@/model/IUserInfo";
import { IUser } from "@/model/IUser";
import { IUserAuthority } from "@/model/IUserAuthority";

export interface ISimpleValidUserAuthority extends IUserAuthority {
  role: string;
  language: string;
  userInfo: IUserInfo;
  simpleUserInfo: ISimpleUserInfo;
  userExists: boolean;
}

export function convertSimpleValidUserAuthorityToUser(simpleValidUserAuthority: ISimpleValidUserAuthority): IUser {
  return {
    id: simpleValidUserAuthority.userEntityId,
    username: simpleValidUserAuthority.username,
    firstName: simpleValidUserAuthority.firstName,
    lastName: simpleValidUserAuthority.lastName,
    language: simpleValidUserAuthority.language
  } as IUser;
}
