import React, { FC, useState } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectAllTaskBoards } from "@/app/store/taskBoardsSlice";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import TaskModalTaskBoardContainer from "@components/task/modal/TaskModalTaskBoardContainer";
import { ITaskBoard } from "@/model/taskBoard/ITaskBoard";
import { useTranslation } from "react-i18next";
import { naturalSortByField } from "@/utilities/sortUtilities";

interface Props {
  saveTaskCallback: Function;
}

const TaskModalBodyTaskBoards: FC<Props> = ({ saveTaskCallback }) => {
  const { t } = useTranslation();
  const projectTaskBoards = useAppSelector(selectAllTaskBoards);
  const [searchFilter, setSearchFilter] = useState("");
  const taskBoards =
    searchFilter === ""
      ? projectTaskBoards
      : projectTaskBoards.filter((tb) => {
        return tb.name.toLowerCase().includes(searchFilter.toLowerCase());
      });
  const taskBoardsSorted = taskBoards.sort((a, b) => naturalSortByField(a, b, "name"));

  const setTaskBoardSearchFilter = (filter: any) => {
    setSearchFilter(filter.value);
  };

  return (
    <div className="flex flex-1 flex-row overflow-hidden">
      <div className="bh-bg-white flex h-full w-full flex-col overflow-y-auto pl-10 pr-6 pt-8">
        <div className="flex flex-row items-center justify-between">
          <div>
            <h3>{t("TASK.CHOOSE_TASK_BOARD")}</h3>
          </div>
          <div>
            <BhSearchInputWBG property={"value"} inputClasses={"w-72"} placeholder={t("TASK.SEARCH_TASKBOARD") as string} onChangeCallback={setTaskBoardSearchFilter} />
          </div>
        </div>
        <div className="bh-border-pigeon-40 mt-6 flex flex-col rounded border">
          <TaskModalTaskBoardContainer taskBoard={{ name: t("TASKBOARD.PERSONAL") } as ITaskBoard} onClick={saveTaskCallback} isPersonalTaskBoard={true} />
          {taskBoardsSorted.length > 0 && taskBoardsSorted.map((taskBoard) => <TaskModalTaskBoardContainer taskBoard={taskBoard} onClick={saveTaskCallback} isPersonalTaskBoard={false}
                                                                                                           key={taskBoard.id} />)}
        </div>
      </div>
    </div>
  );
};

export default TaskModalBodyTaskBoards;
