import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { IChecklistFilter } from "@/model/checklist/IChecklistFilter";
import { IChecklistPage } from "@/model/checklist/IChecklistPage";
import { IChecklist } from "@/model/checklist/IChecklist";
import { IFileEntity } from "@/model/files/IFileEntity";
import { IChecklistRound } from "@/model/checklist/IChecklistRound";
import { IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import { IChecklistRoundRow } from "@/model/checklist/IChecklistRoundRow";
import { IChecklistParticipant } from "@/model/checklist/IChecklistParticipant";
import { IChecklistTag } from "@/model/checklist/IChecklistTag";
import { EntityId } from "@reduxjs/toolkit";
import { IChecklistAttachment } from "@/model/checklist/IChecklistAttachment";
import { IChecklistRoundToDirectoryDTO } from "@/model/checklist/IChecklistRoundToDirectoryDTO";

export function fetchChecklistsForUser(projectId: EntityId, filter: IChecklistFilter): Promise<IChecklistPage> {
  return bauhubGet("/project/" + projectId + "/checklists", filter);
}

export function saveChecklist(checklist: IChecklist): Promise<IChecklist> {
  return bauhubPost("/checklist", checklist);
}

export function generatePdfForChecklistRound(projectId: EntityId, checklistRoundId: EntityId): Promise<IFileEntity> {
  return bauhubPost("/project/" + projectId + "/checklist/round/" + checklistRoundId + "/pdf?st=false");
}

export function saveChecklistForLatestBaseVersion(projectId: EntityId, checklistBaseId: EntityId): Promise<IChecklist> {
  return bauhubPost("/project/" + projectId + "/checklist/base/" + checklistBaseId + "/checklist");
}

export function archiveOrActivateChecklists(checklistIds: Array<EntityId>): Promise<Array<EntityId>> {
  return bauhubPost("/checklist/archive", checklistIds);
}

export function fetchChecklistRoundContainerIds(roundIds: Array<EntityId>): Promise<Array<EntityId>> {
  return bauhubGet("/checklist/rounds/containers/" + roundIds.join(","));
}

export function fetchChecklistRoundPdfIds(roundIds: Array<EntityId>): Promise<Array<EntityId>> {
  return bauhubGet("/checklist/rounds/pdfs/" + roundIds.join(","));
}

export function fetchChecklistWithInfo(checklistId: EntityId): Promise<IChecklist> {
  return bauhubGet("/v2/checklist/optimized/" + checklistId);
}

export function fetchChecklistRoundWithRows(checklistRoundId: EntityId): Promise<IChecklistRound> {
  return bauhubGet("/v2/checklist/round/" + checklistRoundId);
}

export function fetchChecklistBaseVersionRows(checklistId: EntityId, checklistBaseVersionId: EntityId): Promise<Array<IChecklistBaseVersionRow>> {
  return bauhubGet("/v2/checklist/" + checklistId + "/base/version/" + checklistBaseVersionId + "/rows");
}

export function saveChecklistRoundRow(checklistRoundRow: IChecklistRoundRow): Promise<IChecklistRoundRow> {
  return bauhubPost("/checklist/round/row", checklistRoundRow);
}

export function saveChecklistRound(checklistRound: IChecklistRound): Promise<IChecklistRound> {
  return bauhubPost("/checklist/round", checklistRound);
}

export function saveSubRowForRoundRow(roundRowId: EntityId, roundId: EntityId): Promise<IChecklistRoundRow> {
  return bauhubPost("/checklist/base/version/row/" + roundRowId + "/round/" + roundId + "/row/add");
}

export function saveSubRowForRoundListRow(baseVersionRowId: EntityId, roundId: EntityId): Promise<IChecklistRoundRow> {
  return bauhubPost("/checklist/base/version/row/" + baseVersionRowId + "/round/" + roundId + "/list/row/add");
}

export function completeChecklistRound(checklistRoundId: EntityId): Promise<IChecklistRound> {
  return bauhubPost("/checklist/round/" + checklistRoundId + "/complete");
}

export function startNewChecklistRoundFromLatestRound(latestRoundId: EntityId): Promise<IChecklistRound> {
  return bauhubPost("/checklist/round/" + latestRoundId + "/start/new");
}

export function saveUserToChecklist(participant: IChecklistParticipant): Promise<IChecklistParticipant> {
  return bauhubPost("/checklist/user/add", participant);
}

export function removeUserFromChecklist(participant: IChecklistParticipant): Promise<IChecklistParticipant> {
  return bauhubPost("/checklist/user/remove", participant);
}

export function saveChecklistTag(tag: IChecklistTag): Promise<IChecklistTag> {
  return bauhubPost("/checklist/tag", tag);
}

export function fetchUniqueProjectChecklistTags(projectId: EntityId): Promise<Array<IChecklistTag>> {
  return bauhubGet("/project/" + projectId + "/checklist/tags");
}

export function deleteChecklistRoundRowTask(checklistRoundRowId: EntityId, taskId: EntityId): Promise<IChecklistRoundRow> {
  return bauhubPost("/checklist/round/row/" + checklistRoundRowId + "/task/" + taskId + "/delete");
}

export function saveChecklistRoundBaseFile(checklistRoundId: EntityId, fileId: number, isUpload: boolean): Promise<IFileEntity> {
  return bauhubPost("/checklist/round/" + checklistRoundId + "/file/" + fileId + "/upload/" + isUpload);
}

export function removeChecklistRoundBaseFile(checklistRoundId: EntityId): Promise<IFileEntity> {
  return bauhubPost("/checklist/round/" + checklistRoundId + "/file/remove");
}

export function copyChecklistRoundPdfToDirectories(projectId: EntityId, checklistRoundId: EntityId, dirIds: Array<EntityId>) {
  return bauhubPost("/project/" + projectId + "/checklist/" + checklistRoundId + "/copy/dir", dirIds);
}

export function bulkCopyChecklistRoundsPdfToDirectories(projectId: EntityId, dto: IChecklistRoundToDirectoryDTO) {
  return bauhubPost("/project/" + projectId + "/checklist/bulk/copy/dir", dto);
}

export function attachFilesToChecklistRoundRow(checklistRoundId: EntityId, checklistRoundRowId: EntityId, attachments: Array<IChecklistAttachment>): Promise<IChecklistRoundRow> {
  return bauhubPost("/checklist/round/" + checklistRoundId + "/row/" + checklistRoundRowId + "/attachment", attachments);
}

export function removeFileFromChecklistRoundRow(checklistRoundId: EntityId, fileEntityId: EntityId): Promise<IChecklistAttachment> {
  return bauhubPost("/checklist/round/" + checklistRoundId + "/attachment/" + fileEntityId + "/remove");
}
