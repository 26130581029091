import React, { FC } from "react";
import { IBhButtonWithIconProps } from "@components/buttons/IBhButtonProps";
import BhButton from "@components/buttons/BhButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "@/utilities/jsUtilities";

const BhButtonTemplateWithIconWithLabel: FC<IBhButtonWithIconProps> = React.memo(({ buttonProps, icon, iconClasses, spin, iconSize, children }) => {
  const iconClassName = classNames(iconClasses, iconSize || "h-24px");

  const iconElement = icon && <FontAwesomeIcon icon={icon} className={iconClassName} aria-hidden="true" spin={spin} size={iconSize} />;

  return (
    <BhButton buttonProps={buttonProps}>
      <span className="flex flex-col">
        <span>{iconElement}</span>
        <span className="l-h-12px">{children}</span>
      </span>
    </BhButton>
  );
});

export default BhButtonTemplateWithIconWithLabel;
