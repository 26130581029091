import React, { FC, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { Trans, useTranslation } from "react-i18next";
import { IAct, IActExternalInfo, SendingStatus } from "@/model/IAct";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhDivTableHeader from "@components/table/BhDivTableHeader";
import { findActExternalInfo, sendMaruAct } from "@/api/actAPI";
import { classNames } from "@/utilities/jsUtilities";
import { formatDateTime } from "@/utilities/dateUtility";
import BhLargeBoldText from "@components/text/BhLargeBoldText";
import BhSendActConfirmationModal from "@/views/home/project/detail/actsAndContracts/acts/actsModals/BhSendActConfirmationModal";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { useAppDispatch } from "@/app/hooks";
import { actUpdated } from "@/app/store/actsSlice";
import BhTooltip from "@components/BhTooltip";

interface Props {
  act: IAct;
  closeModal: Function;
}

const MrActSendingModal: FC<Props> = ({ act, closeModal }) => {
  const { t } = useTranslation();
  const [actExternals, setActExternals] = useState([] as Array<IActExternalInfo>);
  const [isSendConfirmationModalOpen, setSendConfirmationModalOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const actSendingDisabled = !act.signaturesCount || act.signaturesCount === 0;

  useEffect(() => {
    findActExternalInfo(act.projectId, act.id).then((actExternalInfo) => {
      setActExternals(actExternalInfo);
    });
  }, []);

  const sendAct = () => {
    sendMaruAct(act.projectId, act.id).then((response) => {
      setActExternals((oldValues) => oldValues.concat(response));
      dispatch(actUpdated({ ...act, actSendingStatus: response.status }));
      setSendConfirmationModalOpen(false);
    });
  };

  return (
    <>
      <BhModal
        isShown={true}
        setIsShown={() => !isSendConfirmationModalOpen && closeModal()}
        size="3xl"
        header={
          <div className="flex flex-row items-center gap-x-6">
            <h2 className="my-3">
              <Trans>ACT.SENDING.MODAL.TITLE</Trans>
            </h2>
          </div>
        }
        children={
          <div className="px-8 py-6">
            <div className="flex flex-row items-end">
              <div className="py-0.5">
                <BhInputStackedLabel initialValue={"Finbite"} disabled={true} property={""} onChangeCallback={() => {}} label={t("ACT.SENDING.MODAL.RECEIVER")} />
              </div>
              <div className="pl-4">
                <BhTooltip body={actSendingDisabled ? t("ACT.SENDING.DISABLED") : ""}>
                  <BhPrimaryButton buttonProps={{ onClick: () => setSendConfirmationModalOpen(true), disabled: actSendingDisabled }}>{t("ACT.SENDING.MODAL.SEND")}</BhPrimaryButton>
                </BhTooltip>
              </div>
            </div>
            <div className="flex flex-col overflow-y-auto py-8">
              <BhDivTableHeader
                columns={[
                  { id: 1, content: t("ACT.SENDING.MODAL.HISTORY.RECEIVER"), classes: "w-40 flex-none text-center" },
                  { id: 2, content: t("ACT.SENDING.MODAL.HISTORY.SENDER"), classes: "w-40 flex-none text-center" },
                  { id: 3, content: t("ACT.SENDING.MODAL.HISTORY.STATUS"), classes: "w-40 flex-none text-center" },
                  { id: 4, content: t("ACT.SENDING.MODAL.HISTORY.DATE"), classes: "w-40 flex-none text-center" }
                ]}
                reversed={false}
                sortedBy={"number"}
              />
              <div className="flex-col">
                {actExternals
                  .sort((a, b) => (a.created > b.created ? -1 : 1))
                  .map((info) => {
                    return (
                      <div key={info.id} className={classNames("bh-bg-white hover:bh-bg-mint-30", "bh-border-pigeon-40 bh-hover-container group block flex h-14 items-center border-b")}>
                        <div className="flex w-40 flex-none items-center px-3">{info.recipient}</div>
                        <div className="flex w-40 flex-none items-center px-3">{info.sender}</div>
                        <div className="flex w-40 flex-none items-center px-3">
                          {info.status === SendingStatus.FAILED && <BhTag type={BhTagType.FAILED} children={t("ACT.SENDING.STATUS.FAILED")} />}
                          {info.status === SendingStatus.SENT && <BhTag type={BhTagType.SENT} children={t("ACT.SENDING.STATUS.SENT")} />}
                        </div>
                        <div className="flex w-40 flex-none items-center px-3">{formatDateTime(info.created)}</div>
                      </div>
                    );
                  })}
                {actExternals.length === 0 && (
                  <div className="flex w-full flex-col items-center justify-center p-8">
                    <BhLargeBoldText classes="text-center">
                      <Trans>ACT.SENDING.MODAL.HISTORY.NOT_SENT</Trans>
                    </BhLargeBoldText>
                  </div>
                )}
              </div>
            </div>
          </div>
        }
        footer={
          <div className="flex flex-row items-center">
            <BhTextOnlyButton buttonProps={{ onClick: closeModal }}>{t("GLOBAL.CLOSE")}</BhTextOnlyButton>
          </div>
        }
      />
      {isSendConfirmationModalOpen && (
        <BhSendActConfirmationModal
          isShown={isSendConfirmationModalOpen}
          setIsShown={setSendConfirmationModalOpen}
          onSendButtonClick={sendAct}
          onCancelButtonClick={() => setSendConfirmationModalOpen(false)}
        />
      )}
    </>
  );
};

export default MrActSendingModal;
