import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";

interface Props {
  icon: IconDefinition;
  tagText: number;
}

const BhIconWithBadge: FC<Props> = ({ icon, tagText }) => {
  return (
    <div>
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={icon} className="bh-text-deep-ocean-80" size="lg" />
        <div className="bh-bg-pigeon-40 bh-text-deep-ocean-80 l-h-14 fa-layers-text fa-inverse ml-1.5 -mt-2 inline-block flex h-4 w-5 flex-row items-center justify-center rounded-xl">
          <span className="text-13px font-semibold">{tagText}</span>
        </div>
      </span>
    </div>
  );
};

export default BhIconWithBadge;
