import React, { FC, JSXElementConstructor, ReactElement, useEffect, useState } from "react";

interface Props {
  fetchValues: Function;
  child: ReactElement<any, string | JSXElementConstructor<any>>;
}

const BhChildComponentWithFetchValues: FC<Props> = ({ fetchValues, child }) => {
  const [values, setValues] = useState<Array<any>>([]);

  useEffect(() => {
    fetchValues &&
      fetchValues().then((results: Array<any>) => {
        setValues(results);
      });
  }, []);

  return React.cloneElement(child, { values: values });
};

export default BhChildComponentWithFetchValues;
