import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import { BhSectionMessageWarning } from "@components/sectionMessage/BhSectionMessages";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight";

interface Props {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  mainLabelProperty: string;
  initialMainLabel: string;
  currentMainLabel: string;
  initialStatusLabels: Record<string, string>;
  currentStatusLabels: Record<string, string>;
  saveCallback: Function;
}

const FormBaseLabelChangeModal: FC<Props> = ({ setIsModalOpen, initialMainLabel, initialStatusLabels, saveCallback, currentStatusLabels, currentMainLabel, mainLabelProperty }) => {
  const { t } = useTranslation();
  const [labels, setLabels] = useState<any>({});

  useEffect(() => {
    const mainLabelToSet = currentMainLabel !== initialMainLabel ? currentMainLabel : "";
    const statusLabelsToSet = Object.keys(currentStatusLabels).reduce((accumulator, key) => {
      accumulator[key] = currentStatusLabels[key] === initialStatusLabels[key] ? "" : currentStatusLabels[key];
      return accumulator;
    }, {} as Record<string, string>);
    setLabels({ mainLabelProperty: mainLabelProperty, mainLabel: mainLabelToSet, statusLabels: statusLabelsToSet });
  }, []);

  const saveLabels = (labels: any) => {
    const mainLabelToSave = labels.mainLabel ? labels.mainLabel : initialMainLabel;
    const statusLabelsToSave = Object.keys(currentStatusLabels).reduce((accumulator, key) => {
      accumulator[key] = labels.statusLabels[key] ? labels.statusLabels[key] : initialStatusLabels[key];
      return accumulator;
    }, {} as Record<string, string>);
    const labelsToSave = { mainLabelProperty: mainLabelProperty, mainLabel: mainLabelToSave, statusLabels: statusLabelsToSave };
    saveCallback(labelsToSave);
  };
  return (
    <BhModal
      disableOutsideClick={true}
      isShown={true}
      setIsShown={setIsModalOpen}
      hideSeparator={true}
      size={"3xl"}
      header={<h2 className="bh-text-deep-ocean-80">{t("FORMBUILDER.LABEL_MODAL.HEADER")}</h2>}
      children={
        <div className="flex h-full w-full flex-col px-8 pb-8">
          <BhSectionMessageWarning
            children={
              <div className="flex flex-row items-center gap-x-1">
                <div>{t("FORMBUILDER.LABEL_MODAL.BANNER.LEFT")}</div>
                <div className="font-bold">{t("FORMBUILDER.LABEL_MODAL.BANNER.RIGHT")}</div>
              </div>
            }
          />
          <div className="flex h-full w-full flex-row items-center pt-6">
            <div className="w-full">
              <BhInputStackedLabel initialValue={initialMainLabel} property={""} label={t("FORMBUILDER.LABEL_MODAL.INITIAL_VALUE")} disabled={true} />
            </div>
            <div className="bh-text-pigeon flex h-full flex-row items-end p-3">
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
            <div className="w-full">
              <BhInputStackedLabel
                initialValue={labels.mainLabel}
                property={mainLabelProperty}
                label={t("FORMBUILDER.LABEL_MODAL.NEW_VALUE")}
                saveCallback={(changedObject: any) => {
                  setLabels({ ...labels, mainLabel: !changedObject[mainLabelProperty] ? initialMainLabel : changedObject[mainLabelProperty] });
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="pt-8 pb-4">
              <h3>{t("FORMBUILDER.LABEL_MODAL.SUBHEADER")}</h3>
            </div>
            {labels.statusLabels &&
              Object.keys(labels.statusLabels).map((key, index) => (
                <div className="flex h-full w-full flex-row items-center" key={index}>
                  <div className="w-full">
                    <BhInputStackedLabel initialValue={initialStatusLabels[key]} property={""} label={index === 0 ? t(t("FORMBUILDER.LABEL_MODAL.INITIAL_VALUE")) : ""} disabled={true} />
                  </div>
                  <div className="bh-text-pigeon flex h-full flex-row items-end p-3">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                  <div className="w-full">
                    <BhInputStackedLabel
                      initialValue={labels.statusLabels[key]}
                      property={key}
                      label={index === 0 ? t("FORMBUILDER.LABEL_MODAL.NEW_VALUE") : ""}
                      saveCallback={(changedObject: any) =>
                        setLabels({
                          ...labels,
                          statusLabels: { ...labels.statusLabels, [key]: !changedObject[key] ? initialStatusLabels[key] : changedObject[key] }
                        })
                      }
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      }
      footer={<BhModalFooter onConfirm={() => saveLabels(labels)} onCancel={() => setIsModalOpen(false)} />}
    />
  );
};

export default FormBaseLabelChangeModal;
