import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import BhModal from "@components/modal/BhModal";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhModalFooter from "@components/modal/BhModalFooter";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { IFormBase } from "@/model/form/IFormBase";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { useNavigate } from "react-router-dom";
import { saveNewCompanyFormBaseAsync } from "@/app/store/form/formBasesSlice";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { RootState } from "@/app/store";
import { BauhubPlan } from "@/model/IProject";
import NewFormBaseModalLimitContentContainer from "@components/form/settings/NewFormBaseModalLimitContentContainer";
import { fetchCountOfCompanyBuiltFormBases } from "@/api/form/formBaseAPI";

interface Props {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  companyId: EntityId;
  projectId?: EntityId;
}

const NewFormBaseModal: FC<Props> = ({ setShowModal, companyId, projectId }) => {
  const { t } = useTranslation();
  const [countOfCompanyBuiltFormBases, setCountOfCompanyBuiltFormBases] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const company = useAppSelector((state: RootState) => selectCompanyById(state, companyId));
  const navigate = useNavigate();
  let inputRef = useRef<any>(null);

  useEffect(() => {
    company?.plan === BauhubPlan.LIGHT &&
      fetchCountOfCompanyBuiltFormBases(company.id).then((res) => {
        setCountOfCompanyBuiltFormBases(res);
        setIsLoading(false);
      });

    return function cleanup() {
      setCountOfCompanyBuiltFormBases(null);
    };
  }, []);

  if (!company) return null;

  const isProPlan = company?.plan === BauhubPlan.PRO;
  const canAddNewFormBases = isProPlan || (countOfCompanyBuiltFormBases && countOfCompanyBuiltFormBases < 2);

  const createNewFormBase = () => {
    const newFormBaseType = inputRef.current.value;
    if (!newFormBaseType) {
      return;
    }
    const newFormBase = { type: newFormBaseType, createdInProjectId: projectId } as IFormBase;
    dispatch(saveNewCompanyFormBaseAsync({ companyId: companyId, formBase: newFormBase })).then((action) => {
      const formBase = action.payload as IFormBase;
      const firstVersion = formBase.formBaseVersions[0];
      if (projectId) {
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/form/base/${formBase.id}/version/${firstVersion.id}`);
      } else {
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${companyId}/form/base/${formBase.id}/version/${firstVersion.id}`);
      }
    });
    setShowModal(false);
  };

  return (
    <BhModal
      isShown={company?.plan === BauhubPlan.PRO || !isLoading}
      setIsShown={setShowModal}
      size={canAddNewFormBases ? undefined : "2xl"}
      hideSeparator={!canAddNewFormBases}
      header={<h2>{t(canAddNewFormBases ? "FORMBUILDER.ADD_NEW_MODAL.HEADER" : "FORMBUILDER.LIGHT_PLAN_LIMIT_MODAL.HEADER")}</h2>}
      children={
        canAddNewFormBases ? (
          <div className="flex w-full flex-col gap-y-3 px-8 py-4">
            <BhInputStackedLabel initialValue="" property={"type"} label={t("CHECKLIST.ADD_NEW_MODAL.PLACEHOLDER")} inputRef={inputRef} />
          </div>
        ) : (
          <NewFormBaseModalLimitContentContainer companyId={company.id} />
        )
      }
      footer={
        canAddNewFormBases && (
          <BhModalFooter confirmButtonText={t("GLOBAL.SAVE") as string} onCancel={() => setShowModal(false)} cancelButtonText={t("GLOBAL.CLOSE") as string} onConfirm={createNewFormBase} />
        )
      }
    />
  );
};

export default NewFormBaseModal;
