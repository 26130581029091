import React, { FC, ReactNode } from "react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { ITask } from "@/model/taskBoard/ITask";
import BhLightSeparatorVertical from "@components/separator/BhLightSeparatorVertical";
import { useTranslation } from "react-i18next";
import BhTooltip from "@components/BhTooltip";
import BhIconButton from "@components/buttons/BhIconButton";
import { faLink } from "@fortawesome/pro-regular-svg-icons/faLink";
import { faLock } from "@fortawesome/pro-solid-svg-icons/faLock";
import { faUnlock } from "@fortawesome/pro-regular-svg-icons/faUnlock";

interface Props {
  task: ITask;
  actionButtons: Array<ReactNode>;
  closeTaskModal: Function;
  toggleLock: Function;
  enableTaskLocking: boolean;
  copyTaskLink: Function;
}

const DefaultTaskModalHeader: FC<Props> = ({ task, actionButtons, closeTaskModal, copyTaskLink, toggleLock, enableTaskLocking }) => {
  const { t } = useTranslation();

  return (
    <div className="flex h-full w-full flex-row items-center justify-end">
      <BhTooltip body={t("TASK.COPY_TASK_LINK")} key={0}>
        <BhIconButton icon={faLink} buttonProps={{ classes: "mx-1.25", onClick: copyTaskLink }} />
      </BhTooltip>
      {enableTaskLocking && (
        <BhTooltip body={t("TASK.LOCK.MESSAGE")} key={1}>
          <BhIconButton
            icon={task.locked ? faLock : faUnlock}
            buttonProps={{
              classes: "mx-1.25",
              onClick: toggleLock
            }}
          />
        </BhTooltip>
      )}
      <div className="mx-2 h-full py-3.5">
        <BhLightSeparatorVertical />
      </div>
      {actionButtons.map((button) => {
        return button;
      })}
      {actionButtons && actionButtons.length > 0 && (
        <div className="mx-2 h-full py-3.5">
          <BhLightSeparatorVertical />
        </div>
      )}
      <BhTextOnlyButton icon={faXmark} buttonProps={{ onClick: closeTaskModal }}>
        {t("GLOBAL.CLOSE")}
      </BhTextOnlyButton>
    </div>
  );
};

export default DefaultTaskModalHeader;
