import React, { FC, ReactNode } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/pro-solid-svg-icons";

interface Props {
  children: ReactNode;
}

const ConfirmationFolderPathFC: FC<Props> = ({ children }: Props) => {
  return (
    <div className={classNames("bh-border-pigeon-40 bh-hover-container bh-text-deep-ocean-60 min-h-8 flex items-center border-b py-1 font-bold")}>
      <span className="px-2">
        <FontAwesomeIcon icon={faFolder} size="sm" />
      </span>
      {children}
    </div>
  );
};

const ConfirmationFolderPath = React.memo(ConfirmationFolderPathFC);
export default ConfirmationFolderPath;
