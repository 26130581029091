import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { setAllTasksTaskBoardOpen } from "@/app/store/taskBoardsSlice";
import { fetchAllTasksAsync } from "@/app/store/tasksSlice";
import TaskBoardHeaderRow from "@components/taskBoard/TaskBoardHeaderRow";
import TaskBoardFilter from "@components/taskBoard/TaskBoardFilter";
import TaskBoardColumnsContainer from "@components/taskBoard/TaskBoardColumnsContainer";
import BhFilterContainerWithItemsOnRight from "@components/filters/BhFilterContainerWithItemsOnRight";
import TaskBoardSort from "@components/taskBoard/TaskBoardSort";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import { EntityId } from "@reduxjs/toolkit";

const AllTasksTaskBoard: FC = () => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();
  const [lastOpenedTaskBoardByProjectId, setLastOpenedTaskBoardByProjectId] = useLocalStorage<{ [projectId: EntityId]: EntityId }>("lastOpenedTaskBoardByProjectId", {});

  useEffect(() => {
    dispatch(setAllTasksTaskBoardOpen(true));
    dispatch(fetchAllTasksAsync({ includeExtraFields: true, projectId: projectId }));
    setLastOpenedTaskBoardByProjectId({ ...lastOpenedTaskBoardByProjectId, [projectId]: -2 });
    return function cleanup() {
      dispatch(setAllTasksTaskBoardOpen(false));
    };
  }, []);

  return (
    <div className="flex h-full w-full min-w-0 flex-col overflow-hidden px-6">
      <TaskBoardHeaderRow />
      <BhFilterContainerWithItemsOnRight right={<TaskBoardSort />}>
        <TaskBoardFilter />
      </BhFilterContainerWithItemsOnRight>
      <TaskBoardColumnsContainer />
    </div>
  );
};

export default AllTasksTaskBoard;
