import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import { CompanyInvoiceType, IBillingModel, ProjectInvoiceType } from "@/model/IBillingInfo";
import { useTranslation } from "react-i18next";
import BhModalFooter from "@components/modal/BhModalFooter";
import { IProjectOrder } from "@/model/billing/IProjectOrder";
import { confirmProjectOrders, fetchProjectOrders, getProjectActivationBillingModel } from "@/api/billingAPI";
import { IProjectBilling } from "@/model/billing/IProjectBilling";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { formatDate } from "@/utilities/dateUtility";
import BillingForm from "@/views/home/project/detail/projectSettings/BillingForm";
import { CompanyStatus } from "@/model/ICompany";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { isValidEmails } from "@/utilities/jsUtilities";

interface Props {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  projectOrder: IProjectOrder;
  setProjectOrders?: React.Dispatch<React.SetStateAction<IProjectOrder[]>>;
}

const CompanySettingsConfirmProjectModal: FC<Props> = ({ isShown, setIsShown, projectOrder, setProjectOrders }) => {
  const company = useAppSelector((state) => selectCompanyById(state, projectOrder.companyBillingRequisites?.companyId ?? 0));
  const [projectBilling, setProjectBilling] = useState<IProjectBilling>(projectOrder.projectBilling);
  const [billingModel, setBillingModel] = useState<IBillingModel>({} as IBillingModel);
  const { t } = useTranslation();

  useEffect(() => {
    if (company && isShown) {
      getProjectActivationBillingModel(company?.id, projectOrder.id).then((billingModel) => {
        setBillingModel(billingModel);
      });
    }
  }, [isShown]);

  const companyHasMonthlyAggregateInvoice = projectOrder.companyBillingRequisites?.invoiceType === CompanyInvoiceType.AGGREGATE;
  const getProjectBillingWithCompanyBillingValues = () => {
    return {
      ...projectBilling,
      customerRecipientEmails: projectOrder.companyBillingRequisites!.customerRecipientEmails,
      customerRecipient: projectOrder.companyBillingRequisites!.customerRecipient,
      customerAddress: projectOrder.companyBillingRequisites!.customerAddress,
      customerRegCode: projectOrder.companyBillingRequisites!.customerRegCode,
      customerVatNumber: projectOrder.companyBillingRequisites!.customerVatNumber,
      customerAdditionalInfo: projectOrder.companyBillingRequisites!.customerAdditionalInfo
    };
  };

  const isSaveButtonDisabled =
    !projectBilling.customerRecipientEmails ||
    projectBilling.customerRecipientEmails.length === 0 ||
    !isValidEmails(projectBilling.customerRecipientEmails) ||
    !projectBilling.customerRecipient ||
    projectBilling.customerRecipient.length === 0 ||
    (!projectBilling.customerCivilian && (!projectBilling.customerRegCode || projectBilling.customerRegCode.length === 0));

  const onSave = useCallback(async () => {
    setIsShown(false);
    if (projectOrder.companyBillingRequisites) {
      projectOrder.projectBilling = companyHasMonthlyAggregateInvoice && projectBilling.type === ProjectInvoiceType.MONTH ? getProjectBillingWithCompanyBillingValues() : projectBilling;
      confirmProjectOrders(projectOrder.companyBillingRequisites.companyId, [projectOrder]).then(() => {
        if (company && company.id && setProjectOrders) {
          fetchProjectOrders(company.id).then((projectOrders) => {
            setProjectOrders(projectOrders);
          });
        }
      });
    }
  }, [projectBilling]);

  const onFormChange = (changedValue: IProjectBilling) => {
    setProjectBilling((prevState) => {
      return { ...prevState, ...changedValue };
    });
  };

  const onCancel = () => {
    setIsShown(false);
    setProjectBilling(projectOrder.projectBilling);
  };

  if (!company) {
    return null;
  }

  return (
    <BhModal
      isShown={isShown}
      setIsShown={setIsShown}
      size={"2xl"}
      onClose={onCancel}
      header={<h2>{t("PROJECT.CONFIRM.BILLING.MODAL.HEADER")}</h2>}
      children={
        <BhScrollableBody>
          <div className="px-8">
            <BillingForm
              billingForm={projectBilling}
              onChange={onFormChange}
              companyBillingRequisites={projectOrder.companyBillingRequisites}
              companyHasMonthlyAggregateInvoice={companyHasMonthlyAggregateInvoice}
              companyHasTrial={company?.status === CompanyStatus.TRIAL}
              companyTrialEnd={formatDate(company.trialEnd)}
              billingModel={billingModel}
              projectName={projectOrder.name}
            />
          </div>
        </BhScrollableBody>
      }
      footer={<BhModalFooter onCancel={onCancel} onConfirm={onSave} confirmDisabled={isSaveButtonDisabled} confirmButtonText={t("PROJECT.ACTIVATE") as string} />}
    />
  );
};
export default CompanySettingsConfirmProjectModal;
