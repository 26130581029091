import React, { FC } from "react";
import TaskInfoField from "@components/task/modal/TaskInfoField";
import { ITask, ITaskStatusChangeDTO, TaskStatus } from "@/model/taskBoard/ITask";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveTaskStatusAsync, selectOpenedTaskDisabled } from "@/app/store/tasksSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";

interface Props {
  task: ITask;
  onSelect: Function;
}

const TaskStatusInfoField: FC<Props> = ({ task, onSelect }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isNewTask = !task.id;
  const isTaskDisabled = useAppSelector(selectOpenedTaskDisabled);
  const projectId = useAppSelector(selectCurrentProjectId);

  const statuses = [
    { name: "TASK.TODO", status: TaskStatus.TODO },
    { name: "TASK.IN_PROGRESS", status: TaskStatus.IN_PROGRESS },
    { name: "TASK.NEEDS_APPROVAL", status: TaskStatus.NEEDS_APPROVAL },
    { name: "TASK.DONE", status: TaskStatus.DONE },
    { name: "TASK.ARCHIVED", status: TaskStatus.ARCHIVED }
  ];

  const values = statuses.filter((statusObject) => {
    return statusObject.status !== task.status;
  });

  const changeTaskStatus = (statusObject: any) => {
    if (isNewTask) {
      const changedObject = { status: statusObject.status };
      onSelect(changedObject);
    } else {
      const taskStatusChangeDTO = { taskId: task.id, status: statusObject.status, checklistTask: task.checklistRowId ? true : false } as ITaskStatusChangeDTO;
      dispatch(saveTaskStatusAsync({ taskStatusChangeDTO: taskStatusChangeDTO, projectId: projectId }));
    }
  };

  return (
    <BhDropdown
      button={
        <TaskInfoField
          text={t("TASK.STATUS")}
          children={<div className="bh-text-deep-ocean font-bold">{t("TASK." + task.status)}</div>}
          valueExists={true}
          trailingIcon={faCaretDown}
          disabled={isTaskDisabled}
        />
      }
      menu={<BhDropdownMenu type={BhDropdownTypeEnum.STRING} values={values} textProperty={"name"} onSelect={changeTaskStatus} closeOnItemClick={true} translateValues={true} />}
      position={BhDropdownPositionEnum.BOTTOM_LEFT}
      buttonClasses={"w-full px-0"}
      menuClasses={"right-4 top-11"}
      disabled={isTaskDisabled}
    />
  );
};

export default TaskStatusInfoField;
