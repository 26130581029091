import React, { FC, ReactNode, useState } from "react";

interface Props {
  items: Array<ReactNode>;
}

const ListScrollContainer: FC<Props> = ({ items }) => {
  const increments = 25;
  const [filterFirst, setFilterFirst] = useState(increments);

  const handleScroll = (event: any) => {
    if (event.currentTarget.offsetHeight + event.currentTarget.scrollTop >= event.currentTarget.scrollHeight - 100) {
      setFilterFirst(filterFirst + increments);
    }
  };

  return (
    <div className="h-full overflow-auto pb-32" onScroll={handleScroll}>
      {items.map((item) => item).slice(0, filterFirst)}
    </div>
  );
};

export default ListScrollContainer;
