import { fetchProgress } from "@/api/bimAPI";
import { useAppDispatch } from "@/app/hooks";
import { IFileEntity } from "@/model/files/IFileEntity";
import { IfcConversionStatus, IfcConversionStatusDTO } from "@/model/files/IIfcConversionStatusDTO";
import { delay } from "@/utilities/jsUtilities";
import { EntityId } from "@reduxjs/toolkit";
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCube } from "@fortawesome/pro-solid-svg-icons/faCube";
import { fileModified } from "@/app/store/filesSlice";
import { useTranslation } from "react-i18next";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons/faCircleExclamation";

interface Props {
  fileEntity: IFileEntity;
  conversionStatus: IfcConversionStatusDTO | null;
  setConversionStatus: Dispatch<SetStateAction<IfcConversionStatusDTO>>;
}

const FilePendingConversionContainer: FC<Props> = ({ fileEntity, conversionStatus, setConversionStatus }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [pollingCounter, setPollingCounter] = useState<number>(0);

  useEffect(() => {
    if (fileEntity) {
      initialize(fileEntity.id);
    }
  }, [fileEntity]);

  useEffect(() => {
    if (conversionStatus && conversionStatus.status === IfcConversionStatus.RUNNING) {
      pollProgress();
    }
  }, [pollingCounter]);

  const initialize = async (fileEntityId: EntityId) => {
    setConversionStatus({} as IfcConversionStatusDTO);
    if (fileEntity.pendingConversion) {
      fetchProgress(fileEntity.projectId, fileEntity.id).then(async (result) => {
        setConversionStatus(result);
        setPollingCounter(1);
      });
    }
  };

  const pollProgress = async () => {
    if (!conversionStatus) return;
    if (pollingCounter === 0) return;
    await delay(2000);
    await fetchProgress(fileEntity.projectId, fileEntity.id).then(
      (progressResult) => {
        setConversionStatus(progressResult);
        setPollingCounter(pollingCounter + 1);
        const conversionSuccessful =
          progressResult.status === IfcConversionStatus.SUCCESS && progressResult.fileEntity && !progressResult.fileEntity.pendingConversion && progressResult.fileEntity.convertedToXkt;
        if (conversionSuccessful) {
          dispatch(fileModified({ ...fileEntity, ...{ pendingConversion: progressResult.fileEntity.pendingConversion, convertedToXkt: progressResult.fileEntity.convertedToXkt } }));
        }
      },
      function (error) {
        console.log(error);
        setConversionStatus({ status: IfcConversionStatus.FAILED } as IfcConversionStatusDTO);
      }
    );
  };

  if (!fileEntity || !conversionStatus?.status) {
    return null;
  }

  return (
    <div className="min-w-48">
      {conversionStatus.status === IfcConversionStatus.RUNNING && (
        <div className="flex flex-row items-center gap-x-2">
          <FontAwesomeIcon
            className="bim-file-converting-animation bh-text-pigeon"
            icon={faCube}
            // @ts-ignore
            flip
          />
          <div className="bh-text-deep-ocean whitespace-nowrap">{t("BIM.FILE.CONVERTING")}</div>
        </div>
      )}
      {/*TODO: Failed conversion indicator*/}
      {conversionStatus.status === IfcConversionStatus.UNCONVERTABLE && (
        <div className="bh-text-error-red flex flex-row items-center gap-x-2">
          <FontAwesomeIcon icon={faCircleExclamation} />
          <div className="whitespace-nowrap">{t("BIM.FILE.CONVERTING_FAILED")}</div>
        </div>
      )}
    </div>
  );
};

export default FilePendingConversionContainer;
