import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { formatAndRoundDecimalNumber } from "@/utilities/jsUtilities";
import { IChecklistStatistics } from "@/model/checklist/IChecklistStatistics";
import ChecklistStatisticsDetailsSkeleton from "@components/checklists/statistics/ChecklistStatisticsDetailsSkeleton";

interface Props {
  checklistStatistics: IChecklistStatistics;
  isLoading: boolean;
}

const ChecklistStatisticsSummaryDetailsFC: FC<Props> = ({ checklistStatistics, isLoading }) => {
  const { t } = useTranslation();

  const statisticsHeaders: Array<string> = [
    "CHECKLIST.STATISTICS.AVERAGE_ROUNDS",
    "CHECKLIST.STATISTICS.AVERAGE_FAULTS",
    "CHECKLIST.STATISTICS.FIRST_ROUND_AVERAGE",
    "CHECKLIST.STATISTICS.MOST_FAULTS"
  ];

  const checklistStatisticsFormatted = (value: string | number) => {
    if (value === "NaN") return "-";
    if (typeof value === "number") return formatAndRoundDecimalNumber(value);
  };

  return (
    <div className="flex flex-col px-3 pt-3.5">
      <div className="flex flex-row space-x-3">
        {statisticsHeaders.map((header, index) => (
          <p className="w-40" key={index}>
            {t(header)}
          </p>
        ))}
      </div>
      <div className="flex flex-row space-x-3">
        {isLoading && <ChecklistStatisticsDetailsSkeleton />}
        {!isLoading && (
          <>
            <h1 className="w-40 pt-2">{checklistStatisticsFormatted(checklistStatistics.averageRounds)}</h1>
            <h1 className="w-40 pt-2">{checklistStatisticsFormatted(checklistStatistics.averageFaults)}</h1>
            <h1 className="w-40 pt-2">{checklistStatisticsFormatted(checklistStatistics.averageFirstRoundFaults)}</h1>
            <h1 className="w-40 pt-2">{checklistStatistics.mostFaults || "-"}</h1>
          </>
        )}
      </div>
    </div>
  );
};

const ChecklistStatisticsSummaryDetails = React.memo(ChecklistStatisticsSummaryDetailsFC);
export default ChecklistStatisticsSummaryDetails;
