import React, { useState } from "react";
import { IWorkGroup } from "@/model/IWorkGroup";
import ContactWorkGroup from "@/views/home/project/detail/users/contacts/ContactWorkGroup";
import { useTranslation } from "react-i18next";

interface Props {
  workGroups: Array<IWorkGroup>;
}

const ContactWorkGroupsContainer = React.memo<Props>(({ workGroups }) => {
  const { t } = useTranslation();
  const firstWorkGroup = workGroups[0];
  const otherWorkGroups = workGroups.slice(1); // all besides 1st
  const [expanded, toggleExpanded] = useState(false);

  const toggleExpandWorkGroups = () => {
    toggleExpanded((state) => !state);
  };
  return (
    <>
      {firstWorkGroup && (
        <div className="flex flex-col pb-2">
          <ContactWorkGroup workGroup={firstWorkGroup} />
          {otherWorkGroups.length > 0 && !expanded && (
            <div className="bh-text-dark-jungle bh-underline-link hover:bh-text-deep-ocean peer cursor-pointer text-sm" onClick={toggleExpandWorkGroups}>
              +{otherWorkGroups.length} {`${t("CONTACT.USER_GROUP.MORE_BUTTON")}`}
            </div>
          )}
          {expanded && (
            <div id="hiddenWorkGroups">
              {otherWorkGroups.map((workGroup) => (
                <ContactWorkGroup workGroup={workGroup} key={workGroup.id} />
              ))}
            </div>
          )}
          {otherWorkGroups.length > 0 && expanded && (
            <div className="bh-text-dark-jungle bh-underline-link hover:bh-text-deep-ocean cursor-pointer text-sm" onClick={toggleExpandWorkGroups}>
              {`${t("CONTACT.USER_GROUP.SHOW_LESS")}`}
            </div>
          )}
        </div>
      )}
    </>
  );
});

export default ContactWorkGroupsContainer;
