import React, { FC } from "react";
import BhTableRow from "@components/table/BhTableRow";

interface Props {
  numberOfDisplayedItems: number;
}

const CompanySettingsUsersAllUserSkeleton: FC<Props> = ({ numberOfDisplayedItems }) => {
  return (
    <>
      {[...Array(numberOfDisplayedItems)].map((e, i) => {
        return (
          <BhTableRow key={i} classes="w-full border-b bh-border-pigeon-40">
            <td className="w-2/5 min-w-0">
              <div className="bh-bg-pigeon-50 h-5 w-72 animate-pulse rounded" />
            </td>
            <td className="w-72 min-w-0 flex-grow">
              <div className="bh-bg-pigeon-50 h-5 w-52 animate-pulse rounded" />
            </td>
            <td className="flex-grow-0">
              <div className="bh-bg-pigeon-50 h-5 w-52 animate-pulse rounded" />
            </td>
          </BhTableRow>
        );
      })}
    </>
  );
};

export default CompanySettingsUsersAllUserSkeleton;