import React, { FC } from "react";
import { IBhButtonWithIconProps } from "@components/buttons/IBhButtonProps";
import BhButtonTemplateWithIcon from "@components/buttons/BhButtonTemplateWithIcon";
import { classNames } from "@/utilities/jsUtilities";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";

// FIGMA https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H/Bauhub-design-system?type=design&node-id=17355-230211&mode=design&t=TS3BtPGtr1njTkiZ-4

const BhCloseButton: FC<IBhButtonWithIconProps> = ({ buttonProps, icon, children }) => {
  buttonProps = buttonProps ?? {};
  buttonProps.classes = classNames(
    buttonProps.classes,
    buttonProps.textClasses || "bh-text-white disabled:bh-text-pigeon-60",
    "!rounded-full w-7 h-7 bh-bg-deep-ocean hover:bh-bg-deep-ocean-80 hover:bh-bg-deep-ocean active:bh-bg-deep-ocean-80 disabled:bh-bg-pigeon-20 disabled:bh-text-pigeon-60"
  );

  return (
    <BhButtonTemplateWithIcon buttonProps={buttonProps} icon={faTimes}>
      {children}
    </BhButtonTemplateWithIcon>
  );
};

export default BhCloseButton;
