import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import BhSearchInput, { IBhSearchInputProps } from "@components/input/BhSearchInput";

const BhSearchInputWBG: FC<IBhSearchInputProps> = ({
  property,
  inputClasses,
  initialValue,
  saveCallback,
  placeholder,
  onChangeCallback,
  inputRef,
  onFocus,
  onBlurAction,
  onEnterCallback,
  debounceLength,
  disabled,
  withoutBorder
}) => {
  return (
    <BhSearchInput
      property={property}
      initialValue={initialValue}
      saveCallback={saveCallback}
      placeholder={placeholder}
      inputClasses={classNames("bh-bg-smoke", inputClasses)}
      onChangeCallback={onChangeCallback}
      inputRef={inputRef}
      onFocus={onFocus}
      onBlurAction={onBlurAction}
      onEnterCallback={onEnterCallback}
      debounceLength={debounceLength}
      disabled={disabled}
      withoutBorder={withoutBorder}
    />
  );
};

export default BhSearchInputWBG;
