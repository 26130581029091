import React, { FC, useEffect } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { IBhButtonWithIconProps } from "@components/buttons/IBhButtonProps";
import BhButtonTemplateWithIcon from "@components/buttons/BhButtonTemplateWithIcon";

interface Props extends IBhButtonWithIconProps {
  isActive?: boolean;
}

// FIGMA https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H/Bauhub-design-system?node-id=2080%3A112375

const BhDeclineIconButton: FC<Props> = ({ buttonProps, icon, isActive, children }) => {
  buttonProps = buttonProps ?? {};
  buttonProps.classes += classNames(" bh-bg-error-red-10 hover:bh-bg-error-red-20 active:bh-bg-error-red-30 disabled:bh-bg-pigeon-20 disabled:bh-text-pigeon-60 bh-text-error-red-120 rounded h-8 w-8");

  return (
    <BhButtonTemplateWithIcon buttonProps={buttonProps} icon={icon}>
      {children}
    </BhButtonTemplateWithIcon>
  );
};

export default BhDeclineIconButton;
