import React, { Dispatch, FC, SetStateAction } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { deleteTaskAsync, resetOpenedTaskInfo } from "@/app/store/tasksSlice";
import { ITask } from "@/model/taskBoard/ITask";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { selectCurrentProjectId, toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { useTranslation } from "react-i18next";
import { taskModalClosedWithTaskIdSet } from "@/app/store/fileAnnotationsSlice";
import { useNavigate } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  task: ITask;
  setDeleteConfirmationModalOpen: Dispatch<SetStateAction<boolean>>;
  isDedicatedView?: boolean;
}

const TaskDeleteModal: FC<Props> = ({ task, setDeleteConfirmationModalOpen, isDedicatedView }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const projectId = useAppSelector(selectCurrentProjectId);

  const deleteTask = () => {
    dispatch(deleteTaskAsync(task)).then(() => {
      if (isDedicatedView) {
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/taskboard/list/${task.taskBoardId ? task.taskBoardId : "personal"}`);
      } else {
        dispatch(taskModalClosedWithTaskIdSet(task?.id));
        dispatch(toggleProjectModalsOpen({ modal: "projectTaskModal" }));
        dispatch(resetOpenedTaskInfo());
      }
      setDeleteConfirmationModalOpen(false);
    });
  };

  return (
    <BhDeleteConfirmationModal
      isOpen={true}
      setIsOpen={setDeleteConfirmationModalOpen}
      header={<h2>{t("TASK.DELETE.CONFIRMATION.TITLE")}</h2>}
      body={t("TASK.DELETE.CONFIRMATION.BODY")}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={deleteTask}
    />
  );
};

export default TaskDeleteModal;
