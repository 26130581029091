import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  selected?: boolean;
  children: React.ReactNode;
  onClick?: Function;
  classes?: string;
  size?: "sm" | "lg";
}

const BhCardContainer: FC<Props> = ({ selected, children, onClick, size, classes }) => {
  let cardClasses = " mt-1px relative flex min-h-[80px] flex-1 items-center justify-between outline outline-1 transition duration-500 ease-in-out first:rounded-t last:rounded-b mx-0.5";

  switch (size) {
    case "sm":
      cardClasses += " h-14 ";
      break;
    case "lg":
      cardClasses += " h-20 ";
      break;
    default:
    // do nothing
  }

  return (
    <div
      className={classNames(
        cardClasses,
        selected && "bh-bg-mint-50 bh-outline-dark-jungle hover:bh-bg-mint z-10 ",
        !selected && "bh-outline-pigeon-40 hover:bh-bg-mint-30 hover:bh-outline-pigeon hover:z-10",
        classes && classes,
        onClick && "cursor-pointer"
      )}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {children}
    </div>
  );
};

export default BhCardContainer;
