import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import ShareboxFileContainer from "@/views/home/project/detail/shareboxes/detail/ShareboxFileContainer";
import { selectShareboxFilesForFolderSorted } from "@/app/store/shareboxesSlice";

interface Props {
  shareboxId: EntityId;
  shareboxUuid: string;
  folderId: EntityId;
  publicSharebox: boolean;
  attachmentFileIds: Array<EntityId>;
}

const ShareboxFilesContainerListViewFolder: FC<Props> = ({ shareboxId, shareboxUuid, folderId, publicSharebox, attachmentFileIds }) => {
  const filesForFolderSorted = useAppSelector((state) => selectShareboxFilesForFolderSorted(state, shareboxId, folderId));

  return (
    <div>
      {filesForFolderSorted?.map((file) => (
        <ShareboxFileContainer key={file.id} fileEntity={file} shareboxId={shareboxId} shareboxUuid={shareboxUuid} publicSharebox={publicSharebox} attachmentFileIds={attachmentFileIds} />
      ))}
    </div>
  );
};

export default ShareboxFilesContainerListViewFolder;
