import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProject } from "@/app/store/project/projectSlice";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { selectCurrentUser } from "@/app/store/userSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import BhLargeText from "@components/text/BhLargeText";
import baudriveSyncMirror from "@img/baudrive-peeglike-compressed.png";
import enWebAppImg1 from "@img/baudrive-veebirak-en-1.png";
import enWebAppImg2 from "@img/baudrive-veebirak-en-2.png";
import etWebAppImg1 from "@img/baudrive-veebirak-et-1.png";
import etWebAppImg2 from "@img/baudrive-veebirak-et-2.png";
import rightSyncImg from "@img/baudrive-syncivad-failid-parempoolne-compressed.png";
import leftSyncImg from "@img/baudrive-syncivad-failid-vasakpoolne-compressed.png";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons/faArrowsRotate";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";

import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchSyncedProjectIdList } from "@/api/syncAPI";
import SyncProjectDirectoryTabsContainer from "@/views/home/sync/SyncProjectDirectoryTabsContainer";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { ButtonSize } from "@components/buttons/IBhButtonProps";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import BhTextOnlyButton from "@/components/buttons/BhTextOnlyButton";

interface Props { }

const BaudriveContainer: FC<Props> = () => {
  useDocumentTitle("Baudrive");
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const project = useAppSelector(selectCurrentProject);
  const currentPlan = project.plan;
  const currentUser = useAppSelector(selectCurrentUser);
  const img1 = currentUser?.language === "et_EE" ? etWebAppImg1 : enWebAppImg1;
  const img2 = currentUser?.language === "et_EE" ? etWebAppImg2 : enWebAppImg2;
  const [anyProjectSynced, setAnyProjectSynced] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(setSidebarCollapsed(false));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.BAUDRIVE));
    fetchSyncedProjectIdList().then((res) => {
      setAnyProjectSynced(res.length > 0);
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      {!isLoading && anyProjectSynced && currentPlan === "PRO" && <SyncProjectDirectoryTabsContainer showDownloadButton={true} projectId={project.id} hideFooter={true} />}
      {!isLoading && ((!anyProjectSynced && currentPlan === "PRO") || currentPlan === "LIGHT") && (
        <BhScrollableBody>
          {currentPlan === "LIGHT" && (
            <div className="bh-bg-deep-ocean bh-text-white m-6 flex flex-row items-center rounded-xl py-12">
              <div className="flex w-1/2 flex-row justify-end">
                <div className="flex w-full max-w-[500px] flex-col pl-14 pr-4">
                  <p>{t("BAUDRIVE.LIGHT_UPPER_TEXT")}</p>
                  <h1 className="bh-text-white">Baudrive</h1>
                  <BhLargeText>
                    <strong className="bh-text-bauhub-green">{t("BAUDRIVE.LIGHT_UPPER_TEXT2_STRONG")}</strong> {t("BAUDRIVE.LIGHT_UPPER_TEXT2")}
                  </BhLargeText>
                  <BhLargeText>
                    <strong className="bh-text-bauhub-green">{t("BAUDRIVE.LIGHT_UPPER_TEXT3_STRONG")}</strong> {t("BAUDRIVE.LIGHT_UPPER_TEXT3")}
                  </BhLargeText>
                  <BhTextOnlyButton
                    buttonProps={{ classes: "mt-5 w-44 bh-bg-mint", size: ButtonSize.XL, onClick: () => window.open(currentUser.language === "et_EE" ? "https://www.bauhub.ee/hinnad" : "https://www.bauhub.ee/prices", "_blank") }}
                  >
                    {t("BAUDRIVE.SYNC_PLANS_BUTTON")}
                  </BhTextOnlyButton>
                  <p className="mt-5">
                    {t("BAUDRIVE.FOR_WINDOWS")}
                  </p>
                </div>
              </div>
              <div className="flex w-1/2 flex-row justify-start">
                <div className="flex w-full max-w-[600px] flex-col pr-10">
                  <img src={baudriveSyncMirror} className="m-8 max-h-[480px]" alt="Baudrive Sync" />
                </div>
              </div>
            </div>
          )}
          {currentPlan === "PRO" && (
            <div className="bh-bg-mint bh-text-deep-ocean m-6 flex flex-row items-center rounded-xl">
              <div className="flex w-1/4 flex-col items-center overflow-hidden md:hidden">
                <div className="m-8 max-w-[250px]">
                  <img src={leftSyncImg} alt="Baudrive Sync" />
                </div>
              </div>
              <div className="w-2/4 items-center py-12 px-4 text-center md:w-full">
                <p>
                  <FontAwesomeIcon icon={faArrowsRotate} className={"bh-text-bauhub-green pr-1"} />
                  {t("BAUDRIVE.PRO_UPPER_TEXT")}
                </p>
                <h1>Baudrive</h1>
                <BhLargeText classes="py-4">
                  <strong>{t("BAUDRIVE.LIGHT_UPPER_TEXT2_STRONG")}</strong>
                  <div>{t("BAUDRIVE.LIGHT_UPPER_TEXT2")}</div>
                </BhLargeText>
                <BhLargeText classes="mb-4">
                  <strong>{t("BAUDRIVE.DOWNLOAD_MS_STORE")}</strong>
                </BhLargeText>
                <BhPrimaryButton icon={faArrowDownToLine} buttonProps={{ size: ButtonSize.XL, onClick: () => window.open("https://www.microsoft.com/store/apps/9NMS5FST0HFJ", "_blank") }}>
                  {t("BAUDRIVE.DOWNLOAD_BAUDRIVE")}
                </BhPrimaryButton>
              </div>
              <div className="flex w-1/4 flex-col items-center overflow-hidden md:hidden">
                <div className="m-8 max-w-[250px]">
                  <img src={rightSyncImg} alt="Baudrive Sync" />
                </div>
              </div>
            </div>
          )}
          <div className="xl:mx:8 my-12 mx-20 flex flex-row items-center md:flex-col">
            <div className="flex w-1/2 flex-col md:w-full">
              <img src={img1} className="mr-6" alt="Screenshot 1" />
            </div>
            <div className="text-18px ml-6 w-1/2 pb-4 md:w-full">
              <h1>{t("BAUDRIVE.WHY_SYNC")}</h1>
              <ul className="ml-4 mt-4 list-outside list-disc space-y-4">
                <li>{t("BAUDRIVE.WHY_SYNC_1")}</li>
                <li>{t("BAUDRIVE.WHY_SYNC_2")}</li>
                <li>{t("BAUDRIVE.WHY_SYNC_3")}</li>
              </ul>
            </div>
          </div>
          <div className="xl:mx:8 my-12 mx-20 flex flex-row items-center md:flex-col">
            <div className="text-18px flex w-1/2 flex-col pb-4 md:w-full">
              <h1>{t("BAUDRIVE.CONTROL")}</h1>
              <p>{t("BAUDRIVE.CONTROL_1")}</p>
            </div>
            <div className="ml-6 w-1/2 md:w-full">
              <img src={img2} className="mr-6" alt="Screenshot 2" />
            </div>
          </div>

          {currentPlan === "LIGHT" && (
            <div className="m-12 flex flex-row items-center justify-center">
              <div className="flex flex-col text-center">
                <div className="text-18px max-w-[400px] p-8">
                  <strong className="pr-1">{t("BAUDRIVE.SYNC_LOWER_TEXT_STRONG")}</strong>
                  {t("BAUDRIVE.SYNC_LOWER_TEXT")}
                </div>
                <BhPrimaryButton
                  buttonProps={{ size: ButtonSize.XL, onClick: () => window.open(currentUser.language === "et_EE" ? "https://www.bauhub.ee/hinnad" : "https://www.bauhub.ee/prices", "_blank") }}
                >
                  {t("BAUDRIVE.SYNC_PLANS_BUTTON")}
                </BhPrimaryButton>
              </div>
            </div>
          )}
          {currentPlan === "PRO" && (
            <div className="m-12 flex flex-row items-center justify-center">
              <div className="flex-col text-center">
                <h1 className="max-w-[600px] p-8">{t("BAUDRIVE.SYNC_PRO_LOWER_TEXT")}</h1>
                <BhPrimaryButton icon={faArrowDownToLine} buttonProps={{ size: ButtonSize.XL, onClick: () => window.open("https://www.microsoft.com/store/apps/9NMS5FST0HFJ", "_blank") }}>
                  {t("BAUDRIVE.DOWNLOAD_BAUDRIVE")}
                </BhPrimaryButton>
              </div>
            </div>
          )}
        </BhScrollableBody>
      )}
    </>
  );
};

export default BaudriveContainer;
