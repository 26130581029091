import React, { FC, useState } from "react";
import BhInputSimple from "@components/input/BhInputSimple";
import ProtocolAddRowButton from "@components/form/base/body/fields/protocolField/ProtocolAddRowButton";
import FormBaseProtocolFieldTablePlaceholderRow from "@components/form/base/body/fields/protocolField/FormBaseProtocolFieldTablePlaceholderRow";
import { classNames } from "@/utilities/jsUtilities";
import BhIconButton from "@components/buttons/BhIconButton";
import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
import FormProtocolRowInput from "@components/form/base/body/fields/protocolField/FormProtocolRowInput";
import { useTranslation } from "react-i18next";

interface Props {
  row: any;
  isLastRow: boolean;
  saveCallback: Function;
  saveNewRowCallback: Function;
  removeRowCallback: Function;
  disabled?: boolean;
  isOnlyRow?: boolean;
}

const FormBaseProtocolFieldTableRow: FC<Props> = ({ row, isLastRow, saveCallback, saveNewRowCallback, removeRowCallback, disabled, isOnlyRow }) => {
  const { t } = useTranslation();
  const isSubRow = row.level === 1;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="flex flex-col gap-y-1" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <div className="flex flex-row items-start gap-x-1">
        <div className="min-w-14 h-full w-14 py-1">
          <ProtocolAddRowButton saveNewRowCallback={saveNewRowCallback} row={row} isCurrentRowSubRow={isSubRow} disabled={disabled} isFormBase={true} />
        </div>
        <div className={classNames(isSubRow ? "min-w-16 max-w-16 ml-4 w-16" : "min-w-20 max-w-20 w-20")}>
          <BhInputSimple
            initialValue={row.nr}
            property={"nr"}
            inputClasses={isSubRow ? "min-w-16" : "min-w-20"}
            placeholder={t("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.NUMBER") as string}
            saveCallback={saveCallback}
            disabled={disabled}
          />
        </div>
        <div className="-mb-1 h-full w-full">
          <FormProtocolRowInput
            initialValue={row.content}
            property={"content"}
            saveCallback={saveCallback}
            placeholder={t("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.CONTENT") as string}
            disabled={disabled}
            row={row}
            contentFormatSaveCallback={saveCallback}
            isFormBase={true}
          />
        </div>
        <div className="min-w-56 w-56">
          <BhInputSimple initialValue={row.task && row.task.assignee} property={"assignee"} disabled={true} placeholder={t("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.ASSIGNEE") as string} />
        </div>
        <div className="min-w-26 max-w-26 w-26">
          <BhInputSimple
            initialValue={row.task && row.task.deadline}
            property={"deadline"}
            disabled={true}
            inputClasses={"min-w-26"}
            placeholder={t("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.DEADLINE") as string}
          />
        </div>
        <div className="min-w-24 w-24"></div>
        <div className="min-w-24 w-24"></div>
        <div className="min-w-10 max-w-10 w-10">{!disabled && !isOnlyRow && isHovered && <BhIconButton icon={faXmark} buttonProps={{ onClick: removeRowCallback }} />}</div>
      </div>
      {isLastRow && !disabled && <FormBaseProtocolFieldTablePlaceholderRow isFormBase={true} isCurrentRowSubRow={isSubRow} row={row} saveNewRowCallback={saveNewRowCallback} disabled={disabled} />}
    </div>
  );
};

export default FormBaseProtocolFieldTableRow;
