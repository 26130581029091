import React, { FC, Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowDownToLine, faSignature } from "@fortawesome/pro-regular-svg-icons";
import {
  allFileEntitiesUnSelected,
  fetchAllUnlockedFilesForDirsAsync,
  fileEntitySelected,
  selectAllFilesSelected,
  selectTossFileAnimationTriggered,
  setDirectoryModalsOpen,
  setSelectedFilesAsDeleteConfirmationModalFileEntityIds
} from "@/app/store/filesSlice";
import { faShareFromSquare } from "@fortawesome/pro-regular-svg-icons/faShareFromSquare";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons/faFileExport";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import BhLightSeparatorVertical from "@components/separator/BhLightSeparatorVertical";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhFileCardMedium from "@components/cards/BhFileCardMedium";
import { selectBimDirectoryId, selectIsProjectArchived, toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { IAuthorityBoolean } from "@/model/IUser";
import { IProjectModalsOpen } from "@/model/projects/IProjectModals";
import { isDirectory } from "@/utilities/fileEntity/fileEntityUtilities";
import { IFileEntity } from "@/model/files/IFileEntity";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { EntityId } from "@reduxjs/toolkit";
import { Trans, useTranslation } from "react-i18next";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretUp } from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import BhTooltip from "@components/BhTooltip";
import { faCheckDouble } from "@fortawesome/pro-solid-svg-icons/faCheckDouble";
import { fetchPresignedBatchUrl, fetchUrlForFile } from "@/api/fileAPI";
import { imitateBatchFileDownload, imitateUrlDownload } from "@/utilities/downloadUtilities";
import { selectCurrentUser } from "@/app/store/userSlice";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { useModal } from "react-modal-hook";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons/faCircleXmark";
import { classNames } from "@/utilities/jsUtilities";
import { Transition } from "@headlessui/react";
import DirectoryFileBasketTossFile from "@/views/home/project/detail/directory/DirectoryFileBasketTossFile";

interface Props {
  privilegesForDirectory: IAuthorityBoolean;
  currentDirectoryId: EntityId;
}

const DirectoryFileBasketContainer: FC<Props> = ({ privilegesForDirectory, currentDirectoryId }) => {
  const { t } = useTranslation();
  const isProjectNotArchived = !useAppSelector(selectIsProjectArchived);
  const selectedFileEntities = useAppSelector(selectAllFilesSelected);
  const currentUser = useAppSelector(selectCurrentUser);
  const tossFileActive = useAppSelector(selectTossFileAnimationTriggered);
  const projectBimDirectoryId = useAppSelector(selectBimDirectoryId);
  const isCurrentDirectoryBimDir = projectBimDirectoryId === currentDirectoryId;
  const [isShowing, setIsShowing] = useState<boolean>(false);
  const [fileListOpen, setFileListOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [showNoFilesSelectedModalOpen, hideNoFilesSelectedModal] = useModal(() => (
    <BhModal
      setIsShown={hideNoFilesSelectedModal}
      header={
        <h2>
          <FontAwesomeIcon icon={faCircleXmark} size="sm" className="bh-text-error-red pr-5" />
          {t("DIRECTORY.NO_FILES_FOUND.HEADER")}
        </h2>
      }
      children={<p className="px-8 pt-4 pb-8">{t("DIRECTORY.NO_FILES_FOUND.BODY")}</p>}
      footer={<BhModalFooter onCancel={hideNoFilesSelectedModal} cancelButtonText={t("GLOBAL.CLOSE") as string} />}
    />
  ));

  useEffect(() => {
    setIsShowing(selectedFileEntities.length > 0);
  }, [selectedFileEntities.length]);

  if (selectedFileEntities.length === 0) {
    return null;
  }

  const prepareFilesForActionsAndOpenModal = async (modalName: keyof IProjectModalsOpen) => {
    const directories = selectedFileEntities.filter((fileEntity) => isDirectory(fileEntity)).map((fileEntity) => fileEntity.id);
    const files = selectedFileEntities.filter((fileEntity) => !isDirectory(fileEntity)).map((fileEntity) => fileEntity.id);
    const subFiles: Array<IFileEntity> = directories.length > 0 ? ((await dispatch(fetchAllUnlockedFilesForDirsAsync(directories)).then((action) => action.payload)) as Array<IFileEntity>) : [];
    const subFilesWithoutDirectories = subFiles.filter((fileEntity) => !isDirectory(fileEntity)).map((fileEntity) => fileEntity.id);
    dispatch(fileEntitySelected({ ids: subFilesWithoutDirectories, selected: true }));
    subFilesWithoutDirectories.length + files.length > 0 ? dispatch(toggleProjectModalsOpen({ modal: modalName })) : showNoFilesSelectedModalOpen();
    dispatch(fileEntitySelected({ ids: directories, selected: false }));
  };

  const downloadFiles = () => {
    if (selectedFileEntities.length > 0) {
      if (selectedFileEntities.length === 1 && !isDirectory(selectedFileEntities[0])) {
        const fileEntity = selectedFileEntities[0];
        fetchUrlForFile(fileEntity.id, false, true, fileEntity.uuid)
          .then((response) => {
            imitateUrlDownload(response.value);
          })
          .then(() => {
            dispatch(allFileEntitiesUnSelected());
          });
      } else {
        fetchPresignedBatchUrl(selectedFileEntities.map((fileEntity) => fileEntity.id))
          .then((result) => imitateBatchFileDownload(result))
          .then(() => {
            dispatch(allFileEntitiesUnSelected());
          });
      }
    }
  };

  const allSelectedFilesFromCurrentDir = selectedFileEntities.every((fe) => fe.parentFileEntityId === currentDirectoryId);

  return (
    <Transition as={Fragment} show={isShowing} appear={true} enter="transform transition duration-[300ms]" enterFrom="opacity-0 translate-y-24" enterTo="opacity-100 -translate-y-0">
      <div className="bh-shadow bh-bg-smoke max-w-660px fixed left-1/2 bottom-6 flex -translate-x-1/2 flex-col rounded-xl">
        {fileListOpen && (
          <div className="max-h-70-vh overflow-y-auto overflow-x-hidden">
            <div className="flex h-full flex-col overflow-auto pb-4">
              <div className="p-2 text-right">
                <BhTextOnlyButton buttonProps={{ onClick: () => dispatch(allFileEntitiesUnSelected()) }}>
                  <Trans>GLOBAL.REMOVE_ALL</Trans> <FontAwesomeIcon className="pl-1" icon={faTimes} aria-hidden="true" />
                </BhTextOnlyButton>
              </div>
              <div>
                {selectedFileEntities.map((selectedFileEntity) => (
                  <div key={selectedFileEntity.id} className="p-1 pt-0">
                    <BhFileCardMedium fileEntity={selectedFileEntity} deleteCallback={() => dispatch(fileEntitySelected({ ids: [selectedFileEntity.id], selected: false }))} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="bh-bg-raised-white flex h-16 w-full flex-row overflow-hidden rounded-xl border">
          <div className="bh-bg-bauhub-green-120 flex cursor-pointer items-center rounded-l px-1" onClick={() => setFileListOpen(!fileListOpen)}>
            <DirectoryFileBasketTossFile />
            <div className="bh-text-white relative flex flex-row items-center p-4 text-center font-bold">
              <div className="flex flex-row items-center whitespace-nowrap">
                <span className={classNames(tossFileActive && "animate-scale", "text-[20px]")}>{selectedFileEntities.length}</span>
                <FontAwesomeIcon icon={fileListOpen ? faCaretDown : faCaretUp} className="text-12px pl-1" />
              </div>
            </div>
          </div>
          {!isCurrentDirectoryBimDir && (
            <div className="flex flex-1 items-center justify-center gap-x-4 p-6">
              {isProjectNotArchived && (
                <BhTooltip body={t("GLOBAL.SIGN")}>
                  <BhIconButton icon={faSignature} buttonProps={{ onClick: () => prepareFilesForActionsAndOpenModal("directoryContainerModal") }} />
                </BhTooltip>
              )}
              {privilegesForDirectory?.isRead && (
                <BhTooltip body={t("GLOBAL.SHARE")}>
                  <BhIconButton icon={faShareFromSquare} buttonProps={{ onClick: () => prepareFilesForActionsAndOpenModal("directoryShareboxModal") }} />
                </BhTooltip>
              )}
              {privilegesForDirectory?.isWrite && (
                <BhTooltip body={t("CONFIRM.CONFIRM")}>
                  <BhIconButton icon={faCheckDouble} buttonProps={{ onClick: () => prepareFilesForActionsAndOpenModal("directoryConfirmationModal") }} />
                </BhTooltip>
              )}
            </div>
          )}
          {!isCurrentDirectoryBimDir && <BhLightSeparatorVertical />}
          <div className="flex items-center gap-x-4 p-6 sm:hidden">
            {privilegesForDirectory?.isRead && (
              <BhTooltip body={t("GLOBAL.DOWNLOAD")}>
                <BhIconButton icon={faArrowDownToLine} buttonProps={{ onClick: downloadFiles }} />
              </BhTooltip>
            )}
            {allSelectedFilesFromCurrentDir && privilegesForDirectory?.isAdmin && !isCurrentDirectoryBimDir && (
              <>
                <BhTooltip body={t("GLOBAL.MOVE")}>
                  <BhIconButton
                    icon={faFileExport}
                    buttonProps={{
                      onClick: () => {
                        dispatch(setDirectoryModalsOpen({ modal: "moveModal", value: true }));
                      }
                    }}
                  />
                </BhTooltip>
                <BhTooltip body={t("GLOBAL.COPY")}>
                  <BhIconButton
                    icon={faCopy}
                    buttonProps={{
                      onClick: () => {
                        dispatch(setDirectoryModalsOpen({ modal: "copyModal", value: true }));
                      }
                    }}
                  />
                </BhTooltip>
              </>
            )}
            {allSelectedFilesFromCurrentDir && privilegesForDirectory?.isWrite && (
              <BhTooltip body={t("GLOBAL.DELETE")}>
                <BhIconButton
                  icon={faTrash}
                  buttonProps={{
                    onClick: () => {
                      if (!privilegesForDirectory?.isAdmin) {
                        const anySelectedFileNotUploadedByCurrentUser = selectedFileEntities.some((file) => file.uploaderUserEntityId !== currentUser.id);
                        if (anySelectedFileNotUploadedByCurrentUser) {
                          dispatch(
                            toastFlagAdded({
                              id: uuidv4(),
                              type: BauhubBannerType.ERROR,
                              disappear: true,
                              translateCode: "PROJECT.DIRECTORY.FILE_DELETE_ERROR"
                            })
                          );
                          return;
                        }
                      }
                      dispatch(setSelectedFilesAsDeleteConfirmationModalFileEntityIds());
                      dispatch(setDirectoryModalsOpen({ modal: "deleteConfirmationModal", value: true }));
                    }
                  }}
                />
              </BhTooltip>
            )}
          </div>
          <BhLightSeparatorVertical />
          <div className="flex items-center p-4">
            <BhTooltip body={t("GLOBAL.CLOSE")}>
              <BhIconButton icon={faXmark} buttonProps={{ onClick: () => dispatch(allFileEntitiesUnSelected()) }} />
            </BhTooltip>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default DirectoryFileBasketContainer;
