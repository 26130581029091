import React, { Dispatch, FC, SetStateAction } from "react";
import BhTableHeader from "@components/table/BhTableHeader";
import BhTableRow from "@components/table/BhTableRow";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IBauhubEvent } from "@/model/IBauhubEvent";
import BhUserIconWithNameForSignature from "@components/user/BhUserIconWithNameForSignature";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import InitialQueueSignatureActionButtons from "@/views/home/project/detail/container/containerComponents/InitialQueueSignatureActionButtons";
import { useTranslation } from "react-i18next";

interface Props {
  signatures: Array<ISignatureHolder>;
  setSignatures: Dispatch<SetStateAction<ISignatureHolder[]>>;
}

const InitialQueueSignaturesList: FC<Props> = ({ signatures, setSignatures }) => {
  const { t } = useTranslation();
  const sortedSignatures = signatures.sort((a, b) => {
    return a.orderNumber < b.orderNumber ? -1 : 1;
  });

  const tableColumnHeaders: Array<IBhTableHeader<IBauhubEvent>> = [
    {
      id: 1,
      content: t("GLOBAL.ORDER"),
      classes: "w-24 text-center"
    },
    { id: 2, content: t("GLOBAL.NAME") },
    { id: 3, content: "", classes: "w-48" }
  ];

  if (!sortedSignatures || sortedSignatures.length === 0) {
    return null;
  }

  const deleteSignature = (signature: ISignatureHolder) => {
    setSignatures(sortedSignatures.filter((s) => s !== signature));
  };

  const moveSignature = (signature: ISignatureHolder, up: boolean) => {
    const index = sortedSignatures.indexOf(signature);
    const findIndex = up ? index - 1 : index + 1;
    const other = sortedSignatures.at(findIndex);
    if (!other) return;
    const removedElements = sortedSignatures.filter((s) => ![signature, other].includes(s));
    const newOther = { ...signature, orderNumber: other.orderNumber };
    const newOne = { ...other, orderNumber: signature.orderNumber };
    setSignatures([...removedElements, newOther, newOne]);
  };

  const moveSignatureUp = (signature: ISignatureHolder) => {
    moveSignature(signature, true);
  };

  const moveSignatureDown = (signature: ISignatureHolder) => {
    moveSignature(signature, false);
  };

  return (
    <table className="min-w-full overflow-auto ">
      <BhTableHeader columns={tableColumnHeaders} />
      <tbody className="divide-y overflow-hidden ">
        {sortedSignatures.map((signature, index) => {
          return (
            <BhTableRow key={signature.username}>
              <td className="text-center">{index + 1}</td>
              <td>
                <BhUserIconWithNameForSignature signature={signature} />
              </td>
              <td className="text-right">
                <InitialQueueSignatureActionButtons
                  signature={signature}
                  index={index}
                  length={signatures.length}
                  deleteSignature={deleteSignature}
                  moveSignatureUp={moveSignatureUp}
                  moveSignatureDown={moveSignatureDown}
                />
              </td>
            </BhTableRow>
          );
        })}
      </tbody>
    </table>
  );
};

export default InitialQueueSignaturesList;
