import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import WebViewer, { Core, WebViewerInstance } from "@pdftron/webviewer";
import { fetchUrlForFile } from "@/api/fileAPI";
import { EntityId } from "@reduxjs/toolkit";
import { shouldUsePdftronServer } from "@/utilities/fileEntity/fileEntityUtilities";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchFileEntityAsync, selectFileById } from "@/app/store/filesSlice";
import { saveFileAnnotationAsync, selectFileAnnotationForFileEntityId } from "@/app/store/fileAnnotationsSlice";
import { FileEntityType } from "@/model/files/IFileEntity";
import { ReactComponent as BhLogoLoading } from "@svg/bauhub-loading-compressed.svg";
import { createBauhubYesPointTool } from "@/views/home/project/detail/pdftron/pdftronFunctions";
import { selectCurrentUser } from "@/app/store/userSlice";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import { IFileAnnotation } from "@/model/files/IFileAnnotation";
import { IChecklistRoundRow } from "@/model/checklist/IChecklistRoundRow";
import { saveChecklistRoundRowAsync } from "@/app/store/checklist/checklistRowsSlice";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  rowId: EntityId;
  roundRow?: IChecklistRoundRow;
  isReadOnly: boolean;
  fileEntityId: EntityId;
  header?: ReactNode;
  setMarkYesPointsModalOpen: (value: boolean) => void;
}

const PdftronMarkYesPointsContainer: FC<Props> = ({ rowId, roundRow, isReadOnly, fileEntityId, header, setMarkYesPointsModalOpen }) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const fileEntity = useAppSelector((state) => selectFileById(state, fileEntityId));
  const [webviewerInitializing, setWebviewerInitializing] = useState(false);
  const fileAnnotation = useAppSelector((state) => selectFileAnnotationForFileEntityId(state, fileEntityId));
  const [instance, setInstance] = useState<WebViewerInstance>();
  const [annotationsDrawnFirstTime, setAnnotationsDrawnFirstTime] = useState(false);
  const [fileUrlLoading, setFileUrlLoading] = useState(true);
  const viewer = useRef(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fileEntity && !webviewerInitializing) {
      setWebviewerInitializing(true);
      fetchFileUrlAndInitPdftron();
    }
  }, [fileEntity]);

  useEffect(() => {
    if (!fileEntity) {
      dispatch(fetchFileEntityAsync(fileEntityId));
    }
  }, []);

  const drawAnnotations = () => {
    if (fileAnnotation && fileAnnotation.xfdf) {
      instance?.Core.documentViewer.addEventListener("documentLoaded", function () {
        instance?.Core.annotationManager.importAnnotations(fileAnnotation.xfdf);

        const primaryAnnotation = roundRow?.primaryAnnotationUuid && instance?.Core.annotationManager.getAnnotationById(roundRow.primaryAnnotationUuid);
        const primaryAnnotationGroup = primaryAnnotation && instance?.Core.annotationManager.getGroupAnnotations(primaryAnnotation);

        const allAnnotations = instance?.Core.annotationManager.getAnnotationsList();
        instance?.Core.annotationManager.hideAnnotations(allAnnotations);

        const annotationsToShow = primaryAnnotationGroup ? primaryAnnotationGroup : primaryAnnotation && [primaryAnnotation];
        if (!isReadOnly) {
          annotationsToShow && instance?.Core.annotationManager.ungroupAnnotations(annotationsToShow);
        }
        annotationsToShow && instance?.Core.annotationManager.showAnnotations(annotationsToShow);
      });
    }
  };

  const onConfirm = () => {
    if (!roundRow) return;

    const allAnnotations = instance?.Core.annotationManager.getAnnotationsList() || [];
    const shownAnnotations = allAnnotations.filter((annotation) => !annotation.Hidden);

    let primaryAnnotationUuid: string | undefined = undefined;
    if (shownAnnotations.length === 0) {
      primaryAnnotationUuid = undefined;
    }
    if (shownAnnotations.length > 0) {
      primaryAnnotationUuid = shownAnnotations[0].Id;
    }
    if (shownAnnotations.length > 1) {
      const primaryAnnotation = shownAnnotations.find((annotation) => annotation.Id === primaryAnnotationUuid);
      const subAnnotations = shownAnnotations.filter((annotation) => annotation.Id !== primaryAnnotationUuid);

      if (primaryAnnotation && subAnnotations.length > 0) {
        instance?.Core.annotationManager.groupAnnotations(primaryAnnotation, subAnnotations);
      }
    }

    instance?.Core.annotationManager.exportAnnotations({ links: false, widgets: false }).then(function (xfdfString) {
      fileEntity &&
        dispatch(saveFileAnnotationAsync({ fileEntityId: fileEntityId, xfdf: xfdfString, projectId: fileEntity.projectId } as IFileAnnotation)).then(() => {
          dispatch(saveChecklistRoundRowAsync({ ...roundRow, primaryAnnotationUuid: primaryAnnotationUuid }));
        });
    });
    setMarkYesPointsModalOpen(false);
  };

  // Add first time annotation drawing for one time only
  useEffect(() => {
    if (instance && fileAnnotation && !annotationsDrawnFirstTime) {
      drawAnnotations();
      setAnnotationsDrawnFirstTime(true);
    }
  }, [fileAnnotation, instance]);

  const fetchFileUrlAndInitPdftron = () => {
    async function fetchFileUrl() {
      setFileUrlLoading(true);
      return await fetchUrlForFile(fileEntityId, false, false, fileEntity?.uuid);
    }

    fetchFileUrl()
      .then((presignedUrl) => {
        setFileUrlLoading(false);
        if (!viewer.current || instance) return;

        const formExtension = fileEntity?.type === FileEntityType.FORM ? ".pdf" : "";
        const fileName = fileEntity?.name + "" + formExtension;

        WebViewer(
          {
            licenseKey: "Bauhub OU:OEM:Bauhub::B+:AMS(20260305):4A5F85823C6F78B942CE606F4F3D959CDE1FC65BA4AF8F58BE54B2B6F5C7",
            filename: fileName,
            annotationUser: currentUser.username,
            path: ConfigSingleton.getInstance().getConfig().PUBLIC_URL + "/pdftron10.9.0",
            useDownloader: fileEntity && fileEntity.size > 40000000,
            initialDoc: presignedUrl.value,
            extension: fileName.split(".").pop(),
            webviewerServerURL: fileEntity && shouldUsePdftronServer(fileEntity) ? "https://pdf3.bauhub.ee" : undefined,
            disabledElements: ["header", "markReplaceTextToolButton", "annotationCommentButton", "annotationStyleEditButton", "linkButton", "contextMenuPopup"],
            isReadOnly: isReadOnly
          },
          viewer.current
        ).then((instance) => {
          setInstance(instance);

          instance.UI.hotkeys.off();

          if (!isReadOnly) {
            instance.Core.documentViewer.enableAnnotations();

            createBauhubYesPointTool(
              instance,
              false,
              () => {},
              () => {},
              rowId
            );
            instance?.UI.setToolMode("CustomYesPointStampTool" + rowId);

            const annoChangedCB = function (annotations: Array<Core.Annotations.Annotation>, action: string, info: Core.AnnotationManager.AnnotationChangedInfoObject) {
              annotations.forEach((annotation) => {
                annotation.disableRotationControl();
              });
            };
            instance?.Core.annotationManager.addEventListener("annotationChanged", annoChangedCB);
          }
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    return function cleanup() {
      instance?.UI.dispose();
    };
  }, []);

  if (!fileEntity) {
    return null;
  }

  return (
    <BhModal
      setIsShown={setMarkYesPointsModalOpen}
      isShown={true}
      size="7xl"
      header={<div className="flex w-full flex-row items-center justify-between">{header}</div>}
      footer={<BhModalFooter onConfirm={onConfirm} />}
    >
      <div className="h-[60vh]">
        <div className="flex h-full w-full flex-col">
          <div className="flex h-full w-full flex-col overflow-hidden">
            <div className="flex w-full flex-row justify-center"></div>
            <div className="relative flex-1">
              {fileUrlLoading && (
                <div className="flex h-full w-full flex-row items-center justify-center">
                  <BhLogoLoading className="h-10 w-10" />
                </div>
              )}
              <div className="webviewer" ref={viewer} style={{ height: "100%", width: "100%" }} />
            </div>
          </div>
        </div>
      </div>
    </BhModal>
  );
};

export default PdftronMarkYesPointsContainer;
