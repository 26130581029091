import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BhBanner from "@components/banners/BhBanner";
import { BauhubBannerType } from "@/model/IProject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCube } from "@fortawesome/pro-solid-svg-icons/faCube";
import { EntityId } from "@reduxjs/toolkit";
import { IfcConversionStatus, IfcConversionStatusDTO } from "@/model/files/IIfcConversionStatusDTO";
import { fetchFilesStatus } from "@/api/bimAPI";
import { classNames, delay } from "@/utilities/jsUtilities";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhIconButton from "@components/buttons/BhIconButton";
import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { useNavigate } from "react-router-dom";

interface Props {
  projectId: EntityId;
  fileIds: Array<EntityId>;
  refreshModel: Function;
}

const XeokitFilesConversionStatusBanner: FC<Props> = ({ projectId, fileIds, refreshModel }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filesStatuses, setFilesStatuses] = useState<Array<IfcConversionStatusDTO>>([]);
  const conversionRunning = filesStatuses.length > 0 && filesStatuses.some((status) => status.status === IfcConversionStatus.RUNNING);
  const allFilesConvertedSuccessfully = !conversionRunning && filesStatuses.length > 0 && filesStatuses.every((status) => status.status === IfcConversionStatus.SUCCESS);
  const showUnconvertedFilesBanner = !conversionRunning && filesStatuses.length > 0 && !filesStatuses.every((status) => status.status === IfcConversionStatus.SUCCESS);
  const [bannerVisible, setBannerVisible] = useState(false);
  const [userHasClosedBanner, setUserHasClosedBanner] = useState(false);

  useEffect(() => {
    if (fileIds && fileIds.length > 0) {
      initializePolling();
    }
  }, [fileIds]);

  useEffect(() => {
    const bannerVisible = (conversionRunning && !userHasClosedBanner) || allFilesConvertedSuccessfully || showUnconvertedFilesBanner;
    setBannerVisible(bannerVisible);
    if (filesStatuses.some((status) => status.status === IfcConversionStatus.RUNNING)) {
      pollProgress();
    }
  }, [filesStatuses]);

  const initializePolling = () => {
    fetchFilesStatus(projectId, fileIds).then((statuses) => {
      setFilesStatuses(statuses);
    });
  };

  const pollProgress = async () => {
    await delay(2000);
    await fetchFilesStatus(projectId, fileIds).then((statuses) => {
      setFilesStatuses(statuses);
    });
  };

  const closeBanner = () => {
    setBannerVisible(false);
    setUserHasClosedBanner(true);
  };

  const openModelFiles = () => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/bim/dir`);
  };

  if (!bannerVisible) {
    return null;
  }

  return (
    <div className="absolute bottom-8 flex w-full flex-row items-center justify-center">
      <div className={classNames(conversionRunning && "w-[450px]", !conversionRunning && "w-[550px]")}>
        <BhBanner type={showUnconvertedFilesBanner ? BauhubBannerType.WARNING : BauhubBannerType.ANNOUNCEMENT} fullWidth={true}>
          <div className="flex w-full flex-row items-center justify-center gap-x-4">
            {!showUnconvertedFilesBanner && (
              <FontAwesomeIcon
                className="bim-file-converting-animation"
                icon={faCube}
                // @ts-ignore
                flip={conversionRunning}
              />
            )}
            <div className="flex w-full flex-row items-center justify-between">
              <div>{conversionRunning ? t("BIM.FILES_LOADING") : showUnconvertedFilesBanner ? t("BIM.UNCONVERTED_FILES") : t("BIM.FILES_ADDED")}</div>
              <div className="-mr-3 flex flex-row items-center">
                {allFilesConvertedSuccessfully && <BhSecondaryButton buttonProps={{ onClick: refreshModel }}>{t("BIM.REFRESH_MODEL")}</BhSecondaryButton>}
                {showUnconvertedFilesBanner && <BhTextOnlyButton buttonProps={{ onClick: openModelFiles }}>{t("BIM.OPEN_FILES")}</BhTextOnlyButton>}
                <BhIconButton icon={faXmark} iconClasses={showUnconvertedFilesBanner ? "bh-text-deep-ocean-80" : "bh-text-white"} buttonProps={{ onClick: closeBanner }} />
              </div>
            </div>
          </div>
        </BhBanner>
      </div>
    </div>
  );
};

export default XeokitFilesConversionStatusBanner;
