import React, { FC } from "react";

interface Props {
  badge?: React.ReactNode;
  children?: React.ReactNode;
}

const BhElementWithBadge: FC<Props> = ({ badge, children }) => {
  return (
    <div className="pointer-events-none relative inline-block">
      <div className="absolute -right-0.5 -top-0 z-10">{badge}</div>
      {children}
    </div>
  );
};

export default BhElementWithBadge;
