import React, { FC } from "react";
import BhFakeInput from "@components/input/BhFakeInput";
import { ITask } from "@/model/taskBoard/ITask";
import { getUserFullName } from "@/model/IUser";
import FormProtocolFieldTaskAssigneeInput from "@components/form/fields/protocolField/protocolFieldTaskFields/FormProtocolFieldTaskAssigneeInput";

interface Props {
  row: any;
  newFormTask: ITask;
  saveTaskToTaskBoard: Function;
  saveTaskToForm: Function;
  placeholder?: string;
  disabled: boolean;
}

const FormProtocolFieldTaskAssignee: FC<Props> = ({ row, saveTaskToForm, saveTaskToTaskBoard, disabled, placeholder, newFormTask }) => {
  const saveAssignee = (changedObject: any) => {
    let taskToSave = row.task ? row.task : newFormTask;
    if (changedObject.assignee.userEntityId) {
      taskToSave = { ...taskToSave, assignee: changedObject.assignee.userEntityId, assigneeFullName: getUserFullName(changedObject.assignee) };
    } else {
      taskToSave = { ...taskToSave, assigneeFullName: changedObject.assignee };
    }
    const taskBoardTaskExists = row.task && row.task.id;
    if (taskBoardTaskExists) {
      const chosenUserIsBauhubUser = Boolean(changedObject.assignee.userEntityId);
      const isPersonalTask = !row.task.taskBoardId;
      const chosenUserIsPersonalTaskUser = changedObject.assignee.userEntityId === row.task.assignee;

      if (!chosenUserIsBauhubUser) return;
      if (isPersonalTask && !chosenUserIsPersonalTaskUser) return;

      saveTaskToTaskBoard(taskToSave);
    } else {
      saveTaskToForm(taskToSave);
    }
  };

  return (
    <BhFakeInput value={row.task && row.task.assigneeFullName} className={"min-w-56 w-56"} placeholder={placeholder} disabled={disabled}>
      <FormProtocolFieldTaskAssigneeInput row={row} saveCallback={saveAssignee} disabled={disabled} />
    </BhFakeInput>
  );
};

export default FormProtocolFieldTaskAssignee;
