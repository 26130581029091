import React, { FC } from "react";

interface Props {
  progress: number;
  total: number;
}

const BhProgressBar: FC<Props> = ({ progress, total }) => {
  const progressPercentString = progress.toString().concat("%");
  const remainingPercentString = (total - progress).toString().concat("%");

  return (
    <div className="flex w-full flex-row items-center font-bold">
      <div className="flex h-1 w-full flex-row overflow-hidden rounded-full leading-normal">
        <div className="bh-bg-success-green-110 bh-text-white flex flex-row items-center justify-end rounded-full pr-1" style={{ width: progressPercentString }}></div>
        <div className="bh-bg-pigeon-20 flex flex-row items-center justify-start" style={{ width: remainingPercentString }} />
      </div>
    </div>
  );
};

export default BhProgressBar;
