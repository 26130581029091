import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId } from "@reduxjs/toolkit";
import { IContractWork } from "@/model/contracts/IContractWork";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { saveContractWork, saveNewContractWorkWithParentId } from "@/api/contractAPI";
import { RootState } from "@/app/store";
import { BhWorkType } from "@/model/BhWorkType";

const contractWorksAdapter = createEntityAdapter<IContractWork>({
  sortComparer: (a, b) => (a.id < b.id ? -1 : 1)
});

export interface IContractWorksState {
  status: BhStateStatusType;
}

export const contractWorksInitialState = contractWorksAdapter.getInitialState<IContractWorksState>({
  status: BhStateStatusType.INITIAL
});

export const saveNewContractWorkAsync = createAsyncThunk("contractWork/saveNewContractWork", async (contractWork: IContractWork) => {
  return saveNewContractWorkWithParentId(contractWork);
});
export const saveContractWorkAsync = createAsyncThunk("contractWork/saveContractWork", async (contractWork: IContractWork) => {
  return saveContractWork(contractWork);
});
export const findContractWorkFromStateAndThenSaveAsync = createAsyncThunk("contractWork/saveContractWork", async (changedContractWork: IContractWork, { getState }) => {
  const state: RootState = getState() as RootState;
  const stateContractWork = state.contractWorks.entities[changedContractWork.id];
  if (stateContractWork) {
    return saveContractWork({ ...stateContractWork, ...changedContractWork });
  }
});

const contractWorksSlice = createSlice({
  name: "contractWorks",
  initialState: contractWorksInitialState,
  reducers: {
    addContractWorks: contractWorksAdapter.upsertMany
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveNewContractWorkAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveNewContractWorkAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const deletedWorks = action.payload.filter((work) => work.deleted).map((work) => work.id);
        const addedWorks = action.payload.filter((work) => !work.deleted);
        contractWorksAdapter.removeMany(state, deletedWorks);
        contractWorksAdapter.upsertMany(state, addedWorks);
      })
      .addCase(saveContractWorkAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveContractWorkAsync.rejected, (state) => {
        state.status = BhStateStatusType.ERROR;
      })
      .addCase(saveContractWorkAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const deletedWorks = action.payload.filter((work) => work.deleted).map((work) => work.id);
        const addedWorks = action.payload.filter((work) => !work.deleted);
        contractWorksAdapter.removeMany(state, deletedWorks);
        contractWorksAdapter.upsertMany(state, addedWorks);
      });
  }
});

export const { addContractWorks } = contractWorksSlice.actions;

export const { selectAll: selectAllContractWorks, selectIds: selectContractWorkIds, selectById: selectContractWorkById } = contractWorksAdapter.getSelectors((state: RootState) => state.contractWorks);

export const selectContractWorksStatus = (state: RootState) => state.contractWorks.status;
export const selectContractEntityId = (state: RootState, contractId: EntityId) => contractId;
export const selectContractWorkType = (state: RootState, _: any, type: BhWorkType) => type;
export const selectContractWorkContainerEntityId = (state: RootState, _: any, x: any, contractWorkContainerId?: EntityId) => contractWorkContainerId;
export const selectContractWorkEntityId = (state: RootState, contractWorkId: EntityId) => contractWorkId;

export const selectSortedFirstLevelContractWorkIdsForContractId = createSelector(
  [selectAllContractWorks, selectContractEntityId, selectContractWorkType, selectContractWorkContainerEntityId],
  (contractWorks, contractId, type, contractWorkContainerId) => {
    return contractWorks
      .filter((cw) => cw.contractId === contractId && cw.type === type && cw.contractWorkContainerId === contractWorkContainerId && cw.parentContractWorkId === null)
      .sort((a: IContractWork, b: IContractWork) => (a.orderNumber > b.orderNumber ? 1 : -1))
      .map((cw) => cw.id);
  }
);

export const selectSortedContractWorkIdsForParentId = createSelector([selectAllContractWorks, selectContractWorkEntityId], (contractWorks, parentContractWorkId) => {
  return contractWorks
    .filter((cw) => cw.parentContractWorkId === parentContractWorkId)
    .sort((a: IContractWork, b: IContractWork) => (a.orderNumber > b.orderNumber ? 1 : -1))
    .map((cw) => cw.id);
});

export const selectTotalLevelsForContractId = createSelector(
  [selectAllContractWorks, selectContractEntityId, selectContractWorkType, selectContractWorkContainerEntityId],
  (contractWorks, contractId, type, contractWorkContainerId) => {
    let maxLevel = 0;

    function findMaxLevel(work: IContractWork, level: number): number {
      if (work.parentContractWorkId) {
        const parent = contractWorks.find((w) => w.id === work.parentContractWorkId);
        return (parent && findMaxLevel(parent, (level += 1))) || 0;
      } else {
        return level;
      }
    }

    contractWorks
      .filter((cw) => cw.contractId === contractId && cw.type === type && cw.contractWorkContainerId === contractWorkContainerId)
      .forEach((work) => {
        let level = findMaxLevel(work, 0);
        if (level && level > maxLevel) {
          maxLevel = level;
        }
      });

    return maxLevel + 1;
  }
);

export const selectMaxOrderNumberForFirstLevelContractWorksForContractId = createSelector(
  [selectAllContractWorks, selectContractEntityId, selectContractWorkType, selectContractWorkContainerEntityId],
  (contractWorks, contractId, type, contractWorkContainerId) => {
    const max = Math.max(...contractWorks.filter((cw) => cw.contractId === contractId && cw.type === type && cw.contractWorkContainerId === contractWorkContainerId).map((cw) => cw.orderNumber));
    return max && max > 0 ? max : 1;
  }
);

export default contractWorksSlice.reducer;
