import React, { ChangeEvent, FC, ReactElement, useId, useRef, useState } from "react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import SelectFilesModal from "@/views/home/project/projectModals/selectFilesModal/SelectFilesModal";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  uploadFilesSelectedCallback?: Function;
  projectFilesSelectedCallback?: (selectedFiles: Array<IFileEntity>) => any;
  importCallback?: Function;
  multiple?: boolean;
  button?: ReactElement;
  disabled?: boolean;
  excludeFileTypes?: Array<FileEntityType>;
  dropdownPosition?: BhDropdownPositionEnum;
  fileTypesAllowedForUpload?: string;
  onlyPdfTronFilesAllowedFromProjectFiles?: boolean;
}

const BhAddFilesContainer: FC<Props> = ({
  uploadFilesSelectedCallback,
  projectFilesSelectedCallback,
  importCallback,
  multiple,
  button,
  disabled,
  excludeFileTypes,
  dropdownPosition,
  fileTypesAllowedForUpload,
  onlyPdfTronFilesAllowedFromProjectFiles
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const inputId = useId();
  const [selectFilesModalOpen, setSelectFilesModalOpen] = useState(false);

  const onUploadFilesSelected = (event: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const files = [...event.target.files];
    if (!files) return;
    const filesWithSizeLargerThanZero = files.filter((file) => file.size > 0);
    event.target.value = "";
    if (uploadFilesSelectedCallback) {
      return uploadFilesSelectedCallback(filesWithSizeLargerThanZero);
    }
  };

  const buttonForUpload = button || (
    <BhTextOnlyButton icon={faPlus} buttonProps={{ disabled: disabled }}>
      <Trans>{multiple ? "GLOBAL.ADD_FILES" : "GLOBAL.ADD_FILE"}</Trans>
    </BhTextOnlyButton>
  );

  return (
    <div>
      <BhDropdown
        button={buttonForUpload}
        position={dropdownPosition || BhDropdownPositionEnum.BOTTOM_LEFT}
        menu={
          <BhDropdownMenu
            textProperty="text"
            type={BhDropdownTypeEnum.STRING}
            values={[
              uploadFilesSelectedCallback && {
                text: t("GLOBAL.UPLOAD_FILES"),
                function: () => {
                  // @ts-ignore
                  fileInputRef.current.click();
                }
              },
              projectFilesSelectedCallback && {
                text: t("FILEPICKER.PICK_FILE"),
                function: () => {
                  setSelectFilesModalOpen(true);
                }
              },
              importCallback && {
                text: t("FORM.BASE.ATTACHMENT.IMPORT"),
                function: () => {
                  importCallback();
                }
              }
            ].filter(Boolean)}
            closeOnItemClick={true}
          />
        }
        disabled={disabled}
      />
      <input type="file" id={inputId} ref={fileInputRef} style={{ display: "none" }} multiple={multiple} onChange={onUploadFilesSelected} accept={fileTypesAllowedForUpload} />
      {projectFilesSelectedCallback && selectFilesModalOpen && (
        <SelectFilesModal
          onSelect={projectFilesSelectedCallback}
          setModalOpen={setSelectFilesModalOpen}
          multiple={multiple}
          excludeFileTypes={excludeFileTypes}
          pdftronOnly={onlyPdfTronFilesAllowedFromProjectFiles}
        />
      )}
    </div>
  );
};

export default BhAddFilesContainer;
