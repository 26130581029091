export const pdftronDisabledElements = [
  "settingsButton",
  "thumbnailControl",
  "thumbnailsControlRotatePopupTrigger",
  "thumbnailsControlInsertPopupTrigger",
  "thumbnailsControlManipulatePopupSmallTrigger",
  "cropToolGroupButton",
  "toolbarGroup-Insert",
  "toolbarGroup-FillAndSign",
  "toolbarGroup-Forms",
  "toolbarGroup-Edit",
  "linkButton",
  "annotationCommentButton",
  "toggleNotesButton",
  "notesPanelResizeBar",
  "notesPanel",
  "stickyToolGroupButton",
  "stickyToolButton",
  "countToolGroupButton",
  "countToolButton",
  "markReplaceTextGroupButton",
  "markInsertTextGroupButton",
  "printWatermark",
  "insertPage",
  "replacePage",
  "extractPage",
  "deletePage"
];
