import React, { FC, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId, selectProjectTaskModalOpen, toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import {
  fetchChecklistPersonalTaskAsync,
  fetchPersonalTaskAsync,
  fetchTaskAsync,
  resetNewTask,
  resetOpenedTaskInfo,
  saveFormTaskAsync,
  savePersonalFormTaskAsync,
  savePersonalTaskAsync,
  saveTaskBoardTaskAsync,
  selectNewTask,
  selectOpenedChecklistTask,
  selectOpenedFormTask,
  selectOpenedTaskInfo,
  selectTaskById,
  setNewTask,
  setOpenedTaskInfo
} from "@/app/store/tasksSlice";
import { selectCurrentUser } from "@/app/store/userSlice";
import { isTaskPersonal } from "@/utilities/taskBoardUtil";
import TaskModalHeader from "@components/task/modal/TaskModalHeader";
import TaskModalBody from "@components/task/modal/TaskModalBody";
import { IOpenedTaskInfo, ITask } from "@/model/taskBoard/ITask";
import { taskModalClosedWithTaskIdSet } from "@/app/store/fileAnnotationsSlice";

const ProjectTaskModal: FC = () => {
  const dispatch = useAppDispatch();
  const projectTaskModalOpened = useAppSelector(selectProjectTaskModalOpen);
  const openedTaskInfo = useAppSelector(selectOpenedTaskInfo);
  const taskById = useAppSelector((state) => openedTaskInfo && openedTaskInfo.id && selectTaskById(state, openedTaskInfo.id));
  const formTask = useAppSelector(selectOpenedFormTask);
  const checklistTask = useAppSelector(selectOpenedChecklistTask);
  const task = formTask ? formTask : checklistTask ? checklistTask : taskById;
  const newTask = useAppSelector(selectNewTask);
  const currentUser = useAppSelector(selectCurrentUser);
  const projectId = useAppSelector(selectCurrentProjectId);
  const openedTask = task || newTask;
  const isNewTask = openedTask && !openedTask.id;
  const isPersonalTask = openedTask && isTaskPersonal(openedTask, currentUser);
  const [isVisible, setIsVisible] = useState(true);
  const [transitionTriggered, triggerTransition] = useState(false);

  // This runs only when modal is opened first time and task has id (has been saved before).
  useEffect(() => {
    if (projectTaskModalOpened && task) {
      const isPersonalTask = isTaskPersonal(task, currentUser);
      if (isPersonalTask) {
        if (checklistTask) {
          dispatch(fetchChecklistPersonalTaskAsync({ taskId: task.id, projectId: projectId }));
        } else {
          dispatch(fetchPersonalTaskAsync({ taskId: task.id, projectId: projectId }));
        }
      } else {
        dispatch(fetchTaskAsync({ taskIdentifier: task.taskIdentifier, projectId: projectId }));
      }
    }
  }, [projectTaskModalOpened]);

  useEffect(() => {
    if (formTask && taskById) {
      const formTaskHasChangedOnTaskBoard = new Date(taskById.updated) > new Date(formTask.updated);
      if (formTaskHasChangedOnTaskBoard) {
        const disabledTask = { ...openedTaskInfo, disabled: true } as IOpenedTaskInfo;
        dispatch(setOpenedTaskInfo(disabledTask));
      }
    }
  }, [taskById]);

  // Hack for transitioning out (timeout has to be equal to transition duration)
  useEffect(() => {
    if (transitionTriggered) {
      setIsVisible(false);
      setTimeout(() => {
        closeTaskModal();
      }, 300);
    }
  }, [transitionTriggered]);

  if (!openedTask) return null;

  const closeTaskModal = () => {
    dispatch(toggleProjectModalsOpen({ modal: "projectTaskModal" }));
    dispatch(taskModalClosedWithTaskIdSet(openedTaskInfo?.id || -1));
    dispatch(resetOpenedTaskInfo());
    dispatch(resetNewTask());
  };

  const setOrSaveTask = (changedObject: ITask) => {
    let changedTask = { ...openedTask, ...changedObject };
    if (isNewTask) {
      // Damn store can't handle Date
      if (changedTask.deadline) {
        changedTask = { ...changedTask, ...{ deadline: new Date(changedTask.deadline).toISOString() } };
      }
      dispatch(setNewTask(changedTask));
    } else {
      if (formTask) {
        saveFormTask(changedTask);
      } else {
        saveTaskBoardTask(changedTask);
      }
    }
  };

  const saveTaskBoardTask = (changedTask: ITask) => {
    if (isPersonalTask) {
      dispatch(savePersonalTaskAsync({ task: changedTask, projectId: projectId }));
    } else {
      dispatch(saveTaskBoardTaskAsync({ task: changedTask, projectId: projectId }));
    }
  };

  const saveFormTask = (changedTask: ITask) => {
    if (isPersonalTask) {
      dispatch(savePersonalFormTaskAsync({ task: changedTask, projectId: projectId }));
    } else {
      dispatch(saveFormTaskAsync({ task: changedTask, projectId: projectId }));
    }
  };

  return (
    <Transition
      className={"absolute right-0 top-0 z-30 flex h-screen w-screen flex-row items-end overflow-hidden transition duration-300"}
      as={"div"}
      appear={true}
      show={isVisible}
      enter="ease-in-out"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="bh-bg-pigeon h-full w-full opacity-60" onClick={() => triggerTransition(true)}></div>
      <div className="bh-shadow flex-task-modal flex h-full w-full max-w-[1108px] flex-shrink-0 flex-col overflow-hidden rounded">
        <TaskModalHeader task={openedTask} saveTaskCallback={setOrSaveTask} />
        <TaskModalBody task={openedTask} saveTaskCallback={setOrSaveTask} />
      </div>
    </Transition>
  );
};

export default ProjectTaskModal;
