import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareKanban } from "@fortawesome/pro-regular-svg-icons/faSquareKanban";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { faCalendarDay } from "@fortawesome/pro-regular-svg-icons/faCalendarDay";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { setOpenedTaskInfo } from "@/app/store/tasksSlice";
import { toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { useTranslation } from "react-i18next";
import { formatDate } from "@/utilities/dateUtility";
import { IFormTaskInfo, ITask } from "@/model/taskBoard/ITask";
import BhUserIcon from "@/components/user/BhUserIcon";
import { classNames, getUserFromFullName } from "@/utilities/jsUtilities";
import { isTaskPersonal } from "@/utilities/taskBoardUtil";
import { selectCurrentUser } from "@/app/store/userSlice";

interface Props {
  task: ITask;
  taskDisabled?: boolean;
  formTaskOpenCallback?: Function;
  formTaskInfo: IFormTaskInfo;
  clickDisabled?: boolean;
}

const FormTaskContainer: FC<Props> = React.memo(({ task, taskDisabled, formTaskOpenCallback, clickDisabled, formTaskInfo }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const isPersonalTaskOfCurrentUser = isTaskPersonal(task, currentUser);
  const isPersonalTaskBoardTask = !task.taskBoardId && task.id;
  const taskOpenDisabled = (isPersonalTaskBoardTask && !isPersonalTaskOfCurrentUser) || clickDisabled;

  const openTaskModal = () => {
    if (taskOpenDisabled) {
      return;
    }
    if (task.id) {
      dispatch(setOpenedTaskInfo({ id: task.id, disabled: taskDisabled, formTask: { ...task, formTaskInfo: formTaskInfo } }));
      dispatch(toggleProjectModalsOpen({ modal: "projectTaskModal" }));
    } else {
      formTaskOpenCallback && formTaskOpenCallback();
    }
  };

  return (
    <div
      className={classNames(
        "bh-border-pigeon-40 hover:bh-border-pigeon-60 bh-bg-white flex h-8 w-full cursor-pointer flex-row items-center justify-between rounded border p-1 transition-all duration-300",
        clickDisabled && "cursor-not-allowed"
      )}
      onClick={openTaskModal}
    >
      <div className="flex flex-row items-center">
        <div className="mr-3 flex h-6 w-6 flex-row items-center justify-center rounded" style={{ backgroundColor: task.category?.color }}>
          <div className="bh-text-white">
            <FontAwesomeIcon icon={faSquareKanban} />
          </div>
        </div>
        {(isPersonalTaskBoardTask || task.taskIdentifier) && (
          <div className="bh-text-deep-ocean-80 mr-1">{isPersonalTaskBoardTask ? t("GLOBAL.PERSONAL_TASK").toUpperCase() : task.taskIdentifier}</div>
        )}
        <div className="bh-text-deep-ocean font-bold">{task.name}</div>
      </div>
      <div className="flex flex-row items-center">
        <div className="mr-6">{task.status && <BhTag type={BhTagType.TASK_STATUS} children={t("TASK." + task.status.toUpperCase())} />}</div>
        {task.deadline && (
          <div className="bh-text-deep-ocean mr-4 flex flex-row items-center">
            <div className="mr-2">
              <FontAwesomeIcon icon={faCalendarDay} />
            </div>
            <div>{formatDate(new Date(task.deadline))}</div>
          </div>
        )}
        {task.assigneeFullName && <BhUserIcon user={getUserFromFullName(task.assigneeFullName)} size={24} />}
      </div>
    </div>
  );
});

export default FormTaskContainer;
