import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import { selectTaskBoardUsersByTaskBoardId } from "@/app/store/taskBoardsSlice";
import { convertSimpleValidUserAuthorityToUser } from "@/model/ISimpleValidUserAuthority";
import BhCommentInput from "@components/comment/BhCommentInput";

interface Props {
  onSubmit: Function;
  taskBoardId: EntityId;
  textProperty: string;
}

const BhCommentInputWithUsers: FC<Props> = ({ taskBoardId, onSubmit, textProperty }) => {
  const taskBoardUsers = useAppSelector((state) => (taskBoardId ? selectTaskBoardUsersByTaskBoardId(state, taskBoardId) : []));
  const users = taskBoardUsers.map((u) => convertSimpleValidUserAuthorityToUser(u));

  return <BhCommentInput textProperty={textProperty} onSubmit={onSubmit} usersWithAccess={users} />;
};

export default BhCommentInputWithUsers;
