import React, { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
  classes?: string;
}

const BhInputLabel: FC<Props> = ({ children, classes }) => {
  return <div className={classes ? classes : "text-13px l-h-16px bh-text-deep-ocean-80 px-0.5 py-1.5 font-bold"}>{children}</div>;
};

export default BhInputLabel;
