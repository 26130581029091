import React, { FC, useCallback, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhRadioInput from "@components/input/BhRadioInput";
import BhRadioGroup from "@components/input/BhRadioGroup";
import { IFileCopyOrMoveDTO, IFileEntity, NewVersionOption } from "@/model/files/IFileEntity";
import NewVersionModalFileCard from "@/views/home/project/detail/directory/directoryModals/copyAndMoveModals/NewVersionModalFileCard";
import { fetchFileRevisions } from "@/api/fileAPI";
import { useTranslation } from "react-i18next";
import { textWithVariables } from "@/utilities/jsUtilities";
import parse from "html-react-parser";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";

interface Props {
  dto: IFileCopyOrMoveDTO;
  onClose: Function;
  onSubmit: Function;
  isMoveModal?: boolean;
}

const NewVersionModal: FC<Props> = ({ dto, onClose, onSubmit, isMoveModal }) => {
  const { t } = useTranslation();
  const [option, setOption] = useState("");
  const [selectedFileRevisions, setSelectedFileRevisions] = useState([] as Array<IFileEntity>);
  const [destinationFileRevisions, setDestinationFileRevisions] = useState([] as Array<IFileEntity>);
  const [firstExampleFileWithRevisions, setFirstExampleFileWithRevisions] = useState([] as Array<IFileEntity>);
  const [secondExampleFileWithRevisions, setSecondExampleFileWithRevisions] = useState([] as Array<IFileEntity>);

  useEffect(() => {
    fetchFileRevisions(dto.fileToCopyOrMove.id).then((selectedFileRevisions) => {
      setSelectedFileRevisions(selectedFileRevisions);
      fetchFileRevisions(dto.matchingFile.id).then((destinationFileRevisions) => {
        setDestinationFileRevisions(destinationFileRevisions);
        setOption(NewVersionOption.ONLY_DRAGGED_FILE_AS_NEW_REVISION);
      });
    });
  }, [dto]);

  useEffect(() => {
    changeOption();
  }, [option]);

  const changeOption = useCallback(() => {
    if (option === NewVersionOption.ONLY_DRAGGED_FILE_AS_NEW_REVISION && dto.matchingFile) {
      // First file example
      const matchingFileWithRevisions = [...destinationFileRevisions, dto.matchingFile];
      const selectedFileWithNewExampleRevisionNumber = { ...dto.fileToCopyOrMove, revision: dto.matchingFile.revision + 1 };
      const firstFileExample = [...matchingFileWithRevisions, selectedFileWithNewExampleRevisionNumber].sort((a, b) => (a.revision < b.revision ? 1 : -1));
      setFirstExampleFileWithRevisions(firstFileExample);
      // Second file example
      const secondFileExample = selectedFileRevisions.sort((a, b) => (a.revision < b.revision ? 1 : -1));
      setSecondExampleFileWithRevisions(secondFileExample);
    }
    if (option === NewVersionOption.STACK_DRAGGED_FILE_REVISIONS && dto.matchingFile) {
      // First file example only needed
      const selectedFileWithNewExampleRevisionNumbers = [dto.fileToCopyOrMove, ...selectedFileRevisions].map((file) => {
        return { ...file, revision: file.revision + destinationFileRevisions.length + 1 };
      });
      const firstFileExample = [...selectedFileWithNewExampleRevisionNumbers, ...destinationFileRevisions, dto.matchingFile].sort((a, b) => (a.revision < b.revision ? 1 : -1));
      setFirstExampleFileWithRevisions(firstFileExample);
    }
    if (option === NewVersionOption.COMBINE_FILE_REVISIONS_ACCORDING_TO_TIME && dto.matchingFile) {
      // First file example only needed
      const filesSortedByCreatedTime = [dto.fileToCopyOrMove, dto.matchingFile, ...selectedFileRevisions, ...destinationFileRevisions].sort((a, b) =>
        new Date(a.created) > new Date(b.created) ? 1 : -1
      );
      const sortedFilesWithRevisionNumbers = filesSortedByCreatedTime.map((file, index) => {
        return { ...file, revision: index + 1 };
      });
      const sortedFilesWithRevisionNumbersReversed = sortedFilesWithRevisionNumbers.reverse();
      setFirstExampleFileWithRevisions(sortedFilesWithRevisionNumbersReversed);
    }
  }, [selectedFileRevisions, destinationFileRevisions, option]);

  const submit = () => {
    onSubmit(option);
  };

  const isLatestVersion = (file: IFileEntity, files: Array<IFileEntity>) => {
    const maxRevisionNumber = files.reduce(function (prev, current) {
      return prev.revision > current.revision ? prev : current;
    }).revision;
    return file.revision === maxRevisionNumber;
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={() => onClose()}
      size="4xl"
      header={<h2>{t("FILE.REVISION_OPTIONS.TITLE")}</h2>}
      footer={<BhModalFooter onCancel={onClose} onConfirm={submit} confirmButtonText={`${t("GLOBAL.SAVE")}`} />}
    >
      <BhScrollableBody>
        <div className="mx-20 my-14">
          <div>{parse(textWithVariables(t("FILE.REVISION_OPTIONS.BODY"), { fileName: dto.fileToCopyOrMove.name }))}</div>
          <BhRadioGroup>
            <BhRadioInput
              property=""
              checked={option === NewVersionOption.ONLY_DRAGGED_FILE_AS_NEW_REVISION}
              label={t("FILE.REVISION_OPTIONS.ONLY_DRAGGED_FILE_AS_NEW_REVISION") as string}
              onChange={() => setOption(NewVersionOption.ONLY_DRAGGED_FILE_AS_NEW_REVISION)}
            />
            <BhRadioInput
              property=""
              checked={option === NewVersionOption.STACK_DRAGGED_FILE_REVISIONS}
              label={t("FILE.REVISION_OPTIONS.STACK_DRAGGED_FILE_REVISIONS") as string}
              onChange={() => setOption(NewVersionOption.STACK_DRAGGED_FILE_REVISIONS)}
            />
            {isMoveModal && (
              <BhRadioInput
                property=""
                checked={option === NewVersionOption.COMBINE_FILE_REVISIONS_ACCORDING_TO_TIME}
                label={t("FILE.REVISION_OPTIONS.COMBINE_FILE_REVISIONS_ACCORDING_TO_TIME") as string}
                onChange={() => setOption(NewVersionOption.COMBINE_FILE_REVISIONS_ACCORDING_TO_TIME)}
              />
            )}
          </BhRadioGroup>
          <h3 className="mb-1 font-bold">{t("GLOBAL.RESULT")}</h3>
          {firstExampleFileWithRevisions.map((file, index) => {
            return <NewVersionModalFileCard key={index} fileEntity={file} isLatestVersion={isLatestVersion(file, firstExampleFileWithRevisions)} />;
          })}
          {option === NewVersionOption.ONLY_DRAGGED_FILE_AS_NEW_REVISION &&
            secondExampleFileWithRevisions.map((file, index) => {
              return <NewVersionModalFileCard key={index} fileEntity={file} isLatestVersion={isLatestVersion(file, secondExampleFileWithRevisions)} />;
            })}
        </div>
      </BhScrollableBody>
    </BhModal>
  );
};

export default NewVersionModal;
