import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import PlanFeaturesList from "@/views/home/company/detail/companySettingsSubscription/PlanFeaturesList";
import { EntityId } from "@reduxjs/toolkit";
import { companyPlanSet } from "@/app/store/companiesSlice";
import { useAppDispatch } from "@/app/hooks";
import { fetchBillingDowngradeInfo, postBillingDowngrade } from "@/api/billingAPI";
import { IDowngradePlanInfo, ProjectDowngradePlanInfo } from "@/model/billing/IDowngradePlanInfo";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import BhModalFooter from "@components/modal/BhModalFooter";
import { ICompanyBilling } from "@/model/billing/ICompanyBilling";
import { useTranslation } from "react-i18next";
import { formatDate } from "@/utilities/dateUtility";
import { BauhubPlan } from "@/model/IProject";

interface Props {
  setDowngradeModalOpen: Dispatch<SetStateAction<boolean>>;
  companyId: EntityId;
  setCompanyBilling: Dispatch<SetStateAction<ICompanyBilling>>;
  testDate: Date;
}
const CompanySettingsDowngradeModal: FC<Props> = ({ setDowngradeModalOpen, companyId, setCompanyBilling, testDate }) => {
  const { t } = useTranslation();
  const [downgradeInfo, setDowngradeInfo] = useState({} as IDowngradePlanInfo);
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchBillingDowngradeInfo(companyId, testDate.toISOString()).then((res) => {
      setDowngradeInfo(res);
    });
  }, []);

  const downgrade = () => {
    setConfirmDisabled(true);
    postBillingDowngrade(companyId, BauhubPlan.LIGHT, testDate.toISOString()).then((res: ICompanyBilling) => {
      setCompanyBilling(res);
      dispatch(companyPlanSet(res));
      setDowngradeModalOpen(false);
      setConfirmDisabled(false);
    });
  };

  const companyHasProjectsWithAnnualSubscriptions = downgradeInfo?.projects?.length > 0;

  return (
    <BhModal
      isShown={true}
      setIsShown={setDowngradeModalOpen}
      header={<h2>{t("COMPANY.SETTINGS.SUBSCRIPTION.DOWNGRADE_MODAL.HEADER")}</h2>}
      children={
        <BhScrollableBody>
          <div className="px-8 py-6">
            <p>{t("COMPANY.SETTINGS.SUBSCRIPTION.DOWNGRADE_MODAL.BODY")}</p>
            <PlanFeaturesList isProPlan={false} disablePlanChange={false} />
            {companyHasProjectsWithAnnualSubscriptions && (
              <div className="mt-4">
                <p dangerouslySetInnerHTML={{ __html: t("COMPANY.SETTINGS.SUBSCRIPTION.DOWNGRADE_MODAL.PROLONG_PROJECTS") as any }} />
                <div className="mt-4">
                  {downgradeInfo.projects.map((project: ProjectDowngradePlanInfo) => {
                    return (
                      <p
                        key={project.projectId}
                        dangerouslySetInnerHTML={{
                          __html: t("COMPANY.SETTINGS.SUBSCRIPTION.DOWNGRADE_MODAL.PROJECT", {
                            projectName: project.projectName,
                            originalEndDate: formatDate(project.originalEndDate),
                            newEndDate: formatDate(project.newEndDate)
                          }) as any
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </BhScrollableBody>
      }
      footer={
        <BhModalFooter confirmDisabled={confirmDisabled} onCancel={() => setDowngradeModalOpen(false)} confirmButtonText={t("COMPANY.SETTINGS.SUBSCRIPTION.CONFIRM") as string} onConfirm={downgrade} />
      }
    />
  );
};

export default CompanySettingsDowngradeModal;
