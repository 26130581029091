import React, { FC, useState } from "react";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveProjectAdministratorUserAuthoritiesAsync, selectCurrentProjectId, selectProjectAdministrators } from "@/app/store/project/projectSlice";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { Authority, IUserAuthoritiesRequest } from "@/model/IUserAuthority";
import BhInputLabel from "@components/input/BhInputLabel";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { useTranslation } from "react-i18next";
import { fetchUsersForMaruProject } from "@/api/maru/maruAPI";
import { convertMaruSafPersonToSimpleValidUserAuthority, IMaruSafPerson } from "@/model/maru/IMaruSafPerson";
import BhEmailInputWithUserDropdown from "@components/input/BhEmailInputWithUserDropdown";

const ProjectSettingsAdminInputMaru: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const projectAdministrators = useAppSelector(selectProjectAdministrators);
  const [emailInput, setEmailInput] = useState("");
  const [users, setUsers] = useState([] as Array<ISimpleValidUserAuthority>);

  const showValueCondition = (user: ISimpleValidUserAuthority) => {
    return !projectAdministrators.some((admin) => admin.username.toLowerCase() === user.username.toLowerCase());
  };

  const addAdmin = () => {
    if (emailInput) {
      const usernameIsNotEmail = !emailInput.match(/\S+@\S+\.\S+/);
      const maruUserNotExisting = !users.some((user) => user.username.toLowerCase() === emailInput.toLowerCase());
      if (usernameIsNotEmail || maruUserNotExisting) {
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.ERROR,
            disappear: true,
            children: maruUserNotExisting ? t("GLOBAL.USER_MISSING") : t("GLOBAL.EMAIL_INVALID")
          })
        );
        return;
      }
      const authToSave = { username: emailInput.toLowerCase().replace(/[\s\u200B-\u200D\uFEFF]/g, ""), authority: Authority.ADMIN } as IUserAuthoritiesRequest;
      dispatch(saveProjectAdministratorUserAuthoritiesAsync({ projectId: projectId, userAuths: [authToSave] }));
      setEmailInput("");
      setUsers([]);
    }
  };

  const queryValueCallback = (value: any) => {
    fetchUsersForMaruProject(projectId, value).then((maruSafPersons) => {
      const maruUsers = maruSafPersons
        .filter((maruSafPerson: IMaruSafPerson) => maruSafPerson.e_mail?.length > 0)
        .map((maruSafPerson: IMaruSafPerson) => convertMaruSafPersonToSimpleValidUserAuthority(maruSafPerson));
      setUsers(maruUsers);
    });
    setEmailInput(value);
  };

  return (
    <div>
      <BhInputLabel>{t("COMPANY.SETTINGS.ADD_ADMINISTRATOR")}</BhInputLabel>
      <div className="flex flex-row items-center">
        <div className="w-80">
          <BhEmailInputWithUserDropdown
            emailInput={emailInput}
            setEmailInput={queryValueCallback}
            values={users}
            showValueCondition={showValueCondition}
            placeholder={t("INPUT.PLACEHOLDER.NAME_OR_EMAIL") as string}
          />
        </div>
        <BhPrimaryButton buttonProps={{ onClick: addAdmin, disabled: !emailInput }}>{t("GLOBAL.SEND_INVITE")}</BhPrimaryButton>
      </div>
    </div>
  );
};

export default ProjectSettingsAdminInputMaru;
