import { createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { IFileEntity } from "@/model/files/IFileEntity";

export interface LightboxState {
  fileIds?: Array<EntityId>;
  currentImage?: IFileEntity;
  canRename?: boolean;
  open: boolean;
}

const initialState: LightboxState = {
  open: false
};

export const lightboxSlice = createSlice({
  name: "lightbox",
  initialState,
  reducers: {
    resetLightboxState: () => initialState,
    toggleLightboxOpen: (state) => {
      state.open = !state.open;
    },
    setLightboxCanRename: (state, action) => {
      state.canRename = action.payload;
    },
    setCurrentImage: (state, action: PayloadAction<IFileEntity>) => {
      state.currentImage = action.payload;
    },
    setLightboxFileIds: (state, action: PayloadAction<Array<EntityId>>) => {
      state.fileIds = action.payload;
    }
  }
});

export const { resetLightboxState, toggleLightboxOpen, setCurrentImage, setLightboxFileIds, setLightboxCanRename } = lightboxSlice.actions;
export const selectCurrentLightboxImage = (state: RootState) => state.lightbox.currentImage;
export const selectLightboxModalOpen = (state: RootState) => state.lightbox.open;
export const selectLightboxCanRename = (state: RootState) => state.lightbox.canRename;
export const selectLightboxFileIds = (state: RootState) => state.lightbox.fileIds;

export default lightboxSlice.reducer;
