import { IFileEntity } from "@/model/files/IFileEntity";
import { EntityId } from "@reduxjs/toolkit";

export interface IChecklistBaseVersionRow {
  id: EntityId;
  name: string;
  number: string;
  checklistRowType: ChecklistRowType;
  fieldType: ChecklistRowFieldType;
  editable: boolean;
  indentation: number;
  fieldTitle: string;
  fieldValue: string;
  orderNumber: number;
  checkpoint: boolean;
  checklistBaseVersionId: EntityId;
  deleted: boolean;
  optionFieldValue: any;
  plusMinusFieldValue: IChecklistRowPlusMinusFieldValue;
  required: boolean;
  previousChecklistBaseVersionRowId?: EntityId;
  attachments: Array<IFileEntity>;
  allowAttachmentUploads: boolean;
}

export interface IChecklistRowPlusMinusFieldValue {
  okTotal: number;
  notOkTotal: number;
  tooltipAdded: boolean;
  tooltipText: string;
  prefills: Array<{ id: number; text: string }>;
  autoCreateTask: boolean;
}

export enum PlusMinusCounterType {
  "PLUS" = "PLUS",
  "MINUS" = "MINUS"
}

export enum ChecklistRowType {
  "TEXTFIELD" = "TEXTFIELD",
  "CHECKPOINT" = "CHECKPOINT", //indentation= 0 - Main checkpoint; indentation= 1 - Sub-checkpoint
  "OPTION_FIELD" = "OPTION_FIELD", // indentation= 0 = Single answer; indentation= 1 = Multiple answers;
  "WYSIWYG" = "WYSIWYG",
  "CHECKPOINT_LIST" = "CHECKPOINT_LIST",
  "ATTACHMENTS" = "ATTACHMENTS",
  "PLUSMINUS" = "PLUSMINUS"
}

export enum ChecklistRowFieldType {
  LARGE_TEXTFIELD = "LARGE_TEXTFIELD",
  SMALL_TEXTFIELD = "SMALL_TEXTFIELD",
  DATEPICKER = "DATEPICKER",
  TIMEPICKER = "TIMEPICKER",
  USER = "USER",
  SINGLE = "SINGLE",
  MULTIPLE = "MULTIPLE"
}
