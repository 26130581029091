import React, { FC } from "react";
import ProjectSecurity from "@/views/home/project/detail/projectSettings/ProjectSecurity";
import BhPigeon20Separator from "@components/separator/BhPigeon20Separator";
import ProjectDetailsFormContainer from "@/views/home/project/detail/projectSettings/ProjectDetailsFormContainer";
import ProjectStatusContainer from "@/views/home/project/detail/projectSettings/ProjectStatusContainer";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { useTranslation } from "react-i18next";
import ProjectBillingInfoContainer from "@/views/home/project/detail/projectSettings/ProjectBillingInfoContainer";
import ProjectSettingsGetProPlanBanner from "@/views/home/project/detail/projectSettings/ProjectSettingsGetProPlanBanner";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProject } from "@/app/store/project/projectSlice";
import { selectCompanyById } from "@/app/store/companiesSlice";

const ProjectInfoContainer: FC = () => {
  const { t } = useTranslation();

  return (
    <BhScrollableBody>
      <ProjectSettingsGetProPlanBanner />
      <div className="mr-32 justify-between xl:mr-0">
        <div className="flex lg:flex-col">
          <div className="basis-1/2 pt-4">
            <h3>{t("GLOBAL.PROJECT_INFO")}</h3>
            <p className="w-2/3">{t("PROJECT.INFO.SUB")}</p>
          </div>
          <div className="basis-1/2">
            <ProjectDetailsFormContainer />
            <ProjectBillingInfoContainer />
          </div>
        </div>
        <div className="pt-6">
          <BhPigeon20Separator />
          <ProjectSecurity />
        </div>
        <div className="pt-6">
          <BhPigeon20Separator />
          <ProjectStatusContainer />
        </div>
      </div>
    </BhScrollableBody>
  );
};
export default ProjectInfoContainer;
