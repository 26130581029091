import React, { FC, useEffect, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhProgressContainer from "@components/progress/BhProgressContainer";
import { useNavigate } from "react-router-dom";
import { IWorkGroup } from "@/model/IWorkGroup";
import { FileEntityBranch, IFileEntity } from "@/model/files/IFileEntity";
import {
  projectWorkGroupStateReset,
  saveNewPartyAsync,
  selectCurrentWorkGroupUsersInvitesSorted,
  selectProjectWorkGroupById,
  selectSelectedFileIds,
  workGroupAdded
} from "@/app/store/project/projectWorkGroupsSlice";
import { ITaskBoard } from "@/model/taskBoard/ITaskBoard";
import { BhProgressBulletStatus } from "@/model/utilities/BhProgressBulletStatus";
import PartyOverviewContainer from "@/views/home/project/detail/users/parties/party/PartyOverviewContainer";
import PartyPrivilegesContainer from "@/views/home/project/detail/users/parties/party/PartyPrivilegesContainer";
import PartyFilesContainer from "@/views/home/project/detail/users/parties/party/PartyFilesContainer";
import PartyTaskboardsContainer from "@/views/home/project/detail/users/parties/party/PartyTaskboardsContainer";
import { IPartyDTO } from "@/model/party/IPartyDTO";
import PartyFilesTextContainer from "@/views/home/project/detail/users/parties/party/PartyFilesTextContainer";
import PartyDocumentsTextContainer from "@/views/home/project/detail/users/parties/party/PartyDocumentsTextContainer";
import { useTranslation } from "react-i18next";
import { IProgressContainerStepState } from "@/model/tabs/IProgressContainerStepState";
import { selectMaruProjectModule } from "@/app/store/project/projectSlice";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  projectId: EntityId;
}

const CreatePartyContainer: FC<Props> = ({ projectId }) => {
  const { t } = useTranslation();
  const workGroup = useAppSelector((state) => selectProjectWorkGroupById(state, -1));
  const usersInvites = useAppSelector(selectCurrentWorkGroupUsersInvitesSorted);
  const selectedFileIds = useAppSelector(selectSelectedFileIds);
  const [steps, setSteps] = useState<Array<IProgressContainerStepState>>([] as Array<IProgressContainerStepState>);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMaruProject = useAppSelector(selectMaruProjectModule);

  useDocumentTitle("WORKGROUP.NEW_WORKGROUP");

  const stepsInitialState = [
    {
      id: 0,
      header: t("WORKGROUP.NAME"),
      status: BhProgressBulletStatus.CURRENT,
      href: `details/`,
      validation: [isMaruProject && "type", "name"].filter(Boolean),
      isDirty: true
    },
    {
      id: 1,
      header: t("WORKGROUP.NEW.USERS"),
      status: BhProgressBulletStatus.UPCOMING,
      href: `users/`,
      isDirty: false
    },
    {
      id: 2,
      header: t("WORKGROUP.NEW.ADD_FILES"),
      status: BhProgressBulletStatus.UPCOMING,
      href: `drawings/`,
      isDirty: false
    },
    {
      id: 3,
      header: t("WORKGROUP.NEW.ADD_DOCUMENTS"),
      status: BhProgressBulletStatus.UPCOMING,
      href: `documents/`,
      isDirty: false
    },
    {
      id: 4,
      header: t("WORKGROUP.NEW.TASKBOARDS"),
      status: BhProgressBulletStatus.UPCOMING,
      href: `taskboards/`,
      isDirty: false
    }
  ] as Array<IProgressContainerStepState>;

  useEffect(() => {
    setSteps(stepsInitialState);
    dispatch(projectWorkGroupStateReset());
    dispatch(workGroupAdded({ projectId: projectId, id: -1, taskBoards: [] as Array<ITaskBoard>, files: [] as Array<IFileEntity>, documents: [] as Array<IFileEntity> } as IWorkGroup));
    return function cleanup() {
      dispatch(projectWorkGroupStateReset());
    };
  }, []);

  if (!workGroup) {
    return null;
  }

  const content = [
    <PartyOverviewContainer workGroup={workGroup} />,
    <div className="w-full py-12 px-24">
      <PartyPrivilegesContainer workGroup={workGroup} />
    </div>,
    <PartyFilesTextContainer children={<PartyFilesContainer workGroup={workGroup} branch={FileEntityBranch.ROOT_DIR} newWorkGroup={true} />} />,
    <PartyDocumentsTextContainer children={<PartyFilesContainer workGroup={workGroup} branch={FileEntityBranch.ROOT_DOCUMENT_DIR} newWorkGroup={true} />} />,
    <PartyTaskboardsContainer workGroup={workGroup} />
  ];

  const onSubmit = (buttonRef: React.RefObject<HTMLButtonElement>) => {
    if (buttonRef.current && !buttonRef.current.disabled) {
      buttonRef.current.disabled = true;
      dispatch(
        saveNewPartyAsync({
          projectId: workGroup.projectId,
          type: workGroup.type,
          name: workGroup.name,
          users: usersInvites,
          taskBoards: workGroup.taskBoards,
          fileIds: selectedFileIds.map((fe: { id: EntityId; branch: FileEntityBranch }) => fe.id),
          uniqueClientId: workGroup.uniqueClientId
        } as IPartyDTO)
      ).then(
        () => {
          navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/users/parties`);
          if (buttonRef.current) buttonRef.current.disabled = false;
        },
        () => {
          // do nothing
        }
      );
    }
  };

  const onCancel = () => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/users/parties`);
  };

  if (!steps) {
    return null;
  }
  return (
    <div className="bh-bg-white fixed top-0 left-0 z-40 h-full w-full">
      <div className="flex h-full w-full">
        <BhProgressContainer
          steps={steps}
          setSteps={setSteps}
          header={t("WORKGROUP.NEW_WORKGROUP") as string}
          form={workGroup}
          onSubmit={onSubmit}
          onCancel={onCancel}
          content={content}
          finalizeButtonTitle={t("WORKGROUP.NEW.CONFIRM") as string}
        />
      </div>
    </div>
  );
};

export default React.memo(CreatePartyContainer);
