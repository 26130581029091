import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { directoryInfoBarTabSet, selectFilesSelectedInCurrentDirectory, selectInfoBarTab, selectOneFileSelected } from "@/app/store/filesSlice";
import FileInfoDetailView from "@/views/home/project/detail/directory/fileInfoBar/fileInfoBarDetailViews/FileInfoDetailView";
import FileCommentsDetailView from "@/views/home/project/detail/directory/fileInfoBar/fileInfoBarDetailViews/FileCommentsDetailView";
import { IAuthorityBoolean } from "@/model/IUser";

export enum DirectoryFileInfoBarTab {
  COLLAPSED = 0,
  INFO = 1,
  COMMENTS = 2,
  HISTORY = 3,
  AUTHORITIES = 4
}

interface Props {
  privilegesForDirectory: IAuthorityBoolean;
}

const DirectoryFileInfoBar: FC<Props> = ({ privilegesForDirectory }) => {
  const tab = useAppSelector(selectInfoBarTab);
  const collapsed = tab === DirectoryFileInfoBarTab.COLLAPSED || tab === DirectoryFileInfoBarTab.HISTORY || tab === DirectoryFileInfoBarTab.AUTHORITIES;
  const selectedFileEntitiesInDirectory = useAppSelector(selectFilesSelectedInCurrentDirectory);
  const oneFileSelected = useAppSelector(selectOneFileSelected);
  const dispatch = useAppDispatch();

  const selectedFile = selectedFileEntitiesInDirectory.length === 1 && oneFileSelected && selectedFileEntitiesInDirectory[0];

  if (!privilegesForDirectory.isRead) {
    return null;
  }

  return (
    <div className="flex flex-none flex-row">
      {!collapsed && selectedFile && (
        <div className="bh-border-pigeon-20 flex h-full w-72 flex-col border-l">
          <div className="flex flex-grow flex-col overflow-auto">
            {oneFileSelected && selectedFile && (
              <>
                {tab === DirectoryFileInfoBarTab.INFO && <FileInfoDetailView fileEntityId={selectedFileEntitiesInDirectory[0].id} privilegesForDirectory={privilegesForDirectory} />}
                {tab === DirectoryFileInfoBarTab.COMMENTS && (
                  <FileCommentsDetailView fileEntityId={selectedFileEntitiesInDirectory[0].id} closeButtonAction={() => dispatch(directoryInfoBarTabSet(DirectoryFileInfoBarTab.COLLAPSED))} />
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DirectoryFileInfoBar;
