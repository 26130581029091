import React, { Dispatch, useEffect } from "react";
import { Viewer } from "@xeokit/xeokit-sdk";
import BhIconButton from "@components/buttons/BhIconButton";
import { faPersonSimple } from "@fortawesome/pro-solid-svg-icons/faPersonSimple";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import XeokitMobileMoreToolsModalElement from "@/views/home/project/detail/xeokit/mobile/modals/XeokitMobileMoreToolsModalElement";
import { useTranslation } from "react-i18next";
import BhTooltip from "@components/BhTooltip";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUserLanguageForTranslation } from "@/app/store/userSlice";

interface Props {
  toolActive: boolean;
  setToolActive: Dispatch<boolean>;
  threeDActive: boolean;
  isMobile?: boolean;
  viewer?: Viewer;
}

const XeokitFirstPersonTool = React.memo<Props>(({ toolActive, setToolActive, threeDActive, isMobile, viewer }) => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguageForTranslation);

  useEffect(() => {
    if (!viewer) return;

    viewer.on("reset", () => {
      viewer.cameraControl.navMode = isMobile ? "firstPerson" : "orbit";
      viewer.cameraControl.followPointer = isMobile ? true : false;
      viewer.cameraControl.mouseWheelDollyRate = 100;
      setToolActive(isMobile ? true : false);
    });
  }, [viewer]);

  const toggleFirstPersonModeClicked = () => {
    if (!viewer) return;

    toggleFirstPersonMode(viewer, toolActive, setToolActive);
  };

  if (isMobile) {
    return (
      <XeokitMobileMoreToolsModalElement icon={<FontAwesomeIcon icon={faPersonSimple} />} callback={toggleFirstPersonModeClicked} disabled={!threeDActive} isActive={toolActive}>
        {t("BIM.TOOL.FIRST_PERSON", { lng: currentUserLanguage })}
      </XeokitMobileMoreToolsModalElement>
    );
  }

  return (
    <BhTooltip body={t("BIM.TOOL.FIRST_PERSON")}>
      <BhIconButton icon={faPersonSimple} buttonProps={{ onClick: toggleFirstPersonModeClicked, disabled: !threeDActive }} isActive={toolActive} />
    </BhTooltip>
  );
});

export default XeokitFirstPersonTool;

export const toggleFirstPersonMode = (viewer: Viewer, toolActive: boolean, setToolActive: Function) => {
  const newFirstPersonActive = !toolActive;
  viewer.cameraControl.navMode = newFirstPersonActive ? "firstPerson" : "orbit";
  viewer.cameraControl.mouseWheelDollyRate = newFirstPersonActive ? 7 : 100;

  viewer.cameraControl.followPointer = !newFirstPersonActive;

  setToolActive(newFirstPersonActive);
};
