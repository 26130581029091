import React, { FC, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import { FileEntityType, IDistance, IFileEntity, IFileMatch, IRevisionMatcherFileEntity } from "@/model/files/IFileEntity";
import RevisionModalFileCardContainer from "@/views/home/project/detail/directory/directoryModals/revisionModal/RevisionModalFileCardContainer";
import BhModalFooter from "@components/modal/BhModalFooter";
import { EntityId } from "@reduxjs/toolkit";
import { fetchDirectoryFiles } from "@/api/fileAPI";
import { dalevDistance, stringComparisonDistance } from "@/utilities/jsUtilities";
import RevisionModalUnusedFileCard from "@/views/home/project/detail/directory/directoryModals/revisionModal/RevisionModalUnusedFileCard";
import { useTranslation } from "react-i18next";
import { naturalSortFilesByField } from "@/utilities/sortUtilities";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import RevisionsHeaderWithLegend from "@/views/home/project/detail/directory/directoryModals/revisionModal/RevisionsHeaderWithLegend";

interface Props {
  onSubmit: Function;
  onClose: Function;
  files: Array<IRevisionMatcherFileEntity>;
  directoryId: EntityId;
}

const RevisionModal: FC<Props> = ({ files, directoryId, onSubmit, onClose }) => {
  const { t } = useTranslation();
  const [filesInDirectory, setFilesInDirectory] = useState([] as Array<IFileEntity>);
  const [unusedFiles, setUnusedFiles] = useState([] as Array<IFileEntity>);
  const [unusedDirectoryFiles, setUnusedDirectoryFiles] = useState([] as Array<IFileEntity>);
  const [fileMatches, setFileMatches] = useState([] as Array<IFileMatch>);

  // First fetch and filter all files (file.type !== DIR) in destination directory
  useEffect(() => {
    fetchDirectoryFiles([directoryId]).then((directoryFiles) => {
      const filesOnly = directoryFiles.filter((file) => ![FileEntityType.DIR, FileEntityType.FORM].includes(file.type));
      setFilesInDirectory(filesOnly);
    });
  }, [directoryId]);

  // If all the directory files have been fetched, calculate matches
  useEffect(() => {
    findMatchesForFiles();
  }, [filesInDirectory]);

  const findMatchesForFiles = () => {
    let matches = [] as Array<IFileMatch>;
    let filesNotUsed = [] as Array<IFileEntity>;
    if (filesInDirectory.length > 0) {
      // Match files with same name
      files.forEach((file) => {
        const sameNameFileInDirectory = filesInDirectory.find((f) => f.name.toLowerCase() === file.name.toLowerCase());
        if (sameNameFileInDirectory) {
          const fileMatch = {
            file: file,
            matchingFile: sameNameFileInDirectory,
            bestDistance: { distance: 0 } as IDistance
          } as IFileMatch;
          matches.push(fileMatch);
        } else {
          filesNotUsed.push(file);
        }
      });
      // Find directory files that are not matched already
      let directoryFilesWithoutMatch = filesInDirectory.filter((file) => {
        return !matches.some((match) => {
          return match.matchingFile.id === file.id;
        });
      });
      // All files that are were not matched with same name before, will be now checked for best match
      filesNotUsed.forEach((file) => {
        const fileMatch = findBestMatchInDirectory(file, directoryFilesWithoutMatch);
        if (fileMatch) {
          matches.push(fileMatch);
          directoryFilesWithoutMatch = directoryFilesWithoutMatch.filter((f) => f.id !== fileMatch.matchingFile.id);
        }
      });
      // Set directory files that still don't have a match
      setUnusedDirectoryFiles(directoryFilesWithoutMatch);
    }
    // Get and set all leftover files (without matches)
    const availableFiles = filesNotUsed.filter((f) => {
      return !matches.some((m) => {
        return m.file.uuid === f.uuid;
      });
    });
    setUnusedFiles(availableFiles);

    // Set all the matches
    setFileMatchesOrderedByName(matches);
  };

  const setFileMatchesOrderedByName = (matches: Array<IFileMatch>) => {
    const sortedMatches = matches.sort((a, b) => naturalSortFilesByField(a.file, b.file, "name"));
    setFileMatches([...sortedMatches]);
  };

  const findBestMatchInDirectory = (file: IFileEntity, directoryFilesWithoutMatch: Array<IFileEntity>) => {
    let distances = [] as Array<IDistance>;
    directoryFilesWithoutMatch.forEach((dirFile) => {
      // Calculate distances file vs. each directory file
      const distanceBetweenFileAndDirFile = dalevDistance(file.name, dirFile.name);
      if (distanceBetweenFileAndDirFile <= 9) {
        const stringCompDistance = stringComparisonDistance(file.name, dirFile.name);
        const distance = { distance: distanceBetweenFileAndDirFile, stringComparisonDistance: stringCompDistance, dirFileId: dirFile.id, name: dirFile.name } as IDistance;
        distances.push(distance);
      }
    });
    // If any similar files were found (distance <= 9) get the best match
    if (distances.length > 0) {
      // Smaller distance = better match
      const sortedDistances = distances.sort((a, b) => (a.distance > b.distance ? 1 : -1));
      const distancesWithHighestScore = distances.filter((distance) => distance.distance === sortedDistances[0].distance);
      let matchingFileEntity = {} as IFileEntity;
      // Find best match fileEntity from directoryFilesWithoutMatch
      if (distancesWithHighestScore.length > 1) {
        // If some distances were equal, find best match based on stringComparisonDistance (count of similar letters of both words from the start)
        const sortedByStringComparison = distancesWithHighestScore.sort((a, b) => (a.stringComparisonDistance < b.stringComparisonDistance ? 1 : -1));
        const matchingFile = directoryFilesWithoutMatch.find((dirFile) => dirFile.id === sortedByStringComparison[0].dirFileId);
        if (matchingFile) {
          matchingFileEntity = matchingFile;
        }
      } else {
        const matchingFile = directoryFilesWithoutMatch.find((dirFile) => dirFile.id === distancesWithHighestScore[0].dirFileId);
        if (matchingFile) {
          matchingFileEntity = matchingFile;
        }
      }
      // If matching fileEntity is found, return FileMatch
      if (matchingFileEntity.id) {
        return {
          file: file,
          matchingFile: matchingFileEntity,
          bestDistance: distancesWithHighestScore[0]
        } as IFileMatch;
      }
    }
  };

  const unlinkFiles = (fileMatch: IFileMatch) => {
    const matchesWithoutFileMatch = fileMatches.filter((match) => match.file.uuid !== fileMatch.file.uuid);
    setUnusedFiles([...unusedFiles, fileMatch.file]);
    setUnusedDirectoryFiles([...unusedDirectoryFiles, fileMatch.matchingFile]);
    setFileMatchesOrderedByName(matchesWithoutFileMatch);
  };

  const newFileMatchCreated = (fileMatch: IFileMatch) => {
    const filesNotUsed = unusedFiles.filter((file) => file.uuid !== fileMatch.file.uuid);
    const directoryFilesNotUsed = unusedDirectoryFiles.filter((file) => file.id !== fileMatch.matchingFile.id);
    setUnusedFiles(filesNotUsed);
    setUnusedDirectoryFiles(directoryFilesNotUsed);
    setFileMatchesOrderedByName([...fileMatches, fileMatch]);
  };

  const existingFileMatchChanged = (fileMatch: IFileMatch) => {
    const existingFileMatch = fileMatches.find((match) => match.file.uuid === fileMatch.file.uuid);
    if (existingFileMatch) {
      const unusedDirectoryFilesFiltered = unusedDirectoryFiles.filter((file) => file.id !== fileMatch.matchingFile.id);
      const directoryFilesNotUsed = [...unusedDirectoryFilesFiltered, existingFileMatch.matchingFile];
      const existingFileMatchesFiltered = fileMatches.filter((match) => match.file.uuid !== fileMatch.file.uuid);
      const matchesWithChangedFileMatch = [...existingFileMatchesFiltered, fileMatch];
      setUnusedDirectoryFiles(directoryFilesNotUsed);
      setFileMatchesOrderedByName(matchesWithChangedFileMatch);
    }
  };

  const handleSubmit = () => {
    onSubmit(fileMatches, unusedFiles);
  };
  const closeModal = () => {
    onClose();
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={closeModal}
      size="4xl"
      header={<h2>{t("MODAL.REVISION.TITLE")}</h2>}
      footer={<BhModalFooter onCancel={closeModal} onConfirm={handleSubmit} cancelButtonText={`${t("GLOBAL.CANCEL")}`} />}
    >
      <div className="bh-bg-smoke h-full overflow-hidden">
        <BhScrollableBody>
          <div className="flex flex-col pr-9 pl-8">
            {fileMatches && fileMatches.length > 0 && (
              <div className="pt-5 pl-12">
                <RevisionsHeaderWithLegend />
                <div>
                  {fileMatches.map((fileMatch) => {
                    return (
                      <RevisionModalFileCardContainer
                        fileMatch={fileMatch}
                        unlinkFiles={unlinkFiles}
                        fileMatchSelected={existingFileMatchChanged}
                        unusedDirectoryFiles={unusedDirectoryFiles}
                        key={fileMatch.file.uuid}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {unusedFiles.length > 0 && (
              <div className="mb-10 pt-5 pl-12">
                <div className="mb-2 text-lg">{t("MODAL.UPLOAD_FILE.NEW_FILES")}</div>
                {unusedFiles.map((file) => {
                  return <RevisionModalUnusedFileCard fileEntity={file} unusedDirectoryFiles={unusedDirectoryFiles} fileMatchSelected={newFileMatchCreated} key={file.uuid} />;
                })}
              </div>
            )}
          </div>
        </BhScrollableBody>
      </div>
    </BhModal>
  );
};

export default RevisionModal;
