import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import FormBaseFieldCheckboxWithText from "@components/form/base/body/FormBaseFieldCheckboxWithText";
import FormFieldContainerInput from "@components/form/base/body/fields/FormFieldContainerInput";
import BhFormUploadContainer from "@components/upload/BhFormUploadContainer";

interface Props {
  fieldValue: any;
  saveFieldCallback: Function;
  disabled?: boolean;
}

const FormBaseAttachmentField: FC<Props> = ({ fieldValue, saveFieldCallback, disabled }) => {
  const { t } = useTranslation();

  const toggleCheckbox = (value: any) => {
    saveFieldCallback("import", value.import);
  };

  return (
    <div className="flex h-full flex-col gap-y-1.5">
      <FormFieldContainerInput initialValue={fieldValue.label} property="label" disabled={disabled} onBlurCallback={saveFieldCallback} placeholder={t("GLOBAL.ITEM_NAME") as string} />
      <BhFormUploadContainer
        disabled={true}
        addFilesCallback={() => {}}
        uploadFilesCallback={() => {}}
        importModalCallback={fieldValue?.import ? () => {} : undefined}
        onDropCallback={() => {}}
        projectId={1}
      />
      <FormBaseFieldCheckboxWithText text={t("GLOBAL.IMPORT")} property="import" onChangeCallback={toggleCheckbox} disabled={disabled} isChecked={fieldValue?.import || false} />
    </div>
  );
};

export default FormBaseAttachmentField;
