import { ICompany, ICompanyComment, ICompanyModule, IEnabledForm, INewCompanyDTO } from "@/model/ICompany";
import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { EntityId } from "@reduxjs/toolkit";
import { ICompanyBilling } from "@/model/billing/ICompanyBilling";
import { IBOCompanyTrialDateDTO } from "@components/backoffice/company/companyAndBilling/BOCompanyAndBillingGeneral";
import { IDowngradePlanInfo } from "@/model/billing/IDowngradePlanInfo";
import { IUpgradePlanInfo } from "@/model/billing/IUpgradePlanInfo";
import { IProject, IProjectModule } from "@/model/IProject";
import { IObjectChange } from "@/model/IObjectChange";
import { IProjectBilling } from "@/model/billing/IProjectBilling";
import { IBOBillingSetupDoneDTO } from "@components/backoffice/company/companyAndBilling/BOCompanyKillswitch";
import { IBoFinbiteAuthCodeDTO } from "@components/backoffice/company/BOMaruFinbiteAuthCodeModal";
import { IChecklistBase } from "@/model/checklist/IChecklistBase";
import { IBoDeleteChecklistBaseDTO } from "@components/backoffice/company/modulesAndTemplates/BOCompanyChecklists";
import { IBoTransferChecklistsDTO } from "@components/backoffice/company/modulesAndTemplates/BOCompanyTransferChecklistsModal";
import { IBoAuthCode } from "@components/backoffice/company/BOMaruFinbiteAuthCode";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import { ICompanyInvitedUser } from "@/model/companyUsers/ICompanyInvitedUser";
import { IBoCompanyUser } from "@/model/companyUsers/IBoCompanyUser";
import { ICompanyAdministratorChangeRequest } from "@/model/ICompanyAdministratorChangeRequest";
import { ICompanyUserActionResponse } from "@/model/companyUsers/ICompanyUserActionResponse";

export function boFetchAllCompanies(): Promise<Array<ICompany>> {
  return bauhubGet("/company/list");
}

export function boFetchCompany(companyId: EntityId): Promise<ICompany> {
  return bauhubGet("/company/admin/" + companyId);
}

export function boFetchCompanyBilling(companyId: EntityId): Promise<ICompanyBilling> {
  return bauhubGet("/admin/company/" + companyId + "/billing");
}

export function boSaveCompanyTrialStart(dto: IBOCompanyTrialDateDTO): Promise<ICompany> {
  return bauhubPost("/admin/company/" + dto.companyId + "/trial/start", { value: dto.date, companyId: dto.companyId });
}

export function boSaveCompanyTrialEnd(dto: IBOCompanyTrialDateDTO): Promise<ICompany> {
  return bauhubPost("/admin/company/" + dto.companyId + "/trial/end", { value: dto.date, companyId: dto.companyId });
}

export function boSaveCompanyBilling(companyBilling: ICompanyBilling): Promise<ICompanyBilling> {
  return bauhubPost("/admin/company/billing", companyBilling);
}

export function boFetchDowngradeInfo(companyId: EntityId): Promise<IDowngradePlanInfo> {
  return bauhubGet("/company/" + companyId + "/billing/plan/downgrade/info");
}

export function boFetchUpgradeInfo(companyId: EntityId): Promise<IUpgradePlanInfo> {
  return bauhubGet("/company/" + companyId + "/billing/plan/upgrade/info");
}

export function boDowngradePlan(companyId: EntityId): Promise<ICompanyBilling> {
  return bauhubPost("/company/" + companyId + "/billing/plan/downgrade", {});
}

export function boUpgradePlan(companyId: EntityId): Promise<ICompanyBilling> {
  return bauhubPost("/company/" + companyId + "/billing/plan/upgrade", {});
}

export function boChangeCompanyBillingSetupDone(dto: IBOBillingSetupDoneDTO): Promise<string> {
  return bauhubPost("/admin/company/billing/setup/done", { id: dto.companyId, billingSetupDone: dto.billingSetupDone });
}

export function boSaveCompanyComment(dto: ICompanyComment): Promise<ICompanyComment> {
  return bauhubPost("/admin/company/" + dto.companyId + "/comment/save", dto);
}

export function boSaveCompany(company: INewCompanyDTO): Promise<ICompany> {
  return bauhubPost("/company", company);
}

export function boGetCompanyFinbiteAuthCode(companyId: EntityId): Promise<IBoAuthCode> {
  return bauhubGet("/company/" + companyId + "/authCode");
}

export function boSaveCompanyFinbiteAuthCode(dto: IBoFinbiteAuthCodeDTO): Promise<IBoAuthCode> {
  return bauhubPost("/company/" + dto.companyId + "/authCode", { value: dto.authCode });
}

export function boSaveCompanyModule(module: ICompanyModule): Promise<ICompanyModule> {
  return bauhubPost("/module/company", module);
}

export function boRemoveCompanyModule(module: ICompanyModule): Promise<ICompanyModule> {
  return bauhubPost("/module/company/remove", module);
}

export function boSaveForm(form: IEnabledForm): Promise<IEnabledForm> {
  return bauhubPost("/plan/form/add", form);
}

export function boRemoveForm(form: IEnabledForm): Promise<IEnabledForm> {
  return bauhubPost("/plan/form/remove", form);
}

export function boFetchChecklistBasesForCompany(companyId: EntityId): Promise<Array<IChecklistBase>> {
  return bauhubGet("/company/" + companyId + "/checklist/base");
}

export function boDeleteChecklistBaseVersion(dto: IBoDeleteChecklistBaseDTO): Promise<IChecklistBase> {
  return bauhubPost("/company/" + dto.companyId + "/checklist/base/" + dto.checklistBaseId + "/delete");
}

export function boTransferChecklistBasesToCompany(dto: IBoTransferChecklistsDTO): Promise<void> {
  return bauhubPost("/admin/company/" + dto.companyId + "/transfer/checklist/bases/" + dto.baseIds.join(","));
}

export function boSaveCompanyModuleAdminSettings(module: ICompanyModule): Promise<ICompanyModule> {
  return bauhubPost("/module/company/admin/settings", module);
}

export function boSaveProjectModule(module: IProjectModule): Promise<IProjectModule> {
  return bauhubPost("/module/project", module);
}

export function boFetchProjectHistory(projectId: EntityId): Promise<Array<IObjectChange>> {
  return bauhubGet("/admin/project/" + projectId + "/history");
}

export function boSuspendProject(project: IProject): Promise<IProject> {
  return bauhubPost("/admin/project/suspend", project);
}

export function boCloseProject(project: IProject): Promise<IProject> {
  return bauhubPost("/admin/project/close", project);
}

export function boSaveProjectBilling(projectBilling: IProjectBilling): Promise<IProjectBilling> {
  return bauhubPost("/admin/project/billing", projectBilling);
}

export function boSaveNewProject(project: IProject): Promise<IProject> {
  return bauhubPost("/admin/project/superuser", project);
}

export function boFindCompanyUsers(companyId: EntityId): Promise<Array<IBoCompanyUser>> {
  return bauhubGet("/admin/company/" + companyId + "/users");
}

export function boGetInvitedUsersForCompany(companyId: EntityId): Promise<Array<ICompanyInvitedUser>> {
  return bauhubGet("/invite/company/" + companyId);
}

export function boChangeCompanyAdministrator(companyId: EntityId, adminUser: ICompanyUser, request: ICompanyAdministratorChangeRequest): Promise<ICompanyUser> {
  return bauhubPost("/company/" + companyId + "/administrator/" + adminUser.userEntityId + "/change", request);
}

export function boChangeCompanyAdminUserInvite(companyId: EntityId, request: ICompanyAdministratorChangeRequest): Promise<ICompanyInvitedUser> {
  return bauhubPost("/company/" + companyId + "/administrator/username/" + request.username + "/invites/change", request);
}

export function boAddCompanyAdministrator(companyId: EntityId, request: ICompanyAdministratorChangeRequest): Promise<ICompanyInvitedUser | ICompanyUser> {
  return bauhubPost("/admin/company/" + companyId + "/administrator", request);
}

export function boRemoveCompanyAdministratorPrivileges(companyId: EntityId, userId: EntityId): Promise<Array<ICompanyUser>> {
  return bauhubPost("/admin/company/" + companyId + "/administrator/" + userId + "/remove");
}

export function boRemoveCompanyAdminInvite(companyUser: ICompanyInvitedUser): Promise<ICompanyUserActionResponse> {
  return bauhubPost("/company/" + companyUser.companyId + "/administrator/username/" + companyUser.username + "/invites/delete");
}
