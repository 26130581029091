import React, { FC, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePlus } from "@fortawesome/pro-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fileTossAnimationCleared, selectTossFileAnimationTriggered } from "@/app/store/filesSlice";
import { Transition } from "@headlessui/react";

const DirectoryFileBasketTossFile: FC = () => {
  const tossFileActive = useAppSelector(selectTossFileAnimationTriggered);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const id = tossFileActive && setTimeout(() => dispatch(fileTossAnimationCleared()), 500);

    return function cleanup() {
      if (id) clearTimeout(id);
    };
  }, [tossFileActive]);

  return (
    <Transition
      className="fixed left-6"
      appear={true}
      show={tossFileActive}
      enter="transform transition duration-[500ms] ease-[cubic-bezier(0.32,0.29,0.38,0.77)]"
      enterFrom="opacity-0 -rotate-90 -translate-y-36"
      enterTo="opacity-100 rotate-0 -translate-y-2"
    >
      <FontAwesomeIcon icon={faFilePlus} className="bh-text-bauhub-green-120" size="2x" />
    </Transition>
  );
};

export default DirectoryFileBasketTossFile;
