import React, { FC, useState } from "react";
import { ITask, TaskStatus } from "@/model/taskBoard/ITask";
import TaskCategoryTag from "@components/task/TaskCategoryTag";
import TaskPriorityTag from "@components/task/TaskPriorityTag";
import { classNames, getUserFromFullName } from "@/utilities/jsUtilities";
import BhUserIcon from "@components/user/BhUserIcon";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { CategoryDefaultCode } from "@/model/taskBoard/IProjectTaskCategory";
import { faMessage } from "@fortawesome/pro-regular-svg-icons";
import TaskTags from "@components/task/container/TaskTags";
import BhIconWithBadge from "@components/badge/BhIconWithBadge";
import { selectCurrentProjectId, toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { savePersonalTaskAsync, saveTaskBoardTaskAsync, selectTaskById, setOpenedTaskInfo } from "@/app/store/tasksSlice";
import { EntityId } from "@reduxjs/toolkit";
import { selectOpenedTaskBoardUsers } from "@/app/store/taskBoardsSlice";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCircleUser } from "@fortawesome/pro-solid-svg-icons/faCircleUser";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { isTaskPersonal } from "@/utilities/taskBoardUtil";
import { selectCurrentUser } from "@/app/store/userSlice";
import BhIconButtonDatepicker from "@components/buttons/BhIconButtonDatepicker";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import ReactTimeAgo from "react-time-ago";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import { getUserFullName } from "@/model/IUser";

interface Props {
  taskId: EntityId;
}

const TaskContainer: FC<Props> = React.memo(({ taskId }) => {
  const { t } = useTranslation();
  const task = useAppSelector((state) => selectTaskById(state, taskId));
  const taskBoardUsers = useAppSelector(selectOpenedTaskBoardUsers);
  const currentUser = useAppSelector(selectCurrentUser);
  const projectId = useAppSelector(selectCurrentProjectId);
  const isPersonalTask = task && isTaskPersonal(task, currentUser);
  const [users, setUsers] = useState(taskBoardUsers);
  const dispatch = useAppDispatch();

  if (!task) return null;

  const isArchived = task.status === TaskStatus.ARCHIVED;
  const showTaskCategoryTag = (task.category?.code && task.category?.code !== CategoryDefaultCode.TASK) || !task.category?.code;

  const openTaskModal = () => {
    dispatch(setOpenedTaskInfo({ id: task.id }));
    dispatch(toggleProjectModalsOpen({ modal: "projectTaskModal" }));
  };

  const saveTask = (changedObject: ITask) => {
    const taskToSave = { ...task, ...changedObject };
    if (isPersonalTask) {
      dispatch(savePersonalTaskAsync({ task: taskToSave, projectId: projectId }));
    } else {
      dispatch(saveTaskBoardTaskAsync({ task: taskToSave, projectId: projectId }));
    }
  };

  const saveAssignee = (user: ISimpleValidUserAuthority) => {
    const assignee = { assignee: user.userEntityId } as ITask;
    saveTask(assignee);
  };

  const onAssigneeSearch = (searchObject: any) => {
    const foundUsers = taskBoardUsers.filter((user) => getUserFullName(user).toLowerCase().includes(searchObject.user));
    setUsers(foundUsers);
  };

  return (
    <div
      className={classNames("bh-bg-white bh-border-pigeon-40 hover:bh-border-pigeon-70 group flex w-full cursor-pointer flex-col rounded border p-4 transition", isArchived && "opacity-60")}
      onClick={() => openTaskModal()}
    >
      {showTaskCategoryTag && (
        <div className="pb-2">
          <TaskCategoryTag category={task.category} />
        </div>
      )}
      <div className="max-h-78px w-full overflow-hidden">
        {task.priority && (
          <div className="inline-block align-middle">
            <TaskPriorityTag priority={task.priority} />
          </div>
        )}
        {task.taskIdentifier && <span className="bh-text-deep-ocean-80 text-14 mr-1 font-normal">{task.taskIdentifier}</span>}
        <span className="bh-text-deep-ocean text-14 font-semibold leading-5">{task.name}</span>
      </div>
      {task.tags && task.tags.length > 0 && (
        <div className="pt-3">
          <TaskTags tags={task.tags} />
        </div>
      )}
      <div className={classNames("flex flex-row items-center justify-between ", !task.assignee ? "-mb-2.5" : "pt-2")}>
        <div className="flex flex-row items-center">
          {task.assignee && !isPersonalTask && (
            <div className="mr-2">
              <BhUserIcon user={getUserFromFullName(task.assigneeFullName)} size={24} />
            </div>
          )}
          {task.deadline && (
            <div className="inline-block">
              <span className={classNames("bh-text-pigeon", new Date(task.deadline) < new Date() && "bh-text-error-red")}>
                <ReactTimeAgo date={new Date(task.deadline)} />
              </span>
            </div>
          )}
          {!task.assignee && taskBoardUsers && !isPersonalTask && (
            <div className={classNames("opacity-0 transition-opacity duration-300 group-hover:opacity-100", !task.deadline && "-ml-1")} onClick={(e: any) => e.stopPropagation()}>
              <BhDropdown
                button={
                  <BhTooltip body={t("TASK.ADD_ASSIGNEE")}>
                    <BhIconButton icon={faCircleUser} />
                  </BhTooltip>
                }
                menu={
                  <BhDropdownMenu
                    values={users}
                    type={BhDropdownTypeEnum.AUTH}
                    onSelect={saveAssignee}
                    closeOnItemClick={true}
                    search={true}
                    searchInputSaveCallback={onAssigneeSearch}
                    searchInputProperty={"user"}
                  />
                }
              />
            </div>
          )}
          {!task.deadline && (
            <div className="-ml-1 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
              <BhTooltip body={t("TASK.ADD_DEADLINE")}>
                <BhIconButtonDatepicker property={"deadline"} onChangeCallback={saveTask} />
              </BhTooltip>
            </div>
          )}
        </div>
        {task.comments && task.comments.length > 0 && <BhIconWithBadge icon={faMessage} tagText={task.comments.length} />}
      </div>
    </div>
  );
});

export default TaskContainer;
