import React, { FC, useState } from "react";
import useHandleFormSubmit from "@/utilities/hooks/useHandleFormSubmit";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhIconButton from "@components/buttons/BhIconButton";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons/faPaperclip";
import BhAttachmentsUploadContainer from "@components/upload/BhAttachmentsUploadContainer";
import { IFileEntity } from "@/model/files/IFileEntity";
import AttachmentsContainerLarge from "@components/attachments/AttachmentsContainerLarge";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { Trans, useTranslation } from "react-i18next";
import { IUser } from "@/model/IUser";
import BhTextareaWithMentions from "@components/input/BhTextareaWithMentions";

interface Props {
  onSubmit: Function;
  attachmentsEnabled?: boolean;
  placeholder?: string;
  showRemoveButton?: boolean;
  usersWithAccess?: Array<IUser>;
}

export interface ICommentInput {
  text: string;
  attachments: Array<IFileEntity>;
}

const NewCommentInput: FC<Props> = ({ onSubmit, attachmentsEnabled, placeholder, showRemoveButton, usersWithAccess }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const [active, setActive] = useState(false);
  const [attachments, setAttachments] = useState<Array<IFileEntity>>([]);
  const [text, setText] = useState<string>("");
  const handleFormSubmit = useHandleFormSubmit<ICommentInput>();
  const [editorResetKey, setEditorResetKey] = useState(0);

  const resetInput = () => {
    setAttachments([]);
    setActive(false);
    setText("");
    setEditorResetKey((prevKey) => prevKey + 1);
  };

  const handleSubmit = () => {
    if (text.length < 1) return;
    const savableMessage = { text: text, attachments: attachments, projectId: projectId };
    onSubmit(savableMessage).then(() => {
      resetInput();
    });
  };

  const attachmentAddedHandler = (files: any) => {
    setAttachments([...attachments, ...files.uploaded]);
  };

  const attachmentRemoved = (id: any) => {
    setAttachments(attachments.filter((a) => a.id !== id));
  };

  const finalPlaceholder = placeholder || (t("COMMENT.ADD_COMMENT.PLACEHOLDER") as string);

  return (
    <div className="border-b p-4">
      <form id="newFileCommentForm" className="flex flex-col" onSubmit={handleFormSubmit(handleSubmit)}>
        <div onFocus={() => setActive(true)} className="relative">
          <BhTextareaWithMentions
            key={editorResetKey}
            placeholder={finalPlaceholder}
            property={"text"}
            onChangeCallback={(value: any) => setText(value.text)}
            userSuggestions={usersWithAccess}
            inputClasses={
              "l-h-20 p-2 bh-border-pigeon-40 border rounded hover:bh-border-pigeon-70 focus:bh-border-pigeon-70 w-full rounded py-2 disabled:bh-text-deep-ocean-40 disabled:bh-bg-smoke disabled:bh-border-smoke scrollbar-hide resize-none"
            }
          />

          {attachmentsEnabled && (
            <div className="absolute right-0 top-0">
              <BhAttachmentsUploadContainer
                saveCallback={attachmentAddedHandler}
                property="uploaded"
                button={<BhIconButton icon={faPaperclip} withBackground={false} />}
                fileUploadAllowed={true}
                chooseFromProjectAllowed={true}
                multiple={true}
              />
            </div>
          )}
        </div>
        <div className="mt-2">
          <AttachmentsContainerLarge attachments={attachments} addingDisabled={true} removeCallback={attachmentRemoved} />
        </div>
        {active && (
          <div className="-mr-1 flex flex-row items-center justify-end pt-1.5">
            <BhTextOnlyButton
              buttonProps={{
                onClick: () => resetInput()
              }}
            >
              <Trans>GLOBAL.CANCEL</Trans>
            </BhTextOnlyButton>
            <BhPrimaryButton buttonProps={{ submitForm: "newFileCommentForm" }}>
              <Trans>GLOBAL.ADD</Trans>
            </BhPrimaryButton>
          </div>
        )}
      </form>
    </div>
  );
};

export default NewCommentInput;
