import React, { FC, useEffect } from "react";
import { IFileEntity } from "@/model/files/IFileEntity";
import { fetchContainerSignaturesAsync, selectSignaturesForContainer } from "@/app/store/signaturesSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhUserIconWithNameForSignature from "@components/user/BhUserIconWithNameForSignature";
import BhSignatureStatusLabelIconOnly from "@components/container/BhSignatureStatusLabelIconOnly";
import { sortSignaturesAndInvites } from "@/utilities/sortUtilities";

interface Props {
  fileEntity: IFileEntity;
}

const BhSignatureContainerSignaturesDropdown: FC<Props> = ({ fileEntity }) => {
  const signatures = useAppSelector(selectSignaturesForContainer(fileEntity.uuid));
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchContainerSignaturesAsync(fileEntity));
  }, []);

  return (
    <div className="bh-shadow min-w-300 bh-bg-white inline-block space-y-2.5 rounded p-4 align-top">
      {signatures &&
        [...signatures]
          .sort((a, b) => sortSignaturesAndInvites(a, b, fileEntity.queueEnabled))
          .map((signature) => {
            return (
              <div className="flex flex-row items-center gap-x-2" key={signature.uniqueId}>
                <div className="flex w-8 flex-row items-center justify-center">
                  <BhSignatureStatusLabelIconOnly signature={signature} queueEnabled={fileEntity.queueEnabled} />
                </div>
                <div className="flex-1">
                  <BhUserIconWithNameForSignature signature={signature} size="sm" />
                </div>
              </div>
            );
          })}
    </div>
  );
};

export default BhSignatureContainerSignaturesDropdown;
