import React, { FC, useEffect, useRef } from "react";
import BhLargeInput from "@components/input/BhLargeInput";
import { saveFormNameAsync, selectCurrentFormFileEntityId, selectCurrentFormName, selectValueByPropertyFromCurrentFormData } from "@/app/store/form/formSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import FormContainerNewFillFormFromPrevious from "@/views/home/project/detail/form/FormContainerNewFillFormFromPrevious";
import { validateAndParseNumberValue } from "@/utilities/formUtilities";

interface Props {
  disabled: boolean;
  isPreview?: boolean;
  formNameExample?: string;
  projectId?: EntityId;
  saveNumberCallback?: Function;
  formId?: EntityId;
  dirId?: EntityId;
}

const FormHeaderRow: FC<Props> = ({ disabled, isPreview, formNameExample, projectId, saveNumberCallback, formId, dirId }) => {
  const dispatch = useAppDispatch();
  const currentFormName = useAppSelector(selectCurrentFormName);
  const formName = isPreview ? formNameExample : currentFormName;
  const formFileEntityId = useAppSelector(selectCurrentFormFileEntityId);
  const formNumber = useAppSelector((state) => (!isPreview ? selectValueByPropertyFromCurrentFormData(state, "number") : 1));
  const inputRef = useRef<HTMLInputElement>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (nameInputRef && nameInputRef.current) nameInputRef.current.value = currentFormName;
  }, [currentFormName]);

  const saveFormName = (nameObj: any) => {
    if (projectId) {
      dispatch(saveFormNameAsync({ projectId: projectId, formFileEntityId: formFileEntityId, formName: nameObj.name }));
    }
  };

  const handleSaveNumberCallback = (value: { number: any }) => {
    if (saveNumberCallback) {
      validateAndParseNumberValue(value, saveNumberCallback, formNumber, dispatch, inputRef);
    }
  };

  return (
    <div className="flex flex-row items-center gap-x-1">
      <div className="w-20">
        <BhLargeInput inputRef={inputRef} initialValue={formNumber} property={"number"} disabled={disabled} onBlurCallback={handleSaveNumberCallback} />
      </div>
      <div className="w-full">
        <BhLargeInput inputRef={nameInputRef} initialValue={formName} property={"name"} disabled={disabled} onBlurCallback={saveFormName} />
      </div>
      <div>
        <FormContainerNewFillFormFromPrevious formId={formId && formId} dirId={dirId} disabled={disabled} />
      </div>
    </div>
  );
};

export default FormHeaderRow;
