import React, { FC, useEffect } from "react";

interface Props {
  onClick: Function;
}

const CategoriesModalButton: FC<Props> = ({ onClick }) => {


  return (
    <div>
      <button onClick={() => onClick()} className="hover:bh-bg-pigeon-20 active:bh-bg-pigeon-40 my-0.5 h-10 w-10 rounded p-2.5" type={"button"}>
        <div className="hover:bh-bg-pigeon-20 active:bh-bg-pigeon-40 relative inline-block h-full w-full rounded">
          <div className="absolute top-0 left-0 h-2 w-2 rounded-full" style={{ backgroundColor: "#BB2612" }}></div>
          <div className="absolute top-0 right-0 h-2 w-2 rounded-full" style={{ backgroundColor: "#1A65A7" }}></div>
          <div className="absolute bottom-0 left-0 h-2 w-2 rounded-full" style={{ backgroundColor: "#C89000" }}></div>
          <div className="absolute bottom-0 right-0 h-2 w-2 rounded-full" style={{ backgroundColor: "#320C62" }}></div>
        </div>
      </button>
    </div>
  );
};

export default CategoriesModalButton;
