import React, { FC } from "react";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { IDropdownItem } from "@/model/IDropdownItem";
import BhColorPickerButton from "@components/colorPicker/BhColorPickerButton";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  currentColor: string;
  dropDownValues: Array<string>;
  onSelect: Function;
  diameter?: number;
  disabled?: boolean;
}

const BhColorPicker: FC<Props> = ({ currentColor, dropDownValues, onSelect, diameter, disabled }) => {
  const size = diameter ? "h-" + diameter + " w-" + diameter : "h-8 w-8";
  const values: Array<IDropdownItem> = dropDownValues.map((color) => {
    return {
      text: <div className={classNames(size, "rounded-full")} style={{ backgroundColor: color }} />,
      function: () => {
        onSelect({ color: color });
      }
    };
  });

  return (
    <BhDropdown
      button={<BhColorPickerButton currentColor={currentColor} diameter={diameter} disabled={disabled} />}
      menu={
        <div className="absolute top-8 mt-1">
          <BhDropdownMenu values={values} textProperty="text" type={BhDropdownTypeEnum.STRING} widthClass="w-fit" numberOfItemsDisplayed={6} closeOnItemClick={true} />
        </div>
      }
      position={BhDropdownPositionEnum.BOTTOM_RIGHT}
      fixedPosition={true}
      disabled={disabled}
    />
  );
};

export default React.memo(BhColorPicker);
