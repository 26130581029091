import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boGetCompanyFinbiteAuthCodeAsync, boSelectAuthCode, boSelectCompany } from "@/app/store/backofficeSlice";
import { BhSectionMessageSuccess } from "@components/sectionMessage/BhSectionMessages";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BOMaruFinbiteAuthCodeModal from "@components/backoffice/company/BOMaruFinbiteAuthCodeModal";

export interface IBoAuthCode {
  value: string;
}

const BOMaruFinbiteAuthCode = () => {
  const [authCodeModalOpen, setAuthCodeModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const company = useAppSelector(boSelectCompany);
  const authCode = useAppSelector(boSelectAuthCode);

  useEffect(() => {
    dispatch(boGetCompanyFinbiteAuthCodeAsync(company.id));
  }, []);

  return (
    <div className="py-4">
      <h2>Authentication code</h2>
      <div className="flex w-full items-center gap-60">
        <div className="flex w-full flex-col justify-around">
          <p>Maru's authentication code that enables sending acts to Finbite.</p>
        </div>
        <div className="w-full">
          {authCode && (
            <div className="flex items-center">
              <div className="w-2/3">
                <BhSectionMessageSuccess>
                  <p className="font-weight-700">Code activated</p>
                </BhSectionMessageSuccess>
              </div>
              <BhTextOnlyButton buttonProps={{ onClick: () => setAuthCodeModalOpen(true) }}>Show code</BhTextOnlyButton>
            </div>
          )}
          {!authCode && <BhPrimaryButton buttonProps={{ onClick: () => setAuthCodeModalOpen(true) }}>Add code</BhPrimaryButton>}
        </div>
      </div>
      {authCodeModalOpen && <BOMaruFinbiteAuthCodeModal setIsShown={setAuthCodeModalOpen} authCode={authCode as string} />}
    </div>
  );
};

export default BOMaruFinbiteAuthCode;
