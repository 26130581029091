import React, { FC, useCallback } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

//FIGMA: https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H/Bauhub-design-system?node-id=296%3A4329

interface Props {
  tagText: string;
  backgroundColorClass?: string;
  hoverBackgroundColorClass?: string;
  textColorClass?: string;
  icon?: IconProp;
  disabled?: boolean;
  onClick?: Function;
}

const BhNewTag: FC<Props> = ({ tagText, backgroundColorClass, textColorClass, icon, disabled, onClick, hoverBackgroundColorClass }) => {
  const handleOnClick = useCallback(
    (e: React.MouseEvent) => {
      if (disabled) {
        return;
      }
      onClick && onClick();
    },
    [onClick]
  );

  return (
    <div
      className={classNames(
        backgroundColorClass || "bh-bg-dull-grey-10",
        hoverBackgroundColorClass || "hover:bh-bg-pigeon-40",
        textColorClass || "bh-text-dull-grey",
        !disabled && "cursor-pointer select-none",
        "flex h-5 w-fit flex-row items-center gap-x-1 rounded py-0.5 px-1.5 font-bold"
      )}
      onClick={handleOnClick}
    >
      <div className="text-12px leading-3">{tagText}</div>
      {icon && (
        <div className="bh-text-deep-ocean-80">
          <FontAwesomeIcon icon={icon} size={"sm"} />
        </div>
      )}
    </div>
  );
};

export default BhNewTag;
