import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import BhTabs from "@components/tabs/BhTabs";
import { ITabArrayElement } from "@/model/tabs/ITabArrayElement";
import ConfirmationsPersonalList from "@/views/home/project/detail/confirmations/list/ConfirmationsPersonalList";
import ConfirmationsProjectList from "@/views/home/project/detail/confirmations/list/ConfirmationsProjectList";
import RequireProjectAdminAuth from "@/views/authentication/RequireProjectAdminAuth";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { Trans } from "react-i18next";

interface Props {}

const ConfirmationsList: FC<Props> = () => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSidebarCollapsed(false));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.CONFIRMATIONS));
  }, []);

  // @ts-ignore
  const tabs: Array<ITabArrayElement> = [
    {
      id: 0,
      header: (
        <div className="space-x-1.5">
          <Trans>CONFIRMATIONS.USER_RELATED</Trans>
        </div>
      ),
      content: <ConfirmationsPersonalList />,
      href: "personal"
    },
    isProjectAdmin && {
      id: 1,
      header: (
        <div className="space-x-1.5">
          <Trans>CONFIRMATIONS.PROJECT_CONFIRMATIONS</Trans>
        </div>
      ),
      content: (
        <RequireProjectAdminAuth>
          <ConfirmationsProjectList />
        </RequireProjectAdminAuth>
      ),
      href: "project"
    }
  ].filter(Boolean);

  return <BhTabs tabs={tabs} router={true} />;
};

export default ConfirmationsList;
