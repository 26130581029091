import React, { FC, ReactElement } from "react";
import { useAppSelector } from "@/app/hooks";
import { isCurrentUserSuperUser } from "@/app/store/userSlice";

interface Props {
  children: ReactElement;
}

const RequireSuperUserAuth: FC<Props> = ({ children }) => {
  const isSuperUser = useAppSelector(isCurrentUserSuperUser);

  if (!isSuperUser) {
    return null;
  }

  return children;
};

export default RequireSuperUserAuth;
