import React, { FC, useEffect, useState } from "react";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhFixedDropdownParent from "@components/dropdown/BhFixedDropdownParent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import BhInlineInputXL from "@components/input/BhInlineInputXL";

interface Props {
  initialValue: string;
  property: string;
  values: Array<any>;
  dropdownValueProperty: string;
  onSelect: Function;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const BhInlineInputXLWithDropdown: FC<Props> = ({ initialValue, placeholder, values, onSelect, property, label, disabled, dropdownValueProperty, inputRef }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownValues, setDropdownValues] = useState(values || []);

  useEffect(() => {
    setDropdownValues(values);
  }, [values]);

  const filterValues = (filter: any) => {
    const filteredValues = !filter[property]
      ? values
      : values.filter((val: any) => {
          return val[dropdownValueProperty].toLowerCase().includes(filter[property].toLowerCase());
        });
    setDropdownValues(filteredValues);
    setIsDropdownOpen(filteredValues.length > 0);
  };

  const onSelectAction = (selectedObject: any) => {
    setIsDropdownOpen(false);
    setDropdownValues(values || []);
    if (inputRef?.current) inputRef.current.value = selectedObject.text;

    const changedObject = { name: selectedObject.text };
    onSelect(changedObject);
  };

  return (
    <div className="relative w-full" onBlur={() => setIsDropdownOpen(false)}>
      <div className="relative">
        <div className="w-full">
          <BhInlineInputXL
            onChangeCallback={filterValues}
            initialValue={initialValue}
            placeholder={placeholder}
            property={property}
            inputRef={inputRef}
            disabled={disabled}
            onBlurCallback={onSelect}
            saveOnEnter={true}
            onFocus={() => setIsDropdownOpen(true)}
          />
        </div>
        <div className="absolute bottom-3 right-3">
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
      </div>
      {isDropdownOpen && dropdownValues.length > 0 && (
        <div className="absolute z-50 min-w-full max-w-[500px]" onMouseDown={(e) => e.preventDefault()}>
          <BhFixedDropdownParent>
            <BhDropdownMenu type={BhDropdownTypeEnum.STRING} textProperty={dropdownValueProperty} values={dropdownValues} onSelect={onSelectAction} customDropdown={true} widthClass="w-full" />
          </BhFixedDropdownParent>
        </div>
      )}
    </div>
  );
};
export default React.memo(BhInlineInputXLWithDropdown);
