import React, { CSSProperties, FC } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  noPadding?: boolean;
  children?: React.ReactNode;
  classes?: string;
  style?: CSSProperties;
  colSpan?: number;
  rowSpan?: number;
}

const BhCompactTableTD: FC<Props> = ({ noPadding, children, classes, style, colSpan, rowSpan }) => {
  return (
    <td className={classNames(classes, !noPadding && "px-2", "text-14px l-h-14px bh-text-black bh-border-pigeon-50 border")} colSpan={colSpan} rowSpan={rowSpan} style={style}>
      {children}
    </td>
  );
};

export default BhCompactTableTD;
