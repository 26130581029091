import { IFileEntity } from "@/model/files/IFileEntity";
import { IChecklistRoundRow } from "@/model/checklist/IChecklistRoundRow";
import { EntityId } from "@reduxjs/toolkit";
import { IUser } from "@/model/IUser";

export interface IChecklistRound {
  id: EntityId;
  checkTime: Date;
  roundNumber: number;
  checklistId: EntityId;
  status: string;
  deleted: boolean;
  baseFileEntityId?: EntityId;
  containerId: EntityId;
  checkpointValue: any;
  rows?: Array<IChecklistRoundRow>;
  digiDocContainer: IFileEntity;
  selected: boolean;
  createdByUserEntity: IUser;
  created: Date;
  createdBy: string;
  updated: Date;
  updatedBy: string;
}

export enum ChecklistRoundStatus {
  COMPLETE = "COMPLETE",
  IN_PROGRESS = "IN_PROGRESS"
}
