import React, { FC } from "react";
import { IFormBaseVersionRowField } from "@/model/form/IFormBaseVersionRowField";
import BhInputLabel from "@components/input/BhInputLabel";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyFormBaseByVersionId } from "@/app/store/form/formBasesSlice";
import { FormType } from "@/model/IForm";
import BhFormUploadContainer from "@components/upload/BhFormUploadContainer";

interface Props {
  field: IFormBaseVersionRowField;
}

const FormAttachmentsFieldPreview: FC<Props> = ({ field }) => {
  const formBase = useAppSelector((state) => selectCompanyFormBaseByVersionId(state, field.formBaseVersionId));
  const isBWD = formBase?.type === FormType.EHITUSPAEVIK_UUS || formBase?.type === FormType.BWD;

  return (
    <>
      {field.value?.label ? <BhInputLabel>{field.value.label}</BhInputLabel> : <div className="mt-4" />}
      <BhFormUploadContainer
        projectId={1}
        onDropCallback={() => {}}
        uploadFilesCallback={() => {}}
        addFilesCallback={() => {}}
        fetchFormsCallback={isBWD ? () => {} : undefined}
        importModalCallback={field.value?.import ? () => {} : undefined}
        disabled={true}
      />
    </>
  );
};

export default FormAttachmentsFieldPreview;
