import React, { FC } from "react";

const SignInvitesUnsignedListSkeleton: FC = () => {
  return (
    <>
      <div className="bh-border-pigeon my-3 w-full rounded-lg border-2">
        <div className="mx-6 my-5">
          <div className="bh-bg-pigeon-50 mb-2 h-7 w-1/3 animate-pulse rounded" />
          <div className="bh-bg-pigeon-50 h-5 w-1/2 animate-pulse rounded" />
        </div>
        <div className="bh-bg-smoke flex flex-row rounded-bl rounded-br p-4">
          <div className="flex flex-1 flex-row items-center py-2 px-4">
            <div className="bh-bg-pigeon-50 h-5 w-1/3 animate-pulse rounded" />
          </div>
          <div className="bh-bg-pigeon-50 h-9 w-32 animate-pulse rounded" />
        </div>
      </div>
      <div className="bh-border-pigeon my-3 w-full rounded-lg border-2 pt-3">
        <div className="mx-6 my-5">
          <div className="bh-bg-pigeon-50 mb-2 h-7 w-1/3 animate-pulse rounded" />
          <div className="bh-bg-pigeon-50 h-5 w-1/2 animate-pulse rounded" />
        </div>
        <div className="bh-bg-smoke flex flex-row rounded-bl rounded-br p-4">
          <div className="flex flex-1 flex-row items-center py-2 px-4">
            <div className="bh-bg-pigeon-50 h-5 w-1/3 animate-pulse rounded" />
          </div>
          <div className="bh-bg-pigeon-50 h-9 w-32 animate-pulse rounded" />
        </div>
      </div>
      <div className="bh-border-pigeon my-3 w-full rounded-lg border-2">
        <div className="mx-6 my-5">
          <div className="bh-bg-pigeon-50 mb-2 h-7 w-1/3 animate-pulse rounded" />
          <div className="bh-bg-pigeon-50 h-5 w-1/2 animate-pulse rounded" />
        </div>
        <div className="bh-bg-smoke flex flex-row rounded-bl rounded-br p-4">
          <div className="flex flex-1 flex-row items-center py-2 px-4">
            <div className="bh-bg-pigeon-50 h-5 w-1/3 animate-pulse rounded" />
          </div>
          <div className="bh-bg-pigeon-50 h-9 w-32 animate-pulse rounded" />
        </div>
      </div>
    </>
  );
};

export default SignInvitesUnsignedListSkeleton;
