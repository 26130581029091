import React from "react";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProject } from "@/app/store/project/projectSlice";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

const ProjectSettingsGetProPlanBannerSmall = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const project = useAppSelector(selectCurrentProject);
  const company = useAppSelector((state) => selectCompanyById(state, project.companyId));

  return (
    <div className="bh-bg-deep-ocean h-22 flex max-w-min items-center rounded px-8 sm:hidden">
      <div className="bh-text-white flex flex-row items-center md:flex-col">
        <span className="mr-4 whitespace-nowrap text-3xl font-extrabold">
          <span className="mr-1.5 hidden font-normal md:inline-block">Bauhub</span>
          {t("PLANS.PRO")}
        </span>
        <div className="text-16px whitespace-nowrap">
          <p className="md:hidden" dangerouslySetInnerHTML={{ __html: t("COMPANY.SETTINGS.GET_PRO_BANNER") as any }} />
          <BhSecondaryButton buttonProps={{ onClick: () => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${company?.id}/billing-plan`) }}>
            <span className="px-2">{t("SYNC.COMPARE_PLANS")}</span>
          </BhSecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default ProjectSettingsGetProPlanBannerSmall;
