import React, { FC, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhFilterContainer from "@components/filters/BhFilterContainer";
import SignInvitesSort from "@/views/home/project/detail/signInvites/SignInvitesSort";
import { ISignInvitesFilter } from "@/model/ISignInvitesFilter";
import { useTranslation } from "react-i18next";
import { selectSignInvitesFilter, signInvitesFilterCleared, signInvitesFilterSet } from "@/app/store/signInvitesSlice";
import SignInvitesAllInvitesFilterContainer from "@/views/home/project/detail/signInvites/signInvitesFilter/SignInvitesAllInvitesFilterContainer";
import SignInvitesSenderFilter from "@/views/home/project/detail/signInvites/signInvitesFilter/SignInvitesSenderFilter";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";

interface Props {
  useUnsignedSignInvitesSelector?: boolean;
  useSignedSignInvitesSelector?: boolean;
  useDeclinedSignInvitesSelector?: boolean;
  useAllProjectUnsignedInvitesSelector?: boolean;
}

const SignInvitesFilterAndSort: FC<Props> = ({ useUnsignedSignInvitesSelector, useSignedSignInvitesSelector, useDeclinedSignInvitesSelector, useAllProjectUnsignedInvitesSelector }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const filter = useAppSelector(selectSignInvitesFilter);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return function cleanup() {
      dispatch(signInvitesFilterCleared());
    };
  }, []);

  const onNameFilterChange = useCallback(
    (changedValue: ISignInvitesFilter) => {
      const changedObject = { ...filter, ...changedValue } as ISignInvitesFilter;
      dispatch(signInvitesFilterSet(changedObject));
    },
    [filter]
  );

  return (
    <div>
      <BhFilterContainer>
        <div className="py-0.5">
          <BhSearchInputWBG initialValue={filter.name} property="name" onChangeCallback={(e: ISignInvitesFilter) => onNameFilterChange(e)} placeholder={t("SHAREBOX.LIST.SEARCH_BY_NAME") as string} />
        </div>
        <SignInvitesSenderFilter
          useSignedSignInvitesSelector={useSignedSignInvitesSelector}
          useUnsignedSignInvitesSelector={useUnsignedSignInvitesSelector}
          useDeclinedSignInvitesSelector={useDeclinedSignInvitesSelector}
          useAllProjectUnsignedInvitesSelector={useAllProjectUnsignedInvitesSelector}
        />
        {isProjectAdmin && useAllProjectUnsignedInvitesSelector && <SignInvitesAllInvitesFilterContainer />}
      </BhFilterContainer>
      <SignInvitesSort />
    </div>
  );
};

export default SignInvitesFilterAndSort;
