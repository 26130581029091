import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import BhDivTableHeader from "@components/table/BhDivTableHeader";
import { IFileConfirmationFile } from "@/model/confirmations/IFileConfirmationFile";
import { fileConfirmationFilesSortSet, selectFileConfirmationFilesSort } from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import { Trans } from "react-i18next";

interface Props {
  showConfirmer: boolean;
}
const ConfirmationContainerTableHeader: FC<Props> = ({ showConfirmer }) => {
  const sort = useAppSelector(selectFileConfirmationFilesSort);
  const dispatch = useAppDispatch();

  const tableColumnHeaders: Array<IBhTableHeader<IFileConfirmationFile>> = [
    { id: 2, content: "", classes: "w-11 flex-none" },
    { id: 3, content: <Trans>GLOBAL.ITEM_NAME</Trans>, classes: "flex-grow", field: "name", sortable: true },
    { id: 4, content: <Trans>GLOBAL.VERSION</Trans>, classes: "w-18 flex-none justify-center" },
    showConfirmer && { id: 5, content: <Trans>FILE_CONFIRMATION.CONFIRMER</Trans>, classes: "w-60 flex-none justify-start", field: "nextToConfirmUserId", sortable: true },
    !showConfirmer && { id: 6, content: "", classes: "w-60 flex-none justify-start" },
    { id: 7, content: "", classes: "w-24 flex-none" }
  ].filter(Boolean) as Array<IBhTableHeader<IFileConfirmationFile>>;

  const onTableHeaderClickCallback = (column: IBhTableHeader<IFileConfirmationFile>) => {
    if (column.field) dispatch(fileConfirmationFilesSortSet({ ...sort, property: column.field, reversed: column.field === sort.property ? !sort.reversed : sort.reversed }));
  };

  return <BhDivTableHeader columns={tableColumnHeaders} onClickCallback={onTableHeaderClickCallback} sortedBy={sort.property} reversed={sort.reversed} />;
};

export default ConfirmationContainerTableHeader;
