import React, { FC, startTransition, useCallback, useEffect, useRef, useState } from "react";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { getUniqueValues } from "@/utilities/jsUtilities";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import BhInputLabel from "@components/input/BhInputLabel";
import { ITag } from "@/model/ITag";
import { useTranslation } from "react-i18next";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import BhFixedDropdownParent from "@components/dropdown/BhFixedDropdownParent";
import { useOuterClick } from "@/utilities/hooks/useOuterClick";

interface Props {
  label?: string;
  currentValues: Array<ITag>;
  dropdownValues: Array<ITag>;
  onRemove?: Function;
  onSelect?: Function;
  inputRef?: React.RefObject<HTMLInputElement>;
  disabled?: boolean;
  customTextProperty?: keyof ITag;
  dropdownFixed?: boolean;
  preventDefaultOnTagClick?: boolean;
  autoFocus?: boolean;
}

const BhInputWithTags: FC<Props> = ({ label, currentValues, dropdownValues, onRemove, onSelect, inputRef, disabled, customTextProperty, dropdownFixed, preventDefaultOnTagClick, autoFocus }) => {
  const { t } = useTranslation();
  const containedInputRef = useRef<HTMLInputElement>(null);
  const [dropdownTags, setDropdownTags] = useState([] as Array<ITag>);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const outsideClickRef = useOuterClick(() => {
    setIsDropdownOpen(false);
  });

  const textProperty = customTextProperty ? customTextProperty : "name";

  useEffect(() => {
    if (dropdownValues && dropdownValues.length > 0) {
      setDropdownTags(getFilteredDropdownValues());
    }
  }, [dropdownValues, currentValues]);

  const getFilteredDropdownValues = () => {
    return dropdownValues.filter((tag) => {
      return !currentValues.some((val) => {
        return tag[textProperty] === val[textProperty];
      });
    });
  };

  const onChange = useCallback(
    (event?: React.ChangeEvent<HTMLInputElement>) => {
      event && event.preventDefault();
      startTransition(() => {
        const inputValue = event?.target.value || "";
        if (inputValue.length > 0) {
          setIsDropdownOpen(true);
        }
        const availableTags = getUniqueValues(getFilteredDropdownValues(), textProperty).filter((tag) => {
          return tag[textProperty].toLowerCase().includes(inputValue.toLowerCase());
        });
        const newTagDropdownValue = { [textProperty]: t("TAG.NEW_TAG").toUpperCase() + ' "' + inputValue + '"', icon: faPlus, function: () => onValueSelect({ [textProperty]: inputValue }) };
        const displayNewTagDropdownValue =
          inputValue.length > 0 &&
          !availableTags.some((t) => t[textProperty].toLowerCase() === inputValue.toLowerCase()) &&
          !currentValues.some((t: any) => t[textProperty].toLowerCase() === inputValue.toLowerCase());
        setDropdownTags(displayNewTagDropdownValue ? [newTagDropdownValue, ...availableTags] : availableTags);
      });
    },
    [currentValues]
  );

  const onValueSelect = (value: any) => {
    let inputRefPropOrContained = inputRef || containedInputRef;
    if (inputRefPropOrContained && inputRefPropOrContained.current) {
      inputRefPropOrContained.current.value = "";
    }
    onChange();
    onSelect && onSelect(value);
  };

  const removeTag = (tag: ITag) => {
    currentValues.forEach((currentTag) => {
      if (currentTag[textProperty] === tag[textProperty]) {
        onRemove && onRemove(currentTag);
      }
    });
  };

  const onEnter = (event: any) => {
    if (event.key === "Enter") {
      onValueSelect({ [textProperty]: event.target.value });
    }
  };

  const onBlur = () => {
    setIsDropdownOpen(false);
  };

  const dropdownMenu = (
    <BhDropdownMenu
      type={BhDropdownTypeEnum.STRING}
      textProperty={textProperty}
      values={dropdownTags}
      onSelect={onValueSelect}
      numberOfItemsDisplayed={10}
      customDropdown={true}
      widthClass="w-full font-bold"
    />
  );

  return (
    <div className="relative w-full" onBlur={onBlur} ref={outsideClickRef}>
      {label && <BhInputLabel>{label}</BhInputLabel>}
      <div className="bh-border-pigeon-40 focus-within:bh-border-deep-ocean hover:bh-border-pigeon-70 min-h-42px bh-bg-white flex flex-row justify-between whitespace-nowrap rounded border p-1">
        <div className="flex w-full flex-row flex-wrap items-center gap-y-1 overflow-y-auto overflow-x-hidden" onMouseDown={(e) => preventDefaultOnTagClick && e.preventDefault()}>
          {currentValues?.length > 0 &&
            currentValues.map((tag, index) => {
              return (
                <BhTag key={index} type={BhTagType.TAG} onDeleteClick={disabled ? undefined : () => removeTag(tag)}>
                  {tag[textProperty]}
                </BhTag>
              );
            })}
          <input
            ref={inputRef || containedInputRef}
            className="text-14px min-w-50px disabled:bh-bg-white flex-1 p-0 pl-2 outline-0"
            onChange={onChange}
            onKeyDown={onEnter}
            disabled={disabled}
            autoFocus={autoFocus}
            onFocus={() => setIsDropdownOpen(true)}
            onBlur={() => setIsDropdownOpen(false)}
          />
        </div>
      </div>
      {isDropdownOpen && dropdownTags.length > 0 && (
        <div className="z-60 absolute w-full" onMouseDown={(e) => e.preventDefault()}>
          {dropdownFixed && <BhFixedDropdownParent onOutsideClick={() => setIsDropdownOpen(false)}>{dropdownMenu}</BhFixedDropdownParent>}
          {!dropdownFixed && dropdownMenu}
        </div>
      )}
    </div>
  );
};

export default React.memo(BhInputWithTags);
