import React, { FC } from "react";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "@/utilities/jsUtilities";
import { faLockKeyhole } from "@fortawesome/pro-solid-svg-icons/faLockKeyhole";
import { useTranslation } from "react-i18next";

interface Props {
  fieldValue: string;
  callback: Function;
  isRoundEditable?: boolean;
  checklistLanguage?: string;
  isCheckpointList?: boolean;
}

const CheckpointButtonsNoSelection: FC<Props> = ({ fieldValue, callback, isRoundEditable, checklistLanguage, isCheckpointList }) => {
  const { t } = useTranslation();

  const classesMap: Record<string, string> = {
    YES: "bh-bg-success-green-10 bh-text-success-green-110 hover:bh-bg-success-green-20",
    NO: "bh-bg-error-red-10 bh-text-error-red-120 hover:bh-bg-error-red-20",
    "N/A": "bh-bg-smoke bh-text-deep-ocean hover:bh-bg-pigeon-20"
  };

  const removeAnswer = () => {
    if (!isRoundEditable) {
      return;
    }
    const valueToSave = { fieldValue: null };
    callback(valueToSave, true);
  };

  return (
    <div
      className={classNames(
        "min-w-64 relative ml-1 flex h-full cursor-pointer flex-row items-center justify-center rounded font-bold transition-all duration-300",
        classesMap[fieldValue],
        !isRoundEditable && "cursor-not-allowed"
      )}
      onClick={removeAnswer}
    >
      {(fieldValue === "YES" || fieldValue === "NO") && <FontAwesomeIcon icon={fieldValue === "YES" ? faCheck : faXmark} className={"mr-1"} size={"lg"} />}
      <div>
        {isCheckpointList ? t("CHECKLIST.CHECKPOINT_LIST." + fieldValue, { lng: checklistLanguage }).toUpperCase() : t("CHECKLIST.CHECKPOINT." + fieldValue, { lng: checklistLanguage }).toUpperCase()}
      </div>
      {!isRoundEditable && <FontAwesomeIcon icon={faLockKeyhole} className={"absolute right-4 opacity-50"} />}
    </div>
  );
};

export default CheckpointButtonsNoSelection;
