import React, { FC, useEffect, useState } from "react";
import BhTableHeader from "@components/table/BhTableHeader";
import BhTableRow from "@components/table/BhTableRow";
import BhEventLabelContainer from "@components/eventContainer/BhEventLabelContainer";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import { IUser } from "@/model/IUser";
import { useTranslation } from "react-i18next";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IBauhubEvent } from "@/model/IBauhubEvent";
import { fetchFileLog } from "@/api/fileAPI";
import { IFileEntity } from "@/model/files/IFileEntity";
import { formatDateTime } from "@/utilities/dateUtility";

interface Props {
  selectedFile: IFileEntity;
}

const FileLogContainer: FC<Props> = ({ selectedFile }) => {
  const { t } = useTranslation();
  const [fileHistory, setFileHistory] = useState([] as Array<IBauhubEvent>);

  useEffect(() => {
    if (selectedFile) {
      fetchFileLog(selectedFile.id).then((logs) => {
        const logsSorted = logs.slice().sort((a, b) => (new Date(a.created) < new Date(b.created) ? 1 : -1));
        setFileHistory(logsSorted);
      });
    }
  }, [selectedFile]);

  const fileHistoryTableHeaders: Array<IBhTableHeader<IBauhubEvent>> = [
    { id: 1, content: t("LOG.TYPE") },
    { id: 2, content: t("LOG.TRIGGERER") },
    { id: 3, field: "created", content: t("LOG.CREATED") }
  ];

  return (
    <div className="mt-4">
      {fileHistory && <div className="bh-text-deep-ocean-80 mb-2 pl-3">{t("FILE_INFORMATION.FILES") + " - " + fileHistory.length}</div>}
      <table className="w-full">
        <BhTableHeader columns={fileHistoryTableHeaders} reversed={false} />
        <tbody>
          {fileHistory &&
            fileHistory.map((log) => {
              return (
                <BhTableRow key={log.id}>
                  <td>
                    <BhEventLabelContainer event={log} />
                  </td>
                  <td>
                    <BhUserIconWithName user={{ firstName: log.data.eventTriggererFirstName, lastName: log.data.eventTriggererLastName, userExists: true } as IUser} />
                  </td>
                  <td>{formatDateTime(log.created)}</td>
                </BhTableRow>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default FileLogContainer;
