import { IProject, IProjectSecurityLevelRequest } from "@/model/IProject";
import { ICompany } from "@/model/ICompany";
import { IProjectLogFilter } from "@/model/logs/IProjectLogFilter";
import { IProjectLogQueryDTO } from "@/model/logs/IProjectLogQueryDTO";
import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { ICompanyInvitedUser } from "@/model/companyUsers/ICompanyInvitedUser";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import { EntityId } from "@reduxjs/toolkit";
import { IProjectMessage } from "@/model/IProjectMessage";
import { ITask } from "@/model/taskBoard/ITask";
import { IRecentFilesDTO } from "@/model/IRecentFilesDTO";
import { IUser } from "@/model/IUser";
import { IInvite } from "@/model/invites/IInvite";
import { IUserAuthority } from "@/model/IUserAuthority";
import { IInviteAuthority } from "@/model/invites/IInviteAuthority";
import { IWrapperDTO } from "@/model/utilities/IWrapperDTO";

export function fetchProject(projectId: EntityId): Promise<IProject> {
  return bauhubGet("/project/v2/" + projectId);
}

export function saveProject(project: IProject): Promise<IProject> {
  return bauhubPost("/project/info", project);
}

export function fetchProjectsByCompany(): Promise<Array<ICompany>> {
  return bauhubGet("/project/all");
}

export function fetchProjectLog(projectId: EntityId, filter: IProjectLogFilter): Promise<IProjectLogQueryDTO> {
  return bauhubGet("/project/" + projectId + "/log", filter);
}

export function fetchProjectUserEmailsForSharebox(projectId: EntityId, shareboxId?: EntityId): Promise<Array<ISimpleValidUserAuthority>> {
  return bauhubGet("/project/" + projectId + "/users/emails", { shareboxId: shareboxId });
}

export function fetchRelatedUsersInProjectWithSearch(projectId: EntityId): Promise<Array<ISimpleValidUserAuthority>> {
  return bauhubGet("/project/" + projectId + "/users/search", { query: " " });
}

export function saveProjectStatus(projectId: EntityId, status: string): Promise<IProject> {
  return bauhubPost("/project/status", { id: projectId, status: status });
}

export function removeUserOrInvitedUserFromProject(projectId: EntityId, username: string): Promise<ICompanyUser | ICompanyInvitedUser> {
  return bauhubPost("/project/" + projectId + "/username/" + username + "/remove");
}

export function fetchProjectMessages(projectId: EntityId): Promise<Array<IProjectMessage>> {
  return bauhubGet("/project/" + projectId + "/messages");
}

export function saveProjectMessage(message: IProjectMessage): Promise<IProjectMessage> {
  return bauhubPost("/project/message", message);
}

export function deleteProjectMessage(message: IProjectMessage): Promise<IProjectMessage> {
  return bauhubPost("/project/message/delete", message);
}

export function fetchProjectRecentFilesAndDocumentsForDashboard(projectId: EntityId): Promise<IRecentFilesDTO> {
  return bauhubGet("/project/" + projectId + "/dashboard/recent");
}

export function fetchProjectActiveTasksForDashboard(projectId: EntityId): Promise<Array<ITask>> {
  return bauhubGet("/project/" + projectId + "/user/tasks/active");
}

export function fetchNordeconLawyerAuthorities(projectId: EntityId): Promise<Array<ISimpleValidUserAuthority>> {
  return bauhubGet("/project/" + projectId + "/lawyer/authorities");
}

export function addNordeconLawyerAuthority(projectId: EntityId, authority: ISimpleValidUserAuthority): Promise<ISimpleValidUserAuthority> {
  return bauhubPost("/project/" + projectId + "/authority/lawyer/add", authority);
}

export function removeNordeconLawyerAuthority(projectId: EntityId, authority: ISimpleValidUserAuthority): Promise<ISimpleValidUserAuthority> {
  return bauhubPost("/project/" + projectId + "/authority/lawyer/remove", authority);
}

export function fetchCompanyAdminsByProject(projectId: EntityId): Promise<Array<IUser>> {
  return bauhubGet("/project/" + projectId + "/company/admins");
}

export function fetchRTEngineeringPMAuthorities(projectId: EntityId): Promise<Array<ISimpleValidUserAuthority>> {
  return bauhubGet("/project/" + projectId + "/engineeringpm/authorities");
}

export function addRTEngineeringPMAuthority(projectId: EntityId, authority: ISimpleValidUserAuthority): Promise<IUserAuthority> {
  return bauhubPost("/project/" + projectId + "/authority/engineeringpm/add", authority);
}

export function addRTEngineeringPMAuthorityToInvite(projectId: EntityId, invite: IInvite): Promise<IInviteAuthority> {
  return bauhubPost("/project/" + projectId + "/authority/engineeringpm/invite/add", invite);
}

export function removeRTEngineeringPMAuthority(projectId: EntityId, authority: ISimpleValidUserAuthority): Promise<ISimpleValidUserAuthority> {
  return bauhubPost("/project/" + projectId + "/authority/engineeringpm/remove", authority);
}

export function saveRTProjectCode(projectId: EntityId, projectCode: string): Promise<IWrapperDTO> {
  return bauhubPost("/project/rt/" + projectId + "/code", { value: projectCode });
}

export function saveProjectSecurityLevel(projectSecurityLevelRequest: IProjectSecurityLevelRequest): Promise<IProject> {
  return bauhubPost("/project/security", projectSecurityLevelRequest);
}

export function fetchPossibleSignersForContainer(projectId: EntityId, fileIds: Array<EntityId>): Promise<Array<ISimpleValidUserAuthority>> {
  return bauhubGet("/project/" + projectId + "/container/possibleSigners/" + fileIds.join(","));
}
