import React, { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import BhTableHeader from "@components/table/BhTableHeader";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import { Trans, useTranslation } from "react-i18next";
import { IUserAuthority } from "@/model/IUserAuthority";
import { IWorkGroupUserAuthorities } from "@/model/files/IFileAccess";

interface Props {
  party: IWorkGroupUserAuthorities;
}

const PartyAuthoritiesCard: FC<Props> = ({ party }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const classes =
    "bh-outline-pigeon-40 hover:bh-bg-mint-30 hover:bh-outline-pigeon mt-1px relative flex flex-col cursor-pointer outline outline-1 transition duration-500 ease-in-out first:rounded-t last:rounded-b hover:z-10 mx-0.5";

  const tableColumnHeaders: Array<IBhTableHeader<any>> = [
    { id: 1, content: "", classes: "w-2/3" },
    { id: 2, content: `${t("DIRECTORY.AUTHORITY_TYPE")}`, classes: "w-1/3" }
  ];

  const hasAuthorities = party?.userAuthorities?.length > 0;

  return (
    <>
      <div className={classes} onClick={() => setIsOpen(!isOpen)}>
        <div className="flex h-14 items-center">
          <div className="mx-4 flex h-6 w-6 items-center justify-center">
            <FontAwesomeIcon className="" size="sm" icon={isOpen ? faCaretDown : faCaretRight} />
          </div>
          <p className="text-18px bh-text-deep-ocean leading-6">{party.name}</p>
        </div>
        {isOpen && hasAuthorities && (
          <div className="mx-14">
            <table className="mb-4 w-full">
              <BhTableHeader columns={tableColumnHeaders} />
              <tbody className="divide-y">
                {party.userAuthorities.map((user: IUserAuthority) => (
                  <tr key={user.userEntityId}>
                    <td className="px-3 py-1.5">
                      <BhUserIconWithName user={user} showUsername={true} />
                    </td>
                    <td className="p-3">
                      <Trans>{"PARTY." + user.authority}</Trans>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default PartyAuthoritiesCard;
