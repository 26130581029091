import React, { Dispatch, FC, SetStateAction } from "react";
import BhModal from "@components/modal/BhModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/pro-solid-svg-icons/faWarning";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhWarningButton from "@components/buttons/BhWarningButton";
import { useTranslation } from "react-i18next";

interface Props {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  onCancelButtonClick: Function;
  onSendButtonClick: Function;
}

const BhSendActConfirmationModal: FC<Props> = ({ isShown, setIsShown, onSendButtonClick, onCancelButtonClick }) => {
  const { t } = useTranslation();

  return (
    <BhModal
      isShown={isShown}
      setIsShown={setIsShown}
      header={
        <div className="flex items-center">
          <FontAwesomeIcon className="bh-text-warning-yellow mt-1 pr-5" icon={faWarning} /> <h2>{t("ACT.SENDING.MODAL.TITLE")}</h2>
        </div>
      }
      children={<p className="px-8 py-6">{t("ACT.SENDING.CONFIRMATION.BODY")}</p>}
      footer={
        <div className="flex flex-row items-center">
          <BhTextOnlyButton buttonProps={{ onClick: () => onCancelButtonClick() }}>{t("MODAL.DEFAULT.CONFIRMATION.CANCEL")}</BhTextOnlyButton>
          <BhWarningButton buttonProps={{ onClick: () => onSendButtonClick() }}>{t("ACT.SENDING.CONFIRMATION.OK")}</BhWarningButton>
        </div>
      }
    />
  );
};

export default BhSendActConfirmationModal;
