import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveProjectStatusAsync, selectCurrentProject } from "@/app/store/project/projectSlice";
import { BhSectionMessageSuccess } from "@components/sectionMessage/BhSectionMessages";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faBoxArchive } from "@fortawesome/pro-solid-svg-icons/faBoxArchive";
import { BauhubPlan, ProjectStatus } from "@/model/IProject";
import ProjectArchiveModal from "@/views/home/project/detail/projectSettings/ProjectArchiveModal";
import ProjectActivateModal from "@/views/home/project/detail/projectSettings/ProjectActivateModal";
import { useTranslation } from "react-i18next";
import { isCurrentUserMaruProjectManager, isCurrentUserMaruProjectTeamMember } from "@/app/store/userSlice";
import { formatDate } from "@/utilities/dateUtility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectCompanyById } from "@/app/store/companiesSlice";
import parse from "html-react-parser";
import { getArchivedTimeLeft, textWithVariables } from "@/utilities/jsUtilities";
import ProjectSettingsGetProPlanBannerSmall from "@/views/home/project/detail/projectSettings/ProjectSettingsGetProPlanBannerSmall";

const ProjectStatusContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const project = useAppSelector(selectCurrentProject);
  const company = useAppSelector((state) => selectCompanyById(state, project.companyId));
  const [isStatusModalOpen, setStatusModalOpen] = useState<boolean>(false);
  const [isBillingModalOpen, setBillingModalOpen] = useState<boolean>(false);
  const isCurrentUserMaruPM = useAppSelector((state) => isCurrentUserMaruProjectManager(state, project.id));
  const isCurrentUserMaruTeamMember = useAppSelector((state) => isCurrentUserMaruProjectTeamMember(state, project.id));
  const statusChangeDisabled = isCurrentUserMaruPM || isCurrentUserMaruTeamMember;

  let projectArchived;
  let projectInProgress;
  let projectStatusMessage;
  switch (project.status) {
    case ProjectStatus.ARCHIVED:
      projectArchived = true;
      projectStatusMessage = t("PROJECT.STATUS.ARCHIVED") + " " + formatDate(project.archivedDate);
      break;
    case ProjectStatus.IN_PROGRESS:
      projectInProgress = true;
      projectStatusMessage = t("PROJECT.IN_PROGRESS");
      break;
  }
  const archivedTimeLeft = getArchivedTimeLeft(project.archivedDate);

  const onArchiveConfirmButtonClick = () => {
    const newProject = { ...project, status: ProjectStatus.ARCHIVED };
    dispatch(saveProjectStatusAsync(newProject));
    setStatusModalOpen(false);
  };

  const onActivateButtonClick = () => {
    const newProject = { ...project, status: ProjectStatus.IN_PROGRESS };
    const trialProjectNotConfirmed = project.trialProject && !project.confirmed;
    if (project.billingSetupDone && trialProjectNotConfirmed) {
      dispatch(saveProjectStatusAsync(newProject));
    } else {
      setBillingModalOpen(true);
    }
  };

  return (
    <div className="flex justify-between pt-4 lg:flex-col">
      <div className="w-2/3 basis-1/2">
        <h3>{t("PROJECT.STATUS.TITLE")}</h3>
        <div className="w-2/3">{t("PROJECT.STATUS.INFO")}</div>
      </div>
      {project && (
        <div className="my-2 flex basis-1/2 items-center gap-8">
          <div className="flex-grow">
            {projectInProgress && <BhSectionMessageSuccess>{projectStatusMessage}</BhSectionMessageSuccess>}
            {projectArchived && (
              <div className="flex flex-col items-center justify-center space-y-4">
                <div className="bh-bg-smoke bh-border-pigeon-50 flex max-w-[372px] flex-row items-start rounded border border">
                  <FontAwesomeIcon icon={faBoxArchive} className={"bh-text-deep-ocean h-16px w-16px p-4"} size={"2xl"} aria-hidden="true" />
                  <div className="flex-1 pr-4 pt-3.5 pb-4">
                    <div className="whitespace-nowrap text-[17px] font-bold leading-none">
                      {t("PROJECT.BANNER.ARCHIVED_2")} {formatDate(project.archivedDate)}
                    </div>
                    {company?.plan === BauhubPlan.LIGHT && (
                      <div className="mt-2 text-sm">
                        {parse(
                          textWithVariables(t("PROJECT.BANNER.ARCHIVED.LIGHT"), {
                            year: archivedTimeLeft.years().toString(),
                            month: archivedTimeLeft.months().toString(),
                            day: archivedTimeLeft.days().toString(),
                            monthText: archivedTimeLeft.months() === 1 ? t("PROJECT.BANNER.ARCHIVED.LIGHT.MONTH") : t("PROJECT.BANNER.ARCHIVED.LIGHT.MONTHS"),
                            dayText: archivedTimeLeft.days() === 1 ? t("PROJECT.BANNER.ARCHIVED.LIGHT.DAY") : t("PROJECT.BANNER.ARCHIVED.LIGHT.DAYS"),
                            yearText: archivedTimeLeft.years() === 1 ? t("PROJECT.BANNER.ARCHIVED.LIGHT.YEAR") : t("PROJECT.BANNER.ARCHIVED.LIGHT.YEARS")
                          })
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {company?.plan === BauhubPlan.LIGHT && <ProjectSettingsGetProPlanBannerSmall />}
              </div>
            )}
          </div>
          <div className="items-center align-middle">
            {projectInProgress && (
              <BhTextOnlyButton icon={faBoxArchive} buttonProps={{ onClick: () => setStatusModalOpen(true), disabled: statusChangeDisabled }}>
                {t("PROJECT.ARCHIVE")}
              </BhTextOnlyButton>
            )}
            {projectArchived && <BhTextOnlyButton buttonProps={{ onClick: onActivateButtonClick, disabled: statusChangeDisabled }}>{t("PROJECT.ACTIVATE")}</BhTextOnlyButton>}
          </div>
        </div>
      )}
      {isStatusModalOpen && (
        <ProjectArchiveModal
          companyId={project.companyId}
          isShown={isStatusModalOpen}
          setIsShown={setStatusModalOpen}
          onArchiveButtonClick={onArchiveConfirmButtonClick}
          onCancelButtonClick={() => setStatusModalOpen(false)}
        />
      )}
      {isBillingModalOpen && <ProjectActivateModal isShown={isBillingModalOpen} setIsShown={setBillingModalOpen} companyId={project.companyId} />}
    </div>
  );
};

export default ProjectStatusContainer;
