import React, { FC } from "react";
import BhDatepickerStackedLabel from "@components/input/BhDatepickerStackedLabel";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { useAppSelector } from "@/app/hooks";
import { boSelectCompanyBilling } from "@/app/store/backofficeSlice";
import BhCheckboxWithText from "@components/checkboxes/BhCheckboxWithText";
import { countriesMap } from "@/utilities/countriesMap";
import BhToggleButtonBar from "@components/toggle/BhToggleButtonBar";
import BhComboboxStackedLabel from "@components/input/BhComboboxStackedLabel";

interface Props {
  onFieldChangeCallback: Function;
}

const BOCompanyConsolidatedInvoiceSettings: FC<Props> = ({ onFieldChangeCallback }) => {
  const companyBilling = useAppSelector(boSelectCompanyBilling);
  const countries = countriesMap.map(country => country.country);
  return (
    <div>
      <h2>Consolidated invoice settings</h2>
      <div className="flex w-full gap-60 mt-4">
        <div className="w-full flex flex-col gap-6">
          <div className="grid grid-cols-2 gap-4 w-full">
            <BhInputStackedLabel initialValue={companyBilling.billingDay} property="billingDay" label="Billing day" onChangeCallback={onFieldChangeCallback} />
            <div className="full-w-datepicker">
              <BhDatepickerStackedLabel
                initialValue={new Date(companyBilling.billingDate)}
                property="billingDate"
                label="Next invoice"
                onChangeCallback={onFieldChangeCallback} />
            </div>
          </div>
          <div>
            <BhInputStackedLabel
              initialValue={companyBilling.customerRecipientEmails}
              property="customerRecipientEmails"
              label="Billing e-mail"
              onChangeCallback={onFieldChangeCallback}
              debounceLength={1000}
            />
            <div className="-ml-5">
              <BhCheckboxWithText text="Personal invoicing" isChecked={companyBilling.customerCivilian} property="customerCivilian" onChange={onFieldChangeCallback} />
            </div>
          </div>
          <BhInputStackedLabel initialValue={companyBilling.customerRecipient} property="customerRecipient" label="Recipient" onChangeCallback={onFieldChangeCallback} debounceLength={2000} />
        </div>
        <div className="w-full flex flex-col gap-6">
          <BhComboboxStackedLabel
            initialValue={companyBilling.customerCountry}
            onSelect={onFieldChangeCallback}
            values={countries}
            property="customerCountry"
            label="Country"
            placeholder={companyBilling.customerCountry}
          />
          <BhInputStackedLabel initialValue={companyBilling.customerAddress} property="customerAddress" label="Legal address" onChangeCallback={onFieldChangeCallback} debounceLength={2000} />
          <BhInputStackedLabel initialValue={companyBilling.customerRegCode} property="customerRegCode" label="Reg code" onChangeCallback={onFieldChangeCallback} debounceLength={2000} />

          <BhInputStackedLabel
            initialValue={companyBilling.customerVatNumber}
            property="customerVatNumber" label="VAT"
            labelHelper="(optional)"
            onChangeCallback={onFieldChangeCallback}
            debounceLength={2000}
          />
          <BhInputStackedLabel
            initialValue={companyBilling.customerAdditionalInfo}
            property="customerAdditionalInfo"
            label="Additional info"
            labelHelper="(optional)"
            onChangeCallback={onFieldChangeCallback}
            debounceLength={2000}
          />
          <BhToggleButtonBar
            label="Invoice type"
            items={[{ value: false, text: "E-mail" }, { value: true, text: "E-invoice" }]}
            onClickAction={onFieldChangeCallback}
            property="einvoice"
            selected={companyBilling.einvoice}
          />
        </div>
      </div>
    </div>
  );
};

export default BOCompanyConsolidatedInvoiceSettings;