import React, { FC, useEffect } from "react";
import FileTree from "@/views/home/project/detail/fileTree/FileTree";
import Directory from "@/views/home/project/detail/directory/Directory";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { allFileEntitiesUnSelected, currentDirIdSet, fileNameFilterReset, selectCurrentDirId } from "@/app/store/filesSlice";
import { EntityId } from "@reduxjs/toolkit";
import { selectFolderById } from "@/app/store/foldersSlice";
import { setCurrentSidebarMenuItem } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";

interface Props {
  currentDirId: EntityId;
}

const DirectoryContainer: FC<Props> = ({ currentDirId }) => {
  const dispatch = useAppDispatch();
  const prevCurrentDirId = useAppSelector(selectCurrentDirId);
  const directory = useAppSelector((state) => selectFolderById(state, currentDirId));

  useDocumentTitle(directory?.name);

  useEffect(() => {
    return function cleanup() {
      dispatch(allFileEntitiesUnSelected());
    };
  }, []);

  useEffect(() => {
    if (prevCurrentDirId !== currentDirId) dispatch(fileNameFilterReset());
    dispatch(currentDirIdSet(currentDirId));
    if (directory && directory.branch) {
      dispatch(setCurrentSidebarMenuItem(directory.branch === "ROOT_DIR" ? SidebarItemType.DRAWINGS : SidebarItemType.DOCUMENTS));
    }
  }, [currentDirId]);

  return (
    <div className="directory-container flex w-full">
      <aside className="file-tree-container relative flex w-72 flex-shrink-0 overflow-y-auto xl:hidden">
        <div className="w-full flex-col">
          <div className="h-full">
            <FileTree currentDirId={currentDirId} />
          </div>
        </div>
      </aside>
      <main className="relative inline-flex w-full overflow-hidden">
        <Directory currentDirId={currentDirId} />
      </main>
    </div>
  );
};

export default DirectoryContainer;
