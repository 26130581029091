import React, { FC } from "react";

interface Props {
  children: React.ReactNode;
}

const DetailViewHeading: FC<Props> = ({ children }) => {
  return <h2 className="bh-text-deep-ocean text-left">{children}</h2>;
};

export default DetailViewHeading;
