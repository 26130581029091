import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import wizardDirManagement from "@img/baudrive-wizard-kaustade-haldamine.png";
import wizardDirManagementEn from "@img/baudrive-wizard-kaustade-haldamine-en.png";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUser } from "@/app/store/userSlice";

const SyncStep4Container: FC = () => {
    const { t } = useTranslation();

    const currentUser = useAppSelector(selectCurrentUser);
    const img = currentUser?.language === "et_EE" ? wizardDirManagement : wizardDirManagementEn;

    useEffect(() => {
    }, []);

    return (
        <div>
            <div className="flex w-full flex-col items-center pt-5">
                <img src={img} className="m-2 w-full max-w-[270px]" alt="No matches" />
                <h2 className="bh-text-dark-jungle text-center">{t("SYNC.STEP_4_TITLE")}</h2>
                <p className="text-18px text-center">{t("SYNC.STEP_4_SUBTEXT")}</p>
            </div>
        </div>
    );
};

export default SyncStep4Container;
