import React, { FC } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import BhLightSeparatorVertical from "@components/separator/BhLightSeparatorVertical";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import BhDeclineButton from "@components/buttons/BhDeclineButton";

interface Props {
  selectedIds: Array<EntityId>;
  allSelected: boolean;
  resetSelected: Function;
  addBasesToProjects: Function;
  removeBasesFromProjects: Function;
}

const CompanySettingsChecklistsBasketContainer: FC<Props> = ({ selectedIds, allSelected, resetSelected, removeBasesFromProjects, addBasesToProjects }) => {
  const { t } = useTranslation();

  if (!allSelected && selectedIds.length === 0) {
    return null;
  }

  return (
    <div className="bh-bg-white bh-shadow max-w-660px fixed left-1/2 bottom-6 z-10 flex -translate-x-1/2 flex-col overflow-hidden rounded-xl">
      <div className="flex h-16 flex-row items-center">
        <div className="bh-bg-bauhub-green-120 flex items-center">
          <div className="bh-text-white p-6 font-bold">
            <span className="pl-1">{allSelected ? t("GLOBAL.ALL").toUpperCase() : selectedIds.length}</span>
          </div>
        </div>
        <div className="mx-4 flex h-12 items-center">
          <BhPrimaryButton icon={faCheck} buttonProps={{ onClick: addBasesToProjects, classes: "h-full" }}>
            {t("CHECKLIST.ACTIVATION_MODAL.HEADER.ADD")}
          </BhPrimaryButton>
          <BhDeclineButton icon={faXmark} buttonProps={{ onClick: removeBasesFromProjects, classes: "h-full" }}>
            {t("CHECKLIST.ACTIVATION_MODAL.HEADER.REMOVE")}
          </BhDeclineButton>
        </div>
        <BhLightSeparatorVertical />
        <div className="flex items-center space-x-4 p-4">
          <BhIconButton icon={faXmark} buttonProps={{ onClick: resetSelected }} />
        </div>
      </div>
    </div>
  );
};

export default CompanySettingsChecklistsBasketContainer;
