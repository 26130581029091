import React, { FC, useCallback, useEffect, useState } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyById, selectProjectsSort } from "@/app/store/companiesSlice";
import { EntityId } from "@reduxjs/toolkit";
import { IProjectOrder } from "@/model/billing/IProjectOrder";
import { changeCompanyInvoiceType, fetchCompanyBillingRequisites, fetchProjectOrders } from "@/api/billingAPI";
import { useTranslation } from "react-i18next";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import { CompanyInvoiceType, ICompanyBillingRequisites } from "@/model/IBillingInfo";
import BhPigeon20Separator from "@components/separator/BhPigeon20Separator";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import BhFilter from "@components/filters/BhFilter";
import CompanyPlanSettingsProjectOrderItem from "@/views/home/company/detail/CompanyPlanSettingsProjectOrderItem";
import { faGear } from "@fortawesome/pro-regular-svg-icons/faGear";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import CompanyPlanSettingsProjectOrdersSort from "@/views/home/company/detail/CompanyPlanSettingsProjectOrdersSort";
import CompanySettingsCompanyBillingInfoModal from "@/views/home/company/detail/CompanySettingsCompanyBillingInfoModal";

interface Props {
  companyId: EntityId;
}

const CompanySettingsPlanSettingsTab: FC<Props> = ({ companyId }) => {
  const { t } = useTranslation();
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const [projectOrders, setProjectOrders] = useState([] as Array<IProjectOrder>);
  const [companyBillingRequisites, setCompanyBillingRequisites] = useState({} as ICompanyBillingRequisites);
  const [showArchived, setShowArchived] = useState(false);
  const [filter, setFilter] = useState({ searchWord: "", type: "" });
  const [isCompanyBillingInfoModalOpen, setCompanyBillingInfoModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const sort = useAppSelector(selectProjectsSort);
  const invoiceTypes = [CompanyInvoiceType.AGGREGATE, CompanyInvoiceType.SINGLE];

  useEffect(() => {
    setLoading(true);
    if (companyId) {
      fetchProjectOrders(companyId).then((projectOrders) => {
        setProjectOrders(projectOrders);
        fetchCompanyBillingRequisites(companyId).then((requisites) => {
          setCompanyBillingRequisites(requisites);
          setLoading(false);
        });
      });
    }
  }, []);

  const saveCompanyInvoiceType = (newType: CompanyInvoiceType) => {
    if (company) {
      changeCompanyInvoiceType(company.id, newType).then((orders) => {
        setCompanyBillingRequisites((prev) => ({ ...prev, invoiceType: newType }));
        setProjectOrders(orders);
      });
    }
  };

  const onInvoiceTypeChangeButtonClicked = () => {
    if (company) {
      const newInvoiceType = companyBillingRequisites.invoiceType === CompanyInvoiceType.AGGREGATE ? CompanyInvoiceType.SINGLE : CompanyInvoiceType.AGGREGATE;
      if (newInvoiceType === CompanyInvoiceType.AGGREGATE) {
        setCompanyBillingInfoModalOpen(true);
        return;
      }
      saveCompanyInvoiceType(newInvoiceType);
    }
  };

  const filterOrders = (projectOrder: IProjectOrder) => {
    if (filter.searchWord.length > 1 && !projectOrder.name.toLowerCase().includes(filter.searchWord.toLowerCase())) {
      return false;
    }
    if (filter.type.length > 0 && projectOrder.projectBilling.type !== filter.type) {
      return false;
    }
    if (!showArchived && projectOrder.status === "ARCHIVED") {
      return false;
    }
    return true;
  };

  const sortOrders = (a: IProjectOrder, b: IProjectOrder) => {
    if (a.status.localeCompare(b.status) !== 0) {
      return -1 * a.status.localeCompare(b.status);
    }
    // @ts-ignore
    return a[sort.property].toLocaleString().toLowerCase() > b[sort.property].toLocaleString().toLowerCase() ? (sort.reversed ? -1 : 1) : sort.reversed ? 1 : -1;
  };

  const onFilterTypeSelected = useCallback(
    (value: any) => {
      setFilter({ ...filter, type: value.name });
    },
    [filter]
  );

  const isFilterTypeSelected = useCallback(
    (value: any) => {
      return filter.type === value.name;
    },
    [filter]
  );

  const onTypeFilterReset = useCallback(() => {
    const newFilter = { ...filter, type: "" };
    setFilter(newFilter);
  }, [filter]);

  const orderTypeFilterValues = [
    { name: "MONTH", translation: "COMPANY.SETTINGS.ORDERS.BILLING.MONTH" },
    { name: "YEAR", translation: "COMPANY.SETTINGS.ORDERS.BILLING.YEAR" }
  ];

  return (
    <div className="pt-4 pb-36">
      <div className="flex flex-row px-44 lg:px-8">
        <div className="flex w-60 flex-row">
          <h2 className="bh-text-deep-ocean mb-3">{t("BILLING.SETTINGS")}</h2>
        </div>
        <div className="flex w-full flex-col pl-36 pt-3">
          <div className="flex flex-row items-center pb-4">
            <span className="bh-text-deep-ocean pr-6 font-bold">{t("BILLING.CHOOSE_AGGREGATED_INVOICE")}</span>
            <BhToggleSwitch value={companyBillingRequisites.invoiceType === "AGGREGATE"} onClickAction={onInvoiceTypeChangeButtonClicked}></BhToggleSwitch>
          </div>
          {companyBillingRequisites.invoiceType === "AGGREGATE" && (
            <div className="bh-bg-smoke flex w-full flex-row justify-between rounded">
              <div className="flex flex-row p-4">
                <div className="flex flex-col pr-10">
                  <div className="pb-2">{t("PROJECT.ACTIVATION.MODAL.TYPE")}</div>
                  {!companyBillingRequisites.einvoice && <div className="">{t("COMPANY.SETTINGS.ORDER.SETTINGS.ADDRESS")}</div>}
                </div>
                <div className="flex flex-col">
                  <div className="pb-2 font-bold">{t(companyBillingRequisites.einvoice ? "PROJECT.ACTIVATION.MODAL.TYPE.EINVOICE" : "GLOBAL.EMAIL")}</div>
                  {!companyBillingRequisites.einvoice && <div className="font-bold">{companyBillingRequisites.customerRecipientEmails}</div>}
                </div>
              </div>
              <div className="pt-2 pr-1">
                <BhTextOnlyButton
                  icon={faGear}
                  iconClasses="bh-text-deep-ocean"
                  buttonProps={{
                    onClick: () => setCompanyBillingInfoModalOpen(true),
                    classes: "bh-text-deep-ocean"
                  }}
                >
                  {t("COMPANY.BILLING.AGGREGATE_INVOICE_SETTINGS")}
                </BhTextOnlyButton>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="md:px-22 px-44 lg:px-8">
        <BhPigeon20Separator />
      </div>
      <div className="md:px-22 flex flex-row items-center justify-between px-44 lg:px-8">
        <div className="flex flex-row items-center">
          <h2 className="bh-text-deep-ocean mb-3 pr-2">{t("HOME.PROJECTS")}</h2>
          <BhBadge classes="mr-0.5" type={BhBadgeType.SUBTLE}>
            <span className="bh-text-deep-ocean-80">
              {t("PROJECT.STATUS.IN_PROGRESS")} {projectOrders && projectOrders.filter((p) => p.status === "IN_PROGRESS").length}
            </span>
          </BhBadge>
          <BhBadge type={BhBadgeType.SUBTLE}>
            <span className="bh-text-deep-ocean-80">
              {t("PROJECT.STATUS.ARCHIVED")} {projectOrders && projectOrders.filter((p) => p.status === "ARCHIVED").length}
            </span>
          </BhBadge>
        </div>
        <div className="flex flex-row items-center">
          <span className="bh-text-deep-ocean-80 pr-4">{t("HOME.SHOW_ARCHIVED")}</span>
          <BhToggleSwitch value={showArchived} onClickAction={() => setShowArchived(!showArchived)} small={true}></BhToggleSwitch>
        </div>
      </div>
      <div className="md:px-22 flex flex-row px-44 lg:px-8">
        <div className="w-74 mr-2">
          <BhSearchInputWBG
            property="query"
            initialValue={filter.searchWord}
            onChangeCallback={(search: { query: any }) => setFilter({ ...filter, searchWord: search.query })}
            placeholder={t("SEARCH.SEARCH_PROJECT_PLACEHOLDER") as string}
          />
        </div>
        <BhFilter
          title={t("COMPANY.BILLING.SETTINGS.BILLING_TYPE")}
          values={orderTypeFilterValues}
          textProperty="translation"
          onSelect={onFilterTypeSelected}
          onReset={onTypeFilterReset}
          isSelected={isFilterTypeSelected}
          selectedValues={filter.type.length > 0 ? 1 : 0}
          translateValues={true}
        />
      </div>
      <div className="flex flex-row items-center justify-between px-44 lg:px-8">
        <div className="bh-text-deep-ocean pl-12 pt-5 font-bold">{t("PROJECT.BILLING.MODEL")}</div>
        <CompanyPlanSettingsProjectOrdersSort classes="pt-5" />
      </div>
      <div className="pt-4 pr-44 pl-36 lg:pr-8 lg:pl-1">
        {projectOrders && companyBillingRequisites && companyBillingRequisites.companyId &&
          projectOrders
            .filter((po) => filterOrders(po))
            .sort((a, b) => sortOrders(a, b))
            .map((order, index) => (
              <CompanyPlanSettingsProjectOrderItem
                key={index}
                companyStatus={company?.status ?? "TRIAL"}
                isFirst={index === 0}
                isLast={index === projectOrders.length - 1}
                order={{ ...order, companyBillingRequisites: companyBillingRequisites }}
                setProjectOrders={setProjectOrders}
              />
            ))}
      </div>
      {companyBillingRequisites && (
        <CompanySettingsCompanyBillingInfoModal
          isShown={isCompanyBillingInfoModalOpen}
          setIsShown={setCompanyBillingInfoModalOpen}
          companyBilling={companyBillingRequisites}
          setCompanyBilling={setCompanyBillingRequisites}
          changeInvoiceTypeCallback={saveCompanyInvoiceType}
        />
      )}
    </div>
  );
};

export default CompanySettingsPlanSettingsTab;
