import { bauhubGet } from "@/api/bauhubAPI";
import { EntityId } from "@reduxjs/toolkit";
import { IChecklistBase } from "@/model/checklist/IChecklistBase";
import { IChecklistStatistics } from "@/model/checklist/IChecklistStatistics";
import { getCookie, serializeUrl } from "@/utilities/jsUtilities";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

export function fetchProjectChecklistBasesForStatistics(projectId: EntityId): Promise<Array<IChecklistBase>> {
  return bauhubGet("/project/" + projectId + "/checklist/base/statistics");
}

export function fetchChecklistStatisticsForProject(projectId: EntityId, checklistBaseId: EntityId, version: number): Promise<IChecklistStatistics> {
  return bauhubGet("/project/" + projectId + "/checklist/" + checklistBaseId + "/version/" + version + "/statistics");
}

export async function fetchChecklistStatisticsExcelExportData(projectId: EntityId, checklistBaseId: EntityId, versionNumber: number): Promise<any> {
  let urlAppendixAndParams = "/project/" + projectId + "/checklist/statistics/export";

  urlAppendixAndParams += "?" + new URLSearchParams(serializeUrl({ checklistBaseId: checklistBaseId, version: versionNumber }));

  const response = await fetch(ConfigSingleton.getInstance().getConfig().REACT_APP_API_URL + urlAppendixAndParams, {
    method: "GET",
    headers: {
      "Content-Type": "application/vnd.ms-excel",
      "X-XSRF-TOKEN": getCookie("XSRF-TOKEN") || ""
    }
  }).then(async (response) => {
    return response;
  });

  return response;
}
