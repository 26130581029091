import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { classNames } from "@/utilities/jsUtilities";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import BhTableRow from "@components/table/BhTableRow";
import {
  isCurrentUserCompanyAdminWithAccountManagerPrivilege,
  isCurrentUserMaruProjectManager,
  isCurrentUserMaruProjectTeamMember,
  isCurrentUserRTEngineeringProjectManager,
  selectCurrentUser
} from "@/app/store/userSlice";
import ProjectAdministratorRemoveModal from "@components/projectSettings/ProjectAdministratorRemoveModal";
import {
  addNordeconLawyerAuthorityAsync,
  addRTEngineeringPMAuthorityAsync,
  fetchMaruAuthoritiesAsync,
  removeNordeconLawyerAuthorityAsync,
  removeRTEngineeringPMAuthorityAsync,
  selectCurrentProject,
  selectEmbachProjectModule,
  selectMaruProjectModule,
  selectNordeconProjectModule,
  selectRTProjectModule,
  updateMaruAuthorityAsync
} from "@/app/store/project/projectSlice";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownButton from "@components/dropdown/BhDropdownButton";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { useTranslation } from "react-i18next";
import { Resource } from "@/model/IUserAuthority";
import BhTooltip from "@components/BhTooltip";

interface Props {
  admin: ISimpleValidUserAuthority;
  index: number;
}

const CustomCompanyProjectAdministratorsTableRow: FC<Props> = ({ admin, index }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectCurrentUser);
  const project = useAppSelector(selectCurrentProject);
  const [administratorRemoveModalOpen, setAdministratorRemoveModalOpen] = useState<boolean>(false);

  // NORDECON
  const isNordeconProject = useAppSelector(selectNordeconProjectModule);
  const isEmbachProject = useAppSelector(selectEmbachProjectModule);

  const isCurrentUserNordeconCompanyAccountManager = useAppSelector((state) => isCurrentUserCompanyAdminWithAccountManagerPrivilege(state, project.companyId));
  const enableNordeconUserDelete = currentUser.id !== admin.userEntityId;
  const handleNordeconLawyerAuthorityChange = (value: any) => {
    if (value.lawyer) {
      dispatch(addNordeconLawyerAuthorityAsync({ projectId: project.id, authority: admin }));
    } else {
      dispatch(removeNordeconLawyerAuthorityAsync({ projectId: project.id, authority: admin }));
    }
  };

  // RT
  const isRTProject = useAppSelector(selectRTProjectModule);
  const isCurrentUserRTEngineeringPM = useAppSelector((state) => isCurrentUserRTEngineeringProjectManager(state, project.id));
  const disableRTAuthChangeToggle = isCurrentUserRTEngineeringPM || currentUser.id === admin.userEntityId || (admin.companyRelatedAuthorities && admin.companyRelatedAuthorities.isCompanyAdmin);
  const enableRTUserDelete = isCurrentUserRTEngineeringPM
    ? currentUser.id !== admin.userEntityId && admin.companyRelatedAuthorities && admin.companyRelatedAuthorities.isEngineeringPM
    : currentUser.id !== admin.userEntityId;
  const handleRTAuthChange = (value: any) => {
    if (value.engineeringPM) {
      dispatch(addRTEngineeringPMAuthorityAsync({ projectId: project.id, authority: admin }));
    } else {
      dispatch(removeRTEngineeringPMAuthorityAsync({ projectId: project.id, authority: admin }));
    }
  };

  // MARU
  const isMaruProject = useAppSelector(selectMaruProjectModule);
  const isCurrentUserMaruPM = useAppSelector((state) => isCurrentUserMaruProjectManager(state, project.id));
  const isCurrentUserMaruTeamMember = useAppSelector((state) => isCurrentUserMaruProjectTeamMember(state, project.id));
  const enableMaruUserDelete =
    currentUser.id !== admin.userEntityId &&
    ((!isCurrentUserMaruPM && !isCurrentUserMaruTeamMember) ||
      (isCurrentUserMaruPM && admin.companyRelatedAuthorities?.maruAuthority !== Resource.PROJECT) ||
      (isCurrentUserMaruTeamMember && admin.companyRelatedAuthorities?.maruAuthority === Resource.MR_PROJECT_TEAM));
  const disableMaruAuthChange = currentUser.id === admin.userEntityId || isCurrentUserMaruTeamMember || (isCurrentUserMaruPM && admin.companyRelatedAuthorities?.maruAuthority === Resource.PROJECT);
  const selectableRolesForMaruAdmin = [
    { auth: Resource.PROJECT, translation: "PROJECT.MR.RESOURCE.PROJECT" },
    { auth: Resource.MR_PROJECT_PM, translation: "PROJECT.MR.RESOURCE.MR_PROJECT_PM" },
    { auth: Resource.MR_PROJECT_TEAM, translation: "PROJECT.MR.RESOURCE.MR_PROJECT_TEAM" },
    { auth: Resource.MR_OSAKONNAJUHT, translation: "PROJECT.MR.RESOURCE.MR_OSAKONNAJUHT" }
  ];
  const selectableRolesForMaruPM = [
    { auth: Resource.MR_PROJECT_PM, translation: "PROJECT.MR.RESOURCE.MR_PROJECT_PM" },
    { auth: Resource.MR_PROJECT_TEAM, translation: "PROJECT.MR.RESOURCE.MR_PROJECT_TEAM" }
  ];
  const maruAuthDropdownValues = isCurrentUserMaruPM ? selectableRolesForMaruPM : selectableRolesForMaruAdmin;
  const handleMaruAuthChange = (authority: any) => {
    const authToChange = { ...admin, resource: authority.auth } as ISimpleValidUserAuthority;
    dispatch(updateMaruAuthorityAsync({ projectId: project.id, authority: authToChange })).then(() => {
      dispatch(fetchMaruAuthoritiesAsync(project.id));
    });
  };

  // General
  const enableUserDelete = (isNordeconProject || isEmbachProject) ? enableNordeconUserDelete : isRTProject ? enableRTUserDelete : enableMaruUserDelete;

  return (
    <BhTableRow key={admin.id} classes={classNames("bh-border-pigeon-40", index !== 0 && "border-t")}>
      <td>
        <BhUserIconWithName user={admin} textClasses={"normal"} />
      </td>
      <td>
        {(isNordeconProject || isEmbachProject) && (
          <BhToggleSwitch
            value={admin.companyRelatedAuthorities && admin.companyRelatedAuthorities.isLawyer}
            onClickAction={handleNordeconLawyerAuthorityChange}
            property={"lawyer"}
            disabled={!isCurrentUserNordeconCompanyAccountManager}
          />
        )}
        {isRTProject && (
          <BhToggleSwitch
            value={admin.companyRelatedAuthorities && admin.companyRelatedAuthorities.isEngineeringPM}
            onClickAction={handleRTAuthChange}
            property={"engineeringPM"}
            disabled={disableRTAuthChangeToggle}
          />
        )}
        {isMaruProject && admin.companyRelatedAuthorities && (
          <BhDropdown
            buttonClasses={disableMaruAuthChange ? "cursor-not-allowed opacity-50" : ""}
            disabled={disableMaruAuthChange}
            button={<BhDropdownButton reversed={true} title="" value={t("PROJECT.MR.RESOURCE." + admin.companyRelatedAuthorities.maruAuthority) as string} disabled={disableMaruAuthChange} />}
            menu={<BhDropdownMenu values={maruAuthDropdownValues} textProperty="translation" translateValues={true} type={BhDropdownTypeEnum.STRING} onSelect={handleMaruAuthChange} />}
          />
        )}
      </td>
      <td>
        <div className="flex flex-row items-center">
          <div>{admin.username}</div>
        </div>
      </td>
      <td>{admin.userInfo?.title || ""}</td>
      <td>{admin.userInfo?.companyName || ""}</td>
      <td>
        {enableUserDelete && (
          <BhTooltip body={t("USER.PROFILE.REMOVE")}>
            <div className="flex flex-row justify-end">
              <BhIconButton icon={faCircleMinus} buttonProps={{ onClick: () => setAdministratorRemoveModalOpen(true) }} />
            </div>
          </BhTooltip>
        )}
      </td>
      {administratorRemoveModalOpen && <ProjectAdministratorRemoveModal modalOpen={administratorRemoveModalOpen} setModalOpen={setAdministratorRemoveModalOpen} authority={admin} />}
    </BhTableRow>
  );
};

export default CustomCompanyProjectAdministratorsTableRow;
