import { EntityId } from "@reduxjs/toolkit";

export interface IProjectTaskCategory {
  id: EntityId;
  projectId: EntityId;
  name: string;
  code: CategoryDefaultCode | string;
  color: string;
  deleted: boolean;
}

export enum CategoryDefaultCode {
  TASK = "TASK",
  RFI = "RFI",
  ISSUE = "ISSUE",
  SAFETY = "SAFETY",
  GUARANTEE = "GUARANTEE"
}
