import React, { FC, useEffect } from "react";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useLocation } from "react-router-dom";
import { fetchProjectWorkGroupsAsync } from "@/app/store/project/projectWorkGroupsSlice";
import DetailViewContainer from "@/views/home/project/detail/detailViewContainer/DetailViewContainer";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import Users from "@/views/home/project/detail/users/Users";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { Trans } from "react-i18next";

const UsersContainer: FC = () => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(setSidebarCollapsed(false));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.USERS));
  }, [location]);

  useEffect(() => {
    dispatch(fetchProjectWorkGroupsAsync(projectId));
  }, []);

  return (
    <DetailViewContainer
      header={
        <DetailViewHeading>
          <Trans>GLOBAL.USERS</Trans>
        </DetailViewHeading>
      }
      bodyOverflowHidden={true}
    >
      <Users />
    </DetailViewContainer>
  );
};

export default UsersContainer;
