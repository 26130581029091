import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons/faAngleRight";

interface Props {
  value: string;
  onLeftClick: Function;
  onRightClick: Function;
  menuItems?: Array<any>;
  onMenuItemClickCallback?: Function;
}

const BhNavigationDropdown: FC<Props> = ({ value, onLeftClick, onRightClick, menuItems, onMenuItemClickCallback }) => {
  const isDropdownMenu = menuItems && menuItems?.length > 0;
  return (
    <div className="-mb-1 flex items-center gap-4">
      <FontAwesomeIcon icon={faAngleLeft} size="lg" onClick={() => onLeftClick()} cursor="pointer" />
      {!isDropdownMenu && <p className="rounded-sm px-1 py-0.5 text-lg">{value}</p>}
      {isDropdownMenu && onMenuItemClickCallback && (
        <BhDropdown
          button={<p className="hover:bh-bg-pigeon-20 cursor-pointer rounded-sm px-1 py-0.5 text-lg">{value}</p>}
          menu={
            <BhDropdownMenu
              values={menuItems.map((item) => {
                return {
                  text: item,
                  function: () => onMenuItemClickCallback(item)
                };
              })}
              type={BhDropdownTypeEnum.STRING}
              textProperty="text"
              widthClass={"w-24"}
              isSelected={(val) => val.text === parseInt(value)}
            />
          }
          position={BhDropdownPositionEnum.BOTTOM_LEFT}
        />
      )}
      <FontAwesomeIcon icon={faAngleRight} size="lg" onClick={() => onRightClick()} cursor="pointer" />
    </div>
  );
};

export default BhNavigationDropdown;
