import { IFileEntity } from "@/model/files/IFileEntity";
import { EntityId } from "@reduxjs/toolkit";

export interface ISignInvite {
  id: EntityId;
  username: string;
  containerUuid: string;
  signed: boolean;
  declined: boolean;
  declineReason: string;
  projectId: EntityId;
  deleted: boolean;
  orderNumber: number;
  publicUuid: string;
  signatureId: EntityId;
  inviteType: InviteType;
  userEntityId: EntityId;
  fileEntityId: EntityId;
  nextInLine: boolean;
  assignee: string;
  container: IFileEntity;
  signedOrDeclinedTime: Date;
  createdByFullName: string;
  updatedByFullName: string;
  created: Date;
  createdBy: string;
  updated: Date;
  updatedBy: string;
}

export enum InviteType {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC"
}
