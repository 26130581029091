//////////////////////////////// DATES ////////////////////////////////
import moment from "moment/moment";
import { languageStore } from "@/views/authentication/RequireAuth";

export function formatDateTime(dateString: Date) {
  if (!dateString) {
    return "";
  }
  const dateOptions: Intl.DateTimeFormatOptions = { day: "2-digit", month: "2-digit", year: "numeric" };
  const timeOptions: Intl.DateTimeFormatOptions = { hour12: false, hour: "2-digit", minute: "2-digit" };
  const dateObject = new Date(dateString);
  const language = languageStore.get();

  return dateObject.toLocaleDateString([language], dateOptions) + " " + dateObject.toLocaleTimeString([language], timeOptions);
}

export function formatDateFromString(dateString: string) {
  if (!dateString) {
    return "";
  }
  return formatDate(new Date(dateString));
}

// use withoutOffset if hours and minutes are needed precisely. getUTC will set offset.
export function formatDateTimeInternationalFormat(dateString: Date, urlSafe?: boolean, withoutOffset?: boolean) {
  if (dateString === undefined) {
    return "";
  }
  const date = new Date(dateString);
  const separator = urlSafe ? "+" : " ";
  return (
    (withoutOffset ? date.getFullYear() : date.getUTCFullYear()) +
    "-" +
    String(withoutOffset ? date.getMonth() + 1 : date.getUTCMonth() + 1).padStart(2, "0") +
    "-" +
    String(withoutOffset ? date.getDate() : date.getUTCDate()).padStart(2, "0") +
    separator +
    String(withoutOffset ? date.getHours() : date.getUTCHours()).padStart(2, "0") +
    ":" +
    String(withoutOffset ? date.getMinutes() : date.getUTCMinutes()).padStart(2, "0") +
    ":" +
    String(withoutOffset ? date.getSeconds() : date.getUTCSeconds()).padStart(2, "0")
  );
}

export function formatDateISO(dateString: Date) {
  if (!dateString) {
    return "";
  }
  return new Date(dateString).toISOString().substring(0, 10);
}

export function formatDateTimeISO(dateString: Date) {
  if (dateString === undefined) {
    return "";
  }
  return new Date(dateString).toISOString();
}

export function formatDate(dateString: Date) {
  if (!dateString) {
    return "";
  }
  const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(dateString).toLocaleDateString(["et"], options);
}

/*TODO locale on praegu hardcoded*/
export function formatDateWithMonthName(dateString: Date) {
  const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "2-digit" };
  return new Date(dateString).toLocaleDateString(["et"], options);
}

export function getCurrentDateString() {
  const currentDate = new Date();
  return currentDate.getDate() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getFullYear();
}

export function addDaysToDate(date: Date, days: number) {
  date.setDate(date.getDate() + days);
  date.setHours(12);
  date.setMinutes(0);
  date.setSeconds(0);
  return date;
}

export function subtractDaysFromDate(date: Date, days: number) {
  date.setDate(date.getDate() - days);
  date.setHours(12);
  date.setMinutes(0);
  date.setSeconds(0);
  return date;
}

export function isDateToday(date: Date) {
  const today = new Date();
  const dateToCompare = new Date(date);
  return today.getFullYear() === dateToCompare.getFullYear() && today.getMonth() === dateToCompare.getMonth() && today.getDate() === dateToCompare.getDate();
}

// USED IN TOOPLAAN FORM

function getDateRangeForWeek(year: number, week: number) {
  const startOfWeek = moment().isoWeekYear(year).isoWeek(week).startOf("isoWeek");
  const endOfWeek = moment().isoWeekYear(year).isoWeek(week).endOf("isoWeek");
  return startOfWeek.format("DD.MM") + " - " + endOfWeek.format("DD.MM");
}

function getMonthName(year: number, week: number) {
  var startingDateMonth = moment().isoWeekYear(year).isoWeek(week).day(1).month();
  var endingDateMonth = moment().isoWeekYear(year).isoWeek(week).day(7).month();
  var monthName = moment().month(startingDateMonth).format("MMMM");
  if (startingDateMonth !== endingDateMonth) {
    monthName = moment().month(startingDateMonth).format("MMMM") + "/" + moment().month(endingDateMonth).format("MMMM");
  }
  return monthName;
}

export function getWeeksForYear(year: number) {
  let weeks = [] as Array<any>;
  for (let i = 1; i < 53; i++) {
    const dateRange = getDateRangeForWeek(year, i);
    const monthName = getMonthName(year, i);
    const week = {
      number: i,
      dateRange: dateRange,
      monthName: monthName,
      year: year,
      week: i,
      text: i + " (" + dateRange + ")"
    };
    weeks.push(week);
  }
  return weeks;
}

export function getDatesForWeek(year: number, week: number) {
  let days = [];
  const weekMonday = moment().isoWeekYear(year).isoWeek(week).day(1);
  for (var i = 0; i < 7; i++) {
    days.push(moment(weekMonday).add(i, "days").date());
  }
  return days;
}

// USED IN TOOPLAAN FORM
