import React, { FC } from "react";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import BhTableHeader from "@components/table/BhTableHeader";
import BOMonthlyTrialOverviewTableRow from "@components/backoffice/trials/BOMonthlyTrialOverviewTableRow";
import { IMonthlyTrialOverviewRow } from "@/model/trials/IMonthlyTrialOverviewRow";
import { boSelectSort, boSetSort } from "@/app/store/backofficeSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";

interface Props {
  trialRow: Array<IMonthlyTrialOverviewRow>;
  isOngoingTrialRow: boolean;
}

const BOMonthlyTrialOverviewTable: FC<Props> = ({ trialRow, isOngoingTrialRow }) => {
  const sort = useAppSelector(boSelectSort);
  const dispatch = useAppDispatch();
  const tableColumnHeaders: Array<IBhTableHeader<IMonthlyTrialOverviewRow>> = [
    { id: 0, content: "Company", sortable: true, field: "companyName" },
    { id: 1, content: "Plan", sortable: true, field: "companyPlan", classes: "w-32 pl-6" },
    { id: 2, content: "Trial start", sortable: true, field: "companyTrialStart", classes: "w-36" },
    { id: 3, content: "Trial end", sortable: true, field: "companyTrialEnd", classes: "w-36" },
    { id: 4, content: "Project count", sortable: true, field: "projectCount", classes: "w-40" },
    { id: 5, content: "Confirmed projects", sortable: true, field: "confirmedProjects", classes: "w-44" },
    { id: 6, content: isOngoingTrialRow ? "Estimated turnover" : "Actual turnover", sortable: true, field: isOngoingTrialRow ? "estimatedTurnover" : "actualTurnover", classes: "w-44 text-right" }
  ];

  const onTableHeaderClickCallback = (column: IBhTableHeader<IMonthlyTrialOverviewRow>) => {
    if (column.field) {
      const trialsSort = { ...sort.trialsSort };
      const updatedTrialsSort = { ...trialsSort, property: column.field, reversed: column.field === trialsSort.property ? !trialsSort.reversed : trialsSort.reversed };
      const newSort = { ...sort, trialsSort: updatedTrialsSort };
      dispatch(boSetSort(newSort));
    }
  };

  return (
    <div className="overflow-auto">
      <table className="w-full">
        <BhTableHeader columns={tableColumnHeaders} onClickCallback={onTableHeaderClickCallback} reversed={sort.trialsSort.reversed} sortedBy={sort.trialsSort.property} />
        <tbody>
          {trialRow?.map((trial) => (
            <BOMonthlyTrialOverviewTableRow trial={trial} key={trial.companyId} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BOMonthlyTrialOverviewTable;
