import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { removeAsyncJobAsync, selectAsyncJobById } from "@/app/store/asyncJobsSlice";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { Trans, useTranslation } from "react-i18next";
import { formatDateTime } from "@/utilities/dateUtility";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import BhTooltip from "@components/BhTooltip";

interface Props {
  asyncJobId: EntityId;
}

const BhFailedAsyncJobContainer: FC<Props> = ({ asyncJobId }) => {
  const { t } = useTranslation();
  const asyncJob = useAppSelector((state) => selectAsyncJobById(state, asyncJobId));
  const dispatch = useAppDispatch();

  if (!asyncJob) return null;

  return (
    <div className="flex flex-col px-4 py-1">
      <div className="flex flex-row items-center justify-between">
        <h3>
          <Trans>{"ASYNC.FAILED." + asyncJob.jobType}</Trans>
        </h3>
        <BhTextOnlyButton buttonProps={{ onClick: () => dispatch(removeAsyncJobAsync(asyncJob)) }}>
          <Trans>GLOBAL.REMOVE</Trans>
        </BhTextOnlyButton>
      </div>
      <div className="flex flex-row items-center">
        <div className="flex flex-1 items-center pb-2">
          <Trans>ASYNC_ENDED</Trans>
          <span className="pl-1 pr-4">{formatDateTime(asyncJob.jobEnd)}</span>
          <BhTooltip body={asyncJob.reason}>
            <BhTag type={BhTagType.MUSTAND}>{t("ASYNC." + asyncJob.status)}</BhTag>
          </BhTooltip>
        </div>
      </div>
    </div>
  );
};

export default BhFailedAsyncJobContainer;
