import React, { FC } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons/faCirclePlus";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import FormBaseWeatherFieldColumn from "@components/form/base/body/fields/weatherField/FormBaseWeatherFieldColumn";
import { classNames } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";

interface Props {
  fieldProperty: string;
  fieldValue: any;
  saveFieldCallback: Function;
  disabled?: boolean;
}

const FormBaseWeatherField: FC<Props> = ({ fieldProperty, fieldValue, saveFieldCallback, disabled }) => {
  const { t } = useTranslation();
  const addRow = () => {
    const newRows = [...fieldValue.rows, {}];
    saveFieldCallback("rows", newRows);
  };

  const removeRow = (rowIndex: number) => {
    const newRows = [...fieldValue.rows];
    newRows.splice(rowIndex, 1);
    saveFieldCallback("rows", newRows);
  };

  return (
    <div>
      <div className="flex gap-2">
        {fieldValue.columns.map((col: any, colIndex: number) => (
          <FormBaseWeatherFieldColumn col={col} colIndex={colIndex} saveFieldCallback={saveFieldCallback} fieldValue={fieldValue} key={colIndex} disabled={disabled} />
        ))}
        {fieldValue.rows && fieldValue.rows.length > 1 && !disabled && (
          <div className={classNames("flex flex-col gap-1.5", fieldValue.columns.length > 1 ? "mt-14" : "mt-6")}>
            {fieldValue.rows?.map((_: any, rowIndex: number) => (
              <BhIconButton icon={faXmark} buttonProps={{ onClick: () => removeRow(rowIndex) }} key={rowIndex} />
            ))}
          </div>
        )}
      </div>
      <BhIconButton icon={faCirclePlus} buttonProps={{ onClick: addRow, disabled }} sizeClasses="w-max">
        {t("FORMBUILDER.FORM.BASE.FIELD.WEATHER.ADD_ROW")}
      </BhIconButton>
    </div>
  );
};

export default FormBaseWeatherField;
