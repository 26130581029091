import React, { FC, useEffect } from "react";
import SignInvitesUnsignedProjectInvitesList from "@/views/home/project/detail/signInvites/SignInvitesUnsignedProjectInvitesList";
import { fetchAllUnsignedInvitesInProjectAsync } from "@/app/store/signInvitesSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { useLocation } from "react-router-dom";
import SignInvitesFilterAndSort from "@/views/home/project/detail/signInvites/SignInvitesFilterAndSort";

const SignInvitesUnsignedProjectInvites: FC = () => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchAllUnsignedInvitesInProjectAsync(projectId));
  }, [location]);

  return (
    <BhScrollableBody header={<SignInvitesFilterAndSort useAllProjectUnsignedInvitesSelector={true} />} keepScrollForKey="signInvitesUnsignedProjectInvitesList">
      <SignInvitesUnsignedProjectInvitesList />
    </BhScrollableBody>
  );
};

export default SignInvitesUnsignedProjectInvites;
