import { IUserInfo } from "@/model/IUserInfo";
import { IUser } from "@/model/IUser";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { IUserAuthoritiesRequest, IUserAuthoritiesResponse } from "@/model/IUserAuthority";
import { EntityId } from "@reduxjs/toolkit";

export function fetchCurrentUserInfo(): Promise<IUserInfo> {
  return bauhubGet("/user/info");
}

export function fetchCurrentUser(): Promise<IUser> {
  return bauhubGet("/user");
}

export function saveUser(user: IUser): Promise<IUser> {
  return bauhubPost("/user/name", user);
}

export function saveCurrentUserLanguage(user: IUser): Promise<IUser> {
  return bauhubPost("/user/lang", { id: user.id, language: user.language });
}

export function saveUserInfo(userInfo: IUserInfo): Promise<IUserInfo> {
  return bauhubPost("/user/info", userInfo);
}

export function saveCurrentUserTwoFactorPreference(twoFactorEnabled: boolean): Promise<IUser> {
  return bauhubPost("/user/2fa/change", { value: twoFactorEnabled });
}

export function removeTwoFactorPhoneNumber(): Promise<IUser> {
  return bauhubPost("/user/phone/remove");
}

export function fetchProjectAdministrators(projectId: EntityId): Promise<Array<ISimpleValidUserAuthority>> {
  return bauhubGet("/project/" + projectId + "/users");
}

export function fetchProjectAdministratorsWithUserInfo(projectId: EntityId): Promise<Array<ISimpleValidUserAuthority>> {
  return bauhubGet("/project/" + projectId + "/users/info");
}

export function removeIdentityCode(): Promise<IUser> {
  return bauhubPost("/user/identitycode/remove");
}

export function removeAuthority(authority: ISimpleValidUserAuthority): Promise<ISimpleValidUserAuthority> {
  return bauhubPost("/project/user/auth/remove", authority);
}

export function fetchCompanyUsers(projectId: EntityId): Promise<Array<ISimpleValidUserAuthority>> {
  return bauhubGet("/project/" + projectId + "/company/users");
}

export function saveUserAuthorities(projectId: EntityId, userAuths: Array<IUserAuthoritiesRequest>): Promise<IUserAuthoritiesResponse> {
  return bauhubPost("/project/" + projectId + "/users/add", userAuths);
}

export function fetchSearchedUsersInProject(projectId: EntityId, query: string): Promise<Array<ISimpleValidUserAuthority>> {
  return bauhubGet("/project/" + projectId + "/users/search", { query: query });
}
