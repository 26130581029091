import { IWorkGroup } from "@/model/IWorkGroup";
import { EntityId } from "@reduxjs/toolkit";

export interface IFileTreeTemplates {
  DRAWINGS: Array<IFileTreeTemplate>;
  DOCUMENTS: Array<IFileTreeTemplate>;
}

export interface IFileTreeTemplate {
  associatedPredefinedWorkGroups?: Array<IWorkGroup>;
  companyId: EntityId;
  created: Date;
  createdBy: string;
  deleted: boolean;
  id: EntityId;
  name: string;
  tree: IFileTree;
  type: FileTreeType;
  updated: Date;
  updatedBy: string;
}

export interface IFileTree {
  children: Array<IFileTree>;
  id: EntityId;
  name: string;
  type: FileTreeType;
  open?: boolean;
}

export enum FileTreeType {
  DOCUMENTS = "DOCUMENTS",
  DRAWINGS = "DRAWINGS",
  DIR = "DIR"
}
