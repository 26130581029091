import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { fetchNewBillingRequisites } from "@/api/billingAPI";
import { INewProjectForm } from "@/model/INewProjectForm";
import { CompanyInvoiceType, IBillingRequisites, IProjectBillingRequisites } from "@/model/IBillingInfo";
import { EntityId } from "@reduxjs/toolkit";
import { Trans, useTranslation } from "react-i18next";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { CompanyStatus } from "@/model/ICompany";
import BillingForm from "@/views/home/project/detail/projectSettings/BillingForm";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { isValidEmails } from "@/utilities/jsUtilities";

interface Props {
  newProjectForm: INewProjectForm;
  setNewProjectForm: Dispatch<SetStateAction<INewProjectForm>>;
  companyId: EntityId;
}

const NewProjectBilling: FC<Props> = ({ newProjectForm, setNewProjectForm, companyId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [billingRequisites, setBillingRequisites] = useState<IBillingRequisites>({} as IBillingRequisites);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchNewBillingRequisites(companyId).then((response) => {
      setBillingRequisites(response);
      if (!newProjectForm.billing) {
        setNewProjectForm({ ...newProjectForm, billing: { ...response.projectBillingRequisites, einvoice: response.companyBillingRequisites.einvoice } });
      }
      setLoading(false);
    });
  }, []);

  const companyHasMonthlyAggregateInvoice = billingRequisites.companyBillingRequisites?.invoiceType === CompanyInvoiceType.AGGREGATE;

  const onProjectBillingRequisiteChange = (changedValue: IProjectBillingRequisites) => {
    if ("customerRecipientEmails" in changedValue) {
      if (!isValidEmails(changedValue.customerRecipientEmails)) {
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.ERROR,
            disappear: true,
            children: t("GLOBAL.EMAIL_INVALID")
          })
        );
      }
    }
    setNewProjectForm((prevState) => {
      return { ...prevState, billing: { ...prevState.billing, ...changedValue } };
    });
  };

  return (
    <div className="w-2/3">
      <h2 className="mb-6">
        <Trans>MODAL.NEW_PROJECT.REQUISITES.TITLE</Trans>
      </h2>
      {!loading && (
        <BillingForm
          billingForm={newProjectForm.billing}
          billingModel={billingRequisites.billingModel}
          companyBillingRequisites={billingRequisites.companyBillingRequisites}
          onChange={onProjectBillingRequisiteChange}
          companyHasMonthlyAggregateInvoice={companyHasMonthlyAggregateInvoice}
          companyHasTrial={company?.status === CompanyStatus.TRIAL}
          dontShowProjectBillingRequisitesType={company?.status === CompanyStatus.TRIAL}
          projectName={newProjectForm.name || ""}
        />
      )}
    </div>
  );
};

export default NewProjectBilling;
