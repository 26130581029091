import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { classNames } from "@/utilities/jsUtilities";
import FileNameLink from "@/features/fileContainer/FileNameLink";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { EntityId } from "@reduxjs/toolkit";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import BhIconButton from "@components/buttons/BhIconButton";
import BhElementWithBadge from "@components/badge/BhElementWithBadge";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import { faMessage } from "@fortawesome/pro-regular-svg-icons/faMessage";
import { ButtonSize } from "@components/buttons/IBhButtonProps";
import BhStatusCellProgress, { BhStatusCellProgressColor } from "@components/status/BhStatusCellProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhDeclineButton from "@components/buttons/BhDeclineButton";
import { useNavigate } from "react-router-dom";
import {
  openCommentsForFileConfirmationFileIdSet,
  selectFileConfirmationFileById,
  selectOpenCommentsForFileConfirmationFileId,
  toggleFileConfirmationFileSelected
} from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import { IFileEntity } from "@/model/files/IFileEntity";
import { FileConfirmationDecisionType, IFileConfirmationDecisionV2 } from "@/model/confirmations/IFileConfirmationDecisionV2";
import { decisionStatusChanged } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import { fetchUrlForFile } from "@/api/fileAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  fileConfirmationFileId: EntityId;
  decision: IFileConfirmationDecisionV2 | undefined;
}

const ConfirmationFileContainerActionsFC: FC<Props> = ({ fileConfirmationFileId, decision }) => {
  const { t } = useTranslation();
  const openCommentFileEntityId = useAppSelector(selectOpenCommentsForFileConfirmationFileId);
  const fileConfirmationFile = useAppSelector((state) => selectFileConfirmationFileById(state, fileConfirmationFileId));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  if (!fileConfirmationFile || !decision) {
    return null;
  }

  const isConfirmed = decision.status === FileConfirmationDecisionType.CONFIRMED;
  const isDeclined = decision.status === FileConfirmationDecisionType.DECLINED;

  return (
    <div
      className={classNames(
        isConfirmed && "bh-bg-success-green-10",
        isDeclined && "bh-bg-error-red-10",
        !isConfirmed && !isDeclined && "hover:bh-bg-mint-30",
        "bh-border-pigeon-40 bh-hover-container group group block flex h-14 w-full items-center overflow-hidden border-b"
      )}
    >
      <div className="flex w-9 flex-none items-center justify-center">
        <input
          className="hover:bh-border-deep-ocean bh-border-pigeon-60 h-5 w-5 rounded focus:ring-0 focus:ring-offset-0"
          type="checkbox"
          checked={fileConfirmationFile.selected}
          onChange={() => {}}
          onClick={() => dispatch(toggleFileConfirmationFileSelected(fileConfirmationFile))}
        />
      </div>
      <div className="relative flex w-11 flex-none justify-center text-center">
        <BhStatusCellProgress progress={fileConfirmationFile.confirmCount} total={fileConfirmationFile.userCount} color={BhStatusCellProgressColor.GREEN} />
      </div>
      <div className="flex flex-1 flex-row items-center space-x-2 overflow-hidden px-3">
        <BhFileCardIcon fileEntity={fileConfirmationFile as unknown as IFileEntity} fileCardSize={FileCardSize.LARGE} />
        <div className="flex-1 overflow-hidden">
          <FileNameLink
            fileEntity={fileConfirmationFile as unknown as IFileEntity}
            onFileNameClickCallback={() =>
              navigate(
                ConfigSingleton.getInstance().getConfig().REACT_APP_HOME +
                  `/project/${fileConfirmationFile.projectId}/confirmation/${fileConfirmationFile.fileConfirmationId}/actions/${fileConfirmationFile.fileEntityId}`
              )
            }
          />
        </div>
      </div>
      <div className="w-18 relative flex-none px-3 text-center">{fileConfirmationFile.revision}</div>
      <div className="w-32 flex-none px-3 text-right">
        <div className="flex flex-row items-center">
          <BhElementWithBadge badge={fileConfirmationFile.commentCount > 0 && <BhBadge type={BhBadgeType.DARK}>{fileConfirmationFile.commentCount}</BhBadge>}>
            <BhTooltip body={t("TASK.COMMENTS")}>
              <BhIconButton
                icon={faMessage}
                buttonProps={{
                  onClick: () => {
                    dispatch(openCommentsForFileConfirmationFileIdSet(fileConfirmationFile?.fileEntityId));
                  }
                }}
                withBackground={openCommentFileEntityId === fileConfirmationFile.fileEntityId}
              />
            </BhTooltip>
          </BhElementWithBadge>
          <BhTooltip body={t("GLOBAL.DOWNLOAD")}>
            <BhIconButton
              icon={faArrowDownToLine}
              buttonProps={{
                onClick: () => {
                  fetchUrlForFile(fileConfirmationFile.fileEntityId, false, true, fileConfirmationFile.uuid).then((presignedUrl) => {
                    imitateUrlDownload(presignedUrl.value);
                  });
                }
              }}
            />
          </BhTooltip>
        </div>
      </div>
      <div className="flex w-28 text-center">
        {!isDeclined && (
          <div className="relative w-full pl-1">
            <BhPrimaryButton
              buttonProps={{
                size: ButtonSize.XL,
                widthClasses: "w-full",
                classes: isConfirmed ? "hover:bh-bg-success-green-20 bh-bg-success-green-10 bh-text-success-green-110 active:bh-bg-success-green-30" : "",
                onClick: () => dispatch(decisionStatusChanged({ ...decision, status: isConfirmed ? FileConfirmationDecisionType.PENDING : FileConfirmationDecisionType.CONFIRMED }))
              }}
            >
              <FontAwesomeIcon icon={faCheck} size={"lg"} />
            </BhPrimaryButton>
          </div>
        )}
      </div>
      <div className="w-28 flex-none text-center">
        {!isConfirmed && (
          <div className="relative w-full px-1">
            <BhDeclineButton
              buttonProps={{
                size: ButtonSize.XL,
                widthClasses: "w-full",
                onClick: () => dispatch(decisionStatusChanged({ ...decision, status: isDeclined ? FileConfirmationDecisionType.PENDING : FileConfirmationDecisionType.DECLINED }))
              }}
            >
              <FontAwesomeIcon icon={faTimes} size={"lg"} />
            </BhDeclineButton>
          </div>
        )}
      </div>
    </div>
  );
};

const ConfirmationFileContainerActions = React.memo(ConfirmationFileContainerActionsFC);
export default ConfirmationFileContainerActions;
