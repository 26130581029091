import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";

export interface Props {
  value?: any;
  textProperty?: string;
  withCheckbox?: boolean;
  onSelect?: Function;
  isSelected?: (value: any) => boolean;
  translateValues?: boolean;
  leadingProperty?: string;
}

const BhDropdownMenuListItem: FC<Props> = ({ value, textProperty, withCheckbox, onSelect, isSelected, translateValues, leadingProperty }) => {
  const { t } = useTranslation();

  const content = textProperty ? (leadingProperty ? value[leadingProperty] + " " + value[textProperty] : value[textProperty]) : typeof value === "string" && value;
  const translatedContent = translateValues ? t(content) : content;

  const onClick: Function = (e: React.MouseEvent) => {
    if (value.function) {
      return value.function();
    }
    return onSelect && onSelect(value);
  };

  const selectedFunctionExistsWithoutCheckbox = withCheckbox !== true && isSelected;

  return (
    <div className="flex flex-row items-center space-x-3 py-1.5 px-4" onClick={(e) => onClick(e)}>
      {selectedFunctionExistsWithoutCheckbox && <div className="w-5">{isSelected(value) && <FontAwesomeIcon icon={faCheck} />}</div>}
      {withCheckbox && <input className="h-4 w-4 rounded focus:ring-0 focus:ring-offset-0" type="checkbox" checked={isSelected && isSelected(value)} onChange={() => {}} />}
      {value.icon && (
        <div className="w-4 text-left">
          <FontAwesomeIcon icon={value.icon} />
        </div>
      )}
      <span className="w-full">{translatedContent}</span>
    </div>
  );
};

export default BhDropdownMenuListItem;
