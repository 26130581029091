import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { IBhDropdownButtonProps } from "@components/dropdown/IBhDropdownButtonProps";

// FIGMA https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H/Bauhub-design-system?node-id=2080%3A112368

const BhDropdownButton: FC<IBhDropdownButtonProps> = ({ title, reversed, value, disabled }) => {
  return (
    <div className="inline-flex items-center">
      {title}
      <button className="bh-text-deep-ocean-imp hover:bh-bg-pigeon-20 disabled:bh-text-pigeon-60 bh-text-pigeon mx-1 whitespace-nowrap rounded px-1 py-0.5 py-1 font-bold" disabled={disabled}>
        {value}
        <FontAwesomeIcon className="pl-2 pr-1" icon={reversed ? faCaretDown : faCaretUp} />
      </button>
    </div>
  );
};

export default BhDropdownButton;
