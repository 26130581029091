import React, { FC, SetStateAction, useEffect, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import BhIconButton from "@components/buttons/BhIconButton";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import FormContainerHeaderNavigateOtherForms from "@/views/home/project/detail/form/FormContainerHeaderNavigateOtherForms";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons/faFilePdf";
import { faMessage } from "@fortawesome/pro-regular-svg-icons/faMessage";
import BhElementWithBadge from "@components/badge/BhElementWithBadge";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import { FormType } from "@/model/IForm";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { selectFileById } from "@/app/store/filesSlice";
import { useTranslation } from "react-i18next";
import { faUnlockKeyhole } from "@fortawesome/pro-regular-svg-icons/faUnlockKeyhole";
import {
  saveFormEditorAsync,
  saveFormLockedAsync,
  selectCurrentFormBase,
  selectCurrentFormEditors,
  selectCurrentFormId,
  selectIsCurrentFormLocked,
  selectIsCurrentUserFormCreator
} from "@/app/store/form/formSlice";
import { faLockKeyhole } from "@fortawesome/pro-solid-svg-icons/faLockKeyhole";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { IUser } from "@/model/IUser";
import { fetchEligibleUsersForFormEdit } from "@/api/form/formAPI";
import { IFormEditor, IFormEditorChangeResponse, IFormEditorInfo } from "@/model/IFormEditor";
import BhChipWithUser from "@components/chips/BhChipWithUser";
import BhTooltip from "@components/BhTooltip";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import BackButtonToDirectoryView from "@/views/home/project/detail/pdftron/PdftronContainerBackButton";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  fileEntityId: EntityId;
  dirId: EntityId;
  formType: FormType;
  commentsOpen: boolean;
  setCommentsOpen: React.Dispatch<SetStateAction<boolean>>;
  isFormBuilderForm: boolean;
}

const FormContainerHeader: FC<Props> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const projectId = useAppSelector(selectCurrentProjectId);
  const fileEntity = useAppSelector((state) => selectFileById(state, props.fileEntityId));
  const currentFormId = useAppSelector(selectCurrentFormId);
  const currentFormBase = useAppSelector(selectCurrentFormBase);
  const currentFormLocked = useAppSelector(selectIsCurrentFormLocked);
  const currentFormEditors = useAppSelector(selectCurrentFormEditors);
  const isCurrentUserProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const isCurrentUserFormCreator = useAppSelector(selectIsCurrentUserFormCreator);
  const isCurrentUserProjectAdminOrFormCreator = isCurrentUserProjectAdmin || isCurrentUserFormCreator;
  const [availableFormEditors, setAvailableFormEditors] = useState([] as Array<IUser>);
  const [formEditors, setFormEditors] = useState([] as Array<IFormEditor>);
  const [formName, setFormName] = useState("");

  useDocumentTitle(fileEntity?.name);

  useEffect(() => {
    if (props.isFormBuilderForm && currentFormBase) {
      const formBuilderFormName = currentFormBase.bauhubFormBase ? t("FORMS." + currentFormBase.type) : currentFormBase.type;
      setFormName(formBuilderFormName);
    } else if (!props.isFormBuilderForm && !currentFormBase && props.formType) {
      const oldFormName = t("FORMS." + props.formType);
      setFormName(oldFormName);
    }
  }, [currentFormBase, props.formType]);

  useEffect(() => {
    if (currentFormLocked) {
      fetchEligibleUsersForFormEdit(projectId, props.fileEntityId).then((users) => {
        setAvailableFormEditors(users);
      });
    }
  }, [currentFormLocked]);

  useEffect(() => {
    if (currentFormEditors && currentFormEditors.length > 0) {
      const formEditorsToSet = currentFormEditors.filter((editor) => !editor.admin && !editor.creator);
      setFormEditors(formEditorsToSet);
    }
  }, [currentFormEditors]);

  const setFormLocked = () => {
    dispatch(saveFormLockedAsync({ projectId: projectId, formId: currentFormId, locked: !currentFormLocked }));
  };

  const saveFormEditor = (formEditorId: EntityId, isNewEditor: boolean) => {
    const formEditorInfo = { formId: currentFormId, formEditorId: formEditorId, isNewEditor: isNewEditor } as IFormEditorInfo;
    dispatch(saveFormEditorAsync({ projectId: projectId, formEditorInfo: formEditorInfo })).then((action) => {
      const formEditorChangeResponse = action.payload as IFormEditorChangeResponse;
      setAvailableFormEditors(formEditorChangeResponse.availableFormEditors);
    });
  };

  const addFormEditor = (user: IUser) => {
    saveFormEditor(user.id, true);
  };

  const removeFormEditor = (user: IUser) => {
    saveFormEditor(user.id, false);
  };

  return (
    <div className="flex flex-col">
      <FormContainerHeaderNavigateOtherForms dirId={props.dirId} fileEntityId={props.fileEntityId} />
      <div className="flex w-full flex-col py-4 px-4">
        <div className="flex w-full flex-row justify-between">
          <div className="flex flex-1 items-center">
            <BackButtonToDirectoryView fileEntityId={props.fileEntityId} parentFileEntityId={props.dirId} projectId={projectId} isForm={true} />
            <span className="l-h-24px text-18px pl-2 font-bold">{formName}</span>
          </div>
          <div className="flex flex-row items-center gap-x-1">
            {currentFormLocked && isCurrentUserProjectAdminOrFormCreator && (
              <BhDropdown
                button={<BhTextOnlyButton icon={faPlus}>{t("FORM.LOCKED.ADD_ACCESS")}</BhTextOnlyButton>}
                menu={<BhDropdownMenu values={availableFormEditors} onSelect={addFormEditor} type={BhDropdownTypeEnum.USER} />}
                position={BhDropdownPositionEnum.BOTTOM_LEFT}
              />
            )}
            {currentFormId && isCurrentUserProjectAdminOrFormCreator && (
              <BhTooltip body={t("FORMS.GLOBAL.LOCK.TOOLTIP")}>
                <BhIconButton icon={currentFormLocked ? faLockKeyhole : faUnlockKeyhole} buttonProps={{ onClick: setFormLocked }} isActive={currentFormLocked} />
              </BhTooltip>
            )}
            {fileEntity && (
              <BhElementWithBadge badge={fileEntity.commentCount > 0 && <BhBadge type={BhBadgeType.DARK}>{fileEntity.commentCount}</BhBadge>}>
                <BhIconButton icon={faMessage} buttonProps={{ onClick: () => props.setCommentsOpen(!props.commentsOpen) }} />
              </BhElementWithBadge>
            )}
            <BhSecondaryButton
              icon={faFilePdf}
              buttonProps={{ onClick: () => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/view/${props.fileEntityId}`) }}
            >
              {t("GLOBAL.AS_PDF")}
            </BhSecondaryButton>
          </div>
        </div>
        {currentFormLocked && formEditors.length > 0 && (
          <div className="mt-1 -mb-2 flex flex-row items-center justify-end">
            {formEditors.map((editor) => {
              return <BhChipWithUser user={editor} key={editor.id} onRemove={removeFormEditor} disabled={!isCurrentUserProjectAdminOrFormCreator} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default FormContainerHeader;
