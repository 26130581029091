import React, { FC, useRef, useState } from "react";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhIconButton from "@components/buttons/BhIconButton";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons/faEllipsisVertical";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhFixedDropdownParent from "@components/dropdown/BhFixedDropdownParent";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { IDropdownItem } from "@/model/IDropdownItem";
import { faReceipt } from "@fortawesome/pro-regular-svg-icons/faReceipt";
import { faClockRotateLeft } from "@fortawesome/pro-regular-svg-icons/faClockRotateLeft";
import BOCompanyProjectLogsModal from "@components/backoffice/company/projects/BOCompanyProjectLogsModal";
import { IProject } from "@/model/IProject";
import BOCompanyProjectBillingModal from "@components/backoffice/company/projects/BOCompanyProjectBillingModal";

interface Props {
  project: IProject;
}

const BOProjectDropdown: FC<Props> = ({ project }) => {
  const [projectLogsModalOpen, setProjectLogsModalOpen] = useState(false);
  const [projectBillingModalOpen, setProjectBillingModalOpen] = useState(false);
  const dropdownButtonRef = useRef(null);
  const actionsMap: Record<string, IDropdownItem> = {
    PROJECT_BILLING: {
      text: "Billing",
      function: () => setProjectBillingModalOpen(true),
      icon: faReceipt
    },
    PROJECT_LOGS: {
      text: "Logs",
      function: () => setProjectLogsModalOpen(true),
      icon: faClockRotateLeft
    }
  };

  const dropdownActions = [actionsMap.PROJECT_BILLING, actionsMap.PROJECT_LOGS];

  return (
    <>
      <BhDropdown
        button={<BhIconButton buttonProps={{ buttonRef: dropdownButtonRef }} icon={faEllipsisVertical} />}
        position={BhDropdownPositionEnum.BOTTOM_LEFT}
        menu={
          <BhFixedDropdownParent dropdownPosition={BhDropdownPositionEnum.BOTTOM_LEFT} parentRef={dropdownButtonRef}>
            <BhDropdownMenu values={dropdownActions} type={BhDropdownTypeEnum.STRING} textProperty="text" />
          </BhFixedDropdownParent>
        }
      />
      {projectLogsModalOpen && <BOCompanyProjectLogsModal setIsShown={setProjectLogsModalOpen} project={project} />}
      {projectBillingModalOpen && <BOCompanyProjectBillingModal setIsShown={setProjectBillingModalOpen} project={project} />}
    </>
  );
};

export default BOProjectDropdown;
