import React, { Dispatch, FC, SetStateAction } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectTaskCopyModalOpen, selectTaskMoveModalOpen, setTaskModalsOpen } from "@/app/store/tasksSlice";
import TaskCopyModal from "@/views/home/project/detail/task/modals/TaskCopyModal";
import { ITask } from "@/model/taskBoard/ITask";
import TaskDeleteModal from "@/views/home/project/detail/task/modals/TaskDeleteModal";
import TaskMoveModalContainer from "@/views/home/project/detail/task/modals/TaskMoveModalContainer";

interface Props {
  task: ITask;
  deleteConfirmationModalOpen: boolean;
  setDeleteConfirmationModalOpen: Dispatch<SetStateAction<boolean>>;
  isDedicatedView?: boolean;
}

const TaskModals: FC<Props> = ({ task, deleteConfirmationModalOpen, setDeleteConfirmationModalOpen, isDedicatedView }) => {
  const taskCopyModalOpen = useAppSelector(selectTaskCopyModalOpen);
  const taskMoveModalOpen = useAppSelector(selectTaskMoveModalOpen);
  const dispatch = useAppDispatch();

  return (
    <>
      {taskCopyModalOpen && <TaskCopyModal task={task} />}
      {taskMoveModalOpen && <TaskMoveModalContainer task={task} isDedicatedView={isDedicatedView} closeModal={() => dispatch(setTaskModalsOpen({ modal: "taskMoveModal" }))} />}
      {deleteConfirmationModalOpen && <TaskDeleteModal task={task} setDeleteConfirmationModalOpen={setDeleteConfirmationModalOpen} isDedicatedView={isDedicatedView} />}
    </>
  );
};

export default TaskModals;
