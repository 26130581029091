import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { setNotificationSeenAsync } from "@/app/store/userNotificationsSlice";
import { useAppDispatch } from "@/app/hooks";
import { IUserNotification } from "@/model/IUserNotification";

interface Props {
  notification: IUserNotification;
}

const SeenIndicator: FC<Props> = ({ notification }) => {
  const dispatch = useAppDispatch();
  const classes = classNames(
    "w-3.5 h-3.5 rounded-full border bh-border-raised-white",
    notification.seen && "border bh-border-pigeon-60 bh-bg-white",
    !notification.seen && "bh-bg-bauhub-green-120 cursor-pointer"
  );

  return <div className={classes} onClick={() => !notification.seen && dispatch(setNotificationSeenAsync(notification))}></div>;
};

export default SeenIndicator;
