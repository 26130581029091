import React, { FC } from "react";
import { ITask } from "@/model/taskBoard/ITask";
import { useAppSelector } from "@/app/hooks";
import { selectTaskById } from "@/app/store/tasksSlice";
import { BhSectionMessageWarning } from "@components/sectionMessage/BhSectionMessages";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";

interface Props {
  task: ITask;
  saveTaskCallback: Function;
}

const FormTaskNotification: FC<Props> = ({ task, saveTaskCallback }) => {
  const { t } = useTranslation();
  const taskBoardTask = useAppSelector((state) => selectTaskById(state, task.id));
  const taskBoardTaskHasBeenUpdated = taskBoardTask && new Date(taskBoardTask.updated) > new Date(task.updated);
  const commentsAddedOrRemoved = taskBoardTask && taskBoardTask.comments && task.comments && taskBoardTask.comments.length !== task.comments.length;
  const tagsAddedOrRemoved = taskBoardTask && taskBoardTask.tags && task.tags && taskBoardTask.tags.length !== task.tags.length;
  const taskBoardTaskHasBeenChanged = taskBoardTaskHasBeenUpdated || commentsAddedOrRemoved || tagsAddedOrRemoved;

  const updateTask = () => {
    saveTaskCallback(taskBoardTask);
  };

  const [showUpdateTaskConfirmationModal, hideUpdateTaskConfirmationModal] = useModal(
    () => (
      <BhDeleteConfirmationModal
        setIsOpen={hideUpdateTaskConfirmationModal}
        header={<h2>{t("TASK.UPDATE_TASK.MODAL.HEADER")}</h2>}
        body={<div>{t("TASK.UPDATE_TASK.MODAL.BODY")}</div>}
        confirmationButtonText={t("TASK.UPDATE_TASK.MODAL.CONFIRMATION")}
        handleDelete={() => {
          updateTask();
          hideUpdateTaskConfirmationModal();
        }}
      />
    ),
    [taskBoardTask]
  );

  if (!taskBoardTaskHasBeenChanged) return null;

  return (
    <div className="pb-4">
      <BhSectionMessageWarning buttonLabel={t("TASK.UPDATE_FORM_TASK") as string} onClick={showUpdateTaskConfirmationModal}>
        {t("TASK.UPDATED_ON_TASKBOARD")}
      </BhSectionMessageWarning>
    </div>
  );
};

export default FormTaskNotification;
