import React, { FC, useState } from "react";
import BhPigeon20Separator from "@components/separator/BhPigeon20Separator";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhDeclineButton from "@components/buttons/BhDeclineButton";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import { ICompanyModule, IEnabledForm } from "@/model/ICompany";
import { IChecklistBase } from "@/model/checklist/IChecklistBase";
import { Module } from "@/model/IProject";
import BOCompanyEditModuleModal from "@components/backoffice/company/modulesAndTemplates/BOCompanyEditModuleModal";
import { useAppDispatch } from "@/app/hooks";
import { boSaveCompanyModuleAdminSettingsAsync } from "@/app/store/backofficeSlice";
import BOCompanyProjectCodesModal from "@components/backoffice/company/modulesAndTemplates/BOCompanyProjectCodesModal";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { IFormBase } from "@/model/form/IFormBase";

interface Props {
  template: IEnabledForm | ICompanyModule | IChecklistBase | IFormBase;
  nameProperty: string;
  removeCallback: Function;
  label: React.ReactNode;
}

const BOCompanyTemplateRow: FC<Props> = ({ template, removeCallback, nameProperty, label }) => {
  const propertyValue = (template as any)[nameProperty];
  const [companyNameModuleModalOpen, setCompanyNameModuleModalOpen] = useState(false);
  const [customFormNamesModalOpen, setCustomFormNamesModalOpen] = useState(false);
  const [projectCodesModalOpen, setProjectCodesModalOpen] = useState(false);
  const isCompanyNameModule = propertyValue === Module.COMPANY_NAME;
  const isCustomFormNamesModule = propertyValue === Module.CUSTOM_FORM_NAMES;
  const isProjectCodesModal = propertyValue === Module.TRESOOR_BUDGET || propertyValue === Module.TRESOOR_BUDGET_V2 || propertyValue === Module.RAPID_BUDGET;
  const dispatch = useAppDispatch();
  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteConfirmationModal}
      header={<h2>Delete template</h2>}
      body={`Are you sure you want to delete ${label}?`}
      confirmationButtonText="Delete"
      cancelButtonText="Cancel"
      handleDelete={handleRemove}
    />
  ));

  const handleTemplateModalOpen = () => {
    if (isCompanyNameModule) setCompanyNameModuleModalOpen(true);
    if (isCustomFormNamesModule) setCustomFormNamesModalOpen(true);
  };

  const onEditSave = (newModule: ICompanyModule) => {
    dispatch(boSaveCompanyModuleAdminSettingsAsync(newModule)).then(() => {
      setCustomFormNamesModalOpen(false);
      setCompanyNameModuleModalOpen(false);
    });
  };

  const handleRemove = () => {
    removeCallback(template);
  };

  return (
    <>
      <div>
        <BhPigeon20Separator classes="my-2" />
        <div className="flex w-full items-center justify-between pl-3">
          {label}
          <div className="flex items-center gap-4">
            {(isCompanyNameModule || isCustomFormNamesModule) && <BhTextOnlyButton buttonProps={{ onClick: () => handleTemplateModalOpen() }}>Edit</BhTextOnlyButton>}
            {isProjectCodesModal && <BhTextOnlyButton buttonProps={{ onClick: () => setProjectCodesModalOpen(true) }}>Project codes</BhTextOnlyButton>}
            <BhDeclineButton icon={faCircleMinus} buttonProps={{ widthClasses: "w-max", onClick: showDeleteConfirmationModal }} />
          </div>
        </div>
      </div>
      {companyNameModuleModalOpen && (
        <BOCompanyEditModuleModal setIsShown={setCompanyNameModuleModalOpen} module={template as ICompanyModule} onSaveCallback={onEditSave} property="name" label="Company name UPPERCASE" />
      )}
      {customFormNamesModalOpen && (
        <BOCompanyEditModuleModal
          setIsShown={setCustomFormNamesModalOpen}
          module={template as ICompanyModule}
          onSaveCallback={onEditSave}
          property="nameTitleSuffix"
          label="Prefix added to form titles"
        />
      )}
      {projectCodesModalOpen && <BOCompanyProjectCodesModal setIsShown={setProjectCodesModalOpen} module={template as ICompanyModule} />}
    </>
  );
};

export default BOCompanyTemplateRow;
