import React, { FC } from "react";
import { IFormBase } from "@/model/form/IFormBase";
import { saveCompanyFormBaseAsync, selectCompanyFormBaseById } from "@/app/store/form/formBasesSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { useTranslation } from "react-i18next";
import FormBaseInfoRow from "@components/form/base/body/formBaseInfo/FormBaseInfoRow";

interface Props {
  companyId: EntityId;
  formBaseId: EntityId;
}

const FormBaseInfoBaseNameRow: FC<Props> = ({ companyId, formBaseId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formBase = useAppSelector((state) => selectCompanyFormBaseById(state, formBaseId));

  if (!formBase) return null;

  const saveFormBaseType = (changedObject: IFormBase) => {
    if (!changedObject.type || changedObject.type.toLowerCase() === formBase.type.toLowerCase()) {
      return;
    }
    saveFormBase(changedObject);
  };

  const saveFormBase = (changedObject: IFormBase) => {
    const formBaseToSave = { ...formBase, ...changedObject };
    dispatch(saveCompanyFormBaseAsync({ companyId: companyId, formBase: formBaseToSave }));
  };

  return (
    <FormBaseInfoRow
      title={t("FORMBUILDER.FORM.BASE.HEADER.NAME")}
      helperText={t("FORMBUILDER.FORM.BASE.HEADER.NAME_DESCRIPTION")}
      children={
        <div className="w-full">
          <BhInputStackedLabel
            initialValue={formBase.bauhubFormBase ? t("FORMS." + formBase.type) : formBase.type}
            property={"type"}
            label={t("FORMBUILDER.FORM.BASE.HEADER.NAME_LABEL")}
            disabled={formBase.bauhubFormBase}
            onBlurAction={saveFormBaseType}
          />
        </div>
      }
    />
  );
};

export default FormBaseInfoBaseNameRow;
