import React, { Dispatch, useEffect } from "react";
import { math, Viewer } from "@xeokit/xeokit-sdk";
import BhIconButton from "@components/buttons/BhIconButton";
import { ReactComponent as TwoDIcon } from "@icons/two-d.svg";
import XeokitMobileMoreToolsModalElement from "@/views/home/project/detail/xeokit/mobile/modals/XeokitMobileMoreToolsModalElement";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUserLanguageForTranslation } from "@/app/store/userSlice";

interface Props {
  toolActive: boolean;
  setToolActive: Dispatch<boolean>;
  orthoActive: boolean;
  firstPersonActive: boolean;
  closeFloorplan: Function;
  disabled?: boolean;
  viewer?: Viewer;
  isMobile?: boolean;
}

const Xeokit2DTool = React.memo<Props>(({ toolActive, setToolActive, orthoActive, firstPersonActive, closeFloorplan, disabled, viewer, isMobile }) => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguageForTranslation);

  useEffect(() => {
    if (!viewer) return;

    if (toolActive) {
      exitThreeDMode();
    }

    viewer.on("reset", () => {
      enterThreeDMode();
      setToolActive(true);
    });
  }, [viewer]);

  const enterThreeDMode = () => {
    if (!viewer) return;

    const scene = viewer.scene;
    const aabb = scene.getAABB(scene.visibleObjectIds);
    const diag = math.getAABB3Diag(aabb);
    const tempVec3a = math.vec3();
    // @ts-ignore
    const center = math.getAABB3Center(aabb, tempVec3a);
    const dist = Math.abs(diag / Math.tan(65.0 / 2)); // TODO: fovy match with CameraFlight
    const camera = scene.camera;
    const dir = camera.yUp ? [-1, -1, -1] : [1, 1, 1];
    const up = camera.yUp ? [-1, 1, -1] : [-1, 1, 1];

    viewer.cameraControl.pivotPos = center;

    viewer.cameraFlight.flyTo({
      look: center,
      eye: [center[0] - dist * dir[0], center[1] - dist * dir[1], center[2] - dist * dir[2]],
      up: up,
      orthoScale: diag * 1.3,
      duration: 1,
      projection: orthoActive ? "ortho" : "perspective"
    });

    viewer.cameraControl.navMode = firstPersonActive ? "firstPerson" : "orbit";
  };

  const exitThreeDMode = () => {
    if (!viewer) return;
    closeFloorplan();

    const scene = viewer.scene;
    const camera = scene.camera;
    const aabb = scene.getAABB(scene.visibleObjectIds);
    const look2 = math.getAABB3Center(aabb);
    const diag = math.getAABB3Diag(aabb);
    const fitFOV = 45; // fitFOV;
    const sca = Math.abs(diag / Math.tan(fitFOV * math.DEGTORAD));
    const orthoScale2 = diag * 1.3;
    const tempVec3a = math.vec3();
    const eye2 = tempVec3a;

    eye2[0] = look2[0] + camera.worldUp[0] * sca;
    eye2[1] = look2[1] + camera.worldUp[1] * sca;
    eye2[2] = look2[2] + camera.worldUp[2] * sca;

    const up2 = math.mulVec3Scalar(camera.worldForward, -1, []);
    viewer.cameraFlight.flyTo({
      eye: eye2,
      look: look2,
      up: up2,
      orthoScale: orthoScale2,
      projection: "ortho"
    });

    viewer.cameraControl.navMode = "planView";
  };

  const toggleThreeDActive = () => {
    if (!viewer) return;

    const newThreeDActive = toolActive;

    if (newThreeDActive) {
      enterThreeDMode();
    } else {
      exitThreeDMode();
    }
    setToolActive(newThreeDActive);
  };

  if (isMobile) {
    return (
      <XeokitMobileMoreToolsModalElement icon={<TwoDIcon />} callback={toggleThreeDActive} isActive={toolActive}>
        {t("BIM.TOOL.2D", { lng: currentUserLanguage })}
      </XeokitMobileMoreToolsModalElement>
    );
  }

  return (
    <BhTooltip body={t("BIM.TOOL.2D")}>
      <BhIconButton buttonProps={{ onClick: toggleThreeDActive }} isActive={toolActive}>
        <TwoDIcon />
      </BhIconButton>
    </BhTooltip>
  );
});

export default Xeokit2DTool;
