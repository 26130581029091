import React, { Dispatch, FC, SetStateAction } from "react";
import { IFolderFileEntity } from "@/model/files/IFileEntity";
import DirectorySelectionModal from "@/views/home/project/detail/directory/directoryModals/DirectorySelectionModal";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId, selectRootDirectoryId, selectRootDocumentDirectoryId } from "@/app/store/project/projectSlice";
import { selectAnyOlderRoundIdsSelected, selectSelectedChecklistRoundIds } from "@/app/store/checklist/checklistsSlice";
import { fullPageLoadingSet } from "@/app/store/globalSlice";
import { bulkCopyChecklistRoundsPdfToDirectories } from "@/api/checklist/checklistAPI";

interface Props {
  setIsShown: Function;
  copyAsRevisions: boolean;
  setCopyAsRevisions: Dispatch<SetStateAction<boolean>>;
}

const ChecklistBulkCopyDirSelectionModal: FC<Props> = ({ setIsShown, copyAsRevisions, setCopyAsRevisions }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const fileRootDirectoryId = useAppSelector(selectRootDirectoryId);
  const documentRootDirectoryId = useAppSelector(selectRootDocumentDirectoryId);
  const selectedChecklistRoundIds = useAppSelector(selectSelectedChecklistRoundIds);
  const anyOlderRoundSelected = useAppSelector(selectAnyOlderRoundIdsSelected);
  const directorySelectionModalIds = [fileRootDirectoryId, documentRootDirectoryId];
  const dispatch = useAppDispatch();

  const handleModalSubmit = (selectedFolderFileEntities: Array<IFolderFileEntity>) => {
    dispatch(fullPageLoadingSet(true));
    const selectedFolderIds = selectedFolderFileEntities.map((file) => file.id);
    if (selectedFolderIds.length > 0) {
      bulkCopyChecklistRoundsPdfToDirectories(projectId, {
        checklistRoundIds: selectedChecklistRoundIds,
        directoryIds: selectedFolderIds,
        copyOnlyLastRound: !anyOlderRoundSelected && !copyAsRevisions,
        copyRoundsAsRevisions: copyAsRevisions
      }).then(() => dispatch(fullPageLoadingSet(false)));
    }
    setIsShown();
    setCopyAsRevisions(false);
  };

  return (
    <DirectorySelectionModal
      directoryIds={directorySelectionModalIds}
      modalHeader={t("MODAL.CHANGE_DIR.CHOOSE_DIR")}
      onModalSubmit={(selectedFolderFileEntities: Array<IFolderFileEntity>) => handleModalSubmit(selectedFolderFileEntities)}
      onModalClose={setIsShown}
      selectOnlyOne={false}
    />
  );
};

export default ChecklistBulkCopyDirSelectionModal;
