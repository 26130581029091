import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";

interface Props {
  email: string;
  onRemove?: Function;
}

const BhChipWithUser: FC<Props> = ({ email, onRemove }) => {
  const removeUser = () => {
    onRemove && onRemove(email);
  };

  return (
    <div className="bh-bg-pigeon-40 bh-text-deep-ocean l-h-14px my-0.5 mr-1 inline-block flex flex-row items-center rounded-2xl px-1 py-1.5 pl-2 font-bold">
      <span>{email}</span>
      <div className="hover:bh-bg-deep-ocean hover:bh-text-white ml-0.5 mr-1 inline-block h-4 w-4 cursor-pointer rounded-full text-center" onClick={removeUser}>
        <FontAwesomeIcon icon={faXmark} size="sm" className="h-3 w-3 align-middle" />
      </div>
    </div>
  );
};

export default React.memo(BhChipWithUser);
