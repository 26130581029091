import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectPinnedProjectIds } from "@/app/store/companiesSlice";
import ProjectListItem from "@/features/projectList/projectListItem/ProjectListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faBookmark } from "@fortawesome/pro-solid-svg-icons/faBookmark";

const ProjectPinnedContainer: FC = () => {
  const { t } = useTranslation();
  const pinnedProjectIds = useAppSelector(selectPinnedProjectIds);

  const showPinnedProjectsContainer = pinnedProjectIds.length > 0;

  return (
    <>
      {showPinnedProjectsContainer && (
        <div className="bh-bg-smoke bh-border-smoke -mx-3 rounded px-3 pb-3 pt-2">
          <div className="bh-text-deep-ocean inline-flex items-center">
            <FontAwesomeIcon className="p-2" icon={faBookmark} />
            <h3 className="ml-2 py-2">{t("FAVOURITE.FOLDERS")}</h3>
          </div>
          <div className="bh-bg-white rounded">
            {pinnedProjectIds.map((projectId) => {
              return projectId && <ProjectListItem key={projectId} projectId={projectId} />;
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectPinnedContainer;
