import { EntityId } from "@reduxjs/toolkit";

export interface IFileConfirmationDecisionV2 {
  id: EntityId;
  fileEntityId: EntityId;
  userEntityId: EntityId;
  fileConfirmationId: EntityId;
  orderNumber: number;
  status: FileConfirmationDecisionType;
  nextInLine: boolean;
  previousUserDeclined: boolean;
  deleted: boolean;
}

export enum FileConfirmationDecisionType {
  PENDING = "PENDING",
  DECLINED = "DECLINED",
  CONFIRMED = "CONFIRMED"
}

export interface IFileConfirmationV2DecisionDTO {
  decisionId: EntityId;
  status: FileConfirmationDecisionType;
}
