import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import BhTableRow from "@components/table/BhTableRow";
import { selectCompanyUserOrInviteeByUsername } from "@/app/store/companiesSlice";
import CompanyUserRowEndContainer from "@/views/home/company/detail/companySettingsUsers/CompanyUserRowEndContainer";
import UserOrInviteeIconWithName from "@/views/home/company/detail/companySettingsUsers/UserOrInviteeIconWithName";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  companyId: EntityId;
  username: string;
  userExists: boolean;
}

const CompanyProjectUserRow: FC<Props> = React.memo(({ companyId, username, userExists }) => {
  const companyUser = useAppSelector((state) => selectCompanyUserOrInviteeByUsername(state, companyId, username, userExists));

  if (!companyUser) return null;

  return (
    <>
      <BhTableRow classes="w-full border-b bh-border-pigeon-40">
        <td className="w-2/5 min-w-0 truncate">
          <UserOrInviteeIconWithName username={username} companyId={companyId} userExists={userExists} />
        </td>
        <td className="w-72 min-w-0 flex-grow truncate">
          <a className="bh-underline-link" href={"mailto:" + companyUser.username}>
            {companyUser.username}
          </a>
        </td>
        <td className="flex-grow-0">
          <CompanyUserRowEndContainer companyUser={companyUser} />
        </td>
      </BhTableRow>
    </>
  );
});

export default CompanyProjectUserRow;
