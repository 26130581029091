import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { uploadFilesAsync, uploadRevisionFilesAsync } from "@/app/store/uploadSlice";
import { selectBimDirectoryId, selectCurrentProjectId } from "@/app/store/project/projectSlice";
import DirectoryFileListContainer from "@/views/home/project/detail/directory/DirectoryFileListContainer";
import BhDirectoryDropFileContainer from "@components/upload/BhDirectoryDropFileContainer";
import { IAuthorityBoolean } from "@/model/IUser";
import { fullPageLoadingSet, toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { useTranslation } from "react-i18next";
import { IFileEntity, IFileMatch, IRevisionMatcherFileEntity } from "@/model/files/IFileEntity";
import { replaceLatinLetters } from "@/utilities/jsUtilities";
import RevisionModal from "@/views/home/project/detail/directory/directoryModals/revisionModal/RevisionModal";
import { useModal } from "react-modal-hook";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhModal from "@components/modal/BhModal";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhLargeText from "@components/text/BhLargeText";
import { createFolderHierarchyAsync } from "@/app/store/filesSlice";
import { IFolderHierarchyDTO } from "@/model/files/IFolderHierarchyDTO";
import { canOpenInXeokit } from "@/utilities/fileEntity/fileEntityUtilities";

interface Props {
  currentDirId: EntityId;
  privilegesForDirectory: IAuthorityBoolean;
}

const DirectoryFileListContainerWithUploadDrop: FC<Props> = ({ currentDirId, privilegesForDirectory }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();
  const projectBimDirectoryId = useAppSelector(selectBimDirectoryId);
  const isCurrentDirectoryBimDir = projectBimDirectoryId === currentDirId;

  const [showRevisionModal, setShowRevisionModal] = useState(false);
  const [filesToUploadWithSameNames, setFilesToUploadWithSameNames] = useState([] as Array<File>);
  const [filesToUploadForRevisionModal, setFilesToUploadForRevisionModal] = useState([] as Array<File | IRevisionMatcherFileEntity>);

  const [showSameNameExistsModal, hideSameNameExistsModal] = useModal(
    () => (
      <BhModal
        isShown={true}
        size="xl"
        setIsShown={hideSameNameExistsModal}
        header={<h2>{t("MULTIPLE_DUPLICATES.MODAL.HEADER")}</h2>}
        children={
          <div className="p-8">
            <BhLargeText>{t("MULTIPLE_DUPLICATES.MODAL.BODY")}</BhLargeText>
            <div>
              <ul className="list-disc pl-3">{filesToUploadWithSameNames && filesToUploadWithSameNames.map((file) => <li key={file.name}>{file?.name}</li>)}</ul>
            </div>
          </div>
        }
        footer={
          <div className="flex flex-row flex-wrap items-center">
            <BhTextOnlyButton
              buttonProps={{
                onClick: () => {
                  hideSameNameExistsModal();
                  setFilesToUploadForRevisionModal([]);
                }
              }}
            >
              {t("MULTIPLE_DUPLICATES.MODAL.CANCEL")}
            </BhTextOnlyButton>
            <BhPrimaryButton
              buttonProps={{
                onClick: () => {
                  hideSameNameExistsModal();
                  doUploadFiles(filesToUploadForRevisionModal.map((file) => ({ file: file, directoryToUploadId: currentDirId, projectId: projectId })));
                }
              }}
            >
              {t("MULTIPLE_DUPLICATES.MODAL.UPLOAD_SEPARATELY")}
            </BhPrimaryButton>
            <BhPrimaryButton
              buttonProps={{
                onClick: () => {
                  hideSameNameExistsModal();
                  let filesToMatch = [] as Array<IRevisionMatcherFileEntity>;
                  for (let i = 0; i < filesToUploadForRevisionModal.length; ++i) {
                    const file = filesToUploadForRevisionModal[i];
                    filesToMatch.push({ name: file.name, uuid: uuidv4(), revision: 1, fileToUpload: file } as IRevisionMatcherFileEntity);
                  }
                  setFilesToUploadForRevisionModal(filesToMatch);
                  setShowRevisionModal(true);
                }
              }}
            >
              {t("DUPLICATE.NAME.ADD_REVISION")}
            </BhPrimaryButton>
          </div>
        }
      />
    ),
    [filesToUploadForRevisionModal, filesToUploadWithSameNames, currentDirId]
  );

  const doUploadFiles = async (fileUploadDTO: Array<{ file: any; directoryToUploadId: EntityId; projectId: EntityId }>) => {
    if (!fileUploadDTO) return;

    dispatch(uploadFilesAsync(fileUploadDTO));
  };

  const maintainFolderStructureOnUpload = (files: Array<any>) => {
    window.onbeforeunload = function() {
      return "Folder uploading in progress";
    };

    let paths: Array<string> = [];

    for (let i = 0; i < files.length; ++i) {
      let file = files[i];
      if (file.size > 0 && file.name !== ".DS_Store") {
        let path = file.path
          .replaceAll(String.fromCharCode(111, 776), "ö")
          .replaceAll(String.fromCharCode(97, 776), "ä")
          .replaceAll(String.fromCharCode(117, 776), "ü")
          .replaceAll(String.fromCharCode(111, 771), "õ")
          .split("/")
          .filter((pathPart: string) => pathPart && pathPart.length !== 0);
        path.pop();
        const pathWithoutFileName = path.join("/");
        file.pathWithoutFileName = pathWithoutFileName;

        let pathElementIndex = 1;
        for (let i = 0; i < path.length; ++i) {
          const pathStringArray = path.slice(0, pathElementIndex);
          const pathStringArrayJoined = pathStringArray.join("/");
          if (!paths.some((p) => p === pathStringArrayJoined)) {
            paths.push(pathStringArrayJoined);
          }
          pathElementIndex++;
        }
      }
    }

    dispatch(fullPageLoadingSet(true));
    dispatch(createFolderHierarchyAsync({ directoryId: currentDirId, paths }))
      .then((action) => {
        let fileUploadDTO: Array<{ file: any; directoryToUploadId: EntityId; projectId: EntityId }> = [];
        const createdFolderDTOs = action.payload as Array<IFolderHierarchyDTO>;
        createdFolderDTOs.forEach((createdFolderDTO) => {
          const filesInDirectory = files.filter((file) => file.pathWithoutFileName === createdFolderDTO.path);
          if (filesInDirectory.length > 0) {
            fileUploadDTO = fileUploadDTO.concat(filesInDirectory.map((file) => ({ file: file, directoryToUploadId: createdFolderDTO.folderFileEntity.id, projectId: projectId })));
          }
        });
        doUploadFiles(fileUploadDTO);
        window.onbeforeunload = null;
      })
      .finally(() => {
        dispatch(fullPageLoadingSet(false));
      });
  };

  const onFilesDrop = (files: Array<any>, filesInDirectory: Array<IFileEntity>) => {
    if (!privilegesForDirectory.isWrite) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          translateCode: "TOAST.NO_UPLOAD_AUTH"
        })
      );
      return;
    }

    if (isCurrentDirectoryBimDir) {
      const allFilesSupportedByXeokit = files
        .map((file) => {
          return { name: file.name } as IFileEntity;
        })
        .every((f) => canOpenInXeokit(f));
      if (!allFilesSupportedByXeokit) {
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.ERROR,
            disappear: true,
            translateCode: "BIM.FILES.NOT_SUPPORTED"
          })
        );
        return;
      }
    }

    if (!files || !files.length) return null;

    const filesInsideFolders = files.filter((file) => file.path !== file.name);
    const filesNotInsideFolders = files.filter((file) => file.path === file.name);

    const filesWithSizeNotZero = filesNotInsideFolders.filter((file) => file.size > 0);
    const filesWithSameNameFileInDirectory = filesWithSizeNotZero.filter((fileToUpload) =>
      filesInDirectory.some((fileInDirectory) => fileInDirectory.name?.toLowerCase() === replaceLatinLetters(fileToUpload.name).toLowerCase())
    );

    if (filesInsideFolders.length > 0) {
      maintainFolderStructureOnUpload(filesInsideFolders);
    }

    if (filesWithSameNameFileInDirectory.length > 0) {
      showSameNameExistsModal();
      setFilesToUploadWithSameNames(filesWithSameNameFileInDirectory);
      setFilesToUploadForRevisionModal(filesWithSizeNotZero);
    } else {
      doUploadFiles(filesWithSizeNotZero.map((file) => ({ file: file, directoryToUploadId: currentDirId, projectId: projectId })));
    }
  };

  const onSubmit = (fileMatches: Array<IFileMatch>, unusedFiles: Array<IRevisionMatcherFileEntity>) => {
    dispatch(uploadRevisionFilesAsync({ fileMatches, unusedFiles, projectId, currentDirId }));

    setShowRevisionModal(false);
    setFilesToUploadForRevisionModal([]);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex-1 overflow-y-auto">
        <BhDirectoryDropFileContainer onDrop={onFilesDrop}>
          <DirectoryFileListContainer currentDirId={currentDirId} privilegesForDirectory={privilegesForDirectory} />
        </BhDirectoryDropFileContainer>
        {showRevisionModal && filesToUploadForRevisionModal.length > 0 && (
          <RevisionModal
            onClose={() => {
              setShowRevisionModal(false);
              setFilesToUploadForRevisionModal([]);
            }}
            onSubmit={onSubmit}
            files={filesToUploadForRevisionModal as Array<IRevisionMatcherFileEntity>}
            directoryId={currentDirId}
          />
        )}
      </div>
    </DndProvider>
  );
};

export default DirectoryFileListContainerWithUploadDrop;
