import { createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { BauhubBannerType, ProjectSecurityLevel } from "@/model/IProject";
import { ReactNode } from "react";

export interface IToastFlag {
  id: EntityId;
  type: BauhubBannerType;
  disappear?: boolean;
  children?: ReactNode;
  params?: any;
  translateCode?: string;
  translate?: boolean;
  navigateTo?: string;
}

export interface ISecurityDetails {
  projectId: EntityId;
  projectName: string;
  projectSecurityLevel: ProjectSecurityLevel;
  userHasIdentityCode: boolean;
  userHasPhoneNumber: boolean;
  userRequiresLogout: boolean;
}

export interface IGlobalState {
  showSuccessToast: boolean;
  toastFlags: Array<IToastFlag>;
  fullPageLoading: boolean;
  securityTooLow: { showModal: boolean; details: ISecurityDetails | undefined };
  securityTooLowSync: { showModal: boolean; details: ISecurityDetails | undefined };
  fetchRequests: number;
}

const initialState: IGlobalState = {
  showSuccessToast: false,
  toastFlags: [],
  fullPageLoading: false,
  securityTooLow: { showModal: false, details: undefined },
  securityTooLowSync: { showModal: false, details: undefined },
  fetchRequests: 0
};

const globalSlice = createSlice({
  name: "global",
  initialState: initialState,
  reducers: {
    successToastShown: (state, action: PayloadAction<boolean>) => {
      state.showSuccessToast = action.payload;
    },
    toastFlagAdded: (state, action: PayloadAction<IToastFlag>) => {
      state.toastFlags = [...state.toastFlags, action.payload];
    },
    toastFlagRemoved: (state, action: PayloadAction<IToastFlag>) => {
      state.toastFlags = state.toastFlags.filter((t) => {
        return t.id !== action.payload.id;
      });
    },
    fullPageLoadingSet: (state, action: PayloadAction<boolean>) => {
      state.fullPageLoading = action.payload;
    },
    showSecurityTooLowModalSet: (state, action: PayloadAction<boolean>) => {
      state.securityTooLow.showModal = action.payload;
    },
    showSecurityTooLowSyncModalSet: (state, action: PayloadAction<boolean>) => {
      state.securityTooLowSync.showModal = action.payload;
    },
    securityTooLowDetailsSet: (state, action: PayloadAction<ISecurityDetails>) => {
      const val = action.payload;
      const parsedValues: ISecurityDetails = {
        ...val,
        // @ts-ignore
        userHasPhoneNumber: val.userHasPhoneNumber === "true",
        // @ts-ignore
        userHasIdentityCode: val.userHasIdentityCode === "true",
        // @ts-ignore
        userRequiresLogout: val.userRequiresLogout === "true"
      };
      state.securityTooLow.details = parsedValues;
    },
    fetchRequestStarted: (state) => {
      state.fetchRequests = state.fetchRequests + 1;
    },
    fetchRequestEnded: (state) => {
      if (state.fetchRequests > 0) {
        state.fetchRequests = state.fetchRequests - 1;
      }
    }
  }
});

export const { successToastShown, toastFlagAdded, toastFlagRemoved, fullPageLoadingSet, showSecurityTooLowModalSet, showSecurityTooLowSyncModalSet, securityTooLowDetailsSet, fetchRequestStarted, fetchRequestEnded } =
  globalSlice.actions;

export const selectShowSuccessToast = (state: RootState) => state.global.showSuccessToast;
export const selectToastFlags = (state: RootState) => state.global.toastFlags;
export const selectFullPageLoading = (state: RootState) => state.global.fullPageLoading;
export const selectShowSecurityTooLowModal = (state: RootState) => state.global.securityTooLow.showModal;
export const selectShowSecurityTooLowSyncModal = (state: RootState) => state.global.securityTooLowSync.showModal;
export const selectSecurityTooLowDetails = (state: RootState) => state.global.securityTooLow.details;
export const selectAnyFetchRequestExists = (state: RootState) => state.global.fetchRequests > 0;

export default globalSlice.reducer;
