import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { removeIdentityCodeAsync, removeTwoFactorPhoneNumberAsync, saveCurrentUserTwoFactorPreferenceAsync, selectCurrentUser } from "@/app/store/userSlice";
import { BhSectionMessageSuccess } from "@components/sectionMessage/BhSectionMessages";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhPigeon20Separator from "@components/separator/BhPigeon20Separator";
import { IUserProfileModalsOpen } from "@/model/profile/IUserProfileModals";
import { toggleUserProfileModalsOpen } from "@/app/store/userPreferenceSlice";
import { useTranslation } from "react-i18next";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";

const ProfileAuthSettings: FC = () => {
  const { t } = useTranslation();
  const user = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();

  const twoFactorEnabledTogglePressed = async () => {
    if (user.twoFactorEnabled || user.phoneNumber) {
      dispatch(saveCurrentUserTwoFactorPreferenceAsync(!user.twoFactorEnabled)).then(() => {});
    } else {
      openModal("profileAuthSettingsPhoneModal");
    }
  };

  const removeTwoFactorPhoneNumberPressed = async () => {
    dispatch(removeTwoFactorPhoneNumberAsync()).then(() => {});
  };

  const removeIdentityCodePressed = async () => {
    dispatch(removeIdentityCodeAsync()).then(() => {});
  };

  const openModal = (modalType: keyof IUserProfileModalsOpen) => {
    dispatch(toggleUserProfileModalsOpen({ modal: modalType }));
  };

  return (
    <BhScrollableBody>
      <div className="flex w-full flex-row justify-center px-8 pt-10">
        <div className="flex w-full max-w-[800px] flex-row">
          <div className="flex w-full flex-col">
            <h2>{t("GLOBAL.TWO_FACTOR")}</h2>
            <div className="mt-5 mb-6 flex flex-row items-center justify-between">
              <p>{t("USER.PROFILE.2FA.DESCRIPTION")}</p>
              <BhToggleSwitch value={user.twoFactorEnabled} onClickAction={twoFactorEnabledTogglePressed} />
            </div>
            {user.phoneNumber && user.phoneNumber.length > 0 && (
              <div className="flex flex-row justify-between gap-x-4">
                <div className="flex-1">
                  <BhSectionMessageSuccess buttonLabel={`${t("GLOBAL.CHANGE")}`} onClick={() => openModal("profileAuthSettingsPhoneModal")}>
                    {t("USER.PROFILE.2FA.IDENTIFIED_PHONE_NR")} <strong>{user.phoneNumber}</strong>
                  </BhSectionMessageSuccess>
                </div>
                <div className="ml-1 flex items-center justify-center">
                  <BhTextOnlyButton buttonProps={{ onClick: () => removeTwoFactorPhoneNumberPressed() }} icon={faTimes}>
                    {t("USER.PROFILE.REMOVE")}
                  </BhTextOnlyButton>
                </div>
              </div>
            )}
            <BhPigeon20Separator />
            <h2 className="mt-6">{t("USER.PROFILE.PERSONALIZATION_MODAL.HEADER")}</h2>
            <div className="my-5">
              <div className={"flex " + (user.identityCode && user.identityCode.length > 0 ? "flex-col" : "flex-row")}>
                <div className="flex-1 pr-16">
                  <p>{t("USER.INFO.IDENTIFY_ME_TOOLTIP")}</p>
                </div>
                {(!user.identityCode || user.identityCode.length === 0) && (
                  <div className="flex w-44 items-center justify-end">
                    <BhPrimaryButton buttonProps={{ onClick: () => openModal("profileAuthSettingsPersonalizationModal") }}>{t("USER.PROFILE.PERSONALIZE")}</BhPrimaryButton>
                  </div>
                )}
                {user.identityCode && user.identityCode.length > 0 && (
                  <div>
                    <div className="flex flex-row justify-between gap-4">
                      <div className="flex-1">
                        <BhSectionMessageSuccess>
                          {t("USER.PROFILE.2FA.IDENTIFIED_SSN")} <strong>{user.identityCode}</strong>
                        </BhSectionMessageSuccess>
                      </div>
                      <div className="ml-1 flex items-center justify-center">
                        <BhTextOnlyButton buttonProps={{ onClick: () => removeIdentityCodePressed() }} icon={faTimes}>
                          {t("USER.PROFILE.REMOVE")}
                        </BhTextOnlyButton>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BhScrollableBody>
  );
};

export default ProfileAuthSettings;
