import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import PartiesFilter from "@/views/home/project/detail/users/parties/PartiesFilter";
import PartiesListItem from "@/views/home/project/detail/users/parties/PartiesListItem";
import { EntityId } from "@reduxjs/toolkit";
import PartiesSort from "@/views/home/project/detail/users/parties/PartiesSort";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import BhFilterContainerWithItemsOnRight from "@components/filters/BhFilterContainerWithItemsOnRight";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { selectProjectWorkGroupIdsFiltered } from "@/app/store/project/projectWorkGroupsSlice";
import { useNavigate } from "react-router-dom";
import { selectCurrentProjectId, selectMaruProjectModule } from "@/app/store/project/projectSlice";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import MaruPartiesListItem from "@/views/home/project/detail/users/parties/maru/MaruPartiesListItem";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {}

const PartiesContainer: FC<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const projectId = useAppSelector(selectCurrentProjectId);
  const workGroupIds = useAppSelector(selectProjectWorkGroupIdsFiltered);
  const isMaruProject = useAppSelector(selectMaruProjectModule);
  const isBauhubProject = !isMaruProject;

  useDocumentTitle("GLOBAL.WORKGROUPS");

  const goToNewParty = () => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/users/party/new`);
  };

  return (
    <BhScrollableBody
      header={
        <BhFilterContainerWithItemsOnRight
          right={
            <BhSecondaryButton icon={faPlus} buttonProps={{ onClick: goToNewParty }}>
              {t("WORKGROUP.NEW_WORKGROUP")}
            </BhSecondaryButton>
          }
        >
          <PartiesFilter />
        </BhFilterContainerWithItemsOnRight>
      }
      keepScrollForKey="partiesList"
    >
      <PartiesSort />
      {isBauhubProject && <div>{workGroupIds && workGroupIds.map((workGroupId: EntityId) => <PartiesListItem key={workGroupId} partyId={workGroupId} />)}</div>}
      {isMaruProject && <div>{workGroupIds && workGroupIds.map((workGroupId: EntityId) => <MaruPartiesListItem key={workGroupId} partyId={workGroupId} />)}</div>}
    </BhScrollableBody>
  );
};

export default PartiesContainer;
