import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import WebViewer, { Core, WebViewerInstance } from "@pdftron/webviewer";
import { fetchUrlForFile } from "@/api/fileAPI";
import { EntityId } from "@reduxjs/toolkit";
import { isOfficeDocument, shouldUsePdftronServer } from "@/utilities/fileEntity/fileEntityUtilities";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchFileEntityAsync, selectFileById } from "@/app/store/filesSlice";
import { fetchFileAnnotationAsync, selectFileAnnotationForFileEntityId, taskModalClosedWithTaskIdSet } from "@/app/store/fileAnnotationsSlice";
import { selectCurrentUser, selectCurrentUserTheme, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { FileEntityType } from "@/model/files/IFileEntity";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import PdftronAnnotationsSidebar from "@/views/home/project/detail/pdftron/PdftronAnnotationsSidebar";
import { classNames, removeDuplicates } from "@/utilities/jsUtilities";
import { fetchProjectTaskCategoriesAsync, fetchTasksByIdsWithExtraFieldsAsync, selectProjectTaskCategoriesSorted, setNewTask } from "@/app/store/tasksSlice";
import { ReactComponent as BauhubTaskPin } from "@svg/bauhub-task-pin.svg";
import { createBauhubTaskTools, pdftronAnnotationChangedCallback } from "@/views/home/project/detail/pdftron/pdftronFunctions";
import { IFileAnnotation } from "@/model/files/IFileAnnotation";
import { createNewTaskObject, ITask } from "@/model/taskBoard/ITask";
import { selectCurrentProjectId, toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { CategoryDefaultCode, IProjectTaskCategory } from "@/model/taskBoard/IProjectTaskCategory";
import TaskSaveCallbackLogicForPdftron from "@/views/home/project/detail/pdftron/TaskSaveCallbackLogicForPdftron";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faMessage } from "@fortawesome/pro-regular-svg-icons/faMessage";
import { faShapes } from "@fortawesome/pro-regular-svg-icons/faShapes";
import CommentsSidebar from "@/views/home/project/detail/pdftron/CommentsSidebar";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { ReactComponent as BhLogoLoading } from "@svg/bauhub-loading-compressed.svg";
import { Trans, useTranslation } from "react-i18next";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import BackButtonToDirectoryView from "@/views/home/project/detail/pdftron/PdftronContainerBackButton";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { Theme } from "@/model/IUserInfo";
import { pdftronDisabledElements } from "@/views/home/project/detail/pdftron/PdftronHelperFunctions";

enum PdftronSidebarItem {
  HIDDEN = "HIDDEN",
  COMMENTS = "COMMENTS",
  ANNOTATIONS = "ANNOTATIONS"
}

interface Props {
  fileEntityId: EntityId;
  fileEntityUuid?: string;
  customBackButton?: ReactNode;
  commentsDisabled?: boolean;
}

const PdftronContainer: FC<Props> = ({ fileEntityId, fileEntityUuid, customBackButton, commentsDisabled }) => {
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectCurrentUser);
  const theme = useAppSelector(selectCurrentUserTheme);
  const projectId = useAppSelector(selectCurrentProjectId);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const fileEntity = useAppSelector((state) => selectFileById(state, fileEntityId));
  const [initializeWebviewer, setInitializeWebviewer] = useState(false);
  const fileAnnotation = useAppSelector((state) => selectFileAnnotationForFileEntityId(state, fileEntityId));
  const projectTaskCategories = useAppSelector(selectProjectTaskCategoriesSorted);
  const [taskToolsCreated, setTaskToolsCreated] = useState(false);
  const [selectedAnnotationIds, setSelectedAnnotationIds] = useState<Array<string>>([]);
  const [pdftronSidebarItem, setPdftronSidebarItem] = useState(PdftronSidebarItem.HIDDEN);
  const [instance, setInstance] = useState<WebViewerInstance>();
  const [annotationsDrawnFirstTime, setAnnotationsDrawnFirstTime] = useState(false);
  const [fileUrlLoading, setFileUrlLoading] = useState(true);
  const [selectedToolTaskCategoryId, setSelectedToolTaskCategoryId] = useState<EntityId | undefined>();
  const viewer = useRef(null);
  const dispatch = useAppDispatch();
  useDocumentTitle(fileEntity?.name);

  useEffect(() => {
    dispatch(fetchProjectTaskCategoriesAsync(projectId));
    dispatch(setSidebarCollapsed(true));
    dispatch(taskModalClosedWithTaskIdSet(undefined));
  }, []);

  useEffect(() => {
    if (instance && projectTaskCategories && !taskToolsCreated) {
      createBauhubTaskTools(instance, taskToolsCreated, setTaskToolsCreated, bauhubTaskToolMouseUpCallback, projectTaskCategories);
    }
  }, [projectTaskCategories, instance]);

  useEffect(() => {
    if (fileEntity && !initializeWebviewer) {
      setInitializeWebviewer(true);
    }
  }, [fileEntity]);

  useEffect(() => {
    if (fileEntity && initializeWebviewer) {
      dispatch(fetchFileAnnotationAsync(fileEntity)).then((action) => {
        const fileAnnotation = action.payload as IFileAnnotation;
        if (fileAnnotation) {
          const taskIds = fileAnnotation.taskAnnotations?.filter((ta) => ta.taskId).map((ta) => ta.taskId);
          if (taskIds?.length > 0) {
            dispatch(fetchTasksByIdsWithExtraFieldsAsync(taskIds));
          }
        }
      });
      fetchFileUrlAndInitPdftron();
    }
  }, [initializeWebviewer]);

  useEffect(() => {
    if (!fileEntity) {
      dispatch(fetchFileEntityAsync(fileEntityId));
    }
  }, []);

  useEffect(() => {
    if (annotationsDrawnFirstTime && instance && fileAnnotation?.xfdf) {
      instance?.Core.annotationManager.importAnnotations(fileAnnotation.xfdf);
      instance?.Core.annotationManager.showAnnotations(instance?.Core.annotationManager.getAnnotationsList());
    }
  }, [fileAnnotation?.xfdf]);

  const drawAnnotations = () => {
    if (fileAnnotation && fileAnnotation.xfdf) {
      instance?.Core.documentViewer.addEventListener("documentLoaded", function () {
        instance?.Core.annotationManager.importAnnotations(fileAnnotation.xfdf);
        instance?.Core.annotationManager.showAnnotations(instance?.Core.annotationManager.getAnnotationsList());
      });
    }
  };

  // Add first time annotation drawing for one time only
  useEffect(() => {
    if (instance && fileAnnotation && !annotationsDrawnFirstTime) {
      drawAnnotations();
      setAnnotationsDrawnFirstTime(true);
    }
  }, [fileAnnotation, instance]);

  // Väga kehva konstruktsioon, aga muul juhul on fileAnnotation sisu alati undefined kui midagi tegelikult muutub failiannotatsioonis
  useEffect(() => {
    let saving = false;
    const setSaving = (value: boolean) => {
      saving = value;
    };

    const annoChangedCB = (annotations: Array<Core.Annotations.Annotation>, action: string, info: Core.AnnotationManager.AnnotationChangedInfoObject) => {
      if (saving) {
        return;
      }
      setSaving(true);
      return pdftronAnnotationChangedCallback(fileAnnotation, fileEntity, instance, dispatch, annotations, action, info, setSaving);
    };
    instance?.Core.annotationManager.addEventListener("annotationChanged", annoChangedCB);
    return () => instance?.Core.annotationManager.removeEventListener("annotationChanged", annoChangedCB);
  }, [fileAnnotation, instance]);

  // OPTIONS
  const isReadOnly = fileEntity && !fileEntity.isActive;

  const bauhubTaskToolMouseUpCallback = (annotation: Core.Annotations.Annotation, category: IProjectTaskCategory) => {
    const newTask = { ...createNewTaskObject({ currentUser: currentUser, category: category }), fileAnnotationRelations: [{ annotationId: annotation.Id, fileEntityId: fileEntityId }] } as ITask;
    dispatch(setNewTask(newTask));
    dispatch(toggleProjectModalsOpen({ modal: "projectTaskModal" }));
  };

  const fetchFileUrlAndInitPdftron = () => {
    async function fetchFileUrl() {
      setFileUrlLoading(true);
      return await fetchUrlForFile(fileEntityId, false, false, fileEntityUuid);
    }

    fetchFileUrl()
      .then((presignedUrl) => {
        setFileUrlLoading(false);
        if (!viewer.current || instance) return;

        const formExtension = fileEntity?.type === FileEntityType.FORM ? ".pdf" : "";
        const fileName = fileEntity?.name + "" + formExtension;
        const isMSOfficeFile = isOfficeDocument(fileName.split(".").pop());

        WebViewer(
          {
            licenseKey: "Bauhub OU:OEM:Bauhub::B+:AMS(20260305):4A5F85823C6F78B942CE606F4F3D959CDE1FC65BA4AF8F58BE54B2B6F5C7",
            filename: !isMSOfficeFile ? fileName : undefined,
            annotationUser: currentUser.username,
            path: ConfigSingleton.getInstance().getConfig().PUBLIC_URL + "/pdftron10.9.0",
            useDownloader: fileEntity && fileEntity.size > 40000000,
            initialDoc: !isMSOfficeFile ? presignedUrl.value : undefined,
            extension: !isMSOfficeFile ? fileName.split(".").pop() : undefined,
            webviewerServerURL: fileEntity && shouldUsePdftronServer(fileEntity) ? "https://pdf3.bauhub.ee" : undefined,
            disabledElements: pdftronDisabledElements,
            isReadOnly: isReadOnly,
            selectAnnotationOnCreation: true,
            disableMultiViewerComparison: true,
            enableMeasurement: true
          },
          viewer.current
        ).then((instance) => {
          setInstance(instance);

          if (fileEntity && isMSOfficeFile) {
            const officeDoc = instance.Core.createDocument(presignedUrl.value, { filename: fileName, extension: fileName.split(".").pop(), officeOptions: { formatOptions: { locale: "de-DE" } } });
            officeDoc.then(function (dc) {
              instance.UI.loadDocument(dc, { filename: fileName });
            });
          }

          instance.UI.setTheme(theme === Theme.DARK ? "dark" : "light");

          createBauhubTaskTools(instance, taskToolsCreated, setTaskToolsCreated, bauhubTaskToolMouseUpCallback, projectTaskCategories);

          instance.UI.setPrintQuality(3);
          instance.UI.hotkeys.off(instance.UI.hotkeys.Keys.S);
          instance.UI.hotkeys.off(instance.UI.hotkeys.Keys.Q);
          instance.UI.hotkeys.off(instance.UI.hotkeys.Keys.I);
          instance.UI.hotkeys.off(instance.UI.hotkeys.Keys.N);

          const languageMap = { et_EE: "et", ru_RU: "ru", en_EN: "en" };
          // @ts-ignore
          instance.UI.setLanguage(languageMap[currentUser.language]);

          const annoSelectedCB = function (annotationsList: Array<Core.Annotations.Annotation>, action: string) {
            if (action === "selected") {
              setSelectedAnnotationIds(removeDuplicates([...annotationsList.map((annotation) => annotation.Id)]));
              annotationsList.forEach((anno: Core.Annotations.Annotation) => {
                if (anno.Subject === "Task") {
                  anno.MaintainAspectRatio = true;
                  anno.disableRotationControl();
                  instance?.UI.disableElements(["annotationGroupButton", "annotationUnGroupButton", "annotationStyleEditButton"]);
                } else {
                  instance?.UI.enableElements(["annotationGroupButton", "annotationUnGroupButton", "annotationStyleEditButton"]);
                }
                if (anno.getCustomData("taskId") && pdftronSidebarItem === PdftronSidebarItem.HIDDEN) setPdftronSidebarItem(PdftronSidebarItem.ANNOTATIONS);
              });
            }
            if (action === "deselected") {
              setSelectedAnnotationIds(selectedAnnotationIds.filter((annotation) => !annotationsList.map((annotation) => annotation.Id).includes(annotation)));
            }
          };

          if (isProjectAdmin) {
            instance.Core.annotationManager.promoteUserToAdmin();
          }

          instance.Core.annotationManager.addEventListener("annotationSelected", annoSelectedCB);

          const toolModeUpdatedCB = function (tool: any) {
            setSelectedToolTaskCategoryId(tool.name && (tool.name + "").startsWith("CustomTaskStampTool") ? (tool.name + "").split("CustomTaskStampTool")[1] : undefined);
          };

          instance.Core.documentViewer.addEventListener("toolModeUpdated", toolModeUpdatedCB);

          const distanceMeasurementTool = instance.Core.documentViewer.getTool("AnnotationCreateDistanceMeasurement");
          distanceMeasurementTool.setStyles({
            Scale: [
              [1, "mm"],
              [1, "mm"]
            ]
          });
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    return function cleanup() {
      instance?.UI.dispose();
    };
  }, []);

  const annotationList = instance?.Core.annotationManager?.getAnnotationsList().filter((annotation) => !["link", "widget"].includes(annotation.elementName)) || [];

  if (!fileEntity) {
    return null;
  }

  const bauhubTaskTools =
    projectTaskCategories &&
    projectTaskCategories.map((category, index) => {
      const translateTaskCategory = Object.keys(CategoryDefaultCode).includes(category.name.toUpperCase());
      const categoryName = translateTaskCategory ? t("TASK.CATEGORY." + category.name.toUpperCase()) : category.name;

      return (
        <div key={category.id} className={classNames(index > 0 && !selectedToolTaskCategoryId && "hidden group-hover:flex", "flex flex-row items-center justify-end")}>
          <div className={classNames(!selectedToolTaskCategoryId && "hidden group-hover:flex", "bh-bg-white bh-shadow rounded p-1 font-bold")}>
            {selectedToolTaskCategoryId ? t("PDF.ANNOTATE") + " " + categoryName : categoryName}
          </div>
          <button
            onClick={() => {
              const toolName = "CustomTaskStampTool" + category.id;
              if (instance?.UI.getToolMode().name === toolName) {
                instance?.UI.setToolMode(instance?.Core.Tools.ToolNames.EDIT);
              } else {
                instance?.UI.setToolMode("CustomTaskStampTool" + category.id);
              }
            }}
            style={{ backgroundColor: category.color }}
            className="bh-text-white bh-shadow m-2 flex h-10 w-10 flex-row items-center justify-center rounded-full text-center hover:opacity-90"
          >
            <BauhubTaskPin className="!h-6 !w-6" fill="white" />
          </button>
        </div>
      );
    });

  return (
    <div className="flex h-full w-full flex-col">
      <div className="items-top min-h-12 flex flex-row items-center border-b py-1">
        <div className="flex flex-1 flex-row items-center">
          {!customBackButton && <BackButtonToDirectoryView fileEntityId={fileEntityId} parentFileEntityId={fileEntity.parentFileEntityId} projectId={projectId} />}
          {customBackButton && customBackButton}
          <div className="text-13px whitespace-nowrap px-4 font-bold sm:hidden">V {fileEntity?.revision}</div>
          <h3 className="sm:overflow-hidden">{fileEntity?.name}</h3>
        </div>
        <div className="flex flex-row space-x-2 px-4 sm:hidden">
          {!fileUrlLoading && (
            <>
              <BhIconButton
                icon={faArrowDownToLine}
                buttonProps={{
                  onClick: () => {
                    fetchUrlForFile(fileEntityId, false, true, fileEntityUuid).then((response) => {
                      imitateUrlDownload(response.value);
                    });
                  }
                }}
              />
              <BhSecondaryButton
                icon={faShapes}
                buttonProps={{ onClick: () => setPdftronSidebarItem(pdftronSidebarItem === PdftronSidebarItem.ANNOTATIONS ? PdftronSidebarItem.HIDDEN : PdftronSidebarItem.ANNOTATIONS) }}
              >
                <span>
                  <Trans>PDFTRON.ANNOTATIONS</Trans>
                </span>
                {annotationList?.length > 0 && (
                  <BhBadge classes="ml-2" type={BhBadgeType.BRIGHT}>
                    {annotationList?.length}
                  </BhBadge>
                )}
              </BhSecondaryButton>
              {!commentsDisabled && (
                <BhSecondaryButton
                  icon={faMessage}
                  buttonProps={{ onClick: () => setPdftronSidebarItem(pdftronSidebarItem === PdftronSidebarItem.COMMENTS ? PdftronSidebarItem.HIDDEN : PdftronSidebarItem.COMMENTS) }}
                >
                  <Trans>TASK.COMMENTS</Trans>
                  {fileEntity.commentCount > 0 && (
                    <BhBadge classes="ml-2" type={BhBadgeType.BRIGHT}>
                      {fileEntity.commentCount}
                    </BhBadge>
                  )}
                </BhSecondaryButton>
              )}
            </>
          )}
        </div>
      </div>
      <div className="flex h-full w-full flex-row overflow-hidden">
        <div className="relative flex-1">
          {fileUrlLoading && (
            <div className="flex h-full w-full flex-row items-center justify-center">
              <BhLogoLoading className="h-10 w-10" />
            </div>
          )}
          <div className="webviewer" ref={viewer} style={{ height: "100%", width: "100%" }} />
          {projectTaskCategories && projectTaskCategories.length > 0 && taskToolsCreated && instance && (
            <div className="group absolute bottom-6 right-6 flex flex-col-reverse items-end">
              {selectedToolTaskCategoryId && bauhubTaskTools && bauhubTaskTools.find((tool) => tool.key + "" === selectedToolTaskCategoryId + "")}
              {!selectedToolTaskCategoryId && bauhubTaskTools}
            </div>
          )}
        </div>
        {pdftronSidebarItem === PdftronSidebarItem.ANNOTATIONS && (
          <PdftronAnnotationsSidebar
            fileEntityId={fileEntityId}
            annotationManager={instance?.Core.annotationManager}
            selectedAnnotationIds={selectedAnnotationIds}
            hideSidebar={() => setPdftronSidebarItem(PdftronSidebarItem.HIDDEN)}
          />
        )}
        {pdftronSidebarItem === PdftronSidebarItem.COMMENTS && <CommentsSidebar fileEntityId={fileEntityId} hideSidebar={() => setPdftronSidebarItem(PdftronSidebarItem.HIDDEN)} />}
      </div>
      <TaskSaveCallbackLogicForPdftron fileEntityId={fileEntityId} instance={instance} />
    </div>
  );
};

export default PdftronContainer;
