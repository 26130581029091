import { EntityId } from "@reduxjs/toolkit";

export enum UserNotificationType {
  NEW_FILE = "NEW_FILE",
  NEW_REVISION = "NEW_REVISION",
  FILE_DELETED = "FILE_DELETED",
  FILE_RENAMED = "FILE_RENAMED",
  NEW_DIR = "NEW_DIR",
  NEW_PRIVILEGES = "NEW_PRIVILEGES",
  ADDED_TO_WG = "ADDED_TO_WG",
  NEW_TASK = "NEW_TASK",
  NEW_TASK_STATUS = "NEW_TASK_STATUS",
  NEW_TASK_COMMENT = "NEW_TASK_COMMENT",
  ADDED_AS_COMPANY_ADMIN = "ADDED_AS_COMPANY_ADMIN",
  SIGN_INVITE = "SIGN_INVITE",
  SIGNATURE_DECLINED = "SIGNATURE_DECLINED",
  SIGNED = "SIGNED",
  CONTAINER_SIGNING_COMPLETE = "CONTAINER_SIGNING_COMPLETE",
  TASK_COMMENT_EDIT = "TASK_COMMENT_EDIT",
  TASK_COMMENT_DELETE = "TASK_COMMENT_DELETE",
  FILES_ADDED = "FILES_ADDED",
  FILES_CONFIRMED = "FILES_CONFIRMED",
  FILES_DECLINED = "FILES_DECLINED",
  FILES_NEED_APPROVAL = "FILES_NEED_APPROVAL",
  REFRESH = "REFRESH",
  ACT_SUBMITTED = "ACT_SUBMITTED",
  ACT_APPROVED = "ACT_APPROVED",
  ACT_REFUSED = "ACT_REFUSED",
  ACT_CANCELLED = "ACT_CANCELLED",
  USER_MENTIONED = "USER_MENTIONED",
  NEW_FILE_COMMENT = "NEW_FILE_COMMENT"
}

export enum UserNotificationTypeIcon {
  SUCCESS = "SUCCESS",
  DECLINED = "DECLINED",
  ALERT = "ALERT",
  COMMENT = "COMMENT",
  SIGNATURE = "SIGNATURE",
  EDIT = "EDIT",
  ADD = "ADD",
  TASKBOARD = "TASKBOARD",
  SUBMITTED = "SUBMITTED",
  APPROVED = "APPROVED",
  ADMIN_RIGHTS = "ADMIN_RIGHTS"
}

export interface IUserNotification {
  id: EntityId;
  projectId: EntityId;
  userEntityId: EntityId;
  uuid: string;
  contentCode: string;
  type: UserNotificationType;
  sent: Date;
  seen: boolean;
  isDeleted: boolean;
  titleParams: any;
  contentParams: any;
  createdBy: string;
  created: Date;
  updatedBy: string;
  updated: Date;
  isArchived: boolean;
}

export interface IProjectNotificationCount {
  projectId: EntityId;
  count: number;
}

export function getCombinedParams(notification: IUserNotification) {
  return { ...notification.titleParams, ...notification.contentParams };
}

export function getNewTranslationCode(notification: IUserNotification) {
  if (notification.type === UserNotificationType.USER_MENTIONED) {
    if (notification.contentParams.fileId) {
      return "NOTIFICATION.V2.FILE.USER_MENTIONED_CONTENT";
    }
    return "NOTIFICATION.V2.TASK.USER_MENTIONED_CONTENT";
  } else if (notification.type === UserNotificationType.NEW_FILE_COMMENT) {
    return "NOTIFICATION.V2.NEW_FILE_COMMENT";
  }
  return notification.contentCode;
}
