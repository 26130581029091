import React, { FC, useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhFilter from "@components/filters/BhFilter";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import {
  saveChecklistForLatestBaseVersionAsync,
  selectChecklistCreatorsForFilter,
  selectChecklistFilter,
  selectChecklistTags,
  selectChecklistTypes,
  selectUserChecklistBases,
  setChecklistFilter
} from "@/app/store/checklist/checklistsSlice";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import { IChecklistFilter } from "@/model/checklist/IChecklistFilter";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { IChecklistBase } from "@/model/checklist/IChecklistBase";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useNavigate } from "react-router-dom";
import { IChecklist } from "@/model/checklist/IChecklist";
import { IChecklistTag } from "@/model/checklist/IChecklistTag";
import { useTranslation } from "react-i18next";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faClockRotateLeft } from "@fortawesome/pro-regular-svg-icons/faClockRotateLeft";
import ProjectChecklistLogsModal from "@components/checklists/settings/projectSettings/projectChecklistsLogsModal/ProjectChecklistLogsModal";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { IUser } from "@/model/IUser";
import BhDatePicker from "@components/input/BhDatePicker";

interface Props {
  archived?: boolean;
}

const ChecklistsFilter: FC<Props> = ({ archived }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const checklistBases = useAppSelector(selectUserChecklistBases);
  const checklistTypes = useAppSelector(selectChecklistTypes);
  const filter = useAppSelector(selectChecklistFilter);
  const projectId = useAppSelector(selectCurrentProjectId);
  const projectChecklistTags = useAppSelector(selectChecklistTags);
  const checklistCreators = useAppSelector(selectChecklistCreatorsForFilter);
  const isCurrentUserProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const [showLogsModal, setShowLogsModal] = useState(false);

  const onTypeFilterSelect = useCallback(
    (value: string) => {
      const newFilter = { ...filter, ...{ type: value, page: 1 } };
      dispatch(setChecklistFilter({ filter: newFilter }));
    },
    [filter]
  );

  const onTypeFilterReset = useCallback(() => {
    const newFilter = { ...filter, ...{ type: undefined, page: 1 } };
    dispatch(setChecklistFilter({ filter: newFilter }));
  }, [filter]);

  const isTypeSelected = (type: string) => {
    return filter.type === type;
  };

  const onTagFilterSelect = useCallback(
    (tag: IChecklistTag) => {
      const newFilter = { ...filter, ...{ tagName: tag.name, page: 1 } };
      dispatch(setChecklistFilter({ filter: newFilter }));
    },
    [filter]
  );

  const onTagFilterReset = useCallback(() => {
    const newFilter = { ...filter, ...{ tagName: undefined, page: 1 } };
    dispatch(setChecklistFilter({ filter: newFilter }));
  }, [filter]);

  const isTagSelected = (tag: IChecklistTag) => {
    return filter.tagName === tag.name;
  };

  const onCreatedByFilterSelect = useCallback(
    (user: IUser) => {
      const newFilter = { ...filter, ...{ createdBy: user.username, page: 1 } };
      dispatch(setChecklistFilter({ filter: newFilter }));
    },
    [filter]
  );

  const onCreatedByReset = useCallback(() => {
    const newFilter = { ...filter, ...{ createdBy: undefined, page: 1 } };
    dispatch(setChecklistFilter({ filter: newFilter }));
  }, [filter]);

  const isCreatedBySelected = (user: IUser) => {
    return filter.createdBy === user.username;
  };

  const onNameFilterChange = useCallback(
    (nameObject: IChecklistFilter) => {
      const newFilter = { ...filter, ...nameObject };
      dispatch(setChecklistFilter({ filter: newFilter }));
    },
    [filter]
  );

  const onSinceDateChange = (changedObj: Record<string, string>) => {
    const newFilter = { ...filter, ...{ since: changedObj.date } };
    dispatch(setChecklistFilter({ filter: newFilter }));
  };

  const onSinceDateReset = () => {
    const newFilter = { ...filter, ...{ since: undefined, page: 1 } };
    dispatch(setChecklistFilter({ filter: newFilter }));
  };

  const onUntilDateChange = (changedObj: Record<string, string>) => {
    const newFilter = { ...filter, ...{ until: changedObj.date } };
    dispatch(setChecklistFilter({ filter: newFilter }));
  };

  const onUntilDateReset = () => {
    const newFilter = { ...filter, ...{ until: undefined, page: 1 } };
    dispatch(setChecklistFilter({ filter: newFilter }));
  };

  const startNewChecklist = (checklistBase: IChecklistBase) => {
    dispatch(saveChecklistForLatestBaseVersionAsync({ projectId: projectId, checklistBaseId: checklistBase.id })).then((action) => {
      const checklist = action.payload as IChecklist;
      if (checklist) {
        const lastRoundId = checklist.checklistRounds && checklist.checklistRounds.reduce((max, round) => (max.roundNumber > round.roundNumber ? max : round)).id;
        if (lastRoundId) {
          navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklists/${checklist.id}/round/${lastRoundId}`);
        }
      }
    });
  };

  return (
    <div className="my-4 flex flex-row items-center justify-between">
      <div className="flex flex-row items-center">
        <div className="mr-2">
          <BhSearchInputWBG initialValue={filter.name} property="name" onChangeCallback={onNameFilterChange} placeholder={t("CHECKLIST.SEARCH_PLACEHOLDER") as string} inputClasses={"max-w-xs"} />
        </div>
        <div className="mr-2">
          <BhFilter
            title={t("CHECKLIST.CHECKLIST_TYPE")}
            values={checklistTypes}
            onSelect={onTypeFilterSelect}
            onReset={onTypeFilterReset}
            dropdownWidthClass="w-72"
            isSelected={isTypeSelected}
            selectedValues={filter.type ? 1 : 0}
          />
        </div>
        <div className="mr-2">
          <BhFilter
            title={t("CHECKLIST.TAG")}
            values={projectChecklistTags}
            textProperty={"name"}
            onSelect={onTagFilterSelect}
            onReset={onTagFilterReset}
            dropdownWidthClass="w-72"
            isSelected={isTagSelected}
            selectedValues={filter.tagName ? 1 : 0}
          />
        </div>
        <div className="mr-2">
          <BhFilter
            title={t("CHECKLIST.CREATED_BY")}
            values={checklistCreators}
            textProperty={"fullName"}
            onSelect={onCreatedByFilterSelect}
            onReset={onCreatedByReset}
            dropdownWidthClass="w-72"
            isSelected={isCreatedBySelected}
            selectedValues={filter.createdBy ? 1 : 0}
          />
        </div>
        <div className="mr-2">
          <BhDatePicker property={"date"} placeholder={t("LOG.SINCE") as string} onChangeCallback={onSinceDateChange} onResetCallback={onSinceDateReset} returnISOString={true} />
        </div>
        <div className="mr-2">
          <BhDatePicker property={"date"} placeholder={t("LOG.UNTIL") as string} onChangeCallback={onUntilDateChange} onResetCallback={onUntilDateReset} returnISOString={true} />
        </div>
      </div>
      <div className="flex flex-row">
        {isCurrentUserProjectAdmin && (
          <BhSecondaryButton icon={faClockRotateLeft} buttonProps={{ onClick: () => setShowLogsModal(true) }}>
            {t("CHECKLIST.CHECKLIST_LOGS.SHORT")}
          </BhSecondaryButton>
        )}
        {checklistBases && checklistBases.length > 0 && (
          <div>
            <BhDropdown
              button={<BhPrimaryButton icon={faPlus}>{t("CHECKLIST.START_NEW")}</BhPrimaryButton>}
              menu={<BhDropdownMenu values={checklistBases} type={BhDropdownTypeEnum.STRING} textProperty="type" onSelect={startNewChecklist} widthClass={"w-72"} />}
              position={BhDropdownPositionEnum.BOTTOM_LEFT}
            />
          </div>
        )}
      </div>
      {showLogsModal && <ProjectChecklistLogsModal setIsShown={setShowLogsModal} archived={archived} />}
    </div>
  );
};

export default ChecklistsFilter;
