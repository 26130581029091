import fetchIntercept, { FetchInterceptorResponse } from "fetch-intercept";
import { userLoggedOut } from "@/app/store/userSlice";
import { store } from "@/app/store";
import { fetchRequestEnded, fetchRequestStarted, successToastShown } from "@/app/store/globalSlice";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

export const AuthInterceptor = () => {
  let stopAll = false;

  fetchIntercept.register({
    request: function (url, config) {
      if (!url.includes("st=false")) {
        store.dispatch(fetchRequestStarted());
      }
      // Modify the url or config here
      config = config || { headers: {}, redirect: "manual" };
      return [url, config];
    },

    requestError: function (error) {
      store.dispatch(fetchRequestEnded());
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error);
    },

    response: function (response) {
      if (!response.request.url.includes("st=false")) {
        store.dispatch(fetchRequestEnded());
      }
      // Kui kasutaja mõni päring saab 403 ja suunab ümber siis suuna ümber

      if (stopAll) {
        return {} as FetchInterceptorResponse;
      }

      if (response.type === "opaqueredirect") {
        stopAll = true;
        store.dispatch(userLoggedOut());
        const redirectUrl =
          ConfigSingleton.getInstance().getConfig().REACT_APP_CAS_URL + "/login?service=" + encodeURIComponent(ConfigSingleton.getInstance().getConfig().REACT_APP_CAS_CALLBACK_URL + "");
        // eslint-disable-next-line no-restricted-globals
        location.href = redirectUrl;
      }

      // Disable success toast
      if (response.ok && response.request.method === "POST" && !response.request.url.includes("st=false")) {
        store.dispatch(successToastShown(true));
      }

      return response;
    },

    responseError: function (error) {
      store.dispatch(fetchRequestEnded());
      console.log("error", error);
      // Handle a fetch error
      return Promise.reject(error);
    }
  });
};
