import { FileEntityType } from "@/model/files/IFileEntity";
import { EntityId } from "@reduxjs/toolkit";

export interface IBauhubEvent {
  id: EntityId;
  userEntityId: EntityId;
  fileEntityId: EntityId;
  fileEntityUuid: string;
  parentFileEntityId: EntityId;
  type: ProjectLogType;
  fileType: FileEntityType;
  data: IProjectLogData;
  downloadable: boolean;
  created: Date;
  createdBy: string;
  updated: Date;
  updatedBy: string;
  origin: string;
  projectId: EntityId;
}

export interface IProjectLogData {
  name: string;
  eventTriggererFirstName: string;
  eventTriggererLastName: string;
  parentFileEntityId: EntityId;
  parentFileEntityName: string;
  isRestored: boolean;
  movedFileName: string;
  fromDirectoryName: string;
  fromDirectoryId: EntityId;
  toDirectoryName: string;
  toDirectoryId: EntityId;
  oldFileName: string;
  newFileName: string;
  latestRevisionFileName: string;
  copiedFromFileId: EntityId;
  resource: string;
  resourceId: EntityId;
  workGroupId: EntityId;
}

export enum ProjectLogType {
  NEW_FILE = "NEW_FILE",
  FILE_DELETED = "FILE_DELETED",
  FILE_RENAMED = "FILE_RENAMED",
  FILE_NEW_REVISION = "FILE_NEW_REVISION",
  FILE_REVISION_ROLLBACK = "FILE_REVISION_ROLLBACK",
  FILE_UNDO_DELETE = "FILE_UNDO_DELETE",
  FILE_COMMENT = "FILE_COMMENT",
  FILE_LOCKED = "FILE_LOCKED",
  FILE_UNLOCKED = "FILE_UNLOCKED",
  FILE_MOVED_TO = "FILE_MOVED_TO",
  FILE_MOVED_FROM = "FILE_MOVED_FROM",
  AUTHORITY_CHANGED = "AUTHORITY_CHANGED",
  PROJECT_ARCHIVED = "PROJECT_ARCHIVED",
  PROJECT_OPENED = "PROJECT_OPENED",
  PROJECT_UPDATE = "PROJECT_UPDATE",
  FORM_UPDATE = "FORM_UPDATE",
  CONTAINER_UPDATE = "CONTAINER_UPDATE",
  SYNC_DIR_ADDED = "SYNC_DIR_ADDED",
  SYNC_DIR_REMOVED = "SYNC_DIR_REMOVED"
}

export enum ProjectLogFileType {
  DIR = "DIR",
  DOCUMENT_DIR = "DOCUMENT_DIR",
  FILE = "FILE",
  DOCUMENT = "DOCUMENT",
  FORM = "FORM",
  CONTAINER = "CONTAINER",
  ATTACHMENT_CONTAINER = "ATTACHMENT_CONTAINER"
}

export enum ProjectLogDisplayType {
  NEW = "NEW",
  DELETED = "DELETED",
  CHANGED = "CHANGED"
}

export const ProjectLogTypeToStyleMap = {
  NEW_FILE: ProjectLogDisplayType.NEW,
  FILE_DELETED: ProjectLogDisplayType.DELETED,
  FILE_RENAMED: ProjectLogDisplayType.CHANGED,
  FILE_NEW_REVISION: ProjectLogDisplayType.CHANGED,
  FILE_REVISION_ROLLBACK: ProjectLogDisplayType.CHANGED,
  FILE_UNDO_DELETE: ProjectLogDisplayType.CHANGED,
  FILE_COMMENT: ProjectLogDisplayType.CHANGED,
  FILE_LOCKED: ProjectLogDisplayType.CHANGED,
  FILE_UNLOCKED: ProjectLogDisplayType.CHANGED,
  FILE_MOVED_TO: ProjectLogDisplayType.CHANGED,
  FILE_MOVED_FROM: ProjectLogDisplayType.CHANGED,
  AUTHORITY_CHANGED: ProjectLogDisplayType.CHANGED,
  PROJECT_ARCHIVED: ProjectLogDisplayType.CHANGED,
  PROJECT_OPENED: ProjectLogDisplayType.CHANGED,
  PROJECT_UPDATE: ProjectLogDisplayType.CHANGED,
  FORM_UPDATE: ProjectLogDisplayType.CHANGED,
  CONTAINER_UPDATE: ProjectLogDisplayType.CHANGED,
  SYNC_DIR_ADDED: ProjectLogDisplayType.CHANGED,
  SYNC_DIR_REMOVED: ProjectLogDisplayType.CHANGED
};

export const ProjectLogTypeBgClasses = {
  NEW: "bh-bg-success-green-10",
  DELETED: "bh-bg-error-red-10",
  CHANGED: "bh-bg-warning-yellow-10"
};

export const ProjectLogTypeIconClasses = {
  NEW: "bh-text-success-green",
  DELETED: "bh-text-error-red",
  CHANGED: "bh-text-warning-yellow"
};
