import React, { FC, useState } from "react";
import { TaskStatus } from "@/model/taskBoard/ITask";
import TaskColumnHeader from "@components/taskBoard/TaskColumnHeader";
import TaskColumnBody from "@components/taskBoard/TaskColumnBody";

interface Props {
  taskBoardColumnStatuses: Array<TaskStatus>;
}

const TaskColumn: FC<Props> = ({ taskBoardColumnStatuses }) => {
  const [newTaskContainer, setNewTaskContainer] = useState({ visible: false, status: "" } as any);

  return (
    <div className="flex h-full w-full flex-col overflow-auto p-1">
      <div className="flex w-full flex-row items-center gap-x-1">
        {taskBoardColumnStatuses.map((status, index) => {
          return <TaskColumnHeader status={status} setNewTaskContainer={setNewTaskContainer} key={index} />;
        })}
      </div>
      <div className="flex w-full flex-1 flex-row gap-x-1">
        {taskBoardColumnStatuses.map((status, index) => {
          return <TaskColumnBody status={status} newTaskContainer={newTaskContainer.status === status && newTaskContainer} setNewTaskContainer={setNewTaskContainer} key={index} />;
        })}
      </div>
    </div>
  );
};

export default TaskColumn;
