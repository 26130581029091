import React, { FC, useState } from "react";
import FormBaseFieldCheckboxWithText from "@components/form/base/body/FormBaseFieldCheckboxWithText";
import BhInputSimple from "@components/input/BhInputSimple";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import BhDatePicker from "@components/input/BhDatePicker";
import { useTranslation } from "react-i18next";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons/faCirclePlus";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import FormFieldContainerInput from "@components/form/base/body/fields/FormFieldContainerInput";
import BhIconButton from "@components/buttons/BhIconButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import { classNames } from "@/utilities/jsUtilities";
import FormBaseLabelChangeModal from "@components/form/base/body/FormBaseLabelChangeModal";

interface Props {
  fieldValue: any;
  fieldProperty: any;
  disabled?: boolean;
  saveFieldCallback: Function;
}

const FormBasePeopleField: FC<Props> = ({ fieldValue, fieldProperty, saveFieldCallback, disabled }) => {
  const { t } = useTranslation();
  const allFieldValues = Object.keys(fieldValue.columns);
  const enabledInputFields = allFieldValues.filter((key) => fieldValue.columns[key].enabled);
  const persons = fieldValue.prefilledPersons;
  const [statusLabelChangeModalOpen, setStatusLabelChangeModalOpen] = useState(false);
  const [signerLabelChangeModalOpen, setSignerLabelChangeModalOpen] = useState(false);

  const handleFormBaseFieldCheckbox = (property: string) => {
    const column = fieldValue.columns[property];
    const columnToSave = { ...column, enabled: !column.enabled };
    saveFieldCallback("columns", { ...fieldValue.columns, [property]: columnToSave });
  };

  const handleLabelSave = (changedProperty: string, changedValue: any) => {
    const column = fieldValue.columns[changedProperty];
    const columnToSave = { ...column, inputLabel: changedValue };
    saveFieldCallback("columns", { ...fieldValue.columns, [changedProperty]: columnToSave });
  };

  const addPerson = () => {
    saveFieldCallback("prefilledPersons", [...persons, { title: t("FORMBUILDER.FORM.BASE.FIELD.PEOPLE.NAME") }]);
  };

  const removePerson = (index: number) => {
    const personsToSave = persons.slice();
    personsToSave.splice(index, 1);
    saveFieldCallback("prefilledPersons", personsToSave);
  };

  const handleTitleSave = (changedProperty: string, changedValue: any, index: number) => {
    const personsToSave = persons.slice();
    const personToSave = { [changedProperty]: changedValue };
    personsToSave.splice(index, 1, personToSave);
    saveFieldCallback("prefilledPersons", personsToSave);
  };

  const saveLabels = (labels: any) => {
    handleLabelSave(labels.mainLabelProperty, labels.mainLabel);
    saveFieldCallback("statusLabels", { ...fieldValue.statusLabels, ...labels.statusLabels });
    setStatusLabelChangeModalOpen(false);
    setSignerLabelChangeModalOpen(false);
  };

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex flex-col gap-y-4">
        {persons &&
          persons.length > 0 &&
          persons.map((person: any, personIndex: number) => {
            return (
              <div className="flex w-full flex-row items-end gap-x-2" key={personIndex}>
                {enabledInputFields.map((key, columnIndex) => {
                  if (key === "status" || key === "isSigner") {
                    return (
                      <div key={columnIndex} className="min-w-24 max-w-24 flex w-24 flex-col items-center gap-y-1.5">
                        <div
                          className={classNames(
                            "min-w-24 bh-text-deep-ocean-80 text-13px w-24 whitespace-normal break-words text-center font-bold leading-4",
                            disabled || personIndex !== 0 ? "bh-bg-white hover:cursor-text" : "bh-bg-mint hover:bh-bg-mint-110 hover:cursor-pointer"
                          )}
                          onClick={() => {
                            if (disabled) return;
                            if (key === "status") setStatusLabelChangeModalOpen(true);
                            if (key === "isSigner") setSignerLabelChangeModalOpen(true);
                          }}
                        >
                          {fieldValue.columns[key]?.inputLabel}
                        </div>
                        <div className="flex h-9 flex-col justify-center">
                          <BhCheckbox isChecked={fieldValue.columns[key]?.value} onChange={() => {}} property={key} large={true} disabled={true} />
                        </div>
                      </div>
                    );
                  } else if (key === "date") {
                    return (
                      <div key={columnIndex} className="flex w-full flex-col items-center gap-y-1.5">
                        <FormFieldContainerInput initialValue={fieldValue.columns[key]?.inputLabel} property={key} onBlurCallback={handleLabelSave} disabled={disabled || personIndex !== 0} />
                        <BhDatePicker property={"date"} disabled={true} placeholder="" initialValue="" iconVisible={true} widthClasses={"w-full"} wrapperClasses={"w-full"} />
                      </div>
                    );
                  } else if (key === "name") {
                    return (
                      <div key={columnIndex} className="form-base-people-field-width flex w-full flex-col gap-y-1.5">
                        <FormFieldContainerInput
                          initialValue={person.title}
                          property={"title"}
                          onBlurCallback={(property: string, value: string) => handleTitleSave(property, value, personIndex)}
                          disabled={disabled}
                        />
                        <BhInputSimple
                          initialValue={""}
                          disabled={true}
                          placeholder={`${t("FORMBUILDER.FORM.BASE.FIELD.PEOPLE.PROFILE_INFO")} - ${t("FORMBUILDER.FORM.BASE.FIELD.PEOPLE." + key.toUpperCase())}`}
                          property="value"
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={columnIndex} className="form-base-people-field-width flex w-full flex-col gap-y-1.5">
                        <FormFieldContainerInput initialValue={fieldValue.columns[key]?.inputLabel} property={key} onBlurCallback={handleLabelSave} disabled={disabled || personIndex !== 0} />
                        <BhInputSimple
                          initialValue={""}
                          disabled={true}
                          placeholder={key !== "custom" ? `${t("FORMBUILDER.FORM.BASE.FIELD.PEOPLE.PROFILE_INFO")} - ${t("FORMBUILDER.FORM.BASE.FIELD.PEOPLE." + key.toUpperCase())}` : undefined}
                          property="value"
                        />
                      </div>
                    );
                  }
                })}
                <div className="min-w-10 max-w-10 mt-5 w-10">
                  {!disabled && persons.length > 1 && <BhIconButton icon={faXmark} buttonProps={{ onClick: () => removePerson(personIndex), disabled: disabled }} />}
                </div>
              </div>
            );
          })}
      </div>
      <div className="-ml-1">
        <BhTextOnlyButton
          icon={faCirclePlus}
          buttonProps={{
            disabled: disabled,
            onClick: addPerson
          }}
        >
          {t("FORMBUILDER.FORM.BASE.FIELD.PEOPLE.ADD_NEW")}
        </BhTextOnlyButton>
      </div>
      <div className="mt-1.5 flex flex-row gap-x-4">
        {allFieldValues.map((key, index) => {
          return key === "name" ? null : (
            <FormBaseFieldCheckboxWithText
              key={index}
              text={t("FORMBUILDER.FORM.BASE.FIELD.PEOPLE." + key.toUpperCase())}
              isChecked={fieldValue.columns[key].enabled}
              property={key}
              disabled={disabled}
              onChangeCallback={() => handleFormBaseFieldCheckbox(key)}
            />
          );
        })}
      </div>
      {statusLabelChangeModalOpen && (
        <FormBaseLabelChangeModal
          setIsModalOpen={setStatusLabelChangeModalOpen}
          mainLabelProperty="status"
          initialMainLabel={t("FORMBUILDER.FORM.BASE.FIELD.PEOPLE.STATUS")}
          currentMainLabel={fieldValue.columns.status.inputLabel}
          initialStatusLabels={{ ATTENDED: t("GLOBAL.YES"), MISSED: t("GLOBAL.NO") }}
          currentStatusLabels={{ ATTENDED: fieldValue.statusLabels.ATTENDED, MISSED: fieldValue.statusLabels.MISSED }}
          saveCallback={saveLabels}
        />
      )}
      {signerLabelChangeModalOpen && (
        <FormBaseLabelChangeModal
          setIsModalOpen={setSignerLabelChangeModalOpen}
          mainLabelProperty="isSigner"
          initialMainLabel={t("FORMBUILDER.FORM.BASE.FIELD.PEOPLE.SIGNER")}
          currentMainLabel={fieldValue.columns.isSigner.inputLabel}
          initialStatusLabels={{ SIGNER: t("FORMBUILDER.PEOPLE.SIGNER.STATUS") }}
          currentStatusLabels={{ SIGNER: fieldValue.statusLabels.SIGNER }}
          saveCallback={saveLabels}
        />
      )}
    </div>
  );
};

export default FormBasePeopleField;
