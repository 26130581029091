import React, { FC, useEffect } from "react";
import { EntityId } from "@reduxjs/toolkit";
import ConfirmationActionsFileList from "@/views/home/project/detail/confirmations/confirmation/actions/ConfirmationActionsFilesList";
import { Route, Routes, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import ConfirmationActionsBasket from "@/views/home/project/detail/confirmations/confirmation/actions/ConfirmationActionsBasket";
import { fileConfirmationFilesSelectedReset, selectAnyFilesSelectedInConfirmation } from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import { decisionsReset, fetchFileConfirmationAsync } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import ConfirmationActionsHeaderButtons from "@/views/home/project/detail/confirmations/confirmation/actions/ConfirmationActionsHeaderButtons";
import NotFound from "@/views/global/NotFound";
import ConfirmationActionsPreviewView from "@/views/home/project/detail/confirmations/confirmation/actions/ConfirmationActionsPreviewView";
import ConfirmationContainerFileComments from "@/views/home/project/detail/confirmations/confirmation/ConfirmationContainerFileComments";
import ConfirmationActionsContainerHeader from "@/views/home/project/detail/confirmations/confirmation/actions/ConfirmationActionsContainerHeader";

interface Props {
  confirmationId: EntityId;
}
const ConfirmationActionsContainer: FC<Props> = ({ confirmationId }) => {
  const anyFilesSelected = useAppSelector((state) => selectAnyFilesSelectedInConfirmation(state, confirmationId));
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchFileConfirmationAsync(confirmationId));

    return function cleanup() {
      dispatch(decisionsReset());
      dispatch(fileConfirmationFilesSelectedReset(confirmationId));
    };
  }, []);

  function ConfirmationActionsViewWithParams() {
    const { fileEntityId } = useParams();
    if (fileEntityId) {
      const fileEntityIdInt = parseInt(fileEntityId);
      return <ConfirmationActionsPreviewView confirmationId={confirmationId} selectedFileEntityId={fileEntityIdInt} />;
    }
    return <NotFound />;
  }

  return (
    <div className="bh-bg-white fixed top-0 left-0 z-30 flex h-full w-full flex-1 flex-col">
      <div className="bh-border-pigeon-20 flex flex-row items-center border-b py-2 pl-8 pr-4">
        <ConfirmationActionsContainerHeader confirmationId={confirmationId} />
        <ConfirmationActionsHeaderButtons confirmationId={confirmationId} />
      </div>
      <div className="flex h-full flex-row overflow-hidden">
        <div className="flex-1 overflow-hidden">
          <Routes>
            <Route
              path="/*"
              element={
                <>
                  <ConfirmationActionsFileList confirmationId={confirmationId} previewShown={false} />
                  {anyFilesSelected && <ConfirmationActionsBasket confirmationId={confirmationId} />}
                </>
              }
            />
            <Route path="/:fileEntityId" element={<ConfirmationActionsViewWithParams />} />
          </Routes>
        </div>
        <ConfirmationContainerFileComments fileConfirmationId={confirmationId} />
      </div>
    </div>
  );
};

export default ConfirmationActionsContainer;
