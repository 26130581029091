import React, { FC, startTransition, useCallback, useEffect, useMemo, useRef, useState } from "react";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { getUserFullName } from "@/model/IUser";
import BhInputLabel from "@components/input/BhInputLabel";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { classNames } from "@/utilities/jsUtilities";
import { debounce } from "lodash";
import { sortByFullNameAndEmail } from "@/utilities/sortUtilities";
import BhFixedDropdownParent from "@components/dropdown/BhFixedDropdownParent";

interface Props {
  label?: string;
  initialValue: string;
  property: string;
  dropdownValues: Array<ISimpleValidUserAuthority>;
  saveCallback?: Function;
  inputRef?: React.RefObject<HTMLInputElement>;
  fetchValues?: Function;
  returnOnlyString?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
}

const BhInputWithUserDropdown: FC<Props> = ({ initialValue, label, dropdownValues, autoFocus, saveCallback, inputRef, fetchValues, returnOnlyString, property, disabled }) => {
  const containedInputRef = useRef<HTMLInputElement>(null);
  const [dropdownUsers, setDropdownUsers] = useState(dropdownValues || ([] as Array<ISimpleValidUserAuthority>));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    let inputRefPropOrContained = inputRef ? inputRef : containedInputRef;
    if (inputRefPropOrContained.current) {
      if (initialValue !== undefined) inputRefPropOrContained.current.value = initialValue;
      if (initialValue === undefined && inputRefPropOrContained.current.value) inputRefPropOrContained.current.value = "";
    }

    return () => {
      debouncedOnChangeHandler.cancel();
    };
  }, [initialValue]);

  useEffect(() => {
    const inputRefPropOrContained = inputRef ? inputRef : containedInputRef;
    if (autoFocus) inputRefPropOrContained.current?.focus();
  }, []);

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault();
      startTransition(() => {
        setIsDropdownOpen(false);
        const inputValue = e.target.value;
        if (inputValue === initialValue || inputValue === "") return;
        const changedValue = { [property]: e.target.value };
        saveCallback && saveCallback(changedValue);
        if (saveCallback) {
          debouncedOnChangeHandler.cancel();
        }
      });
    },
    [saveCallback, initialValue]
  );

  const onChange = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    startTransition(() => {
      const inputValue = e.target.value;
      if (fetchValues) {
        //TODO: Siia võiks debounce'i lisada
        fetchValues &&
          fetchValues(inputValue).then((results: Array<ISimpleValidUserAuthority>) => {
            if (results.length > 0) {
              setIsDropdownOpen(true);
              setDropdownUsers(results);
            }
          });
      } else {
        const filteredUsers = dropdownValues.filter((user) => getUserFullName(user).toLowerCase().includes(inputValue));
        if (filteredUsers.length > 0) {
          setIsDropdownOpen(true);
          setDropdownUsers(filteredUsers);
        }
      }
    });
  }, []);

  const debouncedOnChangeHandler = useMemo(() => debounce(onChange, 300), [initialValue, saveCallback]);

  const onValueSelect = (value: ISimpleValidUserAuthority) => {
    let changedValue;
    const userFullName = getUserFullName(value);
    if (returnOnlyString) {
      changedValue = { [property]: userFullName };
    } else {
      changedValue = { [property]: value };
    }
    let inputRefPropOrContained = inputRef ? inputRef : containedInputRef;
    if (inputRefPropOrContained.current) {
      inputRefPropOrContained.current.value = userFullName;
    }
    saveCallback && saveCallback(changedValue);
    setIsDropdownOpen(false);
  };

  const sortedDropdownUsers = dropdownUsers.sort((a, b) => sortByFullNameAndEmail(a, b));

  return (
    <div className="relative w-full">
      {label && <BhInputLabel>{label}</BhInputLabel>}
      <div className={classNames("bh-border-pigeon-40 bh-bg-white flex flex-row justify-between whitespace-nowrap rounded border px-3", disabled && "bh-bg-smoke bh-border-smoke")}>
        <input
          ref={inputRef || containedInputRef}
          className="text-14px min-w-50px disabled:bh-text-deep-ocean-40 disabled:bh-bg-smoke disabled:bh-border-smoke flex-1 leading-5 outline-0"
          onChange={debouncedOnChangeHandler}
          onBlur={onBlur}
          disabled={disabled}
        />
      </div>
      {isDropdownOpen && (
        <div className="z-60 absolute" onMouseDown={(e) => e.preventDefault()}>
          <BhFixedDropdownParent>
            <BhDropdownMenu type={BhDropdownTypeEnum.AUTH} values={sortedDropdownUsers} onSelect={onValueSelect} numberOfItemsDisplayed={8} customDropdown={true} />
          </BhFixedDropdownParent>
        </div>
      )}
    </div>
  );
};

export default React.memo(BhInputWithUserDropdown);
