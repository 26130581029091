import { EntityId } from "@reduxjs/toolkit";

export interface IBillingRequisites {
  billingModel: IBillingModel;
  companyBillingRequisites: ICompanyBillingRequisites;
  projectBillingRequisites: IProjectBillingRequisites;
}

export interface IBillingModel {
  email: string;
  monthlyPrice: number;
  yearlyPrice: number;
  billingDate: Date;
}

export interface ICompanyBillingRequisites {
  billingDate: Date;
  companyId: EntityId;
  customerAdditionalInfo: string;
  customerAddress: string;
  customerCivilian: boolean;
  customerCountry: string;
  customerPhoneNumber: string;
  customerRecipient: string;
  customerRecipientEmails: string;
  customerRegCode: string;
  customerVatNumber: string;
  einvoice: boolean;
  einvoiceOperator: string;
  id: EntityId;
  invoiceType: CompanyInvoiceType;
}

export interface IProjectBillingRequisites {
  customerAdditionalInfo: string;
  customerAddress: string;
  customerCivilian: boolean;
  customerCountry: string;
  customerPhoneNumber: string;
  customerRecipient: string;
  customerRecipientEmails: string;
  customerRegCode: string;
  customerVatNumber: string;
  einvoice: boolean;
  einvoiceOperator: string;
  id: EntityId;
  projectId: EntityId;
  type: ProjectInvoiceType;
}

export enum CompanyInvoiceType {
  SINGLE = "SINGLE",
  AGGREGATE = "AGGREGATE"
}

export enum ProjectInvoiceType {
  MONTH = "MONTH",
  YEAR = "YEAR",
  FREE = "FREE"
}
