import React, { FC, ReactElement } from "react";
import { ChecklistRowFieldType, ChecklistRowType, IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import ChecklistBaseRowTextfield from "@components/checklists/checklistBase/body/rowTypes/Textfield/ChecklistBaseRowTextfield";
import { deleteChecklistBaseVersionRowAsync, saveChecklistBaseVersionRowAsync, selectIsNextBaseVersionRowSubPoint } from "@/app/store/checklist/checklistBaseRowsSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import ChecklistBaseRowOptionField from "@components/checklists/checklistBase/body/rowTypes/ChecklistBaseRowOptionField";
import ChecklistBaseRowWysiwigField from "@components/checklists/checklistBase/body/rowTypes/ChecklistBaseRowWysiwigField";
import ChecklistBaseRowAttachmentField from "@components/checklists/checklistBase/body/rowTypes/ChecklistBaseRowAttachmentField";
import ChecklistBaseRowCheckpoint from "@components/checklists/checklistBase/body/rowTypes/ChecklistBaseRowCheckpoint";
import { classNames } from "@/utilities/jsUtilities";
import ChecklistBaseFieldsAddButton from "@components/checklists/checklistBase/body/ChecklistBaseFieldsAddButton";
import ChecklistBaseRowCheckpointList from "@components/checklists/checklistBase/body/rowTypes/ChecklistBaseRowCheckpointList";
import ChecklistBaseRowPlusMinusField from "@components/checklists/checklistBase/body/rowTypes/plusMinus/ChecklistBaseRowPlusMinusField";

interface Props {
  companyId: EntityId;
  checklistBaseId: EntityId;
  checklistBaseVersionId: EntityId;
  baseVersionRow: IChecklistBaseVersionRow;
  saveNewRowCallback: Function;
  disabled?: boolean;
  projectId?: EntityId;
}

const ChecklistBaseRow: FC<Props> = ({ companyId, checklistBaseId, checklistBaseVersionId, baseVersionRow, saveNewRowCallback, disabled, projectId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isSubCheckpoint = baseVersionRow.checklistRowType === ChecklistRowType.CHECKPOINT && baseVersionRow.indentation === 1;
  const isNextRowSubPoint = useAppSelector((state) => selectIsNextBaseVersionRowSubPoint(state, baseVersionRow.id));
  const fieldName = isSubCheckpoint ? t("CHECKLIST_BASE.FIELD.TYPE.SUB_CHECKPOINT") : t("CHECKLIST_BASE.FIELD.TYPE." + baseVersionRow.checklistRowType);

  const saveChecklistBaseVersionRow = (changedObject: any) => {
    let baseVersionRowToSave = { ...baseVersionRow, ...changedObject };
    if (changedObject.fieldType) {
      baseVersionRowToSave.fieldValue = "";
    }
    if (baseVersionRowToSave.fieldType === ChecklistRowFieldType.DATEPICKER) {
      const fieldValue = new Date();
      fieldValue.setHours((-1 * fieldValue.getTimezoneOffset()) / 60 + 12);
      fieldValue.setMinutes(0);
      fieldValue.setSeconds(0);
      fieldValue.setMilliseconds(0);
      baseVersionRowToSave.fieldValue = fieldValue.toISOString();
    }
    if (baseVersionRowToSave.fieldType === ChecklistRowFieldType.TIMEPICKER) {
      baseVersionRowToSave.fieldValue = "12:00";
    }
    if (!baseVersionRowToSave.editable) {
      baseVersionRowToSave.required = false;
    }
    dispatch(saveChecklistBaseVersionRowAsync({ companyId: companyId, checklistBaseId: checklistBaseId, checklistBaseVersionRow: baseVersionRowToSave }));
  };

  const deleteChecklistBaseVersionRow = () => {
    dispatch(
      deleteChecklistBaseVersionRowAsync({
        companyId: companyId,
        checklistBaseId: checklistBaseId,
        checklistBaseVersionId: checklistBaseVersionId,
        checklistBaseVersionRowId: baseVersionRow.id
      })
    );
  };

  const checklistBaseRowElementMap: Record<ChecklistRowType, ReactElement> = {
    ATTACHMENTS: (
      <ChecklistBaseRowAttachmentField
        baseVersionRow={baseVersionRow}
        saveCallback={saveChecklistBaseVersionRow}
        disabled={disabled}
        checklistBaseId={checklistBaseId}
        companyId={companyId}
        projectId={projectId}
      />
    ),
    CHECKPOINT: (
      <ChecklistBaseRowCheckpoint
        baseVersionRow={baseVersionRow}
        saveCallback={saveChecklistBaseVersionRow}
        saveNewRowCallback={saveNewRowCallback}
        disabled={disabled}
        checklistBaseId={checklistBaseId}
        companyId={companyId}
        projectId={projectId}
      />
    ),
    CHECKPOINT_LIST: <ChecklistBaseRowCheckpointList baseVersionRow={baseVersionRow} saveCallback={saveChecklistBaseVersionRow} disabled={disabled} checklistBaseId={checklistBaseId} />,
    OPTION_FIELD: <ChecklistBaseRowOptionField baseVersionRow={baseVersionRow} saveCallback={saveChecklistBaseVersionRow} disabled={disabled} checklistBaseId={checklistBaseId} />,
    TEXTFIELD: <ChecklistBaseRowTextfield baseVersionRow={baseVersionRow} saveCallback={saveChecklistBaseVersionRow} disabled={disabled} checklistBaseId={checklistBaseId} />,
    WYSIWYG: <ChecklistBaseRowWysiwigField baseVersionRow={baseVersionRow} saveCallback={saveChecklistBaseVersionRow} disabled={disabled} />,
    PLUSMINUS: <ChecklistBaseRowPlusMinusField baseVersionRow={baseVersionRow} saveCallback={saveChecklistBaseVersionRow} disabled={disabled} checklistBaseId={checklistBaseId} />
  };

  return (
    <div>
      <div className={classNames("mb-4 flex flex-col", isSubCheckpoint && "ml-28")}>
        <div className="bh-bg-deep-ocean-80 bh-text-smoke flex w-fit flex-row items-center gap-x-2 rounded-t py-1 px-1.5">
          <div className="text-12px">{fieldName}</div>
          {!disabled && (
            <div className="cursor-pointer" onClick={deleteChecklistBaseVersionRow}>
              <FontAwesomeIcon icon={faXmark} size={"lg"} />
            </div>
          )}
        </div>
        <div className="bh-border-pigeon-60 rounded-b rounded-tr border">{checklistBaseRowElementMap[baseVersionRow.checklistRowType as ChecklistRowType]}</div>
      </div>
      {!disabled && !isNextRowSubPoint && <ChecklistBaseFieldsAddButton saveNewRowCallback={saveNewRowCallback} previousRow={baseVersionRow} />}
    </div>
  );
};

export default ChecklistBaseRow;
