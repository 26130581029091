import React, { FC, useEffect, useState } from "react";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhIconButton from "@components/buttons/BhIconButton";
import { faFilePlus } from "@fortawesome/pro-regular-svg-icons/faFilePlus";
import { BauhubFormTypes, BauhubMntFormTypes, ClientFormTypes, IForm } from "@/model/IForm";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveNewFormAsync } from "@/app/store/form/formSlice";
import { EntityId } from "@reduxjs/toolkit";
import { selectCurrentProject, selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { Module } from "@/model/IProject";
import { classNames } from "@/utilities/jsUtilities";
import { Menu } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { IEnabledForm } from "@/model/ICompany";
import BhTooltip from "@components/BhTooltip";
import { fetchFormBasesForProjectAsync, fetchSuggestedFormsForUserAsync, selectProjectFormBases } from "@/app/store/form/formBasesSlice";
import { IFormBase } from "@/model/form/IFormBase";
import { FormBaseVersionStatus } from "@/model/form/IFormBaseVersion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import DirectoryFileListHeaderMenuNewFormSuggestions from "@/views/home/project/detail/directory/DirectoryFileListHeaderMenuNewFormSuggestions";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faCog } from "@fortawesome/pro-regular-svg-icons/faCog";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { naturalSortByField } from "@/utilities/sortUtilities";

interface Props {
  currentDirId: EntityId;
}

const DirectoryFileListHeaderMenuNewFormButton: FC<Props> = ({ currentDirId }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const [filter, setFilter] = useState({ displayName: "" });
  const project = useAppSelector(selectCurrentProject);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const company = useAppSelector((state) => selectCompanyById(state, project.companyId));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const projectFormBases = useAppSelector(selectProjectFormBases);

  const nameTitleSuffixModuleExists = company?.modules?.find((mod: any) => mod.module === Module.CUSTOM_FORM_NAMES && mod.adminSettings && mod.adminSettings.nameTitleSuffix);
  const nameFromProjectModule = nameTitleSuffixModuleExists ? nameTitleSuffixModuleExists.adminSettings.nameTitleSuffix + "." : "";

  const isNewForm = (form: any) => {
    return form.formBaseVersions;
  };

  const formDisplayName = (form: any) => {
    if (isNewForm(form)) {
      return form.bauhubFormBase ? t("FORMS." + nameFromProjectModule + form.type) : form.type;
    } else {
      return t("FORMS." + nameFromProjectModule + form.code);
    }
  };

  // SET AVAILABLE FORMS
  const notMntForms = projectFormBases?.filter((enabledForm) => !(BauhubMntFormTypes as Array<string>).includes(enabledForm.type));
  const mntForms = projectFormBases?.filter((enabledForm) => (BauhubMntFormTypes as Array<string>).includes(enabledForm.type)).map((form) => ({ ...form, displayName: formDisplayName(form) }));

  useEffect(() => {
    dispatch(fetchFormBasesForProjectAsync(project.id));
    dispatch(fetchSuggestedFormsForUserAsync());
  }, []);

  const enabledCompanyFormsWithoutType = company?.enabledForms?.filter((enabledForm) => !enabledForm.type);
  const oldCustomForms = enabledCompanyFormsWithoutType?.filter(
    (enabledForm) => !BauhubFormTypes.includes(enabledForm.code) && !BauhubMntFormTypes.includes(enabledForm.code) && !ClientFormTypes.includes(enabledForm.code)
  );

  // PROJECT BASED CUSTOM ENABLED FORMS
  const addSCM_NBtoEMBACH_NOBE_COOP = project?.modules?.some((mod) => mod.module === Module.EMBACH_NOBE_COOP);
  const addKOOSOLEK_NORDECONtoNOBE_NORDECON_COOP = project?.modules?.some((mod) => mod.module === Module.NOBE_NORDECON_COOP);

  const oldEnabledForms =
    ((oldCustomForms &&
      [
        ...oldCustomForms,
        addSCM_NBtoEMBACH_NOBE_COOP && ({ id: -1, code: "SCM_NB" } as IEnabledForm),
        addKOOSOLEK_NORDECONtoNOBE_NORDECON_COOP && ({ id: -2, code: "KOOSOLEK_NORDECON" } as IEnabledForm)
      ].filter(Boolean)) as Array<IEnabledForm>) || [];

  const allVisibleForms = [...notMntForms, ...oldEnabledForms].map((form) => ({ ...form, displayName: formDisplayName(form) }));

  const createNewForm = (form: IFormBase | IEnabledForm) => {
    if (currentDirId) {
      if (isNewForm(form)) {
        const formBase = form as IFormBase;
        if (formBase.formBaseVersions) {
          const lastVersion = formBase.formBaseVersions.filter((base) => base.status === FormBaseVersionStatus.FINAL).reduce((max, round) => (max.versionNumber > round.versionNumber ? max : round));
          const form = {
            formType: formBase.type,
            locked: false,
            formBaseVersionId: lastVersion.id
          } as IForm;
          dispatch(saveNewFormAsync({ projectId: project.id, dirId: currentDirId, formToSave: form })).then((response: any) => {
            const navigateOptions = { state: { previousState: "directoryView" } };
            navigate(`/project/${project.id}/dir/${currentDirId}/form/${response.payload.fileEntityId}`, navigateOptions);
          });
        }
      } else {
        const formBase = form as IEnabledForm;
        navigate(`/project/${project.id}/dir/${currentDirId}/form/new`, { state: formBase.code });
      }
    }
  };

  const navigateToSettings = () => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "/project/" + projectId + "/forms/settings");
  };

  return (
    <>
      <BhDropdown
        button={
          <BhTooltip body={t("GLOBAL.CREATE_FORM")}>
            <BhIconButton icon={faFilePlus} />
          </BhTooltip>
        }
        menu={
          <div className={classNames("bh-shadow bh-bg-raised-white max-h-minus-200px flex max-w-[70vw] flex-row overflow-hidden rounded")}>
            <div className="flex flex-col">
              <div className="bh-bg-smoke-50 flex h-12 flex-row items-center p-4">
                <BhSearchInputWBG
                  initialValue={filter?.displayName}
                  property={"displayName"}
                  placeholder={t("FORM.SEARCH.PLACEHOLDER") as string}
                  onChangeCallback={(value: any) => setFilter(value)}
                  autoFocus={true}
                  withoutBorder={true}
                  inputClasses="bh-bg-smoke-50 hover:bh-bg-smoke-50 filled:bh-bg-smoke-50"
                />
              </div>
              <div className="flex flex-1 flex-col overflow-y-auto p-4">
                <div className="flex flex-row lg:flex-col">
                  <DirectoryFileListHeaderMenuNewFormSuggestions currentDirId={currentDirId} allVisibleForms={allVisibleForms} createNewForm={createNewForm} filter={filter} />
                  <div className="flex w-[240px] flex-col px-2">
                    {isProjectAdmin && (
                      <div className={classNames(filter?.displayName.length > 1 && "hidden", "pt-1")}>
                        <BhSecondaryButton buttonProps={{ onClick: navigateToSettings }} icon={faCog}>
                          {t("FORM.PROJECT_TEMPLATES")}
                        </BhSecondaryButton>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row lg:flex-col">
                  <div className="flex w-[440px] flex-col px-2">
                    <p className="mb-0 mt-4 px-2 text-sm font-bold">{t("FORMS.TEMPLATES")}</p>
                    <div className="flex flex-row gap-x-4">
                      <ul className="w-full">
                        {allVisibleForms
                          ?.filter((f) => f?.displayName.toLowerCase().includes(filter?.displayName.toLowerCase()))
                          .sort((a, b) => naturalSortByField(a, b, "displayName"))
                          .map((enabledForm) => {
                            return (
                              <Menu.Item as={"div"} key={enabledForm.id} onClick={() => createNewForm(enabledForm)}>
                                <li className="hover:bh-bg-smoke my-0.5 flex cursor-pointer items-center rounded px-2 py-1">
                                  <FontAwesomeIcon icon={faArrowRight} className="pr-1.5 text-sm" />
                                  {enabledForm?.displayName}
                                </li>
                              </Menu.Item>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                  <div className="flex w-[240px] flex-col px-2">
                    <p className="mb-0 mt-4 px-2 text-sm font-bold">{t("FORMS.MNT")}</p>
                    <div className="flex flex-row gap-x-4">
                      <ul className="w-full">
                        {mntForms
                          ?.filter((f) => f?.displayName.toLowerCase().includes(filter?.displayName.toLowerCase()))
                          .sort((a, b) => naturalSortByField(a, b, "displayName"))
                          .map((enabledForm) => {
                            return (
                              <Menu.Item as={"div"} key={enabledForm.id} onClick={() => createNewForm(enabledForm)}>
                                <li className="hover:bh-bg-smoke my-0.5 cursor-pointer rounded px-2 py-1">
                                  <FontAwesomeIcon icon={faArrowRight} className="pr-1.5 text-sm" />
                                  {enabledForm?.displayName}
                                </li>
                              </Menu.Item>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        position={BhDropdownPositionEnum.BOTTOM_RIGHT}
      />
    </>
  );
};

export default DirectoryFileListHeaderMenuNewFormButton;
