import React, { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const BhSpreadsheetTableBody: FC<Props> = ({ children }) => {
  return <tbody>{children}</tbody>;
};

export default React.memo(BhSpreadsheetTableBody);
