import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import {
  selectConfirmedFileConfirmationFilesForConfirmation,
  selectDeclinedFileConfirmationFilesForConfirmation,
  selectPendingFileConfirmationFilesForConfirmation
} from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import { FileConfirmationDecisionType } from "@/model/confirmations/IFileConfirmationDecisionV2";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";

interface Props {
  confirmationId: EntityId;
  type: FileConfirmationDecisionType;
}

const ConfirmationFilesCount: FC<Props> = ({ confirmationId, type }) => {
  const pendingFiles = useAppSelector((state) => selectPendingFileConfirmationFilesForConfirmation(state, confirmationId));
  const confirmedFiles = useAppSelector((state) => selectConfirmedFileConfirmationFilesForConfirmation(state, confirmationId));
  const declinedFiles = useAppSelector((state) => selectDeclinedFileConfirmationFilesForConfirmation(state, confirmationId));

  const countMap: Record<FileConfirmationDecisionType, number> = {
    [FileConfirmationDecisionType.PENDING]: pendingFiles.length,
    [FileConfirmationDecisionType.CONFIRMED]: confirmedFiles.length,
    [FileConfirmationDecisionType.DECLINED]: declinedFiles.length
  };

  const badgeMap: Record<FileConfirmationDecisionType, BhBadgeType> = {
    [FileConfirmationDecisionType.PENDING]: BhBadgeType.YELLOW,
    [FileConfirmationDecisionType.CONFIRMED]: BhBadgeType.DARK,
    [FileConfirmationDecisionType.DECLINED]: BhBadgeType.ERROR
  };

  return (
    <BhBadge type={countMap[type] === 0 ? BhBadgeType.SUBTLE : badgeMap[type]}>
      <span>{countMap[type]}</span>
    </BhBadge>
  );
};

export default ConfirmationFilesCount;
