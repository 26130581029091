import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentCompany, setCompanyUuid } from "@/app/store/companiesSlice";
import { fetchPresignedLogoUploadUrl } from "@/api/fileAPI";
import { ICompanyLogoDTO } from "@/model/ICompanyLogoDTO";
import CompanyLogoUploadButton from "@/views/home/company/detail/CompanyLogoUploadButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-regular-svg-icons/faImage";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const CompanyLogo: FC = () => {
  const { t } = useTranslation();
  const company = useAppSelector(selectCurrentCompany);
  const [isImageMissing, setIsImageMissing] = useState(false);
  const dispatch = useAppDispatch();
  const [imageSrc, setImageSrc] = useState("https://s3.eu-central-1.amazonaws.com/www.bauhub.ee/public/logo/" + company.uuid + "?" + dayjs().unix());

  // TODO: päringud peaksid olema API failides
  function FileUpload(file: any, dto: ICompanyLogoDTO) {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", dto.logoUrl, true);
    xhr.setRequestHeader("Content-Type", file.type + ";charset=UTF-8");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        dispatch(setCompanyUuid(dto.companyUuid));
        setImageSrc("https://s3.eu-central-1.amazonaws.com/www.bauhub.ee/public/logo/" + dto.companyUuid + "?" + dayjs().unix());
      }
    };
    xhr.send(file);
  }

  const onFileSelected = (file: File) => {
    // TODO: võibolla see kutse liigutada kuhugi thunki, et seda komponendis sees mitte hoida
    async function fetchLogoUrl(companyId: EntityId, contentType: string) {
      const response = await fetchPresignedLogoUploadUrl(companyId, contentType);
      return response;
    }
    return fetchLogoUrl(company.id, file.type).then((dto) => {
      FileUpload(file, dto);
    });
  };

  return (
    <div className="mt-4">
      <div className="flex flex-row justify-center">
        <img
          className="max-h-[120px] max-w-[120px]"
          src={imageSrc}
          alt="Company Logo"
          crossOrigin=""
          onError={(e) => {
            e.currentTarget.style.display = "none";
            setIsImageMissing(true);
          }}
        />
      </div>

      {isImageMissing && (
        <div className="flex items-center">
          <div className="bh-bg-smoke flex h-[120px] w-[120px] items-center justify-center  ">
            <FontAwesomeIcon icon={faImage} className="bh-text-pigeon-60 w-16px h-[14px]" />
          </div>
        </div>
      )}
      <div>
        <CompanyLogoUploadButton onFileSelected={onFileSelected}>{t("COMPANY.SETTINGS.LOGO.UPLOAD")}</CompanyLogoUploadButton>
      </div>
    </div>
  );
};

export default CompanyLogo;
