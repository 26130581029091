import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { sendPhoneNumberVerificationCode, verifyPhoneNumberVerificationCode } from "@/api/verificationAPI";
import { saveCurrentUserTwoFactorPreferenceAsync, selectCurrentUserInfo } from "@/app/store/userSlice";
import BhModal from "@components/modal/BhModal";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { getNumberAndPrefix } from "@/model/IUserInfo";
import { toggleUserProfileModalsOpen } from "@/app/store/userPreferenceSlice";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { BhSectionMessageError } from "@components/sectionMessage/BhSectionMessages";
import BhInputWithPrefixDropdown from "@components/input/BhInputWithPrefixDropdown";

const ProfileAuthSettingsPhoneModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [verification, setVerification] = useState({ number: "", code: "" });
  const [isWrongCode, setWrongCode] = useState(false);
  const userInfo = useAppSelector(selectCurrentUserInfo);
  const [status, setStatus] = useState("numberInput");
  const [telephonePrefix, setTelephonePrefix] = useState(userInfo && userInfo.contactNumber ? getNumberAndPrefix(userInfo.contactNumber).prefix : "+372");
  const [telephoneNumber, setTelephoneNumber] = useState(userInfo && userInfo.contactNumber ? getNumberAndPrefix(userInfo.contactNumber).number : undefined);

  const isValidNumber = () => {
    const combined = telephonePrefix + telephoneNumber;
    return telephoneNumber && /\+\d{9,}/.test(combined);
  };

  const startPhoneNumberVerification = () => {
    const combined = telephonePrefix + telephoneNumber;
    const validNumber = telephoneNumber && /\+\d{9,}/.test(combined);
    if (validNumber) {
      sendPhoneNumberVerificationCode(combined)
        .then(() => {
          setVerification({ ...verification, number: combined });
          setStatus("verificationInput");
        })
        .catch((error) => console.log(error));
    }
  };

  const verifyPhoneNumber = () => {
    verifyPhoneNumberVerificationCode(verification.number, verification.code)
      .then((res) => {
        dispatch(saveCurrentUserTwoFactorPreferenceAsync(true)).then(() => {
          setStatus("success");
          setWrongCode(false);
        });
      })
      .catch((error) => {
        if (error.message === "Wrong code!") {
          setWrongCode(true);
        }
      });
  };

  const closeModal = () => {
    dispatch(toggleUserProfileModalsOpen({ modal: "profileAuthSettingsPhoneModal" }));
  };

  if (status === "numberInput") {
    return (
      <BhModal
        isShown={true}
        setIsShown={() => {}}
        onClose={closeModal}
        header={<h2>{t("GLOBAL.TWO_FACTOR")}</h2>}
        children={
          <div className="my-3 mx-8 flex w-3/4 flex-col">
            <p className="mt-6">{t("USER.PROFILE.2FA.ADD_PHONE_NR")}</p>
            <div className="my-6">
              <BhInputWithPrefixDropdown
                initialPrefix={telephonePrefix}
                initialValue={telephoneNumber}
                property="number"
                type="tel"
                dropdownElements={["+", "+372", "+371", "+370", "+358", "+381", "+39", "+82"]}
                onChangeCallback={(value: { prefix: string; number: string }) => {
                  if (value.prefix !== undefined) setTelephonePrefix(value.prefix);
                  if (value.number !== undefined) setTelephoneNumber(value.number);
                }}
                label={`${t("GLOBAL.PHONE_NUMBER")}`}
              />
            </div>
          </div>
        }
        footer={
          <div className="flex items-center">
            <BhTextOnlyButton buttonProps={{ onClick: closeModal }}>{t("GLOBAL.CANCEL")}</BhTextOnlyButton>
            <BhPrimaryButton buttonProps={{ onClick: () => startPhoneNumberVerification(), disabled: !isValidNumber() }}>{t("USER.PROFILE.NUMBER.CHANGE.MODAL.SUBMIT")}</BhPrimaryButton>
          </div>
        }
      />
    );
  } else if (status === "verificationInput") {
    return (
      <BhModal
        isShown={true}
        setIsShown={() => {}}
        onClose={closeModal}
        header={<h2>{t("GLOBAL.TWO_FACTOR")}</h2>}
        children={
          <div className="my-8 mx-8 flex w-3/4 flex-col">
            {isWrongCode && (
              <BhSectionMessageError
                children={
                  <div className={"inline-block"}>
                    <span className="pr-1">{t("USER.PROFILE.2FA.WRONG_VC")}</span>
                    <button onClick={() => startPhoneNumberVerification()}>
                      <span className="font-semibold underline underline-offset-1 hover:underline-offset-2">{t("USER.PROFILE.2FA.RESEND_VC")}</span>
                    </button>
                  </div>
                }
              />
            )}
            <span className="pt-2">{t("USER.PROFILE.2FA.VERIFICATION.MODAL.BODY")}</span>
            <div className="mt-6">
              <form className="flex flex-row">
                <BhInputStackedLabel
                  initialValue={verification.code}
                  property="code"
                  onChangeCallback={(value: { code: string }) => setVerification({ ...verification, ...value })}
                  label={`${t("GLOBAL.CONTROL_CODE")}`}
                />
              </form>
            </div>
            {!isWrongCode && (
              <div className={"mt-4 inline-block"}>
                <span className="pr-1">{t("USER.PROFILE.2FA.VERIFICATION.MODAL.VC_NOT_RECEIVED")}</span>
                <button onClick={() => startPhoneNumberVerification()}>
                  <span className="font-semibold underline underline-offset-1 hover:underline-offset-2">{t("USER.PROFILE.2FA.RESEND_VC")}</span>
                </button>
              </div>
            )}
          </div>
        }
        footer={
          <div className="flex items-center">
            <BhTextOnlyButton
              buttonProps={{
                onClick: () => {
                  closeModal();
                  setStatus("numberInput");
                }
              }}
            >
              {t("GLOBAL.CANCEL")}
            </BhTextOnlyButton>
            <BhPrimaryButton buttonProps={{ onClick: () => verifyPhoneNumber() }}>{t("MODAL.DEFAULT.CONFIRMATION.OK")}</BhPrimaryButton>
          </div>
        }
      />
    );
  } else if (status === "success") {
    return (
      <BhModal
        isShown={true}
        setIsShown={() => {}}
        onClose={closeModal}
        header={
          <div className="flex flex-row items-center">
            <FontAwesomeIcon icon={faCheckCircle} className="w-24px h-24px bh-text-success-green mr-4" size="2x" aria-hidden="true" />
            <h2>{t("USER.PROFILE.2FA.VERIFICATION.SUCCESS")}</h2>
          </div>
        }
        children={
          <div className="py-6 px-8">
            <p>{t("USER.PROFILE.2FA.VERIFICATION.SUCCESS.BODY")}</p>
          </div>
        }
        footer={
          <div>
            <BhPrimaryButton
              buttonProps={{
                onClick: () => {
                  closeModal();
                  setStatus("numberInput");
                }
              }}
            >
              {t("GLOBAL.CLOSE")}
            </BhPrimaryButton>
          </div>
        }
      />
    );
  } else {
    return <div />;
  }
};

export default ProfileAuthSettingsPhoneModal;
