import React, { FC } from "react";
import ChecklistGeneralInfo from "@components/checklists/checklist/body/ChecklistGeneralInfo";
import ChecklistBaseFile from "@components/checklists/checklist/body/ChecklistBaseFile";
import ChecklistRows from "@components/checklists/checklist/body/ChecklistRows";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  checklistId: EntityId;
  checklistRoundId: EntityId;
}

const ChecklistBody: FC<Props> = ({ checklistId, checklistRoundId }) => {
  return (
    <div className="flex flex-col overflow-auto px-36 pt-4 pb-32 xl:px-6">
      <ChecklistGeneralInfo checklistId={checklistId} checklistRoundId={checklistRoundId} />
      <ChecklistBaseFile checklistRoundId={checklistRoundId} />
      <ChecklistRows checklistId={checklistId} checklistRoundId={checklistRoundId} />
    </div>
  );
};

export default ChecklistBody;
