export const pricesMap: Record<string, number> = {
  tier1ProMonthlyCost: 150,
  tier1ProYearlyCost: 1500,
  tier2ProMonthlyCost: 120,
  tier2ProYearlyCost: 1200,
  tier3ProMonthlyCost: 110,
  tier3ProYearlyCost: 1100,
  tier4ProMonthlyCost: 90,
  tier4ProYearlyCost: 900,
  tier1MonthlyCost: 125,
  tier1YearlyCost: 1250,
  tier2MonthlyCost: 100,
  tier2YearlyCost: 1000,
  tier3MonthlyCost: 90,
  tier3YearlyCost: 900,
  tier4MonthlyCost: 75,
  tier4YearlyCost: 750
};

export function checkIfCompanyHasDefaultPricingSettings(billingInfo: any) {
  for (const key in pricesMap) {
    if (pricesMap[key] !== billingInfo[key]) {
      return false;
    }
  }
  return true;
}
