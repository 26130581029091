import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import ChecklistStatistics from "@/views/home/project/detail/checklists/statistics/ChecklistStatistics";
import ChecklistProjectSettings from "@/views/home/project/detail/checklists/settings/ChecklistProjectSettings";

interface Props {}

const ChecklistAdminContainer: FC<Props> = () => {
  return (
    <Routes>
      <Route path="statistics" element={<ChecklistStatistics />} />
      <Route path="settings" element={<ChecklistProjectSettings />} />
    </Routes>
  );
};

export default ChecklistAdminContainer;
