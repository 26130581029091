import React, { FC } from "react";
import BhTableRow from "@components/table/BhTableRow";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import BhUserIconWithName from "@components/user/BhUserIconWithName";

interface Props {
  admin: ISimpleValidUserAuthority;
}

const BOCompanyProjectAdministratorRow: FC<Props> = ({ admin }) => {
  return (
    <BhTableRow key={admin.id}>
      <td>
        <BhUserIconWithName user={admin} adminBadgeVisible={true} />
      </td>
      <td>
        <a href={"mailto:" + admin.username} className="bh-underline-link underline-offset-4">
          {admin.username}
        </a>
      </td>
      <td>{admin.userInfo?.contactNumber}</td>
      <td>{admin.userInfo?.title}</td>
    </BhTableRow>
  );
};

export default BOCompanyProjectAdministratorRow;
