import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import BhProgressBullet from "@components/progress/BhProgressBullet";
import BhProgressFooter from "@components/progress/BhProgressFooter";
import { BhProgressBulletStatus } from "@/model/utilities/BhProgressBulletStatus";
import { Trans } from "react-i18next";
import { IProgressContainerStepState } from "@/model/tabs/IProgressContainerStepState";
import { isValidEmail, isValidEmails } from "@/utilities/jsUtilities";

interface Props {
  steps: Array<IProgressContainerStepState>;
  setSteps: Dispatch<SetStateAction<Array<IProgressContainerStepState>>>;
  content: Array<React.ReactElement>;
  header?: string;
  form: any;
  onSubmit: Function;
  onCancel: Function;
  disabled?: boolean;
  finalizeButtonTitle?: string;
}

const BhProgressContainer: FC<Props> = ({ steps, setSteps, content, header, form, onSubmit, onCancel, disabled, finalizeButtonTitle }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    validate();
  }, [form, selectedIndex]);

  const onTabChange = (newIndex: number) => {
    let currentStep = steps[selectedIndex];
    let newStep = steps[newIndex];
    if (currentStep.properties) {
      const anySelected = currentStep.properties?.some((property) => form[property]);
      if (!anySelected) {
        currentStep.status = BhProgressBulletStatus.SKIPPED;
      } else {
        currentStep.status = BhProgressBulletStatus.FINISHED;
      }
    } else {
      currentStep.status = currentStep.isDirty ? BhProgressBulletStatus.UPCOMING : BhProgressBulletStatus.FINISHED;
    }
    const updatedSteps = steps.map((step, index) => {
      const isCurrentStep = index === selectedIndex;
      const isNewStep = index === newIndex;
      if (isCurrentStep) return currentStep;
      if (isNewStep) return { ...newStep, status: BhProgressBulletStatus.CURRENT };
      return step;
    });
    setSteps(updatedSteps);
    newIndex < steps.length && setSelectedIndex(newIndex);
    steps[newIndex].status = BhProgressBulletStatus.CURRENT;
  };

  const validate = () => {
    const currentStep = steps[selectedIndex];
    let isDirty: boolean;
    if (currentStep?.validation && currentStep.validation.length > 0) {
      isDirty = currentStep?.validation?.some((property) => {
        const isNestedProperty = property.match(/\./g);
        if (isNestedProperty) {
          const propertyArray = property.split(".");
          const parentProperty = propertyArray[0];
          const childProperty = propertyArray[1];
          const parentObject = form[parentProperty];
          if (!parentObject) return true;
          const childObject = parentObject[childProperty];
          if (childProperty.toLowerCase().includes("emails")) {
            return !isValidEmails(childObject);
          } else if (childProperty.toLowerCase().includes("email")) {
            return !isValidEmail(childObject);
          } else {
            if (parentObject.customerCivilian && childProperty === "customerRegCode") {
              return false;
            }
            return !childObject || childObject?.length === 0;
          }
        } else {
          if (property?.toLowerCase().includes("emails")) {
            return !isValidEmails(form[property]);
          } else if (property?.toLowerCase().includes("email")) {
            return !isValidEmail(form[property]);
          } else {
            return form[property] === undefined || form[property]?.length === 0;
          }
        }
      });

      const updatedSteps = steps.map((step, index) => {
        return index === selectedIndex ? { ...currentStep, isDirty: isDirty || false } : step;
      });
      setSteps(updatedSteps);
    }
  };

  return (
    <div className="flex w-full">
      <Tab.Group selectedIndex={selectedIndex} onChange={onTabChange} vertical={true}>
        <Tab.List className="bh-bg-smoke w-1/3 min-w-[280px] max-w-[360px]">
          <div className="mt-11 ml-16 flex flex-col gap-7">
            <h2>
              <Trans>{header}</Trans>
            </h2>
            {steps.map((step, stepIdx) => {
              return (
                <Tab key={step.id} disabled={step.status === BhProgressBulletStatus.UPCOMING}>
                  <BhProgressBullet stepNumber={stepIdx + 1} status={step.status} stepHeader={step.header} />
                </Tab>
              );
            })}
          </div>
        </Tab.List>
        <div className="flex w-full flex-1 flex-col overflow-hidden">
          <Tab.Panels className="h-full overflow-y-auto focus-visible:outline-0">
            {steps.map((step) => {
              return (
                <Tab.Panel key={step.id + step.header} className="flex h-full justify-center overflow-y-auto pt-11">
                  {content[step.id as number]}
                </Tab.Panel>
              );
            })}
          </Tab.Panels>
          <BhProgressFooter
            onNextButtonClick={() => onTabChange(selectedIndex + 1)}
            onPrevButtonClick={() => onTabChange(selectedIndex - 1)}
            onCancel={onCancel}
            selectedIndex={selectedIndex}
            stepsLength={steps.length}
            disabled={steps[selectedIndex]?.isDirty || disabled}
            onSubmit={onSubmit}
            finalizeButtonTitle={finalizeButtonTitle}
          />
        </div>
      </Tab.Group>
    </div>
  );
};

export default BhProgressContainer;
