import React, { FC, useEffect, useState } from "react";
import { MetaObject, Viewer } from "@xeokit/xeokit-sdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from "@fortawesome/pro-solid-svg-icons/faSquareCheck";
import { faSquare } from "@fortawesome/pro-regular-svg-icons/faSquare";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons/faAngleRight";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import { LoadedObjectInfo } from "@/views/home/project/detail/xeokit/XeokitWebViewer";
import { naturalSortString } from "@/utilities/sortUtilities";
import { Virtuoso } from "react-virtuoso";
import FileNameWrapped from "@/features/fileContainer/FileNameWrapped";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUserLanguageForTranslation } from "@/app/store/userSlice";

interface Props {
  viewer: Viewer;
  loadedModels: Array<LoadedObjectInfo>;
}

interface TypeNodeElementProps {
  node: MetaObject;
  viewer: Viewer;
}

const TypeNodeElement: FC<TypeNodeElementProps> = ({ node, viewer }) => {
  const isVisible = viewer.scene.objects[node.id].visible;

  const toggleVisibility = () => {
    viewer.scene.objects[node.id].visible = !viewer.scene.objects[node.id].visible;
  };

  return (
    <div>
      <div className="flex flex-row gap-x-1 py-0.5 md:gap-x-2 md:py-2 md:text-[1.2rem]">
        <div className="h-4 w-4 flex-shrink-0 md:h-5 md:w-5"></div>
        <FontAwesomeIcon
          className="bh-text-bauhub-green-120 py-0.25 relative top-0 cursor-pointer text-lg md:-top-1 md:h-6 md:text-2xl"
          icon={isVisible ? faSquareCheck : faSquare}
          onClick={toggleVisibility}
        />
        <div onClick={() => console.log(node.id)} className="w-60">
          <FileNameWrapped fileName={node.name} />
        </div>
      </div>
    </div>
  );
};

interface TypeNodeParentProps {
  type: string;
  nodes: { [p: string]: MetaObject };
  viewer: Viewer;
}

const TypeNodeParent: FC<TypeNodeParentProps> = ({ type, nodes, viewer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const visibleChildren = Object.values(nodes)
    .filter((node: MetaObject) => viewer.scene.objects[node.id])
    .sort((a, b) => naturalSortString(a.name + "" + a.id, b.name + "" + b.id));
  const virtualizedListHeight = visibleChildren.length * 24 > 300 ? "300px" : visibleChildren.length * 24 + "px";

  const someSceneObjectsInSubTreeHidden = visibleChildren.some((node) => {
    return !viewer.scene.objects[node.id].visible;
  });

  const isVisible = !someSceneObjectsInSubTreeHidden;
  const hasVisibleChildren = visibleChildren && visibleChildren.length > 0;

  const toggleVisibility = () => {
    viewer.scene.setObjectsVisible(viewer.metaScene.getObjectIDsByType(type), someSceneObjectsInSubTreeHidden ? true : false);
  };

  if (!hasVisibleChildren) return null;

  return (
    <div>
      <div className="flex flex-row gap-x-1 py-0.5 md:gap-x-2 md:py-2 md:text-[1.2rem]">
        <div className="h-4 w-4 flex-shrink-0 md:h-5 md:w-5">
          {hasVisibleChildren && <FontAwesomeIcon className="h-4 w-4 cursor-pointer md:h-5 md:w-5" icon={isOpen ? faAngleDown : faAngleRight} onClick={() => setIsOpen(!isOpen)} />}
        </div>
        <FontAwesomeIcon
          className="bh-text-bauhub-green-120 py-0.25 relative top-0 cursor-pointer text-lg md:-top-1 md:h-6 md:text-2xl"
          icon={isVisible ? faSquareCheck : faSquare}
          onClick={toggleVisibility}
        />
        <div>{type}</div>
      </div>
      {isOpen && hasVisibleChildren && (
        <div className="bh-bg-smoke rounded-sm pl-2">
          <Virtuoso
            style={{ height: virtualizedListHeight, width: "100%", overflowX: "hidden" }}
            totalCount={visibleChildren.length}
            itemContent={(index) => <TypeNodeElement node={visibleChildren[index]} viewer={viewer} key={visibleChildren[index].id} />}
          />
        </div>
      )}
    </div>
  );
};

const XeokitSidebarMenuTypesTreeView: FC<Props> = ({ viewer, loadedModels }) => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguageForTranslation);
  const [randomNumber, setRandomNumber] = useState<number>(1);

  useEffect(() => {
    const onObjectVisibility = viewer.scene.on("objectVisibility", (entity) => {
      setRandomNumber(Math.floor(Math.random() * 100000));
    });

    return function cleanup() {
      viewer.scene.off(onObjectVisibility);
    };
  }, []);

  const metaObjectsByType = viewer.metaScene.metaObjectsByType;
  const types = Object.keys(metaObjectsByType);
  const allVisible = Object.keys(viewer.scene.objects).length === Object.keys(viewer.scene.visibleObjects).length;

  const toggleAllVisible = () => {
    types.forEach((type) => {
      viewer.scene.setObjectsVisible(viewer.metaScene.getObjectIDsByType(type), !allVisible);
    });
  };

  return (
    <div>
      <div className="flex flex-row gap-x-1 py-0.5 md:gap-x-2 md:py-2 md:text-[1.2rem]">
        <div className="h-4 w-4 flex-shrink-0 md:h-5 md:w-5"></div>
        <FontAwesomeIcon
          className="bh-text-bauhub-green-120 py-0.25 relative top-0 cursor-pointer text-lg md:-top-1 md:h-6 md:text-2xl"
          icon={allVisible ? faSquareCheck : faSquare}
          onClick={toggleAllVisible}
        />
        <div>{t("BIM.SIDEBAR.SELECT_ALL", { lng: currentUserLanguage })}</div>
      </div>
      {types.map((type) => {
        const nodes = metaObjectsByType[type] as unknown as { [p: string]: MetaObject };
        return <TypeNodeParent key={type} type={type} nodes={nodes} viewer={viewer} />;
      })}
    </div>
  );
};

export default XeokitSidebarMenuTypesTreeView;
