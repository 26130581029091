import React, { FC, useEffect } from "react";

import { useLocation } from "react-router-dom";
import { setSidebarCollapsed } from "@/app/store/sidebarSlice";
import FileContainerTableHeader from "@/views/home/project/detail/confirmations/confirmation/tableHeader/FileContainerTableHeader";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchFilesAsync, selectFilesStatus } from "@/app/store/filesSlice";
import DirectoryFileListHeaderMenu from "@/views/home/project/detail/directory/DirectoryFileListHeaderMenu";
import DirectoryFileListFilter from "@/views/home/project/detail/directory/DirectoryFileListFilter";
import DirectoryFileInfoBar from "@/views/home/project/detail/directory/fileInfoBar/DirectoryFileInfoBar";
import DirectoryModals from "@/views/home/project/detail/directory/directoryModals/DirectoryModals";
import DirectoryFileBasketContainer from "@/views/home/project/detail/directory/DirectoryFileBasketContainer";
import DirectoryFileListContainerWithUploadDrop from "@/views/home/project/detail/directory/DirectoryFileListContainerWithUploadDrop";
import DirectoryBreadcrumbs from "@/views/home/project/detail/directory/DirectoryBreadcrumbs";
import { EntityId } from "@reduxjs/toolkit";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { selectFolderById } from "@/app/store/foldersSlice";
import { selectIsCurrentUserProjectAdmin, selectPrivilegesForDir } from "@/app/store/userSlice";
import { shallowEqual } from "react-redux";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import { Trans } from "react-i18next";

interface Props {
  currentDirId: EntityId;
}

const Directory: FC<Props> = ({ currentDirId }) => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const status = useAppSelector(selectFilesStatus);
  const currentDirectory = useAppSelector((state) => selectFolderById(state, currentDirId));
  const isCurrentUserProjectAdmin = useAppSelector((state) => currentDirectory && selectIsCurrentUserProjectAdmin(state, projectId));
  const privilegesForDirectory = useAppSelector((state) => currentDirectory && selectPrivilegesForDir(state, currentDirId, currentDirectory, isCurrentUserProjectAdmin), shallowEqual);

  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSidebarCollapsed(true));
    if (currentDirId) {
      dispatch(fetchFilesAsync(currentDirId));
    }
  }, [location, currentDirId]);

  if (!privilegesForDirectory) {
    return (
      <BhNoMatchesImage>
        <Trans>DIRECTORY.NO_PRIVILEGES</Trans>
      </BhNoMatchesImage>
    );
  }

  const differentProjectThanDirectory = currentDirectory?.projectId && projectId && currentDirectory.projectId !== projectId;
  if (status === BhStateStatusType.ERROR || differentProjectThanDirectory) {
    return (
      <BhNoMatchesImage>
        <Trans>DIRECTORY.NON_EXISTANT</Trans>
      </BhNoMatchesImage>
    );
  }

  return (
    <div className="flex h-full w-full min-w-0 flex-row overflow-hidden">
      <div className="flex w-full flex-col overflow-hidden px-6">
        <div className="flex-2 mt-3 mb-1 flex flex-row items-center">
          <DirectoryBreadcrumbs currentDirId={currentDirId} />
        </div>
        <div className="flex-2">
          <DirectoryFileListHeaderMenu currentDirId={currentDirId} privilegesForDirectory={privilegesForDirectory} />
          <DirectoryFileListFilter />
          <FileContainerTableHeader privilegesForDirectory={privilegesForDirectory} />
        </div>
        <DirectoryFileListContainerWithUploadDrop currentDirId={currentDirId} privilegesForDirectory={privilegesForDirectory} />
      </div>
      <DirectoryFileInfoBar privilegesForDirectory={privilegesForDirectory} />
      <DirectoryFileBasketContainer privilegesForDirectory={privilegesForDirectory} currentDirectoryId={currentDirId} />
      <DirectoryModals currentDirectoryId={currentDirId} />
    </div>
  );
};

export default Directory;
