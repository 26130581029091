import React, { FC } from "react";
import BhChildComponentWithFetchValues from "@components/input/BhChildComponentWithFetchValues";
import { fetchRelatedUsersInProjectWithSearch } from "@/api/projectAPI";
import { convertSimpleValidUserAuthorityToUser } from "@/model/ISimpleValidUserAuthority";
import { IUser } from "@/model/IUser";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import { useTranslation } from "react-i18next";
import BhSearchInputWithUserDropdownAndCustomEmailOption from "@components/input/BhSearchInputWithUserDropdownAndCustomEmailOption";

interface Props {
  userSelectedCallback?: (arg0: IUser) => any;
  signatures: Array<ISignatureHolder>;
}

const InitialQueueSignatureAddUserInput: FC<Props> = ({ userSelectedCallback, signatures }) => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const { t } = useTranslation();

  const addSigner = (value: IUser) => {
    userSelectedCallback && userSelectedCallback(value);
  };

  const showValueCondition = (user: IUser) => {
    return !signatures.some((s) => s.username === user.username);
  };

  return (
    <div>
      <BhChildComponentWithFetchValues
        fetchValues={() => {
          return fetchRelatedUsersInProjectWithSearch(projectId).then((users) => {
            return users.map((simpleValidUser) => convertSimpleValidUserAuthorityToUser(simpleValidUser));
          });
        }}
        child={
          <BhSearchInputWithUserDropdownAndCustomEmailOption values={[]} onSelect={addSigner} showValueCondition={showValueCondition} placeholder={t("CONTAINER.SIGN_INVITE_PLACEHOLDER") as string} />
        }
      />
    </div>
  );
};

export default InitialQueueSignatureAddUserInput;
