import BhBreadcrumbs from "@components/breadcrumb/BhBreadcrumbs";
import React, { FC } from "react";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { useLocation } from "react-router-dom";

interface Props {
  customHeader?: React.ReactNode;
}

const BOHeader: FC<Props> = ({ customHeader }) => {
  const location = useLocation();
  const paths = location.pathname.split("/").slice(1);
  const breadcrumbValues = paths.map((path, i) => {
    return {
      text: path === "bo" ? "Back Office" : path.charAt(0).toUpperCase() + path.slice(1),
      url: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "/" + paths.slice(0, i + 1).join("/")
    };
  });
  const currentPageTitle = paths[paths.length - 1];
  const currentPageTitleFormatted = currentPageTitle.charAt(0).toUpperCase() + currentPageTitle.slice(1);

  return (
    <div className="pt-6">
      <BhBreadcrumbs values={breadcrumbValues} />
      {customHeader && customHeader}
      {!customHeader && <h1>{currentPageTitleFormatted}</h1>}
    </div>
  );
};

export default BOHeader;
