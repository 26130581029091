import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectPlusMinusRowsNotOkTotal, selectPlusMinusRowsOkTotal } from "@/app/store/checklist/checklistRowsSlice";

interface Props {
  labelLanguage: string | undefined;
}

const ChecklistRowPlusMinusFieldSumRow: FC<Props> = ({ labelLanguage }) => {
  const { t } = useTranslation();
  const okTotalSum = useAppSelector(selectPlusMinusRowsOkTotal);
  const notOkTotalSum = useAppSelector(selectPlusMinusRowsNotOkTotal);
  const tomPercentage = (okTotalSum / (okTotalSum + notOkTotalSum)) * 100 || 0;

  return (
    <div className="flex w-full flex-row items-center justify-between">
      <div className="flex flex-col gap-y-2">
        <div className="text-18px w-full font-bold leading-6">{t("CHECKLIST.PLUS_MINUS.SUM", { lng: labelLanguage })}</div>
        <div className="text-14px bh-text-pigeon w-full leading-5">{t("CHECKLIST.ROW.TOM", { lng: labelLanguage })}</div>
      </div>
      <div className="flex flex-row items-center gap-x-2">
        <div className="min-w-56 bh-bg-success-green-10 flex h-16 flex-col">
          <div className="text-12px bh-text-dark-jungle mt-2 mb-1 flex w-full flex-row justify-center font-bold leading-4">{t("CHECKLIST.PLUS_MINUS.SUM.OK", { lng: labelLanguage })}</div>
          <div className="text-18px bh-text-deep-ocean flex w-full flex-row justify-center font-bold leading-6">{okTotalSum}</div>
        </div>
        <div className="min-w-56 bh-bg-error-red-10 flex h-16 flex-col">
          <div className="text-12px bh-text-error-red mt-2 mb-1 flex w-full flex-row justify-center font-bold leading-4">{t("CHECKLIST.PLUS_MINUS.SUM.NOT_OK", { lng: labelLanguage })}</div>
          <div className="text-18px bh-text-deep-ocean flex w-full flex-row justify-center font-bold leading-6">{notOkTotalSum}</div>
        </div>
        <div className="bh-bg-smoke flex h-16 min-w-[100px] flex-col">
          <div className="text-12px bh-text-deep-ocean mt-2 mb-1 flex w-full flex-row justify-center font-bold leading-4">{t("CHECKLIST.TOM.LABEL", { lng: labelLanguage })}</div>
          <div className="text-18px bh-text-deep-ocean flex w-full flex-row justify-center font-bold leading-6">{tomPercentage.toFixed(1) + "%"}</div>
        </div>
      </div>
    </div>
  );
};

export default ChecklistRowPlusMinusFieldSumRow;
