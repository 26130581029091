import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/pro-regular-svg-icons/faFile";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  isCustomFormBase?: boolean;
}

const BhFormBaseIcon: FC<Props> = ({ isCustomFormBase }) => {
  return (
    <div
      className={classNames(
        isCustomFormBase ? "bh-file-card-icon-file " : "bh-file-card-icon-bauhub-entity",
        "bh-file-card-large-icon  flex flex-none items-center justify-center overflow-hidden rounded-sm"
      )}
    >
      <FontAwesomeIcon icon={faFile} className={classNames(!isCustomFormBase && "bh-text-dark-jungle")} />
    </div>
  );
};

export default BhFormBaseIcon;
