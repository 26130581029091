import React, { FC, useEffect } from "react";
import ShareboxCard from "@/views/home/project/detail/shareboxes/ShareboxCard";
import { fetchUserCreatedShareboxesAsync, selectShareboxListStatus, selectUserProjectShareboxesCount, selectUserProjectShareboxesFilteredAndSorted } from "@/app/store/shareboxesSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import ShareboxFilter from "@/views/home/project/detail/shareboxes/shareboxListFilter/ShareboxFilter";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import BhLargeText from "@components/text/BhLargeText";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import ShareboxListSkeleton from "@/views/home/project/detail/shareboxes/ShareboxListSkeleton";

interface Props {
  projectId: EntityId;
}

const UserShareboxesTab: FC<Props> = ({ projectId }) => {
  const { t } = useTranslation();
  const shareboxListStatus = useAppSelector(selectShareboxListStatus);
  const userShareboxes = useAppSelector((state) => selectUserProjectShareboxesFilteredAndSorted(state, projectId));
  const userShareboxesCount = useAppSelector((state) => selectUserProjectShareboxesCount(state, projectId));
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    const fetchShareboxesPromise = dispatch(fetchUserCreatedShareboxesAsync(projectId));

    return () => {
      fetchShareboxesPromise.abort();
    };
  }, [location]);

  const listIsEmpty = userShareboxesCount === 0 && userShareboxes.length === 0;
  const filteredListIsEmpty = userShareboxesCount > 0 && userShareboxes.length === 0;

  return (
    <BhScrollableBody header={<ShareboxFilter userShareboxes={true} />} keepScrollForKey="userShareboxesList">
      {shareboxListStatus === BhStateStatusType.INITIAL && <ShareboxListSkeleton />}
      {shareboxListStatus === BhStateStatusType.SUCCESS && listIsEmpty && (
        <BhNoMatchesImage>
          <span className="pr-1">{t("SHAREBOX.LIST.PERSONAL.IS_EMPTY.HEADER")}</span>
          <span>{t("SHAREBOX.LIST.PERSONAL.IS_EMPTY.BODY")}</span>
        </BhNoMatchesImage>
      )}
      {filteredListIsEmpty && <BhLargeText classes="text-center pt-10 items-center">{t("GLOBAL.NO_RESULTS")}</BhLargeText>}
      {!listIsEmpty && userShareboxes.map((sharebox) => <ShareboxCard shareboxId={sharebox.id} key={"sharebox" + sharebox.id} userShareboxes={true} />)}
    </BhScrollableBody>
  );
};

export default UserShareboxesTab;
