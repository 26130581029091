import React, { FC, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";

interface Props {
  icon?: IconDefinition;
  text: string;
}

const TaskInfoFieldLabel: FC<Props> = ({ icon, text }) => {

  return (
    <div className="min-w-24 inline-block flex flex-row items-center text-left">
      {icon && <FontAwesomeIcon icon={icon} className="bh-text-pigeon mr-2 h-4 w-4" />}
      <span className="bh-text-pigeon text-12 font-bold">{text}</span>
    </div>
  );
};

export default TaskInfoFieldLabel;
