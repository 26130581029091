import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import { useAppSelector } from "@/app/hooks";
import { selectFileConfirmationFilesForConfirmationWithDecisionId } from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import { selectFileConfirmationById } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import { fetchPresignedBatchUrl } from "@/api/fileAPI";
import { imitateBatchFileDownload } from "@/utilities/downloadUtilities";
import { Trans } from "react-i18next";

interface Props {
  confirmationId: EntityId;
}
const ConfirmationActionsContainerHeader: FC<Props> = ({ confirmationId }) => {
  const fileConfirmation = useAppSelector((state) => selectFileConfirmationById(state, confirmationId));
  const fileConfirmationFilesWithDecisionIds = useAppSelector((state) => selectFileConfirmationFilesForConfirmationWithDecisionId(state, confirmationId));

  return (
    <div className="flex flex-1 flex-col">
      <h2 className="mb-0 mt-2">{fileConfirmation?.description}</h2>
      <div className="flex flex-row items-center">
        <span className="pr-2">
          <Trans>FILE_CONFIRMATION.TITLE</Trans>
        </span>
        <BhBadge type={BhBadgeType.SUBTLE}>{fileConfirmationFilesWithDecisionIds.length}</BhBadge>
        <BhTextOnlyButton
          icon={faArrowDownToLine}
          buttonProps={{
            classes: "ml-4",
            onClick: () => {
              fetchPresignedBatchUrl(fileConfirmationFilesWithDecisionIds.map((f) => f.fileEntityId)).then((result) => imitateBatchFileDownload(result));
            }
          }}
        >
          <Trans>SHAREBOX.DOWNLOAD</Trans>
        </BhTextOnlyButton>
      </div>
    </div>
  );
};

export default ConfirmationActionsContainerHeader;
