import { IUser } from "@/model/IUser";
import { EntityId } from "@reduxjs/toolkit";
import { Authority, Resource } from "@/model/IUserAuthority";

export interface IChecklistParticipant {
  id: EntityId;
  userEntityId: EntityId;
  checklistId: EntityId;
  name: string;
  deleted: boolean;
  authority: Authority;
  username: string;
}

export function convertChecklistParticipantsToUsers(checklistParticipants: Array<IChecklistParticipant>): Array<IUser> {
  if (!checklistParticipants || checklistParticipants.length === 0) {
    return [];
  }
  return checklistParticipants.map((checklistParticipant) => {
    let firstName = checklistParticipant.name;
    let lastName = "";
    if (checklistParticipant.name.split(" ").length > 1) {
      firstName = checklistParticipant.name.split(" ")[0];
      const lastNames = checklistParticipant.name.split(" ");
      lastNames.shift();
      lastName = lastNames.join(" ");
    }
    return {
      id: checklistParticipant.userEntityId,
      username: checklistParticipant.username || checklistParticipant.name,
      firstName: firstName,
      lastName: lastName,
      userAuthorities: [{ authority: checklistParticipant.authority, resource: Resource.CHECKLIST, resourceId: checklistParticipant.checklistId }]
    } as IUser;
  });
}
