import React, { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TaskBoardItem from "@components/taskBoard/TaskBoardItem";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

interface Props {
  status: string;
  taskBoardIds: Array<EntityId>;
}

const TaskBoardItemGroup: FC<Props> = ({ status, taskBoardIds }) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(taskBoardIds.length > 0 ? true : false);

  const toggleOpen = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="mb-6 cursor-pointer">
      <div className="px-3" onClick={() => toggleOpen()}>
        <FontAwesomeIcon icon={dropdownOpen ? faCaretDown : faCaretRight} size="xs" />
        <span className="bh-text-deep-ocean-80 ml-3 font-bold">{t("WORKGROUP.STATUS." + status)}</span>
      </div>
      {taskBoardIds &&
        dropdownOpen &&
        taskBoardIds.map((id) => {
          return <TaskBoardItem taskBoardId={id} key={id} />;
        })}
    </div>
  );
};

export default TaskBoardItemGroup;
