import React, { FC } from "react";
import FileNameLink from "@/features/fileContainer/FileNameLink";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import BhIconButton from "@components/buttons/BhIconButton";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { fetchPublicUrlForFileInContainer } from "@/api/fileAPI";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import { IFileEntity } from "@/model/files/IFileEntity";

interface Props {
  fileEntity: IFileEntity;
  containerUuid: string;
  signInviteUuid: string;
}

const PublicContainerFileRowFC: FC<Props> = ({ fileEntity, containerUuid, signInviteUuid }) => {
  const downloadFile = () => {
    if (!fileEntity || !fileEntity?.uuid) {
      return;
    }

    fetchPublicUrlForFileInContainer(containerUuid, signInviteUuid, fileEntity.uuid, true, false).then((response) => {
      imitateUrlDownload(response.value);
    });
  };

  if (!fileEntity) {
    return null;
  }

  return (
    <>
      <div className="bh-bg-white hover:bh-bg-mint-30 bh-border-pigeon-40 bh-hover-container group group block flex h-14 items-center border-b">
        <div className="relative flex w-12 flex-none justify-center text-center">
          <BhFileCardIcon fileEntity={fileEntity} fileCardSize={FileCardSize.LARGE} withThumbnail={false} />
        </div>
        <div className="min-w-24 flex flex-grow flex-row items-center overflow-hidden pr-3">
          <div className="flex flex-col justify-center overflow-hidden">
            <div className="flex flex-row items-center">
              <FileNameLink fileEntity={fileEntity} onFileNameClickCallback={downloadFile} />
            </div>
          </div>
        </div>
        <div className="w-48 flex-none px-3">{fileEntity.createdByFullName}</div>
        <div className="w-32 flex-none px-3 text-right">
          <BhIconButton icon={faArrowDownToLine} buttonProps={{ onClick: downloadFile }} />
        </div>
      </div>
    </>
  );
};

const PublicContainerFileRow = React.memo(PublicContainerFileRowFC);
export default PublicContainerFileRow;
