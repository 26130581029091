import React, { FC, useState } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { IFileEntity } from "@/model/files/IFileEntity";
import FileNameLink from "@/features/fileContainer/FileNameLink";
import { classNames } from "@/utilities/jsUtilities";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import { Trans } from "react-i18next";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  fileEntity: IFileEntity;
  deleteCallback?: Function;
  withBorder?: boolean;
  withCreatorName?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  onCheckboxClickCallback?: Function;
  onFolderClickCallback?: Function;
  onFileNameClickCallback?: Function;
  isSearchView?: boolean;
  maxWidthClass?: string;
  openInNewTab?: boolean;
  withThumbnail?: boolean;
  previewOnHover?: boolean;
  attachmentFileIds?: Array<EntityId>;
}

const BhFileCardMedium: FC<Props> = React.memo(
  ({
    fileEntity,
    deleteCallback,
    withBorder,
    children,
    disabled,
    onCheckboxClickCallback,
    onFolderClickCallback,
    onFileNameClickCallback,
    withCreatorName,
    isSearchView,
    openInNewTab,
    maxWidthClass,
    withThumbnail,
    previewOnHover,
    attachmentFileIds
  }) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const classes = classNames(
      "bh-bg-white flex min-h-[36px] flex-row items-center bh-hover-container rounded px-1.5 py-1 hover:bh-bg-mint-50 duration-300 ease-in-out",
      withBorder && "bh-border-pigeon-40 hover:bh-border-pigeon-60 border"
    );

    return (
      <div className={classes}>
        {onCheckboxClickCallback && <BhCheckbox large={true} property="selected" isChecked={fileEntity.selected} onChange={() => onCheckboxClickCallback(fileEntity)} disabled={disabled} />}
        <div className="relative">
          <BhFileCardIcon
            fileEntity={fileEntity}
            fileCardSize={FileCardSize.MEDIUM}
            withThumbnail={withThumbnail ? withThumbnail : false}
            previewVisible={previewOnHover && previewVisible}
            onPreviewHover={() => setPreviewVisible(false)}
          />
        </div>
        <div className={classNames("text-14px ml-2 flex w-full flex-row items-center justify-between overflow-hidden", maxWidthClass)}>
          <div className="flex flex-1 flex-col overflow-hidden" onMouseEnter={() => setPreviewVisible(true)} onMouseLeave={() => setPreviewVisible(false)}>
            <strong>
              <FileNameLink
                fileEntity={fileEntity}
                disableOnClickHandler={disabled}
                onFolderClickCallback={onFolderClickCallback}
                onFileNameClickCallback={onFileNameClickCallback}
                openInNewTab={openInNewTab}
                attachmentFileIds={attachmentFileIds}
              />
            </strong>
            {withCreatorName && (
              <span className="bh-text-deep-ocean-80 text-12px leading-4">
                <span className="pr-1">
                  <Trans className="pr-1">MESSAGE.ADDED_BY</Trans>
                </span>
                {fileEntity.createdByFullName}
              </span>
            )}
          </div>
          <div className="flex items-center pl-1">
            {deleteCallback && <BhIconButton sizeClasses="h-6 w-6" icon={faTimes} buttonProps={{ onClick: () => deleteCallback(fileEntity) }} />}
            {children}
          </div>
        </div>
      </div>
    );
  }
);

export default BhFileCardMedium;
