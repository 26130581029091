import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { classNames } from "@/utilities/jsUtilities";
import FileNameLink from "@/features/fileContainer/FileNameLink";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { EntityId } from "@reduxjs/toolkit";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import BhElementWithBadge from "@components/badge/BhElementWithBadge";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import { faMessage } from "@fortawesome/pro-regular-svg-icons/faMessage";
import BhStatusCellProgress, { BhStatusCellProgressColor } from "@components/status/BhStatusCellProgress";
import {
  deleteFilesFromConfirmationAsync,
  openCommentsForFileConfirmationFileIdSet,
  selectFileConfirmationFileById,
  selectOpenCommentsForFileConfirmationFileId
} from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import { IFileEntity } from "@/model/files/IFileEntity";
import { selectFileConfirmationUsers } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import ConfirmationFileRevisionContainer from "@/views/home/project/detail/confirmations/confirmation/confirmationFileContainer/ConfirmationFileRevisionContainer";
import { fetchUrlForFile } from "@/api/fileAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { useTranslation } from "react-i18next";
import ConfirmationFileCardRevisionNumber from "@/views/home/project/detail/confirmations/confirmation/confirmationFileContainer/ConfirmationFileCardRevisionNumber";
import BhTooltip from "@components/BhTooltip";

interface Props {
  fileConfirmationFileId: EntityId;
  canEditConfirmation: boolean;
  attachmentFileIds: Array<EntityId>;
}

const ConfirmationFileContainerFC: FC<Props> = ({ fileConfirmationFileId, canEditConfirmation, attachmentFileIds }) => {
  const { t } = useTranslation();
  const fileConfirmationFile = useAppSelector((state) => selectFileConfirmationFileById(state, fileConfirmationFileId));
  const fileConfirmationUsers = useAppSelector((state) => fileConfirmationFile && selectFileConfirmationUsers(state, fileConfirmationFile.fileConfirmationId));
  const openCommentFileEntityId = useAppSelector(selectOpenCommentsForFileConfirmationFileId);
  const [revisionsShown, setRevisionShown] = useState(false);
  const [revisions, setRevisions] = useState<Array<IFileEntity>>([]);
  const dispatch = useAppDispatch();

  const [showDeleteFileConfirmationModal, hideDeleteFileConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteFileConfirmationModal}
      header={<h2>{t("CONFIRMATIONS.DELETE_FILE_MODAL.HEADER")}</h2>}
      body={<div>{t("CONFIRMATIONS.DELETE_FILE_MODAL.BODY")}</div>}
      confirmationButtonText={t("CONFIRM.CONFIRM")}
      handleDelete={() => {
        dispatch(deleteFilesFromConfirmationAsync({ fileConfirmationId: fileConfirmationFileWithId.fileConfirmationId, fileIds: [fileConfirmationFileWithId.fileEntityId] }));
        hideDeleteFileConfirmationModal();
      }}
    />
  ));

  if (!fileConfirmationFile) {
    return null;
  }

  const nextToConfirmUser = fileConfirmationUsers?.find((u) => u.id === fileConfirmationFile?.nextToConfirmUserId);
  const notConfirmed = fileConfirmationFile.confirmCount !== fileConfirmationFile.userCount;

  const fileConfirmationFileWithId = { ...fileConfirmationFile, id: fileConfirmationFile.fileEntityId };

  return (
    <>
      <div className={classNames("bh-border-pigeon-40 bh-hover-container group group block flex h-14 items-center border-b")}>
        <div className="relative flex w-11 flex-none justify-center text-center">
          <BhStatusCellProgress progress={fileConfirmationFileWithId.confirmCount} total={fileConfirmationFileWithId.userCount} color={BhStatusCellProgressColor.GREEN} />
        </div>
        <div className="flex flex-1 flex-row justify-between overflow-hidden pl-3 pr-2">
          <div className="flex flex-1 flex-row items-center space-x-2 overflow-hidden">
            <BhFileCardIcon fileEntity={fileConfirmationFileWithId as unknown as IFileEntity} fileCardSize={FileCardSize.LARGE} />
            <FileNameLink fileEntity={fileConfirmationFileWithId as unknown as IFileEntity} attachmentFileIds={attachmentFileIds} />
          </div>
          <div className={classNames(fileConfirmationFileWithId.commentCount < 1 && "hidden group-hover:block")}>
            <BhElementWithBadge badge={fileConfirmationFileWithId.commentCount > 0 && <BhBadge type={BhBadgeType.DARK}>{fileConfirmationFileWithId.commentCount}</BhBadge>}>
              <BhTooltip body={t("TASK.COMMENTS")}>
                <BhIconButton
                  icon={faMessage}
                  buttonProps={{
                    onClick: () => {
                      dispatch(openCommentsForFileConfirmationFileIdSet(fileConfirmationFileWithId?.fileEntityId));
                    }
                  }}
                  withBackground={openCommentFileEntityId === fileConfirmationFileWithId.fileEntityId}
                />
              </BhTooltip>
            </BhElementWithBadge>
          </div>
        </div>
        <div className="w-18 flex-none px-1 text-center">
          <ConfirmationFileCardRevisionNumber
            fileEntityId={fileConfirmationFileWithId.fileEntityId}
            parentFileEntityId={fileConfirmationFileWithId.parentFileEntityId}
            fileConfirmationId={fileConfirmationFileWithId.fileConfirmationId}
            canUploadNewVersion={canEditConfirmation && notConfirmed}
            revision={fileConfirmationFileWithId.revision}
            isRevision={false}
            revisionsShown={revisionsShown}
            setRevisionShown={() => setRevisionShown(!revisionsShown)}
            setRevisions={setRevisions}
          />
        </div>
        <div className="w-60 flex-none px-3">{nextToConfirmUser && <BhUserIconWithName user={nextToConfirmUser} />}</div>
        <div className="w-24 flex-none px-3 text-right">
          <div className="hidden flex-row justify-end group-hover:flex">
            <BhTooltip body={t("GLOBAL.DOWNLOAD")}>
              <BhIconButton
                icon={faArrowDownToLine}
                buttonProps={{
                  onClick: () => {
                    fetchUrlForFile(fileConfirmationFileWithId.fileEntityId, false, true, fileConfirmationFileWithId.uuid).then((presignedUrl) => {
                      imitateUrlDownload(presignedUrl.value);
                    });
                  }
                }}
              />
            </BhTooltip>
            {canEditConfirmation && notConfirmed && (
              <BhTooltip body={t("ASYNC.REMOVE")}>
                <BhIconButton
                  icon={faCircleMinus}
                  buttonProps={{
                    onClick: () => showDeleteFileConfirmationModal()
                  }}
                />
              </BhTooltip>
            )}
          </div>
        </div>
      </div>
      {revisionsShown && revisions.length > 0 && revisions.map((revisionFileEntity) => <ConfirmationFileRevisionContainer fileEntity={revisionFileEntity} key={revisionFileEntity.id} />)}
    </>
  );
};

const ConfirmationFileContainer = React.memo(ConfirmationFileContainerFC);
export default ConfirmationFileContainer;
