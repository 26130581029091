import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhTableRow from "@components/table/BhTableRow";
import { useTranslation } from "react-i18next";
import { ChecklistBaseCategory, IChecklistBase } from "@/model/checklist/IChecklistBase";
import { classNames } from "@/utilities/jsUtilities";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { formatDateTime } from "@/utilities/dateUtility";
import { getUserFullName } from "@/model/IUser";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { selectProjectWorkGroupsCount } from "@/app/store/project/projectWorkGroupsSlice";
import {
  addChecklistBasesToProjectsAsync,
  changeChecklistBaseCategoryAsync,
  copyChecklistBaseToProjectAsync,
  deleteChecklistBaseAsync,
  deleteChecklistBaseVersionAsync,
  removeChecklistBasesFromProjectsAsync,
  saveNewChecklistBaseVersionAsync,
  selectChecklistBaseActiveInWorkgroupsCount,
  selectIsChecklistBaseActivatedInProjectByBaseAndProjectId
} from "@/app/store/checklist/checklistsSlice";
import ChecklistWorkGroupAuthoritiesModal from "@components/checklists/settings/projectSettings/ChecklistWorkGroupAuthoritiesModal";
import { selectCurrentProjectCompanyId, selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import { ChecklistBaseVersionStatus, IChecklistBaseVersion } from "@/model/checklist/IChecklistBaseVersion";
import { Link, useNavigate } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { EntityId } from "@reduxjs/toolkit";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons/faPlusCircle";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import BhTableRowVersion from "@components/table/BhTableRowVersion";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons/faCirclePlus";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhIconButton from "@components/buttons/BhIconButton";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons/faEllipsisVertical";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { faUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import BhTooltip from "@components/BhTooltip";
import { IBasesToProjectsDTO } from "@/model/checklist/IBasesToProjectsDTO";
import ChecklistBaseCategoryTagDropdown from "@components/checklists/category/ChecklistBaseCategoryTagDropdown";
import BhNewTag from "@components/tags/BhNewTag";
import BhLink from "@components/buttons/BhLink";

interface Props {
  checklistBase: IChecklistBase;
  checklistBaseVersion: IChecklistBaseVersion;
  isLastVersion: boolean;
  toggleShowAllVersions: Function;
  allVersionsVisible: boolean;
  anyVersionInDraft: boolean;
  handleCompanyTemplateTagClick: Function;
}

const ProjectChecklistSettingsListRow: FC<Props> = ({
  checklistBase,
  checklistBaseVersion,
  isLastVersion,
  toggleShowAllVersions,
  allVersionsVisible,
  anyVersionInDraft,
  handleCompanyTemplateTagClick
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const projectId = useAppSelector(selectCurrentProjectId);
  const companyId = useAppSelector(selectCurrentProjectCompanyId);
  const isCompanyBase = !checklistBase.createdInProjectId;
  const isDraft = checklistBaseVersion.status === ChecklistBaseVersionStatus.DRAFT;
  const isChecklistBaseActivatedInProject = useAppSelector((state) => selectIsChecklistBaseActivatedInProjectByBaseAndProjectId(state, checklistBase.id, projectId));
  const baseActiveInWorkgroupsCount = useAppSelector((state) => selectChecklistBaseActiveInWorkgroupsCount(state, checklistBase.id));
  const projectWorkGroupsCount = useAppSelector(selectProjectWorkGroupsCount);
  const [showWorkGroupAuthoritiesModal, setShowWorkGroupAuthoritiesModal] = useState(false);
  const isBauhubChecklistTemplate = checklistBase.bauhubChecklistBase;

  const [showDeleteChecklistBaseConfirmationModal, hideDeleteChecklistBaseConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteChecklistBaseConfirmationModal}
      header={<h2>{t("CHECKLIST.DELETE.MODAL.HEADER")}</h2>}
      body={<div>{t("CHECKLIST.DELETE.MODAL.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={() => {
        deleteChecklistBase();
        hideDeleteChecklistBaseConfirmationModal();
      }}
    />
  ));

  const [showDeleteChecklistBaseVersionConfirmationModal, hideDeleteChecklistBaseVersionConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteChecklistBaseVersionConfirmationModal}
      header={<h2>{t("CHECKLIST.VERSION.DELETE.MODAL.HEADER")}</h2>}
      body={<div>{t("CHECKLIST.VERSION.DELETE.MODAL.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={() => {
        deleteChecklistBaseVersion();
        hideDeleteChecklistBaseVersionConfirmationModal();
      }}
    />
  ));

  const [showChecklistBaseCopyConfirmationModal, hideChecklistBaseCopyConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideChecklistBaseCopyConfirmationModal}
      header={<h2>{t("CHECKLIST.BASE.COPY.MODAL.HEADER")}</h2>}
      body={<div>{t("CHECKLIST.BASE.COPY_TO_PROJECT.MODAL.BODY")}</div>}
      confirmationButtonText={t("CHECKLIST.BASE.COPY.MODAL.CONFIRM")}
      handleDelete={() => {
        copyChecklistBaseToProject();
        hideChecklistBaseCopyConfirmationModal();
      }}
    />
  ));

  if (!checklistBase || !checklistBaseVersion) return null;

  const toggleBaseInProject = () => {
    if (isChecklistBaseActivatedInProject) {
      removeChecklistBaseFromProjects();
    } else {
      addChecklistBaseToProjects();
    }
  };

  const addChecklistBaseToProjects = () => {
    const dto = { checklistBaseIds: [checklistBase.id], projectIds: [projectId] } as IBasesToProjectsDTO;
    dispatch(addChecklistBasesToProjectsAsync({ companyId: companyId, basesToProjectsDTO: dto }));
  };

  const removeChecklistBaseFromProjects = () => {
    const dto = { checklistBaseIds: [checklistBase.id], projectIds: [projectId] } as IBasesToProjectsDTO;
    dispatch(removeChecklistBasesFromProjectsAsync({ companyId: companyId, basesToProjectsDTO: dto }));
  };

  const addNewVersion = () => {
    dispatch(saveNewChecklistBaseVersionAsync({ companyId: companyId, checklistBaseId: checklistBase.id })).then((action) => {
      const newChecklistBaseVersion = action.payload as IChecklistBaseVersion;
      editVersion(newChecklistBaseVersion.id);
    });
  };

  const editVersion = (checklistBaseVersionId: EntityId) => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklist/base/${checklistBase.id}/version/${checklistBaseVersionId}`);
  };

  const deleteChecklistBase = () => {
    dispatch(deleteChecklistBaseAsync({ companyId: companyId, checklistBaseId: checklistBase.id }));
  };

  const deleteChecklistBaseVersion = () => {
    dispatch(deleteChecklistBaseVersionAsync({ companyId: companyId, checklistBaseId: checklistBase.id, checklistBaseVersionId: checklistBaseVersion.id }));
  };

  const copyChecklistBaseToProject = () => {
    dispatch(copyChecklistBaseToProjectAsync({ companyId: companyId, projectId: projectId, checklistBaseId: checklistBase.id }));
  };

  const changeChecklistBaseCategory = (category: ChecklistBaseCategory) => {
    const checklistBaseToSave = { ...checklistBase, category: category };
    dispatch(changeChecklistBaseCategoryAsync({ companyId: companyId, checklistBase: checklistBaseToSave }));
  };

  const ellipsisDropdownValues: Array<any> = [
    !isCompanyBase &&
      !anyVersionInDraft &&
      isLastVersion && {
        text: t("CHECKLIST.BASE.NEW_VERSION"),
        function: addNewVersion,
        icon: faPlusCircle
      },
    isLastVersion &&
      !isDraft && {
        text: t("CHECKLIST.BASE.COPY_TO_PROJECT"),
        function: showChecklistBaseCopyConfirmationModal,
        icon: faCopy
      },
    isLastVersion &&
      isChecklistBaseActivatedInProject && {
        text: t("CHECKLIST.WORK_GROUP.AUTHORITIES"),
        function: () => setShowWorkGroupAuthoritiesModal(true),
        icon: faUsers
      },
    isLastVersion &&
      !isCompanyBase && {
        text: t("CHECKLIST.BASE.DELETE"),
        function: showDeleteChecklistBaseConfirmationModal,
        icon: faTrash
      },
    !isLastVersion &&
      !isCompanyBase &&
      isDraft && {
        text: t("CHECKLIST.BASE.VERSION.DELETE"),
        function: showDeleteChecklistBaseVersionConfirmationModal,
        icon: faTrash
      }
  ].filter(Boolean);

  return (
    <BhTableRow classes={classNames(!isLastVersion && !isDraft && "bh-bg-smoke-50", isDraft && "bh-bg-warning-yellow-10", "border-b bh-border-pigeon-40 group hover:bh-bg-mint-30")}>
      <td>
        <div className={classNames("group flex w-full flex-row items-center", !isLastVersion && "pl-7")}>
          <BhFileCardIcon
            fileEntity={
              isBauhubChecklistTemplate
                ? ({ id: checklistBaseVersion.id, name: checklistBase.type, type: FileEntityType.FORM } as IFileEntity)
                : ({
                    id: checklistBaseVersion.id,
                    name: checklistBase.type,
                    type: FileEntityType.CHECKLIST
                  } as IFileEntity)
            }
            fileCardSize={FileCardSize.LARGE}
          />
          <div className="ml-2 flex w-full flex-row items-center">
            {isCompanyBase && (
              <div className="text-14px bh-text-deep-ocean leading-5">
                {checklistBase.type}
                <BhNewTag tagText={t("CHECKLIST.COMPANY_TEMPLATE")} onClick={handleCompanyTemplateTagClick} />
              </div>
            )}
            {!isCompanyBase && (
              <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklist/base/${checklistBase.id}/version/${checklistBaseVersion.id}`}>
                <BhLink>{checklistBase.type}</BhLink>
              </Link>
            )}
            {isDraft && <BhTag type={BhTagType.MUSTAND} children={t("CHECKLIST.STATUS.DRAFT")} classes={"ml-3 h-4.5"} />}
          </div>
        </div>
      </td>
      <td>{isLastVersion && <ChecklistBaseCategoryTagDropdown category={checklistBase.category} saveCallback={changeChecklistBaseCategory} disabled={isCompanyBase} />}</td>
      <td className="bh-text-deep-ocean text-center font-bold">
        <BhTableRowVersion
          versionNumber={checklistBaseVersion.versionNumber}
          disabled={isCompanyBase || !isLastVersion || anyVersionInDraft}
          onClick={toggleShowAllVersions}
          otherVersionsExist={checklistBaseVersion.versionNumber > 1 && isLastVersion && !isCompanyBase}
          allVersionsVisible={allVersionsVisible}
          isActive={allVersionsVisible && isLastVersion}
          icon={faCirclePlus}
          addNewCallback={addNewVersion}
        />
      </td>
      <td>
        <div className="flex flex-col">
          <div className="text-14px bh-text-deep-ocean leading-5">{isBauhubChecklistTemplate ? t("FORM_BASE.BAUHUB_TEMPLATE") : formatDateTime(checklistBaseVersion.created)}</div>
          <div className="text-12px bh-text-deep-ocean-80">{!isBauhubChecklistTemplate && checklistBaseVersion.createdByUserEntity && getUserFullName(checklistBaseVersion.createdByUserEntity)}</div>
        </div>
      </td>
      <td>
        <BhTooltip body={isCompanyBase ? t("CHECKLIST.BASE.DEACTIVATE.DISABLED") : ""}>
          {isLastVersion && !isDraft && <BhToggleSwitch value={isChecklistBaseActivatedInProject} onClickAction={toggleBaseInProject} disabled={isCompanyBase} />}
        </BhTooltip>
      </td>
      <td>
        {isChecklistBaseActivatedInProject && isLastVersion && (
          <BhSecondaryButton buttonProps={{ onClick: () => setShowWorkGroupAuthoritiesModal(true) }}>{baseActiveInWorkgroupsCount + "/" + projectWorkGroupsCount}</BhSecondaryButton>
        )}
      </td>
      <td>
        {ellipsisDropdownValues.length > 0 && (
          <BhDropdown
            button={<BhIconButton icon={faEllipsisVertical} />}
            menu={<BhDropdownMenu values={ellipsisDropdownValues} type={BhDropdownTypeEnum.STRING} textProperty="text" />}
            position={BhDropdownPositionEnum.BOTTOM_LEFT}
          />
        )}
        {showWorkGroupAuthoritiesModal && <ChecklistWorkGroupAuthoritiesModal setIsShown={setShowWorkGroupAuthoritiesModal} checklistBase={checklistBase} />}
      </td>
    </BhTableRow>
  );
};

export default ProjectChecklistSettingsListRow;
