import React, { FC } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import NotFound from "@/views/global/NotFound";
import PublicShareboxDataContainer from "@/views/home/project/detail/shareboxes/detail/public/PublicShareboxDataContainer";

import et from "date-fns/locale/et";
import ru from "date-fns/locale/ru";
import { registerLocale } from "react-datepicker";
import PublicContainer from "@/views/home/project/detail/container/public/PublicContainer";
import { Tooltip } from "react-tooltip";
import GlobalToasts from "@/views/global/GlobalToasts";
import "react-tooltip/dist/react-tooltip.css";
import HomeRouter from "@/views/home/HomeRouter";
import PublicInviteContainer from "@/views/home/invite/PublicInviteContainer";
import NavbarLoadingIndicator from "@/views/home/navbar/NavbarLoadingIndicator";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import SyncRedirect from "@/views/home/SyncRedirect";
import PdftronContainerBlank from "@/views/home/project/detail/pdftron/PdftronContainerBlank";

registerLocale("et", et);
registerLocale("ru", ru);

const BauhubAppRouter: FC = () => {
  function InviteContainerWithParams() {
    const { inviteUuid } = useParams();
    if (inviteUuid) {
      return <PublicInviteContainer inviteUuid={inviteUuid} />;
    }
    return <NotFound />;
  }

  function PublicShareboxContainerWithParams() {
    const { shareboxUrlUuid, shareboxUuid } = useParams();
    if (shareboxUrlUuid) {
      return <PublicShareboxDataContainer shareboxUrlUuid={shareboxUrlUuid} />;
    }
    if (shareboxUuid) {
      return <PublicShareboxDataContainer shareboxUuid={shareboxUuid} />;
    }
    return <NotFound />;
  }

  function PublicContainerWithParams() {
    const { containerUuid, signInviteUuid, projectId } = useParams();
    if (containerUuid && signInviteUuid && projectId) {
      return <PublicContainer containerUuid={containerUuid} signInviteUuid={signInviteUuid} projectId={projectId} />;
    }
    return <NotFound />;
  }

  return (
    <>
      <NavbarLoadingIndicator />
      <Routes>
        <Route path={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/*`} element={<HomeRouter />} />
        <Route path={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/invite/:inviteUuid`} element={<InviteContainerWithParams />} />
        <Route path={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/public/sharebox/:shareboxUuid/*`} element={<PublicShareboxContainerWithParams />} />
        <Route path={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/public/sharebox/url/:shareboxUrlUuid/*`} element={<PublicShareboxContainerWithParams />} />
        <Route path={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/public/sign/:containerUuid/:signInviteUuid/:projectId`} element={<PublicContainerWithParams />} />
        <Route path={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/public/pdf`} element={<PdftronContainerBlank />} />

        <Route path="redirect" element={<SyncRedirect />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <GlobalToasts />
      <Tooltip id="bh-tooltip" className="bh-tooltip sm:hidden" />
    </>
  );
};

export default BauhubAppRouter;
