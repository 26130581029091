import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { useTranslation } from "react-i18next";
import BhSortButton from "@components/sort/BhSortButton";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import { initialTasksSort, ITasksSort } from "@/model/taskBoard/ITasksSort";
import { selectTasksSortInSearch, tasksSortBySetInSearch } from "@/app/store/searchSlice";

const TaskBoardSortInSearch: FC = () => {
  const { t } = useTranslation();
  const [tasksOrderingInSearch, setTasksOrderingInSearch] = useLocalStorage<ITasksSort>("tasksOrderingInSearch", initialTasksSort);
  const sort = useAppSelector(selectTasksSortInSearch);

  const dropdownMenuValues = [
    { value: "name", translation: t("GLOBAL.ORDER_BY.NAME") },
    { value: "created", translation: t("GLOBAL.ORDER_BY.CREATED") },
    { value: "updated", translation: t("GLOBAL.ORDER_BY.UPDATED") },
    { value: "deadline", translation: t("GLOBAL.ORDER_BY.DEADLINE") },
    { value: "priority", translation: t("GLOBAL.ORDER_BY.PRIORITY") },
    { value: "assignee", translation: t("GLOBAL.ORDER_BY.ASSIGNEEFULLNAME") }
  ];

  return (
    <div className="flex flex-row items-center">
      <BhSortButton sort={sort} setSortForStore={tasksSortBySetInSearch} localStorageSort={tasksOrderingInSearch} setSortForLocalStorage={setTasksOrderingInSearch} sortValues={dropdownMenuValues} />
    </div>
  );
};

export default TaskBoardSortInSearch;
