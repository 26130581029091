import React, { FC, ReactNode } from "react";
import { faOctagonExclamation } from "@fortawesome/pro-solid-svg-icons/faOctagonExclamation";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import BhSectionMessage from "@components/sectionMessage/BhSectionMessage";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons/faCircleInfo";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  buttonLabel?: ReactNode;
  onClick?: Function;
  children?: React.ReactNode;
  icon?: IconProp;
  small?: boolean;
  classes?: string;
  iconClasses?: string;
}

export const BhSectionMessageError: FC<Props> = ({ buttonLabel, onClick, children, small }) => {
  return (
    <BhSectionMessage
      classes="bh-bg-error-red-10 border bh-border-error-red-120"
      iconClasses="bh-text-error-red-120"
      icon={faOctagonExclamation}
      buttonLabel={buttonLabel}
      onClick={onClick}
      children={children}
      small={small}
    />
  );
};

export const BhSectionMessageWarning: FC<Props> = ({ buttonLabel, onClick, children, small }) => {
  return (
    <BhSectionMessage
      classes="bh-bg-warning-yellow-10 border bh-border-warning-yellow"
      iconClasses="bh-text-warning-yellow"
      icon={faExclamationTriangle}
      buttonLabel={buttonLabel}
      onClick={onClick}
      children={children}
      small={small}
    />
  );
};

export const BhSectionMessageSuccess: FC<Props> = ({ buttonLabel, onClick, children, small }) => {
  return (
    <BhSectionMessage
      classes="bh-bg-success-green-10 border border bh-border-success-green"
      iconClasses="bh-text-success-green"
      icon={faCheckCircle}
      buttonLabel={buttonLabel}
      onClick={onClick}
      children={children}
      small={small}
    />
  );
};

export const BhSectionMessageAction: FC<Props> = ({ buttonLabel, onClick, children, small }) => {
  return <BhSectionMessage classes="bh-bg-mint border border bh-border-dark-jungle" children={children} small={small} />;
};

export const BhSectionMessageInfo: FC<Props> = ({ buttonLabel, onClick, children, icon, small }) => {
  return (
    <BhSectionMessage
      classes="bh-bg-smoke border border bh-border-pigeon-50"
      iconClasses="bh-text-information-blue"
      icon={faCircleInfo}
      buttonLabel={buttonLabel}
      onClick={onClick}
      children={children}
      small={small}
    />
  );
};

export const BhSectionMessageSomethingElse: FC<Props> = ({ buttonLabel, onClick, children, icon, small, classes, iconClasses }) => {
  return (
    <BhSectionMessage
      classes={classNames(classes ? classes : "bh-bg-smoke bh-border-pigeon-50", "border border")}
      iconClasses={iconClasses ? iconClasses : "bh-text-deep-ocean"}
      icon={icon}
      buttonLabel={buttonLabel}
      onClick={onClick}
      children={children}
      small={small}
    />
  );
};
