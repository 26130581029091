import React, { FC } from "react";
import ActsAndContractsList from "@/views/home/project/detail/actsAndContracts/ActsAndContractsList";
import DetailViewContainer from "@/views/home/project/detail/detailViewContainer/DetailViewContainer";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import { Trans } from "react-i18next";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";

interface Props {}

const ActsAndContractsListContainer: FC<Props> = () => {
  useDocumentTitle("GLOBAL.ACTS_AND_CONTRACTS");

  return (
    <DetailViewContainer
      header={
        <DetailViewHeading>
          <Trans>GLOBAL.ACTS_AND_CONTRACTS</Trans>
        </DetailViewHeading>
      }
    >
      <ActsAndContractsList />
    </DetailViewContainer>
  );
};

export default ActsAndContractsListContainer;
