import React, { FC } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "@/utilities/jsUtilities";
import { ITag } from "@/model/ITag";

//FIGMA: https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H/Bauhub-design-system?node-id=296%3A4329

interface Props {
  icon: IconDefinition;
  tagText: string;
  onIconClick?: Function;
  disabled?: boolean;
  customTextProperty?: keyof ITag;
}

const BhTagWithAction: FC<Props> = ({ icon, tagText, onIconClick, disabled, customTextProperty }) => {
  const textProperty = customTextProperty ? customTextProperty : "name";

  const onClick = () => {
    onIconClick && onIconClick({ [textProperty]: tagText });
  };

  return (
    <div
      className={classNames(
        "py-0.25 l-h-14 bh-border-pigeon-40 bh-bg-pigeon-20 bh-text-deep-ocean-80 hover:bh-bg-pigeon-40 my-0.5 mr-1 inline-block flex w-fit cursor-pointer flex-row items-center rounded-xl border pl-1.5",
        disabled ? "pr-1.5" : "pr-0.5"
      )}
      onClick={(e) => e.preventDefault()}
    >
      <span className="text-13 font-semibold">{tagText}</span>
      {!disabled && (
        <div
          className="hover:bh-bg-deep-ocean hover:bh-text-white ml-0.5 inline-block flex h-4 w-4 flex-row items-center rounded-full text-center"
          onMouseDown={(e) => e.preventDefault()}
          onClick={onClick}
        >
          <div className="flex h-full w-full flex-row justify-center">
            <FontAwesomeIcon icon={icon} size={"sm"} className="h-full w-full" />
          </div>
        </div>
      )}
    </div>
  );
};

export default BhTagWithAction;
