import { IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import { EntityId } from "@reduxjs/toolkit";
import { IUser } from "@/model/IUser";

export interface IChecklistBaseVersion {
  id: EntityId;
  checklistBaseId: EntityId;
  status: ChecklistBaseVersionStatus;
  versionNumber: number;
  deleted: boolean;
  customLabel: any;
  checklistBaseVersionRows: Array<IChecklistBaseVersionRow>;
  createdBy: string;
  updatedBy: string;
  created: Date;
  updated: Date;
  createdByUserEntity?: IUser;
}

export enum ChecklistBaseVersionStatus {
  DRAFT = "DRAFT",
  FINAL = "FINAL"
}
