import React, { FC } from "react";
import BhTableRow from "@components/table/BhTableRow";
import { Authority, IPredefinedWorkGroupUser } from "@/model/IUserAuthority";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import BhUserIcon from "@components/user/BhUserIcon";
import { useTranslation } from "react-i18next";
import PartyTemplateUserListItemDropdown from "@/views/home/company/detail/companySettingsPartyTemplates/PartyTemplateUserListItemDropdown";
import { getUserFullName, IUser } from "@/model/IUser";

interface Props {
  user: IPredefinedWorkGroupUser;
  onUserPrivilegeChange: Function;
  dropdownMenuValues: Array<{ value: Authority; translation: string }>;
  onUserRemove: Function;
}

const PartyTemplateUserListItem: FC<Props> = ({ user, onUserPrivilegeChange, dropdownMenuValues, onUserRemove }) => {
  const { t } = useTranslation();

  const dropdownProperties: Array<keyof IPredefinedWorkGroupUser> = ["filesAuthLevel", "documentsAuthLevel", "modelAuthLevel", "actsAuthLevel", "contractAuthLevel"];

  const getTranslation = (authValue: string) => {
    const translation = dropdownMenuValues.find((val) => val.value === authValue);
    return translation ? translation.translation : t("ROLE.CUSTOM");
  };
  const handleUserPrivilegeChange = (changedAuthority: Authority, field: string) => {
    onUserPrivilegeChange && onUserPrivilegeChange(user.id, { [field]: changedAuthority });
  };

  const handleRemoveUser = () => {
    user && onUserRemove(user);
  };

  const userHasName = user.firstName && user.lastName;

  return (
    <BhTableRow>
      <td>
        <div className="flex min-w-0 flex-row items-center">
          <BhUserIcon user={user} />
          <div className="mx-2 ml-2 truncate">
            <div className="text-14px bh-text-deep-ocean md:whitespace-pre-wrap">
              {userHasName && <strong>{getUserFullName({ firstName: user.firstName, lastName: user.lastName } as IUser)}</strong>} {!userHasName && <strong>{user.username}</strong>}
            </div>
            <span className="text-12px bh-text-deep-ocean-80">{userHasName && user.username}</span>
          </div>
        </div>
      </td>
      {dropdownProperties.map((property) => (
        <PartyTemplateUserListItemDropdown
          key={property}
          user={user}
          onChange={(val: { value: Authority; translation: string }) => handleUserPrivilegeChange(val.value, property)}
          dropdownMenuValues={dropdownMenuValues}
          buttonValue={getTranslation(user[property] as string)}
        />
      ))}
      <td className="text-end">
        <BhIconButton icon={faCircleMinus} buttonProps={{ onClick: handleRemoveUser }} />
      </td>
    </BhTableRow>
  );
};

export default PartyTemplateUserListItem;
