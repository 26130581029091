import React, { FC } from "react";
import BhControlledInput from "@components/input/BhInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhInputLabel from "@components/input/BhInputLabel";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  initialPrefix: any;
  initialValue: any;
  property: string;
  dropdownElements: any[];
  onChangeCallback?: Function;
  onEnterCallback?: Function;
  onBlurCallback?: Function;
  label?: string;
  type?: string;
  labelHelper?: string;
  placeholder?: string;
  disabled?: boolean;
  inputDisabledOnly?: boolean;
  inputClasses?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const BhInputWithSuffixDropdown: FC<Props> = ({
  property,
  type,
  dropdownElements,
  initialPrefix,
  initialValue,
  onChangeCallback,
  onEnterCallback,
  onBlurCallback,
  label,
  placeholder,
  disabled,
  inputDisabledOnly,
  inputClasses,
  labelHelper,
  inputRef
}) => {
  return (
    <div className="flex flex-col">
      {label && <BhInputLabel>{label}</BhInputLabel>}
      {labelHelper && (
        <div className="text-14px l-h-18px bh-text-deep-ocean-80 px-0.5 py-1.5 font-semibold">
          <span className="bh-text-pigeon-70 font-normal italic"> - {labelHelper}</span>
        </div>
      )}
      <div className={classNames("flex w-full flex-row items-center rounded", !disabled && "hover:bh-border-pigeon-70 bh-border-pigeon-40 border", disabled && "bh-bg-smoke")}>
        <div className="w-full pr-2">
          <BhControlledInput
            initialValue={initialValue}
            property={property}
            inputClasses={classNames("h-9 border-0 w-full", inputDisabledOnly && "pointer-events-none")}
            type={type}
            placeholder={placeholder}
            inputRef={inputRef}
            onEnterCallback={onEnterCallback}
            onBlurAction={onBlurCallback}
            disabled={disabled}
          />
        </div>
        <div>
          <BhDropdown
            buttonClasses={classNames(!disabled && "cursor-pointer", disabled && "cursor-not-allowed")}
            button={
              <div
                className={classNames("hover:bh-bg-pigeon-20 h-8.5 flex w-fit items-center justify-center whitespace-nowrap rounded-r px-2", disabled && "bh-bg-pigeon-20", !disabled && "bh-bg-smoke")}
              >
                <span>{initialPrefix}</span>
                {!disabled && <FontAwesomeIcon className="pl-2 pr-1" icon={faCaretDown} />}
              </div>
            }
            menu={<BhDropdownMenu type={BhDropdownTypeEnum.STRING} values={dropdownElements} widthClass="w-fit whitespace-nowrap" textProperty={"text"} />}
            position={BhDropdownPositionEnum.BOTTOM_LEFT}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default BhInputWithSuffixDropdown;
