import React, { FC } from "react";
import { IChecklistBaseVersionRow, PlusMinusCounterType } from "@/model/checklist/IChecklistBaseVersionRow";
import BhInputSimple from "@components/input/BhInputSimple";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectChecklistBaseLanguageForTranslationById } from "@/app/store/checklist/checklistsSlice";
import { EntityId } from "@reduxjs/toolkit";
import BhCheckboxWithText from "@components/checkboxes/BhCheckboxWithText";
import PlusMinusFieldCounter from "@components/checklists/checklistBase/body/rowTypes/plusMinus/PlusMinusFieldCounter";
import PlusMinusFieldPrefills from "@components/checklists/checklistBase/body/rowTypes/plusMinus/PlusMinusFieldPrefills";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons/faCircleInfo";

interface Props {
  checklistBaseId: EntityId;
  baseVersionRow: IChecklistBaseVersionRow;
  saveCallback: Function;
  disabled?: boolean;
}

const ChecklistBaseRowPlusMinusField: FC<Props> = ({ baseVersionRow, saveCallback, disabled, checklistBaseId }) => {
  const { t } = useTranslation();
  const checklistBaseLanguage = useAppSelector((state) => selectChecklistBaseLanguageForTranslationById(state, checklistBaseId));
  const fieldValues = baseVersionRow.plusMinusFieldValue;

  const saveFieldValues = (changedObject: any) => {
    const fieldValuesToSave = { plusMinusFieldValue: { ...fieldValues, ...changedObject } };
    saveCallback(fieldValuesToSave);
  };

  return (
    <div className="flex h-full flex-row">
      <div className="bh-border-pigeon-60 flex w-full flex-col gap-y-2 border-r p-4">
        <div className="-ml-4 flex flex-row items-center">
          <BhCheckboxWithText
            text={t("CHECKLIST.ROW.AUTO_TASK")}
            isChecked={fieldValues.autoCreateTask}
            property={"autoCreateTask"}
            isSmallCheckbox={true}
            onChange={saveFieldValues}
            disabled={disabled}
          />
          <BhCheckboxWithText text={t("CHECKLIST.ROW.TOOLTIP")} isChecked={fieldValues.tooltipAdded} property={"tooltipAdded"} isSmallCheckbox={true} onChange={saveFieldValues} disabled={disabled} />
        </div>
        <div className="w-full">
          <BhInputSimple
            initialValue={baseVersionRow.fieldTitle}
            property={"fieldTitle"}
            placeholder={t("CHECKLIST.ROW.PLUS_MINUS.PLACEHOLDER") as string}
            onBlurAction={saveCallback}
            disabled={disabled}
          />
        </div>
        {fieldValues.tooltipAdded && (
          <div className="flex flex-row items-center gap-x-2">
            <div className="bh-text-deep-ocean">
              <FontAwesomeIcon icon={faCircleInfo} size={"lg"} />
            </div>
            <BhInputSimple initialValue={fieldValues.tooltipText} property={"tooltipText"} onBlurAction={saveFieldValues} disabled={disabled} placeholder={t("CHECKLIST.ROW.TOOLTIP") as string} />
          </div>
        )}
        <PlusMinusFieldPrefills saveCallback={saveFieldValues} prefills={fieldValues.prefills} disabled={disabled} />
      </div>
      <div className="flex h-full flex-row gap-x-2 py-4 px-2.5">
        <PlusMinusFieldCounter total={0} fieldType={PlusMinusCounterType.PLUS} disabled={true} labelLanguage={checklistBaseLanguage} />
        <PlusMinusFieldCounter total={0} fieldType={PlusMinusCounterType.MINUS} disabled={true} labelLanguage={checklistBaseLanguage} />
      </div>
    </div>
  );
};

export default ChecklistBaseRowPlusMinusField;
