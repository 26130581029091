import React, { FC } from "react";

interface Props {
  children: React.ReactNode;
}

const BhCompactTable: FC<Props> = ({ children }) => {
  return <table className="w-full border-collapse border">{children}</table>;
};

export default BhCompactTable;

export interface ICompactTableColumn<T> {
  id: number;
  accessor?: keyof T;
  label: string;
  show?: boolean;
  classes?: string;
  rowSpan?: number;
  colSpan?: number;
}
