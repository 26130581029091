import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { fetchNewProjectFileTreeTemplates } from "@/api/fileTreeAPI";
import { FileTreeType, IFileTree, IFileTreeTemplates } from "@/model/files/IFileTreeTemplate";
import NewProjectTreeList from "@/views/home/company/createProject/newProjectFolderStructure/NewProjectProjectTreeList";
import BhModal from "@components/modal/BhModal";
import ProjectTreeRootContainer from "@/views/home/company/createProject/newProjectFolderStructure/projectTreePreviewModal/ProjectTreeRootContainer";
import { INewProjectForm } from "@/model/INewProjectForm";
import { Trans, useTranslation } from "react-i18next";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { BauhubPlan } from "@/model/IProject";
import directoryFolders from "@img/eelseadistatud-kaustapuud-compressed.png";
import NewProjectProFeaturesUpsellView from "@/views/home/company/createProject/NewProjectProFeaturesUpsellView";
import { CompanyStatus } from "@/model/ICompany";

interface Props {
  companyId: EntityId;
  newProjectForm: INewProjectForm;
  setNewProjectForm: Dispatch<SetStateAction<INewProjectForm>>;
}

const NewProjectProjectTreeContainer: FC<Props> = ({ companyId, newProjectForm, setNewProjectForm }) => {
  const { t } = useTranslation();
  const [projectTreeTemplates, setProjectTreeTemplates] = useState<IFileTreeTemplates>({} as IFileTreeTemplates);
  const [loading, setLoading] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [treePreview, setTreePreview] = useState<IFileTree>({} as IFileTree);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));

  useEffect(() => {
    fetchNewProjectFileTreeTemplates(companyId).then((response) => {
      setProjectTreeTemplates(response.value);
      setLoading(false);
    });
  }, []);

  const onChangeCallback = useCallback(
    (changedValue: INewProjectForm) => {
      const property = Object.keys(changedValue)[0] as keyof INewProjectForm;
      const isSelected = newProjectForm[property] === changedValue[property];
      const changedObject = { ...newProjectForm, [property]: isSelected ? undefined : changedValue[property] };
      setNewProjectForm({ ...newProjectForm, ...changedObject });
    },
    [newProjectForm]
  );

  if (!company) return null;

  //TODO: Trial ettevõtetel peaks tulema BE-st "plan: "PRO"" väärtus, nii et see booleani teine osa ära visata, kui BE pool valmis, sest trial võib olla ka light paketiga
  const canUseProFeatures = company.plan === BauhubPlan.PRO || company.status === CompanyStatus.TRIAL;

  return (
    <div className="w-2/3 lg:w-full lg:px-4">
      <h2 className="pb-6">
        <Trans>NEW_PROJECT.PROJECT_TREES</Trans>
      </h2>
      {company.plan === BauhubPlan.LIGHT && (
        <NewProjectProFeaturesUpsellView imageSource={directoryFolders} headerText={t("COMPANY.SETTINGS.PROJECT_TREE.LIGHT.HEADER")} bodyText={t("COMPANY.SETTINGS.PROJECT_TREE.LIGHT.BODY")} />
      )}
      {canUseProFeatures && (
        <>
          <p>
            <Trans>NEW_PROJECT.PROJECT_TREES.INFO</Trans>
          </p>
          {!loading && (
            <div className="pb-10">
              <NewProjectTreeList
                projectTreeTemplates={projectTreeTemplates.DRAWINGS}
                templateType={FileTreeType.DRAWINGS}
                setModalOpen={setModalOpen}
                setTreePreview={setTreePreview}
                property="drawingTemplateTree"
                onChangeCallback={onChangeCallback}
                selectedTreeId={newProjectForm.drawingTemplateTree}
              />
              <NewProjectTreeList
                projectTreeTemplates={projectTreeTemplates.DOCUMENTS}
                templateType={FileTreeType.DOCUMENTS}
                setModalOpen={setModalOpen}
                setTreePreview={setTreePreview}
                property="documentTemplateTree"
                onChangeCallback={onChangeCallback}
                selectedTreeId={newProjectForm.documentTemplateTree}
              />
            </div>
          )}
          {/*ProjectTree preview modal*/}
          <BhModal
            isShown={modalOpen}
            setIsShown={setModalOpen}
            header={
              <h2>
                <Trans>COMPANY.SETTINGS.PROJECT.TREE.PREVIEW</Trans>
              </h2>
            }
            children={<ProjectTreeRootContainer projectTree={treePreview} />}
            size="3xl"
          />
        </>
      )}
    </div>
  );
};

export default React.memo(NewProjectProjectTreeContainer);
