import React, { FC } from "react";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IContact } from "@/model/IContact";
import BhTableHeader from "@components/table/BhTableHeader";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectFilteredAndSortedUsersInSearch, selectUsersSortInSearch, usersSortBySetInSearch, usersSortToggledInSearch } from "@/app/store/searchSlice";
import { useTranslation } from "react-i18next";
import ContactsTableRowContainer from "@/features/contactsTable/ContactsTableRowContainer";
import ContactsFilter from "@/views/home/project/detail/users/contacts/ContactsFilter";

const SearchResultsUserTab: FC = () => {
  const { t } = useTranslation();
  const sort = useAppSelector(selectUsersSortInSearch);
  const users = useAppSelector(selectFilteredAndSortedUsersInSearch);
  const dispatch = useAppDispatch();

  const tableColumnHeaders: Array<IBhTableHeader<IContact>> = [
    { id: 0, content: `${t("COMPANY.NAME")}`, field: "firstName", sortable: true },
    { id: 1, content: `${t("SEARCH.USERS")}`, field: "email", sortable: true },
    { id: 2, content: `${t("CONTACT.POSITION")}/${t("CONTACTS.FILTER.COMPANY")}`, field: "company", sortable: true }
  ];

  const onTableHeaderClickCallback = (column: IBhTableHeader<IContact>) => {
    if (column.field) {
      if (sort.property === column.field) {
        dispatch(usersSortToggledInSearch());
      } else {
        dispatch(usersSortBySetInSearch(column.field));
      }
    }
  };

  return (
    <BhScrollableBody>
      <div className="flex flex-col">
        <ContactsFilter useSearchSliceActions={true} />
        <table className="min-w-full overflow-auto ">
          {/*TODO sort!*/}
          <BhTableHeader columns={tableColumnHeaders} onClickCallback={onTableHeaderClickCallback} sortedBy={sort.property} reversed={sort.reversed} />
          <tbody className="divide-y overflow-hidden ">
            {users.map((user) => (
              <ContactsTableRowContainer contact={user} key={user.id} isSearchView={true} />
            ))}
          </tbody>
        </table>
      </div>
    </BhScrollableBody>
  );
};

export default SearchResultsUserTab;
