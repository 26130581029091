import React, { FC, ReactNode } from "react";
import BhDatePicker from "@components/input/BhDatePicker";
import BhFormInput from "@components/input/BhFormInput";

interface Props {
  initialValue?: any;
  saveCallback?: Function;
  field?: string;
  label?: ReactNode;
  colSpan?: number;
  textAlignClass?: string;
  inputOnly?: boolean;
  labelOnly?: boolean;
  datePickerOnly?: boolean;
  disabled: boolean;
}

const MntMpTruupTableCell: FC<Props> = ({ initialValue, saveCallback, field, label, inputOnly, labelOnly, colSpan, textAlignClass, datePickerOnly, disabled }) => {
  const saveValue = (changedObj: any) => {
    if (field) {
      saveCallback && saveCallback({ column: field, value: changedObj[field] });
    }
  };
  return (
    <td colSpan={colSpan || 1}>
      <div className="flex flex-row items-center">
        {!inputOnly && !datePickerOnly && <div className="mr-2 whitespace-nowrap">{label}</div>}
        {!labelOnly && !datePickerOnly && field && (
          <div className="w-full">
            <BhFormInput initialValue={initialValue} property={field} inputClasses={textAlignClass} saveCallback={saveValue} disabled={disabled} />
          </div>
        )}
        {datePickerOnly && field && (
          <BhDatePicker
            property={field}
            onChangeCallback={saveValue}
            initialValue={initialValue ? new Date(initialValue) : new Date()}
            classes={"p-1 h-6 border-0 bh-bg-smoke-50 form-table-input"}
            disabled={disabled}
          />
        )}
      </div>
    </td>
  );
};

export default MntMpTruupTableCell;
