import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  children: React.ReactNode;
  classes?: string;
}

const BhCompactTableHead: FC<Props> = ({ children, classes }) => {
  return <thead className={classNames(classes)}>{children}</thead>;
};

export default React.memo(BhCompactTableHead);
