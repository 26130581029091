import React, { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/pro-regular-svg-icons/faGear";
import { useTranslation } from "react-i18next";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { classNames } from "@/utilities/jsUtilities";
import BhTooltip from "@components/BhTooltip";
import BhChip from "@components/chips/BhChip";
import { WorkGroupType } from "@/model/IWorkGroup";
import BhLightSeparator from "@components/separator/BhLightSeparator";
import BhDeclineButton from "@components/buttons/BhDeclineButton";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { removePredefinedPartyAsync, savePredefinedPartyNameAsync, savePredefinedPartyTypeAsync, selectCompanyPredefinedPartyById } from "@/app/store/companiesSlice";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { IPredefinedWorkGroup } from "@/model/IPredefinedWorkGroup";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { useNavigate } from "react-router-dom";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  partyId: EntityId;
  companyId: EntityId;
}

const PartyTemplateGeneralSettingsTab: FC<Props> = ({ partyId, companyId }) => {
  const { t } = useTranslation();
  const predefinedParty = useAppSelector((state) => selectCompanyPredefinedPartyById(state, [companyId, partyId]));
  const [deleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  if (!predefinedParty || !companyId) return null;

  const handleNameChange = (changedValue: IPredefinedWorkGroup) => {
    changedValue.name.length > 0
      ? dispatch(savePredefinedPartyNameAsync({ companyId: companyId, partyId: partyId, dto: { value: changedValue.name } }))
      : dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.ERROR,
            disappear: true,
            translateCode: "COMPANY.SETTINGS.PREDEFINED_WORKGROUP.NO_NAME"
          })
        );
  };

  const handleTypeChange = (type: WorkGroupType) => {
    const newType = type === predefinedParty.type ? undefined : type;
    dispatch(savePredefinedPartyTypeAsync({ companyId: companyId, partyId: partyId, dto: { value: newType } }));
  };

  const deletePredefinedParty = () => {
    dispatch(removePredefinedPartyAsync(predefinedParty));
    setIsDeleteConfirmationModalOpen(false);
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${companyId}/work-group-templates`);
  };

  return (
    <BhScrollableBody>
      <div className="flex h-full w-full flex-col overflow-auto py-12 px-24">
        <div className="flex flex-row justify-start">
          <div className="w-full">
            <h2 className="pb-6">
              <FontAwesomeIcon icon={faGear} className="mr-3" />
              {t("WORKGROUP.TAB.GENERAL")}
            </h2>
            <div className="w-3/5">
              <BhInputStackedLabel
                label={t("WORKGROUP.NEW.NAME")}
                initialValue={predefinedParty.name}
                property="name"
                onChangeCallback={handleNameChange}
                onBlurAction={handleNameChange}
                debounceLength={900}
              />
            </div>
            <p className="bh-text-deep-ocean-80 mt-6 mb-3">
              <span className="mr-2 font-bold">{t("WORKGROUP.NEW.TYPE")}</span>
              <i>({t("GLOBAL.NOT_MANDATORY")})</i>
            </p>

            <div className={classNames("flex flex-row items-center space-x-2")}>
              <BhTooltip body={t("WORKGROUP.NEW.TYPE")}>
                <BhChip isSelected={predefinedParty.type === WorkGroupType.SUBCONTRACTOR} onClick={() => handleTypeChange(WorkGroupType.SUBCONTRACTOR)}>
                  {t("PARTY.SUBCONTRACTOR")}
                </BhChip>
              </BhTooltip>
              <BhTooltip body={t("WORKGROUP.NEW.TYPE")}>
                <BhChip isSelected={predefinedParty.type === WorkGroupType.CLIENT} onClick={() => handleTypeChange(WorkGroupType.CLIENT)}>
                  {t("PARTY.CLIENT")}
                </BhChip>
              </BhTooltip>
            </div>
          </div>
        </div>
        <div>
          <div className="my-8">
            <BhLightSeparator />
          </div>
          <div>
            <h2>{t("WORKGROUP.DELETE")}</h2>
            <BhDeclineButton icon={faTrash} buttonProps={{ onClick: () => setIsDeleteConfirmationModalOpen(true) }}>
              {t("WORKGROUP.DELETE")}
            </BhDeclineButton>
          </div>
        </div>
        {deleteConfirmationModalOpen && (
          <BhDeleteConfirmationModal
            isOpen={true}
            setIsOpen={setIsDeleteConfirmationModalOpen}
            header={<h2>{t("COMPANY.SETTINGS.WORKGROUP.DELETE.HEADER")}</h2>}
            body={t("COMPANY.SETTINGS.WORKGROUP.DELETE.BODY")}
            confirmationButtonText={t("GLOBAL.DELETE")}
            handleDelete={deletePredefinedParty}
          />
        )}
      </div>
    </BhScrollableBody>
  );
};

export default PartyTemplateGeneralSettingsTab;
