import { ChecklistBaseCategory } from "@/model/checklist/IChecklistBase";
import { IUser } from "@/model/IUser";
import { IChecklistTag } from "@/model/checklist/IChecklistTag";

export interface IChecklistFilter {
  page: number;
  limit: number;
  total?: number;
  type?: string;
  name?: string;
  orderBy?: string;
  orderAsc?: boolean;
  archived?: boolean;
  tagName?: string;
  category: ChecklistBaseCategory;
  createdBy?: string;
  since?: string;
  until?: string;
  toggleFieldValue?: string;
  filterDropdownValues?: IChecklistFilterDropdownValues;
}

export interface IChecklistFilterDropdownValues {
  creators?: Array<IUser>;
  types?: Array<string>;
  tags?: Array<IChecklistTag>;
}

export const checklistFilterInitialState = {
  page: 1,
  limit: 40,
  total: 0,
  toggleFieldValue: "created",
  filterDropdownValues: {
    creators: [] as Array<IUser>,
    types: [] as Array<string>,
    tags: [] as Array<IChecklistTag>
  }
} as IChecklistFilter;

export interface IChecklistStatisticsFilter {
  orderBy: string;
  orderAsc: boolean;
}

export const checklistStatisticsFilterInitialState = {
  orderBy: "name",
  orderAsc: false
};
