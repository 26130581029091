import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boSelectCompanyProjectsFilteredAndSorted, boSelectSort, boSetSort } from "@/app/store/backofficeSlice";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import BhTableHeader from "@components/table/BhTableHeader";
import BOCompanyProjectTableRow from "@components/backoffice/company/projects/BOCompanyProjectTableRow";
import { IBoProjectSort } from "@/model/backoffice/IBoSort";
import BOCompanyProjectAdministratorsModal from "@components/backoffice/company/projects/BOCompanyProjectAdministratorsModal";
import { IProject } from "@/model/IProject";

const BOCompanyProjectsTable: FC = () => {
  const companyProjects = useAppSelector(boSelectCompanyProjectsFilteredAndSorted);
  const [administratorsModalOpen, setAdministratorsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<IProject>();
  const sort = useAppSelector(boSelectSort);
  const dispatch = useAppDispatch();

  const tableColumnHeaders: Array<IBhTableHeader<IBoProjectSort>> = [
    { id: 0, content: "Project name", sortable: true, field: "name" },
    { id: 1, content: "Status", sortable: true, field: "status", classes: "w-24" },
    { id: 2, content: "Created", sortable: true, field: "created", classes: "w-28" },
    { id: 3, content: "Users", sortable: true, field: "relatedUsers", classes: "w-24" },
    { id: 4, content: "Admins", classes: " w-10" },
    { id: 5, content: "Security level", sortable: true, field: "securityLevel", classes: "w-34" },
    { id: 6, content: "Suspended", sortable: true, field: "suspended", classes: "w-32" },
    { id: 7, content: "Closed", sortable: true, field: "closed", classes: "w-28" },
    { id: 8, content: "Invoice type", sortable: true, field: "type", classes: "w-32" },
    { id: 9, content: "Date", sortable: true, field: "billingDay", classes: "w-24" },
    { id: 10, content: "New invoice date", sortable: true, field: "billingDate", classes: "w-40", colSpan: 2 }
  ];

  const onTableHeaderClickCallback = (column: IBhTableHeader<IBoProjectSort>) => {
    if (column.field) {
      const projectsSort = { ...sort.companyProjectsSort };
      const updatedProjectsSort = { ...projectsSort, property: column.field, reversed: column.field === projectsSort.property ? !projectsSort.reversed : projectsSort.reversed };
      const newSort = { ...sort, companyProjectsSort: updatedProjectsSort };
      dispatch(boSetSort(newSort));
    }
  };

  const prepareAdministratorsModal = (project: IProject) => {
    setSelectedProject(project);
    setAdministratorsModalOpen(true);
  };

  return (
    <>
      <div className="max-h-full overflow-auto">
        <table className="w-full">
          <BhTableHeader columns={tableColumnHeaders} onClickCallback={onTableHeaderClickCallback} reversed={sort.companyProjectsSort.reversed} sortedBy={sort.companyProjectsSort.property} />
          <tbody>
            {companyProjects?.map((project) => (
              <BOCompanyProjectTableRow project={project} key={project.id} setAdministratorsModalOpen={prepareAdministratorsModal} />
            ))}
          </tbody>
        </table>
      </div>
      {administratorsModalOpen && <BOCompanyProjectAdministratorsModal project={selectedProject as IProject} setIsShown={setAdministratorsModalOpen} />}
    </>
  );
};

export default BOCompanyProjectsTable;
