export const abortUploadFunctionsStore = {
  _data: new Map(),
  get(key: any) {
    if (!key) {
      return null;
    }

    return this._data.get(key) || null;
  },
  set(key: any, data: any) {
    if (!key) {
      return;
    }
    return this._data.set(key, data);
  }
};

export const parallelUploadCountStore = {
  _count: 0,
  getCount() {
    return this._count;
  },
  increment() {
    this._count += 1;
  },
  decrement() {
    this._count -= 1;
  }
};
