import React, { FC } from "react";
import BhInputSimple from "@components/input/BhInputSimple";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  rowObject: any;
  property: string;
  placeholder: string;
  saveRowCallback: Function;
  bottomBorder?: boolean;
  disabled?: boolean;
  checkboxDisabled?: boolean;
}

const ChecklistBaseInfoFieldRow: FC<Props> = ({ rowObject, property, placeholder, bottomBorder, disabled, saveRowCallback, checkboxDisabled }) => {
  const saveRow = (changedObject: any) => {
    saveRowCallback({ [property]: { ...rowObject, ...changedObject } });
  };

  return (
    <div className={classNames("flex h-full w-full flex-row items-center", bottomBorder && "bh-border-pigeon-60 border-b")}>
      <div className="flex w-full flex-row items-center p-4">
        <div className="w-2/6">
          <BhInputSimple initialValue={rowObject.value} property={"value"} onBlurAction={saveRow} disabled={disabled || !rowObject.visible} />
        </div>
        <div className="ml-2 w-full">
          <BhInputSimple initialValue={placeholder} property={"placeholder"} disabled={true} />
        </div>
      </div>
      <div className="bh-border-pigeon-60 h-17 ml-2 flex w-36 flex-row items-center justify-center border-l">
        <BhCheckbox property={"visible"} onChange={saveRow} isChecked={rowObject.visible} large={true} disabled={disabled || checkboxDisabled} />
      </div>
    </div>
  );
};

export default ChecklistBaseInfoFieldRow;
