import React, { FC, ReactElement } from "react";
import { ChecklistRowType, IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import ChecklistRowWysiwyg from "@components/checklists/checklist/body/rowTypes/ChecklistRowWysiwyg";
import ChecklistRowAttachments from "@components/checklists/checklist/body/rowTypes/ChecklistRowAttachments";
import ChecklistRowCheckpointList from "@components/checklists/checklist/body/rowTypes/ChecklistRowCheckpointList";
import ChecklistRowCheckpoint from "@components/checklists/checklist/body/rowTypes/ChecklistRowCheckpoint";
import { calculateCheckpointsAndSaveRoundAsync } from "@/app/store/checklist/checklistsSlice";
import { saveChecklistRoundRowAsync } from "@/app/store/checklist/checklistRowsSlice";
import { useAppDispatch } from "@/app/hooks";
import { IChecklistRoundRow } from "@/model/checklist/IChecklistRoundRow";
import { EntityId } from "@reduxjs/toolkit";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { BauhubBannerType } from "@/model/IProject";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import ChecklistFieldWithLabel from "@components/checklists/checklist/body/rowTypes/ChecklistFieldWithLabel";
import ChecklistRowPlusMinusField from "@components/checklists/checklist/body/rowTypes/ChecklistRowPlusMinusField";

interface Props {
  checklistId: EntityId;
  checklistRoundId: EntityId;
  baseVersionRow: IChecklistBaseVersionRow;
  disabled: boolean;
}

const ChecklistRow: FC<Props> = ({ checklistId, checklistRoundId, baseVersionRow, disabled }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const saveChecklistRow = (checklistRow: IChecklistRoundRow) => {
    if (disabled) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          children: t("CHECKLIST.ERROR.ROUND_COMPLETED")
        })
      );
      return;
    }
    return dispatch(saveChecklistRoundRowAsync(checklistRow)).then(() => {
      const isCheckpoint = checklistRow.baseRow && checklistRow.baseRow.checkpoint && !checklistRow.extraRow;
      const isTomField = checklistRow.baseRow && checklistRow.baseRow.checklistRowType === ChecklistRowType.PLUSMINUS;
      if (isCheckpoint || isTomField) {
        dispatch(calculateCheckpointsAndSaveRoundAsync(checklistRow.checklistRoundId));
      }
    });
  };

  const checklistRowElementMap: Record<ChecklistRowType, ReactElement> = {
    ATTACHMENTS: <ChecklistRowAttachments baseVersionRow={baseVersionRow} checklistRoundId={checklistRoundId} checklistId={checklistId} disabled={disabled} />,
    CHECKPOINT: <ChecklistRowCheckpoint checklistId={checklistId} checklistRoundId={checklistRoundId} baseVersionRow={baseVersionRow} callback={saveChecklistRow} disabled={disabled} />,
    CHECKPOINT_LIST: <ChecklistRowCheckpointList checklistId={checklistId} checklistRoundId={checklistRoundId} baseVersionRow={baseVersionRow} callback={saveChecklistRow} disabled={disabled} />,
    OPTION_FIELD: <ChecklistFieldWithLabel baseVersionRow={baseVersionRow} callback={saveChecklistRow} disabled={disabled} />,
    TEXTFIELD: <ChecklistFieldWithLabel baseVersionRow={baseVersionRow} callback={saveChecklistRow} disabled={disabled} />,
    WYSIWYG: <ChecklistRowWysiwyg baseVersionRow={baseVersionRow} />,
    PLUSMINUS: <ChecklistRowPlusMinusField checklistId={checklistId} checklistRoundId={checklistRoundId} baseVersionRow={baseVersionRow} callback={saveChecklistRow} disabled={disabled} />
  };

  return checklistRowElementMap[baseVersionRow.checklistRowType as ChecklistRowType];
};

export default ChecklistRow;
