import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId, selectMaruProjectModule } from "@/app/store/project/projectSlice";
import BhIFrameComponent from "@/views/global/BhIFrameComponent";
import BhDetailContainer from "@components/detailContainer/BhDetailContainer";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faFileArrowDown } from "@fortawesome/pro-regular-svg-icons/faFileArrowDown";
import BhLightSeparator from "@components/separator/BhLightSeparator";
import BhDetailContainerBody from "@components/detailContainer/BhDetailContainerBody";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  fileEntityId: EntityId;
  partyId: EntityId;
}

const ContractFormContainer: FC<Props> = ({ fileEntityId, partyId }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const isMaruProject = useAppSelector(selectMaruProjectModule);
  const isBauhubProject = !isMaruProject;
  const navigate = useNavigate();

  return (
    <BhDetailContainer>
      <div>
        <div className="flex w-full flex-col">
          <div className="flex w-full flex-row py-4 px-4">
            <div className="flex w-full flex-row justify-between">
              <div className="flex flex-1 items-center">
                <BhIconButton icon={faArrowLeft} buttonProps={{ onClick: () => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/contract/${partyId}`) }} />
              </div>
              <div>
                <BhSecondaryButton icon={faFileArrowDown} buttonProps={{ onClick: () => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/view/${fileEntityId}`) }}>
                  {t("GLOBAL.AS_PDF")}
                </BhSecondaryButton>
              </div>
            </div>
          </div>
        </div>
        <BhLightSeparator />
      </div>
      <BhDetailContainerBody>
        <>
          {isBauhubProject && <BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/parties/${partyId}/contract/react/form/${fileEntityId}/react`} />}
          {isMaruProject && <BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/parties/${partyId}/mr/contract/react/form/${fileEntityId}/react`} />}
        </>
      </BhDetailContainerBody>
    </BhDetailContainer>
  );
};

export default ContractFormContainer;
