import React, { FC, useEffect } from "react";
import ShareboxCard from "@/views/home/project/detail/shareboxes/ShareboxCard";
import { fetchProjectShareboxesAsync, selectProjectShareboxesCount, selectProjectShareboxesFilteredAndSorted, selectShareboxListStatus } from "@/app/store/shareboxesSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import ShareboxFilter from "@/views/home/project/detail/shareboxes/shareboxListFilter/ShareboxFilter";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import BhLargeText from "@components/text/BhLargeText";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import ShareboxListSkeleton from "@/views/home/project/detail/shareboxes/ShareboxListSkeleton";

interface Props {
  projectId: EntityId;
}

const ProjectShareboxesTab: FC<Props> = ({ projectId }) => {
  const { t } = useTranslation();
  const shareboxListStatus = useAppSelector(selectShareboxListStatus);
  const projectShareboxes = useAppSelector((state) => selectProjectShareboxesFilteredAndSorted(state, projectId));
  const projectShareboxesCount = useAppSelector((state) => selectProjectShareboxesCount(state, projectId));
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    const fetchShareboxesPromise = dispatch(fetchProjectShareboxesAsync(projectId));

    return () => {
      fetchShareboxesPromise.abort();
    };
  }, [location]);

  const listIsEmpty = projectShareboxesCount === 0 && projectShareboxes.length === 0;
  const filteredListIsEmpty = projectShareboxesCount > 0 && projectShareboxes.length === 0;

  return (
    <BhScrollableBody header={<ShareboxFilter userShareboxes={false} />}>
      {shareboxListStatus === BhStateStatusType.INITIAL && <ShareboxListSkeleton />}
      {shareboxListStatus === BhStateStatusType.SUCCESS && listIsEmpty && (
        <BhNoMatchesImage>
          <span>{t("SHAREBOX.LIST.PROJECT.IS_EMPTY.HEADER")}</span>
          <span>{t("SHAREBOX.LIST.PERSONAL.IS_EMPTY.BODY")}</span>
        </BhNoMatchesImage>
      )}
      {filteredListIsEmpty && <BhLargeText classes="text-center pt-10 items-center">{t("GLOBAL.NO_RESULTS") as string}</BhLargeText>}
      {!listIsEmpty && projectShareboxes.map((sharebox) => <ShareboxCard shareboxId={sharebox.id} key={"sharebox" + sharebox.id} userShareboxes={false} />)}
    </BhScrollableBody>
  );
};

export default ProjectShareboxesTab;
