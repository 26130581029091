import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { AsyncJobStatus, AsyncJobType, IAsyncJob } from "@/model/IAsyncJob";
import { cancelAsyncJob, fetchAsyncJob, fetchUserRunningAsyncJobs, removeAsyncJob } from "@/api/asyncJobAPI";
import { fetchFilesAsync, fileAdded, filesRemoved } from "@/app/store/filesSlice";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { fetchChecklistContainerFileEntityAsync } from "@/app/store/checklist/checklistsSlice";
import dayjs from "dayjs";
import { naturalSortByField } from "@/utilities/sortUtilities";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { IFileEntity } from "@/model/files/IFileEntity";

export const asyncJobsAdapter = createEntityAdapter<IAsyncJob>({});

export interface IAsyncJobsState {
  status: string;
}

const initialState = asyncJobsAdapter.getInitialState<IAsyncJobsState>({
  status: BhStateStatusType.INITIAL
});

export const fetchUserRunningAsyncJobsAsync = createAsyncThunk("asyncJobs/fetchUserRunningAsyncJobs", async () => {
  return fetchUserRunningAsyncJobs();
});

export const cancelAsyncJobAsync = createAsyncThunk("asyncJobs/cancelAsyncJob", async (job: IAsyncJob) => {
  return cancelAsyncJob(job);
});

export const removeAsyncJobAsync = createAsyncThunk("asyncJobs/removeAsyncJob", async (job: IAsyncJob) => {
  return removeAsyncJob(job);
});

export const fetchAsyncJobAsync = createAsyncThunk("asyncJobs/fetchAsyncJobAsync", async (jobId: EntityId, { dispatch, getState }) => {
  const resultAsyncJob = await fetchAsyncJob(jobId);
  const state: RootState = getState() as RootState;
  const stateAsyncJob = state.asyncJobs.entities[resultAsyncJob.id];
  if (stateAsyncJob) {
    const isFinished = [AsyncJobStatus.NEW, AsyncJobStatus.EXECUTING].includes(stateAsyncJob.status) && resultAsyncJob.status === AsyncJobStatus.SUCCESS;

    if ([AsyncJobStatus.NEW, AsyncJobStatus.EXECUTING].includes(stateAsyncJob.status) && resultAsyncJob.status === AsyncJobStatus.FAILED) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          translateCode: "ASYNC.FAILED." + resultAsyncJob.jobType
        })
      );
    }

    if (isFinished) {
      const isContainer = resultAsyncJob.jobType === AsyncJobType.BDOC_GENERATION || resultAsyncJob.jobType === AsyncJobType.ASICE_GENERATION;
      const isChecklistContainer = isContainer && resultAsyncJob.data?.file?.resource === "CHECKLIST_ROUND";
      const isDirectoryContainer = isContainer && !isChecklistContainer && resultAsyncJob.data?.file?.id;
      if (isDirectoryContainer) {
        const containerFileEntity = resultAsyncJob.data.file;
        dispatch(fileAdded(containerFileEntity));
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.SUCCESS,
            disappear: true,
            navigateTo: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${containerFileEntity.projectId}/container/${containerFileEntity.id}`,
            translateCode: "CONTAINER.CREATED"
          })
        );
      }

      // If container files should be deleted
      if (stateAsyncJob.requestDTO && stateAsyncJob.requestDTO.deleteFiles && stateAsyncJob.requestDTO.files) {
        const fileIdsToRemove = stateAsyncJob.requestDTO.files.map((file: IFileEntity) => file.id);
        dispatch(filesRemoved(fileIdsToRemove));
      }

      if (isChecklistContainer) {
        const containerId = resultAsyncJob.data.file.id;
        dispatch(fetchChecklistContainerFileEntityAsync(containerId));
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.SUCCESS,
            disappear: true,
            translateCode: "CONTAINER.CREATED"
          })
        );
      }

      const isZipImport = resultAsyncJob.jobType === AsyncJobType.ZIP_IMPORT;
      if (isFinished && isZipImport && resultAsyncJob.data?.dirId) {
        dispatch(fetchFilesAsync(resultAsyncJob.data?.dirId));
      }
    }
  }
  return resultAsyncJob;
});

export const asyncJobsSlice = createSlice({
  name: "asyncJobs",
  initialState,
  reducers: {
    asyncJobAdded: asyncJobsAdapter.addOne
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRunningAsyncJobsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchUserRunningAsyncJobsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        asyncJobsAdapter.upsertMany(state, action.payload);
      })
      .addCase(cancelAsyncJobAsync.fulfilled, (state, action) => {
        asyncJobsAdapter.upsertOne(state, action.payload);
      })
      .addCase(removeAsyncJobAsync.fulfilled, (state, action) => {
        asyncJobsAdapter.removeOne(state, action.payload.id);
      })
      .addCase(fetchAsyncJobAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchAsyncJobAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        asyncJobsAdapter.upsertOne(state, action.payload);
      });
  }
});
export const { selectIds: selectAsyncJobsIds, selectAll: selectAllAsyncJobs, selectById: selectAsyncJobById } = asyncJobsAdapter.getSelectors((state: RootState) => state.asyncJobs);

export const selectFailedAsyncJobIds = createSelector([selectAllAsyncJobs], (asyncJobs) =>
  asyncJobs
    .filter((asyncJob) => asyncJob.status === AsyncJobStatus.FAILED || asyncJob.status === AsyncJobStatus.CANCELED)
    .filter((asyncJob) => dayjs(asyncJob.jobEnd) > dayjs().subtract(5, "minutes"))
    .sort((a, b) => naturalSortByField(a, b, "id", true))
    .map((aj) => aj.id)
);

export const selectExecutingAsyncJobIds = createSelector([selectAllAsyncJobs], (asyncJobs) => asyncJobs.filter((asyncJob) => asyncJob.status === AsyncJobStatus.EXECUTING).map((aj) => aj.id));

export const selectAnyAsyncJobsExecuting = createSelector([selectExecutingAsyncJobIds], (executingAsyncJobIds) => executingAsyncJobIds.length > 0);

export const { asyncJobAdded } = asyncJobsSlice.actions;

export default asyncJobsSlice.reducer;
