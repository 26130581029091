import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { classNames } from "@/utilities/jsUtilities";
import { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
  icon: IconProp;
  url: string;
  iconColor?: string;
  linkName: string;
}

const BhMenuListItem: FC<Props> = ({ icon, iconColor, linkName, url }) => {
  return (
    <Link to={url}>
      <div className="border-b bh-border-pigeon-40 flex items-center justify-between w-full px-6 py-3 group hover:bh-bg-mint-30 cursor-pointer">
        <div className="flex items-center gap-8">
          <div className={classNames("w-10 h-10 flex items-center justify-center rounded-full", iconColor ? iconColor : "bh-bg-pigeon-20")}>
            <FontAwesomeIcon icon={icon} />
          </div>
          {linkName}
        </div>
        <div>
          <FontAwesomeIcon icon={faArrowRight} className="bh-text-pigeon-50 group-hover:bh-text-pigeon-group" />
        </div>
      </div>
    </Link>
  );
};

export default BhMenuListItem;