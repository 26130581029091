import React, { FC, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { deleteFileEntityTagAsync, directoryInfoBarTabSet, saveFileEntityTagAsync, selectDistinctTagsForCurrentDirFiles, selectFileById } from "@/app/store/filesSlice";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { bytesToSize, getUserFromFullName } from "@/utilities/jsUtilities";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import { EntityId } from "@reduxjs/toolkit";
import { formatDateTime } from "@/utilities/dateUtility";
import { IFileEntityInfo } from "@/model/files/IFileEntityInfo";
import { Trans } from "react-i18next";
import { isDirectory } from "@/utilities/fileEntity/fileEntityUtilities";
import BhInputWithTags from "@components/input/BhInputWithTags";
import { fetchFileEntityInfo } from "@/api/fileInfoAPI";
import { IFileEntityTag } from "@/model/files/IFileEntityTag";
import { store } from "@/app/store";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import BhIconButton from "@components/buttons/BhIconButton";
import { selectCurrentUserUsername } from "@/app/store/userSlice";
import { IAuthorityBoolean } from "@/model/IUser";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faTag } from "@fortawesome/pro-regular-svg-icons/faTag";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { DirectoryFileInfoBarTab } from "@/views/home/project/detail/directory/fileInfoBar/DirectoryFileInfoBar";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";

interface Props {
  fileEntityId: EntityId;
  privilegesForDirectory: IAuthorityBoolean;
}

const FileInfoDetailView: FC<Props> = ({ fileEntityId, privilegesForDirectory }) => {
  const focusedFileEntity = useAppSelector((state) => selectFileById(state, fileEntityId));
  const [fileInfo, setFileInfo] = useState<{ loading: boolean; info?: IFileEntityInfo }>({ loading: true });
  const [isTagInput, setIsTagInput] = useState(false);
  const tagInputRef = useRef<any>(null);
  const tagsInDir = useAppSelector(selectDistinctTagsForCurrentDirFiles);
  const currentUserUsername = useAppSelector(selectCurrentUserUsername);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setFileInfo({ ...fileInfo, loading: true });
    if (focusedFileEntity?.id) {
      fetchFileEntityInfo(focusedFileEntity.id).then((fileInfoResult) => {
        setFileInfo({ loading: false, info: fileInfoResult });
      });
    }
  }, [fileEntityId]);

  if (!focusedFileEntity) {
    return null;
  }

  const saveTag = (tag: IFileEntityTag) => {
    if (tag.text === undefined || !tag.text.trim().length) return;
    if (focusedFileEntity.tags.some((t) => t.text.toLowerCase() === tag.text)) {
      store.dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.WARNING,
          disappear: true,
          translateCode: "FILE.DUPLICATE_TAG"
        })
      );
      return;
    }
    const tagToSave = { text: tag.text, fileEntityId: fileEntityId } as IFileEntityTag;
    dispatch(saveFileEntityTagAsync(tagToSave));
  };

  const removeTag = (tag: IFileEntityTag) => {
    dispatch(deleteFileEntityTagAsync(tag));
  };

  const size = fileInfo.info?.totalSize || focusedFileEntity.size;
  const canEditTags = privilegesForDirectory.isAdmin || focusedFileEntity.createdBy === currentUserUsername;
  const hasTags = focusedFileEntity.tags?.length > 0;

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center px-2 pt-2">
        <div className="bh-text-deep-ocean-80 flex-1">
          <Trans>MODAL.INFORMATION.TITLE</Trans>
        </div>
        <div className="">
          <BhIconButton icon={faTimes} buttonProps={{ onClick: () => dispatch(directoryInfoBarTabSet(DirectoryFileInfoBarTab.COLLAPSED)) }} />
        </div>
      </div>
      <div className="flex flex-col p-2">
        <div>
          <BhFileCardIcon fileEntity={focusedFileEntity} fileCardSize={FileCardSize.LARGE} />
        </div>
        <p className="p-1 pb-2">
          <strong>{focusedFileEntity.name}</strong>
        </p>
        {!hasTags && canEditTags && !isTagInput && (
          <div className="pb-2">
            <BhTextOnlyButton icon={faTag} buttonProps={{ onClick: () => setIsTagInput(true) }}>
              <Trans>TAG.CHOOSE</Trans>
            </BhTextOnlyButton>
          </div>
        )}
        {(hasTags || isTagInput) && (
          <>
            <div className="bh-text-pigeon flex w-20 p-1 text-sm">
              <Trans>FILE.TAGS</Trans>
            </div>
            <div className="px-1 pb-3">
              <div className="inline-block w-full">
                {!canEditTags && (
                  <div className="flex flex-row flex-wrap items-center gap-y-1">
                    {hasTags &&
                      focusedFileEntity.tags.map((tag) => (
                        <BhTag key={tag.id} type={BhTagType.TAG}>
                          {tag.text}
                        </BhTag>
                      ))}
                  </div>
                )}
                {canEditTags && (
                  <div className="py-1">
                    <BhInputWithTags
                      inputRef={tagInputRef}
                      onRemove={removeTag}
                      onSelect={saveTag}
                      currentValues={focusedFileEntity.tags}
                      dropdownValues={tagsInDir}
                      customTextProperty={"text"}
                      autoFocus={!hasTags}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div className="break-all text-sm">
          <div className="flex flex-row ">
            <div className="bh-text-pigeon flex w-20 flex-shrink-0 p-1">
              <Trans>FILE.SIZE</Trans>
            </div>
            <div className="flex flex-1 p-1 font-bold">
              {fileInfo.loading && <div className="bh-bg-pigeon-50 h-4 w-14 animate-pulse rounded" />}
              {!fileInfo.loading && (
                <>
                  {isDirectory(focusedFileEntity) && size > 0 && "~"}
                  {size && bytesToSize(size)}
                </>
              )}
            </div>
          </div>
          {!fileInfo.loading && fileInfo.info && isDirectory(focusedFileEntity) && (
            <div className="flex flex-row">
              <div className="bh-text-pigeon flex w-20 flex-shrink-0 p-1">
                <Trans>FILE_INFORMATION.FILES</Trans>
              </div>
              <div className="flex flex-1 flex-col space-y-2 p-1">
                <strong>{fileInfo.info?.numberOfFiles}</strong>
              </div>
            </div>
          )}
          {!isDirectory(focusedFileEntity) && (
            <div className="flex flex-row">
              <div className="bh-text-pigeon flex w-20 flex-shrink-0 p-1">
                <Trans>GLOBAL.VERSION</Trans>
              </div>
              <div className="flex flex-1 p-1 font-bold">{focusedFileEntity.revision}</div>
            </div>
          )}
          {!fileInfo.loading && fileInfo.info && (
            <div className="flex flex-row">
              <div className="bh-text-pigeon flex w-20 flex-shrink-0 p-1">
                <Trans>GLOBAL.ORDER_BY.CREATED</Trans>
              </div>
              <div className="flex flex-1 flex-col space-y-2 p-1">
                <strong>{formatDateTime(fileInfo.info?.fileCreated)}</strong>
                <div>
                  <BhUserIconWithName user={getUserFromFullName(fileInfo.info?.fileCreatedByFullName)} size="sm" />
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-row">
            <div className="bh-text-pigeon flex w-20 flex-shrink-0 p-1">
              <Trans>GLOBAL.UPDATED</Trans>
            </div>
            <div className="flex flex-1 flex-col space-y-2 p-1">
              <strong>{formatDateTime(focusedFileEntity.updated)}</strong>
              <div>
                <BhUserIconWithName user={getUserFromFullName(focusedFileEntity.updatedByFullName)} size="sm" />
              </div>
            </div>
          </div>
          {!fileInfo.loading && fileInfo.info?.title && (
            <div className="flex flex-row">
              <div className="bh-text-pigeon flex w-20 flex-shrink-0 p-1">
                <Trans>FILE.TITLE</Trans>
              </div>
              <div className="flex flex-1 p-1 font-bold">{fileInfo.info.title}</div>
            </div>
          )}
          {!fileInfo.loading && fileInfo.info?.customRevision && (
            <div className="flex flex-row">
              <div className="bh-text-pigeon flex w-20 flex-shrink-0 p-1">
                <Trans>FILE.CUSTOM_REVISION</Trans>
              </div>
              <div className="flex flex-1 p-1 font-bold">{fileInfo.info.customRevision}</div>
            </div>
          )}
          {!fileInfo.loading && fileInfo.info?.projectPart && (
            <div className="flex flex-row">
              <div className="bh-text-pigeon flex w-20 flex-shrink-0 p-1">
                <Trans>FILE.PROJECT_PART</Trans>
              </div>
              <div className="flex flex-1 p-1 font-bold">{fileInfo.info.projectPart}</div>
            </div>
          )}
          {!fileInfo.loading && fileInfo.info?.creationDate && (
            <div className="flex flex-row">
              <div className="bh-text-pigeon flex w-20 flex-shrink-0 p-1">
                <Trans>FILE.CREATION_DATE</Trans>
              </div>
              <div className="flex flex-1 p-1 font-bold">{formatDateTime(fileInfo.info.creationDate)}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileInfoDetailView;
