import React, { FC, useState } from "react";
import { IFileCopyOrMoveDTO, IFileMoveOrCopyRequestDTO, IRevisionReplaceRequestDTO, NewVersionOption } from "@/model/files/IFileEntity";
import NewVersionModal from "@/views/home/project/detail/directory/directoryModals/copyAndMoveModals/NewVersionModal";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import DuplicateFileNameWarningModal from "@/views/home/project/detail/directory/directoryModals/copyAndMoveModals/DuplicateFileNameWarningModal";

interface Props {
  dto: IFileCopyOrMoveDTO;
  onClose: Function;
  projectId: EntityId;
  isMoveModal: boolean;
  copyRevisions: boolean;
}

const DuplicateFileNameWarningModals: FC<Props> = ({ dto, onClose, projectId, isMoveModal, copyRevisions }) => {
  const { t } = useTranslation();
  const [showDuplicateFileNameModal, setShowDuplicateFileNameModal] = useState(true);
  const [showNewVersionModal, setShowNewVersionModal] = useState(false);
  const fileCopyOrMoveRequestDTO = {
    fileIds: [dto.fileToCopyOrMove.id],
    destinationId: dto.destinationDirectory.id,
    fromId: dto.fileToCopyOrMove.parentFileEntityId,
    projectId: projectId,
    copyRevisions: copyRevisions,
    mergeFolders: false,
    keepSameName: false
  } as IFileMoveOrCopyRequestDTO;

  const close = () => {
    const dtoToRemove = { fileIds: fileCopyOrMoveRequestDTO.fileIds } as IFileMoveOrCopyRequestDTO;
    onClose([dtoToRemove]);
  };

  const duplicateFileModalOptionChosen = (manageFiles: boolean) => {
    if (manageFiles) {
      setShowDuplicateFileNameModal(false);
      setShowNewVersionModal(true);
    } else {
      onClose([fileCopyOrMoveRequestDTO]);
    }
  };

  const newVersionModalOptionChosen = (option: string) => {
    let dtoWithRevisionReplaceDTO = {
      ...fileCopyOrMoveRequestDTO,
      fileRevisionRequests: [{
        replacementFileId: dto.fileToCopyOrMove.id, 
        fileToReplaceId: dto.matchingFile.id,
        stackDraggedFile: option === NewVersionOption.ONLY_DRAGGED_FILE_AS_NEW_REVISION,
        stackFiles: option === NewVersionOption.STACK_DRAGGED_FILE_REVISIONS,
        combineFiles: option === NewVersionOption.COMBINE_FILE_REVISIONS_ACCORDING_TO_TIME
      } as IRevisionReplaceRequestDTO]
    } as IFileMoveOrCopyRequestDTO;
    if (isMoveModal && dtoWithRevisionReplaceDTO.fileRevisionRequests && dtoWithRevisionReplaceDTO.fileRevisionRequests.length > 0) {
      const replaceDto = {
        replacementFileId: dto.fileToCopyOrMove.id, 
        fileToReplaceId: dto.matchingFile.id,
        stackDraggedFile: option === NewVersionOption.ONLY_DRAGGED_FILE_AS_NEW_REVISION,
        stackFiles: option === NewVersionOption.STACK_DRAGGED_FILE_REVISIONS,
        combineFiles: option === NewVersionOption.COMBINE_FILE_REVISIONS_ACCORDING_TO_TIME
      } as IRevisionReplaceRequestDTO;
      dtoWithRevisionReplaceDTO.fileRevisionRequests = [replaceDto];
    }
    onClose([dtoWithRevisionReplaceDTO]);
  };

  return (
    <>
      {showDuplicateFileNameModal && (
        <DuplicateFileNameWarningModal
          onClose={close}
          onSubmit={duplicateFileModalOptionChosen}
          destinationDirectoryName={dto.destinationDirectory.name}
          duplicateFileName={dto.fileToCopyOrMove.name}
        />
      )}
      {showNewVersionModal && <NewVersionModal onClose={close} onSubmit={newVersionModalOptionChosen} dto={dto} isMoveModal={isMoveModal} />}
    </>
  );
};

export default DuplicateFileNameWarningModals;
