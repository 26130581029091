import React, { FC, useEffect } from "react";
import { useAppDispatch } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { fetchChecklistBaseVersionRowsForEditingAsync, removeAllChecklistBaseRows } from "@/app/store/checklist/checklistBaseRowsSlice";
import { fetchChecklistBaseVersionsAsync, fetchCompanyChecklistBaseAsync } from "@/app/store/checklist/checklistsSlice";
import ChecklistBaseHeader from "@components/checklists/checklistBase/header/ChecklistBaseHeader";
import ChecklistBaseBody from "@components/checklists/checklistBase/body/ChecklistBaseBody";

interface Props {
  companyId: EntityId;
  checklistBaseId: EntityId;
  checklistBaseVersionId: EntityId;
  projectId?: EntityId;
}

const ChecklistBaseContainer: FC<Props> = ({ companyId, checklistBaseId, checklistBaseVersionId, projectId }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return function cleanup() {
      dispatch(removeAllChecklistBaseRows());
    };
  });

  useEffect(() => {
    dispatch(fetchCompanyChecklistBaseAsync({ companyId: companyId, checklistBaseId: checklistBaseId })).then(() => {
      dispatch(fetchChecklistBaseVersionsAsync({ companyId: companyId, checklistBaseId: checklistBaseId })).then(() => {
        dispatch(fetchChecklistBaseVersionRowsForEditingAsync({ companyId: companyId, checklistBaseId: checklistBaseId, checklistBaseVersionId: checklistBaseVersionId }));
      });
    });
  }, [checklistBaseVersionId]);

  return (
    <div className="flex w-full flex-col">
      <ChecklistBaseHeader checklistBaseId={checklistBaseId} checklistBaseVersionId={checklistBaseVersionId} companyId={companyId} projectId={projectId} />
      <ChecklistBaseBody checklistBaseId={checklistBaseId} checklistBaseVersionId={checklistBaseVersionId} companyId={companyId} projectId={projectId} />
    </div>
  );
};

export default ChecklistBaseContainer;
