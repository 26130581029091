import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhBreadcrumbs from "@components/breadcrumb/BhBreadcrumbs";
import { selectParentDirs } from "@/app/store/foldersSlice";
import { EntityId } from "@reduxjs/toolkit";
import { selectCurrentProjectId, selectRootDirectoryId, selectRootDocumentDirectoryId } from "@/app/store/project/projectSlice";
import BhTooltip from "@components/BhTooltip";
import BhButtonTemplateWithIcon from "@components/buttons/BhButtonTemplateWithIcon";
import { classNames } from "@/utilities/jsUtilities";
import { faBookmark as faBookmarkSolid } from "@fortawesome/pro-solid-svg-icons/faBookmark";
import { faBookmark as faBookmarkRegular } from "@fortawesome/pro-regular-svg-icons/faBookmark";
import { fetchFileEntityAsync, selectFileById, toggleFavouriteFileAsync } from "@/app/store/filesSlice";
import { useTranslation } from "react-i18next";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  currentDirId: EntityId;
}

const DirectoryBreadcrumbs: FC<Props> = ({ currentDirId }) => {
  const { t } = useTranslation();
  const fileEntity = useAppSelector((state) => selectFileById(state, currentDirId));
  const projectId = useAppSelector(selectCurrentProjectId);
  const parentDirs = useAppSelector((state) => selectParentDirs(state, currentDirId));
  const rootDirectoryId = useAppSelector(selectRootDirectoryId);
  const rootDocumentDirectoryId = useAppSelector(selectRootDocumentDirectoryId);
  const dispatch = useAppDispatch();
  const isCurrentDirectoryRoot = currentDirId === rootDirectoryId || currentDirId === rootDocumentDirectoryId;
  const enableFavouriteToggle = fileEntity && !isCurrentDirectoryRoot;

  useEffect(() => {
    if (!isCurrentDirectoryRoot && !fileEntity) {
      dispatch(fetchFileEntityAsync(currentDirId));
    }
  }, [currentDirId]);

  const breadcrumbs = parentDirs.map((dir) => ({
    text: !dir?.parentFileEntityId ? t(dir.name) : dir.name,
    url: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/dir/${dir.id}`
  }));

  return (
    <div className="flex flex-row items-center">
      <div className="py-1">
        <BhBreadcrumbs values={breadcrumbs}></BhBreadcrumbs>
      </div>
      {enableFavouriteToggle && (
        <div>
          <BhTooltip body={fileEntity.favourite ? t("GLOBAL.SET_NOT_PINNED") : t("GLOBAL.SET_PINNED")} delayShow={500}>
            <BhButtonTemplateWithIcon
              buttonProps={{ classes: classNames("hover:bh-bg-pigeon-20 w-3"), onClick: () => dispatch(toggleFavouriteFileAsync(fileEntity)) }}
              icon={fileEntity.favourite ? faBookmarkSolid : faBookmarkRegular}
              iconSize="sm"
            />
          </BhTooltip>
        </div>
      )}
    </div>
  );
};

export default React.memo(DirectoryBreadcrumbs);
