import BhModal from "@/components/modal/BhModal";
import React, { FC, useCallback, useState } from "react";
import BhTextOnlyButton from "@/components/buttons/BhTextOnlyButton";
import BhPrimaryButton from "@/components/buttons/BhPrimaryButton";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchProjectLogAsync, resetFileRestoreModalData, selectFileRestoreModalFileTree, selectFileRestoreModalLog, setFileRestoreModalShown } from "@/app/store/project/projectLogSlice";
import { useTranslation } from "react-i18next";
import { restoreFileFromEventLog } from "@/api/fileAPI";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhFileTreeContained from "@components/directory/BhFileTreeContained";
import { fullPageLoadingSet } from "@/app/store/globalSlice";

const ProjectLogFileRestoreModal: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const log = useAppSelector(selectFileRestoreModalLog);
  const fileTree = useAppSelector(selectFileRestoreModalFileTree);
  const projectId = useAppSelector(selectCurrentProjectId);
  const [restoreButtonDisabled, setRestoreButtonDisabled] = useState(false);

  const closeFileRestoreModal = () => {
    if (restoreButtonDisabled) return;
    dispatch(setFileRestoreModalShown(false));
    dispatch(resetFileRestoreModalData());
  };

  const restoreFileWithParents = useCallback(() => {
    setRestoreButtonDisabled(true);
    dispatch(fullPageLoadingSet(true));
    restoreFileFromEventLog(log.id)
      .then(() => {
        dispatch(setFileRestoreModalShown(false));
        dispatch(resetFileRestoreModalData());
        dispatch(fetchProjectLogAsync(projectId));
      })
      .finally(() => {
        dispatch(fullPageLoadingSet(false));
        setRestoreButtonDisabled(false);
      });
  }, [log]);

  return (
    <BhModal
      isShown={true}
      onClose={closeFileRestoreModal}
      setIsShown={() => {}}
      header={<h2>{t("FILE_RESTORE.MODAL.HEADER")}</h2>}
      children={
        <div className="px-8">
          <BhFileTreeContained fileEntity={fileTree} indicateDeletedFolders={true} />
        </div>
      }
      footer={
        <div>
          <BhTextOnlyButton buttonProps={{ onClick: closeFileRestoreModal, disabled: restoreButtonDisabled }}>{t("GLOBAL.CLOSE")}</BhTextOnlyButton>
          <BhPrimaryButton buttonProps={{ onClick: restoreFileWithParents, disabled: restoreButtonDisabled }}>{t("FILE.RESTORE")}</BhPrimaryButton>
        </div>
      }
    />
  );
};

export default ProjectLogFileRestoreModal;
