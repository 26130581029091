import React, { FC } from "react";
import { Authority, IPredefinedWorkGroupUser } from "@/model/IUserAuthority";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownButton from "@components/dropdown/BhDropdownButton";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";

interface Props {
  user: IPredefinedWorkGroupUser;
  onChange: Function;
  dropdownMenuValues: Array<{ value: Authority; translation: string }>;
  buttonValue: string;
}

const PartyTemplateUserListItemDropdown: FC<Props> = ({ onChange, dropdownMenuValues, buttonValue }) => {
  return (
    <td>
      <div className="flex h-5 flex-row items-center">
        <BhDropdown
          button={<BhDropdownButton reversed={true} title="" value={buttonValue} />}
          menu={
            <BhDropdownMenu
              values={dropdownMenuValues}
              textProperty="translation"
              type={BhDropdownTypeEnum.STRING}
              widthClass="max-w-min"
              onSelect={(value: { value: Authority; translation: string }) => onChange(value)}
            />
          }
        />
      </div>
    </td>
  );
};

export default PartyTemplateUserListItemDropdown;
