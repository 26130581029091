import React, { FC, useEffect } from "react";
import { FileEntityBranch, IFolderFileEntity } from "@/model/files/IFileEntity";
import { fetchParentsForDirectories } from "@/api/fileAPI";
import DirectorySelectionFolderContainer from "@/features/directoryContainer/DirectorySelectionFolderContainer";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import { useAppSelector } from "@/app/hooks";
import { selectSelectedFileIds } from "@/app/store/project/projectWorkGroupsSlice";

interface Props {
  directoryIds: Array<EntityId>; // Folder ids that need to be opened at start (or root dir id(s))
  selectOnlyOne: boolean;
  preSelectedDirectoryIds?: Array<EntityId>;
  allowOnlyParentsToBeSelected?: boolean;
  selectCallback?: Function;
  fileRootDir: IFolderFileEntity;
  modelRootDir: IFolderFileEntity;
  documentRootDir: IFolderFileEntity;
  setFileRootDir: Function;
  setDocumentRootDir: Function;
  setModelRootDir: Function;
  ignorePrivileges?: boolean;
  keepChildrenSelectedOnParentSelectionChange?: boolean;
  showSelectedDirectoriesCount?: boolean;
}

const DirectorySelectionContainer: FC<Props> = ({
  directoryIds,
  selectOnlyOne,
  preSelectedDirectoryIds,
  allowOnlyParentsToBeSelected,
  selectCallback,
  fileRootDir,
  documentRootDir,
  modelRootDir,
  setFileRootDir,
  setDocumentRootDir,
  setModelRootDir,
  ignorePrivileges,
  keepChildrenSelectedOnParentSelectionChange,
  showSelectedDirectoriesCount
}) => {
  const { t } = useTranslation();
  const selectedFileIds = useAppSelector(selectSelectedFileIds);
  const filteredSelectedFileIds = selectedFileIds.filter((fileId: { id: EntityId; branch: FileEntityBranch }) =>
    fileRootDir.id ? fileId.branch === FileEntityBranch.ROOT_DIR : documentRootDir ? fileId.branch === FileEntityBranch.ROOT_DOCUMENT_DIR : fileId.branch === FileEntityBranch.ROOT_DOCUMENT_DIR
  );

  useEffect(() => {
    if (directoryIds) {
      fetchFoldersAndBuildTree(directoryIds);
    }
  }, [directoryIds]);

  const fetchFoldersAndBuildTree = (dirIds: Array<EntityId>) => {
    fetchParentsForDirectories(dirIds).then((dirs) => {
      const fileTreeFolders = dirs.filter((dir) => dir.branch === FileEntityBranch.ROOT_DIR);
      const documentTreeFolders = dirs.filter((dir) => dir.branch === FileEntityBranch.ROOT_DOCUMENT_DIR);
      const modelTreeFolders = dirs.filter((dir) => dir.branch === FileEntityBranch.ROOT_BIM_DIR);

      if (fileTreeFolders.length > 0) {
        const fileTreeRootFolder = fileTreeFolders.find((folder) => !folder.parentFileEntityId);
        if (fileTreeRootFolder) {
          let fileTree = fileTreeRootFolder;
          buildTree(fileTree, fileTreeFolders);
          setFileRootDir(fileTree);
        }
      }
      if (documentTreeFolders.length > 0) {
        const documentTreeRootFolder = documentTreeFolders.find((folder) => !folder.parentFileEntityId);
        if (documentTreeRootFolder) {
          let documentTree = documentTreeRootFolder;
          buildTree(documentTree, documentTreeFolders);
          setDocumentRootDir(documentTree);
        }
      }
      if (modelTreeFolders.length > 0) {
        const modelTreeFolder = modelTreeFolders.find((folder) => !folder.parentFileEntityId);
        if (modelTreeFolder) {
          let modeltree = modelTreeFolder;
          buildTree(modelTreeFolder, modelTreeFolders);
          setModelRootDir(modeltree);
        }
      }
    });
  };

  const buildTree = (root: IFolderFileEntity, directoriesFlat: Array<IFolderFileEntity>) => {
    const children = directoriesFlat.filter((dir) => dir.parentFileEntityId === root.id);
    if (preSelectedDirectoryIds && preSelectedDirectoryIds.some((dirId) => dirId === root.id)) {
      root.selected = true;
    }
    if (children.length > 0) {
      if (allowOnlyParentsToBeSelected) {
        if (root.selected) {
          root.open = false;
          root.subDirs = children;
        } else {
          root.open = true;
          root.subDirs = children;
        }
      } else {
        root.open = true;
        root.subDirs = children;
      }
    }
    if (root.subDirs && root.subDirs.length > 0) {
      root.subDirs.forEach((sub) => {
        buildTree(sub, directoriesFlat);
      });
    }
  };

  const findAndUpdateDirectoryInTree = (root: IFolderFileEntity, directory: IFolderFileEntity) => {
    if (root.id === directory.id) {
      root.subDirs = directory.subDirs;
      root.open = directory.open;
      root.selected = directory.selected;
      root.subDirsFetched = directory.subDirsFetched;
      if (allowOnlyParentsToBeSelected && root.subDirs && root.subDirs.length > 0) {
        if (root.selected) {
          root.open = false;
        }
        root.subDirs.forEach((subDir) => {
          if (root.selected) {
            resetLowerTreeSelections(subDir);
          }
        });
      }
      return;
    }
    if (root.subDirs && root.subDirs.length > 0) {
      root.subDirs.forEach((dir) => {
        findAndUpdateDirectoryInTree(dir, directory);
      });
    }
  };

  const resetLowerTreeSelections = (root: IFolderFileEntity) => {
    if (keepChildrenSelectedOnParentSelectionChange) {
      if (preSelectedDirectoryIds && preSelectedDirectoryIds.some((dirId) => dirId === root.id)) {
        root.selected = true;
      }
    } else {
      if (root.selected) {
        root.selected = false;
      }
    }

    if (root.subDirs && root.subDirs.length > 0) {
      root.subDirs.forEach((dir) => {
        resetLowerTreeSelections(dir);
      });
    }
  };

  const updateDirectoryTree = (directory: IFolderFileEntity) => {
    const isFileTree = directory.branch === FileEntityBranch.ROOT_DIR;
    const isModelTree = directory.branch === FileEntityBranch.ROOT_BIM_DIR;
    let dirToSet = isFileTree ? fileRootDir : isModelTree ? modelRootDir : documentRootDir;
    if (selectOnlyOne) {
      resetLowerTreeSelections(fileRootDir);
      resetLowerTreeSelections(documentRootDir);
    }
    findAndUpdateDirectoryInTree(dirToSet, directory);
    const dirToSetWithSubDirs = { ...dirToSet, subDirs: dirToSet.subDirs };
    isFileTree ? setFileRootDir(dirToSetWithSubDirs) : isModelTree ? setModelRootDir(dirToSetWithSubDirs) : setDocumentRootDir(dirToSetWithSubDirs);
  };

  const anyFilesSelected = filteredSelectedFileIds.length > 0;

  return (
    <div className="w-full py-8">
      {showSelectedDirectoriesCount && (
        <div className="mb-2 h-5">
          {anyFilesSelected && (
            <div className="flex flex-row items-center space-x-1">
              <span>{t("GLOBAL.SELECTED")}</span>
              <BhBadge type={BhBadgeType.BRIGHT}>{filteredSelectedFileIds.length}</BhBadge>
            </div>
          )}
        </div>
      )}
      {fileRootDir.id && (
        <DirectorySelectionFolderContainer
          directory={fileRootDir}
          setDirectory={updateDirectoryTree}
          allowOnlyParentsToBeSelected={allowOnlyParentsToBeSelected}
          changeCallback={selectCallback}
          ignorePrivileges={ignorePrivileges}
          showSelectedDirectoriesCount={showSelectedDirectoriesCount}
        />
      )}
      {documentRootDir.id && (
        <DirectorySelectionFolderContainer
          directory={documentRootDir}
          setDirectory={updateDirectoryTree}
          allowOnlyParentsToBeSelected={allowOnlyParentsToBeSelected}
          changeCallback={selectCallback}
          ignorePrivileges={ignorePrivileges}
          showSelectedDirectoriesCount={showSelectedDirectoriesCount}
        />
      )}
      {modelRootDir.id && (
        <DirectorySelectionFolderContainer
          directory={modelRootDir}
          setDirectory={updateDirectoryTree}
          allowOnlyParentsToBeSelected={allowOnlyParentsToBeSelected}
          changeCallback={selectCallback}
          ignorePrivileges={ignorePrivileges}
          showSelectedDirectoriesCount={showSelectedDirectoriesCount}
        />
      )}
    </div>
  );
};

export default DirectorySelectionContainer;
