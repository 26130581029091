import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/app/hooks";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import BhLoadingIconContained from "@components/loading/BhLoadingIconContained";
import { SidebarItemType } from "@/model/ISidebarItem";

interface Props {
  src: string;
  hideSidebar?: boolean;
  sidebarItem?: SidebarItemType;
}

const BhIFrameComponent: FC<Props> = ({ src, hideSidebar, sidebarItem }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const [isIFrameLoaded, setIsIFrameLoaded] = useState<boolean>(false);

  // This hook is listening an event that came from the Iframe
  useEffect(() => {
    if (sidebarItem) {
      dispatch(setCurrentSidebarMenuItem(sidebarItem));
    }
    if (hideSidebar) {
      dispatch(setSidebarCollapsed(hideSidebar));
    }

    const handler = (ev: MessageEvent<{ type: string; message: string }>) => {
      const messageTypes = ["redirect"];

      if (typeof ev.data !== "object") return;
      if (!ev.data.type) return;
      if (!messageTypes.includes(ev.data.type)) return;
      if (!ev.data.message) return;

      const type = ev.data.type;
      const message = ev.data.message;

      if (type === "redirect") {
        if (message && typeof message === "object") {
          navigate(message["url"], { state: message["state"] });
          return;
        }
        navigate(message);
      }
    };

    window.addEventListener("message", handler);

    return () => {
      window.removeEventListener("message", handler);
    };
  }, []);

  useEffect(() => {
    if (iFrameRef.current) {
      iFrameRef.current?.addEventListener("load", () => setIsIFrameLoaded(true));
    }
    return () => {
      if (iFrameRef.current) {
        iFrameRef.current?.removeEventListener("load", () => setIsIFrameLoaded(true));
      }
    };
  }, []);

  return (
    <div className="h-full w-full">
      {!isIFrameLoaded && <BhLoadingIconContained />}
      <iframe ref={iFrameRef} id="bhAngular" src={src} className="h-full w-full" />
    </div>
  );
};

export default BhIFrameComponent;
