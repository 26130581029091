import React, { FC } from "react";
import BhTableRow from "@components/table/BhTableRow";

interface Props {
  isProjectFormTable?: boolean;
}

const FormsSettingsTableSkeleton: FC<Props> = ({ isProjectFormTable }) => {
  return (
    <>
      {[1, 2, 3].map((i) => (
        <BhTableRow key={i}>
          {!isProjectFormTable && <td />}
          <td>
            <div className="bh-bg-pigeon-50 h-5 w-64 animate-pulse rounded" />
          </td>
          <td />
          <td>
            <div className="bh-bg-pigeon-50 h-5 w-12 animate-pulse rounded" />
          </td>
          <td colSpan={2} />
        </BhTableRow>
      ))}
    </>
  );
};

export default FormsSettingsTableSkeleton;
