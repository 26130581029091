import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhModal from "@components/modal/BhModal";
import { copyTaskAsync, setTaskModalsOpen } from "@/app/store/tasksSlice";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhInputWithChips from "@components/input/BhInputWithChips";
import { ITask } from "@/model/taskBoard/ITask";
import { selectOpenedTaskBoardUsers } from "@/app/store/taskBoardsSlice";
import { getUserFromFullName } from "@/utilities/jsUtilities";
import { convertSimpleValidUserAuthorityToUser } from "@/model/ISimpleValidUserAuthority";
import { getUserFullName, IUser } from "@/model/IUser";
import { useTranslation } from "react-i18next";
import BhModalFooter from "@components/modal/BhModalFooter";

interface Props {
  task: ITask;
}

const TaskCopyModal: FC<Props> = ({ task }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const taskBoardUsers = useAppSelector(selectOpenedTaskBoardUsers);
  const [taskToCopy, setTaskToCopy] = useState({ ...task, ...{ attachments: [] } });
  const assignee = taskToCopy.assignee ? [{ ...getUserFromFullName(taskToCopy.assigneeFullName), ...{ id: taskToCopy.assignee } }] : [];
  const [availableUsers, setAvailableUsers] = useState(taskBoardUsers.map((user) => convertSimpleValidUserAuthorityToUser(user)));

  const closeModal = () => {
    dispatch(setTaskModalsOpen({ modal: "taskCopyModal" }));
  };

  const removeAssignee = () => {
    setTask({ assignee: null, assigneeFullName: null });
  };

  const selectAssignee = (user: IUser) => {
    setTask({ assignee: user.id, assigneeFullName: getUserFullName(user) });
  };

  const removeParticipant = (user: IUser) => {
    setTask({ participants: taskToCopy.participants.filter((participant) => participant.id !== user.id) });
  };

  const selectParticipant = (user: IUser) => {
    setTask({ participants: [...taskToCopy.participants, user] });
  };

  const setNewTaskName = (nameObject: any) => {
    setTask(nameObject);
  };

  const setAttachments = (e: any) => {
    setTask({ attachments: e.target.checked ? task.attachments : [] });
  };

  const setTask = (changedObject: any) => {
    setTaskToCopy({ ...taskToCopy, ...changedObject });
  };

  const copyTask = () => {
    dispatch(copyTaskAsync(taskToCopy));
    dispatch(setTaskModalsOpen({ modal: "taskCopyModal" }));
  };

  return (
    <BhModal
      size="xl"
      isShown={true}
      setIsShown={() => {}}
      onClose={closeModal}
      header={
        <div className="h-7">
          <h2 className="mt-1 leading-7">{t("TASK.COPY_TASK")}</h2>
        </div>
      }
      children={
        <div className="px-8 pt-3 pb-4">
          <BhInputStackedLabel initialValue={taskToCopy.name} property={"name"} label={t("TASK.TASK_NAME")} saveCallback={setNewTaskName} />
          <div className="my-5 flex flex-row items-center">
            <input className="mr-3 h-4 w-4 rounded focus:ring-0 focus:ring-offset-0" type="checkbox" onChange={(e) => setAttachments(e)} />
            <span>{t("TASK.COPY.WITH_ATTACHMENTS")}</span>
          </div>
          <BhInputWithChips label={t("TASK.ASSIGNEE") as string} currentValues={assignee} dropdownValues={availableUsers} onRemove={removeAssignee} onSelect={selectAssignee} />
          <div className="mt-4">
            <BhInputWithChips
              label={t("TASK.PARTICIPANTS") as string}
              currentValues={taskToCopy.participants}
              dropdownValues={availableUsers}
              onRemove={removeParticipant}
              onSelect={selectParticipant}
            />
          </div>
        </div>
      }
      footer={<BhModalFooter onConfirm={copyTask} confirmButtonText={t("GLOBAL.COPY") as string} onCancel={closeModal} />}
    />
  );
};

export default TaskCopyModal;
