import React, { FC, useState } from "react";
import { CategoryDefaultCode, IProjectTaskCategory } from "@/model/taskBoard/IProjectTaskCategory";
import BhInputSimple from "@components/input/BhInputSimple";
import BhIconButton from "@components/buttons/BhIconButton";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import BhColorPicker from "@components/colorPicker/BhColorPicker";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { useTranslation } from "react-i18next";

interface Props {
  category: IProjectTaskCategory;
  availableColors: Array<string>;
  saveColor: Function;
  saveName: Function;
  deleteCallback: Function;
}

const CategoriesModalRow: FC<Props> = ({ category, availableColors, saveColor, saveName, deleteCallback }) => {
  const { t } = useTranslation();
  const translateValues = Object.keys(CategoryDefaultCode).includes(category.name.toUpperCase());
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);

  const saveCategoryColor = (colorObj: any) => {
    saveColor({ ...category, ...colorObj });
  };

  const saveCategoryName = (nameObj: any) => {
    saveName({ ...category, ...{ name: nameObj.name } });
  };

  const deleteRow = () => {
    if (category.id) {
      setDeleteConfirmationModalOpen(true);
    } else {
      deleteCallback(category);
    }
  };

  const handleDelete = () => {
    setDeleteConfirmationModalOpen(false);
    deleteCallback(category);
  };

  return (
    <div className="mb-4 flex flex-row items-center">
      <BhColorPicker currentColor={category.color} dropDownValues={availableColors} onSelect={saveCategoryColor} />
      <BhInputSimple
        initialValue={translateValues ? t("TASK.CATEGORY." + category.name.toUpperCase()) : category.name}
        property={"name"}
        placeholder={t("TASK.CATEGORY.ADD_NAME") as string}
        onBlurAction={saveCategoryName}
      />
      <BhIconButton icon={faTrash} buttonProps={{ onClick: deleteRow, classes: "ml-4", disabled: category.code === CategoryDefaultCode.TASK }} />
      {deleteConfirmationModalOpen && (
        <BhDeleteConfirmationModal
          isOpen={true}
          setIsOpen={setDeleteConfirmationModalOpen}
          header={<h2>{t("TASK.CATEGORY.DELETE.MODAL.HEADER")}</h2>}
          body={<div dangerouslySetInnerHTML={{ __html: t("TASK.CATEGORY.DELETE.MODAL.BODY1") as any }} />}
          confirmationButtonText={t("GLOBAL.DELETE")}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default CategoriesModalRow;
