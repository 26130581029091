import React, { FC } from "react";

const FileContainerSkeleton: FC = () => {
  return (
    <div className="bh-border-pigeon-40 bh-hover-container flex h-14 items-center border-b">
      <div className="flex w-8 flex-none items-center justify-center"></div>
      <div className="relative flex w-9 flex-none justify-center text-center"></div>
      <div className="min-w-24 flex flex-grow flex-row items-center overflow-hidden px-3">
        <div className="h-5 w-3/4 animate-pulse rounded bg-gray-400" />
      </div>
      <div className="w-18 flex-none px-3 text-center sm:hidden">
        <div className="h-5 w-3/4 animate-pulse rounded bg-gray-400" />
      </div>
      <div className="w-12 flex-none text-center lg:hidden"></div>
      <div className="w-12 flex-none text-center lg:hidden"></div>
      <div className="w-40 flex-none px-3 lg:hidden">
        <div className="h-5 w-3/4 animate-pulse rounded bg-gray-400" />
      </div>
      <div className="w-14 flex-none px-3 text-right"></div>
    </div>
  );
};

export default FileContainerSkeleton;
