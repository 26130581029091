import React, { FC, useCallback, useEffect, useState } from "react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { useTranslation } from "react-i18next";
import TooplaanWorkRow from "@components/form/fields/oldCustomFields/tooplaan/TooplaanWorkRow";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { getWeeksForYear } from "@/utilities/dateUtility";
import { createObjectId } from "@/utilities/formUtilities";
import { IFormDataSaveRequest } from "@/model/IForm";
import { faArrowUp } from "@fortawesome/pro-regular-svg-icons/faArrowUp";
import { faArrowDown } from "@fortawesome/pro-regular-svg-icons/faArrowDown";
import { classNames } from "@/utilities/jsUtilities";
import BhTooltip from "@components/BhTooltip";

interface Props {
  week: any;
  saveCallback?: Function;
  removeCallback?: Function;
  changeWeekOrderCallback: Function;
  weekIndex: number;
  changeWeekCallback: Function;
  disabled: boolean;
}

const TooplaanWeekTable: FC<Props> = ({ week, saveCallback, removeCallback, weekIndex, changeWeekOrderCallback, changeWeekCallback, disabled }) => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(week.selectedYear as number);
  const availableYears = [currentYear.toString(), (currentYear + 1).toString(), (currentYear + 2).toString(), (currentYear + 3).toString(), (currentYear + 4).toString()];
  const [selectedYearWeeks, setSelectedYearWeeks] = useState([] as Array<any>);
  const [selectedWeek, setSelectedWeek] = useState(week.selectedWeek);
  const [lastFocusedElement, setLastFocusedElement] = useState<any>(undefined);
  const isLastFocusedElementInput = lastFocusedElement?.name && ["name", "notes"].includes(lastFocusedElement.name);

  const onBlurFunction = useCallback((event: any) => {
    setLastFocusedElement(event.target);
    setTimeout(() => {
      setLastFocusedElement(undefined);
    }, 500);
  }, []);

  useEffect(() => {
    document.addEventListener("blur", onBlurFunction, true);

    return () => {
      document.removeEventListener("blur", onBlurFunction, true);
    };
  }, []);

  useEffect(() => {
    if (week) {
      setSelectedYear(week.selectedYear as number);
      setSelectedWeek(week.selectedWeek);
    }
  }, [week]);

  useEffect(() => {
    const weeksForYear = getWeeksForYear(selectedYear);
    const weekNumberToSet = week.info.year === selectedYear ? week.info.number : 1;
    const weekToSet = weeksForYear.find((week) => week.number === weekNumberToSet);
    if (weekToSet) {
      setSelectedWeek(weekToSet);
    }
    setSelectedYearWeeks(weeksForYear);
  }, [selectedYear]);

  const saveWeek = (changedObject: any, changedProperty: any) => {
    const saveRequest = {
      path: "weeks",
      changedProperty: changedProperty,
      changedValue: changedObject[changedProperty],
      changedObjectId: week._id
    } as IFormDataSaveRequest;
    saveCallback && saveCallback(saveRequest);
  };

  const saveWork = (work: any) => {
    const weekWorks = week.worksInWeek.map((w: any) => {
      if (w._id === work._id) {
        return work;
      }
      return w;
    });
    const worksToSave = { worksInWeek: weekWorks };
    saveWeek(worksToSave, "worksInWeek");
  };

  const addWorkToWeek = () => {
    if (isLastFocusedElementInput) return;
    const workToAdd = { days: { m: false, tu: false, w: false, th: false, f: false, sa: false, su: false }, isObjectRow: false, _id: createObjectId() };
    const weekWorks = [...week.worksInWeek, workToAdd];
    const worksToSave = { worksInWeek: weekWorks };
    saveWeek(worksToSave, "worksInWeek");
  };

  const removeWorkFromWeek = (work: any) => {
    const worksToSave = {
      worksInWeek: week.worksInWeek.filter((w: any) => {
        return w._id !== work._id;
      })
    };
    saveWeek(worksToSave, "worksInWeek");
  };

  const changeWorkOrder = (workToChange: any, oldIndex: number, newIndex: number) => {
    let works = week.worksInWeek.slice();
    const firstWorkMovedHigher = oldIndex === 0 && newIndex < oldIndex;
    const lastWorkMovedLower = oldIndex === works.length - 1 && newIndex > oldIndex;
    if (firstWorkMovedHigher || lastWorkMovedLower) {
      return;
    }
    works.splice(oldIndex, 1);
    works.splice(newIndex, 0, workToChange);
    const worksToSave = { worksInWeek: works };
    saveWeek(worksToSave, "worksInWeek");
  };

  const [showWeekDeleteConfirmationModal, hideWeekDeleteConfirmationModal] = useModal(
    () => (
      <BhDeleteConfirmationModal
        setIsOpen={hideWeekDeleteConfirmationModal}
        header={<h2>{t("FORMS.CONFIRMATION.DELETE")}</h2>}
        body={<div>{t("FORMS.CONFIRMATION.DELETE_BODY")}</div>}
        confirmationButtonText={t("GLOBAL.DELETE")}
        handleDelete={() => {
          removeCallback && removeCallback(week, "weeks");
          hideWeekDeleteConfirmationModal();
        }}
      />
    ),
    [week]
  );

  const changeWeekOrder = (moveHigher: boolean) => {
    const newIndex = moveHigher ? weekIndex - 1 : weekIndex + 1;
    changeWeekOrderCallback(week, weekIndex, newIndex);
  };

  const changeWeek = () => {
    changeWeekCallback(week, selectedYear, selectedWeek);
  };

  return (
    <div>
      <div className="mb-1 flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <div className="font-bold">{week.info.number + ". " + t("FORMS.TOOPLAAN.WEEK")}</div>
          <div className="ml-2 flex h-full flex-row items-center justify-center">
            <BhTooltip body={t("FORM.TABLE_ACTIONS.MOVE_UP")}>
              <div className="hover:bh-bg-pigeon-20 flex h-5 w-5 cursor-pointer flex-row items-center justify-center rounded p-1" onClick={() => changeWeekOrder(true)}>
                <FontAwesomeIcon icon={faArrowUp} size={"xs"} />
              </div>
            </BhTooltip>
            <BhTooltip body={t("FORM.TABLE_ACTIONS.MOVE_DOWN")}>
              <div className="hover:bh-bg-pigeon-20 flex h-5 w-5 cursor-pointer flex-row items-center justify-center rounded p-1" onClick={() => changeWeekOrder(false)}>
                <FontAwesomeIcon icon={faArrowDown} size={"xs"} />
              </div>
            </BhTooltip>
            <BhTooltip body={t("FORM.TABLE_ACTIONS.DELETE_WEEK")}>
              <div className="hover:bh-bg-pigeon-20 flex h-5 w-5 cursor-pointer flex-row items-center justify-center rounded p-1" onClick={showWeekDeleteConfirmationModal}>
                <FontAwesomeIcon icon={faTrash} size={"xs"} />
              </div>
            </BhTooltip>
          </div>
        </div>
        <div className="flex flex-row items-center">
          {(selectedYear !== week.selectedYear || selectedWeek.number !== week.info.number) && (
            <BhTextOnlyButton buttonProps={{ onClick: changeWeek, disabled: disabled }}>{t("FORMS.TOOPLAAN.CHANGE_WEEK")}</BhTextOnlyButton>
          )}
          <BhDropdown
            button={
              <BhTextOnlyButton icon={faCaretDown} buttonProps={{ disabled: disabled }}>
                {selectedYear}
              </BhTextOnlyButton>
            }
            menu={<BhDropdownMenu values={availableYears} onSelect={(value: string) => setSelectedYear(parseInt(value))} type={BhDropdownTypeEnum.STRING} widthClass="w-fit" />}
            position={BhDropdownPositionEnum.BOTTOM_LEFT}
            disabled={disabled}
          />
          <BhDropdown
            button={
              <BhTextOnlyButton icon={faCaretDown} buttonProps={{ disabled: disabled }}>
                {selectedWeek.number + " (" + selectedWeek.dateRange + ")"}
              </BhTextOnlyButton>
            }
            menu={<BhDropdownMenu values={selectedYearWeeks} textProperty={"text"} onSelect={setSelectedWeek} type={BhDropdownTypeEnum.STRING} widthClass="w-fit whitespace-nowrap" />}
            position={BhDropdownPositionEnum.BOTTOM_LEFT}
            disabled={disabled}
          />
        </div>
      </div>
      <table className="form-table">
        <thead>
          <tr>
            <th>{t("FORMS.TOOPLAAN.PLANNED_WORKS.NAME")}</th>
            <th colSpan={7}>{t("FORMS.TOOPLAAN.PLANNED_WORKS.TIME")}</th>
            <th rowSpan={4} className="w-1/3 align-top">
              {t("FORMS.TOOPLAAN.PLANNED_WORKS.NOTES")}
            </th>
            <th rowSpan={4} className="w-24"></th>
          </tr>
          <tr>
            <th rowSpan={3}></th>
            <th colSpan={7}>{week.info.monthName}</th>
          </tr>
          <tr>
            <th className="w-6">{t("FORMS.TOOPLAAN.MONDAY")}</th>
            <th className="w-6">{t("FORMS.TOOPLAAN.TUESDAY")}</th>
            <th className="w-6">{t("FORMS.TOOPLAAN.WEDNESDAY")}</th>
            <th className="w-6">{t("FORMS.TOOPLAAN.THURSDAY")}</th>
            <th className="w-6">{t("FORMS.TOOPLAAN.FRIDAY")}</th>
            <th className="w-6">{t("FORMS.TOOPLAAN.SATURDAY")}</th>
            <th className="w-6">{t("FORMS.TOOPLAAN.SUNDAY")}</th>
          </tr>
          <tr>
            {week.dates.map((date: any, index: number) => {
              return <th key={index}>{date}</th>;
            })}
          </tr>
        </thead>

        <tbody>
          {week.worksInWeek.map((work: any, index: number) => {
            return (
              <TooplaanWorkRow
                work={work}
                saveCallback={saveWork}
                workIndex={index}
                removeWorkCallback={removeWorkFromWeek}
                changeWorkOrderCallback={changeWorkOrder}
                key={index}
                disabled={disabled}
                lastFocusedElement={lastFocusedElement}
                setLastFocusedElement={setLastFocusedElement}
                isLastFocusedElementInput={isLastFocusedElementInput}
              />
            );
          })}
          {!disabled && (
            <tr>
              <td colSpan={10}>
                <div
                  className={classNames(!isLastFocusedElementInput && "cursor-pointer hover:underline", "ml-1 flex flex-row items-center gap-x-2")}
                  onClick={!isLastFocusedElementInput ? addWorkToWeek : undefined}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <div>{t("FORMS.TOOPLAAN.ADD_ROW")}</div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TooplaanWeekTable;
