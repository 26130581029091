import React, { FC } from "react";
import { IBhButtonWithIconProps } from "@components/buttons/IBhButtonProps";
import BhButton from "@components/buttons/BhButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "@/utilities/jsUtilities";

const BhButtonTemplateWithIcon: FC<IBhButtonWithIconProps> = React.memo(({ buttonProps, icon, iconClasses, spin, iconSize, children, right }) => {
  const iconClassName = classNames(iconClasses, children && !right && "mr-1.5", children && right && "ml-1", iconSize || "h-16px", "my-1");

  const iconElement = icon && <FontAwesomeIcon icon={icon} className={iconClassName} aria-hidden="true" spin={spin} size={iconSize} />;

  return (
    <BhButton buttonProps={buttonProps}>
      {!right && iconElement}
      <span className="button-text uppercase">{children}</span>
      {right && iconElement}
    </BhButton>
  );
});

export default BhButtonTemplateWithIcon;
