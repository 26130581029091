import React from "react";
import { EntityId } from "@reduxjs/toolkit";
import { deleteShareboxAsync, selectShareboxById } from "@/app/store/shareboxesSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BhCardContainer from "@components/cards/BhCardContainer";
import BhIconButton from "@components/buttons/BhIconButton";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faClock, faFile, faLink, faShareFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import BhLargeBoldText from "@components/text/BhLargeBoldText";
import { formatDate, formatDateTime } from "@/utilities/dateUtility";
import { useTranslation } from "react-i18next";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons/faCircleExclamation";
import { classNames, copyToClipboard } from "@/utilities/jsUtilities";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { useModal } from "react-modal-hook";
import BhTooltip from "@components/BhTooltip";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  shareboxId: EntityId;
  userShareboxes: boolean;
}

const ShareboxCard = React.memo<Props>(({ shareboxId, userShareboxes }) => {
  const { t } = useTranslation();
  const sharebox = useAppSelector((state) => selectShareboxById(state, shareboxId));
  const dispatch = useAppDispatch();
  const expired = sharebox && sharebox.validTo && new Date(sharebox.validTo) < new Date();
  const shareboxUpdatedAfterCreation = sharebox && sharebox.updated > sharebox.created;

  const [showReplaceFileConfirmationModal, hideReplaceFileConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideReplaceFileConfirmationModal}
      header={<h2>{t("MODAL.SHAREBOX.DELETE.CONFIRMATION.TITLE")}</h2>}
      body={<div>{t("MODAL.SHAREBOX.DELETE.CONFIRMATION.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={(e: React.MouseEvent) => {
        e.stopPropagation();
        dispatch(deleteShareboxAsync(shareboxId));
      }}
    />
  ));

  if (!sharebox) {
    return null;
  }

  const handleShareboxDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    showReplaceFileConfirmationModal();
  };

  const handleCopyPublicUrlToClipboard = (e: React.MouseEvent) => {
    e.preventDefault();
    const shareboxPublicUrl = ConfigSingleton.getInstance().getConfig().REACT_APP_BASE_URL + "/public/sharebox/" + sharebox.uuid;
    copyToClipboard(shareboxPublicUrl, "TOAST.LINK_COPIED");
  };

  return (
    <BhCardContainer classes="md:px-2 p-4 pr-3">
      <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${sharebox.projectId}/sharebox/${sharebox.id}`} className="flex w-full flex-row items-center">
        <div className="min-w-32px bh-text-deep-ocean-40 w-10 shrink-0 sm:hidden">
          <FontAwesomeIcon icon={faShareFromSquare} className="h-4" />
        </div>
        <div className="min-w-0 flex-grow">
          <BhLargeBoldText classes="mb-1">{sharebox.name}</BhLargeBoldText>
          {userShareboxes && (
            <span className="bh-text-deep-ocean-80 text-sm font-bold">
              {t("SHAREBOX.LIST.CREATED")} {formatDate(sharebox.created)}
            </span>
          )}
          {!userShareboxes && (
            <span className="bh-text-deep-ocean-80 text-sm font-bold">
              {t("SHAREBOX.LIST.CREATED_BY")} {formatDateTime(sharebox.created)} {sharebox.creatorName}
            </span>
          )}
          {shareboxUpdatedAfterCreation && (
            <span className="bh-text-deep-ocean-80 ml-1 text-sm">
              ({t("SHAREBOX.LIST.CHANGED")} {formatDateTime(sharebox.updated)})
            </span>
          )}
        </div>
        <div className="min-w-92px w-32 flex-shrink-0 text-center md:w-16 sm:hidden">
          <FontAwesomeIcon icon={faFile} />
          <div className="text-center font-bold">{sharebox.numberOfFiles | 0}</div>
        </div>
        <div className="min-w-92px bh-text-deep-ocean w-32 flex-shrink-0 md:w-16 sm:hidden">
          {sharebox.validTo && (
            <div className={classNames(expired && "bh-text-error-red")}>
              <FontAwesomeIcon icon={expired ? faCircleExclamation : faClock} className="mr-1" /> {expired ? `${t("SHAREBOX.LIST.EXPIRED")}` : `${t("SHAREBOX.LIST.VALID_TO")}`}
              <div className="font-bold">{formatDate(sharebox.validTo)}</div>
            </div>
          )}
        </div>
        <div className="flex-shrink-0">
          <BhSecondaryButton icon={faLink} buttonProps={{ classes: "px-3", onClick: handleCopyPublicUrlToClipboard }}>
            {t("SHAREBOX.COPY_LINK")}
          </BhSecondaryButton>
        </div>
        <div className="ml-1 w-9 flex-shrink-0 md:w-9">
          <BhTooltip body={t("SHAREBOX.DELETE.TOOLTIP")}>
            <BhIconButton icon={faTrash} buttonProps={{ onClick: handleShareboxDelete }} />
          </BhTooltip>
        </div>
      </Link>
    </BhCardContainer>
  );
});
export default ShareboxCard;
