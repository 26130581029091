import React, { FC } from "react";
import { IFileEntity } from "@/model/files/IFileEntity";
import FileNameLink from "@/features/fileContainer/FileNameLink";
import BhCommentAttachmentDropdown from "@components/comment/BhCommentAttachmentDropdown";
import { fetchUrlForFile } from "@/api/fileAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  fileEntity: IFileEntity;
  disabled?: boolean;
  openInNewTab?: boolean;
  removeCallback?: Function;
  renameCallback?: Function;
  downloadContainers?: boolean;
  attachmentFileIds?: Array<EntityId>;
}

const BhFileCardLarge: FC<Props> = ({ fileEntity, disabled, openInNewTab, removeCallback, renameCallback, downloadContainers, attachmentFileIds }) => {
  const extension = fileEntity.name.substring(fileEntity.name.lastIndexOf(".") + 1).toUpperCase();

  const downloadFile = () => {
    fetchUrlForFile(fileEntity.id, false, true, fileEntity.uuid).then((presignedUrl) => {
      imitateUrlDownload(presignedUrl.value);
    });
  };

  const removeCallbackOrUndefined = removeCallback
    ? () => {
        removeCallback && removeCallback(fileEntity.id);
      }
    : undefined;

  const renameCallbackOrUndefined = renameCallback
    ? () => {
        renameCallback && renameCallback(fileEntity.id);
      }
    : undefined;

  return (
    <div className="bh-border-pigeon-40 hover:bh-border-pigeon-60 bh-hover-container relative flex h-14 w-56 flex-row items-center rounded border p-3">
      <BhFileCardIcon fileEntity={fileEntity} fileCardSize={FileCardSize.LARGE} />
      <div className="ml-3 flex w-full flex-row items-center justify-between">
        <div>
          <div className="bh-file-card-large-text overflow-hidden text-ellipsis whitespace-nowrap text-sm">
            <FileNameLink
              fileEntity={fileEntity}
              disableOnClickHandler={disabled}
              openInNewTab={openInNewTab}
              downloadContainers={downloadContainers}
              attachmentFileIds={attachmentFileIds}
              canRename={renameCallback !== undefined}
            />
          </div>
          <div className="bh-text-deep-ocean-60 w-18 overflow-hidden whitespace-nowrap text-xs">
            <span>{extension}</span>
          </div>
        </div>
        <BhCommentAttachmentDropdown renameCallback={renameCallbackOrUndefined} removeCallback={removeCallbackOrUndefined} handleDownload={downloadFile} />
      </div>
    </div>
  );
};

export default BhFileCardLarge;
