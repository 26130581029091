import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IObjectChange } from "@/model/IObjectChange";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDateTime } from "@/utilities/dateUtility";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { classNames } from "@/utilities/jsUtilities";
import BhTableRow from "@components/table/BhTableRow";
import { faArrowRotateLeft } from "@fortawesome/pro-regular-svg-icons/faArrowRotateLeft";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";

interface Props {
  log: IObjectChange;
  restoreChecklist: Function;
  isRestorable: Function;
}

const ProjectChecklistLogsModalRow: FC<Props> = ({ log, restoreChecklist, isRestorable }) => {
  const { t } = useTranslation();

  return (
    <BhTableRow>
      <td>
        <div className="my-1.5 flex w-full flex-row items-center space-x-2">
          <div
            className={classNames(
              "flex inline-flex flex-row items-center rounded text-center",
              log.data.action === "deleted" && "bh-bg-error-red-10",
              log.data.action === "completed" && "bh-bg-success-green-10",
              log.data.action === "restored" && "bh-bg-warning-yellow-10"
            )}
          >
            <span className="px-1">
              <FontAwesomeIcon
                icon={faCircle}
                size="xs"
                className={classNames(
                  log.data.action === "deleted" && "bh-text-error-red",
                  log.data.action === "completed" && "bh-text-success-green",
                  log.data.action === "restored" && "bh-text-warning-yellow"
                )}
              />
            </span>
            <span className="px-1">{t("CHECKLIST.HISTORY.ACTION." + log.data.action.toUpperCase()) + (log.data.roundNumber || "")}</span>
          </div>
          {isRestorable(log) && (
            <BhTextOnlyButton buttonProps={{ onClick: () => restoreChecklist(log) }} icon={faArrowRotateLeft}>
              {t("FILE.RESTORE")}
            </BhTextOnlyButton>
          )}
        </div>
      </td>
      <td>
        <div className="flex flex-row items-center gap-x-2">
          <BhFileCardIcon fileEntity={{ id: log.id, name: log.data.name, type: FileEntityType.CHECKLIST } as IFileEntity} fileCardSize={FileCardSize.LARGE} />
          <div>{log.data.name}</div>
        </div>
      </td>
      <td>{formatDateTime(log.created)}</td>
    </BhTableRow>
  );
};

export default ProjectChecklistLogsModalRow;
