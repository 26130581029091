import React, { FC, ReactNode } from "react";

import noMatchesFox from "@img/rebane-p6hu-peal-compressed.png";
import BhLargeBoldText from "@components/text/BhLargeBoldText";

interface Props {
  children?: ReactNode;
}

const BhNoImagesList: FC<Props> = ({ children }) => {
  return (
    <div className="flex w-full flex-col items-center justify-center">
      <img src={noMatchesFox} className="m-8 w-full max-w-[280px]" alt="No matches" />
      <BhLargeBoldText classes="text-center">{children}</BhLargeBoldText>
    </div>
  );
};

export default BhNoImagesList;
