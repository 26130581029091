import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

//Figma: https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H/Bauhub-design-system?node-id=2540%3A105323
interface Props {
  callback?: Function;
  icon: IconProp;
}

//TODO kas see komponent tõsta kokku BhUploadButtoniga?
const BhAttachFileButton: FC<Props> = ({ callback, icon }) => {
  const onClick = () => {
    callback && callback();
  };

  return (
    <button className="bh-bg-smoke bh-border-pigeon-40 hover:bh-bg-pigeon-20 active:bh-bg-pigeon-40 h-14 w-14 rounded border border-dashed text-center" onClick={onClick}>
      <FontAwesomeIcon icon={icon} />
    </button>
  );
};
export default BhAttachFileButton;
