import { IUserAuthority } from "@/model/IUserAuthority";

export interface IFileConfirmationsFilter {
  name: string;
  userRoles: Array<IFileConfirmationsRoleFilter>;
  users: Array<IUserAuthority>;
  status: FileConfirmationStatusFilter;
}

export interface IFileConfirmationsRoleFilter {
  name: FileConfirmationUserRoleFilterEnum;
  translation: string;
}

export enum FileConfirmationStatusFilter {
  ALL = "ALL",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED"
}

export enum FileConfirmationUserRoleFilterEnum {
  CREATOR = "CREATOR",
  NEXT_CONFIRMER = "NEXT_CONFIRMER",
  PARTICIPANT = "PARTICIPANT"
}

export const initialFileConfirmationsFilter = {
  userRoles: [] as Array<IFileConfirmationsRoleFilter>,
  users: [] as Array<IUserAuthority>,
  status: FileConfirmationStatusFilter.ALL
} as IFileConfirmationsFilter;
