import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import { BhOwnSignatureStatusType } from "@components/container/BhSignatureStatusType";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";

interface Props {
  signature: ISignatureHolder;
  queueEnabled: boolean;
}

const BhSignatureStatusLabelIconOnly: FC<Props> = ({ signature, queueEnabled }) => {
  const { t } = useTranslation();

  const getStatus = () => {
    if (signature.declined) {
      return "REFUSED";
    } else if (signature.isUnsignedInvite) {
      if (!queueEnabled) {
        return "PENDING";
      } else {
        if (signature.nextInLine) {
          return "PENDING";
        } else {
          return "IN_LINE";
        }
      }
    } else if (signature.signatureId) {
      if (signature.isValid) {
        return "SIGNED";
      } else {
        return "SIGNED_NOT_VALID";
      }
    }
  };

  const status = getStatus();

  if (signature.deleted || !status) {
    return null;
  }

  return (
    <BhTooltip body={t(BhOwnSignatureStatusType[status].translateCode)}>
      <FontAwesomeIcon icon={BhOwnSignatureStatusType[status].icon} className={"h-4 py-1 px-1.5 " + BhOwnSignatureStatusType[status].iconColor} aria-hidden="true" />
    </BhTooltip>
  );
};

export default BhSignatureStatusLabelIconOnly;
