import React, { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveCurrentUserAsync, selectCurrentUser } from "@/app/store/userSlice";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { IUser } from "@/model/IUser";
import { useTranslation } from "react-i18next";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";

const ProfileUserInfoNameForm: FC = () => {
  const { t } = useTranslation();
  const user = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();

  const onUserSubmit = useCallback(
    (changedValue: IUser) => {
      if (!changedValue.firstName && !changedValue.lastName) {
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.ERROR,
            disappear: true,
            children: t("INPUT.NOT_EMPTY")
          })
        );
        return;
      }
      const changedObject = { ...user, ...changedValue };
      dispatch(saveCurrentUserAsync(changedObject));
    },
    [user]
  );

  return (
    <div className="mt-6 flex flex-row">
      <div className="w-1/2 whitespace-nowrap pr-8">
        <BhInputStackedLabel
          initialValue={user.firstName}
          property="firstName"
          onChangeCallback={onUserSubmit}
          onBlurAction={onUserSubmit}
          label={t("CONTACT.FIRST_NAME")}
          debounceLength={900}
          placeholderDisabled={true}
        />
      </div>
      <div className="w-1/2 whitespace-nowrap">
        <BhInputStackedLabel
          initialValue={user.lastName}
          property="lastName"
          onChangeCallback={onUserSubmit}
          onBlurAction={onUserSubmit}
          label={t("CONTACT.LAST_NAME")}
          debounceLength={900}
          placeholderDisabled={true}
        />
      </div>
    </div>
  );
};

export default ProfileUserInfoNameForm;
