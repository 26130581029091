import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useOuterClick } from "@/utilities/hooks/useOuterClick";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";

// FIGMA https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H/Bauhub-design-system?node-id=460%3A8702

interface Props {
  children: ReactNode;
  onOutsideClick?: Function;
  dropdownPosition?: BhDropdownPositionEnum;
  parentRef?: React.RefObject<HTMLDivElement>;
}

const BhFixedDropdownParent: FC<Props> = ({ children, onOutsideClick, dropdownPosition, parentRef }) => {
  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const outsideClickRef = useOuterClick(() => {
    onOutsideClick && onOutsideClick();
  });
  const getFixedPosition = () => {
    const parent = parentRef ? parentRef?.current : dropdownRef?.current?.parentElement;
    const dropdown = dropdownRef?.current;

    if (!parent || !dropdown) return undefined;

    const modal = parent.closest(".bauhub-modal");

    const modalLeft = modal?.getBoundingClientRect().left || 0;
    const modalTop = modal?.getBoundingClientRect().top || 0;

    const parentLeft = parent.getBoundingClientRect().left || 0;
    const parentTop = parent.getBoundingClientRect().top || 0;
    const parentWidth = parent.getBoundingClientRect().width || 0;
    const parentHeight = parent.getBoundingClientRect().height || 0;
    const dropdownWidth = dropdown.getBoundingClientRect().width || 0;
    const dropdownHeight = dropdown.getBoundingClientRect().height || 0;

    const defaultDropdownLeft = parentLeft - modalLeft;
    const defaultDropdownTop = parentTop + parentHeight - modalTop;

    const positionMap: Record<BhDropdownPositionEnum, any> = {
      [BhDropdownPositionEnum.BOTTOM_RIGHT]: { left: defaultDropdownLeft, top: defaultDropdownTop, width: dropdownWidth },
      [BhDropdownPositionEnum.BOTTOM_LEFT]: { left: defaultDropdownLeft - dropdownWidth + parentWidth, top: defaultDropdownTop, width: dropdownWidth },
      [BhDropdownPositionEnum.TOP_RIGHT]: { left: defaultDropdownLeft, top: defaultDropdownTop - parentHeight - dropdownHeight, width: dropdownWidth },
      [BhDropdownPositionEnum.TOP_LEFT]: { left: defaultDropdownLeft - dropdownWidth + parentWidth, top: defaultDropdownTop - parentHeight - dropdownHeight, width: dropdownWidth }
    };

    return dropdownPosition ? positionMap[dropdownPosition] : positionMap[BhDropdownPositionEnum.BOTTOM_RIGHT];
  };
  
  useEffect(() => {
    forceUpdate();
  }, [dropdownRef]);

  return (
    <div className="fixed z-40" ref={dropdownRef} style={getFixedPosition()}>
      <div ref={outsideClickRef}>{children}</div>
    </div>
  );
};

export default BhFixedDropdownParent;
