import React, { FC } from "react";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/pro-regular-svg-icons/faFile";
import { faFolder } from "@fortawesome/pro-solid-svg-icons/faFolder";

export interface Props {
  value: IFileEntity;
  withCheckbox?: boolean;
  onSelect?: Function;
  isSelected?: (value: any) => boolean;
}

const BhDropdownMenuFileListItem: FC<Props> = ({ value, withCheckbox, onSelect, isSelected }) => {
  return (
    <div className="flex flex-row items-center space-x-3 py-1.5 px-4" onClick={() => onSelect && onSelect(value)}>
      {withCheckbox && <input className="h-4 w-4 rounded focus:ring-0 focus:ring-offset-0" type="checkbox" checked={isSelected && isSelected(value)} onChange={() => {}} />}
      <FontAwesomeIcon icon={value.type === FileEntityType.DIR ? faFolder : faFile} />
      <div>{value && value.name}</div>
    </div>
  );
};

export default BhDropdownMenuFileListItem;
