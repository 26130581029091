import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUser, selectCurrentUserFetchStatus } from "@/app/store/userSlice";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhNavbarUserIconWithName from "@components/user/BhNavbarUserIconWithName";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { logOutOfCas } from "@/api/auth/authAPI";
import { useTranslation } from "react-i18next";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {}

const NavbarUserComponent: FC<Props> = () => {
  const { t } = useTranslation();
  const user = useAppSelector(selectCurrentUser);
  const userFetchStatus = useAppSelector(selectCurrentUserFetchStatus);
  const navigate = useNavigate();

  return (
    <>
      {userFetchStatus === BhStateStatusType.SUCCESS && (
        <BhDropdown
          button={<BhNavbarUserIconWithName user={user} />}
          menu={
            <BhDropdownMenu
              values={[
                {
                  text: t("USER.PROFILE.SETTINGS"),
                  function: () => {
                    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/user/info`);
                  }
                },
                {
                  text: t("GLOBAL.LOGOUT"),
                  function: () => {
                    logOutOfCas(ConfigSingleton.getInstance().getConfig().REACT_APP_CAS_URL + "/login?service=" + encodeURIComponent(ConfigSingleton.getInstance().getConfig().REACT_APP_CAS_CALLBACK_URL + ""));
                  }
                }
              ]}
              type={BhDropdownTypeEnum.STRING}
              textProperty="text"
              widthClass={"w-40"}
            />
          }
          position={BhDropdownPositionEnum.BOTTOM_RIGHT}
        />
      )}
    </>
  );
};

export default NavbarUserComponent;
