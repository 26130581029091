import React, { FC, useCallback, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { IFolderFileEntity } from "@/model/files/IFileEntity";
import { EntityId } from "@reduxjs/toolkit";
import { classNames } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";
import DirectorySelectionContainer from "../DirectorySelectionContainer";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import BhCheckboxWithText from "@components/checkboxes/BhCheckboxWithText";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faSquareCheck } from "@fortawesome/pro-regular-svg-icons/faSquareCheck";

interface Props {
  directoryIds: Array<EntityId>; // Folder ids that need to be opened at start (or root dir id(s))
  modalHeader: string;
  onModalSubmit: Function;
  onModalClose: Function;
  selectOnlyOne: boolean;
  preSelectedDirectoryIds?: Array<EntityId>;
  allowOnlyParentsToBeSelected?: boolean;
  isCopyModal?: boolean;
  isMoveModal?: boolean;
  haveToSelectAtLeastOne?: boolean;
  recentActionCallback?: Function;
}

const DirectorySelectionModal: FC<Props> = ({
  directoryIds,
  modalHeader,
  onModalClose,
  onModalSubmit,
  selectOnlyOne,
  preSelectedDirectoryIds,
  allowOnlyParentsToBeSelected,
  isCopyModal,
  isMoveModal,
  haveToSelectAtLeastOne,
  recentActionCallback
}) => {
  const { t } = useTranslation();
  const [fileRootDir, setFileRootDir] = useState({} as IFolderFileEntity);
  const [documentRootDir, setDocumentRootDir] = useState({} as IFolderFileEntity);
  const [modelRootDir, setModelRootDir] = useState({} as IFolderFileEntity);
  const [copyRevisions, setCopyRevisions] = useState(true);

  const handleSubmit = () => {
    let selectedDirectories = [] as Array<IFolderFileEntity>;
    findSelectedDirectoriesFromTree(fileRootDir, selectedDirectories);
    findSelectedDirectoriesFromTree(documentRootDir, selectedDirectories);
    findSelectedDirectoriesFromTree(modelRootDir, selectedDirectories);
    onModalSubmit(selectedDirectories, copyRevisions);
  };

  const containsSelectedFolders = (dir: IFolderFileEntity): boolean => {
    if (dir.selected) {
      return true;
    }
    if (!dir.subDirs || dir.subDirs.length === 0) {
      return false;
    }
    return dir.subDirs.some((subDir) => containsSelectedFolders(subDir));
  };

  const documentRootDirContainsSelectedFolders = useCallback((): boolean => {
    return containsSelectedFolders(documentRootDir);
  }, [documentRootDir]);

  const fileRootDirContainsSelectedFolders = useCallback((): boolean => {
    return containsSelectedFolders(fileRootDir);
  }, [fileRootDir]);

  const rootDirsContainSelectedFolders = documentRootDirContainsSelectedFolders() || fileRootDirContainsSelectedFolders();

  const closeModal = () => {
    onModalClose();
  };

  const findSelectedDirectoriesFromTree = (root: IFolderFileEntity, selectedDirectories: Array<IFolderFileEntity>) => {
    if (root.selected) {
      selectedDirectories.push(root);
    }
    if (root.subDirs && root.subDirs.length > 0) {
      root.subDirs.forEach((dir) => {
        findSelectedDirectoriesFromTree(dir, selectedDirectories);
      });
    }
  };

  const unselectAllDirectoriesInTree = (root: IFolderFileEntity) => {
    if (root.selected) {
      root.selected = false;
    }
    if (root.subDirs && root.subDirs.length > 0) {
      root.subDirs.forEach((dir) => {
        unselectAllDirectoriesInTree(dir);
      });
    }
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={closeModal}
      size="4xl"
      header={<h2>{modalHeader}</h2>}
      children={
        <BhScrollableBody>
          <div className="px-20">
            {recentActionCallback && (
              <div className="-mb-5 mt-5">
                <BhSecondaryButton icon={faSquareCheck} buttonProps={{ onClick: () => recentActionCallback() }}>
                  {t("MODAL.CHANGE_DIR.CHOOSE_RECENT")}
                </BhSecondaryButton>
              </div>
            )}
            <DirectorySelectionContainer
              directoryIds={directoryIds}
              selectOnlyOne={selectOnlyOne}
              preSelectedDirectoryIds={preSelectedDirectoryIds}
              allowOnlyParentsToBeSelected={allowOnlyParentsToBeSelected}
              fileRootDir={fileRootDir}
              setFileRootDir={setFileRootDir}
              documentRootDir={documentRootDir}
              setDocumentRootDir={setDocumentRootDir}
              modelRootDir={modelRootDir}
              setModelRootDir={setModelRootDir}
            />
          </div>
        </BhScrollableBody>
      }
      footer={
        <div className={classNames("flex w-full flex-row items-center", isCopyModal && "justify-between", !isCopyModal && "justify-end")}>
          {isCopyModal && <BhCheckboxWithText text={t("GLOBAL.COPY_REVISIONS")} isChecked={copyRevisions} property={""} onChange={() => setCopyRevisions(!copyRevisions)} />}
          <div className="flex flex-row items-center">
            <BhTextOnlyButton buttonProps={{ onClick: closeModal }}>{t("GLOBAL.CANCEL")}</BhTextOnlyButton>
            <BhPrimaryButton buttonProps={{ onClick: handleSubmit, disabled: haveToSelectAtLeastOne && !rootDirsContainSelectedFolders }}>
              {isCopyModal || isMoveModal ? (isCopyModal ? t("GLOBAL.COPY") : t("GLOBAL.MOVE")) : t("CONFIRM.CONFIRM")}
            </BhPrimaryButton>
          </div>
        </div>
      }
    />
  );
};

export default DirectorySelectionModal;
