import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  onChangeCallback?: Function;
  icon: IconDefinition;
  classes?: string;
  disabled?: boolean;
}

const PlusMinusFieldCounterButton: FC<Props> = ({ onChangeCallback, icon, classes, disabled }) => {
  return (
    <button
      className={classNames(classes, "min-w-12 flex h-12 w-12 flex-row justify-center rounded border disabled:cursor-default")}
      onClick={() => onChangeCallback && onChangeCallback()}
      disabled={disabled}
    >
      <div className="flex h-full flex-col justify-center">
        <FontAwesomeIcon icon={icon} />
      </div>
    </button>
  );
};

export default PlusMinusFieldCounterButton;
