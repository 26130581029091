import React, { Dispatch, FC, useEffect, useState } from "react";
// @ts-ignore
import { StoreyViewsPlugin, Viewer } from "@xeokit/xeokit-sdk";
import { LoadedObjectInfo } from "@/views/home/project/detail/xeokit/XeokitWebViewer";
import { fetchBimFloorplans } from "@/api/fileAPI";
import { IfcConversionStatus } from "@/model/files/IIfcConversionStatusDTO";
import { EntityId } from "@reduxjs/toolkit";
import XeokitFloorPlanMenu from "@/views/home/project/detail/xeokit/menus/XeokitFloorPlanMenu";
import { IBimFloorplansDTO } from "@/model/files/IManifestDTO";

interface Props {
  isOpen: boolean;
  isMobile?: boolean;
  projectId: EntityId;
  viewer: Viewer;
  loadedStoreyElevations: Array<LoadedObjectInfo>;
  storeyViewsPluginObject?: StoreyViewsPlugin;
  setFirstPersonActive: Function;
  setToolActive: Dispatch<boolean>;
}

const XeokitFloorPlanMenuContainer: FC<Props> = ({ isOpen, isMobile, projectId, viewer, loadedStoreyElevations, storeyViewsPluginObject, setFirstPersonActive, setToolActive }) => {
  const [firstOpen, setFirstOpen] = useState<boolean>(false);
  const [floorplansDTO, setFloorplansDTO] = useState<IBimFloorplansDTO>();

  useEffect(() => {
    if (isOpen) {
      setFirstOpen(true);
    }
    if (!isOpen) {
      viewer.cameraControl.constrainVertical = false;
    }
  }, [isOpen]);

  useEffect(() => {
    if (firstOpen) {
      fetchBimFloorplans(projectId).then((floorplansDTO) => {
        setFloorplansDTO(floorplansDTO);
      });
    }
  }, [firstOpen]);

  const floorplanConversionSuccess = floorplansDTO?.conversionStatus === IfcConversionStatus.SUCCESS;

  if (!firstOpen || !floorplansDTO) return null;

  if (floorplanConversionSuccess) {
    return (
      <div className={isOpen ? "" : "hidden"}>
        <XeokitFloorPlanMenu
          isMobile={isMobile}
          projectId={projectId}
          viewer={viewer}
          loadedStoreyElevations={loadedStoreyElevations}
          storeyViewsPluginObject={storeyViewsPluginObject}
          setFirstPersonActive={setFirstPersonActive}
          setToolActive={setToolActive}
          floorplansDTO={floorplansDTO}
          setFirstOpen={setFirstOpen}
        />
      </div>
    );
  } else {
    if (isOpen) {
      return (
        <XeokitFloorPlanMenu
          isMobile={isMobile}
          projectId={projectId}
          viewer={viewer}
          loadedStoreyElevations={loadedStoreyElevations}
          storeyViewsPluginObject={storeyViewsPluginObject}
          setFirstPersonActive={setFirstPersonActive}
          setToolActive={setToolActive}
          floorplansDTO={floorplansDTO}
          setFirstOpen={setFirstOpen}
        />
      );
    } else {
      return null;
    }
  }
};

export default XeokitFloorPlanMenuContainer;
