import React, { FC, startTransition, useCallback, useEffect, useMemo, useRef, useState } from "react";
import BhChipWithUser from "@components/chips/BhChipWithUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { IUser } from "@/model/IUser";
import BhInputLabel from "@components/input/BhInputLabel";
import BhTagWithAction from "@components/tags/BhTagWithAction";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import BhFixedDropdownParent from "@components/dropdown/BhFixedDropdownParent";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { classNames } from "@/utilities/jsUtilities";
import { debounce } from "lodash";

interface Props {
  label?: string;
  currentValues: Array<IUser>;
  dropdownValues: Array<IUser>;
  onRemove?: Function;
  onSelect?: Function;
  inputRef?: React.RefObject<HTMLInputElement>;
  fetchValues?: Function;
  disabled?: boolean;
  disabledUserIds?: Array<EntityId>;
  onNewValueSelect?: Function;
}

const BhInputWithChips: FC<Props> = ({ label, currentValues, dropdownValues, onRemove, onSelect, inputRef, fetchValues, disabled, disabledUserIds, onNewValueSelect }) => {
  const { t } = useTranslation();
  const containedInputRef = useRef<HTMLInputElement>(null);
  const [dropdownUsers, setDropdownUsers] = useState([] as Array<IUser>);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    setDropdownUsers(getFilteredDropdownValues(dropdownValues));
  }, [dropdownValues, currentValues]);

  const getFilteredDropdownValues = (users: Array<IUser>) => {
    return users.filter((user) => {
      return !currentValues.some((val) => {
        return user.id === val.id;
      });
    });
  };

  const onChange = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault();
      startTransition(() => {
        const inputValue = e.target.value;
        setIsDropdownOpen(inputValue.length > 0);
        if (fetchValues) {
          fetchValues &&
            fetchValues(inputValue).then((results: Array<IUser>) => {
              const availableUsers = getFilteredDropdownValues(results);
              const newUserDropdownValue = { id: -1, firstName: t("GLOBAL.ADD").toUpperCase() + ' "' + inputValue + '"', lastName: "" } as IUser;
              const displayNewUserDropdownValue =
                onNewValueSelect &&
                inputValue.length > 0 &&
                !availableUsers.some((user) => (user.firstName + " " + user.lastName).toLowerCase() === inputValue.toLowerCase()) &&
                !currentValues.some((user) => (user.firstName + " " + user.lastName).toLowerCase() === inputValue.toLowerCase());

              setDropdownUsers(displayNewUserDropdownValue ? [newUserDropdownValue, ...availableUsers] : availableUsers);
            });
        } else {
          setDropdownUsers(
            getFilteredDropdownValues(dropdownValues).filter((user) => {
              return (user.firstName + " " + user.lastName).toLowerCase().includes(inputValue.toLowerCase());
            })
          );
        }
      });
    },
    [currentValues]
  );

  const debouncedOnChangeHandler = useMemo(() => debounce(onChange, 300), [fetchValues, onNewValueSelect, currentValues]);

  const onValueSelect = (value: any) => {
    let inputRefPropOrContained = inputRef || containedInputRef;
    if (inputRefPropOrContained && inputRefPropOrContained.current) {
      if (onNewValueSelect && value.id === -1) {
        onNewValueSelect(inputRefPropOrContained.current.value);
        inputRefPropOrContained.current.value = "";
        setIsDropdownOpen(false);
        return;
      }
      inputRefPropOrContained.current.value = "";
    }
    setIsDropdownOpen(false);

    onSelect && onSelect(value);
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "," && onNewValueSelect) {
      e.preventDefault();
      let inputRefPropOrContained = inputRef || containedInputRef;
      if (inputRefPropOrContained && inputRefPropOrContained.current && inputRefPropOrContained.current.value.length > 0) {
        onNewValueSelect(inputRefPropOrContained.current.value);
        inputRefPropOrContained.current.value = "";
        setIsDropdownOpen(false);
      }
    }
  };

  return (
    <div className="w-full">
      {label && <BhInputLabel>{label}</BhInputLabel>}
      <div className="bh-border-pigeon-40 min-h-42px bh-bg-white flex flex-row justify-between whitespace-nowrap rounded border p-1">
        <div className="flex w-full flex-row flex-wrap items-center overflow-y-auto overflow-x-hidden">
          {currentValues &&
            currentValues.length > 0 &&
            currentValues.map((user, index) => {
              if (user && user.id) {
                return <BhChipWithUser user={user} key={index} onRemove={onRemove} disabled={disabled || (disabledUserIds && disabledUserIds.filter((id) => id === user.id).length > 0)} />;
              } else {
                return <BhTagWithAction icon={faXmark} tagText={user.username} onIconClick={onRemove} key={index} disabled={disabled} />;
              }
            })}
          <input
            ref={inputRef || containedInputRef}
            className="text-14px min-w-50px disabled:bh-bg-white flex-1 leading-5 outline-0"
            onChange={fetchValues ? debouncedOnChangeHandler : onChange}
            onKeyDown={(e) => onKeyDown(e)}
            disabled={disabled}
          />
        </div>
        {!disabled && (
          <div className="flex w-7 flex-col items-start pt-2">
            <FontAwesomeIcon icon={faCaretDown} className="mr-3 cursor-pointer pl-3" onClick={() => setIsDropdownOpen(!isDropdownOpen)} />
          </div>
        )}
      </div>
      {isDropdownOpen && dropdownUsers.length > 0 && (
        <div onMouseDown={(e) => e.preventDefault()}>
          <BhFixedDropdownParent>
            <BhDropdownMenu
              type={BhDropdownTypeEnum.USER}
              values={dropdownUsers}
              onSelect={onValueSelect}
              numberOfItemsDisplayed={10}
              customDropdown={true}
              widthClass={classNames(onNewValueSelect ? "w-fit" : "w-full")}
            />
          </BhFixedDropdownParent>
        </div>
      )}
    </div>
  );
};

export default React.memo(BhInputWithChips);
