import React, { FC } from "react";
import FormAttachmentsFieldPreview from "@components/form/fields/attachmentsField/FormAttachmentsFieldPreview";
import FormAttachmentsFieldContent from "@components/form/fields/attachmentsField/FormAttachmentsFieldContent";

interface Props {
  field: any;
  disabled: boolean;
  isPreview?: boolean;
  saveCallback?: Function;
}

const FormAttachmentsField: FC<Props> = ({ field, disabled, isPreview, saveCallback }) => {
  return (
    <div>
      {isPreview && <FormAttachmentsFieldPreview field={field} />}
      {!isPreview && <FormAttachmentsFieldContent field={field} saveCallback={saveCallback} disabled={disabled} />}
    </div>
  );
};

export default FormAttachmentsField;
