import { EntityId } from "@reduxjs/toolkit";
import React, { useState } from "react";
import XeokitWebViewer from "@/views/home/project/detail/xeokit/XeokitWebViewer";

// Teeb state katki
// const XeokitWebViewer = lazy(() => import("./XeokitWebViewer"));

interface Props {
  folderFileEntityId: EntityId;
}

const XeokitFullContainer = React.memo<Props>(({ folderFileEntityId }) => {
  const [xeokitViewerKey, setXeokitViewerKey] = useState(1);

  const resetViewer = () => {
    setXeokitViewerKey(Math.random());
  };

  return <XeokitWebViewer folderFileEntityId={folderFileEntityId} resetViewer={resetViewer} key={xeokitViewerKey} />;
});

export default XeokitFullContainer;
