import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { IToastFlag, selectToastFlags, toastFlagRemoved } from "@/app/store/globalSlice";
import BhOverlayFlag from "@components/flags/BhOverlayFlag";

const ToastBucket: FC = () => {
  const flags = useAppSelector(selectToastFlags);
  const dispatch = useAppDispatch();

  if (flags.length < 1) {
    return null;
  }

  const removeCallback = (flag: IToastFlag) => {
    dispatch(toastFlagRemoved(flag));
  };

  return (
    <div className="z-100 fixed bottom-10 right-10 flex flex-col space-y-4">
      {flags.map((flag) => (
        <BhOverlayFlag key={flag.id} flag={flag} removeCallback={() => removeCallback(flag)}>
          {flag.children}
        </BhOverlayFlag>
      ))}
    </div>
  );
};

export default ToastBucket;
