import { ISignInvite } from "@/model/ISignInvite";
import { bauhubGet, bauhubPost, bauhubPublicGet } from "@/api/bauhubAPI";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import { EntityId } from "@reduxjs/toolkit";
import { IContainer } from "@/model/container/IContainer";
import { IPublicContainer } from "@/model/container/IPublicContainer";

export function fetchUserUnsignedSignInvites(): Promise<Array<ISignInvite>> {
  return bauhubGet("/user/sign/invites/unsigned");
}

export function fetchAllUnsignedSignInvitesInProject(projectId: EntityId): Promise<Array<{ container: IContainer; signInvites: Array<ISignInvite> }>> {
  return bauhubGet("/project/" + projectId + "/container/unsigned");
}

export function fetchUserInvites(projectId: EntityId, signed?: boolean, declined?: boolean): Promise<Array<ISignInvite>> {
  return bauhubGet("/project/" + projectId + "/user/invites", { signed: signed, declined: declined });
}

export function sendAllSignInvitesForContainer(containerUuid: string): Promise<Array<ISignatureHolder>> {
  return bauhubPost("/project/container/" + containerUuid + "/send/invites", {});
}

export function deleteSignInvite(signature: ISignatureHolder): Promise<ISignInvite> {
  return bauhubPost("/project/container/invite/delete", signature);
}

export function deleteSignature(fileEntityId: EntityId, signature: ISignatureHolder): Promise<ISignatureHolder> {
  return bauhubPost("/project/container/" + fileEntityId + "/remove/signature", signature);
}

export function saveSignInvite(signInvite: ISignInvite): Promise<ISignatureHolder> {
  return bauhubPost("/project/container/invite", signInvite);
}

// Endpoint expects ISignInvites, but we want to move to ISignatureHolders in FE2, so using a hack for now
export function saveQueueEnabledSignInvites(signatureHolders: Array<ISignatureHolder>): Promise<Array<ISignatureHolder>> {
  return bauhubPost("/project/container/invites/queue", signatureHolders);
}

export function swapSignInvite(containerUuid: string, swapUsername: string, withUsername: string): Promise<Array<ISignatureHolder>> {
  return bauhubPost("/v2/project/container/" + containerUuid + "/user/" + swapUsername + "/user/" + withUsername + "/swap", {});
}

export function fetchPublicSignInviteByUuid(containerUuid: string, signInviteUuid: string, projectId: EntityId): Promise<ISignInvite> {
  return bauhubPublicGet("/container/" + containerUuid + "/invite/" + signInviteUuid + "/" + projectId);
}

export function fetchPublicContainerByUuid(containerUuid: string, signInviteUuid: string, projectId: EntityId): Promise<IPublicContainer> {
  return bauhubPublicGet("/container/" + containerUuid + "/" + signInviteUuid + "/" + projectId);
}
