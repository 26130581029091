import React, { FC, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft } from "@fortawesome/pro-regular-svg-icons/faClockRotateLeft";
import { IProject } from "@/model/IProject";
import { IObjectChange } from "@/model/IObjectChange";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons/faSpinner";
import BOCompanyProjectLogsTable from "@components/backoffice/company/projects/BOCompanyProjectLogsTable";
import BhModalFooter from "@components/modal/BhModalFooter";
import { boFetchProjectHistory } from "@/api/backoffice/boCompaniesAPI";

interface Props {
  project: IProject;
  setIsShown: (value: boolean) => void;
}

const BOCompanyProjectLogsModal: FC<Props> = ({ setIsShown, project }) => {
  const [projectLogs, setProjectLogs] = useState<Array<IObjectChange>>();
  const [loading, setLoading] = useState(true);

  const projectLogsExist = projectLogs && projectLogs?.length > 0;

  useEffect(() => {
    boFetchProjectHistory(project.id).then((response: Array<IObjectChange>) => {
      setProjectLogs(response);
      setLoading(false);
    });
  }, []);

  return (
    <BhModal
      size="5xl"
      setIsShown={setIsShown}
      header={
        <div className="flex items-center gap-4">
          <FontAwesomeIcon icon={faClockRotateLeft} size="lg" />
          <h2>
            <span className="bh-text-deep-ocean-60">Project logs – </span>
            {project.name}
          </h2>
        </div>
      }
      footer={<BhModalFooter onCancel={() => setIsShown(false)} cancelButtonText="Close" />}
    >
      <div className="h-full overflow-auto">
        <div className="py-10 px-8">
          {loading && (
            <div className="text-center">
              <FontAwesomeIcon icon={faSpinner} spin aria-hidden="true" size="lg" />
            </div>
          )}
          {!loading && projectLogsExist && <BOCompanyProjectLogsTable logs={projectLogs} />}
          {!projectLogsExist && !loading && <p className="text-center">No data</p>}
        </div>
      </div>
    </BhModal>
  );
};

export default BOCompanyProjectLogsModal;
