import React, { FC, useState } from "react";
import BhSearchInput from "@components/input/BhSearchInput";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import PartyTemplateUsersList from "@/views/home/company/detail/companySettingsPartyTemplates/PartyTemplateUsersList";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import PartyTemplateAddNewUserModal from "@/views/home/company/detail/companySettingsPartyTemplates/PartyTemplateAddNewUserModal";

interface Props {
  companyId: EntityId;
  predefinedPartyId: EntityId;
}

const PartyTemplateUsersContainer: FC<Props> = ({ companyId, predefinedPartyId }) => {
  const { t } = useTranslation();
  const [isAddNewModalOpen, setIsAddNewModalOpen] = useState<boolean>(false);
  const [usersFilter, setUsersFilter] = useState<string>("");

  return (
    <div>
      <div className="mt-6 mb-4 flex items-center justify-between">
        <div className="w-1/3">
          <BhSearchInput property="name" placeholder={t("COMPANY.SETTINGS.USERS.SEARCH_BY_USER") as string} onChangeCallback={(user: { name: string }) => setUsersFilter(user.name.toLowerCase())} />
        </div>
        <BhSecondaryButton icon={faPlus} buttonProps={{ onClick: () => setIsAddNewModalOpen(true) }}>
          {t("WORKGROUP.ADD_USER")}
        </BhSecondaryButton>
      </div>
      <PartyTemplateUsersList companyId={companyId} predefinedPartyId={predefinedPartyId} usersFilter={usersFilter} />

      {isAddNewModalOpen && <PartyTemplateAddNewUserModal setIsAddNewModalOpen={setIsAddNewModalOpen} companyId={companyId} predefinedPartyId={predefinedPartyId} />}
    </div>
  );
};

export default PartyTemplateUsersContainer;
