import React, { Dispatch, FC, SetStateAction } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useOuterClick } from "@/utilities/hooks/useOuterClick";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import BhInputLabel from "@components/input/BhInputLabel";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  disabled?: boolean;
  value: string;
  label?: string;
  dropdown: React.ReactNode;
  dropdownOpen: boolean;
  setDropdownOpen: Dispatch<SetStateAction<boolean>>;
}

const FormBaseInputWithCustomDropdown: FC<Props> = ({ disabled, value, label, dropdown, dropdownOpen, setDropdownOpen }) => {
  const outsideClickRef = useOuterClick(() => {
    setDropdownOpen(false);
  });

  return (
    <div className="w-full">
      {label && <BhInputLabel>{label}</BhInputLabel>}
      <div
        className={classNames(
          "l-h-20 relative flex w-full flex-row items-center justify-between rounded border px-3",
          value ? "py-7px" : "py-[8px]",
          disabled ? "bh-bg-smoke bh-border-smoke bh-text-deep-ocean-40" : "hover:bh-border-pigeon-70 bh-border-pigeon-40 hover:cursor-pointer "
        )}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        ref={outsideClickRef}
      >
        <div>{value}</div>
        {!disabled && <FontAwesomeIcon icon={faAngleDown} className="mt-1" />}
        {!disabled && dropdownOpen && <div className="absolute bottom-0 right-0">{dropdown}</div>}
      </div>
    </div>
  );
};

export default FormBaseInputWithCustomDropdown;
