import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faGear } from "@fortawesome/pro-regular-svg-icons/faGear";
import { Trans, useTranslation } from "react-i18next";
import FormBaseInfoPdfModal from "@components/form/base/body/formBaseInfo/FormBaseInfoPdfModal";
import FormBaseInfoRow from "@components/form/base/body/formBaseInfo/FormBaseInfoRow";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyFormBaseById } from "@/app/store/form/formBasesSlice";

interface Props {
  companyId: EntityId;
  formBaseId: EntityId;
}

const FormBaseInfoPdfRow: FC<Props> = ({ companyId, formBaseId }) => {
  const { t } = useTranslation();
  const [showPdfModal, setShowPdfModal] = React.useState(false);
  const formBase = useAppSelector((state) => selectCompanyFormBaseById(state, formBaseId));

  if (!formBase) return null;

  return (
    <>
      <FormBaseInfoRow
        title={"PDF"}
        helperText={t("FORMBUILDER.FORM.BASE.PDF_DESCRIPTION")}
        children={
          <div className="w-full">
            <BhSecondaryButton icon={faGear} buttonProps={{ classes: "-ml-0.5", onClick: () => setShowPdfModal(true) }}>
              <Trans>FORMBUILDER.FORM.BASE.PDF_SETTINGS</Trans>
            </BhSecondaryButton>
          </div>
        }
      />
      {showPdfModal && (
        <FormBaseInfoPdfModal
          isShown={showPdfModal}
          setIsShown={setShowPdfModal}
          companyId={companyId}
          formBaseId={formBaseId}
          initialTitle={formBase.bauhubFormBase ? t("FORMS." + formBase.type) : formBase.type}
        />
      )}
    </>
  );
};

export default FormBaseInfoPdfRow;
