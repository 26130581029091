import { Authority } from "@/model/IUserAuthority";

export interface IRoleAuthName {
  name: string;
  documents: Authority;
  files: Authority;
  models: Authority;
}

export const ROLES: Array<IRoleAuthName> = [
  {
    name: "PARTY.READ",
    documents: Authority.READ,
    files: Authority.READ,
    models: Authority.READ
  },
  {
    name: "PARTY.WRITE",
    documents: Authority.WRITE,
    files: Authority.WRITE,
    models: Authority.WRITE
  },
  {
    name: "PARTY.ADMIN",
    documents: Authority.ADMIN,
    files: Authority.ADMIN,
    models: Authority.ADMIN
  }
];
