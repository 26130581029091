import React, { FC, useEffect, useRef, useState } from "react";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import BhInputSimple from "@components/input/BhInputSimple";
import { useOuterClick } from "@/utilities/hooks/useOuterClick";
import BhFixedDropdownParent from "@components/dropdown/BhFixedDropdownParent";
import BhUserDropdownMenu from "@components/dropdown/BhUserDropdownMenu";
import { isValidEmail } from "@/utilities/jsUtilities";
import { sortByFullNameAndEmail } from "@/utilities/sortUtilities";

interface Props {
  values: Array<ISimpleValidUserAuthority>;
  onSelect: Function;
  showValueCondition: Function;
  placeholder?: string;
}

const BhSearchInputWithUserDropdownAndCustomEmailOption: FC<Props> = ({ values, onSelect, showValueCondition, placeholder }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const outsideClickRef = useOuterClick(() => {
    setIsDropdownOpen(false);
  });

  const onInputChange = (value: { query: string }) => {
    setSearchQuery(value.query);
    if (!isDropdownOpen) setIsDropdownOpen(true);
  };

  const onSelectDropdownValue = (selectedUser: ISimpleValidUserAuthority) => {
    onSelect && onSelect(selectedUser);
    setSearchQuery("");
    if (inputRef?.current) inputRef.current.value = "";
    setIsDropdownOpen(false);
  };

  const queryFilter = (value: ISimpleValidUserAuthority) => {
    const fullName = value?.firstName + " " + value?.lastName;
    const nameFilter = fullName.toLowerCase().replace(/\s+/g, "").includes(searchQuery.toLowerCase().replace(/\s+/g, ""));
    const userNameFilter = value.username?.toLowerCase().replace(/\s+/g, "").includes(searchQuery.toLowerCase().replace(/\s+/g, ""));
    return nameFilter || userNameFilter;
  };

  const isEmailNonUser = (email: string) => {
    return !values.some((u) => typeof u === "object" && "username" in u && u.username === email);
  };

  const isCustomEmailAdd = searchQuery !== "" && isValidEmail(searchQuery) && isEmailNonUser(searchQuery);

  const filteredValues = [
    ...values
      .filter((value) => {
        const isQueryFilter = searchQuery === "" ? true : queryFilter(value);
        const isShowValueCondition = showValueCondition ? showValueCondition(value) : true;
        return isQueryFilter && isShowValueCondition;
      })
      .sort((a, b) => sortByFullNameAndEmail(a, b)),
    isCustomEmailAdd && ({ username: searchQuery, userExists: false } as ISimpleValidUserAuthority)
  ].filter(Boolean) as Array<ISimpleValidUserAuthority>;

  const onKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      filteredValues[selectedIndex] && onSelectDropdownValue(filteredValues[selectedIndex]);
    }
    if (event.key === "ArrowDown") {
      !isDropdownOpen && setIsDropdownOpen(true);
      selectedIndex + 1 < filteredValues.length && setSelectedIndex(selectedIndex + 1);
    }
    if (event.key === "ArrowUp") {
      !isDropdownOpen && setIsDropdownOpen(true);
      selectedIndex - 1 >= 0 && setSelectedIndex(selectedIndex - 1);
    }
  };

  useEffect(() => {
    isDropdownOpen && setSelectedIndex(0);
  }, [isDropdownOpen]);

  return (
    <div className="relative w-full" ref={outsideClickRef}>
      <div onKeyDown={onKeyDown} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <BhInputSimple initialValue={searchQuery} property={"query"} placeholder={placeholder} onChangeCallback={onInputChange} inputRef={inputRef} />
      </div>

      {isDropdownOpen && (filteredValues.length > 0 || isCustomEmailAdd) && (
        <BhFixedDropdownParent>
          <BhUserDropdownMenu values={filteredValues} onSelect={onSelectDropdownValue} customEmail={isCustomEmailAdd ? searchQuery : undefined} selectedIndex={selectedIndex} />
        </BhFixedDropdownParent>
      )}
    </div>
  );
};

export default BhSearchInputWithUserDropdownAndCustomEmailOption;
