import React from "react";
import { useAppSelector } from "@/app/hooks";
import { selectIsCustomCompany } from "@/app/store/project/projectSlice";
import ProjectDetailsFormCustomCompany from "@/views/home/project/detail/projectSettings/customClient/ProjectDetailsFormCustomClient";
import ProjectDetailsFormBauhub from "@/views/home/project/detail/projectSettings/ProjectDetailsFormBauhub";

const ProjectDetailsFormContainer = () => {
  const isCustomCompany = useAppSelector(selectIsCustomCompany);

  return (
    <>
      {isCustomCompany && <ProjectDetailsFormCustomCompany />}
      {!isCustomCompany && <ProjectDetailsFormBauhub />}
    </>
  );
};

export default ProjectDetailsFormContainer;
