import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boFetchAllCompaniesAsync, boFetchMonthlyInvoiceOverviewAsync, boSelectAllMonthlyInvoiceOverviewRowsFilteredAndSorted } from "@/app/store/backofficeSlice";
import BOHeader from "@/views/home/backoffice/views/BOHeader";
import { faReceipt } from "@fortawesome/pro-solid-svg-icons/faReceipt";
import { faCheck, faPlus, faXmark } from "@fortawesome/pro-solid-svg-icons";
import BhNavigationDropdown from "@components/dropdown/BhNavigationDropdown";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import BOInvoicesFilter from "@components/backoffice/invoices/BOInvoicesFilter";
import BOInvoicesTable from "@components/backoffice/invoices/BOInvoicesTable";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BOInvoiceSendKmdInfModal from "@components/backoffice/invoices/BOInvoiceSendKmdInfModal";
import BOInvoiceSendReportModal from "@components/backoffice/invoices/BOInvoiceSendReportModal";
import BONewInvoiceModal from "@components/backoffice/invoices/BONewInvoiceModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ITabCardType } from "@components/backoffice/company/projects/BOCompanyProjectsTabCards";
import BOInvoiceTabCard from "@components/backoffice/invoices/BOInvoiceTabCard";
import { BhInvoiceOrderType } from "@/model/backoffice/IBoFilter";

const BOMonthlyInvoiceOverview: FC = () => {
  const { month } = useParams();
  const monthlyInvoiceOverview = useAppSelector(boSelectAllMonthlyInvoiceOverviewRowsFilteredAndSorted);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [kmdInfModalOpen, setKmdInfModalOpen] = useState(false);
  const [sendReportModalOpen, setSendReportModalOpen] = useState(false);
  const [newInvoiceModalOpen, setNewInvoiceModalOpen] = useState(false);

  useEffect(() => {
    dispatch(boFetchAllCompaniesAsync());
  }, []);

  useEffect(() => {
    if (month) dispatch(boFetchMonthlyInvoiceOverviewAsync(month));
  }, [month]);

  const onNextMonthClick = () => {
    if (month) {
      let currentMonth = Number(month.split("-")[1]);
      let currentYear = Number(month.split("-")[0]);
      if (currentMonth > 0 && currentMonth < 12) {
        currentMonth += 1;
      } else {
        currentMonth = 1;
        currentYear += 1;
      }
      const newMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth;
      const newDate = `${currentYear}-${newMonth}`;
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "/bo/invoices/" + newDate);
    }
  };

  const onPreviousMonthClick = () => {
    if (month) {
      let currentMonth = Number(month.split("-")[1]);
      let currentYear = Number(month.split("-")[0]);
      if (currentMonth > 1 && currentMonth <= 12) {
        currentMonth -= 1;
      } else {
        currentMonth = 12;
        currentYear -= 1;
      }
      const newMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth;
      const newDate = `${currentYear}-${newMonth}`;
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + "/bo/invoices/" + newDate);
    }
  };
  const monthlyTotalSum = monthlyInvoiceOverview.rows?.reduce((acc, row) => (row.salesInvoice.credited ? acc : acc + row.salesInvoice.sumWithoutVat), 0) || (0).toFixed(2);
  const monthlyPaidSum = monthlyInvoiceOverview.rows?.reduce((acc, row) => (row.salesInvoice.paid ? acc + row.salesInvoice.sumWithoutVat : acc), 0).toFixed(2) || 0;
  const monthlyDueSum = monthlyInvoiceOverview.rows?.reduce((acc, row) => (row.salesInvoice.paid || row.salesInvoice.credited ? acc : acc + row.salesInvoice.sumWithoutVat), 0).toFixed(2) || 0;

  const monthlyProjects = monthlyInvoiceOverview.rows?.reduce(
    (acc, row) => (row.salesInvoice.type === BhInvoiceOrderType.MONTH || row.salesInvoice.type === BhInvoiceOrderType.AGGREGATE ? acc + row.salesInvoice.projectsOnInvoice : acc),
    0
  );
  const annualProjects = monthlyInvoiceOverview.rows?.reduce(
    (acc, row) => (row.salesInvoice.type === BhInvoiceOrderType.YEAR || row.salesInvoice.type === BhInvoiceOrderType.UPGRADE ? acc + row.salesInvoice.projectsOnInvoice : acc),
    0
  );
  const manualProjects = monthlyInvoiceOverview.rows?.reduce((acc, row) => (row.salesInvoice.type === BhInvoiceOrderType.CUSTOM ? acc + row.salesInvoice.projectsOnInvoice : acc), 0);

  const noSalesInvoices = (monthlyInvoiceOverview.rows && monthlyInvoiceOverview.rows?.length === 0) || !monthlyInvoiceOverview.rows;
  const noCreditedRows = (monthlyInvoiceOverview.creditedRows && monthlyInvoiceOverview.creditedRows?.length === 0) || !monthlyInvoiceOverview.creditedRows;

  const invoiceStatusTabCards = [
    {
      type: ITabCardType.DEFAULT,
      title: "Submitted",
      value: `${monthlyTotalSum}€`,
      icon: <FontAwesomeIcon icon={faReceipt} className="bh-text-deep-ocean" />
    },
    {
      type: ITabCardType.SUCCESS,
      title: "Paid",
      value: `${monthlyPaidSum}€`,
      icon: <FontAwesomeIcon icon={faCheck} className="bh-text-success-green-110" />
    },
    {
      type: ITabCardType.ERROR,
      title: "Unpaid",
      value: `${monthlyDueSum}€`,
      icon: <FontAwesomeIcon icon={faXmark} className="bh-text-error-red" />
    }
  ];

  const projectsTabCards = [
    {
      type: ITabCardType.DEFAULT,
      title: "Monthly projects",
      value: monthlyProjects
    },
    {
      type: ITabCardType.DEFAULT,
      title: "Annual projects",
      value: annualProjects
    },
    {
      type: ITabCardType.DEFAULT,
      title: "Manual invoices",
      value: manualProjects
    }
  ];

  return (
    <div className="w-full px-16 pb-40 sm:px-4">
      <div className="flex items-end justify-between">
        <BOHeader
          customHeader={
            <div className="flex items-center gap-4">
              <h1>Monthly sales</h1>
              {month && (
                <div className="-mb-1">
                  <BhNavigationDropdown value={month} onLeftClick={onPreviousMonthClick} onRightClick={onNextMonthClick} />
                </div>
              )}
            </div>
          }
        />
        <div>
          <BhSecondaryButton icon={faPlus} buttonProps={{ onClick: () => setNewInvoiceModalOpen(true) }}>
            New invoice
          </BhSecondaryButton>
          <BhPrimaryButton buttonProps={{ onClick: () => setSendReportModalOpen(true) }}>Report</BhPrimaryButton>
          <BhPrimaryButton buttonProps={{ onClick: () => setKmdInfModalOpen(true) }}>KMD INF A</BhPrimaryButton>
        </div>
      </div>
      <div className="mt-6 flex w-10/12 gap-10">
        <div className="flex w-full gap-1">
          {invoiceStatusTabCards.map((card) => (
            <BOInvoiceTabCard type={card.type} icon={card.icon} title={card.title} value={card.value} key={card.title} />
          ))}
        </div>
        <div className="flex w-full gap-1">
          {projectsTabCards.map((card) => (
            <BOInvoiceTabCard type={card.type} title={card.title} value={card.value} key={card.title} />
          ))}
        </div>
      </div>
      <div className="mt-6 flex flex-wrap items-center justify-between">
        <BOInvoicesFilter />
      </div>
      <div className="my-4 w-full">
        <h2>Invoices</h2>
        {noSalesInvoices && <p className="bh-text-deep-ocean-60 text-lg">No invoices</p>}
        <div className="w-full">{!noSalesInvoices && monthlyInvoiceOverview && <BOInvoicesTable invoiceRows={monthlyInvoiceOverview.rows} />}</div>
      </div>
      <div className="mt-10 mb-4 w-full">
        <h2>Credit invoices</h2>
        {noCreditedRows && <p className="bh-text-deep-ocean-60 text-lg">No invoices</p>}

        <div className="w-full">{!noCreditedRows && monthlyInvoiceOverview && <BOInvoicesTable invoiceRows={monthlyInvoiceOverview.creditedRows} />}</div>
      </div>
      {newInvoiceModalOpen && <BONewInvoiceModal setIsShown={setNewInvoiceModalOpen} />}
      {kmdInfModalOpen && <BOInvoiceSendKmdInfModal setIsShown={() => setKmdInfModalOpen(!kmdInfModalOpen)} currentMonth={month as string} />}
      {sendReportModalOpen && <BOInvoiceSendReportModal setIsShown={() => setSendReportModalOpen(!sendReportModalOpen)} currentMonth={month as string} />}
    </div>
  );
};

export default BOMonthlyInvoiceOverview;
