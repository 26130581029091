import React, { FC } from "react";
import { IUser } from "@/model/IUser";
import { IFileEntity } from "@/model/files/IFileEntity";
import { IDropdownItem } from "@/model/IDropdownItem";
import BhCombobox from "@components/input/BhCombobox";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { IProject } from "@/model/IProject";

interface Props {
  initialValue?: string;
  placeholder?: string;
  property: string;
  values: Array<any | IUser | IFileEntity | IDropdownItem | IProject>;
  onSelect: Function;
  showValueCondition?: Function;
  disabled?: boolean;
  label: string;
  labelHelper?: string;
  customType?: BhDropdownTypeEnum;
  // enable using the value from the input as a new value
  enableNewValue?: boolean;
  // callback for updating the value of input in upper component
  queryValueCallback?: Function;
  // keep value visible after selecting a value from list
  keepValueVisibleAfterSelect?: boolean;
  // which property to use of the objects that are in the list
  visibleValueProperty?: string;
  canAddCustomEmail?: boolean;
  showUsernameWithUserValues?: boolean;
  required?: boolean;
}

const BhComboboxStackedLabel: FC<Props> = ({
  property,
  initialValue,
  values,
  showValueCondition,
  disabled,
  onSelect,
  label,
  labelHelper,
  placeholder,
  customType,
  enableNewValue,
  queryValueCallback,
  keepValueVisibleAfterSelect,
  visibleValueProperty,
  canAddCustomEmail,
  showUsernameWithUserValues,
  required
}) => {
  return (
    <div className="flex flex-col">
      <div className="text-13px l-h-18px bh-text-deep-ocean-80 place-self-start px-0.5 py-1.5 font-semibold">
        {label}
        {labelHelper && <span className="bh-text-pigeon-70 ml-1 font-normal italic">- {labelHelper}</span>}
        {required && <span className="bh-text-error-red">*</span>}
      </div>
      <BhCombobox
        property={property}
        values={values}
        initialValue={initialValue}
        onSelect={onSelect}
        showValueCondition={showValueCondition}
        disabled={disabled}
        placeholder={placeholder}
        customType={customType}
        enableNewValue={enableNewValue}
        queryValueCallback={queryValueCallback}
        keepValueVisibleAfterSelect={keepValueVisibleAfterSelect}
        visibleValueProperty={visibleValueProperty}
        canAddCustomEmail={canAddCustomEmail}
        showUsernameWithUserValues={showUsernameWithUserValues}
      />
    </div>
  );
};

export default BhComboboxStackedLabel;
