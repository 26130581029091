import React, { FC, useEffect } from "react";

interface Props {}

const NotFound: FC<Props> = () => {

  return (
    <div>
      <h1>Not Found</h1>
    </div>
  );
};

export default NotFound;
