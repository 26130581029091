import App from "@/App";
import { AuthInterceptor } from "@/api/interceptor/fetchInterceptor";
import { store } from "@/app/store";
import FullPageLogo from "@/views/global/FullPageLogo";
import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "./i18n";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { overwriteEnvironmentVariables } from "./utilities/jsUtilities";

overwriteEnvironmentVariables();
const container = document.getElementById("root");
AuthInterceptor();

if (container) {
  const root = createRoot(container);
  root.render(
    // TODO: Kasuta React Strict Mode, ver 18 peale uuendades oli mingi mure.
    // <React.StrictMode>
    <Suspense fallback={<FullPageLogo />}>
      <Provider store={store}>
        <App />
      </Provider>
    </Suspense>
    // </React.StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
