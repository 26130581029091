import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boDeleteChecklistBaseVersionAsync, boSelectCompany } from "@/app/store/backofficeSlice";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import BOCompanyTransferChecklistsModal from "@components/backoffice/company/modulesAndTemplates/BOCompanyTransferChecklistsModal";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IChecklistBase } from "@/model/checklist/IChecklistBase";
import BhTableHeader from "@components/table/BhTableHeader";
import BOCompanyChecklistRow from "@components/backoffice/company/modulesAndTemplates/BOCompanyChecklistRow";
import { EntityId } from "@reduxjs/toolkit";

export interface IBoDeleteChecklistBaseDTO {
  companyId: EntityId;
  checklistBaseId: EntityId;
}

const BOCompanyChecklists = () => {
  const company = useAppSelector(boSelectCompany);
  const [transferModalOpen, setTransferModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  const tableColumnHeaders: Array<IBhTableHeader<IChecklistBase>> = [
    { id: 0, content: "Name" },
    { id: 1, content: "Versions" },
    { id: 2, content: "Projects" },
    { id: 3, content: "" }
  ];

  const deleteChecklistBase = (cl: IChecklistBase) => {
    const dto: IBoDeleteChecklistBaseDTO = { companyId: company.id, checklistBaseId: cl.id };
    dispatch(boDeleteChecklistBaseVersionAsync(dto));
  };

  return (
    <>
      <div id="checklists" className="w-full">
        <div className="flex items-center justify-between">
          <h2>Checklists</h2>
          <BhSecondaryButton buttonProps={{ onClick: () => setTransferModalOpen(true) }}>Transfer</BhSecondaryButton>
        </div>
        <table className="w-full">
          <BhTableHeader columns={tableColumnHeaders} />
          <tbody>
            {company.companyChecklistBases?.map((cl) => (
              <BOCompanyChecklistRow checklist={cl} key={cl.id} onRemoveCallback={() => deleteChecklistBase(cl)} />
            ))}
          </tbody>
        </table>
      </div>
      {transferModalOpen && <BOCompanyTransferChecklistsModal setIsShown={setTransferModalOpen} />}
    </>
  );
};

export default BOCompanyChecklists;
