/* This example requires Tailwind CSS v2.0+ */
import React, { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const BhModalContainer: FC<Props> = ({ children }) => {
  return (
    <div className="bh-bg-pigeon-60-alpha95 fixed z-50 flex h-full w-full flex-col items-center overflow-hidden p-8">
      <div className="bh-bg-raised-white max-w-385px flex h-full w-full rounded shadow-xl">{children}</div>
    </div>
  );
};

export default BhModalContainer;
