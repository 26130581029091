import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fileAddedToWorkGroup, fileRemovedFromWorkgroup, saveWorkGroupFileDeSelectedAsync, saveWorkGroupFileSelectedAsync, selectSelectedFileIds } from "@/app/store/project/projectWorkGroupsSlice";
import { FileEntityBranch, IFolderFileEntity } from "@/model/files/IFileEntity";
import { IFileTreeDTO } from "@/model/files/IFileTreeDTO";
import { IWorkGroup } from "@/model/IWorkGroup";
import DirectorySelectionContainer from "../../../directory/DirectorySelectionContainer";
import { EntityId } from "@reduxjs/toolkit";
import { selectRootDirectoryId, selectRootDocumentDirectoryId } from "@/app/store/project/projectSlice";

interface Props {
  workGroup: IWorkGroup;
  branch: FileEntityBranch;
  newWorkGroup: boolean;
}

const PartyFilesContainer: FC<Props> = ({ workGroup, branch, newWorkGroup }) => {
  const [fileRootDir, setFileRootDir] = useState({} as IFolderFileEntity);
  const [documentRootDir, setDocumentRootDir] = useState({} as IFolderFileEntity);
  const [modelRootDir, setModelRootDir] = useState({} as IFolderFileEntity);
  const rootDirectoryId = useAppSelector(selectRootDirectoryId);
  const rootDocumentDirId = useAppSelector(selectRootDocumentDirectoryId);
  const [currentSelectedFileIds, setCurrentSelectedFileIds] = useState<EntityId[]>([]);
  const [openedFileIds, setOpenedFileIds] = useState<EntityId[]>([]);
  const selectedFileIds = useAppSelector(selectSelectedFileIds);
  const [done, setDone] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const selectedIds = selectedFileIds.filter((id: { id: EntityId; branch: FileEntityBranch }) => id.branch === branch).map((id: { id: EntityId; branch: FileEntityBranch }) => id.id);
    const openedIds = branch === FileEntityBranch.ROOT_DIR ? [...selectedIds, rootDirectoryId] : [...selectedIds, rootDocumentDirId];
    setCurrentSelectedFileIds(selectedIds);
    setOpenedFileIds(openedIds);
    setDone(true);
  }, []);

  const onFileSelect = (file: IFileTreeDTO) => {
    if (newWorkGroup) {
      if (file.selected) {
        dispatch(fileAddedToWorkGroup({ file: file, workGroupId: workGroup.id }));
      } else {
        dispatch(fileRemovedFromWorkgroup({ file: file, workGroupId: workGroup.id }));
      }
    } else {
      if (file.selected) {
        dispatch(saveWorkGroupFileSelectedAsync({ file: file, workGroupId: workGroup.id }));
      } else {
        dispatch(saveWorkGroupFileDeSelectedAsync({ file: file, workGroupId: workGroup.id }));
      }
    }
  };

  if (!done) {
    return null;
  }

  return (
    <>
      <div className="bh-bg-white flex h-full flex-col text-left">
        <DirectorySelectionContainer
          allowOnlyParentsToBeSelected={true}
          directoryIds={openedFileIds}
          selectOnlyOne={false}
          preSelectedDirectoryIds={currentSelectedFileIds}
          selectCallback={onFileSelect}
          fileRootDir={fileRootDir}
          setFileRootDir={setFileRootDir}
          documentRootDir={documentRootDir}
          setDocumentRootDir={setDocumentRootDir}
          modelRootDir={modelRootDir}
          setModelRootDir={setModelRootDir}
          ignorePrivileges={true}
          keepChildrenSelectedOnParentSelectionChange={true}
          showSelectedDirectoriesCount={true}
        />
      </div>
    </>
  );
};

export default PartyFilesContainer;
