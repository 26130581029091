import React from "react";
import BhTableRow from "@components/table/BhTableRow";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import BhTag from "@components/tags/BhTag";
import BhIconButton from "@components/buttons/BhIconButton";
import { useTranslation } from "react-i18next";
import { ISalesInvoice } from "@/model/billing/ISalesInvoice";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import moment from "moment";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { downloadSalesInvoice } from "@/api/billingAPI";
import { formatDate } from "@/utilities/dateUtility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons/faTriangleExclamation";

interface Props {
  invoice: ISalesInvoice;
}

const CompanyBillsTableRowContainer = React.memo<Props>(({ invoice }) => {
  const { t } = useTranslation();
  const isDue = !invoice.credited && !invoice.paid && moment(invoice.billingDueDate).add(7, "days").isBefore(moment());
  const rowClasses = isDue ? "bh-bg-error-red-10" : "bh-bg-white";

  if (!invoice) {
    return null;
  }

  const invoiceStatusTag = () => {
    if (invoice.credited || invoice.paid) {
      return <BhTag type={BhTagType.PAID}>{t("COMPANY.SETTINGS.ORDER.INVOICES.PAID")}</BhTag>;
    } else {
      if (isDue) {
        return <BhTag type={BhTagType.VOLGNEVUS}>{t("COMPANY.SETTINGS.ORDERS.SUSPENDED")}</BhTag>;
      }
      return t("COMPANY.SETTINGS.ORDER.INVOICES.DUE");
    }
  };

  const downloadInvoice = () => {
    if (!invoice.uuid || !invoice.companyId) {
      return;
    }

    downloadSalesInvoice(invoice.companyId, invoice.uuid).then((response) => {
      imitateUrlDownload(response.value);
    });
  };

  return (
    <BhTableRow classes={rowClasses} disableHover={isDue}>
      <td>
        <div>{formatDate(invoice.invoiceDate)}</div>
      </td>
      <td>
        <div className="">#{invoice.fullInvoiceNumber}</div>
      </td>
      <td>
        <div className="flex flex-row items-center justify-between">
          <div className="font-bold">{invoice.description}</div>
          {isDue && <FontAwesomeIcon icon={faTriangleExclamation} className={"bh-text-error-red"} aria-hidden="true" />}
        </div>
      </td>
      <td>
        <div className="">{formatDate(invoice.billingDueDate)}</div>
      </td>
      <td>
        <div className="">{invoice.sumWithoutVat}€</div>
      </td>
      <td>
        <div className="flex flex-row items-center justify-between">
          <div>{invoiceStatusTag()}</div>
          <BhIconButton icon={faArrowDownToLine} buttonProps={{ onClick: downloadInvoice, classes: "" }} />
        </div>
      </td>
    </BhTableRow>
  );
});

export default CompanyBillsTableRowContainer;
