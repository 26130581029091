import React, { FC } from "react";
import RevisionModalFileCardPaired from "@/views/home/project/detail/directory/directoryModals/revisionModal/RevisionModalFileCardPaired";
import BhIconButton from "@components/buttons/BhIconButton";
import { IFileEntity, IFileMatch } from "@/model/files/IFileEntity";
import { faUnlockKeyhole } from "@fortawesome/pro-regular-svg-icons/faUnlockKeyhole";
import { classNames } from "@/utilities/jsUtilities";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";

interface Props {
  fileMatch: IFileMatch;
  unlinkFiles: Function;
  fileMatchSelected: Function;
  unusedDirectoryFiles: Array<IFileEntity>;
}

const RevisionModalFileCardContainer: FC<Props> = ({ fileMatch, unlinkFiles, fileMatchSelected, unusedDirectoryFiles }) => {
  const { t } = useTranslation();

  const distanceColorClasses: Record<number, string> = {
    0: "bh-bg-success-green-110",
    1: "bh-bg-success-green-110",
    2: "bh-bg-success-green-110",
    3: "bh-bg-warning-yellow-120",
    4: "bh-bg-warning-yellow-120",
    5: "bh-bg-warning-yellow-120",
    6: "bh-bg-warning-yellow-120",
    7: "bh-bg-error-red",
    8: "bh-bg-error-red",
    9: "bh-bg-error-red"
  };

  return (
    <div className="mb-1.5 flex flex-row items-center">
      <div className="bh-bg-pigeon-40 flex w-full items-center rounded pr-0.5">
        <RevisionModalFileCardPaired fileMatch={fileMatch} fileMatchSelected={fileMatchSelected} unusedDirectoryFiles={unusedDirectoryFiles} />
        <BhTooltip body={t("REVISIONS.UNLINK")}>
          <BhIconButton icon={faUnlockKeyhole} buttonProps={{ onClick: () => unlinkFiles(fileMatch) }} />
        </BhTooltip>
      </div>
      <div className={classNames(fileMatch.bestDistance && distanceColorClasses[fileMatch.bestDistance.distance], "ml-2 flex w-4 flex-col justify-center self-stretch")}>
        {fileMatch.bestDistance && <div className="bh-text-white flex w-full flex-row items-center justify-center font-bold">{fileMatch.bestDistance.distance}</div>}
      </div>
    </div>
  );
};

export default RevisionModalFileCardContainer;
