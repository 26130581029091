import React, { useState } from "react";
import { useAppSelector } from "@/app/hooks";
import BhTableRow from "@components/table/BhTableRow";
import BhEventLabelContainer from "@components/eventContainer/BhEventLabelContainer";
import { IBauhubEvent, ProjectLogType } from "@/model/IBauhubEvent";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faEllipsisVertical, faHistory } from "@fortawesome/pro-solid-svg-icons";
import { selectCurrentUserUsername, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import BhTag from "@components/tags/BhTag";
import { classNames } from "@/utilities/jsUtilities";
import BhIconButton from "@components/buttons/BhIconButton";
import BhFileCardMedium from "@components/cards/BhFileCardMedium";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { faFolder } from "@fortawesome/pro-regular-svg-icons";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import ProjectLogFileMovedExtraContainer from "@/features/projectLogTable/ProjectLogFileMovedExtraContainer";
import { useTranslation } from "react-i18next";
import ProjectLogFileRenamedExtraContainer from "@/features/projectLogTable/ProjectLogFileRenamedExtraContainer";
import { formatDateTime } from "@/utilities/dateUtility";
import { faArrowRotateLeft } from "@fortawesome/pro-regular-svg-icons/faArrowRotateLeft";
import BhTagListContainer from "@components/tags/BhTagListContainer";
import { faMemoCircleCheck } from "@fortawesome/pro-regular-svg-icons/faMemoCircleCheck";
import { fetchUrlForFile } from "@/api/fileAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";

interface Props {
  log: IBauhubEvent;
  restoreFileCallback?: Function;
  openDirectoryCallback?: Function;
  openFileHistoryCallback?: Function;
  openActCallback?: Function;
}

const ProjectLogTableRowContainer = React.memo<Props>(({ log, restoreFileCallback, openDirectoryCallback, openFileHistoryCallback, openActCallback }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const username = useAppSelector(selectCurrentUserUsername);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const dropdownEnabled = openDirectoryCallback && openFileHistoryCallback;
  const [restoreButtonDisabled, setRestoreButtonDisabled] = useState(false);

  const restoreFile = () => {
    setRestoreButtonDisabled(true);
    restoreFileCallback &&
      restoreFileCallback(log).then(() => {
        setRestoreButtonDisabled(false);
      });
  };

  if (!log) {
    return null;
  }

  const isRestorable = (log: IBauhubEvent) => {
    const isTypeDeleted = log.type === ProjectLogType.FILE_DELETED;
    const notRestored = !log.data.isRestored;
    const isCurrentUserProjectAdminOrCreator = isProjectAdmin || log.createdBy === username;

    return isTypeDeleted && notRestored && isCurrentUserProjectAdminOrCreator;
  };

  const isRestored = (log: IBauhubEvent) => {
    const isTypeDeleted = log.type === ProjectLogType.FILE_DELETED;
    const restored = !!log.data.isRestored;

    return isTypeDeleted && restored;
  };

  const openDirectory = () => {
    openDirectoryCallback && openDirectoryCallback(log);
  };

  const openFileHistoryModal = () => {
    openFileHistoryCallback && openFileHistoryCallback(log);
  };

  const openAct = () => {
    openActCallback && openActCallback(log);
  };

  const rowEllipsisDropdownValues = [
    log.data.resource !== FileEntityType.ACT && {
      text: t("FILE.GO_TO_DIR"),
      function: openDirectory,
      icon: faFolder
    },
    log.data.resource === FileEntityType.ACT && {
      text: t("FILE.OPEN_ACT"),
      function: openAct,
      icon: faMemoCircleCheck
    },
    {
      text: t("MODAL.FILE_LOG_BUTTON"),
      function: openFileHistoryModal,
      icon: faHistory
    }
  ].filter(Boolean);

  const generateEventFieldExtras = (log: IBauhubEvent) => {
    if (isRestorable(log)) {
      return (
        <BhTextOnlyButton buttonProps={{ onClick: restoreFile, disabled: restoreButtonDisabled }} icon={faArrowRotateLeft}>
          {t("FILE.RESTORE")}
        </BhTextOnlyButton>
      );
    }
    if (isRestored(log)) {
      return (
        <div>
          <BhTagListContainer>
            <BhTag type={BhTagType.TAG}>{t("FILE.RESTORED")}</BhTag>
          </BhTagListContainer>
          <div className="ml-1 text-sm">{formatDateTime(log.updated)}</div>
        </div>
      );
    }
  };

  const fileNameExtrasNeeded = (log: IBauhubEvent) => {
    const isFileMoved = log.type === ProjectLogType.FILE_MOVED_TO || log.type === ProjectLogType.FILE_MOVED_FROM;
    const isFileCopied = log.type === ProjectLogType.NEW_FILE && log.data.copiedFromFileId;
    const isFileRenamed = log.type === ProjectLogType.FILE_RENAMED;
    const isNewRevision = log.type === ProjectLogType.FILE_NEW_REVISION;
    const isRevisionRollback = log.type === ProjectLogType.FILE_REVISION_ROLLBACK;
    return isFileMoved || isFileCopied || isFileRenamed || isNewRevision || isRevisionRollback;
  };

  const generateFileNameFieldExtras = (log: IBauhubEvent) => {
    let classes = "mt-1 mb-2 flex flex-row w-full";
    if (log.type === ProjectLogType.FILE_MOVED_TO || log.type === ProjectLogType.FILE_MOVED_FROM || (log.type === ProjectLogType.NEW_FILE && log.data.copiedFromFileId)) {
      // HACK for contract directory name
      const isContractDir = log.data.parentFileEntityName === "CONTRACT_DIRECTORY";
      const toName = log.data.toDirectoryName === "CONTRACT_DIRECTORY" ? t("CONTRACT.CONTRACT_FILES") : log.data.toDirectoryName;
      const fromName = log.data.fromDirectoryName === "CONTRACT_DIRECTORY" ? t("CONTRACT.CONTRACT_FILES") : log.data.fromDirectoryName;

      return (
        <ProjectLogFileMovedExtraContainer
          classes={classes}
          fromDirectory={{ id: log.data.fromDirectoryId, name: fromName, type: "DIR", projectId: projectId } as IFileEntity}
          toDirectory={{ id: log.data.toDirectoryId, name: toName, type: "DIR", projectId: projectId, branch: isContractDir && "CONTRACT_DIRECTORY" } as IFileEntity}
        />
      );
    }
    if (log.type === ProjectLogType.FILE_RENAMED || log.type === ProjectLogType.FILE_NEW_REVISION || log.type === ProjectLogType.FILE_REVISION_ROLLBACK) {
      const newFileName = log.type === ProjectLogType.FILE_RENAMED ? log.data.name : log.type === ProjectLogType.FILE_NEW_REVISION ? log.data.newFileName : log.data.latestRevisionFileName;
      return <ProjectLogFileRenamedExtraContainer classes={classes} oldFileName={log.data.oldFileName} newFileName={newFileName} />;
    }
  };

  const fileLogToFileEntityMap: Record<ProjectLogType, IFileEntity> = {
    AUTHORITY_CHANGED: { id: log.fileEntityId, name: log.data.name, type: log.fileType, projectId: projectId } as IFileEntity,
    CONTAINER_UPDATE: { id: log.fileEntityId, name: log.data.name, type: log.fileType, projectId: projectId } as IFileEntity,
    FILE_COMMENT: { id: log.fileEntityId, name: log.data.name, type: log.fileType, projectId: projectId } as IFileEntity,
    FILE_DELETED: { id: log.fileEntityId, name: log.data.name, type: log.fileType, projectId: projectId } as IFileEntity,
    FILE_LOCKED: { id: log.fileEntityId, name: log.data.parentFileEntityName, type: log.fileType, projectId: projectId } as IFileEntity,
    FILE_MOVED_FROM: { id: log.fileEntityId, name: log.data.movedFileName, type: log.fileType, projectId: projectId } as IFileEntity,
    FILE_MOVED_TO: { id: log.fileEntityId, name: log.data.movedFileName, type: log.fileType, projectId: projectId } as IFileEntity,
    FILE_NEW_REVISION: { id: log.fileEntityId, name: log.data.newFileName, type: log.fileType, projectId: projectId } as IFileEntity,
    FILE_RENAMED: { id: log.fileEntityId, name: log.data.name, type: log.fileType, projectId: projectId } as IFileEntity,
    FILE_REVISION_ROLLBACK: { id: log.fileEntityId, name: log.data.latestRevisionFileName, type: log.fileType, projectId: projectId } as IFileEntity,
    FILE_UNDO_DELETE: { id: log.fileEntityId, name: log.data.name, type: log.fileType, projectId: projectId } as IFileEntity,
    FILE_UNLOCKED: { id: log.fileEntityId, name: log.data.parentFileEntityName, type: log.fileType, projectId: projectId } as IFileEntity,
    FORM_UPDATE: { id: log.fileEntityId, name: log.data.name, type: log.fileType, projectId: projectId } as IFileEntity,
    NEW_FILE: { id: log.fileEntityId, name: log.data.name, type: log.fileType, projectId: projectId } as IFileEntity,
    PROJECT_ARCHIVED: { id: log.fileEntityId, name: log.data.name, type: log.fileType, projectId: projectId } as IFileEntity,
    PROJECT_OPENED: { id: log.fileEntityId, name: log.data.name, type: log.fileType, projectId: projectId } as IFileEntity,
    PROJECT_UPDATE: { id: log.fileEntityId, name: log.data.name, type: log.fileType, projectId: projectId } as IFileEntity,
    SYNC_DIR_ADDED: { id: log.fileEntityId, name: log.data.name, type: log.fileType, projectId: projectId } as IFileEntity,
    SYNC_DIR_REMOVED: { id: log.fileEntityId, name: log.data.name, type: log.fileType, projectId: projectId } as IFileEntity
  };

  const downloadFile = () => {
    fetchUrlForFile(log.fileEntityId, false, true).then((presignedUrl) => {
      imitateUrlDownload(presignedUrl.value);
    });
  };

  const isDownloadable =
    log.fileType === FileEntityType.DIR ||
    log.fileType === FileEntityType.FILE ||
    log.fileType === FileEntityType.DOCUMENT ||
    log.fileType === FileEntityType.FORM ||
    log.fileType === FileEntityType.ATTACHMENT_CONTAINER ||
    log.fileType === FileEntityType.CONTAINER;

  return (
    <BhTableRow classes="hover:bh-bg-white">
      <td>
        <div className={classNames("my-1.5 flex w-full flex-row items-center space-x-2")}>
          <BhEventLabelContainer event={log} />
          {generateEventFieldExtras(log)}
        </div>
      </td>
      <td className="align-middle">
        <div className="my-1">
          <BhFileCardMedium fileEntity={fileLogToFileEntityMap[log.type]} disabled={log.type === ProjectLogType.FILE_DELETED} onFileNameClickCallback={isDownloadable ? downloadFile : undefined} />
        </div>
        {fileNameExtrasNeeded(log) && <div className="mb-1 flex flex-row items-center">{generateFileNameFieldExtras(log)}</div>}
      </td>
      <td className="align-top">
        <div className="flex flex-row items-center justify-between pt-1.5">
          <div>
            <div>{formatDateTime(log.created)}</div>
            <div className="text-sm">{log.data.eventTriggererFirstName + " " + log.data.eventTriggererLastName}</div>
          </div>
          {dropdownEnabled && (
            <div className="bh-hover-item">
              <BhDropdown
                button={<BhIconButton icon={faEllipsisVertical} buttonProps={{ classes: "my-0-i mx-1-i" }} />}
                menu={<BhDropdownMenu values={rowEllipsisDropdownValues} type={BhDropdownTypeEnum.STRING} textProperty="text" />}
                position={BhDropdownPositionEnum.BOTTOM_LEFT}
              />
            </div>
          )}
        </div>
      </td>
    </BhTableRow>
  );
});

export default ProjectLogTableRowContainer;
