import { IUser } from "@/model/IUser";
import { ITask } from "@/model/taskBoard/ITask";

export function isTaskPersonal(task: ITask, currentUser: IUser) {
  return !task.taskBoardId && task.assignee === currentUser.id && task.reporter === currentUser.id;
}

function findFirstLetterOrNumberIndexFromString(string: string, originalString: string, startingIndex: number, existingLetters?: Array<string>) {
  const matchingAbbrLetters = existingLetters ? existingLetters : [];
  const char = Array.from(string).find((c) => {
    return (
      c.match("^[A-Za-z0-9]+$") &&
      !matchingAbbrLetters.some((x) => {
        return x.toUpperCase() === c.toUpperCase();
      })
    );
  });
  let index = 0;
  if (char) {
    for (let i = startingIndex; i < originalString.length; i++) {
      if (originalString[i].toUpperCase() === char.toUpperCase()) {
        index = i;
        break;
      }
    }
    return index;
  }
  return index;
}

export function findUniqueAbbreviationForTaskBoard(taskBoardName: string, existingTaskboardAbbreviations: Array<string>) {
  if (!taskBoardName || taskBoardName.length === -1) {
    return "";
  } else {
    const wgName = taskBoardName;

    let index = findFirstLetterOrNumberIndexFromString(wgName, wgName, 0);
    let uniqueAbbr = wgName[index];

    let existsLetterOrNumberInRemainingString = findFirstLetterOrNumberIndexFromString(wgName.slice(index + 1), wgName, index + 1) > index;
    if (wgName.length > 1 && existsLetterOrNumberInRemainingString) {
      index = findFirstLetterOrNumberIndexFromString(wgName.slice(index + 1), wgName, index + 1);
      uniqueAbbr = uniqueAbbr + wgName[index];
    }
    if (wgName.length > 2) {
      let lastLettersOfMatchingAbbrs: Array<string> = [];
      Array.from(wgName).forEach((letter) => {
        const matchingAbbr = existingTaskboardAbbreviations.find((abbr) => {
          return abbr === uniqueAbbr.toUpperCase() + letter.toUpperCase();
        });
        if (matchingAbbr) {
          lastLettersOfMatchingAbbrs.push(matchingAbbr[2]);
        }
      });
      existsLetterOrNumberInRemainingString = findFirstLetterOrNumberIndexFromString(wgName.slice(index + 1), wgName, index + 1, lastLettersOfMatchingAbbrs) > index;
      if (existsLetterOrNumberInRemainingString) {
        index = findFirstLetterOrNumberIndexFromString(wgName.slice(index + 1), wgName, index + 1, lastLettersOfMatchingAbbrs);
        uniqueAbbr = uniqueAbbr + wgName[index];
      }
    }
    return uniqueAbbr.toUpperCase();
  }
}

export const orderByDropdownValues = [
  { name: "GLOBAL.ORDER_BY.NAME", property: "name" },
  { name: "GLOBAL.ORDER_BY.CREATED", property: "created" },
  { name: "GLOBAL.ORDER_BY.UPDATED", property: "updated" },
  { name: "GLOBAL.ORDER_BY.DEADLINE", property: "deadline" },
  { name: "GLOBAL.ORDER_BY.PRIORITY", property: "priority" },
  { name: "GLOBAL.ORDER_BY.ASSIGNEEFULLNAME", property: "assignee" }
];
