import React, { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const BhFilterContainer: FC<Props> = ({ children }) => {
  return (
    <div className="my-3">
      <div className="flex flex-row flex-wrap items-center gap-y-2 gap-x-4">{children}</div>
    </div>
  );
};

export default BhFilterContainer;
