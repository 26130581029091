import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boRemoveFormAsync, boSelectCompany } from "@/app/store/backofficeSlice";
import { ClientActFormType, SubcontractorActFormType, SubcontractorContractFormType } from "@/model/IForm";
import { EnabledFormType, IEnabledForm } from "@/model/ICompany";
import BOCompanyTemplateRow from "@components/backoffice/company/modulesAndTemplates/BOCompanyTemplateRow";
import BhCombobox from "@components/input/BhCombobox";
import { naturalSortString } from "@/utilities/sortUtilities";

interface Props {
  formType: EnabledFormType;
  onSaveCallback: Function;
}

const BOCompanyActs: FC<Props> = ({ formType, onSaveCallback }) => {
  const { t } = useTranslation();
  const company = useAppSelector(boSelectCompany);
  const acts: string[] = Object.values(formType === EnabledFormType.SUBCONTRACTOR_ACT ? SubcontractorActFormType : ClientActFormType);
  const companyAct = company.enabledForms?.find((form) => form.type === formType);
  const dispatch = useAppDispatch();

  const actsTranslationMap: Record<string, string> = {};

  const translatedActs = acts
    .map((act) => {
      const translatedAct = t("BACKOFFICE.ACTS." + act);
      actsTranslationMap[translatedAct] = act;
      return translatedAct;
    })
    .sort((a, b) => naturalSortString(a, b));

  const removeForm = (form: IEnabledForm) => {
    dispatch(boRemoveFormAsync(form));
  };

  const saveForm = (selectedValue: Record<string, SubcontractorContractFormType>) => {
    const dto = { code: actsTranslationMap[selectedValue.code], nameLabel: actsTranslationMap[selectedValue.code], companyId: company.id, type: formType };
    onSaveCallback(dto);
  };

  return (
    <div className="mt-2 w-full">
      {companyAct && (
        <BOCompanyTemplateRow
          key={companyAct.id}
          template={companyAct as IEnabledForm}
          nameProperty="code"
          removeCallback={() => removeForm(companyAct)}
          label={t("BACKOFFICE.ACTS." + companyAct.code)}
        />
      )}
      {!companyAct && <BhCombobox initialValue={""} values={translatedActs} property="code" placeholder="+ Add module" onSelect={saveForm} />}
    </div>
  );
};

export default BOCompanyActs;
