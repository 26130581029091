import React, { FC } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import FormFieldContainerInput from "@components/form/base/body/fields/FormFieldContainerInput";
import FormBaseWeatherFieldAddColumnButton from "@components/form/base/body/fields/weatherField/FormBaseWeatherFieldAddColumnButton";
import FormBaseWeatherFieldRow from "@components/form/base/body/fields/weatherField/FormBaseWeatherFieldRow";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { v4 as uuidv4 } from "uuid";
import { classNames } from "@/utilities/jsUtilities";
import BhDropdown from "@components/dropdown/BhDropdown";
import { useTranslation } from "react-i18next";

interface Props {
  col: any;
  colIndex: number;
  saveFieldCallback: Function;
  fieldValue: any;
  disabled?: boolean;
}

const FormBaseWeatherFieldColumn: FC<Props> = ({ col, colIndex, saveFieldCallback, fieldValue, disabled }) => {
  const { t } = useTranslation();
  const isMaxColumns = fieldValue.columns.length >= 10;
  const isOnlyColumn = fieldValue.columns.length === 1;

  const addColumn = (index: number, type: string) => {
    const newColumns = [...fieldValue.columns];
    const newColumn = {
      label: "",
      type: type,
      property: uuidv4(),
      labelProperty: uuidv4()
    };
    newColumns.splice(index + 1, 0, newColumn);

    saveFieldCallback("columns", newColumns);
  };

  const removeColumn = (property: string) => {
    const newColumns = fieldValue.columns.filter((col: any) => col.property !== property);
    saveFieldCallback("columns", newColumns);
  };

  const handleLabelChange = (newLabel: string, property: string) => {
    const columns = [...fieldValue.columns];
    const changedColumns = columns.map((col: any) => (col.property === property ? { ...col, label: newLabel } : col));
    saveFieldCallback("columns", changedColumns);
  };

  const actionsMap = {
    CHECKBOX_FIELD: {
      text: t("FORMBUILDER.FORM.BASE.FIELD.WEATHER.CHECKBOX"),
      type: "CHECKBOX"
    },
    TEXT_FIELD: {
      text: t("FORMBUILDER.FORM.BASE.FIELD.WEATHER.TEXT_FIELD"),
      type: "TEXT"
    }
  };

  const dropdownActions = [actionsMap.CHECKBOX_FIELD, actionsMap.TEXT_FIELD];

  return (
    <div className={classNames("flex w-full flex-col items-center", col.type === "CHECKBOX" ? "min-w-24 max-w-100px" : "max-w-half")}>
      {!isOnlyColumn && !disabled && (
        <div className={classNames("flex w-full flex-row justify-center", !isMaxColumns && !disabled && "pr-8")}>
          <BhIconButton icon={faXmark} buttonProps={{ onClick: () => removeColumn(col.property) }} />
        </div>
      )}
      <div className="flex h-full w-full justify-center gap-2">
        <div className={classNames("flex h-full w-full flex-col items-center")}>
          <FormFieldContainerInput
            initialValue={col.label}
            disabled={disabled}
            property="label"
            onBlurCallback={(_: any, val: any) => handleLabelChange(val, col.property)}
            inputClasses={classNames("!min-w-0", col.type === "CHECKBOX" && "text-center")}
          />
          {fieldValue.rows?.map((row: any, rowIndex: number) => (
            <FormBaseWeatherFieldRow col={col} row={row} rowIndex={rowIndex} saveFieldCallback={saveFieldCallback} fieldValue={fieldValue} key={rowIndex} disabled={disabled} />
          ))}
        </div>
        {!isMaxColumns && !disabled && (
          <div className="form-builder-weather-button">
            <BhDropdown
              buttonClasses="h-full"
              button={<FormBaseWeatherFieldAddColumnButton />}
              menu={
                <BhDropdownMenu
                  widthClass="w-fit"
                  onSelect={(value: any) => addColumn(colIndex, value.type)}
                  values={dropdownActions}
                  type={BhDropdownTypeEnum.STRING}
                  textProperty="text"
                  closeOnItemClick={true}
                />
              }
              position={BhDropdownPositionEnum.BOTTOM_LEFT}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FormBaseWeatherFieldColumn;
