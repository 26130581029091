import React, { FC, useEffect } from "react";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import BhTableHeader from "@components/table/BhTableHeader";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  fetchCompanyAdminsByProjectAsync,
  fetchCurrentProjectAdministratorInvitesAsync,
  fetchMaruAuthoritiesAsync,
  fetchNordeconLawyerAuthoritiesAsync,
  fetchProjectAdministratorsWithUserInfoAsync,
  fetchRTEngineeringPMAuthoritiesAsync,
  selectCurrentProjectAdministratorInvites,
  selectCurrentProjectId,
  selectEmbachProjectModule,
  selectMaruProjectModule,
  selectNordeconProjectModule,
  selectProjectAdministratorsSorted,
  selectRTProjectModule
} from "@/app/store/project/projectSlice";
import ProjectAdministratorInvitesTableRow from "@components/projectSettings/ProjectAdministratorInvitesTableRow";
import { useTranslation } from "react-i18next";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import CustomCompanyProjectAdministratorsTableRow from "@components/projectSettings/CustomCompanyProjectAdministratorsTableRow";
import ProjectSettingsAdminInputMaru from "@components/projectSettings/customClient/ProjectSettingsAdminInputMaru";
import ProjectSettingsAdminInput from "@components/projectSettings/ProjectSettingsAdminInput";

const ProjectAdministratorsContainerCustomClient: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const projectAdministrators = useAppSelector((state) => selectProjectAdministratorsSorted(state, "firstName"));
  const projectAdministratorInvites = useAppSelector(selectCurrentProjectAdministratorInvites);
  const isNordeconProject = useAppSelector(selectNordeconProjectModule);
  const isEmbachProject = useAppSelector(selectEmbachProjectModule);
  const isRTProject = useAppSelector(selectRTProjectModule);
  const isMaruProject = useAppSelector(selectMaruProjectModule);

  useEffect(() => {
    dispatch(fetchProjectAdministratorsWithUserInfoAsync(projectId)).then(() => {
      if (isNordeconProject || isEmbachProject) {
        dispatch(fetchNordeconLawyerAuthoritiesAsync(projectId));
      }
      if (isRTProject) {
        dispatch(fetchCompanyAdminsByProjectAsync(projectId));
        dispatch(fetchRTEngineeringPMAuthoritiesAsync(projectId));
      }
      if (isMaruProject) {
        dispatch(fetchMaruAuthoritiesAsync(projectId));
      }
    });
    dispatch(fetchCurrentProjectAdministratorInvitesAsync(projectId));
  }, []);

  const customCompanyAuthorityHeader = (isNordeconProject || isEmbachProject) ? t("SETTINGS.LAWYER") : isRTProject ? t("RT_ADMIN.ENGINEERING_PM") : t("SHARE.HEADER.RIGHTS");
  const customCompanyTableColumnHeaders: Array<IBhTableHeader<ISimpleValidUserAuthority>> = [
    { id: 1, content: t("PROJECT.TABLE.HEADER.NAME"), classes: "", field: "firstName" },
    { id: 2, content: customCompanyAuthorityHeader, classes: "" },
    { id: 3, content: t("PROJECT.TABLE.HEADER.EMAIL"), classes: "" },
    { id: 4, content: t("PROJECT.TABLE.HEADER.TITLE"), classes: "" },
    { id: 5, content: t("PROJECT.TABLE.HEADER.COMPANY"), classes: "" },
    { id: 6, content: "", classes: "w-12" }
  ];

  return (
    <div>
      {isMaruProject && <ProjectSettingsAdminInputMaru />}
      {!isMaruProject && <ProjectSettingsAdminInput />}
      <div className="mt-4">
        <table className="w-full">
          <BhTableHeader columns={customCompanyTableColumnHeaders} />
          <tbody>
            {projectAdministrators &&
              projectAdministrators.map((admin, index) => {
                return <CustomCompanyProjectAdministratorsTableRow admin={admin} index={index} key={admin.userEntityId} />;
              })}
            {projectAdministratorInvites &&
              projectAdministratorInvites.map((invite) => {
                return <ProjectAdministratorInvitesTableRow invite={invite} key={invite.id} />;
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ProjectAdministratorsContainerCustomClient;
