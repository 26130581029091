import React, { FC } from "react";
import FormBaseProtocolFieldTableRow from "@components/form/base/body/fields/protocolField/FormBaseProtocolFieldTableRow";
import FormBaseProtocolFieldTableHeader from "@components/form/base/body/fields/protocolField/FormBaseProtocolFieldTableHeader";

interface Props {
  fieldValue: any;
  saveFieldCallback: Function;
  disabled?: boolean;
}

const FormBaseProtocolField: FC<Props> = ({ fieldValue, saveFieldCallback, disabled }) => {
  const agenda = fieldValue.agenda;

  if (!agenda || agenda.length === 0) return null;

  const saveRow = (changedValue: any, row: any, index: number) => {
    const rowToSave = { ...row, ...changedValue };
    if (agenda && agenda.length > 0) {
      const agendaToSave = agenda.slice();
      agendaToSave.splice(index, 1, rowToSave);
      saveFieldCallback("agenda", agendaToSave);
    }
  };

  const addRow = (index: number, newRow: any) => {
    const agendaToSave = agenda.slice();
    if (newRow.level === 0) {
      let nextMainRowIndex = agendaToSave.findIndex((row: any, i: number) => row.level === 0 && i > index);
      if (nextMainRowIndex === -1) {
        nextMainRowIndex = agendaToSave.length;
      }
      agendaToSave.splice(nextMainRowIndex, 0, newRow);
    } else {
      agendaToSave.splice(index + 1, 0, newRow);
    }
    saveFieldCallback("agenda", agendaToSave);
  };

  const removeRow = (index: number) => {
    const agendaToSave = agenda.slice();
    agendaToSave.splice(index, 1);
    saveFieldCallback("agenda", agendaToSave);
  };

  return (
    <div className="flex h-full w-full flex-col gap-y-1">
      <FormBaseProtocolFieldTableHeader saveCallback={saveFieldCallback} fieldValue={fieldValue} disabled={disabled} />
      {agenda.map((row: any, i: number) => {
        return (
          <FormBaseProtocolFieldTableRow
            row={row}
            isLastRow={agenda.length === i + 1}
            key={i}
            saveCallback={(changedValue: any) => saveRow(changedValue, row, i)}
            saveNewRowCallback={(index: number, newRow: any) => addRow(i, newRow)}
            removeRowCallback={() => removeRow(i)}
            disabled={disabled}
            isOnlyRow={row.level === 0 && agenda.filter((row: any) => row.level === 0).length === 1}
          />
        );
      })}
    </div>
  );
};

export default FormBaseProtocolField;
