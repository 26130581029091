import React, { FC, useState } from "react";
import BhUserIcon from "@components/user/BhUserIcon";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons";
import BhIconButton from "@components/buttons/BhIconButton";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { classNames } from "@/utilities/jsUtilities";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUser } from "@/app/store/userSlice";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import BhAttachmentsUploadContainer from "@components/upload/BhAttachmentsUploadContainer";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import AttachmentsContainerLarge from "@components/attachments/AttachmentsContainerLarge";
import { useTranslation } from "react-i18next";
import { EntityId } from "@reduxjs/toolkit";
import { IUser } from "@/model/IUser";
import BhTextareaWithMentions from "@components/input/BhTextareaWithMentions";

interface Props {
  onSubmit: Function;
  textProperty: string;
  usersWithAccess?: Array<IUser>;
}

const BhCommentInput: FC<Props> = ({ onSubmit, textProperty, usersWithAccess }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const currentUser = useAppSelector(selectCurrentUser);
  const [text, setText] = useState<string>("");
  const [attachments, setAttachments] = useState([] as Array<IFileEntity>);
  const [editorResetKey, setEditorResetKey] = useState(0);

  const saveComment = () => {
    if (active) {
      const changedValue = { [textProperty]: text, attachments: attachments };
      onSubmit(changedValue);
      setActive(false);
      setText("");
      setEditorResetKey((prevKey) => prevKey + 1);
    }
    setAttachments([]);
  };

  const cancelComment = () => {
    setActive(false);
    setText("");
    setAttachments([]);
    setEditorResetKey((prevKey) => prevKey + 1);
  };

  const addAttachments = (addedFiles: any) => {
    setAttachments(addedFiles.attachments);
  };

  const removeAttachment = (fileEntityId: EntityId) => {
    setAttachments(attachments.filter((file) => file.id !== fileEntityId));
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-start">
        <BhUserIcon user={currentUser} />
        <div onFocus={() => setActive(true)} className={classNames("relative ml-2 flex w-full flex-col rounded", active ? "bh-border-pigeon-70 border" : "bh-border-pigeon-40 border")}>
          <div className="w-comment-textarea mb-2">
            <BhTextareaWithMentions
              key={editorResetKey}
              inputClasses="w-full w-full rounded border-0 p-2 leading-5 placeholder:bh-text-deep-ocean-40"
              placeholder={t("COMMENT.ADD_COMMENT.PLACEHOLDER") as string}
              property={textProperty}
              userSuggestions={usersWithAccess}
              onChangeCallback={(value: any) => setText(value.text)}
            />
          </div>
          <div className="top-0.25 right-0.25 absolute">
            <BhAttachmentsUploadContainer
              saveCallback={addAttachments}
              existingAttachments={attachments}
              button={<BhIconButton icon={faPaperclip} buttonProps={{ classes: "mt-[1px] mr-[1px]" }} />}
              property={"attachments"}
              dropdownPosition={BhDropdownPositionEnum.BOTTOM_LEFT}
              excludeFileTypes={[FileEntityType.ROOT_DIR, FileEntityType.ROOT_DOCUMENT_DIR, FileEntityType.DIR]}
              fileUploadAllowed={true}
              chooseFromProjectAllowed={true}
              multiple={true}
            />
          </div>
          {attachments.length > 0 && (
            <div className="ml-2">
              <AttachmentsContainerLarge attachments={attachments} addingDisabled={true} removeCallback={removeAttachment} />
            </div>
          )}
        </div>
      </div>
      {active && (
        <div className="flex flex-row items-center justify-end pt-2">
          <BhTextOnlyButton buttonProps={{ classes: "mt-0", onClick: cancelComment }}>{t("GLOBAL.CANCEL")}</BhTextOnlyButton>
          <BhPrimaryButton buttonProps={{ classes: "mt-0 mr-0", onClick: saveComment }}>{t("COMMENT.ADD_COMMENT")}</BhPrimaryButton>
        </div>
      )}
    </div>
  );
};

export default BhCommentInput;
