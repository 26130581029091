import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { allFileEntitiesUnSelected, directoryInfoBarTabSet, fileEntitySelected, selectAreAllFilesSelectedFromCurrentDirectory } from "@/app/store/filesSlice";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import { IAuthorityBoolean } from "@/model/IUser";
import { DirectoryFileInfoBarTab } from "@/views/home/project/detail/directory/fileInfoBar/DirectoryFileInfoBar";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { IFileEntity } from "@/model/files/IFileEntity";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  fileEntity: IFileEntity;
  setRevisionShown: Function;
  privilegesForDirectory: IAuthorityBoolean;
  disableDragging?: boolean;
}

const FileContainerIconDraggable: FC<Props> = ({ fileEntity, setRevisionShown, privilegesForDirectory, disableDragging }) => {
  const allSelectedFilesFromCurrentDir = useAppSelector(selectAreAllFilesSelectedFromCurrentDirectory);
  const dispatch = useAppDispatch();

  const [{ isDragging }, dragRef, fileDragPreview] = useDrag({
    type: "fileEntityRow",
    item: fileEntity,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    end: (draggedItem, monitor) => {
      if (monitor.didDrop()) {
        setRevisionShown(false);
      }
    }
  });

  useEffect(() => {
    fileDragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  // IF you start dragging then select the current file
  useEffect(() => {
    if (fileEntity && isDragging && !fileEntity.selected) {
      dispatch(fileEntitySelected({ ids: [fileEntity.id], selected: true }));
    }
  }, [isDragging]);

  if (!fileEntity) return null;

  const handleCommentCountClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!privilegesForDirectory.isRead) return;
    e.stopPropagation();
    dispatch(allFileEntitiesUnSelected());
    dispatch(fileEntitySelected({ ids: [fileEntity.id], selected: true }));
    dispatch(directoryInfoBarTabSet(DirectoryFileInfoBarTab.COMMENTS));
  };

  const isDragEnabled = privilegesForDirectory.isWrite && allSelectedFilesFromCurrentDir && !disableDragging;

  return (
    <>
      <div
        ref={isDragEnabled ? dragRef : undefined}
        className={classNames(isDragEnabled && "cursor-grab", "relative flex h-full w-9 flex-none items-center justify-center text-center")}
        onClick={(e) => e.stopPropagation()}
      >
        <BhFileCardIcon fileEntity={fileEntity} fileCardSize={FileCardSize.LARGE} />
        {fileEntity.commentCount > 0 && (
          <div className="absolute right-0 bottom-0.5 cursor-pointer" onClick={handleCommentCountClick}>
            <BhBadge type={BhBadgeType.DARK} classes="hover:bh-bg-bauhub-green-120">
              {fileEntity.commentCount}
            </BhBadge>
          </div>
        )}
      </div>
    </>
  );
};

export default FileContainerIconDraggable;
