import React, { FC } from "react";
import { IBhButtonWithIconProps } from "@components/buttons/IBhButtonProps";
import BhButtonTemplateWithIcon from "@components/buttons/BhButtonTemplateWithIcon";
import { classNames } from "@/utilities/jsUtilities";

// FIGMA https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H/Bauhub-design-system?node-id=2080%3A112368

const BhTextOnlyButton: FC<IBhButtonWithIconProps> = ({ buttonProps, icon, right, children }) => {
  buttonProps = buttonProps ?? {};
  buttonProps.classes = classNames(
    "py-1 extra-letter-spacing !px-2",
    buttonProps.inverted && " hover:bh-bg-deep-ocean-80 active:bh-bg-deep-ocean-60 disabled:bh-text-pigeon-60 bh-text-mint",
    !buttonProps.inverted && " hover:bh-bg-pigeon-20 active:bh-bg-pigeon-40 disabled:bh-text-pigeon-60 bh-text-deep-ocean ",
    buttonProps.disabled && " disabled:bh-text-pigeon-60 hover:bg-none ",
    buttonProps.classes
  );

  return (
    <BhButtonTemplateWithIcon buttonProps={buttonProps} icon={icon}>
      {children}
    </BhButtonTemplateWithIcon>
  );
};

export default BhTextOnlyButton;
