import React, { FC, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  deleteTaskBoardAsync,
  resetOpenedTaskBoardId,
  resetTaskBoardModalActiveTaskBoardId,
  saveTaskBoardAsync,
  selectTaskBoardAbbreviations,
  selectTaskBoardById,
  selectTaskBoardModalActiveTaskBoardId, selectTaskBoardStatus,
  toggleTaskBoardModalsOpen
} from "@/app/store/taskBoardsSlice";
import BhModal from "@components/modal/BhModal";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { ITaskBoard, TaskBoardStatus } from "@/model/taskBoard/ITaskBoard";
import BhTagWithDropdown from "@components/tags/BhTagWithDropdown";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { selectAllProjectWorkGroupsSorted } from "@/app/store/project/projectWorkGroupsSlice";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { findUniqueAbbreviationForTaskBoard } from "@/utilities/taskBoardUtil";
import { IWorkGroupToTaskBoard } from "@/model/taskBoard/IWorkGroupToTaskBoard";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { useNavigate } from "react-router-dom";
import { removeTaskBoardTasks, updateTaskBoardTaskIdentifiers } from "@/app/store/tasksSlice";
import TaskBoardSettingsWorkGroup from "@components/taskBoard/modals/TaskBoardSettingsWorkGroup";
import { useTranslation } from "react-i18next";
import { IWorkGroup } from "@/model/IWorkGroup";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { EntityId } from "@reduxjs/toolkit";

const TaskBoardSettingsModal: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const projectWorkGroups = useAppSelector(selectAllProjectWorkGroupsSorted);
  const existingAbbreviations = useAppSelector(selectTaskBoardAbbreviations);
  const existingTaskBoardId = useAppSelector(selectTaskBoardModalActiveTaskBoardId);
  const existingTaskBoard = useAppSelector((state) => existingTaskBoardId && selectTaskBoardById(state, existingTaskBoardId));
  let wgAbbrInputRef = useRef<HTMLInputElement>(null);
  const [taskBoard, setTaskBoard] = useState(
    existingTaskBoardId ? existingTaskBoard : ({ status: TaskBoardStatus.IN_PROGRESS, projectId: projectId, workGroupToTaskBoards: [] as Array<IWorkGroupToTaskBoard> } as ITaskBoard)
  );
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const taskBoardStatus = useAppSelector(selectTaskBoardStatus);

  const isTaskBoardSaving = taskBoardStatus === BhStateStatusType.PENDING;

  useEffect(() => {
    if (existingTaskBoard) {
      setTaskBoard(existingTaskBoard);
    }
  }, [existingTaskBoard]);

  if (!taskBoard) return null;

  const closeModal = (navigateToId?: EntityId) => {
    dispatch(toggleTaskBoardModalsOpen({ modal: "taskBoardSettingsModal" }));
    dispatch(resetTaskBoardModalActiveTaskBoardId());
    if (navigateToId) {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/taskboard/list/${navigateToId}`);
    }
  };

  const statuses = [
    { status: TaskBoardStatus.TODO, name: "WORKGROUP.STATUS.TODO" },
    { status: TaskBoardStatus.IN_PROGRESS, name: "WORKGROUP.STATUS.IN_PROGRESS" },
    { status: TaskBoardStatus.DONE, name: "WORKGROUP.STATUS.DONE" }
  ];

  const setTaskBoardNameAndAbbreviation = (nameObj: any) => {
    if (wgAbbrInputRef && wgAbbrInputRef.current) {
      wgAbbrInputRef.current.value = findUniqueAbbreviationForTaskBoard(nameObj.name, existingAbbreviations) as string;
      setTaskBoard({ ...taskBoard, ...{ name: nameObj.name, abbreviation: wgAbbrInputRef.current.value } });
    }
  };

  const saveTaskBoard = (changedObj: any) => {
    if (!existingTaskBoard && changedObj.name) {
      setTaskBoardNameAndAbbreviation(changedObj);
    } else {
      const changedTb = { ...taskBoard, ...changedObj };
      setTaskBoard(changedTb);
      if (existingTaskBoard) {
        const tbToSave = { ...changedTb, ...{ users: [], workGroupToTaskBoards: [] } };
        dispatch(saveTaskBoardAsync(tbToSave)).then((action) => {
          const savedTaskBoard = action.payload as ITaskBoard;
          if (savedTaskBoard && savedTaskBoard.abbreviation) {
            dispatch(updateTaskBoardTaskIdentifiers({ taskBoardId: savedTaskBoard.id, abbreviation: savedTaskBoard.abbreviation }));
          }
        });
      }
    }
  };

  const setTaskBoardStatus = (statusObject: any) => {
    const changedTb = { ...taskBoard, ...{ status: statusObject.status } };
    setTaskBoard(changedTb);
    if (existingTaskBoard) {
      const tbToSave = { ...changedTb, ...{ users: [], workGroupToTaskBoards: [] } };
      dispatch(saveTaskBoardAsync(tbToSave));
    }
  };

  const saveNewTaskBoard = () => {
    dispatch(saveTaskBoardAsync(taskBoard)).then((action: any) => {
      const savedTaskBoard = action.payload as ITaskBoard;
      if (savedTaskBoard) {
        closeModal(savedTaskBoard.id);
      }
    });
  };

  const deleteTaskBoard = () => {
    if (taskBoard.id) {
      setDeleteConfirmationModalOpen(false);
      dispatch(deleteTaskBoardAsync(taskBoard));
      dispatch(resetOpenedTaskBoardId());
      dispatch(toggleTaskBoardModalsOpen({ modal: "taskBoardSettingsModal" }));
      dispatch(resetTaskBoardModalActiveTaskBoardId());
      dispatch(removeTaskBoardTasks(taskBoard.id));
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/taskboard/list/personal`);
    }
  };

  return (
    <BhModal
      size="3xl"
      isShown={true}
      setIsShown={() => {}}
      onClose={closeModal}
      header={
        <div className="h-7 pl-4">
          <h2 className="mt-1 leading-7">{taskBoard.id ? t("TASKBOARD.SETTINGS") : t("TASKBOARD.CREATE_NEW")}</h2>
        </div>
      }
      children={
        <BhScrollableBody>
          <div className="px-20 py-14">
            <h2 className="bh-text-deep-ocean mt-0 mb-4">{t("GLOBAL.INFO")}</h2>
            <div className="flex flex-row">
              <div className="w-full flex-1">
                <BhInputStackedLabel initialValue={taskBoard.name} property={"name"} label={t("TASKBOARD.NAME") as string} saveCallback={saveTaskBoard} />
              </div>
              <div className="ml-8 w-56">
                <BhInputStackedLabel
                  initialValue={taskBoard.abbreviation}
                  property={"abbreviation"}
                  label={t("GLOBAL.ABBREVIATION") as string}
                  inputRef={wgAbbrInputRef}
                  saveCallback={saveTaskBoard}
                />
              </div>
            </div>
            <div className="mt-5 flex flex-row items-center">
              <span className="mr-2 font-bold leading-5">{t("TASK.STATUS")}</span>
              <BhTagWithDropdown
                icon={faCaretDown}
                tagText={t("WORKGROUP.STATUS." + taskBoard.status)}
                values={statuses}
                textProperty={"name"}
                onSelect={setTaskBoardStatus}
                dropdownPosition={BhDropdownPositionEnum.BOTTOM_LEFT}
                translateValues={true}
              />
            </div>
            {taskBoard.id && (
              <div className="bh-border-pigeon-20 mt-6 border-t border-b py-6">
                <BhTextOnlyButton icon={faTrash} buttonProps={{ onClick: () => setDeleteConfirmationModalOpen(true) }}>
                  {t("TASKBOARD.DELETE")}
                </BhTextOnlyButton>
              </div>
            )}
            <h2 className="bh-text-deep-ocean mt-8 mb-4">{t("PARTIES.ACCESSES")}</h2>
            <div className="flex flex-col">
              <div className="flex flex-row p-3">
                <div className="w-full flex-1 font-bold">{t("GLOBAL.ITEM_NAME")}</div>
                <div className="w-56 font-bold">{t("GLOBAL.USERS")}</div>
              </div>
              {projectWorkGroups.map((workGroup: IWorkGroup) => {
                return <TaskBoardSettingsWorkGroup workGroup={workGroup} key={workGroup.id} taskBoard={taskBoard} setTaskBoard={setTaskBoard} />;
              })}
            </div>
          </div>
        </BhScrollableBody>
      }
      footer={
        <div className="flex flex-row items-center">
          <BhTextOnlyButton buttonProps={{ onClick: () => closeModal() }}>{t("GLOBAL.CANCEL")}</BhTextOnlyButton>
          {!taskBoard.id && <BhPrimaryButton buttonProps={{ onClick: () => saveNewTaskBoard(), disabled: isTaskBoardSaving }}>{t("TASKBOARD.SAVE")}</BhPrimaryButton>}
          {deleteConfirmationModalOpen && (
            <BhDeleteConfirmationModal
              isOpen={true}
              setIsOpen={setDeleteConfirmationModalOpen}
              header={<h2>{t("TASK_BOARD.REMOVE.HEADER")}</h2>}
              body={t("TASK_BOARD.REMOVE.BODY")}
              confirmationButtonText={t("GLOBAL.DELETE")}
              handleDelete={deleteTaskBoard}
            />
          )}
        </div>
      }
    />
  );
};

export default TaskBoardSettingsModal;
