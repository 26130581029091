import React, { FC } from "react";
import { getUserFullName } from "@/model/IUser";
import BhUserIcon from "@components/user/BhUserIcon";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import { ICompanyInvitedUser } from "@/model/companyUsers/ICompanyInvitedUser";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyUserOrInviteeByUsername } from "@/app/store/companiesSlice";
import { EntityId } from "@reduxjs/toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { formatDateTime } from "@/utilities/dateUtility";
import BhTagListContainer from "@components/tags/BhTagListContainer";
import { useTranslation } from "react-i18next";

interface Props {
  username: string;
  companyId: EntityId;
  userExists: boolean;
}

const UserOrInviteeIconWithName: FC<Props> = ({ username, companyId, userExists }) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => selectCompanyUserOrInviteeByUsername(state, companyId, username, userExists));
  const inviteDateExists = user && !user.userExists && (user as ICompanyInvitedUser).inviteSentAt !== undefined;
  const inviteDate = inviteDateExists ? (user as ICompanyInvitedUser).inviteSentAt : undefined;

  if (!user) {
    return null;
  }

  return (
    <>
      {user.userExists && (
        <div className="flex items-center gap-3">
          <BhUserIcon user={user} /> <b>{getUserFullName(user as ICompanyUser)}</b>
        </div>
      )}
      {!userExists && (
        <div className="flex flex-row items-center gap-3">
          <div className="bh-bg-pigeon-20 flex h-8 w-8 flex-col items-center justify-center rounded-full ring-2 ring-white">
            <FontAwesomeIcon icon={faUser} className="bh-text-pigeon-60 " />
          </div>
          {inviteDate && (
            <div>
              <BhTagListContainer>
                <BhTag type={BhTagType.TAG}>{t("GLOBAL.INVITE.PENDING")}</BhTag>
              </BhTagListContainer>
              <div className="ml-1">
                {t("INVITE.SENT")} {formatDateTime(inviteDate)}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UserOrInviteeIconWithName;
