import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { faMinus } from "@fortawesome/pro-regular-svg-icons/faMinus";
import PlusMinusFieldCounterButton from "@components/checklists/checklistBase/body/rowTypes/plusMinus/PlusMinusFieldCounterButton";
import { classNames } from "@/utilities/jsUtilities";
import { PlusMinusCounterType } from "@/model/checklist/IChecklistBaseVersionRow";

interface Props {
  total: number;
  fieldType: PlusMinusCounterType;
  labelLanguage: string | undefined;
  disabled?: boolean;
  onPlusClickCallback?: Function;
  onMinusClickCallback?: Function;
}

const PlusMinusFieldCounter: FC<Props> = ({ total, fieldType, disabled, labelLanguage, onPlusClickCallback, onMinusClickCallback }) => {
  const { t } = useTranslation();

  const classMap: Record<PlusMinusCounterType, any> = {
    PLUS: {
      bgClass: "bh-bg-success-green-10",
      textClasses: "bh-text-dark-jungle disabled:bh-text-mint-130",
      borderClasses: "bh-border-mint-130 active:bh-border-dark-jungle",
      label: "CHECKLIST.ROW.PLUS_MINUS.OK"
    },
    MINUS: {
      bgClass: "bh-bg-error-red-10",
      textClasses: "bh-text-error-red disabled:bh-text-error-red-30",
      borderClasses: "bh-border-error-red-30 active:bh-border-error-red",
      label: "CHECKLIST.ROW.PLUS_MINUS.NOT_OK"
    }
  };

  return (
    <div className={classNames(classMap[fieldType].bgClass, "min-w-56 flex h-full flex-col py-2")}>
      <div className={classNames(classMap[fieldType].textClasses, "text-12px mb-1 flex w-full flex-row justify-center leading-4")}>{t(classMap[fieldType].label, { lng: labelLanguage })}</div>
      <div className="flex w-full flex-row items-center justify-center gap-x-1 px-4">
        <PlusMinusFieldCounterButton
          onChangeCallback={onMinusClickCallback}
          icon={faMinus}
          classes={classNames(classMap[fieldType].textClasses, classMap[fieldType].borderClasses)}
          disabled={disabled}
        />
        <div className="bh-border-pigeon-40 bh-bg-white flex h-12 w-full flex-row justify-center rounded border">
          <div className="bh-text-deep-ocean-60 text-18px flex h-full flex-col justify-center leading-6">{total}</div>
        </div>
        <PlusMinusFieldCounterButton
          onChangeCallback={onPlusClickCallback}
          icon={faPlus}
          classes={classNames(classMap[fieldType].textClasses, classMap[fieldType].borderClasses)}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default PlusMinusFieldCounter;
