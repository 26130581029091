import React, { FC } from "react";

const ContainerFilesSkeleton: FC = () => {
  return (
    <div className="bh-border-pigeon-40 bh-hover-container flex h-14 items-center border-b">
      <div className="w-2 flex-none"></div>
      <div className="relative flex w-9 flex-none flex-grow">
        <div className="h-5 w-3/4 animate-pulse rounded bg-gray-400" />
      </div>
      <div className="w-18 flex flex-none items-center justify-center">
        <div className="h-5 w-3/4 animate-pulse rounded bg-gray-400" />
      </div>
      <div className="flex w-48 flex-none items-center pl-2"></div>
      <div className="w-14 w-14 flex-none items-center"></div>
    </div>
  );
};

export default ContainerFilesSkeleton;
