import React, { Dispatch, FC, SetStateAction } from "react";
import BhDropdownButton from "@components/dropdown/BhDropdownButton";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { Authority, IPredefinedWorkGroupUser } from "@/model/IUserAuthority";
import BhDropdown from "@components/dropdown/BhDropdown";
import { useTranslation } from "react-i18next";

interface Props {
  userToAdd: IPredefinedWorkGroupUser;
  setUserToAdd: Dispatch<SetStateAction<IPredefinedWorkGroupUser>>;
  property: keyof IPredefinedWorkGroupUser;
  header: string;
}
const PartyTemplateNewUserPrivilegeDropdown: FC<Props> = ({ userToAdd, setUserToAdd, property, header }) => {
  const { t } = useTranslation();
  const dropdownMenuValues = [
    { value: Authority.NONE, translation: t("PARTY.NONE") },
    { value: Authority.READ, translation: t("PARTY.READ") },
    { value: Authority.WRITE, translation: t("PARTY.WRITE") },
    { value: Authority.ADMIN, translation: t("PARTY.ADMIN") }
  ];

  return (
    <div className="bh-bg-smoke flex basis-1/4 flex-col p-4">
      <div className="px-2">{header}</div>
      <BhDropdown
        button={<BhDropdownButton reversed={true} title="" value={t("PARTY." + userToAdd[property]) as string} />}
        menu={
          <BhDropdownMenu
            values={dropdownMenuValues}
            textProperty="translation"
            type={BhDropdownTypeEnum.STRING}
            onSelect={(changedValue: { value: Authority; translation: string }) => setUserToAdd({ ...userToAdd, [property]: changedValue.value })}
          />
        }
      />
    </div>
  );
};

export default PartyTemplateNewUserPrivilegeDropdown;
