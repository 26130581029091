import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import FormBaseRows from "@components/form/base/body/FormBaseRows";
import FormBaseInfo from "@components/form/base/body/formBaseInfo/FormBaseInfo";
import FormBaseRowsPreview from "@components/form/base/body/FormBaseRowsPreview";
import { useAppSelector } from "@/app/hooks";
import { selectIsFormBasePreviewEnabled } from "@/app/store/form/formBasesSlice";
import FormBaseInfoPreview from "@components/form/base/body/formBaseInfo/FormBaseInfoPreview";
import FormFooterRow from "@components/form/FormFooterRow";

interface Props {
  companyId: EntityId;
  formBaseId: EntityId;
  formBaseVersionId: EntityId;
  projectId?: EntityId;
}

const FormBaseBody: FC<Props> = ({ companyId, formBaseId, formBaseVersionId, projectId }) => {
  const isPreviewEnabled = useAppSelector(selectIsFormBasePreviewEnabled);
  return (
    <div className="flex flex-col overflow-auto px-16 pb-96 xl:px-6">
      {!isPreviewEnabled && (
        <>
          <FormBaseInfo companyId={companyId} formBaseId={formBaseId} />
          <FormBaseRows companyId={companyId} formBaseId={formBaseId} formBaseVersionId={formBaseVersionId} projectId={projectId} />
        </>
      )}
      {isPreviewEnabled && (
        <>
          <FormBaseInfoPreview companyId={companyId} formBaseId={formBaseId} />
          <FormBaseRowsPreview />
          <FormFooterRow isPreview={true} />
        </>
      )}
    </div>
  );
};

export default FormBaseBody;
