import React, { FC } from "react";
import DetailViewContainer from "@/views/home/project/detail/detailViewContainer/DetailViewContainer";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import SignInvitesTabs from "@/views/home/project/detail/signInvites/SignInvitesTabs";
import { Trans } from "react-i18next";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";

interface Props {}

const SignInvites: FC<Props> = () => {
  useDocumentTitle("PROJECT.SIDEBAR.SIGNATURES");

  return (
    <DetailViewContainer
      header={
        <DetailViewHeading>
          <Trans>PROJECT.SIDEBAR.SIGNATURES</Trans>
        </DetailViewHeading>
      }
    >
      <SignInvitesTabs />
    </DetailViewContainer>
  );
};

export default SignInvites;
