import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  children: React.ReactNode;
  classes?: string;
  trRef?: any;
}

const BhCompactTableTR: FC<Props> = ({ children, classes, trRef }) => {
  return (
    <tr className={classNames(classes, "group relative h-8")} ref={trRef}>
      {children}
    </tr>
  );
};

export default BhCompactTableTR;
