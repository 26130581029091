import React, { FC, useEffect, useRef, useState } from "react";
import { ITaskComment } from "@/model/taskBoard/ITaskComment";
import { getUserFromFullName } from "@/utilities/jsUtilities";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import BhCommentDropdown from "@components/comment/BhCommentDropdown";
import AttachmentsContainerLarge from "@components/attachments/AttachmentsContainerLarge";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { selectCurrentUser, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { formatDateTime } from "@/utilities/dateUtility";
import BhTextareaWithMentions from "@components/input/BhTextareaWithMentions";
import { EntityId } from "@reduxjs/toolkit";
import { selectTaskBoardUsersByTaskBoardId } from "@/app/store/taskBoardsSlice";
import { convertSimpleValidUserAuthorityToUser } from "@/model/ISimpleValidUserAuthority";
import { fetchPresignedBatchUrl, fetchUrlForFile } from "@/api/fileAPI";
import { imitateBatchFileDownload, imitateUrlDownload } from "@/utilities/downloadUtilities";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";

interface Props {
  comment: ITaskComment;
  editCallback: Function;
  deleteCallback: Function;
  taskBoardId: EntityId;
}

const BhComment: FC<Props> = ({ comment, editCallback, deleteCallback, taskBoardId }) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const currentUser = useAppSelector(selectCurrentUser);
  const projectId = useAppSelector(selectCurrentProjectId);
  const isCurrentUserProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const disableCommentEditing = !isCurrentUserProjectAdmin && currentUser.username !== comment.createdBy;
  const taskBoardUsers = useAppSelector((state) => (taskBoardId ? selectTaskBoardUsersByTaskBoardId(state, taskBoardId) : []));
  const users = taskBoardUsers.map((u) => convertSimpleValidUserAuthorityToUser(u));

  useEffect(() => {
    if (editing) {
      //TODO: Timeout because dropdown menu uses "closeOnItemClick"
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 100);
    }
  }, [editing]);

  const saveEdited = (value: any) => {
    const { text } = value;
    setEditing(false);
    if (text !== comment.text) {
      const commentToSave = { ...comment, text };
      editCallback(commentToSave);
    }
  };

  const downloadCommentAttachments = () => {
    if (comment.attachments && comment.attachments.length > 0) {
      if (comment.attachments.length === 1) {
        const fileEntity = comment.attachments[0];
        fetchUrlForFile(fileEntity.id, false, true, fileEntity.uuid).then((response) => {
          imitateUrlDownload(response.value);
        });
      } else {
        fetchPresignedBatchUrl(comment.attachments.map((att) => att.id)).then((result) => imitateBatchFileDownload(result, "excludeChildren=true"));
      }
    }
  };

  return (
    <div className="hover:bh-bg-smoke-50 flex flex-col rounded p-2 duration-200 ease-in-out">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center">
          <BhUserIconWithName user={getUserFromFullName(comment.authorFullName)} />
          <div className="text-12 ml-2 pt-0.5">{formatDateTime(comment.created)}</div>
        </div>
        {!disableCommentEditing && (
          <BhCommentDropdown
            comment={comment}
            editCallback={() => setEditing(true)}
            deleteCallback={deleteCallback}
            disabled={disableCommentEditing}
            downloadCallback={comment.attachments && comment.attachments.length > 0 ? downloadCommentAttachments : undefined}
          />
        )}
        {disableCommentEditing && comment.attachments && comment.attachments.length > 0 && (
          <BhTooltip body={t("COMMENT.DOWNLOAD_ATTACHMENTS")}>
            <BhIconButton icon={faArrowDownToLine} buttonProps={{ onClick: downloadCommentAttachments }} />
          </BhTooltip>
        )}
      </div>
      {!editing && <div className="whitespace-pre-line break-words pl-10" dangerouslySetInnerHTML={{ __html: comment.text }} />}
      {editing && (
        <div className="pl-10">
          <BhTextareaWithMentions
            initialValue={comment.text}
            inputClasses="w-full w-full rounded p-2 leading-5 bh-border-pigeon-70 border rounded"
            property={"text"}
            userSuggestions={users}
            onBlurCallback={saveEdited}
          />
        </div>
      )}
      {comment.attachments && comment.attachments.length > 0 && (
        <div className="pl-10 pt-2">
          <AttachmentsContainerLarge attachments={comment.attachments} addingDisabled={true} />
        </div>
      )}
    </div>
  );
};

export default BhComment;
