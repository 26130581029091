import { ITask, ITaskStatusChangeDTO } from "@/model/taskBoard/ITask";
import { IProjectTaskCategory } from "@/model/taskBoard/IProjectTaskCategory";
import { ITaskTag } from "@/model/taskBoard/ITaskTag";
import { ITaskComment, ITaskCommentDTO } from "@/model/taskBoard/ITaskComment";
import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { EntityId } from "@reduxjs/toolkit";

export function fetchTaskByIdentifier(projectId: EntityId, taskIdentifier: string): Promise<ITask> {
  return bauhubGet("/project/" + projectId + "/task/" + taskIdentifier);
}

export function fetchPersonalTask(projectId: EntityId, taskId: EntityId): Promise<ITask> {
  return bauhubGet("/project/" + projectId + "/personal/task/" + taskId);
}

export function fetchChecklistPersonalTask(projectId: EntityId, taskId: EntityId): Promise<ITask> {
  return bauhubGet("/project/" + projectId + "/checklist/personal/task/" + taskId);
}

export function fetchTaskBoardTasks(projectId: EntityId, taskBoardId: EntityId): Promise<Array<ITask>> {
  return bauhubGet("/project/" + projectId + "/board/" + taskBoardId + "/tasks");
}

export function fetchTasksByIdsWithExtraFields(taskIds: Array<EntityId>): Promise<Array<ITask>> {
  return bauhubPost("/project/tasks/extra?st=false", { taskIds: taskIds });
}

export function fetchTaskBoardTasksWithExtraFields(projectId: EntityId, taskBoardId: EntityId): Promise<Array<ITask>> {
  return bauhubGet("/project/" + projectId + "/board/" + taskBoardId + "/tasks/extra");
}

export function fetchPersonalTaskBoardTasks(projectId: EntityId): Promise<Array<ITask>> {
  return bauhubGet("/project/" + projectId + "/user/personal/tasks");
}

export function fetchAllTasks(projectId: EntityId, includeExtraFields: boolean): Promise<Array<ITask>> {
  return bauhubGet("/project/" + projectId + "/tasks", { includeExtraFields: includeExtraFields });
}

export function fetchProjectTaskCategories(projectId: EntityId): Promise<Array<IProjectTaskCategory>> {
  return bauhubGet("/project/" + projectId + "/categories");
}

export function fetchProjectTaskTags(projectId: EntityId): Promise<Array<ITaskTag>> {
  return bauhubGet("/project/" + projectId + "/tags");
}

export function saveTaskBoardTask(projectId: EntityId, task: ITask): Promise<ITask> {
  return bauhubPost("/project/" + projectId + "/board/" + task.taskBoardId + "/task", task);
}

export function savePersonalTask(projectId: EntityId, task: ITask): Promise<ITask> {
  return bauhubPost("/project/" + projectId + "/user/personal/task", task);
}

export function saveTaskStatus(projectId: EntityId, taskStatusChangeDTO: ITaskStatusChangeDTO): Promise<ITask> {
  return bauhubPost("/project/" + projectId + "/task/" + taskStatusChangeDTO.taskId + "/status", taskStatusChangeDTO);
}

export function deleteTask(taskId: EntityId): Promise<ITask> {
  return bauhubPost("/task/" + taskId + "/delete");
}

export function saveTaskBoardTaskComment(projectId: EntityId, commentDTO: ITaskCommentDTO): Promise<ITaskComment> {
  return bauhubPost("/project/" + projectId + "/board/" + commentDTO.taskBoardId + "/task/" + commentDTO.taskId + "/comment", commentDTO.comment);
}

export function savePersonalTaskComment(projectId: EntityId, commentDTO: ITaskCommentDTO): Promise<ITaskComment> {
  return bauhubPost("/project/" + projectId + "/private/task/" + commentDTO.taskId + "/comment", commentDTO.comment);
}

export function deleteTaskComment(comment: ITaskComment): Promise<ITaskComment> {
  return bauhubPost("/project/workgroup/task/comment/delete", comment);
}

export function saveProjectTaskCategory(category: IProjectTaskCategory): Promise<IProjectTaskCategory> {
  return bauhubPost("/project/task/category/save", category);
}

export function copyTask(task: ITask): Promise<ITask> {
  return bauhubPost("/project/workgroup/task/copy", task);
}

export function moveTask(task: ITask): Promise<ITask> {
  return bauhubPost("/project/workgroup/task/move", task);
}

export function saveTaskTag(tag: ITaskTag): Promise<ITaskTag> {
  return bauhubPost("/project/workgroup/task/tag", tag);
}

export function deleteTaskTag(tag: ITaskTag): Promise<ITaskTag> {
  return bauhubPost("/project/workgroup/task/tag/delete", tag);
}
