import moment from "moment";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import { IFileEntity } from "@/model/files/IFileEntity";

const bhToString = (value: any) => {
  if (value === null || value === undefined) {
    return "";
  }
  const string = "" + value;
  return string.toLowerCase();
};

// Replaces all suspected dates with a standardized yyyy-m-d, which is fixed below
const fixDates = (value: string) => {
  // group 1 catches : yyyy-mm-dd or yyyy-dd-mm
  // group 2 catches : dd-mm-yyyy or mm-dd-yyyy or d-mm-yyyy or m-dd-yyyy
  // group 3 catches : dd-mm-yy or mm-dd-yy
  // group 4 catches : yyyy.mm.dd or yyyy.dd.mm
  // group 5 catches : dd.mm.yyyy or mm.dd.yyyy or d.mm.yyyy or m.dd.yyyy
  // group 6 catches : dd.mm.yy or mm.dd.yy
  // Juhul kui MM(kuu) on > 12
  return value.replace(
    /(\d{4}-\d{2}-\d{2})|(\d{1,2}-\d{2}-\d{4})|(\d{2}-\d{2}-\d{2})|(\d{4}\.\d{2}\.\d{2})|(\d{1,2}\.\d{2}\.\d{4})|(\d{2}\.\d{2}\.\d{2})/,
    function ($0, group1, group2, group3, group4, group5, group6) {
      let parsedDate, day, month, year;
      if (group1) {
        parsedDate = moment(group1, ["YYYY-MM-DD", "YYYY-DD-MM"]);
      } else if (group2) {
        parsedDate = moment(group2, ["D-MM-YYYY", "M-DD-YYYY", "DD-MM-YYYY", "MM-DD-YYYY"]);
      } else if (group3) {
        parsedDate = moment(group3, ["DD-MM-YY", "MM-DD-YY"]);
      } else if (group4) {
        parsedDate = moment(group4, ["YYYY.MM.DD", "YYYY.DD.MM"]);
      } else if (group5) {
        parsedDate = moment(group5, ["D.MM.YYYY", "M.DD.YYYY", "DD.MM.YYYY", "MM.DD.YYYY"]);
      } else if (group6) {
        parsedDate = moment(group6, ["DD.MM.YY", "MM.DD.YY"]);
      }
      day = parsedDate?.date();
      month = parsedDate && parsedDate.month() + 1;
      year = parsedDate?.year();
      return year + "-" + month + "-" + day;
    }
  );
};

const padding = (value: string) => {
  return "00000000000000000000".slice(value.length);
};

// Fix numbers to be correctly padded
const fixNumbers = (value: string) => {
  // First, look for anything in the form of d.d or d.d.d...
  return bhToString(value).replace(/(\d+)((\.\d+)+)?/g, function ($0, integer, decimal, $3) {
    // If there's more than 2 sets of numbers...
    return bhToString($0).replace(/(\d+)/g, function ($d) {
      return padding($d) + $d;
    });
  });
};

const natValue = (value: any) => {
  const strValue = bhToString(value);
  const containsDate = strValue.match(/(\d{4}-\d{2}-\d{2})|(\d{1,2}-\d{2}-\d{4})|(\d{2}-\d{2}-\d{2})|(\d{4}\.\d{2}\.\d{2})|(\d{1,2}\.\d{2}\.\d{4})|(\d{2}\.\d{2}\.\d{2})/);
  if (!containsDate) {
    return fixNumbers(strValue);
  } else {
    return fixNumbers(fixDates(strValue));
  }
};

export const naturalSortFilesByField = (a: any, b: any, property: string, reversed?: boolean) => {
  if (property === "name") {
    if (a[property] === b[property]) {
      return 0;
    }
    const result = natValue(a[property]) > natValue(b[property]) ? 1 : -1;
    return reversed ? -result : result;
  } else {
    const result = (a[property] ? a[property].toString().toLowerCase() : "") > (b[property] ? b[property].toString().toLowerCase() : "") ? 1 : -1;
    return reversed ? -result : result;
  }
};

export const naturalSortByField = (a: any, b: any, property: string, reversed?: boolean) => {
  const twoLevelProperty = property.includes(".");
  const [firstProperty, secondProperty] = property.split(".") || [];

  const aValue = !twoLevelProperty
    ? a[property]
      ? a[property].toString().toLowerCase()
      : ""
    : a[firstProperty] && a[firstProperty][secondProperty]
    ? a[firstProperty][secondProperty].toString().toLowerCase()
    : "";
  const bValue = !twoLevelProperty
    ? b[property]
      ? b[property].toString().toLowerCase()
      : ""
    : b[firstProperty] && b[firstProperty][secondProperty]
    ? b[firstProperty][secondProperty].toString().toLowerCase()
    : "";
  const result = natValue(aValue) > natValue(bValue) ? 1 : -1;
  return reversed ? -result : result;
};

export const naturalSortString = (a: any, b: any, reversed?: boolean) => {
  const aValue = a.toString().toLowerCase();
  const bValue = b.toString().toLowerCase();
  const result = aValue > bValue ? 1 : -1;
  return reversed ? -result : result;
};

export const sortSignaturesAndInvites = (a: ISignatureHolder, b: ISignatureHolder, queueEnabled: boolean) => {
  // Allkirjastatud kutsed alati kõige ees
  if (a.claimedSigningTime && b.claimedSigningTime) {
    return a.claimedSigningTime < b.claimedSigningTime ? -1 : 1;
  } else if (a.claimedSigningTime && !b.claimedSigningTime) {
    return -1;
  } else if (!a.claimedSigningTime && b.claimedSigningTime) {
    return 1;
  }
  // Kui järjekorraga, siis ülejäänud kutsed järjekorranumbri järgi
  if (queueEnabled) {
    return a.orderNumber < b.orderNumber ? -1 : 1;
  }
  // kui ei, siis niisama ID järgi
  return a.uniqueId < b.uniqueId ? -1 : 1;
};

export const sortByFullNameAndEmail = (a: any, b: any) => {
  const aFullName = a.fullName ? a.fullName : a?.firstName + "" + a?.lastName;
  const bFullName = b.fullName ? b.fullName : b?.firstName + "" + b?.lastName;
  const aValue = aFullName + "" + a?.username;
  const bValue = bFullName + "" + b?.username;
  return aValue.toLowerCase() > bValue.toLowerCase() ? 1 : -1;
};

export const sortFilesBySignatures = (a: IFileEntity, b: IFileEntity, reversed?: boolean) => {
  const aIsSigned = a.invitesCount === 0 && a.signaturesCount > 0 && a.declinedInvitesCount === 0;
  const aIsBeingSigned = a.invitesCount > 0 && a.signaturesCount > 0 && a.declinedInvitesCount === 0;
  const aHasNoSignatures = a.invitesCount > 0 && a.signaturesCount === 0 && a.declinedInvitesCount === 0;
  const aIsDeclined = a.declinedInvitesCount > 0;

  const bIsSigned = b.invitesCount === 0 && b.signaturesCount > 0 && b.declinedInvitesCount === 0;
  const bIsBeingSigned = b.invitesCount > 0 && b.signaturesCount > 0 && b.declinedInvitesCount === 0;
  const bHasNoSignatures = b.invitesCount > 0 && b.signaturesCount === 0 && b.declinedInvitesCount === 0;
  const bIsDeclined = b.declinedInvitesCount > 0;

  const aIsSignedValue = aIsSigned ? 1 : 0;
  const bIsSignedValue = bIsSigned ? 1 : 0;
  const aIsBeingSignedValue = aIsBeingSigned ? 1 : 0;
  const bIsBeingSignedValue = bIsBeingSigned ? 1 : 0;
  const aHasNoSignaturesValue = aHasNoSignatures ? 1 : 0;
  const bHasNoSignaturesValue = bHasNoSignatures ? 1 : 0;
  const aIsDeclinedValue = aIsDeclined ? 1 : 0;
  const bIsDeclinedValue = bIsDeclined ? 1 : 0;

  if (aIsSigned !== bIsSigned) {
    return reversed ? aIsSignedValue - bIsSignedValue : bIsSignedValue - aIsSignedValue;
  } else if (aIsBeingSigned !== bIsBeingSigned) {
    return reversed ? aIsBeingSignedValue - bIsBeingSignedValue : bIsBeingSignedValue - aIsBeingSignedValue;
  } else if (aHasNoSignatures !== bHasNoSignatures) {
    return reversed ? aHasNoSignaturesValue - bHasNoSignaturesValue : bHasNoSignaturesValue - aHasNoSignaturesValue;
  } else if (aIsDeclined !== bIsDeclined) {
    return reversed ? aIsDeclinedValue - bIsDeclinedValue : bIsDeclinedValue - aIsDeclinedValue;
  }
  return 0;
};

export const sortByAnyType = (a: any, b: any, property: string, reversed?: boolean) => {
  const valueA = a[property];
  const valueB = b[property];

  if (typeof valueA === "number" && typeof valueB === "number") {
    return reversed ? valueB - valueA : valueA - valueB;
  } else if (typeof valueA === "string" && typeof valueB === "string") {
    return naturalSortString(valueB, valueA, reversed);
  } else if (typeof valueA === "boolean" && typeof valueB === "boolean") {
    const comparison = valueA === valueB ? 0 : valueA ? 1 : -1;
    return reversed ? -comparison : comparison;
  } else if (typeof valueA === "object" && valueA instanceof Date && typeof valueB === "object" && valueB instanceof Date) {
    return reversed ? valueB.getTime() - valueA.getTime() : valueA.getTime() - valueB.getTime();
  } else {
    return 0;
  }
};
