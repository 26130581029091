import React, { FC, useEffect, useRef, useState } from "react";
import { useWindowSize } from "@/utilities/hooks/useWindowSize";
import BhIconButton from "@components/buttons/BhIconButton";
import { faEllipsis } from "@fortawesome/pro-regular-svg-icons/faEllipsis";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import FormBaseFieldCheckboxWithText from "@components/form/base/body/FormBaseFieldCheckboxWithText";
import { useTranslation } from "react-i18next";

interface Props {
  fieldValue: any;
  saveFieldCallback: Function;
  disabled?: boolean;
}

const FormBaseFieldWithImportCheckbox: FC<Props> = ({ fieldValue, saveFieldCallback, disabled }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [showSmall, setShowSmall] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonText = t("FORMBUILDER.FORM.BASE.FIELD.TEXT.IMPORT");

  useEffect(() => {
    setShowSmall((containerRef.current && containerRef.current?.clientWidth < 220) || false);
  }, [width]);

  return (
    <div ref={containerRef}>
      {!showSmall && (
        <>
          <FormBaseFieldCheckboxWithText text={buttonText} isChecked={fieldValue} property={"enabled"} disabled={disabled} onChangeCallback={saveFieldCallback} />
        </>
      )}
      {showSmall && (
        <>
          <BhDropdown
            button={
              <>
                <BhIconButton icon={faEllipsis} buttonProps={{ disabled: disabled }} small={true} />
                {fieldValue && <div className="absolute top-1 right-1">{<div className="bh-bg-bauhub-green h-2 w-2 rounded-full"></div>}</div>}
              </>
            }
            menu={
              <BhDropdownMenu
                values={[
                  {
                    text: buttonText,
                    function: saveFieldCallback
                  }
                ]}
                withCheckbox={true}
                isSelected={() => fieldValue}
                textProperty={"text"}
                closeOnItemClick={true}
                type={BhDropdownTypeEnum.STRING}
                widthClass={"w-48"}
              />
            }
          />
        </>
      )}
    </div>
  );
};

export default FormBaseFieldWithImportCheckbox;
