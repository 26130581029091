import React, { FC } from "react";
import BhUserIconGrouped from "@components/user/BhUserIconGrouped";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/pro-solid-svg-icons/faCheckDouble";
import { faCircleXmark } from "@fortawesome/pro-regular-svg-icons/faCircleXmark";
import { useAppSelector } from "@/app/hooks";
import { IFileConfirmation } from "@/model/confirmations/IFileConfirmation";
import { classNames } from "@/utilities/jsUtilities";
import BhLargeBoldText from "@components/text/BhLargeBoldText";
import { selectConfirmationUserBubbles } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons/faSpinnerThird";

interface Props {
  fileConfirmation: IFileConfirmation;
}
const ConfirmationsListItemBody: FC<Props> = ({ fileConfirmation }) => {
  const users = useAppSelector(selectConfirmationUserBubbles);
  const usersForConfirmation = users.filter((user) => fileConfirmation.userIds?.includes(user.userEntityId));

  return (
    <div className="flex w-full flex-row p-3">
      <div className="text-12px l-h-16px bh-text-deep-ocean-80 w-16 py-2.5 text-center">{"#" + fileConfirmation.number}</div>
      <div className="flex flex-1 flex-col">
        <div>
          <BhLargeBoldText>{fileConfirmation.description}</BhLargeBoldText>
        </div>
        <div className="flex flex-row pt-1 pb-2">
          <BhUserIconGrouped userGroup={usersForConfirmation} />
        </div>
      </div>
      <div className="flex flex-row items-center">
        <div className="flex flex-row items-start space-x-16 pr-16 pl-8">
          <div className="flex flex-col items-center">
            <div>
              <FontAwesomeIcon icon={faSpinnerThird} className={classNames(fileConfirmation?.overallFilesPending > 0 ? "bh-text-success-green-110" : "bh-text-pigeon-60", "h-4 w-4")} />
            </div>
            <div className="pt-1 font-bold">{fileConfirmation.overallFilesPending || 0}</div>
          </div>
          <div className="flex flex-col items-center">
            <div>
              <FontAwesomeIcon icon={faCheckDouble} className={classNames(fileConfirmation?.completedFiles > 0 ? "bh-text-success-green-110" : "bh-text-pigeon-60", "h-4 w-4")} />
            </div>
            <div className="pt-1 font-bold">{fileConfirmation.completedFiles || 0}</div>
          </div>
          <div className="flex flex-col items-center">
            <div>
              <FontAwesomeIcon icon={faCircleXmark} className={classNames(fileConfirmation?.declinedFiles > 0 ? "bh-text-error-red" : "bh-text-pigeon-60", "h-4 w-4")} />
            </div>
            <div className="pt-1 font-bold">{fileConfirmation.declinedFiles || 0}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationsListItemBody;
