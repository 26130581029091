import React, { FC } from "react";
import { IChecklistBase } from "@/model/checklist/IChecklistBase";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  checklistBase: IChecklistBase;
  onChange: Function;
  isActive: boolean;
  disabled: boolean;
}

const BOCompanyChecklistBaseListItemWithCheckbox: FC<Props> = ({ checklistBase, onChange, isActive, disabled }) => {
  const onToggle = () => {
    onChange && onChange(checklistBase.id);
  };

  return (
    <div
      className={classNames(
        "hover:bh-bg-mint group flex h-7 cursor-pointer flex-row items-center justify-start px-2 py-1",
        isActive && "bh-bg-mint-50 font-weight-700",
        disabled && "bh-bg-smoke hover:bh-bg-smoke"
      )}
    >
      <input className="mr-2 h-4 w-4 rounded focus:ring-0 focus:ring-offset-0 " type="checkbox" checked={isActive} onChange={onToggle} disabled={disabled} />
      <div className="bh-text-deep-ocean overflow-hidden text-ellipsis whitespace-nowrap ">{checklistBase.type}</div>
    </div>
  );
};

export default BOCompanyChecklistBaseListItemWithCheckbox;
