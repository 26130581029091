import React, { FC, ReactNode } from "react";

interface Props {
  heading: ReactNode;
  body: ReactNode;
}

const SupportContactCard: FC<Props> = ({ heading, body }) => {
  return (
    <div className="bh-bg-smoke flex w-1/2 flex-col space-y-1 rounded p-4">
      <div>{heading}</div>
      <div className="text-18px l-h-24px bh-text-dark-jungle font-bold">{body}</div>
    </div>
  );
};

export default SupportContactCard;
