import React, { FC } from "react";
import { IUser } from "@/model/IUser";
import BhUserIconWithName from "@components/user/BhUserIconWithName";

export interface Props {
  value: IUser;
  withCheckbox?: boolean;
  onSelect?: Function;
  isSelected?: (value: any) => boolean;
}

const BhDropdownMenuUserListItem: FC<Props> = ({ value, withCheckbox, onSelect, isSelected }) => {
  return (
    <div className="flex flex-row items-center space-x-3 py-1.5 px-4" onClick={() => onSelect && onSelect(value)}>
      {withCheckbox && <input className="h-4 w-4 rounded focus:ring-0 focus:ring-offset-0" type="checkbox" checked={isSelected && isSelected(value)} onChange={() => {}} />}
      <BhUserIconWithName user={value} size="sm" disablePopup={true} />
    </div>
  );
};

export default BhDropdownMenuUserListItem;
