import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import DirectoryFileInfoBar from "@/views/home/project/detail/directory/fileInfoBar/DirectoryFileInfoBar";
import DirectoryModals from "@/views/home/project/detail/directory/directoryModals/DirectoryModals";
import BhBreadcrumbs from "@components/breadcrumb/BhBreadcrumbs";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import { allFileEntitiesUnSelected, currentDirIdSet, fetchFilesAsync } from "@/app/store/filesSlice";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import { Trans, useTranslation } from "react-i18next";
import BimDirectoryFileListHeaderMenu from "@/views/home/project/detail/directory/bimDirectory/BimDirectoryFileListHeaderMenu";
import BimDirectoryFileContainerTableHeader from "@/views/home/project/detail/directory/bimDirectory/BimDirectoryFileContainerTableHeader";
import DirectoryFileBasketContainer from "@/views/home/project/detail/directory/DirectoryFileBasketContainer";
import DirectoryFileListContainerWithUploadDrop from "@/views/home/project/detail/directory/DirectoryFileListContainerWithUploadDrop";
import { selectIsCurrentUserProjectAdmin, selectPrivilegesForDir } from "@/app/store/userSlice";
import { selectFolderById } from "@/app/store/foldersSlice";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import nextGenLogo from "@img/NextGen_Rahastanud_EL_NextGeneration_EST_hor_color_RGB.png";

interface Props {
  bimDirectoryId: number;
}

const BimDirectoryContainer: FC<Props> = ({ bimDirectoryId }) => {
  useDocumentTitle("BIM.MODEL");

  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const currentDirectory = useAppSelector((state) => selectFolderById(state, bimDirectoryId));
  const isCurrentUserProjectAdmin = useAppSelector((state) => currentDirectory && selectIsCurrentUserProjectAdmin(state, projectId));
  const privilegesForDirectory = useAppSelector((state) => currentDirectory && selectPrivilegesForDir(state, bimDirectoryId, currentDirectory, isCurrentUserProjectAdmin));
  const breadcrumbs = [{ text: t("BIM.MODEL"), url: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/bim` }, { text: t("BIM.FILES") }];
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSidebarCollapsed(true));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.BIM));
    return function cleanup() {
      dispatch(allFileEntitiesUnSelected());
    };
  }, []);

  useEffect(() => {
    dispatch(currentDirIdSet(bimDirectoryId));
  }, [bimDirectoryId]);

  useEffect(() => {
    if (bimDirectoryId) {
      dispatch(fetchFilesAsync(bimDirectoryId));
    }
  }, [bimDirectoryId]);

  if (!privilegesForDirectory?.isRead) {
    return (
      <BhNoMatchesImage>
        <Trans>DIRECTORY.NO_PRIVILEGES</Trans>
      </BhNoMatchesImage>
    );
  }

  return (
    <div className="flex h-full w-full min-w-0 flex-row overflow-hidden">
      <div className="flex w-full flex-col overflow-hidden px-6">
        <div className="flex-2 mt-3 mb-1 flex flex-row items-center">
          <div className="flex flex-row items-center">
            <div className="py-1">
              <BhBreadcrumbs values={breadcrumbs}></BhBreadcrumbs>
            </div>
          </div>
        </div>
        <DetailViewHeading>{t("BIM.FILES")}</DetailViewHeading>
        <div className="flex-2">
          <BimDirectoryFileListHeaderMenu currentDirId={bimDirectoryId} privilegesForDirectory={privilegesForDirectory} />
          <BimDirectoryFileContainerTableHeader privilegesForDirectory={privilegesForDirectory} />
        </div>
        <DirectoryFileListContainerWithUploadDrop currentDirId={bimDirectoryId} privilegesForDirectory={privilegesForDirectory} />
        <div className="flex h-[100px] flex-shrink-0 flex-row items-center border-t">
          <img src={nextGenLogo} className="h-[100px]" alt="Next Gen Rahastatud" />
          <div className="bh-text-deep-ocean flex flex-1 p-3">{t("BIM.EUROPE_RAHASTANUD")}</div>
        </div>
      </div>
      <DirectoryFileInfoBar privilegesForDirectory={privilegesForDirectory} />
      <DirectoryFileBasketContainer privilegesForDirectory={privilegesForDirectory} currentDirectoryId={bimDirectoryId} />
      <DirectoryModals currentDirectoryId={bimDirectoryId} />
    </div>
  );
};

export default BimDirectoryContainer;
