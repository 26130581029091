import React, { FC, useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  resetProjectLogFilterFolderIds,
  resetProjectLogFilterPage,
  resetProjectLogFilterSince,
  resetProjectLogFilterType,
  resetProjectLogFilterUntil,
  resetProjectLogFilterUsername,
  selectCurrentProjectLogFilter,
  selectCurrentProjectLogFilterOptions,
  selectCurrentProjectLogLoading,
  setProjectLogFilter
} from "@/app/store/project/projectLogSlice";
import { IProjectLogFilter } from "@/model/logs/IProjectLogFilter";
import { useLocation, useNavigate } from "react-router-dom";
import { serialize } from "@/utilities/jsUtilities";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhFilterContainer from "@components/filters/BhFilterContainer";
import { useTranslation } from "react-i18next";
import BhDatePicker from "@components/input/BhDatePicker";
import BhFilterButton from "@components/filters/BhFilterButton";
import DirectorySelectionModal from "@/views/home/project/detail/directory/directoryModals/DirectorySelectionModal";
import { IFolderFileEntity } from "@/model/files/IFileEntity";
import BhSelectInputFilter from "@components/filters/BhSelectInputFilter";
import { formatDateISO } from "@/utilities/dateUtility";
import { selectBimDirectoryId, selectRootDirectoryId, selectRootDocumentDirectoryId } from "@/app/store/project/projectSlice";

interface Props {}

const ProjectLogFilter: FC<Props> = () => {
  const filter = useAppSelector(selectCurrentProjectLogFilter);
  const filterOptions = useAppSelector(selectCurrentProjectLogFilterOptions);
  const fileRootDirectoryId = useAppSelector(selectRootDirectoryId);
  const documentRootDirectoryId = useAppSelector(selectRootDocumentDirectoryId);
  const modelRootDirectoryId = useAppSelector(selectBimDirectoryId);
  const loading = useAppSelector(selectCurrentProjectLogLoading);
  const directorySelectionModalIds = filter.folderIds
    ? [...filter.folderIds, fileRootDirectoryId, documentRootDirectoryId, modelRootDirectoryId]
    : [fileRootDirectoryId, documentRootDirectoryId, modelRootDirectoryId];
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [directorySelectionModalOpen, setDirectorySelectionModalOpen] = useState(false);

  const onNameFilterChange = useCallback(
    (filterObject: IProjectLogFilter) => {
      let newFilter = { ...filter, ...filterObject };
      if (!newFilter.fileName) {
        delete newFilter.fileName;
      }
      delete newFilter.filterOptions;
      newFilter.page = 1;
      const newLocation = {
        pathname: location.pathname,
        search: serialize(newFilter)
      };
      dispatch(setProjectLogFilter(newFilter));
      dispatch(resetProjectLogFilterPage());
      navigate(newLocation);
    },
    [filter]
  );

  const onLogTypeFilterSelect = useCallback(
    (type: string) => {
      let newFilter = { ...filter, type: type };
      delete newFilter.filterOptions;
      newFilter.page = 1;
      const newLocation = {
        pathname: location.pathname,
        search: serialize(newFilter)
      };
      dispatch(resetProjectLogFilterPage());
      navigate(newLocation);
    },
    [filter]
  );

  const onLogTypeFilterReset = () => {
    let newFilter = { ...filter };
    delete newFilter.type;
    delete newFilter.filterOptions;
    newFilter.page = 1;
    const newLocation = {
      pathname: location.pathname,
      search: serialize(newFilter)
    };
    dispatch(resetProjectLogFilterType());
    dispatch(resetProjectLogFilterPage());
    navigate(newLocation);
  };

  const onLogUsernameFilterSelect = useCallback(
    (username: string) => {
      let newFilter = { ...filter, username: username };
      delete newFilter.filterOptions;
      newFilter.page = 1;
      const newLocation = {
        pathname: location.pathname,
        search: serialize(newFilter)
      };
      dispatch(resetProjectLogFilterPage());
      navigate(newLocation);
    },
    [filter]
  );

  const onLogUsernameFilterReset = () => {
    let newFilter = { ...filter };
    delete newFilter.username;
    delete newFilter.filterOptions;
    newFilter.page = 1;
    const newLocation = {
      pathname: location.pathname,
      search: serialize(newFilter)
    };
    dispatch(resetProjectLogFilterUsername());
    dispatch(resetProjectLogFilterPage());
    navigate(newLocation);
  };

  const onLogDateFilterSelect = useCallback(
    (date: any) => {
      let filterObject;
      if (date.since) {
        filterObject = { since: formatDateISO(date.since) };
      }
      if (date.until) {
        filterObject = { until: formatDateISO(date.until) };
      }

      let newFilter = { ...filter, ...filterObject };
      delete newFilter.filterOptions;
      newFilter.page = 1;
      const newLocation = {
        pathname: location.pathname,
        search: serialize(newFilter)
      };
      dispatch(resetProjectLogFilterPage());
      navigate(newLocation);
    },
    [filter]
  );

  const onLogSinceFilterReset = () => {
    let newFilter = { ...filter };
    delete newFilter.since;
    delete newFilter.filterOptions;
    newFilter.page = 1;
    const newLocation = {
      pathname: location.pathname,
      search: serialize(newFilter)
    };
    dispatch(resetProjectLogFilterSince());
    dispatch(resetProjectLogFilterPage());
    navigate(newLocation);
  };

  const onLogUntilFilterReset = () => {
    let newFilter = { ...filter };
    delete newFilter.until;
    delete newFilter.filterOptions;
    newFilter.page = 1;
    const newLocation = {
      pathname: location.pathname,
      search: serialize(newFilter)
    };
    dispatch(resetProjectLogFilterUntil());
    dispatch(resetProjectLogFilterPage());
    navigate(newLocation);
  };

  const onLogFolderFilterSelect = useCallback(
    (selectedFolders: Array<IFolderFileEntity>) => {
      if (selectedFolders.length > 0) {
        const filterObject = { folderIds: selectedFolders.map((dir) => dir.id) };
        let newFilter = { ...filter, ...filterObject };
        delete newFilter.filterOptions;
        newFilter.page = 1;
        const newLocation = {
          pathname: location.pathname,
          search: serialize(newFilter)
        };
        dispatch(resetProjectLogFilterPage());
        navigate(newLocation);
      } else {
        onLogFolderFilterReset();
      }
      setDirectorySelectionModalOpen(false);
    },
    [filter]
  );

  const onLogFolderFilterReset = () => {
    let newFilter = { ...filter };
    delete newFilter.folderIds;
    delete newFilter.filterOptions;
    newFilter.page = 1;
    const newLocation = {
      pathname: location.pathname,
      search: serialize(newFilter)
    };
    dispatch(resetProjectLogFilterFolderIds());
    dispatch(resetProjectLogFilterPage());
    navigate(newLocation);
  };

  const logTypes = ["NEW_FILE", "FILE_DELETED", "FILE_RENAMED", "FILE_MOVED_TO", "FILE_NEW_REVISION", "FILE_REVISION_ROLLBACK", "FILE_UNDO_DELETE", "FILE_COMMENT"];

  return (
    <BhFilterContainer>
      <div>
        <BhSearchInputWBG initialValue={filter.fileName} property="fileName" onChangeCallback={onNameFilterChange} placeholder={t("LOG.SEARCH_FILENAME_PLACEHOLDER") as string} debounceLength={500} />
      </div>
      <BhDatePicker
        initialValue={filter.since ? new Date(filter.since) : null}
        property={"since"}
        onChangeCallback={onLogDateFilterSelect}
        classes={"w-full"}
        returnISOString={true}
        placeholder={t("LOG.SINCE") as string}
        onResetCallback={onLogSinceFilterReset}
        disabled={loading}
      />
      <BhDatePicker
        initialValue={filter.until ? new Date(filter.until) : null}
        property={"until"}
        onChangeCallback={onLogDateFilterSelect}
        classes={"w-full"}
        returnISOString={true}
        placeholder={t("LOG.UNTIL") as string}
        onResetCallback={onLogUntilFilterReset}
        disabled={loading}
      />
      <BhSelectInputFilter
        values={logTypes}
        currentValue={filter.type}
        onSelect={onLogTypeFilterSelect}
        placeholder={t("LOG.TYPE_OF_CHANGE")}
        translateValues={true}
        onReset={onLogTypeFilterReset}
        disabled={loading}
      />
      {filterOptions && filterOptions.usernames?.length > 0 && (
        <BhSelectInputFilter
          values={filterOptions.usernames.map((u: { name: string }) => u.name)}
          currentValue={filter.username}
          onSelect={onLogUsernameFilterSelect}
          placeholder={t("LOG.CHANGED_BY")}
          onReset={onLogUsernameFilterReset}
          disabled={loading}
        />
      )}
      {filter.total > 0 && (
        <BhFilterButton selectedValues={filter.folderIds ? filter.folderIds.length : 0} onReset={onLogFolderFilterReset} onClick={() => setDirectorySelectionModalOpen(true)} disabled={loading}>
          {t("LOG.DIRECTORY")}
        </BhFilterButton>
      )}
      {directorySelectionModalOpen && (
        <DirectorySelectionModal
          directoryIds={directorySelectionModalIds}
          preSelectedDirectoryIds={filter.folderIds || []}
          modalHeader={t("MODAL.CHANGE_DIR.CHOOSE_DIR")}
          onModalSubmit={onLogFolderFilterSelect}
          onModalClose={() => setDirectorySelectionModalOpen(false)}
          selectOnlyOne={false}
          allowOnlyParentsToBeSelected={false}
        />
      )}
    </BhFilterContainer>
  );
};

export default ProjectLogFilter;
