import React, { FC, useEffect } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectProjectTaskCategoryModalOpen, selectTaskBoardSettingsModalOpen } from "@/app/store/taskBoardsSlice";
import TaskBoardSettingsModal from "@/views/home/project/detail/taskBoard/modals/TaskBoardSettingsModal";
import ProjectTaskCategoryModal from "@/views/home/project/detail/taskBoard/modals/ProjectTaskCategoryModal";

const TaskBoardModals: FC = () => {
  const taskBoardSettingsModal = useAppSelector(selectTaskBoardSettingsModalOpen);
  const projectTaskCategoryModal = useAppSelector(selectProjectTaskCategoryModalOpen);

  return (
    <>
      {taskBoardSettingsModal && <TaskBoardSettingsModal />}
      {projectTaskCategoryModal && <ProjectTaskCategoryModal />}
    </>
  );
};

export default TaskBoardModals;
