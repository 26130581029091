import React, { Dispatch, FC, SetStateAction } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectNextToSignForContainer, selectSignaturesForContainer } from "@/app/store/signaturesSlice";
import { IFileEntity } from "@/model/files/IFileEntity";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import BhLightSeparatorVertical from "@components/separator/BhLightSeparatorVertical";
import BhDeclineButton from "@components/buttons/BhDeclineButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { selectIsProjectInProgress } from "@/app/store/project/projectSlice";
import { selectCurrentUser, selectCurrentUserInfo } from "@/app/store/userSlice";
import { fetchUrlForFile } from "@/api/fileAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { Trans } from "react-i18next";

interface Props {
  containerFileEntity: IFileEntity;
  setContainerSignModalShown: Dispatch<SetStateAction<boolean>>;
  setContainerDeclineModalShown: Dispatch<SetStateAction<boolean>>;
}

const ContainerActionButtonsContainer: FC<Props> = ({ containerFileEntity, setContainerSignModalShown, setContainerDeclineModalShown }) => {
  const isProjectInProgress = useAppSelector(selectIsProjectInProgress);
  const signatures = useAppSelector(selectSignaturesForContainer(containerFileEntity.uuid));
  const currentUserInfo = useAppSelector(selectCurrentUserInfo);
  const currentUser = useAppSelector(selectCurrentUser);
  const nextToSign = useAppSelector(selectNextToSignForContainer(containerFileEntity.uuid));

  const isCurrentUserAlreadySigned = signatures.some((signature) => {
    return !signature.deleted && !signature.isUnsignedInvite && signature.personalCode && signature.personalCode === currentUserInfo.personalCode;
  });
  const isCurrentUserAlreadyDeclined = signatures.some((signature) => {
    return !signature.deleted && signature.isUnsignedInvite && signature.declined && signature.username === currentUser.username;
  });
  const isUnsignedNotDeclinedSignInviteForCurrentUser = signatures.some((signature) => {
    return !signature.deleted && signature.isUnsignedInvite && !signature.declined && signature.username === currentUser.username;
  });

  const isQueueEnabledAndCurrentUserNextToSign = containerFileEntity.queueEnabled && nextToSign && nextToSign.username !== null && currentUser.username === nextToSign.username;
  const isQueueDisabled = !containerFileEntity.queueEnabled;

  const showSigningButtons = isProjectInProgress && (isQueueDisabled || isQueueEnabledAndCurrentUserNextToSign);
  const showDeclineButton = isUnsignedNotDeclinedSignInviteForCurrentUser;
  const disabledDeclineButton = isCurrentUserAlreadyDeclined;
  const disableSigningButton = isCurrentUserAlreadySigned;

  return (
    <>
      <div className="flex items-center space-x-4">
        <BhIconButton
          icon={faArrowDownToLine}
          buttonProps={{
            onClick: () => {
              fetchUrlForFile(containerFileEntity.id, false, true).then((presignedUrl) => imitateUrlDownload(presignedUrl.value));
            }
          }}
        />
        {showSigningButtons && (
          <>
            <BhLightSeparatorVertical />
            {showDeclineButton && (
              <BhDeclineButton icon={faXmark} buttonProps={{ onClick: () => setContainerDeclineModalShown(true), disabled: disabledDeclineButton }}>
                <Trans>GLOBAL.DECLINE_SIGNATURE</Trans>
              </BhDeclineButton>
            )}
            <BhPrimaryButton icon={faCheck} buttonProps={{ onClick: () => setContainerSignModalShown(true), disabled: disableSigningButton }}>
              <Trans>GLOBAL.SIGN</Trans>
            </BhPrimaryButton>
          </>
        )}
      </div>
    </>
  );
};

export default ContainerActionButtonsContainer;
