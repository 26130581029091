import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { RootState } from "@/app/store";
import { isCurrentUserCompanyFormsManager } from "@/app/store/userSlice";
import { EntityId } from "@reduxjs/toolkit";
import CompanySettingsPlanLimitModalContent from "@/views/home/company/detail/companySettingsSubscription/CompanySettingsPlanLimitModalContent";

interface Props {
  companyId: EntityId;
}

const NewFormBaseModalLimitContentContainer: FC<Props> = ({ companyId }) => {
  const isFormsManager = useAppSelector((state: RootState) => isCurrentUserCompanyFormsManager(state, companyId));

  return (
    <CompanySettingsPlanLimitModalContent
      companyId={companyId}
      isManager={isFormsManager}
      modalTextKey="FORMBUILDER.LIGHT_PLAN_LIMIT_MODAL.TEXT"
      lightBannerTextKey="FORMBUILDER.LIGHT_PLAN_LIMIT_MODAL.LIGHT_BODY"
      proBannerTextKey="FORMBUILDER.LIGHT_PLAN_LIMIT_MODAL.PRO_BODY"
    />
  );
};

export default NewFormBaseModalLimitContentContainer;
