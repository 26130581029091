import React, { FC } from "react";
import { FormNameOption, IFormBase } from "@/model/form/IFormBase";
import { saveCompanyFormBaseAsync, selectCompanyFormBaseById } from "@/app/store/form/formBasesSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import FormBaseNameContainer from "@components/form/base/body/formBaseInfo/FormBaseNameContainer";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { useTranslation } from "react-i18next";
import { getCurrentDateString } from "@/utilities/dateUtility";
import { formatStringForFormBaseName } from "@/utilities/jsUtilities";
import FormBaseInfoRow from "@components/form/base/body/formBaseInfo/FormBaseInfoRow";

interface Props {
  companyId: EntityId;
  formBaseId: EntityId;
}

const FormBaseInfoFileNameRow: FC<Props> = ({ companyId, formBaseId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formBase = useAppSelector((state) => selectCompanyFormBaseById(state, formBaseId));

  if (!formBase) return null;

  const formOptionExampleMap: Record<FormNameOption, string> = {
    DATE: getCurrentDateString(),
    NONE: "",
    ORDER_NUMBER: "1",
    ORDER_NUMBER_AND_DATE: "1_" + getCurrentDateString()
  };

  const saveFormName = (changedObject: IFormBase) => {
    if (!changedObject.formName || changedObject.formName.toLowerCase() === formBase.formName.toLowerCase()) {
      return;
    }
    const nameToSave = { formName: formatStringForFormBaseName(changedObject.formName) } as IFormBase;
    saveFormBase(nameToSave);
  };

  const saveFormNamePrefix = (changedObject: IFormBase) => {
    const suffixConflict =
      (changedObject.formNamePrefix === FormNameOption.ORDER_NUMBER && [FormNameOption.ORDER_NUMBER, FormNameOption.ORDER_NUMBER_AND_DATE].includes(formBase.formNameSuffix)) ||
      (changedObject.formNamePrefix === FormNameOption.DATE && [FormNameOption.DATE, FormNameOption.ORDER_NUMBER_AND_DATE].includes(formBase.formNameSuffix));

    const formBaseToSave = {
      ...formBase,
      ...changedObject,
      ...(suffixConflict && { formNameSuffix: FormNameOption.NONE })
    };

    saveFormBase(formBaseToSave);
  };

  const saveFormNameSuffix = (changedObject: IFormBase) => {
    const prefixConflict =
      (changedObject.formNameSuffix === FormNameOption.ORDER_NUMBER && formBase.formNamePrefix === FormNameOption.ORDER_NUMBER) ||
      (changedObject.formNameSuffix === FormNameOption.DATE && formBase.formNamePrefix === FormNameOption.DATE) ||
      (changedObject.formNameSuffix === FormNameOption.ORDER_NUMBER_AND_DATE && [FormNameOption.ORDER_NUMBER, FormNameOption.DATE].includes(formBase.formNamePrefix));

    const formBaseToSave = {
      ...formBase,
      ...changedObject,
      ...(prefixConflict && { formNamePrefix: FormNameOption.NONE })
    };

    saveFormBase(formBaseToSave);
  };

  const saveFormBase = (changedObject: IFormBase) => {
    const formBaseToSave = { ...formBase, ...changedObject };
    dispatch(saveCompanyFormBaseAsync({ companyId: companyId, formBase: formBaseToSave }));
  };

  return (
    <FormBaseInfoRow
      title={t("FORMBUILDER.BASE.HEADER.FILE_NAME")}
      helperText={t("FORMBUILDER.BASE.HEADER.FILE_DESCRIPTION")}
      children={
        <div className="flex w-full flex-col gap-y-4">
          <div className="flex w-full flex-row gap-x-2">
            <div className="w-1/4">
              <FormBaseNameContainer
                property={"formNamePrefix"}
                value={formBase.formNamePrefix === FormNameOption.NONE ? "" : t("FORM_BASE.NAME.OPTION." + formBase.formNamePrefix)}
                saveCallback={saveFormNamePrefix}
                label={t("FORMBUILDER.BASE.HEADER.FILE_PREFIX")}
                isPrefixInput={true}
              />
            </div>
            <div className="w-2/4">
              <BhInputStackedLabel initialValue={formBase.formName} property={"formName"} label={t("FORMBUILDER.BASE.HEADER.FILE_MAIN_PART")} onBlurAction={saveFormName} />
            </div>
            <div className="w-1/4">
              <FormBaseNameContainer
                property={"formNameSuffix"}
                value={formBase.formNameSuffix === FormNameOption.NONE ? "" : t("FORM_BASE.NAME.OPTION." + formBase.formNameSuffix)}
                saveCallback={saveFormNameSuffix}
                label={t("FORMBUILDER.BASE.HEADER.FILE_SUFFIX")}
                isPrefixInput={false}
              />
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-3">
            <div className="text-13px bh-text-deep-ocean-80 font-bold leading-4">{t("FORMBUILDER.BASE.HEADER.FILE_EXAMPLE")}</div>
            <div className="flex flex-row items-center gap-x-2">
              <BhFileCardIcon fileEntity={{ type: FileEntityType.FORM } as IFileEntity} fileCardSize={FileCardSize.MEDIUM} />
              <div>{formOptionExampleMap[formBase.formNamePrefix] + formBase.formName + formOptionExampleMap[formBase.formNameSuffix]}</div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default FormBaseInfoFileNameRow;
