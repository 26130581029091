import React, { FC, useEffect } from "react";
import BhUserIcon from "@components/user/BhUserIcon";
import { IPartyPrivilegeListItemDTO } from "@/model/party/IPartyPrivilegeListItemDTO";
import { ROLES } from "@/model/party/IRoleAuthName";
import { useTranslation } from "react-i18next";

interface Props {
  user: IPartyPrivilegeListItemDTO;
}

const PartyUserIconContainer: FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const role = ROLES.find((role) => {
    return role.documents === user.documents && role.files === user.files;
  }) || { name: "ROLE.CUSTOM" };

  useEffect(() => { });

  return (
    <div className="flex flex-row items-center whitespace-nowrap">
      <BhUserIcon user={user} />
      <div className="mx-2 ml-2">
        <div className="text-14px bh-text-deep-ocean">
          {user.firstName && user.lastName && <strong>{user.firstName + " " + user.lastName}</strong>}
          {!user.firstName && !user.lastName && <strong>{user.username}</strong>}
        </div>
        {user.firstName && user.lastName &&
          <span className="text-12px bh-text-deep-ocean-80">{user.username}</span>}
      </div>
    </div>
  );
};

export default PartyUserIconContainer;
