import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import FormBaseInfoFileNameRow from "@components/form/base/body/formBaseInfo/FormBaseInfoFileNameRow";
import FormBaseInfoBaseNameRow from "@components/form/base/body/formBaseInfo/FormBaseInfoBaseNameRow";
import FormBaseInfoPdfRow from "@components/form/base/body/formBaseInfo/FormBaseInfoPdfRow";

interface Props {
  companyId: EntityId;
  formBaseId: EntityId;
}

const FormBaseInfo: FC<Props> = ({ companyId, formBaseId }) => {
  return (
    <div className="flex w-full flex-col gap-y-10 border-b py-10 pr-44 pl-16 xl:pr-20 xl:pl-6">
      <FormBaseInfoBaseNameRow formBaseId={formBaseId} companyId={companyId} />
      <FormBaseInfoFileNameRow formBaseId={formBaseId} companyId={companyId} />
      <FormBaseInfoPdfRow formBaseId={formBaseId} companyId={companyId} />
    </div>
  );
};

export default FormBaseInfo;
