import React, { FC } from "react";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import ShareboxCard from "@/views/home/project/detail/shareboxes/ShareboxCard";
import { useAppSelector } from "@/app/hooks";
import { selectFilteredAndSortedShareboxIdsInSearch } from "@/app/store/searchSlice";
import SearchResultsShareboxFilterAndSort from "@/views/home/project/detail/search/shareboxResults/SearchResultsShareboxFilterAndSort";

const SearchResultsShareboxTab: FC = () => {
  const filteredAndSortedShareboxIds = useAppSelector(selectFilteredAndSortedShareboxIdsInSearch);

  if (!filteredAndSortedShareboxIds) return null;

  return (
    <BhScrollableBody>
      <div className="flex flex-col">
        <SearchResultsShareboxFilterAndSort />
        <div>
          {filteredAndSortedShareboxIds.map((shareboxId) => (
            <ShareboxCard key={shareboxId} shareboxId={shareboxId} userShareboxes={true} />
          ))}
        </div>
      </div>
    </BhScrollableBody>
  );
};

export default SearchResultsShareboxTab;
