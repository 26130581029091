import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { Trans } from "react-i18next";
import { selectFileById } from "@/app/store/filesSlice";
import { useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  fileId: EntityId;
}

const AuthoritiesModalHeader: FC<Props> = ({ fileId }) => {
  const file = useAppSelector((state) => selectFileById(state, fileId));

  if (!file) return null;

  return (
    <div className="flex items-center gap-4">
      <FontAwesomeIcon icon={faUsers} />
      <h2>
        <span className="bh-text-deep-ocean-60">
          <Trans>PARTIES.ACCESSES</Trans>
        </span>
        <span className="bh-text-deep-ocean-80"> - </span>
        <span className="bh-text-deep-ocean">{file.name}</span>
      </h2>
    </div>
  );
};

export default AuthoritiesModalHeader;
