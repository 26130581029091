import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { classNames } from "@/utilities/jsUtilities";
import AttachmentsContainerLarge from "@components/attachments/AttachmentsContainerLarge";
import { useTranslation } from "react-i18next";
import { useOuterClick } from "@/utilities/hooks/useOuterClick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { EntityId } from "@reduxjs/toolkit";
import { IFileEntity } from "@/model/files/IFileEntity";
import BhTextareaSimple from "@components/input/BhTextareaSimple";
import BhTooltip from "@components/BhTooltip";
import BhIconButton from "@components/buttons/BhIconButton";
import { faBold } from "@fortawesome/pro-solid-svg-icons/faBold";
import { faStrikethrough } from "@fortawesome/pro-regular-svg-icons/faStrikethrough";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhAttachmentsUploadContainer from "@components/upload/BhAttachmentsUploadContainer";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons/faPaperclip";

interface Props {
  initialValue: string;
  property: string;
  saveCallback: Function;
  placeholder?: string;
  disabled?: boolean;
  row: any;
  contentFormatSaveCallback: Function;
  isFormBase?: boolean;
  isHovered?: boolean;
}

const FormProtocolRowInput: FC<Props> = ({ initialValue, disabled, placeholder, property, saveCallback, row, contentFormatSaveCallback, isFormBase, isHovered }) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState<boolean>(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const outsideClickRef = useOuterClick(() => {
    setIsActive(false);
  });
  const hasAttachments = row.attachments && row.attachments.length > 0;

  useEffect(() => {
    if (isActive) inputRef && inputRef.current && inputRef.current.focus();
  }, [isActive]);

  const rowColorMap: Record<string, string> = {
    red: "bh-text-error-red",
    blue: "bh-text-mediterranean",
    green: "bh-text-lush",
    black: "bh-text-deep-ocean"
  };

  const availableColors = [
    { text: t("GLOBAL.COLOR.BLACK"), color: "black" },
    { text: t("GLOBAL.COLOR.RED"), color: "red" },
    { text: t("GLOBAL.COLOR.GREEN"), color: "green" },
    { text: t("GLOBAL.COLOR.BLUE"), color: "blue" }
  ];

  const colorDropdownValues = availableColors.map((colorObject) => {
    return {
      text: (
        <div className={classNames("flex flex-row items-center gap-x-3", rowColorMap[colorObject.color])}>
          <FontAwesomeIcon icon={faCircle} />
          <div>{colorObject.text}</div>
        </div>
      ),
      function: () => {
        contentFormatSaveCallback({ color: colorObject.color }, "color");
      }
    };
  });

  const removeFile = (fileId: EntityId) => {
    const attachmentsToSave = row.attachments.filter((att: any) => fileId !== att.id);
    const objToSave = { attachments: attachmentsToSave };
    contentFormatSaveCallback(objToSave, "attachments");
  };

  const addFiles = (changedValue: { attachments: Array<IFileEntity> }) => {
    const attachmentsToSave = [...(row.attachments || []), ...changedValue.attachments].map((val: any) => ({ ...val, selected: false }));
    const objToSave = { attachments: attachmentsToSave };
    contentFormatSaveCallback(objToSave, "attachments");
  };

  const onClick = useCallback(() => {
    if (disabled) return;
    setIsActive(true);
  }, [disabled]);

  return (
    <div
      className={classNames(
        "fake-input bh-border-pigeon-40 l-h-20 break-word mb-[4.5px] min-h-[36px] w-full cursor-text whitespace-pre-line rounded border ",
        !initialValue && placeholder && "bh-text-deep-ocean-40",
        disabled ? "bh-bg-smoke bh-border-smoke bh-text-deep-ocean-40" : "hover:bh-border-pigeon-70"
      )}
      onClick={onClick}
      ref={outsideClickRef}
    >
      <div className={classNames(!isActive ? "py-7px visible mx-3" : "invisible h-0")}>
        {!initialValue && <span className="bh-text-deep-ocean-40">{isHovered ? placeholder : "\u00A0"}</span>}
        {initialValue && (
          <span
            className={classNames(
              !disabled && row.color ? rowColorMap[row.color] : rowColorMap["black"],
              row.bold && "font-bold",
              row.strikethrough && "line-through",
              disabled && "bh-bg-smoke bh-border-smoke bh-text-deep-ocean-40"
            )}
          >
            {initialValue}
          </span>
        )}
      </div>
      {isActive && (
        <div className={classNames(isActive || hasAttachments ? "visible" : "invisible h-0")}>
          <BhTextareaSimple
            initialValue={initialValue}
            property={property}
            onBlurCallback={saveCallback}
            placeholder={placeholder}
            disabled={disabled}
            inputClasses={classNames("w-full border-0 !py-[6px]", row.color ? rowColorMap[row.color] : rowColorMap["black"], row.bold && "font-bold", row.strikethrough && "line-through")}
            inputRef={inputRef}
            autoFocus={true}
          />
        </div>
      )}
      {hasAttachments && (
        <div className="bh-text-black ml-2 font-normal">
          <AttachmentsContainerLarge attachments={row.attachments} removeCallback={removeFile} saveCallback={addFiles} property={"attachments"} />
        </div>
      )}
      {isActive && !disabled && (
        <div className="mb-1.5 -mt-1 flex flex-row">
          <BhTooltip body={t("GLOBAL.FONT.BOLD")}>
            <BhIconButton withBackground={row.bold} icon={faBold} buttonProps={{ onClick: () => contentFormatSaveCallback({ bold: !row.bold }, "bold") }} />
          </BhTooltip>
          <BhTooltip body={t("GLOBAL.FONT.STRIKETHROUGH")}>
            <BhIconButton
              withBackground={row.strikethrough}
              icon={faStrikethrough}
              buttonProps={{ onClick: () => contentFormatSaveCallback({ strikethrough: !row.strikethrough }, "strikethrough") }}
            />
          </BhTooltip>
          <BhTooltip body={t("GLOBAL.FONT.TEXT_COLOR")}>
            <BhDropdown
              menuClasses="whitespace-nowrap"
              button={<BhIconButton icon={faCircle} withBackground={row.color} />}
              menu={<BhDropdownMenu values={colorDropdownValues} textProperty="text" type={BhDropdownTypeEnum.STRING} widthClass="w-fit" closeOnItemClick={true} />}
              position={BhDropdownPositionEnum.BOTTOM_LEFT}
              disabled={disabled}
            />
          </BhTooltip>
          {!isFormBase && (
            <BhTooltip body={t("TASK.ATTACHMENTS")}>
              <div className="bh-text-black" onClick={(event: any) => event.stopPropagation()}>
                <BhAttachmentsUploadContainer
                  saveCallback={addFiles}
                  existingAttachments={row.attachments}
                  button={<BhIconButton icon={faPaperclip} buttonProps={{ disabled: disabled }} />}
                  property={"attachments"}
                  multiple={true}
                  disabled={disabled}
                  fileUploadAllowed={true}
                  chooseFromProjectAllowed={true}
                />
              </div>
            </BhTooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(FormProtocolRowInput);
