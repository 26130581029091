import React, { FC, ReactNode, useCallback } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { useDropzone } from "react-dropzone";
import { useAppSelector } from "@/app/hooks";
import { selectAllFilesForCurrentDirectory } from "@/app/store/filesSlice";
import { IFileEntity } from "@/model/files/IFileEntity";

interface Props {
  onDrop: (files: Array<any>, filesInDirectory: Array<IFileEntity>) => void;
  children: ReactNode;
}

export const BhDirectoryDropFileContainer: FC<Props> = ({ onDrop, children }) => {
  const filesInDirectory = useAppSelector(selectAllFilesForCurrentDirectory);

  const onDropCallback = useCallback(
    (acceptedFiles: any) => {
      onDrop(acceptedFiles, filesInDirectory);
    },
    [onDrop, filesInDirectory]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onDropCallback, noClick: true });

  return (
    <div {...getRootProps({})} className={classNames(isDragActive && "opacity-50", "h-full")}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export default BhDirectoryDropFileContainer;
