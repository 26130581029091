import React, { FC } from "react";
import BhCardContainer from "@components/cards/BhCardContainer";

const SignInvitesListItemSkeleton: FC = () => {
  return (
    <>
      <BhCardContainer>
        <div className="flex w-full flex-row items-center px-6 py-2">
          <div className="flex flex-1 flex-col">
            <div className="bh-bg-pigeon-50 mb-2 h-5 w-72 animate-pulse rounded" />
            <div className="bh-bg-pigeon-50 h-4 w-60 animate-pulse rounded" />
          </div>
          <div className="flex w-48 flex-col p-2">
            <div className="bh-bg-pigeon-50 mb-1.5 h-5 w-20 animate-pulse rounded" />
            <div className="bh-bg-pigeon-50 h-4 w-8 animate-pulse rounded" />
          </div>
          <div className="flex w-40" />
        </div>
      </BhCardContainer>
      <BhCardContainer>
        <div className="flex w-full flex-row items-center px-6 py-2">
          <div className="flex flex-1 flex-col">
            <div className="bh-bg-pigeon-50 mb-2 h-5 w-72 animate-pulse rounded" />
            <div className="bh-bg-pigeon-50 h-4 w-60 animate-pulse rounded" />
          </div>
          <div className="flex w-48 flex-col p-2">
            <div className="bh-bg-pigeon-50 mb-1.5 h-5 w-20 animate-pulse rounded" />
            <div className="bh-bg-pigeon-50 h-4 w-8 animate-pulse rounded" />
          </div>
          <div className="flex w-40" />
        </div>
      </BhCardContainer>
      <BhCardContainer>
        <div className="flex w-full flex-row items-center px-6 py-2">
          <div className="flex flex-1 flex-col">
            <div className="bh-bg-pigeon-50 mb-2 h-5 w-72 animate-pulse rounded" />
            <div className="bh-bg-pigeon-50 h-4 w-60 animate-pulse rounded" />
          </div>
          <div className="flex w-48 flex-col p-2">
            <div className="bh-bg-pigeon-50 mb-1.5 h-5 w-20 animate-pulse rounded" />
            <div className="bh-bg-pigeon-50 h-4 w-8 animate-pulse rounded" />
          </div>
          <div className="flex w-40" />
        </div>
      </BhCardContainer>
    </>
  );
};

export default SignInvitesListItemSkeleton;
