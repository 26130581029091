import { IUserAuthority, Resource } from "@/model/IUserAuthority";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import { IPartyPrivilegeListItemDTO } from "@/model/party/IPartyPrivilegeListItemDTO";
import { EntityId } from "@reduxjs/toolkit";
import { IContact } from "@/model/IContact";

export interface IUser {
  id: EntityId;
  username: string;
  firstName: string;
  lastName: string;
  language: string;
  role: string;
  identityCode: string;
  phoneNumber: string;
  twoFactorEnabled: boolean;
  userAvatarImage: string;
  userExists: boolean;
  projectAdmin: boolean;
  userAuthorities: Array<IUserAuthority>;
  workGroupToFileMap: Record<EntityId, Array<EntityId>>;
  privileges: Record<Resource, Record<EntityId, IAuthorityBoolean | Record<EntityId, IAuthorityBoolean>>>;
}

export function getUserFullName(user: IUser | ISimpleValidUserAuthority | ICompanyUser | IPartyPrivilegeListItemDTO | IContact) {
  return user.firstName + " " + user.lastName;
}

export interface IAuthorityBoolean {
  isRead: boolean;
  isWrite: boolean;
  isAdmin: boolean;
}

export function preparePrivileges(user: IUser) {
  // @ts-ignore
  user.privileges = {
    PROJECT: {},
    SHAREBOX: {},
    WORK_GROUP: {},
    COMPANY: {},
    MR_PROJECT_PM: {},
    MR_PROJECT_TEAM: {},
    MR_OSAKONNAJUHT: {},
    MR_FINANTSJUHT: {},
    PROJECT_LAWYER: {},
    TASK_BOARD: {},
    RT_ENGINEERING_PM: {},
    CHECKLIST: {}
  };

  user.userAuthorities.forEach((auth) => {
    if (!user.privileges[auth.resource]) {
      return;
    }

    if (auth.resource === "APP") {
      return;
    }

    if (auth.subResource) {
      if (!user.privileges[auth.resource][auth.resourceId]) {
        user.privileges[auth.resource][auth.resourceId] = {};
      }

      // @ts-ignore
      user.privileges[auth.resource][auth.resourceId][auth.subResource] = {
        isRead: auth.authority === "READ" || auth.authority === "WRITE" || auth.authority === "ADMIN",
        isWrite: auth.authority === "WRITE" || auth.authority === "ADMIN",
        isAdmin: auth.authority === "ADMIN"
      };
    } else {
      if (!user.privileges[auth.resource][auth.resourceId]) {
        user.privileges[auth.resource][auth.resourceId] = {};
      }
      user.privileges[auth.resource][auth.resourceId].isRead = auth.authority === "READ" || auth.authority === "WRITE" || auth.authority === "ADMIN";
      user.privileges[auth.resource][auth.resourceId].isWrite = auth.authority === "WRITE" || auth.authority === "ADMIN";
      user.privileges[auth.resource][auth.resourceId].isAdmin = auth.authority === "ADMIN";
    }
  });
}
