import React, { FC } from "react";
import { IBhButtonWithIconProps } from "@components/buttons/IBhButtonProps";
import BhButtonTemplateWithIcon from "@components/buttons/BhButtonTemplateWithIcon";
import { classNames } from "@/utilities/jsUtilities";

//FIGMA https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H/Bauhub-design-system?node-id=2080%3A112362

const BhSecondaryButton: FC<IBhButtonWithIconProps> = ({ buttonProps, icon, children }) => {
  buttonProps = buttonProps ?? {};
  buttonProps.classes += classNames(
    !buttonProps.disabled && " bh-bg-mint hover:bh-bg-mint-120 active:bh-bg-mint-130  ",
    buttonProps.disabled && " disabled:bh-bg-pigeon-20 disabled:bh-text-pigeon-60 ",
    " bh-secondary-button py-1.5 bh-text-deep-ocean min-w-70px "
  );

  return (
    <BhButtonTemplateWithIcon buttonProps={buttonProps} icon={icon}>
      {children}
    </BhButtonTemplateWithIcon>
  );
};

export default BhSecondaryButton;
