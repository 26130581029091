import React, { FC, useEffect, useState } from "react";
import { FileEntityBranch, FileEntityType, IFileCopyOrMoveDTO, IFileEntity, IFolderFileEntity } from "@/model/files/IFileEntity";
import { fileDraggedToDirectorySet, selectAllFilesSelected, selectFileDraggedToDirectory } from "@/app/store/filesSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import DirectorySelectionModal from "@/views/home/project/detail/directory/directoryModals/DirectorySelectionModal";
import { fetchDirectoryFiles } from "@/api/fileAPI";
import { EntityId } from "@reduxjs/toolkit";
import { dalevDistanceMatches } from "@/utilities/jsUtilities";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { selectCurrentProjectId, selectRootDirectoryId, selectRootDocumentDirectoryId } from "@/app/store/project/projectSlice";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import { isDateToday } from "@/utilities/dateUtility";

interface Props {
  setIsShown: (value: boolean) => void;
  setFilesWithRevisionsAndMatchingName: (value: Array<IFileCopyOrMoveDTO>) => void;
  setDuplicateDirectories: (value: Array<IFileCopyOrMoveDTO>) => void;
  setSimilarFiles: (value: Array<IFileCopyOrMoveDTO>) => void;
  setRemainingFiles: (value: Array<IFileCopyOrMoveDTO>) => void;
  setCopyRevisions: (value: boolean) => void;
  onClose: Function;
  isMoveModal: boolean;
  currentDirectoryId: EntityId;
}

const CopyAndMoveDirectorySelectionModal: FC<Props> = ({
  setIsShown,
  setFilesWithRevisionsAndMatchingName,
  setDuplicateDirectories,
  setSimilarFiles,
  setRemainingFiles,
  setCopyRevisions,
  onClose,
  isMoveModal,
  currentDirectoryId
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const selectedFilesToCopyOrMove = useAppSelector(selectAllFilesSelected);
  const fileRootDirectoryId = useAppSelector(selectRootDirectoryId);
  const documentRootDirectoryId = useAppSelector(selectRootDocumentDirectoryId);
  const fileDraggedToDirectory = useAppSelector(selectFileDraggedToDirectory);
  const [directorySelectionModalIds, setDirectorySelectionModalIds] = useState<Array<EntityId>>([currentDirectoryId, fileRootDirectoryId, documentRootDirectoryId]);
  const [preSelectedDirectoryIds, setPreSelectedDirectoryIds] = useState<Array<EntityId>>([]);
  const [recentActionsMap, setRecentActionsMap] = useLocalStorage<{ [projectId: EntityId]: any }>(isMoveModal ? "recentMoveActionMap" : "recentCopyActionMap", {});

  // IF you drag some file to other folder then do not show directory selection modal
  useEffect(() => {
    if (fileDraggedToDirectory) {
      const destination = [{ ...fileDraggedToDirectory } as IFolderFileEntity];
      handleSelectedDirectories(destination, true);
    }
  }, [fileDraggedToDirectory]);

  useEffect(() => {
    return function cleanup() {
      dispatch(fileDraggedToDirectorySet(undefined));
    };
  }, []);

  const closeModal = (
    filesWithRevisionsAndMatchingName: Array<IFileCopyOrMoveDTO>,
    duplicateDirectories: Array<IFileCopyOrMoveDTO>,
    similarFiles: Array<IFileCopyOrMoveDTO>,
    remainingFiles: Array<IFileCopyOrMoveDTO>,
    copyRevisions: boolean
  ) => {
    setIsShown(false);
    setFilesWithRevisionsAndMatchingName(filesWithRevisionsAndMatchingName);
    setDuplicateDirectories(duplicateDirectories);
    setSimilarFiles(similarFiles);
    setRemainingFiles(remainingFiles);
    setCopyRevisions(copyRevisions);
  };

  const handleSelectedDirectories = (destinationDirectories: Array<IFolderFileEntity>, copyRevisions: boolean) => {
    const errorsExisting = checkForErrors(destinationDirectories);
    if (errorsExisting) {
      setIsShown(false);
      return;
    }

    // Find and map files in destination directories (map by directory id)
    const destinationDirectoryIds = destinationDirectories.map((dir) => dir.id);
    fetchDirectoryFiles(destinationDirectoryIds).then((filesInDestinations) => {
      let dirIdToFilesMap: Record<EntityId, Array<IFileEntity>> = {};
      filesInDestinations.forEach((file) => {
        if (!dirIdToFilesMap[file.parentFileEntityId]) {
          dirIdToFilesMap[file.parentFileEntityId] = [];
        }
        dirIdToFilesMap[file.parentFileEntityId].push(file);
      });

      let filesWithRevisionsAndMatchingName = [] as Array<IFileCopyOrMoveDTO>;
      let duplicateDirectories = [] as Array<IFileCopyOrMoveDTO>;
      let similarFiles = [] as Array<IFileCopyOrMoveDTO>;
      let remainingFiles = [] as Array<IFileCopyOrMoveDTO>;
      // Loop every destination directory and check if any special cases apply
      destinationDirectories.forEach((destinationDirectory) => {
        let filesToCopyOrMove = selectedFilesToCopyOrMove.map((file) => file);
        const filesInDestination = dirIdToFilesMap[destinationDirectory.id] || [];
        // Check if any of the selected files has revisions and if any destination file is with same name as the selected file
        checkForFilesWithRevisionsAndMatchingNames(filesToCopyOrMove, filesInDestination, destinationDirectory, filesWithRevisionsAndMatchingName);
        // Check if any of the selected folders has duplicate in destination
        checkForDuplicateDirectoryNames(filesToCopyOrMove, filesInDestination, destinationDirectory, duplicateDirectories);
        // Check if any of the selected files doesn't have a revision, but is similar to file in destination
        checkForSimilarFiles(filesToCopyOrMove, filesInDestination, destinationDirectory, similarFiles);
        // Handle remaining selected files
        handleRemainingFiles(filesToCopyOrMove, destinationDirectory, remainingFiles);
      });

      closeModal(filesWithRevisionsAndMatchingName, duplicateDirectories, similarFiles, remainingFiles, copyRevisions);
    });

    // Save directory ids to localstorage for recent action usage
    const localStorageObject = { ...recentActionsMap, [projectId]: { dirId: destinationDirectoryIds, time: new Date().toString() } };
    setRecentActionsMap(localStorageObject);
  };

  const handleRemainingFiles = (filesToCopyOrMove: Array<IFileEntity>, destinationDirectory: IFileEntity, remainingFiles: Array<IFileCopyOrMoveDTO>) => {
    const files = filesToCopyOrMove.map((f) => f);
    files.forEach((file) => {
      const remainingFileDTO = { fileToCopyOrMove: file, destinationDirectory: destinationDirectory } as IFileCopyOrMoveDTO;
      remainingFiles.push(remainingFileDTO);
      filesToCopyOrMove.splice(filesToCopyOrMove.indexOf(file), 1);
    });
  };

  const checkForSimilarFiles = (filesToCopyOrMove: Array<IFileEntity>, filesInDestination: Array<IFileEntity>, destinationDirectory: IFileEntity, similarFiles: Array<IFileCopyOrMoveDTO>) => {
    const filesOnly = filesToCopyOrMove.filter((file) => file.type !== FileEntityType.DIR);
    if (filesOnly.length > 0) {
      const allFilesWithoutRevisions = filesOnly.every((file) => file.revision === 1);
      const destinationFilesOnly = filesInDestination.filter((file) => file.type !== FileEntityType.DIR);
      if (allFilesWithoutRevisions && destinationFilesOnly.length > 0) {
        const anyMatchingFileWithDalevDistanceInDestination = filesOnly.some((file) => {
          return destinationFilesOnly.some((destinationFile) => {
            return dalevDistanceMatches(destinationFile.name, file.name, 9);
          });
        });
        if (anyMatchingFileWithDalevDistanceInDestination) {
          filesOnly.forEach((file) => {
            const fileIsMovedToSameFolderWhereItAlreadyIs = isMoveModal && destinationFilesOnly.some((destinationFile) => file.id === destinationFile.id);
            if (!fileIsMovedToSameFolderWhereItAlreadyIs) {
              const similarFileDTO = { fileToCopyOrMove: file, destinationDirectory: destinationDirectory } as IFileCopyOrMoveDTO;
              similarFiles.push(similarFileDTO);
              filesToCopyOrMove.splice(filesToCopyOrMove.indexOf(file), 1);
            }
          });
        }
      }
    }
  };

  const checkForFilesWithRevisionsAndMatchingNames = (
    filesToCopyOrMove: Array<IFileEntity>,
    filesInDestination: Array<IFileEntity>,
    destinationDirectory: IFileEntity,
    revisionFiles: Array<IFileCopyOrMoveDTO>
  ) => {
    const filesToCopyOrMoveWithMoreThanOneRevision = filesToCopyOrMove.filter((file) => {
      return file.type !== FileEntityType.DIR && file.revision > 1;
    });
    if (filesToCopyOrMoveWithMoreThanOneRevision.length > 0) {
      const revisionFilesWithMatchingDestinationFile = filesToCopyOrMoveWithMoreThanOneRevision.filter((revisionFile) => {
        return filesInDestination.some((f) => {
          return f.name === revisionFile.name;
        });
      });
      if (revisionFilesWithMatchingDestinationFile.length > 0) {
        revisionFilesWithMatchingDestinationFile.forEach((revisionFile) => {
          const fileIsMovedToSameFolderWhereItAlreadyIs = isMoveModal && filesInDestination.some((destinationFile) => revisionFile.id === destinationFile.id);
          if (!fileIsMovedToSameFolderWhereItAlreadyIs) {
            const matchingFile = filesInDestination.find((destinationFile) => {
              return revisionFile.name.toLowerCase() === destinationFile.name.toLowerCase();
            });
            if (matchingFile) {
              const revisionFileDTO = { fileToCopyOrMove: revisionFile, destinationDirectory: destinationDirectory, matchingFile: matchingFile } as IFileCopyOrMoveDTO;
              revisionFiles.push(revisionFileDTO);
              filesToCopyOrMove.splice(filesToCopyOrMove.indexOf(revisionFile), 1);
            }
          }
        });
      }
    }
  };

  const checkForDuplicateDirectoryNames = (
    filesToCopyOrMove: Array<IFileEntity>,
    filesInDestination: Array<IFileEntity>,
    destinationDirectory: IFileEntity,
    duplicateDirectories: Array<IFileCopyOrMoveDTO>
  ) => {
    const directoriesInDestination = filesInDestination.filter((file) => file.type === FileEntityType.DIR);
    if (directoriesInDestination.length > 0) {
      const matchingDirectoriesToCopyOrMove = filesToCopyOrMove.filter((file) => {
        return (
          file.type === FileEntityType.DIR &&
          directoriesInDestination.some((dir) => {
            return dir.name.toLowerCase() === file.name.toLowerCase();
          })
        );
      });
      if (matchingDirectoriesToCopyOrMove.length > 0) {
        matchingDirectoriesToCopyOrMove.forEach((dir) => {
          const matchingDir = directoriesInDestination.find((destinationFile) => {
            return dir.name.toLowerCase() === destinationFile.name.toLowerCase();
          });

          if (matchingDir) {
            const duplicateDirectoryDTO = { fileToCopyOrMove: dir, destinationDirectory: destinationDirectory, matchingFile: matchingDir } as IFileCopyOrMoveDTO;
            duplicateDirectories.push(duplicateDirectoryDTO);
            filesToCopyOrMove.splice(filesToCopyOrMove.indexOf(dir), 1);
          }
        });
      }
    }
  };

  const checkForErrors = (destinationDirectories: Array<IFolderFileEntity>) => {
    let errorsExisting = false;
    let toastText = "";
    const anyFormCopiedOrMovedToFiles = selectedFilesToCopyOrMove.some((file) => file.type === FileEntityType.FORM) && destinationDirectories.some((dir) => dir.branch === FileEntityBranch.ROOT_DIR);
    if (anyFormCopiedOrMovedToFiles) {
      toastText = isMoveModal ? t("FORM.MOVE_TO_FILES.ERROR") : t("FORM.COPY_TO_FILES.ERROR");
      errorsExisting = true;
    }
    const anyDirectoryCopiedOrMovedInsideItself = selectedFilesToCopyOrMove
      .filter((file) => file.type === FileEntityType.DIR)
      .some((dir) => {
        return destinationDirectories.some((destinationDir) => {
          return dir.id === destinationDir.id;
        });
      });
    if (anyDirectoryCopiedOrMovedInsideItself) {
      toastText = isMoveModal ? t("FOLDER.MOVE_INSIDE_ITSELF.ERROR") : t("FOLDER.COPY_INSIDE_ITSELF.ERROR");
      errorsExisting = true;
    }
    if (errorsExisting) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          children: toastText
        })
      );
    }
    return errorsExisting;
  };

  if (fileDraggedToDirectory) {
    return null;
  }

  const isAnyRecentActionDoneToday = () => {
    const recentActionInProject = recentActionsMap[projectId];
    if (recentActionInProject) {
      return isDateToday(new Date(recentActionInProject.time));
    }
    return false;
  };

  const selectRecentFolders = () => {
    if (isAnyRecentActionDoneToday()) {
      const actionIds = recentActionsMap[projectId].dirId;
      setDirectorySelectionModalIds([...directorySelectionModalIds, ...actionIds]);
      setPreSelectedDirectoryIds([...preSelectedDirectoryIds, ...actionIds]);
    }
  };

  return (
    <DirectorySelectionModal
      directoryIds={directorySelectionModalIds}
      modalHeader={isMoveModal ? t("FILE.MOVE.MODAL.HEADER") : t("FILE.COPY.MODAL.HEADER")}
      onModalSubmit={handleSelectedDirectories}
      onModalClose={onClose}
      selectOnlyOne={isMoveModal}
      isCopyModal={!isMoveModal}
      isMoveModal={isMoveModal}
      haveToSelectAtLeastOne={true}
      recentActionCallback={isAnyRecentActionDoneToday() ? selectRecentFolders : undefined}
      preSelectedDirectoryIds={preSelectedDirectoryIds}
    />
  );
};

export default CopyAndMoveDirectorySelectionModal;
