import React, { FC, ReactElement } from "react";
import { FileEntityConfirmationStatus } from "@/model/files/IFileEntity";
import BhStatusCellApproved from "@components/status/BhStatusCellApproved";
import BhStatusCellDenied from "@components/status/BhStatusCellDenied";
import BhStatusCellProgress, { BhStatusCellProgressColor } from "@components/status/BhStatusCellProgress";
import BhDropdown from "@components/dropdown/BhDropdown";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhFileConfirmationStatusDropdown from "@components/confirmation/BhFileConfirmationStatusDropdown";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  fileEntityId: EntityId;
  status: FileEntityConfirmationStatus;
  confirmationUserCount: number;
  confirmedCount: number;
  dropdownPosition?: BhDropdownPositionEnum;
}

const BhFileConfirmationStatus: FC<Props> = ({ fileEntityId, status, confirmationUserCount, confirmedCount, dropdownPosition }) => {
  if (!status) {
    return null;
  }

  const elementMap: Record<FileEntityConfirmationStatus, ReactElement> = {
    [FileEntityConfirmationStatus.CONFIRMED]: <BhStatusCellApproved />,
    [FileEntityConfirmationStatus.DECLINED]: <BhStatusCellDenied />,
    [FileEntityConfirmationStatus.PENDING]: <BhStatusCellProgress progress={confirmedCount} total={confirmationUserCount} color={BhStatusCellProgressColor.GREEN} />
  };

  return (
    <div className="cursor-pointer">
      <BhDropdown
        position={dropdownPosition ? dropdownPosition : BhDropdownPositionEnum.BOTTOM_LEFT}
        button={elementMap[status]}
        menu={<BhFileConfirmationStatusDropdown fileEntityId={fileEntityId} />}
      />
    </div>
  );
};

export default BhFileConfirmationStatus;
