import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from "@fortawesome/pro-solid-svg-icons/faSquareCheck";
import { faSquare } from "@fortawesome/pro-regular-svg-icons/faSquare";
import { LoadedObjectInfo } from "@/views/home/project/detail/xeokit/XeokitWebViewer";
import { Trans } from "react-i18next";

interface Props {
  loadedModels: Array<LoadedObjectInfo>;
  toggleModelVisible: Function;
  toggleAllModelsVisible: Function;
}

const XeokitSidebarMenuModelsTreeView: FC<Props> = ({ loadedModels, toggleModelVisible, toggleAllModelsVisible }) => {
  const allVisible = loadedModels.every((model) => model.visible);

  return (
    <div>
      <div className="flex cursor-pointer flex-row gap-x-1 py-[3px] px-2" onClick={() => toggleAllModelsVisible(!allVisible)}>
        <FontAwesomeIcon className="bh-text-bauhub-green-120 py-0.25 text-lg" icon={allVisible ? faSquareCheck : faSquare} />
        <div>
          <Trans>BIM.SIDEBAR.SELECT_ALL</Trans>
        </div>
      </div>
      {loadedModels.map((model) => {
        return (
          <div key={model.id} className="flex cursor-pointer flex-row gap-x-1 py-[3px] px-2" onClick={() => toggleModelVisible(model)}>
            <FontAwesomeIcon className="bh-text-bauhub-green-120 py-0.25 text-lg" icon={model.visible ? faSquareCheck : faSquare} />
            <div>{model.name}</div>
          </div>
        );
      })}
    </div>
  );
};

export default XeokitSidebarMenuModelsTreeView;
