import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  isSelected: boolean;
  onSelect?: Function;
  sizeClasses?: string;
}

const BhRadioButtonWithCheckmark: FC<Props> = ({ isSelected, onSelect, sizeClasses }) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onSelect && onSelect(e);
  };

  return (
    <button
      onClick={(e) => handleClick(e)}
      className={classNames(isSelected ? "bh-bg-bauhub-green-120" : "bh-border-pigeon-60 border", sizeClasses || "h-5 w-5", "flex items-center justify-center rounded-full border")}
    >
      {isSelected && <FontAwesomeIcon className="bh-text-white relative" icon={faCheck} size="sm" />}
    </button>
  );
};

export default BhRadioButtonWithCheckmark;
