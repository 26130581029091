import React, { ChangeEvent, FC, useId } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentDirId } from "@/app/store/filesSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { uploadFilesAsync } from "@/app/store/uploadSlice";
import { EntityId } from "@reduxjs/toolkit";
import { IFileEntity } from "@/model/files/IFileEntity";
import { canOpenInXeokit } from "@/utilities/fileEntity/fileEntityUtilities";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";

interface Props {
  fileInputRef: React.LegacyRef<HTMLInputElement>;
}

const BimDirectoryFileUpload: FC<Props> = ({ fileInputRef }) => {
  const id = useId();
  const currentDirId = useAppSelector(selectCurrentDirId);
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();

  const onFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    let fileArray = [];
    for (let i = 0; i < files.length; ++i) {
      fileArray.push(files[i]);
    }

    const allFilesSupportedByXeokit = fileArray
      .map((file) => {
        return { name: file.name } as IFileEntity;
      })
      .every((f) => canOpenInXeokit(f));
    if (!allFilesSupportedByXeokit) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          translateCode: "BIM.FILES.NOT_SUPPORTED"
        })
      );
      return;
    }

    if (currentDirId) {
      const fileUploadDTO: Array<{ file: any; directoryToUploadId: EntityId; projectId: EntityId }> = fileArray.map((file) => ({
        file: file,
        directoryToUploadId: currentDirId,
        projectId: projectId
      }));
      dispatch(uploadFilesAsync(fileUploadDTO));
    }

    event.target.value = "";
  };

  return <input type="file" id={id} ref={fileInputRef} style={{ display: "none" }} multiple={true} accept=".ifc,.ifczip" onChange={onFileSelect} />;
};

export default BimDirectoryFileUpload;
