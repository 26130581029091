import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  fileEntitySelected,
  onlyThisFileEntitySelected,
  selectAllRevisionFileEntityIdsForFile,
  selectFileById,
  selectFilesSortDateField,
  setDeleteConfirmationModalFileEntityIds,
  setDirectoryModalsOpen,
  tagToggledInFilesFilter
} from "@/app/store/filesSlice";
import { classNames } from "@/utilities/jsUtilities";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import FileNameLink from "@/features/fileContainer/FileNameLink";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { EntityId } from "@reduxjs/toolkit";
import BhFileCardDateWithName from "@components/cards/BhFileCardDateWithName";
import { shallowEqual } from "react-redux";
import BhFileCardRevisionNumber from "@components/cards/BhFileCardRevisionNumber";
import { IAuthorityBoolean } from "@/model/IUser";
import { isDirectory } from "@/utilities/fileEntity/fileEntityUtilities";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import { fetchUrlForFile } from "@/api/fileAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import FileContainerIconDraggable from "@/features/fileContainer/FileContainerIconDraggable";
import RevisionFileContainerIcon from "@/features/fileContainer/RevisionFileContainerIcon";
import FilePendingConversionContainer from "../FilePendingConversionContainer";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { IfcConversionStatus, IfcConversionStatusDTO } from "@/model/files/IIfcConversionStatusDTO";
import FileContainerDropdown from "@/features/fileContainer/FileContainerDropdown";
import { selectCurrentUser } from "@/app/store/userSlice";

interface Props {
  fileId: EntityId;
  privilegesForDirectory: IAuthorityBoolean;
  isRevision?: boolean;
  highlighted?: boolean;
}

const BimFileContainerFC: FC<Props> = ({ fileId, isRevision, privilegesForDirectory, highlighted }) => {
  const fileEntity = useAppSelector((state) => selectFileById(state, fileId));
  const currentUser = useAppSelector(selectCurrentUser);
  const revisionFileEntityIds = useAppSelector((state) => selectAllRevisionFileEntityIdsForFile(state, fileId), shallowEqual);
  const [revisionsShown, setRevisionShown] = useState(false);
  const [conversionStatus, setConversionStatus] = useState<IfcConversionStatusDTO>({} as IfcConversionStatusDTO);
  const dispatch = useAppDispatch();
  const filesSortDateField = useAppSelector(selectFilesSortDateField);

  const isFolder = fileEntity && isDirectory(fileEntity);

  const handleChange = (id: EntityId, e: { target: { checked: any } }) => {
    if (!privilegesForDirectory.isRead) return;
    if (isRevision) return;
    dispatch(fileEntitySelected({ ids: [id], selected: e.target.checked }));
  };

  const selectCurrentRowIfIsRead = (id: EntityId, e: React.MouseEvent<HTMLDivElement>) => {
    if (!privilegesForDirectory.isRead) return;
    if (isRevision) return;
    e.stopPropagation();

    if (e.shiftKey) {
      dispatch(fileEntitySelected({ ids: [id], selected: !fileEntity?.selected }));
      let sel = window.getSelection();
      sel?.removeAllRanges();
      return;
    }

    dispatch(onlyThisFileEntitySelected({ id: id }));
  };

  const downloadFile = () => {
    if (fileEntity) {
      fetchUrlForFile(fileEntity.id, false, true, fileEntity.uuid).then((presignedUrl) => {
        imitateUrlDownload(presignedUrl.value);
      });
    }
  };

  const deleteFile = () => {
    if (fileEntity) {
      dispatch(setDeleteConfirmationModalFileEntityIds([fileEntity.id]));
      dispatch(setDirectoryModalsOpen({ modal: "deleteConfirmationModal", value: true }));
    }
  };

  if (!fileEntity) {
    return null;
  }

  // DO NOT CHANGE THE ELEMENT HEIGHT IN ANY CIRCUMSTANCE
  return (
    <>
      <div
        className={classNames(
          fileEntity.selected ? "bh-bg-mint" : conversionStatus?.status === IfcConversionStatus.RUNNING ? "bh-bg-smoke-50" : highlighted ? "bh-bg-warning-yellow-10" : "bh-bg-white",
          isRevision ? "bh-bg-smoke-50 ml-8" : !fileEntity.selected && "hover:bh-bg-mint-30",
          "bh-border-pigeon-40 bh-hover-container group relative block flex h-14 items-center border-b"
        )}
        onClick={(e) => {
          if (conversionStatus?.status === IfcConversionStatus.RUNNING) return;
          selectCurrentRowIfIsRead(fileEntity.id, e);
        }}
      >
        <div className={classNames(fileEntity.selected && "bh-bg-bauhub-green-120", "h-full w-0.5")} />
        {!isRevision && (
          <div className="flex h-full w-8 flex-none items-center justify-center" onClick={(e) => e.stopPropagation()}>
            {privilegesForDirectory.isRead && (
              <input
                className="hover:bh-border-deep-ocean bh-border-pigeon-60 h-5 w-5 rounded focus:ring-0 focus:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50"
                type="checkbox"
                checked={fileEntity.selected || false}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => handleChange(fileEntity.id, e)}
                disabled={conversionStatus?.status === IfcConversionStatus.RUNNING}
              />
            )}
          </div>
        )}
        {!isRevision && (
          <div className={classNames(conversionStatus?.status === IfcConversionStatus.RUNNING && "opacity-50")}>
            <FileContainerIconDraggable
              fileEntity={fileEntity}
              privilegesForDirectory={privilegesForDirectory}
              setRevisionShown={setRevisionShown}
              disableDragging={conversionStatus?.status === IfcConversionStatus.RUNNING}
            />
          </div>
        )}
        {isRevision && <RevisionFileContainerIcon fileEntity={fileEntity} />}
        <div className="min-w-24 flex h-11 w-full flex-grow flex-row items-center overflow-hidden px-2">
          <div className="flex w-full flex-col justify-center overflow-hidden">
            <div className={classNames("flex w-full flex-row items-center justify-between", conversionStatus?.status === IfcConversionStatus.RUNNING && "opacity-50")}>
              <FileNameLink
                fileEntity={fileEntity}
                onFileNameClickCallback={downloadFile}
                currentState={"directoryView"}
                disableOnClickHandler={conversionStatus?.status === IfcConversionStatus.RUNNING}
              />
            </div>
            <div className="flex flex-row overflow-hidden">
              {fileEntity.tags &&
                fileEntity.tags.slice(0, 5).map((tag) => {
                  return (
                    <BhTag key={tag.id} type={BhTagType.TAG} onClickCallback={() => dispatch(tagToggledInFilesFilter(tag))}>
                      {tag.text}
                    </BhTag>
                  );
                })}
              {fileEntity.tags?.length > 5 && (
                <BhTag key="unique-over-five-tags" type={BhTagType.TAG}>
                  +{fileEntity.tags?.length - 5}
                </BhTag>
              )}
            </div>
          </div>
        </div>
        <FilePendingConversionContainer fileEntity={fileEntity} conversionStatus={conversionStatus} setConversionStatus={setConversionStatus}></FilePendingConversionContainer>
        <div className={classNames("w-18 flex-none px-1 text-center sm:hidden", conversionStatus?.status === IfcConversionStatus.RUNNING && "opacity-50")}>
          {privilegesForDirectory.isRead && (
            <BhFileCardRevisionNumber
              fileId={fileId}
              canUploadNewVersion={privilegesForDirectory.isWrite && fileEntity.type !== FileEntityType.FORM && !isRevision && conversionStatus?.status !== IfcConversionStatus.RUNNING}
              revision={fileEntity.revision}
              isRevision={isRevision}
              revisionsShown={revisionsShown}
              setRevisionShown={setRevisionShown}
              isFolder={isFolder}
            />
          )}
        </div>
        <div className={classNames("w-60 flex-none px-3 lg:hidden", conversionStatus?.status === IfcConversionStatus.RUNNING && "opacity-50")}>
          {!isFolder && <BhFileCardDateWithName fileDate={fileEntity[filesSortDateField]} fullName={fileEntity[(filesSortDateField + "ByFullName") as keyof IFileEntity] as string} />}
        </div>
        {privilegesForDirectory.isRead && (
          <div className="w-15 -left-1 flex flex-none flex-row justify-end gap-x-1 p-1" onClick={(e: any) => e.stopPropagation()}>
            {conversionStatus?.status !== IfcConversionStatus.RUNNING && (
              <>
                <div className="flex flex-row items-center gap-x-1 lg:hidden">
                  <BhIconButton
                    icon={faArrowDownToLine}
                    buttonProps={{
                      classes: "hidden group-hover:block",
                      onClick: downloadFile
                    }}
                  />
                  {!isRevision && (privilegesForDirectory.isAdmin || fileEntity.createdBy === currentUser.username) && (
                    <BhIconButton
                      icon={faTrash}
                      buttonProps={{
                        classes: "hidden group-hover:block",
                        onClick: deleteFile
                      }}
                    />
                  )}
                </div>
                <div className="w-10">
                  <FileContainerDropdown fileEntity={fileEntity} isRevision={isRevision} privilegesForDirectory={privilegesForDirectory} />
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {revisionsShown &&
        revisionFileEntityIds.length > 0 &&
        revisionFileEntityIds.map((revisionFileEntityId) => (
          <BimFileContainerFC fileId={revisionFileEntityId} privilegesForDirectory={privilegesForDirectory} key={revisionFileEntityId} isRevision={true} />
        ))}
    </>
  );
};

const FileContainer = React.memo(BimFileContainerFC);
export default FileContainer;
