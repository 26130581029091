import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { Link } from "react-router-dom";
import ConfirmationsListItemBody from "@/views/home/project/detail/confirmations/list/ConfirmationsListItemBody";
import ConfirmationsListItemApprovalFooter from "@/views/home/project/detail/confirmations/list/ConfirmationsListItemApprovalFooter";
import { IFileConfirmation } from "@/model/confirmations/IFileConfirmation";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  fileConfirmation: IFileConfirmation;
}

const ConfirmationsListItemNeedsApproval: FC<Props> = ({ fileConfirmation }) => {
  const projectId = useAppSelector(selectCurrentProjectId);

  return (
    <div className="bh-border-dark-jungle hover:bh-bg-mint-30 my-1 w-full overflow-hidden rounded-lg border-2">
      <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/confirmation/${fileConfirmation.id}`}>
        <div className="flex flex-col">
          <ConfirmationsListItemBody fileConfirmation={fileConfirmation} />
          <ConfirmationsListItemApprovalFooter fileConfirmation={fileConfirmation} />
        </div>
      </Link>
    </div>
  );
};

export default ConfirmationsListItemNeedsApproval;
