import React, { FC, useEffect } from "react";
import ContactsContainer from "@/views/home/project/detail/users/contacts/ContactsContainer";
import PartiesContainer from "@/views/home/project/detail/users/parties/PartiesContainer";
import BhTabs from "@components/tabs/BhTabs";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useLocation } from "react-router-dom";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import RequireProjectAdminAuth from "@/views/authentication/RequireProjectAdminAuth";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useTranslation } from "react-i18next";
import { ITabArrayElement } from "@/model/tabs/ITabArrayElement";

interface Props {}

const Users: FC<Props> = () => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(setSidebarCollapsed(false));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.USERS));
  }, [location]);

  const tabs = [
    isProjectAdmin && {
      id: 0,
      header: t("GLOBAL.WORKGROUPS"),
      content: (
        <RequireProjectAdminAuth>
          <PartiesContainer />
        </RequireProjectAdminAuth>
      ),
      href: `parties`
    },
    {
      id: 1,
      header: t("PROJECT.CONTACTS"),
      content: <ContactsContainer />,
      href: `contacts`
    }
  ].filter(Boolean) as Array<ITabArrayElement>;

  const initialActiveTab = isProjectAdmin ? 0 : 1;

  return <BhTabs tabs={tabs} router={true} initialActiveTab={initialActiveTab} />;
};

export default Users;
