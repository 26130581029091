import React, { FC } from "react";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { Trans } from "react-i18next";
import BhLargeText from "@components/text/BhLargeText";
import BhProPlanTag from "@components/tags/BhProPlanTag";

interface Props {
  imageSource: string;
  headerText: string;
  bodyText: string;
}

const NewProjectProFeaturesUpsellView: FC<Props> = ({ imageSource, headerText, bodyText }) => {
  return (
    <BhScrollableBody>
      <div className="mt-14 flex flex-row items-center md:flex-col">
        <img src={imageSource} className="m-8 max-w-[140px] place-self-center" alt="No matches" />
        <div className="flex flex-col">
          <div className=" -ml-2 flex flex-row items-center gap-2">
            <BhProPlanTag />
            <span>
              <Trans>NEW_PROJECT.PROJECT_TREES.GET_PRO_TAG_BODY</Trans>
            </span>
          </div>
          <span className="mt-4 text-2xl font-bold">{headerText}</span>
          <BhLargeText classes="mt-4">
            <span dangerouslySetInnerHTML={{ __html: bodyText }} />
          </BhLargeText>
        </div>
      </div>
    </BhScrollableBody>
  );
};

export default NewProjectProFeaturesUpsellView;
