import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { fetchNewProjectDefaultParties } from "@/api/companyAPI";
import BhSelectCard from "@components/cards/BhSelectCard";
import BhUserIconGrouped from "@components/user/BhUserIconGrouped";
import BhSearchInput from "@components/input/BhSearchInput";
import partyHelmets from "@img/eelseadistatud-osapooled-compressed.png";
import { EntityId } from "@reduxjs/toolkit";
import { INewProjectForm } from "@/model/INewProjectForm";
import { Trans, useTranslation } from "react-i18next";
import PartyNameContainer from "@/views/home/company/createProject/newProjectPredefinedParties/PartyNameContainer";
import { IPredefinedWorkGroup } from "@/model/IPredefinedWorkGroup";
import NewProjectProFeaturesUpsellView from "@/views/home/company/createProject/NewProjectProFeaturesUpsellView";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { BauhubPlan } from "@/model/IProject";
import NewProjectPredefinedPartiesNoMatches from "@/views/home/company/createProject/newProjectPredefinedParties/NewProjectPredefinedPartiesNoMatches";
import { CompanyStatus } from "@/model/ICompany";

interface Props {
  newProjectForm: INewProjectForm;
  setNewProjectForm: Dispatch<SetStateAction<INewProjectForm>>;
  companyId: EntityId;
}

const NewProjectPredefinedParties: FC<Props> = ({ newProjectForm, setNewProjectForm, companyId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [parties, setParties] = useState<Array<IPredefinedWorkGroup>>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [filteredParties, setFilteredParties] = useState<Array<IPredefinedWorkGroup>>([]);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));

  useEffect(() => {
    fetchNewProjectDefaultParties(companyId).then((response) => {
      setParties(response);
      setFilteredParties(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setFilteredParties(parties.filter((party) => party.name.toLowerCase().includes(searchString.toLowerCase())));
  }, [searchString]);

  const isWorkGroupSelected = (partyId: EntityId) => {
    return newProjectForm?.predefinedWorkGroups?.some((pp) => pp.id === partyId) || false;
  };

  const toggleSelectPredefinedWorkGroup = useCallback(
    (selectedWorkGroup: IPredefinedWorkGroup) => {
      if (!newProjectForm.predefinedWorkGroups) return setNewProjectForm({ ...newProjectForm, predefinedWorkGroups: [selectedWorkGroup] });
      const isSelected = isWorkGroupSelected(selectedWorkGroup.id);
      return isSelected
        ? setNewProjectForm({ ...newProjectForm, predefinedWorkGroups: newProjectForm.predefinedWorkGroups?.filter((party) => party.id !== selectedWorkGroup.id) })
        : setNewProjectForm({
            ...newProjectForm,
            predefinedWorkGroups: [...newProjectForm.predefinedWorkGroups, { ...selectedWorkGroup }]
          });
    },
    [newProjectForm]
  );

  if (!company) return null;

  //TODO: Trial ettevõtetel peaks tulema BE-st "plan: "PRO"" väärtus, nii et see booleani teine osa ära visata, kui BE pool valmis, sest trial võib olla ka light paketiga
  const canUseProFeatures = company.plan === BauhubPlan.PRO || company.status === CompanyStatus.TRIAL;

  return (
    <div className="w-2/3 lg:w-full lg:px-4">
      <h2 className="pb-6">
        <Trans>MODAL.NEW_PROJECT.PREDEFINED_WORKGROUPS</Trans>
      </h2>
      {company.plan === BauhubPlan.LIGHT && (
        <NewProjectProFeaturesUpsellView imageSource={partyHelmets} headerText={t("COMPANY.SETTINGS.PARTIES.LIGHT.HEADER")} bodyText={t("COMPANY.SETTINGS.PARTIES.LIGHT.BODY")} />
      )}
      {canUseProFeatures && (
        <>
          {!loading && (
            <>
              {parties.length === 0 && <NewProjectPredefinedPartiesNoMatches />}
              {parties.length > 0 && (
                <>
                  <BhSearchInput
                    property="name"
                    initialValue={searchString}
                    placeholder={t("NEW_PROJECT.PREDEFINED_WORKGROUPS.SEARCH") as string}
                    onChangeCallback={(changedValue: { name: string }) => setSearchString(changedValue.name)}
                  />
                  <div className="mt-5 pb-10">
                    {filteredParties.map((party) => {
                      return (
                        <BhSelectCard
                          key={party.id}
                          children={<PartyNameContainer party={party} />}
                          value={party}
                          onSelect={toggleSelectPredefinedWorkGroup}
                          trailingComponent={<BhUserIconGrouped userGroup={party.users || []} />}
                          multiSelect={true}
                          isSelected={isWorkGroupSelected(party.id)}
                          size="lg"
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default NewProjectPredefinedParties;
