import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  saveAddUserToTaskBoardAsync,
  saveAddWorkGroupToTaskBoardAsync,
  saveRemoveUserFromTaskBoardAsync,
  saveRemoveWorkGroupFromTaskBoardAsync,
  selectCurrentWorkGroupUsersInvites,
  taskBoardAddedToWorkGroup,
  taskBoardRemovedFromWorkGroup,
  userAddedToTaskboard,
  userRemovedFromTaskboard
} from "@/app/store/project/projectWorkGroupsSlice";
import { faCaretDown, faCaretRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IPartyTaskBoard } from "@/model/taskBoard/IPartyTaskBoard";
import { IPartyPrivilegeListItemDTO } from "@/model/party/IPartyPrivilegeListItemDTO";
import { IWorkGroup } from "@/model/IWorkGroup";
import BhCardContainer from "@components/cards/BhCardContainer";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { classNames } from "@/utilities/jsUtilities";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import BhLargeBoldText from "@components/text/BhLargeBoldText";
import { useTranslation } from "react-i18next";
import { selectAllTaskBoards } from "@/app/store/taskBoardsSlice";

interface Props {
  workGroup: IWorkGroup;
  taskBoard: IPartyTaskBoard;
}

const PartyTaskBoardListItem: FC<Props> = ({ workGroup, taskBoard }) => {
  const { t } = useTranslation();
  const projectTaskBoards = useAppSelector(selectAllTaskBoards);
  const usersInvites = useAppSelector(selectCurrentWorkGroupUsersInvites);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  function setOpen() {
    setIsOpen(!isOpen);
  }

  function changeValue(user: IPartyPrivilegeListItemDTO) {
    const dto = {
      userEntityId: user.userEntityId,
      inviteId: user.inviteId,
      taskBoardId: taskBoard.id,
      workGroupId: workGroup.id,
      username: user.username,
      uniqueId: user.uniqueId
    };

    if (workGroup.id === -1) {
      if (!isSelected(user)) {
        dispatch(userAddedToTaskboard(dto));
      } else {
        dispatch(userRemovedFromTaskboard(dto));
      }
    } else {
      if (!isSelected(user)) {
        dispatch(saveAddUserToTaskBoardAsync(dto));
      } else {
        dispatch(saveRemoveUserFromTaskBoardAsync(dto));
      }
    }
  }

  function isSelected(user: IPartyPrivilegeListItemDTO): boolean {
    return taskBoard.currentWgUsersInThisTaskBoard.some((userInWg) => {
      return user.uniqueId === userInWg.uniqueId;
    });
  }

  function modifyWorkGroupTaskBoardRelation() {
    const dto = {
      taskBoardId: taskBoard.id,
      workGroupId: workGroup.id
    };

    if (workGroup.id === -1) {
      const tb = projectTaskBoards.find((tb) => tb.id === taskBoard.id);
      if (!tb) {
        return;
      }
      if (!taskBoard.relationExists) {
        dispatch(taskBoardAddedToWorkGroup({ workGroupId: -1, taskBoard: tb }));
      } else {
        dispatch(taskBoardRemovedFromWorkGroup({ workGroupId: -1, taskBoard: tb }));
      }
    } else {
      if (!taskBoard.relationExists) {
        dispatch(saveAddWorkGroupToTaskBoardAsync(dto));
      } else {
        dispatch(saveRemoveWorkGroupFromTaskBoardAsync(dto));
      }
    }
  }

  if (!workGroup) {
    return null;
  }

  return (
    <BhCardContainer>
      <div className="flex h-full w-full flex-col">
        <div className="min-h-14 flex w-full flex-row items-center">
          <div className="flex w-16 flex-row justify-center ">
            <input className="h-5 w-5 rounded p-0.5 focus:ring-0 focus:ring-offset-0" type="checkbox" checked={taskBoard.relationExists} onChange={modifyWorkGroupTaskBoardRelation} />
          </div>
          <div className="pr-1">
            {(taskBoard.relationExists || taskBoard.currentWgUsersInThisTaskBoard.length > 0) && (
              <div>
                {taskBoard.currentWgUsersInThisTaskBoard.length} {taskBoard.currentWgUsersInThisTaskBoard.length === 1 ? t("PARTY.USER") : t("PARTY.USERS")}{" "}
              </div>
            )}
          </div>
          <BhLargeBoldText classes="flex flex-1 py-4">{taskBoard.name}</BhLargeBoldText>
          <div className="mr-2">
            <BhTextOnlyButton buttonProps={{ onClick: setOpen }}>
              <span className="pr-3">{t("GLOBAL.USERS")}</span>
              <FontAwesomeIcon icon={isOpen ? faCaretDown : faCaretRight} onClick={setOpen} />
            </BhTextOnlyButton>
          </div>
        </div>
        {isOpen && (
          <div className="ml-16 mr-4 px-1 pb-2">
            <div className="text-13px l-h-16px pb-2 font-bold">{t("WORKGROUP.USERS")}</div>
            <div>
              {usersInvites &&
                usersInvites.map((user: IPartyPrivilegeListItemDTO) => {
                  return (
                    <div key={user.uniqueId} className={classNames(isSelected(user) && "bh-bg-mint", "bh-border-pigeon-40 flex w-full flex-row items-center whitespace-nowrap border-t")}>
                      <div className="flex w-8 justify-center">
                        <input
                          className="h-5 w-5 rounded border-t p-0.5 focus:ring-0 focus:ring-offset-0"
                          type="checkbox"
                          checked={isSelected(user)}
                          onChange={() => {
                            changeValue(user);
                          }}
                        />
                      </div>
                      <div className="py-2 px-1">
                        <BhUserIconWithName user={user} />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </BhCardContainer>
  );
};

export default PartyTaskBoardListItem;
