import React, { FC } from "react";

//FIGMA: https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H/Bauhub-design-system?node-id=296%3A4329

interface Props {
  children?: React.ReactNode;
}

const BhTagListContainer: FC<Props> = ({ children }) => {
  return <div className="flex flex-row flex-wrap items-center gap-y-1">{children}</div>;
};

export default BhTagListContainer;
