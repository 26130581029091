import React, { Dispatch, FC, SetStateAction, useMemo } from "react";
import { TaskStatus } from "@/model/taskBoard/ITask";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { makeSelectFilteredAndSortedTaskIdsByColumnStatus, saveTaskStatusAsync } from "@/app/store/tasksSlice";
import NewTaskContainer from "@components/task/container/NewTaskContainer";
import { selectCurrentProjectId, selectIsProjectArchived } from "@/app/store/project/projectSlice";
import { selectOpenedTaskBoardId } from "@/app/store/taskBoardsSlice";
import DnDTaskContainer from "@components/task/container/DnDTaskContainer";
import { classNames } from "@/utilities/jsUtilities";
import { EntityId } from "@reduxjs/toolkit";
import { useDrop } from "react-dnd";
import { fullPageLoadingSet, toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";

interface Props {
  status: TaskStatus;
  newTaskContainer: any;
  setNewTaskContainer: Dispatch<SetStateAction<any>>;
}

const TaskColumnBody: FC<Props> = ({ status, setNewTaskContainer, newTaskContainer }) => {
  const selectFilteredAndSortedTaskIdsByColumnStatus = useMemo(makeSelectFilteredAndSortedTaskIdsByColumnStatus, []);
  const filteredTaskIds = useAppSelector((state) => selectFilteredAndSortedTaskIdsByColumnStatus(state, status));
  const projectArchived = useAppSelector(selectIsProjectArchived);
  const openedTaskBoardId = useAppSelector(selectOpenedTaskBoardId);
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();

  const [{ isOver }, taskDropRef] = useDrop({
    accept: "taskItem",
    drop: (droppedFile: { taskId: EntityId }) => {
      if (!droppedFile?.taskId) return;

      if (projectArchived) {
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.ERROR,
            disappear: true,
            translateCode: "'ERROR.PROJECT_ARCHIVED"
          })
        );
        return;
      }

      dispatch(fullPageLoadingSet(true));
      dispatch(saveTaskStatusAsync({ taskStatusChangeDTO: { status: status, taskId: droppedFile.taskId }, projectId: projectId })).then(() => {
        dispatch(fullPageLoadingSet(false));
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  const toggleNewTask = () => {
    if (projectArchived) {
      return;
    }
    setNewTaskContainer({ visible: false, status: "" } as any);
  };

  return (
    <div
      ref={taskDropRef}
      className={classNames(isOver ? "bh-outline-dark-jungle bh-bg-mint z-10 rounded-sm outline outline-1" : "bh-bg-smoke", "flex w-1/4 min-w-[200px] flex-col gap-y-1 rounded-b px-1")}
    >
      {newTaskContainer.visible && <NewTaskContainer saveCallback={toggleNewTask} taskBoardId={openedTaskBoardId} status={status} />}
      {filteredTaskIds.map((taskId) => {
        return <DnDTaskContainer taskId={taskId} key={taskId} />;
      })}
    </div>
  );
};

export default TaskColumnBody;
