import React, { FC } from "react";
import BhModal from "@components/modal/BhModal";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { Trans, useTranslation } from "react-i18next";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import BhFileCardMedium from "@components/cards/BhFileCardMedium";
import { ActToFileEntityType, IAct, IActToFileEntity } from "@/model/IAct";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import BhAttachmentsUploadContainer from "@components/upload/BhAttachmentsUploadContainer";
import { saveActFileRelations } from "@/api/actAPI";
import { useAppDispatch } from "@/app/hooks";
import { actUpdated } from "@/app/store/actsSlice";
import { getUniqueValues } from "@/utilities/jsUtilities";

interface Props {
  act: IAct;
  closeModal: Function;
  isWritePrivilege: boolean;
}

const ActAttachmentsModal: FC<Props> = ({ act, closeModal, isWritePrivilege }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const removeFile = (fileEntity: IFileEntity) => {
    if (!isWritePrivilege) return;
    const relationToDelete = act.fileRelations.find((fr) => fr.fileEntityId === fileEntity.id && !fr.deleted && fr.type === ActToFileEntityType.ATTACHMENT);
    if (relationToDelete) {
      saveActFileRelations([{ ...relationToDelete, deleted: true }]).then((deletedFileRelations) => {
        const deletedFiles = deletedFileRelations.filter((fr) => fr.deleted).map((fr) => fr.file);
        const updatedAct = {
          ...act,
          attachments: act.attachments.filter((a) => !deletedFiles.some((df) => df.id === a.id)),
          fileRelations: act.fileRelations.filter((afr) => !deletedFileRelations.some((df) => df.id === afr.id))
        };
        dispatch(actUpdated(updatedAct));
      });
    }
  };

  const saveAttachments = (formValues: { attachments: Array<IFileEntity> }) => {
    if (!isWritePrivilege) return;

    const { attachments } = formValues;

    const newRelationObjects = attachments.map((file) => {
      return { actId: act.id, fileEntityId: file.id, type: ActToFileEntityType.ATTACHMENT, file: file } as IActToFileEntity;
    });
    const onlyNewRelations = newRelationObjects.filter(
      (newRel) => !act.fileRelations.some((oldRel) => newRel.actId === oldRel.actId && newRel.fileEntityId === oldRel.fileEntityId && newRel.type === oldRel.type)
    );

    if (onlyNewRelations.length > 0) {
      saveActFileRelations(onlyNewRelations).then((newFileRelations) => {
        const updatedAct = {
          ...act,
          attachments: [...act.attachments, ...newFileRelations.map((nfr) => nfr.file)],
          fileRelations: getUniqueValues([...act.fileRelations, ...newFileRelations], "fileEntityId")
        };
        dispatch(actUpdated(updatedAct));
      });
    }
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={() => closeModal()}
      size="3xl"
      header={
        <div className="flex flex-row items-center gap-x-6">
          <h2 className="my-3">
            <Trans>TASK.ATTACHMENTS</Trans>
          </h2>
          {isWritePrivilege && (
            <BhAttachmentsUploadContainer
              saveCallback={saveAttachments}
              property={"attachments"}
              excludeFileTypes={[FileEntityType.CONTAINER]}
              multiple={true}
              fileUploadAllowed={true}
              chooseFromProjectAllowed={true}
            />
          )}
        </div>
      }
      children={
        <div className="px-8 py-6">
          {act.attachments.length < 1 && (
            <div className="-mt-10">
              <BhNoMatchesImage>
                <Trans>ACT.NO_ATTACHMENTS</Trans>
              </BhNoMatchesImage>
            </div>
          )}
          <div className="flex flex-row flex-wrap">
            {act.attachments.map((fileEntity) => (
              <BhFileCardMedium key={fileEntity.id} fileEntity={fileEntity} deleteCallback={isWritePrivilege ? removeFile : undefined} openInNewTab={true} />
            ))}
          </div>
        </div>
      }
      footer={
        <div className="flex flex-row items-center">
          <BhTextOnlyButton buttonProps={{ onClick: closeModal }}>{t("GLOBAL.CLOSE")}</BhTextOnlyButton>
        </div>
      }
    />
  );
};

export default ActAttachmentsModal;
