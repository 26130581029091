import React, { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhSelectInputFilter from "@components/filters/BhSelectInputFilter";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { makeSelectCompanyProjectsForFormBaseFilter, selectUsernamesForFormBaseFilter, selectUsernamesForProjectFormBaseFilterInCompanySettings } from "@/app/store/form/formBasesSlice";
import NewFormBaseModal from "@components/form/settings/companySettings/NewFormBaseModal";

interface Props {
  filter: any;
  setFilter: Dispatch<SetStateAction<any>>;
  companyId: EntityId;
  isProjectFormTableHeader?: boolean;
}

const CompanyFormBasesHeader: FC<Props> = ({ filter, setFilter, companyId, isProjectFormTableHeader }) => {
  const { t } = useTranslation();
  const usernamesForCompanyBasesFilter = useAppSelector(selectUsernamesForFormBaseFilter);
  const usernamesForProjectBasesFilter = useAppSelector(selectUsernamesForProjectFormBaseFilterInCompanySettings).map((username) => {
    return { username: username };
  });
  const selectProjectsForFilter = useMemo(makeSelectCompanyProjectsForFormBaseFilter, []);
  const projectsForFilter = useAppSelector((state) => selectProjectsForFilter(state, companyId));
  const [showNewFormBaseModal, setShowNewFormBaseModal] = useState(false);

  const onChange = (search: any) => {
    setFilter({ ...filter, searchString: search.type });
  };

  const onUsernameFilterSelect = (username: { username: string; translateValue: string } | { username: string }) => {
    setFilter({ ...filter, username: username.username });
  };

  const onUsernameFilterReset = () => {
    setFilter({ ...filter, username: "" });
  };

  const onProjectFilterSelect = (project: any) => {
    setFilter({ ...filter, projectId: project.id });
  };

  const onProjectFilterReset = () => {
    setFilter({ ...filter, projectId: null });
  };

  const findUsernameLabelForFilter = () => {
    const filteredUsername = usernamesForCompanyBasesFilter.find((username) => username.username === filter.username);
    return t(filteredUsername?.translateValue);
  };

  const findProjectLabelForFilter = () => {
    return projectsForFilter.find((project) => project.id === filter.projectId)?.name || "";
  };

  return (
    <div className="flex flex-col">
      <div className="mb-4 mt-1.5 flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-x-4">
          <BhSearchInputWBG initialValue={filter.searchString} property="type" onChangeCallback={onChange} inputClasses="w-72" placeholder={t("FORMBUILDER.FORM.SEARCH.TEMPLATE") as string} />
          <BhSelectInputFilter
            values={isProjectFormTableHeader ? usernamesForProjectBasesFilter : usernamesForCompanyBasesFilter}
            currentValue={isProjectFormTableHeader ? filter.username : findUsernameLabelForFilter()}
            onSelect={onUsernameFilterSelect}
            placeholder={t("FORMBUILDER.FORM_BASE.FILTER.CREATED_BY")}
            onReset={onUsernameFilterReset}
            translateValues={isProjectFormTableHeader ? undefined : true}
            textProperty={isProjectFormTableHeader ? "username" : "translateValue"}
          />
          {isProjectFormTableHeader && (
            <BhSelectInputFilter
              values={projectsForFilter}
              currentValue={findProjectLabelForFilter()}
              onSelect={onProjectFilterSelect}
              placeholder={t("FORMBUILDER.FORM.BASE.PROJECT")}
              onReset={onProjectFilterReset}
              textProperty={"name"}
            />
          )}
        </div>
        {!isProjectFormTableHeader && (
          <div>
            <BhPrimaryButton icon={faPlus} buttonProps={{ onClick: () => setShowNewFormBaseModal(true) }}>
              {t("FORMBUILDER.FORM.NEW_BASE")}
            </BhPrimaryButton>
          </div>
        )}
        {showNewFormBaseModal && <NewFormBaseModal setShowModal={() => setShowNewFormBaseModal(false)} companyId={companyId} />}
      </div>
    </div>
  );
};

export default CompanyFormBasesHeader;
