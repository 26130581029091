import React, { FC, ReactElement } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectPlan } from "@/app/store/project/projectSlice";
import { BauhubPlan } from "@/model/IProject";
import BhProPlanTag from "@components/tags/BhProPlanTag";
import BhLightPlanTag from "@components/tags/BhLightPlanTag";

const BhCurrentProjectPlanTag: FC = () => {
  const projectPlan = useAppSelector(selectCurrentProjectPlan);

  const planTagMap: Record<BauhubPlan, ReactElement> = {
    [BauhubPlan.PRO]: <BhProPlanTag />,
    [BauhubPlan.LIGHT]: <BhLightPlanTag />
  };

  if (!projectPlan) return null;

  return planTagMap[projectPlan];
};

export default BhCurrentProjectPlanTag;
