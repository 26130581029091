import React, { FC, useEffect, useRef } from "react";
import { ReactComponent as TextImage } from "@svg/checklist-base-text.svg";
import { ReactComponent as InputImage } from "@svg/checklist-base-input.svg";
import { ReactComponent as CheckpointImage } from "@svg/checklist-base-checkpoint.svg";
import { ReactComponent as ListImage } from "@svg/checklist-base-list.svg";
import { ReactComponent as SelectionImage } from "@svg/checklist-base-selection.svg";
import { ReactComponent as AttachmentImage } from "@svg/checklist-base-attachment.svg";
import { ReactComponent as PlusMinusImage } from "@svg/checklist-base-plus-minus.svg";
import { classNames } from "@/utilities/jsUtilities";
import { ChecklistRowFieldType, ChecklistRowType, IChecklistRowPlusMinusFieldValue } from "@/model/checklist/IChecklistBaseVersionRow";
import { useTranslation } from "react-i18next";

interface Props {
  saveNewRowCallback: Function;
  clickOutsideCallback?: Function;
}

const ChecklistBaseFields: FC<Props> = ({ saveNewRowCallback, clickOutsideCallback }) => {
  const { t } = useTranslation();
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        clickOutsideCallback && clickOutsideCallback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef]);

  const newPlusMinusFieldData = {
    okTotal: 0,
    notOkTotal: 0,
    tooltipAdded: false,
    tooltipText: "",
    autoCreateTask: true,
    prefills: []
  } as IChecklistRowPlusMinusFieldValue;

  const fieldTypes = [
    { id: 0, element: <TextImage />, text: t("CHECKLIST_BASE.FIELD.TYPE.WYSIWYG"), type: ChecklistRowType.WYSIWYG, checkpoint: false },
    { id: 1, element: <InputImage />, text: t("CHECKLIST_BASE.FIELD.TYPE.TEXTFIELD"), type: ChecklistRowType.TEXTFIELD, checkpoint: false, fieldType: ChecklistRowFieldType.SMALL_TEXTFIELD },
    { id: 2, element: <CheckpointImage />, text: t("CHECKLIST_BASE.FIELD.TYPE.CHECKPOINT"), type: ChecklistRowType.CHECKPOINT, indentation: 0, checkpoint: false },
    { id: 3, element: <PlusMinusImage />, text: t("CHECKLIST_BASE.FIELD.TYPE.PLUSMINUS"), type: ChecklistRowType.PLUSMINUS, checkpoint: false, plusMinusFieldValue: newPlusMinusFieldData },
    { id: 4, element: <ListImage />, text: t("CHECKLIST_BASE.FIELD.TYPE.CHECKPOINT_LIST"), type: ChecklistRowType.CHECKPOINT_LIST, checkpoint: true },
    { id: 5, element: <SelectionImage />, text: t("CHECKLIST_BASE.FIELD.TYPE.OPTION_FIELD"), type: ChecklistRowType.OPTION_FIELD, checkpoint: false, fieldType: ChecklistRowFieldType.SINGLE },
    { id: 6, element: <AttachmentImage />, text: t("CHECKLIST_BASE.FIELD.TYPE.ATTACHMENTS"), type: ChecklistRowType.ATTACHMENTS, checkpoint: false }
  ];

  return (
    <div className="bh-shadow flex flex-row items-center justify-center" ref={divRef}>
      {fieldTypes.map((field, index) => {
        return (
          <div
            className={classNames(
              "bh-border-pigeon-60 hover:bh-bg-mint-30 h-32 w-32 cursor-pointer border",
              index === 0 && "rounded-l",
              index === fieldTypes.length - 1 && "rounded-r",
              index !== 0 && "border-l-0"
            )}
            key={field.id}
            onClick={() => saveNewRowCallback(field)}
          >
            <div className="flex h-full flex-col justify-between pt-4">
              <div className="flex w-full flex-row justify-center">{field.element}</div>
              <div className="flex w-full flex-row justify-center pb-4">
                <div className="text-14px bh-text-deep-ocean leading-5">{field.text}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ChecklistBaseFields;
