import React, { FC, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import { useTranslation } from "react-i18next";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import BhTextareaSimple from "@components/input/BhTextareaSimple";
import FormBaseInfoPdfModalRowsPlaceholder from "@components/form/base/body/formBaseInfo/FormBaseInfoPdfModalRowsPlaceholder";
import { IFormBasePdfInfo } from "@/model/form/IFormBasePdfInfo";
import { EntityId } from "@reduxjs/toolkit";
import { fetchFormBasePdfInfo, saveFormBasePdfInfo } from "@/api/form/formBaseAPI";
import { classNames } from "@/utilities/jsUtilities";
import FormBaseInfoPdfLogoContainer from "@components/form/base/body/formBaseInfo/FormBaseInfoPdfLogoContainer";

interface Props {
  isShown: boolean;
  setIsShown: (value: boolean) => void;
  formBaseId: EntityId;
  companyId: EntityId;
  initialTitle: string;
}

const FormBaseInfoPdfModal: FC<Props> = ({ isShown, setIsShown, formBaseId, initialTitle, companyId }) => {
  const { t } = useTranslation();
  const [formBasePdfInfo, setFormBasePdfInfo] = useState<IFormBasePdfInfo>({ _id: "", formBaseId: formBaseId });

  useEffect(() => {
    fetchFormBasePdfInfo(companyId, formBaseId).then((pdfInfo) => {
      const pdfInfoToSet = { ...pdfInfo, title: pdfInfo.title || initialTitle, formBaseId: formBaseId } as IFormBasePdfInfo;
      setFormBasePdfInfo(pdfInfoToSet);
    });
  }, []);

  const saveAndClose = (pdfInfo: IFormBasePdfInfo) => {
    return saveFormBasePdfInfo(companyId, formBaseId, formBasePdfInfo).then((pdfInfo) => {
      setFormBasePdfInfo(pdfInfo);
      setIsShown(false);
    });
  };

  const convertToHTML = (changedObject: { text: string }, footer?: boolean) => {
    let lines = changedObject.text.split(/\r?\n/);

    return (
      '<style>html { -webkit-print-color-adjust: exact; } #header, #footer { all: unset; font-family: "Lato", sans-serif; font-size: 10px; line-height: 11px; } #header #wrapper { padding-top: 26px; padding-bottom: 16px; } #footer #wrapper { padding-top: 16px; padding-bottom: 26px; } #wrapper { padding-left: 42px;  padding-right: 42px; display: flex; flex-direction: column; justify-content: center; color: #56747e !important;} #wrapper div { min-height: 11px; } #pageNum { display: none; position: absolute; right: 0; bottom: 0; margin: 26px 42px; } #footer #pageNum { display: block; } </style>' +
      '<div id="wrapper">' +
      lines.map((line) => `<div>${line}</div>`).join("") +
      (footer ? '<span id="pageNum"><span class="pageNumber"></span>/<span class="totalPages"></span></span>' : "") +
      "</div>"
    );
  };

  function convertToText(html: string) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const paragraphs = tempDiv.querySelector("[id='wrapper']")?.querySelectorAll("div");
    if (!paragraphs) {
      return [];
    }
    return Array.from(paragraphs)
      .map((p) => p.textContent)
      .join("\n");
  }

  return (
    <BhModal
      isShown={isShown}
      disableOutsideClick={true}
      setIsShown={setIsShown}
      header={<h2>{t("FORMBUILDER.FORM.BASE.PDF_SETTINGS")}</h2>}
      onClose={() => setIsShown(false)}
      footer={<BhModalFooter onCancel={() => setIsShown(false)} onConfirm={saveAndClose} />}
      size={"7xl"}
    >
      <BhScrollableBody>
        <div className="bh-bg-smoke -mb-16 h-auto w-full px-36 py-7">
          <div className="flex flex-col gap-y-10">
            <div className="bh-bg-white flex h-[1262px] flex-col overflow-hidden p-10">
              <div className="flex flex-row justify-between">
                <div className="flex h-full w-full flex-col justify-between">
                  <div className="w-7/12">
                    <BhTextareaSimple
                      initialValue={convertToText(formBasePdfInfo.header || "")}
                      placeholder={t("FORMBUILDER.FORM.BASE.PDF.HEADER") as string}
                      property={"text"}
                      inputClasses={
                        "bh-bg-mint bh-text-deep-ocean-80 text-13px hover:bh-bg-mint-110 disabled:bh-bg-white disabled:hover:bh-bg-white w-full !rounded-none border-none !py-0.5 !px-0.5 leading-4 focus:cursor-text disabled:cursor-text"
                      }
                      onBlurCallback={(changedObject: any) => setFormBasePdfInfo({ ...formBasePdfInfo, header: convertToHTML(changedObject) })}
                    />
                  </div>
                  <div className="w-10/12">
                    <div>
                      <BhTextareaSimple
                        initialValue={formBasePdfInfo.title}
                        property={"title"}
                        inputClasses={
                          "bh-bg-mint bh-text-deep-ocean-80 text-18px font-bold hover:bh-bg-mint-110 disabled:bh-bg-white disabled:hover:bh-bg-white w-full !rounded-none border-none !py-0.5 !px-0.5 leading-4 focus:cursor-text disabled:cursor-text"
                        }
                        onBlurCallback={(changedObject: any) => setFormBasePdfInfo({ ...formBasePdfInfo, ...changedObject })}
                      />
                    </div>
                    <div className="text-14px">Nr X</div>
                  </div>
                </div>
                <div className="mb-6 flex items-end">
                  <FormBaseInfoPdfLogoContainer
                    formBaseId={formBaseId}
                    companyId={companyId}
                    setPdfInfoCallback={(changedValue: IFormBasePdfInfo) => setFormBasePdfInfo({ ...formBasePdfInfo, ...changedValue })}
                    logoFileEntityId={formBasePdfInfo.logoFileEntityId}
                  />
                </div>
              </div>
              <div className="my-6 flex flex-1 flex-col gap-y-3 overflow-hidden">
                <FormBaseInfoPdfModalRowsPlaceholder numberOfRows={5} />
                <FormBaseInfoPdfModalRowsPlaceholder numberOfRows={4} />
                <FormBaseInfoPdfModalRowsPlaceholder numberOfRows={4} />
                <FormBaseInfoPdfModalRowsPlaceholder numberOfRows={1} />
              </div>
              <div className="flex flex-row items-end justify-between">
                <div className="w-7/12">
                  <BhTextareaSimple
                    placeholder={t("FORMBUILDER.FORM.BASE.PDF.FOOTER") as string}
                    initialValue={convertToText(formBasePdfInfo.footer || "")}
                    property={"text"}
                    inputClasses={
                      "bh-bg-mint bh-text-deep-ocean-80 text-13px hover:bh-bg-mint-110 disabled:bh-bg-white disabled:hover:bh-bg-white w-full !rounded-none border-none !py-0.5 !px-0.5 leading-4 focus:cursor-text disabled:cursor-text"
                    }
                    onBlurCallback={(changedObject: any) => setFormBasePdfInfo({ ...formBasePdfInfo, footer: convertToHTML(changedObject, true) })}
                  />
                </div>
                <div className="bh-text-pigeon">1/2</div>
              </div>
            </div>
            <div className="bh-bg-white flex h-[1262px] flex-col justify-between overflow-hidden py-10">
              <div className="flex flex-col">{formBasePdfInfo.header && <div dangerouslySetInnerHTML={{ __html: formBasePdfInfo.header }} className={"form-base-pdf-info"}></div>}</div>
              <div className="my-6 flex flex-1 flex-col gap-y-3 overflow-hidden px-10">
                <FormBaseInfoPdfModalRowsPlaceholder numberOfRows={5} />
                <FormBaseInfoPdfModalRowsPlaceholder numberOfRows={4} />
                <FormBaseInfoPdfModalRowsPlaceholder numberOfRows={2} />
              </div>
              <div className={classNames("flex w-full flex-row items-end", formBasePdfInfo.footer ? "justify-between" : "justify-end")}>
                {formBasePdfInfo.footer && <div dangerouslySetInnerHTML={{ __html: formBasePdfInfo.footer }} className={"form-base-pdf-info"}></div>}
                <div className="bh-text-pigeon pr-10">2/2</div>
              </div>
            </div>
          </div>
        </div>
      </BhScrollableBody>
    </BhModal>
  );
};

export default FormBaseInfoPdfModal;
