import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faArrowTurnDownRight } from "@fortawesome/pro-regular-svg-icons/faArrowTurnDownRight";
import { classNames } from "@/utilities/jsUtilities";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  parentId?: EntityId;
  currentId: EntityId;
  addChildToIdCallback: Function;
  disabled?: boolean;
  onHover?: boolean;
  classes?: string;
}

const ContractRowsTableNewRowDropDown: FC<Props> = ({ parentId, currentId, addChildToIdCallback, disabled, onHover, classes }) => {
  const handleClick = (e: React.MouseEvent, id: EntityId, insertAfterId?: EntityId) => {
    e.stopPropagation();
    addChildToIdCallback(id, insertAfterId);
  };

  return (
    <div className={classNames(classes && classes, "flex flex-row items-center justify-center")}>
      {!disabled && (
        <div className={classNames(onHover ? "hidden group-hover:flex" : "flex", "bh-bg-deep-ocean bh-text-white mx-1 flex h-6 w-12 cursor-pointer flex-row justify-end overflow-hidden rounded")}>
          {parentId && (
            <div className="hover:bh-bg-deep-ocean-80 flex w-6 items-center justify-center" onClick={(e) => handleClick(e, parentId, currentId)}>
              <FontAwesomeIcon icon={faPlus} size="sm" />
            </div>
          )}
          <div className="hover:bh-bg-deep-ocean-80 flex w-6 items-center justify-center" onClick={(e) => handleClick(e, currentId)}>
            <FontAwesomeIcon icon={faArrowTurnDownRight} size="sm" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractRowsTableNewRowDropDown;
