import React, { FC, useEffect } from "react";
import BhFlagSmall from "@components/flags/BhFlagSmall";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectShowSuccessToast, successToastShown } from "@/app/store/globalSlice";
import BhToastTransition from "@components/flags/BhToastTransition";
import { useTranslation } from "react-i18next";

const BhSuccessToast: FC = () => {
  const { t } = useTranslation();
  const show = useAppSelector(selectShowSuccessToast);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const id = setTimeout(() => dispatch(successToastShown(false)), 2000);

    return () => clearTimeout(id);
  }, [show]);

  return (
    <div className="z-100 fixed bottom-0 h-0 w-full">
      <BhToastTransition show={show}>
        <BhFlagSmall text={t("GLOBAL.SAVED")} />
      </BhToastTransition>
    </div>
  );
};

export default BhSuccessToast;
