import React, { FC } from "react";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import { IUser } from "@/model/IUser";

interface Props {
  user: IUser;
  onRemove?: Function;
  disabled?: boolean;
}

const BhChipWithUser: FC<Props> = ({ user, onRemove, disabled }) => {
  const removeUser = () => {
    onRemove && onRemove(user);
  };

  return (
    <div className="bh-bg-pigeon-40 bh-text-deep-ocean l-h-14px my-0.5 mr-1 inline-block flex w-fit flex-row items-center rounded-2xl p-0.5 py-0.5 font-bold">
      <BhUserIconWithName user={user} size="sm" withoutRing={true} />
      {!disabled && (
        <div
          className="hover:bh-bg-deep-ocean hover:bh-text-white ml-0.5 mr-1 inline-block h-4 w-4 cursor-pointer rounded-full text-center"
          onMouseDown={(e) => e.preventDefault()}
          onClick={removeUser}
        >
          <FontAwesomeIcon icon={faXmark} size={"1x"} className="h-full align-middle" />
        </div>
      )}
    </div>
  );
};

export default React.memo(BhChipWithUser);
