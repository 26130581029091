import React, { useEffect } from "react";
import { PickResult, Viewer } from "@xeokit/xeokit-sdk";
import { BauhubXeokitTool } from "@/views/home/project/detail/xeokit/XeokitWebViewer";

interface Props {
  onModelsLoaded: boolean;
  bauhubToolActive: BauhubXeokitTool;
  viewer: Viewer;
}

const XeokitHideObjectTool = React.memo<Props>(({ onModelsLoaded, bauhubToolActive, viewer }) => {
  useEffect(() => {
    viewer.on("reset", () => {
      viewer.scene.setObjectsVisible(viewer.scene.objectIds, true);
    });
  }, [onModelsLoaded]);

  const pickedCallback = (pickResult: PickResult) => {
    if (bauhubToolActive !== BauhubXeokitTool.HIDE) return;

    if (!pickResult.entity) return;
    pickResult.entity.visible = false;
  };

  useEffect(() => {
    viewer.cameraControl.doublePickFlyTo = bauhubToolActive !== BauhubXeokitTool.HIDE;

    const pickedId = viewer.cameraControl.on("picked", pickedCallback);

    return () => {
      viewer.cameraControl.off(pickedId);
    };
  }, [bauhubToolActive]);

  return null;
});

export default XeokitHideObjectTool;
