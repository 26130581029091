import React, { FC, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { createContainerAsync, fetchFileEntityAsync, fileEntitySelected, selectAllFilesSelected, selectCurrentDirId, selectFileById } from "@/app/store/filesSlice";
import { selectCurrentProjectId, toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { IContainerCreateRequest, IUserSigningInfo } from "@/model/container/IContainerCreateRequest";
import { findFileNameForContainer } from "@/utilities/fileEntityUtilities";
import { fetchPossibleSignersForContainer } from "@/api/projectAPI";
import BhContainerModal from "@/views/home/project/detail/container/modals/BhContainerModal";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { IFileEntity } from "@/model/files/IFileEntity";

interface Props {
  hasFileDeletePermission?: boolean;
  allowPublicSigners?: boolean;
  allowOrderNumber?: boolean;
}

const DirectoryContainerModalContainer: FC<Props> = ({ hasFileDeletePermission, allowOrderNumber, allowPublicSigners }) => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const files = useAppSelector(selectAllFilesSelected);
  const [selectedFiles, setSelectedFiles] = useState(files);
  const currentDirId = useAppSelector(selectCurrentDirId);
  const currentDir = useAppSelector((state) => currentDirId && selectFileById(state, currentDirId));
  const requestObject = { containerName: findFileNameForContainer(files), parentDirId: currentDirId } as IContainerCreateRequest;
  const [signers, setSigners] = useState<Array<IUserSigningInfo>>([]);
  const dispatch = useAppDispatch();

  const onFileSelect = (files: IFileEntity[]) => {
    setSelectedFiles([...selectedFiles, ...files]);

  };

  const onFileDelete = (selectedFile: IFileEntity) => {
    const newFiles = selectedFiles.filter(file => file.id !== selectedFile.id);
    setSelectedFiles(newFiles);
  };

  useEffect(() => {
    if (currentDirId) {
      dispatch(fetchFileEntityAsync(currentDirId));
    }

    fetchPossibleSignersForContainer(
      projectId,
      files.map((file) => file.id)
    ).then((authorities) => {
      setSigners(authorities);
    });
  }, []);

  const closeModal = useCallback(() => {
    dispatch(toggleProjectModalsOpen({ modal: "directoryContainerModal" }));
  }, []);

  if (!selectedFiles || !currentDirId || !currentDir) return null;

  return (
    <BhContainerModal
      files={selectedFiles}
      requestObject={requestObject}
      containerPath={currentDir ? currentDir.filePath : ""}
      predefinedSigners={signers}
      closeModalCallback={closeModal}
      createContainerCallback={createContainerAsync}
      hasFileDeletePermission={isProjectAdmin}
      onFileSelect={onFileSelect}
      onFileDelete={onFileDelete}
    />
  );
};

export default DirectoryContainerModalContainer;
