import React, { FC } from "react";
import "react-tooltip/dist/react-tooltip.css";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

const EmptyRouteFallback: FC = () => {
  return (
    <BhScrollableBody>
      <div className="flex h-full w-full flex-col items-center">
        <BhNoMatchesImage></BhNoMatchesImage>
        <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/`}>
          <BhSecondaryButton>
            <Trans>PROJECT.SIDEBAR.DASHBOARD</Trans>
          </BhSecondaryButton>
        </Link>
      </div>
    </BhScrollableBody>
  );
};

export default EmptyRouteFallback;
