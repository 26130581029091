import React, { FC, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { serialize } from "@/utilities/jsUtilities";
import BhFilterContainer from "@components/filters/BhFilterContainer";
import { useTranslation } from "react-i18next";
import BhDatePicker from "@components/input/BhDatePicker";
import BhSelectInputFilter from "@components/filters/BhSelectInputFilter";
import { resetCompanyBillsFilterProject, resetCompanyBillsFilterSince, resetCompanyBillsFilterStatus, resetCompanyBillsFilterUntil, selectCompanyBillsFilter } from "@/app/store/companiesSlice";
import { getCompanySalesInvoiceProjects } from "@/api/billingAPI";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  companyId: EntityId;
}

const CompanyBillsFilter: FC<Props> = ({ companyId }) => {
  const filter = useAppSelector(selectCompanyBillsFilter);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [projectNames, setProjectNames] = useState([] as Array<string>);
  const { t } = useTranslation();

  useEffect(() => {
    getCompanySalesInvoiceProjects(companyId).then((response) => {
      setProjectNames(response);
    });
  }, []);

  const onProjectFilterSelect = useCallback(
    (project: string) => {
      let newFilter = { ...filter, project: project };
      const newLocation = {
        pathname: location.pathname,
        search: serialize(newFilter)
      };
      navigate(newLocation);
    },
    [filter]
  );

  const onProjectFilterReset = () => {
    let newFilter = { ...filter };
    delete newFilter.project;
    const newLocation = {
      pathname: location.pathname,
      search: serialize(newFilter)
    };
    dispatch(resetCompanyBillsFilterProject());
    navigate(newLocation);
  };

  const onStatusFilterSelect = useCallback(
    (status: { name: string; translation: string }) => {
      let newFilter = { ...filter, status: status.name };
      const newLocation = {
        pathname: location.pathname,
        search: serialize(newFilter)
      };
      navigate(newLocation);
    },
    [filter]
  );

  const onStatusFilterReset = () => {
    let newFilter = { ...filter };
    delete newFilter.status;
    const newLocation = {
      pathname: location.pathname,
      search: serialize(newFilter)
    };
    dispatch(resetCompanyBillsFilterStatus());
    navigate(newLocation);
  };

  const onLogDateFilterSelect = useCallback(
    (date: any) => {
      let filterObject;
      if (date.since) {
        filterObject = { since: date.since };
      }
      if (date.until) {
        filterObject = { until: date.until };
      }

      let newFilter = { ...filter, ...filterObject };
      const newLocation = {
        pathname: location.pathname,
        search: serialize(newFilter)
      };
      navigate(newLocation);
    },
    [filter]
  );

  const onLogSinceFilterReset = () => {
    let newFilter = { ...filter };
    delete newFilter.since;
    const newLocation = {
      pathname: location.pathname,
      search: serialize(newFilter)
    };
    dispatch(resetCompanyBillsFilterSince());
    navigate(newLocation);
  };

  const onLogUntilFilterReset = () => {
    let newFilter = { ...filter };
    delete newFilter.until;
    const newLocation = {
      pathname: location.pathname,
      search: serialize(newFilter)
    };
    dispatch(resetCompanyBillsFilterUntil());
    navigate(newLocation);
  };

  return (
    <BhFilterContainer>
      <BhDatePicker
        initialValue={filter.since ? new Date(filter.since) : null}
        property={"since"}
        onChangeCallback={onLogDateFilterSelect}
        classes={"w-full"}
        returnISOString={true}
        placeholder={t("LOG.SINCE") as string}
        onResetCallback={onLogSinceFilterReset}
      />
      <BhDatePicker
        initialValue={filter.until ? new Date(filter.until) : null}
        property={"until"}
        onChangeCallback={onLogDateFilterSelect}
        classes={"w-full"}
        returnISOString={true}
        placeholder={t("LOG.UNTIL") as string}
        onResetCallback={onLogUntilFilterReset}
      />
      <BhSelectInputFilter
        values={projectNames}
        currentValue={filter.project}
        onSelect={onProjectFilterSelect}
        placeholder={t("COMPANY.SETTINGS.ORDER.INVOICES.PROJECT")}
        onReset={onProjectFilterReset}
      />
      <BhSelectInputFilter
        values={[
          { name: "DUE", translation: "COMPANY.SETTINGS.ORDER.INVOICES.DUE" },
          { name: "PAID", translation: "COMPANY.SETTINGS.ORDER.INVOICES.PAID" }
        ]}
        textProperty="translation"
        currentValue={filter.status && "COMPANY.SETTINGS.ORDER.INVOICES." + filter.status}
        onSelect={onStatusFilterSelect}
        placeholder={t("COMPANY.SETTINGS.ORDER.INVOICES.STATUS")}
        translateValues={true}
        onReset={onStatusFilterReset}
      />
    </BhFilterContainer>
  );
};

export default CompanyBillsFilter;
