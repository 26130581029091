import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  fetchProjectDocumentRootDirectoryForDailyMailAsync,
  fetchProjectFileRootDirectoryForDailyMailAsync,
  fetchUserDailyMailProjectFileEntityRelationAsync,
  fetchUserMailSettingsInfosForDailyMailAsync,
  saveUserDailyMailSettingsInfoAsync,
  selectUserDailyMailRelatedSettings,
  selectUserMailSettingsActiveProjectId
} from "@/app/store/userPreferenceSlice";
import MailSettingsHighSecurityProjectWarning from "@/views/home/profile/modals/MailSettingsHighSecurityProjectWarning";
import DailyMailFoldersContainer from "@/views/home/profile/modals/dailyMailModal/DailyMailFoldersContainer";
import { IUserEmailSetting, UserEmailSettingsType } from "@/model/profile/IUserEmailSettings";
import BhToggleRow from "@components/toggle/BhToggleRow";
import { useTranslation } from "react-i18next";

interface Props {
  userSecurityLevelTooLow: boolean;
}

const DailyMailProjectSettingsContainer: FC<Props> = ({ userSecurityLevelTooLow }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const activeProjectId = useAppSelector(selectUserMailSettingsActiveProjectId);
  const dailyMailSettingsInfo = useAppSelector(selectUserDailyMailRelatedSettings);
  const [toggleValues, setToggleValues] = useState({
    addNewFileDirectories: true,
    addNewDocumentDirectories: true,
    dailyMailTasks: true,
    dailyMailSigningAndConfirmations: true
  });
  const dailyMailTypesToFetch = {
    types: [
      // toggle values
      UserEmailSettingsType.ADDING_NEW_DRAWINGS_FOLDER_TO_DAILY_MAIL_DISABLED,
      UserEmailSettingsType.ADDING_NEW_DOCUMENTS_FOLDER_TO_DAILY_MAIL_DISABLED,
      UserEmailSettingsType.DAILY_MAIL_TASKS_DISABLED,
      UserEmailSettingsType.DAILY_MAIL_SIGNING_AND_CONFIRMATION_DISABLED,
      // folder selection values
      UserEmailSettingsType.DAILY_MAIL_PROJECT_SPECIFIC_FILES_SELECTED,
      UserEmailSettingsType.DAILY_MAIL_PROJECT_SPECIFIC_DOCUMENTS_SELECTED,
      UserEmailSettingsType.ADDING_NEW_DRAWINGS_FOLDER_TO_DAILY_MAIL_DISABLED,
      UserEmailSettingsType.ADDING_NEW_DOCUMENTS_FOLDER_TO_DAILY_MAIL_DISABLED
    ]
  };

  useEffect(() => {
    if (activeProjectId) {
      dispatch(fetchUserMailSettingsInfosForDailyMailAsync(dailyMailTypesToFetch));
      dispatch(fetchProjectDocumentRootDirectoryForDailyMailAsync(activeProjectId));
      dispatch(fetchProjectFileRootDirectoryForDailyMailAsync(activeProjectId));
      !userSecurityLevelTooLow && dispatch(fetchUserDailyMailProjectFileEntityRelationAsync(activeProjectId));
    }
  }, [activeProjectId]);

  const isToggled = (type: UserEmailSettingsType) => {
    return !dailyMailSettingsInfo.some((info) => info.type === type && info.resource === "PROJECT" && info.resourceId === activeProjectId);
  };

  useEffect(() => {
    if (dailyMailSettingsInfo) {
      setToggleValues({
        addNewFileDirectories: isToggled(UserEmailSettingsType.ADDING_NEW_DRAWINGS_FOLDER_TO_DAILY_MAIL_DISABLED),
        addNewDocumentDirectories: isToggled(UserEmailSettingsType.ADDING_NEW_DOCUMENTS_FOLDER_TO_DAILY_MAIL_DISABLED),
        dailyMailTasks: isToggled(UserEmailSettingsType.DAILY_MAIL_TASKS_DISABLED),
        dailyMailSigningAndConfirmations: isToggled(UserEmailSettingsType.DAILY_MAIL_SIGNING_AND_CONFIRMATION_DISABLED)
      });
    }
  }, [dailyMailSettingsInfo]);

  if (!activeProjectId) return null;

  const saveDailyMailSetting = (type: UserEmailSettingsType) => {
    const objectToSave = {
      resource: "PROJECT",
      resourceId: activeProjectId,
      type: type,
      deleted: !isToggled(type)
    } as IUserEmailSetting;
    dispatch(saveUserDailyMailSettingsInfoAsync(objectToSave));
  };

  return (
    <div className="bh-bg-pigeon-20 flex w-full flex-col overflow-auto p-4">
      {userSecurityLevelTooLow && <MailSettingsHighSecurityProjectWarning projectId={activeProjectId} />}
      <div className="mb-2">
        <DailyMailFoldersContainer
          disabled={userSecurityLevelTooLow}
          addNewDirectoriesAutomatically={toggleValues.addNewFileDirectories}
          addNewDirectoriesAutomaticallyCallback={saveDailyMailSetting}
          isFiles={true}
        />
      </div>
      <div className="mb-2">
        <DailyMailFoldersContainer
          disabled={userSecurityLevelTooLow}
          addNewDirectoriesAutomatically={toggleValues.addNewDocumentDirectories}
          addNewDirectoriesAutomaticallyCallback={saveDailyMailSetting}
          isFiles={false}
        />
      </div>
      <BhToggleRow
        disabled={userSecurityLevelTooLow}
        toggleObject={toggleValues}
        toggleProperty={"dailyMailTasks"}
        onToggle={() => saveDailyMailSetting(UserEmailSettingsType.DAILY_MAIL_TASKS_DISABLED)}
        label={<h3 className="bh-text-deep-ocean-80 my-0">{t("MAIL.SETTINGS.MODAL.DAILY_MAIL.TASKS")}</h3>}
        classes={"rounded mb-2 p-6"}
      />
      <BhToggleRow
        disabled={userSecurityLevelTooLow}
        toggleObject={toggleValues}
        toggleProperty={"dailyMailSigningAndConfirmations"}
        onToggle={() => saveDailyMailSetting(UserEmailSettingsType.DAILY_MAIL_SIGNING_AND_CONFIRMATION_DISABLED)}
        label={<h3 className="bh-text-deep-ocean-80 my-0">{t("MAIL.SETTINGS.MODAL.DAILY_MAIL.SIGN_INVITES")}</h3>}
        classes={"rounded mb-2 p-6"}
      />
    </div>
  );
};

export default DailyMailProjectSettingsContainer;
