import React, { FC, useEffect, useRef } from "react";
import { EntityId } from "@reduxjs/toolkit";
import BhIconButton from "@components/buttons/BhIconButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import { useAppSelector } from "@/app/hooks";
import { Core } from "@pdftron/webviewer";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectFileAnnotationForFileEntityId } from "@/app/store/fileAnnotationsSlice";
import TaskContainer from "@components/task/container/TaskContainer";
import { ReactComponent as BauhubTaskPin } from "@svg/bauhub-task-pin.svg";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import { faShapes } from "@fortawesome/pro-regular-svg-icons/faShapes";
import { Trans } from "react-i18next";

interface Props {
  fileEntityId: EntityId;
  annotationManager?: Core.AnnotationManager;
  selectedAnnotationIds: Array<string>;
  hideSidebar: Function;
}

const PdftronAnnotationsSidebar: FC<Props> = ({ fileEntityId, annotationManager, selectedAnnotationIds, hideSidebar }) => {
  const fileAnnotation = useAppSelector((state) => selectFileAnnotationForFileEntityId(state, fileEntityId));
  const annotationContainerRef = useRef<HTMLDivElement>(null);

  const annotationList = annotationManager?.getAnnotationsList().filter((annotation) => !["link", "widget"].includes(annotation.elementName)) || [];

  useEffect(() => {
    if (selectedAnnotationIds.length > 0 && annotationContainerRef.current) {
      const selectedAnnotationDiv = annotationContainerRef.current.querySelector(`#${"id-" + selectedAnnotationIds[0]}`);
      if (selectedAnnotationDiv) {
        selectedAnnotationDiv.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [selectedAnnotationIds]);

  return (
    <div className="flex w-72 flex-col border-l">
      <div className="flex flex-col overflow-hidden">
        <div className="">
          <div className="flex flex-row items-center justify-between pl-4 pr-2 pt-2">
            <p>
              <span className="font-bold">
                <Trans>PDFTRON.ANNOTATIONS</Trans>
              </span>
              {annotationList?.length > 0 && (
                <BhBadge classes="ml-2" type={BhBadgeType.BRIGHT}>
                  {annotationList?.length}
                </BhBadge>
              )}
            </p>
            <BhIconButton icon={faXmark} buttonProps={{ onClick: hideSidebar }} />
          </div>
        </div>
        <div className="flex-1 overflow-auto pt-3" ref={annotationContainerRef}>
          {annotationList?.length < 1 && (
            <div className="m-auto w-2/3 overflow-hidden">
              <BhNoMatchesImage />
            </div>
          )}
          {[...annotationList].reverse().map((annotation) => {
            const taskId = annotation.getCustomData("taskId");
            const taskToFileAnnotation = fileAnnotation?.taskAnnotations.find((ta) => ta.annotationId === annotation.Id && ta.taskId.toString() === taskId);
            const task = taskToFileAnnotation?.task;

            return (
              <div
                key={annotation.Id}
                id={"id-" + annotation.Id}
                className={classNames(selectedAnnotationIds.includes(annotation.Id) && "bh-bg-mint", "flex cursor-pointer flex-row border-t p-1")}
                onClick={() => {
                  annotationManager?.deselectAllAnnotations();
                  annotationManager?.selectAnnotation(annotation);
                  annotationManager?.jumpToAnnotation(annotation, { isSmoothScroll: true });
                }}
              >
                <div className="w-12 py-3 pl-3">
                  {!taskId && <FontAwesomeIcon icon={faShapes} color={annotation.Color && annotation.Color.toHexString()} size="lg" />}
                  {taskId && <BauhubTaskPin className="!h-4 w-auto" />}
                </div>
                {!taskId && (
                  <div className="flex flex-1 flex-col py-1 pr-1">
                    <div className="font-bold">{annotation.Subject}</div>
                    <div className="text-12px">{annotation.Author}</div>
                    <div>{annotation.getContents()}</div>
                  </div>
                )}
                {task && <TaskContainer taskId={task.id} />}
                {annotation.Subject === "Task" && !task && (
                  <div className="flex flex-row items-center p-1">
                    <Trans>PDFTRON.NO_TASK</Trans>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PdftronAnnotationsSidebar;
