import React, { FC, useCallback, useEffect } from "react";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { IBhSort, IBhSortValues } from "@/model/IBhSort";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/pro-solid-svg-icons/faArrowUp";
import { faArrowDown } from "@fortawesome/pro-solid-svg-icons/faArrowDown";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@/app/hooks";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";

interface Props<T> {
  sort: IBhSort<T>;
  localStorageSort?: IBhSort<T>;
  sortValues: Array<IBhSortValues<T>>;
  setSortForStore?: Function;
  setSortForLocalStorage?: Function;
  position?: BhDropdownPositionEnum;
}

const BhSortButton: FC<Props<any>> = ({ sort, localStorageSort, sortValues, setSortForStore, setSortForLocalStorage, position }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    localStorageSort && setSortForStore && dispatch(setSortForStore(localStorageSort));
  }, []);

  const sortButtonValue = sortValues.find((value) => value.value === sort.property)?.translation;

  const onSortSelectHandler = useCallback(
    (value: any) => {
      const updatedSort = { ...sort, property: value.value };
      setSortForStore && dispatch(setSortForStore(updatedSort));
      setSortForLocalStorage && setSortForLocalStorage(updatedSort);
    },
    [sort]
  );

  const onReversedClickHandler = useCallback(() => {
    const updatedSort = { ...sort, reversed: !sort.reversed };
    setSortForStore && dispatch(setSortForStore(updatedSort));
    setSortForLocalStorage && setSortForLocalStorage(updatedSort);
  }, [sort]);

  return (
    <div className="mr-2 inline-flex items-center">
      <span className="pr-1">{t("GLOBAL.ORDER_BY")}</span>
      <BhDropdown
        button={<button className="bh-text-deep-ocean-imp hover:bh-bg-smoke disabled:bh-text-pigeon-60 bh-text-pigeon mx-1 rounded px-1 py-0.5 font-bold">{sortButtonValue}</button>}
        menu={<BhDropdownMenu values={sortValues} textProperty="translation" type={BhDropdownTypeEnum.STRING} onSelect={onSortSelectHandler} />}
        position={position}
      />
      <button onClick={onReversedClickHandler} className="bh-text-deep-ocean-imp hover:bh-bg-smoke disabled:bh-text-pigeon-60 bh-text-pigeon mr-0.5 rounded px-0.5 py-0.5 font-bold">
        <FontAwesomeIcon className="px-1" icon={sort.reversed ? faArrowDown : faArrowUp} />
      </button>
    </div>
  );
};

export default BhSortButton;
