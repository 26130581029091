import React, { FC, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhFilterContainer from "@components/filters/BhFilterContainer";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhFilter from "@components/filters/BhFilter";
import {
  clearProjectsFilter,
  selectCompanyUsersFilter,
  selectProjectFilterValuesInCompanySettingsAllUsers,
  selectShowArchivedProjects,
  setCompanyUserSearchKey,
  toggleArchivedProjects,
  toggleProjectInCompanyUsersFilter,
  usersFilterCleared
} from "@/app/store/companiesSlice";
import { ICompanyUsersFilter } from "@/model/companyUsers/ICompanyUsersFilter";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { ISimpleProject } from "@/model/projects/ISimpleProject";

interface Props {
  companyId: EntityId;
}

const CompanyUsersFilter: FC<Props> = ({ companyId }) => {
  const { t } = useTranslation();
  const filter = useAppSelector(selectCompanyUsersFilter);
  const projectFilterDropdownValues = useAppSelector((state) => selectProjectFilterValuesInCompanySettingsAllUsers(state, companyId));
  const showArchived = useAppSelector((state) => selectShowArchivedProjects(state));
  const dispatch = useAppDispatch();

  useEffect(() => {
    return function cleanup() {
      dispatch(usersFilterCleared());
    };
  }, []);

  const onNameFilterChange = useCallback(
    (changedValue: ICompanyUsersFilter) => {
      const changedObject = { ...filter, ...changedValue };
      dispatch(setCompanyUserSearchKey(changedObject));
    },
    [filter]
  );

  const onProjectFilterSelect = useCallback(
    (project: ISimpleProject) => {
      dispatch(toggleProjectInCompanyUsersFilter(project));
    },
    [filter.projects]
  );

  const onProjectFilterReset = useCallback(() => {
    dispatch(clearProjectsFilter());
  }, []);

  const numOfSelectedProjectsInFilter = filter.projects.length;
  const isProjectSelected = (projectId: EntityId) => filter.projects.some((project) => project.projectId === projectId);

  return (
    <BhFilterContainer>
      <div>
        <BhSearchInputWBG initialValue={filter.searchQuery} property="searchQuery" onChangeCallback={onNameFilterChange} placeholder={t("COMPANY.SETTINGS.USERS.SEARCH_BY_USER") as string} />
      </div>
      <div className="ml-4 font-bold">{t("GLOBAL.FILTER")}</div>
      <BhFilter
        title={t("COMPANY.SETTINGS.USERS.FILTER.PROJECT_NAME")}
        values={projectFilterDropdownValues}
        textProperty="projectName"
        onSelect={onProjectFilterSelect}
        isSelected={(value: ISimpleProject) => isProjectSelected(value.projectId)}
        onReset={onProjectFilterReset}
        selectedValues={numOfSelectedProjectsInFilter}
        closeOnItemClick={false}
      />
      <div className="inline-flex items-center">
        <p className="ml-3 mr-1.5">{t("HOME.SHOW_ARCHIVED")}</p>
        <BhToggleSwitch value={showArchived} onClickAction={() => dispatch(toggleArchivedProjects())} small={true} />
      </div>
    </BhFilterContainer>
  );
};

export default CompanyUsersFilter;
