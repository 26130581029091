import React, { FC, useRef, useState } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCaretUp } from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { allFileEntitiesUnSelected, fetchFileRevisionsAsync, replaceFileRevisionAsync, selectFileById } from "@/app/store/filesSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons/faCirclePlus";
import DirectoryFileRevisionUpload from "@/views/home/project/detail/directory/DirectoryFileRevisionUpload";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import { useDrop } from "react-dnd";
import { classNames } from "@/utilities/jsUtilities";
import { FileEntityType, IFileCopyOrMoveDTO, IFileEntity, IRevisionReplaceRequestDTO, NewVersionOption } from "@/model/files/IFileEntity";
import NewVersionModal from "@/views/home/project/detail/directory/directoryModals/copyAndMoveModals/NewVersionModal";

interface Props {
  revision: number;
  canUploadNewVersion: boolean;
  fileId: EntityId;
  isFolder?: boolean;
  isRevision?: boolean;
  setRevisionShown: Function;
  revisionsShown: boolean;
}

const BhFileCardRevisionNumber: FC<Props> = ({ revision, canUploadNewVersion, fileId, isFolder, isRevision, setRevisionShown, revisionsShown }) => {
  const { t } = useTranslation();
  const fileRevisionInputRef = useRef(null);
  const fileEntity = useAppSelector((state) => selectFileById(state, fileId));
  const [showRevisionAddOptionsModal, setShowRevisionAddOptionsModal] = useState(false);
  const [revisionAddOptionsModalDTO, setRevisionAddOptionsModalDTO] = useState<IFileCopyOrMoveDTO>();
  const dispatch = useAppDispatch();

  const [{ isOver, isAnyDragging }, dropRef] = useDrop({
    accept: "fileEntityRow",
    drop: (droppedFile: IFileEntity) => {
      if (canUploadNewVersion && droppedFile.id !== fileId && droppedFile.type !== FileEntityType.FORM) {
        if (droppedFile.revision > 1) {
          const dto = { fileToCopyOrMove: droppedFile, matchingFile: fileEntity } as IFileCopyOrMoveDTO;
          setShowRevisionAddOptionsModal(true);
          setRevisionAddOptionsModalDTO(dto);
        } else {
          dispatch(replaceFileRevisionAsync({ fileToReplaceId: fileId, replacementFileId: droppedFile.id } as IRevisionReplaceRequestDTO));
          dispatch(allFileEntitiesUnSelected());
          setRevisionShown(false);
        }
      }
    },
    collect: (monitor) => ({
      isOver: canUploadNewVersion && monitor.isOver(),
      isAnyDragging: canUploadNewVersion && monitor.canDrop()
    })
  });

  const addRevisionWithOptions = (option?: NewVersionOption) => {
    if (revisionAddOptionsModalDTO?.matchingFile && revisionAddOptionsModalDTO?.fileToCopyOrMove) {
      const dto = {
        fileToReplaceId: revisionAddOptionsModalDTO?.matchingFile.id,
        replacementFileId: revisionAddOptionsModalDTO?.fileToCopyOrMove.id,
        revisionOption: option
      } as IRevisionReplaceRequestDTO;
      dispatch(replaceFileRevisionAsync(dto));
      setShowRevisionAddOptionsModal(false);
      setRevisionAddOptionsModalDTO(undefined);
      setRevisionShown(false);
      dispatch(allFileEntitiesUnSelected());
    }
  };

  if (isFolder) {
    return null;
  }

  return (
    <div
      ref={canUploadNewVersion ? dropRef : undefined}
      className={classNames(isOver && "bh-outline-dark-jungle outline outline-1", isAnyDragging && "bh-bg-mint", "flex h-9 flex-row items-center justify-start overflow-hidden rounded")}
      onClick={(e: any) => e.stopPropagation()}
    >
      <div className="w-8">
        {canUploadNewVersion && !isAnyDragging && (
          <BhTooltip body={t("GLOBAL.ADD_NEW_VERSION")}>
            <BhIconButton
              icon={faCirclePlus}
              buttonProps={{
                onClick: (e: any) => {
                  e.preventDefault();
                  // @ts-ignore
                  fileRevisionInputRef.current.click();
                }
              }}
              sizeClasses="hidden group-hover:block w-6 h-8"
            />
          </BhTooltip>
        )}
      </div>
      {!isRevision && revision > 1 && (
        <div className="-ml-2">
          <BhIconButton
            icon={revisionsShown ? faCaretUp : faCaretDown}
            right={true}
            iconSize={"xs"}
            buttonProps={{
              onClick: (e: any) => {
                e.preventDefault();
                setRevisionShown(!revisionsShown);
                !revisionsShown && dispatch(fetchFileRevisionsAsync(fileId));
              }
            }}
          >
            {revision}
          </BhIconButton>
        </div>
      )}
      {(isRevision || revision === 1) && <span className="text-12px pl-1">{revision}</span>}
      {canUploadNewVersion && fileEntity && <DirectoryFileRevisionUpload fileInputRef={fileRevisionInputRef} revisionFile={fileEntity} />}
      {showRevisionAddOptionsModal && revisionAddOptionsModalDTO && (
        <NewVersionModal
          onClose={() => {
            setRevisionAddOptionsModalDTO(undefined);
            setShowRevisionAddOptionsModal(false);
          }}
          onSubmit={addRevisionWithOptions}
          dto={revisionAddOptionsModalDTO}
          isMoveModal={true}
        />
      )}
    </div>
  );
};

export default BhFileCardRevisionNumber;
