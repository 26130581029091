import React, { FC, useEffect } from "react";
import TaskInfoField from "@components/task/modal/TaskInfoField";
import { ITask } from "@/model/taskBoard/ITask";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import useInputIsActive from "@hooks/useInputIsActive";
import BhInputWithTags from "@components/input/BhInputWithTags";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { deleteTaskTagAsync, saveTaskTagAsync, selectOpenedTaskDisabled, selectProjectTaskTags, setNewTask } from "@/app/store/tasksSlice";
import { ITaskTag } from "@/model/taskBoard/ITaskTag";
import TaskTags from "@components/task/container/TaskTags";
import { useTranslation } from "react-i18next";
import { faTag } from "@fortawesome/pro-regular-svg-icons/faTag";

interface Props {
  task: ITask;
}

const TaskTagsInfoField: FC<Props> = ({ task }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isActive, toggleIsActive, inputRef } = useInputIsActive();
  const projectTaskTags = useAppSelector(selectProjectTaskTags);
  const isTaskDisabled = useAppSelector(selectOpenedTaskDisabled);
  const taskTags = task.tags || [];
  const isNewTask = !task.id;

  useEffect(() => {
    if (isActive) {
      inputRef.current.focus();
    }
  }, [isActive]);

  const selectTag = (tag: ITaskTag) => {
    if (taskTags.some((taskTag) => taskTag.name.toLowerCase() === tag.name.toLowerCase())) {
      return;
    }
    const newTag = { name: tag.name, projectId: task.projectId } as ITaskTag;
    if (isNewTask) {
      const taskToSet = { ...task, ...{ tags: [...taskTags, newTag] } };
      dispatch(setNewTask(taskToSet));
    } else {
      const tagToSave = { ...newTag, ...{ taskId: task.id } } as ITaskTag;
      dispatch(saveTaskTagAsync(tagToSave));
    }
  };

  const deleteTag = (tag: ITaskTag) => {
    if (isNewTask) {
      const taskToSet = { ...task, ...{ tags: taskTags.filter((t) => t.name !== tag.name) } };
      dispatch(setNewTask(taskToSet));
    } else {
      dispatch(deleteTaskTagAsync(tag));
    }
  };

  return (
    <div onClick={() => toggleIsActive(isTaskDisabled)} className="w-full">
      <TaskInfoField
        text={t("TASK.TAGS")}
        children={
          <div className="inline-block w-full overflow-hidden">
            {!isActive && taskTags.length > 0 && (
              <div className="py-2">
                <TaskTags tags={taskTags} />
              </div>
            )}
            {isActive && (
              <div className="py-1" onBlur={() => toggleIsActive()}>
                <BhInputWithTags
                  inputRef={inputRef}
                  onRemove={deleteTag}
                  onSelect={selectTag}
                  currentValues={taskTags}
                  dropdownValues={projectTaskTags}
                  dropdownFixed={true}
                  preventDefaultOnTagClick={true}
                />
              </div>
            )}
          </div>
        }
        valueExists={taskTags.length > 0}
        icon={faTag}
        trailingIcon={!isActive ? (taskTags.length > 0 ? faCaretDown : faPlus) : undefined}
        disabled={isTaskDisabled}
      />
    </div>
  );
};

export default TaskTagsInfoField;
