import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import TaskContainer from "@components/task/container/TaskContainer";
import { useDrag } from "react-dnd";

interface Props {
  taskId: EntityId;
}

const DnDTaskContainer: FC<Props> = React.memo(({ taskId }) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: "taskItem",
    item: { taskId: taskId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <div ref={dragRef} className={isDragging ? "opacity-70" : ""}>
      <TaskContainer taskId={taskId} />
    </div>
  );
});

export default DnDTaskContainer;
