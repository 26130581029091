import { IContract } from "@/model/contracts/IContract";
import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { EntityId } from "@reduxjs/toolkit";
import { IContractWork } from "@/model/contracts/IContractWork";
import { IContractWorkType } from "@/model/contracts/IContractWorkType";

export function fetchContractByPartyId(projectId: EntityId, partyId: EntityId): Promise<IContract> {
  return bauhubGet("/project/" + projectId + "/party/" + partyId + "/contract");
}

export function saveNewContractWork(work: IContractWork): Promise<Array<IContractWork>> {
  return bauhubPost("/project/contract/work/new", work);
}

export function saveNewContractWorkWithParentId(work: IContractWork): Promise<Array<IContractWork>> {
  return bauhubPost("/v2/project/contract/work/new", work);
}

export function saveContractWork(work: IContractWork): Promise<Array<IContractWork>> {
  return bauhubPost("/v2/project/contract/work", work);
}

export function saveContract(contract: IContract): Promise<IContract> {
  return bauhubPost("/project/" + contract.projectId + "/party/" + contract.workGroupId + "/contract", contract);
}

export function saveContractWorkType(contractWorkType: IContractWorkType): Promise<IContractWorkType> {
  return bauhubPost("/project/contract/work/type", contractWorkType);
}
