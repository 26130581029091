import React, { FC } from "react";
import { IFileEntity, IFileMatch, RevisionFileVersion } from "@/model/files/IFileEntity";
import RevisionModalFileCard from "@/views/home/project/detail/directory/directoryModals/revisionModal/RevisionModalFileCard";

interface Props {
  fileMatch: IFileMatch;
  fileMatchSelected: Function;
  unusedDirectoryFiles: Array<IFileEntity>;
}

const RevisionModalFileCardPaired: FC<Props> = ({ fileMatch, fileMatchSelected, unusedDirectoryFiles }) => {
  return (
    <div className="bh-border-pigeon-40 flex w-full flex-col gap-y-0.5 rounded py-1.5 pl-1.5 pr-1">
      <RevisionModalFileCard fileMatch={fileMatch} version={RevisionFileVersion.NEW} showVersionNumber={true} fileMatchSelected={fileMatchSelected} />
      <RevisionModalFileCard fileMatch={fileMatch} version={RevisionFileVersion.OLD} showVersionNumber={true} fileMatchSelected={fileMatchSelected} unusedDirectoryFiles={unusedDirectoryFiles} />
    </div>
  );
};

export default RevisionModalFileCardPaired;
