import React, { FC, useEffect } from "react";
import BhBreadcrumbs from "@components/breadcrumb/BhBreadcrumbs";
import BhTabs from "@components/tabs/BhTabs";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import PartyTemplateUsersContainer from "@/views/home/company/detail/companySettingsPartyTemplates/PartyTemplateUsersContainer";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchCompanyAsync, fetchPredefinedPartiesAsync, selectCompanyById, selectCompanyPredefinedPartyById } from "@/app/store/companiesSlice";
import { WorkGroupType } from "@/model/IWorkGroup";
import { useTranslation } from "react-i18next";
import PartyFilesTextContainer from "@/views/home/project/detail/users/parties/party/PartyFilesTextContainer";
import PartyTemplateDirSelectionTab from "@/views/home/company/detail/companySettingsPartyTemplates/PartyTemplateDirSelectionTab";
import PartyDocumentsTextContainer from "@/views/home/project/detail/users/parties/party/PartyDocumentsTextContainer";
import { FileTreeType } from "@/model/files/IFileTreeTemplate";
import PartyTemplateGeneralSettingsTab from "@/views/home/company/detail/companySettingsPartyTemplates/PartyTemplateGeneralSettingsTab";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  companyId: EntityId;
  predefinedPartyId: EntityId;
}

const PartyTemplateSettingsContainer: FC<Props> = ({ companyId, predefinedPartyId }) => {
  const { t } = useTranslation();
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const predefinedParty = useAppSelector((state) => selectCompanyPredefinedPartyById(state, [companyId, predefinedPartyId]));
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCompanyAsync(companyId));
    dispatch(fetchPredefinedPartiesAsync(companyId));
  }, []);

  useEffect(() => {
    if (company?.id) {
      dispatch(fetchPredefinedPartiesAsync(companyId));
    }
  }, [company?.id]);

  if (!predefinedParty) return null;

  const tabs = [
    {
      id: 0,
      header: t("WORKGROUP.TAB.USERS"),
      content: <PartyTemplateUsersContainer companyId={companyId} predefinedPartyId={predefinedPartyId} />,
      href: "users"
    },
    {
      id: 1,
      header: t("COMPANY.SETTINGS.DRAWINGS"),
      content: (
        <PartyFilesTextContainer>
          <PartyTemplateDirSelectionTab predefinedPartyId={predefinedPartyId} companyId={companyId} type={FileTreeType.DRAWINGS} />
        </PartyFilesTextContainer>
      ),
      href: "drawings"
    },
    {
      id: 2,
      header: t("GLOBAL.DOCUMENTS"),
      content: (
        <PartyDocumentsTextContainer>
          <PartyTemplateDirSelectionTab predefinedPartyId={predefinedPartyId} companyId={companyId} type={FileTreeType.DOCUMENTS} />
        </PartyDocumentsTextContainer>
      ),
      href: "documents"
    },
    {
      id: 3,
      header: t("WORKGROUP.TAB.GENERAL"),
      content: <PartyTemplateGeneralSettingsTab partyId={predefinedPartyId} companyId={companyId} />,
      href: "general"
    }
  ];

  const breadcrumbValues = [
    { text: t("HOME.MY_PROJECTS"), url: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/` },
    { text: t("COMPANY.SETTINGS.HEADER", { name: company?.name || "" }), url: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${companyId}/work-group-templates` },
    { text: t("CONTACTS.FILTER.USER_GROUP") + " " + predefinedParty?.name }
  ];

  const partyTypes: Record<WorkGroupType, string> = {
    [WorkGroupType.SUBCONTRACTOR]: t("PARTY.SUBCONTRACTOR"),
    [WorkGroupType.CLIENT]: t("PARTY.CLIENT")
  };

  const partyTagTypes: Record<WorkGroupType, BhTagType> = {
    [WorkGroupType.SUBCONTRACTOR]: BhTagType.ALLTOOVOTJA,
    [WorkGroupType.CLIENT]: BhTagType.TELLIJA
  };

  return (
    <div className="h-full w-full overflow-hidden">
      <BhScrollableBody>
        <div className="mx-44 mt-6 lg:mx-8">
          <BhBreadcrumbs values={breadcrumbValues} />
          <div className="bh-bg-smoke mt-5 rounded-t px-6 pb-4 pt-4">
            <h3>{t("COMPANY.SETTINGS.PARTY")}</h3>
            <h2 className="bh-text-deep-ocean">{predefinedParty.name}</h2>
            {predefinedParty.type && <BhTag type={partyTagTypes[predefinedParty.type]}>{partyTypes[predefinedParty.type]}</BhTag>}
          </div>
          <BhTabs tabs={tabs} router={true} tabsContainerClasses="bh-bg-smoke" />
        </div>
      </BhScrollableBody>
    </div>
  );
};

export default PartyTemplateSettingsContainer;
