import React, { FC } from "react";
import { IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import BhInputSimple from "@components/input/BhInputSimple";
import BhTextareaSimple from "@components/input/BhTextareaSimple";
import BhToggleButtonBar from "@components/toggle/BhToggleButtonBar";
import BhCheckboxWithText from "@components/checkboxes/BhCheckboxWithText";
import { useTranslation } from "react-i18next";

interface Props {
  baseVersionRow: IChecklistBaseVersionRow;
  saveCallback: Function;
  changeFieldTypeCallback: Function;
  disabled?: boolean;
}

const ChecklistBaseLargeTextfield: FC<Props> = ({ baseVersionRow, changeFieldTypeCallback, saveCallback, disabled }) => {
  const { t } = useTranslation();

  const size = [
    { text: t("CHECKLIST_BASE.TEXT_FIELD.SIZE.SMALL").toUpperCase(), value: "SMALL_TEXTFIELD" },
    { text: t("CHECKLIST_BASE.TEXT_FIELD.SIZE.LARGE").toUpperCase(), value: "LARGE_TEXTFIELD" }
  ];

  return (
    <div className="flex flex-col">
      <div className="mb-2 flex flex-row items-center">
        <div className="w-40">
          <BhToggleButtonBar items={size} onClickAction={changeFieldTypeCallback} selected={baseVersionRow.fieldType} property={"fieldType"} disabled={disabled} />
        </div>
        <BhCheckboxWithText text={t("CHECKLIST.ROW.IS_EDITABLE")} isChecked={baseVersionRow.editable} property={"editable"} isSmallCheckbox={true} onChange={saveCallback} disabled={disabled} />
        <BhCheckboxWithText
          text={t("CHECKLIST.ROW.IS_REQUIRED")}
          isChecked={baseVersionRow.required}
          property={"required"}
          isSmallCheckbox={true}
          onChange={saveCallback}
          disabled={!baseVersionRow.editable || disabled}
        />
      </div>
      <div className="flex w-full flex-col gap-y-2">
        <div className="w-full">
          <BhInputSimple
            initialValue={baseVersionRow.fieldTitle}
            property={"fieldTitle"}
            placeholder={t("CHECKLIST_BASE.TEXT_FIELD.NAME.PLACEHOLDER") as string}
            onBlurAction={saveCallback}
            disabled={disabled}
          />
        </div>
        <div className="w-full">
          <BhTextareaSimple
            initialValue={baseVersionRow.fieldValue}
            property={"fieldValue"}
            placeholder={t("CHECKLIST_BASE.TEXT_FIELD.PLACEHOLDER") as string}
            onBlurCallback={saveCallback}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default ChecklistBaseLargeTextfield;
