import BhProjectStatusBadge from "@/components/badge/BhProjectStatusBadge";
import BhCardContainer from "@/components/cards/BhCardContainer";
import BhLargeBoldText from "@/components/text/BhLargeBoldText";
import { ISyncProject } from "@/model/sync/ISyncProject";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { faCircleCheck as faCircleCheckRegular } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProjectStatus } from "@/model/IProject";
import ProjectListItemTag from "@/features/projectList/projectListItem/ProjectListItemTag";

interface Props {
  project: ISyncProject;
}

const SyncProjectListItemContainer: FC<Props> = ({ project }) => {
  const { t } = useTranslation();

  const archivedClasses = project.status === ProjectStatus.ARCHIVED ? " bh-text-pigeon-70 bh-bg-smoke " : " bh-text-deep-ocean-80 ";
  const syncClasses = project.fullySynced || project.partlySynced ? " bh-bg-mint-50" : "";

  return (
    <BhCardContainer classes={archivedClasses + syncClasses}>
      <Link
        className="w-full cursor-pointer"
        to={`/sync/${project.id}/picker`}
        onClick={(e) => {
          if (project.status === ProjectStatus.ARCHIVED) {
            e.preventDefault();
          }
        }}
      >
        <div className="group flex items-center transition duration-300">
          <BhProjectStatusBadge type={project.securityLevel} />
          <div className="flex h-20 flex-1 flex-col flex-wrap justify-center pl-4 transition duration-300">
            <BhLargeBoldText classes="mt-1">{project.name}</BhLargeBoldText>
            <ProjectListItemTag project={project} />
            {project.fullySynced && (
              <div className="flex items-center">
                <FontAwesomeIcon icon={faCircleCheck} className="bh-text-success-green" />
                <span className="bh-text-pigeon pl-1">{t("SYNC.ALL_DIRS_MARKED")}</span>
              </div>
            )}
            {project.partlySynced && (
              <div className="flex items-center">
                <FontAwesomeIcon icon={faCircleCheckRegular} className="bh-text-success-green" />
                <span className="bh-text-pigeon pl-1">{t("SYNC.DIRS_PARTLY_MARKED")}</span>
              </div>
            )}
          </div>
        </div>
      </Link>
    </BhCardContainer>
  );
};

export default SyncProjectListItemContainer;
