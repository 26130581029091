import React, { FC } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons/faCirclePlus";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { useTranslation } from "react-i18next";
import FormWeatherFieldHeader from "@components/form/fields/weatherField/FormWeatherFieldHeader";
import FormWeatherFieldRow from "@components/form/fields/weatherField/FormWeatherFieldRow";

interface Props {
  rows?: any;
  cols?: any;
}

const FormWeatherFieldTablePreview: FC<Props> = ({ rows, cols }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full">
      {rows.map((row: any, rowIndex: number) => (
        <div className="flex flex-col gap-1" key={rowIndex}>
          {rowIndex === 0 && <FormWeatherFieldHeader columns={cols} />}
          <FormWeatherFieldRow row={row} columns={cols} disabled={true} />
        </div>
      ))}

      <div className="mt-1 flex flex-row justify-between">
        <BhIconButton icon={faCirclePlus} buttonProps={{ disabled: true }} sizeClasses="w-max">
          {t("FORMBUILDER.FORM.BASE.FIELD.WEATHER.ADD_ROW")}
        </BhIconButton>
        <BhTextOnlyButton buttonProps={{ disabled: true }}>{t("FORMBUILDER.FORM.BASE.FIELD.WEATHER.CHECK_WEATHER")}</BhTextOnlyButton>
      </div>
    </div>
  );
};

export default FormWeatherFieldTablePreview;
