import { EntityId } from "@reduxjs/toolkit";

export interface IFormBaseVersionRowField {
  _id: string;
  formBaseVersionId: EntityId;
  orderNumber: number;
  fieldType: FormRowFieldType;
  property: string;
  value: any;
  newField: boolean;
  removedField: boolean;
}

export enum FormRowFieldType {
  "TEXT" = "TEXT",
  "PEOPLE" = "PEOPLE",
  "DATE" = "DATE",
  "TIME" = "TIME",
  "SEPARATOR" = "SEPARATOR",
  "PROTOCOL" = "PROTOCOL",
  "WEATHER" = "WEATHER",
  "TABLE" = "TABLE",
  "ATTACHMENT" = "ATTACHMENT",
  "WYSIWYG" = "WYSIWYG",
  "MNT_MP_TABLE_FIELD" = "MNT_MP_TABLE_FIELD",
  "TOOPLAAN_TABLE" = "TOOPLAAN_TABLE",
  "TEXT_EDITOR" = "TEXT_EDITOR",
  "SCM_TABLE" = "SCM_TABLE",
  "SCM_TASKS" = "SCM_TASKS",
  "UPLOAD" = "UPLOAD",
  "ATTACHMENTS_WITH_DATES" = "ATTACHMENTS_WITH_DATES"
}

export const FormRowFieldTypes = [
  FormRowFieldType.TEXT,
  FormRowFieldType.PEOPLE,
  FormRowFieldType.DATE,
  FormRowFieldType.TIME,
  FormRowFieldType.SEPARATOR,
  FormRowFieldType.PROTOCOL,
  FormRowFieldType.WEATHER,
  FormRowFieldType.TABLE,
  FormRowFieldType.ATTACHMENT,
  FormRowFieldType.WYSIWYG
];
