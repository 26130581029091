import React, { FC } from "react";
import BhBanner from "@components/banners/BhBanner";
import { BauhubBannerType } from "@/model/IProject";
import { Dayjs } from "dayjs";
import { formatDateTime } from "@/utilities/dateUtility";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { textWithVariables } from "@/utilities/jsUtilities";

interface Props {
  expiresAt: Dayjs;
}

const PublicContainerTimeLeft: FC<Props> = ({ expiresAt }) => {
  const { t } = useTranslation();

  return (
    <BhBanner type={BauhubBannerType.WARNING} fullWidth={true}>
      <span>
        {parse(
          textWithVariables(t("PUBLIC.CONTAINER.VALID_TO"), {
            dateTime: formatDateTime(expiresAt.toDate())
          })
        )}
      </span>
    </BhBanner>
  );
};

export default PublicContainerTimeLeft;
