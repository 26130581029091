import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import { IObjectChange } from "@/model/IObjectChange";
import { fetchChecklistLogsForProject, restoreChecklistFromLogs } from "@/api/checklist/checklistSettingsAPI";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft } from "@fortawesome/pro-regular-svg-icons/faClockRotateLeft";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IBauhubEvent } from "@/model/IBauhubEvent";
import BhTableHeader from "@components/table/BhTableHeader";
import ProjectLogTableRowContainerSkeleton from "@/features/projectLogTable/ProjectLogTableRowContainerSkeleton";
import ProjectChecklistLogsModalRow from "@components/checklists/settings/projectSettings/projectChecklistsLogsModal/ProjectChecklistLogsModalRow";
import { fetchChecklistsForUserAsync, selectChecklistFilter } from "@/app/store/checklist/checklistsSlice";

interface Props {
  setIsShown: Dispatch<SetStateAction<boolean>>;
  archived?: boolean;
}

const ProjectChecklistLogsModal: FC<Props> = ({ setIsShown, archived }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const filter = useAppSelector(selectChecklistFilter);
  const [logs, setLogs] = useState([] as Array<IObjectChange>);
  const [logsLoading, setLogsLoading] = useState(true);

  useEffect(() => {
    setLogsLoading(true);
    fetchChecklistLogsForProject(projectId).then((response) => {
      const sortedLogs = response.map((log) => log).sort((a, b) => (new Date(a.created) < new Date(b.created) ? 1 : -1));
      setLogs(sortedLogs);
      setLogsLoading(false);
    });
  }, []);

  const tableColumnHeaders: Array<IBhTableHeader<IBauhubEvent>> = [
    { id: 1, content: t("LOG.TYPE"), field: "type", classes: "w-80" },
    { id: 2, content: t("LOG.NAME"), field: "fileType" },
    { id: 3, content: t("LOG.CREATED"), field: "created", classes: "w-52" }
  ];

  const tableHeaderOnClick = () => {};

  const restoreChecklist = (log: IObjectChange) => {
    setLogsLoading(true);
    restoreChecklistFromLogs(projectId, log.objectId).then((response) => {
      const sortedLogs = response.map((log) => log).sort((a, b) => (new Date(a.created) < new Date(b.created) ? 1 : -1));
      setLogs(sortedLogs);
      setLogsLoading(false);
      const newFilter = archived ? { ...filter, ...{ archived: true } } : filter;
      dispatch(fetchChecklistsForUserAsync({ projectId: projectId, filter: newFilter }));
    });
  };

  const isRestorable = (log: IObjectChange) => {
    return (
      log.field === "deleted" &&
      log.newValue === "true" &&
      logs.filter((l) => l.field === "deleted" && l.newValue === "false" && l.objectId === log.objectId).length <
        logs.filter((l) => l.field === "deleted" && l.newValue === "true" && l.objectId === log.objectId).length
    );
  };

  return (
    <BhModal
      isShown={true}
      size={"5xl"}
      setIsShown={setIsShown}
      header={
        <div className="h-7">
          <h2 className="bh-text-deep-ocean mt-1 leading-7">
            <FontAwesomeIcon icon={faClockRotateLeft} />
            <span className="ml-4">{t("CHECKLIST.HISTORY.MODAL.HEADER")}</span>
          </h2>
        </div>
      }
      children={
        <BhScrollableBody>
          <div className="p-8">
            <table className="w-full table-fixed">
              <BhTableHeader columns={tableColumnHeaders} onClickCallback={tableHeaderOnClick} sortedBy={"created"} reversed={true} />
              <tbody className="divide-y">
                {logsLoading && <ProjectLogTableRowContainerSkeleton numberOfDisplayedItems={5} />}
                {!logsLoading && logs.map((log) => <ProjectChecklistLogsModalRow key={log.id} log={log} restoreChecklist={restoreChecklist} isRestorable={isRestorable} />)}
                {!logsLoading && logs.length === 0 && (
                  <tr>
                    <td colSpan={10} className="center pt-10 text-center">
                      <h2>{t("LOG._NO_HISTORY")}</h2>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </BhScrollableBody>
      }
      footer={<BhModalFooter onCancel={() => setIsShown(false)} cancelButtonText={t("GLOBAL.CLOSE") as string} />}
    />
  );
};

export default ProjectChecklistLogsModal;
