import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faShareSquare } from "@fortawesome/pro-regular-svg-icons/faShareSquare";
import { faCog } from "@fortawesome/pro-regular-svg-icons/faCog";
import { faHistory } from "@fortawesome/pro-regular-svg-icons/faHistory";
import { faHome } from "@fortawesome/pro-regular-svg-icons/faHome";
import { faSquareKanban } from "@fortawesome/pro-regular-svg-icons/faSquareKanban";
import { faUserGroup } from "@fortawesome/pro-regular-svg-icons/faUserGroup";
import { faPenRuler } from "@fortawesome/pro-regular-svg-icons/faPenRuler";
import { faFiles } from "@fortawesome/pro-regular-svg-icons/faFiles";
import { faSignature } from "@fortawesome/pro-regular-svg-icons/faSignature";
import { faMemoCircleCheck } from "@fortawesome/pro-regular-svg-icons/faMemoCircleCheck";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons/faListCheck";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons/faArrowsRotate";
import { faQuestion, faQuestion as faQuestionSolid } from "@fortawesome/pro-solid-svg-icons/faQuestion";
import { faEuroSign } from "@fortawesome/pro-regular-svg-icons/faEuroSign";
import { faTable } from "@fortawesome/pro-regular-svg-icons/faTable";
import { faCheckDouble } from "@fortawesome/pro-regular-svg-icons/faCheckDouble";
import { faCheckDouble as faCheckDoubleSolid } from "@fortawesome/pro-solid-svg-icons/faCheckDouble";
import { faShareSquare as faShareSquareSolid } from "@fortawesome/pro-solid-svg-icons/faShareSquare";
import { faCog as faCogSolid } from "@fortawesome/pro-solid-svg-icons/faCog";
import { faHistory as faHistorySolid } from "@fortawesome/pro-solid-svg-icons/faHistory";
import { faHome as faHomeSolid } from "@fortawesome/pro-solid-svg-icons/faHome";
import { faSquareKanban as faSquareKanbanSolid } from "@fortawesome/pro-solid-svg-icons/faSquareKanban";
import { faUserGroup as faUserGroupSolid } from "@fortawesome/pro-solid-svg-icons/faUserGroup";
import { faPenRuler as faPenRulerSolid } from "@fortawesome/pro-solid-svg-icons/faPenRuler";
import { faFiles as faFilesSolid } from "@fortawesome/pro-solid-svg-icons/faFiles";
import { faSignature as faSignatureSolid } from "@fortawesome/pro-solid-svg-icons/faSignature";
import { faMemoCircleCheck as faMemoCircleCheckSolid } from "@fortawesome/pro-solid-svg-icons/faMemoCircleCheck";
import { faListCheck as faListCheckSolid } from "@fortawesome/pro-solid-svg-icons/faListCheck";
import { faArrowsRotate as faArrowsRotateSolid } from "@fortawesome/pro-solid-svg-icons/faArrowsRotate";
import { faEuroSign as faEuroSignSolid } from "@fortawesome/pro-solid-svg-icons/faEuroSign";
import { faTable as faTableSolid } from "@fortawesome/pro-solid-svg-icons/faTable";
import { faCube } from "@fortawesome/pro-regular-svg-icons/faCube";
import { faCube as faCubeSolid } from "@fortawesome/pro-solid-svg-icons/faCube";

export interface ISidebarItem {
  id: number;
  nameCode: string;
  type?: SidebarItemType;
  href?: string;
  onClick?: Function;
  icon?: IconDefinition;
  iconActive?: IconDefinition;
  needsAdmin?: boolean;
}

export enum SidebarItemType {
  DIVIDER = "DIVIDER",
  OVERVIEW = "OVERVIEW",
  TASKBOARDS = "TASKBOARDS",
  USERS = "USERS",
  DRAWINGS = "DRAWINGS",
  DOCUMENTS = "DOCUMENTS",
  BIM = "BIM",
  SIGN_INVITES = "SIGN_INVITES",
  SHAREBOXES = "SHAREBOXES",
  CONFIRMATIONS = "CONFIRMATIONS",
  CONTRACTS = "CONTRACTS",
  CHECKLISTS = "CHECKLISTS",
  PROJECT_SETTINGS = "PROJECT_SETTINGS",
  PROJECT_LOG = "PROJECT_LOG",
  BAUDRIVE = "BAUDRIVE",
  SUPPORT = "SUPPORT",
  SEARCH = "SEARCH",

  // CUSTOM Sidebar items
  BUDGET = "BUDGET",
  BUDGET_V2 = "BUDGET_V2",
  MR_BUDGET = "MR_BUDGET",
  MR_4R = "MR_4R",
  MR_DASHBOARD = "MR_DASHBOARD"
}

export const SidebarInitialState: Array<ISidebarItem> = [
  {
    id: 1,
    nameCode: "PROJECT.SIDEBAR.DASHBOARD",
    href: "overview",
    icon: faHome,
    iconActive: faHomeSolid,
    type: SidebarItemType.OVERVIEW
  },
  {
    id: 2,
    nameCode: "GLOBAL.TASKBOARDS",
    href: "taskboard/list",
    icon: faSquareKanban,
    iconActive: faSquareKanbanSolid,
    type: SidebarItemType.TASKBOARDS
  },
  {
    id: 3,
    nameCode: "GLOBAL.USERS",
    href: "users/parties",
    icon: faUserGroup,
    iconActive: faUserGroupSolid,
    type: SidebarItemType.USERS
  },
  { id: 4, nameCode: "", type: SidebarItemType.DIVIDER },
  {
    id: 5,
    nameCode: "GLOBAL.DRAWINGS",
    href: "dir",
    icon: faPenRuler,
    iconActive: faPenRulerSolid,
    type: SidebarItemType.DRAWINGS
  },
  {
    id: 6,
    nameCode: "GLOBAL.DOCUMENTS",
    href: "dir",
    icon: faFiles,
    iconActive: faFilesSolid,
    type: SidebarItemType.DOCUMENTS
  },
  {
    id: 7,
    nameCode: "PROJECT.CHECKLISTS",
    href: "checklists",
    icon: faListCheck,
    iconActive: faListCheckSolid,
    type: SidebarItemType.CHECKLISTS
  },
  {
    id: 8,
    nameCode: "GLOBAL.MODEL",
    href: "bim",
    icon: faCube,
    iconActive: faCubeSolid,
    type: SidebarItemType.BIM
  },
  {
    id: 9,
    nameCode: "PROJECT.SIDEBAR.SIGNATURES",
    href: "sign/personal",
    icon: faSignature,
    iconActive: faSignatureSolid,
    type: SidebarItemType.SIGN_INVITES
  },
  {
    id: 10,
    nameCode: "HOME.SHAREBOXES",
    href: "shareboxes/user",
    icon: faShareSquare,
    iconActive: faShareSquareSolid,
    type: SidebarItemType.SHAREBOXES
  },
  {
    id: 11,
    nameCode: "GLOBAL.CONFIRMATIONS",
    href: "confirmations/personal",
    icon: faCheckDouble,
    iconActive: faCheckDoubleSolid,
    type: SidebarItemType.CONFIRMATIONS
  },
  {
    id: 12,
    nameCode: "GLOBAL.ACTS_AND_CONTRACTS",
    href: "contracts",
    icon: faMemoCircleCheck,
    iconActive: faMemoCircleCheckSolid,
    type: SidebarItemType.CONTRACTS
  },
  {
    id: 13,
    nameCode: "PROJECT.BUDGET",
    href: "budget",
    icon: faEuroSign,
    iconActive: faEuroSignSolid,
    type: SidebarItemType.BUDGET,
    needsAdmin: true
  },
  {
    id: 14,
    nameCode: "PROJECT.BUDGET_V2",
    href: "budget/v2",
    icon: faTable,
    iconActive: faTableSolid,
    type: SidebarItemType.BUDGET_V2,
    needsAdmin: true
  },
  {
    id: 15,
    nameCode: "PROJECT.MR_DASHBOARD",
    href: "mr/dashboard",
    icon: faTable,
    iconActive: faTableSolid,
    type: SidebarItemType.MR_DASHBOARD,
    needsAdmin: true
  },
  {
    id: 16,
    nameCode: "PROJECT.MR_BUDGET",
    href: "mr/budget",
    icon: faEuroSign,
    iconActive: faEuroSignSolid,
    type: SidebarItemType.MR_BUDGET,
    needsAdmin: true
  },
  {
    id: 17,
    nameCode: "PROJECT.MR_4R",
    href: "mr/4r",
    icon: faEuroSign,
    iconActive: faEuroSignSolid,
    type: SidebarItemType.MR_4R,
    needsAdmin: true
  },
  { id: 20, nameCode: "", type: SidebarItemType.DIVIDER },
  {
    id: 21,
    nameCode: "PROJECT.SIDEBAR.SETTINGS",
    href: "settings",
    icon: faCog,
    iconActive: faCogSolid,
    type: SidebarItemType.PROJECT_SETTINGS,
    needsAdmin: true
  },
  {
    id: 22,
    nameCode: "PROJECT.LOG",
    href: "log",
    icon: faHistory,
    iconActive: faHistorySolid,
    type: SidebarItemType.PROJECT_LOG
  },
  {
    id: 23,
    nameCode: "GLOBAL.BAUDRIVE",
    href: "baudrive",
    icon: faArrowsRotate,
    iconActive: faArrowsRotateSolid,
    type: SidebarItemType.BAUDRIVE
  },
  { id: 24, nameCode: "", type: SidebarItemType.DIVIDER },
  {
    id: 25,
    nameCode: "PROJECT.NAVBAR.HELP",
    href: "support",
    icon: faQuestion,
    iconActive: faQuestionSolid,
    type: SidebarItemType.SUPPORT
  }
];
