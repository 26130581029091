import React, { FC, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhRadioGroup from "@components/input/BhRadioGroup";
import BhRadioInput from "@components/input/BhRadioInput";
import { IFileCopyOrMoveDTO, IFileMoveOrCopyRequestDTO } from "@/model/files/IFileEntity";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { textWithVariables } from "@/utilities/jsUtilities";
import parse from "html-react-parser";

interface Props {
  dto: IFileCopyOrMoveDTO;
  onClose: Function;
  projectId: EntityId;
  copyRevisions: boolean;
}

const DuplicateFolderNameWarningModal: FC<Props> = ({ dto, onClose, projectId, copyRevisions }) => {
  const { t } = useTranslation();
  const [mergeFolders, setMergeFolders] = useState(false);
  const fileCopyOrMoveRequestDTO = {
    fileIds: [dto.fileToCopyOrMove.id],
    destinationId: dto.destinationDirectory.id,
    fromId: dto.fileToCopyOrMove.parentFileEntityId,
    projectId: projectId,
    copyRevisions: copyRevisions,
    keepSameName: false
  } as IFileMoveOrCopyRequestDTO;

  const close = () => {
    const dtoToRemove = { fileIds: fileCopyOrMoveRequestDTO.fileIds } as IFileMoveOrCopyRequestDTO;
    onClose([dtoToRemove]);
  };

  const submit = () => {
    const dtoWithMergeFolders = { ...fileCopyOrMoveRequestDTO, mergeFolders: mergeFolders } as IFileMoveOrCopyRequestDTO;
    onClose([dtoWithMergeFolders]);
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={close}
      header={<h2>{t("DUPLICATE_FOLDER.MODAL.HEADER")}</h2>}
      size="4xl"
      children={
        <div className="mx-20 my-14 pb-48">
          <div>{parse(textWithVariables(t("DUPLICATE_FOLDER.MODAL.BODY"), { folderName: dto.destinationDirectory.name, fileName: dto.fileToCopyOrMove.name }))}</div>
          <BhRadioGroup>
            <BhRadioInput property="" checked={!mergeFolders} label={t("DUPLICATE_FOLDER.MODAL.OPTION.CREATE_SEPARATE_FOLDERS") as string} onChange={() => setMergeFolders(false)} />
            <BhRadioInput property="" checked={mergeFolders} label={t("DUPLICATE_FOLDER.MODAL.OPTION.MERGE_FOLDERS") as string} onChange={() => setMergeFolders(true)} />
          </BhRadioGroup>
        </div>
      }
      footer={<BhModalFooter onCancel={close} onConfirm={submit} confirmButtonText={`${t("GLOBAL.SAVE")}`} />}
    />
  );
};

export default DuplicateFolderNameWarningModal;
