import React, { FC } from "react";

interface Props {
  children: React.ReactNode;
}

// FIGMA https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H/Bauhub-design-system?node-id=284%3A2448

const BhSearchInputContainerInDropdown: FC<Props> = ({ children }) => {

  return <div className="px-4 pb-1">{children}</div>;
};

export default BhSearchInputContainerInDropdown;
