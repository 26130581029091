import React, { FC, useEffect, useRef, useState } from "react";
import UserNotificationTypeIconContainer from "@/views/home/notifications/UserNotificationTypeIconContainer";
import SeenIndicator from "@/views/home/notifications/SeenIndicator";
import { getCombinedParams, getNewTranslationCode, IUserNotification, UserNotificationType } from "@/model/IUserNotification";
import { textWithVariables } from "@/utilities/jsUtilities";
import parse from "html-react-parser";
import ReactTimeAgo from "react-time-ago";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useNavigate } from "react-router-dom";
import { toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { fetchPersonalTaskAsync, fetchTaskAsync, setOpenedTaskInfo } from "@/app/store/tasksSlice";
import { setNotificationSeenAsync } from "@/app/store/userNotificationsSlice";
import NotificationTaskController from "@components/notifications/NotificationTaskController";
import { useTranslation } from "react-i18next";
import { IFileEntity } from "@/model/files/IFileEntity";
import { allFileEntitiesUnSelected, directoryInfoBarTabSet, fileEntitySelected, selectFileById } from "@/app/store/filesSlice";
import { fetchFileEntity } from "@/api/fileAPI";
import { DirectoryFileInfoBarTab } from "@/views/home/project/detail/directory/fileInfoBar/DirectoryFileInfoBar";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  notification: IUserNotification;
  callBack?: Function;
}

const UserNotificationListItemContainer: FC<Props> = ({ notification, callBack }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const combinedParams = getCombinedParams(notification);
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const relatedFileEntity = useAppSelector<IFileEntity | undefined>((state) => selectFileById(state, combinedParams.fileId));

  useEffect(() => {
    if (ref.current && !notification.seen) {
      const observer = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          const [entry] = entries;
          setIsIntersecting(entry.isIntersecting);

          if (entry.isIntersecting) {
            timeoutIdRef.current = setTimeout(() => {
              if (ref.current && isIntersecting) {
                dispatch(setNotificationSeenAsync(notification));
              }
            }, 1000);
          } else {
            if (timeoutIdRef.current !== null) {
              clearTimeout(timeoutIdRef.current);
              timeoutIdRef.current = null;
            }
          }
        },
        { threshold: [1.0] }
      );
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
        if (timeoutIdRef.current !== null) {
          clearTimeout(timeoutIdRef.current);
          timeoutIdRef.current = null;
        }
      };
    }
  }, [isIntersecting, timeoutIdRef]);

  const onClick = async function () {
    !notification.seen && dispatch(setNotificationSeenAsync(notification));
    callBack && callBack(false);

    if (
      notification.type === UserNotificationType.NEW_FILE ||
      notification.type === UserNotificationType.FILE_RENAMED ||
      notification.type === UserNotificationType.NEW_REVISION ||
      notification.type === UserNotificationType.FILE_DELETED ||
      notification.type === UserNotificationType.FILES_ADDED
    ) {
      if (combinedParams.dirId) {
        if (combinedParams.fileType === "FILE" || combinedParams.fileType === "DIR") {
          // seda tüüpi teavitusi ei esine enam tegelt, viimased sellised on 2017. aastast andmebaasis
          navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${notification.projectId}/dir/${combinedParams.dirId}`);
        }
      }
    } else if (notification.type === UserNotificationType.NEW_DIR) {
      if (combinedParams.fileType === "DIR") {
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${notification.projectId}/dir/${combinedParams.dirId}`);
      }
    } else if (notification.type === UserNotificationType.NEW_PRIVILEGES) {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${notification.projectId}/overview`);
    } else if (
      notification.type === UserNotificationType.NEW_TASK ||
      notification.type === UserNotificationType.NEW_TASK_STATUS ||
      notification.type === UserNotificationType.NEW_TASK_COMMENT ||
      notification.type === UserNotificationType.TASK_COMMENT_DELETE ||
      notification.type === UserNotificationType.TASK_COMMENT_EDIT
    ) {
      if (combinedParams.taskIdentifier) {
        await dispatch(fetchTaskAsync({ taskIdentifier: combinedParams.taskIdentifier, projectId: notification.projectId }));
      } else {
        await dispatch(fetchPersonalTaskAsync({ taskId: combinedParams.taskId, projectId: notification.projectId }));
      }
      dispatch(toggleProjectModalsOpen({ modal: "projectTaskModal" }));
      dispatch(setOpenedTaskInfo({ id: combinedParams.taskId }));
    } else if (notification.type === UserNotificationType.USER_MENTIONED || notification.type === UserNotificationType.NEW_FILE_COMMENT) {
      if (combinedParams.projectId) {
        if (combinedParams.taskBoardId && combinedParams.taskId) {
          await dispatch(fetchPersonalTaskAsync({ taskId: combinedParams.taskId, projectId: notification.projectId }));
          dispatch(toggleProjectModalsOpen({ modal: "projectTaskModal" }));
          dispatch(setOpenedTaskInfo({ id: combinedParams.taskId }));
        }
        if (combinedParams.fileId) {
          if (relatedFileEntity) {
            navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${notification.projectId}/dir/${relatedFileEntity.parentFileEntityId}`);
          } else {
            const file = await fetchFileEntity(combinedParams.fileId);
            navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${notification.projectId}/dir/${file.parentFileEntityId}`);
          }
          setTimeout(() => {
            dispatch(allFileEntitiesUnSelected());
            dispatch(fileEntitySelected({ ids: [combinedParams.fileId], selected: true }));
            dispatch(directoryInfoBarTabSet(DirectoryFileInfoBarTab.COMMENTS));
          }, 200);
        }
      }
    } else if (notification.type === UserNotificationType.ADDED_TO_WG) {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${notification.projectId}/taskboard/list/all`);
    } else if (
      notification.type === UserNotificationType.SIGN_INVITE ||
      notification.type === UserNotificationType.SIGNED ||
      notification.type === UserNotificationType.CONTAINER_SIGNING_COMPLETE ||
      notification.type === UserNotificationType.SIGNATURE_DECLINED
    ) {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${notification.projectId}/container/${combinedParams.containerId}`);
    } else if (
      notification.type === UserNotificationType.FILES_CONFIRMED ||
      notification.type === UserNotificationType.FILES_DECLINED ||
      notification.type === UserNotificationType.FILES_NEED_APPROVAL
    ) {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${notification.projectId}/confirmation/${combinedParams.confirmationId}`);
    } else if (
      notification.type === UserNotificationType.ACT_APPROVED ||
      notification.type === UserNotificationType.ACT_SUBMITTED ||
      notification.type === UserNotificationType.ACT_REFUSED ||
      notification.type === UserNotificationType.ACT_CANCELLED
    ) {
      if (combinedParams.partyId) {
        if (combinedParams.actId) {
          navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${notification.projectId}/contract/${combinedParams.partyId}/act/${combinedParams.actId}`);
        } else {
          navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${notification.projectId}/contract/${combinedParams.partyId}/acts`);
        }
      }
    }
  };
  return (
    <div ref={ref} className="hover:bh-bg-mint-50 flex flex-row rounded p-1.5">
      <div className="flex h-10 flex-row items-center pr-3">
        <div className="pr-5">
          <SeenIndicator notification={notification}></SeenIndicator>
        </div>
        <div>
          <UserNotificationTypeIconContainer type={notification.type}></UserNotificationTypeIconContainer>
        </div>
      </div>

      <div className="flex flex-col hover:cursor-pointer" onClick={() => onClick()}>
        <div>{parse(textWithVariables(t(getNewTranslationCode(notification)), combinedParams))}</div>
        {notification.type.includes("TASK") && <NotificationTaskController combinedParams={combinedParams} />}
        <div className="text-12px bh-text-deep-ocean-80">
          <ReactTimeAgo date={new Date(notification.sent)} />
        </div>
      </div>
    </div>
  );
};

export default UserNotificationListItemContainer;
