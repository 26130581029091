import React, { ChangeEventHandler, FC } from "react";
import { selectShareboxFilesSort, shareboxFilesSortSet } from "@/app/store/shareboxesSlice";
import BhDivTableHeader from "@components/table/BhDivTableHeader";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IFileEntity } from "@/model/files/IFileEntity";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/app/hooks";

interface Props {
  checked?: boolean;
  handleChange?: ChangeEventHandler<HTMLInputElement>;
}

const ShareboxFilesContainerTableHeader: FC<Props> = ({ checked, handleChange }) => {
  const sort = useAppSelector(selectShareboxFilesSort);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const tableColumnHeaders: Array<IBhTableHeader<IFileEntity>> = [
    handleChange && {
      id: 0,
      content: <input className="hover:bh-border-deep-ocean bh-border-pigeon-60 h-5 w-5 rounded focus:ring-0 focus:ring-offset-0" type="checkbox" checked={checked} onChange={handleChange} />,
      classes: "w-8 flex-none justify-center"
    },
    { id: 1, content: t("GLOBAL.ITEM_NAME"), classes: "relative flex w-9 flex-none flex-grow", field: "name", sortable: true },
    { id: 2, content: t("GLOBAL.VERSION"), classes: "flex-none flex w-18" },
    { id: 3, content: t("GLOBAL.CREATED"), classes: "w-48 flex-none", field: "created", sortable: true },
    { id: 4, content: "", classes: "w-14 flex-none w-14" }
  ].filter(Boolean) as Array<IBhTableHeader<IFileEntity>>;

  const onTableHeaderClickCallback = (column: IBhTableHeader<IFileEntity>) => {
    if (column.field) {
      const updatedSort = { ...sort, property: column.field, reversed: column.field === sort.property ? !sort.reversed : sort.reversed };
      dispatch(shareboxFilesSortSet(updatedSort));
    }
  };

  return <BhDivTableHeader columns={tableColumnHeaders} onClickCallback={onTableHeaderClickCallback} sortedBy={sort.property} reversed={sort.reversed} />;
};

export default ShareboxFilesContainerTableHeader;
