import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { Trans, useTranslation } from "react-i18next";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import DetailViewContainer from "@/views/home/project/detail/detailViewContainer/DetailViewContainer";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import BhPigeon20Separator from "@components/separator/BhPigeon20Separator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import useHandleFormSubmit from "@/utilities/hooks/useHandleFormSubmit";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhTextareaStackedLabel from "@components/input/BhTextareaStackedLabel";
import { saveFeedback } from "@/api/feedbackAPI";
import { selectCurrentProjectId, selectCurrentProjectIsPro } from "@/app/store/project/projectSlice";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons/faSpinnerThird";
import SupportContactCard from "@/views/home/project/detail/support/SupportContactCard";
import SupportContactCardDark from "@/views/home/project/detail/support/SupportContactCardDark";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import { selectCurrentUserLanguage } from "@/app/store/userSlice";

interface Props {}

const SupportContainer: FC<Props> = () => {
  useDocumentTitle("PROJECT.NAVBAR.HELP");
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const isPro = useAppSelector(selectCurrentProjectIsPro);
  const [feedbackText, setFeedbackText] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const handleFormSubmit = useHandleFormSubmit<{ text: string }>();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguage);

  useEffect(() => {
    dispatch(setSidebarCollapsed(false));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.SUPPORT));
  }, []);

  const supportItems = [
    {
      id: 1,
      href: "https://www.bauhub.ee/tugi/kuidas-alustada",
      nameCode: "SUPPORT.LINKS.START"
    },
    {
      id: 2,
      href: "https://www.bauhub.ee/tugi/toolaud-ja-tood",
      nameCode: "SUPPORT.LINKS.TASKBOARDS"
    },
    {
      id: 3,
      href: "https://www.bauhub.ee/tugi/kasutajad",
      nameCode: "SUPPORT.LINKS.USERS"
    },
    {
      id: 4,
      href: "https://www.bauhub.ee/tugi/lepingud-ja-akteerimine",
      nameCode: "SUPPORT.LINKS.ACTS"
    },
    {
      id: 5,
      href: "https://www.bauhub.ee/tugi/failid-ja-dokumendid",
      nameCode: "SUPPORT.LINKS.FILES"
    },
    {
      id: 6,
      href: "https://www.bauhub.ee/tugi/mobiilirakendused",
      nameCode: "SUPPORT.LINKS.MOBILE"
    },
    {
      id: 7,
      href: "https://www.bauhub.ee/tugi/tellimused",
      nameCode: "SUPPORT.LINKS.SUBSCRIPTIONS"
    },
    {
      id: 8,
      href: "https://www.bauhub.ee/tugi/kontrollid",
      nameCode: "SUPPORT.LINKS.CHECKLISTS"
    },
    {
      id: 9,
      href: "https://www.bauhub.ee/tugi/baudrive",
      nameCode: "GLOBAL.BAUDRIVE"
    }
  ];

  const handleSubmit = (formValues: { text: string }) => {
    setSaveLoading(true);
    saveFeedback(projectId, formValues)
      .then(() => {
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.SUCCESS,
            disappear: true,
            children: t("SUPPORT.FEEDBACK_SENT")
          })
        );
        setFeedbackText("");
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const supportEmail = currentUserLanguage === "et_EE" ? "tugi@bauhub.ee" : "support@bauhub.ee";

  return (
    <DetailViewContainer
      header={
        <DetailViewHeading>
          <Trans>PROJECT.NAVBAR.HELP</Trans>
        </DetailViewHeading>
      }
    >
      <BhScrollableBody>
        <div className="mr-32 overflow-hidden xl:mr-0">
          <div className="flex flex-row lg:flex-col">
            <div className="w-1/2 pt-4 lg:w-full">
              <h3>
                <Trans>SUPPORT.INSTRUCTIONS</Trans>
              </h3>
              <p className="w-2/3">
                <Trans>SUPPORT.INSTRUCTIONS.DETAILS</Trans>
              </p>
            </div>
            <div className="w-1/2 pt-4 lg:w-full">
              {supportItems.map((supportItem) => {
                return (
                  <a key={supportItem.id} href={supportItem.href} target="_blank" className="hover:bh-bg-mint group relative flex h-10 items-center px-3 font-bold" rel="noreferrer">
                    <FontAwesomeIcon icon={faChevronRight} className="mx-1 h-4 w-4" aria-hidden="true" />
                    <span className="ml-3">{t(supportItem.nameCode)}</span>
                  </a>
                );
              })}
            </div>
          </div>
          <BhPigeon20Separator classes="my-4" />
          <div className="flex flex-row lg:flex-col">
            <div className="w-1/2 pt-1 lg:w-full">
              <h3>
                <Trans>SUPPORT.GET_CONTACT</Trans>
              </h3>
              <p className="w-2/3">{isPro ? <Trans>SUPPORT.GET_CONTACT.DETAILS_PRO</Trans> : <Trans>SUPPORT.GET_CONTACT.DETAILS</Trans>}</p>
            </div>
            <div className="my-2 flex w-1/2 flex-row space-x-2 lg:w-full">
              <SupportContactCard heading={<Trans>SUPPORT.GET_CONTACT.WRITE</Trans>} body={<a href={"mailto:" + supportEmail}>{supportEmail}</a>} />
              {!isPro && <SupportContactCardDark />}
              {isPro && <SupportContactCard heading={<Trans>SUPPORT.GET_CONTACT.CALL</Trans>} body={<a href="tel:+3728840440">+372 884 0440</a>} />}
            </div>
          </div>
          <BhPigeon20Separator classes="my-4" />
          <div className="flex flex-row lg:flex-col">
            <div className="w-1/2 pt-1 lg:w-full">
              <h3>
                <Trans>SUPPORT.LEAVE_FEEDBACK</Trans>
              </h3>
              <p className="w-2/3">
                <Trans>SUPPORT.LEAVE_FEEDBACK.DETAILS</Trans>
              </p>
            </div>
            <div className="w-1/2 flex-col lg:w-full">
              <form id="feedbackForm" onSubmit={handleFormSubmit((feedback) => handleSubmit(feedback))}>
                <BhTextareaStackedLabel
                  label={t("SUPPORT.FEEDBACK")}
                  placeholder={t("SUPPORT.FEEDBACK.PLACEHOLDER")}
                  initialValue={feedbackText}
                  property={"text"}
                  onChangeCallback={({ text }: { text: string }) => {
                    setFeedbackText(text);
                  }}
                  rows={3}
                />
              </form>
              <div className="mt-2 -mr-1 flex flex-row justify-end">
                <BhPrimaryButton buttonProps={{ submitForm: "feedbackForm", disabled: feedbackText.length === 0 || saveLoading }}>
                  <Trans>GLOBAL.SEND</Trans>
                  {saveLoading && <FontAwesomeIcon icon={faSpinnerThird} className="fa-spin ml-2" />}
                </BhPrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </BhScrollableBody>
    </DetailViewContainer>
  );
};

export default SupportContainer;
