import React, { FC } from "react";
import BhControlledInput from "@components/input/BhInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhInputLabel from "@components/input/BhInputLabel";

interface Props {
  initialPrefix: any;
  initialValue: any;
  property: string;
  dropdownElements: string[];
  onChangeCallback?: Function;
  onEnterCallback?: Function;
  label?: string;
  type?: string;
  labelHelper?: string;
  placeholder?: string;
  disabled?: boolean;
  inputClasses?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  debounceLength?: number;
}

const BhInputWithPrefixDropdown: FC<Props> = ({
  property,
  type,
  dropdownElements,
  initialPrefix,
  initialValue,
  onChangeCallback,
  onEnterCallback,
  label,
  placeholder,
  disabled,
  inputClasses,
  labelHelper,
  inputRef,
  debounceLength
}) => {
  return (
    <div className="flex flex-col">
      <BhInputLabel>{label}</BhInputLabel>
      {labelHelper && (
        <div className="text-14px l-h-18px bh-text-deep-ocean-80 px-0.5 py-1.5 font-semibold">
          <span className="bh-text-pigeon-70 font-normal italic"> - {labelHelper}</span>
        </div>
      )}
      <div className="bh-border-pigeon-40 hover:bh-border-pigeon-70 relative inline-block w-full rounded border">
        <BhDropdown
          buttonClasses="cursor-pointer"
          button={
            <div className={"bh-bg-smoke hover:bh-bg-pigeon-20 h-8.5 absolute flex w-20 items-center justify-center rounded-l"}>
              <span>{initialPrefix}</span>
              <FontAwesomeIcon className="pl-2 pr-1" icon={faCaretDown} />
            </div>
          }
          menu={
            <div className="absolute top-8 mt-1">
              <BhDropdownMenu type={BhDropdownTypeEnum.STRING} values={dropdownElements} onSelect={(value: string) => onChangeCallback && onChangeCallback({ prefix: value })} widthClass="w-20" />
            </div>
          }
          fixedPosition={true}
        />
        <BhControlledInput
          initialValue={initialValue}
          property={property}
          onChangeCallback={onChangeCallback}
          inputClasses={"pl-24 h-8.5 border-0"}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          inputRef={inputRef}
          onEnterCallback={onEnterCallback}
          debounceLength={debounceLength}
        />
      </div>
    </div>
  );
};

export default BhInputWithPrefixDropdown;
