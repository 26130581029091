import React, { FC, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IBauhubEvent, ProjectLogType, ProjectLogTypeBgClasses, ProjectLogTypeIconClasses, ProjectLogTypeToStyleMap } from "@/model/IBauhubEvent";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { FileEntityType } from "@/model/files/IFileEntity";
import { capitalizeFirstLetter } from "@/utilities/jsUtilities";

interface Props {
  event: IBauhubEvent;
}

const BhEventLabelContainer: FC<Props> = ({ event }) => {
  const { t } = useTranslation();

  const containerClasses = ProjectLogTypeBgClasses[ProjectLogTypeToStyleMap[event.type]] + " flex flex-row inline-flex items-center rounded text-center";
  const iconClasses = ProjectLogTypeIconClasses[ProjectLogTypeToStyleMap[event.type]];

  const eventFileTypeTranslationMap: Record<string, string> = {
    ATTACHMENT_CONTAINER: "LOG.CONTAINER",
    CONTAINER: "LOG.CONTAINER",
    DIR: "LOG.DIR",
    DOCUMENT: "LOG.DOCUMENT",
    DOCUMENT_DIR: "LOG.DIR",
    FILE: "LOG.FILE",
    FORM: "LOG.FORM"
  };

  const eventActionTranslationMap: Record<string, string> = {
    FILE_DELETED: "LOG.FILE_DELETED",
    FILE_LOCKED: "LOG.FILE_LOCKED",
    FILE_MOVED_FROM: "LOG.FILE_MOVED",
    FILE_MOVED_TO: "LOG.FILE_MOVED",
    FILE_RENAMED: "LOG.FILE_RENAMED",
    FILE_UNLOCKED: "LOG.FILE_UNLOCKED",
    FILE_COMMENT: "LOG.FILE_COMMENT",
    FILE_NEW_REVISION: "LOG.FILE_NEW_REVISION",
    FILE_REVISION_ROLLBACK: "LOG.FILE_REVISION_ROLLBACK",
    FILE_UNDO_DELETE: "LOG.RESTORED"
  };

  const getTranslatedLabel = useCallback(() => {
    let label = "";
    const addNewPrefix = (event.type === ProjectLogType.NEW_FILE || event.type === ProjectLogType.FILE_NEW_REVISION || event.type === ProjectLogType.FILE_COMMENT) && !event.data.copiedFromFileId;
    if (addNewPrefix) {
      label += t("LOG.NEW") + " ";
    }
    const addTypeAndActionSuffix = event.type !== ProjectLogType.FILE_NEW_REVISION && event.type !== ProjectLogType.FILE_REVISION_ROLLBACK && event.type !== ProjectLogType.FILE_COMMENT;
    if (addTypeAndActionSuffix) {
      const addFileType =
        event.fileType === FileEntityType.DIR ||
        event.fileType === FileEntityType.FILE ||
        event.fileType === FileEntityType.DOCUMENT ||
        event.fileType === FileEntityType.FORM ||
        event.fileType === FileEntityType.ATTACHMENT_CONTAINER ||
        event.fileType === FileEntityType.CONTAINER;
      if (addFileType) {
        label += t(eventFileTypeTranslationMap[event.fileType]);
      }
      const addAction =
        event.type === ProjectLogType.FILE_DELETED ||
        event.type === ProjectLogType.FILE_RENAMED ||
        event.type === ProjectLogType.FILE_LOCKED ||
        event.type === ProjectLogType.FILE_MOVED_FROM ||
        event.type === ProjectLogType.FILE_MOVED_TO ||
        event.type === ProjectLogType.FILE_UNLOCKED ||
        event.type === ProjectLogType.FILE_UNDO_DELETE;
      if (addAction) {
        label += addFileType && " " + t(eventActionTranslationMap[event.type]);
      }
      const isCopyAction = event.type === ProjectLogType.NEW_FILE && event.data.copiedFromFileId;
      if (isCopyAction) {
        label += addFileType && " " + t("LOG.FILE_COPIED");
      }
    }
    const addActionSuffixOnly = event.type === ProjectLogType.FILE_COMMENT || event.type === ProjectLogType.FILE_NEW_REVISION || event.type === ProjectLogType.FILE_REVISION_ROLLBACK;
    if (addActionSuffixOnly) {
      label += t(eventActionTranslationMap[event.type]);
    }
    if (!label) {
      return "";
    }
    return capitalizeFirstLetter(label.toLowerCase());
  }, [event]);

  return (
    <div className={containerClasses}>
      <span className="px-1">
        <FontAwesomeIcon icon={faCircle} size="xs" className={iconClasses} />
      </span>
      <span className="px-1">{getTranslatedLabel()}</span>
    </div>
  );
};

export default React.memo(BhEventLabelContainer);
