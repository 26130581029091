import React, { FC, ReactElement } from "react";
import { ChecklistRowFieldType, IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import ChecklistBaseLargeTextfield from "@components/checklists/checklistBase/body/rowTypes/Textfield/ChecklistBaseLargeTextfield";
import ChecklistBaseSmallTextfield from "@components/checklists/checklistBase/body/rowTypes/Textfield/ChecklistBaseSmallTextfield";
import ChecklistBaseDatepicker from "@components/checklists/checklistBase/body/rowTypes/Textfield/ChecklistBaseDatepicker";
import ChecklistBaseTimepicker from "@components/checklists/checklistBase/body/rowTypes/Textfield/ChecklistBaseTimepicker";
import ChecklistBaseUser from "@components/checklists/checklistBase/body/rowTypes/Textfield/ChecklistBaseUser";
import { faPen } from "@fortawesome/pro-regular-svg-icons/faPen";
import { faCalendarDay } from "@fortawesome/pro-regular-svg-icons/faCalendarDay";
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

interface Props {
  checklistBaseId: EntityId;
  baseVersionRow: IChecklistBaseVersionRow;
  saveCallback: Function;
  disabled?: boolean;
}

const ChecklistBaseRowTextfield: FC<Props> = ({ baseVersionRow, saveCallback, disabled, checklistBaseId }) => {
  const { t } = useTranslation();

  const saveFieldType = (changedObject: any) => {
    if (changedObject.fieldType === baseVersionRow.fieldType) return;
    const editableFields = [ChecklistRowFieldType.DATEPICKER, ChecklistRowFieldType.TIMEPICKER, ChecklistRowFieldType.USER];
    if (editableFields.includes(changedObject.fieldType)) {
      changedObject = { ...changedObject, editable: true };
    }
    saveCallback(changedObject);
  };

  const fieldTypeDropdownOptions: Array<any> = [
    baseVersionRow.fieldType !== ChecklistRowFieldType.SMALL_TEXTFIELD && {
      text: t("CHECKLIST_BASE.TEXT_FIELD.TYPE.SMALL_TEXTFIELD"),
      function: () => saveFieldType({ fieldType: ChecklistRowFieldType.SMALL_TEXTFIELD }),
      icon: faPen
    },
    baseVersionRow.fieldType !== ChecklistRowFieldType.DATEPICKER && {
      text: t("CHECKLIST_BASE.TEXT_FIELD.TYPE.DATEPICKER"),
      function: () => saveFieldType({ fieldType: ChecklistRowFieldType.DATEPICKER }),
      icon: faCalendarDay
    },
    baseVersionRow.fieldType !== ChecklistRowFieldType.TIMEPICKER && {
      text: t("CHECKLIST_BASE.TEXT_FIELD.TYPE.TIMEPICKER"),
      function: () => saveFieldType({ fieldType: ChecklistRowFieldType.TIMEPICKER }),
      icon: faClock
    },
    baseVersionRow.fieldType !== ChecklistRowFieldType.USER && {
      text: t("CHECKLIST_BASE.TEXT_FIELD.TYPE.USER"),
      function: () => saveFieldType({ fieldType: ChecklistRowFieldType.USER }),
      icon: faUser
    }
  ].filter(Boolean);

  const checklistBaseTextfieldElementMap: Record<string, ReactElement> = {
    DATEPICKER: (
      <ChecklistBaseDatepicker baseVersionRow={baseVersionRow} saveCallback={saveCallback} changeFieldTypeCallback={saveFieldType} dropdownOptions={fieldTypeDropdownOptions} disabled={disabled} />
    ),
    LARGE_TEXTFIELD: <ChecklistBaseLargeTextfield baseVersionRow={baseVersionRow} saveCallback={saveCallback} changeFieldTypeCallback={saveFieldType} disabled={disabled} />,
    SMALL_TEXTFIELD: (
      <ChecklistBaseSmallTextfield baseVersionRow={baseVersionRow} saveCallback={saveCallback} changeFieldTypeCallback={saveFieldType} dropdownOptions={fieldTypeDropdownOptions} disabled={disabled} />
    ),
    TIMEPICKER: (
      <ChecklistBaseTimepicker baseVersionRow={baseVersionRow} saveCallback={saveCallback} changeFieldTypeCallback={saveFieldType} dropdownOptions={fieldTypeDropdownOptions} disabled={disabled} />
    ),
    USER: <ChecklistBaseUser baseVersionRow={baseVersionRow} saveCallback={saveCallback} changeFieldTypeCallback={saveFieldType} dropdownOptions={fieldTypeDropdownOptions} disabled={disabled} />
  };
  return <div className="p-4">{checklistBaseTextfieldElementMap[baseVersionRow.fieldType]}</div>;
};

export default ChecklistBaseRowTextfield;
