import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "@/utilities/accountingUtilities";

interface Props {
  ettemaks: number;
  ettemaksuJaak: number;
}

const MaruDashboardEttemaksContainer: FC<Props> = ({ ettemaks, ettemaksuJaak }) => {
  const { t } = useTranslation();

  // Calculate the total to compute percentages for widths
  const total = ettemaks + ettemaksuJaak;

  return (
    <div>
      <h3 className="mb-1">{t("MR.DASHBOARD.TELLIJA_ETTEMAKS")}</h3>
      <div className="mb-1 flex flex-row items-center gap-x-4">
        <div className="flex flex-row items-center">
          <div className="bh-bg-bauhub-green-120 mr-1 h-3 w-3 overflow-hidden rounded"></div>
          <div>{t("MR.DASHBOARD.ARVELDATUD_ETTEMAKS")}</div>
        </div>
        <div className="flex flex-row items-center">
          <div className="bh-bg-error-red mr-1 h-3 w-3 overflow-hidden rounded"></div>
          <div>{t("MR.DASHBOARD.ETTEMAKSU_JAAK")}</div>
        </div>
      </div>
      <div className="max-w-420px flex h-10 w-full flex-row overflow-hidden rounded font-bold">
        <div className="bh-bg-bauhub-green-120 bh-text-white flex items-center justify-center" style={{ width: `${(ettemaks / total) * 100}%` }}>
          <div className="inner-wrapper px-1" data-tooltip-id="bh-tooltip" data-tooltip-content={formatCurrency(ettemaks)}>
            {ettemaks !== 0 && `${formatCurrency(ettemaks)}`}
          </div>
        </div>
        <div className="bh-bg-error-red bh-text-white flex items-center justify-center whitespace-nowrap" style={{ width: `${(ettemaksuJaak / total) * 100}%` }}>
          <div className="inner-wrapper px-1" data-tooltip-id="bh-tooltip" data-tooltip-content={formatCurrency(ettemaksuJaak)}>
            {ettemaksuJaak !== 0 && `${formatCurrency(ettemaksuJaak)}`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaruDashboardEttemaksContainer;
