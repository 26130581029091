import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import {
  fetchProjectLogAsync,
  selectAllProjectLogsSorted,
  selectCurrentProjectLogFilterOrderedBy,
  selectCurrentProjectLogFilterSortOrderAsc,
  selectCurrentProjectLogLoading,
  setFileHistoryModalLog,
  setFileHistoryModalShown,
  setFileRestoreModalInfo,
  toggleLogSortOrder
} from "@/app/store/project/projectLogSlice";
import { IBauhubEvent } from "@/model/IBauhubEvent";
import ProjectLogTable from "@/views/home/project/detail/projectLog/ProjectLogTable";
import { checkForFileDeletedParents, restoreFileFromEventLog } from "@/api/fileAPI";
import { FileEntityType } from "@/model/files/IFileEntity";
import { selectedInFileTreeFolderSet } from "@/app/store/foldersSlice";
import { useNavigate } from "react-router-dom";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { isAnyParentDeleted } from "@/utilities/fileEntityUtilities";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { findWorkGroupIdByContractDirectoryFileEntityId } from "@/api/partyAPI";

interface Props {}

const ProjectLogTableContainer: FC<Props> = () => {
  const loading = useAppSelector(selectCurrentProjectLogLoading);
  const logs = useAppSelector(selectAllProjectLogsSorted);
  const sortedBy = useAppSelector(selectCurrentProjectLogFilterOrderedBy);
  const sortReversed = useAppSelector(selectCurrentProjectLogFilterSortOrderAsc);
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onTableHeaderClickCallback = (column: IBhTableHeader<IBauhubEvent>) => {
    if (column.field) {
      dispatch(toggleLogSortOrder(column.field));
      dispatch(fetchProjectLogAsync(projectId));
    }
  };

  const restoreFile = (log: IBauhubEvent) => {
    return checkForFileDeletedParents(projectId, log.fileEntityId).then((res) => {
      if (isAnyParentDeleted(res)) {
        dispatch(setFileRestoreModalInfo({ log: log, fileTree: res, shown: true }));
      } else {
        return restoreFileFromEventLog(log.id).then(() => {
          dispatch(fetchProjectLogAsync(projectId));
        });
      }
    });
  };

  const openDirectory = (log: IBauhubEvent) => {
    // CONTRACT FILES
    if (log.parentFileEntityId && log.data.parentFileEntityName && log.data.parentFileEntityName.startsWith("CONTRACT_DIRECTORY")) {
      findWorkGroupIdByContractDirectoryFileEntityId(log.projectId, log.parentFileEntityId).then(function (result) {
        navigate(`/project/${projectId}/contract/${result.value}`);
      });
      return;
    }
    // BIM FILES
    if (log.data.parentFileEntityName && log.data.parentFileEntityName === "Mudel") {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/bim/dir`);
      return;
    }
    let folderId = log.parentFileEntityId;
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/dir/${folderId}`);
    dispatch(selectedInFileTreeFolderSet(folderId));
  };

  const openAct = (log: IBauhubEvent) => {
    if (log.data.resource === FileEntityType.ACT) {
      const partyId = log.data.workGroupId;
      const actId = log.data.resourceId;
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/contract/${partyId}/act/${actId}`);
    }
  };

  const openFileHistoryModal = (log: IBauhubEvent) => {
    dispatch(setFileHistoryModalLog(log));
    dispatch(setFileHistoryModalShown(true));
  };

  return (
    <ProjectLogTable
      logs={logs}
      sortedBy={sortedBy}
      sortReversed={sortReversed}
      tableHeaderOnClick={onTableHeaderClickCallback}
      logsLoading={loading}
      restoreFileCallback={restoreFile}
      openDirectoryCallback={openDirectory}
      openFileHistoryCallback={openFileHistoryModal}
      openActCallback={openAct}
    />
  );
};

export default ProjectLogTableContainer;
