import React, { FC } from "react";
import { Link } from "react-router-dom";
import BhCardContainer from "@components/cards/BhCardContainer";
import { useAppSelector } from "@/app/hooks";
import { selectSignInvitesContainerById } from "@/app/store/signInvitesSlice";
import { EntityId } from "@reduxjs/toolkit";
import { ISignInvite } from "@/model/ISignInvite";
import { useTranslation } from "react-i18next";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import { formatDateTime } from "@/utilities/dateUtility";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  inviteContainerId: EntityId;
}

const SignInvitesUnsignedProjectInvitesListItem: FC<Props> = ({ inviteContainerId }) => {
  const { t } = useTranslation();
  const inviteContainer = useAppSelector((state) => selectSignInvitesContainerById(state, inviteContainerId));

  if (!inviteContainer) return null;

  const { signInvites, container } = inviteContainer;

  const findValidInvites = () => {
    return signInvites.filter((signInvite: ISignInvite) => {
      return typeof signInvite.declined !== "undefined" && typeof signInvite.signed !== "undefined" && !signInvite.signed && !signInvite.declined;
    }).length;
  };

  return (
    <BhCardContainer>
      <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${container.projectId}/container/${container.id}`} className="w-full px-6 py-4">
        <div className="flex flex-row items-center px-6 py-2">
          <div className="flex flex-1 flex-col">
            <div className="text-18px font-bold">{container.name}</div>
          </div>
          <div className="flex w-48 flex-col p-2">
            {`${t("GLOBAL.CREATED")}: `} {formatDateTime(container.created)}
          </div>
          <div className="flex w-40 flex-row items-center p-2">
            <span>{t("SIGN_INVITE.TABLE.INVITE")}</span>
            {findValidInvites() > 0 && (
              <BhBadge type={BhBadgeType.SUBTLE} classes="ml-1">
                {findValidInvites()}
              </BhBadge>
            )}
          </div>
        </div>
      </Link>
    </BhCardContainer>
  );
};

export default SignInvitesUnsignedProjectInvitesListItem;
