import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhTableHeader from "@components/table/BhTableHeader";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { selectAllChecklistLastRoundsSelected, selectChecklistFilter, selectSortedChecklistRounds, setChecklistFilter, toggleAllLastRoundsOnPageChecked } from "@/app/store/checklist/checklistsSlice";
import ChecklistListRow from "@components/checklists/list/ChecklistListRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignature } from "@fortawesome/pro-regular-svg-icons/faSignature";
import { IChecklist } from "@/model/checklist/IChecklist";
import { useTranslation } from "react-i18next";
import { ChecklistBaseCategory } from "@/model/checklist/IChecklistBase";
import { classNames } from "@/utilities/jsUtilities";
import ChecklistListRowSkeleton from "@components/checklists/list/ChecklistListRowSkeleton";
import ChecklistCreatedByAndCreatedSortToggle from "@components/checklists/list/ChecklistCreatedByAndCreatedToggle";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import BhTooltip from "@components/BhTooltip";
import { selectRootDirectoryId, selectRootDocumentDirectoryId } from "@/app/store/project/projectSlice";

interface Props {
  isLoading: boolean;
}

const ChecklistsTable: FC<Props> = ({ isLoading }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const checklistRounds = useAppSelector(selectSortedChecklistRounds);
  const filter = useAppSelector(selectChecklistFilter);
  const orderBy = filter.orderBy || "created";
  const ascending = filter.orderAsc || false;
  const isSafetyCategory = filter && filter.category === ChecklistBaseCategory.SAFETY;
  const allLastRoundsSelected = useAppSelector(selectAllChecklistLastRoundsSelected);
  const fileRootDirectoryId = useAppSelector(selectRootDirectoryId);
  const documentRootDirectoryId = useAppSelector(selectRootDocumentDirectoryId);
  const directorySelectionModalIds = [fileRootDirectoryId, documentRootDirectoryId];

  const handleMasterCheckboxChange = (e: { allSelected: boolean }) => {
    dispatch(toggleAllLastRoundsOnPageChecked(e.allSelected));
  };

  const tableColumnHeaders: Array<IBhTableHeader<any>> = [
    {
      id: 0,
      content: (
        <div className="ml-0.25">
          <BhTooltip body={t("CHECKLISTS.SELECT_ALL")}>
            <BhCheckbox isChecked={allLastRoundsSelected} property={"allSelected"} large={true} onChange={handleMasterCheckboxChange} />
          </BhTooltip>
        </div>
      ),
      classes: "w-6"
    },
    { id: 1, content: t("CHECKLIST.CHECKLIST_NAME"), sortable: true, field: "name" },
    {
      id: 2,
      content: (
        <BhTooltip body={t("SIGNATURE.SIGNED")}>
          <FontAwesomeIcon icon={faSignature} />{" "}
        </BhTooltip>
      ),
      classes: "w-20 text-center",
      sortable: true,
      field: "signatures"
    },
    { id: 3, content: <ChecklistCreatedByAndCreatedSortToggle />, classes: "w-44" },
    { id: 4, content: t("CHECKLIST.ROUND"), classes: "w-24" },
    { id: 5, content: t(isSafetyCategory ? "CHECKLIST.TOM" : "CHECKLIST.PROGRESS"), classes: classNames(isSafetyCategory ? "w-24" : "w-80", "text-center"), sortable: true, field: "progress" },
    { id: 6, content: "", classes: "w-12" }
  ];

  const onTableHeaderClickCallback = (column: IBhTableHeader<IChecklist>) => {
    if (column.field) {
      const newFilter = { ...filter, orderBy: column.field, orderAsc: !filter.orderBy || column.field === filter.orderBy ? !ascending : false };
      dispatch(setChecklistFilter({ filter: newFilter }));
    }
  };

  return (
    <div className="pb-4">
      <table className="h-full w-full">
        <BhTableHeader columns={tableColumnHeaders} sortedBy={orderBy} reversed={!ascending} onClickCallback={onTableHeaderClickCallback} />
        <tbody>
          {!isLoading &&
            checklistRounds.length > 0 &&
            checklistRounds.map((checklistRound) => {
              return checklistRound && <ChecklistListRow checklistRoundId={checklistRound.id} key={checklistRound.id} directorySelectionModalIds={directorySelectionModalIds} />;
            })}
          {isLoading && <ChecklistListRowSkeleton numberOfDisplayedItems={5} />}
        </tbody>
      </table>
    </div>
  );
};

export default ChecklistsTable;
