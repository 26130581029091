import React, { FC } from "react";
import BhInputWithFetchedUsers from "@components/input/BhInputWithFetchedUsers";
import BhInputWithUserDropdown from "@components/input/BhInputWithUserDropdown";
import { useAppSelector } from "@/app/hooks";
import { selectTaskBoardUsersByTaskBoardId } from "@/app/store/taskBoardsSlice";

interface Props {
  row: any;
  saveCallback: Function;
  disabled: boolean;
}

const FormProtocolFieldTaskAssigneeInput: FC<Props> = ({ row, disabled, saveCallback }) => {
  const taskBoardUsers = useAppSelector((state) => (row.task && row.task.taskBoardId ? selectTaskBoardUsersByTaskBoardId(state, row.task.taskBoardId) : []));
  const taskBoardTaskExisting = row.task && row.task.id;

  return (
    <>
      {!taskBoardTaskExisting && (
        <BhInputWithFetchedUsers initialValue={row.task && row.task.assigneeFullName} property={"assignee"} disabled={disabled} saveCallback={saveCallback} autoFocus={true} />
      )}
      {taskBoardTaskExisting && (
        <BhInputWithUserDropdown
          initialValue={row.task && row.task.assigneeFullName}
          property={"assignee"}
          saveCallback={saveCallback}
          dropdownValues={taskBoardUsers}
          disabled={disabled}
          autoFocus={true}
        />
      )}
    </>
  );
};

export default FormProtocolFieldTaskAssigneeInput;
