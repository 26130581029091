import React, { FC, useEffect } from "react";
import { useAppDispatch } from "@/app/hooks";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import XeokitFullContainer from "@/views/home/project/detail/xeokit/XeokitFullContainer";

interface Props {
  bimDirectoryId: number;
}

const BimContainer: FC<Props> = ({ bimDirectoryId }) => {
  useDocumentTitle("GLOBAL.MODEL");
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSidebarCollapsed(true));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.BIM));
  }, []);

  return <XeokitFullContainer folderFileEntityId={bimDirectoryId} />;
};

export default BimContainer;
