import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/app/hooks";

import BhModal from "@components/modal/BhModal";
import parse from "html-react-parser";
import ProjectTreeListItemWithCheckbox from "@/views/home/company/createProject/newProjectFolderStructure/projectTreePreviewModal/ProjectTreeListItemWithCheckbox";
import { IProject } from "@/model/IProject";
import BhModalFooter from "@components/modal/BhModalFooter";
import { IFormBasesToProjectsDTO } from "@/model/form/IFormBasesToProjectsDTO";
import { addFormBasesToProjectsAsync, removeFormBasesFromProjectsAsync } from "@/app/store/form/formBasesSlice";
import { selectAllCompanyActiveProjects } from "@/app/store/companiesSlice";
import { naturalSortString } from "@/utilities/sortUtilities";

interface Props {
  selectedIds: Array<EntityId>;
  removeBases: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  companyId: EntityId;
}

const ToggleFormBaseInProjectsModal: FC<Props> = ({ selectedIds, removeBases, setIsShown, companyId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const companyProjects = useAppSelector((state) => selectAllCompanyActiveProjects(state, companyId)).sort((a, b) => naturalSortString(a.name, b.name));
  const [selectedProjectIds, setSelectedProjectIds] = useState([] as Array<EntityId>);

  if (!companyProjects || companyProjects.length === 0) return null;

  const toggleProjectSelection = (projectId: EntityId) => {
    const projectAlreadySelected = selectedProjectIds.find((id) => id === projectId);
    if (projectAlreadySelected) {
      setSelectedProjectIds(selectedProjectIds.filter((id) => id !== projectId));
    } else {
      setSelectedProjectIds([...selectedProjectIds, projectId]);
    }
  };

  const toggleSelectAllProjects = () => {
    if (selectedProjectIds.length === companyProjects.length) {
      setSelectedProjectIds([]);
    } else {
      const allIds = companyProjects.map((project) => project.id);
      setSelectedProjectIds(allIds);
    }
  };

  const activateOrRemoveProjects = () => {
    const dto = { formBaseIds: selectedIds, projectIds: selectedProjectIds } as IFormBasesToProjectsDTO;
    if (removeBases) {
      dispatch(removeFormBasesFromProjectsAsync({ companyId: companyId, basesToProjectsDTO: dto }));
    } else {
      dispatch(addFormBasesToProjectsAsync({ companyId: companyId, basesToProjectsDTO: dto }));
    }
    setIsShown(false);
  };

  return (
    <BhModal
      isShown={true}
      size={"5xl"}
      setIsShown={setIsShown}
      header={
        <div className="h-7">
          <h2 className="mt-1 leading-7">{removeBases ? t("CHECKLIST.PROJECTS_MODAL.HEADER.REMOVE") : t("CHECKLIST.PROJECTS_MODAL.HEADER.ADD")}</h2>
        </div>
      }
      children={
        <div className="h-full overflow-y-auto py-8 px-10">
          <div>{removeBases ? parse(t("CHECKLIST.PROJECTS_MODAL.BODY.REMOVE")) : parse(t("CHECKLIST.PROJECTS_MODAL.BODY.ADD"))}</div>
          <div className="my-5">
            <ProjectTreeListItemWithCheckbox
              customProject={{ id: 0, name: t("PROJECT.SIDEBAR.ALL_PROJECTS") } as IProject}
              projectId={0}
              isChecked={selectedProjectIds.length === companyProjects.length}
              onChange={toggleSelectAllProjects}
              property={"selected"}
              isActive={selectedProjectIds.length === companyProjects.length}
              classes={"py-1 my-2 px-1"}
              hideStatusBadge={true}
            />
          </div>
          <div className="flex flex-col gap-y-1">
            {companyProjects.map((project, index) => {
              return (
                <ProjectTreeListItemWithCheckbox
                  projectId={project.id}
                  isChecked={selectedProjectIds.some((id) => id === project.id)}
                  onChange={toggleProjectSelection}
                  property={"selected"}
                  key={project.id}
                  isActive={selectedProjectIds.some((id) => id === project.id)}
                  classes={"py-1 mb-0.5 px-1"}
                  index={index}
                  hideStatusBadge={true}
                />
              );
            })}
          </div>
        </div>
      }
      footer={
        <BhModalFooter
          onCancel={() => setIsShown(false)}
          cancelButtonText={t("GLOBAL.CLOSE") as string}
          confirmButtonText={removeBases ? (t("CHECKLIST.REMOVE_FROM_PROJECT") as string) : (t("CHECKLIST.TEMPLATE_ACTIVATE") as string)}
          isWarningButton={removeBases}
          onConfirm={activateOrRemoveProjects}
        />
      }
    />
  );
};

export default ToggleFormBaseInProjectsModal;
