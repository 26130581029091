import React, { FC, useEffect, useState } from "react";
import XeokitMobileViewer from "@/views/home/project/detail/xeokit/mobile/XeokitMobileViewer";
import { EntityId } from "@reduxjs/toolkit";
import { fetchFilesAsync } from "@/app/store/filesSlice";
import { useAppDispatch } from "@/app/hooks";
import { fetchBimManifests } from "@/api/fileAPI";
import { IFileEntity } from "@/model/files/IFileEntity";
import { IManifestDTO } from "@/model/files/IManifestDTO";
import { delay, mapToObject, objectToMap } from "@/utilities/jsUtilities";

interface Props {
  folderFileEntityId: EntityId;
}

const XeokitMobileViewerEmulator: FC<Props> = ({ folderFileEntityId }) => {
  const [manifests, setManifests] = useState<IManifestDTO[]>();
  const dispatch = useAppDispatch();

  const downloadAndConvertToBase64 = async (url: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        // Convert the Blob to Base64
        const reader = new FileReader();
        reader.onload = () => {
          // @ts-ignore
          const base64String = reader.result.split(",")[1];
          resolve(base64String);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(blob);
      });
    } catch (error) {}
  };

  const loadFilesIntoViewer = async (fileEntities: Array<IFileEntity>, manifests: Array<IManifestDTO>) => {
    window.postMessage({ type: "startLoaderAnimation" }, "*");
    let filePartsToLoadMap = new Map<string, Array<string>>();
    let currentFileEntityId;
    let currentPartFile;

    const fileEntitiesNameMap = fileEntities.map((f) => {
      return { id: f.id, name: f.name, projectId: f.projectId };
    });

    for (const manifest of manifests) {
      if (!currentPartFile) {
        currentFileEntityId = manifest.fileEntityId;
        currentPartFile = manifest.xktFiles![0];
      }
      const partFileIdsForFileEntity = manifest.xktFiles.filter((e) => e.id != null).map((e) => e.id.toString());
      filePartsToLoadMap.set(manifest.fileEntityId.toString(), partFileIdsForFileEntity);
      //
      // const metaDataJsonBase64String = await downloadAndConvertToBase64(manifest?.metaDataUrl || "");
      //
      // window.postMessage(
      //   {
      //     type: "loadModel",
      //     model: {
      //       fileEntityId: manifest.fileEntityId,
      //       metaDataJsonBase64: metaDataJsonBase64String,
      //       fileEntitiesNameMap: JSON.stringify(fileEntitiesNameMap),
      //       // @ts-ignore
      //       filePartsToLoadInitial: JSON.stringify(mapToObject(filePartsToLoadMap))
      //     }
      //   },
      //   "*"
      // );
    }

    // window.postMessage({ type: "endLoaderAnimation" }, "*");
    // return;

    if (currentPartFile !== undefined) {
      const base64String = await downloadAndConvertToBase64(currentPartFile.url);
      window.postMessage(
        {
          type: "loadFiles",
          files: {
            currentFileEntityId: currentFileEntityId,
            currentPartFileId: currentPartFile.id,
            // @ts-ignore
            filePartsToLoadMap: JSON.stringify(mapToObject(filePartsToLoadMap)),
            // @ts-ignore
            filePartsToLoadInitial: JSON.stringify(mapToObject(filePartsToLoadMap)),
            fileEntitiesNameMap: JSON.stringify(fileEntitiesNameMap),
            xktBase64String: base64String
          }
        },
        "*"
      );
    }
  };

  const fetchAndLoadFileRemainingParts = async (
    loadedFileEntityId: string,
    loadedPartFileId: string,
    fileEntitiesNameMap: Array<IFileEntity>,
    filePartsToLoadMap: Map<string, string[]>,
    filePartsToLoadInitial: Map<string, string[]>
  ) => {
    let filePartsToLoadMapObject = filePartsToLoadMap;
    // @ts-ignore
    const remainingPartsForLoadedFileEntity = filePartsToLoadMapObject.get(loadedFileEntityId).filter((e) => {
      return e !== loadedPartFileId;
    });
    filePartsToLoadMapObject.set(loadedFileEntityId, remainingPartsForLoadedFileEntity);

    let nextFileEntityId: any;
    let nextPartFileId: any;

    if (remainingPartsForLoadedFileEntity.length === 0) {
      filePartsToLoadMapObject.delete(loadedFileEntityId);
      // @ts-ignore
      const manifest = manifests?.find((m) => m.fileEntityId + "" === loadedFileEntityId + "");
      const metaDataJsonBase64String = await downloadAndConvertToBase64(manifest?.metaDataUrl || "");
      window.postMessage(
        {
          type: "loadModel",
          model: {
            fileEntityId: loadedFileEntityId,
            metaDataJsonBase64: metaDataJsonBase64String,
            fileEntitiesNameMap: JSON.stringify(fileEntitiesNameMap),
            // @ts-ignore
            filePartsToLoadInitial: JSON.stringify(mapToObject(filePartsToLoadInitial))
          }
        },
        "*"
      );

      if (filePartsToLoadMapObject.size === 0) {
        window.postMessage({ type: "endLoaderAnimation" }, "*");
        return;
      } else {
        nextFileEntityId = filePartsToLoadMapObject.keys().next().value;
        nextPartFileId = filePartsToLoadMapObject.get(nextFileEntityId)?.at(0);
      }
    } else {
      nextFileEntityId = loadedFileEntityId;
      nextPartFileId = remainingPartsForLoadedFileEntity.at(0);
    }

    if (nextFileEntityId === undefined || nextPartFileId === undefined) {
      window.postMessage({ type: "endLoaderAnimation" }, "*");
      return;
    }

    if (!nextFileEntityId) return;
    // @ts-ignore
    const manifest = manifests?.find((m) => m.fileEntityId + "" === nextFileEntityId + "");
    // @ts-ignore
    const nextPartFile = manifest?.xktFiles.find((e) => e.id + "" === nextPartFileId + "");
    if (nextPartFile == null) return;

    const base64String = await downloadAndConvertToBase64(nextPartFile.url);

    window.postMessage(
      {
        type: "loadFiles",
        files: {
          currentFileEntityId: nextFileEntityId,
          currentPartFileId: nextPartFile.id,
          // @ts-ignore
          filePartsToLoadMap: JSON.stringify(mapToObject(filePartsToLoadMapObject)),
          // @ts-ignore
          filePartsToLoadInitial: JSON.stringify(mapToObject(filePartsToLoadInitial)),
          fileEntitiesNameMap: JSON.stringify(fileEntitiesNameMap),
          xktBase64String: base64String
        }
      },
      "*"
    );
  };

  useEffect(() => {
    function handleMessage(event: any) {
      // Access the message data from the event object
      const messageData = event.data;

      // Check if the message is the one you are expecting
      if (messageData && messageData.handler === "partLoaded") {
        const loadedFileEntityId = event.data.fileEntityId + "";
        const loadedPartFileId = event.data.partFileId + "";
        const fileEntitiesNameMap = JSON.parse(event.data.fileEntitiesNameMap);
        const filePartsToLoadMap = objectToMap(JSON.parse(event.data.filePartsToLoadMap));
        const filePartsToLoadInitial = objectToMap(JSON.parse(event.data.filePartsToLoadInitial));
        if (loadedFileEntityId != null && loadedPartFileId != null) {
          // @ts-ignore
          fetchAndLoadFileRemainingParts(loadedFileEntityId, loadedPartFileId, fileEntitiesNameMap, filePartsToLoadMap, filePartsToLoadInitial);
        }
      }
    }

    window.addEventListener("message", handleMessage);

    return function () {
      window.removeEventListener("message", handleMessage);
    };
  }, [manifests]);

  useEffect(() => {
    async function startWithDelay() {
      await delay(1000);
      dispatch(fetchFilesAsync(folderFileEntityId)).then((response: any) => {
        const files: Array<IFileEntity> = response.payload.files;
        const xktFiles = files.filter((fileEntity) => fileEntity.parentFileEntityId + "" === folderFileEntityId && fileEntity.convertedToXkt);

        const fileIds = xktFiles.map((file) => file.id);
        fetchBimManifests(fileIds).then((manifestDTOs) => {
          setManifests(manifestDTOs);
          loadFilesIntoViewer(xktFiles, manifestDTOs);
        });
      });
    }

    startWithDelay();
  }, []);

  return <XeokitMobileViewer />;
};

export default XeokitMobileViewerEmulator;
