import React, { FC, ReactElement, useCallback } from "react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { uploadAttachmentFileAsync } from "@/app/store/uploadSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhAddFilesContainer from "@components/upload/BhAddFilesContainer";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";

interface Props {
  existingAttachments?: Array<IFileEntity>;
  parentFileEntityId?: number;
  property?: string;
  button?: ReactElement;
  saveCallback: Function;
  dropdownPosition?: BhDropdownPositionEnum;
  disabled?: boolean;
  multiple?: boolean;
  excludeFileTypes?: Array<FileEntityType>;
  fileTypesAllowedForUpload?: string;
  fileUploadAllowed: boolean;
  chooseFromProjectAllowed: boolean;
  onlyPdfTronFilesAllowedFromProjectFiles?: boolean;
  uploadCallback?: Function;
  importCallback?: Function;
}

const BhAttachmentsUploadContainer: FC<Props> = ({
  existingAttachments,
  parentFileEntityId,
  property,
  button,
  saveCallback,
  dropdownPosition,
  disabled,
  multiple,
  excludeFileTypes,
  fileTypesAllowedForUpload,
  fileUploadAllowed,
  chooseFromProjectAllowed,
  onlyPdfTronFilesAllowedFromProjectFiles,
  uploadCallback,
  importCallback
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const previousAttachments = existingAttachments || [];
  const fileTypesAllowedForUploadKey = fileTypesAllowedForUpload;

  const onDropCallback = useCallback(
    (acceptedFiles: any, rejectedFiles: any) => {
      if (!acceptedFiles) return;
      if (acceptedFiles.length > 0) {
        const filesWithSizeLargerThanZero = acceptedFiles.filter((file: any) => file.size > 0);
        if (!multiple && acceptedFiles.length > 1) dispatch(toastFlagAdded({ id: uuidv4(), type: BauhubBannerType.ERROR, disappear: true, translateCode: "TOAST.ONLY_ONE_FILE" }));
        onUploadFilesSelected(multiple ? filesWithSizeLargerThanZero : [filesWithSizeLargerThanZero[0]]);
      }
      if (rejectedFiles.length > 0) {
        dispatch(toastFlagAdded({ id: uuidv4(), type: BauhubBannerType.ERROR, disappear: true, translateCode: "ERROR.WRONG_FILE_FORMAT" }));
      }
    },
    [previousAttachments]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      onDropCallback(acceptedFiles, fileRejections);
    },
    noClick: true,
    disabled: disabled,
    ...(fileTypesAllowedForUploadKey && { accept: { "*": fileTypesAllowedForUpload?.split(",") || [] } })
  });

  const combineAndSave = (addedFiles: Array<IFileEntity>, isUpload: boolean) => {
    const newFiles = addedFiles.filter((file) => !previousAttachments.some((f) => f.id === file.id));
    if (newFiles.length > 0) {
      const combinedAttachments = multiple ? [...newFiles, ...previousAttachments] : [...newFiles];
      const changedValue = { [property as string]: combinedAttachments };
      saveCallback(changedValue, isUpload);
    }
  };

  const onUploadFilesSelected = async (files: any) => {
    if (disabled) {
      return;
    }
    if (uploadCallback) {
      uploadCallback(files);
      return;
    }
    let savedFiles: Array<IFileEntity> = [];
    await Promise.all(
      files.map(async (file: any) => {
        await dispatch(uploadAttachmentFileAsync({ file: file, projectId: projectId, parentFileEntityId: parentFileEntityId })).then((action) => {
          const savedFileEntity = action.payload as IFileEntity;
          if (savedFileEntity.id) {
            savedFiles.push(savedFileEntity);
          }
        });
      })
    ).then(() => {
      if (savedFiles.length > 0) {
        combineAndSave(savedFiles, true);
      }
    });
  };

  const onProjectFilesSelected = (files: Array<IFileEntity>) => {
    if (disabled) {
      return;
    }
    combineAndSave(files, false);
  };

  const buttonForUpload = button || <BhTextOnlyButton icon={faPlus}>{t("GLOBAL.ADD_FILE")}</BhTextOnlyButton>;

  return (
    <BhAddFilesContainer
      uploadFilesSelectedCallback={fileUploadAllowed ? (files: any) => onUploadFilesSelected(files) : undefined}
      projectFilesSelectedCallback={chooseFromProjectAllowed ? (files: Array<IFileEntity>) => onProjectFilesSelected(files) : undefined}
      importCallback={importCallback}
      button={buttonForUpload}
      disabled={disabled}
      multiple={multiple}
      excludeFileTypes={excludeFileTypes}
      dropdownPosition={dropdownPosition}
      fileTypesAllowedForUpload={fileTypesAllowedForUpload}
      onlyPdfTronFilesAllowedFromProjectFiles={onlyPdfTronFilesAllowedFromProjectFiles}
    />
    // <div {...getRootProps({})}>
    //   <input {...getInputProps()} />
    //   <BhAddFilesContainer
    //     uploadFilesSelectedCallback={fileUploadAllowed ? (files: any) => onUploadFilesSelected(files) : undefined}
    //     projectFilesSelectedCallback={chooseFromProjectAllowed ? (files: Array<IFileEntity>) => onProjectFilesSelected(files) : undefined}
    //     button={buttonForUpload}
    //     disabled={disabled}
    //     multiple={multiple}
    //     excludeFileTypes={excludeFileTypes}
    //     dropdownPosition={dropdownPosition}
    //     fileTypesAllowedForUpload={fileTypesAllowedForUpload}
    //     onlyPdfTronFilesAllowedFromProjectFiles={onlyPdfTronFilesAllowedFromProjectFiles}
    //   />
    // </div>
  );
};

export default BhAttachmentsUploadContainer;
