import { bauhubGet, bauhubPublicGet, bauhubPublicPost } from "@/api/bauhubAPI";
import { IInvite, IInviteResponse } from "@/model/invites/IInvite";
import { IUserRemovalInfoResponse } from "@/model/invites/IUserRemovalInfoResponse";
import { EntityId } from "@reduxjs/toolkit";

export function resendInvite(projectId: EntityId, invite: IInvite): Promise<IInvite> {
  return bauhubGet("/project/" + projectId + "/invite/" + invite.uuid + "/resend");
}

export function resendAllInvitesForCompanyAndUsername(companyId: EntityId, username: string): Promise<Array<IInvite>> {
  return bauhubGet("/company/" + companyId + "/invites/" + username + "/resend");
}

export function deleteInvite(projectId: EntityId, invite: IInvite): Promise<IInvite> {
  return bauhubGet("/project/" + projectId + "/invite/" + invite.uuid + "/delete");
}

export function fetchProjectAdministratorInvites(projectId: EntityId): Promise<Array<IInvite>> {
  return bauhubGet("/invite/project/" + projectId);
}

export function fetchPendingInvitesForRemovalFromProject(projectId: EntityId, userId: EntityId): Promise<IUserRemovalInfoResponse> {
  return bauhubGet("/project/" + projectId + "/user/" + userId + "/invites/pending");
}

export function fetchPublicInvite(inviteUuid: string): Promise<IInviteResponse> {
  return bauhubPublicGet("/invite/" + inviteUuid + "?disableErrors=true");
}

export function registerUser(inviteUuid: string, user: any): Promise<{ value: string }> {
  return bauhubPublicPost("/register/" + inviteUuid, user);
}
