import React, { FC } from "react";
import BhTableHeader from "@components/table/BhTableHeader";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IBauhubEvent } from "@/model/IBauhubEvent";
import { IUserSigningInfo } from "@/model/container/IContainerCreateRequest";
import NewContainerSignaturesListRow from "@/views/home/project/detail/container/containerComponents/NewContainerSignaturesListRow";
import { useTranslation } from "react-i18next";

interface Props {
  users: Array<IUserSigningInfo>;
  isQueueEnabled: boolean;
  removeSigner: Function;
  insertOldIndexToNewIndex: Function;
}

const NewContainerSignaturesList: FC<Props> = ({ users, isQueueEnabled, removeSigner, insertOldIndexToNewIndex }) => {
  const { t } = useTranslation();

  const tableColumnHeaders: Array<IBhTableHeader<IBauhubEvent>> = [
    { id: 1, content: "", classes: "w-6" },
    { id: 2, content: t("GLOBAL.ORDER"), classes: "w-14 text-center" },
    { id: 3, content: t("GLOBAL.NAME") },
    { id: 4, content: "", classes: "w-10" }
  ];
  const finalTableColumnHeaders = isQueueEnabled ? tableColumnHeaders : tableColumnHeaders.slice(2);

  if (!users || users.length === 0) {
    return null;
  }

  return (
    <table className="min-w-full overflow-auto">
      <BhTableHeader columns={finalTableColumnHeaders} />
      <tbody className="divide-y overflow-hidden ">
        {users.map((signer, index) => {
          return (
            <NewContainerSignaturesListRow key={index} signer={signer} isQueueEnabled={isQueueEnabled} removeSigner={removeSigner} index={index} insertOldIndexToNewIndex={insertOldIndexToNewIndex} />
          );
        })}
      </tbody>
    </table>
  );
};

export default NewContainerSignaturesList;
