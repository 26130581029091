import React, { Dispatch, FC, SetStateAction } from "react";
import BhModal from "@components/modal/BhModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/pro-solid-svg-icons/faWarning";
import BhModalFooter from "@components/modal/BhModalFooter";
import { useTranslation } from "react-i18next";

interface Props {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleResend: Function;
}

const ResendInvitationModal: FC<Props> = ({ setIsOpen, handleResend }) => {
  const { t } = useTranslation();

  return (
    <BhModal
      isShown={true}
      setIsShown={setIsOpen}
      header={
        <div className="flex flex-row items-center">
          <FontAwesomeIcon className="bh-text-warning-yellow mt-1 pr-5" icon={faWarning} />
          <h2>{t("SHARE.RESEND")}</h2>
        </div>
      }
      children={<div className="px-8">{t("COMPANY.SETTINGS.USERS.RESEND_INVITATION")}</div>}
      footer={<BhModalFooter isWarningButton={true} confirmButtonText={t("GLOBAL.RESEND") as string} onConfirm={handleResend} onCancel={setIsOpen} />}
    />
  );
};

export default ResendInvitationModal;
