import { FC, useEffect, useState } from "react";
import { fetchPublicShareboxByShareboxUuid, fetchPublicShareboxByUrlUuid } from "@/api/shareboxAPI";
import { ISharebox } from "@/model/shareboxes/ISharebox";
import { IShareboxUrl } from "@/model/shareboxes/IShareboxUrl";
import PublicShareboxContainer from "@/views/home/project/detail/shareboxes/detail/public/PublicShareboxContainer";
import PublicShareboxErrorContainer from "@/views/home/project/detail/shareboxes/detail/public/PublicShareboxErrorContainer";
import { useAppDispatch } from "@/app/hooks";
import { shareboxesAdded } from "@/app/store/shareboxesSlice";
import { filesUpdatedAndAdded } from "@/app/store/filesSlice";

interface Props {
  shareboxUrlUuid?: string;
  shareboxUuid?: string;
}

const PublicShareboxDataContainer: FC<Props> = ({ shareboxUrlUuid, shareboxUuid }) => {
  const [sharebox, setSharebox] = useState<ISharebox>();
  const [shareboxUrl, setShareboxUrl] = useState<IShareboxUrl>();
  const [error, setError] = useState<string>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (shareboxUrlUuid) {
      fetchByUrlUuid(shareboxUrlUuid);
    } else if (shareboxUuid) {
      fetchByShareboxUuid(shareboxUuid);
    }
  }, []);

  const fetchByUrlUuid = (shareboxUrlUuid: string) => {
    fetchPublicShareboxByUrlUuid(shareboxUrlUuid)
      .then((response) => {
        setSharebox(response.sharebox);
        dispatch(shareboxesAdded([response.sharebox]));
        dispatch(filesUpdatedAndAdded(response.sharebox.fileList || []));
        setShareboxUrl(response.shareboxUrl);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const fetchByShareboxUuid = (shareboxUuid: string) => {
    fetchPublicShareboxByShareboxUuid(shareboxUuid)
      .then((response: ISharebox) => {
        setError(undefined);
        setSharebox(response);
        dispatch(shareboxesAdded([response]));
        dispatch(filesUpdatedAndAdded(response.fileList || []));
      })
      .catch((error) => {
        // Temporary hack while old links are still used
        if (error.message === "SHAREBOX.NO_SHAREBOX" || error.message === "SHAREBOX.NO_URL") {
          fetchByUrlUuid(shareboxUuid);
        } else {
          setError(error.message);
        }
      });
  };

  if (error) {
    return <PublicShareboxErrorContainer error={error} />;
  }

  if (!sharebox) {
    return null;
  }

  if (shareboxUrl) {
    return <PublicShareboxContainer sharebox={sharebox} shareboxUrl={shareboxUrl} />;
  } else {
    return <PublicShareboxContainer sharebox={sharebox} />;
  }
};

export default PublicShareboxDataContainer;
