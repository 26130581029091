import React from "react";
import { useAppSelector } from "@/app/hooks";
import { boSelectYearlyInvoiceOverview } from "@/app/store/backofficeSlice";
import BhLineChart, { IBhChartAccessors, IBhChartData, IBhChartLinePoint } from "@components/charts/BhLineChart";
import BhChartLegend, { IBhChartLegendData } from "@components/charts/BhChartLegend";
import { formatCurrency } from "@/utilities/accountingUtilities";

const BOYearlyInvoiceOverviewChart = () => {
  const yearlyInvoiceOverview = useAppSelector(boSelectYearlyInvoiceOverview);

  const chartData = yearlyInvoiceOverview?.data?.map((row) => {
    const date = new Date(row.yearMonth);
    const month = date.toLocaleString("default", { month: "short" });
    return {
      name: month,
      monthly: row.monthlyInvoiceSum,
      annual: row.yearlyInvoiceSum,
      total: row.totalSum
    };
  });

  const total: Array<IBhChartLinePoint> = chartData?.map((item) => ({ dataKey: "Total", x: item.name, y: item.total, color: "#00B95F" }));
  const monthly: Array<IBhChartLinePoint> = chartData?.map((item) => ({ dataKey: "Monthly", x: item.name, y: item.monthly, color: "#1A65A7" }));
  const annual: Array<IBhChartLinePoint> = chartData?.map((item) => ({ dataKey: "Annual", x: item.name, y: item.annual, color: "#EDAB00" }));
  const data: IBhChartData = { total, monthly, annual };

  const accessors: IBhChartAccessors = {
    xAccessor: (d: any) => d.x,
    yAccessor: (d: any) => d.y,
    colorAccessor: (d: any) => d.color
  };
  const legendData: Array<IBhChartLegendData> = [
    { label: "Total", color: "#00B95F" },
    { label: "Monthly", color: "#1A65A7" },
    { label: "Annual", color: "#EDAB00" }
  ];
  const formatPriceAxisTick = (value: number) => {
    return formatCurrency(value, { symbol: "€", significantDigits: 2, thousandsSeparator: String.fromCharCode(160), decimalSeparator: "," }, true);
  };

  return (
    <div className="flex">
      <BhChartLegend legendData={legendData} legendTitle="Sales summary" />
      {total && monthly && annual && <BhLineChart accessors={accessors} data={data} leftAxisFormatter={formatPriceAxisTick} />}
    </div>
  );
};

export default BOYearlyInvoiceOverviewChart;
