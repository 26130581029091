import React, { FC, useEffect, useRef, useState } from "react";
import WebViewer, { Core, WebViewerInstance } from "@pdftron/webviewer";
import { fetchUrlForFile } from "@/api/fileAPI";
import { EntityId } from "@reduxjs/toolkit";
import { shouldUsePdftronServer } from "@/utilities/fileEntity/fileEntityUtilities";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchFileEntityAsync, selectFileById } from "@/app/store/filesSlice";
import {
  fetchFileAnnotationAsync,
  saveFileAnnotationAsync,
  selectFileAnnotationForFileEntityId,
  selectRemoveAnnotationFromChecklist,
  setRemovedAnnotationIdsForFileAnnotationId
} from "@/app/store/fileAnnotationsSlice";
import { FileEntityType } from "@/model/files/IFileEntity";
import { ReactComponent as BhLogoLoading } from "@svg/bauhub-loading-compressed.svg";
import { setOpenedTaskInfo } from "@/app/store/tasksSlice";
import { toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  fileEntityId: EntityId;
  fileEntityUuid?: string;
  rowAnnotationsShown: boolean;
  isRoundEditable?: boolean;
}

const ChecklistsPdftronViewOnlyContainer: FC<Props> = ({ fileEntityId, fileEntityUuid, rowAnnotationsShown, isRoundEditable }) => {
  const fileEntity = useAppSelector((state) => selectFileById(state, fileEntityId));
  const [webviewerInitializing, setWebviewerInitializing] = useState(false);
  const fileAnnotation = useAppSelector((state) => selectFileAnnotationForFileEntityId(state, fileEntityId));
  const [instance, setInstance] = useState<WebViewerInstance>();
  const [annotationsDrawnFirstTime, setAnnotationsDrawnFirstTime] = useState(false);
  const [fileUrlLoading, setFileUrlLoading] = useState(true);
  const viewer = useRef(null);
  const removeAnnotationFromChecklist = useAppSelector(selectRemoveAnnotationFromChecklist);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fileEntity && !webviewerInitializing) {
      setWebviewerInitializing(true);
      dispatch(fetchFileAnnotationAsync(fileEntity));
      fetchFileUrlAndInitPdftron();
    }
  }, [fileEntity]);

  useEffect(() => {
    if (!fileEntity) {
      dispatch(fetchFileEntityAsync(fileEntityId));
    }
  }, []);

  useEffect(() => {
    if (fileAnnotation && removeAnnotationFromChecklist) {
      // Kui keegi kustutab checklistist row mille küljes on task ja annotatsioon siis lõpuks jõutakse siia.
      if (removeAnnotationFromChecklist.type === "Task") {
        let changedFileAnnotation = { ...fileAnnotation, removedAnnotationIds: removeAnnotationFromChecklist.annotationIds };

        const deletedAnnotations = removeAnnotationFromChecklist.annotationIds.map((annotationId) => {
          return instance?.Core.annotationManager.getAnnotationById(annotationId);
        });

        const definedDeletedAnnotations = deletedAnnotations.filter((anno) => anno !== undefined);

        // @ts-ignore
        definedDeletedAnnotations?.length > 0 && instance?.Core.annotationManager.deleteAnnotations(definedDeletedAnnotations, { force: true });
        instance?.Core.annotationManager.exportAnnotations({ links: false, widgets: false }).then((xfdfString) => {
          changedFileAnnotation.xfdf = xfdfString;
          dispatch(saveFileAnnotationAsync(changedFileAnnotation));
          dispatch(setRemovedAnnotationIdsForFileAnnotationId(undefined));
        });
      }
      if (removeAnnotationFromChecklist.type === "Row") {
        const allAnnotations = instance?.Core.annotationManager.getAnnotationsList();
        const primaryAnnotation = allAnnotations && allAnnotations.find((annotation) => annotation.Id === removeAnnotationFromChecklist.annotationIds[0]);
        const primaryGroupAnnotations = primaryAnnotation && instance?.Core.annotationManager.getGroupAnnotations(primaryAnnotation);

        primaryAnnotation && instance?.Core.annotationManager.deleteAnnotations([primaryAnnotation], { force: true });
        primaryGroupAnnotations && instance?.Core.annotationManager.deleteAnnotations(primaryGroupAnnotations, { force: true });

        instance?.Core.annotationManager.exportAnnotations({ links: false, widgets: false }).then((xfdfString) => {
          const changedFileAnnotation = { ...fileAnnotation, xfdf: xfdfString };
          dispatch(saveFileAnnotationAsync(changedFileAnnotation));
          dispatch(setRemovedAnnotationIdsForFileAnnotationId(undefined));
        });
      }
    }
  }, [removeAnnotationFromChecklist]);

  useEffect(() => {
    const allAnnotations = instance?.Core.annotationManager.getAnnotationsList();
    const rowAnnotations = allAnnotations ? allAnnotations.filter((annotation) => annotation.Subject === "Row") : [];
    if (rowAnnotationsShown) {
      instance?.Core.annotationManager.showAnnotations(rowAnnotations);
    } else {
      instance?.Core.annotationManager.hideAnnotations(rowAnnotations);
    }
  }, [rowAnnotationsShown]);

  useEffect(() => {
    if (instance && fileAnnotation?.xfdf) {
      const currentAnnotations = instance?.Core.annotationManager.getAnnotationsList();
      instance?.Core.annotationManager.deleteAnnotations(currentAnnotations, { imported: true, force: true });
      instance?.Core.annotationManager.importAnnotations(fileAnnotation.xfdf);
      const allAnnotations = instance?.Core.annotationManager.getAnnotationsList();
      const rowAnnotations = allAnnotations ? allAnnotations.filter((annotation) => annotation.Subject === "Row") : [];
      const otherAnnotations = allAnnotations ? allAnnotations.filter((annotation) => annotation.Subject !== "Row") : [];
      if (rowAnnotationsShown) {
        instance?.Core.annotationManager.showAnnotations(rowAnnotations);
      } else {
        instance?.Core.annotationManager.hideAnnotations(rowAnnotations);
      }
      instance?.Core.annotationManager.showAnnotations(otherAnnotations);
    }
  }, [fileAnnotation?.xfdf]);

  const drawAnnotations = () => {
    if (fileAnnotation && fileAnnotation.xfdf) {
      instance?.Core.documentViewer.addEventListener("documentLoaded", function () {
        instance?.Core.annotationManager.importAnnotations(fileAnnotation.xfdf);
        const allAnnotations = instance?.Core.annotationManager.getAnnotationsList();
        instance?.Core.annotationManager.showAnnotations(allAnnotations);
        const rowAnnotations = allAnnotations.filter((annotation) => annotation.Subject === "Row");
        instance?.Core.annotationManager.hideAnnotations(rowAnnotations);
      });
    }
  };

  // Add first time annotation drawing for one time only
  useEffect(() => {
    if (instance && fileAnnotation && !annotationsDrawnFirstTime) {
      drawAnnotations();
      setAnnotationsDrawnFirstTime(true);
    }
  }, [fileAnnotation, instance]);

  const fetchFileUrlAndInitPdftron = () => {
    async function fetchFileUrl() {
      setFileUrlLoading(true);
      return await fetchUrlForFile(fileEntityId, false, false, fileEntityUuid);
    }

    fetchFileUrl()
      .then((presignedUrl) => {
        setFileUrlLoading(false);
        if (!viewer.current || instance) return;

        const formExtension = fileEntity?.type === FileEntityType.FORM ? ".pdf" : "";
        const fileName = fileEntity?.name + "" + formExtension;

        WebViewer(
          {
            licenseKey: "Bauhub OU:OEM:Bauhub::B+:AMS(20260305):4A5F85823C6F78B942CE606F4F3D959CDE1FC65BA4AF8F58BE54B2B6F5C7",
            filename: fileName,
            annotationUser: "ViewOnly",
            useDownloader: fileEntity && fileEntity.size > 40000000,
            path: ConfigSingleton.getInstance().getConfig().PUBLIC_URL + "/pdftron10.9.0",
            initialDoc: presignedUrl.value,
            extension: fileName.split(".").pop(),
            webviewerServerURL: fileEntity && shouldUsePdftronServer(fileEntity) ? "https://pdf3.bauhub.ee" : undefined,
            disabledElements: ["header", "markReplaceTextToolButton", "annotationCommentButton"],
            isReadOnly: true
          },
          viewer.current
        ).then((instance) => {
          setInstance(instance);

          instance.UI.setFitMode(instance.UI.FitMode.FitPage);

          instance.UI.hotkeys.off();

          const annoSelectedCB = function (annotationsList: Array<Core.Annotations.Annotation>, action: string) {
            if (action === "selected") {
              if (annotationsList.length === 1) {
                const selectedAnnotation = annotationsList[0];
                if (selectedAnnotation.Subject === "Task") {
                  const taskId = selectedAnnotation.getCustomData("taskId");
                  dispatch(setOpenedTaskInfo({ id: taskId, disabled: !isRoundEditable }));
                  dispatch(toggleProjectModalsOpen({ modal: "projectTaskModal" }));
                }
              }
            }
          };

          instance?.Core.annotationManager.addEventListener("annotationSelected", annoSelectedCB);
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    return function cleanup() {
      instance?.UI.dispose();
    };
  }, []);

  if (!fileEntity) {
    return null;
  }

  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex h-full w-full flex-row overflow-hidden">
        <div className="relative flex-1">
          {fileUrlLoading && (
            <div className="flex h-full w-full flex-row items-center justify-center">
              <BhLogoLoading className="h-10 w-10" />
            </div>
          )}
          <div className="webviewer" ref={viewer} style={{ height: "100%", width: "100%" }} />
        </div>
      </div>
    </div>
  );
};

export default ChecklistsPdftronViewOnlyContainer;
