import React, { FC, ReactNode } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  classes?: string;
  children: ReactNode;
}

const BhLargeText: FC<Props> = ({ classes, children }) => {
  return <p className={classNames(classes, "text-18px")}>{children}</p>;
};

export default React.memo(BhLargeText);
