import React, { FC, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BhIconButton from "@components/buttons/BhIconButton";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { MetaObject, Viewer } from "@xeokit/xeokit-sdk";
import XeokitSidebarMenuObjectProperties from "@/views/home/project/detail/xeokit/xeokitSidebar/XeokitSidebarMenuObjectProperties";
import { useTranslation } from "react-i18next";

interface Props {
  viewer: Viewer;
  propertiesVisible: boolean;
  setPropertiesVisible: Function;
}

const XeokitSidebarMenuObjectInfo: FC<Props> = ({ viewer, propertiesVisible, setPropertiesVisible }) => {
  const { t } = useTranslation();
  const [objectProperties, setObjectProperties] = useState<MetaObject | undefined>(undefined);

  useEffect(() => {
    if (!viewer) return;

    const onObjectSelected = viewer.scene.on("objectSelected", (entity) => {
      const metaObject = viewer.metaScene.metaObjects[entity.id];
      if (metaObject) {
        setObjectProperties(metaObject);
      }
    });

    const pickedNothingId = viewer.cameraControl.on("pickedNothing", () => {
      setObjectProperties(undefined);
    });

    return function cleanup() {
      viewer.scene.off(onObjectSelected);
      viewer.cameraControl.off(pickedNothingId);
    };
  }, [viewer]);

  return (
    <div className={propertiesVisible ? "flex flex-1 flex-col overflow-hidden" : "hidden"}>
      <div className="flex flex-row items-center justify-between p-1">
        <div className="flex flex-row items-center p-2 font-bold">
          <div className="pr-2">
            <FontAwesomeIcon icon={faInfoCircle} />
          </div>
          <div>{t("BIM.PROPERTIES")}</div>
        </div>
        <BhIconButton
          icon={faTimes}
          buttonProps={{
            onClick: () => {
              setPropertiesVisible(false);
              setObjectProperties(undefined);
            }
          }}
        />
      </div>
      <div className="overflow-auto p-3">
        {!objectProperties && <div>{t("BIM.NO_OBJECTS_SELECTED")}</div>}
        {objectProperties && <XeokitSidebarMenuObjectProperties objectProperties={objectProperties} viewer={viewer} />}
      </div>
    </div>
  );
};

export default XeokitSidebarMenuObjectInfo;
