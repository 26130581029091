import React, { FC } from "react";
import { ISignInvite } from "@/model/ISignInvite";
import BhSignatureContainerStatusForUser from "@components/container/BhSignatureContainerStatusForUser";
import { Link } from "react-router-dom";
import BhCardContainer from "@components/cards/BhCardContainer";
import { formatDateTime } from "@/utilities/dateUtility";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  signInvite: ISignInvite;
}

const SignInvitesListItem: FC<Props> = ({ signInvite }) => {
  return (
    <BhCardContainer>
      <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${signInvite.projectId}/container/${signInvite.container.id}`} className="w-full">
        <div className="flex flex-row items-center px-6 py-2">
          <div className="flex flex-1 flex-col">
            <div className="text-18px mb-2 font-bold">{signInvite.container.name}</div>
            <div className="bh-text-deep-ocean-80">
              <span>
                <Trans>SIGN_INVITE.TABLE.CREATED</Trans> {formatDateTime(signInvite.created)}
              </span>
              <span className="text-9px bh-text-pigeon-50 px-2">
                <FontAwesomeIcon icon={faCircle} size="xs" />
              </span>
              <span>
                <Trans>SIGN_INVITE.TABLE.ASSIGNEE</Trans> <strong>{signInvite.assignee}</strong>
              </span>
            </div>
          </div>
          <div className="flex w-48 flex-col p-2">
            <div className="text-12px mb-1.5">
              <Trans>SIGNATURE.MY_STATUS</Trans>
            </div>
            <div>
              <BhSignatureContainerStatusForUser signInvite={signInvite} />
            </div>
          </div>
          <div className="flex w-40 flex-col p-2">
            <div className="text-12px mb-1.5">{signInvite.declined ? <Trans>SIGNATURE.DECLINED_TIME</Trans> : <Trans>SIGNATURE.SIGNED_TIME</Trans>}</div>
            <div>
              <span>{formatDateTime(signInvite.signedOrDeclinedTime)}</span>
            </div>
          </div>
        </div>
      </Link>
    </BhCardContainer>
  );
};

export default SignInvitesListItem;
