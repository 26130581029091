import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { EntityId } from "@reduxjs/toolkit";
import { IFileAnnotation } from "@/model/files/IFileAnnotation";

export function fetchFileAnnotation(projectId: EntityId, fileEntityId: EntityId): Promise<IFileAnnotation> {
  return bauhubGet("/project/" + projectId + "/pdf/" + fileEntityId + "/annotation");
}

export function saveFileAnnotation(fileAnnotation: IFileAnnotation): Promise<IFileAnnotation> {
  return bauhubPost("/pdf/annotation?st=false", fileAnnotation);
}
