import React from "react";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import BhIconButton from "@components/buttons/BhIconButton";
import { ISimpleProject } from "@/model/projects/ISimpleProject";
import { useAppDispatch } from "@/app/hooks";
import { ICompanyInvitedUser } from "@/model/companyUsers/ICompanyInvitedUser";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons/faArrowsRotate";
import { resendAllInvitesForCompanyAndUsernameAsync } from "@/app/store/companiesSlice";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { removeUserOrInvitedUserFromProjectAsync } from "@/app/store/project/projectSlice";
import ResendInvitationModal from "@/views/home/company/detail/companySettingsUsers/ResendInvitationModal";

interface Props {
  project: ISimpleProject;
  user: ICompanyUser | ICompanyInvitedUser;
}

const CompanyUserProject = React.memo<Props>(({ project, user }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteConfirmationModal}
      header={<h2>{t("PROJECT.CONFIRM_ADMIN_REMOVE.REMOVE")}</h2>}
      body={<div dangerouslySetInnerHTML={{ __html: t("COMPANY.SETTINGS.USERS.DELETE.WORKGROUP_AUTHORITIES.BODY") as any }} />}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={() => {
        dispatch(removeUserOrInvitedUserFromProjectAsync({ companyId: user.companyId, projectId: project.projectId, username: user.username, userExists: user.userExists }));
      }}
    />
  ));

  const [showResendInvitationConfirmationModal, hideResendInvitationConfirmationModal] = useModal(() => (
    <ResendInvitationModal
      setIsOpen={hideResendInvitationConfirmationModal}
      handleResend={() => {
        dispatch(resendAllInvitesForCompanyAndUsernameAsync({ companyId: user.companyId, username: user.username }));
        hideResendInvitationConfirmationModal();
      }}
    />
  ));

  return (
    <div className="flex flex-row items-center justify-between">
      <span className="my-1 inline-block align-middle font-bold">{project.projectName}</span>
      <div className="flex grow-0 flex-row justify-end lg:flex-col-reverse">
        {!user.userExists && user.projects.length === 1 && (
          <BhTooltip body={t("GLOBAL.RESEND")}>
            <BhIconButton icon={faArrowsRotate} buttonProps={{ onClick: showResendInvitationConfirmationModal }} />
          </BhTooltip>
        )}
        <BhTooltip body={t("COMPANY.SETTINGS.USERS.REMOVE_FROM_PROJECT")}>
          <BhIconButton
            icon={faCircleMinus}
            buttonProps={{
              onClick: showDeleteConfirmationModal
            }}
          />
        </BhTooltip>
      </div>
    </div>
  );
});

export default CompanyUserProject;
