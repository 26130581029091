import React, { Dispatch, FC, SetStateAction } from "react";
import PartiesListItemTag from "@/views/home/project/detail/users/parties/PartiesListItemTag";
import BhUserIconGrouped from "@components/user/BhUserIconGrouped";
import { IWorkGroup } from "@/model/IWorkGroup";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import BhLargeBoldText from "@components/text/BhLargeBoldText";
import { formatDateTime } from "@/utilities/dateUtility";
import { useTranslation } from "react-i18next";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "@/utilities/jsUtilities";
import { useAppSelector } from "@/app/hooks";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { faStar as faStarSolid } from "@fortawesome/pro-solid-svg-icons/faStar";
import { faStar as faStarRegular } from "@fortawesome/pro-regular-svg-icons/faStar";

interface Props {
  party: IWorkGroup;
  setValuationModalOpen: Dispatch<SetStateAction<boolean>>;
}

const MaruPartiesListItemInfo: FC<Props> = ({ party, setValuationModalOpen }) => {
  const { t } = useTranslation();
  const isCurrentUserProjectAdmin = useAppSelector((state) => party && selectIsCurrentUserProjectAdmin(state, party.projectId));

  if (!party) {
    return null;
  }

  return (
    <div className="flex w-full">
      <div className="mx-6 mt-0.5 w-full flex-col">
        <BhLargeBoldText>{party.name}</BhLargeBoldText>
        <div className="mb-2 -mt-2 flex items-center gap-1">
          <div className="flex items-center gap-3">
            {party.type && <PartiesListItemTag workGroupType={party.type} />}
            <p>{t("GLOBAL.ORDER_BY.CREATED") + ": " + formatDateTime(party.created)}</p>
          </div>
          {party.uniqueClientId && isCurrentUserProjectAdmin && (
            <BhTextOnlyButton
              buttonProps={{
                onClick: (e: any) => {
                  e.stopPropagation();
                  setValuationModalOpen(true);
                }
              }}
            >
              <FontAwesomeIcon icon={party.maruValuationId ? faStarSolid : faStarRegular} size={"lg"} className={classNames(party.maruValuationId && "bh-text-warning-yellow")} />
            </BhTextOnlyButton>
          )}
        </div>
      </div>
      <div className="flex">
        {party.users.length > 0 && (
          <div className="min-w-34 mr-6 flex items-center justify-end">
            <BhUserIconGrouped userGroup={party.users as Array<ISimpleValidUserAuthority>} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MaruPartiesListItemInfo;
