import React, { FC } from "react";
import { Link } from "react-router-dom";
import BhHeadingBreadcrumbText from "@components/breadcrumb/BhHeadingBreadcrumbText";
import { Trans, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import ShareboxDropdown from "@/views/home/project/detail/shareboxes/ShareboxDropdown";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faLink } from "@fortawesome/pro-regular-svg-icons/faLink";
import { copyToClipboard } from "@/utilities/jsUtilities";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { EntityId } from "@reduxjs/toolkit";
import { selectShareboxById } from "@/app/store/shareboxesSlice";
import BhSeparatorMarginless from "@components/separator/BhSeparatorMarginless";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  shareboxId: EntityId;
}
const ShareboxDetailViewHeader: FC<Props> = ({ shareboxId }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const sharebox = useAppSelector((state) => selectShareboxById(state, shareboxId));

  if (!sharebox) return null;

  const shareboxPublicUrl = ConfigSingleton.getInstance().getConfig().REACT_APP_BASE_URL + "/public/sharebox/" + sharebox.uuid;

  return (
    <>
      <div className="flex w-full flex-row items-center gap-2 py-5 pl-4 pr-8">
        <div className="flex flex-1 items-center space-x-2">
          <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/shareboxes/user`}>
            <BhHeadingBreadcrumbText>
              <Trans>HOME.SHAREBOXES</Trans>
            </BhHeadingBreadcrumbText>
          </Link>
          <FontAwesomeIcon icon={faChevronRight} />
          <h2 className="m-0">{sharebox.name}</h2>
          <ShareboxDropdown shareboxId={shareboxId} />
        </div>
        <div>
          <BhPrimaryButton icon={faLink} buttonProps={{ onClick: () => copyToClipboard(shareboxPublicUrl, "TOAST.LINK_COPIED") }}>
            {t("SHAREBOX.COPY_LINK")}
          </BhPrimaryButton>
        </div>
      </div>
      <BhSeparatorMarginless />
    </>
  );
};

export default ShareboxDetailViewHeader;
