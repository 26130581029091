import React, { FC } from "react";
import BhFileCardMedium from "@components/cards/BhFileCardMedium";
import BhDatePicker from "@components/input/BhDatePicker";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/form/formSlice";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";

interface Props {
  field: any;
  saveCallback?: Function;
  disabled: boolean;
  isPreview?: boolean;
}

const FormAttachmentsWithDatesField: FC<Props> = ({ field, saveCallback, disabled, isPreview }) => {
  const { t } = useTranslation();
  const attachments = useAppSelector((state) => (!isPreview ? selectValueByPropertyFromCurrentFormData(state, field.property) : []));

  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteConfirmationModal}
      header={<h2>{t("FORM.REMOVE_ATTACHMENT.MODAL.HEADER")}</h2>}
      body={<div>{t("FORM.REMOVE_ATTACHMENT.MODAL.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={() => {
        const changedObject = { [field.property]: [] };
        saveCallback && saveCallback(changedObject);
        hideDeleteConfirmationModal();
      }}
    />
  ));

  if (!attachments || attachments.length === 0) return null;

  const saveAttachmentDate = (value: any, attachmentToSave: any) => {
    const attachmentsToSave = attachments.map((attachment: any) => {
      if (attachment.id === attachmentToSave.id) {
        return { ...attachment, ...value };
      }
      return attachment;
    });
    saveCallback && saveCallback({ [field.property]: attachmentsToSave });
  };

  const resetAttachmentDate = (attachmentToSave: any) => {
    saveAttachmentDate({ date: null }, attachmentToSave);
  };

  const removeAttachment = (attachmentToRemove: any) => {
    const filteredAttachments = attachments.filter((attachment: any) => attachment.id !== attachmentToRemove.id);
    saveCallback && saveCallback({ [field.property]: filteredAttachments });
  };

  return (
    <div className="flex flex-col">
      {attachments.map((attachment: any) => {
        return (
          <div className="flex flex-row items-center pb-2" key={attachment.id}>
            <div className="w-80">
              <BhFileCardMedium fileEntity={attachment} deleteCallback={!disabled ? (attachmentToRemove: any) => removeAttachment(attachmentToRemove) : undefined} />
            </div>
            <div className="ml-10">
              <BhDatePicker
                property={"date"}
                initialValue={attachment.date && new Date(attachment.date)}
                onChangeCallback={(value: any) => saveAttachmentDate(value, attachment)}
                returnISOString={true}
                onResetCallback={() => resetAttachmentDate(attachment)}
                disabled={disabled}
              />
            </div>
          </div>
        );
      })}
      <div>
        <BhTextOnlyButton icon={faTrash} buttonProps={{ onClick: () => showDeleteConfirmationModal(), disabled: disabled }}>
          {t("FORM.REMOVE_ATTACHMENTS")}
        </BhTextOnlyButton>
      </div>
    </div>
  );
};

export default FormAttachmentsWithDatesField;
