import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { useTranslation } from "react-i18next";
import NewChecklistBaseModal from "@components/checklists/settings/companySettings/NewChecklistBaseModal";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhSelectInputFilter from "@components/filters/BhSelectInputFilter";
import { useAppSelector } from "@/app/hooks";
import {
  selectCategoriesForChecklistFilter,
  selectCategoriesForProjectChecklistFilter,
  selectCurrentCompanyProjectsForChecklistFilter,
  selectUsernamesForChecklistFilter,
  selectUsernamesForProjectChecklistFilter
} from "@/app/store/checklist/checklistsSlice";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  filter: any;
  setFilter: Dispatch<SetStateAction<any>>;
  companyId: EntityId;
  isProjectChecklistTableHeader?: boolean;
}

const CompanyChecklistBasesHeader: FC<Props> = ({ filter, setFilter, companyId, isProjectChecklistTableHeader }) => {
  const { t } = useTranslation();
  const usernamesForCompanyBasesFilter = useAppSelector(selectUsernamesForChecklistFilter);
  const usernamesForProjectBasesFilter = useAppSelector(selectUsernamesForProjectChecklistFilter);
  const categoriesForCompanyBasesFilter = useAppSelector(selectCategoriesForChecklistFilter);
  const categoriesForProjectBasesFilter = useAppSelector(selectCategoriesForProjectChecklistFilter);
  const projectsForFilter = useAppSelector(selectCurrentCompanyProjectsForChecklistFilter);
  const [showNewChecklistBaseModal, setShowNewChecklistBaseModal] = useState(false);
  const categoriesForFilter = isProjectChecklistTableHeader ? categoriesForProjectBasesFilter : categoriesForCompanyBasesFilter;
  const baseCategoryFilterValues = categoriesForFilter.map((category) => {
    return { categoryName: category, translation: t("CHECKLIST.CATEGORY_TAG." + category) };
  });

  const onChange = (search: any) => {
    setFilter({ ...filter, searchString: search.type });
  };

  const onUsernameFilterSelect = (username: string) => {
    setFilter({ ...filter, username: username });
  };

  const onUsernameFilterReset = () => {
    setFilter({ ...filter, username: "" });
  };

  const onProjectFilterSelect = (project: any) => {
    setFilter({ ...filter, projectId: project.id });
  };

  const onProjectFilterReset = () => {
    setFilter({ ...filter, projectId: null });
  };

  const findProjectLabelForFilter = () => {
    const project = projectsForFilter.find((project) => project.id === filter.projectId);
    if (project) {
      return project.name;
    }
    return "";
  };

  const onBaseCategoryFilterSelect = (categoryObject: any) => {
    setFilter({ ...filter, ...{ category: categoryObject } });
  };

  const onBaseCategoryFilterReset = () => {
    setFilter({ ...filter, ...{ category: null } });
  };

  return (
    <div className="flex flex-col">
      <div className="mb-4 mt-1.5 flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-x-4">
          <BhSearchInputWBG property={"type"} onChangeCallback={onChange} inputClasses={"w-72"} placeholder={t("CHECKLIST.SEARCH.TEMPLATE") as string} />
          <BhSelectInputFilter
            values={baseCategoryFilterValues}
            textProperty={"translation"}
            currentValue={filter.category && t(filter.category.translation)}
            onSelect={onBaseCategoryFilterSelect}
            placeholder={t("CHECKLIST_BASE.CATEGORY")}
            onReset={onBaseCategoryFilterReset}
          />
          <BhSelectInputFilter
            values={isProjectChecklistTableHeader ? usernamesForProjectBasesFilter : usernamesForCompanyBasesFilter}
            currentValue={filter.username}
            onSelect={onUsernameFilterSelect}
            placeholder={t("CHECKLIST_BASE.FILTER.CREATED_BY")}
            onReset={onUsernameFilterReset}
          />
          {isProjectChecklistTableHeader && (
            <BhSelectInputFilter
              values={projectsForFilter}
              currentValue={findProjectLabelForFilter()}
              onSelect={onProjectFilterSelect}
              placeholder={t("CHECKLIST.BASE.PROJECT")}
              onReset={onProjectFilterReset}
              textProperty={"name"}
            />
          )}
        </div>
        {!isProjectChecklistTableHeader && (
          <div>
            <BhPrimaryButton icon={faPlus} buttonProps={{ onClick: () => setShowNewChecklistBaseModal(true) }}>
              {t("CHECKLIST.NEW_BASE")}
            </BhPrimaryButton>
          </div>
        )}
        {showNewChecklistBaseModal && <NewChecklistBaseModal setIsShown={setShowNewChecklistBaseModal} companyId={companyId} />}
      </div>
    </div>
  );
};

export default CompanyChecklistBasesHeader;
