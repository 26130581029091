import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "@/app/hooks";
import { classNames } from "@/utilities/jsUtilities";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  companyId: EntityId;
  small?: boolean;
}

const BhCompanyLogo: FC<Props> = ({ companyId, small }) => {
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const [imagePresent, setImagePresent] = useState<boolean>();
  const sizeClasses = small ? "h-8 w-8" : "h-12 w-12";
  const [thumbUrl, setThumbUrl] = useState("https://s3.eu-central-1.amazonaws.com/www.bauhub.ee/public/logo/" + company?.uuid);

  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      setThumbUrl("https://s3.eu-central-1.amazonaws.com/www.bauhub.ee/public/logo/" + company?.uuid + "?dummy=" + Math.floor(Math.random() * 100000));
    } else {
      setThumbUrl("https://s3.eu-central-1.amazonaws.com/www.bauhub.ee/public/logo/" + company?.uuid);
    }
  }, [company]);

  return (
    <div className={classNames(sizeClasses, "m-2 flex flex-row items-center overflow-hidden rounded align-middle")}>
      <img
        className={classNames(imagePresent ? "block" : "hidden", sizeClasses, "rounded object-contain p-0.5")}
        src={thumbUrl}
        crossOrigin=""
        alt="Company logo"
        onError={(e) => setImagePresent(false)}
        onLoad={(e) => setImagePresent(true)}
      />
      {imagePresent === false && (
        <div className={classNames(sizeClasses, "bh-bg-company-name bh-text-white text-16px flex h-full w-full flex-row items-center justify-center")}>
          <div className="flex h-auto flex-row">{company?.name.substring(0, 2)}</div>
        </div>
      )}
    </div>
  );
};

export default BhCompanyLogo;
