import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  deleteWorkGroupAsync,
  maruClientSelected,
  saveChangeWorkGroupTypeAsync,
  saveMaruWorkGroupClientChange,
  saveRenameWorkGroupAsync,
  workGroupRenamed,
  workGroupTypeChanged
} from "@/app/store/project/projectWorkGroupsSlice";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhChip from "@components/chips/BhChip";
import { IWorkGroup, WorkGroupType } from "@/model/IWorkGroup";
import { useTranslation } from "react-i18next";
import { fetchPartyActsAsync, selectActIdsForPartyId } from "@/app/store/actsSlice";
import BhLightSeparator from "@components/separator/BhLightSeparator";
import BhDeclineButton from "@components/buttons/BhDeclineButton";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/pro-regular-svg-icons/faGear";
import { bauhubToString, classNames } from "@/utilities/jsUtilities";
import BhTooltip from "@components/BhTooltip";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { selectCurrentProjectId, selectMaruProjectModule } from "@/app/store/project/projectSlice";
import { useNavigate } from "react-router-dom";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { fetchClientsForMaruProject } from "@/api/maru/maruAPI";
import { isCurrentUserMaruProjectTeamMember } from "@/app/store/userSlice";
import { useModal } from "react-modal-hook";
import BhInputWithDropdownWithFetchValuesOnChange from "@components/input/BhInputWithDropdownWithFetchValuesOnChange";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  workGroup: IWorkGroup;
}

const PartyOverviewContainer: FC<Props> = ({ workGroup }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const newWorkGroup = workGroup.id === -1;
  const projectId = useAppSelector(selectCurrentProjectId);
  const workGroupActIds = useAppSelector((state) => selectActIdsForPartyId(state, workGroup.id));
  const isMaruProject = useAppSelector(selectMaruProjectModule);
  const isBauhubProject = !isMaruProject;
  const isCurrentUserMaruTeamMember = useAppSelector((state) => isCurrentUserMaruProjectTeamMember(state, projectId));

  const disableTypeChangeAndDelete = workGroupActIds.length > 0;
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);

  useEffect(() => {
    if (!newWorkGroup) {
      dispatch(fetchPartyActsAsync({ partyId: workGroup.id, projectId: projectId }));
    }
  }, []);

  const renameWorkGroup = (value: any) => {
    if (value?.name?.length < 1) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          children: t("INPUT.NOT_EMPTY")
        })
      );
      return;
    }
    if (workGroup.id === -1) {
      dispatch(workGroupRenamed({ name: value.name, workGroupId: workGroup.id }));
    } else {
      dispatch(saveRenameWorkGroupAsync({ name: value.name, workGroupId: workGroup.id }));
    }
  };

  const setWorkGroupType = (type: WorkGroupType) => {
    const typeToSave = type !== workGroup.type ? type : null;
    if (disableTypeChangeAndDelete) {
      return;
    }
    if (workGroup.id === -1) {
      dispatch(workGroupTypeChanged({ type: typeToSave, workGroupId: workGroup.id }));
    } else {
      if (isMaruProject && !typeToSave) {
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.WARNING,
            disappear: true,
            children: t("PARTY.MR.TYPE_NOT_CHANGABLE")
          })
        );
        return;
      }
      if (!typeToSave) {
        showWorkGroupTypeRemoveConfirmationModal();
        return;
      }
      dispatch(saveChangeWorkGroupTypeAsync({ type: typeToSave, workGroupId: workGroup.id }));
    }
  };

  const deleteWorkGroup = () => {
    dispatch(deleteWorkGroupAsync({ projectId: projectId, workGroupId: workGroup.id }));
    setDeleteConfirmationModalOpen(false);
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/users/parties`);
  };

  const selectMaruClient = (client: any) => {
    if (workGroup.id === -1) {
      dispatch(maruClientSelected({ name: client.clientname, uniqueClientId: client.uniqueclientid, workGroupId: workGroup.id }));
    } else {
      dispatch(saveMaruWorkGroupClientChange({ name: client.clientname, uniqueClientId: client.uniqueclientid, workGroupId: workGroup.id }));
    }
  };

  const fetchMaruClients = (searchString: string) => {
    const queryString = bauhubToString(searchString).replace(/\s/g, "+");
    return fetchClientsForMaruProject(projectId, queryString);
  };

  const [showWorkGroupTypeRemoveConfirmationModal, hideWorkGroupTypeRemoveConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideWorkGroupTypeRemoveConfirmationModal}
      header={<h2>{t("WORKGROUP.CONFIRM_TYPE_REMOVE.TITLE")}</h2>}
      body={<div>{t("WORKGROUP.CONFIRM_TYPE_REMOVE.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.OK")}
      handleDelete={() => {
        dispatch(saveChangeWorkGroupTypeAsync({ type: null, workGroupId: workGroup.id }));
        hideWorkGroupTypeRemoveConfirmationModal();
      }}
    />
  ));

  return (
    <div className="flex h-full w-full flex-col overflow-auto py-12 px-24">
      <div className="flex flex-row justify-start">
        <div className="w-3/5">
          <h2 className="pb-6">
            {!newWorkGroup && <FontAwesomeIcon icon={faGear} className="mr-3" />}
            {newWorkGroup ? t("WORKGROUP.NEW.NAME") : t("WORKGROUP.TAB.GENERAL")}
          </h2>
          <div className="w-3/5">
            {isBauhubProject && (
              <BhInputStackedLabel
                label={t("WORKGROUP.NEW.NAME")}
                initialValue={workGroup.name}
                property="name"
                onChangeCallback={renameWorkGroup}
                onBlurAction={renameWorkGroup}
                debounceLength={900}
              />
            )}
            {isMaruProject && (
              <BhInputWithDropdownWithFetchValuesOnChange
                label={t("WORKGROUP.NEW.NAME") as string}
                property={"clientname"}
                onSelect={selectMaruClient}
                initialValue={workGroup.name}
                fetchValues={fetchMaruClients}
              />
            )}
          </div>
          <p className="bh-text-deep-ocean-80 mt-6 mb-3">
            <span className="mr-2 font-bold">{t("WORKGROUP.NEW.TYPE")}</span>
            <i>({t("GLOBAL.NOT_MANDATORY")})</i>
          </p>

          <div className={classNames("flex flex-row items-center space-x-2")}>
            <BhTooltip body={disableTypeChangeAndDelete ? t("PARTY.TYPE.DISABLED.TOOLTIP") : t("WORKGROUP.NEW.TYPE")}>
              <BhChip isSelected={workGroup.type === WorkGroupType.SUBCONTRACTOR} onClick={() => setWorkGroupType(WorkGroupType.SUBCONTRACTOR)} disabled={disableTypeChangeAndDelete}>
                {t("PARTY.SUBCONTRACTOR")}
              </BhChip>
            </BhTooltip>
            <BhTooltip body={disableTypeChangeAndDelete ? t("PARTY.TYPE.DISABLED.TOOLTIP") : t("WORKGROUP.NEW.TYPE")}>
              <BhChip isSelected={workGroup.type === WorkGroupType.CLIENT} onClick={() => setWorkGroupType(WorkGroupType.CLIENT)} disabled={isCurrentUserMaruTeamMember || disableTypeChangeAndDelete}>
                {t("PARTY.CLIENT")}
              </BhChip>
            </BhTooltip>
          </div>
        </div>
      </div>
      {!newWorkGroup && (
        <div>
          <div className="my-8">
            <BhLightSeparator />
          </div>
          <div>
            <h2>{t("WORKGROUP.DELETE")}</h2>
            <div className="my-6">{t("WORKGROUP.DELETE.TEXT")}</div>
            <BhTooltip body={disableTypeChangeAndDelete ? t("PARTY.DELETE.DISABLED.TOOLTIP") : t("WORKGROUP.DELETE")}>
              <BhDeclineButton icon={faTrash} buttonProps={{ onClick: () => setDeleteConfirmationModalOpen(true), disabled: disableTypeChangeAndDelete }}>
                {t("WORKGROUP.DELETE")}
              </BhDeclineButton>
            </BhTooltip>
          </div>
        </div>
      )}
      {deleteConfirmationModalOpen && (
        <BhDeleteConfirmationModal
          isOpen={true}
          setIsOpen={setDeleteConfirmationModalOpen}
          header={<h2>{t("WORK_GROUP.REMOVE.HEADER")}</h2>}
          body={t("WORK_GROUP.REMOVE.BODY")}
          confirmationButtonText={t("GLOBAL.DELETE")}
          handleDelete={deleteWorkGroup}
        />
      )}
    </div>
  );
};

export default PartyOverviewContainer;
