import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchFilesAsync, selectAllXktFilesForParentFileEntityId } from "@/app/store/filesSlice";
import { EntityId } from "@reduxjs/toolkit";
// @ts-ignore
import { FastNavPlugin, SectionPlanesPlugin, StoreyViewsPlugin, Viewer, XKTLoaderPlugin } from "@xeokit/xeokit-sdk";
import React, { useEffect, useRef, useState } from "react";
import { IFileEntity } from "@/model/files/IFileEntity";
import { fetchBimManifests } from "@/api/fileAPI";
import BhIconButton from "@components/buttons/BhIconButton";
import { faListTree } from "@fortawesome/pro-regular-svg-icons/faListTree";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { faEraser } from "@fortawesome/pro-solid-svg-icons/faEraser";
import { faScissors } from "@fortawesome/pro-solid-svg-icons/faScissors";
import { useNavigate } from "react-router-dom";
import { selectBimProjectModule, selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import { defaultCameraPosition, firstCameraCfg } from "@/views/home/project/detail/xeokit/helpers/XeokitHelper";
import XeokitSectionTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitSectionTool";
import XeokitObjectSelectionTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitObjectSelectionTool";
import BhDropdown from "@components/dropdown/BhDropdown";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import XeokitSectionsDropdown from "@/views/home/project/detail/xeokit/xeokitDropdowns/XeokitSectionsDropdown";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import XeokitHideObjectTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitHideObjectTool";
import XeokitOrthoTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitOrthoTool";
import Xeokit2DTool from "@/views/home/project/detail/xeokit/xeokitTools/Xeokit2DTool";
import XeokitFitObjectsTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitFitObjectsTool";
import XeokitFirstPersonTool, { toggleFirstPersonMode } from "@/views/home/project/detail/xeokit/xeokitTools/XeokitFirstPersonTool";
import XeokitMeasureTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitMeasureTool";
import XeokitStoreyElevationTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitStoreyElevationTool";
import XeokitResetTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitResetTool";
import { classNames } from "@/utilities/jsUtilities";
import XeokitNavCubeTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitNavCubeTool";
import { useTranslation } from "react-i18next";
import XeokitNoFiles from "@/views/home/project/detail/xeokit/XeokitNoFiles";
import XeokitFilesConversionStatusBanner from "@/views/home/project/detail/xeokit/XeokitFilesConversionStatusBanner";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { faFolder } from "@fortawesome/pro-regular-svg-icons/faFolder";
import { selectHasBimrights } from "@/app/store/userSlice";
import XeokitEscButtonTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitEscButtonTool";
import { IManifestDTO } from "@/model/files/IManifestDTO";
import BhTooltip from "@components/BhTooltip";
import XeokitFloorPlanTool from "@/views/home/project/detail/xeokit/xeokitTools/XeokitFloorPlanTool";
import XeokitElementContextMenu from "@/views/home/project/detail/xeokit/menus/XeokitElementContextMenu";
import XeokitSidebarMenu from "./xeokitSidebar/XeokitSidebarMenu";
import XeokitFloorPlanMenuContainer from "@/views/home/project/detail/xeokit/menus/XeokitFloorPlanMenuContainer";

export interface LoadedObjectInfo {
  name: string;
  elevation?: number;
  id: string;
  visible: boolean;
  fileEntityId?: EntityId;
  loaded: boolean;
  rootMetaObjectId: string | number;
  manifest?: IManifestDTO;
}

interface Props {
  folderFileEntityId: EntityId;
  resetViewer: Function;
}

export enum BauhubXeokitTool {
  SELECT = "SELECT",
  SECTION = "SECTION",
  HIDE = "HIDE",
  MEASURE = "MEASURE"
}

const XeokitWebViewer = React.memo<Props>(({ folderFileEntityId, resetViewer }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const hasBimRights = useAppSelector((state) => selectHasBimrights(state, projectId));
  const bimModule = useAppSelector(selectBimProjectModule);
  const hasBimVisible = hasBimRights && bimModule;

  const [onModelsLoaded, setOnModelsLoaded] = useState<boolean>(false);
  const [onNewModelLoaded, setOnNewModelLoaded] = useState<number>(0);
  const [filesFetched, setFilesFetched] = useState(false);
  const [convertingFileIds, setConvertingFileIds] = useState<Array<EntityId>>([]);
  const xktFileEntitiesInFolder = useAppSelector((state) => selectAllXktFilesForParentFileEntityId(state, folderFileEntityId));
  const [treeVisible, setTreeVisible] = useLocalStorage<boolean>("bimTreeVisible", false);
  const [propertiesVisible, setPropertiesVisible] = useLocalStorage<boolean>("bimPropertiesVisible", false);
  const [hiddenBimModelFileEntityIds, setHiddenBimModelFileEntityIds] = useLocalStorage<{ [key: EntityId]: Array<EntityId> }>("hiddenBimModelFileEntityIds", {});

  const [loadedModels, setLoadedModels] = useState<Array<LoadedObjectInfo>>([]);
  const [loadedStoreyElevations, setLoadedStoreyElevations] = useState<Array<LoadedObjectInfo>>([]);

  const [bauhubToolActive, setBauhubToolActive] = useState<BauhubXeokitTool>(BauhubXeokitTool.SELECT);
  const [threeDActive, setThreeDActive] = useLocalStorage<boolean>("bimThreeDActive", true);
  const [orthoActive, setOrthoActive] = useLocalStorage<boolean>("bimOrthoActive", false);
  const [firstPersonActive, setFirstPersonActive] = useState(false);
  const [floorPlanActive, setFloorPlanActive] = useState(false);

  const [sectionToolProps, setSectionToolProps] = useState<{ sections: number }>({ sections: 0 });
  const [sectionPlanesPluginObject, setSectionPlanesPluginObject] = useState<SectionPlanesPlugin>();

  const [viewer, setViewer] = useState<Viewer>();
  const [storeyViewsPluginObject, setStoreyViewsPluginObject] = useState<StoreyViewsPlugin>();
  const [manifests, setManifests] = useState(Array<IManifestDTO>);
  const [viewerReady, setViewerReady] = useState(false);

  const isNotDestroyed = useRef(true);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const ios = /iphone|ipod|ipad/i.test(navigator.userAgent);
  const android = /Android/i.test(navigator.userAgent);
  const windows = /Windows/i.test(navigator.userAgent);
  const memoryConfiguration = {
    dtxEnabled: !android,
    maxGeometryBatchSize: ios || android ? 7000 : 5000000,
    scaleFactor: windows || android ? 1 : 2
  };

  useEffect(() => {
    /*let interval: string | number | NodeJS.Timeout | null | undefined = null;
    if (enableDebug) {
      interval = setInterval(() => {
        // @ts-ignore
        setCurrentStats({ frame: { fps: stats.frame.fps } });
      }, 1000);
    }*/

    dispatch(fetchFilesAsync(folderFileEntityId)).then((response: any) => {
      const convertingFiles = response.payload && response.payload.files && response.payload.files.filter((file: IFileEntity) => file.pendingConversion);
      if (convertingFiles.length > 0) {
        const fileIds = convertingFiles.map((file: IFileEntity) => file.id);
        setConvertingFileIds(fileIds);
      }
      setFilesFetched(true);
    });

    return () => {
      isNotDestroyed.current = false;
      viewer?.destroy();
      /*if (interval) {
        clearInterval(interval);
      }*/
    };
  }, []);

  useEffect(() => {
    if (filesFetched) {
      initialize(xktFileEntitiesInFolder);
    }
  }, [filesFetched]);

  const initialize = (filesWithXkt: Array<IFileEntity>) => {
    if (filesWithXkt.length === 0) {
      return;
    }

    const fileIds = filesWithXkt.map((file) => file.id);
    fetchBimManifests(fileIds).then((manifestDTOs) => {
      setManifests(manifestDTOs);
      loadViewer(manifestDTOs);
    });
  };

  // ------ TOGGLE SINGLE MODEL VISIBILITY ------

  const toggleSingleModelVisibility = (model: LoadedObjectInfo) => {
    if (!viewer) return;
    Object.entries(viewer.scene.models)
      .filter(([key, _]) => key.startsWith(model.fileEntityId + ""))
      .forEach(([_, value]) => (value.visible = !model.visible));

    if (model.fileEntityId) {
      const hiddenBimModels = {
        ...hiddenBimModelFileEntityIds,
        [projectId]: !model.visible
          ? (hiddenBimModelFileEntityIds[projectId] || []).filter((fileEntityId) => fileEntityId !== model.fileEntityId)
          : [...(hiddenBimModelFileEntityIds[projectId] || []), model.fileEntityId]
      };
      setHiddenBimModelFileEntityIds(hiddenBimModels);
    }
    const loadedModelsUpdated = loadedModels.map((loadedModel) => {
      if (loadedModel.fileEntityId === model.fileEntityId) {
        return { ...loadedModel, visible: !model.visible, loaded: true };
      }
      return loadedModel;
    });
    setLoadedModels(loadedModelsUpdated);
  };

  const loadModelIntoViewer = async (model: LoadedObjectInfo) => {
    if (!viewer) return;
    const xktLoader = new XKTLoaderPlugin(viewer);
    if (model.manifest) {
      await loadXktFiles(0, loadedModels, model.manifest, viewer, xktLoader, true);
      setOnNewModelLoaded(onNewModelLoaded + 1);
    }
  };

  const toggleSingleModelVisible = (model: LoadedObjectInfo) => {
    if (!model.loaded) {
      loadModelIntoViewer(model);
      return;
    }
    toggleSingleModelVisibility(model);
  };

  // ------ TOGGLE SINGLE MODEL VISIBILITY ------

  // ------ TOGGLE ALL MODELS VISIBLE ------

  const toggleAllModelsVisible = (visible: boolean) => {
    const modelsLoaded = loadedModels.filter((model) => model.loaded);
    const modelsNotLoaded = loadedModels.filter((model) => !model.loaded);
    if (modelsNotLoaded.length > 0) {
      if (!viewer) return;
      let promises = [] as any;
      const xktLoader = new XKTLoaderPlugin(viewer);
      modelsNotLoaded.forEach((model) => {
        if (model.manifest) {
          promises.push(loadXktFiles(0, loadedModels, model.manifest, viewer, xktLoader, false));
        }
      });
      Promise.all(promises).then(() => {
        toggleLoadedModels(loadedModels, visible);
      });
    } else {
      toggleLoadedModels(modelsLoaded, visible);
    }
  };

  const toggleLoadedModels = (models: Array<LoadedObjectInfo>, visible: boolean) => {
    if (!viewer) return;
    Object.entries(viewer.scene.models).forEach(([_, value]) => (value.visible = visible));
    const fileEntityIds = models.map((model) => model.fileEntityId) as Array<EntityId>;
    const hiddenBimModels = {
      ...hiddenBimModelFileEntityIds,
      [projectId]: visible ? [] : fileEntityIds || []
    };
    setHiddenBimModelFileEntityIds(hiddenBimModels);

    const loadedModelsUpdated = models.map((loadedModel) => {
      return { ...loadedModel, visible: visible, loaded: true };
    });
    setLoadedModels(loadedModelsUpdated);
  };

  // ------ TOGGLE ALL MODELS VISIBLE ------

  async function loadXktFiles(index = 0, modelsLoaded: Array<LoadedObjectInfo>, manifest: IManifestDTO, viewer: Viewer, xktLoader: XKTLoaderPlugin, toggleVisibility: boolean) {
    // All parts of this manifest file have been loaded
    if (index >= manifest.xktFiles.length) return;

    if (!isNotDestroyed.current) return;

    // @ts-ignore
    viewer.scene.canvas.spinner.processes++;

    // @ts-ignore
    const sceneModel = xktLoader.load({
      id: manifest.fileEntityId + "",
      manifest: { xktFiles: manifest.xktFiles.map((value) => value.url), metaModelFiles: manifest.metaDataUrls },
      reuseGeometries: true,
      globalizeObjectIds: true,
      excludeTypes: [],
      edges: true
    });

    await new Promise<void>((resolve) => {
      sceneModel.on("loaded", async function () {
        // Kui tahad mudeli osamudelite nimesid treeView asjale ette anda
        const metaModel = viewer.metaScene.metaModels[sceneModel.id];
        const fileEntity = xktFileEntitiesInFolder.find((f) => f.id === manifest.fileEntityId);
        const isVisible = !fileEntity || !hiddenBimModelFileEntityIds[projectId]?.find((id) => id === fileEntity.id);
        sceneModel.visible = isVisible;
        // @ts-ignore
        const rootObjectForModel = metaModel.rootMetaObjects.find((m) => m.type === "IfcProject");
        if (!rootObjectForModel) {
          console.log("No root object found with type IfcProject");
          // @ts-ignore

          viewer.scene.canvas.spinner.processes--;
          resolve();
          return;
        }
        const existingModel = modelsLoaded.find((m) => m.id === fileEntity?.id + "");
        if (existingModel) {
          // when setting a model visible when it has not been loaded
          // @ts-ignore
          existingModel.rootMetaObjectId = rootObjectForModel.id;
          if (toggleVisibility) {
            toggleSingleModelVisibility(existingModel);
          }
        } else {
          // @ts-ignore
          modelsLoaded.push({
            // @ts-ignore
            name: fileEntity?.name || rootObjectForModel.name,
            // @ts-ignore
            rootMetaObjectId: metaModel ? rootObjectForModel.id : fileEntity?.id + "",
            id: manifest.fileEntityId + "",
            visible: isVisible,
            fileEntityId: fileEntity?.id,
            loaded: true
          });
        }
        // @ts-ignore
        viewer.scene.canvas.spinner.processes--;
        resolve();
      });
    });
  }

  const loadViewer = async (manifests: Array<IManifestDTO>) => {
    const viewerConfig = {
      canvasId: "xeokitCanvas",
      antialias: true,
      spinnerElementId: "bauhubSpinnerElement",
      units: "millimeters",
      scale: 1000,
      dtxEnabled: memoryConfiguration.dtxEnabled
    };

    // @ts-ignore
    const viewer = new Viewer(viewerConfig);
    // @ts-ignore
    viewer.scene.canvas.resolutionScale = memoryConfiguration.scaleFactor;

    // @ts-ignore
    viewer.scene.canvas.spinner.processes++;
    setViewerReady(true);
    viewer.camera.eye = firstCameraCfg.eye;
    viewer.camera.look = firstCameraCfg.look;
    viewer.camera.up = firstCameraCfg.up;

    const xktLoader = new XKTLoaderPlugin(viewer, {
      maxGeometryBatchSize: memoryConfiguration.maxGeometryBatchSize
    });
    // We enable XKTLoaderPlugin's globalizeObjectIds mode, which automatically globalizes the ID of each
    // Entity loaded, in order to avoid Entity ID clashes between the two models.
    // TODO: see on katki uutes versioonides
    // xktLoader.globalizeObjectIds = true;

    const fastNavConfig = {
      hideEdges: true,
      hideSAO: true,
      hidePBR: false,
      hideTransparentObjects: false,
      scaleCanvasResolution: true,
      scaleCanvasResolutionFactor: 0.6,
      delayBeforeRestore: true,
      delayBeforeRestoreSeconds: 0.4,
      defaultScaleCanvasResolutionFactor: memoryConfiguration.scaleFactor
    };

    const fastNavPlugin = new FastNavPlugin(viewer, fastNavConfig);

    const storeyViewsPluginObject = new StoreyViewsPlugin(viewer, {});
    setStoreyViewsPluginObject(storeyViewsPluginObject);

    const onModelsLoaded = () => {
      setOnModelsLoaded(true);
      setOnNewModelLoaded(onNewModelLoaded + 1);

      // TODO: vii kaamera kuhugi

      const projection = orthoActive ? "ortho" : "perspective";
      defaultCameraPosition(viewer, projection);
      //------------------------------------------------------------------------------------------------------------------
      // Kaota ära spaces objektid alustuseks (lõikeid tehes need näevad veidrad välja
      //------------------------------------------------------------------------------------------------------------------

      const objectIds = viewer.metaScene.getObjectIDsByType("IfcSpace");
      viewer.scene.setObjectsCulled(objectIds, true);

      // @ts-ignore
      viewer.scene.canvas.spinner.processes--;
    };

    let modelsLoaded: Array<LoadedObjectInfo> = [];

    //------------------CREATE MODELS FOR HIDDEN FILES------------------
    const hiddenFiles = xktFileEntitiesInFolder.filter((file) => hiddenBimModelFileEntityIds[projectId] && hiddenBimModelFileEntityIds[projectId].some((fileId) => file.id === fileId));
    const hiddenFileManifests = manifests.filter((manifest) => hiddenFiles.some((file) => file.id === manifest.fileEntityId));
    const visibleFileManifests = manifests.filter((manifest) => !hiddenFileManifests.some((hiddenManifest) => manifest.fileEntityId === hiddenManifest.fileEntityId));

    if (hiddenFiles.length > 0) {
      hiddenFiles.forEach((fileEntity) => {
        const manifest = hiddenFileManifests.find((manifest) => manifest.fileEntityId === fileEntity.id);
        modelsLoaded.push({ name: fileEntity.name, id: fileEntity.id + "", rootMetaObjectId: "", visible: false, fileEntityId: fileEntity.id, loaded: false, manifest: manifest });
      });
    }

    //---------------CREATE MODELS AND LOAD VISIBLE FILES---------------

    for (const manifest of visibleFileManifests) {
      // Start loading
      await loadXktFiles(0, modelsLoaded, manifest, viewer, xktLoader, true);
    }
    onModelsLoaded();
    setLoadedModels(modelsLoaded);
    setOnNewModelLoaded(onNewModelLoaded + 1);

    setViewer(viewer);
  };

  const closeFloorplan = () => {
    if (!viewer) return;
    toggleFirstPersonMode(viewer, true, setFirstPersonActive);
    setFloorPlanActive(false);
  };

  if (!filesFetched) return null;

  return (
    <>
      <div>
        {manifests &&
          manifests
            .filter((m) => !hiddenBimModelFileEntityIds[projectId] || !hiddenBimModelFileEntityIds[projectId].includes(m.fileEntityId))
            .map((m) => <link key={m.metaDataUrl} rel="preload" as="fetch" type="application/octet-stream" crossOrigin="anonymous" href={m.metaDataUrl} />)}
        {manifests &&
          manifests
            .filter((m) => !hiddenBimModelFileEntityIds[projectId] || !hiddenBimModelFileEntityIds[projectId].includes(m.fileEntityId))
            .map(
              (m) =>
                // for preloading the files to speed up first load
                m.xktFiles && m.xktFiles.map((mf) => <link key={mf.url} rel="preload" as="fetch" type="application/octet-stream" crossOrigin="anonymous" href={mf.url} />)
            )}
      </div>
      {hasBimVisible && xktFileEntitiesInFolder && xktFileEntitiesInFolder.length > 0 && (
        <div className={classNames("flex h-full w-full flex-col")}>
          <div className="items-top min-h-12 flex flex-row items-center border-b py-1">
            <div className="flex flex-1 flex-row items-center justify-between">
              <div className="flex flex-row space-x-1 divide-x">
                <div className="flex flex-row items-center pl-1">
                  <Xeokit2DTool
                    toolActive={!threeDActive}
                    setToolActive={setThreeDActive}
                    firstPersonActive={firstPersonActive}
                    orthoActive={orthoActive}
                    closeFloorplan={closeFloorplan}
                    viewer={viewer}
                  />
                  <XeokitOrthoTool toolActive={orthoActive} setToolActive={setOrthoActive} closeFloorplan={closeFloorplan} disabled={!threeDActive} viewer={viewer} />
                  <XeokitFitObjectsTool viewer={viewer} />
                  <XeokitFirstPersonTool toolActive={firstPersonActive} setToolActive={setFirstPersonActive} threeDActive={threeDActive} viewer={viewer} />
                  {projectId && <XeokitFloorPlanTool toolActive={floorPlanActive} setToolActive={setFloorPlanActive} viewer={viewer} isMobile={false} disabled={!threeDActive || orthoActive} />}
                </div>
                <div className="flex flex-row items-center pl-1">
                  <BhTooltip body={t("BIM.TOOL.HIDE")}>
                    <BhIconButton
                      icon={faEraser}
                      buttonProps={{ onClick: () => setBauhubToolActive(bauhubToolActive === BauhubXeokitTool.HIDE ? BauhubXeokitTool.SELECT : BauhubXeokitTool.HIDE) }}
                      isActive={bauhubToolActive === BauhubXeokitTool.HIDE}
                    />
                  </BhTooltip>
                  {viewer && <XeokitHideObjectTool onModelsLoaded={onModelsLoaded} bauhubToolActive={bauhubToolActive} viewer={viewer} />}
                  <BhTooltip body={t("BIM.TOOL.SECTION")}>
                    <BhIconButton
                      icon={faScissors}
                      buttonProps={{ onClick: () => setBauhubToolActive(bauhubToolActive === BauhubXeokitTool.SECTION ? BauhubXeokitTool.SELECT : BauhubXeokitTool.SECTION) }}
                      isActive={bauhubToolActive === BauhubXeokitTool.SECTION}
                    />
                  </BhTooltip>
                  {viewer && sectionPlanesPluginObject && sectionToolProps.sections > 0 && (
                    <div className="flex inline-flex">
                      <BhDropdown
                        button={<BhTextOnlyButton>{t("BIM.SECTIONS") + " " + sectionToolProps.sections}</BhTextOnlyButton>}
                        menu={<XeokitSectionsDropdown sectionToolProps={sectionToolProps} setSectionToolProps={setSectionToolProps} viewer={viewer} sectionPlanes={sectionPlanesPluginObject} />}
                        position={BhDropdownPositionEnum.BOTTOM_LEFT}
                      />
                    </div>
                  )}
                  <XeokitMeasureTool onModelsLoaded={onModelsLoaded} toolActive={bauhubToolActive} setToolActive={setBauhubToolActive} viewer={viewer} />
                </div>
                <div className="pl-1">
                  <XeokitResetTool viewer={viewer} setBauhubToolActive={setBauhubToolActive} />
                </div>
              </div>
              <div className="justify-right flex flex-row items-center space-x-2 divide-x">
                <div>
                  <BhIconButton icon={faListTree} buttonProps={{ onClick: () => setTreeVisible(!treeVisible) }} isActive={treeVisible} />
                  <BhIconButton icon={faInfoCircle} buttonProps={{ onClick: () => setPropertiesVisible(!propertiesVisible) }} isActive={propertiesVisible} />
                </div>
                <div className="px-2">
                  <BhSecondaryButton icon={faFolder} buttonProps={{ onClick: () => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/bim/dir`) }}>
                    {t("BIM.FILES")}
                  </BhSecondaryButton>
                </div>
              </div>
            </div>
          </div>
          <div className="flex h-full w-full flex-row overflow-hidden">
            <div className="relative flex flex-1">
              {/* Floor Plan */}
              {viewer && projectId && (
                <XeokitFloorPlanMenuContainer
                  isOpen={floorPlanActive}
                  viewer={viewer}
                  projectId={projectId}
                  loadedStoreyElevations={loadedStoreyElevations}
                  isMobile={false}
                  storeyViewsPluginObject={storeyViewsPluginObject}
                  setFirstPersonActive={setFirstPersonActive}
                  setToolActive={setFloorPlanActive}
                />
              )}
              {/* Context menu for right click on object */}
              {viewer && <XeokitElementContextMenu viewer={viewer} onModelsLoaded={onModelsLoaded} setPropertiesVisible={setPropertiesVisible} setTreeVisible={setTreeVisible} />}
              {/* Functions and initalization for storey map and logic */}
              {viewer && <XeokitStoreyElevationTool viewer={viewer} onNewModelLoaded={onNewModelLoaded} setLoadedStoreyElevations={setLoadedStoreyElevations} />}
              {viewer && <XeokitObjectSelectionTool onModelsLoaded={onModelsLoaded} bauhubToolActive={bauhubToolActive} viewer={viewer} setPropertiesVisible={setPropertiesVisible} />}
              {viewer && (
                <XeokitSectionTool
                  onModelsLoaded={onModelsLoaded}
                  bauhubToolActive={bauhubToolActive}
                  setBauhubToolActive={setBauhubToolActive}
                  sectionToolProps={sectionToolProps}
                  setSectionToolProps={setSectionToolProps}
                  viewer={viewer}
                  sectionPlanesPluginObject={sectionPlanesPluginObject}
                  setSectionPlanesPluginObject={setSectionPlanesPluginObject}
                />
              )}
              {viewer && <XeokitEscButtonTool setBauhubToolActive={setBauhubToolActive} />}
              <canvas id="xeokitCanvas" className="bh-bg-3d-background absolute h-full w-full overflow-hidden"></canvas>
              {/*enableDebug && <div className="opacity-50 visible block h-0 w-0 text-sm">
                <pre>dtxEnabled: {!!memoryConfiguration.dtxEnabled ? 1 : 0}</pre>
                <pre>maxGeoBatchSize: {memoryConfiguration.maxGeometryBatchSize}</pre>
                <pre>fps: {currentStats.frame.fps}</pre>
              </div>*/}
              <div className={threeDActive ? "block" : "hidden"}>
                <XeokitNavCubeTool viewer={viewer} onModelsLoaded={onModelsLoaded} />
              </div>
              <div id="bauhubSpinnerElement" className="bh-bg-smoke flex h-full w-full flex-row items-center justify-center p-6 opacity-50">
                <div className="logo-loading-block-container h-10 w-10">
                  <div className="logo-loading-block logo-loading-static-block"></div>
                  <div className="logo-loading-block logo-loading-animated-block"></div>
                </div>
              </div>
              <XeokitFilesConversionStatusBanner projectId={projectId} fileIds={convertingFileIds} refreshModel={resetViewer} />
            </div>
            {viewerReady && viewer && (
              <XeokitSidebarMenu
                viewer={viewer}
                treeVisible={onModelsLoaded && treeVisible}
                setTreeVisible={setTreeVisible}
                propertiesVisible={onModelsLoaded && propertiesVisible}
                setPropertiesVisible={setPropertiesVisible}
                loadedModels={loadedModels}
                loadedStoreyElevations={loadedStoreyElevations}
                setLoadedStoreyElevations={setLoadedStoreyElevations}
                toggleModelVisible={toggleSingleModelVisible}
                toggleAllModelsVisible={toggleAllModelsVisible}
              />
            )}
          </div>
        </div>
      )}
      {(!hasBimVisible || !xktFileEntitiesInFolder || xktFileEntitiesInFolder.length === 0) && (
        <div className="relative h-full w-full">
          <XeokitNoFiles />
          {hasBimVisible && <XeokitFilesConversionStatusBanner projectId={projectId} fileIds={convertingFileIds} refreshModel={resetViewer} />}
        </div>
      )}
    </>
  );
});

export default XeokitWebViewer;
