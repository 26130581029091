import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveProjectBillingRequisitesAsync, selectCurrentProject } from "@/app/store/project/projectSlice";
import { CompanyInvoiceType, IBillingModel, IBillingRequisites, IProjectBillingRequisites, ProjectInvoiceType } from "@/model/IBillingInfo";
import { useTranslation } from "react-i18next";
import BhModalFooter from "@components/modal/BhModalFooter";
import BillingForm from "@/views/home/project/detail/projectSettings/BillingForm";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { EntityId } from "@reduxjs/toolkit";
import { CompanyStatus } from "@/model/ICompany";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { fetchBillingRequisites, getProjectActivationBillingModel } from "@/api/billingAPI";
import { isValidEmails } from "@/utilities/jsUtilities";
import { formatDate } from "@/utilities/dateUtility";

interface Props {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  companyId: EntityId;
}

const ProjectActivateModal: FC<Props> = ({ isShown, setIsShown, companyId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [billingRequisites, setBillingRequisites] = useState<IBillingRequisites>({} as IBillingRequisites);
  const [billingModel, setBillingModel] = useState<IBillingModel>({} as IBillingModel);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const project = useAppSelector(selectCurrentProject);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (company && project && isShown) {
      getProjectActivationBillingModel(company.id, project.id).then((billingModel) => {
        setBillingModel(billingModel);
      });
      fetchBillingRequisites(project.id).then((billingRequisites) => {
        setBillingRequisites(billingRequisites);
        setIsLoading(false);
      });
    }
  }, [isShown]);

  const onFormChange = useCallback((changedValue: IProjectBillingRequisites) => {
    setBillingRequisites((prevState) => {
      return { ...prevState, projectBillingRequisites: { ...prevState.projectBillingRequisites, ...changedValue } };
    });
  }, []);

  const companyHasMonthlyAggregateInvoice = billingRequisites.companyBillingRequisites?.invoiceType === CompanyInvoiceType.AGGREGATE;
  const hasConflictingInvoiceType = billingRequisites && billingRequisites.projectBillingRequisites?.type === ProjectInvoiceType.MONTH && companyHasMonthlyAggregateInvoice;

  const onActivate = () => {
    setIsShown(false);
    if (hasConflictingInvoiceType) {
      setBillingRequisites({
        ...billingRequisites,
        projectBillingRequisites: {
          ...billingRequisites.projectBillingRequisites,
          customerRecipientEmails: billingRequisites.companyBillingRequisites.customerRecipientEmails,
          customerRecipient: billingRequisites.companyBillingRequisites.customerRecipient,
          customerAddress: billingRequisites.companyBillingRequisites.customerAddress,
          customerRegCode: billingRequisites.companyBillingRequisites.customerRegCode,
          customerVatNumber: billingRequisites.companyBillingRequisites.customerVatNumber,
          customerAdditionalInfo: billingRequisites.companyBillingRequisites.customerAdditionalInfo
        }
      });
    }
    dispatch(saveProjectBillingRequisitesAsync(billingRequisites.projectBillingRequisites));
  };

  const isSaveButtonDisabled =
    !billingRequisites.projectBillingRequisites ||
    !billingRequisites.projectBillingRequisites.customerRecipientEmails ||
    billingRequisites.projectBillingRequisites.customerRecipientEmails.length === 0 ||
    !isValidEmails(billingRequisites.projectBillingRequisites.customerRecipientEmails) ||
    !billingRequisites.projectBillingRequisites.customerRecipient ||
    billingRequisites.projectBillingRequisites.customerRecipient.length === 0 ||
    (!billingRequisites.projectBillingRequisites.customerCivilian &&
      (!billingRequisites.projectBillingRequisites.customerRegCode || billingRequisites.projectBillingRequisites.customerRegCode.length === 0));

  const onCancel = () => {
    setIsShown(false);
    setBillingRequisites({} as IBillingRequisites);
  };

  if (!company) return null;

  return (
    <>
      {!isLoading && (
        <BhModal
          isShown={isShown}
          setIsShown={setIsShown}
          onClose={onCancel}
          size={"2xl"}
          header={<h2>{t("PROJECT.ACTIVATION.BILLING.MODAL.HEADER")}</h2>}
          children={
            <BhScrollableBody>
              <div className="px-8">
                <BillingForm
                  billingForm={billingRequisites.projectBillingRequisites}
                  companyBillingRequisites={billingRequisites.companyBillingRequisites}
                  onChange={onFormChange}
                  companyHasMonthlyAggregateInvoice={companyHasMonthlyAggregateInvoice}
                  companyHasTrial={company.status === CompanyStatus.TRIAL}
                  dontShowProjectBillingRequisitesType={company.status === CompanyStatus.TRIAL}
                  companyTrialEnd={formatDate(company.trialEnd)}
                  billingModel={billingModel}
                  projectName={project?.name}
                />
              </div>
            </BhScrollableBody>
          }
          footer={<BhModalFooter onCancel={onCancel} onConfirm={onActivate} confirmDisabled={isSaveButtonDisabled} confirmButtonText={t("PROJECT.ACTIVATE") as string} />}
        />
      )}
    </>
  );
};

export default ProjectActivateModal;
