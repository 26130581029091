import { useLocation, useNavigate } from "react-router-dom";
import React, { FC, useCallback, useEffect } from "react";

const OldUrlRedirect: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const oldUrlToNewUrlMap = new Map<string, string>([
    //TODO public allkirjastamise link
    ["/", ""],
    ["/login", "/"],
    ["/user", "/user"],
    ["/project", "/project"],
    ["/redirect", "/redirect"],
    ["/sync/home", "/sync/home"],
    ["/project/{id}", "/project/{id}"],
    ["/invite/{uuid}", "/invite/{uuid}"],
    ["/project/{id}/{sbId}", "/project/{id}/sharebox/{sbId}"],
    ["/project/{id}/log", "/project/{id}/log"],
    ["/project/{id}/mr/4r", "/project/{id}/mr/4r"],
    ["/project/{id}/sync", "/project/{id}/baudrive"],
    ["/project/{id}/budget", "/project/{id}/budget"],
    ["/project/{id}/dash", "/project/{id}/overview"],
    ["/project/{id}/error", "/project/{id}/overview"],
    ["/project/{id}/mr/4r/full", "/project/{id}/mr/4r"],
    ["/project/{id}/feedback", "/project/{id}/support"],
    ["/project/{id}/share", "/project/{id}/shareboxes"],
    ["/project/{id}/dir/{dirId}", "/project/{id}/dir/{dirId}"],
    ["/project/{id}/dir/root", "/project/{id}/overview"],
    ["/project/{id}/settings", "/project/{id}/settings"],
    ["/project/{id}/template", "/project/{id}/contracts"],
    ["/public/sharebox/{uuid}", "/public/sharebox/{uuid}"],
    ["/project/{id}/mr/budget", "/project/{id}/mr/budget"],
    ["/project/{id}/budget/v2", "/project/{id}/budget/v2"],
    ["/company/{companyId}", "/company/{companyId}/general"],
    ["/project/{id}/mr/reports", "/project/{id}/mr/reports"],
    ["/project/{id}/parties/{partyId}/contract/form/{formFileId}", "/project/{id}/contract/{partyId}/form/{formFileId}"],
    ["/project/{id}/parties/{partyId}/mr/contract/form/{formFileId}", "/project/{id}/contract/{partyId}/form/{formFileId}"],
    ["/project/{id}/parties/{partyId}/mr/contract/form", "/project/{id}/contract/{partyId}"],
    ["/project/{id}/parties/{partyId}/contract/form", "/project/{id}/contract/{partyId}"],
    ["/project/{id}/party/{partyId}", "/project/{id}/users/party/{partyId}/privileges"],
    ["/project/{id}/parties", "/project/{id}/users/parties"],
    ["/project/{id}/document/{dirId}", "/project/{id}/dir/{dirId}"],
    ["/project/{id}/document/root", "/project/{id}/overview"],
    ["/project/{id}/workgroup", "/project/{id}/users/parties"],
    ["/project/{id}/contacts", "/project/{id}/users/contacts"],
    ["/project/{id}/mr/budget/full", "/project/{id}/mr/budget"],
    ["/company/{companyId}/users", "/company/{companyId}/users"],
    ["/project/{id}/pdf/{fileId}", "/project/{id}/view/{fileId}"],
    ["/project/{id}/checklists", "/project/{id}/checklists/list"],
    ["/project/{id}/sign/invites", "/project/{id}/sign/personal"],
    ["/project/{id}/shareboxes", "/project/{id}/shareboxes/user"],
    ["/company/{companyId}/orders/invoices", "/company/{companyId}/billing"],
    ["/company/{companyId}/orders", "/company/{companyId}/billing"],
    ["/project/{id}/dir/{dirId}/form/{formFileId}", "/project/{id}/dir/{dirId}/form/{formFileId}"],
    ["/project/{id}/dir/{dirId}/form", "/project/{id}/dir/{dirId}"],
    ["/project/{id}/parties/{partyId}/contract/form/new", "/project/{id}/contract/{partyId}"],
    ["/project/{id}/workgroup/{workGroupId}", "/project/{id}/users/parties/{workGroupId}/privileges"],
    ["/project/{id}/workgroup/list", "/project/{id}/users/parties"],
    ["/project/{id}/task/{taskName}", "/project/{id}/task/{taskName}"],
    ["/project/{id}/notifications", "/project/{id}/user/notifications"],
    ["/project/{id}/mr/daily/reports", "/project/{id}/mr/daily/reports"],
    ["/project/{id}/template/{templateId}/edit", "/project/{id}/contracts"],
    ["/project/{id}/personal/task/{taskId}", "/project/{id}/task/{taskId}"],
    ["/company/{companyId}/orders/settings", "/company/{companyId}/billing"],
    ["/project/{id}/mr/daily/4r/reports", "/project/{id}/mr/daily/4r/reports"],
    ["/company/{companyId}/tree", "/company/{companyId}/project-tree-templates"],
    ["/project/{id}/mr/report/{reportId}", "/project/{id}/mr/report/{reportId}"],
    ["/project/{id}/file/confirmations", "/project/{id}/confirmations/personal"],
    ["/project/{id}/search?query&tab&tags", "/project/{id}/search?query&tab&tags"],
    ["/company/{companyId}/workgroups", "/company/{companyId}/work-group-templates"],
    ["/project/{id}/parties/{partyId}/contract", "/project/{id}/contract/{partyId}"],
    ["/project/{id}/mr/report/{reportId}/full", "/project/{id}/mr/report/{reportId}"],
    ["/project/{id}/parties/{partyId}/acts", "/project/{id}/contract/{partyId}/acts"],
    ["/sync/project/{projectId}/tree/picker", "/sync/{projectId}/picker"],
    ["/project/{id}/mr/4r/report/{reportId}", "/project/{id}/mr/4r/report/{reportId}"],
    ["/project/{id}/container/{containerId}", "/project/{id}/container/{containerId}"],
    ["/project/{id}/board/{taskBoardId}", "/project/{id}/taskboard/list/{taskBoardId}"],
    ["/project/{id}/board/{taskBoardId}/task/{taskId}", "/project/{id}/task/id/{taskId}"],
    ["/project/{id}/parties/{partyId}/mr/contract", "/project/{id}/contract/{partyId}"],
    ["/project/{id}/checklists/manage/{companyId}", "/project/{id}/checklists/settings"],
    ["/project/{id}/checklists/{checklistId}", "/project/{id}/checklists/{checklistId}"],
    ["/project/{id}/mr/4r/report/{reportId}/full", "/project/{id}/mr/4r/report/{reportId}"],
    ["/project/{id}/parties/{partyId}", "/project/{id}/users/parties/{partyId}/privileges"],
    ["/project/{id}/checklists/statistics/{companyId}", "/project/{id}/checklists/statistics"],
    ["/project/{id}/parties/{partyId}/mr/contract/form/new", "/project/{id}/contract/{partyId}"],
    ["/project/{id}/parties/{partyId}/act/{actId}", "/project/{id}/contract/{partyId}/act/{actId}"],
    ["/project/{id}/file/confirmation/{confirmationId}", "/project/{id}/confirmation/{confirmationId}"],
    ["/project/{id}/checklists/base/{checklistBaseId}", "/project/{id}/checklists/base/{checklistBaseId}"],
    ["/company/{companyId}/tree/template/{fileTreeTemplateId}/edit", "/company/{companyId}/project-tree-templates"],
    ["/project/{id}/parties/{partyId}/act/{actId}/accountant", "/project/{id}/contract/{partyId}/act/{actId}/accountant"],
    ["/public/sign/{containerUuid}/{signInviteUuid}/{projectId}", "/public/sign/{containerUuid}/{signInviteUuid}/{projectId}"],
    ["/company/{companyId}/workgroups/predefined/{predefinedWorkGroupId}/edit", "/company/{companyId}/work-group-templates"],
    ["/project/{id}/checklists/{checklistId}/round/{checklistRoundId}", "/project/{id}/checklists/{checklistId}/round/{checklistRoundId}"],
    ["/public/sign/{containerUuid}/{signInviteUuid}/{projectId}/file/{fileUuid}", "/public/sign/{containerUuid}/{signInviteUuid}/{projectId}"],
    ["/project/{id}/checklists/base/{checklistBaseId}/version/{checklistBaseVersionId}", "/project/{id}/checklists/base/{checklistBaseId}/version/{checklistBaseVersionId}"]
  ]);

  const bestMatchFromOldUrlToNewUrlMap = (url: string) => {
    const templateParts = url.split(/\//);
    let match = null;
    let maxMatches = 0;
    let maxTemplatePartsLength = 0;
    oldUrlToNewUrlMap.forEach((value: string, key: string) => {
      const urlTemplateParts = key.split(/\//);
      let countOfMatches = 0;
      urlTemplateParts.forEach((part, index) => {
        if (part === "") {
          return;
        }
        templateParts.forEach((templatePart, templateIndex) => {
          if (part === templatePart) {
            countOfMatches++;
          }
        });
      });
      if (countOfMatches === maxMatches) {
        if (urlTemplateParts.length > templateParts.length) {
          return;
        }

        if (urlTemplateParts.length > maxTemplatePartsLength) {
          match = [key, value];
          maxMatches = countOfMatches;
          maxTemplatePartsLength = urlTemplateParts.length;
        }
      }
      if (countOfMatches > maxMatches) {
        match = [key, value];
        maxMatches = countOfMatches;
        maxTemplatePartsLength = urlTemplateParts.length;
      }
    });
    return match;
  };

  const replaceUrlParameters = (template: string, str: string, newTemplate: string) => {
    const templateParts = template.split(/{|}/);
    const paramParts = str.split(/\?/);
    const extracted = {} as { [key: string]: string };
    let lastEndIndex = 0;
    for (let index = 1; index < templateParts.length; index += 2) {
      const possibleKey = templateParts[index];
      const keyPrefix = templateParts[index - 1];
      const nextPrefix = templateParts[index + 1];
      const substringStartIndex = str.indexOf(keyPrefix, lastEndIndex) + keyPrefix.length;
      const substringEndIndex = nextPrefix ? str.indexOf(nextPrefix, substringStartIndex) : str.length;
      lastEndIndex = substringEndIndex;
      extracted[possibleKey] = str.substring(substringStartIndex, substringEndIndex);
      newTemplate = newTemplate.replace(`{${possibleKey}}`, extracted[possibleKey]);
    }
    if (newTemplate.indexOf("//") > -1) {
      return "/";
    }
    return newTemplate;
  };

  useEffect(() => {
    if (location.hash.startsWith("#")) {
      doRedirect();
    }
  }, [location]);

  const doRedirect = useCallback(() => {
    if (location.hash.endsWith("#/")) {
      navigate("/");
      return;
    }

    // "/project/{id}/search?query&tab&tags"
    if (location.hash.substring(2).indexOf("search") > -1) {
      navigate(location.hash.substring(2));
      return;
    }

    // "/project/{id}/pdf/{fileId}" (oleks vaja saada UUID search parameetrist)
    if (location.hash.substring(2).indexOf("pdf") > -1 && location.hash.substring(2).indexOf("uuid") > -1) {
      const url = location.hash.substring(2);
      const uuid = url.split("?uuid=")[1];
      const newUrl = url.replace("pdf", "view").replace(`?uuid=${uuid}`, `/${uuid}`);
      navigate(newUrl);
      return;
    }

    const bestMatch = bestMatchFromOldUrlToNewUrlMap(location.hash.substring(2));
    if (bestMatch != null) {
      const newUrl = replaceUrlParameters(bestMatch[0], location.hash.substring(2), bestMatch[1]);
      navigate(newUrl);
    }
  }, [location]);

  return null;
};

export default OldUrlRedirect;
