import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { ButtonSize, IBhButtonProps } from "@components/buttons/IBhButtonProps";

const BhButton: FC<IBhButtonProps> = ({ buttonProps, children }) => {
  if (!buttonProps) {
    return null;
  }

  let primaryClasses = "rounded mx-1 my-0.5 px-3 lh-24 align-top pointer-events-auto";
  if (buttonProps.size && buttonProps.size === ButtonSize.XL) primaryClasses += " min-h-48px";

  return (
    <button
      onClick={(e) => buttonProps.onClick && buttonProps.onClick(e)}
      className={classNames(primaryClasses, buttonProps.classes)}
      disabled={buttonProps.disabled}
      type={buttonProps.submitForm ? "submit" : "button"}
      form={buttonProps.submitForm}
      ref={buttonProps.buttonRef}
    >
      {children && <span className="flex flex-row items-center justify-center">{children}</span>}
    </button>
  );
};

export default BhButton;
