import React, { FC, useRef } from "react";
import { IUserSigningInfo } from "@/model/container/IContainerCreateRequest";
import BhDeclineIconButton from "@components/buttons/BhDeclineIconButton";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import { IUser } from "@/model/IUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons/faGripDotsVertical";
import { useDrag, useDrop } from "react-dnd";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  signer: IUserSigningInfo;
  index: number;
  isQueueEnabled: boolean;
  removeSigner: Function;
  insertOldIndexToNewIndex: Function;
}

const NewContainerSignaturesListRow: FC<Props> = ({ signer, index, isQueueEnabled, removeSigner, insertOldIndexToNewIndex }) => {
  // DRAG AND DROP

  const [{ opacity, isDragging }, dragRef, preview] = useDrag({
    type: "signerRow",
    item: { oldIndex: index },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: monitor.isDragging()
    })
  });

  const [{ isOver }, dropRef] = useDrop({
    accept: "signerRow",
    drop: (item: { oldIndex: number }) => {
      if (!isDragging) {
        insertOldIndexToNewIndex(item.oldIndex, index);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  const ref = useRef(null);
  const previewDropRef = dragRef(dropRef(ref));

  return (
    <tr ref={previewDropRef as any} className={classNames(isOver && "bh-bg-mint-110", "bh-table-row h-13 bh-hover-container")} style={{ opacity }}>
      {isQueueEnabled && (
        <>
          <td ref={dragRef} className="cursor-move">
            <FontAwesomeIcon icon={faGripDotsVertical} />
          </td>
          <td className="text-center">{index + 1}</td>
        </>
      )}
      <td>{<BhUserIconWithName user={signer as object as IUser} />}</td>
      <td className="text-right">{<BhDeclineIconButton icon={faCircleMinus} buttonProps={{ onClick: () => removeSigner(signer) }} />}</td>
    </tr>
  );
};

export default NewContainerSignaturesListRow;
