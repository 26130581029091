import React, { FC } from "react";
import { IFileEntity } from "@/model/files/IFileEntity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/pro-solid-svg-icons/faFolder";
import ContactAuthoritiesDataSkeleton from "@/views/home/project/detail/users/contacts/contactRightsModal/ContactAuthoritiesDataSkeleton";

interface Props {
  isLoading: boolean;
  userFiles: Array<IFileEntity>;
  title: string;
}

const ContactAuthoritiesFileCard: FC<Props> = ({ isLoading, userFiles, title }) => {
  return (
    <div className="bh-bg-white mb-2 rounded p-6">
      <h3>{title}</h3>
      {isLoading ? (
        <ContactAuthoritiesDataSkeleton />
      ) : (
        userFiles.map((file: IFileEntity) => {
          return (
            <p key={file.id}>
              <span className="bh-text-pigeon mr-3 ">
                <FontAwesomeIcon icon={faFolder} />
              </span>
              {file.filePath}
            </p>
          );
        })
      )}
    </div>
  );
};

export default ContactAuthoritiesFileCard;
