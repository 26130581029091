import React, { FC } from "react";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { Trans } from "react-i18next";
import { ITask } from "@/model/taskBoard/ITask";
import BhTaskContainer from "@components/task/container/BhTaskContainer";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  tasks: Array<ITask>;
}

const OverviewTabsTasks: FC<Props> = ({ tasks }) => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const navigate = useNavigate();

  return (
    <>
      {tasks.length > 0 && (
        <div className="space-y-1 pb-4">
          {tasks
            .sort((a, b) => ((a.deadline ?? "9") > (b.deadline ?? "9") ? 1 : -1))
            .map((task) => {
              return <BhTaskContainer key={task.id} taskId={task.id} />;
            })}
        </div>
      )}
      {tasks.length > 1 && (
        <BhSecondaryButton buttonProps={{ onClick: () => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/taskboard/list/all`) }}>
          <Trans>DASHBOARD.INFO.SEE_ALL</Trans>
        </BhSecondaryButton>
      )}
      {tasks.length < 1 && (
        <BhNoMatchesImage>
          <Trans>OVERVIEW.NO_TASKS</Trans>
        </BhNoMatchesImage>
      )}
    </>
  );
};

export default OverviewTabsTasks;
