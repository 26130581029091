import React, { FC } from "react";
import FormFieldContainerInput from "@components/form/base/body/fields/FormFieldContainerInput";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  columns: Array<any>;
  removeEnabled?: boolean;
}

const FormWeatherFieldHeader: FC<Props> = ({ columns, removeEnabled }) => {
  return (
    <div className={classNames("mt-1 flex gap-2", removeEnabled && "pr-12")}>
      {columns.map((column: any, index: number) => (
        <FormFieldContainerInput
          disabled={true}
          key={index}
          property={""}
          initialValue={column.label}
          inputClasses={classNames("w-full", column.type === "CHECKBOX" ? "text-center min-w-20 w-20 max-w-20" : "max-w-half")}
        />
      ))}
    </div>
  );
};

export default FormWeatherFieldHeader;
