import React, { FC } from "react";
import DetailViewContainer from "@/views/home/project/detail/detailViewContainer/DetailViewContainer";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import ConfirmationsList from "@/views/home/project/detail/confirmations/list/ConfirmationsList";
import { Trans } from "react-i18next";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";

interface Props {}

const ConfirmationsListContainer: FC<Props> = () => {
  useDocumentTitle("GLOBAL.CONFIRMATIONS");

  return (
    <DetailViewContainer
      header={
        <DetailViewHeading>
          <Trans>GLOBAL.CONFIRMATIONS</Trans>
        </DetailViewHeading>
      }
    >
      <ConfirmationsList />
    </DetailViewContainer>
  );
};

export default ConfirmationsListContainer;
