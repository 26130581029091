import React, { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const BhHeadingBreadcrumbText: FC<Props> = ({ children }) => {
  return <span className="text-20px l-h-28px bh-text-deep-ocean-60 hover:bh-text-deep-ocean">{children}</span>;
};

export default React.memo(BhHeadingBreadcrumbText);
