import React, { FC, useEffect } from "react";
import SignInvitesFilterAndSort from "@/views/home/project/detail/signInvites/SignInvitesFilterAndSort";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useLocation } from "react-router-dom";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { fetchUserSignedInvitesAsync } from "@/app/store/signInvitesSlice";
import SignInvitesSignedList from "@/views/home/project/detail/signInvites/SignInvitesSignedList";

interface Props {}

const SignInvitesSigned: FC<Props> = () => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchUserSignedInvitesAsync(projectId));
  }, [location]);

  return (
    <BhScrollableBody header={<SignInvitesFilterAndSort useSignedSignInvitesSelector={true} />} keepScrollForKey="signInvitesSignedList">
      <SignInvitesSignedList />
    </BhScrollableBody>
  );
};

export default SignInvitesSigned;
