import React, { FC, useState } from "react";
import { IObjectChange } from "@/model/IObjectChange";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import BhTableHeader from "@components/table/BhTableHeader";
import BOCompanyProjectLogRow from "@components/backoffice/company/projects/BOCompanyProjectLogRow";
import { naturalSortByField } from "@/utilities/sortUtilities";

interface Props {
  logs: Array<IObjectChange>;
}

const BOCompanyProjectLogsTable: FC<Props> = ({ logs }) => {
  const [reversed, setReversed] = useState(false);
  const tableColumnHeaders: Array<IBhTableHeader<IObjectChange>> = [
    { id: 0, content: "Field" },
    { id: 1, content: "Old value" },
    { id: 2, content: "New value" },
    { id: 3, content: "Changed", sortable: true, field: "updated" },
    { id: 4, content: "User" }
  ];
  return (
    <div>
      <table className="w-full">
        <BhTableHeader columns={tableColumnHeaders} sortedBy="updated" reversed={reversed} onClickCallback={() => setReversed(!reversed)} />
        <tbody>
          {logs
            .sort((a, b) => naturalSortByField(a, b, "updated", reversed))
            .map((log) => (
              <BOCompanyProjectLogRow log={log} key={log.id} />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default BOCompanyProjectLogsTable;
