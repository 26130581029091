import React, { FC } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { faPen } from "@fortawesome/pro-regular-svg-icons/faPen";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { IDropdownItem } from "@/model/IDropdownItem";
import { useTranslation } from "react-i18next";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import BhTooltip from "@components/BhTooltip";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import { Menu } from "@headlessui/react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  removeCallback?: Function;
  renameCallback?: Function;
  handleDownload: Function;
}

const BhCommentAttachmentDropdown: FC<Props> = ({ renameCallback, removeCallback, handleDownload }) => {
  const { t } = useTranslation();

  const attachmentDropdownValues = [
    {
      text: t("GLOBAL.DOWNLOAD"),
      function: handleDownload,
      icon: faArrowDownToLine
    },
    renameCallback && {
      text: t("GLOBAL.RENAME"),
      function: () => {
        renameCallback();
      },
      icon: faPen
    },
    removeCallback && {
      text: t("GLOBAL.REMOVE"),
      function: () => {
        removeCallback();
      },
      icon: faTrash
    }
  ].filter(Boolean) as Array<IDropdownItem>;

  const showDropdown = attachmentDropdownValues.length > 1;

  return (
    <div className="absolute top-[9px] right-1 flex h-8 flex-row">
      {showDropdown && (
        <Menu as="div" className="relative inline" onClick={(e: any) => e.stopPropagation()}>
          <Menu.Button as="div">
            {({ open }) => <BhIconButton icon={faCaretDown} buttonProps={{ classes: classNames(!open && "bh-hover-item", "h-8 w-8 border bh-border-pigeon-50 bh-bg-white") }} />}
          </Menu.Button>
          <Menu.Items className="absolute right-1 z-30 mt-1 origin-top-right focus-visible:outline-0">
            <BhDropdownMenu values={attachmentDropdownValues} type={BhDropdownTypeEnum.STRING} textProperty="text" closeOnItemClick={true} />
          </Menu.Items>
        </Menu>
      )}
      {!showDropdown && (
        <BhTooltip body={attachmentDropdownValues[0].text}>
          <BhIconButton icon={attachmentDropdownValues[0].icon} buttonProps={{ onClick: attachmentDropdownValues[0].function, classes: "border bh-border-pigeon-50 bh-hover-item bh-bg-white" }} />
        </BhTooltip>
      )}
    </div>
  );
};

export default BhCommentAttachmentDropdown;
