import React, { FC } from "react";
import PartiesListItemTag from "@/views/home/project/detail/users/parties/PartiesListItemTag";
import BhUserIconGrouped from "@components/user/BhUserIconGrouped";
import { IWorkGroup } from "@/model/IWorkGroup";
import { IPredefinedWorkGroup } from "@/model/IPredefinedWorkGroup";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import BhLargeBoldText from "@components/text/BhLargeBoldText";
import { formatDateTime } from "@/utilities/dateUtility";
import { useTranslation } from "react-i18next";

interface Props {
  party: IWorkGroup | IPredefinedWorkGroup;
}

const PartiesListItemInfo: FC<Props> = ({ party }) => {
  const { t } = useTranslation();

  if (!party) {
    return null;
  }

  return (
    <div className="flex w-full">
      <div className="mx-6 mt-0.5 w-full flex-col">
        <BhLargeBoldText>{party.name}</BhLargeBoldText>
        <div className="mb-2 -mt-2 flex items-center gap-3">
          {party.type && <PartiesListItemTag workGroupType={party.type} />}
          <p>{t("GLOBAL.ORDER_BY.CREATED") + ": " + formatDateTime(party.created)}</p>
        </div>
      </div>
      <div className="flex">
        {party.users.length > 0 && (
          <div className="min-w-34 mr-6 flex items-center justify-end">
            <BhUserIconGrouped userGroup={party.users as Array<ISimpleValidUserAuthority>} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PartiesListItemInfo;
