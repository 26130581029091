import { EntityId } from "@reduxjs/toolkit";

export interface IAsyncJob {
  id: EntityId;
  projectId: EntityId;
  userEntityId: EntityId;
  uuid: string;
  progress: number;
  name: string;
  status: AsyncJobStatus;
  substatus: string;
  jobType: AsyncJobType;
  relatedResource: string;
  relatedResourceId: EntityId;
  jobStart: Date;
  jobEnd: Date;
  reason: string;
  stacktrace: string;
  deleted: boolean;
  canceled: boolean;
  node: string;
  data: any;
  // To save the request parameters that initialized the asyncJob
  requestDTO?: any;
}

export enum AsyncJobStatus {
  NEW = "NEW",
  EXECUTING = "EXECUTING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  CANCELED = "CANCELED"
}

export enum AsyncJobType {
  ZIP_IMPORT = "ZIP_IMPORT",
  ZIP_EXPORT = "ZIP_EXPORT",
  BDOC_GENERATION = "BDOC_GENERATION",
  ASICE_GENERATION = "ASICE_GENERATION",
  FORGE_UPLOAD = "FORGE_UPLOAD"
}
