import React, { FC, useRef, useState } from "react";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import BhInputSimple from "@components/input/BhInputSimple";
import { useOuterClick } from "@/utilities/hooks/useOuterClick";
import { sortByFullNameAndEmail } from "@/utilities/sortUtilities";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  values: Array<ISimpleValidUserAuthority>;
  onSelect: Function;
  showValueCondition: Function;
  placeholder?: string;
  trailingIcon?: IconProp;
}

const BhSearchInputWithUserDropdown: FC<Props> = ({ values, onSelect, showValueCondition, placeholder, trailingIcon }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const outsideClickRef = useOuterClick(() => {
    setIsDropdownOpen(false);
  });

  const onSelectDropdownValue = (selectedUser: ISimpleValidUserAuthority) => {
    onSelect && onSelect(selectedUser);
    setSearchQuery("");
    if (inputRef?.current) inputRef.current.value = "";
    setIsDropdownOpen(false);
  };

  const queryFilter = (value: ISimpleValidUserAuthority) => {
    const fullName = value?.firstName + " " + value?.lastName;
    const nameFilter = fullName.toLowerCase().replace(/\s+/g, "").includes(searchQuery.toLowerCase().replace(/\s+/g, ""));
    const userNameFilter = value.username?.toLowerCase().replace(/\s+/g, "").includes(searchQuery.toLowerCase().replace(/\s+/g, ""));
    return nameFilter || userNameFilter;
  };

  const filteredValues = values
    .filter((value) => {
      const isQueryFilter = searchQuery === "" ? true : queryFilter(value);
      const isShowValueCondition = showValueCondition ? showValueCondition(value) : true;
      return isQueryFilter && isShowValueCondition;
    })
    .sort((a, b) => sortByFullNameAndEmail(a, b));

  return (
    <div className="relative w-full" ref={outsideClickRef}>
      <BhInputSimple
        initialValue={searchQuery}
        property={"query"}
        placeholder={placeholder}
        onChangeCallback={(value: { query: string }) => setSearchQuery(value.query)}
        onFocus={() => setIsDropdownOpen(true)}
        inputRef={inputRef}
        trailingIcon={trailingIcon}
      />
      {isDropdownOpen && filteredValues.length > 0 && (
        <div className="absolute z-50 w-full">
          <BhDropdownMenu type={BhDropdownTypeEnum.USER} values={filteredValues} onSelect={onSelectDropdownValue} customDropdown={true} widthClass="w-full" />
        </div>
      )}
    </div>
  );
};

export default React.memo(BhSearchInputWithUserDropdown);
