import React, { FC } from "react";
import BhRadioButtonWithCheckmark from "@components/selectionControl/BhRadioButtonWithCheckmark";
import BhCardContainer from "@components/cards/BhCardContainer";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  children: React.ReactNode;
  trailingComponent?: React.ReactNode;
  value: any;
  isSelected: boolean;
  isAnySelected?: boolean;
  onSelect: Function;
  multiSelect?: boolean;
  size?: "sm" | "lg";
}

const BhSelectCard: FC<Props> = ({ children, trailingComponent, value, isSelected, isAnySelected, onSelect, multiSelect, size }) => {
  const handleSelect = (e: React.MouseEvent | React.KeyboardEvent) => {
    if ("code" in e) {
      (e.code === "Enter" || e.code === "Space") && onSelect(value);
    } else {
      onSelect(value);
    }
  };

  return (
    <BhCardContainer onClick={() => onSelect(value)} selected={isSelected} size={size}>
      <div className="ml-6 flex w-full items-center gap-6">
        {multiSelect && <input className="h-4 w-4 rounded" type="checkbox" checked={isSelected} onKeyDown={(e) => handleSelect(e)} readOnly={true} />}{" "}
        {!multiSelect && <BhRadioButtonWithCheckmark isSelected={isSelected} onSelect={(e: React.MouseEvent) => handleSelect(e)} />}
        <div className={classNames(!multiSelect && isAnySelected && !isSelected && "bh-text-deep-ocean-40")}>{children}</div>
      </div>
      {trailingComponent && <div className="mr-5 flex grow-0 items-center">{trailingComponent}</div>}
    </BhCardContainer>
  );
};

export default React.memo(BhSelectCard);
