import React, { FC, useCallback } from "react";
import { MetaObject, Viewer } from "@xeokit/xeokit-sdk";
import XeokitSidebarMenuPropertySet from "@/views/home/project/detail/xeokit/xeokitSidebar/XeokitSidebarMenuPropertySet";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { selectCurrentUserLanguageForTranslation } from "@/app/store/userSlice";

export interface IBimFavouriteProperty {
  propertySet: string;
  propertyName: string;
}

interface Props {
  objectProperties: MetaObject | undefined;
  viewer: Viewer;
}

const XeokitSidebarMenuObjectProperties: FC<Props> = ({ objectProperties, viewer }) => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguageForTranslation);
  const projectId = useAppSelector(selectCurrentProjectId);
  const [favouriteProperties, setFavouriteProperties] = useLocalStorage<{ [projectId: EntityId]: Array<IBimFavouriteProperty> }>("bimFavouriteProperties", {});

  const toggleFavouriteProperty = useCallback(
    (propertySet: string, propertyName: string) => {
      const projectFavourites = favouriteProperties[projectId] || [];
      const existingFavourite = projectFavourites.find((fav) => fav.propertySet === propertySet && fav.propertyName === propertyName);
      if (existingFavourite) {
        const favouritesWithoutExisting = projectFavourites.filter((favourite) => favourite !== existingFavourite);
        setFavouriteProperties({ ...favouriteProperties, [projectId]: favouritesWithoutExisting });
      } else {
        const favouritesWithNew = [...projectFavourites, { propertySet: propertySet, propertyName: propertyName }];
        setFavouriteProperties({ ...favouriteProperties, [projectId]: favouritesWithNew });
      }
    },
    [favouriteProperties, projectId]
  );

  const setAllTypeVisibility = useCallback(
    (visibility: boolean) => {
      if (!objectProperties) return;
      if (visibility) {
        viewer.scene.setObjectsVisible(viewer.scene.visibleObjectIds, false);
      }
      viewer.scene.setObjectsVisible(viewer.metaScene.getObjectIDsByType(objectProperties.type), visibility);
    },
    [objectProperties]
  );

  const setAllVisible = useCallback(() => {
    if (!objectProperties) return;
    viewer.scene.setObjectsVisible(viewer.scene.objectIds, true);
  }, [objectProperties]);

  if (!objectProperties) return null;

  return (
    <>
      <div className="pb-4">
        <div className="flex flex-row">
          <div className="w-10">
            <strong className="bh-text-pigeon">{t("BIM.OBJECT.PROPERTIES.NAME", { lng: currentUserLanguage })}</strong>
          </div>
          <div className="flex flex-1">{objectProperties.name}</div>
        </div>
        <div className="flex flex-row">
          <div className="w-10">
            <strong className="bh-text-pigeon">{t("BIM.OBJECT.PROPERTIES.CLASS", { lng: currentUserLanguage })}</strong>
          </div>
          <div className="flex flex-1">{objectProperties.type}</div>
        </div>
        <div className="flex flex-row">
          <div className="w-10"></div>
          <div className="flex flex-1">
            <div className="bh-text-pigeon flex flex-row gap-x-1.5">
              <a className="bh-underline-link bh-text-deep-ocean cursor-pointer font-bold" onClick={() => setAllTypeVisibility(true)}>
                {t("BIM.SIDEBAR.ISOLATE", { lng: currentUserLanguage })}
              </a>
              <a className="bh-underline-link bh-text-deep-ocean cursor-pointer font-bold" onClick={() => setAllTypeVisibility(false)}>
                {t("BIM.SIDEBAR.HIDE", { lng: currentUserLanguage })}
              </a>
              <a className="bh-underline-link bh-text-deep-ocean cursor-pointer font-bold" onClick={setAllVisible}>
                {t("BIM.SIDEBAR.SHOW_ALL", { lng: currentUserLanguage })}
              </a>
            </div>
          </div>
        </div>
      </div>
      {(!objectProperties.propertySets || objectProperties.propertySets.length === 0) && <div>{t("BIM.OBJECT.PROPERTIES.NO_PROPERTIES", { lng: currentUserLanguage })}</div>}
      {objectProperties.propertySets.length > 0 && (
        <div>
          {objectProperties.propertySets.map((propertySet) => (
            <XeokitSidebarMenuPropertySet
              key={propertySet.id}
              propertySet={propertySet}
              toggleFavourite={toggleFavouriteProperty}
              projectId={projectId}
              favouriteProperties={favouriteProperties[projectId] && favouriteProperties[projectId].filter((favourite) => favourite.propertySet === propertySet.name)}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default XeokitSidebarMenuObjectProperties;
