import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProject } from "@/app/store/project/projectSlice";
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import BhCompanyDropdown from "@components/dropdown/BhCompanyDropdown";
import BhCompanyLogo from "@/views/home/project/sidebar/BhCompanyLogo";

interface Props {
  collapsed: boolean;
}

const SidebarProjectName: FC<Props> = ({ collapsed }) => {
  const project = useAppSelector(selectCurrentProject);

  return (
    <BhCompanyDropdown collapsed={collapsed}>
      <div className="h-22 bh-border-pigeon-20 hover:bh-bg-pigeon-40 flex w-full items-center px-1">
        <BhCompanyLogo companyId={project.companyId} />
        {!collapsed && (
          <div className="flex flex-1 items-center overflow-hidden">
            <div className="three-line-overflow my-6 ml-3 flex flex-grow overflow-hidden text-left">{project.name}</div>
            <div className="mx-4 w-2 items-center justify-end">
              <FontAwesomeIcon icon={faCaretRight} className="bh-text-deep-ocean cursor-pointer" size="xs" aria-hidden="true" />
            </div>
          </div>
        )}
      </div>
    </BhCompanyDropdown>
  );
};

export default SidebarProjectName;
