import React, { FC } from "react";
import BhCardContainer from "@components/cards/BhCardContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderTree } from "@fortawesome/pro-regular-svg-icons/faFolderTree";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { useAppDispatch } from "@/app/hooks";
import { removeFileTreeTemplateAsync } from "@/app/store/companiesSlice";
import { IFileTreeTemplate } from "@/model/files/IFileTreeTemplate";
import BhLargeBoldText from "@components/text/BhLargeBoldText";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";

interface Props {
  template: IFileTreeTemplate;
  onClick: Function;
}

const ProjectTreeListCard: FC<Props> = ({ template, onClick }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteConfirmationModal}
      header={<h2>{t("COMPANY.SETTINGS.PROJECT_TREE.DELETE_TREE.HEADER")}</h2>}
      body={<div>{t("COMPANY.SETTINGS.PROJECT_TREE.DELETE_TREE.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={() => dispatch(removeFileTreeTemplateAsync(template))}
    />
  ));

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    showDeleteConfirmationModal();
  };

  return (
    <>
      {template && (
        <BhCardContainer onClick={onClick} classes="overflow-hidden sm:flex-col sm:items-start" size="lg">
          <div className="flex items-center px-7">
            <FontAwesomeIcon icon={faFolderTree} />
            <BhLargeBoldText classes="pl-6">{template.name}</BhLargeBoldText>
          </div>
          <BhTextOnlyButton
            icon={faTrash}
            buttonProps={{
              classes: "mx-4",
              onClick: handleDelete
            }}
          >
            {t("GLOBAL.DELETE")}
          </BhTextOnlyButton>
        </BhCardContainer>
      )}
    </>
  );
};

export default ProjectTreeListCard;
