import React, { Dispatch, FC, SetStateAction } from "react";
import BhModal from "@components/modal/BhModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/pro-solid-svg-icons/faWarning";
import { useTranslation } from "react-i18next";
import BhModalFooter from "@components/modal/BhModalFooter";

interface Props {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleConfirm: Function;
  handleCancel: Function;
}

const PartyTemplateChangeAssociatedFileTreeConfirmationModal: FC<Props> = ({ setIsOpen, handleConfirm, handleCancel }) => {
  const { t } = useTranslation();

  return (
    <BhModal
      isShown={true}
      setIsShown={setIsOpen}
      header={
        <div className="inline-flex items-center">
          <FontAwesomeIcon className="bh-text-warning-yellow mt-1 pr-5" icon={faWarning} />
          <h2>{t("COMPANY.SETTINGS.WORKGROUP.CHOOSE_FILE_TREE_TEMPLATE.REMOVE_FILE_TREE_TEMPLATE_RELATIONS.HEADER")}</h2>
        </div>
      }
      children={<div className="px-8 py-6">{t("COMPANY.SETTINGS.WORKGROUP.CHOOSE_FILE_TREE_TEMPLATE.REMOVE_FILE_TREE_TEMPLATE_RELATIONS.BODY")}</div>}
      footer={<BhModalFooter onConfirm={handleConfirm} onCancel={handleCancel} isWarningButton={true} />}
    />
  );
};

export default PartyTemplateChangeAssociatedFileTreeConfirmationModal;
