import React, { FC } from "react";
import { ChecklistBaseCategory } from "@/model/checklist/IChecklistBase";
import { useTranslation } from "react-i18next";
import BhNewTag from "@components/tags/BhNewTag";

interface Props {
  category: ChecklistBaseCategory;
  disabled?: boolean;
}

const ChecklistBaseCategoryTag: FC<Props> = ({ category, disabled }) => {
  const { t } = useTranslation();

  const categoryClassesMap: Record<ChecklistBaseCategory, { backgroundColorClass: string; textColorClass: string; categoryName: string; dropdownValue: string }> = {
    SAFETY: { backgroundColorClass: "bh-bg-mediterranean-10", textColorClass: "bh-text-mediterranean", categoryName: "SAFETY", dropdownValue: t("CHECKLIST.CATEGORY_TAG.SAFETY") },
    QUALITY: { backgroundColorClass: "bh-bg-lush-10", textColorClass: "bh-text-lush", categoryName: "QUALITY", dropdownValue: t("CHECKLIST.CATEGORY_TAG.QUALITY") },
    HANDOVERS: { backgroundColorClass: "bh-bg-eggplant-10", textColorClass: "bh-text-eggplant", categoryName: "HANDOVERS", dropdownValue: t("CHECKLIST.CATEGORY_TAG.HANDOVERS") },
    OTHER: { backgroundColorClass: "bh-bg-dull-grey-10", textColorClass: "bh-text-dull-grey", categoryName: "OTHER", dropdownValue: t("CHECKLIST.CATEGORY_TAG.OTHER") }
  };

  return (
    <BhNewTag
      tagText={t("CHECKLIST.CATEGORY_TAG." + category.toUpperCase())}
      backgroundColorClass={categoryClassesMap[category].backgroundColorClass}
      textColorClass={categoryClassesMap[category].textColorClass}
      disabled={disabled}
    />
  );
};

export default ChecklistBaseCategoryTag;
