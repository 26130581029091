import React, { FC, useEffect } from "react";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { selectFilteredAndSortedTaskIdsInSearch } from "@/app/store/searchSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import SearchResultsTaskContainer from "@/views/home/project/detail/search/taskResults/SearchResultsTaskContainer";
import SearchResultsTaskTabFilters from "@/views/home/project/detail/search/taskResults/SearchResultsTaskTabFilters";
import { fetchProjectTaskBoardsAsync } from "@/app/store/taskBoardsSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";

const SearchResultsTaskTab: FC = () => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const searchResultTaskIds = useAppSelector(selectFilteredAndSortedTaskIdsInSearch);
  const dispatch = useAppDispatch();

  useEffect(() => {
    searchResultTaskIds.length > 0 && dispatch(fetchProjectTaskBoardsAsync(projectId));
  }, []);

  if (!searchResultTaskIds) return null;

  return (
    <BhScrollableBody>
      <div className="flex flex-col">
        <SearchResultsTaskTabFilters />
        <div className="flex flex-col gap-0.5">
          {searchResultTaskIds.map((taskId) => (
            <SearchResultsTaskContainer key={taskId} taskId={taskId} />
          ))}
        </div>
      </div>
    </BhScrollableBody>
  );
};

export default SearchResultsTaskTab;
