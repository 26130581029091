import React, { ChangeEvent, FC, useId, useRef, useState } from "react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { useTranslation } from "react-i18next";
import { faArrowUpToLine } from "@fortawesome/pro-regular-svg-icons/faArrowUpToLine";
import { faFolderBlank } from "@fortawesome/pro-regular-svg-icons/faFolderBlank";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import BhDropFileContainer from "@components/upload/BhDropFileContainer";
import { IFileEntity } from "@/model/files/IFileEntity";
import SelectFilesModal from "@/views/home/project/projectModals/selectFilesModal/SelectFilesModal";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  onDropCallback: (files: Array<any>) => void;
  uploadFilesCallback: (event: ChangeEvent<HTMLInputElement>) => any;
  importModalCallback?: Function;
  addFilesCallback: (selectedFiles: Array<IFileEntity>) => any;
  fetchFormsCallback?: Function;
  disabled?: boolean;
  projectId?: EntityId;
}

const BhFormUploadContainer: FC<Props> = ({ disabled, uploadFilesCallback, onDropCallback, projectId, addFilesCallback, fetchFormsCallback, importModalCallback }) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const inputId = useId();
  const [selectFilesModalOpen, setSelectFilesModalOpen] = useState(false);

  return (
    <>
      <BhDropFileContainer onDrop={onDropCallback} disabled={disabled}>
        <div className="bh-bg-smoke bh-border-pigeon-50 flex flex-row items-center justify-center gap-x-3 rounded border border-dashed pt-4 pb-3">
          <BhTextOnlyButton
            icon={faArrowUpToLine}
            buttonProps={{
              onClick: () => {
                // @ts-ignore
                fileInputRef.current.click();
              },
              disabled: disabled
            }}
          >
            {t("GLOBAL.UPLOAD_FILES")}
          </BhTextOnlyButton>
          {projectId && (
            <BhTextOnlyButton
              icon={faFolderBlank}
              buttonProps={{
                onClick: () => setSelectFilesModalOpen(true),
                disabled: disabled
              }}
            >
              {t("FILEPICKER.PICK_FILE")}
            </BhTextOnlyButton>
          )}
          {importModalCallback && (
            <BhTextOnlyButton icon={faArrowDownToLine} buttonProps={{ disabled: disabled, onClick: importModalCallback }}>
              {t("FORM.BASE.ATTACHMENT.IMPORT")}
            </BhTextOnlyButton>
          )}
          {fetchFormsCallback && (
            <BhTextOnlyButton icon={faArrowDownToLine} buttonProps={{ disabled: disabled, onClick: fetchFormsCallback }}>
              {t("FORMS.BDW.IMPORT_DOCS")}
            </BhTextOnlyButton>
          )}
        </div>
      </BhDropFileContainer>
      <input type="file" id={inputId} ref={fileInputRef} style={{ display: "none" }} multiple={true} onChange={uploadFilesCallback} />
      {selectFilesModalOpen && <SelectFilesModal onSelect={addFilesCallback} setModalOpen={setSelectFilesModalOpen} multiple={true} />}
    </>
  );
};

export default BhFormUploadContainer;
