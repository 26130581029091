import React, { FC, useCallback } from "react";
import { IFileEntity } from "@/model/files/IFileEntity";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhSignatureContainerSignaturesDropdown from "@components/container/BhSignatureContainerSignaturesDropdown";
import BhStatusCellProgress, { BhStatusCellProgressColor } from "@components/status/BhStatusCellProgress";
import BhStatusCellSigned from "@components/status/BhStatusCellSigned";
import BhStatusCellDenied from "@components/status/BhStatusCellDenied";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";

interface Props {
  fileEntity: IFileEntity;
  dropdownPosition?: BhDropdownPositionEnum;
}

const BhSignatureContainerStatus: FC<Props> = ({ fileEntity, dropdownPosition }) => {
  const findElement = useCallback(() => {
    if (fileEntity.declinedInvitesCount > 0) {
      return <BhStatusCellDenied />;
    } else if (fileEntity.invitesCount > 0) {
      const invitesPlusSignatures = fileEntity.signaturesCount + fileEntity.invitesCount;
      return <BhStatusCellProgress progress={fileEntity.signaturesCount} total={invitesPlusSignatures} color={BhStatusCellProgressColor.BLUE} />;
    } else if (fileEntity.signaturesCount > 0) {
      return <BhStatusCellSigned />;
    }
    return <></>;
  }, [fileEntity.declinedInvitesCount, fileEntity.invitesCount, fileEntity.signaturesCount]);

  const element = <div className="cursor-pointer">{findElement()}</div>;
  return <BhDropdown position={dropdownPosition ? dropdownPosition : BhDropdownPositionEnum.BOTTOM_LEFT} button={element} menu={<BhSignatureContainerSignaturesDropdown fileEntity={fileEntity} />} />;
};

export default BhSignatureContainerStatus;
