import React, { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  selectShareboxesFilterAndSortInSearch,
  selectShareboxesReceiversFilterValuesInSearch,
  shareboxCreatedFilterSetInSearch,
  shareboxesReceiversFilterChangedInSearch,
  shareboxesReceiversFilterClearedInSearch,
  shareboxReceiversFilterToggledInSearch,
  shareboxSortBySetInSearch
} from "@/app/store/searchSlice";
import { IShareboxesFilter, IShareboxReceiversFilter } from "@/model/shareboxes/IShareboxesFilter";
import BhFilter from "@components/filters/BhFilter";
import BhFilterContainer from "@components/filters/BhFilterContainer";
import BhDatePicker from "@components/input/BhDatePicker";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import { IBhSort } from "@/model/IBhSort";
import { ISharebox } from "@/model/shareboxes/ISharebox";
import BhSortButton from "@components/sort/BhSortButton";

const SearchResultsShareboxFilterAndSort: FC = () => {
  const { t } = useTranslation();
  const [shareboxOrdering, setShareboxOrdering] = useLocalStorage<IBhSort<ISharebox>>("shareboxOrderingInSearch", {
    property: "name" as keyof ISharebox,
    reversed: false
  });
  const filterAndSort = useAppSelector(selectShareboxesFilterAndSortInSearch);
  const receiverFilterValues = useAppSelector(selectShareboxesReceiversFilterValuesInSearch);
  const dispatch = useAppDispatch();

  const { shareboxesFilter, shareboxesSort } = filterAndSort;

  const orderByDropdownValues = [
    { translation: `${t("GLOBAL.ORDER_BY.NAME")}`, value: "name" },
    { translation: `${t("SHAREBOX.SORT.VALID_TO")}`, value: "validTo" }
  ];

  const selectedFilterValuesCount = shareboxesFilter.shareboxReceivers.receiversDropdown.length;

  const onShareboxesReceiverFilterSelect = useCallback(
    (email: string) => {
      dispatch(shareboxReceiversFilterToggledInSearch(email));
    },
    [shareboxesFilter.shareboxReceivers]
  );

  const onShareboxesReceiverFilterSearchChange = useCallback(
    (changedValue: IShareboxReceiversFilter) => {
      dispatch(shareboxesReceiversFilterChangedInSearch(changedValue.receiversSearchKey));
    },
    [shareboxesFilter.shareboxReceivers]
  );

  const onShareboxesReceiverFilterReset = useCallback(() => {
    dispatch(shareboxesReceiversFilterClearedInSearch());
  }, [shareboxesFilter.shareboxReceivers]);

  const onShareboxCreatedFilterChange = useCallback(
    (changedValue: IShareboxesFilter) => {
      dispatch(shareboxCreatedFilterSetInSearch(changedValue));
    },
    [shareboxesFilter]
  );

  const isReceiverSelected = (email: string) => {
    return shareboxesFilter.shareboxReceivers.receiversDropdown.some((selectedEmail) => selectedEmail === email);
  };

  return (
    <BhFilterContainer>
      <BhSortButton
        sort={shareboxesSort}
        sortValues={orderByDropdownValues}
        localStorageSort={shareboxOrdering}
        setSortForLocalStorage={setShareboxOrdering}
        setSortForStore={shareboxSortBySetInSearch}
      />
      <BhFilter
        title={`${t("SHAREBOX.FILTER.SHARED_WITH")}`}
        values={receiverFilterValues}
        onSelect={onShareboxesReceiverFilterSelect}
        isSelected={isReceiverSelected}
        onReset={onShareboxesReceiverFilterReset}
        search={true}
        searchInputProperty="receiversSearchKey"
        searchInputSaveCallback={onShareboxesReceiverFilterSearchChange}
        closeOnItemClick={false}
        selectedValues={selectedFilterValuesCount}
        dropdownWidthClass="min-w-max"
      />
      <BhDatePicker
        initialValue={shareboxesFilter.createdFrom ? new Date(shareboxesFilter.createdFrom) : null}
        property={"createdFrom"}
        onChangeCallback={onShareboxCreatedFilterChange}
        classes={"w-full"}
        returnISOString={true}
        placeholder={t("LOG.SINCE") as string}
        onResetCallback={() => onShareboxCreatedFilterChange({ createdFrom: undefined } as IShareboxesFilter)}
      />
      <BhDatePicker
        initialValue={shareboxesFilter.createdTo ? new Date(shareboxesFilter.createdTo) : null}
        property={"createdTo"}
        onChangeCallback={onShareboxCreatedFilterChange}
        classes={"w-full"}
        returnISOString={true}
        placeholder={t("LOG.UNTIL") as string}
        onResetCallback={() => onShareboxCreatedFilterChange({ createdTo: undefined } as IShareboxesFilter)}
      />
    </BhFilterContainer>
  );
};

export default SearchResultsShareboxFilterAndSort;
