import React, { FC, ReactNode } from "react";
import { Transition } from "@headlessui/react";

interface Props {
  show?: boolean;
  children: ReactNode;
}

const BhToastTransition: FC<Props> = ({ show, children }) => {
  const showTransition = show !== undefined ? show : true;

  return (
    <Transition
      show={showTransition}
      appear={true}
      enter="transition ease-out duration-300"
      enterFrom="transform opacity-0 translate-y-2"
      enterTo="transform opacity-100"
      leave="transition ease-in duration-200"
      leaveFrom="transform opacity-100"
      leaveTo="transform opacity-0 translate-y-2"
    >
      {children}
    </Transition>
  );
};

export default BhToastTransition;
