import React, { FC } from "react";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { IFileEntity } from "@/model/files/IFileEntity";
import FileNameLink from "@/features/fileContainer/FileNameLink";
import { findWorkGroupIdByContractDirectoryFileEntityId } from "@/api/partyAPI";
import { useNavigate } from "react-router-dom";

interface Props {
  fileEntity: IFileEntity;
  disabled?: boolean;
}

const BhFileCardSmall: FC<Props> = ({ fileEntity, disabled }) => {
  const isContractDirectory = fileEntity.branch === "CONTRACT_DIRECTORY";
  const navigate = useNavigate();

  const onFolderClickCallback = isContractDirectory
    ? (fileEntity: IFileEntity) => {
        if (fileEntity.id && fileEntity.projectId) {
          findWorkGroupIdByContractDirectoryFileEntityId(fileEntity.projectId, fileEntity.id).then(function (result) {
            navigate(`/project/${fileEntity.projectId}/contract/${result.value}`);
          });
        }
      }
    : undefined;

  return (
    <div className="hover:bh-border-pigeon-60 bh-border-white flex h-6 w-full flex-row items-center overflow-hidden rounded border p-1.5">
      <BhFileCardIcon fileEntity={fileEntity} fileCardSize={FileCardSize.SMALL} />
      <span className="bh-text-deep-ocean ml-1.5 overflow-hidden text-ellipsis whitespace-nowrap">
        <FileNameLink fileEntity={fileEntity} disableOnClickHandler={disabled} onFolderClickCallback={onFolderClickCallback} />
      </span>
    </div>
  );
};

export default BhFileCardSmall;
