import React, { FC, useState } from "react";
import { classNames } from "@/utilities/jsUtilities";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { BauhubPlan } from "@/model/IProject";
import BhProPlanTag from "@components/tags/BhProPlanTag";
import BhLightPlanTag from "@components/tags/BhLightPlanTag";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons/faCircleInfo";
import moment from "moment";
import BoInvoicePaidField from "@components/backoffice/invoices/BOInvoicePaidField";
import BOInvoiceDropdown from "@components/backoffice/invoices/BOInvoiceDropdown";
import BhTableRow from "@components/table/BhTableRow";
import { ISalesInvoice } from "@/model/billing/ISalesInvoice";
import { IMonthlyInvoiceOverviewRow } from "@/model/invoices/IMonthlyInvoiceOverviewRow";
import { useOuterClick } from "@/utilities/hooks/useOuterClick";
import { formatCurrency } from "@/utilities/accountingUtilities";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  invoiceRow: IMonthlyInvoiceOverviewRow;
}

const BoSalesInvoiceRow: FC<Props> = ({ invoiceRow }) => {
  const { companyName, companyPlan, salesInvoice } = invoiceRow;
  const projectAvgPrice = salesInvoice.sumWithVat / salesInvoice.projectsOnInvoice;
  const [clientInfoPopUpOpen, setClientInfoPopUpOpen] = useState(false);
  const outsideClickRef = useOuterClick(() => {
    setClientInfoPopUpOpen(false);
  });
  const isInvoiceBillingDueDateOverdue = (salesInvoice: ISalesInvoice) => {
    const todaysDate = new Date();
    const invoiceBillingDueDate = new Date(salesInvoice.billingDueDate);
    return todaysDate > invoiceBillingDueDate;
  };

  const formatSum = (sum: number) => {
    return formatCurrency(sum, { symbol: "€", thousandsSeparator: " ", decimalSeparator: ",", significantDigits: 2 });
  };

  return (
    <BhTableRow
      key={salesInvoice.id}
      classes={classNames(salesInvoice.credited ? "bh-bg-smoke" : isInvoiceBillingDueDateOverdue(salesInvoice) && !salesInvoice.paid ? "bh-bg-error-red-10" : "bg-white")}
    >
      <td>
        <div className="py-2 pb-1.5">
          <a
            href={ConfigSingleton.getInstance().getConfig().REACT_APP_BASE_URL + "/bo/company/" + salesInvoice.companyId + "/general"}
            target="_blank"
            rel="noreferrer"
            className="underline-offset-2 hover:underline"
          >
            {companyName}
          </a>
          {salesInvoice.credited && (
            <BhTag type={BhTagType.TAG} classes="rounded">
              Credited
            </BhTag>
          )}
        </div>
      </td>
      <td>
        <div className="-ml-2 w-max">{companyPlan === BauhubPlan.PRO ? <BhProPlanTag /> : <BhLightPlanTag />}</div>
      </td>
      <td>
        <div ref={outsideClickRef} className="relative flex items-center justify-between gap-1">
          {salesInvoice.customerRecipient}
          <BhIconButton icon={faCircleInfo} buttonProps={{ onClick: () => setClientInfoPopUpOpen(!clientInfoPopUpOpen) }} />
          {clientInfoPopUpOpen && (
            <div className="bh-bg-white bh-shadow absolute top-10 right-1 z-30 flex flex-row items-center rounded p-2">
              <div className="ml-1">
                <div className="mx-1 w-max">
                  <p className="font-bold">
                    Email: <span className="font-normal">{salesInvoice.customerRecipientEmails}</span>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </td>
      <td>{moment(salesInvoice.invoiceDate).format("DD.MM.YY")}</td>
      <td>#{salesInvoice.fullInvoiceNumber}</td>
      <td className="text-right">{formatSum(salesInvoice.sumWithoutVat)}</td>
      <td>{formatSum(salesInvoice.sumWithVat)}</td>
      <td>{moment(salesInvoice.billingDueDate).format("DD.MM.YY")}</td>
      <td>{salesInvoice.type.slice(0, 1).toUpperCase() + salesInvoice.type.slice(1, salesInvoice.type.length).toLowerCase()}</td>
      <td>{salesInvoice.projectsOnInvoice}</td>
      <td>{formatSum(projectAvgPrice)}</td>
      <td>
        <BoInvoicePaidField salesInvoice={salesInvoice} />
      </td>
      <td>{salesInvoice.eInvoice ? "E-invoice" : "E-mail"}</td>
      <td>
        <div className="relative">
          <BOInvoiceDropdown invoiceRow={invoiceRow} />
        </div>
      </td>
    </BhTableRow>
  );
};

export default BoSalesInvoiceRow;
