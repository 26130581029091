import React, { FC, useEffect } from "react";
import DetailViewContainer from "@/views/home/project/detail/detailViewContainer/DetailViewContainer";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import BhTabs from "@/components/tabs/BhTabs";
import SearchResultsFileTab from "@/views/home/project/detail/search/fileResults/SearchResultsFileTab";
import SearchTabHeader from "@/views/home/project/detail/search/SearchTabHeader";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  fetchSearchForKeywordAsync,
  searchStateCleared,
  searchStringSet,
  selectFileResultsCountInSearch,
  selectSearchString,
  selectShareboxResultsCountInSearch,
  selectTaskResultsCountInSearch,
  selectUserResultsCountInSearch
} from "@/app/store/searchSlice";
import DirectoryFileBasketContainer from "@/views/home/project/detail/directory/DirectoryFileBasketContainer";
import SearchResultsUserTab from "@/views/home/project/detail/search/userResults/SearchResultsUserTab";
import SearchResultsTaskTab from "@/views/home/project/detail/search/taskResults/SearchResultsTaskTab";
import SearchResultsShareboxTab from "@/views/home/project/detail/search/shareboxResults/SearchResultsShareboxTab";
import { useLocation } from "react-router-dom";
import { urlParamsToObject } from "@/utilities/jsUtilities";
import { Trans, useTranslation } from "react-i18next";
import { setCurrentSidebarMenuItem } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";

const SearchResultsContainer: FC = () => {
  useDocumentTitle("GLOBAL.SEARCH");
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const fileResultsCount = useAppSelector(selectFileResultsCountInSearch);
  const userResultsCount = useAppSelector(selectUserResultsCountInSearch);
  const taskResultsCount = useAppSelector(selectTaskResultsCountInSearch);
  const shareboxResultsCount = useAppSelector(selectShareboxResultsCountInSearch);
  const searchString = useAppSelector(selectSearchString);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { query } = urlParamsToObject(location.search);

  useEffect(() => {
    return function cleanup() {
      dispatch(searchStateCleared());
    };
  }, []);

  useEffect(() => {
    if (query && query.length > 2) {
      dispatch(searchStringSet(query));
      dispatch(fetchSearchForKeywordAsync({ projectId, query }));
    }
  }, [query]);

  useEffect(() => {
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.SEARCH));
  }, [location]);

  const NoResults = () => (
    <p className="bh-text-deep-ocean-80 mt-16 text-center text-lg">
      <Trans>GLOBAL.NO_RESULTS</Trans>
    </p>
  );

  const searchTabs = [
    {
      id: 0,
      header: <SearchTabHeader title={t("SEARCH.FILES") as string} count={fileResultsCount > 499 ? "500+" : fileResultsCount} />,
      content: <>{fileResultsCount > 0 ? <SearchResultsFileTab /> : <NoResults />}</>,
      href: `files`
    },
    {
      id: 1,
      header: <SearchTabHeader title={t("SEARCH.USERS") as string} count={userResultsCount} />,
      content: <>{userResultsCount > 0 ? <SearchResultsUserTab /> : <NoResults />}</>,
      href: `users`
    },
    {
      id: 2,
      header: <SearchTabHeader title={t("SEARCH.TASKS") as string} count={taskResultsCount} />,
      content: <>{taskResultsCount > 0 ? <SearchResultsTaskTab /> : <NoResults />}</>,
      href: `tasks`
    },
    {
      id: 3,
      header: <SearchTabHeader title={t("SEARCH.SHAREBOXES") as string} count={shareboxResultsCount} />,
      content: <>{shareboxResultsCount > 0 ? <SearchResultsShareboxTab /> : <NoResults />}</>,
      href: `shareboxes`
    }
  ];

  return (
    <>
      <DetailViewContainer
        header={
          <DetailViewHeading>
            <span className="bh-text-pigeon">{t("SEARCH.RESULTS_FOR") as string}</span> "{searchString}"
          </DetailViewHeading>
        }
      >
        {/* @ts-ignore */}
        <BhTabs tabs={searchTabs} router={true} initialActiveTab={0} />
      </DetailViewContainer>
      {/*TODO privileegid hardcoded*/}
      <DirectoryFileBasketContainer privilegesForDirectory={{ isRead: true, isWrite: false, isAdmin: false }} currentDirectoryId={-1} />
    </>
  );
};

export default SearchResultsContainer;
