import React, { FC } from "react";
import BhTableRow from "@components/table/BhTableRow";
import { IMonthlyTrialOverviewRow } from "@/model/trials/IMonthlyTrialOverviewRow";
import { formatCurrency } from "@/utilities/accountingUtilities";
import BhIconButton from "@components/buttons/BhIconButton";
import BhTooltip from "@components/BhTooltip";
import BhBOCompanyPlanTag from "@components/tags/BhBOCompanyPlanTag";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHubspot } from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { formatDate } from "@/utilities/dateUtility";

interface Props {
  trial: IMonthlyTrialOverviewRow;
}

const BOMonthlyTrialOverviewTableRow: FC<Props> = ({ trial }) => {
  const formatSum = (sum: number) => {
    return formatCurrency(sum, { symbol: "€", thousandsSeparator: " ", decimalSeparator: ",", significantDigits: 2 }, true);
  };
  return (
    <BhTableRow>
      <td>
        <div className="flex items-center justify-between py-2 pb-1.5">
          <a className="cursor-pointer hover:underline" href={ConfigSingleton.getInstance().getConfig().REACT_APP_BASE_URL + "/company/" + trial.companyId + "/general"}>
            {trial.companyName}
          </a>
          <BhTooltip body={"Open in Hubspot"}>
            <BhIconButton>
              <FontAwesomeIcon icon={faHubspot as IconProp} size="lg" />
            </BhIconButton>
          </BhTooltip>
        </div>
      </td>
      <td>
        <div className="w-max">
          <BhBOCompanyPlanTag plan={trial.companyPlan} />
        </div>
      </td>
      <td>{formatDate(trial.companyTrialStart)}</td>
      <td>{formatDate(trial.companyTrialEnd)}</td>
      <td>{trial.projectCount}</td>
      <td>{trial.confirmedProjects}</td>
      <td className="text-right">{formatSum(trial.estimatedTurnover)}</td>
    </BhTableRow>
  );
};

export default BOMonthlyTrialOverviewTableRow;
