import React, { ChangeEvent, FC, useId } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { uploadFileRevisionAsync } from "@/app/store/uploadSlice";
import { IFileEntity } from "@/model/files/IFileEntity";

interface Props {
  fileInputRef: React.LegacyRef<HTMLInputElement>;
  revisionFile: IFileEntity;
}

const DirectoryFileRevisionUpload: FC<Props> = ({ fileInputRef, revisionFile }) => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const inputId = useId();
  const dispatch = useAppDispatch();

  const onFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;
    for (let i = 0; i < files.length; ++i) {
      const file = files[i];
      if (!revisionFile.parentFileEntityId) return;
      dispatch(uploadFileRevisionAsync({ file: file, projectId: projectId, revisionFile: revisionFile }));
    }
    event.target.value = "";
  };

  return <input type="file" id={inputId} ref={fileInputRef} style={{ display: "none" }} multiple={false} onChange={onFileSelect} />;
};

export default DirectoryFileRevisionUpload;
