import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { resetLightboxState, selectCurrentLightboxImage, selectLightboxCanRename, selectLightboxFileIds, selectLightboxModalOpen, setCurrentImage } from "@/app/store/lightboxSlice";
import { Transition } from "@headlessui/react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faArrowDownToLine, faChevronLeft, faChevronRight, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { selectAllImagesByParentId, selectImageFilesFromIds, setDirectoryModalsOpen, setFileEntityUrl, setRenameModalFileEntity } from "@/app/store/filesSlice";
import { formatFileSize } from "@/utilities/jsUtilities";
import BhButtonTemplateWithIcon from "@components/buttons/BhButtonTemplateWithIcon";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IFileEntity } from "@/model/files/IFileEntity";
import GalleryThumbnail from "@/features/gallery/GalleryThumbnail";
import { useKeyPress } from "@/utilities/hooks/useKeyPress";
import { formatDateTime } from "@/utilities/dateUtility";
import { useTranslation } from "react-i18next";
import BhIconButton from "@components/buttons/BhIconButton";
import { faPen } from "@fortawesome/pro-regular-svg-icons/faPen";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { setFileIdToRenameInForm } from "@/app/store/form/formSlice";
import { fetchUrlForFile } from "@/api/fileAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { fetchPublicShareboxFileUrl } from "@/api/shareboxAPI";

interface Props {
  isPublicSharebox?: boolean;
  shareboxUuid?: string;
}

const LightboxModal: FC<Props> = ({ isPublicSharebox, shareboxUuid }) => {
  const dispatch = useAppDispatch();
  const lightboxModalOpened = useAppSelector(selectLightboxModalOpen);
  const openedImage = useAppSelector(selectCurrentLightboxImage);
  const fileIds = useAppSelector(selectLightboxFileIds);
  const imageFiles = useAppSelector((state) => (fileIds ? selectImageFilesFromIds(state, fileIds) : openedImage && selectAllImagesByParentId(state, openedImage.parentFileEntityId))) as IFileEntity[];
  const canRename = useAppSelector(selectLightboxCanRename);
  const multipleImages = imageFiles && imageFiles.length > 1;
  const [imageUrl, setImageUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [urlMap, setUrlMap] = useState<Record<number, any>>({});
  const leftKeyPress = useKeyPress("ArrowLeft");
  const rightKeyPress = useKeyPress("ArrowRight");
  const escKeyPress = useKeyPress("Escape");
  const { t } = useTranslation();
  const location = useLocation();

  const openedFromForm = location.pathname.match("^\\/project\\/[0-9]+\\/dir\\/[0-9]+\\/form\\/[0-9]+$");
  const openedFromFiles = location.pathname.match("^\\/project\\/[0-9]+\\/dir\\/[0-9]+$");

  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    closeModal();
  };

  useEffect(() => {
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      dispatch(resetLightboxState());
      setTimeout(() => window.removeEventListener("popstate", onBackButtonEvent), 100);
    };
  }, []);

  useEffect(() => {
    if (openedImage) {
      const imageFileFromState = imageFiles.find((file) => file.id === openedImage.id);
      if (imageFileFromState && !_.isEqual(imageFileFromState, openedImage)) {
        dispatch(setCurrentImage(imageFileFromState));
      }
    }
  }, [imageFiles]);

  async function fetchFileUrl(compressed?: boolean) {
    if (openedImage && openedImage.uuid) {
      return await fetchUrlForFile(openedImage.id, false, false, openedImage.uuid, false, compressed);
    }
    return null;
  }

  async function fetchPublicFileUrl(compressed: boolean) {
    if (openedImage && openedImage.uuid && shareboxUuid) {
      return await fetchPublicShareboxFileUrl(shareboxUuid, openedImage.uuid, false, compressed);
    }
  }

  const handleUrlFetchResponse = (presignedUrl: { value: string } | { uuid: string; value: string } | undefined | null) => {
    setIsLoading(false);
    if (presignedUrl) {
      setImageUrl(presignedUrl.value);
      urlMap[openedImage!.id as number] = { url: presignedUrl.value, urlCreated: new Date().getTime() };
      setUrlMap(urlMap);
      dispatch(setFileEntityUrl({ id: openedImage!.id as number, url: presignedUrl.value }));
    }
  };

  function getValidUrl() {
    if (openedImage && openedImage.url && openedImage.urlCreated) {
      const now = new Date();
      const differenceInSeconds = (now.getTime() - openedImage.urlCreated) / 1000;
      if (differenceInSeconds < 19 * 60) {
        return openedImage.url;
      }
    }
    if (openedImage && urlMap[openedImage.id as number]) {
      const now = new Date();
      const differenceInSeconds = (now.getTime() - urlMap[openedImage.id as number].urlCreated) / 1000;
      if (differenceInSeconds < 19 * 60) {
        return urlMap[openedImage.id as number].url;
      }
    }
    return null;
  }

  useEffect(() => {
    const url = getValidUrl();
    if (openedImage && url) {
      setImageUrl(url);
      return;
    }
    setIsLoading(true);
    const fetchUrlPromise = isPublicSharebox ? fetchPublicFileUrl(true) : fetchFileUrl(true);
    fetchUrlPromise.then(
      (presignedUrl) => handleUrlFetchResponse(presignedUrl),
      () => {
        setIsLoading(false);
      }
    );
  }, [openedImage]);

  useEffect(() => {
    leftKeyPress && previousImage();
    rightKeyPress && nextImage();
    escKeyPress && closeModal();
  }, [leftKeyPress, rightKeyPress, escKeyPress]);

  const closeModal = () => {
    dispatch(resetLightboxState());
  };

  const nextImage = () => {
    if (imageFiles && openedImage && imageFiles.indexOf(imageFiles.find((f) => f.id === openedImage.id)!) + 1 === imageFiles.length) {
      dispatch(setCurrentImage(imageFiles[0]));
      return;
    }
    imageFiles && openedImage && dispatch(setCurrentImage(imageFiles[imageFiles.indexOf(imageFiles.find((f) => f.id === openedImage.id)!) + 1]));
  };

  const downloadImage = () => {
    fetchFileUrl(false).then((presignedUrl) => {
      if (presignedUrl) {
        setImageUrl(presignedUrl.value);
        urlMap[openedImage!.id as number] = { url: presignedUrl.value, urlCreated: new Date().getTime() };
        setUrlMap(urlMap);
        dispatch(setFileEntityUrl({ id: openedImage!.id as number, url: presignedUrl.value }));
        imitateUrlDownload(presignedUrl.value);
      }
    });
  };

  const previousImage = () => {
    if (imageFiles && openedImage && imageFiles.indexOf(imageFiles.find((f) => f.id === openedImage.id)!) === 0) {
      dispatch(setCurrentImage(imageFiles[imageFiles.length - 1]));
      return;
    }
    imageFiles && openedImage && dispatch(setCurrentImage(imageFiles[imageFiles.indexOf(imageFiles.find((f) => f.id === openedImage.id)!) - 1]));
  };

  const handleRenameClick = () => {
    if (!openedImage) return;

    // IF OPENED FROM FORM
    if (openedFromForm) {
      dispatch(setFileIdToRenameInForm(openedImage.id));
    }
    // IF OPENED FROM FORM
    if (openedFromFiles) {
      dispatch(setRenameModalFileEntity(openedImage));
      dispatch(setDirectoryModalsOpen({ modal: "renameModal", value: true }));
    }
  };

  if (!openedImage) {
    return null;
  }

  return (
    <Transition
      show={lightboxModalOpened}
      enter="transition ease duration-1000"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="bh-bg-light-black absolute left-0 top-0 z-30 flex h-screen w-screen flex-col justify-between overflow-hidden">
        <div className="bh-bg-light-black flex flex-row items-center justify-between pl-8 pr-10">
          <div className="flex flex-1 flex-col overflow-hidden pt-2">
            <div className="bh-text-smoke min-h-12 flex flex-row items-center gap-x-2 pb-2 font-bold">
              <div>{openedImage && openedImage.name}</div>
              {canRename && (
                <div data-theme="DARK">
                  <BhIconButton icon={faPen} isDark={true} buttonProps={{ onClick: handleRenameClick }} />
                </div>
              )}
            </div>
            <div className="bh-text-pigeon-40">
              {openedImage.created && openedImage.size && (
                <>
                  {t("LIGHTBOX.UPLOADED") + " " + formatDateTime(openedImage.created)} &nbsp;&nbsp;&nbsp;&nbsp; {t("FILE.SIZE").toLowerCase() + " " + formatFileSize(openedImage.size)}
                </>
              )}
            </div>
          </div>
          <div>
            <BhTextOnlyButton icon={faArrowDownToLine} buttonProps={{ inverted: true, onClick: () => downloadImage() }}>
              {t("GLOBAL.DOWNLOAD")}
            </BhTextOnlyButton>
            <BhTextOnlyButton icon={faXmark} buttonProps={{ inverted: true, onClick: () => closeModal() }}>
              {t("GLOBAL.CLOSE")}
            </BhTextOnlyButton>
          </div>
        </div>
        <div className="flex overflow-hidden py-10 px-10 md:px-2">
          <div className="flex h-full w-full flex-row items-center justify-center overflow-hidden">
            <div>
              {multipleImages && (
                <BhButtonTemplateWithIcon buttonProps={{ classes: "h-8 w-8 bh-text-mint-110 hover:bh-bg-deep-ocean-80", inverted: true, onClick: previousImage }} icon={faChevronLeft} />
              )}
            </div>
            <div className="flex h-full w-full items-center justify-center px-32 md:px-2">
              {isLoading && <FontAwesomeIcon icon={faSpinner} className="bh-text-bauhub-green w-96" spin aria-hidden="true" size="3x" />}
              {!isLoading && imageUrl && <img className="inline-block max-h-full w-auto" crossOrigin="" src={imageUrl} />}
            </div>
            <div>
              {multipleImages && <BhButtonTemplateWithIcon buttonProps={{ classes: "h-8 w-8 bh-text-mint-110 hover:bh-bg-deep-ocean-80", inverted: true, onClick: nextImage }} icon={faChevronRight} />}
            </div>
          </div>
        </div>
        <div className="flex shrink-0 flex-row overflow-x-auto pl-4 pb-8">
          {multipleImages && (
            <div className="m-auto flex flex-row">
              {imageFiles.map((file: IFileEntity) => {
                return (
                  <div className="m-auto mr-4" key={file.id}>
                    <GalleryThumbnail
                      fileEntity={file}
                      selected={openedImage.id === file.id}
                      onClickAction={() => {
                        dispatch(setCurrentImage(file));
                      }}
                      isPublicSharebox={isPublicSharebox}
                      shareboxUuid={shareboxUuid}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Transition>
  );
};

export default LightboxModal;
