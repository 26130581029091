import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CategoryDefaultCode } from "@/model/taskBoard/IProjectTaskCategory";
import { formatDate } from "@/utilities/dateUtility";

interface Props {
  field: string;
  valueField: string;
}

const TaskHistoryLogValue: FC<Props> = ({ field, valueField }) => {
  const { t } = useTranslation();

  if (!valueField) return null;

  const defaultCategories = [CategoryDefaultCode.RFI, CategoryDefaultCode.TASK, CategoryDefaultCode.ISSUE, CategoryDefaultCode.GUARANTEE, CategoryDefaultCode.SAFETY];
  const priorities: Record<any, string> = {
    5: "PRIORITY.CRITICAL",
    4: "PRIORITY.HIGH",
    3: "PRIORITY.MEDIUM",
    2: "PRIORITY.LOW",
    1: "PRIORITY.LOWEST"
  };

  if (field === "status") {
    return <span>{t("TASK." + valueField)}</span>;
  } else if (field === "priority") {
    return <span>{t(priorities[valueField])}</span>;
  } else if (field === "deadline") {
    return <span>{formatDate(new Date(valueField))}</span>;
  } else if (field === "locked") {
    return <span>{t("TASK.HISTORY.LOCKED." + valueField.toUpperCase())}</span>;
  } else if (field === "projectCategoryId") {
    return <span>{defaultCategories.includes(valueField.toUpperCase() as CategoryDefaultCode) ? t("TASK.CATEGORY." + valueField.toUpperCase()) : valueField}</span>;
  } else {
    return <span>{valueField}</span>;
  }
};

export default TaskHistoryLogValue;
