import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import BhRadioInput from "@components/input/BhRadioInput";

interface Props {
  option: any;
  callback: Function;
  singleChoice: boolean;
  disabled?: boolean;
}

const ChecklistOption: FC<Props> = ({ option, callback, singleChoice, disabled }) => {
  const onChange = (choice: any) => {
    const optionToSave = { ...option, ...choice };
    callback(optionToSave);
  };

  return (
    <div className={classNames(option.orderNumber !== 1 && "mt-1", "flex flex-row items-center")}>
      <div className="mr-4">
        {singleChoice && (
          <div className="p-1.25">
            <BhRadioInput checked={option.value} property={"value"} sizeClasses={"w-4.5 h-4.5"} onChange={onChange} disabled={disabled} />
          </div>
        )}
        {!singleChoice && <BhCheckbox isChecked={option.value} property={"value"} onChange={onChange} large={true} disabled={disabled} />}
      </div>
      <div className="bh-text-deep-ocean-80 text-14px leading-5">{option.name}</div>
    </div>
  );
};

export default ChecklistOption;
