import React, { FC } from "react";
import { Trans } from "react-i18next";

const SupportContactCardDark: FC = () => {
  return (
    <div className="bh-bg-deep-ocean bh-text-white flex w-1/2 flex-row items-center rounded p-4">
      <div className="text-24px pr-4 font-extrabold">Pro</div>
      <div className="child-bold-bh-text-bauhub-green">
        <Trans>SUPPORT.GET_PRO</Trans>
      </div>
    </div>
  );
};

export default SupportContactCardDark;
