import React, { FC, useState } from "react";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import BhTableRow from "@components/table/BhTableRow";
import BhSeparatorMarginless from "@components/separator/BhSeparatorMarginless";
import { IUserAuthority } from "@/model/IUserAuthority";

interface Props {
  administrators: Array<IUserAuthority>;
}

const AuthoritiesModalAdministratorsTab: FC<Props> = ({ administrators }) => {
  const [searchString, setSearchString] = useState<string>("");

  if (!administrators) return null;
  
  const filteredAndSortedAdmins = administrators
    .filter((admin) => {
      const nameFilter = admin.firstName.toLowerCase().includes(searchString) || admin.lastName.toLowerCase().includes(searchString);
      const usernameFilter = admin.username.toLowerCase().includes(searchString);
      return nameFilter || usernameFilter;
    })
    .sort((a, b) => (a.firstName > b.lastName ? 1 : -1));

  const renderHeaderRow = administrators.length > 0;

  return (
    <BhScrollableBody>
      <div className="h-22 flex items-center py-6">
        <BhSearchInputWBG property="query" inputClasses="w-72" onChangeCallback={(v: { query: string }) => setSearchString(v.query)} />
      </div>
      <div className="pb-8">
        {renderHeaderRow && <BhSeparatorMarginless />}
        <table className="mb-4 w-full">
          <tbody className="divide-y">
            {filteredAndSortedAdmins?.map((admin) => {
              return (
                <BhTableRow key={admin.userEntityId}>
                  <td>
                    <BhUserIconWithName user={admin} showUsername={true} />
                  </td>
                </BhTableRow>
              );
            })}
          </tbody>
        </table>
      </div>
    </BhScrollableBody>
  );
};

export default AuthoritiesModalAdministratorsTab;
