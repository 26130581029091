import { FC, useEffect, useState } from "react";
import { ContextMenu, MetaObject, Viewer } from "@xeokit/xeokit-sdk";
import { useTranslation } from "react-i18next";
import { BauhubXeokitTool } from "@/views/home/project/detail/xeokit/XeokitWebViewer";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUserLanguageForTranslation } from "@/app/store/userSlice";

interface Props {
  viewer: Viewer;
  isMobile?: boolean;
  bauhubToolActive?: BauhubXeokitTool;
  onModelsLoaded: boolean;
  setTreeVisible: Function;
  setPropertiesVisible: Function;
}

const XeokitElementContextMenu: FC<Props> = ({ viewer, isMobile, bauhubToolActive, onModelsLoaded, setTreeVisible, setPropertiesVisible }) => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguageForTranslation);
  const [objectContextMenuObject, setObjectContextMenuObject] = useState<ContextMenu>();

  useEffect(() => {
    if (onModelsLoaded) {
      //------------------------------------------------------------------------------------------------------------------
      // Objektile vajutamise context menu
      //------------------------------------------------------------------------------------------------------------------

      const firstSubmenu = [
        {
          title: t("BIM.CONTEXT_MENU.INFO", { lng: currentUserLanguage }),
          doAction: function (context: any) {
            if (!viewer) return;
            const metaObject = viewer.metaScene.metaObjects[context.entity.id];
            if (!metaObject) return;
            setPropertiesVisible(true);
            viewer.scene.setObjectsSelected(viewer.scene.objectIds, false);
            viewer.scene.setObjectsSelected([context.entity.id], true);
          }
        },
        {
          title: t("BIM.CONTEXT_MENU.FOCUS", { lng: currentUserLanguage }),
          doAction: function (context: any) {
            const viewer = context.viewer;
            const scene = viewer.scene;
            const entity = context.entity;
            viewer.cameraFlight.flyTo(
              {
                aabb: entity.aabb,
                duration: 0.5
              },
              () => {
                setTimeout(function () {
                  scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
                }, 500);
              }
            );
          }
        },
        isMobile
          ? {
              title: t("BIM.CONTEXT_MENU.CREATE_SLICE", { lng: currentUserLanguage }),
              doAction: function (context: any) {
                viewer.scene.fire("createSectionPlaneForEntityId", context.hit);
              }
            }
          : false
      ].filter(Boolean);

      const objectContextMenu = new ContextMenu({
        hideOnMouseDown: true,
        items: [
          firstSubmenu,
          [
            {
              title: t("BIM.CONTEXT_MENU.HIDE_OBJECT", { lng: currentUserLanguage }),
              getEnabled: (context: any) => {
                return context.entity.visible;
              },
              doAction: (context: any) => {
                context.entity.visible = false;
              }
            },
            {
              title: t("BIM.CONTEXT_MENU.HIDE_OTHER_OBJECTS", { lng: currentUserLanguage }),
              doAction: (context: any) => {
                const viewer = context.viewer;
                const scene = viewer.scene;
                const entity = context.entity;
                const metaObject = viewer.metaScene.metaObjects[entity.id];
                if (!metaObject) {
                  return;
                }
                scene.setObjectsVisible(scene.visibleObjectIds, false);
                metaObject.withMetaObjectsInSubtree((metaObject: MetaObject) => {
                  const entity = scene.objects[metaObject.id];
                  if (entity) {
                    entity.visible = true;
                  }
                });
              }
            },
            {
              title: t("BIM.CONTEXT_MENU.ISOLATE", { lng: currentUserLanguage }),
              getEnabled: (context: any) => {
                const entity = context.entity;
                const metaObject: MetaObject = context.viewer.metaScene.metaObjects[entity.id];
                return metaObject.type;
              },
              doAction: (context: any) => {
                const entity = context.entity;
                const metaObject: MetaObject = context.viewer.metaScene.metaObjects[entity.id];
                context.viewer.scene.setObjectsVisible(context.viewer.scene.visibleObjectIds, false);
                context.viewer.scene.setObjectsVisible(context.viewer.metaScene.getObjectIDsByType(metaObject.type), true);
              }
            },
            {
              title: t("BIM.CONTEXT_MENU.HIDE_TYPE", { lng: currentUserLanguage }),
              getEnabled: (context: any) => {
                const entity = context.entity;
                const metaObject: MetaObject = context.viewer.metaScene.metaObjects[entity.id];
                return metaObject.type;
              },
              doAction: (context: any) => {
                const entity = context.entity;
                const metaObject: MetaObject = context.viewer.metaScene.metaObjects[entity.id];
                context.viewer.scene.setObjectsVisible(context.viewer.metaScene.getObjectIDsByType(metaObject.type), false);
              }
            },
            {
              title: t("BIM.CONTEXT_MENU.SHOW_ALL_OBJECTS", { lng: currentUserLanguage }),
              getEnabled: (context: any) => {
                const scene = context.viewer.scene;
                return scene.numVisibleObjects < scene.numObjects || context.viewer.scene.numXRayedObjects > 0;
              },
              doAction: (context: any) => {
                const scene = context.viewer.scene;
                scene.setObjectsVisible(scene.objectIds, true);
                scene.setObjectsPickable(scene.xrayedObjectIds, true);
                scene.setObjectsXRayed(scene.xrayedObjectIds, false);
              }
            }
          ],
          [
            {
              title: t("BIM.CONTEXT_MENU.DESELECT_ALL", { lng: currentUserLanguage }),
              getEnabled: function (context: any) {
                return context.viewer.scene.numSelectedObjects > 0;
              },
              doAction: function (context: any) {
                context.viewer.scene.setObjectsSelected(context.viewer.scene.selectedObjectIds, false);
              }
            }
          ]
        ],
        enabled: true
      });

      if (!isMobile) {
        viewer.cameraControl.on("rightClick", function (e) {
          // @ts-ignore
          if (window.measurementsContextMenuOpen) {
            // @ts-ignore
            window.measurementsContextMenuOpen = false;
            return;
          }
          const hit = viewer.scene.pick({
            canvasPos: e.canvasPos
          });

          if (hit && hit.entity && hit.entity.isObject) {
            objectContextMenu.context = {
              viewer: viewer,
              // treeViewPlugin: treeView,
              entity: hit.entity
            };

            objectContextMenu.show(e.event.pageX, e.event.pageY);
          }
          e.event.preventDefault();
        });
      }

      setObjectContextMenuObject(objectContextMenu);
    }
  }, [onModelsLoaded, currentUserLanguage]);

  useEffect(() => {
    if (!bauhubToolActive) return;
    if (!isMobile) return;
    if (!objectContextMenuObject) return;

    const id = viewer.cameraControl.on("picked", function (hit) {
      if (bauhubToolActive !== BauhubXeokitTool.SELECT) return;
      if (hit && hit.entity && hit.entity.isObject) {
        if (viewer.scene.selectedObjectIds.length === 0) {
          return;
        }

        objectContextMenuObject.context = {
          viewer: viewer,
          // treeViewPlugin: treeView,
          entity: hit.entity,
          hit: hit
        };
        objectContextMenuObject.show(hit.canvasPos[0], hit.canvasPos[1]);
      }
    });

    return function destroy() {
      viewer.cameraControl.off(id);
    };
  }, [bauhubToolActive, objectContextMenuObject]);

  useEffect(() => {
    return function destroy() {
      if (!objectContextMenuObject) return;
      objectContextMenuObject.destroy();
    };
  }, [objectContextMenuObject]);

  return null;
};

export default XeokitElementContextMenu;
