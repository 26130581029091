import React, { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { clearWorkGroupTypeFilter, selectCurrentProjectWorkGroupsFilter, setWorkGroupFilter, toggleWorkGroupTypeFilter } from "@/app/store/project/projectWorkGroupsSlice";
import { IWorkGroupFilter } from "@/model/IWorkGroupFilter";
import BhFilterContainer from "@components/filters/BhFilterContainer";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhFilter from "@components/filters/BhFilter";
import { useTranslation } from "react-i18next";

interface Props {}

const ContactsFilter: FC<Props> = () => {
  const { t } = useTranslation();
  const filter = useAppSelector(selectCurrentProjectWorkGroupsFilter);
  const dispatch = useAppDispatch();

  const onWorkGroupNameFilterChange = useCallback(
    (changedValue: IWorkGroupFilter) => {
      const changedObject = { ...filter, ...changedValue };
      dispatch(setWorkGroupFilter(changedObject));
    },
    [filter]
  );

  const onWorkGroupTypeFilterSelect = useCallback(
    (type: any) => {
      dispatch(toggleWorkGroupTypeFilter(type));
    },
    [filter.typeDropdownValues]
  );

  const onWorkGroupTypeFilterReset = useCallback(() => {
    dispatch(clearWorkGroupTypeFilter());
  }, []);

  const numOfSelectedWorkGroupTypesInFilter = filter.typeDropdownValues.filter((value: { selected: boolean }) => value.selected).length;

  return (
    <BhFilterContainer>
      <div>
        <BhSearchInputWBG initialValue={filter.name} property="name" onChangeCallback={onWorkGroupNameFilterChange} placeholder={t("WORKGROUP.SEARCH.PLACEHOLDER") as string} />
      </div>
      <BhFilter
        title={t("WORKGROUP.TYPE")}
        values={filter.typeDropdownValues}
        textProperty="translation"
        onSelect={onWorkGroupTypeFilterSelect}
        isSelected={(value) => value.selected}
        onReset={onWorkGroupTypeFilterReset}
        selectedValues={numOfSelectedWorkGroupTypesInFilter}
        translateValues={true}
      />
    </BhFilterContainer>
  );
};

export default ContactsFilter;
