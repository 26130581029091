import React, { FC, SetStateAction, useState } from "react";
import { IPredefinedWorkGroup } from "@/model/IPredefinedWorkGroup";
import { Authority, IPredefinedWorkGroupUser } from "@/model/IUserAuthority";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhTableHeader from "@components/table/BhTableHeader";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import PartyTemplateUserListItem from "@/views/home/company/detail/companySettingsPartyTemplates/PartyTemplateUserListItem";
import PartyTemplateAddNewUserModalContent from "@/views/home/company/detail/companySettingsPartyTemplates/PartyTemplateAddNewUserModalContent";
import { isValidEmail } from "@/utilities/jsUtilities";

interface Props {
  newPartyForm: IPredefinedWorkGroup;
  setNewPartyForm: React.Dispatch<SetStateAction<IPredefinedWorkGroup>>;
}

const CreateNewPartyTemplateUsersStep: FC<Props> = ({ newPartyForm, setNewPartyForm }) => {
  const { t } = useTranslation();
  const [userToAdd, setUserToAdd] = useState<IPredefinedWorkGroupUser>({
    username: "",
    filesAuthLevel: Authority.NONE,
    documentsAuthLevel: Authority.NONE,
    actsAuthLevel: Authority.NONE,
    contractAuthLevel: Authority.NONE
  } as IPredefinedWorkGroupUser);

  const userAlreadyAdded = newPartyForm.users?.some((user) => user.username === userToAdd.username);

  const confirmNewUserRoles = () => {
    if (!userAlreadyAdded) {
      setNewPartyForm({ ...newPartyForm, users: [...(newPartyForm.users || []), userToAdd] });
    }
    setUserToAdd({
      username: "",
      filesAuthLevel: Authority.NONE,
      documentsAuthLevel: Authority.NONE,
      actsAuthLevel: Authority.NONE,
      contractAuthLevel: Authority.NONE
    } as IPredefinedWorkGroupUser);
  };

  const onUserPrivilegeChange = (userId: EntityId, changedAuthority: IPredefinedWorkGroupUser) => {
    const updatedUsers = newPartyForm.users.map((user) => {
      return user.id === userId ? { ...user, ...changedAuthority } : user;
    });
    setNewPartyForm({ ...newPartyForm, users: updatedUsers });
  };

  const dropdownMenuValues = [
    { value: Authority.NONE, translation: t("PARTY.NONE") },
    { value: Authority.READ, translation: t("PARTY.READ") },
    { value: Authority.WRITE, translation: t("PARTY.WRITE") },
    { value: Authority.ADMIN, translation: t("PARTY.ADMIN") }
  ];

  const tableColumnHeaders = [
    { id: 0, content: t("GLOBAL.USER"), field: "", classes: "w-64", sortable: false },
    { id: 1, content: t("GLOBAL.DRAWINGS"), field: "filesAuthLevel", classes: "w-32", sortable: false },
    { id: 2, content: t("GLOBAL.DOCUMENTS"), field: "documentsAuthLevel", classes: "w-32", sortable: false },
    { id: 3, content: t("COMPANY.SETTINGS.ACT"), field: "actsAuthLevel", classes: "w-32", sortable: false },
    { id: 4, content: t("COMPANY.SETTINGS.CONTRACT"), field: "contractsAuthLevel", classes: "w-32", sortable: false },
    { id: 5, content: "", field: "", classes: "w-8", sortable: false }
  ];

  const removeUser = (username: string) => {
    const updatedUsers = newPartyForm?.users?.filter((user) => user.username !== username);
    setNewPartyForm({ ...newPartyForm, users: updatedUsers });
  };

  const hasAddedUsers = newPartyForm?.users?.length > 0;

  return (
    <div className="w-full px-24 py-12">
      <h2 className="bh-text-deep-ocean mb-6">{t("WORKGROUP.ADD_USER")}</h2>
      <PartyTemplateAddNewUserModalContent
        userToAdd={userToAdd}
        setUserToAdd={setUserToAdd}
        userDoesNotExist={false}
        partyAlreadyAdded={userAlreadyAdded}
        companyId={newPartyForm.companyId}
        predefinedPartyId={-1}
      />
      <div className="mt-6 mb-4">
        <div className="mt-5 flex gap-4">
          <BhPrimaryButton buttonProps={{ onClick: () => confirmNewUserRoles(), disabled: !isValidEmail(userToAdd.username) || userAlreadyAdded }}>{t("WORKGROUP.NEW.CONFIRM_USERS")}</BhPrimaryButton>
          <BhTextOnlyButton
            buttonProps={{
              onClick: () =>
                setUserToAdd({
                  username: "",
                  filesAuthLevel: Authority.NONE,
                  documentsAuthLevel: Authority.NONE,
                  actsAuthLevel: Authority.NONE,
                  contractAuthLevel: Authority.NONE
                } as IPredefinedWorkGroupUser)
            }}
          >
            {t("GLOBAL.CANCEL")}
          </BhTextOnlyButton>
        </div>
        {hasAddedUsers && (
          <table className="mt-14">
            <BhTableHeader columns={tableColumnHeaders} />
            <tbody className="divide-y">
              {newPartyForm.users?.map((user, index) => {
                return (
                  <PartyTemplateUserListItem
                    key={user.username}
                    user={user as IPredefinedWorkGroupUser}
                    onUserPrivilegeChange={onUserPrivilegeChange}
                    dropdownMenuValues={dropdownMenuValues}
                    onUserRemove={removeUser}
                  />
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default CreateNewPartyTemplateUsersStep;
