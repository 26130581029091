import React, { FC, ReactElement, useState } from "react";
import { CompanyStatus, ICompany } from "@/model/ICompany";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { BauhubPlan } from "@/model/IProject";
import BhProPlanTag from "@components/tags/BhProPlanTag";
import BhLightPlanTag from "@components/tags/BhLightPlanTag";
import { faFort } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import BhTableRow from "@components/table/BhTableRow";

interface Props {
  company: ICompany;
}

const BhCompanyRow: FC<Props> = ({ company }) => {
  const [isImageMissing, setIsImageMissing] = useState(false);
  const statusMap: Record<CompanyStatus, ReactElement> = {
    [CompanyStatus.TRIAL]: <BhTag type={BhTagType.MUSTAND}>On trial</BhTag>,
    [CompanyStatus.TRIAL_EXPIRED]: <BhTag type={BhTagType.FAILED}>Trial expired</BhTag>,
    [CompanyStatus.INACTIVE]: <BhTag type={BhTagType.ARHIVEERITUD}>Inactive</BhTag>,
    [CompanyStatus.ACTIVE]: <BhTag type={BhTagType.PROJEKT_TOOS}>Active</BhTag>
  };

  return (
    <BhTableRow>
      <td>
        <Link to={"/bo/company/" + company.id + "/general"}>
          <div className="flex items-center gap-4">
            <div className="flex w-6 items-center justify-center">
              {isImageMissing && <FontAwesomeIcon icon={faFort} className="bh-text-pigeon-60" />}
              {!isImageMissing && (
                <img
                  className="max-w-6 max-h-6 object-contain"
                  src={"https://s3.eu-central-1.amazonaws.com/www.bauhub.ee/public/logo/" + company.uuid}
                  alt="Company Logo"
                  crossOrigin=""
                  onLoad={() => setIsImageMissing(false)}
                  onError={() => setIsImageMissing(true)}
                />
              )}
            </div>
            {company.name}
            {statusMap[company.status]}
          </div>
        </Link>
      </td>
      <td className="w-full">
        <div className="w-fit">{company.plan === BauhubPlan.PRO ? <BhProPlanTag /> : <BhLightPlanTag />}</div>
      </td>
    </BhTableRow>
  );
};
export default BhCompanyRow;
