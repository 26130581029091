import React, { Dispatch, useEffect } from "react";
import { PickResult, Viewer } from "@xeokit/xeokit-sdk";
import { BauhubXeokitTool } from "@/views/home/project/detail/xeokit/XeokitWebViewer";

interface Props {
  onModelsLoaded: boolean;
  isMobile?: boolean;
  bauhubToolActive: BauhubXeokitTool;
  viewer: Viewer;
  setPropertiesVisible: Dispatch<boolean>;
}

const XeokitObjectSelectionTool = React.memo<Props>(({ onModelsLoaded, isMobile, bauhubToolActive, viewer }) => {
  useEffect(() => {
    viewer.on("reset", () => {
      unSelectAll();
    });
  }, [onModelsLoaded]);

  const unSelectAll = () => {
    viewer.scene.setObjectsSelected(viewer.scene.selectedObjectIds, false);
  };

  const pickedCallback = (pickResult: PickResult) => {
    if (bauhubToolActive !== BauhubXeokitTool.SELECT) return;

    if (!pickResult.entity) return;
    if (!viewer) return;

    if (viewer.scene.selectedObjectIds.length === 1 && viewer.scene.selectedObjectIds[0] === pickResult.entity.id) {
      unSelectAll();
      return;
    }

    viewer.scene.setObjectsSelected(viewer.scene.selectedObjectIds, false);

    pickResult.entity.selected = true;
  };

  const pickedNothingCallback = () => {
    if (bauhubToolActive !== BauhubXeokitTool.SELECT) return;

    unSelectAll();
  };

  useEffect(() => {
    const pickedId = viewer.cameraControl.on("picked", pickedCallback);
    const pickedNothingId = viewer.cameraControl.on("pickedNothing", pickedNothingCallback);

    return () => {
      viewer.cameraControl.off(pickedId);
      viewer.cameraControl.off(pickedNothingId);
    };
  }, [bauhubToolActive]);

  return null;
});

export default XeokitObjectSelectionTool;
