import React, { FC, useEffect, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { IUser } from "@/model/IUser";
import { findAccessListForFileInformation } from "@/api/fileInfoAPI";
import NewCommentInput from "./NewCommentInput";

interface Props {
  fileEntityId: EntityId;
  projectId: EntityId;
  onSubmit: Function;
}

const NewFileCommentWithUsers: FC<Props> = ({ fileEntityId, projectId, onSubmit }) => {
  const [usersWithAccess, setUsersWithAccess] = useState([] as Array<IUser>);

  useEffect(() => {
    findAccessListForFileInformation(fileEntityId, projectId).then((users) => {
      const distinctUsers = users.filter((user, i, arr) => arr.findIndex((t) => t.id === user.id) === i);
      setUsersWithAccess(distinctUsers);
    });
  }, [fileEntityId, projectId]);

  return <NewCommentInput onSubmit={onSubmit} usersWithAccess={usersWithAccess} />;
};

export default NewFileCommentWithUsers;
