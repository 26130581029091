import { EntityId } from "@reduxjs/toolkit";
import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectContractPrivilegesForParty, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { selectCurrentProjectId, selectMaruProjectModule } from "@/app/store/project/projectSlice";
import BhIFrameComponent from "@/views/global/BhIFrameComponent";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  partyId: EntityId;
}

const ContractContainerAngular: FC<Props> = ({ partyId }) => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const isMaruProject = useAppSelector(selectMaruProjectModule);
  const isBauhubProject = !isMaruProject;
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const privilegesForContract = useAppSelector((state) => !isProjectAdmin && selectContractPrivilegesForParty(state, partyId));

  if (!isProjectAdmin && !privilegesForContract.isRead) {
    return null;
  }

  return (
    <>
      {isBauhubProject && <BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/parties/${partyId}/contract/react`} />}
      {isMaruProject && <BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/parties/${partyId}/mr/contract/react`} />}
    </>
  );
};

export default ContractContainerAngular;
