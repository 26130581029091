import React, { FC } from "react";
import { IObjectChange } from "@/model/IObjectChange";
import BhTableRow from "@components/table/BhTableRow";
import moment from "moment";

interface Props {
  log: IObjectChange;
}

const BOCompanyProjectLogRow: FC<Props> = ({ log }) => {
  return (
    <BhTableRow key={log.id}>
      <td>{log.field}</td>
      <td>{log.oldValue}</td>
      <td>{log.newValue}</td>
      <td>{moment(log.updated).format("DD.MM.YY HH:mm")}</td>
      <td>{log.creatorFullName}</td>
    </BhTableRow>
  );
};

export default BOCompanyProjectLogRow;
