import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import ConfirmationFolderPath from "@/views/home/project/detail/confirmations/confirmation/confirmationFileContainer/ConfirmationFolderPath";
import ConfirmationFileContainerActions from "@/views/home/project/detail/confirmations/confirmation/confirmationFileContainer/ConfirmationFileContainerActions";
import ConfirmationContainerActionsTableHeader from "@/views/home/project/detail/confirmations/confirmation/tableHeader/ConfirmationContainerActionsTableHeader";
import ConfirmationContainerActionsPreviewTableHeader from "@/views/home/project/detail/confirmations/confirmation/tableHeader/ConfirmationContainerActionsPreviewTableHeader";
import ConfirmationFileContainerActionsPreview from "@/views/home/project/detail/confirmations/confirmation/confirmationFileContainer/ConfirmationFileContainerActionsPreview";
import { useAppSelector } from "@/app/hooks";
import { selectFileConfirmationFileParentEntityNameAndIdsSorted, selectFileConfirmationFilesForConfirmationWithDecisionId } from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import { selectCurrentDecisions } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";

interface Props {
  confirmationId: EntityId;
  previewShown: boolean;
  selectedFileEntityId?: EntityId;
}

const ConfirmationActionsFileList: FC<Props> = ({ confirmationId, previewShown, selectedFileEntityId }) => {
  const fileConfirmationFilesWithDecisionIds = useAppSelector((state) => selectFileConfirmationFilesForConfirmationWithDecisionId(state, confirmationId));
  const uniqueParentFileEntityIdsAndNames = useAppSelector((state) => selectFileConfirmationFileParentEntityNameAndIdsSorted(state, confirmationId));
  const decisions = useAppSelector(selectCurrentDecisions);

  const files = uniqueParentFileEntityIdsAndNames.map((parentFileEntity) => {
    const files = fileConfirmationFilesWithDecisionIds.filter((fcf) => fcf.parentFileEntityId === parentFileEntity.id);
    if (files.length < 1) return null;
    return (
      <React.Fragment key={parentFileEntity.id}>
        <ConfirmationFolderPath>{parentFileEntity.name}</ConfirmationFolderPath>
        {fileConfirmationFilesWithDecisionIds
          .filter((fcf) => fcf.parentFileEntityId === parentFileEntity.id)
          .map((file) => {
            return (
              <React.Fragment key={file.fileEntityId}>
                {previewShown ? (
                  <ConfirmationFileContainerActionsPreview
                    fileConfirmationFileId={file.fileEntityId}
                    decision={decisions.find((d) => d.id === file.decisionId)}
                    selected={file.fileEntityId === selectedFileEntityId}
                  />
                ) : (
                  <ConfirmationFileContainerActions fileConfirmationFileId={file.fileEntityId} decision={decisions.find((d) => d.id === file.decisionId)} />
                )}
              </React.Fragment>
            );
          })}
      </React.Fragment>
    );
  });

  return (
    <>
      {!previewShown && (
        <div className="h-full px-8 py-2">
          <BhScrollableBody header={<ConfirmationContainerActionsTableHeader confirmationId={confirmationId} />}>{files}</BhScrollableBody>
        </div>
      )}
      {previewShown && (
        <div className="h-full pl-5">
          <BhScrollableBody header={<ConfirmationContainerActionsPreviewTableHeader />}>{files}</BhScrollableBody>
        </div>
      )}
    </>
  );
};

export default ConfirmationActionsFileList;
