import React, { FC } from "react";
import { faList, faListTree } from "@fortawesome/pro-regular-svg-icons";
import BhToggleIconBar from "@components/toggle/BhToggleIconBar";
import { IToggleBarIconValue } from "@components/toggle/IToggleBarIconValue";
import { ISharebox, ShareboxDisplayOption } from "@/model/shareboxes/ISharebox";
import { IShareboxUrl } from "@/model/shareboxes/IShareboxUrl";
import { Trans, useTranslation } from "react-i18next";
import BhLargeText from "@components/text/BhLargeText";
import ShareboxFilesContainerListView from "@/views/home/project/detail/shareboxes/detail/shareboxFilesView/ShareboxFilesContainerListView";
import ShareboxFilesContainerDirectoryView from "@/views/home/project/detail/shareboxes/detail/shareboxFilesView/ShareboxFilesContainerDirectoryView";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import PublicShareboxContainerHeader from "@/views/home/project/detail/shareboxes/detail/public/PublicShareboxContainerHeader";
import PublicNavbar from "@/views/home/navbar/PublicNavbar";
import PublicShareboxInfoHeader from "@/views/home/project/detail/shareboxes/detail/public/PublicShareboxInfoHeader";
import { selectLightboxModalOpen } from "@/app/store/lightboxSlice";
import { useAppSelector } from "@/app/hooks";
import LightboxModal from "@/views/home/project/projectModals/LightboxModal";

interface Props {
  sharebox: ISharebox;
  shareboxUrl?: IShareboxUrl;
}

const PublicShareboxContainer: FC<Props> = ({ sharebox, shareboxUrl }) => {
  const { t } = useTranslation();
  const lightboxModalOpen = useAppSelector(selectLightboxModalOpen);
  const [shareboxDisplayOption, setShareboxDisplayOption] = useLocalStorage<ShareboxDisplayOption>("shareboxDisplayOption", ShareboxDisplayOption.FILE_LIST);

  const showFilesAsFoldersOrListToggleButtonValues: Array<IToggleBarIconValue> = [
    { textValue: ShareboxDisplayOption.FILE_LIST, icon: faList, tooltipText: t("SHAREBOX.AS_LIST") },
    { textValue: ShareboxDisplayOption.DIRECTORY_TREE, icon: faListTree, tooltipText: t("SHAREBOX.AS_TREE") }
  ];

  if (!sharebox) {
    return null;
  }

  const noFilesAdded = !sharebox.fileIds || sharebox.fileIds.length === 0;

  return (
    <div className="flex h-screen w-screen flex-col overflow-hidden">
      {lightboxModalOpen && <LightboxModal isPublicSharebox={true} shareboxUuid={sharebox.uuid} />}
      <div>
        <PublicNavbar />
        <PublicShareboxContainerHeader shareboxId={sharebox.id} shareboxDisplayOption={shareboxDisplayOption} />
      </div>
      <div className="flex flex-1 flex-col overflow-auto">
        <PublicShareboxInfoHeader shareboxId={sharebox.id} shareboxUrl={shareboxUrl} />
        <div className="mx-auto flex w-full max-w-[1366px] flex-col p-6">
          <div>
            <div className="flex flex-row items-center justify-between">
              <h2>
                <Trans>SEARCH.FILES</Trans>
              </h2>
              <div className="flex items-center">
                <BhToggleIconBar
                  values={showFilesAsFoldersOrListToggleButtonValues}
                  onClickAction={(changedValue: any) => {
                    setShareboxDisplayOption(changedValue.displayPreference.textValue);
                  }}
                  selected={shareboxDisplayOption}
                  property="displayPreference"
                />
              </div>
            </div>
            {noFilesAdded && <BhLargeText classes="text-center pt-10 items-center">{t("SHAREBOX.NOFILES.HEADER") as string}</BhLargeText>}
            {shareboxDisplayOption === ShareboxDisplayOption.FILE_LIST && <ShareboxFilesContainerListView shareboxId={sharebox.id} shareboxUuid={sharebox.uuid} publicSharebox={true} />}
            {shareboxDisplayOption === ShareboxDisplayOption.DIRECTORY_TREE && <ShareboxFilesContainerDirectoryView shareboxId={sharebox.id} shareboxUuid={sharebox.uuid} publicSharebox={true} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicShareboxContainer;
