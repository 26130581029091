import React, { FC, useEffect, useState } from "react";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useTranslation } from "react-i18next";
import { fetchProjectTaskCategoriesAsync } from "@/app/store/tasksSlice";
import FormTaskContainer from "@components/task/container/FormTaskContainer";
import { selectCurrentFormFileEntityId, selectCurrentFormId, selectCurrentFormType, selectValueByPropertyFromCurrentFormData } from "@/app/store/form/formSlice";
import BhIconButton from "@components/buttons/BhIconButton";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import ScmTaskDropdown from "@components/form/fields/oldCustomFields/scm/ScmTaskDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons/faCircleExclamation";
import { IFormTaskInfo } from "@/model/taskBoard/ITask";

interface Props {
  field: any;
  disabled: boolean;
  removeCallback?: Function;
  addCallback?: Function;
  saveCallback?: Function;
  isPreview?: boolean;
}

const FormScmTasksField: FC<Props> = ({ field, disabled, removeCallback, addCallback, saveCallback, isPreview }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const tasks = useAppSelector((state) => (!isPreview ? selectValueByPropertyFromCurrentFormData(state, field.property) : []));
  const [openedFormTask, setOpenedFormTask] = useState({} as any);
  const formId = useAppSelector(selectCurrentFormId);
  const formFileEntityId = useAppSelector(selectCurrentFormFileEntityId);
  const formType = useAppSelector(selectCurrentFormType);
  const formTaskInfo = { formId: formId, fileEntityId: formFileEntityId, projectId: projectId, formType: formType } as IFormTaskInfo;

  useEffect(() => {
    if (!isPreview) {
      dispatch(fetchProjectTaskCategoriesAsync(projectId));
    }
  }, []);

  const removeTaskRow = (task: any) => {
    removeCallback && removeCallback(task, "tasks");
  };

  const openFormTask = (task: any) => {
    setOpenedFormTask(task);
  };

  return (
    <div>
      <ScmTaskDropdown addRow={addCallback} disabled={disabled} tasksLength={tasks.length || 0} setOpenedFormTask={setOpenedFormTask} openedFormTask={openedFormTask} saveRow={saveCallback} />
      {tasks && tasks.length > 0 && (
        <div>
          <div className="flex flex-row items-start pt-2.5 font-bold">{t("FORMS.SCM.ADDED_TASKS")}</div>
          <div className="flex w-full flex-col">
            {tasks.map((task: any) => {
              return (
                <div className="flex flex-col" key={task._id}>
                  <div className="flex flex-row items-center gap-x-1 py-1">
                    <FormTaskContainer task={task} clickDisabled={disabled} formTaskOpenCallback={() => openFormTask(task)} formTaskInfo={formTaskInfo} />
                    <div>
                      <BhIconButton icon={faTrash} buttonProps={{ onClick: () => removeTaskRow(task) }} />
                    </div>
                  </div>
                  {!task.id && (
                    <div className="bh-text-deep-ocean-80 ml-2 flex flex-row items-center gap-x-2">
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      {t("FORMS.TASK.SAVED_TO_FORM")}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default FormScmTasksField;
