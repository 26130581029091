import React, { FC, useEffect } from "react";

interface Props {
  children: React.ReactNode;
}

const ContentContainer: FC<Props> = ({ children }) => {


  return (
    <div className="flex w-screen justify-center overflow-y-auto">
      <div className="w-full max-w-screen-2xl px-16 pb-4">{children}</div>
    </div>
  );
};

export default ContentContainer;
