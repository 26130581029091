import React, { FC, useEffect, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { IFileConfirmation } from "@/model/confirmations/IFileConfirmation";
import { fetchConfirmationForFile } from "@/api/fileConfirmationAPI";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import BhConfirmationStatusLabelIconOnly from "@components/confirmation/BhConfirmationStatusLabelIconOnly";
import { IUser } from "@/model/IUser";

interface Props {
  fileEntityId: EntityId;
}

const BhSignatureContainerSignaturesDropdown: FC<Props> = ({ fileEntityId }) => {
  const [fileConfirmation, setFileConfirmation] = useState<IFileConfirmation>({} as IFileConfirmation);

  useEffect(() => {
    fetchConfirmationForFile(fileEntityId).then((confirmation) => setFileConfirmation(confirmation));
  }, []);

  if (!fileConfirmation.id) return null;

  const confirmationDecisionsSorted = fileConfirmation.decisionsV2 && fileConfirmation.decisionsV2.sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1));

  const findUser = (userId: EntityId) => {
    const confirmationUsers = fileConfirmation.users && fileConfirmation.users.map((fcu) => fcu.userEntity);
    const user = confirmationUsers.find((user) => user.id === userId);
    return user || ({} as IUser);
  };

  return (
    <div className="bh-shadow min-w-300 bh-bg-white inline-block space-y-2.5 rounded p-4 align-top">
      {confirmationDecisionsSorted &&
        confirmationDecisionsSorted.length > 0 &&
        confirmationDecisionsSorted.map((decision) => {
          return (
            <div className="flex flex-row items-center gap-x-2" key={decision.id}>
              <div className="flex w-8 flex-row items-center justify-center">
                <BhConfirmationStatusLabelIconOnly status={decision.status} />
              </div>
              <div className="flex-1">{<BhUserIconWithName user={findUser(decision.userEntityId)} size={"sm"} />}</div>
            </div>
          );
        })}
    </div>
  );
};

export default BhSignatureContainerSignaturesDropdown;
