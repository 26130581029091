import React, { FC, useEffect } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch } from "@/app/hooks";
import BOCompanySettingsContainer from "@/components/backoffice/company/BOCompanySettingsContainer";
import { boFetchCompanyAsync, boFetchCompanyBillingAsync, boFindCompanyUsersAsync, boGetInvitedUsersForCompanyAsync } from "@/app/store/backofficeSlice";

interface Props {
  companyId: EntityId;
}

const BOCompany: FC<Props> = ({ companyId }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(boFetchCompanyAsync(companyId));
    dispatch(boFetchCompanyBillingAsync(companyId));
    dispatch(boFindCompanyUsersAsync(companyId));
    dispatch(boGetInvitedUsersForCompanyAsync(companyId));
  }, []);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <div className="flex h-full w-full flex-row overflow-hidden">
        <BOCompanySettingsContainer />
      </div>
    </div>
  );
};

export default BOCompany;
