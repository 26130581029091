import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import BhSortButton from "@components/sort/BhSortButton";
import { initialSignInvitesSort, ISignInvitesSort } from "@/model/ISignInvitesSort";
import { selectSignInvitesSort, signInvitesSortSet } from "@/app/store/signInvitesSlice";

const SignInvitesSort: FC = () => {
  const { t } = useTranslation();
  const [signInvitesSort, setSignInvitesSort] = useLocalStorage<ISignInvitesSort>("signInvitesOrdering", initialSignInvitesSort);
  const sort = useAppSelector(selectSignInvitesSort);

  const dropdownMenuValues = [
    { value: "created", translation: t("SIGN_INVITE.TABLE.CREATED") },
    { value: "container.name", translation: t("GLOBAL.ORDER_BY.NAME") }
  ];

  return (
    <div className="flex flex-row items-center">
      <BhSortButton sort={sort} setSortForStore={signInvitesSortSet} localStorageSort={signInvitesSort} setSortForLocalStorage={setSignInvitesSort} sortValues={dropdownMenuValues} />
    </div>
  );
};

export default SignInvitesSort;
