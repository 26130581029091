import React, { FC, useEffect, useState } from "react";
import TaskInfoField from "@components/task/modal/TaskInfoField";
import { ITask } from "@/model/taskBoard/ITask";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCalendarDay } from "@fortawesome/pro-regular-svg-icons/faCalendarDay";
import useInputIsActive from "@hooks/useInputIsActive";
import BhInputSimple from "@components/input/BhInputSimple";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectOpenedTaskDisabled } from "@/app/store/tasksSlice";
import { formatDate } from "@/utilities/dateUtility";
import BhDatePicker from "@components/input/BhDatePicker";

interface Props {
  task: ITask;
  onSelect: Function;
}

const TaskDeadlineInfoField: FC<Props> = ({ task, onSelect }) => {
  const { t } = useTranslation();
  const { isActive, toggleIsActive, inputRef } = useInputIsActive();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const selectedDate = task.deadline ? new Date(task.deadline) : new Date();
  const isTaskDisabled = useAppSelector(selectOpenedTaskDisabled);

  useEffect(() => {
    setIsDropdownOpen(isActive);
  }, [isActive]);

  const onSelectDate = (changedObject: any) => {
    toggleIsActive();
    onSelect(changedObject);
  };

  return (
    <div onClick={() => toggleIsActive(isTaskDisabled)} className="relative w-full">
      <TaskInfoField
        text={t("TASK.DEADLINE")}
        children={
          <div
            className="inline-block w-full"
            onBlur={() => {
              toggleIsActive();
            }}
          >
            {!isActive && (
              <div className="bh-text-deep-ocean flex w-full flex-row items-center font-bold">
                <span>{formatDate(task.deadline as Date)}</span>
              </div>
            )}
            {isActive && (
              <div>
                <BhInputSimple initialValue={formatDate(task.deadline as Date)} property={"deadline"} inputRef={inputRef} />
                {isDropdownOpen && (
                  <div
                    className="right-30 absolute top-12 z-50"
                    onClick={(e: any) => {
                      e.stopPropagation();
                    }}
                    onMouseDown={(e: any) => {
                      e.preventDefault();
                    }}
                  >
                    <BhDatePicker property={"deadline"} inline={true} initialValue={selectedDate} onChangeCallback={onSelectDate} returnISOString={true} />
                  </div>
                )}
              </div>
            )}
          </div>
        }
        valueExists={!!task.deadline}
        icon={faCalendarDay}
        trailingIcon={!isActive ? ("deadline" in task && task.deadline ? faCaretDown : faPlus) : undefined}
        disabled={isTaskDisabled}
      />
    </div>
  );
};

export default TaskDeadlineInfoField;
