import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhCardContainer from "@components/cards/BhCardContainer";
import { Link } from "react-router-dom";
import ConfirmationsListItemBody from "@/views/home/project/detail/confirmations/list/ConfirmationsListItemBody";
import { IFileConfirmation } from "@/model/confirmations/IFileConfirmation";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  fileConfirmation: IFileConfirmation;
}

const ConfirmationsListItemGeneral: FC<Props> = ({ fileConfirmation }) => {
  const projectId = useAppSelector(selectCurrentProjectId);

  return (
    <BhCardContainer>
      <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/confirmation/${fileConfirmation.id}`} className="w-full">
        <ConfirmationsListItemBody fileConfirmation={fileConfirmation} />
      </Link>
    </BhCardContainer>
  );
};

export default ConfirmationsListItemGeneral;
