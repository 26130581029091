import React, { FC } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons";
import { IFileEntity } from "@/model/files/IFileEntity";
import { EntityId } from "@reduxjs/toolkit";
import { selectShareboxById } from "@/app/store/shareboxesSlice";
import { useAppSelector } from "@/app/hooks";
import { fetchShareboxFilesUrl } from "@/api/shareboxAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { findAllChildrenForFileEntities } from "@/utilities/fileEntityUtilities";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  fileEntity: IFileEntity;
  shareboxId: EntityId;
}

const ShareboxFolderContainerDownloadButtonFC: FC<Props> = ({ fileEntity, shareboxId }) => {
  const sharebox = useAppSelector((state) => selectShareboxById(state, shareboxId));

  const downloadFolder = () => {
    if (sharebox?.uuid) {
      const allFiles = [...sharebox.fileList, ...sharebox.parentList];
      let allSelectedIds: Array<EntityId> = [];
      findAllChildrenForFileEntities(allFiles, [fileEntity.id], allSelectedIds);
      fetchShareboxFilesUrl(sharebox.uuid, allSelectedIds).then((response) => {
        const url = ConfigSingleton.getInstance().getConfig().REACT_APP_PUBLIC_API_URL + "/url/batch/sharebox/direct/" + response.value;
        imitateUrlDownload(url);
      });
    }
  };

  return <BhIconButton icon={faArrowDownToLine} buttonProps={{ onClick: () => downloadFolder() }} />;
};

const ShareboxFolderContainerDownloadButton = React.memo(ShareboxFolderContainerDownloadButtonFC);
export default ShareboxFolderContainerDownloadButton;
