import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentFormRowsSorted } from "@/app/store/form/formSlice";
import FormRow from "@components/form/FormRow";
import { classNames } from "@/utilities/jsUtilities";
import { IFormBaseVersionRow } from "@/model/form/IFormBaseVersionRow";
import { FormRowFieldType, IFormBaseVersionRowField } from "@/model/form/IFormBaseVersionRowField";

interface Props {
  dataSaveCallback: Function;
  nestedDataSaveCallback: Function;
  nestedDataAddCallback: Function;
  nestedDataRemoveCallback: Function;
  disabled: boolean;
}

const FormContent: FC<Props> = ({ dataSaveCallback, nestedDataSaveCallback, nestedDataAddCallback, nestedDataRemoveCallback, disabled }) => {
  const formRows = useAppSelector(selectCurrentFormRowsSorted);

  if (!formRows) return null;

  const isProtocolTableRow = (row: IFormBaseVersionRow) => {
    return row.fields.some((field: IFormBaseVersionRowField) => field.fieldType === FormRowFieldType.PROTOCOL);
  };

  return (
    <div>
      {formRows.map((row, index) => {
        return (
          <div className={classNames(isProtocolTableRow(row) ? "-ml-1 pr-0 pl-0" : "px-14 lg:px-0")} key={index}>
            <FormRow
              baseVersionRow={row}
              dataSaveCallback={dataSaveCallback}
              nestedDataSaveCallback={nestedDataSaveCallback}
              nestedDataAddCallback={nestedDataAddCallback}
              nestedDataRemoveCallback={nestedDataRemoveCallback}
              disabled={disabled}
            />
          </div>
        );
      })}
    </div>
  );
};

export default FormContent;
