import React, { FC, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhRadioGroup from "@components/input/BhRadioGroup";
import BhRadioInput from "@components/input/BhRadioInput";
import { useTranslation } from "react-i18next";
import { textWithVariables } from "@/utilities/jsUtilities";
import parse from "html-react-parser";

interface Props {
  onClose: Function;
  onSubmit: Function;
  destinationDirectoryName: string;
  duplicateFileName: string;
}

const DuplicateFileNameWarningModal: FC<Props> = ({ onClose, onSubmit, destinationDirectoryName, duplicateFileName }) => {
  const { t } = useTranslation();
  const [manageFiles, setManageFiles] = useState(true);

  const close = () => {
    onClose();
  };

  const submit = () => {
    onSubmit(manageFiles);
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={close}
      header={<h2>{t("DISTINCT.FILE.NAME.MODAL.HEADER")}</h2>}
      size="4xl"
      children={
        <div className="mx-20 my-14 pb-48">
          <div>{parse(textWithVariables(t("DISTINCT.FILE.NAME.MODAL.BODY2"), { folderName: destinationDirectoryName, fileName: duplicateFileName }))}</div>
          <BhRadioGroup>
            <BhRadioInput property="" checked={manageFiles} label={t("DISTINCT.FILE.NAME.MODAL.OPTION.MANAGE") as string} onChange={() => setManageFiles(true)} />
            <BhRadioInput property="" checked={!manageFiles} label={t("DISTINCT.FILE.NAME.MODAL.OPTION.KEEP_FILES") as string} onChange={() => setManageFiles(false)} />
          </BhRadioGroup>
        </div>
      }
      footer={<BhModalFooter onCancel={close} onConfirm={submit} confirmButtonText={`${t("GLOBAL.SAVE")}`} />}
    />
  );
};

export default DuplicateFileNameWarningModal;
