import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IFileEntity } from "@/model/files/IFileEntity";
import BhDivTableHeader from "@components/table/BhDivTableHeader";
import { filesSortSet, selectFilesSort } from "@/app/store/filesSlice";
import FileContainerTableHeaderCheckbox from "@/views/home/project/detail/confirmations/confirmation/tableHeader/FileContainerTableHeaderCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/pro-solid-svg-icons/faCheckDouble";
import { faSignature } from "@fortawesome/pro-regular-svg-icons/faSignature";
import { useTranslation } from "react-i18next";
import { IAuthorityBoolean } from "@/model/IUser";
import { faArrowRightArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowRightArrowLeft";
import { faArrowDown } from "@fortawesome/pro-regular-svg-icons/faArrowDown";
import { faArrowUp } from "@fortawesome/pro-regular-svg-icons/faArrowUp";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import { IFilesSort, initialFilesSort } from "@/model/files/IFilesSort";
import BhTooltip from "@components/BhTooltip";

interface Props {
  privilegesForDirectory: IAuthorityBoolean;
  isSearchView?: boolean;
}

const FileContainerTableHeader: FC<Props> = ({ privilegesForDirectory, isSearchView }) => {
  const { t } = useTranslation();
  const [filesOrdering, setFilesOrdering] = useLocalStorage<IFilesSort>("filesOrdering", initialFilesSort);
  const sort = useAppSelector(selectFilesSort);
  const dispatch = useAppDispatch();

  useEffect(() => {
    filesOrdering && dispatch(filesSortSet(filesOrdering));
  }, []);

  const createdUpdatedContent = (
    <span>
      <button
        onClick={() => {
          const dateFields = ["created", "updated"];
          const otherField = dateFields.find((field) => field !== sort.dateField) as "created" | "updated";
          if (otherField) {
            const updatedSort = { ...sort, dateField: otherField, property: dateFields.includes(sort.property) ? otherField : sort.property };
            dispatch(filesSortSet(updatedSort));
            setFilesOrdering(updatedSort);
          }
        }}
        className="bh-text-deep-ocean-imp hover:bh-bg-smoke disabled:bh-text-pigeon-60 bh-text-pigeon mr-0.5 rounded px-0.5 py-0.5 font-bold"
      >
        <FontAwesomeIcon className="px-1" icon={faArrowRightArrowLeft} />
      </button>
      <span
        onClick={() => {
          const updatedSort = { ...sort, property: sort.dateField, reversed: sort.dateField === sort.property ? !sort.reversed : sort.reversed };
          dispatch(filesSortSet(updatedSort));
          setFilesOrdering(updatedSort);
        }}
      >
        <span className="hover:bh-bg-smoke cursor-pointer rounded py-0.5 px-1">{t("GLOBAL." + sort.dateField.toUpperCase())}</span>
        {sort.property && ["created", "updated"].includes(sort.property) && (
          <button className="bh-text-deep-ocean-imp hover:bh-bg-smoke disabled:bh-text-pigeon-60 bh-text-pigeon mx-0.5 rounded px-0.5 py-0.5 font-bold">
            <FontAwesomeIcon className="px-1" icon={sort.reversed ? faArrowDown : faArrowUp} />
          </button>
        )}
      </span>
    </span>
  );

  const tableColumnHeaders: Array<IBhTableHeader<IFileEntity>> = [
    { id: 0, content: "", classes: "w-0.5 !p-0" },
    { id: 1, content: privilegesForDirectory.isRead && <FileContainerTableHeaderCheckbox isSearchView={isSearchView} />, classes: "w-8 flex-none justify-center" },
    { id: 2, content: "", classes: "w-9 flex-none" },
    { id: 3, content: `${t("GLOBAL.ITEM_NAME")}`, classes: "flex-grow min-w-24", field: "name", sortable: true },
    { id: 4, content: `${t("GLOBAL.VERSION")}`, classes: "w-18 flex-none justify-center sm:hidden" },
    {
      id: 5,
      content: (
        <BhTooltip body={t("CONFIRMATION.CONFIRMED")}>
          <FontAwesomeIcon icon={faCheckDouble} />
        </BhTooltip>
      ),
      classes: "w-12 flex-none justify-center lg:hidden"
    },
    {
      id: 6,
      content: (
        <BhTooltip body={t("SIGNATURE.SIGNED")}>
          <FontAwesomeIcon icon={faSignature} />
        </BhTooltip>
      ),
      classes: "px-0 w-12 whitespace-nowrap flex-none justify-center lg:hidden",
      field: "signaturesCount",
      sortable: true
    },
    { id: 7, content: createdUpdatedContent, classes: "w-40 flex-none lg:hidden" },
    { id: 8, content: "", classes: `${isSearchView ? " w-21 " : " w-15 "} flex-none` }
  ];

  const onTableHeaderClickCallback = (column: IBhTableHeader<IFileEntity>) => {
    if (column.field) {
      const updatedSort = { ...sort, property: column.field, reversed: column.field === sort.property ? !sort.reversed : sort.reversed };
      dispatch(filesSortSet(updatedSort));
      setFilesOrdering(updatedSort);
    }
  };

  return <BhDivTableHeader columns={tableColumnHeaders} onClickCallback={onTableHeaderClickCallback} sortedBy={sort.property} reversed={sort.reversed} />;
};

export default FileContainerTableHeader;
