import { EntityId } from "@reduxjs/toolkit";
import { IFormBaseVersion } from "@/model/form/IFormBaseVersion";
import { IUser } from "@/model/IUser";
import { FormType } from "@/model/IForm";

export interface IFormBase {
  id: EntityId;
  type: string;
  deleted: boolean;
  templateBaseId: EntityId;
  formBaseVersions: Array<IFormBaseVersion>;
  bauhubFormBase?: boolean;
  createdBy: string;
  updatedBy: string;
  created: Date;
  updated: Date;
  addToNewProjects: boolean;
  createdInProjectId: EntityId;
  createdByUserEntity?: IUser;
  displayName: string;
  formName: string;
  formNamePrefix: FormNameOption;
  formNameSuffix: FormNameOption;
}

export enum FormNameOption {
  NONE = "NONE",
  ORDER_NUMBER = "ORDER_NUMBER",
  DATE = "DATE",
  ORDER_NUMBER_AND_DATE = "ORDER_NUMBER_AND_DATE"
}

export const CopyNotAllowedFormBaseTypes = [
  FormType.MNT_MP_KANDEVOIME,
  FormType.MNT_MP_ALUSED,
  FormType.MNT_MP_PINNAS,
  FormType.MNT_MP_KRAAVID,
  FormType.MNT_MP_MULD,
  FormType.MNT_MP_DREEN,
  FormType.MNT_MP_KATEND,
  FormType.MNT_MP_TRUUP,
  FormType.TOOPLAAN,
  FormType.EHITUSOBJEKTI_TOOOHUTUSE_PLAAN_MARU,
  FormType.SCM
];
