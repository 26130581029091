import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { IInvite } from "@/model/invites/IInvite";
import { EntityId } from "@reduxjs/toolkit";

export interface IUserAuthority {
  id: EntityId;
  userEntityId: EntityId;
  authority: Authority;
  subResource: SubResource;
  resource: Resource;
  resourceId: EntityId;
  username: string;
  firstName: string;
  lastName: string;
  companyRelatedAuthorities: IUserCompanyRelatedAuthority;
  projectId: EntityId;
}

export interface IUserCompanyRelatedAuthority {
  isLawyer: boolean;
  isCompanyAdmin: boolean;
  isEngineeringPM: boolean;
  maruAuthority: Resource;
}

export enum Authority {
  READ = "READ",
  WRITE = "WRITE",
  ADMIN = "ADMIN",
  NONE = "NONE",
  SUPERUSER = "SUPERUSER"
}

export enum Resource {
  PROJECT = "PROJECT",
  FILE = "FILE",
  DOCUMENT = "DOCUMENT",
  SHAREBOX = "SHAREBOX",
  WORK_GROUP = "WORK_GROUP",
  TASK_BOARD = "TASK_BOARD",
  USER = "USER",
  COMPANY = "COMPANY",
  APP = "APP",
  MR_PROJECT_PM = "MR_PROJECT_PM",
  MR_PROJECT_TEAM = "MR_PROJECT_TEAM",
  MR_OSAKONNAJUHT = "MR_OSAKONNAJUHT",
  MR_FINANTSJUHT = "MR_FINANTSJUHT",
  PROJECT_LAWYER = "PROJECT_LAWYER",
  RT_ENGINEERING_PM = "RT_ENGINEERING_PM",
  CHECKLIST = "CHECKLIST"
}

export enum SubResource {
  DOCUMENT = "DOCUMENT",
  FILE = "FILE",
  MODEL = "MODEL",
  ACT = "ACT",
  CONTRACT = "CONTRACT",
  ACCOUNT_MANAGER = "ACCOUNT_MANAGER",
  NEW_PROJECT_DEFAULT_ADMIN = "NEW_PROJECT_DEFAULT_ADMIN",
  CHECKLISTS_MANAGER = "CHECKLISTS_MANAGER",
  //TODO back-endis muuta see ka "FORMS_MANAGER" väärtuseks
  FORMS_MANAGER = "FORMS_MANAGER"
}

export interface IUserAuthoritiesRequest {
  username: string;
  authority: string;
}

export interface IUserAuthoritiesResponse {
  userList: Array<ISimpleValidUserAuthority>;
  inviteList: Array<IInvite>;
}

export interface IPredefinedWorkGroupUser extends IUserAuthority {
  predefinedWorkGroupId: EntityId;
  companyId: EntityId;
  documentsAuthLevel: Authority;
  filesAuthLevel: Authority;
  tasksAuthLevel: Authority;
  actsAuthLevel: Authority;
  contractAuthLevel: Authority;
  modelAuthLevel: Authority;
}
