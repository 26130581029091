import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useTranslation } from "react-i18next";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import BhSmallText from "@components/text/BhSmallText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenRuler } from "@fortawesome/pro-regular-svg-icons/faPenRuler";
import { classNames } from "@/utilities/jsUtilities";
import { selectCountOfUnsignedInvitesForProject } from "@/app/store/signaturesSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { fetchProjectActiveTasksForDashboard, fetchProjectRecentFilesAndDocumentsForDashboard } from "@/api/projectAPI";
import { ITask } from "@/model/taskBoard/ITask";
import { IRecentFilesDTO } from "@/model/IRecentFilesDTO";
import { faSquareKanban } from "@fortawesome/pro-regular-svg-icons/faSquareKanban";
import { faFiles } from "@fortawesome/pro-regular-svg-icons/faFiles";
import { faSignature } from "@fortawesome/pro-regular-svg-icons/faSignature";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import SignInvitesUnsignedList from "@/views/home/project/detail/signInvites/SignInvitesUnsignedList";
import OverviewTabsFileList from "@/views/home/project/detail/overview/OverviewTabsFileList";
import OverviewTabsTasks from "@/views/home/project/detail/overview/OverviewTabsTasks";
import { addOrUpdateTasks } from "@/app/store/tasksSlice";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";

interface Props {}

const OverviewTabs: FC<Props> = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(BhStateStatusType.INITIAL);
  const [overviewActiveTab, setOverviewActiveTab] = useLocalStorage<number>("overviewActiveTab", 0);

  const projectId = useAppSelector(selectCurrentProjectId);

  const [activeTasks, setActiveTasks] = useState<Array<ITask>>([]);
  const [recentFiles, setRecentFiles] = useState<IRecentFilesDTO>({ fileCount: 0, documentCount: 0, latestFiles: [], latestDocuments: [] });

  const signInvitesCount = useAppSelector(selectCountOfUnsignedInvitesForProject(projectId));
  const dispatch = useAppDispatch();

  const tabs: any = [
    {
      id: 0,
      icon: faPenRuler,
      heading: "GLOBAL.DRAWINGS",
      count: recentFiles.fileCount > 499 ? "500+" : recentFiles.fileCount,
      info: "DASH.INFO.NEW_DRAWINGS",
      description: "DASH.INFO.DRAWINGS_24",
      content: <OverviewTabsFileList files={recentFiles.latestFiles} count={recentFiles.fileCount} isPlan={true} />
    },
    {
      id: 1,
      icon: faFiles,
      heading: "GLOBAL.DOCUMENTS",
      count: recentFiles.documentCount > 499 ? "500+" : recentFiles.documentCount,
      info: "DASH.INFO.NEW_DOCS",
      description: "DASH.INFO.DOCS_24",
      content: <OverviewTabsFileList files={recentFiles.latestDocuments} count={recentFiles.documentCount} isPlan={false} />
    },
    {
      id: 2,
      icon: faSignature,
      heading: "PROJECT.SIDEBAR.SIGNATURES",
      count: signInvitesCount,
      info: "DASH.INFO.NEW_SIGNINVITE",
      description: "DASH.INFO.TO_SIGN",
      content: <SignInvitesUnsignedList />
    },
    {
      id: 3,
      icon: faSquareKanban,
      heading: "PARTY.TASKS",
      count: activeTasks.length,
      info: "DASH.INFO.UNFINISHED_TASKS",
      description: "DASH.INFO.UNFINISHED_INFO",
      content: <OverviewTabsTasks tasks={activeTasks} />
    }
  ];

  useEffect(() => {
    setStatus(BhStateStatusType.PENDING);
    fetchProjectRecentFilesAndDocumentsForDashboard(projectId).then((result) => {
      setRecentFiles(result);
      setStatus(BhStateStatusType.SUCCESS);
    });
    fetchProjectActiveTasksForDashboard(projectId).then((result) => {
      dispatch(addOrUpdateTasks(result));
      setActiveTasks(result);
      setStatus(BhStateStatusType.SUCCESS);
    });
  }, []);

  return (
    <div className="h-full w-full overflow-auto px-6 py-4">
      <div>
        <DetailViewHeading>{t("OVERVIEW.HEADING")}</DetailViewHeading>
        <div className="mt-4 flex flex-row flex-wrap">
          {tabs.map((tab: any) => {
            return (
              <div
                key={tab.id}
                className={classNames(
                  tab.id === overviewActiveTab ? "bh-bg-mint-50" : "bh-bg-smoke-50 hover:bh-bg-smoke",
                  "hover:bh-border-pigeon-60 mr-1 mb-2 flex h-[154px] w-40 cursor-pointer flex-col border-b p-4"
                )}
                onClick={() => setOverviewActiveTab(tab.id)}
              >
                <div>
                  <BhSmallText classes="m-0 space-x-1">
                    <FontAwesomeIcon icon={tab.icon} />
                    <span>{t(tab.heading)}</span>
                  </BhSmallText>
                </div>
                <h1 className={classNames(tab.id === overviewActiveTab && "bh-text-bauhub-green-120", "mt-5 mb-2 flex h-8 items-center")}>
                  {[BhStateStatusType.SUCCESS].includes(status) && tab.count}
                  {[BhStateStatusType.INITIAL, BhStateStatusType.PENDING].includes(status) && <div className="h-6 w-6 animate-pulse rounded bg-gray-400" />}
                </h1>
                <p className="m-0 font-bold">{t(tab.info)}</p>
              </div>
            );
          })}
        </div>
      </div>
      <BhSmallText classes="bh-text-pigeon">{t(tabs[overviewActiveTab].description)}</BhSmallText>
      <div>{tabs[overviewActiveTab].content}</div>
    </div>
  );
};

export default OverviewTabs;
