import React, { FC } from "react";
import { FileTreeType } from "@/model/files/IFileTreeTemplate";
import ProjectTreeContainer from "@/views/home/company/detail/companySettingsProjectTree/ProjectTreeContainer";
import { EntityId } from "@reduxjs/toolkit";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";

interface Props {
  companyId: EntityId;
}

const CompanySettingsProjectTreeTemplatesTabPro: FC<Props> = ({ companyId }) => {
  return (
    <BhScrollableBody>
      <div className="px-44 pt-6 md:px-8">
        <ProjectTreeContainer fileTreeType={FileTreeType.DRAWINGS} companyId={companyId} />
        <ProjectTreeContainer fileTreeType={FileTreeType.DOCUMENTS} companyId={companyId} />
      </div>
    </BhScrollableBody>
  );
};

export default CompanySettingsProjectTreeTemplatesTabPro;
