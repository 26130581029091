import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// @ts-ignore
import _ from "lodash";
import { IFileEntity } from "@/model/files/IFileEntity";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchParentDirsAsync, selectedInFileTreeFolderSet } from "@/app/store/foldersSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { classNames } from "@/utilities/jsUtilities";
import { faFolder } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { EntityId } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";
import BhTooltip from "@components/BhTooltip";
import { selectFileById } from "@/app/store/filesSlice";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  folderId: EntityId;
}

const FavouriteFolderContainerFC: FC<Props> = ({ folderId }: Props) => {
  const folderFileEntity = useAppSelector<IFileEntity | undefined>((state) => selectFileById(state, folderId));
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();
  if (!folderId || !folderFileEntity) return null;
  const isSelected = folderFileEntity.selectedInFileTree;

  return (
    <div className="group block">
      <div className={classNames(isSelected ? "bh-bg-mint-110" : "", "hover:bh-bg-mint-50 rounded-sm")}>
        <div className="flex h-7 flex-row items-center py-2 px-2">
          <span className="flex w-0.5"></span>
          <div className="flex w-full flex-col overflow-hidden">
            <BhTooltip body={folderFileEntity.name} delayShow={1000}>
              <Link
                className="flex flex-row items-center"
                to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/dir/${folderId}`}
                onClick={() => {
                  dispatch(fetchParentDirsAsync(folderId));
                  dispatch(selectedInFileTreeFolderSet(folderId));
                }}
              >
                <FontAwesomeIcon className={classNames(isSelected ? "bh-text-bauhub-green-120" : "bh-text-pigeon-60", "w-11px mr-1.5")} icon={faFolder} aria-hidden="true" />
                <div className={classNames(isSelected && "font-bold", "text-13 flex-1 overflow-hidden truncate")}>{folderFileEntity.name}</div>
              </Link>
            </BhTooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

const FavouriteFolderContainer = React.memo(FavouriteFolderContainerFC);
export default FavouriteFolderContainer;
