import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectProjectWorkGroupById } from "@/app/store/project/projectWorkGroupsSlice";
import { EntityId } from "@reduxjs/toolkit";
import PartiesListItemInfo from "@/views/home/project/detail/users/parties/PartiesListItemInfo";
import { useNavigate } from "react-router-dom";
import BhCardContainer from "@components/cards/BhCardContainer";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  partyId: EntityId;
}

const PartiesListItem: FC<Props> = ({ partyId }) => {
  const party = useAppSelector((state) => selectProjectWorkGroupById(state, partyId));
  const navigate = useNavigate();

  if (!party) return null;

  return (
    <BhCardContainer
      onClick={() => {
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${party.projectId}/users/party/${party.id}/privileges`);
      }}
    >
      <PartiesListItemInfo party={party} />
    </BhCardContainer>
  );
};

export default PartiesListItem;
