import React, { FC, SetStateAction, useState } from "react";
import BhModal from "@components/modal/BhModal";
import { useTranslation } from "react-i18next";
import { ITask, TaskStatus } from "@/model/taskBoard/ITask";
import BhToggleButtonBar from "@components/toggle/BhToggleButtonBar";
import BhInputWithFetchedUsers from "@components/input/BhInputWithFetchedUsers";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import BhDatePicker from "@components/input/BhDatePicker";
import BhTextareaSimple from "@components/input/BhTextareaSimple";
import BhInputSimple from "@components/input/BhInputSimple";
import BhModalFooter from "@components/modal/BhModalFooter";

interface Props {
  task: ITask;
  saveCallback: Function;
  setIsShown: React.Dispatch<SetStateAction<boolean>>;
}

const FormTaskModal: FC<Props> = ({ task, saveCallback, setIsShown }) => {
  const { t } = useTranslation();
  const [formTask, setFormTask] = useState(task);

  const statuses = [
    { text: t("TASK.TODO"), value: TaskStatus.TODO },
    { text: t("TASK.IN_PROGRESS"), value: TaskStatus.IN_PROGRESS },
    { text: t("TASK.NEEDS_APPROVAL"), value: TaskStatus.NEEDS_APPROVAL },
    { text: t("TASK.DONE"), value: TaskStatus.DONE }
  ];

  const setTask = (changedObj: any) => {
    const taskToSave = { ...formTask, ...changedObj };
    setFormTask(taskToSave);
  };

  const saveTask = () => {
    saveCallback(formTask);
    setIsShown(false);
  };

  return (
    <BhModal
      size={"2xl"}
      isShown={true}
      setIsShown={setIsShown}
      header={<h2>{t("TASK.TASK")}</h2>}
      children={
        <div className="mb-72 px-8 pt-6">
          <BhToggleButtonBar label={t("TASK.STATUS") as string} items={statuses} onClickAction={setTask} selected={formTask.status} property={"status"} />
          <div className="mt-3 -ml-28">
            <BhSideBySideInput label={t("TASK.NAME")}>
              <BhInputSimple property={"name"} initialValue={formTask.name} saveCallback={setTask} />
            </BhSideBySideInput>
          </div>
          <div className="-ml-28">
            <BhSideBySideInput label={t("TASK.ASSIGNEE")}>
              <BhInputWithFetchedUsers initialValue={formTask.assigneeFullName} property={"assigneeFullName"} saveCallback={setTask} returnOnlyString={true} />
            </BhSideBySideInput>
          </div>
          <div className="-ml-28">
            <BhSideBySideInput label={<div className="flex flex-row items-start pt-2">{t("TASK.DESCRIPTION")}</div>} customLabelPosition={true}>
              <BhTextareaSimple property={"description"} initialValue={formTask.description} onBlurCallback={setTask} />
            </BhSideBySideInput>
          </div>
          <div className="-ml-28">
            <BhSideBySideInput label={t("TASK.DEADLINE")}>
              <BhDatePicker property={"deadline"} initialValue={new Date(formTask.deadline)} onChangeCallback={setTask} />
            </BhSideBySideInput>
          </div>
        </div>
      }
      footer={<BhModalFooter onConfirm={saveTask} onCancel={() => setIsShown(false)} cancelButtonText={t("GLOBAL.CLOSE") as string} />}
    />
  );
};

export default FormTaskModal;
