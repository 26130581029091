import { bauhubPost } from "@/api/bauhubAPI";
import { IFavourite } from "@/model/IFavourite";
import { EntityId } from "@reduxjs/toolkit";
import { IFolderFileEntity } from "@/model/files/IFileEntity";

export function saveFavouriteProject(projectId: EntityId): Promise<IFavourite> {
  return bauhubPost("/project/" + projectId + "/favourite/add");
}

export function removeFavouriteProject(projectId: EntityId): Promise<IFavourite> {
  return bauhubPost("/project/" + projectId + "/favourite/remove");
}

export function saveFavouriteFile(fileId: EntityId): Promise<IFavourite> {
  return bauhubPost("/file/" + fileId + "/favourite/add");
}

export function removeFavouriteFile(fileId: EntityId): Promise<IFavourite> {
  return bauhubPost("/file/" + fileId + "/favourite/remove");
}

export function fetchAllFavouriteFolders(projectId: EntityId): Promise<Array<IFolderFileEntity>> {
  return bauhubPost("/project/" + projectId + "/favourite/folders?st=false");
}
