import React, { FC, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boFetchAllCompaniesAsync, boSelectAllCompanies, boSelectCompany, boTransferChecklistBasesToCompanyAsync } from "@/app/store/backofficeSlice";
import BOCompanyChecklistBaseListItemWithCheckbox from "@components/backoffice/company/modulesAndTemplates/BOCompanyChecklistBaseListItemWithCheckbox";
import BhInputLabel from "@components/input/BhInputLabel";
import { EntityId } from "@reduxjs/toolkit";
import BhInputWithDropdown from "@components/input/BhInputWithDropdown";
import { ICompany } from "@/model/ICompany";
import { IChecklistBase } from "@/model/checklist/IChecklistBase";
import { boFetchChecklistBasesForCompany } from "@/api/backoffice/boCompaniesAPI";

interface Props {
  setIsShown: (value: boolean) => void;
}

export interface IBoTransferChecklistsDTO {
  companyId: EntityId;
  baseIds: Array<EntityId>;
}

const BOCompanyTransferChecklistsModal: FC<Props> = ({ setIsShown }) => {
  const companies = useAppSelector(boSelectAllCompanies);
  const company = useAppSelector(boSelectCompany);
  const companiesListForInput = companies.map((company) => company);
  const [selectedCompany, setSelectedCompany] = useState<ICompany>();
  const [baseIds, setBaseIds] = useState<Array<EntityId>>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(boFetchAllCompaniesAsync());
  }, []);

  const isBaseIdInArray = (baseId: EntityId): boolean => {
    return baseIds.some((id) => id === baseId);
  };

  const onChecklistToggle = (baseId: EntityId) => {
    if (isBaseIdInArray(baseId)) {
      const newBaseIds = baseIds.filter((id) => id !== baseId);
      setBaseIds(newBaseIds);
    } else {
      setBaseIds([...baseIds, baseId]);
    }
  };

  const onSelect = (company: ICompany) => {
    boFetchChecklistBasesForCompany(company.id).then((checklists) => {
      setSelectedCompany({ ...company, companyChecklistBases: [...checklists] });
      setBaseIds([]);
    });
  };

  const checklistExistsOnSelectedCompany = (cl: IChecklistBase): boolean => {
    const checklistExists = selectedCompany?.companyChecklistBases?.some((companyChecklist) => companyChecklist.type === cl.type);
    return checklistExists || false;
  };

  const transferChecklists = () => {
    if (selectedCompany) {
      const dto: IBoTransferChecklistsDTO = { companyId: selectedCompany.id, baseIds };
      dispatch(boTransferChecklistBasesToCompanyAsync(dto)).then(() => {
        setIsShown(false);
      });
    }
  };

  const companyAndBaseIdsExist = baseIds && selectedCompany && baseIds.length > 0;

  return (
    <BhModal
      size="3xl"
      header={<h2>Transfer checklists</h2>}
      footer={
        <BhModalFooter cancelButtonText="Close" onCancel={() => setIsShown(false)} confirmButtonText="Transfer" onConfirm={() => transferChecklists()} confirmDisabled={!companyAndBaseIdsExist} />
      }
      setIsShown={setIsShown}
    >
      <div className="px-20 pt-8 pb-40">
        <div className="w-2/3">
          <BhInputWithDropdown initialValue={selectedCompany?.name || ""} values={companiesListForInput} property="name" onSelect={onSelect} label="Select company" />
        </div>
        <div className="mt-6">
          <BhInputLabel>Checklists</BhInputLabel>
          {company.companyChecklistBases.map((cl) => (
            <BOCompanyChecklistBaseListItemWithCheckbox key={cl.id} checklistBase={cl} onChange={onChecklistToggle} isActive={isBaseIdInArray(cl.id)} disabled={checklistExistsOnSelectedCompany(cl)} />
          ))}
        </div>
      </div>
    </BhModal>
  );
};

export default BOCompanyTransferChecklistsModal;
