import React, { FC } from "react";
import ChecklistOption from "@components/checklists/checklist/body/rowTypes/optionField/ChecklistOption";

interface Props {
  onChangeCallback: Function;
  singleChoice: boolean;
  values: Array<any>;
  disabled?: boolean;
}

const ChecklistRowOptionField: FC<Props> = ({ singleChoice, values, onChangeCallback, disabled }) => {
  if (values.length === 0) {
    return null;
  }

  const saveValue = (changedOption: any) => {
    const newValues = values.map((option: any) => {
      if (option.orderNumber === changedOption.orderNumber) {
        return changedOption;
      } else {
        if (singleChoice) {
          return { ...option, ...{ value: false } };
        }
        if (!singleChoice) {
          return option;
        }
      }
    });
    onChangeCallback({ optionFieldValue: { values: newValues } });
  };

  return (
    <div className="flex flex-col pt-1">
      {values.map((option: any) => {
        return <ChecklistOption option={option} callback={saveValue} singleChoice={singleChoice} key={option.orderNumber} disabled={disabled} />;
      })}
    </div>
  );
};

export default ChecklistRowOptionField;
