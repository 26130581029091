import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { v4 as uuidv4 } from "uuid";

export interface IMaruSafPerson {
  firstname: string;
  surname: string;
  companyname: string;
  e_mail: string;
  amet: string;
  telefon: string;
  mobiiltelefon: string;
}

export function convertMaruSafPersonToSimpleValidUserAuthority(maruSafPerson: IMaruSafPerson): ISimpleValidUserAuthority {
  return {
    userEntityId: uuidv4(),
    username: maruSafPerson.e_mail,
    firstName: maruSafPerson.firstname,
    lastName: maruSafPerson.surname
  } as ISimpleValidUserAuthority;
}
