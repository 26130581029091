import React from "react";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { IWorkGroup, WorkGroupType } from "@/model/IWorkGroup";
import { useTranslation } from "react-i18next";
import BhTagListContainer from "@components/tags/BhTagListContainer";

interface Props {
  workGroup: IWorkGroup;
}

const ContactWorkGroup = React.memo<Props>(({ workGroup }) => {
  const { t } = useTranslation();

  const partyTypes: Record<WorkGroupType, string> = {
    [WorkGroupType.SUBCONTRACTOR]: "PARTY.SUBCONTRACTOR",
    [WorkGroupType.CLIENT]: "PARTY.CLIENT"
  };

  return (
    <div className="py-1">
      <div className="my-1">{workGroup.name}</div>
      <BhTagListContainer>{workGroup.type && <BhTag type={BhTagType.TAG}>{t(partyTypes[workGroup.type])}</BhTag>}</BhTagListContainer>
    </div>
  );
});

export default ContactWorkGroup;
