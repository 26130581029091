import React, { FC } from "react";
import { IChecklistBase } from "@/model/checklist/IChecklistBase";
import BhTableRow from "@components/table/BhTableRow";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import BhDeclineButton from "@components/buttons/BhDeclineButton";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";

interface Props {
  checklist: IChecklistBase;
  onRemoveCallback: Function;
}

const BOCompanyChecklistRow: FC<Props> = ({ checklist, onRemoveCallback }) => {
  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteConfirmationModal}
      header={<h2>Delete checklist base</h2>}
      body={<div>Are you sure you want to delete this template and all its versions?</div>}
      confirmationButtonText="Delete"
      cancelButtonText="Cancel"
      handleDelete={onRemoveCallback}
    />
  ));
  return (
    <BhTableRow>
      <td>{checklist.type}</td>
      <td>{checklist.checklistBaseVersions?.length || 0}</td>
      <td>{checklist.activeInProjectsCount}</td>
      <td className="!px-0">
        <div className="flex justify-end">
          <BhDeclineButton icon={faCircleMinus} buttonProps={{ widthClasses: "w-max", onClick: showDeleteConfirmationModal }} />
        </div>
      </td>
    </BhTableRow>
  );
};

export default BOCompanyChecklistRow;
