import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";

export enum BhStatusCellProgressColor {
  BLUE = "BLUE",
  GREEN = "GREEN"
}
interface Props {
  progress: number;
  total: number;
  color: BhStatusCellProgressColor;
}

const BhStatusCellProgress: FC<Props> = ({ progress, total, color }) => {
  const percent = (progress / total) * 100 + "%";
  const style = { "--progress": percent } as React.CSSProperties;

  const colorMap: Record<BhStatusCellProgressColor, string> = {
    [BhStatusCellProgressColor.BLUE]: "circle-progress-blue",
    [BhStatusCellProgressColor.GREEN]: "circle-progress-green"
  };

  return (
    <div className="flex select-none flex-row items-center justify-center">
      <div className={classNames(colorMap[color], "text-12px l-h-16px circle-progress flex h-8 w-8 flex-row items-center justify-center overflow-hidden font-bold")} style={style}>
        <div className="bh-bg-white flex h-7 w-7 flex-row items-center justify-center rounded-full">
          {progress}/{total}
        </div>
      </div>
    </div>
  );
};

export default BhStatusCellProgress;
