import React, { FC, useEffect, useMemo, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import BhSearchInput from "@components/input/BhSearchInput";
import BhSortButton from "@components/sort/BhSortButton";
import {
  makeSelectCompanyById,
  projectsSortSet,
  selectCompanyArchivedProjectsCount,
  selectCompanyInProgressProjectsCount,
  selectProjectsSort,
  selectShowArchivedProjects,
  toggleArchivedProjects
} from "@/app/store/companiesSlice";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import { initialProjectsSort, IProjectsSort } from "@/model/projects/IProjectsSort";
import CompanySettingsProjectList from "@/views/home/company/detail/CompanySettingsProjectList";

interface Props {
  companyId: EntityId;
}

const CompanySettingsProjectsTab: FC<Props> = ({ companyId }) => {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState<string>("");
  const [projectsOrdering, setProjectsOrdering] = useLocalStorage<IProjectsSort>("projectsOrdering", initialProjectsSort);
  const selectCompanyById = useMemo(makeSelectCompanyById, []);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const showArchived = useAppSelector((state) => selectShowArchivedProjects(state));
  const sort = useAppSelector(selectProjectsSort);
  const numOfCompanyProjects = useAppSelector((state) => selectCompanyInProgressProjectsCount(state, companyId));
  const numOfCompanyArchivedProjects = useAppSelector((state) => selectCompanyArchivedProjectsCount(state, companyId));
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(projectsSortSet(projectsOrdering));
  }, []);

  const dropdownMenuValues = [
    { value: "name", translation: t("GLOBAL.ITEM_NAME") },
    { value: "created", translation: t("GLOBAL.DATE") }
  ];

  return (
    <BhScrollableBody>
      <div className="px-44 pt-6 md:px-8">
        <div className="pb-3">
          <div className="flex flex-row items-center">
            <h2 className="bh-text-deep-ocean mb-3 pr-2">{t("HOME.PROJECTS")}</h2>
            <BhBadge classes="mr-0.5" type={BhBadgeType.SUBTLE}>
              <span className="bh-text-deep-ocean-80">
                {t("PROJECT.STATUS.IN_PROGRESS")} {numOfCompanyProjects}
              </span>
            </BhBadge>
            <BhBadge type={BhBadgeType.SUBTLE}>
              <span className="bh-text-deep-ocean-80">
                {t("PROJECT.STATUS.ARCHIVED")} {numOfCompanyArchivedProjects}
              </span>
            </BhBadge>
          </div>
          <div className="max-w-385px w-1/3 md:w-1/2">
            <BhSearchInput property="searchString" initialValue={searchString} onChangeCallback={(val: { searchString: string }) => setSearchString(val.searchString)} />
          </div>
          <div className="my-2 flex h-8 items-center">
            <BhSortButton sort={sort} sortValues={dropdownMenuValues} localStorageSort={projectsOrdering} setSortForLocalStorage={setProjectsOrdering} setSortForStore={projectsSortSet} />
            <div className="inline-flex items-center">
              <p className="ml-3 mr-1.5">{t("HOME.SHOW_ARCHIVED")}</p>
              <BhToggleSwitch value={showArchived} onClickAction={() => dispatch(toggleArchivedProjects())} small={true} />
            </div>
          </div>
        </div>
        {company && <CompanySettingsProjectList companyId={companyId} searchString={searchString} />}
      </div>
    </BhScrollableBody>
  );
};

export default CompanySettingsProjectsTab;
