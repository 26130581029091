import React, { FC, useRef, useState } from "react";
import { IFileEntity, IFileMatch, RevisionFileVersion } from "@/model/files/IFileEntity";
import RevisionModalFileCard from "@/views/home/project/detail/directory/directoryModals/revisionModal/RevisionModalFileCard";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import BhSearchInput from "@components/input/BhSearchInput";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhIconButton from "@components/buttons/BhIconButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { useTranslation } from "react-i18next";
import BhFixedDropdownParent from "@components/dropdown/BhFixedDropdownParent";

interface Props {
  fileEntity: IFileEntity;
  unusedDirectoryFiles: Array<IFileEntity>;
  fileMatchSelected: Function;
}

const RevisionModalUnusedFileCard: FC<Props> = ({ fileEntity, unusedDirectoryFiles, fileMatchSelected }) => {
  const { t } = useTranslation();
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [availableFiles, setAvailableFiles] = useState([] as Array<IFileEntity>);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const closeSearch = () => {
    isSearchActive && setIsSearchActive(false);
    isDropdownOpen && setIsDropdownOpen(false);
  };

  const onSearchChange = (filter: any) => {
    if (filter.name) {
      const filteredFiles = unusedDirectoryFiles.filter((file) => {
        return file.name.toLowerCase().includes(filter.name.toLowerCase());
      });
      if (filteredFiles.length > 0) {
        setAvailableFiles(filteredFiles);
      }
      setIsDropdownOpen(filteredFiles.length > 0);
    } else {
      setIsDropdownOpen(false);
    }
  };

  const onMatchingFileSelect = (selectedMatchingFile: IFileEntity) => {
    const fileMatch = { file: fileEntity, matchingFile: selectedMatchingFile } as IFileMatch;
    fileMatchSelected(fileMatch);
  };

  const activateSearch = () => {
    setIsSearchActive(true);
    setTimeout(() => {
      searchInputRef?.current?.focus();
    }, 100);
  };

  return (
    <div className="flex flex-col">
      <div className="my-1 flex w-full flex-row items-center">
        <div className="w-full">
          <RevisionModalFileCard fileMatch={{ file: fileEntity } as IFileMatch} version={RevisionFileVersion.NEW} isUnusedFileContainer={true} />
        </div>
        <div className="ml-1 w-64">
          <BhTextOnlyButton buttonProps={{ classes: "whitespace-nowrap", onClick: activateSearch }}>{t("REVISIONS.SEARCH")}</BhTextOnlyButton>
        </div>
      </div>
      {isSearchActive && (
        <div className="relative flex flex-row items-center">
          <BhTag classes="bh-bg-pigeon-60 ml-1.5 absolute w-10 -left-14 l-h-18px" type={BhTagType.CUSTOM}>
            <div className="flex w-full flex-row items-center justify-center">{t("GLOBAL.OLD")}</div>
          </BhTag>
          <div className="relative w-full">
            <BhSearchInput property="name" placeholder={t("REVISIONS.SEARCH_PLACEHOLDER") as string} inputClasses="bh-bg-white" onChangeCallback={onSearchChange} inputRef={searchInputRef} />
            {isDropdownOpen && (
              <BhFixedDropdownParent onOutsideClick={() => setIsDropdownOpen(false)}>
                <BhDropdownMenu type={BhDropdownTypeEnum.FILE} values={availableFiles} onSelect={onMatchingFileSelect} numberOfItemsDisplayed={5} customDropdown={true} widthClass={"w-full"} />
              </BhFixedDropdownParent>
            )}
          </div>
          <div className="ml-1 w-64">
            <BhIconButton icon={faXmark} buttonProps={{ onClick: () => closeSearch() }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default RevisionModalUnusedFileCard;
