import React, { FC } from "react";
import { ReactComponent as BhLogoLoading } from "@svg/bauhub-loading-compressed.svg";

const BhLoadingIconContained: FC = () => {
  return (
    <div className="flex h-full w-full flex-row items-center justify-center p-6">
      <BhLogoLoading className="h-10 w-10" />
    </div>
  );
};

export default BhLoadingIconContained;
