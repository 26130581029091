import React, { Dispatch, FC, SetStateAction } from "react";
import { CopyNotAllowedFormBaseTypes, IFormBase } from "@/model/form/IFormBase";
import { FormBaseVersionStatus, IFormBaseVersion } from "@/model/form/IFormBaseVersion";
import BhTableRow from "@components/table/BhTableRow";
import { classNames } from "@/utilities/jsUtilities";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { Link, useNavigate } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectCompanyId, selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useTranslation } from "react-i18next";
import BhTableRowVersion from "@components/table/BhTableRowVersion";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons/faCirclePlus";
import { formatDateTime } from "@/utilities/dateUtility";
import { getUserFullName } from "@/model/IUser";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhIconButton from "@components/buttons/BhIconButton";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons/faEllipsisVertical";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import {
  addFormBasesToProjectsAsync,
  copyFormBaseToProjectAsync,
  removeFormBaseAsync,
  removeFormBasesFromProjectsAsync,
  removeFormBaseVersionAsync,
  saveNewFormBaseVersionAsync,
  selectIsFormBaseActivatedInProjectByBaseAndProjectId
} from "@/app/store/form/formBasesSlice";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons/faPlusCircle";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { IFormBasesToProjectsDTO } from "@/model/form/IFormBasesToProjectsDTO";
import { EntityId } from "@reduxjs/toolkit";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { useModal } from "react-modal-hook";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import BhFormBaseIcon from "@components/form/settings/BhFormBaseIcon";
import BhTooltip from "@components/BhTooltip";
import BhNewTag from "@components/tags/BhNewTag";
import BhLink from "@components/buttons/BhLink";

interface Props {
  formBase: IFormBase;
  formBaseVersion: IFormBaseVersion;
  isLastVersion: boolean;
  toggleShowAllVersions: Function;
  allVersionsVisible: boolean;
  anyVersionInDraft: boolean;
  setFilter: Dispatch<SetStateAction<any>>;
}

const ProjectFormsSettingsListRow: FC<Props> = ({ formBase, formBaseVersion, isLastVersion, toggleShowAllVersions, allVersionsVisible, anyVersionInDraft, setFilter }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const companyId = useAppSelector(selectCurrentProjectCompanyId);
  const navigate = useNavigate();
  const isCompanyBase = !formBase.createdInProjectId;
  const isDraft = formBaseVersion.status === FormBaseVersionStatus.DRAFT;
  const isFormBaseActivatedInProject = useAppSelector((state) => selectIsFormBaseActivatedInProjectByBaseAndProjectId(state, formBase.id, projectId));
  const isBauhubFormBase = formBase.bauhubFormBase;
  const isCustomFormBase = formBase.createdBy === "custom" && !isBauhubFormBase;
  const formDisplayName = isBauhubFormBase || isCustomFormBase ? t(`FORMS.${formBase.type}`) : formBase.type;
  const isCopyAllowed = !CopyNotAllowedFormBaseTypes.some((type) => type === formBase.type);

  const [showDeleteFormBaseConfirmationModal, hideDeleteFormBaseConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteFormBaseConfirmationModal}
      header={<h2>{t("FORM.DELETE.MODAL.HEADER")}</h2>}
      body={<div>{t("FORM.DELETE.MODAL.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={() => {
        deleteFormBase();
        hideDeleteFormBaseConfirmationModal();
      }}
    />
  ));

  const [showDeleteFormBaseVersionConfirmationModal, hideDeleteFormBaseVersionConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteFormBaseVersionConfirmationModal}
      header={<h2>{t("FORM.VERSION.DELETE.MODAL.HEADER")}</h2>}
      body={<div>{t("FORM.VERSION.DELETE.MODAL.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={() => {
        deleteFormBaseVersion();
        hideDeleteFormBaseVersionConfirmationModal();
      }}
    />
  ));

  const [showFormBaseCopyConfirmationModal, hideFormBaseCopyConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideFormBaseCopyConfirmationModal}
      header={<h2>{t("FORM.BASE.COPY.MODAL.HEADER")}</h2>}
      body={<div>{t("FORM.BASE.COPY_TO_PROJECT.MODAL.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.COPY")}
      handleDelete={() => {
        copyFormBaseToProject();
        hideFormBaseCopyConfirmationModal();
      }}
    />
  ));

  if (!formBase || !formBaseVersion) return null;

  const toggleBaseInProject = () => {
    if (isFormBaseActivatedInProject) {
      removeFormBaseFromProjects();
    } else {
      addFormBaseToProjects();
    }
  };

  const addFormBaseToProjects = () => {
    const dto = { formBaseIds: [formBase.id], projectIds: [projectId] } as IFormBasesToProjectsDTO;
    dispatch(addFormBasesToProjectsAsync({ companyId: companyId, basesToProjectsDTO: dto }));
  };

  const removeFormBaseFromProjects = () => {
    const dto = { formBaseIds: [formBase.id], projectIds: [projectId] } as IFormBasesToProjectsDTO;
    dispatch(removeFormBasesFromProjectsAsync({ companyId: companyId, basesToProjectsDTO: dto }));
  };

  const addNewVersion = () => {
    dispatch(saveNewFormBaseVersionAsync({ companyId: companyId, formBaseId: formBase.id })).then((action) => {
      const newFormBaseVersion = action.payload as IFormBaseVersion;
      editVersion(newFormBaseVersion.id);
    });
  };

  const editVersion = (formBaseVersionId: EntityId) => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/form/base/${formBase.id}/version/${formBaseVersionId}`);
  };

  const deleteFormBase = () => {
    dispatch(removeFormBaseAsync({ companyId: companyId, formBaseId: formBase.id }));
  };

  const deleteFormBaseVersion = () => {
    dispatch(removeFormBaseVersionAsync({ companyId: companyId, formBaseId: formBase.id, formBaseVersionId: formBaseVersion.id }));
  };

  const copyFormBaseToProject = () => {
    dispatch(copyFormBaseToProjectAsync({ companyId: companyId, projectId: projectId, formBaseId: formBase.id }));
  };

  const ellipsisDropdownValues: Array<false | { text: string; function: () => void; icon: IconDefinition }> = [
    !isBauhubFormBase &&
      !isCompanyBase &&
      !isDraft && {
        text: t("FORM.BASE.NEW_VERSION"),
        function: addNewVersion,
        icon: faPlusCircle
      },
    isLastVersion &&
      !isDraft &&
      isCopyAllowed && {
        text: t("FORM.BASE.COPY_TO_PROJECT"),
        function: showFormBaseCopyConfirmationModal,
        icon: faCopy
      },
    isLastVersion &&
      !isCompanyBase && {
        text: t("FORM.BASE.DELETE"),
        function: showDeleteFormBaseConfirmationModal,
        icon: faTrash
      },
    !isLastVersion &&
      !isCompanyBase &&
      isDraft && {
        text: t("FORM.BASE.VERSION.DELETE"),
        function: showDeleteFormBaseVersionConfirmationModal,
        icon: faTrash
      }
  ].filter(Boolean);

  const showDropdown = ellipsisDropdownValues && ellipsisDropdownValues.length > 1;

  return (
    <BhTableRow classes={classNames(!isLastVersion && !isDraft && "bh-bg-smoke-50", isDraft && "bh-bg-warning-yellow-10", "border-b bh-border-pigeon-40 group hover:bh-bg-mint-30")}>
      <td>
        <div className={classNames("group flex w-full flex-row items-center", !isLastVersion && "pl-7")}>
          {isBauhubFormBase && <BhFileCardIcon fileEntity={{ id: formBase.id, name: formBase.type, type: FileEntityType.FORM } as IFileEntity} fileCardSize={FileCardSize.LARGE} />}
          {!isBauhubFormBase && <BhFormBaseIcon isCustomFormBase={isCustomFormBase} />}
          <div className="ml-2 flex w-full flex-row items-center">
            {isCompanyBase && (
              <div className="text-14px bh-text-deep-ocean flex flex-col leading-5">
                <>{formDisplayName}</>
                <BhNewTag
                  tagText={t("FORM.COMPANY_TEMPLATE")}
                  onClick={() =>
                    setFilter((prevState: any) => {
                      return { ...prevState, showCompanyTemplatesOnly: !prevState.showCompanyTemplatesOnly };
                    })
                  }
                />
              </div>
            )}
            {!isCompanyBase && !isCustomFormBase && (
              <div className="text-14px bh-text-deep-ocean flex flex-col leading-5">
                <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/form/base/${formBase.id}/version/${formBaseVersion.id}`}>
                  <BhLink>{formDisplayName}</BhLink>
                </Link>
                <BhNewTag
                  backgroundColorClass={"bh-bg-deep-ocean-80"}
                  hoverBackgroundColorClass={"hover:bh-bg-deep-ocean-60"}
                  textColorClass={"bh-text-smoke"}
                  tagText={t("FORM.PROJECT_TEMPLATE")}
                  onClick={() =>
                    setFilter((prevState: any) => {
                      return { ...prevState, showProjectTemplatesOnly: !prevState.showProjectTemplatesOnly };
                    })
                  }
                />
              </div>
            )}
            {isDraft && <BhTag type={BhTagType.MUSTAND} children={t("FORM.STATUS.DRAFT")} classes={"ml-3 h-4.5"} />}
          </div>
        </div>
      </td>
      <td className="bh-text-deep-ocean text-center font-bold">
        {!isCustomFormBase && (
          <BhTableRowVersion
            versionNumber={formBaseVersion.versionNumber}
            disabled={isCompanyBase || !isLastVersion || anyVersionInDraft}
            onClick={toggleShowAllVersions}
            otherVersionsExist={formBaseVersion.versionNumber > 1 && isLastVersion && !isCompanyBase}
            allVersionsVisible={allVersionsVisible}
            isActive={allVersionsVisible && isLastVersion}
            icon={faCirclePlus}
            addNewCallback={addNewVersion}
          />
        )}
      </td>
      <td>
        <div className="flex flex-col">
          <div className="text-14px bh-text-deep-ocean leading-5">
            {isBauhubFormBase && t("FORM_BASE.BAUHUB_TEMPLATE")}
            {isCustomFormBase && t("FORMS.CREATED_BY.CUSTOM_FORM_BASE")}
            {!isBauhubFormBase && !isCustomFormBase && formatDateTime(formBaseVersion.created)}
          </div>
          {formBaseVersion.createdByUserEntity && !isBauhubFormBase && !isCustomFormBase && (
            <div className="text-12px bh-text-deep-ocean-80">{getUserFullName(formBaseVersion.createdByUserEntity)}</div>
          )}
        </div>
      </td>
      <td>
        <BhTooltip body={isCompanyBase ? t("FORM.BASE.DEACTIVATE.DISABLED") : ""}>
          {isLastVersion && !isDraft && (
            <BhToggleSwitch property={"active"} onClickAction={toggleBaseInProject} value={isFormBaseActivatedInProject || isCustomFormBase} disabled={isCompanyBase || isCustomFormBase} />
          )}
        </BhTooltip>
      </td>
      <td>
        {!isCustomFormBase && (
          <>
            {showDropdown ? (
              <BhDropdown
                button={<BhIconButton icon={faEllipsisVertical} />}
                menu={<BhDropdownMenu values={ellipsisDropdownValues} type={BhDropdownTypeEnum.STRING} textProperty="text" />}
                position={BhDropdownPositionEnum.BOTTOM_LEFT}
              />
            ) : (
              <>
                {ellipsisDropdownValues.length !== 0 && (
                  <BhTooltip body={ellipsisDropdownValues[0] ? ellipsisDropdownValues[0].text : ""}>
                    <BhIconButton
                      icon={ellipsisDropdownValues[0] ? ellipsisDropdownValues[0].icon : undefined}
                      buttonProps={{ onClick: ellipsisDropdownValues[0] ? ellipsisDropdownValues[0].function : undefined }}
                    />
                  </BhTooltip>
                )}
              </>
            )}
          </>
        )}
      </td>
    </BhTableRow>
  );
};

export default ProjectFormsSettingsListRow;
