import React, { FC, useEffect } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId, selectMaruProjectModule } from "@/app/store/project/projectSlice";
import { useLocation, useNavigate } from "react-router-dom";
import BhDetailContainer from "@components/detailContainer/BhDetailContainer";
import BhDetailContainerHeader from "@components/detailContainer/BhDetailContainerHeader";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft";
import BhDetailContainerBody from "@components/detailContainer/BhDetailContainerBody";
import { useTranslation } from "react-i18next";
import BhIFrameComponent from "@/views/global/BhIFrameComponent";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  partyId: EntityId;
}

const NewContractFormContainerForOldFE: FC<Props> = ({ partyId }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const location = useLocation();
  const navigate = useNavigate();
  const formType = location.state;
  const isMaruProject = useAppSelector(selectMaruProjectModule);
  const isBauhubProject = !isMaruProject;

  useEffect(() => {
    if (!formType) {
      navigate(`/project/${projectId}/contracts/${partyId}`);
    }
    window.history.replaceState({}, document.title);
  }, []);

  return (
    <BhDetailContainer>
      <BhDetailContainerHeader>
        <div className="flex w-full flex-row justify-between">
          <div className="flex flex-1 items-center">
            <BhIconButton icon={faArrowLeft} buttonProps={{ onClick: () => navigate(-1) }} />
            <span className="l-h-24px text-18px pl-2">
              <span className="pr-2">{t("GLOBAL.CREATE_FORM")}</span>
              <strong>{formType ? t("FORMS." + formType) : ""}</strong>
            </span>
          </div>
        </div>
      </BhDetailContainerHeader>
      <BhDetailContainerBody>
        <>
          {isBauhubProject && <BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/parties/${partyId}/contract/react/form/new/${formType}/react`} />}
          {isMaruProject && <BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/parties/${partyId}/mr/contract/react/form/new/${formType}/react`} />}
        </>
      </BhDetailContainerBody>
    </BhDetailContainer>
  );
};

export default NewContractFormContainerForOldFE;
