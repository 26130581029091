import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { IChecklist } from "@/model/checklist/IChecklist";
import { checklistFilterInitialState, checklistStatisticsFilterInitialState, IChecklistFilter, IChecklistStatisticsFilter } from "@/model/checklist/IChecklistFilter";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import {
  archiveOrActivateChecklists,
  completeChecklistRound,
  fetchChecklistsForUser,
  fetchChecklistWithInfo,
  fetchUniqueProjectChecklistTags,
  removeChecklistRoundBaseFile,
  removeUserFromChecklist,
  saveChecklist,
  saveChecklistForLatestBaseVersion,
  saveChecklistRound,
  saveChecklistRoundBaseFile,
  saveChecklistTag,
  saveUserToChecklist,
  startNewChecklistRoundFromLatestRound
} from "@/api/checklist/checklistAPI";
import { ChecklistBaseCategory, IChecklistBase } from "@/model/checklist/IChecklistBase";
import { IChecklistSelection } from "@/model/checklist/IChecklistSelection";
import { ChecklistRoundStatus, IChecklistRound } from "@/model/checklist/IChecklistRound";
import {
  copyChecklistBaseToCompany,
  copyChecklistBaseToProject,
  deleteChecklistBase,
  deleteChecklistBaseVersion,
  fetchChecklistBasesForUser,
  fetchChecklistBaseVersions,
  fetchCompanyChecklistBase,
  fetchCompanyChecklistBases,
  fetchCompanyChecklistBasesForProject,
  publishChecklistBaseVersion,
  renameChecklistBase,
  saveChecklistBase,
  saveChecklistBaseVersion,
  saveNewChecklistBaseVersion,
  saveNewCompanyChecklistBase
} from "@/api/checklist/checklistBaseAPI";
import { IChecklistParticipant } from "@/model/checklist/IChecklistParticipant";
import { IChecklistTag } from "@/model/checklist/IChecklistTag";
import { selectCurrentUser, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { IUserAuthority } from "@/model/IUserAuthority";
import { IContainerCreateRequest } from "@/model/container/IContainerCreateRequest";
import { createContainer, fetchFileEntityWithContainerInfo } from "@/api/fileAPI";
import { IAsyncJob } from "@/model/IAsyncJob";
import { asyncJobAdded } from "@/app/store/asyncJobsSlice";
import { fetchProjectChecklistBasesForStatistics } from "@/api/checklist/checklistStatisticsAPI";
import {
  addAllProjectBasesToWorkGroup,
  addChecklistBasesToProjects,
  fetchProjectChecklistBasesForCompany,
  fetchProjectChecklistBasesForCompanyProject,
  fetchWorkgroupToChecklistBasesForProject,
  removeChecklistBasesFromProjects,
  saveChecklistBaseToAllWorkGroups,
  saveWorkGroupToChecklistBaseAuthority
} from "@/api/checklist/checklistSettingsAPI";
import { IWorkGroupToChecklistBase } from "@/model/checklist/IWorkGroupToChecklistBase";
import { IBasesToProjectsDTO } from "@/model/checklist/IBasesToProjectsDTO";
import { IProjectChecklistBase } from "@/model/checklist/IProjectChecklistBase";
import { removeDuplicates } from "@/utilities/jsUtilities";
import { ChecklistBaseVersionStatus, IChecklistBaseVersion } from "@/model/checklist/IChecklistBaseVersion";
import { ChecklistRowType } from "@/model/checklist/IChecklistBaseVersionRow";
import { IProject, ProjectStatus } from "@/model/IProject";

export const checklistsAdapter = createEntityAdapter<IChecklist>();

export interface IChecklistsState {
  status: BhStateStatusType;
  filter: IChecklistFilter;
  statisticsFilter: IChecklistStatisticsFilter;
  userChecklistBases: Array<IChecklistBase>;
  companyChecklistBases: Array<IChecklistBase>;
  selection: IChecklistSelection;
  workgroupToChecklistBases: Array<IWorkGroupToChecklistBase>;
  activatedBasesInCompanyProjects: Array<IProjectChecklistBase>;
}

export const checklistsInitialState = checklistsAdapter.getInitialState<IChecklistsState>({
  status: BhStateStatusType.INITIAL,
  filter: checklistFilterInitialState,
  statisticsFilter: checklistStatisticsFilterInitialState,
  selection: { selectedRoundIds: [] as Array<EntityId>, selectedChecklistIds: [] as Array<EntityId>, selectedOlderRoundIds: [] as Array<EntityId> } as IChecklistSelection,
  userChecklistBases: [] as Array<IChecklistBase>,
  companyChecklistBases: [] as Array<IChecklistBase>,
  workgroupToChecklistBases: [] as Array<IWorkGroupToChecklistBase>,
  activatedBasesInCompanyProjects: [] as Array<IProjectChecklistBase>
});

// -------------------------------- CHECKLIST BASE --------------------------------
export const fetchChecklistBasesForUserAsync = createAsyncThunk("checklist/bases", async (dto: { projectId: EntityId; category: ChecklistBaseCategory }) => {
  return fetchChecklistBasesForUser(dto.projectId, dto.category);
});

export const fetchCompanyChecklistBasesAsync = createAsyncThunk("checklist/company/bases", async (companyId: EntityId) => {
  return fetchCompanyChecklistBases(companyId);
});

export const fetchCompanyChecklistBasesForProjectAsync = createAsyncThunk("checklist/company/bases/project", async (dto: { companyId: EntityId; projectId: EntityId }) => {
  return fetchCompanyChecklistBasesForProject(dto.companyId, dto.projectId);
});

export const saveNewCompanyChecklistBaseAsync = createAsyncThunk("checklist/company/base/save", async (dto: { checklistBase: IChecklistBase; companyId: EntityId }) => {
  return saveNewCompanyChecklistBase(dto.checklistBase, dto.companyId);
});

export const deleteChecklistBaseAsync = createAsyncThunk("checklist/base/delete", async (dto: { companyId: EntityId; checklistBaseId: EntityId }) => {
  return deleteChecklistBase(dto.companyId, dto.checklistBaseId);
});

export const deleteChecklistBaseVersionAsync = createAsyncThunk("checklist/base/version/delete", async (dto: { companyId: EntityId; checklistBaseId: EntityId; checklistBaseVersionId: EntityId }) => {
  return deleteChecklistBaseVersion(dto.companyId, dto.checklistBaseId, dto.checklistBaseVersionId);
});

export const saveNewChecklistBaseVersionAsync = createAsyncThunk("checklist/base/version/new/save", async (dto: { companyId: EntityId; checklistBaseId: EntityId }) => {
  return saveNewChecklistBaseVersion(dto.companyId, dto.checklistBaseId);
});

export const fetchCompanyChecklistBaseAsync = createAsyncThunk("checklist/base/fetch", async (dto: { companyId: EntityId; checklistBaseId: EntityId }) => {
  return fetchCompanyChecklistBase(dto.companyId, dto.checklistBaseId);
});

export const fetchChecklistBaseVersionsAsync = createAsyncThunk("checklist/base/versions/fetch", async (dto: { companyId: EntityId; checklistBaseId: EntityId }) => {
  return fetchChecklistBaseVersions(dto.companyId, dto.checklistBaseId);
});

export const renameChecklistBaseAsync = createAsyncThunk("checklist/base/rename", async (dto: { companyId: EntityId; checklistBase: IChecklistBase }) => {
  return renameChecklistBase(dto.companyId, dto.checklistBase);
});

export const changeChecklistBaseLanguageAsync = createAsyncThunk("checklist/base/language", async (dto: { companyId: EntityId; checklistBase: IChecklistBase }) => {
  return saveChecklistBase(dto.companyId, dto.checklistBase);
});

export const changeChecklistBaseCategoryAsync = createAsyncThunk("checklist/base/category", async (dto: { companyId: EntityId; checklistBase: IChecklistBase }) => {
  return saveChecklistBase(dto.companyId, dto.checklistBase);
});

export const addChecklistBaseToNewProjectsAsync = createAsyncThunk("checklist/base/projects", async (dto: { companyId: EntityId; checklistBase: IChecklistBase }) => {
  return saveChecklistBase(dto.companyId, dto.checklistBase);
});

export const saveChecklistBaseVersionAsync = createAsyncThunk("checklist/base/version/save", async (dto: { companyId: EntityId; checklistBaseVersion: IChecklistBaseVersion }) => {
  return saveChecklistBaseVersion(dto.companyId, dto.checklistBaseVersion);
});

export const publishChecklistBaseVersionAsync = createAsyncThunk(
  "checklist/base/version/publish",
  async (dto: { companyId: EntityId; checklistBaseId: EntityId; checklistBaseVersionId: EntityId }) => {
    return publishChecklistBaseVersion(dto.companyId, dto.checklistBaseId, dto.checklistBaseVersionId);
  }
);

export const copyChecklistBaseToCompanyAsync = createAsyncThunk("checklist/base/company/copy", async (dto: { companyId: EntityId; checklistBaseId: EntityId }) => {
  return copyChecklistBaseToCompany(dto.companyId, dto.checklistBaseId);
});

export const copyChecklistBaseToProjectAsync = createAsyncThunk("checklist/base/project/copy", async (dto: { companyId: EntityId; projectId: EntityId; checklistBaseId: EntityId }) => {
  return copyChecklistBaseToProject(dto.companyId, dto.projectId, dto.checklistBaseId);
});

// -------------------------------- CHECKLIST --------------------------------
export const fetchChecklistsForUserAsync = createAsyncThunk("checklist/list", async (dto: { projectId: EntityId; filter: IChecklistFilter }) => {
  const newFilter = { ...dto.filter, ...{ filterDropdownValues: undefined, toggleFieldValue: undefined, total: undefined } };
  return fetchChecklistsForUser(dto.projectId, newFilter);
});

export const saveChecklistAsync = createAsyncThunk("checklist/save", async (checklist: IChecklist) => {
  return saveChecklist(checklist);
});

export const saveChecklistForLatestBaseVersionAsync = createAsyncThunk("checklist/new", async (dto: { projectId: EntityId; checklistBaseId: EntityId }) => {
  return saveChecklistForLatestBaseVersion(dto.projectId, dto.checklistBaseId);
});

export const archiveOrActivateChecklistsAsync = createAsyncThunk("checklist/archive", async (checklistIds: Array<EntityId>) => {
  return archiveOrActivateChecklists(checklistIds);
});

export const fetchChecklistWithInfoAsync = createAsyncThunk("checklist/withInfo", async (checklistId: EntityId) => {
  return fetchChecklistWithInfo(checklistId);
});

export const saveChecklistRoundAsync = createAsyncThunk("checklist/round/save", async (checklistRound: IChecklistRound) => {
  return saveChecklistRound(checklistRound);
});

export const completeChecklistRoundAsync = createAsyncThunk("checklist/round/complete", async (checklistRoundId: EntityId) => {
  return completeChecklistRound(checklistRoundId);
});

export const startNewChecklistRoundFromLatestRoundAsync = createAsyncThunk("checklist/round/start/new", async (latestRoundId: EntityId) => {
  return startNewChecklistRoundFromLatestRound(latestRoundId);
});

export const saveUserToChecklistAsync = createAsyncThunk("checklist/user/add", async (participant: IChecklistParticipant) => {
  return saveUserToChecklist(participant);
});

export const removeUserFromChecklistAsync = createAsyncThunk("checklist/user/remove", async (participant: IChecklistParticipant) => {
  return removeUserFromChecklist(participant);
});

export const saveChecklistTagAsync = createAsyncThunk("checklist/tag/save", async (tag: IChecklistTag) => {
  return saveChecklistTag(tag);
});

export const fetchUniqueProjectChecklistTagsAsync = createAsyncThunk("checklist/project/tags", async (projectId: EntityId) => {
  return fetchUniqueProjectChecklistTags(projectId);
});

export const calculateCheckpointsAndSaveRoundAsync = createAsyncThunk("checklist/checkpoints", async (checklistRoundId: EntityId, { getState }) => {
  const state: RootState = getState() as RootState;
  const checkpointRows = Object.values(state.checklistRows.entities).filter((row) => {
    return row && row.baseRow && row.baseRow.checkpoint && !row.extraRow;
  });
  const tomRows = Object.values(state.checklistRows.entities).filter((row) => {
    return row && row.baseRow && row.baseRow.checklistRowType === ChecklistRowType.PLUSMINUS;
  });

  const checkpointValue = {
    yes: checkpointRows.filter((row) => row && row.fieldValue === "YES").length,
    no: checkpointRows.filter((row) => row && row.fieldValue === "NO").length,
    na: checkpointRows.filter((row) => row && row.fieldValue === "N/A").length,
    ua: checkpointRows.filter((row) => row && !row.fieldValue).length,
    total: checkpointRows.length,
    tomOk: tomRows.reduce((sum, current) => sum + (current?.plusMinusFieldValue.okTotal ?? 0), 0),
    tomNotOk: tomRows.reduce((sum, current) => sum + (current?.plusMinusFieldValue.notOkTotal ?? 0), 0)
  };
  const checklistRound = Object.values(state.checklists.entities)
    .flatMap((checklist) => checklist && checklist.checklistRounds)
    .find((round) => round && round.id === checklistRoundId);
  if (checklistRound) {
    const roundToSave = { ...checklistRound, ...{ checkpointValue: checkpointValue } };
    return saveChecklistRound(roundToSave);
  }
  return {} as IChecklistRound;
});

export const saveChecklistRoundBaseFileAsync = createAsyncThunk("checklist/base/file/save", async (dto: { checklistRoundId: EntityId; fileId: number; isUpload: boolean }) => {
  return saveChecklistRoundBaseFile(dto.checklistRoundId, dto.fileId, dto.isUpload);
});

export const removeChecklistRoundBaseFileAsync = createAsyncThunk("checklist/base/file/remove", async (checklistRoundId: EntityId) => {
  return removeChecklistRoundBaseFile(checklistRoundId);
});

export const createContainerForChecklistAsync = createAsyncThunk("checklist/container/create", async (dto: { projectId: EntityId; requestDTO: IContainerCreateRequest }, { dispatch }) => {
  const result = await createContainer(dto.projectId, dto.requestDTO);
  const asyncJob = { id: result.jobId, status: result.status } as IAsyncJob;
  dispatch(asyncJobAdded(asyncJob));
  return result;
});

export const fetchChecklistContainerFileEntityAsync = createAsyncThunk("checklist/container/fetch", async (fileEntityId: EntityId) => {
  return fetchFileEntityWithContainerInfo(fileEntityId);
});

// -------------------------------- CHECKLIST MANAGEMENT --------------------------------

export const fetchWorkgroupToChecklistBasesForProjectAsync = createAsyncThunk("checklist/base/auths", async (projectId: EntityId) => {
  return fetchWorkgroupToChecklistBasesForProject(projectId);
});

export const fetchProjectChecklistBasesForCompanyAsync = createAsyncThunk("checklist/projectChecklistBases/fetch", async (companyId: EntityId) => {
  return fetchProjectChecklistBasesForCompany(companyId);
});

export const fetchProjectChecklistBasesForCompanyProjectAsync = createAsyncThunk("checklist/project/projectChecklistBases/fetch", async (dto: { companyId: EntityId; projectId: EntityId }) => {
  return fetchProjectChecklistBasesForCompanyProject(dto.companyId, dto.projectId);
});

export const addChecklistBasesToProjectsAsync = createAsyncThunk("checklist/bases/add", async (dto: { companyId: EntityId; basesToProjectsDTO: IBasesToProjectsDTO }) => {
  return addChecklistBasesToProjects(dto.companyId, dto.basesToProjectsDTO);
});

export const removeChecklistBasesFromProjectsAsync = createAsyncThunk("checklist/bases/remove", async (dto: { companyId: EntityId; basesToProjectsDTO: IBasesToProjectsDTO }) => {
  return removeChecklistBasesFromProjects(dto.companyId, dto.basesToProjectsDTO);
});

export const saveWorkGroupToChecklistBaseAuthorityAsync = createAsyncThunk("checklist/base/auth/save", async (dto: { projectId: EntityId; authority: IWorkGroupToChecklistBase }) => {
  return saveWorkGroupToChecklistBaseAuthority(dto.projectId, dto.authority);
});

export const saveChecklistBaseToAllWorkGroupsAsync = createAsyncThunk("checklist/base/auth/all", async (dto: { projectId: EntityId; checklistBaseId: EntityId; removeBase: boolean }) => {
  return saveChecklistBaseToAllWorkGroups(dto.projectId, dto.checklistBaseId, dto.removeBase);
});

export const addAllProjectBasesToWorkGroupAsync = createAsyncThunk("checklist/bases/wg/all", async (dto: { projectId: EntityId; workGroupId: EntityId; removeBase: boolean }) => {
  return addAllProjectBasesToWorkGroup(dto.projectId, dto.workGroupId, dto.removeBase);
});

// -------------------------------- CHECKLIST STATISTICS --------------------------------
export const fetchChecklistBasesForStatisticsAsync = createAsyncThunk("checklist/bases/statistics", async (projectId: EntityId) => {
  return fetchProjectChecklistBasesForStatistics(projectId);
});

const checklistsSlice = createSlice({
  name: "checklists",
  initialState: checklistsInitialState,
  reducers: {
    checklistsRemoved: checklistsAdapter.removeAll,
    checklistAdded: checklistsAdapter.addOne,
    checklistModified: checklistsAdapter.upsertOne,
    checklistsModified: checklistsAdapter.upsertMany,
    setChecklistFilter: (state, action: PayloadAction<{ filter: IChecklistFilter }>) => {
      state.filter = action.payload.filter;
    },
    resetChecklistFilter: (state) => {
      state.filter = checklistFilterInitialState;
    },
    checklistStatisticsFilterSet: (state, action: PayloadAction<IChecklistStatisticsFilter>) => {
      state.statisticsFilter = action.payload;
    },
    resetChecklistSelection: (state) => {
      state.selection.selectedRoundIds = [];
      state.selection.selectedChecklistIds = [];
      state.selection.selectedOlderRoundIds = [];
    },
    toggleChecklistRoundsVisible: (state, action: PayloadAction<{ checklistId: EntityId }>) => {
      let checklist = state.entities[action.payload.checklistId];
      if (checklist) {
        checklist.showAllRounds = !checklist.showAllRounds;
      }
    },
    toggleChecklistRoundSelection: (state, action: PayloadAction<{ checklistId: EntityId; checklistRoundId: EntityId }>) => {
      const checklist = state.entities[action.payload.checklistId];

      if (!checklist || !checklist.checklistRounds) return;

      const lastRoundId = checklist.checklistRounds.reduce((max, round) => (max.roundNumber > round.roundNumber ? max : round)).id;
      const checklistRound = checklist.checklistRounds.find((round) => round.id === action.payload.checklistRoundId);

      if (!checklistRound) return;

      const wasChecklistRoundAlreadySelected = state.selection.selectedRoundIds.includes(action.payload.checklistRoundId);
      const isLatestRound = checklistRound.id === lastRoundId;

      const addToSelectedRoundIds = () => state.selection.selectedRoundIds.push(action.payload.checklistRoundId);
      const removeFromSelectedRoundIds = () => {
        state.selection.selectedRoundIds = state.selection.selectedRoundIds.filter((id) => checklistRound && id !== checklistRound.id);
      };
      const addToSelectedChecklistIds = () => {
        state.selection.selectedChecklistIds.push(action.payload.checklistId);
      };
      const removeFromSelectedChecklistIds = () => {
        state.selection.selectedChecklistIds = state.selection.selectedChecklistIds.filter((id) => id !== action.payload.checklistId);
      };
      const addToOlderRoundIds = () => state.selection.selectedOlderRoundIds.push(action.payload.checklistRoundId);
      const removeFromOlderRoundIds = () => {
        state.selection.selectedOlderRoundIds = state.selection.selectedOlderRoundIds.filter((id) => id !== action.payload.checklistRoundId);
      };

      if (wasChecklistRoundAlreadySelected) {
        removeFromSelectedRoundIds();
        isLatestRound ? removeFromSelectedChecklistIds() : removeFromOlderRoundIds();
      } else {
        addToSelectedRoundIds();
        isLatestRound ? addToSelectedChecklistIds() : addToOlderRoundIds();
      }
    },
    toggleAllLastRoundsOnPageChecked: (state, action: PayloadAction<boolean>) => {
      const checklists = Object.values(state.entities);
      const allChecklistsRounds = checklists.flatMap((checklist) => checklist?.checklistRounds || []);
      const allChecklistsRoundsIds = allChecklistsRounds.map((round) => round.id);
      const allSelected = action.payload;

      const addToSelectedRoundIds = (id: EntityId) => {
        state.selection.selectedRoundIds.push(id);
      };
      const removeFromSelectedRoundIds = () => {
        state.selection.selectedRoundIds = state.selection.selectedRoundIds.filter((selectedId) => !allChecklistsRoundsIds.some((id) => id === selectedId));
      };
      const addToSelectedChecklistIds = (id: EntityId) => {
        state.selection.selectedChecklistIds.push(id);
      };
      const removeFromSelectedChecklistIds = () => {
        state.selection.selectedChecklistIds = state.selection.selectedChecklistIds.filter((selectedId) => !checklists.some((checklist) => checklist?.id === selectedId));
      };
      const removeFromOlderRoundIds = () => {
        state.selection.selectedOlderRoundIds = state.selection.selectedOlderRoundIds.filter((selectedId) => !allChecklistsRoundsIds.some((id) => id === selectedId));
      };

      checklists.forEach((checklist) => {
        if (!checklist || !checklist.checklistRounds) return;

        const lastRoundId = checklist.checklistRounds.reduce((max, round) => (max.roundNumber > round.roundNumber ? max : round)).id;
        const wasChecklistLastRoundAlreadySelected = state.selection.selectedRoundIds.includes(lastRoundId);

        if (allSelected) {
          if (!wasChecklistLastRoundAlreadySelected) {
            addToSelectedRoundIds(lastRoundId);
            addToSelectedChecklistIds(checklist.id);
          }
        } else {
          removeFromSelectedRoundIds();
          removeFromSelectedChecklistIds();
          removeFromOlderRoundIds();
        }
      });
    }
  },
  extraReducers: (builder) => {
    builder
      // -------------------------------- CHECKLIST BASE --------------------------------
      .addCase(fetchChecklistBasesForUserAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchChecklistBasesForUserAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.userChecklistBases = action.payload;
      })
      .addCase(fetchCompanyChecklistBasesAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchCompanyChecklistBasesAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.companyChecklistBases = action.payload;
      })
      .addCase(fetchCompanyChecklistBasesForProjectAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchCompanyChecklistBasesForProjectAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.companyChecklistBases = action.payload;
      })
      .addCase(saveNewCompanyChecklistBaseAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveNewCompanyChecklistBaseAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.companyChecklistBases = [...state.companyChecklistBases, action.payload] as Array<IChecklistBase>;
      })
      .addCase(deleteChecklistBaseAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(deleteChecklistBaseAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.companyChecklistBases = state.companyChecklistBases.filter((ccb) => ccb.id !== action.payload.id);
      })
      .addCase(deleteChecklistBaseVersionAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(deleteChecklistBaseVersionAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const checklistBase = state.companyChecklistBases.find((cb) => cb.id === action.payload.checklistBaseId);
        if (checklistBase && checklistBase.checklistBaseVersions) {
          checklistBase.checklistBaseVersions = checklistBase.checklistBaseVersions.filter((cbv) => cbv.id !== action.payload.id);
        }
      })
      .addCase(saveNewChecklistBaseVersionAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveNewChecklistBaseVersionAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const checklistBase = state.companyChecklistBases.find((cb) => cb.id === action.payload.checklistBaseId);
        if (checklistBase) {
          const existingBaseVersions = checklistBase.checklistBaseVersions || [];
          checklistBase.checklistBaseVersions = [...existingBaseVersions, action.payload];
        }
      })
      .addCase(fetchCompanyChecklistBaseAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchCompanyChecklistBaseAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.companyChecklistBases = state.companyChecklistBases.filter((ccb) => ccb.id !== action.payload.id);
        state.companyChecklistBases = [...state.companyChecklistBases, action.payload];
      })
      .addCase(fetchChecklistBaseVersionsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchChecklistBaseVersionsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const firstBaseVersion = action.payload[0];
        const companyChecklistBase = state.companyChecklistBases.find((ccb) => ccb.id === firstBaseVersion.checklistBaseId);
        if (companyChecklistBase) {
          companyChecklistBase.checklistBaseVersions = action.payload;
        }
      })
      .addCase(renameChecklistBaseAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(renameChecklistBaseAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const companyChecklistBase = state.companyChecklistBases.find((ccb) => ccb.id === action.payload.id);
        if (companyChecklistBase) {
          companyChecklistBase.type = action.payload.type;
        }
      })
      .addCase(changeChecklistBaseLanguageAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(changeChecklistBaseLanguageAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const companyChecklistBase = state.companyChecklistBases.find((ccb) => ccb.id === action.payload.id);
        if (companyChecklistBase) {
          companyChecklistBase.language = action.payload.language;
        }
      })
      .addCase(changeChecklistBaseCategoryAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(changeChecklistBaseCategoryAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const companyChecklistBase = state.companyChecklistBases.find((ccb) => ccb.id === action.payload.id);
        if (companyChecklistBase) {
          companyChecklistBase.category = action.payload.category;
        }
      })
      .addCase(addChecklistBaseToNewProjectsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(addChecklistBaseToNewProjectsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const companyChecklistBase = state.companyChecklistBases.find((ccb) => ccb.id === action.payload.id);
        if (companyChecklistBase) {
          companyChecklistBase.addToNewProjects = action.payload.addToNewProjects;
        }
      })
      .addCase(saveChecklistBaseVersionAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveChecklistBaseVersionAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const companyChecklistBase = state.companyChecklistBases.find((ccb) => ccb.id === action.payload.checklistBaseId);
        if (companyChecklistBase) {
          companyChecklistBase.checklistBaseVersions = companyChecklistBase.checklistBaseVersions.map((cbv) => {
            if (cbv.id === action.payload.id) {
              return action.payload;
            }
            return cbv;
          });
        }
      })
      .addCase(publishChecklistBaseVersionAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(publishChecklistBaseVersionAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const companyChecklistBase = state.companyChecklistBases.find((ccb) => ccb.id === action.payload.checklistBaseId);
        if (companyChecklistBase) {
          companyChecklistBase.checklistBaseVersions = companyChecklistBase.checklistBaseVersions.map((cbv) => {
            if (cbv.id === action.payload.id) {
              return { ...cbv, status: action.payload.status };
            }
            return cbv;
          });
        }
      })
      // -------------------------------- CHECKLIST --------------------------------
      .addCase(fetchChecklistsForUserAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchChecklistsForUserAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.filter = {
          ...state.filter,
          ...{
            page: action.payload.page,
            limit: action.payload.limit,
            total: action.payload.total,
            filterDropdownValues: { types: action.payload.checklistTypes, creators: action.payload.checklistCreators, tags: action.payload.checklistTags }
          }
        };
        checklistsAdapter.setAll(state, action.payload.list);

        action.payload.list.forEach((checklist) => {
          if (!checklist || !checklist.checklistRounds) return;
          const maxRound = checklist.checklistRounds.reduce((max, round) => {
            return max.roundNumber > round.roundNumber ? max : round;
          });
          checklist.checklistRounds.forEach((round) => {
            const isOlderRound = round.roundNumber < maxRound.roundNumber;
            const isSelected = state.selection.selectedRoundIds.includes(round.id);
            if (isOlderRound && isSelected && !checklist.showAllRounds) checklist.showAllRounds = true;
          });
        });
      })
      .addCase(saveChecklistAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveChecklistAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        checklistsAdapter.upsertOne(state, action.payload);
      })
      .addCase(saveChecklistForLatestBaseVersionAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveChecklistForLatestBaseVersionAsync.fulfilled, (state) => {
        state.status = BhStateStatusType.SUCCESS;
      })
      .addCase(archiveOrActivateChecklistsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(archiveOrActivateChecklistsAsync.fulfilled, (state) => {
        state.status = BhStateStatusType.SUCCESS;
      })
      .addCase(fetchChecklistWithInfoAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchChecklistWithInfoAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const existingChecklist = state.entities[action.payload.id];
        checklistsAdapter.upsertOne(state, existingChecklist ? { ...action.payload, ...{ checklistBaseVersion: existingChecklist.checklistBaseVersion } } : action.payload);
      })
      .addCase(saveChecklistRoundAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveChecklistRoundAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const checklist = state.entities[action.payload.checklistId];
        if (checklist && checklist.checklistRounds) {
          checklist.checklistRounds = checklist.checklistRounds.map((round) => {
            if (round.id === action.payload.id) {
              return { ...round, ...action.payload };
            }
            return round;
          });
        }
      })
      .addCase(completeChecklistRoundAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(completeChecklistRoundAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const checklist = state.entities[action.payload.checklistId];
        if (checklist && checklist.checklistRounds) {
          checklist.checklistRounds = checklist.checklistRounds.map((round) => {
            if (round.id === action.payload.id) {
              return { ...round, ...action.payload };
            }
            return round;
          });
        }
      })
      .addCase(startNewChecklistRoundFromLatestRoundAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(startNewChecklistRoundFromLatestRoundAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const checklist = state.entities[action.payload.checklistId];
        if (checklist && checklist.checklistRounds) {
          checklist.checklistRounds = [...checklist.checklistRounds, action.payload];
        }
      })
      .addCase(calculateCheckpointsAndSaveRoundAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(calculateCheckpointsAndSaveRoundAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const checklist = state.entities[action.payload.checklistId];
        if (checklist && checklist.checklistRounds) {
          checklist.checklistRounds = checklist.checklistRounds.map((round) => {
            if (round.id === action.payload.id) {
              return action.payload;
            }
            return round;
          });
        }
      })
      .addCase(saveUserToChecklistAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveUserToChecklistAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const checklist = state.entities[action.payload.checklistId];
        if (checklist) {
          checklist.checklistParticipants = checklist.checklistParticipants || [];
          checklist.checklistParticipants = [...checklist.checklistParticipants, action.payload];
        }
      })
      .addCase(removeUserFromChecklistAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(removeUserFromChecklistAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const checklist = state.entities[action.payload.checklistId];
        if (checklist && checklist.checklistParticipants) {
          checklist.checklistParticipants = checklist.checklistParticipants.filter((p) => {
            return p.id !== action.payload.id;
          });
        }
      })
      .addCase(saveChecklistTagAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveChecklistTagAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const checklist = state.entities[action.payload.checklistId];
        if (checklist) {
          if (action.payload.deleted) {
            checklist.checklistTags = checklist.checklistTags.filter((tag) => tag.id !== action.payload.id);
          } else {
            checklist.checklistTags = checklist.checklistTags || [];
            checklist.checklistTags = [...checklist.checklistTags, action.payload];
          }
        }
      })
      .addCase(fetchUniqueProjectChecklistTagsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchUniqueProjectChecklistTagsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        if (state.filter.filterDropdownValues) {
          state.filter.filterDropdownValues.tags = action.payload;
        }
      })
      .addCase(saveChecklistRoundBaseFileAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveChecklistRoundBaseFileAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const checklist = Object.values(state.entities).find((cl) => {
          return cl && cl.checklistRounds && cl.checklistRounds.some((r) => r.id === action.payload.resourceId);
        });
        if (checklist) {
          checklist.checklistRounds =
            checklist.checklistRounds &&
            checklist.checklistRounds.map((round) => {
              if (round.id === action.payload.resourceId) {
                return { ...round, ...{ baseFileEntityId: action.payload.id } };
              }
              return round;
            });
        }
      })
      .addCase(removeChecklistRoundBaseFileAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(removeChecklistRoundBaseFileAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const checklist = Object.values(state.entities).find((cl) => {
          return cl && cl.checklistRounds && cl.checklistRounds.some((r) => r.id === action.payload.resourceId);
        });
        if (checklist) {
          let round = checklist.checklistRounds && checklist.checklistRounds.find((r) => r.id === action.payload.resourceId);
          if (round) {
            delete round.baseFileEntityId;
          }
        }
      })
      .addCase(fetchChecklistContainerFileEntityAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchChecklistContainerFileEntityAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const file = action.payload;
        const checklistRound = Object.values(state.entities)
          .flatMap((checklist) => checklist && checklist.checklistRounds)
          .find((round) => round && round.id === file.resourceId);
        if (checklistRound) {
          checklistRound.containerId = file.id;
          checklistRound.digiDocContainer = file;
        }
      })
      .addCase(copyChecklistBaseToCompanyAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(copyChecklistBaseToCompanyAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.companyChecklistBases = [...state.companyChecklistBases, action.payload];
      })
      .addCase(copyChecklistBaseToProjectAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(copyChecklistBaseToProjectAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.companyChecklistBases = [...state.companyChecklistBases, action.payload];
      })
      // -------------------------------- CHECKLIST STATISTICS --------------------------------
      .addCase(fetchChecklistBasesForStatisticsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchChecklistBasesForStatisticsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.companyChecklistBases = action.payload;
      })
      // -------------------------------- CHECKLIST MANAGEMENT --------------------------------
      .addCase(fetchWorkgroupToChecklistBasesForProjectAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchWorkgroupToChecklistBasesForProjectAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.workgroupToChecklistBases = action.payload;
      })
      .addCase(fetchProjectChecklistBasesForCompanyAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchProjectChecklistBasesForCompanyAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.activatedBasesInCompanyProjects = action.payload;
      })
      .addCase(fetchProjectChecklistBasesForCompanyProjectAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchProjectChecklistBasesForCompanyProjectAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.activatedBasesInCompanyProjects = action.payload;
      })
      .addCase(addChecklistBasesToProjectsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(addChecklistBasesToProjectsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.activatedBasesInCompanyProjects = [...state.activatedBasesInCompanyProjects, ...action.payload];
      })
      .addCase(removeChecklistBasesFromProjectsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(removeChecklistBasesFromProjectsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.activatedBasesInCompanyProjects = state.activatedBasesInCompanyProjects.filter((pcb) => !action.payload.some((cb) => cb.id === pcb.id));
        state.workgroupToChecklistBases = state.workgroupToChecklistBases.filter(
          (wgtcb) => !action.payload.some((cb) => cb.checklistBaseId === wgtcb.checklistBaseId && cb.projectId === wgtcb.projectId)
        );
      })
      .addCase(saveWorkGroupToChecklistBaseAuthorityAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveWorkGroupToChecklistBaseAuthorityAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        if (action.payload.active) {
          state.workgroupToChecklistBases = [...state.workgroupToChecklistBases, action.payload];
        } else {
          state.workgroupToChecklistBases = state.workgroupToChecklistBases.filter((auth) => auth.id !== action.payload.id);
        }
      })
      .addCase(saveChecklistBaseToAllWorkGroupsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveChecklistBaseToAllWorkGroupsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.workgroupToChecklistBases = state.workgroupToChecklistBases.filter(
          (authority) => !action.payload.some((auth) => authority.checklistBaseId === auth.checklistBaseId && authority.workGroupId === auth.workGroupId)
        );
        if (action.payload && action.payload.length > 0 && action.payload[0].active) {
          state.workgroupToChecklistBases = [...state.workgroupToChecklistBases, ...action.payload];
        }
      })
      .addCase(addAllProjectBasesToWorkGroupAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(addAllProjectBasesToWorkGroupAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.workgroupToChecklistBases = state.workgroupToChecklistBases.filter(
          (authority) => !action.payload.some((auth) => authority.checklistBaseId === auth.checklistBaseId && authority.workGroupId === auth.workGroupId)
        );
        if (action.payload && action.payload.length > 0 && action.payload[0].active) {
          state.workgroupToChecklistBases = [...state.workgroupToChecklistBases, ...action.payload];
        }
      });
    // -------------------------------- CHECKLIST STATISTICS --------------------------------
  }
});

export const {
  checklistsRemoved,
  checklistsModified,
  checklistModified,
  checklistAdded,
  setChecklistFilter,
  checklistStatisticsFilterSet,
  toggleChecklistRoundSelection,
  toggleChecklistRoundsVisible,
  toggleAllLastRoundsOnPageChecked,
  resetChecklistSelection,
  resetChecklistFilter
} = checklistsSlice.actions;

export const { selectAll: selectAllChecklists, selectById: selectChecklistById, selectIds: selectChecklistIds } = checklistsAdapter.getSelectors((state: RootState) => state.checklists);
export const selectUserChecklistBases = (state: RootState) => state.checklists.userChecklistBases;
export const selectCompanyChecklistBases = (state: RootState) => state.checklists.companyChecklistBases;
export const selectWorkgroupToChecklistBases = (state: RootState) => state.checklists.workgroupToChecklistBases;
export const selectActivatedBasesInCompanyProjects = (state: RootState) => state.checklists.activatedBasesInCompanyProjects;
export const selectChecklistFilter = (state: RootState) => state.checklists.filter;
export const selectChecklistStatisticsFilter = (state: RootState) => state.checklists.statisticsFilter;
export const selectAllChecklistsSelected = (state: RootState) => {
  return state.checklists.ids.length > 0 && state.checklists.ids.every((id) => state.checklists.selection.selectedRoundIds.includes(id));
};
export const selectSelectedChecklistsIds = (state: RootState) => state.checklists.selection.selectedChecklistIds;
export const selectAnyOlderRoundIdsSelected = (state: RootState) => state.checklists.selection.selectedOlderRoundIds.length > 0;
export const selectChecklistTypes = (state: RootState) => state.checklists.filter.filterDropdownValues && state.checklists.filter.filterDropdownValues.types;
export const selectSelectedChecklistRoundIds = (state: RootState) => state.checklists.selection.selectedRoundIds;
export const selectChecklistTags = (state: RootState) => state.checklists.filter.filterDropdownValues && state.checklists.filter.filterDropdownValues.tags;
export const selectChecklistCreators = (state: RootState) => state.checklists.filter.filterDropdownValues && state.checklists.filter.filterDropdownValues.creators;
export const selectAllChecklistLastRoundsSelected = createSelector([selectAllChecklists, selectSelectedChecklistRoundIds], (allChecklists, selectedChecklistRoundIds) => {
  return allChecklists.every((checklist) => {
    if (!checklist || !checklist.checklistRounds) return false;
    const lastRoundId = checklist.checklistRounds.reduce((max, round) => (max.roundNumber > round.roundNumber ? max : round)).id;
    return selectedChecklistRoundIds.includes(lastRoundId);
  });
});

export const selectOnlySingleRoundChecklistsSelected = createSelector([selectAllChecklists, selectSelectedChecklistRoundIds], (allChecklists, selectedChecklistRoundIds) => {
  const uniqueChecklistIds = new Set();
  allChecklists.forEach(
    (checklist) => checklist && checklist.checklistRounds && checklist.checklistRounds.map((round) => selectedChecklistRoundIds.includes(round.id) && uniqueChecklistIds.add(round.checklistId))
  );
  return Array.from(uniqueChecklistIds).every((id) => {
    const checklist = allChecklists.find((checklist) => checklist.id === id);
    return checklist?.checklistRounds?.length === 1;
  });
});

export const selectChecklistLanguageForTranslationById = createSelector([selectAllChecklists, (state: any, checklistId: EntityId) => checklistId], (checklists, checklistId) => {
  const checklist = checklists.find((cl) => cl.id === checklistId);
  if (checklist && checklist.language) {
    return checklist.language.split("_")[0] || "et";
  }
});
export const selectChecklistNameByChecklistId = createSelector([selectAllChecklists, (state: any, checklistId: EntityId) => checklistId], (checklists, checklistId) => {
  const checklist = checklists.find((cl) => cl.id === checklistId);
  if (checklist && checklist.name) {
    return checklist.name;
  }
});
export const selectChecklistLanguageForTranslationByRoundId = createSelector([selectAllChecklists, (state: any, checklistRoundId: EntityId) => checklistRoundId], (checklists, checklistRoundId) => {
  const checklist = checklists.find((cl) => {
    return cl?.checklistRounds?.some((round) => round.id === checklistRoundId);
  });
  if (checklist && checklist.language) {
    return checklist.language.split("_")[0] || "et";
  }
});
export const selectChecklistRoundById = createSelector([selectAllChecklists, (state: any, checklistRoundId: EntityId) => checklistRoundId], (checklists, checklistRoundId) => {
  const checklistRound = checklists.flatMap((checklist) => checklist.checklistRounds).find((round) => round && round.id === checklistRoundId);
  if (checklistRound) {
    return checklistRound;
  }
});
export const isLastChecklistRound = createSelector([selectAllChecklists, (state: any, checklistRoundId: EntityId) => checklistRoundId], (checklists, checklistRoundId) => {
  const checklist = checklists.find((cl) => {
    return cl.checklistRounds && cl.checklistRounds.some((round) => round.id === checklistRoundId);
  });
  if (checklist && checklist.checklistRounds) {
    const checklistRound = checklist.checklistRounds.find((round) => round.id === checklistRoundId);
    if (checklistRound) {
      return checklist.checklistRounds.reduce((max, round) => (max.roundNumber > round.roundNumber ? max : round)).roundNumber === checklistRound.roundNumber;
    }
  }
});
export const selectChecklistTagsByChecklistId = createSelector([selectAllChecklists, (state: any, checklistId: EntityId) => checklistId], (checklists, checklistId) => {
  const checklist = checklists.find((cl) => {
    return cl.id === checklistId;
  });
  if (checklist && checklist.checklistTags) {
    return checklist.checklistTags;
  }
  return [];
});
export const selectCurrentProjectIdAndChecklistRound = (state: RootState, projectId: EntityId, checklistRoundId: EntityId) => checklistRoundId;
export const selectIsChecklistRoundEditable = createSelector(
  [selectAllChecklists, selectCurrentUser, selectIsCurrentUserProjectAdmin, selectCurrentProjectIdAndChecklistRound],
  (checklists, currentUser, isCurrentUserProjectAdmin, checklistRoundId) => {
    let isRoundEditable;
    let canCurrentUserEditChecklist;
    let checklistNotArchived;
    const checklist = checklists.find((cl) => {
      return cl.checklistRounds && cl.checklistRounds.some((round) => round.id === checklistRoundId);
    });
    if (checklist) {
      checklistNotArchived = !checklist.archived;
      if (checklist.checklistRounds) {
        const checklistRound = checklist.checklistRounds.find((round) => round.id === checklistRoundId);
        if (checklistRound) {
          isRoundEditable = checklistRound.status !== ChecklistRoundStatus.COMPLETE;
        }
      }
      const isCurrentUserWithChecklistWriteAuths = currentUser.userAuthorities.some(
        (auth: IUserAuthority) => auth.resource === "CHECKLIST" && auth.resourceId === checklist.id && auth.authority === "WRITE"
      );
      const isCurrentUserChecklistCreator = currentUser.username === checklist.createdBy;
      canCurrentUserEditChecklist = isCurrentUserWithChecklistWriteAuths || isCurrentUserProjectAdmin || isCurrentUserChecklistCreator;
    }
    return isRoundEditable && canCurrentUserEditChecklist && checklistNotArchived;
  }
);
export const selectSortedChecklistRounds = createSelector([selectAllChecklists, selectChecklistFilter], (checklists, filter) => {
  return checklists.flatMap((checklist) => {
    let rounds = checklist.checklistRounds;
    if (checklist && rounds && !checklist.showAllRounds) {
      const lastRound = rounds.reduce((max, round) => (max.roundNumber > round.roundNumber ? max : round));
      return [lastRound];
    }
    return rounds && rounds.slice().sort((a, b) => (a.roundNumber < b.roundNumber ? 1 : -1));
  });
});
export const selectCurrentProjectIdAndChecklistId = (state: RootState, projectId: EntityId, checklistRoundId: EntityId) => checklistRoundId;
export const selectCanCurrentUserEditChecklist = createSelector(
  [selectCurrentUser, selectAllChecklists, selectIsCurrentUserProjectAdmin, selectCurrentProjectIdAndChecklistId],
  (currentUser, checklists, isCurrentUserProjectAdmin, checklistId) => {
    const checklist = checklists.find((cl) => {
      return cl.id === checklistId;
    });
    let canCurrentUserEditChecklist;
    if (checklist) {
      const isCurrentUserWithChecklistWriteAuths = currentUser.userAuthorities.some(
        (auth: IUserAuthority) => auth.resource === "CHECKLIST" && auth.resourceId === checklist.id && auth.authority === "WRITE"
      );
      const isCurrentUserChecklistCreator = currentUser.username === checklist.createdBy;
      canCurrentUserEditChecklist = isCurrentUserWithChecklistWriteAuths || isCurrentUserProjectAdmin || isCurrentUserChecklistCreator;
    }
    return canCurrentUserEditChecklist;
  }
);
export const selectChecklistId = (state: RootState, checklistId: EntityId) => checklistId;
export const selectChecklistRoundId = (state: RootState, _: any, checklistRoundId: EntityId) => checklistRoundId;
export const selectChecklistBaseFileId = createSelector([selectChecklistById, selectChecklistId, selectChecklistRoundId], (checklist, checklistId, checklistRoundId) => {
  const checklistRound = checklist?.checklistRounds?.find((checklistRound) => checklistRound.id === checklistRoundId) as IChecklistRound;
  return checklistRound?.baseFileEntityId;
});
export const selectChecklistBaseActiveInWorkgroupsCount = createSelector([selectWorkgroupToChecklistBases, (state: any, checklistBaseId: EntityId) => checklistBaseId], (auths, checklistBaseId) => {
  return auths.filter((auth) => auth.checklistBaseId === checklistBaseId && auth.active).length;
});
export const selectUsernamesForChecklistFilter = createSelector([selectCompanyChecklistBases], (companyChecklistBases) => {
  return removeDuplicates(companyChecklistBases.filter((cb) => !cb.createdInProjectId).map((ccb) => ccb.createdBy));
});
export const selectUsernamesForProjectChecklistFilter = createSelector([selectCompanyChecklistBases], (companyChecklistBases) => {
  return removeDuplicates(companyChecklistBases.filter((cb) => cb.createdInProjectId).map((ccb) => ccb.createdBy));
});
export const selectCategoriesForProjectChecklistFilter = createSelector([selectCompanyChecklistBases], (companyChecklistBases) => {
  return removeDuplicates(companyChecklistBases.filter((cb) => cb.createdInProjectId).map((cb) => cb.category));
});
export const selectCategoriesForChecklistFilter = createSelector([selectCompanyChecklistBases], (companyChecklistBases) => {
  return removeDuplicates(companyChecklistBases.map((cb) => cb.category));
});
export const selectCompanyChecklistBaseById = createSelector([selectCompanyChecklistBases, (state: any, checklistBaseId: EntityId) => checklistBaseId], (checklistBases, checklistBaseId) => {
  const checklistBase = checklistBases.find((cb) => cb.id === checklistBaseId);
  if (checklistBase) {
    return checklistBase;
  }
});
export const selectChecklistBaseLanguageForTranslationById = createSelector(
  [selectCompanyChecklistBases, (state: any, checklistBaseId: EntityId) => checklistBaseId],
  (checklistBases, checklistBaseId) => {
    const checklistBase = checklistBases.find((cb) => cb.id === checklistBaseId);
    if (checklistBase && checklistBase.language) {
      return checklistBase.language.split("_")[0] || "et";
    }
  }
);
export const selectCompanyChecklistBasesActivatedInProject = createSelector(
  [selectCompanyChecklistBases, selectActivatedBasesInCompanyProjects, (state: any, projectId: EntityId) => projectId],
  (checklistBases, activatedCompanyBases, projectId) => {
    return checklistBases.filter((cb) => activatedCompanyBases.some((ccb) => ccb.projectId === projectId && ccb.checklistBaseId === cb.id));
  }
);
export const selectCompanyChecklistBasesNotCreatedInProjects = createSelector([selectCompanyChecklistBases], (checklistBases) => {
  return checklistBases.filter((ccb) => !ccb.createdInProjectId);
});
export const selectCompanyChecklistBasesCreatedInProjects = createSelector([selectCompanyChecklistBases], (checklistBases) => {
  return checklistBases.filter((ccb) => ccb.createdInProjectId);
});
export const selectCompanyChecklistBasesWithFinishedVersions = createSelector([selectCompanyChecklistBases], (checklistBases) => {
  return checklistBases.filter((ccb) => ccb.checklistBaseVersions.some((cbv) => cbv.status === ChecklistBaseVersionStatus.FINAL));
});

export const selectChecklistBaseId = (state: RootState, checklistBaseId: EntityId) => checklistBaseId;
export const selectProjectId = (state: RootState, _: any, projectId: EntityId) => projectId;
export const selectIsChecklistBaseActivatedInProjectByBaseAndProjectId = createSelector(
  [selectActivatedBasesInCompanyProjects, selectChecklistBaseId, selectProjectId],
  (activatedProjectBases, checklistBaseId, projectId) => {
    return activatedProjectBases.some((pcb) => pcb.checklistBaseId === checklistBaseId && pcb.projectId === projectId);
  }
);

export const selectCurrentCompanyProjectsForChecklistFilter = createSelector([selectCompanyChecklistBasesCreatedInProjects, (state: RootState) => state], (projectBases, state) => {
  if (projectBases && projectBases.length > 0) {
    const projectIds = projectBases.map((cb) => cb.createdInProjectId);
    const projects = state.companies.current.projects?.filter((project) => projectIds.includes(project.id));
    if (projects && projects.length > 0) {
      return projects.map((project) => {
        return { id: project.id, name: project.name };
      });
    }
  }
  return [];
});

export const selectChecklistCreatorsForFilter = createSelector([selectChecklistCreators], (checklistCreators) => {
  if (!checklistCreators || checklistCreators.length === 0) {
    return [];
  }
  return checklistCreators.map((user) => ({
    ...user,
    fullName: user.firstName + " " + user.lastName
  }));
});
export const selectIsChecklistRoundSelected = createSelector([selectChecklistId, selectSelectedChecklistRoundIds], (toggableChecklistRoundId, selectedChecklistRoundIds) => {
  return selectedChecklistRoundIds.some((id) => id === toggableChecklistRoundId);
});

export const selectActivatedBasesInCompanyActiveProjects = createSelector([selectActivatedBasesInCompanyProjects, (state) => state.companies.current.projects], (activatedBases, companyProjects) => {
  const activeCompanyProjects = companyProjects.filter((project: IProject) => project.status !== ProjectStatus.ARCHIVED && !project.suspended && !project.closed);
  return activatedBases.filter((base) => activeCompanyProjects.some((project: IProject) => project.id === base.projectId));
});

export default checklistsSlice.reducer;
