import React, { FC, useCallback } from "react";
import { classNames } from "@/utilities/jsUtilities";

export interface IBhToggleButtonBarItem {
  value: string | boolean;
  text?: string;
  detail?: string;
}

interface Props {
  label?: string;
  items: Array<IBhToggleButtonBarItem>;
  onClickAction: Function;
  selected?: string | boolean;
  property?: string;
  classes?: string;
  disabled?: boolean;
}

const BhToggleButtonBar: FC<Props> = ({ label, items, onClickAction, property, classes, selected, disabled }) => {
  const valuesCount = items.length;

  const handleClick = useCallback(
    (value: any) => () => {
      if (disabled) {
        return;
      }
      return property ? onClickAction({ [property]: value }) : onClickAction(value);
    },
    [onClickAction]
  );

  const isSelected = (item: IBhToggleButtonBarItem) => {
    return item.value === selected;
  };

  return (
    <div className={classes && classes}>
      {label && <div className="text-14 l-h-16 bh-text-deep-ocean-80 px-0.5 py-2 font-semibold">{label}</div>}
      <div>
        <div className="bh-text-pigeon bh-border-pigeon-50 l-h-14px group flex h-9 cursor-pointer flex-row items-center justify-around border-y transition transition-all duration-500 ease-out first:rounded-l first:border-l last:rounded-r last:border-r">
          {items.map((item, index) => {
            return (
              <button
                key={index}
                disabled={disabled}
                className={classNames(
                  isSelected(item)
                    ? disabled
                      ? "bh-bg-pigeon-20 bh-outline-pigeon-60 hover:none z-10 cursor-not-allowed outline outline-1"
                      : "bh-bg-mint bh-outline-dark-jungle bh-text-dark-jungle z-10 outline outline-1"
                    : !disabled && "hover:bh-bg-mint-50 hover:bh-text-dark-jungle",
                  `w-1/${valuesCount} flex h-full min-w-fit items-center justify-center transition transition-all duration-500 ease-out first-of-type:rounded-l last-of-type:rounded-r`
                )}
                onClick={handleClick(item.value)}
              >
                <span className="extra-letter-spacing button-text min-w-[80px] px-2 uppercase">
                  {item.text ? item.text : item.value} {item.detail && item["detail"]}
                </span>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(BhToggleButtonBar);
