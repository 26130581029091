import React, { FC } from "react";
import BhCardContainer from "@components/cards/BhCardContainer";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyPredefinedParties } from "@/app/store/companiesSlice";
import { EntityId } from "@reduxjs/toolkit";
import PartiesListItemInfo from "../../../project/detail/users/parties/PartiesListItemInfo";
import { useNavigate } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  companyId: EntityId;
}

const PartyTemplatesList: FC<Props> = ({ companyId }) => {
  const predefinedParties = useAppSelector((state) => selectCompanyPredefinedParties(state, companyId));
  const navigate = useNavigate();

  if (!predefinedParties) return null;

  const onPartySelect = (partyId: EntityId) => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${companyId}/work-group-templates/${partyId}/edit/users`);
  };

  return (
    <div>
      {predefinedParties.map((predefinedParty) => {
        return (
          <BhCardContainer key={predefinedParty.id} onClick={() => onPartySelect(predefinedParty.id)}>
            <PartiesListItemInfo party={predefinedParty} />
          </BhCardContainer>
        );
      })}
    </div>
  );
};

export default PartyTemplatesList;
