import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectChecklistFilter, setChecklistFilter } from "@/app/store/checklist/checklistsSlice";
import { faArrowDown, faArrowRightArrowLeft, faArrowUp } from "@fortawesome/pro-regular-svg-icons";

const ChecklistCreatedByAndCreatedSortToggle = () => {
  const filter = useAppSelector(selectChecklistFilter);
  const orderBy = filter.orderBy || "created";
  const ascending = filter.orderAsc || false;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <span>
      <button
        onClick={() => {
          const createdByAndCreatedField = ["createdBy", "created"];
          const otherField = createdByAndCreatedField.find((field) => field !== filter.orderBy) as "createdBy" | "created";
          if (otherField) {
            const newFilter = { ...filter, orderBy: otherField, orderAsc: !filter.orderBy || otherField === filter.orderBy ? !ascending : false, toggleFieldValue: otherField };
            dispatch(setChecklistFilter({ filter: newFilter }));
          }
        }}
        className="bh-text-deep-ocean-imp hover:bh-bg-smoke disabled:bh-text-pigeon-60 bh-text-pigeon mr-0.5 rounded px-0.5 py-0.5 font-bold"
      >
        <FontAwesomeIcon className="px-1" icon={faArrowRightArrowLeft} />
      </button>
      <span
        onClick={() => {
          const newOrderBy = !["createdBy", "created"].includes(orderBy) ? "created" : filter.orderBy;
          const newFilter = { ...filter, orderBy: newOrderBy, orderAsc: !ascending };
          dispatch(setChecklistFilter({ filter: newFilter }));
        }}
        className="hover:bh-bg-smoke cursor-pointer rounded py-0.5 px-1"
      >
        {orderBy === "createdBy" && t("CHECKLIST.CREATED_BY")}
        {orderBy === "created" && t("CHECKLIST.CREATED")}
        {!["createdBy", "created"].includes(orderBy) && t("CHECKLIST.CREATED")}
        {["createdBy", "created"].includes(orderBy) && (
          <button className="bh-text-deep-ocean-imp hover:bh-bg-smoke disabled:bh-text-pigeon-60 bh-text-pigeon mx-0.5 rounded px-0.5 py-0.5 font-bold">
            <FontAwesomeIcon className="px-1" icon={ascending ? faArrowDown : faArrowUp} />
          </button>
        )}
      </span>
    </span>
  );
};

export default ChecklistCreatedByAndCreatedSortToggle;
