import { EntityId } from "@reduxjs/toolkit";
import React, { FC, useEffect } from "react";
import { fetchCompanyChecklistBasesAsync, fetchProjectChecklistBasesForCompanyAsync } from "@/app/store/checklist/checklistsSlice";
import { useAppDispatch } from "@/app/hooks";
import CompanyChecklistSettingsTablesContainer from "@components/checklists/settings/companySettings/CompanyChecklistSettingsTablesContainer";

interface Props {
  companyId: EntityId;
}

const CompanySettingsChecklistBasesTab: FC<Props> = ({ companyId }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCompanyChecklistBasesAsync(companyId));
    dispatch(fetchProjectChecklistBasesForCompanyAsync(companyId));
  }, []);

  return <CompanyChecklistSettingsTablesContainer companyId={companyId} />;
};

export default CompanySettingsChecklistBasesTab;
