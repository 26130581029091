import React, { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const BhSpreadsheetTable: FC<Props> = ({ children }) => {
  return <table className="text-13px mt-4 w-full border-collapse border">{children}</table>;
};

export default BhSpreadsheetTable;
