import React, { FC } from "react";
import BhDatePicker from "@components/input/BhDatePicker";
import FormFieldContainerInput from "@components/form/base/body/fields/FormFieldContainerInput";
import FormBaseFieldCheckboxWithText from "@components/form/base/body/FormBaseFieldCheckboxWithText";
import { useTranslation } from "react-i18next";

interface Props {
  fieldValue: any;
  saveFieldCallback: Function;
  disabled?: boolean;
}

const FormBaseDateField: FC<Props> = ({ fieldValue, saveFieldCallback, disabled }) => {
  const { t } = useTranslation();
  const isPrefillInput = fieldValue.prefill && fieldValue.prefill.enabled;

  return (
    <div className="flex h-full flex-col gap-y-1.5">
      <FormFieldContainerInput
        initialValue={fieldValue.label}
        property={"label"}
        disabled={disabled}
        onBlurCallback={saveFieldCallback}
        placeholder={t("FORMBUILDER.FORM.BASE.FIELD.TEXT.LABEL_PLACEHOLDER") as string}
      />
      <div className="flex h-full flex-col justify-between">
        <BhDatePicker
          widthClasses={"!min-w-full"}
          property={"date"}
          placeholder={isPrefillInput ? (t("FORMBUILDER.FORM.BASE.FIELD.DATE_PLACEHOLDER") as string) : undefined}
          disabled={true}
          initialValue={!fieldValue.prefill.enabled && new Date(fieldValue.date)}
          iconVisible={true}
          wrapperClasses={"w-full"}
        />
        <div className="mt-1.5">
          <FormBaseFieldCheckboxWithText
            text={t("FORMBUILDER.FORM.BASE.FIELD.DATE_PREFILL")}
            isChecked={fieldValue.prefill && fieldValue.prefill.enabled}
            property={"enabled"}
            disabled={disabled}
            onChangeCallback={(value: any) => saveFieldCallback("prefill", value)}
          />
        </div>
      </div>
    </div>
  );
};

export default FormBaseDateField;
