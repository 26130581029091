import { createAction, createAsyncThunk, createEntityAdapter, createReducer, createSelector, EntityId } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { IBauhubEvent } from "@/model/IBauhubEvent";
import { IProjectLogFilter, IProjectLogFilterDropdownItem, IProjectLogFilterOptions } from "@/model/logs/IProjectLogFilter";
import { fetchProjectLog } from "@/api/projectAPI";
import { IProjectLogQueryDTO } from "@/model/logs/IProjectLogQueryDTO";
import { IProjectLogFileRestoreModal, IProjectLogModal } from "@/model/logs/IProjectLogModal";
import { IBauhubModal } from "@/model/IBauhubModal";
import { fetchFileLog } from "@/api/fileAPI";
import { formatDateISO, subtractDaysFromDate } from "@/utilities/dateUtility";

export const projectLogAdapter = createEntityAdapter<IBauhubEvent>({
  sortComparer: (a, b) => {
    return a.id < b.id ? -1 : 1;
  }
});

export interface IProjectLogState {
  loading: boolean;
  filter: IProjectLogFilter;
  modals: IProjectLogModal;
}

export const projectLogInitialState = projectLogAdapter.getInitialState<IProjectLogState>({
  loading: true,
  filter: {
    isContract: false,
    limit: 20,
    page: 1,
    total: 0,
    orderBy: "created",
    sortOrderAsc: false,
    until: formatDateISO(new Date()),
    since: formatDateISO(subtractDaysFromDate(new Date(), 30))
  } as IProjectLogFilter,
  modals: {
    fileRestoreModal: {
      shown: false
    } as IBauhubModal,
    fileHistoryModal: {
      shown: false
    } as IBauhubModal
  } as IProjectLogModal
});

export const fetchProjectLogAsync = createAsyncThunk("project/fetchProjectLog", async (projectId: EntityId, { getState }) => {
  const state: RootState = getState() as RootState;
  return fetchProjectLog(projectId, state.project.log.filter);
});

export const fetchFileLogAsync = createAsyncThunk("project/fetchFileLog", async (_, { getState }) => {
  const state: RootState = getState() as RootState;
  const log = state.project.log.modals.fileHistoryModal.log;
  return fetchFileLog(log.fileEntityId);
});

export const resetProjectLog = createAction("project/resetProjectLogState");
export const setProjectLogFilter = createAction<IProjectLogFilter>("project/setProjectLogFilter");
export const toggleLogSortOrder = createAction<string>("project/log/toggleSortReversed");
export const resetProjectLogFilterType = createAction("project/log/reset/type");
export const resetProjectLogFilterUsername = createAction("project/log/reset/username");
export const resetProjectLogFilterFileName = createAction("project/log/reset/fileName");
export const resetProjectLogFilterSince = createAction("project/log/reset/since");
export const resetProjectLogFilterUntil = createAction("project/log/reset/until");
export const resetProjectLogFilterFolderIds = createAction("project/log/reset/folderIds");
export const resetProjectLogFilterPage = createAction("project/log/reset/page");
export const setFileHistoryModalShown = createAction<boolean>("project/log/modal/file/history/shown");
export const setFileHistoryModalLog = createAction<IBauhubEvent>("project/log/modal/file/history/log");
export const resetFileHistoryModalData = createAction("project/log/modal/file/history/reset");
export const setFileRestoreModalShown = createAction<boolean>("project/log/modal/file/restore/shown");
export const setFileRestoreModalInfo = createAction<IProjectLogFileRestoreModal>("project/log/modal/file/restore/info");
export const resetFileRestoreModalData = createAction("project/log/modal/file/restore/reset");

export const projectLogExtraReducer = createReducer(projectLogInitialState, (builder) => {
  builder
    .addCase(resetProjectLog, () => {
      return projectLogInitialState;
    })
    .addCase(setProjectLogFilter, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(toggleLogSortOrder, (state, action) => {
      let currentlyOrderedBy = state.filter.orderBy;
      if (currentlyOrderedBy === action.payload) {
        state.filter.sortOrderAsc = !state.filter.sortOrderAsc;
      } else {
        state.filter.orderBy = action.payload;
      }
    })
    .addCase(fetchProjectLogAsync.pending, (state) => {
      state.loading = true;
    })
    .addCase(resetProjectLogFilterType, (state) => {
      delete state.filter.type;
    })
    .addCase(resetProjectLogFilterUsername, (state) => {
      delete state.filter.username;
    })
    .addCase(resetProjectLogFilterFileName, (state) => {
      delete state.filter.fileName;
    })
    .addCase(resetProjectLogFilterSince, (state) => {
      delete state.filter.since;
    })
    .addCase(resetProjectLogFilterUntil, (state) => {
      delete state.filter.until;
    })
    .addCase(resetProjectLogFilterFolderIds, (state) => {
      delete state.filter.folderIds;
    })
    .addCase(resetProjectLogFilterPage, (state) => {
      state.filter.page = 1;
    })
    .addCase(setFileHistoryModalShown, (state, action) => {
      state.modals.fileHistoryModal.shown = action.payload;
    })
    .addCase(setFileHistoryModalLog, (state, action) => {
      state.modals.fileHistoryModal.log = action.payload;
    })
    .addCase(resetFileHistoryModalData, (state) => {
      delete state.modals.fileHistoryModal.log;
      delete state.modals.fileHistoryModal.fileHistory;
    })
    .addCase(setFileRestoreModalShown, (state, action) => {
      state.modals.fileRestoreModal.shown = action.payload;
    })
    .addCase(setFileRestoreModalInfo, (state, action) => {
      state.modals.fileRestoreModal.log = action.payload.log;
      state.modals.fileRestoreModal.fileTree = action.payload.fileTree;
      state.modals.fileRestoreModal.shown = action.payload.shown;
    })
    .addCase(resetFileRestoreModalData, (state) => {
      delete state.modals.fileRestoreModal.log;
      delete state.modals.fileRestoreModal.fileTree;
    })
    .addCase(fetchFileLogAsync.fulfilled, (state, action) => {
      const result: Array<IBauhubEvent> = action.payload;
      state.modals.fileHistoryModal.fileHistory = result.sort((a, b) => {
        return a.id < b.id ? -1 : 1;
      });
    })
    .addCase(fetchProjectLogAsync.fulfilled, (state, action) => {
      state.loading = false;
      const result: IProjectLogQueryDTO = action.payload;
      state.filter.page = result.page;
      state.filter.limit = result.limit;
      state.filter.total = result.total;

      // filterOptions
      let typeArray: Array<IProjectLogFilterDropdownItem> = [];
      let usernameArray: Array<IProjectLogFilterDropdownItem> = [];
      result.filterOptions.types.forEach((type) => {
        typeArray.push({ name: type, selected: state.filter.type === type ? true : false });
      });
      result.filterOptions.usernames.forEach((username) => {
        usernameArray.push({ name: username, selected: state.filter.username === username ? true : false });
      });
      let filterOptions: IProjectLogFilterOptions = { types: typeArray, usernames: usernameArray };
      state.filter.filterOptions = filterOptions;

      result.list = result.list || [];
      projectLogAdapter.setAll(state, result.list);
    });
});

export const { selectAll: selectAllProjectLogs, selectIds: selectAllProjectLogIds, selectById: selectProjectLogById } = projectLogAdapter.getSelectors((state: RootState) => state.project.log);
export const selectCurrentProjectLogLoading = (state: RootState) => state.project.log.loading;
export const selectCurrentProjectLogFilterOrderedBy = (state: RootState) => state.project.log.filter.orderBy;
export const selectCurrentProjectLogFilterSortOrderAsc = (state: RootState) => state.project.log.filter.sortOrderAsc;
export const selectCurrentProjectLogFilter = (state: RootState) => state.project.log.filter;
export const selectCurrentProjectLogFilterOptions = (state: RootState) => state.project.log.filter.filterOptions;
export const isFileHistoryModalShown = (state: RootState) => state.project.log.modals.fileHistoryModal.shown;
export const selectFileHistoryModalLog = (state: RootState) => state.project.log.modals.fileHistoryModal.log;
export const selectFileHistoryModalFileHistory = (state: RootState) => state.project.log.modals.fileHistoryModal.fileHistory;
export const isFileRestoreModalShown = (state: RootState) => state.project.log.modals.fileRestoreModal.shown;
export const selectFileRestoreModalLog = (state: RootState) => state.project.log.modals.fileRestoreModal.log;
export const selectFileRestoreModalFileTree = (state: RootState) => state.project.log.modals.fileRestoreModal.fileTree;
export const selectAllProjectLogsSorted = createSelector([selectAllProjectLogs, selectCurrentProjectLogFilterSortOrderAsc], (logs, sortOrderAsc) => (sortOrderAsc ? [...logs] : [...logs].reverse()));
