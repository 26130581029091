import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectAllProjectsSortedByName } from "@/app/store/companiesSlice";
import BhToggleRow from "@components/toggle/BhToggleRow";
import { classNames } from "@/utilities/jsUtilities";
import { saveUserMailSettingsInfoAsync, selectUserMailProjectRelatedSettings, selectUserMailSettingsModalType } from "@/app/store/userPreferenceSlice";
import { IUserEmailSetting } from "@/model/profile/IUserEmailSettings";
import { useTranslation } from "react-i18next";

const MailSettingsModalProjectToggleList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const allProjects = useAppSelector(selectAllProjectsSortedByName);
  const userMailSettingsInfo = useAppSelector(selectUserMailProjectRelatedSettings);
  const modalType = useAppSelector(selectUserMailSettingsModalType);
  const [projects, setProjects] = useState(allProjects);

  useEffect(() => {
    if (userMailSettingsInfo && allProjects) {
      const projectsWithSelectedValue = allProjects.map((project) => {
        return { ...project, ...{ selected: !userMailSettingsInfo.some((info) => info.resourceId === project.id) } };
      });
      setProjects(projectsWithSelectedValue);
    }
  }, [userMailSettingsInfo, allProjects]);

  const toggleProject = (project: any) => {
    if (userMailSettingsInfo) {
      const existingSetting = userMailSettingsInfo.find((info) => info.resource === "PROJECT" && info.resourceId === project.id);
      let objectToSave = existingSetting ? { ...existingSetting, ...{ deleted: !project.selected } } : ({ resource: "PROJECT", resourceId: project.id, type: modalType } as IUserEmailSetting);
      dispatch(saveUserMailSettingsInfoAsync(objectToSave));
    }
  };

  return (
    <div className="w-full overflow-auto px-20 py-16">
      <h3 className="bh-text-deep-ocean mx-0 mt-0">{t("HOME.PROJECTS")}</h3>
      {projects &&
        projects.map((project, index) => {
          return (
            <BhToggleRow
              key={project.id}
              toggleObject={project}
              toggleProperty={"selected"}
              onToggle={() => toggleProject(project)}
              label={<span className="bh-text-deep-ocean text-14px leading-5">{project.name}</span>}
              classes={classNames("pt-5 bh-border-pigeon-20", index !== 0 && "border-t", index !== projects.length - 1 && "pb-5")}
            />
          );
        })}
    </div>
  );
};

export default MailSettingsModalProjectToggleList;
