import React, { FC, useCallback, useState } from "react";
import BhShareModal from "@components/modal/BhShareModal";
import BhShareFileContainer from "@components/modal/BhShareFileContainer";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { allFileEntitiesUnSelected, selectAllFilesSelected } from "@/app/store/filesSlice";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhIconButton from "@components/buttons/BhIconButton";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import BhLightSeparator from "@components/separator/BhLightSeparator";
import BhLargeBoldText from "@components/text/BhLargeBoldText";
import { selectCurrentProjectId, toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import useHandleFormSubmit from "@/utilities/hooks/useHandleFormSubmit";
import { ISharebox, ShareboxDisplayOption } from "@/model/shareboxes/ISharebox";
import BhDatePicker from "@components/input/BhDatePicker";
import { saveShareboxAsync, saveShareboxUrlsAsync } from "@/app/store/shareboxesSlice";
import { IShareboxUrl } from "@/model/shareboxes/IShareboxUrl";
import { fetchProjectUserEmailsForSharebox } from "@/api/projectAPI";
import BhInputLabel from "@components/input/BhInputLabel";
import { Trans, useTranslation } from "react-i18next";
import BhChildComponentWithFetchValues from "@components/input/BhChildComponentWithFetchValues";
import BhEmailInputWithEmailsDropdown from "@components/input/BhSearchInputWithStringDropdown";
import BhTagListContainer from "@components/tags/BhTagListContainer";
import BhChipWithEmail from "@/views/home/project/detail/directory/directoryShareModals/BhChipWithEmail";
import { getUniqueValues } from "@/utilities/jsUtilities";
import BhTextareaStackedLabel from "@components/input/BhTextareaStackedLabel";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { BauhubBannerType } from "@/model/IProject";
import { v4 as uuidv4 } from "uuid";
import { formatDateTime } from "@/utilities/dateUtility";
import dayjs from "dayjs";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { IFileEntity } from "@/model/files/IFileEntity";
import { selectCurrentUserLanguage } from "@/app/store/userSlice";

interface Props {}

const DirectoryShareboxModalContainer: FC<Props> = () => {
  const { t } = useTranslation();
  const files = useAppSelector(selectAllFilesSelected);
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguage);
  const [selectedFiles, setSelectedFiles] = useState(files);
  const handleShareboxFormSubmit = useHandleFormSubmit<ISharebox>();
  const [expires, setExpires] = useState(false);
  const [validToInput, setValidToInput] = useState<Date>(new Date());
  const [emailsToSend, setEmailsToSend] = useState<Array<string>>([]);
  const [saving, setSaving] = useState(false);
  const [initialName] = useState(formatDateTime(new Date()));
  const currentProjectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();

  const onFileSelect = (files: IFileEntity[]) => {
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const onFileDelete = (selectedFile: IFileEntity) => {
    const newFiles = selectedFiles.filter((file) => file.id !== selectedFile.id);
    setSelectedFiles(newFiles);
  };

  const closeModal = useCallback(() => {
    dispatch(toggleProjectModalsOpen({ modal: "directoryShareboxModal" }));
  }, []);

  const saveSharebox = (formValues: ISharebox) => {
    if (formValues.name.length < 1) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.WARNING,
          disappear: true,
          children: t("INPUT.NOT_EMPTY")
        })
      );
      return;
    }

    setSaving(true);

    const withCorrectDateFormat = {
      ...formValues,
      validTo: expires ? dayjs.utc(validToInput).endOf("day").toDate() : null,
      fileList: selectedFiles,
      projectId: currentProjectId,
      displayPreference: ShareboxDisplayOption.DIRECTORY_TREE,
      message: formValues.message
    } as ISharebox;

    dispatch(saveShareboxAsync(withCorrectDateFormat)).then((action) => {
      const sharebox = action.payload as ISharebox;
      const shareboxId = sharebox.id;
      const shareboxUrls: Array<IShareboxUrl> = emailsToSend.map((email) => {
        return { shareboxId: shareboxId, email: email, visited: false, created: new Date(), message: formValues.message || "", id: undefined };
      });
      dispatch(saveShareboxUrlsAsync({ shareboxId: shareboxId, shareboxUrls: shareboxUrls }));
      closeModal();
      dispatch(allFileEntitiesUnSelected());
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.SUCCESS,
          disappear: true,
          navigateTo: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${sharebox.projectId}/sharebox/${sharebox.id}`,
          translateCode: "SHAREBOX.CREATED"
        })
      );
      setSaving(false);
    });
  };

  const removeEmail = (removedEmail: string) => {
    setEmailsToSend((prev) => prev.filter((prevEmail) => prevEmail !== removedEmail));
  };

  const selectEmail = (email: string) => {
    setEmailsToSend((prev) => getUniqueValues([...prev, email]));
  };

  const submitButtonDisabled = () => {
    if (saving) return true;
    if (selectedFiles.length === 0) return true;
    if (expires && !validToInput) return true;
    return false;
  };

  return (
    <BhShareModal
      header={
        <div className="flex flex-row items-center justify-between">
          <h2>
            <Trans>SHAREBOX.HEADER</Trans>
          </h2>
          <BhIconButton icon={faTimes} buttonProps={{ onClick: closeModal }} />
        </div>
      }
      footer={
        <div className="flex w-full flex-1 justify-between">
          <BhTextOnlyButton buttonProps={{ onClick: closeModal }}>
            <Trans>GLOBAL.BACK</Trans>
          </BhTextOnlyButton>
          <BhPrimaryButton buttonProps={{ submitForm: "shareboxForm", disabled: submitButtonDisabled() }}>
            {currentUserLanguage === "ru_RU"
              ? selectedFiles.length <= 1
                ? t("SHAREBOX.CREATE").slice(0, -1)
                : t("SHAREBOX.CREATE") + " (" + selectedFiles.length + ")"
              : t("SHAREBOX.CREATE") + " " + selectedFiles.length + " " + (selectedFiles.length === 1 ? t("GLOBAL.WITH_FILE") : t("GLOBAL.WITH_FILES"))}
          </BhPrimaryButton>
        </div>
      }
      onOutsideClick={closeModal}
    >
      <BhShareFileContainer files={selectedFiles} fileAddingCallback={onFileSelect} deleteCallback={onFileDelete} />
      <div className="py-8 px-20">
        <form id="shareboxForm" className="max-w-420px flex flex-col" onSubmit={handleShareboxFormSubmit((sharebox) => saveSharebox(sharebox))}>
          <BhLargeBoldText classes="pb-4">
            <Trans>SHAREBOX.NEW</Trans>
          </BhLargeBoldText>
          <BhInputStackedLabel initialValue={initialName} property={"name"} label={t("SHAREBOX.NAME")} onChangeCallback={() => {}} />
          <div className="mt-6 mb-4 flex flex-row items-center">
            <label className="text-13px l-h-16px bh-text-deep-ocean-80 mr-6 font-bold">
              <Trans>SHAREBOX.EXPIRES</Trans>
            </label>
            <BhToggleSwitch property={"expires"} value={expires} onClickAction={(value: any) => setExpires(value.expires)} />
          </div>
          {expires && (
            <div className="space-y-1.5">
              <label className="text-13px l-h-16px bh-text-deep-ocean-80 font-bold">
                <Trans>SHAREBOX.EXPIRE_DATE</Trans>
              </label>
              <BhDatePicker
                property={"validTo"}
                initialValue={validToInput}
                onChangeCallback={(value: any) => {
                  setValidToInput(value.validTo);
                }}
                disablePastDates={true}
              />
            </div>
          )}
          <div className="mt-10 mb-6">
            <BhLightSeparator />
          </div>
          <BhLargeBoldText classes="mb-4">
            <Trans>SHAREBOX.SHARE_BY_EMAIL</Trans>
          </BhLargeBoldText>
          <BhInputLabel>
            <Trans>SHARE.HEADER.EMAIL</Trans>
          </BhInputLabel>
          <BhChildComponentWithFetchValues
            fetchValues={() => fetchProjectUserEmailsForSharebox(currentProjectId)}
            child={
              <BhEmailInputWithEmailsDropdown
                values={[]}
                onSelect={selectEmail}
                showValueCondition={(value: string) => !emailsToSend.includes(value)}
                placeholder={t("SHARE.HEADER.EMAIL") as string}
              />
            }
          />
          <div className="py-3">
            <BhTagListContainer>
              {emailsToSend.map((email) => (
                <BhChipWithEmail email={email} key={email} onRemove={removeEmail} />
              ))}
            </BhTagListContainer>
          </div>
          <BhTextareaStackedLabel initialValue="" property={"message"} label={t("SHAREBOX.EDIT_MESSAGE") as string} rows={3} />
        </form>
      </div>
    </BhShareModal>
  );
};

export default DirectoryShareboxModalContainer;
