import React, { FC, useEffect, useRef, useState } from "react";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { fetchDownloadUrl } from "@/api/contactAPI";
import { imitatePdfFromFormDownload } from "@/utilities/downloadUtilities";
import { useAppSelector } from "@/app/hooks";
import { selectAllProjectContactsFilteredAndSorted } from "@/app/store/project/projectContactsSlice";
import { selectCurrentProject } from "@/app/store/project/projectSlice";
import { BhFormType } from "@/model/IPdfExportForm";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { getUserFullName } from "@/model/IUser";
import BhTooltip from "@components/BhTooltip";

const ContactsExportDropdown: FC = () => {
  const { t } = useTranslation();
  const contacts = useAppSelector(selectAllProjectContactsFilteredAndSorted);
  const project = useAppSelector(selectCurrentProject);
  const csvLinkRef = useRef(null);
  const [csvExportData, setCsvExportData] = useState([] as Array<any>);

  useEffect(() => {
    if (csvExportData && csvExportData.length > 0) {
      // @ts-ignore
      csvLinkRef?.current?.link.click();
      setCsvExportData([] as Array<any>);
    }
  }, [csvExportData]);

  const exportContactsPDF = () => {
    const pdfData = {
      data: {
        title: t("PROJECT.CONTACTS"),
        thead: { name: t("COMPANY.NAME"), email: t("GLOBAL.EMAIL"), position: t("USER.INFO.TITLE"), company: t("USER.INFO.COMPANY"), phone: t("USER.INFO.PHONE") },
        contacts: contacts,
        projectName: project.name
      },
      formType: BhFormType.CONTACTS_PDF
    };
    fetchDownloadUrl(project.id, pdfData).then((response) => imitatePdfFromFormDownload(response));
  };

  const exportContactsCSV = () => {
    const csvData = contacts.map((contact) => {
      return { name: getUserFullName(contact), email: contact.email, position: contact.position, company: contact.company, phone: contact.phone };
    });
    setCsvExportData(csvData);
  };

  const exportDropdownValues = [
    {
      text: t("GLOBAL.PDF"),
      function: exportContactsPDF
    },
    {
      text: t("GLOBAL.CSV"),
      function: exportContactsCSV
    }
  ];

  return (
    <BhTooltip body={t("CONTACTS.EXPORT")}>
      <BhDropdown
        button={<BhIconButton icon={faArrowDownToLine} />}
        menu={<BhDropdownMenu values={exportDropdownValues} type={BhDropdownTypeEnum.STRING} widthClass={"w-32"} textProperty="text" />}
        position={BhDropdownPositionEnum.BOTTOM_LEFT}
      />
      <CSVLink data={csvExportData} ref={csvLinkRef} filename={t("PROJECT.CONTACTS") as string} separator={";"} className={"hidden"} />
    </BhTooltip>
  );
};

export default ContactsExportDropdown;
