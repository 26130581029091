import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { removePredefinedPartyUserAsync, savePredefinedPartyUserAsync, selectCompanyPredefinedPartyUsersById } from "@/app/store/companiesSlice";
import BhTableHeader from "@components/table/BhTableHeader";
import { Authority, IPredefinedWorkGroupUser } from "@/model/IUserAuthority";
import { useTranslation } from "react-i18next";
import { getUserFullName, IUser } from "@/model/IUser";
import BhLargeText from "@components/text/BhLargeText";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import PartyTemplateUserListItem from "@/views/home/company/detail/companySettingsPartyTemplates/PartyTemplateUserListItem";

interface Props {
  companyId: EntityId;
  predefinedPartyId: EntityId;
  usersFilter?: string;
}

const PartyTemplateUsersList: FC<Props> = ({ companyId, predefinedPartyId, usersFilter }) => {
  const { t } = useTranslation();
  const predefinedPartyUsers = useAppSelector((state) => selectCompanyPredefinedPartyUsersById(state, [companyId, predefinedPartyId]));
  const dispatch = useAppDispatch();

  if (!predefinedPartyUsers) return null;

  const tableColumnHeaders = [
    { id: 0, content: t("GLOBAL.USER"), field: "", classes: "w-64", sortable: false },
    { id: 1, content: t("GLOBAL.DRAWINGS"), field: "filesAuthLevel", classes: "w-32", sortable: false },
    { id: 2, content: t("GLOBAL.DOCUMENTS"), field: "documentsAuthLevel", classes: "w-32", sortable: false },
    { id: 3, content: t("PARTY.MODEL"), field: "modelAuthLevel", classes: "w-32", sortable: false },
    { id: 4, content: t("COMPANY.SETTINGS.ACT"), field: "actsAuthLevel", classes: "w-32", sortable: false },
    { id: 5, content: t("COMPANY.SETTINGS.CONTRACT"), field: "contractsAuthLevel", classes: "w-32", sortable: false },
    { id: 6, content: "", field: "", classes: "w-8", sortable: false }
  ];

  const dropdownMenuValues = [
    { value: Authority.NONE, translation: t("PARTY.NONE") },
    { value: Authority.READ, translation: t("PARTY.READ") },
    { value: Authority.WRITE, translation: t("PARTY.WRITE") },
    { value: Authority.ADMIN, translation: t("PARTY.ADMIN") }
  ];

  const changeUserPrivilege = (userId: EntityId, changedAuthority: IPredefinedWorkGroupUser) => {
    const user = predefinedPartyUsers.find((user) => user.id === userId);
    const updatedUser = { ...user, ...changedAuthority };
    dispatch(savePredefinedPartyUserAsync(updatedUser));
  };

  const filteredUsers = predefinedPartyUsers.filter((user) =>
    getUserFullName({ firstName: user.firstName, lastName: user.lastName } as IUser)
      .toLowerCase()
      .includes(usersFilter || "".toLowerCase())
  );

  const removeUser = (user: IPredefinedWorkGroupUser) => {
    dispatch(removePredefinedPartyUserAsync(user));
  };

  const listIsEmpty = predefinedPartyUsers.length === 0;
  const filteredListIsEmpty = filteredUsers.length === 0;

  return (
    <div className="w-full overflow-auto pb-40">
      {listIsEmpty && (
        <div className="flex w-full flex-col items-center">
          <BhNoMatchesImage />
          <BhLargeText classes="text-center">{t("PARTY.NO_USERS")}</BhLargeText>
        </div>
      )}
      {!listIsEmpty && (
        <table className="w-full px-2">
          <BhTableHeader columns={tableColumnHeaders} onClickCallback={() => {}} />
          <tbody className="divide-y">
            {filteredListIsEmpty && (
              <tr>
                <td colSpan={5}>
                  <BhLargeText classes="w-full text-center mt-10">{t("GLOBAL.NO_RESULTS")}</BhLargeText>
                </td>
              </tr>
            )}
            {filteredUsers?.map((user) => {
              return <PartyTemplateUserListItem key={user.id} user={user} onUserPrivilegeChange={changeUserPrivilege} dropdownMenuValues={dropdownMenuValues} onUserRemove={removeUser} />;
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default PartyTemplateUsersList;
