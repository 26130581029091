import React, { FC, useEffect, useRef, useState } from "react";
import { ISignInvite } from "@/model/ISignInvite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import BhSignatureContainerStatusForUser from "@components/container/BhSignatureContainerStatusForUser";
import { Link } from "react-router-dom";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { formatDateTime } from "@/utilities/dateUtility";
import { Trans } from "react-i18next";
import { useWindowSize } from "@/utilities/hooks/useWindowSize";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  signInvite: ISignInvite;
}

const SignInvitesUnsignedListItem: FC<Props> = ({ signInvite }) => {
  const { width } = useWindowSize();
  const [showNext, setShowNext] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setShowNext(containerRef.current ? containerRef.current?.clientWidth > 700 : true);
  }, [width]);

  return (
    <div ref={containerRef} className="bh-border-dark-jungle hover:bh-bg-mint-30 my-3 w-full overflow-hidden rounded-lg border-2">
      <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${signInvite.projectId}/container/${signInvite.container.id}`}>
        <div className="flex flex-row items-center px-6 py-5">
          <div className="flex flex-1 flex-col">
            <div className="text-18px mb-2 font-bold">{signInvite.container.name}</div>
            <div className="bh-text-deep-ocean-80">
              <span>
                <Trans>SIGN_INVITE.TABLE.CREATED</Trans> {formatDateTime(signInvite.created)}
              </span>
              <span className="text-9px bh-text-pigeon-50 px-2">
                <FontAwesomeIcon icon={faCircle} size="xs" />
              </span>
              <span>
                <Trans>SIGN_INVITE.TABLE.ASSIGNEE</Trans> <strong>{signInvite.assignee}</strong>
              </span>
            </div>
          </div>
          {showNext && (
            <div className="flex w-48 flex-col">
              <div className="text-12px mb-1.5">
                <Trans>CONTAINER.MY_STATUS</Trans>
              </div>
              <div>
                <BhSignatureContainerStatusForUser signInvite={signInvite} />
              </div>
            </div>
          )}
        </div>
        <div className="bh-bg-mint flex flex-row rounded-bl rounded-br p-4">
          <div className="flex flex-1 flex-row items-center py-2 px-4">
            <span className="font-bold">
              <Trans>CONTAINER.WAITING</Trans>
            </span>
          </div>
          <div className="flex flex-row items-center">
            <BhPrimaryButton>
              <Trans>CONTAINER.TO_SIGN</Trans>
            </BhPrimaryButton>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SignInvitesUnsignedListItem;
