import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhIconButton from "@components/buttons/BhIconButton";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons/faEllipsisVertical";
import { IDropdownItem } from "@/model/IDropdownItem";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { EntityId } from "@reduxjs/toolkit";
import { faCopy } from "@fortawesome/pro-solid-svg-icons";
import { deleteShareboxAsync, saveShareboxAsync, selectShareboxById } from "@/app/store/shareboxesSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhModal from "@components/modal/BhModal";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhModalFooter from "@components/modal/BhModalFooter";
import { ISharebox } from "@/model/shareboxes/ISharebox";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  shareboxId: EntityId;
}

const ShareboxDropdown: FC<Props> = ({ shareboxId }) => {
  const { t } = useTranslation();
  const [isRenameModalShown, setIsRenameModalShown] = useState<boolean>(false);
  const sharebox = useAppSelector((state) => selectShareboxById(state, shareboxId));
  const [shareboxName, setShareboxName] = useState<string>(sharebox?.name || "");
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteConfirmationModal}
      header={<h2>{t("MODAL.SHAREBOX.DELETE.CONFIRMATION.TITLE")}</h2>}
      body={<div>{t("MODAL.SHAREBOX.DELETE.CONFIRMATION.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={() => {
        dispatch(deleteShareboxAsync(shareboxId));
        //TODO händlida see, et õige tab jääks aktiivseks
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/shareboxes/project`);
      }}
    />
  ));

  if (!sharebox) {
    return null;
  }

  const dropdownItems: Array<IDropdownItem> = [
    {
      text: t("GLOBAL.RENAME"),
      function: () => {
        setIsRenameModalShown(true);
      },
      icon: faCopy
    },
    {
      text: t("GLOBAL.DELETE"),
      function: showDeleteConfirmationModal,
      icon: faTrash
    }
  ];
  const handleShareboxRename = () => {
    shareboxName.length > 0 && dispatch(saveShareboxAsync({ ...sharebox, name: shareboxName }));
    setIsRenameModalShown(false);
  };

  return (
    <>
      <BhDropdown
        button={<BhIconButton icon={faEllipsisVertical} buttonProps={{ classes: "bh-text-deep-ocean-80" }} />}
        menu={<BhDropdownMenu values={dropdownItems} type={BhDropdownTypeEnum.STRING} textProperty="text" />}
        position={BhDropdownPositionEnum.BOTTOM_LEFT}
        menuClasses="w-48"
      />
      <BhModal
        isShown={isRenameModalShown}
        setIsShown={setIsRenameModalShown}
        header={<h2>{t("SHAREBOX.EDIT.RENAME")}</h2>}
        footer={
          <BhModalFooter
            confirmButtonText={`${t("MODAL.DEFAULT.CONFIRMATION.OK")}`}
            cancelButtonText={`${t("MODAL.DEFAULT.CONFIRMATION.CANCEL")}`}
            onConfirm={handleShareboxRename}
            onCancel={() => setIsRenameModalShown(false)}
            confirmDisabled={!shareboxName || shareboxName.length === 0}
          />
        }
      >
        <div className="p-8">
          <BhInputStackedLabel initialValue={sharebox?.name} property="name" label={t("SHAREBOX.EDIT.INPUT_LABEL")} onChangeCallback={(val: ISharebox) => setShareboxName(val.name)} maxLength={300} />
        </div>
      </BhModal>
    </>
  );
};

export default ShareboxDropdown;
