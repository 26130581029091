import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { fetchProjectWorkGroupsAsync, selectProjectWorkGroupIdsFiltered } from "@/app/store/project/projectWorkGroupsSlice";
import ActsAndContractsListItem from "@/views/home/project/detail/actsAndContracts/ActsAndContractsListItem";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import PartiesSort from "@/views/home/project/detail/users/parties/PartiesSort";
import PartiesFilter from "@/views/home/project/detail/users/parties/PartiesFilter";
import { EntityId } from "@reduxjs/toolkit";

interface Props {}

const ActsAndContractsList: FC<Props> = () => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const partyIds = useAppSelector(selectProjectWorkGroupIdsFiltered);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSidebarCollapsed(false));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.CONTRACTS));
    dispatch(fetchProjectWorkGroupsAsync(projectId));
  }, []);

  return (
    <BhScrollableBody header={<PartiesFilter />} keepScrollForKey="actsAndContractsList">
      <PartiesSort />
      <div className="pt-4">
        {partyIds.map((partyId: EntityId) => (
          <ActsAndContractsListItem key={partyId} partyId={partyId} />
        ))}
      </div>
    </BhScrollableBody>
  );
};

export default ActsAndContractsList;
