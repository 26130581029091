import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import BhTableHeader from "@components/table/BhTableHeader";
import { useAppSelector } from "@/app/hooks";
import CompanySettingsFormBaseListRowContainer from "@components/form/settings/companySettings/CompanySettingsFormBaseListRowContainer";
import {
  makeSelectActivatedBasesInCompanyActiveProjects,
  selectCompanyCustomFormBases,
  selectCompanyFormBasesCreatedInProjects,
  selectCompanyFormBasesNotCreatedInProjects,
  selectCompanyFormBasesStatus
} from "@/app/store/form/formBasesSlice";
import { FormBaseVersionStatus } from "@/model/form/IFormBaseVersion";
import { IFormBase } from "@/model/form/IFormBase";
import { naturalSortByField } from "@/utilities/sortUtilities";
import CompanySettingsFormsBasketContainer from "@components/form/settings/companySettings/CompanySettingsFormsBasketContainer";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import ToggleFormBaseInProjectsModal from "@components/form/settings/companySettings/ToggleFormBaseInProjectsModal";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import FormsSettingsTableSkeleton from "@components/form/settings/FormsSettingsTableSkeleton";

interface Props {
  filter: any;
  setFilter: Dispatch<SetStateAction<any>>;
  companyId: EntityId;
  isProjectFormTable: boolean;
}

const CompanySettingsFormTable: FC<Props> = ({ filter, setFilter, companyId, isProjectFormTable }) => {
  const { t } = useTranslation();
  const companyFormBases = useAppSelector(isProjectFormTable ? selectCompanyFormBasesCreatedInProjects : selectCompanyFormBasesNotCreatedInProjects);
  const companyCustomFormBases = useAppSelector(selectCompanyCustomFormBases);
  const basesWithAtLeastOneFinishedVersion = companyFormBases.filter((cfb) => cfb.formBaseVersions && cfb.formBaseVersions.some((fbv) => fbv.status === FormBaseVersionStatus.FINAL));
  const selectActivatedBasesInCompanyProjects = useMemo(makeSelectActivatedBasesInCompanyActiveProjects, []);
  const activatedBasesInCompanyProjects = useAppSelector((state) => selectActivatedBasesInCompanyProjects(state, companyId));
  const formBaseStatus = useAppSelector(selectCompanyFormBasesStatus);
  const [selectedIds, setSelectedIds] = useState([] as Array<EntityId>);
  const [showProjectsAddModal, setShowProjectsAddModal] = useState(false);
  const [showProjectsRemoveModal, setShowProjectsRemoveModal] = useState(false);
  const [filteredAndSortedFormBases, setFilteredAndSortedFormBases] = useState([] as Array<any>);

  useEffect(() => {
    if (!showProjectsAddModal) {
      setSelectedIds([]);
    }
  }, [showProjectsAddModal]);

  useEffect(() => {
    if (!showProjectsRemoveModal) {
      setSelectedIds([]);
    }
  }, [showProjectsRemoveModal]);

  useEffect(() => {
    filterAndSortFormBases();
  }, [filter, companyFormBases, companyCustomFormBases]);

  const filterAndSortFormBases = useCallback(() => {
    const formBasesToFilter = isProjectFormTable ? companyFormBases : [...companyFormBases, ...companyCustomFormBases];
    const filteredFormBases = filterFormBases(formBasesToFilter);
    const sortedAndFilteredFormBases = sortFormBases(filteredFormBases);
    setFilteredAndSortedFormBases(sortedAndFilteredFormBases);
  }, [filter, companyFormBases, companyCustomFormBases]);

  const filterFormBases = (formBases: Array<IFormBase>) => {
    if (filter.searchString && filter.searchString.length > 0) {
      formBases = formBases.filter((base) => {
        const formNameValue = base.bauhubFormBase ? t(`FORMS.${base.type}`) : base.type;
        return formNameValue.toLowerCase().includes(filter.searchString.toLowerCase());
      });
    }
    if (filter.username && filter.username.length > 0) {
      formBases = formBases.filter((base) => base.createdBy.toLowerCase() === filter.username.toLowerCase());
    }
    if (filter.projectId) {
      formBases = formBases.filter((base) => base.createdInProjectId === filter.projectId);
    }
    return formBases;
  };

  const sortFormBases = (formBases: Array<IFormBase>) => {
    const filteredFormBases = filterFormBases(formBases);
    if (filter.orderBy === "type") {
      formBases = filteredFormBases.slice().sort((a, b) => {
        if (a.bauhubFormBase || a.createdBy === "custom") {
          a = { ...a, type: t("FORMS." + a.type) };
        }
        if (b.bauhubFormBase || b.createdBy === "custom") {
          b = { ...b, type: t("FORMS." + b.type) };
        }
        return naturalSortByField(a, b, filter.orderBy, filter.reversed);
      });
    }
    if (filter.orderBy === "created") {
      const bauhubTemplates = filteredFormBases
        .slice()
        .filter((fb) => fb.bauhubFormBase)
        .sort((a, b) => (t("FORMS." + a.type) > t("FORMS." + b.type) ? 1 : -1));
      const customTemplates = filteredFormBases
        .slice()
        .filter((fb) => fb.createdBy === "custom")
        .sort((a, b) => (t("FORMS." + a.type) > t("FORMS." + b.type) ? 1 : -1));
      const userTemplates = filteredFormBases.slice().filter((fb) => !fb.bauhubFormBase && fb.createdBy !== "custom");
      formBases = userTemplates
        .slice()
        .sort((a, b) =>
          a.formBaseVersions.reduce((max, row) => (max.versionNumber > row.versionNumber ? max : row)).created >
          b.formBaseVersions.reduce((max, row) => (max.versionNumber > row.versionNumber ? max : row)).created
            ? filter.reversed
              ? -1
              : 1
            : filter.reversed
            ? 1
            : -1
        );
      formBases = [...formBases, ...bauhubTemplates, ...customTemplates];
    }
    if (filter.orderBy === "active") {
      const activatedBases = filteredFormBases
        .filter((base) => activatedBasesInCompanyProjects.some((pfb) => pfb.formBaseId === base.id))
        .sort((a, b) => naturalSortByField(a, b, "type", filter.reversed));
      const inactiveBases = filteredFormBases
        .filter((base) => !activatedBasesInCompanyProjects.some((pfb) => pfb.formBaseId === base.id))
        .sort((a, b) => naturalSortByField(a, b, "type", filter.reversed));
      formBases = filter.reversed ? [...inactiveBases, ...activatedBases] : [...activatedBases, ...inactiveBases];
    }
    return formBases;
  };

  const toggleSelectAllRows = () => {
    if (selectedIds.length === basesWithAtLeastOneFinishedVersion.length) {
      setSelectedIds([]);
    } else {
      const allIds = basesWithAtLeastOneFinishedVersion.map((cb) => cb.id);
      setSelectedIds(allIds);
    }
  };

  const toggleRowSelection = (row: IFormBase) => {
    const rowAlreadySelected = selectedIds.find((id) => id === row.id);
    if (rowAlreadySelected) {
      setSelectedIds(selectedIds.filter((id) => id !== row.id));
    } else {
      setSelectedIds([...selectedIds, row.id]);
    }
  };

  const tableColumnHeaders: Array<any> = [
    !isProjectFormTable && {
      id: 0,
      content: <BhCheckbox large={true} property={"allChecked"} onChange={toggleSelectAllRows} isChecked={selectedIds.length === basesWithAtLeastOneFinishedVersion.length} />,
      classes: "w-10"
    },
    { id: 1, content: t("CHECKLIST.BASE.NAME"), field: "type", sortable: true },
    { id: 2, content: t("CHECKLIST.CHECKLIST_VERSION"), classes: "w-24" },
    { id: 3, content: t("CHECKLIST.CREATED"), field: "created", sortable: true, classes: "w-44" },
    !isProjectFormTable && { id: 4, content: t("FORMBUILDER.FORM.BASE.DEFAULT_ADMIN"), sortable: false, classes: "w-44 break-words" },
    isProjectFormTable && { id: 5, content: t("CHECKLIST.BASE.PROJECT"), classes: "w-80" },
    isProjectFormTable && { id: 6, content: t("CHECKLIST.ACTIVE_IN_PROJECT"), classes: "w-16 whitespace-nowrap", sortable: true, field: "active" },
    !isProjectFormTable && { id: 7, content: t("CHECKLIST.ACTIVE_IN_PROJECTS"), classes: "w-40" },
    { id: 8, content: "", classes: "w-10" }
  ].filter(Boolean);

  const onTableHeaderClickCallback = (column: IBhTableHeader<IFormBase>) => {
    if (column.field) {
      setFilter({ ...filter, orderBy: column.field, reversed: column.field === filter.orderBy ? !filter.reversed : filter.reversed });
    }
  };

  return (
    <div>
      <table className="break h-full w-full">
        <BhTableHeader columns={tableColumnHeaders} sortedBy={filter.orderBy} reversed={filter.reversed} onClickCallback={onTableHeaderClickCallback} />
        <tbody>
          {formBaseStatus === BhStateStatusType.PENDING && <FormsSettingsTableSkeleton isProjectFormTable={isProjectFormTable} />}
          {formBaseStatus === BhStateStatusType.SUCCESS && (
            <>
              {filteredAndSortedFormBases.length > 0 &&
                filteredAndSortedFormBases.map((formBase: IFormBase) => (
                  <CompanySettingsFormBaseListRowContainer
                    key={formBase.id}
                    companyId={companyId}
                    formBase={formBase}
                    isProjectFormBase={isProjectFormTable}
                    selectRow={() => toggleRowSelection(formBase)}
                    isRowSelected={selectedIds.some((id) => id === formBase.id)}
                  />
                ))}
              {isProjectFormTable && filteredAndSortedFormBases.length === 0 && (
                <tr>
                  <td colSpan={6} className="pt-10 text-center">
                    <h2>{t("CHECKLIST_BASE.NO_BASES_CREATED_IN_PROJECTS")}</h2>
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
      {selectedIds.length > 0 && (
        <CompanySettingsFormsBasketContainer
          selectedIds={selectedIds}
          allSelected={selectedIds.length === basesWithAtLeastOneFinishedVersion.length}
          resetSelected={() => setSelectedIds([])}
          addBasesToProjects={() => setShowProjectsAddModal(true)}
          removeBasesFromProjects={() => setShowProjectsRemoveModal(true)}
        />
      )}
      {showProjectsAddModal && <ToggleFormBaseInProjectsModal selectedIds={selectedIds} removeBases={false} setIsShown={setShowProjectsAddModal} companyId={companyId} />}
      {showProjectsRemoveModal && <ToggleFormBaseInProjectsModal selectedIds={selectedIds} removeBases={true} setIsShown={setShowProjectsRemoveModal} companyId={companyId} />}
    </div>
  );
};

export default CompanySettingsFormTable;
