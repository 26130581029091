import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { faCalendarDay } from "@fortawesome/pro-regular-svg-icons/faCalendarDay";
import { IFormBaseVersionRowField } from "@/model/form/IFormBaseVersionRowField";

interface Props {
  field: IFormBaseVersionRowField;
}

const FormProtocolFieldTableHeader: FC<Props> = ({ field }) => {
  const labels = field.value.labels;

  return (
    <div className="flex flex-row items-end gap-x-1 py-2 font-bold">
      <div className="min-w-14 w-14"></div>
      <div className="min-w-20 w-20 break-words">{labels.number}</div>
      <div className="w-full">{labels.content}</div>
      <div className="min-w-56 flex flex w-56 flex-row items-center gap-x-2">
        <FontAwesomeIcon icon={faUser} />
        <div className="min-w-0 flex-1 break-words">{labels.assignee}</div>
      </div>
      <div className="min-w-26 max-w-26 w-26 flex flex-row items-center gap-x-2">
        <FontAwesomeIcon icon={faCalendarDay} />
        <div className="min-w-0 flex-1 break-words">{labels.deadline}</div>
      </div>
      <div className="min-w-24 w-24 break-words text-center">{labels.status}</div>
      <div className="min-w-24 mr-11 w-24 break-words text-center">{labels.taskBoard}</div>
    </div>
  );
};

export default FormProtocolFieldTableHeader;
