import React, { FC } from "react";
import { faSquareKanban } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  combinedParams: any;
}

const NotificationTaskController: FC<Props> = ({ combinedParams }) => {
  return (
    <div className="bh-bg-white bh-border-pigeon-40 my-1 flex h-8 w-80 flex-row items-center rounded border">
      <div className="bh-bg-mediterranean ml-1 mr-3 flex h-6 w-6 items-center justify-center rounded">
        <FontAwesomeIcon icon={faSquareKanban} className="bh-text-white" aria-hidden="true" size="lg" />
      </div>
      <div className="bh-text-deep-ocean-80 mr-1 whitespace-nowrap">{combinedParams["taskIdentifier"]}</div>
      <div className="overflow-hidden text-ellipsis whitespace-nowrap pr-1 font-bold">{combinedParams["taskName"]}</div>
    </div>
  );
};

export default NotificationTaskController;
