import React, { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { fetchProjectContactsAsync, selectContactEditModalOpen, selectContactRightsModalOpen, setContactEditModalOpen, setEditableContact } from "@/app/store/project/projectContactsSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import EditContactModal from "@/views/home/project/detail/users/contacts/ContactEditModal";
import { IContact } from "@/model/IContact";
import ContactsFilter from "@/views/home/project/detail/users/contacts/ContactsFilter";
import ContactsTable from "@/views/home/project/detail/users/contacts/ContactsTable";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import ContactRightsModal from "@/views/home/project/detail/users/contacts/contactRightsModal/ContactRightsModal";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import BhFilterContainerWithItemsOnRight from "@components/filters/BhFilterContainerWithItemsOnRight";
import { fetchProjectWorkGroupsAsync } from "@/app/store/project/projectWorkGroupsSlice";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useTranslation } from "react-i18next";
import ContactsExportDropdown from "@/views/home/project/detail/users/contacts/ContactsExportDropdown";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";

const ContactsContainer: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const isEditModalOpen = useAppSelector(selectContactEditModalOpen);
  const isContactRightsModalOpen = useAppSelector(selectContactRightsModalOpen);
  const dispatch = useAppDispatch();

  useDocumentTitle("PROJECT.CONTACTS");

  useEffect(() => {
    dispatch(fetchProjectContactsAsync(projectId));
    dispatch(fetchProjectWorkGroupsAsync(projectId));
  }, [location]);

  const addNewContactClick = () => {
    dispatch(setEditableContact({} as IContact));
    dispatch(setContactEditModalOpen(true));
  };

  return (
    <BhScrollableBody
      header={
        <BhFilterContainerWithItemsOnRight
          right={
            <>
              <ContactsExportDropdown />
              {isProjectAdmin && (
                <BhSecondaryButton icon={faPlus} buttonProps={{ onClick: addNewContactClick, classes: "text-nowrap" }}>
                  {t("CONTRACT.ADD_CONTACT")}
                </BhSecondaryButton>
              )}
            </>
          }
        >
          <ContactsFilter />
        </BhFilterContainerWithItemsOnRight>
      }
    >
      <ContactsTable />
      {/* MODALS */}
      {isEditModalOpen && <EditContactModal />}
      {isContactRightsModalOpen && <ContactRightsModal />}
    </BhScrollableBody>
  );
};

export default ContactsContainer;
