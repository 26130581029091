import React, { FC } from "react";
import { ReactComponent as BhLogoPositive } from "@svg/bauhub-logo-colour-positive.svg";
import { ReactComponent as BhLogoNegative } from "@svg/bauhub-logo-colour-negative.svg";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUserTheme } from "@/app/store/userSlice";
import { Theme } from "@/model/IUserInfo";

const BhBauhubLogo: FC = () => {
  const theme = useAppSelector(selectCurrentUserTheme);

  return theme === Theme.DARK ? <BhLogoNegative className="h-4 w-auto" /> : <BhLogoPositive className="h-4 w-auto" />;
};

export default BhBauhubLogo;
