import React, { FC } from "react";
import { useTranslation } from "react-i18next";

const RevisionsHeaderWithLegend: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="mb-2 flex flex-row items-center justify-between">
      <div className="text-lg">{t("REVISIONS.REVISIONS")}</div>
      <div className="flex flex-row items-center gap-x-4">
        <div className="bh-text-deep-ocean">{t("REVISIONS.DIFFERENCES")}</div>
        <div className="flex flex-row items-center gap-x-1.5">
          <div className="bh-text-deep-ocean font-bold">1-2</div>
          <div className="bh-bg-success-green-110 h-4 w-4 rounded" />
        </div>
        <div className="flex flex-row items-center gap-x-1.5">
          <div className="bh-text-deep-ocean font-bold">3-6</div>
          <div className="bh-bg-warning-yellow-120 h-4 w-4 rounded" />
        </div>
        <div className="flex flex-row items-center gap-x-1.5">
          <div className="bh-text-deep-ocean font-bold">7+</div>
          <div className="bh-bg-error-red h-4 w-4 rounded" />
        </div>
      </div>
    </div>
  );
};

export default RevisionsHeaderWithLegend;
