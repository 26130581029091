import React, { FC, useEffect, useState } from "react";
import { MetaObject, Viewer } from "@xeokit/xeokit-sdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from "@fortawesome/pro-solid-svg-icons/faSquareCheck";
import { faSquare } from "@fortawesome/pro-regular-svg-icons/faSquare";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons/faAngleRight";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import { LoadedObjectInfo } from "@/views/home/project/detail/xeokit/XeokitWebViewer";
import { naturalSortString } from "@/utilities/sortUtilities";
import { Virtuoso } from "react-virtuoso";
import FileNameWrapped from "@/features/fileContainer/FileNameWrapped";
import { classNames } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUserLanguageForTranslation } from "@/app/store/userSlice";

interface Props {
  viewer: Viewer;
  loadedModels: Array<LoadedObjectInfo>;
  toggleModelVisible: Function;
  toggleAllModelsVisible: Function;
}

interface NodeElementProps {
  node: MetaObject;
  viewer: Viewer;
  toggleModelVisible?: Function;
  model?: LoadedObjectInfo;
  nestedLevel: number;
  openedNodes: Array<String>;
  setOpenedNodes: Function;
}

const NodeElement: FC<NodeElementProps> = ({ node, viewer, toggleModelVisible, model, nestedLevel, openedNodes, setOpenedNodes }) => {
  const isSceneObject = viewer.scene.objects[node.id];
  const someSceneObjectsInSubTreeHidden =
    !isSceneObject &&
    viewer.metaScene.getObjectIDsInSubtree(node.id).some((objectId) => {
      return viewer.scene.objects[objectId] && !viewer.scene.objects[objectId].visible;
    });
  const isOpen = openedNodes && openedNodes.length > 0 && openedNodes.some((nodeId) => node.id === nodeId);
  const isVisible = isSceneObject ? viewer.scene.objects[node.id].visible : !someSceneObjectsInSubTreeHidden;
  const hasChildren = node.children && node.children.length > 0;
  const visibleName = model?.name || node.name;

  const toggleVisibility = (node: MetaObject) => {
    const sceneEntity = viewer.scene.objects[node.id];
    if (sceneEntity) {
      viewer.scene.objects[node.id].visible = !viewer.scene.objects[node.id].visible;
    } else {
      if (hasChildren) {
        viewer.scene.setObjectsVisible(viewer.metaScene.getObjectIDsInSubtree(node.id), someSceneObjectsInSubTreeHidden ? true : false);
      }
    }
    // If is root, toggle also file visibility in localstorage (only root has model)
    if (model) {
      toggleModelVisible && toggleModelVisible(model);
    }
  };

  const toggleOpen = () => {
    const updatedOpenedNodes = !isOpen ? [...openedNodes, node.id] : openedNodes.filter((nodeId) => nodeId !== node.id);
    setOpenedNodes(updatedOpenedNodes);
  };

  const viewObject = () => {
    const sceneEntity = viewer.scene.objects[node.id];
    if (sceneEntity) {
      viewer.scene.setObjectsSelected(viewer.scene.selectedObjectIds, false);
      sceneEntity.selected = true;
    }
    const viewableIds = viewer.metaScene.getObjectIDsInSubtree(node.id);
    viewer.cameraFlight.flyTo({
      aabb: viewer.scene.getAABB(viewableIds),
      duration: 0.5
    });
  };

  const visibleChildren =
    hasChildren &&
    node.children.filter((c) => {
      const isNotSceneObjectAndHasNoChildren = !viewer.scene.objects[c.id] && (!c.children || c.children.length < 1);
      return c.id && !isNotSceneObjectAndHasNoChildren;
    });
  const sortedChildren = visibleChildren && visibleChildren.sort((a, b) => naturalSortString(a.name + "" + a.id, b.name + "" + b.id));

  const enableVirtualizedChildrenList = sortedChildren && sortedChildren.length * 24 > 300;
  const nodeWidth = 240 - nestedLevel * 4;

  return (
    <div>
      <div className="flex flex-row gap-x-1 py-0.5 md:gap-x-2 md:py-2 md:text-[1.2rem]">
        <div className="h-4 w-4 flex-shrink-0 md:h-5 md:w-5">
          {hasChildren && <FontAwesomeIcon className="h-4 w-4 cursor-pointer md:h-5 md:w-5" icon={isOpen ? faAngleDown : faAngleRight} onClick={toggleOpen} />}
        </div>
        <FontAwesomeIcon
          className="bh-text-bauhub-green-120 py-0.25 relative top-0 cursor-pointer text-lg md:-top-1 md:h-6 md:text-2xl"
          icon={isVisible ? faSquareCheck : faSquare}
          onClick={() => toggleVisibility(node)}
        />
        <div className="cursor-pointer" style={{ width: nodeWidth }} onClick={viewObject}>
          <FileNameWrapped fileName={visibleName} />
        </div>
      </div>
      {isOpen && hasChildren && (
        <div className="pl-2">
          {sortedChildren &&
            !enableVirtualizedChildrenList &&
            sortedChildren.map((child) => {
              return (
                <div className={classNames(nestedLevel & 1 ? "bh-bg-smoke" : "bh-bg-white", "rounded-sm")} key={child.id}>
                  <NodeElement node={child} viewer={viewer} nestedLevel={nestedLevel + 1} openedNodes={openedNodes} setOpenedNodes={setOpenedNodes} />
                </div>
              );
            })}

          {sortedChildren && enableVirtualizedChildrenList && (
            <div className={classNames(nestedLevel & 1 ? "bh-bg-smoke" : "bh-bg-white", "rounded-sm")}>
              <Virtuoso
                style={{ height: "300px", width: "100%", overflowX: "hidden" }}
                totalCount={sortedChildren.length}
                itemContent={(index) => (
                  <NodeElement node={sortedChildren[index]} viewer={viewer} key={sortedChildren[index].id} nestedLevel={nestedLevel + 1} openedNodes={openedNodes} setOpenedNodes={setOpenedNodes} />
                )}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const XeokitSidebarMenuObjectsTreeView: FC<Props> = ({ viewer, loadedModels, toggleModelVisible, toggleAllModelsVisible }) => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguageForTranslation);
  const [randomNumber, setRandomNumber] = useState<number>(1);
  const [openedNodes, setOpenedNodes] = useState<Array<String>>([]);

  useEffect(() => {
    const onObjectVisibility = viewer.scene.on("objectVisibility", (entity) => {
      setRandomNumber(Math.floor(Math.random() * 100000));
    });

    return function cleanup() {
      viewer.scene.off(onObjectVisibility);
    };
  }, []);

  //@ts-ignore
  const rootMetaObjects = viewer.metaScene.rootMetaObjects;
  const allVisible = Object.keys(viewer.scene.objects).length === Object.keys(viewer.scene.visibleObjects).length;

  const toggleAllVisible = () => {
    loadedModels.forEach((loadedModel: any) => {
      const rootObject = rootMetaObjects[loadedModel.rootMetaObjectId];
      if (rootObject) {
        const sceneEntity = viewer.scene.objects[rootObject.id];
        if (sceneEntity) {
          viewer.scene.objects[rootObject.id].visible = !allVisible;
        } else {
          if (rootObject.children && rootObject.children.length > 0) {
            viewer.scene.setObjectsVisible(viewer.metaScene.getObjectIDsInSubtree(rootObject.id), !allVisible);
          }
        }
      }
    });
    toggleAllModelsVisible(!allVisible);
  };

  return (
    <div>
      <div className="flex flex-row gap-x-1 py-0.5 md:gap-x-2 md:py-2 md:text-[1.2rem]">
        <div className="h-4 w-4 flex-shrink-0 md:h-5 md:w-5"></div>
        <FontAwesomeIcon
          className="bh-text-bauhub-green-120 py-0.25 relative top-0 cursor-pointer text-lg md:-top-1 md:h-6 md:text-2xl"
          icon={allVisible ? faSquareCheck : faSquare}
          onClick={toggleAllVisible}
        />
        <div>{t("BIM.SIDEBAR.SELECT_ALL", { lng: currentUserLanguage })}</div>
      </div>
      {loadedModels &&
        loadedModels.length > 0 &&
        loadedModels.map((loadedModel) => {
          const rootObject = rootMetaObjects[loadedModel.rootMetaObjectId];
          if (rootObject && rootObject.id) {
            return (
              <div key={loadedModel.id}>
                <NodeElement
                  key={loadedModel.id}
                  node={rootObject}
                  viewer={viewer}
                  model={loadedModel}
                  toggleModelVisible={toggleModelVisible}
                  nestedLevel={0}
                  openedNodes={openedNodes}
                  setOpenedNodes={setOpenedNodes}
                />
              </div>
            );
          }
        })}
    </div>
  );
};

export default XeokitSidebarMenuObjectsTreeView;
