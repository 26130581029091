import React, { FC, useState } from "react";
import { useAppSelector } from "@/app/hooks";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { classNames } from "@/utilities/jsUtilities";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import BhTableRow from "@components/table/BhTableRow";
import { selectCurrentUser } from "@/app/store/userSlice";
import ProjectAdministratorRemoveModal from "@components/projectSettings/ProjectAdministratorRemoveModal";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";

interface Props {
  admin: ISimpleValidUserAuthority;
  index: number;
}

const ProjectAdministratorsTableRow: FC<Props> = ({ admin, index }) => {
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectCurrentUser);
  const [administratorRemoveModalOpen, setAdministratorRemoveModalOpen] = useState<boolean>(false);

  return (
    <BhTableRow key={admin.id} classes={classNames("bh-border-pigeon-40", index !== 0 && "border-t")}>
      <td>
        <BhUserIconWithName user={admin} textClasses={"normal"} />
      </td>
      <td>{admin.username}</td>
      <td>{admin.userInfo?.title || ""}</td>
      <td>{admin.userInfo?.companyName || ""}</td>
      <td>{admin.userInfo?.contactNumber || ""}</td>
      <td>
        {admin.userEntityId !== currentUser.id && (
          <div className="flex flex-row justify-end">
            <BhTooltip body={t("USER.PROFILE.REMOVE")}>
              <BhIconButton icon={faCircleMinus} buttonProps={{ onClick: () => setAdministratorRemoveModalOpen(true) }} />
            </BhTooltip>
          </div>
        )}
      </td>
      {administratorRemoveModalOpen && <ProjectAdministratorRemoveModal modalOpen={administratorRemoveModalOpen} setModalOpen={setAdministratorRemoveModalOpen} authority={admin} />}
    </BhTableRow>
  );
};

export default ProjectAdministratorsTableRow;
