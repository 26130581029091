import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectAllChecklistBaseVersionRowsSorted } from "@/app/store/checklist/checklistBaseRowsSlice";
import { EntityId } from "@reduxjs/toolkit";
import ChecklistRow from "@components/checklists/checklist/body/ChecklistRow";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { selectIsChecklistRoundEditable } from "@/app/store/checklist/checklistsSlice";

interface Props {
  checklistId: EntityId;
  checklistRoundId: EntityId;
}

const ChecklistRows: FC<Props> = ({ checklistId, checklistRoundId }) => {
  const baseVersionRows = useAppSelector(selectAllChecklistBaseVersionRowsSorted);
  const projectId = useAppSelector(selectCurrentProjectId);
  const isRoundEditable = useAppSelector((state) => selectIsChecklistRoundEditable(state, projectId, checklistRoundId));

  if (!baseVersionRows) {
    return null;
  }

  return (
    <div className="mt-4 flex w-full flex-col">
      {baseVersionRows &&
        baseVersionRows.length > 0 &&
        baseVersionRows.map((baseVersionRow) => {
          return <ChecklistRow checklistId={checklistId} baseVersionRow={baseVersionRow} checklistRoundId={checklistRoundId} key={baseVersionRow.id} disabled={!isRoundEditable} />;
        })}
    </div>
  );
};

export default ChecklistRows;
