import { ITaskTag } from "@/model/taskBoard/ITaskTag";
import { IProjectTaskCategory } from "@/model/taskBoard/IProjectTaskCategory";
import { IUserAuthority } from "@/model/IUserAuthority";

export interface ITasksFilter {
  name: string;
  categories: Array<IProjectTaskCategory>;
  tags: Array<ITaskTag>;
  tagSearch: string;
  userRoles: Array<ITaskUserRoleFilter>;
  users: Array<IUserAuthority>;
  showArchived: boolean;
}

export interface ITaskUserRoleFilter {
  name: TaskUserRoleFilterEnum;
  translation: string;
}

export enum TaskUserRoleFilterEnum {
  REPORTER = "REPORTER",
  ASSIGNEE = "ASSIGNEE",
  PARTICIPANT = "PARTICIPANT"
}

export const initialTasksFilter = {
  users: [] as Array<IUserAuthority>,
  userRoles: [] as Array<ITaskUserRoleFilter>,
  tags: [] as Array<ITaskTag>,
  categories: [] as Array<IProjectTaskCategory>,
  showArchived: false
} as ITasksFilter;
