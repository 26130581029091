import React, { FC } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";

//FIGMA: https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H/Bauhub-design-system?node-id=296%3A4329

interface Props {
  icon: IconDefinition;
  tagText: string;
  onSelect: Function;
  dropdownPosition: BhDropdownPositionEnum;
  values?: Array<any>;
  textProperty?: string;
  translateValues?: boolean;
  disabled?: boolean;
}

const BhTagWithDropdown: FC<Props> = ({ icon, tagText, onSelect, values, dropdownPosition, textProperty, translateValues, disabled }) => {
  return (
    <div>
      {!disabled && (
        <BhDropdown
          button={
            <div className="py-0.25 l-h-14 bh-border-pigeon-40 bh-bg-pigeon-20 bh-text-deep-ocean-80 hover:bh-bg-pigeon-40 my-0.5 mr-1 inline-block flex w-fit cursor-pointer flex-row items-center rounded-xl border pl-1.5 pr-0.5">
              <span className="text-13 font-semibold">{tagText}</span>
              <div className="h-4">
                <div className="hover:bh-bg-deep-ocean hover:bh-text-white ml-0.5 inline-block flex h-4 w-4 flex-row items-center justify-center rounded-full text-center ">
                  <FontAwesomeIcon icon={icon} size={"xs"} />
                </div>
              </div>
            </div>
          }
          menu={
            <BhDropdownMenu
              values={values}
              textProperty={textProperty}
              type={BhDropdownTypeEnum.STRING}
              onSelect={onSelect}
              widthClass={"w-fit whitespace-nowrap"}
              closeOnItemClick={true}
              translateValues={translateValues}
            />
          }
          position={dropdownPosition}
          disabled={disabled}
        />
      )}
      {disabled && <BhTag type={BhTagType.TAG} children={tagText} />}
    </div>
  );
};

export default BhTagWithDropdown;
