import React, { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const BhLink: FC<Props> = ({ children }) => {
  return <div className="text-14px bh-text-deep-ocean cursor-pointer leading-5 hover:underline">{children}</div>;
};

export default BhLink;
