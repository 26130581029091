import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { ITabCardType } from "@components/backoffice/company/projects/BOCompanyProjectsTabCards";

interface Props {
  title: string;
  type: ITabCardType;
  value: number;
  icon?: React.ReactNode;
}

const BOProjectTabCard: FC<Props> = ({ title, type, value, icon }) => {
  const backgroundMap: Record<ITabCardType, string> = {
    [ITabCardType.DEFAULT]: "bh-bg-smoke",
    [ITabCardType.SUCCESS]: "bh-bg-success-green-10",
    [ITabCardType.WARNING]: "bh-bg-warning-yellow-10",
    [ITabCardType.ERROR]: "bh-bg-error-red-10"
  };

  return (
    <div className={classNames("bh-bg-smoke bh-text-deep-ocean w-full min-w-[175px] p-4", backgroundMap[type])}>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1">
          {icon}
          <p>{title}</p>
        </div>
        <h2>{value}</h2>
      </div>
    </div>
  );
};

export default BOProjectTabCard;
