import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { fetchChecklistBaseVersionRows } from "@/api/checklist/checklistAPI";
import { ChecklistRowType, IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import {
  attachNewFilesToChecklistBaseVersionRow,
  deleteChecklistBaseVersionRow,
  deleteFileFromChecklistBaseVersionRow,
  fetchChecklistBaseVersionRowsForEditing,
  saveChecklistBaseVersionRow,
  saveNewChecklistBaseVersionRow
} from "@/api/checklist/checklistBaseAPI";

export const checklistBaseRowsAdapter = createEntityAdapter<IChecklistBaseVersionRow>();

export interface IChecklistBaseRowsState {
  status: BhStateStatusType;
}

export const checklistBaseRowsInitialState = checklistBaseRowsAdapter.getInitialState<IChecklistBaseRowsState>({
  status: BhStateStatusType.INITIAL
});

export const fetchChecklistBaseVersionRowsAsync = createAsyncThunk("checklist/base/version/rows", async (checklistId: EntityId, { getState }) => {
  const state: RootState = getState() as RootState;
  const checklist = state.checklists.entities[checklistId];
  const checklistBaseVersionId = checklist && checklist.checklistBaseVersionId;
  if (checklistBaseVersionId) {
    return fetchChecklistBaseVersionRows(checklistId, checklistBaseVersionId);
  }
  return [] as Array<IChecklistBaseVersionRow>;
});

export const fetchChecklistBaseVersionRowsForEditingAsync = createAsyncThunk(
  "checklist/base/version/rows/edit",
  async (dto: { companyId: EntityId; checklistBaseId: EntityId; checklistBaseVersionId: EntityId }) => {
    return fetchChecklistBaseVersionRowsForEditing(dto.companyId, dto.checklistBaseId, dto.checklistBaseVersionId);
  }
);

export const saveChecklistBaseVersionRowAsync = createAsyncThunk(
  "checklist/base/version/row/save",
  async (dto: { companyId: EntityId; checklistBaseId: EntityId; checklistBaseVersionRow: IChecklistBaseVersionRow }) => {
    return saveChecklistBaseVersionRow(dto.companyId, dto.checklistBaseId, dto.checklistBaseVersionRow);
  }
);

export const attachNewFilesToChecklistBaseVersionRowAsync = createAsyncThunk(
  "checklist/base/version/row/attachment/save",
  async (dto: { companyId: EntityId; checklistBaseId: EntityId; baseRowId: EntityId; fileIds: Array<EntityId>; isUpload: boolean }) => {
    return attachNewFilesToChecklistBaseVersionRow(dto.companyId, dto.checklistBaseId, dto.baseRowId, dto.fileIds, dto.isUpload);
  }
);

export const deleteFileFromChecklistBaseVersionRowAsync = createAsyncThunk(
  "checklist/base/version/row/attachment/delete",
  async (dto: { companyId: EntityId; checklistBaseId: EntityId; baseRowId: EntityId; fileId: EntityId }) => {
    return deleteFileFromChecklistBaseVersionRow(dto.companyId, dto.checklistBaseId, dto.baseRowId, dto.fileId);
  }
);

export const deleteChecklistBaseVersionRowAsync = createAsyncThunk(
  "checklist/base/version/row/delete",
  async (dto: { companyId: EntityId; checklistBaseVersionId: EntityId; checklistBaseId: EntityId; checklistBaseVersionRowId: EntityId }) => {
    return deleteChecklistBaseVersionRow(dto.companyId, dto.checklistBaseId, dto.checklistBaseVersionId, dto.checklistBaseVersionRowId);
  }
);

export const saveNewChecklistBaseVersionRowAsync = createAsyncThunk(
  "checklist/base/version/row/new/save",
  async (dto: { companyId: EntityId; checklistBaseId: EntityId; checklistBaseVersionRow: IChecklistBaseVersionRow }) => {
    return saveNewChecklistBaseVersionRow(dto.companyId, dto.checklistBaseId, dto.checklistBaseVersionRow);
  }
);

const checklistBaseRowsSlice = createSlice({
  name: "checklistBaseRows",
  initialState: checklistBaseRowsInitialState,
  reducers: {
    removeAllChecklistBaseRows: checklistBaseRowsAdapter.removeAll,
    addOneChecklistBaseRow: checklistBaseRowsAdapter.addOne,
    modifyOneChecklistBaseRow: checklistBaseRowsAdapter.upsertOne,
    modifyChecklistBaseRows: checklistBaseRowsAdapter.upsertMany
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChecklistBaseVersionRowsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchChecklistBaseVersionRowsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const baseVersionRows = action.payload;
        checklistBaseRowsAdapter.setAll(state, baseVersionRows);
      })
      .addCase(fetchChecklistBaseVersionRowsForEditingAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchChecklistBaseVersionRowsForEditingAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const baseVersionRows = action.payload;
        checklistBaseRowsAdapter.setAll(state, baseVersionRows);
      })
      .addCase(saveChecklistBaseVersionRowAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveChecklistBaseVersionRowAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        checklistBaseRowsAdapter.upsertOne(state, action.payload);
      })
      .addCase(attachNewFilesToChecklistBaseVersionRowAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(attachNewFilesToChecklistBaseVersionRowAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const file = action.payload[0];
        const baseVersionRow = Object.values(state.entities).find((row) => row && row.id === file.resourceId);
        if (baseVersionRow) {
          baseVersionRow.attachments = baseVersionRow.attachments ? [...baseVersionRow.attachments, ...action.payload] : action.payload;
        }
      })
      .addCase(deleteFileFromChecklistBaseVersionRowAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(deleteFileFromChecklistBaseVersionRowAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const baseVersionRow = Object.values(state.entities).find((row) => row && row.id === action.payload.baseVersionRowId);
        if (baseVersionRow) {
          baseVersionRow.attachments = baseVersionRow.attachments.filter((attachment) => attachment.id !== action.payload.fileEntityId);
        }
      })
      .addCase(deleteChecklistBaseVersionRowAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(deleteChecklistBaseVersionRowAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        checklistBaseRowsAdapter.setAll(state, action.payload.checklistBaseVersionRows);
      })
      .addCase(saveNewChecklistBaseVersionRowAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveNewChecklistBaseVersionRowAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        checklistBaseRowsAdapter.setAll(state, action.payload.checklistBaseVersionRows);
      });
  }
});

export const { addOneChecklistBaseRow, modifyOneChecklistBaseRow, removeAllChecklistBaseRows, modifyChecklistBaseRows } = checklistBaseRowsSlice.actions;

export const {
  selectAll: selectAllChecklistBaseRows,
  selectById: selectChecklistBaseRowById,
  selectIds: selectChecklistBaseRowIds
} = checklistBaseRowsAdapter.getSelectors((state: RootState) => state.checklistBaseRows);

export const selectAllChecklistBaseVersionRowsSorted = createSelector([selectAllChecklistBaseRows], (baseRows) => {
  return baseRows.slice().sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1));
});

export const selectIsNextBaseVersionRowSubPoint = createSelector([selectAllChecklistBaseRows, (state: any, baseRowId: EntityId) => baseRowId], (baseRows, baseRowId) => {
  const currentRow = baseRows.find((baseRow) => baseRow.id === baseRowId);
  if (currentRow && baseRows.some((baseRow) => baseRow.orderNumber > currentRow.orderNumber)) {
    const nextRow = baseRows.find((baseRow) => baseRow.orderNumber === currentRow.orderNumber + 1);
    if (nextRow) {
      return nextRow.checklistRowType === ChecklistRowType.CHECKPOINT && nextRow.indentation === 1;
    }
  }
  return false;
});

export default checklistBaseRowsSlice.reducer;
