import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "@/app/hooks";
import { selectProjectById } from "@/app/store/companiesSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EntityId } from "@reduxjs/toolkit";
import BhTooltip from "@components/BhTooltip";
import BhProjectStatusBadge from "@components/badge/BhProjectStatusBadge";
import { ProjectSecurityLevel } from "@/model/IProject";
import ProjectListNotificationButton from "@/features/projectList/projectListItem/ProjectListNotificationButton";
import ProjectListSignatureButton from "@/features/projectList/projectListItem/ProjectListSignatureButton";
import { isMaruProject } from "@/utilities/customClients/maruUtilities";
import ProjectListItemPin from "@/features/projectList/projectListItem/ProjectListItemPin";
import { selectCountOfUnsignedInvitesForProject } from "@/app/store/signaturesSlice";
import { faBookmark } from "@fortawesome/pro-solid-svg-icons/faBookmark";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  projectId: EntityId;
  dontShowPin?: boolean;
}

const BhCompanyDropdownModalProjectItem: FC<Props> = ({ projectId, dontShowPin }) => {
  const project = useAppSelector((state) => selectProjectById(state, projectId));
  const invitesCountForProject = useAppSelector(selectCountOfUnsignedInvitesForProject(projectId));

  if (!project) return null;

  return (
    <BhTooltip body={project.name.length > 40 ? project.name : ""} delayShow={1000}>
      <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${project.id}/overview`} reloadDocument={true}>
        <div className=" hover:bh-bg-smoke group flex h-10 flex-row items-center justify-between overflow-hidden pr-6 pl-4">
          <div className="flex flex-row items-center overflow-hidden">
            {!dontShowPin && project.pinned && <FontAwesomeIcon icon={faBookmark} className="bh-text-pigeon mr-2 ml-2" />}
            {(project.securityLevel === ProjectSecurityLevel.HIGH || project.securityLevel === ProjectSecurityLevel.MEDIUM) && (
              <div className="flex h-10 items-stretch pr-2">
                <BhProjectStatusBadge type={project.securityLevel} />
              </div>
            )}
            <div className="bh-text-deep-ocean line truncate pl-2 text-lg font-normal">{(isMaruProject(project) ? project.contractNumber + " " : "") + project.name}</div>
          </div>
          <div className="flex flex-row">
            {dontShowPin && <ProjectListItemPin projectId={project.id} showOnlyOnHover={true} classes="pr-1" />}
            <ProjectListSignatureButton projectId={project.id} classes="mr-1" />
            <ProjectListNotificationButton projectId={project.id} fixedWidth={invitesCountForProject > 0} />
          </div>
        </div>
      </Link>
    </BhTooltip>
  );
};

export default BhCompanyDropdownModalProjectItem;
