import React, { FC, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { ICompanyModule } from "@/model/ICompany";

interface Props {
  setIsShown: (value: boolean) => void;
  module: ICompanyModule;
  onSaveCallback: (module: ICompanyModule) => void;
  property: string;
  label: string;
}

const BOCompanyEditModuleModal: FC<Props> = ({ setIsShown, module, onSaveCallback, property, label }) => {
  const [newModule, setNewModule] = useState<ICompanyModule>(module);
  const initialValue = (module && module?.adminSettings && module?.adminSettings[property]) ?? "";

  const onChange = (changedValue: Record<string, string>) => {
    const changedModule = { ...module, adminSettings: { ...changedValue } };
    setNewModule(changedModule);
  };
  const onSave = () => {
    onSaveCallback && onSaveCallback(newModule);
  };
  return (
    <BhModal
      size="3xl"
      header={
        <h2>
          <span className="bh-text-deep-ocean-60">Edit module</span> – Custom form name
        </h2>
      }
      footer={<BhModalFooter cancelButtonText="Cancel" onCancel={() => setIsShown(false)} confirmButtonText="Save changes" onConfirm={onSave} />}
      setIsShown={setIsShown}
    >
      <div className="px-20 pt-8 pb-20">
        <BhInputStackedLabel initialValue={initialValue} property={property} label={label} onChangeCallback={onChange} />
      </div>
    </BhModal>
  );
};

export default BOCompanyEditModuleModal;
