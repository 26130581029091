import React, { FC } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { useTranslation } from "react-i18next";
import FormTableFieldHeader from "@components/form/fields/tableField/FormTableFieldHeader";
import FormTableFieldRow from "@components/form/fields/tableField/FormTableFieldRow";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons/faCirclePlus";

interface Props {
  rows?: any;
  cols?: any;
}

const FormTableFieldTablePreview: FC<Props> = ({ rows, cols }) => {
  const { t } = useTranslation();

  return (
    <div>
      {rows.map((row: any, rowIndex: number) => (
        <div className="flex flex-col gap-1" key={rowIndex}>
          {rowIndex === 0 && <FormTableFieldHeader columns={cols} />}
          <FormTableFieldRow row={row} columns={cols} disabled={true} />
        </div>
      ))}
      <div className="mt-1 flex flex-row justify-between">
        <BhIconButton icon={faCirclePlus} buttonProps={{ disabled: true }} sizeClasses="w-max">
          {t("FORMBUILDER.FORM.BASE.FIELD.WEATHER.ADD_ROW")}
        </BhIconButton>
      </div>
    </div>
  );
};
export default FormTableFieldTablePreview;
