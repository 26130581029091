import React, { FC, useEffect } from "react";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import BhTableHeader from "@components/table/BhTableHeader";
import { useTranslation } from "react-i18next";
import { checklistStatisticsFilterInitialState, IChecklistStatisticsFilter } from "@/model/checklist/IChecklistFilter";
import { useAppDispatch } from "@/app/hooks";
import { checklistStatisticsFilterSet } from "@/app/store/checklist/checklistsSlice";
import { IChecklistData } from "@/model/checklist/IChecklistStatistics";

const ChecklistStatisticsTableHeader: FC = () => {
  const { t } = useTranslation();
  const [checklistsOrdering, setChecklistsOrdering] = useLocalStorage<IChecklistStatisticsFilter>("checklistsStatisticsOrdering", checklistStatisticsFilterInitialState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    checklistsOrdering && dispatch(checklistStatisticsFilterSet(checklistsOrdering));
  }, []);

  const tableColumnHeaders: Array<IBhTableHeader<IChecklistData>> = [
    { id: 1, content: t("CHECKLIST.CHECKLIST_NAME"), field: "name", sortable: true },
    { id: 2, content: t("CHECKLIST.ROUND"), classes: "w-20 text-center" },
    { id: 3, content: t("CHECKLIST.STATISTICS.DATE"), field: "firstCheck", sortable: true, classes: "w-44" },
    { id: 4, content: t("CHECKLIST.STATISTICS.FAULT_COUNT"), classes: "w-40" },
    { id: 5, content: t("CHECKLIST.STATISTICS.NEW_FAULT_COUNT"), classes: "w-40" },
    { id: 6, content: t("CHECKLIST.STATISTICS.FAULT_POINTS"), classes: "w-52" }
  ];

  const onTableHeaderClick = (column: IBhTableHeader<IChecklistData>) => {
    if (column.field) {
      const updatedOrdering = { orderBy: column.field, orderAsc: column.field === checklistsOrdering.orderBy ? !checklistsOrdering.orderAsc : checklistsOrdering.orderAsc };
      setChecklistsOrdering(updatedOrdering);
      dispatch(checklistStatisticsFilterSet(updatedOrdering));
    }
  };

  return <BhTableHeader columns={tableColumnHeaders} sortedBy={checklistsOrdering.orderBy} reversed={!checklistsOrdering.orderAsc} onClickCallback={onTableHeaderClick} />;
};

export default ChecklistStatisticsTableHeader;
