import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IPublicContainer } from "@/model/container/IPublicContainer";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { naturalSortByField } from "@/utilities/sortUtilities";
import BhLargeText from "@components/text/BhLargeText";
import BhTableHeader from "@components/table/BhTableHeader";
import SignaturesListItem from "@/views/home/project/detail/container/containerComponents/SignaturesListItem";
import { IContainer } from "@/model/container/IContainer";

interface Props {
  publicContainer?: IPublicContainer;
}

const PublicContainerSignaturesList: FC<Props> = ({ publicContainer }) => {
  const { t } = useTranslation();

  const tableColumnHeaders: Array<IBhTableHeader<IContainer>> = [
    { id: 2, content: t("GLOBAL.NAME") },
    { id: 3, content: t("CONTAINER.TABLE.DATE"), classes: "w-44" },
    { id: 4, content: t("CONTAINER.TABLE.VALIDITY"), classes: "w-32 text-center" }
  ];

  const sortedSignatures = [...(publicContainer?.signatures || [])].sort((a, b) => naturalSortByField(a, b, "claimedSigningTime"));

  return (
    <>
      {publicContainer?.signatures && publicContainer?.signatures.length < 1 && (
        <BhLargeText classes="text-center flex pt-6 items-center justify-center w-full">{t("CONTAINER.SIGNATURES_MISSING") as string}</BhLargeText>
      )}
      {publicContainer?.signatures && publicContainer?.signatures.length > 0 && (
        <table className="min-w-full text-left">
          <BhTableHeader columns={tableColumnHeaders} />
          <tbody className="divide-y overflow-hidden">
            {sortedSignatures.map((signature, index) => {
              const signatureAdditionalFieldsFilled = signature.roleResolution || signature.country || signature.county || signature.city || signature.zip;
              return <SignaturesListItem key={signature.uniqueId} signature={signature} queueEnabled={false} index={index} signatureAdditionalFieldsFilled={signatureAdditionalFieldsFilled} />;
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default PublicContainerSignaturesList;
