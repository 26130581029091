import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { IFileEntity } from "@/model/files/IFileEntity";
import BhModal from "@components/modal/BhModal";
import InitialQueueSignatureAddUserInput from "@/views/home/project/detail/container/containerComponents/InitialQueueSignatureAddUserInput";
import { IUser } from "@/model/IUser";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import InitialQueueSignaturesList from "@/views/home/project/detail/container/containerComponents/InitialQueueSignaturesList";
import { saveQueueEnabledSignInvitesAsync } from "@/app/store/signaturesSlice";
import { useTranslation } from "react-i18next";
import { InviteType } from "@/model/ISignInvite";

interface Props {
  containerFileEntity: IFileEntity;
}

const InitialQueueSignaturesContainer: FC<Props> = ({ containerFileEntity }) => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const [signatures, setSignatures] = useState<Array<ISignatureHolder>>([] as Array<ISignatureHolder>);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const findBiggestOrderNumber = (): number => {
    if (signatures.length === 0) {
      return 0;
    }
    const biggestOrderNumber = signatures.sort((a, b) => a.orderNumber - b.orderNumber).reverse()[0];
    return biggestOrderNumber.orderNumber;
  };

  const firstUserSelected = (user: IUser) => {
    userSelected(user);
    setModalOpen(true);
  };

  const userSelected = (user: IUser) => {
    if (signatures.find((s) => s.username === user.username)) return;
    let signInvite: ISignatureHolder = {
      containerUuid: containerFileEntity.uuid,
      declined: false,
      fileEntityId: containerFileEntity.id,
      givenName: user.firstName,
      surname: user.lastName,
      username: user.username,
      signed: false,
      projectId: projectId,
      orderNumber: findBiggestOrderNumber() + 10,
      isUnsignedInvite: true
    } as ISignatureHolder;

    if (!user.id) {
      signInvite.inviteType = InviteType.PUBLIC;
      signInvite.isUnsignedInvite = true;
      signInvite.publicInvite = true;
    }

    setSignatures([...signatures, signInvite]);
  };

  const setModalOpenAndClearSignatures = (value: boolean) => {
    setModalOpen(value);
    if (!value) {
      setSignatures([]);
    }
  };

  const sendInvites = () => {
    dispatch(saveQueueEnabledSignInvitesAsync(signatures));
  };

  return (
    <div>
      <InitialQueueSignatureAddUserInput userSelectedCallback={firstUserSelected} signatures={signatures} />
      <BhModal
        isShown={modalOpen}
        setIsShown={setModalOpenAndClearSignatures}
        header={<h2>{t("CONTAINER.INITIAL_QUEUE_MODAL_TITLE")}</h2>}
        footer={
          <div>
            <BhTextOnlyButton buttonProps={{ onClick: () => setModalOpenAndClearSignatures(false) }}>{t("GLOBAL.CANCEL")}</BhTextOnlyButton>
            <BhPrimaryButton buttonProps={{ onClick: () => sendInvites() }}>{t("CONTAINER.SEND_INVITES")}</BhPrimaryButton>
          </div>
        }
        size="3xl"
      >
        <div className="mb-4 px-4 pt-6 pb-2">
          <InitialQueueSignaturesList signatures={signatures} setSignatures={setSignatures} />
          <div className="pt-2 pb-8">
            <InitialQueueSignatureAddUserInput userSelectedCallback={userSelected} signatures={signatures} />
          </div>
        </div>
      </BhModal>
    </div>
  );
};

export default InitialQueueSignaturesContainer;
