import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import BhCardContainer from "@components/cards/BhCardContainer";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useNavigate } from "react-router-dom";
import { EntityId } from "@reduxjs/toolkit";
import { faReceipt } from "@fortawesome/pro-solid-svg-icons/faReceipt";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import PartiesListItemTag from "@/views/home/project/detail/users/parties/PartiesListItemTag";
import BhLargeBoldText from "@components/text/BhLargeBoldText";
import { selectActsPrivilegesForParty, selectContractPrivilegesForParty, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { classNames } from "@/utilities/jsUtilities";
import { selectProjectWorkGroupById } from "@/app/store/project/projectWorkGroupsSlice";
import { Trans } from "react-i18next";
import { faGear } from "@fortawesome/pro-regular-svg-icons/faGear";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  partyId: EntityId;
}

const ActsAndContractsListItem: FC<Props> = ({ partyId }) => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const party = useAppSelector((state) => selectProjectWorkGroupById(state, partyId));
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const privilegesForActs = useAppSelector((state) => !isProjectAdmin && party && selectActsPrivilegesForParty(state, party.id));
  const privilegesForContract = useAppSelector((state) => !isProjectAdmin && party && selectContractPrivilegesForParty(state, party.id));
  const navigate = useNavigate();

  const showPartyInActsAndContractsList = isProjectAdmin || privilegesForActs.isRead || privilegesForContract.isRead;
  const showContractButton = isProjectAdmin || privilegesForContract.isRead;
  const showActs = isProjectAdmin || privilegesForActs.isRead;

  if (!showPartyInActsAndContractsList || !party) return null;

  const onActButtonClick = () => {
    if (showActs && party.type) {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/contract/${partyId}/acts`);
    }
  };

  const onContractButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/contract/${partyId}`);
    // navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/contract/${partyId}/info`);
  };

  const onPartyEditButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/users/party/${partyId}/general`);
  };

  return (
    <BhCardContainer onClick={onActButtonClick} classes={classNames((!showActs || !party.type) && "!cursor-default")}>
      <div className="flex w-full flex-row items-center px-4 py-3">
        <div className="flex flex-1 flex-col">
          <BhLargeBoldText>{party.name}</BhLargeBoldText>
          <div className="flex flex-row items-center pb-1">
            {party.type && <PartiesListItemTag workGroupType={party.type} />}
            {!party.type && <Trans>CONTRACT.TYPE_MISSING</Trans>}
          </div>
        </div>
        {party.type && (
          <div className="flex flex-row items-center">
            {/*<ActsAndContractsListActInfo partyId={partyId} />*/}
            {showContractButton && (
              <div className="flex w-28 flex-row items-center justify-end">
                <BhTextOnlyButton icon={faReceipt} buttonProps={{ onClick: (e: React.MouseEvent<HTMLDivElement>) => onContractButtonClick(e) }}>
                  <Trans>CONTRACT.CONTRACT</Trans>
                </BhTextOnlyButton>
              </div>
            )}
          </div>
        )}
        {!party.type && isProjectAdmin && (
          <BhPrimaryButton buttonProps={{ onClick: (e: React.MouseEvent<HTMLDivElement>) => onPartyEditButtonClick(e) }} icon={faGear}>
            <Trans>WORKGROUP.EXISTING.TITLE</Trans>
          </BhPrimaryButton>
        )}
      </div>
    </BhCardContainer>
  );
};

export default ActsAndContractsListItem;
