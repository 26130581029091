import React, { FC, useEffect, useState } from "react";
import { boSaveNewProjectAsync, boSelectCompany } from "@/app/store/backofficeSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import BhInputLabel from "@components/input/BhInputLabel";
import { IProject } from "@/model/IProject";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhComboboxStackedLabel from "@components/input/BhComboboxStackedLabel";
import { countriesMap } from "@/utilities/countriesMap";
import BhCheckboxWithText from "@components/checkboxes/BhCheckboxWithText";
import BhToggleButtonBar, { IBhToggleButtonBarItem } from "@components/toggle/BhToggleButtonBar";
import { ProjectInvoiceType } from "@/model/IBillingInfo";
import { SubResource } from "@/model/IUserAuthority";
import BOCompanyNewProjectAdministrators from "@components/backoffice/company/projects/BOCompanyNewProjectAdministrators";

interface Props {
  setIsShown: (value: boolean) => void;
}

const BOCompanyNewProjectModal: FC<Props> = ({ setIsShown }) => {
  const company = useAppSelector(boSelectCompany);
  const initialNewProjectValue = {
    companyId: company.id,
    country: "Eesti",
    billing: { customerCountry: "Eesti", customerRecipient: company.name, type: ProjectInvoiceType.MONTH, einvoice: true }
  } as IProject;
  const [newProject, setNewProject] = useState<IProject>(initialNewProjectValue);
  const countries = countriesMap.map((country) => country.country);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setNewProject({ ...newProject, relatedUsers: [...company.users.filter((user) => user.subResource === SubResource.NEW_PROJECT_DEFAULT_ADMIN)] });
  }, [company]);

  const getStatesByCountry = (selectedCountry: string) => {
    const countryData = countriesMap.find((country) => country.country === selectedCountry);
    return countryData ? countryData.states : [];
  };

  const onFieldChangeCallback = (changedValue: Record<string, any>) => {
    setNewProject({ ...newProject, ...changedValue } as IProject);
  };

  const onNewProjectSaveCallback = () => {
    dispatch(boSaveNewProjectAsync(newProject)).then(() => {
      setIsShown(false);
    });
  };

  const billingPeriodValues: Array<IBhToggleButtonBarItem> = [
    {
      value: ProjectInvoiceType.MONTH,
      text: "Monthly (100€)"
    },
    {
      value: ProjectInvoiceType.YEAR,
      text: "Annual (1000€)"
    },
    {
      value: ProjectInvoiceType.FREE,
      text: "Free (0€)"
    }
  ];

  const invoiceTypeValues: Array<IBhToggleButtonBarItem> = [
    {
      value: false,
      text: "E-mail"
    },
    {
      value: true,
      text: "E-invoice"
    }
  ];

  const isNewProjectValid = () => {
    const { name, country, relatedUsers } = newProject;
    const { customerCountry, customerRecipient, customerRecipientEmails, customerRegCode, customerCivilian, type } = newProject.billing;
    if (!customerCivilian) {
      return name && country && relatedUsers.length > 0 && customerCountry && customerRecipient && customerRecipientEmails && customerRegCode && type;
    } else {
      return name && country && relatedUsers.length > 0 && customerCountry && customerRecipient && customerRecipientEmails && type;
    }
  };

  return (
    <BhModal
      size="2xl"
      setIsShown={setIsShown}
      header={<h2>New project</h2>}
      footer={<BhModalFooter onCancel={() => setIsShown(false)} cancelButtonText="Cancel" onConfirm={onNewProjectSaveCallback} confirmButtonText="Save" confirmDisabled={!isNewProjectValid()} />}
    >
      <div className="h-full overflow-auto">
        <div className="flex flex-col gap-2 py-4 px-8">
          <div>
            <BhInputLabel>Company</BhInputLabel>
            <p className="l-h-20px">{company.name}</p>
          </div>
          <BhInputStackedLabel initialValue={newProject.name} property="name" label="Project name" onChangeCallback={onFieldChangeCallback} required={true} />
          <BhComboboxStackedLabel property="country" values={countries} label="Country" initialValue={newProject.country} onSelect={onFieldChangeCallback} required={true} />
          <BhComboboxStackedLabel
            property="state"
            values={getStatesByCountry(newProject.country)}
            label="State"
            labelHelper="(optional)"
            initialValue={newProject?.state}
            onSelect={onFieldChangeCallback}
          />
          <BOCompanyNewProjectAdministrators newProjectForm={newProject} setNewProjectForm={setNewProject} companyId={company.id} />
          <h3>Subscription information</h3>
          <BhInputStackedLabel
            initialValue={newProject.billing?.customerRecipientEmails}
            property="customerRecipientEmails"
            label="Billing e-mail"
            required={true}
            onChangeCallback={(e: any) => onFieldChangeCallback({ ...newProject, billing: { ...newProject.billing, ...e } })}
          />
          <div className="-ml-5">
            <BhCheckboxWithText
              text="Personal invoicing"
              isChecked={newProject.billing?.customerCivilian}
              property="customerCivilian"
              onChange={(e: any) => onFieldChangeCallback({ ...newProject, billing: { ...newProject.billing, ...e } })}
            />
          </div>
          {!newProject.billing?.customerCivilian && (
            <>
              <BhInputStackedLabel
                initialValue={newProject.billing?.customerRegCode}
                property="customerRegCode"
                label="Reg code"
                required={true}
                onChangeCallback={(e: any) => onFieldChangeCallback({ ...newProject, billing: { ...newProject.billing, ...e } })}
              />
              <BhInputStackedLabel
                initialValue={newProject.billing?.customerVatNumber}
                property="customerVatNumber"
                label="VAT"
                labelHelper="(optional)"
                onChangeCallback={(e: any) => onFieldChangeCallback({ ...newProject, billing: { ...newProject.billing, ...e } })}
              />
            </>
          )}
          <BhInputStackedLabel
            initialValue={newProject.billing?.customerRecipient}
            property="customerRecipient"
            label="Recipient"
            required={true}
            onChangeCallback={(e: any) => onFieldChangeCallback({ ...newProject, billing: { ...newProject.billing, ...e } })}
          />
          <BhInputStackedLabel
            initialValue={newProject.billing?.customerAddress}
            property="customerAddress"
            label="Legal address"
            labelHelper="(optional)"
            onChangeCallback={(e: any) => onFieldChangeCallback({ ...newProject, billing: { ...newProject.billing, ...e } })}
          />
          <BhComboboxStackedLabel
            initialValue={newProject.billing?.customerCountry}
            property="customerCountry"
            values={countries}
            label="Country"
            required={true}
            onSelect={(e: any) => onFieldChangeCallback({ ...newProject, billing: { ...newProject.billing, ...e } })}
          />
          <BhInputStackedLabel
            initialValue={newProject.billing?.customerAdditionalInfo}
            property="customerAdditionalInfo"
            label="Additional info"
            labelHelper="(optional)"
            onChangeCallback={(e: any) => onFieldChangeCallback({ ...newProject, billing: { ...newProject.billing, ...e } })}
          />
          <BhToggleButtonBar
            items={billingPeriodValues}
            property="type"
            selected={newProject.billing?.type}
            label="Subscription model"
            onClickAction={(e: any) => onFieldChangeCallback({ ...newProject, billing: { ...newProject.billing, ...e } })}
          />
          <BhToggleButtonBar
            items={invoiceTypeValues}
            property="einvoice"
            selected={newProject.billing?.einvoice}
            label="Invoice type"
            onClickAction={(e: any) => onFieldChangeCallback({ ...newProject, billing: { ...newProject.billing, ...e } })}
          />
        </div>
      </div>
    </BhModal>
  );
};

export default BOCompanyNewProjectModal;
