import React, { FC, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectShareboxById } from "@/app/store/shareboxesSlice";
import { EntityId } from "@reduxjs/toolkit";
import { IFileEntity } from "@/model/files/IFileEntity";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faListTree } from "@fortawesome/pro-regular-svg-icons";
import { resetLightboxState } from "@/app/store/lightboxSlice";
import { useTranslation } from "react-i18next";
import BhToggleIconBar from "@components/toggle/BhToggleIconBar";
import { faList } from "@fortawesome/pro-regular-svg-icons/faList";
import { IToggleBarIconValue } from "@components/toggle/IToggleBarIconValue";
import { ShareboxDisplayOption } from "@/model/shareboxes/ISharebox";
import { CSVLink } from "react-csv";
import { naturalSortByField, naturalSortFilesByField } from "@/utilities/sortUtilities";
import { formatDate } from "@/utilities/dateUtility";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { fetchDownloadUrl } from "@/api/contactAPI";
import { imitatePdfFromFormDownload } from "@/utilities/downloadUtilities";
import moment from "moment";
import ShareboxFilesContainerListView from "@/views/home/project/detail/shareboxes/detail/shareboxFilesView/ShareboxFilesContainerListView";
import BhLargeText from "@components/text/BhLargeText";
import ShareboxFilesContainerDirectoryView from "@/views/home/project/detail/shareboxes/detail/shareboxFilesView/ShareboxFilesContainerDirectoryView";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";

interface Props {
  shareboxId: EntityId;
}

const ShareboxFilesContainer: FC<Props> = ({ shareboxId }) => {
  const { t } = useTranslation();
  const csvLinkRef = useRef(null);
  const [csvExportData, setCsvExportData] = useState<Array<any>>([]);
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const sharebox = useAppSelector((state) => selectShareboxById(state, shareboxId));
  const [shareboxDisplayOption, setShareboxDisplayOption] = useLocalStorage<ShareboxDisplayOption>("shareboxDisplayOption", ShareboxDisplayOption.FILE_LIST);

  useEffect(() => {
    return () => {
      dispatch(resetLightboxState());
    };
  }, []);

  useEffect(() => {
    if (csvExportData && csvExportData.length > 0) {
      // @ts-ignore
      csvLinkRef?.current?.link.click();
      setCsvExportData([] as Array<any>);
    }
  }, [csvExportData]);

  const showFilesAsFoldersOrListToggleButtonValues: Array<IToggleBarIconValue> = [
    { textValue: ShareboxDisplayOption.FILE_LIST, icon: faList, tooltipText: t("SHAREBOX.AS_LIST") },
    { textValue: ShareboxDisplayOption.DIRECTORY_TREE, icon: faListTree, tooltipText: t("SHAREBOX.AS_TREE") }
  ];

  if (!sharebox) {
    return null;
  }

  const exportShareboxFilesCSV = () => {
    const sortedFiles = [...sharebox.fileList].sort((a, b) => naturalSortFilesByField(a, b, "filePath", false));
    const csvData = sortedFiles.map((file) => {
      return { name: file.name, version: file.revision, createdBy: file.createdByFullName, created: formatDate(file.created), path: file.filePath.substring(0, file.filePath.lastIndexOf("/")) };
    });
    setCsvExportData(csvData);
  };

  const exportShareboxFilesPDF = () => {
    const sortedFiles = [...sharebox.fileList].sort((a, b) => naturalSortFilesByField(a, b, "name", false));
    let filesByPath = [] as Array<{ path: string; files: Array<IFileEntity> }>;
    sortedFiles.forEach((file) => {
      const filePath = file.filePath.substring(0, file.filePath.lastIndexOf("/"));
      if (!filesByPath.some((fb) => fb.path === filePath)) {
        filesByPath.push({ path: filePath, files: [] as Array<IFileEntity> });
      }
      filesByPath.forEach((fb) => {
        if (fb.path === filePath) {
          fb.files.push(file);
        }
      });
    });

    const sortedFilesByPath = filesByPath.sort((a, b) => naturalSortByField(a, b, "path", false));

    var thead = {
      name: t("GLOBAL.ITEM_NAME"),
      version: t("GLOBAL.VERSION"),
      createdBy: t("GLOBAL.UPLOADER"),
      created: t("GLOBAL.UPLOAD_DATE"),
      title: t("SHAREBOX.LIST.SHAREBOX")
    };

    const pdfData = {
      data: {
        title: t("SHAREBOX.FILE_LIST_PDF_EXPORT") + " " + sharebox.name,
        thead: thead,
        printed: moment(),
        shareBoxName: sharebox.name,
        files: sortedFilesByPath
      },
      formType: "FILE_LIST_PDF"
    };

    fetchDownloadUrl(projectId, pdfData).then((response) => imitatePdfFromFormDownload(response));
  };

  const noFilesAdded = !sharebox.fileIds || sharebox.fileIds.length === 0;

  return (
    <div className="bh-bg-smoke mx-auto mt-10 max-w-[1366px] rounded px-6 pt-2 pb-8">
      <div className="flex flex-row items-center justify-between py-4">
        <h3>{t("SHAREBOX.FILE_LIST")}</h3>
        <div className="flex h-6 flex-row items-center space-x-2">
          <span className="bh-text-deep-ocean-60 text-sm">{t("GLOBAL.EXPORT")}</span>
          <BhTextOnlyButton buttonProps={{ classes: "w-10", onClick: exportShareboxFilesCSV }}>{t("GLOBAL.CSV")}</BhTextOnlyButton>
          <CSVLink data={csvExportData} ref={csvLinkRef} filename={t("SHAREBOX.FILE_LIST_PDF_EXPORT") as string} separator={";"} className={"hidden"} />
          <BhTextOnlyButton buttonProps={{ classes: "w-10", onClick: exportShareboxFilesPDF }}>{t("GLOBAL.PDF")}</BhTextOnlyButton>
          <BhToggleIconBar
            values={showFilesAsFoldersOrListToggleButtonValues}
            onClickAction={(changedValue: any) => {
              setShareboxDisplayOption(changedValue.displayPreference.textValue);
            }}
            selected={shareboxDisplayOption}
            property="displayPreference"
          />
        </div>
      </div>
      {noFilesAdded && <BhLargeText classes="text-center pt-10 items-center">{t("SHAREBOX.NOFILES.HEADER") as string}</BhLargeText>}
      {shareboxDisplayOption === ShareboxDisplayOption.FILE_LIST && <ShareboxFilesContainerListView shareboxId={shareboxId} shareboxUuid={sharebox.uuid} publicSharebox={false} />}
      {shareboxDisplayOption === ShareboxDisplayOption.DIRECTORY_TREE && <ShareboxFilesContainerDirectoryView shareboxId={shareboxId} shareboxUuid={sharebox.uuid} publicSharebox={false} />}
    </div>
  );
};

export default ShareboxFilesContainer;
