import React, { FC, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import FormBaseFields from "@components/form/base/body/FormBaseFields";
import { classNames } from "@/utilities/jsUtilities";
import { IFormBaseVersionRowField } from "@/model/form/IFormBaseVersionRowField";
import { EntityId } from "@reduxjs/toolkit";
import { getDefaultFieldValues, IFormBaseVersionRow } from "@/model/form/IFormBaseVersionRow";
import { useTranslation } from "react-i18next";

interface Props {
  formBaseVersionId: EntityId;
  saveNewRowCallback: Function;
  previousRow?: IFormBaseVersionRow;
  dropdownOpen?: boolean;
}

const FormBaseFieldsAddButton: FC<Props> = ({ formBaseVersionId, saveNewRowCallback, previousRow, dropdownOpen }) => {
  const { t } = useTranslation();
  const [fieldsVisible, setFieldsVisible] = useState(false);

  useEffect(() => {
    setFieldsVisible(Boolean(dropdownOpen));
  }, [dropdownOpen]);

  const saveNewRowWithField = (selectedField: any) => {
    setFieldsVisible(false);
    const newField = {
      formBaseVersionId: formBaseVersionId,
      fieldType: selectedField.type,
      orderNumber: 1,
      value: getDefaultFieldValues(selectedField.type, t) || {},
      newField: true
    } as IFormBaseVersionRowField;
    saveNewRowCallback([newField], previousRow);
  };

  const onOutsideClick = () => {
    setFieldsVisible(false);
  };

  return (
    <div className="relative mt-2 flex w-full flex-row justify-center">
      <div
        className={classNames(
          "bh-border-pigeon-40 bh-bg-smoke hover:bh-bg-mint hover:bh-border-dark-jungle bh-text-deep-ocean-80 hover:bh-text-dark-jungle flex h-6 w-full cursor-pointer flex-row justify-center rounded border border-dashed transition duration-200 ease-in-out",
          fieldsVisible && "opacity-0"
        )}
        onClick={() => setFieldsVisible(true)}
      >
        <div className="flex flex-col justify-center pr-8">
          <FontAwesomeIcon icon={faPlus} />
        </div>
      </div>
      {fieldsVisible && (
        <div className="bh-bg-white absolute z-20">
          <FormBaseFields onFieldSelectCallback={saveNewRowWithField} clickOutsideCallback={onOutsideClick} />
        </div>
      )}
    </div>
  );
};

export default FormBaseFieldsAddButton;
