import React, { ChangeEvent, FC, useId, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectAllFilesForCurrentDirectory, selectCurrentDirId } from "@/app/store/filesSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import RevisionModal from "@/views/home/project/detail/directory/directoryModals/revisionModal/RevisionModal";
import { IFileEntity, IFileMatch, IRevisionMatcherFileEntity } from "@/model/files/IFileEntity";
import { v4 as uuidv4 } from "uuid";
import { uploadRevisionFilesAsync } from "@/app/store/uploadSlice";
import { canOpenInXeokit } from "@/utilities/fileEntity/fileEntityUtilities";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { BauhubBannerType } from "@/model/IProject";

interface Props {
  fileInputRef: React.LegacyRef<HTMLInputElement>;
}

const BimDirectoryRevisionUpload: FC<Props> = ({ fileInputRef }) => {
  const id = useId();
  const currentDirId = useAppSelector(selectCurrentDirId);
  const fileIdsInCurrentDirectory = useAppSelector(selectAllFilesForCurrentDirectory);
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();
  const [showRevisionModal, setShowRevisionModal] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([] as Array<IRevisionMatcherFileEntity>);

  if (!currentDirId) return null;

  const onFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (fileIdsInCurrentDirectory && fileIdsInCurrentDirectory.length < 1) return;
    const files = event.target.files;
    if (!files) return;
    let filesToMatch = [] as Array<IRevisionMatcherFileEntity>;
    for (let i = 0; i < files.length; ++i) {
      const file = files[i];
      filesToMatch.push({ name: file.name, uuid: uuidv4(), revision: 1, fileToUpload: file } as IRevisionMatcherFileEntity);
    }

    const allFilesSupportedByXeokit = filesToMatch
      .map((file) => {
        return { name: file.name } as IFileEntity;
      })
      .every((f) => canOpenInXeokit(f));
    if (!allFilesSupportedByXeokit) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          translateCode: "BIM.FILES.NOT_SUPPORTED"
        })
      );
      return;
    }

    setFilesToUpload(filesToMatch);
    setShowRevisionModal(true);
    event.target.value = "";
  };

  const onSubmit = (fileMatches: Array<IFileMatch>, unusedFiles: Array<IRevisionMatcherFileEntity>) => {
    dispatch(uploadRevisionFilesAsync({ fileMatches, unusedFiles, projectId, currentDirId }));
    setShowRevisionModal(false);
  };

  return (
    <>
      <input type="file" id={id} ref={fileInputRef} style={{ display: "none" }} multiple={true} onChange={onFileSelect} />
      {showRevisionModal && <RevisionModal onClose={() => setShowRevisionModal(false)} onSubmit={onSubmit} files={filesToUpload} directoryId={currentDirId} />}
    </>
  );
};

export default BimDirectoryRevisionUpload;
