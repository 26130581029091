import React, { FC, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  fetchSearchForKeywordAsync,
  searchInputFocusSet,
  searchStateCleared,
  searchStringSet,
  selectFirstTabWithResults,
  selectIsSearchInputFocused,
  selectSearchString
} from "@/app/store/searchSlice";
import SearchWithDropdownPopover from "@/features/searchWithDropdown/SearchWithDropdownPopover";
import { useOuterClick } from "@/utilities/hooks/useOuterClick";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  projectId: EntityId;
}

const SearchWithDropdown: FC<Props> = ({ projectId }) => {
  const { t } = useTranslation();
  const firstTabWithResults = useAppSelector(selectFirstTabWithResults);
  const searchString = useAppSelector(selectSearchString);
  const isSearchInputFocused = useAppSelector(selectIsSearchInputFocused);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const outsideClickRef = useOuterClick(() => {
    if (isSearchInputFocused) dispatch(searchInputFocusSet(false));
    if (isOpen) setIsOpen(false);
  });

  useEffect(() => {
    if (isOpen) setIsOpen(false);
    if (isSearchInputFocused) dispatch(searchInputFocusSet(false));
  }, [window.location.href]);

  if (!projectId) return null;

  const handleSearch = (changedValue: { query: string }) => {
    const query = changedValue.query;
    dispatch(searchStringSet(query));
    if (query.length > 2) {
      dispatch(fetchSearchForKeywordAsync({ projectId, query })).then(() => {
        setIsOpen(true);
      });
    }
    if (query.length === 0) {
      dispatch(searchStateCleared());
      setIsOpen(false);
    }
  };

  const handleFocus = () => {
    dispatch(searchInputFocusSet(true));
    if (searchString && searchString.length > 0) {
      setIsOpen(true);
    }
  };

  const handleEnterPress = (value: { query: string }) => {
    if (!value) return;
    const { query } = value;
    if (firstTabWithResults) {
      setIsOpen(false);
      if (inputRef?.current) {
        inputRef.current.blur();
      }
      navigate(`/project/${projectId}/search/${firstTabWithResults}?query=${query}`);
    }
  };

  return (
    <div ref={outsideClickRef} className={classNames(isSearchInputFocused ? "w-full" : "w-80 lg:w-48 md:w-full", "relative flex sm:hidden")}>
      <BhSearchInputWBG
        initialValue={searchString}
        placeholder={`${t("SEARCH.INPUT_PLACEHOLDER")}`}
        property="query"
        onChangeCallback={handleSearch}
        onBlurAction={handleSearch}
        onFocus={handleFocus}
        onEnterCallback={handleEnterPress}
        debounceLength={900}
        inputRef={inputRef}
      />
      {isOpen && <SearchWithDropdownPopover setIsOpen={setIsOpen} />}
    </div>
  );
};

export default SearchWithDropdown;
