import React, { FC } from "react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhBadge from "@components/badge/BhBadge";
import { useAppSelector } from "@/app/hooks";
import { selectSearchString, selectTaskIdsInSearch } from "@/app/store/searchSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useNavigate } from "react-router-dom";
import SearchResultsTaskContainer from "@/views/home/project/detail/search/taskResults/SearchResultsTaskContainer";
import { Trans } from "react-i18next";

interface Props {
  setIsOpen: Function;
}

const SearchDropdownTaskResults: FC<Props> = ({ setIsOpen }) => {
  const searchResultTaskIds = useAppSelector(selectTaskIdsInSearch);
  const projectId = useAppSelector(selectCurrentProjectId);
  const searchString = useAppSelector(selectSearchString);
  const navigate = useNavigate();

  if (!searchResultTaskIds) return null;

  const taskResultIdsInDropdown = searchResultTaskIds?.length > 4 ? searchResultTaskIds?.slice(0, 4) : searchResultTaskIds;
  const hasMoreThanFourResults = searchResultTaskIds?.length > 4;
  const remainingTaskIdsCount = searchResultTaskIds?.length - taskResultIdsInDropdown?.length;

  const goToAllTaskResults = () => {
    navigate(`/project/${projectId}/search/tasks?query=${searchString}`);
  };

  return (
    <div className="px-6">
      <h4 className="bh-text-deep-ocean mb-5">
        <Trans>SEARCH.TASKS</Trans>
      </h4>
      <div className="flex flex-col gap-1" onClick={() => setIsOpen(false)}>
        {taskResultIdsInDropdown.map((taskId) => {
          return <SearchResultsTaskContainer key={taskId} taskId={taskId} />;
        })}
      </div>
      {hasMoreThanFourResults && (
        <BhTextOnlyButton buttonProps={{ classes: "mt-3 w-max", onClick: goToAllTaskResults }}>
          <BhBadge classes="mr-1">+{remainingTaskIdsCount}</BhBadge>
          <Trans>DASHBOARD.INFO.SEE_ALL</Trans>
        </BhTextOnlyButton>
      )}
    </div>
  );
};

export default SearchDropdownTaskResults;
