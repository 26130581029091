import React, { FC } from "react";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhDatePicker from "@components/input/BhDatePicker";
import BhFilter from "@components/filters/BhFilter";
import BhFilterContainer from "@components/filters/BhFilterContainer";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boSelectFilter, boSetFilters } from "@/app/store/backofficeSlice";
import { IBoOrderTypeFilter, IBoPaidStatusFilter, IBoPlanFilter, IInvoicesFilter } from "@/model/backoffice/IBoFilter";

interface Props {
}

const BOInvoicesFilter: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector(boSelectFilter);

  const onNameFilterChange = (searchQuery: Record<string, string>) => {
    const changedObject = { ...filter, ...searchQuery };
    dispatch(boSetFilters(changedObject));
  };

  const onPlanFilterChange = (changedValue: IBoPlanFilter) => {
    const invoicesFilter: IInvoicesFilter = { ...filter.invoicesFilter };
    const updatedOrderTypeFilter = invoicesFilter.plans.map((plan) => plan.value === changedValue.value ? { ...plan, selected: !plan.selected } : plan);
    const newInvoiceFilter = { ...invoicesFilter, plans: updatedOrderTypeFilter };
    const newFilter = { ...filter, invoicesFilter: newInvoiceFilter };
    dispatch(boSetFilters(newFilter));
  };

  const isPlanSelected = (plan: IBoPlanFilter) => {
    return filter.invoicesFilter.plans.flatMap((p) => p.selected && p.value).includes(plan.value);
  };

  const onPlanFilterReset = () => {
    const newFilterPlans = filter.invoicesFilter.plans.map((plan) => ({ ...plan, selected: false }));
    const newFilter = { ...filter, invoicesFilter: { ...filter.invoicesFilter, plans: newFilterPlans } };
    dispatch(boSetFilters(newFilter));
  };

  const onOrderTypeFilterChange = (changedValue: IBoOrderTypeFilter) => {
    const invoicesFilter: IInvoicesFilter = { ...filter.invoicesFilter };
    const updatedOrderTypeFilter = invoicesFilter.orderTypes.map((type) => type.value === changedValue.value ? { ...type, selected: !type.selected } : type);
    const newInvoiceFilter = { ...invoicesFilter, orderTypes: updatedOrderTypeFilter };
    const newFilter = { ...filter, invoicesFilter: newInvoiceFilter };
    dispatch(boSetFilters(newFilter));
  };

  const isOrderTypeSelected = (orderType: IBoOrderTypeFilter) => {
    return filter.invoicesFilter.orderTypes.flatMap((t) => t.selected && t.value).includes(orderType.value);
  };

  const onOrderTypeFilterReset = () => {
    const newFilterOrderTypes = filter.invoicesFilter.orderTypes.map((type) => ({ ...type, selected: false }));
    const newFilter = { ...filter, invoicesFilter: { ...filter.invoicesFilter, orderTypes: newFilterOrderTypes } };
    dispatch(boSetFilters(newFilter));
  };

  const onStatusFilterChange = (changedValue: IBoPaidStatusFilter) => {
    const invoicesFilter: IInvoicesFilter = { ...filter.invoicesFilter };
    const updatedStatusFilter = invoicesFilter.status.map((status) => status.value === changedValue.value ? { ...status, selected: !status.selected } : status);
    const newInvoiceFilter = { ...invoicesFilter, status: updatedStatusFilter };
    const newFilter = { ...filter, invoicesFilter: newInvoiceFilter };
    dispatch(boSetFilters(newFilter));
  };

  const onStatusFilterReset = () => {
    const newFilterStatus = filter.invoicesFilter.status.map((status) => ({ ...status, selected: false }));
    const newFilter = { ...filter, invoicesFilter: { ...filter.invoicesFilter, status: newFilterStatus } };
    dispatch(boSetFilters(newFilter));
  };

  const isStatusFilterSelected = (status: IBoPaidStatusFilter) => {
    return filter?.invoicesFilter?.status?.flatMap((s) => (s.selected ? [s.value] : [])).includes(status.value);
  };

  const onSinceDateChange = (changedObj: Record<string, string>) => {
    const newFilter = { ...filter, invoicesFilter: { ...filter.invoicesFilter, date: { ...filter.invoicesFilter.date, since: changedObj.date } } };
    dispatch(boSetFilters(newFilter));
  };

  const onUntilDateChange = (changedObj: Record<string, string>) => {
    const newFilter = { ...filter, invoicesFilter: { ...filter.invoicesFilter, date: { ...filter.invoicesFilter.date, until: changedObj.date } } };
    dispatch(boSetFilters(newFilter));
  };

  const onSinceDateReset = () => {
    const newFilter = { ...filter, invoicesFilter: { ...filter.invoicesFilter, date: { ...filter.invoicesFilter.date, since: "" } } };
    dispatch(boSetFilters(newFilter));
  };
  const onUntilDateReset = () => {
    const newFilter = { ...filter, invoicesFilter: { ...filter.invoicesFilter, date: { ...filter.invoicesFilter.date, until: "" } } };
    dispatch(boSetFilters(newFilter));
  };

  return (
    <div>
      <BhFilterContainer>
        <div>
          <BhSearchInputWBG property="searchQuery" placeholder="Search invoice" inputClasses="w-52" onChangeCallback={onNameFilterChange} />
        </div>
        <BhDatePicker property={"date"} placeholder="Since" onChangeCallback={onSinceDateChange} onResetCallback={onSinceDateReset} returnISOString={true} />
        <BhDatePicker property={"date"} placeholder="Until" onChangeCallback={onUntilDateChange} onResetCallback={onUntilDateReset} returnISOString={true} />
        <BhFilter
          title={"Plan"}
          values={filter.invoicesFilter.plans}
          textProperty={"name"}
          onSelect={onPlanFilterChange}
          isSelected={isPlanSelected}
          onReset={onPlanFilterReset}
          selectedValues={filter.invoicesFilter.plans?.filter((plan) => plan.selected).length}
        />
        <BhFilter
          title={"Order type"}
          values={filter.invoicesFilter.orderTypes}
          textProperty={"name"}
          onSelect={onOrderTypeFilterChange}
          isSelected={isOrderTypeSelected}
          onReset={onOrderTypeFilterReset}
          selectedValues={filter.invoicesFilter.orderTypes?.filter((type) => type.selected).length}

        />
        <BhFilter
          title={"Status"}
          values={filter.invoicesFilter.status}
          textProperty={"name"}
          onSelect={onStatusFilterChange}
          onReset={onStatusFilterReset}
          isSelected={isStatusFilterSelected}
          selectedValues={filter.invoicesFilter.status?.filter((status) => status.selected).length}
        />
      </BhFilterContainer>
    </div>
  );
};

export default BOInvoicesFilter;