import React, { FC, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { Trans, useTranslation } from "react-i18next";
import { fetchSimpleFormsInSameDir } from "@/api/form/formAPI";
import { ISimpleForm } from "@/model/IForm";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { useAppDispatch } from "@/app/hooks";
import { formAndFileNameUpdated, prefillFormFromPreviousFormAsync } from "@/app/store/form/formSlice";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";

interface Props {
  formId?: EntityId;
  dirId?: EntityId;
  disabled?: boolean;
}

const FormContainerNewFillFormFromPrevious: FC<Props> = ({ formId, dirId, disabled }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [previousForms, setPreviousForms] = useState<Array<ISimpleForm>>();
  const [formToUseForPrefill, setFormToUseForPrefill] = useState({} as ISimpleForm);

  const fetchPreviousForms = () => {
    if (dirId && formId) {
      fetchSimpleFormsInSameDir(dirId, formId).then((result) => {
        setPreviousForms(result);
      });
    }
  };

  const selectPreviousForm = (selectedForm: ISimpleForm) => {
    setFormToUseForPrefill(selectedForm);
    showPrefillConfirmationModal();
  };

  const sortedPreviousForms = previousForms?.sort((a, b) => (new Date(a.created) < new Date(b.created) ? 1 : -1));

  const [showPrefillConfirmationModal, hidePrefillConfirmationModal] = useModal(
    () => (
      <BhDeleteConfirmationModal
        setIsOpen={hidePrefillConfirmationModal}
        header={<h2>{t("FORMBUILDER.FORM.PREFILL_MODAL.HEADER")}</h2>}
        body={<div>{t("FORMBUILDER.FORM.PREFILL_MODAL.BODY")}</div>}
        confirmationButtonText={t("FORMBUILDER.FORM.PREFILL_MODAL.REPLACE")}
        handleDelete={() => {
          if (formId) {
            dispatch(prefillFormFromPreviousFormAsync({ formId: formId, selectedForm: formToUseForPrefill })).then((res) => {
              dispatch(formAndFileNameUpdated(res.payload.meta.formName));
            });
            hidePrefillConfirmationModal();
          }
        }}
      />
    ),
    [formToUseForPrefill]
  );

  return (
    <div>
      <BhDropdown
        button={
          <BhSecondaryButton buttonProps={{ classes: "my-0 !p-3 !min-w-max", disabled: disabled, onClick: fetchPreviousForms }}>
            <Trans>FORMBUILDER.FORM.HISTORY_SELECT.PLACEHOLDER</Trans>
          </BhSecondaryButton>
        }
        menu={<BhDropdownMenu values={sortedPreviousForms} textProperty="name" type={BhDropdownTypeEnum.STRING} onSelect={selectPreviousForm} widthClass={"w-fit whitespace-nowrap"} />}
        position={BhDropdownPositionEnum.BOTTOM_LEFT}
      />
    </div>
  );
};

export default FormContainerNewFillFormFromPrevious;
