import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhModal from "@components/modal/BhModal";
import { IContact } from "@/model/IContact";
import { deleteContactAsync, saveContactAsync, selectContactEditModalOpen, selectEditableContact, setContactEditModalOpen } from "@/app/store/project/projectContactsSlice";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import useHandleFormSubmit from "@/utilities/hooks/useHandleFormSubmit";
import BhPigeon20Separator from "@components/separator/BhPigeon20Separator";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { useModal } from "react-modal-hook";
import { useTranslation } from "react-i18next";
import BhModalFooter from "@components/modal/BhModalFooter";
import { store } from "@/app/store";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { isValidEmail } from "@/utilities/jsUtilities";

const EditContactModal: FC = () => {
  const { t } = useTranslation();
  const contact = useAppSelector(selectEditableContact);
  const contactEditModalOpen = useAppSelector(selectContactEditModalOpen);
  const handleFormSubmit = useHandleFormSubmit<IContact>();
  const [notEnoughInformationToSubmit, setNotEnoughInformationToSubmit] = useState(false);
  const dispatch = useAppDispatch();

  const [showDeleteContactConfirmationModal, hideDeleteContactConfirmationModal] = useModal(
    () => (
      <BhDeleteConfirmationModal
        setIsOpen={() => {
          hideDeleteContactConfirmationModal();
        }}
        header={<h2>{t("MODAL.CONTACT.CONFIRMATION.DELETE.HEADER")}</h2>}
        body={<div>{t("MODAL.CONTACT.CONFIRMATION.DELETE")}</div>}
        confirmationButtonText={t("GLOBAL.DELETE")}
        handleDelete={() => {
          dispatch(deleteContactAsync(contact));
          hideDeleteContactConfirmationModal();
          dispatch(setContactEditModalOpen(false));
        }}
      />
    ),
    [contact]
  );

  // DO this for all modals
  if (!contactEditModalOpen) {
    return null;
  }

  const handleSubmit = (formValues: IContact) => {
    if (formValues.firstName === "" || formValues.lastName === "") {
      store.dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.WARNING,
          disappear: true,
          children: t("CONTACT.FILL_NAME")
        })
      );
      return;
    }

    const savableContactObject = { ...contact, ...formValues };

    if (!savableContactObject.phone && !savableContactObject.email) {
      setNotEnoughInformationToSubmit(true);
      return;
    }

    if (savableContactObject.phone && savableContactObject.phone.length > 0) {
      //numbri valideerimine?
    }

    if (savableContactObject.email && savableContactObject.email.length > 0 && !isValidEmail(savableContactObject.email)) {
      store.dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.WARNING,
          disappear: true,
          children: t("GLOBAL.EMAIL_INVALID")
        })
      );
      return;
    }

    if (!!savableContactObject) {
      dispatch(saveContactAsync(savableContactObject)).then(() => {
        dispatch(setContactEditModalOpen(false));
      });
    }
  };

  const isNew = contact && Object.keys(contact).length === 0;

  return (
    <BhModal
      isShown={contactEditModalOpen}
      setIsShown={(value) => dispatch(setContactEditModalOpen(value))}
      header={<h2>{t(isNew ? "CONTRACT.ADD_CONTACT" : "CONTACT.MODAL.EDIT")}</h2>}
      size="2xl"
      children={
        <div className="mb-14 mt-8 flex flex-col px-20">
          <form id="contactForm" className="flex flex-col gap-4" onSubmit={handleFormSubmit((contact) => handleSubmit(contact))}>
            <div className="flex flex-row">
              <div className="flex-1 pr-8">
                <BhInputStackedLabel initialValue={contact.firstName} property="firstName" label={`${t("CONTACT.FIRST_NAME")}`} />
              </div>
              <div className="flex-1">
                <BhInputStackedLabel initialValue={contact.lastName} property="lastName" label={`${t("CONTACT.LAST_NAME")}`} />
              </div>
            </div>
            <BhInputStackedLabel
              initialValue={contact.phone}
              property="phone"
              placeholder=" "
              label={`${t("CONTACT.PHONE")}`}
              validationHelper={`${t("CONTACT.MODAL.CONTACT_INPUT_HELPER")}`}
              validationHelperClasses={notEnoughInformationToSubmit ? "bh-text-error-red" : ""}
            />
            <BhInputStackedLabel
              initialValue={contact.email}
              property="email"
              placeholder=" "
              label={`${t("CONTACT.EMAIL")}`}
              validationHelper={`${t("CONTACT.MODAL.CONTACT_INPUT_HELPER")}`}
              validationHelperClasses={notEnoughInformationToSubmit ? "bh-text-error-red" : ""}
            />
            <BhInputStackedLabel initialValue={contact.position} property="position" placeholder=" " label={`${t("CONTACT.POSITION")}`} />
            <BhInputStackedLabel
              initialValue={contact.company}
              property="company"
              placeholder=" "
              label={`${t("CONTACTS.FILTER.COMPANY")}`}
              labelHelper={`${t("INPUT.LABEL_HELPER.OPTIONAL_FIELD")}`}
            />
          </form>
          {!isNew && (
            <div className="mt-4">
              <BhPigeon20Separator />
              <div className="mt-8">
                <BhTextOnlyButton
                  icon={faTrash}
                  buttonProps={{
                    onClick: () => {
                      showDeleteContactConfirmationModal();
                    }
                  }}
                >
                  {`${t("MODAL.CONTACT.CONFIRMATION.DELETE.HEADER")}`}
                </BhTextOnlyButton>
              </div>
            </div>
          )}
        </div>
      }
      footer={
        <BhModalFooter
          confirmButtonText={`${t(isNew ? "CONTRACT.ADD_CONTACT" : "MODAL.DEFAULT.CONFIRMATION.OK")}`}
          cancelButtonText={`${t("GLOBAL.CANCEL")}`}
          onCancel={() => dispatch(setContactEditModalOpen(false))}
          submitForm="contactForm"
        />
      }
    />
  );
};

export default EditContactModal;
