import React, { FC } from "react";
import BhFileCardSmall from "@components/cards/BhFileCardSmall";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { IFileEntity } from "@/model/files/IFileEntity";

interface Props {
  classes?: string;
  fromDirectory: IFileEntity;
  toDirectory: IFileEntity;
}

const ProjectLogFileMovedExtraContainer: FC<Props> = ({ classes, fromDirectory, toDirectory }) => {
  return (
    <div className={classes}>
      <div className="max-w-half">
        <BhFileCardSmall fileEntity={fromDirectory} />
      </div>
      <div className="self-center">
        <FontAwesomeIcon icon={faChevronRight} size={"xs"} className="mx-2" />
      </div>
      <div className="max-w-half">
        <BhFileCardSmall fileEntity={toDirectory} />
      </div>
    </div>
  );
};

export default ProjectLogFileMovedExtraContainer;
