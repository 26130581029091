import React, { FC, ReactNode } from "react";
import { classNames } from "@/utilities/jsUtilities";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";

interface Props {
  toggleObject: any;
  toggleProperty: string;
  onToggle: Function;
  label: ReactNode;
  classes?: string;
  disabled?: boolean;
}

const BhToggleRow: FC<Props> = ({ toggleObject, toggleProperty, onToggle, label, classes, disabled }) => {
  return (
    <div className={classNames("bh-bg-white flex flex-row items-center justify-between", classes)}>
      <div className="w-full">{label}</div>
      <div>
        <BhToggleSwitch value={toggleObject[toggleProperty]} onClickAction={onToggle} disabled={disabled} />
      </div>
    </div>
  );
};

export default BhToggleRow;
