import React, { FC, useState } from "react";
import BhInputSimple from "@components/input/BhInputSimple";
import { useTranslation } from "react-i18next";
import { faAngleDown, faPlus } from "@fortawesome/pro-solid-svg-icons";
import BhIconButton from "@components/buttons/BhIconButton";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons/faAngleRight";

interface Props {
  saveCallback: Function;
  prefills: Array<{ id: number; text: string }>;
  disabled?: boolean;
}

const ChecklistBaseRowPlusMinusField: FC<Props> = ({ saveCallback, prefills, disabled }) => {
  const { t } = useTranslation();
  const prefillsExist = prefills && prefills.length > 0;
  const sortedPrefills = prefillsExist ? prefills.slice().sort((a, b) => a.id - b.id) : [];
  const [prefillsVisible, setPrefillsVisible] = useState(prefillsExist);

  const addNewPrefill = () => {
    const newPrefillId = prefillsExist ? prefills.reduce((max, prefill) => (max.id > prefill.id ? max : prefill)).id + 1 : 1;
    const newPrefill = { text: "", id: newPrefillId };
    const prefillsToSave = { prefills: [...prefills, newPrefill] };
    setPrefillsVisible(true);
    saveCallback(prefillsToSave);
  };

  const savePrefill = (changedObject: any, id: number) => {
    const prefillsToSave = { prefills: prefills.map((prefill) => (prefill.id === id ? { ...prefill, ...changedObject } : prefill)) };
    saveCallback(prefillsToSave);
  };

  const removePrefill = (prefillToRemove: { id: number; text: string }) => {
    const prefillsToSave = { prefills: prefills.filter((prefill) => prefill.id !== prefillToRemove.id) };
    saveCallback(prefillsToSave);
  };

  return (
    <div>
      {prefillsExist && (
        <div className="pl-2">
          <div className="my-1 flex w-fit cursor-pointer flex-row items-center gap-x-2" onClick={() => setPrefillsVisible(!prefillsVisible)}>
            <div>
              <FontAwesomeIcon icon={prefillsVisible ? faAngleDown : faAngleRight} size={"lg"} />
            </div>
            <div className="text-14px bh-text-deep-ocean-80 font-bold leading-5">{t("CHECKLIST.ROW.PLUS_MINUS.PREFILLS")}</div>
          </div>
          {prefillsVisible && (
            <div className="pl-5">
              {sortedPrefills.map((prefill: { id: number; text: string }) => {
                return (
                  <div className="mt-2 flex flex-row items-center gap-x-2" key={prefill.id}>
                    <BhInputSimple
                      initialValue={prefill.text}
                      property={"text"}
                      onBlurAction={(value: any) => savePrefill(value, prefill.id)}
                      disabled={disabled}
                      placeholder={t("CHECKLIST.ROW.PLUS_MINUS.PREFILL.PLACEHOLDER") as string}
                    />
                    {!disabled && <BhIconButton icon={faTrash} buttonProps={{ onClick: () => removePrefill(prefill) }} />}
                  </div>
                );
              })}
              {!disabled && (
                <div className="mt-2">
                  <BhTextOnlyButton icon={faPlus} buttonProps={{ onClick: addNewPrefill }}>
                    {t("CHECKLIST.ROW.PLUS_MINUS.ADD_PREFILL")}
                  </BhTextOnlyButton>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {!prefillsExist && !disabled && (
        <BhTextOnlyButton icon={faPlus} buttonProps={{ onClick: addNewPrefill }}>
          {t("CHECKLIST.ROW.PLUS_MINUS.ADD_PREFILL")}
        </BhTextOnlyButton>
      )}
    </div>
  );
};

export default ChecklistBaseRowPlusMinusField;
