import React, { FC } from "react";
import FileNameLink from "@/features/fileContainer/FileNameLink";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import BhIconButton from "@components/buttons/BhIconButton";
import { IFileEntity } from "@/model/files/IFileEntity";
import { fetchUrlForFile } from "@/api/fileAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";

interface Props {
  fileEntity: IFileEntity;
}

const ConfirmationFileRevisionContainer: FC<Props> = ({ fileEntity }) => {
  return (
    <>
      <div className="bh-border-pigeon-40 bh-hover-container bh-bg-smoke-50 group ml-9 flex flex-col border-b">
        <div className="flex h-14 w-full flex-row items-center">
          <div className="flex flex-1 flex-row justify-between overflow-hidden pl-3 pr-5">
            <div className="flex w-full flex-row items-center space-x-2">
              <BhFileCardIcon fileEntity={fileEntity} fileCardSize={FileCardSize.LARGE} />
              <FileNameLink fileEntity={fileEntity} />
            </div>
          </div>
          <div className="text-12px relative flex w-12 flex-none items-center px-3">{fileEntity.revision}</div>
          <div className="w-66 flex-none px-3"></div>
          <div className="w-24 flex-none px-3 text-right"></div>
          <div className="w-20 flex-none">
            <div className="hidden flex-row group-hover:flex">
              <BhIconButton
                icon={faArrowDownToLine}
                buttonProps={{
                  onClick: () => {
                    fetchUrlForFile(fileEntity.id, false, true, fileEntity.uuid).then((presignedUrl) => {
                      imitateUrlDownload(presignedUrl.value);
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationFileRevisionContainer;
