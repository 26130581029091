import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import FileNameLink from "@/features/fileContainer/FileNameLink";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { IFileEntity } from "@/model/files/IFileEntity";
import { EntityId } from "@reduxjs/toolkit";
import { shareboxFolderIdsSelected } from "@/app/store/shareboxesSlice";
import { useAppDispatch } from "@/app/hooks";
import ShareboxFolderContainerDownloadButton from "@/views/home/project/detail/shareboxes/detail/ShareboxFolderContainerDownloadButton";

interface Props {
  fileEntity: IFileEntity;
  shareboxId: EntityId;
  publicSharebox: boolean;
  setCurrentDirectoryId: Function;
}

const ShareboxFolderContainerFC: FC<Props> = ({ fileEntity, shareboxId, publicSharebox, setCurrentDirectoryId }) => {
  const dispatch = useAppDispatch();

  if (!fileEntity) {
    return null;
  }

  const handleFileNameClick = () => {
    setCurrentDirectoryId && setCurrentDirectoryId(fileEntity.id);
  };

  const handleChange = (id: EntityId, e: { target: { checked: any } }) => {
    dispatch(shareboxFolderIdsSelected({ shareboxId: shareboxId, folderIds: [fileEntity.id], selected: e.target.checked }));
  };

  return (
    <div className={classNames(fileEntity.selected ? "bh-bg-mint" : "bh-bg-white hover:bh-bg-mint-30", "bh-border-pigeon-40 bh-hover-container group group block flex h-14 items-center border-b")}>
      {publicSharebox && (
        <>
          <div className={classNames(fileEntity.selected && "bh-bg-bauhub-green-120", "h-full w-0.5")} />
          <div className="flex w-8 flex-none items-center justify-center">
            <input
              className="hover:bh-border-deep-ocean bh-border-pigeon-60 h-5 w-5 rounded focus:ring-0 focus:ring-offset-0"
              type="checkbox"
              checked={fileEntity.selected || false}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => handleChange(fileEntity.id, e)}
            />
          </div>
        </>
      )}
      <div className="relative flex w-9 flex-none justify-center pl-3 text-center">
        <BhFileCardIcon fileEntity={fileEntity} fileCardSize={FileCardSize.LARGE} />
      </div>
      <div className="flex flex-grow flex-col overflow-hidden px-3">
        <div>
          <FileNameLink fileEntity={fileEntity} onFolderClickCallback={handleFileNameClick} />
        </div>
      </div>
      <div className="w-18 flex-none px-3 text-center"></div>
      <div className="w-36 flex-none px-3"></div>
      <div className="w-14 flex-none px-3 text-right">
        <ShareboxFolderContainerDownloadButton shareboxId={shareboxId} fileEntity={fileEntity} />
      </div>
    </div>
  );
};

const ShareboxFolderContainer = React.memo(ShareboxFolderContainerFC);
export default ShareboxFolderContainer;
