import React, { FC, useEffect } from "react";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  fetchTaskBoardWorkGroupsAsync,
  selectHasCurrentUserAccessToOpenedTaskBoard,
  selectIsAllTasksTaskBoardOpen,
  selectIsPersonalTaskBoardOpen,
  selectOpenedTaskBoardId,
  selectOpenedTaskBoardName,
  selectStatusToTaskBoardMap,
  setTaskBoardModalActiveTaskBoardId,
  toggleTaskBoardModalsOpen
} from "@/app/store/taskBoardsSlice";
import { fetchProjectWorkGroupsAsync } from "@/app/store/project/projectWorkGroupsSlice";
import { selectCurrentProjectId, toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { createNewTaskObject } from "@/model/taskBoard/ITask";
import { selectCurrentUser, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { selectDefaultProjectTaskCategory, setNewTask } from "@/app/store/tasksSlice";
import { useTranslation } from "react-i18next";
import TaskBoardItem from "@components/taskBoard/TaskBoardItem";
import TaskBoardItemGroup from "@components/taskBoard/TaskBoardItemGroup";
import BhDropdown from "@components/dropdown/BhDropdown";
import { TaskBoardStatus } from "@/model/taskBoard/ITaskBoard";
import { useNavigate } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

const TaskBoardHeaderRow: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const projectId = useAppSelector(selectCurrentProjectId);
  const openedTaskBoardName = useAppSelector(selectOpenedTaskBoardName);
  const taskBoardId = useAppSelector(selectOpenedTaskBoardId);
  const isPersonal = useAppSelector(selectIsPersonalTaskBoardOpen);
  const isAllTasks = useAppSelector(selectIsAllTasksTaskBoardOpen);
  const taskBoardName = isPersonal || isAllTasks ? t(openedTaskBoardName) : openedTaskBoardName;
  const currentUser = useAppSelector(selectCurrentUser);
  const defaultProjectTaskCategory = useAppSelector(selectDefaultProjectTaskCategory);
  const openedTaskBoardId = useAppSelector(selectOpenedTaskBoardId);
  const isPersonalTaskBoard = useAppSelector(selectIsPersonalTaskBoardOpen);
  const taskBoardStatusToIdMap = useAppSelector(selectStatusToTaskBoardMap);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const hasCurrentUserAccessToTaskBoard = useAppSelector(selectHasCurrentUserAccessToOpenedTaskBoard);

  useEffect(() => {
    if (!hasCurrentUserAccessToTaskBoard && !isProjectAdmin) {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/taskboard/list/personal`);
    }
  }, [hasCurrentUserAccessToTaskBoard]);

  const openTaskBoardSettingsModal = () => {
    if (taskBoardId) {
      dispatch(fetchTaskBoardWorkGroupsAsync(taskBoardId));
      dispatch(fetchProjectWorkGroupsAsync(projectId));
      dispatch(setTaskBoardModalActiveTaskBoardId(taskBoardId));
      dispatch(toggleTaskBoardModalsOpen({ modal: "taskBoardSettingsModal" }));
    }
  };

  const createNewTask = () => {
    if (!hasCurrentUserAccessToTaskBoard && !isProjectAdmin) {
      return;
    }
    const newTask = createNewTaskObject({ currentUser: currentUser, category: defaultProjectTaskCategory, taskBoardId: openedTaskBoardId, isPersonalTask: isPersonalTaskBoard });
    dispatch(setNewTask(newTask));
    dispatch(toggleProjectModalsOpen({ modal: "projectTaskModal" }));
  };

  const taskBoardStatusesSorted = [TaskBoardStatus.IN_PROGRESS, TaskBoardStatus.TODO, TaskBoardStatus.DONE];

  return (
    <div className="mt-8 flex flex-row justify-between">
      <h2 className="bh-text-deep-ocean m-0 mb-2 font-bold md:hidden">{taskBoardName}</h2>
      <div className="hidden md:flex">
        <BhDropdown
          button={<h2 className="bh-text-deep-ocean m-0 mb-2 cursor-pointer font-bold underline">{taskBoardName}</h2>}
          menu={
            <div className="bh-bg-white bh-shadow w-[300px] max-w-full rounded p-2">
              <section>
                <TaskBoardItem isAllTasks={true} />
                <TaskBoardItem isPersonal={true} />
                <div className="mt-6">
                  {taskBoardStatusToIdMap &&
                    Object.keys(taskBoardStatusToIdMap)
                      .sort((a: any, b: any) => {
                        return taskBoardStatusesSorted.indexOf(a) - taskBoardStatusesSorted.indexOf(b);
                      })
                      .map((status, index) => {
                        return <TaskBoardItemGroup status={status} taskBoardIds={taskBoardStatusToIdMap[status]} key={index} />;
                      })}
                </div>
              </section>
            </div>
          }
        />
      </div>
      <div>
        {!isPersonal && !isAllTasks && isProjectAdmin && <BhSecondaryButton buttonProps={{ classes: "px-4", onClick: () => openTaskBoardSettingsModal() }}>{t("GLOBAL.SETTINGS")}</BhSecondaryButton>}
        {!isAllTasks && (isProjectAdmin || hasCurrentUserAccessToTaskBoard) && (
          <BhPrimaryButton icon={faPlus} buttonProps={{ onClick: createNewTask }}>
            {t("TASK.NEW_TASK")}
          </BhPrimaryButton>
        )}
      </div>
    </div>
  );
};

export default TaskBoardHeaderRow;
