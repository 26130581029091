import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { EntityId } from "@reduxjs/toolkit";
import { IFileConfirmation } from "@/model/confirmations/IFileConfirmation";
import { IFileConfirmationV2DecisionDTO } from "@/model/confirmations/IFileConfirmationDecisionV2";
import { IFileConfirmationFile } from "@/model/confirmations/IFileConfirmationFile";
import { IFileConfirmationComment } from "@/model/confirmations/IFileConfirmationComment";
import { IFileConfirmationResponseDTO } from "@/model/confirmations/IFileConfirmationResponseDTO";

export function fetchFileConfirmation(fileConfirmationId: EntityId): Promise<IFileConfirmation> {
  return bauhubGet("/v2/file/confirmation/" + fileConfirmationId);
}

export function fetchFileConfirmationWithFiles(fileConfirmationId: EntityId): Promise<IFileConfirmation> {
  return bauhubGet("/v2/project/confirmation/" + fileConfirmationId);
}

export function fetchAllUserConfirmationsForProject(projectId: EntityId): Promise<IFileConfirmationResponseDTO> {
  return bauhubGet("/v2/project/" + projectId + "/user/file/confirmations");
}

export function fetchAllProjectConfirmations(projectId: EntityId): Promise<IFileConfirmationResponseDTO> {
  return bauhubGet("/v2/project/" + projectId + "/file/confirmations");
}

export function saveNewFileConfirmation(fileConfirmation: IFileConfirmation): Promise<IFileConfirmation> {
  return bauhubPost("/v2/file/confirmation/new", fileConfirmation);
}

export function saveDecisions(decisions: Array<IFileConfirmationV2DecisionDTO>): Promise<Array<IFileConfirmationV2DecisionDTO>> {
  return bauhubPost("/v2/file/confirmation/decision/save/list", decisions);
}

export function deleteFileConfirmation(fileConfirmationId: EntityId): Promise<IFileConfirmation> {
  return bauhubPost("/v2/file/confirmation/" + fileConfirmationId + "/delete");
}

export function deleteFilesFromConfirmation(fileConfirmationId: EntityId, fileIdsToDelete: Array<EntityId>): Promise<{ value: Array<EntityId> }> {
  return bauhubPost("/v2/project/confirmation/" + fileConfirmationId + "/remove/files", { value: fileIdsToDelete });
}

export function resetFileInConfirmation(fileConfirmationId: EntityId, fileId: EntityId): Promise<IFileConfirmationFile> {
  return bauhubPost("/v2/file/confirmation/" + fileConfirmationId + "/file/" + fileId + "/reset");
}

export function replaceFileWithLatestVersion(fileConfirmationId: EntityId, fileId: EntityId): Promise<IFileConfirmationFile> {
  return bauhubPost("/v2/project/confirmation/" + fileConfirmationId + "/replace/file/" + fileId);
}

export function addFilesToConfirmation(fileConfirmationId: EntityId, fileIds: Array<EntityId>): Promise<Array<IFileConfirmationFile>> {
  return bauhubPost("/v2/project/confirmation/" + fileConfirmationId + "/add/files", { value: fileIds });
}

export function fetchFileConfirmationFileRevisions(fileConfirmationId: EntityId, fileId: EntityId): Promise<Array<IFileConfirmationFile>> {
  return bauhubGet("/v2/file/confirmation/" + fileConfirmationId + "/file/" + fileId + "/revisions");
}

export function fetchFileConfirmationFileComments(fileConfirmationId: EntityId, fileId: EntityId): Promise<Array<IFileConfirmationComment>> {
  return bauhubGet("/v2/file/confirmation/" + fileConfirmationId + "/file/" + fileId + "/comments");
}

export function saveFileConfirmationFileComment(fileConfirmationId: EntityId, fileId: EntityId, comment: IFileConfirmationComment): Promise<IFileConfirmationComment> {
  return bauhubPost("/file/confirmation/" + fileConfirmationId + "/file/" + fileId + "/comment", comment);
}

export function fetchUserPendingFileConfirmationsCount(projectId: EntityId): Promise<{ value: number }> {
  return bauhubGet("/v2/project/" + projectId + "/confirmation/pending/count");
}

export function fetchUrlForConfirmationPdf(confirmationId: EntityId): Promise<{ value: string }> {
  return bauhubGet("/v2/project/confirmation/" + confirmationId + "/pdf");
}

export function fetchConfirmationForFile(fileEntityId: EntityId): Promise<IFileConfirmation> {
  return bauhubGet("/v2/file/" + fileEntityId + "/confirmation");
}
