import React, { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import FileContainer from "@/features/fileContainer/FileContainer";
import FileContainerTableHeader from "@/views/home/project/detail/confirmations/confirmation/tableHeader/FileContainerTableHeader";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import {
  fileExtensionFilterClearedInSearch,
  fileExtensionToggledInSearch,
  fileTagFilterClearedInSearch,
  fileTagToggledInSearch,
  selectFilesFilterInSearch,
  selectFilteredAndSortedFileEntityIdsInSearch,
  selectUniqueFileTagsInSearch
} from "@/app/store/searchSlice";
import BhFilter from "@components/filters/BhFilter";
import { BhFilterTypeEnum } from "@components/filters/BhFilterTypeEnum";
import { IFileExtensionFilter } from "@/model/files/IFileExtensionFilter";
import { IFileEntityTag } from "@/model/files/IFileEntityTag";
import BhFilterContainer from "@components/filters/BhFilterContainer";
import { useTranslation } from "react-i18next";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

const SearchResultsFileTab: FC = () => {
  const { t } = useTranslation();
  const searchResultFileIds = useAppSelector(selectFilteredAndSortedFileEntityIdsInSearch);
  const filter = useAppSelector(selectFilesFilterInSearch);
  const uniqueTagNames = useAppSelector(selectUniqueFileTagsInSearch);
  const dispatch = useAppDispatch();

  const onExtensionFilterSelect = useCallback(
    (ext: IFileExtensionFilter) => {
      dispatch(fileExtensionToggledInSearch(ext));
    },
    [filter]
  );

  const onExtensionFilterReset = useCallback(() => {
    dispatch(fileExtensionFilterClearedInSearch());
  }, [filter]);

  const isFileExtensionSelected = useCallback(
    (ext: IFileExtensionFilter) => {
      return ext.selected;
    },
    [filter.extensions]
  );

  const onTagFilterSelect = useCallback(
    (tag: IFileEntityTag) => {
      dispatch(fileTagToggledInSearch(tag));
    },
    [filter.tags]
  );

  const isTagSelected = useCallback(
    (tag: IFileEntityTag) => {
      return filter.tags.some((t) => t.text === tag.text);
    },
    [filter.tags]
  );

  const onTagFilterReset = useCallback(() => {
    dispatch(fileTagFilterClearedInSearch());
  }, [filter]);

  if (!searchResultFileIds) return null;

  return (
    <>
      <BhScrollableBody>
        <DndProvider backend={HTML5Backend}>
          <div className="flex flex-col">
            <BhFilterContainer>
              <BhFilter
                values={filter.extensions}
                title={t("FILE_FILTER.TYPE")}
                filterType={BhFilterTypeEnum.STRING}
                textProperty="name"
                selectedValues={filter.extensions?.filter((v) => v.selected).length}
                onSelect={onExtensionFilterSelect}
                isSelected={isFileExtensionSelected}
                onReset={onExtensionFilterReset}
              />
              {uniqueTagNames.length > 0 && (
                <BhFilter
                  values={uniqueTagNames}
                  title={t("TASKBOARD.FILTER.TAG")}
                  filterType={BhFilterTypeEnum.STRING}
                  textProperty="text"
                  selectedValues={filter.tags.length}
                  onSelect={onTagFilterSelect}
                  isSelected={isTagSelected}
                  onReset={onTagFilterReset}
                />
              )}
            </BhFilterContainer>
            <FileContainerTableHeader privilegesForDirectory={{ isRead: true, isWrite: false, isAdmin: false }} isSearchView={true} />
            {searchResultFileIds?.map((fileId) => {
              return <FileContainer fileId={fileId} key={fileId} isSearchView={true} privilegesForDirectory={{ isRead: true, isAdmin: false, isWrite: false }} />;
            })}
          </div>
        </DndProvider>
      </BhScrollableBody>
    </>
  );
};

export default SearchResultsFileTab;
