import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";

const FormBaseTableFieldAddColumnButton = () => {
  return (
    <div className="bh-border-pigeon-40 bh-bg-smoke hover:bh-bg-mint hover:bh-border-dark-jungle bh-text-deep-ocean-80 hover:bh-text-dark-jungle flex h-full w-6 cursor-pointer flex-row justify-center rounded border border-dashed transition duration-200 ease-in-out">
      <div className="flex flex-col justify-center">
        <FontAwesomeIcon icon={faPlus} />
      </div>
    </div>
  );
};
export default FormBaseTableFieldAddColumnButton;
