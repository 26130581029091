import React, { FC } from "react";
import FormWeatherFieldRow from "@components/form/fields/weatherField/FormWeatherFieldRow";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons/faCirclePlus";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import FormWeatherFieldHeader from "@components/form/fields/weatherField/FormWeatherFieldHeader";
import { classNames } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUserLanguage } from "@/app/store/userSlice";
import { languageMap } from "@/views/home/profile/ProfileTabs/ProfileUserInfo";

interface Props {
  rows: any;
  field: any;
  nestedDataSaveCallback?: Function;
  addCallback?: Function;
  disabled?: boolean;
  nestedDataRemoveCallback?: Function;
}

const FormWeatherFieldTable: FC<Props> = ({ field, nestedDataSaveCallback, addCallback, rows, disabled, nestedDataRemoveCallback }) => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguage);
  const langSuffix = languageMap[currentUserLanguage].weatherSuffix ? "?lang=" + languageMap[currentUserLanguage].weatherSuffix : "";

  const weatherLink = "http://www.ilmateenistus.ee/ilm/ilmavaatlused/vaatlusandmed/tunniandmed/" + langSuffix;

  const handleClick = () => {
    window.open(weatherLink, "_blank");
  };

  const addRow = () => {
    const newRow = field.value.columns.reduce((acc: any, item: any) => {
      acc[item.property] = item.type === "TEXT" ? "" : false;
      return acc;
    }, {});

    addCallback && addCallback(newRow, field.property);
  };

  const removeRow = (rowId: string) => {
    nestedDataRemoveCallback && nestedDataRemoveCallback({ _id: rowId }, field.property);
  };

  return (
    <div className="flex w-full flex-col gap-2">
      {rows.map((row: any, rowIndex: number) => (
        <div className="flex flex-col gap-1" key={rowIndex}>
          {rowIndex === 0 && <FormWeatherFieldHeader columns={field.value.columns} removeEnabled={rows.length > 1} />}
          <FormWeatherFieldRow
            row={row}
            saveCallback={nestedDataSaveCallback}
            fieldProperty={field.property}
            disabled={disabled}
            removeRowCallback={rows.length > 1 ? removeRow : undefined}
            columns={field.value.columns}
          />
        </div>
      ))}
      <div className={classNames("flex flex-row", field.value.disableRowAdding ? "justify-end" : "justify-between")}>
        {!field.value.disableRowAdding && (
          <BhIconButton
            icon={faCirclePlus}
            buttonProps={{
              onClick: addRow,
              disabled: disabled
            }}
            sizeClasses="w-max"
          >
            {t("FORMBUILDER.FORM.BASE.FIELD.WEATHER.ADD_ROW")}
          </BhIconButton>
        )}
        <BhTextOnlyButton buttonProps={{ disabled: disabled, onClick: handleClick }}>{t("FORMBUILDER.FORM.BASE.FIELD.WEATHER.CHECK_WEATHER")}</BhTextOnlyButton>
      </div>
    </div>
  );
};

export default FormWeatherFieldTable;
