import React, { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { IPartyPrivilegeListItemDTO } from "@/model/party/IPartyPrivilegeListItemDTO";
import BhTableRow from "@components/table/BhTableRow";
import { saveWorkGroupUserAuthorityChangeAsync, setRemoveUserModalShown, userAuthChanged, workGroupUserInviteRemoved } from "@/app/store/project/projectWorkGroupsSlice";
import PartyUserListItemDropdown from "@/views/home/project/detail/users/parties/party/PartyUserListItemDropdown";
import { SubResource } from "@/model/IUserAuthority";
import PartyUserIconContainer from "@/views/home/project/detail/users/parties/party/PartyUserIconContainer";
import PartyUserListItemTaskBoardDropdown from "@/views/home/project/detail/users/parties/party/PartyUserListItemTaskBoardDropdown";
import BhIconButton from "@components/buttons/BhIconButton";
import { IWorkGroup } from "@/model/IWorkGroup";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import { useTranslation } from "react-i18next";
import { selectBimProjectModule } from "@/app/store/project/projectSlice";

interface Props {
  workGroup: IWorkGroup;
  listItem: IPartyPrivilegeListItemDTO;
  setOpenedUser: Function;
}

const PartyUserListItem: FC<Props> = ({ workGroup, listItem, setOpenedUser }) => {
  const { t } = useTranslation();
  const userDropdownValues = [{ label: t("GLOBAL.DELETE"), action: "DELETE" }];
  const dispatch = useAppDispatch();
  const isBimProject = useAppSelector(selectBimProjectModule);

  const clickUserDropdownValue = () => {
    setOpenedUser(listItem);
    dispatch(setRemoveUserModalShown(true));
  };

  const removeUserFromList = () => {
    dispatch(workGroupUserInviteRemoved(listItem));
  };

  const onUserPrivilegeChange = useCallback((authLevelDropdownValue: any, subResource: SubResource, fieldName: keyof IPartyPrivilegeListItemDTO) => {
    const dto = {
      userEntityId: listItem.userEntityId,
      inviteId: listItem.inviteId,
      workGroupId: workGroup.id,
      subResource: subResource,
      authLevel: authLevelDropdownValue.value
    };
    if (workGroup.id !== -1) {
      dispatch(saveWorkGroupUserAuthorityChangeAsync(dto));
    } else {
      dispatch(userAuthChanged(dto));
    }
  }, []);

  return (
    <BhTableRow key={listItem.uniqueId}>
      <td>
        <PartyUserIconContainer user={listItem} />
      </td>
      <td className="align-middle">
        <PartyUserListItemDropdown workGroup={workGroup} listItem={listItem} fieldName="files" subResource={SubResource.FILE} callback={onUserPrivilegeChange}></PartyUserListItemDropdown>
      </td>
      <td>
        <PartyUserListItemDropdown workGroup={workGroup} listItem={listItem} fieldName="documents" subResource={SubResource.DOCUMENT} callback={onUserPrivilegeChange}></PartyUserListItemDropdown>
      </td>
      {isBimProject && (
        <td>
          <PartyUserListItemDropdown workGroup={workGroup} listItem={listItem} fieldName="models" subResource={SubResource.MODEL} callback={onUserPrivilegeChange}></PartyUserListItemDropdown>
        </td>
      )}
      <td>
        <PartyUserListItemDropdown workGroup={workGroup} listItem={listItem} fieldName="acts" subResource={SubResource.ACT} callback={onUserPrivilegeChange}></PartyUserListItemDropdown>
      </td>
      <td>
        <PartyUserListItemDropdown workGroup={workGroup} listItem={listItem} fieldName="contracts" subResource={SubResource.CONTRACT} callback={onUserPrivilegeChange}></PartyUserListItemDropdown>
      </td>
      {workGroup.id !== -1 && (
        <td>
          <PartyUserListItemTaskBoardDropdown workGroup={workGroup} listItem={listItem} />
        </td>
      )}
      <td className="text-right">
        {workGroup.id !== -1 && <BhIconButton icon={faCircleMinus} buttonProps={{ onClick: clickUserDropdownValue }} />}
        {workGroup.id === -1 && <BhIconButton icon={faCircleMinus} buttonProps={{ onClick: removeUserFromList }} />}
      </td>
    </BhTableRow>
  );
};

export default PartyUserListItem;
