import React, { FC, useRef } from "react";
import BhSeparatorMarginless from "@components/separator/BhSeparatorMarginless";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { useTranslation } from "react-i18next";

interface Props {
  onNextButtonClick: Function;
  onPrevButtonClick: Function;
  onCancel: Function;
  selectedIndex: number;
  stepsLength: number;
  disabled?: boolean;
  onSubmit: Function;
  finalizeButtonTitle?: string;
}

const BhProgressFooter: FC<Props> = ({ onNextButtonClick, onPrevButtonClick, onCancel, selectedIndex, stepsLength, disabled, onSubmit, finalizeButtonTitle }) => {
  const { t } = useTranslation();
  const isFirstStep = selectedIndex === 0;
  const isLastStep = selectedIndex === stepsLength - 1;
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const handleSubmit = () => {
    return isLastStep ? onSubmit(submitButtonRef) : onNextButtonClick();
  };

  return (
    <div className="h-17 flex flex-col">
      <BhSeparatorMarginless />
      <div className="flex flex-1 items-center justify-between">
        <div className="ml-3">{!isFirstStep && <BhTextOnlyButton buttonProps={{ onClick: onPrevButtonClick }}>{t("GLOBAL.PREVIOUS")}</BhTextOnlyButton>}</div>
        <div className="mr-3 flex gap-2">
          <BhTextOnlyButton buttonProps={{ onClick: onCancel }}>{t("MODAL.DEFAULT.CONFIRMATION.CANCEL")}</BhTextOnlyButton>
          <BhPrimaryButton buttonProps={{ onClick: handleSubmit, disabled: disabled, buttonRef: submitButtonRef }}>
            {isLastStep ? (finalizeButtonTitle ? t(finalizeButtonTitle) : t("MODAL.DEFAULT.CONFIRMATION.OK")) : t("GLOBAL.NEXT")}
          </BhPrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default BhProgressFooter;
