import React, { FC } from "react";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import BhBreadcrumbs from "@components/breadcrumb/BhBreadcrumbs";
import BhTabs from "@components/tabs/BhTabs";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { useAppSelector } from "@/app/hooks";
import BOCompanyAndBillingInfoTab from "@/views/home/backoffice/views/company/BOCompanyAndBillingInfoTab";
import { boSelectCompany } from "@/app/store/backofficeSlice";
import BhBOCompanyPlanTag from "@components/tags/BhBOCompanyPlanTag";
import BhBOCompanyStatusTag from "@components/tags/BhBOCompanyStatusTag";
import BOCompanyModulesTemplatesTab from "@/views/home/backoffice/views/company/BOCompanyModulesTemplatesTab";
import BOCompanyProjectsTab from "@/views/home/backoffice/views/company/BOCompanyProjectsTab";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faGear } from "@fortawesome/pro-regular-svg-icons/faGear";
import { useNavigate } from "react-router-dom";

const BOCompanySettingsContainer: FC = () => {
  const company = useAppSelector(boSelectCompany);
  const navigate = useNavigate();

  if (!company) {
    return null;
  }

  const breadcrumbValues = [
    { text: "Admin", url: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/bo` },
    { text: "Companies", url: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/bo/companies` },
    { text: company?.name || "" }
  ];

  const tabs = [
    {
      id: 0,
      header: "Company & billing info",
      content: <BOCompanyAndBillingInfoTab company={company} />,
      href: "general"
    },
    {
      id: 1,
      header: "Projects",
      content: <BOCompanyProjectsTab />,
      href: "projects"
    },
    {
      id: 2,
      header: "Modules & Templates",
      content: <BOCompanyModulesTemplatesTab />,
      href: "modules-templates"
    }
  ];

  return (
    <BhScrollableBody>
      <div className="relative flex w-full flex-col">
        <div className="bh-bg-smoke w-full px-20 pt-2 pb-14 lg:px-24 md:px-8">
          <div className="relative mt-4">
            <BhBreadcrumbs values={breadcrumbValues} />
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1">
                <h1 className="mt-3">{company?.name}</h1>
                <BhBOCompanyPlanTag plan={company.plan} />
                <BhBOCompanyStatusTag status={company.status} />
              </div>
              <BhPrimaryButton buttonProps={{ onClick: () => navigate("/company/" + company.id + "/general") }} icon={faGear}>
                Company settings
              </BhPrimaryButton>
            </div>
          </div>
        </div>
        <div className="-mt-12 flex h-full w-full flex-1 overflow-hidden pt-0.5">
          <BhTabs tabs={tabs} router={true} tabsContainerClasses="lg:px-24 px-20 md:px-8" />
        </div>
      </div>
    </BhScrollableBody>
  );
};

export default BOCompanySettingsContainer;
