import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { openCommentsForFileConfirmationFileIdSet, selectFileConfirmationFileById, selectOpenCommentsForFileConfirmationFileId } from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import { FileConfirmationDecisionType, IFileConfirmationDecisionV2 } from "@/model/confirmations/IFileConfirmationDecisionV2";
import { classNames } from "@/utilities/jsUtilities";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { IFileEntity } from "@/model/files/IFileEntity";
import { useNavigate } from "react-router-dom";
import FileNameLink from "@/features/fileContainer/FileNameLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import BhElementWithBadge from "@components/badge/BhElementWithBadge";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import BhIconButton from "@components/buttons/BhIconButton";
import { faMessage } from "@fortawesome/pro-regular-svg-icons/faMessage";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  fileConfirmationFileId: EntityId;
  decision: IFileConfirmationDecisionV2 | undefined;
  selected?: boolean;
}

const ConfirmationFileContainerActionsPreviewFC: FC<Props> = ({ fileConfirmationFileId, decision, selected }) => {
  const openCommentFileEntityId = useAppSelector(selectOpenCommentsForFileConfirmationFileId);
  const fileConfirmationFile = useAppSelector((state) => selectFileConfirmationFileById(state, fileConfirmationFileId));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  if (!fileConfirmationFile || !decision) {
    return null;
  }

  const isConfirmed = decision.status === FileConfirmationDecisionType.CONFIRMED;
  const isDeclined = decision.status === FileConfirmationDecisionType.DECLINED;

  return (
    <div
      className={classNames(
        selected && "bh-bg-mint-110",
        isConfirmed && "bh-bg-success-green-10",
        isDeclined && "bh-bg-error-red-10",
        "bh-border-pigeon-40 bh-hover-container group flex h-14 items-center border-b"
      )}
    >
      <div className="flex flex-1 flex-row items-center space-x-2 overflow-hidden px-2">
        <BhFileCardIcon fileEntity={fileConfirmationFile as unknown as IFileEntity} fileCardSize={FileCardSize.LARGE} />
        <div className="flex-1 overflow-hidden">
          <FileNameLink
            fileEntity={fileConfirmationFile as unknown as IFileEntity}
            onFileNameClickCallback={() =>
              navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${fileConfirmationFile.projectId}/confirmation/${fileConfirmationFile.fileConfirmationId}/actions/${fileConfirmationFile.fileEntityId}`)
            }
          />
        </div>
      </div>
      <BhElementWithBadge badge={fileConfirmationFile.commentCount > 0 && <BhBadge type={BhBadgeType.DARK}>{fileConfirmationFile.commentCount}</BhBadge>}>
        <BhIconButton
          icon={faMessage}
          buttonProps={{
            onClick: () => {
              dispatch(openCommentsForFileConfirmationFileIdSet(fileConfirmationFile?.fileEntityId));
            }
          }}
          withBackground={openCommentFileEntityId === fileConfirmationFile.fileEntityId}
        />
      </BhElementWithBadge>
      <div className="w-10 flex-none text-center">
        {isConfirmed && <FontAwesomeIcon icon={faCheck} size={"lg"} className="bh-text-success-green-110" />}
        {isDeclined && <FontAwesomeIcon icon={faTimes} size={"lg"} className="bh-text-error-red" />}
      </div>
    </div>
  );
};

const ConfirmationFileContainerActionsPreview = React.memo(ConfirmationFileContainerActionsPreviewFC);
export default ConfirmationFileContainerActionsPreview;
