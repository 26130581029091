import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import wizardDir from "@img/baudrive-wizard-kaust.png";
import wizardEnDir from "@img/baudrive-wizard-kaust-en.png";

import { useAppSelector } from "@/app/hooks";
import { selectCurrentUser } from "@/app/store/userSlice";


const SyncStep2Container: FC = () => {
    const { t } = useTranslation();
    const currentUser = useAppSelector(selectCurrentUser);
    const img = currentUser?.language === "et_EE" ? wizardDir : wizardEnDir;

    useEffect(() => {
    }, []);

    return (
        <div>
            <div className="flex w-full flex-col items-center pt-5">
                <img src={img} className="m-2 w-full max-w-[639px]" alt="No matches" />
                <h2 className="bh-text-dark-jungle text-center">{t("SYNC.STEP_2_TITLE")}</h2>
                <p className="text-18px text-center">{t("SYNC.STEP_2_SUBTEXT")}</p>
            </div>
        </div>
    );
};

export default SyncStep2Container;
