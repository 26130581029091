import React, { FC, useEffect } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch } from "@/app/hooks";
import CompanyFormSettingsTablesContainer from "@components/form/settings/companySettings/CompanyFormSettingsTablesContainer";
import { fetchCompanyCustomFormBasesAsync, fetchFormBasesForCompanyAsync, fetchProjectFormBasesForCompanyAsync } from "@/app/store/form/formBasesSlice";

interface Props {
  companyId: EntityId;
}

const CompanySettingsFormBasesTab: FC<Props> = ({ companyId }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchFormBasesForCompanyAsync(companyId));
    dispatch(fetchProjectFormBasesForCompanyAsync(companyId));
    dispatch(fetchCompanyCustomFormBasesAsync({ companyId: companyId }));
  }, []);

  return <CompanyFormSettingsTablesContainer companyId={companyId} />;
};

export default CompanySettingsFormBasesTab;
