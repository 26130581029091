import React, { FC, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "@/utilities/jsUtilities";
import { faFolder } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { IFolderFileEntity } from "@/model/files/IFileEntity";
import { fetchChildrenDirectoriesForDirectory } from "@/api/fileAPI";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import { useAppSelector } from "@/app/hooks";
import { selectIsCurrentUserProjectAdmin, selectPrivilegesForDirUsingParentDirIds } from "@/app/store/userSlice";
import { EntityId } from "@reduxjs/toolkit";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons/faAngleRight";
import { naturalSortFilesByField } from "@/utilities/sortUtilities";

interface Props {
  directory: IFolderFileEntity;
  setDirectory: Function;
  allowOnlyParentsToBeSelected?: boolean;
  directoryParentIds?: Array<EntityId>;
  changeCallback?: Function;
  ignorePrivileges?: boolean;
  showSelectedDirectoriesCount?: boolean;
}

const DirectorySelectionFolderContainer: FC<Props> = ({
  directory,
  setDirectory,
  allowOnlyParentsToBeSelected,
  directoryParentIds,
  changeCallback,
  ignorePrivileges,
  showSelectedDirectoriesCount
}) => {
  const parentIdsWithCurrentDirectoryId = directoryParentIds ? [...directoryParentIds, directory.id] : [directory.id];
  const isCurrentUserProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, directory.projectId));
  const privilegesForDirectory = useAppSelector((state) => selectPrivilegesForDirUsingParentDirIds(state, directory.id, directory, isCurrentUserProjectAdmin, parentIdsWithCurrentDirectoryId));
  const isSelected = directory.selected;
  const isOpen = directory.open;
  const subDirs = directory.subDirs && [...directory.subDirs].sort((a, b) => naturalSortFilesByField(a, b, "name"));

  const disabled = ignorePrivileges ? false : !privilegesForDirectory.isRead;

  useEffect(() => {
    if (allowOnlyParentsToBeSelected && !directory.selected && parentIdsWithCurrentDirectoryId.length === 1) toggleDirectoryOpen();
  }, [directory.selected]);

  const selectFolder = (selectedObject: any) => {
    const selectedDirectory = { ...directory, ...selectedObject };
    setDirectory(selectedDirectory);
    if (changeCallback) {
      changeCallback(selectedDirectory);
    }
  };

  const toggleDirectoryOpen = () => {
    if (directory.selected && allowOnlyParentsToBeSelected) {
      return;
    }
    const toggledDirectory = { ...directory, open: !directory.open };
    if (toggledDirectory.subDirsFetched) {
      setDirectory(toggledDirectory);
    } else {
      fetchChildrenDirectoriesForDirectory(directory.id).then((children) => {
        const subDirsToAdd = subDirs
          ? [
              ...subDirs,
              ...children.filter((ch) => {
                return !subDirs.some((d) => d.id === ch.id);
              })
            ]
          : children;
        const toggledDirectoryWithSubDirs = { ...toggledDirectory, ...{ subDirs: subDirsToAdd, subDirsFetched: true, open: true } };
        setDirectory(toggledDirectoryWithSubDirs);
      });
    }
  };

  return (
    <>
      <div className={classNames(isSelected && "bh-bg-mint-110", "hover:bh-bg-mint-50 even:bh-bg-smoke mb-0.5 rounded-sm")}>
        <div className={classNames("flex h-7 flex-row items-center overflow-hidden py-2 pr-2")}>
          <div>
            <BhCheckbox isChecked={isSelected} onChange={selectFolder} large={true} property={"selected"} disabled={disabled} />
          </div>
          <div className={classNames("flex items-center overflow-hidden", !isSelected && "cursor-pointer")} onClick={toggleDirectoryOpen}>
            {showSelectedDirectoriesCount && isSelected ? (
              <div className="h-4 w-8" />
            ) : (
              <FontAwesomeIcon className={classNames("h-4 w-8")} icon={isOpen && directory.subDirsFetched ? faAngleDown : faAngleRight} size={"1x"} />
            )}
            <div className="pl-1 pr-1.5">
              <FontAwesomeIcon className={classNames(isSelected ? "bh-text-bauhub-green-120" : "bh-text-pigeon-60")} icon={faFolder} />
            </div>
            <div className="flex flex-1 truncate">
              <div className={classNames("bh-text-deep-ocean", isSelected && "font-bold")}>{directory.name}</div>
            </div>
          </div>
        </div>
      </div>
      {subDirs && isOpen && (
        <div className="ml-2">
          {subDirs.map((dir) => {
            return (
              <DirectorySelectionFolderContainer
                directory={dir}
                setDirectory={setDirectory}
                allowOnlyParentsToBeSelected={allowOnlyParentsToBeSelected}
                directoryParentIds={parentIdsWithCurrentDirectoryId}
                key={dir.id}
                changeCallback={changeCallback}
                ignorePrivileges={ignorePrivileges}
                showSelectedDirectoriesCount={showSelectedDirectoriesCount}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default DirectorySelectionFolderContainer;
