import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { deleteChecklistBaseVersionAsync, publishChecklistBaseVersionAsync, saveNewChecklistBaseVersionAsync, selectCompanyChecklistBaseById } from "@/app/store/checklist/checklistsSlice";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft";
import { useNavigate } from "react-router-dom";
import { EntityId } from "@reduxjs/toolkit";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import BhTag from "@components/tags/BhTag";
import { useTranslation } from "react-i18next";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { ChecklistBaseVersionStatus, IChecklistBaseVersion } from "@/model/checklist/IChecklistBaseVersion";
import BhLightSeparatorVertical from "@components/separator/BhLightSeparatorVertical";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import ChecklistBaseHeaderVersionDropdown from "@components/checklists/checklistBase/header/ChecklistBaseHeaderVersionDropdown";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { fullPageLoadingSet } from "@/app/store/globalSlice";
import { generatePdfForChecklistBaseVersion } from "@/api/checklist/checklistBaseAPI";

interface Props {
  companyId: EntityId;
  checklistBaseId: EntityId;
  checklistBaseVersionId: EntityId;
  projectId?: EntityId;
}

const ChecklistBaseHeader: FC<Props> = ({ companyId, checklistBaseId, checklistBaseVersionId, projectId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const checklistBase = useAppSelector((state) => selectCompanyChecklistBaseById(state, checklistBaseId));
  const checklistBaseVersion = checklistBase && checklistBase.checklistBaseVersions && checklistBase.checklistBaseVersions.find((cbv) => cbv.id === checklistBaseVersionId);
  const lastChecklistBaseVersion =
    checklistBase && checklistBase.checklistBaseVersions && checklistBase.checklistBaseVersions.reduce((max, round) => (max.versionNumber > round.versionNumber ? max : round));
  const isDraft = checklistBaseVersion && checklistBaseVersion.status === ChecklistBaseVersionStatus.DRAFT;
  const isFirstVersion = checklistBaseVersion && checklistBaseVersion.versionNumber === 1;
  const showPublishVersionButton = isDraft;
  const showNewVersionButton = !isDraft && lastChecklistBaseVersion && lastChecklistBaseVersion.status === ChecklistBaseVersionStatus.FINAL;
  const showDeleteVersionButton = isDraft && !isFirstVersion;
  const [isLoading, setIsLoading] = useState(false);

  useDocumentTitle(checklistBase?.type);

  const deleteChecklistBaseVersion = () => {
    const previousVersion =
      checklistBase && checklistBase.checklistBaseVersions && checklistBase.checklistBaseVersions.find((cbv) => checklistBaseVersion && cbv.versionNumber === checklistBaseVersion.versionNumber - 1);
    if (previousVersion) {
      dispatch(deleteChecklistBaseVersionAsync({ companyId: companyId, checklistBaseId: checklistBaseId, checklistBaseVersionId: checklistBaseVersionId }));
      if (projectId) {
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklist/base/${checklistBase.id}/version/${previousVersion.id}`);
      } else {
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${companyId}/checklist/base/${checklistBase.id}/version/${previousVersion.id}`);
      }
    }
  };

  const [showDeleteChecklistBaseVersionConfirmationModal, hideDeleteChecklistBaseVersionConfirmationModal] = useModal(
    () => (
      <BhDeleteConfirmationModal
        setIsOpen={hideDeleteChecklistBaseVersionConfirmationModal}
        header={<h2>{t("CHECKLIST.VERSION.DELETE.MODAL.HEADER")}</h2>}
        body={<div>{t("CHECKLIST.VERSION.DELETE.MODAL.BODY")}</div>}
        confirmationButtonText={t("GLOBAL.DELETE")}
        handleDelete={() => {
          deleteChecklistBaseVersion();
          hideDeleteChecklistBaseVersionConfirmationModal();
        }}
      />
    ),
    [checklistBaseVersionId]
  );

  if (!checklistBase || !checklistBase.checklistBaseVersions || !checklistBaseVersion) {
    return null;
  }

  const goBack = () => {
    if (projectId) {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklists/admin/settings`);
    } else {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${companyId}/checklists`);
    }
  };

  const publishChecklistBaseVersion = () => {
    dispatch(publishChecklistBaseVersionAsync({ companyId: companyId, checklistBaseId: checklistBaseId, checklistBaseVersionId: checklistBaseVersionId }));
  };

  const addNewVersion = () => {
    if (isLoading) return;
    setIsLoading(true);
    dispatch(saveNewChecklistBaseVersionAsync({ companyId: companyId, checklistBaseId: checklistBaseId }))
      .then((action) => {
        const newChecklistBaseVersion = action.payload as IChecklistBaseVersion;
        if (projectId) {
          navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklist/base/${checklistBase.id}/version/${newChecklistBaseVersion.id}`);
        } else {
          navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${companyId}/checklist/base/${checklistBase.id}/version/${newChecklistBaseVersion.id}`);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const openPreviewPdf = () => {
    dispatch(fullPageLoadingSet(true));
    generatePdfForChecklistBaseVersion(companyId, checklistBaseId, checklistBaseVersionId).then((response) => {
      const navigateOptions = { state: { fileEntity: response } };
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/view`, navigateOptions);
      dispatch(fullPageLoadingSet(false));
    });
  };

  return (
    <div className="flew-row bh-border-pigeon-20 flex w-full items-center justify-between border-b-8 p-4">
      <div className="flex flex-row items-center">
        <BhIconButton icon={faArrowLeft} buttonProps={{ classes: "mr-5", onClick: goBack }} />
        <ChecklistBaseHeaderVersionDropdown checklistBaseVersions={checklistBase.checklistBaseVersions} currentVersionId={checklistBaseVersion.id} companyId={companyId} projectId={projectId} />
        <div className="flex flex-row items-center">
          <div className="bh-text-deep-ocean text-18px mr-2 font-bold leading-6">{checklistBase.type}</div>
          <BhTag type={isDraft ? BhTagType.MUSTAND : BhTagType.TAG} children={t("CHECKLIST.STATUS." + checklistBaseVersion.status.toUpperCase())} />
        </div>
      </div>
      <div className="flex h-full flex-row items-center">
        {showDeleteVersionButton && (
          <div className="flex flex-row items-center">
            <BhIconButton icon={faTrash} buttonProps={{ onClick: showDeleteChecklistBaseVersionConfirmationModal }} />
            <div className="h-6 pr-4 pl-1">
              <BhLightSeparatorVertical />
            </div>
          </div>
        )}
        <BhSecondaryButton icon={faEye} buttonProps={{ onClick: openPreviewPdf }}>
          {t("CHECKLIST.TEMPLATE_PDF_PREVIEW")}
        </BhSecondaryButton>
        {showPublishVersionButton && <BhPrimaryButton buttonProps={{ onClick: publishChecklistBaseVersion }}>{t("CHECKLIST.PUBLISH")}</BhPrimaryButton>}
        {showNewVersionButton && (
          <BhPrimaryButton icon={faPlus} buttonProps={{ onClick: addNewVersion }}>
            {t("CHECKLIST.BASE.NEW_VERSION")}
          </BhPrimaryButton>
        )}
      </div>
    </div>
  );
};

export default ChecklistBaseHeader;
