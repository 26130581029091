import React, { FC } from "react";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import BhTextareaSimple from "@components/input/BhTextareaSimple";

interface Props {
  col: any;
  row: any;
  rowIndex: number;
  saveFieldCallback: Function;
  fieldValue: any;
  disabled?: boolean;
}

const FormBaseWeatherFieldRow: FC<Props> = ({ col, row, rowIndex, saveFieldCallback, fieldValue, disabled }) => {
  const handleInputChange = (newValue: any, property: string, rowIndex: number) => {
    const rows = [...fieldValue.rows];
    rows[rowIndex] = {
      ...rows[rowIndex],
      [property]: newValue
    };
    saveFieldCallback("rows", rows);
  };

  return (
    <div className="flex h-full w-full items-center">
      {col.type === "CHECKBOX" && (
        <div className="flex w-full items-center justify-center">
          <BhCheckbox isChecked={row[col.property]} onChange={() => {}} disabled={true} property="checked" large={true} />
        </div>
      )}
      {col.type === "TEXT" && (
        <div className="-mb-2 mt-1 w-full">
          <BhTextareaSimple
            initialValue={row[col.property]}
            property={"text"}
            onBlurCallback={(val: any) => handleInputChange(val.text, col.property, rowIndex)}
            disabled={disabled}
            inputClasses="w-full"
            maxHeightClass={"max-h-[36px]"}
          />
        </div>
      )}
    </div>
  );
};

export default FormBaseWeatherFieldRow;
