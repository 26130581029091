import React, { FC, useState } from "react";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhFixedDropdownParent from "@components/dropdown/BhFixedDropdownParent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";

interface Props {
  initialValue: string;
  property: string;
  leadingProperty?: string;
  values?: Array<any>;
  onSelect: Function;
  placeholder?: string;
  label?: string;
  fetchValues: Function;
}

const BhInputWithDropdownWithFetchValuesOnChange: FC<Props> = ({ initialValue, placeholder, values, onSelect, property, label, fetchValues, leadingProperty }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownValues, setDropdownValues] = useState(values || []);

  const filterValues = (filter: any) => {
    fetchValues(filter.text.toLowerCase()).then((results: Array<any>) => {
      if (results.length > 0) {
        setDropdownValues(results);
      }
      setIsDropdownOpen(results.length > 0);
    });
  };

  const onSelectAction = (selectedObject: any) => {
    setIsDropdownOpen(false);
    setDropdownValues([]);
    onSelect(selectedObject);
  };

  return (
    <div className="relative w-full">
      <div className="relative">
        <div className="w-full">
          <BhInputStackedLabel initialValue={initialValue} property={"text"} placeholder={placeholder} onChangeCallback={filterValues} label={label} onFocus={() => setIsDropdownOpen(true)} />
        </div>
        <div className="absolute bottom-2 right-3">
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
      </div>
      {isDropdownOpen && dropdownValues.length > 0 && (
        <div className="absolute z-50 min-w-full max-w-[500px]">
          <BhFixedDropdownParent>
            <BhDropdownMenu
              type={BhDropdownTypeEnum.STRING}
              textProperty={property}
              leadingProperty={leadingProperty}
              values={dropdownValues}
              onSelect={onSelectAction}
              customDropdown={true}
              widthClass="w-full"
            />
          </BhFixedDropdownParent>
        </div>
      )}
    </div>
  );
};
export default React.memo(BhInputWithDropdownWithFetchValuesOnChange);
