import React, { FC, useCallback } from "react";
import BhFilter from "@components/filters/BhFilter";
import { useTranslation } from "react-i18next";
import {
  clearShareboxReceiversFilter,
  receiverToggledInShareboxFilter,
  selectProjectShareboxesFilterReceiversValues,
  selectShareboxesReceiversFilter,
  selectUserShareboxesFilterReceiversValues,
  setShareboxReceiversFilter
} from "@/app/store/shareboxesSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { IShareboxReceiversFilter } from "@/model/shareboxes/IShareboxesFilter";

interface Props {
  userShareboxes?: boolean;
}
const ShareboxFilterByReceivers: FC<Props> = ({ userShareboxes }) => {
  const { t } = useTranslation();
  const receiversFilter = useAppSelector(selectShareboxesReceiversFilter);
  const projectId = useAppSelector(selectCurrentProjectId);
  const receivers = useAppSelector((state) => {
    if (userShareboxes) return selectUserShareboxesFilterReceiversValues(state, projectId);
    if (!userShareboxes) return selectProjectShareboxesFilterReceiversValues(state, projectId);
  });
  const dispatch = useAppDispatch();

  const { receiversSearchKey, receiversDropdown } = receiversFilter;

  const onShareboxReceiverSearchInputChange = useCallback(
    (changedValue: IShareboxReceiversFilter) => {
      dispatch(setShareboxReceiversFilter(changedValue.receiversSearchKey));
    },
    [receiversFilter]
  );

  const onShareboxReceiversFilterSelect = useCallback(
    (dropdownValue: string) => {
      dispatch(receiverToggledInShareboxFilter(dropdownValue));
    },
    [receiversFilter]
  );

  const onShareboxReceiversFilterReset = useCallback(() => {
    dispatch(clearShareboxReceiversFilter());
  }, [receiversFilter]);

  const isSelected = (value: string) => {
    return receiversDropdown.includes(value.toLowerCase());
  };

  const filteredReceivers = receivers?.filter((receiver) => receiver?.toLowerCase().includes(receiversSearchKey?.toLowerCase())) || [];

  return (
    <BhFilter
      title={t("SHAREBOX.FILTER.SHARED_WITH")}
      values={filteredReceivers}
      onSelect={onShareboxReceiversFilterSelect}
      search={true}
      searchInputSaveCallback={onShareboxReceiverSearchInputChange}
      selectedValues={receiversDropdown.length}
      searchInputProperty="receiversSearchKey"
      dropdownSearchInitialValue={receiversFilter.receiversSearchKey}
      isSelected={isSelected}
      onReset={onShareboxReceiversFilterReset}
      dropdownWidthClass="min-w-max"
      closeOnItemClick={false}
    />
  );
};

export default ShareboxFilterByReceivers;
