import React, { FC, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  selectSenderFilterDropdownValuesForAllProjectUnsignedInvites,
  selectSenderFilterDropdownValuesForUserDeclinedSignInvites,
  selectSenderFilterDropdownValuesForUserSignedSignInvites,
  selectSenderFilterDropdownValuesForUserUnsignedSignInvites,
  selectSignInvitesFilter,
  signInvitesSenderFilterCleared,
  signInvitesSenderFilterSet
} from "@/app/store/signInvitesSlice";
import { IUser } from "@/model/IUser";
import BhFilter from "@components/filters/BhFilter";
import { BhFilterTypeEnum } from "@components/filters/BhFilterTypeEnum";
import { useTranslation } from "react-i18next";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { intersection } from "@/utilities/jsUtilities";
import { fetchRelatedUsersInProjectWithSearch } from "@/api/projectAPI";
import { sortByFullNameAndEmail } from "@/utilities/sortUtilities";

interface Props {
  useUnsignedSignInvitesSelector?: boolean;
  useSignedSignInvitesSelector?: boolean;
  useDeclinedSignInvitesSelector?: boolean;
  useAllProjectUnsignedInvitesSelector?: boolean;
}

const SignInvitesSenderFilter: FC<Props> = ({ useSignedSignInvitesSelector, useUnsignedSignInvitesSelector, useAllProjectUnsignedInvitesSelector, useDeclinedSignInvitesSelector }) => {
  const { t } = useTranslation();
  const [senderUserEntities, setSenderUserEntities] = useState<Array<ISimpleValidUserAuthority>>([]);
  const [filteredProjectUsers, setFilteredProjectUsers] = useState<Array<ISimpleValidUserAuthority>>([]);
  const projectId = useAppSelector(selectCurrentProjectId);
  const senderFilterDropdownValues = useAppSelector((state) => {
    if (useUnsignedSignInvitesSelector) return selectSenderFilterDropdownValuesForUserUnsignedSignInvites(state, projectId);
    if (useSignedSignInvitesSelector) return selectSenderFilterDropdownValuesForUserSignedSignInvites(state, projectId);
    if (useDeclinedSignInvitesSelector) return selectSenderFilterDropdownValuesForUserDeclinedSignInvites(state, projectId);
    if (useAllProjectUnsignedInvitesSelector) return selectSenderFilterDropdownValuesForAllProjectUnsignedInvites(state);
  });
  const filter = useAppSelector(selectSignInvitesFilter);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [projectUsers, setProjectUsers] = useState<Array<ISimpleValidUserAuthority>>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSenderUserEntities(
      projectUsers.filter((projectUser) => {
        const allProjectUsernames = projectUsers.map((user) => user.username);
        return intersection(allProjectUsernames, senderFilterDropdownValues || []).some((username) => username === projectUser.username);
      })
    );
  }, [projectUsers]);

  useEffect(() => {
    setFilteredProjectUsers(senderUserEntities);
  }, [senderUserEntities]);

  const onSenderSearch = (query: { query: string }) => {
    const searchString = query.query.toLowerCase();
    setFilteredProjectUsers(
      senderUserEntities
        .filter((user) => {
          const firsNameFilter = user.firstName.toLowerCase().includes(searchString);
          const lastNameFilter = user.lastName.toLowerCase().includes(searchString);
          return firsNameFilter || lastNameFilter;
        })
        .sort((a, b) => sortByFullNameAndEmail(a, b))
    );
  };

  const searchUsers = () => {
    if (projectId) {
      setIsLoading(true);
      fetchRelatedUsersInProjectWithSearch(projectId)
        .then((projectUsers) => {
          setProjectUsers(projectUsers);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const isSelected = (selectedSender: IUser) => {
    return filter.initiator.some((initiator) => initiator?.toLowerCase() === selectedSender.username.toLowerCase());
  };

  const toggleSenderFilter = useCallback(
    (selectedValue: ISimpleValidUserAuthority) => {
      dispatch(signInvitesSenderFilterSet(selectedValue.username));
    },
    [filter]
  );

  return (
    <div className="inline-flex" onClick={searchUsers}>
      <BhFilter
        title={t("SIGN_INVITE.TABLE.ASSIGNEE")}
        dropdownWidthClass="min-w-max"
        values={filteredProjectUsers}
        filterType={BhFilterTypeEnum.USER}
        onSelect={toggleSenderFilter}
        onReset={() => dispatch(signInvitesSenderFilterCleared())}
        isSelected={isSelected}
        selectedValues={filter.initiator.length}
        search={true}
        searchInputProperty="query"
        searchInputSaveCallback={onSenderSearch}
        isLoading={isLoading}
      />
    </div>
  );
};

export default SignInvitesSenderFilter;
