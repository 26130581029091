import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyFormBaseById } from "@/app/store/form/formBasesSlice";
import { EntityId } from "@reduxjs/toolkit";
import { FormNameOption } from "@/model/form/IFormBase";
import { getCurrentDateString } from "@/utilities/dateUtility";
import FormHeaderRow from "@components/form/FormHeaderRow";
import FormBaseInfoFileNameRow from "@components/form/base/body/formBaseInfo/FormBaseInfoFileNameRow";
import BhPigeon20Separator from "@components/separator/BhPigeon20Separator";
import BhBanner from "@components/banners/BhBanner";
import { BauhubBannerType } from "@/model/IProject";
import { useTranslation } from "react-i18next";

interface Props {
  formBaseId: EntityId;
  companyId: EntityId;
}

const FormBaseInfoPreview: FC<Props> = ({ formBaseId, companyId }) => {
  const { t } = useTranslation();
  const formBase = useAppSelector((state) => selectCompanyFormBaseById(state, formBaseId));

  if (!formBase) return null;

  const formOptionExampleMap: Record<FormNameOption, string> = {
    DATE: getCurrentDateString(),
    NONE: "",
    ORDER_NUMBER: "1",
    ORDER_NUMBER_AND_DATE: "1_" + getCurrentDateString()
  };

  return (
    <div className="flex w-full flex-col">
      {formBase.bauhubFormBase && (
        <div className="flex w-full flex-col">
          <div className="my-4 w-full">
            <BhBanner type={BauhubBannerType.ANNOUNCEMENT_LIGHT} fullWidth={true}>
              {t("FORMBUILDER.FORM.BASE.BAUHUB_TEMPLATE_INFO")}
            </BhBanner>
          </div>
          <FormBaseInfoFileNameRow formBaseId={formBaseId} companyId={companyId} />
          <div className="my-8">
            <BhPigeon20Separator />
          </div>
        </div>
      )}
      <div className="mt-2 mb-4">
        {formBase.bauhubFormBase && <div className="text-16px bh-text-pigeon mb-4 font-bold">{t("FORMBUILDER.FORM.BASE.PREVIEW")}</div>}
        <FormHeaderRow formNameExample={formOptionExampleMap[formBase.formNamePrefix] + formBase.formName + formOptionExampleMap[formBase.formNameSuffix]} disabled={true} isPreview={true} />
      </div>
    </div>
  );
};

export default FormBaseInfoPreview;
