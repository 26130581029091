import React, { FC } from "react";
import BhCheckbox from "@components/checkboxes/BhCheckbox";

interface Props {
  text: string;
  isChecked: boolean;
  onChange?: Function;
  property: string;
  disabled?: boolean;
  isSmallCheckbox?: boolean;
}

const BhCheckboxWithText: FC<Props> = ({ text, isChecked, onChange, property, disabled, isSmallCheckbox }) => {
  const onToggle = (changedObject: any) => {
    onChange && onChange(changedObject);
  };

  return (
    <div className="ml-4 flex flex-row items-center">
      <BhCheckbox property={property} onChange={onToggle} isChecked={isChecked} large={isSmallCheckbox ? false : true} disabled={disabled} />
      <span className="ml-2">{text}</span>
    </div>
  );
};

export default BhCheckboxWithText;
