import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { ISignInvite } from "@/model/ISignInvite";
import { selectCountOfDeclinedSignInvitesForProject, selectDeclinedSignInvitesForProjectFilteredAndSorted, selectSignInvitesStatus } from "@/app/store/signInvitesSlice";
import SignInvitesListItem from "@/views/home/project/detail/signInvites/SignInvitesListItem";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import { useTranslation } from "react-i18next";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import BhLargeText from "@components/text/BhLargeText";
import SignInvitesListItemSkeleton from "@/views/home/project/detail/signInvites/SignInvitesListItemSkeleton";

interface Props {}

const SignInvitesDeclinedList: FC<Props> = () => {
  const { t } = useTranslation();
  const status = useAppSelector(selectSignInvitesStatus);
  const projectId = useAppSelector(selectCurrentProjectId);
  const filteredInvites = useAppSelector((state) => selectDeclinedSignInvitesForProjectFilteredAndSorted(state, projectId));
  const countOfAllInvites = useAppSelector((state) => selectCountOfDeclinedSignInvitesForProject(state, projectId));

  const noSearchResults = filteredInvites.length === 0 && countOfAllInvites > 0;

  return (
    <div className="mt-2">
      {status === BhStateStatusType.PENDING && filteredInvites.length === 0 && <SignInvitesListItemSkeleton />}
      {status === BhStateStatusType.SUCCESS && (
        <>
          {countOfAllInvites === 0 && <BhNoMatchesImage children={t("SIGN_INVITE.NO_DECLINED_INVITES")} />}
          {noSearchResults && <BhLargeText classes="text-center pt-10 items-center">{t("GLOBAL.NO_RESULTS")}</BhLargeText>}
          {filteredInvites.length > 0 &&
            filteredInvites.map((signInvite: ISignInvite) => {
              return <SignInvitesListItem key={signInvite.id} signInvite={signInvite} />;
            })}
        </>
      )}
    </div>
  );
};

export default SignInvitesDeclinedList;
