import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { ITaskBoard } from "@/model/taskBoard/ITaskBoard";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import {
  deleteTaskBoard,
  fetchProjectTaskBoards,
  fetchTaskBoardWorkGroups,
  removeUserFromTaskBoard,
  removeWorkGroupFromTaskBoard,
  saveTaskBoard,
  saveUserToTaskBoard,
  saveWorkGroupToTaskBoard
} from "@/api/taskBoardAPI";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { ITaskBoardModals, ITaskBoardModalsOpen, TaskBoardModalsInitialState } from "@/model/taskBoard/ITaskBoardModals";
import { ITaskBoardUserDTO } from "@/model/taskBoard/ITaskBoardUserDTO";
import { IWorkGroupToTaskBoard } from "@/model/taskBoard/IWorkGroupToTaskBoard";
import { getUniqueValues } from "@/utilities/jsUtilities";
import { selectCurrentUser } from "@/app/store/userSlice";

export const taskBoardsAdapter = createEntityAdapter<ITaskBoard>();

export interface ITaskBoardState {
  status: BhStateStatusType;
  openedTaskBoardId?: EntityId;
  personalTaskBoardOpen?: boolean;
  allTasksTaskBoardOpen?: boolean;
  taskBoardModals: ITaskBoardModals;
}

export const taskBoardsInitialState = taskBoardsAdapter.getInitialState<ITaskBoardState>({
  status: BhStateStatusType.INITIAL,
  taskBoardModals: TaskBoardModalsInitialState
});

export const fetchProjectTaskBoardsAsync = createAsyncThunk("taskBoards/fetchTaskBoards", async (projectId: EntityId) => {
  return fetchProjectTaskBoards(projectId);
});

export const saveTaskBoardAsync = createAsyncThunk("taskBoards/board/save", async (taskBoard: ITaskBoard) => {
  return saveTaskBoard(taskBoard);
});

export const deleteTaskBoardAsync = createAsyncThunk("taskBoards/board/delete", async (taskBoard: ITaskBoard) => {
  return deleteTaskBoard(taskBoard);
});

export const fetchTaskBoardWorkGroupsAsync = createAsyncThunk("taskBoards/board/workgroups", async (taskBoardId: EntityId) => {
  return fetchTaskBoardWorkGroups(taskBoardId);
});

export const saveUserToTaskBoardAsync = createAsyncThunk("taskBoards/user/save", async (taskBoardUserDTO: ITaskBoardUserDTO) => {
  return saveUserToTaskBoard(taskBoardUserDTO);
});

export const removeUserFromTaskBoardAsync = createAsyncThunk("taskBoards/user/remove", async (taskBoardUserDTO: ITaskBoardUserDTO) => {
  return removeUserFromTaskBoard(taskBoardUserDTO);
});

export const saveWorkGroupToTaskBoardAsync = createAsyncThunk("taskBoards/workgroup/save", async (taskBoardUserDTO: ITaskBoardUserDTO) => {
  return saveWorkGroupToTaskBoard(taskBoardUserDTO);
});

export const removeWorkGroupFromTaskBoardAsync = createAsyncThunk("taskBoards/workgroup/remove", async (taskBoardUserDTO: ITaskBoardUserDTO) => {
  return removeWorkGroupFromTaskBoard(taskBoardUserDTO);
});

const taskBoardsSlice = createSlice({
  name: "taskBoards",
  initialState: taskBoardsInitialState,
  reducers: {
    taskBoardsRemoved: taskBoardsAdapter.removeAll,
    taskBoardAdded: taskBoardsAdapter.addOne,
    setOpenedTaskBoardId: (state, action) => {
      state.openedTaskBoardId = action.payload;
    },
    resetOpenedTaskBoardId: (state) => {
      delete state.openedTaskBoardId;
    },
    setPersonalTaskBoardOpen: (state, action) => {
      state.personalTaskBoardOpen = action.payload;
    },
    setAllTasksTaskBoardOpen: (state, action) => {
      state.allTasksTaskBoardOpen = action.payload;
    },
    toggleTaskBoardModalsOpen: (state, action: PayloadAction<{ modal: keyof ITaskBoardModalsOpen }>) => {
      state.taskBoardModals.open[action.payload.modal] = !state.taskBoardModals.open[action.payload.modal];
    },
    setTaskBoardModalActiveTaskBoardId: (state, action) => {
      state.taskBoardModals.activeTaskBoardId = action.payload;
    },
    resetTaskBoardModalActiveTaskBoardId: (state) => {
      delete state.taskBoardModals.activeTaskBoardId;
    },
    addUsersToTaskBoard: (state, action: PayloadAction<{ users: Array<ISimpleValidUserAuthority>; taskBoardId: EntityId }>) => {
      const taskBoard = state.entities[action.payload.taskBoardId];
      if (taskBoard) {
        taskBoard.users = getUniqueValues([...taskBoard.users, ...action.payload.users], "userEntityId");
      }
    },
    removeUsersFromTaskBoard: (state, action: PayloadAction<{ users: Array<ISimpleValidUserAuthority>; taskBoardId: EntityId }>) => {
      const taskBoard = state.entities[action.payload.taskBoardId];
      if (taskBoard) {
        taskBoard.users = taskBoard.users.filter((user) => {
          return !action.payload.users.some((u) => {
            return u.userEntityId === user.userEntityId;
          });
        });
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectTaskBoardsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchProjectTaskBoardsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        taskBoardsAdapter.setAll(state, action.payload);
      })
      .addCase(saveTaskBoardAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveTaskBoardAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        taskBoardsAdapter.upsertOne(state, action.payload);
      })
      .addCase(saveTaskBoardAsync.rejected, (state) => {
        state.status = BhStateStatusType.ERROR;
      })
      .addCase(deleteTaskBoardAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(deleteTaskBoardAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        taskBoardsAdapter.removeOne(state, action.payload.id);
      })
      .addCase(fetchTaskBoardWorkGroupsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchTaskBoardWorkGroupsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        if (action.payload.length > 0) {
          const taskBoard = state.entities[action.payload[0].taskBoardId];
          if (taskBoard) {
            taskBoard.workGroupToTaskBoards = action.payload;
          }
        }
      })
      .addCase(saveUserToTaskBoardAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveUserToTaskBoardAsync.fulfilled, (state) => {
        state.status = BhStateStatusType.SUCCESS;
      })
      .addCase(removeUserFromTaskBoardAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(removeUserFromTaskBoardAsync.fulfilled, (state) => {
        state.status = BhStateStatusType.SUCCESS;
      })
      .addCase(saveWorkGroupToTaskBoardAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveWorkGroupToTaskBoardAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const taskBoard = state.entities[action.payload.taskBoardId];
        if (taskBoard) {
          const wgTb = { taskBoardId: action.payload.taskBoardId, workGroupId: action.payload.workGroupId } as IWorkGroupToTaskBoard;
          taskBoard.workGroupToTaskBoards = [...taskBoard.workGroupToTaskBoards, wgTb];
        }
      })
      .addCase(removeWorkGroupFromTaskBoardAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(removeWorkGroupFromTaskBoardAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const taskBoard = state.entities[action.payload.taskBoardId];
        if (taskBoard) {
          taskBoard.workGroupToTaskBoards = taskBoard.workGroupToTaskBoards.filter((wgTb) => wgTb.workGroupId !== action.payload.workGroupId);
        }
      });
  }
});

export const {
  taskBoardsRemoved,
  taskBoardAdded,
  setOpenedTaskBoardId,
  resetOpenedTaskBoardId,
  setPersonalTaskBoardOpen,
  setAllTasksTaskBoardOpen,
  toggleTaskBoardModalsOpen,
  setTaskBoardModalActiveTaskBoardId,
  resetTaskBoardModalActiveTaskBoardId,
  addUsersToTaskBoard,
  removeUsersFromTaskBoard
} = taskBoardsSlice.actions;
export const { selectIds: selectTaskboardIds, selectAll: selectAllTaskBoards, selectById: selectTaskBoardById } = taskBoardsAdapter.getSelectors((state: RootState) => state.taskBoards);

export const selectOpenedTaskBoardId = (state: RootState) => state.taskBoards.openedTaskBoardId;
export const selectIsPersonalTaskBoardOpen = (state: RootState) => state.taskBoards.personalTaskBoardOpen;
export const selectIsAllTasksTaskBoardOpen = (state: RootState) => state.taskBoards.allTasksTaskBoardOpen;
export const selectTaskBoardSettingsModalOpen = (state: RootState) => state.taskBoards.taskBoardModals.open.taskBoardSettingsModal;
export const selectProjectTaskCategoryModalOpen = (state: RootState) => state.taskBoards.taskBoardModals.open.projectTaskCategoryModal;
export const selectPartyAddTaskBoardModal = (state: RootState) => state.taskBoards.taskBoardModals.open.partyAddTaskBoardModal;
export const selectTaskBoardModalActiveTaskBoardId = (state: RootState) => state.taskBoards.taskBoardModals.activeTaskBoardId;
export const selectTaskBoardStatus = (state: RootState) => state.taskBoards.status;
export const selectOpenedTaskBoard = createSelector([selectAllTaskBoards, selectOpenedTaskBoardId], (taskBoards, openedTaskBoardId) => {
  const taskBoard = taskBoards.find((tb: ITaskBoard) => {
    return tb.id === openedTaskBoardId;
  });
  return taskBoard && taskBoard;
});
export const selectOpenedTaskBoardName = createSelector(
  [selectOpenedTaskBoard, selectIsPersonalTaskBoardOpen, selectIsAllTasksTaskBoardOpen],
  (openedTaskBoard, personalTaskBoardOpen, allTasksTaskBoardOpen) => {
    return openedTaskBoard ? openedTaskBoard.name : personalTaskBoardOpen ? "TASKBOARD.PERSONAL" : allTasksTaskBoardOpen ? "TASKBOARD.ALL_TASKS" : "";
  }
);
export const selectHasCurrentUserAccessToOpenedTaskBoard = createSelector(
  [selectOpenedTaskBoard, selectIsPersonalTaskBoardOpen, selectIsAllTasksTaskBoardOpen, selectCurrentUser],
  (openedTaskBoard, personalTaskBoardOpen, allTasksTaskBoardOpen, currentUser) => {
    return (
      personalTaskBoardOpen ||
      allTasksTaskBoardOpen ||
      (openedTaskBoard && currentUser.privileges.TASK_BOARD && currentUser.privileges.TASK_BOARD[openedTaskBoard.id] && currentUser.privileges.TASK_BOARD[openedTaskBoard.id].isWrite)
    );
  }
);
export const selectStatusToTaskBoardMap = createSelector([selectAllTaskBoards], (taskBoards) => {
  const statusToTaskBoardMap: Record<string, Array<EntityId>> = {};
  taskBoards.sort((a, b) => b.status.localeCompare(a.status) || a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  Object.values(taskBoards).forEach((tb) => {
    if (tb && !statusToTaskBoardMap[tb.status]) {
      statusToTaskBoardMap[tb.status] = [];
    }
    tb && statusToTaskBoardMap[tb.status].push(tb.id);
  });
  return statusToTaskBoardMap;
});
export const selectTaskBoardAbbreviations = createSelector([selectAllTaskBoards], (taskBoards) => {
  return taskBoards.map((tb) => {
    return tb.abbreviation;
  });
});
export const selectTaskBoardProjectAdministrators = (state: RootState) => state.project.projectAdministrators as Array<ISimpleValidUserAuthority>;
export const selectOpenedTaskBoardUsers = createSelector(
  [selectAllTaskBoards, selectOpenedTaskBoardId, selectIsPersonalTaskBoardOpen, selectIsAllTasksTaskBoardOpen, selectTaskBoardProjectAdministrators],
  (taskBoards, openedTaskBoardId, isPersonal, isAllTasks, projectAdministrators) => {
    let taskBoardUsers: Array<ISimpleValidUserAuthority> = [];
    if (isPersonal) {
      return taskBoardUsers;
    }
    taskBoards.forEach((tb) => {
      if (openedTaskBoardId && openedTaskBoardId === tb.id) {
        taskBoardUsers = [...tb.users];
      }
      if (!openedTaskBoardId && isAllTasks) {
        taskBoardUsers = [...taskBoardUsers, ...tb.users];
      }
    });
    taskBoardUsers = [...taskBoardUsers, ...projectAdministrators];
    return getUniqueValues(taskBoardUsers, "userEntityId").sort((a, b) => (a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1));
  }
);
const getTaskBoardId = (_state: any, taskBoardId: EntityId) => taskBoardId;
export const selectTaskBoardUsersByTaskBoardId = createSelector([selectAllTaskBoards, selectTaskBoardProjectAdministrators, getTaskBoardId], (taskBoards, projectAdministrators, taskBoardId) => {
  let taskBoardUsers: Array<ISimpleValidUserAuthority> = [];
  const taskBoard = taskBoards.find((tb) => tb.id === taskBoardId);
  if (taskBoard) {
    taskBoardUsers = [...taskBoard.users];
  }
  taskBoardUsers = [...taskBoardUsers, ...projectAdministrators];
  return getUniqueValues(taskBoardUsers, "userEntityId").sort((a, b) => (a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1));
});

export default taskBoardsSlice.reducer;
