import React, { FC, ReactNode, useEffect } from "react";
import BhBanner from "@components/banners/BhBanner";
import BhIconButton from "@components/buttons/BhIconButton";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { useAppDispatch } from "@/app/hooks";
import { IToastFlag, toastFlagRemoved } from "@/app/store/globalSlice";
import { textWithVariables } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { useNavigate } from "react-router-dom";

interface Props {
  flag: IToastFlag;
  removeCallback?: Function;
  children: ReactNode;
}

const BhOverlayFlag: FC<Props> = ({ flag, removeCallback }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const id = flag.disappear ? setTimeout(() => dispatch(toastFlagRemoved(flag)), 5000) : undefined;

    return () => {
      if (id) clearTimeout(id);
    };
  }, []);

  const body = flag.translate ? t(flag.children as string) : flag.children;

  return (
    <BhBanner type={flag.type} shadow={true}>
      <div className="flex flex-row items-center justify-between">
        {body}
        <div>{flag.translateCode && parse(textWithVariables(t(flag.translateCode), flag.params))}</div>
        <div className="flex flex-row gap-x-1">
          <div>
            {flag.navigateTo && (
              <BhTextOnlyButton
                buttonProps={{
                  onClick: () => {
                    navigate(`${flag.navigateTo}`);
                    dispatch(toastFlagRemoved(flag));
                  }
                }}
              >
                {t("GLOBAL.OPEN")}
              </BhTextOnlyButton>
            )}
          </div>
          {removeCallback && <BhIconButton icon={faTimes} withBackground={false} buttonProps={{ onClick: removeCallback }} />}
        </div>
      </div>
    </BhBanner>
  );
};

export default BhOverlayFlag;
