import React, { FC, ReactNode } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  classes?: string;
  children: ReactNode;
}

const BhSmallText: FC<Props> = ({ classes, children }) => {
  return <p className={classNames(classes, "text-12px l-h-16px")}>{children}</p>;
};

export default BhSmallText;
