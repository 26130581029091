import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  addNestedFormDataAsync,
  fetchFormDTOAsync,
  removeNestedFormDataAsync,
  saveFormDataAsync,
  saveNestedFormDataAsync,
  selectCanCurrentUserEditForm,
  selectCurrentFormId
} from "@/app/store/form/formSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { IFormDataSaveRequest } from "@/model/IForm";
import FormModals from "@components/form/modals/FormModals";
import FormContent from "@components/form/FormContent";
import { EntityId } from "@reduxjs/toolkit";
import FormHeaderRow from "@components/form/FormHeaderRow";
import FormFooterRow from "@components/form/FormFooterRow";

interface Props {
  fileEntityId: EntityId;
  dirId: EntityId;
}

const FormContainerNew: FC<Props> = ({ fileEntityId, dirId }) => {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const formId = useAppSelector(selectCurrentFormId);

  const canCurrentUserEditForm = useAppSelector((state) => selectCanCurrentUserEditForm(state, projectId));

  useEffect(() => {
    dispatch(fetchFormDTOAsync({ dirId: dirId, fileEntityId: fileEntityId, projectId: projectId }));
  }, [fileEntityId]);

  const formDataSave = (changedObject: any, path?: string) => {
    const dataSaveRequest = { formId: formId, fileEntityId: fileEntityId, projectId: projectId, changes: changedObject, path: path } as IFormDataSaveRequest;
    return dispatch(saveFormDataAsync(dataSaveRequest));
  };

  const formNestedDataSave = (formDataSaveRequest: IFormDataSaveRequest) => {
    const dataSaveRequest = {
      formId: formId,
      fileEntityId: fileEntityId,
      projectId: projectId,
      path: formDataSaveRequest.path,
      changedProperty: formDataSaveRequest.changedProperty,
      changedValue: formDataSaveRequest.changedValue,
      changedObjectId: formDataSaveRequest.changedObjectId,
      changes: formDataSaveRequest.changes
    } as IFormDataSaveRequest;
    dispatch(saveNestedFormDataAsync(dataSaveRequest));
  };

  const formNestedDataAdd = (changedObject: any, path: string, index?: number) => {
    const dataSaveRequest = { formId: formId, fileEntityId: fileEntityId, projectId: projectId, changes: changedObject, path: path, index: index } as IFormDataSaveRequest;
    dispatch(addNestedFormDataAsync(dataSaveRequest));
  };

  const formNestedDataRemove = (changedObject: any, path: string) => {
    const dataSaveRequest = { formId: formId, fileEntityId: fileEntityId, projectId: projectId, changes: changedObject, path: path } as IFormDataSaveRequest;
    dispatch(removeNestedFormDataAsync(dataSaveRequest));
  };

  if (!formId) return null;

  return (
    <>
      <div className="pr-3 pb-24">
        <div className="mb-5">
          <FormHeaderRow disabled={!canCurrentUserEditForm} projectId={projectId} saveNumberCallback={formDataSave} dirId={dirId} formId={formId} />
        </div>
        <FormContent
          dataSaveCallback={formDataSave}
          nestedDataSaveCallback={formNestedDataSave}
          nestedDataAddCallback={formNestedDataAdd}
          nestedDataRemoveCallback={formNestedDataRemove}
          disabled={!canCurrentUserEditForm}
        />
        <FormFooterRow disabled={!canCurrentUserEditForm} saveCallback={formDataSave} />
      </div>
      <FormModals dirId={dirId} saveCallback={formDataSave} />
    </>
  );
};

export default FormContainerNew;
