import React, { FC, useRef } from "react";
import { faFolderPlus } from "@fortawesome/pro-regular-svg-icons/faFolderPlus";
import BhIconButton from "@components/buttons/BhIconButton";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  allFileEntitiesUnSelected,
  authoritiesModalFileEntitySet,
  directoryInfoBarTabSet,
  fetchFileEntityAsync,
  fileEntitySelected,
  selectFilesSelectedInCurrentDirectory,
  selectOneFileSelected,
  setDirectoryModalsOpen
} from "@/app/store/filesSlice";
import BhDropdown from "@components/dropdown/BhDropdown";
import { faArrowUpToLine } from "@fortawesome/pro-regular-svg-icons/faArrowUpToLine";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import DirectoryFileUpload from "@/views/home/project/detail/directory/DirectoryFileUpload";
import { selectCurrentProjectId, selectIsProjectArchived } from "@/app/store/project/projectSlice";
import DirectoryZipFileUploadAndImport from "@/views/home/project/detail/directory/DirectoryZipFileUploadAndImport";
import { selectFolderById } from "@/app/store/foldersSlice";
import { EntityId } from "@reduxjs/toolkit";
import { FileEntityBranch, FileEntityType } from "@/model/files/IFileEntity";
import { IAuthorityBoolean } from "@/model/IUser";
import { useTranslation } from "react-i18next";
import BhTooltip from "@components/BhTooltip";
import DirectoryFileListHeaderMenuNewFormButton from "@/views/home/project/detail/directory/DirectoryFileListHeaderMenuNewFormButton";
import DirectoryRevisionUpload from "@/views/home/project/detail/directory/DirectoryRevisionUpload";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import BhElementWithBadge from "@components/badge/BhElementWithBadge";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import { faMessage } from "@fortawesome/pro-regular-svg-icons/faMessage";
import { faClockRotateLeft } from "@fortawesome/pro-regular-svg-icons/faClockRotateLeft";
import { faUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { DirectoryFileInfoBarTab } from "@/views/home/project/detail/directory/fileInfoBar/DirectoryFileInfoBar";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";

interface Props {
  currentDirId: EntityId;
  privilegesForDirectory: IAuthorityBoolean;
}

const DirectoryFileListHeaderMenu: FC<Props> = ({ currentDirId, privilegesForDirectory }) => {
  const { t } = useTranslation();
  const isProjectNotArchived = !useAppSelector(selectIsProjectArchived);
  const currentDirectory = useAppSelector((state) => selectFolderById(state, currentDirId));

  const isCurrentDirDocument = currentDirectory?.branch === FileEntityBranch.ROOT_DOCUMENT_DIR;
  const fileInputRef = useRef(null);
  const revisionInputRef = useRef(null);
  const zipFileInputRef = useRef(null);

  const projectId = useAppSelector(selectCurrentProjectId);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const selectedFileEntitiesInDirectory = useAppSelector(selectFilesSelectedInCurrentDirectory);
  const oneFileSelected = useAppSelector(selectOneFileSelected);

  const selectedFile = selectedFileEntitiesInDirectory.length === 1 && oneFileSelected && selectedFileEntitiesInDirectory[0];
  const filesSelected = selectedFileEntitiesInDirectory.length > 0;

  const dispatch = useAppDispatch();

  const showProjectLogButtonForCurrentDir = isProjectAdmin || privilegesForDirectory.isRead;

  const selectView = (value: DirectoryFileInfoBarTab) => {
    dispatch(directoryInfoBarTabSet(value));
  };

  const openFileHistoryModal = () => {
    dispatch(setDirectoryModalsOpen({ modal: "fileHistoryModal", value: true }));
  };

  const toggleAuthoritiesModal = () => {
    if (!isProjectAdmin) return;
    if (selectedFile) {
      dispatch(setDirectoryModalsOpen({ modal: "authoritiesModal", value: true }));
      dispatch(authoritiesModalFileEntitySet(selectedFile.id));
    }
  };

  const openCurrentDirectoryHistoryModal = () => {
    if (showProjectLogButtonForCurrentDir) {
      dispatch(allFileEntitiesUnSelected());
      dispatch(fetchFileEntityAsync(currentDirId));
      dispatch(setDirectoryModalsOpen({ modal: "fileHistoryModal", value: true }));
    }
  };

  const openCurrentDirectoryAuthoritiesModal = () => {
    if (!isProjectAdmin) return;
    dispatch(allFileEntitiesUnSelected());
    dispatch(fileEntitySelected({ ids: [currentDirId], selected: true }));
    dispatch(fetchFileEntityAsync(currentDirId));
    dispatch(setDirectoryModalsOpen({ modal: "authoritiesModal", value: true }));
    dispatch(authoritiesModalFileEntitySet(currentDirId));
  };

  return (
    <div className="my-1 flex flex-row flex-wrap justify-between">
      <div>
        <DirectoryFileUpload fileInputRef={fileInputRef} />
        <DirectoryRevisionUpload fileInputRef={revisionInputRef} />
        <DirectoryZipFileUploadAndImport fileInputRef={zipFileInputRef} />
        {privilegesForDirectory?.isWrite && (
          <div className="flex flex-row space-x-4">
            {isCurrentDirDocument && isProjectNotArchived && <DirectoryFileListHeaderMenuNewFormButton currentDirId={currentDirId} />}
            <BhDropdown
              button={
                <BhTooltip body={t("FORM.UPLOAD")}>
                  <BhIconButton icon={faArrowUpToLine} />
                </BhTooltip>
              }
              menu={
                <BhDropdownMenu
                  textProperty="text"
                  type={BhDropdownTypeEnum.STRING}
                  values={[
                    {
                      text: t("GLOBAL.UPLOAD_FILES"),
                      function: () => {
                        // @ts-ignore
                        fileInputRef.current.click();
                      }
                    },
                    {
                      text: t("GLOBAL.UPLOAD_VERSIONS"),
                      function: () => {
                        // @ts-ignore
                        revisionInputRef.current.click();
                      }
                    },
                    {
                      text: t("MODAL.UPLOAD_FILE.CHOOSE_IMPORT_FILE"),
                      function: () => {
                        // @ts-ignore
                        zipFileInputRef.current.click();
                      }
                    }
                  ]}
                />
              }
            />
            <BhTooltip body={t("GLOBAL.NEW_DIR")}>
              <BhIconButton icon={faFolderPlus} buttonProps={{ onClick: () => dispatch(setDirectoryModalsOpen({ modal: "newFolderModal", value: true })) }} />
            </BhTooltip>
          </div>
        )}
      </div>
      <div className="flex flex-row justify-end">
        {!filesSelected && currentDirectory && (
          <div className="flex flex-row space-x-4">
            {isProjectAdmin && (
              <BhTooltip body={t("DIRECTORY.FOLDER_ACCESS")}>
                <BhIconButton icon={faUsers} buttonProps={{ onClick: openCurrentDirectoryAuthoritiesModal }} />
              </BhTooltip>
            )}
            {showProjectLogButtonForCurrentDir && (
              <BhTooltip body={t("DIRECTORY.LOG")}>
                <BhIconButton icon={faClockRotateLeft} buttonProps={{ onClick: openCurrentDirectoryHistoryModal }} />
              </BhTooltip>
            )}
          </div>
        )}
        {selectedFile && (
          <div className="flex flex-row space-x-4">
            {isProjectAdmin && selectedFile && selectedFile.type === FileEntityType.DIR && (
              <BhTooltip body={t("DIRECTORY.FOLDER_ACCESS")}>
                <BhIconButton icon={faUsers} buttonProps={{ onClick: () => toggleAuthoritiesModal() }} />
              </BhTooltip>
            )}
            <BhTooltip body={t(selectedFile.type !== FileEntityType.DIR ? "MODAL.FILE_LOG_BUTTON" : "DIRECTORY.LOG")}>
              <BhIconButton icon={faClockRotateLeft} buttonProps={{ onClick: openFileHistoryModal }} />
            </BhTooltip>
            <BhTooltip body={t("TASK.COMMENTS")}>
              <BhElementWithBadge badge={selectedFile && selectedFile.commentCount > 0 && <BhBadge type={BhBadgeType.DARK}>{selectedFile.commentCount}</BhBadge>}>
                <BhIconButton icon={faMessage} buttonProps={{ onClick: () => selectView(DirectoryFileInfoBarTab.COMMENTS) }} />
              </BhElementWithBadge>
            </BhTooltip>
            <BhTooltip body={t(selectedFile.type !== FileEntityType.DIR ? "MODAL.INFORMATION.TITLE" : "DIRECTORY.INFO")}>
              <BhIconButton icon={faInfoCircle} buttonProps={{ onClick: () => selectView(DirectoryFileInfoBarTab.INFO) }} />
            </BhTooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default DirectoryFileListHeaderMenu;
