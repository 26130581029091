import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  archiveOrActivateChecklistsAsync,
  completeChecklistRoundAsync,
  saveChecklistAsync,
  selectCanCurrentUserEditChecklist,
  startNewChecklistRoundFromLatestRoundAsync
} from "@/app/store/checklist/checklistsSlice";
import BhIconButton from "@components/buttons/BhIconButton";
import { ChecklistRoundStatus, IChecklistRound } from "@/model/checklist/IChecklistRound";
import { faLockKeyhole as faLockKeyholeRegular } from "@fortawesome/pro-regular-svg-icons/faLockKeyhole";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { useNavigate } from "react-router-dom";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { checkIfAllRequiredRowsFilled, fetchChecklistRoundWithRowsAsync } from "@/app/store/checklist/checklistRowsSlice";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhLightSeparatorVertical from "@components/separator/BhLightSeparatorVertical";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons/faEllipsisVertical";
import { generatePdfForChecklistRound } from "@/api/checklist/checklistAPI";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { IDropdownItem } from "@/model/IDropdownItem";
import { faBoxArchive } from "@fortawesome/pro-regular-svg-icons/faBoxArchive";
import { IChecklist } from "@/model/checklist/IChecklist";
import { useTranslation } from "react-i18next";
import { fullPageLoadingSet, toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import ChecklistHeaderRoundSigningContainer from "@components/checklists/checklist/header/ChecklistHeaderRoundSigningContainer";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import BhTooltip from "@components/BhTooltip";
import { faArrowRotateLeft } from "@fortawesome/pro-regular-svg-icons/faArrowRotateLeft";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  checklist: IChecklist;
  checklistRound: IChecklistRound;
}

const ChecklistHeaderRoundActions: FC<Props> = ({ checklist, checklistRound }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const allRequiredFieldsFilled = useAppSelector(checkIfAllRequiredRowsFilled);
  const complete = checklistRound.status === ChecklistRoundStatus.COMPLETE;
  const canUserEdit = useAppSelector((state) => selectCanCurrentUserEditChecklist(state, projectId, checklist.id));
  const completeRoundButtonVisible = !complete && canUserEdit && !checklist.archived;
  const newRoundButtonVisible = complete && canUserEdit && !checklist.archived;
  const signingButtonVisible = complete && canUserEdit && !checklist.archived;
  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(
    () => (
      <BhDeleteConfirmationModal
        setIsOpen={hideDeleteConfirmationModal}
        header={<h2>{t("CHECKLIST.DELETE_CHECKLIST.HEADER")}</h2>}
        body={<div>{t("CHECKLIST.DELETE_CHECKLIST.BODY")}</div>}
        confirmationButtonText={t("GLOBAL.DELETE")}
        handleDelete={() => {
          deleteChecklist();
          hideDeleteConfirmationModal();
        }}
      />
    ),
    [checklist]
  );

  const changeRound = (round: IChecklistRound) => {
    if (round.id === checklistRound.id) {
      return;
    }
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklists/${round.checklistId}/round/${round.id}`);
  };

  const toggleArchiveChecklist = () => {
    dispatch(archiveOrActivateChecklistsAsync([checklist.id])).then(() => {
      goBack(!checklist.archived);
    });
  };

  const deleteChecklist = () => {
    const checklistToDelete = { ...checklist, deleted: true };
    dispatch(saveChecklistAsync(checklistToDelete)).then(() => {
      goBack();
    });
  };

  const ellipsisDropdownValues: Array<IDropdownItem> = [
    {
      text: checklist.archived ? t("CHECKLIST.RESTORE") : t("CHECKLIST.ARCHIVE"),
      function: toggleArchiveChecklist,
      icon: checklist.archived ? faArrowRotateLeft : faBoxArchive
    },
    {
      text: t("GLOBAL.DELETE"),
      function: showDeleteConfirmationModal,
      icon: faTrash
    }
  ];

  const goBack = (toArchivedView?: boolean) => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklists/list/${toArchivedView ? "archived" : "view"}?category=` + checklist.category);
  };

  const completeChecklistRound = () => {
    if (allRequiredFieldsFilled) {
      dispatch(completeChecklistRoundAsync(checklistRound.id)).then(() => {
        dispatch(fetchChecklistRoundWithRowsAsync(checklistRound.id));
      });
    } else {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          children: t("CHECKLIST.REQUIRED_FIELDS_NOT_FILLED")
        })
      );
    }
  };

  const startNewRound = () => {
    const lastRoundId = checklist.checklistRounds && checklist.checklistRounds.reduce((max, round) => (max.roundNumber > round.roundNumber ? max : round)).id;
    if (lastRoundId) {
      dispatch(startNewChecklistRoundFromLatestRoundAsync(lastRoundId)).then((action) => {
        const newRound = action.payload as IChecklistRound;
        changeRound(newRound);
      });
    }
  };

  const openPdf = () => {
    dispatch(fullPageLoadingSet(true));
    generatePdfForChecklistRound(projectId, checklistRound.id).then((response) => {
      dispatch(fullPageLoadingSet(false));
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/view/${response.id}/${response.uuid}`);
      dispatch(fullPageLoadingSet(false));
    });
  };

  return (
    <div className="flex h-full flex-row items-center pr-4">
      {canUserEdit && (
        <div className="flex flex-row items-center">
          <BhDropdown
            button={<BhIconButton icon={faEllipsisVertical} buttonProps={{ classes: "" }} />}
            menu={<BhDropdownMenu values={ellipsisDropdownValues} type={BhDropdownTypeEnum.STRING} textProperty="text" widthClass={"w-40"} />}
            position={BhDropdownPositionEnum.BOTTOM_LEFT}
          />
          <div className="mx-2 h-6">
            <BhLightSeparatorVertical />
          </div>
        </div>
      )}
      <BhTooltip body={t("GLOBAL.AS_PDF")}>
        <BhTextOnlyButton buttonProps={{ onClick: openPdf }}>{t("CHECKLIST.DOWNLOAD.PDF")}</BhTextOnlyButton>
      </BhTooltip>
      {signingButtonVisible && <ChecklistHeaderRoundSigningContainer checklist={checklist} checklistRound={checklistRound} />}
      {completeRoundButtonVisible && (
        <BhTooltip body={!allRequiredFieldsFilled ? t("CHECKLIST.REQUIRED_FIELDS_NOT_FILLED") : ""}>
          <BhSecondaryButton icon={faLockKeyholeRegular} buttonProps={{ onClick: completeChecklistRound, disabled: !allRequiredFieldsFilled }}>
            {t("CHECKLIST.END_ROUND")}
          </BhSecondaryButton>
        </BhTooltip>
      )}
      {newRoundButtonVisible && (
        <BhPrimaryButton icon={faPlus} buttonProps={{ onClick: startNewRound }}>
          {t("CHECKLIST.START_NEW_ROUND")}
        </BhPrimaryButton>
      )}
    </div>
  );
};

export default ChecklistHeaderRoundActions;
