import React, { FC, useEffect, useRef, useState } from "react";
import { faFolder } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileEntityType, IFileEntity, IUploadFileEntity } from "@/model/files/IFileEntity";
import { fetchUrlForFile } from "@/api/fileAPI";
import { classNames } from "@/utilities/jsUtilities";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons/faSpinner";
import { ReactComponent as BhLogoMark } from "@svg/bauhub-logomark-green.svg";
import { IFileConfirmationFile } from "@/model/confirmations/IFileConfirmationFile";
import { faImageLandscape } from "@fortawesome/pro-regular-svg-icons/faImageLandscape";
import { isDirectory } from "@/utilities/fileEntity/fileEntityUtilities";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons/faListCheck";
import BhFileCardImagePreview from "@components/cards/BhFileCardImagePreview";
import { fetchPublicShareboxFileUrl } from "@/api/shareboxAPI";

export enum FileCardSize {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE"
}

interface Props {
  fileEntity: IFileEntity | IUploadFileEntity | IFileConfirmationFile;
  fileCardSize: FileCardSize;
  withThumbnail?: boolean;
  previewVisible?: boolean;
  onPreviewHover?: Function;
  shareboxUuid?: string;
}

const BhFileCardIcon: FC<Props> = ({ fileEntity, fileCardSize, withThumbnail = true, previewVisible, onPreviewHover, shareboxUuid }) => {
  const imageContentTypes = ["image/png", "image/jpg", "image/jpeg", "image/pjpeg", "image/gif"];
  const iconRef = useRef<HTMLDivElement | null>(null);

  const [isLoading, setIsLoading] = useState(withThumbnail);
  const [thumbUrl, setThumbUrl] = useState("");

  const fileEntityId = "fileEntityId" in fileEntity ? fileEntity.fileEntityId : fileEntity.id;

  const isImage = imageContentTypes.indexOf(fileEntity.contentType) !== -1;
  const isFolder = isDirectory(fileEntity);
  const isForm = fileEntity.type === FileEntityType.FORM;
  const isAct = fileEntity.type === FileEntityType.ACT;
  const isChecklist = fileEntity.type === FileEntityType.CHECKLIST;

  const isFile = !isFolder && !isForm && !isAct && !isChecklist;
  const isBauhubEntity = isForm || isAct;
  const isChecklistEntity = !isFile && !isBauhubEntity && isChecklist;

  const extension =
    isFile &&
    fileEntity.name
      ?.substring(fileEntity.name.lastIndexOf(".") + 1)
      .toUpperCase()
      .substring(0, 5);

  let componentMounted = true;

  const sizeClassesMap: Record<FileCardSize, string> = {
    [FileCardSize.SMALL]: "bh-file-card-small-icon",
    [FileCardSize.MEDIUM]: "bh-file-card-medium-icon",
    [FileCardSize.LARGE]: "bh-file-card-large-icon"
  };

  const fileTypeClassesMap = {
    PDF: "bh-file-card-icon-pdf",
    ASICE: "bh-file-card-icon-asice",
    XLS: "bh-file-card-icon-xls",
    XLSX: "bh-file-card-icon-xls",
    DOC: "bh-file-card-icon-doc",
    DOCX: "bh-file-card-icon-doc",
    DWG: "bh-file-card-icon-dwg",
    IFC: "bh-file-card-icon-ifc",
    ZIP: "bh-file-card-icon-zip",
    RAR: "bh-file-card-icon-zip",
    DEFAULT: "bh-file-card-icon-file"
  };

  const classes = classNames(
    "rounded-sm flex justify-center items-center flex-none overflow-hidden",
    (isBauhubEntity || isChecklistEntity) && "bh-file-card-icon-bauhub-entity",
    sizeClassesMap[fileCardSize],
    // @ts-ignore
    isFile && (fileTypeClassesMap[extension] ? fileTypeClassesMap[extension] : fileTypeClassesMap["DEFAULT"])
  );

  async function fetchThumbnailUrlsAsyncIfImage() {
    if (("uploading" in fileEntity && fileEntity.uploading) || ("errorUploading" in fileEntity && fileEntity.errorUploading)) {
      return;
    }
    // @ts-ignore
    if (isImage && !fileEntity.uploading && !fileEntity.thumbUrl) {
      const presignedUrlPromise = shareboxUuid ? fetchPublicShareboxFileUrl(shareboxUuid, fileEntity.uuid, false, true, true) : fetchUrlForFile(fileEntityId, true, false, fileEntity?.uuid);
      const presignedUrl = await presignedUrlPromise;
      if (componentMounted) {
        setThumbUrl(presignedUrl.value);
        setIsLoading(false);
      }
    } else if (fileEntity && fileEntity.thumbUrl) {
      setThumbUrl(fileEntity.thumbUrl);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    return () => {
      componentMounted = false;
    };
  }, []);

  useEffect(() => {
    if (withThumbnail) {
      fetchThumbnailUrlsAsyncIfImage();
    }
    // @ts-ignore
  }, [fileEntity.uploading, fileEntity.id]);

  if (isImage) {
    return (
      <div className={classes} ref={iconRef}>
        {withThumbnail && (
          <div>
            {isLoading && <FontAwesomeIcon icon={faSpinner} className="w-4 w-4" spin aria-hidden="true" />}
            {!isLoading && <img className="inline-flex h-full w-full rounded-sm" style={{ objectFit: "cover" }} src={thumbUrl} crossOrigin="" alt="" />}
            {previewVisible && <BhFileCardImagePreview thumbUrl={thumbUrl} onHoverIn={() => onPreviewHover && onPreviewHover()} loading={isLoading} iconRef={iconRef} />}
          </div>
        )}
        {!withThumbnail && <FontAwesomeIcon icon={faImageLandscape} className="w-4 w-4" aria-hidden="true" />}
      </div>
    );
  }

  return (
    <div className={classes}>
      {isFolder && <FontAwesomeIcon icon={faFolder} className="bh-text-pigeon-60" />}
      {isFile && <span>{extension}</span>}
      {isBauhubEntity && <BhLogoMark className="!h-1/2 w-auto" />}
      {isChecklistEntity && <FontAwesomeIcon icon={faListCheck} className={"bh-text-dark-jungle"} />}
    </div>
  );
};

export default BhFileCardIcon;
