import React, { FC, useEffect, useState } from "react";
import BhShareModal from "@components/modal/BhShareModal";
import BhShareFileContainer from "@components/modal/BhShareFileContainer";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhIconButton from "@components/buttons/BhIconButton";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhLightSeparator from "@components/separator/BhLightSeparator";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhInputLabel from "@components/input/BhInputLabel";
import BhCheckboxWithText from "@components/checkboxes/BhCheckboxWithText";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import { IContainerCreateRequest, IUserSigningInfo } from "@/model/container/IContainerCreateRequest";
import NewContainerSignaturesList from "@/views/home/project/detail/container/containerComponents/NewContainerSignaturesList";
import useHandleFormSubmit from "@/utilities/hooks/useHandleFormSubmit";
import { fetchRelatedUsersInProjectWithSearch } from "@/api/projectAPI";
import { convertSimpleValidUserAuthorityToUser } from "@/model/ISimpleValidUserAuthority";
import { IUser } from "@/model/IUser";
import BhChildComponentWithFetchValues from "@components/input/BhChildComponentWithFetchValues";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { Trans, useTranslation } from "react-i18next";
import BhSearchInputWithUserDropdownAndCustomEmailOption from "@components/input/BhSearchInputWithUserDropdownAndCustomEmailOption";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { FileEntityResource, IFileEntity } from "@/model/files/IFileEntity";
import { AsyncThunk, EntityId } from "@reduxjs/toolkit";
import { IAsyncJobResponse } from "@/model/IAsyncJobResponse";
import { selectCurrentUserLanguage } from "@/app/store/userSlice";

interface Props {
  files: Array<IFileEntity>;
  requestObject: IContainerCreateRequest;
  hasFileDeletePermission?: boolean;
  allowPublicSigners?: boolean;
  allowOrderNumber?: boolean;
  containerPath?: string;
  predefinedSigners?: Array<IUserSigningInfo>;
  closeModalCallback: Function;
  createContainerCallback: AsyncThunk<IAsyncJobResponse, { projectId: EntityId; requestDTO: IContainerCreateRequest }, {}>;
  containerCreatedCallback?: Function;
  onFileSelect?: Function;
  onFileDelete?: Function;
}

const BhContainerModal: FC<Props> = ({
  hasFileDeletePermission,
  allowOrderNumber,
  allowPublicSigners,
  containerPath,
  files,
  predefinedSigners,
  requestObject,
  closeModalCallback,
  createContainerCallback,
  containerCreatedCallback,
  onFileSelect,
  onFileDelete
}) => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguage);
  const projectId = useAppSelector(selectCurrentProjectId);
  const [requestDTO, setRequestDTO] = useState<IContainerCreateRequest>(requestObject);
  const [signers, setSigners] = useState<Array<IUserSigningInfo>>([]);
  const handleFormSubmit = useHandleFormSubmit<IContainerCreateRequest>();
  const [saving, setSaving] = useState(false);
  const dispatch = useAppDispatch();
  const isChecklistRoundContainer = files.some((file) => file.resource === FileEntityResource.CHECKLIST_ROUND);

  useEffect(() => {
    if (predefinedSigners) {
      setSigners(predefinedSigners);
    }
  }, [predefinedSigners]);

  const handleSubmit = (formValues: IContainerCreateRequest) => {
    if (formValues.containerName.length < 1) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.WARNING,
          disappear: true,
          translateCode: "INPUT.NOT_EMPTY"
        })
      );
      return;
    }

    setSaving(true);
    const signersWithOrderNumbers = requestDTO.queueEnabled ? signers.map((signer, index) => ({ ...signer, orderNumber: (index + 1) * 10 })) : signers;
    const requestFiles = requestDTO.hideTaskInfo ? [] : files;
    const savableContactObject: IContainerCreateRequest = { ...requestDTO, ...formValues, users: signersWithOrderNumbers, files: requestFiles, containerName: formValues.containerName + ".asice" };

    dispatch(createContainerCallback({ projectId: projectId, requestDTO: savableContactObject })).then(() => {
      setSaving(false);
      containerCreatedCallback && containerCreatedCallback();
    });
  };

  const addSigner = (value: IUser) => {
    const signer = { ...value, userExists: value.id ? true : false } as object as IUserSigningInfo;
    if (signers.some((signer) => signer.username === value.username)) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.WARNING,
          disappear: true,
          translateCode: "PARTY.ALREADY_WG_USER"
        })
      );
    }
    setSigners([...signers, signer]);
  };

  const removeSigner = (signerToRemove: IUserSigningInfo) => {
    setSigners(signers.filter((signer) => signer !== signerToRemove));
  };

  const showValueCondition = (user: IUserSigningInfo) => {
    return !signers.find((s) => s.username === user.username);
  };

  const insertOldIndexToNewIndex = (oldIndex: number, newIndex: number) => {
    const oldSigner = signers.find((_, index) => index === oldIndex);
    const withoutSigner = [...signers].filter((_, index) => index !== oldIndex);
    if (oldSigner && withoutSigner) {
      const newSigners = [...withoutSigner.slice(0, newIndex), oldSigner, ...withoutSigner.slice(newIndex)];
      setSigners(newSigners);
    }
  };

  return (
    <BhShareModal
      header={
        <div className="flex flex-row items-center justify-between">
          <h2>
            <Trans>CHECKLIST.CREATE_CONTAINER</Trans>
          </h2>
          <BhIconButton icon={faTimes} buttonProps={{ onClick: closeModalCallback }} />
        </div>
      }
      footer={
        <div className="flex w-full flex-1 justify-between">
          <BhTextOnlyButton buttonProps={{ onClick: closeModalCallback }}>
            <Trans>GLOBAL.BACK</Trans>
          </BhTextOnlyButton>
          <BhPrimaryButton buttonProps={{ submitForm: "newContainerForm", disabled: saving || files.length === 0 }}>
            {currentUserLanguage === "ru_RU"
              ? files.length <= 1
                ? t("GLOBAL.CREATE_CONTAINER").slice(0, -1)
                : t("GLOBAL.CREATE_CONTAINER") + " (" + files.length + ")"
              : t("GLOBAL.CREATE_CONTAINER") + " " + files.length + " " + (files.length === 1 ? t("GLOBAL.WITH_FILE") : t("GLOBAL.WITH_FILES"))}
          </BhPrimaryButton>
        </div>
      }
      onOutsideClick={closeModalCallback}
    >
      <BhShareFileContainer files={files} fileAddingCallback={onFileSelect} deleteCallback={onFileDelete} />
      <div className="py-8 px-20">
        <form id="newContainerForm" onSubmit={handleFormSubmit((requestDTO) => handleSubmit(requestDTO))}>
          <BhInputStackedLabel initialValue={requestDTO.containerName} property={"containerName"} label={t("MODAL.NEW_CONTAINER.CONTAINER_NAME")} onChangeCallback={() => {}} autoFocus={true} />

          {containerPath && (
            <>
              <div className="mt-6 flex flex-row items-center">
                <BhInputLabel>
                  <Trans>CONTAINER.DESTINATION</Trans>
                </BhInputLabel>
              </div>
              <div className="px-0.5">
                <FontAwesomeIcon icon={faFolder} className="bh-text-deep-ocean-60 pr-2" />
                {containerPath}
              </div>
            </>
          )}
          {hasFileDeletePermission && (
            <div className="mt-6 space-y-2">
              <BhInputLabel>
                <Trans>CONTAINER.FILE_DELETION</Trans>
              </BhInputLabel>
              <div className="-ml-3">
                <BhCheckboxWithText
                  text={t("MODAL.NEW_CONTAINER.DELETE_FILES")}
                  isChecked={requestDTO.deleteFiles}
                  property={"deleteFiles"}
                  onChange={(changed: IContainerCreateRequest) => {
                    setRequestDTO({ ...requestDTO, deleteFiles: changed.deleteFiles });
                  }}
                />
              </div>
            </div>
          )}
          {isChecklistRoundContainer && (
            <div>
              <div className="my-10">
                <BhLightSeparator />
              </div>
              <div className="space-y-2">
                <BhInputLabel>
                  <Trans>CHECKLISTS.HIDE_TASK_LABEL</Trans>
                </BhInputLabel>
                <div className="-ml-3">
                  <BhCheckboxWithText
                    text={t("CHECKLISTS.HIDE_TASK_INFO")}
                    isChecked={requestDTO.hideTaskInfo}
                    property={"hideTaskInfo"}
                    onChange={(changed: IContainerCreateRequest) => {
                      setRequestDTO({ ...requestDTO, hideTaskInfo: changed.hideTaskInfo });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </form>
        <div className="my-10">
          <BhLightSeparator />
        </div>
        <div className="flex flex-row pb-8">
          <div className="w-1/2">
            <BhInputLabel>
              <Trans>SIGNING.SIGNER</Trans>
            </BhInputLabel>
            <BhChildComponentWithFetchValues
              fetchValues={() => {
                return fetchRelatedUsersInProjectWithSearch(projectId).then((users) => {
                  return users.map((simpleValidUser) => convertSimpleValidUserAuthorityToUser(simpleValidUser));
                });
              }}
              child={
                <BhSearchInputWithUserDropdownAndCustomEmailOption
                  values={[]}
                  onSelect={addSigner}
                  showValueCondition={showValueCondition}
                  placeholder={t("CONTAINER.SIGN_INVITE_PLACEHOLDER") as string}
                />
              }
            />
          </div>
          <div className="flex w-1/2 flex-row items-center justify-end space-x-4 pt-7">
            <BhInputLabel>
              <Trans>CONTAINER.ENABLE_QUEUE</Trans>
            </BhInputLabel>
            <BhToggleSwitch
              value={requestDTO.queueEnabled}
              property={"queueEnabled"}
              onClickAction={(changed: IContainerCreateRequest) => {
                setRequestDTO({ ...requestDTO, queueEnabled: changed.queueEnabled });
              }}
            />
          </div>
        </div>
        <DndProvider backend={HTML5Backend}>
          <NewContainerSignaturesList users={signers} isQueueEnabled={requestDTO.queueEnabled} removeSigner={removeSigner} insertOldIndexToNewIndex={insertOldIndexToNewIndex} />
        </DndProvider>
      </div>
    </BhShareModal>
  );
};

export default BhContainerModal;
