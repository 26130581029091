import React, { FC, ReactNode, useEffect, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { fetchFormPdfPreviewHtml, fetchFormTypePdfPreviewHtml } from "@/api/form/formBaseAPI";
import { useModal } from "react-modal-hook";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhModal from "@components/modal/BhModal";
import { FormType } from "@/model/IForm";
import ShadowContent from "@components/wysiwyg/ShadowContent";
import { IFormPdfPreview } from "@/model/form/IFormPdfPreview";
import { useTranslation } from "react-i18next";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  companyId: EntityId;
  formBaseId?: EntityId;
  formBaseVersionId?: EntityId;
  formType?: string;
  children: ReactNode;
}

const FormBaseHeaderPdfButton: FC<Props> = ({ companyId, formBaseId, formBaseVersionId, formType, children }) => {
  const { t } = useTranslation();
  const [formHtml, setFormHtml] = useState<IFormPdfPreview>({ content: "", footer: "", header: "" });

  const hasNoPDF =
    formType &&
    [
      FormType.DOKUMENTIDE_REGISTER_NORDECON,
      FormType.EHITUSOBJEKTI_TOOOHUTUSE_PLAAN_MARU,
      FormType.KOOSOLEK_MARU,
      FormType.REGISTER_NORDECON,
      FormType.KONTROLLIDE_REGISTER_NORDECON,
      FormType.RINGKAIGU_CHECKLIST_NORDECON,
      FormType.ULEVAATUSE_LEIUD_NORDECON,
      FormType.TOOPLAAN_NORDECON,
      FormType.TOOPLAAN_NORDECON_PTV,
      FormType.KOKKULEPETE_REGISTER_NORDECON,
      FormType.TOOPLAAN
    ]
      .map((t) => t.toString())
      .includes(formType);

  const isLandscape =
    formType &&
    [
      FormType.KOOSOLEK,
      FormType.KOOSOLEK_LV,
      FormType.KOOSOLEK_KULINVEST,
      FormType.KOOSOLEK_NORDECON,
      FormType.LEPINGUTE_REGISTER_NORDECON,
      FormType.MATERJALIDE_KOOSKOLASTAMISE_VORM,
      FormType.PROOVIDE_KOONDTABEL_NORDECON,
      FormType.TOODE_MARKUSTE_TABEL_MERKO
    ]
      .map((t) => t.toString())
      .includes(formType);

  const [showPdfPreviewModal, hidePdfPreviewModal] = useModal(
    () => (
      <BhModal
        isShown={true}
        setIsShown={hidePdfPreviewModal}
        header={<h2>{t("FORMBUILDER.FORM.PDF_PREVIEW")}</h2>}
        children={
          <div className="bh-bg-smoke h-full w-full overflow-auto p-4">
            <div className={classNames(formHtml.addHeaderFooterMargins && "custom-scale-90", isLandscape && "a4-landscape", "a4-paper flex flex-col")}>
              <ShadowContent htmlContent={formHtml.header} contentId="header" className={classNames(formHtml.addHeaderFooterMargins && "mx-10 my-4")} />
              <div className="flex-1">
                <ShadowContent htmlContent={formHtml.content} className="px-10" />
              </div>
              <ShadowContent htmlContent={formHtml.footer} contentId="footer" className={classNames(formHtml.addHeaderFooterMargins && "mx-10 my-4")} />
            </div>
          </div>
        }
        size="7xl"
        footer={
          <div className="flex flex-row items-center">
            <BhTextOnlyButton
              buttonProps={{
                onClick: () => {
                  setFormHtml({ content: "", footer: "", header: "" });
                  hidePdfPreviewModal();
                }
              }}
            >
              Sulge
            </BhTextOnlyButton>
          </div>
        }
      />
    ),
    [formHtml]
  );

  const openPreviewPdf = () => {
    if (hasNoPDF) return;

    if (formBaseId && formBaseVersionId) {
      fetchFormPdfPreviewHtml(companyId, formBaseId, formBaseVersionId).then((result) => {
        setFormHtml(result);
        showPdfPreviewModal();
      });
      return;
    }
    if (formType && Object.values(FormType).includes(formType as FormType)) {
      fetchFormTypePdfPreviewHtml(companyId, formType as FormType).then((result) => {
        // If the form is an old custom form, then add header and footer margins
        setFormHtml({ ...result, addHeaderFooterMargins: true });
        showPdfPreviewModal();
      });
      return;
    }
  };

  return (
    <div className={classNames(hasNoPDF && "pointer-events-none", "flex flex-row")} onClick={openPreviewPdf}>
      {children}
    </div>
  );
};

export default FormBaseHeaderPdfButton;
