import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PublicNavbar from "@/views/home/navbar/PublicNavbar";
import { useForm } from "react-hook-form";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { fetchPublicInvite, registerUser } from "@/api/inviteApi";
import { IInviteResponse } from "@/model/invites/IInvite";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { ButtonSize } from "@components/buttons/IBhButtonProps";
import BhLargeText from "@components/text/BhLargeText";
import BhInputLabel from "@components/input/BhInputLabel";
import { classNames, urlParamsToObject } from "@/utilities/jsUtilities";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import BhFullPageLoading from "@components/loading/BhFullPageLoading";
import { BhSectionMessageError } from "@components/sectionMessage/BhSectionMessages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons/faSpinnerThird";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { languageMap } from "@/views/home/profile/ProfileTabs/ProfileUserInfo";
import { useLocation } from "react-router-dom";

interface Props {
  inviteUuid: string;
}

const PublicInviteContainer: FC<Props> = ({ inviteUuid }) => {
  const { t } = useTranslation();
  const [inviteResponse, setInviteResponse] = useState<IInviteResponse>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [inviteLoading, setInviteLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>();
  const locationState = useLocation();
  const { lang } = urlParamsToObject(locationState.search);
  const [publicLanguage] = useLocalStorage<string>("publicLanguageCode", lang !== undefined ? lang : "et_EE");

  useEffect(() => {
    const controlledUuid = inviteUuid.match("[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}");
    const inviteUuidToFetch = controlledUuid ? controlledUuid[0] : inviteUuid;
    fetchPublicInvite(inviteUuidToFetch)
      .then((result) => {
        setInviteResponse(result);
      })
      .catch((error) => {
        setErrorMessage(error.name === "SyntaxError" ? (t("INVITE.URL_ERROR") as string) : error.message);
      })
      .finally(() => {
        setInviteLoading(false);
      });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (formData: any) => {
    if (!inviteResponse?.invite.username || !inviteResponse?.invite.uuid) return;

    setIsSaving(true);
    const userLanguage = languageMap[publicLanguage];

    const newUserDTO = { ...formData, username: inviteResponse?.invite.username, language: userLanguage?.value };
    registerUser(inviteResponse?.invite.uuid, newUserDTO)
      .then((response) => {
        const redirectUrl = ConfigSingleton.getInstance().getConfig().REACT_APP_CAS_CALLBACK_URL + "?ticket=" + response.value;
        // eslint-disable-next-line no-restricted-globals
        location.href = redirectUrl;
      })
      .catch(() => {
        setIsSaving(false);
      });
  };

  return (
    <div className="flex h-screen w-screen flex-col overflow-hidden">
      <div>
        <PublicNavbar withoutSignInButton={true} />
      </div>
      <div className="flex flex-row justify-center overflow-auto">
        {inviteLoading && <BhFullPageLoading />}
        {!inviteLoading && (
          <>
            {errorMessage && (
              <div className="flex max-w-[800px] flex-1 flex-col p-20">
                <BhSectionMessageError>
                  <div className="text-18px l-h-24px font-bold">{t(errorMessage)}</div>
                </BhSectionMessageError>
              </div>
            )}
            {inviteResponse && !errorMessage && (
              <div className="flex max-w-[600px] flex-1 flex-col p-20">
                <h2>{t("INVITE.HEADER")}</h2>
                <BhLargeText classes="pt-3 pb-6">
                  {t("INVITE.WELCOME")} <strong>{inviteResponse?.projectName}</strong>
                </BhLargeText>
                <form id="inviteForm" className="flex flex-col gap-y-4" onSubmit={handleSubmit(onSubmit)}>
                  <BhInputStackedLabel initialValue={inviteResponse?.invite.username} property={"username"} label={t("GLOBAL.EMAIL")} disabled={true} />
                  <div className="flex w-full flex-row gap-x-4">
                    <div className="flex-1">
                      <div className="flex flex-col">
                        <div className="flex flex-row">
                          <BhInputLabel>{t("INVITE.FIRST_NAME")}</BhInputLabel>
                        </div>
                        <div className="relative inline-block w-full">
                          <input
                            className={classNames(
                              errors?.firstName && "bh-border-error-red",
                              "bh-border-pigeon-40",
                              "l-h-20 placeholder:bh-text-deep-ocean-60 hover:bh-border-pigeon-70 focus:bh-border-pigeon-70 w-full rounded py-[7px] " +
                                "disabled:bh-text-deep-ocean-40 disabled:bh-bg-smoke disabled:bh-border-smoke"
                            )}
                            type="text"
                            autoComplete="off"
                            {...register("firstName", { required: true, maxLength: 80 })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="flex flex-col">
                        <div className="flex flex-row">
                          <BhInputLabel>{t("INVITE.LAST_NAME")}</BhInputLabel>
                        </div>
                        <div className="relative inline-block w-full">
                          <input
                            className={classNames(
                              errors?.lastName && "bh-border-error-red",
                              "bh-border-pigeon-40",
                              "l-h-20 placeholder:bh-text-deep-ocean-60 hover:bh-border-pigeon-70 focus:bh-border-pigeon-70 w-full rounded py-[7px] " +
                                "disabled:bh-text-deep-ocean-40 disabled:bh-bg-smoke disabled:bh-border-smoke"
                            )}
                            type="text"
                            autoComplete="off"
                            {...register("lastName", { required: true, maxLength: 80 })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <BhInputLabel>{t("INVITE.PASSWORD")}</BhInputLabel>
                    </div>
                    <div className="relative inline-block w-full">
                      <input
                        className={classNames(
                          "bh-border-pigeon-40",
                          "l-h-20 placeholder:bh-text-deep-ocean-60 hover:bh-border-pigeon-70 focus:bh-border-pigeon-70 w-full rounded py-[7px] " +
                            "disabled:bh-text-deep-ocean-40 disabled:bh-bg-smoke disabled:bh-border-smoke"
                        )}
                        type="password"
                        autoComplete="new-password"
                        {...register("password", { required: true, minLength: 8, pattern: /^(?=.*[A-Z\u00C0-\u00DC])(?=.*\d).*$/ })}
                      />
                    </div>
                    <div className={classNames(errors?.password?.type === "pattern" && "bh-text-error-red", "l-h-14px bh-text-deep-ocean-60 px-0.5 py-1.5 text-sm")}>
                      {t("INVITE.PASSWORD.PATTERN")}
                    </div>
                    {errors?.password?.type === "minLength" && <div className="bh-text-error-red l-h-14px bh-text-deep-ocean-60 px-0.5 py-1.5 text-sm">{t("INVITE.PASSWORD.MINLENGTH")}</div>}
                  </div>
                  <p>
                    <span className="pr-0.5">{t("INVITE.TERMS_AND_CONDITIONS")}</span>
                    <a href={languageMap[publicLanguage]?.terms} target="_blank" rel="noreferrer" className="lowercase underline">
                      {t("INVITE.TERMS_AND_CONDITIONS.LINK")}
                    </a>
                  </p>
                  <BhPrimaryButton buttonProps={{ size: ButtonSize.XL, classes: "w-full ml-0", submitForm: "inviteForm", disabled: isSaving }}>
                    {t("INVITE.REGISTER")}
                    {isSaving && <FontAwesomeIcon icon={faSpinnerThird} className="fa-spin ml-2" />}
                  </BhPrimaryButton>
                </form>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PublicInviteContainer;
