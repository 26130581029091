import { IWorkGroup } from "@/model/IWorkGroup";
import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { IPartyPrivilegeListItemDTO } from "@/model/party/IPartyPrivilegeListItemDTO";
import { IPartyPrivilegeChangeDTO } from "@/model/party/IPartyPrivilegeChangeDTO";
import { ITaskBoard } from "@/model/taskBoard/ITaskBoard";
import { IPartyUserTaskBoardChangeDTO } from "@/model/party/IPartyUserTaskBoardChangeDTO";
import { FileEntityType } from "@/model/files/IFileEntity";
import { IFileTreeDTO } from "@/model/files/IFileTreeDTO";
import { IWrapperDTO } from "@/model/utilities/IWrapperDTO";
import { EntityId } from "@reduxjs/toolkit";
import { IUserRemovalInfoResponse } from "@/model/invites/IUserRemovalInfoResponse";
import { IPartyDTO } from "@/model/party/IPartyDTO";

export function fetchProjectWorkGroups(projectId: EntityId): Promise<Array<IWorkGroup>> {
  return bauhubGet("/project/" + projectId + "/parties");
}

export function fetchWorkGroup(projectId: EntityId, workGroupId: EntityId): Promise<IWorkGroup> {
  return bauhubGet("/project/" + projectId + "/party/" + workGroupId);
}

export function fetchProjectWorkGroupPrivileges(workGroupId: EntityId): Promise<Array<IPartyPrivilegeListItemDTO>> {
  return bauhubGet("/v2/project/party/" + workGroupId + "/privileges");
}

export function saveWorkGroupUserAuthorityChange(authChange: IPartyPrivilegeChangeDTO): Promise<IPartyPrivilegeChangeDTO> {
  return bauhubPost("/v2/project/party/auth/change", authChange);
}

export function saveWorkGroupFileSelected(file: IFileTreeDTO, workGroupId: EntityId): Promise<IFileTreeDTO> {
  return bauhubPost("/v2/project/party/" + workGroupId + "/file/select", { id: file.id });
}

export function saveWorkGroupUserInvite(workGroupId: EntityId, dto: IPartyPrivilegeListItemDTO): Promise<IPartyPrivilegeListItemDTO> {
  return bauhubPost("/v2/project/party/" + workGroupId + "/auth/invite", dto);
}

export function saveRemoveUserFromWorkGroup(workGroupId: EntityId, dto: IPartyPrivilegeListItemDTO): Promise<IPartyPrivilegeListItemDTO> {
  return bauhubPost("/v2/project/party/" + workGroupId + "/auth/remove", dto);
}

export function saveAddWorkGroupToTaskBoard(dto: { taskBoardId: EntityId; workGroupId: EntityId }): Promise<ITaskBoard> {
  return bauhubPost("/v2/project/party/taskboard/add", dto);
}

export function saveRemoveWorkGroupFromTaskBoard(dto: { taskBoardId: EntityId; workGroupId: EntityId }): Promise<ITaskBoard> {
  return bauhubPost("/v2/project/party/taskboard/remove", dto);
}

export function saveWorkGroupFileDeSelected(file: IFileTreeDTO, workGroupId: EntityId): Promise<IFileTreeDTO> {
  return bauhubPost("/v2/project/party/" + workGroupId + "/file/deselect", { id: file.id });
}

export function saveRemoveUserFromTaskBoard(authChange: IPartyUserTaskBoardChangeDTO): Promise<IPartyUserTaskBoardChangeDTO> {
  return bauhubPost("/v2/project/party/taskboard/user/remove", authChange);
}

export function saveAddUserToTaskBoard(authChange: IPartyUserTaskBoardChangeDTO): Promise<IPartyUserTaskBoardChangeDTO> {
  return bauhubPost("/v2/project/party/taskboard/user/add", authChange);
}

export function saveAddAllUsersToTaskBoard(authChange: IPartyUserTaskBoardChangeDTO): Promise<IPartyUserTaskBoardChangeDTO> {
  return bauhubPost("/v2/project/party/taskboard/user/add/all", authChange);
}

export function saveRemoveAllUsersFromTaskBoard(authChange: IPartyUserTaskBoardChangeDTO): Promise<IPartyUserTaskBoardChangeDTO> {
  return bauhubPost("/v2/project/party/taskboard/user/remove/all", authChange);
}

export function fetchWorkGroupTaskBoards(workGroupId: EntityId): Promise<Array<ITaskBoard>> {
  return bauhubGet("/v2/project/party/" + workGroupId + "/taskboards");
}

export function fetchProjectWorkGroupSelectedFileTree(projectId: EntityId, workGroupId: EntityId, branch: FileEntityType, selectedFileIds: Array<number>): Promise<Array<IFileTreeDTO>> {
  return bauhubGet("/v2/project/" + projectId + "/party/" + workGroupId + "/file/tree/" + branch, { selectedFileIds: selectedFileIds.join(",") });
}

export function saveRenameWorkGroup(name: string, workGroupId: EntityId, uniqueClientId?: string): Promise<IWrapperDTO> {
  return bauhubPost("/v2/project/party/" + workGroupId + "/info/name", { name: name, uniqueClientId: uniqueClientId });
}

export function saveChangeWorkGroupType(type: string | null, workGroupId: EntityId): Promise<IWrapperDTO> {
  return bauhubPost("/v2/project/party/" + workGroupId + "/info/type", { value: type });
}

export function fetchUserRemovalInfo(projectId: EntityId, workGroupId: EntityId, userId: EntityId): Promise<IUserRemovalInfoResponse> {
  return bauhubGet("/project/" + projectId + "/workgroup/" + workGroupId + "/user/" + userId + "/invites/pending");
}

export function saveNewParty(dto: IPartyDTO): Promise<IWorkGroup> {
  return bauhubPost("/v2/project/party/new", dto);
}

export function deleteWorkGroup(projectId: EntityId, workGroupId: EntityId): Promise<IWorkGroup> {
  return bauhubPost("/project/" + projectId + "/workgroup/" + workGroupId + "/delete");
}

export function findWorkGroupIdByContractDirectoryFileEntityId(projectId: EntityId, contractDirectoryFileEntityId: EntityId): Promise<{ value: EntityId }> {
  return bauhubGet("/project/" + projectId + "/contract/" + contractDirectoryFileEntityId + "/workGroupId");
}
