import React, { FC } from "react";
import { CategoryDefaultCode, IProjectTaskCategory } from "@/model/taskBoard/IProjectTaskCategory";
import { useTranslation } from "react-i18next";

interface Props {
  category: IProjectTaskCategory;
}

const TaskCategoryTag: FC<Props> = ({ category }) => {
  const { t } = useTranslation();

  if (!category || !category.name) return null;

  const translateValue = Object.keys(CategoryDefaultCode).includes(category?.name.toUpperCase());

  return (
    <div className="inline-block flex h-4 w-fit flex-row items-center rounded-full px-1.5" style={{ backgroundColor: category?.color }}>
      <span className="text-12px bh-text-white font-bold">{translateValue ? t("TASK.CATEGORY." + category?.name.toUpperCase()) : category?.name}</span>
    </div>
  );
};

export default TaskCategoryTag;
