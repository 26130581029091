import React, { FC, useEffect } from "react";
import { useAppDispatch } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { fetchFormBaseVersionRowsForEditingAsync, removeAllFormBaseRows, setFormBaseRowsStatus } from "@/app/store/form/formBaseRowsSlice";
import { fetchCompanyFormBaseAsync, fetchFormBaseVersionsAsync, toggleFormBasePreview } from "@/app/store/form/formBasesSlice";
import FormBaseBody from "@components/form/base/body/FormBaseBody";
import FormBaseHeader from "@components/form/base/header/FormBaseHeader";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";

interface Props {
  companyId: EntityId;
  formBaseId: EntityId;
  formBaseVersionId: EntityId;
  projectId?: EntityId;
}

const FormBaseContainer: FC<Props> = ({ companyId, formBaseId, formBaseVersionId, projectId }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return function cleanup() {
      dispatch(removeAllFormBaseRows());
      dispatch(toggleFormBasePreview(false));
      dispatch(setFormBaseRowsStatus(BhStateStatusType.INITIAL));
    };
  });

  useEffect(() => {
    dispatch(fetchCompanyFormBaseAsync({ companyId: companyId, formBaseId: formBaseId })).then(() => {
      dispatch(fetchFormBaseVersionsAsync({ companyId: companyId, formBaseId: formBaseId })).then(() => {
        dispatch(fetchFormBaseVersionRowsForEditingAsync({ companyId: companyId, formBaseId: formBaseId, formBaseVersionId: formBaseVersionId }));
      });
    });
  }, [formBaseVersionId]);

  return (
    <div className="flex w-full flex-col">
      <FormBaseHeader formBaseId={formBaseId} formBaseVersionId={formBaseVersionId} companyId={companyId} projectId={projectId} />
      <FormBaseBody companyId={companyId} formBaseId={formBaseId} formBaseVersionId={formBaseVersionId} projectId={projectId} />
    </div>
  );
};

export default FormBaseContainer;
