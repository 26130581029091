import React, { FC, useEffect, useState } from "react";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import { IMaruProjectInfo } from "@/model/maru/IMaruProjectInfo";
import { fetchMaruProjectInfo } from "@/api/maru/maruAPI";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { WorkGroupType } from "@/model/IWorkGroup";
import MaruDashboardDeadlinesTable from "@/views/home/project/detail/maru/MaruDashboardDeadlinesTable";
import MaruDashboardEttemaksContainer from "@/views/home/project/detail/maru/MaruDashboardEttemaksContainer";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { useTranslation } from "react-i18next";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import { IMaruProjectContractInfo } from "@/model/maru/IMaruProjectContractInfo";
import MaruDashboardContractsTable from "@/views/home/project/detail/maru/MaruDashboardContractsTable";

const MaruDashboardContainer: FC = () => {
  useDocumentTitle("MR.DASHBOARD");

  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const [clientMaruProjectInfos, setClientMaruProjectInfos] = useState<Array<IMaruProjectInfo>>([]);
  const [subcontractorMaruProjectInfos, setSubcontractorMaruProjectInfos] = useState<Array<IMaruProjectInfo>>([]);
  const [clientMaruProjectContractInfos, setClientMaruProjectContractInfos] = useState<Array<IMaruProjectContractInfo>>([]);
  const [subcontractorMaruProjectContractInfos, setSubcontractorMaruProjectContractInfos] = useState<Array<IMaruProjectContractInfo>>([]);
  const [ettemaks, setEttemaks] = useState<number>(0);
  const [ettemaksuJaak, setEttemaksuJaak] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.MR_DASHBOARD));
    dispatch(setSidebarCollapsed(true));

    fetchMaruProjectInfo(projectId)
      .then((info) => {
        const clientMaruProjectInfo = info.projectInfos.filter((i) => i.type === WorkGroupType.CLIENT);
        const subcontractorMaruProjectInfo = info.projectInfos.filter((i) => i.type === WorkGroupType.SUBCONTRACTOR);
        const clientMaruProjectContractInfo = info.projectContractInfos.filter((i) => i.type === WorkGroupType.CLIENT);
        const subcontractorMaruProjectContractInfo = info.projectContractInfos.filter((i) => i.type === WorkGroupType.SUBCONTRACTOR);
        setClientMaruProjectInfos(clientMaruProjectInfo);
        setSubcontractorMaruProjectInfos(subcontractorMaruProjectInfo);
        setClientMaruProjectContractInfos(clientMaruProjectContractInfo);
        setSubcontractorMaruProjectContractInfos(subcontractorMaruProjectContractInfo);
        const totalEttemaksuJaak = clientMaruProjectInfo.reduce((sum, current) => sum + current.ettemaksuJaak, 0);
        setEttemaksuJaak(totalEttemaksuJaak);
        const totalEttemaks = clientMaruProjectInfo.reduce((sum, current) => sum + current.ettemaks, 0);
        setEttemaks(totalEttemaks - totalEttemaksuJaak);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="h-full w-full overflow-y-auto px-6 py-4">
      <DetailViewHeading>{t("MR.DASHBOARD")}</DetailViewHeading>
      <div className="flex w-full flex-col gap-y-2">
        <div className="flex w-full flex-row gap-x-20 overflow-x-auto">
          <div className="flex w-full flex-col gap-y-2">
            <MaruDashboardDeadlinesTable title={t("MR.DASHBOARD.TELLIJA_TAHTAJAD")} maruProjectInfos={clientMaruProjectInfos} isLoading={isLoading} />
            <MaruDashboardDeadlinesTable title={t("MR.DASHBOARD.ATV_TAHTAJAD")} maruProjectInfos={subcontractorMaruProjectInfos} isLoading={isLoading} />
          </div>
          <div className="min-w-385px w-full">{(ettemaks !== 0 || ettemaksuJaak !== 0) && <MaruDashboardEttemaksContainer ettemaks={ettemaks} ettemaksuJaak={ettemaksuJaak} />}</div>
        </div>
        <MaruDashboardContractsTable title={t("MR.DASHBOARD.CLIENT_CONTRACTS")} maruProjectContractInfos={clientMaruProjectContractInfos} type={WorkGroupType.CLIENT} isLoading={isLoading} />
        <MaruDashboardContractsTable
          title={t("MR.DASHBOARD.SUBCONTRACTOR_CONTRACTS")}
          maruProjectContractInfos={subcontractorMaruProjectContractInfos}
          type={WorkGroupType.SUBCONTRACTOR}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default MaruDashboardContainer;
