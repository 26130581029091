import React, { FC } from "react";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons";
import BhNewTag from "@components/tags/BhNewTag";

interface Props {
  tagText: string;
  onSelect: Function;
  dropdownPosition: BhDropdownPositionEnum;
  values?: Array<any>;
  textProperty?: string;
  translateValues?: boolean;
  disabled?: boolean;
  backgroundColorClass?: string;
  textColorClass?: string;
}

const BhNewTagWithDropdown: FC<Props> = ({ tagText, onSelect, values, dropdownPosition, textProperty, translateValues, disabled, backgroundColorClass, textColorClass }) => {
  return (
    <div>
      {!disabled && (
        <BhDropdown
          button={<BhNewTag tagText={tagText} icon={faAngleDown} textColorClass={textColorClass} backgroundColorClass={backgroundColorClass} />}
          menu={
            <BhDropdownMenu
              values={values}
              textProperty={textProperty}
              type={BhDropdownTypeEnum.STRING}
              onSelect={onSelect}
              widthClass={"w-fit whitespace-nowrap"}
              closeOnItemClick={true}
              translateValues={translateValues}
            />
          }
          position={dropdownPosition}
          disabled={disabled}
        />
      )}
      {disabled && <BhNewTag tagText={tagText} textColorClass={textColorClass} backgroundColorClass={backgroundColorClass} disabled={true} />}
    </div>
  );
};

export default BhNewTagWithDropdown;
