import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { setCurrentSidebarMenuItem } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { fetchShareboxAsync, fetchShareboxUrlsAsync, selectShareboxById, selectShareboxMessageModalOpen, selectShareboxShareModalOpen } from "@/app/store/shareboxesSlice";
import { EntityId } from "@reduxjs/toolkit";
import ShareboxFilesContainer from "@/views/home/project/detail/shareboxes/detail/ShareboxFilesContainer";
import ShareboxShareModal from "@/views/home/project/detail/shareboxes/modals/ShareboxShareModal";
import ShareboxMessageModal from "@/views/home/project/detail/shareboxes/modals/ShareboxMessageModal";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import ShareboxDetailViewHeader from "@/views/home/project/detail/shareboxes/detail/ShareboxDetailViewHeader";
import ShareboxDetailShareByEmailTable from "@/views/home/project/detail/shareboxes/detail/ShareboxDetailShareByEmailTable";
import ShareboxDetailValidTo from "@/views/home/project/detail/shareboxes/detail/ShareboxDetailValidTo";
import ShareboxDetailPublicLink from "@/views/home/project/detail/shareboxes/detail/ShareboxDetailPublicLink";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";

interface Props {
  shareboxId: EntityId;
}

const ShareboxContainer: FC<Props> = ({ shareboxId }) => {
  const dispatch = useAppDispatch();
  const sharebox = useAppSelector((state) => selectShareboxById(state, shareboxId));
  const shareModalOpen = useAppSelector(selectShareboxShareModalOpen);
  const messageModalOpen = useAppSelector(selectShareboxMessageModalOpen);

  useDocumentTitle(sharebox?.name);

  useEffect(() => {
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.SHAREBOXES));
    dispatch(fetchShareboxAsync(shareboxId));
  }, []);

  useEffect(() => {
    dispatch(fetchShareboxUrlsAsync(shareboxId));
  }, []);

  if (!sharebox) return null;

  return (
    <div className="w-full pb-7">
      <ShareboxDetailViewHeader shareboxId={shareboxId} />
      <BhScrollableBody>
        <div className="mx-6 mb-6 flex flex-1 flex-col p-6">
          <div className="space-y-8">
            <ShareboxDetailShareByEmailTable shareboxId={shareboxId} />
            <ShareboxDetailValidTo shareboxId={shareboxId} />
            <ShareboxDetailPublicLink shareboxId={shareboxId} />
            <ShareboxFilesContainer shareboxId={shareboxId} />
          </div>
        </div>
      </BhScrollableBody>
      {shareModalOpen && <ShareboxShareModal shareboxId={shareboxId} />}
      {messageModalOpen && <ShareboxMessageModal />}
    </div>
  );
};

export default ShareboxContainer;
