import React, { FC, ReactElement, useEffect, useLayoutEffect, useRef, useState } from "react";
import { scrollMemoryStore } from "@components/detailContainer/BhScrollableBody";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  currentDirId: EntityId;
  items: Array<ReactElement>;
  highlight?: string;
}

const DirectoryListScrollContainer: FC<Props> = ({ currentDirId, items, highlight }) => {
  const containerEl = useRef<HTMLDivElement>(null);
  const increments = 25;
  const [filterFirst, setFilterFirst] = useState(increments);

  const handleScroll = (event: any) => {
    const scrollNumber = event.currentTarget.offsetHeight + event.currentTarget.scrollTop;

    if (scrollNumber >= filterFirst * 56 - 200) {
      if (items.length > filterFirst) {
        if (scrollNumber < (filterFirst + increments) * 56 - 200) {
          setFilterFirst(filterFirst + increments);
        } else {
          setFilterFirst(Math.floor(scrollNumber / 56 + 25));
        }
      }
    }
  };

  useEffect(() => {
    if (highlight) {
      const highlightedItemIndex = items.findIndex((file) => file.key === highlight);
      if (highlightedItemIndex) {
        if (highlightedItemIndex > filterFirst) {
          setFilterFirst(highlightedItemIndex + 15);
        }
        if (containerEl.current) {
          containerEl.current.scrollTop = highlightedItemIndex * 56 - 2 * 56;
        }
      }
    }
  }, [highlight]);

  useLayoutEffect(() => {
    const lastScroll = scrollMemoryStore.get("fileDirectoryScroll");
    if (lastScroll && lastScroll.id === currentDirId && (lastScroll.scroll || lastScroll === 0) && containerEl.current) {
      containerEl.current.scrollTop = lastScroll.scroll;
    }

    // Kui ekraanile mahub algselt rohkem kui 25 failirida
    if (items.length > filterFirst) {
      if (window.visualViewport && window.visualViewport.height && window.visualViewport.height > filterFirst * 56) {
        const roomForElementsOnScreen = Math.floor(window.visualViewport.height / 56);
        setFilterFirst(roomForElementsOnScreen + 6);
      }
    }

    return () => {
      if (containerEl.current) {
        const currentScrollTop = containerEl.current?.scrollTop;
        scrollMemoryStore.set("fileDirectoryScroll", { id: currentDirId, scroll: currentScrollTop });
      }
    };
  }, []);

  const fullHeight = items.length * 56;

  return (
    <div ref={containerEl} className="h-full overflow-auto pb-32" onScroll={handleScroll}>
      <div className="w-full" style={{ height: fullHeight + "px" }}>
        {items.map((item) => item).slice(0, filterFirst)}
      </div>
    </div>
  );
};

export default DirectoryListScrollContainer;
