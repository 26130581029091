import React, { FC } from "react";
import BhSpreadsheetTableCell from "@components/spreadsheet/BhSpreadsheetTableCell";
import { Link } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import BhSpreadsheetTableTR from "@components/spreadsheet/BhSpreadsheetTableTR";
import { IMonthlyTrialOverview } from "@/model/trials/IMonthlyTrialOverview";
import { formatCurrency } from "@/utilities/accountingUtilities";

interface Props {
  trial: IMonthlyTrialOverview;
}

const BOTrialsSpreadsheetTableRow: FC<Props> = ({ trial }) => {
  const totalActiveCompanies = (trial.ongoingTrials || []).filter((t) => t.projectCount > 0).length;

  const formatSum = (sum: number) => {
    return formatCurrency(sum, { symbol: "€", thousandsSeparator: " ", decimalSeparator: ",", significantDigits: 2 }, true);
  };

  return (
    <BhSpreadsheetTableTR key={trial.yearMonth}>
      <BhSpreadsheetTableCell classes="p-3">
        <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + trial.yearMonth} className="underline-offset-4 hover:underline">
          {trial.yearMonth}
        </Link>
      </BhSpreadsheetTableCell>
      <BhSpreadsheetTableCell>{trial.noOfCompanies}</BhSpreadsheetTableCell>
      <BhSpreadsheetTableCell>{totalActiveCompanies}</BhSpreadsheetTableCell>
      <BhSpreadsheetTableCell classes="text-right">{formatSum(trial.estimatedTurnover)}</BhSpreadsheetTableCell>
      <BhSpreadsheetTableCell classes="text-right">{formatSum(trial.actualTurnover)}</BhSpreadsheetTableCell>
      <BhSpreadsheetTableCell classes="text-right">{formatSum(trial.turnoverTotal)}</BhSpreadsheetTableCell>
    </BhSpreadsheetTableTR>
  );
};

export default BOTrialsSpreadsheetTableRow;
