import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUserLanguage } from "@/app/store/userSlice";
import euLogo from "@img/NextGen_Rahastanud_EL_NextGeneration_EST_hor_color_RGB.png";
import euLogoEng from "@img/NextGen_Rahastanud_EL_NextGeneration_ENG_hor_color_RGB.png";

const FormBuilderEuFundingFooter: FC = () => {
  const { t } = useTranslation();
  const currentUserLanguage = useAppSelector(selectCurrentUserLanguage);
  const img = currentUserLanguage === "et_EE" ? euLogo : euLogoEng;

  return (
    <div className="flex flex-row items-center">
      <img src={img} className="mt-5 mr-4 w-40" alt={t("FORMS.EUROPE_RAHASTANUD") as string} />
      <span>{t("FORMS.EUROPE_RAHASTANUD")}</span>
    </div>
  );
};

export default FormBuilderEuFundingFooter;
