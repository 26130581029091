import React, { FC, ReactElement } from "react";
import { useAppSelector } from "@/app/hooks";
import { BauhubPlan } from "@/model/IProject";
import BhProPlanTag from "@components/tags/BhProPlanTag";
import BhLightPlanTag from "@components/tags/BhLightPlanTag";
import { EntityId } from "@reduxjs/toolkit";
import { selectCompanyById } from "@/app/store/companiesSlice";

interface Props {
  companyId: EntityId;
}

const BhCompanyPlanTag: FC<Props> = ({ companyId }) => {
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  
  const planTagMap: Record<BauhubPlan, ReactElement> = {
    [BauhubPlan.PRO]: <BhProPlanTag />,
    [BauhubPlan.LIGHT]: <BhLightPlanTag />
  };

  if (!company?.plan) return null;

  return planTagMap[company?.plan];
};

export default BhCompanyPlanTag;
