import React, { FC, useEffect, useRef } from "react";

interface Props {
  htmlContent: string;
  className?: string;
  contentId?: string;
}

const ShadowContent: FC<Props> = ({ htmlContent, className, contentId }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      // @ts-ignore
      const shadowRoot = containerRef.current.attachShadow({ mode: "open" });
      const wrapper = document.createElement("div");
      if (contentId) {
        wrapper.setAttribute("id", contentId); // Assign an ID using setAttribute
      }

      // Replace placeholders with the desired numbers
      // Add the modified HTML content inside the shadow DOM
      wrapper.innerHTML = htmlContent
        .replace(/<span class="pageNumber"><\/span>/g, '<span class="pageNumber">1</span>')
        .replace(/<span class="totalPages"><\/span>/g, '<span class="totalPages">1</span>');

      // Append the wrapper with the content to the shadow root
      shadowRoot.appendChild(wrapper);
    }
  }, [htmlContent]);

  return <div ref={containerRef} className={className}></div>;
};

export default ShadowContent;
