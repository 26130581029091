import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchProjectAsync, resetProjectState, selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { EntityId } from "@reduxjs/toolkit";
import Project from "@/views/home/project/Project";
import { useNavigate } from "react-router-dom";
import FullPageLogo from "@/views/global/FullPageLogo";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { resetSearchState } from "@/app/store/searchSlice";

interface Props {
  projectId: EntityId;
}

const ProjectAuth: FC<Props> = ({ projectId }) => {
  const currentProjectIdInStore = useAppSelector(selectCurrentProjectId);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetProjectState());
    dispatch(resetSearchState());
    dispatch(fetchProjectAsync(projectId)).then((result) => {
      if (result.meta.requestStatus === "rejected") {
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/`);
      }
    });
  }, [projectId]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetProjectState());
      dispatch(resetSearchState());
    };
  }, []);

  if (!projectId) {
    return null;
  }

  if (currentProjectIdInStore) {
    return <Project projectId={projectId} />;
  }

  return <FullPageLogo />;
};

export default ProjectAuth;
