import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import { IBillingModel, ProjectInvoiceType } from "@/model/IBillingInfo";
import { useTranslation } from "react-i18next";
import BhModalFooter from "@components/modal/BhModalFooter";
import { IProjectOrder } from "@/model/billing/IProjectOrder";
import { getProjectsOrderPrice } from "@/api/billingAPI";
import { textWithVariables } from "@/utilities/jsUtilities";
import parse from "html-react-parser";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { formatDate } from "@/utilities/dateUtility";

interface Props {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  projectOrder: IProjectOrder;
  callback: Function;
}

const ChangeOrderTypeConfirmationModal: FC<Props> = ({ isShown, setIsShown, projectOrder, callback }) => {
  const company = useAppSelector((state) => selectCompanyById(state, projectOrder.companyBillingRequisites?.companyId ?? 0));
  const [billingModel, setBillingModel] = useState<IBillingModel>();
  const { t } = useTranslation();

  useEffect(() => {
    if (isShown && company && company.id) {
      getProjectsOrderPrice(company.id, [projectOrder.projectBilling.projectId]).then((billingModel) => {
        setBillingModel(billingModel);
      });
    }
  }, [company, isShown]);

  const onSave = async () => {
    setIsShown(false);
    callback();
  };

  const onCancel = () => {
    setIsShown(false);
  };

  if (!company) {
    return null;
  }

  const newPrice = () => {
    return projectOrder.projectBilling.type === ProjectInvoiceType.YEAR ? billingModel?.monthlyPrice.toString() ?? "" : billingModel?.yearlyPrice.toString() ?? "";
  };

  return (
    <BhModal
      isShown={isShown}
      setIsShown={setIsShown}
      onClose={onCancel}
      header={<h2>{t("COMPANY.SETTINGS.CHANGE_TYPE_CONFIRMATION_MODAL.HEADER")}</h2>}
      children={
        <div className="px-10">
          <span>
            {billingModel && parse(
              textWithVariables(t("COMPANY.SETTINGS.CHANGE_TYPE_CONFIRMATION_MODAL.BODY." + projectOrder.projectBilling.type), {
                date: formatDate(new Date(billingModel.billingDate)),
                price: newPrice()
              })
            )}
          </span>
        </div>
      }
      footer={<BhModalFooter onCancel={onCancel} onConfirm={onSave} />}
    />
  );
};

export default ChangeOrderTypeConfirmationModal;
