import React, { FC, ReactNode } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  classes?: string;
  children: ReactNode;
}

const BhLargeBoldText: FC<Props> = ({ classes, children }) => {
  return <p className={classNames(classes, "text-18px l-h-24px bh-text-deep-ocean font-bold")}>{children}</p>;
};

export default BhLargeBoldText;
