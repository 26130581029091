import React, { FC } from "react";
import { Disclosure } from "@headlessui/react";
import { useAppSelector } from "@/app/hooks";
import SearchWithDropdown from "@/features/searchWithDropdown/SearchWithDropdown";
import { EntityId } from "@reduxjs/toolkit";
import { isThereUnseenNotifications } from "@/app/store/userNotificationsSlice";
import BhCurrentProjectPlanTag from "@components/tags/BhCurrentProjectPlanTag";
import { Link } from "react-router-dom";
import { selectCurrentProjectName } from "@/app/store/project/projectSlice";
import { selectIsSearchInputFocused } from "@/app/store/searchSlice";
import BhDropdown from "@components/dropdown/BhDropdown";
import UserNotificationsDropdown from "@/views/home/notifications/UserNotificationsDropdown";
import BhIconButton from "@components/buttons/BhIconButton";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import NavbarUserComponent from "@/views/home/navbar/NavbarUserComponent";
import { faBell } from "@fortawesome/pro-regular-svg-icons/faBell";
import BhSmallText from "@components/text/BhSmallText";
import { classNames } from "@/utilities/jsUtilities";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import BhBauhubLogo from "@components/logo/BhBauhubLogo";

interface Props {
  projectId: EntityId;
}

const NavbarProject: FC<Props> = ({ projectId }) => {
  const unseenNotifications = useAppSelector((state) => isThereUnseenNotifications(state, projectId));
  const projectName = useAppSelector(selectCurrentProjectName);
  const isSearchInputFocused = useAppSelector(selectIsSearchInputFocused);

  return (
    <Disclosure as="nav" className="bh-border-pigeon-20 z-30 flex h-14 w-full flex-row items-center justify-between border-b">
      <div className="flex w-44 shrink-0 flex-row items-center overflow-visible">
        <div className="flex w-full shrink-0 items-center overflow-visible pl-8 pr-2 sm:pr-0">
          <div className="mr-1 flex flex-shrink-0 items-center">
            <Link className="h-font" to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/`}>
              <BhBauhubLogo />
            </Link>
            <BhCurrentProjectPlanTag />
          </div>
        </div>
      </div>
      <div className="flex min-w-0 flex-1 flex-row items-center justify-end">
        <div className={classNames(isSearchInputFocused ? "shrink" : "flex-1", "min-w-48 flex-1 lg:hidden")}>
          <BhSmallText classes="truncate">{projectName}</BhSmallText>
        </div>
        <div className={classNames(isSearchInputFocused && "max-w-1200px w-full", "ml-2 flex flex-row items-center justify-end")}>
          <SearchWithDropdown projectId={projectId} />
        </div>
      </div>
      <div className="ml-2 flex flex-row items-center space-x-2 pr-8">
        <BhDropdown
          button={
            <div className="pointer-events-none relative inline-block">
              {unseenNotifications && <div className="absolute top-1 right-2">{<div className="bh-bg-bauhub-green bh-border-raised-white h-3.5 w-3.5 rounded-full border-2"></div>}</div>}
              <BhIconButton icon={faBell} />
            </div>
          }
          menu={<UserNotificationsDropdown />}
          position={BhDropdownPositionEnum.BOTTOM_LEFT}
        />
        <NavbarUserComponent />
      </div>
    </Disclosure>
  );
};

export default NavbarProject;
