import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { useAppSelector } from "@/app/hooks";
import { projectsSortSet, selectProjectsSort } from "@/app/store/companiesSlice";
import BhSortButton from "@components/sort/BhSortButton";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  classes?: string;
}

const CompanyPlanSettingsProjectOrdersSort: FC<Props> = ({ classes }) => {
  const { t } = useTranslation();
  const sort = useAppSelector(selectProjectsSort);

  const dropdownMenuValues = [
    { value: "name", translation: t("GLOBAL.ITEM_NAME") },
    { value: "created", translation: t("GLOBAL.CREATED") }
  ];

  return (
    <div className={classes}>
      <BhSortButton sort={sort} setSortForStore={projectsSortSet} sortValues={dropdownMenuValues} position={BhDropdownPositionEnum.BOTTOM_LEFT} />
    </div>
  );
};

export default CompanyPlanSettingsProjectOrdersSort;
