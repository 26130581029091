import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { deleteFileEntityCommentAsync, fetchFileEntityCommentsAsync, saveFileEntityCommentAsync, selectFileById } from "@/app/store/filesSlice";
import BhCommentCompact from "@components/comment/BhCommentCompact";
import { ICommentInput } from "@/views/home/project/detail/directory/fileInfoBar/fileInfoBarDetailViews/NewCommentInput";
import { IFileEntityComment } from "@/model/files/IFileEntityComment";
import { EntityId } from "@reduxjs/toolkit";
import noMatches from "@img/teadetetahvli-mullid-compressed.png";
import { Trans } from "react-i18next";
import { selectCurrentUserUsername, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import BhIconButton from "@components/buttons/BhIconButton";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import BhLightSeparator from "@components/separator/BhLightSeparator";
import { extractMentions } from "@/utilities/jsUtilities";
import NewFileCommentWithUsers from "@/views/home/project/detail/directory/fileInfoBar/fileInfoBarDetailViews/NewFileCommentWithUsers";

interface Props {
  fileEntityId: EntityId;
  closeButtonAction: Function;
}

const FileCommentsDetailView: FC<Props> = ({ fileEntityId, closeButtonAction }) => {
  const focusedFileEntity = useAppSelector((state) => selectFileById(state, fileEntityId));
  const isProjectAdmin = useAppSelector((state) => focusedFileEntity && selectIsCurrentUserProjectAdmin(state, focusedFileEntity.projectId));
  const currentUserUsername = useAppSelector(selectCurrentUserUsername);
  const dispatch = useAppDispatch();

  useEffect(() => {
    focusedFileEntity && dispatch(fetchFileEntityCommentsAsync(focusedFileEntity.id));
  }, [fileEntityId]);

  if (!focusedFileEntity) {
    return null;
  }

  const deleteComment = (comment: IFileEntityComment) => {
    dispatch(deleteFileEntityCommentAsync(comment));
  };

  const onNewCommentSubmit = (formValues: ICommentInput) => {
    const mentions = extractMentions(formValues.text, fileEntityId, focusedFileEntity.projectId, "FILE_COMMENT");
    const savableFileEntityCommentObject = { ...formValues, ...{ fileEntityId: fileEntityId, mentions: mentions } } as IFileEntityComment;
    return dispatch(saveFileEntityCommentAsync(savableFileEntityCommentObject));
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center px-2 pt-2">
        <div className="bh-text-deep-ocean-80 flex-1">
          <Trans>TASK.COMMENTS</Trans>
        </div>
        <div className="">
          <BhIconButton icon={faTimes} buttonProps={{ onClick: closeButtonAction }} />
        </div>
      </div>
      <div className="flex flex-col px-2 pt-2">
        <div>
          <BhFileCardIcon fileEntity={focusedFileEntity} fileCardSize={FileCardSize.LARGE} />
        </div>
        <p className="p-1 pb-2">
          <strong>{focusedFileEntity.name}</strong>
        </p>
      </div>
      <BhLightSeparator />
      <div className="flex flex-col">
        <NewFileCommentWithUsers fileEntityId={fileEntityId} projectId={focusedFileEntity.projectId} onSubmit={onNewCommentSubmit} />
        {focusedFileEntity.comments &&
          [...focusedFileEntity.comments]
            .sort((a, b) => (new Date(a.created) > new Date(b.created) ? -1 : 1))
            .map((comment) => {
              const canDeleteComment = isProjectAdmin || comment.createdBy === currentUserUsername;

              return (
                <div className="border-b" key={comment.id}>
                  <BhCommentCompact comment={comment} deleteCallback={canDeleteComment ? deleteComment : undefined} />
                </div>
              );
            })}
        {focusedFileEntity?.comments?.length < 1 && (
          <div className="flex w-full flex-col items-center justify-center">
            <img src={noMatches} className="m-8 mb-0 w-full max-w-[100px]" alt="No matches" />
            <p className="text-center">
              <Trans>MESSAGEBOARD.NO_MESSAGES</Trans>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileCommentsDetailView;
