import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import React from "react";

export interface IBhButtonProps {
  buttonProps?: {
    onClick?: Function;
    classes?: string;
    disabled?: boolean;
    submitForm?: string;
    size?: ButtonSize;
    inverted?: boolean;
    style?: ButtonStyle;
    widthClasses?: string;
    textClasses?: string;
    buttonRef?: React.RefObject<HTMLButtonElement>;
  };
  children?: React.ReactNode;
}

export interface IBhButtonWithIconProps extends IBhButtonProps {
  icon?: IconProp;
  iconSize?: SizeProp;
  iconClasses?: string;
  right?: boolean;
  spin?: boolean;
}

export interface IBhUploadButton extends IBhButtonProps {
  onFileSelected: Function;
  icon?: IconProp;
}

export enum ButtonSize {
  XL = "xl"
}

export enum ButtonStyle {
  PRIMARY = " py-1.5 bh-bg-dark-jungle hover:bh-border-deep-ocean active:bh-bg-deep-ocean-80 disabled:bh-bg-pigeon-20 disabled:bh-text-pigeon-60 bh-text-white min-w-70px ",
  SECONDARY = " py-1.5 bh-bg-mint hover:bh-bg-mint-120b active:bh-bg-mint-130b disabled:bh-bg-pigeon-20 disabled:bh-text-pigeon-60 bh-text-deep-ocean min-w-70px ",
  TEXTONLY = " py-1.5 min-w-70px text-uppercase extra-letter-spacing hover:bh-text-dark-jungle hover:bh-bg-pigeon-20 active:bh-bg-pigeon-40 disabled:bh-text-pigeon bh-text-deep-ocean-80 ",
  INVERTED = " py-1.5 min-w-70px text-uppercase extra-letter-spacing bh-text-mint hover:bh-bg-deep-ocean-80 active:bh-bg-deep-ocean-60 disabled:bh-text-pigeon-60 "
}
