import React, { FC } from "react";
import BhTableHeader from "@components/table/BhTableHeader";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boSelectSort, boSetSort } from "@/app/store/backofficeSlice";
import BOSalesInvoiceRow from "@components/backoffice/invoices/BOSalesInvoiceRow";
import { IMonthlyInvoiceOverviewRow } from "@/model/invoices/IMonthlyInvoiceOverviewRow";
import { IBoInvoiceRowSort } from "@/model/backoffice/IBoSort";

interface Props {
  invoiceRows: Array<IMonthlyInvoiceOverviewRow>;
}

const BOInvoicesTable: FC<Props> = ({ invoiceRows }) => {
  const sort = useAppSelector(boSelectSort);
  const dispatch = useAppDispatch();

  const tableColumnHeaders: Array<IBhTableHeader<IBoInvoiceRowSort>> = [
    { id: 0, content: "Company", sortable: true, field: "companyName" },
    { id: 1, content: "Plan", sortable: true, field: "companyPlan" },
    { id: 2, content: "Client", sortable: true, field: "customerRecipient" },
    { id: 3, content: "Date", classes: "text-center", sortable: true, field: "invoiceDate" },
    { id: 4, content: "Number", sortable: true, field: "fullInvoiceNumber" },
    { id: 5, content: "Sum", classes: "text-right", sortable: true, field: "sumWithoutVat" },
    { id: 6, content: "Sum + VAT", sortable: true, field: "sumWithVat" },
    { id: 7, content: "Deadline", sortable: true, field: "billingDueDate" },
    { id: 8, content: "Order", sortable: true, field: "type" },
    { id: 9, content: "Projects", sortable: true, field: "projectsOnInvoice" },
    { id: 10, content: "Avg project price" },
    { id: 11, content: "Paid", sortable: true, field: "paid" },
    { id: 12, content: "Type", sortable: true, field: "eInvoice" },
    { id: 13, content: "" }
  ];

  const onTableHeaderClickCallback = (column: IBhTableHeader<IBoInvoiceRowSort>) => {
    if (column.field) {
      const invoicesSort = { ...sort.invoicesSort };
      const updatedInvoicesSort = { ...invoicesSort, property: column.field, reversed: column.field === invoicesSort.property ? !invoicesSort.reversed : invoicesSort.reversed };
      const newSort = { ...sort, invoicesSort: updatedInvoicesSort };
      dispatch(boSetSort(newSort));
    }
  };

  return (
    <div className="overflow-auto">
      <table className="w-full">
        <BhTableHeader columns={tableColumnHeaders} onClickCallback={onTableHeaderClickCallback} reversed={sort.invoicesSort.reversed} sortedBy={sort.invoicesSort.property} />
        <tbody>
          {invoiceRows?.map((invoiceRow) => (
            <BOSalesInvoiceRow invoiceRow={invoiceRow} key={invoiceRow.salesInvoice?.id} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BOInvoicesTable;
