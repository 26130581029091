import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { IBhButtonWithIconProps } from "@components/buttons/IBhButtonProps";
import BhButtonTemplateWithIcon from "@components/buttons/BhButtonTemplateWithIcon";

interface Props extends IBhButtonWithIconProps {
  isActive?: boolean;
  withBackground?: boolean;
  isDark?: boolean;
  small?: boolean;
  sizeClasses?: string;
}

// FIGMA https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H/Bauhub-design-system?node-id=2080%3A112375
const BhIconButton: FC<Props> = (props) => {
  const { buttonProps, isActive, withBackground, isDark, children, small, sizeClasses } = props;

  let newButtonProps = buttonProps ?? {};
  const size = small ? "h-6 w-6" : "h-8 w-8";
  const sizeClassesFinal = sizeClasses || size;

  const hoverBg = withBackground ? (isDark ? "hover:bh-bg-pigeon-70" : "hover:bh-bg-mint-120b") : isActive ? "hover:bh-bg-deep-ocean" : "hover:bh-bg-pigeon-20";

  newButtonProps.classes += classNames(
    isActive ? " bh-bg-dark-jungle bh-text-white" : " bh-text-deep-ocean",
    withBackground ? (isDark ? "bh-bg-pigeon-50 active:bh-bg-pigeon" : "bh-bg-mint active:bh-bg-mint-130b disabled:bh-bg-pigeon-20") : " active:bh-bg-pigeon-40 disabled:hover:bh-bg-white",
    sizeClassesFinal,
    hoverBg,
    "disabled:bh-text-pigeon-60 rounded"
  );

  const newProps = { ...props, buttonProps: newButtonProps };

  return <BhButtonTemplateWithIcon {...newProps}>{children}</BhButtonTemplateWithIcon>;
};

export default BhIconButton;
