import React, { FC } from "react";
import DetailViewContainer from "@/views/home/project/detail/detailViewContainer/DetailViewContainer";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import ProjectSettings from "@/views/home/project/detail/projectSettings/ProjectSettings";
import RequireProjectAdminAuth from "@/views/authentication/RequireProjectAdminAuth";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";

const ProjectSettingsContainer: FC = () => {
  useDocumentTitle("PROJECT.SIDEBAR.SETTINGS");

  const { t } = useTranslation();
  return (
    <RequireProjectAdminAuth>
      <DetailViewContainer header={<DetailViewHeading>{t("PROJECT.SIDEBAR.SETTINGS")}</DetailViewHeading>}>
        <ProjectSettings />
      </DetailViewContainer>
    </RequireProjectAdminAuth>
  );
};

export default ProjectSettingsContainer;
