import React, { FC, useCallback, useRef, useState } from "react";
import { Menu } from "@headlessui/react";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  button?: React.ReactElement;
  menu?: React.ReactNode;
  position?: BhDropdownPositionEnum;
  buttonClasses?: string;
  menuClasses?: string;
  disabled?: boolean;
  fixedPosition?: boolean;
}

const BhDropdown: FC<Props> = ({ button, menu, position, buttonClasses, menuClasses, disabled, fixedPosition }) => {
  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  if (!button) return null;

  const getFixedPosition = () => {
    if (!fixedPosition) return undefined;

    const modal = document.getElementsByClassName("bauhub-modal");
    const button = buttonRef?.current;

    const modalLeft = modal[0]?.getBoundingClientRect().left || 0;
    const modalTop = modal[0]?.getBoundingClientRect().top || 0;
    const buttonLeft = button?.getBoundingClientRect().left || 0;
    const buttonTop = button?.getBoundingClientRect().top || 0;

    const dropdownLeft = buttonLeft - modalLeft;
    const dropdownTop = buttonTop - modalTop;

    return { left: dropdownLeft, top: dropdownTop };
  };

  const positionClassesMap: Record<BhDropdownPositionEnum, string> = {
    [BhDropdownPositionEnum.BOTTOM_LEFT]: "right-1",
    [BhDropdownPositionEnum.BOTTOM_RIGHT]: "left-1",
    [BhDropdownPositionEnum.TOP_LEFT]: "-top-2 -translate-y-full right-1",
    [BhDropdownPositionEnum.TOP_RIGHT]: "-top-2 -translate-y-full left-1"
  };

  return (
    <Menu as="div" className="relative" onClick={(e: any) => e.stopPropagation()}>
      <Menu.Button
        className={classNames(buttonClasses)}
        as="div"
        // @ts-ignore
        ref={buttonRef}
        onMouseDown={(e: any) => {
          // calculate dropdown position if necessary
          forceUpdate();
        }}
      >
        {({ open }) => button}
      </Menu.Button>
      {!disabled && (
        <Menu.Items
          className={classNames(
            fixedPosition ? "fixed" : "absolute",
            "z-30 mt-1 origin-top-right focus-visible:outline-0",
            menuClasses,
            position ? positionClassesMap[position] : positionClassesMap.BOTTOM_RIGHT
          )}
          style={getFixedPosition()}
        >
          {menu}
        </Menu.Items>
      )}
    </Menu>
  );
};

export default BhDropdown;
