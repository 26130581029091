import React, { FC, useState } from "react";
import BhCompactTable from "@components/table/compactTable/BhCompactTable";
import BhCompactTableHead from "@components/table/compactTable/BhCompactTableHead";
import BhCompactTableHeaderRow from "@components/table/compactTable/BhCompactTableHeaderRow";
import BhCompactTableTH from "@components/table/compactTable/BhCompactTableTH";
import BhCompactTableBody from "@components/table/compactTable/BhCompactTableBody";
import BhCompactTableTD from "@components/table/compactTable/BhCompactTableTD";
import BhCompactTableTR from "@components/table/compactTable/BhCompactTableTR";
import { useTranslation } from "react-i18next";
import { IMaruProjectContractInfo } from "@/model/maru/IMaruProjectContractInfo";
import MaruDashboardTableRowLoading from "@/views/home/project/detail/maru/MaruDashboardTableRowLoading";
import { WorkGroupType } from "@/model/IWorkGroup";
import { formatCurrency } from "@/utilities/accountingUtilities";
import { formatDateFromString } from "@/utilities/dateUtility";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { Link } from "react-router-dom";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import BhInputLabel from "@components/input/BhInputLabel";
import BhLink from "@components/buttons/BhLink";

interface MaruDashboardContractsTableProps {
  title: string;
  maruProjectContractInfos: Array<IMaruProjectContractInfo>;
  type: string;
  isLoading: boolean;
}

const MaruDashboardContractsTable: FC<MaruDashboardContractsTableProps> = ({ title, maruProjectContractInfos, type, isLoading }) => {
  const { t } = useTranslation();
  const [guaranteeVisible, setGuaranteeVisible] = useState(false);
  const isClientTable = type === WorkGroupType.CLIENT;
  const isSubcontractorTable = type === WorkGroupType.SUBCONTRACTOR;

  return (
    <div>
      <div className="mb-1 flex flex-row justify-between">
        <h3>{title}</h3>
        <div className="flex flex-row items-center gap-x-1.5">
          <BhInputLabel>{t("MR.DASHBOARD.GUARANTEE")}</BhInputLabel>
          <BhToggleSwitch value={guaranteeVisible} onClickAction={() => setGuaranteeVisible(!guaranteeVisible)} small={true} />
        </div>
      </div>
      <div className="overflow-auto">
        <BhCompactTable>
          <BhCompactTableHead>
            <BhCompactTableHeaderRow>
              <BhCompactTableTH classes={"min-w-48 w-48"} rowSpan={2}>
                {t("MR.DASHBOARD.CONTRACT")}
              </BhCompactTableTH>
              <BhCompactTableTH classes={"min-w-34 w-34"} rowSpan={2}>
                {t("MR.DASHBOARD.CONTRACT_COST")}
              </BhCompactTableTH>
              <BhCompactTableTH classes={"min-w-34 w-34"} rowSpan={2}>
                {t("MR.DASHBOARD.CERTIFIED_EARLIED")}
              </BhCompactTableTH>
              <BhCompactTableTH classes={"min-w-34 w-34"} rowSpan={2}>
                {t("MR.DASHBOARD.CERTIFIED")}
              </BhCompactTableTH>
              <BhCompactTableTH classes={"min-w-34 w-34"} rowSpan={2}>
                {t("MR.DASHBOARD.CERTIFIED_TOTAL")}
              </BhCompactTableTH>
              <BhCompactTableTH classes={"min-w-24 w-24"} rowSpan={2}>
                {t("MR.DASHBOARD.CERTIFIED_RESIDUAL")}
              </BhCompactTableTH>
              {guaranteeVisible && (
                <BhCompactTableTH colSpan={6} classes={"text-center"}>
                  {t("MR.DASHBOARD.GUARANTEE")}
                </BhCompactTableTH>
              )}
              {isClientTable && (
                <>
                  <BhCompactTableTH classes={"min-w-34 w-34"} rowSpan={2}>
                    {t("MR.DASHBOARD.TAITMISTAGATISE_SUMMA")}
                  </BhCompactTableTH>
                  <BhCompactTableTH classes={"min-w-34 w-34"} rowSpan={2}>
                    {t("MR.DASHBOARD.TAITMISTAGATISE_LOPP")}
                  </BhCompactTableTH>
                  <BhCompactTableTH classes={"min-w-34 w-34"} rowSpan={2}>
                    {t("MR.DASHBOARD.ETTEMAKSUTAGATISE_SUMMA")}
                  </BhCompactTableTH>
                  <BhCompactTableTH classes={"min-w-34 w-34"} rowSpan={2}>
                    {t("MR.DASHBOARD.ETTEMAKSUTAGATISE_LOPP")}
                  </BhCompactTableTH>
                </>
              )}
              <BhCompactTableTH classes={"min-w-34"} rowSpan={2}>
                {t("MR.DASHBOARD.CONTRACT_FILES")}
              </BhCompactTableTH>
            </BhCompactTableHeaderRow>
            <BhCompactTableHeaderRow>
              {guaranteeVisible && (
                <>
                  <BhCompactTableTH classes={"min-w-24 w-24"}>{t("MR.DASHBOARD.GUARANTEE_PERCENTAGE")}</BhCompactTableTH>
                  <BhCompactTableTH classes={"min-w-34 w-34"}>{t("MR.DASHBOARD.GUARANTEE_TOTAL")}</BhCompactTableTH>
                  <BhCompactTableTH classes={"min-w-34 w-34"}>{t("MR.DASHBOARD.GUARANTEE_START")}</BhCompactTableTH>
                  <BhCompactTableTH classes={"min-w-34 w-34"}>{t("MR.DASHBOARD.GUARANTEE_END")}</BhCompactTableTH>
                  <BhCompactTableTH classes={"min-w-34 w-34"}>{t("MR.DASHBOARD.GUARANTEE_TYPE")}</BhCompactTableTH>
                  <BhCompactTableTH classes={"min-w-34 w-34"}>{t("MR.DASHBOARD.GUARANTEE_LETTER")}</BhCompactTableTH>
                </>
              )}
            </BhCompactTableHeaderRow>
          </BhCompactTableHead>
          <BhCompactTableBody>
            {!isLoading && (
              <>
                {maruProjectContractInfos.length < 1 && (
                  <BhCompactTableTR>
                    <BhCompactTableTD colSpan={isClientTable ? 11 : 7} classes="text-center">
                      {t("MR.DASHBOARD_EMPTY")}
                    </BhCompactTableTD>
                  </BhCompactTableTR>
                )}
                {maruProjectContractInfos.map((info, index) => {
                  return (
                    <BhCompactTableTR key={info.id} classes={!info.parentMaruProjectContractInfoId ? "bh-bg-pigeon-40 font-bold" : ""}>
                      <BhCompactTableTD>
                        {info.parentMaruProjectContractInfoId ? (
                          <div>{info.name}</div>
                        ) : (
                          <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${info.projectId}/contract/${info.workGroupId}`}>
                            <BhLink>{info.name}</BhLink>
                          </Link>
                        )}
                      </BhCompactTableTD>
                      <BhCompactTableTD>{formatCurrency(info.maksumus)}</BhCompactTableTD>
                      <BhCompactTableTD>{formatCurrency(info.varemAkteeritud)}</BhCompactTableTD>
                      <BhCompactTableTD>{formatCurrency(info.perioodilAkteeritud)}</BhCompactTableTD>
                      <BhCompactTableTD>{formatCurrency(info.kokkuAkteeritud)}</BhCompactTableTD>
                      <BhCompactTableTD>{info.jaak}</BhCompactTableTD>
                      {guaranteeVisible && (
                        <>
                          <BhCompactTableTD>{info.garantiiProtsent}</BhCompactTableTD>
                          <BhCompactTableTD>{formatCurrency(info.garantiiajaTagatis)}</BhCompactTableTD>
                          <BhCompactTableTD>{formatDateFromString(info.garantiiperioodiAlgus)}</BhCompactTableTD>
                          <BhCompactTableTD>{formatDateFromString(info.garantiiperioodiLopp)}</BhCompactTableTD>
                          <BhCompactTableTD>{info.garantiiajaTagatisLiik}</BhCompactTableTD>
                          <BhCompactTableTD>{info.garantiikirjaNumber}</BhCompactTableTD>
                        </>
                      )}
                      {isClientTable && (
                        <>
                          <BhCompactTableTD>{formatCurrency(info.taitmistagatiseSumma)}</BhCompactTableTD>
                          <BhCompactTableTD>{formatDateFromString(info.taitmistagatiseKehtivuseLopp)}</BhCompactTableTD>
                          <BhCompactTableTD>{formatCurrency(info.ettemaksutagatiseSumma)}</BhCompactTableTD>
                          <BhCompactTableTD>{formatDateFromString(info.ettemaksutagatiseKehtivuseLopp)}</BhCompactTableTD>
                        </>
                      )}
                      <BhCompactTableTD>
                        <div className="flex flex-row whitespace-nowrap">
                          {info.relatedFiles &&
                            info.relatedFiles.map((file, index) => (
                              <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${info.projectId}/contract/${info.workGroupId}`} key={index}>
                                <div className="text-14px bh-text-deep-ocean mr-1 font-semibold leading-5 hover:underline">{file.name + (info.relatedFiles[index + 1] ? ", " : "")}</div>
                              </Link>
                            ))}
                        </div>
                      </BhCompactTableTD>
                    </BhCompactTableTR>
                  );
                })}
              </>
            )}
            {isLoading && <MaruDashboardTableRowLoading tdCount={isClientTable ? 11 : 7} />}
          </BhCompactTableBody>
        </BhCompactTable>
      </div>
    </div>
  );
};
export default MaruDashboardContractsTable;
