import React, { FC, useCallback, useEffect } from "react";
import { IPartyPrivilegeListItemDTO } from "@/model/party/IPartyPrivilegeListItemDTO";
import BhDropdownButton from "@components/dropdown/BhDropdownButton";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { SubResource } from "@/model/IUserAuthority";
import { IWorkGroup } from "@/model/IWorkGroup";
import { useTranslation } from "react-i18next";

interface Props {
  workGroup: IWorkGroup;
  listItem: IPartyPrivilegeListItemDTO;
  subResource: SubResource;
  fieldName: keyof IPartyPrivilegeListItemDTO;
  callback?: Function;
}

const PartyUserListItemDropdown: FC<Props> = ({ workGroup, listItem, subResource, fieldName, callback }) => {
  const { t } = useTranslation();

  const dropdownMenuValues = [
    { value: "NONE", translation: "PARTY.NONE" },
    { value: "READ", translation: "PARTY.READ" },
    { value: "WRITE", translation: "PARTY.WRITE" },
    { value: "ADMIN", translation: "PARTY.ADMIN" }
  ];

  const getTranslation = useCallback(
    (authValue: string) => {
      const translation = dropdownMenuValues.find((val) => val.value === authValue);
      return translation ? translation.translation : "Puudub";
    },
    [listItem]
  );

  // modal - callback ja not -1 aga ära mine serverisse
  // edit - callback ja not -1 ja mine serverisse
  // new - callback ja -1 ära mine serverisse
  const onUserPrivilegeChange = useCallback((authLevelDropdownValue: any) => {
    if (callback) {
      //{ [fieldName]: authLevelDropdownValue.value }
      callback(authLevelDropdownValue, subResource, fieldName);
    }
  }, []);

  useEffect(() => {}, []);

  return (
    <div>
      <BhDropdown
        fixedPosition={true}
        button={<BhDropdownButton reversed={true} title="" value={t("PARTY." + listItem[fieldName]) as string} />}
        menu={<BhDropdownMenu values={dropdownMenuValues} textProperty="translation" type={BhDropdownTypeEnum.STRING} onSelect={onUserPrivilegeChange} translateValues={true} />}
      />
    </div>
  );
};

export default PartyUserListItemDropdown;
