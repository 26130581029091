import { IFileEntity } from "@/model/files/IFileEntity";
import { EntityId } from "@reduxjs/toolkit";

export interface IDirectoryModals {
  open: IDirectoryModalsOpen;
  renameModalFileEntity?: IFileEntity;
  deleteConfirmationModalFileEntityIds?: Array<EntityId>;
  undoRevisionModalFileEntity?: IFileEntity;
  authoritiesModalFileEntity?: EntityId;
}

export interface IDirectoryModalsOpen {
  newFolderModal: boolean;
  renameModal: boolean;
  deleteConfirmationModal: boolean;
  copyModal: boolean;
  moveModal: boolean;
  undoRevisionModal: boolean;
  fileHistoryModal: boolean;
  authoritiesModal: boolean;
}

export const directoryModalsInitialState = {
  open: {
    newFolderModal: false,
    renameModal: false,
    deleteConfirmationModal: false,
    copyModal: false,
    moveModal: false,
    undoRevisionModal: false,
    fileHistoryModal: false,
    authoritiesModal: false
  }
};
