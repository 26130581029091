import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  checkpointValue: any;
}

const ChecklistRoundTomPercentage: FC<Props> = ({ checkpointValue }) => {
  const tomPercentage = (checkpointValue.tomOk / (checkpointValue.tomOk + checkpointValue.tomNotOk)) * 100;
  const tomPercentageBackgroundClass =
    tomPercentage >= 95
      ? "bh-bg-success-green-30"
      : tomPercentage >= 90
      ? "bh-bg-success-green-20"
      : tomPercentage >= 85
      ? "bh-bg-warning-yellow-20"
      : tomPercentage < 85
      ? "bh-bg-error-red-20"
      : "bh-bg-smoke";

  return (
    <div className="flex h-full w-full flex-row justify-center">
      <div className={classNames(tomPercentageBackgroundClass, "flex h-full w-11 flex-col justify-center")}>
        <div className="bh-text-deep-ocean text-14px flex flex-row justify-center font-bold leading-5">{tomPercentage ? tomPercentage.toFixed(tomPercentage === 100 ? 0 : 1) : "-"}</div>
      </div>
    </div>
  );
};

export default ChecklistRoundTomPercentage;
