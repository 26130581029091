import React, { FC } from "react";
import { findProtocolNextMainRowNumber, findProtocolNextSubRowNumber } from "@/utilities/formUtilities";
import { useAppSelector } from "@/app/hooks";
import { selectIndexForNextProtocolMainRowByCurrentRowId, selectIndexForNextProtocolSubRowByCurrentRowId } from "@/app/store/form/formSlice";

interface Props {
  disabled?: boolean;
  saveNewRowCallback: Function;
  isCurrentRowSubRow: boolean;
  row: any;
  isFormBase?: boolean;
  agendaFieldProperty?: string;
}

const FormBaseProtocolFieldTablePlaceholderRow: FC<Props> = ({ disabled, isFormBase, row, isCurrentRowSubRow, saveNewRowCallback, agendaFieldProperty }) => {
  const nextMainRowIndex = useAppSelector((state) => (!isFormBase && agendaFieldProperty ? selectIndexForNextProtocolMainRowByCurrentRowId(state, row._id, agendaFieldProperty) : 0));
  const nextSubRowIndex = useAppSelector((state) => (!isFormBase && agendaFieldProperty ? selectIndexForNextProtocolSubRowByCurrentRowId(state, row._id, agendaFieldProperty) : 0));
  const mainRowPlaceholderNumber = findProtocolNextMainRowNumber(row.nr);
  const subRowPlaceholderNumber = findProtocolNextSubRowNumber(row.nr);

  const saveNewRow = (isSubRow: boolean) => {
    if (disabled) return;
    const newRowNumber = isSubRow ? subRowPlaceholderNumber : mainRowPlaceholderNumber;
    const newRowToSave = { level: isSubRow ? 1 : 0, nr: newRowNumber, content: "" };
    const newRowIndex = isSubRow ? nextSubRowIndex : nextMainRowIndex;
    saveNewRowCallback(newRowIndex, newRowToSave);
  };

  return (
    <div className="flex flex-col gap-y-1">
      {isCurrentRowSubRow && (
        <div className="flex flex-row items-center gap-x-1">
          <div className="min-w-14 w-14"></div>
          <div
            className="min-w-16 max-w-16 bh-border-pigeon-50 bh-text-deep-ocean-40 hover:bh-border-pigeon-70 ml-4 flex h-9 w-16 flex-col justify-center rounded border border-dashed px-3 align-middle hover:cursor-pointer hover:border-solid"
            onClick={() => saveNewRow(true)}
          >
            {subRowPlaceholderNumber}
          </div>
          <div className="bh-border-pigeon-50 hover:bh-border-pigeon-70 h-9 w-full rounded border border-dashed hover:cursor-pointer hover:border-solid" onClick={() => saveNewRow(true)}></div>
          <div className="min-w-56 w-56"></div>
          <div className="min-w-26 max-w-26 w-26"></div>
          <div className="min-w-24 w-24"></div>
          <div className="min-w-24 ml-11 w-24"></div>
        </div>
      )}
      <div className="flex flex-row items-center gap-x-1">
        <div className="min-w-14 w-14"></div>
        <div
          className="min-w-20 max-w-20 bh-border-pigeon-50 bh-text-deep-ocean-40 hover:bh-border-pigeon-70 flex h-9 w-20 flex-col justify-center rounded border border-dashed px-3 align-middle hover:cursor-pointer hover:border-solid"
          onClick={() => saveNewRow(false)}
        >
          {mainRowPlaceholderNumber}
        </div>
        <div className="bh-border-pigeon-50 hover:bh-border-pigeon-70 h-9 w-full rounded border border-dashed hover:cursor-pointer hover:border-solid" onClick={() => saveNewRow(false)}></div>
        <div className="min-w-56 w-56"></div>
        <div className="min-w-26 max-w-26 w-26"></div>
        <div className="min-w-24 w-24"></div>
        <div className="min-w-24 ml-11 w-24"></div>
      </div>
    </div>
  );
};

export default FormBaseProtocolFieldTablePlaceholderRow;
