import React, { FC, useEffect, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import ConfirmationContainerTableHeader from "@/views/home/project/detail/confirmations/confirmation/tableHeader/ConfirmationContainerTableHeader";
import ConfirmationFileContainer from "@/views/home/project/detail/confirmations/confirmation/confirmationFileContainer/ConfirmationFileContainer";
import { useAppSelector } from "@/app/hooks";
import { selectFileConfirmationById } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import {
  selectConfirmedFileConfirmationFilesForConfirmation,
  selectFileConfirmationFileParentEntityNameAndIdsSorted,
  selectFileConfirmationFilesSort
} from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import ConfirmationFolderPath from "@/views/home/project/detail/confirmations/confirmation/confirmationFileContainer/ConfirmationFolderPath";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { Trans } from "react-i18next";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import { IFileConfirmationFile } from "@/model/confirmations/IFileConfirmationFile";

interface Props {
  confirmationId: EntityId;
  canEditConfirmation: boolean;
  sortFilesInParentFolders: Function;
}

const ConfirmationContainerConfirmedContainer: FC<Props> = ({ confirmationId, canEditConfirmation, sortFilesInParentFolders }) => {
  const fileConfirmation = useAppSelector((state) => selectFileConfirmationById(state, confirmationId));
  const fileConfirmationFiles = useAppSelector((state) => selectConfirmedFileConfirmationFilesForConfirmation(state, confirmationId));
  const uniqueParentFileEntityIdsAndNames = useAppSelector((state) => selectFileConfirmationFileParentEntityNameAndIdsSorted(state, confirmationId));
  const fileConfirmationFilesSort = useAppSelector(selectFileConfirmationFilesSort);
  const [fileConfirmationFilesSorted, setFileConfirmationFilesSorted] = useState<Array<IFileConfirmationFile>>(fileConfirmationFiles || []);
  const anyFiles = fileConfirmationFiles.length > 0;

  const attachmentFileIds = fileConfirmationFilesSorted.map((file) => file.fileEntityId);

  useEffect(() => {
    const sortedFiles = sortFilesInParentFolders(uniqueParentFileEntityIdsAndNames, fileConfirmationFiles);
    setFileConfirmationFilesSorted(sortedFiles);
  }, [fileConfirmationFilesSort, fileConfirmationFiles]);

  if (!fileConfirmation) {
    return null;
  }

  if (!anyFiles) {
    return (
      <BhNoMatchesImage>
        <Trans>CONFIRMATIONS.NO_FILES_CONFIRMED</Trans>
      </BhNoMatchesImage>
    );
  }

  return (
    <BhScrollableBody header={<ConfirmationContainerTableHeader showConfirmer={false} />}>
      {uniqueParentFileEntityIdsAndNames.map((parentFileEntity) => {
        const files = fileConfirmationFiles.filter((fcf) => fcf.parentFileEntityId === parentFileEntity.id);
        if (files.length < 1) return null;
        return (
          <div key={parentFileEntity.id}>
            <ConfirmationFolderPath>{parentFileEntity.name}</ConfirmationFolderPath>
            {fileConfirmationFiles
              .filter((fcf) => fcf.parentFileEntityId === parentFileEntity.id)
              .map((file) => {
                return <ConfirmationFileContainer key={file.fileEntityId} fileConfirmationFileId={file.fileEntityId} canEditConfirmation={canEditConfirmation} attachmentFileIds={attachmentFileIds} />;
              })}
          </div>
        );
      })}
    </BhScrollableBody>
  );
};

export default ConfirmationContainerConfirmedContainer;
