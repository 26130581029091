import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyFileTreeTemplatesByType } from "@/app/store/companiesSlice";
import { EntityId } from "@reduxjs/toolkit";
import { FileTreeType } from "@/model/files/IFileTreeTemplate";
import ProjectTreeListCard from "@/views/home/company/detail/companySettingsProjectTree/ProjectTreeListCard";
import { useTranslation } from "react-i18next";
import BhLargeText from "@components/text/BhLargeText";

interface Props {
  companyId: EntityId;
  fileTreeType: FileTreeType;
  onClick: Function;
}

const ProjectTreeListContainer: FC<Props> = ({ companyId, fileTreeType, onClick }) => {
  const { t } = useTranslation();
  const fileTreeTemplates = useAppSelector((state) => selectCompanyFileTreeTemplatesByType(state, companyId, fileTreeType));

  const listIsEmpty = !fileTreeTemplates || fileTreeTemplates.length === 0;

  return (
    <div>
      {listIsEmpty && <BhLargeText classes="text-center py-10">{t("NEW_PROJECT.PROJECT_TREES." + fileTreeType + ".NO_MATCHES")}</BhLargeText>}
      {fileTreeTemplates?.map((template) => {
        return <ProjectTreeListCard key={template.id} template={template} onClick={() => onClick(template)} />;
      })}
    </div>
  );
};

export default ProjectTreeListContainer;
