import React, { FC } from "react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhBadge from "@components/badge/BhBadge";
import { selectFileEntityIdsInSearch, selectSearchString } from "@/app/store/searchSlice";
import { useAppSelector } from "@/app/hooks";
import { useNavigate } from "react-router-dom";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import SearchFileCardContainer from "@/features/searchWithDropdown/SearchFileCardContainer";
import { Trans } from "react-i18next";

const SearchDropdownFileResults: FC = () => {
  const searchResultFileIds = useAppSelector(selectFileEntityIdsInSearch);
  const projectId = useAppSelector(selectCurrentProjectId);
  const searchString = useAppSelector(selectSearchString);
  const navigate = useNavigate();

  if (!searchResultFileIds) return null;

  let fileResultIdsInDropdown = searchResultFileIds.length > 4 ? searchResultFileIds?.slice(0, 4) : searchResultFileIds;
  let hasMoreThanFourResults = searchResultFileIds.length > 4;
  let remainingFilesCount = searchResultFileIds.length - fileResultIdsInDropdown.length;

  const goToAllFileResults = () => {
    navigate(`/project/${projectId}/search/files?query=${searchString}`);
  };

  return (
    <div className="z-50 px-6">
      <h4 className="bh-text-deep-ocean mb-5">
        <Trans>SEARCH.FILES</Trans>
      </h4>
      <div className="flex flex-col gap-1">
        {fileResultIdsInDropdown.map((fileId) => {
          return <SearchFileCardContainer key={fileId} fileId={fileId} />;
        })}
      </div>
      {hasMoreThanFourResults && (
        <BhTextOnlyButton buttonProps={{ classes: "mt-3", onClick: goToAllFileResults }}>
          <BhBadge classes="mr-1">+{remainingFilesCount}</BhBadge>
          <Trans>DASHBOARD.INFO.SEE_ALL</Trans>
        </BhTextOnlyButton>
      )}
    </div>
  );
};

export default SearchDropdownFileResults;
