import React, { FC } from "react";
import BhInputLabel from "@components/input/BhInputLabel";
import BhTimePicker from "@components/input/BhTimePicker";

interface Props {
  initialValue?: any;
  property: string;
  onChangeCallback?: Function;
  disabled?: boolean;
  label?: string;
  labelHelper?: string;
  validationHelper?: string;
  classes?: string;
}

const BhTimePickerStackedLabel: FC<Props> = ({ property, initialValue, label, validationHelper, disabled, labelHelper, onChangeCallback, classes }) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        {label && <BhInputLabel>{label}</BhInputLabel>}
        {labelHelper && (
          <div className="text-13px l-h-16px bh-text-deep-ocean-80 px-0.5 py-1.5 font-bold">
            <span className="bh-text-pigeon-70 font-normal italic"> - {labelHelper}</span>
          </div>
        )}
      </div>
      <BhTimePicker initialValue={initialValue} property={property} saveCallback={onChangeCallback} disabled={disabled} classes={classes} />
      {validationHelper && <div className="l-h-14px bh-text-deep-ocean-80 px-0.5 py-1.5 text-sm">{validationHelper}</div>}
    </div>
  );
};

export default BhTimePickerStackedLabel;
