import React, { FC, useEffect } from "react";
import { EntityId } from "@reduxjs/toolkit";
import ConfirmationActionsFileList from "@/views/home/project/detail/confirmations/confirmation/actions/ConfirmationActionsFilesList";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { ButtonSize } from "@components/buttons/IBhButtonProps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import BhDeclineButton from "@components/buttons/BhDeclineButton";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import {
  openCommentsForFileConfirmationFileIdSet,
  selectFileConfirmationFileParentEntityNameAndIdsSorted,
  selectFileConfirmationFilesForConfirmationWithDecisionId,
  selectOpenCommentsForFileConfirmationFileId
} from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import { decisionStatusChanged, selectCurrentDecisions } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import { FileConfirmationDecisionType } from "@/model/confirmations/IFileConfirmationDecisionV2";
import { classNames } from "@/utilities/jsUtilities";
import ConfirmationActionsPreviewViewFilePreview from "@/views/home/project/detail/confirmations/confirmation/actions/ConfirmationActionsPreviewViewFilePreview";
import { useTranslation } from "react-i18next";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  confirmationId: EntityId;
  selectedFileEntityId: EntityId;
}
const ConfirmationActionsPreviewView: FC<Props> = ({ confirmationId, selectedFileEntityId }) => {
  const { t } = useTranslation();
  const openCommentFileEntityId = useAppSelector(selectOpenCommentsForFileConfirmationFileId);
  const fileConfirmationFilesWithDecisionIds = useAppSelector((state) => selectFileConfirmationFilesForConfirmationWithDecisionId(state, confirmationId));
  const uniqueParentFileEntityIdsAndNames = useAppSelector((state) => selectFileConfirmationFileParentEntityNameAndIdsSorted(state, confirmationId));
  const decisions = useAppSelector(selectCurrentDecisions);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const fileConfirmationFilesWithDecisionIdsSorted = [...fileConfirmationFilesWithDecisionIds].sort((a, b) => {
    const aParentIndex = uniqueParentFileEntityIdsAndNames.findIndex((val) => val.id === a.parentFileEntityId);
    const bParentIndex = uniqueParentFileEntityIdsAndNames.findIndex((val) => val.id === b.parentFileEntityId);
    const aIndex = fileConfirmationFilesWithDecisionIds.findIndex((v) => a === v);
    const bIndex = fileConfirmationFilesWithDecisionIds.findIndex((v) => b === v);
    return aParentIndex < bParentIndex ? (aIndex > bIndex ? -1 : 1) : aIndex > bIndex ? 1 : -1;
  });

  const currentFile = fileConfirmationFilesWithDecisionIds.find((f) => f.fileEntityId === selectedFileEntityId);
  const decision = currentFile && decisions.find((d) => d.id === currentFile.decisionId);

  useEffect(() => {
    if (openCommentFileEntityId) {
      dispatch(openCommentsForFileConfirmationFileIdSet(selectedFileEntityId));
    }
  }, [selectedFileEntityId]);

  if (!decision) {
    return null;
  }

  const isConfirmed = decision.status === FileConfirmationDecisionType.CONFIRMED;
  const isDeclined = decision.status === FileConfirmationDecisionType.DECLINED;

  const selectNextFile = (previous?: boolean) => {
    const index = fileConfirmationFilesWithDecisionIdsSorted.findIndex((v) => v.fileEntityId === selectedFileEntityId);
    const nextIndex = previous ? index - 1 : index + 1;
    const nextFile = fileConfirmationFilesWithDecisionIdsSorted.at(nextIndex);
    if (nextFile) {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${nextFile.projectId}/confirmation/${nextFile.fileConfirmationId}/actions/${nextFile.fileEntityId}`);
    }
  };

  return (
    <div className="flex h-full flex-1 flex-row overflow-hidden">
      <div className="w-96 border-r pt-[5px]">
        <ConfirmationActionsFileList confirmationId={confirmationId} previewShown={true} selectedFileEntityId={selectedFileEntityId} />
      </div>
      <div className="flex flex-1 flex-col">
        <div className="flex-1">
          <ConfirmationActionsPreviewViewFilePreview fileEntityId={selectedFileEntityId} />
        </div>
        <div className={classNames(isConfirmed && "bh-bg-success-green-10", isDeclined && "bh-bg-error-red-10", "flex h-16 flex-row items-center")}>
          <div className="w-14 text-center">
            <BhIconButton icon={faArrowLeft} buttonProps={{ onClick: () => selectNextFile(true) }} />
          </div>
          <div className="flex-1 text-center">
            {!isDeclined && (
              <BhPrimaryButton
                buttonProps={{
                  size: ButtonSize.XL,
                  classes: isConfirmed ? "w-32 bg-none bh-text-success-green-110" : "w-32",
                  onClick: () => {
                    dispatch(decisionStatusChanged({ ...decision, status: isConfirmed ? FileConfirmationDecisionType.PENDING : FileConfirmationDecisionType.CONFIRMED }));
                    !isConfirmed && selectNextFile();
                  }
                }}
              >
                <FontAwesomeIcon icon={faCheck} size={"lg"} className="pr-1" />
                {t("CONFIRM.CONFIRM")}
              </BhPrimaryButton>
            )}
            {!isConfirmed && (
              <BhDeclineButton
                buttonProps={{
                  size: ButtonSize.XL,
                  classes: "w-32",
                  onClick: () => {
                    dispatch(decisionStatusChanged({ ...decision, status: isDeclined ? FileConfirmationDecisionType.PENDING : FileConfirmationDecisionType.DECLINED }));
                    !isDeclined && selectNextFile();
                  }
                }}
              >
                <FontAwesomeIcon icon={faTimes} size={"lg"} className="pr-1" />
                {t("ACT.REFUSE")}
              </BhDeclineButton>
            )}
          </div>
          <div className="w-14 text-center">
            <BhIconButton icon={faArrowRight} buttonProps={{ onClick: () => selectNextFile() }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationActionsPreviewView;
