import React, { ChangeEvent, FC, useId } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentDirId } from "@/app/store/filesSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { uploadAndImportZipFileAsync } from "@/app/store/uploadSlice";
import { selectCurrentSidebarMenuItem } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";

interface Props {
  fileInputRef: React.LegacyRef<HTMLInputElement>;
}

const DirectoryZipFileUploadAndImport: FC<Props> = ({ fileInputRef }) => {
  const id = useId();
  const currentDirId = useAppSelector(selectCurrentDirId);
  const projectId = useAppSelector(selectCurrentProjectId);
  const currentItem = useAppSelector(selectCurrentSidebarMenuItem);
  const dispatch = useAppDispatch();
  const maxFileSizeInBytes = 100 * 1024 * 1024 * 1024;

  const isDocument = currentItem === SidebarItemType.DOCUMENTS;

  const onFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file = files && files[0];
    if (!file || !currentDirId) return;

    if (file.size > maxFileSizeInBytes) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.WARNING,
          disappear: true,
          translateCode: "ZIP.SIZE_TOO_LARGE"
        })
      );
      event.target.value = "";
      return;
    }
    dispatch(uploadAndImportZipFileAsync({ file: file, projectId: projectId, isDocument, dirId: currentDirId }));
    event.target.value = "";
  };

  return <input type="file" id={id} ref={fileInputRef} style={{ display: "none" }} multiple={false} accept={"application/zip"} onChange={onFileSelect} />;
};

export default DirectoryZipFileUploadAndImport;
