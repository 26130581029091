import React, { FC } from "react";
import { IFormBaseVersionRowField } from "@/model/form/IFormBaseVersionRowField";

interface Props {
  field: IFormBaseVersionRowField;
  disabled?: boolean;
  isPreview?: boolean;
}

const FormWysiwygField: FC<Props> = ({ field, isPreview }) => {
  const inputValue = field.value.html;

  return (
    <div>
      <div className="wysiwyg-output" dangerouslySetInnerHTML={{ __html: inputValue }}></div>
    </div>
  );
};

export default FormWysiwygField;
