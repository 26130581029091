import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  values?: Array<string>;
  onSelect?: Function;
  customEmail?: string;
  selectedIndex?: number;
}

const BhStringDropdownMenu: FC<Props> = ({ values, onSelect, customEmail, selectedIndex }) => {
  return (
    <div className={classNames("bh-shadow bh-bg-white inline-block flex w-full flex-col rounded py-3 text-left align-top")}>
      <div className="max-h-40 overflow-auto">
        <ul>
          {values &&
            values.map((value, index) => {
              return (
                <li key={value} className={classNames(index === selectedIndex && "bh-bg-smoke", "hover:bh-bg-smoke cursor-pointer")} onClick={() => onSelect && onSelect(value)}>
                  {customEmail ? (
                    <span className="block truncate py-1.5 px-4">
                      Lisa e-mail <b>"{customEmail}"</b>
                    </span>
                  ) : (
                    <span className="block truncate py-1.5 px-4">{value}</span>
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default BhStringDropdownMenu;
