import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { urlParamsToObject } from "@/utilities/jsUtilities";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import { useTranslation } from "react-i18next";
import { EntityId } from "@reduxjs/toolkit";
import { selectCompanyBillsFilter, setCompanyBillsFilter } from "@/app/store/companiesSlice";
import { fetchCompanyInvoices } from "@/api/billingAPI";
import { ISalesInvoice } from "@/model/billing/ISalesInvoice";
import CompanyBillsFilter from "@/views/home/company/detail/CompanyBillsFilter";
import CompanyBillsTableContainer from "@/views/home/company/detail/CompanyBillsTableContainer";
import { ICompanyBillsFilter } from "@/model/billing/ICompanyBillsFilter";

interface Props {
  companyId: EntityId;
}

const CompanySettingsBillsTab: FC<Props> = ({ companyId }) => {
  const filter = useAppSelector(selectCompanyBillsFilter);
  const [invoices, setInvoices] = useState([] as Array<ISalesInvoice>);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    let filterFromUrlParams = urlParamsToObject(location.search) as unknown as ICompanyBillsFilter;
    filterFromUrlParams.sortOrderAsc = filterFromUrlParams.sortOrderAsc === "true";
    dispatch(setCompanyBillsFilter({ ...filter, ...filterFromUrlParams }));
    if (companyId) {
      fetchCompanyInvoices(companyId, filterFromUrlParams).then((invoices) => {
        const filtered = invoices.sort((a: any, b: any) => (a[filter.sortBy] > b[filter.sortBy] ? (filterFromUrlParams.sortOrderAsc ? 1 : -1) : filterFromUrlParams.sortOrderAsc ? -1 : 1));
        setInvoices(filtered);
        setLoading(false);
      });
    }
  }, [location]);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden px-44 pt-6 pb-36 md:px-8">
      <div className="mt-4 flex flex-col">
        <DetailViewHeading>{t("COMPANY.SETTINGS.ORDERS.INVOICES")}</DetailViewHeading>
        <CompanyBillsFilter companyId={companyId} />
      </div>
      <div className="flex flex-1 overflow-auto pb-8">
        <CompanyBillsTableContainer invoices={invoices} setInvoices={setInvoices} />
      </div>
    </div>
  );
};

export default CompanySettingsBillsTab;
