import React, { FC, startTransition, useCallback, useEffect, useRef } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  initialValue: any;
  property: string;
  onBlurCallback?: Function;
  disabled?: boolean;
  placeholder?: string;
  isHelperTextInput?: boolean;
  inputClasses?: string;
}

const FormFieldContainerInput: FC<Props> = ({ initialValue, property, onBlurCallback, disabled, placeholder, isHelperTextInput, inputClasses }) => {
  let containedInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (containedInputRef.current) {
      if (initialValue !== undefined) containedInputRef.current.value = initialValue;
      if (initialValue === undefined && containedInputRef.current.value) containedInputRef.current.value = "";
    }
  }, [initialValue]);

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault();
      startTransition(() => {
        const inputValue = e.target.value;
        const initialValueString = initialValue || "";
        if (inputValue === initialValueString) return;
        onBlurCallback && onBlurCallback(property, e.target.value);
      });
    },
    [onBlurCallback, initialValue]
  );

  return (
    <input
      className={classNames(
        "bh-bg-mint bh-text-deep-ocean-80 text-13px hover:bh-bg-mint-110 disabled:bh-bg-white disabled:hover:bh-bg-white w-full !rounded-none border-none py-1 px-0.5 leading-4 hover:cursor-pointer focus:cursor-text disabled:cursor-text",
        isHelperTextInput ? "h-3.5" : "h-4",
        !isHelperTextInput && "font-bold",
        inputClasses && inputClasses
      )}
      disabled={disabled}
      ref={containedInputRef}
      onBlur={onBlur}
      name={property}
      type={"text"}
      placeholder={placeholder || ""}
      autoComplete="off"
      aria-autocomplete={"none"}
    />
  );
};

export default FormFieldContainerInput;
