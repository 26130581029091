import { ISignInvite } from "@/model/ISignInvite";
import { ISignatureHolder } from "@/model/ISignatureHolder";

export function convertSignInviteToSignatureHolder(signInvite: ISignInvite): ISignatureHolder {
  return {
    uniqueId: "invite-" + signInvite.id,
    username: signInvite.username,
    isUnsignedInvite: !signInvite.signed,
    declined: signInvite.declined,
    projectId: signInvite.projectId,
    containerUuid: signInvite.containerUuid,
    orderNumber: signInvite.orderNumber,
    fileEntityId: signInvite.fileEntityId,
    deleted: signInvite.deleted
  } as ISignatureHolder;
}

export function convertSignInvitesToSignatureHolders(signInvites: Array<ISignInvite>): Array<ISignatureHolder> {
  const signatureHolders = signInvites.map((signInvite) => convertSignInviteToSignatureHolder(signInvite));
  return signatureHolders;
}
/* https://stackoverflow.com/a/39460839 */
export function base64ToBase16(base64: any) {
  return window
    .atob(base64)
    .split("")
    .map(function (aChar) {
      return ("0" + aChar.charCodeAt(0).toString(16)).slice(-2);
    })
    .join("");
}
