import { EntityId } from "@reduxjs/toolkit";
import { IBillingModel, IBillingRequisites, ICompanyBillingRequisites, IProjectBillingRequisites, ProjectInvoiceType } from "@/model/IBillingInfo";
import { getCookie } from "@/utilities/jsUtilities";
import { ICompanyBilling } from "@/model/billing/ICompanyBilling";
import { IDowngradePlanInfo } from "@/model/billing/IDowngradePlanInfo";
import { IUpgradePlanInfo } from "@/model/billing/IUpgradePlanInfo";
import { bauhubGet, bauhubPost } from "./bauhubAPI";
import { IProjectOrder } from "@/model/billing/IProjectOrder";
import { IProjectBilling } from "@/model/billing/IProjectBilling";
import { ISalesInvoice } from "@/model/billing/ISalesInvoice";
import { ICompanyBillsFilter } from "@/model/billing/ICompanyBillsFilter";
import { IWrapperDTO } from "@/model/utilities/IWrapperDTO";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

export function fetchBillingRequisites(projectId: EntityId) {
  return bauhubGet("/project/" + projectId + "/billing/requisites");
}

export function fetchCompanyBilling(companyId: EntityId): Promise<ICompanyBilling> {
  return bauhubGet("/company/" + companyId + "/billing");
}

export function fetchBillingUpgradeInfo(companyId: EntityId, date: string): Promise<IUpgradePlanInfo> {
  return bauhubGet("/company/" + companyId + "/billing/plan/upgrade/info?date=" + date);
}

export function fetchProjectOrders(companyId: EntityId): Promise<Array<IProjectOrder>> {
  return bauhubGet("/company/" + companyId + "/orders");
}

export function postBillingUpgrade(companyId: EntityId, plan: string, date: string): Promise<ICompanyBilling> {
  return bauhubPost("/company/" + companyId + "/billing/plan/upgrade?date=" + date, { value: plan });
}

export function fetchBillingDowngradeInfo(companyId: EntityId, date: string): Promise<IDowngradePlanInfo> {
  return bauhubGet("/company/" + companyId + "/billing/plan/downgrade/info?date=" + date);
}

export function postBillingDowngrade(companyId: EntityId, plan: string, date: string): Promise<ICompanyBilling> {
  return bauhubPost("/company/" + companyId + "/billing/plan/downgrade?date=" + date, { value: plan });
}

export function fetchNewBillingRequisites(companyId: EntityId): Promise<IBillingRequisites> {
  return bauhubGet("/company/" + companyId + "/project/billing/requisites/new");
}

export function fetchCompanyBillingRequisites(companyId: EntityId): Promise<ICompanyBillingRequisites> {
  return bauhubGet("/company/" + companyId + "/billing/requisites");
}

export function changeProjectSubscriptionModel(companyId: EntityId, projectId: EntityId, type: ProjectInvoiceType): Promise<IProjectOrder> {
  return bauhubPost("/company/" + companyId + "/project/" + projectId + "/subscription/change", { value: type });
}

export function changeCompanyBillingRequisites(companyBillingRequisites: ICompanyBillingRequisites): Promise<ICompanyBillingRequisites> {
  return bauhubPost("/company/billing/requisites", companyBillingRequisites);
}

export function changeProjectBillingRequisites(companyId: EntityId, order: IProjectOrder): Promise<IProjectOrder> {
  return bauhubPost("/company/" + companyId + "/project/billing/requisites/change", order);
}

export function changeCompanyInvoiceType(companyId: EntityId, invoiceType: String): Promise<Array<IProjectOrder>> {
  return bauhubPost("/company/" + companyId + "/invoice/type/change", { value: invoiceType });
}

export function confirmProjectOrders(companyId: EntityId, orders: Array<IProjectOrder>): Promise<Array<IProjectOrder>> {
  return bauhubPost("/company/" + companyId + "/project/orders/confirm", orders);
}

export function getProjectsOrderPrice(companyId: EntityId, projectIds: Array<EntityId>): Promise<IBillingModel> {
  return bauhubGet("/company/" + companyId + "/project/order/price", { projectIds: projectIds });
}

export function getProjectActivationBillingModel(companyId: EntityId, projectId: EntityId): Promise<IBillingModel> {
  return bauhubGet("/company/" + companyId + "/project/" + projectId + "/activation/billing");
}

export function fetchCompanyInvoices(companyId: EntityId, filter: ICompanyBillsFilter): Promise<Array<ISalesInvoice>> {
  return bauhubGet("/company/" + companyId + "/billing/invoices", {
    since: filter.since?.toString().split("T")[0],
    until: filter.until?.toString().split("T")[0],
    project: filter.project,
    status: filter.status
  });
}

export function downloadSalesInvoice(companyId: EntityId, uuid: string): Promise<IWrapperDTO> {
  return bauhubGet("/company/" + companyId + "/invoice/" + uuid + "/download");
}

export function getCompanySalesInvoiceProjects(companyId: EntityId): Promise<Array<string>> {
  return bauhubGet("/company/" + companyId + "/billing/invoices/projects");
}

export function activateProjectSubscription(projectBilling: IProjectBillingRequisites | IProjectBilling) {
  return fetch(ConfigSingleton.getInstance().getConfig().REACT_APP_API_URL + "/project/subscription/activate", {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "X-XSRF-TOKEN": getCookie("XSRF-TOKEN") || ""
    },
    body: JSON.stringify(projectBilling)
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
}

export function findVatNumberCompany(vatNumber: string): Promise<{ valid: boolean; name: string; nameSpecified: boolean }> {
  return bauhubGet("/vat/" + vatNumber + "?st=false&disableErrors=true");
}
