import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchFileEntityAsync, fetchFilesOldAsync, selectFileById } from "@/app/store/filesSlice";
import ContainerSignaturesContainer from "@/views/home/project/detail/container/ContainerSignaturesContainer";
import ContainerSignModal from "@/views/home/project/detail/container/modals/ContainerSignModal";
import ContainerActionButtonsContainer from "@/views/home/project/detail/container/ContainerActionButtonsContainer";
import ContainerDeclineModal from "@/views/home/project/detail/container/modals/ContainerDeclineModal";
import BhDetailContainer from "@components/detailContainer/BhDetailContainer";
import BhDetailContainerHeader from "@components/detailContainer/BhDetailContainerHeader";
import BhDetailContainerBody from "@components/detailContainer/BhDetailContainerBody";
import ContainerFilesContainer from "@/views/home/project/detail/container/ContainerFilesContainer";
import { EntityId } from "@reduxjs/toolkit";
import { fetchContainerSignaturesAsync } from "@/app/store/signaturesSlice";
import ContainerDeletedSignaturesContainer from "@/views/home/project/detail/container/ContainerDeletedSignaturesContainer";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import BackButtonToDirectoryView from "@/views/home/project/detail/pdftron/PdftronContainerBackButton";

interface Props {
  fileEntityId: EntityId;
}

const ContainerContainer: FC<Props> = ({ fileEntityId }) => {
  const containerFileEntity = useAppSelector((state) => selectFileById(state, fileEntityId));
  const [containerSignModalShown, setContainerSignModalShown] = useState(false);
  const [containerDeclineModalShown, setContainerDeclineModalShown] = useState(false);
  const [initialSignaturesLoading, setInitialSignaturesLoading] = useState(true);
  const dispatch = useAppDispatch();

  useDocumentTitle(containerFileEntity?.name);

  useEffect(() => {
    if (containerFileEntity?.id) {
      dispatch(fetchContainerSignaturesAsync(containerFileEntity)).then(() => {
        setInitialSignaturesLoading(false);
      });
    }
    dispatch(fetchFileEntityAsync(fileEntityId));
    dispatch(fetchFilesOldAsync(fileEntityId));
  }, [containerFileEntity?.id]);

  if (!containerFileEntity) {
    return null;
  }

  return (
    <BhDetailContainer>
      <BhDetailContainerHeader>
        <div className="flex flex-1 items-center">
          <BackButtonToDirectoryView fileEntityId={fileEntityId} parentFileEntityId={containerFileEntity.parentFileEntityId} projectId={containerFileEntity.projectId} />
          <span className="l-h-24px text-18px pl-2 font-bold">{containerFileEntity.name}</span>
        </div>
        {!initialSignaturesLoading && (
          <ContainerActionButtonsContainer
            containerFileEntity={containerFileEntity}
            setContainerSignModalShown={setContainerSignModalShown}
            setContainerDeclineModalShown={setContainerDeclineModalShown}
          />
        )}
      </BhDetailContainerHeader>
      <BhDetailContainerBody>
        <div className="max-w-1152px m-auto pb-40 pt-4">
          <ContainerDeletedSignaturesContainer containerFileEntity={containerFileEntity} />
          {!initialSignaturesLoading && <ContainerSignaturesContainer containerFileEntity={containerFileEntity} />}
          <ContainerFilesContainer containerFileEntity={containerFileEntity} />
        </div>
      </BhDetailContainerBody>
      {/*MODALS*/}
      {containerSignModalShown && <ContainerSignModal fileContainer={containerFileEntity} setContainerSignModalShown={setContainerSignModalShown} />}
      {containerDeclineModalShown && <ContainerDeclineModal container={containerFileEntity} setContainerDeclineModalShown={setContainerDeclineModalShown} />}
    </BhDetailContainer>
  );
};

export default ContainerContainer;
