import React, { useState } from "react";
import BOCompanyProjectsTabCards from "@components/backoffice/company/projects/BOCompanyProjectsTabCards";
import BOCompanyProjectsTable from "@components/backoffice/company/projects/BOCompanyProjectsTable";
import BOCompanyProjectsFilter from "@components/backoffice/company/projects/BOCompanyProjectsFilter";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import BOCompanyNewProjectModal from "@components/backoffice/company/projects/BOCompanyNewProjectModal";

const BOCompanyProjectsTab = () => {
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false);

  return (
    <div className="min-h-screen px-20 pt-10">
      <h2>Projects</h2>
      <BOCompanyProjectsTabCards />
      <div className="flex items-center justify-between">
        <BOCompanyProjectsFilter />
        <BhSecondaryButton icon={faPlus} buttonProps={{ onClick: () => setNewProjectModalOpen(true) }}>
          New project
        </BhSecondaryButton>
      </div>
      <div className="mt-6">
        <BOCompanyProjectsTable />
      </div>
      {newProjectModalOpen && <BOCompanyNewProjectModal setIsShown={setNewProjectModalOpen} />}
    </div>
  );
};

export default BOCompanyProjectsTab;
