import React, { FC } from "react";
import BhBreadcrumb from "@components/breadcrumb/BhBreadcrumb";

interface Props {
  values: Array<{ text: string; url?: string; onClick?: Function }>;
}

const BhBreadcrumbs: FC<Props> = ({ values }) => {
  return (
    <div>
      {values &&
        values.map((value, index) => {
          return <BhBreadcrumb text={value.text} url={value.url} onClick={value.onClick} active={index === values.length - 1} key={"breadcrumb" + index} />;
        })}
    </div>
  );
};

export default React.memo(BhBreadcrumbs);
