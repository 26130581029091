import React, { FC } from "react";
import BhAttachmentsUploadContainer from "@components/upload/BhAttachmentsUploadContainer";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons/faPaperclip";
import { useAppSelector } from "@/app/hooks";
import { useTranslation } from "react-i18next";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/form/formSlice";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";

interface Props {
  field: any;
  saveCallback?: Function;
  disabled: boolean;
  isPreview?: boolean;
}

const FormUploadField: FC<Props> = ({ field, saveCallback, disabled, isPreview }) => {
  const { t } = useTranslation();
  const fieldValue = useAppSelector((state) => (!isPreview ? selectValueByPropertyFromCurrentFormData(state, field.property) : []));
  const addDatesWhenAddingFiles = ["VCW", "MNT_TEOSTATUD_TOODE_AKT", "MNT_KTA", "KTA_EVENTUS"].includes(field.value.formType);

  const saveUploadedFiles = (changedValue: any) => {
    changedValue[field.property] = changedValue[field.property].map((val: any) => ({ ...val, selected: false }));
    if (addDatesWhenAddingFiles) {
      saveUploadedFilesWithDates(changedValue);
      return;
    }
    saveCallback && saveCallback(changedValue);
  };

  const saveUploadedFilesWithDates = (changedValue: any) => {
    const filesWithDates = changedValue[field.property].map((file: any) => {
      const isNewFile = !fieldValue.some((f: any) => f.id === file.id);
      if (isNewFile) {
        return { ...file, date: file.created };
      }
      return file;
    });
    saveCallback && saveCallback({ [field.property]: filesWithDates });
  };

  return (
    <div className="flex h-full flex-row items-center">
      <BhAttachmentsUploadContainer
        saveCallback={saveUploadedFiles}
        existingAttachments={fieldValue}
        button={
          <BhSecondaryButton icon={faPaperclip} buttonProps={{ disabled: disabled }}>
            {t("TASK.ADD_ATTACHMENT")}
          </BhSecondaryButton>
        }
        property={field.property}
        multiple={true}
        disabled={disabled}
        fileUploadAllowed={true}
        chooseFromProjectAllowed={true}
        dropdownPosition={BhDropdownPositionEnum.BOTTOM_RIGHT}
      />
    </div>
  );
};

export default FormUploadField;
