import React, { FC, useEffect } from "react";
import { useAppDispatch } from "@/app/hooks";
import { fetchNewUserNotificationsAsync, fetchProjectNotificationCountsAsync } from "@/app/store/userNotificationsSlice";

const NotificationIntervalController: FC = () => {
  const dispatch = useAppDispatch();
  const POLLING_INTERVAL = 1 * 60 * 1000;

  // https://stackoverflow.com/questions/71831468/violation-setinterval-handler-took-nms
  const pollNotifications = async () => {
    await new Promise(() => {
      return dispatch(fetchNewUserNotificationsAsync());
    });
  };

  useEffect(() => {
    dispatch(fetchProjectNotificationCountsAsync());

    const interval = setInterval(pollNotifications, POLLING_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <></>;
};

export default NotificationIntervalController;
