import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import partyHelmets from "@img/eelseadistatud-osapooled-compressed.png";
import directoryFolders from "@img/eelseadistatud-kaustapuud-compressed.png";
import { Trans, useTranslation } from "react-i18next";
import BhLargeText from "@components/text/BhLargeText";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { useNavigate } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  companyId: EntityId;
  isFolderStructureTemplateView?: boolean;
  isPartyTemplateView?: boolean;
}

const CompanySettingsGetProPlanPlaceholder: FC<Props> = ({ companyId, isFolderStructureTemplateView, isPartyTemplateView }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const folders = isFolderStructureTemplateView && directoryFolders;
  const helmets = isPartyTemplateView && partyHelmets;

  return (
    <BhScrollableBody>
      <div className="flex flex-col items-center px-44 pt-6 md:px-8">
        <img src={folders || helmets || ""} className="m-8 max-w-[140px] place-self-center" alt="No matches" />
        <span className="text-center text-2xl font-bold">
          {isFolderStructureTemplateView && <Trans>COMPANY.SETTINGS.PROJECT_TREE.LIGHT.HEADER</Trans>}
          {isPartyTemplateView && <Trans>COMPANY.SETTINGS.PARTIES.LIGHT.HEADER</Trans>}
        </span>
        <BhLargeText classes="text-center w-2/3 pt-4">
          {isFolderStructureTemplateView && <Trans>COMPANY.SETTINGS.PROJECT_TREE.LIGHT.BODY</Trans>}
          {isPartyTemplateView && <Trans>COMPANY.SETTINGS.PARTIES.LIGHT.BODY</Trans>}
        </BhLargeText>
        <BhPrimaryButton buttonProps={{ classes: "w-min whitespace-nowrap mt-10 h-12 px-9", onClick: () => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${companyId}/billing-plan`) }}>
          {t("COMPANY.SETTINGS.PROJECT_TREE.LIGHT.GET_PRO")}
        </BhPrimaryButton>
      </div>
    </BhScrollableBody>
  );
};

export default CompanySettingsGetProPlanPlaceholder;
