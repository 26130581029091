import React, { FC } from "react";
import TaskInfoField from "@components/task/modal/TaskInfoField";
import { ITask } from "@/model/taskBoard/ITask";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { IDropdownItem } from "@/model/IDropdownItem";
import { classNames } from "@/utilities/jsUtilities";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import PriorityTagWithName from "@components/task/modal/PriorityTagWithName";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectOpenedTaskDisabled } from "@/app/store/tasksSlice";

interface Props {
  task: ITask;
  onSelect: Function;
}

const TaskPriorityInfoField: FC<Props> = ({ task, onSelect }) => {
  const { t } = useTranslation();
  const isTaskDisabled = useAppSelector(selectOpenedTaskDisabled);

  const priorities: Record<number, string> = {
    5: "PRIORITY.CRITICAL",
    4: "PRIORITY.HIGH",
    3: "PRIORITY.MEDIUM",
    2: "PRIORITY.LOW",
    1: "PRIORITY.LOWEST"
  };

  const values: Array<IDropdownItem> = [5, 4, 3, 2, 1]
    .filter((prio) => prio !== task.priority)
    .map((priority) => {
      return {
        text: <PriorityTagWithName priority={priority} text={t(priorities[priority])} textClasses={classNames("bh-text-deep-ocean ml-2", priority === 3 ? "ml-7.5" : "ml-2")} />,
        function: () => {
          const changedObject = { priority: priority };
          onSelect(changedObject);
        }
      };
    });

  return (
    <BhDropdown
      button={
        <TaskInfoField
          text={t("TASK.PRIORITY")}
          children={
            <div className="bh-text-deep-ocean flex min-h-[30px] flex-row items-center font-bold">
              <PriorityTagWithName priority={task.priority} text={t(priorities[task.priority])} textClasses={classNames("bh-text-deep-ocean", task.priority === 3 ? "m-0" : "ml-2")} />
            </div>
          }
          valueExists={true}
          trailingIcon={faCaretDown}
          disabled={isTaskDisabled}
        />
      }
      menu={<BhDropdownMenu type={BhDropdownTypeEnum.STRING} values={values} textProperty={"text"} closeOnItemClick={true} />}
      position={BhDropdownPositionEnum.BOTTOM_LEFT}
      buttonClasses={"w-full px-0"}
      menuClasses={"right-4 top-11"}
      disabled={isTaskDisabled}
    />
  );
};

export default TaskPriorityInfoField;
