import { bauhubGet, bauhubPost } from "@/api/bauhubAPI";
import { EntityId } from "@reduxjs/toolkit";
import { IAct, IActExternalInfo, IActToFileEntity } from "@/model/IAct";
import { IActWork } from "@/model/IActWork";
import { ISigner } from "@/model/ISigner";
import { ISignerGroup } from "@/model/ISignerGroup";
import { IContractWorkContainer } from "@/model/contracts/IContractWorkContainer";

export function fetchPartyActs(projectId: EntityId, partyId: EntityId): Promise<Array<IAct>> {
  return bauhubGet("/project/" + projectId + "/party/" + partyId + "/acts");
}

export function fetchActV2(actId: EntityId): Promise<IAct> {
  return bauhubGet("/v2/project/act/" + actId);
}

export function fetchAct(actId: EntityId): Promise<IAct> {
  return bauhubGet("/project/act/" + actId);
}

export function saveNewActForParty(dto: { projectId: EntityId; workGroupId: EntityId }): Promise<IAct> {
  return bauhubPost("/v2/project/act/new", dto);
}

export function saveNewActForPartyAngular(dto: { projectId: EntityId; workGroupId: EntityId }): Promise<IAct> {
  return bauhubPost("/project/act/new", dto);
}

export function saveAct(act: IAct): Promise<IAct> {
  return bauhubPost("/project/act", act);
}

export function deleteAct(actId: EntityId): Promise<IAct> {
  return bauhubPost("/project/act/" + actId + "/delete");
}

export function saveNewActExtraWorkContainer(contractWorkContainer: IContractWorkContainer, actId: EntityId): Promise<Array<IActWork>> {
  return bauhubPost("/project/act/" + actId + "/work/container", contractWorkContainer);
}

export function saveActWork(actWork: IActWork): Promise<Array<IActWork>> {
  return bauhubPost("/v2/project/act/work", actWork);
}

export function saveActSigner(signer: ISigner): Promise<ISigner> {
  return bauhubPost("/project/act/signer", signer);
}

export function saveActSignerGroup(signerGroup: ISignerGroup): Promise<ISignerGroup> {
  return bauhubPost("/v2/project/act/signerGroup", signerGroup);
}

export function fetchUrlForActPdf(actId: EntityId): Promise<{ value: string }> {
  return bauhubGet("/project/act/" + actId + "/pdf");
}

export function moveActToFileTree(actId: EntityId, dirId: EntityId): Promise<IAct> {
  return bauhubPost("/project/act/" + actId + "/copy/dir/" + dirId);
}

export function fetchAnyDigiDocumentPresentInWorkGroupContracts(partyId: EntityId): Promise<{ value: boolean }> {
  return bauhubGet("/project/party/" + partyId + "/contracts/any/digidocument");
}

export function sendActFiles(dto: any): Promise<string> {
  return bauhubPost("/project/act/send?st=false", dto);
}

export function saveActFileRelations(relations: Array<IActToFileEntity>): Promise<Array<IActToFileEntity>> {
  return bauhubPost("/project/act/relations", relations);
}

export function findActExternalInfo(projectId: EntityId, actId: EntityId): Promise<Array<IActExternalInfo>> {
  return bauhubGet("/project/" + projectId + "/act/" + actId + "/external/info");
}

export function sendMaruAct(projectId: EntityId, actId: EntityId): Promise<IActExternalInfo> {
  return bauhubGet("/project/" + projectId + "/act/" + actId + "/maru/send?st=false");
}
