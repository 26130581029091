import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchChecklistWithInfoAsync, fetchUniqueProjectChecklistTagsAsync } from "@/app/store/checklist/checklistsSlice";
import ChecklistHeader from "@components/checklists/checklist/header/ChecklistHeader";
import ChecklistBody from "@components/checklists/checklist/body/ChecklistBody";
import { fetchChecklistRoundWithRowsAsync, removeAllChecklistRows } from "@/app/store/checklist/checklistRowsSlice";
import { fetchChecklistBaseVersionRowsAsync, removeAllChecklistBaseRows } from "@/app/store/checklist/checklistBaseRowsSlice";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { fetchProjectTaskCategoriesAsync, fetchProjectTaskTagsAsync } from "@/app/store/tasksSlice";
import { fetchProjectTaskBoardsAsync } from "@/app/store/taskBoardsSlice";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  checklistId: EntityId;
  checklistRoundId: EntityId;
}

const ChecklistContainer: FC<Props> = ({ checklistId, checklistRoundId }) => {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);

  useEffect(() => {
    return function cleanup() {
      dispatch(removeAllChecklistRows());
      dispatch(removeAllChecklistBaseRows());
    };
  });

  useEffect(() => {
    dispatch(setSidebarCollapsed(true));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.CHECKLISTS));
    dispatch(fetchUniqueProjectChecklistTagsAsync(projectId));
    dispatch(fetchChecklistWithInfoAsync(checklistId)).then(() => {
      dispatch(fetchChecklistBaseVersionRowsAsync(checklistId)).then(() => {
        dispatch(fetchChecklistRoundWithRowsAsync(checklistRoundId));
      });
    });
    //Needed for tasks
    dispatch(fetchProjectTaskCategoriesAsync(projectId));
    dispatch(fetchProjectTaskTagsAsync(projectId));
    dispatch(fetchProjectTaskBoardsAsync(projectId));
  }, [checklistRoundId]);

  return (
    <div className="flex w-full flex-col">
      <ChecklistHeader checklistId={checklistId} checklistRoundId={checklistRoundId} />
      <ChecklistBody checklistId={checklistId} checklistRoundId={checklistRoundId} />
    </div>
  );
};

export default ChecklistContainer;
