import React from "react";
import partyHelmets from "@img/eelseadistatud-osapooled-compressed.png";
import BhSmallText from "@components/text/BhSmallText";
import { Trans } from "react-i18next";

const NewProjectPredefinedPartiesNoMatches = () => {
  return (
    <div className="flex w-full flex-col">
      <img src={partyHelmets} className="m-8 max-w-[100px] place-self-center" alt="No matches" />
      <BhSmallText classes="text-center">
        <Trans>NEW_PROJECT.PREDEFINED_WORKGROUPS.NO_MATCHES</Trans>
      </BhSmallText>
      <BhSmallText classes="text-center font-bold">
        <Trans>NEW_PROJECT.NO_MATCHES_INFO</Trans>
      </BhSmallText>
    </div>
  );
};

export default NewProjectPredefinedPartiesNoMatches;
