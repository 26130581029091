import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { Trans, useTranslation } from "react-i18next";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import NewCommentInput from "@/views/home/project/detail/directory/fileInfoBar/fileInfoBarDetailViews/NewCommentInput";
import { deleteProjectMessageAsync, fetchProjectMessagesAsync, saveProjectMessagesAsync, selectAllProjectMessages, selectProjectMessagesStatus } from "@/app/store/project/projectMessageSlice";
import BhProjectMessageCompact from "@components/comment/BhProjectMessageCompact";
import { IProjectMessage } from "@/model/IProjectMessage";
import { selectCurrentUserUsername, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { EntityId } from "@reduxjs/toolkit";
import noMatches from "@img/teadetetahvli-mullid-compressed.png";

interface Props {
  projectId: EntityId;
}

const MessageBoard: FC<Props> = ({ projectId }) => {
  const { t } = useTranslation();
  const status = useAppSelector(selectProjectMessagesStatus);
  const projectMessages = useAppSelector(selectAllProjectMessages);
  const isCurrentUserProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const currentUserUsername = useAppSelector(selectCurrentUserUsername);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchProjectMessagesAsync(projectId));
  }, []);

  const onNewMessageSubmit = (formValues: IProjectMessage) => {
    return dispatch(saveProjectMessagesAsync(formValues));
  };

  const canDelete = (message: IProjectMessage) => {
    return isCurrentUserProjectAdmin || message.createdBy === currentUserUsername;
  };

  const showMessages = status === BhStateStatusType.SUCCESS && projectMessages?.length > 0;

  return (
    <div className="flex h-full flex-col border-l py-4">
      <div className="px-4">
        <DetailViewHeading>{t("DASHBOARD.MESSAGEBOARD_TITLE")}</DetailViewHeading>
      </div>
      <NewCommentInput onSubmit={onNewMessageSubmit} attachmentsEnabled={true} placeholder={t("MESSAGEBOARD.ADD_MESSAGE") as string} showRemoveButton={true} />
      <div className="flex-1 overflow-auto">
        {showMessages &&
          projectMessages.map((message) => {
            return (
              <div className="border-b" key={message.id}>
                <BhProjectMessageCompact message={message} deleteCallback={canDelete(message) ? () => dispatch(deleteProjectMessageAsync(message)) : undefined} />
              </div>
            );
          })}
        {!showMessages && (
          <div className="flex w-full flex-col items-center justify-center">
            <img src={noMatches} className="m-8 mb-0 w-full max-w-[100px]" alt="No matches" />
            <p className="text-center">
              <Trans>MESSAGEBOARD.NO_MESSAGES</Trans>
            </p>
          </div>
        )}
      </div>
      {/*<div className="flex flex-row justify-between border-t px-4 pt-4">*/}
      {/*  <BhLargeBoldText>Projekti maht</BhLargeBoldText>*/}
      {/*  <BhLargeText>156.1GB</BhLargeText>*/}
      {/*</div>*/}
    </div>
  );
};

export default MessageBoard;
