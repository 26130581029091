import React, { FC, useRef, useState } from "react";
import { IUser } from "@/model/IUser";
import BhInputWithChips from "@components/input/BhInputWithChips";
import { convertSimpleValidUserAuthorityToUser } from "@/model/ISimpleValidUserAuthority";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { fetchSearchedUsersInProject } from "@/api/userAPI";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  label?: string;
  currentValues: Array<IUser>;
  onRemove?: Function;
  onSelect?: Function;
  disabled?: boolean;
  disabledUserIds?: Array<EntityId>;
  onNewValueSelect?: Function;
  usersToExclude?: Array<IUser>;
}

const BhInputWithFetchedUserChips: FC<Props> = ({ label, currentValues, onRemove, onSelect, disabled, disabledUserIds, onNewValueSelect, usersToExclude }) => {
  const [fetchedUsers, setFetchedUsers] = useState([] as Array<IUser>);
  const inputRef = useRef<HTMLInputElement>(null);
  const projectId = useAppSelector(selectCurrentProjectId);

  const fetchUsers = (query: string) => {
    return fetchSearchedUsersInProject(projectId, query).then((users) => {
      let fetchedUsers = users;
      if (usersToExclude && usersToExclude.length > 0) {
        fetchedUsers = users.filter((user) => !usersToExclude.some((u) => u.id === user.userEntityId));
      }
      return fetchedUsers.map((simpleValidUser) => convertSimpleValidUserAuthorityToUser(simpleValidUser));
    });
  };

  return (
    <BhInputWithChips
      dropdownValues={fetchedUsers}
      onSelect={onSelect}
      currentValues={currentValues}
      onRemove={onRemove}
      inputRef={inputRef}
      fetchValues={fetchUsers}
      onNewValueSelect={onNewValueSelect}
      disabled={disabled}
      disabledUserIds={disabledUserIds}
    />
  );
};

export default React.memo(BhInputWithFetchedUserChips);
