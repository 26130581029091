import { useEffect, useRef } from "react";

type ClickOutsideCallback = () => Function;

export function useClickOutside<T extends HTMLElement>(clickOutsideCallback: ClickOutsideCallback) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        clickOutsideCallback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [clickOutsideCallback]);

  return ref;
}
