import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  children: React.ReactNode;
  classes?: string;
  colSpan?: number;
  rowSpan?: number;
}

const BhCompactTableTH: FC<Props> = ({ children, classes, colSpan, rowSpan }) => {
  return (
    <th className={classNames(classes, "bh-text-deep-ocean-80 bh-bg-smoke bh-border-pigeon-50 border py-0.5 px-2")} colSpan={colSpan} rowSpan={rowSpan}>
      {children}
    </th>
  );
};

export default BhCompactTableTH;
