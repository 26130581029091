import React, { FC, ReactNode } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  children: ReactNode;
  classes?: string;
}

const BhSpreadsheetTableHeaderRow: FC<Props> = ({ children, classes }) => {
  return <tr className={classNames(classes, "bh-border-pigeon-50 min-h-7 border")}>{children}</tr>;
};

export default BhSpreadsheetTableHeaderRow;