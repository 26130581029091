import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faCircleXmark } from "@fortawesome/pro-regular-svg-icons/faCircleXmark";
import { faCheckDouble } from "@fortawesome/pro-solid-svg-icons/faCheckDouble";
import { FileConfirmationDecisionType } from "@/model/confirmations/IFileConfirmationDecisionV2";

interface Props {
  status: FileConfirmationDecisionType;
}

const BhConfirmationStatusLabelIconOnly: FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  const confirmationStatusMap = {
    CONFIRMED: {
      iconColor: "bh-text-royal-blue",
      icon: faCheckDouble,
      translateCode: "CONFIRMATION.CONFIRMED"
    },
    PENDING: {
      iconColor: "bh-text-warning-yellow-120",
      icon: faClock,
      translateCode: "CONFIRMATION.PENDING"
    },
    DECLINED: {
      iconColor: "bh-text-error-red",
      icon: faCircleXmark,
      translateCode: "CONFIRMATION.DECLINED"
    }
  };

  if (!status) {
    return null;
  }

  return (
    <BhTooltip body={t(confirmationStatusMap[status].translateCode)}>
      <FontAwesomeIcon icon={confirmationStatusMap[status].icon} className={"h-4 py-1 px-1.5 " + confirmationStatusMap[status].iconColor} aria-hidden="true" />
    </BhTooltip>
  );
};

export default BhConfirmationStatusLabelIconOnly;
