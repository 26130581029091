import React, { FC, useEffect } from "react";
import DetailViewContainer from "@/views/home/project/detail/detailViewContainer/DetailViewContainer";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import UserNotificationListItemContainer from "@/views/home/notifications/UserNotificationListItemContainer";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchUserNotificationsAsync, selectUserNotifications, setAllNotificationsSeenAsync } from "@/app/store/userNotificationsSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import ListScrollContainer from "@/views/home/project/detail/userNotifications/ListScrollContainer";
import { useTranslation } from "react-i18next";
import BhButtonTemplateWithIcon from "@components/buttons/BhButtonTemplateWithIcon";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";

interface Props {}

const UserNotificationsContainer: FC<Props> = () => {
  useDocumentTitle("NOTIFICATION.NOTIFICATIONS.HEADER");
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const notifications = useAppSelector((state) => selectUserNotifications(state, projectId));

  useEffect(() => {
    dispatch(fetchUserNotificationsAsync(projectId));
  }, []);

  return (
    <div className="w-full pb-7">
      <DetailViewContainer
        header={
          <DetailViewHeading>
            <div className="bh-text-deep-ocean flex flex-row items-center justify-between pb-10">
              <div>{t("NOTIFICATION.NOTIFICATIONS.HEADER")}</div>
              <BhButtonTemplateWithIcon
                buttonProps={{
                  onClick: () => {
                    dispatch(setAllNotificationsSeenAsync(projectId));
                  },
                  classes: "py-1 min-w-70px extra-letter-spacing bh-text-deep-ocean hover:bh-text-dark-jungle hover:bh-bg-pigeon-20"
                }}
                icon={faEye}
              >
                {t("NOTIFICATION.SET_ALL_SEEN")}
              </BhButtonTemplateWithIcon>
            </div>
          </DetailViewHeading>
        }
      >
        <ListScrollContainer
          items={notifications.map((notification: any) => {
            return <UserNotificationListItemContainer notification={notification} key={notification.id} />;
          })}
        />
      </DetailViewContainer>
    </div>
  );
};

export default UserNotificationsContainer;
