import React, { FC, useMemo } from "react";
import CompanyHeaderRow from "@/views/home/projectList/CompanyHeaderRow";
import { useAppSelector } from "@/app/hooks";
import { makeSelectAllCompanyProjectIds, makeSelectCompanyById } from "@/app/store/companiesSlice";
import { EntityId } from "@reduxjs/toolkit";
import ProjectListItem from "@/features/projectList/projectListItem/ProjectListItem";
import CompanyContainerNoProjects from "@/views/home/projectList/CompanyContainerNoProjects";

interface Props {
  companyId: EntityId;
}

const CompanyContainer: FC<Props> = ({ companyId }) => {
  const selectCompanyById = useMemo(makeSelectCompanyById, []);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const selectAllCompanyProjectIds = useMemo(makeSelectAllCompanyProjectIds, []);
  const filteredProjectIds = useAppSelector((state) => selectAllCompanyProjectIds(state, companyId));

  return (
    <>
      {company && (
        <div className="mb-8" key={company.id}>
          <CompanyHeaderRow companyId={companyId} />
          {filteredProjectIds && (
            <div>
              {filteredProjectIds.map((id) => {
                return <ProjectListItem projectId={id} key={id} />;
              })}
              {filteredProjectIds.length < 1 && (
                <div className="flex flex-row justify-center">
                  <CompanyContainerNoProjects />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CompanyContainer;
