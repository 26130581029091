import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectAllFormBaseVersionRowsSorted } from "@/app/store/form/formBaseRowsSlice";
import FormRow from "@components/form/FormRow";
import { IFormBaseVersionRow } from "@/model/form/IFormBaseVersionRow";
import { FormRowFieldType, IFormBaseVersionRowField } from "@/model/form/IFormBaseVersionRowField";
import { classNames } from "@/utilities/jsUtilities";

interface Props {}

const FormBaseRowsPreview: FC<Props> = ({}) => {
  const baseVersionRows = useAppSelector(selectAllFormBaseVersionRowsSorted);

  const isProtocolTableRow = (row: IFormBaseVersionRow) => {
    return row.fields.some((field: IFormBaseVersionRowField) => field.fieldType === FormRowFieldType.PROTOCOL);
  };

  return (
    <div className="flex w-full flex-col pt-2">
      {baseVersionRows && baseVersionRows.length > 0 && (
        <div>
          {baseVersionRows.map((baseVersionRow, index) => {
            return (
              <div className={classNames(isProtocolTableRow(baseVersionRow) ? "-ml-1 px-0" : "px-14")} key={index}>
                <FormRow baseVersionRow={baseVersionRow} disabled={true} isPreview={true} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FormBaseRowsPreview;
