import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";

interface Props {
  isSelected?: boolean;
  onClick?: Function;
  children?: React.ReactNode;
  disabled?: boolean;
}

const BhChip: FC<Props> = ({ isSelected, onClick, children, disabled }) => {
  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    e.stopPropagation();
    onClick && onClick();
  };

  return (
    <div
      className={classNames(
        isSelected ? "bh-text-dark-jungle bh-bg-mint hover:bh-bg-mint-120" : "bh-bg-smoke bh-text-dark-ocean hover:bh-bg-pigeon-20",
        onClick && "cursor-pointer",
        disabled && "cursor-not-allowed opacity-50",
        "flex inline-flex w-auto flex-row items-center gap-1.5 rounded-full py-2 px-3 font-semibold"
      )}
      onClick={(e) => handleClick(e)}
    >
      {isSelected && <FontAwesomeIcon icon={faCheck} />}
      {children}
    </div>
  );
};

export default React.memo(BhChip);
