import React, { FC, startTransition, useCallback, useEffect, useState } from "react";
import TaskInfoField from "@components/task/modal/TaskInfoField";
import { ITask } from "@/model/taskBoard/ITask";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons/faUserCircle";
import { getUserFullName, IUser } from "@/model/IUser";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import useInputIsActive from "@hooks/useInputIsActive";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { useAppSelector } from "@/app/hooks";
import { selectTaskBoardUsersByTaskBoardId } from "@/app/store/taskBoardsSlice";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { getUserFromFullName } from "@/utilities/jsUtilities";
import { selectCurrentUser } from "@/app/store/userSlice";
import { isTaskPersonal } from "@/utilities/taskBoardUtil";
import { useTranslation } from "react-i18next";
import { selectOpenedTaskDisabled } from "@/app/store/tasksSlice";
import BhFixedDropdownParent from "@components/dropdown/BhFixedDropdownParent";

interface Props {
  task: ITask;
  onSelect: Function;
}

const TaskAssigneeInfoField: FC<Props> = ({ task, onSelect }) => {
  const { t } = useTranslation();
  const { isActive, toggleIsActive, inputRef } = useInputIsActive();
  const taskBoardUsers = useAppSelector((state) => selectTaskBoardUsersByTaskBoardId(state, task.taskBoardId));
  const assignee = task.assignee ? { ...getUserFromFullName(task.assigneeFullName), ...{ id: task.assignee } } : ({} as IUser);
  const currentUser = useAppSelector(selectCurrentUser);
  const isTaskDisabled = useAppSelector(selectOpenedTaskDisabled);
  const isPersonalTask = isTaskPersonal(task, currentUser);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownValues, setDropdownValues] = useState(taskBoardUsers);

  useEffect(() => {
    setIsDropdownOpen(isActive);
  }, [isActive]);

  useEffect(() => {
    setDropdownValues(taskBoardUsers);
  }, [taskBoardUsers]);

  const selectAssignee = (user: ISimpleValidUserAuthority) => {
    const changedObject = { assignee: user.userEntityId, assigneeFullName: getUserFullName(user) };
    onSelect(changedObject);
    setDropdownValues(taskBoardUsers);
  };

  const onChange = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault();
      startTransition(() => {
        const inputValue = e.target.value;
        if (inputValue.length > 0) {
          setIsDropdownOpen(true);
        }
        setDropdownValues(
          taskBoardUsers.filter((user) => {
            return (user.firstName + " " + user.lastName).toLowerCase().includes(inputValue.toLowerCase());
          })
        );
      });
    },
    [taskBoardUsers]
  );

  if (isPersonalTask) return null;

  return (
    <div onClick={() => toggleIsActive(isTaskDisabled)} className="w-full">
      <TaskInfoField
        text={t("TASK.ASSIGNEE")}
        children={
          <div className="inline-block w-full" onBlur={() => toggleIsActive()}>
            {!isActive && assignee.id && (
              <div className="bh-text-deep-ocean flex min-h-[40px] w-full flex-row items-center font-bold">
                <BhUserIconWithName user={assignee} />
              </div>
            )}
            {isActive && (
              <div>
                <input
                  ref={inputRef}
                  className="l-h-20 bh-border-pigeon-40 placeholder:bh-text-deep-ocean-40 hover:bh-border-pigeon-70 focus:bh-border-pigeon-70 disabled:bh-text-deep-ocean-40 disabled:bh-bg-smoke disabled:bh-border-smoke w-full rounded border p-2 focus-visible:outline-0"
                  onChange={onChange}
                />
                {isDropdownOpen && (
                  <div onMouseDown={(e) => e.preventDefault()}>
                    <BhFixedDropdownParent>
                      <BhDropdownMenu type={BhDropdownTypeEnum.USER} values={dropdownValues} onSelect={selectAssignee} numberOfItemsDisplayed={10} customDropdown={true} widthClass={"w-full"} />
                    </BhFixedDropdownParent>
                  </div>
                )}
              </div>
            )}
          </div>
        }
        valueExists={"id" in assignee}
        icon={faUserCircle}
        trailingIcon={!isActive ? ("id" in assignee ? faCaretDown : faPlus) : undefined}
        disabled={isTaskDisabled}
      />
    </div>
  );
};

export default TaskAssigneeInfoField;
