import React, { FC } from "react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { useTranslation } from "react-i18next";
import BhWarningButton from "@components/buttons/BhWarningButton";

interface Props {
  cancelButtonText?: string;
  confirmButtonText?: string;
  onCancel?: Function;
  onConfirm?: Function;
  submitForm?: string;
  confirmDisabled?: boolean;
  isWarningButton?: boolean;
}

const BhModalFooter: FC<Props> = ({ cancelButtonText, confirmButtonText, onCancel, onConfirm, submitForm, confirmDisabled, isWarningButton }) => {
  const { t } = useTranslation();
  const confirmButtonProps = { onClick: onConfirm, submitForm: submitForm, disabled: confirmDisabled };

  return (
    <div className="flex flex-row items-center">
      {onCancel && <BhTextOnlyButton buttonProps={{ onClick: onCancel }}>{cancelButtonText || t("GLOBAL.CANCEL")}</BhTextOnlyButton>}
      {(onConfirm || submitForm) && !isWarningButton && <BhPrimaryButton buttonProps={confirmButtonProps}>{confirmButtonText || t("GLOBAL.SAVE")}</BhPrimaryButton>}
      {(onConfirm || submitForm) && isWarningButton && <BhWarningButton buttonProps={confirmButtonProps}>{confirmButtonText || t("GLOBAL.SAVE")}</BhWarningButton>}
    </div>
  );
};

export default BhModalFooter;
