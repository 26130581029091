import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import ChecklistsAuthoritiesModal from "@components/checklists/settings/projectSettings/checklistsAuthoritiesModal/ChecklistsAuthoritiesModal";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhSelectInputFilter from "@components/filters/BhSelectInputFilter";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyChecklistBases, selectUsernamesForChecklistFilter } from "@/app/store/checklist/checklistsSlice";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import NewChecklistBaseModal from "@components/checklists/settings/companySettings/NewChecklistBaseModal";
import { selectCurrentProjectCompanyId, selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { BhSectionMessageSomethingElse } from "@components/sectionMessage/BhSectionMessages";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons/faCircleInfo";
import { faCog } from "@fortawesome/pro-regular-svg-icons/faCog";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { isCurrentUserCompanyChecklistsManager } from "@/app/store/userSlice";
import { Link } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  filter: any;
  setFilter: Dispatch<SetStateAction<any>>;
}

const ProjectChecklistSettingsHeader: FC<Props> = ({ filter, setFilter }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const companyId = useAppSelector(selectCurrentProjectCompanyId);
  const usernamesForFilter = useAppSelector(selectUsernamesForChecklistFilter);
  const checklistBases = useAppSelector(selectCompanyChecklistBases);
  const isCompanyChecklistManager = useAppSelector((state) => isCurrentUserCompanyChecklistsManager(state, companyId));
  const [showAuthoritiesModal, setShowAuthoritiesModal] = useState(false);
  const [showNewChecklistBaseModal, setShowNewChecklistBaseModal] = useState(false);

  const baseTypeFilterValues = [
    { name: t("CHECKLIST.PROJECT_TEMPLATES"), showProjectTemplatesOnly: true, showCompanyTemplatesOnly: false },
    {
      name: t("CHECKLIST.COMPANY_TEMPLATES"),
      showProjectTemplatesOnly: false,
      showCompanyTemplatesOnly: true
    }
  ];

  const baseCategoryFilterValues = [
    { categoryName: "SAFETY", translation: t("CHECKLIST.CATEGORY_TAG.SAFETY") },
    { categoryName: "QUALITY", translation: t("CHECKLIST.CATEGORY_TAG.QUALITY") },
    { categoryName: "HANDOVERS", translation: t("CHECKLIST.CATEGORY_TAG.HANDOVERS") },
    { categoryName: "OTHER", translation: t("CHECKLIST.CATEGORY_TAG.OTHER") }
  ];

  const onChange = (search: any) => {
    setFilter({ ...filter, searchString: search.type });
  };

  const onUsernameFilterSelect = (username: string) => {
    setFilter({ ...filter, username: username });
  };

  const onUsernameFilterReset = () => {
    setFilter({ ...filter, username: "" });
  };

  const onBaseTypeFilterSelect = (typeObject: any) => {
    setFilter({ ...filter, ...{ showProjectTemplatesOnly: typeObject.showProjectTemplatesOnly, showCompanyTemplatesOnly: typeObject.showCompanyTemplatesOnly } });
  };

  const onBaseTypeFilterReset = () => {
    setFilter({ ...filter, ...{ showProjectTemplatesOnly: false, showCompanyTemplatesOnly: false } });
  };

  const onBaseCategoryFilterSelect = (categoryObject: any) => {
    setFilter({ ...filter, ...{ category: categoryObject } });
  };

  const onBaseCategoryFilterReset = () => {
    setFilter({ ...filter, ...{ category: null } });
  };

  return (
    <div className="flex flex-col">
      <BhSectionMessageSomethingElse iconClasses="pl-2" small={true} icon={faCircleInfo}>
        <div className="flex flex-row items-center justify-between pl-1">
          <p>
            <b>{t("CHECKLIST.PROJECT_SETTINGS.COMPANY_BASE_INFO.HEADER")}</b> {t("CHECKLIST.PROJECT_SETTINGS.COMPANY_BASE_INFO.BODY")}
          </p>
          {isCompanyChecklistManager && companyId && (
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_BASE_URL + `/company/${companyId}/checklists`}>
              <BhTextOnlyButton icon={faCog}>{t("CHECKLIST.COMPANY_SETTINGS")}</BhTextOnlyButton>
            </Link>
          )}
        </div>
      </BhSectionMessageSomethingElse>
      <div className="my-4 flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-x-4">
          <BhSearchInputWBG property={"type"} onChangeCallback={onChange} inputClasses={"w-72"} placeholder={t("CHECKLIST.SEARCH.TEMPLATE") as string} />
          <BhSelectInputFilter
            values={baseCategoryFilterValues}
            textProperty={"translation"}
            currentValue={filter.category && t(filter.category.translation)}
            onSelect={onBaseCategoryFilterSelect}
            placeholder={t("CHECKLIST_BASE.CATEGORY")}
            onReset={onBaseCategoryFilterReset}
          />
          <BhSelectInputFilter
            values={usernamesForFilter}
            currentValue={filter.username}
            onSelect={onUsernameFilterSelect}
            placeholder={t("CHECKLIST_BASE.FILTER.CREATED_BY")}
            onReset={onUsernameFilterReset}
          />
          <BhSelectInputFilter
            values={baseTypeFilterValues}
            currentValue={filter.showProjectTemplatesOnly ? (t("CHECKLIST.PROJECT_TEMPLATES") as string) : filter.showCompanyTemplatesOnly ? (t("CHECKLIST.COMPANY_TEMPLATES") as string) : ""}
            onSelect={onBaseTypeFilterSelect}
            placeholder={t("CHECKLIST.CHECKLIST_TYPE")}
            onReset={onBaseTypeFilterReset}
            textProperty={"name"}
          />
        </div>
        <div className="flex flex-row items-center">
          <BhSecondaryButton buttonProps={{ onClick: () => setShowAuthoritiesModal(true), disabled: !checklistBases || checklistBases.length === 0 }}>
            {t("CHECKLIST.WORK_GROUP.AUTHORITIES")}
          </BhSecondaryButton>
          <BhPrimaryButton icon={faPlus} buttonProps={{ onClick: () => setShowNewChecklistBaseModal(true) }}>
            {t("CHECKLIST.NEW_BASE")}
          </BhPrimaryButton>
        </div>
        {showAuthoritiesModal && <ChecklistsAuthoritiesModal setIsShown={setShowAuthoritiesModal} />}
        {showNewChecklistBaseModal && <NewChecklistBaseModal setIsShown={setShowNewChecklistBaseModal} companyId={companyId} projectId={projectId} />}
      </div>
    </div>
  );
};

export default ProjectChecklistSettingsHeader;
