import React, { FC, ReactElement } from "react";
import { FormRowFieldType, IFormBaseVersionRowField } from "@/model/form/IFormBaseVersionRowField";
import FormTextField from "@components/form/fields/FormTextField";
import FormDateField from "@components/form/fields/FormDateField";
import FormTimeField from "@components/form/fields/FormTimeField";
import FormSeparatorField from "@components/form/fields/FormSeparatorField";
import FormWeatherField from "@components/form/fields/weatherField/FormWeatherField";
import FormProtocolField from "@components/form/fields/protocolField/FormProtocolField";
import FormPeopleField from "@components/form/fields/peopleField/FormPeopleField";
import FormWysiwygField from "@components/form/fields/FormWysiwygField";
import FormAttachmentsField from "@components/form/fields/attachmentsField/FormAttachmentsField";
import FormTableField from "@components/form/fields/tableField/FormTableField";
import FormMntTableField from "@components/form/fields/oldCustomFields/mntTables/FormMntTableField";
import FormTooplaanTableField from "@components/form/fields/oldCustomFields/tooplaan/FormTooplaanTableField";
import FormTextEditorField from "@components/form/fields/oldCustomFields/FormTextEditorField";
import FormScmTableField from "@components/form/fields/oldCustomFields/scm/FormScmTableField";
import FormScmTasksField from "@components/form/fields/oldCustomFields/scm/FormScmTasksField";
import FormAttachmentsWithDatesField from "@components/form/fields/oldCustomFields/FormAttachmentsWithDatesField";
import FormUploadField from "@components/form/fields/oldCustomFields/FormUploadField";

interface Props {
  field: IFormBaseVersionRowField;
  disabled: boolean;
  isPreview?: boolean;
  dataSaveCallback?: Function;
  nestedDataSaveCallback?: Function;
  nestedDataAddCallback?: Function;
  nestedDataRemoveCallback?: Function;
}

const FormRowFieldContainer: FC<Props> = ({ field, disabled, isPreview, nestedDataRemoveCallback, nestedDataSaveCallback, nestedDataAddCallback, dataSaveCallback }) => {
  const fieldTypeToElementMap: Record<FormRowFieldType, ReactElement> = {
    ATTACHMENT: <FormAttachmentsField field={field} disabled={disabled} isPreview={isPreview} saveCallback={dataSaveCallback} />,
    DATE: <FormDateField field={field} disabled={disabled} isPreview={isPreview} saveCallback={dataSaveCallback} />,
    TABLE: (
      <FormTableField
        field={field}
        disabled={disabled}
        isPreview={isPreview}
        nestedDataSaveCallback={nestedDataSaveCallback}
        nestedDataAddCallback={nestedDataAddCallback}
        nestedDataRemoveCallback={nestedDataRemoveCallback}
      />
    ),
    PEOPLE: (
      <FormPeopleField
        field={field}
        disabled={disabled}
        isPreview={isPreview}
        addCallback={nestedDataAddCallback}
        saveCallback={nestedDataSaveCallback}
        removeNestedDataCallback={nestedDataRemoveCallback}
      />
    ),
    PROTOCOL: (
      <FormProtocolField field={field} disabled={disabled} isPreview={isPreview} saveCallback={nestedDataSaveCallback} addCallback={nestedDataAddCallback} removeCallback={nestedDataRemoveCallback} />
    ),
    SEPARATOR: <FormSeparatorField />,
    TEXT: <FormTextField field={field} disabled={disabled} isPreview={isPreview} saveCallback={dataSaveCallback} />,
    WEATHER: (
      <FormWeatherField
        field={field}
        disabled={disabled}
        isPreview={isPreview}
        nestedDataSaveCallback={nestedDataSaveCallback}
        nestedDataAddCallback={nestedDataAddCallback}
        nestedDataRemoveCallback={nestedDataRemoveCallback}
      />
    ),
    TIME: <FormTimeField field={field} disabled={disabled} isPreview={isPreview} saveCallback={dataSaveCallback} />,
    WYSIWYG: <FormWysiwygField field={field} disabled={disabled} isPreview={isPreview} />,
    // Old components that only exist in some old forms and can't be added by users
    MNT_MP_TABLE_FIELD: (
      <FormMntTableField
        field={field}
        saveCallback={dataSaveCallback}
        addRowCallback={nestedDataAddCallback}
        removeRowCallback={nestedDataRemoveCallback}
        saveRowCallback={nestedDataSaveCallback}
        disabled={disabled}
        isPreview={isPreview}
      />
    ),
    TOOPLAAN_TABLE: (
      <FormTooplaanTableField
        field={field}
        saveCallback={dataSaveCallback}
        saveWeekCallback={nestedDataSaveCallback}
        addWeekCallback={nestedDataAddCallback}
        removeWeekCallback={nestedDataRemoveCallback}
        disabled={disabled}
        isPreview={isPreview}
      />
    ),
    TEXT_EDITOR: <FormTextEditorField field={field} saveCallback={dataSaveCallback} disabled={disabled} />,
    SCM_TABLE: <FormScmTableField saveCallback={dataSaveCallback} disabled={disabled} field={field} isPreview={isPreview} />,
    SCM_TASKS: (
      <FormScmTasksField field={field} disabled={disabled} removeCallback={nestedDataRemoveCallback} addCallback={nestedDataAddCallback} saveCallback={nestedDataSaveCallback} isPreview={isPreview} />
    ),
    ATTACHMENTS_WITH_DATES: <FormAttachmentsWithDatesField field={field} saveCallback={dataSaveCallback} disabled={disabled} isPreview={isPreview} />,
    UPLOAD: <FormUploadField field={field} saveCallback={dataSaveCallback} disabled={disabled} isPreview={isPreview} />
  };

  return <div className="w-full p-2">{fieldTypeToElementMap[field.fieldType]}</div>;
};

export default FormRowFieldContainer;
