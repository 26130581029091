import React, { FC, useRef } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  allFileEntitiesUnSelected,
  authoritiesModalFileEntitySet,
  directoryInfoBarTabSet,
  fetchFileEntityAsync,
  fileEntitySelected,
  selectFilesSelectedInCurrentDirectory,
  selectOneFileSelected,
  setDirectoryModalsOpen
} from "@/app/store/filesSlice";
import { faArrowUpToLine } from "@fortawesome/pro-regular-svg-icons/faArrowUpToLine";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { selectFolderById } from "@/app/store/foldersSlice";
import { EntityId } from "@reduxjs/toolkit";
import { IAuthorityBoolean } from "@/model/IUser";
import { useTranslation } from "react-i18next";
import BhTooltip from "@components/BhTooltip";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import BhElementWithBadge from "@components/badge/BhElementWithBadge";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import { faMessage } from "@fortawesome/pro-regular-svg-icons/faMessage";
import { faClockRotateLeft } from "@fortawesome/pro-regular-svg-icons/faClockRotateLeft";
import { DirectoryFileInfoBarTab } from "@/views/home/project/detail/directory/fileInfoBar/DirectoryFileInfoBar";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BimDirectoryFileUpload from "@/views/home/project/detail/directory/bimDirectory/BimDirectoryFileUpload";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons/faCirclePlus";
import BimDirectoryRevisionUpload from "@/views/home/project/detail/directory/bimDirectory/BimDirectoryRevisionUpload";

interface Props {
  currentDirId: EntityId;
  privilegesForDirectory: IAuthorityBoolean;
}

const BimDirectoryFileListHeaderMenu: FC<Props> = ({ currentDirId, privilegesForDirectory }) => {
  const { t } = useTranslation();
  const currentDirectory = useAppSelector((state) => selectFolderById(state, currentDirId));

  const fileInputRef = useRef(null);
  const revisionInputRef = useRef(null);

  const projectId = useAppSelector(selectCurrentProjectId);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const selectedFileEntitiesInDirectory = useAppSelector(selectFilesSelectedInCurrentDirectory);
  const oneFileSelected = useAppSelector(selectOneFileSelected);

  const selectedFile = selectedFileEntitiesInDirectory.length === 1 && oneFileSelected && selectedFileEntitiesInDirectory[0];
  const filesSelected = selectedFileEntitiesInDirectory.length > 0;

  const dispatch = useAppDispatch();

  const showProjectLogButtonForCurrentDir = isProjectAdmin || privilegesForDirectory.isRead;

  const selectView = (value: DirectoryFileInfoBarTab) => {
    dispatch(directoryInfoBarTabSet(value));
  };

  const openFileHistoryModal = () => {
    dispatch(setDirectoryModalsOpen({ modal: "fileHistoryModal", value: true }));
  };

  const openCurrentDirectoryHistoryModal = () => {
    if (showProjectLogButtonForCurrentDir) {
      dispatch(allFileEntitiesUnSelected());
      dispatch(fetchFileEntityAsync(currentDirId));
      dispatch(setDirectoryModalsOpen({ modal: "fileHistoryModal", value: true }));
    }
  };

  const openCurrentDirectoryAuthoritiesModal = () => {
    if (!isProjectAdmin) return;
    dispatch(allFileEntitiesUnSelected());
    dispatch(fileEntitySelected({ ids: [currentDirId], selected: true }));
    dispatch(fetchFileEntityAsync(currentDirId));
    dispatch(setDirectoryModalsOpen({ modal: "authoritiesModal", value: true }));
    dispatch(authoritiesModalFileEntitySet(currentDirId));
  };

  return (
    <div className="mt-1 mb-5 flex flex-row flex-wrap justify-between">
      <div className="flex w-full flex-row justify-between">
        <BimDirectoryFileUpload fileInputRef={fileInputRef} />
        <BimDirectoryRevisionUpload fileInputRef={revisionInputRef} />
        {privilegesForDirectory?.isWrite && (
          <div className="-ml-1 flex flex-row items-center gap-x-2">
            <BhPrimaryButton
              icon={faArrowUpToLine}
              buttonProps={{
                onClick: () => {
                  // @ts-ignore
                  fileInputRef.current.click();
                }
              }}
            >
              {t("BIM.UPLOAD_FILES")}
            </BhPrimaryButton>
            <BhTextOnlyButton
              icon={faCirclePlus}
              buttonProps={{
                onClick: () => {
                  // @ts-ignore
                  revisionInputRef.current.click();
                }
              }}
            >
              {t("BIM.NEW_REVISION")}
            </BhTextOnlyButton>
          </div>
        )}
        <div className="flex flex-row items-center">
          {privilegesForDirectory?.isAdmin && (
            <div className="-ml-1">
              <BhTextOnlyButton icon={faUsers} buttonProps={{ onClick: openCurrentDirectoryAuthoritiesModal }}>
                {t("BIM.AUTHORITIES")}
              </BhTextOnlyButton>
            </div>
          )}
          <div className="flex flex-row justify-end">
            {!filesSelected && currentDirectory && (
              <div className="flex flex-row space-x-4">
                {showProjectLogButtonForCurrentDir && (
                  <BhTooltip body={t("DIRECTORY.LOG")}>
                    <BhIconButton icon={faClockRotateLeft} buttonProps={{ onClick: openCurrentDirectoryHistoryModal }} />
                  </BhTooltip>
                )}
              </div>
            )}
            {selectedFile && (
              <div className="flex flex-row space-x-4">
                <BhTooltip body={t("MODAL.FILE_LOG_BUTTON")}>
                  <BhIconButton icon={faClockRotateLeft} buttonProps={{ onClick: openFileHistoryModal }} />
                </BhTooltip>
                <BhTooltip body={t("TASK.COMMENTS")}>
                  <BhElementWithBadge badge={selectedFile && selectedFile.commentCount > 0 && <BhBadge type={BhBadgeType.DARK}>{selectedFile.commentCount}</BhBadge>}>
                    <BhIconButton icon={faMessage} buttonProps={{ onClick: () => selectView(DirectoryFileInfoBarTab.COMMENTS) }} />
                  </BhElementWithBadge>
                </BhTooltip>
                <BhTooltip body={t("MODAL.INFORMATION.TITLE")}>
                  <BhIconButton icon={faInfoCircle} buttonProps={{ onClick: () => selectView(DirectoryFileInfoBarTab.INFO) }} />
                </BhTooltip>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BimDirectoryFileListHeaderMenu;
