import React, { FC, SetStateAction, useState } from "react";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhPigeon20Separator from "@components/separator/BhPigeon20Separator";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { IFileTreeTemplate } from "@/model/files/IFileTreeTemplate";
import { EntityId } from "@reduxjs/toolkit";
import { IProject } from "@/model/IProject";
import { fetchProjectFileTreeJson } from "@/api/fileTreeAPI";
import { useTranslation } from "react-i18next";
import ProjectTreeImportModal from "@/views/home/company/detail/companySettingsProjectTree/ProjectTreeImportModal";

interface Props {
  fileTreeTemplate: IFileTreeTemplate;
  setFileTreeTemplate: React.Dispatch<SetStateAction<IFileTreeTemplate>>;
  companyId: EntityId;
}

const ProjectTreeSettingsModalSidebar: FC<Props> = ({ fileTreeTemplate, setFileTreeTemplate, companyId }) => {
  const { t } = useTranslation();
  const [isImportModalShown, setIsImportModalShown] = useState<boolean>(false);

  const handleNameChange = (changedValue: IFileTreeTemplate) => {
    setFileTreeTemplate((prevState) => {
      return { ...prevState, ...changedValue };
    });
  };

  const handleImportCancel = () => {
    setIsImportModalShown(false);
  };

  const handleImportSelect = async (changedValue: { project: IProject }) => {
    const { project } = changedValue;
    const response = await fetchProjectFileTreeJson(project.id, fileTreeTemplate.type);
    setFileTreeTemplate({ ...fileTreeTemplate, tree: response.tree });
    setIsImportModalShown(false);
  };

  return (
    <>
      <div className="w-1/3 p-8">
        <BhInputStackedLabel
          initialValue={fileTreeTemplate.name}
          property="name"
          label={t("GLOBAL.ITEM_NAME")}
          onChangeCallback={handleNameChange}
          onBlurAction={handleNameChange}
          debounceLength={900}
          required={true}
        />
        <BhPigeon20Separator classes="mt-10 mb-8" />
        <p>{t("COMPANY.SETTINGS.PROJECT.TREE.DOCUMENTS_MODAL.BODY")}</p>
        <BhSecondaryButton buttonProps={{ classes: "mt-2.5", onClick: () => setIsImportModalShown(true) }}>{t("COMPANY.SETTINGS.PROJECT.TREE.IMPORT_HEADER")}</BhSecondaryButton>
      </div>

      <ProjectTreeImportModal isShown={isImportModalShown} setIsShown={setIsImportModalShown} companyId={companyId} handleCancel={handleImportCancel} handleSelect={handleImportSelect} />
    </>
  );
};

export default ProjectTreeSettingsModalSidebar;
