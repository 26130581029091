import React, { FC, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectContactRightsModalOpen, selectEditableContact, setContactRightsModalOpen, setEditableContact } from "@/app/store/project/projectContactsSlice";
import { useTranslation } from "react-i18next";
import { fetchContactStatus } from "@/api/contactAPI";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { IContact } from "@/model/IContact";
import ContactAuthoritiesForWorkGroupUser from "@/views/home/project/detail/users/contacts/contactRightsModal/ContactAuthoritiesForWorkGroupUser";
import { ContactStatus } from "@/model/contacts/ContactStatus";
import BhModalFooter from "@components/modal/BhModalFooter";

const ContactRightsModal: FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [contactStatus, setContactStatus] = useState<ContactStatus>(ContactStatus.WORKGROUP_USER);
  const projectId = useAppSelector(selectCurrentProjectId);
  const editableContact = useAppSelector(selectEditableContact);
  const rightsModalOpen = useAppSelector(selectContactRightsModalOpen);
  const dispatch = useAppDispatch();

  useEffect(() => {
    rightsModalOpen &&
      fetchContactStatus(projectId, editableContact)
        .then((status) => setContactStatus(status.value))
        .then(() => setIsLoading(false));
  }, []);

  const handleClose = (value: boolean) => {
    dispatch(setContactRightsModalOpen(value));
    if (!value) dispatch(setEditableContact({} as IContact));
  };

  const modalContent: Record<ContactStatus, React.ReactElement> = {
    [ContactStatus.PROJECT_ADMIN]: <div className="p-8">{`${t("CONTACT.IS_SET_TO_ADMIN")}`}</div>,
    [ContactStatus.WORKGROUP_USER]: <ContactAuthoritiesForWorkGroupUser isContainerLoading={isLoading} />,
    [ContactStatus.NOT_BAUHUB_USER]: <div className="p-8">{`${t("CONTACT.IS_SET_TO_ADMIN")}`}</div>
  };

  const showModalFooter = contactStatus === ContactStatus.PROJECT_ADMIN || contactStatus === ContactStatus.NOT_BAUHUB_USER;
  const modalSize = contactStatus === ContactStatus.WORKGROUP_USER ? "4xl" : "md";

  return (
    <BhModal
      isShown={rightsModalOpen}
      setIsShown={handleClose}
      size={modalSize}
      header={
        <h2>
          {editableContact.firstName} {editableContact.lastName} {`${t("PARTIES.ACCESSES").toLowerCase()}`}
        </h2>
      }
      children={!isLoading && modalContent[contactStatus]}
      footer={showModalFooter && <BhModalFooter onCancel={() => handleClose(false)} cancelButtonText={`${t("GLOBAL.CLOSE")}`} />}
    />
  );
};

export default ContactRightsModal;
