import React, { FC } from "react";
import { scaleOrdinal } from "@visx/scale";
import { LegendOrdinal } from "@visx/legend";


export interface IBhChartLegendData {
  label: string;
  color: string;
}

interface Props {
  legendData: Array<IBhChartLegendData>;
  legendTitle?: string;
}

const BhChartLegend: FC<Props> = ({ legendTitle, legendData }) => {

  const colorScale = scaleOrdinal<string>({
    domain: legendData.map((d) => d.label),
    range: legendData.map((d) => d.color)
  });

  return (
    <div className="flex flex-col mt-8 w-1/4">
      <h2>{legendTitle}</h2>
      <LegendOrdinal scale={colorScale}>
        {(labels) => (
          <div className="flex flex-col gap-2">
            {labels.map((label) => (
              <div
                className="flex items-center mr-2 text-sm"
                key={label.text}
              >
                <div
                  className="w-2 h-2 rounded-full mr-1 border-2"
                  style={{
                    borderColor: label?.value?.toString()
                  }}
                />
                <span>{label.text}</span>
              </div>
            ))}
          </div>
        )}
      </LegendOrdinal>
    </div>
  );
};

export default BhChartLegend;