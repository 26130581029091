import React, { FC, ReactElement } from "react";
import { CompanyStatus } from "@/model/ICompany";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";

interface Props {
  status: CompanyStatus;
}


const BhBOCompanyStatusTag: FC<Props> = ({ status }) => {

  const statusMap: Record<CompanyStatus, ReactElement> = {
    [CompanyStatus.TRIAL]: <BhTag type={BhTagType.MUSTAND} classes="!py-1 -mr-2">On trial</BhTag>,
    [CompanyStatus.TRIAL_EXPIRED]: <BhTag type={BhTagType.FAILED} classes="!py-1 -mr-2">Trial expired</BhTag>,
    [CompanyStatus.INACTIVE]: <BhTag type={BhTagType.ARHIVEERITUD} classes="!py-1 -mr-2">Inactive</BhTag>,
    [CompanyStatus.ACTIVE]: <BhTag type={BhTagType.PROJEKT_TOOS} classes="!py-1 -mr-2">Active</BhTag>
  };

  if (!status) return null;

  return statusMap[status];
};

export default BhBOCompanyStatusTag;
