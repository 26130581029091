import React, { FC } from "react";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import { IUser } from "@/model/IUser";

interface Props {
  signature: ISignatureHolder;
  reducedOpacity?: boolean;
  size?: "sm";
}

const BhUserIconWithNameForSignature: FC<Props> = ({ signature, reducedOpacity, size }) => {
  const user = { firstName: signature.givenName, lastName: signature.surname, username: signature.username, userExists: !signature.publicInvite } as IUser;

  return <BhUserIconWithName user={user} reducedOpacity={reducedOpacity} size={size} />;
};

export default BhUserIconWithNameForSignature;
