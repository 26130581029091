import React, { FC } from "react";
import BhTableRow from "@components/table/BhTableRow";

interface Props {
  numberOfDisplayedItems: number;
}

const CompanySettingsUsersAdminSkeleton: FC<Props> = ({ numberOfDisplayedItems }) => {
  return (
    <>
      {[...Array(numberOfDisplayedItems)].map((e, i) => {
        return (
          <BhTableRow key={i} classes={"w-full border-b bh-border-pigeon-40"}>
            <td>
              <div className="bh-bg-pigeon-50 h-5 w-2/5 animate-pulse rounded" />
            </td>
            <td>
              <div className="bh-bg-pigeon-50 h-5 w-52 animate-pulse rounded" />
            </td>
            <td>
              <div className="bh-bg-pigeon-50 h-5 w-16 animate-pulse rounded" />
            </td>
            <td>
              <div className="bh-bg-pigeon-50 h-5 w-16 animate-pulse rounded" />
            </td>
          </BhTableRow>
        );
      })}
    </>
  );
};

export default CompanySettingsUsersAdminSkeleton;