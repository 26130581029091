import React, { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  clearContactCompanyFilter,
  clearContactWorkGroupFilter,
  clearContactWorkGroupTypeFilter,
  companyToggledInContactsFilter,
  selectContactsFilterCompanyDropdownValues,
  selectContactsFilterWorkGroupDropdownValues,
  selectCurrentProjectContactsFilter,
  setContactsCompanySearchFilter,
  setContactsFilter,
  setContactsWorkGroupSearchFilter,
  workGroupToggledInContactsFilter,
  workGroupTypeToggledInContactsFilter
} from "@/app/store/project/projectContactsSlice";
import { IContactsCompanyFilter, IContactsFilter, IContactsWorkGroupDropdownFilter, IContactsWorkGroupFilter } from "@/model/contacts/IContactsFilter";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhFilter from "@components/filters/BhFilter";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useTranslation } from "react-i18next";
import {
  companyClearedInUsersFilterInSearch,
  companyFilterSearchStringSetInSearch,
  selectContactsFilterCompanyDropdownValuesInSearch,
  selectUsersFilterInSearch,
  userCompanyFilterToggledInSearch
} from "@/app/store/searchSlice";
import { WorkGroupType } from "@/model/IWorkGroup";
import BhFilterContainer from "@components/filters/BhFilterContainer";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  useSearchSliceActions?: boolean;
}

const ContactsFilter: FC<Props> = ({ useSearchSliceActions }) => {
  const { t } = useTranslation();
  const filter = useAppSelector(useSearchSliceActions ? selectUsersFilterInSearch : selectCurrentProjectContactsFilter);
  const companyDropdownFilterValues = useAppSelector(useSearchSliceActions ? selectContactsFilterCompanyDropdownValuesInSearch : selectContactsFilterCompanyDropdownValues);
  const workGroupDropdownFilterValues = useAppSelector(selectContactsFilterWorkGroupDropdownValues);
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));

  const showWorkGroupFilters = !useSearchSliceActions;
  const showSearchInput = !useSearchSliceActions;

  const onSearchQueryFilterChange = useCallback(
    (changedValue: IContactsFilter) => {
      const changedObject = { ...filter, ...changedValue };
      dispatch(setContactsFilter(changedObject));
    },
    [filter]
  );

  const onCompanyNameFilterChange = useCallback(
    (changedValue: IContactsCompanyFilter) => {
      const changedObject = { ...filter.company, ...changedValue };
      dispatch(useSearchSliceActions ? companyFilterSearchStringSetInSearch(changedObject) : setContactsCompanySearchFilter(changedObject));
    },
    [filter]
  );

  const onWorkGroupFilterChange = useCallback(
    (changedValue: IContactsWorkGroupDropdownFilter) => {
      dispatch(setContactsWorkGroupSearchFilter(changedValue));
    },
    [filter]
  );

  const onContactCompanyFilterReset = useCallback(() => {
    dispatch(useSearchSliceActions ? companyClearedInUsersFilterInSearch() : clearContactCompanyFilter());
  }, [filter]);

  const onContactWorkGroupFilterReset = useCallback(() => {
    dispatch(clearContactWorkGroupFilter());
  }, [filter]);

  const onContactWorkGroupTypeFilterReset = useCallback(() => {
    dispatch(clearContactWorkGroupTypeFilter());
  }, [filter]);

  const onCompanyFilterSelect = useCallback((changedValue: IContactsCompanyFilter) => {
    dispatch(useSearchSliceActions ? userCompanyFilterToggledInSearch(changedValue) : companyToggledInContactsFilter(changedValue));
  }, []);

  const isCompanySelectedInFilter = useCallback(
    (selectedCompany: IContactsCompanyFilter) => {
      return filter.company.selectedCompaniesInDropdown.some((company: IContactsCompanyFilter) => company.companyName === selectedCompany.companyName);
    },
    [filter]
  );

  const onWorkGroupFilterSelect = useCallback((workGroup: IContactsWorkGroupFilter) => {
    showWorkGroupFilters && dispatch(workGroupToggledInContactsFilter(workGroup));
  }, []);

  const isWorkGroupSelectedInFilter = useCallback(
    (selectedWorkGroup: IContactsWorkGroupFilter) => {
      return showWorkGroupFilters && filter.workGroup.selectedWorkGroupIds.some((workGroup: EntityId) => workGroup === selectedWorkGroup.workGroupId);
    },
    [filter]
  );

  const onWorkGroupTypeFilterSelect = useCallback((selectedValue: { workGroupType: WorkGroupType; text: WorkGroupType }) => {
    showWorkGroupFilters && dispatch(workGroupTypeToggledInContactsFilter(selectedValue.workGroupType));
  }, []);

  const partyTypes = [
    { workGroupType: WorkGroupType.SUBCONTRACTOR, text: `${t("PARTY.SUBCONTRACTOR")}` },
    { workGroupType: WorkGroupType.CLIENT, text: `${t("PARTY.CLIENT")}` }
  ];

  const numOfSelectedWorkGroups = filter.workGroup.selectedWorkGroupIds.filter((workGroupId: number) => workGroupId !== undefined).length;

  const isWorkGroupTypeSelected = useCallback(
    (value: { workGroupType: WorkGroupType; text: string }) => {
      return showWorkGroupFilters && filter.workGroupType.includes(value.workGroupType);
    },
    [filter]
  );

  return (
    <BhFilterContainer>
      {showSearchInput && (
        <div>
          <BhSearchInputWBG initialValue={filter.searchQuery} property="searchQuery" onChangeCallback={onSearchQueryFilterChange} placeholder={`${t("CONTACTS.SEARCH.PLACEHOLDER")}`} />
        </div>
      )}
      <BhFilter
        title={`${t("CONTACTS.FILTER.COMPANY")}`}
        values={companyDropdownFilterValues}
        onSelect={onCompanyFilterSelect}
        textProperty="companyName"
        search={true}
        searchInputSaveCallback={onCompanyNameFilterChange}
        selectedValues={filter.company.selectedCompaniesInDropdown.length}
        searchInputProperty="companySearchInput"
        dropdownSearchInitialValue={filter.company.companySearchInput}
        isSelected={isCompanySelectedInFilter}
        onReset={onContactCompanyFilterReset}
        closeOnItemClick={false}
      />
      {isProjectAdmin && showWorkGroupFilters && (
        <>
          <BhFilter
            title={`${t("CONTACTS.FILTER.USER_GROUP")}`}
            values={workGroupDropdownFilterValues}
            textProperty="workGroupName"
            onSelect={onWorkGroupFilterSelect}
            search={true}
            searchInputSaveCallback={onWorkGroupFilterChange}
            selectedValues={numOfSelectedWorkGroups}
            searchInputProperty="workGroupSearchInput"
            dropdownSearchInitialValue={filter.workGroup.workGroupSearchInput}
            isSelected={isWorkGroupSelectedInFilter}
            onReset={onContactWorkGroupFilterReset}
            closeOnItemClick={false}
          />
          <BhFilter
            title={`${t("CONTACTS.FILTER.USER_GROUP_TYPE")}`}
            values={partyTypes}
            textProperty="text"
            onSelect={onWorkGroupTypeFilterSelect}
            selectedValues={filter.workGroupType.length}
            isSelected={isWorkGroupTypeSelected}
            onReset={onContactWorkGroupTypeFilterReset}
          />
        </>
      )}
    </BhFilterContainer>
  );
};

export default ContactsFilter;
