import { BauhubPlan, ProjectStatus } from "@/model/IProject";
import { CompanyStatus } from "@/model/ICompany";
import { ProjectInvoiceType } from "@/model/IBillingInfo";

export interface IBoFilter {
  searchQuery: string;
  companiesFilter: ICompaniesFilter;
  invoicesFilter: IInvoicesFilter;
  companyProjectsFilter: ICompanyProjectsFilter;
  trialsFilter: ITrialsFilter;
}

export interface IBoPlanFilter {
  name: string;
  value: BauhubPlan;
  selected: boolean;
}

export interface IBoTrialStatusFilter {
  name: string;
  selected: boolean;
  value: CompanyStatus;
}

export interface IBoOrderTypeFilter {
  name: string;
  selected: boolean;
  value: string;
}

export interface IBoDateFilter {
  since: string;
  until: string;
}

export interface IBoPaidStatusFilter {
  name: string;
  value: boolean;
  selected: boolean;
}

export enum BhInvoiceOrderType {
  YEAR = "YEAR",
  MONTH = "MONTH",
  AGGREGATE = "AGGREGATE",
  CUSTOM = "CUSTOM",
  UPGRADE = "UPGRADE"
}

export interface IBoCompanyProjectStatusFilter {
  name: string;
  value: ProjectStatus;
  selected: boolean;
}

export interface IBoCompanyProjectInvoiceTypeFilter {
  name: string;
  value: ProjectInvoiceType;
  selected: boolean;
}

export interface ICompaniesFilter {
  status: Array<IBoTrialStatusFilter>;
  plans: Array<IBoPlanFilter>;
}

export interface IInvoicesFilter {
  plans: Array<IBoPlanFilter>;
  orderTypes: Array<IBoOrderTypeFilter>;
  status: Array<IBoPaidStatusFilter>;
  date: IBoDateFilter;
}

export interface ICompanyProjectsFilter {
  status: Array<IBoCompanyProjectStatusFilter>;
  created: string;
  invoiceType: Array<IBoCompanyProjectInvoiceTypeFilter>;
  showArchived: boolean;
}

export interface ITrialsFilter {
  date: IBoDateFilter;
  plans: Array<IBoPlanFilter>;
}

const initialPlanFilter: Array<IBoPlanFilter> = [
  { name: "Pro", value: BauhubPlan.PRO, selected: false },
  { name: "Light", value: BauhubPlan.LIGHT, selected: false }
];

const initialTrialStatusFilter: Array<IBoTrialStatusFilter> = [
  { name: "Trial", value: CompanyStatus.TRIAL, selected: false },
  { name: "Trial expired", value: CompanyStatus.TRIAL_EXPIRED, selected: false },
  { name: "Active", value: CompanyStatus.ACTIVE, selected: false },
  { name: "Inactive", value: CompanyStatus.INACTIVE, selected: false }
];

const initialOrderTypeFilter: Array<IBoOrderTypeFilter> = [
  { name: "Annual", value: BhInvoiceOrderType.YEAR, selected: false },
  { name: "Monthly", value: BhInvoiceOrderType.MONTH, selected: false },
  { name: "Aggregate", value: BhInvoiceOrderType.AGGREGATE, selected: false },
  { name: "Custom", value: BhInvoiceOrderType.CUSTOM, selected: false }
];

const initialPaidStatusFilter: Array<IBoPaidStatusFilter> = [
  { name: "Paid", value: true, selected: false },
  { name: "Unpaid", value: false, selected: false }
];

const initialCompanyProjectStatusFilter: Array<IBoCompanyProjectStatusFilter> = [
  { name: "In progress", value: ProjectStatus.IN_PROGRESS, selected: false },
  { name: "Archived", value: ProjectStatus.ARCHIVED, selected: false }
];

const initialCompanyProjectInvoiceTypeFilter: Array<IBoCompanyProjectInvoiceTypeFilter> = [
  { name: "Month", value: ProjectInvoiceType.MONTH, selected: false },
  { name: "Year", value: ProjectInvoiceType.YEAR, selected: false },
  { name: "Free", value: ProjectInvoiceType.FREE, selected: false }
];

export const initialCompaniesFilter: ICompaniesFilter = {
  status: initialTrialStatusFilter,
  plans: initialPlanFilter
};

const initialInvoicesFilter: IInvoicesFilter = {
  plans: initialPlanFilter,
  orderTypes: initialOrderTypeFilter,
  status: initialPaidStatusFilter,
  date: {} as IBoDateFilter
};

const initialCompanyProjectsFilter: ICompanyProjectsFilter = {
  status: initialCompanyProjectStatusFilter,
  created: "",
  invoiceType: initialCompanyProjectInvoiceTypeFilter,
  showArchived: false
};

const initialTrialsFilter: ITrialsFilter = {
  date: {} as IBoDateFilter,
  plans: initialPlanFilter
};

export const initialFilter: IBoFilter = {
  companiesFilter: initialCompaniesFilter,
  invoicesFilter: initialInvoicesFilter,
  companyProjectsFilter: initialCompanyProjectsFilter,
  trialsFilter: initialTrialsFilter
} as IBoFilter;
