import React, { FC, useState } from "react";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import BhIconButton from "@components/buttons/BhIconButton";
import { faMinus } from "@fortawesome/pro-regular-svg-icons/faMinus";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/form/formSlice";
import { generateMockScmData } from "@/utilities/formUtilities";

interface Props {
  field: any;
  saveCallback?: Function;
  disabled: boolean;
  isPreview?: boolean;
}

const FormScmTableField: FC<Props> = ({ field, saveCallback, disabled, isPreview }) => {
  const { t } = useTranslation();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const fieldValue = useAppSelector((state) => (!isPreview ? selectValueByPropertyFromCurrentFormData(state, field.property) : generateMockScmData()));
  const totalPlusFromData = useAppSelector((state) => (!isPreview ? selectValueByPropertyFromCurrentFormData(state, "totalPlusCount") : 0));
  const totalMinusFromData = useAppSelector((state) => (!isPreview ? selectValueByPropertyFromCurrentFormData(state, "totalMinusCount") : 0));
  const totalPlus = totalPlusFromData || 0;
  const totalMinus = totalMinusFromData || 0;

  const addCount = async (key: string, property: string) => {
    setButtonsDisabled(true);
    const changedFieldValue = { ...fieldValue, ...{ ...fieldValue, [key]: { ...fieldValue[key], [property]: fieldValue[key][property] + 1 } } };
    saveCallback && (await saveCallback({ value: { ...fieldValue[key], ...{ [property]: fieldValue[key][property] + 1 } } }, "count." + key));
    await calculateAndSaveTotals(changedFieldValue);
    setButtonsDisabled(false);
  };

  const removeCount = async (key: any, property: string) => {
    if (fieldValue[key][property] - 1 < 0) {
      return;
    }
    setButtonsDisabled(true);
    const changedFieldValue = { ...fieldValue, ...{ ...fieldValue, [key]: { ...fieldValue[key], [property]: fieldValue[key][property] - 1 } } };
    saveCallback && (await saveCallback({ value: { ...fieldValue[key], ...{ [property]: fieldValue[key][property] - 1 } } }, "count." + key));
    await calculateAndSaveTotals(changedFieldValue);
    setButtonsDisabled(false);
  };

  const calculateAndSaveTotals = (values: any) => {
    const plusValues = Object.values(values)
      .map((value: any) => {
        return value.plus;
      })
      .filter(Boolean);
    const totalPlusCount = plusValues.length > 0 ? plusValues.reduce((previousValue, currentValue) => previousValue + currentValue) : 0;
    const minusValues = Object.values(values)
      .map((value: any) => {
        return value.minus;
      })
      .filter(Boolean);
    const totalMinusCount = minusValues.length > 0 ? minusValues.reduce((previousValue, currentValue) => previousValue + currentValue) : 0;
    const totalSumCount = totalPlusCount + totalMinusCount;
    const totalPercentage = ~~parseFloat(((totalPlusCount / totalSumCount) * 100).toString()).toFixed(2);
    const totals = { totalPlusCount: totalPlusCount, totalMinusCount: totalMinusCount, totalSumCount: totalSumCount, totalPercentage: totalPercentage };
    return saveCallback && saveCallback(totals);
  };

  return (
    <div>
      <div>
        <BhSideBySideInput>
          <div className="flex w-full flex-row items-center justify-evenly">
            <div className="flex w-32 flex-row items-center justify-evenly font-bold">
              <div>{t("FORMS.SCM.OK").toUpperCase()}</div>
            </div>
            <div className="flex w-32 flex-row items-center justify-evenly font-bold">
              <div>{t("FORMS.SCM.NOK").toUpperCase()}</div>
            </div>
          </div>
        </BhSideBySideInput>
      </div>
      {Object.keys(fieldValue).map((c, index) => {
        if (fieldValue[c].minus >= 0 && fieldValue[c].plus >= 0) {
          return (
            <div className="py-1" key={index}>
              <BhSideBySideInput label={t("FORMS.SCM." + c.toUpperCase())}>
                <div className="flex w-full flex-row items-center justify-evenly">
                  <div className="flex w-32 flex-row items-center justify-evenly">
                    <BhIconButton icon={faMinus} withBackground={true} buttonProps={{ onClick: () => removeCount(c, "plus"), disabled: disabled || buttonsDisabled }} />
                    <div>{fieldValue[c].plus}</div>
                    <BhIconButton icon={faPlus} withBackground={true} buttonProps={{ onClick: () => addCount(c, "plus"), disabled: disabled || buttonsDisabled }} />
                  </div>
                  <div className="flex w-32 flex-row items-center justify-evenly">
                    <BhIconButton icon={faMinus} withBackground={true} buttonProps={{ onClick: () => removeCount(c, "minus"), disabled: disabled || buttonsDisabled }} />
                    <div>{fieldValue[c].minus}</div>
                    <BhIconButton icon={faPlus} withBackground={true} buttonProps={{ onClick: () => addCount(c, "minus"), disabled: disabled || buttonsDisabled }} />
                  </div>
                </div>
              </BhSideBySideInput>
            </div>
          );
        }
      })}
      <div className="py-5">
        <BhSideBySideInput label={t("FORMS.SCM.COUNT").toUpperCase()}>
          <div className="flex w-full flex-row items-center justify-evenly">
            <div className="flex w-32 flex-row items-center justify-evenly font-bold">
              <div>{totalPlus}</div>
            </div>
            <div className="flex w-32 flex-row items-center justify-evenly font-bold">
              <div>{totalMinus}</div>
            </div>
          </div>
        </BhSideBySideInput>
      </div>
    </div>
  );
};

export default FormScmTableField;
