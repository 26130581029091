import React, { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const BhDetailContainer: FC<Props> = ({ children }) => {
  return <div className="flex h-full w-full flex-col">{children}</div>;
};

export default BhDetailContainer;
