import { createAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { ICompany } from "@/model/ICompany";
import { fetchProjectsByCompany } from "@/api/projectAPI";
import { RootState } from "@/app/store";
import { BauhubPlan, IProject, ProjectStatus } from "@/model/IProject";
import { initialProjectsSort, IProjectsSort } from "@/model/projects/IProjectsSort";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import {
  addCompanyAdministrator,
  changeCompanyAdministrator,
  changeCompanyAdminUserInvite,
  fetchCompany,
  fetchCompanyInvitedUsers,
  fetchCompanyUsers,
  removeCompanyAdminInvite,
  removeCompanyAdministratorPrivileges,
  removeUserFromCompany,
  removeUserInvites,
  saveCompany,
  saveCompanyInfo,
  saveNcnContractEmail
} from "@/api/companyAPI";
import { ICompanyInfo } from "@/model/iCompanyInfo";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import { ICompanyUsersFilter } from "@/model/companyUsers/ICompanyUsersFilter";
import { getUserFullName } from "@/model/IUser";
import { ICompanyInvitedUser } from "@/model/companyUsers/ICompanyInvitedUser";
import { removeFavouriteProject, saveFavouriteProject } from "@/api/favouriteAPI";
import { ICompanyAdministratorChangeRequest } from "@/model/ICompanyAdministratorChangeRequest";
import { resendAllInvitesForCompanyAndUsername } from "@/api/inviteApi";
import { removeUserOrInvitedUserFromProjectAsync, selectCurrentProject } from "@/app/store/project/projectSlice";
import { filterCompanyUsers } from "@/utilities/companyUtilities";
import { FileTreeType, IFileTreeTemplate } from "@/model/files/IFileTreeTemplate";
import { fetchDefaultFileTreeTemplates, removeFileTreeTemplate, saveFileTreeTemplate, saveNewFileTreeTemplate } from "@/api/fileTreeAPI";
import {
  fetchCompanyUsersForNewPredefinedParty,
  fetchPredefinedParties,
  removePredefinedParty,
  removePredefinedPartyUser,
  saveNewPredefinedParty,
  saveNewPredefinedPartyUser,
  savePredefinedPartyName,
  savePredefinedPartyType,
  savePredefinedPartyUser
} from "@/api/predefinedPartiesAPI";
import { IPredefinedWorkGroup } from "@/model/IPredefinedWorkGroup";
import { IPredefinedWorkGroupUser } from "@/model/IUserAuthority";
import { isMaruProject } from "@/utilities/customClients/maruUtilities";
import { ICompanyBillsFilter } from "@/model/billing/ICompanyBillsFilter";
import { ISimpleProject } from "@/model/projects/ISimpleProject";
import { removeDuplicates } from "@/utilities/jsUtilities";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { ICompanyBilling } from "@/model/billing/ICompanyBilling";
import { WorkGroupType } from "@/model/IWorkGroup";
import { naturalSortByField } from "@/utilities/sortUtilities";

export const companiesAdapter = createEntityAdapter<ICompany>({
  selectId: (company) => company.id
});

export interface ICompaniesState {
  current: ICompany;
  status: BhStateStatusType;
  xsrfToken: string;
  showArchivedProjects: boolean;
  sort: IProjectsSort;
  usersFilter: ICompanyUsersFilter;
  companyBillsFilter: ICompanyBillsFilter;
  addCompanyAdministratorModalOpen: boolean;
  deletableUser: ICompanyUser | ICompanyInvitedUser;
  companiesStatus: BhStateStatusType;
  usersStatus: BhStateStatusType;
}

const initialState = companiesAdapter.getInitialState<ICompaniesState>({
  // TODO: kaotada ära current ja kogu state ettevõtte vaate jaoks võtta adapteri entititest
  current: {} as ICompany,
  status: BhStateStatusType.INITIAL,
  xsrfToken: "",
  showArchivedProjects: false,
  sort: initialProjectsSort,
  companyBillsFilter: {
    sortBy: "created",
    sortOrderAsc: false
  } as ICompanyBillsFilter,
  usersFilter: {
    searchQuery: "",
    projectSearchInput: "",
    projects: []
  },
  addCompanyAdministratorModalOpen: false,
  deletableUser: {} as ICompanyUser,
  companiesStatus: BhStateStatusType.INITIAL,
  usersStatus: BhStateStatusType.INITIAL
});

export const fetchProjectsByCompanyAsync = createAsyncThunk("companies/fetchProjectsByCompany", async () => {
  return fetchProjectsByCompany();
});

export const fetchCompanyAsync = createAsyncThunk("companies/fetchCompany", async (companyId: EntityId) => {
  return fetchCompany(companyId);
});

export const saveCompanyAsync = createAsyncThunk("companies/postCompany", async (company: ICompany) => {
  return saveCompany(company);
});

export const fetchCompanyUsersAsync = createAsyncThunk("companies/fetchCompanyUsers", async (companyId: EntityId) => {
  return fetchCompanyUsers(companyId);
});

export const fetchCompanyUsersForNewPredefinedPartyAsync = createAsyncThunk("companies/fetchCompanyUsersForPredefinedPartyAsync", async (companyId: EntityId) => {
  const users = await fetchCompanyUsersForNewPredefinedParty(companyId);
  return { companyId: companyId, users: users };
});

export const fetchCompanyInvitedUsersAsync = createAsyncThunk("companies/fetchCompanyInvitedUsers", async (companyId: EntityId) => {
  const response = await fetchCompanyInvitedUsers(companyId);
  return { companyId: companyId, invitedUsers: response };
});

export const toggleFavouriteProjectAsync = createAsyncThunk("companies/favouriteProject/save", async (project: IProject) => {
  if (!project.pinned) {
    return saveFavouriteProject(project.id);
  } else {
    return removeFavouriteProject(project.id);
  }
});

export const setCompanyUuid = createAction<string>("company/uuid");

export const saveCompanyInfoAsync = createAsyncThunk("company/saveCompanyInfo", async (companyInfo: ICompanyInfo) => {
  return saveCompanyInfo(companyInfo.companyId, companyInfo);
});

export const saveNcnContractEmailAsync = createAsyncThunk("company/saveNcnContractEmail", async (dto: { companyId: EntityId; contractEmail: string }) => {
  return saveNcnContractEmail(dto.companyId, dto.contractEmail);
});

export const addCompanyAdministratorAsync = createAsyncThunk(
  "company/addCompanyAdministrator",
  async ({ companyId, request }: { companyId: EntityId; request: ICompanyAdministratorChangeRequest }) => {
    return addCompanyAdministrator(companyId, request);
  }
);

export const changeCompanyAdministratorAsync = createAsyncThunk("company/changeCompanyAdministrator", async ({ companyId, changedUser }: { companyId: EntityId; changedUser: ICompanyUser }) => {
  const companyAdministratorChangeRequest = {
    username: changedUser.username,
    accountManager: changedUser.accountManager,
    newProjectDefaultAdmin: changedUser.newProjectDefaultAdmin,
    checklistsManager: changedUser.checklistsManager,
    formsManager: changedUser.formsManager
  };
  return changeCompanyAdministrator(companyId, changedUser, companyAdministratorChangeRequest);
});

export const changeCompanyAdminUserInviteAsync = createAsyncThunk(
  "company/changeCompanyInvitedAdministrator",
  async ({ companyId, request }: { companyId: EntityId; request: ICompanyAdministratorChangeRequest }) => {
    const invitedUser = await changeCompanyAdminUserInvite(companyId, request);
    return { companyId: companyId, changedUser: invitedUser };
  }
);

export const resendAllInvitesForCompanyAndUsernameAsync = createAsyncThunk("company/resendCompanyUsernameInvites", async ({ companyId, username }: { companyId: EntityId; username: string }) => {
  return resendAllInvitesForCompanyAndUsername(companyId, username);
});

export const removeCompanyAdministratorAsync = createAsyncThunk("company/removeCompanyAdministrator", async ({ companyId, userId }: { companyId: EntityId; userId: EntityId }) => {
  // TODO: Change BE return type so that we wouldn't have to use companyId from request here?
  const response = await removeCompanyAdministratorPrivileges(companyId, userId);
  return { companyId: companyId, companyUsers: response };
});

export const removeUserFromCompanyAsync = createAsyncThunk("company/removeCompanyUser", async (companyUser: ICompanyUser) => {
  return removeUserFromCompany(companyUser);
});

export const removeUserInvitesAsync = createAsyncThunk("company/removeCompanyUserInvites", async (companyUser: ICompanyInvitedUser) => {
  return removeUserInvites(companyUser);
});

export const removeCompanyAdminInviteAsync = createAsyncThunk("company/removeAdminUserInvites", async (companyUser: ICompanyInvitedUser) => {
  return removeCompanyAdminInvite(companyUser);
});

export const toggleProjectInCompanyUsersFilter = createAction<ISimpleProject>("company/toggleProjectInCompanyUsersFilter");
export const setCompanyUserSearchKey = createAction<ICompanyUsersFilter>("company/setCompanyUserSearchKey");
export const clearProjectsFilter = createAction("company/clearProjectsFilter");
export const setAddCompanyAdministratorModalOpen = createAction<boolean>("company/setCompanyAdministratorModalOpen");

export const fetchDefaultFileTreeTemplatesAsync = createAsyncThunk("companies/fetchDefaultFileTrees", async ({ companyId, fileTreeType }: { companyId: EntityId; fileTreeType: FileTreeType }) => {
  const result = await fetchDefaultFileTreeTemplates(companyId, fileTreeType);
  return { companyId: companyId, type: fileTreeType, result: result };
});

export const removeFileTreeTemplateAsync = createAsyncThunk("companies/removeFileTreeTemplate", async (fileTree: IFileTreeTemplate) => {
  const result = await removeFileTreeTemplate(fileTree);
  return { companyId: fileTree.companyId, type: fileTree.type, result: result };
});

export const saveNewFileTreeTemplateAsync = createAsyncThunk("companies/saveNewFileTreeTemplate", async (fileTreeTemplate: IFileTreeTemplate) => {
  const result = await saveNewFileTreeTemplate(fileTreeTemplate);
  return { companyId: fileTreeTemplate.companyId, type: fileTreeTemplate.type, result: result };
});

export const saveFileTreeTemplateAsync = createAsyncThunk("companies/saveFileTreeTemplate", async (fileTreeTemplate: IFileTreeTemplate) => {
  const result = await saveFileTreeTemplate(fileTreeTemplate);
  return { companyId: fileTreeTemplate.companyId, type: fileTreeTemplate.type, result: result };
});

export const fetchPredefinedPartiesAsync = createAsyncThunk("companies/fetchPredefinedParties", async (companyId: EntityId) => {
  return fetchPredefinedParties(companyId);
});

export const saveNewPredefinedPartyAsync = createAsyncThunk("companies/saveNewPredefinedParty", async (predefinedParty: IPredefinedWorkGroup) => {
  return saveNewPredefinedParty(predefinedParty);
});

export const savePredefinedPartyUserAsync = createAsyncThunk("companies/savePredefinedPartyUser", async (predefinedPartyUser: IPredefinedWorkGroupUser) => {
  return savePredefinedPartyUser(predefinedPartyUser);
});

export const saveNewPredefinedPartyUserAsync = createAsyncThunk("companies/saveNewPredefinedPartyUser", async (predefinedPartyUser: IPredefinedWorkGroupUser) => {
  return saveNewPredefinedPartyUser(predefinedPartyUser);
});

export const savePredefinedPartyNameAsync = createAsyncThunk("companies/savePredefinedPartyName", async (dto: { companyId: EntityId; partyId: EntityId; dto: { value: string } }) => {
  const response = await savePredefinedPartyName(dto.companyId, dto.partyId, dto.dto);
  return { companyId: dto.companyId, id: dto.partyId, name: response.value };
});

export const savePredefinedPartyTypeAsync = createAsyncThunk("companies/savePredefinedPartyType", async (dto: { companyId: EntityId; partyId: EntityId; dto: { value?: WorkGroupType } }) => {
  const response = await savePredefinedPartyType(dto.companyId, dto.partyId, dto.dto);
  return { companyId: dto.companyId, id: dto.partyId, type: response.value };
});

export const removePredefinedPartyUserAsync = createAsyncThunk("companies/removePredefinedPartyUser", async (predefinedPartyUser: IPredefinedWorkGroupUser) => {
  return removePredefinedPartyUser(predefinedPartyUser);
});

export const removePredefinedPartyAsync = createAsyncThunk("companies/removePredefinedParty", async (predefinedParty: IPredefinedWorkGroup) => {
  return removePredefinedParty(predefinedParty);
});

export const setCompanyBillsFilter = createAction<ICompanyBillsFilter>("company/setCompanyBillsFilter");
export const resetCompanyBillsFilterProject = createAction("company/bills/reset/project");
export const resetCompanyBillsFilterStatus = createAction("company/bills/reset/status");
export const resetCompanyBillsFilterSince = createAction("company/bills/reset/since");
export const resetCompanyBillsFilterUntil = createAction("company/bills/reset/until");
export const toggleCompanyBillsSortOrder = createAction<string>("company/bills/sort/toggle");

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    companiesSet: (state, action: PayloadAction<Array<ICompany>>) => {
      companiesAdapter.setAll(state, action.payload);
    },
    xsrfTokenSet: (state, action: PayloadAction<string>) => {
      state.xsrfToken = action.payload;
    },
    toggleArchivedProjects: (state) => {
      state.showArchivedProjects = !state.showArchivedProjects;
    },
    projectsSortSet: (state, action: PayloadAction<IProjectsSort>) => {
      state.sort = action.payload;
    },
    deletableUserSet: (state, action: PayloadAction<ICompanyUser | ICompanyInvitedUser>) => {
      state.deletableUser = action.payload;
    },
    companyPlanSet: (state, action: PayloadAction<ICompanyBilling>) => {
      const company = state.entities[action.payload.companyId];
      if (company) companiesAdapter.upsertOne(state, { ...company, plan: action.payload.plan as BauhubPlan });
    },
    predefinedPartyPropertyChanged: (state, action: PayloadAction<{ companyId: EntityId; predefinedPartyId: EntityId; property: keyof IPredefinedWorkGroup; value: any }>) => {
      const company = state.entities[action.payload.companyId];
      if (company) {
        const updatedCompany = {
          ...company,
          predefinedWorkGroups: company.predefinedWorkGroups?.map((pp) => {
            if (pp.id === action.payload.predefinedPartyId) {
              return { ...pp, [action.payload.property]: { ...action.payload.value } };
            } else {
              return pp;
            }
          })
        };
        companiesAdapter.upsertOne(state, updatedCompany);
      }
    },
    usersFilterCleared: (state) => {
      state.usersFilter = {
        searchQuery: "",
        projectSearchInput: "",
        projects: []
      };
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(setCompanyUuid, (state, action) => {
        state.current.uuid = action.payload;
      })
      .addCase(saveCompanyInfoAsync.fulfilled, (state, action) => {
        // TODO: Terve company muutmine on tegelikult kole, kas on mingi parem variant?
        const company = state.entities[action.payload.companyId];
        if (company) {
          company.companyInfo = action.payload;
          companiesAdapter.upsertOne(state, company);
        }
      })
      .addCase(addCompanyAdministratorAsync.fulfilled, (state, action) => {
        state.addCompanyAdministratorModalOpen = false;
        const invitedAdmin = action.payload;
        const company = state.entities[invitedAdmin.companyId];
        if (company) {
          if (invitedAdmin.userExists) {
            const alreadyExists = company.realUsers.some((user) => user.username === invitedAdmin.username);
            if (alreadyExists && "userEntityId" in invitedAdmin) {
              company.realUsers = company.realUsers.map((user) => (user.userEntityId === invitedAdmin.userEntityId ? invitedAdmin : user));
            }
            if (!alreadyExists) company.realUsers = [...company.realUsers, invitedAdmin as ICompanyUser];
          } else if (invitedAdmin.username) {
            const alreadyExists = company.invitedUsers.some((user) => user.username === invitedAdmin.username);
            if (!alreadyExists) company.invitedUsers = [...company.invitedUsers, invitedAdmin as ICompanyInvitedUser];
          }
        }
      })
      .addCase(changeCompanyAdministratorAsync.fulfilled, (state, action) => {
        const companyId = action.payload.companyId;
        const userId = action.payload.userEntityId;
        const companyToChange = state.entities[companyId];
        if (companyToChange) {
          const index = companyToChange.realUsers.findIndex((user) => user.userEntityId === userId);
          companyToChange.realUsers[index] = action.payload;
        }
      })
      .addCase(changeCompanyAdminUserInviteAsync.fulfilled, (state, action) => {
        const companyId = action.payload.companyId;
        const changedUserFromRequest = action.payload.changedUser;
        const companyToChange = state.entities[companyId];
        if (companyToChange) {
          const userToUpdate = companyToChange.invitedUsers.find((user) => user.username === changedUserFromRequest.username);
          if (userToUpdate) {
            userToUpdate.accountManager = changedUserFromRequest.accountManager;
            userToUpdate.companyAdmin = changedUserFromRequest.companyAdmin;
            userToUpdate.newProjectDefaultAdmin = changedUserFromRequest.newProjectDefaultAdmin;
            userToUpdate.checklistsManager = changedUserFromRequest.checklistsManager;
            userToUpdate.formsManager = changedUserFromRequest.formsManager;
          }
        }
      })
      .addCase(resendAllInvitesForCompanyAndUsernameAsync.fulfilled, (state, action) => {
        // Nothing to do here
      })
      .addCase(removeCompanyAdministratorAsync.fulfilled, (state, action) => {
        const { companyId, companyUsers } = action.payload;
        const companyToUpdate = state.entities[companyId];
        if (companyToUpdate) {
          companyToUpdate.realUsers = companyUsers;
        }
      })
      .addCase(fetchProjectsByCompanyAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchProjectsByCompanyAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.companiesStatus = BhStateStatusType.SUCCESS;
        companiesAdapter.setAll(state, action.payload);
      })
      .addCase(fetchCompanyAsync.pending, (state, action) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchCompanyAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        companiesAdapter.upsertOne(state, action.payload);
        state.current = action.payload;
      })
      .addCase(fetchCompanyUsersAsync.pending, (state) => {
        state.usersStatus = BhStateStatusType.PENDING;
      })
      .addCase(fetchCompanyUsersAsync.fulfilled, (state, action) => {
        state.usersStatus = BhStateStatusType.SUCCESS;
        const company = state.entities[action.payload[0].companyId];
        if (company) {
          company.realUsers = action.payload;
        }
      })
      .addCase(fetchCompanyUsersForNewPredefinedPartyAsync.fulfilled, (state, action) => {
        const company = state.entities[action.payload.companyId];
        if (company) {
          company.users = action.payload.users;
        }
      })
      .addCase(fetchCompanyInvitedUsersAsync.pending, (state) => {
        state.usersStatus = BhStateStatusType.PENDING;
      })
      .addCase(fetchCompanyInvitedUsersAsync.fulfilled, (state, action) => {
        state.usersStatus = BhStateStatusType.SUCCESS;
        const { companyId, invitedUsers } = action.payload;
        const companyToChange = state.entities[companyId];
        if (companyToChange) {
          companyToChange.invitedUsers = invitedUsers;
        }
      })
      .addCase(removeUserFromCompanyAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const response = action.payload;
        if (response.companyId && response.userId) {
          const company = state.entities[response.companyId];
          if (company) {
            const index = company.realUsers.findIndex((user) => user.userEntityId === response.userId);
            company.realUsers.splice(index, 1);
          }
        }
      })
      .addCase(removeUserInvitesAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.deletableUser = {} as ICompanyUser;
        const response = action.payload;
        if (response.companyId && response.username) {
          const company = state.entities[response.companyId];
          if (company) {
            const index = company.invitedUsers.findIndex((user) => user.username === response.username);
            company.invitedUsers.splice(index, 1);
          }
        }
      })
      .addCase(removeCompanyAdminInviteAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const response = action.payload;
        if (response.companyId && response.username) {
          const company = state.entities[response.companyId];
          if (company) {
            const index = company.invitedUsers.findIndex((user) => user.username === response.username);
            if (company.invitedUsers[index].projects.length > 0) {
              company.invitedUsers[index].companyAdmin = false;
            } else {
              company.invitedUsers.splice(index, 1);
            }
          }
        }
      })
      .addCase(removeUserOrInvitedUserFromProjectAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;

        const { companyId, username, userExists, userDTO } = action.payload;
        const company = state.entities[companyId];
        if (company) {
          if (userExists) {
            const index = company.realUsers.findIndex((user) => user.username === userDTO.username);
            if (userDTO) {
              company.realUsers[index] = userDTO as ICompanyUser;
            } else {
              // User was removed from last project
              company.realUsers.splice(index, 1);
            }
          } else if (username) {
            const index = company.invitedUsers.findIndex((user) => user.username === userDTO.username);
            if (userDTO) {
              company.invitedUsers[index] = userDTO as ICompanyInvitedUser;
            } else {
              // Invitee was removed from last project
              company.invitedUsers.splice(index, 1);
            }
          }
        }
      })
      .addCase(toggleProjectInCompanyUsersFilter, (state, action) => {
        const project = action.payload;
        const alreadySelected = state.usersFilter.projects.some((p) => p.projectId === project.projectId);
        if (alreadySelected) {
          state.usersFilter.projects = state.usersFilter.projects.filter((p) => p.projectId !== project.projectId);
        } else {
          state.usersFilter.projects = [...state.usersFilter.projects, project];
        }
      })
      .addCase(setCompanyUserSearchKey, (state, action) => {
        state.usersFilter = action.payload;
      })
      .addCase(setCompanyBillsFilter, (state, action) => {
        state.companyBillsFilter = action.payload;
      })
      .addCase(clearProjectsFilter, (state) => {
        state.usersFilter.projects = [];
      })
      .addCase(resetCompanyBillsFilterProject, (state) => {
        delete state.companyBillsFilter.project;
      })
      .addCase(resetCompanyBillsFilterStatus, (state) => {
        delete state.companyBillsFilter.status;
      })
      .addCase(resetCompanyBillsFilterSince, (state) => {
        delete state.companyBillsFilter.since;
      })
      .addCase(resetCompanyBillsFilterUntil, (state) => {
        delete state.companyBillsFilter.until;
      })
      .addCase(toggleCompanyBillsSortOrder, (state, action) => {
        let currentlyOrderedBy = state.companyBillsFilter.sortBy;
        if (currentlyOrderedBy === action.payload) {
          state.companyBillsFilter.sortOrderAsc = !state.companyBillsFilter.sortOrderAsc;
        } else {
          state.companyBillsFilter.sortBy = action.payload;
        }
      })
      .addCase(setAddCompanyAdministratorModalOpen, (state, action) => {
        state.addCompanyAdministratorModalOpen = action.payload;
      })
      .addCase(toggleFavouriteProjectAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(toggleFavouriteProjectAsync.fulfilled, (state, action) => {
        const project = Object.values(state.entities)
          .flatMap((company) => (company && company.projects) || [])
          .find((project) => project.id === action.payload.resourceId);
        if (project) {
          project.pinned = !action.payload.deleted;
        }
      })
      .addCase(removePredefinedPartyUserAsync.fulfilled, (state, action) => {
        const predefinedPartyUser = action.payload;
        const company = state.entities[predefinedPartyUser.companyId];
        const predefinedParty = company?.predefinedWorkGroups?.find((party) => party.id === predefinedPartyUser.predefinedWorkGroupId);
        if (company && predefinedParty) {
          const updatedPartyUsers = predefinedParty.users.filter((user) => user.id !== predefinedPartyUser.id);
          const updatedParty = { ...predefinedParty, users: updatedPartyUsers };
          const updatedParties = company.predefinedWorkGroups?.map((party) => {
            return party.id === updatedParty.id ? updatedParty : party;
          });
          const updatedCompany = { ...company, predefinedWorkGroups: updatedParties };
          companiesAdapter.upsertOne(state, updatedCompany);
        }
      })
      .addCase(saveNewPredefinedPartyAsync.fulfilled, (state, action) => {
        const predefinedParty = action.payload;
        const { companyId } = action.payload;
        const company = state.entities[companyId];
        if (company) {
          const updatedPredefinedParties = company.predefinedWorkGroups ? [...company.predefinedWorkGroups, predefinedParty] : [predefinedParty];
          companiesAdapter.upsertOne(state, { ...company, predefinedWorkGroups: updatedPredefinedParties });
        }
      })
      .addCase(fetchPredefinedPartiesAsync.fulfilled, (state, action) => {
        const predefinedPartyList = action.payload;
        if (!predefinedPartyList[0]) return;
        const { companyId } = predefinedPartyList[0];
        const company = state.entities[companyId];
        if (company) {
          companiesAdapter.upsertOne(state, { ...company, predefinedWorkGroups: predefinedPartyList });
        }
      })
      .addCase(savePredefinedPartyNameAsync.fulfilled, (state, action) => {
        const changedParty = action.payload;
        const company = state.entities[changedParty.companyId];
        if (company) {
          const updatedPredefinedParties = company.predefinedWorkGroups?.map((party) => (party.id === changedParty.id ? { ...party, name: changedParty.name } : party)) || [];
          companiesAdapter.upsertOne(state, { ...company, predefinedWorkGroups: updatedPredefinedParties });
        }
      })
      .addCase(savePredefinedPartyTypeAsync.fulfilled, (state, action) => {
        const changedParty = action.payload;
        const company = state.entities[changedParty.companyId];
        if (company) {
          const updatedPredefinedParties = company.predefinedWorkGroups?.map((party) => (party.id === changedParty.id ? { ...party, type: changedParty.type } : party)) || [];
          companiesAdapter.upsertOne(state, { ...company, predefinedWorkGroups: updatedPredefinedParties });
        }
      })
      .addCase(removePredefinedPartyAsync.fulfilled, (state, action) => {
        const company = state.entities[action.payload.companyId];
        if (company) companiesAdapter.upsertOne(state, { ...company, predefinedWorkGroups: company.predefinedWorkGroups?.filter((p) => p.id !== action.payload.id) });
      })
      .addMatcher(isAnyOf(savePredefinedPartyUserAsync.fulfilled, saveNewPredefinedPartyUserAsync.fulfilled), (state, action) => {
        const predefinedPartyUser = action.payload;
        const company = state.entities[predefinedPartyUser.companyId];
        const predefinedParty = company?.predefinedWorkGroups?.find((party) => party.id === predefinedPartyUser.predefinedWorkGroupId);
        if (company && predefinedParty) {
          const userExists = predefinedParty.users.some((user) => user.id === predefinedPartyUser.id);
          let updatedPartyUsers = [];
          if (!userExists) {
            updatedPartyUsers = [...predefinedParty.users, action.payload];
          } else {
            updatedPartyUsers = predefinedParty.users.map((user) => {
              return user.id === predefinedPartyUser.id ? predefinedPartyUser : user;
            });
          }
          const updatedParty = { ...predefinedParty, users: updatedPartyUsers };
          const updatedParties = company.predefinedWorkGroups?.map((party) => {
            return party.id === updatedParty.id ? updatedParty : party;
          });
          const updatedCompany = { ...company, predefinedWorkGroups: updatedParties };
          companiesAdapter.upsertOne(state, updatedCompany);
        }
      })
      .addMatcher(isAnyOf(fetchDefaultFileTreeTemplatesAsync.fulfilled, removeFileTreeTemplateAsync.fulfilled), (state, action) => {
        const fileTreeList = action.payload.result;
        const companyId = action.payload.companyId;
        const type = action.payload.type;
        const company = state.entities[companyId];
        if (company && type) {
          const updatedCompany = { ...company, fileTreeTemplates: { ...company.fileTreeTemplates, [type]: fileTreeList } };
          companiesAdapter.upsertOne(state, updatedCompany);
        }
      })
      .addMatcher(isAnyOf(saveNewFileTreeTemplateAsync.fulfilled, saveFileTreeTemplateAsync.fulfilled), (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const { id } = action.payload.result;
        const companyId = action.payload.companyId;
        const type = action.payload.type;
        const company = state.entities[companyId];
        if (company && type && type !== FileTreeType.DIR) {
          const isExistingFileTree = company.fileTreeTemplates[type].some((tree) => tree.id === id);
          if (isExistingFileTree) {
            company.fileTreeTemplates[type] = company.fileTreeTemplates[type].map((fileTreeTemplate) => {
              return fileTreeTemplate.id === id ? action.payload.result : fileTreeTemplate;
            });
          } else {
            company.fileTreeTemplates[type] = [...company.fileTreeTemplates[type], action.payload.result];
          }
        }
      });
  }
});
export const { selectIds: selectCompanyIds, selectAll: selectAllCompanies, selectById: selectCompanyById } = companiesAdapter.getSelectors((state: RootState) => state.companies);

export const selectProjectsSort = (state: RootState) => state.companies.sort;
export const selectCompanyStateStatus = (state: RootState) => state.companies.status;
export const selectCompaniesStatus = (state: RootState) => state.companies.companiesStatus;
export const selectCompanyUsersStatus = (state: RootState) => state.companies.usersStatus;
export const selectShowArchivedProjects = (state: RootState) => state.companies.showArchivedProjects;
export const selectCompanyUsersFilter = (state: RootState) => state.companies.usersFilter;
export const selectCompanyBillsFilter = (state: RootState) => state.companies.companyBillsFilter;
export const selectAddCompanyAdministratorModalOpen = (state: RootState) => state.companies.addCompanyAdministratorModalOpen;
export const selectDeletableUser = (state: RootState) => state.companies.deletableUser;

export const selectCompanyId = (state: RootState, companyId: EntityId) => companyId;
export const selectCompanyItemIds = (state: RootState, itemIds: Array<EntityId>) => itemIds;
export const selectCompaniesLoading = (state: RootState) => state.companies.status !== BhStateStatusType.SUCCESS;
export const selectCurrentCompanyEnabledForms = (state: RootState) => state.companies.current.enabledForms;

export const selectCompanyIdsSortedByName = createSelector([selectAllCompanies], (allCompanies) => {
  return allCompanies
    .sort((a, b) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    })
    .map((company) => company.id);
});

export const selectAllProjects = createSelector([selectAllCompanies], (allCompanies) => allCompanies.flatMap((company) => company.projects || []));
export const selectAllProjectsSortedByName = (state: RootState) =>
  selectAllProjects(state).sort((a, b) => {
    return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
  });

export const selectAllCompanyProjects = createSelector([selectAllProjects, selectCompanyId], (allProjects, companyId) => {
  return allProjects.filter((project) => project.companyId === companyId);
});

export const selectAllCompanyProjectIds = createSelector(
  [selectAllCompanyProjects, selectCompanyId, selectShowArchivedProjects, selectProjectsSort],
  (allCompanyProjects, companyId, showArchivedProjects, sort) => {
    const isMaru = allCompanyProjects.some((project) => isMaruProject(project));
    const allProjectsLowercase = allCompanyProjects.map((project) => {
      return { ...project, name: project.name.toLowerCase() };
    });
    const sortProperty = isMaru && sort.property === "name" ? "contractNumber" : sort.property;
    const sortedProjects = allProjectsLowercase.sort((a: any, b: any) => (a[sortProperty] > b[sortProperty] ? (sort.reversed ? -1 : 1) : sort.reversed ? 1 : -1));
    const sortedArchivedProjects = [
      ...sortedProjects.filter((project) => project.status === ProjectStatus.IN_PROGRESS),
      ...sortedProjects.filter((project) => project.status === ProjectStatus.ARCHIVED)
    ];
    const allCompanyProjectsFiltered = showArchivedProjects ? sortedArchivedProjects : sortedArchivedProjects.filter((project) => project.status !== ProjectStatus.ARCHIVED);
    return allCompanyProjectsFiltered.map((p: IProject) => p.id);
  }
);

export const selectAllCompanyProjectsSortedForSettings = createSelector(
  [selectAllCompanyProjects, selectCompanyId, selectShowArchivedProjects, selectProjectsSort],
  (allCompanyProjects, companyId, showArchivedProjects, sort) => {
    const isMaru = allCompanyProjects.some((project) => isMaruProject(project));
    const allProjectsLowercase = allCompanyProjects.map((project) => {
      return { ...project, name: project.name.toLowerCase() };
    });
    const sortProperty = isMaru && sort.property === "name" ? "contractNumber" : sort.property;
    const sortedProjects = allProjectsLowercase.sort((a: any, b: any) => (a[sortProperty] > b[sortProperty] ? (sort.reversed ? -1 : 1) : sort.reversed ? 1 : -1));
    const sortedArchivedProjects =
      [...sortedProjects.filter((project) => project.status === ProjectStatus.IN_PROGRESS), ...sortedProjects.filter((project) => project.status === ProjectStatus.ARCHIVED)] || [];
    return showArchivedProjects ? sortedArchivedProjects : sortedArchivedProjects.filter((project) => project.status !== ProjectStatus.ARCHIVED) || [];
  }
);

export const selectAllCompanyActiveProjects = createSelector([selectAllCompanyProjects, selectCompanyId, selectProjectsSort], (allCompanyProjects, companyId, sort) => {
  const isMaru = allCompanyProjects.some((project) => isMaruProject(project));
  const allProjects = allCompanyProjects.map((project) => {
    return { ...project, name: project.name } as IProject;
  });
  const sortProperty = isMaru && sort.property === "name" ? "contractNumber" : sort.property;
  const sortedProjects = allProjects.sort((a: any, b: any) => (a[sortProperty] > b[sortProperty] ? (sort.reversed ? -1 : 1) : sort.reversed ? 1 : -1));
  const sortedArchivedProjects = [
    ...sortedProjects.filter((project) => project.status === ProjectStatus.IN_PROGRESS),
    ...sortedProjects.filter((project) => project.status === ProjectStatus.ARCHIVED)
  ];
  const allCompanyProjectsFiltered = sortedArchivedProjects.filter((project) => project.status !== ProjectStatus.ARCHIVED && !project.suspended && !project.closed);
  return allCompanyProjectsFiltered;
});

export const selectAllCompanyActiveProjectIds = createSelector([selectAllCompanyActiveProjects], (activeProjects) => {
  return activeProjects.map((p) => p.id);
});

export const selectCompanyInProgressProjectsCount = createSelector([selectAllCompanyProjects], (companyProjects) => {
  return companyProjects.filter((project) => project.status !== ProjectStatus.ARCHIVED).length;
});

export const selectCompanyArchivedProjectsCount = createSelector([selectAllCompanyProjects], (companyProjects) => {
  return companyProjects.filter((project) => project.status === ProjectStatus.ARCHIVED).length;
});

export const selectPinnedProjectIds = createSelector([selectAllProjects, selectProjectsSort], (allProjects, sort) => {
  const allProjectsLowercase: Array<IProject> = allProjects.map((project) => {
    return { ...project, name: project.name.toLowerCase() };
  });
  const sortedProjects = allProjectsLowercase.sort((a: any, b: any) => (a[sort.property] > b[sort.property] ? (sort.reversed ? -1 : 1) : sort.reversed ? 1 : -1));

  return sortedProjects
    .filter((project) => project.pinned && project.status === ProjectStatus.IN_PROGRESS)
    .map((project) => {
      return project.id;
    });
});

export const makeSelectAllCompanyProjectIds = () => {
  return selectAllCompanyProjectIds;
};
export const makeSelectAllCompanyProjectsSortedForSettings = () => {
  return selectAllCompanyProjectsSortedForSettings;
};
export const makeSelectCompanyById = () => {
  return selectCompanyById;
};
export const selectCurrentCompany = (state: RootState) => state.companies.current;
export const selectCurrentCompanyId = (state: RootState) => state.companies.current.id;

export const selectProjectById = createSelector([selectAllProjects, selectCompanyId], (projects, id: EntityId) =>
  projects.find((project) => {
    return project && project.id === id;
  })
);

export const selectCompanyAdminUsers = (state: RootState, companyId: EntityId) => {
  const company = state.companies.entities[companyId];
  if (company) {
    const realUsers = company.realUsers;
    return realUsers.filter((user: ICompanyUser) => user.companyAdmin);
  } else {
    return new Array<ICompanyUser>();
  }
};

export const selectCompanyUsers = (state: RootState, companyId: EntityId) => {
  const company = state.companies.entities[companyId];
  if (company) {
    const realUsers = company.realUsers;
    // Filter by person's name
    const filteredUsers = realUsers.filter((user) => getUserFullName(user).toLowerCase().includes(state.companies.usersFilter.searchQuery.toLowerCase()) && user.projects.length > 0);
    const selectedProjectIds = state.companies.usersFilter.projects.map((project) => project.projectId) || [];
    const archivedProjectIds = company.projects.filter((project) => project.status === ProjectStatus.ARCHIVED).map((project) => project.id);
    return filterCompanyUsers(filteredUsers, selectedProjectIds, state.companies.showArchivedProjects, archivedProjectIds) as Array<ICompanyUser>;
  } else {
    return new Array<ICompanyUser>();
  }
};

//TODO - store'is on "realUsers" ja "users" võtmed, kumma pärima peaks?
export const selectAllCompanyUsers = (state: RootState, companyId: EntityId) => {
  const company = state.companies.entities[companyId];
  if (company?.users) {
    const userIds = company.users.map((user) => user.userEntityId) || [];
    const uniqueIds = removeDuplicates(userIds);
    const uniqueUsers = uniqueIds.map((id) => company.users.find((user) => user.userEntityId === id) || ({} as ISimpleValidUserAuthority));
    return uniqueUsers || [];
  } else {
    return [];
  }
};

export const selectCompanyInvitedUsers = createSelector(
  [selectAllCompanies, selectCompanyId, selectCompanyUsersFilter, selectShowArchivedProjects],
  (companies, companyId, usersFilter, showArchivedProjects) => {
    const company = companies.find((company) => company.id === companyId);
    if (company) {
      const invitedUsers = company.invitedUsers.filter((user) => user.projects.length > 0);

      // Search query is for names. We don't know the names of invitees, so let's not show invitees in search results
      if (usersFilter.searchQuery) {
        return new Array<ICompanyInvitedUser>();
      }
      const selectedProjectIds = usersFilter.projects.map((project) => project.projectId) || [];
      const archivedProjectIds = company.projects.filter((project) => project.status === ProjectStatus.ARCHIVED).map((project) => project.id);
      return filterCompanyUsers(invitedUsers, selectedProjectIds, showArchivedProjects, archivedProjectIds) as Array<ICompanyInvitedUser>;
    } else {
      return new Array<ICompanyUser>();
    }
  }
);

export const selectCompanyInvitedAdmins = createSelector([selectAllCompanies, selectCompanyId], (companies, companyId) => {
  const company = companies.find((company) => company.id === companyId);
  if (company) {
    const invitedUsers = company.invitedUsers;
    const filteredUsers = invitedUsers?.filter((invitee) => invitee.companyAdmin);
    return filteredUsers;
  } else {
    return new Array<ICompanyUser>();
  }
});

export const selectCompanyUserOrInviteeByUsername = (state: RootState, companyId: EntityId, username: string, userExists: boolean) => {
  const company = state.companies.entities[companyId];
  if (!company) {
    return;
  }
  if (userExists) {
    return company.realUsers.find((user) => user.username === username);
  } else {
    return company.invitedUsers.find((user) => user.username === username);
  }
};

//TODO siin võiks tulevikus "showArchivedProjects" booleani järgi ka filtreerida, aga praegu BE-st tulevad kasutaja projektid ISimpleProject tüübiga, kus pole "archived" lipukest
export const selectProjectFilterValuesInCompanySettingsAllUsers = createSelector([selectAllCompanies, selectCompanyId], (companies, companyId) => {
  const company = companies.find((company) => company.id === companyId);
  if (company) {
    const dropdownValues = [] as Array<ISimpleProject>;
    const usersProjects = company.realUsers.flatMap((user) => user.projects);
    const invitedUsersProjects = company.invitedUsers.flatMap((user) => user.projects);
    const allProjects = [...usersProjects, ...invitedUsersProjects];
    allProjects.forEach((project) => !dropdownValues.some((p) => p.projectId === project.projectId) && dropdownValues.push(project));
    return dropdownValues;
  } else {
    return [];
  }
});

export const selectFileTreeType = (state: RootState, itemId: EntityId, fileTreeType: FileTreeType) => fileTreeType;
export const selectCompanyFileTreeTemplatesByType = createSelector([selectAllCompanies, selectCompanyId, selectFileTreeType], (companies, companyId, fileTreeType) => {
  const company = companies.find((company) => company.id === companyId);
  if (company && fileTreeType !== FileTreeType.DIR) {
    const templates = company.fileTreeTemplates ? company.fileTreeTemplates[fileTreeType] || [] : [];
    return [...templates].sort((a, b) => naturalSortByField(a, b, "name"));
  }
});

export const selectCompanyPredefinedParties = createSelector([selectAllCompanies, selectCompanyId], (companies, companyId) => {
  const company = companies.find((company) => company.id === companyId);
  const templates = company ? company.predefinedWorkGroups || [] : [];
  return [...templates].sort((a, b) => naturalSortByField(a, b, "name"));
});

export const selectCompanyPredefinedPartyById = createSelector([selectAllCompanies, selectCompanyItemIds], (companies, ids) => {
  const companyId = ids[0];
  const predefinedPartyId = ids[1];
  const company: ICompany | undefined = companies.find((company) => company.id === companyId);
  const party: IPredefinedWorkGroup | undefined = company?.predefinedWorkGroups?.find((predefinedParty) => predefinedParty.id === predefinedPartyId);
  return party;
});

export const selectCompanyPredefinedPartyUsersById = createSelector([selectCompanyPredefinedPartyById], (party) => {
  return party?.users || [];
});

export const selectCurrentProjectUserOrInviteeEmails = createSelector([selectCurrentProject, selectAllCompanies], (project, companies: Array<ICompany>) => {
  const projectCompanyId = project.companyId;
  const currentCompany = companies.find((company) => company.id === projectCompanyId);
  if (currentCompany) {
    const projectUsersEmails = currentCompany.realUsers.filter((user) => user.projects.map((project) => project.projectId).includes(project.id)).map((user) => user.username) || new Array<string>();
    const invitedUsersEmails =
      currentCompany.invitedUsers.filter((invitee) => invitee.projects.map((project) => project.projectId).includes(project.id)).map((invitee) => invitee.username) || new Array<string>();
    return [...projectUsersEmails, ...invitedUsersEmails];
  }
  return Array<string>();
});

export const selectCompanyBillsFilterSortBy = (state: RootState) => state.companies.companyBillsFilter.sortBy;
export const selectCompanyBillsFilterSortOrderAsc = (state: RootState) => state.companies.companyBillsFilter.sortOrderAsc;

export const selectProjectId = (state: RootState, projectId: EntityId) => projectId;
export const selectCurrentCompanyProjectNameById = createSelector([selectCurrentCompany, selectProjectId], (company, projectId) => {
  const project = company.projects?.find((project) => project.id === projectId);
  if (project) {
    return project.name;
  }
  return "";
});

export const { companiesSet, xsrfTokenSet, toggleArchivedProjects, projectsSortSet, deletableUserSet, companyPlanSet, usersFilterCleared, predefinedPartyPropertyChanged } = companiesSlice.actions;

export default companiesSlice.reducer;
