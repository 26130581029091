import React, { FC } from "react";
import { IMaruProjectInfo } from "@/model/maru/IMaruProjectInfo";
import BhCompactTable from "@components/table/compactTable/BhCompactTable";
import BhCompactTableHead from "@components/table/compactTable/BhCompactTableHead";
import BhCompactTableHeaderRow from "@components/table/compactTable/BhCompactTableHeaderRow";
import BhCompactTableTH from "@components/table/compactTable/BhCompactTableTH";
import BhCompactTableBody from "@components/table/compactTable/BhCompactTableBody";
import BhCompactTableTD from "@components/table/compactTable/BhCompactTableTD";
import BhCompactTableTR from "@components/table/compactTable/BhCompactTableTR";
import { useTranslation } from "react-i18next";
import { formatDateFromString } from "@/utilities/dateUtility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons/faCheckSquare";
import { faTimesSquare } from "@fortawesome/pro-solid-svg-icons/faTimesSquare";
import { naturalSortByField } from "@/utilities/sortUtilities";
import dayjs from "dayjs";
import { classNames } from "@/utilities/jsUtilities";
import { formatCurrency } from "@/utilities/accountingUtilities";
import MaruDashboardTableRowLoading from "@/views/home/project/detail/maru/MaruDashboardTableRowLoading";

interface MaruDashboardDeadlinesTableProps {
  title: string;
  maruProjectInfos: Array<IMaruProjectInfo>;
  isLoading: boolean;
}

const MaruDashboardDeadlinesTable: FC<MaruDashboardDeadlinesTableProps> = ({ title, maruProjectInfos, isLoading }) => {
  const { t } = useTranslation();

  const getColorForPercentageCell = (value: number) => {
    const numberValue = (100 - value) / 100;
    if (isNaN(numberValue)) return "white";
    const hue = ((1 - numberValue) * 120).toString(10);
    return "hsl(" + hue + ",80%,70%)";
  };

  return (
    <div>
      <h3>{title}</h3>
      <BhCompactTable>
        <BhCompactTableHead>
          <BhCompactTableHeaderRow>
            <BhCompactTableTH classes="text-right w-10">{t("MR.DASHBOARD.NR")}</BhCompactTableTH>
            <BhCompactTableTH classes="min-w-48">{t("MR.DASHBOARD.NIMI")}</BhCompactTableTH>
            <BhCompactTableTH classes="w-34 min-w-34">{t("MR.DASHBOARD.LEPINGU_NR")}</BhCompactTableTH>
            <BhCompactTableTH classes="w-34 min-w-34 text-right">{t("MR.DASHBOARD.MAKSUMUS")}</BhCompactTableTH>
            <BhCompactTableTH classes="w-24 min-w-24 text-center">{t("MR.DASHBOARD.KUUPAEV")}</BhCompactTableTH>
            <BhCompactTableTH classes="w-24 min-w-24 text-center">{t("MR.DASHBOARD.TAHTAEG")}</BhCompactTableTH>
            <BhCompactTableTH classes="w-14 text-center">{t("MR.DASHBOARD.LEPING")}</BhCompactTableTH>
            <BhCompactTableTH classes="w-34 min-w-34 text-center">{t("MR.DASHBOARD.AKTEERITUD")}</BhCompactTableTH>
          </BhCompactTableHeaderRow>
        </BhCompactTableHead>
        <BhCompactTableBody>
          {!isLoading && (
            <>
              {maruProjectInfos.length < 1 && (
                <BhCompactTableTR>
                  <BhCompactTableTD colSpan={8} classes="text-center">
                    {t("MR.DASHBOARD_EMPTY")}
                  </BhCompactTableTD>
                </BhCompactTableTR>
              )}
              {maruProjectInfos
                .sort((a, b) => naturalSortByField(a, b, "tahtaeg"))
                .map((info, index) => {
                  const isTahtaegPast = dayjs(info.tahtaeg) < dayjs();

                  return (
                    <BhCompactTableTR key={info.id}>
                      <BhCompactTableTD classes={classNames(isTahtaegPast && "bh-text-pigeon-60", "text-right")}>{index + 1}</BhCompactTableTD>
                      <BhCompactTableTD classes={classNames(isTahtaegPast && "bh-text-pigeon-60")}>{info.name}</BhCompactTableTD>
                      <BhCompactTableTD classes={classNames(isTahtaegPast && "bh-text-pigeon-60")}>{info.lepinguNumber}</BhCompactTableTD>
                      <BhCompactTableTD classes={classNames(isTahtaegPast && "bh-text-pigeon-60", "text-right")}>{formatCurrency(info.lepinguMaksumus)}</BhCompactTableTD>
                      <BhCompactTableTD classes={classNames(isTahtaegPast && "bh-text-pigeon-60", "text-center")}>{formatDateFromString(info.lepinguKp)}</BhCompactTableTD>
                      <BhCompactTableTD classes={classNames(isTahtaegPast && "bh-text-pigeon-60", "text-center")}>{formatDateFromString(info.tahtaeg)}</BhCompactTableTD>
                      <BhCompactTableTD classes="text-center">
                        {info.allkirjadeArv >= 2 ? (
                          <FontAwesomeIcon icon={faCheckSquare} size={"lg"} className="bh-text-bauhub-green-120" />
                        ) : (
                          <FontAwesomeIcon icon={faTimesSquare} size={"lg"} className="bh-text-error-red" />
                        )}
                      </BhCompactTableTD>
                      <BhCompactTableTD classes="text-center" style={{ backgroundColor: getColorForPercentageCell(info.akteeritudProtsent) }}>
                        {info.akteeritudProtsent}%
                      </BhCompactTableTD>
                    </BhCompactTableTR>
                  );
                })}
            </>
          )}
          {isLoading && <MaruDashboardTableRowLoading tdCount={8} />}
        </BhCompactTableBody>
      </BhCompactTable>
    </div>
  );
};
export default MaruDashboardDeadlinesTable;
