import React, { FC, useEffect, useState } from "react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faLocationDot, faPaperclip } from "@fortawesome/pro-regular-svg-icons";
import { useAppSelector } from "@/app/hooks";
import { ITask } from "@/model/taskBoard/ITask";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import BhInlineInput from "@components/input/BhInlineInput";
import TaskAttachments from "@components/task/modal/TaskAttachments";
import TaskComments from "@components/task/modal/TaskComments";
import { selectOpenedTaskInfo } from "@/app/store/tasksSlice";
import { selectCurrentUser } from "@/app/store/userSlice";
import { isTaskPersonal } from "@/utilities/taskBoardUtil";
import BhAttachmentsUploadContainer from "@components/upload/BhAttachmentsUploadContainer";
import { useTranslation } from "react-i18next";
import PdftronContainerForTaskPreview from "@/views/home/project/detail/pdftron/PdftronContainerForTaskPreview";
import BhFileCardMedium from "@components/cards/BhFileCardMedium";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import SelectFilesModal from "@/views/home/project/projectModals/selectFilesModal/SelectFilesModal";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import TaskModalCategorySelection from "@components/task/modal/TaskModalCategorySelection";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { selectFileById } from "@/app/store/filesSlice";
import FormTaskNotification from "@components/task/modal/FormTaskNotification";
import ChecklistTaskNotification from "@components/task/modal/ChecklistTaskNotification";
import TaskModalNameInput from "@components/task/modal/TaskModalNameInput";

interface Props {
  task: ITask;
  saveTaskCallback: Function;
}

const TaskModalBodyLeft: FC<Props> = ({ task, saveTaskCallback }) => {
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectCurrentUser);
  const openedTaskInfo = useAppSelector(selectOpenedTaskInfo);
  const isTaskDisabled = openedTaskInfo && openedTaskInfo.disabled;
  const isChecklistTask = openedTaskInfo && openedTaskInfo.checklistTask;
  const isFormTask = openedTaskInfo && openedTaskInfo.formTask;
  const isNewTask = !task.id;
  const isPersonalTask = isTaskPersonal(task, currentUser);
  const [deleteAnnotation, setDeleteAnnotation] = useState<boolean>();
  const [selectedDrawing, setSelectedDrawing] = useState<IFileEntity | undefined>();
  const checklistBaseFileEntity = useAppSelector((state) => task.checklistBaseFileId && selectFileById(state, task.checklistBaseFileId));

  const [showRemoveFileAnnotationRelationModal, hideRemoveFileAnnotationRelationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideRemoveFileAnnotationRelationModal}
      header={<h2>{t("TASK.ANNOTATION.DELETE.CONFIRMATION.TITLE")}</h2>}
      body={<div>{t("TASK.ANNOTATION.DELETE.CONFIRMATION.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={() => {
        setDeleteAnnotation(true);
        hideRemoveFileAnnotationRelationModal();
        setSelectedDrawing(undefined);
        saveTaskCallback({ fileAnnotationRelations: [] });
      }}
    />
  ));

  const [showSelectDrawingModal, setShowSelectDrawingModal] = useState(false);

  useEffect(() => {
    // Checklist point marking on selected checklistBaseFileEntity
    checklistBaseFileEntity && setSelectedDrawing(checklistBaseFileEntity);
  }, []);

  return (
    <div className="bh-bg-raised-white flex h-full w-full flex-col overflow-y-auto py-6 pl-8 pr-14 md:overflow-visible">
      {isChecklistTask && isTaskDisabled && <ChecklistTaskNotification task={task} isPersonalTask={isPersonalTask} />}
      {isFormTask && <FormTaskNotification task={task} saveTaskCallback={saveTaskCallback} />}
      <div className="flex flex-row items-center pl-2">
        {!isNewTask && !isPersonalTask && <span className="bh-text-deep-ocean-80 mr-2">{task.taskIdentifier}</span>}
        <TaskModalCategorySelection taskCategory={task.category} saveTaskCallback={saveTaskCallback} />
      </div>
      <div className="pt-3">
        <TaskModalNameInput task={task} saveTaskCallback={saveTaskCallback} disabled={isTaskDisabled} />
      </div>
      <div className="flex flex-row items-center pt-6 pl-1">
        {(!task.fileAnnotationRelations || task.fileAnnotationRelations?.length < 1) && !selectedDrawing && (
          <BhSecondaryButton icon={faLocationDot} buttonProps={{ classes: "mr-2", disabled: isTaskDisabled, onClick: () => setShowSelectDrawingModal(true) }}>
            {t("TASK.ANNOTATE")}
          </BhSecondaryButton>
        )}
        <BhAttachmentsUploadContainer
          saveCallback={saveTaskCallback}
          existingAttachments={task.attachments}
          button={
            <BhTextOnlyButton icon={faPaperclip} buttonProps={{ disabled: isTaskDisabled, classes: "disabled:bh-text-pigeon-60" }}>
              {t("TASK.ADD_ATTACHMENT")}
            </BhTextOnlyButton>
          }
          property={"attachments"}
          disabled={isTaskDisabled}
          multiple={true}
          excludeFileTypes={[FileEntityType.ROOT_DIR, FileEntityType.ROOT_DOCUMENT_DIR, FileEntityType.DIR]}
          dropdownPosition={BhDropdownPositionEnum.BOTTOM_RIGHT}
          fileUploadAllowed={true}
          chooseFromProjectAllowed={true}
        />
      </div>
      {(!task.id || task.fileAnnotationRelations?.length < 1) && selectedDrawing && (
        <div key={selectedDrawing.id} className="mt-6">
          <div className="mb-1 flex flex-row items-center">
            <div className="bh-text-deep-ocean pl-2 pr-3 font-bold">{t("GLOBAL.DRAWING")}</div>
            <BhFileCardMedium fileEntity={selectedDrawing} openInNewTab={true} deleteCallback={() => showRemoveFileAnnotationRelationModal()} />
          </div>
          <div className="h-[400px] w-full">
            <PdftronContainerForTaskPreview
              selectedFileEntityIdForInitialMarking={selectedDrawing.id}
              task={task}
              saveTaskCallback={saveTaskCallback}
              deleteAnnotation={deleteAnnotation}
              setSelectedDrawing={setSelectedDrawing}
              setDeleteAnnotation={setDeleteAnnotation}
            />
          </div>
        </div>
      )}
      {!isNewTask &&
        task.fileAnnotationRelations?.length > 0 &&
        task.fileAnnotationRelations.map((fileAnnotationRelation) => {
          return (
            <div key={fileAnnotationRelation.id} className="mt-6">
              <div className="mb-1 flex flex-row items-center">
                <div className="bh-text-deep-ocean pl-2 pr-3 font-bold">{t("GLOBAL.DRAWING")}</div>
                <BhFileCardMedium
                  fileEntity={fileAnnotationRelation.file}
                  openInNewTab={true}
                  deleteCallback={
                    !isTaskDisabled
                      ? () => {
                          showRemoveFileAnnotationRelationModal();
                        }
                      : undefined
                  }
                />
              </div>
              <div className="h-[200px] w-full">
                <PdftronContainerForTaskPreview fileAnnotationRelation={fileAnnotationRelation} task={task} deleteAnnotation={deleteAnnotation} setDeleteAnnotation={setDeleteAnnotation} />
              </div>
            </div>
          );
        })}
      <div className="pt-6">
        <div className="bh-text-deep-ocean pl-2 pb-2 font-bold">{t("TASK.DESCRIPTION")}</div>
        <BhInlineInput placeholder={t("TASK.DESCRIPTION.PLACEHOLDER") as string} saveCallback={saveTaskCallback} initialValue={task.description} property={"description"} disabled={isTaskDisabled} />
      </div>
      {task.attachments && task.attachments.length > 0 && <TaskAttachments task={task} saveCallback={saveTaskCallback} />}
      {!isNewTask && !isPersonalTask && !task.deleted && <TaskComments task={task} />}

      {showSelectDrawingModal && (
        <SelectFilesModal onSelect={(files: Array<IFileEntity>) => files[0] && setSelectedDrawing(files[0])} setModalOpen={setShowSelectDrawingModal} multiple={false} pdftronOnly={true} />
      )}
    </div>
  );
};

export default TaskModalBodyLeft;
