import React, { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "@/utilities/jsUtilities";
import BhTextarea from "@components/input/BhTextarea";

interface Props {
  initialValue: any;
  property: string;
  onBlurCallback?: Function;
  onChangeCallback?: Function;
  required?: boolean;
  leadingIcon?: IconProp;
  trailingIcon?: IconProp;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  inputClasses?: string;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  triggerSaveWithInitialValue?: boolean;
  rows?: number;
  autoFocus?: boolean;
  trailingButton?: React.ReactElement;
  maxHeightClass?: string;
}

const BhTextareaSimple: FC<Props> = ({
  property,
  initialValue,
  onBlurCallback,
  onChangeCallback,
  required,
  leadingIcon,
  trailingIcon,
  type,
  placeholder,
  disabled,
  inputClasses,
  inputRef,
  triggerSaveWithInitialValue,
  rows,
  autoFocus,
  trailingButton,
  maxHeightClass
}) => {
  return (
    <div className="relative inline-block w-full">
      {leadingIcon && <FontAwesomeIcon icon={leadingIcon} className="w-16px h-16px bh-text-pigeon absolute left-1 top-1 p-1.5" aria-hidden="true" />}
      <BhTextarea
        initialValue={initialValue}
        property={property}
        onBlurCallback={onBlurCallback}
        onChangeCallback={onChangeCallback}
        inputClasses={classNames(leadingIcon && "pl-9", trailingIcon && "pr-9", inputClasses)}
        placeholder={placeholder}
        disabled={disabled}
        inputRef={inputRef}
        triggerSaveWithInitialValue={triggerSaveWithInitialValue}
        required={required}
        rows={rows}
        autoFocus={autoFocus}
        maxHeightClass={maxHeightClass}
      />
      {trailingButton && !trailingIcon && <div className="absolute right-1 top-0">{trailingButton}</div>}
      {trailingIcon && !trailingButton && <FontAwesomeIcon icon={trailingIcon} className="w-16px h-16px bh-text-pigeon absolute right-1 top-1 p-1.5" size="2x" aria-hidden="true" />}
    </div>
  );
};

export default BhTextareaSimple;
