import React, { FC } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons/faEllipsisVertical";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { ITaskComment } from "@/model/taskBoard/ITaskComment";
import { faPen } from "@fortawesome/pro-regular-svg-icons/faPen";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { IFileEntityComment } from "@/model/files/IFileEntityComment";
import { IFileConfirmationComment } from "@/model/confirmations/IFileConfirmationComment";
import { useTranslation } from "react-i18next";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";

interface Props {
  comment: IFileEntityComment | ITaskComment | IFileConfirmationComment;
  editCallback?: Function;
  deleteCallback?: Function;
  downloadCallback?: Function;
  disabled?: boolean;
}

const BhCommentDropdown: FC<Props> = ({ comment, editCallback, deleteCallback, downloadCallback, disabled }) => {
  const { t } = useTranslation();

  if (!comment) {
    return null;
  }

  if (!editCallback && !deleteCallback && !downloadCallback) {
    return null;
  }

  const commentEllipsisDropdownValues = [
    downloadCallback && {
      text: t("COMMENT.DOWNLOAD_ATTACHMENTS"),
      function: () => {
        downloadCallback && downloadCallback(comment);
      },
      icon: faArrowDownToLine
    },
    editCallback && {
      text: t("GLOBAL.CHANGE"),
      function: () => {
        editCallback && editCallback(comment);
      },
      icon: faPen
    },
    deleteCallback && {
      text: t("GLOBAL.DELETE"),
      function: () => {
        deleteCallback && deleteCallback(comment);
      },
      icon: faTrash
    }
  ].filter(Boolean);

  return (
    <BhDropdown
      disabled={disabled}
      button={<BhIconButton icon={faEllipsisVertical} buttonProps={{ disabled: disabled }} />}
      menu={<BhDropdownMenu values={commentEllipsisDropdownValues} type={BhDropdownTypeEnum.STRING} textProperty="text" closeOnItemClick={true} />}
      position={BhDropdownPositionEnum.BOTTOM_LEFT}
    />
  );
};

export default BhCommentDropdown;
