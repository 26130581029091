import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import BhInput from "@components/input/BhInput";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faLink } from "@fortawesome/pro-regular-svg-icons/faLink";
import { copyToClipboard } from "@/utilities/jsUtilities";
import { useAppSelector } from "@/app/hooks";
import { selectShareboxById } from "@/app/store/shareboxesSlice";
import { useTranslation } from "react-i18next";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  shareboxId: EntityId;
}
const ShareboxDetailPublicLink: FC<Props> = ({ shareboxId }) => {
  const { t } = useTranslation();
  const sharebox = useAppSelector((state) => selectShareboxById(state, shareboxId));

  if (!sharebox) return null;

  const shareboxPublicUrl = ConfigSingleton.getInstance().getConfig().REACT_APP_BASE_URL + "/public/sharebox/" + sharebox.uuid;

  return (
    <div className="flex flex-row items-center">
      <h3 className="m-0 w-1/4 py-3 px-6">{t("SHAREBOX.PUBLIC")}</h3>
      {sharebox?.uuid && (
        <div className="flex w-3/4 flex-row items-center">
          <BhInput inputClasses="overflow-ellipsis" initialValue={shareboxPublicUrl} property={"docDate"} saveCallback={() => {}} readOnly={true} />
          <div>
            <BhSecondaryButton icon={faLink} buttonProps={{ onClick: () => copyToClipboard(shareboxPublicUrl, "TOAST.LINK_COPIED") }}>
              {t("GLOBAL.COPY")}
            </BhSecondaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareboxDetailPublicLink;
