import React, { Dispatch, FC, ReactNode, SetStateAction } from "react";
import BhModal from "@components/modal/BhModal";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhWarningButton from "@components/buttons/BhWarningButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/pro-solid-svg-icons/faWarning";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen?: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  header: ReactNode;
  body: ReactNode;
  confirmationButtonText: ReactNode;
  handleDelete?: Function;
  confirmDisabled?: boolean;
  cancelButtonText?: ReactNode;
}

const BhDeleteConfirmationModal: FC<Props> = ({ isOpen, setIsOpen, header, body, confirmationButtonText, handleDelete, confirmDisabled, cancelButtonText }) => {
  const { t } = useTranslation();

  return (
    <BhModal
      isShown={isOpen}
      setIsShown={setIsOpen}
      header={
        <div className="inline-flex items-center">
          <FontAwesomeIcon className="bh-text-warning-yellow mt-1 pr-5" icon={faWarning} />
          {header}
        </div>
      }
      children={<div className="px-8 py-6">{body}</div>}
      footer={
        <div className="flex flex-row items-center">
          <BhTextOnlyButton buttonProps={{ onClick: () => setIsOpen(false) }}>
            {cancelButtonText && cancelButtonText}
            {!cancelButtonText && t("GLOBAL.CANCEL")}
          </BhTextOnlyButton>
          <BhWarningButton buttonProps={{ onClick: handleDelete, disabled: confirmDisabled }}>{confirmationButtonText}</BhWarningButton>
        </div>
      }
    />
  );
};

export default BhDeleteConfirmationModal;
