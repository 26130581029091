import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft";
import BhIconButton from "@components/buttons/BhIconButton";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const BhGoBackButton: FC = () => {
  const navigate = useNavigate();

  return <BhIconButton icon={faArrowLeft} buttonProps={{ onClick: () => navigate(-1) }} />;
};

export default BhGoBackButton;
