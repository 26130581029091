import React, { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhFilterContainer from "@components/filters/BhFilterContainer";
import { selectShareboxesFilter, setShareboxFilter, toggleDisplayExpired } from "@/app/store/shareboxesSlice";
import { IShareboxesFilter } from "@/model/shareboxes/IShareboxesFilter";
import BhFilterWithToggle from "@components/filters/BhFilterWithToggle";
import { useTranslation } from "react-i18next";
import ShareboxSort from "@/views/home/project/detail/shareboxes/ShareboxSort";
import ShareboxFilterByReceivers from "@/views/home/project/detail/shareboxes/shareboxListFilter/ShareboxFilterByReceivers";
import ShareboxFilterByCreators from "@/views/home/project/detail/shareboxes/shareboxListFilter/ShareboxFilterByCreators";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";

interface Props {
  userShareboxes: boolean;
}

const ShareboxFilter: FC<Props> = ({ userShareboxes }) => {
  const { t } = useTranslation();
  const filter = useAppSelector(selectShareboxesFilter);
  const projectId = useAppSelector(selectCurrentProjectId);
  const isAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const dispatch = useAppDispatch();

  const onSearchQueryFilterChange = useCallback(
    (changedValue: IShareboxesFilter) => {
      const changedObject = { ...filter, ...changedValue };
      dispatch(setShareboxFilter(changedObject));
    },
    [filter]
  );

  const toggleExpired = useCallback(() => {
    dispatch(toggleDisplayExpired());
  }, [filter]);

  const showFilterByCreators = !userShareboxes && isAdmin;

  return (
    <div className="flex flex-col pb-2">
      <BhFilterContainer>
        <div>
          <BhSearchInputWBG initialValue={filter.searchKey} property="searchKey" onChangeCallback={onSearchQueryFilterChange} placeholder={`${t("SEARCH.INPUT_PLACEHOLDER_SHAREBOX")}`} />
        </div>
        {showFilterByCreators && <ShareboxFilterByCreators />}
        <ShareboxFilterByReceivers userShareboxes={userShareboxes} />
        <div className="inline-block">
          <BhFilterWithToggle text={t("SHAREBOX.LIST.SHOW_EXPIRED")} value={filter.displayExpired} onClickAction={toggleExpired} />
        </div>
      </BhFilterContainer>
      <ShareboxSort />
    </div>
  );
};

export default ShareboxFilter;
