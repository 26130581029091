import React, { FC } from "react";
import { Trans } from "react-i18next";
import BhLargeText from "@components/text/BhLargeText";
import noMatchesFox from "@img/rebane-p6hu-peal-compressed.png";

const CompanyContainerNoProjects: FC = () => {
  return (
    <div className="bh-bg-smoke-50 flex max-w-[580px] flex-row overflow-hidden">
      <div className="flex h-16 w-1/3 flex-col items-center p-2">
        <img className="w-[80%]" src={noMatchesFox} alt="No matches" />
      </div>
      <div className="w-2/3 p-4 text-center">
        <BhLargeText>
          <Trans>COMPANY.NO_PROJECTS</Trans>
        </BhLargeText>
      </div>
    </div>
  );
};

export default CompanyContainerNoProjects;
