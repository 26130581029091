import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhIFrameComponent from "@/views/global/BhIFrameComponent";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  fileEntityId: EntityId;
  dirId: EntityId;
}

const FormContainerForOldFE: FC<Props> = ({ fileEntityId, dirId }) => {
  const projectId = useAppSelector(selectCurrentProjectId);

  return <BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/dir/${dirId}/form/${fileEntityId}/react`} />;
};

export default FormContainerForOldFE;
