import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  image: any;
  text: string;
  index: number;
  onClickCallback: Function;
  classes?: string;
}

const FormBaseFieldsAddContainer: FC<Props> = ({ image, text, onClickCallback, index, classes }) => {
  return (
    <div className={classNames("bh-border-pigeon-60 hover:bh-bg-mint-30 h-32 w-32 cursor-pointer", index !== 0 && "border-l", classes)} onClick={() => onClickCallback()}>
      <div className="flex h-full flex-col justify-between pt-4">
        <div className="flex w-full flex-row justify-center">{image}</div>
        <div className="flex w-full flex-row justify-center pb-4">
          <div className="text-14px bh-text-deep-ocean leading-5">{text}</div>
        </div>
      </div>
    </div>
  );
};

export default FormBaseFieldsAddContainer;
