import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import BhTaskContainer from "@components/task/container/BhTaskContainer";
import { selectTaskById } from "@/app/store/tasksSlice";
import { useAppSelector } from "@/app/hooks";

interface Props {
  taskId: EntityId;
}

const SearchResultsTaskContainer: FC<Props> = ({ taskId }) => {
  const task = useAppSelector((state) => selectTaskById(state, taskId));

  if (!task) return null;

  return <BhTaskContainer taskId={taskId} isSearchView={true} />;
};

export default SearchResultsTaskContainer;
