import React, { FC } from "react";
import { IUser } from "@/model/IUser";
import BhUserIcon from "@components/user/BhUserIcon";
import { IFileEntityComment } from "@/model/files/IFileEntityComment";
import { IFileConfirmationComment } from "@/model/confirmations/IFileConfirmationComment";
import { formatDateTime } from "@/utilities/dateUtility";

interface Props {
  comment: IFileEntityComment | IFileConfirmationComment;
}

const BhCommentUserIconWithName: FC<Props> = ({ comment }) => {
  const firstName = comment.authorFullName?.split(" ")[0] || "";
  const lasName = comment.authorFullName?.split(" ")[1] || "";
  const userEntity = { firstName: firstName, lastName: lasName } as IUser;

  return (
    <div className="flex flex-row items-center whitespace-nowrap">
      <BhUserIcon user={{ ...userEntity, userExists: true }} />
      <div className="mx-1 ml-2">
        <div>
          <strong>{comment.authorFullName}</strong>
        </div>
        <div>
          <span className="bh-text-deep-ocean-80 text-sm">{formatDateTime(comment.created)}</span>
        </div>
      </div>
    </div>
  );
};

export default BhCommentUserIconWithName;
