import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IBauhubEvent } from "@/model/IBauhubEvent";
import { ISalesInvoice } from "@/model/billing/ISalesInvoice";
import { selectCompanyBillsFilterSortBy, selectCompanyBillsFilterSortOrderAsc, toggleCompanyBillsSortOrder } from "@/app/store/companiesSlice";
import BhTableHeader from "@components/table/BhTableHeader";
import CompanyBillsTableRowContainer from "@/views/home/company/detail/CompanyBillsTableRowContainer";
import { useTranslation } from "react-i18next";

interface Props {
  invoices: Array<ISalesInvoice>;
  setInvoices: Function;
}

const CompanyBillsTableContainer: FC<Props> = ({ invoices, setInvoices }) => {
  const sortedBy = useAppSelector(selectCompanyBillsFilterSortBy);
  const sortReversed = useAppSelector(selectCompanyBillsFilterSortOrderAsc);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const tableHeaderOnClick = (column: IBhTableHeader<IBauhubEvent>) => {
    if (column.field) {
      dispatch(toggleCompanyBillsSortOrder(column.field));
    }
  };

  const sortInvoices = (a: any, b: any) => {
    return a[sortedBy] > b[sortedBy] ? (sortReversed ? 1 : -1) : sortReversed ? -1 : 1;
  };

  const tableColumnHeaders: Array<IBhTableHeader<ISalesInvoice>> = [
    { id: 1, content: "COMPANY.SETTINGS.ORDER.INVOICES.DATE", field: "invoiceDate", sortable: true, classes: "w-28" },
    { id: 2, content: "COMPANY.SETTINGS.ORDER.INVOICES.INVOICE_NR", field: "fullInvoiceNumber", sortable: true, classes: "w-32" },
    { id: 3, content: "COMPANY.SETTINGS.ORDER.INVOICES.PROJECT", field: "description", sortable: true, classes: "w-80" },
    { id: 4, content: "COMPANY.SETTINGS.ORDER.INVOICES.DUE_DATE", field: "billingDueDate", sortable: true, classes: "w-28" },
    { id: 5, content: "COMPANY.SETTINGS.ORDER.INVOICES.SUM", field: "sumWithoutVat", sortable: true, classes: "w-28" },
    { id: 6, content: "COMPANY.SETTINGS.ORDER.INVOICES.STATUS", field: "paid", sortable: false, classes: "w-44" }
  ];

  return (
    <div className="flex w-full min-w-[800px] flex-col pb-36">
      <table className="w-full table-fixed">
        <BhTableHeader columns={tableColumnHeaders} onClickCallback={tableHeaderOnClick} sortedBy={sortedBy} reversed={sortReversed} translateContent={true} />
        <tbody className="divide-y">
          {invoices
            .sort((a, b) => sortInvoices(a, b))
            .map((invoice) => (
              <CompanyBillsTableRowContainer key={invoice.id} invoice={invoice} />
            ))}
          {invoices.length === 0 && (
            <tr>
              <td colSpan={10} className="center pt-10 text-center">
                <h2>{t("COMPANY.SETTINGS.ORDER.INVOICES.NO_INVOICES")}</h2>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CompanyBillsTableContainer;
