import React, { FC, useEffect, useState } from "react";
import BhTableRow from "@components/table/BhTableRow";
import { IChecklistData } from "@/model/checklist/IChecklistStatistics";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { IFileEntity } from "@/model/files/IFileEntity";
import { formatDateTime } from "@/utilities/dateUtility";
import BhTableRowVersion from "@components/table/BhTableRowVersion";
import { classNames } from "@/utilities/jsUtilities";
import BhBadge from "@components/badge/BhBadge";
import BhTags from "@components/tags/BhTags";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { selectChecklistStatisticsFilter } from "@/app/store/checklist/checklistsSlice";
import { naturalSortString } from "@/utilities/sortUtilities";

interface Props {
  checklistData: IChecklistData;
  isRound?: boolean;
}

const ChecklistStatisticsRow: FC<Props> = ({ checklistData, isRound }) => {
  const [isVersionRowOpen, setIsVersionRowOpen] = useState<boolean>(false);
  const filter = useAppSelector(selectChecklistStatisticsFilter);
  const projectId = useAppSelector(selectCurrentProjectId);
  const navigate = useNavigate();

  useEffect(() => {
    if (isVersionRowOpen) setIsVersionRowOpen(false);
  }, [filter]);

  if (!projectId) return null;

  const firstCheckAsDate = new Date(checklistData.firstCheck);
  const lastCheckAsDate = new Date(checklistData.lastCheck);
  const differentFirstAndLastCheckDate = checklistData.lastCheck && firstCheckAsDate.getDate() !== lastCheckAsDate.getDate();

  const roundRows = checklistData.checklistRounds?.sort((a, b) => (a.roundNumber < b.roundNumber ? 1 : -1)) || [];

  const faults = checklistData.rounds === 1 && !isRound ? checklistData.checklistRounds[0].faults : checklistData.faults;
  const sortedFaults = faults && faults.sort((a, b) => naturalSortString(a, b));

  return (
    <>
      <BhTableRow classes={classNames(isRound && "bh-bg-smoke-50")}>
        <td>
          <div className={classNames(isRound && "pl-8", "flex w-full flex-row items-center space-x-2")}>
            <BhFileCardIcon fileEntity={{ id: 0, name: checklistData.name, type: "CHECKLIST" } as IFileEntity} fileCardSize={FileCardSize.LARGE} />
            <div className="ml-2 flex flex-col">
              <div
                className="text-14px bh-text-deep-ocean cursor-pointer leading-5 hover:underline"
                onClick={() => navigate(`/project/${projectId}/checklists/${checklistData.checklistId}/round/${checklistData.roundId}`)}
              >
                {checklistData.name}
              </div>
              {checklistData.checklistTagList && checklistData.checklistTagList.length > 0 && (
                <BhTags tags={checklistData.checklistTagList} type={BhTagType.TAG} property={"name"} sortByProperty={true} />
              )}
            </div>
          </div>
        </td>
        <td className="text-center">
          <BhTableRowVersion
            versionNumber={checklistData.rounds}
            isActive={isVersionRowOpen}
            allVersionsVisible={isVersionRowOpen}
            otherVersionsExist={!isRound && checklistData.rounds > 1}
            onClick={() => setIsVersionRowOpen(!isVersionRowOpen)}
          />
        </td>
        <td>
          {formatDateTime(checklistData.firstCheck)} {differentFirstAndLastCheckDate && ` - ${formatDateTime(checklistData.lastCheck)}`}
        </td>
        <td>{checklistData.faultsCount}</td>
        <td>{checklistData.newFaultsCount}</td>
        <td>
          <div className="flex flex-row flex-wrap gap-1">
            {sortedFaults?.map((fault, index) => (
              <BhBadge key={index}>{fault}</BhBadge>
            ))}
          </div>
        </td>
      </BhTableRow>

      {isVersionRowOpen && roundRows.map((round, index) => <ChecklistStatisticsRow key={index} checklistData={round} isRound={true} />)}
    </>
  );
};

export default ChecklistStatisticsRow;
