import React, { FC } from "react";
import { IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";

interface Props {
  baseVersionRow: IChecklistBaseVersionRow;
}

const ChecklistRowWysiwyg: FC<Props> = ({ baseVersionRow }) => {
  return <div className="mb-3 w-4/5" dangerouslySetInnerHTML={{ __html: baseVersionRow.fieldValue }} />;
};

export default ChecklistRowWysiwyg;
