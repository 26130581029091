import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { toggleTaskBoardModalsOpen } from "@/app/store/taskBoardsSlice";
import BhModal from "@components/modal/BhModal";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveProjectTaskCategoryAsync, selectProjectTaskCategoriesSorted } from "@/app/store/tasksSlice";
import CategoriesModalRow from "@components/taskBoard/modals/CategoriesModalRow";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { CategoryDefaultCode, IProjectTaskCategory } from "@/model/taskBoard/IProjectTaskCategory";
import { useTranslation } from "react-i18next";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { EntityId } from "@reduxjs/toolkit";
import { fetchProjectTaskCategories, saveProjectTaskCategory } from "@/api/taskAPI";

interface Props {
  projectId?: EntityId;
  isShown?: boolean;
  setIsShown?: Dispatch<SetStateAction<boolean>>;
}
// Siia sai siis selline dubleeritud lahendus, et juhul kui antakse propsides projectId kaasa, siis kasutatakse seda ja tehaks toimingud ilma slice'ta lokaalselt, kui ei anta kaasa siis kasutatakse hetkel avatud projekti. See oli vajalik, kuna meil on vaja ka ettevõtte seadete alt projekti kategooriaid muuta, ehk olukorras, kus projekti ei ole avatud.

const ProjectTaskCategoryModal: FC<Props> = ({ projectId, isShown, setIsShown }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentProjectTaskCategories = useAppSelector(selectProjectTaskCategoriesSorted);
  const currentProjectId = useAppSelector(selectCurrentProjectId);
  const [categories, setCategories] = useState<Array<IProjectTaskCategory>>([]);
  const projectIdUsed = projectId !== undefined ? projectId : currentProjectId;
  const projectTaskCategoriesUsed = projectId !== undefined ? categories : currentProjectTaskCategories;

  const usedColors =
    projectTaskCategoriesUsed && projectTaskCategoriesUsed.length > 0
      ? projectTaskCategoriesUsed.map((category) => {
          return category.color;
        })
      : [];

  const availableColors = ["#00B95F", "#003232", "#0050E1", "#68737A", "#C566E8", "#FFA500", "#FF0000", "#4AE8DB", "#FDDD00", "#8B4513"].filter((color) => {
    return !usedColors.some((usedColor) => {
      return usedColor === color;
    });
  });
  const [isNewShown, setIsNewShown] = useState(false);
  const [newCategory, setNewCategory] = useState({ name: "", color: "", projectId: projectIdUsed } as IProjectTaskCategory);

  useEffect(() => {
    if (projectId) {
      fetchProjectTaskCategories(projectId).then((response) => {
        const defaultCategory = response.find((cat) => cat.code === CategoryDefaultCode.TASK);
        response = response.filter((cat) => cat.code !== CategoryDefaultCode.TASK).sort((a, b) => (a.id > b.id ? 1 : -1));
        defaultCategory && response.unshift(defaultCategory);
        setCategories(response);
      });
    }
  }, []);

  const closeModal = () => {
    if (setIsShown) {
      setIsShown(false);
      return;
    }
    dispatch(toggleTaskBoardModalsOpen({ modal: "projectTaskCategoryModal" }));
  };

  const addNewCategory = () => {
    const newCategoryColor = availableColors[0];
    setNewCategory({ ...newCategory, ...{ color: newCategoryColor } });
    setIsNewShown(true);
  };

  const saveCategoryColor = (category: IProjectTaskCategory) => {
    if (!category.id) {
      setNewCategory(category);
    } else {
      saveTaskCategory(category);
    }
  };

  const saveTaskCategory = (category: IProjectTaskCategory) => {
    if (projectId) {
      saveProjectTaskCategory(category).then((response) => {
        setCategories((oldValues) => {
          if (category.deleted) {
            return oldValues.filter((o) => o.id !== category.id);
          }
          return oldValues.filter((o) => o.id !== category.id).concat(category);
        });
      });
    } else {
      dispatch(saveProjectTaskCategoryAsync(category));
    }
  };

  const saveCategoryName = (category: IProjectTaskCategory) => {
    if (!category.id && !category.name) {
      return;
    }
    const sameNameExists =
      projectTaskCategoriesUsed &&
      projectTaskCategoriesUsed.some((cat) => {
        return cat.name.toLowerCase() === category.name.toLowerCase();
      });
    if (sameNameExists) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          children: t("TASK.CATEGORY.ALREADY_EXISTS.ERROR")
        })
      );
      return;
    }
    if (!category.id) {
      setNewCategory({ name: "", color: "", projectId: projectIdUsed } as IProjectTaskCategory);
      setIsNewShown(false);
    }
    saveTaskCategory(category);
  };

  const deleteCategory = (category: IProjectTaskCategory) => {
    if (category.id) {
      saveTaskCategory({ ...category, ...{ deleted: true } });
    } else {
      setNewCategory({ name: "", color: "", projectId: projectId } as IProjectTaskCategory);
      setIsNewShown(false);
    }
  };

  return (
    <BhModal
      size="lg"
      isShown={isShown !== undefined ? isShown : true}
      setIsShown={setIsShown !== undefined ? setIsShown : () => {}}
      onClose={closeModal}
      header={
        <div className="h-7 pl-4">
          <h2 className="mt-1 leading-7">{t("TASK.CATEGORIES")}</h2>
        </div>
      }
      children={
        <div className="px-9 py-4">
          {projectTaskCategoriesUsed &&
            projectTaskCategoriesUsed.length > 0 &&
            projectTaskCategoriesUsed.map((category) => {
              return (
                <CategoriesModalRow category={category} key={category.id} availableColors={availableColors} saveColor={saveCategoryColor} saveName={saveCategoryName} deleteCallback={deleteCategory} />
              );
            })}
          {isNewShown && projectTaskCategoriesUsed && (
            <CategoriesModalRow category={newCategory} availableColors={availableColors} saveColor={saveCategoryColor} saveName={saveCategoryName} deleteCallback={deleteCategory} />
          )}
          {availableColors.length > 0 && !isNewShown && (
            <BhTextOnlyButton icon={faPlus} buttonProps={{ onClick: addNewCategory, classes: "ml-20" }}>
              {t("TASK.ADD.CATEGORY")}
            </BhTextOnlyButton>
          )}
        </div>
      }
      footer={
        <div>
          <BhTextOnlyButton buttonProps={{ onClick: () => closeModal() }}>{t("GLOBAL.CLOSE")}</BhTextOnlyButton>
        </div>
      }
    />
  );
};

export default ProjectTaskCategoryModal;
