import React, { FC } from "react";
import { IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import BhInputSimple from "@components/input/BhInputSimple";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

interface Props {
  checklistBaseId: EntityId;
  baseVersionRow: IChecklistBaseVersionRow;
  saveCallback: Function;
  disabled?: boolean;
}

const ChecklistBaseRowCheckpointList: FC<Props> = ({ baseVersionRow, saveCallback, disabled, checklistBaseId }) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full flex-row p-4">
      <BhInputSimple
        initialValue={baseVersionRow.fieldTitle}
        property={"fieldTitle"}
        placeholder={t("CHECKLIST_BASE.FAULT_LIST.PLACEHOLDER") as string}
        onBlurAction={saveCallback}
        disabled={disabled}
      />
    </div>
  );
};

export default ChecklistBaseRowCheckpointList;
