import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import FileCommentsDetailView from "@/views/home/project/detail/directory/fileInfoBar/fileInfoBarDetailViews/FileCommentsDetailView";

interface Props {
  fileEntityId: EntityId;
  hideSidebar: Function;
}

const CommentsSidebar: FC<Props> = ({ fileEntityId, hideSidebar }) => {
  return (
    <div className="flex w-72 flex-col border-l">
      <div className="flex h-full flex-col overflow-hidden">
        <div className="flex-1 overflow-auto">
          <FileCommentsDetailView fileEntityId={fileEntityId} closeButtonAction={hideSidebar} />
        </div>
      </div>
    </div>
  );
};

export default CommentsSidebar;
