import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import { IBhSort } from "@/model/IBhSort";
import { ISharebox } from "@/model/shareboxes/ISharebox";
import { useAppSelector } from "@/app/hooks";
import { selectShareboxesSort, shareboxesSortSet } from "@/app/store/shareboxesSlice";
import BhSortButton from "@components/sort/BhSortButton";

const ShareboxSort: FC = () => {
  const { t } = useTranslation();
  const [shareboxOrdering, setShareboxOrdering] = useLocalStorage<IBhSort<ISharebox>>("shareboxOrdering", {
    property: "name" as keyof ISharebox,
    reversed: false
  });
  const sort = useAppSelector(selectShareboxesSort);

  const dropdownMenuValues = [
    { value: "name", translation: t("GLOBAL.ITEM_NAME") },
    { value: "created", translation: t("GLOBAL.CREATED") },
    { value: "validTo", translation: t("SHAREBOX.SORT.VALID_TO") }
  ];

  return (
    <div className="flex items-center">
      <BhSortButton sort={sort} sortValues={dropdownMenuValues} localStorageSort={shareboxOrdering} setSortForLocalStorage={setShareboxOrdering} setSortForStore={shareboxesSortSet} />
    </div>
  );
};

export default ShareboxSort;
