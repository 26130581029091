import React, { FC, useCallback, useEffect, useState } from "react";
import { fetchRelatedUsersInProjectWithSearch } from "@/api/projectAPI";
import { convertSimpleValidUserAuthorityToUser } from "@/model/ISimpleValidUserAuthority";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { useTranslation } from "react-i18next";
import BhFilter from "@components/filters/BhFilter";
import {
  clearShareboxCreatorsFilter,
  creatorToggledInShareboxFilter,
  selectProjectShareboxesFilterCreatorsValues,
  selectShareboxesCreatorsFilter,
  setShareboxCreatorsFilter
} from "@/app/store/shareboxesSlice";
import { IShareboxCreatorsFilter } from "@/model/shareboxes/IShareboxesFilter";
import { BhFilterTypeEnum } from "@components/filters/BhFilterTypeEnum";
import { IUser } from "@/model/IUser";

const ShareboxFilterByCreators: FC = () => {
  const { t } = useTranslation();
  const [creators, setCreators] = useState<Array<IUser>>([]);
  const projectId = useAppSelector(selectCurrentProjectId);
  const creatorsFilterValues = useAppSelector((state) => selectProjectShareboxesFilterCreatorsValues(state, projectId));
  const creatorsFilter = useAppSelector(selectShareboxesCreatorsFilter);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (creatorsFilterValues) {
      fetchRelatedUsersInProjectWithSearch(projectId).then((users) => {
        const convertedUsers = users.map((usr) => convertSimpleValidUserAuthorityToUser(usr));
        const dropdownValues = convertedUsers.filter((user) => creatorsFilterValues.some((creator) => creator.toLowerCase() === user.username.toLowerCase()));
        setCreators(dropdownValues);
      });
    }
  }, []);

  const { creatorsSearchKey, creatorsDropdown } = creatorsFilter;

  const filteredCreators = creators.filter((creator) => {
    if (!creatorsSearchKey || creatorsSearchKey === "") return true;
    const firstNameFilter = creator.firstName.toLowerCase().includes(creatorsSearchKey.toLowerCase());
    const lastNameFilter = creator.lastName.toLowerCase().includes(creatorsSearchKey.toLowerCase());
    const emailFilter = creator.username.toLowerCase().includes(creatorsSearchKey.toLowerCase());
    return firstNameFilter || lastNameFilter || emailFilter;
  });

  const onShareboxCreatorsFilterSelect = useCallback(
    (dropdownValue: IUser) => {
      dispatch(creatorToggledInShareboxFilter(dropdownValue));
    },
    [creatorsFilter]
  );

  const onShareboxCreatorSearchInputChange = useCallback(
    (searchString: IShareboxCreatorsFilter) => {
      dispatch(setShareboxCreatorsFilter(searchString.creatorsSearchKey));
    },
    [creatorsFilter]
  );

  const onShareboxCreatorsFilterReset = useCallback(() => {
    dispatch(clearShareboxCreatorsFilter());
  }, [creatorsFilter]);

  const isSelected = (value: IUser) => {
    return creatorsDropdown.some((selectedCreator) => selectedCreator.id === value.id);
  };

  return (
    <BhFilter
      title={t("SHAREBOX.FILTER.CREATED_BY")}
      values={filteredCreators}
      filterType={BhFilterTypeEnum.USER}
      onSelect={onShareboxCreatorsFilterSelect}
      search={true}
      searchInputSaveCallback={onShareboxCreatorSearchInputChange}
      selectedValues={creatorsDropdown.length}
      searchInputProperty="creatorsSearchKey"
      dropdownSearchInitialValue={creatorsFilter.creatorsSearchKey}
      isSelected={isSelected}
      onReset={onShareboxCreatorsFilterReset}
      dropdownWidthClass="min-w-max"
      closeOnItemClick={false}
    />
  );
};

export default ShareboxFilterByCreators;
