import React, { FC } from "react";
import BhInputSimple from "@components/input/BhInputSimple";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons/faMagnifyingGlass";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";

export interface IBhSearchInputProps {
  property: string;
  inputClasses?: string;
  containerClasses?: string;
  initialValue?: any;
  saveCallback?: Function;
  placeholder?: string;
  onEnterCallback?: Function;
  onChangeCallback?: Function;
  autoFocus?: boolean;
  onFocus?: Function;
  onBlurAction?: Function;
  inputRef?: React.RefObject<HTMLInputElement>;
  withoutBorder?: boolean;
  debounceLength?: number;
  disabled?: boolean;
}

// FIGMA https://www.figma.com/file/A4lwyy6UKvTJt2uzy33W6H/Bauhub-design-system?node-id=284%3A2448

const BhSearchInput: FC<IBhSearchInputProps> = ({
  property,
  inputClasses,
  containerClasses,
  initialValue,
  saveCallback,
  placeholder,
  onEnterCallback,
  onChangeCallback,
  autoFocus,
  onFocus,
  onBlurAction,
  inputRef,
  withoutBorder,
  debounceLength,
  disabled
}) => {
  const clearFilter = () => {
    onChangeCallback && onChangeCallback({ [property]: "" });
  };

  const showClearFilterButton = initialValue?.length > 0;

  return (
    <div className="relative w-full">
      <BhInputSimple
        inputRef={inputRef}
        property={property}
        initialValue={initialValue}
        saveCallback={saveCallback}
        placeholder={placeholder}
        leadingIcon={faMagnifyingGlass}
        inputClasses={classNames(withoutBorder ? "" : "bh-border-white-important hover:bh-border-pigeon-20", "h-9 not-focus:hover:bh-bg-pigeon-20 filled:bh-bg-smoke", inputClasses)}
        containerClasses={containerClasses}
        onEnterCallback={onEnterCallback}
        onChangeCallback={onChangeCallback}
        autoFocus={autoFocus}
        onFocus={onFocus}
        onBlurAction={onBlurAction}
        withoutBorder={withoutBorder}
        debounceLength={debounceLength}
        disabled={disabled}
      />
      {showClearFilterButton && (
        <div className="absolute right-3 top-2.5 flex">
          <FontAwesomeIcon
            onClick={clearFilter}
            icon={faXmark}
            className={classNames("hover:bh-bg-deep-ocean bh-text-deep-ocean h-4 w-4 cursor-pointer rounded-full hover:text-white")}
            size="1x"
            aria-hidden="true"
          />
        </div>
      )}
    </div>
  );
};

export default BhSearchInput;
