import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  fileDraggedToDirectorySet,
  fileEntitySelected,
  onlyThisFileEntitySelected,
  selectAllRevisionFileEntityIdsForFile,
  selectFileById,
  selectFilesSortDateField,
  setDirectoryModalsOpen,
  tagToggledInFilesFilter,
  toggleFavouriteFileAsync
} from "@/app/store/filesSlice";
import { classNames } from "@/utilities/jsUtilities";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import FileNameLink from "@/features/fileContainer/FileNameLink";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import BhSignatureContainerStatus from "@components/container/BhSignatureContainerStatus";
import FileContainerDropdown from "@/features/fileContainer/FileContainerDropdown";
import { EntityId } from "@reduxjs/toolkit";
import BhFileConfirmationStatus from "@components/confirmation/BhFileConfirmationStatus";
import BhFileCardDateWithName from "@components/cards/BhFileCardDateWithName";
import { shallowEqual } from "react-redux";
import BhFileCardRevisionNumber from "@components/cards/BhFileCardRevisionNumber";
import { IAuthorityBoolean } from "@/model/IUser";
import FileContainerSearchViewActions from "@/features/fileContainer/FileContainerSearchViewActions";
import { useTranslation } from "react-i18next";
import { isDirectory } from "@/utilities/fileEntity/fileEntityUtilities";
import BhTooltip from "@components/BhTooltip";
import BhButtonTemplateWithIcon from "@components/buttons/BhButtonTemplateWithIcon";
import { faBookmark as faBookmarkSolid } from "@fortawesome/pro-solid-svg-icons/faBookmark";
import { faBookmark as faBookmarkRegular } from "@fortawesome/pro-regular-svg-icons/faBookmark";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import { fetchUrlForFile } from "@/api/fileAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { useDrop } from "react-dnd";
import FileContainerIconDraggable from "@/features/fileContainer/FileContainerIconDraggable";
import RevisionFileContainerIcon from "@/features/fileContainer/RevisionFileContainerIcon";

interface Props {
  fileId: EntityId;
  privilegesForDirectory: IAuthorityBoolean;
  isRevision?: boolean;
  isSearchView?: boolean;
  highlighted?: boolean;
}

const FileContainerFC: FC<Props> = ({ fileId, isRevision, privilegesForDirectory, isSearchView, highlighted }) => {
  const fileEntity = useAppSelector((state) => selectFileById(state, fileId));
  const revisionFileEntityIds = useAppSelector((state) => selectAllRevisionFileEntityIdsForFile(state, fileId), shallowEqual);
  const [revisionsShown, setRevisionShown] = useState(false);
  const dispatch = useAppDispatch();
  const filesSortDateField = useAppSelector(selectFilesSortDateField);
  const { t } = useTranslation();

  const isFolder = fileEntity && isDirectory(fileEntity);

  const [{ isOver, isAnyDragging }, directoryDropRef] = useDrop({
    accept: "fileEntityRow",
    drop: (droppedFile: IFileEntity) => {
      if (fileEntity) {
        dispatch(fileDraggedToDirectorySet(fileEntity));
        dispatch(setDirectoryModalsOpen({ modal: "moveModal", value: true }));
      }
    },
    collect: (monitor) => ({
      isOver: isFolder && monitor.isOver(),
      isAnyDragging: isFolder && monitor.canDrop()
    })
  });

  const handleChange = (id: EntityId, e: { target: { checked: any } }) => {
    if (!privilegesForDirectory.isRead) return;
    if (isRevision) return;
    dispatch(fileEntitySelected({ ids: [id], selected: e.target.checked }));
  };

  const selectCurrentRowIfIsRead = (id: EntityId, e: React.MouseEvent<HTMLDivElement>) => {
    if (!privilegesForDirectory.isRead) return;
    if (isRevision) return;
    e.stopPropagation();

    if (e.shiftKey) {
      dispatch(fileEntitySelected({ ids: [id], selected: !fileEntity?.selected }));
      let sel = window.getSelection();
      sel?.removeAllRanges();
      return;
    }

    dispatch(onlyThisFileEntitySelected({ id: id }));
  };

  const handleFavouriteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    fileEntity && dispatch(toggleFavouriteFileAsync(fileEntity));
  };

  const downloadFile = () => {
    if (fileEntity) {
      fetchUrlForFile(fileEntity.id, false, true, fileEntity.uuid).then((presignedUrl) => {
        imitateUrlDownload(presignedUrl.value);
      });
    }
  };

  const folderIsNotReadableButContainsFoldersForPartyUser = !privilegesForDirectory.isRead && !isFolder;

  if (!fileEntity || folderIsNotReadableButContainsFoldersForPartyUser) {
    return null;
  }

  // DO NOT CHANGE THE ELEMENT HEIGHT IN ANY CIRCUMSTANCE
  return (
    <>
      <div
        className={classNames(
          fileEntity.selected ? "bh-bg-mint" : highlighted ? "bh-bg-warning-yellow-10" : "bh-bg-white",
          isRevision ? "bh-bg-smoke-50 ml-8" : !fileEntity.selected && "hover:bh-bg-mint-30",
          "bh-border-pigeon-40 bh-hover-container group block flex h-14 items-center border-b"
        )}
        onClick={(e) => selectCurrentRowIfIsRead(fileEntity.id, e)}
      >
        <div className={classNames(fileEntity.selected && "bh-bg-bauhub-green-120", "h-full w-0.5")} />
        {!isRevision && (
          <div className="flex h-full w-8 flex-none items-center justify-center" onClick={(e) => e.stopPropagation()}>
            {privilegesForDirectory.isRead && (
              <input
                className="hover:bh-border-deep-ocean bh-border-pigeon-60 h-5 w-5 rounded focus:ring-0 focus:ring-offset-0"
                type="checkbox"
                checked={fileEntity.selected || false}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => handleChange(fileEntity.id, e)}
              />
            )}
          </div>
        )}
        {!isRevision && !isSearchView && <FileContainerIconDraggable fileEntity={fileEntity} privilegesForDirectory={privilegesForDirectory} setRevisionShown={setRevisionShown} />}
        {(isRevision || isSearchView) && <RevisionFileContainerIcon fileEntity={fileEntity} />}
        <div
          ref={isFolder && privilegesForDirectory.isWrite && !isSearchView ? directoryDropRef : undefined}
          className={classNames(
            isOver && "bh-outline-dark-jungle bh-bg-mint-30 rounded outline outline-1",
            isAnyDragging && "bh-bg-mint",
            "min-w-24 flex h-11 flex-grow flex-row items-center overflow-hidden px-2"
          )}
        >
          <div className="flex flex-col justify-center overflow-hidden">
            <div className={classNames(!(fileEntity.tags?.length > 0) && "file-container-name", "flex flex-row items-center")}>
              <FileNameLink fileEntity={fileEntity} onFileNameClickCallback={isRevision ? downloadFile : undefined} currentState={!isSearchView ? "directoryView" : undefined} canRename={true} />
            </div>
            <div className="flex flex-row overflow-hidden">
              {fileEntity.tags &&
                fileEntity.tags.slice(0, 5).map((tag) => {
                  return (
                    <BhTag key={tag.id} type={BhTagType.TAG} onClickCallback={() => dispatch(tagToggledInFilesFilter(tag))}>
                      {tag.text}
                    </BhTag>
                  );
                })}
              {fileEntity.tags?.length > 5 && (
                <BhTag key="unique-over-five-tags" type={BhTagType.TAG}>
                  +{fileEntity.tags?.length - 5}
                </BhTag>
              )}
            </div>
          </div>
          {isFolder && (
            <div className="ml-2">
              <BhTooltip body={fileEntity.favourite ? t("GLOBAL.SET_NOT_PINNED") : t("GLOBAL.SET_PINNED")} delayShow={500}>
                <BhButtonTemplateWithIcon
                  buttonProps={{ classes: classNames(!fileEntity.favourite ? "hidden group-hover:block" : "", "hover:bh-bg-pigeon-20 w-3"), onClick: handleFavouriteClick }}
                  icon={fileEntity.favourite ? faBookmarkSolid : faBookmarkRegular}
                  iconSize="sm"
                />
              </BhTooltip>
            </div>
          )}
        </div>
        <div className="w-18 flex-none px-1 text-center sm:hidden">
          {privilegesForDirectory.isRead && (
            <BhFileCardRevisionNumber
              fileId={fileId}
              canUploadNewVersion={privilegesForDirectory.isWrite && fileEntity.type !== FileEntityType.FORM && !isRevision}
              revision={fileEntity.revision}
              isRevision={isRevision}
              revisionsShown={revisionsShown}
              setRevisionShown={setRevisionShown}
              isFolder={isFolder}
            />
          )}
        </div>
        <div className="w-12 flex-none text-center lg:hidden">
          {privilegesForDirectory.isRead && (
            <div onClick={(e: any) => e.stopPropagation()}>
              <BhFileConfirmationStatus fileEntityId={fileEntity.id} status={fileEntity.status} confirmationUserCount={fileEntity.confirmationUserCount} confirmedCount={fileEntity.confirmedCount} />
            </div>
          )}
        </div>
        <div className="w-12 flex-none text-center lg:hidden">
          {privilegesForDirectory.isRead && fileEntity.type === FileEntityType.CONTAINER && (
            <div onClick={(e: any) => e.stopPropagation()}>
              <BhSignatureContainerStatus fileEntity={fileEntity} />
            </div>
          )}
        </div>
        <div className="w-40 flex-none px-3 lg:hidden">
          {!isFolder && <BhFileCardDateWithName fileDate={fileEntity[filesSortDateField]} fullName={fileEntity[(filesSortDateField + "ByFullName") as keyof IFileEntity] as string} />}
        </div>
        {isSearchView && <FileContainerSearchViewActions fileEntityId={fileEntity.id} />}
        {privilegesForDirectory.isRead && !isSearchView && (
          <div className="w-15 relative flex flex-none flex-row justify-end p-1" onClick={(e: any) => e.stopPropagation()}>
            {!isFolder && (
              <BhIconButton
                icon={faArrowDownToLine}
                buttonProps={{
                  classes: "absolute -left-4 hidden group-hover:block",
                  onClick: downloadFile
                }}
              />
            )}
            <div className="w-10">
              <FileContainerDropdown fileEntity={fileEntity} isRevision={isRevision} privilegesForDirectory={privilegesForDirectory} />
            </div>
          </div>
        )}
      </div>
      {revisionsShown &&
        revisionFileEntityIds.length > 0 &&
        revisionFileEntityIds.map((revisionFileEntityId) => (
          <FileContainer fileId={revisionFileEntityId} privilegesForDirectory={privilegesForDirectory} key={revisionFileEntityId} isRevision={true} isSearchView={isSearchView} />
        ))}
    </>
  );
};

const FileContainer = React.memo(FileContainerFC);
export default FileContainer;
