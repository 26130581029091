import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import SignInvitesUnsigned from "@/views/home/project/detail/signInvites/SignInvitesUnsigned";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { selectCountOfMyUnsignedSignInvitesForProject } from "@/app/store/signInvitesSlice";
import { selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import SignInvitesUnsignedProjectInvites from "@/views/home/project/detail/signInvites/SignInvitesUnsignedProjectInvites";
import BhTabs from "@components/tabs/BhTabs";
import SignInvitesSigned from "@/views/home/project/detail/signInvites/SignInvitesSigned";
import SignInvitesDeclined from "@/views/home/project/detail/signInvites/SignInvitesDeclined";
import { Trans } from "react-i18next";
import { ITabArrayElement } from "@/model/tabs/ITabArrayElement";

interface Props {}

const SignInvitesTabs: FC<Props> = () => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const myInvitesCountForProject = useAppSelector(selectCountOfMyUnsignedSignInvitesForProject(projectId));
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSidebarCollapsed(false));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.SIGN_INVITES));
  }, []);

  const tabs = [
    {
      id: 0,
      header: (
        <div className="space-x-1.5">
          <span>
            <Trans>SIGN_INVITE.UNSIGNED_DOCUMENTS</Trans>
          </span>
          {myInvitesCountForProject > 0 && <BhBadge type={BhBadgeType.BRIGHT}>{myInvitesCountForProject}</BhBadge>}
        </div>
      ),
      content: <SignInvitesUnsigned />,
      href: "personal"
    },
    {
      id: 1,
      header: <Trans>SIGNATURE.SIGNED</Trans>,
      content: <SignInvitesSigned />,
      href: "signed"
    },
    {
      id: 2,
      header: <Trans>SIGNATURE.DECLINED</Trans>,
      content: <SignInvitesDeclined />,
      href: "declined"
    },
    isProjectAdmin && {
      id: 3,
      header: <Trans>SIGN_INVITE.PROJECT_AWAITING</Trans>,
      content: <SignInvitesUnsignedProjectInvites />,
      href: "project"
    }
  ].filter(Boolean) as Array<ITabArrayElement>;

  return <BhTabs tabs={tabs} router={true} />;
};

export default SignInvitesTabs;
