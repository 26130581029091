import React, { Dispatch, FC, SetStateAction } from "react";
import BhModal from "@components/modal/BhModal";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectAnyOlderRoundIdsSelected } from "@/app/store/checklist/checklistsSlice";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";

interface Props {
  isShown: boolean;
  setIsShown: (value: boolean) => void;
  setIsDirSelectionModalShown: Dispatch<SetStateAction<boolean>>;
  setCopyFilesAsRevisions: Dispatch<SetStateAction<boolean>>;
}

const ChecklistBulkCopyModal: FC<Props> = ({ isShown, setIsShown, setIsDirSelectionModalShown, setCopyFilesAsRevisions }) => {
  const { t } = useTranslation();
  const anyOlderRoundSelected = useAppSelector(selectAnyOlderRoundIdsSelected);

  const handleCopyConfirmation = (copyAsRevisions: boolean) => {
    setIsShown(false);
    setIsDirSelectionModalShown(true);
    setCopyFilesAsRevisions(copyAsRevisions);
  };

  return (
    <BhModal
      isShown={isShown}
      setIsShown={setIsShown}
      header={<h2>{t("CHECKLIST.COPY.REVISION_MODAL.HEADER")}</h2>}
      onClose={() => setIsShown(false)}
      footer={
        <>
          {anyOlderRoundSelected ? (
            <>
              <div className="flex flex-row items-center">
                {/*Checklist has several rounds, older rounds are selected. Copy only selected rounds as PDF files*/}
                <BhTextOnlyButton buttonProps={{ onClick: () => handleCopyConfirmation(false) }}>{t("CHECKLIST.COPY.REVISION_MODAL.COPY_AS_FILES") as string}</BhTextOnlyButton>
                {/*Checklist has several rounds, older rounds are selected. Copy all rounds as revisions*/}
                <BhPrimaryButton buttonProps={{ onClick: () => handleCopyConfirmation(true) }}>{t("CHECKLIST.COPY.REVISION_MODAL.COPY_ALL_ROUNDS_AS_VERSIONS") as string}</BhPrimaryButton>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-row items-center">
                {/*Checklist has several rounds, only latest is selected. Copy all rounds as revisions*/}
                <BhTextOnlyButton buttonProps={{ onClick: () => handleCopyConfirmation(true) }}>{t("CHECKLIST.COPY.REVISION_MODAL.COPY_AS_VERSIONS") as string}</BhTextOnlyButton>
                {/*Checklist has several rounds, only latest is selected. Copy only last round*/}
                <BhPrimaryButton buttonProps={{ onClick: () => handleCopyConfirmation(false) }}>{t("CHECKLIST.COPY.REVISION_MODAL.COPY_LAST_ROUND") as string}</BhPrimaryButton>
              </div>
            </>
          )}
        </>
      }
    >
      <div className="px-8 pb-16 pt-8">{t(anyOlderRoundSelected ? "CHECKLIST.COPY.REVISION_MODAL.BODY_FOR_REVISIONS" : "CHECKLIST.COPY.REVISION_MODAL.BODY")}</div>
    </BhModal>
  );
};

export default ChecklistBulkCopyModal;
