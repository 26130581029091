import { ICompanyInvitedUser } from "@/model/companyUsers/ICompanyInvitedUser";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import { intersection } from "@/utilities/jsUtilities";
import { EntityId } from "@reduxjs/toolkit";

export function filterCompanyUsers(users: Array<ICompanyUser | ICompanyInvitedUser>, selectedProjectIds: Array<EntityId>, showArchivedProjects: boolean, archivedProjectIds: Array<EntityId>) {
  const projectsFilterSelected = selectedProjectIds.length > 0;
  let filteredUsers = users;

  // Filter by selected projects
  if (projectsFilterSelected) {
    filteredUsers = users.filter(
      (user) =>
        intersection(
          user.projects.map((project) => project.projectId),
          selectedProjectIds
        ).length > 0
    );
  }

  // Filter out archived projects if needed
  if (filteredUsers && !showArchivedProjects) {
    filteredUsers = filteredUsers.filter((user) => {
      const userProjectIds = user.projects.map((project) => project.projectId);
      return userProjectIds.length === 0 || userProjectIds.length > intersection(userProjectIds, archivedProjectIds).length;
    });
  }
  return filteredUsers;
}
