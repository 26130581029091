import React, { FC, useState } from "react";
import BoInputWithEmailChip from "@components/backoffice/invoices/BOInputWithEmailChip";
import BhModal from "@components/modal/BhModal";
import { IInvoiceEmail } from "@/model/invoices/IInvoiceEmail";
import { boSendMonthlyInvoiceReportAsync } from "@/app/store/backofficeSlice";
import { useAppDispatch } from "@/app/hooks";
import BhModalFooter from "@components/modal/BhModalFooter";

interface Props {
  setIsShown: (value: boolean) => void;
  currentMonth: string;
}

const BOInvoiceSendReportModal: FC<Props> = ({ setIsShown, currentMonth }) => {
  const [emailsToSend, setEmailsToSend] = useState<Array<string>>([]);
  const dispatch = useAppDispatch();
  const onEnterKeyDown = (inputValue: any) => {
    if (!emailsToSend.includes(inputValue) && inputValue !== "") {
      setEmailsToSend([...emailsToSend, inputValue]);
    }
  };

  const removeEmail = (email: string) => {
    const newEmails = emailsToSend.filter((e) => e !== email);
    setEmailsToSend(newEmails);
  };

  const sendReport = () => {
    const emailsWithYearMonth: IInvoiceEmail = { emails: [...emailsToSend], yearMonth: currentMonth };
    dispatch(boSendMonthlyInvoiceReportAsync(emailsWithYearMonth)).then(() => {
      setEmailsToSend([]);
      setIsShown(false);
    });
  };

  const isSendDisabled = emailsToSend.length === 0;

  return (
    <BhModal
      setIsShown={setIsShown}
      header={<h2>Send monthly report</h2>}
      footer={<BhModalFooter cancelButtonText="Cancel" onCancel={setIsShown} confirmButtonText="Send" onConfirm={sendReport} confirmDisabled={isSendDisabled} />}
    >
      <BoInputWithEmailChip label="E-mails" emailsToSend={emailsToSend} onEnterKeyDown={onEnterKeyDown} onRemove={removeEmail} />
    </BhModal>
  );
};

export default BOInvoiceSendReportModal;