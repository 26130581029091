import React, { FC, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectIsAllTasksTaskBoardOpen, selectIsPersonalTaskBoardOpen, selectOpenedTaskBoardId, selectTaskBoardById } from "@/app/store/taskBoardsSlice";
import { Link } from "react-router-dom";
import { classNames } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";
import { selectCurrentProjectId, selectIsProjectArchived } from "@/app/store/project/projectSlice";
import { useDrop } from "react-dnd";
import TaskMoveModalContainer from "@/views/home/project/detail/task/modals/TaskMoveModalContainer";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  taskBoardId?: EntityId;
  isPersonal?: boolean;
  isAllTasks?: boolean;
}

const TaskBoardItem: FC<Props> = ({ taskBoardId, isPersonal, isAllTasks }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const taskBoard = useAppSelector((state) => taskBoardId && selectTaskBoardById(state, taskBoardId));
  const openedTaskBoardId = useAppSelector(selectOpenedTaskBoardId);
  const isPersonalTaskBoardOpened = useAppSelector(selectIsPersonalTaskBoardOpen);
  const isAllTasksTaskBoardOpened = useAppSelector(selectIsAllTasksTaskBoardOpen);
  const projectArchived = useAppSelector(selectIsProjectArchived);
  const isActive = (taskBoardId && openedTaskBoardId === taskBoardId) || (isPersonal && isPersonalTaskBoardOpened) || (isAllTasks && isAllTasksTaskBoardOpened);
  const [taskMoveModalTaskId, setTaskMoveModalTaskId] = useState<EntityId | undefined>();
  const dispatch = useAppDispatch();

  const [{ isOver }, taskDropRef] = useDrop({
    accept: "taskItem",
    drop: (droppedFile: { taskId: EntityId }) => {
      if (!droppedFile?.taskId) return;

      if (projectArchived) {
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.ERROR,
            disappear: true,
            translateCode: "'ERROR.PROJECT_ARCHIVED"
          })
        );
        return;
      }

      setTaskMoveModalTaskId(droppedFile?.taskId);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  return (
    <>
      <Link
        ref={!isAllTasks ? taskDropRef : undefined}
        className={classNames(
          isOver && "bh-outline-dark-jungle bh-bg-mint outline outline-1",
          "hover:bh-bg-mint my-1 flex cursor-pointer flex-row items-center justify-start rounded px-2 py-1.5",
          isActive && "bh-bg-mint pointer-events-none"
        )}
        to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/taskboard/list/${isPersonal ? "personal" : isAllTasks ? "all" : taskBoardId}`}
      >
        <div className="bh-text-deep-ocean overflow-hidden text-ellipsis whitespace-nowrap">{taskBoard ? taskBoard.name : isPersonal ? t("TASKBOARD.PERSONAL") : t("TASKBOARD.ALL_TASKS")}</div>
      </Link>
      {taskMoveModalTaskId && (
        <TaskMoveModalContainer taskId={taskMoveModalTaskId} isDedicatedView={false} closeModal={() => setTaskMoveModalTaskId(undefined)} selectedTaskBoardId={isPersonal ? -1 : taskBoardId} />
      )}
    </>
  );
};

export default TaskBoardItem;
