import React, { FC } from "react";
import { IUser } from "@/model/IUser";
import BhUserIcon from "@components/user/BhUserIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";

interface Props {
  user: IUser;
}

const BhNavBarUserIconWithName: FC<Props> = ({ user }) => {
  return (
    <div className="flex cursor-pointer flex-row items-center whitespace-nowrap">
      <BhUserIcon user={user} withoutRing={false} />
      <div className="ml-2 mr-3 flex flex-col sm:hidden">
        <div className="l-h-18px font-semibold">{user.firstName + " " + user.lastName}</div>
        <div className="text-12px l-h-14px">{user.username}</div>
      </div>
      <FontAwesomeIcon icon={faCaretDown} className="pr-2 sm:hidden" />
    </div>
  );
};

export default BhNavBarUserIconWithName;
