import React, { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import ChecklistBaseFields from "@components/checklists/checklistBase/body/ChecklistBaseFields";
import { IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";

interface Props {
  saveNewRowCallback: Function;
  previousRow?: IChecklistBaseVersionRow;
}

const ChecklistBaseFieldsAddButton: FC<Props> = ({ saveNewRowCallback, previousRow }) => {
  const [fieldsVisible, setFieldsVisible] = useState(false);

  const saveNewRow = (field: any) => {
    setFieldsVisible(false);
    saveNewRowCallback(field, previousRow);
  };

  const onOutsideClick = () => {
    setFieldsVisible(false);
  };

  return (
    <div className="relative flex w-full flex-row justify-center">
      <div
        className="bh-border-pigeon-40 bh-bg-smoke hover:bh-bg-mint hover:bh-border-dark-jungle bh-text-deep-ocean-80 hover:bh-text-dark-jungle flex h-6 w-40 cursor-pointer flex-row justify-center rounded border border-dashed transition duration-200 ease-in-out"
        onClick={() => setFieldsVisible(true)}
      >
        <div className="flex flex-col justify-center">
          <FontAwesomeIcon icon={faPlus} />
        </div>
      </div>
      {fieldsVisible && (
        <div className="bh-bg-white absolute z-20">
          <ChecklistBaseFields saveNewRowCallback={saveNewRow} clickOutsideCallback={onOutsideClick} />
        </div>
      )}
    </div>
  );
};

export default ChecklistBaseFieldsAddButton;
