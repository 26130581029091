import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import MntMpTableRow from "@components/form/fields/oldCustomFields/mntTables/MntMpTableRow";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";

interface Props {
  dataTableRows: Array<any>;
  dataTableColumns: Array<string>;
  saveRowCallback?: Function;
  addRowCallback: Function;
  removeRowCallback: Function;
  onPasteCallback: Function;
  formType?: string;
  disabled: boolean;
}

const MntMpKandevoimeTable: FC<Props> = ({ dataTableRows, dataTableColumns, saveRowCallback, addRowCallback, removeRowCallback, onPasteCallback, formType, disabled }) => {
  const { t } = useTranslation();

  return (
    <table className="form-table">
      <thead>
        <tr>
          <th colSpan={3}>ASUKOHT</th>
          <th colSpan={8}>SEERIA KATSETE LUGEMID Mpa</th>
          <th rowSpan={2}>∑E/3</th>
          <th rowSpan={2} className="mnt-kandevoime-cell-wide">
            ∑E/3
            <br />
            /E[2]
          </th>
          <th className="mnt-kandevoime-cell-wide" rowSpan={2}>
            Märkused
          </th>
          <th rowSpan={2} className="w-[45px] min-w-[45px]">
            <BhTooltip body={t("FORMS.MNT_MP.PASTE_TOOLTIP")}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </BhTooltip>
          </th>
        </tr>
        <tr>
          <th className="mnt-kandevoime-cell-wide">
            Katse-
            <br />
            seeria
            <br />
            NR.
          </th>
          <th className="mnt-kandevoime-cell-wide">PIKETT</th>
          <th>
            Rist
            <br />
            prof.
          </th>
          <th className="mnt-kandevoime-cell-short">1</th>
          <th className="mnt-kandevoime-cell-short">2</th>
          <th className="mnt-kandevoime-cell-short">3</th>
          <th className="mnt-kandevoime-cell-short">4</th>
          <th className="mnt-kandevoime-cell-short">5</th>
          <th className="mnt-kandevoime-cell-short">6</th>
          <th className="mnt-kandevoime-cell-short">7</th>
          <th className="mnt-kandevoime-cell-short">8</th>
        </tr>
      </thead>
      <tbody>
        {dataTableRows &&
          dataTableRows.length > 0 &&
          dataTableRows.map((row: any, index) => {
            return (
              <MntMpTableRow
                row={row}
                columns={dataTableColumns}
                saveRowCallback={saveRowCallback}
                onPasteCallback={onPasteCallback}
                addRowCallback={addRowCallback}
                removeRowCallback={removeRowCallback}
                formType={formType}
                key={index}
                disabled={disabled}
                rowIndex={index}
              />
            );
          })}
        {dataTableRows.length === 0 && !disabled && (
          <tr>
            <td colSpan={14}></td>
            <td>
              <div className="flex flex-row items-center justify-center">
                <div className="hover:bh-bg-pigeon-20 flex h-5 w-5 cursor-pointer flex-row items-center justify-center rounded p-1" onClick={() => addRowCallback()}>
                  <FontAwesomeIcon icon={faPlus} size={"xs"} />
                </div>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default MntMpKandevoimeTable;
