import React, { FC } from "react";
import { ChecklistRowType, IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import BhInputSimple from "@components/input/BhInputSimple";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { useTranslation } from "react-i18next";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons/faPaperclip";
import { faArrowTurnDownRight } from "@fortawesome/pro-regular-svg-icons/faArrowTurnDownRight";
import { classNames } from "@/utilities/jsUtilities";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectChecklistBaseLanguageForTranslationById } from "@/app/store/checklist/checklistsSlice";
import { EntityId } from "@reduxjs/toolkit";
import BhAttachmentsUploadContainer from "@components/upload/BhAttachmentsUploadContainer";
import { FileEntityResource, FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import AttachmentsContainerLarge from "@components/attachments/AttachmentsContainerLarge";
import { attachNewFilesToChecklistBaseVersionRowAsync, deleteFileFromChecklistBaseVersionRowAsync } from "@/app/store/checklist/checklistBaseRowsSlice";
import { uploadCompanyAttachmentAsync } from "@/app/store/uploadSlice";

interface Props {
  companyId: EntityId;
  checklistBaseId: EntityId;
  baseVersionRow: IChecklistBaseVersionRow;
  saveCallback: Function;
  saveNewRowCallback: Function;
  disabled?: boolean;
  projectId?: EntityId;
}

const ChecklistBaseRowCheckpoint: FC<Props> = ({ companyId, baseVersionRow, saveCallback, saveNewRowCallback, disabled, checklistBaseId, projectId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const checklistBaseLanguage = useAppSelector((state) => selectChecklistBaseLanguageForTranslationById(state, checklistBaseId));
  const isCheckpoint = baseVersionRow.checkpoint;
  const isSubPoint = baseVersionRow.indentation === 1;

  const saveNewSubPoint = () => {
    const field = { type: ChecklistRowType.CHECKPOINT, indentation: 1, checkpoint: false };
    saveNewRowCallback(field, baseVersionRow);
  };

  const uploadFiles = async (files: any) => {
    if (disabled) return;
    let savedFiles: Array<IFileEntity> = [];
    await Promise.all(
      files.map(async (file: any) => {
        await dispatch(uploadCompanyAttachmentAsync({ file: file, companyId: companyId, resource: FileEntityResource.CHECKLIST_BASE_VERSION_ROW, resourceId: baseVersionRow.id })).then((action) => {
          const savedFileEntity = action.payload as IFileEntity;
          if (savedFileEntity.id) {
            savedFiles.push(savedFileEntity);
          }
        });
      })
    ).then(() => {
      addFilesToRow(savedFiles, true);
    });
  };

  const addFilesToRow = (files: Array<IFileEntity>, isUpload: boolean) => {
    if (files.length > 0) {
      const fileIds = files.map((file) => file.id);
      dispatch(attachNewFilesToChecklistBaseVersionRowAsync({ companyId: companyId, checklistBaseId: checklistBaseId, baseRowId: baseVersionRow.id, fileIds: fileIds, isUpload: isUpload }));
    }
  };
  const addUploadedOrAddedFilesToRow = (attachmentObject: { attachments: Array<IFileEntity> }) => {
    const newAttachments = baseVersionRow.attachments
      ? attachmentObject.attachments.filter((file) => {
          return !baseVersionRow.attachments.some((att) => att.id === file.id);
        })
      : attachmentObject.attachments;
    const isUpload = newAttachments.length > 0 && !newAttachments[0].parentFileEntityId;
    addFilesToRow(newAttachments, isUpload);
  };

  const removeAttachment = (fileId: EntityId) => {
    dispatch(deleteFileFromChecklistBaseVersionRowAsync({ companyId: companyId, checklistBaseId: checklistBaseId, baseRowId: baseVersionRow.id, fileId: fileId }));
  };

  return (
    <div className="flex h-full flex-row">
      <div className="bh-border-pigeon-60 flex w-full flex-col border-r p-4">
        <div className="flex w-full flex-row items-center gap-x-2">
          <div className="w-28">
            <BhInputSimple
              initialValue={baseVersionRow.number}
              property={"number"}
              placeholder={t("CHECKLIST_BASE.NUMBER") as string}
              onBlurAction={saveCallback}
              inputClasses={"w-full"}
              disabled={disabled}
            />
          </div>
          <div className="w-full">
            <BhInputSimple
              initialValue={baseVersionRow.fieldTitle}
              property={"fieldTitle"}
              placeholder={t("CHECKLIST_BASE.CHECKPOINT_NAME") as string}
              onBlurAction={saveCallback}
              disabled={disabled}
            />
          </div>
        </div>
        {baseVersionRow.attachments && baseVersionRow.attachments.length > 0 && (
          <div className="mt-2">
            <AttachmentsContainerLarge
              attachments={baseVersionRow.attachments}
              property={"attachments"}
              saveCallback={addUploadedOrAddedFilesToRow}
              removeCallback={!disabled ? removeAttachment : undefined}
              chooseFromProjectAllowed={projectId ? true : false}
              multiple={true}
              addingDisabled={disabled}
              excludeFileTypes={[FileEntityType.FORM]}
              uploadCallback={uploadFiles}
            />
          </div>
        )}
        {!disabled && (
          <div className={classNames("flex flex-row items-center gap-x-2", !isSubPoint && "pl-28", (!baseVersionRow.attachments || baseVersionRow.attachments.length === 0) && "pt-3")}>
            <BhTextOnlyButton icon={isSubPoint ? faPlus : faArrowTurnDownRight} buttonProps={{ onClick: saveNewSubPoint }}>
              {t("CHECKLIST.CHECKPOINT.ADD_SUB_ROW")}
            </BhTextOnlyButton>
            {(!baseVersionRow.attachments || baseVersionRow.attachments.length === 0) && (
              <BhAttachmentsUploadContainer
                saveCallback={addUploadedOrAddedFilesToRow}
                button={<BhTextOnlyButton icon={faPaperclip}>{t("CHECKLIST_BASE.ADD_ATTACHMENT")}</BhTextOnlyButton>}
                property={"attachments"}
                multiple={true}
                disabled={disabled}
                fileUploadAllowed={true}
                chooseFromProjectAllowed={projectId ? true : false}
                excludeFileTypes={[FileEntityType.FORM]}
                uploadCallback={uploadFiles}
              />
            )}
          </div>
        )}
      </div>
      <div className="h-full p-4">
        <div className="flex h-12 flex-row items-center">
          <div className="mr-1">
            <BhCheckbox large={true} property={"checkpoint"} onChange={saveCallback} isChecked={isCheckpoint} disabled={disabled} />
          </div>
          <BhPrimaryButton icon={faCheck} buttonProps={{ classes: "w-25 h-full mr-0 cursor-default disabled:pointer-events-none", disabled: !isCheckpoint }}>
            {t("CHECKLIST.CHECKPOINT.YES", { lng: checklistBaseLanguage })}
          </BhPrimaryButton>
          <BhPrimaryButton icon={faXmark} buttonProps={{ classes: "w-25 bh-bg-error-red h-full mr-0 cursor-default disabled:pointer-events-none", disabled: !isCheckpoint }}>
            {t("CHECKLIST.CHECKPOINT.NO", { lng: checklistBaseLanguage })}
          </BhPrimaryButton>
          <BhPrimaryButton
            buttonProps={{
              classes: "bh-bg-pigeon-40 hover:bh-bg-pigeon-45 h-full mr-0 disabled:bh-text-pigeon-60 cursor-default disabled:pointer-events-none",
              widthClasses: "w-12",
              textClasses: "bh-text-deep-ocean",
              disabled: !isCheckpoint
            }}
          >
            {t("CHECKLIST.CHECKPOINT.NA", { lng: checklistBaseLanguage })}
          </BhPrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default ChecklistBaseRowCheckpoint;
