import React, { FC } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { faFolder } from "@fortawesome/pro-regular-svg-icons/faFolder";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import { useLocation, useNavigate } from "react-router-dom";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import { fetchUrlForFile } from "@/api/fileAPI";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectFileById } from "@/app/store/filesSlice";
import { EntityId } from "@reduxjs/toolkit";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import { goToFileEntityParentDir } from "@/features/searchWithDropdown/SearchFileCardContainer";

interface Props {
  fileEntityId: EntityId;
}

const FileContainerSearchViewActions: FC<Props> = ({ fileEntityId }) => {
  const { t } = useTranslation();
  const fileEntity = useAppSelector((state) => selectFileById(state, fileEntityId));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  if (!fileEntity) return null;

  const handleFileDownload = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (fileEntity.uuid) {
      fetchUrlForFile(fileEntity.id, false, true, fileEntity.uuid).then((response) => {
        imitateUrlDownload(response.value);
      });
    }
  };

  const handleNavigateToParentDir = (e: React.MouseEvent) => {
    e.stopPropagation();
    goToFileEntityParentDir(fileEntity.projectId, fileEntity, navigate, dispatch, location.pathname);
  };

  return (
    <div className="w-21 flex-none pr-1">
      <BhTooltip body={t("FILE.GO_TO_DIR")}>
        <BhIconButton
          icon={faFolder}
          buttonProps={{
            classes: "w-8",
            onClick: handleNavigateToParentDir
          }}
        />
      </BhTooltip>
      <BhTooltip body={t("GLOBAL.DOWNLOAD")}>
        <BhIconButton
          icon={faArrowDownToLine}
          buttonProps={{
            classes: "w-8",
            onClick: handleFileDownload
          }}
        />
      </BhTooltip>
    </div>
  );
};

export default FileContainerSearchViewActions;
