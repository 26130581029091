import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faZzz } from "@fortawesome/pro-regular-svg-icons/faZzz";

const BhStatusCellInLineOfSigning: FC = () => {
  return (
    <div className="bh-bg-pigeon-20 flex h-14 w-12 flex-row items-center justify-center">
      <FontAwesomeIcon icon={faZzz} size="lg" className="bh-text-pigeon h-4 w-[14px]" />
    </div>
  );
};

export default BhStatusCellInLineOfSigning;
