import React, { FC } from "react";
import BhFilterButton from "@components/filters/BhFilterButton";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { useTranslation } from "react-i18next";

interface Props {
  currentValue?: string;
  values: any;
  placeholder: string;
  onSelect?: Function;
  onReset?: Function;
  translateValues?: true;
  textProperty?: string;
  disabled?: boolean;
}

const BhSelectInputFilter: FC<Props> = ({ currentValue, values, placeholder, onSelect, onReset, translateValues, textProperty, disabled }) => {
  const { t } = useTranslation();
  const isSelected = currentValue && currentValue?.length > 0;
  const buttonName = translateValues ? currentValue && t(currentValue) : currentValue;

  return (
    <BhDropdown
      disabled={disabled}
      button={
        <BhFilterButton onReset={onReset} selectedValues={isSelected ? 1 : 0}>
          {isSelected ? buttonName : placeholder}
        </BhFilterButton>
      }
      menu={
        <BhDropdownMenu
          type={BhDropdownTypeEnum.STRING}
          values={values}
          onSelect={onSelect}
          isSelected={(item) => item === currentValue}
          translateValues={translateValues}
          textProperty={textProperty}
        />
      }
    />
  );
};

export default BhSelectInputFilter;
