import React, { FC } from "react";
import DetailViewContainer from "@/views/home/project/detail/detailViewContainer/DetailViewContainer";
import ShareboxList from "@/views/home/project/detail/shareboxes/ShareboxList";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import { Trans } from "react-i18next";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";

const ShareboxListContainer: FC = () => {
  useDocumentTitle("HOME.SHAREBOXES");

  return (
    <DetailViewContainer
      header={
        <DetailViewHeading>
          <Trans>HOME.SHAREBOXES</Trans>
        </DetailViewHeading>
      }
    >
      <ShareboxList />
    </DetailViewContainer>
  );
};

export default ShareboxListContainer;
