import React, { Dispatch, FC, SetStateAction } from "react";
import BhModal from "@components/modal/BhModal";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import useHandleFormSubmit from "@/utilities/hooks/useHandleFormSubmit";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import { useAppDispatch } from "@/app/hooks";
import { declineSignatureAsync } from "@/app/store/signaturesSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons/faCircleXmark";
import { useTranslation } from "react-i18next";
import { declinePublicSignature } from "@/api/signatureAPI";
import BhTextareaStackedLabel from "@components/input/BhTextareaStackedLabel";
import { store } from "@/app/store";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";

interface Props {
  signInviteToDecline: ISignatureHolder;
  setContainerDeclineModalShown: Dispatch<SetStateAction<boolean>>;
  isPublicSignInvite?: boolean;
  callbackAfterDeclineSuccess?: Function;
}

const ContainerDeclineModalBody: FC<Props> = ({ signInviteToDecline, setContainerDeclineModalShown, isPublicSignInvite, callbackAfterDeclineSuccess }) => {
  const handleFormSubmit = useHandleFormSubmit<ISignatureHolder>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleSubmit = (formValues: ISignatureHolder) => {
    if (formValues.declineReason?.length < 1) {
      store.dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.WARNING,
          disappear: true,
          translateCode: "GLOBAL.FILL_REQUIRED_FIELDS"
        })
      );
      return;
    }

    if (!signInviteToDecline) {
      return;
    }
    const savableFileEntityObject = { ...signInviteToDecline, ...formValues };

    if (!!savableFileEntityObject) {
      if (isPublicSignInvite) {
        declinePublicSignature(savableFileEntityObject).then((result) => {
          callbackAfterDeclineSuccess && callbackAfterDeclineSuccess(result);
          setContainerDeclineModalShown(false);
        });
        return;
      }
      dispatch(declineSignatureAsync(savableFileEntityObject)).then(() => {
        setContainerDeclineModalShown(false);
      });
    }
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={setContainerDeclineModalShown}
      header={
        <div className="flex flex-row items-center space-x-5">
          <FontAwesomeIcon icon={faCircleXmark} className="bh-text-error-red h-4 w-4" />
          <h2>{t("DECLINE_MODAL.TITLE")}</h2>
        </div>
      }
      children={
        <div className="w-full p-8">
          <form id="declineForm" className="flex flex-col" onSubmit={handleFormSubmit((signature) => handleSubmit(signature))}>
            <BhTextareaStackedLabel initialValue={""} property={"declineReason"} label={t("DECLINE_MODAL.REASON_TITLE")} placeholder={t("DECLINE_MODAL.REASON")} />
          </form>
        </div>
      }
      footer={
        <div>
          <BhTextOnlyButton buttonProps={{ onClick: () => setContainerDeclineModalShown(false) }}>{t("GLOBAL.CANCEL")}</BhTextOnlyButton>
          <BhPrimaryButton buttonProps={{ submitForm: "declineForm" }}>{t("GLOBAL.SAVE")}</BhPrimaryButton>
        </div>
      }
    />
  );
};

export default ContainerDeclineModalBody;
