import { IFileEntity } from "@/model/files/IFileEntity";
import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectDeletedSignaturesForContainer } from "@/app/store/signaturesSlice";
import { useTranslation } from "react-i18next";
import { BhSectionMessageWarning } from "@components/sectionMessage/BhSectionMessages";
import { faClockRotateLeft } from "@fortawesome/pro-regular-svg-icons/faClockRotateLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BhModal from "@components/modal/BhModal";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IBauhubEvent } from "@/model/IBauhubEvent";
import BhTableHeader from "@components/table/BhTableHeader";
import BhTableRow from "@components/table/BhTableRow";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { formatDateTime } from "@/utilities/dateUtility";
import BhSmallText from "@components/text/BhSmallText";
import BhFileCardMedium from "@components/cards/BhFileCardMedium";
import { fetchDeletedSignatureFileEntity, fetchUrlForFile } from "@/api/fileAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";

interface Props {
  containerFileEntity: IFileEntity;
}

const ContainerDeletedSignaturesContainer: FC<Props> = ({ containerFileEntity }) => {
  const deletedSignatures = useAppSelector(selectDeletedSignaturesForContainer(containerFileEntity.uuid));
  const [showPreviousVersionsModal, setShowPreviousVersionsModal] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const fileHistoryTableHeaders: Array<IBhTableHeader<IBauhubEvent>> = [
    { id: 1, content: t("LOG.TYPE"), classes: "w-56" },
    { id: 2, content: t("FILE.NAME") },
    { id: 3, content: t("LOG.CREATED"), classes: "w-60" }
  ];

  if (deletedSignatures.length < 1) return null;

  return (
    <div>
      <BhSectionMessageWarning
        buttonLabel={
          <div>
            <FontAwesomeIcon icon={faClockRotateLeft} className="pr-2" />
            {t("CONTAINER.PREVIOUS_VERSIONS")}
          </div>
        }
        onClick={() => setShowPreviousVersionsModal(true)}
      >
        {t("CONTAINER.DELETED_SIGNATURES")}
      </BhSectionMessageWarning>
      {showPreviousVersionsModal && (
        <BhModal
          setIsShown={setShowPreviousVersionsModal}
          isShown={showPreviousVersionsModal}
          onClose={setShowPreviousVersionsModal}
          size="4xl"
          header={
            <div className="w-5/6">
              <div className="flex flex-row items-center">
                <h2>
                  <FontAwesomeIcon icon={faClockRotateLeft} className="mr-4" />
                  {t("CONTAINER.PREVIOUS_VERSIONS")}
                </h2>
              </div>
            </div>
          }
          footer={
            <div>
              <BhTextOnlyButton buttonProps={{ onClick: () => setShowPreviousVersionsModal(false) }}>{t("GLOBAL.CLOSE")}</BhTextOnlyButton>
            </div>
          }
        >
          <BhScrollableBody>
            <div className="p-8">
              <table className="w-full">
                <BhTableHeader columns={fileHistoryTableHeaders} reversed={false} />
                <tbody>
                  {deletedSignatures &&
                    deletedSignatures.map((deletedSignature) => {
                      return (
                        <BhTableRow key={deletedSignature.uniqueId}>
                          <td>
                            <div className="bh-bg-error-red-10 flex inline-flex flex-row items-center rounded text-center">
                              <span className="px-1">
                                <FontAwesomeIcon icon={faCircle} size="xs" className="bh-text-error-red" />
                              </span>
                              <span className="px-1">{t("CONTAINER.SIGNATURE_REMOVED")}</span>
                            </div>
                          </td>
                          <td>
                            <BhFileCardMedium
                              fileEntity={containerFileEntity}
                              onFileNameClickCallback={() => {
                                fetchDeletedSignatureFileEntity(containerFileEntity.projectId, containerFileEntity.id, deletedSignature.signatureId).then((fileEntity) => {
                                  fetchUrlForFile(fileEntity.id, false, true).then((response) => {
                                    imitateUrlDownload(response.value);
                                  });
                                });
                              }}
                            />
                          </td>
                          <td>
                            <div className="flex flex-col">
                              <div>{formatDateTime(deletedSignature.deletedTime)}</div>
                              <div>
                                <BhSmallText classes="-mt-0">{deletedSignature.deletedByFullName}</BhSmallText>
                              </div>
                            </div>
                          </td>
                        </BhTableRow>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </BhScrollableBody>
        </BhModal>
      )}
    </div>
  );
};

export default ContainerDeletedSignaturesContainer;
