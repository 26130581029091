import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import BhTableRow from "@components/table/BhTableRow";
import { deleteProjectAdministratorInviteAsync, resendProjectAdministratorInviteAsync, selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { IInvite } from "@/model/invites/IInvite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons/faArrowsRotate";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "@/utilities/dateUtility";
import BhTooltip from "@components/BhTooltip";
import BhTagListContainer from "@components/tags/BhTagListContainer";

interface Props {
  invite: IInvite;
}

const ProjectAdministratorInvitesTableRow: FC<Props> = ({ invite }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);

  const resendInvite = (invite: IInvite) => {
    dispatch(resendProjectAdministratorInviteAsync({ projectId: projectId, invite: invite }));
  };

  const deleteInvite = (invite: IInvite) => {
    dispatch(deleteProjectAdministratorInviteAsync({ projectId: projectId, invite: invite }));
  };

  return (
    <BhTableRow key={invite.id} classes={"bh-border-pigeon-40 bh-bg-smoke-50 border-t"}>
      <td>
        <div className="flex flex-row items-center">
          <div className="bh-bg-pigeon-20 flex h-8 w-8 flex-col items-center justify-center rounded-full ring-2 ring-white">
            <FontAwesomeIcon icon={faUser} className="bh-text-pigeon-60 " />
          </div>
          <div className="ml-2 flex flex-col">
            <div className="mb-0.5">
              <BhTagListContainer>
                <BhTag children={t("GLOBAL.INVITE.PENDING")} type={BhTagType.TAG} />
              </BhTagListContainer>
            </div>
            <div className="bh-text-deep-ocean text-12px leading-4">{t("SHARE.SENT") + " " + formatDateTime(invite.updated)}</div>
          </div>
        </div>
      </td>
      <td>{invite.username}</td>
      <td></td>
      <td></td>
      <td></td>
      <td>
        <div className="flex flex-row">
          <BhTooltip body={t("SHARE.RESEND")}>
            <BhIconButton icon={faArrowsRotate} buttonProps={{ onClick: () => resendInvite(invite), classes: "mr-2" }} />
          </BhTooltip>
          <BhTooltip body={t("USER.PROFILE.REMOVE")}>
            <BhIconButton icon={faCircleMinus} buttonProps={{ onClick: () => deleteInvite(invite) }} />
          </BhTooltip>
        </div>
      </td>
    </BhTableRow>
  );
};

export default ProjectAdministratorInvitesTableRow;
