import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import TooplaanWeekTable from "@components/form/fields/oldCustomFields/tooplaan/TooplaanWeekTable";
import moment from "moment";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { getDatesForWeek, getWeeksForYear } from "@/utilities/dateUtility";
import { createObjectId } from "@/utilities/formUtilities";
import { IFormDataSaveRequest } from "@/model/IForm";
import { selectTooplaanWorksDateRange, selectValueByPropertyFromCurrentFormData } from "@/app/store/form/formSlice";

interface Props {
  field: any;
  saveCallback?: Function;
  saveWeekCallback?: Function;
  addWeekCallback?: Function;
  removeWeekCallback?: Function;
  disabled: boolean;
  isPreview?: boolean;
}

const FormTooplaanTableField: FC<Props> = ({ saveCallback, saveWeekCallback, addWeekCallback, removeWeekCallback, disabled, field, isPreview }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const weeks = useAppSelector((state) => (!isPreview ? selectValueByPropertyFromCurrentFormData(state, field.property) : []));
  const formWorksDateRangeFromData = useAppSelector((state) => (!isPreview ? selectValueByPropertyFromCurrentFormData(state, "formWorksDateRange") : ""));
  const formWorksDateRange = useAppSelector(selectTooplaanWorksDateRange);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear as number);
  const availableYears = [currentYear.toString(), (currentYear + 1).toString(), (currentYear + 2).toString(), (currentYear + 3).toString(), (currentYear + 4).toString()];
  const [selectedYearWeeks, setSelectedYearWeeks] = useState([] as Array<any>);
  const [selectedWeek, setSelectedWeek] = useState({} as any);
  const [findIfContainsWeeksOnce, setFindIfContainsWeeksOnce] = useState(false);

  useEffect(() => {
    const weeksForYear = getWeeksForYear(selectedYear);
    const currentWeekNumber = moment().isoWeek();
    const weekNumberToSet = currentYear === selectedYear ? currentWeekNumber : 1;
    const weekToSet = weeksForYear.find((week) => week.number === weekNumberToSet);
    if (weekToSet) {
      setSelectedWeek(weekToSet);
    }
    setSelectedYearWeeks(weeksForYear);
  }, [selectedYear]);

  useEffect(() => {
    // Pane viimaseks valitud nädalaks viimane nädal, mis vormis on
    if (findIfContainsWeeksOnce || !selectedYearWeeks || selectedYearWeeks.length === 0) return;
    if (weeks.length > 0 && weeks.slice(-1)[0].selectedWeek) {
      const lastSelectedWeek = weeks.slice(-1)[0].selectedWeek;
      const lastSelectedYear = weeks.slice(-1)[0].selectedYear;
      const weekNumberToSet = lastSelectedWeek.number + 1;
      const weekToSet = selectedYearWeeks.find((week) => week.number === weekNumberToSet);

      if (weekToSet) {
        setSelectedWeek(weekToSet);
      } else if (availableYears.includes((lastSelectedYear + 1).toString())) {
        setSelectedYear(lastSelectedYear + 1);
      }
    }
    setFindIfContainsWeeksOnce(true);
  }, [selectedYearWeeks, findIfContainsWeeksOnce]);

  useEffect(() => {
    if (formWorksDateRangeFromData !== formWorksDateRange) {
      saveCallback && saveCallback({ formWorksDateRange: formWorksDateRange });
    }
  }, [formWorksDateRange]);

  const addWeek = () => {
    const weekAlreadyExists = weeks.some((week: any) => week.info.year === selectedYear && week.info.number === selectedWeek.number);
    if (weekAlreadyExists) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.WARNING,
          disappear: true,
          children: t("FORMS.TOOPLAAN.WEEK_ALREADY_ADDED")
        })
      );
      return;
    }
    const weekNumberToSet = selectedWeek.number + 1;
    const weekToSet = selectedYearWeeks.find((week) => week.number === weekNumberToSet);
    if (weekToSet) {
      setSelectedWeek(weekToSet);
    } else if (availableYears.includes((selectedYear + 1).toString())) {
      setSelectedYear(selectedYear + 1);
    }
    const weekToAdd = {
      info: selectedWeek,
      dates: getDatesForWeek(selectedYear, selectedWeek.number),
      selectedYear: selectedYear,
      selectedWeek: selectedWeek,
      worksInWeek: [{ days: { m: false, tu: false, w: false, th: false, f: false, sa: false, su: false }, isObjectRow: false, _id: createObjectId() }]
    };
    addWeekCallback && addWeekCallback(weekToAdd, "weeks");
  };

  const changeWeekOrder = (weekToChange: any, oldIndex: number, newIndex: number) => {
    let weeksToChange = weeks.slice();
    const firstWeekMovedHigher = oldIndex === 0 && newIndex < oldIndex;
    const lastWeekMovedLower = oldIndex === weeks.length - 1 && newIndex > oldIndex;
    if (firstWeekMovedHigher || lastWeekMovedLower) {
      return;
    }
    weeksToChange.splice(oldIndex, 1);
    weeksToChange.splice(newIndex, 0, weekToChange);
    const weeksToSave = { weeks: weeksToChange };
    saveCallback && saveCallback(weeksToSave);
  };

  const changeWeek = (weekToChange: any, newYear: number, newWeek: any) => {
    const weekAlreadyExists = weeks.some((week: any) => week.info.year === newYear && week.info.number === newWeek.number);
    if (weekAlreadyExists) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.WARNING,
          disappear: true,
          children: t("FORMS.TOOPLAAN.WEEK_ALREADY_ADDED")
        })
      );
      return;
    }
    const weekToSave = { ...weekToChange, ...{ info: newWeek, selectedWeek: newWeek, selectedYear: newYear, dates: getDatesForWeek(newYear, newWeek.number) } };
    const saveRequest = {
      path: "weeks",
      changes: weekToSave,
      changedObjectId: weekToSave._id
    } as IFormDataSaveRequest;
    saveWeekCallback && saveWeekCallback(saveRequest);
  };

  return (
    <div className="flex w-full flex-col">
      <div className="mb-10 flex flex-row justify-center">
        <h3>{t("FORMS.TOOPLAAN.PLANNED_WORKS").toUpperCase() + " " + (formWorksDateRange || "")}</h3>
      </div>
      <div className="flex flex-col gap-y-10">
        {weeks &&
          weeks.length > 0 &&
          weeks.map((week: any, index: number) => {
            return (
              <TooplaanWeekTable
                week={week}
                saveCallback={saveWeekCallback}
                removeCallback={removeWeekCallback}
                changeWeekOrderCallback={changeWeekOrder}
                weekIndex={index}
                changeWeekCallback={changeWeek}
                key={index}
                disabled={disabled}
              />
            );
          })}
        <div className="flex flex-row items-center justify-center">
          <BhDropdown
            button={
              <BhTextOnlyButton icon={faCaretDown} buttonProps={{ disabled: disabled }}>
                {selectedYear}
              </BhTextOnlyButton>
            }
            menu={<BhDropdownMenu values={availableYears} onSelect={(value: string) => setSelectedYear(parseInt(value))} type={BhDropdownTypeEnum.STRING} widthClass="w-fit" />}
            position={BhDropdownPositionEnum.BOTTOM_LEFT}
            disabled={disabled}
          />
          <BhDropdown
            button={
              <BhTextOnlyButton icon={faCaretDown} buttonProps={{ disabled: disabled }}>
                {selectedWeek.number + " (" + selectedWeek.dateRange + ")"}
              </BhTextOnlyButton>
            }
            menu={<BhDropdownMenu values={selectedYearWeeks} textProperty={"text"} onSelect={setSelectedWeek} type={BhDropdownTypeEnum.STRING} widthClass="w-fit whitespace-nowrap" />}
            position={BhDropdownPositionEnum.BOTTOM_LEFT}
            disabled={disabled}
          />
          <BhSecondaryButton buttonProps={{ onClick: addWeek, disabled: disabled }}>{t("FORMS.TOOPLAAN.ADD_WEEK")}</BhSecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default FormTooplaanTableField;
