import React, { FC } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import { faArrowDown } from "@fortawesome/pro-regular-svg-icons/faArrowDown";
import { faArrowUp } from "@fortawesome/pro-regular-svg-icons/faArrowUp";
import BhDeclineIconButton from "@components/buttons/BhDeclineIconButton";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";

interface Props {
  signature: ISignatureHolder;
  index: number;
  length: number;
  deleteSignature: (signature: ISignatureHolder) => any;
  moveSignatureUp: (signature: ISignatureHolder) => any;
  moveSignatureDown: (signature: ISignatureHolder) => any;
}

const InitialQueueSignatureActionButtons: FC<Props> = ({ signature, index, length, deleteSignature, moveSignatureUp, moveSignatureDown }) => {
  const showMoveSignatureUp = index > 0;
  const showMoveSignatureDown = index + 1 < length;

  return (
    <>
      {showMoveSignatureUp && <BhIconButton icon={faArrowUp} withBackground={true} buttonProps={{ onClick: () => moveSignatureUp(signature) }} />}
      {showMoveSignatureDown && <BhIconButton icon={faArrowDown} withBackground={true} buttonProps={{ onClick: () => moveSignatureDown(signature) }} />}
      <BhDeclineIconButton icon={faCircleMinus} buttonProps={{ onClick: () => deleteSignature(signature) }} />
    </>
  );
};

export default InitialQueueSignatureActionButtons;
