import React, { Dispatch, FC, SetStateAction } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignature } from "@fortawesome/pro-regular-svg-icons/faSignature";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import { fetchPublicUrlForContainer } from "@/api/fileAPI";
import { imitateUrlDownload } from "@/utilities/downloadUtilities";
import BhLightSeparatorVertical from "@components/separator/BhLightSeparatorVertical";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { ISignInvite } from "@/model/ISignInvite";

interface Props {
  signInvite?: ISignInvite;
  containerUuid: string;
  signInviteUuid: string;
  setContainerSignModalShown: Dispatch<SetStateAction<boolean>>;
  setContainerDeclineModalShown: Dispatch<SetStateAction<boolean>>;
}

const PublicContainerHeader: FC<Props> = ({ signInvite, containerUuid, signInviteUuid, setContainerSignModalShown, setContainerDeclineModalShown }) => {
  const { t } = useTranslation();

  const showSigningButtons = signInvite && !signInvite?.signed && !signInvite?.declined;
  const showDeclineButton = true;

  return (
    <div className="bh-bg-mint flex w-full">
      <div className="mx-auto flex w-full max-w-[1366px] flex-row flex-wrap items-center justify-between px-6 py-2.5">
        <div>
          <FontAwesomeIcon icon={faSignature} />
          <span className="ml-1.5">{t("PROJECT.SIDEBAR.SIGNATURES")}</span>
        </div>
        <div className="flex h-full items-center">
          <BhIconButton
            icon={faArrowDownToLine}
            buttonProps={{
              onClick: () => {
                fetchPublicUrlForContainer(containerUuid, signInviteUuid).then((presignedUrl) => imitateUrlDownload(presignedUrl.value));
              },
              classes: "hover:bh-bg-mint-120b"
            }}
          />
          {showSigningButtons && (
            <>
              <div className="h-full px-2">
                <BhLightSeparatorVertical />
              </div>
              {showDeclineButton && (
                <BhTextOnlyButton icon={faXmark} buttonProps={{ onClick: () => setContainerDeclineModalShown(true), classes: "hover:bh-bg-mint-120b" }}>
                  <Trans>GLOBAL.DECLINE_SIGNATURE</Trans>
                </BhTextOnlyButton>
              )}
              <BhPrimaryButton icon={faCheck} buttonProps={{ onClick: () => setContainerSignModalShown(true) }}>
                <Trans>GLOBAL.SIGN</Trans>
              </BhPrimaryButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PublicContainerHeader;
