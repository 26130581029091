import React, { FC, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { useAppDispatch } from "@/app/hooks";
import BhModal from "@components/modal/BhModal";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhTabs from "@components/tabs/BhTabs";
import { toggleUserProfileModalsOpen } from "@/app/store/userPreferenceSlice";
import { Trans, useTranslation } from "react-i18next";
import PersonalizationIdCardTab from "@/views/home/profile/ProfileTabs/profileAuthSettings/profileAuthPersonalizationTabs/PersonalizationIdCardTab";
import PersonalizationMobileIdTab from "@/views/home/profile/ProfileTabs/profileAuthSettings/profileAuthPersonalizationTabs/PersonalizationMobileIdTab";
import PersonalizationSmartIdTab from "@/views/home/profile/ProfileTabs/profileAuthSettings/profileAuthPersonalizationTabs/PersonalizationSmartIdTab";
import BhModalFooter from "@components/modal/BhModalFooter";

const ProfileAuthSettingsPersonalizationModal: FC = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState("numberInput");
  const [tab, setTab] = useState(1);

  const [verification, setVerification] = useState("");
  const dispatch = useAppDispatch();

  const tabs = [
    { id: 0, header: <h2>{t("GLOBAL.ID_CARD")}</h2>, content: <PersonalizationIdCardTab /> },
    { id: 1, header: <h2>{t("GLOBAL.M_ID")}</h2>, content: <PersonalizationMobileIdTab setStatus={setStatus} setVerification={setVerification} /> },
    { id: 2, header: <h2>{t("GLOBAL.S_ID")}</h2>, content: <PersonalizationSmartIdTab setStatus={setStatus} setVerification={setVerification} /> }
  ];

  const closeModal = () => {
    dispatch(toggleUserProfileModalsOpen({ modal: "profileAuthSettingsPersonalizationModal" }));
  };

  if (status === "numberInput") {
    return (
      <BhModal
        size={"2xl"}
        isShown={true}
        setIsShown={() => {}}
        onClose={closeModal}
        header={
          <h2>
            <Trans>USER.PROFILE.PERSONALIZATION_MODAL.HEADER</Trans>
          </h2>
        }
        children={
          <div className="mt-6 flex flex-col">
            <h3 className="mx-20 mb-4">
              <Trans>USER.PROFILE.PERSONALIZATION_MODAL.BODY</Trans>
            </h3>
            <BhTabs tabsContainerClasses="mx-20" tabs={tabs} tabClasses="w-1/3" callback={setTab} initialActiveTab={1} tabsWidthFull={true} />
          </div>
        }
      />
    );
  } else if (status === "verificationInput") {
    return (
      <BhModal
        size={"2xl"}
        isShown={true}
        setIsShown={() => {}}
        onClose={closeModal}
        header={
          <h2>
            <Trans>USER.PROFILE.PERSONALIZATION_MODAL.HEADER</Trans>
          </h2>
        }
        children={
          <div>
            <h3 className="mx-20 mb-12 mt-14 w-3/4">
              {tab === 1 && <>{t("USER.PROFILE.PERSONALIZATION_MODAL.M_ID.HEADER")}</>}
              {tab === 2 && <>{t("USER.PROFILE.PERSONALIZATION_MODAL.S_ID.HEADER")}</>}
            </h3>
            <div className="mx-20 my-6 flex w-3/4 flex-col">
              <span>{t("USER.PROFILE.PERSONALIZATION_MODAL.CONFIRM_CODE")}</span>
              <h1>{verification}</h1>
            </div>
            <div className="mx-20 my-6 w-3/4">
              {tab === 1 && <>{t("USER.PROFILE.PERSONALIZATION_MODAL.M_ID.INFO")}</>}
              {tab === 2 && <>{t("USER.PROFILE.PERSONALIZATION_MODAL.S_ID.INFO")}</>}
            </div>
          </div>
        }
        footer={
          <div>
            <BhTextOnlyButton
              buttonProps={{
                onClick: () => {
                  closeModal();
                  setStatus("numberInput");
                }
              }}
            >
              {t("MODAL.DEFAULT.CONFIRMATION.CANCEL")}
            </BhTextOnlyButton>
          </div>
        }
      />
    );
  } else if (status === "success") {
    return (
      <BhModal
        isShown={true}
        setIsShown={() => {}}
        onClose={closeModal}
        header={
          <div className="flex items-center">
            <FontAwesomeIcon icon={faCheckCircle} className="bh-text-success-green mr-4" size="lg" aria-hidden="true" />
            <h2 className="capitalize">{t("USER.PROFILE.PERSONALIZED_BADGE")}</h2>
          </div>
        }
        children={
          <div className="my-8 ml-8 flex w-3/4 flex-col">
            <p>{t("USER.PROFILE.PERSONALIZATION_MODAL.SUCCESS")}</p>
          </div>
        }
        footer={
          <BhModalFooter
            cancelButtonText={t("GLOBAL.CLOSE") as string}
            onCancel={() => {
              closeModal();
              setStatus("numberInput");
            }}
          />
        }
      />
    );
  } else {
    return <div />;
  }
};

export default ProfileAuthSettingsPersonalizationModal;
