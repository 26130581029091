import React, { FC, useRef } from "react";
import { IBhUploadButton } from "@components/buttons/IBhButtonProps";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";

const BhUploadButton: FC<IBhUploadButton> = ({ buttonProps, onFileSelected, icon, children }) => {
  const fileRef = useRef<HTMLInputElement | null>(null);

  buttonProps = { ...buttonProps, onClick: () => fileRef.current && fileRef.current.click() };
  buttonProps.classes += " px-4 my-4 min-w-70px";

  const handleChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      onFileSelected(file);
    }
    event.target.value = "";
  };

  return (
    <div>
      <BhSecondaryButton
        buttonProps={buttonProps}
        children={
          <div>
            <span>{children}</span>
            <input ref={fileRef} type="file" style={{ display: "none" }} accept="image/*" onChange={handleChange} />
          </div>
        }
      />
    </div>
  );
};

export default BhUploadButton;
