import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { IFormBaseVersionRow } from "@/model/form/IFormBaseVersionRow";
import {
  fetchFormBaseVersionRowsForEditing,
  removeFieldFromBaseVersionRow,
  removeFormBaseVersionRow,
  saveFormBaseVersionRowField,
  saveFormBaseVersionRowWithNewField,
  saveNewFormBaseVersionRow
} from "@/api/form/formBaseAPI";
import { IFormFieldSaveRequest } from "@/model/form/IFormFieldSaveRequest";

export const formBaseRowsAdapter = createEntityAdapter<IFormBaseVersionRow>({
  selectId: (entity) => entity._id
});

export interface IFormBaseRowsState {
  status: BhStateStatusType;
}

export const formBaseRowsInitialState = formBaseRowsAdapter.getInitialState<IFormBaseRowsState>({
  status: BhStateStatusType.INITIAL
});

export const fetchFormBaseVersionRowsForEditingAsync = createAsyncThunk("form/base/version/rows/edit", async (dto: { companyId: EntityId; formBaseId: EntityId; formBaseVersionId: EntityId }) => {
  return fetchFormBaseVersionRowsForEditing(dto.companyId, dto.formBaseId, dto.formBaseVersionId);
});

export const saveNewFormBaseVersionRowAsync = createAsyncThunk(
  "form/base/version/row/new/save",
  async (dto: { companyId: EntityId; formBaseId: EntityId; formBaseVersionRow: IFormBaseVersionRow }) => {
    return saveNewFormBaseVersionRow(dto.companyId, dto.formBaseId, dto.formBaseVersionRow);
  }
);

export const removeFormBaseVersionRowAsync = createAsyncThunk("form/base/version/row/remove", async (dto: { companyId: EntityId; formBaseId: EntityId; formBaseVersionRow: IFormBaseVersionRow }) => {
  return removeFormBaseVersionRow(dto.companyId, dto.formBaseId, dto.formBaseVersionRow);
});

export const saveFormBaseVersionRowWithNewFieldAsync = createAsyncThunk(
  "form/base/version/field/new/save",
  async (dto: { companyId: EntityId; formBaseId: EntityId; formBaseVersionRow: IFormBaseVersionRow }) => {
    return saveFormBaseVersionRowWithNewField(dto.companyId, dto.formBaseId, dto.formBaseVersionRow);
  }
);

export const removeFieldFromBaseVersionRowAsync = createAsyncThunk(
  "form/base/version/field/remove",
  async (dto: { companyId: EntityId; formBaseId: EntityId; formBaseVersionRow: IFormBaseVersionRow }) => {
    return removeFieldFromBaseVersionRow(dto.companyId, dto.formBaseId, dto.formBaseVersionRow);
  }
);

export const saveFormBaseVersionRowFieldAsync = createAsyncThunk(
  "form/base/version/field/save",
  async (dto: { companyId: EntityId; formBaseId: EntityId; formFieldSaveRequest: IFormFieldSaveRequest }) => {
    return saveFormBaseVersionRowField(dto.companyId, dto.formBaseId, dto.formFieldSaveRequest);
  }
);

const formBaseRowsSlice = createSlice({
  name: "formBaseRows",
  initialState: formBaseRowsInitialState,
  reducers: {
    removeAllFormBaseRows: formBaseRowsAdapter.removeAll,
    addOneFormBaseRow: formBaseRowsAdapter.addOne,
    modifyOneFormBaseRow: formBaseRowsAdapter.upsertOne,
    modifyFormBaseRows: formBaseRowsAdapter.upsertMany,
    setFormBaseRowsStatus: (state, action) => {
      state.status = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormBaseVersionRowsForEditingAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchFormBaseVersionRowsForEditingAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        formBaseRowsAdapter.setAll(state, action.payload);
      })
      .addCase(saveNewFormBaseVersionRowAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveNewFormBaseVersionRowAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        formBaseRowsAdapter.setAll(state, action.payload);
      })
      .addCase(removeFormBaseVersionRowAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(removeFormBaseVersionRowAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        formBaseRowsAdapter.setAll(state, action.payload);
      })
      .addCase(saveFormBaseVersionRowWithNewFieldAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveFormBaseVersionRowWithNewFieldAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        formBaseRowsAdapter.setOne(state, action.payload);
      })
      .addCase(removeFieldFromBaseVersionRowAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(removeFieldFromBaseVersionRowAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        formBaseRowsAdapter.setOne(state, action.payload);
      })
      .addCase(saveFormBaseVersionRowFieldAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveFormBaseVersionRowFieldAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const formBaseVersionRow = Object.values(state.entities).find((row) => row && row.fields.some((field) => field._id === action.payload.changedObjectId));
        if (formBaseVersionRow) {
          const formBaseVersionRowField = formBaseVersionRow.fields.find((field) => field._id === action.payload.changedObjectId);
          if (formBaseVersionRowField) {
            formBaseVersionRowField.value[action.payload.changedProperty] = action.payload.changedValue;
          }
        }
      });
  }
});

export const { setFormBaseRowsStatus, addOneFormBaseRow, removeAllFormBaseRows, modifyOneFormBaseRow, modifyFormBaseRows } = formBaseRowsSlice.actions;

export const { selectAll: selectAllFormBaseRows, selectById: selectFormBaseRowById, selectIds: selectFormBaseRowIds } = formBaseRowsAdapter.getSelectors((state: RootState) => state.formBaseRows);
export const selectBaseRowsLoadingStatus = (state: RootState) => state.formBaseRows.status;

export const selectAllFormBaseVersionRowsSorted = createSelector([selectAllFormBaseRows], (baseRows) => {
  return baseRows.slice().sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1));
});

export default formBaseRowsSlice.reducer;
