import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import BimContainer from "@/views/home/project/bim/BimContainer";
import BimDirectoryContainer from "@/views/home/project/bim/BimDirectoryContainer";
import { useAppSelector } from "@/app/hooks";
import { selectBimDirectoryId } from "@/app/store/project/projectSlice";

const BimRouter: FC = () => {
  const bimDirectoryId = useAppSelector(selectBimDirectoryId);

  return (
    <Routes>
      <Route path={"/"} element={<BimContainer bimDirectoryId={bimDirectoryId} />} />
      <Route path={"/dir"} element={<BimDirectoryContainer bimDirectoryId={bimDirectoryId} />} />
    </Routes>
  );
};

export default BimRouter;
