import React, { FC, ReactNode } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { useAppSelector } from "@/app/hooks";
import { ITask } from "@/model/taskBoard/ITask";
import { faLock } from "@fortawesome/pro-solid-svg-icons/faLock";
import { faUnlock } from "@fortawesome/pro-regular-svg-icons/faUnlock";
import BhLightSeparatorVertical from "@components/separator/BhLightSeparatorVertical";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import { selectTaskBoardById } from "@/app/store/taskBoardsSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhBreadcrumbs from "@components/breadcrumb/BhBreadcrumbs";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  task: ITask;
  toggleLock: Function;
  enableTaskLocking: boolean;
  actionButtons: Array<ReactNode>;
}

const DedicatedTaskViewHeader: FC<Props> = ({ task, toggleLock, actionButtons, enableTaskLocking }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const taskBoard = useAppSelector((state) => selectTaskBoardById(state, task.taskBoardId));

  const breadCrumbs = [
    { text: taskBoard ? taskBoard.name : t("TASKBOARD.PERSONAL"), url: ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/taskboard/list/${taskBoard ? taskBoard.id : "personal"}` },
    task.taskIdentifier && { text: task.taskIdentifier }
  ].filter(Boolean) as Array<{ text: string; url?: string; onClick?: Function }>;

  return (
    <div className="flex h-full flex-row items-center justify-between">
      <BhBreadcrumbs values={breadCrumbs} />
      <div className="flex h-full flex-row items-center">
        {enableTaskLocking && (
          <div className="flex h-full flex-row items-center">
            <BhTooltip body={t("TASK.LOCK.MESSAGE")} key={1}>
              <BhIconButton
                icon={task.locked ? faLock : faUnlock}
                buttonProps={{
                  classes: "mx-1.25",
                  onClick: toggleLock
                }}
              />
            </BhTooltip>
            <div className="mx-2 h-full py-3.5">
              <BhLightSeparatorVertical />
            </div>
          </div>
        )}
        {actionButtons.map((button) => {
          return button;
        })}
      </div>
    </div>
  );
};

export default DedicatedTaskViewHeader;
