import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock";

const BhStatusCellWaiting: FC = () => {
  return (
    <div className="bh-bg-warning-yellow-10 flex h-14 w-12 flex-row items-center justify-center">
      <FontAwesomeIcon icon={faClock} className="bh-text-warning-yellow-120 h-4 w-4" />
    </div>
  );
};

export default BhStatusCellWaiting;
