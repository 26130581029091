import React, { FC, useState } from "react";
import { useAppSelector } from "@/app/hooks";
import { classNames } from "@/utilities/jsUtilities";
import { EntityId } from "@reduxjs/toolkit";
import BhFileCardDateWithName from "@components/cards/BhFileCardDateWithName";
import { selectActById } from "@/app/store/actsSlice";
import ActListItemContainerDropdown from "@/views/home/project/detail/actsAndContracts/acts/ActListItemContainerDropdown";
import { useNavigate } from "react-router-dom";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons/faPaperclip";
import BhElementWithBadge from "@components/badge/BhElementWithBadge";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import BhIconButton from "@components/buttons/BhIconButton";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { Trans, useTranslation } from "react-i18next";
import { ActStatus, SendingStatus } from "@/model/IAct";
import BhStatusCellSigned from "@components/status/BhStatusCellSigned";
import BhStatusCellDenied from "@components/status/BhStatusCellDenied";
import BhStatusCellWaiting from "@components/status/BhStatusCellWaiting";
import { selectActsPrivilegesForParty, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import ActAttachmentsModal from "@/views/home/project/detail/actsAndContracts/acts/actsModals/ActAttachmentsModal";
import MrActSendingModal from "@/views/home/project/detail/actsAndContracts/acts/actsModals/MrActSendingModal";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import BhTag from "@components/tags/BhTag";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  actId: EntityId;
  partyId: EntityId;
}

const MrActListItemContainerFC: FC<Props> = ({ actId, partyId }) => {
  const act = useAppSelector((state) => selectActById(state, actId));
  const projectId = useAppSelector(selectCurrentProjectId);
  const isProjectAdmin = useAppSelector((state) => selectIsCurrentUserProjectAdmin(state, projectId));
  const actPrivileges = useAppSelector((state) => selectActsPrivilegesForParty(state, partyId));
  const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);
  const [showSendingModal, setShowSendingModal] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!act) {
    return null;
  }

  return (
    <>
      <div className={classNames("bh-bg-white hover:bh-bg-mint-30", "bh-border-pigeon-40 bh-hover-container group block flex h-14 items-center border-b")}>
        <div className="w-18 flex flex-none items-center justify-center px-3">{act.number}</div>
        <div className="flex flex-grow flex-row items-center space-x-2 overflow-hidden px-3">
          <BhFileCardIcon fileEntity={{ type: FileEntityType.ACT } as IFileEntity} fileCardSize={FileCardSize.LARGE} />
          <a
            className={classNames("filename cursor-pointer hover:underline")}
            onClick={() => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/contract/${partyId}/act/${actId}`)}
          >
            {act.name}
          </a>
        </div>
        <div className="w-14 flex-none text-center">
          <BhElementWithBadge badge={act.attachments?.length > 0 && <BhBadge type={BhBadgeType.DARK}>{act.attachments?.length}</BhBadge>}>
            <BhIconButton icon={faPaperclip} buttonProps={{ onClick: () => setShowAttachmentsModal(true) }} />
          </BhElementWithBadge>
        </div>
        <div
          className={classNames(act.digiDocContainerId && "cursor-pointer", "w-12 flex-none text-center")}
          onClick={() => {
            if (act?.digiDocContainerId) {
              navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/container/${act?.digiDocContainerId}`);
            }
          }}
        >
          {act.status === ActStatus.SIGNED && <BhStatusCellSigned />}
          {act.status === ActStatus.REFUSED && <BhStatusCellDenied />}
          {(act.status === ActStatus.SIGNING || act.status === ActStatus.NO_SIGNERS) && <BhStatusCellWaiting />}
        </div>
        <div className={classNames("flex-none px-3", isProjectAdmin ? "w-40" : "w-56")}>
          <Trans>{"ACT.STATUS." + act.status}</Trans>
        </div>
        {isProjectAdmin && (
          <div className="w-40 flex-none px-3">
            <div className="cursor-pointer" onClick={() => setShowSendingModal(true)}>
              {act.actSendingStatus === SendingStatus.FAILED && <BhTag type={BhTagType.FAILED} children={t("ACT.SENDING.STATUS.FAILED")} />}
              {act.actSendingStatus === SendingStatus.SENT && <BhTag type={BhTagType.SENT} children={t("ACT.SENDING.STATUS.SENT")} />}
              {act.actSendingStatus === SendingStatus.NOT_SENT && <BhTag type={BhTagType.NOT_SENT} children={t("ACT.SENDING.STATUS.NOT_SENT")} />}
            </div>
          </div>
        )}
        <div className="w-40 flex-none px-3">
          <BhFileCardDateWithName fileDate={act.created} fullName={act.createdByFullName} />
        </div>
        <div className="w-14 flex-none px-3 text-right">
          <ActListItemContainerDropdown actId={actId} isWritePrivilege={isProjectAdmin || actPrivileges.isWrite} isLatestAct={act.latest} />
        </div>
      </div>
      {showAttachmentsModal && <ActAttachmentsModal act={act} closeModal={() => setShowAttachmentsModal(false)} isWritePrivilege={isProjectAdmin || actPrivileges.isWrite} />}
      {showSendingModal && isProjectAdmin && <MrActSendingModal act={act} closeModal={() => setShowSendingModal(false)} />}
    </>
  );
};

const MrActListItemContainer = React.memo(MrActListItemContainerFC);
export default MrActListItemContainer;
