import React, { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveCurrentUserInfoAsync, selectCurrentUserInfo } from "@/app/store/userSlice";
import { IUserInfo } from "@/model/IUserInfo";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { toggleHideUserContactsGloballyAsync } from "@/app/store/project/projectContactsSlice";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import { Trans, useTranslation } from "react-i18next";

const ProfileUserInfoForm: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(selectCurrentUserInfo);

  const onUserInfoSubmit = useCallback(
    (changedValue: IUserInfo) => {
      const changedObject = { ...userInfo, ...changedValue };
      dispatch(saveCurrentUserInfoAsync(changedObject));
    },
    [userInfo]
  );

  return (
    <div>
      <div className="mt-6">
        <BhInputStackedLabel
          initialValue={userInfo.title}
          property="title"
          onChangeCallback={onUserInfoSubmit}
          onBlurAction={onUserInfoSubmit}
          label={t("USER.INFO.TITLE")}
          debounceLength={900}
          placeholderDisabled={true}
        />
      </div>
      <div className="mt-6">
        <BhInputStackedLabel
          initialValue={userInfo.companyName}
          property="companyName"
          onChangeCallback={onUserInfoSubmit}
          onBlurAction={onUserInfoSubmit}
          label={t("USER.INFO.COMPANY")}
          debounceLength={900}
          placeholderDisabled={true}
        />
      </div>
      <div className="mt-6">
        <BhInputStackedLabel
          initialValue={userInfo.contactNumber}
          property="contactNumber"
          type="tel"
          onChangeCallback={onUserInfoSubmit}
          onBlurAction={onUserInfoSubmit}
          label={t("USER.INFO.PHONE")}
          debounceLength={900}
          placeholderDisabled={true}
        />
      </div>
      <div className="my-11 flex w-full flex-row items-center justify-between">
        <div className="text-14px bh-text-deep-ocean leading-5">
          <Trans>USER.PROFILE.DETAILS.HIDE_MY_INFO</Trans>
        </div>
        <div>
          <BhToggleSwitch
            value={userInfo.contactsHidden}
            onClickAction={() => dispatch(toggleHideUserContactsGloballyAsync({ userEntityId: userInfo.userEntityId, hide: !userInfo.contactsHidden }))}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileUserInfoForm;
