import React, { FC } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import BhIFrameComponent from "@/views/global/BhIFrameComponent";
import BauhubAdminHome from "@/views/home/admin/BauhubAdminHome";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

const BauhubAdminRouter: FC = () => {
  function CompanyEditWithParams() {
    const { companyId } = useParams();
    return companyId ? <BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/admin/plan/list/edit/${companyId}`} /> : null;
  }

  function InvoiceMonthWithParams() {
    const { month } = useParams();
    return month ? <BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/admin/plan/invoices/month/${month}`} /> : null;
  }

  return (
    <div className="h-full w-full overflow-hidden px-8">
      <Routes>
        <Route index element={<BauhubAdminHome />} />
        <Route path="companies/list" element={<BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/admin/plan/list`} />} />
        <Route path="company/:companyId" element={<CompanyEditWithParams />} />
        <Route path="users" element={<BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/admin/plan/users`} />} />
        <Route path="backend" element={<BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/admin/plan/backend`} />} />
        <Route path="template" element={<BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/admin/plan/template`} />} />
        <Route path="analytics" element={<BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/admin/plan/analytics`} />} />
        <Route path="statistics" element={<BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/admin/plan/statistics`} />} />
        <Route path="asyncjobs" element={<BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/admin/plan/asyncjobs`} />} />
        <Route path="invoices" element={<BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/admin/plan/invoices`} />} />
        <Route path="invoices/month/:month" element={<InvoiceMonthWithParams />} />
        <Route path="clients" element={<BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/admin/plan/clients`} />} />
        <Route path="trials" element={<BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/admin/plan/trials/2023`} />} />
        <Route path="sessions" element={<BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/admin/plan/sessions`} />} />
        <Route path="events" element={<BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/admin/plan/events`} />} />
        <Route path="killswitch" element={<BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/admin/plan/killswitch`} />} />
        <Route path="logs" element={<BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/admin/plan/logs?pageSize=25&pageNumber=1`} />} />
      </Routes>
    </div>
  );
};

export default BauhubAdminRouter;
