import React, { FC } from "react";
import { BauhubBannerType, BauhubPlan, ProjectStatus } from "@/model/IProject";
import BhBanner from "@components/banners/BhBanner";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProject } from "@/app/store/project/projectSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxArchive } from "@fortawesome/pro-solid-svg-icons/faBoxArchive";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { classNames, getArchivedTimeLeft, textWithVariables } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";
import { selectCompanyById } from "@/app/store/companiesSlice";
import parse from "html-react-parser";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
  bodyOverflowHidden?: boolean;
}

const DetailViewContainer: FC<Props> = ({ header, footer, children, bodyOverflowHidden }) => {
  const currentProject = useAppSelector(selectCurrentProject);
  const currentCompany = useAppSelector((state) => selectCompanyById(state, currentProject.companyId));
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const isProjectArchived = currentProject && currentProject.status === ProjectStatus.ARCHIVED;
  const isCurrentLocationProjectSettings = location.pathname.includes("project") && location.pathname.includes("settings");

  const archivedTimeLeft = getArchivedTimeLeft(currentProject.archivedDate);

  const projectArchivedBanner = (
    <BhBanner type={BauhubBannerType.ANNOUNCEMENT_LIGHT} fullWidth={true}>
      <div className="flex w-full items-center justify-between">
        <div>
          <FontAwesomeIcon icon={faBoxArchive} className="mr-5 ml-1" />
          {currentCompany?.plan === BauhubPlan.LIGHT && (
            <span>
              <span>{t("PROJECT.BANNER.ARCHIVED_2")}</span>
              <span>. </span>
              <span>
                {parse(
                  textWithVariables(t("PROJECT.BANNER.ARCHIVED.LIGHT"), {
                    year: archivedTimeLeft.years().toString(),
                    month: archivedTimeLeft.months().toString(),
                    day: archivedTimeLeft.days().toString(),
                    monthText: archivedTimeLeft.months() === 1 ? t("PROJECT.BANNER.ARCHIVED.LIGHT.MONTH") : t("PROJECT.BANNER.ARCHIVED.LIGHT.MONTHS"),
                    dayText: archivedTimeLeft.days() === 1 ? t("PROJECT.BANNER.ARCHIVED.LIGHT.DAY") : t("PROJECT.BANNER.ARCHIVED.LIGHT.DAYS"),
                    yearText: archivedTimeLeft.years() === 1 ? t("PROJECT.BANNER.ARCHIVED.LIGHT.YEAR") : t("PROJECT.BANNER.ARCHIVED.LIGHT.YEARS")
                  })
                )}
              </span>
            </span>
          )}
          {currentCompany?.plan !== BauhubPlan.LIGHT && <span>{t("PROJECT.BANNER.ARCHIVED")}</span>}
        </div>
        {!isCurrentLocationProjectSettings && (
          <BhTextOnlyButton buttonProps={{ inverted: true, onClick: () => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${currentProject.id}/settings`) }}>
            {t("PROJECT.BANNER.ARCHIVED.BUTTON")}
          </BhTextOnlyButton>
        )}
      </div>
    </BhBanner>
  );

  return (
    <div className="mx-auto flex h-full w-full min-w-0 max-w-[1366px] overflow-hidden">
      <div className="flex w-full flex-col overflow-hidden px-6 pt-4 pb-2">
        {isProjectArchived && <div className="my-2 pb-1">{projectArchivedBanner}</div>}
        <div className="flex-2">{header}</div>
        <div className={classNames(bodyOverflowHidden ? "overflow-hidden" : "overflow-y-auto", "flex-1 px-0.5")}>{children}</div>
        <div className="flex-2">{footer}</div>
      </div>
    </div>
  );
};

export default DetailViewContainer;
