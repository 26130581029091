import React, { FC, useEffect } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchPartyActsAsync, selectActIdsForPartyId } from "@/app/store/actsSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { selectProjectWorkGroupById } from "@/app/store/project/projectWorkGroupsSlice";
import PartiesListItemTag from "@/views/home/project/detail/users/parties/PartiesListItemTag";
import BhHeadingBreadcrumbText from "@components/breadcrumb/BhHeadingBreadcrumbText";
import { Trans } from "react-i18next";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import ActsListContainerHeaderButtons from "@/views/home/project/detail/actsAndContracts/acts/ActsListContainerHeaderButtons";
import MrActsListTableHeader from "@/views/home/project/detail/actsAndContracts/acts/MrActsListTableHeader";
import MrActListItemContainer from "@/views/home/project/detail/actsAndContracts/acts/MrActListItemContainer";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  partyId: EntityId;
}

const MrActsListContainer: FC<Props> = ({ partyId }) => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const party = useAppSelector((state) => selectProjectWorkGroupById(state, partyId));
  const actIds = useAppSelector((state) => selectActIdsForPartyId(state, partyId));
  const dispatch = useAppDispatch();

  const anyActsPresent = actIds.length > 0;

  useEffect(() => {
    dispatch(fetchPartyActsAsync({ partyId: partyId, projectId: projectId }));
  }, []);

  return (
    <BhScrollableBody
      header={
        <div className="px-6">
          <div className="flex flex-row py-6">
            <div className="flex-1">
              <div className="flex flex-row items-center space-x-2 pb-1 pt-1">
                <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/contracts`}>
                  <BhHeadingBreadcrumbText>
                    <Trans>GLOBAL.ACTS_AND_CONTRACTS</Trans>
                  </BhHeadingBreadcrumbText>
                </Link>
                <FontAwesomeIcon icon={faChevronRight} />
                <h2 className="bh-text-deep-ocean m-0">{party?.name}</h2>
                {party?.type && <PartiesListItemTag workGroupType={party.type} />}
              </div>
            </div>
            <ActsListContainerHeaderButtons partyId={partyId} />
          </div>
          <MrActsListTableHeader projectId={projectId} />
        </div>
      }
    >
      <div className="flex-col px-6">
        {actIds.map((actId) => {
          return <MrActListItemContainer key={actId} actId={actId} partyId={partyId} />;
        })}
        {!anyActsPresent && (
          <BhNoMatchesImage>
            <Trans>ACTS.NONE</Trans>
          </BhNoMatchesImage>
        )}
      </div>
    </BhScrollableBody>
  );
};

export default MrActsListContainer;
