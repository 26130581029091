import React, { FC } from "react";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { IProject, ProjectStatus } from "@/model/IProject";
import { useTranslation } from "react-i18next";
import { formatDate } from "@/utilities/dateUtility";
import { IProjectOrder } from "@/model/billing/IProjectOrder";
import { ISyncProject } from "@/model/sync/ISyncProject";

interface Props {
  project: IProject | IProjectOrder | ISyncProject;
}

const ProjectListItemTag: FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const projectClosed = project.closed;
  const projectSuspended = project.suspended;
  const projectArchived = project.status === ProjectStatus.ARCHIVED;
  const archivedDate = "archivedDate" in project ? project.archivedDate : "";

  return (
    <>
      {project && (
        <div className="flex gap-2 pr-4">
          {projectClosed && <BhTag type={BhTagType.VOLGNEVUS}>{t("PROJECT.STATUS.CLOSED")}</BhTag>}
          {projectSuspended && <BhTag type={BhTagType.VOLGNEVUS}>{t("COMPANY.SETTINGS.ORDERS.SUSPENDED")}</BhTag>}
          {projectArchived && archivedDate && (
            <BhTag type={BhTagType.ARHIVEERITUD}>
              {t("PROJECT.STATUS.ARCHIVED")} {formatDate(archivedDate)}
            </BhTag>
          )}
          {projectArchived && !archivedDate && <BhTag type={BhTagType.ARHIVEERITUD}>{t("PROJECT.STATUS.ARCHIVED")}</BhTag>}
        </div>
      )}
    </>
  );
};

export default ProjectListItemTag;
