import { store } from "@/app/store";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { ICustomerAddressObject } from "@/model/ICustomerAddressObject";
import { BauhubBannerType } from "@/model/IProject";
import { IUser } from "@/model/IUser";
import { IUserMention } from "@/model/IUserMention";
import { IFileEntity } from "@/model/files/IFileEntity";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { IFileDirectoryDTO } from "@/model/utilities/IFileDirectoryDTO";
import { EntityId } from "@reduxjs/toolkit";
import moment from "moment";
import React, { FC, ReactNode, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

export function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export const mapToObject = (map: any[] | Map<string, number>) => Object.fromEntries(map.entries());
export const objectToMap = (obj: { [s: string]: unknown } | ArrayLike<unknown>) => new Map(Object.entries(obj));

export function formatFileSize(size: number) {
  const units = ["bytes", "kB", "MB", "GB", "TB", "PB"];
  const number = Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, Math.floor(number))).toFixed(1) + " " + units[number];
}

// Kui tahad saada eelmist väärtust enne mõne hooki uuesti laadimist
// eslint-disable-next-line
function usePrevious(value: { folderFileEntity: any }) {
  const ref = useRef();
  useEffect(() => {
    // @ts-ignore
    ref.current = value;
  });
  return ref.current;

  // Kasutad nii
  // const prevFileEntity = usePrevious({folderFileEntity});
  // useEffect(() => {
  //   console.log('previous');
  //   console.log(prevFileEntity);
  //   console.log('new');
  //   console.log(folderFileEntity);
  // }, [folderFileEntity]);
}

// SERIALISEERIB KA TÜHIKUD VEIDRAKS
export const serialize = (obj: any) =>
  Object.keys(obj)
    .filter((key) => obj[key] != null)
    .map((key) => `${key}=${encodeURIComponent(obj[key]).replace(/%20/g, "+")}`)
    .join("&");

// SERIALISEERIB PIGEM OTSINGU PARAMEETREID
export const serializeUrl = (obj: any) =>
  Object.keys(obj)
    .filter((key) => obj[key] != null)
    .map((key) => {
      const params = new URLSearchParams();
      params.set(key, obj[key]);
      return `${params.toString()}`;
    })
    .join("&");

export function castObject<T, K extends keyof T>(obj: T, keys: Array<K>): Pick<T, K> {
  const copy = {} as Pick<T, K>;

  keys.forEach((key) => {
    if (obj[key]) {
      return (copy[key] = obj[key]);
    }
  });

  return copy;
}

// eslint-disable-next-line
export const urlParamsToObject = <T, K extends keyof T>(search: string) => {
  return Object.fromEntries(new URLSearchParams(search));
};

export const ConditionalWrapper: FC<{
  condition: boolean;
  wrapper: any;
  children: React.ReactNode;
}> = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

// Kui tahad mõne muutuja väärtust string property kaudu
export const getKeyValue =
  <U extends keyof T, T extends object>(key: U) =>
  (obj: T) =>
    obj[key];
export const setKeyValue = <U extends keyof T, T extends object>(obj: T, key: U, value: T[U]) => (obj[key] = value);

// Cookie väärtuse saamiseks
export function getCookie(key: string) {
  let b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

// Teksti selekteerimiseks
export function setSelectionRange(field: React.RefObject<HTMLInputElement>, start: number, end: number) {
  if (!field.current) return;
  field.current.setSelectionRange(start, end);
  field.current.focus();
}

// Check if element is in viewport (for use in Dropdowns)
export function isElementVisible(el: HTMLElement) {
  const rect = el.getBoundingClientRect(),
    vWidth = window.innerWidth || document.documentElement.clientWidth,
    vHeight = window.innerHeight || document.documentElement.clientHeight,
    efp = function (x: number, y: number) {
      return document.elementFromPoint(x, y);
    };

  // Return false if it's not in the viewport
  if (rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight) return false;

  // Return true if any of its four corners are visible
  return el.contains(efp(rect.left, rect.top)) || el.contains(efp(rect.right, rect.top)) || el.contains(efp(rect.right, rect.bottom)) || el.contains(efp(rect.left, rect.bottom));
}

// Faili baitide suuruse arvutamine õigele kujule
export function bytesToSize(bytes: number) {
  if (isNaN(bytes) || !isFinite(bytes)) return "-";
  const units = ["bytes", "kB", "MB", "GB", "TB", "PB"],
    number = Math.floor(Math.log(bytes) / Math.log(1024));
  return (bytes / Math.pow(1024, Math.floor(number))).toFixed(1) + " " + units[number];
}

// Unikaalsete väärtuste saamiseks kindla propi abil
export function getUniqueValues(array: Array<any>, property?: keyof any) {
  let uniqueArray = [] as Array<any>;
  array.forEach((item) => {
    if (
      !uniqueArray.some((i) => {
        if (property) {
          return i[property] === item[property];
        } else {
          return i === item;
        }
      })
    ) {
      uniqueArray.push(item);
    }
  });
  return uniqueArray;
}

export function getUserFromFullName(fullName: string) {
  return { firstName: fullName.split(" ")[0], lastName: fullName.split(" ")[1], userExists: true } as IUser;
}

export function isImage(fileEntity: IFileEntity) {
  const imageContentTypes = ["image/png", "image/jpg", "image/jpeg", "image/pjpeg", "image/gif"];
  return imageContentTypes.indexOf(fileEntity.contentType) !== -1;
}

export function removeDuplicates(inputArray: Array<any>) {
  return Array.from(new Set(inputArray));
}

// https://stackoverflow.com/questions/1885557/simplest-code-for-array-intersection-in-javascript
export function intersection(array1: Array<any>, array2: Array<any>) {
  return array1.filter((value) => array2.includes(value));
}

export function findFolderWithPathFromRoot(rootDirectory: IFileDirectoryDTO, directoryId: EntityId, path?: string) {
  let dirPath = path ? path + "/" : "";
  dirPath += rootDirectory.name;
  if (rootDirectory.id === directoryId) {
    let foundDir = { ...rootDirectory };
    foundDir.path = dirPath;
    return foundDir;
  } else if (rootDirectory.subDirs) {
    let result = {} as IFileDirectoryDTO;
    let i = 0;
    for (i; !result.id && i < rootDirectory.subDirs.length; i++) {
      result = findFolderWithPathFromRoot(rootDirectory.subDirs[i], directoryId, dirPath);
    }
    return result;
  }
  return {} as IFileDirectoryDTO;
}

export function delay(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

export function isValidEmail(email: string) {
  const re = /^(([^<>()\[\]\\.,;:\s@ÕÄÖÜõäöü"]+(\.[^<>()\[\]\\.,;:\sÕÄÖÜõäöü"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function isValidEmails(emailsCommaSeparated?: string) {
  if (!emailsCommaSeparated) return false;
  const emails = emailsCommaSeparated?.split(",");
  const re = /^(([^<>()\[\]\\.,;:\s@ÕÄÖÜõäöü"]+(\.[^<>()\[\]\\.,;:\s@ÕÄÖÜõäöü"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  for (let i = 0; i < emails.length; i++) {
    if (!re.test(emails[i].trim())) {
      return false;
    }
  }
  return true;
}

export function textWithVariables(text: string, variables: Record<string, string>) {
  // using {{ text }} decoding
  let template = text;
  let r = template.match(/\{\{[\w]+\}\}/g);
  r &&
    r.forEach((state) => {
      let regex = new RegExp(state, "g");
      let stateItem = state.split(/{{|}}/g)[1];
      template = template.replace(regex, variables[stateItem] ?? "");
    });
  return template;
}

export function getProjectBillingAddress(addressObject: ICustomerAddressObject) {
  let addressList = [];
  if (addressObject.street && addressObject.house) {
    addressList.push(addressObject.street + " " + addressObject.house);
  } else if (addressObject.street && !addressObject.house) {
    addressList.push(addressObject.street);
  }
  addressList.push(addressObject.zipCode);
  addressList.push(addressObject.city);
  addressList.push(addressObject.state);
  addressList.push(addressObject.customerCountry);
  addressList = addressList.filter((listItem) => listItem !== "" && listItem);
  return addressList.join(", ");
}

//Dalev distance
export function dalevDistance(source: string, target: string) {
  if (!source) {
    return target ? target.length : 0;
  } else if (!target) {
    return source.length;
  }

  let m = source.length,
    n = target.length,
    INF = m + n,
    score = new Array(m + 2),
    sd = {} as any;
  for (let i = 0; i < m + 2; i++) {
    score[i] = new Array(n + 2);
  }
  score[0][0] = INF;
  for (let i = 0; i <= m; i++) {
    score[i + 1][1] = i;
    score[i + 1][0] = INF;
    sd[source[i]] = 0;
  }
  for (let j = 0; j <= n; j++) {
    score[1][j + 1] = j;
    score[0][j + 1] = INF;
    sd[target[j]] = 0;
  }

  for (let i = 1; i <= m; i++) {
    let DB = 0;
    for (let j = 1; j <= n; j++) {
      let i1 = sd[target[j - 1]],
        j1 = DB;
      if (source[i - 1] === target[j - 1]) {
        score[i + 1][j + 1] = score[i][j];
        DB = j;
      } else {
        score[i + 1][j + 1] = Math.min(score[i][j], Math.min(score[i + 1][j], score[i][j + 1])) + 1;
      }
      score[i + 1][j + 1] = Math.min(score[i + 1][j + 1], score[i1] ? score[i1][j1] + (i - i1 - 1) + 1 + (j - j1 - 1) : Infinity);
    }
    sd[source[i - 1]] = i;
  }
  return score[m + 1][n + 1];
}

export function dalevDistanceMatches(source: string, target: string, threshold: number) {
  return dalevDistance(source, target) <= threshold;
}

export function stringComparisonDistance(stringA: string, stringB: string) {
  const length = stringA.length;
  let countOfSameCharactersFromStringStart = 0;
  let i;
  for (i = 0; i < length; i++) {
    if (stringA[i] !== stringB[i]) {
      countOfSameCharactersFromStringStart = i;
      i = length;
    }
  }
  return countOfSameCharactersFromStringStart;
}

export function bauhubToString(value: any) {
  if (value === null || value === undefined) {
    return "";
  }
  return "" + value;
}

export function capitalizeFirstLetter(string: string) {
  const trimmed = string.trim();
  return trimmed[0].toUpperCase() + trimmed.slice(1).toLowerCase();
}

export const chunkArray = <T>(arr: Array<T>, size: number): Array<Array<T>> => {
  return arr.length > size ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)] : [arr];
};

export const copyToClipboard = async (textToCopy: string, toastTranslateCode: string) => {
  if ("clipboard" in navigator) {
    await navigator.clipboard.writeText(textToCopy);
  } else {
    document.execCommand("copy", true, textToCopy);
  }
  store.dispatch(
    toastFlagAdded({
      id: uuidv4(),
      type: BauhubBannerType.SUCCESS,
      disappear: true,
      translateCode: toastTranslateCode
    })
  );
};

export const formatAndRoundDecimalNumber = (number: number) => {
  const roundedNumber = Math.round(number * 100) / 100;
  return roundedNumber.toLocaleString("et-ET");
};

export function extractMentions(text: string, resourceId: EntityId, projectId: EntityId, resourceType: "TASK_COMMENT" | "FILE_COMMENT") {
  const mentionRegex = /<span class="user-mention" data-mentioned-by-user-id="(\d+)" data-mentioned-user-id="(\d+)" [^>]*>([^<]+)<\/span>/g;
  const mentions = [] as Array<IUserMention>;
  let match;
  while ((match = mentionRegex.exec(text)) !== null) {
    mentions.push({
      id: null,
      mentionedByUserEntityId: parseInt(match[1]),
      mentionedUserEntityId: parseInt(match[2]),
      resource: resourceType,
      resourceId: resourceId,
      deleted: false,
      projectId: projectId
    });
  }
  return mentions;
}

// for Bauhub Light projects
export function getArchivedTimeLeft(archivedDate: Date) {
  const archivedDateMoment = archivedDate ? moment(archivedDate, "YYYY-MM-DD") : moment("2023-03-15");
  const archivedDateToBeUsed = archivedDateMoment.isBefore(moment("2023-03-15")) ? moment("2023-03-15") : archivedDateMoment;
  return moment.duration(archivedDateToBeUsed.add(2, "years").diff(moment()));
}

// LATIN letters
export function replaceLatinLetters(name: string) {
  return bauhubToString(name).replace(/ö/g, "ö").replace(/ü/g, "ü").replace(/õ/g, "õ").replace(/ä/g, "ä");
}

// WAIT UNTIL CONDITION IS TRUE
export function untilCondition(conditionFunction: Function) {
  const poll = (resolve: any) => {
    if (conditionFunction()) resolve();
    else setTimeout((_) => poll(resolve), 1000);
  };

  return new Promise(poll);
}

export function overwriteEnvironmentVariables() {
  //@ts-ignore
  if (window.BAUHUB_CONFIGURATION) {
    //@ts-ignore
    ConfigSingleton.getInstance().updateConfig(window.BAUHUB_CONFIGURATION);
  }
}

export function sleep(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export const getNodeText = (node: ReactNode): any => {
  if (["string", "number"].includes(typeof node)) return node;
  if (node instanceof Array) return node.map(getNodeText).join("");
  if (typeof node === "object" && node) {
    // @ts-ignore
    return getNodeText(node.props.children);
  }
};

export const interleaveAndRemoveDuplicates = (arr1: Array<any>, arr2: Array<any>) => {
  const maxLength = Math.max(arr1.length, arr2.length);

  // Interleave the arrays
  const interleaved = [];
  for (let i = 0; i < maxLength; i++) {
    if (i < arr1.length) interleaved.push(arr1[i]);
    if (i < arr2.length) interleaved.push(arr2[i]);
  }

  // Remove duplicates by converting to a Set and back to an array
  return removeDuplicates(interleaved);
};

export const formatStringForFormBaseName = (formBaseName: string) => {
  return formBaseName.replace(/[<>:"\/\\|?*]/g, "-").toLowerCase();
};
