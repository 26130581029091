import React, { FC } from "react";
import ProjectLogFileHistoryModal from "@/features/projectLogTable/ProjectLogFileHistoryModal";
import ProjectLogFileRestoreModal from "@/features/projectLogTable/ProjectLogFileRestoreModal";
import { isFileHistoryModalShown, isFileRestoreModalShown } from "@/app/store/project/projectLogSlice";
import { useAppSelector } from "@/app/hooks";

const ProjectLogModals: FC = () => {
  const fileHistoryModalShown = useAppSelector(isFileHistoryModalShown);
  const fileRestoreModalShown = useAppSelector(isFileRestoreModalShown);

  return (
    <>
      {fileHistoryModalShown && <ProjectLogFileHistoryModal />}
      {fileRestoreModalShown && <ProjectLogFileRestoreModal />}
    </>
  );
};

export default ProjectLogModals;
