import React, { FC } from "react";
import { IFileEntity } from "@/model/files/IFileEntity";
import { Trans } from "react-i18next";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId, selectRootDirectoryId, selectRootDocumentDirectoryId } from "@/app/store/project/projectSlice";
import { useNavigate } from "react-router-dom";
import { formatDateISO } from "@/utilities/dateUtility";
import BhNoMatchesImage from "@components/images/BhNoMatchesImage";
import SearchFileCardContainer from "@/features/searchWithDropdown/SearchFileCardContainer";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  files: Array<IFileEntity>;
  count: number;
  isPlan: boolean;
}

const OverviewTabsFileList: FC<Props> = ({ files, count, isPlan }) => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const rootPlanDirId = useAppSelector(selectRootDirectoryId);
  const rootDocumentDirId = useAppSelector(selectRootDocumentDirectoryId);
  const rootDirId = isPlan ? rootPlanDirId : rootDocumentDirId;
  const navigate = useNavigate();

  const yesterday = formatDateISO(((d) => new Date(d.setDate(d.getDate() - 1)))(new Date()));

  const showProjectLog = () => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/log?page=1&since=${yesterday}&folderIds=${rootDirId}&limit=25`);
  };

  return (
    <>
      {count > 0 && (
        <div className="space-y-1 pb-4">
          {files.map((fileEntity) => {
            return <SearchFileCardContainer key={fileEntity.id} fileId={fileEntity.id} fileEntity={fileEntity} />;
          })}
        </div>
      )}
      {count > 25 && (
        <BhSecondaryButton buttonProps={{ onClick: showProjectLog }}>
          <Trans>DASHBOARD.INFO.SEE_ALL</Trans>
        </BhSecondaryButton>
      )}
      {count < 1 && (
        <BhNoMatchesImage>
          <Trans>OVERVIEW.NO_FILES</Trans>
        </BhNoMatchesImage>
      )}
    </>
  );
};

export default OverviewTabsFileList;
