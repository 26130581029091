import React, { FC, ReactNode } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  children: ReactNode;
  classes?: string;
  colSpan?: number;
  rowSpan?: number;
  subheader?: boolean;
}

const BhSpreadsheetTableTH: FC<Props> = ({ children, classes, colSpan, rowSpan, subheader }) => {
  return (
    <th className={classNames(classes, "bh-text-deep-ocean border px-1", subheader ? "bh-bg-pigeon-40 bh-border-pigeon-50" : "bh-bg-smoke bh-border-pigeon-40")} colSpan={colSpan}
        rowSpan={rowSpan}>
      {children}
    </th>
  );
};

export default BhSpreadsheetTableTH;
