import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { ITask, TaskStatus } from "@/model/taskBoard/ITask";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentUser } from "@/app/store/userSlice";
import { EntityId } from "@reduxjs/toolkit";
import { selectOpenedTaskBoardUsers } from "@/app/store/taskBoardsSlice";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhUserIcon from "@components/user/BhUserIcon";
import { faCircleUser } from "@fortawesome/pro-solid-svg-icons/faCircleUser";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { savePersonalTaskAsync, saveTaskBoardTaskAsync } from "@/app/store/tasksSlice";
import { getUserFullName } from "@/model/IUser";
import BhIconButtonDatepicker from "@components/buttons/BhIconButtonDatepicker";
import { useTranslation } from "react-i18next";
import BhTooltip from "@components/BhTooltip";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";

interface Props {
  saveCallback: Function;
  taskBoardId?: EntityId;
  status: TaskStatus;
}

const NewTaskContainer: FC<Props> = ({ saveCallback, taskBoardId, status }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const isPersonalTask = !taskBoardId;
  const currentUser = useAppSelector(selectCurrentUser);
  const taskBoardUsers = useAppSelector(selectOpenedTaskBoardUsers);
  const projectId = useAppSelector(selectCurrentProjectId);
  const [task, setTask] = useState({ reporter: currentUser.id, status: status, priority: 3, name: "" } as ITask);
  const [assignee, setAssignee] = useState({} as ISimpleValidUserAuthority);
  const [users, setUsers] = useState(taskBoardUsers);

  useEffect(() => {
    inputRef?.current?.focus();
  });

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const saveNewTask = () => {
    if (inputRef.current && inputRef.current.value) {
      if (isPersonalTask) {
        let newTask = { ...task, ...({ name: inputRef.current.value, assignee: currentUser.id } as ITask) };
        dispatch(savePersonalTaskAsync({ task: newTask, projectId: projectId }));
      }
      if (!isPersonalTask) {
        const newTask = { ...task, ...({ taskBoardId: taskBoardId, name: inputRef.current.value, assignee: assignee.userEntityId } as ITask) };
        dispatch(saveTaskBoardTaskAsync({ task: newTask, projectId: projectId }));
      }
    }
    saveCallback();
  };

  const handleClickOutside = useCallback(
    (event: any) => {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        saveNewTask();
        document.removeEventListener("click", handleClickOutside, true);
      }
    },
    [assignee, task]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return function cleanup() {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [assignee, task]);

  const onChange = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    e.target.style.height = "inherit";
    e.target.style.height = `${e.target.scrollHeight}px`;
    e.target.style.height = `${Math.min(e.target.scrollHeight, 78)}px`;
  };

  const selectAssignee = (auth: ISimpleValidUserAuthority) => {
    setAssignee(auth);
  };

  const selectDeadline = (selectedDate: { deadline: Date }) => {
    setTask({ ...task, ...selectedDate });
  };

  const onSearch = (e: React.FocusEvent<HTMLInputElement>) => {
    const searchString = e.target.value;
    const foundUsers = taskBoardUsers.filter((user) => getUserFullName(user).toLowerCase().includes(searchString));
    setUsers(foundUsers);
  };

  const onEnter = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      saveNewTask();
    }
  };

  return (
    <div ref={componentRef} className="bh-bg-white bh-border-pigeon-70 mb-1 flex w-full cursor-pointer flex-col rounded border-2 p-4">
      <div>
        <textarea
          ref={inputRef}
          className="placeholder:bh-text-deep-ocean-40 bh-text-deep-ocean text-14 w-full resize-none overflow-hidden border-0 py-2 font-semibold leading-5 placeholder:font-normal"
          placeholder={t("TASK.NAME.PLACEHOLDER") as string}
          autoComplete="off"
          rows={1}
          onChange={onChange}
          onKeyDown={(e) => onEnter(e)}
        />
      </div>
      <div className="mt-0.5 flex flex-row items-center">
        {taskBoardUsers && !isPersonalTask && (
          <div>
            <BhDropdown
              button={
                assignee.userEntityId ? (
                  <BhUserIcon user={assignee} size={24} />
                ) : (
                  <BhTooltip body={t("TASK.ADD_ASSIGNEE")}>
                    <BhIconButton icon={faCircleUser} />
                  </BhTooltip>
                )
              }
              menu={<BhDropdownMenu values={users} type={BhDropdownTypeEnum.AUTH} onSelect={selectAssignee} closeOnItemClick={true} search={true} searchInputSaveCallback={onSearch} />}
            />
          </div>
        )}
        <BhTooltip body={t("TASK.ADD_DEADLINE")}>
          <BhIconButtonDatepicker property={"deadline"} onChangeCallback={selectDeadline} dateSelected={task.deadline !== undefined} />
        </BhTooltip>
      </div>
    </div>
  );
};

export default NewTaskContainer;
