export interface IPdfExportForm {
  data: any;
  formType: BhFormType;
}

export enum BhFormType {
  TRESOOR_BUDGET = "TRESOOR_BUDGET",
  TRESOOR_TRANSACTIONS = "TRESOOR_TRANSACTIONS",
  CONTACTS_PDF = "CONTACTS_PDF",
  FILE_LIST_PDF = "FILE_LIST_PDF",
  MR_MODAL_PDF = "MR_MODAL_PDF"
}
