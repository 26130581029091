import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFiles } from "@fortawesome/pro-regular-svg-icons/faFiles";

interface Props {
  children: ReactNode;
}

const PartyDocumentsTextContainer: FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div className="flex h-full w-full flex-col overflow-auto py-12 px-24">
      <h2>
        <FontAwesomeIcon icon={faFiles} className="mr-3" />
        {t("WORKGROUP.NEW.DOCUMENTS.AUTHORITIES")}
      </h2>
      <p className="my-6">{t("WORKGROUP.NEW.DOCUMENTS.AUTHORITIES.TEXT")}</p>
      <div className="overflow-visible">{children}</div>
    </div>
  );
};

export default PartyDocumentsTextContainer;
