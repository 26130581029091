import React, { FC, useEffect, useState } from "react";
import { FileTreeType, IFileTree, IFileTreeTemplate } from "@/model/files/IFileTreeTemplate";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import ProjectTreeSettingsModal from "@/views/home/company/detail/companySettingsProjectTree/ProjectTreeSettingsModal";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import ProjectTreeImportModal from "@/views/home/company/detail/companySettingsProjectTree/ProjectTreeImportModal";
import { EntityId } from "@reduxjs/toolkit";
import ProjectTreeListContainer from "@/views/home/company/detail/companySettingsProjectTree/ProjectTreeListContainer";
import { useAppDispatch } from "@/app/hooks";
import { fetchDefaultFileTreeTemplatesAsync } from "@/app/store/companiesSlice";
import { useTranslation } from "react-i18next";
import { IProject } from "@/model/IProject";
import { fetchProjectFileTreeJson } from "@/api/fileTreeAPI";
import { v4 as uuidv4 } from "uuid";
import { naturalSortFilesByField } from "@/utilities/sortUtilities";

interface Props {
  fileTreeType: FileTreeType;
  companyId: EntityId;
}

const ProjectTreeContainer: FC<Props> = ({ fileTreeType, companyId }) => {
  const { t } = useTranslation();
  const [isSettingsModalShown, setIsSettingsModalShown] = useState<boolean>(false);
  const [isImportModalShown, setIsImportModalShown] = useState<boolean>(false);
  const [editableFileTreeTemplate, setEditableFileTreeTemplate] = useState<IFileTreeTemplate>({} as IFileTreeTemplate);
  const dispatch = useAppDispatch();

  useEffect(() => {
    resetEditableTemplate();
    dispatch(fetchDefaultFileTreeTemplatesAsync({ companyId: companyId, fileTreeType: fileTreeType }));
  }, []);

  const resetEditableTemplate = () => {
    setEditableFileTreeTemplate({
      type: fileTreeType,
      companyId: companyId,
      tree: {
        id: uuidv4(),
        name: "ROOT",
        type: fileTreeType,
        children: [] as Array<IFileTree>
      }
    } as IFileTreeTemplate);
  };

  const handleCreateNewClick = () => {
    resetEditableTemplate();
    setIsSettingsModalShown(true);
  };

  const sortChildrenInTree: any = (tree: IFileTree) => {
    if (tree.children && tree.children.length > 0) {
      return { ...tree, children: tree.children.map((c) => sortChildrenInTree(c)).sort((a, b) => naturalSortFilesByField(a, b, "name")) };
    } else {
      return tree;
    }
  };

  const handleCardClick = (fileTreeTemplate: IFileTreeTemplate) => {
    const sortedFileTreeTemplate = { ...fileTreeTemplate, tree: sortChildrenInTree(fileTreeTemplate.tree) };
    setEditableFileTreeTemplate(sortedFileTreeTemplate);
    setIsSettingsModalShown(true);
  };

  const handleSelectEditableFileTreeTemplate = async (changedValue: { project: IProject }) => {
    const { project } = changedValue;
    const response = await fetchProjectFileTreeJson(project.id, fileTreeType);
    let newTemplate = Object.assign({}, { ...editableFileTreeTemplate, tree: response.tree });
    setEditableFileTreeTemplate(newTemplate);
    setIsSettingsModalShown(true);
    setIsImportModalShown(false);
  };

  return (
    <>
      <div className="mt-12 mb-2 flex w-full items-center justify-between">
        <h2>{t("GLOBAL." + fileTreeType)}</h2>
        <div className="flex gap-2">
          <BhSecondaryButton buttonProps={{ onClick: () => setIsImportModalShown(true) }}>{t("COMPANY.SETTINGS.PROJECT.TREE.IMPORT_BUTTON")}</BhSecondaryButton>
          <BhPrimaryButton
            buttonProps={{
              onClick: () => handleCreateNewClick()
            }}
            icon={faPlus}
          >
            {t("GLOBAL.CREATE_FORM")}
          </BhPrimaryButton>
        </div>
      </div>
      <ProjectTreeListContainer companyId={companyId} fileTreeType={fileTreeType} onClick={handleCardClick} />
      <ProjectTreeSettingsModal
        isShown={isSettingsModalShown}
        setIsShown={setIsSettingsModalShown}
        editableTemplate={editableFileTreeTemplate}
        setEditableTemplate={setEditableFileTreeTemplate}
        fileTreeType={fileTreeType}
        companyId={companyId}
        resetEditableTemplate={resetEditableTemplate}
      />
      <ProjectTreeImportModal
        isShown={isImportModalShown}
        setIsShown={setIsImportModalShown}
        companyId={companyId}
        handleCancel={() => setIsImportModalShown(false)}
        handleSelect={handleSelectEditableFileTreeTemplate}
      />
    </>
  );
};

export default ProjectTreeContainer;
