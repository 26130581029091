import React, { FC, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boSaveCompanyFinbiteAuthCodeAsync, boSelectCompany } from "@/app/store/backofficeSlice";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  setIsShown: (value: boolean) => void;
  authCode: string;
}

export interface IBoFinbiteAuthCodeDTO {
  companyId: EntityId;
  authCode: string;
}

const BOMaruFinbiteAuthCodeModal: FC<Props> = ({ setIsShown, authCode }) => {
  const company = useAppSelector(boSelectCompany);
  const dispatch = useAppDispatch();
  const [newAuthCode, setNewAuthCode] = useState(authCode);

  const saveAuthCode = () => {
    const dto: IBoFinbiteAuthCodeDTO = { companyId: company.id, authCode: newAuthCode };
    dispatch(boSaveCompanyFinbiteAuthCodeAsync(dto)).then(() => setIsShown(false));
  };

  return (
    <BhModal
      setIsShown={setIsShown}
      header={<h2>Authentication code</h2>}
      footer={<BhModalFooter cancelButtonText="Cancel" confirmButtonText="Save" onConfirm={saveAuthCode} onCancel={() => setIsShown(false)} />}
    >
      <div className="px-8">
        <BhInputStackedLabel initialValue={authCode} property="value" label="Authentication code" onChangeCallback={(changedObj: any) => setNewAuthCode(changedObj.value)} />
      </div>
    </BhModal>
  );
};

export default BOMaruFinbiteAuthCodeModal;
