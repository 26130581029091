import React, { FC, SetStateAction, useEffect, useState } from "react";
import BhComboboxStackedLabel from "@components/input/BhComboboxStackedLabel";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { FileTreeType, IFileTreeTemplate } from "@/model/files/IFileTreeTemplate";
import { fetchDefaultFileTreeTemplatesAsync, selectCompanyFileTreeTemplatesByType } from "@/app/store/companiesSlice";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { IPredefinedWorkGroup } from "@/model/IPredefinedWorkGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFiles } from "@fortawesome/pro-regular-svg-icons/faFiles";
import NewPartyTemplateFileTreeContainer from "@/views/home/company/detail/companySettingsPartyTemplates/createNewPartyTemplate/NewPartyTemplateFileTreeContainer";
import { useTranslation } from "react-i18next";

interface Props {
  newPartyForm: IPredefinedWorkGroup;
  setNewPartyForm: React.Dispatch<SetStateAction<IPredefinedWorkGroup>>;
  companyId: EntityId;
}

const CreateNewPartyTemplateDocumentsStep: FC<Props> = ({ newPartyForm, setNewPartyForm, companyId }) => {
  const { t } = useTranslation();
  const [documentTreeTemplate, setDocumentTreeTemplate] = useState<IFileTreeTemplate>({} as IFileTreeTemplate);
  const documentTreeTemplates = useAppSelector((state) => selectCompanyFileTreeTemplatesByType(state, companyId, FileTreeType.DOCUMENTS));
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchDefaultFileTreeTemplatesAsync({ companyId: companyId, fileTreeType: FileTreeType.DOCUMENTS }));
  }, []);

  const selectDocumentTreeRelation = (template: { documentTreeTemplate: IFileTreeTemplate }) => {
    setDocumentTreeTemplate(template.documentTreeTemplate);
  };

  return (
    <div className="mx-28 w-full">
      <h2 className="bh-text-deep-ocean mb-6 flex items-center gap-3">
        <FontAwesomeIcon className="pb-1" icon={faFiles} size="xs" />
        {t("WORKGROUP.NEW.DOCUMENTS.AUTHORITIES")}
      </h2>
      <p className="mt-6">{t("WORKGROUP.NEW.DOCUMENTS.AUTHORITIES.TEXT")}</p>
      <div className="mb-10 flex items-center gap-2">
        {documentTreeTemplates && (
          <BhComboboxStackedLabel
            property="documentTreeTemplate"
            values={documentTreeTemplates || []}
            onSelect={selectDocumentTreeRelation}
            label={t("SIDEBAR.FILE_TREE")}
            customType={BhDropdownTypeEnum.FILETREETEMPLATE}
          />
        )}
      </div>
      <NewPartyTemplateFileTreeContainer fileTree={documentTreeTemplate?.tree} />
    </div>
  );
};

export default CreateNewPartyTemplateDocumentsStep;
