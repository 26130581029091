import React, { ChangeEvent, FC, useId, useRef, useState } from "react";
import { IChecklistBaseVersionRow } from "@/model/checklist/IChecklistBaseVersionRow";
import BhInputSimple from "@components/input/BhInputSimple";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faArrowUpToLine } from "@fortawesome/pro-regular-svg-icons/faArrowUpToLine";
import { faFolderBlank } from "@fortawesome/pro-regular-svg-icons/faFolderBlank";
import SelectFilesModal from "@/views/home/project/projectModals/selectFilesModal/SelectFilesModal";
import { FileEntityResource, FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import BhDropFileContainer from "@components/upload/BhDropFileContainer";
import { useAppDispatch } from "@/app/hooks";
import AttachmentsContainerLarge from "@components/attachments/AttachmentsContainerLarge";
import { EntityId } from "@reduxjs/toolkit";
import { classNames } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";
import { uploadCompanyAttachmentAsync } from "@/app/store/uploadSlice";
import { attachNewFilesToChecklistBaseVersionRowAsync, deleteFileFromChecklistBaseVersionRowAsync } from "@/app/store/checklist/checklistBaseRowsSlice";
import BhCheckboxWithText from "@components/checkboxes/BhCheckboxWithText";

interface Props {
  companyId: EntityId;
  checklistBaseId: EntityId;
  baseVersionRow: IChecklistBaseVersionRow;
  saveCallback: Function;
  disabled?: boolean;
  projectId?: EntityId;
}

const ChecklistBaseRowAttachmentField: FC<Props> = ({ baseVersionRow, saveCallback, disabled, checklistBaseId, companyId, projectId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const fileInputRef = useRef(null);
  const inputId = useId();
  const [selectFilesModalOpen, setSelectFilesModalOpen] = useState(false);
  const attachments = baseVersionRow.attachments || [];

  const addFilesToRow = (files: Array<IFileEntity>, isUpload: boolean) => {
    if (files.length > 0) {
      const fileIds = files.map((file) => file.id);
      dispatch(attachNewFilesToChecklistBaseVersionRowAsync({ companyId: companyId, checklistBaseId: checklistBaseId, baseRowId: baseVersionRow.id, fileIds: fileIds, isUpload: isUpload }));
    }
  };

  const uploadFiles = async (files: any) => {
    if (disabled) return;
    let savedFiles: Array<IFileEntity> = [];
    await Promise.all(
      files.map(async (file: any) => {
        await dispatch(uploadCompanyAttachmentAsync({ file: file, companyId: companyId, resource: FileEntityResource.CHECKLIST_BASE_VERSION_ROW, resourceId: baseVersionRow.id })).then((action) => {
          const savedFileEntity = action.payload as IFileEntity;
          if (savedFileEntity.id) {
            savedFiles.push(savedFileEntity);
          }
        });
      })
    ).then(() => {
      addFilesToRow(savedFiles, true);
    });
  };

  const onUploadFilesSelected = (event: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const files = [...event.target.files];
    if (!files) return;
    const filesWithSizeLargerThanZero = files.filter((file) => file.size > 0);
    event.target.value = "";
    uploadFiles(filesWithSizeLargerThanZero);
  };

  const addProjectFilesToRow = (selectedFiles: Array<IFileEntity>) => {
    addFilesToRow(selectedFiles, false);
  };

  const removeAttachment = (fileId: EntityId) => {
    dispatch(deleteFileFromChecklistBaseVersionRowAsync({ companyId: companyId, checklistBaseId: checklistBaseId, baseRowId: baseVersionRow.id, fileId: fileId }));
  };

  const addUploadedOrAddedFilesToRow = (attachmentObject: { files: Array<IFileEntity> }) => {
    const addedAttachments = attachmentObject.files.filter((file) => {
      return !attachments.some((att) => att.id === file.id);
    });
    const isUpload = addedAttachments.length > 0 && !addedAttachments[0].parentFileEntityId;
    addFilesToRow(addedAttachments, isUpload);
  };

  return (
    <div className="flex w-full flex-col p-4">
      <div className="-ml-4 mb-2">
        <BhCheckboxWithText
          text={t("CHECKLIST_BASE.ALLOW_ATTACHMENT_UPLOADS")}
          isChecked={baseVersionRow.allowAttachmentUploads}
          property={"allowAttachmentUploads"}
          isSmallCheckbox={true}
          onChange={saveCallback}
          disabled={disabled}
        />
      </div>
      <BhInputSimple initialValue={baseVersionRow.fieldTitle} property={"fieldTitle"} placeholder={t("CHECKLIST_BASE.FIELD.PLACEHOLDER") as string} onBlurAction={saveCallback} disabled={disabled} />
      <BhDropFileContainer onDrop={uploadFiles} disabled={disabled}>
        {attachments.length === 0 && !disabled && (
          <div className={classNames("mt-2.5 flex flex-row items-center justify-center gap-x-3 rounded py-5", !disabled && "bh-bg-smoke bh-border-pigeon-50 border border-dashed")}>
            <BhTextOnlyButton
              icon={faArrowUpToLine}
              buttonProps={{
                onClick: () => {
                  // @ts-ignore
                  fileInputRef.current.click();
                }
              }}
            >
              {t("GLOBAL.UPLOAD_FILES")}
            </BhTextOnlyButton>
            {projectId && (
              <BhTextOnlyButton icon={faFolderBlank} buttonProps={{ onClick: () => setSelectFilesModalOpen(true) }}>
                {t("FILEPICKER.PICK_FILE")}
              </BhTextOnlyButton>
            )}
          </div>
        )}
        {attachments.length > 0 && (
          <div className={classNames("mt-2.5 flex flex-row items-center rounded px-4 pt-4 pb-2", !disabled && "bh-bg-smoke bh-border-pigeon-50 border border-dashed")}>
            <AttachmentsContainerLarge
              attachments={attachments}
              property={"files"}
              saveCallback={addUploadedOrAddedFilesToRow}
              removeCallback={!disabled ? removeAttachment : undefined}
              chooseFromProjectAllowed={projectId ? true : false}
              multiple={true}
              addingDisabled={disabled}
              excludeFileTypes={[FileEntityType.FORM]}
              uploadCallback={uploadFiles}
            />
          </div>
        )}
      </BhDropFileContainer>
      <input type="file" id={inputId} ref={fileInputRef} style={{ display: "none" }} multiple={true} onChange={onUploadFilesSelected} />
      {selectFilesModalOpen && <SelectFilesModal onSelect={addProjectFilesToRow} setModalOpen={setSelectFilesModalOpen} multiple={true} excludeFileTypes={[FileEntityType.FORM]} />}
    </div>
  );
};

export default ChecklistBaseRowAttachmentField;
