import React, { FC, useEffect } from "react";
import { fetchPersonalTaskAsync } from "@/app/store/tasksSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { EntityId } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { ITask } from "@/model/taskBoard/ITask";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  taskId: EntityId;
}

const TaskIdRedirect: FC<Props> = ({ taskId }) => {
  const projectId = useAppSelector(selectCurrentProjectId);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPersonalTaskAsync({ taskId: taskId, projectId: projectId })).then((action) => {
      const task = action.payload as ITask;
      if (task?.taskIdentifier) {
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/task/${task.taskIdentifier}`);
      } else {
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/taskboard/list/`);
      }
    });
  }, []);

  return null;
};

export default TaskIdRedirect;
