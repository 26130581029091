import React, { FC } from "react";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";

interface Props {
  text: string;
  value: boolean;
  onClickAction: () => void;
  disabled?: boolean;
}

const BhFilterWithToggle: FC<Props> = ({ text, value, onClickAction, disabled }) => {
  return (
    <div className="hover:bh-bg-smoke flex w-fit flex-row items-center rounded p-2">
      <div className="bh-text-deep-ocean-80 mr-1.5">{text}</div>
      <div className="flex flex-col justify-center">
        <BhToggleSwitch value={value} onClickAction={onClickAction} small={true} disabled={disabled} />
      </div>
    </div>
  );
};

export default BhFilterWithToggle;
