import React, { FC } from "react";
import { IFileEntityComment } from "@/model/files/IFileEntityComment";
import BhCommentUserIconWithName from "@components/user/BhCommentUserIconWithName";
import BhCommentDropdown from "@components/comment/BhCommentDropdown";
import { IFileConfirmationComment } from "@/model/confirmations/IFileConfirmationComment";
import AttachmentsContainerLarge from "@components/attachments/AttachmentsContainerLarge";

interface Props {
  comment: IFileEntityComment | IFileConfirmationComment;
  editCallback?: Function;
  deleteCallback?: Function;
}

const BhCommentCompact: FC<Props> = ({ comment, editCallback, deleteCallback }) => {
  return (
    <div className="p-4">
      <div className="flex flex-row justify-between">
        <BhCommentUserIconWithName comment={comment} />
        <div className="inline-flex">
          <BhCommentDropdown comment={comment} editCallback={editCallback} deleteCallback={deleteCallback} />
        </div>
      </div>
      <div className="whitespace-pre-line break-words pt-2" dangerouslySetInnerHTML={{ __html: comment.text }} />
      {comment.attachments?.length > 0 && (
        <div className="mt-2">
          <AttachmentsContainerLarge attachments={comment.attachments} addingDisabled={true} />
        </div>
      )}
    </div>
  );
};

export default BhCommentCompact;
