import React, { FC, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import BhDatePicker from "@components/input/BhDatePicker";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchShareboxAsync, saveShareboxValidToAsync, selectShareboxById } from "@/app/store/shareboxesSlice";
import { IShareboxValidToRequest } from "@/model/shareboxes/IShareboxValidToRequest";
import dayjs from "dayjs";

interface Props {
  shareboxId: EntityId;
}
const ShareboxDetailValidTo: FC<Props> = ({ shareboxId }) => {
  const { t } = useTranslation();
  const sharebox = useAppSelector((state) => selectShareboxById(state, shareboxId));
  const [validToVisible, setValidToVisibility] = useState<boolean>(sharebox?.validTo !== null);
  const dispatch = useAppDispatch();

  if (!sharebox) return null;

  const dateUpdated = (change: any) => {
    const request = { shareboxId: shareboxId, validTo: dayjs(change.validTo).endOf("day").toDate() } as IShareboxValidToRequest;
    dispatch(saveShareboxValidToAsync(request));
  };

  const removeShareboxValidTo = () => {
    if (sharebox) {
      const request = { shareboxId: shareboxId, validTo: undefined } as IShareboxValidToRequest;
      dispatch(saveShareboxValidToAsync(request)).then(() => {
        setValidToVisibility(false);
        dispatch(fetchShareboxAsync(shareboxId));
      });
    }
  };

  return (
    <div className="flex flex-row">
      <h3 className="m-0 w-1/4 py-3 px-6">{t("SHAREBOX.VALID_TO")}</h3>
      {validToVisible && (
        <div className="flex w-3/4">
          <BhDatePicker initialValue={sharebox?.validTo ? new Date(sharebox?.validTo) : null} property={"validTo"} onChangeCallback={dateUpdated} onResetCallback={removeShareboxValidTo} />
        </div>
      )}
      {!validToVisible && (
        <BhSecondaryButton icon={faPlus} buttonProps={{ onClick: () => setValidToVisibility(true) }}>
          {t("SHAREBOX.ADD_VALID_TO")}
        </BhSecondaryButton>
      )}
    </div>
  );
};

export default ShareboxDetailValidTo;
