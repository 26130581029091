import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IPublicContainer } from "@/model/container/IPublicContainer";
import BhDivTableHeader from "@components/table/BhDivTableHeader";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { IFileEntity } from "@/model/files/IFileEntity";
import { naturalSortFilesByField } from "@/utilities/sortUtilities";
import PublicContainerFileRow from "@/views/home/project/detail/container/public/PublicContainerFileRow";

interface Props {
  publicContainer?: IPublicContainer;
  containerUuid: string;
  signInviteUuid: string;
}

const PublicContainerFileList: FC<Props> = ({ publicContainer, containerUuid, signInviteUuid }) => {
  const { t } = useTranslation();

  const fileTableHeaders: Array<IBhTableHeader<IFileEntity>> = [
    { id: 1, content: t("GLOBAL.ORDER_BY.NAME"), classes: "min-w-24 flex-grow" },
    { id: 2, content: t("GLOBAL.USER"), classes: "w-48" },
    { id: 3, content: "", classes: "w-32" }
  ];

  const sortedFiles = [...(publicContainer?.files || [])].sort((a, b) => naturalSortFilesByField(a, b, "name"));

  return (
    <>
      <div className="bh-bg-smoke w-full rounded p-8">
        <div className="flex flex-row items-center justify-between">
          <h3 className="bh-text-deep-ocean pb-3">{t("CONTAINER.CONTENT")}</h3>
        </div>
        <div className="w-full">
          <BhDivTableHeader key="filesTable" columns={fileTableHeaders} sortedBy="" reversed={false} />
        </div>
        <div>
          {sortedFiles
            .filter((f) => f.isActive)
            .map((fileEntity) => {
              return <PublicContainerFileRow key={fileEntity.id} fileEntity={fileEntity} containerUuid={containerUuid} signInviteUuid={signInviteUuid} />;
            })}
        </div>
      </div>
    </>
  );
};

export default PublicContainerFileList;
