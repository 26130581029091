import React, { FC, useState } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { faFolder } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContractRowsTableNewRowDropDown from "@/views/home/project/detail/actsAndContracts/contract/contractWorksTable/ContractRowsTableNewRowDropDown";
import BhIconButton from "@components/buttons/BhIconButton";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { IFileTree } from "@/model/files/IFileTreeTemplate";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import BhInputSimple from "@components/input/BhInputSimple";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons/faAngleRight";

interface Props {
  rowItem: IFileTree;
  level: number;
  parentId?: EntityId;
  changeNameCallback: Function;
  deleteIdCallback: Function;
  addChildToIdCallback: Function;
}

const ProjectSettingsModalTreeRowNew: FC<Props> = ({ rowItem, level, parentId, changeNameCallback, deleteIdCallback, addChildToIdCallback }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(level < 2);
  const hasChildren = rowItem?.children?.length > 0;

  return (
    <div className="ml-3">
      {rowItem && (
        <>
          <div className={classNames("group flex h-8 gap-8 leading-8")}>
            <ContractRowsTableNewRowDropDown
              classes="ml-2"
              parentId={parentId}
              currentId={rowItem.id}
              addChildToIdCallback={(id: EntityId, insertAfterId?: EntityId) => {
                setIsOpen(true);
                addChildToIdCallback(id, insertAfterId);
              }}
            />
            <div className="mr-3 flex w-full items-center justify-between">
              <div className="relative flex w-full items-center">
                <FontAwesomeIcon icon={faFolder} className={classNames("bh-text-pigeon-60 pr-1.5")} />
                {level === 0 && <span>{t("GLOBAL." + rowItem.type)}</span>}
                {level > 0 && (
                  <BhInputSimple
                    autoFocus={rowItem.name === ""}
                    withoutBorder={true}
                    inputClasses="h-7 w-full bh-border-white"
                    initialValue={rowItem?.name || ""}
                    property="name"
                    onBlurAction={(changedObject: IFileTree) => changeNameCallback({ ...rowItem, ...changedObject })}
                  />
                )}
                {level > 0 && hasChildren && (
                  <FontAwesomeIcon icon={isOpen ? faAngleDown : faAngleRight} className="bh-text-deep-ocean absolute -left-6 cursor-pointer select-none" onClick={() => setIsOpen(!isOpen)} />
                )}
              </div>
              {level > 0 && <BhIconButton icon={faTrash} buttonProps={{ onClick: () => deleteIdCallback(rowItem.id) }} />}
            </div>
          </div>
          {isOpen &&
            rowItem?.children?.map((child: IFileTree) => {
              return (
                <ProjectSettingsModalTreeRowNew
                  key={child.id}
                  rowItem={child}
                  level={level + 1}
                  parentId={rowItem.id}
                  changeNameCallback={changeNameCallback}
                  deleteIdCallback={deleteIdCallback}
                  addChildToIdCallback={addChildToIdCallback}
                />
              );
            })}
        </>
      )}
    </div>
  );
};
export default ProjectSettingsModalTreeRowNew;
