import React, { FC } from "react";
import { TaskStatus } from "@/model/taskBoard/ITask";
import { useAppSelector } from "@/app/hooks";
import { selectShowArchivedFilter } from "@/app/store/tasksSlice";
import TaskColumn from "@components/taskBoard/TaskColumn";

const TaskBoardColumnsContainer: FC = () => {
  const taskBoardColumnStatuses: Array<TaskStatus> = [TaskStatus.TODO, TaskStatus.IN_PROGRESS, TaskStatus.NEEDS_APPROVAL, TaskStatus.DONE];
  const archivedTaskBoardColumnStatuses: Array<TaskStatus> = [TaskStatus.ARCHIVED];
  const showArchived = useAppSelector(selectShowArchivedFilter);

  return (
    <div className="flex h-full w-full flex-row overflow-hidden pb-4">
      {!showArchived && <TaskColumn taskBoardColumnStatuses={taskBoardColumnStatuses} />}
      {showArchived && <TaskColumn taskBoardColumnStatuses={archivedTaskBoardColumnStatuses} />}
    </div>
  );
};

export default TaskBoardColumnsContainer;
