import React, { FC, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BoInputWithEmailChip from "@components/backoffice/invoices/BOInputWithEmailChip";
import { IInvoiceEmail } from "@/model/invoices/IInvoiceEmail";
import { useAppDispatch } from "@/app/hooks";
import { boSendKmdInfAAsync } from "@/app/store/backofficeSlice";
import BhModalFooter from "@components/modal/BhModalFooter";

interface Props {
  setIsShown: (value: boolean) => void;
  currentMonth: string;
}

const BOInvoiceSendKmdInfModal: FC<Props> = ({ setIsShown, currentMonth }) => {
  const [emailsToSend, setEmailsToSend] = useState<Array<string>>([]);
  const dispatch = useAppDispatch();

  const onEnterKeyDown = (inputValue: any) => {
    if (!emailsToSend.includes(inputValue)) {
      setEmailsToSend([...emailsToSend, inputValue]);
    }
  };


  const removeEmail = (email: string) => {
    const newEmails = emailsToSend.filter((e) => e !== email);
    setEmailsToSend(newEmails);
  };

  const sendKmdEmail = () => {
    const emailsWithYearMonth: IInvoiceEmail = { emails: [...emailsToSend], yearMonth: currentMonth };
    dispatch(boSendKmdInfAAsync(emailsWithYearMonth)).then(() => {
    });
    setEmailsToSend([]);
    setIsShown(false);
  };

  const isSendDisabled = emailsToSend.length === 0;

  return (
    <BhModal
      setIsShown={setIsShown}
      header={<h2>Send KMD INF A</h2>}
      footer={<BhModalFooter cancelButtonText="Cancel" onCancel={setIsShown} confirmButtonText="Send" onConfirm={sendKmdEmail} confirmDisabled={isSendDisabled} />}
    >
      <BoInputWithEmailChip label="E-mails" emailsToSend={emailsToSend} onEnterKeyDown={onEnterKeyDown} onRemove={removeEmail} />
    </BhModal>
  );
};

export default BOInvoiceSendKmdInfModal;