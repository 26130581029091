import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import DailyMailProjectSettingsContainer from "@/views/home/profile/modals/dailyMailModal/DailyMailProjectSettingsContainer";
import TaskMailProjectSettingsContainer from "@/views/home/profile/modals/taskMailModal/TaskMailProjectSettingsContainer";
import { useAppSelector } from "@/app/hooks";
import { selectUserMailSettingsModalType } from "@/app/store/userPreferenceSlice";
import { UserEmailSettingsType } from "@/model/profile/IUserEmailSettings";
import { selectProjectById } from "@/app/store/companiesSlice";
import { selectCurrentUserInfo } from "@/app/store/userSlice";
import { ProjectSecurityLevel } from "@/model/IProject";

interface Props {
  projectId: EntityId;
}

const UserMailSettingsModalContent: FC<Props> = ({ projectId }) => {
  const currentUserInfo = useAppSelector(selectCurrentUserInfo);
  const project = useAppSelector((state) => selectProjectById(state, projectId));
  const modalType = useAppSelector(selectUserMailSettingsModalType);
  const isDailyMailModal = modalType === UserEmailSettingsType.DISABLE_IN_DAILY_EMAIL;
  const isTaskMailModal = modalType === UserEmailSettingsType.DISABLE_IN_TASK_EMAIL;

  if (!project) return null;

  const securityLevels: Record<ProjectSecurityLevel, number> = {
    [ProjectSecurityLevel.LOW]: 1,
    [ProjectSecurityLevel.MEDIUM]: 2,
    [ProjectSecurityLevel.HIGH]: 3
  };

  const projectSecurityLevel = securityLevels[project.securityLevel];
  const userSecurityLevel = securityLevels[currentUserInfo.securityLevel];

  const userSecurityLevelTooLow = projectSecurityLevel > userSecurityLevel;

  return (
    <>
      {projectId && isDailyMailModal && <DailyMailProjectSettingsContainer userSecurityLevelTooLow={userSecurityLevelTooLow} />}
      {projectId && isTaskMailModal && <TaskMailProjectSettingsContainer userSecurityLevelTooLow={userSecurityLevelTooLow} />}
    </>
  );
};

export default UserMailSettingsModalContent;
