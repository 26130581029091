import React, { FC, useState } from "react";
import ProjectTreeSubDirContainer from "@/views/home/company/createProject/newProjectFolderStructure/projectTreePreviewModal/ProjectTreeSubDirContainer";
import ProjectTreeListItem from "@/views/home/company/createProject/newProjectFolderStructure/projectTreePreviewModal/ProjectTreeListItem";
import { IFileTree } from "@/model/files/IFileTreeTemplate";
import { useTranslation } from "react-i18next";
import { naturalSortFilesByField } from "@/utilities/sortUtilities";

interface Props {
  projectTree: IFileTree;
}

const ProjectTreeRootContainer: FC<Props> = ({ projectTree }) => {
  const { t } = useTranslation();
  const [isRootDirOpen, setRootDirOpen] = useState<boolean>(true);
  const subDirs = [...projectTree.children].sort((a, b) => naturalSortFilesByField(a, b, "name"));
  const hasSubDirs = subDirs.length > 0;

  return (
    <div className="min-h-180 mx-20 my-14 flex-col justify-self-center">
      <ProjectTreeListItem hasSubDirs={hasSubDirs} isDirOpen={isRootDirOpen} name={t("GLOBAL." + projectTree.type)} onClickAction={setRootDirOpen} level={0} />
      {isRootDirOpen && (
        <div className="ml-4">
          {subDirs.map((dir: IFileTree) => {
            return <ProjectTreeSubDirContainer subDir={dir} key={dir.id} level={1} />;
          })}
        </div>
      )}
    </div>
  );
};

export default ProjectTreeRootContainer;
