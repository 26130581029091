import React, { FC } from "react";
import DetailViewContainer from "@/views/home/project/detail/detailViewContainer/DetailViewContainer";
import DetailViewHeading from "@/views/home/project/detail/detailViewContainer/DetailViewHeading";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import ChecklistList from "@/views/home/project/detail/checklists/ChecklistList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { useAppSelector } from "@/app/hooks";
import { selectChecklistFilter } from "@/app/store/checklist/checklistsSlice";
import { classNames } from "@/utilities/jsUtilities";
import { useNavigate } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";

interface Props {}

const ChecklistListContainer: FC<Props> = () => {
  useDocumentTitle("PROJECT.CHECKLISTS");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const projectId = useAppSelector(selectCurrentProjectId);
  const filter = useAppSelector(selectChecklistFilter);

  const navigateToCategories = () => {
    if (!filter.category) return;
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklists`);
  };

  return (
    <DetailViewContainer
      header={
        <DetailViewHeading>
          <div className="flex flex-row items-center gap-x-2">
            <div className={classNames(filter.category && "bh-text-deep-ocean-60 cursor-pointer")} onClick={navigateToCategories}>
              {t("PROJECT.CHECKLISTS")}
            </div>
            {filter.category && (
              <div className="flex flex-row items-center gap-x-2">
                <div className="bh-text-deep-ocean-40">
                  <FontAwesomeIcon icon={faChevronRight} size={"2xs"} />
                </div>
                <div>{t("CHECKLIST.CATEGORY." + filter.category.toUpperCase())}</div>
              </div>
            )}
          </div>
        </DetailViewHeading>
      }
    >
      <ChecklistList />
    </DetailViewContainer>
  );
};

export default ChecklistListContainer;
