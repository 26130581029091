import React, { FC } from "react";
import FormWeatherFieldTable from "@components/form/fields/weatherField/FormWeatherFieldTable";
import FormWeatherFieldTablePreview from "@components/form/fields/weatherField/FormWeatherFieldTablePreview";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/form/formSlice";

interface Props {
  field: any;
  disabled?: boolean;
  isPreview?: boolean;
  nestedDataSaveCallback?: Function;
  nestedDataAddCallback?: Function;
  nestedDataRemoveCallback?: Function;
}

const FormWeatherField: FC<Props> = ({ field, disabled, isPreview, nestedDataSaveCallback, nestedDataAddCallback, nestedDataRemoveCallback }) => {
  const rows = useAppSelector((state) => (!isPreview ? selectValueByPropertyFromCurrentFormData(state, field.property) : field.value.rows));

  return (
    <div className="flex w-full">
      {!isPreview && (
        <FormWeatherFieldTable
          rows={rows}
          field={field}
          nestedDataSaveCallback={nestedDataSaveCallback}
          addCallback={nestedDataAddCallback}
          disabled={disabled}
          nestedDataRemoveCallback={nestedDataRemoveCallback}
        />
      )}
      {isPreview && <FormWeatherFieldTablePreview rows={rows} cols={field.value.columns} />}
    </div>
  );
};

export default FormWeatherField;
