import React, { FC, ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";

interface Props {
  text: ReactNode;
}

const BhFlagSmall: FC<Props> = ({ text }) => {
  return (
    <div className="bh-bg-success-green-10 m-0-auto bh-shadow z-100 fixed bottom-10 left-1/2 flex -translate-x-1/2 flex-row items-center rounded py-2 px-3">
      <FontAwesomeIcon icon={faCircleCheck} className="bh-text-success-green-110 pr-2" />
      <span>{text}</span>
    </div>
  );
};

export default BhFlagSmall;
