import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import {
  selectShareboxFilesForFolderSorted,
  selectShareboxFolderParentDirs,
  selectShareboxFoldersForFolderSorted,
  selectShareboxLoadingStatus,
  shareboxFileEntityIdsSelected,
  shareboxFolderIdsSelected
} from "@/app/store/shareboxesSlice";
import ShareboxFileContainer from "@/views/home/project/detail/shareboxes/detail/ShareboxFileContainer";
import ShareboxFolderContainer from "@/views/home/project/detail/shareboxes/detail/ShareboxFolderContainer";
import BhBreadcrumbs from "@components/breadcrumb/BhBreadcrumbs";
import ShareboxFilesContainerTableHeader from "@/views/home/project/detail/shareboxes/detail/shareboxFilesView/ShareboxFilesContainerTableHeader";
import { useTranslation } from "react-i18next";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import ShareboxFilesContainerSkeleton from "@/views/home/project/detail/shareboxes/detail/shareboxFilesView/ShareboxFilesContainerSkeleton";
import { IFileEntity } from "@/model/files/IFileEntity";

interface Props {
  shareboxId: EntityId;
  shareboxUuid: string;
  folderId: EntityId | null;
  setCurrentDirectoryId: Function;
  publicSharebox: boolean;
}

const ShareboxFilesContainerDirectoryViewFolder: FC<Props> = ({ shareboxId, shareboxUuid, folderId, setCurrentDirectoryId, publicSharebox }) => {
  const { t } = useTranslation();
  const filesForFolderSorted = useAppSelector((state) => (folderId ? selectShareboxFilesForFolderSorted(state, shareboxId, folderId) : ([] as Array<IFileEntity>)));
  const foldersForFolderSorted = useAppSelector((state) => selectShareboxFoldersForFolderSorted(state, shareboxId, folderId));
  const parentFolders = useAppSelector((state) => selectShareboxFolderParentDirs(state, shareboxId, folderId));
  const shareboxLoadingStatus = useAppSelector(selectShareboxLoadingStatus);
  const dispatch = useAppDispatch();

  const attachmentFileIds = filesForFolderSorted?.map((file) => file.id) || [];

  const breadcrumbs = [
    { text: t("SHAREBOX.LIST.SHAREBOX"), onClick: () => setCurrentDirectoryId(null) },
    ...parentFolders.map((dir) => ({ text: dir.name, onClick: () => setCurrentDirectoryId(dir.id) }))
  ];

  const allSelected = [...(filesForFolderSorted || []), ...(foldersForFolderSorted || [])].every((file) => file.selected);

  const handleCheckboxChange = () => {
    const selected = !allSelected;
    foldersForFolderSorted && dispatch(shareboxFolderIdsSelected({ shareboxId: shareboxId, folderIds: foldersForFolderSorted.map((f) => f.id), selected: selected }));
    filesForFolderSorted && dispatch(shareboxFileEntityIdsSelected({ shareboxId: shareboxId, fileEntityIds: filesForFolderSorted.map((f) => f.id), selected: selected }));
  };

  return (
    <div>
      <ShareboxFilesContainerTableHeader checked={publicSharebox ? allSelected : undefined} handleChange={publicSharebox ? handleCheckboxChange : undefined} />
      <div className="border-b p-2">
        <BhBreadcrumbs values={breadcrumbs} />
      </div>
      {shareboxLoadingStatus !== BhStateStatusType.SUCCESS && !publicSharebox && (
        <div>
          <ShareboxFilesContainerSkeleton />
          <ShareboxFilesContainerSkeleton />
          <ShareboxFilesContainerSkeleton />
        </div>
      )}
      {(shareboxLoadingStatus === BhStateStatusType.SUCCESS || publicSharebox) && (
        <div>
          {foldersForFolderSorted?.map((file) => (
            <ShareboxFolderContainer key={file.id} fileEntity={file} shareboxId={shareboxId} setCurrentDirectoryId={setCurrentDirectoryId} publicSharebox={publicSharebox} />
          ))}
          {filesForFolderSorted &&
            filesForFolderSorted?.map((file) => (
              <ShareboxFileContainer key={file.id} fileEntity={file} shareboxId={shareboxId} shareboxUuid={shareboxUuid} publicSharebox={publicSharebox} attachmentFileIds={attachmentFileIds} />
            ))}
        </div>
      )}
    </div>
  );
};

export default ShareboxFilesContainerDirectoryViewFolder;
