import { EntityId } from "@reduxjs/toolkit";

export interface IUserEmailSetting {
  id: EntityId;
  userEntityId: EntityId;
  type: UserEmailSettingsType;
  resource: string;
  resourceId: EntityId;
  deleted: boolean;
}

export interface IUserDailyMailProjectFileEntityRelation {
  id: EntityId;
  userEntityId: EntityId;
  projectId: EntityId;
  fileEntityId: EntityId;
  deleted: boolean;
}

export interface IChangeDailyMailFolderSelectionRequestBody {
  projectId: EntityId;
  selection: DailyMailFolderSelection;
}

export enum UserEmailSettingsType {
  DISABLE_IN_DAILY_EMAIL = "DISABLE_IN_DAILY_EMAIL",
  DISABLE_IN_TASK_EMAIL = "DISABLE_IN_TASK_EMAIL",
  DISABLE_IN_SIGNING_AND_CONFIRMATION_EMAIL = "DISABLE_IN_SIGNING_AND_CONFIRMATION_EMAIL",
  DISABLE_IN_OTHER_EMAIL = "DISABLE_IN_OTHER_EMAIL",
  DAILY_MAIL_PROJECT_SPECIFIC_FILES_SELECTED = "DAILY_MAIL_PROJECT_SPECIFIC_FILES_SELECTED",
  DAILY_MAIL_PROJECT_SPECIFIC_DOCUMENTS_SELECTED = "DAILY_MAIL_PROJECT_SPECIFIC_DOCUMENTS_SELECTED",
  ADDING_NEW_DRAWINGS_FOLDER_TO_DAILY_MAIL_DISABLED = "ADDING_NEW_DRAWINGS_FOLDER_TO_DAILY_MAIL_DISABLED",
  ADDING_NEW_DOCUMENTS_FOLDER_TO_DAILY_MAIL_DISABLED = "ADDING_NEW_DOCUMENTS_FOLDER_TO_DAILY_MAIL_DISABLED",
  DAILY_MAIL_TASKS_DISABLED = "DAILY_MAIL_TASKS_DISABLED",
  DAILY_MAIL_SIGNING_AND_CONFIRMATION_DISABLED = "DAILY_MAIL_SIGNING_AND_CONFIRMATION_DISABLED",
  TASK_EMAIL_TASK_BOARD_DISABLED = "TASK_EMAIL_TASK_BOARD_DISABLED"
}

export enum DailyMailFolderSelection {
  ALL = "ALL",
  SELECTED = "SELECTED",
  NONE = "NONE"
}
